var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { QueryEntity } from '@datorama/akita';
import { PlatformsStore } from './platforms.store';
import { PlatformsRelationsQuery } from '../platforms-relations/platforms-relations.query';
import { flatMap, map } from 'rxjs/operators';
import { combineLatest, of } from 'rxjs';
import { isNullOrUndefined } from 'util';
import * as i0 from "@angular/core";
import * as i1 from "./platforms.store";
import * as i2 from "../platforms-relations/platforms-relations.query";
var PlatformsQuery = /** @class */ (function (_super) {
    __extends(PlatformsQuery, _super);
    function PlatformsQuery(store, relationsQuery) {
        var _this = _super.call(this, store) || this;
        _this.store = store;
        _this.relationsQuery = relationsQuery;
        _this.originPlatforms$ = _this.selectAll({ filterBy: function (entity) { return entity.isDataOrigin; } });
        _this.destinationPlatforms$ = _this.selectAll({ filterBy: function (entity) { return !entity.isDataOrigin; } });
        _this.selectedOriginPlatformCode$ = _this.select(function (state) { return state.ui.selectedOriginPlatform; });
        _this.selectedDestinationPlatformCode$ = _this.select(function (state) {
            return state.ui.selectedDestinationPlatform;
        });
        _this.isDestinationPlatformSelected$ = _this.selectedDestinationPlatformCode$
            .pipe(map(function (code) { return code !== null; }));
        _this.selectedOriginPlatform = _this.selectedOriginPlatformCode$
            .pipe(flatMap(function (selectedOriginPlatformCode) { return _this.selectAll({ filterBy: function (entity) { return entity.code === selectedOriginPlatformCode; } }); }), map(function (platforms) { return platforms[0]; }));
        _this.selectedDestinationPlatform$ = _this.selectedDestinationPlatformCode$
            .pipe(flatMap(function (selectedDestinationPlatformCode) { return _this.selectAll({ filterBy: function (entity) { return entity.code === selectedDestinationPlatformCode; } }); }), map(function (platforms) { return platforms[0]; }));
        _this.relatedPlatforms$ = _this.selectedOriginPlatform
            .pipe(flatMap(function (platform) { return platform ? _this.relationsQuery.relatedPlatformsIds(platform.id) : of([]); }), flatMap(function (platformIds) { return _this.selectAll({ filterBy: function (entity) { return platformIds.includes(entity.integrationServiceId); } }); }));
        /**
         * Возвращает дополнительные параметры платформы
         * @example this.platformsQuery.getPlatformParams(AssignationPlatforms.BITRIX24
         * @param {AssignationPlatforms} platformId - id платформы к которой привязаны параметры
         */
        _this.getPlatformParams = function (platformId) { return _this.select(function (state) { return state.ui.params[platformId]; }); };
        _this.hasLeads$ = _this.select(function (state) { return state.ui.params; })
            .pipe(map(function (params) {
            var status = true;
            if (!isNullOrUndefined(params.BITRIX24)) {
                status = params.BITRIX24.hasLeads;
            }
            return status;
        }));
        _this.destinationPlatformSections$ = combineLatest(_this.hasLeads$, _this.selectedDestinationPlatform$)
            .pipe(map(function (_a) {
            var hasLeads = _a[0], platform = _a[1];
            return platform.sections.filter(function (section) { return hasLeads ? true : section.code !== 'LEADS'; });
        }));
        return _this;
    }
    Object.defineProperty(PlatformsQuery.prototype, "selectedOriginPlatformCode", {
        get: function () {
            var code = this.getValue().ui.selectedOriginPlatform;
            return code;
        },
        enumerable: true,
        configurable: true
    });
    ;
    Object.defineProperty(PlatformsQuery.prototype, "selectedDestinationPlatformCode", {
        get: function () {
            var code = this.getValue().ui.selectedDestinationPlatform;
            return code;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PlatformsQuery.prototype, "requiredFields", {
        get: function () {
            return this.getValue().ui.requiredFields;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PlatformsQuery.prototype, "HasLeads", {
        get: function () {
            var state = this.getValue();
            var status = true;
            if (state.ui.selectedOriginPlatform === 'BITRIX_EXPORT' || state.ui.selectedDestinationPlatform === 'BITRIX_IMPORT') {
                if (state.ui && state.ui.params && state.ui.params.BITRIX24) {
                    status = state.ui.params.BITRIX24.hasLeads;
                }
            }
            return status;
        },
        enumerable: true,
        configurable: true
    });
    PlatformsQuery.ngInjectableDef = i0.defineInjectable({ factory: function PlatformsQuery_Factory() { return new PlatformsQuery(i0.inject(i1.PlatformsStore), i0.inject(i2.PlatformsRelationsQuery)); }, token: PlatformsQuery, providedIn: "root" });
    return PlatformsQuery;
}(QueryEntity));
export { PlatformsQuery };
