import { Injectable } from '@angular/core';

@Injectable()
export class AdvancedTextSearchService {

  constructor() { }

  private extraSpaceRemoveRegex: RegExp = new RegExp('[\\ ]{2,}', 'ig');
  private separatorNoiseRemover: RegExp = new RegExp('(\\ )?([\\-\\_\\=])(\\ )', 'ig');

  public TextContainsTerm(text: string, term: string): boolean {
    // Lower text & term to ignore case
    text = text.toLowerCase();
    term = term.toLowerCase();

    // Trim text & term
    text = text.trim();
    term = term.trim();

    // Remove double, tripple, etc spaces (replace with single space)
    text = text.replace(this.extraSpaceRemoveRegex, ' ');
    term = term.replace(this.extraSpaceRemoveRegex, ' ');

    // Clear off separators (
    // e.g. searching for "b-flex", found "b - flex" = NOT FOUND (but we should)
    text = text.replace(this.separatorNoiseRemover, '$2');
    term = term.replace(this.separatorNoiseRemover, '$2');

    // Search for term in text
    return (text.indexOf(term) >= 0);
  }
}
