var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { QueryEntity } from '@datorama/akita';
import { AffiliateMemberStattisticsByUserStore } from './affiliate-member-stattistics-by-user.store';
import * as i0 from "@angular/core";
import * as i1 from "./affiliate-member-stattistics-by-user.store";
var AffiliateMemberStattisticsByUserQuery = /** @class */ (function (_super) {
    __extends(AffiliateMemberStattisticsByUserQuery, _super);
    function AffiliateMemberStattisticsByUserQuery(store) {
        var _this = _super.call(this, store) || this;
        _this.store = store;
        return _this;
    }
    Object.defineProperty(AffiliateMemberStattisticsByUserQuery.prototype, "dateFrom$", {
        /**
         * Дата начала периода за который необходимо получить статистику (Observable)
         */
        get: function () {
            return this.select(function (state) { return state.ui.dateFrom; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AffiliateMemberStattisticsByUserQuery.prototype, "dateTo$", {
        /**
         * Дата окончания периода а который необходимо получить статистику (Observable)
         */
        get: function () {
            return this.select(function (state) { return state.ui.dateTo; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AffiliateMemberStattisticsByUserQuery.prototype, "dateFrom", {
        /**
         * Дата начала периода за который необходимо получить статистику
         */
        get: function () {
            return this.getValue().ui.dateFrom;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AffiliateMemberStattisticsByUserQuery.prototype, "dateTo", {
        /**
         * Дата окончания периода а который необходимо получить статистику
         */
        get: function () {
            return this.getValue().ui.dateTo;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AffiliateMemberStattisticsByUserQuery.prototype, "statistics$", {
        /**
         * Все данные о статистике по пользователюы
         */
        get: function () {
            return this.selectAll();
        },
        enumerable: true,
        configurable: true
    });
    AffiliateMemberStattisticsByUserQuery.ngInjectableDef = i0.defineInjectable({ factory: function AffiliateMemberStattisticsByUserQuery_Factory() { return new AffiliateMemberStattisticsByUserQuery(i0.inject(i1.AffiliateMemberStattisticsByUserStore)); }, token: AffiliateMemberStattisticsByUserQuery, providedIn: "root" });
    return AffiliateMemberStattisticsByUserQuery;
}(QueryEntity));
export { AffiliateMemberStattisticsByUserQuery };
