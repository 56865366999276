/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./update-ad-lite.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../components/lite/forms/ad-form-lite/ad-form-lite.component.ngfactory";
import * as i3 from "../../../components/lite/forms/ad-form-lite/ad-form-lite.component";
import * as i4 from "../../../../../api/services/ad-manager.service";
import * as i5 from "../../../../../api/services/automation-vk.service";
import * as i6 from "../../../../../api/services/automation-vk-slot.service";
import * as i7 from "../../../../../api/services/strategies.service";
import * as i8 from "@angular/router";
import * as i9 from "../../../services/groups-selector/groups-selector.service";
import * as i10 from "../../../../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i11 from "@angular/material/progress-spinner";
import * as i12 from "@angular/cdk/platform";
import * as i13 from "@angular/common";
import * as i14 from "@angular/platform-browser/animations";
import * as i15 from "./update-ad-lite.component";
import * as i16 from "../../../../../vk/services/url-exctractor.service";
var styles_UpdateAdLiteComponent = [i0.styles];
var RenderType_UpdateAdLiteComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UpdateAdLiteComponent, data: {} });
export { RenderType_UpdateAdLiteComponent as RenderType_UpdateAdLiteComponent };
function View_UpdateAdLiteComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-ad-form-lite", [], null, [[null, "AdSaved"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("AdSaved" === en)) {
        var pd_0 = (_co.SaveAd($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_AdFormLiteComponent_0, i2.RenderType_AdFormLiteComponent)), i1.ɵdid(1, 114688, null, 0, i3.AdFormLiteComponent, [i4.AdManagerService, i5.AutomationVkService, i6.AutomationVkSlotService, i7.StrategiesService, i8.Router, i1.ChangeDetectorRef, i9.GroupsSelectorService], { ad: [0, "ad"], targetingData: [1, "targetingData"], isUpdating: [2, "isUpdating"] }, { AdSaved: "AdSaved" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.ad; var currVal_1 = _co.adTargetingData; var currVal_2 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_UpdateAdLiteComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "update-loading-screen"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "mat-spinner", [["class", "mat-spinner mat-progress-spinner"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i10.View_MatSpinner_0, i10.RenderType_MatSpinner)), i1.ɵdid(2, 49152, null, 0, i11.MatSpinner, [i1.ElementRef, i12.Platform, [2, i13.DOCUMENT], [2, i14.ANIMATION_MODULE_TYPE], i11.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], null, null), (_l()(), i1.ɵted(-1, null, [" \u041F\u043E\u0434\u043E\u0436\u0434\u0438\u0442\u0435 \u0438\u0434\u0435\u0442 \u0437\u0430\u0433\u0440\u0443\u0437\u043A\u0430 \u0434\u0430\u043D\u043D\u044B\u0445 "]))], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2)._noopAnimations; var currVal_1 = i1.ɵnov(_v, 2).diameter; var currVal_2 = i1.ɵnov(_v, 2).diameter; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_UpdateAdLiteComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_UpdateAdLiteComponent_1)), i1.ɵdid(1, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["loadingScreen", 2]], null, 0, null, View_UpdateAdLiteComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.loading; var currVal_1 = i1.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_UpdateAdLiteComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-update-ad-lite", [], null, null, null, View_UpdateAdLiteComponent_0, RenderType_UpdateAdLiteComponent)), i1.ɵdid(1, 114688, null, 0, i15.UpdateAdLiteComponent, [i8.ActivatedRoute, i8.Router, i5.AutomationVkService, i16.UrlExctractorService, i4.AdManagerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UpdateAdLiteComponentNgFactory = i1.ɵccf("app-update-ad-lite", i15.UpdateAdLiteComponent, View_UpdateAdLiteComponent_Host_0, {}, {}, []);
export { UpdateAdLiteComponentNgFactory as UpdateAdLiteComponentNgFactory };
