/**
 * Типы платформ поддерживаемые платформой
 */
export enum PlatformsTypes {
  /**
   * Экспорт данных из системы amoCRM
   */
  AMO_CRM_EXPORT = 'AMO_CRM_EXPORT',

  /**
   * Импорт данных в систему amoCRM
   */
  AMO_CRM_IMPORT = 'AMO_CRM_IMPORT',

  /**
   * Экспорт данных из системы Битрикс24
   */
  BITRIX_EXPORT = 'BITRIX_EXPORT',

  /**
   * Импорт данных в системы Битрикс24
   */
  BITRIX_IMPORT = 'BITRIX_IMPORT',

  /**
   * Экспорт данных из системы Мегаплан
   */
  MEGAPLAN_EXPORT = 'MEGAPLAN_EXPORT',

  /**
   * Импорт данных в систему Мегаплан
   */
  MEGAPLAN_IMPORT = 'MEGAPLAN_IMPORT',

  /**
   * Экспорт данных из лид-форм социальной сети ВКонтакте
   */
  VK_LEAD_FORMS = 'VK_LEAD_FORMS',

  /**
   * Импорт данных в группы ретаргетинга социальной сети ВКонтакте
   */
  VK_RETARGETING_GROUPS = 'VK_RETARGETING_GROUPS',

  /**
   * Экспорт данных из лид-форм myTarget
   */
  MT_LEAD_FORMS = 'MT_LEAD_FORMS',

  /**
   * Импорт данных в группу ремаркетинга myTarget
   */
  MT_REMARKETING_GROUPS = 'MT_REMARKETING_GROUPS',

  /**
   * Импорт данных в систему UON Travel
   */
  UON_IMPORT = 'UON_IMPORT',

  /**
   * Экспорт данных из системы UON Travel
   */
  UON_EXPORT = 'UON_EXPORT',

  /**
   * Лид формы facebook
   */
  FB_LEAD_FORMS = 'FB_LEAD_FORMS',

  /**
   * Аудитории Facebook
   */
  FB_CUSTOM_AUDIENCES = 'FB_CUSTOM_AUDIENCES',

  /**
   * Экспорт данных из системы МойСклад
   */
  MOY_SKLAD_EXPORT = 'MOY_SKLAD_EXPORT',

  /**
   * Импорт данных в систему МойСклад
   */
  MOY_SKLAD_IMPORT = 'MOY_SKLAD_IMPORT',

  /**
   * Яндекс.Аудитории
   */
  YANDEX_AUDIENCES = 'YANDEX_AUDIENCES'
}
