<div class="dialog-header">
  <button mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="loading-screen">
  <div class="loading-screen__spinner">
    <mat-spinner [diameter]="30"></mat-spinner>
  </div>
  <div class="loading-screen__message">Подождите операция выполняется</div>
</div>
