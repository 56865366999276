import { ChangeDetectorRef, EventEmitter, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AdManagerService, AutomationVkService, AutomationVkSlotService, StrategiesService } from '../../../../../../api/services';
import { emojiCount, url, urlWithHttpOrHttps } from '../../../../validators/url-validator';
import { delay, flatMap } from 'rxjs/operators';
import { AdTargeting, APIReponseWrapperListCampaign, APIReponseWrapperListUserAutomationCabinetSlot, BidRange, Cabinet, Campaign, PostMonitorBackend_V2ModelsvkVKAPIGroupModel, UserAutomationCabinetSlot } from '../../../../../../api/models';
import { Observable } from 'rxjs/Observable';
import 'rxjs-compat/add/observable/empty';
import { DomainOnlyPipe } from '../../../../../../shared/pipes/domain-only.pipe';
import { MatVerticalStepper } from '@angular/material';
import { AccountViewModel } from '../../../../../models/view-models/account-view-model';
import { isNullOrUndefined } from 'util';
import * as moment from 'moment';
import { AdViewModel } from '../../../../../models/view-models/ad-view-model';
import { Router } from '@angular/router';
import { PromopostFormLiteComponent } from '../promopost-form-lite/promopost-form-lite.component';
import { TeaserFormLiteComponent } from '../teaser-form-lite/teaser-form-lite.component';
import * as Sentry from '@sentry/browser';
import { GroupsSelectorService } from '../../../../services/groups-selector/groups-selector.service';
import { VkGroupViewModel } from '../../../../models/vk-group.view-model';
var adSourceTypes;
(function (adSourceTypes) {
    adSourceTypes["VK_GROUP"] = "vk_group";
    adSourceTypes["WEB_SITE"] = "web_site";
})(adSourceTypes || (adSourceTypes = {}));
var AdFormLiteComponent = /** @class */ (function () {
    function AdFormLiteComponent(adsManagerService, automationVkService, automationVkSlotService, strategiesService, router, cd, groupsSelectorService) {
        this.adsManagerService = adsManagerService;
        this.automationVkService = automationVkService;
        this.automationVkSlotService = automationVkSlotService;
        this.strategiesService = strategiesService;
        this.router = router;
        this.cd = cd;
        this.groupsSelectorService = groupsSelectorService;
        this.isSubmitLoading = false;
        this.isUpdating = false;
        this.AdSaved = new EventEmitter();
        this.IsCampaignsLoading = false;
        this.errors = {
            format: 'Вы не выбрали формат объявления в пункте 1',
            teazer: 'Вы не ввели данные при создании объвления в пункте 2',
            promopost: 'Вы не ввели данные при создании объвления в пункте 2',
            targetingData: 'Вы не выбрали кому показывать объявление в пункте 3',
            campaignData: 'Вы не выбрали где сохранить объявление в пункте 4',
            rate: 'Вы не выбрали стоимость и способ показа объявления в пункте 5',
            moderationSettings: 'Вы не выбрали способ запуска и сохранения объявления в пункте 6'
        };
        this.adSources = [
            {
                title: 'На вашу группу в ВКонтакте',
                description: '',
                name: adSourceTypes.VK_GROUP
            },
            {
                title: 'На сайт',
                description: 'Вы можете уазать ссылку на ваш сайт',
                name: adSourceTypes.WEB_SITE
            }
        ];
        this.adFormats = [
            {
                title: 'Рекламная запись в новостной ленте',
                description: 'Объявление будет показываться в ВКонтакте в новостной ленте, на стенах сообществ и в рекламной сети.',
                image: 'assets/images/promopost.jpg',
                selected: true,
                value: 'promopost',
                ad_format: 9,
                min_cpm: 30,
                ad_platform: 'all',
                type: 'promoted_posts'
            },
            {
                title: 'Рекламное объявление на страницах ВКонтакте',
                description: 'Объявление будет показываться в ВКонтакте в левом рекламном блоке',
                image: 'assets/images/ad_teazer.jpg',
                selected: false,
                value: 'teazer',
                ad_format: 2,
                min_cpm: 1.2,
                ad_platform: '0',
                type: 'normal'
            },
        ];
        this.PROMOPOST_CAMPAIGN_NAME = 'Кампания PostMonitor - Запись в новостной ленте';
        this.TEAZER_CAMPAIGN_NAME = 'Кампания PostMonitor - Объявления на страницах ВКонтакте';
        this.currentResource = 'vk_group';
        this.lastGroupId = null;
        this.bidRanges = [];
        this.groupIdValidators = [Validators.required];
        this.IsPromopost = true;
        this.countOfOpenedGroups = 0;
        this.systemErrors = [];
        this.validatorsInfo = {
            promopost: {
                groupId: this.groupIdValidators,
                linkAddress: [
                    Validators.required,
                    url
                ],
                text: [
                    Validators.required,
                    Validators.minLength(1),
                    Validators.maxLength(16384),
                    emojiCount
                ],
                image: [
                    Validators.required,
                ]
            },
            teazer: {
                title: [
                    Validators.required,
                ],
                link: [
                    Validators.required,
                    url,
                ],
                image: [
                    Validators.required
                ]
            }
        };
    }
    Object.defineProperty(AdFormLiteComponent.prototype, "AccountId", {
        get: function () {
            return this.currentAccount.account_id + "_null";
        },
        enumerable: true,
        configurable: true
    });
    AdFormLiteComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.InitStepsFormErrors();
        this.LoadRanges();
        this.InitForm();
        this.LoadGroups()
            .pipe(flatMap(function () { return _this.LoadAccountsData(); }))
            .subscribe(function () {
            if (_this.groups && _this.groups.length > 0) {
                var firstGroupId = _this.groups[0].id;
                _this.SetSelectedGroup(firstGroupId, null);
                if (_this.groups.length === 1) {
                    _this.addGroupInfoToVkGroupAddTargetResource(_this.groups[0]);
                }
                _this.lastGroupId = firstGroupId;
            }
            if (_this.ad) {
                if (_this.IsAdLinkLeadForAdminGroup()) {
                    _this.currentResource = adSourceTypes.VK_GROUP;
                }
                else {
                    _this.currentResource = adSourceTypes.WEB_SITE;
                }
                _this.FillForm();
            }
        });
    };
    AdFormLiteComponent.prototype.InitStepsFormErrors = function () {
        var _this = this;
        this.stepper.selectionChange.subscribe(function (_a) {
            var selectedIndex = _a.selectedIndex;
            var triggerSelectedIndex = (_this.isUpdating) ? 1 : 2;
            if (selectedIndex >= triggerSelectedIndex) {
                var teazer = _this.getFormControl('teazer');
                var promopost = _this.getFormControl('promopost');
                console.log(teazer.valid, promopost.valid);
                _this.isAdValid = teazer.valid || promopost.valid;
                if (!_this.isAdValid) {
                    _this.displayedErrors = ['Вы не ввели данные при создании объвления в пункте 2'];
                    _this.LoadSystemErrors();
                }
                console.log(_this.displayedErrors, 'ERRORS');
            }
        });
    };
    AdFormLiteComponent.prototype.LoadSystemErrors = function () {
        this.systemErrors = [];
        console.log(this.HasAnyAdminCabinetAssgnedToSystem(), 'NO FUCKING ACCOUNT');
        if (!this.HasAnyAdminCabinetAssgnedToSystem()) {
            this.systemErrors.push('У вас нет ни одного рекламного кабинета. Вернитесь на шаг 3');
        }
        console.log((this.HasAnyOpenedGroups() && !this.IsAdForPromo()), this.HasAnyOpenedGroups(), this.IsAdForPromo());
        if (!(this.HasAnyOpenedGroups())) {
            if (this.IsAdForPromo()) {
                this.systemErrors.push('У вас нет ни одной открытой группы для публикации объявления.');
            }
        }
        if (!this.IsFormValid()) {
            this.systemErrors.push('Вы не верно заполнили данные объявления. Вернитесь на шаг 2');
        }
    };
    AdFormLiteComponent.prototype.InitAdTimers = function () {
        var _this = this;
        this.stepper.selectionChange.subscribe(function (_a) {
            var selectedIndex = _a.selectedIndex;
            _this.StartPromopostTimer(selectedIndex);
            _this.StartTeaserTimer(selectedIndex);
        });
    };
    AdFormLiteComponent.prototype.StartPromopostTimer = function (selectedIndex) {
        if (selectedIndex === 1 && this.IsSelectedFormatPromopost()) {
            this.promopostForm.StartImageErrorTimer();
        }
    };
    AdFormLiteComponent.prototype.StartTeaserTimer = function (selectedIndex) {
        if (selectedIndex === 1 && !this.IsSelectedFormatPromopost()) {
            this.teaserForm.StartImageErrorTimer();
        }
    };
    AdFormLiteComponent.prototype.GetSelectedFormatePreview = function () {
        return "assets/images/ad-format-preview-" + this.form.value.format + ".jpg";
    };
    AdFormLiteComponent.prototype.InitForm = function () {
        var _this = this;
        this.form = new FormGroup({
            format: new FormControl('promopost', [Validators.required]),
            promopost: new FormGroup({
                groupId: new FormControl(null, this.groupIdValidators),
                linkAddress: new FormControl(null, [Validators.required, urlWithHttpOrHttps]),
                linkTitle: new FormControl(null),
                text: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(650), emojiCount]),
                image: new FormControl('', [Validators.required])
            }),
            teazer: new FormGroup({
                groupId: new FormControl(),
                title: new FormControl('', [Validators.required]),
                link: new FormControl('', [Validators.required, url]),
                image: new FormControl('', [Validators.required])
            }),
            targetingData: new FormGroup({
                country: new FormControl(),
                cities: new FormControl(),
                sex: new FormControl(),
                ageFrom: new FormControl(),
                ageTo: new FormControl(),
                groups: new FormControl(),
                audience: new FormControl()
            })
        });
        var adFormContol = this.getFormControl('format');
        adFormContol.valueChanges.subscribe(function (data) {
            _this.ToggleValidationMode();
            console.log(data, 'IS PROMO POST ');
            _this.IsPromopost = data === 'promopost';
        });
        this.form.controls.promopost.controls.groupId
            .valueChanges
            .subscribe(function (groupId) {
            if (_this.countOfOpenedGroups > 0 && _this.groups && !_this.ad) {
                var newGroupId = parseInt(groupId, 10);
                var lastSelectedGroup = _this.findGroupById(_this.lastGroupId);
                _this.SetSelectedGroup(newGroupId, lastSelectedGroup);
                _this.lastGroupId = newGroupId;
            }
        });
        var promopostLinkControl = this.getFormControl('promopost', 'linkAddress');
        var teazerLinkControl = this.getFormControl('teazer', 'link');
        promopostLinkControl.valueChanges.subscribe(function () {
            _this.LoadTargetAfterChangeLinkValue();
        });
        teazerLinkControl.valueChanges.subscribe(function () {
            _this.LoadTargetAfterChangeLinkValue();
        });
        this.initTargetingData();
    };
    AdFormLiteComponent.prototype.LoadTargetAfterChangeLinkValue = function () {
        if (this.campaign) {
            this.GetTargetingStats()
                .subscribe();
        }
    };
    AdFormLiteComponent.prototype.findGroupById = function (lastGroupId) {
        console.log(this.groups, lastGroupId);
        return this.groups.find(function (group) { return group.id === lastGroupId; });
    };
    AdFormLiteComponent.prototype.findGroupByLink = function (groupLink) {
        var foundedGroup = this.groups.find(function (groupItem) { return groupLink.includes(groupItem.screen_name); });
        return foundedGroup;
    };
    AdFormLiteComponent.prototype.SelectFormat = function () {
        this.stepper.next();
    };
    AdFormLiteComponent.prototype.LoadGroups = function () {
        var _this = this;
        return this.adsManagerService
            .GetAdminGroups()
            .debounceTime(1000)
            .do(function (groups) {
            _this.groups = groups;
            console.log('Sending Groups');
            Sentry.addBreadcrumb({
                category: 'ad-form-lite',
                message: '',
                data: groups
            });
            _this.countOfOpenedGroups = _this.groups.filter(function (group) { return group.is_closed === 0; }).length;
            if (_this.ad) {
                var link_1 = _this.ad.GetTeazerVkLink();
                if (link_1) {
                    var teazerGroup = _this.groups.find(function (group) { return link_1.includes(group.screen_name); });
                    if (teazerGroup) {
                        var teazerGroupIdControl = _this.getFormControl('teazer', 'groupId');
                        teazerGroupIdControl.setValue(teazerGroup.id);
                    }
                }
            }
        });
    };
    AdFormLiteComponent.prototype.LoadSlots = function () {
        var _this = this;
        return this.automationVkSlotService.GetSlots()
            .do(function (slotsResponse) {
            _this.slots = slotsResponse.data;
        });
    };
    AdFormLiteComponent.prototype.LoadAccounts = function () {
        var _this = this;
        return this.automationVkService.GetAccounts()
            .debounceTime(1000)
            .do(function (accountsResponse) {
            _this.accounts = accountsResponse.data
                .filter(function (account) { return account.access_role === 'admin'; })
                .map(function (account, index) { return _this.ConvertAccountToViewModel(account, index); });
            _this.currentAccount = _this.accounts[0];
        });
    };
    AdFormLiteComponent.prototype.ConvertAccountToViewModel = function (account, index) {
        console.log(this);
        var accountViewModel = new AccountViewModel(account.account_id, account.account_name, account.access_role, account.account_status, account.account_type, !this.IsAccountAssignedToSystem(account), account.client_id, index);
        return accountViewModel;
    };
    AdFormLiteComponent.prototype.LoadCampaign = function () {
        var _this = this;
        return this.automationVkService.GetCampaigns({
            clientId: this.currentAccount.client_id,
            accountId: this.currentAccount.account_id
        })
            .debounceTime(1000)
            .do(function (campaignsResponse) {
            _this.campaigns = campaignsResponse.data;
            _this.campaign = _this.CurrentCampaign();
        });
    };
    AdFormLiteComponent.prototype.CurrentCampaign = function () {
        var _this = this;
        return this.campaigns.find(function (campaign) { return campaign.name === _this.CurrentCampaignName; });
    };
    AdFormLiteComponent.prototype.SetSelectedGroup = function (selectedGroupId, lastSelectedGroup) {
        if (lastSelectedGroup === void 0) { lastSelectedGroup = null; }
        var selectedGroup = this.groups.find(function (group) { return group.id === selectedGroupId; });
        var promopostFormGroup = this.form.controls.promopost;
        var lastSelectedGroupTitle = (lastSelectedGroup) ? lastSelectedGroup.name : '';
        promopostFormGroup.controls.groupId.setValue(selectedGroupId, { emitEvent: false });
        this.SetPromopostLinkTitle(selectedGroup.name, lastSelectedGroupTitle);
        if (this.currentResource === adSourceTypes.VK_GROUP) {
            this.SetPromopostLinkAddress("https://vk.com/" + selectedGroup.screen_name);
            console.log(selectedGroupId, 'LOG SELECTED GROUP ID');
            this.SetSelectedGroupLinkForTeaser(selectedGroupId);
            this.SetSelectedGroupIdToTeazer(selectedGroupId);
        }
    };
    AdFormLiteComponent.prototype.SetPromopostLinkTitle = function (selectedGroupTitle, lastSelectedGroupTitle) {
        if (lastSelectedGroupTitle === void 0) { lastSelectedGroupTitle = ''; }
        var promopostFormGroupLinkTitleControl = this.form.controls.promopost.get('linkTitle');
        if (promopostFormGroupLinkTitleControl.value === null || promopostFormGroupLinkTitleControl.value === lastSelectedGroupTitle) {
            promopostFormGroupLinkTitleControl
                .setValue(selectedGroupTitle);
        }
    };
    AdFormLiteComponent.prototype.SetPromopostLinkAddress = function (linkUrl) {
        var promopostFormGroupLinkAddressControl = this.form.controls.promopost.get('linkAddress');
        promopostFormGroupLinkAddressControl.setValue(linkUrl);
    };
    AdFormLiteComponent.prototype.addGroupInfoToVkGroupAddTargetResource = function (group) {
        var targetResource = this.adSources.find(function (x) { return x.name === 'vk_group'; });
        targetResource.title = "\u041D\u0430 \u0432\u0430\u0448\u0443 \u0433\u0440\u0443\u043F\u043F\u0443 \u0432 \u0412\u041A\u043E\u043D\u0442\u0430\u043A\u0442\u0435. \u041D\u0430 \u0433\u0440\u0443\u043F\u043F\u0443 <a target=\"_blank\" rel=\"noopener nofolow\" href=\"https://vk.com/" + group.screen_name + "\">" + group.name + "</a>";
    };
    AdFormLiteComponent.prototype.SetSelectedGroupLinkForTeaser = function (groupId) {
        var selectedGroup = this.groups.find(function (group) { return group.id === groupId; });
        var teazerLinkControl = this.getFormControl('teazer', 'link');
        teazerLinkControl.setValue("https://vk.com/" + selectedGroup.screen_name);
    };
    AdFormLiteComponent.prototype.SetSelectedGroupIdToTeazer = function (groupId) {
        var teazerGroupIdControl = this.getFormControl('teazer', 'groupId');
        teazerGroupIdControl.setValue(groupId);
    };
    AdFormLiteComponent.prototype.getFormControl = function (groupName, controlKey) {
        if (controlKey === void 0) { controlKey = null; }
        var teazerFormControl = this.form.controls[groupName];
        if (controlKey) {
            teazerFormControl = teazerFormControl.get(controlKey);
        }
        if (!teazerFormControl) {
            throw new Error("Control with key " + controlKey + " is not defined");
        }
        return teazerFormControl;
    };
    AdFormLiteComponent.prototype.initTargetingData = function () {
        if (this.targetingData) {
            console.log(this.targetingData, 'WTF TARGETING');
            var targetingData = {
                country: this.targetingData.country,
                cities: this.targetingData.cities ? this.targetingData.cities.split(',') : [0],
                groups: this.targetingData.groups,
                ageFrom: this.targetingData.age_from,
                ageTo: this.targetingData.age_to,
                sex: parseInt(this.targetingData.sex, 10),
                audience: this.targetingData.count
            };
            this.setTargetingData(targetingData);
            this.LoadGroupsInfo();
        }
        else {
            this.setTargetingData({
                country: 1,
                cities: [0],
                sex: 0,
                groups: '',
                ageFrom: 0,
                ageTo: 0,
                audience: 0
            });
        }
    };
    AdFormLiteComponent.prototype.setTargetingData = function (targetingData) {
        var targetingDataControlGroup = this.getFormControl('targetingData');
        targetingDataControlGroup.setValue(targetingData);
    };
    AdFormLiteComponent.prototype.IsSelectedFormatPromopost = function () {
        var adFormatControl = this.getFormControl('format');
        return adFormatControl.value === 'promopost';
    };
    Object.defineProperty(AdFormLiteComponent.prototype, "CurrentAdFormat", {
        get: function () {
            var adFormatControl = this.getFormControl('format');
            return this.adFormats.find(function (adFormat) { return adFormat.value === adFormatControl.value; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdFormLiteComponent.prototype, "CurrentCampaignName", {
        get: function () {
            var currentCampaignName = '';
            console.log('Is Promopost', this.IsSelectedFormatPromopost());
            if (this.IsSelectedFormatPromopost()) {
                currentCampaignName = this.PROMOPOST_CAMPAIGN_NAME;
            }
            else {
                currentCampaignName = this.TEAZER_CAMPAIGN_NAME;
            }
            return currentCampaignName;
        },
        enumerable: true,
        configurable: true
    });
    AdFormLiteComponent.prototype.CreateCampaignIfNotExists = function () {
        var _this = this;
        var result = Observable.of({});
        if (!this.CurrentCampaign()) {
            result = this.CreateCampaignWithName(this.CurrentCampaignName)
                .pipe(delay(1000), flatMap(function () { return _this.LoadCampaign(); }));
        }
        return result;
    };
    AdFormLiteComponent.prototype.CreateCampaignWithName = function (name) {
        return this.adsManagerService.CreateCampaign({
            accountId: this.currentAccount.account_id,
            campaigns: [
                {
                    type: this.CurrentAdFormat.type,
                    name: name,
                    status: 1
                },
            ]
        });
    };
    AdFormLiteComponent.prototype.GetTargetingStats = function () {
        var linkUrl = this.GetLinkUrl();
        var TargetingControl = this.getFormControl('targetingData');
        var criteria = TargetingControl.value;
        console.log('GROUPS', typeof criteria.groups);
        return this.adsManagerService.GetTargetingStats({
            adFormat: this.CurrentAdFormat.ad_format,
            accountId: this.currentAccount.account_id,
            linkUrl: linkUrl,
            clientId: null,
            linkDomain: new DomainOnlyPipe().transform(linkUrl),
            criteria: {
                age_to: criteria.age_to,
                age_from: criteria.age_from,
                groups: (typeof criteria.groups === 'string') ? criteria.groups : criteria.groups.join(),
                country: criteria.country,
                cities: criteria.cities.join(),
                sex: criteria.sex
            }
        })
            .do(function (targetingData) {
            TargetingControl.controls.audience.setValue(targetingData.audience_count);
        });
    };
    AdFormLiteComponent.prototype.GetLinkUrl = function () {
        var IsPromopost = this.IsSelectedFormatPromopost();
        var linkUrl = '';
        if (IsPromopost) {
            linkUrl = this.GetPromopostLinkUrl();
        }
        else {
            linkUrl = this.GetTeazerLinkUrl();
        }
        return linkUrl;
    };
    AdFormLiteComponent.prototype.GetPromopostLinkUrl = function () {
        var promopostControl = this.getFormControl('promopost', 'linkAddress');
        console.log(promopostControl.value, 'PROMOPOST TARGETING URL');
        return promopostControl.value;
    };
    AdFormLiteComponent.prototype.GetTeazerLinkUrl = function () {
        var teazerControl = this.getFormControl('teazer', 'link');
        return teazerControl.value;
    };
    AdFormLiteComponent.prototype.GetTargetingCities = function (criteria) {
        return criteria.cities.join();
    };
    AdFormLiteComponent.prototype.IsAccountAssignedToSystem = function (account) {
        if (account) {
            var accountId_1 = account.account_id;
            var clientId_1 = account.client_id;
            var slot = null;
            if (clientId_1) {
                slot = this.slots.find(function (slotsItem) { return slotsItem.bindedCabinetId === accountId_1 && slotsItem.bindedClientId === clientId_1; });
            }
            else {
                slot = this.slots.find(function (slotsItem) { return slotsItem.bindedCabinetId === accountId_1; });
            }
            console.log(slot);
            return !isNullOrUndefined(slot);
        }
        else {
            return false;
        }
    };
    AdFormLiteComponent.prototype.BindCabinet = function ($event) {
        this.LoadAccountsData()
            .subscribe();
    };
    AdFormLiteComponent.prototype.LoadAccountsData = function () {
        var _this = this;
        return this.LoadSlots()
            .pipe(flatMap(function () { return _this.LoadAccounts(); }));
    };
    AdFormLiteComponent.prototype.NextWithCheckingCampaigns = function () {
        var _this = this;
        this.IsCampaignsLoading = true;
        console.log(this.IsCampaignsLoading);
        this.LoadCampaignsAndCheckForExistingSystemCampaign()
            .subscribe(function () {
            _this.stepper.next();
            _this.IsCampaignsLoading = false;
        });
    };
    AdFormLiteComponent.prototype.LoadCampaignsAndCheckForExistingSystemCampaign = function () {
        var _this = this;
        return this.LoadCampaign()
            .pipe(flatMap(function () { return _this.CreateCampaignIfNotExists(); }));
    };
    AdFormLiteComponent.prototype.HasAssignedAccounts = function () {
        if (this.accounts && this.slots && this.slots.length > 0) {
            var countOfAssignedSlots = this.slots
                .filter(function (x) { return x.bindedCabinetId !== null; })
                .length;
            var adminCabinet_1 = this.accounts.find(function (account) { return account.account_role === 'admin'; });
            if (adminCabinet_1) {
                var slotWithAdminCabinet = this.slots
                    .find(function (slot) { return slot.bindedCabinetId === adminCabinet_1.account_id && slot.bindedClientId === adminCabinet_1.client_id; });
                if (slotWithAdminCabinet) {
                    return true;
                }
                else {
                    console.warn('Кабинет с доступом администартора не подключен к системе');
                    return false;
                }
            }
            console.warn('Нет кабинета с доступом администратора');
            return false;
            // let hasAssignedAdminCabinet = false;
            // const hasAssignedSlots = countOfAssignedSlots > 0;
            // const adminNotAgencyCabinets = this.accounts.filter(account => account.account_role === 'admin' && account.account_type !== 'agency');
            // adminNotAgencyCabinets.forEach(adminCabinet => {
            //   const slotWithAdminCabinet = this.slots.find(slot => slot.bindedCabinetId === adminCabinet.account_id);
            //   hasAssignedAdminCabinet = slotWithAdminCabinet !== null;
            // });
            // const hasAdminAccounts = (adminNotAgencyCabinets) ? adminNotAgencyCabinets.length > 0 : false;
            // return hasAssignedSlots && hasAdminAccounts && hasAssignedAdminCabinet;
        }
        else {
            return false;
        }
    };
    AdFormLiteComponent.prototype.FinishEditingAd = function () {
        console.log('HasAssignedAccounts', this.HasAssignedAccounts());
        if (this.HasAssignedAccounts()) {
            this.NextWithCheckingCampaigns();
        }
        else {
            this.stepper.next();
        }
    };
    AdFormLiteComponent.prototype.GenerateGeneralData = function () {
        var promopost = this.getFormControl('promopost').value;
        var adData = {
            adSpecifications: [
                this.GenerateSpecification()
            ],
            accountId: this.currentAccount.account_id,
            text: promopost.text,
            groupId: promopost.groupId,
            postId: (this.ad && this.ad.ad_format === 9) ? this.ad.GetPostId() : 0,
            linkTitle: promopost.linkTitle,
            linkUrl: promopost.linkAddress
        };
        return adData;
    };
    AdFormLiteComponent.prototype.GetAdImage = function () {
        var promopostImage = this.getFormControl('promopost', 'image').value;
        var teazerImage = this.getFormControl('teazer', 'image').value;
        var image = '';
        if (this.IsSelectedFormatPromopost()) {
            image = promopostImage;
        }
        else {
            image = teazerImage;
        }
        return image[0];
    };
    AdFormLiteComponent.prototype.GetLink = function () {
        var promopostLinkUrl = this.getFormControl('promopost', 'linkAddress').value;
        var teazerlinkUrl = this.getFormControl('teazer', 'link').value;
        var link = '';
        if (this.IsSelectedFormatPromopost()) {
            link = promopostLinkUrl;
        }
        else {
            link = teazerlinkUrl;
        }
        return link;
    };
    AdFormLiteComponent.prototype.GenerateSpecification = function () {
        var currentBidRange = this.GetCurrentBidRange();
        var formatAd = this.CurrentAdFormat;
        var targetingData = this.getFormControl('targetingData').value;
        var campaign_id = this.campaign.id;
        var teazer = this.getFormControl('teazer').value;
        var image = this.GetAdImage();
        var link_url = this.GetLink();
        var link_domain = new DomainOnlyPipe().transform(link_url);
        console.log(targetingData, 'AD Format');
        var data = {
            image: image,
            campaign_id: campaign_id,
            link_url: link_url,
            link_domain: link_domain,
            ad_format: formatAd.ad_format,
            age_from: targetingData.ageFrom,
            age_to: targetingData.ageTo,
            cities: targetingData.cities.filter(function (cityId) { return cityId > 0; }).join(),
            country: targetingData.country,
            sex: parseInt(targetingData.sex, 10),
            groups: targetingData.groups,
            title: teazer.title,
            cpm: currentBidRange.cpmMin / 100,
            cost_type: 1,
            impressions_limited: (formatAd.ad_format === 2) ? 1 : null,
            impressions_limit: (formatAd.ad_format === 9) ? 1 : null,
            category1_id: 126,
            name: "\u041D\u043E\u0432\u043E\u0435 \u043E\u0431\u044A\u044F\u0432\u043B\u0435\u043D\u0438\u0435 " + moment(new Date()).format('DD.MM.YYYY HH:mm:ss')
        };
        if (this.isUpdating) {
            data['attachments'] = '';
        }
        return data;
    };
    AdFormLiteComponent.prototype.LoadRanges = function () {
        var _this = this;
        this.strategiesService.GetBidRanges()
            .subscribe(function (bidRanges) {
            _this.bidRanges = bidRanges;
        });
    };
    AdFormLiteComponent.prototype.GetCurrentBidRange = function () {
        var _this = this;
        var currentBidRange = this.bidRanges.find(function (bidRange) { return bidRange.adFormat === _this.CurrentAdFormat.ad_format; });
        return currentBidRange;
    };
    AdFormLiteComponent.prototype.SaveAd = function () {
        var _this = this;
        if (this.CanSaveAd()) {
            if (!this.campaign) {
                this.LoadCampaignsAndCheckForExistingSystemCampaign()
                    .subscribe(function () {
                    _this.AdSaved.emit(_this.GenerateGeneralData());
                });
            }
            else {
                this.AdSaved.emit(this.GenerateGeneralData());
            }
        }
        else {
            this.LoadSystemErrors();
        }
    };
    AdFormLiteComponent.prototype.FillForm = function () {
        var _this = this;
        var teaserControl = this.getFormControl('teazer');
        var promopostControl = this.getFormControl('promopost');
        var format = this.getFormControl('format');
        var formatValue = this.GetFormatByAdFormat(this.ad.ad_format);
        format.setValue(formatValue.value);
        if (this.IsSelectedFormatPromopost()) {
            if (this.groups) {
                console.log(this.ad.GetAdGroupId());
                var groupId = this.findGroupById(-this.ad.GetAdGroupId());
                console.log(groupId, ' GROUPasdasd');
            }
            promopostControl.setValue({
                text: this.ad.wall.text,
                linkAddress: this.ad.GetAdAttachmentLinkUrl(),
                linkTitle: this.ad.GetAdAttachmentLinkTitle(),
                groupId: -this.ad.GetAdGroupId(),
                image: [this.ad.image_src]
            }, { emitEvent: false, onlySelf: true });
        }
        else {
            var group1 = null;
            if (this.groups) {
                console.log(this.groups, this.ad.link_url);
                group1 = this.groups.find(function (group) { return "https://vk.com/" + group.screen_name === _this.ad.link_url; });
            }
            teaserControl.setValue({
                title: this.ad.title,
                link: this.ad.link_url,
                image: [this.ad.image_src],
                groupId: group1 ? group1.id : ''
            });
        }
    };
    AdFormLiteComponent.prototype.GetFormatByAdFormat = function (ad_format) {
        var format = this.adFormats.find(function (adFormat) { return adFormat.ad_format === ad_format; });
        return format;
    };
    AdFormLiteComponent.prototype.getPromopostGroupByLink = function () {
        var promopostLink = this.ad.link_url.replace('https://vk.com/wall-', '');
        var groupId = promopostLink.split('_')[0];
        return groupId;
    };
    AdFormLiteComponent.prototype.IsAdLinkLeadForAdminGroup = function () {
        var isAdminGroupLead = false;
        console.log(isAdminGroupLead, this.ad.link_url.includes('vk.com'));
        if (this.ad.link_url.includes('vk.com')) {
            var adminGroup = this.findGroupByLink(this.ad.getOwnerLink());
            console.log(adminGroup, 'ADMIN GROUP');
            if (adminGroup) {
                isAdminGroupLead = true;
            }
            else {
                isAdminGroupLead = false;
            }
        }
        else {
            isAdminGroupLead = false;
        }
        return isAdminGroupLead;
    };
    AdFormLiteComponent.prototype.EnableGroupValidation = function () {
        var teazerControl = this.getFormControl('teazer', 'groupId');
        teazerControl.setValidators(this.groupIdValidators);
    };
    AdFormLiteComponent.prototype.DisableGroupValidation = function () {
        var teazerControl = this.getFormControl('teazer', 'groupId');
        teazerControl.setValidators(null);
    };
    AdFormLiteComponent.prototype.OnChangeCurrentResource = function () {
        if (this.currentResource === adSourceTypes.VK_GROUP) {
            this.SetAdLink();
            this.EnableGroupValidation();
        }
        else {
            this.DisableGroupValidation();
        }
    };
    AdFormLiteComponent.prototype.SetAdLink = function () {
        var linkControl = null;
        if (this.IsPromopost) {
            linkControl = this.getFormControl('promopost', 'linkAddress');
        }
        else {
            linkControl = this.getFormControl('teazer', 'link');
        }
        if (this.groups && this.groups.length > 0) {
            var firstGroupScreenName = this.groups[0].screen_name;
            var groupLinkUrl = "https://vk.com/" + firstGroupScreenName;
            linkControl.setValue(groupLinkUrl);
        }
    };
    AdFormLiteComponent.prototype.isPromopostValid = function () {
        var promopostControl = this.getFormControl('promopost');
        return promopostControl.valid;
    };
    AdFormLiteComponent.prototype.isTeazerValid = function () {
        var teazerControl = this.getFormControl('teazer');
        return teazerControl.valid;
    };
    AdFormLiteComponent.prototype.IsAdValid = function () {
        var status = false;
        if (this.IsSelectedFormatPromopost()) {
            status = this.isPromopostValid();
        }
        else {
            status = this.isTeazerValid();
        }
        return status;
    };
    AdFormLiteComponent.prototype.getErrors = function () {
        var _this = this;
        var errorsMessages = Object.keys(this.form.controls)
            .map(function (key) { return ({ key: key, control: _this.form.controls[key] }); })
            .filter(function (_a) {
            var key = _a.key, control = _a.control;
            return control.invalid && key;
        })
            .map(function (_a) {
            var key = _a.key;
            return ({ key: key, message: _this.errors[key] });
        });
        if (this.form.value.format === 'promopost') {
            errorsMessages = errorsMessages
                .filter(function (x) { return x.key !== 'teazer'; });
        }
        else {
            errorsMessages = errorsMessages
                .filter(function (x) { return x.key !== 'promopost'; });
        }
        return errorsMessages
            .map(function (x) { return x.message; });
    };
    AdFormLiteComponent.prototype.ToggleValidationMode = function () {
        console.log('Validation ToggleD');
        if (this.IsSelectedFormatPromopost()) {
            this.EnableValidationPromopost();
        }
        else {
            this.EnableVaidationTeaser();
        }
        // this.cd.();
    };
    AdFormLiteComponent.prototype.EnableValidationPromopost = function () {
        this.EnableFormValidators('promopost');
        this.DisableFormValidators('teazer');
    };
    AdFormLiteComponent.prototype.EnableVaidationTeaser = function () {
        this.EnableFormValidators('teazer');
        this.DisableFormValidators('promopost');
    };
    AdFormLiteComponent.prototype.EnableFormValidators = function (formName) {
        var _this = this;
        var formValidators = this.validatorsInfo[formName];
        Object.keys(formValidators)
            .map(function (formFieldKey) { return ({ key: formFieldKey, validators: formValidators[formFieldKey] }); })
            .forEach(function (_a) {
            var key = _a.key, validators = _a.validators;
            var formFieldControl = _this.getFormControl(formName, key);
            formFieldControl.setValidators(validators);
        });
    };
    AdFormLiteComponent.prototype.DisableFormValidators = function (formName) {
        var _this = this;
        var formValidators = this.validatorsInfo[formName];
        Object.keys(formValidators)
            .forEach(function (key) {
            var formFieldControl = _this.getFormControl(formName, key);
            formFieldControl.setValidators(null);
            formFieldControl.updateValueAndValidity();
        });
    };
    AdFormLiteComponent.prototype.CanSaveAd = function () {
        var hasAnyAdminAccount = this.HasAnyAdminCabinetAssgnedToSystem();
        var hasAnyOpenedGroups = this.HasAnyOpenedGroups();
        var isAdPromo = this.IsAdForPromo();
        var isFormValid = this.IsFormValid();
        console.log(hasAnyAdminAccount, hasAnyOpenedGroups, isFormValid, 'CAN BE SAVED');
        return hasAnyAdminAccount && (hasAnyOpenedGroups || !isAdPromo) && isFormValid;
    };
    AdFormLiteComponent.prototype.IsFormValid = function () {
        var teazerFormControl = this.getFormControl('teazer');
        var promopostFormControl = this.getFormControl('promopost');
        return (teazerFormControl.valid || promopostFormControl.valid);
    };
    AdFormLiteComponent.prototype.HasAnyOpenedGroups = function () {
        return this.countOfOpenedGroups > 0;
    };
    AdFormLiteComponent.prototype.HasAnyAdminCabinetAssgnedToSystem = function () {
        console.log(this.currentAccount);
        var hasAnyAdminAccount = this.currentAccount !== null;
        var AssignedToSystem = this.IsAccountAssignedToSystem(this.currentAccount);
        return hasAnyAdminAccount && AssignedToSystem;
    };
    AdFormLiteComponent.prototype.LoadGroupsInfo = function () {
        var _this = this;
        if (this.targetingData.groups) {
            var groupsIds = this.targetingData.groups
                .split(',')
                .map(function (groupId) { return parseInt(groupId, 10); });
            this.adsManagerService
                .GetGroupsById(groupsIds)
                .subscribe(function (groups) {
                var groupsToSelect = groups.map(function (group) { return new VkGroupViewModel().fill(group); });
                _this.groupsSelectorService.SelectGroups(groupsToSelect);
            });
        }
    };
    AdFormLiteComponent.prototype.IsAdForPromo = function () {
        var isAdForPromo = false;
        var formatControl = this.getFormControl('format');
        if (formatControl) {
            isAdForPromo = formatControl.value === 'promopost';
        }
        return isAdForPromo;
    };
    return AdFormLiteComponent;
}());
export { AdFormLiteComponent };
