import {Injectable} from '@angular/core';
import {UtmExtractorService} from '../../shared/services/utm-extractor.service';
import {WallPost} from '../../api/models/wall-post';
import {WallLink} from '../../api/models/wall-link';
import {WallPrettyCards} from '../../api/models/wall-pretty-cards';
import {WallCard} from '../../api/models/wall-card';
import {UtmMark} from '../../automation/models/utm-mark';

@Injectable()
export class VkUtmExtractorService {

    constructor(private utmExtractor: UtmExtractorService) {
    }

    public ExtractUtm(post: WallPost): Array<string> {
        if (!post) {
            return null;
        }

        // Extract UTM from TEXT
        const TextUTM = this.utmExtractor.ExtractUtm(post.text);

        const marks = [];
        marks.push.apply(marks, TextUTM);

        // Extract UTM from attachments
        // Current procssed: link, carousel (pretty_cards)
        if (post.attachments) {
            post.attachments.forEach(attachment => {
                // Exctract from link
                if (attachment.link) {
                    marks.push.apply(marks, this.ExtractUTMFromLink(attachment.link));

                } else if (attachment.pretty_cards) {
                    marks.push.apply(marks, this.ExtractUTMFromPrettyCards(attachment.pretty_cards));
                }

                // TODO Decide if we should process photo, video & animation
            });
        }

        return marks;
    }

    private ExtractUTMFromLink(link: WallLink): Array<UtmMark> {
        // Check if  all necessary properties are provided
        if (!link) {
            console.error(`ExtractUTMFromLink ERROR: link is not presented!`);
            return [];
        }

        return this.utmExtractor.ExtractUtm(link.url);
    }

    private ExtractUTMFromPrettyCards(PrettyCards: WallPrettyCards): Array<UtmMark> {
        // Check if  all necessary properties are provided
        if (!PrettyCards || !PrettyCards.cards) {
            console.error(`ExtractUTMFromPrettyCards ERROR: PrettyCards or PrettyCards.cards are not presnted!`);
            return [];
        }

        const marks = [];

        PrettyCards.cards.forEach((card: WallCard) => {
            // Extract UTM from card's url
            const cardUtmMark = this.utmExtractor.ExtractUtm(card.link_url);

            // If we found some UTM - append it to previously found UTM
            if (cardUtmMark) {
                marks.push.apply(marks, cardUtmMark);
            }
        });

        return marks;
    }
}
