<ng-container *ngIf="isVisible">
  <div class="new-modal-wrapper">
    <div class="new-modal-container">
      <div class="new-modal-card">
        <div class="new-modal-header" [ngStyle]='{"background-color": headerBackgroundColor}'>
          <ng-content select=".new-modal-header-content"></ng-content>
        </div>
        <div class="new-modal-card__content">
          <ng-content select=".new-modal-body-content"></ng-content>
        </div>
        <div class="new-modal-card__content">
          <div class="new-modal-bottom-button-strip">
            <ng-content select=".new-modal-buttons"></ng-content>
          </div>
        </div>
      </div>

      <ng-container *ngIf="showCloseButton">
        <div class="new-modal-close-container" (click)="onCloseButtonClick.emit(true)">
          <i class="icon ion-md-close"></i>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>
