<ng-container *ngIf="!loading; else loadingScreen">
  <form [formGroup]="form" class="form">
    <h2 class="form__title">Подключение U-ON.Travel</h2>
    <vk-form-field orientation="vertical" controlsWidth="100%">
      <vk-label>
        Адрес личного кабинета U-ON.Travel
        <app-suggestion-info code="UON_TRAVEL_DOMAIN"></app-suggestion-info>
      </vk-label>
      <input type="text" formControlName="domain" vkInput placeholder="Введите адрес">
      <vk-error validator="required">Вы не ввели домен uOnTravel</vk-error>
    </vk-form-field>


    <vk-form-field orientation="vertical" controlsWidth="100%">
      <vk-label>
        Ключ API
        <app-suggestion-info code="UON_TRAVEL_CRM_API_KEY"></app-suggestion-info>
      </vk-label>
      <input type="text" formControlName="apiKey" vkInput placeholder="Введите ключ">
      <vk-error validator="required">Вы не ввели ключ</vk-error>
    </vk-form-field>

    <button class="vk-standart-button vk-standart-button-strip" (click)="Assign()" [disabled]="form.invalid">
      Подключить U-ON.Travel
    </button>
  </form>
</ng-container>

<ng-template #loadingScreen>
  <app-loading-screen>
    Подождите, идет подключение Мегаплан
  </app-loading-screen>
</ng-template>
