<mat-vertical-stepper>
  <ng-template matStepperIcon="edit" let-index="index">
    {{index + 1}}
  </ng-template>
  <mat-step *ngIf="!IsUpdate">
    <div *matStepLabel>Выберите формат объявления</div>
    <div>
      <app-new-ad-format-step></app-new-ad-format-step>
      <button class="vk-standart-button" matStepperNext>Далее</button>
    </div>
  </mat-step>

  <ng-container *ngIf="IsPromopost; else teazerForm">
    <mat-step>
      <div *matStepLabel>Создайте объявление</div>
      <div>
        <app-new-promopost-form-pro></app-new-promopost-form-pro>
        <button class="vk-standart-button" matStepperNext [disabled]="!IsValidAd">Далее</button>
      </div>
    </mat-step>
  </ng-container>

  <ng-template #teazerForm>
    <mat-step>
      <div *matStepLabel>Создайте объявление</div>
      <div>
        <app-new-teazer-form-pro></app-new-teazer-form-pro>
        <button class="vk-standart-button" matStepperNext [disabled]="!IsValidAd">Далее</button>
      </div>
    </mat-step>
  </ng-template>

  <mat-step *ngIf="ShowActiveAccounts && !IsUpdate">
    <div *matStepLabel>Активируйте кабинет</div>
    <div>
      <app-new-accounts-manager
        (bindingChanged)="Bindend()"
        [slots]="slots"
        [accounts]="accounts"
      ></app-new-accounts-manager>
      <br>
      <button class="vk-standart-button" matStepperNext [disabled]="!HasAssignedAccounts">Далее</button>
    </div>
  </mat-step>

  <mat-step *ngIf="!IsUpdate">
    <div *matStepLabel>Выберите где сохранить объявление</div>
    <app-new-account-settings-step></app-new-account-settings-step>
    <button class="vk-standart-button" matStepperNext [disabled]="!IsCampaignsValid">Далее</button>
  </mat-step>

  <mat-step>
    <div *matStepLabel>Выберите свою аудиторию</div>
    <app-new-targeting-settings></app-new-targeting-settings>
    <button class="vk-standart-button" matStepperNext [disabled]="!IsValidTargeting">Далее</button>
  </mat-step>

  <mat-step>
    <div *matStepLabel>Выберите стоимость и способ показа объявления</div>
    <app-new-price-and-strategy-settings></app-new-price-and-strategy-settings>
    <ng-container *ngIf="!IsUpdate || IsAdLayoutDirty; else createAdComponent">
      <button class="vk-standart-button" matStepperNext>Далее</button>
    </ng-container>
  </mat-step>

  <mat-step *ngIf="!IsUpdate || IsAdLayoutDirty">
    <div *matStepLabel>Выберите способ запуска и сохранения объявления</div>
    <app-moderation-settings></app-moderation-settings>
    <ng-container [ngTemplateOutlet]="createAdComponent"></ng-container>
  </mat-step>
</mat-vertical-stepper>

<ng-template #createAdComponent>
  <ng-container *ngFor="let error of errors">
    <div class="vk-error">
      {{error}}
    </div>
  </ng-container>
  <ng-container *ngIf="!createAdLoading; else loadingScreen">
    <button class="vk-standart-button" (click)="CreateAd()">
      <ng-container *ngIf="!IsUpdate; else isUpdateCreateButtonText">Создать объявления</ng-container>
      <ng-template #isUpdateCreateButtonText>Сохраниь объявление</ng-template>
    </button>
  </ng-container>
  <ng-template #loadingScreen>
    <div class="loading-screen">
      <div class="loading-screen__spinner">
        <mat-spinner diameter="18"></mat-spinner>
      </div>
      <div class="loading-screen__message">
        <ng-container *ngIf="!IsUpdate; else savingText">
          Подождите, идет создание объявления ...
        </ng-container>
        <ng-template #savingText>
          Подождите, идет сохранение объявления ...
        </ng-template>
      </div>
    </div>
  </ng-template>
</ng-template>
