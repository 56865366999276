import { ChangeDetectorRef, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { PlatformsQuery } from '../../stores/platforms/platforms.query';
import { PlatformsService } from '../../stores/platforms/platforms.service';
import { PlatformsRelationsQuery } from '../../stores/platforms-relations/platforms-relations.query';
import { PlatformsRelationsService } from '../../stores/platforms-relations/platforms-relations.service';
import { UserIntegrationService } from '../../../api/services';
import { combineLatest, of } from 'rxjs';
import { ConfiguratorService } from '../../service/configurator.service';
import { debounceTime, delay, distinctUntilChanged, map, take, tap } from 'rxjs/operators';
import { PlatformsTypes } from '../../platforms-types';
import { AkitaNgFormsManager } from '@datorama/akita-ng-forms-manager';
import { ActivatedRoute, Router } from '@angular/router';
import { PlatformAssignationsQuery } from '../../stores/platform-assignations/platform-assignations.query';
import { isNullOrUndefined } from 'util';
import { AdNetworkFormsService } from '../../stores/ad-network-forms/ad-network-forms.service';
import { LoggingService } from '../../../shared/services/logging.service';
import { Severity } from '@sentry/types';
import { FormMessagesService } from "../../services/form-messages/form-messages.service";
var IntegrationFormComponent = /** @class */ (function () {
    function IntegrationFormComponent(platformsQuery, platformsService, platformsRelationsService, platformsRelationsQuery, userIntegrationForm, configurator, fm, cd, router, route, assignationQuery, adNetworkFormsService, logger, formMessagesService) {
        var _this = this;
        this.platformsQuery = platformsQuery;
        this.platformsService = platformsService;
        this.platformsRelationsService = platformsRelationsService;
        this.platformsRelationsQuery = platformsRelationsQuery;
        this.userIntegrationForm = userIntegrationForm;
        this.configurator = configurator;
        this.fm = fm;
        this.cd = cd;
        this.router = router;
        this.route = route;
        this.assignationQuery = assignationQuery;
        this.adNetworkFormsService = adNetworkFormsService;
        this.logger = logger;
        this.formMessagesService = formMessagesService;
        this.isCreateLoading = false;
        this.originPlatformCode = null;
        this.originPlatforms$ = this.platformsQuery.originPlatforms$.pipe(
        // map(platforms => platforms.filter(platform => platform.code === PlatformsTypes.MT_LEAD_FORMS))
        );
        this.destinationPlatforms$ = this.platformsQuery.relatedPlatforms$;
        this.isValid = false;
        this.originPlatformAssigned = false;
        this.destinationPlatformAssigned = false;
        this.allFields = false;
        this.originPlatformSettingsValid$ = this.platformsQuery.selectedOriginPlatformCode$
            .pipe(map(function (code) { return _this.configurator.valid(PlatformsTypes[code]); }));
        this.isDestinationSettingsOpened = false;
        this.isNextButtonShowed = false;
        this.isOriginSettingsValid$ = this.fm.selectValue('originPlatformSettings')
            .pipe(map(function (form) { return _this.configurator.valid(_this.originPlatformCode); }));
        this.isDestinationSettingsValid$ = this.fm.selectValue('destinationPlatformSettings')
            .pipe(map(function (form) { return _this.configurator.valid(_this.destinationPlatformCode); }));
        this.ShowMessages = false;
        this.isDestinationPlatformSelected$ = this.platformsQuery.isDestinationPlatformSelected$;
        this.Messages = this.formMessagesService.Messages$
            .asObservable()
            .pipe(debounceTime(500), tap(console.log));
    }
    Object.defineProperty(IntegrationFormComponent.prototype, "isOriginValid", {
        get: function () {
            return this.configurator.valid(this.originPlatformCode);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IntegrationFormComponent.prototype, "isDestinationValid", {
        get: function () {
            return this.configurator.valid(this.destinationPlatformCode);
        },
        enumerable: true,
        configurable: true
    });
    // public get Messages() {
    //   let formMessages = ;
    //   let messages = formMessages.asObservable();
    //   return messages
    //     .pipe(
    //       debounceTime(500),
    //       tap(console.log)
    //     )
    // }
    IntegrationFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.queryParams.subscribe(function (_a) {
            var allFields = _a.allFields;
            _this.allFields = !isNullOrUndefined(allFields);
            _this.platformsService.LoadIntegrations(allFields);
            _this.platformsRelationsService.LoadIntegrationsRelations();
            _this.platformsQuery.selectedOriginPlatformCode$.subscribe(function (code) {
                _this.originPlatformCode = code;
                _this.isPlatformAssigned$ =
                    _this.assignationQuery.isPlatformAssigned$(PlatformsTypes[code]);
                _this.isPlatformAssigned$.subscribe(function (data) {
                });
                _this.isDestinationSettingsOpened = false;
                _this.fm.remove('originPlatformSettings');
            });
            _this.platformsQuery.selectedDestinationPlatformCode$
                .pipe(distinctUntilChanged())
                .subscribe(function (code) {
                _this.destinationPlatformCode = code;
                _this.fm.remove('destinationPlatformSettings');
            });
            // combineLatest(
            //   this.platformsQuery.selectedOriginPlatformCode$,
            //   this.platformsQuery.selectedDestinationPlatformCode$
            // )
            //   .subscribe(([originPlatformCode, destinationPlatformCode]) => {
            //     console.log(originPlatformCode, destinationPlatformCode, 'SELECTED PLATFORM CODES');
            //     this.ShowMessages = !(isNullOrUndefined(originPlatformCode) && isNullOrUndefined((destinationPlatformCode)))
            //   })
        });
        this.isOriginSettingsValid$
            .pipe(distinctUntilChanged(), delay(100))
            .subscribe(function (isOriginValid) {
            if (_this.originPlatformCode === PlatformsTypes.MOY_SKLAD_EXPORT) {
                _this.Open();
            }
            console.log(isOriginValid, 'Is origin valid', !_this.isDestinationSettingsOpened, 'is destination closed');
            _this.isNextButtonShowed = isOriginValid && !_this.isDestinationSettingsOpened;
        });
        combineLatest(this.isOriginSettingsValid$, this.isDestinationSettingsValid$)
            .pipe(delay(100))
            .subscribe(function (_a) {
            var isOriginValid = _a[0], isDestionationValid = _a[1];
            _this.isValid = isOriginValid && isDestionationValid;
            _this.cd.detectChanges();
        });
    };
    IntegrationFormComponent.prototype.SaveIntegration = function () {
        var _this = this;
        // this.formMessagesService.Clear()
        try {
            var t = 0;
            var originPlatformValidation = this.configurator.valid(this.originPlatformCode);
            t = 1;
            var destinationPlatformValidation = this.configurator.valid(this.destinationPlatformCode);
            t = 2;
            var validation = originPlatformValidation && destinationPlatformValidation;
            t = 3;
            console.error('VALIDATION WAS ' + validation, originPlatformValidation, destinationPlatformValidation, t);
            this.ShowMessages = true;
            // this.cd.detectChanges();
            if (validation) {
                this.logger.AddBreadcrumb('Начался процесс сохранения интеграции пользователя');
                this.SetCreateLoading(true);
                var leadFormsStatus = of(true);
                if (this.originPlatformCode === PlatformsTypes.MT_LEAD_FORMS || this.originPlatformCode === PlatformsTypes.VK_LEAD_FORMS || this.originPlatformCode === PlatformsTypes.FB_LEAD_FORMS) {
                    leadFormsStatus = this.adNetworkFormsService.LoadLastIdOfSelectedLeadForms(PlatformsTypes[this.originPlatformCode]);
                }
                leadFormsStatus
                    .subscribe(function () {
                    combineLatest(_this.platformsQuery.selectedOriginPlatform, _this.platformsQuery.selectedDestinationPlatform$, _this.fm.selectValue('name', 'name'), _this.platformsQuery.selectedOriginPlatformCode$
                        .pipe(map(function (code) { return _this.configurator.configure(PlatformsTypes[code]); })), _this.platformsQuery.selectedDestinationPlatformCode$
                        .pipe(map(function (code) { return _this.configurator.configure(PlatformsTypes[code]); })))
                        .pipe(take(1))
                        .subscribe(function (_a) {
                        var originPlatform = _a[0], destinationPlatform = _a[1], name = _a[2], originData = _a[3], destData = _a[4];
                        _this.logger.AddBreadcrumb('Сохраняется интеграция пользователя', {
                            originPlatform: originPlatform.code,
                            destinationPlatform: destinationPlatform.code,
                            name: name
                        });
                        _this.SendAddUserIntegrationRequest(destinationPlatform, originPlatform, name, destData, originData);
                    });
                });
            }
            else {
                combineLatest(this.platformsQuery.selectedOriginPlatform, this.platformsQuery.selectedDestinationPlatform$, this.fm.selectValue('name', 'name'), this.platformsQuery.selectedOriginPlatformCode$
                    .pipe(map(function (code) { return _this.configurator.configure(PlatformsTypes[code]); })), this.platformsQuery.selectedDestinationPlatformCode$
                    .pipe(map(function (code) { return _this.configurator.configure(PlatformsTypes[code]); })))
                    .pipe(take(1))
                    .subscribe(function (_a) {
                    var originPlatform = _a[0], destinationPlatform = _a[1], name = _a[2], originData = _a[3], destData = _a[4];
                    _this.logger.AddBreadcrumb('Сохраняется интеграция пользователя', {
                        originPlatform: originPlatform.code,
                        destinationPlatform: destinationPlatform.code,
                        name: name
                    });
                    _this.logger.AddExtra('PlatformConfiguration', {
                        destinationPlatform: destinationPlatform, originPlatform: originPlatform, name: name, destData: destData, originData: originData
                    });
                    _this.logger.Error('Пользователь попытался создать интеграцию с не валидными данными', {
                        destinationPlatform: destinationPlatform, originPlatform: originPlatform, name: name, destData: destData, originData: originData
                    });
                });
            }
        }
        catch (e) {
            this.logger.AddBreadcrumb('Ошибка:', e, 'default', Severity.Error);
            this.logger.Error('При сохранении интеграции произошла ошибка.', e);
        }
    };
    IntegrationFormComponent.prototype.SendAddUserIntegrationRequest = function (destinationPlatform, originPlatform, name, destData, originData) {
        var _this = this;
        this.logger.AddBreadcrumb('Начался процесс отправки данных на создание интеграции');
        try {
            // throw new Error('WTF ???');
            this.userIntegrationForm.AddUserIntegration(this.GenerateModelData(destinationPlatform, originPlatform, name, destData, originData)).subscribe(function (data) {
                _this.logger.Log('Интеграция успешно сохранена!');
                _this.SetCreateLoading(false);
                _this.router.navigate(['/integrations']);
            }, function (err) {
                _this.SetCreateLoading(false);
                _this.HandleSendAddUserIntegrtionRequestError(err);
                _this.logger.AddBreadcrumb('Ошибка', err);
                _this.logger.Error('При сохранении интеграции произошла ошибка!', err);
            });
        }
        catch (e) {
            this.SetCreateLoading(false);
            this.HandleSendAddUserIntegrtionRequestError(e);
            this.logger.AddBreadcrumb('Ошибка', e);
            this.logger.Error('При сохранении интеграции произошла ошибка!', e);
        }
    };
    /**
     * Генерация новой модели для отправки запроса на создание новой пользовательской интеграции
     * @param destinationPlatform
     * @param originPlatform
     * @param name
     * @param destData
     * @param originData
     * @constructor
     */
    IntegrationFormComponent.prototype.GenerateModelData = function (destinationPlatform, originPlatform, name, destData, originData) {
        return {
            toId: destinationPlatform.id,
            fromId: this.getOriginId(originPlatform, originData),
            model: {
                name: name,
                destinationServiceData: this.getDestinationServiceData(destData, originData),
                originServiceData: this.getOriginServiceData(originData)
            }
        };
    };
    IntegrationFormComponent.prototype.getDestinationServiceData = function (destData, originData) {
        var isUonMarksExport = originData.map(function (_a) {
            var code = _a.code;
            return code;
        })
            .filter(function (code) { return code === 'UON_MARKS_EXPORT'; }).length > 0;
        console.log(originData, isUonMarksExport, 'ORIGIN DATA');
        if (isUonMarksExport) {
            return destData.map(function (x) {
                x.data = x.data.map(function (y) {
                    var codes = y.code.split('_');
                    y.code = codes[1] + "_" + codes[1];
                    return y;
                });
                return x;
            });
        }
        return destData;
    };
    IntegrationFormComponent.prototype.getOriginServiceData = function (originData) {
        var isUonMarksExport = originData.map(function (_a) {
            var code = _a.code;
            return code;
        }).filter(function (code) { return code === 'UON_MARKS_EXPORT'; }).length > 0;
        if (isUonMarksExport) {
            return originData.map(function (section) {
                if (section.code === 'UON_MARKS_EXPORT') {
                    section.code = 'CONTACTS';
                }
                return section;
            });
        }
        else {
            return originData;
        }
    };
    IntegrationFormComponent.prototype.getOriginId = function (originPlatform, originSettings) {
        // console.log(originSettings, 'ORIGIN SETTINGS');
        var isUonMarksExport = originSettings.map(function (_a) {
            var code = _a.code;
            return code;
        }).filter(function (code) { return code === 'UON_MARKS_EXPORT'; }).length > 0;
        return isUonMarksExport ? 22 : originPlatform.id;
    };
    IntegrationFormComponent.prototype.HandleSendAddUserIntegrtionRequestError = function (err) {
        if (err.status === 400) {
            alert(err.error.description);
        }
        else {
            alert('Неизвестная ошибка! Обратитесь в службу технической поддержки');
        }
    };
    IntegrationFormComponent.prototype.valid = function () {
        var valid = this.isOriginValid && this.isDestinationValid && this.isDestinationSettingsOpened;
        return valid || false;
    };
    IntegrationFormComponent.prototype.Open = function () {
        this.isDestinationSettingsOpened = true;
        window.scrollTo(0, 0);
    };
    /**
     * Задать статус для индикатора загрузки
     * @param status - статус индикатора загрузки
     * @constructor
     */
    IntegrationFormComponent.prototype.SetCreateLoading = function (status) {
        this.isCreateLoading = status;
    };
    IntegrationFormComponent.prototype.ngOnChanges = function (changes) {
    };
    return IntegrationFormComponent;
}());
export { IntegrationFormComponent };
