export interface AffiliateMemberStattisticsByUser {
  id: number;
  email: string,
  amount: number,
  countOfTransactions: number,
  invitedAt: Date,
  invitingFinishedAt: Date
}

export function createAffiliateMemberStattisticsByUser(params: Partial<AffiliateMemberStattisticsByUser>, id: number) {
  return {
    ...params,
    id,
  } as AffiliateMemberStattisticsByUser;
}
