import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material";
import {NotRemovedStrategiesModalDataViewModal} from "../../../models/view-models/not-removed-strategies-modal-data.view-modal";

@Component({
  selector: 'app-not-removed-strategies-modal',
  templateUrl: './not-removed-strategies-modal.component.html',
  styleUrls: ['./not-removed-strategies-modal.component.scss']
})
export class NotRemovedStrategiesModalComponent implements OnInit {

  public notRemovedStrategiesAdsTitles: Array<string> = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: NotRemovedStrategiesModalDataViewModal
  ) {
  }

  ngOnInit() {
    this.notRemovedStrategiesAdsTitles = this.data.ads.map(ad => ad.name);
  }

}
