<div class="integration-form">
  <div class="integration-form__name">
    <app-integration-name-form></app-integration-name-form>
  </div>


  <div class="integration-form__messages" [hidden]="!ShowMessages">
    <ng-container *ngFor="let Message of Messages | async">
      <app-form-message [Message]="Message"></app-form-message>
    </ng-container>
  </div>

  <div class="integration-form__selectors">
    <div class="integration-form__selector integration-form__selector_origin">
      <app-integration-platform-selector
        label="Собирать данные из"
        placeholder="Выберите откуда собирать данные"
        [platforms]="originPlatforms$ | async"
        controlName="leftService"
        [isOrigin]="true"
        suggestionCode="FROM_PLATFORM"
      ></app-integration-platform-selector>
      <div class="integration-form__actions">
        <ng-container *ngIf="(isPlatformAssigned$ | async) && !isDestinationSettingsOpened">
          <button
            class="vk-standart-button"
            *ngIf="isNextButtonShowed"
            (click)="Open()">Далее
          </button>
        </ng-container>
      </div>
    </div>

    <div class="integration-form__selector integration-form__selector_destination" *ngIf="isDestinationSettingsOpened">
      <app-integration-platform-selector
        label="Передавать данные в"
        placeholder="Выберите куда отправлять данные"
        controlName="rightService"
        [platforms]="destinationPlatforms$ | async"
        [isOrigin]="false"
        suggestionCode="IMPORT_PLATFORM"
      ></app-integration-platform-selector>
    </div>
  </div>
  <div class="integration-form__actions">

    <ng-container *ngIf="isDestinationSettingsOpened">
      <button class="vk-standart-button" (click)="SaveIntegration()" [disabled]="isCreateLoading">
        <ng-container *ngIf="isCreateLoading; else defaultButtonContent">
          <mat-spinner [diameter]="18"></mat-spinner>
        </ng-container>
        <ng-template #defaultButtonContent>
          Сохранить интеграцию
        </ng-template>
      </button>
    </ng-container>
  </div>
</div>
