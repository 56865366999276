import {Injectable, OnInit} from "@angular/core";
import {AffiliateMemberStattisticsByUserQuery} from "./affiliate-member-stattistics-by-user.query";
import {AffiliateMemberStattisticsByUserService} from "./affiliate-member-stattistics-by-user.service";
import {combineLatest} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AffiliateMemberStatisticsByUserFacade implements OnInit {

  public get dateFrom$() {
    return this.affiliateMembersStatisticByUserQuery.dateFrom$;
  }

  public get dateTo$() {
    return this.affiliateMembersStatisticByUserQuery.dateFrom$;
  }

  public get dateFrom() {
    return this.affiliateMembersStatisticByUserQuery.dateFrom;
  }

  public get dateTo() {
    return this.affiliateMembersStatisticByUserQuery.dateTo;
  }

  public statistics$ = this.affiliateMembersStatisticByUserQuery.statistics$;

  constructor(
    private affiliateMembersStatisticByUserQuery: AffiliateMemberStattisticsByUserQuery,
    private affiliateMemberStattisticByUserService: AffiliateMemberStattisticsByUserService
  ) {
  }

  /**
   * Load statistics
   * @constructor
   */
  public LoadStatistics() {
    const dateFrom = this.affiliateMembersStatisticByUserQuery.dateFrom;
    const dateTo = this.affiliateMembersStatisticByUserQuery.dateTo;

    this.affiliateMemberStattisticByUserService.get(dateFrom, dateTo)
      .subscribe();
  }

  ngOnInit(): void {
    combineLatest(
      this.dateFrom$,
      this.dateTo$
    )
      .subscribe(data => {
        this.LoadStatistics();
      })
  }
}
