import { Injectable } from '@angular/core';
import { TimePeriod } from './time-period.model';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

export interface TimePeriodsState extends EntityState<TimePeriod> {
  ui: {
    selectedTimePeriods: {
      tariffGroup1: number,
      tariffGroup2: number,
      tariffGroup3: number
    }
  }
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'time-periods' })
export class TimePeriodsStore extends EntityStore<TimePeriodsState> {

  constructor() {
    super({
      ui: {
        selectedTimePeriods: {
          tariffGroup1: 1,
          tariffGroup2: 1,
          tariffGroup3: 1
        }
      }
    });
  }

  selectTimePeriodId(tariffGroupId, timePeriodId) {
    const newTimePeriodState = {};
    newTimePeriodState[`tariffGroup${tariffGroupId}`] = timePeriodId;
    this._setState(state => ({
      ...state,
      ui: {
        ...state.ui,
        selectedTimePeriods: {
          ...state.ui.selectedTimePeriods,
          ...newTimePeriodState
        }
      }
    }))
  }

}

