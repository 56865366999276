//
import {Component, OnInit} from '@angular/core';
import {AffiliatePromgramStatisticsByUserService} from "../../services/affiliate-promgram-statistics-by-user.service";

@Component({
  selector: 'app-affiliate-promgram-statistics-by-user-table',
  templateUrl: './affiliate-promgram-statistics-by-user-table.component.html',
  styleUrls: ['./affiliate-promgram-statistics-by-user-table.component.scss']
})
export class AffiliatePromgramStatisticsByUserTableComponent implements OnInit {

  public statistics$ = this.service.statistics$;

  public displayedColumns = ['email', 'invitedAt', 'amount', 'invitingFinishing'];

  constructor(
    private service: AffiliatePromgramStatisticsByUserService
  ) {
  }

  ngOnInit() {
  }

}
