import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  TemplateRef
} from '@angular/core';
import {DgColumnComponent} from '../dg-column/dg-column.component';
import {List} from 'linqts';
import {isNullOrUndefined} from 'util';
import {DgPaginationComponent} from '../dg-pagination/dg-pagination.component';
import {ExcelService} from '../../../automation/services/excel-service.service';
import {DgActionBarComponent} from '../dg-action-bar/dg-action-bar.component';
import {Subject} from 'rxjs/Subject';
import * as moment from 'moment';

@Component({
  selector: 'ngr-data-grid',
  templateUrl: './data-grid.component.html',
  styleUrls: ['./data-grid.component.scss']
})
export class DataGridComponent implements OnInit, AfterViewInit, OnChanges, AfterViewChecked {

  @Input() public data: Array<any> = [];
  @Input() public height: string;
  @Input() public selectable: boolean = false;

  @Input() public currentPage: number = 1;
  @Input() public countOfResults: number = 10;

  @Input() public filterString: string = '';
  @Input() public loading: boolean = false;

  @Input() public showEditForm: boolean = false;
  @Input() public showFilterString: boolean = true;

  @Input() public exportId: boolean = false;
  @Input() public exportIdHeader: string = '';

  @Input() public selectedRows: Array<any> = [];
  @Output() public selectedRowsChange: EventEmitter<Array<any>> = new EventEmitter<Array<any>>();

  @Input() public showedColumns = null;
  @Output() public showedColumnsChange: EventEmitter<object> = new EventEmitter<object>();

  @Output() public withoutPagination: EventEmitter<any> = new EventEmitter<any>();

  @Input() public parent: any = null;

  public filterStrings = {};
  public dataWithoutPagination = [];

  @ContentChildren(DgColumnComponent) public columns: QueryList<DgColumnComponent>;
  @ContentChildren(TemplateRef) public actionBars: QueryList<TemplateRef<DgActionBarComponent>>;

  @ContentChildren(DgPaginationComponent) paginationComponents: QueryList<DgPaginationComponent>;

  @Input() public pagination: DgPaginationComponent = null;

  public sortingField: string = '';
  public sortingDescend: boolean = false;

  public countOfPages: number = 1;
  public IsSidebarHidden: boolean = true;

  public displayedData = [];

  public loadDataSubject = new Subject<any>();
  @Input() public dataNotFoundMessage: string = 'Данные не найдены';

  constructor(
    private excel: ExcelService,
    private cd: ChangeDetectorRef
  ) {
  }

  public get displayedColumns() {
    if (this.columns) {
      return this.columns.toArray();
    } else {
      return [];
    }
  }

  public get IsFilterLoaded() {
    return Object.keys(this.filterStrings).length === this.displayedColumns.length;
  }

  public LoadDisplayedData() {
    console.log('START DISPLAING DATA');
    if (!isNullOrUndefined(this.data)) {
      let data = new List<any>(this.data);
      if (this.sortingField) {
        data = this.SortData(data, this.sortingField, this.sortingDescend);
      }
      data = this.FilterData(data, this.filterStrings);
      this.countOfPages = Math.ceil(data.Count() / this.countOfResults);
      this.withoutPagination.emit(data.ToArray());
      data = this.GetPaginatedData(data, this.currentPage - 1, this.countOfResults);
      console.log(data, 'DISPLAYED DATA');
      if (data) {
        this.displayedData = data.ToArray();
      } else {
        this.displayedData = [];
      }
    }
    this.cd.detectChanges();
    // this.displayedData = [];
  }

  ngOnInit() {
    console.log(this.pagination);
    const columnsSettings = JSON.parse(localStorage.getItem('showed_columns'));
    console.log(columnsSettings, 'SHOWED COLUMNS SETTINGS');
    if (columnsSettings && columnsSettings[this.currentColumnsStore]) {
      this.showedColumns = columnsSettings[this.currentColumnsStore];
    }
    this.displayedColumns.forEach(column => {
      this.filterStrings[column.name] = this.CreateFilterModule(column);
    });
    console.log(this.displayedColumns);
    this.cd.detectChanges();
  }

  public ToggleSidebar() {
    this.IsSidebarHidden = !this.IsSidebarHidden;
  }

  public HideColumnByName(name) {
    this.showedColumns[name] = false;
    let columnsStorage = JSON.parse(localStorage.getItem('showed_columns'));
    if (!columnsStorage) {
      columnsStorage = {};
    }
    columnsStorage[this.currentColumnsStore] = this.showedColumns;
    localStorage.setItem('showed_columns', JSON.stringify(columnsStorage));
  }

  public GetColumnData(columnName) {
    if (this.data !== null) {
      return this.data
        .map(x => x[columnName]);
    }
    return [];
  }

  public get currentColumnsStore() {
    return location.pathname.substr(1).replace('/', '-');
  }

  public GetColumnDataFromPage(columnName) {
    if (this.data !== null) {
      return this.displayedData
        .map(x => x[columnName]);
    }
    return [];
  }


  public GetSummaryFuncByColumn(column) {
    if (column.summaryFunction) {
      if (column.formatFunction) {
        return column.formatFunction(column.summaryFunction(this.GetColumnData(column.name), this.displayedData));
      }
      return column.summaryFunction(this.GetColumnData(column.name), this.displayedData);
    }
  }

  public get SummaryData() {
    const data = this.displayedColumns
      .filter(column => column.HasSummaryFunction)
      .map(column => ({
        column,
        key: column.name,
        value: column.summaryFunction(this.GetColumnData(column.name), this.displayedData)
      }))
      .reduce((summaryObject, {column, key, value}) => {
        let itemValue = value;
        if (column.formatFunction) {
          itemValue = column.formatFunction(itemValue);
        }
        summaryObject[key] = itemValue;
        return summaryObject;
      }, {});
    return data;
  }

  public get PagingSummaryData() {
    const data = this.displayedColumns
      .filter(column => column.HasPagingSummaryFunction || column.HasSummaryFunction)
      .map(column => ({
        column,
        key: column.name,
        value: column.GetPagingSummaryFunctionResult(this.GetColumnDataFromPage(column.name), this.displayedData)
      }))
      .reduce((summaryObject, {column, key, value}) => {
        let itemValue = value;
        if (column.formatFunction) {
          itemValue = column.formatFunction(itemValue);
        }
        summaryObject[key] = itemValue;
        return summaryObject;
      }, {});
    return data;
  }

  public GetPagingSummaryFunction(column) {
    if (column.pagingSummaryFunction) {
      if (column.formatFunction) {
        return column.formatFunction(column.pagingSummaryFunction(this.GetColumnDataFromPage(column.name)));
      }
      return column.pagingSummaryFunction(this.GetColumnDataFromPage(column.name));
    } else {
      if (column.summaryFunction) {
        if (column.formatFunction) {
          return column.formatFunction(column.summaryFunction(this.GetColumnDataFromPage(column.name)));
        }
        return column.summaryFunction(this.GetColumnDataFromPage(column.name));
      }
    }
    return 0;
  }

  public GetCellContent() {
  }

  public SetSorting(field, sortable, sortDescending = false) {
    if (sortable) {
      if (this.sortingField === field) {
        this.sortingDescend = !this.sortingDescend;
      } else {
        this.sortingField = field;
        this.sortingDescend = false;
      }
      if (sortDescending) {
        this.sortingDescend = true;
      }
      this.loadDataSubject.next();
      if (!isNullOrUndefined(this.pagination)) {
        this.pagination.FirstPage();
      }
    }

  }

  public getCellContent(column: DgColumnComponent, row: any): number {
    // console.log(column);
    const cellData = row[column.name];
    if (column.formatFunction) {
      return column.formatFunction(cellData);
    } else {
      return cellData;
    }
  }


  public OnChangeFilter() {
    Object.keys(this.filterStrings)
      .forEach(filterStringName => {
        if (this.filterStrings[filterStringName].from) {
          this.filterStrings[filterStringName].from = Math.max(this.filterStrings[filterStringName].from, 0);
        }
        if (this.filterStrings[filterStringName].to) {
          this.filterStrings[filterStringName].to = Math.max(this.filterStrings[filterStringName].to, 0);
        }
      });
    this.loadDataSubject.next();
    this.LoadDisplayedData();
    console.log('FUCKING GO TO FIRST PAGE');
    this.GoToFirstPage();
  }

  private GoToFirstPage() {
    console.log(this.pagination, 'PAGINATION DG');
    if (this.pagination) {
      console.log('FUCKING FIRST PAGE');
      this.pagination.FirstPage();
    }
  }

  public IsShowedColumn(columnName) {
    return this.showedColumns && this.showedColumns[columnName];
  }

  public get HiddableColumns() {
    return this.columns ? this.columns
      .filter(column => column.hiddable) : [];
  }

  ngAfterViewInit(): void {
    this.paginationComponents.changes.subscribe(x => {
      console.log(x, ' PAGINATION');
    });
    console.log(this.pagination, 'PAGINATION AFTER INIT');
    if (this.showedColumns === null) {
      this.showedColumns = {};
      this.ShowAllColumns();
    }
    this.columns.changes.subscribe(columns => {
      this.ShowAllColumns();
      this.displayedColumns.forEach(column => {
        if (!this.filterStrings[column.name]) {
          this.filterStrings[column.name] = this.CreateFilterModule(column);
        }
      });
    });

    this.displayedColumns.forEach(column => {
      this.filterStrings[column.name] = this.CreateFilterModule(column);
    });

    this.LoadDisplayedData();
    this.loadDataSubject.subscribe(() => {
      this.LoadDisplayedData();
    });
  }

  public ShowAllColumns() {
    this.columns.forEach(column => {
      this.showedColumns[column.name] = true;
    });
  }

  /**
   * Return sorted data
   * @param data
   * @param field
   * @param isDescending
   * @constructor
   */
  private SortData(data: List<any>, field, isDescending): List<any> {
    let result = null;
    if (!isDescending) {
      result = data.ToArray().sort((a, b) => this.NormalizeField(field, b[field]) - this.NormalizeField(field, a[field]));
    } else {
      result = data.ToArray().sort((a, b) => this.NormalizeField(field, a[field]) - this.NormalizeField(field, b[field]));
    }
    return new List(result);
  }

  public NormalizeField(field, fieldValue) {
    const column = this.columns.find(x => x.name === field);
    if (column && column.type === 'date') {
      return parseInt(moment(new Date(fieldValue)).format('x'), 10);
    }
    return fieldValue;
  }

  /**
   * Return all data matches for filter object
   * @param data - Data of data-grid
   * @param fieldsOfFilter filter object
   * @constructor
   */
  private FilterData(data: List<any>, fieldsOfFilter: object): List<any> {
    Object
      .keys(fieldsOfFilter)
      .filter(field => {
        return fieldsOfFilter[field].value || fieldsOfFilter[field].from || fieldsOfFilter[field].to || fieldsOfFilter[field].type === 'custom';
      })
      .forEach(field => {
        const column = this.columns.find(column => column.name === field);
        console.log(fieldsOfFilter[field], 'field of filter');
        data = data.Where(dataItem => this.filterValue(dataItem, fieldsOfFilter[column.name], column.name));
      });
    //
    // if (this.filterString) {
    //   data = data.Where(dataItem => dataItem.name.match(this.filterString));
    // }

    return data;
  }

  private filterValue(row, filter, columnName) {
    if (filter.type === 'custom') {
      return filter.filterFunc.apply(this.parent, [row]);
    } else {
      return this.MatchesTheFilter(filter, row[columnName]);
    }
  }

  private matchFilters(filterValue, dataItemFilter, cloumn) {
    this.MatchesTheFilter(filterValue, dataItemFilter);
  }

  /**
   *
   * @param dataItemFilter
   * @param filterValue
   * @constructor
   */
  private MatchesTheFilter(filterValue, dataItemFilter): boolean {
    let isMatched = false;
    if (!isNullOrUndefined(dataItemFilter)) {
      if (filterValue.type === 'between') {
        let isMatchedFrom = true;
        let isMatchedTo = true;
        if (filterValue.from) {
          isMatchedFrom = parseFloat(dataItemFilter.toFixed(3)) >= parseFloat(filterValue.from.toFixed(3));
        }

        if (filterValue.to) {
          isMatchedTo = parseFloat(dataItemFilter.toFixed(3)) <= parseFloat(filterValue.to.toFixed(3));
        }

        isMatched = isMatchedFrom && isMatchedTo;
      } else if (filterValue.type === 'select') {
        if (filterValue.value === 'null') {
          isMatched = true;
        } else {
          isMatched = dataItemFilter.toString() === filterValue.value.toString();
        }
      } else {
        if (typeof dataItemFilter === 'string') {
          const reg = new RegExp(filterValue.value.toLowerCase());
          // isMatched = dataItemFilter.toLowerCase().match(filterValue.value.toLowerCase()).length > 0;
          isMatched = reg.test(dataItemFilter.toLowerCase());
        } else {
          isMatched = parseFloat(dataItemFilter) === parseFloat(filterValue.value);
        }
      }
    }
    return isMatched;
  }

  /**
   *
   * @param data
   * @param currentPage
   * @param countOfResults
   * @constructor
   */
  private GetPaginatedData(data: List<any>, currentPage, countOfResults) {
    console.log(countOfResults, 'DATA GRID COUNT OF RESULTS');
    let toRender = data
      .Skip(currentPage * countOfResults);
    if (countOfResults) {
      toRender = toRender
        .Take(countOfResults);
    }
    return toRender;
  }

  private CreateFilterModule(column: DgColumnComponent) {
    this.cd.detectChanges();
    if (column.filterFunction) {
      return this.CreateCustomFilter(column.filterFunction);
    } else {
      if (column.filterType === 'between') {
        return this.CreateFilterBetween();
      } else if (column.filterType === 'checkbox') {
        return this.CreateChecbkoxFilter();
      } else if (column.filterType === 'select') {
        return this.CreateSelectFilter();
      } else {
        return this.CreateFilterDefault();
      }
    }
  }

  private CreateFilterDefault() {
    return {
      value: '',
      type: 'default'
    };
  }

  private CreateFilterBetween() {
    return {
      from: '',
      to: '',
      type: 'between'
    };
  }

  private CreateChecbkoxFilter() {
    return {
      value: null,
      type: 'checkbox'
    };
  }

  private CreateSelectFilter() {
    return {
      value: null,
      type: 'select'
    };
  }

  private CreateCustomFilter(filterFunc: Function) {
    return {
      type: 'custom',
      filterFunc,
    };
  }

  private intToBool(value: number) {
    return value === 1;
  }

  private stringToBool(value: string) {
    return value === 'true';
  }

  public findColumnByName(columnName) {
    return this.columns.find(column => column.name === columnName);
  }

  public ExportToExcel() {
    console.log('Start Excel');
    const dataToExport = this.data.map(dataItem => {
      const excelDataItem = Object.keys(dataItem)
        .map(dataItemKey => {
          const column = this.findColumnByName(dataItemKey);
          if (column) {
            const result = {key: column.header || 'CPM', value: this.getCellContent(column, dataItem)};
            return result;
          } else {
            return null;
          }
        })
        .filter(columnData => columnData !== null)
        .reduce((resultObject, {key, value}) => {
          resultObject[key] = value;
          return resultObject;
        }, {});
      return excelDataItem;
    });

    this.excel.exportAsExcelFile(dataToExport, 'excel');
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.LoadDisplayedData();
    console.log(this.displayedColumns, 'DISPLAYED COLUMNS');
  }

  public get ShowFooter() {
    return this.data !== null && this.data.length > 0;
  }

  public OnPageChange(page) {
    this.currentPage = page;
    this.loadDataSubject.next();
  }

  ngAfterViewChecked(): void {
    this.cd.detectChanges();
  }

  UpdateStorage() {
    let columnsStorage = JSON.parse(localStorage.getItem('showed_columns'));
    if (!columnsStorage) {
      columnsStorage = {};
    }
    columnsStorage[this.currentColumnsStore] = this.showedColumns;
    localStorage.setItem('showed_columns', JSON.stringify(columnsStorage));
  }
}
