import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AdNetworkFormsQuery} from "../../../../stores/ad-network-forms/ad-network-forms.query";
import {AdNetworkFormsService} from "../../../../stores/ad-network-forms/ad-network-forms.service";
import {PlatformAssignationsService} from "../../../../stores/platform-assignations/platform-assignations.service";
import {PlatformAssignationsQuery} from "../../../../stores/platform-assignations/platform-assignations.query";
import {PlatformsTypes} from "../../../../platforms-types";
import {AssignationPlatforms} from "../../../../stores/platform-assignations/platform-assignation.model";
import {untilDestroyed} from "ngx-take-until-destroy";
import {AdNetworkGroupsService} from "../../../../stores/ad-network-groups/ad-network-groups.service";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {AkitaNgFormsManager} from "@datorama/akita-ng-forms-manager";
import {distinctUntilChanged} from "rxjs/operators";

@Component({
  selector: 'app-lead-forms-configurator',
  templateUrl: './lead-forms-configurator.component.html',
  styleUrls: ['./lead-forms-configurator.component.scss']
})
export class LeadFormsConfiguratorComponent implements OnInit, OnDestroy {

  public form: FormGroup;

  ngOnDestroy(): void {
  }

  @Input() public type: PlatformsTypes;
  @Input() public platform: AssignationPlatforms

  public forms$ = this.formsQuery.forms$;

  constructor(
    private formsQuery: AdNetworkFormsQuery,
    private formsService: AdNetworkFormsService,
    private assignationsService: PlatformAssignationsService,
    private assignationsQuery: PlatformAssignationsQuery,
    private groupsService: AdNetworkGroupsService,
    private fb: FormBuilder,
    private fm: AkitaNgFormsManager
  ) {
  }

  ngOnInit() {
    this.assignationsQuery
      .isAssigned$(this.platform)
      .pipe(
        distinctUntilChanged(),
        untilDestroyed(this)
      )
      .subscribe(data => {
        console.log(data, 'LGO');
        this.formsService.LoadMtLeadForms();
        this.groupsService.LoadGroups(this.type);
      });

    this.forms$.subscribe(forms => {
      const formsGroup = {};
      forms.forEach(form => {
        formsGroup[form.id] = this.fb.control(false);
      });
      this.form = this.fb.group(formsGroup);
      this.fm.upsert('originPlatformSettings', this.form);
    });
  }

  public GetControl(formId) {
    return this.form.controls[formId] as FormControl;
  }

}
