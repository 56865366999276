<form [formGroup]="controlContainer.control">
  <div *ngIf="IsAdmin">
    Оставшийся бюджет: {{budget | currency:"Руб."}}
    <a href="https://vk.com/ads?act=payments" rel="nofollow noopener" target="_blank">пополнить</a>
    <app-generic-tooltip header="Зачем и как пополнить" [text]="budgetInfo"></app-generic-tooltip>
    <button class="vk-standart-button" (click)="Update()" style="margin-left: 16px;">Обновить</button>
  </div>
  <div class="vk-container">
    <div class="tip-field">
      <div class="vk-container">
        Если вы хотите включить объявление сразу, то выберите “Отправить на модерацию сразу после создания объявления” и “Запустить объявление сразу после прохождения модерации".
      </div>

      <div class="vk-container">
        Если вы хотите отправить объявление на модерацию, но не хотите сразу его включать, то отметьте только  “Отправить на модерацию сразу после создания объявления".
      </div>

      <div>
        Если вы хотите только создать объявление и не отправлять его на модерацию,  то не отмечайте ни одно поле.
      </div>
    </div>
  </div>


  <div class="vk-container">
    <mat-checkbox formControlName="sendModeration" [disabled]="IsBudgetZero()">
      Отправить на модерацию сразу после создания объявления
    </mat-checkbox>
    <app-generic-tooltip [text]="Tip"></app-generic-tooltip>
  </div>

  <div class="vk-container">
    <mat-checkbox formControlName="startAfterModeration" [disabled]="!controlContainer.control.value.sendModeration">
      Запустить объявление сразу после прохождения модерации
    </mat-checkbox>
  </div>
</form>

