/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./budget-panel.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../shared/components/generic-tooltip/generic-tooltip.component.ngfactory";
import * as i4 from "../../../../../shared/components/generic-tooltip/generic-tooltip.component";
import * as i5 from "./budget-panel.component";
var styles_BudgetPanelComponent = [i0.styles];
var RenderType_BudgetPanelComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BudgetPanelComponent, data: {} });
export { RenderType_BudgetPanelComponent as RenderType_BudgetPanelComponent };
export function View_BudgetPanelComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.CurrencyPipe, [i1.LOCALE_ID]), (_l()(), i1.ɵted(1, null, ["\u041E\u0441\u0442\u0430\u0432\u0448\u0438\u0439\u0441\u044F \u0431\u044E\u0434\u0436\u0435\u0442: ", " \u0420\u0443\u0431. "])), i1.ɵppd(2, 2), (_l()(), i1.ɵeld(3, 0, null, null, 1, "a", [["href", "https://vk.com/ads?act=payments"], ["rel", "nofollow noopener"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u043F\u043E\u043F\u043E\u043B\u043D\u0438\u0442\u044C"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-generic-tooltip", [["header", "\u0417\u0430\u0447\u0435\u043C \u0438 \u043A\u0430\u043A \u043F\u043E\u043F\u043E\u043B\u043D\u0438\u0442\u044C"]], null, null, null, i3.View_GenericTooltipComponent_0, i3.RenderType_GenericTooltipComponent)), i1.ɵdid(6, 114688, null, 0, i4.GenericTooltipComponent, [], { header: [0, "header"], text: [1, "text"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "button", [["class", "vk-standart-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.refresh() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\u041E\u0431\u043D\u043E\u0432\u0438\u0442\u044C"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = "\u0417\u0430\u0447\u0435\u043C \u0438 \u043A\u0430\u043A \u043F\u043E\u043F\u043E\u043B\u043D\u0438\u0442\u044C"; var currVal_2 = _co.budgetInfo; _ck(_v, 6, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v, 0), _co.budget, "\u0420\u0443\u0431.")); _ck(_v, 1, 0, currVal_0); }); }
export function View_BudgetPanelComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-budget-panel", [], null, null, null, View_BudgetPanelComponent_0, RenderType_BudgetPanelComponent)), i1.ɵdid(1, 114688, null, 0, i5.BudgetPanelComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BudgetPanelComponentNgFactory = i1.ɵccf("app-budget-panel", i5.BudgetPanelComponent, View_BudgetPanelComponent_Host_0, { budget: "budget" }, { refreshTriggered: "refreshTriggered" }, []);
export { BudgetPanelComponentNgFactory as BudgetPanelComponentNgFactory };
