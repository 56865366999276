import { OnInit } from '@angular/core';
import { CustomFieldsQuery } from "../../../../stores/custom-fields/custom-fields.query";
import { CustomFieldsService } from "../../../../stores/custom-fields/custom-fields.service";
import { AssignationPlatforms } from "../../../../stores/platform-assignations/platform-assignation.model";
import { CustomFieldValueQuery } from "../../../../stores/custom-field-value/custom-field-value.query";
import { CustomFieldValueService } from "../../../../stores/custom-field-value/custom-field-value.service";
import * as i0 from "@angular/core";
import * as i1 from "../../../../stores/custom-fields/custom-fields.query";
import * as i2 from "../../../../stores/custom-fields/custom-fields.service";
import * as i3 from "../../../../stores/custom-field-value/custom-field-value.query";
import * as i4 from "../../../../stores/custom-field-value/custom-field-value.service";
var NewImportCrmService = /** @class */ (function () {
    function NewImportCrmService(customFieldsQuery, customFieldsService, customFieldsValuesQuery, customFieldsValuesService) {
        this.customFieldsQuery = customFieldsQuery;
        this.customFieldsService = customFieldsService;
        this.customFieldsValuesQuery = customFieldsValuesQuery;
        this.customFieldsValuesService = customFieldsValuesService;
        this.advancedSettings = {
            FB_LEAD_FORMS_TO_MEGAPLAN_IMPORT: {
                DEALS: [
                    {
                        label: 'Передавать название кампании',
                        key: 'includeCampaignName'
                    },
                    {
                        label: 'Передавать название лид-формы',
                        key: 'includeLeadFormName'
                    },
                    {
                        label: 'Привязывать контакт к лиду',
                        key: 'bindContact'
                    }
                ]
            }
        };
    }
    NewImportCrmService.prototype.SetDefaultOptions = function (options) {
        this.defaultOptions = options;
    };
    NewImportCrmService.prototype.SetUsersOptions = function (options) {
        this.usersOption = options;
    };
    NewImportCrmService.prototype.SetPipelinesOptions = function (options) {
        this.pipelinesOptions = options;
    };
    NewImportCrmService.prototype.SetStagesOptions = function (options) {
        this.stagesOptions = options;
    };
    NewImportCrmService.prototype.getFieldOptions = function (fieldCode, questions) {
        switch (fieldCode) {
            case 'PM_RESPONSIBLE_USER_ID':
                return this.usersOption;
            case 'PM_PIPELINE_ID':
                return this.pipelinesOptions;
            case 'PM_STAGE':
                return this.stagesOptions;
            default:
                return questions;
        }
    };
    /**
     * Get advanced settings required for destination settings
     * @param originPlatformCode
     * @param destinationPlatformCode
     * @param sectionCode
     * @constructor
     */
    NewImportCrmService.prototype.GetAdvancedSettings = function (originPlatformCode, destinationPlatformCode) {
        console.log(originPlatformCode + "_TO_" + destinationPlatformCode);
        return this.advancedSettings[originPlatformCode + "_TO_" + destinationPlatformCode];
    };
    NewImportCrmService.prototype.ngOnInit = function () {
    };
    NewImportCrmService.prototype.LoadData = function () {
        var _this = this;
        this.customFieldsService.LoadCustomFields(AssignationPlatforms.MEGAPLAN)
            .subscribe();
        this.customFieldsQuery.CustomFields$.
            subscribe(function (customFields) {
            _this.customFields = customFields;
        });
        this.customFieldsValuesQuery.CustomFieldsValues$.subscribe(function (customFieldsValues) {
            _this.customFieldValues = customFieldsValues;
        });
    };
    NewImportCrmService.ngInjectableDef = i0.defineInjectable({ factory: function NewImportCrmService_Factory() { return new NewImportCrmService(i0.inject(i1.CustomFieldsQuery), i0.inject(i2.CustomFieldsService), i0.inject(i3.CustomFieldValueQuery), i0.inject(i4.CustomFieldValueService)); }, token: NewImportCrmService, providedIn: "root" });
    return NewImportCrmService;
}());
export { NewImportCrmService };
