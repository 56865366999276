<div class="ad-promopost-form">
  <div class="ad-promopost-form__controls">
    <form [formGroup]="form">
      <div class="promopost-form__examples-container">
        <a class="btn btn-success btn-sm" href="/vk/hidden" rel="nofollow noopener"
           style="text-align:right; margin: 0; margin-bottom: 8px;" target="_blank"> Посмотреть
          примеры объявлений </a>
      </div>
      <ng-container *ngIf="HasAdminOpenedGroups(); else adminOpenedGroupsError;">
        <vk-form-field [labelWidth]="labelsWidth" [controlsWidth]="controlsWidth">
          <vk-label>Выберите сообщество</vk-label>
          <select vkInput formControlName="groupId">
            <ng-container *ngFor="let group of adminGroups">
              <option [value]="group.id">{{group.name}}</option>
            </ng-container>
          </select>
          <vk-error name="groupId" validator="required">Вы не выбрали группу для публикации рекламы</vk-error>
        </vk-form-field>
      </ng-container>

      <ng-template #adminOpenedGroupsError>
        <app-missing-admin-groups-alert></app-missing-admin-groups-alert>
        <br>
      </ng-template>

      <vk-form-field [labelWidth]="labelsWidth" [controlsWidth]="controlsWidth">
        <vk-label>Введите заголовок ссылки</vk-label>
        <input type="text" vkInput formControlName="linkTitle">
        <vk-error name="linkTitle" validator="required">Вы не ввели название ссылки</vk-error>
      </vk-form-field>

      <vk-form-field [labelWidth]="labelsWidth" [controlsWidth]="controlsWidth">
        <vk-label>Введите ссылку на сайт или группу</vk-label>
        <input type="text" vkInput formControlName="linkAddress">
        <vk-error name="groupId" validator="required">Вы не ввели ссылку на рекламируемый объект</vk-error>
        <vk-error name="groupId" validator="url">Ссылка не валидна</vk-error>
      </vk-form-field>

      <vk-form-field formArrayName="text" [labelWidth]="labelsWidth" [controlsWidth]="controlsWidth">
        <vk-label>
          <div>Текст объявления</div>
          <div [innerHtml]="emojiNotification" class="label-alert"></div>
        </vk-label>
        <textarea type="text" vkInput formControlName="0" placeholder="Введите текст объявления ..."
                  [style.height]="textAreaHeight" maxlength="600"></textarea>
        <vk-hint>
          Осталось символов: {{textLength(0)}}
        </vk-hint>
        <vk-error name="text" validator="required">Вы не ввели текст объявления</vk-error>
        <vk-error name="text" validator="minLength">Текст рекламной записи должен содержать хотя бы 1 символ</vk-error>
        <vk-error name="text" validator="maxLength">Текст рекламной записи не должен содержать более 600 символов
        </vk-error>
        <vk-error name="text" validator="emojiCount">Текст рекламной записи не должен содержать более 6 символов emoji
        </vk-error>
      </vk-form-field>

      <vk-form-field [labelWidth]="labelsWidth" [controlsWidth]="controlsWidth">
        <vk-label>Название объявления</vk-label>
        <input type="text" vkInput formControlName="name">
      </vk-form-field>

    </form>
  </div>
  <div class="ad-promopost-form__examples">
    <app-ad-promoposts-list></app-ad-promoposts-list>
  </div>
</div>
<app-new-images-manager
  (selected)="ImageSelected($event)"
  [minWidth]="537"
  [minHeight]="240"
></app-new-images-manager>
