import {Component, OnDestroy, OnInit} from '@angular/core';
import {RetargetingGroupsQuery} from '../../../../stores/retargeting-groups/retargeting-groups.query';
import {RetargetingGroupsService} from '../../../../stores/retargeting-groups/retargeting-groups.service';
import {FormControl, FormGroup} from '@angular/forms';
import {CrmPipelinesQuery} from '../../../../stores/crm-pipelines/crm-pipelines.query';
import {CrmPipelinesService} from '../../../../stores/crm-pipelines/crm-pipelines.service';
import {CrmPipelineStatus} from '../../../../stores/crm-pipelines/crm-pipeline.model';
import {combineLatest} from 'rxjs';
import {RemarketingBaseType} from '../../../../stores/retargeting-groups/retargeting-groups.store';
import {AkitaNgFormsManager} from '@datorama/akita-ng-forms-manager';
import {map} from 'rxjs/operators';
import {IMtRemarketingGroupSettingsModel} from '../../models/mt-remarketing-group-settings.model';
import {PlatformsQuery} from '../../../../stores/platforms/platforms.query';
import {PlatformAssignationsQuery} from '../../../../stores/platform-assignations/platform-assignations.query';
import {toBoolean} from '@datorama/akita';
import {untilDestroyed} from 'ngx-take-until-destroy';
import {AssignationPlatforms} from '../../../../stores/platform-assignations/platform-assignation.model';
import {environment} from "../../../../../../environments/environment";

@Component({
  selector: 'app-remarketing-groups-settings',
  templateUrl: './remarketing-groups-settings.component.html',
  styleUrls: ['./remarketing-groups-settings.component.scss']
})
export class RemarketingGroupsSettingsComponent implements OnInit, OnDestroy {

  public containsEmails = false;
  public containsPhones = false;

  public remarketingBasesTypes = RemarketingBaseType;
  public pipelinesStatuses$ = this.pipelinesQuery.selectedPipeLineStatuses$;
  public leadPipelines$ = this.pipelinesQuery.leadsPipelineStatuses$;
  public isNotEmptyPipelines$ = this.pipelinesQuery.selectedPipeLineStatuses$
    .pipe(
      map(pipelines => pipelines.length > 0)
    );
  
  public isPipelinesCheckboxVisible$ = combineLatest(
      this.isNotEmptyPipelines$,
      this.fm.selectValue(environment.integrations.originFormKey)
          .pipe(map(({section}) => section))
  )
      .pipe(map(([isPipelinesNotEmpty, section]) => {
        if (section === 'UON_MARKS_EXPORT') {
          return false;
        } else  {
          return isPipelinesNotEmpty;
        }
      }));

  public isLoading$ = this.remarketingGroupsQuery.selectLoading();

  public groups$ = this.remarketingGroupsQuery.filteredRetargetingGroups$;
  public form: FormGroup = new FormGroup({
    type: new FormControl(RemarketingBaseType.EMAILS),
    split_by_pipeline_statuses: new FormControl(false),
    pipeline_statuses: new FormGroup({
      pipeline_null: new FormGroup({
        pipeline_id: new FormControl(),
        pipeline_retargeting_groups: new FormControl()
      })
    })
  });
  public splitByPipelines: boolean = false;
  public selectedRemarketingGroups: number[];
  public hasLeads = false;
  public isSelectedUonTravel$ = this.platformsQuery.selectedOriginPlatformCode$
    .pipe(map(x => x === 'UON_EXPORT'));

  private withoutSplittingLabel: string = 'Передавать данные в списки пользователей';

  constructor(
    private remarketingGroupsQuery: RetargetingGroupsQuery,
    private remakertingGroupsService: RetargetingGroupsService,
    private pipelinesQuery: CrmPipelinesQuery,
    private pipelinesService: CrmPipelinesService,
    private fm: AkitaNgFormsManager,
    private platformsQuery: PlatformsQuery,
    private assignationsQuery: PlatformAssignationsQuery
  ) {
  }

  public get isAllContacts() {
    return this.fm.getForm('originPlatformSettings').value.section === 'CONTACTS';
  }

  ngOnInit() {
    this.initComponentOnAssignPlatform();
    this.clearDestinationSettingsOnChangeDestinationCode();
    this.clearDestinationSettingsOnRemarketingGroupsListChanged();
    this.subscribeToOriginPlatformCodeChanging();

    this.subscribeToPipelineStatusesChanging();
    this.subscribeToPipelineStatusType();
    this.combinePipelineStatusesOnChangingLeadAndDealsPipelines();
  }

  private combinePipelineStatusesOnChangingLeadAndDealsPipelines() {
    combineLatest(
      this.pipelinesStatuses$,
      this.leadPipelines$
    )
      .subscribe(([pipelines, leadPipelines]) => {
        const statuses = [...pipelines, ...leadPipelines];
        this.changePipelineStatusesFormGroups(statuses);
      });
  }

  private subscribeToPipelineStatusesChanging() {
    this.pipelinesStatuses$.subscribe(data => {
      if (data.length === 0) {
        this.form.get('split_by_pipeline_statuses').reset();
      }
      this.subscribeAllPipelineStatusesControls();
    });
  }

  private subscribeToOriginPlatformCodeChanging() {
    this.platformsQuery.selectedOriginPlatformCode$
      .subscribe(code => {
        const platform = this.assignationsQuery.platform(code);
        this.platformsQuery.getPlatformParams(platform)
          .subscribe(params => {
            if (params) {
              this.hasLeads = params.hasLeads;
            }
          });
      });
  }

  private clearDestinationSettingsOnRemarketingGroupsListChanged() {
    this.remarketingGroupsQuery.retargetingGroups$.subscribe(data => {
      this.containsPhones = toBoolean(data.find(x => x.type === 'phones'));
      this.containsEmails = toBoolean(data.find(x => x.type === 'emails'));

      if (!(this.containsPhones && this.containsEmails)) {
        if (data.length > 0) {
          this.form.get('type').setValue(data[0].type);
        }
      }
    });
  }

  private clearDestinationSettingsOnChangeDestinationCode() {
    this.platformsQuery.selectedDestinationPlatformCode$
      .pipe(
        untilDestroyed(this)
      )
      .subscribe(data => {
        this.form.get('type').setValue(RemarketingBaseType.EMAILS);
      });
  }

  private initComponentOnAssignPlatform() {
    this.assignationsQuery.isAssigned$(AssignationPlatforms.MY_TAREGT)
      .subscribe(isPlatformAssigned => {
        if (isPlatformAssigned) {
          this.LoadRemarketingGroups();
          this.subscribeToSplitByPipelinesControl();
          this.subscribeToPipelineStatusesChanging();
          this.subscribeToPipelineStatusType();
          this.combinePipelineStatusesOnChangingLeadAndDealsPipelines();
          this.subscribeToTypeControl();
        }
      });
  }

  private LoadRemarketingGroups() {
    this.remakertingGroupsService.LoadRemarketingGroupsFromMt();
  }

  public subscribeToSplitByPipelinesControl() {
    this.form.get('split_by_pipeline_statuses')
      .valueChanges
      .subscribe((data) => {
        console.log('SELECT WTF???');
        this.splitByPipelines = data;
        this.resetRetargetingGroupsForAllControls();
      });
  }

  public subscribeToTypeControl() {
    this.form.get('type')
      .valueChanges
      .subscribe((data) => {
        this.remakertingGroupsService.SetFilter(data);
        this.resetRetargetingGroupsForAllControls();
      });
  }

  public subscribeToPipelineStatusType() {
    this.fm.selectValue('originPlatformSettings')
      .subscribe(({section}) => {
        console.log(section, 'SECTION');
        if (section === 'CONTACTS') {
          this.withoutSplittingLabel = 'Передавать данные в списки пользователей';
        } else if (section === 'UON_MARKS_EXPORT') {
          this.form.get('split_by_pipeline_statuses').setValue(true);
        } else {
          this.withoutSplittingLabel = 'Передавать данные всей воронки в списки';
        }
      });
  }

  /**
   *
   * @param pipelineStatuses
   */
  public changePipelineStatusesFormGroups(pipelineStatuses: CrmPipelineStatus[]) {
    const pipelineStatusesFG = this.form.get('pipeline_statuses');
    this.resetPipelineStatusesGroups();
    pipelineStatuses.map(pipelineStatus => {
      this.AddPipelineStatus(pipelineStatus);
    });
    this.fm.upsert('destinationPlatformSettings', this.form);
  }

  public resetPipelineStatusesGroups() {
    const groups = {
      pipeline_null: new FormGroup({
        pipeline_id: new FormControl(),
        pipeline_type: new FormControl(),
        pipeline_retargeting_groups: new FormControl()
      })
    };
    this.form.setControl('pipeline_statuses', new FormGroup(groups));
  }

  /**
   * Add new pipeline status to form
   * @param pipelineStatus
   * @constructor
   */
  public AddPipelineStatus(pipelineStatus: CrmPipelineStatus) {
    const pipelineStatusesFG = this.form.get('pipeline_statuses') as FormGroup;
    const pipelineStatusControlName = pipelineStatus !== null ? `pipeline_${pipelineStatus.type}_${pipelineStatus.id}` : 'pipeline_null';
    const pipelineId = pipelineStatus !== null ? pipelineStatus.id : null;
    const pipelineType = pipelineStatus !== null ? pipelineStatus.type : 'LEADS';
    pipelineStatusesFG.addControl(pipelineStatusControlName, this.createPipelineStatusFormGroup(pipelineId, pipelineType));
  }

  /**
   * Создать новую группу статуса воронки продаж
   * @param pipelineId
   * @param pipelineType
   */
  public createPipelineStatusFormGroup(pipelineId, pipelineType) {
    return new FormGroup({
      pipeline_id: new FormControl(pipelineId),
      pipeline_type: new FormControl(pipelineType),
      pipeline_retargeting_groups: new FormControl()
    });
  }

  /**
   * Reset retargeting groups value for all controls
   */
  public resetRetargetingGroupsForAllControls() {
    const keys = Object.keys((this.form.get('pipeline_statuses') as FormGroup).controls);
    keys
      .map(key => key.replace('pipeline_', ''))
      .map(key => this.getControlByPipelineId(key))
      .forEach(control => control.reset());
  }

  getControlByPipelineId(pipelineId = null) {
    const formControl = this.form
      .get('pipeline_statuses')
      .get(`pipeline_${pipelineId}`)
      .get('pipeline_retargeting_groups') as FormControl;

    return formControl;
  }

  private GetSelectedRemarketingGroups() {
    const formValue = this.form.value as IMtRemarketingGroupSettingsModel;
    const pipelineStatuses = formValue.pipeline_statuses;
    const selectedRemarketingGroups = Object.keys(pipelineStatuses)
      .map(pipelineId => pipelineStatuses[pipelineId])
      .filter(pipelineStatus => pipelineStatus.pipeline_retargeting_groups !== null)
      .map(pipelineStatus => pipelineStatus.pipeline_retargeting_groups)
      .reduce((acc, curr) => acc.concat(curr), []);

    return Array.from(new Set(selectedRemarketingGroups)) || [];
  }

  private subscribeAllPipelineStatusesControls() {
    const pipelineStatusesControls = (this.form.get('pipeline_statuses') as FormGroup).controls;
    Object.keys(pipelineStatusesControls)
      .map(key => pipelineStatusesControls[key] as FormGroup)
      .map(x => x.get('pipeline_retargeting_groups'))
      .forEach((control) => {
        control.valueChanges
          .subscribe((data) => {
            if (data && data.length > 10) {
              control.setValue(data.filter((item, index) => index < 10));
            }
            this.selectedRemarketingGroups = this.GetSelectedRemarketingGroups();
          });
      });
  }

  ngOnDestroy(): void {
  }


  withoutSplitSuggestionCode() {
    if (!this.isAllContacts) {
      return 'MT_REMARKETING_GROUPS_GROUPS_SELECTOR_PIPELINES';
    }
    return 'MT_REMARKETING_GROUPS_GROUPS_SELECTOR_ALL_CONTACTS';
  }
}
