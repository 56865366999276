import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AffiliateProgramSettingsReportRoutingModule } from './affiliate-program-settings-report-routing.module';
import { AffiliateProgramFinancialReportTableComponent } from './components/affiliate-program-financial-report-table/affiliate-program-financial-report-table.component';
import { AffiliateProgramMemberLinkComponent } from './components/affiliate-program-member-link/affiliate-program-member-link.component';
import { AffiliateProgramSettingsReportRouteComponent } from './routes/affiliate-program-settings-report-route/affiliate-program-settings-report-route.component';
import { AffiliateProgramDescriptionComponent } from './components/affiliate-program-description/affiliate-program-description.component';
import {SharedModule} from "../../../shared/shared.module";
import { AffiliateReportTableComponent } from './components/affiliate-report-table/affiliate-report-table.component';
import { AffiliateTileComponent } from './components/affiliate-tile/affiliate-tile.component';
import { FullBalanceTileContentComponent } from './components/full-balance-tile-content/full-balance-tile-content.component';
import { ToPayTileContentComponent } from './components/to-pay-tile-content/to-pay-tile-content.component';
import { RegistratedTileContentComponent } from './components/registrated-tile-content/registrated-tile-content.component';
import { PayedTileContentComponent } from './components/payed-tile-content/payed-tile-content.component';
import { AmountComponent } from './components/amount/amount.component';

@NgModule({
  declarations: [AffiliateProgramFinancialReportTableComponent, AffiliateProgramMemberLinkComponent, AffiliateProgramSettingsReportRouteComponent, AffiliateProgramDescriptionComponent, AffiliateReportTableComponent, AffiliateTileComponent, FullBalanceTileContentComponent, ToPayTileContentComponent, RegistratedTileContentComponent, PayedTileContentComponent, AmountComponent],
  imports: [
    CommonModule,
    AffiliateProgramSettingsReportRoutingModule,
    SharedModule
  ]
})
export class AffiliateProgramSettingsReportModule { }
