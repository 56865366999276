import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
@Pipe({
  name: 'secondsToDate'
})
export class SecondsToDatePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    const t = new Date(1970, 0, 1); // Epoch
    t.setUTCSeconds(value);
    console.log(t);
    console.log(moment(value * 1000).toDate().getHours());
    return moment(value * 1000).toDate();
  }

}
