var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { QueryEntity } from '@datorama/akita';
import { CustomFieldValueStore } from './custom-field-value.store';
import { PlatformsQuery } from "../platforms/platforms.query";
import { flatMap } from "rxjs/operators";
import * as i0 from "@angular/core";
import * as i1 from "./custom-field-value.store";
import * as i2 from "../platforms/platforms.query";
var CustomFieldValueQuery = /** @class */ (function (_super) {
    __extends(CustomFieldValueQuery, _super);
    function CustomFieldValueQuery(store, platformsQuery) {
        var _this = _super.call(this, store) || this;
        _this.store = store;
        _this.platformsQuery = platformsQuery;
        _this.CustomFieldsValues$ = _this.platformsQuery.selectedOriginPlatformCode$
            .pipe(flatMap(function (code) { return _this.selectAll({
            filterBy: function (customValue) { return customValue.PlatformCode === code; }
        }); }));
        return _this;
    }
    CustomFieldValueQuery.ngInjectableDef = i0.defineInjectable({ factory: function CustomFieldValueQuery_Factory() { return new CustomFieldValueQuery(i0.inject(i1.CustomFieldValueStore), i0.inject(i2.PlatformsQuery)); }, token: CustomFieldValueQuery, providedIn: "root" });
    return CustomFieldValueQuery;
}(QueryEntity));
export { CustomFieldValueQuery };
