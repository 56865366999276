/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { APIReponseWrapperListUserIntegration } from '../models/apireponse-wrapper-list-user-integration';
import { APIReponseWrapperUserIntegration } from '../models/apireponse-wrapper-user-integration';
import { PostMonitorBackend_V2ModelsIntegrationsSharedUserIntegrationData } from '../models/post-monitor-backend-_v2models-integrations-shared-user-integration-data';
import { UserIntegration } from '../models/user-integration';
import { APIReponseWrapperListUserIntegrationStatistics } from '../models/apireponse-wrapper-list-user-integration-statistics';
import { APIReponseWrapperint } from '../models/apireponse-wrapperint';
@Injectable({
  providedIn: 'root',
})
class UserIntegrationService extends __BaseService {
  static readonly GetUserIntegrationsPath = '/apiv2/user-integration';
  static readonly AddUserIntegrationPath = '/apiv2/user-integration';
  static readonly RemoveUserIntegrationPath = '/apiv2/user-integration';
  static readonly GetIntegrationDetailedStatisticsPath = '/apiv2/user-integration/{integrationId}/stats/detailed';
  static readonly GetIntegrationSummaryStatisticsPath = '/apiv2/user-integration/{integrationId}/stats';
  static readonly RenameUserIntegrationPath = '/apiv2/user-integration/name';
  static readonly ChangeUserIntegrationStatusPath = '/apiv2/user-integration/status';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `UserIntegrationService.GetUserIntegrationsParams` containing the following parameters:
   *
   * - `showRemoved`:
   *
   * - `integrationId`:
   *
   * @return Success
   */
  GetUserIntegrationsResponse(params: UserIntegrationService.GetUserIntegrationsParams): __Observable<__StrictHttpResponse<APIReponseWrapperListUserIntegration>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.showRemoved != null) __params = __params.set('showRemoved', params.showRemoved.toString());
    if (params.integrationId != null) __params = __params.set('integrationId', params.integrationId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/user-integration`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<APIReponseWrapperListUserIntegration>;
      })
    );
  }
  /**
   * @param params The `UserIntegrationService.GetUserIntegrationsParams` containing the following parameters:
   *
   * - `showRemoved`:
   *
   * - `integrationId`:
   *
   * @return Success
   */
  GetUserIntegrations(params: UserIntegrationService.GetUserIntegrationsParams): __Observable<APIReponseWrapperListUserIntegration> {
    return this.GetUserIntegrationsResponse(params).pipe(
      __map(_r => _r.body as APIReponseWrapperListUserIntegration)
    );
  }

  /**
   * @param params The `UserIntegrationService.AddUserIntegrationParams` containing the following parameters:
   *
   * - `toId`:
   *
   * - `fromId`:
   *
   * - `model`:
   *
   * @return Success
   */
  AddUserIntegrationResponse(params: UserIntegrationService.AddUserIntegrationParams): __Observable<__StrictHttpResponse<APIReponseWrapperUserIntegration>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.toId != null) __params = __params.set('toId', params.toId.toString());
    if (params.fromId != null) __params = __params.set('fromId', params.fromId.toString());
    __body = params.model;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/apiv2/user-integration`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<APIReponseWrapperUserIntegration>;
      })
    );
  }
  /**
   * @param params The `UserIntegrationService.AddUserIntegrationParams` containing the following parameters:
   *
   * - `toId`:
   *
   * - `fromId`:
   *
   * - `model`:
   *
   * @return Success
   */
  AddUserIntegration(params: UserIntegrationService.AddUserIntegrationParams): __Observable<APIReponseWrapperUserIntegration> {
    return this.AddUserIntegrationResponse(params).pipe(
      __map(_r => _r.body as APIReponseWrapperUserIntegration)
    );
  }

  /**
   * @param integrationId undefined
   * @return Success
   */
  RemoveUserIntegrationResponse(integrationId: number): __Observable<__StrictHttpResponse<UserIntegration>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (integrationId != null) __params = __params.set('integrationId', integrationId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/apiv2/user-integration`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserIntegration>;
      })
    );
  }
  /**
   * @param integrationId undefined
   * @return Success
   */
  RemoveUserIntegration(integrationId: number): __Observable<UserIntegration> {
    return this.RemoveUserIntegrationResponse(integrationId).pipe(
      __map(_r => _r.body as UserIntegration)
    );
  }

  /**
   * @param params The `UserIntegrationService.GetIntegrationDetailedStatisticsParams` containing the following parameters:
   *
   * - `sortDesc`:
   *
   * - `offset`:
   *
   * - `integrationId`:
   *
   * - `dateTo`:
   *
   * - `dateFrom`:
   *
   * @return Success
   */
  GetIntegrationDetailedStatisticsResponse(params: UserIntegrationService.GetIntegrationDetailedStatisticsParams): __Observable<__StrictHttpResponse<APIReponseWrapperListUserIntegrationStatistics>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortDesc != null) __params = __params.set('sortDesc', params.sortDesc.toString());
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());

    if (params.dateTo != null) __params = __params.set('dateTo', params.dateTo.toString());
    if (params.dateFrom != null) __params = __params.set('dateFrom', params.dateFrom.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/user-integration/${(params.integrationId)}/stats/detailed`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<APIReponseWrapperListUserIntegrationStatistics>;
      })
    );
  }
  /**
   * @param params The `UserIntegrationService.GetIntegrationDetailedStatisticsParams` containing the following parameters:
   *
   * - `sortDesc`:
   *
   * - `offset`:
   *
   * - `integrationId`:
   *
   * - `dateTo`:
   *
   * - `dateFrom`:
   *
   * @return Success
   */
  GetIntegrationDetailedStatistics(params: UserIntegrationService.GetIntegrationDetailedStatisticsParams): __Observable<APIReponseWrapperListUserIntegrationStatistics> {
    return this.GetIntegrationDetailedStatisticsResponse(params).pipe(
      __map(_r => _r.body as APIReponseWrapperListUserIntegrationStatistics)
    );
  }

  /**
   * @param params The `UserIntegrationService.GetIntegrationSummaryStatisticsParams` containing the following parameters:
   *
   * - `integrationId`:
   *
   * - `dateTo`:
   *
   * - `dateFrom`:
   *
   * @return Success
   */
  GetIntegrationSummaryStatisticsResponse(params: UserIntegrationService.GetIntegrationSummaryStatisticsParams): __Observable<__StrictHttpResponse<APIReponseWrapperint>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.dateTo != null) __params = __params.set('dateTo', params.dateTo.toString());
    if (params.dateFrom != null) __params = __params.set('dateFrom', params.dateFrom.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/user-integration/${(params.integrationId)}/stats`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<APIReponseWrapperint>;
      })
    );
  }
  /**
   * @param params The `UserIntegrationService.GetIntegrationSummaryStatisticsParams` containing the following parameters:
   *
   * - `integrationId`:
   *
   * - `dateTo`:
   *
   * - `dateFrom`:
   *
   * @return Success
   */
  GetIntegrationSummaryStatistics(params: UserIntegrationService.GetIntegrationSummaryStatisticsParams): __Observable<APIReponseWrapperint> {
    return this.GetIntegrationSummaryStatisticsResponse(params).pipe(
      __map(_r => _r.body as APIReponseWrapperint)
    );
  }

  /**
   * @param params The `UserIntegrationService.RenameUserIntegrationParams` containing the following parameters:
   *
   * - `integrationId`:
   *
   * - `name`:
   *
   * @return Success
   */
  RenameUserIntegrationResponse(params: UserIntegrationService.RenameUserIntegrationParams): __Observable<__StrictHttpResponse<APIReponseWrapperUserIntegration>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.integrationId != null) __params = __params.set('integrationId', params.integrationId.toString());
    __body = params.name;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/apiv2/user-integration/name`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<APIReponseWrapperUserIntegration>;
      })
    );
  }
  /**
   * @param params The `UserIntegrationService.RenameUserIntegrationParams` containing the following parameters:
   *
   * - `integrationId`:
   *
   * - `name`:
   *
   * @return Success
   */
  RenameUserIntegration(params: UserIntegrationService.RenameUserIntegrationParams): __Observable<APIReponseWrapperUserIntegration> {
    return this.RenameUserIntegrationResponse(params).pipe(
      __map(_r => _r.body as APIReponseWrapperUserIntegration)
    );
  }

  /**
   * @param params The `UserIntegrationService.ChangeUserIntegrationStatusParams` containing the following parameters:
   *
   * - `status`:
   *
   * - `integrationId`:
   *
   * @return Success
   */
  ChangeUserIntegrationStatusResponse(params: UserIntegrationService.ChangeUserIntegrationStatusParams): __Observable<__StrictHttpResponse<APIReponseWrapperUserIntegration>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.status != null) __params = __params.set('status', params.status.toString());
    if (params.integrationId != null) __params = __params.set('integrationId', params.integrationId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/apiv2/user-integration/status`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<APIReponseWrapperUserIntegration>;
      })
    );
  }
  /**
   * @param params The `UserIntegrationService.ChangeUserIntegrationStatusParams` containing the following parameters:
   *
   * - `status`:
   *
   * - `integrationId`:
   *
   * @return Success
   */
  ChangeUserIntegrationStatus(params: UserIntegrationService.ChangeUserIntegrationStatusParams): __Observable<APIReponseWrapperUserIntegration> {
    return this.ChangeUserIntegrationStatusResponse(params).pipe(
      __map(_r => _r.body as APIReponseWrapperUserIntegration)
    );
  }
}

module UserIntegrationService {

  /**
   * Parameters for GetUserIntegrations
   */
  export interface GetUserIntegrationsParams {
    showRemoved: boolean;
    integrationId?: number;
  }

  /**
   * Parameters for AddUserIntegration
   */
  export interface AddUserIntegrationParams {
    toId: number;
    fromId: number;
    model?: PostMonitorBackend_V2ModelsIntegrationsSharedUserIntegrationData;
  }

  /**
   * Parameters for GetIntegrationDetailedStatistics
   */
  export interface GetIntegrationDetailedStatisticsParams {
    sortDesc: boolean;
    offset: number;
    integrationId: number;
    dateTo?: string;
    dateFrom?: string;
  }

  /**
   * Parameters for GetIntegrationSummaryStatistics
   */
  export interface GetIntegrationSummaryStatisticsParams {
    integrationId: number;
    dateTo?: string;
    dateFrom?: string;
  }

  /**
   * Parameters for RenameUserIntegration
   */
  export interface RenameUserIntegrationParams {
    integrationId: number;
    name?: string;
  }

  /**
   * Parameters for ChangeUserIntegrationStatus
   */
  export interface ChangeUserIntegrationStatusParams {
    status: boolean;
    integrationId: number;
  }
}

export { UserIntegrationService }
