/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./strategy-preview.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/radio/typings/index.ngfactory";
import * as i3 from "@angular/material/radio";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/cdk/collections";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "@clr/angular";
import * as i8 from "@angular/common";
import * as i9 from "./strategy-preview.component";
var styles_StrategyPreviewComponent = [i0.styles];
var RenderType_StrategyPreviewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StrategyPreviewComponent, data: {} });
export { RenderType_StrategyPreviewComponent as RenderType_StrategyPreviewComponent };
function View_StrategyPreviewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u0421\u043A\u0440\u044B\u0442\u044C"]))], null, null); }
function View_StrategyPreviewComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\u041F\u043E\u0434\u0440\u043E\u0431\u043D\u0435\u0435"]))], null, null); }
export function View_StrategyPreviewComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "vk-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "mat-radio-button", [["class", "mat-radio-button"], ["color", "primary"]], [[2, "mat-radio-checked", null], [2, "mat-radio-disabled", null], [2, "_mat-animation-noopable", null], [1, "tabindex", 0], [1, "id", 0]], [[null, "change"], [null, "focus"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("focus" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2)._inputElement.nativeElement.focus() !== false);
        ad = (pd_0 && ad);
    } if (("change" === en)) {
        var pd_1 = (_co.change() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_MatRadioButton_0, i2.RenderType_MatRadioButton)), i1.ɵdid(2, 4440064, null, 0, i3.MatRadioButton, [[2, i3.MatRadioGroup], i1.ElementRef, i1.ChangeDetectorRef, i4.FocusMonitor, i5.UniqueSelectionDispatcher, [2, i6.ANIMATION_MODULE_TYPE]], { color: [0, "color"], checked: [1, "checked"] }, { change: "change" }), (_l()(), i1.ɵeld(3, 0, null, null, 2, "label", [["class", "vk-label"]], [[1, "for", 0]], null, null, null, null)), i1.ɵdid(4, 212992, null, 0, i7.ClrLabel, [[2, i7.ɵbe], [2, i7.ɵbf], [2, i7.ɵbg], i1.Renderer2, i1.ElementRef], null, null), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 5, "div", [["class", "vk-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "div", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 3, "a", [["class", "strategy-preview__show-more"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showMore() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StrategyPreviewComponent_1)), i1.ɵdid(10, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["detailsHidden", 2]], null, 0, null, View_StrategyPreviewComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_5 = "primary"; var currVal_6 = (_co.selected === _co.strategy.code); _ck(_v, 2, 0, currVal_5, currVal_6); _ck(_v, 4, 0); var currVal_10 = _co.detailsShowed; var currVal_11 = i1.ɵnov(_v, 11); _ck(_v, 10, 0, currVal_10, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 2).checked; var currVal_1 = i1.ɵnov(_v, 2).disabled; var currVal_2 = (i1.ɵnov(_v, 2)._animationMode === "NoopAnimations"); var currVal_3 = (0 - 1); var currVal_4 = i1.ɵnov(_v, 2).id; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_7 = i1.ɵnov(_v, 4).forAttr; _ck(_v, 3, 0, currVal_7); var currVal_8 = _co.strategy.title; _ck(_v, 5, 0, currVal_8); var currVal_9 = _co.StrategyDescription; _ck(_v, 7, 0, currVal_9); }); }
export function View_StrategyPreviewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-strategy-preview", [], null, null, null, View_StrategyPreviewComponent_0, RenderType_StrategyPreviewComponent)), i1.ɵdid(1, 114688, null, 0, i9.StrategyPreviewComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StrategyPreviewComponentNgFactory = i1.ɵccf("app-strategy-preview", i9.StrategyPreviewComponent, View_StrategyPreviewComponent_Host_0, { strategy: "strategy", selected: "selected" }, { selectedChange: "selectedChange" }, []);
export { StrategyPreviewComponentNgFactory as StrategyPreviewComponentNgFactory };
