import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {NewIntegrationRoutingModule} from './new-integration-routing.module';
import {IntegrationNameFormComponent} from './components/integration-name-form/integration-name-form.component';
import {IntegrationPlatformSelectorComponent} from './components/integration-platform-selector/integration-platform-selector.component';
import {NewIntegrationComponent} from './routes/new-integration/new-integration.component';
import {IntegrationFormComponent} from './components/integration-form/integration-form.component';
import {VkControlsModule} from '../vk-controls/vk-controls.module';
import {MatButtonModule, MatIconModule, MatSelectModule} from '@angular/material';
import {ReactiveFormsModule} from '@angular/forms';
import {environment} from '../../environments/environment';
import {AkitaNgDevtools} from '@datorama/akita-ngdevtools';
import {SharedCrmModule} from './platforms-module/shared-crm/shared-crm.module';
import {SharedAdNetworkModule} from './platforms-module/shared-ad-network/shared-ad-network.module';
import {SharedModule} from '../shared/shared.module';
import {VkAssignationScreenComponent} from './components/assignation-screens/vk-assignation-screen/vk-assignation-screen.component';
import {MtAssignationScreenComponent} from './components/assignation-screens/mt-assignation-screen/mt-assignation-screen.component';
import {AmoCrmAssignationScreenComponent} from './components/assignation-screens/amo-crm-assignation-screen/amo-crm-assignation-screen.component';
import {BitrixAssignationScreenComponent} from './components/assignation-screens/bitrix-assignation-screen/bitrix-assignation-screen.component';
import {AmoCrmAssignationDialogComponent} from './components/assignation-dialogs/amo-crm-assignation-dialog/amo-crm-assignation-dialog.component';
import {BitrixAssignationDialogComponent} from './components/assignation-dialogs/bitrix-assignation-dialog/bitrix-assignation-dialog.component';
import {MegaplanAssignationScreenComponent} from './components/assignation-screens/megaplan-assignation-screen/megaplan-assignation-screen.component';
import {MegaplanAssignationDialogComponent} from './components/assignation-dialogs/megaplan-assignation-dialog/megaplan-assignation-dialog.component';
import {BitrixModule} from './platforms-module/bitrix/bitrix.module';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {LoadingModule} from '../loading/loading.module';
import {SaveIntegrationProcessDialogComponent} from './components/dialogs/save-integration-process-dialog/save-integration-process-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {ClrIconModule} from '@clr/angular';
import {UonTravelAssignationScreenComponent} from './components/assignation-screens/uon-travel-assignation-screen/uon-travel-assignation-screen.component';
import {UonTravelAssignationScreenDialogComponent} from './components/assignation-dialogs/uon-travel-assignation-screen-dialog/uon-travel-assignation-screen-dialog.component';
import {UOnTravelModule} from './platforms-module/u-on-travel/u-on-travel.module';
import {MoiSkladAssignationScreenComponent} from './components/assignation-screens/moi-sklad-assignation-screen/moi-sklad-assignation-screen.component';
import {MoiSkladAssignationDialogComponent} from './components/assignation-dialogs/moi-sklad-assignation-dialog/moi-sklad-assignation-dialog.component';
import {MoiSkladModule} from './platforms-module/moi-sklad/moi-sklad.module';
import { FacebookAssignationScreenComponent } from './components/assignation-screens/facebook-assignation-screen/facebook-assignation-screen.component';
import {FacebookModule} from "./platforms-module/facebook/facebook.module";
import { YandexMetrikaAssignationScreenComponent } from './components/assignation-screens/yandex-metrika-assignation-screen/yandex-metrika-assignation-screen.component';
import { YandexMetrikaModule } from './platforms-module/yandex-metrika/yandex-metrika.module';
import { FormMessageComponent } from './components/form-message/form-message.component';
import {FormMessagesService} from "./services/form-messages/form-messages.service";
import {NewImportCrmModule} from "./platforms-module/new-import-crm/new-import-crm.module";


@NgModule({
  declarations: [IntegrationNameFormComponent, IntegrationPlatformSelectorComponent, NewIntegrationComponent, IntegrationFormComponent, VkAssignationScreenComponent, MtAssignationScreenComponent, AmoCrmAssignationScreenComponent, BitrixAssignationScreenComponent, AmoCrmAssignationDialogComponent, BitrixAssignationDialogComponent, MegaplanAssignationScreenComponent, MegaplanAssignationDialogComponent, SaveIntegrationProcessDialogComponent, UonTravelAssignationScreenComponent, UonTravelAssignationScreenDialogComponent, MoiSkladAssignationDialogComponent, FacebookAssignationScreenComponent, MoiSkladAssignationScreenComponent, YandexMetrikaAssignationScreenComponent, FormMessageComponent],
  imports: [
    CommonModule,
    NewIntegrationRoutingModule,
    VkControlsModule,
    MatSelectModule,
    ReactiveFormsModule,
    environment.production ? [] : AkitaNgDevtools.forRoot(),
    SharedCrmModule,
    SharedAdNetworkModule,
    SharedModule,
    MatIconModule,
    MatButtonModule,
    BitrixModule,
    MatProgressSpinnerModule,
    LoadingModule,
    MatDialogModule,
    ClrIconModule,
    UOnTravelModule,
    MoiSkladModule,
    FacebookModule,
    YandexMetrikaModule,
    NewImportCrmModule
  ],
  entryComponents: [
    AmoCrmAssignationDialogComponent,
    BitrixAssignationDialogComponent,
    MegaplanAssignationDialogComponent,
    UonTravelAssignationScreenDialogComponent,
    MoiSkladAssignationDialogComponent
  ]
})
export class NewIntegrationModule {
}
