import {
  AfterContentInit,
  AfterViewChecked,
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import {PlatformsTypes} from '../../../../platforms-types';
import {AdNetworkGroupsService} from '../../../../stores/ad-network-groups/ad-network-groups.service';
import {AssignationPlatforms} from '../../../../stores/platform-assignations/platform-assignation.model';
import {PlatformsQuery} from '../../../../stores/platforms/platforms.query';

@Component({
  selector: 'app-ad-network-export',
  templateUrl: './ad-network-export.component.html',
  styleUrls: ['./ad-network-export.component.scss']
})
export class AdNetworkExportComponent implements OnInit, AfterContentInit, AfterViewInit, AfterViewChecked, OnChanges {

  @Input() public type: PlatformsTypes;
  @Input() public platform: AssignationPlatforms;

  constructor(
    private platformsQuery: PlatformsQuery,
    private adNetworkService: AdNetworkGroupsService
  ) {
  }

  ngOnInit() {
    // this.platformsQuery.selectedOriginPlatformCode$
    //   .subscribe(data => {
    //     console.log(data, 'CHANGIN CODE');
    //     this.adNetworkService.OpenGroup(null);
    //   })
  }

  ngAfterContentInit(): void {
  }

  ngAfterViewInit(): void {
  }

  ngAfterViewChecked(): void {
  }


  public CheckAssignation() {
  }

  ngOnChanges(changes: SimpleChanges): void {
  }
}
