import {Directive, TemplateRef} from '@angular/core';

@Directive({
  selector: 'appEditableColumn, [appEditableColumn]'
})
export class EditableColumnDirective {

  constructor(
    public template: TemplateRef<any>
  ) {
  }

}
