/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sign-in.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../components/forms/sign-in-form/sign-in-form.component.ngfactory";
import * as i3 from "../../components/forms/sign-in-form/sign-in-form.component";
import * as i4 from "@angular/router";
import * as i5 from "../../../api/services/accounts.service";
import * as i6 from "./sign-in.component";
var styles_SignInComponent = [i0.styles];
var RenderType_SignInComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SignInComponent, data: {} });
export { RenderType_SignInComponent as RenderType_SignInComponent };
export function View_SignInComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-sign-in-form", [], null, [[null, "onSubmit"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onSubmit" === en)) {
        var pd_0 = (_co.handleSubmit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_SignInFormComponent_0, i2.RenderType_SignInFormComponent)), i1.ɵdid(1, 114688, null, 0, i3.SignInFormComponent, [i4.ActivatedRoute, i5.AccountsService, i4.Router], null, { onSubmit: "onSubmit" })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_SignInComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-sign-in", [], null, null, null, View_SignInComponent_0, RenderType_SignInComponent)), i1.ɵdid(1, 114688, null, 0, i6.SignInComponent, [i5.AccountsService, i4.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SignInComponentNgFactory = i1.ɵccf("app-sign-in", i6.SignInComponent, View_SignInComponent_Host_0, {}, {}, []);
export { SignInComponentNgFactory as SignInComponentNgFactory };
