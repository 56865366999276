import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { YandexAudienceComponent } from './components/yandex-audience/yandex-audience.component';
import { YandexAudiencesImportComponent } from './components/yandex-audiences-import/yandex-audiences-import.component';
import { VkControlsModule } from '../../../vk-controls/vk-controls.module';
import { MatRadioModule, MatCheckboxModule, MatSelectModule } from '@angular/material';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../../shared/shared.module';
import { MyTargetModule } from '../my-target/my-target.module';

@NgModule({
  declarations: [YandexAudienceComponent, YandexAudiencesImportComponent],
  exports: [YandexAudienceComponent, YandexAudiencesImportComponent],
  imports: [
    CommonModule,
    MatRadioModule,
    VkControlsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatSelectModule,
    SharedModule,
    MyTargetModule
  ]
})
export class YandexMetrikaModule { }
