import { OnInit, QueryList, TemplateRef } from '@angular/core';
import { EditableColumnDirective } from '../../directives/editable-column.directive';
import { SortingType } from '../../models/SortingType';
import { DgColumnFilterDirective } from "../../directives/dg-column-filter.directive";
var DgColumnComponent = /** @class */ (function () {
    function DgColumnComponent() {
        this.align = 'left';
        this.hiddable = true;
        this.hidden = false;
        this.loading = false;
        this.sortable = true;
        this.sortingType = SortingType.DEFAULT;
        this.sortFunction = null;
        this.filterFunction = null;
    }
    Object.defineProperty(DgColumnComponent.prototype, "displayedTemplate", {
        get: function () {
            return this.template.first;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DgColumnComponent.prototype, "HeaderTemplate", {
        get: function () {
            return this.headerTemplate.first;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DgColumnComponent.prototype, "EditTemplate", {
        get: function () {
            var templates = this.editTemplate.toArray();
            if (templates.length > 0) {
                return templates[0].template;
            }
            else {
                return null;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DgColumnComponent.prototype, "FilterTemplate", {
        get: function () {
            return this.filterTemplate.first.template;
        },
        enumerable: true,
        configurable: true
    });
    DgColumnComponent.prototype.IsFilterMatch = function () {
        return this.filterType === 'match';
    };
    DgColumnComponent.prototype.IsFilterEquals = function () {
        return this.filterType === 'equals';
    };
    DgColumnComponent.prototype.IsFilterBetween = function () {
        return this.filterType === 'between';
    };
    DgColumnComponent.prototype.ToggleHidden = function () {
        this.hidden = !this.hidden;
    };
    Object.defineProperty(DgColumnComponent.prototype, "HasSummaryFunction", {
        get: function () {
            return this.summaryFunction && typeof this.summaryFunction === 'function';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DgColumnComponent.prototype, "useCustomSortingFunction", {
        get: function () {
            return this.sortFunction !== null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DgColumnComponent.prototype, "HasPagingSummaryFunction", {
        get: function () {
            return this.pagingSummaryFunction && typeof this.pagingSummaryFunction === 'function';
        },
        enumerable: true,
        configurable: true
    });
    DgColumnComponent.prototype.GetPagingSummaryFunctionResult = function (data, fullData) {
        if (this.HasPagingSummaryFunction) {
            return this.pagingSummaryFunction(data, fullData);
        }
        else {
            return this.summaryFunction(data, fullData);
        }
    };
    DgColumnComponent.prototype.ngOnInit = function () {
    };
    return DgColumnComponent;
}());
export { DgColumnComponent };
