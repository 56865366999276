<table class="payments-table">
  <thead class="payments-table__header">
  <tr>
    <th class="payments-table__cell_header">
      <app-payments-tariff-time-periods-selector></app-payments-tariff-time-periods-selector>
    </th>
    <ng-container *ngFor="let tariff of tariffPlans | async">
      <th>
        <app-payments-tariff-header [tariff]="tariff"></app-payments-tariff-header>
      </th>
    </ng-container>
  </tr>
  </thead>
  <tbody>
  <ng-container *ngFor="let feature of features  | async">
    <tr>
      <td [innerHtml]="feature?.name"></td>
      <ng-container *ngFor="let tariff of tariffPlans  | async">
        <td>
          <app-payments-tariff-feature-status [tariff]="tariff" [feature]="feature"></app-payments-tariff-feature-status>
        </td>
      </ng-container>
    </tr>
  </ng-container>
  </tbody>
</table>
