/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./user-experience-switcher.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../new-style-modal/new-style-modal.component.ngfactory";
import * as i3 from "../new-style-modal/new-style-modal.component";
import * as i4 from "@angular/common";
import * as i5 from "./user-experience-switcher.component";
import * as i6 from "@angular/router";
import * as i7 from "../../../api/services/user-experience-level.service";
var styles_UserExperienceSwitcherComponent = [i0.styles];
var RenderType_UserExperienceSwitcherComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UserExperienceSwitcherComponent, data: {} });
export { RenderType_UserExperienceSwitcherComponent as RenderType_UserExperienceSwitcherComponent };
function View_UserExperienceSwitcherComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u0412\u044B \u043F\u0435\u0440\u0435\u0445\u043E\u0434\u0438\u0442\u0435 \u0432 \u0440\u0430\u0437\u0434\u0435\u043B \u0434\u043B\u044F \u0440\u0430\u0431\u043E\u0442\u044B \u0441 \u0440\u0435\u043A\u043B\u0430\u043C\u043E\u0439 \u0412\u041A\u043E\u043D\u0442\u0430\u043A\u0442\u0435 \u0434\u043B\u044F \u041D\u043E\u0432\u0438\u0447\u043A\u043E\u0432."])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u0414\u043B\u044F \u0432\u043E\u0437\u0432\u0440\u0430\u0449\u0435\u043D\u0438\u044F \u0432 \u0444\u0443\u043D\u043A\u0446\u0438\u043E\u043D\u0430\u043B \u0434\u043B\u044F \u041F\u0440\u043E\u0444\u0435\u0441\u0441\u0438\u043E\u043D\u0430\u043B\u043E\u0432, \u043D\u0435\u043E\u0431\u0445\u043E\u0434\u0438\u043C\u043E \u043D\u0430\u0436\u0430\u0442\u044C \u0432 \u0432\u0435\u0440\u0445\u043D\u0435\u043C \u043F\u0440\u0430\u0432\u043E\u043C \u0443\u0433\u043B\u0443 \u201C"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u0414\u043B\u044F \u043F\u0440\u043E\u0444\u0435\u0441\u0441\u0438\u043E\u043D\u0430\u043B\u043E\u0432"])), (_l()(), i1.ɵted(-1, null, ["\u201D. "]))], null, null); }
function View_UserExperienceSwitcherComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u0412\u044B \u043F\u0435\u0440\u0435\u0445\u043E\u0434\u0438\u0442\u0435 \u0432 \u0440\u0430\u0437\u0434\u0435\u043B \u0434\u043B\u044F \u0440\u0430\u0431\u043E\u0442\u044B \u0441 \u0440\u0435\u043A\u043B\u0430\u043C\u043E\u0439 \u0412\u041A\u043E\u043D\u0442\u0430\u043A\u0442\u0435 \u0438 \u0440\u0430\u0431\u043E\u0442\u0435 \u0441 \u0438\u043D\u0442\u0435\u0433\u0440\u0430\u0446\u0438\u044F\u043C\u0438 \u0434\u043B\u044F \u041F\u0440\u043E\u0444\u0435\u0441\u0441\u0438\u043E\u043D\u0430\u043B\u043E\u0432. "])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u0414\u043B\u044F \u0432\u043E\u0437\u0432\u0440\u0430\u0449\u0435\u043D\u0438\u044F \u0432 \u0444\u0443\u043D\u043A\u0446\u0438\u043E\u043D\u0430\u043B \u0434\u043B\u044F \u041D\u043E\u0432\u0438\u0447\u043A\u043E\u0432, \u043D\u0435\u043E\u0431\u0445\u043E\u0434\u0438\u043C\u043E \u043D\u0430\u0436\u0430\u0442\u044C \u0432 \u0432\u0435\u0440\u0445\u043D\u0435\u043C \u043F\u0440\u0430\u0432\u043E\u043C \u0443\u0433\u043B\u0443 \u201C"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u0414\u043B\u044F \u043D\u043E\u0432\u0438\u0447\u043A\u043E\u0432"])), (_l()(), i1.ɵted(-1, null, ["\u201D "]))], null, null); }
export function View_UserExperienceSwitcherComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\u041E\u043F\u0435\u0440\u0430\u0446\u0438\u044F \u0432\u044B\u043F\u043E\u043B\u043D\u044F\u0435\u0442\u0441\u044F, \u043F\u043E\u0434\u043E\u0436\u0434\u0438\u0442\u0435... "])), (_l()(), i1.ɵeld(1, 0, null, null, 11, "app-new-style-modal", [], null, null, null, i2.View_NewStyleModalComponent_0, i2.RenderType_NewStyleModalComponent)), i1.ɵdid(2, 114688, null, 0, i3.NewStyleModalComponent, [], { isVisible: [0, "isVisible"] }, null), (_l()(), i1.ɵeld(3, 0, null, 0, 1, "div", [["class", "new-modal-header-content"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u0412\u0430\u0436\u043D\u043E! "])), (_l()(), i1.ɵeld(5, 0, null, 1, 4, "div", [["class", "new-modal-body-content"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserExperienceSwitcherComponent_1)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserExperienceSwitcherComponent_2)), i1.ɵdid(9, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, 2, 2, "div", [["class", "new-modal-buttons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "button", [["class", "vk-standart-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.Accept() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u041F\u043E\u043D\u044F\u0442\u043D\u043E "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showWarningModal; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.CheckExperienceLevelMatch(1, 1); _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.CheckExperienceLevelMatch(1, 2); _ck(_v, 9, 0, currVal_2); }, null); }
export function View_UserExperienceSwitcherComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-user-experience-switcher", [], null, null, null, View_UserExperienceSwitcherComponent_0, RenderType_UserExperienceSwitcherComponent)), i1.ɵdid(1, 114688, null, 0, i5.UserExperienceSwitcherComponent, [i6.ActivatedRoute, i6.Router, i7.UserExperienceLevelService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UserExperienceSwitcherComponentNgFactory = i1.ɵccf("app-user-experience-switcher", i5.UserExperienceSwitcherComponent, View_UserExperienceSwitcherComponent_Host_0, {}, {}, []);
export { UserExperienceSwitcherComponentNgFactory as UserExperienceSwitcherComponentNgFactory };
