import {Injectable} from '@angular/core';
import {
  AutomationVkService,
  FacebookAdAccountService, FacebookAdsService,
  IntegrationExtraDataService,
  MyTargetService,
  VkApiService
} from '../../../api/services';
import {AdNetworkFormsStore} from './ad-network-forms.store';
import {AdNetworkForm, createAdNetworkFormFromVk, createFbFrom, createMtForm} from './ad-network-form.model';
import {AdNetworkFormsQuery} from './ad-network-forms.query';
import {PlatformsTypes} from '../../platforms-types';
import {PlatformsQuery} from '../platforms/platforms.query';
import {combineLatest, from, of} from 'rxjs';
import {PlatformAssignationsService} from '../platform-assignations/platform-assignations.service';
import {FormGroupsModel} from '../../models/form-groups.model';
import {concatMap, delay, filter, flatMap, map, tap, toArray} from 'rxjs/operators';
import {AkitaNgFormsManager} from '@datorama/akita-ng-forms-manager';
import {unionAllArraysFromObjectsToArray} from '../../../utils/js/arrays';
import * as moment from 'moment';
import {waterfall} from "../../../utils/rxjs-operators/waterfall";

@Injectable({
  providedIn: 'root'
})
export class AdNetworkFormsService {
  private count: number;

  constructor(
    private store: AdNetworkFormsStore,
    private vkSerivce: AutomationVkService,
    private formsQuery: AdNetworkFormsQuery,
    private mtService: MyTargetService,
    private platformsQuery: PlatformsQuery,
    private vkApi: VkApiService,
    private integrationServiceData: IntegrationExtraDataService,
    private assignationService: PlatformAssignationsService,
    private fm: AkitaNgFormsManager,
    private facebookAdsService: FacebookAdsService
  ) {
    this.count = 0
  }

  /**
   * Получиь id последнего лида из результатов
   * @param leads - результат загрузки лидов
   * @param formId - id формы из которой загружены лиды
   * @param groupId - id группы к которой принадлежит форма
   * @constructor
   */
  private static ExtractLastLeadId(leads, formId, groupId) {
    if (leads.length > 0) {
      return {
        formId,
        groupId,
        lastLeadId: leads[0].lead_id
      };
    } else {
      return null;
    }
  }

  public LoadForms(groupId, type: PlatformsTypes) {
    console.log('WTF ???', type, PlatformsTypes.FB_LEAD_FORMS, type === PlatformsTypes.FB_LEAD_FORMS);
    this.store.set([]);
    switch (type) {
      case PlatformsTypes.VK_LEAD_FORMS:
        return this.LoadVkLeadForms(groupId);
        break;
      case PlatformsTypes.MT_LEAD_FORMS:
        return this.LoadMtLeadForms();
        break;
      case PlatformsTypes.FB_LEAD_FORMS:
        console.log('LOAD FACEBOOK LEADS FORMS');
        return this.LoadFbLeadForms(groupId);
        break;
    }
  }

  /**
   *
   * @param formsData
   * @param type
   * @constructor
   */
  public LoadLastIdOfSelectedLeadForms(type) {
    switch (type) {
      case PlatformsTypes.MT_LEAD_FORMS.toString():
        const ids = this.GetMtSelectedForms();
        return this.LoadLastLeadsIdsFromMtForms(ids);
        break;
      case PlatformsTypes.VK_LEAD_FORMS.toString():
        const formsData = this.GetVkSelectedForms();
        return this.LoadLastLeadsIdsFromVkForms(formsData);
        break;
      case PlatformsTypes.FB_LEAD_FORMS.toString():
        const formsData1 = this.GetVkSelectedForms();
        console.log(formsData1, 'FORMS DATA !');
        return this.LoadMtUdsFromVkForms(formsData1);
        break;
    }
  }

  public GetMtSelectedForms() {
    const settings = this.fm.getForm('originPlatformSettings').value;
    const formIds = Object.keys(settings)
      .map((formId) => ({formId, isSelected: settings[formId]}))
      .filter(({isSelected}) => isSelected)
      .map(({formId}) => parseInt(formId, 10));

    return formIds;
  }

  public GetVkSelectedForms() {
    const ids = this.fm.getForm('originPlatformSettings').value;
    console.log(ids, 'IDS');
    const resultIds = unionAllArraysFromObjectsToArray(ids);
    console.log(resultIds, 'RESULT IDS');
    return resultIds
      .map((group) => (group.replace('ad_', '').split('_')))
      .map(([groupId, formId]) => ({groupId, formId}));
  }

  /**
   * Loading all vk lead forms
   * @param groupId
   * @constructor
   */
  public LoadVkLeadForms(groupId: number) {
    this.store.setLoading(true);
    if (!(this.formsQuery.ContainsFormsWithGroupId(groupId))) {
      this.vkApi.GetLeadFormsListAsync({
        groupId: groupId,
        key: 'integration'
      })
        .subscribe(data => {
          if (data && data && data.length) {
            this.store.add(data.map(x => createAdNetworkFormFromVk(x)));
          }
          this.store.setLoading(false);
        });
    } else {
      this.store.setLoading(false);
    }
  }

  public LoadMtLeadForms() {
    console.log('CLEAR');
    this.store.set([]);
    combineLatest(
      this.mtService.GetLeadAdsForms({
        limit: 50,
        offset: 0,
      }),
      this.integrationServiceData.GetProviderExtraData('my-target__lead-ads')
    )
      .subscribe(([data, formFields]) => {
        this.store.set([]);
        console.log('CLEAR CLEAR');
        if (data && data.data) {
          this.store.set(data.data.map(x => createMtForm(x, formFields)));
        }
      });
  }

  public OpenForm(opened) {
    this.store.update(state => ({
      ...state,
      ui: {
        opened: opened !== state.ui.opened ? opened : null
      }
    }));
  }

  /**
   * Получить id последних лидов для всех форм
   * @param formsData {FormGroupsModel[]} - данные форм для которых нужно получить id последних лидов
   * @constructor
   */
  public LoadLastLeadsIdsFromVkForms(formsData: FormGroupsModel[]) {
    return from(formsData)
      .pipe(
        concatMap(({formId, groupId}) => this.LoadLeadFormData(formId, groupId)),
        filter(leadIdData => leadIdData !== null),
        toArray(),
        tap((leads) => {
          leads.forEach(({formId, groupId, lastLeadId}) => {
            console.log(lastLeadId);
            this.store.update(`${groupId}_${formId}`, {lastLeadId});
          });
        })
      );
  }

  /**
   * Получить данные о последнем id лида в форме
   * @param formId - id формы
   * @param groupId - id группы
   * @constructor
   */
  private LoadLeadFormData(formId, groupId) {
    return this.vkApi.GetLeadFormsLeadsAsync({
      formId,
      groupId,
      limit: 1,
      key: 'integration'
    }).pipe(
      delay(1000),
      map(({leads}) => AdNetworkFormsService.ExtractLastLeadId(leads, formId, groupId))
    );
  }

  /**
   * Загрузить данные об id последнего лида в формах
   * @param formIds - id форм
   * @constructor
   */
  private LoadLastLeadsIdsFromMtForms(formIds: number[]) {
    return from(formIds)
      .pipe(
        concatMap(formId => this.LoadMtFormInfo(formId)),
        toArray(),
        tap(leadsInfo => {
          leadsInfo.forEach(leadInfo => {
            if (leadInfo) {
              this.store.update(leadInfo.formId, {lastLeadId: leadInfo.leadId});
            }
          });
        })
      );
  }

  /**
   * Загрузить данные о последнем лиде формы
   * @param formId - id лид формы
   * @constructor
   */
  private LoadMtFormInfo(formId: number) {
    return this.mtService.GetLeadInfoAsync({
      formId,
      offset: 0,
      limit: 1,
      bannerIds: [],
      campaignIds: []
    })
      .pipe(
        delay(1000),
        map(data => {
          console.log(data);
          return data;
        }),
        flatMap(({records_found}) => this.mtService.GetLeadInfoAsync({
          formId,
          offset: records_found - 1,
          limit: 1,
          bannerIds: [],
          campaignIds: []
        })),
        map(data => {
          const result = {formId, leadId: null};

          if (data) {
            if (data.data) {
              const [lead] = data.data;
              if (lead) {
                result.leadId = lead.id;
              }
            }
          }
          return result;
        })
      )
      ;
  }

  private LoadFbLeadForms(id) {
    this.store.setLoading(true);
    combineLatest(
      this.facebookAdsService.FacebookGetAdsAsync({
        fields: ['name'],
        adAccountId: id,
        summary: ['total_count']
      }),
      this.integrationServiceData.GetProviderExtraData('facebook_lead-forms')
    )
      .pipe(
        tap((response: any) => {
          if (response[0] && response[0].data) {
            const responseData = response[0];
            const totalCount = responseData.data.summary.total_count;
            const count = responseData.data.data.length;
            const countOfRequests = totalCount / count - 1;
            const requests = []
            if (responseData.data.data) {
              const data = responseData.data.data.map(x => {
                  this.count++;
                  return createFbFrom({
                    id: `${x.id}`,
                    form_id: `${x.id}`,
                    group_id: id,
                    name: `${x.name}`,
                  }, response[1]);
                }
              )
              this.store.add(data);
            }
            let afterPage = responseData && responseData.data && responseData.data.paging && responseData.data.paging.cursors ? responseData.data.paging.cursors.after : null;
            
            if (countOfRequests <= 1) {
              this.store.setLoading(false);
            }

            for (let i = 0; i < countOfRequests; i++) {
              const request = () => {
                this.store.setLoading(true)
                return this.facebookAdsService.FacebookGetAdsAsync({
                  fields: ['name'],
                  adAccountId: id,
                  summary: ['total_count'],
                  pagingAfter: afterPage
                })
                  .pipe(
                    tap((newData: any) => {
                      this.store.setLoading(true)
                      this.store.upsertMany(newData.data.data.map((x, index) => {
                        this.count++;
                        return createFbFrom({
                          id: `${x.id}`,
                          form_id: `${x.id}`,
                          group_id: id,
                          name: `${x.name}`,
                        }, response[1])
                      }));
                      afterPage = newData && newData.data && newData.data.paging && newData.data.paging.cursors && newData.data.paging.cursors.after
                        ? newData.data.paging.cursors.after : null;
                      this.store.setLoading(true);
                    }),
                    delay(1000)
                  )
              }
              requests.push(request)
            }
            if (requests.length > 0) {
              waterfall(requests)
                .subscribe(() => {
                  this.store.setLoading(false);
                })
            } else {
              this.store.setLoading(false);
            }
          }
        })
      )
      .subscribe();
    return of([]);
  }

  private LoadMtUdsFromVkForms(formsData1: { formId: any; groupId: any }[]) {
    return from(formsData1)
      .pipe(
        concatMap(({groupId, formId}) => this.LoadLeadsFromFacebook(groupId)),
        filter(leadIdData => leadIdData !== null),
        toArray(),
        tap((leads) => {
          const lastDate = moment.max(leads.map(x => moment(x.created_time))).toDate();
          console.log(`${formsData1[0].groupId}`, {lastLeadCreateDate: lastDate}, 'LEADS LAST DATE');
          formsData1.forEach(formData => {
            this.store.update(`${formData.groupId}`, {lastLeadCreateDate: new Date()});
          });
          // leads.forEach((lead) => {
          //   console.log(JSON.stringify(lead));
          //   console.log(`${formsData1[0].groupId} LEAD ID`);
          //   // console.log(lastLeadId);

          // });
        })
      );
  }

  private LoadLeadsFromFacebook(groupId: any) {
    return this.facebookAdsService.FacebookGetAdLeadsAsyncResponse({
      adId: groupId,
    })
      .pipe(
        delay(1000),
        map(x => x.body.data['data'])
      );
  }
}
