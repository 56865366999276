import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-moderation-modal',
  templateUrl: './moderation-modal.component.html',
  styleUrls: ['./moderation-modal.component.scss']
})
export class ModerationModalComponent implements OnInit {

  public startAfterModerationError;
  public sendModerationError;
  public errorOnStart = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<ModerationModalComponent>
  ) {
  }

  ngOnInit() {
    this.errorOnStart = localStorage.getItem('error_code');
    this.sendModerationError = window.localStorage.getItem('error_code');
    this.startAfterModerationError = window.localStorage.getItem('start_after_moderation');
    if (this.sendModerationError) {
      localStorage.removeItem('send_moderation');
    }
    if (this.startAfterModerationError) {
      localStorage.removeItem('start_after_moderation');
    }
    if (this.errorOnStart) {
      localStorage.removeItem('error_code');
    }
  }

  CloseButton() {
    this.dialogRef.close();
  }
}
