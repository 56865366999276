<form [formGroup]="form">
  <vk-form-field [controlsWidth]="controlWidth" [labelWidth]="labelWidth">
    <vk-label>Выберите рекламный кабинет</vk-label>
    <select vkInput formControlName="accountId">
      <ng-container *ngFor="let account of accounts">
        <ng-container *ngIf="IsAccountAssigned(account); else assignedAccount">
          <option [value]="getAccountId(account)">{{account.account_name}}</option>
        </ng-container>
        <ng-template #assignedAccount>
          <option [value]="getAccountId(account)" disabled title="Данный акаунт не подключен к системе PostMonitor">{{account.account_name}}</option>
        </ng-template>
      </ng-container>
    </select>
  </vk-form-field>

  <vk-form-field [controlsWidth]="controlWidth" [labelWidth]="labelWidth">
    <vk-label>Выберите кампанию</vk-label>
    <select vkInput formControlName="campaignId">
      <ng-container *ngFor="let campaign of campaigns">
        <option [value]="campaign.id">{{campaign.name}}</option>
      </ng-container>
    </select>
    <vk-input-options>
      <span [style.marginRight]="'8px'">или</span><button class="vk-standart-button" (click)="OpenCreateCampaignDialog()">Создайте кампанию</button>
    </vk-input-options>
  </vk-form-field>
</form>
