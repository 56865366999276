import {Component, OnInit} from '@angular/core';
import {AdFormManagerService} from '../../services/ad-form-manager/ad-form-manager.service';

@Component({
  selector: 'app-new-price-and-strategy-settings',
  templateUrl: './new-price-and-strategy-settings.component.html',
  styleUrls: ['./new-price-and-strategy-settings.component.scss']
})
export class NewPriceAndStrategySettingsComponent implements OnInit {

  public controlWidth = '150px';
  public labelWidth = '280px';

  public get RateMin() {
    return this.adFormManagerService.MinRateValue;
  }

  public get RateMax() {
    return this.adFormManagerService.MaxRateValue;
  }

  public get form() {
    return this.adFormManagerService.getControl('rateData');
  }

  constructor(
    private adFormManagerService: AdFormManagerService
  ) {
  }

  ngOnInit() {
  }

  public IsStrategyModeAuto() {
    return this.adFormManagerService.IsStrategyModeAuto();
  }
}
