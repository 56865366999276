import { AfterViewInit, ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { PlatformsTypes } from '../../../../platforms-types';
import { AkitaNgFormsManager } from '@datorama/akita-ng-forms-manager';
import { unionAllArraysFromObjectsToArray } from '../../../../../utils/js/arrays';
import { PlatformsQuery } from '../../../../stores/platforms/platforms.query';
import { AdNetworkFormsQuery } from '../../../../stores/ad-network-forms/ad-network-forms.query';
import { FormBuilder, Validators } from '@angular/forms';
import { AssignationPlatforms } from '../../../../stores/platform-assignations/platform-assignation.model';
import { CrmPipelinesService } from '../../../../stores/crm-pipelines/crm-pipelines.service';
import { PlatformAssignationsQuery } from '../../../../stores/platform-assignations/platform-assignations.query';
import { CrmUsersService } from '../../../../stores/crm-users/crm-users.service';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';
import { CrmSectionValidator } from '../../../../validators/crm-section-validator';
import { PlatformsService } from '../../../../stores/platforms/platforms.service';
import { CrmPipelinesQuery } from '../../../../stores/crm-pipelines/crm-pipelines.query';
import { toBoolean } from '@datorama/akita';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { BitrixPipelinesSettingsMode } from '../../../bitrix/components/bitrix-export-settings/bitrix-export-settings.component';
import { BitrixService } from '../../../../../api/services';
import { CrmOrganisationService } from '../../../../stores/crm-organisation/crm-organisation.service';
import { CustomFieldsService } from "../../../../stores/custom-fields/custom-fields.service";
import { CustomFieldsQuery } from "../../../../stores/custom-fields/custom-fields.query";
import { CustomFieldValueService } from "../../../../stores/custom-field-value/custom-field-value.service";
var ImportCrmComponent = /** @class */ (function () {
    function ImportCrmComponent(fm, platformsQuery, formsQuery, fb, pipelineSettings, crmUsersService, assginationsQuery, platformService, pipelineQuery, bitrixService, organisationService, cdr, customFieldsService, customFieldsQuery, customFieldsValuesService) {
        this.fm = fm;
        this.platformsQuery = platformsQuery;
        this.formsQuery = formsQuery;
        this.fb = fb;
        this.pipelineSettings = pipelineSettings;
        this.crmUsersService = crmUsersService;
        this.assginationsQuery = assginationsQuery;
        this.platformService = platformService;
        this.pipelineQuery = pipelineQuery;
        this.bitrixService = bitrixService;
        this.organisationService = organisationService;
        this.cdr = cdr;
        this.customFieldsService = customFieldsService;
        this.customFieldsQuery = customFieldsQuery;
        this.customFieldsValuesService = customFieldsValuesService;
        this.destinationPlatform$ = this.platformsQuery.selectedDestinationPlatform$
            .pipe(
        // map(x => {
        //   console.log(x, 'SECTIONS OF COMPONENT');
        //   x.sections = x.sections.filter(x => this.ShowLeads() ? true : x.code !== 'LEADS');
        //   return x;
        // })
        );
        this.sections$ = combineLatest(this.platformsQuery.hasLeads$, this.platformsQuery.selectedDestinationPlatform$)
            .pipe(map(function (_a) {
            var hasLeads = _a[0], x = _a[1];
            return x.sections.filter(function (section) {
                return hasLeads ? true : section.code !== 'LEADS';
            });
        }));
        this.forms$ = this.formsQuery.selectedForms;
        this.forms = [];
        this.showPipelines = false;
        this.showAdditionalQuery$ = this.platformsQuery.selectedOriginPlatformCode$
            .pipe(map(function (originalPlatformCode) { return ['FB_LEAD_FORMS'].includes(originalPlatformCode); }));
        this.currentPage = 0;
        this.countOfPages = 1;
    }
    Object.defineProperty(ImportCrmComponent.prototype, "visibleForms", {
        get: function () {
            var _this = this;
            return this.forms.filter(function (_, i) {
                return i >= _this.currentPage * 10 && i < (_this.currentPage + 1) * 10;
            });
        },
        enumerable: true,
        configurable: true
    });
    ImportCrmComponent.prototype.IsLeadSelected = function (formId) {
        return this.fm.selectValue('destinationPlatformSettings')
            .pipe(
        // distinctUntilChanged(),
        map(function (formData) {
            // this.cdr.detectChanges();
            return formData && formData[formId] && formData[formId].LEADS ? formData[formId].LEADS.selected : false;
        }));
    };
    ImportCrmComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.fm.upsert('destinationPlatformSettings', this.fb.group({}));
        this.pipelineQuery.pipelines$.subscribe(function (data) {
            _this.pipelines = data;
            _this.showPipelines = data.length > 1;
        });
        this.formsQuery.selectedForms.subscribe(function (forms) {
            _this.forms = forms;
            _this.countOfPages = Math.ceil(forms.length / 10);
            console.log(_this.countOfPages, 'COUNT OF PAGES');
        });
        combineLatest(this.fm.selectValue('originPlatformSettings'), this.platformsQuery.selectedOriginPlatformCode$)
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var data = _a[0], code = _a[1];
            var form = _this.fm.getForm('destinationPlatformSettings');
            if (form) {
                var lastFormValue_1 = form.value;
                _this.fm.remove('destinationPlatformSettings');
                _this.resultForm = _this.fb.group({});
                var formIds_1 = unionAllArraysFromObjectsToArray(data);
                if (code === PlatformsTypes.MT_LEAD_FORMS) {
                    formIds_1 = Object.keys(data)
                        .map(function (x) { return ({ formId: x, isSelected: data[x] }); })
                        .filter(function (_a) {
                        var isSelected = _a.isSelected;
                        return isSelected;
                    })
                        .map(function (_a) {
                        var formId = _a.formId;
                        return formId;
                    });
                }
                combineLatest(_this.destinationPlatform$, _this.customFieldsQuery.CustomFields$)
                    .pipe(
                // distinctUntilChanged(),
                // debounceTime(1000)
                )
                    .subscribe(function (_a) {
                    var destinationPlatform = _a[0], customFields = _a[1];
                    _this.platformService.ClearRequiredFields();
                    var globalForm = {};
                    formIds_1
                        .forEach(function (formId) {
                        globalForm[formId] = {};
                        // console.log(, 'FORM VALUE');
                        var formSettings = {
                            includeCampaignName: _this.fb.control(form.value[formId] ? form.value[formId].includeCampaignName : false),
                            includeLeadFormName: _this.fb.control(form.value[formId] ? form.value[formId].includeLeadFormName : false),
                            bindContact: _this.fb.control(form.value[formId] ? form.value[formId].bindContact : true)
                        };
                        if (destinationPlatform) {
                            var currentFormValue_1 = lastFormValue_1 ? lastFormValue_1[formId] : null;
                            destinationPlatform.sections.forEach(function (section) {
                                var sectionForm = {};
                                var customFieldsForm = [];
                                var currentSectionValue = currentFormValue_1 ? currentFormValue_1[section.code] : null;
                                section.fields.forEach(function (field) {
                                    var fieldValue = currentSectionValue ? currentSectionValue.fields[field.code] : null;
                                    if (field.isRequired) {
                                        _this.platformService.AddRequiredField(formId + "_" + section.code + "_" + field.code);
                                    }
                                    if (field.code === 'PM_PIPELINE_ID') {
                                        fieldValue = fieldValue || _this.pipelines.length > 0 ? _this.pipelines[0].id : null;
                                    }
                                    sectionForm[field.code] = _this.fb.control(fieldValue, []);
                                });
                                customFields.forEach(function (CustomField) {
                                    // customFieldsForm[CustomField.Key] = this.fb.control('')
                                    sectionForm[CustomField.Key] = _this.fb.control(null, []);
                                    customFieldsForm.push(CustomField.Key);
                                });
                                formSettings[section.code] = _this.GenerateSectionForm(section, sectionForm, currentSectionValue ? currentSectionValue.selected : false, customFieldsForm);
                            });
                            globalForm[formId] = _this.fb.group(formSettings);
                        }
                        _this.resultForm = _this.fb.group(globalForm);
                    });
                    _this.fm
                        .upsert('destinationPlatformSettings', _this.resultForm, { arrControlFactory: function () { return _this.fb.control(null); } });
                });
            }
        });
        this.assginationsQuery
            .isPlatformAssigned$(this.type)
            .pipe(distinctUntilChanged(), untilDestroyed(this))
            .subscribe(function (data) {
            _this.pipelineSettings.LoadPipelines(_this.type);
            _this.crmUsersService.LoadUsers(_this.type, null);
            _this.organisationService.LoadOrganisations(_this.type);
            _this.customFieldsService.LoadCustomFields(_this.platform)
                .subscribe();
            if (_this.platform === AssignationPlatforms.BITRIX24) {
                _this.bitrixService.GetCrmSettingsMode()
                    .pipe(map(function (response) {
                    return response['result'];
                }))
                    .subscribe(function (crmSettingsMode) {
                    _this.platformService
                        .SetParameters(AssignationPlatforms.BITRIX24, {
                        hasLeads: BitrixPipelinesSettingsMode.CLASSIC_MODE === crmSettingsMode
                    });
                });
            }
        });
    };
    ImportCrmComponent.prototype.ClearFromsControls = function (formIds) {
        var _this = this;
        Object.keys(this.resultForm.controls)
            .forEach(function (formControlName) {
            if (!formIds.includes(formControlName)) {
                _this.resultForm.removeControl(formControlName);
            }
        });
    };
    ImportCrmComponent.prototype.GetFieldValidators = function (field) {
        var validators = [];
        if (field.isRequired) {
            validators.push(Validators.required);
        }
        return validators;
    };
    ImportCrmComponent.prototype.GenerateSectionForm = function (section, sectionForm, selected, sectionCustomFields) {
        var _this = this;
        if (sectionCustomFields === void 0) { sectionCustomFields = []; }
        var fieldsFormGroup = this.fb.group(sectionForm);
        var form = this.fb.group({
            selected: this.fb.control(section.isRequired || selected),
            fields: fieldsFormGroup,
            customFields: this.fb.control(sectionCustomFields)
        }, {
            validators: [
                CrmSectionValidator
            ]
        });
        form.get('selected')
            .valueChanges
            .subscribe(function (selectedData) {
            if (selectedData) {
                _this.EnableSectionValidators(section, form);
            }
            else {
                _this.DisabeSectionValidators(section, form);
            }
        });
        return form;
    };
    ImportCrmComponent.prototype.DisabeSectionValidators = function (section, form) {
        section.fields.forEach(function (field) {
            form.get('fields').get(field.code).setValidators([]);
        });
    };
    ImportCrmComponent.prototype.EnableSectionValidators = function (section, form) {
        var _this = this;
        section.fields.forEach(function (field) {
            var validators = _this.GetFieldValidators(field);
            form.get('fields').get(field.code).setValidators(validators);
        });
    };
    ImportCrmComponent.prototype.ngAfterViewInit = function () {
    };
    ImportCrmComponent.prototype.ngOnDestroy = function () {
    };
    ImportCrmComponent.prototype.GetFormControl = function (id, code) {
        var form = this.resultForm.controls[id];
        if (form) {
            var group = form.controls[code];
            if (group) {
                return group;
            }
            return null;
        }
        return null;
    };
    ImportCrmComponent.prototype.GetFormGroup = function (id) {
        var form = this.resultForm.controls[id];
        if (form) {
            return form;
        }
        else {
            return null;
        }
    };
    ImportCrmComponent.prototype.IsInitedFormId = function (x) {
        var isFormInited = toBoolean(this.resultForm.get(x));
        return isFormInited;
    };
    ImportCrmComponent.prototype.ShowLeads = function () {
        return this.platformsQuery.HasLeads;
    };
    ImportCrmComponent.prototype.ShowAdditionalSettings = function () {
        return this.type === PlatformsTypes.BITRIX_IMPORT &&
            (this.platformsQuery.selectedOriginPlatformCode === PlatformsTypes.FB_LEAD_FORMS);
    };
    ImportCrmComponent.prototype.ChangePage = function ($event) {
        this.currentPage = $event.pageIndex;
    };
    return ImportCrmComponent;
}());
export { ImportCrmComponent };
