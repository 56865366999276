var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { combineQueries, QueryEntity } from '@datorama/akita';
import { AdNetworkGroupsStore } from './ad-network-groups.store';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./ad-network-groups.store";
var AdNetworkGroupsQuery = /** @class */ (function (_super) {
    __extends(AdNetworkGroupsQuery, _super);
    function AdNetworkGroupsQuery(store) {
        var _this = _super.call(this, store) || this;
        _this.store = store;
        _this.searchGroupName$ = _this.select(function (state) { return state.ui.searchGroupName; })
            .pipe(map(function (x) { return x.toLowerCase(); }));
        _this.groups$ = _this.selectAll();
        _this.byIds$ = function (groupIds) { return _this.selectAll({
            filterBy: function (entity) {
                return groupIds && groupIds.length ? groupIds.includes(entity.id) : groupIds === entity.id;
            }
        }); };
        _this.isEmpty = combineQueries([
            _this.groups$,
            _this.selectLoading()
        ])
            .pipe(map(function (_a) {
            var groups = _a[0], isLoading = _a[1];
            return !isLoading && groups.length === 0;
        }));
        _this.isOpened$ = function (groupId) { return _this.select(function (state) { return state.ui.openedGroupId === groupId; }); };
        _this.isGroupSearched$ = function (group) { return _this.searchGroupName$
            .pipe(map(function (groupName) { return groupName && groupName.length > 0 && group ? new RegExp(groupName).test(group.name.toLowerCase()) : true; })); };
        return _this;
    }
    AdNetworkGroupsQuery.ngInjectableDef = i0.defineInjectable({ factory: function AdNetworkGroupsQuery_Factory() { return new AdNetworkGroupsQuery(i0.inject(i1.AdNetworkGroupsStore)); }, token: AdNetworkGroupsQuery, providedIn: "root" });
    return AdNetworkGroupsQuery;
}(QueryEntity));
export { AdNetworkGroupsQuery };
