/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./target-group.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../node_modules/@angular/material/divider/typings/index.ngfactory";
import * as i3 from "@angular/material/divider";
import * as i4 from "./target-group.component";
var styles_TargetGroupComponent = [i0.styles];
var RenderType_TargetGroupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TargetGroupComponent, data: {} });
export { RenderType_TargetGroupComponent as RenderType_TargetGroupComponent };
export function View_TargetGroupComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "ad-target-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "ad-target-group__name"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "ad-target-group__audience-count"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", " \u0447\u0435\u043B\u043E\u0432\u0435\u043A"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "mat-divider", [["class", "mat-divider"], ["role", "separator"]], [[1, "aria-orientation", 0], [2, "mat-divider-vertical", null], [2, "mat-divider-horizontal", null], [2, "mat-divider-inset", null]], null, null, i2.View_MatDivider_0, i2.RenderType_MatDivider)), i1.ɵdid(6, 49152, null, 0, i3.MatDivider, [], null, null)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.targetGroup.name; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.targetGroup.audience_count; _ck(_v, 4, 0, currVal_1); var currVal_2 = (i1.ɵnov(_v, 6).vertical ? "vertical" : "horizontal"); var currVal_3 = i1.ɵnov(_v, 6).vertical; var currVal_4 = !i1.ɵnov(_v, 6).vertical; var currVal_5 = i1.ɵnov(_v, 6).inset; _ck(_v, 5, 0, currVal_2, currVal_3, currVal_4, currVal_5); }); }
export function View_TargetGroupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-target-group", [], null, null, null, View_TargetGroupComponent_0, RenderType_TargetGroupComponent)), i1.ɵdid(1, 114688, null, 0, i4.TargetGroupComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TargetGroupComponentNgFactory = i1.ɵccf("app-target-group", i4.TargetGroupComponent, View_TargetGroupComponent_Host_0, { targetGroup: "targetGroup" }, {}, []);
export { TargetGroupComponentNgFactory as TargetGroupComponentNgFactory };
