import {Component, Input, OnInit} from '@angular/core';
import {StoreService} from "../../../../../services/store.service";
import {UsersIntegrationsService} from "../../../../../store/user-integrations/user-integration.service";
import {IntegrationSessionQuery} from "../../../../../store/integrations-session/integration-session.query";
import {map} from "rxjs/operators";

const styles = [
  {
    id: 0,
    name: 'Остановлено',
    style: {"color": "red"},
    icon: 'stop'
  },
  {
    id: 1,
    name: 'Запущено',
    style: {"color": "green"},
    icon: 'play_arrow'
  }
];

@Component({
  selector: 'app-user-integration-status',
  templateUrl: './user-integration-status.component.html',
  styleUrls: ['./user-integration-status.component.scss']
})
export class UserIntegrationStatusComponent implements OnInit {

  public isSessionExhausted$ = this.integrationSessionQuery.currentSession$
    .pipe(
      map(x => x.isLimitExhausted)
    );

  @Input() public status: any;
  @Input() public integrationId: number;
  @Input() public isRemoved: boolean;

  public get statusData(): any {
    return status;
  }

  public get StatusStyle() {
    return JSON.parse(this.status.style);
  }

  constructor(
    private store: StoreService,
    private userIntegrationsService: UsersIntegrationsService,
    private integrationSessionQuery: IntegrationSessionQuery
  ) {
  }

  ngOnInit() {
  }

  ChangeStatus(status: boolean) {
    this.userIntegrationsService.ChangeUserIntegrationStatus(this.integrationId, status);
  }
}
