<div class="teazer">
  <div class="teazer__image" [ngStyle]="{
    'background-image': 'url('+ TeazerImage +')'
  }">
    <div class="teazer-image__remove-button" (click)="removeImage()">
      <mat-icon>close</mat-icon>
    </div>
    <!--<img [src]="TeazerImage" class="teazer__image">-->
  </div>
  <div class="teazer__title">
    {{TeazerTitle}}
  </div>
  <div class="teazer__link">
    {{TeazerLink | domainOnly}}
  </div>
</div>

