import {Injectable} from '@angular/core';
import Dexie from 'dexie';
import {CoreService} from './core.service';
import {CampaignViewModel} from '../../../automation/models/view-models/campaign-view-model';

@Injectable()
export class DexieCampaignsService {

  table: Dexie.Table<CampaignViewModel, number>;

  constructor(
    private dexieService: CoreService
  ) {
    this.table = this.dexieService.table('campaigns');
  }

  getAll() {
    return this.table.toArray();
  }

  getAllByAccountId(account_id, clientId) {
    account_id = parseInt(account_id, 10);
    clientId = parseInt(clientId, 10);
    let query = 'accountId';
    let value = account_id;

    if (clientId) {
      query = '[accountId+clientId]';
      value = [account_id, clientId];
    }

    const a = this.table
      .where(query)
      .equals(value)
      .reverse()
      .sortBy('id');
    // .toArray();
    return a;
  }

  addCampaign(data) {
    return this.table.put(data);
  }

  setCampaigns(data: Array<CampaignViewModel>) {
    this.table.clear();
    return data.map(x => {
      this.addCampaign(x);
    });
  }

  findCampaignById(id: number) {
    return this.table
      .where('id')
      .equals(id)
      .first();
  }

  update(id: number, campaign) {
    return this.table
      .update(id, campaign);
  }
}
