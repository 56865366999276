import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AccountsService} from '../../../../api/services/accounts.service';
import {UtilsService} from '../../../../shared/services/utils.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector: 'app-sign-in-form',
    templateUrl: './sign-in-form.component.html',
    styleUrls: ['./sign-in-form.component.scss']
})
export class SignInFormComponent implements OnInit {

    public form: FormGroup;
    public errors: any;
    public loading = false;

    @Output()
    public onSubmit: EventEmitter<any> = new EventEmitter<any>();

    private source: string = null;

    constructor(
        private route: ActivatedRoute,
        private accoutsService: AccountsService,
        private router: Router
    ) {
    }

    ngOnInit() {
        this.source = this.route.snapshot.queryParams.source;

        this.form = new FormGroup({
            email: new FormControl('', [Validators.required, Validators.email]),
            password: new FormControl('', [
                Validators.required,
                Validators.minLength(4),
                Validators.maxLength(32)
            ]),
        });
    }

    submit($event) {
        $event.preventDefault();
        this.errors = {};
        if (this.form.valid) {
            this.loading = true;
            this.accoutsService
                .signIn(this.form.value)
                .subscribe(x => {
                    if (x) {
                        localStorage.setItem('token', x.token);
                        localStorage.setItem('token_expired_at', x.expiredAt);
                        //this.router.navigate(['/dashboard']);

                        // Redirects to proxy that registers Yandex.Metrika goal
                        this.router.navigate(['sign-up-completed'], {queryParams: {source: this.source}});
                    } else {
                        this.errors = {
                            system: [
                                'Неверный email или пароль'
                            ]
                        };
                    }
                    this.loading = false;
                }, err => {
                    this.errors = {
                        system: [
                            err.error.description
                        ]
                    };
                    this.loading = false;
                });
        } else {
            this.errors = UtilsService.GetErrorsByForm('authorization', this.form.controls);
        }
    }

    public ContainsSource(source: string): boolean {
        if (this.source) {

            if (source && source !== this.source) {
                return false;
            }

            return true;
        }

        return false;
    }
}
