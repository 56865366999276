import {defer} from "rxjs";
import "rxjs-compat/add/operator/delay";

export const waterfall = (series: Array<any>) => {
  return defer(() => {
    let acc = series[0]();
    for (let i = 1; i < series.length; i++) {
      (function (func) {
        acc = acc.switchMap(x => func(x));
      }(series[i]));
    }
    return acc;
  })
}
