<div class="groups-table-wrapper">
  <table mat-table [dataSource]="dataSource" matSort class="groups-table">

    <ng-container matColumnDef="avatar">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let group">
        <img [src]="group.photo_50" [alt]="group.name" class="group__avatar">
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Название группы</th>
      <td mat-cell *matCellDef="let group">{{group.name}}</td>
    </ng-container>

    <ng-container matColumnDef="functions">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Опции</th>
      <td mat-cell *matCellDef="let group">
        <button mat-icon-button (click)="toggleSelectedRow(group)">
          <mat-icon>
            close
          </mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayColumns"></tr>
  </table>

  <mat-paginator [pageSize]="10" showFirstLastButtons></mat-paginator>
</div>
