<form [formGroup]="form">
  <mat-vertical-stepper>
    <ng-template matStepperIcon="edit" let-index="index">
      {{index + 1}}
    </ng-template>

    <mat-step *ngIf="!ad">
      <ng-template matStepLabel>Выберите формат объявления</ng-template>

      <a
        href="https://postmonitor.ru/blog/instrukciya-dlya-1-shaga-sozdaniya-obyavleniya---vyberite-format-obyavleniya"
        target="_blank"
        rel="nofollow noopener"
        class="ad-form-step-instruction"
      >
        <span class="ion-md-bulb"></span>
        Рекомендации по выбору формата объявления.
      </a>
      <div class="vk-container">
        <div class="ad-format-strip">
          <div class="ad-format-strip__item ad-format-strip__item_full">
            <div class="ad-format-list">
              <ng-container *ngFor="let adFormat of adFormats">
                <div class="ad-format-list__item">
                  <app-ad-format
                    [adFormat]="adFormat"
                    [selectedField]="form.value.format"
                    formControlName="format"
                    [disabled]="isUpdating"
                  ></app-ad-format>
                </div>
              </ng-container>
            </div>
          </div>

          <div class="ad-format-strip__item">
            <div class="ad-format-preview-container">
              <img [src]="GetSelectedFormatePreview()" class="ad-format-preview">
              <div class="ad-format-preview-tip">
                Пример того, как объявление будет выглядеть ВКонтакте
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="vk-container">
        <button [disabled]="!form.controls.format.valid" class="vk-standart-button" (click)="SelectFormat()">Далее
        </button>
      </div>
    </mat-step>
    <mat-step>
      <div *matStepLabel>Создайте объявление</div>

      <a
        href="https://postmonitor.ru/blog/instrukciya-dlya-2-shaga-sozdaniya-obyavleniya---sozdajjte-obyavlenie"
        target="_blank"
        rel="nofollow noopener"
        class="ad-form-step-instruction"
      >
        <span class="ion-md-bulb"></span>
        Рекомендации по созданию объявления.
      </a>
      <app-ad-target-resource-selector
        [adSources]="adSources"
        [(ngModel)]="currentResource"
        (ngModelChange)="OnChangeCurrentResource($event)"
        [ngModelOptions]="{standalone: true}"
        *ngIf="!ad"
      ></app-ad-target-resource-selector>

      <ng-container *ngIf="IsPromopost; else teazerForm">
        <app-promopost-form-lite
          [groups]="groups"
          [adTargetResource]="currentResource"
          formGroupName="promopost"
          [isUpdating]="isUpdating"
        ></app-promopost-form-lite>
      </ng-container>
      <ng-template #teazerForm>
        <app-teaser-form-lite
          formGroupName="teazer"
          [groups]="groups"
          [adTargetResource]="currentResource"
          *ngIf="!IsSelectedFormatPromopost()"
          [isUpdating]="isUpdating"
        >
        </app-teaser-form-lite>
      </ng-template>


      <ng-container *ngIf="!IsCampaignsLoading; else CampaignLoadingText">
        <button class="vk-standart-button" [disabled]="!IsAdValid()" (click)="FinishEditingAd()">Далее</button>
      </ng-container>
      <ng-template #CampaignLoadingText>
        <div class="loading-screen">
          <mat-spinner diameter="18"></mat-spinner>
          Подождите, идет обработка данных
        </div>
      </ng-template>
    </mat-step>
    <mat-step *ngIf="!HasAssignedAccounts()">
      <div *matStepLabel>Подключите аккаунт к системе</div>

      <app-activate-cabinets
        [accounts]="accounts"
        [slots]="slots"
        [countOfMaxSlots]="slots.length"
        (bindingChanged)="BindCabinet($event)"
        *ngIf="slots && accounts"
      >

      </app-activate-cabinets>
      <button class="vk-standart-button" [disabled]="!HasAssignedAccounts()" (click)="NextWithCheckingCampaigns()">
        Далее
      </button>
      <!--{{IsCampaignsLoading}}-->
      <!--<ng-container *ngIf="IsCampaignsLoading; else CampaignLoadingText">-->
      <!--<button class="vk-standart-button" (click)="NextWithCheckingCampaigns()">Далее</button>-->
      <!--</ng-container>-->
      <!--<ng-template #CampaignLoadingText>-->
      <!--<mat-spinner></mat-spinner> Подождите идет проверка кампании-->
      <!--</ng-template>-->
      <!--<app-ad-account-manager-->
      <!--[accountId]="currentAccount.account_id"-->
      <!--[accounts]="accounts"-->
      <!--*ngIf="currentAccount"-->
      <!--&gt;</app-ad-account-manager>-->
    </mat-step>
    <mat-step>
      <div *matStepLabel>Выберите кому показывать объявление</div>

      <a
        href="https://postmonitor.ru/blog/instrukciya-dlya-3-shaga-sozdaniya-obyavleniya---vyberite-komu-pokazyvat-obyavlenie"
        target="_blank"
        rel="nofollow noopener"
        class="ad-form-step-instruction"
      >
        <span class="ion-md-bulb"></span>
        Рекомендации по выбору кому показывать объявление.
      </a>
      <app-targeting-settings
        formGroupName="targetingData"
        [accountId]="AccountId"
        [adFormat]="CurrentAdFormat.ad_format"
        *ngIf="currentAccount"
      ></app-targeting-settings>
      <ng-container *ngIf="!isSubmitLoading; else savingLoadingScreen">
        <ng-container *ngFor="let error of displayedErrors">
          <div class="vk-error">{{error}}</div>
        </ng-container>
        <ng-container *ngFor="let error of systemErrors">
          <div class="vk-error">{{error}}</div>
        </ng-container>

        <ng-container *ngIf="isUpdating; else createButtonText">
          <button class="vk-standart-button" (click)="SaveAd()" [disabled]="!isAdValid">
            Сохранить объявление
          </button>
        </ng-container>
        <ng-template #createButtonText>
          <button class="vk-standart-button" (click)="SaveAd()" 
          onclick="ym(45402093, 'reachGoal', 'metrika-js-pressed-create-ad-at-ad-manager-create-lite');" [disabled]="!isAdValid">
          <!-- onclick="yaCounter45402093.reachGoal('metrika-js-pressed-create-ad-at-ad-manager-create-lite');" [disabled]="!isAdValid"> -->
            Создать объявление
          </button>
        </ng-template>
      </ng-container>
      <ng-template #savingLoadingScreen>
        <div class="loading-screen">
          <mat-spinner diameter="18"></mat-spinner>
          <ng-container *ngIf="isUpdating; else creatingProcessText">Подождите, идет сохранение объявления
          </ng-container>
          <ng-template #creatingProcessText>Подождите, идет создание объявления</ng-template>
        </div>
      </ng-template>
    </mat-step>
  </mat-vertical-stepper>

</form>
<!--TODO: Check this bot 71HEGOcN Леонид Лесицкий 380661928057-->
