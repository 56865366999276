/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { RegistrationSuccessed } from '../models/registration-successed';
import { RegisterData } from '../models/register-data';
import { AccountToken } from '../models/account-token';
import { LoginData } from '../models/login-data';
import { JwtTokenModel } from '../models/jwt-token-model';
import { AccountViewModel } from '../models/account-view-model';
import { TariffPlanExpiration } from '../models/tariff-plan-expiration';
import { APIReponseWrapperListTariffPlanExpirationMeta } from '../models/apireponse-wrapper-list-tariff-plan-expiration-meta';
@Injectable({
  providedIn: 'root',
})
class AccountsService extends __BaseService {
  static readonly signUpPath = '/apiv2/account/registration';
  static readonly signInPath = '/apiv2/account/login';
  static readonly forgotPasswordPath = '/apiv2/account/forgotpassword';
  static readonly ChangePasswordPath = '/apiv2/account/change-password';
  static readonly ExternalConfirmTokenPath = '/apiv2/account/external-confirm/{TicketCode}';
  static readonly UnBindExternalTokenPath = '/apiv2/account/external-unbind/{provider}';
  static readonly BindExternalTokenPath = '/apiv2/account/external-bind/{TicketCode}';
  static readonly GetExternalTokensPath = '/apiv2/account/external-list/{provider}';
  static readonly RemoveAccountDataPath = '/apiv2/account/remove';
  static readonly GetUserInfoPath = '/apiv2/account/info';
  static readonly ChangePasswordAuthPath = '/apiv2/account/change-password-auth';
  static readonly GetUserTariffPlansExpirationsPath = '/apiv2/account/expiration-dates';
  static readonly GetUserTariffAccessPath = '/apiv2/account/tariff-access';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param model undefined
   * @return Success
   */
  signUpResponse(model?: RegisterData): __Observable<__StrictHttpResponse<RegistrationSuccessed>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = model;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/apiv2/account/registration`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RegistrationSuccessed>;
      })
    );
  }
  /**
   * @param model undefined
   * @return Success
   */
  signUp(model?: RegisterData): __Observable<RegistrationSuccessed> {
    return this.signUpResponse(model).pipe(
      __map(_r => _r.body as RegistrationSuccessed)
    );
  }

  /**
   * @param model undefined
   * @return Success
   */
  signInResponse(model?: LoginData): __Observable<__StrictHttpResponse<AccountToken>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = model;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/apiv2/account/login`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AccountToken>;
      })
    );
  }
  /**
   * @param model undefined
   * @return Success
   */
  signIn(model?: LoginData): __Observable<AccountToken> {
    return this.signInResponse(model).pipe(
      __map(_r => _r.body as AccountToken)
    );
  }

  /**
   * @param email undefined
   */
  forgotPasswordResponse(email?: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (email != null) __params = __params.set('email', email.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/apiv2/account/forgotpassword`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param email undefined
   */
  forgotPassword(email?: string): __Observable<null> {
    return this.forgotPasswordResponse(email).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `AccountsService.ChangePasswordParams` containing the following parameters:
   *
   * - `passwordRepeat`:
   *
   * - `password`:
   *
   * - `code`:
   */
  ChangePasswordResponse(params: AccountsService.ChangePasswordParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.passwordRepeat != null) __params = __params.set('passwordRepeat', params.passwordRepeat.toString());
    if (params.password != null) __params = __params.set('password', params.password.toString());
    if (params.code != null) __params = __params.set('code', params.code.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/apiv2/account/change-password`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `AccountsService.ChangePasswordParams` containing the following parameters:
   *
   * - `passwordRepeat`:
   *
   * - `password`:
   *
   * - `code`:
   */
  ChangePassword(params: AccountsService.ChangePasswordParams): __Observable<null> {
    return this.ChangePasswordResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `AccountsService.ExternalConfirmTokenParams` containing the following parameters:
   *
   * - `TicketCode`:
   *
   * - `utmData`:
   *
   * - `phone`:
   *
   * - `name`:
   *
   * - `email`:
   *
   * - `affiliateMemberCode`:
   *
   * @return Success
   */
  ExternalConfirmTokenResponse(params: AccountsService.ExternalConfirmTokenParams): __Observable<__StrictHttpResponse<JwtTokenModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.utmData != null) __params = __params.set('utmData', params.utmData.toString());
    if (params.phone != null) __params = __params.set('phone', params.phone.toString());
    if (params.name != null) __params = __params.set('name', params.name.toString());
    if (params.email != null) __params = __params.set('email', params.email.toString());
    if (params.affiliateMemberCode != null) __params = __params.set('affiliateMemberCode', params.affiliateMemberCode.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/apiv2/account/external-confirm/${encodeURIComponent(params.TicketCode)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<JwtTokenModel>;
      })
    );
  }
  /**
   * @param params The `AccountsService.ExternalConfirmTokenParams` containing the following parameters:
   *
   * - `TicketCode`:
   *
   * - `utmData`:
   *
   * - `phone`:
   *
   * - `name`:
   *
   * - `email`:
   *
   * - `affiliateMemberCode`:
   *
   * @return Success
   */
  ExternalConfirmToken(params: AccountsService.ExternalConfirmTokenParams): __Observable<JwtTokenModel> {
    return this.ExternalConfirmTokenResponse(params).pipe(
      __map(_r => _r.body as JwtTokenModel)
    );
  }

  /**
   * @param provider undefined
   */
  UnBindExternalTokenResponse(provider: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/apiv2/account/external-unbind/${encodeURIComponent(provider)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param provider undefined
   */
  UnBindExternalToken(provider: string): __Observable<null> {
    return this.UnBindExternalTokenResponse(provider).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param TicketCode undefined
   * @return Success
   */
  BindExternalTokenResponse(TicketCode: string): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/apiv2/account/external-bind/${encodeURIComponent(TicketCode)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * @param TicketCode undefined
   * @return Success
   */
  BindExternalToken(TicketCode: string): __Observable<{}> {
    return this.BindExternalTokenResponse(TicketCode).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * @param provider undefined
   */
  GetExternalTokensResponse(provider: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/account/external-list/${encodeURIComponent(provider)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param provider undefined
   */
  GetExternalTokens(provider: string): __Observable<null> {
    return this.GetExternalTokensResponse(provider).pipe(
      __map(_r => _r.body as null)
    );
  }
  RemoveAccountDataResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/apiv2/account/remove`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }  RemoveAccountData(): __Observable<null> {
    return this.RemoveAccountDataResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @return Success
   */
  GetUserInfoResponse(): __Observable<__StrictHttpResponse<AccountViewModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/account/info`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AccountViewModel>;
      })
    );
  }
  /**
   * @return Success
   */
  GetUserInfo(): __Observable<AccountViewModel> {
    return this.GetUserInfoResponse().pipe(
      __map(_r => _r.body as AccountViewModel)
    );
  }

  /**
   * @param params The `AccountsService.ChangePasswordAuthParams` containing the following parameters:
   *
   * - `passwordRepeat`:
   *
   * - `password`:
   *
   * @return Success
   */
  ChangePasswordAuthResponse(params: AccountsService.ChangePasswordAuthParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.passwordRepeat != null) __params = __params.set('passwordRepeat', params.passwordRepeat.toString());
    if (params.password != null) __params = __params.set('password', params.password.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/apiv2/account/change-password-auth`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * @param params The `AccountsService.ChangePasswordAuthParams` containing the following parameters:
   *
   * - `passwordRepeat`:
   *
   * - `password`:
   *
   * @return Success
   */
  ChangePasswordAuth(params: AccountsService.ChangePasswordAuthParams): __Observable<{}> {
    return this.ChangePasswordAuthResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * @return Success
   */
  GetUserTariffPlansExpirationsResponse(): __Observable<__StrictHttpResponse<Array<TariffPlanExpiration>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/account/expiration-dates`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TariffPlanExpiration>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetUserTariffPlansExpirations(): __Observable<Array<TariffPlanExpiration>> {
    return this.GetUserTariffPlansExpirationsResponse().pipe(
      __map(_r => _r.body as Array<TariffPlanExpiration>)
    );
  }

  /**
   * @param codes undefined
   * @return Success
   */
  GetUserTariffAccessResponse(codes?: Array<string>): __Observable<__StrictHttpResponse<APIReponseWrapperListTariffPlanExpirationMeta>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (codes || []).forEach(val => {if (val != null) __params = __params.append('codes', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/account/tariff-access`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<APIReponseWrapperListTariffPlanExpirationMeta>;
      })
    );
  }
  /**
   * @param codes undefined
   * @return Success
   */
  GetUserTariffAccess(codes?: Array<string>): __Observable<APIReponseWrapperListTariffPlanExpirationMeta> {
    return this.GetUserTariffAccessResponse(codes).pipe(
      __map(_r => _r.body as APIReponseWrapperListTariffPlanExpirationMeta)
    );
  }
}

module AccountsService {

  /**
   * Parameters for ChangePassword
   */
  export interface ChangePasswordParams {
    passwordRepeat?: string;
    password?: string;
    code?: string;
  }

  /**
   * Parameters for ExternalConfirmToken
   */
  export interface ExternalConfirmTokenParams {
    TicketCode: string;
    utmData?: string;
    phone?: string;
    name?: string;
    email?: string;
    affiliateMemberCode?: string;
  }

  /**
   * Parameters for ChangePasswordAuth
   */
  export interface ChangePasswordAuthParams {
    passwordRepeat?: string;
    password?: string;
  }
}

export { AccountsService }
