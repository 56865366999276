var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { AdNetworkGroupsStore } from './ad-network-groups.store';
import { AdManagerService, AutomationVkService, FacebookAdAccountService, MyTargetService, VkApiService } from '../../../api/services';
import { PlatformsTypes } from '../../platforms-types';
import { createAdNetworkGroupFromVk } from './ad-network-group.model';
import { FormBuilder } from '@angular/forms';
import { PlatformsQuery } from '../platforms/platforms.query';
import { AdNetworkFormsService } from '../ad-network-forms/ad-network-forms.service';
import { delay, tap } from 'rxjs/operators';
import { waterfall } from "../../../utils/rxjs-operators/waterfall";
import * as i0 from "@angular/core";
import * as i1 from "./ad-network-groups.store";
import * as i2 from "../../../api/services/automation-vk.service";
import * as i3 from "../../../api/services/ad-manager.service";
import * as i4 from "@angular/forms";
import * as i5 from "../platforms/platforms.query";
import * as i6 from "../ad-network-forms/ad-network-forms.service";
import * as i7 from "../../../api/services/my-target.service";
import * as i8 from "../../../api/services/vk-api.service";
import * as i9 from "../../../api/services/facebook-ad-account.service";
var AdNetworkGroupsService = /** @class */ (function () {
    function AdNetworkGroupsService(store, vkAdManagerService, adManager, fb, platformsQuery, formsService, mtService, vkapiService, facebookAdAccountService) {
        var _this = this;
        this.store = store;
        this.vkAdManagerService = vkAdManagerService;
        this.adManager = adManager;
        this.fb = fb;
        this.platformsQuery = platformsQuery;
        this.formsService = formsService;
        this.mtService = mtService;
        this.vkapiService = vkapiService;
        this.facebookAdAccountService = facebookAdAccountService;
        this.platformsQuery.selectedOriginPlatform.subscribe(function (data) {
            _this.ClearStore();
        });
    }
    /**
     * Load groups by type
     * @param type
     * @constructor
     */
    AdNetworkGroupsService.prototype.LoadGroups = function (type) {
        this.store.set([]);
        switch (type) {
            case PlatformsTypes.VK_LEAD_FORMS:
                this.LoadVkGroups();
                break;
            case PlatformsTypes.MT_LEAD_FORMS:
                this.LoadMtCampaigns();
                break;
            case PlatformsTypes.FB_LEAD_FORMS:
                this.LoadFacebookAccounts();
                break;
            default:
                throw new Error("Unknown platform type " + type);
        }
    };
    /**
     *
     * @constructor
     */
    AdNetworkGroupsService.prototype.LoadVkGroups = function () {
        var _this = this;
        this.store.setLoading(true);
        this.vkapiService
            .GetGroupsAsync({ filter: 'admin', key: 'integration' })
            .subscribe(function (data) {
            _this.store.set((data
                .map(function (group) { return createAdNetworkGroupFromVk(group); })
                .sort(function (a, b) {
                if (a.name < b.name) {
                    return -1;
                }
                else if (a.name === b.name) {
                    return 0;
                }
                else {
                    return 1;
                }
            })));
            _this.store.setLoading(false);
        }, function (err) {
            _this.store.setLoading(false);
        });
    };
    /**
     *
     * @param groupName
     * @constructor
     */
    AdNetworkGroupsService.prototype.SearchGroup = function (groupName) {
        this.store.update(function (state) { return (__assign({}, state, { ui: __assign({}, state.ui, { searchGroupName: groupName }) })); });
    };
    /**
     *
     * @param groupId
     * @constructor
     */
    AdNetworkGroupsService.prototype.OpenGroup = function (groupId) {
        this.store.update(function (state) { return (__assign({}, state, { ui: __assign({}, state.ui, { openedGroupId: state.ui.openedGroupId === groupId ? null : groupId }) })); });
    };
    AdNetworkGroupsService.prototype.LoadMtCampaigns = function () {
        var _this = this;
        this.mtService.GetCampaigns({ offset: 0, limit: 50 })
            .pipe(delay(500))
            .subscribe(function (data) {
            if (data.data && data.data.length > 0) {
                _this.store.add(data.data.map(function (x) { return ({ id: x.id, name: x.name }); }));
            }
        });
    };
    AdNetworkGroupsService.prototype.ClearStore = function () {
        this.store.reset();
    };
    AdNetworkGroupsService.prototype.LoadFacebookAccounts = function () {
        var _this = this;
        this.store.setLoading(true);
        this.facebookAdAccountService.FacebookGetAdAccountsAsync({
            fields: ['name'],
            summary: ['total_count']
        })
            .subscribe(function (data) {
            if (data && data.data) {
                var totalCount = data.data.summary.total_count;
                var count = data.data.data.length;
                var countOfRequests = totalCount / count - 1;
                var requests = [];
                if (data.data.data) {
                    _this.store.set(data.data.data.map(function (x) { return ({ id: x.id, name: x.name }); }));
                }
                var afterPage_1 = data.data.paging.cursors.after;
                if (countOfRequests < 1) {
                    _this.store.setLoading(false);
                }
                for (var i = 0; i < countOfRequests; i++) {
                    var request = function () {
                        _this.store.setLoading(true);
                        return _this.facebookAdAccountService.FacebookGetAdAccountsAsync({
                            fields: ['name'],
                            summary: ['total_count'],
                            pagingAfter: afterPage_1
                        })
                            .pipe(tap(function (newData) {
                            _this.store.setLoading(true);
                            _this.store.upsertMany(newData.data.data.map(function (x) { return ({
                                id: x.id,
                                name: x.name
                            }); }));
                            afterPage_1 = newData && newData.data && newData.data.paging && newData.data.paging.cursors && newData.data.paging.cursors.after
                                ? newData.data.paging.cursors.after : null;
                            _this.store.setLoading(true);
                        }), delay(1000));
                    };
                    requests.push(request);
                }
                if (requests.length > 0) {
                    waterfall(requests)
                        .subscribe(function () {
                        _this.store.setLoading(false);
                    });
                }
            }
        });
    };
    AdNetworkGroupsService.ngInjectableDef = i0.defineInjectable({ factory: function AdNetworkGroupsService_Factory() { return new AdNetworkGroupsService(i0.inject(i1.AdNetworkGroupsStore), i0.inject(i2.AutomationVkService), i0.inject(i3.AdManagerService), i0.inject(i4.FormBuilder), i0.inject(i5.PlatformsQuery), i0.inject(i6.AdNetworkFormsService), i0.inject(i7.MyTargetService), i0.inject(i8.VkApiService), i0.inject(i9.FacebookAdAccountService)); }, token: AdNetworkGroupsService, providedIn: "root" });
    return AdNetworkGroupsService;
}());
export { AdNetworkGroupsService };
