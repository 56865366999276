<ng-container *ngIf="!loading; else loadingScreen">
  <form [formGroup]="form" class="form">
    <h2 class="form__title">Подключение Битрикс24</h2>

    <div class="alert alert-warning">
      <div class="alert-items">
        <div class="alert-item static">
<!--          <div class="alert-icon-warning">-->
<!--            <clr-icon class="alert-icon" shape="exclamation-circle"></clr-icon>-->
<!--          </div>-->
          <div class="alert-text">
<!--            Перед созданием нового подключения установите приложение Albato для Битрикс24.-->
            Перед подключением вашего портала вам необходимо
            <a href="https://www.bitrix24.ru/apps/?app=postmonitor.postmonitor_integratsiya" target="_blank" rel="nofollow noopener">
              установить приложение PostMonitor
            </a> Битрикс24
            <br>
          </div>
        </div>
        <div class="alert-item static">
          Установить приложение могут только пользователи, обладающие правами администратора в Битрикс24.
        </div>
      </div>
    </div>

    <vk-form-field orientation="vertical" controlsWidth="100%">
      <vk-label>
        Домен вашей учетной записи в CRM.
        <app-suggestion-info code="BITRIX_24_DOMAIN"></app-suggestion-info>
      </vk-label>
      <input type="text" formControlName="domain" vkInput placeholder="Введите домен">
      <vk-error validator="required">Вы не ввели домен Битрикс24</vk-error>
    </vk-form-field>

    <button class="vk-standart-button" (click)="Assign()" [disabled]="form.invalid">
      Подключить Битрикс24
    </button>
  </form>
</ng-container>

<ng-template #loadingScreen>
  <app-loading-screen>
    Подождите, идет подключение Битрикс24
  </app-loading-screen>
</ng-template>

