import {Component, OnInit} from '@angular/core';
import {AdManagerService} from '../../../../../api/services/ad-manager.service';
import {UserStrategiesService} from '../../../../../api/services/user-strategies.service';
import {AdFormManagerService} from '../../../new-ad-manager-shared/services/ad-form-manager/ad-form-manager.service';

@Component({
  selector: 'app-new-update-ad-pro',
  templateUrl: './new-update-ad-pro.component.html',
  styleUrls: ['./new-update-ad-pro.component.scss']
})
export class NewUpdateAdProComponent implements OnInit {

  constructor(
    private adFormManagerService: AdFormManagerService,
    private adManagerService: AdManagerService,
    private userStrategiesService: UserStrategiesService
  ) {
    console.log('Is Update', this.adFormManagerService.IsUpdate);
    this.adFormManagerService.IsUpdate = true;
    console.log('Is Update', this.adFormManagerService.IsUpdate);
  }

  public get IsLoading() {
    return this.adFormManagerService.adDataLoading;
  }

  public get LoadingProgress() {
    return this.adFormManagerService.loadingProgress;
  }

  public get LoadingStep() {
    return this.adFormManagerService.LoadingStepMessage;
  }

  ngOnInit() {
    console.log('Is Update', this.adFormManagerService.IsUpdate);
    this.adFormManagerService.IsUpdate = true;
    console.log('Is Update', this.adFormManagerService.IsUpdate);
    this.adFormManagerService.reset();
    this.adFormManagerService.IsUpdate = true;
    this.adFormManagerService.LoadData()
      .subscribe(data => {
        console.log(data, 'PRO');
      });
  }

  public UpdateAds($event: any) {
    this.adFormManagerService.UpdateAd()
      .subscribe((data) => {
      }, err => {
        this.adFormManagerService.loadingCreateAd = false;
        alert('Во время изменения объявления произошла ошибка');
      });
  }
}
