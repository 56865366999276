var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { QueryEntity } from '@datorama/akita';
import { UserIntegrationStatisticsStore } from './user-integration-statistics.store';
import { map } from "rxjs/operators";
import * as i0 from "@angular/core";
import * as i1 from "./user-integration-statistics.store";
var UserIntegrationStatisticsQuery = /** @class */ (function (_super) {
    __extends(UserIntegrationStatisticsQuery, _super);
    function UserIntegrationStatisticsQuery(store) {
        var _this = _super.call(this, store) || this;
        _this.store = store;
        /**
         * получить данные по колличеству транзакций для интеграции
         * @param integrationId
         */
        _this.integrationStatistic = function (integrationId) { return _this.selectAll({ filterBy: function (e) { return e.id === integrationId; } })
            .pipe(map(function (x) { return x[0] ? x[0].value : 0; })); };
        return _this;
    }
    UserIntegrationStatisticsQuery.ngInjectableDef = i0.defineInjectable({ factory: function UserIntegrationStatisticsQuery_Factory() { return new UserIntegrationStatisticsQuery(i0.inject(i1.UserIntegrationStatisticsStore)); }, token: UserIntegrationStatisticsQuery, providedIn: "root" });
    return UserIntegrationStatisticsQuery;
}(QueryEntity));
export { UserIntegrationStatisticsQuery };
