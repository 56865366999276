var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/**
 * A factory function that creates AdNetworkForms
 */
export function createAdNetworkFormFromVk(params) {
    return {
        id: params.group_id + "_" + params.form_id,
        group_id: params.group_id,
        form_id: params.form_id,
        name: params.name,
        questions: params.questions.map(function (question) { return createVkFormQuestion(question); })
    };
}
/**
 *
 * @param question
 */
export function createVkFormQuestion(question) {
    return {
        id: question.label,
        key: question.key,
        label: question.label,
        options: question.options
    };
}
export function createMtForm(mtForm, formFields) {
    return {
        id: mtForm.form_id,
        name: "\u0424\u043E\u0440\u043C\u0430 " + mtForm.form_id.toString(),
        group_id: mtForm.campaign_ids,
        questions: formFields
    };
}
export function createFbFrom(forms, questions) {
    return __assign({}, forms, { questions: questions });
}
