import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserExperienceLevelService} from "../../../api/services/user-experience-level.service";

@Component({
  selector: 'app-user-experience-switcher',
  templateUrl: './user-experience-switcher.component.html',
  styleUrls: ['./user-experience-switcher.component.scss']
})
export class UserExperienceSwitcherComponent implements OnInit {
  private defaultRedirectTo = '/dashboard';

  private targetNoviceKey = 'accepted-target-switch-newbie';
  private targetProKey = 'accepted-target-switch-professional';

  public showWarningModal: boolean = false;

  constructor(private route: ActivatedRoute, private router: Router,
              private userExperienceApi: UserExperienceLevelService) {

  }

  ngOnInit() {
    if (this.CheckExperienceLevelMatch(1,1)) {
      if (!localStorage.getItem(this.targetNoviceKey)) {
        this.showWarningModal = true;
        return;
      }
    } else if (this.CheckExperienceLevelMatch(1,2)) {
      if (!localStorage.getItem(this.targetProKey)) {
        this.showWarningModal = true;
        return;
      }
    }

    this.EngageRedirectionProcess();
  }

  public Accept(): void {
    this.EngageRedirectionProcess();
  }

  private EngageRedirectionProcess(): void {
    try {
      this.SwitchUserExperienceLevel();
    } catch (e) {
      console.error(e);
      this.PerformRedirect(this.defaultRedirectTo);
    }
  }

  private SwitchUserExperienceLevel(): void {
    const experienceFieldId = parseInt(this.route.snapshot.queryParams.experienceFieldId, 10);
    const experienceLevelId = parseInt(this.route.snapshot.queryParams.experienceLevelId, 10);
    const redirect = this.route.snapshot.queryParams.redirect;

    console.log(`experienceFieldId=${experienceFieldId};experienceLevelId=${experienceLevelId};redirect=${redirect}`);

    if (!experienceFieldId || !experienceLevelId) {
      console.log('!experienceFieldId || !experienceLevelId');
      this.PerformRedirect(this.defaultRedirectTo);
      return;
    }

    const redirectTo = (redirect && redirect.length > 0) ? redirect : this.defaultRedirectTo;

    if (experienceFieldId === 1) {
      if (experienceLevelId === 1) {
        localStorage.setItem(this.targetNoviceKey, 'true');
      } else {
        if (experienceLevelId === 2) {
          localStorage.setItem(this.targetProKey, 'true');
        }
      }
    }

    console.log('Sending switch command');
    this.userExperienceApi.AssignUserExperienceLevel({
      experienceLevelId: experienceLevelId,
      experienceFieldId: experienceFieldId
    }).subscribe(response => {
      console.log('COMMAND COMPLETED', response);
      this.PerformRedirect(redirectTo);
    }, error => {
      console.log(error);
      this.PerformRedirect(redirectTo);
    });
  }

  private PerformRedirect(route: string): void {
    this.router.navigate([route]);
  }

  public CheckExperienceLevelMatch(fieldId: number, levelId): boolean {
    try {
      const experienceFieldId = parseInt(this.route.snapshot.queryParams.experienceFieldId, 10);
      const experienceLevelId = parseInt(this.route.snapshot.queryParams.experienceLevelId, 10);

      return experienceFieldId === fieldId && experienceLevelId === levelId;
    } catch (ex) {
      return false;
    }
  }
}
