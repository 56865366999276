import {Component, ContentChild, ElementRef, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {PopoverMenuTriggerDirective} from "../../directives/popover-menu-trigger.directive";
import {Overlay, OverlayRef} from "@angular/cdk/overlay";
import {DomPortalHost, TemplatePortal} from "@angular/cdk/portal";
import {PopoverMenuContentDirective} from "../../directives/popover-menu-content.directive";

@Component({
  selector: 'app-popover-menu',
  templateUrl: './popover-menu.component.html',
  styleUrls: ['./popover-menu.component.scss']
})
export class PopoverMenuComponent implements OnInit {

  private overlayRef: OverlayRef;
  private portalHost: DomPortalHost;
  private portal: TemplatePortal;

  @ContentChild(PopoverMenuTriggerDirective) menuTrigger: PopoverMenuTriggerDirective;
  @ContentChild(PopoverMenuContentDirective) menuContent: PopoverMenuContentDirective;
  @ViewChild('popover') popoverElement: ElementRef;

  constructor(
    private overlay: Overlay,
    private viewContainerRef: ViewContainerRef
  ) {
  }

  ngOnInit() {
    this.overlayRef = this.overlay.create({
      width: 214,
      maxHeight: 300,
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(this.menuTrigger.el.nativeElement)
        .withPositions([
          {
            originY: 'top',
            originX: 'end',
            overlayX: 'end',
            overlayY: 'bottom'
          },
        ]),
      panelClass: 'popover-menu__content',
      hasBackdrop: true,
      backdropClass: 'popover-menu__backdrop'
    });

    this.overlayRef
      .backdropClick()
      .subscribe(x => {
        this.close();
      });


    this.portal = new TemplatePortal<any>(this.menuContent.template, this.viewContainerRef);

    this.menuTrigger.triggerSubject
      .subscribe(x => {
        this.ToggleMenu()
      })
  }

  public ToggleMenu() {
    if (this.overlayRef.hasAttached()) {
      this.close()
    } else {
      this.open();
    }
  }

  private open() {
    this.overlayRef.attach(this.portal);
  }

  public close() {
    this.overlayRef.detach();
  }

  ClickOutside() {
    this.close();
  }
}
