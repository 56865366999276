import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TermsComponent } from './routes/terms/terms.component';
import { PrivacyComponent } from './routes/privacy/privacy.component';
import { ContactsComponent } from './routes/contacts/contacts.component';
import { PartnersPageComponent } from './routes/partners-page/partners-page.component';
import { TestComponent } from './routes/test/test.component';
import { AdManagerMiddlewareComponent } from "./routes/ad-manager-middleware/ad-manager-middleware.component";

const routes: Routes = [
    {
        path: 'terms',
        component: TermsComponent
    },
    {
        path: 'privacy',
        component: PrivacyComponent
    },
    {
        path: 'contacts',
        component: ContactsComponent
    },
    {
        path: 'partners',
        component: PartnersPageComponent
    },
    {
        path: 'test',
        component: TestComponent
    },
    {
        path: 'ad-manager-middle',
        component: AdManagerMiddlewareComponent
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
