/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./breadcrumbs.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./breadcrumbs.component";
import * as i5 from "../../services/dexie/dexie-campaigns.service";
import * as i6 from "../../services/dexie/dexie-cabinets.service";
import * as i7 from "../../services/dexie/dexie-clients.service";
import * as i8 from "../../services/logging.service";
var styles_BreadcrumbsComponent = [i0.styles];
var RenderType_BreadcrumbsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BreadcrumbsComponent, data: {} });
export { RenderType_BreadcrumbsComponent as RenderType_BreadcrumbsComponent };
function View_BreadcrumbsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "a", [["class", "breadcrumbs__item"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, [[2, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { queryParams: [0, "queryParams"], routerLink: [1, "routerLink"] }, null), i1.ɵdid(2, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], null, null), i1.ɵqud(603979776, 1, { links: 1 }), i1.ɵqud(603979776, 2, { linksWithHrefs: 1 }), (_l()(), i1.ɵted(5, null, ["", ""]))], function (_ck, _v) { var currVal_2 = _v.context.$implicit.queryParams; var currVal_3 = _v.context.$implicit.link; _ck(_v, 1, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_4 = _v.context.$implicit.title; _ck(_v, 5, 0, currVal_4); }); }
export function View_BreadcrumbsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "nav", [["class", "breadcrumbs"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BreadcrumbsComponent_1)), i1.ɵdid(2, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.breadcrumbs; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_BreadcrumbsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-breadcrumbs", [], null, null, null, View_BreadcrumbsComponent_0, RenderType_BreadcrumbsComponent)), i1.ɵdid(1, 114688, null, 0, i4.BreadcrumbsComponent, [i5.DexieCampaignsService, i6.DexieCabinetsService, i7.DexieClientsService, i2.ActivatedRoute, i8.LoggingService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BreadcrumbsComponentNgFactory = i1.ɵccf("app-breadcrumbs", i4.BreadcrumbsComponent, View_BreadcrumbsComponent_Host_0, {}, {}, []);
export { BreadcrumbsComponentNgFactory as BreadcrumbsComponentNgFactory };
