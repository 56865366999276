import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ChatConversationMessagesService} from '../../services/chat-conversation-messages.service';
import {ChatMessageModel} from "../../models/chat-message-model";
import {Observable} from "rxjs/Rx";

@Component({
  selector: 'app-chat-form',
  templateUrl: './chat-form.component.html',
  styleUrls: ['./chat-form.component.scss']
})
export class ChatFormComponent implements OnInit, OnDestroy {
  @Input() public sendNotificationOverEmail: boolean = false;
  @Input() public conversationWithUserId: number = null;

  public formMessage: string = '';
  public refreshTimeout = 5000;
  public refreshReadTimeout = 5000;

  private refreshTimer: any = null;
  private refreshReadTimer: any = null;

  private lastChangesRecievedAt: number = null;

  constructor(public conversationMessagesService: ChatConversationMessagesService) {
  }

  ngOnInit() {

    this.refreshTimer = Observable.interval(this.refreshTimeout)
      .takeWhile(() => true)
      .subscribe(i => {
        this.conversationMessagesService.LoadNewMessages(this.conversationWithUserId);

        if (this.conversationMessagesService.isBanned) {
          this.refreshTimer.unsubscribe();
        }
      });

    this.lastChangesRecievedAt = Math.round((new Date()).getTime() / 1000);

    this.refreshReadTimer = Observable.interval(this.refreshReadTimeout)
      .takeWhile(() => true)
      .subscribe(i => {
        this.conversationMessagesService.GetConversationChanges(this.lastChangesRecievedAt, this.conversationWithUserId, true);
        this.lastChangesRecievedAt = Math.round((new Date()).getTime() / 1000);

        if (this.conversationMessagesService.isBanned) {
          this.refreshReadTimer.unsubscribe();
        }
      });
  }

  ngOnDestroy() {
    if (this.refreshTimer) {
      this.refreshTimer.unsubscribe();
    }

    if (this.refreshReadTimer) {
      this.refreshReadTimer.unsubscribe();
    }
  }

  public SendMessage(): void {
    this.conversationMessagesService.SendMessage(this.formMessage, this.conversationWithUserId, this.sendNotificationOverEmail);
    this.formMessage = '';
  }

  public IsLoading(): boolean {
    return this.conversationMessagesService.loadingMessages;
  }

  public MessagesExists(): boolean {
    return (this.conversationMessagesService.messages && this.conversationMessagesService.messages.length > 0);
  }

  // TODO Add type here
  public GetMessasges(): Array<ChatMessageModel> {
    return this.conversationMessagesService.messages;
  }
}
