/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./copying-by-interest-categories-list-checkboxes.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "@angular/common";
import * as i5 from "../../../../../../../node_modules/@angular/material/checkbox/typings/index.ngfactory";
import * as i6 from "@angular/forms";
import * as i7 from "@angular/material/checkbox";
import * as i8 from "@angular/cdk/a11y";
import * as i9 from "@angular/platform-browser/animations";
import * as i10 from "./copying-by-interest-categories-list-checkboxes.component";
import * as i11 from "../../../../ad-manager/services/ad-manager-data-service.service";
import * as i12 from "../../../services/interest-categories.service";
import * as i13 from "../../../services/copying.service";
var styles_CopyingByInterestCategoriesListCheckboxesComponent = [i0.styles];
var RenderType_CopyingByInterestCategoriesListCheckboxesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CopyingByInterestCategoriesListCheckboxesComponent, data: {} });
export { RenderType_CopyingByInterestCategoriesListCheckboxesComponent as RenderType_CopyingByInterestCategoriesListCheckboxesComponent };
function View_CopyingByInterestCategoriesListCheckboxesComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["class", "interest-category-node__toggle-button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(2, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(3, 0, [" ", " "]))], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 2).inline; var currVal_1 = (((i1.ɵnov(_v, 2).color !== "primary") && (i1.ɵnov(_v, 2).color !== "accent")) && (i1.ɵnov(_v, 2).color !== "warn")); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = (_co.isTreeNodeOpened(_v.parent.context.$implicit) ? "keyboard_arrow_down" : "keyboard_arrow_right"); _ck(_v, 3, 0, currVal_2); }); }
function View_CopyingByInterestCategoriesListCheckboxesComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "interest-category-node interest-category-node_level_2"]], null, null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i4.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "interest-category-node_visible": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "interest-category-node__toggle-trigger-area"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "interest-category-node__content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 3, "mat-checkbox", [["class", "mat-checkbox"]], [[8, "id", 0], [1, "tabindex", 0], [2, "mat-checkbox-indeterminate", null], [2, "mat-checkbox-checked", null], [2, "mat-checkbox-disabled", null], [2, "mat-checkbox-label-before", null], [2, "_mat-animation-noopable", null]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.ToggleCategorySelect(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatCheckbox_0, i5.RenderType_MatCheckbox)), i1.ɵprd(5120, null, i6.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i7.MatCheckbox]), i1.ɵdid(8, 8568832, null, 0, i7.MatCheckbox, [i1.ElementRef, i1.ChangeDetectorRef, i8.FocusMonitor, i1.NgZone, [8, null], [2, i7.MAT_CHECKBOX_CLICK_ACTION], [2, i9.ANIMATION_MODULE_TYPE]], { checked: [0, "checked"] }, { change: "change" }), (_l()(), i1.ɵted(9, 0, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "interest-category-node interest-category-node_level_2"; var currVal_1 = _ck(_v, 3, 0, _co.isTreeNodeOpened(_v.parent.context.$implicit)); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_9 = _co.isSelected(_v.context.$implicit); _ck(_v, 8, 0, currVal_9); }, function (_ck, _v) { var currVal_2 = i1.ɵnov(_v, 8).id; var currVal_3 = null; var currVal_4 = i1.ɵnov(_v, 8).indeterminate; var currVal_5 = i1.ɵnov(_v, 8).checked; var currVal_6 = i1.ɵnov(_v, 8).disabled; var currVal_7 = (i1.ɵnov(_v, 8).labelPosition == "before"); var currVal_8 = (i1.ɵnov(_v, 8)._animationMode === "NoopAnimations"); _ck(_v, 6, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); var currVal_10 = _v.context.$implicit.title; _ck(_v, 9, 0, currVal_10); }); }
function View_CopyingByInterestCategoriesListCheckboxesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "interest-category-node interest-category-node_level_1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "interest-category-node__toggle-trigger-area"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleTreeCategory(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CopyingByInterestCategoriesListCheckboxesComponent_2)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "interest-category-node__content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 3, "mat-checkbox", [["class", "mat-checkbox"]], [[8, "id", 0], [1, "tabindex", 0], [2, "mat-checkbox-indeterminate", null], [2, "mat-checkbox-checked", null], [2, "mat-checkbox-disabled", null], [2, "mat-checkbox-label-before", null], [2, "_mat-animation-noopable", null]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.ToggleParentCategorySelect(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatCheckbox_0, i5.RenderType_MatCheckbox)), i1.ɵprd(5120, null, i6.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i7.MatCheckbox]), i1.ɵdid(8, 8568832, null, 0, i7.MatCheckbox, [i1.ElementRef, i1.ChangeDetectorRef, i8.FocusMonitor, i1.NgZone, [8, null], [2, i7.MAT_CHECKBOX_CLICK_ACTION], [2, i9.ANIMATION_MODULE_TYPE]], { checked: [0, "checked"], indeterminate: [1, "indeterminate"] }, { change: "change" }), (_l()(), i1.ɵted(9, 0, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CopyingByInterestCategoriesListCheckboxesComponent_3)), i1.ɵdid(11, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hasChildren(_v.context.$implicit); _ck(_v, 4, 0, currVal_0); var currVal_8 = (_co.isSelected(_v.context.$implicit) || _co.isAllChildrenChecked(_v.context.$implicit)); var currVal_9 = _co.isChildrenPartlyChecked(_v.context.$implicit); _ck(_v, 8, 0, currVal_8, currVal_9); var currVal_11 = _v.context.$implicit.children; _ck(_v, 11, 0, currVal_11); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 8).id; var currVal_2 = null; var currVal_3 = i1.ɵnov(_v, 8).indeterminate; var currVal_4 = i1.ɵnov(_v, 8).checked; var currVal_5 = i1.ɵnov(_v, 8).disabled; var currVal_6 = (i1.ɵnov(_v, 8).labelPosition == "before"); var currVal_7 = (i1.ɵnov(_v, 8)._animationMode === "NoopAnimations"); _ck(_v, 6, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_10 = _v.context.$implicit.title; _ck(_v, 9, 0, currVal_10); }); }
export function View_CopyingByInterestCategoriesListCheckboxesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "form", [["action", ""], ["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i6.ɵangular_packages_forms_forms_bh, [], null, null), i1.ɵdid(2, 4210688, null, 0, i6.NgForm, [[8, null], [8, null]], null, null), i1.ɵprd(2048, null, i6.ControlContainer, null, [i6.NgForm]), i1.ɵdid(4, 16384, null, 0, i6.NgControlStatusGroup, [[4, i6.ControlContainer]], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "interest-category-tree"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CopyingByInterestCategoriesListCheckboxesComponent_1)), i1.ɵdid(7, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.interestCategories; _ck(_v, 7, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 4).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 4).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 4).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 4).ngClassValid; var currVal_5 = i1.ɵnov(_v, 4).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 4).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_CopyingByInterestCategoriesListCheckboxesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-copying-by-interest-categories-list-checkboxes", [], null, null, null, View_CopyingByInterestCategoriesListCheckboxesComponent_0, RenderType_CopyingByInterestCategoriesListCheckboxesComponent)), i1.ɵdid(1, 114688, null, 0, i10.CopyingByInterestCategoriesListCheckboxesComponent, [i11.AdManagerDataServiceService, i12.InterestCategoriesService, i13.CopyingService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CopyingByInterestCategoriesListCheckboxesComponentNgFactory = i1.ɵccf("app-copying-by-interest-categories-list-checkboxes", i10.CopyingByInterestCategoriesListCheckboxesComponent, View_CopyingByInterestCategoriesListCheckboxesComponent_Host_0, {}, {}, []);
export { CopyingByInterestCategoriesListCheckboxesComponentNgFactory as CopyingByInterestCategoriesListCheckboxesComponentNgFactory };
