import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AdNetworkGroup} from '../../../../stores/ad-network-groups/ad-network-group.model';
import {AdNetworkGroupsQuery} from '../../../../stores/ad-network-groups/ad-network-groups.query';
import {AdNetworkFormsQuery} from '../../../../stores/ad-network-forms/ad-network-forms.query';
import {AdNetworkGroupsService} from '../../../../stores/ad-network-groups/ad-network-groups.service';
import {AdNetworkFormsService} from '../../../../stores/ad-network-forms/ad-network-forms.service';
import {FormGroup} from '@angular/forms';
import {PlatformsQuery} from '../../../../stores/platforms/platforms.query';
import {PlatformsTypes} from '../../../../platforms-types';
import {Observable, of} from 'rxjs';
import {filter} from "rxjs/operators";

@Component({
  selector: 'app-ad-network-group',
  templateUrl: './ad-network-group.component.html',
  styleUrls: ['./ad-network-group.component.scss']
})
export class AdNetworkGroupComponent implements OnInit, OnDestroy {

  @Input() public group: AdNetworkGroup;
  @Input() public control: FormGroup;

  public  isGroupSearched$;
  public forms$;
  public data = [1,2,3,4,5,6,7,8,9,0]
  public isOpened$;
  public form: FormGroup;
  private isEmpty$: Observable<boolean> = of(true);
  public isLoading$ = this.formsQuery.selectLoading();
  public loadingMessage = "Подождите, идет загрузка форм ...";

  public formTrackByFunc = ((index, item) => index)

  public get notFoundMessage() {
    let text = "Лид-формы не обнаружены";
    if (this.platformsQuery.selectedOriginPlatformCode === PlatformsTypes.FB_LEAD_FORMS) {
      text = 'Объявления не обнаружены';
    }
    return text;
  }

  constructor(
    private groupsQuery: AdNetworkGroupsQuery,
    private formsQuery: AdNetworkFormsQuery,
    private groupsService: AdNetworkGroupsService,
    private formsService: AdNetworkFormsService,
    private platformsQuery: PlatformsQuery
  ) {
  }

  ngOnInit() {
    this.isGroupSearched$ = this.groupsQuery.isGroupSearched$(this.group);
    this.forms$ = this.formsQuery.groupForms$(this.group.id);
    this.isOpened$ = this.groupsQuery.isOpened$(this.group.id);
    this.isEmpty$ = this.formsQuery.isEmpty$(this.group.id);

    this.isOpened$
      .pipe(
        filter(x => x === true)
      )
      .subscribe(data => {
        this.formsService.LoadForms(this.group.id, PlatformsTypes[this.platformsQuery.selectedOriginPlatformCode]);
      })

    if (this.platformsQuery.selectedOriginPlatformCode === PlatformsTypes.FB_LEAD_FORMS) {
      this.loadingMessage = 'Подождите, идет загрузка объявлений';
    }
  }

  public OpenGroup() {
    this.groupsService.OpenGroup(this.group.id);
  }

  ngOnDestroy(): void {
  }

}
