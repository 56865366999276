import {Directive, Input} from '@angular/core';

@Directive({
  selector: '[appDgColumnHeader]'
})
export class DgColumnHeaderDirective {

  constructor() { }

}
