import { AkitaNgFormsManager } from '@datorama/akita-ng-forms-manager';
import { CrmPipelinesQuery } from '../../../stores/crm-pipelines/crm-pipelines.query';
import * as i0 from "@angular/core";
import * as i1 from "@datorama/akita-ng-forms-manager";
import * as i2 from "../../../stores/crm-pipelines/crm-pipelines.query";
var ExportConfiguratorService = /** @class */ (function () {
    function ExportConfiguratorService(fm, pipelinesQuery) {
        this.fm = fm;
        this.pipelinesQuery = pipelinesQuery;
    }
    ExportConfiguratorService.prototype.configure = function (type) {
        var form = this.fm.getForm('originPlatformSettings');
        var destinationFormValue = this.fm.getForm('destinationPlatformSettings').value;
        console.log(form.value, 'CONFIGURATION FORM VALUE FOR EXPORT');
        var data = [
            {
                code: form.value.section,
                data: {}
            }
        ];
        console.log(form.value.isClassicMode, destinationFormValue.splitByPipelines, destinationFormValue.split_by_pipeline_statuses, 'IS CLASSIC MODE');
        if (form.value.isClassicMode && (destinationFormValue.splitByPipelines || destinationFormValue.split_by_pipeline_statuses)) {
            data.push({
                code: 'LEADS',
                data: {}
            });
        }
        return data;
    };
    ExportConfiguratorService.prototype.valid = function (type) {
        var form = this.fm.getForm('originPlatformSettings');
        console.log(form.value, 'Validation form value');
        if (form) {
            console.log(form.value.section === 'LEADS' || form.value.section === 'DEALS' || form.value.section === 'UON_MARKS_EXPORT', 'STATUS VALIDATION');
            return form.value.section === 'LEADS' || form.value.section === 'DEALS' || form.value.section === 'UON_MARKS_EXPORT' ?
                form.value.selectedPipelineId !== null : this.pipelinesQuery.hasPipelines;
        }
        else {
            return false;
        }
    };
    ExportConfiguratorService.ngInjectableDef = i0.defineInjectable({ factory: function ExportConfiguratorService_Factory() { return new ExportConfiguratorService(i0.inject(i1.AkitaNgFormsManager), i0.inject(i2.CrmPipelinesQuery)); }, token: ExportConfiguratorService, providedIn: "root" });
    return ExportConfiguratorService;
}());
export { ExportConfiguratorService };
