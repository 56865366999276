/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./checkboxes-group.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "./checkboxes-group.component";
var styles_CheckboxesGroupComponent = [i0.styles];
var RenderType_CheckboxesGroupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CheckboxesGroupComponent, data: {} });
export { RenderType_CheckboxesGroupComponent as RenderType_CheckboxesGroupComponent };
export function View_CheckboxesGroupComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "checkboxes-group"]], null, null, null, null, null)), i1.ɵncd(null, 0)], null, null); }
export function View_CheckboxesGroupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-checkboxes-group", [], null, null, null, View_CheckboxesGroupComponent_0, RenderType_CheckboxesGroupComponent)), i1.ɵprd(5120, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.CheckboxesGroupComponent]), i1.ɵdid(2, 4308992, null, 1, i3.CheckboxesGroupComponent, [], null, null), i1.ɵqud(603979776, 1, { checkboxes: 1 })], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var CheckboxesGroupComponentNgFactory = i1.ɵccf("app-checkboxes-group", i3.CheckboxesGroupComponent, View_CheckboxesGroupComponent_Host_0, {}, {}, ["*"]);
export { CheckboxesGroupComponentNgFactory as CheckboxesGroupComponentNgFactory };
