import { FormMessage } from "../../models/form-message";
import { BehaviorSubject } from "rxjs";
import * as i0 from "@angular/core";
var FormMessagesService = /** @class */ (function () {
    function FormMessagesService() {
        this.Messages$ = new BehaviorSubject([]);
    }
    FormMessagesService.prototype.AddSuccessMessage = function (text) {
        var message = FormMessage.CreateSuccessMessage(text);
        this.AddMessage(message);
    };
    FormMessagesService.prototype.AddMessage = function (message) {
        var messages = this.Messages$.getValue();
        messages.push(message);
        this.Messages$.next(messages);
    };
    FormMessagesService.prototype.AddWarningMessage = function (text) {
        var message = FormMessage.CreateWarningMessage(text);
        this.AddMessage(message);
    };
    FormMessagesService.prototype.AddErrorMessage = function (text) {
        var message = FormMessage.CreateErrorMessage(text);
        console.log(message, this.Messages$, 'MESSAGE');
        this.AddMessage(message);
    };
    FormMessagesService.prototype.AddInfoMessage = function (text) {
        var message = FormMessage.CreateInfoMessage(text);
        this.AddMessage(message);
    };
    FormMessagesService.prototype.Clear = function () {
        console.log('CLEAR MESSAGES');
        this.Messages$.next([]);
    };
    FormMessagesService.ngInjectableDef = i0.defineInjectable({ factory: function FormMessagesService_Factory() { return new FormMessagesService(); }, token: FormMessagesService, providedIn: "root" });
    return FormMessagesService;
}());
export { FormMessagesService };
