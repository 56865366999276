import {Component, OnInit} from '@angular/core';
import {
  AdManagerService,
  AutomationVkService,
  AutomationVkSlotService,
  UserStrategiesService
} from '../../../../api/services';
import {PostMonitorBackend_V2ModelsAdManagerAdSpecificationViewModel} from '../../../../api/models/post-monitor-backend-_v2models-ad-manager-ad-specification-view-model';
import {DomainOnlyPipe} from '../../../../shared/pipes/domain-only.pipe';
import {Router} from '@angular/router';
import {GroupsSelectorService} from '../../services/groups-selector/groups-selector.service';
import 'rxjs-compat/add/operator/mergeMap';
import {UserAutomationCabinetSlot} from '../../../../api/models/user-automation-cabinet-slot';
import 'rxjs-compat/add/operator/map';
import {AccountViewModel} from '../../../models/view-models/account-view-model';
import {of} from 'rxjs/internal/observable/of';
import {isNullOrUndefined} from 'util';
import {Client} from '../../../../api/models/client';
import {DexieCabinetsService} from '../../../../shared/services/dexie/dexie-cabinets.service';
import {AutomationExpirationManagerService} from '../../../services/automation-expiration-manager.service';
import {DexieClientsService} from '../../../../shared/services/dexie/dexie-clients.service';
import {Observable} from 'rxjs/Observable';
import {MatDialog} from '@angular/material';
import {MatConfirmDialogComponent} from '../../components/modals/configrm-dialog/mat-confirm-dialog.component';

const DEFAULT_AD_PLATFORM = 'all';
const EMPTY_SUBSCRIBER = Observable.create(observer => observer.complete());

@Component({
  selector: 'app-create-ad',
  templateUrl: './create-ad.component.html',
  styleUrls: ['./create-ad.component.scss']
})
export class CreateAdComponent implements OnInit {

  public loading: boolean = false;
  public loadingAccounts: boolean = false;
  public slots: Array<UserAutomationCabinetSlot> = [];
  public accounts: Array<AccountViewModel> = [];

  constructor(
    private adManagerService: AdManagerService,
    private usService: UserStrategiesService,
    private router: Router,
    private automationVkService: AutomationVkService,
    private automationVkSlotService: AutomationVkSlotService,
    private usiService: UserStrategiesService,
    private groupsSelectorService: GroupsSelectorService,
    private _api: AutomationVkService,
    private localDb: DexieCabinetsService,
    private _db: AutomationVkSlotService,
    public accessManager: AutomationExpirationManagerService,
    private localClientsDb: DexieClientsService,
    public dialog: MatDialog
  ) {
  }

  public ToggleLoading() {
    this.loading = !this.loading;
  }

  ngOnInit(): void {
    this.groupsSelectorService.ClearSelectedGroups();
    const HasStatus = localStorage.getItem('was_showed_instruction');
    if (!HasStatus) {
      setTimeout(() => {
        this.dialog.open(MatConfirmDialogComponent, {
          data: {
            title: 'Ознакомьтесь с инструкцией',
            description: `Перед созданием объявления, рекомендуем ознакомиться с инструкцией.
      Также Вы сможете ознакомиться с инструкцией позже, нажав на кнопку “Инструкция по созданию объявлений”,
      которая находится в правом верхнем углу на странице создания объявления`,
            confirmActionText: 'Ознакомится сейчас',
            rejectActionText: 'Ознакомится позже'
          },
          width: '650px'
        })
          .afterClosed()
          .subscribe(status => {
            localStorage.setItem('was_showed_instruction', '1');
            if (status) {
              const win = window.open('https://postmonitor.ru/blog/instrukciya-po-sozdaniyu-obyavlenijj-v-vkontakte-cherez-servis-postmonitor', '_blank');
            }
          })
        ;
      }, 1500);
    }
    this.LoadData();
  }

  public OnSaveAd({ad, strategy, updateStrategy}) {
    const {adData, ads} = this.CreateAds(ad);
    this.adManagerService.CreateAd(ads)
      .subscribe(data => {
        const [accountId, clientId] = adData.campaignData.accountId.split('_');
        const {sendModeration, startAfterModeration} = adData.moderationSettings;
        // const StrategyMustCreate = true;
        // let strategyObservable = EMPTY_SUBSCRIBER;
        // if (StrategyMustCreate) {
        //   strategyObservable = this.CreateStrategy(accountId, clientId, data, strategy);
        // }
        // strategyObservable
        //   .flatMap(() => this.HandleToggleStatus(accountId, data, clientId, sendModeration, startAfterModeration))
        //   .flatMap(() => this.HandToggleStrategy(adData.moderationSettings.sendModeration, accountId, data, clientId))
        //   .subscribe(createdStrategyData => {
        //     this.NavigateToAdsPage(adData, accountId, clientId, data[0].id);
        //   });


        if (strategy && Object.keys(strategy).length > 0) {
          this.CreateStrategy(accountId, clientId, data, strategy)
            .subscribe(data1 => {
              let subs = null;
              if (adData.moderationSettings.sendModeration) {
                subs = this.ToggleAdStatus(adData, data1)
                  .flatMap(d => {
                    if (d[0].error_code) {
                      localStorage.setItem('error_code', d[0].error_code.toString());
                    }
                    return this.ToggleAdStrategy(accountId, data1, clientId);
                  });
              }
              if (subs) {
                subs.subscribe(d => {
                  if (!adData.moderationSettings.startAfterModeration) {
                    if (d[0].error_code) {
                      localStorage.setItem('error_code', d[0].error_code.toString());
                    }
                    this.ToggleAdStatus(adData, data1);
                  }
                });
              }
              setTimeout(() => {
                this.NavigateToAdsPage(adData, accountId, clientId, data[0].id);
              }, 500);
            });
        } else {
          setTimeout(() => {
            let subs = null;
            if (adData.moderationSettings.sendModeration) {
              subs = this.ToggleAdStatus(adData, data);
            }
            if (subs) {
              subs.subscribe(a => {
                if (!adData.moderationSettings.startAfterModeration) {
                  this.ToggleAdStatus(adData, data)
                    .delay(1000)
                    .subscribe(d => {
                      if (d[0].error_code) {
                        localStorage.setItem('error_code', d[0].error_code.toString());
                      }
                      this.NavigateToAdsPage(adData, accountId, clientId, data[0].id);
                    });
                } else {
                  this.NavigateToAdsPage(adData, accountId, clientId, data[0].id);
                }
              });
            } else {
              this.NavigateToAdsPage(adData, accountId, clientId, data[0].id);
            }
          }, 1000);
        }

      }, error => {
        this.loading = false;
        let message = '';
        if (error.error) {
          message = error.error.description;
        } else {
          message = 'Не удалось создать объявление. Попробуйте повторить это действие позже или обратитесь в поддержку.';
        }
        alert(message);
        throw error;
      });
  }

  public HandleToggleStatus(accountId, data, clientId, sendModeration: boolean = false, startAfterModeration: boolean = false) {
    let subs = of({});
    if (sendModeration) {
      subs = this.ToggleAdStrategy(accountId, data, clientId);
      if (startAfterModeration) {
        subs
          .flatMap(() => this.ToggleAdStrategy(accountId, data, clientId));
      }
    }

    return subs;
  }

  public HandToggleStrategy(sendModeration, accountId, data, clientId) {
    let subs = of({});
    if (sendModeration) {
      subs = this.ToggleAdStrategy(accountId, data, clientId);
    }
    return subs;
  }


  private CreateAds(ad: any) {
    const adData = ad;
    this.loading = true;
    let type = null;
    const images = (ad.format.value === 'teazer') ? adData.teazer.image : adData.promopost.image;
    const texts = (ad.format.value === 'teazer') ? adData.teazer.title : adData.promopost.text;
    if (ad.format.value === 'promopost') {
      type = ad.promopost.type;
    } else {
      type = ad.teazer.type;
    }
    const ads = [];

    if (type === 'linear') {
      const min = Math.min(images.length, texts.length);
      for (let i = 0; i < min; i++) {
        ads.push(this.CreateData(adData, texts[i], images[i]));
      }
    } else if (type === 'quadratic') {
      images.forEach(image => {
        texts.forEach(text => {
          ads.push(this.CreateData(adData, text, image));
        });
      });
    }
    return {adData, ads};
  }

  private CreateStrategy(accountId: any, clientId: any, data, strategy: any) {
    const adId = data[0].id;
    const targetAccountId = parseInt(accountId, 10);
    const targetClientId = clientId !== 'null' ? parseInt(clientId, 10) : 0;
    const adIds = data.map(x => x.id);
    const strategyData = {
      accountId: targetAccountId,
      clientId: targetClientId,
      ads: adIds,
      properties: strategy.data,
      strategyType: strategy.type,
      updateStep: 10
    };

    return this.usService
      .CreateUserStrategy(strategyData)
      .flatMap(() => this.StartStrategy(targetAccountId, targetClientId, adIds));
  }

  private StartStrategy(accountId: any, clientId: any, adIds) {
    return this.usiService.ToggleStrategies({
      accountId,
      clientId,
      adIds,
      status: true
    })
      .do((data) => {
        return data;
      });
  }

  private ToggleAdStatus(adData, data1) {
    const [accountId] = adData.campaignData.accountId.split('_');
    return this.usiService.ToggleAdStatus({
      accountId,
      adIds: [data1.adId],
      status: true
    });
  }

  private ToggleAdStrategy(accountId: any, data1, clientId: any) {
    return this.usiService.ToggleStrategies({
      accountId: parseInt(accountId, 10),
      adIds: [data1.adId],
      status: true,
      clientId: (clientId === 'null') ? 0 : parseInt(clientId, 10)
    });
  }

  private NavigateToAdsPage(adData, accountId: any, clientId: any, adId) {
    this.router.navigate(['/automation/ads'], {
      queryParams: {
        sendModeration: adData.moderationSettings.sendModeration,
        startAfterModeration: adData.moderationSettings.startAfterModeration,
        account_id: parseInt(accountId, 10),
        client_id: clientId !== 'null' ? parseInt(clientId, 10) : null,
        campaign_id: adData.campaignData.campaignId,
        adId
      }
    });
  }

  public CreateData(adData, text, image) {
    const ad: PostMonitorBackend_V2ModelsAdManagerAdSpecificationViewModel = {
      ad_format: adData.format.ad_format,
      age_from: adData.targetingData.ageFrom,
      age_to: adData.targetingData.ageTo,
      campaign_id: adData.campaignData.campaignId,
      cities: adData.targetingData.cities.split(',').filter(x => parseInt(x, 10) >= 0).join(),
      country: adData.targetingData.country.toString().split(',').map(x => parseInt(x, 10) >= 0 ? x : 0).join(),
      sex: adData.targetingData.sex,
      groups: adData.targetingData.groups,
      title: text,
      image: image,
      link_url: encodeURIComponent(adData.teazer.link),
      link_domain: new DomainOnlyPipe().transform(adData.teazer.link),
      cpm: adData.rate.cpm,
      cost_type: 1,
      impressions_limited: (adData.format.ad_format === 2) ? 1 : null,
      impressions_limit: (adData.format.ad_format === 9) ? 1 : null,
      category1_id: adData.promopost.categoryId || adData.teazer.categoryId,
      name: adData.format.ad_format === 9 ? adData.promopost.name : adData.teazer.name
    };

    const adViewModel = {
      adSpecifications: [ad],
      accountId: adData.campaignData.accountId.split('_')[0],
      groupId: adData.promopost.groupId,
      text: text,
      linkTitle: adData.promopost.linkTitle,
      linkUrl: encodeURIComponent(adData.promopost.linkAddress)
    };

    return adViewModel;
  }

  public LoadData() {
    this.loadingAccounts = true;
    this.LoadSlots()
      .then(this.LoadAccounts.bind(this))
      .then(this.LoadClients.bind(this))
      .then(() => {
        this.accounts = this.accounts.map(account => {
          let slot: UserAutomationCabinetSlot = null;
          if (account.account_type === 'client') {
            slot = this.slots.find(x => x.bindedCabinetId === account.account_id && x.bindedClientId === account.client_id);
          } else {
            slot = this.slots.find(x => x.bindedCabinetId === account.account_id);
          }
          account.is_blocked = isNullOrUndefined(slot);
          this.accounts = this.accounts.sort((a, b) => a.priority - b.priority);
          this.loadingAccounts = false;
          return account;
        });
      });
    // this.LoadSlots();
  }

  public LoadSlots() {
    return new Promise(resolve => {
      this._db.GetSlots()
        .subscribe(x => {
          this.slots = x.data;
          // this.countOfMaxSlots = this.slots.length;
          // if (this.firstLoading) {
          //   this.opened = this.slots.filter(slot => slot.bindedCabinetId !== null).length === 0;
          // }
          // this.countOfFree = this.slots.filter(slot => slot.bindedCabinetId === null).length;
          // this.firstLoading = false;
          resolve();
        });
    });
  }

  public LoadAccounts() {
    // this.accountsViewModels = [];
    return new Promise(resolve => {
      this._api.GetAccounts()
        .subscribe(x => {
          this.accounts = x.data.map((y, index) => {
            return new AccountViewModel(
              y.account_id,
              y.account_name,
              y.access_role,
              y.account_status,
              y.account_type,
              y.is_blocked,
              null,
              index
            );
          });

          resolve();
        });
    });
  }

  public LoadClients() {
    return new Promise(resolve => {
      const promises = this.accounts.filter(x => x.account_type === 'agency')
        .map((account, i) => {
          return new Promise(resolve1 => {
            setTimeout(() => {
              this._api.getClients(account.account_id)
                .subscribe(clients => {
                  clients.data.forEach((client: Client) => {
                    this.accounts.push(new AccountViewModel(
                      account.account_id,
                      client.name,
                      account.account_role,
                      account.account_status,
                      'client',
                      account.is_blocked,
                      client.id,
                      account.priority
                    ));
                  });
                  this.localClientsDb.SetClients(clients.data)
                    .then(data => {
                      resolve1();
                    });
                });
            }, 1300 * i);
          });
        });
      return Promise.all(promises)
        .then(() => {
          setTimeout(() => {
            resolve();
          }, 2000);
        });
    });
  }

  public isCabinetBlocked(cabinetId, cabinetClientId) {
    const slotForCheck = this.slots
      .find(slot => slot.bindedCabinetId === cabinetId && slot.bindedClientId === cabinetClientId);

    return slotForCheck === null;
  }

  public ChangeBind() {
    this.LoadData();
  }
}
