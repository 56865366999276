/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./ad-account-manager.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "@clr/angular";
import * as i4 from "@angular/common";
import * as i5 from "../../../../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i6 from "@angular/material/progress-spinner";
import * as i7 from "@angular/cdk/platform";
import * as i8 from "@angular/platform-browser/animations";
import * as i9 from "./ad-account-manager.component";
import * as i10 from "../../../../../api/services/automation-vk.service";
import * as i11 from "../../../../../shared/services/dexie/dexie-cabinets.service";
import * as i12 from "../../../../../api/services/automation-vk-slot.service";
import * as i13 from "../../../../services/automation-expiration-manager.service";
import * as i14 from "../../../../../shared/services/dexie/dexie-clients.service";
import * as i15 from "../../../../../api/services/ad-manager.service";
import * as i16 from "@angular/material/dialog";
import * as i17 from "@angular/router";
var styles_AdAccountManagerComponent = [i0.styles];
var RenderType_AdAccountManagerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AdAccountManagerComponent, data: {} });
export { RenderType_AdAccountManagerComponent as RenderType_AdAccountManagerComponent };
function View_AdAccountManagerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "option", [], [[8, "disabled", 0], [8, "title", 0]], null, null, null, null)), i1.ɵdid(2, 147456, null, 0, i2.NgSelectOption, [i1.ElementRef, i1.Renderer2, [2, i2.SelectControlValueAccessor]], { value: [0, "value"] }, null), i1.ɵdid(3, 147456, null, 0, i2.ɵangular_packages_forms_forms_s, [i1.ElementRef, i1.Renderer2, [8, null]], { value: [0, "value"] }, null), (_l()(), i1.ɵted(4, null, [" ", " "]))], function (_ck, _v) { var currVal_2 = ((_v.context.$implicit.account_id + "_") + _v.context.$implicit.client_id); _ck(_v, 2, 0, currVal_2); var currVal_3 = ((_v.context.$implicit.account_id + "_") + _v.context.$implicit.client_id); _ck(_v, 3, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.is_blocked; var currVal_1 = _co.GetCampaignTitle(_v.context.$implicit); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_4 = _v.context.$implicit.account_name; _ck(_v, 4, 0, currVal_4); }); }
function View_AdAccountManagerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "vk-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 12, "div", [["class", "vk-strip-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 11, "div", [["class", "vk-strip-wrapper__item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "label", [["class", "vk-label"], ["for", "chose-account"]], [[1, "for", 0]], null, null, null, null)), i1.ɵdid(4, 212992, null, 0, i3.ClrLabel, [[2, i3.ɵbe], [2, i3.ɵbf], [2, i3.ɵbg], i1.Renderer2, i1.ElementRef], { forAttr: [0, "forAttr"] }, null), (_l()(), i1.ɵted(-1, null, ["\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u0440\u0435\u043A\u043B\u0430\u043C\u043D\u044B\u0439 \u043A\u0430\u0431\u0438\u043D\u0435\u0442:"])), (_l()(), i1.ɵeld(6, 0, null, null, 7, "select", [["class", "vk-select"], ["id", "chose-account"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).onChange($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 7).onTouched() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 16384, null, 0, i2.SelectControlValueAccessor, [i1.Renderer2, i1.ElementRef], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.SelectControlValueAccessor]), i1.ɵdid(9, 540672, null, 0, i2.FormControlDirective, [[8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR], [2, i2.ɵangular_packages_forms_forms_k]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i2.NgControl, null, [i2.FormControlDirective]), i1.ɵdid(11, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AdAccountManagerComponent_2)), i1.ɵdid(13, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "chose-account"; _ck(_v, 4, 0, currVal_1); var currVal_9 = _co.controlContainer.control.get("accountId"); _ck(_v, 9, 0, currVal_9); var currVal_10 = _co.AccountsFiltered; _ck(_v, 13, 0, currVal_10); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).forAttr; _ck(_v, 3, 0, currVal_0); var currVal_2 = i1.ɵnov(_v, 11).ngClassUntouched; var currVal_3 = i1.ɵnov(_v, 11).ngClassTouched; var currVal_4 = i1.ɵnov(_v, 11).ngClassPristine; var currVal_5 = i1.ɵnov(_v, 11).ngClassDirty; var currVal_6 = i1.ɵnov(_v, 11).ngClassValid; var currVal_7 = i1.ɵnov(_v, 11).ngClassInvalid; var currVal_8 = i1.ɵnov(_v, 11).ngClassPending; _ck(_v, 6, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }); }
function View_AdAccountManagerComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "option", [], null, null, null, null, null)), i1.ɵdid(2, 147456, null, 0, i2.NgSelectOption, [i1.ElementRef, i1.Renderer2, [2, i2.SelectControlValueAccessor]], { value: [0, "value"] }, null), i1.ɵdid(3, 147456, null, 0, i2.ɵangular_packages_forms_forms_s, [i1.ElementRef, i1.Renderer2, [8, null]], { value: [0, "value"] }, null), (_l()(), i1.ɵted(4, null, [" ", " "]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.id; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.id; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.name; _ck(_v, 4, 0, currVal_2); }); }
function View_AdAccountManagerComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "label", [["class", "vk-label"], ["for", "chose-campaign"]], [[1, "for", 0]], null, null, null, null)), i1.ɵdid(2, 212992, null, 0, i3.ClrLabel, [[2, i3.ɵbe], [2, i3.ɵbf], [2, i3.ɵbg], i1.Renderer2, i1.ElementRef], { forAttr: [0, "forAttr"] }, null), (_l()(), i1.ɵted(-1, null, ["\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u043A\u0430\u043C\u043F\u0430\u043D\u0438\u044E:"])), (_l()(), i1.ɵeld(4, 0, null, null, 7, "select", [["class", "vk-select"], ["id", "chose-campaign"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onChange($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 5).onTouched() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 16384, null, 0, i2.SelectControlValueAccessor, [i1.Renderer2, i1.ElementRef], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.SelectControlValueAccessor]), i1.ɵdid(7, 540672, null, 0, i2.FormControlDirective, [[8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR], [2, i2.ɵangular_packages_forms_forms_k]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i2.NgControl, null, [i2.FormControlDirective]), i1.ɵdid(9, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AdAccountManagerComponent_5)), i1.ɵdid(11, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "chose-campaign"; _ck(_v, 2, 0, currVal_1); var currVal_9 = _co.controlContainer.control.get("campaignId"); _ck(_v, 7, 0, currVal_9); var currVal_10 = _co.CampaingsFiltered; _ck(_v, 11, 0, currVal_10); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).forAttr; _ck(_v, 1, 0, currVal_0); var currVal_2 = i1.ɵnov(_v, 9).ngClassUntouched; var currVal_3 = i1.ɵnov(_v, 9).ngClassTouched; var currVal_4 = i1.ɵnov(_v, 9).ngClassPristine; var currVal_5 = i1.ɵnov(_v, 9).ngClassDirty; var currVal_6 = i1.ɵnov(_v, 9).ngClassValid; var currVal_7 = i1.ɵnov(_v, 9).ngClassInvalid; var currVal_8 = i1.ɵnov(_v, 9).ngClassPending; _ck(_v, 4, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }); }
function View_AdAccountManagerComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AdAccountManagerComponent_4)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.CampaingsFiltered.length > 0); _ck(_v, 2, 0, currVal_0); }, null); }
function View_AdAccountManagerComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-spinner", [["class", "mat-spinner mat-progress-spinner"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i5.View_MatSpinner_0, i5.RenderType_MatSpinner)), i1.ɵdid(1, 49152, null, 0, i6.MatSpinner, [i1.ElementRef, i7.Platform, [2, i4.DOCUMENT], [2, i8.ANIMATION_MODULE_TYPE], i6.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], { diameter: [0, "diameter"] }, null)], function (_ck, _v) { var currVal_3 = 16; _ck(_v, 1, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1)._noopAnimations; var currVal_1 = i1.ɵnov(_v, 1).diameter; var currVal_2 = i1.ɵnov(_v, 1).diameter; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_AdAccountManagerComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "label", [["class", "vk-label"], ["for", "create-new-campaign-button"]], [[1, "for", 0]], null, null, null, null)), i1.ɵdid(2, 212992, null, 0, i3.ClrLabel, [[2, i3.ɵbe], [2, i3.ɵbf], [2, i3.ɵbg], i1.Renderer2, i1.ElementRef], { forAttr: [0, "forAttr"] }, null), (_l()(), i1.ɵted(-1, null, ["\u0438\u043B\u0438"]))], function (_ck, _v) { var currVal_1 = "create-new-campaign-button"; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).forAttr; _ck(_v, 1, 0, currVal_0); }); }
export function View_AdAccountManagerComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { pickList: 0 }), i1.ɵqud(402653184, 2, { dgPagination: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 19, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 18, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 5).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 16384, null, 0, i2.ɵangular_packages_forms_forms_bh, [], null, null), i1.ɵdid(5, 540672, null, 0, i2.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i2.ControlContainer, null, [i2.FormGroupDirective]), i1.ɵdid(7, 16384, null, 0, i2.NgControlStatusGroup, [[4, i2.ControlContainer]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AdAccountManagerComponent_1)), i1.ɵdid(9, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 11, "div", [["class", "vk-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 10, "div", [["class", "vk-strip-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 3, "div", [["class", "vk-strip-wrapper__item"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AdAccountManagerComponent_3)), i1.ɵdid(14, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["loadingScreen", 2]], null, 0, null, View_AdAccountManagerComponent_6)), (_l()(), i1.ɵeld(16, 0, null, null, 5, "div", [["class", "vk-strip-wrapper__item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 4, "div", [["class", "vk-strip-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AdAccountManagerComponent_7)), i1.ɵdid(19, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(20, 0, null, null, 1, "button", [["class", "vk-standart-button"], ["id", "create-new-campaign-button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.CreateCompany() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u0421\u043E\u0437\u0434\u0430\u0439\u0442\u0435 \u043D\u043E\u0432\u0443\u044E \u043A\u0430\u043C\u043F\u0430\u043D\u0438\u044E "]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.controlContainer.control; _ck(_v, 5, 0, currVal_7); var currVal_8 = (_co.accounts && (_co.accounts.length > 1)); _ck(_v, 9, 0, currVal_8); var currVal_9 = !_co.loadingCampaigns; var currVal_10 = i1.ɵnov(_v, 15); _ck(_v, 14, 0, currVal_9, currVal_10); var currVal_11 = (_co.CampaingsFiltered.length > 0); _ck(_v, 19, 0, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 7).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 7).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 7).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 7).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 7).ngClassValid; var currVal_5 = i1.ɵnov(_v, 7).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 7).ngClassPending; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_12 = _co.disabled; _ck(_v, 20, 0, currVal_12); }); }
export function View_AdAccountManagerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-ad-account-manager", [], null, null, null, View_AdAccountManagerComponent_0, RenderType_AdAccountManagerComponent)), i1.ɵdid(1, 638976, null, 0, i9.AdAccountManagerComponent, [i10.AutomationVkService, i11.DexieCabinetsService, i12.AutomationVkSlotService, i13.AutomationExpirationManagerService, i14.DexieClientsService, i15.AdManagerService, i16.MatDialog, i17.ActivatedRoute, i2.ControlContainer, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AdAccountManagerComponentNgFactory = i1.ɵccf("app-ad-account-manager", i9.AdAccountManagerComponent, View_AdAccountManagerComponent_Host_0, { accounts: "accounts", accountId: "accountId", account_role: "account_role", campaignId: "campaignId", disabled: "disabled", selectedCampaignsType: "selectedCampaignsType", ad: "ad" }, { onNext: "onNext", onSave: "onSave" }, []);
export { AdAccountManagerComponentNgFactory as AdAccountManagerComponentNgFactory };
