import {Injectable} from '@angular/core';
import {AdNetworkGroup} from './ad-network-group.model';
import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';

export interface AdNetworkGroupsState extends EntityState<AdNetworkGroup> {
  ui: {
    searchGroupName: string;
    openedGroupId: number | null;
  };
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'ad-network-groups', resettable: true})
export class AdNetworkGroupsStore extends EntityStore<AdNetworkGroupsState> {

  constructor() {
    super({
      ui: {
        searchGroupName: '',
        openedGroupId: null
      }
    });
  }

}

