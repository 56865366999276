import {Pipe, PipeTransform} from '@angular/core';
import {isNullOrUndefined} from 'util';

@Pipe({
  name: 'secondsToTime'
})
export class SecondsToTimePipe implements PipeTransform {

  transform(value: number): string {

    const seconds = value % 60;
    const minutes = (value >= 60) ? (value / 60) % 60 : 0;
    const hours = (value >= 60 * 60) ? ((value / 60) / 60) % 24 : null;

    let timeString = '';

    if (!isNullOrUndefined(hours)) {
      timeString += `${this.GetValueFormatted(hours, 1)}:`;
    }

    timeString += `${this.GetValueFormatted(minutes, 2)}:${this.GetValueFormatted(seconds,2)}`;

    return timeString;
  }

  private GetValueFormatted(value: number, minimalLength: number): string {
    return (`0${Math.floor(value)}`).slice(-minimalLength);
  }

}
