export class TariffPlanOptionTimeValue {
  public timeId: number;

  public title: string;
  public value: number;
  public descriminator: string;

  public isAvailable: boolean;

  public price: number;
}
