import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from "@angular/forms";
import { pairwise, startWith } from "rxjs/operators";
import { CopyingFormState, CopyingService } from "../../services/copying.service";
import { MatDialogRef } from "@angular/material";
import { CopyingAdDataViewModel } from "../../models/copying-ad-data.view-model";
import { CopyingType } from "../../copying-configurators/abstract-copying-configurator";
import { AdViewModel } from "../../../models/view-models/ad-view-model";
export var CopyingCloseStatus;
(function (CopyingCloseStatus) {
    CopyingCloseStatus["REFRESH_PAGE"] = "REFRESH_PAGE";
    CopyingCloseStatus["NAVIGATE_TO"] = "NAVIGATE_TO";
    CopyingCloseStatus["NOTHING_TO_DO"] = "NOTHING_TO_DO";
})(CopyingCloseStatus || (CopyingCloseStatus = {}));
var CopyingAdsDialogComponent = /** @class */ (function () {
    function CopyingAdsDialogComponent(copyingService, dialogData, dialog, cd) {
        var _this = this;
        this.copyingService = copyingService;
        this.dialogData = dialogData;
        this.dialog = dialog;
        this.cd = cd;
        this.enableSegmentation = false;
        this.enabledGenerationLink = false;
        this.segment = new FormControl(false);
        this.changeCpmCost = new FormControl(false);
        this.prevMode = 'BY_SEX_AND_AGE';
        this.copyingAd = new AdViewModel();
        this.CloseDialogStatuse = CopyingCloseStatus;
        this.showGenerationLinkForm = false;
        this.messages = {
            BY_ACTIVE_GROUPS: 'по группам активности',
            BY_SEX_AND_AGE: 'по полу и возрасту',
            BY_RETARGETING_GROUPS: 'по группам ретаргетинга',
            BY_GROUPS: 'по группам',
            BY_ACTIVE_GROUPS_FILES: 'по группам активности из файла',
            BY_INTEREST_CATEGORIES: 'по категориям интересов',
            BY_INTEREST_CATEGORIES_CHECKBOXES_TREE: 'по категориям интересов из списка',
            BY_GROUPS_FILE: 'по группам из файла',
        };
        this.modes = [
            {
                title: 'По аудитории',
                suggestion_code: 'AUDITORIES_SEGMENTATION',
                children: [
                    {
                        title: 'По полу и возрасту',
                        mode_code: 'BY_SEX_AND_AGE',
                        suggestion_code: 'AGE_SEX_SEGMENTATION',
                        is_new: false,
                        can_be_selected: function () { return true; },
                        is_full_width: false
                    },
                    {
                        title: 'По группам',
                        mode_code: 'BY_GROUPS',
                        suggestion_code: 'GROUPS_SEGMENT',
                        is_new: false,
                        can_be_selected: function () { return (_this.copyingAd.groups === '' || _this.copyingAd.groups === null); },
                        is_full_width: false
                    },
                    {
                        title: 'По группам из файла',
                        mode_code: 'BY_GROUPS_FILE',
                        suggestion_code: 'GROUPS_FILE_SEGMENTATION',
                        is_new: false,
                        can_be_selected: function () { return true; },
                        is_full_width: false
                    },
                    {
                        title: 'По группам ретаргетинга',
                        mode_code: 'BY_RETARGETING_GROUPS',
                        suggestion_code: 'RETARGETING_GROUPS_SEGMENTATION',
                        is_new: false,
                        can_be_selected: function () { return (_this.copyingAd.containsRetargetingGroups()); },
                        is_full_width: false
                    },
                    {
                        title: 'Активности в сообществах из списка групп в объявлении',
                        mode_code: 'BY_ACTIVE_GROUPS',
                        suggestion_code: 'ACTIVE_GROUPS_SEGMENTATION',
                        is_new: true,
                        can_be_selected: function () { return (_this.copyingAd.containsActiveGroups()); },
                        is_full_width: true
                    },
                    {
                        title: 'Активности в сообществах из файла',
                        mode_code: 'BY_ACTIVE_GROUPS_FILES',
                        suggestion_code: 'ACTIVE_GROUPS_FILE_SEGMENTATION',
                        is_new: true,
                        can_be_selected: function () { return true; },
                        is_full_width: true
                    },
                    {
                        title: 'Категории интересов из списка в объявлении',
                        mode_code: 'BY_INTEREST_CATEGORIES',
                        suggestion_code: 'INTEREST_CATEGORIES_SEGMENT',
                        is_new: true,
                        can_be_selected: function () { return (_this.copyingAd.containsInterestGroups()); },
                        is_full_width: true
                    },
                    {
                        title: 'Категории интересов - выбор из списка',
                        mode_code: 'BY_INTEREST_CATEGORIES_CHECKBOXES_TREE',
                        suggestion_code: 'INTEREST_CATEGORIES_CHECKBOXES_TREE_SGEMENT',
                        is_new: true,
                        can_be_selected: function () { return true; },
                        is_full_width: true
                    }
                ]
            }
        ];
    }
    CopyingAdsDialogComponent.prototype.ngOnDestroy = function () {
        this.copyingService.state = CopyingFormState.DEFAULT_FORM;
        this.copyingService.adsToCopying = [];
        this.copyingService.form.reset({
            configurationMode: 'DEFAULT_COPYING',
            defaultConfiguration: {
                countOfCopies: 1
            }
        });
        this.cd.detach();
    };
    Object.defineProperty(CopyingAdsDialogComponent.prototype, "countOfAds", {
        get: function () {
            return this.copyingService.adsToCopying.length;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CopyingAdsDialogComponent.prototype, "countOfCopyedAds", {
        get: function () {
            return this.copyingService.countOfCopyedAds;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CopyingAdsDialogComponent.prototype, "ShowRedirectUrl", {
        // public get showGenerationLinkForm() {
        //   return this.form.value.generateLinks;
        // }
        get: function () {
            var _a = this.copyingService.form.value, accountId = _a.accountId, campaignId = _a.campaignId, targetAccountId = _a.targetAccountId, targetCampaignId = _a.targetCampaignId;
            return accountId !== targetAccountId || campaignId !== targetCampaignId;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CopyingAdsDialogComponent.prototype, "isPromo", {
        get: function () {
            return this.copyingService.ad_format === 9;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CopyingAdsDialogComponent.prototype, "isAllNotCopied", {
        get: function () {
            return this.copyingService.notCopyedAds.length === this.copyingService.adsToCopying.length;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CopyingAdsDialogComponent.prototype, "formState", {
        get: function () {
            return this.copyingService.state;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CopyingAdsDialogComponent.prototype, "generateLinks", {
        get: function () {
            return this.form.get('generateLinks').value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CopyingAdsDialogComponent.prototype, "segmentName", {
        get: function () {
            return this.messages[this.CurrentConfigurationMode];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CopyingAdsDialogComponent.prototype, "ShowDefaultConfigurationForms", {
        get: function () {
            return !this.segment.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CopyingAdsDialogComponent.prototype, "ShowCostConfiguration", {
        get: function () {
            return this.changeCpmCost.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CopyingAdsDialogComponent.prototype, "CurrentConfigurationMode", {
        get: function () {
            return this.form.get('configurationMode').value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CopyingAdsDialogComponent.prototype, "errorMessage", {
        get: function () {
            return this.copyingService.errorMessage;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CopyingAdsDialogComponent.prototype, "ShowSegmentationInfo", {
        get: function () {
            return (this.CopyingAdvancedMode || this.showGenerationLinkForm === true)
                && this.form.get('configurationMode').value !== CopyingType.DEFAULT_COPYING;
        },
        enumerable: true,
        configurable: true
    });
    ;
    Object.defineProperty(CopyingAdsDialogComponent.prototype, "CopyingAdvancedMode", {
        get: function () {
            return this.copyingService.openedAdvancedMode;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CopyingAdsDialogComponent.prototype, "ShowCostValueControl", {
        get: function () {
            return this.form.value.costType !== null;
        },
        enumerable: true,
        configurable: true
    });
    ;
    Object.defineProperty(CopyingAdsDialogComponent.prototype, "ContainsLinks", {
        get: function () {
            return this.copyingAd.ad_format === 9 && this.copyingAd.containsLinks();
        },
        enumerable: true,
        configurable: true
    });
    CopyingAdsDialogComponent.prototype.ngOnInit = function () {
        var _a = this.dialogData, account_id = _a.account_id, ad_format = _a.ad_format, ads = _a.ads, campaign_id = _a.campaign_id, client_id = _a.client_id;
        this.copyingAd = ads[0];
        this.enableSegmentation = ads.length === 1;
        console.log(ads[0].CanGenerateLink(), 'ADS');
        this.enabledGenerationLink = ads[0].CanGenerateLink();
        var _b = this.PrepareInitialData(ads), adGroups = _b.adGroups, retargetingGroups = _b.retargetingGroups, retargetingGroupsNot = _b.retargetingGroupsNot, activeGroups = _b.activeGroups, interestGroups = _b.interestGroups, link = _b.link;
        this.copyingService.init(account_id + "_" + client_id, "" + campaign_id, account_id + "_" + client_id, "" + campaign_id, ads, ad_format, adGroups[0], retargetingGroups[0].map(function (x) { return parseInt(x, 10); }), retargetingGroupsNot[0], activeGroups[0], interestGroups[0], link);
        this.form = this.copyingService.form;
        this.SubscribToForm();
    };
    CopyingAdsDialogComponent.prototype.SubscribToForm = function () {
        this.SubscribeToAgeSegments();
        this.SubscribeToCostType();
        this.SubscribeToLinkGeneration();
    };
    CopyingAdsDialogComponent.prototype.SubscribeToCostType = function () {
        var _this = this;
        this.form.get('costType').valueChanges.subscribe(function (costType) {
            console.log(costType, 'COST TYPE');
            switch (costType) {
                case 0:
                    _this.showCostValue = true;
                    _this.showErrors = false;
                    _this.setCpcValidators();
                    _this.showErrors = true;
                    break;
                case 1:
                    _this.showCostValue = true;
                    _this.showErrors = false;
                    _this.setCpmValidators();
                    _this.showErrors = true;
                    break;
                case null:
                    _this.showCostValue = false;
                    _this.setDefaultValidators();
                    break;
            }
            _this.setCostValueMessageText();
        });
    };
    CopyingAdsDialogComponent.prototype.SubscribeToAgeSegments = function () {
        var _this = this;
        this.segment.valueChanges
            .pipe(startWith(false), pairwise())
            .subscribe(function (_a) {
            var segmentPrev = _a[0], segmentCurr = _a[1];
            console.log(segmentPrev && !segmentCurr);
            if (segmentPrev && !segmentCurr) {
                _this.prevMode = _this.form.value.configurationMode;
                _this.form.patchValue({
                    configurationMode: 'DEFAULT_COPYING'
                });
            }
            else {
                _this.form.patchValue({
                    configurationMode: _this.prevMode != 'DEFAULT_COPYING' ? _this.prevMode : 'BY_SEX_AND_AGE'
                });
            }
        });
    };
    CopyingAdsDialogComponent.prototype.PrepareInitialData = function (ads) {
        var adGroups = ads.map(function (_a) {
            var groups = _a.groups;
            return groups ? groups.split(',') : [];
        });
        var retargetingGroups = ads.map(function (_a) {
            var retargeting_groups = _a.retargeting_groups;
            return retargeting_groups ? retargeting_groups.split(',') : [];
        });
        var retargetingGroupsNot = ads.map(function (_a) {
            var retargeting_groups_not = _a.retargeting_groups_not;
            return retargeting_groups_not ? retargeting_groups_not.split(',') : [];
        });
        var activeGroups = ads.map(function (_a) {
            var groups_active = _a.groups_active;
            return groups_active ? groups_active.split(',') : [];
        });
        var interestGroups = ads.map(function (_a) {
            var interestGroups = _a.interestGroups;
            return interestGroups ? interestGroups.split(',') : [];
        });
        var link = ads.length > 1 ? '' : ads[0].GetLinkUrl();
        return { adGroups: adGroups, retargetingGroups: retargetingGroups, retargetingGroupsNot: retargetingGroupsNot, activeGroups: activeGroups, interestGroups: interestGroups, link: link };
    };
    CopyingAdsDialogComponent.prototype.save = function () {
        this.copyingService.CopyAds();
    };
    CopyingAdsDialogComponent.prototype.ToggleAdvancedMode = function () {
        this.copyingService.ToggleAdvancedMode();
    };
    CopyingAdsDialogComponent.prototype.CloseWithStatus = function (status) {
        if (status === void 0) { status = CopyingCloseStatus.NAVIGATE_TO; }
        var link = '';
        if (status === CopyingCloseStatus.NAVIGATE_TO) {
            var _a = this.form.value.targetAccountId.split('_'), accountId = _a[0], clientId = _a[1];
            link = "/automation/ads?account_id=" + accountId + "&campaign_id=" + this.form.value.targetCampaignId + "&client_id=" + clientId;
        }
        this.dialog.close({
            status: status,
            link: link
        });
    };
    CopyingAdsDialogComponent.prototype.setCpmValidators = function () {
        var control = this.form.get('costValue');
        var minCpm = this.dialogData.bid_range.cpmMin / 100;
        var maxCpm = this.dialogData.bid_range.cpmMax / 100;
        this.costValueMinMessage = "\u041C\u0438\u043D\u0438\u043C\u0430\u043B\u044C\u043D\u0430\u044F \u0446\u0435\u043D\u0430 1000 \u043F\u0440\u043E\u0441\u043C\u043E\u0442\u0440\u043E\u0432 \u0440\u0430\u0432\u043D\u0430 " + minCpm + " \u0440\u0443\u0431.";
        this.costValueMaxMessage = "\u041C\u0430\u043A\u0441\u0438\u043C\u0430\u043B\u044C\u043D\u0430\u044F \u0446\u0435\u043D\u0430 1000 \u043F\u0440\u043E\u0441\u043C\u043E\u0442\u0440\u043E\u0432 \u0440\u0430\u0432\u043D\u0430 " + maxCpm + " \u0440\u0443\u0431.";
        control.setValidators([Validators.required, Validators.min(minCpm), Validators.max(maxCpm)]);
        control.setValue(minCpm);
        console.log(control.value);
        control.updateValueAndValidity();
        this.cd.detectChanges();
    };
    CopyingAdsDialogComponent.prototype.setCpcValidators = function () {
        var control = this.form.get('costValue');
        var minCpc = this.dialogData.bid_range.cpcMin / 100;
        var maxCpc = this.dialogData.bid_range.cpcMax / 100;
        console.log(minCpc, maxCpc, 'MINMAX');
        this.costValueMinMessage = "\u041C\u0438\u043D\u0438\u043C\u0430\u043B\u044C\u043D\u0430\u044F \u0446\u0435\u043D\u0430 1 \u043F\u0435\u0440\u0435\u0445\u043E\u0434\u0430 \u0440\u0430\u0432\u043D\u0430 " + minCpc + " \u0440\u0443\u0431.";
        this.costValueMaxMessage = "\u041C\u0430\u043A\u0441\u0438\u043C\u0430\u043B\u044C\u043D\u0430\u044F \u0446\u0435\u043D\u0430 1 \u043F\u0435\u0440\u0435\u0445\u043E\u0434\u0430 \u0440\u0430\u0432\u043D\u0430 " + maxCpc + " \u0440\u0443\u0431.";
        control.setValidators([Validators.required, Validators.min(minCpc), Validators.max(maxCpc)]);
        control.setValue(minCpc);
        control.updateValueAndValidity();
        console.log(control.value);
        this.cd.detectChanges();
    };
    CopyingAdsDialogComponent.prototype.setDefaultValidators = function () {
        var control = this.form.get('costValue');
        control.setValidators([]);
    };
    CopyingAdsDialogComponent.prototype.setCostValueMessageText = function () {
        var costType = this.form.get('costType').value;
        if (costType === 0) {
            this.costValueLabelText = 'Введите цену 1 перехода';
        }
        else {
            this.costValueLabelText = 'Введите цену 1000 показов';
        }
    };
    CopyingAdsDialogComponent.prototype.ShowSegmentationData = function () {
        this.copyingService.openedAdvancedMode = false;
        this.showGenerationLinkForm = false;
    };
    CopyingAdsDialogComponent.prototype.ToggleShowGenerationLink = function () {
        this.showGenerationLinkForm = !this.showGenerationLinkForm;
    };
    CopyingAdsDialogComponent.prototype.SubscribeToLinkGeneration = function () {
        var _this = this;
        this.form
            .get('generateLinks')
            .valueChanges
            .subscribe(function (x) {
            _this.showGenerationLinkForm = x;
        });
    };
    return CopyingAdsDialogComponent;
}());
export { CopyingAdsDialogComponent };
