import { OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ControlContainer, FormControl } from '@angular/forms';
import { AdManagerService, AutomationVkService } from '../../../../../api/services';
import { Subject } from 'rxjs/Subject';
import 'rxjs-compat/add/operator/delay';
import 'rxjs-compat/add/operator/skipUntil';
import { environment } from '../../../../../../environments/environment';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { GroupsSelectorService } from '../../../services/groups-selector/groups-selector.service';
import { MatSort, MatTableDataSource } from '@angular/material';
import 'rxjs-compat/add/operator/pairwise';
import 'rxjs-compat/add/operator/map';
var TargetingSettingsComponent = /** @class */ (function () {
    function TargetingSettingsComponent(adManagerService, vkService, controlContainer, groupsService) {
        this.adManagerService = adManagerService;
        this.vkService = vkService;
        this.controlContainer = controlContainer;
        this.groupsService = groupsService;
        this.countrySearchString = new FormControl();
        this.citySearchString = new FormControl();
        this.citiesParams = new Subject();
        this.cities = [];
        this.ages = environment.vkAges;
        this.audience = null;
        this.dataSource = new MatTableDataSource([]);
        this.TipGroups = 'Вы можете показывать объявление, подписчикам групп конкурентов или смежных по вашей тематике групп. Для этого введите названия групп, либо слова которые могут содержать названия групп.\n' +
            '\n' +
            'Пример:\n' +
            'Если ваш вид деятельности - салон красоты, то в поле Название группы введите “Салон красоты”, “Наращивание ресниц” или “Брови” и т.п. \n' +
            '\n' +
            'Выберите страну и город где находятся ваши клиенты. \n' +
            'Если вы и ваши клиенты находятся, например, в городе Екатеринбург, то выберите в поле Страна - “Россия”, а в поле Город или регион: Екатеринбург.\n' +
            '\n' +
            'После выбора групп, справа, будет показываться какому количеству пользователей может быть показано ваше объявление.\n';
    }
    Object.defineProperty(TargetingSettingsComponent.prototype, "AgesFrom", {
        get: function () {
            var ageTo = parseInt(this.controlContainer.control.get('ageTo').value, 10);
            if (ageTo !== 0) {
                return this.ages.filter(function (x) { return x <= ageTo; });
            }
            else {
                return this.ages;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TargetingSettingsComponent.prototype, "AgesTo", {
        get: function () {
            var ageFrom = parseInt(this.controlContainer.control.get('ageFrom').value, 10);
            if (ageFrom !== 0) {
                return this.ages.filter(function (x) { return x >= ageFrom; });
            }
            else {
                return this.ages;
            }
        },
        enumerable: true,
        configurable: true
    });
    TargetingSettingsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.LoadCountries();
        this.LoadCities(1, '');
        this.groupsService.selectedGroupsSubject.subscribe(function (data) {
            console.log(data);
            _this.controlContainer.control.get('groups').setValue(data.map(function (x) { return x.id; }).join());
            _this.dataSource = new MatTableDataSource(data);
            _this.dataSource.sort = _this.sort;
        });
        this.controlContainer.control.valueChanges
            .pipe(distinctUntilChanged(), debounceTime(1000))
            .subscribe(function (data) {
            var _a = _this.accountId.split('_'), accountId = _a[0], clientId = _a[1];
            accountId = parseInt(accountId, 10);
            clientId = parseInt(clientId, 10);
            _this.LoadStats(accountId, clientId, data);
        });
        this.controlContainer.control.get('country')
            .valueChanges
            .filter(function (x) { return parseInt(x, 10) >= 0; })
            .subscribe(function (data) {
            if (parseInt(data, 10) === 0) {
                _this.controlContainer
                    .control
                    .get('cities')
                    .setValue([0]);
            }
            console.log(_this.controlContainer.control.value, data, 'TARGETING SETTINGS');
            if (parseInt(data, 10) > 0) {
                _this.citiesParams.next({ country: data, searchString: _this.citySearchString.value });
            }
        });
        this.citySearchString.valueChanges
            .subscribe(function (searchString) {
            _this.citiesParams.next({ searchString: searchString, country: _this.controlContainer.control.get('country').value });
        });
        this.citiesParams.subscribe(function (_a) {
            var country = _a.country, searchString = _a.searchString;
            console.log(country, searchString);
            _this.LoadCities(country, searchString);
        });
        this.controlContainer.control.get('cities').valueChanges.subscribe(function (data) {
            if (data[0] === 0) {
                _this.controlContainer.control.get('cities').setValue([0], { emitEvent: false });
            }
            if (data.length > 1) {
                _this.controlContainer.control.get('cities').setValue(data.filter(function (val) { return val > 0; }), { emitEvent: false });
            }
            // if (data.length === 1 && data[0] === 0) {
            //
            // }
            if (data.length === 0) {
                _this.controlContainer.control.get('cities').setValue([0], { emitEvent: false });
            }
        });
        // combineLatest(
        //     this.controlContainer.control.get('country').valueChanges,
        //     this.citySearchString.valueChanges)
        //   .pipe(
        //     distinctUntilChanged(),
        //   )
        //   .subscribe(([country, searchString]) => {
        //     console.log(searchString, country);
        //   });
    };
    TargetingSettingsComponent.prototype.LoadStats = function (accountId, clientId, data) {
        var _this = this;
        console.log('TARGETING STATS');
        setTimeout(function () {
            _this.targetingStats$ = _this.adManagerService.GetTargetingStats({
                accountId: accountId,
                clientId: clientId,
                linkUrl: 'https://vk.com',
                linkDomain: 'vk.com',
                adFormat: _this.adFormat,
                criteria: {
                    age_from: data ? data.ageFrom : 0,
                    age_to: data ? data.ageTo : 0,
                    cities: data ? data.cities ? data.cities.filter(function (x) { return x >= 0; }).join() : '' : '',
                    country: (data && parseInt(data.country, 10) >= 0) ? data.country : 0,
                    sex: data ? data.sex : 0,
                    groups: data ? data.groups : '',
                },
            })
                .pipe(distinctUntilChanged(), debounceTime(1000), map(function (targetingStats) {
                if (targetingStats !== null) {
                    _this.controlContainer.control.get('audience').setValue(targetingStats.audience_count, {
                        emitEvent: false
                    });
                    _this.controlContainer.control.get('audience').markAsDirty();
                    // this.audience = targetingStats.audience_count;
                }
                return targetingStats;
            }));
        }, 1300);
    };
    TargetingSettingsComponent.prototype.RemoveGroupFromSelected = function (group) {
        this.groupsService.SelectGroups([group]);
    };
    TargetingSettingsComponent.prototype.LoadCountries = function () {
        this.countries$ = this.adManagerService.GetCountries()
            .map(function (response) { return response.items; });
    };
    Object.defineProperty(TargetingSettingsComponent.prototype, "DisplayedCities", {
        get: function () {
            var _this = this;
            return (this.citySearchString.value)
                ? this.cities.filter(function (x) { return x.title.toLowerCase().includes(_this.citySearchString.value.toLowerCase()); })
                : this.cities;
        },
        enumerable: true,
        configurable: true
    });
    TargetingSettingsComponent.prototype.LoadCities = function (countryId, searchString) {
        var _this = this;
        this.adManagerService.GetCities({
            countryId: countryId,
            searchString: searchString
        })
            .subscribe(function (data) {
            data.forEach(function (city) {
                if (!_this.cities.map(function (x) { return x.id; }).includes(city.id)) {
                    _this.cities.push(city);
                }
            });
        });
    };
    TargetingSettingsComponent.prototype.ngOnChanges = function (changes) {
        console.log(changes);
        if ((changes.accountId || changes.adFormat)) {
            if (!this.accountId.includes('undefined')) {
                var _a = this.accountId.split('_'), accountId = _a[0], clientId = _a[1];
                accountId = parseInt(accountId, 10);
                clientId = parseInt(clientId, 10);
                this.LoadStats(accountId, clientId, this.controlContainer.control.value);
            }
        }
    };
    return TargetingSettingsComponent;
}());
export { TargetingSettingsComponent };
