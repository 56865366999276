import {Ad} from '../../api/models/ad';
import {StatisticsData} from '../../api/models/statistics-data';
import {AdLayout} from '../../api/models/ad-layout';
import {WallPost} from '../../api/models/wall-post';
import {UserStrategy} from '../../api/models/user-strategy';
import {AdViewModel} from './view-models/ad-view-model';

export class EasyAdContainerModel {
  public accountId: number;
  public clientId: number;
  public campaignId: number;
  public adId: number;

  public createdAt: Date;

  public ad: Ad;

  public adLayout: AdLayout;

  public post: WallPost;

  public statistics: StatisticsData; // Статистику сюда стоит записывать, получая её с параметром period=overall

  public strategyRecieved: boolean = false;
  public userStrategy: UserStrategy;

  public graphicsLoaded: boolean = false;

  public errors: Array<string> = null;

  public forceLoadingAbort: boolean = false;

  public blockAd: boolean = false;

  public canBeEditable: boolean = false;
}
