var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { map as __map, filter as __filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../api-configuration";
import * as i2 from "@angular/common/http";
var FilesService = /** @class */ (function (_super) {
    __extends(FilesService, _super);
    function FilesService(config, http) {
        return _super.call(this, config, http) || this;
    }
    /**
     * @return Success
     */
    FilesService.prototype.ApiFilesGetResponse = function () {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('GET', this.rootUrl + "/api/files", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @return Success
     */
    FilesService.prototype.ApiFilesGet = function () {
        return this.ApiFilesGetResponse().pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param file undefined
     */
    FilesService.prototype.ApiFilesPostResponse = function (file) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = file;
        var req = new HttpRequest('POST', this.rootUrl + "/api/files", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param file undefined
     */
    FilesService.prototype.ApiFilesPost = function (file) {
        return this.ApiFilesPostResponse(file).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param id undefined
     * @return Success
     */
    FilesService.prototype.ApiFilesByIdGetResponse = function (id) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('GET', this.rootUrl + ("/api/files/" + (id)), __body, {
            headers: __headers,
            params: __params,
            responseType: 'text'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param id undefined
     * @return Success
     */
    FilesService.prototype.ApiFilesByIdGet = function (id) {
        return this.ApiFilesByIdGetResponse(id).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `FilesService.ApiFilesByIdPutParams` containing the following parameters:
     *
     * - `id`:
     *
     * - `value`:
     */
    FilesService.prototype.ApiFilesByIdPutResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.value;
        var req = new HttpRequest('PUT', this.rootUrl + ("/api/files/" + (params.id)), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `FilesService.ApiFilesByIdPutParams` containing the following parameters:
     *
     * - `id`:
     *
     * - `value`:
     */
    FilesService.prototype.ApiFilesByIdPut = function (params) {
        return this.ApiFilesByIdPutResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param id undefined
     */
    FilesService.prototype.ApiFilesByIdDeleteResponse = function (id) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('DELETE', this.rootUrl + ("/api/files/" + (id)), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param id undefined
     */
    FilesService.prototype.ApiFilesByIdDelete = function (id) {
        return this.ApiFilesByIdDeleteResponse(id).pipe(__map(function (_r) { return _r.body; }));
    };
    FilesService.ApiFilesGetPath = '/api/files';
    FilesService.ApiFilesPostPath = '/api/files';
    FilesService.ApiFilesByIdGetPath = '/api/files/{id}';
    FilesService.ApiFilesByIdPutPath = '/api/files/{id}';
    FilesService.ApiFilesByIdDeletePath = '/api/files/{id}';
    FilesService.ngInjectableDef = i0.defineInjectable({ factory: function FilesService_Factory() { return new FilesService(i0.inject(i1.ApiConfiguration), i0.inject(i2.HttpClient)); }, token: FilesService, providedIn: "root" });
    return FilesService;
}(__BaseService));
export { FilesService };
