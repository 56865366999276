<h2>Кабинеты</h2>
<div class="personal-cabinets">
  <!--<a class="btn"  [href]="baseLink + Cabinet.link" target="_blank">-->
    <!--{{Cabinet.name}}-->
  <!--</a>-->
  <app-personal-cabinet
          *ngFor="let Cabinet of Cabinets"
          [cabinet]="Cabinet"
          [Link]="baseLink + Cabinet.link"
          [IsLoggedIn]="IsLoggedIn(Cabinet.id)"
  ></app-personal-cabinet>
</div>