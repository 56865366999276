import {Component, EventEmitter, Input, OnInit, OnChanges, Output} from '@angular/core';
import {EasyAdContainerModel} from '../../models/easy-ad-container-model';
import {BidRange} from '../../../api/models/bid-range';

@Component({
  selector: 'app-easy-ad-card',
  templateUrl: './easy-ad-card.component.html',
  styleUrls: ['./easy-ad-card.component.scss']
})
export class EasyAdCardComponent implements OnInit, OnChanges {
  @Input() public forcedLoadingAbort: boolean = false;
  @Input() public data: EasyAdContainerModel = null;
  @Input() public bidLimit: BidRange = null;

  @Output() public OnAdStatusChange: EventEmitter<EasyAdContainerModel> = new EventEmitter<EasyAdContainerModel>();
  @Output() public OnAdSendToModeration: EventEmitter<EasyAdContainerModel> = new EventEmitter<EasyAdContainerModel>();
  @Output() public OnAdGetRejectionReason: EventEmitter<EasyAdContainerModel> = new EventEmitter<EasyAdContainerModel>();
  @Output() public OnAdNameChange: EventEmitter<EasyAdContainerModel> = new EventEmitter<EasyAdContainerModel>();

  @Output() public OnCpmChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() public OnEcpcLimitChange: EventEmitter<number> = new EventEmitter<number>();

  public forceAwaitLabel: boolean = false;

  public statusAnnotationInPreviewMode: boolean = true;
  public statusAnnotation: string = null;

  public statusState: number = 0; // 0 - нейтральный, 1- позитивный, -1 - негативный

  public actionInProgress: boolean = false;


  public inputParamterShowEditBar: boolean = false;
  public inputParameter: number = null;
  public inputParameterProcessingRequest: boolean = false;

  public editableAdName: string = null;
  public editAdNameActive: boolean = false;

  constructor() {
  }

  ngOnInit() {
    this.InterpretAdStatus();
  }

  ngOnChanges() {
    this.InterpretAdStatus();
  }

  public SetAnnotationMode(previewMode: boolean): void {
    this.statusAnnotationInPreviewMode = previewMode;
  }

  public IsCPMWithinLimits(value: number): boolean {
    if (value === null || value === undefined || !this.bidLimit) {
      return false;
    }

    return value >= this.bidLimit.cpmMin / 100 && value <= this.bidLimit.cpmMax / 100;
  }

  public IsAdPromo(): boolean {
    return this.data.ad.ad_format === 9;
  }

  public InterpretAdStatus(): string {
    // Проверка есть ли у нас минимально-необходимые данные для выполнения функции
    if (!this.data || !this.data.ad || !this.data.ad.status === null || !this.data.ad.status === undefined) {
      this.statusAnnotation = 'Попробуйте перезагрузить страницу или обратитесь в поддержку PostMonitor.';
      this.statusState = 0;
      return 'Возникла неизвестная ошибка.'; // Мы не получили необходимые данные - выводим загулшку, чтобы не было ошибки
    }

    // Если объявление запущено
    if (this.data.ad.status === 1) {
      this.statusState = 1;
      if (this.data.adLayout && this.data.adLayout.preview_link) {
        this.statusAnnotation = `<a href="${this.data.adLayout.preview_link}" target="_blank" rel="noopener nofollow" title="Открыть страницу ВКонтакте  в новой вкладке для просмотра объявления">Посмотреть пример объявления ВКонтакте</a>`;
      } else {
        this.statusAnnotation = null;
      }
      return 'Объявление показывается пользователям ВКонтакте';
    } else if (this.data.ad.status === 0) {
      this.statusState = -1;
      // Если объявление не запущено и есть информация о статусе модерации
      if (this.data.ad.approved !== null && this.data.ad.approved !== undefined) {
        switch (this.data.ad.approved.toString()) {
          case '0':
            this.statusState = 2;
            // TODO Заполнить аннотацию для статуса
            this.statusAnnotation = 'Для того чтобы запустить объявление,<full> сначала необходимо отправить его на модерацию в ВКонтакте. Чтобы отправить объявление на модерацию нажмите кнопку “Отправить на модерацию”. <br>Примечание: Все объявления проходят обязательную проверку (модерацию) сотрудниками ВКонтакте. Если объявление не соответствует правилам ВКонтакте, то его могут отклонить и оно не может быть запущено. Если объявление соответствует правилам ВКонтакте, то оно может быть запущено.';
            return 'Объявление создано'; // Объявление не проходило модерацию
          case '1':
            this.statusState = 2;
            // TODO Заполнить аннотацию для статуса
            this.statusAnnotation = 'Это может занять от 5 минут до<full> нескольких часов. Если объявление пройдет модерацию, то статус смениться на “Готово к запуску”. Если не пройдет модерацию, то необходимо будет внести изменения в объявление и отправить повторно на модерацию.';
            return 'Объявление проходит модерацию'; // Объявление отправлено на модерацию, ожидает модерации
          case '2':
            this.statusState = 0;
            // TODO Заполнить аннотацию для статуса
            this.statusAnnotation = 'Для запуска нажмите кнопку “Запустить”.';
            return 'Объявление не запущено'; // Объявление прошло модерацию. Остановлено
          case '3':
            // TODO Заполнить аннотацию для статуса
            this.statusAnnotation = 'Посмотрите причины отклонения объявления<full>, внесите изменения и отправьте повторно на модерацию.';
            return 'Объявление не прошло модерацию ВКонтакте'; // TODO Добавить кнопку с показом модалки
        }
      }

      // Статус заглушка, если всё провалится
      return 'Не запущено';
    }
  }

  public IsAnnotationHasFullMode(): boolean {
    if (!this.statusAnnotation || this.statusAnnotation.length === 0) {
      return false;
    }

    return this.statusAnnotation.indexOf('<full>') >= 0;
  }

  public GetAnnotation(inPreviewFormat: boolean = false): string {
    if (!this.statusAnnotation || this.statusAnnotation.length === 0) {
      return '';
    }

    return (inPreviewFormat) ? this.statusAnnotation.split('<full>')[0] : this.statusAnnotation.replace('<full>', '');
  }

  public GetStrategyECPCLimit(): number {
    const input = this.GetStrategyECPCLimitInput();

    if (input) {
      return input.value;
    }

    return null;
  }

  private GetStrategyECPCLimitInput() {
    if (this.data && this.data.userStrategy && this.data.userStrategy.inputs) {
      return this.data.userStrategy.inputs.find(x => x.type && x.type.code === 'ECPC_LIMIT');
    }

    return null;
  }

  public EasyAdInputParameterChange() {
    this.inputParameterProcessingRequest = true;

    const strategyInput = this.GetStrategyECPCLimitInput();

    if (strategyInput) {
      // TODO Change strategy input
      this.OnEcpcLimitChange.emit(this.inputParameter * 100);
    } else {
      this.OnCpmChange.emit(this.inputParameter * 100);
      // TODO Change cpm
    }

    this.inputParameter = null;
    this.inputParameterProcessingRequest = false;
    this.inputParamterShowEditBar = false;
  }

  public SetInputParameterShow(state: boolean): void {
    this.inputParamterShowEditBar = state;
  }

  public ChangeAdStatus(): void {
    this.actionInProgress = true;
    this.OnAdStatusChange.emit(this.data);
  }

  public SendToModeration(): void {
    this.actionInProgress = true;
    this.OnAdSendToModeration.emit(this.data);
  }

  public GetRejectionReason(): void {
    this.OnAdGetRejectionReason.emit(this.data);
  }

  public ChangeAdName(): void {
    console.log('ChangeAdName');
    console.log(`Name is valid: ${this.IsAdNameValid(this.editableAdName)}`);
    console.log(`New name doesn't match previous: ${this.editableAdName !== this.data.ad.name}`);
    if (this.IsAdNameValid(this.editableAdName) && this.editableAdName !== this.data.ad.name) {
      console.log('Change will be emitted');
      this.data.ad.name = this.editableAdName;
      this.OnAdNameChange.emit(this.data);
      this.editAdNameActive = false;
    }
  }

  public IsAdNameValid(text: string): boolean {
    return text && text.length > 0 && text.length < 60;
  }

  public SwitchToAdNameEditMode(): void {
    this.editableAdName = this.data.ad.name;
    this.editAdNameActive = true;
  }

  public GetAdIdentifier(): string {
    if (this.data) {
      return `${this.data.accountId}_${(this.data.clientId !== null) ? this.data.clientId : 0}_${this.data.adId}`;
    }

    return null;
  }

  public GetTipAutomatic(): string {
    return `Объявления будет работать в  автоматическом режиме. Необходимо просто запустить объявление и отслеживать результаты.

Справка: ВКонтакте устанавливает цену за 1000 показов объявления пользователям. ${(this.bidLimit) ? 'Для данного вида объявления цена варьируется в пределах ' + (this.bidLimit.cpmMin / 100) + ' - ' + (this.bidLimit.cpmMax / 100) + ' руб.' : ''}
Цену за 1000 показов объявления можно устанавливать вручную. От выбора цены за 1000 показов зависит как часто ваше объявление будет показываться пользователям ВКонтакте и за какую цену. Чем меньше цена за 1000 показов тем реже объявление будет показываться пользователям, но и дешевле. Соответственно, чем больше цена за 1000 показов, тем чаще объявление будет показываться пользователям, но и дороже.
Автоматический способ установки цены за показы дает возможность увеличивать эффективность рекламы и экономить бюджет. Это достигается с помощью умных алгоритмов которые позволяют устанавливать необходимую цену за 1000 показов.
`;
  }
}
