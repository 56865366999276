<div class="images-manager">
  <div class="vk-container">
    <div class="vk-label">
      Выберите одно или несколько изображений для объявления:
    </div>
  </div>
  <div class="images-manager__list">
    <ng-container *ngFor="let image of files">
      <app-ad-image
        [minHeight]="minHeight"
        [minWidth]="minWidth"
        [image]="image"
        [maxImageSize]="maxImageSize"
        [selected]="selectedImages"
        (selectedChange)="OnSelectImage($event)"
        (removeImage)="RemoveImage($event)"
      ></app-ad-image>
    </ng-container>
    <label>
      <input #file type="file" (change)="FilesSelected(file.files)" multiple accept="image/jpeg,image/png,image/bmp,image/tif,image/gif" class="hidden">
      <span class="image-loader-button" matRipple>
          <span class="image-loader-button__image">
            <mat-icon color="primary">add_a_photo</mat-icon>
          </span>
          <!--<span class="image-loader-button__text">-->
            <!--Выберите изображения-->
          <!--</span>-->
        </span>
    </label>
  </div>
</div>
