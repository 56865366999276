import { OnInit } from '@angular/core';
import { CopyingService } from "../../../services/copying.service";
var CopyingByLinksComponent = /** @class */ (function () {
    function CopyingByLinksComponent(copyingService) {
        this.copyingService = copyingService;
    }
    Object.defineProperty(CopyingByLinksComponent.prototype, "form", {
        get: function () {
            return this.copyingService.form.get('linkSettings');
        },
        enumerable: true,
        configurable: true
    });
    CopyingByLinksComponent.prototype.ngOnInit = function () {
        console.log(this.form, 'LINK FORMS');
        console.log(this.copyingService.form, 'LINK FORMS');
        this.form.valueChanges.subscribe(function (data) {
            console.log(data);
        });
    };
    return CopyingByLinksComponent;
}());
export { CopyingByLinksComponent };
