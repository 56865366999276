import {ChangeDetectorRef, Injectable} from '@angular/core';
import {SortingInstance} from '../models/SortingInstance';
import {Subject} from 'rxjs/Subject';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs/Subscription';
import {ErrorDescription} from '../../api/models/error-description';
import {Chip} from '../models/view-models/chip';
import * as moment from 'moment';
import {Group} from '../../api/models/group';
import {VkApiGroup} from '../../vk/models/api/vk-api-group';
import {VkApiService} from '../../vk/services/vk-api.service';
import {VkApiConvertorService} from '../../vk/services/vk-api-convertor.service';
import {MathService} from './math.service';
import {Post} from '../../api/models/post';
import {VkAdDetectorService} from '../../vk/services/vk-ad-detector.service';
import {VkApiPost} from '../../vk/models/api/vk-api-post';
import {VkStealthParserHelperService} from '../../vk/services/vk-stealth-parser-helper.service';
import {VkAdvancedSearchReportWrapperService} from '../../vk/services/vk-advanced-search-report-wrapper.service';
import {isNullOrUndefined} from 'util';
import {AdvancedTextSearchService} from './advanced-text-search.service';

@Injectable()
export class VkRequestService {
  public page = 1;
  public sorting: SortingInstance;
  public filter: any = {};
  public data = [];
  public Search = new Subject<any>();
  public recordsFound = null;
  public loading = false;
  public defaultSortingField = '';
  public subscribed: Subscription = null;
  public isExpired: boolean = false;
  public ExpirationDate = null;
  public error: ErrorDescription = null;
  public excluded: Array<Chip> = [];
  public maxCountOfChips = 3;
  public IsAllChipsShowed = false;
  public IsChanged = false;
  public ConfirmedExcludedGroups = [];
  public countOfResults = 10;
  public countOfPages = 1;
  public countPerPageItems = [
    10, 20, 30, 40, 50
  ];
  public countOfMinutesToExpiration = null;
  public expirationString = null;

  /**
   *
   * @type {null}
   */
  public DataObserver: any = null;

  public get ExcludedToShow() {
    if (this.IsAllChipsShowed) {
      return this.excluded;
    } else {
      return this.excluded.filter((x, i) => i < this.maxCountOfChips);
    }
  }

  get Request() {
    return Object.assign(this.filter, this.sorting, {page: this.page});
  }

  public mode: string = 'groups';
  public parsedDataCached = [];
  public parsedDataCahceSortedBy = '';
  public parsedDataCahceSortedDescend = true;
  public apiParsedGroupAddress: string = null;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private VkApi: VkApiService,
              private VkApiConvert: VkApiConvertorService,
              private MathService: MathService,
              private AdDetector: VkAdDetectorService,
              private StealthParserHelper: VkStealthParserHelperService,
              private ReportService: VkAdvancedSearchReportWrapperService,
              private advancedSearchAPI: AdvancedTextSearchService,
              // private cd: ChangeDetectorRef
  ) {

  }

  Init() {
    this.sorting = new SortingInstance(
      this.route.snapshot.queryParams.sortBy || '',
      this.route.snapshot.queryParams.sortDescend === 'true'
    );
    if (this.route.snapshot.queryParams.page) {
      this.page = parseInt(this.route.snapshot.queryParams.page, 10);
    }
    this.Search.subscribe(data => {
      this.LoadData();
    });
  }

  public OfferProviderLogin(): boolean {
    return isNullOrUndefined(this.VkApi.token);
  }

  public SetDefaultSortingField(field: string) {
    this.defaultSortingField = field;
  }

  public AddObserver(observer: any) {
    this.DataObserver = observer;
  }

  public LoadData() {
    this.error = null;
    this.loading = true;
    // this.cd.detectChanges()
    this.recordsFound = null;
    if (this.subscribed !== null) {
      this.subscribed.unsubscribe();
    }
    this.Navigate();
    this.filter.excludedGroups = this.ConfirmedExcludedGroups;
    this.filter.perPage = this.countOfResults;

    if (this.apiParsedGroupAddress && this.filter.groupAddress && this.filter.groupAddress === this.apiParsedGroupAddress) {

      // Check if we already for group
      // TODO Optimize this condition - If group not contains stealth ads (for real 0 ads) - each fitler change will cause RE parsing
      if (!this.parsedDataCached || this.parsedDataCached.length === 0) {
        this.LoadGroupsByAPI();
      } else {
        // Else if we have data just retrieve portion of this data
        this.AddDataToView();
      }
    } else {
      this.apiParsedGroupAddress = null;
      this.parsedDataCached = [];
      this.subscribed = this.DataObserver.findByFilter(this.Request)
        .subscribe(data => {
          this.data = data.data;
          this.recordsFound = data.records_found;
          this.loading = false;
          this.isExpired = data.userMeta.isExpired;
          this.ExpirationDate = new Date(data.userMeta.expirationDate);
          this.GetTimerToExpirationFromNow();
        }, err => {
          this.loading = false;
          this.error = err.error;
          this.data = [];
          this.recordsFound = 0;

          if (err.status === 404) {
            this.apiParsedGroupAddress = this.filter.groupAddress;
            this.LoadGroupsByAPI();
          }
        });
    }
  }

  public SetSorting(field) {
    if (this.sorting.sortBy === field) {
      this.sorting.sortDescend = !this.sorting.sortDescend;
    } else {
      this.sorting.sortBy = field;
      this.sorting.sortDescend = true;
    }

    if (this.parsedDataCached && this.parsedDataCached.length > 0) {
      this.SetSortingForParsedData(this.parsedDataCached);
      this.AddDataToView();
    }

    this.Search.next();
  }

  public SetDefaultSorting() {
    this.sorting.sortBy = this.defaultSortingField;
    this.sorting.sortDescend = true;
  }

  public SetFilter(filter) {
    this.filter = filter;
    this.ResetPagination();
    this.Search.next();
  }

  public SetPage(page) {
    this.page = page;
    this.Search.next();
  }

  public Navigate() {
    const a = this.GetNotNullKeys();
    this.router.navigate([this.router.url.split('?')[0]], {
      queryParams: a
    });
  }

  public ResetPagination() {
    this.page = 1;
  }

  // public GetNotNullKeys(): object {
  //     const result = Object.create({});
  //     Object
  //         .keys(this.Request)
  //         .filter(k => this.Request[k] !== null)
  //         .filter(k => {
  //             // 
  //             return (k === 'postType') ? !isNaN(this.Request[k]) : true;
  //         })// Filter of all null keys
  //         .filter(k => !k.startsWith('_') && k !== 'closed' && k !== 'isStopped' && k !== 'hasError' && k !== 'observers')
  //         .forEach(key => {
  //             if (!isNaN(parseInt(this.Request[key], 10))) {
  //                 result[key] = this.Request[key];
  //             } else {
  //                 result[key] = this.Request[key];
  //             }
  //         });
  //     
  //     return result;
  // }

  public Reset() {
    this.SetPage(1);
    this.SetDefaultSorting();
  }

  public RemoveFromExcludes(groupId: number) {
    this.IsChanged = true;
    this.excluded = this.excluded.filter(x => x.id !== groupId);
    if (this.excluded.length === 0) {
      this.ApplyExcluded();
    }
  }

  // public SetFilter(filter) {
  //     
  //     this.filter = filter;
  //     this.ResetPagination();
  //     this.Search.next();
  // }

  // 242 812
  public RemoveAllFromExcludes() {
    this.IsChanged = true;
    this.excluded = [];
    this.ApplyExcluded();
  }

  public AddToExcludes(data: Chip) {
    this.IsChanged = true;
    this.excluded.push(data);
  }

  public IsExcluded(data: Chip) {
    return this.excluded.filter(x => x.id === data.id).length > 0;
  }

  public ApplyExcluded() {
    this.ConfirmedExcludedGroups = this.excluded.map(x => x.id);
    this.IsChanged = false;
    this.SetPage(1);
    if (this.ConfirmedExcludedGroups.length === 0 && this.filter.excludedGroups.length === 0) {
      return;
    }
    this.Search.next(this.filter);
  }

  public GetNotNullKeys(): object {
    const result = Object.create({});

    Object
      .keys(this.Request)
      .filter(k => this.Request[k] !== null)
      .filter(k => {
        return (k === 'postType') ? !isNaN(this.Request[k]) : true;
      })// Filter of all null keys
      .filter(k => !k.startsWith('_') && k !== 'closed' && k !== 'isStopped' && k !== 'hasError' && k !== 'observers')
      .forEach(key => {
        result[key] = this.Request[key];
      });

    return result;
  }

  public get CountOfHiddenExcluded() {
    return this.excluded.length - this.maxCountOfChips;
  }

  public ShowAllExcluded() {
    this.IsAllChipsShowed = true;
  }

  public HideAllExcluded() {
    this.IsAllChipsShowed = false;
  }

  public SetCountOfResults(data) {
    this.countOfResults = data;
    this.SetPage(1);
    this.Search.next();
  }

  public SetDefaultPerPage() {
    this.countOfResults = 10;
  }

  public SetCountOfMinutesToExpiration(countOfMinutesToExpiration) {
    this.countOfMinutesToExpiration = countOfMinutesToExpiration;
  }

  public GetTimerToExpirationFromNow() {
    const minutesToExpiration = moment(this.ExpirationDate).diff(new Date(), 'minutes');
    if (minutesToExpiration > 0 && this.countOfMinutesToExpiration > minutesToExpiration) {
      this.expirationString = moment().to(this.ExpirationDate);
    }
    return null;
  }

  private LoadGroupsByAPI(): void {
    if (this.filter.groupAddress && this.filter.groupAddress.length >= 2 && this.VkApi.token) {
      this.loading = true;
      this.data = [];
      this.recordsFound = 0;

      const split = this.filter.groupAddress.split('/');
      const screen_name = split[split.length - 1];

      this.VkApi.UtilsResolveScreenName(screen_name).subscribe(x => {
        if (x) {
          const group_id = x.object_id;
          const timeout = this.VkApi.timeout;

          switch (this.mode) {
            case 'groups':
              setTimeout(() => {
                this.GetGroupData(group_id);
              }, timeout);
              break;
            case 'posts':
              setTimeout(() => {
                this.GetPostsData(group_id);
              }, timeout);
              break;
            case 'stealth':
              setTimeout(() => {
                this.GetStealthPostData(group_id);
              }, timeout);
              break;
            default:
              this.loading = false;
              break;
          }
        } else {
          this.loading = false;
        }
      }, error => {
        this.loading = false;
      });
    }
  }

  private GetStealthPostData(group_id: number): void {
    const posts: Array<Post> = [];

    this.parsedDataCached = [];

    this.VkApi.ExecuteGetGroupWall(group_id)
      .subscribe(async response => {
        if (response) {

          let group = null;

          if (response.group) {
            group = this.VkApiConvert.ConvertGroup(response.group);
          }

          if (response.wall) {
            // Lookahead scaning
            if (response.wall.length > 0) {
              if (response.wall[0] && response.wall[0].length > 0) {
                const latestPost = response.wall[0][0];

                if (latestPost) {
                  const ads = await this.StealthParserHelper.LookaheadScan(group_id, latestPost.id);

                  if (ads && ads.length > 0) {
                    ads.forEach(post => {
                      const converted = this.VkApiConvert.ConvertPost(group, post);

                      if (converted) {
                        posts.push(converted);
                      }
                    });
                  }
                }
              }
            }

            // Lookbehind scaning
            const flatenedPosts: Array<VkApiPost> = [].concat.apply([], response.wall);

            if (flatenedPosts && flatenedPosts.length > 0) {
              const postIdList = flatenedPosts.map(post => post.id);

              let gaps = this.StealthParserHelper.DetectGapsInWall(postIdList);

              // Exclude already visited posts from
              if (this.StealthParserHelper.visitedPostsIdList && this.StealthParserHelper.visitedPostsIdList.length > 0) {
                gaps = gaps.filter((element) => !this.StealthParserHelper.visitedPostsIdList.includes(element));
              }

              if (gaps && gaps.length > 0) {
                const ads = await this.StealthParserHelper.RetrieveStealthAds(gaps, group_id);

                // Convert VkApiPost to Pm posts
                if (ads && ads.length > 0) {
                  ads.forEach(post => {
                    const converted = this.VkApiConvert.ConvertPost(group, post);

                    if (converted) {
                      posts.push(converted);
                    }
                  });
                }
              }
            }

            this.ReportService.ReportPosts(posts);

            this.SetSortingForParsedData(posts);

            this.parsedDataCached = posts;

            this.AddDataToView();
          }
        }
      });
  }

  private SetSortingForParsedData(data): void {
    const sortBy = this.sorting.sortBy;
    const sortDesc = this.sorting.sortDescend;

    switch (this.mode) {
      case 'groups':
        this.SortGroups(data, sortBy, sortDesc);
        break;
      case 'posts':
        this.SortPosts(data, sortBy, sortDesc);
        break;
      case 'stealth':
        this.SortPosts(data, sortBy, sortDesc);
        break;
    }

    this.parsedDataCahceSortedBy = sortBy;
    this.parsedDataCahceSortedDescend = sortDesc;
  }

  private SortGroups(posts: Array<Group>, sortBy: string, sortDesc: boolean): void {

  }

  private SortPosts(data: Array<Post>, sortBy: string, sortDesc: boolean): void {

    switch (sortBy) {
      case 'date':
        data.sort(function (a, b) {
          return (sortDesc) ? (parseInt(moment(b.date).format('x'), 10) - parseInt(moment(a.date).format('x'), 10)) :
            (parseInt(moment(a.date).format('x'), 10) - parseInt(moment(b.date).format('x'), 10));
        });
        break;
      case 'likes':
        data.sort(function (a, b) {
          return (sortDesc) ? (b.likes - a.likes) : (a.likes - b.likes);
        });
        break;
      case 'comments':
        data.sort(function (a, b) {
          return (sortDesc) ? (b.comments - a.comments) : (a.comments - b.comments);
        });
        break;
      case 'reposts':
        data.sort(function (a, b) {
          return (sortDesc) ? (b.reposts - a.reposts) : (a.reposts - b.reposts);
        });
        break;
      case 'views':
        data.sort(function (a, b) {
          return (sortDesc) ? (b.views - a.views) : (a.views - b.views);
        });
        break;
    }
  }

  private GetPostsData(group_id: number): void {
    const posts: Array<Post> = [];

    this.VkApi.ExecuteGetGroupWall(group_id)
      .subscribe(data => {
        if (data) {
          let group = this.GetEmptyGroupInstace(group_id);

          group = this.SetEmptyGroupValuesFromApiGroup(group, data.group);

          if (data.wall && data.wall.length > 0) {
            data.wall.forEach(wall => {

              wall.forEach(post => {
                const isAdv = this.AdDetector.IsPostAnAd(post);

                if (isAdv) {
                  const convertedPost = this.VkApiConvert
                    .ConvertPost(group, post);

                  posts.push(convertedPost);
                }
              });
            });

            // Collect all reposted groups
            if (posts && posts.length > 0) {
              const postWithReposts = posts.filter(x => x.copy_history && x.copy_history.length > 0);

              if (postWithReposts && postWithReposts.length > 0) {
                const repostedGroupIdList: Array<number> = [];

                postWithReposts.forEach(post => {
                  if (post.copy_history) {
                    post.copy_history.forEach(repost => {
                      if (repostedGroupIdList.indexOf(repost.group_id) === -1) {
                        repostedGroupIdList.push(repost.group_id);
                      }
                    });
                  }
                });

                // Retrieve data for all repost groups
                if (repostedGroupIdList.length > 0) {
                  setTimeout(() => {
                    this.FillRepostsWithGroupData(posts, repostedGroupIdList);
                  }, this.VkApi.timeout);
                }
              }
            }
          }

          this.SetSortingForParsedData(posts);

          this.parsedDataCached = posts;

          this.AddDataToView();
        }
      });
  }

  private FillRepostsWithGroupData(posts: Array<Post>, repostedGroupIdList: Array<number>): void {
    this.VkApi.GroupsGetById(repostedGroupIdList, null, 'members_count').subscribe(groups => {
      if (groups && groups.length > 0) {
        const convertedGroups: Array<Group> = [];

        groups.forEach(group => {
          const convertedGroup = this.VkApiConvert.ConvertGroup(group);

          if (convertedGroup) {
            convertedGroups.push(convertedGroup);
          }
        });

        if (convertedGroups.length > 0) {
          const reposts = posts.filter(x => x.copy_history && x.copy_history.length > 0).map(x => x.copy_history);

          if (reposts && reposts.length > 0) {
            reposts.forEach(repost => {

              repost.forEach(entry => {
                if (entry) {
                  const repostGroup = convertedGroups.find(group => group.id === entry.group_id);

                  if (repostGroup && entry && entry.child_post) {
                    entry.child_post.group = repostGroup;
                  }
                }
              });
            });
          }
        }
      }
    });
  }

  private GetGroupData(group_id: number): void {
    let group = this.GetEmptyGroupInstace(group_id);

    const currentDay = moment(new Date());
    const previousDay = moment(new Date()).subtract(7, 'days');

    // Request data from API
    this.VkApi.ExecuteGetGroupById(group_id,
      currentDay.format(this.VkApi.date_format),
      previousDay.format(this.VkApi.date_format))
      .subscribe(data => {

        if (data) {

          // If main group's data provided
          group = this.SetEmptyGroupValuesFromApiGroup(group, data.group);

          // If statistics provided
          if (data.statistics) {
            const source = data.statistics;

            const convertedStats = this.VkApiConvert.ConvertStatistics(group_id, source);

            const male = convertedStats.sex_statistics.find(x => x.id === 'male');
            const female = convertedStats.sex_statistics.find(x => x.id === 'female');

            group.statistics = convertedStats;
            group.sex = {
              id: group_id,
              male: male.total,
              female: female.total
            };

            group.geo = this.VkApiConvert.ConvertGeo(group_id, source);
          }

          if (data.wall) {

            const commentsIsOpened = false;
            group.engagement.commentsOpened = false;

            if (data.wall.items && data.wall.items.length > 0) {

              if (data.wall.items.find(x => x.comments.count > 0)) {
                group.engagement.commentsOpened = true;
              }
            }

            const source = data.wall;

            const posts = source.items;

            const maxTimeStamp = moment().startOf('day').unix();
            let minTimeStamp = moment().subtract(1, 'days').startOf('day').unix();

            const previousDayPosts = posts.filter(post => post.date >= minTimeStamp && post.date <= maxTimeStamp);

            if (previousDayPosts && previousDayPosts.length > 0) {

              // Get the posts with views data
              const postsWithViews = previousDayPosts.filter(x => x.views);


              if (postsWithViews && postsWithViews.length > 0) {
                // Aggregate all viewsfrom these posts
                const views = postsWithViews.map(x => x.views.count);

                // Get average views for one (previous, this is important) day - this is post reach
                const medianViewsForOneDay = this.MathService.GetMedianValue(views);

                // Assign value
                group.statistics.reach_post = medianViewsForOneDay;
              }


              // Calculate post per day
              const maxDaysToCapture = Math.floor(100 / previousDayPosts.length);
              const dayStep = 60 * 60 * 24;
              // Redefine minTimeStamp
              minTimeStamp = moment().add(-maxDaysToCapture, 'days').startOf('day').unix();

              const postsToCalculate = posts.filter(post => post.date >= minTimeStamp);
              const postsPerDay = [];

              for (let i = maxTimeStamp; i >= minTimeStamp; i -= dayStep) {
                postsPerDay.push({
                  day: i,
                  count: 0,
                  likes: [],
                  comments: [],
                  reposts: [],
                  averageLikes: 0,
                  averageComments: 0,
                  averageReposts: 0
                });
              }

              const commentsOpened = null;

              postsToCalculate.forEach(post => {
                const date = postsPerDay.find(x => post.date >= x.day);

                if (date) {
                  date.count = date.count + 1;
                  date.likes.push(post.likes.count);
                  date.comments.push(post.comments.count);
                  date.reposts.push(post.reposts.count);

                  // 
                  // if (commentsOpened === null && post.comments) {
                  //
                  //   commentsOpened = post.comments.can_post === 1;
                  // }
                }
              });

              // Count average values
              postsPerDay.forEach(x => {
                x.averageLikes = this.MathService.GetAverageValue(x.likes);
                x.averageComments = this.MathService.GetAverageValue(x.comments);
                x.averageReposts = this.MathService.GetAverageValue(x.reposts);
              });

              const postPerDay = postsPerDay.map(x => x.count);
              const likes = postsPerDay.map(x => x.averageLikes);
              const comments = postsPerDay.map(x => x.averageComments);
              const reposts = postsPerDay.map(x => x.averageReposts);

              const averagePostPerDay = this.MathService.GetAverageValue(postPerDay);
              const averageLikes = this.MathService.GetAverageValue(likes);
              const averageComments = this.MathService.GetAverageValue(comments);
              const averageReposts = this.MathService.GetAverageValue(reposts);

              group.engagement.posts_per_day = averagePostPerDay;
              group.engagement.likes = averageLikes;
              group.engagement.comments = averageComments;
              group.engagement.reposts = averageReposts;

              // if (commentsOpened != null) {
              //   group.engagement.commentsOpened = commentsIsOpened;
              // }

            }
          }

          // This is meaningless to sort 1 group. But if later there will be serach by term via API - uncomment this code
          //this.SetSortingForParsedData([group]);

          this.ReportService.ReportGroups([group]);

          this.parsedDataCached = [group];

          this.AddDataToView();
        }
      }, error => {
        this.loading = false;
      });
  }

  private GetEmptyGroupInstace(group_id: number): Group {
    const group: Group = {
      id: group_id,
      members_history: [],
      links: [],
      contacts: [],
      engagement: {
        id: group_id,
        likes: 0,
        reposts: 0,
        comments: 0,
        posts_per_day: 0,
        er: 0,
        commentsOpened: null
      },
      statistics: {
        group_id: group_id
      },
      geo: {
        id: group_id
      },
      sex: {id: group_id},
      meta_data: null,
      pricing: null,
      inactive: null
    };

    return group;
  }

  private SetEmptyGroupValuesFromApiGroup(group: Group, source: VkApiGroup): Group {
    if (source) {
      group.name = source.name;
      group.screen_name = source.screen_name;
      group.photo_50 = source.photo_50;
      group.photo_100 = source.photo_100;
      group.photo_200 = source.photo_200;
      group.members_count = source.members_count;
    }

    return group;
  }

  private FilterResults(): Array<any> {
    switch (this.mode) {
      case 'groups':
        return this.parsedDataCached;
      case 'posts':
        return this.FilterPosts(this.parsedDataCached, this.filter);
      case 'stealth':
        return this.FilterPosts(this.parsedDataCached, this.filter);
      default:
        return null;
    }
  }

  private FilterPosts(data: Array<Post>, request: any): Array<Post> {
    if (!this.parsedDataCached || this.parsedDataCached.length === 0) {
      return;
    }

    const searchEngine = this.advancedSearchAPI;

    return this.parsedDataCached.filter(function (post) {
      if (!post) {
        return false;
      }

      if (request && request.text && request.text.length > 0 && !searchEngine.TextContainsTerm(post.text, request.text)) {
        return false;
      }

      // Likes
      if (request.likesFrom && post.likes < request.likesFrom) {
        return false;
      }

      if (request.likesTo && post.likes > request.likesTo) {
        return false;
      }

      // Reposts
      if (request.repostsFrom && post.reposts < request.repostsFrom) {
        return false;
      }

      if (request.repostsTo && post.reposts > request.repostsTo) {
        return false;
      }

      // Comments
      if (request.commentsFrom && post.comments < request.commentsFrom) {
        return false;
      }

      if (request.commentsTo && post.comments > request.commentsTo) {
        return false;
      }

      // Views
      if (request.viewsFrom && post.views < request.viewsFrom) {
        return false;
      }

      if (request.viewsTo && post.views > request.viewsTo) {
        return false;
      }

      // Date
      if (request.dateFrom || request.dateTo) {
        const convertedDate = parseInt(moment(post.date).format('x'), 10);

        if (request.dateFrom) {
          const convertedDateFrom = parseInt(moment(request.dateFrom).format('x'), 10);

          if (convertedDate < convertedDateFrom) {
            return false;
          }
        }

        if (request.dateTo) {
          const convertedDateTo = parseInt(moment(request.dateTo).add(60 * 23 + 59, 'minutes').format('x'), 10);

          if (convertedDate > convertedDateTo) {
            return false;
          }
        }
      }

      if (request.containVideo) {
        if (!post.videos || post.videos.lenght === 0) {
          return false;
        }
      }

      if (request.markedAsAds) {
        if (!post.marked_as_ads) {
          return false;
        }
      }

      if (request.postType && post.post_type !== request.postType) {
        return false;
      }

      return true;
    });
  }

  private AddDataToView(): void {
    const filteredResults = this.FilterResults();

    const paginationStart = (this.page - 1) * this.countOfResults;

    if (filteredResults) {

      const maxAvailableItems = Math.min(filteredResults.length - paginationStart, this.countOfResults);
      const viewResult = filteredResults.slice(paginationStart).slice(0, maxAvailableItems);

      this.data = viewResult;
      this.recordsFound = filteredResults.length;
    } else {
      this.data = [];
      this.recordsFound = 0;
    }

    this.loading = false;

  }
}
