<div class="ad-audience">
  <div class="ad-audience__item ad-audience-item">
    <div class="ad-audience-item__title">Целевая аудитория</div>
    <div class="ad-audience-item__value ad-audience-value">
      <ng-container *ngIf="!isDirty || targeting; else loading">
        <div class="ad-audience-value__bold">{{TargetingAudienceCount | number}}</div>
        <div class="ad-audience-value__light">&nbsp;человек&nbsp;</div>
        <div class="ad-audience-value__tooltip" matTooltip="Активная аудитория подходящая под заданные настройки">[?]</div>
      </ng-container>
    </div>
  </div>
  <div class="ad-audience__item ad-audience-item">
    <div class="ad-audience-item__title">&nbsp;Рекомендуемая цена</div>
    <div class="ad-audience-item__value ad-audience-value">
      <ng-container *ngIf="!isDirty || targeting; else loading">
        <div class="ad-audience-value__bold">&nbsp;{{targeting?.recommended_cpm || 0}}</div>
        <div class="ad-audience-value__light">&nbsp;руб.</div>
      </ng-container>
    </div>
  </div>
</div>

<ng-container *ngIf="TargetingAudienceCount < 100">
  <div class="ad-audience-error">Аудитория должна быть больше 100 человек.</div>
</ng-container>

<ng-template #loading>Загрузка ...</ng-template>
