import { OnInit } from '@angular/core';
import { AdFormManagerService } from '../../services/ad-form-manager/ad-form-manager.service';
var AdTeazersListComponent = /** @class */ (function () {
    function AdTeazersListComponent(adFormManagerService) {
        this.adFormManagerService = adFormManagerService;
    }
    Object.defineProperty(AdTeazersListComponent.prototype, "teazers", {
        get: function () {
            return this.adFormManagerService.teazers;
        },
        enumerable: true,
        configurable: true
    });
    AdTeazersListComponent.prototype.ngOnInit = function () {
    };
    return AdTeazersListComponent;
}());
export { AdTeazersListComponent };
