import {Component, OnInit} from '@angular/core';
import {CabinetsService} from '../../../api/services/cabinets.service';
import {AuthCabinet} from '../../../api/models/auth-cabinet';
import {AccountsService} from '../../../api/services/accounts.service';
import {AccountViewModel} from '../../../api/models/account-view-model';
import {TariffPlanExpiration} from '../../../api/models';
import {TokenCheckerService} from "../../../shared/services/token-checker.service";
import {Router} from "@angular/router";
import {TariffPlansQuery} from "../../../new-payments/stores/tariff-plans/tariff-plans.query";
import {TariffPlansService} from "../../../new-payments/stores/tariff-plans/tariff-plans.service";
import {UserTariffQuery} from "../../stores/user-tariff/state/user-tariff.query";
import {UserTariffService} from "../../stores/user-tariff/user-tariff/user-tariff.service";
import {map} from "rxjs/operators";
import {TariffGroup} from "../../../new-payments/stores/tariff-plans/tariff-plan.model";
import {TariffGroupsService} from "../../../tariff-groups-payments/services/tariff-groups/tariff-groups.service";
import {TariffGroupModel} from "../../../tariff-groups-payments/models/tariff-group.model";
import {AffiliateStatusService} from "../../services/affiliate-status.service";

@Component({
  selector: 'app-personal-settings',
  templateUrl: './personal-settings.component.html',
  styleUrls: ['./personal-settings.component.scss']
})
export class PersonalSettingsComponent implements OnInit {

  public tariff$ = this.tariffQuery.tariff$;
  public expiredAt$ = this.tariffQuery.expiredAt$;

  public Cabinets: Array<AuthCabinet>;
  public LoggedInCabinets: Array<AuthCabinet>;
  public Account: AccountViewModel;
  public AccountLoading = true;
  public CabinetsLoading = true;
  public openedChangePasswordForm = false;
  public tariffPlanExpirations: Array<TariffPlanExpiration>;
  public tariffGroups: TariffGroupModel[] = [];

  public showRemoveAccountDataModal: boolean = false;

  public get affiliateStatus() {
    return this.affiliateStatusService.isPartner;
  }
  
  ToggleChangePasswordForm() {
    this.openedChangePasswordForm = !this.openedChangePasswordForm;
  }

  constructor(
    private cabinetsService: CabinetsService,
    private accountsService: AccountsService,
    private tokenService: TokenCheckerService,
    private router: Router,
    private tariffQuery: UserTariffQuery,
    private tariffService: UserTariffService,
    private tariffGroupsService: TariffGroupsService,
    private affiliateStatusService: AffiliateStatusService
  ) {
  }

  ngOnInit() {
    this.LoadCabinets();
    this.LoadUserCabinets();
    this.LoadAccountInformation();
    this.LoadExpirations();
    this.LoadTariffGroups();
    this.affiliateStatusService.LoadAffiliateMemberStatus();
    this.tariffService.LoadUserTariffPackage();
  }

  public RemoveAccountInitiate(): void {
    this.showRemoveAccountDataModal = true;
  }

  public ToggleAccountsLoading() {
    this.AccountLoading = !this.AccountLoading;
  }

  public ToggleCabinetsLoading() {
    this.CabinetsLoading = !this.CabinetsLoading;
  }

  public LoadCabinets() {
    this.ToggleCabinetsLoading();
    this.cabinetsService.GetCabinets()
      .subscribe(x => {
        this.Cabinets = x;
        this.ToggleCabinetsLoading();
      });
  }

  public LoadUserCabinets() {
    this.ToggleAccountsLoading();
    this.cabinetsService.GetUserCabinets()
      .subscribe(x => {
        this.LoggedInCabinets = x;
        this.ToggleAccountsLoading();
      });
  }

  public LoadExpirations() {
    this.accountsService.GetUserTariffPlansExpirations()
      .subscribe(data => {
        this.tariffPlanExpirations = data;
      });
  }

  public IsLoggedIn(cabinetId) {
    return (this.LoggedInCabinets) ? this.LoggedInCabinets.map(x => x.id).includes(cabinetId) : 0;
  }

  public LoadAccountInformation() {
    this.accountsService.GetUserInfo()
      .subscribe(x => {
        this.Account = x;
      });
  }

  public LoadTariffGroups() {
    this.tariffGroupsService.LoadTariffGroups()
      .subscribe(tariffGroups => {
        this.tariffGroups = tariffGroups;
      })
  }

}
