/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./banners-content.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../banner-item/banner-item.component.ngfactory";
import * as i3 from "../banner-item/banner-item.component";
import * as i4 from "@angular/platform-browser";
import * as i5 from "../../services/banners.service";
import * as i6 from "@angular/common";
import * as i7 from "./banners-content.component";
var styles_BannersContentComponent = [i0.styles];
var RenderType_BannersContentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BannersContentComponent, data: {} });
export { RenderType_BannersContentComponent as RenderType_BannersContentComponent };
function View_BannersContentComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-banner-item", [], null, [[null, "mousedown"]], function (_v, en, $event) { var ad = true; if (("mousedown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).OnBannerClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_BannerItemComponent_0, i2.RenderType_BannerItemComponent)), i1.ɵdid(2, 114688, null, 0, i3.BannerItemComponent, [i4.DomSanitizer, i5.BannersService], { banner: [0, "banner"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_BannersContentComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_BannersContentComponent_1)), i1.ɵdid(1, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i6.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.banners$)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_BannersContentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-banners-content", [], null, null, null, View_BannersContentComponent_0, RenderType_BannersContentComponent)), i1.ɵdid(1, 114688, null, 0, i7.BannersContentComponent, [i5.BannersService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BannersContentComponentNgFactory = i1.ɵccf("app-banners-content", i7.BannersContentComponent, View_BannersContentComponent_Host_0, {}, {}, []);
export { BannersContentComponentNgFactory as BannersContentComponentNgFactory };
