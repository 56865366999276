import {Component, Input, OnInit} from '@angular/core';
import {AdFormatViewModel} from '../../../../ad-manager/models/ad-format.view-model';

@Component({
  selector: 'app-new-ad-format-preview',
  templateUrl: './new-ad-format-preview.component.html',
  styleUrls: ['./new-ad-format-preview.component.scss']
})
export class NewAdFormatPreviewComponent implements OnInit {

  @Input() public adFormat: AdFormatViewModel;

  constructor() {
  }

  ngOnInit() {
  }

}
