<app-automation-header [backLink]="'/automation/accounts'">
  {{currentCabinet?.account_name}}
</app-automation-header>

<!--<app-analytic-services-header-->
<!--[provider]="'yandex-metrika'"-->
<!--[yandexMetrikaConnected]="IsAnalyticServiceAvaialbe(yandex)"-->
<!--[availabeCounter]="yandex.Counters"-->
<!--[availabeGoals]="yandex.Goals"-->
<!--[yandexSelectedCounterId]="counterId"-->
<!--(SelectCounter)="SelectCounter($event)"-->
<!--(SelectGoal)="SelectGoal($event)"-->
<!--&gt;-->

<app-memory-alert [id]="'automation-campaigns-tip'">
  Выберите кампанию, в которой будете работать.
</app-memory-alert>

<ngr-dg-action-bar>
  <div class="fx-row">
    <div class="fx-divider"></div>
    <div class="actions">
      <div class="fx-row">
        <div class="count-label">
          Выберите количество запией на странице
        </div>
        <mat-form-field  [style.width]="'50px'">
          <mat-select [(ngModel)]="countOfResults">
            <ng-container *ngFor="let size of availableSizes">
              <mat-option [value]="size.value">{{size.label}}</mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
</ngr-dg-action-bar>

<ngr-data-grid
  [data]="campaignsViewModels"
  [loading]="isLoading"
  [countOfResults]="countOfResults"
  [pagination]="dgPagination"
  #dg
>
  <ngr-dg-column
    header="Название"
    name="name"
    [summaryFunction]="SummaryTitleFunction"
    [pagingSummaryFunction]="PaginSummaryTitleFunction"
    filterPlaceholderText="Название кампании"
    [hiddable]="false"
  >
    <div *ngrDgContent="let campaign=row">
      <a
        routerLink="/automation/ads"
        [queryParams]="{
          account_id: this.account_id,
          campaign_id: campaign.id,
          client_id: clientId
        }"
      >
        {{campaign.name}}
      </a>
    </div>
  </ngr-dg-column>
  <ngr-dg-column
    header="Статус"
    name="status"
    filterType="select"
    [filterOptions]="statusFilterOptions"
    align="center"
  >
    <div *ngrDgContent="let status=cell; let campaign=row">
      <button class="status-button" [matMenuTriggerFor]="menu">
        <ng-container [ngSwitch]="status">
          <ng-container *ngSwitchCase="1">
            <i class="ion-md-play" style="color: #00ff00"></i>
          </ng-container>
          <ng-container *ngSwitchCase="0">
            <i class="ion-md-square" style="color: #ff0000"></i>
          </ng-container>
          <ng-container *ngSwitchCase="2">
            <div class="ad_status_wait"></div>
          </ng-container>
        </ng-container>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="ToggleCampaignStatus(campaign.id, true)">
          <mat-icon style="color: #00a100; width: 16px; height: 16px; font-size: 16px; margin: 0;">play_arrow</mat-icon>
          Запустить
        </button>
        <button mat-menu-item (click)="ToggleCampaignStatus(campaign.id, false)">
          <mat-icon  style="color: #ff0000; width: 16px; height: 16px; font-size: 16px; margin: 0;">stop</mat-icon>
          Остановить
        </button>
      </mat-menu>
      <ng-template #stopAdStatus>
        <mat-icon  style="color: #ff0000; width: 16px; height: 16px; font-size: 16px;">stop</mat-icon>
      </ng-template>
      <!--<ng-container *ngIf="status === 1; else stopAdStatus">-->
      <!--<i class="ion-md-play" style="color: #85C81A"></i>-->
      <!--</ng-container>-->
      <!--<ng-template #stopAdStatus>-->
      <!--<i class="ion-md-square" style="color: #ff0000"></i>-->
      <!--</ng-template>-->
    </div>
  </ngr-dg-column>
  <ngr-dg-column
    header="Дневной лимит"
    name="day_limit"
    [formatFunction]="CurrencyFunction"
    filterType="between"
  ></ngr-dg-column>
  <ngr-dg-column
    header="Общий лимит"
    name="all_limit"
    [formatFunction]="CurrencyFunction"
    filterType="between"
  ></ngr-dg-column>

  <ngr-dg-column
    header="Потрачено"
    name="spent"
    [summaryFunction]="SummaryFunction"
    [formatFunction]="CurrencyFunction"
    filterType="between"
  ></ngr-dg-column>

  <ngr-dg-column
    header="Показы"
    name="impressions"
    [summaryFunction]="SummaryFunction"
    [formatFunction]="NumberFunction"
    filterType="between"
  ></ngr-dg-column>

  <ngr-dg-column
    header="Переходы"
    name="clicks"
    [summaryFunction]="SummaryFunction"
    [formatFunction]="NumberFunction"
    filterType="between"
  ></ngr-dg-column>

  <ngr-dg-column
    header="CTR"
    name="ctr"
    [summaryFunction]="AvgSummaryFunction"
    [formatFunction]="CtrFormatFunction"
    filterType="between"
  ></ngr-dg-column>

  <ngr-dg-column
    header="eCPC"
    name="ecpc"
    [summaryFunction]="AvgSummaryFunction"
    [formatFunction]="CurrencyFunction"
    filterType="between"
  ></ngr-dg-column>

  <ngr-dg-pagination
    [(currentPage)]="dg.currentPage"
    (currentPageChange)="dg.OnPageChange($event)"
    [countOfPages]="dg.countOfPages"
    #dgPagination
  ></ngr-dg-pagination>
</ngr-data-grid>

<div class="action-bar">
  <div class="action-bar__action">
    <!--<ngr-dg-pagination-->
      <!--[(currentPage)]="dg.currentPage"-->
      <!--(currentPageChange)="dg.OnPageChange($event)"-->
      <!--[countOfPages]="dg.countOfPages"-->
      <!--#dgPagination-->
      <!--*ngIf="dg.countOfPages > 1"-->
    <!--&gt;</ngr-dg-pagination>-->
  </div>
  <div class="action-bar__divider"></div>
  <div class="action-bar__action">
    <!--<button class="btn btn-sm btn-success action" (click)="InitPage()">-->
    <!--<span class="ion-md-refresh"></span>-->
    <!--Обновить-->
    <!--</button>-->
    <button class="btn btn-sm btn-primary action_margin" (click)="dg.ToggleSidebar()">
      <span class="ion-md-settings"></span>
      Настройки таблицы
    </button>
    <button class="btn btn-sm btn-primary action" (click)="dg.ExportToExcel()">
      <span class="ion-md-download"></span>
      Экспортировать в excel
    </button>
  </div>
</div>

<!--</app-analytic-services-header>-->
<!--<clr-datagrid>-->
<!--<clr-dg-action-bar>-->
<!--<div class="fx-row fx-row_block" *ngIf="campaignsViewModels?.length">-->
<!--<app-date-filter [date]="date" (changingDate)="ChangeDate($event)" [disabled]="isLoading"></app-date-filter>-->
<!--<div class="fx-divider"></div>-->
<!--<button class="btn btn-sm btn-export" (click)="ExportToExcel()" [disabled]="disabledExportButton">Экспортировать в Excel</button>-->
<!--</div>-->
<!--</clr-dg-action-bar>-->
<!--<clr-dg-column [clrDgField]="'name'">-->
<!--<ng-container *clrDgHideableColumn="hiddenColumns.name">-->
<!--Название-->
<!--</ng-container>-->
<!--</clr-dg-column>-->
<!--<clr-dg-column [clrDgField]="'status'">-->
<!--<ng-container *clrDgHideableColumn="hiddenColumns.status">-->
<!--Статус-->
<!--</ng-container>-->
<!--</clr-dg-column>-->
<!--<clr-dg-column [clrDgField]="'day_limit'">-->
<!--<ng-container *clrDgHideableColumn="hiddenColumns.day_limit">-->
<!--Дневной лимит-->
<!--</ng-container>-->
<!--</clr-dg-column>-->
<!--<clr-dg-column [clrDgField]="'all_limit'">-->
<!--<ng-container *clrDgHideableColumn="hiddenColumns.all_limit">-->
<!--Общий лимит-->
<!--</ng-container>-->
<!--</clr-dg-column>-->
<!--<clr-dg-column [clrDgField]="'spent'">-->
<!--<ng-container *clrDgHideableColumn="hiddenColumns.spent">-->
<!--Потрачено-->
<!--</ng-container>-->
<!--</clr-dg-column>-->
<!--<clr-dg-column [clrDgField]="'impressions'">-->
<!--<ng-container *clrDgHideableColumn="hiddenColumns.impressions">-->
<!--Показы-->
<!--</ng-container>-->
<!--</clr-dg-column>-->
<!--<clr-dg-column [clrDgField]="'clicks'">-->
<!--<ng-container *clrDgHideableColumn="hiddenColumns.clicks">-->
<!--Переходы-->
<!--</ng-container>-->
<!--</clr-dg-column>-->
<!--<clr-dg-column [clrDgField]="'ctr'">-->
<!--<ng-container *clrDgHideableColumn="hiddenColumns.ctr">-->
<!--CTR-->
<!--</ng-container>-->
<!--</clr-dg-column>-->
<!--<clr-dg-column [clrDgField]="'ecpc'">-->
<!--<ng-container *clrDgHideableColumn="hiddenColumns.ecpc">-->
<!--eCPC-->
<!--</ng-container>-->
<!--</clr-dg-column>-->

<!--<clr-dg-column clrDgField="yandex_cr_from_view" *ngIf="goalId">-->
<!--<ng-container *clrDgHideableColumn="hiddenColumns.yandex_cr_from_view">-->
<!--Yandex.CR просмотр-->
<!--</ng-container>-->
<!--</clr-dg-column>-->
<!--<clr-dg-column clrDgField="yandex_cr_from_click" *ngIf="goalId">-->
<!--<ng-container *clrDgHideableColumn="hiddenColumns.yandex_cr_from_click">-->
<!--Yandex.CR переход-->
<!--</ng-container>-->
<!--</clr-dg-column>-->

<!--<clr-dg-column clrDgField="yandex_cpa" *ngIf="goalId">-->
<!--<ng-container *clrDgHideableColumn="hiddenColumns.yandex_cpa">-->
<!--Yandex.CPA-->
<!--</ng-container>-->
<!--</clr-dg-column>-->
<!--<clr-dg-column clrDgField="yandex_reched_goals" *ngIf="goalId">-->
<!--<ng-container *clrDgHideableColumn="hiddenColumns.yandex_reched_goals">-->
<!--Yandex.Целей достигнуто-->
<!--</ng-container>-->
<!--</clr-dg-column>-->

<!--<clr-dg-column clrDgField="yandex_page_depth" *ngIf="goalId">-->
<!--<ng-container *clrDgHideableColumn="hiddenColumns.yandex_page_depth">-->
<!--Yandex.Глубина просмотра-->
<!--</ng-container>-->
<!--</clr-dg-column>-->
<!--<clr-dg-column clrDgField="yandex_average_duration" *ngIf="goalId">-->
<!--<ng-container *clrDgHideableColumn="hiddenColumns.yandex_average_duration">-->
<!--Yandex.Время на сайте-->
<!--</ng-container>-->
<!--</clr-dg-column>-->

<!--<clr-dg-placeholder>-->
<!--<ng-container *ngIf="!isLoading; else loadingScreen">-->
<!--Пока нет ни одной созданной кампании!-->
<!--</ng-container>-->
<!--<ng-template #loadingScreen>-->
<!--<span class="spinner"></span>-->
<!--<span>Подождите, данные загружаются ...</span>-->
<!--</ng-template>-->
<!--</clr-dg-placeholder>-->
<!--<clr-dg-row *clrDgItems="let campaign of campaignsViewModels">-->
<!--<clr-dg-cell>-->

<!--<ng-container *ngIf="!campaign.loaded">-->
<!--<div class="spinner spinner-inline"></div>-->
<!--</ng-container>-->
<!--</clr-dg-cell>-->
<!--<clr-dg-cell>-->
<!--<ng-container *ngIf="campaign.status === 0">-->
<!--<clr-icon shape="stop" class="is-solid" style="color: #F52F22;"></clr-icon>-->
<!--</ng-container>-->
<!--<ng-container *ngIf="campaign.status === 1">-->
<!--<clr-icon shape="play" class="is-solid" style="color: #85C81A;"></clr-icon>-->
<!--</ng-container>-->
<!--<ng-container *ngIf="campaign.status === 2">-->
<!--<clr-icon shape="pause" class="is-solid" style="color: #00f"></clr-icon>-->
<!--</ng-container>-->
<!--</clr-dg-cell>-->
<!--<clr-dg-cell>{{campaign.day_limit | number:'1.0-2'}}</clr-dg-cell>-->
<!--<clr-dg-cell>{{campaign.all_limit | number:'1.0-2'}}</clr-dg-cell>-->
<!--<clr-dg-cell>{{campaign.spent | currency:' Руб.'}}</clr-dg-cell>-->
<!--<clr-dg-cell>{{campaign.impressions | number:'1.0-2'}}</clr-dg-cell>-->
<!--<clr-dg-cell>{{campaign.clicks | number:'1.0-2'}}</clr-dg-cell>-->
<!--<clr-dg-cell>{{campaign.ctr | number:'1.0-3'}} %</clr-dg-cell>-->
<!--<clr-dg-cell>{{campaign.ecpc | currency:' Руб.'}}</clr-dg-cell>-->
<!--<clr-dg-cell *ngIf="goalId">{{campaign.yandex_cr_from_view | number:"1.0-4"}}%</clr-dg-cell>-->
<!--<clr-dg-cell *ngIf="goalId">{{campaign.yandex_cr_from_click | number:"1.0-2"}}%</clr-dg-cell>-->
<!--<clr-dg-cell *ngIf="goalId">{{campaign.yandex_cpa | currency:" Руб."}}</clr-dg-cell>-->
<!--<clr-dg-cell *ngIf="goalId">{{campaign.yandex_reched_goals}}</clr-dg-cell>-->

<!--<clr-dg-cell *ngIf="goalId">{{campaign.yandex_page_depth | number:"1.0-2"}}</clr-dg-cell>-->
<!--<clr-dg-cell *ngIf="goalId">{{campaign.yandex_average_duration | secondsToTime}}</clr-dg-cell>-->
<!--</clr-dg-row>-->
<!--<clr-dg-footer>-->
<!--<clr-dg-column-toggle>-->
<!--<clr-dg-column-toggle-title>Отобразить/Cкрыть колонки</clr-dg-column-toggle-title>-->
<!--<clr-dg-column-toggle-button clrType="selectAll">Выбрать все</clr-dg-column-toggle-button>-->
<!--<clr-dg-column-toggle-button clrType="ok">-->
<!--ОК-->
<!--</clr-dg-column-toggle-button>-->
<!--</clr-dg-column-toggle>-->
<!--</clr-dg-footer>-->
<!--</clr-datagrid>-->

<!--<table class="table">-->
<!--<thead>-->
<!--<tr>-->
<!--<th>Название</th>-->
<!--<th>Статус</th>-->
<!--<th>Дневной лимит</th>-->
<!--<th>Общий лимит</th>-->
<!--<th>Потрачено</th>-->
<!--<th>Показы</th>-->
<!--<th>Переходы</th>-->
<!--<th>CTR</th>-->
<!--<th>eCPC</th>-->
<!--</tr>-->
<!--</thead>-->
<!--<tbody>-->
<!--<tr *ngFor="let campaign of campaignsViewModels">-->
<!--<td><a routerLink="/automation/ads" [queryParams]="{-->
<!--account_id: account_id,-->
<!--campaign_id: campaign.id-->
<!--}">{{campaign.name}}</a></td>-->
<!--<td>{{campaign.status}}</td>-->
<!--<td>{{campaign.day_limit}}</td>-->
<!--<td>{{campaign.all_limit}}</td>-->
<!--<td>-->
<!--{{campaign.spent}} руб.-->
<!--</td>-->
<!--<td>-->
<!--{{campaign.impressions}}-->
<!--</td>-->
<!--<td>-->
<!--{{campaign.clicks}}-->
<!--</td>-->
<!--<td>-->
<!--{{campaign.ctr | number:'1.0-3'}} %-->
<!--</td>-->
<!--<td>-->
<!--{{campaign.ecpc | currency:' Руб.'}}-->
<!--</td>-->
<!--</tr>-->
<!--</tbody>-->
<!--</table>-->
