import {Injectable} from '@angular/core';
import {Cabinet} from '../../../api/models/cabinet';
import Dexie from 'dexie';
import {CoreService} from './core.service';
import {AccountViewModel} from '../../../automation/models/view-models/account-view-model';

@Injectable()
export class DexieCabinetsService {

  table: Dexie.Table<AccountViewModel, number>;

  constructor(
    private core: CoreService
  ) {
    this.table = core.table('cabinets');
  }

  getAll() {
    return this.table.toArray();
  }

  addCabinet(cabinet: AccountViewModel) {
    this.table.put(cabinet);
  }

  setCabinets(cabinet: Array<AccountViewModel>) {
    this.table.clear();
    return Promise.all(cabinet.map(x => {
      return this.addCabinet(x);
    }));
  }

  /**
   *
   * @param id
   * @returns {Dexie.Promise<Cabinet | undefined>}
   */
  getCabinetById(id) {
    return this.table.where('account_id')
      .equals(id)
      .first();
  }

  /**
   * Update cabinets
   * @param id
   * @param cabinet
   * @returns {Dexie.Promise<number>}
   */
  updateCabinet(id, cabinet: any) {
    return this.table.update(id, cabinet);
  }

}
