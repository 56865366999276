var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { map as __map, filter as __filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../api-configuration";
import * as i2 from "@angular/common/http";
var AdManagerService = /** @class */ (function (_super) {
    __extends(AdManagerService, _super);
    function AdManagerService(config, http) {
        return _super.call(this, config, http) || this;
    }
    /**
     * @return Success
     */
    AdManagerService.prototype.GetCountriesResponse = function () {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/ad-manager/countries", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @return Success
     */
    AdManagerService.prototype.GetCountries = function () {
        return this.GetCountriesResponse().pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `AdManagerService.GetCitiesParams` containing the following parameters:
     *
     * - `countryId`:
     *
     * - `searchString`:
     *
     * @return Success
     */
    AdManagerService.prototype.GetCitiesResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.countryId != null)
            __params = __params.set('countryId', params.countryId.toString());
        if (params.searchString != null)
            __params = __params.set('searchString', params.searchString.toString());
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/ad-manager/cities", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `AdManagerService.GetCitiesParams` containing the following parameters:
     *
     * - `countryId`:
     *
     * - `searchString`:
     *
     * @return Success
     */
    AdManagerService.prototype.GetCities = function (params) {
        return this.GetCitiesResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `AdManagerService.SearchGroupsParams` containing the following parameters:
     *
     * - `countryId`:
     *
     * - `cityId`:
     *
     * - `title`:
     *
     * @return Success
     */
    AdManagerService.prototype.SearchGroupsResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.countryId != null)
            __params = __params.set('countryId', params.countryId.toString());
        if (params.cityId != null)
            __params = __params.set('cityId', params.cityId.toString());
        if (params.title != null)
            __params = __params.set('title', params.title.toString());
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/ad-manager/groups", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `AdManagerService.SearchGroupsParams` containing the following parameters:
     *
     * - `countryId`:
     *
     * - `cityId`:
     *
     * - `title`:
     *
     * @return Success
     */
    AdManagerService.prototype.SearchGroups = function (params) {
        return this.SearchGroupsResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param groupIds undefined
     * @return Success
     */
    AdManagerService.prototype.GetGroupsByIdResponse = function (groupIds) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        (groupIds || []).forEach(function (val) { if (val != null)
            __params = __params.append('groupIds', val.toString()); });
        var req = new HttpRequest('POST', this.rootUrl + "/apiv2/ad-manager/groups", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param groupIds undefined
     * @return Success
     */
    AdManagerService.prototype.GetGroupsById = function (groupIds) {
        return this.GetGroupsByIdResponse(groupIds).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param targetingData undefined
     * @return Success
     */
    AdManagerService.prototype.GetTargetingStatsResponse = function (targetingData) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = targetingData;
        var req = new HttpRequest('POST', this.rootUrl + "/apiv2/ad-manager/targeting", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param targetingData undefined
     * @return Success
     */
    AdManagerService.prototype.GetTargetingStats = function (targetingData) {
        return this.GetTargetingStatsResponse(targetingData).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `AdManagerService.GetAdRejectionParams` containing the following parameters:
     *
     * - `adId`:
     *
     * - `accountId`:
     */
    AdManagerService.prototype.GetAdRejectionResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.adId != null)
            __params = __params.set('adId', params.adId.toString());
        if (params.accountId != null)
            __params = __params.set('accountId', params.accountId.toString());
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/ad-manager/rejection", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `AdManagerService.GetAdRejectionParams` containing the following parameters:
     *
     * - `adId`:
     *
     * - `accountId`:
     */
    AdManagerService.prototype.GetAdRejection = function (params) {
        return this.GetAdRejectionResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param ads1 undefined
     * @return Success
     */
    AdManagerService.prototype.UpdateAdResponse = function (ads1) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = ads1;
        var req = new HttpRequest('PUT', this.rootUrl + "/apiv2/ad-manager/ad", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param ads1 undefined
     * @return Success
     */
    AdManagerService.prototype.UpdateAd = function (ads1) {
        return this.UpdateAdResponse(ads1).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param ads1 undefined
     * @return Success
     */
    AdManagerService.prototype.CreateAdResponse = function (ads1) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = ads1;
        var req = new HttpRequest('POST', this.rootUrl + "/apiv2/ad-manager/ad", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param ads1 undefined
     * @return Success
     */
    AdManagerService.prototype.CreateAd = function (ads1) {
        return this.CreateAdResponse(ads1).pipe(__map(function (_r) { return _r.body; }));
    };
    AdManagerService.prototype.GetAdminGroupsResponse = function () {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/ad-manager/admin-groups", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    AdManagerService.prototype.GetAdminGroups = function () {
        return this.GetAdminGroupsResponse().pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param groupId undefined
     */
    AdManagerService.prototype.GetGroupAlbumsResponse = function (groupId) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (groupId != null)
            __params = __params.set('groupId', groupId.toString());
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/ad-manager/albums", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param groupId undefined
     */
    AdManagerService.prototype.GetGroupAlbums = function (groupId) {
        return this.GetGroupAlbumsResponse(groupId).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param campaignData undefined
     */
    AdManagerService.prototype.CreateCampaignResponse = function (campaignData) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = campaignData;
        var req = new HttpRequest('POST', this.rootUrl + "/apiv2/ad-manager/campaign", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param campaignData undefined
     */
    AdManagerService.prototype.CreateCampaign = function (campaignData) {
        return this.CreateCampaignResponse(campaignData).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @return Success
     */
    AdManagerService.prototype.GetAdRatesResponse = function () {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/ad-manager/rates", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @return Success
     */
    AdManagerService.prototype.GetAdRates = function () {
        return this.GetAdRatesResponse().pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `AdManagerService.CabBeEditableParams` containing the following parameters:
     *
     * - `clientId`:
     *
     * - `accountId`:
     *
     * - `adIds`:
     *
     * @return Success
     */
    AdManagerService.prototype.CabBeEditableResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.clientId != null)
            __params = __params.set('clientId', params.clientId.toString());
        if (params.accountId != null)
            __params = __params.set('accountId', params.accountId.toString());
        (params.adIds || []).forEach(function (val) { if (val != null)
            __params = __params.append('adIds', val.toString()); });
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/ad-manager/can-be-editable", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `AdManagerService.CabBeEditableParams` containing the following parameters:
     *
     * - `clientId`:
     *
     * - `accountId`:
     *
     * - `adIds`:
     *
     * @return Success
     */
    AdManagerService.prototype.CabBeEditable = function (params) {
        return this.CabBeEditableResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `AdManagerService.GetImageParams` containing the following parameters:
     *
     * - `clientId`:
     *
     * - `adId`:
     *
     * - `accountId`:
     *
     * @return Success
     */
    AdManagerService.prototype.GetImageResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.clientId != null)
            __params = __params.set('clientId', params.clientId.toString());
        if (params.adId != null)
            __params = __params.set('adId', params.adId.toString());
        if (params.accountId != null)
            __params = __params.set('accountId', params.accountId.toString());
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/ad-manager/get-image", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `AdManagerService.GetImageParams` containing the following parameters:
     *
     * - `clientId`:
     *
     * - `adId`:
     *
     * - `accountId`:
     *
     * @return Success
     */
    AdManagerService.prototype.GetImage = function (params) {
        return this.GetImageResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @return Success
     */
    AdManagerService.prototype.GetCategoriesResponse = function () {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/ad-manager/get-categories", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @return Success
     */
    AdManagerService.prototype.GetCategories = function () {
        return this.GetCategoriesResponse().pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param adsData undefined
     * @return Success
     */
    AdManagerService.prototype.MultipleUpdateAdsResponse = function (adsData) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = adsData;
        var req = new HttpRequest('POST', this.rootUrl + "/apiv2/ad-manager/multiple-update-ads", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param adsData undefined
     * @return Success
     */
    AdManagerService.prototype.MultipleUpdateAds = function (adsData) {
        return this.MultipleUpdateAdsResponse(adsData).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @return Success
     */
    AdManagerService.prototype.GetUserStrategyInputKeysResponse = function () {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/ad-manager/strategy-inputs-keys", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @return Success
     */
    AdManagerService.prototype.GetUserStrategyInputKeys = function () {
        return this.GetUserStrategyInputKeysResponse().pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param adsData undefined
     * @return Success
     */
    AdManagerService.prototype.UpdateStrategiesResponse = function (adsData) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = adsData;
        var req = new HttpRequest('POST', this.rootUrl + "/apiv2/ad-manager/update-strategies", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param adsData undefined
     * @return Success
     */
    AdManagerService.prototype.UpdateStrategies = function (adsData) {
        return this.UpdateStrategiesResponse(adsData).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param copyAdsViewModel undefined
     * @return Success
     */
    AdManagerService.prototype.CopyAdsResponse = function (copyAdsViewModel) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = copyAdsViewModel;
        var req = new HttpRequest('POST', this.rootUrl + "/apiv2/ad-manager/copy-ads", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param copyAdsViewModel undefined
     * @return Success
     */
    AdManagerService.prototype.CopyAds = function (copyAdsViewModel) {
        return this.CopyAdsResponse(copyAdsViewModel).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `AdManagerService.GetTargetingGroupParams` containing the following parameters:
     *
     * - `accountId`:
     *
     * - `clientId`:
     *
     * @return Success
     */
    AdManagerService.prototype.GetTargetingGroupResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.accountId != null)
            __params = __params.set('accountId', params.accountId.toString());
        if (params.clientId != null)
            __params = __params.set('clientId', params.clientId.toString());
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/ad-manager/get-targeting-groups", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `AdManagerService.GetTargetingGroupParams` containing the following parameters:
     *
     * - `accountId`:
     *
     * - `clientId`:
     *
     * @return Success
     */
    AdManagerService.prototype.GetTargetingGroup = function (params) {
        return this.GetTargetingGroupResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @return Success
     */
    AdManagerService.prototype.GetInterestCategoriesResponse = function () {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/ad-manager/get-interest-categories", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @return Success
     */
    AdManagerService.prototype.GetInterestCategories = function () {
        return this.GetInterestCategoriesResponse().pipe(__map(function (_r) { return _r.body; }));
    };
    AdManagerService.GetCountriesPath = '/apiv2/ad-manager/countries';
    AdManagerService.GetCitiesPath = '/apiv2/ad-manager/cities';
    AdManagerService.SearchGroupsPath = '/apiv2/ad-manager/groups';
    AdManagerService.GetGroupsByIdPath = '/apiv2/ad-manager/groups';
    AdManagerService.GetTargetingStatsPath = '/apiv2/ad-manager/targeting';
    AdManagerService.GetAdRejectionPath = '/apiv2/ad-manager/rejection';
    AdManagerService.UpdateAdPath = '/apiv2/ad-manager/ad';
    AdManagerService.CreateAdPath = '/apiv2/ad-manager/ad';
    AdManagerService.GetAdminGroupsPath = '/apiv2/ad-manager/admin-groups';
    AdManagerService.GetGroupAlbumsPath = '/apiv2/ad-manager/albums';
    AdManagerService.CreateCampaignPath = '/apiv2/ad-manager/campaign';
    AdManagerService.GetAdRatesPath = '/apiv2/ad-manager/rates';
    AdManagerService.CabBeEditablePath = '/apiv2/ad-manager/can-be-editable';
    AdManagerService.GetImagePath = '/apiv2/ad-manager/get-image';
    AdManagerService.GetCategoriesPath = '/apiv2/ad-manager/get-categories';
    AdManagerService.MultipleUpdateAdsPath = '/apiv2/ad-manager/multiple-update-ads';
    AdManagerService.GetUserStrategyInputKeysPath = '/apiv2/ad-manager/strategy-inputs-keys';
    AdManagerService.UpdateStrategiesPath = '/apiv2/ad-manager/update-strategies';
    AdManagerService.CopyAdsPath = '/apiv2/ad-manager/copy-ads';
    AdManagerService.GetTargetingGroupPath = '/apiv2/ad-manager/get-targeting-groups';
    AdManagerService.GetInterestCategoriesPath = '/apiv2/ad-manager/get-interest-categories';
    AdManagerService.ngInjectableDef = i0.defineInjectable({ factory: function AdManagerService_Factory() { return new AdManagerService(i0.inject(i1.ApiConfiguration), i0.inject(i2.HttpClient)); }, token: AdManagerService, providedIn: "root" });
    return AdManagerService;
}(__BaseService));
export { AdManagerService };
