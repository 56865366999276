import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs/Observable';
import * as moment from 'moment';
import {TokenCheckerService} from '../services/token-checker.service';

@Injectable()
export class AuthGuardGuard implements CanActivate {
  constructor(private tokenChecker: TokenCheckerService) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
          return this.tokenChecker.IsAuthorized();
    }
}
