var AdblockService = /** @class */ (function () {
    function AdblockService() {
    }
    Object.defineProperty(AdblockService.prototype, "AdBlockEnabled", {
        get: function () {
            // @ts-ignore
            return window['adBlockDisabled'] === undefined;
        },
        enumerable: true,
        configurable: true
    });
    return AdblockService;
}());
export { AdblockService };
