import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-comment-form',
  templateUrl: './comment-form.component.html',
  styleUrls: ['./comment-form.component.scss']
})
export class CommentFormComponent implements OnInit {

  public form: FormGroup;

  @Output() public commentSaved: EventEmitter<any> = new EventEmitter();
  constructor() {
  }

  ngOnInit() {
    this.form = new FormGroup({
      text: new FormControl('', [Validators.required, Validators.maxLength(255)])
    });
  }

  submit() {
    if (this.form.valid) {
      this.commentSaved.emit(this.form.value);
    }
  }

}
