<ng-container *ngIf="graphicsLoaded; else graphicsNotLoaded">
  <ng-container *ngIf="GetHighestResolutionImage() as imageSource; else noVisual">
    <div class="easy-ad-teaser-container">
      <img [src]="imageSource" class="easy-ad-teaser">
      <div class="easy-ad-teaser-title">
        {{adLayout!.title}}
      </div>
    </div>
  </ng-container>
  <ng-template #noVisual>
    <div class="easy-ad-teaser">
      Нет изображения
    </div>
  </ng-template>
</ng-container>
<ng-template #graphicsNotLoaded>
  <div class="easy-ad-teaser">
    Загрузка...
  </div>
</ng-template>
