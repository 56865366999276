import { OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatCheckboxChange, MatTableDataSource } from '@angular/material';
import { AdFormManagerService } from '../../services/ad-form-manager/ad-form-manager.service';
var GroupsTableComponent = /** @class */ (function () {
    function GroupsTableComponent(adFormManagerService) {
        this.adFormManagerService = adFormManagerService;
        this.groups = [];
        this.displayColumns = ['checkbox', 'avatar', 'name', 'members_count'];
        this.columns = ['checkbox', 'avatar', 'name', 'members_count'];
    }
    Object.defineProperty(GroupsTableComponent.prototype, "CountOfSelected", {
        get: function () {
            return this.adFormManagerService.countOfSelected();
        },
        enumerable: true,
        configurable: true
    });
    GroupsTableComponent.prototype.ngOnInit = function () {
    };
    GroupsTableComponent.prototype.ngOnChanges = function (changes) {
        console.log(changes);
        this.dataSource = new MatTableDataSource(this.groups);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    };
    GroupsTableComponent.prototype.toggleSelectedRow = function (group) {
        this.adFormManagerService.toggleSelectedRow(group);
    };
    GroupsTableComponent.prototype.isGroupSelected = function (group) {
        return this.adFormManagerService.isGroupSelected(group);
    };
    GroupsTableComponent.prototype.ToggleAll = function ($event) {
        this.adFormManagerService.ToggleAll();
    };
    GroupsTableComponent.prototype.IsAllToggled = function () {
        return this.adFormManagerService.IsAllToggled();
    };
    GroupsTableComponent.prototype.IsRowDisabled = function (group) {
        return this.adFormManagerService.IsRowDisabled(group);
    };
    return GroupsTableComponent;
}());
export { GroupsTableComponent };
