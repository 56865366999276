import {Directive, TemplateRef} from '@angular/core';

@Directive({
  selector: '[appLinkQueryParamTemplate]'
})
export class LinkQueryParamTemplateDirective {

  constructor(
    public template: TemplateRef<any>
  ) { }

}
