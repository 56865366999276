import {Injectable} from '@angular/core';
import {Platform} from './platform.model';
import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';

export interface PlatformsState extends EntityState<Platform> {
  ui: {
    selectedOriginPlatform: string;
    selectedDestinationPlatform: string;
    params: any,
    requiredFields: string[]
  };
}


@Injectable({providedIn: 'root'})
@StoreConfig({name: 'platforms'})
export class PlatformsStore extends EntityStore<PlatformsState> {

  constructor() {
    super({
      ui: {
        selectedOriginPlatform: '',
        selectedDestinationPlatform: '',
        params: {},
        requiredFields: []
      }
    });
  }

  setParameters(platformId, paramsData) {
    this.update(state => {
      const params = {};
      params[platformId] = paramsData;
      const newState = {
        ...state,
        ui: {
          ...state.ui,
          params
        }
      };
      return newState;
    });
  }
}

