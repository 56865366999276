var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AbstractCopyingConfigurator } from "./abstract-copying-configurator";
/**
 *
 */
var RetargetingGroupsCopyingConfigurator = /** @class */ (function (_super) {
    __extends(RetargetingGroupsCopyingConfigurator, _super);
    function RetargetingGroupsCopyingConfigurator(props) {
        return _super.call(this, props) || this;
    }
    RetargetingGroupsCopyingConfigurator.prototype.generate = function () {
        var _this = this;
        var specifications = [];
        console.log(this.copyingData.retargetingGroupConfiguration, 'RETARGETING_GROUP');
        this.copyingData.retargetingGroupConfiguration.retargetingGroups.forEach(function (retargetingGroupId) {
            var spec = _this.generateDefeault();
            spec = Object.assign(spec, {
                retargetingGroup: retargetingGroupId,
                retargetingGroupNot: (_this.copyingData.retargetingGroupConfiguration.excludeOtherGroups)
                    ? _this.copyingData.retargetingGroupConfiguration.retargetingGroups.filter(function (x) { return x != retargetingGroupId; }).join()
                    : ''
            });
            specifications.push(spec);
        });
        return specifications;
    };
    return RetargetingGroupsCopyingConfigurator;
}(AbstractCopyingConfigurator));
export { RetargetingGroupsCopyingConfigurator };
