/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { APIReponseWrapperSystemUInt64 } from '../models/apireponse-wrapper-system-uint-64';
import { APIReponseWrapperDictionaryStringWithULong } from '../models/apireponse-wrapper-dictionary-string-with-ulong';
@Injectable({
  providedIn: 'root',
})
class HashService extends __BaseService {
  static readonly ConvertUUIDToIdPath = '/apiv2/hash/uuid/{uuid}';
  static readonly ConvertUUIDsToIdPath = '/apiv2/hash/uuid';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param uuid undefined
   * @return Success
   */
  ConvertUUIDToIdResponse(uuid: string): __Observable<__StrictHttpResponse<APIReponseWrapperSystemUInt64>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/hash/uuid/${encodeURIComponent(uuid)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<APIReponseWrapperSystemUInt64>;
      })
    );
  }
  /**
   * @param uuid undefined
   * @return Success
   */
  ConvertUUIDToId(uuid: string): __Observable<APIReponseWrapperSystemUInt64> {
    return this.ConvertUUIDToIdResponse(uuid).pipe(
      __map(_r => _r.body as APIReponseWrapperSystemUInt64)
    );
  }

  /**
   * @param uuids undefined
   * @return Success
   */
  ConvertUUIDsToIdResponse(uuids?: Array<string>): __Observable<__StrictHttpResponse<APIReponseWrapperDictionaryStringWithULong>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = uuids;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/apiv2/hash/uuid`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<APIReponseWrapperDictionaryStringWithULong>;
      })
    );
  }
  /**
   * @param uuids undefined
   * @return Success
   */
  ConvertUUIDsToId(uuids?: Array<string>): __Observable<APIReponseWrapperDictionaryStringWithULong> {
    return this.ConvertUUIDsToIdResponse(uuids).pipe(
      __map(_r => _r.body as APIReponseWrapperDictionaryStringWithULong)
    );
  }
}

module HashService {
}

export { HashService }
