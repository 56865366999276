<div class="lead-form-settings">
  <div class="lead-form-settings__header">
    <div class="lead-form-settings__checkbox">
      <mat-checkbox [formControl]="control" class="no-margin-checkbox">{{form.name}}</mat-checkbox>
    </div>
    <a class="lead-form-settings__settings-trigger" href="javascript:void(0)" (click)="ShowCampaigns()">
      <ng-container *ngIf="isOpened$ | async; else showCampaignsText">
        Скрыть кампании
      </ng-container>
      <ng-template #showCampaignsText>Показать кампании</ng-template>
    </a>
    <a class="lead-form-settings__settings-sample" [href]="'https://ok.ru/leadad/' + form.id" target="_blank">
      Посмотреть форму
    </a>
  </div>
  <div class="lead-form-settings__campaigns" [hidden]="!(isOpened$ | async)">
    <ng-container *ngFor="let campaign of campaigns$ | async">
      {{campaign.name}}
    </ng-container>
  </div>
</div>
