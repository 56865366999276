<ng-container *ngIf="graphicsLoaded; else graphicsNotLoaded">
  <ng-container *ngIf="GetLinkSnippet() as linkSnippet; else noLinkSnippet">
    <div class="easy-ad-promo-link-snippet">
      <div class="easy-ad-promo-link-snippet__image-container">
        <img [src]="GetSpinnetLink(linkSnippet)" class="easy-ad-promo-link-snippet-image">
      </div>
      <div class="easy-ad-promo-link-snippet__meta-container">
        <div class="easy-ad-promo-link-snippet-title">
          {{linkSnippet.title}}
        </div>

        <div class="easy-ad-promo-link-snippet-url">
          {{linkSnippet.url | domainOnly}}
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #noLinkSnippet>
    <div class="easy-ad-promo-loading-placeholder">
      Нет изображения
    </div>
  </ng-template>
</ng-container>
<ng-template #graphicsNotLoaded>
  <div class="easy-ad-promo-loading-placeholder">
    Загрузка...
  </div>
</ng-template>

