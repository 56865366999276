import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatCheckboxModule, MatIconModule, MatProgressSpinnerModule} from '@angular/material';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {environment} from '../../environments/environment';

import {IntegrationsRoutingModule} from './integrations-routing.module';
import {IntegrationFormModule} from './modules/integration-form/integration-form.module';
// import {reducers} from './store';
import {IntegrationsListComponent} from './routes/integrations-list/integrations-list.component';
// import {appEffects} from './store/effects/app.effects';
import {IntegrationsTableComponent} from './components/integrations-table/integrations-table.component';
import {NgrUiModule} from "../ngr-ui/ngr-ui.module";
import {SharedModule} from '../shared/shared.module';
import { IntegrationAccessDeniedComponent } from './routes/integration-access-denied/integration-access-denied.component';

@NgModule({
  declarations: [IntegrationsListComponent, IntegrationsTableComponent, IntegrationAccessDeniedComponent],
  exports: [
  ],
  imports: [
    CommonModule,
    IntegrationsRoutingModule,
    // StoreModule.forRoot(reducers),
    // StoreDevtoolsModule.instrument({
    //   maxAge: 25, // Retains last 25 states
    //   logOnly: environment.production, // Restrict extension to log-only mode
    // }),
    // EffectsModule.forRoot(appEffects),
    MatIconModule,
    IntegrationFormModule,
    NgrUiModule,
    MatCheckboxModule,
    SharedModule,
    MatProgressSpinnerModule
  ]
})
export class IntegrationsModule {
}
