import { OnInit } from '@angular/core';
var PromopostMetaDataComponent = /** @class */ (function () {
    function PromopostMetaDataComponent() {
    }
    Object.defineProperty(PromopostMetaDataComponent.prototype, "meta", {
        get: function () {
            return {
                avatar: this.promopostMeta ? this.promopostMeta.avatar : 'https://via.placeholder.com/50',
                groupName: this.promopostMeta ? this.promopostMeta.groupName : 'Сообщество не выбрано'
            };
        },
        enumerable: true,
        configurable: true
    });
    PromopostMetaDataComponent.prototype.ngOnInit = function () {
    };
    return PromopostMetaDataComponent;
}());
export { PromopostMetaDataComponent };
