import {Component, OnDestroy, OnInit} from '@angular/core';
import {environment} from '../../../../../environments/environment';
import {ActivatedRoute} from '@angular/router';
import {OAuthService} from '../../../../api/services/oauth.service';
import {MatDialog} from '@angular/material';
import {BitrixAssignationDialogComponent} from '../../assignation-dialogs/bitrix-assignation-dialog/bitrix-assignation-dialog.component';
import {PlatformAssignationsService} from '../../../stores/platform-assignations/platform-assignations.service';
import {AssignationPlatforms} from '../../../stores/platform-assignations/platform-assignation.model';
import {PlatformAssignationsQuery} from "../../../stores/platform-assignations/platform-assignations.query";
import {untilDestroyed} from "ngx-take-until-destroy";

@Component({
  selector: 'app-bitrix-assignation-screen',
  templateUrl: './bitrix-assignation-screen.component.html',
  styleUrls: ['./bitrix-assignation-screen.component.scss']
})
export class BitrixAssignationScreenComponent implements OnInit, OnDestroy {

  public baseUrl = environment.frontEndUrl;
  public provider = 'bitrix';
  public assigned = false;
  public loading: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private oauth: OAuthService,
    private dialog: MatDialog,
    private assignationService: PlatformAssignationsService,
    private assignationQuery: PlatformAssignationsQuery
  ) {
  }

  ngOnInit() {
    this.CheckAssignation();

    console.log('WTF ????');

    this.assignationQuery.assingnationStatus$(AssignationPlatforms.BITRIX24)
      .pipe(
        // untilDestroyed(this)
      )
      .subscribe(data => {
        console.log('BITRIX WAS CHANGED', data);
        this.assigned = data;
      })
  }

  public AddVkAdCabinetLinkClick(): void {
    this.dialog.open(BitrixAssignationDialogComponent)
      .afterClosed()
      .subscribe(data => {
        this.CheckAssignation();
      });
  }

  public GetBindUrl(): string {
    return `${this.baseUrl}/${environment.apiPrefix}/OAuth/authorize/${this.provider}`;
  }

  public CheckAssignation() {
    this.loading = true;
    this.oauth.CheckProviderToken(this.provider)
      .subscribe(data => {
          this.assigned = data;
          if (data) {
            this.assignationService.assignPlatform(AssignationPlatforms.BITRIX24)
          }
          this.loading = false;
        },
        err => {
          this.loading = false;
        }
      );
  }

  ngOnDestroy(): void {
  }

}
