<form [formGroup]="form">
  <vk-form-field [orientation]="'vertical'" [controlsWidth]="'470px'">
    <vk-label>
      Ссылка на сайт
      <app-suggestion-info code="GENERATION_LINK_HOST"></app-suggestion-info>
    </vk-label>
    <input type="link" vkInput formControlName="host">
    <vk-error validator="required">Вы не ввели ссылку</vk-error>
    <vk-error validator="urlHttp">Ссылка должна содержать http:// или https://</vk-error>
  </vk-form-field>
  <table>
    <thead>
    <tr>
      <th>
        Название параметра
        <app-suggestion-info code="GENERATION_LINK_PARAM_NAME"></app-suggestion-info>
      </th>
      <th>
        Значение параметра
        <app-suggestion-info code="GENERATION_LINK_PARAM_VALUE"></app-suggestion-info>
      </th>
      <th></th>
    </tr>
    </thead>
    <tbody formArrayName="linkQueryParams">
    <ng-container *ngFor="let param of params; let i = index;">
      <tr [formGroup]="param">
        <td>
          <app-link-query-param-input [items]="utms" formControlName="name">
          </app-link-query-param-input>
        </td>
        <td>
          <app-link-query-param-input [items]="macros" formControlName="value">
            <div *appLinkQueryParamTemplate="let selectedItem = selectedItem">
              {{selectedItem.title}}
            </div>
<!--            <ng-template let-selectedItem="selectedItem">-->
<!--              {{selectedItem | json}}-->
<!--            </ng-template>-->
          </app-link-query-param-input>
        </td>
        <td>
          <button class="remove-button" (click)="RemoveParam(i)">
            <mat-icon>
              close
            </mat-icon>
          </button>
        </td>
      </tr>
    </ng-container>
    </tbody>
    <tfoot>
      <tr>
        <td colspan="2">
          <button (click)="AddParam()" mat-raised-button color="primary" [style.width]="'100%'" [style.marginTop]="'4px'">
            <mat-icon>add</mat-icon>
            Добавить параметр
          </button>
        </td>
      </tr>
    </tfoot>
  </table>
</form>
