import {Injectable, OnInit} from '@angular/core';
import {UserAutomationCabinetSlot} from '../../api/models/user-automation-cabinet-slot';
import {
  Cabinet,
  Campaign,
} from '../../api/models';
import {VkFormModel} from '../models/vk-form.model';
import {
  AdManagerService,
  AutomationVkService,
  AutomationVkSlotService,
  IntegrationService,
  MyTargetService,
  UserIntegrationService
} from '../../api/services';
import {delay, flatMap, map, tap} from 'rxjs/operators';
import {waterfall} from '../../utils/rxjs-operators/waterfall';
import {isNullOrUndefined} from 'util';
import {MockService} from './mock/mock.service';
import {combineLatest, forkJoin, merge, Observable} from 'rxjs';
import {LeadFormField} from '../models/lead-form-field';
import {MtCampaign} from '../models/mt-campaign';
import {FormField} from '../modules/integration-form/models/form-field';
import * as _ from 'lodash';
import {IntegrationFormService} from '../modules/integration-form/services/integration-form.service';

@Injectable({
  providedIn: 'root'
})
export class StoreService implements OnInit {
  public integrations: Array<any> = [];
  public loading: boolean = false;
  public loadingMessage: string = '';

  public get assignedAccounts() {
    return this.slots.filter(x => x.bindedCabinetId != null)
      .map(slot => this.accounts.find(x => x.account_id === slot.bindedCabinetId && slot.bindedClientId === x.client_id))
      .filter(cabinet => !isNullOrUndefined(cabinet));
  }

  public slots: UserAutomationCabinetSlot[];
  public accounts: Cabinet[];
  public campaigns: Campaign[];
  public forms: VkFormModel[];
  public services: any[];
  public relations: Array<any>;
  public formFields: FormField[];

  constructor(
    private vkSlotService: AutomationVkSlotService,
    private vkService: AutomationVkService,
    private mock: MockService,
    private adManagerService: AdManagerService,
    private mtService: MyTargetService,
    private integrationService: IntegrationService,
    private integrationForm: IntegrationFormService,
    private userIntegration: UserIntegrationService
  ) {
  }

  public get IsOriginServiceSelected() {
    return this.integrationForm.form.value.leftService !== '';
  }

  ngOnInit() {
    console.log('INT SERVICE');
    forkJoin(
      this.integrationForm.leftServiceChanged,
      this.integrationForm.rightServiceChanged
    )
      .subscribe(([leftServicesCode, rightServicesCode]) => {
        const relation = this.getRealtionByServiceCodes(leftServicesCode, rightServicesCode);
        console.log('relation', relation);
        if (relation) {
          this.integrationForm.form.patchValue({name: relation.name});
        }
      });
  }

  public LoadVkAccountData() {
    return this.LoadSlots()
      .pipe(
        flatMap(() => this.LoadAccounts()),
        flatMap(() => this.LoadClients())
      );
  }

  //
  public get selectedFormFields() {
    const a = _.chain(this.formFields)
      .groupBy((field) => field.key)
      .map((data, item) => {
        return {
          data: data.map(x => `${x.groupId}_${x.formId}`),
          item
        };
      })
      .value()
      .reduce((acc, {item, data}) => {
        acc[item] = data;
        return acc;
      }, {});
    const selectedForms = this.integrationForm.formService.getControl('vk_forms', 'forms_ids').value;

    return Object.keys(a).map(x => _.isEqual(a[x], []));
  }

  public LoadSlots() {
    return this.vkSlotService.GetSlots()
      .pipe(
        tap(({data}) => {
          this.slots = data;
        }),
        delay(1000)
      );
  }

  public LoadServices() {
    return this.integrationService.GetIntegrationServices({
      allFields: false, sortFieldsAsc: true, filterOnlyActive: true
    })
      .pipe(
        tap((servicesResponse: any) => {
          console.log(servicesResponse);
          this.services = servicesResponse.data;
        })
      );
  }

  public LoadServiceRelations() {
    return this.integrationService
      .GetCompableIntegrations()
      .pipe(
        tap(relationResponse => {
          console.log(relationResponse);
          this.relations = relationResponse.data;
        })
      );
  }

  public LoadServicesData() {
    return forkJoin(
      [this.LoadServices(),
        this.LoadServiceRelations()]
    )
      .pipe(tap(() => {
        console.log(this.services, this.relations, 'RELATIONS');
      }));
  }

  public LoadAccounts() {
    return this.vkService.GetAccounts()
      .pipe(
        tap(({data}) => {
          this.accounts = data;
        }),
        delay(1000)
      );
  }

  public LoadClients() {
    const agencyAccounts = this.accounts.filter(x => x.account_type === 'agency');
    const clients = agencyAccounts.map(account => () => this.vkService.getClients(account.account_id)
      .pipe(
        delay(1000),
        tap(({data}) => {
          data.forEach(client => {
            this.accounts.push({
              ...account,
              ...client
            });
          });
        })
      )
    );
    return waterfall(clients);
  }

  public LoadCampaigns(accountId: number, clientId: number) {
    return this.vkService.GetCampaigns({
      accountId,
      clientId
    })
      .map(({data}) => data.sort((a, b) => b.id - a.id));
  }

  public LoadVkForms(accountId: number, clientId: number, campaignId) {
    return this.vkService.GetAds({
      accountId,
      clientId,
      campaignIds: [campaignId],
      adIds: [],
      limit: null,
      offset: null,
      includeDeleted: false
    })
      .pipe(
        delay(1000),
        map(({data: ads}) => ads.map(x => x.id)),
        flatMap((adIds) => this.vkService.GetAdsLayout({
          accountId,
          clientId,
          campaignIds: [campaignId],
          adIds: [],
          limit: null,
          offset: null,
          includeDeleted: false
        })),
        delay(1000),
        map(({data: adsLayouts}) => adsLayouts.map(x => x.link_url.replace('http://vk.com/wall', '')).join(',')),
        flatMap(wallPostIds => this.vkService.GetWallById(wallPostIds)),
        delay(1000),
        map(({data: wallPosts}) => wallPosts.map(wallPost => -wallPost.owner_id)),
        map((data) => Array.from(new Set(data))),
        map(groupIds => groupIds.map(groupId => this.vkService.GetLeadForms(groupId).pipe(delay(1000)))),
        map(formsObservables => formsObservables.map(obs => () => obs)),
        flatMap(formsObservable => waterfall(formsObservable)),
        tap((forms: any[]) => {
          this.formFields = [];
          forms.forEach(form => {
            form.questions.forEach(question => {
              this.AddFormField(form, question);
            });
          });
          const groupedFields = _.chain(this.formFields)
            .groupBy((field) => field.key)
            .map((data, item) => {
              return {
                data: data.map(x => `${x.groupId}_${x.formId}`),
                item
              };
            })
            .value()
            .reduce((acc, {item, data}) => {
              acc[item] = data;
              return acc;
            }, {});
          const selectedForms = this.integrationForm.formService.getControl('vk_forms', 'forms_ids').value;
          console.log(selectedForms, 'SELECTED FORMS');
          console.log(groupedFields, 'GROUPED');
          console.log(forms, 'LOADING VK FORMS');
        })
      );
  }

  private AddFormField(form, question) {
    const formField = this.formFields.find(field => field.key === question.key);
    if (!formField) {
      this.formFields.push({
        groupId: form.group_id,
        formId: form.form_id,
        key: question.key,
        name: question.label,
        type: question.type
      });
    }
  }

  public LoadMtAccounts() {
    return this.mock.LoadMtAccounts();
  }

  public LoadMtCampaigns() {
    return this.mtService.GetCampaigns({
      limit: 20,
      offset: 0
    })
      .map(({data}) => data.map(x => ({name: x.name, id: x.id} as MtCampaign)));
  }

  public LoadMtBanners(campaignIds) {
    return this.mtService.GetBanners({
      limit: 20,
      offset: 0,
      campaignIds
    });
  }

  public LoadMtForms(campaignIds, bannerIds) {
    return this.LoadMtBanners(campaignIds)
      .pipe(
        tap((data) => {
          console.log(data);
        }),
        flatMap(({data}) => this.mtService.GetLeadAdsForms({
          campaignIds,
          bannerIds: data.map(x => x.id),
          limit: 20,
          offset: 0,
        })),
        map(({data}) => data.map(x => ({name: 'Form 1', id: 1})))
      );
  }

  public LoadLeadFormFields(): Observable<LeadFormField[]> {
    return this.mock.LoadFormFields();
  }

  public LoadVkRetargetingGroups(accountId: number, clientId: number) {
    return this.adManagerService.GetTargetingGroup({
      accountId,
      clientId
    });
  }

  public LoadMtRemarketingGroups(accountId: number) {
    return this.mock.LoadMtRemarketingGroups(accountId);
  }

  getFormsField() {
    console.log(this.forms, 'FORMS_FIELDS');
  }

  getRealtionByServiceCodes(leftServiceCode, rightServiceCode) {
    const leftService = this.getServiceByCode(leftServiceCode);
    const rightService = this.getServiceByCode(rightServiceCode);
    const relation = this.relations
      .find(relationItem => relationItem.integrationServiceFromId === leftService.integrationServiceId && relationItem.integrationServiceToId === rightService.integrationServiceId);

    if (relation) {
      return relation;
    } else {
      throw new Error(`Relation ${leftServiceCode} and ${rightServiceCode} not found`);
    }

  }

  private getServiceByCode(rightServiceCode: any) {
    const service = this.services.find(service1 => service1.code === rightServiceCode);
    if (service) {
      return this.services.find(service1 => service1.code === rightServiceCode);
    } else {
      throw new Error(`Service with code ${rightServiceCode} not found`);
    }
  }

  changeUserIntegrationStatus(integrationId: number, status: boolean) {
    return this.userIntegration.ChangeUserIntegrationStatus({
      integrationId,
      status
    })
      .pipe(
        tap(data => {
          this.LoadUsersIntegrations();
        })
      );
  }

  /**
   * Загрузка ползовательских интеграций
   * @constructor
   */
  LoadUsersIntegrations() {
    this.userIntegration.GetUserIntegrations({showRemoved: false})
      .subscribe((response) => {
        this.integrations = response.data;
        console.log('Loaded user integrations', this.integrations);
      });
  }

  /**
   * Получение название интеграции по коду платформы импорта и платформы экспорта
   * @param leftServiceName
   * @param rightServiceName
   */
  getRelationNameByServiceCodes(leftServiceName: string, rightServiceName: string) {
    const relation = this.getRealtionByServiceCodes(leftServiceName, rightServiceName);
    if (relation) {
      return relation.name;
    }
    return null;
  }

  /**
   * Удаление интеграции по id
   * @param id - id интеграции в базе данных
   */
  public removeIntegration(id) {
    this.loading = true;
    this.loadingMessage = 'Подождите, идет удаление интеграции';
    this.userIntegration.RemoveUserIntegration(id)
      .subscribe(() => {
        this.loading = false;
        this.loadingMessage = '';
        this.LoadUsersIntegrations();
      }, err => {
        if (err.status === 400 && err.error && err.error.description) {
          alert(err.error.description);
        } else {
          alert('Неизвестная ошибка! Обратитесь в службу технической поддержки.');
        }
      });
  }
}
