import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material';
import {flatMap} from 'rxjs/operators';
import {AdManagerService} from '../../../../../api/services';
import {CreateCampaignFormComponent} from '../../../../ad-manager/components/create-campaign-form/create-campaign-form.component';
import {AdFormManagerService} from '../../services/ad-form-manager/ad-form-manager.service';

@Component({
  selector: 'app-new-account-settings-step',
  templateUrl: './new-account-settings-step.component.html',
  styleUrls: ['./new-account-settings-step.component.scss']
})
export class NewAccountSettingsStepComponent implements OnInit {
  controlWidth: string = '200px';
  labelWidth: string = '250px';

  public get form() {
    return this.adFormManagerService.getControl('campaignData');
  }

  public get accounts() {
    return this.adFormManagerService
      .accounts
      .filter(account => account.account_type !== 'agency' || (account.account_type === 'agency' && account.client_id != null));
  }

  public get campaigns() {
    return this.adFormManagerService.displayedCampaigns;
  }

  public getAccountId(account) {
    return this.adFormManagerService.GetAccountId(account);
  }

  public IsAccountAssigned(account) {
    return this.adFormManagerService.isAccountAssigned(account.account_id, account.client_id);
  }

  constructor(
    private adFormManagerService: AdFormManagerService,
    private adManagerService: AdManagerService,
    private dialog: MatDialog
  ) {
  }

  public OpenCreateCampaignDialog() {
    const {accountId, clientId} = this.adFormManagerService.getAccountData();
    this.dialog.open(CreateCampaignFormComponent, {
      data: {
        clientId,
        type: this.adFormManagerService.currentFormat().type,
        accountId
      }
    })
      .afterClosed()
      .pipe(
        flatMap(data => this.adFormManagerService.LoadCampaigns())
      )
      .subscribe(data => {
      });
  }

  ngOnInit() {
  }
}
