<vk-form-field labelPosition="left" [flexible]="true" labelWidth="316px">
  <vk-label>
    {{statusName}}
    <app-suggestion-info [code]="suggestionCode"></app-suggestion-info>
  </vk-label>
  <mat-select vkInput [formControl]="control" multiple placeholder="Выберите списки пользователей">
    <ng-container *ngFor="let group of options">
      <mat-option [value]="group.id" [attr.disabled]="isOptionDisabled(group.id)">
        {{group.name}}
      </mat-option>
    </ng-container>
  </mat-select>
</vk-form-field>
