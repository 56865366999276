import {Directive, ElementRef, Input, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {FormControl, NgControl} from "@angular/forms";
import {MatCheckbox} from "@angular/material";

@Directive({
  selector: '[appCheckboxItem]'
})
export class CheckboxItemDirective implements OnInit{

  @Input() appCheckboxItem: any;

  control: FormControl = new FormControl();

  constructor(
    private template: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef,
    private el: ElementRef
  ) {

  }

  ngOnInit(): void {
    // console.log(this.host.value, 'CHECKBOX ITEN');
    this.viewContainerRef.createEmbeddedView(this.template, {control: this.control});
    // setTimeout(() => {
    //   console.log(this.el);
    //   this.value = this.el.nativeElement.getAttribute('value')
    // }, 1000)
  }

}
