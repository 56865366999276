import {Component, OnInit} from '@angular/core';
import {Partner} from '../../api/models';
import {PartnersService} from '../../api/services';

@Component({
    selector: 'app-partners-page',
    templateUrl: './partners-page.component.html',
    styleUrls: ['./partners-page.component.scss']
})
export class PartnersPageComponent implements OnInit {

    public partners: Array<Partner>;

    constructor(
        private partnersService: PartnersService
    ) {
    }

    ngOnInit() {
        this.LoadPartners();
    }

    public LoadPartners() {
        this.partnersService.GetActivePartners()
            .subscribe(data => {
                this.partners = data;
            });
    }

}
