import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Strategy} from '../../../api/models/strategy';
import {AdViewModel} from '../../models/view-models/ad-view-model';
import {StrategyInputValue} from '../../models/view-models/strategy-input-value';
import {BidRange} from '../../../api/models/bid-range';
import {Validator} from '../../../api/models/validator';
import {StrategyCreationModel} from '../../models/view-models/strategy-creation-model';
import {UserStrategy} from "../../../api/models/user-strategy";
import {UserStrategiesService} from "../../../api/services/user-strategies.service";


@Component({
  selector: 'app-strategy-creation-form',
  templateUrl: './strategy-creation-form.component.html',
  styleUrls: ['./strategy-creation-form.component.scss']
})
export class StrategyCreationFormComponent implements OnInit, OnChanges {
  @Input() public strategies: Array<Strategy>;
  @Input() public ads: Array<AdViewModel> = [];
  @Input() public bidRanges: Array<BidRange> = [];

  @Input() public userStrategies: Array<UserStrategy> = [];

  @Input() public strategyCreated: number = 0;

  @Output() public onStrategySelected: EventEmitter<Strategy> = new EventEmitter<Strategy>();

  @Output() public onStrategySave: EventEmitter<StrategyCreationModel> = new EventEmitter<StrategyCreationModel>();
  @Output() public onEditedStrategySave: EventEmitter<any> = new EventEmitter<any>();

  @Output() public onStartButtonStartNowPress: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onStartButtonStartLaterPress: EventEmitter<any> = new EventEmitter<any>();

  public extraInputsListExpanded: boolean = true;

  public selectedStrategy: Strategy = null;

  /**
   * Подходящие (по типу оплаты) стратегии
   * @type {Array}
   */
  public suitableStrategies: Array<Strategy> = [];

  public values: Array<StrategyInputValue> = [];

  public valuesPrimary: Array<StrategyInputValue> = [];
  public valuesExtra: Array<StrategyInputValue> = [];

  public allowChangeSelectedStrategy: boolean = true;

  public editedValues: Array<number> = []; // Dictionary<string,number>

  public mode: string = 'CREATE'; // CREATE, UPDATE

  constructor() {
  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges) {
    // Проверим есть ли изменения объявлений
    if (changes) {
      if (changes.ads) {
        if (!this.userStrategies || this.userStrategies.length === 0) {
          this.selectedStrategy = null;
        }

        this.values = [];

        // Узнаем есть ли среди объявлений те, которые работают по модели CPC
        const gotCpcAds = this.ads.filter(x => x.cpc > 0).length > 0;

        // Если есть - то уберем из стратегий те, которые не поддерживают CPC
        if (gotCpcAds) {
          this.suitableStrategies = this.strategies.filter(x => x.cpcCompitable);
        } else {
          this.suitableStrategies = this.strategies;
        }
      }

      if (changes.userStrategies && changes.userStrategies.currentValue) {
        if (changes.userStrategies.currentValue.length > 0) {
          this.mode = 'UPDATE';
          this.allowChangeSelectedStrategy = false;
          const userStrategyId = this.userStrategies[0].strategyId;

          const strategy = this.strategies.find(x => x.id === userStrategyId);

          this.StrategySelected(strategy);

          // На этом этапе мы уже знаем какие стратегии выбраны для редактирования
          // Мы можем заполнить значениями инпуты
          if (this.userStrategies.length === 1) {
            // Если стратегия всего-лишь одна, то можем заполнить форму значениями
            const currentuserSrtrategy = this.userStrategies[0];

            this.selectedStrategy.inputs.forEach(input => {
              let model = this.GetInputFromPrimaries(input.id);

              if (!model) {
                model = this.GetInputFromExtras(input.id);
              }

              if (model) {
                const userStrategyInput = currentuserSrtrategy.inputs.find(x => x.id === input.id);

                if (userStrategyInput) {
                  model.value = userStrategyInput.value / model.valueMultiplicator;
                } else {
                  model.value = null;
                }
              }
            });
          }
        } else {
          this.mode = 'CREATE';
          this.allowChangeSelectedStrategy = true;
        }
      }
    }
  }

  public StrategySelected(strategy: Strategy): void {
    this.selectedStrategy = strategy;

    const adFormats = this.GetAdFormats();
    const adFormat = adFormats[0];

    this.values = [];
    this.valuesPrimary = [];
    this.valuesExtra = [];

    const gotCpcAds = this.ads.filter(x => x.cpc > 0).length > 0;

    this.selectedStrategy.inputs.forEach(input => {
      const value: StrategyInputValue = {
        id: input.id,
        code: input.code,
        type: input.frontendInputType,
        name: input.title,
        value: (input.defaultValue !== null) ? parseInt(input.defaultValue, 10) : null,
        requiredValueValidator: null,
        minValueValidator: null,
        maxValueValidator: null,
        valueMultiplicator: 1,
        errors: []
      };

      const inputValidators = input.validators;

      if (inputValidators && inputValidators.length > 0) {
        inputValidators.forEach(validator => {
          if (validator.code === 'REQUIRED') {
            value.requiredValueValidator = validator;
          }

          if (validator.code === 'MIN') {
            value.minValueValidator = validator;
          }

          if (validator.code === 'MAX') {
            value.maxValueValidator = validator;
          }
        });
      }

      // Обработаем специальные коды, зависящие от внешних данных
      const relatedBidRange = this.bidRanges.find(x => x.adFormat === adFormat);

      switch (value.code) {
        case 'BID_START':

          if (relatedBidRange) {
            if (value.minValueValidator) {
              value.minValueValidator.value = ((gotCpcAds) ? relatedBidRange.cpcMin : relatedBidRange.cpmMin) / 100;
              //value.minValueValidator = this.RenderValidatorMessage(value.minValueValidator);
            }

            if (value.maxValueValidator) {
              value.maxValueValidator.value = ((gotCpcAds) ? relatedBidRange.cpcMax : relatedBidRange.cpmMax) / 100;
              //value.maxValueValidator = this.RenderValidatorMessage(value.maxValueValidator);
            }
          }

          value.valueMultiplicator = 100;
          break;
        case 'BID_END':
          if (relatedBidRange) {
            if (value.minValueValidator) {
              value.minValueValidator.value = ((gotCpcAds) ? relatedBidRange.cpcMin : relatedBidRange.cpmMin) / 100;
              //value.minValueValidator = this.RenderValidatorMessage(value.minValueValidator);
            }

            if (value.maxValueValidator) {
              value.maxValueValidator.value = ((gotCpcAds) ? relatedBidRange.cpcMax : relatedBidRange.cpmMax) / 100;
              //value.maxValueValidator = this.RenderValidatorMessage(value.maxValueValidator);
            }
          }

          value.valueMultiplicator = 100;
          break;

        case 'BID_STEP':
          value.valueMultiplicator = 100;
          break;

        case 'ECPC_LIMIT':
          value.valueMultiplicator = 100;
          break;

        case 'CUSTOM_OVERALL_LIMIT':
          value.valueMultiplicator = 100;
          break;
      }

      if (input.showInExtraList) {
        this.valuesExtra.push(value);
      } else {
        this.valuesPrimary.push(value);
      }
    });

    this.onStrategySelected.emit(this.selectedStrategy);
  }

  public RemoveSelectedStrategy(): void {
    this.selectedStrategy = null;
    this.onStrategySelected.emit(null);
  }

  public Save(): void {
    // TODO Сделать массив из двух списков инпутов
    this.values = this.valuesPrimary.concat(this.valuesExtra);

    const validations = this.values.map(x => {
      return this.Validate(x);
    });

    // Проверим есть хотя бы один инпут не прошедший валиадцию
    if (validations.filter(x => x === false).length > 0) {
      this.extraInputsListExpanded = true;
      return;
    }

    // Модифицировать инпуты
    this.values.forEach(input => {
      if (input.value) {
        input.value = input.value * input.valueMultiplicator;
      }
    });


    // На этом моменте все инпуты прошли валидацию
    this.onStrategySave.emit({
      strategy: this.selectedStrategy,
      inputs: this.values,
      ads: this.ads
    });
  }

  public GetInputModel(id: number): StrategyInputValue {
    return this.values.find(x => x.id === id);
  }

  public GetInputFromPrimaries(id: number): StrategyInputValue {
    return this.valuesPrimary.find(x => x.id === id);
  }

  public GetInputFromExtras(id: number): StrategyInputValue {
    return this.valuesExtra.find(x => x.id === id);
  }

  public Validate(inputValue: StrategyInputValue): boolean {
    inputValue.errors = [];

    if (inputValue.requiredValueValidator && (inputValue.value === null || inputValue.value === undefined)) {
      inputValue.errors.push(inputValue.requiredValueValidator.invalidMessage);
    }

    if (inputValue.value !== null && inputValue.value !== undefined) {
      if (inputValue.minValueValidator && inputValue.value < inputValue.minValueValidator.value) {
        inputValue.errors.push(inputValue.minValueValidator.invalidMessage.replace('{{value}}', '' + inputValue.minValueValidator.value));
      }

      if (inputValue.maxValueValidator && inputValue.value > inputValue.maxValueValidator.value) {
        inputValue.errors.push(inputValue.maxValueValidator.invalidMessage.replace('{{value}}', '' + inputValue.maxValueValidator.value));
      }
    }

    return inputValue.errors.length === 0;
  }

  private GetAdFormats(): Array<number> {
    const adFormats: Array<number> = [];

    this.ads.forEach(x => {
      if (adFormats.indexOf(x.ad_format) === -1) {
        adFormats.push(x.ad_format);
      }
    });

    return adFormats;
  }

  private RenderValidatorMessage(validator: Validator): Validator {
    //validator.invalidMessage = validator.invalidMessage.replace('{{value}}', '' + validator.value);
    return validator;
  }

  public GetFilledValues(): Array<StrategyInputValue> {
    return this.values.filter(x => x.value !== null && x.value !== undefined);
  }

  public StartNowClick(): void {
    this.onStartButtonStartNowPress.emit(true);
  }

  public StartLaterClick(): void {
this.onStartButtonStartLaterPress.emit(true);
  }

  public SwitchExtraInputListExpanding(): void {
    this.extraInputsListExpanded = !this.extraInputsListExpanded;
  }

  public SaveEditedStrategy(): void {
    const specification = {
      AccountId: this.userStrategies[0].accountId,
      ClientId: this.userStrategies[0].clientId,
      AdIds: this.userStrategies.map(x => x.adId),
      Values: {}
    };

    this.values = this.valuesPrimary.concat(this.valuesExtra);

    // Оставить только модели, которые были отредактированы
    this.values = this.values.filter(x => this.editedValues.indexOf(x.id) >= 0);

    const validations = this.values.map(x => {
      return this.Validate(x);
    });

    // Проверим есть хотя бы один инпут не прошедший валиадцию
    if (validations.filter(x => x === false).length > 0) {
      this.extraInputsListExpanded = true;
      return;
    }

    // На этом моменте будет формироватсья спецификация
    this.editedValues.forEach(inputId => {
      const strategyInput = this.selectedStrategy.inputs.find(input => input.id === inputId);

      if (strategyInput) {
          let value = null;

          let model = this.GetInputFromPrimaries(inputId);

          if (!model) {
            model = this.GetInputFromExtras(inputId);
          }

          if (model && model.value != null && model.value !== undefined) {
            value = model.value * model.valueMultiplicator;
          }

        specification.Values[strategyInput.code] = value;
      }
    });

    this.onEditedStrategySave.emit(specification);
    this.ClearUserStrategyEditedValues();
  }

  private ClearUserStrategyEditedValues(): void {
    this.editedValues = [];
  }

  public AddUserStrategyEditedValue(id: number): void {
    if (this.editedValues.indexOf(id) === -1) {
      this.editedValues.push(id);
    }
  }
}
