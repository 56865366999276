import { AffiliateMemberStatisticsByDateFacade } from "../../../../storage/affiliate-member-statistics-by-date/state/affiliate-member-statistics-by-date.facade";
import * as i0 from "@angular/core";
import * as i1 from "../../../../storage/affiliate-member-statistics-by-date/state/affiliate-member-statistics-by-date.facade";
var AffiliateProgramStatisticsByDateService = /** @class */ (function () {
    function AffiliateProgramStatisticsByDateService(statisticsByDateFacade) {
        this.statisticsByDateFacade = statisticsByDateFacade;
    }
    Object.defineProperty(AffiliateProgramStatisticsByDateService.prototype, "statistics$", {
        get: function () {
            return this.statisticsByDateFacade.statistics$;
        },
        enumerable: true,
        configurable: true
    });
    AffiliateProgramStatisticsByDateService.prototype.LoadStatistics = function () {
        this.statisticsByDateFacade.LoadStatistics();
    };
    AffiliateProgramStatisticsByDateService.ngInjectableDef = i0.defineInjectable({ factory: function AffiliateProgramStatisticsByDateService_Factory() { return new AffiliateProgramStatisticsByDateService(i0.inject(i1.AffiliateMemberStatisticsByDateFacade)); }, token: AffiliateProgramStatisticsByDateService, providedIn: "root" });
    return AffiliateProgramStatisticsByDateService;
}());
export { AffiliateProgramStatisticsByDateService };
