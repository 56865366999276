import { Component, OnInit } from '@angular/core';
import {BannersService} from "../../services/banners.service";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-banner-dialog',
  templateUrl: './banner-dialog.component.html',
  styleUrls: ['./banner-dialog.component.scss']
})
export class BannerDialogComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<BannerDialogComponent>
  ) {
  }

  ngOnInit(): void {
  }

  close($event: MouseEvent) {
    this.dialogRef.close()
  }
}
