/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./ad-cost.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./ad-cost.component";
var styles_AdCostComponent = [i0.styles];
var RenderType_AdCostComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AdCostComponent, data: {} });
export { RenderType_AdCostComponent as RenderType_AdCostComponent };
export function View_AdCostComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.CurrencyPipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "ad-cost"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "ad-cost__value"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵppd(4, 2), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "ad-cost__name"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v, 0), _co.value, "\u0420\u0443\u0431.")); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.costTypeName; _ck(_v, 6, 0, currVal_1); }); }
export function View_AdCostComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-ad-cost", [], null, null, null, View_AdCostComponent_0, RenderType_AdCostComponent)), i1.ɵdid(1, 114688, null, 0, i3.AdCostComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AdCostComponentNgFactory = i1.ɵccf("app-ad-cost", i3.AdCostComponent, View_AdCostComponent_Host_0, { ad: "ad", costTypeMode: "costTypeMode" }, {}, []);
export { AdCostComponentNgFactory as AdCostComponentNgFactory };
