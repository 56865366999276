import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-affiliate-program-layout',
  templateUrl: './affiliate-program-layout.component.html',
  styleUrls: ['./affiliate-program-layout.component.scss']
})
export class AffiliateProgramLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
