<ng-container *ngIf="!disabled; else loading">
    <form>
        <div class="filter-row">
            <div class="btn-group">
                <!--<div class="radio btn btn-sm">-->
                    <!--<input type="radio" value="overall" name="period" id="period-8" [(ngModel)]="period"-->
                           <!--(ngModelChange)="ChangeDate()" [disabled]="disabled" >-->
                    <!--<label for="period-8">За все время</label>-->
                <!--</div>-->
                <div class="radio btn btn-sm">
                    <input type="radio" value="today" name="period" id="period-1" [(ngModel)]="period"
                           (ngModelChange)="ChangeDate()" [disabled]="disabled" >
                    <label for="period-1">Сегодня</label>
                </div>
                <div class="radio btn btn-sm">
                    <input type="radio" value="yesterday" name="period" id="period-2" [(ngModel)]="period"
                           (ngModelChange)="ChangeDate()" [disabled]="disabled" >
                    <label for="period-2">Вчера</label>
                </div>
                <div class="radio btn btn-sm">
                    <input type="radio" value="7_day" name="period" id="period-3" [(ngModel)]="period"
                           (ngModelChange)="ChangeDate()" [disabled]="disabled" >
                    <label for="period-3">Неделя</label>
                </div>
                <div class="radio btn btn-sm">
                    <input type="radio" value="1_month" name="period" id="period-4" [(ngModel)]="period"
                           (ngModelChange)="ChangeDate()" [disabled]="disabled" >
                    <label for="period-4">Месяц</label>
                </div>
                <!--<div class="radio btn btn-sm">-->
                    <!--<input type="radio" value="3_month" name="period" id="period-5" [(ngModel)]="period"-->
                           <!--(ngModelChange)="ChangeDate()" [disabled]="disabled" >-->
                    <!--<label for="period-5">Квартал</label>-->
                <!--</div>-->
                <!--<div class="radio btn btn-sm">-->
                    <!--<input type="radio" value="1_year" name="period" id="period-6" [(ngModel)]="period"-->
                           <!--(ngModelChange)="ChangeDate()" [disabled]="disabled" >-->
                    <!--<label for="period-6">Год</label>-->
                <!--</div>-->
                <!--<div class="radio btn btn-sm" >-->
                    <!--<input type="radio" value="day" name="period" id="period-7" [(ngModel)]="period"-->
                           <!--[disabled]="disabled">-->
                    <!--<label for="period-7">-->
                        <!--<clr-icon shape="calendar" size="16"></clr-icon>-->
                        <!--{{startDateButton}}-->
                        <!--<ng-container *ngIf="period !== 'today'">-</ng-container>-->
                        <!--{{stopDateButton}}-->
                    <!--</label>-->
                <!--</div>-->
            </div>
            <!--<ng-container *ngIf="period === 'day'">-->
                <!--<label>-->
                    <!--От:-->
                    <input type="text" size="10" clrDate [ngModel]="dateFrom" (ngModelChange)="ChangeFromDate($event)"
                           [ngModelOptions]="{standalone: true}" [disabled]="true" >
                <!--<input-->
                  <!--type="text"-->
                  <!--#dp="ngx-mydatepicker"-->
                  <!--ngx-mydatepicker-->
                  <!--style="float: none;"-->
                  <!--placeholder="От"-->
                  <!--[(ngModel)]="dateFrom"-->
                  <!--[ngModelOptions]="{standalone: true}"-->
                <!--&gt;-->
                <!--</label>-->
                <!--<label>-->
                    <!--До:-->
                    <input type="text" size="10" clrDate [ngModel]="dateTo" (ngModelChange)="ChangeToDate($event)"
                           [ngModelOptions]="{standalone: true}" [disabled]="true" >
                <!--</label>-->
            <!--</ng-container>-->
        </div>
    </form>
</ng-container>
<ng-template #loading>
    Загрузка ...
</ng-template>
