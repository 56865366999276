import { OnInit } from '@angular/core';
import { IntegrationFormService } from '../../services/integration-form.service';
var ImportCrmSectionComponent = /** @class */ (function () {
    function ImportCrmSectionComponent(integrationForm) {
        this.integrationForm = integrationForm;
        this.loaded = false;
        this.wwww = '';
    }
    Object.defineProperty(ImportCrmSectionComponent.prototype, "form", {
        get: function () {
            return this.integrationForm.formService.getControl('amoImport', this.section.code);
        },
        enumerable: true,
        configurable: true
    });
    ImportCrmSectionComponent.prototype.ngOnInit = function () {
        console.log('amoImport', this.section.code);
        // this.form = this.integrationForm.formService.getControl('amoImport', this.section.code) as FormGroup;
        console.log(this.form);
    };
    ImportCrmSectionComponent.prototype.ToggleSettings = function () {
        this.settingsOpened = !this.settingsOpened;
    };
    return ImportCrmSectionComponent;
}());
export { ImportCrmSectionComponent };
