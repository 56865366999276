/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { FacebookResponseWrapperIEnumerablePostMonitorBackend_V2ControllersFacebookFacebookAdAccountController } from '../models/facebook-response-wrapper-ienumerable-post-monitor-backend-_v2controllers-facebook-facebook-ad-account-controller';
@Injectable({
  providedIn: 'root',
})
class FacebookAdAccountService extends __BaseService {
  static readonly FacebookGetAdAccountsAsyncPath = '/apiv2/facebook/ad-account';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `FacebookAdAccountService.FacebookGetAdAccountsAsyncParams` containing the following parameters:
   *
   * - `summary`:
   *
   * - `pagingBefore`:
   *
   * - `pagingAfter`:
   *
   * - `limit`:
   *
   * - `fields`:
   *
   * @return Success
   */
  FacebookGetAdAccountsAsyncResponse(params: FacebookAdAccountService.FacebookGetAdAccountsAsyncParams): __Observable<__StrictHttpResponse<FacebookResponseWrapperIEnumerablePostMonitorBackend_V2ControllersFacebookFacebookAdAccountController>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.summary || []).forEach(val => {if (val != null) __params = __params.append('summary', val.toString())});
    if (params.pagingBefore != null) __params = __params.set('pagingBefore', params.pagingBefore.toString());
    if (params.pagingAfter != null) __params = __params.set('pagingAfter', params.pagingAfter.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    (params.fields || []).forEach(val => {if (val != null) __params = __params.append('fields', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/facebook/ad-account`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FacebookResponseWrapperIEnumerablePostMonitorBackend_V2ControllersFacebookFacebookAdAccountController>;
      })
    );
  }
  /**
   * @param params The `FacebookAdAccountService.FacebookGetAdAccountsAsyncParams` containing the following parameters:
   *
   * - `summary`:
   *
   * - `pagingBefore`:
   *
   * - `pagingAfter`:
   *
   * - `limit`:
   *
   * - `fields`:
   *
   * @return Success
   */
  FacebookGetAdAccountsAsync(params: FacebookAdAccountService.FacebookGetAdAccountsAsyncParams): __Observable<FacebookResponseWrapperIEnumerablePostMonitorBackend_V2ControllersFacebookFacebookAdAccountController> {
    return this.FacebookGetAdAccountsAsyncResponse(params).pipe(
      __map(_r => _r.body as FacebookResponseWrapperIEnumerablePostMonitorBackend_V2ControllersFacebookFacebookAdAccountController)
    );
  }
}

module FacebookAdAccountService {

  /**
   * Parameters for FacebookGetAdAccountsAsync
   */
  export interface FacebookGetAdAccountsAsyncParams {
    summary?: Array<string>;
    pagingBefore?: string;
    pagingAfter?: string;
    limit?: number;
    fields?: Array<string>;
  }
}

export { FacebookAdAccountService }
