import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {IPromopostMetaDataViewModel} from '../../models/promopost-meta-data.view-model';

@Component({
  selector: 'app-promopost-meta-data',
  templateUrl: './promopost-meta-data.component.html',
  styleUrls: ['./promopost-meta-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PromopostMetaDataComponent implements OnInit {

  @Input() public promopostMeta: IPromopostMetaDataViewModel;

  public get meta() {
    return {
      avatar: this.promopostMeta ? this.promopostMeta.avatar : 'https://via.placeholder.com/50',
      groupName: this.promopostMeta ? this.promopostMeta.groupName : 'Сообщество не выбрано'
    };
  }

  constructor() {
  }

  ngOnInit() {
  }

}
