<div class="strategy-input" *ngFor="let userStrategyInput of userStrategyInputs">
  <div class="strategy-input__title">{{GetFormatedUserStrategyInputTitle(userStrategyInput.type.title)}}</div>
  <div class="strategy-input__value">
    <ng-container [ngSwitch]="userStrategyInput.type.frontendInputType">
      <ng-container *ngSwitchCase="'number'">
        {{GetInputValue(userStrategyInput) | number:'1.0-2'}}
      </ng-container>
      <ng-container *ngSwitchCase="'time_of_day_in_minutes'">
        {{userStrategyInput.value | minutesToTime}}
      </ng-container>
      <ng-container *ngSwitchCase="'datetimepicker'">
        {{userStrategyInput.value | secondsToDate | utcToLocalDate:"DD MMM YYYY [в] HH:mm"}}
      </ng-container>
      <ng-container *ngSwitchCase="'checkbox'">
        {{userStrategyInput.value === 1 ? 'Да' : 'Нет'}}
      </ng-container>
      <ng-container *ngSwitchCase="'select'">
        {{GetSelectedOptionValue(userStrategyInput)}}
      </ng-container>
    </ng-container>
    {{userStrategyInput.type.placeholder}}
  </div>
</div>
