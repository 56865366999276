import {Component, Input, OnInit} from '@angular/core';
import {FormArray, FormControl, FormGroup} from "@angular/forms";
import {CopyingService} from "../../../services/copying.service";

@Component({
  selector: 'app-copying-by-sex-and-age',
  templateUrl: './copying-by-sex-and-age.component.html',
  styleUrls: ['./copying-by-sex-and-age.component.scss']
})
export class CopyingBySexAndAgeComponent implements OnInit {

  public form: FormGroup = null;

  public get ages() {
    return this.form ? (this.form.get('ageItems') as FormArray).controls : [];
  }

  constructor(
    private copyingService: CopyingService
  ) {
  }

  ngOnInit() {
    this.form = this.copyingService.form.get('sexAndAgeConfiguration') as FormGroup;
  }

  public addControl() {
    this.copyingService.addSexAndAgeGroup()
  }

  public removeControl(i) {
    this.copyingService.removeSexAndAgeGroup(i);
  }

}
