import {Component, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {AdFormManagerService} from '../../../new-ad-manager-shared/services/ad-form-manager/ad-form-manager.service';

@Component({
  selector: 'app-new-promopost-form-lite',
  templateUrl: './new-promopost-form-lite.component.html',
  styleUrls: ['./new-promopost-form-lite.component.scss']
})
export class NewPromopostFormLiteComponent implements OnInit {

  public controlsWidth: string = '256px';
  public labelsWidth: string = '140px';
  public IsAdToGroup: boolean = true;

  public get form(): FormGroup {
    return this.adFormManagerService.adForm.controls.promopost as FormGroup;
  }

  public get adminGroups() {
    return this.adFormManagerService.adminGroups;
  }

  public get label() {
    return this.adFormManagerService.promopostLabelTextForm;
  }

  constructor(
    private adFormManagerService: AdFormManagerService
  ) {
  }

  ngOnInit() {
  }

  public ImageSelected(images: any) {
    this.adFormManagerService.AddPromopostImage(images);
  }

  public HasAdminOpenedGroups() {
    return this.adFormManagerService.hasOpenedAdminGroups();
  }

  public textLength(i) {
    return 600 - this.adFormManagerService.getControl('promopost', 'text', i.toString()).value.length;
  }
}
