import { Injectable } from '@angular/core';
import {UserExperienceLevelService} from '../../api/services/user-experience-level.service';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ExperienceLevelBasedDashboardRedirectionService {
  public showExperienceLevelDialog = false;

  private localStorageKey: string = 'experience-targeting-experience-level-id';

  constructor( private userExperienceApi: UserExperienceLevelService, private router: Router,
               private activeRoute: ActivatedRoute) { }

  public async GetUserTargetingLevel(useLocalStorageCahce: boolean = true): Promise<number> {
    try {
      if (useLocalStorageCahce) {
        const localStoredExperienceId = localStorage.getItem(this.localStorageKey);

        if (localStoredExperienceId) {
          return parseInt(localStoredExperienceId, 10);
        }
      }
    } catch (ex) {

    }

    try {
      const request = this.userExperienceApi.GetUserExperienceLevel([1]).toPromise();

      const response = await request;

      if (response && response.data && response.data.length) {
        return response.data[0].experienceLevelId;
      }
    } catch (ex) {

    }

    return null;
  }

  public ReactOnuserTargetingLevel(level: number, redirect: boolean = true) {
    if (level === null) {
      // this.showExperienceLevelDialog = true;
    } else {

      try {
        localStorage.setItem(this.localStorageKey, level.toString()); // Save level, so next request can be faster
      } catch (ex) {

      }

      if (redirect) {
        this.RedirectToDashboardBasedOnExperienceLevel(level);
      }
    }
  }

  public CustomRedirectBasedOnMod(noviceMode: boolean, noviceMod: string, proMod: string, keepQuery: boolean= false): void {
    this.router.navigate([(noviceMode) ? noviceMod : proMod],  {
      queryParamsHandling: ((keepQuery) ? 'merge' : null)
    });
  }

  // Will deprecate in future releses
  public CheckUserTargetingExperienceLevel(redirect: boolean = true): void {
    let experienceLevelId: number = null;

    // TODO Задуматься над тем, чтобы добавить кешинг в localStorage
    try {
      const localStoredExperienceId = localStorage.getItem(this.localStorageKey);

      if (localStoredExperienceId) {
        console.log('experienceLevelId', experienceLevelId);
        experienceLevelId = parseInt(localStoredExperienceId, 10);
      }
    } catch (ex) {

    }
    console.log('So experienceLevelId', experienceLevelId);

    if (experienceLevelId === null) {
      this.userExperienceApi.GetUserExperienceLevel([1]).subscribe(response => {
        let level = null;

        if (response && response.data && response.data.length) {
          level = response.data[0].experienceLevelId;
        }

        if (level != null) {
          // this.RedirectToDashboardBasedOnExperienceLevel(level);
        } else {
          this.showExperienceLevelDialog = true;
        }
      }, error => {
        // this.RedirectToDashboardBasedOnExperienceLevel(2); // If request fails = redirect to professinoal desahboard
      });
    } else {
      if (redirect) {
        this.RedirectToDashboardBasedOnExperienceLevel(experienceLevelId);
      }
    }
  }

  public RedirectToDashboardBasedOnExperienceLevel(experienceLevelId: number): void {
    let navigateToRoute = '';

    switch (experienceLevelId) {
      case 1:
        navigateToRoute = '/automation/dashboard';
        break; // When use is novice in ads targeting
      default:
        navigateToRoute = '/dashboard';
        break; // When user is the professional or other level chosed
    }

    this.router.navigate([navigateToRoute], { queryParamsHandling: "merge" });
  }
}
