import {FormGroup} from "@angular/forms";

export function CrmSectionValidator(sectionGroup: FormGroup) {
  let status = false;
  const errors = {
    errors: {}
  };

  if (sectionGroup.get('selected').value) {
    const fieldsGroup = sectionGroup.get('fields') as FormGroup;

    Object
      .keys(fieldsGroup.controls)
      .map(key => ({key, fieldControl: fieldsGroup.controls[key]}))
      .forEach(({key, fieldControl}) => {
        const controlValid = !isNullOrUndefined(fieldControl.value) && fieldControl.valid;
        status = status || controlValid;
        if (fieldControl.errors) {
          errors.errors[key] = fieldControl.errors;
        }
      });

    if (!status) {
      return errors;
    }
  }

  return true;
}

/**
 * Равно ли значение переменной null или undefined
 * @param val
 */
function isNullOrUndefined(val) {
  return val === null || val === undefined;
}
