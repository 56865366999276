<form [formGroup]="controlContainer.control">
  <div class="promopost-lite-form">
    <div class="promopost-lite-form__left">
      <a
        rel="nofollow noopener"
        style="text-align:right; margin: 0; margin-bottom: 8px;"
        target="_blank"
        href="/vk/hidden"
        class="btn btn-success btn-sm"
      >
        Посмотреть примеры объявлений
      </a>

      <ng-container [ngSwitch]="CountOfOpenedGroups()" *ngIf="!isUpdating">
        <ng-container *ngSwitchCase="0">
          <div class="alert alert-danger alert_ad-form-lite">
            <div class="alert-items">
              <div class="alert-item static">
                <div class="alert-text">
                  Нет ни одной группы для создания объявления.
                  Вам необходимо создать группу и вернуться на данную страницу.
                  Примечание: Не возможно создать объявление если у вас нет группы или она закрытая.
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="1"></ng-container>
        <ng-container *ngSwitchDefault>
          <vk-form-field [labelWidth]="labelWidth" [controlsWidth]="controlsWidth">
            <vk-label>Выберите сообщество</vk-label>
            <select vkInput formControlName="groupId">
              <ng-container *ngFor="let group of groups">
                <option [value]="group.id">{{group.name}}</option>
              </ng-container>
            </select>
            <vk-error validator="required" name="groupId">Вы не выбрали группу</vk-error>
          </vk-form-field>
        </ng-container>
      </ng-container>

      <vk-form-field [labelWidth]="labelWidth" [controlsWidth]="controlsWidth" *ngIf="ShowLinkUrl">
        <vk-label>Введите ссылку на сайт</vk-label>
        <input type="url" id="website_promopost_link_address" vkInput formControlName="linkAddress">
        <vk-error validator="required" name="linkAddress">Вы не заполнили поле</vk-error>
        <vk-error validator="urlHttp" name="linkAddress">
          Ссылка обязательно должна содержать http:// или https://
        </vk-error>
      </vk-form-field>

      <vk-form-field [labelWidth]="labelWidth" [controlsWidth]="controlsWidth">
        <vk-label>
          <div>Текст объявления</div>
          <div class="label-alert" [innerHTML]="getEmojjiContent()">

          </div>
        </vk-label>
        <textarea
          id="website_promopost_text"
          vkInput
          [multiline]="true"
          [style.height]="TextAreaDefaultHeight"
          formControlName="text"
          placeholder='Введите текст объявления. Примеры текстов вы можете посмотреть нажав выше кнопку “Посмотреть примеры объявлений"'
        ></textarea>
        <vk-error validator="required" name="text">Вы не вели текст</vk-error>
        <vk-error validator="minlength" name="text">Минимальная длинна текста 1 символ</vk-error>
        <vk-error validator="maxlength" name="text">Максимальная длинна текста 650 символа</vk-error>
        <vk-error validator="emojiCount" name="text">Текст рекламной записи не должен содержать более 6 символов emoji</vk-error>
      </vk-form-field>
    </div>
    <app-promopost
      [promopost]="controlContainer.value"
      [text]="controlContainer.value.text"
      [image]="controlContainer.value.image"
      (linkTitleChanged)="OnSaveLinkTitle($event)"
      [selectedGroup]="selectedGroup"
      [showEditForm]="true"
    >
    </app-promopost>
  </div>
  <ng-container *ngIf="controlContainer.control.get('image').invalid && controlContainer.control.get('image').dirty">
    <div class="vk-error">Вы не выбрали изображение для вашего объявления</div>
  </ng-container>
  <app-images-manager
    (selected)="OnSelectImage($event)"
    [minWidth]="537"
    [minHeight]="240"
  >
  </app-images-manager>
</form>

