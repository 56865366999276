<h1>Данные пользователя</h1>
<h2>Комментарии</h2>
<app-comment-view [comment]="comment" *ngFor="let comment of comments"></app-comment-view>
<app-comment-form (commentSaved)="SaveComment($event)"></app-comment-form>
<hr>
<h2>Общие</h2>
<form [formGroup]="form" (submit)="SaveGeneralInformation($event)" class="fx-row">
  <div class="form-block">
    <label for="role">Специальность</label>
    <br>
    <div class="select">
      <select formControlName="roleId" id="role">
        <option
          *ngFor="let role of roles"
          [selected]="form.value.roleId === role.id"
          [value]="role.id"
        >
          {{role.title}}
        </option>
      </select>
    </div>
  </div>
  <div class="form-block">
    <label for="role">Статус</label>
    <br>
    <div class="select">
      <select formControlName="leadStatusId" id="status">
        <option
          *ngFor="let status of statuses"
          [selected]="form.value.leadStatusId === status.id"
          [value]="status.id"
        >
          {{status.title}}
        </option>
      </select>
    </div>
  </div>
  <button class="btn btn-sm">Сохранить</button>
</form>
<hr>

<h2>Звонки</h2>
<div class="fx-row">
  <button class="btn btn-sm" (click)="ToggleCallForm()">Запланировать звонок</button>
  <button class="btn btn-primary btn-sm" (click)="ToggleInteressForms()">Изменить интересы</button>
</div>

<call-data-list *ngIf="user" [calls]="user.calls"></call-data-list>
<ngr-data-grid
  [data]="user.calls"
  *ngIf="user"
>
  <ngr-dg-column
    name="callDate"
    header="Дата звонка"
    width="150px"
  >
    <div *ngrDgContent="let cellData=cell">
      {{cellData | utcToLocalDate | momentDate:"DD MMMM YYYY HH:mm"}}
    </div>
  </ngr-dg-column>
  <ngr-dg-column name="comment" header="Комментарий"></ngr-dg-column>
</ngr-data-grid>
<hr>

<clr-modal [(clrModalOpen)]="interessFormOpened">
  <div class="modal-body">
    <div class="select">
      <select (change)="SelectLeadDetail($event.target.value)">
        <ng-container *ngFor="let option of leadDetailsInterests">
          <option [value]="option.id">{{option.title}}</option>
        </ng-container>
      </select>
    </div>
    <form [formGroup]="formInterests" (submit)="SaveInterests($event)">
      <div formArrayName="items">
        <ng-container *ngFor="let item of formInterests.controls.items?.controls; let i = index">
          <div [formGroupName]="i" class="fx-row">
            <mat-checkbox formControlName="selected">{{selectedLeadDetail.items[i].title}}</mat-checkbox>
            <ng-container *ngIf="selectedLeadDetail.items[i].canBeCommented">
              <input type="text" formControlName="comment">
            </ng-container>
          </div>
        </ng-container>
      </div>
      <button class="btn btn-primary">Сохранить</button>
    </form>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="callFormOpened">
  <div class="modal-body">
    <form [formGroup]="formCall">
      <div class="form-block">
        <label>Дата звонка</label>
        <app-datepicker formControlName="callDate"></app-datepicker>
      </div>
      <div class="form-block">
        <label>Комментарий</label>
        <textarea formControlName="comment"></textarea>
      </div>
      <button class="btn btn-primary" (click)="SaveCall()">Сохранить</button>
    </form>
  </div>
</clr-modal>
