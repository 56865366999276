import { BehaviorSubject } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { MatDialog } from "@angular/material/dialog";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/material/dialog";
var BannersService = /** @class */ (function () {
    function BannersService(http, dialog) {
        this.http = http;
        this.dialog = dialog;
        this.baseUrl = environment.backendUri + "/" + environment.apiPrefix + "/system-banners";
        this.banners = new BehaviorSubject([]);
    }
    Object.defineProperty(BannersService.prototype, "banners$", {
        get: function () {
            return this.banners.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    BannersService.prototype.LoadAllBanners = function () {
        // this.http.get(this.baseUrl)
        //   .subscribe((response: any) => {
        //     const banners = response.data;
        //     this.banners.next(banners);
        //     if (banners.length > 0) {
        //       this.dialog
        //         .open(BannerDialogComponent)
        //         .afterClosed()
        //         .subscribe(data => {
        //           this.banners.getValue()
        //             .map(x => {
        //               this.ConfirmViewByUser(x.id);
        //             });
        //         });
        //     }
        //   })
    };
    BannersService.prototype.ConfirmViewByUser = function (bannerId) {
        var params = new HttpParams();
        params.append('bannerId', bannerId.toString());
        console.log(params);
        this.http.post(this.baseUrl + "?bannerId=" + bannerId, null)
            .subscribe(function (data) {
            console.log(data);
        });
    };
    BannersService.ngInjectableDef = i0.defineInjectable({ factory: function BannersService_Factory() { return new BannersService(i0.inject(i1.HttpClient), i0.inject(i2.MatDialog)); }, token: BannersService, providedIn: "root" });
    return BannersService;
}());
export { BannersService };
