import {TariffPlanOptionSet} from './tariff-plan-option-set';
import {TariffPlanOptionTimeValue} from "./tariff-plan-option-time-value";
import {Promocode} from "../../api/models/promocode";

export class TariffPlanViewModel {
  public id: number;

  public code: string;

  public title: string;

  public description: string;

  public configurations: Array<TariffPlanOptionSet>;

  public selectedOptionId: number = 0;

  public selectedTimeId: number = 0;

  public optionTimes: Array<TariffPlanOptionTimeValue> = [];

  public selectedOption: TariffPlanOptionSet = null;
  public selectedTime: TariffPlanOptionTimeValue = null;

  public appliedPromocode: Promocode;
  public promocodeMessage: string = '';
  public promocodeMessageIsError: boolean = false;

  public SelectOption(optionId: number) {
    const option = this.configurations.find(x => x.optionId === optionId);

    if (option) {
      this.selectedOption = option;
      this.optionTimes = option.times;
    }
  }

  public SelectTime(timeId: number) {
    this.selectedTime = this.optionTimes.find(x => x.timeId === timeId);
  }
}
