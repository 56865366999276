<div class="fx-row">
  <h1 matDialogTitle>Детали модерации</h1>
  <div class="fx-divider"></div>
  <button mat-dialog-close mat-button mat-icon-button>
    <mat-icon>
      close
    </mat-icon>
  </button>
</div>
<div matDialogContent>
  {{data.comment}}
  <ng-container *ngFor="let rule of data.rules">
    <h4>{{rule.title}}</h4>
    <p *ngFor="let paragraph of rule.paragraphs" [innerHtml]="paragraph"></p>
  </ng-container>
</div>
