import {Injectable} from '@angular/core';
import {QueryEntity} from '@datorama/akita';
import {CrmPipelinesState, CrmPipelinesStore} from './crm-pipelines.store';
import {flatMap, map} from 'rxjs/operators';
import {AkitaNgFormsManager} from '@datorama/akita-ng-forms-manager';
import {isNullOrUndefined} from 'util';

@Injectable({providedIn: 'root'})
export class CrmPipelinesQuery extends QueryEntity<CrmPipelinesState> {

  /**
   *
   */
  public pipelines$ = this.selectAll()
    .pipe(map(pipelines => pipelines.sort((a, b) => a.sort - b.sort)));

  public pipelinesBySection$ = (section) => this.selectAll({filterBy: x => x.type === section})
      .pipe(
          map(x => x.sort((a, b) => a.sort - b.sort))
      );

  public get hasPipelines() {
    console.log('HAS PIPELINES', this.getAll(), this.getValue());
    return this.getAll().length > 0;
  }

  /**
   *
   */
  public selectedPipeLineStatuses$ = this.fm.selectValue('originPlatformSettings')
    .pipe(
      map(x => x.selectedPipelineId),
      flatMap(pipelineId => this.selectAll({
        filterBy: e => e.id === pipelineId
      })),
      map(x => x[0] ? x[0].statuses : [])
    );

  /**
   * Список всех статусов из воронки продаж с типом LEADS (Только для Битрикс24)
   * @return {CrmPipelineStatus[]} список этапов воронки продаж;
   */
  public leadsPipelineStatuses$ = this.pipelines$
    .map(pipelines => pipelines.find(pipeline => pipeline.type === 'LEADS'))
    .map(pipeline => pipeline ? pipeline.statuses : []);

  public pipelineSectionCodeById(pipelineId: number) {
    const pipeline = this.getAll()
      .find(x => x.id === pipelineId);
    let pipelineSectionCode = 'CONTACTS';
    if (pipeline != null) {
      pipelineSectionCode = pipeline.type;
    }

    return pipelineSectionCode;
  }

  /**
   * Getting entity id by pipeline id
   * @param pipelineId
   */
  public getEntityIdByPipelineId$(pipelineId) {
    const pipeline = this.getAll()
      .find(x => x.id === pipelineId);

    if (!isNullOrUndefined(pipeline)) {
      return pipeline.entity_id;
    } else {
      return 0;
    }
  }

  /**
   *
   * @param section
   * @param pipelineId
   * @constructor
   */
  public GetPipelineBySectionCode(section: string, pipelineId: string = null) {
    return this.getAll()
      .find(pipeline => pipelineId !== null ? pipeline.type === section && pipeline.id === pipelineId : pipeline.type === section);
  }

  constructor(
    protected store: CrmPipelinesStore,
    private fm: AkitaNgFormsManager
  ) {
    super(store);
  }

}
