/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./multiple-edit-success.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "@angular/common";
import * as i4 from "./multiple-edit-success.component";
var styles_MultipleEditSuccessComponent = [i0.styles];
var RenderType_MultipleEditSuccessComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MultipleEditSuccessComponent, data: {} });
export { RenderType_MultipleEditSuccessComponent as RenderType_MultipleEditSuccessComponent };
function View_MultipleEditSuccessComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "li", [["class", "properties-list__item"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", ": ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.key; var currVal_1 = _v.context.$implicit.value; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_MultipleEditSuccessComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵted(-1, null, ["\u0412\u044B\u0431\u0440\u0430\u043D\u043D\u044B\u0435 \u043E\u0431\u044A\u044F\u0432\u043B\u0435\u043D\u0438\u044F \u0443\u0441\u043F\u0435\u0448\u043D\u043E \u0438\u0437\u043C\u0435\u043D\u0435\u043D\u044B"])), (_l()(), i1.ɵeld(3, 0, null, null, 8, "div", [["class", "mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i1.ɵdid(4, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵted(-1, null, [" \u0411\u044B\u043B\u0438 \u0438\u0437\u043C\u0435\u043D\u0435\u043D\u044B \u0441\u043B\u0435\u0434\u0443\u044E\u0449\u0438\u0435 \u043F\u0430\u0440\u0430\u043C\u0435\u0442\u0440\u044B: "])), (_l()(), i1.ɵeld(6, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "ul", [["class", "properties-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MultipleEditSuccessComponent_1)), i1.ɵdid(9, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 1, "button", [["class", "vk-standart-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.Close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\u041E\u043A"]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_1 = _co.Data; _ck(_v, 9, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); }); }
export function View_MultipleEditSuccessComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-multiple-edit-success", [], null, null, null, View_MultipleEditSuccessComponent_0, RenderType_MultipleEditSuccessComponent)), i1.ɵdid(1, 114688, null, 0, i4.MultipleEditSuccessComponent, [i2.MAT_DIALOG_DATA, i2.MatDialogRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MultipleEditSuccessComponentNgFactory = i1.ɵccf("app-multiple-edit-success", i4.MultipleEditSuccessComponent, View_MultipleEditSuccessComponent_Host_0, {}, {}, []);
export { MultipleEditSuccessComponentNgFactory as MultipleEditSuccessComponentNgFactory };
