var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { map as __map, filter as __filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../api-configuration";
import * as i2 from "@angular/common/http";
var AutomationVkSlotService = /** @class */ (function (_super) {
    __extends(AutomationVkSlotService, _super);
    function AutomationVkSlotService(config, http) {
        return _super.call(this, config, http) || this;
    }
    /**
     * @return Success
     */
    AutomationVkSlotService.prototype.GetSlotsResponse = function () {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/automation-vk-slot", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @return Success
     */
    AutomationVkSlotService.prototype.GetSlots = function () {
        return this.GetSlotsResponse().pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `AutomationVkSlotService.AssignCabinetToSlotParams` containing the following parameters:
     *
     * - `id`:
     *
     * - `clientId`:
     *
     * @return Success
     */
    AutomationVkSlotService.prototype.AssignCabinetToSlotResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.id != null)
            __params = __params.set('id', params.id.toString());
        if (params.clientId != null)
            __params = __params.set('clientId', params.clientId.toString());
        var req = new HttpRequest('POST', this.rootUrl + "/apiv2/automation-vk-slot/cabinet", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `AutomationVkSlotService.AssignCabinetToSlotParams` containing the following parameters:
     *
     * - `id`:
     *
     * - `clientId`:
     *
     * @return Success
     */
    AutomationVkSlotService.prototype.AssignCabinetToSlot = function (params) {
        return this.AssignCabinetToSlotResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `AutomationVkSlotService.UnBindCabinetFromSlotParams` containing the following parameters:
     *
     * - `id`:
     *
     * - `clientId`:
     *
     * @return Success
     */
    AutomationVkSlotService.prototype.UnBindCabinetFromSlotResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.id != null)
            __params = __params.set('id', params.id.toString());
        if (params.clientId != null)
            __params = __params.set('clientId', params.clientId.toString());
        var req = new HttpRequest('DELETE', this.rootUrl + "/apiv2/automation-vk-slot/cabinet", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `AutomationVkSlotService.UnBindCabinetFromSlotParams` containing the following parameters:
     *
     * - `id`:
     *
     * - `clientId`:
     *
     * @return Success
     */
    AutomationVkSlotService.prototype.UnBindCabinetFromSlot = function (params) {
        return this.UnBindCabinetFromSlotResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @return Success
     */
    AutomationVkSlotService.prototype.GetAssignedAccountsResponse = function () {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/automation-vk-slot/cabinets/bined", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @return Success
     */
    AutomationVkSlotService.prototype.GetAssignedAccounts = function () {
        return this.GetAssignedAccountsResponse().pipe(__map(function (_r) { return _r.body; }));
    };
    AutomationVkSlotService.GetSlotsPath = '/apiv2/automation-vk-slot';
    AutomationVkSlotService.AssignCabinetToSlotPath = '/apiv2/automation-vk-slot/cabinet';
    AutomationVkSlotService.UnBindCabinetFromSlotPath = '/apiv2/automation-vk-slot/cabinet';
    AutomationVkSlotService.GetAssignedAccountsPath = '/apiv2/automation-vk-slot/cabinets/bined';
    AutomationVkSlotService.ngInjectableDef = i0.defineInjectable({ factory: function AutomationVkSlotService_Factory() { return new AutomationVkSlotService(i0.inject(i1.ApiConfiguration), i0.inject(i2.HttpClient)); }, token: AutomationVkSlotService, providedIn: "root" });
    return AutomationVkSlotService;
}(__BaseService));
export { AutomationVkSlotService };
