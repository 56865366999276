<h1>Пользователи</h1>

<app-new-date-filter
  [dateFrom]="usersListPeriod.dateFrom"
  [period]="usersListPeriod.period"
  [dateTo]="usersListPeriod.dateTo"

  (dateChanged)="DateChanged($event)"
></app-new-date-filter>

<ng-container *ngIf="!loading; else loadingScreen">
  <ng-container *ngIf="users?.length > 0; else emptyData">
    <ngr-dg-action-bar>
      <div class="action-bar">
        <div class="action-bar__action">

        </div>
        <div class="action-bar__divider"></div>
        <div class="action-bar__action">
          <button class="btn btn-primary" (click)="dg.ExportToExcel()">Выгрузить пользователей в excel</button>
        </div>
      </div>
    </ngr-dg-action-bar>

    <ngr-data-grid
      [data]="users"
      [currentPage]="currentPage"
      [parent]="context"
      #dg
    >
      <ngr-dg-column name="id" header="#">
        <div *ngrDgContent="let user = row">
          <span class="new" *ngIf="IsNew(user)">new</span>
          <a [routerLink]="[user.id, 'comments']" target="_blank" rel="nofollow, noopener">{{user.id}}</a>
        </div>
      </ngr-dg-column>
      <ngr-dg-column name="email" header="Email"></ngr-dg-column>
      <ngr-dg-column name="name" header="Имя" [loading]="false">
        <div *ngrDgContent="let cell = cell">
          <ng-container *ngIf="cell; else emptyField">{{cell}}</ng-container>
        </div>
      </ngr-dg-column>
      <ngr-dg-column name="registrationDate" header="Дата регистрации" type="date">
        <div *ngrDgContent="let date = cell">
          {{date | date:'medium'}}
        </div>
      </ngr-dg-column>
      <ngr-dg-column name="phone" header="Телефон" [loading]="false">
        <div *ngrDgContent="let cell = cell">
          <ng-container *ngIf="cell; else emptyField">{{cell}}</ng-container>
        </div>
      </ngr-dg-column>
      <ngr-dg-column name="vkId" header="Ссылка на профиль вк">
        <div *ngrDgContent="let vk = cell">
          <ng-container *ngIf="vk; else emptyField">
            <a [href]="'https://vk.com/id'+vk" target="_blank" rel="nofollow, norefferer">https://vk.com/id{{vk}}</a>
          </ng-container>
        </div>
      </ngr-dg-column>
      <ngr-dg-column
        name="level"
        header="Профи/Новичек"
        [filterOptions]="profiOrNoob"
        filterType="select "
      >
        <div *ngrDgContent="let level=cell;">
          <ng-container *ngIf="level; else emptyField">
            {{level}}
          </ng-container>
        </div>
      </ngr-dg-column>
      <ngr-dg-column name="roleName" header="Должность">
        <div *ngrDgContent="let user = row;">
          {{user?.lead?.role?.title}}
        </div>
      </ngr-dg-column>
      <ngr-dg-column name="leadStatusName" header="Статус">
        <div *ngrDgContent="let user = row;">
          {{user?.lead?.leadStatus?.title}}
        </div>
      </ngr-dg-column>
      <ngr-dg-column
        name="nextContactDate"
        header="Дата следущего контакта"
        type="number"
        filterType="custom"
        [sortFunction]="SortByLastCallFunction"
        [filterFunction]="FilterByCall"
      >
        <div *appDgColumnFilter>
          <mat-select [formControl]="leadCallsStatus">
            <mat-option [value]="0">Все</mat-option>
            <ng-container *ngFor="let callStatus of leadCallsStatuses$ | async">
              <mat-option [value]="callStatus.id">{{callStatus.title}}</mat-option>
            </ng-container>
          </mat-select>
        </div>
        <div *ngrDgContent="let date=cell;let user=row">
          <ng-container *ngIf="user?.lead?.calls.length > 0">
            <app-user-calls [userCalls]="getUserCalls(user)" [callStatuses]="leadCallsStatuses$" (callStatusChanged)="UserStatustChanged()"></app-user-calls>
          </ng-container>
          <button class="btn btn-sm btn-primary" (click)="CreateForm(user.id)">Добавить звонок</button>
        </div>
      </ngr-dg-column>
      <ngr-dg-column name="countOfFilledSlots" header="Колличество активированных кабинетов" filterType="between">
        <div *ngrDgContent="let countOfActivated = cell">
          {{countOfActivated}}
        </div>
      </ngr-dg-column>
      <ngr-dg-column name="isBindedAdCabinet" header="Активировал рекламный кабинет" filterType="select" [filterOptions]="activeAdCabinet">
        <div *ngrDgContent="let isActivated=cell">
          <ng-container *ngIf="isActivated; else disabled">
            <div class="ion-md-checkmark"></div>
          </ng-container>
        </div>
      </ngr-dg-column>

      <ngr-dg-column name="isBindedYandexMetrika" header="Подключил Яндекс.Метрику" filterType="select" [filterOptions]="yandexMetrikaOptions">
        <div *ngrDgContent="let isActivated=cell">
          <ng-container *ngIf="isActivated; else disabled">
            <div class="ion-md-checkmark"></div>
          </ng-container>
        </div>
      </ngr-dg-column>

      <ngr-dg-column name="createdStrategies" header="Создано стратегий">
        <div *ngrDgContent="let created=cell">{{created}}</div>
      </ngr-dg-column>

      <ngr-dg-column name="requestsPerDayGroups" header="Колличество запросов(Группы)" filterType="between">
        <div *ngrDgContent="let created=cell">{{created}}</div>
      </ngr-dg-column>
      <ngr-dg-column name="requestsPerDayPosts" header="Колличество запросов(Посты)" filterType="between">
        <div *ngrDgContent="let created=cell">{{created}}</div>
      </ngr-dg-column>
      <ngr-dg-column name="requestsPerDayPromoPosts" header="Колличество запросов(Промо)" filterType="between">
        <div *ngrDgContent="let created=cell">{{created}}</div>
      </ngr-dg-column>
      <ngr-dg-column name="requestsPerDayAutomation" header="Колличество запросов(РК)" filterType="between">
        <div *ngrDgContent="let created=cell">{{created}}</div>
      </ngr-dg-column>

      <ngr-dg-column name="lastActivityAd" header="Последняя активность(РК)" type="date">
        <div *ngrDgContent="let lastActivity=cell">
          <ng-container *ngIf="lastActivity != null; else emptyField">
            {{lastActivity | momentDate:"DD MMM YYYY [в] HH:mm"}}
          </ng-container>
        </div>
      </ngr-dg-column>

      <ngr-dg-column name="lastActivityPromo" header="Последняя активность(Промо)" type="date">
        <div *ngrDgContent="let lastActivity=cell">
          <ng-container *ngIf="lastActivity != null; else emptyField">
            {{lastActivity | momentDate:"DD MMM YYYY [в] HH:mm"}}
          </ng-container>
        </div>
      </ngr-dg-column>

      <ngr-dg-column name="lastActivityPosts" header="Последняя активность(Посты)" type="date">
        <div *ngrDgContent="let lastActivity=cell">
          <ng-container *ngIf="lastActivity != null; else emptyField">
            {{lastActivity | momentDate:"DD MMM YYYY [в] HH:mm"}}
          </ng-container>
        </div>
      </ngr-dg-column>

      <ngr-dg-column name="lastActivityGroups" header="Последняя активность(группы)" type="date">
        <div *ngrDgContent="let lastActivity=cell">
          <ng-container *ngIf="lastActivity != null; else emptyField">
            {{lastActivity | momentDate:"DD MMM YYYY [в] HH:mm"}}
          </ng-container>
        </div>
      </ngr-dg-column>

      <ngr-dg-column
        name="paymentsAmount"
        header="Количество платежей"
        filterType="between"
      >
        <div *ngrDgContent="let cell = cell">
          <ng-container *ngIf="cell != null; else emptyField">
            {{cell}}
          </ng-container>
        </div>
      </ngr-dg-column>

      <ngr-dg-column
        name="paymentConfirmedFunds"
        header="Сумма всех платежей"
        filterType="between"
      >
        <div *ngrDgContent="let cell = cell">
          <ng-container *ngIf="cell != null; else emptyField">
            {{cell}}
          </ng-container>
        </div>
      </ngr-dg-column>

      <ngr-dg-column
        name="expirationAnalytics"
        header="Дата истечения аналитики"
      >
        <div *ngrDgContent="let cell = cell">
          <ng-container *ngIf="cell != null; else emptyField">
            {{cell | momentDate:"DD MMM YYYY [в] HH:mm"}}
          </ng-container>
        </div>
      </ngr-dg-column>


      <ngr-dg-column
        name="expirationAutomation"
        header="Дата истечения автоматизации"
      >
        <div *ngrDgContent="let cell = cell">
          <ng-container *ngIf="cell != null; else emptyField">
            {{cell | momentDate:"DD MMM YYYY [в] HH:mm"}}
          </ng-container>
        </div>
      </ngr-dg-column>

      <ngr-dg-column
        name="countAvailableSlots"
        header="Колличество слотов"
        filterType="between"
      >
        <div *ngrDgContent="let cell = cell">
          <ng-container *ngIf="cell != null; else emptyField">
            {{cell}}
          </ng-container>
        </div>
      </ngr-dg-column>

      <ngr-dg-column
        name="CountOfFilledSlots"
        header="Колличество подключенных кабинетов"
        filterType="between"
      >
        <div *ngrDgContent="let cell = cell">
          <ng-container *ngIf="cell != null; else emptyField">
            {{cell}}
          </ng-container>
        </div>
      </ngr-dg-column>

      <ngr-dg-column
        name="isBindedVk"
        header="Подключил ВК"
      >
        <div *ngrDgContent="let cell = cell">
          <ng-container *ngIf="cell; else disabled">
            <div class="ion-md-checkmark"></div>
          </ng-container>
        </div>
      </ngr-dg-column>

      <ngr-dg-column
        name="paymentsConfirmedFirstDate"
        header="Дата первого подтвержденного платежа"
      >
        <div *ngrDgContent="let paymentDate = cell">
          <ng-container *ngIf="paymentDate; else emptyField">
            {{paymentDate  | momentDate:"DD MMM YYYY [в] HH:mm"}}
          </ng-container>
        </div>
      </ngr-dg-column>

      <ngr-dg-column
        name="paymentsConfirmedFirstFunds"
        header="Цена первого подтвержденного платежа"
        filterType="between"
      >
        <div *ngrDgContent="let paymentDate = cell">
          <ng-container *ngIf="paymentDate; else emptyField">
            {{paymentDate  | currency:'Руб.'}}
          </ng-container>
        </div>
      </ngr-dg-column>

      <ngr-dg-column
        name="paymentsConfirmedFirstTariff"
        header="Первый купленный тариф"
      >
        <div *ngrDgContent="let paymentDate = cell">
          <ng-container *ngIf="paymentDate; else emptyField">
            {{paymentDate}}
          </ng-container>
        </div>
      </ngr-dg-column>

      <ngr-dg-column
        name="paymentsConfirmedSecondDate"
        header="Дата второго подтвержденного платежа"
      >
        <div *ngrDgContent="let paymentDate = cell">
          <ng-container *ngIf="paymentDate; else emptyField">
            {{paymentDate  | momentDate:"DD MMM YYYY [в] HH:mm"}}
          </ng-container>
        </div>
      </ngr-dg-column>

      <ngr-dg-column
        name="paymentsConfirmedSecondFunds"
        header="Цена второго подтвержденного платежа"
      >
        <div *ngrDgContent="let paymentDate = cell">
          <ng-container *ngIf="paymentDate; else emptyField">
            {{paymentDate  | currency:'Руб.'}}
          </ng-container>
        </div>
      </ngr-dg-column>

      <ngr-dg-column
        name="paymentsConfirmedSecondTariff"
        header="Второй купленный тариф"
      >
        <div *ngrDgContent="let paymentDate = cell">
          <ng-container *ngIf="paymentDate; else emptyField">
            {{paymentDate}}
          </ng-container>
        </div>
      </ngr-dg-column>

      <ngr-dg-column
        name="paymentsConfirmedThirdDate"
        header="Дата третьего подтвержденного платежа"
      >
        <div *ngrDgContent="let paymentDate = cell">
          <ng-container *ngIf="paymentDate; else emptyField">
            {{paymentDate  | momentDate:"DD MMM YYYY [в] HH:mm"}}
          </ng-container>
        </div>
      </ngr-dg-column>

      <ngr-dg-column
        name="paymentsConfirmedThirdFunds"
        header="Цена третьего подтвержденного платежа"
      >
        <div *ngrDgContent="let paymentDate = cell">
          <ng-container *ngIf="paymentDate; else emptyField">
            {{paymentDate  | currency:'Руб.'}}
          </ng-container>
        </div>
      </ngr-dg-column>

      <ngr-dg-column
        name="paymentsConfirmedThirdTariff"
        header="Тертий купленный тариф"
      >
        <div *ngrDgContent="let paymentDate = cell">
          <ng-container *ngIf="paymentDate; else emptyField">
            {{paymentDate}}
          </ng-container>
        </div>
      </ngr-dg-column>

      <ngr-dg-column
        name="paymentsConfirmedFourthDate"
        header="Дата четвертого подтвержденного платежа"
      >
        <div *ngrDgContent="let paymentDate = cell">
          <ng-container *ngIf="paymentDate; else emptyField">
            {{paymentDate  | momentDate:"DD MMM YYYY [в] HH:mm"}}
          </ng-container>
        </div>
      </ngr-dg-column>

      <ngr-dg-column
        name="paymentsConfirmedFourthFunds"
        header="Цена четвертого подтвержденного платежа"
      >
        <div *ngrDgContent="let paymentDate = cell">
          <ng-container *ngIf="paymentDate; else emptyField">
            {{paymentDate  | currency:'Руб.'}}
          </ng-container>
        </div>
      </ngr-dg-column>

      <ngr-dg-column
        name="paymentsConfirmedFourthTariff"
        header="Четвертый купленный тариф"
      >
        <div *ngrDgContent="let paymentDate = cell">
          <ng-container *ngIf="paymentDate; else emptyField">
            {{paymentDate}}
          </ng-container>
        </div>
      </ngr-dg-column>

      <ngr-dg-column
        name="paymentsConfirmedFifthDate"
        header="Дата пятого подтвержденного платежа"
      >
        <div *ngrDgContent="let paymentDate = cell">
          <ng-container *ngIf="paymentDate; else emptyField">
            {{paymentDate  | momentDate:"DD MMM YYYY [в] HH:mm"}}
          </ng-container>
        </div>
      </ngr-dg-column>

      <ngr-dg-column
        name="paymentsConfirmedFifthFunds"
        header="Цена пятого подтвержденного платежа"
      >
        <div *ngrDgContent="let paymentDate = cell">
          <ng-container *ngIf="paymentDate; else emptyField">
            {{paymentDate  | currency:'Руб.'}}
          </ng-container>
        </div>
      </ngr-dg-column>

      <ngr-dg-column
        name="paymentsConfirmedFifthTariff"
        header="Пятый купленный тариф"
      >
        <div *ngrDgContent="let paymentDate = cell">
          <ng-container *ngIf="paymentDate; else emptyField">
            {{paymentDate}}
          </ng-container>
        </div>
      </ngr-dg-column>

      <ngr-dg-pagination
        [countOfPages]="CountOfPages"
        [currentPage]="1"
        [maxShowedPages]="10"
        #pagination
      ></ngr-dg-pagination>

    </ngr-data-grid>
  </ng-container>
</ng-container>

<ng-template #loadingScreen>
  <div class="loading-screen">
    <div class="loading-screen__spinner">
      <mat-spinner diameter="18"></mat-spinner>
    </div>
    <div class="loading-screen__message">Подождите идет загрузка данных</div>
  </div>
</ng-template>
<ng-template #emptyData>
  Данные по текущему запросу не найдены ...
</ng-template>


<ng-template #emptyField> -</ng-template>
<ng-template #disabled>
  <div class="ion-md-close"></div>
</ng-template>

<ng-template #dataIsEmpty>
  <div class="center">
    Нет данных за выбранный период.
  </div>
</ng-template>


<clr-modal [(clrModalOpen)]="callFormOpened">
  <div class="modal-body">
    <form [formGroup]="formCall">
      <div class="form-block">
        <label>Дата звонка</label>
        <app-datepicker formControlName="callDate"></app-datepicker>
      </div>
      <div class="form-block">
        <label>Комментарий</label>
        <textarea formControlName="comment"></textarea>
      </div>
      <button class="btn btn-primary" (click)="SaveCall()">Сохранить</button>
    </form>
  </div>
</clr-modal>
