<div class="images-manager">
  <div class="images-manager__images">
    <ng-container *ngFor="let image of files">
      <div class="images-manager__image">
        <app-new-ad-image
          [minHeight]="minHeight"
          [minWidth]="minWidth"
          [image]="image"
          [maxImageSize]="maxImageSize"
          [selected]="selectedImages"
          (selectedChange)="OnSelectImage($event)"
          (removeImage)="RemoveImage($event)"
        ></app-new-ad-image>
      </div>
    </ng-container>
    <div class="images-manager__trigger">
      <label class="images-manager-trigger">
        <div class="images-manager-trigger__icon">
          <mat-icon color="primary">add_a_photo</mat-icon>
        </div>
        <div class="images-manager-trigger__hidden">
          <input #file type="file" (change)="FilesSelected(file.files)" multiple accept="image/jpeg,image/png,image/bmp,image/tif,image/gif">
        </div>
      </label>
    </div>
  </div>
</div>

