import {Injectable} from '@angular/core';

@Injectable()
export class MathService {

  constructor() {
  }

  public GetAverageValue(data: Array<number>): number {
    if (!data == null || data.length === 0) {
      return 0;
    }

    if (data.length === 1) {
      return data[0];
    }

    return data.reduce((a, b) => a + b, 0) / data.length;
  }

  public GetMedianValue(data: Array<number>): number {
    if (!data || data.length === 0) {
      return 0;
    }

    if (data.length === 0) {
      return data[0];
    }
    // Sort array descending
    data.sort(function (a, b) {
      return b - a;
    });

    const middleIndex = Math.floor(data.length / 2);

    if (data.length % 2 === 0) {
      if (middleIndex - 1 >= 0 && middleIndex + 1 < data.length) {
        return (data[middleIndex - 1] + data[middleIndex + 1]) / 2;
      }
    }

    return data[middleIndex];
  }
}
