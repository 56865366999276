import {AfterViewInit, Component, ElementRef, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {TokenCheckerService} from './shared/services/token-checker.service';
import {environment} from '../environments/environment';

import * as Raven from 'raven-js';
import {ExperienceLevelBasedDashboardRedirectionService} from './shared/services/experience-level-based-dashboard-redirection.service';
import {BannersService} from './system-banners/services/banners.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit, OnInit {
  title = 'app';

  links = [];

  // Dperecated version of widget
  // private vkWidgetRootRouteCode = 'VK.Widgets.CommunityMessages("vk_community_messages", 150497232, {expandTimeout: "15000",tooltipButtonText: "Есть вопрос?"});';
  private vkWidgetRootRouteCode = 'VK.Widgets.CommunityMessages("vk_community_messages", 150497232, {tooltipButtonText: "Есть вопрос?"}); ';


  public ignore1280 = [
    'sign-in',
    'sign-up',
    'forgot-password',
    '/automation/accounts'
  ];

  public notStandartLink = [
    'sign-in',
    'sign-up'
  ];

  public showChatNotification: boolean = false;

  public noviceMod: boolean = false;

  public showRefrenceAtentuation: boolean = false;
  currentYear: number = new Date().getFullYear();

  public constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private tokenChecker: TokenCheckerService,
    private elementRef: ElementRef,
    public experienceRedirection: ExperienceLevelBasedDashboardRedirectionService,
    private bannersService: BannersService
  ) {
    // WWTF ???
    if (this.IsAuthorized) {

      this.showRefrenceAtentuation = localStorage.getItem('refrence-atentuator') !== 'false';

      // Mark that no refrence attentuator no longer needed

      this.experienceRedirection.GetUserTargetingLevel(false).then(x => {
        this.noviceMod = x === 1;

        if (this.noviceMod) {
          this.SwitchMenuToNoviceMode();
        } else {
          this.SwitchMenuToProMode();
        }
      });
    }


    this.router.events
      .filter(event => event instanceof NavigationEnd)
      .subscribe((event: NavigationEnd) => {
        // Redirect from PRO dashbaord to LITE & vice versa
        if (this.IsAuthorized) {
          if (event.url.indexOf('redefine-experience') < 0) {
            if (event.url.indexOf('dashboard') >= 0) {
              this.experienceRedirection.GetUserTargetingLevel(false).then(x => {
                this.noviceMod = x === 1;
                this.experienceRedirection.ReactOnuserTargetingLevel(x);

                if (this.noviceMod) {
                  this.SwitchMenuToNoviceMode();
                } else {
                  this.SwitchMenuToProMode();
                }
              });
            } else if (event.url.indexOf('payments-create') >= 0) {
              this.experienceRedirection.GetUserTargetingLevel(false).then(x => {
                this.noviceMod = x === 1;

                this.experienceRedirection.CustomRedirectBasedOnMod(this.noviceMod, '/easy-payments-create', '/payments-create');
              });
            }
          }
        }
      });
  }

  ngAfterViewInit() {
    if (environment.initVkWidget) {
      const scriptMessasges = document.createElement('script');
      scriptMessasges.type = 'text/javascript';
      scriptMessasges.innerHTML = this.vkWidgetRootRouteCode;
      this.elementRef.nativeElement.appendChild(scriptMessasges);
    }
    if (this.IsAuthorized) {
      this.bannersService.LoadAllBanners();
    }
  }

  private SwitchMenuToProMode(): void {
    this.links = [
      // {
      //   path: '/vk/group',
      //   title: 'Сообщества',
      //   is_new: false
      // },
      // {
      //   path: '/vk/post',
      //   title: 'Рекламные посты',
      //   is_new: false
      // },
      // {
      //   path: '/vk/hidden',
      //   title: 'Рекламные записи',
      //   is_new: false
      // },
      {
        path: '/automation/accounts',
        title: 'Автоматизация',
        is_new: false
      },
      {
        path: '/integrations',
        title: 'Интеграции',
        is_new: false
      },{
        path: '/new-payment',
        title: 'Оплата',
        is_new: false
      }
    ];
  }

  private SwitchMenuToNoviceMode(): void {
    this.links = [
      {
        path: '/automation/dashboard',
        title: 'Мои объявления',
        is_new: false
      },
      {
        path: '/vk/easy-stealth',
        title: 'Примеры объявлений',
        is_new: false
      },
      {
        path: '/easy-payments-create',
        title: 'Оплата',
        is_new: false
      }
    ];
  }

  public get ShowContainer1280() {
    const path = window.location.href.split('/');
    const currentPath = path[path.length - 1];
    const a = this.ignore1280.find(x => x.includes(currentPath));
    return !this.ignore1280.includes(currentPath);
  }

  public get LogoLink() {
    const path = window.location.href.split('/');
    const currentPath = path[path.length - 1];
    if (this.notStandartLink.includes(currentPath)) {
      return 'https://postmonitor.ru/';
    }
    return '/dashboard';
  }

  public get IsAuthorized() {
    return this.tokenChecker.IsAuthorized();
  }

  public Logout() {
    this.tokenChecker.RemoveToken();
    window.location.href = 'https://postmonitor.ru';
  }

  ngOnInit(): void {
    // const userData = JSON.parse(localStorage.getItem('user_meta'));
    // Raven.setUserContext({
    //   userId: userData.userId
    // });
  }
}
