import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AutomationVkService} from '../../../api/services/automation-vk.service';
import {Campaign} from '../../../api/models/campaign';
import {CampaignViewModel} from '../../models/view-models/campaign-view-model';
import {DateFilterViewModel} from '../../models/view-models/date-filter-view-model';
import {ClrDatagridPagination} from '@clr/angular';
import {DexieCampaignsService} from '../../../shared/services/dexie/dexie-campaigns.service';
import {DexieCabinetsService} from '../../../shared/services/dexie/dexie-cabinets.service';
import {Cabinet} from '../../../api/models/cabinet';
import {YandexMetrikaWrapperService} from '../../services/yandex-metrika-wrapper.service';
import {ExcelService} from '../../services/excel-service.service';
import {Client} from '../../../api/models/client';

import * as _ from 'lodash';
import {AutomationExpirationManagerService} from '../../services/automation-expiration-manager.service';
import {CurrencyPipe, DecimalPipe} from '@angular/common';
import {DgPaginationComponent} from '../../../ngr-ui/components/dg-pagination/dg-pagination.component';
import {MatSnackBar} from '@angular/material';
import {environment} from "../../../../environments/environment";
import {OAuthService} from "../../../api/services";

@Component({
    selector: 'app-automation-campaigns',
    templateUrl: './automation-campaigns.component.html',
    styleUrls: ['./automation-campaigns.component.scss']
})
export class AutomationCampaignsComponent implements OnInit {

    constructor(
        public yandex: YandexMetrikaWrapperService,
        private route: ActivatedRoute,
        private api: AutomationVkService,
        private localDb: DexieCampaignsService,
        private localCabinets: DexieCabinetsService,
        private excel: ExcelService,
        private accessManager: AutomationExpirationManagerService,
        private snackBar: MatSnackBar,
        private oauthService: OAuthService
    ) {
    }

    public campaigns: Array<Campaign> = [];
    public campaignsViewModels: Array<CampaignViewModel> = [];
    public statistics: object = {};
    public account_id = null;
    public date: DateFilterViewModel = new DateFilterViewModel('0', '0', 'overall');
    public countOfRowsOnPage = 25;
    public disabledExportButton = false;
    public hiddenColumns = {
        name: {
            hidden: false,
            title: 'Название'
        },
        status: {
            hidden: false,
            title: 'Статус',
            noVisible: true
        },
        day_limit: {
            hidden: false,
            title: 'Дневной лимит'
        },
        all_limit: {
            hidden: false,
            title: 'Общий лимит'
        },
        spent: {
            hidden: false,
            title: 'Потрачено'
        },
        impressions: {
            hidden: false,
            title: 'Показы'
        },
        clicks: {
            hidden: false,
            title: 'Переходы'
        },
        ctr: {
            hidden: false,
            title: 'CTR'
        },
        ecpc: {
            hidden: false,
            title: 'eCPC'
        },
    };

    public goalId = null;
    public counterId = null;
    public clients: Array<Client> = [];
    public clientId = null;

    public isLoading = false;

    @ViewChild('pagination') pagination: ClrDatagridPagination;
    public currentCabinet: Cabinet;

    @ViewChild('dgPagination') public dgPagination: DgPaginationComponent;

    public statusFilterOptions = [
        {
            value: null,
            title: 'Все'
        },
        {
            value: 1,
            title: 'Только запущенные'
        },
        {
            value: 0,
            title: 'Только остановленные'
        }
    ];
    countOfResults: number = 10;
    availableSizes = environment.availableSizes;

    public ToggleEnabledExportButton() {
        this.disabledExportButton = !this.disabledExportButton;
    }

    public IsAnalyticServiceAvaialbe(analyticService: any) {
        return analyticService.IsAvaliable();
    }

    SelectCounter(selectedCounterId: number) {
        this.counterId = selectedCounterId;
        this.yandex.LoadGoals(selectedCounterId);
    }

    SelectGoal(selectedGoalId: number) {
        this.goalId = selectedGoalId;
        this.yandex.LoadGoalReach({
            counterId: this.counterId,
            goalId: selectedGoalId,
            dateFrom: (this.date.dateFrom === '0') ? '2012-06-01' : this.date.dateFrom,
            dateTo: (this.date.dateTo === '0') ? 'today' : this.date.dateTo
        })
            .then(x => {
                this.campaignsViewModels.forEach(ad => {
                    const t = this.yandex.GetMetrikaValues(ad.utms);
                    ad.yandex_reched_goals = 0;
                    ad.yandex_cr_from_view = 0;
                    ad.yandex_cr_from_click = 0;
                    ad.yandex_cpa = 0;
                    ad.yandex_page_depth = 0;
                    ad.yandex_average_duration = 0;

                    if (t && t.length > 0 && t[0].metrics) {
                        ad.yandex_reched_goals = t[0].metrics[0];


                        const pageDepthMetric = t[0].metrics[1];
                        const averageDurationMetric = t[0].metrics[2];

                        ad.yandex_page_depth = (pageDepthMetric) ? pageDepthMetric : 0;
                        ad.yandex_average_duration = (averageDurationMetric) ? averageDurationMetric : 0;

                        // Calculate ConversionRate from view (impression) & site visit (click)
                        ad.yandex_cr_from_view = (ad.impressions > 0) ? ad.yandex_reched_goals / ad.impressions * 100 : 0;
                        ad.yandex_cr_from_click = (ad.clicks > 0) ? ad.yandex_reched_goals / ad.clicks * 100 : 0;

                        ad.yandex_cpa = (ad.yandex_reched_goals > 0) ? ad.spent / ad.yandex_reched_goals : 0;
                    }
                });
            });
    }

    ngOnInit() {
        this.LoadCurrentCabinet();
        this.oauthService.CheckProviderToken('yandex-metrika')
            .subscribe(data => {
                if (data) {
                    this.yandex.LoadCounters();
                }
            })
        this.clientId = this.route.snapshot.queryParams.client_id;
    }

    public ExportToExcel() {
        const data = this.campaignsViewModels.map(campaign => {
            return Object.keys(this.hiddenColumns).reduce((camp, column) => {

                if (!this.hiddenColumns[column].noVisible) {
                    camp[this.hiddenColumns[column].title] = campaign[column];
                }
                return camp;
            }, {});
        });
        this.excel.exportAsExcelFile(data, this.currentCabinet.account_name.replace(' ', '_'));
    }

    LoadCampaigns() {
        this.isLoading  = true;
        
        this.ToggleEnabledExportButton();
        this.campaignsViewModels = [];
        if (this.account_id) {
            this.api.GetCampaigns({
                accountId: this.account_id,
                clientId: this.clientId
            })
                .subscribe(x => {
                    const campaigns = x.data
                        .sort((a, b) => b.id - a.id)
                        .map(y => {
                            const campaign = new CampaignViewModel();
                            campaign.FillFromCampaign(y);
                            campaign.accountId = parseInt(this.account_id, 10);
                            campaign.clientId = parseInt(this.clientId, 10);
                            return campaign;
                        });
                    this.campaignsViewModels = campaigns;
                    
                    this.isLoading  = false;

                    this.localDb.setCampaigns(campaigns);
                    this.localDb.getAllByAccountId(this.account_id, this.clientId)
                        .then((y) => {
                            this.campaignsViewModels = y;
                            this.LoadStatistics();
                        });
                }, (err) => {
                    this.accessManager.SetOccured(err.error);
                });
        }
    }

    public ToggleLoading() {
        this.isLoading = !this.isLoading;
    }

    public LoadCurrentCabinet() {
        this.localCabinets.getCabinetById(parseInt(this.route.snapshot.queryParams.account_id, 10))
            .then(x => {
                this.currentCabinet = x;
                // if (this.currentCabinet.account_type === 'agency') {
                //     this.LoadClients();
                // }
                this.GetAccountId();

            });
    }

    LoadStatistics() {
        setTimeout(() => {
            if (this.campaignsViewModels.length > 0) {
                this.api.GetStatistics({
                    accountId: this.account_id,
                    idsType: 'campaign',
                    ids: this.campaignsViewModels.map(x => x.id).join(','),
                    dateFrom: this.date.dateFrom,
                    dateTo: this.date.dateTo,
                    period: this.date.period,
                    clientId: this.clientId
                })
                    .subscribe(stats => {
                        stats.data.forEach((x) => {
                            this.statistics[x.id] = x.stats;
                            this.localDb.update(x.id, {
                                spent: (x.stats.length > 0) ? x.stats[0].spent : 0,
                                impressions: (x.stats.length > 0) ? x.stats[0].impressions : 0,
                                clicks: (x.stats.length > 0) ? x.stats[0].clicks : 0,
                                ctr: (x.stats.length > 0 && x.stats[0].impressions > 0) ? x.stats[0].clicks / x.stats[0].impressions * 100 : 0,
                                ecpc: (x.stats.length > 0 && x.stats[0].clicks > 0) ? x.stats[0].spent / x.stats[0].clicks : 0
                            });
                        });
                        this.localDb.getAllByAccountId(this.account_id, this.clientId)
                            .then(y => {
                                this.campaignsViewModels = y.map(x => {
                                    x.loaded = true;
                                    return x;
                                });
                                this.ToggleEnabledExportButton();
                            });
                    });
            }
        }, 1100);
    }

    GetAccountId() {
        this.route.queryParams.subscribe(params => {
            this.account_id = params['account_id'];
            if (this.currentCabinet && this.currentCabinet.account_type === 'agency') {
                this.LoadClients();
            } else {
                this.LoadCampaigns();
            }

        });
    }

    ChangeDate(date) {
        this.date = date;
        this.GetAccountId();
    }

    ChangePage() {
        this.pagination.page.size = this.countOfRowsOnPage;
    }

    LoadClients() {
        this.clients = [];
        return this.api.getClients(this.currentCabinet.account_id)
            .subscribe(x => {
                this.clients = x.data;
                this.LoadCampaigns();
            });
    }

    public SummaryFunction(data: Array<number>): number {
        if (data != null) {
            return data.reduce((a, b) => a + b, 0);
        }
        return 0;
    }

    public SummaryTitleFunction(data) {
        return 'Итого:';
    }

    public CurrencyFunction(data) {
        if (!isNaN(data)) {
            return new CurrencyPipe('ru').transform(data, ' Руб.');
        }
        return '';
    }

    public PaginSummaryTitleFunction() {
        return 'Итого на странице:';
    }

    public NumberFunction(data) {
        if (!isNaN(data)) {
            return new DecimalPipe('ru').transform(data, '0.0-2');
        }
        return '';
    }

    public PercentFunction(data) {
        if (!isNaN(data) && data !== null) {
            return new DecimalPipe('ru').transform(data, '0.0-2') + '%';
        }
    }

    public AvgSummaryFunction(data: Array<number>) {
        if (data != null) {
            return (data.reduce((a, b) => a + b, 0) / data.length).toFixed(2);
        }
        return 0;
    }

    public CtrFormatFunction(data) {
        if (!isNaN(data) && data !== null) {
            return new DecimalPipe('ru').transform(data, '0.3') + '%';
        }
    }

    ToggleCampaignStatus(id: any, b: boolean) {
        this.api.ToggleCampaign({
            accountId: parseInt(this.account_id, 10),
            campaigns: [
                {
                    campaign_id: id,
                    status: b ? 1 : 0
                }
            ]
        })
            .subscribe(x => {
                this.campaignsViewModels.forEach(campaign => {
                    if (campaign.id === id) {
                        campaign.status = 2;
                    }
                });
                // this.LoadCampaigns();
            });
    }
}
