import { EventEmitter, OnInit } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, Validators } from '@angular/forms';
import { PromopostViewModel } from '../../models/promopost.view-model';
import { AdManagerService } from '../../../../api/services';
import { UtilsService } from '../../../../shared/services/utils.service';
import 'rxjs-compat/add/operator/distinctUntilChanged';
import 'rxjs-compat/add/operator/filter';
import { ActivatedRoute } from '@angular/router';
import { delay } from 'rxjs/operators';
import { PostificatePipe } from '../../../../shared/pipes/postificate.pipe';
var CreatePromopostFormComponent = /** @class */ (function () {
    function CreatePromopostFormComponent(adManagerService, controlContainer, route) {
        this.adManagerService = adManagerService;
        this.controlContainer = controlContainer;
        this.route = route;
        this.isUpdating = false;
        this.promopostChange = new EventEmitter();
        this.onGroupSelect = new EventEmitter();
        this.ControlAdded = new EventEmitter();
        this.ControlRemoved = new EventEmitter();
        this.errors = {};
        this.userGroups = [];
    }
    Object.defineProperty(CreatePromopostFormComponent.prototype, "PromopostTexts", {
        get: function () {
            return this.controlContainer.control.get('text').controls;
        },
        enumerable: true,
        configurable: true
    });
    CreatePromopostFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        var _a = this.route.snapshot.queryParams, account_id = _a.account_id, client_id = _a.client_id, adId = _a.adId;
        this.adManagerService.GetAdminGroups()
            .pipe(delay(1000))
            .subscribe(function (data) {
            if (data !== null) {
                _this.userGroups = data.sort(function (firstGroup, secondGroup) { return firstGroup.is_closed - secondGroup.is_closed; });
                if (_this.isUpdating) {
                    var selectedGroup = _this.userGroups.find(function (group) { return group.id === _this.controlContainer.control.value.groupId; });
                    console.log(selectedGroup);
                    _this.onGroupSelect.emit(selectedGroup);
                }
            }
        });
        this.adManagerService.GetImage({
            accountId: account_id,
            clientId: client_id,
            adId: adId
        })
            .subscribe(function (data) {
            if (data) {
                _this.controlContainer.control.get('image').setValue([data.src]);
            }
            // (this.controlContainer.control.get('image') as FormArray).controls[0].setValue(data.src);
            // (this.controlContainer.control.get('image') as FormArray).controls[0].markAsDirty();
            // console.log(this.controlContainer.control.value);
        });
        this.form = new FormGroup({
            text: new FormControl(this.promopost.text, [Validators.required, Validators.maxLength(16734)]),
            image: new FormControl(this.promopost.image, [Validators.required]),
            groupId: new FormControl(this.promopost.groupId, [Validators.required]),
            albumId: new FormControl(this.promopost.albumId)
        });
        this.form
            .valueChanges
            .subscribe(function (value) {
            _this.promopostChange.emit(value);
        });
        this.controlContainer
            .control
            .get('groupId')
            .valueChanges
            .subscribe(function (data) {
            var group = _this.userGroups.find(function (group1) { return group1.id === parseInt(data, 10); });
            _this.onGroupSelect.emit(group);
        });
        this.controlContainer
            .control
            .valueChanges
            .subscribe(function (data) {
            _this.errors = UtilsService.GetErrorsByForm('create-promopost-form', _this.controlContainer.control);
            console.log(_this.errors, data);
        });
    };
    CreatePromopostFormComponent.prototype.SelectPromostGroup = function ($event) {
        if (!$event.source.selected) {
            console.log($event.source.selected);
            return;
        }
        var groupId = $event.source.value;
        var group = this.userGroups.find(function (x) { return x.id === groupId; });
        console.log(group, 'groups');
        if (group) {
            this.onGroupSelect.emit(group);
        }
    };
    CreatePromopostFormComponent.prototype.ImageSelected = function ($event) {
        console.log(this.controlContainer.control.get('image'));
        this.controlContainer.control.get('image').enable();
        this.controlContainer.control.get('image').setValue({ value: $event, disabled: true });
        console.log(this.controlContainer.control.get('image'), 'MUST BE ENABLED');
    };
    CreatePromopostFormComponent.prototype.SaveText = function (text, i) {
        this.controlContainer.control.get('text')
            .controls[i]
            .setValue(text.target.value.replace(/(?:\r\n|\r|\n)/g, '<br>').trim());
        this.controlContainer.control.get('text')
            .controls[i].markAsDirty();
    };
    CreatePromopostFormComponent.prototype.addControl = function () {
        this.controlContainer.control.get('text').push(new FormControl('', [Validators.required, Validators.maxLength(16687)]));
    };
    CreatePromopostFormComponent.prototype.removeControl = function (controlI) {
        this.controlContainer.control.get('text').removeAt(controlI);
    };
    CreatePromopostFormComponent.prototype.getEmojjiContent = function () {
        return new PostificatePipe().transform('Внимание! Рекламная запись должна содержать не более 6 эмодзи. Например: 🔥😏📅💃💪🏻🏻👁😱😈 или другие.');
    };
    return CreatePromopostFormComponent;
}());
export { CreatePromopostFormComponent };
