var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { map as __map, filter as __filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../api-configuration";
import * as i2 from "@angular/common/http";
var LeadsService = /** @class */ (function (_super) {
    __extends(LeadsService, _super);
    function LeadsService(config, http) {
        return _super.call(this, config, http) || this;
    }
    /**
     * @return Success
     */
    LeadsService.prototype.GetAllLeadStatusesResponse = function () {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('GET', this.rootUrl + "/statuses", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @return Success
     */
    LeadsService.prototype.GetAllLeadStatuses = function () {
        return this.GetAllLeadStatusesResponse().pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `LeadsService.UpdateLeadParams` containing the following parameters:
     *
     * - `id`:
     *
     * - `lead`:
     *
     * @return Success
     */
    LeadsService.prototype.UpdateLeadResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.lead;
        var req = new HttpRequest('PUT', this.rootUrl + ("/" + (params.id)), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `LeadsService.UpdateLeadParams` containing the following parameters:
     *
     * - `id`:
     *
     * - `lead`:
     *
     * @return Success
     */
    LeadsService.prototype.UpdateLead = function (params) {
        return this.UpdateLeadResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param call undefined
     * @return Success
     */
    LeadsService.prototype.CreateCallResponse = function (call) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = call;
        var req = new HttpRequest('POST', this.rootUrl + "/create-call", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param call undefined
     * @return Success
     */
    LeadsService.prototype.CreateCall = function (call) {
        return this.CreateCallResponse(call).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param id undefined
     * @return Success
     */
    LeadsService.prototype.GetUserCallsResponse = function (id) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('GET', this.rootUrl + ("/" + (id) + "/calls"), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param id undefined
     * @return Success
     */
    LeadsService.prototype.GetUserCalls = function (id) {
        return this.GetUserCallsResponse(id).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @return Success
     */
    LeadsService.prototype.GetLeadDetailsDataResponse = function () {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('GET', this.rootUrl + "/get-lead-details-data", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @return Success
     */
    LeadsService.prototype.GetLeadDetailsData = function () {
        return this.GetLeadDetailsDataResponse().pipe(__map(function (_r) { return _r.body; }));
    };
    LeadsService.GetAllLeadStatusesPath = '/statuses';
    LeadsService.UpdateLeadPath = '/{id}';
    LeadsService.CreateCallPath = '/create-call';
    LeadsService.GetUserCallsPath = '/{id}/calls';
    LeadsService.GetLeadDetailsDataPath = '/get-lead-details-data';
    LeadsService.ngInjectableDef = i0.defineInjectable({ factory: function LeadsService_Factory() { return new LeadsService(i0.inject(i1.ApiConfiguration), i0.inject(i2.HttpClient)); }, token: LeadsService, providedIn: "root" });
    return LeadsService;
}(__BaseService));
export { LeadsService };
