import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-moderation-details',
  templateUrl: './moderation-details.component.html',
  styleUrls: ['./moderation-details.component.scss']
})
export class ModerationDetailsComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ModerationDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    console.log(this.data);
  }

}
