import {Injectable} from '@angular/core';
import {QueryEntity} from '@datorama/akita';
import {RetargetingGroupsStore, RetargetingGroupsState} from './retargeting-groups.store';
import {combineLatest} from 'rxjs';
import {map, tap} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class RetargetingGroupsQuery extends QueryEntity<RetargetingGroupsState> {

  public retargetingGroups$ = this.selectAll();
  public filteredRetargetingGroups$ = combineLatest(
    this.select(state => state.ui.filter),
    this.selectAll()
  )
    .pipe(
      map(([filter, groups]) => groups.filter(x => {
        return x.type === filter
      }))
    );

  constructor(protected store: RetargetingGroupsStore) {
    super(store);
  }

}
