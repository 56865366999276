<div class="main-container">
  <div class="headline">
    <div class="wrapper-container">
      <div class="headline-strip">
        <div class="headline-strip__item">
          <a class="logotype" [href]="LogoLink">
            <div class="logotype-text logotype-text_primary">Post</div>
            <div class="logotype-text logotype-text_secondary">Monitor</div>
          </a>
          <!--<div class="branding">-->
          <!--<a class="title" routerLink="/dashboard">PostMonitor</a>-->
          <!--</div>-->
        </div>
        <div class="headline-strip__item">
          <nav class="main-navigation">
            <ng-container *ngFor="let link of links">
              <ng-container *ngIf="IsAuthorized || link.unAuthorizedVisibility">
                <a [routerLink]="link.path" routerLinkActive="main-navigation__item_active"
                   class="main-navigation__item">
                  {{link.title}}
                  <ng-container *ngIf="link.is_new">
                    <span class="new-menu-item-mark">Новое</span>
                  </ng-container>
                </a>
              </ng-container>
            </ng-container>

            <ng-container *ngIf="IsAuthorized">
              <a [href]="(noviceMod) ?
              'https://postmonitor.ru/blog/informaciya-o-funkcionale-postmonitor-dlya-novichkov' :
              'https://postmonitor.ru/blog/informaciya-o-funkcionale-servisa-postmonitor'"
                 class="main-navigation__item"  onclick="localStorage.setItem('refrence-atentuator', 'false');" target="_blank" rel="noopener">
                <i [ngClass]='{
                  "refrence-icon icon ion-md-help-circle": true,
                  "refrence-icon_atentuate": showRefrenceAtentuation
                }'></i>
                <span>Справка</span>
              </a>
            </ng-container>
          </nav>
        </div>

        <div class="headline-strip__item headline-strip__item_squeeze-right">
          <!--<ng-container *ngIf="showChatNotification">-->
            <!-- <app-chat-notification *ngIf="IsAuthorized"></app-chat-notification> -->
          <!--</ng-container>-->
        </div>
        <div class="headline-strip__item">
          <div class="personal-actions">
            <ng-container *ngIf="IsAuthorized; else notLoggedIn">
<!--              <div class="personal-actions__item">-->
<!--                <a routerLink="/redefine-experience" [ngClass]='{-->
<!--                  "experience-switch-link": true,-->
<!--                  "experience-switch-link_newbie": !noviceMod,-->
<!--                  "experience-switch-link_pro": noviceMod-->
<!--                }'-->
<!--                   [queryParams]="{experienceFieldId: 1, experienceLevelId: (!noviceMod) ? 1 : 2, redirect: '/automation/dashboard'}">-->
<!--                  {{(!noviceMod) ? 'Для новичков' : 'Для профессионалов'}}-->
<!--                </a>-->
<!--              </div>-->

              <div class="personal-actions__item">
<!--                <ng-container *ngIf="noviceMod; else proMod">-->
<!--                  <a routerLink="/easy-settings" class="personal-actions-link personal-actions-link_atentuate">-->
<!--                    Настройки-->
<!--                  </a>-->
<!--                </ng-container>-->
                <a routerLink="/settings" class="personal-actions-link personal-actions-link_atentuate">
                  Личный кабинет
                </a>
              </div>
              <div class="personal-actions__item">
                <a class="personal-actions-link" (click)="Logout()">
                  Выйти
                </a>
              </div>
            </ng-container>
            <ng-template #notLoggedIn>
              <div class="personal-actions__item">
                <a routerLink="/sign-in" class="personal-actions-link personal-actions-link_atentuate">
                  Войти
                </a>
              </div>
              <div class="personal-actions__item">
                <a routerLink="/sign-up" class="personal-actions-link">
                  Регистрация
                </a>
              </div>
            </ng-template>
          </div>
        </div>
        <!--<nav class="header-nav">-->
        <!--<ng-container *ngIf="ShowContainer1280">-->
        <!--<a [routerLink]="link.path" routerLinkActive="active" class="nav-link nav-text"-->
        <!--*ngFor="let link of links">-->
        <!--{{link.title}}-->
        <!--</a>-->
        <!--</ng-container>-->
        <!--</nav>-->

      </div>
    </div>
    <!--<div class="colorizer"></div>-->
  </div>
  <div class="content-container">
    <div [ngClass]="{
            'content-area': ShowContainer1280
        }">
      <div [ngClass]="{
            'container_1280': ShowContainer1280
        }">
        <div class="app__content">
          <app-easy-ads-wrapper [useWrapper]="noviceMod && IsAuthorized">
            <router-outlet></router-outlet>
          </app-easy-ads-wrapper>
        </div>

        <div class="footer-container">
          <div class="footer">
            <div class="footer-strip">
              <div class="footer-strip__item">
                <div class="footer-block">
                  <!--<div class="footer-block-title">Общее</div>-->
                  <div class="footer-block__item">
                    <div class="information">
                      <a href="https://postmonitor.ru/static/documents/privacy_policy.pdf" target="_blank" rel="noopener nofollow">Политика конфиденциальности</a>
                    </div>
                  </div>
                  <div class="footer-block__item">
                    <div class="information">
                      <a href="/assets/oferta.pdf" target="_blank" rel="noopener nofollow">Договор оферта</a>
                    </div>
                  </div>
                  <div class="footer-block__item">
                    <div class="information">
                      <a routerLink="/partners" target="_blank" rel="noopener nofollow">Наши партнеры</a>
                    </div>
                  </div>
                  <div class="footer-block__item">
                    <div class="information">
                      <app-affiliate-program-link>
                      </app-affiliate-program-link>
                    </div>
                  </div>
                </div>
              </div>
              <div class="footer-strip__item">
                <!--<div class="footer-block-title">Контакты</div>-->
                <div class="footer-block">
                  <div class="footer-block__item">
                    <div class="information">
                      <div class="information-main-data">
                        <a href="https://vk.com/postmonitor" target="_blank" rel="noopener nofollow">Группа
                          ВКонтакте</a>
                      </div>
                      <div class="information-secondary-data">
                        vk.com/postmonitor
                      </div>
                    </div>
                  </div>
                  <div class="footer-block__item">
                    <div class="information">
                      <div class="information-main-data">
                        <a href="https://vk.me/postmonitor" target="_blank" rel="nofollow noopener">Написать нам в
                          ВК</a>
                      </div>
                      <div class="information-secondary-data">
                        vk.me/postmonitor
                      </div>
                    </div>
                  </div>
                  <div class="footer-block__item">
                    <div class="information">
                      <div class="information-main-data">
                        <a href="mailto:support@postmonitor.ru">Техническая поддержка</a>
                      </div>
                      <div class="information-secondary-data">
                        support@postmonitor.ru
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--<div class="footer-strip">-->
            <!--<div class="footer-strip__item">-->
            <!--<a routerLink="/privacy">Политика конфиденциальности</a>-->
            <!--</div>-->
            <!--<div class="footer-strip__item">-->
            <!--<a routerLink="/privacy">Пользовательское соглашение</a>-->
            <!--</div>-->
            <!--<div class="footer-strip__item">-->
            <!--<a routerLink="/privacy">Наши партнеры</a>-->
            <!--</div>-->
            <!--</div>-->
            <!--<div class="footer-strip">-->
            <!--<div class="footer-strip__item">-->
            <!--<div class="footer-block">-->
            <!--<div class="footer-block-title">-->
            <!--Контактная информация-->
            <!--</div>-->
            <!--<div class="footer-block__item">-->
            <!--<div class="information">-->
            <!--<div class="information-main-data">-->
            <!--<a href="https://vk.com/postmonitor">Группа ВКонтакте</a>-->
            <!--</div>-->
            <!--<div class="information-secondary-data">-->
            <!--vk.com/postmonitor-->
            <!--</div>-->
            <!--</div>-->
            <!--</div>-->
            <!--</div>-->
            <!--</div>-->
            <!--</div>-->
            <div class="wrapper-container">
              <div class="copyright">
                © 2017 - {{currentYear}} PostMonitor
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <div id="vk_community_messages"></div> -->

<ng-container *ngIf="experienceRedirection.showExperienceLevelDialog">
  <app-experience-define-modal></app-experience-define-modal>
</ng-container>
