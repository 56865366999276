import {AbstractControl, FormArray, FormControl} from '@angular/forms';
import * as messages from '../../../assets/messages/forms.json';
import {isNullOrUndefined} from 'util';

export class UtilsService {
  public static GetNotNullKeys(object): object {
    const result = Object.create({});

    Object
      .keys(object)
      .filter(k => object[k] !== null) // Filter of all null keys
      .filter(k => !k.startsWith('_') && k !== 'closed' && k !== 'isStopped' && k !== 'hasError' && k !== 'observers')
      .forEach(key => {
        result[key] = object[key];
      });

    return result;
  }


  public static flatten(arr): Array<any> {
    return arr.reduce(function (flat, toFlatten) {
      return flat.concat(Array.isArray(toFlatten) ? UtilsService.flatten(toFlatten) : toFlatten);
    }, []);
  }

  /**
   * Return all errors of adForm
   * @param formName
   * @param {Array<FormControl>} controls
   * @returns {{}}
   * @constructor
   */
  public static GetErrorsByForm(formName, controls, params = null) {
    const errors = {};
    if (controls.controls) {
      controls = controls.controls;
    }
    Object.keys(controls).forEach(key => {
      console.log(params, '32');
      errors[key] = this.CheckControlErrors(formName, controls[key], key, 0, params);
    });
    return errors;
  }

  /**
   * Check all control Errors
   * @param formName
   * @param control
   * @param controlName
   * @param index
   * @param params
   * @constructor
   */
  public static CheckControlErrors(formName, control: AbstractControl, controlName, index = 0, params = {}) {
    console.log(params, '48');
    if ((control as FormArray).controls) {
      const errors = (control as FormArray).controls
        .map((ctrl, i) => {
          return this.CheckControlErrors(formName, ctrl, controlName, i, params);
        });
      return errors;
    } else {
      const errors = control.errors;
      console.log(params, '57');
      if (errors) {
        return this.GetMessagesByErrorKeys(formName, Object.keys(errors), controlName, params);
      } else {
        return [];
      }
    }
  }

  public static GetMessagesByErrorKeys(formName, errorKeys: Array<string>, controlName, params = {}) {
    console.log(params, '67');
    return errorKeys
      .map((key) => {
        let val = messages.default[formName][controlName][key];
        console.log(params);
        if (!isNullOrUndefined(params)) {
          Object.keys(params)
            .forEach(k => {
              console.log(k, 'KEY VALUE');
              val = val.replace(`{{${k}}}`, params[k]);
            });
        }
        console.log(val, 'KEY ERROR');
        return {key, value: val};
      })
      .reduce((acc, {key, value}) => {
        acc.push(value);
        return acc;
      }, []);
  }

  public static promiseWaterfall(callbacks) {
    // Don't assume we're running in an environment with promises
    return callbacks.reduce(function (accumulator, callback) {
      return accumulator.then(callback);
    }, Promise.resolve());
  }

  public static CamelCaseToDash(myStr) {
    return myStr.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
  }

  public static DashToCamelCase(myStr) {
    return myStr.toLowerCase().replace(/_([a-z])/g, function (g) {
      return g[1].toUpperCase();
    });
  }

  public static GetInnerOptions(obj, propertyString) {
    const properties = propertyString.split('.');
    return properties.reduce((value, property) => {
      return value[property];
    }, obj);
  }

  public static GetPhoto(photo, largest = true) {
    let previews = [];

    if (photo) {
      previews.push(photo.photo_2560);
      previews.push(photo.photo_1280);
      previews.push(photo.photo_807);
      previews.push(photo.photo_604);
      previews.push(photo.photo_130);
      previews.push(photo.photo_75);
    }

    previews = previews.filter(x => x !== '' && x !== null);
    const i = (largest) ? 0 : previews.length - 1;
    return previews[i];
  }

  public static IsAllArrayElementsHomogeneous(array) {
    console.log(array, 'HOMOGENEOUS');
    let lastValue = array[0];
    let isHomogeneous = true;
    array.forEach(element => {
      isHomogeneous = isHomogeneous && lastValue === element;
      lastValue = element;
    });

    return isHomogeneous;
  }

}
