import {Injectable} from '@angular/core';
import {PlatformAssignationsStore} from './platform-assignations.store';

@Injectable({
  providedIn: 'root'
})
export class PlatformAssignationsService {

  constructor(
    private store: PlatformAssignationsStore,
  ) {
  }

  public assignPlatform(platformId: string) {
    this.store.upsert(platformId, {assignationStatus: true});
  }

  /**
   * Отключить платформу от системы
   * @param platformId
   */
  public unsubscribePlatform(platformId: string) {
    this.store.upsert(platformId, {assignationStatus: false});
  }
}
