var ImageValidationViewModel = /** @class */ (function () {
    function ImageValidationViewModel(width, height, size) {
        this.width = width;
        this.height = height;
        this.size = size;
    }
    Object.defineProperty(ImageValidationViewModel.prototype, "status", {
        get: function () {
            return this.width && this.height && this.size;
        },
        enumerable: true,
        configurable: true
    });
    return ImageValidationViewModel;
}());
export { ImageValidationViewModel };
