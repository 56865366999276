import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {AdManagerService} from '../../../../api/services';
import * as moment from 'moment';

const FORM_STATE = 'form';
const ERROR_STATE = 'error';
const SUCCESS_STATE = 'success';
const LOADING_STATE = 'loading';

@Component({
  selector: 'app-create-campaign-form',
  templateUrl: './create-campaign-form.component.html',
  styleUrls: ['./create-campaign-form.component.scss']
})
export class CreateCampaignFormComponent implements OnInit {

  public form: FormGroup;

  public result = null;

  public state = FORM_STATE;

  public isAdvanced = false;
  public isLoading = false;
  public isSuccessed = false;

  public changeState(stateValue) {
    this.state = stateValue;
  }

  constructor(
    public dialogRef: MatDialogRef<CreateCampaignFormComponent>,
    private adManagerService: AdManagerService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit() {
    console.log(this.data);
    const clientId = (this.data.clientId === 'null') ? null : parseInt(this.data.clientId, 10);
    this.form = new FormGroup({
      type: new FormControl(this.data.type),
      name: new FormControl(`Новая кампания ${moment(new Date()).format('DD.MM.YYYY HH:mm:ss')}`, [Validators.required, Validators.minLength(1)]),
      day_limit: new FormControl(0),
      all_limit: new FormControl(0),
      start_time: new FormControl(0),
      stop_time: new FormControl(0),
      status: new FormControl(1),
      client_id: new FormControl(clientId)
    });
    console.log(this.form);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  public ToggleAdvanced() {
    this.isAdvanced = !this.isAdvanced;
  }

  public ToggleLoading() {
    this.isLoading = !this.isLoading;
  }

  public Save() {
    this.changeState(LOADING_STATE);
    this.ToggleLoading();
    console.log(this.form);
    if (this.form.valid) {
      this.adManagerService.CreateCampaign({
        accountId: this.data.accountId,
        campaigns: [
          this.form.value
        ]
      })
        .subscribe((data) => {
          this.changeState(SUCCESS_STATE);
          this.result = data;
        }, () => {
          this.changeState(ERROR_STATE);
        });
    }
  }

  public Ok() {
    this.dialogRef.close(this.result);
  }

  Close() {
    this.dialogRef.close(null);
  }
}
