import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SearchPageWrapperComponent} from './components/search-page-wrapper/search-page-wrapper.component';
import {SortingPanelComponent} from './components/sorting-panel/sorting-panel.component';
import {ExpirationNotificationComponent} from './components/expiration-notification/expiration-notification.component';
import {PaginationComponent} from './components/pagination/pagination.component';
import {ClarityModule} from '@clr/angular';
import {PostificatePipe} from './pipes/postificate.pipe';
import {DateFormatingPipe} from './pipes/date-formating.pipe';
import {UrlWrapperPipe} from './pipes/url-wrapper.pipe';
import {OptionComponent} from './components/option/option.component';
import {ScrollToModule} from '@nicky-lenaers/ngx-scroll-to';
import {DomainOnlyPipe} from './pipes/domain-only.pipe';

import {GraphicFilterComponent} from './components/graphic-filter/graphic-filter.component';
import {GraphicWrapperComponent} from './components/graphic-wrapper/graphic-wrapper.component';
import {FormsModule} from '@angular/forms';
import {NotificationListComponent} from './components/notification-list/notification-list.component';
import {HighlightTextPipe} from './pipes/highlight-text.pipe';
import {SecondsToTimePipe} from './pipes/seconds-to-time.pipe';
import {NameShorterPipe} from './pipes/name-shorter.pipe';
import {SortingService} from './services/sorting.service';
import {FiltersService} from './services/filters.service';
import {VkRequestService} from './services/vk-request.service';
import {NotFoundPlaceholderComponent} from './components/not-found-placeholder/not-found-placeholder.component';
import {NotificationsService} from './services/notifications.service';
import {NoDataPlaceholderComponent} from './components/no-data-placeholder/no-data-placeholder.component';
import {UtmExtractorService} from './services/utm-extractor.service';
import {VkUtmExtractorService} from '../vk/services/vk-utm-extractor.service';
import {NothingFoundNotificationComponent} from './components/nothing-found-notification/nothing-found-notification.component';
import {TokenCheckerService} from './services/token-checker.service';
import {DexieCampaignsService} from './services/dexie/dexie-campaigns.service';
import {CoreService} from './services/dexie/core.service';
import {DexieCabinetsService} from './services/dexie/dexie-cabinets.service';
import {ClrDisabledDirective} from './directives/clr-disabled.directive';
import {DexieClientsService} from './services/dexie/dexie-clients.service';
import {ChipComponent} from './components/chip/chip.component';
import {ChipsListComponent} from './components/chips-list/chips-list.component';
import {ResultPerPageSelectorComponent} from './components/result-per-page-selector/result-per-page-selector.component';
import {MathService} from './services/math.service';
import {RouterModule} from '@angular/router';
import {AdvancedSearchReportService} from './services/advanced-search-report.service';
import {MinutesToTimePipe} from './pipes/minutes-to-time.pipe';
import {AdvancedTextSearchService} from './services/advanced-text-search.service';
import {SecondsToDatePipe} from './pipes/seconds-to-date.pipe';
import {UtcToLocalDatePipe} from './pipes/utc-to-local-date.pipe';
import { AlertWithMemoryComponent } from './components/alert-with-memory/alert-with-memory.component';
import { MomentDatePipe } from './pipes/moment-date.pipe';
import {UtilsService} from './services/utils.service';
import {StrategyPreviewComponent} from './components/strategy-preview/strategy-preview.component';
import { StrategiesManagerComponent } from './components/strategies-manager/strategies-manager.component';
import {
  MatButtonModule, MatCheckboxModule, MatDividerModule, MatListModule, MatProgressSpinnerModule, MatRadioModule,
  MatTooltipModule
} from '@angular/material';
import {NgrUiModule} from '../ngr-ui/ngr-ui.module';
import { GenericTooltipComponent } from './components/generic-tooltip/generic-tooltip.component';
import {AutomationWrapperComponent} from "./components/automation-wrapper/automation-wrapper.component";
import {BreadcrumbsComponent} from "./components/breadcrumbs/breadcrumbs.component";
import {StrategySettingsComponent} from '../automation/components/strategy-settings/strategy-settings.component';
import {StrategyParamsComponent} from './components/strategy-params/strategy-params.component';
import { DeclensionPipe } from './pipes/declension.pipe';
import { ExperienceDefineModalComponent } from './components/experience-define-modal/experience-define-modal.component';
import {ExperienceLevelBasedDashboardRedirectionService} from "./services/experience-level-based-dashboard-redirection.service";
import { EasyAdsWrapperComponent } from './components/easy-ads-wrapper/easy-ads-wrapper.component';
import { UserExperienceSwitcherComponent } from './components/user-experience-switcher/user-experience-switcher.component';
import { NewStyleModalComponent } from './components/new-style-modal/new-style-modal.component';
import { RemoveAccountModalComponent } from './components/remove-account-modal/remove-account-modal.component';
import { LoadingScreenComponent } from './components/loading-screen/loading-screen.component';
import {SuggestionInfoComponent} from "./components/suggestion-info/suggestion-info.component";
import {ClickOutsideDirective} from "./directives/click-outside.directive";
import { ContentEditableDirective } from './directives/content-editable.directive';
import { MultipleCheckboxDirective } from './directives/multiple-checkbox.directive';
import { UkraineEventBlockingComponent } from '../components/ukraine-event-blocking/ukraine-event-blocking.component';

@NgModule({
  imports: [
    CommonModule,
    ClarityModule,
    FormsModule,
    RouterModule,
    ScrollToModule.forRoot(),
    MatListModule,
    MatRadioModule,
    MatCheckboxModule,
    MatDividerModule,
    MatButtonModule,
    NgrUiModule,
    MatTooltipModule,
    MatProgressSpinnerModule
  ],
  declarations: [
    UkraineEventBlockingComponent,
    SearchPageWrapperComponent,
    SortingPanelComponent,
    ExpirationNotificationComponent,
    PaginationComponent,
    PostificatePipe,
    DateFormatingPipe,
    UrlWrapperPipe,
    OptionComponent,
    DomainOnlyPipe,
    GraphicFilterComponent,
    GraphicWrapperComponent,
    NotificationListComponent,
    HighlightTextPipe,
    SecondsToTimePipe,
    NameShorterPipe,
    NotFoundPlaceholderComponent,
    NoDataPlaceholderComponent,
    NothingFoundNotificationComponent,
    ClrDisabledDirective,
    ChipComponent,
    ChipsListComponent,
    ResultPerPageSelectorComponent,
    MinutesToTimePipe,
    SecondsToDatePipe,
    UtcToLocalDatePipe,
    AlertWithMemoryComponent,
    MomentDatePipe,
    StrategyPreviewComponent,
    StrategiesManagerComponent,
    GenericTooltipComponent,
    AutomationWrapperComponent,
    BreadcrumbsComponent,
    StrategyParamsComponent,
    DeclensionPipe,
    ExperienceDefineModalComponent,
    EasyAdsWrapperComponent,
    UserExperienceSwitcherComponent,
    NewStyleModalComponent,
    RemoveAccountModalComponent,
    LoadingScreenComponent,
    RemoveAccountModalComponent,
    SuggestionInfoComponent,
    ClickOutsideDirective,
    ContentEditableDirective,
    MultipleCheckboxDirective
  ],
  exports: [
    UkraineEventBlockingComponent,
    ExperienceDefineModalComponent,
    SearchPageWrapperComponent,
    SortingPanelComponent,
    ExpirationNotificationComponent,
    PaginationComponent, PostificatePipe,
    DateFormatingPipe,
    UrlWrapperPipe,
    OptionComponent,
    DomainOnlyPipe,
    GraphicFilterComponent,
    GraphicWrapperComponent,
    NotificationListComponent,
    HighlightTextPipe,
    SecondsToTimePipe,
    NameShorterPipe,
    NoDataPlaceholderComponent,
    NothingFoundNotificationComponent,
    ChipComponent,
    ChipsListComponent,
    ResultPerPageSelectorComponent,
    MinutesToTimePipe,
    SecondsToDatePipe,
    UtcToLocalDatePipe,
    AlertWithMemoryComponent,
    MomentDatePipe,
    ClrDisabledDirective,
    StrategyPreviewComponent,
    StrategiesManagerComponent,
    GenericTooltipComponent,
    AutomationWrapperComponent,
    BreadcrumbsComponent,
    StrategyParamsComponent,
    DeclensionPipe,
    EasyAdsWrapperComponent,
    UserExperienceSwitcherComponent,
    NewStyleModalComponent,
    RemoveAccountModalComponent,
    LoadingScreenComponent,
    SuggestionInfoComponent,
    ContentEditableDirective,
    MultipleCheckboxDirective
  ],
  providers: [
    ExperienceLevelBasedDashboardRedirectionService,
    NotificationsService,
    SortingService,
    FiltersService,
    VkRequestService,
    UtmExtractorService,
    VkUtmExtractorService,
    TokenCheckerService,
    DexieCampaignsService,
    DexieCabinetsService,
    CoreService,
    DexieClientsService,
    MathService,
    AdvancedSearchReportService,
    AdvancedTextSearchService,
    UtilsService
  ]
})

export class SharedModule {
}
