import { OnInit } from '@angular/core';
import { first } from 'rxjs/internal/operators/first';
import { AdManagerService } from '../../../../../api/services';
import { LoggingService } from '../../../../../shared/services/logging.service';
import { AdFormManagerService } from '../../../new-ad-manager-shared/services/ad-form-manager/ad-form-manager.service';
var CreateAdNewLiteComponent = /** @class */ (function () {
    function CreateAdNewLiteComponent(adFormManagerService, adManagerService, logger) {
        this.adFormManagerService = adFormManagerService;
        this.adManagerService = adManagerService;
        this.logger = logger;
    }
    Object.defineProperty(CreateAdNewLiteComponent.prototype, "IsLoading", {
        get: function () {
            return this.adFormManagerService.adDataLoading;
        },
        enumerable: true,
        configurable: true
    });
    CreateAdNewLiteComponent.prototype.ngOnInit = function () {
        var _this = this;
        console.log('NG ON INIT LIGHT');
        this.adFormManagerService.reset();
        this.adFormManagerService.LoadData()
            .pipe(first())
            .subscribe(function (data) {
            console.log(data);
        }, function (err) {
            console.log(err);
            _this.logger.AddBreadcrumb('Loading Data Error Object', JSON.stringify(err.error));
            _this.logger.Error('Loading Ads data init error');
        });
    };
    CreateAdNewLiteComponent.prototype.OnSave = function (specifications) {
        var _this = this;
        this.adFormManagerService
            .CreateAd()
            .subscribe(function (data) {
            console.log(data);
        }, function (err) {
            console.log(err);
            _this.logger.Error('Creating Ad error', err);
        });
    };
    return CreateAdNewLiteComponent;
}());
export { CreateAdNewLiteComponent };
