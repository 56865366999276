import {Injectable} from '@angular/core';
import {PlatformsTypes} from '../../../platforms-types';
import {AkitaNgFormsManager} from '@datorama/akita-ng-forms-manager';
import {unionAllArraysFromObjectsToArray} from '../../../../utils/js/arrays';
import {LeadFormsService} from '../../my-target/services/lead-forms.service';
import {AdNetworkFormsQuery} from '../../../stores/ad-network-forms/ad-network-forms.query';

@Injectable({
  providedIn: 'root'
})
export class AdNetworkExportConfiguratorService {

  constructor(
    private fm: AkitaNgFormsManager,
    private mtLeadFormsService: LeadFormsService,
    private adNetworkFormsQuery: AdNetworkFormsQuery
  ) {
  }


  public configure(type: PlatformsTypes) {
    switch (type) {
      case PlatformsTypes.VK_LEAD_FORMS:
      case PlatformsTypes.FB_LEAD_FORMS:
        const forms = this.fm.getForm('originPlatformSettings').value;
        return [
          {
            code: type.toString(),
            data: this.getConfigurationData(forms, type)
          }
        ];
        break;
      case PlatformsTypes.MT_LEAD_FORMS:
        return [{
          code: type.toString(),
          data: this.mtLeadFormsService.configure()
        }];
        break;
    }
  }

  private getConfigurationData(forms, type: PlatformsTypes) {
    const result = {};
    const formsDestinationSettings = this.fm.getForm('destinationPlatformSettings').value;
    if (type === PlatformsTypes.FB_LEAD_FORMS) {
      console.log(forms, formsDestinationSettings, 'FB_LEAD_FORMS');
      result['ad_ids'] = unionAllArraysFromObjectsToArray(forms)
        .map(form => form.split('_'))
        .map(([group_id, form_id]) => {
          // tslint:disable-next-line:no-shadowed-variable
          const result: any = {
            ad_id: `${group_id || form_id}`
          };
          if (formsDestinationSettings[`${group_id || form_id}`].LEADS && formsDestinationSettings[`${group_id || form_id}`].LEADS.selected) {
            result['include_lead_form_name'] = formsDestinationSettings[`${group_id || form_id}`].includeLeadFormName;
            result['include_lead_campaign_name'] = formsDestinationSettings[`${group_id || form_id}`].includeCampaignName;
          }
          const lastLeadId = this.adNetworkFormsQuery.GetLastCreateDateByGroupAndFormIds(form_id, group_id);
          if (lastLeadId) {
            result.last_lead_created_at = new Date();
          }
          return result;
        });
    } else {
      result['forms_ids'] = unionAllArraysFromObjectsToArray(forms)
        .map(form => form.split('_'))
        .map(([group_id, form_id]) => {
          // tslint:disable-next-line:no-shadowed-variable
          const result: any = {
            group_id,
            form_id,
            include_lead_form_name: formsDestinationSettings[`${group_id}_${form_id}`] ? formsDestinationSettings[`${group_id}_${form_id}`].includeLeadFormName : false,
            include_lead_campaign_name: formsDestinationSettings[`${group_id}_${form_id}`] ? formsDestinationSettings[`${group_id}_${form_id}`].includeCampaignName : false
          };
          const lastLeadId = this.adNetworkFormsQuery.GetLastLeadIdByGroupAndFormIds(form_id, group_id);
          if (lastLeadId) {
            result.last_lead_id = lastLeadId;
          }

          return result;
        });
    }
    // {
    //   forms_ids:
    // };
    return result;
  }

  public valid(type: PlatformsTypes) {
    switch (type) {
      case PlatformsTypes.MT_LEAD_FORMS:
        return this.mtLeadFormsService.valid();
        break;
      case PlatformsTypes.VK_LEAD_FORMS:
      case PlatformsTypes.FB_LEAD_FORMS:
        const form = this.fm.getForm('originPlatformSettings');
        const forms = form ? form.value : {};
        return unionAllArraysFromObjectsToArray(forms).length > 0;
        break;
    }
  }

  private getForms() {
    return this;
  }
}
