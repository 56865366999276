import {Injectable} from '@angular/core';
import {QueryEntity} from '@datorama/akita';
import {CrmUsersStore, CrmUsersState} from './crm-users.store';

@Injectable({providedIn: 'root'})
export class CrmUsersQuery extends QueryEntity<CrmUsersState> {

  public users$ = this.selectAll();

  constructor(protected store: CrmUsersStore) {
    super(store);
  }

}
