/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./personal-cabinets.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../components/personal-cabinet/personal-cabinet.component.ngfactory";
import * as i3 from "../../components/personal-cabinet/personal-cabinet.component";
import * as i4 from "@angular/common";
import * as i5 from "./personal-cabinets.component";
import * as i6 from "../../../api/services/cabinets.service";
var styles_PersonalCabinetsComponent = [i0.styles];
var RenderType_PersonalCabinetsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PersonalCabinetsComponent, data: {} });
export { RenderType_PersonalCabinetsComponent as RenderType_PersonalCabinetsComponent };
function View_PersonalCabinetsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-personal-cabinet", [], null, null, null, i2.View_PersonalCabinetComponent_0, i2.RenderType_PersonalCabinetComponent)), i1.ɵdid(1, 114688, null, 0, i3.PersonalCabinetComponent, [], { cabinet: [0, "cabinet"], IsLoggedIn: [1, "IsLoggedIn"], Link: [2, "Link"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.IsLoggedIn(_v.context.$implicit.id); var currVal_2 = (_co.baseLink + _v.context.$implicit.link); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_PersonalCabinetsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u041A\u0430\u0431\u0438\u043D\u0435\u0442\u044B"])), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "personal-cabinets"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PersonalCabinetsComponent_1)), i1.ɵdid(4, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.Cabinets; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_PersonalCabinetsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-personal-cabinets", [], null, null, null, View_PersonalCabinetsComponent_0, RenderType_PersonalCabinetsComponent)), i1.ɵdid(1, 114688, null, 0, i5.PersonalCabinetsComponent, [i6.CabinetsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PersonalCabinetsComponentNgFactory = i1.ɵccf("app-personal-cabinets", i5.PersonalCabinetsComponent, View_PersonalCabinetsComponent_Host_0, {}, {}, []);
export { PersonalCabinetsComponentNgFactory as PersonalCabinetsComponentNgFactory };
