import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AdImageViewModel, IAdImageViewModel} from '../../models/ad-image.view-model';
import * as _ from 'lodash';

@Component({
  selector: 'app-images-manager',
  templateUrl: './images-manager.component.html',
  styleUrls: ['./images-manager.component.scss']
})
export class ImagesManagerComponent implements OnInit {

  @Input() minWidth: number = 0;
  @Input() minHeight: number = 0;

  @Input() maxCountOfSelected = 1;

  public maxImageSize = 5242880;

  public files: Array<AdImageViewModel> = [];
  public selectedImages = [];
  @Output() public selected: EventEmitter<any> = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
  }

  public FilesSelected(files: FileList) {
    console.log(files);
    for (let i = 0; i < files.length; i++) {
      this.loadImage(files.item(i), files.item(i).name, i === 0);
    }
  }

  public loadImage(image: Blob, fileName: string, canBeSelected) {
    const fileReader = new FileReader();
    fileReader.addEventListener('load', () => {
      const img = new Image();
      img.src = fileReader.result.toString();
      const self = this;
      img.onload = function () {
        const image1 = new AdImageViewModel(fileName, fileReader.result, this['width'], this['height'], image.size);
        if (!self.files.map(x => x.content).includes(image1.content)) {
          self.files.push(image1);
        }
        if (canBeSelected && image1.isValid(self.minWidth, self.minHeight, self.maxImageSize)) {
          self.OnSelectImage(img.src);
        }
      };
    }, false);

    if (image) {
      fileReader.readAsDataURL(image);
    }
  }

  OnSelectImage($event: any) {
    const index = this.selectedImages.indexOf($event);
    console.log(index);
    if (index < 0) {
      this.selectedImages.push($event);
    } else {
      this.selectedImages.splice(index, 1);
    }

    this.selectedImages = this.selectedImages.slice(-this.maxCountOfSelected);

    if (this.selectedImages[0] === '') {
      this.selectedImages.splice(0, 1);
    }
    if (this.selectedImages.length === 0) {
      this.selectedImages.push('');
    }
    console.log(this.selectedImages);
    this.selected.emit(this.selectedImages);
  }

  RemoveImage($event: any) {
    console.log($event);
    this.files = this.files.filter(file => file.content !== $event.content);
    if (this.selectedImages.includes($event.content)) {
      const selected = this.files.find(x => x.isValid(this.minWidth, this.minHeight, this.maxImageSize));
      this.OnSelectImage(selected.content);
    }
  }
}
