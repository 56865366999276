import {ID} from '@datorama/akita';
import {IntegrationPlatformCompability} from '../../../api/models/integration-platform-compability';

export interface PlatformsRelation extends IntegrationPlatformCompability {
  id: ID;
}

/**
 * A factory function that creates PlatformsRelations
 */
export function createPlatformsRelation(params: Partial<PlatformsRelation>) {
  params.id = `${params.integrationServiceCompabilityId}`;
  return params as PlatformsRelation;
}
