var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { map as __map, filter as __filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../api-configuration";
import * as i2 from "@angular/common/http";
var UserExperienceLevelService = /** @class */ (function (_super) {
    __extends(UserExperienceLevelService, _super);
    function UserExperienceLevelService(config, http) {
        return _super.call(this, config, http) || this;
    }
    /**
     * @param experienceFieldIds undefined
     * @return Success
     */
    UserExperienceLevelService.prototype.GetUserExperienceLevelResponse = function (experienceFieldIds) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        (experienceFieldIds || []).forEach(function (val) { if (val != null)
            __params = __params.append('experienceFieldIds', val.toString()); });
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/user-experience-level", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param experienceFieldIds undefined
     * @return Success
     */
    UserExperienceLevelService.prototype.GetUserExperienceLevel = function (experienceFieldIds) {
        return this.GetUserExperienceLevelResponse(experienceFieldIds).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `UserExperienceLevelService.AssignUserExperienceLevelParams` containing the following parameters:
     *
     * - `experienceLevelId`:
     *
     * - `experienceFieldId`:
     *
     * @return Success
     */
    UserExperienceLevelService.prototype.AssignUserExperienceLevelResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.experienceLevelId != null)
            __params = __params.set('experienceLevelId', params.experienceLevelId.toString());
        if (params.experienceFieldId != null)
            __params = __params.set('experienceFieldId', params.experienceFieldId.toString());
        var req = new HttpRequest('POST', this.rootUrl + "/apiv2/user-experience-level", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `UserExperienceLevelService.AssignUserExperienceLevelParams` containing the following parameters:
     *
     * - `experienceLevelId`:
     *
     * - `experienceFieldId`:
     *
     * @return Success
     */
    UserExperienceLevelService.prototype.AssignUserExperienceLevel = function (params) {
        return this.AssignUserExperienceLevelResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    UserExperienceLevelService.GetUserExperienceLevelPath = '/apiv2/user-experience-level';
    UserExperienceLevelService.AssignUserExperienceLevelPath = '/apiv2/user-experience-level';
    UserExperienceLevelService.ngInjectableDef = i0.defineInjectable({ factory: function UserExperienceLevelService_Factory() { return new UserExperienceLevelService(i0.inject(i1.ApiConfiguration), i0.inject(i2.HttpClient)); }, token: UserExperienceLevelService, providedIn: "root" });
    return UserExperienceLevelService;
}(__BaseService));
export { UserExperienceLevelService };
