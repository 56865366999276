import {Component, OnInit} from '@angular/core';
import {of} from 'rxjs/internal/observable/of';
import {PostMonitorBackend_V2ModelsvkVKAPIUpdateAds} from '../../../../../api/models/post-monitor-backend-_v2modelsvk-vkapiupdate-ads';
import {AdManagerService, UserStrategiesService} from '../../../../../api/services';
import {AdFormManagerService} from '../../../new-ad-manager-shared/services/ad-form-manager/ad-form-manager.service';

@Component({
  selector: 'app-new-create-ad-pro',
  templateUrl: './new-create-ad-pro.component.html',
  styleUrls: ['./new-create-ad-pro.component.scss']
})
export class NewCreateAdProComponent implements OnInit {

  constructor(
    private adFormManagerService: AdFormManagerService,
    private adManagerService: AdManagerService,
    private userStrategiesService: UserStrategiesService
  ) {
  }

  public get IsLoading() {
    return this.adFormManagerService.adDataLoading;
  }

  ngOnInit() {
    this.adFormManagerService.isPro = true;
    this.adFormManagerService.reset();
    this.adFormManagerService.LoadData()
      .subscribe(data => {
        console.log(data, 'PRO');
      });
  }

  public get LoadingProgress() {
    return this.adFormManagerService.loadingProgress;
  }

  public get LoadingStep() {
    return this.adFormManagerService.LoadingStepMessage;
  }

  public CreateAds($event: any) {
    this.adFormManagerService
      .CreateAd()
      .subscribe((data) => {
      }, err => {
        this.adFormManagerService.loadingCreateAd = false;
        alert('Во время создания объявления произошла ошибка');
      });
  }

  public CreateStrategies(createdAdsResponse: PostMonitorBackend_V2ModelsvkVKAPIUpdateAds) {
    if (createdAdsResponse.error_code) {
      return of({});
    } else {
      return this.adFormManagerService.CreateStrategy(createdAdsResponse.id);
    }
  }

  public sendModeration(createdAdsResponse: PostMonitorBackend_V2ModelsvkVKAPIUpdateAds) {
    const sendModeration = this.adFormManagerService.getControl('moderationData', 'sendModeration').value;
    if (sendModeration) {
      return this.userStrategiesService.ToggleAdStatus({})
        .map(() => createdAdsResponse);
    } else {
      return of(createdAdsResponse);
    }
  }

  public startAfterModeration(createdAdsResponse: PostMonitorBackend_V2ModelsvkVKAPIUpdateAds) {
    const sendModeration = this.adFormManagerService.getControl('moderationData', 'startAfterModeration').value;
    if (sendModeration) {
      return this.userStrategiesService.ToggleAdStatus({})
        .map(() => createdAdsResponse);
    } else {
      return of(createdAdsResponse);
    }
  }
}
