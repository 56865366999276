import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AffiliateProgramStatisticsByDateRoutingModule } from './affiliate-program-statistics-by-date-routing.module';
import { AffiliateProgramStatisticsByDateTableComponent } from './components/affiliate-program-statistics-by-date-table/affiliate-program-statistics-by-date-table.component';
import { AffiliateProgramStatisticsByDateRouteComponent } from './routes/affiliate-program-statistics-by-date-route/affiliate-program-statistics-by-date-route.component';
import {MatTableModule} from "@angular/material/table";
import {SharedModule} from "../../../shared/shared.module";

@NgModule({
  declarations: [AffiliateProgramStatisticsByDateTableComponent, AffiliateProgramStatisticsByDateRouteComponent],
  imports: [
    CommonModule,
    AffiliateProgramStatisticsByDateRoutingModule,
    MatTableModule,
    SharedModule
  ]
})
export class AffiliateProgramStatisticsByDateModule { }
