import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {UtilsService} from '../../../../shared/services/utils.service';
import {AccountsService} from '../../../../api/services/accounts.service';

@Component({
    selector: 'app-forgot-password-form',
    templateUrl: './forgot-password-form.component.html',
    styleUrls: ['./forgot-password-form.component.scss']
})
export class ForgotPasswordFormComponent implements OnInit {

    public form: FormGroup;
    public loading = false;
    public message = false;

    public errors: object;

    constructor(
        private accountsService: AccountsService
    ) {
    }

    ngOnInit() {
        this.form = new FormGroup({
            email: new FormControl('', [Validators.required, Validators.email])
        });
    }

    Submit ($event) {
        this.ToggleLoading();
        this.message = false;
        $event.preventDefault();
        this.errors = {};
        if (this.form.valid) {
            this.accountsService
                .forgotPassword(this.form.value.email)
                .subscribe(x => {
                    this.ToggleLoading();
                    this.message = true;
                });
        } else {
            this.errors = UtilsService.GetErrorsByForm('forgot-password', this.form.controls);
        }
    }

    public ToggleLoading () {
        this.loading = !this.loading;
    }

}
