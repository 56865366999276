import {Injectable} from '@angular/core';
import {QueryEntity} from '@datorama/akita';
import {AdNetworkFormsState, AdNetworkFormsStore} from './ad-network-forms.store';
import {AkitaNgFormsManager} from '@datorama/akita-ng-forms-manager';
import {flatMap, map, tap} from 'rxjs/operators';
import {combineLatest} from 'rxjs';
import {unionAllArraysFromObjectsToArray} from '../../../utils/js/arrays';
import {PlatformsQuery} from "../platforms/platforms.query";
import {PlatformsTypes} from "../../platforms-types";
import * as moment from 'moment';

/**
 *
 * @param data
 * @constructor
 */
function GetMtForms(data) {
  const selected = Object.keys(data)
    .map((formId) => ({formId, isSelected: data[formId]}))
    .filter(({isSelected}) => isSelected)
    .map(({formId}) => parseInt(formId, 10));
  return selected;
}

@Injectable({providedIn: 'root'})
export class AdNetworkFormsQuery extends QueryEntity<AdNetworkFormsState> {

  constructor(
    protected store: AdNetworkFormsStore,
    private fm: AkitaNgFormsManager,
    private platformsQuery: PlatformsQuery
  ) {
    super(store);
  }

  public forms$ = this.selectAll();

  public selectedForms =
    combineLatest(
      this.fm.selectValue('originPlatformSettings'),
      this.platformsQuery.selectedOriginPlatformCode$
    )
      .pipe(
        map(([data, code]) => code === PlatformsTypes.MT_LEAD_FORMS ? GetMtForms(data) : unionAllArraysFromObjectsToArray(data)),
        // map(data => data.map(x => parseInt(x, 10))),
        flatMap((formIds) => this.selectAll({
          filterBy: entity => {
            return formIds.map(x => x.toString()).includes(entity.id.toString() as any)
          }
        }))
      );

  public groupForms$ = groupId => this.selectAll({filterBy: entity => entity.group_id === groupId});
  public isEmpty$ = groupId => combineLatest(
    this.groupForms$(groupId),
    this.selectLoading()
  )
    .pipe(map(([groups, loading]) => {
      return groups.length === 0;
    }));

  public isOpened$ = (formId) => this.select(state => {
    return state.ui.opened === formId;
  });

  /**
   * Получить id последнего лида
   * @param formId - id формы
   * @param groupId - id группы
   * @constructor
   */
  public GetLastLeadIdByGroupAndFormIds(formId: number, groupId: number | null) {
    let group = '';
    if (groupId) {
      group = `${groupId}_${formId}`;
    } else {
      group = `${formId}`;
    }

    const groupId1 = group.replace('_undefined', '');
    let entity = this.getEntity(group.replace('_undefined', ''));
    return entity ?  entity.lastLeadId : null;
  }

  /**
   * Получить id последнего лида
   * @param formId - id формы
   * @param groupId - id группы
   * @constructor
   */
  public GetLastCreateDateByGroupAndFormIds(formId: number, groupId: number | null) {
    let group = '';
    if (groupId) {
      group = `${groupId}_${formId}`;
    } else {
      group = `${formId}`;
    }


    const groupId1 = group.replace('_undefined', '');
    const entity = this.getEntity(group.replace('_undefined', ''));
    return entity ?  moment.utc(entity.lastLeadCreateDate).format('YYYY-MM-DD[T]HH:mm:ss') : null;
  }

  public ContainsFormsWithGroupId(group: number) {
    return this.getCount(x => x.group_id === group) > 0;
  }

  public GetForm(id) {
    return this.getAll().find(x => x.id === id)
  }
}
