import {ID} from '@datorama/akita';
import {PostMonitorBackend_V2ModelsvkAmoCrmAPIAmoCrmUser} from '../../../api/models/post-monitor-backend-_v2modelsvk-amo-crm-apiamo-crm-user';
import {PostMonitorBackend_V2ModelsBitrixApiBitrixUser} from '../../../api/models/post-monitor-backend-_v2models-bitrix-api-bitrix-user';
import {UonManager} from '../../models/uon-manager.model';

export interface CrmUser {
  id: ID;
  name: string;
}

export interface MoiSkladEmployee {
  id: string;
  accountId: string;
  shared: boolean;
  uid: string;
  email: string;
  phone: string;
  lastName: string;
  fullName: string;
  shortFio: string;
  name: string;
  externalCode: string;
  isArchived?: boolean;
  created?: Date;
}

/**
 * A factory function that creates CrmUsers
 */
export function createCrmUserFromCrmUser(params: Partial<PostMonitorBackend_V2ModelsvkAmoCrmAPIAmoCrmUser>) {
  return {
    id: params.id,
    name: `${params.name} ${params.last_name}`
  } as CrmUser;
}

export function createCrmUserFromBitrix(params: Partial<PostMonitorBackend_V2ModelsBitrixApiBitrixUser>) {
  return {
    id: params.ID,
    name: `${params.NAME} ${params.LAST_NAME}`
  } as CrmUser;
}

export function createCrmUserFromMegaplan(params: Partial<any>) {
  return {
    id: params.id,
    name: `${params.firstName} ${params.lastName}`,
  } as CrmUser;
}

export function createCrmUserFromUOn(params: Partial<UonManager>) {
  return {
    id: params.u_id,
    name: `${params.u_name} - ${params.u_email}`
  } as CrmUser;
}

export function createCrmUserFromMoiSklad(params: Partial<MoiSkladEmployee>) {
  return {
    id: params.id,
    name: params.fullName
  } as CrmUser;
}
