import { OnInit } from '@angular/core';
import { IntegrationAccessService } from '../../../api/services';
var IntegrationAccessDeniedComponent = /** @class */ (function () {
    function IntegrationAccessDeniedComponent(integrationAccessService) {
        this.integrationAccessService = integrationAccessService;
        this._hasNoAccessHistory = true;
    }
    Object.defineProperty(IntegrationAccessDeniedComponent.prototype, "hasNoAccessHistory", {
        get: function () {
            return this._hasNoAccessHistory;
        },
        enumerable: true,
        configurable: true
    });
    IntegrationAccessDeniedComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._hasNoAccessHistory = true;
        this.integrationAccessService
            .GetCurrentAccessSession()
            .subscribe(function (response) {
            if (response) {
                if (response.data) {
                    _this._hasNoAccessHistory = false;
                    // TODO Redirect back
                    // try {
                    //   const accessTo =  new Date(response.data.accessTo);
                    //   accessTo.setHours(0,0,0,0);
                    //   const currentDate = new Date();
                    //   currentDate.setHours(0,0,0,0);
                    //   const hasAccess = currentDate < accessTo;
                    //   if (hasAccess) {
                    //     //this.fillSessionData(response.data);
                    //     return;
                    //   }
                    // } catch(exception) {
                    //   console.error(exception);
                    // }
                }
            }
            //this.NavigateToAccessDeniedPage();
        });
    };
    return IntegrationAccessDeniedComponent;
}());
export { IntegrationAccessDeniedComponent };
