import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RetargetingGroupsSettingsComponent} from './components/retargeting-groups-settings/retargeting-groups-settings.component';
import {VkControlsModule} from '../../../vk-controls/vk-controls.module';
import {MatCheckboxModule, MatProgressSpinnerModule, MatSelectModule} from '@angular/material';
import {SharedModule} from '../../../shared/shared.module';
import {ReactiveFormsModule} from '@angular/forms';
import {NewAdManagerSharedModule} from '../../../automation/new-ad-manager/new-ad-manager-shared/new-ad-manager-shared.module';
import {VkLeadFormsSettingsComponent} from './components/vk-lead-forms-settings/vk-lead-forms-settings.component';
import {AdNetworkGroupComponent} from "./components/ad-network-group/ad-network-group.component";
import {CheckboxesGroupModule} from "../../../checkboxes-group/checkboxes-group.module";
import {ScrollingModule} from "@angular/cdk/scrolling";

@NgModule({
  declarations: [RetargetingGroupsSettingsComponent, VkLeadFormsSettingsComponent, AdNetworkGroupComponent],
  exports: [
    RetargetingGroupsSettingsComponent,
    VkLeadFormsSettingsComponent
  ],
  imports: [
    CommonModule,
    VkControlsModule,
    MatCheckboxModule,
    SharedModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    ReactiveFormsModule,
    NewAdManagerSharedModule,
    CheckboxesGroupModule,
    ScrollingModule
  ]
})
export class VkModule {
}
