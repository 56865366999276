import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {IntegrationFormComponent} from './components/integration-form/integration-form.component';

const routes: Routes = [
  {
    path: 'new-integration',
    component: IntegrationFormComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NewIntegrationRoutingModule {
}
