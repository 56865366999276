import {Injectable} from '@angular/core';
import {Store, StoreConfig} from '@datorama/akita';
import {AccessLimitation} from "../../../api/models/access-limitation";

export interface IntegrationSessionState {
  session: AccessLimitation;
  IsLimitExhausted: false;
  IsLoading: boolean;
}

export function createInitialState(): IntegrationSessionState {
  return {
    session: null,
    IsLimitExhausted: false,
    IsLoading: false
  };
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'integration-session'})
export class IntegrationSessionStore extends Store<IntegrationSessionState> {
  constructor() {
    super(createInitialState());
  }

}

