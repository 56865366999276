var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { AutomationVkService, FacebookAdsService, IntegrationExtraDataService, MyTargetService, VkApiService } from '../../../api/services';
import { AdNetworkFormsStore } from './ad-network-forms.store';
import { createAdNetworkFormFromVk, createFbFrom, createMtForm } from './ad-network-form.model';
import { AdNetworkFormsQuery } from './ad-network-forms.query';
import { PlatformsTypes } from '../../platforms-types';
import { PlatformsQuery } from '../platforms/platforms.query';
import { combineLatest, from, of } from 'rxjs';
import { PlatformAssignationsService } from '../platform-assignations/platform-assignations.service';
import { concatMap, delay, filter, flatMap, map, tap, toArray } from 'rxjs/operators';
import { AkitaNgFormsManager } from '@datorama/akita-ng-forms-manager';
import { unionAllArraysFromObjectsToArray } from '../../../utils/js/arrays';
import * as moment from 'moment';
import { waterfall } from "../../../utils/rxjs-operators/waterfall";
import * as i0 from "@angular/core";
import * as i1 from "./ad-network-forms.store";
import * as i2 from "../../../api/services/automation-vk.service";
import * as i3 from "./ad-network-forms.query";
import * as i4 from "../../../api/services/my-target.service";
import * as i5 from "../platforms/platforms.query";
import * as i6 from "../../../api/services/vk-api.service";
import * as i7 from "../../../api/services/integration-extra-data.service";
import * as i8 from "../platform-assignations/platform-assignations.service";
import * as i9 from "@datorama/akita-ng-forms-manager";
import * as i10 from "../../../api/services/facebook-ads.service";
var AdNetworkFormsService = /** @class */ (function () {
    function AdNetworkFormsService(store, vkSerivce, formsQuery, mtService, platformsQuery, vkApi, integrationServiceData, assignationService, fm, facebookAdsService) {
        this.store = store;
        this.vkSerivce = vkSerivce;
        this.formsQuery = formsQuery;
        this.mtService = mtService;
        this.platformsQuery = platformsQuery;
        this.vkApi = vkApi;
        this.integrationServiceData = integrationServiceData;
        this.assignationService = assignationService;
        this.fm = fm;
        this.facebookAdsService = facebookAdsService;
        this.count = 0;
    }
    /**
     * Получиь id последнего лида из результатов
     * @param leads - результат загрузки лидов
     * @param formId - id формы из которой загружены лиды
     * @param groupId - id группы к которой принадлежит форма
     * @constructor
     */
    AdNetworkFormsService.ExtractLastLeadId = function (leads, formId, groupId) {
        if (leads.length > 0) {
            return {
                formId: formId,
                groupId: groupId,
                lastLeadId: leads[0].lead_id
            };
        }
        else {
            return null;
        }
    };
    AdNetworkFormsService.prototype.LoadForms = function (groupId, type) {
        console.log('WTF ???', type, PlatformsTypes.FB_LEAD_FORMS, type === PlatformsTypes.FB_LEAD_FORMS);
        this.store.set([]);
        switch (type) {
            case PlatformsTypes.VK_LEAD_FORMS:
                return this.LoadVkLeadForms(groupId);
                break;
            case PlatformsTypes.MT_LEAD_FORMS:
                return this.LoadMtLeadForms();
                break;
            case PlatformsTypes.FB_LEAD_FORMS:
                console.log('LOAD FACEBOOK LEADS FORMS');
                return this.LoadFbLeadForms(groupId);
                break;
        }
    };
    /**
     *
     * @param formsData
     * @param type
     * @constructor
     */
    AdNetworkFormsService.prototype.LoadLastIdOfSelectedLeadForms = function (type) {
        switch (type) {
            case PlatformsTypes.MT_LEAD_FORMS.toString():
                var ids = this.GetMtSelectedForms();
                return this.LoadLastLeadsIdsFromMtForms(ids);
                break;
            case PlatformsTypes.VK_LEAD_FORMS.toString():
                var formsData = this.GetVkSelectedForms();
                return this.LoadLastLeadsIdsFromVkForms(formsData);
                break;
            case PlatformsTypes.FB_LEAD_FORMS.toString():
                var formsData1 = this.GetVkSelectedForms();
                console.log(formsData1, 'FORMS DATA !');
                return this.LoadMtUdsFromVkForms(formsData1);
                break;
        }
    };
    AdNetworkFormsService.prototype.GetMtSelectedForms = function () {
        var settings = this.fm.getForm('originPlatformSettings').value;
        var formIds = Object.keys(settings)
            .map(function (formId) { return ({ formId: formId, isSelected: settings[formId] }); })
            .filter(function (_a) {
            var isSelected = _a.isSelected;
            return isSelected;
        })
            .map(function (_a) {
            var formId = _a.formId;
            return parseInt(formId, 10);
        });
        return formIds;
    };
    AdNetworkFormsService.prototype.GetVkSelectedForms = function () {
        var ids = this.fm.getForm('originPlatformSettings').value;
        console.log(ids, 'IDS');
        var resultIds = unionAllArraysFromObjectsToArray(ids);
        console.log(resultIds, 'RESULT IDS');
        return resultIds
            .map(function (group) { return (group.replace('ad_', '').split('_')); })
            .map(function (_a) {
            var groupId = _a[0], formId = _a[1];
            return ({ groupId: groupId, formId: formId });
        });
    };
    /**
     * Loading all vk lead forms
     * @param groupId
     * @constructor
     */
    AdNetworkFormsService.prototype.LoadVkLeadForms = function (groupId) {
        var _this = this;
        this.store.setLoading(true);
        if (!(this.formsQuery.ContainsFormsWithGroupId(groupId))) {
            this.vkApi.GetLeadFormsListAsync({
                groupId: groupId,
                key: 'integration'
            })
                .subscribe(function (data) {
                if (data && data && data.length) {
                    _this.store.add(data.map(function (x) { return createAdNetworkFormFromVk(x); }));
                }
                _this.store.setLoading(false);
            });
        }
        else {
            this.store.setLoading(false);
        }
    };
    AdNetworkFormsService.prototype.LoadMtLeadForms = function () {
        var _this = this;
        console.log('CLEAR');
        this.store.set([]);
        combineLatest(this.mtService.GetLeadAdsForms({
            limit: 50,
            offset: 0,
        }), this.integrationServiceData.GetProviderExtraData('my-target__lead-ads'))
            .subscribe(function (_a) {
            var data = _a[0], formFields = _a[1];
            _this.store.set([]);
            console.log('CLEAR CLEAR');
            if (data && data.data) {
                _this.store.set(data.data.map(function (x) { return createMtForm(x, formFields); }));
            }
        });
    };
    AdNetworkFormsService.prototype.OpenForm = function (opened) {
        this.store.update(function (state) { return (__assign({}, state, { ui: {
                opened: opened !== state.ui.opened ? opened : null
            } })); });
    };
    /**
     * Получить id последних лидов для всех форм
     * @param formsData {FormGroupsModel[]} - данные форм для которых нужно получить id последних лидов
     * @constructor
     */
    AdNetworkFormsService.prototype.LoadLastLeadsIdsFromVkForms = function (formsData) {
        var _this = this;
        return from(formsData)
            .pipe(concatMap(function (_a) {
            var formId = _a.formId, groupId = _a.groupId;
            return _this.LoadLeadFormData(formId, groupId);
        }), filter(function (leadIdData) { return leadIdData !== null; }), toArray(), tap(function (leads) {
            leads.forEach(function (_a) {
                var formId = _a.formId, groupId = _a.groupId, lastLeadId = _a.lastLeadId;
                console.log(lastLeadId);
                _this.store.update(groupId + "_" + formId, { lastLeadId: lastLeadId });
            });
        }));
    };
    /**
     * Получить данные о последнем id лида в форме
     * @param formId - id формы
     * @param groupId - id группы
     * @constructor
     */
    AdNetworkFormsService.prototype.LoadLeadFormData = function (formId, groupId) {
        return this.vkApi.GetLeadFormsLeadsAsync({
            formId: formId,
            groupId: groupId,
            limit: 1,
            key: 'integration'
        }).pipe(delay(1000), map(function (_a) {
            var leads = _a.leads;
            return AdNetworkFormsService.ExtractLastLeadId(leads, formId, groupId);
        }));
    };
    /**
     * Загрузить данные об id последнего лида в формах
     * @param formIds - id форм
     * @constructor
     */
    AdNetworkFormsService.prototype.LoadLastLeadsIdsFromMtForms = function (formIds) {
        var _this = this;
        return from(formIds)
            .pipe(concatMap(function (formId) { return _this.LoadMtFormInfo(formId); }), toArray(), tap(function (leadsInfo) {
            leadsInfo.forEach(function (leadInfo) {
                if (leadInfo) {
                    _this.store.update(leadInfo.formId, { lastLeadId: leadInfo.leadId });
                }
            });
        }));
    };
    /**
     * Загрузить данные о последнем лиде формы
     * @param formId - id лид формы
     * @constructor
     */
    AdNetworkFormsService.prototype.LoadMtFormInfo = function (formId) {
        var _this = this;
        return this.mtService.GetLeadInfoAsync({
            formId: formId,
            offset: 0,
            limit: 1,
            bannerIds: [],
            campaignIds: []
        })
            .pipe(delay(1000), map(function (data) {
            console.log(data);
            return data;
        }), flatMap(function (_a) {
            var records_found = _a.records_found;
            return _this.mtService.GetLeadInfoAsync({
                formId: formId,
                offset: records_found - 1,
                limit: 1,
                bannerIds: [],
                campaignIds: []
            });
        }), map(function (data) {
            var result = { formId: formId, leadId: null };
            if (data) {
                if (data.data) {
                    var lead = data.data[0];
                    if (lead) {
                        result.leadId = lead.id;
                    }
                }
            }
            return result;
        }));
    };
    AdNetworkFormsService.prototype.LoadFbLeadForms = function (id) {
        var _this = this;
        this.store.setLoading(true);
        combineLatest(this.facebookAdsService.FacebookGetAdsAsync({
            fields: ['name'],
            adAccountId: id,
            summary: ['total_count']
        }), this.integrationServiceData.GetProviderExtraData('facebook_lead-forms'))
            .pipe(tap(function (response) {
            if (response[0] && response[0].data) {
                var responseData = response[0];
                var totalCount = responseData.data.summary.total_count;
                var count = responseData.data.data.length;
                var countOfRequests = totalCount / count - 1;
                var requests = [];
                if (responseData.data.data) {
                    var data = responseData.data.data.map(function (x) {
                        _this.count++;
                        return createFbFrom({
                            id: "" + x.id,
                            form_id: "" + x.id,
                            group_id: id,
                            name: "" + x.name,
                        }, response[1]);
                    });
                    _this.store.add(data);
                }
                var afterPage_1 = responseData && responseData.data && responseData.data.paging && responseData.data.paging.cursors ? responseData.data.paging.cursors.after : null;
                if (countOfRequests <= 1) {
                    _this.store.setLoading(false);
                }
                for (var i = 0; i < countOfRequests; i++) {
                    var request = function () {
                        _this.store.setLoading(true);
                        return _this.facebookAdsService.FacebookGetAdsAsync({
                            fields: ['name'],
                            adAccountId: id,
                            summary: ['total_count'],
                            pagingAfter: afterPage_1
                        })
                            .pipe(tap(function (newData) {
                            _this.store.setLoading(true);
                            _this.store.upsertMany(newData.data.data.map(function (x, index) {
                                _this.count++;
                                return createFbFrom({
                                    id: "" + x.id,
                                    form_id: "" + x.id,
                                    group_id: id,
                                    name: "" + x.name,
                                }, response[1]);
                            }));
                            afterPage_1 = newData && newData.data && newData.data.paging && newData.data.paging.cursors && newData.data.paging.cursors.after
                                ? newData.data.paging.cursors.after : null;
                            _this.store.setLoading(true);
                        }), delay(1000));
                    };
                    requests.push(request);
                }
                if (requests.length > 0) {
                    waterfall(requests)
                        .subscribe(function () {
                        _this.store.setLoading(false);
                    });
                }
                else {
                    _this.store.setLoading(false);
                }
            }
        }))
            .subscribe();
        return of([]);
    };
    AdNetworkFormsService.prototype.LoadMtUdsFromVkForms = function (formsData1) {
        var _this = this;
        return from(formsData1)
            .pipe(concatMap(function (_a) {
            var groupId = _a.groupId, formId = _a.formId;
            return _this.LoadLeadsFromFacebook(groupId);
        }), filter(function (leadIdData) { return leadIdData !== null; }), toArray(), tap(function (leads) {
            var lastDate = moment.max(leads.map(function (x) { return moment(x.created_time); })).toDate();
            console.log("" + formsData1[0].groupId, { lastLeadCreateDate: lastDate }, 'LEADS LAST DATE');
            formsData1.forEach(function (formData) {
                _this.store.update("" + formData.groupId, { lastLeadCreateDate: new Date() });
            });
            // leads.forEach((lead) => {
            //   console.log(JSON.stringify(lead));
            //   console.log(`${formsData1[0].groupId} LEAD ID`);
            //   // console.log(lastLeadId);
            // });
        }));
    };
    AdNetworkFormsService.prototype.LoadLeadsFromFacebook = function (groupId) {
        return this.facebookAdsService.FacebookGetAdLeadsAsyncResponse({
            adId: groupId,
        })
            .pipe(delay(1000), map(function (x) { return x.body.data['data']; }));
    };
    AdNetworkFormsService.ngInjectableDef = i0.defineInjectable({ factory: function AdNetworkFormsService_Factory() { return new AdNetworkFormsService(i0.inject(i1.AdNetworkFormsStore), i0.inject(i2.AutomationVkService), i0.inject(i3.AdNetworkFormsQuery), i0.inject(i4.MyTargetService), i0.inject(i5.PlatformsQuery), i0.inject(i6.VkApiService), i0.inject(i7.IntegrationExtraDataService), i0.inject(i8.PlatformAssignationsService), i0.inject(i9.AkitaNgFormsManager), i0.inject(i10.FacebookAdsService)); }, token: AdNetworkFormsService, providedIn: "root" });
    return AdNetworkFormsService;
}());
export { AdNetworkFormsService };
