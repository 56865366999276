import { Directive } from '@angular/core';

@Directive({
  selector: '[ngrDgContent]'
})
export class DgContentDirective {

  constructor() { }

}
