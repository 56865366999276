<div class="b-card document-wrapper">
  <h1 class="document-header">Политика конфиденциальности и защиты информации.</h1>
  <p>
    Оставляя данные на сайте, Вы соглашаетесь с Политикой конфиденциальности и защиты информации.
  </p>

  <h2 class="document-header">Защита данных</h2>
  <p>
    Администрация сайта postmonitor.ru (далее Сайт) не может передать или раскрыть информацию, предоставленную
    пользователем (далее Пользователь) при регистрации и использовании функций сайта третьим лицам, кроме случаев,
    описанных законодательством страны, на территории которой пользователь ведет свою деятельность.
  </p>

  <h2 class="document-header">Получение персональной информации</h2>
  <p>
    Для коммуникации на сайте пользователь обязан внести некоторую персональную информацию. Для проверки предоставленных
    данных, сайт оставляет за собой право потребовать доказательства идентичности в онлайн или офлайн режимах.
  </p>

  <h2 class="document-header">Использование персональной информации</h2>
  <p>
    Сайт использует личную информацию Пользователя для обслуживания и для улучшения качества предоставляемых услуг.
    Часть персональной информации может быть предоставлена банку или платежной системе, в случае, если предоставление
    этой информации обусловлено процедурой перевода средств платежной системе, услугами которой Пользователь желает
    воспользоваться. Сайт прилагает все усилия для сбережения в сохранности личных данных Пользователя. Личная
    информация может быть раскрыта в случаях, описанных законодательством, либо когда администрация сочтет подобные
    действия необходимыми для соблюдения юридической процедуры, судебного распоряжения или легального процесса
    необходимого для работы Пользователя с Сайтом. В других случаях, ни при каких условиях, информация, которую
    Пользователь передает Сайту, не будет раскрыта третьим лицам.
  </p>

  <h2 class="document-header">Коммуникация</h2>

  <p>
    После того, как Пользователь оставил данные, он получает сообщение, подтверждающее его успешную регистрацию.
    Пользователь соглашается получать от Сайта на электронный адрес и/или абонентский номер телефона, указанный при работе с Сайтом,
    информационные электронные сообщения. Сайт вправе использовать электронные сообщения для информирования Пользователя об изменениях и новых возможностях Сайта,
    об изменении Соглашения, а также для рассылок информационного или рекламного характера.
    <br>
    Пользователь имеет право в любой момент прекратить получение информационных рассылок, воспользовавшись соответствующим сервисом на Сайте.
  </p>

  <h2 class="document-header">Ссылки</h2>
  <p>
    На сайте могут содержаться ссылки на другие сайты. Сайт не несет ответственности за содержание, качество и политику
    безопасности этих сайтов. Данное заявление о конфиденциальности относится только к информации, размещенной
    непосредственно на сайте.
  </p>

  <h2 class="document-header">Безопасность</h2>
  <p>Сайт обеспечивает безопасность учетной записи Пользователя от несанкционированного доступа.</p>

  <h2 class="document-header">Уведомления об изменениях</h2>
  <p>
    Сайт оставляет за собой право вносить изменения в Политику конфиденциальности без дополнительных уведомлений.
    Нововведения вступают в силу с момента их опубликования. Пользователи могут отслеживать изменения в Политике
    конфиденциальности самостоятельно.
  </p>
</div>
