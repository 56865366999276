/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./segmentation-info.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./segmentation-info.component";
var styles_SegmentationInfoComponent = [i0.styles];
var RenderType_SegmentationInfoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SegmentationInfoComponent, data: {} });
export { RenderType_SegmentationInfoComponent as RenderType_SegmentationInfoComponent };
export function View_SegmentationInfoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "alert alert-info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "alert-items"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 8, "div", [["class", "alert-item static"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "alert-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "segmentation-info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "segmentation-info__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" \u0412\u044B \u0432\u044B\u0431\u0440\u0430\u043B\u0438 \u0441\u0435\u0433\u043C\u0435\u043D\u0442\u0438\u0440\u043E\u0432\u0430\u0442\u044C ", "\n"])), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [["class", "alert-actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "alert-action"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "a", [["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.ToggleAdvancedMode() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\u041F\u043E\u0441\u043C\u043E\u0442\u0440\u0435\u0442\u044C"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.segmentationType; _ck(_v, 6, 0, currVal_0); }); }
export function View_SegmentationInfoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-segmentation-info", [], null, null, null, View_SegmentationInfoComponent_0, RenderType_SegmentationInfoComponent)), i1.ɵdid(1, 114688, null, 0, i2.SegmentationInfoComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SegmentationInfoComponentNgFactory = i1.ɵccf("app-segmentation-info", i2.SegmentationInfoComponent, View_SegmentationInfoComponent_Host_0, { segmentationType: "segmentationType" }, { advancedModeToggled: "advancedModeToggled" }, []);
export { SegmentationInfoComponentNgFactory as SegmentationInfoComponentNgFactory };
