import { OnInit } from '@angular/core';
import { AdFormManagerService } from '../../../new-ad-manager-shared/services/ad-form-manager/ad-form-manager.service';
var NewTeazerFormProComponent = /** @class */ (function () {
    function NewTeazerFormProComponent(adFormManagerService) {
        this.adFormManagerService = adFormManagerService;
        this.controlsWidth = '256px';
        this.labelsWidth = '220px';
        this.textAreaHeight = '237px';
    }
    Object.defineProperty(NewTeazerFormProComponent.prototype, "form", {
        get: function () {
            return this.adFormManagerService.getControl('teazer');
        },
        enumerable: true,
        configurable: true
    });
    NewTeazerFormProComponent.prototype.ngOnInit = function () {
    };
    NewTeazerFormProComponent.prototype.ImageSelected = function (images) {
        this.adFormManagerService.AddTeazerImage(images);
    };
    Object.defineProperty(NewTeazerFormProComponent.prototype, "TeazerLength", {
        get: function () {
            return 33 - this.adFormManagerService.getTeazerTextLength(0);
        },
        enumerable: true,
        configurable: true
    });
    return NewTeazerFormProComponent;
}());
export { NewTeazerFormProComponent };
