/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./registrated-tile-content.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../amount/amount.component.ngfactory";
import * as i3 from "../amount/amount.component";
import * as i4 from "@angular/common";
import * as i5 from "./registrated-tile-content.component";
import * as i6 from "../../../../../storage/user-balance/state/user-balance.query";
var styles_RegistratedTileContentComponent = [i0.styles];
var RenderType_RegistratedTileContentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RegistratedTileContentComponent, data: {} });
export { RenderType_RegistratedTileContentComponent as RenderType_RegistratedTileContentComponent };
export function View_RegistratedTileContentComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-amount", [["currency", ""]], null, null, null, i2.View_AmountComponent_0, i2.RenderType_AmountComponent)), i1.ɵdid(1, 114688, null, 0, i3.AmountComponent, [], { amount: [0, "amount"], currency: [1, "currency"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.registrated$)); var currVal_1 = ""; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_RegistratedTileContentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-registrated-tile-content", [], null, null, null, View_RegistratedTileContentComponent_0, RenderType_RegistratedTileContentComponent)), i1.ɵdid(1, 114688, null, 0, i5.RegistratedTileContentComponent, [i6.UserBalanceQuery], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RegistratedTileContentComponentNgFactory = i1.ɵccf("app-registrated-tile-content", i5.RegistratedTileContentComponent, View_RegistratedTileContentComponent_Host_0, {}, {}, []);
export { RegistratedTileContentComponentNgFactory as RegistratedTileContentComponentNgFactory };
