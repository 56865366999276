import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AutomationVkSlotService} from '../../../../../api/services';
import {PickListNgrComponent} from '../../../../../ngr-ui/components/pick-list/pick-list-ngr.component';
import {AdFormManagerService} from '../../services/ad-form-manager/ad-form-manager.service';
import {delay} from 'rxjs/operators';

@Component({
  selector: 'app-new-accounts-manager',
  templateUrl: './new-accounts-manager.component.html',
  styleUrls: ['./new-accounts-manager.component.scss']
})
export class NewAccountsManagerComponent implements OnInit {
  public get countOfMaxSlots(): any {
    return this.adFormManagerService.slots.length;
  }

  // public get slots() {
  //   return this.adFormManagerService.slots;
  // }
  //
  // public get accounts() {
  //   return this.adFormManagerService.accounts
  //     .filter(account => account.account_role === 'admin');
  // }

  @Input() public showAccounts = true;
  @Input() public slots: [];
  @Input() public accounts: [];

  @Output() bindingChanged: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('pickList') public pickList: PickListNgrComponent;

  constructor(
    private adFormManagerService: AdFormManagerService,
    private automationSlotsService: AutomationVkSlotService
  ) {
  }

  ngOnInit() {
  }

  public changed($event: any) {
//    this.automationSlotsService.AssignCabinetToSlot({
//      id:
//    })
  }

  public OnSaveCabinetsBined(data: any) {
    if (!data.isSelected) {
      this.automationSlotsService.AssignCabinetToSlot({
        id: data.account_id,
        clientId: data.client_id
      })
        .pipe(
          delay(1000)
        )
        .subscribe(data1 => {
          this.bindingChanged.emit();
        }, (err) => {
          this.pickList.SetError(err.error);
        });
    } else {
      this.automationSlotsService.UnBindCabinetFromSlot({
        id: data.account_id,
        clientId: data.client_id
      })
        .subscribe(data1 => {
          this.bindingChanged.emit();
        }, (err) => {
          this.pickList.SetError(err.error);
        });
    }
  }

  public Refresh() {
    if (this.showAccounts) {
      this.adFormManagerService.LoadAdCabinetData()
        .subscribe(data => {
        });
    } else {
      this.bindingChanged.emit();
    }
  }
}
