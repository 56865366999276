/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./amount.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./amount.component";
var styles_AmountComponent = [i0.styles];
var RenderType_AmountComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AmountComponent, data: {} });
export { RenderType_AmountComponent as RenderType_AmountComponent };
export function View_AmountComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DecimalPipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "amount"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "amount__value"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵppd(4, 1), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "amount__currency"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v, 0), _co.amount)); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.currency; _ck(_v, 6, 0, currVal_1); }); }
export function View_AmountComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-amount", [], null, null, null, View_AmountComponent_0, RenderType_AmountComponent)), i1.ɵdid(1, 114688, null, 0, i3.AmountComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AmountComponentNgFactory = i1.ɵccf("app-amount", i3.AmountComponent, View_AmountComponent_Host_0, { amount: "amount", currency: "currency" }, {}, []);
export { AmountComponentNgFactory as AmountComponentNgFactory };
