var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AbstractCopyingConfigurator } from "./abstract-copying-configurator";
var GroupsInFileSegmentationConfigurator = /** @class */ (function (_super) {
    __extends(GroupsInFileSegmentationConfigurator, _super);
    function GroupsInFileSegmentationConfigurator(props) {
        return _super.call(this, props) || this;
    }
    GroupsInFileSegmentationConfigurator.prototype.generate = function () {
        var _this = this;
        var specifications = [];
        this.copyingData.groupsFromFileConfiguration.groupsList.forEach(function (groupId) {
            var spec = _this.generateDefeault();
            spec = Object.assign(spec, {
                groupId: groupId,
                groupsNot: _this.copyingData.groupsFromFileConfiguration.excludeOtherGroups
                    ? _this.copyingData.groupsFromFileConfiguration.groupsList.filter(function (x) { return x !== groupId; }).join(',')
                    : null
            });
            specifications.push(spec);
        });
        return specifications;
    };
    return GroupsInFileSegmentationConfigurator;
}(AbstractCopyingConfigurator));
export { GroupsInFileSegmentationConfigurator };
