/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./affiliate-program-link.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/router";
import * as i4 from "./affiliate-program-link.component";
import * as i5 from "../../services/affiliate-status.service";
var styles_AffiliateProgramLinkComponent = [i0.styles];
var RenderType_AffiliateProgramLinkComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AffiliateProgramLinkComponent, data: {} });
export { RenderType_AffiliateProgramLinkComponent as RenderType_AffiliateProgramLinkComponent };
function View_AffiliateProgramLinkComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "a", [["routerLink", "/affiliate/settings"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { title: 0 }), i1.ɵdid(4, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["\u041F\u0430\u0440\u0442\u043D\u0435\u0440\u0441\u043A\u0430\u044F \u043F\u0440\u043E\u0433\u0440\u0430\u043C\u043C\u0430"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _ck(_v, 3, 0, _co.isTitle); _ck(_v, 2, 0, currVal_2); var currVal_3 = "/affiliate/settings"; _ck(_v, 4, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).target; var currVal_1 = i1.ɵnov(_v, 4).href; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_AffiliateProgramLinkComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "a", [["href", "https://postmonitor.ru/affiliates"], ["rel", "noopener nofolow"], ["target", "_blank"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(2, { title: 0 }), (_l()(), i1.ɵted(-1, null, ["\u041F\u0430\u0440\u0442\u043D\u0435\u0440\u0441\u043A\u0430\u044F \u043F\u0440\u043E\u0433\u0440\u0430\u043C\u043C\u0430"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, _co.isTitle); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AffiliateProgramLinkComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AffiliateProgramLinkComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["allAffiliate", 2]], null, 0, null, View_AffiliateProgramLinkComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.IsPartner; var currVal_1 = i1.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_AffiliateProgramLinkComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-affiliate-program-link", [], null, null, null, View_AffiliateProgramLinkComponent_0, RenderType_AffiliateProgramLinkComponent)), i1.ɵdid(1, 114688, null, 0, i4.AffiliateProgramLinkComponent, [i5.AffiliateStatusService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AffiliateProgramLinkComponentNgFactory = i1.ɵccf("app-affiliate-program-link", i4.AffiliateProgramLinkComponent, View_AffiliateProgramLinkComponent_Host_0, { isTitle: "isTitle" }, {}, []);
export { AffiliateProgramLinkComponentNgFactory as AffiliateProgramLinkComponentNgFactory };
