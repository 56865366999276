import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AffiliateStatusService {

  public isPartner = false;
  
  private _baseUrl = `${environment.backendUri}/${environment.apiPrefix}/affiliate-status`
  
  constructor(
      private http: HttpClient
  ) { }
  
  public LoadAffiliateMemberStatus() {
    this.http.get(this._baseUrl)
        .subscribe(affiliateStatus => {
          this.isPartner = affiliateStatus ? affiliateStatus['isActivated'] : false;
        })
  }
}
