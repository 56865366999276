/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./partners-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../components/partner-view/partner-view.component.ngfactory";
import * as i3 from "../../components/partner-view/partner-view.component";
import * as i4 from "@angular/common";
import * as i5 from "./partners-page.component";
import * as i6 from "../../api/services/partners.service";
var styles_PartnersPageComponent = [i0.styles];
var RenderType_PartnersPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PartnersPageComponent, data: {} });
export { RenderType_PartnersPageComponent as RenderType_PartnersPageComponent };
function View_PartnersPageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-partner-view", [], null, null, null, i2.View_PartnerViewComponent_0, i2.RenderType_PartnerViewComponent)), i1.ɵdid(1, 114688, null, 0, i3.PartnerViewComponent, [], { partner: [0, "partner"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_PartnersPageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u041D\u0430\u0448\u0438 \u043F\u0430\u0440\u0442\u043D\u0435\u0440\u044B"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PartnersPageComponent_1)), i1.ɵdid(3, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.partners; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_PartnersPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-partners-page", [], null, null, null, View_PartnersPageComponent_0, RenderType_PartnersPageComponent)), i1.ɵdid(1, 114688, null, 0, i5.PartnersPageComponent, [i6.PartnersService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PartnersPageComponentNgFactory = i1.ɵccf("app-partners-page", i5.PartnersPageComponent, View_PartnersPageComponent_Host_0, {}, {}, []);
export { PartnersPageComponentNgFactory as PartnersPageComponentNgFactory };
