import {Injectable} from '@angular/core';
import {Store, StoreConfig} from '@datorama/akita';
import {PostMonitorBackend_V2ModelsPaymentsTariffPackage} from "../../../../api/models/post-monitor-backend-_v2models-payments-tariff-package";

export interface UserTariffState {
  tariff: PostMonitorBackend_V2ModelsPaymentsTariffPackage[],
  expiredAt: Date;
}

export function createInitialState(): UserTariffState {
  return {
    tariff: null,
    expiredAt: null
  };
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'user-tariff'})
export class UserTariffStore extends Store<UserTariffState> {

  constructor() {
    super(createInitialState());
  }

}

