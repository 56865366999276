import {Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {AkitaNgFormsManager} from '@datorama/akita-ng-forms-manager';
import {ContentEditableDirective} from '../../../shared/directives/content-editable.directive';
import {IntegrationFormService} from '../../../integrations/modules/integration-form/services/integration-form.service';
import {StoreService} from '../../../integrations/services/store.service';
import {combineLatest} from 'rxjs';
import {PlatformsQuery} from '../../stores/platforms/platforms.query';
import {filter, flatMap, map} from 'rxjs/operators';
import {subscribe} from '@sentry/browser/esm/tracekit';
import {PlatformsRelationsQuery} from '../../stores/platforms-relations/platforms-relations.query';

@Component({
  selector: 'app-integration-name-form',
  templateUrl: './integration-name-form.component.html',
  styleUrls: ['./integration-name-form.component.scss']
})
export class IntegrationNameFormComponent implements OnInit {

  @ViewChild('editable') public editable: ElementRef;

  constructor(private fm: AkitaNgFormsManager,
              private integrationFormService: IntegrationFormService,
              private store: StoreService,
              private platformsQuery: PlatformsQuery,
              private platformsRelationsQuery: PlatformsRelationsQuery) {
  }

  form: FormGroup = new FormGroup({
    name: new FormControl()
  });

  ngOnInit() {
    this.fm.upsert('name', this.form);

    combineLatest(
      this.platformsQuery.selectedOriginPlatform,
      this.platformsQuery.selectedDestinationPlatform$
    )
      .pipe(
        filter(([origin, dest]) => origin !== undefined && dest !== undefined),
        map(([origin, dest]) => [origin.id, dest.id]),
        flatMap(([origin, dest]) => this.platformsRelationsQuery.RelationPlatformsName(origin, dest))
      )
      .subscribe((name) => {
        if (!this.form.get('name').dirty) {
          this.form.get('name').setValue(name);
        }
      });
  }

  public ToggleEditing() {
    this.editable.nativeElement.focus();
  }

}
