<div class="tariff-plan-header">
  <div class="tariff-plan-header__title">{{tariff.name}}</div>
  <div class="tariff-plan-header__price">
    <ng-container *ngIf="customPrice; else defaultPrice">
      {{customPrice}} Руб.
    </ng-container>
    <ng-template #defaultPrice>
      {{price | number:"1.0-0"}} Руб.
    </ng-template>
  </div>
</div>
