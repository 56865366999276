import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatProgressSpinnerModule, MatRadioModule, MatStepperModule} from '@angular/material';
import {VkControlsModule} from '../../../vk-controls/vk-controls.module';
import {AdManagerModule} from '../../ad-manager/ad-manager.module';
import {NewAdManagerSharedModule} from '../new-ad-manager-shared/new-ad-manager-shared.module';

import { NewAdManagerLiteRoutingModule } from './new-ad-manager-lite-routing.module';
import { NewAdFormLiteComponent } from './components/new-ad-form-lite/new-ad-form-lite.component';
import { CreateAdNewLiteComponent } from './routes/create-ad-new-lite/create-ad-new-lite.component';
import { NewPromopostFormLiteComponent } from './components/new-promopost-form-lite/new-promopost-form-lite.component';
import { NewTeazerFormLiteComponent } from './components/new-teazer-form-lite/new-teazer-form-lite.component';
import { NewUpdateLiteComponent } from './routes/new-update-lite/new-update-lite.component';
import { AdAccountsNotFoundComponent } from './components/ad-accounts-not-found/ad-accounts-not-found.component';
import {SharedModule} from "../../../shared/shared.module";

@NgModule({
  declarations: [NewAdFormLiteComponent, CreateAdNewLiteComponent, NewPromopostFormLiteComponent, NewTeazerFormLiteComponent, NewUpdateLiteComponent, AdAccountsNotFoundComponent],
  imports: [
    CommonModule,
    NewAdManagerLiteRoutingModule,
    NewAdManagerSharedModule,
    MatStepperModule,
    FormsModule,
    ReactiveFormsModule,
    VkControlsModule,
    MatRadioModule,
    AdManagerModule,
    MatProgressSpinnerModule,
    SharedModule
  ]
})
export class NewAdManagerLiteModule { }
