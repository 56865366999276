<div class="vk-ad-cabinet-header">
  Новое объявление
</div>

<div class="vk-container vk-container_large">
  Пожалуйста, <a routerLink="/sign-in">войдите</a> или <a routerLink="/sign-up">зарегистрируйтесь</a>
</div>

<div class="ad-manager-placeholder-image">

</div>
