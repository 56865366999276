import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../../environments/environment";
import {PostMonitorBackend_V2ModelsPaymentsTariffPackage} from "../../../../api/models/post-monitor-backend-_v2models-payments-tariff-package";
import {UserTariffStore} from "../state/user-tariff.store";

@Injectable({
  providedIn: 'root'
})
export class UserTariffService {

  constructor(
    private http: HttpClient,
    private store: UserTariffStore
  ) {
  }

  /**
   * Загрузка тарифного плана пользователя
   * @constructor
   */
  public LoadUserTariffPackage() {
    this.http.get(`${environment.backendUri}/${environment.apiPrefix}/tariff-packages/user`)
      .subscribe((data: any) => {
        console.log(data, 'TARIFFS');
        this.store.update(state => ({
          tariff: data,
          expiredAt: data.expiredAt
        }));
      });
  }
}
