import {Injectable} from '@angular/core';
import {QueryEntity} from '@datorama/akita';
import {
  AffiliateMembersStatisticsByDateStore,
  AffiliateMembersStatisticsByDateState
} from './affiliate-members-statistics-by-date.store';

@Injectable({providedIn: 'root'})
export class AffiliateMembersStatisticsByDateQuery extends QueryEntity<AffiliateMembersStatisticsByDateState> {

  public get statistics$() {
    return this.selectAll();
  }

  public get dateFrom$() {
    return this.select(state => state.ui.dateFrom);
  }

  public get dateTo$() {
    return this.select(state => state.ui.dateTo);
  }

  public get dateFrom() {
    return this.getValue().ui.dateFrom;
  }

  public get dateTo() {
    return this.getValue().ui.dateTo;
  }

  constructor(protected store: AffiliateMembersStatisticsByDateStore) {
    super(store);
  }

}
