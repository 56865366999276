var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { environment } from '../../../../environments/environment';
import Dexie from 'dexie';
var CoreService = /** @class */ (function (_super) {
    __extends(CoreService, _super);
    function CoreService() {
        var _this = _super.call(this, environment.indexedDB.tableName) || this;
        _this.version(1).stores({
            campaigns: '++id,name,status,day_limit,all_limit,spent,impressions,clicks,ctr,ecpc,yandex_cr_from_view,yandex_cr_from_click,yandex_cpa,yandex_reched_goals,yandex_page_depth,yandex_average_duration,utms,accountId'
        });
        _this.version(2).stores({
            cabinets: '++account_id,account_name,account_type,account_status,access_role',
        })
            .upgrade(function (data) {
        });
        _this.version(3).stores({
            user: '++id,email'
        }).upgrade(function (data) {
        });
        _this.version(4).stores({
            cabinets: '++account_id,account_name,account_type,account_status,access_role,is_blocked'
        }).upgrade(function (data) {
        });
        _this.version(5)
            .stores({
            clients: 'id,name,day_limit,all_limit,account_id'
        })
            .upgrade(function () {
        });
        _this.version(6)
            .stores({
            campaigns: '++id,name,status,day_limit,all_limit,spent,impressions,clicks,ctr,ecpc,yandex_cr_from_view,yandex_cr_from_click,yandex_cpa,yandex_reched_goals,yandex_page_depth,yandex_average_duration,utms,accountId,clientId'
        })
            .upgrade(function () {
        });
        _this.version(7)
            .stores({
            campaigns: '++id,name,status,day_limit,all_limit,spent,impressions,clicks,ctr,ecpc,yandex_cr_from_view,yandex_cr_from_click,yandex_cpa,yandex_reched_goals,yandex_page_depth,yandex_average_duration,utms,[accountId+clientId]'
        })
            .upgrade(function () {
        });
        _this.version(8)
            .stores({
            campaigns: '++id,name,status,day_limit,all_limit,spent,impressions,clicks,ctr,ecpc,yandex_cr_from_view,yandex_cr_from_click,yandex_cpa,yandex_reched_goals,yandex_page_depth,yandex_average_duration,utms,accountId,clientId,[accountId+clientId]'
        })
            .upgrade(function () {
        });
        return _this;
    }
    return CoreService;
}(Dexie));
export { CoreService };
