import { OnInit } from '@angular/core';
import { tap } from "rxjs/operators";
import { ControlValueAccessor, FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import * as urlParser from 'url-parse';
import { MacroCommandsService, UtmParamsService } from "../../../../../api/services";
import { urlWithHttpOrHttps } from "../../../../ad-manager/validators/url-validator";
var LinkControlComponent = /** @class */ (function () {
    function LinkControlComponent(macrosService, utmsService) {
        this.macrosService = macrosService;
        this.utmsService = utmsService;
    }
    Object.defineProperty(LinkControlComponent.prototype, "params", {
        get: function () {
            return this.getLinkQueryParams().controls;
        },
        enumerable: true,
        configurable: true
    });
    LinkControlComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.form = new FormGroup({
            host: new FormControl('', [Validators.required, urlWithHttpOrHttps]),
            linkQueryParams: new FormArray([
                new FormGroup({
                    name: new FormControl('', [Validators.required]),
                    value: new FormControl('', [Validators.required])
                })
            ])
        });
        this.form.get('host')
            .valueChanges.subscribe(function (url) {
            _this.parseLink(url);
        });
        this.form.valueChanges.subscribe(function (a) {
            _this.buildLink();
        });
        this.LoadMacros().subscribe();
        this.LoadUtmParams().subscribe();
    };
    LinkControlComponent.prototype.SubscribeToMethods = function () {
        var _this = this;
        if (this.subscribtion) {
            console.log(this.subscribtion);
            this.subscribtion.unsubscribe();
        }
        this.subscribtion = this.form
            .valueChanges
            .subscribe(function (x) {
            _this.buildLink();
        });
    };
    LinkControlComponent.prototype.AddParam = function () {
        this.form.get('linkQueryParams').push(new FormGroup({
            name: new FormControl('', [Validators.required]),
            value: new FormControl('', [Validators.required])
        }));
    };
    LinkControlComponent.prototype.getLinkQueryParams = function () {
        return this.form.controls.linkQueryParams;
    };
    LinkControlComponent.prototype.RemoveParam = function (i) {
        var linkQueryParams = this.getLinkQueryParams();
        linkQueryParams.removeAt(i);
    };
    LinkControlComponent.prototype.LoadUtmParams = function () {
        var _this = this;
        return this.utmsService.GetAllUtmParams()
            .pipe(tap(function (utmParams) {
            _this.utms = utmParams;
        }));
    };
    LinkControlComponent.prototype.LoadMacros = function () {
        var _this = this;
        return this.macrosService.GetAllMacroCommands()
            .pipe(tap(function (macroCommands) {
            _this.macros = macroCommands;
        }));
    };
    LinkControlComponent.prototype.registerOnChange = function (fn) {
        this.onChange = fn;
    };
    LinkControlComponent.prototype.registerOnTouched = function (fn) {
        this.onTouch = fn;
    };
    LinkControlComponent.prototype.setDisabledState = function (isDisabled) {
    };
    LinkControlComponent.prototype.writeValue = function (obj) {
        console.log(obj, 'VALUE LOG');
        if (obj) {
            this.form.patchValue(obj);
        }
    };
    LinkControlComponent.prototype.parseLink = function (link) {
        var parsed = urlParser(link);
        var a = parsed
            .query
            .replace('?', '')
            .split('&');
        console.log(parsed);
        console.log(a, 'QUERY PARAMS');
        if (a[0] !== '') {
            for (var i = 0; i < a.length - 1; i++) {
                this.AddParam();
            }
            // a
            //   .forEach(() => this.AddParam());
            console.log(parsed);
            var data = {
                host: parsed.protocol + "//" + parsed.host + "/" + parsed.hash,
                linkQueryParams: a
                    .map(function (queryParam) {
                    var _a = queryParam.split('='), name = _a[0], value = _a[1];
                    return { name: name, value: value };
                })
            };
            this.form.patchValue(data, { emitEvent: false, onlySelf: true });
        }
    };
    LinkControlComponent.prototype.buildLink = function () {
        if (this.onChange) {
            this.onChange(this.form.value);
        }
    };
    LinkControlComponent.prototype.ChangeDisablingState = function () {
        if (!this.form.get('host').valid) {
            this.form.get('linkQueryParams').disable({ emitEvent: false });
        }
        else {
            this.form.get('linkQueryParams').disable({ emitEvent: false });
        }
        this.form.get('linkQueryParams').controls.forEach(function (controlGroup) {
            if (!controlGroup.get('name').valid) {
                controlGroup.get('name').disable({ emitEvent: false });
            }
            else {
                controlGroup.get('name').enable({ emitEvent: false });
            }
        });
    };
    return LinkControlComponent;
}());
export { LinkControlComponent };
