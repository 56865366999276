import {Injectable} from '@angular/core';
import {AdvancedSearchReportService} from '../../shared/services/advanced-search-report.service';
import {Post} from '../../api/models/post';
import {AdvancedSearchReportModel} from '../../shared/models/AdvancedSearchReportModel';
import {Group} from '../../api/models/group';

@Injectable()
export class VkAdvancedSearchReportWrapperService {
  private source_id: number = 1;
  private group_type_id: number = 1;
  private post_type_id: number = 2;

  constructor(private advancedSearchReportAPI: AdvancedSearchReportService) {
  }

  public ReportGroups(groups: Array<Group>): void {
    if (!groups || groups.length === 0) {
      return null;
    }
    const convertedGroups: Array<AdvancedSearchReportModel> =
      groups.map(x => this.ConvertGroupToReportModel(x));

    this.advancedSearchReportAPI.SendReport(convertedGroups);
  }

  public ReportPosts(posts: Array<Post>): void {
    if (!posts || posts.length === 0) {
      return null;
    }
    const convertedPosts: Array<AdvancedSearchReportModel> =
      posts.map(x => this.ConvertPostToReportModel(x));

    this.advancedSearchReportAPI.SendReport(convertedPosts);
  }

  private ConvertPostToReportModel(post: Post): AdvancedSearchReportModel {
    const report: AdvancedSearchReportModel = {
      source_id: this.source_id,
      type_id: this.post_type_id,
      key: `${post.group_id}_${post.id}`
    };

    return report;
  }

  private ConvertGroupToReportModel(group: Group): AdvancedSearchReportModel {
    const report: AdvancedSearchReportModel = {
      source_id: this.source_id,
      type_id: this.group_type_id,
      key: `${group.id}`
    };

    return report;
  }
}
