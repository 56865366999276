<div class="card">
  <div class="card-header">
    <div class="fx-row">
      <div class="comment__author">{{comment?.author?.email}}</div>
      <div class="fx-divider"></div>
      <div class="comment__created-at">{{comment.createdAt | date:"medium"}}</div>
    </div>
  </div>
  <div class="card-block">
    <div class="comment">
      <div class="comment__text">{{comment.text}}</div>
    </div>
  </div>
</div>
