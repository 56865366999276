/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
class UserAffiliateTransactionsService extends __BaseService {
  static readonly GetAffiliateStatisticByUserPath = '/apiv2/user-affiliate-statistics/by-user';
  static readonly GetAffiliateStatisticByDatePath = '/apiv2/user-affiliate-statistics/by-date';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `UserAffiliateTransactionsService.GetAffiliateStatisticByUserParams` containing the following parameters:
   *
   * - `dateTo`:
   *
   * - `dateFrom`:
   */
  GetAffiliateStatisticByUserResponse(params: UserAffiliateTransactionsService.GetAffiliateStatisticByUserParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.dateTo != null) __params = __params.set('dateTo', params.dateTo.toString());
    if (params.dateFrom != null) __params = __params.set('dateFrom', params.dateFrom.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/user-affiliate-statistics/by-user`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `UserAffiliateTransactionsService.GetAffiliateStatisticByUserParams` containing the following parameters:
   *
   * - `dateTo`:
   *
   * - `dateFrom`:
   */
  GetAffiliateStatisticByUser(params: UserAffiliateTransactionsService.GetAffiliateStatisticByUserParams): __Observable<null> {
    return this.GetAffiliateStatisticByUserResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `UserAffiliateTransactionsService.GetAffiliateStatisticByDateParams` containing the following parameters:
   *
   * - `dateTo`:
   *
   * - `dateFrom`:
   */
  GetAffiliateStatisticByDateResponse(params: UserAffiliateTransactionsService.GetAffiliateStatisticByDateParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.dateTo != null) __params = __params.set('dateTo', params.dateTo.toString());
    if (params.dateFrom != null) __params = __params.set('dateFrom', params.dateFrom.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/user-affiliate-statistics/by-date`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `UserAffiliateTransactionsService.GetAffiliateStatisticByDateParams` containing the following parameters:
   *
   * - `dateTo`:
   *
   * - `dateFrom`:
   */
  GetAffiliateStatisticByDate(params: UserAffiliateTransactionsService.GetAffiliateStatisticByDateParams): __Observable<null> {
    return this.GetAffiliateStatisticByDateResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module UserAffiliateTransactionsService {

  /**
   * Parameters for GetAffiliateStatisticByUser
   */
  export interface GetAffiliateStatisticByUserParams {
    dateTo?: string;
    dateFrom?: string;
  }

  /**
   * Parameters for GetAffiliateStatisticByDate
   */
  export interface GetAffiliateStatisticByDateParams {
    dateTo?: string;
    dateFrom?: string;
  }
}

export { UserAffiliateTransactionsService }
