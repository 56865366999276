var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { map as __map, filter as __filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../api-configuration";
import * as i2 from "@angular/common/http";
var TokenCenterService = /** @class */ (function (_super) {
    __extends(TokenCenterService, _super);
    function TokenCenterService(config, http) {
        return _super.call(this, config, http) || this;
    }
    /**
     * @param provider undefined
     * @return Success
     */
    TokenCenterService.prototype.GetTokenResponse = function (provider) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (provider != null)
            __params = __params.set('provider', provider.toString());
        var req = new HttpRequest('POST', this.rootUrl + "/apiv2/tokencenter", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param provider undefined
     * @return Success
     */
    TokenCenterService.prototype.GetToken = function (provider) {
        return this.GetTokenResponse(provider).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param provider undefined
     */
    TokenCenterService.prototype.Apiv2TokencenterDeleteResponse = function (provider) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (provider != null)
            __params = __params.set('provider', provider.toString());
        var req = new HttpRequest('DELETE', this.rootUrl + "/apiv2/tokencenter", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param provider undefined
     */
    TokenCenterService.prototype.Apiv2TokencenterDelete = function (provider) {
        return this.Apiv2TokencenterDeleteResponse(provider).pipe(__map(function (_r) { return _r.body; }));
    };
    TokenCenterService.GetTokenPath = '/apiv2/tokencenter';
    TokenCenterService.Apiv2TokencenterDeletePath = '/apiv2/tokencenter';
    TokenCenterService.ngInjectableDef = i0.defineInjectable({ factory: function TokenCenterService_Factory() { return new TokenCenterService(i0.inject(i1.ApiConfiguration), i0.inject(i2.HttpClient)); }, token: TokenCenterService, providedIn: "root" });
    return TokenCenterService;
}(__BaseService));
export { TokenCenterService };
