/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./affiliate-program-statistics-by-date-route.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../components/affiliate-program-statistics-by-date-table/affiliate-program-statistics-by-date-table.component.ngfactory";
import * as i3 from "../../components/affiliate-program-statistics-by-date-table/affiliate-program-statistics-by-date-table.component";
import * as i4 from "../../services/affiliate-program-statistics-by-date.service";
import * as i5 from "./affiliate-program-statistics-by-date-route.component";
var styles_AffiliateProgramStatisticsByDateRouteComponent = [i0.styles];
var RenderType_AffiliateProgramStatisticsByDateRouteComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AffiliateProgramStatisticsByDateRouteComponent, data: {} });
export { RenderType_AffiliateProgramStatisticsByDateRouteComponent as RenderType_AffiliateProgramStatisticsByDateRouteComponent };
export function View_AffiliateProgramStatisticsByDateRouteComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "page"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "page__content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h2", [["class", "page__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u0421\u0442\u0430\u0442\u0438\u0441\u0442\u0438\u043A\u0430 \u043F\u043E \u0434\u0430\u0442\u0430\u043C"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-affiliate-program-statistics-by-date-table", [], null, null, null, i2.View_AffiliateProgramStatisticsByDateTableComponent_0, i2.RenderType_AffiliateProgramStatisticsByDateTableComponent)), i1.ɵdid(5, 114688, null, 0, i3.AffiliateProgramStatisticsByDateTableComponent, [i4.AffiliateProgramStatisticsByDateService], null, null)], function (_ck, _v) { _ck(_v, 5, 0); }, null); }
export function View_AffiliateProgramStatisticsByDateRouteComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-affiliate-program-statistics-by-date-route", [], null, null, null, View_AffiliateProgramStatisticsByDateRouteComponent_0, RenderType_AffiliateProgramStatisticsByDateRouteComponent)), i1.ɵdid(1, 114688, null, 0, i5.AffiliateProgramStatisticsByDateRouteComponent, [i4.AffiliateProgramStatisticsByDateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AffiliateProgramStatisticsByDateRouteComponentNgFactory = i1.ɵccf("app-affiliate-program-statistics-by-date-route", i5.AffiliateProgramStatisticsByDateRouteComponent, View_AffiliateProgramStatisticsByDateRouteComponent_Host_0, {}, {}, []);
export { AffiliateProgramStatisticsByDateRouteComponentNgFactory as AffiliateProgramStatisticsByDateRouteComponentNgFactory };
