import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/browser';
import { Severity } from '@sentry/browser';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
var LoggingService = /** @class */ (function () {
    function LoggingService(router) {
        this.router = router;
        this.tags = [];
        this.breadcrumbs = [];
        this.extras = [];
    }
    LoggingService.prototype.ngOnInit = function () {
    };
    /**
     *
     * @constructor
     */
    LoggingService.prototype.SetUserData = function (scope) {
        var userId = this.getUserId();
        if (userId) {
            scope.setUser({
                id: userId
            });
        }
    };
    /**
     *
     * @param {string} tagName
     * @param tagValue
     * @constructor
     */
    LoggingService.prototype.SetTag = function (tagName, tagValue) {
        this.tags.push({ tagName: tagName, tagValue: tagValue });
    };
    /**
     *
     * @param {string} message
     * @param data
     * @param {string} category
     * @param {Severity} level
     * @constructor
     */
    LoggingService.prototype.AddBreadcrumb = function (message, data, category, level) {
        if (data === void 0) { data = null; }
        if (category === void 0) { category = 'default'; }
        if (level === void 0) { level = Severity.Info; }
        this.breadcrumbs.push({
            timestamp: Date.now(),
            message: message,
            category: category,
            data: data,
            level: level,
            type: null
        });
    };
    LoggingService.prototype.AddExtra = function (key, data) {
        this.extras.push({ key: key, data: data });
    };
    /**
     *
     * @returns {string}
     */
    LoggingService.prototype.getUserId = function () {
        return localStorage.getItem('sentry_user_id') || null;
    };
    LoggingService.prototype.Log = function (message) {
        var _this = this;
        console.log('Отправки логов!');
        Sentry.withScope(function (scope) {
            _this.SetBreadCrumbs(scope);
            _this.SetTags(scope);
            _this.SetUserData(scope);
            Sentry.captureMessage(message);
        });
    };
    LoggingService.prototype.Error = function (error, errorData) {
        var _this = this;
        if (errorData === void 0) { errorData = null; }
        console.log('Отправки логов! с ошибкой');
        Sentry.withScope(function (scope) {
            _this.SetBreadCrumbs(scope);
            _this.SetTags(scope);
            _this.SetUserData(scope);
            _this.SetExtras(scope);
            var err = null;
            if (errorData) {
                err = errorData;
            }
            else {
                err = new Error(error);
            }
            _this.AddExtra('error_data', err);
            Sentry.captureMessage(error, Severity.Error);
        });
    };
    LoggingService.prototype.SetBreadCrumbs = function (scope) {
        this.breadcrumbs.forEach(function (_a) {
            var level = _a.level, category = _a.category, message = _a.message, timestamp = _a.timestamp, data = _a.data, type = _a.type;
            scope.addBreadcrumb({ level: level, category: category, message: message, timestamp: timestamp, data: data });
        });
    };
    LoggingService.prototype.SetTags = function (scope) {
        this.tags.forEach(function (_a) {
            var tagName = _a.tagName, tagValue = _a.tagValue;
            scope.setTag(tagName, tagValue);
        });
    };
    LoggingService.prototype.SetExtras = function (scope) {
        this.extras.forEach(function (_a) {
            var key = _a.key, data = _a.data;
            scope.setExtra(key, data);
        });
    };
    LoggingService.ngInjectableDef = i0.defineInjectable({ factory: function LoggingService_Factory() { return new LoggingService(i0.inject(i1.Router)); }, token: LoggingService, providedIn: "root" });
    return LoggingService;
}());
export { LoggingService };
