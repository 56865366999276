export interface AffiliateMembersStatisticsByDate {
  id: number | string;
  statisticDate: Date;
  amount: number;
  countOfTransactions: number;
}

export function createAffiliateMembersStatisticsByDate(params: Partial<AffiliateMembersStatisticsByDate>, id: number) {
  return {
    ...params,
    id,
  } as AffiliateMembersStatisticsByDate;
}
