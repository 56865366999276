import {Component, forwardRef, OnInit} from '@angular/core';
import {tap} from "rxjs/operators";
import {ControlValueAccessor, FormArray, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators} from "@angular/forms";
import * as urlParser from 'url-parse';
import {Subscriber, Subscription} from "rxjs";
import {
  PostMonitorBackend_V2ModelsAdManagerEntitiesMacroCommand,
  PostMonitorBackend_V2ModelsAdManagerEntitiesUtmParam
} from 'app/api/models';
import {MacroCommandsService, UtmParamsService} from "../../../../../api/services";
import {urlWithHttpOrHttps} from "../../../../ad-manager/validators/url-validator";

@Component({
  selector: 'app-link-control',
  templateUrl: './link-control.component.html',
  styleUrls: ['./link-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LinkControlComponent),
      multi: true
    }
  ]
})
export class LinkControlComponent implements OnInit, ControlValueAccessor {
  public utms: Array<PostMonitorBackend_V2ModelsAdManagerEntitiesUtmParam>;
  public macros: Array<PostMonitorBackend_V2ModelsAdManagerEntitiesMacroCommand>;
  public form: FormGroup;

  public onChange;
  public onTouch;

  public subscription: Subscriber<any>;
  private subscribtion: Subscription;

  public get params() {
    return this.getLinkQueryParams().controls as Array<FormGroup>
  }

  constructor(
    private macrosService: MacroCommandsService,
    private utmsService: UtmParamsService
  ) {
  }

  ngOnInit() {
    this.form = new FormGroup({
      host: new FormControl('', [Validators.required, urlWithHttpOrHttps]),
      linkQueryParams: new FormArray([
        new FormGroup({
          name: new FormControl('', [Validators.required]),
          value: new FormControl('', [Validators.required])
        })
      ])
    });

    this.form.get('host')
      .valueChanges.subscribe(url => {
        this.parseLink(url)
      });

    this.form.valueChanges.subscribe(a => {
      this.buildLink()
    });

    this.LoadMacros().subscribe()
    this.LoadUtmParams().subscribe()
  }

  private SubscribeToMethods() {
    if (this.subscribtion) {
      console.log(this.subscribtion);
      this.subscribtion.unsubscribe();
    }
    this.subscribtion = this.form
      .valueChanges
      .subscribe(x => {
        this.buildLink()
      });
  }

  public AddParam() {
    (this.form.get('linkQueryParams') as FormArray).push(new FormGroup({
      name: new FormControl('', [Validators.required]),
      value: new FormControl('', [Validators.required])
    }));
  }

  private getLinkQueryParams() {
    return this.form.controls.linkQueryParams as FormArray;
  }

  public RemoveParam(i: number) {
    const linkQueryParams = this.getLinkQueryParams();
    linkQueryParams.removeAt(i);
  }

  public LoadUtmParams() {
    return this.utmsService.GetAllUtmParams()
      .pipe(
        tap((utmParams: Array<PostMonitorBackend_V2ModelsAdManagerEntitiesUtmParam>) => {
          this.utms = utmParams;
        })
      )
  }

  public LoadMacros() {
    return this.macrosService.GetAllMacroCommands()
      .pipe(
        tap((macroCommands: Array<PostMonitorBackend_V2ModelsAdManagerEntitiesMacroCommand>) => {
          this.macros = macroCommands;
        })
      )
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
  }

  writeValue(obj: any): void {
    console.log(obj, 'VALUE LOG');
    if (obj) {
      this.form.patchValue(obj);
    }
  }

  public parseLink(link: string) {
    const parsed = urlParser(link);
    const a = parsed
      .query
      .replace('?', '')
      .split('&');

    console.log(parsed);

    console.log(a, 'QUERY PARAMS');
    if (a[0] !== '') {
      for (let i = 0; i < a.length - 1;i++) {
        this.AddParam();
      }
      // a
      //   .forEach(() => this.AddParam());
      console.log(parsed);

      const data = {
        host: `${parsed.protocol}//${parsed.host}/${parsed.hash}`,
        linkQueryParams: a
          .map((queryParam) => {
            const [name, value] = queryParam.split('=');
            return {name, value}
          })
      };

      this.form.patchValue(data, {emitEvent: false, onlySelf: true});
    }
  }

  public buildLink() {
    if (this.onChange) {
      this.onChange(this.form.value);
    }
  }

  private ChangeDisablingState() {
    if (!this.form.get('host').valid) {
      this.form.get('linkQueryParams').disable({emitEvent: false});
    } else {
      this.form.get('linkQueryParams').disable({emitEvent: false});
    }

    (this.form.get('linkQueryParams') as FormArray).controls.forEach((controlGroup: FormGroup) => {
      if (!controlGroup.get('name').valid) {
        controlGroup.get('name').disable({emitEvent: false});
      } else {
        controlGroup.get('name').enable({emitEvent: false});
      }
    })
  }
}
