import { OnInit } from '@angular/core';
import { UserBalanceFacade } from "../../../../../storage/user-balance/state/user-balance.facade";
import { AffiliateLinksService } from "../../../../../storage/affiliate-links/state/affiliate-links.service";
import { AffiliateLinksQuery } from "../../../../../storage/affiliate-links/state/affiliate-links.query";
import { environment } from "../../../../../../environments/environment";
var AffiliateProgramSettingsReportRouteComponent = /** @class */ (function () {
    function AffiliateProgramSettingsReportRouteComponent(userBalanceFacade, affiliateLinksService, affiliateLinksQuery) {
        this.userBalanceFacade = userBalanceFacade;
        this.affiliateLinksService = affiliateLinksService;
        this.affiliateLinksQuery = affiliateLinksQuery;
    }
    Object.defineProperty(AffiliateProgramSettingsReportRouteComponent.prototype, "affiliateLinks$", {
        get: function () {
            return this.affiliateLinksQuery.selectFirst();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AffiliateProgramSettingsReportRouteComponent.prototype, "affiliateBaseUrl", {
        get: function () {
            return environment.affiliateProgram.affiliateRootLink;
        },
        enumerable: true,
        configurable: true
    });
    AffiliateProgramSettingsReportRouteComponent.prototype.ngOnInit = function () {
        this.userBalanceFacade.LoadCurrentBalance();
        this.affiliateLinksService.LoadAffiliateLink();
    };
    return AffiliateProgramSettingsReportRouteComponent;
}());
export { AffiliateProgramSettingsReportRouteComponent };
