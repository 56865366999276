import { OnInit, QueryList } from '@angular/core';
import { ContentEditableDirective } from '../../../../../shared/directives/content-editable.directive';
import { IntegrationFormService } from '../../services/integration-form.service';
import { combineLatest } from "rxjs";
import { StoreService } from "../../../../services/store.service";
var IntegrationFormNameComponent = /** @class */ (function () {
    function IntegrationFormNameComponent(integrationFormService, store) {
        this.integrationFormService = integrationFormService;
        this.store = store;
        this.editable = true;
        this.form = this.integrationFormService.form;
    }
    IntegrationFormNameComponent.prototype.ngOnInit = function () {
        var _this = this;
        // console.log('NAME INTEGRAION ');
        // this.form.get('leftService').valueChanges.subscribe(x => {
        //   console.log(x, 'NAME WWW');
        // });
        combineLatest(this.form.get('leftService').valueChanges, this.form.get('rightService').valueChanges)
            .subscribe(function (_a) {
            var leftServiceName = _a[0], rightServiceName = _a[1];
            var relationName = _this.store.getRelationNameByServiceCodes(leftServiceName, rightServiceName);
            if (relationName) {
                _this.form.patchValue({ name: relationName });
            }
        });
    };
    IntegrationFormNameComponent.prototype.focusOnEditable = function () {
        this.editable = true;
        this.contentEditable.first.elementRef.nativeElement.focus();
    };
    return IntegrationFormNameComponent;
}());
export { IntegrationFormNameComponent };
