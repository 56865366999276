<div class="ad-promopost-form">
  <div class="ad-promopost-form__controls">
    <form [formGroup]="form">
      <vk-form-field formArrayName="text" [labelWidth]="labelsWidth" [controlsWidth]="controlsWidth">
        <vk-label>Текст заголовка</vk-label>
        <input type="text" vkInput formControlName="0" maxlength="33">
        <vk-hint>Осталось символов: {{TeazerLength}}</vk-hint>
      </vk-form-field>

      <vk-form-field [labelWidth]="labelsWidth" [controlsWidth]="controlsWidth">
        <vk-label>Введите ссылку на сайт или группу</vk-label>
        <input type="text" vkInput formControlName="linkAddress">
      </vk-form-field>

      <vk-form-field [labelWidth]="labelsWidth" [controlsWidth]="controlsWidth">
        <vk-label>Название объявления</vk-label>
        <input type="text" vkInput formControlName="name">
      </vk-form-field>

    </form>
  </div>
  <div class="ad-promopost-form__examples">
    <app-ad-teazers-list></app-ad-teazers-list>
  </div>
</div>
<br>
<app-new-images-manager
  (selected)="ImageSelected($event)"
  [minWidth]="145"
  [minHeight]="165"
></app-new-images-manager>
