import * as _ from 'lodash';
import {isNullOrUndefined} from 'util';
import {Ad, AdLayout, AdTargeting, PostReach, Statistics, StatisticsData} from '../../../api/models';
import {UserStrategy} from '../../../api/models/user-strategy';
import {WallPost} from '../../../api/models/wall-post';
import {UrlExctractorService} from '../../../vk/services/url-exctractor.service';
import {YandexMetrikaWrapperService} from '../../services/yandex-metrika-wrapper.service';
import {AdCostTypeModes} from '../ad-cost-type-modes';
import {UtmMark} from '../utm-mark';
import {IncrediblesCopyType} from './incredible-copy-notification-data-view.model';

const URL_REGEXP = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;

export class AdViewModel {
  id: number;
  name: string;
  status: number;
  cpm: number;
  day_limit: number;
  all_limit: number;
  autobidding: number;
  spent: number;
  impressions: number;
  clicks: number;
  ctr: number;
  ecpc: number;
  reach: number;
  ad_format: number;
  ad_platform: string;
  ad_platform_no_wall: boolean;
  approved: number;
  campaign_id: number;
  category1_id: number;
  category2_id: number;
  cost_type: number;
  cpc: number;
  desclaimer_medical: number;
  desclaimer_specialist: number;
  disclaimer_supplements: number;
  events_retargeting_groups: any;
  impressions_limit: number;
  impressions_limited: boolean;
  video: boolean;
  hide: number;
  join_group: number;
  links: number;
  reach_subscribers: number;
  reach_not_subscribers: number;
  reach_total: number;
  report: number;
  to_group: number;
  unsubscribe: number;
  video_views_3s: number;
  video_views_25p: number;
  video_views_50p: number;
  video_views_75p: number;
  video_views_100p: number;
  video_views_start: number;
  image_src: string;
  image_src_2x: string;
  link_domain: string;
  link_url: string;
  preview_link: string;
  title: string;
  yandex_cr_from_view: number;
  yandex_cr_from_click: number;
  yandex_cpa: number;
  yandex_reched_goals: number;
  yandex_page_depth: number;
  yandex_average_duration: number;
  utms: Array<UtmMark>;
  pricing_subscribe: number;
  price_click: number;
  price_to_group: number;
  join_rate: number;

  likes: number;
  reposts: number;
  comments: number;
  client_id: number;

  ad_url: Array<string>;

  wall: WallPost;
  strategy: UserStrategy;

  className: string;

  can_be_editable: boolean = false;
  public canBeCopied: boolean = false;
  public count: number = null;
  public auditoryPercent: number = null;
  public costTypeMode: AdCostTypeModes;
  public incrediblesCopyType: IncrediblesCopyType;
  public price = 0;
  public groups: string;
  public groups_not: string;
  public retargeting_groups: string = '';
  public retargeting_groups_not: string = '';
  public groups_active: string = '';
  public interestGroups: string;

  public get IsActive() {
    return this.status === 1;
  }

  public get ContainsRetargetingGroups() {
    return this.retargeting_groups !== '' && !isNullOrUndefined(this.retargeting_groups);
  }

  constructor() {
  }

  public ConvertAd(ad: Ad) {
    this.id = ad.id;
    this.name = ad.name;
    this.status = ad.status;
    this.cpm = ad.cpm / 100;
    this.cpc = ad.cpc;
    this.ad_format = ad.ad_format;
    this.ad_platform = ad.ad_platform;
    this.ad_platform_no_wall = ad.ad_platform_no_wall;
    this.autobidding = ad.autobidding;
    this.campaign_id = ad.campaign_id;
    this.category1_id = ad.category1_id;
    this.category2_id = ad.category2_id;
    this.all_limit = ad.all_limit;
    this.day_limit = ad.day_limit;
    this.video = ad.video;
    this.impressions_limited = ad.impressions_limited;
    this.impressions_limit = ad.impressions_limit;
    this.events_retargeting_groups = ad.events_retargeting_groups;
    this.desclaimer_medical = ad.desclaimer_specialist;
    this.desclaimer_medical = ad.desclaimer_medical;
    this.cost_type = ad.cost_type;
    this.approved = ad.approved;
    this.disclaimer_supplements = ad.disclaimer_supplements;
    this.className = (this.approved === 3) ? 'ad_rejected' : '';
    if (ad.cost_type) {
      this.costTypeMode = AdCostTypeModes.CPM_ONLY_MODE;
      this.price = this.cpm;
    } else {
      this.costTypeMode = AdCostTypeModes.CPC_ONLY_MODE;
      this.price = this.cpc / 100;
    }
  }

  public AddStatistic(adStatistic: Statistics) {
    const statistic: StatisticsData = this.SumStatistic(adStatistic.stats);
    if (statistic) {
      this.impressions = statistic.impressions || 0;
      this.clicks = statistic.clicks || 0;
      this.spent = statistic.spent || 0;
      this.reach = statistic.reach;
      this.join_rate = statistic.join_rate;
      this.pricing_subscribe = this.spent / this.join_rate;
      this.ctr = (statistic.impressions > 0) ? (statistic.clicks / statistic.impressions * 100) : 0;
    } else {
      this.impressions = 0;
      this.clicks = 0;
      this.spent = 0;
      this.reach = 0;
      this.join_rate = 0;
      this.pricing_subscribe = 0;
      this.ctr = 0;
    }
  }

  public AddReach(adReach: PostReach) {
    if (adReach) {
      this.video_views_3s = adReach.video_views_3s;
      this.video_views_25p = adReach.video_views_25p;
      this.video_views_50p = adReach.video_views_50p;
      this.video_views_75p = adReach.video_views_75p;
      this.video_views_100p = adReach.video_views_100p;
      this.video_views_start = adReach.video_views_start;
      this.hide = adReach.hide;
      this.join_group = adReach.join_group || 0;
      this.links = adReach.links;
      this.reach_subscribers = adReach.reach_subscribers;
      this.reach_total = adReach.reach_total;
      this.unsubscribe = adReach.unsubscribe;
      this.report = adReach.report;
      this.reach_not_subscribers = (adReach) ? adReach.reach_total - adReach.reach_subscribers : 0;
      this.to_group = adReach.to_group || 0;
    }
  }

  AddLayout(adLayout: AdLayout, vkUrlExtractor: UrlExctractorService) {
    if (adLayout) {
      this.image_src = adLayout.image_src;
      this.image_src_2x = adLayout.image_src_2x;
      this.link_domain = adLayout.link_domain;
      this.link_url = adLayout.link_url;
      this.preview_link = adLayout.preview_link;
      this.title = adLayout.title;
    }
    this.ecpc = (this.clicks > 0) ? this.spent / this.clicks : 0;
  }

  AddUtms(vkExtractService, extractService, adWall) {
    let utm;
    if (this.ad_format === 9) {
      const ids1 = this.link_url
        .replace('https://vk.com/wall', '')
        .replace('http://vk.com/wall', '')
        .split('_')
        .map(adl => parseInt(adl, 10));
      utm = vkExtractService.ExtractUtm(adWall);
    } else {
      utm = extractService.ExtractUtm(this.link_url);
    }
    this.utms = utm;
  }

  // AddWall(adWall: WallPost, buttons: Array<PostMonitorBackend_V2ModelsAdManagerEntitiesVkPromopostButton> = null) {
  AddWall(adWall: WallPost) {
    this.likes = (adWall && adWall.likes) ? adWall.likes.count : 0;
    this.reposts = (adWall && adWall.reposts) ? adWall.reposts.count : 0;
    this.comments = (adWall && adWall.comments) ? adWall.comments.count : 0;
    this.price_click = (this.clicks > 0) ? this.spent / this.clicks : 0;
    this.price_to_group = (this.to_group > 0) ? this.spent / this.to_group : 0;
    this.wall = adWall;

    if (this.ad_format === 9) {
      this.canBeCopied = this.CanBeCopied();
    }
  }

  AddGroupsJoin() {
    const groupJoins = this.join_group || this.join_rate || 0;
    let groupJoinPrice = 0;

    if (groupJoins > 0) {
      groupJoinPrice = this.spent / groupJoins;
    }
    this.join_group = groupJoins;
    this.pricing_subscribe = groupJoinPrice;
  }

  AddYandexData(yandexData, yandex: YandexMetrikaWrapperService) {
    const t = yandex.GetMetrikaValues(this.utms);

    this.yandex_reched_goals = 0;
    this.yandex_cr_from_view = 0;
    this.yandex_cr_from_click = 0;
    this.yandex_cpa = 0;
    this.yandex_page_depth = 0;
    this.yandex_average_duration = 0;

    if (t && t.length > 0 && t[0].metrics && t[0].metrics.length > 0) {
      try {
        this.yandex_reched_goals = t[0].metrics[0];


        const pageDepthMetric = t[0].metrics[1];
        const averageDurationMetric = t[0].metrics[2];

        if (pageDepthMetric && averageDurationMetric) {
          this.yandex_page_depth = (pageDepthMetric) ? pageDepthMetric : 0;
          this.yandex_average_duration = (averageDurationMetric) ? averageDurationMetric : 0;
        }

        // Calculate ConversionRate from view (impression) & site visit (click)
        this.yandex_cr_from_view = (this.impressions > 0) ? this.yandex_reched_goals / this.impressions * 100 : 0;
        this.yandex_cr_from_click = (this.clicks > 0) ? this.yandex_reched_goals / this.clicks * 100 : 0;

        this.yandex_cpa = (this.yandex_reched_goals > 0) ? this.spent / this.yandex_reched_goals : 0;
      } catch (ex) {
        console.error(ex);
      }
    }
  }

  private Fill(data) {
    if (data !== undefined) {
      Object.keys(data)
        .forEach(field => {
          this[field] = data[field];
        });
    }
  }

  private SumStatistic(statistics: Array<StatisticsData>): StatisticsData {
    if (statistics.length > 0) {
      return Object.keys(statistics[0]).reduce((a, b) => {
        a[b] = _.sumBy(statistics, b);
        return a;
      }, {});
    } else {
      return null;
    }
  }

  AddStrategy(Strategy: UserStrategy) {
    this.strategy = Strategy;
  }

  public GetAdGroupId() {
    return this.wall.owner_id;
  }

  public GetAdAttachmentLinkTitle() {
    return this.wall.attachments[0].link.title;
  }

  public GetAdAttachmentLinkUrl() {
    return this.wall.attachments[0].link.url;
  }

  public GetTeazerVkLink() {
    const teazerLink = this.link_url;
    if (teazerLink.includes('vk.com')) {
      return teazerLink;
    } else {
      return null;
    }
  }

  public GetPostId() {
    return this.wall.id;
  }

  public getOwnerLink() {
    let link = '';
    if (this.IsPromopost()) {
      link = this.GetAdAttachmentLinkUrl();
    } else {
      link = this.link_url;
    }

    return link;
  }

  private IsPromopost() {
    return this.ad_format === 9;
  }

  AddTargetingData(adTargetingData: AdTargeting) {
    this.count = adTargetingData.count;
    this.groups = adTargetingData.groups;
    this.groups_not = adTargetingData.groups_not;
    this.groups_active = adTargetingData.groups_active;
    this.auditoryPercent = this.reach / this.count * 100;
    this.retargeting_groups = adTargetingData.retargeting_groups;
    this.retargeting_groups_not = adTargetingData.retargeting_groups_not;
    this.interestGroups = adTargetingData.interest_categories;
  }

  public CanBeCopied() {
    return !(this.IsPrettyCards() || this.IsStories() || this.IsAdaptiveFormat() || this.IsTargetingCountLess100());
  }

  private IsAdaptiveFormat() {
    const isAdaptiveFormat = this.ad_format === 11;
    if (isAdaptiveFormat) {
      this.incrediblesCopyType = IncrediblesCopyType.ADVERTISING_SITE;
    }
    return isAdaptiveFormat;
  }

  private HasButtons() {
    let hasButtons = false;
    if (this.wall && this.wall.attachments) {
      const linkAttachment = this.wall.attachments.find(attachment => attachment.type === 'link');
      if (linkAttachment) {
        hasButtons = !isNullOrUndefined(linkAttachment.link.button);
      }
      if (hasButtons) {
        this.incrediblesCopyType = IncrediblesCopyType.LINK_WITH_BUTTON;
      }
    }
    return hasButtons;
  }

  private IsPrettyCards() {
    let isPrettyCard = false;
    if (this.wall && this.wall.attachments) {
      const prettyCardAttachment = this.wall.attachments.find(attachment => attachment.type === PromopostAttachmentTypes.PRETTY_CARDS);
      isPrettyCard = !isNullOrUndefined(prettyCardAttachment);
    }
    if (isPrettyCard) {
      this.incrediblesCopyType = IncrediblesCopyType.PRETTY_CARD;
    }
    return isPrettyCard;
  }

  public IsTargetingCountLess100() {
    const isTargetingCountLess100 = this.count < 100;
    if (isTargetingCountLess100) {
      this.incrediblesCopyType = IncrediblesCopyType.AD_TARGETING_COUNT;
    }
    return isTargetingCountLess100;
  }

  private IsStories() {
    const isStories = this.ad_format === 12;
    if (isStories) {
      this.incrediblesCopyType = IncrediblesCopyType.STORIES;
    }
    return isStories;
  }

  public containsActiveGroups() {
    console.log(this.groups_active, this.groups_active !== '' && this.groups_active !== null, 'CONTAINS ACTIVE GROUPS');
    return this.groups_active !== '' && this.groups_active !== null;
  }

  public containsInterestGroups() {
    console.log(this.interestGroups, this.interestGroups !== '' && this.interestGroups !== null, 'CONTAINS INTEREST GROUPS');
    return this.interestGroups !== '' && this.interestGroups !== null;
  }

  public containsRetargetingGroups() {
    return this.retargeting_groups !== '' && this.retargeting_groups !== null
  }

  public containsLinks() {
    return this.textContainsLinks() || this.hasLinkAttachment();
  }

  private textContainsLinks() {
    return this.wall.text.match(URL_REGEXP);
  }

  private hasLinkAttachment() {
    return this.wall && this.wall.attachments ? !isNullOrUndefined(this.wall.attachments.find(x => x.type === 'link')) : false;
  }

  public GetLinkUrl() {
    let link = '';
    if (this.ad_format === 9) {
      if (this.hasLinkAttachment()) {
        const linkAttachment = this.wall.attachments.find(x => x.type === 'link');
        if (linkAttachment && linkAttachment.link) {
          link = linkAttachment.link.url;
        }
      }
    } else {
      link = this.link_url
    }
    return link;
  }

  public CanGenerateLink() {
    return this.GetLinkUrl() !== '';
  }
}


export enum PromopostAttachmentTypes {
  PRETTY_CARDS = 'pretty_cards',
  LINK = 'link',
  BUTTON = 'button',
  VIDEO = 'video',
  PHOTO = 'photo'
}
