import {Component, OnInit} from '@angular/core';
import {environment} from '../../../../../environments/environment';
import {ActivatedRoute} from '@angular/router';
import {OAuthService} from '../../../../api/services/oauth.service';
import {MatDialog} from '@angular/material';
import {AmoCrmAssignationDialogComponent} from '../../assignation-dialogs/amo-crm-assignation-dialog/amo-crm-assignation-dialog.component';
import {PlatformAssignationsService} from '../../../stores/platform-assignations/platform-assignations.service';
import {AssignationPlatforms} from '../../../stores/platform-assignations/platform-assignation.model';
import {PlatformAssignationsQuery} from "../../../stores/platform-assignations/platform-assignations.query";

@Component({
  selector: 'app-amo-crm-assignation-screen',
  templateUrl: './amo-crm-assignation-screen.component.html',
  styleUrls: ['./amo-crm-assignation-screen.component.scss']
})
export class AmoCrmAssignationScreenComponent implements OnInit {

  public baseUrl = environment.frontEndUrl;
  public provider = 'amo';
  public assigned = false;
  public loading: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private oauth: OAuthService,
    private dialog: MatDialog,
    private assignationService: PlatformAssignationsService,
    private assignationsQuery: PlatformAssignationsQuery
  ) {
  }

  ngOnInit() {
    this.CheckAssignation();

    this.assignationsQuery.assingnationStatus$(AssignationPlatforms.AMOCRM)
      .subscribe(data => {
        this.assigned = data;
      })
  }

  public AddVkAdCabinetLinkClick(): void {
    this.dialog.open(AmoCrmAssignationDialogComponent)
      .afterClosed()
      .subscribe(data => {
        this.CheckAssignation();
      });
  }

  public GetBindUrl(): string {
    return `${this.baseUrl}/${environment.apiPrefix}/OAuth/authorize/${this.provider}`;
  }

  public CheckAssignation() {
    this.loading = true;
    this.oauth.CheckProviderToken(this.provider)
      .subscribe(data => {
          this.assigned = data;
          if (data) {
            this.assignationService.assignPlatform(AssignationPlatforms.AMOCRM);
          }
          this.loading = false;
        },
        err => {
          this.loading = false;
        }
      );
  }
}
