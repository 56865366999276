<div class="custom-field-control">
    <div class="custom-field-control__control">
        <vk-form-field [labelWidth]="'169px'" controlsWidth="320px">
            <vk-label>{{FieldName}}</vk-label>
            <input type="text" vkInput [formControl]="Control" [attr.disabled]="Disabled">
            <vk-input-options>
                <button
                    cdkOverlayOrigin
                    #trigger="cdkOverlayOrigin"
                    (click)="OpenAutoCompleteOptions()"
                    class="custom-field-control__trigger"
                    [attr.disabled]="(CustomFieldsValues$ | async).length === 0"
                >
                    <mat-icon>more_vert</mat-icon>
                </button>
            </vk-input-options>
        </vk-form-field>
    </div>
</div>

<ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="IsAutocompleteOptionsOpened"
    [cdkConnectedOverlayHasBackdrop]="true"
    [cdkConnectedOverlayBackdropClass]="'popover-menu__backdrop'"
    (backdropClick)="CloseAutoCompleteOptions($event)"
    [cdkConnectedOverlayPositions]="[{originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'top'}]"
>
    <div class="custom-field-control__autocomplete">
        <ng-container *ngFor="let Value of CustomFieldsValues$ | async">
            <div class="custom-field-control-item" (click)="SelectValue(Value.Code)">
                <div class="custom-field-control-item__title">{{Value.Name}}</div>
                <div class="custom-field-control-item__description">{{Value.Description}}</div>
            </div>
        </ng-container>
    </div>
</ng-template>
