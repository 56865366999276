import { OnInit } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
import { BannersService } from "../../services/banners.service";
var BannerItemComponent = /** @class */ (function () {
    function BannerItemComponent(sanitizer, bannersService) {
        this.sanitizer = sanitizer;
        this.bannersService = bannersService;
    }
    BannerItemComponent.prototype.OnBannerClick = function () {
        this.bannersService.ConfirmViewByUser(this.banner.id);
        console.log('Banner was clicked');
        this.RedirecToToBannerTargetUrl();
    };
    Object.defineProperty(BannerItemComponent.prototype, "bannerWidth", {
        get: function () {
            return "" + this.banner.maxWidthPx;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BannerItemComponent.prototype, "bannerHeight", {
        get: function () {
            return "" + this.banner.maxHeightPx;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BannerItemComponent.prototype, "bannerCode", {
        get: function () {
            return this.sanitizer.bypassSecurityTrustHtml(this.banner.code);
        },
        enumerable: true,
        configurable: true
    });
    BannerItemComponent.prototype.ngOnInit = function () {
    };
    BannerItemComponent.prototype.RedirecToToBannerTargetUrl = function () {
        window.open(this.banner.targetUrl, '_blank');
    };
    return BannerItemComponent;
}());
export { BannerItemComponent };
