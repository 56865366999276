<form class="amo-crm-contacts-export-form" [formGroup]="form">
  <div class="amo-crm-contacts-export-form__message">
    Выберите те данные CRM-системы, которые вы хотите автоматически передавать в базы ретаргетинга (ремаркетинга) рекламной платформы
  </div>
  <div class="amo-crm-contacts-export__pipeline-selector">
    <vk-form-field orientation="vertical" controlsWidth="100%">
      <vk-label>Выберите воронку продаж</vk-label>
      <mat-select formControlName="pipelineIds" vkInput>
        <ng-container *ngFor="let pipeline of pipelines">
          <mat-option [value]="pipeline.id">{{pipeline?.name}}</mat-option>
        </ng-container>
      </mat-select>
    </vk-form-field>
  </div>
  <div class="amo-crm-contacts-export-form__item">
    <mat-checkbox class="no-margin-checkbox">Контакты</mat-checkbox>
  </div>
  <div class="amo-crm-contacts-export-form__item">
    <mat-checkbox class="no-margin-checkbox">Покупатели</mat-checkbox>
  </div>
  <div class="amo-crm-contacts-export-form__item">
    <mat-checkbox class="no-margin-checkbox">Кампании</mat-checkbox>
  </div>
</form>
