import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {IntegrationsListComponent} from './routes/integrations-list/integrations-list.component';
import {IntegrationAccessDeniedComponent} from "./routes/integration-access-denied/integration-access-denied.component";

const routes: Routes = [
  {
    path: 'integrations',
    component: IntegrationsListComponent
  },
  {
    path: 'integrations/access-denied',
    component: IntegrationAccessDeniedComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class IntegrationsRoutingModule {
}
