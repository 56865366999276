/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./ad-target-resource-selector.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../../node_modules/@angular/material/radio/typings/index.ngfactory";
import * as i3 from "@angular/material/radio";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/cdk/collections";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "@angular/forms";
import * as i8 from "@angular/common";
import * as i9 from "./ad-target-resource-selector.component";
var styles_AdTargetResourceSelectorComponent = [i0.styles];
var RenderType_AdTargetResourceSelectorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AdTargetResourceSelectorComponent, data: {} });
export { RenderType_AdTargetResourceSelectorComponent as RenderType_AdTargetResourceSelectorComponent };
function View_AdTargetResourceSelectorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "mat-radio-button", [["class", "ad-target-resource-selector__item ad-target-resource-selector-item mat-radio-button"], ["color", "primary"]], [[2, "mat-radio-checked", null], [2, "mat-radio-disabled", null], [2, "_mat-animation-noopable", null], [1, "tabindex", 0], [1, "id", 0]], [[null, "focus"]], function (_v, en, $event) { var ad = true; if (("focus" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2)._inputElement.nativeElement.focus() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatRadioButton_0, i2.RenderType_MatRadioButton)), i1.ɵdid(2, 4440064, [[1, 4]], 0, i3.MatRadioButton, [[2, i3.MatRadioGroup], i1.ElementRef, i1.ChangeDetectorRef, i4.FocusMonitor, i5.UniqueSelectionDispatcher, [2, i6.ANIMATION_MODULE_TYPE]], { color: [0, "color"], id: [1, "id"], value: [2, "value"] }, null), (_l()(), i1.ɵeld(3, 0, null, 0, 0, "div", [["class", "ad-target-resource-selector-item__name"]], [[8, "innerHTML", 1]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_5 = "primary"; var currVal_6 = _co.GetItemId(_v.context.$implicit.name); var currVal_7 = _v.context.$implicit.name; _ck(_v, 2, 0, currVal_5, currVal_6, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).checked; var currVal_1 = i1.ɵnov(_v, 2).disabled; var currVal_2 = (i1.ɵnov(_v, 2)._animationMode === "NoopAnimations"); var currVal_3 = (0 - 1); var currVal_4 = i1.ɵnov(_v, 2).id; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_8 = _v.context.$implicit.title; _ck(_v, 3, 0, currVal_8); }); }
export function View_AdTargetResourceSelectorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "ad-target-resource-selector"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [["class", "ad-target-resource-selector__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u043A\u0443\u0434\u0430 \u0431\u0443\u0434\u0443\u0442 \u043F\u0435\u0440\u0435\u0445\u043E\u0434\u0438\u0442\u044C \u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u0435\u043B\u0438 \u0441 \u043E\u0431\u044A\u044F\u0432\u043B\u0435\u043D\u0438\u044F"])), (_l()(), i1.ɵeld(3, 0, null, null, 8, "mat-radio-group", [["class", "ad-target-resource-selector__list mat-radio-group"], ["role", "radiogroup"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = ((_co.currentValue = $event) !== false);
        ad = (pd_0 && ad);
    } if (("change" === en)) {
        var pd_1 = (_co.Change() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 1064960, null, 1, i3.MatRadioGroup, [i1.ChangeDetectorRef], null, { change: "change" }), i1.ɵqud(603979776, 1, { _radios: 1 }), i1.ɵprd(1024, null, i7.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.MatRadioGroup]), i1.ɵdid(7, 671744, null, 0, i7.NgModel, [[8, null], [8, null], [8, null], [6, i7.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i7.NgControl, null, [i7.NgModel]), i1.ɵdid(9, 16384, null, 0, i7.NgControlStatus, [[4, i7.NgControl]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AdTargetResourceSelectorComponent_1)), i1.ɵdid(11, 278528, null, 0, i8.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.currentValue; _ck(_v, 7, 0, currVal_7); var currVal_8 = _co.adSources; _ck(_v, 11, 0, currVal_8); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 9).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 9).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 9).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 9).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 9).ngClassValid; var currVal_5 = i1.ɵnov(_v, 9).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 9).ngClassPending; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_AdTargetResourceSelectorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-ad-target-resource-selector", [], null, null, null, View_AdTargetResourceSelectorComponent_0, RenderType_AdTargetResourceSelectorComponent)), i1.ɵprd(5120, null, i7.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i9.AdTargetResourceSelectorComponent]), i1.ɵdid(2, 114688, null, 0, i9.AdTargetResourceSelectorComponent, [], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var AdTargetResourceSelectorComponentNgFactory = i1.ɵccf("app-ad-target-resource-selector", i9.AdTargetResourceSelectorComponent, View_AdTargetResourceSelectorComponent_Host_0, { adSources: "adSources" }, {}, []);
export { AdTargetResourceSelectorComponentNgFactory as AdTargetResourceSelectorComponentNgFactory };
