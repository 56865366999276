import { HttpClient } from '@angular/common/http';
import { AffiliateUserInfoStore } from './affiliate-user-info.store';
import { map, tap } from 'rxjs/operators';
import { environment } from "../../../environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "./affiliate-user-info.store";
import * as i2 from "@angular/common/http";
var AffiliateUserInfoService = /** @class */ (function () {
    function AffiliateUserInfoService(affiliateUserInfoStore, http) {
        this.affiliateUserInfoStore = affiliateUserInfoStore;
        this.http = http;
        this.baseUrl = environment.backendUri + "/" + environment.apiPrefix + "/affiliate-user-infos";
    }
    AffiliateUserInfoService.prototype.LoadUserDataAsync = function () {
        var _this = this;
        return this.http.get(this.baseUrl + "/current")
            .pipe(map(function (response) { return response['data']; }), tap(function (affiliateUserInfo) { return _this.affiliateUserInfoStore.update(affiliateUserInfo); }));
    };
    AffiliateUserInfoService.prototype.LoadUserData = function () {
        this.LoadUserDataAsync()
            .subscribe();
    };
    AffiliateUserInfoService.prototype.CreateAffiliateUserAsync = function (affiliateUserInfo) {
        var _this = this;
        return this.http.post("" + this.baseUrl, affiliateUserInfo)
            .pipe(tap(function (data) { return _this.LoadUserData(); }));
    };
    AffiliateUserInfoService.prototype.UpdateAffiliateUserAsync = function (affiliateUserInfo) {
        var _this = this;
        return this.http.put(this.baseUrl, affiliateUserInfo)
            .pipe(tap(function () { return _this.LoadUserData(); }));
    };
    AffiliateUserInfoService.ngInjectableDef = i0.defineInjectable({ factory: function AffiliateUserInfoService_Factory() { return new AffiliateUserInfoService(i0.inject(i1.AffiliateUserInfoStore), i0.inject(i2.HttpClient)); }, token: AffiliateUserInfoService, providedIn: "root" });
    return AffiliateUserInfoService;
}());
export { AffiliateUserInfoService };
