import {Client} from '../../../api/models';

export class AccountViewModel {

    public account_id: number;
    public account_name: string;
    public account_role: string;
    public account_status: boolean;
    public account_type: string;
    public clicks: number = 0;
    public spent: number = 0;
    public ctr: number = 0;
    public impressions: number = 0;
    public loaded = false;
    public is_blocked: boolean = true;
    public clients: Array<Client>;
    public client_id;
    public isSelected = false;
    public priority = 0;

    constructor(account_id: number, account_name: string, account_role: string, account_status: boolean, account_type: string, is_blocked: boolean, client_id?: any, priority?: number) {
        this.account_id = account_id;
        this.account_name = account_name;
        this.account_role = account_role;
        this.account_status = account_status;
        this.account_type = account_type;
        this.is_blocked = is_blocked;
        this.client_id = client_id;
        this.priority = priority || 0;
    }
}
