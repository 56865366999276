<div mat-dialog-title>Удаление объявлений</div>

<div>Удаление выполнено не полностью.</div>
<div>В следующих  объявлениях способы управления ставками не были удалены:</div>

<ul class="not-removed-ads-list">
  <ng-container *ngFor="let adTitle of notRemovedStrategiesAdsTitles">
    <li>{{adTitle}}</li>
  </ng-container>
</ul>

<div mat-dialog-actions align="end">
  <button class="btn btn-primary" mat-dialog-close>Ок</button>
</div>
