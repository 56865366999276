<form [formGroup]="form">
  <div class="services-selector">
    <div class="services-selector__select-control">
      <vk-form-field orientation="vertical" controlsWidth="100%">
        <vk-label>
          {{label}}
          <app-suggestion-info [code]="suggestionCode"></app-suggestion-info>
        </vk-label>
        <mat-select vkInput [formControlName]="controlName" [placeholder]="placeholder" [id]="id">
          <mat-option [value]="platform.code" *ngFor="let platform of uiPlatforms">
            {{platform.name}}
          </mat-option>
        </mat-select>
      </vk-form-field>
    </div>
    <div class="services-selector__service-settings">
      <ng-container [ngSwitch]="form.get(controlName).value">
        <ng-container *ngSwitchCase="types.AMO_CRM_EXPORT">
          <app-amo-crm-assignation-screen>
            <app-export-crm [type]="types.AMO_CRM_EXPORT" platform="AMOCRM"></app-export-crm>
          </app-amo-crm-assignation-screen>
        </ng-container>
        <ng-container *ngSwitchCase="types.AMO_CRM_IMPORT">
          <app-amo-crm-assignation-screen>
            <app-import-crm [type]="types.AMO_CRM_IMPORT" platform="AMOCRM"></app-import-crm>
          </app-amo-crm-assignation-screen>
        </ng-container>
        <ng-container *ngSwitchCase="types.BITRIX_EXPORT">
          <app-bitrix-assignation-screen>
            <!--            <app-export-crm [type]="types.BITRIX_EXPORT" platform="BITRIX24"></app-export-crm>-->
            <app-bitrix-export-settings></app-bitrix-export-settings>
          </app-bitrix-assignation-screen>
        </ng-container>
        <ng-container *ngSwitchCase="types.BITRIX_IMPORT">
          <app-bitrix-assignation-screen>
            <app-import-crm [type]="types.BITRIX_IMPORT" platform="BITRIX24"></app-import-crm>
          </app-bitrix-assignation-screen>
        </ng-container>
        <ng-container *ngSwitchCase="types.MEGAPLAN_EXPORT">
          <app-megaplan-assignation-screen>
            <app-export-crm [type]="types.MEGAPLAN_EXPORT" platform="MEGAPLAN"></app-export-crm>
          </app-megaplan-assignation-screen>
        </ng-container>
        <ng-container *ngSwitchCase="types.MEGAPLAN_IMPORT">
          <app-megaplan-assignation-screen>
<!--            <app-import-crm [type]="types.MEGAPLAN_IMPORT" platform="MEGAPLAN"></app-import-crm>-->
            <app-new-import-crm [type]="types.MEGAPLAN_IMPORT" platform="MEGAPLAN"></app-new-import-crm>
          </app-megaplan-assignation-screen>
        </ng-container>
        <ng-container *ngSwitchCase="types.VK_LEAD_FORMS">
          <app-vk-assignation-screen>
            <app-ad-network-export [type]="types.VK_LEAD_FORMS" platform="VK"></app-ad-network-export>
          </app-vk-assignation-screen>
        </ng-container>
        <ng-container *ngSwitchCase="types.VK_RETARGETING_GROUPS">
          <app-vk-assignation-screen>
            <app-ad-network-import [type]="types.VK_RETARGETING_GROUPS" platform="VK"></app-ad-network-import>
          </app-vk-assignation-screen>
        </ng-container>
        <ng-container *ngSwitchCase="types.MT_LEAD_FORMS">
          <app-mt-assignation-screen>
            <app-ad-network-export [type]="types.MT_LEAD_FORMS" platform="MY_TARGET"></app-ad-network-export>
          </app-mt-assignation-screen>
        </ng-container>
        <ng-container *ngSwitchCase="types.MT_REMARKETING_GROUPS">
          <app-mt-assignation-screen>
            <app-ad-network-import [type]="types.MT_REMARKETING_GROUPS" platform="MY_TARGET"></app-ad-network-import>
          </app-mt-assignation-screen>
        </ng-container>
        <ng-container *ngSwitchCase="types.UON_IMPORT">
          <app-uon-travel-assignation-screen>
            <app-import-crm [type]="types.UON_IMPORT" platform="UON"></app-import-crm>
            <!--            <app-u-on-travel-import-screen></app-u-on-travel-import-screen>-->
          </app-uon-travel-assignation-screen>
        </ng-container>
        <ng-container *ngSwitchCase="types.UON_EXPORT">
          <app-uon-travel-assignation-screen>
<!--            <app-export-crm [type]="types.UON_EXPORT" platform="UON"></app-export-crm>-->
            <app-new-export-crm [type]="types.UON_EXPORT"></app-new-export-crm>
          </app-uon-travel-assignation-screen>
        </ng-container>
        <ng-container *ngSwitchCase="types.YANDEX_AUDIENCES">
          <app-yandex-metrika-assignation-screen>
            <app-ad-network-import [type]="types.YANDEX_AUDIENCES" platform="YANDEX"></app-ad-network-import>
          </app-yandex-metrika-assignation-screen>
        </ng-container>
<!--        <ng-container *ngSwitchCase="types.MOY_SKLAD_EXPORT">-->
<!--          <app-moi-sklad-assignation-screen>-->
<!--            <app-moi-sklad-export [type]="types.MOY_SKLAD_EXPORT" platform="MOI_SKLAD"></app-moi-sklad-export>-->
<!--          </app-moi-sklad-assignation-screen>-->
<!--        </ng-container>-->
<!--        <ng-container *ngSwitchCase="types.MOY_SKLAD_IMPORT">-->
<!--          <app-moi-sklad-assignation-screen>-->
<!--&lt;!&ndash;            <app-moi-sklad-import [type]="types.MOY_SKLAD_IMPORT" platform="MOI_SKLAD"></app-moi-sklad-import>&ndash;&gt;-->
<!--            <app-import-crm [type]="types.MOY_SKLAD_IMPORT" platform="MOI_SKLAD"></app-import-crm>-->
<!--          </app-moi-sklad-assignation-screen>-->
<!--        </ng-container>-->

        <ng-container *ngSwitchCase="types.FB_LEAD_FORMS">
          <app-facebook-assignation-screen>
            <app-ad-network-export [type]="types.FB_LEAD_FORMS" platform="FACEBOOK"></app-ad-network-export>
<!--            <app-facebook-lead-ads></app-facebook-lead-ads>-->
          </app-facebook-assignation-screen>
        </ng-container>

        <ng-container *ngSwitchCase="types.FB_CUSTOM_AUDIENCES">
          <app-facebook-assignation-screen>
            <app-ad-network-import [type]="types.FB_CUSTOM_AUDIENCES" platform="FACEBOOK"></app-ad-network-import>
          </app-facebook-assignation-screen>
        </ng-container>
      </ng-container>
    </div>
  </div>
</form>
<!--432-->
