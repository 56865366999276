import { EventEmitter, OnInit } from '@angular/core';
import { MatVerticalStepper } from '@angular/material';
import { AdFormManagerService } from '../../../new-ad-manager-shared/services/ad-form-manager/ad-form-manager.service';
import { flatMap } from "rxjs/operators";
import { LoggingService } from "../../../../../shared/services/logging.service";
var NewAdFormLiteComponent = /** @class */ (function () {
    function NewAdFormLiteComponent(adFormManagerService, logger) {
        this.adFormManagerService = adFormManagerService;
        this.logger = logger;
        this.adsSaved = new EventEmitter();
        this.checkingAccount = false;
    }
    Object.defineProperty(NewAdFormLiteComponent.prototype, "ShowActiveAccounts", {
        get: function () {
            return this.adFormManagerService.ShowActiveAccounts();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewAdFormLiteComponent.prototype, "form", {
        get: function () {
            return this.adFormManagerService.adForm;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewAdFormLiteComponent.prototype, "IsPromopost", {
        get: function () {
            return this.adFormManagerService.IsSelectFormatPromopost;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewAdFormLiteComponent.prototype, "IsUpdate", {
        get: function () {
            return this.adFormManagerService.IsUpdate;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewAdFormLiteComponent.prototype, "createAdLoading", {
        get: function () {
            return this.adFormManagerService.loadingCreateAd;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewAdFormLiteComponent.prototype, "IsAdValid", {
        get: function () {
            return this.adFormManagerService.IsValidaAd();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewAdFormLiteComponent.prototype, "IsValidTargeting", {
        get: function () {
            return this.adFormManagerService.IsValidTargeting();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewAdFormLiteComponent.prototype, "errors", {
        get: function () {
            return this.adFormManagerService.getErrors();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewAdFormLiteComponent.prototype, "HasAssignedAccounts", {
        get: function () {
            return this.adFormManagerService.HasAssignedAccounts();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewAdFormLiteComponent.prototype, "IsAssignedCabinetProcessLoading", {
        get: function () {
            return this.adFormManagerService.assignAccountLoadingLite;
        },
        enumerable: true,
        configurable: true
    });
    NewAdFormLiteComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.stepper.selectionChange.subscribe(function (selectionState) {
            var countOfSteps = _this.stepper.steps.length;
            var selectedStepIndex = selectionState.selectedIndex + 1;
            if (countOfSteps === selectedStepIndex) {
                _this.checkForExistingSystemCampaignsAndCreateIfNotExists(true);
                // if (!this.adFormManagerService.IsValidCampaignData()) {
                //
                // }
            }
        });
    };
    NewAdFormLiteComponent.prototype.createAd = function () {
        var specifications = this.adFormManagerService.getSpecifications();
        this.adsSaved.emit(specifications);
    };
    NewAdFormLiteComponent.prototype.checkForExistingSystemCampaignsAndCreateIfNotExists = function (check) {
        var _this = this;
        if (check === void 0) { check = true; }
        this.adFormManagerService.assignAccountLoadingLite = true;
        var accountId = this.adFormManagerService.getAccountData().accountId;
        console.log('CONSOLE LOG CHECKING CAMPAIGN SYSTEM', accountId);
        if (check) {
            this.adFormManagerService.LoadAdCabinetData()
                .pipe(flatMap(function () { return _this.adFormManagerService.checkForSystemCampaignsAndCreateIfNotExitis(); }), flatMap(function () { return _this.adFormManagerService.LoadTargetingStats(); }))
                .subscribe(function () {
                console.log('subscribe');
                _this.checkingAccount = false;
                if (!_this.adFormManagerService.IsValidCampaignData()) {
                    _this.logger.AddExtra('campaign', _this.adFormManagerService.campaigns);
                    _this.logger.AddExtra('account', _this.adFormManagerService.accounts);
                    _this.logger.Error('Не удалось загрузить кампании');
                }
                _this.adFormManagerService.assignAccountLoadingLite = false;
            });
        }
        else {
            console.log('STEPPER NEXT');
            this.stepper.next();
        }
    };
    NewAdFormLiteComponent.prototype.checkForExistingSystemCampaignsAndCreateIfNotExistsAndContainsAccounts = function () {
        console.log(this.ShowActiveAccounts);
        if (this.ShowActiveAccounts) {
            this.checkForExistingSystemCampaignsAndCreateIfNotExists();
        }
        else {
            console.log('STEPPER NEXT FUCK');
            this.stepper.next();
        }
    };
    return NewAdFormLiteComponent;
}());
export { NewAdFormLiteComponent };
