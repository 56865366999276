var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { map as __map, filter as __filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../api-configuration";
import * as i2 from "@angular/common/http";
var UserStrategiesService = /** @class */ (function (_super) {
    __extends(UserStrategiesService, _super);
    function UserStrategiesService(config, http) {
        return _super.call(this, config, http) || this;
    }
    /**
     * @return Success
     */
    UserStrategiesService.prototype.GetAllUserStrategiesResponse = function () {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/user-strategies", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @return Success
     */
    UserStrategiesService.prototype.GetAllUserStrategies = function () {
        return this.GetAllUserStrategiesResponse().pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param strategy undefined
     * @return Success
     */
    UserStrategiesService.prototype.UpdateUserStrategiesResponse = function (strategy) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = strategy;
        var req = new HttpRequest('PUT', this.rootUrl + "/apiv2/user-strategies", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param strategy undefined
     * @return Success
     */
    UserStrategiesService.prototype.UpdateUserStrategies = function (strategy) {
        return this.UpdateUserStrategiesResponse(strategy).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param strategy undefined
     * @return Success
     */
    UserStrategiesService.prototype.CreateUserStrategyResponse = function (strategy) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = strategy;
        var req = new HttpRequest('POST', this.rootUrl + "/apiv2/user-strategies", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param strategy undefined
     * @return Success
     */
    UserStrategiesService.prototype.CreateUserStrategy = function (strategy) {
        return this.CreateUserStrategyResponse(strategy).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `UserStrategiesService.UpdateUserStrategyInputParams` containing the following parameters:
     *
     * - `value`:
     *
     * - `inputId`:
     *
     * - `adId`:
     *
     * - `accountId`:
     *
     * - `clientId`:
     *
     * @return Success
     */
    UserStrategiesService.prototype.UpdateUserStrategyInputResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.value != null)
            __params = __params.set('value', params.value.toString());
        if (params.inputId != null)
            __params = __params.set('inputId', params.inputId.toString());
        if (params.adId != null)
            __params = __params.set('adId', params.adId.toString());
        if (params.accountId != null)
            __params = __params.set('accountId', params.accountId.toString());
        if (params.clientId != null)
            __params = __params.set('clientId', params.clientId.toString());
        var req = new HttpRequest('PUT', this.rootUrl + "/apiv2/user-strategies/input", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `UserStrategiesService.UpdateUserStrategyInputParams` containing the following parameters:
     *
     * - `value`:
     *
     * - `inputId`:
     *
     * - `adId`:
     *
     * - `accountId`:
     *
     * - `clientId`:
     *
     * @return Success
     */
    UserStrategiesService.prototype.UpdateUserStrategyInput = function (params) {
        return this.UpdateUserStrategyInputResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param specification undefined
     * @return Success
     */
    UserStrategiesService.prototype.EditUserStrategyInputsResponse = function (specification) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = specification;
        var req = new HttpRequest('POST', this.rootUrl + "/apiv2/user-strategies/edit", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param specification undefined
     * @return Success
     */
    UserStrategiesService.prototype.EditUserStrategyInputs = function (specification) {
        return this.EditUserStrategyInputsResponse(specification).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `UserStrategiesService.GetStrategiesByAdAndAccountIdsParams` containing the following parameters:
     *
     * - `clientId`:
     *
     * - `accountId`:
     *
     * - `viewModel`:
     *
     * @return Success
     */
    UserStrategiesService.prototype.GetStrategiesByAdAndAccountIdsResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.clientId != null)
            __params = __params.set('clientId', params.clientId.toString());
        __body = params.viewModel;
        var req = new HttpRequest('POST', this.rootUrl + ("/apiv2/user-strategies/" + params.accountId), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `UserStrategiesService.GetStrategiesByAdAndAccountIdsParams` containing the following parameters:
     *
     * - `clientId`:
     *
     * - `accountId`:
     *
     * - `viewModel`:
     *
     * @return Success
     */
    UserStrategiesService.prototype.GetStrategiesByAdAndAccountIds = function (params) {
        return this.GetStrategiesByAdAndAccountIdsResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `UserStrategiesService.RemoveStrategyParams` containing the following parameters:
     *
     * - `adId`:
     *
     * - `accountId`:
     *
     * @return Success
     */
    UserStrategiesService.prototype.RemoveStrategyResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.adId != null)
            __params = __params.set('adId', params.adId.toString());
        var req = new HttpRequest('DELETE', this.rootUrl + ("/apiv2/user-strategies/" + (params.accountId)), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `UserStrategiesService.RemoveStrategyParams` containing the following parameters:
     *
     * - `adId`:
     *
     * - `accountId`:
     *
     * @return Success
     */
    UserStrategiesService.prototype.RemoveStrategy = function (params) {
        return this.RemoveStrategyResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param toggleAdsData undefined
     * @return Success
     */
    UserStrategiesService.prototype.ToggleAdStatusResponse = function (toggleAdsData) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = toggleAdsData;
        var req = new HttpRequest('POST', this.rootUrl + "/apiv2/user-strategies/toggle-ad-status", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param toggleAdsData undefined
     * @return Success
     */
    UserStrategiesService.prototype.ToggleAdStatus = function (toggleAdsData) {
        return this.ToggleAdStatusResponse(toggleAdsData).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param toggleStrategiesData undefined
     * @return Success
     */
    UserStrategiesService.prototype.ToggleStrategiesResponse = function (toggleStrategiesData) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = toggleStrategiesData;
        var req = new HttpRequest('POST', this.rootUrl + "/apiv2/user-strategies/toggle-strategies", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param toggleStrategiesData undefined
     * @return Success
     */
    UserStrategiesService.prototype.ToggleStrategies = function (toggleStrategiesData) {
        return this.ToggleStrategiesResponse(toggleStrategiesData).pipe(__map(function (_r) { return _r.body; }));
    };
    UserStrategiesService.GetAllUserStrategiesPath = '/apiv2/user-strategies';
    UserStrategiesService.UpdateUserStrategiesPath = '/apiv2/user-strategies';
    UserStrategiesService.CreateUserStrategyPath = '/apiv2/user-strategies';
    UserStrategiesService.UpdateUserStrategyInputPath = '/apiv2/user-strategies/input';
    UserStrategiesService.EditUserStrategyInputsPath = '/apiv2/user-strategies/edit';
    UserStrategiesService.GetStrategiesByAdAndAccountIdsPath = '/apiv2/user-strategies/{accountId}';
    UserStrategiesService.RemoveStrategyPath = '/apiv2/user-strategies/{accountId}';
    UserStrategiesService.ToggleAdStatusPath = '/apiv2/user-strategies/toggle-ad-status';
    UserStrategiesService.ToggleStrategiesPath = '/apiv2/user-strategies/toggle-strategies';
    UserStrategiesService.ngInjectableDef = i0.defineInjectable({ factory: function UserStrategiesService_Factory() { return new UserStrategiesService(i0.inject(i1.ApiConfiguration), i0.inject(i2.HttpClient)); }, token: UserStrategiesService, providedIn: "root" });
    return UserStrategiesService;
}(__BaseService));
export { UserStrategiesService };
