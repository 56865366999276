var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { map as __map, filter as __filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../api-configuration";
import * as i2 from "@angular/common/http";
var StrategiesService = /** @class */ (function (_super) {
    __extends(StrategiesService, _super);
    function StrategiesService(config, http) {
        return _super.call(this, config, http) || this;
    }
    /**
     * @param params The `StrategiesService.GetAllStrategiesParams` containing the following parameters:
     *
     * - `showInactive`:
     *
     * - `onlyAllowedInCreationModule`:
     *
     * @return Success
     */
    StrategiesService.prototype.GetAllStrategiesResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.showInactive != null)
            __params = __params.set('showInactive', params.showInactive.toString());
        if (params.onlyAllowedInCreationModule != null)
            __params = __params.set('onlyAllowedInCreationModule', params.onlyAllowedInCreationModule.toString());
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/strategies", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `StrategiesService.GetAllStrategiesParams` containing the following parameters:
     *
     * - `showInactive`:
     *
     * - `onlyAllowedInCreationModule`:
     *
     * @return Success
     */
    StrategiesService.prototype.GetAllStrategies = function (params) {
        return this.GetAllStrategiesResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param strategy undefined
     * @return Success
     */
    StrategiesService.prototype.UpdateStrategyResponse = function (strategy) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = strategy;
        var req = new HttpRequest('PUT', this.rootUrl + "/apiv2/strategies", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param strategy undefined
     * @return Success
     */
    StrategiesService.prototype.UpdateStrategy = function (strategy) {
        return this.UpdateStrategyResponse(strategy).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param strategy undefined
     * @return Success
     */
    StrategiesService.prototype.CreateStrategyResponse = function (strategy) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = strategy;
        var req = new HttpRequest('POST', this.rootUrl + "/apiv2/strategies", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param strategy undefined
     * @return Success
     */
    StrategiesService.prototype.CreateStrategy = function (strategy) {
        return this.CreateStrategyResponse(strategy).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param id undefined
     * @return Success
     */
    StrategiesService.prototype.GetStrategyByIdResponse = function (id) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('GET', this.rootUrl + ("/apiv2/strategies/" + (id)), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param id undefined
     * @return Success
     */
    StrategiesService.prototype.GetStrategyById = function (id) {
        return this.GetStrategyByIdResponse(id).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param id undefined
     * @return Success
     */
    StrategiesService.prototype.RemoveStrategyResponse = function (id) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('DELETE', this.rootUrl + ("/apiv2/strategies/" + (id)), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param id undefined
     * @return Success
     */
    StrategiesService.prototype.RemoveStrategy = function (id) {
        return this.RemoveStrategyResponse(id).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @return Success
     */
    StrategiesService.prototype.GetBidRangesResponse = function () {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/strategies/getbidranges", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @return Success
     */
    StrategiesService.prototype.GetBidRanges = function () {
        return this.GetBidRangesResponse().pipe(__map(function (_r) { return _r.body; }));
    };
    StrategiesService.GetAllStrategiesPath = '/apiv2/strategies';
    StrategiesService.UpdateStrategyPath = '/apiv2/strategies';
    StrategiesService.CreateStrategyPath = '/apiv2/strategies';
    StrategiesService.GetStrategyByIdPath = '/apiv2/strategies/{id}';
    StrategiesService.RemoveStrategyPath = '/apiv2/strategies/{id}';
    StrategiesService.GetBidRangesPath = '/apiv2/strategies/getbidranges';
    StrategiesService.ngInjectableDef = i0.defineInjectable({ factory: function StrategiesService_Factory() { return new StrategiesService(i0.inject(i1.ApiConfiguration), i0.inject(i2.HttpClient)); }, token: StrategiesService, providedIn: "root" });
    return StrategiesService;
}(__BaseService));
export { StrategiesService };
