import { ChangeDetectorRef, EventEmitter, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { isNullOrUndefined } from 'util';
import { AccountViewModel } from '../../../../models/view-models/account-view-model';
import { PickListComponent } from '../../../../components/pick-list/pick-list.component';
import { DgPaginationComponent } from '../../../../../ngr-ui/components/dg-pagination/dg-pagination.component';
import { AutomationVkService } from '../../../../../api/services/automation-vk.service';
import { DexieCabinetsService } from '../../../../../shared/services/dexie/dexie-cabinets.service';
import { AutomationVkSlotService } from '../../../../../api/services/automation-vk-slot.service';
import { AutomationExpirationManagerService } from '../../../../services/automation-expiration-manager.service';
import { DexieClientsService } from '../../../../../shared/services/dexie/dexie-clients.service';
import { AdManagerService } from '../../../../../api/services';
import { MatDialog } from '@angular/material';
import { CreateCampaignFormComponent } from '../../create-campaign-form/create-campaign-form.component';
import { AdViewModel } from '../../../../models/view-models/ad-view-model';
import { ActivatedRoute } from '@angular/router';
import { ControlContainer } from '@angular/forms';
import { debounceTime, distinctUntilChanged, flatMap, tap } from 'rxjs/operators';
var AdAccountManagerComponent = /** @class */ (function () {
    function AdAccountManagerComponent(_api, localDb, _db, accessManager, localClientsDb, adManagerService, dialog, route, controlContainer, changeDetector) {
        this._api = _api;
        this.localDb = localDb;
        this._db = _db;
        this.accessManager = accessManager;
        this.localClientsDb = localClientsDb;
        this.adManagerService = adManagerService;
        this.dialog = dialog;
        this.route = route;
        this.controlContainer = controlContainer;
        this.changeDetector = changeDetector;
        this.token = '2d74c771895089eb9c237b388499b6877a967ea5b25935215fd489276327ceed6c4cf49708b44cb4f1a6b';
        this.accounts = null;
        this.accountsViewModels = [];
        this.accountsStatistics = [];
        this.IsExpired = true;
        this.opened = false;
        this.pickItems = [];
        this.countOfMaxSlots = 0;
        this.countOfFree = 0;
        this.firstLoading = true;
        this.loading = false;
        this.openedActivationCabinet = false;
        this.accountId = null;
        this.account_role = null;
        this.campaignId = null;
        this.disabled = false;
        this.loadingCampaigns = false;
        this.campaigns = [];
        this.selectedCampaignsType = '';
        this.ad = null;
        this.onNext = new EventEmitter();
        this.onSave = new EventEmitter();
    }
    Object.defineProperty(AdAccountManagerComponent.prototype, "CampaingsFiltered", {
        get: function () {
            var _this = this;
            return this.campaigns.filter(function (campaign) { return campaign.type === _this.selectedCampaignsType; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdAccountManagerComponent.prototype, "AccountsFiltered", {
        get: function () {
            return this.accounts.filter(function (x) { return x.account_type !== 'agency'; });
        },
        enumerable: true,
        configurable: true
    });
    AdAccountManagerComponent.prototype.ngOnInit = function () {
        var _this = this;
        // this.LoadData();
        if (this.accounts.length > 0) {
            var account = this.accounts.find(function (x) { return !x.is_blocked; });
            console.log(account, 'ACCOUNT ID');
            if (account) {
                this.controlContainer.control.get('accountId').setValue(account.account_id + "_" + account.client_id);
                this.changeDetector.detectChanges();
            }
        }
        this.controlContainer.control.get('accountId')
            .valueChanges
            .pipe(distinctUntilChanged())
            .subscribe(function (data) {
            console.log('CHANGING CAMPAIGN');
            _this.LoadCampaign();
        });
    };
    AdAccountManagerComponent.prototype.LoadData = function () {
        var _this = this;
        this.LoadSlots()
            .pipe(debounceTime(1000), flatMap(function (data) { return _this.LoadAccounts(); }))
            .subscribe(function (data) {
            console.log(data);
        });
    };
    AdAccountManagerComponent.prototype.SelectFirstCampaignByType = function (param) {
    };
    AdAccountManagerComponent.prototype.LoadSlots = function () {
        var _this = this;
        return this._db.GetSlots()
            .pipe(tap(function (slots) {
            _this.slots = slots.data;
            console.log(_this.slots);
        }));
    };
    AdAccountManagerComponent.prototype.LoadAccounts = function () {
        var _this = this;
        console.log(this.HaveActivatedCabinets());
        if (this.HaveActivatedCabinets()) {
            return this._api.GetAccounts()
                .pipe(tap(function (accounts) {
                _this.accounts = accounts.data.map(function (account, i) {
                    console.log(account, "Account " + i, _this.slots);
                    return new AccountViewModel(account.account_id, account.account_name, account.access_role, account.account_status, account.account_type, _this.CanCabinetSelected(account) || account.account_type === 'agency  ', account.client_id, i);
                });
                if (!_this.controlContainer.control.get('accountId').value || _this.controlContainer.control.get('accountId').value.includes('undefined')) {
                    var account = _this.accounts.filter(function (acc) { return !acc.is_blocked && acc.account_type !== 'agency'; });
                    console.log(account);
                    _this.controlContainer.control.get('accountId').setValue(account[0].account_id + "_" + account[0].client_id);
                }
            }));
        }
        return [];
    };
    AdAccountManagerComponent.prototype.CanCabinetSelected = function (account) {
        console.log(account.account_id, account.client_id, 'CLIEN ID');
        account.client_id = account.client_id === undefined ? null : account.client_id;
        var slot1 = this.slots
            .find(function (slot) { return slot.bindedCabinetId === account.account_id && slot.bindedClientId === account.client_id; });
        console.log(isNullOrUndefined(slot1), account.account_type === 'agency', 'CAN BE SELECTED');
        return isNullOrUndefined(slot1) || account.account_type === 'agency';
    };
    AdAccountManagerComponent.prototype.LoadCampaign = function () {
        var _this = this;
        this.loadingCampaigns = true;
        var _a = this.controlContainer.control.get('accountId').value.split('_'), accountId = _a[0], clientId = _a[1];
        this.controlContainer.control.get('campaignId').setValue(null);
        this._api.GetCampaigns({
            accountId: accountId,
            clientId: clientId
        })
            .delay(1500)
            .subscribe(function (campaigns) {
            _this.campaigns = campaigns.data.sort(function (a, b) { return b.id - a.id; });
            if (isNullOrUndefined(_this.controlContainer.control.get('campaignId').value) && _this.CampaingsFiltered.length > 0) {
                _this.controlContainer.control.get('campaignId').setValue(_this.CampaingsFiltered[0].id);
            }
            _this.loadingCampaigns = false;
        });
    };
    AdAccountManagerComponent.prototype.CreateCompany = function () {
        var _this = this;
        var _a = this.controlContainer.control.get('accountId').value.split('_'), accountId = _a[0], clientId = _a[1];
        this.dialog
            .open(CreateCampaignFormComponent, {
            width: '600px',
            data: {
                accountId: accountId,
                clientId: clientId,
                type: this.selectedCampaignsType
            }
        })
            .afterClosed()
            .subscribe(function (data) {
            _this.LoadCampaign();
            var campaign = data[0];
            if (campaign) {
                _this.controlContainer.control.get('campaignId').setValue(data[0].id);
            }
        });
    };
    AdAccountManagerComponent.prototype.ngOnChanges = function (changes) {
        if (changes.selectedCampaignsType && this.CampaingsFiltered.length > 0) {
            console.log(this.CampaingsFiltered);
            this.controlContainer.control.get('campaignId').setValue(this.CampaingsFiltered[0].id);
        }
        if (changes.accounts) {
            var account = this.accounts.find(function (x) { return !x.is_blocked; });
            console.log(account, 'ACCOUNT ID');
            if (account) {
                this.controlContainer.control.get('accountId').setValue(account.account_id + "_" + account.client_id);
                this.changeDetector.detectChanges();
            }
        }
    };
    AdAccountManagerComponent.prototype.GetCampaignTitle = function (account) {
        if (account.is_blocked) {
            return 'Данный кабинет не подключен к системе.';
        }
    };
    AdAccountManagerComponent.prototype.OnSaveCabinetsBined = function (data) {
        var _this = this;
        if (!data.isSelected) {
            this._db.AssignCabinetToSlot({
                id: data.account_id,
                clientId: data.client_id
            })
                .subscribe(function (data1) {
                _this.LoadData();
            }, function (err) {
                _this.pickList.SetError(err.error);
            });
        }
        else {
            this._db.UnBindCabinetFromSlot({
                id: data.account_id,
                clientId: data.client_id
            })
                .subscribe(function (data1) {
                _this.LoadData();
            }, function (err) {
                _this.pickList.SetError(err.error);
            });
        }
        // this.LoadSlots();
    };
    AdAccountManagerComponent.prototype.OnClose = function () {
        this.ToggleOpened();
    };
    AdAccountManagerComponent.prototype.ToggleOpened = function () {
        this.openedActivationCabinet = !this.openedActivationCabinet;
    };
    AdAccountManagerComponent.prototype.HaveActivatedCabinets = function () {
        return (this.slots) ? this.slots.filter(function (slot) { return slot.bindedCabinetId !== null; }).length > 0 : false;
    };
    return AdAccountManagerComponent;
}());
export { AdAccountManagerComponent };
