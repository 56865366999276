import {Component, OnInit} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {CopyingService} from "../../../services/copying.service";

@Component({
  selector: 'app-copying-by-retareting-groups',
  templateUrl: './copying-by-retareting-groups.component.html',
  styleUrls: ['./copying-by-retareting-groups.component.scss']
})
export class CopyingByRetaretingGroupsComponent implements OnInit {

  public form: FormGroup;

  constructor(
    private copyingService: CopyingService
  ) {
  }

  ngOnInit() {
    this.form = this.copyingService.form.get('retargetingGroupConfiguration') as FormGroup;
  }

}
