import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material';
import {IntegrationCreateEditFormComponent} from '../../modules/integration-form/components/integration-create-edit-form/integration-create-edit-form.component';
import {StoreService} from '../../services/store.service';
import {UserIntegrationService} from '../../../api/services';
import {IntegrationsTableComponent} from '../../components/integrations-table/integrations-table.component';
import {UsersIntegrationsService} from "../../store/user-integrations/user-integration.service";
import {UserIntegrationQuery} from "../../store/user-integrations/user-integration.query";
import {IntegrationSessionService} from "../../store/integrations-session/integration-session.service";
import {IntegrationSessionQuery} from "../../store/integrations-session/integration-session.query";
import {map} from "rxjs/operators";

@Component({
  selector: 'app-integrations-list',
  templateUrl: './integrations-list.component.html',
  styleUrls: ['./integrations-list.component.scss']
})
export class IntegrationsListComponent implements OnInit {

  public userIntegrations$ = this.userIntegrationsQuery.userIntegrations$;
  public sessionExhausted$ = this.integrationSessionQuery.currentSession$
    .pipe(
      map(x => x.isLimitExhausted)
    );

  integrationFormOpened: boolean = false;
  private integrations: Array<any> = [];
  @ViewChild(IntegrationsTableComponent) table: IntegrationsTableComponent;

  constructor(
    private dialog: MatDialog,
    private store: StoreService,
    private userIntegrationService: UserIntegrationService,
    private userIntegrationsService: UsersIntegrationsService,
    private userIntegrationsQuery: UserIntegrationQuery,
    private integrationSessionService: IntegrationSessionService,
    public integrationSessionQuery: IntegrationSessionQuery
  ) {
  }

  ngOnInit() {
    this.integrationSessionService.LoadIntegrationSessionData();
  }

  AddForm() {
    this.dialog.open(IntegrationCreateEditFormComponent, {
      maxHeight: '80vh',
      panelClass: 'integration-form-dialog'
    })
      .afterClosed()
      .subscribe(updateUserIntegrations => {
        console.log(updateUserIntegrations);
        if (updateUserIntegrations) {
          this.table.LoadUsersIntegrations();
        }
      });
  }

  public LoadUserIntegrations() {
    this.userIntegrationsService.LoadUserIntegrations();
    // console.log('LOADING USERS');
    // this.userIntegrationService.GetUserIntegrations()
    //   .subscribe((integrations) => {
    //     this.integrations = integrations.data;
    //   });
  }
}
