<div class="dialog-header">
  <div class="dialog-header__title">Копирование объявлений</div>
  <div class="dialog-header__divider"></div>
  <div class="dialog-header__close">
    <button mat-dialog-close mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>

<ng-container [ngSwitch]="formState">
  <ng-container *ngSwitchCase="'DEFAULT_FORM'">
    <form [formGroup]="form">
      <div class="copying-ads-form">
        <div class="copying-ads-form__notifications" *ngIf="isPromo || ShowRedirectUrl">
          <div class="alert alert-warning">
            <div class="alert-items">
              <div class="alert-item static">
                <div class="alert-text">
                  <ng-container *ngIf="isPromo">
                    Можно создать не более 100 объявлений в день. (Ограничение ВК)

                  </ng-container>
                  <ng-container *ngIf="isPromo || ShowRedirectUrl">
                    <br><br>
                  </ng-container>
                  <ng-container *ngIf="ShowRedirectUrl">
                    Не копируются:
                    <br>
                    <ol>
                      <li>Аудитории ретаргетинга.</li>
                    </ol>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="copying-ads-form__configurations">
          <ng-container *ngIf="ShowDefaultConfigurationForms">
            <div class="copying-ads-configuration__item copying-configuration-item_default"
              formGroupName="defaultConfiguration">
              <vk-form-field [orientation]="'vertical'" [controlsWidth]="'100%'">
                <vk-label>Введите колличество копий объявления</vk-label>
                <input type="number" formControlName="countOfCopies" vkInput>
                <vk-error validator="required">Вы не ввели колличество копий</vk-error>
                <vk-error validator="min">Вы должны сделать минимум 1 копию</vk-error>
                <vk-error validator="max">Вы не можете сделать более 100 копий</vk-error>
              </vk-form-field>
            </div>
            <div class="copying-ads-configuration__item">
              <mat-checkbox [formControl]="changeCpmCost" class="copying-ads-configuration__checkbox no-margin-checkbox"
                [disabled]="!ContainsLinks">
                Изменить модель оплаты
              </mat-checkbox>
            </div>
            <ng-container *ngIf="ShowCostConfiguration">
              <mat-radio-group formControlName="costType">
                <mat-radio-button [value]="null">Оставить без изменений</mat-radio-button>
                <br>
                <mat-radio-button [value]="1">CPM</mat-radio-button>
                <br>
                <mat-radio-button [value]="0">CPC</mat-radio-button>
              </mat-radio-group>
              <vk-form-field orientation="vertical" *ngIf="ShowCostValueControl">
                <vk-label>
                  {{costValueLabelText}}
                </vk-label>
                <input type="text" vkInput formControlName="costValue">
                <ng-container *ngIf="showErrors">
                  <vk-error name="costValue" validator="required">Это поле обязательно для заполнения</vk-error>
                  <vk-error name="costValue" validator="min">{{costValueMinMessage}}</vk-error>
                  <vk-error name="costValue" validator="max">{{costValueMaxMessage}}</vk-error>
                </ng-container>
              </vk-form-field>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="!ShowSegmentationInfo; else segmentationInfo">
            <div class="copying-ads-configuration__item">
              <mat-checkbox [formControl]="segment" class="copying-ads-configuration__checkbox no-margin-checkbox"
                [disabled]="!enableSegmentation">
                Сегментировать аудиторию
              </mat-checkbox>
              <app-suggestion-info code="SEGMENTATION_CHECKBOX"></app-suggestion-info>
            </div>
            <ng-container *ngIf="segment.value">
              <div class="copying-ads-configuration__modes">
                <mat-radio-group formControlName="configurationMode">
                  <ng-container *ngFor="let group of modes">
                    <div class="copying-modes-group">
                      <div class="copying-modes-group__meta">
                        <div class="copying-modes-group__title">{{group.title}}</div>
                        <div class="copying-modes-group__suggestion">
                          <app-suggestion-info [code]="group.suggestion_code"></app-suggestion-info>
                        </div>
                      </div>
                      <div class="copying-modes-group__children">
                        <ng-container *ngFor="let mode of group.children">
                          <div class="copying-mode" [ngClass]="{'copying-mode_full-width': mode.is_full_width}">
                            <div class="copying-mode__control">
                              <mat-radio-button [value]="mode.mode_code" [disabled]="mode.can_be_selected() === false">
                                {{mode.title}}</mat-radio-button>
                            </div>
                            <ng-container *ngIf="mode.is_new">
                              <div class="copying-mode__is-new">
                                new
                              </div>
                            </ng-container>
                            <div class="copying-mode__suggestion">
                              <app-suggestion-info [code]="mode.suggestion_code"></app-suggestion-info>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </ng-container>
                </mat-radio-group>
              </div>
              <div class="copying-ads-configuration__screens">
                <ng-container [ngSwitch]="CurrentConfigurationMode">
                  <ng-container *ngSwitchCase="'BY_GROUPS'">
                    <app-copying-by-grops-configuration></app-copying-by-grops-configuration>
                  </ng-container>
                  <ng-container *ngSwitchCase="'BY_GROUPS_FILE'">
                    <app-copying-by-groups-in-file></app-copying-by-groups-in-file>
                  </ng-container>
                  <ng-container *ngSwitchCase="'BY_SEX_AND_AGE'">
                    <app-copying-by-sex-and-age></app-copying-by-sex-and-age>
                  </ng-container>
                  <ng-container *ngSwitchCase="'BY_RETARGETING_GROUPS'">
                    <app-copying-by-retareting-groups></app-copying-by-retareting-groups>
                  </ng-container>
                  <ng-container *ngSwitchCase="'BY_ACTIVE_GROUPS_FILES'">
                    <app-copying-by-group-actives-file></app-copying-by-group-actives-file>
                  </ng-container>
                  <ng-container *ngSwitchCase="'BY_INTEREST_CATEGORIES_CHECKBOXES_TREE'">
                    <app-copying-by-interest-categories-list-checkboxes>
                    </app-copying-by-interest-categories-list-checkboxes>
                  </ng-container>
                </ng-container>
              </div>
            </ng-container>
          </ng-container>
          <div class="copying-ads-form__generation-link-configuration">
            <div class="generation-link-widget">
              <mat-checkbox class="no-margin-checkbox generation-link-widget__checkbox" formControlName="generateLinks"
                [disabled]="!enabledGenerationLink">Генерировать ссылки автоматически</mat-checkbox>
              <!--              {{generateLinks && showGenerationLinkForm === false}}-->
              <a href="javascript:void(0)" (click)="ToggleShowGenerationLink()"
                *ngIf="generateLinks && showGenerationLinkForm === false" class="generation-link-widget__show-trigger">
                Показать
              </a>
            </div>
            <br>
            <ng-container *ngIf="showGenerationLinkForm">
              <app-copying-by-links></app-copying-by-links>
            </ng-container>
          </div>
        </div>
        <div class="copying-ads-form__target-account target-account">
          <a href="javascript:void(0)" class="target-account__trigger" (click)="ToggleAdvancedMode()">Копировать
            объявления в другую кампанию или кабинет</a>
        </div>
        <app-target-account-and-campaign></app-target-account-and-campaign>
        <div class="copying-ads-form__save-button">
          <button class="vk-standart-button" (click)="save()" [disabled]="form.invalid">Скопировать объявления</button>
        </div>
      </div>
    </form>
  </ng-container>
  <ng-container *ngSwitchCase="'COPYING_PROCESS'">
    <div class="loading-screen">
      <div class="loading-screen__spinner">
        <mat-spinner [diameter]="18"></mat-spinner>
      </div>
      <div class="loading-screen__message">
        Подождите, идет копирование объявлений <br>
        Скопировано {{countOfCopyedAds}} из {{countOfAds}} объявлений
      </div>
    </div>

  </ng-container>
  <ng-container *ngSwitchCase="'COPYING_SUCCESS'">
    Копирование объявлений завершено
    <div class="multiple-copy-form__actions">
      <button class="vk-standart-button" (click)="CloseWithStatus(CloseDialogStatuse.REFRESH_PAGE)">
        Ок
      </button>
      <button class="vk-standart-button vk-standart-button_cancel"
        (click)="CloseWithStatus(CloseDialogStatuse.NAVIGATE_TO)" *ngIf="ShowRedirectUrl">
        Перейти к скопированным объявлениям
      </button>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'COPYING_FAILURE'">
    <ng-container *ngIf="isAllNotCopied; else notAllNotCopiedTemplate">
      При копировании объявлений произошла ошибка.
      <ng-container *ngIf="errorMessage; else defaultErrorMessage">
        <div [innerHTML]="errorMessage"></div>
      </ng-container>
      <ng-template #defaultErrorMessage>
        Возможно вы превысили лимит возможных объявлений
      </ng-template>
    </ng-container>
    <ng-template #notAllNotCopiedTemplate>
      <div>Копирование выполнено не полностью!</div>
      <div>Данные объявления не были скопированны:</div>
      <mat-list dense>
        <ng-container *ngFor="let adWrap of adsWithCopyingErrors">
          <mat-list-item>
            <strong matLine>{{adWrap.ad.name}}</strong>
            <span class="vk-error" matLine>{{adWrap.error.description}}</span>
          </mat-list-item>
        </ng-container>
      </mat-list>
    </ng-template>
    <div class="multiple-copy-form__actions">
      <button class="vk-standart-button" (click)="CloseWithStatus(CloseDialogStatuse.NOTHING_TO_DO)">
        Ок
      </button>
    </div>
  </ng-container>
</ng-container>

<ng-template #segmentationInfo>
  <app-segmentation-info [segmentationType]="segmentName" (advancedModeToggled)="ShowSegmentationData()">
  </app-segmentation-info>
</ng-template>