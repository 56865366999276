import { OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IntegrationFormService } from '../../../services/integration-form.service';
import { StoreService } from '../../../../../services/store.service';
import { distinctUntilChanged } from 'rxjs/operators';
var VkRetargetingGroupsComponent = /** @class */ (function () {
    function VkRetargetingGroupsComponent(service, store) {
        this.service = service;
        this.store = store;
        this.account = new FormControl();
        this.accountsLoading = false;
        this.retargetingGroups = [];
        this.searchTargetGroups = new FormControl();
        this.sortByPipeline = new FormControl(false);
        this.pipelineStatuses = [
            {
                id: 1,
                name: 'Первичный контакт'
            },
            {
                id: 2,
                name: 'Согласование'
            },
            {
                id: 3,
                name: 'Сделка успешна'
            },
            {
                id: 4,
                name: 'Сделка сорвалась'
            }
        ];
    }
    Object.defineProperty(VkRetargetingGroupsComponent.prototype, "useSortingByPipelines", {
        get: function () {
            return this.sortByPipeline.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VkRetargetingGroupsComponent.prototype, "filteredRetargetingGroup", {
        get: function () {
            var search = this.searchTargetGroups.value;
            if (search && search !== '') {
                return this.retargetingGroups.filter(function (x) { return x.name.toLowerCase().match(search); });
            }
            else {
                return this.retargetingGroups;
            }
        },
        enumerable: true,
        configurable: true
    });
    VkRetargetingGroupsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.form = this.service.formService.getControl('vkRetargetingGroups');
        this.LoadAccounts();
        this.account.valueChanges
            .pipe(distinctUntilChanged())
            .subscribe(function () {
            _this.LoadRetargetingGroups();
        });
    };
    VkRetargetingGroupsComponent.prototype.LoadAccounts = function () {
        var _this = this;
        this.accountsLoading = true;
        this.store.LoadVkAccountData()
            .subscribe(function (accounts) {
            _this.accounts = _this.store.assignedAccounts;
            _this.accountsLoading = false;
        });
    };
    VkRetargetingGroupsComponent.prototype.LoadRetargetingGroups = function () {
        var _this = this;
        var _a = this.account.value, account_id = _a.account_id, client_id = _a.client_id;
        this.store.LoadVkRetargetingGroups(account_id, client_id)
            .subscribe(function (groups) {
            _this.retargetingGroups = groups.sort(function (a, b) { return b.id - a.id; });
        });
    };
    return VkRetargetingGroupsComponent;
}());
export { VkRetargetingGroupsComponent };
