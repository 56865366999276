export class VkApiConvertSexDistributedData {
  public age_12_18: Array<number> = [];
  public age_18_21: Array<number> = [];
  public age_21_24: Array<number> = [];
  public age_24_27: Array<number> = [];
  public age_27_30: Array<number> = [];
  public age_30_35: Array<number> = [];
  public age_35_45: Array<number> = [];
  public age_45_100: Array<number> = [];

  public GetTotal(): number {
    return this.GetSum(this.age_12_18) + this.GetSum(this.age_18_21) +
      this.GetSum(this.age_21_24) + this.GetSum(this.age_24_27) +
      this.GetSum(this.age_27_30) + this.GetSum(this.age_30_35) +
      this.GetSum(this.age_35_45) + this.GetSum(this.age_45_100);
  }

  public GetSum(data: Array<number>): number {
    if (!data || data.length === 0) {
      return 0;
    }

    return data.reduce((a, b) => a + b, 0);
  }
}
