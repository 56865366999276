var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { TariffGroup } from './tariff-plan.model';
import { EntityStore, StoreConfig } from '@datorama/akita';
import * as i0 from "@angular/core";
var TariffPlansStore = /** @class */ (function (_super) {
    __extends(TariffPlansStore, _super);
    function TariffPlansStore() {
        return _super.call(this, {
            ui: {
                selectedTariffPlans: {
                    tariffGroup1: 0,
                    tariffGroup2: 0,
                    tariffGroup3: 0,
                }
            }
        }) || this;
    }
    TariffPlansStore.prototype.SelectTariffPlan = function (tariffGroupId, tariffPlanId) {
        var newTariffPlan = {};
        newTariffPlan["tariffGroup" + tariffGroupId] = tariffPlanId;
        this.update(function (state) { return (__assign({}, state, { ui: __assign({}, state.ui, { selectedTariffPlans: __assign({}, state.ui.selectedTariffPlans, newTariffPlan) }) })); });
    };
    TariffPlansStore.ngInjectableDef = i0.defineInjectable({ factory: function TariffPlansStore_Factory() { return new TariffPlansStore(); }, token: TariffPlansStore, providedIn: "root" });
    TariffPlansStore = __decorate([
        StoreConfig({ name: 'tariff-plans' }),
        __metadata("design:paramtypes", [])
    ], TariffPlansStore);
    return TariffPlansStore;
}(EntityStore));
export { TariffPlansStore };
