import {Component, Input, OnInit} from '@angular/core';
import {AdLayout} from '../../../api/models/ad-layout';

@Component({
  selector: 'app-easy-ad-preview-teaser',
  templateUrl: './easy-ad-preview-teaser.component.html',
  styleUrls: ['./easy-ad-preview-teaser.component.scss']
})
export class EasyAdPreviewTeaserComponent implements OnInit {
  @Input() public graphicsLoaded: boolean = false;

  @Input() public adLayout: AdLayout; // Where any is a correct format for ad

  constructor() {
  }

  ngOnInit() {
  }

  public GetHighestResolutionImage(): string {
    if (this.adLayout) {
      if (this.adLayout.image_src_2x) {
        return this.adLayout.image_src_2x;
      } else if (this.adLayout.image_src) {
        return this.adLayout.image_src;
      }
    }

    return null;
  }
}
