/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./integration-form-name.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "../../../../../shared/directives/content-editable.directive";
import * as i4 from "../../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i5 from "@angular/material/icon";
import * as i6 from "./integration-form-name.component";
import * as i7 from "../../services/integration-form.service";
import * as i8 from "../../../../services/store.service";
var styles_IntegrationFormNameComponent = [i0.styles];
var RenderType_IntegrationFormNameComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_IntegrationFormNameComponent, data: {} });
export { RenderType_IntegrationFormNameComponent as RenderType_IntegrationFormNameComponent };
export function View_IntegrationFormNameComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { contentEditable: 1 }), (_l()(), i1.ɵeld(1, 0, null, null, 14, "div", [["class", "integration-form-name"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 540672, null, 0, i2.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i2.ControlContainer, null, [i2.FormGroupDirective]), i1.ɵdid(4, 16384, null, 0, i2.NgControlStatusGroup, [[4, i2.ControlContainer]], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 6, "div", [["class", "integration-form-name__editable"], ["formControlName", "name"], ["placeholder", "\u0412\u0432\u0435\u0434\u0438\u0442\u0435 \u043D\u0430\u0437\u0432\u0430\u043D\u0438\u0435 \u0438\u043D\u0442\u0435\u0433\u0440\u0430\u0446\u0438\u0438"], ["tabindex", "-1"]], [[8, "contentEditable", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "input"], [null, "blur"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onInput() !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 6).onBlur() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 81920, [[1, 4]], 0, i3.ContentEditableDirective, [i1.ElementRef, i1.Renderer2], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.ContentEditableDirective]), i1.ɵdid(8, 671744, null, 0, i2.FormControlName, [[3, i2.ControlContainer], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR], [2, i2.ɵangular_packages_forms_forms_k]], { name: [0, "name"] }, null), i1.ɵprd(2048, null, i2.NgControl, null, [i2.FormControlName]), i1.ɵdid(10, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵted(-1, null, ["\u041F\u0440\u0438\u0432\u0435\u0442 \u043C\u0438\u0440"])), (_l()(), i1.ɵeld(12, 0, null, null, 3, "button", [["class", "integration-form-name__button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.focusOnEditable() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i4.View_MatIcon_0, i4.RenderType_MatIcon)), i1.ɵdid(14, 9158656, null, 0, i5.MatIcon, [i1.ElementRef, i5.MatIconRegistry, [8, null], [2, i5.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["edit"]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.form; _ck(_v, 2, 0, currVal_7); _ck(_v, 6, 0); var currVal_16 = "name"; _ck(_v, 8, 0, currVal_16); _ck(_v, 14, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 4).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 4).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 4).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 4).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 4).ngClassValid; var currVal_5 = i1.ɵnov(_v, 4).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 4).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_8 = _co.editable; var currVal_9 = i1.ɵnov(_v, 10).ngClassUntouched; var currVal_10 = i1.ɵnov(_v, 10).ngClassTouched; var currVal_11 = i1.ɵnov(_v, 10).ngClassPristine; var currVal_12 = i1.ɵnov(_v, 10).ngClassDirty; var currVal_13 = i1.ɵnov(_v, 10).ngClassValid; var currVal_14 = i1.ɵnov(_v, 10).ngClassInvalid; var currVal_15 = i1.ɵnov(_v, 10).ngClassPending; _ck(_v, 5, 0, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15); var currVal_17 = i1.ɵnov(_v, 14).inline; var currVal_18 = (((i1.ɵnov(_v, 14).color !== "primary") && (i1.ɵnov(_v, 14).color !== "accent")) && (i1.ɵnov(_v, 14).color !== "warn")); _ck(_v, 13, 0, currVal_17, currVal_18); }); }
export function View_IntegrationFormNameComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-integration-form-name", [], null, null, null, View_IntegrationFormNameComponent_0, RenderType_IntegrationFormNameComponent)), i1.ɵdid(1, 114688, null, 0, i6.IntegrationFormNameComponent, [i7.IntegrationFormService, i8.StoreService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var IntegrationFormNameComponentNgFactory = i1.ɵccf("app-integration-form-name", i6.IntegrationFormNameComponent, View_IntegrationFormNameComponent_Host_0, {}, {}, []);
export { IntegrationFormNameComponentNgFactory as IntegrationFormNameComponentNgFactory };
