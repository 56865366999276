/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./token-receiver.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./token-receiver.component";
import * as i3 from "@angular/router";
import * as i4 from "../../../shared/services/token-checker.service";
var styles_TokenReceiverComponent = [i0.styles];
var RenderType_TokenReceiverComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TokenReceiverComponent, data: {} });
export { RenderType_TokenReceiverComponent as RenderType_TokenReceiverComponent };
export function View_TokenReceiverComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" token-receiver works!\n"]))], null, null); }
export function View_TokenReceiverComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-token-receiver", [], null, null, null, View_TokenReceiverComponent_0, RenderType_TokenReceiverComponent)), i1.ɵdid(1, 114688, null, 0, i2.TokenReceiverComponent, [i3.ActivatedRoute, i4.TokenCheckerService, i3.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TokenReceiverComponentNgFactory = i1.ɵccf("app-token-receiver", i2.TokenReceiverComponent, View_TokenReceiverComponent_Host_0, {}, {}, []);
export { TokenReceiverComponentNgFactory as TokenReceiverComponentNgFactory };
