import { Component, OnInit } from '@angular/core';
import {ApiBlogService} from '../../../../api/services';

@Component({
  selector: 'app-posts-list',
  templateUrl: './posts-list.component.html',
  styleUrls: ['./posts-list.component.scss']
})
export class PostsListComponent implements OnInit {

  public posts: Array<any>;

  constructor(
    private postsService: ApiBlogService
  ) { }

  ngOnInit() {
    this.LoadPosts();
  }

  public GenerateSitemap() {
    this.postsService.GenerateSitemap()
      .subscribe(() => {
        alert('sitemap.xml перегенрирован');
      },err => {
        alert('Возникла ошибка! обратитесь к программистам!');
      });
  }

  public LoadPosts () {
    this.postsService.GetAllPosts()
      .subscribe(x => {
        this.posts = x;
      });
  }

  public TogglePublication(post) {
    if (post.isPublished) {
      this.postsService.RemoveFromPublication(post.postId)
        .subscribe(x => {
          alert('Пост снят с публикации');
          this.LoadPosts();
        }, err => {
          alert('Ошибка! Пост не найден');
          this.LoadPosts();
        });
    } else {
      this.postsService.PublishPost(post.postId)
        .subscribe(x => {
          alert('Пост опубликован');
          this.LoadPosts();
        }, err => {
          alert('Ошибка! Пост не найден');
          this.LoadPosts();
        });
    }
  }

}
