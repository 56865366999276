import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
@Pipe({
  name: 'utcToLocalDate'
})
export class UtcToLocalDatePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    console.log(value, moment.utc(value).toDate(), moment.utc(value).local(false).toDate());
    return moment.utc(value).local().format(args);
  }

}
