/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PostMonitorBackend_V2ModelsMegaplanMegaplanAuthData } from '../models/post-monitor-backend-_v2models-megaplan-megaplan-auth-data';
@Injectable({
  providedIn: 'root',
})
class MegaplanService extends __BaseService {
  static readonly AuthorizeAsyncPath = '/apiv2/mega-plan/auth';
  static readonly GetContractorDealsExtraFieldsAsyncPath = '/apiv2/mega-plan/contractor/{contractorId}/deals/extrafields';
  static readonly GetContractorTypeAsyncPath = '/apiv2/mega-plan/contractor-type';
  static readonly GetProgramsAsyncPath = '/apiv2/mega-plan/program';
  static readonly GetProgramAsyncPath = '/apiv2/mega-plan/program/{id}';
  static readonly GetEmployeesAsyncPath = '/apiv2/mega-plan/employee';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param credentials undefined
   */
  AuthorizeAsyncResponse(credentials?: PostMonitorBackend_V2ModelsMegaplanMegaplanAuthData): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = credentials;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/apiv2/mega-plan/auth`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param credentials undefined
   */
  AuthorizeAsync(credentials?: PostMonitorBackend_V2ModelsMegaplanMegaplanAuthData): __Observable<null> {
    return this.AuthorizeAsyncResponse(credentials).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `MegaplanService.GetContractorDealsExtraFieldsAsyncParams` containing the following parameters:
   *
   * - `limit`:
   *
   * - `contractorId`:
   *
   * - `pageBefore`:
   *
   * - `pageAfter`:
   *
   * - `fields`:
   *
   * - `contentType`:
   */
  GetContractorDealsExtraFieldsAsyncResponse(params: MegaplanService.GetContractorDealsExtraFieldsAsyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());

    if (params.pageBefore != null) __params = __params.set('pageBefore', params.pageBefore.toString());
    if (params.pageAfter != null) __params = __params.set('pageAfter', params.pageAfter.toString());
    (params.fields || []).forEach(val => {if (val != null) __params = __params.append('fields', val.toString())});
    if (params.contentType != null) __params = __params.set('contentType', params.contentType.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/mega-plan/contractor/${encodeURIComponent(params.contractorId)}/deals/extrafields`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `MegaplanService.GetContractorDealsExtraFieldsAsyncParams` containing the following parameters:
   *
   * - `limit`:
   *
   * - `contractorId`:
   *
   * - `pageBefore`:
   *
   * - `pageAfter`:
   *
   * - `fields`:
   *
   * - `contentType`:
   */
  GetContractorDealsExtraFieldsAsync(params: MegaplanService.GetContractorDealsExtraFieldsAsyncParams): __Observable<null> {
    return this.GetContractorDealsExtraFieldsAsyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `MegaplanService.GetContractorTypeAsyncParams` containing the following parameters:
   *
   * - `limit`:
   *
   * - `pageBefore`:
   *
   * - `pageAfter`:
   */
  GetContractorTypeAsyncResponse(params: MegaplanService.GetContractorTypeAsyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    if (params.pageBefore != null) __params = __params.set('pageBefore', params.pageBefore.toString());
    if (params.pageAfter != null) __params = __params.set('pageAfter', params.pageAfter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/mega-plan/contractor-type`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `MegaplanService.GetContractorTypeAsyncParams` containing the following parameters:
   *
   * - `limit`:
   *
   * - `pageBefore`:
   *
   * - `pageAfter`:
   */
  GetContractorTypeAsync(params: MegaplanService.GetContractorTypeAsyncParams): __Observable<null> {
    return this.GetContractorTypeAsyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `MegaplanService.GetProgramsAsyncParams` containing the following parameters:
   *
   * - `limit`:
   *
   * - `pageBefore`:
   *
   * - `pageAfter`:
   *
   * - `fields`:
   */
  GetProgramsAsyncResponse(params: MegaplanService.GetProgramsAsyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    if (params.pageBefore != null) __params = __params.set('pageBefore', params.pageBefore.toString());
    if (params.pageAfter != null) __params = __params.set('pageAfter', params.pageAfter.toString());
    (params.fields || []).forEach(val => {if (val != null) __params = __params.append('fields', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/mega-plan/program`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `MegaplanService.GetProgramsAsyncParams` containing the following parameters:
   *
   * - `limit`:
   *
   * - `pageBefore`:
   *
   * - `pageAfter`:
   *
   * - `fields`:
   */
  GetProgramsAsync(params: MegaplanService.GetProgramsAsyncParams): __Observable<null> {
    return this.GetProgramsAsyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   */
  GetProgramAsyncResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/mega-plan/program/${encodeURIComponent(id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  GetProgramAsync(id: string): __Observable<null> {
    return this.GetProgramAsyncResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `MegaplanService.GetEmployeesAsyncParams` containing the following parameters:
   *
   * - `limit`:
   *
   * - `pageBefore`:
   *
   * - `pageAfter`:
   */
  GetEmployeesAsyncResponse(params: MegaplanService.GetEmployeesAsyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    if (params.pageBefore != null) __params = __params.set('pageBefore', params.pageBefore.toString());
    if (params.pageAfter != null) __params = __params.set('pageAfter', params.pageAfter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/mega-plan/employee`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `MegaplanService.GetEmployeesAsyncParams` containing the following parameters:
   *
   * - `limit`:
   *
   * - `pageBefore`:
   *
   * - `pageAfter`:
   */
  GetEmployeesAsync(params: MegaplanService.GetEmployeesAsyncParams): __Observable<null> {
    return this.GetEmployeesAsyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module MegaplanService {

  /**
   * Parameters for GetContractorDealsExtraFieldsAsync
   */
  export interface GetContractorDealsExtraFieldsAsyncParams {
    limit: number;
    contractorId: string;
    pageBefore?: string;
    pageAfter?: string;
    fields?: Array<string>;
    contentType?: string;
  }

  /**
   * Parameters for GetContractorTypeAsync
   */
  export interface GetContractorTypeAsyncParams {
    limit: number;
    pageBefore?: string;
    pageAfter?: string;
  }

  /**
   * Parameters for GetProgramsAsync
   */
  export interface GetProgramsAsyncParams {
    limit: number;
    pageBefore?: string;
    pageAfter?: string;
    fields?: Array<string>;
  }

  /**
   * Parameters for GetEmployeesAsync
   */
  export interface GetEmployeesAsyncParams {
    limit: number;
    pageBefore?: string;
    pageAfter?: string;
  }
}

export { MegaplanService }
