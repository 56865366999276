import {Component, HostListener, Input, OnInit} from '@angular/core';
import {SystemBanner} from "../../models/system-banner.model";
import {DomSanitizer} from "@angular/platform-browser";
import {BannersService} from "../../services/banners.service";

@Component({
  selector: 'app-banner-item',
  templateUrl: './banner-item.component.html',
  styleUrls: ['./banner-item.component.scss']
})
export class BannerItemComponent implements OnInit {

  @Input() public banner: SystemBanner;

  @HostListener('mousedown')
  public OnBannerClick() {
    this.bannersService.ConfirmViewByUser(this.banner.id);
    console.log('Banner was clicked');
    this.RedirecToToBannerTargetUrl();
  }

  public get bannerWidth() {
    return `${this.banner.maxWidthPx}`;
  }

  public get bannerHeight() {
    return `${this.banner.maxHeightPx}`;
  }

  public get bannerCode() {
    return this.sanitizer.bypassSecurityTrustHtml(this.banner.code);
  }

  constructor(
    private sanitizer: DomSanitizer,
    private bannersService: BannersService
  ) {
  }

  ngOnInit() {
  }

  RedirecToToBannerTargetUrl() {
    window.open(this.banner.targetUrl, '_blank');
  }
}
