import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {UsersRoutingModule} from './users-routing.module';
import {UsersListComponent} from './routes/users-list/users-list.component';
import {NgrUiModule} from '../../ngr-ui/ngr-ui.module';
import {UserCommentsComponent} from './routes/user-comments/user-comments.component';
import {CommentFormComponent} from './components/comment-form/comment-form.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommentViewComponent} from './components/comment-view/comment-view.component';
import {SharedModule} from '../../shared/shared.module';
import {ClarityModule} from '@clr/angular';
import {AutomationModule} from '../../automation/automation.module';
import {
  MatButtonModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatDialogModule,
  MatFormFieldModule, MatInputModule, MatNativeDateModule,
  MatProgressSpinnerModule, MatTableModule, MatPaginatorModule, MatSortModule, MatSelectModule, MatMenuModule
} from '@angular/material';
import {UserCallsComponent} from './components/user-calls/user-calls.component';
import {UserCallComponent} from './components/user-call/user-call.component';
import {CallFormComponent} from './components/modals/call-form/call-form.component';
import { CallDataListComponent } from './components/call-data-list/call-data-list.component';

@NgModule({
  imports: [
    CommonModule,
    UsersRoutingModule,
    NgrUiModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    ClarityModule,
    AutomationModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    MatButtonModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatSelectModule,
    MatMenuModule,
  ],
  declarations: [UsersListComponent, UserCommentsComponent, CommentFormComponent, CommentViewComponent, UserCallsComponent, UserCallComponent, CallFormComponent, CallDataListComponent],
  entryComponents: [
    CallFormComponent
  ],
  providers: [
    MatDatepickerModule,
  ]
})
export class UsersModule {
}
