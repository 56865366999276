import {Injectable} from '@angular/core';
import {AdNetworkGroupsStore} from './ad-network-groups.store';
import {
    AdManagerService,
    AutomationVkService, FacebookAdAccountService,
    FacebookAdsService,
    MyTargetService,
    VkApiService
} from '../../../api/services';
import {PlatformsTypes} from '../../platforms-types';
import {AdNetworkGroup, createAdNetworkGroupFromVk} from './ad-network-group.model';
import {FormBuilder} from '@angular/forms';
import {PlatformsQuery} from '../platforms/platforms.query';
import {AdNetworkFormsService} from '../ad-network-forms/ad-network-forms.service';
import {delay, flatMap, tap} from 'rxjs/operators';
import {toPromise} from "rxjs-compat/operator/toPromise";
import {Observable} from "rxjs";
import {waterfall} from "../../../utils/rxjs-operators/waterfall";

@Injectable({
    providedIn: 'root'
})
export class AdNetworkGroupsService {

    constructor(
        private store: AdNetworkGroupsStore,
        private vkAdManagerService: AutomationVkService,
        private adManager: AdManagerService,
        private fb: FormBuilder,
        private platformsQuery: PlatformsQuery,
        private formsService: AdNetworkFormsService,
        private mtService: MyTargetService,
        private vkapiService: VkApiService,
        private facebookAdAccountService: FacebookAdAccountService
    ) {
        this.platformsQuery.selectedOriginPlatform.subscribe(data => {
            this.ClearStore();
        });
    }

    /**
     * Load groups by type
     * @param type
     * @constructor
     */
    public LoadGroups(type: PlatformsTypes) {
        this.store.set([]);
        switch (type) {
            case PlatformsTypes.VK_LEAD_FORMS:
                this.LoadVkGroups();
                break;
            case PlatformsTypes.MT_LEAD_FORMS:
                this.LoadMtCampaigns();
                break;
            case PlatformsTypes.FB_LEAD_FORMS:
                this.LoadFacebookAccounts();
                break;
            default:
                throw new Error(`Unknown platform type ${type}`);
        }
    }

    /**
     *
     * @constructor
     */
    public LoadVkGroups() {
        this.store.setLoading(true);
        this.vkapiService
            .GetGroupsAsync({filter: 'admin', key: 'integration'})
            .subscribe((data) => {
                this.store.set(((data as any[])
                        .map(group => createAdNetworkGroupFromVk(group))
                        .sort((a, b) => {
                            if (a.name < b.name) {
                                return -1;
                            } else if (a.name === b.name) {
                                return 0;
                            } else {
                                return 1;
                            }
                        })
                ));
                this.store.setLoading(false);
            }, err => {
                this.store.setLoading(false);
            });
    }

    /**
     *
     * @param groupName
     * @constructor
     */
    SearchGroup(groupName: any) {
        this.store.update(state => ({
            ...state,
            ui: {
                ...state.ui,
                searchGroupName: groupName
            }
        }));
    }

    /**
     *
     * @param groupId
     * @constructor
     */
    OpenGroup(groupId) {
        this.store.update(state => ({
            ...state,
            ui: {
                ...state.ui,
                openedGroupId: state.ui.openedGroupId === groupId ? null : groupId
            }
        }));
    }

    private LoadMtCampaigns() {
        this.mtService.GetCampaigns({offset: 0, limit: 50})
            .pipe(
                delay(500),
            )
            .subscribe(data => {
                if (data.data && data.data.length > 0) {
                    this.store.add(data.data.map(x => ({id: x.id, name: x.name} as AdNetworkGroup)));
                }
            });
    }

    public ClearStore() {
        this.store.reset();
    }

    private LoadFacebookAccounts() {
        this.store.setLoading(true);
        this.facebookAdAccountService.FacebookGetAdAccountsAsync({
            fields: ['name'],
            summary: ['total_count']
        })
            .subscribe((data: any) => {
                if (data && data.data) {
                    const totalCount = data.data.summary.total_count;
                    const count = data.data.data.length;
                    const countOfRequests = totalCount / count - 1;
                    const requests = [];
                    if (data.data.data) {
                        this.store.set(data.data.data.map(x => ({id: x.id, name: x.name} as AdNetworkGroup)))
                    }
                    let afterPage = data.data.paging.cursors.after;
                    if (countOfRequests < 1) {
                        this.store.setLoading(false)
                    }
                    for (let i = 0; i < countOfRequests; i++) {
                        const request = () => {
                            this.store.setLoading(true)
                            return this.facebookAdAccountService.FacebookGetAdAccountsAsync({
                                fields: ['name'],
                                summary: ['total_count'],
                                pagingAfter: afterPage
                            })
                                .pipe(
                                    tap((newData: any) => {
                                        this.store.setLoading(true)
                                        this.store.upsertMany(newData.data.data.map(x => ({
                                            id: x.id,
                                            name: x.name
                                        } as AdNetworkGroup)))
                                        afterPage = newData && newData.data && newData.data.paging && newData.data.paging.cursors && newData.data.paging.cursors.after
                                            ? newData.data.paging.cursors.after : null;
                                        this.store.setLoading(true)
                                    }),
                                    delay(1000)
                                )
                        }

                        requests.push(request);
                    }
                    if (requests.length > 0) {
                        waterfall(requests)
                            .subscribe(() => {
                                this.store.setLoading(false);
                            })
                    }
                }
            })
    }
}
