import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'minutesToTime'
})
export class MinutesToTimePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    const hours = Math.floor(value / 60);
    const minutes = value % 60;
    return `${this.toTimeFormat(hours)}:${this.toTimeFormat(minutes)}`;
  }

  private toTimeFormat(val) {
    if (val < 10) {
      val = `0${val}`;
    } else {
      val = val.toString();
    }
    return val;
  }

}
