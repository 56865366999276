<div class="strategy-settings">
  <ng-container *ngIf="userStrategy">
    <ng-container *ngIf="userStrategy.userId === -1; else userStrategyisOkay">
      <button class="btn btn-sm btn-outline-primary strategy-action" disabled="true">
        Управляется с другого аккаунта
      </button>
    </ng-container>
    <ng-template #userStrategyisOkay>
      <div class="strategy-settings__header">
        <ng-container *ngIf="userStrategy.strategy">
          <div class="strategy-settings__type">{{userStrategy.strategy.title}}</div>
        </ng-container>
      </div>
      <div class="strategy-settings__status">
        <div class="stategy-status-list">
          <div class="stategy-status-list__item">
            <ng-container *ngIf="userStrategy.status">
              <ng-container *ngIf="userStrategy.status.code === 'WORKING' && userStrategy.isStarting; else regularStatus">
                <span class="icon ion-md-time" [ngStyle]="{color: '#5181B7'}"></span>
                Запускается
              </ng-container>
              <ng-template #regularStatus>
                <span [class]="userStrategy.status.icon" [ngStyle]="{color: '#' + userStrategy.status.color}"></span>
                {{userStrategy.status.title}}
              </ng-template>
              <clr-signpost>
                <clr-signpost-content *clrIfOpen>
                  <ng-container
                    *ngIf="userStrategy.status.code === 'WORKING' && userStrategy.isStarting; else regularDescription">
                    <span>Управление ставкой в процессе запуска. Этот процесс займет около минуты.</span>
                  </ng-container>
                  <ng-template #regularDescription>
                    <span [innerHtml]="userStrategy.status.description"></span>
                  </ng-template>
                </clr-signpost-content>
              </clr-signpost>
            </ng-container>
          </div>
          <div class="stategy-status-list__item">
            <span class="strategy-status" (click)="ToggleInputsVisible()">
              {{GetToggleInputsText()}}
            </span>
            <!--<button class="btn btn-primary btn-icon btn-sm" (click)="ToggleInputsVisible()">-->
            <!--<clr-icon shape="angle" style="transform: rotate(180deg)"></clr-icon>-->
            <!--</button>-->
          </div>
        </div>
      </div>
      <div class="strategy-settings__inputs" *ngIf="IsShowedInputs">
        <app-strategy-params
          [userStrategyInputs]="userStrategy.inputs"
          [displayFunctions]="displayFunctions"
        ></app-strategy-params>
        <ng-container
          [ngTemplateOutlet]="userStrategyControlPanel"
        ></ng-container>
      </div>
    </ng-template>
  </ng-container>
  <div class="strategy-settings__actions">
    <ng-container *ngIf="!userStrategy">
      <div class="strategy-action-group" (click)="CreateStrategy()">
        <button class="btn btn-sm btn-outline-primary strategy-action" [disabled]="disabled">
          <span class="ion-md-add"></span>
          Управление ставкой
        </button>
      </div>
    </ng-container>
    <ng-template #userStrategyControlPanel>

      <div class="strategy-action-group">
        <!--<button class="btn btn-sm btn-outline-primary strategy-action" (click)="UpdateStrategy()">-->
        <!--<span class="ion-md-create"></span>-->
        <!--Изменить-->
        <!--</button>-->
        <button
          class="btn btn-sm strategy-action"
          [ngClass]="{
            'btn-success': userStrategy.isActive === false,
            'btn-danger':  userStrategy.isActive === true
          }"
          (click)="ToggleStrategyActiveStatus()"
          [disabled]="disabled || this.IsDisabledChangeStatusButton"
        >
          <span [ngClass]="{
            'ion-md-play': userStrategy.isActive === false,
            'ion-md-square': userStrategy.isActive === true
          }"></span>
          <ng-container *ngIf="userStrategy.isActive; else StrategyActiveButtonText">
            Остановить
          </ng-container>
          <ng-template #StrategyActiveButtonText>
            Запустить
          </ng-template>
        </button>

        <button class="btn btn-sm strategy-action" (click)="EditStrategy()"
                [disabled]="disabled || !userStrategy.strategy.isAvailable">
          <span class="ion-md-create"></span>
          Редактировать
        </button>

        <button class="btn btn-sm btn-outline-danger strategy-action" (click)="RemoveStrategy()" [disabled]="disabled">
          <span class="ion-md-trash"></span>
          Удалить
        </button>
      </div>
      <!--<div class="strategy-action-group">-->
      <!--<button-->
      <!--class="btn btn-sm strategy-action"-->
      <!--[ngClass]="{-->
      <!--'btn-success': userStrategy.isActive === false,-->
      <!--'btn-danger':  userStrategy.isActive === true-->
      <!--}"-->
      <!--(click)="ToggleStrategyActiveStatus()"-->
      <!--&gt;-->
      <!--<span [ngClass]="{-->
      <!--'ion-md-play': userStrategy.isActive === false,-->
      <!--'ion-md-square': userStrategy.isActive === true-->
      <!--}"></span>-->
      <!--<ng-container *ngIf="userStrategy.isActive; else StrategyActiveButtonText">-->
      <!--Остановить-->
      <!--</ng-container>-->
      <!--<ng-template #StrategyActiveButtonText>-->
      <!--Запустить-->
      <!--</ng-template>-->
      <!--</button>-->
      <!--</div>-->
    </ng-template>
  </div>
</div>


<!--<clr-modal [(clrModalOpen)]="ConfirmStartAdDialog">-->
<!--<div class="modal-title">-->
<!--Запустить объявление-->
<!--</div>-->
<!--<div class="modal-body">-->
<!--Внимание! Данное объявление не запущено. Вы хотели бы его запустить?-->
<!--</div>-->
<!--<div class="modal-footer">-->
<!--<button class="btn btn-success" (click)="OnConfirmStartAd()">Запустить</button>-->
<!--<button class="btn btn-danger" (click)="OnRejectStartAd()">Отменить запуск</button>-->
<!--</div>-->
<!--</clr-modal>-->
