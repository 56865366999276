var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AbstractCopyingConfigurator } from "./abstract-copying-configurator";
var InterestGroupsListConfigurator = /** @class */ (function (_super) {
    __extends(InterestGroupsListConfigurator, _super);
    function InterestGroupsListConfigurator(props) {
        return _super.call(this, props) || this;
    }
    InterestGroupsListConfigurator.prototype.generate = function () {
        var _this = this;
        var ads = [];
        this.copyingData.interestCategoriesList.interestIds.forEach(function (interestId) {
            var ad = _this.generateDefeault();
            ad.interestCategories = interestId.toString();
            ads.push(ad);
        });
        return ads;
    };
    return InterestGroupsListConfigurator;
}(AbstractCopyingConfigurator));
export { InterestGroupsListConfigurator };
