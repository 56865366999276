<div class="feature-status">
  <div class="feature-status__indicator" [ngClass]="{
    'feature-status__indicator_green': (contains),
    'feature-status__indicator_red': !(contains)
  }" *ngIf="!featureTariffPlanStatus?.restrictions">
    <mat-icon>
      {{featureTariffPlanStatus ? 'check' : 'close'}}
    </mat-icon>
  </div>
  <div class="feature-status__description">
    {{featureTariffPlanStatus?.restrictions}}
  </div>
</div>
