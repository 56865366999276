export interface ITeazerViewModel {
  image: string;
  title: string;
  link: string;
}

export class TeazerViewModel implements ITeazerViewModel{
  constructor (
    public image: string,
    public title: string,
    public link: string
  ) {}
}
