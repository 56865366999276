<form [formGroup]="form" class="mt-lead-forms">
  <div>Выберите формы Lead Ads, данные которых вы хотите отправить в CRM-систему</div>
  <div class="vk-lead-forms__accounts vk-lead-forms-selector">
    <div class="vk-lead-forms-selector__select">
      <vk-form-field orientation="vertical" controlsWidth="100%">
        <vk-label>Выберите кабинет</vk-label>
        <mat-select vkInput [formControl]="accountId" [disabled]="accountsLoading">
          <ng-container *ngFor="let account of accounts">
            <mat-option [value]="account">{{account.name}}</mat-option>
          </ng-container>
        </mat-select>
      </vk-form-field>
    </div>
    <div class="vk-lead-forms-selector__spinner" *ngIf="accountsLoading">
      <mat-spinner diameter="18"></mat-spinner>
    </div>
  </div>
  <div class="vk-lead-forms__campaigns vk-lead-forms-selector" *ngIf="accountId.value">
    <div class="vk-lead-forms-selector__select">
      <vk-form-field orientation="vertical" controlsWidth="100%">
        <vk-label>Выберите кампанию</vk-label>
        <mat-select vkInput [formControl]="campaignId">
          <ng-container *ngFor="let campaign of campaigns">
            <mat-option [value]="campaign">{{campaign.name}}</mat-option>
          </ng-container>
        </mat-select>
      </vk-form-field>
    </div>
    <div class="vk-lead-forms-selector__spinner" *ngIf="campaignsLoading">
      <mat-spinner diameter="18"></mat-spinner>
    </div>
  </div>
  <div class="vk-lead-forms__forms-container">
    <ng-container *ngIf="!formsLoading; else formsLoadingScreen">
      <ng-container *ngFor="let vkForm of forms">
        <div class="vk-lead-form">
          <mat-checkbox class="no-margin-checkbox">{{vkForm.name}}</mat-checkbox>
        </div>
      </ng-container>
    </ng-container>
    <ng-template #formsLoadingScreen>
      <app-loading-screen>
        Подождите, идет загрузка форм
      </app-loading-screen>
    </ng-template>
  </div>
</form>
