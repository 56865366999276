import {Component, Input, OnInit} from '@angular/core';
import {FormMessage} from "../../models/form-message";
import {FormMessageType} from "../../models/form-message-type";

@Component({
  selector: 'app-form-message',
  templateUrl: './form-message.component.html',
  styleUrls: ['./form-message.component.scss']
})
export class FormMessageComponent implements OnInit {

  @Input() public Message: FormMessage;

  public get FormMessageClass() {
    return `form-message_${this.Message.Type.toLowerCase()}`;
  }

  public get FormMessageIconClass() {
    return `form-message__icon_${this.Message.Type.toLowerCase()}`;
  }

  public get FormMessageTextClass() {
    return `form-message__text_${this.Message.Type.toLowerCase()}`;
  }

  public get MessageIcon() {
    let icon = ''
    switch (this.Message.Type) {
      case FormMessageType.SUCCESS:
        icon = 'done'
        break;
      case FormMessageType.WARNING:
        icon = 'warning'
        break;
      case FormMessageType.ERROR:
        icon = 'error'
        break;
      case FormMessageType.INFO:
        icon = 'info'
        break;
    }
    return icon;
  }

  constructor() {
  }

  ngOnInit() {
  }

}
