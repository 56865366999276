import {Injectable} from '@angular/core';
import {combineQueries, QueryEntity} from '@datorama/akita';
import {AdNetworkGroupsState, AdNetworkGroupsStore} from './ad-network-groups.store';
import {map, tap} from 'rxjs/operators';
import {AdNetworkGroup} from './ad-network-group.model';

@Injectable({providedIn: 'root'})
export class AdNetworkGroupsQuery extends QueryEntity<AdNetworkGroupsState> {

  public searchGroupName$ = this.select(state => state.ui.searchGroupName)
    .pipe(
      map(x => x.toLowerCase())
    );
  public groups$ = this.selectAll();

  public byIds$ = (groupIds) => this.selectAll({
    filterBy: entity => {
      return groupIds && groupIds.length ? groupIds.includes(entity.id) : groupIds === entity.id
    }
  });

  public isEmpty = combineQueries(
    [
      this.groups$,
      this.selectLoading()
    ]
  )
    .pipe(
      map(([groups, isLoading]) => !isLoading && groups.length === 0)
    );


  public isOpened$ = (groupId) => this.select(state => state.ui.openedGroupId === groupId);

  public isGroupSearched$ = (group: AdNetworkGroup) => this.searchGroupName$
    .pipe(
      map(groupName => groupName && groupName.length > 0 && group ? new RegExp(groupName).test(group.name.toLowerCase()) : true)
    );

  constructor(protected store: AdNetworkGroupsStore) {
    super(store);
  }

}
