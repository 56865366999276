import { FeaturesService, TariffPackagesService, TimePeriodsService } from '../../../../../src/app/api/services';
import { isNullOrUndefined } from 'util';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../../../../../src/app/api/services/tariff-packages.service";
import * as i2 from "../../../../../src/app/api/services/time-periods.service";
import * as i3 from "../../../../../src/app/api/services/features.service";
var PaymentsService = /** @class */ (function () {
    function PaymentsService(tariffPlanService, timePeriodsService, featureService) {
        this.tariffPlanService = tariffPlanService;
        this.timePeriodsService = timePeriodsService;
        this.featureService = featureService;
        this.tariffPlans$ = new BehaviorSubject([]);
        this.timePeriods$ = new BehaviorSubject([]);
        this.visibleFeatures$ = new BehaviorSubject([]);
        this.features$ = new BehaviorSubject([]);
        this.selectedTimePeriodId$ = new BehaviorSubject(null);
        this.selectedTimePeriod$ = combineLatest(this.timePeriods$, this.selectedTimePeriodId$)
            .pipe(map(function (_a) {
            var timePeriods = _a[0], selectedTimePeriodId = _a[1];
            return timePeriods.find(function (x) { return x.id === selectedTimePeriodId; });
        }), map(function (x) { return !isNullOrUndefined(x) ? x.name : ''; }));
    }
    /**
     * Загрузить все тарифные планы
     * @constructor
     */
    PaymentsService.prototype.LoadTariffPlans = function () {
        var _this = this;
        this.tariffPlanService.GetAllTariffPackages()
            .subscribe(function (tariffPlans) {
            _this.tariffPlans$.next(tariffPlans);
        });
    };
    /**
     * Загрузить все периоды вемени
     * @constructor
     */
    PaymentsService.prototype.LoadTimePeriod = function () {
        var _this = this;
        this.timePeriodsService.GetTimePeriods()
            .subscribe(function (timePeriods) {
            _this.timePeriods$.next(timePeriods);
            _this.selectTimePeriod(timePeriods[0].id);
        });
    };
    /**
     * Загрузить все функции
     * @constructor
     */
    PaymentsService.prototype.LoadVisibleFeatures = function () {
        var _this = this;
        this.featureService.GetVisibleFeature()
            .subscribe(function (features) {
            _this.visibleFeatures$.next(features);
        });
    };
    /**
     *
     * @constructor
     */
    PaymentsService.prototype.LoadFeatures = function () {
        var _this = this;
        this.featureService.GetAllTariffSFeatures()
            .subscribe(function (data) {
            _this.features$.next(data);
        });
    };
    /**
     * Выбрать активный период времени
     * @param timePeriodId - id тарифного плана
     */
    PaymentsService.prototype.selectTimePeriod = function (timePeriodId) {
        this.selectedTimePeriodId$.next(timePeriodId);
    };
    /**
     * Получить цену по тарифному плану и периоду времени
     * @param tariffPlanId - id тарифного плана
     * @param timePeriodId - id периода времени
     * @constructor
     */
    PaymentsService.prototype.GetPriceByTariffPlanAndTimePeriod = function (tariffPlanId, timePeriodId) {
        var tariffPlansList = this.tariffPlans$.getValue();
        var timePeriodsList = this.timePeriods$.getValue();
        if (tariffPlansList) {
            var tariffPlan = tariffPlansList.find(function (x) { return x.id === tariffPlanId; });
            if (tariffPlan) {
                var price = tariffPlan.prices.find(function (x) { return x['periodId'] === timePeriodId; });
                return price;
            }
        }
        return null;
        // if (this.timePeriods$.getValue() && this.timePeriods$.getValue().length > 0) {
        //   const timePeriod = this.timePeriods$.getValue().find(tp => tp.id === timePeriodId);
        //   if (timePeriod && timePeriod.prices) {
        //     const price = timePeriod.prices.find(x => x.tariffPlanId === tariffPlanId);
        //     if (price) {
        //       return price.price;
        //     } else {
        //       return 0;
        //     }
        //   }
        //
        // } else {
        //   return 0;
        // }
    };
    /**
     * Получить цену по тарифному плану
     * @param tariffPlan - id тарифного плана
     * @constructor
     */
    PaymentsService.prototype.GetPriceByTariffPlan = function (tariffPlan) {
        return this.GetPriceByTariffPlanAndTimePeriod(tariffPlan, this.selectedTimePeriodId$.getValue());
    };
    /**
     *
     * @param featureId
     * @param tariffPlanId
     * @constructor
     */
    PaymentsService.prototype.GetFeatureTariffPlanStatus = function (featureId, tariffPlanId) {
        if (this.visibleFeatures$ && this.visibleFeatures$.getValue().length > 0) {
            var featureStatus = this.visibleFeatures$.getValue().find(function (vf) { return vf.featureId === featureId && vf.tariffId === tariffPlanId; });
            console.log(featureStatus, 'FEATURE STATUS WTF ???');
            return featureStatus;
        }
        return null;
    };
    /**
     *
     * @param timePeriodId
     */
    PaymentsService.prototype.isSelected = function (timePeriodId) {
        return this.selectedTimePeriodId$.getValue() === timePeriodId;
    };
    PaymentsService.prototype.LoadData = function () {
        this.LoadVisibleFeatures();
        this.LoadTariffPlans();
        this.LoadTimePeriod();
        this.LoadFeatures();
    };
    PaymentsService.ngInjectableDef = i0.defineInjectable({ factory: function PaymentsService_Factory() { return new PaymentsService(i0.inject(i1.TariffPackagesService), i0.inject(i2.TimePeriodsService), i0.inject(i3.FeaturesService)); }, token: PaymentsService, providedIn: "root" });
    return PaymentsService;
}());
export { PaymentsService };
