import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {CreateAdNewLiteComponent} from './routes/create-ad-new-lite/create-ad-new-lite.component';
import {NewUpdateLiteComponent} from './routes/new-update-lite/new-update-lite.component';

const routes: Routes = [
  {
    path: 'automation',
    children: [
      {
        path: 'new-ad-manager',
        children: [
          {
            path: 'lite',
            children: [
              {
                path: 'update',
                component: NewUpdateLiteComponent
              },
              {
                path: 'create',
                component: CreateAdNewLiteComponent
              }
            ]
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NewAdManagerLiteRoutingModule {
}
