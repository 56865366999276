import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {AdNetworkForm} from "../../../../stores/ad-network-forms/ad-network-form.model";
import {IntegrationPlatformSection} from "../../../../../api/models/integration-platform-section";
import {AkitaNgFormsManager} from "@datorama/akita-ng-forms-manager";
import {environment} from "../../../../../../environments/environment";
import {FormGroup} from "@angular/forms";
import {CrmFieldOptionModel} from "../../models/crm-field-option.model";

@Component({
  selector: 'app-new-import-crm-form',
  templateUrl: './new-import-crm-form.component.html',
  styleUrls: ['./new-import-crm-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewImportCrmFormComponent implements OnInit {

  @Input() public form: AdNetworkForm;
  @Input() public formGroup: FormGroup;
  @Input() public sections: IntegrationPlatformSection[];
  @Input() public advancedSettings;

  public formQuestions: CrmFieldOptionModel[];

  constructor(
    private fm: AkitaNgFormsManager
  ) { }

  ngOnInit() {
    this.formQuestions = this.form.questions.map((question) => ({
      Key: `{{${question.key.toUpperCase()}}}`,
      Label: question.label
    }))
    console.log(this.advancedSettings, 'ADVANCED SETTINGS');
  }

  public GetSectionFrom(sectionCode) {
    return this.formGroup.get(sectionCode) as FormGroup;
  }

  GetAdvancedSettingsForSection(sectionCode: string) {
    return this.advancedSettings ? this.advancedSettings[sectionCode] : [];
  }
}
