var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ChangeDetectorRef, OnInit } from '@angular/core';
import { delay } from 'rxjs/operators';
import { AdManagerService, AutomationVkService, AutomationYandexService, OAuthService, StrategiesService, UserStrategiesService } from '../../../api/services';
import { ActivatedRoute, Router } from '@angular/router';
import { AdViewModel } from '../../models/view-models/ad-view-model';
import { DexieCabinetsService } from '../../../shared/services/dexie/dexie-cabinets.service';
import { DexieCampaignsService } from '../../../shared/services/dexie/dexie-campaigns.service';
import { SecondsToTimePipe } from '../../../shared/pipes/seconds-to-time.pipe';
import { VkUtmExtractorService } from '../../../vk/services/vk-utm-extractor.service';
import { AutomationExpirationManagerService } from '../../services/automation-expiration-manager.service';
import { ExcelService } from '../../services/excel-service.service';
import { UrlExctractorService } from '../../../vk/services/url-exctractor.service';
import { YandexMetrikaWrapperService } from '../../services/yandex-metrika-wrapper.service';
import { UtmExtractorService } from '../../../shared/services/utm-extractor.service';
import { isNullOrUndefined } from 'util';
import * as moment from 'moment';
import { UtilsService } from '../../../shared/services/utils.service';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { MinutesToTimePipe } from '../../../shared/pipes/minutes-to-time.pipe';
import { DgPaginationComponent } from '../../../ngr-ui/components/dg-pagination/dg-pagination.component';
import { NotificationsService } from '../../../shared/services/notifications.service';
import { createValidator } from '../../../ngr-ui/utils/validator-creator';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ModerationModalComponent } from '../../components/moderation-modal/moderation-modal.component';
import { MultipleEditSuccessComponent } from '../../ad-manager/components/modals/multiple-edit-success/multiple-edit-success.component';
import { MultipleEditRejectComponent } from '../../ad-manager/components/modals/multiple-edit-reject/multiple-edit-reject.component';
import { MatConfirmDialogComponent } from '../../ad-manager/components/modals/configrm-dialog/mat-confirm-dialog.component';
import 'rxjs-compat/add/operator/debounceTime';
import { DataGridComponent } from '../../../ngr-ui/components/data-grid/data-grid.component';
import 'rxjs-compat/add/operator/delay';
import { MultipleCopyStatus } from '../../utils/types/multiple-copy-status';
import { AdCostTypeModes } from '../../models/ad-cost-type-modes';
import { IncredibleCopyNotificationComponent } from '../../components/modals/incredible-copy-notification/incredible-copy-notification.component';
import { NotRemovedStrategiesModalComponent } from '../../components/modals/not-removed-strategies-modal/not-removed-strategies-modal.component';
import { AppSuggestionsService } from "../../../shared/services/suggestions-service/app-suggestions.service";
import { environment } from "../../../../environments/environment";
import { AdManagerDataServiceService } from "../../ad-manager/services/ad-manager-data-service.service";
import { CopyingAdsDialogComponent } from "../../copying-module/components/copying-ads-dialog/copying-ads-dialog.component";
import { LoadingDialogComponent } from "../../components/modals/loading-dialog/loading-dialog.component";
var DEFAULT_TIMEOUT = 1000;
var DEFAULT_HIDDEN_FIELDS = {
    reach: { hidden: false },
    name: { hidden: false },
    yandex_cr: { hidden: false },
    yandex_cpa: { hidden: false },
    status: { hidden: false },
    cpm: { hidden: false },
    ecpc: { hidden: false },
    ctr: { hidden: false },
    clicks: { hidden: false },
    impressions: { hidden: false },
    day_limit: { hidden: false },
    all_limit: { hidden: false },
    spent: { hidden: false },
    to_group: { hidden: false },
    join_group: { hidden: false },
    hide: { hidden: false },
    report: { hidden: false },
    unsubscribe: { hidden: false },
    reach_subscribers: { hidden: false },
    reach_total: { hidden: false },
    links: { hidden: false },
    pricing_subscribe: { hidden: false },
    price_click: { hidden: false },
    price_to_group: { hidden: false },
    yandex_reched_goals: { hidden: false },
    likes: { hidden: false },
    reposts: { hidden: false },
    comments: { hidden: false },
    yandex_cr_from_click: { hidden: false },
    yandex_cr_from_view: { hidden: false },
    yandex_average_duration: { hidden: false },
};
var WAIT_STATUS = {
    color: '067CBA',
    description: 'Осталось только запустить управелние ставкой<br>Для этого нажмите на кнопку "запустить".',
    icon: 'ion-md-time',
    id: 1,
    title: 'Ожидает запуска'
};
var AutomationAdsComponent = /** @class */ (function () {
    function AutomationAdsComponent(vkService, route, localCabinetsDb, localCampaignsDb, excel, yandex, extractService, vkUrlExtractor, vkExtractService, accessManager, strategiesService, yandexService, usiService, notificationService, dialog, adManagerService, router, cd, suggestionsService, adDataService, oauthService) {
        var _this = this;
        this.vkService = vkService;
        this.route = route;
        this.localCabinetsDb = localCabinetsDb;
        this.localCampaignsDb = localCampaignsDb;
        this.excel = excel;
        this.yandex = yandex;
        this.extractService = extractService;
        this.vkUrlExtractor = vkUrlExtractor;
        this.vkExtractService = vkExtractService;
        this.accessManager = accessManager;
        this.strategiesService = strategiesService;
        this.yandexService = yandexService;
        this.usiService = usiService;
        this.notificationService = notificationService;
        this.dialog = dialog;
        this.adManagerService = adManagerService;
        this.router = router;
        this.cd = cd;
        this.suggestionsService = suggestionsService;
        this.adDataService = adDataService;
        this.oauthService = oauthService;
        this.PercentAuditoryTooltipText = 'Рассчитывается по формуле: (Охват/Аудитория)*100 ' +
            '\n ' +
            'Примечание: Если у объявления выставлено Ограничение показов: До 2-х и более показов на человека, то возможно искажение статистики на периоде более 1 дня.';
        this.selectedRows = [];
        this.accountId = null;
        this.campaignId = null;
        this.clients = [];
        this.currentCabinet = null;
        this.onlyTizers = true;
        this.goalId = null;
        this.showLink = true;
        this.showUtms = true;
        this.showMin = true;
        this.disabled = false;
        this.hiddenColumns = DEFAULT_HIDDEN_FIELDS;
        this.counterId = null;
        this.BidManagmentModalOpened = false;
        this.inputs = {};
        this.strategiesUpdate = false;
        this.filterString = '';
        this.isLoadingData = false;
        this.IsStrategyTypeSelected = false;
        this.CreatedStrategyOpened = false;
        this.CreatedStrategyInputs = [];
        this.IsCreatingStrategy = true;
        this.saveProperties = {};
        this.bidRanges = [];
        this.selected = [];
        this.inputStrategyKeys = {};
        this.UpdatingLoading = false;
        this.IsPromopostCampaign = false;
        this.loadingGeneralData = false;
        this.enableMultipleEditingButton = false;
        this.loading = true;
        this.targetingLoading = true;
        this.ShowCpcStrategies = false;
        this.CostTypeModes = AdCostTypeModes;
        this.SelectedGroups = [];
        this.errors = [];
        this.ShowStrategyColumns = false;
        this.period = {
            period: 'day',
            dateFrom: moment(new Date()).format('YYYY-MM-DD'),
            dateTo: moment(new Date()).format('YYYY-MM-DD')
        };
        this.instructionsOpened = false;
        this.filterObject = {
            period: 'today',
            dateFrom: new Date(),
            dateTo: new Date()
        };
        this.IsYandexMetrikaLoading = false;
        this.IsStrategiesLoaded = false;
        this.strategy = null;
        this.alternatives = {
            id: {
                name: 'Id объявдения'
            },
            name: {
                name: 'Название'
            },
            cpm: {
                name: 'CPM'
            },
            all_limit: {
                name: 'Общий лимит'
            },
            day_limit: {
                name: 'Дневной лимит'
            },
            spent: {
                name: 'Потрачено'
            },
            clicks: {
                name: 'Переходы'
            },
            ctr: {
                name: 'CTR'
            },
            ecpc: {
                name: 'eCPC'
            },
            reach: {
                name: 'Охват записи'
            },
            links: {
                name: 'Переходы по ссылке'
            },
            price_click: {
                name: 'Цена перехода по ссылке'
            },
            price_to_group: {
                name: 'Цена перехода в группу'
            },
            join_group: {
                name: 'Вступлений в группу'
            },
            pricing_subscribe: {
                name: 'Цена подписчика'
            },
            likes: {
                name: 'Лайки'
            },
            reposts: {
                name: 'Репосты'
            },
            comments: {
                name: 'Комментарии'
            },
            report: {
                name: 'Жалоб'
            },
            unsubscribe: {
                name: 'Скрытых записей всех'
            },
            reach_subscribers: {
                name: 'Охват подписчиков'
            },
            reach_total: {
                name: 'Охват не подписчиков'
            },
            yandex_reched_goals: {
                name: 'Yandex.Целей достигнуто'
            },
            yandex_cpa: {
                name: 'Yandex.Стоимость цели'
            },
            yandex_cr_from_click: {
                name: 'Yandex.CR переход в цель'
            },
            yandex_cr_from_view: {
                name: 'Yandex.CR просмотр в цель'
            },
            yandex_page_depth: {
                name: 'Yandex.Глубина просмотра'
            },
            yandex_average_duration: {
                name: 'Yandex.Время на сайте',
                pipe: SecondsToTimePipe
            }
        };
        this.adsViewModels = null;
        this.showGroupJoinData = false;
        this.counters = [];
        this.goals = [];
        this.hasAnyStrategies = false;
        this.statusFilterOptions = [
            {
                value: null,
                title: 'Все'
            },
            {
                value: 1,
                title: 'Только запущенные'
            },
            {
                value: 0,
                title: 'Только остановленные'
            }
        ];
        this.hiddenColumnsDg = {};
        this.confirmEditDialogOpened = false;
        this.confirmRemoveDialogOpened = false;
        this.ShowMultipleCreateStrategyButton = true;
        this.ShowMultipleEditStrategyButton = true;
        this.StrategyHelpVisible = false;
        this.editErrors = {};
        this.StrategyDescriptionExpandedList = [];
        this.modifyFunctions = {
            BID_MIN: function (value) { return parseFloat(value) * 100; },
            BID_START: function (value) { return parseFloat(value) * 100; },
            BID_END: function (value) { return parseFloat(value) * 100; },
            BID_MAX: function (value) { return parseFloat(value) * 100; },
            BID_STEP: function (value) { return parseFloat(value) * 100; },
            ECPC_LIMIT: function (value) { return parseFloat(value) * 100; },
            CUSTOM_OVERALL_LIMIT: function (value) { return parseFloat(value) * 100; },
        };
        this.displayFunctions = {
            BID_MIN: function (value) { return parseFloat(value) / 100; },
            BID_MAX: function (value) { return parseFloat(value) / 100; },
            BID_START: function (value) { return parseFloat(value) / 100; },
            BID_END: function (value) { return parseFloat(value) / 100; },
            BID_STEP: function (value) { return parseFloat(value) / 100; },
            ECPC_LIMIT: function (value) { return parseFloat(value) / 100; },
            CUSTOM_OVERALL_LIMIT: function (value) { return parseFloat(value) / 100; },
        };
        this.customValidators = {
            BID_START: function (bidRange) {
                var bidMax = _this.GetCurrentBidRangeMax(bidRange);
                var bidMin = _this.GetCurrentBidRangeMin(bidRange);
                return [
                    createValidator({
                        code: 'REQUIRED',
                        invalidMessage: 'Поле должно быть заполнено'
                    }, null),
                    createValidator({
                        code: 'MIN',
                        invalidMessage: "\u0417\u043D\u0430\u0447\u0435\u043D\u0438\u0435 \u043F\u043E\u043B\u044F \u0434\u043E\u043B\u0436\u043D\u043E \u0431\u044B\u0442\u044C \u0431\u043E\u043B\u044C\u0448\u0435 \u0447\u0435\u043C " + _this.displayFunctions.BID_START(bidMin) + "!"
                    }, _this.displayFunctions.BID_START(bidMin)),
                    createValidator({
                        code: 'MAX',
                        invalidMessage: "\u0417\u043D\u0430\u0447\u0435\u043D\u0438\u0435 \u043F\u043E\u043B\u044F \u0434\u043E\u043B\u0436\u043D\u043E \u0431\u044B\u0442\u044C \u043C\u0435\u043D\u044C\u0448\u0435 \u0447\u0435\u043C " + _this.displayFunctions.BID_START(bidMax) + "!"
                    }, _this.displayFunctions.BID_START(bidMax))
                ];
            }
        };
        this.strategyCreateRequest = 0;
        this.selectedUserStrategies = [];
        this.showInactive = false;
        this.selectedStrategy = null;
        this.countOfResults = 10;
        this.availableSizes = environment.availableSizes;
    }
    Object.defineProperty(AutomationAdsComponent.prototype, "IsAllSelected", {
        get: function () {
            return (this.withoutPagination && this.selected.length > 0) ? this.selected.length === this.withoutPagination.length : false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AutomationAdsComponent.prototype, "ShowEditUI", {
        get: function () {
            return this.selected.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AutomationAdsComponent.prototype, "CostTypeHeader", {
        get: function () {
            var header = '';
            switch (this.CostTypeMode) {
                case AdCostTypeModes.CPC_ONLY_MODE:
                    header = 'CPC';
                    break;
                case AdCostTypeModes.CPM_ONLY_MODE:
                    header = 'CPM';
                    break;
                case AdCostTypeModes.SHARE_COST_TYPE_MODE:
                default:
                    header = 'Цена';
            }
            return header;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AutomationAdsComponent.prototype, "CostTypeName", {
        get: function () {
            var name = '';
            switch (this.CostTypeMode) {
                case AdCostTypeModes.CPC_ONLY_MODE:
                    name = 'cpc';
                    break;
                case AdCostTypeModes.CPM_ONLY_MODE:
                    name = 'cpm';
                    break;
                case AdCostTypeModes.SHARE_COST_TYPE_MODE:
                default:
                    name = 'cpc_cpm';
            }
            return name;
        },
        enumerable: true,
        configurable: true
    });
    AutomationAdsComponent.prototype.ToggleConfirmEdit = function () {
        this.confirmEditDialogOpened = !this.confirmEditDialogOpened;
    };
    AutomationAdsComponent.prototype.ToggleConfirmRemove = function () {
        this.confirmRemoveDialogOpened = !this.confirmRemoveDialogOpened;
    };
    AutomationAdsComponent.prototype.GetCurrentBidRangeMin = function (bidRange) {
        var min = null;
        if (this.ShowCpcStrategies) {
            min = bidRange.cpcMin;
        }
        else {
            min = bidRange.cpmMin;
        }
        return min;
    };
    AutomationAdsComponent.prototype.GetCurrentBidRangeMax = function (bidRange) {
        var max = null;
        if (this.ShowCpcStrategies) {
            max = bidRange.cpcMax;
        }
        else {
            max = bidRange.cpmMax;
        }
        return max;
    };
    Object.defineProperty(AutomationAdsComponent.prototype, "EditFormErrors", {
        get: function () {
            var _this = this;
            var editErrorsKeys = [];
            if (this.editErrors) {
                editErrorsKeys = Object.keys(this.editErrors)
                    .map(function (x) { return _this.editErrors[x]; });
            }
            return editErrorsKeys;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AutomationAdsComponent.prototype, "displayedStrategies", {
        get: function () {
            var _this = this;
            return this.strategies
                .filter(function (strategy) { return _this.DisplayedStrategyCostType(strategy); });
        },
        enumerable: true,
        configurable: true
    });
    AutomationAdsComponent.prototype.DisplayedStrategyCostType = function (strategy) {
        if (this.ShowCpcStrategies) {
            return strategy.cpcCompitable === true;
        }
        else {
            return strategy.cpmCompitable === true;
        }
    };
    Object.defineProperty(AutomationAdsComponent.prototype, "CountOfErrors", {
        get: function () {
            return this.EditFormErrors.reduce(function (a, i) { return a + i.length; }, 0);
        },
        enumerable: true,
        configurable: true
    });
    AutomationAdsComponent.prototype.EditStrategyInit = function (data) {
        console.log('EDIT', data);
        this.BidManagmentModalOpened = true;
        this.SelectedGroups = data;
        this.selectedUserStrategies = data.map(function (x) { return x.strategy; });
    };
    AutomationAdsComponent.prototype.SendUserStrategiesEditSpecification = function (specification) {
        var _this = this;
        this.usiService.EditUserStrategyInputs(specification).subscribe(function (response) {
            var viewModels = _this.adsViewModels.filter(function (viewModel) {
                return viewModel && // Вьюха не нулевая
                    viewModel.strategy && // У вьюхи есть стратегия
                    response.find(function (x) { return x.strategyId === viewModel.strategy.strategyId; }) && // Id этой стратегии содержится в ответе
                    response.find(function (x) { return x.adId === viewModel.id; });
            } // Id объявления содержится в ответе
            );
            response.forEach(function (uStrategy) {
                var originalViewModel = viewModels.find(function (x) { return x.id === uStrategy.adId; });
                if (originalViewModel) {
                    originalViewModel.strategy = uStrategy;
                }
            });
            _this.BidManagmentModalOpened = false;
        }, function (err) {
            console.warn('Error during editing userStrategy', err);
            _this.BidManagmentModalOpened = false;
        });
    };
    AutomationAdsComponent.prototype.ClearSelectedUserStrategies = function () {
        this.selectedUserStrategies = [];
    };
    Object.defineProperty(AutomationAdsComponent.prototype, "SelectedGroupsStrategy", {
        get: function () {
            if (this.ContainsUserStrategy()) {
                return this.SelectedGroups[0].strategy.inputs.map(function (x) {
                    return {
                        key: x.type.code,
                        value: x.value
                    };
                })
                    .reduce(function (a, b) {
                    a[b.key] = b.value;
                    return a;
                }, {});
            }
            else {
                return null;
            }
        },
        enumerable: true,
        configurable: true
    });
    AutomationAdsComponent.prototype.ContainsUserStrategy = function () {
        return this.SelectedGroups.length === 1 && this.SelectedGroups[0].strategy;
    };
    AutomationAdsComponent.prototype.ToggleInstructions = function () {
        this.instructionsOpened = !this.instructionsOpened;
    };
    AutomationAdsComponent.prototype.ToggleLoadingData = function () {
        this.isLoadingData = !this.isLoadingData;
    };
    AutomationAdsComponent.prototype.IsAnalyticServiceAvaialbe = function (analyticService) {
        return analyticService.IsAvaliable();
    };
    AutomationAdsComponent.prototype.ToggleDisabled = function () {
        this.disabled = !this.disabled;
    };
    AutomationAdsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.accountId = parseInt(this.route.snapshot.queryParams.account_id, 10);
        this.campaignId = parseInt(this.route.snapshot.queryParams.campaign_id, 10);
        this.clientId = parseInt(this.route.snapshot.queryParams.client_id, 10);
        this.adIds = this.route.snapshot.queryParams.adId;
        this.sendModeration = this.route.snapshot.queryParams.sendModeration;
        this.startAfterModeration = this.route.snapshot.queryParams.startAfterModeration;
        this.showInactive = this.route.snapshot.queryParams.showAll === 'true';
        this.GetStrategyInputKeys();
        this.adManagerService
            .GetInterestCategories();
        this.adDataService.LoadInterests();
        this.editForm = new FormGroup({
            status: new FormControl(null),
            strategy: new FormControl(null),
            cpm: new FormControl(null, [Validators.min(1.2), Validators.max(30)]),
            cpc: new FormControl(null, [Validators.min(1.2), Validators.max(30)]),
            day_limit: new FormControl(null, [Validators.min(100)]),
            all_limit: new FormControl(null, [Validators.min(100)]),
        });
        if (this.adIds) {
            setTimeout(function () {
                var dialog = _this.dialog.open(ModerationModalComponent, {
                    width: '400px',
                    data: {
                        sendModeration: _this.sendModeration,
                        startAfterModeration: _this.startAfterModeration,
                    }
                });
                dialog.afterClosed()
                    .subscribe(function () {
                    _this.router.navigate(['/automation/ads'], {
                        queryParams: {
                            account_id: _this.accountId,
                            campaign_id: _this.campaignId,
                            client_id: _this.clientId,
                            sendModeration: null,
                            startAfterModeration: null,
                            adId: null
                        },
                        queryParamsHandling: 'merge'
                    });
                });
            }, 1500);
        }
        this.LoadBidRanges();
        this.LoadCurrentCabinet()
            .then(function () {
            _this.InitPage();
        });
        this.LoadCurrentCampaign();
        this.LoadActionValues();
        this.route.queryParams
            .distinctUntilChanged()
            .filter(function (val, index) { return index > 0; })
            .subscribe(function (params) {
            _this.InitPage();
        });
    };
    AutomationAdsComponent.prototype.StrategySelected = function (strategy) {
        this.selectedStrategy = strategy;
        this.StrategyHelpVisible = this.selectedStrategy && this.selectedStrategy.code === 'BID_REACH_CONTROL';
        this.cd.detectChanges();
    };
    AutomationAdsComponent.prototype.ToggleStrategyDescription = function (id, status) {
        // Find status
        var item = this.FindStrategyDescriptionStatusById(id);
        // Change status
        item.status = status;
    };
    AutomationAdsComponent.prototype.GetStrategyDescriptionStatus = function (id) {
        // Find status
        var item = this.FindStrategyDescriptionStatusById(id);
        // return status
        return item.status;
    };
    AutomationAdsComponent.prototype.FindStrategyDescriptionStatusById = function (id) {
        return this.StrategyDescriptionExpandedList.find(function (x) { return x.id === id; });
    };
    AutomationAdsComponent.prototype.SetActionValues = function () {
        window.localStorage.setItem('show_actions', JSON.stringify({
            showUtms: this.showUtms,
            showMin: this.showMin,
            showLink: this.showLink
        }));
    };
    AutomationAdsComponent.prototype.LoadCurrentCampaign = function () {
        var _this = this;
        this.localCampaignsDb.findCampaignById(this.campaignId)
            .then(function (data) {
            _this.currentCampaigns = data;
        });
    };
    AutomationAdsComponent.prototype.LoadCurrentCabinet = function () {
        var _this = this;
        return this
            .localCabinetsDb
            .getCabinetById(this.accountId)
            .then(function (cabinet) {
            _this.currentCabinet = cabinet;
        });
    };
    AutomationAdsComponent.prototype.InitPage = function () {
        var _this = this;
        this.LoadClients()
            .then(function () {
            return new Promise(function (resolve) {
                _this.LoadData(_this.clientId)
                    .then(function () {
                    resolve();
                });
            });
        });
        this.oauthService.CheckProviderToken('yandex-metrika')
            .subscribe(function (data) {
            console.log(data, 'YANDEX METROKA STATUS');
            if (data) {
                _this.LoadCounters();
            }
        });
    };
    AutomationAdsComponent.prototype.LoadClients = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.currentCabinet && _this.currentCabinet.account_type === 'agency') {
                _this.vkService.getClients(_this.accountId)
                    .subscribe(function (data) {
                    _this.clients = data.data;
                    resolve();
                });
            }
            else {
                resolve();
            }
        });
    };
    AutomationAdsComponent.prototype.LoadData = function (clientId) {
        return __awaiter(this, void 0, void 0, function () {
            var err_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.warn("LoadData(" + clientId + ")...");
                        this.disabled = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.LoadAds(clientId)
                                .then(this.LoadStatistics.bind(this))
                                .then(this.LoadReach.bind(this))
                                .then(this.LoadLayouts.bind(this))
                                .then(this.LoadWallPost.bind(this))
                                .then(this.LoadYandex.bind(this, 2))
                                .then(this.LoadTargetingAds.bind(this, 2))
                                .then(function () {
                                _this.adsViewModels = _this.adsViewModels
                                    .map(function (ad, i) {
                                    ad.AddGroupsJoin();
                                    ad.ad_url = _this.GetAdUrls(ad);
                                    return ad;
                                });
                                _this.adManagerService.CabBeEditable({
                                    accountId: _this.accountId,
                                    clientId: _this.clientId,
                                    adIds: _this.adsViewModels.map(function (x) { return x.id; })
                                })
                                    .subscribe(function (data) {
                                    _this.adsViewModels.forEach(function (ad) {
                                        ad.can_be_editable = data[ad.id];
                                    });
                                });
                                //this.loadingGeneralData = false;
                                //this.ToggleDisabled();
                                _this.disabled = false;
                                _this.loadingGeneralData = false;
                                console.warn("LoadData(" + clientId + ") completed! this.loadingGeneralData=" + _this.loadingGeneralData);
                            })];
                    case 2: return [2 /*return*/, _a.sent()];
                    case 3:
                        err_1 = _a.sent();
                        //console.error('FUCKING ERROR');
                        console.error(err_1, 'ERROR');
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    AutomationAdsComponent.prototype.CheckCostTypeMode = function (ads) {
        var costTypes = ads.map(function (x) { return x.cost_type; });
        var IsArrayHomogeneous = UtilsService.IsAllArrayElementsHomogeneous(costTypes);
        if (IsArrayHomogeneous) {
            if (costTypes[0]) {
                this.CostTypeMode = AdCostTypeModes.CPM_ONLY_MODE;
            }
            else {
                this.CostTypeMode = AdCostTypeModes.CPC_ONLY_MODE;
            }
        }
        else {
            this.CostTypeMode = AdCostTypeModes.SHARE_COST_TYPE_MODE;
        }
    };
    AutomationAdsComponent.prototype.SelectedAdsHaveStrategies = function () {
        console.log(this.selected.map(function (x) { return x.strategy; }));
        var adsHaveStrategies = this.selected.map(function (ad) { return !isNullOrUndefined(ad.strategy); });
        var status = adsHaveStrategies
            .reduce(function (allAdsHaveStrategies, adHaveStrategy) {
            return allAdsHaveStrategies || adHaveStrategy;
        }, false);
        console.log(status, 'STATUS_ SELECTED');
        return status;
    };
    AutomationAdsComponent.prototype.LoadAds = function (clientId) {
        var _this = this;
        console.warn("LoadAds(" + clientId + ")...");
        this.ToggleLoadingData();
        this.loading = true;
        this.loadingGeneralData = true;
        this.adsViewModels = [];
        return new Promise(function (resolve) {
            setTimeout(function () {
                console.warn("this.vkService.GetAds();", {
                    accountId: _this.accountId,
                    campaignIds: [_this.campaignId],
                    adIds: null,
                    clientId: clientId,
                    includeDeleted: false,
                    limit: 2000,
                    offset: 0
                });
                _this.vkService.GetAds({
                    accountId: _this.accountId,
                    campaignIds: [_this.campaignId],
                    adIds: null,
                    clientId: clientId,
                    includeDeleted: false,
                    limit: 2000,
                    offset: 0
                })
                    .subscribe(function (data) {
                    console.warn("this.vkService.GetAds() responded", JSON.stringify(data));
                    if (data.data.length > 0) {
                        _this.IsPromopostCampaign = data.data[0].ad_format === 9;
                        _this.adsViewModels = data.data.map(function (ad) {
                            _this.onlyTizers = !(ad.ad_format === 9);
                            var newAd = new AdViewModel();
                            newAd.ConvertAd(ad);
                            return newAd;
                        });
                        _this.CheckCostTypeMode(_this.adsViewModels);
                        _this.loading = false;
                        console.warn("adsViewModels.length=" + _this.adsViewModels.length);
                        if (_this.adsViewModels.length > 0) {
                            var bid_1 = _this.bidRanges.find(function (x) { return x.adFormat === _this.adsViewModels[0].ad_format; });
                            if (bid_1) {
                                _this.editForm.controls.cpm.setValidators([Validators.min(bid_1.cpmMin / 100), Validators.max(bid_1.cpmMax / 100)]);
                                _this.editForm.controls.cpc.setValidators([Validators.min(bid_1.cpcMin / 100), Validators.max(bid_1.cpcMax / 100)]);
                                _this.editForm.valueChanges
                                    .debounceTime(500)
                                    .subscribe(function () {
                                    var errorsParams = null;
                                    if (_this.SelectedCostTypeMode === AdCostTypeModes.CPM_ONLY_MODE) {
                                        errorsParams = {
                                            valueMin: bid_1.cpmMin / 100,
                                            valueMax: bid_1.cpmMax / 100,
                                        };
                                    }
                                    else {
                                        errorsParams = {
                                            valueMin: bid_1.cpcMin / 100,
                                            valueMax: bid_1.cpcMax / 100,
                                        };
                                    }
                                    _this.editErrors = UtilsService.GetErrorsByForm('multipleAdEditForm', _this.editForm.controls, errorsParams);
                                });
                            }
                        }
                        _this.LoadStrategies();
                        _this.LoadStrategiesForAds();
                        console.warn("this.adsViewModels[0].ad_format=" + _this.adsViewModels[0].ad_format);
                        if (_this.adsViewModels[0].ad_format === 2) {
                            _this.loadingGeneralData = false;
                        }
                        resolve(clientId);
                    }
                    else {
                        _this.loading = false;
                        _this.IsYandexMetrikaLoading = false;
                        _this.loadingGeneralData = false;
                    }
                }, function (_a) {
                    var error = _a.error;
                    _this.notificationService.Error(error.description);
                    _this.ToggleLoadingData();
                    console.error("{error}", JSON.stringify(error));
                });
            }, DEFAULT_TIMEOUT);
        });
    };
    AutomationAdsComponent.prototype.LoadStatistics = function (clientId) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) { return __awaiter(_this, void 0, void 0, function () {
                        var adsIds, period, period_date_from, period_date_to, _loop_1, this_1, _i, adsIds_1, adIdsGroup;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    adsIds = this.GetIds();
                                    if (!(adsIds.length > 0)) return [3 /*break*/, 5];
                                    period = '';
                                    period_date_from = this.period.dateFrom;
                                    period_date_to = this.period.dateTo;
                                    if (this.period.period === 'overall') {
                                        period = 'day';
                                        period_date_from = '2012-06-01';
                                        period_date_to = moment(new Date()).format('YYYY-MM-DD');
                                    }
                                    else {
                                        period = this.period.period;
                                        period_date_from = this.period.dateFrom;
                                        period_date_to = this.period.dateTo;
                                    }
                                    _loop_1 = function (adIdsGroup) {
                                        var data;
                                        return __generator(this, function (_a) {
                                            switch (_a.label) {
                                                case 0: return [4 /*yield*/, this_1.vkService.GetStatistics({
                                                        accountId: this_1.accountId,
                                                        ids: adIdsGroup,
                                                        idsType: 'ad',
                                                        period: period,
                                                        dateFrom: period_date_from,
                                                        dateTo: period_date_to,
                                                        clientId: this_1.clientId
                                                    })
                                                        .delay(DEFAULT_TIMEOUT)
                                                        .toPromise()];
                                                case 1:
                                                    data = _a.sent();
                                                    this_1.adsViewModels.forEach(function (ad) {
                                                        var stat = data.data.find(function (x) { return x.id === ad.id; });
                                                        if (stat) {
                                                            ad.AddStatistic(stat);
                                                        }
                                                    });
                                                    return [2 /*return*/];
                                            }
                                        });
                                    };
                                    this_1 = this;
                                    _i = 0, adsIds_1 = adsIds;
                                    _a.label = 1;
                                case 1:
                                    if (!(_i < adsIds_1.length)) return [3 /*break*/, 4];
                                    adIdsGroup = adsIds_1[_i];
                                    return [5 /*yield**/, _loop_1(adIdsGroup)];
                                case 2:
                                    _a.sent();
                                    _a.label = 3;
                                case 3:
                                    _i++;
                                    return [3 /*break*/, 1];
                                case 4:
                                    resolve(clientId);
                                    return [3 /*break*/, 6];
                                case 5:
                                    resolve(clientId);
                                    _a.label = 6;
                                case 6: return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    AutomationAdsComponent.prototype.LoadReach = function (clientId) {
        var _this = this;
        return new Promise(function (resolve) { return __awaiter(_this, void 0, void 0, function () {
            var idsGroup, _loop_2, this_2, _i, idsGroup_1, groupId;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        idsGroup = this.GetIds(true);
                        if (!(idsGroup.length > 0 && idsGroup[0].length > 0)) return [3 /*break*/, 4];
                        _loop_2 = function (groupId) {
                            var data_1, ex_1;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        _a.trys.push([0, 2, , 3]);
                                        return [4 /*yield*/, this_2.vkService.GetPostReach({
                                                ids: groupId,
                                                accountId: this_2.accountId,
                                                idsType: 'ad',
                                                clientId: this_2.clientId
                                            })
                                                .delay(1000)
                                                .toPromise()];
                                    case 1:
                                        data_1 = _a.sent();
                                        this_2.adsViewModels = this_2.adsViewModels.map(function (ad) {
                                            var reach = data_1.data.find(function (x) { return x.id === ad.id; });
                                            ad.AddReach(reach);
                                            return ad;
                                        });
                                        return [3 /*break*/, 3];
                                    case 2:
                                        ex_1 = _a.sent();
                                        console.log(ex_1);
                                        return [3 /*break*/, 3];
                                    case 3: return [2 /*return*/];
                                }
                            });
                        };
                        this_2 = this;
                        _i = 0, idsGroup_1 = idsGroup;
                        _a.label = 1;
                    case 1:
                        if (!(_i < idsGroup_1.length)) return [3 /*break*/, 4];
                        groupId = idsGroup_1[_i];
                        return [5 /*yield**/, _loop_2(groupId)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        _i++;
                        return [3 /*break*/, 1];
                    case 4:
                        resolve(clientId);
                        return [2 /*return*/];
                }
            });
        }); });
    };
    AutomationAdsComponent.prototype.LoadWallPost = function (clientId) {
        var _this = this;
        return new Promise(function (resolve) { return __awaiter(_this, void 0, void 0, function () {
            var ids, _i, ids_1, idsGroup, data;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        ids = this.GetWithFormatIds();
                        if (!(ids.length > 0)) return [3 /*break*/, 5];
                        _i = 0, ids_1 = ids;
                        _a.label = 1;
                    case 1:
                        if (!(_i < ids_1.length)) return [3 /*break*/, 4];
                        idsGroup = ids_1[_i];
                        return [4 /*yield*/, this.vkService
                                .GetWallById(idsGroup)
                                .delay(1000)
                                .toPromise()];
                    case 2:
                        data = _a.sent();
                        data.data.forEach(function (wallData) {
                            var ad = _this.adsViewModels.find(function (ad1) { return ad1.link_url === "http://vk.com/wall" + wallData.owner_id + "_" + wallData.id; });
                            if (ad) {
                                wallData = _this.ProcessPostLinkSnippet(wallData, ad);
                                ad.AddWall(wallData);
                                ad.AddUtms(_this.vkExtractService, _this.extractService, wallData);
                            }
                        });
                        _a.label = 3;
                    case 3:
                        _i++;
                        return [3 /*break*/, 1];
                    case 4:
                        if (this.adsViewModels[0].ad_format === 9) {
                            this.loadingGeneralData = false;
                        }
                        resolve(clientId);
                        return [3 /*break*/, 6];
                    case 5:
                        this.adsViewModels = this.adsViewModels.map(function (ad) {
                            ad.AddUtms(_this.vkExtractService, _this.extractService, null);
                            return ad;
                        });
                        if (this.adsViewModels[0].ad_format === 9) {
                            this.loadingGeneralData = false;
                        }
                        resolve(clientId);
                        _a.label = 6;
                    case 6: return [2 /*return*/];
                }
            });
        }); });
    };
    AutomationAdsComponent.prototype.ProcessPostLinkSnippet = function (post, ad) {
        if (post && ad) {
            var adId_1 = ad.id.toString();
            if (post.text) {
                post.text = post.text.replace('{ad_id}', adId_1);
            }
            if (post.attachments && post.attachments.length > 0) {
                var linkAttachments = post.attachments.filter(function (x) { return x.link; }).map(function (x) { return x.link; });
                if (linkAttachments && linkAttachments.length > 0) {
                    linkAttachments.forEach(function (link) {
                        if (link.url) {
                            link.url = link.url.replace('{ad_id}', adId_1);
                        }
                    });
                }
            }
        }
        return post;
    };
    AutomationAdsComponent.prototype.LoadLayouts = function (clientId) {
        var _this = this;
        this.showGroupJoinData = false;
        return new Promise(function (resolve) { return __awaiter(_this, void 0, void 0, function () {
            var ids, _loop_3, this_3, _i, ids_2, idsGroup;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        ids = this.GetIds();
                        _loop_3 = function (idsGroup) {
                            var idsData, data;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        idsData = idsGroup.split(',').map(function (x) { return parseInt(x, 10); });
                                        return [4 /*yield*/, this_3.vkService.GetAdsLayout({
                                                adIds: idsData,
                                                offset: 0,
                                                includeDeleted: false,
                                                limit: 2000,
                                                clientId: clientId,
                                                campaignIds: [this_3.campaignId],
                                                accountId: this_3.accountId
                                            })
                                                .delay(DEFAULT_TIMEOUT)
                                                .toPromise()];
                                    case 1:
                                        data = _a.sent();
                                        this_3.adsViewModels = this_3.adsViewModels.map(function (ad) {
                                            var layout = data.data.find(function (x) { return x.id === ad.id; });
                                            if (!_this.showGroupJoinData && ad.ad_format !== 9) {
                                                if (layout && layout.link_url.indexOf('vk.com/') >= 0) {
                                                    _this.showGroupJoinData = true;
                                                }
                                            }
                                            ad.AddLayout(layout, _this.vkUrlExtractor);
                                            return ad;
                                        });
                                        return [2 /*return*/];
                                }
                            });
                        };
                        this_3 = this;
                        _i = 0, ids_2 = ids;
                        _a.label = 1;
                    case 1:
                        if (!(_i < ids_2.length)) return [3 /*break*/, 4];
                        idsGroup = ids_2[_i];
                        return [5 /*yield**/, _loop_3(idsGroup)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        _i++;
                        return [3 /*break*/, 1];
                    case 4:
                        resolve(clientId);
                        return [2 /*return*/];
                }
            });
        }); });
    };
    AutomationAdsComponent.prototype.LoadYandex = function (countOfRepeats) {
        var _this = this;
        if (countOfRepeats === void 0) { countOfRepeats = 1; }
        console.log(countOfRepeats, 'COUNT OF REPEATS WTF ?????');
        this.StartYandexMetrikaLoading();
        return new Promise(function (resolve) {
            if (_this.goalId) {
                setTimeout(function () {
                    _this.yandex.LoadGoalReach({
                        counterId: _this.counterId,
                        goalId: _this.goalId,
                        dateFrom: (_this.period.dateFrom === null) ? '2012-06-01' : _this.period.dateFrom,
                        dateTo: (_this.period.dateTo === null) ? 'today' : _this.period.dateTo
                    })
                        .then(function (data) {
                        countOfRepeats = countOfRepeats - 1;
                        if (_this.CanReplay(countOfRepeats, data)) {
                            setTimeout(function () {
                                _this.LoadYandex(countOfRepeats);
                            }, 2000);
                        }
                        else {
                            _this.adsViewModels = _this.adsViewModels.map(function (ad) {
                                ad.AddYandexData(null, _this.yandex);
                                return ad;
                            });
                            _this.StopYandexMetrikaLoading();
                        }
                        resolve();
                    });
                }, DEFAULT_TIMEOUT);
            }
            resolve();
        });
    };
    AutomationAdsComponent.prototype.CanReplay = function (countOfRepeats, data) {
        return countOfRepeats > 0 && (!data || !data.data || data.data.length === 0);
    };
    AutomationAdsComponent.prototype.LoadCounters = function () {
        this.yandex.LoadCounters();
    };
    AutomationAdsComponent.prototype.LoadGoals = function () {
        this.yandex.LoadGoals(this.counterId);
    };
    AutomationAdsComponent.prototype.OnChangeShowUtms = function (showUtms) {
        this.showUtms = showUtms;
        this.SetActionValues();
    };
    AutomationAdsComponent.prototype.OnChangeShowMin = function (showMin) {
        this.showMin = showMin;
        this.SetActionValues();
    };
    AutomationAdsComponent.prototype.OnChangeShowLink = function (showLink) {
        this.showLink = showLink;
        this.SetActionValues();
    };
    AutomationAdsComponent.prototype.LoadActionValues = function () {
        var _this = this;
        var values = JSON.parse(localStorage.getItem('show_actions'));
        if (values) {
            Object.keys(values).forEach(function (x) {
                _this[x] = values[x];
            });
        }
    };
    AutomationAdsComponent.prototype.GetAdUrls = function (ad) {
        if (!ad) {
            return null;
        }
        if (this.IsPromopost(ad)) {
            return this.vkUrlExtractor.ExtractUrl(ad.wall);
        }
        else {
            // This is teasers
            return [ad.link_url];
        }
    };
    AutomationAdsComponent.prototype.IsPromopost = function (ad) {
        return ad.ad_format === 9;
    };
    AutomationAdsComponent.prototype.GetLinkClass = function (classname, post, link) {
        return classname + (this.IsArticle(post, link) ? " " + classname + "_article-mode" : '');
    };
    AutomationAdsComponent.prototype.GetLinkTitleLength = function (post, link) {
        return this.IsArticle(post, link) ? 16 : 28;
    };
    AutomationAdsComponent.prototype.ExportToExcel = function () {
        var _this = this;
        var data = this.adsViewModels.map(function (x) {
            return Object.keys(_this.alternatives).reduce(function (a, b) {
                if (_this.alternatives[b].pipe) {
                    a[_this.alternatives[b].name] = new _this.alternatives[b].pipe().transform(parseInt(x[b], 10));
                }
                else {
                    a[_this.alternatives[b].name] = x[b];
                }
                return a;
            }, {});
        });
        this.excel.exportAsExcelFile(data, this.currentCampaigns.name.replace(' ', '_'));
    };
    AutomationAdsComponent.prototype.SelectCounter = function (data) {
        this.counterId = data;
        this.LoadGoals();
    };
    AutomationAdsComponent.prototype.SelectGoal = function (data) {
        console.log('START SELECT GOAL');
        this.goalId = data;
        this.LoadYandex(5);
    };
    AutomationAdsComponent.prototype.ChangeDate = function (date) {
        date.dateFrom = moment(date.dateFrom).format('YYYY-MM-DD');
        date.dateTo = moment(date.dateTo).format('YYYY-MM-DD');
        this.filterObject.period = date.periodState;
        this.period = date;
        this.InitPage();
    };
    AutomationAdsComponent.prototype.GetAdUrlInVkCabinet = function (ad) {
        return "https://vk.com/ads?act=office&union_id=" + ad.id;
    };
    AutomationAdsComponent.prototype.OpenBidManager = function (isUpdating) {
        var _this = this;
        this.strategiesUpdate = isUpdating;
        var selectedGroupAdsFormat = (this.SelectedGroups.length > 0) ? this.SelectedGroups[0].ad_format : 9;
        var bidRange = this.bidRanges.find(function (range) { return range.adFormat === selectedGroupAdsFormat; });
        if (this.strategiesUpdate) {
            this.inputs = {};
            this.strategies.forEach(function (x) {
                var fields = {};
                x.inputs.forEach(function (y) {
                    var inputValue = null;
                    if (isUpdating) {
                        inputValue = (_this.SelectedGroupsStrategy) ? _this.formatFormValues(_this.SelectedGroupsStrategy[y.code], y.code) : 0;
                    }
                    var validators = [];
                    if (_this.customValidators[y.code]) {
                        validators = _this.customValidators[y.code](bidRange);
                    }
                    else {
                        validators = y.validators
                            .map(function (validator) { return createValidator(validator, validator.value); });
                    }
                    fields[y.code] = {
                        name: y.code,
                        type: y['frontendInputType'],
                        label: y.title,
                        value: inputValue,
                        options: y.options,
                        placeholder: y.placeholderContent,
                        description: y.description,
                        required: y.required,
                        Validators: validators
                    };
                });
                _this.inputs[x.code] = fields;
            });
        }
        else {
            this.inputs = {};
            this.strategies.forEach(function (x) {
                var fields = {};
                x.inputs.forEach(function (y) {
                    var validators = [];
                    if (_this.customValidators[y.code]) {
                        validators = _this.customValidators[y.code](bidRange);
                    }
                    else {
                        validators = y.validators
                            .map(function (validator) { return createValidator(validator, validator.value); });
                    }
                    fields[y.code] = {
                        name: y.code,
                        type: y['frontendInputType'],
                        label: y.title,
                        value: 0,
                        options: y.options,
                        placeholder: y.placeholderContent,
                        description: y.description,
                        required: y.required,
                        Validators: validators
                    };
                });
                _this.inputs[x.code] = fields;
            });
        }
        this.BidManagmentModalOpened = true;
    };
    AutomationAdsComponent.prototype.HideSelected = function () {
        throw new Error('Not implemented');
    };
    AutomationAdsComponent.prototype.LoadStrategies = function () {
        var _this = this;
        console.warn("LoadStrategies()...");
        this.IsStrategiesLoaded = false;
        this.strategiesService.GetAllStrategies({ showInactive: this.showInactive, onlyAllowedInCreationModule: false })
            .subscribe(function (data) {
            console.warn("LoadStrategies() responded", JSON.stringify(data));
            _this.strategies = data;
            _this.inputs = {};
            _this.strategies.forEach(function (x) {
                _this.StrategyDescriptionExpandedList.push({
                    id: x.id,
                    status: false
                });
                var fields = {};
                x.inputs.forEach(function (y) {
                    fields[y.code] = {
                        name: y.code,
                        type: y['frontendInputType'],
                        label: y.title,
                        value: (_this.SelectedGroupsStrategy) ? _this.formatFormValues(_this.SelectedGroupsStrategy[y.code], y.code) : 0,
                        options: y.options,
                        placeholder: y.placeholderContent,
                        required: y.required,
                        description: y.description
                    };
                });
                _this.inputs[x.code] = fields;
            });
            _this.IsStrategiesLoaded = true;
            console.warn("LoadStrategies() completed!");
        });
    };
    AutomationAdsComponent.prototype.formatFormValues = function (value, inputName) {
        if (value) {
            if (this.displayFunctions[inputName]) {
                return this.displayFunctions[inputName](value);
            }
            return value;
        }
        return 0;
    };
    AutomationAdsComponent.prototype.NormalizeValue = function (value) {
        if (typeof value === 'string') {
            value = value.replace('.', ',');
        }
        return value;
    };
    AutomationAdsComponent.prototype.SuccessHandle = function (formValue) {
        this.strategy = formValue;
    };
    AutomationAdsComponent.prototype.CreateStrategies = function (creationModel) {
        var _this = this;
        console.log('CreateStrategies', creationModel);
        console.log('ADS', creationModel.ads.map(function (x) { return x.id; }));
        this.selectedStrategy = null;
        var params = {
            accountId: this.accountId,
            clientId: this.clientId || 0,
            ads: creationModel.ads.map(function (x) { return x.id; }),
            strategyType: creationModel.strategy.code,
            updateStep: 10,
            properties: {}
        };
        creationModel.inputs
            .forEach(function (input) {
            params.properties[input.code] = input.value;
        });
        this.strategyCreateRequest = 1;
        console.log('WILL_CREATE', params);
        this.usiService.CreateUserStrategy(params).subscribe(function (data) {
            console.log(data);
            _this.strategyCreateRequest = 2;
            _this.LoadStrategiesForAds(true);
        }, function (err) {
            _this.strategyCreateRequest = 2;
            console.warn(err);
        });
    };
    AutomationAdsComponent.prototype.SaveStrategy = function () {
        var _this = this;
        var formValue = Object.assign(this.strategy, { adIds: [] });
        formValue.adIds = [];
        formValue = Object
            .keys(this.strategy)
            .map(function (formItemKey) { return ({
            key: formItemKey,
            value: (_this.modifyFunctions[formItemKey]) ? _this.modifyFunctions[formItemKey](formValue[formItemKey]) : formValue[formItemKey]
        }); })
            .reduce(function (resultObject, _a) {
            var key = _a.key, value = _a.value;
            resultObject[key] = value;
            return resultObject;
        }, {});
        formValue.strategyType = this.strategyType;
        var data = {
            properties: Object.keys(formValue)
                .map(function (key) { return ({ key: key, value: _this.NormalizeValue(formValue[key]) }); })
                .reduce(function (summaryObject, _a) {
                var key = _a.key, value = _a.value;
                summaryObject[key] = value;
                return summaryObject;
            }, {}),
            accountId: this.accountId,
            clientId: this.clientId || 0,
            strategyType: this.strategyType,
            ads: this.SelectedGroups.map(function (x) { return x.id; }),
            adIds: this.selected.map(function (x) { return x.id; })
        };
        this.saveProperties = data;
        this.errors = [];
        formValue.ads = this.SelectedGroups.map(function (x) { return x.id; });
        formValue.adIds = this.selected.map(function (x) { return x.id; });
        formValue.accountId = this.accountId;
        formValue.clientId = this.clientId;
        if (this.selected.length === 0) {
            if (!this.strategiesUpdate) {
                this.usiService.CreateUserStrategy(data)
                    .subscribe(function (data1) {
                    _this.CreatedStrategyInputs = data1.inputs;
                    _this.ToggleModalOpened();
                    _this.CreatedStrategyOpened = true;
                    _this.strategyType = null;
                    _this.LoadStrategiesForAds(true);
                }, function (err) {
                    _this.errors.push(err.error.description);
                });
            }
            else {
                this.ToggleConfirmEdit();
            }
        }
        else {
            this.ToggleModalOpened();
            this.dialog.open(MatConfirmDialogComponent, {
                data: {
                    title: 'Подтвердите действие',
                    description: 'Применить настройки управления ставками для выбранных объявлений?',
                    confirmActionText: 'Применить настройки',
                    rejectActionText: 'Отменить'
                }
            })
                .afterClosed()
                .subscribe(function (isConfirmed) { return __awaiter(_this, void 0, void 0, function () {
                var props, req, userInputs, _i, _a, ad, a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            if (!isConfirmed) return [3 /*break*/, 5];
                            this.UpdatingLoading = true;
                            props = Object.keys(formValue)
                                .filter(function (x) { return x === x.toUpperCase(); })
                                .map(function (key) { return ({ key: key, value: formValue[key] }); })
                                .reduce(function (arr, _a) {
                                var key = _a.key, value = _a.value;
                                arr[key] = value;
                                return arr;
                            }, {});
                            req = {
                                accountId: this.accountId,
                                clientId: this.clientId,
                                properties: props,
                                strategyType: this.strategyType,
                                all_limit: 0,
                                day_limit: 0,
                            };
                            this.editForm.controls.strategy.setValue(formValue);
                            data['all_limit'] = 0;
                            data['day_limit'] = 0;
                            data['campaignId'] = this.campaignId;
                            data['status'] = 0;
                            data['cpm'] = 0;
                            userInputs = null;
                            _i = 0, _a = this.selected;
                            _b.label = 1;
                        case 1:
                            if (!(_i < _a.length)) return [3 /*break*/, 4];
                            ad = _a[_i];
                            return [4 /*yield*/, this.adManagerService.UpdateStrategies({
                                    accountId: this.accountId,
                                    campaignId: this.campaignId,
                                    clientId: this.clientId || 0,
                                    adIds: [ad.id],
                                    all_limit: 0,
                                    day_limit: 0,
                                    cpm: 0,
                                    properties: data.properties,
                                    strategyType: this.strategyType,
                                    status: 0
                                })
                                    .pipe(delay(2000))
                                    .toPromise()];
                        case 2:
                            a = _b.sent();
                            userInputs = a[0].inputs;
                            _b.label = 3;
                        case 3:
                            _i++;
                            return [3 /*break*/, 1];
                        case 4:
                            this.UpdatingLoading = false;
                            this.CreatedStrategyInputs = userInputs;
                            this.CreatedStrategyOpened = true;
                            this.strategyType = null;
                            this.LoadStrategiesForAds(true);
                            _b.label = 5;
                        case 5: return [2 /*return*/];
                    }
                });
            }); });
        }
    };
    AutomationAdsComponent.prototype.LoadStrategiesForAds = function (resetStatus) {
        var _this = this;
        if (resetStatus === void 0) { resetStatus = false; }
        console.warn("LoadStrategiesForAds(resetStatus)...");
        this.usiService
            .GetStrategiesByAdAndAccountIds({
            accountId: this.accountId,
            clientId: this.clientId,
            viewModel: {
                adIds: this.adsViewModels.map(function (x) { return x.id; })
            }
        })
            .subscribe(function (data) {
            console.warn("LoadStrategiesForAds(resetStatus) responded", JSON.stringify(data));
            if (data.length > 0) {
                _this.adsViewModels.forEach(function (x) {
                    var strategy = data.find(function (y) { return y.adId === x.id; });
                    if (strategy && resetStatus) {
                        x.status = (strategy.isActive) ? 1 : 0;
                    }
                    x.AddStrategy(strategy);
                });
                _this.ShowStrategyColumns = true;
            }
            else {
                _this.adsViewModels.forEach(function (x) {
                    x.AddStrategy(null);
                });
                _this.ShowStrategyColumns = false;
            }
            console.warn("LoadStrategiesForAds(resetStatus) completed!");
        }, function (err) {
            console.error("LoadStrategiesForAds(resetStatus) error", JSON.stringify(err));
        });
    };
    AutomationAdsComponent.prototype.CancelHandle = function () {
        this.ToggleModalOpened();
    };
    AutomationAdsComponent.prototype.ToggleModalOpened = function () {
        this.BidManagmentModalOpened = !this.BidManagmentModalOpened;
    };
    AutomationAdsComponent.prototype.CreateStrategy = function (data) {
        data.ads = this.SelectedGroups;
        this.usiService.CreateUserStrategy(data)
            .subscribe(function (data1) {
        });
    };
    AutomationAdsComponent.prototype.RemoveStrategies = function () {
        var _this = this;
        var dialog = this.dialog.open(LoadingDialogComponent);
        console.log('dialgo opened');
        var promises = this.SelectedGroups.map(function (x) { return _this.usiService.RemoveStrategy({
            accountId: _this.accountId,
            adId: x.id
        }).toPromise(); });
        Promise.all(promises)
            .then(function (x) {
            _this.LoadStrategiesForAds();
            dialog.close();
        })
            .catch(function (x) {
            _this.LoadStrategiesForAds();
            dialog.close();
        });
    };
    AutomationAdsComponent.prototype.SelectedRow = function (data) {
        this.LoadStrategies();
    };
    AutomationAdsComponent.prototype.GetSelectedOptionValue = function (input) {
        return input.type.options.find(function (x) { return parseInt(x.value, 10) === input.value; }).title;
    };
    AutomationAdsComponent.prototype.ShowAllStrategies = function () {
        return this.route.snapshot.queryParams.showAll;
    };
    AutomationAdsComponent.prototype.SummaryFunction = function (data) {
        if (data != null) {
            return data.reduce(function (a, b) { return a + b; }, 0);
        }
        return 0;
    };
    AutomationAdsComponent.prototype.SummaryTitleFunction = function (data) {
        return 'Итого:';
    };
    AutomationAdsComponent.prototype.CurrencyFunction = function (data) {
        if (!isNaN(data)) {
            return new CurrencyPipe('ru').transform(data, ' Руб.');
        }
        return '0 Руб.';
    };
    AutomationAdsComponent.prototype.PaginSummaryTitleFunction = function () {
        return 'Итого на странице:';
    };
    AutomationAdsComponent.prototype.NumberFunction = function (data) {
        if (!isNaN(data)) {
            return new DecimalPipe('ru').transform(data, '0.0-3');
        }
        return '0';
    };
    AutomationAdsComponent.prototype.PercentFunction = function (data) {
        if (!isNaN(data)) {
            return new DecimalPipe('ru').transform(data, '0.0-2') + '%';
        }
        return '0%';
        // return new DecimalPipe('ru').transform(data, '0.0-2') + ' %'
    };
    AutomationAdsComponent.prototype.AvgSummaryFunction = function (data) {
        if (data != null) {
            data = data.filter(function (x) { return x !== 0; });
            return (data.reduce(function (a, b) { return a + b; }, 0) / data.length).toFixed(2);
        }
        return 0;
    };
    AutomationAdsComponent.prototype.YandexTime = function (data) {
        var date = Math.round(data);
        if (!isNaN(date)) {
            return new MinutesToTimePipe().transform(Math.round(data));
        }
        return '00:00';
    };
    AutomationAdsComponent.prototype.EcpcFormatFunction = function (data) {
        if (!isNaN(data)) {
            return new DecimalPipe('ru').transform(data, '1.3') + '%';
        }
        return '0.000%';
    };
    AutomationAdsComponent.prototype.GetIds = function (findOnlyPromoposts) {
        if (findOnlyPromoposts === void 0) { findOnlyPromoposts = false; }
        var COUNT_OF_ITEMS_IN_ARRAY = 100;
        var countOfArrays = Math.ceil(this.adsViewModels.length / COUNT_OF_ITEMS_IN_ARRAY);
        return this.adsViewModels
            .filter(function (x) { return (findOnlyPromoposts) ? x.ad_format === 9 : true; })
            .map(function (x) { return x.id; })
            .reduce(function (a, b, i) {
            var currentIndex = i % countOfArrays;
            if (a[currentIndex] === undefined) {
                a[currentIndex] = [];
            }
            a[currentIndex].push(b);
            return a;
        }, [])
            .map(function (x) { return x.join(); });
    };
    AutomationAdsComponent.prototype.IsArticle = function (post, link) {
        var containOnlyLink = post && post.attachments.filter(function (x) { return isNullOrUndefined(x.link); }).length === 0;
        return link.url.indexOf('vk.com/@') >= 0 && containOnlyLink;
    };
    AutomationAdsComponent.prototype.GetWithFormatIds = function () {
        var COUNT_OF_ITEMS_IN_ARRAY = 100;
        var countOfArrays = Math.ceil(this.adsViewModels.length / COUNT_OF_ITEMS_IN_ARRAY);
        return this.adsViewModels
            .filter(function (x) { return x.ad_format === 9; })
            .map(function (x) { return x.link_url.replace('http://vk.com/wall', ''); })
            .reduce(function (a, b, i) {
            var currentIndex = i % countOfArrays;
            if (a[currentIndex] === undefined) {
                a[currentIndex] = [];
            }
            a[currentIndex].push(b);
            return a;
        }, [])
            .map(function (x) { return x.join(); });
    };
    AutomationAdsComponent.prototype.StartYandexMetrikaLoading = function () {
        this.IsYandexMetrikaLoading = true;
    };
    AutomationAdsComponent.prototype.StopYandexMetrikaLoading = function () {
        this.IsYandexMetrikaLoading = false;
    };
    AutomationAdsComponent.prototype.changeAdStatus = function (adId, status) {
        this.adsViewModels = this.adsViewModels.map(function (ad) {
            if (ad.id === adId) {
                ad.status = status;
            }
            return ad;
        });
    };
    AutomationAdsComponent.prototype.HandleToggleStrategyActive = function (_a) {
        var _this = this;
        var status = _a.status, adIds = _a.adIds;
        this.changeAdStatus(adIds, 2);
        this.usiService.ToggleStrategies({
            status: status,
            adIds: (adIds.length) ? adIds : [adIds],
            clientId: this.clientId || 0,
            accountId: this.accountId
        })
            .subscribe(function (data) {
            _this.LoadStrategiesForAds();
        }, function (err) {
            _this.notificationService.Error(err.error.description);
        });
    };
    AutomationAdsComponent.prototype.HandleCreateStrategy = function (ad) {
        this.strategyCreateRequest = 0;
        this.selectedStrategy = null;
        this.IsCreatingStrategy = true;
        this.IsStrategyTypeSelected = false;
        this.strategyType = null;
        this.SelectedGroups = [ad];
        this.ShowCpcStrategies = ad.cost_type === 0;
        this.ClearSelectedUserStrategies();
        this.OpenBidManager(false);
    };
    AutomationAdsComponent.prototype.HandleUpdateStrategy = function (ad) {
        this.SelectedGroups = [ad];
        this.strategyType = ad.strategy.strategy.code;
        this.IsStrategyTypeSelected = true;
        this.IsCreatingStrategy = false;
        this.OpenBidManager(true);
    };
    AutomationAdsComponent.prototype.HandleRemoveStrategy = function (ad) {
        this.SelectedGroups = [ad];
        this.ToggleConfirmRemove();
    };
    AutomationAdsComponent.prototype.ToggleAd = function (adId, status) {
        var adIds = [adId];
        this.ChangeAdsStatus(adIds, status)
            .subscribe(function () {
        });
    };
    AutomationAdsComponent.prototype.ChangeAdsStatus = function (adIds, status) {
        var _this = this;
        return this.usiService.ToggleAdStatus({
            accountId: this.accountId,
            adIds: adIds,
            status: status
        })
            .map(function (response) {
            if (response[0].error_code !== null) {
                _this.notificationService.Error('Не удалось запустить объявление. Убедитесь что кампания запущена и не превышены дневной или общий лимит');
            }
            else {
                adIds.forEach(function (adId) {
                    _this.ChangeStatusToWait(adId);
                });
            }
        });
    };
    AutomationAdsComponent.prototype.ShowAllColumns = function () {
    };
    AutomationAdsComponent.prototype.CrFromViewFunction = function (data) {
        if (!isNaN(data)) {
            return new DecimalPipe('ru').transform(data, '1.0-4') + '%';
        }
        return '0%';
    };
    AutomationAdsComponent.prototype.GetSelectedStrategyType = function () {
        var _this = this;
        return this.strategies.find(function (strategy) { return strategy.code === _this.strategyType; });
    };
    AutomationAdsComponent.prototype.ToggleSelectedStrategy = function (status) {
        this.IsStrategyTypeSelected = status;
    };
    AutomationAdsComponent.prototype.ChangeStatusToWait = function (adId) {
        this.adsViewModels = this.adsViewModels.map(function (ad) {
            if (ad.id === adId) {
                ad.status = 2;
            }
            if (ad.strategy && ad.strategy.adId === adId) {
                ad.strategy.status = WAIT_STATUS;
            }
            return ad;
        });
    };
    AutomationAdsComponent.prototype.StartStrategyNow = function () {
        var _this = this;
        if (this.selected && this.selected.length > 0) {
            this.selected.forEach(function (x) {
                _this.ChangeStatusToWait(x.id);
            });
        }
        else {
            this.ChangeStatusToWait(this.SelectedGroups[0].id);
        }
        this.BidManagmentModalOpened = false;
        this.HandleToggleStrategyActive({
            status: true,
            adIds: (this.selected && this.selected.length > 0) ? this.selected.map(function (x) { return x.id; }) : this.SelectedGroups.map(function (x) { return x.id; })
        });
    };
    AutomationAdsComponent.prototype.StartStrategyLater = function () {
        this.strategyCreateRequest = 0;
        this.BidManagmentModalOpened = false;
    };
    AutomationAdsComponent.prototype.UpdateAdsInfo = function () {
        var _this = this;
        this.vkService.GetAds({
            accountId: this.accountId,
            campaignIds: [this.campaignId],
            adIds: null,
            clientId: this.clientId,
            includeDeleted: false,
            limit: 2000,
            offset: 0
        })
            .subscribe(function (data) {
            _this.adsViewModels = data.data.map(function (ad) {
                _this.onlyTizers = !(ad.ad_format === 9);
                var newAd = new AdViewModel();
                newAd.ConvertAd(ad);
                return newAd;
            });
        });
    };
    AutomationAdsComponent.prototype.OnEditConfirm = function () {
        var _this = this;
        this.usiService.UpdateUserStrategies(this.saveProperties)
            .subscribe(function (data1) {
            _this.ToggleModalOpened();
            _this.LoadStrategiesForAds(true);
            _this.strategyType = null;
        }, function (err) {
            _this.errors.push(err.error.description);
        });
        this.ToggleConfirmEdit();
    };
    AutomationAdsComponent.prototype.OnEditReject = function () {
        this.ToggleConfirmEdit();
    };
    AutomationAdsComponent.prototype.OnRemoveConfirm = function () {
        this.RemoveStrategies();
        this.ToggleConfirmRemove();
    };
    AutomationAdsComponent.prototype.OnRemoveReject = function () {
        this.ToggleConfirmRemove();
    };
    AutomationAdsComponent.prototype.LoadBidRanges = function () {
        var _this = this;
        this.strategiesService.GetBidRanges()
            .subscribe((function (data) {
            _this.bidRanges = data;
        }));
    };
    AutomationAdsComponent.prototype.OpenAfterCreateModule = function () {
    };
    AutomationAdsComponent.prototype.CtrSummaryFunction = function (data, fullData) {
        var sumClicks = fullData
            .map(function (x) { return x.clicks; })
            .reduce(function (a, b) { return a + b; }, 0);
        var sumImpressions = fullData
            .map(function (x) { return x.impressions; })
            .reduce(function (a, b) { return a + b; }, 0);
        return (sumImpressions > 0) ? sumClicks / sumImpressions * 100 : 0;
    };
    AutomationAdsComponent.prototype.EcpcSummaryFunction = function (data, fullData) {
        var sumClicks = fullData
            .map(function (x) { return x.clicks; })
            .reduce(function (a, b) { return a + b; }, 0);
        var sumSpent = fullData
            .map(function (x) { return x.spent; })
            .reduce(function (a, b) { return a + b; }, 0);
        return (sumClicks) ? sumSpent / sumClicks : 0;
    };
    AutomationAdsComponent.prototype.IsSelected = function (row) {
        return this.selected.map(function (x) { return x.id; }).includes(row.id);
    };
    AutomationAdsComponent.prototype.SetWithoutPagination = function (data) {
        this.withoutPagination = data;
    };
    AutomationAdsComponent.prototype.ToggleAll = function () {
        if (!this.IsAllSelected) {
            this.selected = this.withoutPagination.map(function (x) { return x; });
        }
        else {
            this.selected = [];
        }
        var selectedCostTypes = this.selected.map(function (x) { return x.cost_type; });
        this.ShowMultipleCreateStrategyButton = UtilsService.IsAllArrayElementsHomogeneous(selectedCostTypes);
        this.ShowMultipleEditStrategyButton = this.HaveAllSelectedAdsStrategy() && this.IsSelectedStrategiesHomogeneous();
        this.ToggleCostType(selectedCostTypes);
    };
    AutomationAdsComponent.prototype.CountOfSelected = function () {
        return this.selected.length;
    };
    AutomationAdsComponent.prototype.ToggleSelected = function (row) {
        var index = this.selected.map(function (x) { return x.id; }).indexOf(row.id);
        if (index >= 0) {
            this.selected.splice(index, 1);
        }
        else {
            this.selected.push(row);
        }
        var selectedCostTypes = this.selected.map(function (x) { return x.cost_type; });
        this.ShowMultipleCreateStrategyButton = UtilsService.IsAllArrayElementsHomogeneous(selectedCostTypes);
        this.ShowMultipleEditStrategyButton = this.HaveAllSelectedAdsStrategy() && this.IsSelectedStrategiesHomogeneous();
        this.ToggleCostType(selectedCostTypes);
        this.CurrentMultipleEditCostTypeIsCpc = selectedCostTypes[0] === 0;
    };
    AutomationAdsComponent.prototype.ToggleCostType = function (selectedCostTypes) {
        if (!this.ShowMultipleCreateStrategyButton) {
            this.SelectedCostTypeMode = AdCostTypeModes.SHARE_COST_TYPE_MODE;
        }
        else {
            if (selectedCostTypes[0]) {
                this.SelectedCostTypeMode = AdCostTypeModes.CPM_ONLY_MODE;
            }
            else {
                this.SelectedCostTypeMode = AdCostTypeModes.CPC_ONLY_MODE;
            }
        }
    };
    AutomationAdsComponent.prototype.UpdateAds = function () {
        var _this = this;
        if (this.editForm.valid) {
            this.dialog.open(MatConfirmDialogComponent, {
                data: {
                    title: 'Подтверждение действия',
                    description: 'Изменить параметры для выделенных объявлений',
                    confirmActionText: 'Изменить',
                    rejectActionText: 'Отменить'
                }
            })
                .afterClosed()
                .subscribe(function (status) {
                if (status) {
                    _this.UpdatingLoading = true;
                    var formValue_1 = _this.editForm.value;
                    formValue_1.accountId = _this.accountId;
                    formValue_1.campaignId = _this.campaignId;
                    formValue_1.adIds = _this.selected.map(function (x) { return x.id; });
                    if (formValue_1.strategy) {
                        _this.StrategyToProperties(formValue_1);
                    }
                    formValue_1.costTypeMode = _this.SelectedCostTypeMode;
                    var dialog_1 = _this.dialog.open(LoadingDialogComponent);
                    _this.adManagerService.MultipleUpdateAds(formValue_1)
                        .subscribe(function (data) {
                        _this.UpdatingLoading = false;
                        var dialogSuccessEdit = _this.dialog.open(MultipleEditSuccessComponent, {
                            data: {
                                formValue: formValue_1,
                                strategyInputs: formValue_1.strategy ? _this.GetUserStrategy().inputs : null
                            },
                            width: '560px',
                        });
                        dialog_1.close();
                        _this.editForm.reset();
                        _this.InitPage();
                    }, function (err) {
                        var dialogRejectEdit = _this.dialog.open(MultipleEditRejectComponent, {
                            data: formValue_1,
                            width: '480px',
                        });
                    });
                }
            });
        }
    };
    AutomationAdsComponent.prototype.StrategyToProperties = function (formValue) {
        formValue.strategyType = formValue.strategy.strategyType;
        formValue.properties = Object.keys(formValue.strategy)
            .filter(function (x) { return x === x.toUpperCase(); })
            .map(function (key) { return ({ key: key, value: formValue.strategy[key] }); })
            .reduce(function (arr, _a) {
            var key = _a.key, value = _a.value;
            arr[key] = value;
            return arr;
        }, {});
    };
    AutomationAdsComponent.prototype.HandleEditStrategy = function () {
        this.strategyCreateRequest = 0;
        this.selectedStrategy = null;
        this.IsCreatingStrategy = true;
        this.IsStrategyTypeSelected = false;
        this.strategyType = null;
        this.SelectedGroups = this.selected;
        this.ShowCpcStrategies = this.CurrentMultipleEditCostTypeIsCpc;
        this.strategyCreateRequest = 0;
        this.ClearSelectedUserStrategies();
        this.OpenBidManager(false);
    };
    AutomationAdsComponent.prototype.SetStatusMultiple = function (status) {
        var _this = this;
        var action = (status) ? 'запуск' : 'остановку';
        var action1 = (status) ? 'Запустить' : 'Остановить';
        this.dialog.open(MatConfirmDialogComponent, {
            data: {
                title: "\u041F\u043E\u0434\u0442\u0432\u0435\u0440\u0434\u0438\u0442\u0435 " + action + " \u043E\u0431\u044A\u044F\u0432\u043B\u0435\u043D\u0438\u0439",
                description: action1 + " \u0432\u044B\u0431\u0440\u0430\u043D\u043D\u044B\u0435 \u043E\u0431\u044A\u044F\u0432\u043B\u0435\u043D\u0438\u044F?",
                confirmActionText: action1,
                rejectActionText: 'Отменить'
            }
        })
            .afterClosed()
            .subscribe(function (confirmed) {
            if (confirmed) {
                var dialog_2 = _this.dialog.open(LoadingDialogComponent);
                _this.UpdatingLoading = true;
                var adIds = _this.selected.map(function (x) { return x.id; });
                _this.ChangeAdsStatus(adIds, status)
                    .subscribe(function (x) {
                    dialog_2.close();
                    _this.UpdatingLoading = false;
                });
            }
        });
        // this.editForm.controls.status.setValue(status);
    };
    AutomationAdsComponent.prototype.GetMultipleStrategiesInputs = function () {
        var _this = this;
        var res = Object.keys(this.editForm.controls.strategy.value)
            .filter(function (key) { return key.includes('_'); })
            .map(function (x) {
            var val = _this.displayFunctions[x] ? _this.displayFunctions[x](_this.editForm.controls.strategy.value[x]) : _this.editForm.controls.strategy.value[x];
            return { key: _this.inputStrategyKeys[x], value: _this.editForm.controls.strategy.value[x] };
        });
        return res;
    };
    AutomationAdsComponent.prototype.GetStrategyInputKeys = function () {
        var _this = this;
        this.adManagerService.GetUserStrategyInputKeys()
            .subscribe(function (inputKeys) {
            _this.inputStrategyKeys = inputKeys;
        });
    };
    AutomationAdsComponent.prototype.propertiesToUserStrategyInputs = function (properties) {
        var strategyType = this.editForm.value.strategy.strategyType;
        var strategy = this.strategies.find(function (x) { return x.code === strategyType; });
        return {
            strategy: strategy,
            inputs: strategy.inputs.map(function (input) {
                return {
                    type: input,
                    value: properties[input.code]
                };
            })
        };
    };
    AutomationAdsComponent.prototype.GetUserStrategy = function () {
        var formValue = this.editForm.value;
        if (formValue.strategy) {
            var properties = Object.keys(formValue.strategy)
                .filter(function (x) { return x === x.toUpperCase(); })
                .map(function (key) { return ({ key: key, value: formValue.strategy[key] }); })
                .reduce(function (arr, _a) {
                var key = _a.key, value = _a.value;
                arr[key] = value;
                return arr;
            }, {});
            return this.propertiesToUserStrategyInputs(properties);
        }
        else {
            return null;
        }
    };
    AutomationAdsComponent.prototype.HasSelectedStrategy = function () {
        var selectedIds = this.selected.map(function (x) { return x.id; });
        var strategies = this.adsViewModels.filter(function (x) { return selectedIds.includes(x.id); }).map(function (x) {
            return x.strategy !== undefined && x.strategy !== null;
        });
        return strategies ? strategies.reduce(function (a, i) { return a && i; }, true) : false;
    };
    AutomationAdsComponent.prototype.RemoveStrategiesMany = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.dialog.open(MatConfirmDialogComponent, {
                    data: {
                        title: 'Подтвердите удаление',
                        description: 'Удалить способы управления ставками в выбранных объявлениях?',
                        confirmActionText: 'Удалить',
                        rejectActionText: 'Отменить'
                    }
                })
                    .afterClosed()
                    .subscribe(function (status) { return __awaiter(_this, void 0, void 0, function () {
                    var notRemovedStrategies, dialog, _i, _a, adId, e_1, notRemovedStrategiesAds;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                notRemovedStrategies = [];
                                if (!status) return [3 /*break*/, 7];
                                dialog = this.dialog.open(LoadingDialogComponent);
                                this.UpdatingLoading = true;
                                _i = 0, _a = this.selected.map(function (x) { return x.id; });
                                _b.label = 1;
                            case 1:
                                if (!(_i < _a.length)) return [3 /*break*/, 6];
                                adId = _a[_i];
                                _b.label = 2;
                            case 2:
                                _b.trys.push([2, 4, , 5]);
                                return [4 /*yield*/, this.usiService.RemoveStrategy({
                                        accountId: this.accountId,
                                        adId: adId
                                    })
                                        .delay(2000)
                                        .retry(2)
                                        .toPromise()];
                            case 3:
                                _b.sent();
                                return [3 /*break*/, 5];
                            case 4:
                                e_1 = _b.sent();
                                console.warn('AdIdNotRemoved', adId);
                                notRemovedStrategies.push(adId);
                                console.error(e_1);
                                return [3 /*break*/, 5];
                            case 5:
                                _i++;
                                return [3 /*break*/, 1];
                            case 6:
                                if (notRemovedStrategies.length > 0) {
                                    notRemovedStrategiesAds = this.adsViewModels.filter(function (ad) { return notRemovedStrategies.includes(ad.id); });
                                    this.OpenNotRemovedStrategiesNotificationDialog(notRemovedStrategiesAds);
                                }
                                this.UpdatingLoading = false;
                                dialog.close();
                                this.LoadStrategiesForAds();
                                _b.label = 7;
                            case 7: return [2 /*return*/];
                        }
                    });
                }); });
                return [2 /*return*/];
            });
        });
    };
    AutomationAdsComponent.prototype.OpenNotRemovedStrategiesNotificationDialog = function (notRemovedStrategies) {
        this.dialog.open(NotRemovedStrategiesModalComponent, {
            data: {
                ads: notRemovedStrategies
            }
        });
    };
    AutomationAdsComponent.prototype.ToggleStrategiesMany = function (status) {
        var _this = this;
        var action = (status) ? 'запуск' : 'остановку';
        var action1 = (status) ? 'Запустить' : 'Остановить';
        this.dialog.open(MatConfirmDialogComponent, {
            data: {
                title: "\u041F\u043E\u0434\u0442\u0432\u0435\u0440\u0434\u0438\u0442\u0435 " + action,
                description: action1 + " \u0430\u0432\u0442\u043E\u043C\u0430\u0442\u0438\u0447\u0435\u0441\u043A\u043E\u0435 \u0443\u043F\u0440\u0430\u0432\u043B\u0435\u043D\u0438\u0435 \u0441\u0442\u0430\u0432\u043A\u0430\u043C\u0438 \u0434\u043B\u044F \u0432\u044B\u0431\u0440\u0430\u043D\u043D\u044B\u0445 \u043E\u0431\u044A\u044F\u0432\u043B\u0435\u043D\u0438\u0439",
                confirmActionText: action1,
                rejectActionText: 'Отменить'
            }
        })
            .afterClosed()
            .subscribe(function (isConfirmed) { return __awaiter(_this, void 0, void 0, function () {
            var dialog_3, adIds, _i, adIds_1, adId, err_2, err_3;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!isConfirmed) return [3 /*break*/, 10];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 8, , 9]);
                        dialog_3 = this.dialog.open(LoadingDialogComponent);
                        this.UpdatingLoading = true;
                        adIds = this.selected.map(function (x) { return x.id; });
                        _i = 0, adIds_1 = adIds;
                        _a.label = 2;
                    case 2:
                        if (!(_i < adIds_1.length)) return [3 /*break*/, 7];
                        adId = adIds_1[_i];
                        this.changeAdStatus(adId, 2);
                        _a.label = 3;
                    case 3:
                        _a.trys.push([3, 5, , 6]);
                        return [4 /*yield*/, this.usiService.ToggleStrategies({
                                status: status,
                                adIds: [adId],
                                clientId: this.clientId || 0,
                                accountId: this.accountId
                            })
                                .delay(2000)
                                .catch(function (err) {
                                _this.UpdatingLoading = false;
                                _this.notificationService.Error(err.error.description);
                                dialog_3.close();
                                return err;
                            })
                                .toPromise()
                                .then(function (res) { return res; }, function (err) {
                                console.log(err, 'FUCKING ERROR');
                                dialog_3.close();
                            })
                                .catch(function (err) {
                                console.log('TOGGLE ERROR');
                                _this.UpdatingLoading = false;
                                _this.notificationService.Error(err.error.description);
                                dialog_3.close();
                            })];
                    case 4:
                        _a.sent();
                        dialog_3.close();
                        return [3 /*break*/, 6];
                    case 5:
                        err_2 = _a.sent();
                        console.log('TOGGLE ERROR');
                        dialog_3.close();
                        this.UpdatingLoading = false;
                        this.notificationService.Error(err_2.error.description);
                        return [3 /*break*/, 6];
                    case 6:
                        _i++;
                        return [3 /*break*/, 2];
                    case 7: return [3 /*break*/, 9];
                    case 8:
                        err_3 = _a.sent();
                        console.log('TOGGLE ERROR');
                        this.UpdatingLoading = false;
                        this.notificationService.Error(err_3.error.description);
                        return [3 /*break*/, 9];
                    case 9:
                        this.UpdatingLoading = false;
                        this.LoadStrategiesForAds();
                        _a.label = 10;
                    case 10: return [2 /*return*/];
                }
            });
        }); });
    };
    AutomationAdsComponent.prototype.IsUniforms = function () {
        return this.selected.map(function (x) { return x.strategy && x.strategy.isActive; });
    };
    AutomationAdsComponent.prototype.StrategyStatusStarted = function () {
        return (this.selected[0] && this.selected[0].strategy) ? this.selected[0].strategy.isActive : false;
    };
    AutomationAdsComponent.prototype.HasEditFormFilledField = function () {
        var _this = this;
        var fields = ['cpc', 'cpm', 'all_limit', 'day_limit'];
        var stat = false;
        fields.forEach(function (field) { return stat = stat || _this.editForm.value[field] !== null; });
        return stat;
    };
    AutomationAdsComponent.prototype.OpenCopyAdsDialog = function (selected, segmentation) {
        var _this = this;
        if (selected === void 0) { selected = null; }
        if (segmentation === void 0) { segmentation = false; }
        var adsToCopy = selected || this.selected;
        var ad_format = this.adsViewModels.find(function (ad) { return ad.ad_format !== null; }).ad_format;
        var isClientIdIsNan = isNaN(this.clientId);
        console.log(this.clientId, isClientIdIsNan);
        // this.dialog.open(MultipleCopyFormComponent, {
        //   data: {
        //     accountId: this.accountId,
        //     campaignId: this.campaignId,
        //     clientId: isClientIdIsNan ? 0 : this.clientId,
        //     selectedAds: adsToCopy,
        //     ad_format,
        //     segmentation,
        //     bidRange: this.bidRanges.find(x => x.adFormat === ad_format)
        //   }
        // })
        this.dialog.open(CopyingAdsDialogComponent, {
            data: {
                ad_format: ad_format,
                account_id: this.accountId,
                client_id: isClientIdIsNan ? null : this.clientId,
                campaign_id: this.campaignId,
                ads: adsToCopy,
                bid_range: this.bidRanges.find(function (x) { return x.adFormat === ad_format; })
            }
        })
            .afterClosed()
            .subscribe(function (_a) {
            var status = _a.status, link = _a.link;
            console.log('STATUSES: ', { status: status, link: link });
            switch (status) {
                case MultipleCopyStatus.NOTHING_TO_DO:
                    break;
                case MultipleCopyStatus.NAVIGATE_TO:
                    window.location.href = link;
                    break;
                case MultipleCopyStatus.REFRESH_PAGE:
                    _this.InitPage();
                    break;
            }
            if (status) {
            }
            else {
            }
        });
    };
    AutomationAdsComponent.prototype.LoadTargetingAds = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.targetingLoading = true;
                return [2 /*return*/, new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
                        var _this = this;
                        return __generator(this, function (_a) {
                            setTimeout(function () { return __awaiter(_this, void 0, void 0, function () {
                                var adsIds, _i, adsIds_2, adIds, adsTargetingResponse;
                                var _this = this;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            adsIds = this.GetIds();
                                            _i = 0, adsIds_2 = adsIds;
                                            _a.label = 1;
                                        case 1:
                                            if (!(_i < adsIds_2.length)) return [3 /*break*/, 4];
                                            adIds = adsIds_2[_i];
                                            return [4 /*yield*/, this.vkService.GetAdsTargeting({
                                                    adIds: adIds.split(','),
                                                    accountId: this.accountId,
                                                    campaignIds: [this.campaignId],
                                                    clientId: this.clientId,
                                                    includeDeleted: false,
                                                    limit: 0,
                                                    offset: 0
                                                })
                                                    .delay(1000)
                                                    .toPromise()];
                                        case 2:
                                            adsTargetingResponse = _a.sent();
                                            adsTargetingResponse.data.forEach(function (adTargetingData) {
                                                var ad = _this.adsViewModels.find(function (adItem) { return adItem.id === adTargetingData.id; });
                                                if (!isNullOrUndefined(ad)) {
                                                    ad.AddTargetingData(adTargetingData);
                                                }
                                            });
                                            _a.label = 3;
                                        case 3:
                                            _i++;
                                            return [3 /*break*/, 1];
                                        case 4:
                                            this.targetingLoading = false;
                                            resolve();
                                            return [2 /*return*/];
                                    }
                                });
                            }); }, DEFAULT_TIMEOUT);
                            return [2 /*return*/];
                        });
                    }); })];
            });
        });
    };
    AutomationAdsComponent.prototype.CountOfIncredibleToCopy = function () {
        return this.selected.filter(function (x) { return !x.canBeCopied; }).length;
    };
    AutomationAdsComponent.prototype.IsAllAdsIncredibleToCopy = function () {
        return this.selected.length === this.CountOfIncredibleToCopy();
    };
    // public LoadButtons() {
    //   this.adManagerService.GetAllPromopostButtons()
    //     .subscribe(buttons => {
    //       this.buttons = buttons;
    //     });
    // }
    AutomationAdsComponent.prototype.CheckOnCanBeCopied = function (segmentation) {
        if (segmentation === void 0) { segmentation = false; }
        var countOfIncredibleToCopyingAds = this.selected.filter(function (selected) { return !selected.CanBeCopied(); }).length;
        if (countOfIncredibleToCopyingAds === 0) {
            this.OpenCopyAdsDialog(null, segmentation);
        }
        else {
            this.OpenIncredibleToCopyNotification(countOfIncredibleToCopyingAds);
        }
    };
    AutomationAdsComponent.prototype.OpenIncredibleToCopyNotification = function (countOfIncredible) {
        var _this = this;
        var incredibleToCopyData = {
            incredibleToCopyTypes: this.GetUniqueIncredibleTypes(),
            isAllAdsIncredibleToCopy: this.selected.length === countOfIncredible
        };
        this.dialog.open(IncredibleCopyNotificationComponent, {
            data: incredibleToCopyData,
            width: '640px'
        })
            .afterClosed()
            .subscribe(function (status) {
            if (status) {
                var selected = _this.selected.filter(function (x) { return x.CanBeCopied(); });
                _this.OpenCopyAdsDialog(selected);
            }
        });
    };
    AutomationAdsComponent.prototype.GetUniqueIncredibleTypes = function () {
        var uniqueIncredibleTypes = this.selected
            .filter(function (selectedAd) { return !selectedAd.canBeCopied; })
            .map(function (selectedAd) { return selectedAd.incrediblesCopyType; })
            .reduce(function (arr, current) {
            if (!arr.includes(current)) {
                arr.push(current);
            }
            return arr;
        }, []);
        return uniqueIncredibleTypes;
    };
    AutomationAdsComponent.prototype.IsSelectedStrategiesHomogeneous = function () {
        var selectedStrategiesIds = this.selected.map(function (x) { return x.strategy ? x.strategy.strategyId : null; });
        return UtilsService.IsAllArrayElementsHomogeneous(selectedStrategiesIds);
    };
    AutomationAdsComponent.prototype.HaveAllSelectedAdsStrategy = function () {
        var hasAllSelecteStrategies = this.selected.map(function (x) { return x.strategy !== null; });
        return UtilsService.IsAllArrayElementsHomogeneous(hasAllSelecteStrategies) && hasAllSelecteStrategies[0] === true;
    };
    return AutomationAdsComponent;
}());
export { AutomationAdsComponent };
