/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./secured-oauth-bind-email.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./secured-oauth-bind-email.component";
import * as i3 from "@angular/router";
import * as i4 from "../../../api/services/accounts.service";
var styles_SecuredOauthBindEmailComponent = [i0.styles];
var RenderType_SecuredOauthBindEmailComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SecuredOauthBindEmailComponent, data: {} });
export { RenderType_SecuredOauthBindEmailComponent as RenderType_SecuredOauthBindEmailComponent };
export function View_SecuredOauthBindEmailComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" secured-oauth-bind-email works!\n"]))], null, null); }
export function View_SecuredOauthBindEmailComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-secured-oauth-bind-email", [], null, null, null, View_SecuredOauthBindEmailComponent_0, RenderType_SecuredOauthBindEmailComponent)), i1.ɵdid(1, 114688, null, 0, i2.SecuredOauthBindEmailComponent, [i3.ActivatedRoute, i4.AccountsService, i3.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SecuredOauthBindEmailComponentNgFactory = i1.ɵccf("app-secured-oauth-bind-email", i2.SecuredOauthBindEmailComponent, View_SecuredOauthBindEmailComponent_Host_0, {}, {}, []);
export { SecuredOauthBindEmailComponentNgFactory as SecuredOauthBindEmailComponentNgFactory };
