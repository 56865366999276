import {AdCostTypeModes} from './../../models/ad-cost-type-modes';
import {Component, Input, OnInit} from '@angular/core';
import {AdViewModel} from '../../models/view-models/ad-view-model';

@Component({
  selector: 'app-ad-cost',
  templateUrl: './ad-cost.component.html',
  styleUrls: ['./ad-cost.component.scss'],
})
export class AdCostComponent implements OnInit {
  @Input() public ad: AdViewModel;
  @Input() public costTypeMode: AdCostTypeModes;

  public get costTypeName() {
    let costTypeName = '';
    if (this.costTypeMode === AdCostTypeModes.SHARE_COST_TYPE_MODE) {
      if (this.ad.costTypeMode === AdCostTypeModes.CPC_ONLY_MODE) {
        costTypeName = 'за 1 переход';
      } else {
        costTypeName = 'за 1000 показов';
      }
    }

    return costTypeName;
  }

  public get value() {
    if (this.ad.costTypeMode === AdCostTypeModes.CPC_ONLY_MODE) {
      return this.ad.cpc / 100;
    } else {
      return this.ad.cpm;
    }
  }

  constructor() {
  }

  ngOnInit() {
  }
}
