<ng-container *ngIf="!loading; else loadingScreen">
  <ng-container *ngIf="!assigned; else content">
    <div class="assignation-screen">
      <h1 class="assignation-screen__title">
        Для продолжения работы подключите Мегаплан
      </h1>
      <div class="assignation-screen__content">
        <div class="vk-container">
          Привязав CRM-систему вам будет доступно создание интеграции с этой системой.
          Подключение происходит через официальный доступ и <strong>абсолютно безопасно</strong>.
        </div>
      </div>
      <div class="assignation-screen__actions">
        <div class="vk-container">
          <button class="vk-standart-button" (click)="AddVkAdCabinetLinkClick()" target="_blank"
                  rel="noopener nofollow">
            Подключить Мегаплан
          </button>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #content>
    <ng-content></ng-content>
  </ng-template>
</ng-container>
<ng-template #loadingScreen>
  <app-loading-screen>
    Подождите, идет проверка подключения ...
  </app-loading-screen>
</ng-template>
