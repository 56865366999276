import { AfterViewInit, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TokenCheckerService } from './shared/services/token-checker.service';
import { environment } from '../environments/environment';
import { ExperienceLevelBasedDashboardRedirectionService } from './shared/services/experience-level-based-dashboard-redirection.service';
import { BannersService } from './system-banners/services/banners.service';
var AppComponent = /** @class */ (function () {
    function AppComponent(activatedRoute, router, tokenChecker, elementRef, experienceRedirection, bannersService) {
        var _this = this;
        this.activatedRoute = activatedRoute;
        this.router = router;
        this.tokenChecker = tokenChecker;
        this.elementRef = elementRef;
        this.experienceRedirection = experienceRedirection;
        this.bannersService = bannersService;
        this.title = 'app';
        this.links = [];
        // Dperecated version of widget
        // private vkWidgetRootRouteCode = 'VK.Widgets.CommunityMessages("vk_community_messages", 150497232, {expandTimeout: "15000",tooltipButtonText: "Есть вопрос?"});';
        this.vkWidgetRootRouteCode = 'VK.Widgets.CommunityMessages("vk_community_messages", 150497232, {tooltipButtonText: "Есть вопрос?"}); ';
        this.ignore1280 = [
            'sign-in',
            'sign-up',
            'forgot-password',
            '/automation/accounts'
        ];
        this.notStandartLink = [
            'sign-in',
            'sign-up'
        ];
        this.showChatNotification = false;
        this.noviceMod = false;
        this.showRefrenceAtentuation = false;
        this.currentYear = new Date().getFullYear();
        // WWTF ???
        if (this.IsAuthorized) {
            this.showRefrenceAtentuation = localStorage.getItem('refrence-atentuator') !== 'false';
            // Mark that no refrence attentuator no longer needed
            this.experienceRedirection.GetUserTargetingLevel(false).then(function (x) {
                _this.noviceMod = x === 1;
                if (_this.noviceMod) {
                    _this.SwitchMenuToNoviceMode();
                }
                else {
                    _this.SwitchMenuToProMode();
                }
            });
        }
        this.router.events
            .filter(function (event) { return event instanceof NavigationEnd; })
            .subscribe(function (event) {
            // Redirect from PRO dashbaord to LITE & vice versa
            if (_this.IsAuthorized) {
                if (event.url.indexOf('redefine-experience') < 0) {
                    if (event.url.indexOf('dashboard') >= 0) {
                        _this.experienceRedirection.GetUserTargetingLevel(false).then(function (x) {
                            _this.noviceMod = x === 1;
                            _this.experienceRedirection.ReactOnuserTargetingLevel(x);
                            if (_this.noviceMod) {
                                _this.SwitchMenuToNoviceMode();
                            }
                            else {
                                _this.SwitchMenuToProMode();
                            }
                        });
                    }
                    else if (event.url.indexOf('payments-create') >= 0) {
                        _this.experienceRedirection.GetUserTargetingLevel(false).then(function (x) {
                            _this.noviceMod = x === 1;
                            _this.experienceRedirection.CustomRedirectBasedOnMod(_this.noviceMod, '/easy-payments-create', '/payments-create');
                        });
                    }
                }
            }
        });
    }
    AppComponent.prototype.ngAfterViewInit = function () {
        if (environment.initVkWidget) {
            var scriptMessasges = document.createElement('script');
            scriptMessasges.type = 'text/javascript';
            scriptMessasges.innerHTML = this.vkWidgetRootRouteCode;
            this.elementRef.nativeElement.appendChild(scriptMessasges);
        }
        if (this.IsAuthorized) {
            this.bannersService.LoadAllBanners();
        }
    };
    AppComponent.prototype.SwitchMenuToProMode = function () {
        this.links = [
            // {
            //   path: '/vk/group',
            //   title: 'Сообщества',
            //   is_new: false
            // },
            // {
            //   path: '/vk/post',
            //   title: 'Рекламные посты',
            //   is_new: false
            // },
            // {
            //   path: '/vk/hidden',
            //   title: 'Рекламные записи',
            //   is_new: false
            // },
            {
                path: '/automation/accounts',
                title: 'Автоматизация',
                is_new: false
            },
            {
                path: '/integrations',
                title: 'Интеграции',
                is_new: false
            }, {
                path: '/new-payment',
                title: 'Оплата',
                is_new: false
            }
        ];
    };
    AppComponent.prototype.SwitchMenuToNoviceMode = function () {
        this.links = [
            {
                path: '/automation/dashboard',
                title: 'Мои объявления',
                is_new: false
            },
            {
                path: '/vk/easy-stealth',
                title: 'Примеры объявлений',
                is_new: false
            },
            {
                path: '/easy-payments-create',
                title: 'Оплата',
                is_new: false
            }
        ];
    };
    Object.defineProperty(AppComponent.prototype, "ShowContainer1280", {
        get: function () {
            var path = window.location.href.split('/');
            var currentPath = path[path.length - 1];
            var a = this.ignore1280.find(function (x) { return x.includes(currentPath); });
            return !this.ignore1280.includes(currentPath);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppComponent.prototype, "LogoLink", {
        get: function () {
            var path = window.location.href.split('/');
            var currentPath = path[path.length - 1];
            if (this.notStandartLink.includes(currentPath)) {
                return 'https://postmonitor.ru/';
            }
            return '/dashboard';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppComponent.prototype, "IsAuthorized", {
        get: function () {
            return this.tokenChecker.IsAuthorized();
        },
        enumerable: true,
        configurable: true
    });
    AppComponent.prototype.Logout = function () {
        this.tokenChecker.RemoveToken();
        window.location.href = 'https://postmonitor.ru';
    };
    AppComponent.prototype.ngOnInit = function () {
        // const userData = JSON.parse(localStorage.getItem('user_meta'));
        // Raven.setUserContext({
        //   userId: userData.userId
        // });
    };
    return AppComponent;
}());
export { AppComponent };
