import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'vk-form-group',
  templateUrl: './vk-form-group.component.html',
  styleUrls: ['./vk-form-group.component.scss']
})
export class VkFormGroupComponent implements OnInit {

  @Input() public orientation: VkFormGroupOrientation = VkFormGroupOrientation.VERTICAL;

  constructor() {
  }

  ngOnInit() {
  }

}

export enum VkFormGroupOrientation {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical'
}
