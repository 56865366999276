import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {VkGroupViewModel} from '../../models/vk-group.view-model';
import {AdManagerService} from '../../../../api/services';
import {FormControl, FormGroup} from '@angular/forms';
import {Observable} from 'rxjs/Observable';
import {MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import {SelectionModel} from '@angular/cdk/collections';
import {isNullOrUndefined} from 'util';
import {Subscription} from 'rxjs/Subscription';
import {forkJoin} from 'rxjs/internal/observable/forkJoin';
import {merge} from 'rxjs/internal/observable/merge';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {GroupsSelectorService} from '../../services/groups-selector/groups-selector.service';
import {group} from '@angular/animations';
import {Subject} from 'rxjs/Subject';
import {of} from 'rxjs/internal/observable/of';


@Component({
  selector: 'app-groups-selector',
  templateUrl: './groups-selector.component.html',
  styleUrls: ['./groups-selector.component.scss']
})
export class GroupsSelectorComponent implements OnInit {

  public form: FormGroup;

  public dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  public selection: SelectionModel<VkGroupViewModel> = new SelectionModel(true, []);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public countries$ = of([]);

  public searchCityField: FormControl = new FormControl();
  public countryId: FormControl = new FormControl();
  public cities$;
  public citiesSubject = new Subject();

  public searchSubscriber;
  public loading = false;

  public cities = [];

  constructor(
    private adManagerService: AdManagerService,
    private groupsSelectorService: GroupsSelectorService
  ) {
  }

  public get DisplayedCities() {
    return (this.searchCityField.value)
      ? this.cities.filter(x => x.title.toLowerCase().includes(this.searchCityField.value.toLowerCase()))
      : this.cities;
  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.LoadCountries();

    this.form = new FormGroup({
      title: new FormControl(),
      countryId: new FormControl(-1),
      cityId: new FormControl(-1)
    });

    // this.selection = new SelectionModel(true, this.selectedGroups);
    // // this.selection.changed.subscribe(selections => {
    // // });

    const searchCityField$ = this.searchCityField.valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(500)
    )
      .subscribe(searchString => {
        this.citiesSubject.next({searchString, countryId: (this.form.value.countryId > 0) ? this.form.value.countryId : null});
      });

    this.form.controls.countryId.valueChanges
      .subscribe(val => {
        this.citiesSubject.next({searchString: this.searchCityField.value, countryId: (val > 0) ? val : null});
      });

    this.citiesSubject.subscribe((searchCitiesParams: any) => {
      console.log(searchCitiesParams);
      if (searchCitiesParams.countryId !== null || searchCitiesParams.searchString !== null) {
        this.adManagerService.GetCities(searchCitiesParams)
          .subscribe(data => {
            data.forEach((city: any) => {
              if (!this.cities.map(x => x.id).includes(city.id)) {
                this.cities.push(city);
              }
            });
          });
      }
    });

    this.form.valueChanges.debounceTime(500).subscribe(value => {
      if (value.title) {
        this.groupsSelectorService.ClearGroups();
        this.loading = true;
        if (this.searchSubscriber) {
          this.searchSubscriber.unsubscribe();
        }
        value.countryId = (value.countryId > 0) ? value.countryId : null;
        value.cityId = (value.cityId > 0) ? value.cityId : null;
        this.searchSubscriber = this.adManagerService.SearchGroups(value)
          .subscribe(data => {
            this.groupsSelectorService.AddGroups(data);
            this.dataSource.data = data;
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            this.loading = false;
          }, err => {
            this.loading = false;
          });
        // this.dataSource.changeFilter(value);
      }
    });
  }

  public LoadCountries() {
    this.countries$ = this.adManagerService.GetCountries()
      .map(data => data.items);
  }

  isAllSelected() {
    const numSelected = this.groupsSelectorService.selectedGroups.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.groupsSelectorService.ClearSelectedGroups() :
      this.groupsSelectorService.SelectGroups(this.dataSource.filteredData);
  }

  SelectRow(row) {
    this.groupsSelectorService.SelectGroups([row]);
  }

  public IsChecked(group) {
    return this.groupsSelectorService.IsChecked(group);
  }


  CanBeSelected(row: any) {
    return this.groupsSelectorService.CanBeSelected(row);
  }
}
