import { OnInit } from '@angular/core';
import { CopyingService } from "../../services/copying.service";
import { FormControl } from "@angular/forms";
var TargetAccountAndCampaignComponent = /** @class */ (function () {
    function TargetAccountAndCampaignComponent(copyingService) {
        this.copyingService = copyingService;
    }
    Object.defineProperty(TargetAccountAndCampaignComponent.prototype, "openedAdvancedMode", {
        get: function () {
            return this.copyingService.openedAdvancedMode;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TargetAccountAndCampaignComponent.prototype, "isLoading", {
        get: function () {
            return this.copyingService.loadingAdvancedScreen;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TargetAccountAndCampaignComponent.prototype, "accounts", {
        get: function () {
            return this.copyingService.accounts;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TargetAccountAndCampaignComponent.prototype, "campaigns", {
        get: function () {
            return this.copyingService.campaigns;
        },
        enumerable: true,
        configurable: true
    });
    TargetAccountAndCampaignComponent.prototype.getAccountId = function (account) {
        return account.account_id + "_" + account.client_id;
    };
    TargetAccountAndCampaignComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.form = this.copyingService.form;
        this.targetAccountId = new FormControl(this.form.value.accountId);
        this.targetAccountId.valueChanges.subscribe(function (x) {
            var _a = x.split('_').map(function (x) { return x === 'null' ? null : parseInt(x, 10); }), targetAccountId = _a[0], targetClientId = _a[1];
            _this.copyingService.LoadCampaigns(targetAccountId, targetClientId)
                .subscribe();
        });
    };
    TargetAccountAndCampaignComponent.prototype.OpenCreateNewCampaign = function () {
        this.copyingService.OpenCreateCampaignForm();
    };
    return TargetAccountAndCampaignComponent;
}());
export { TargetAccountAndCampaignComponent };
