import { Injectable } from '@angular/core';
import {AdManagerService} from "../../../api/services";
import {PostMonitorBackend_V2ModelsAdManagerEntitiesInterestCategory} from "../../../api/models";

@Injectable({
  providedIn: 'root'
})
export class AdManagerDataServiceService {
  public interestCategories: Array<PostMonitorBackend_V2ModelsAdManagerEntitiesInterestCategory>;

  constructor(
    private adManagerService: AdManagerService
  ) { }

  public LoadInterests() {
    this.adManagerService.GetInterestCategories()
      .subscribe(x => {
        this.interestCategories = x;
      })
  }

}
