var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { map as __map, filter as __filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../api-configuration";
import * as i2 from "@angular/common/http";
var VkApiService = /** @class */ (function (_super) {
    __extends(VkApiService, _super);
    function VkApiService(config, http) {
        return _super.call(this, config, http) || this;
    }
    /**
     * @param key undefined
     * @return Success
     */
    VkApiService.prototype.GetAccountsAsyncResponse = function (key) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (key != null)
            __params = __params.set('key', key.toString());
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/vk-api/getaccounts", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param key undefined
     * @return Success
     */
    VkApiService.prototype.GetAccountsAsync = function (key) {
        return this.GetAccountsAsyncResponse(key).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `VkApiService.GetClientsAsyncParams` containing the following parameters:
     *
     * - `accountId`:
     *
     * - `key`:
     *
     * @return Success
     */
    VkApiService.prototype.GetClientsAsyncResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.accountId != null)
            __params = __params.set('accountId', params.accountId.toString());
        if (params.key != null)
            __params = __params.set('key', params.key.toString());
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/vk-api/getclients", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `VkApiService.GetClientsAsyncParams` containing the following parameters:
     *
     * - `accountId`:
     *
     * - `key`:
     *
     * @return Success
     */
    VkApiService.prototype.GetClientsAsync = function (params) {
        return this.GetClientsAsyncResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `VkApiService.GetGroupsAsyncParams` containing the following parameters:
     *
     * - `offset`:
     *
     * - `key`:
     *
     * - `filter`:
     *
     * - `fields`:
     *
     * - `count`:
     *
     * @return Success
     */
    VkApiService.prototype.GetGroupsAsyncResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.offset != null)
            __params = __params.set('offset', params.offset.toString());
        if (params.key != null)
            __params = __params.set('key', params.key.toString());
        if (params.filter != null)
            __params = __params.set('filter', params.filter.toString());
        if (params.fields != null)
            __params = __params.set('fields', params.fields.toString());
        if (params.count != null)
            __params = __params.set('count', params.count.toString());
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/vk-api/getgroups", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `VkApiService.GetGroupsAsyncParams` containing the following parameters:
     *
     * - `offset`:
     *
     * - `key`:
     *
     * - `filter`:
     *
     * - `fields`:
     *
     * - `count`:
     *
     * @return Success
     */
    VkApiService.prototype.GetGroupsAsync = function (params) {
        return this.GetGroupsAsyncResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `VkApiService.GetAdsTargetGroupsAsyncParams` containing the following parameters:
     *
     * - `accountId`:
     *
     * - `key`:
     *
     * - `clientId`:
     *
     * @return Success
     */
    VkApiService.prototype.GetAdsTargetGroupsAsyncResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.accountId != null)
            __params = __params.set('accountId', params.accountId.toString());
        if (params.key != null)
            __params = __params.set('key', params.key.toString());
        if (params.clientId != null)
            __params = __params.set('clientId', params.clientId.toString());
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/vk-api/adstargetgroups", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `VkApiService.GetAdsTargetGroupsAsyncParams` containing the following parameters:
     *
     * - `accountId`:
     *
     * - `key`:
     *
     * - `clientId`:
     *
     * @return Success
     */
    VkApiService.prototype.GetAdsTargetGroupsAsync = function (params) {
        return this.GetAdsTargetGroupsAsyncResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `VkApiService.GetLeadFormsListAsyncParams` containing the following parameters:
     *
     * - `groupId`:
     *
     * - `key`:
     *
     * @return Success
     */
    VkApiService.prototype.GetLeadFormsListAsyncResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.groupId != null)
            __params = __params.set('groupId', params.groupId.toString());
        if (params.key != null)
            __params = __params.set('key', params.key.toString());
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/vk-api/leadformslist", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `VkApiService.GetLeadFormsListAsyncParams` containing the following parameters:
     *
     * - `groupId`:
     *
     * - `key`:
     *
     * @return Success
     */
    VkApiService.prototype.GetLeadFormsListAsync = function (params) {
        return this.GetLeadFormsListAsyncResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `VkApiService.GetLeadFormsLeadsAsyncParams` containing the following parameters:
     *
     * - `limit`:
     *
     * - `groupId`:
     *
     * - `formId`:
     *
     * - `nextPageToken`:
     *
     * - `key`:
     *
     * @return Success
     */
    VkApiService.prototype.GetLeadFormsLeadsAsyncResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.limit != null)
            __params = __params.set('limit', params.limit.toString());
        if (params.groupId != null)
            __params = __params.set('groupId', params.groupId.toString());
        if (params.formId != null)
            __params = __params.set('formId', params.formId.toString());
        if (params.nextPageToken != null)
            __params = __params.set('nextPageToken', params.nextPageToken.toString());
        if (params.key != null)
            __params = __params.set('key', params.key.toString());
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/vk-api/leadformsgetleads", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `VkApiService.GetLeadFormsLeadsAsyncParams` containing the following parameters:
     *
     * - `limit`:
     *
     * - `groupId`:
     *
     * - `formId`:
     *
     * - `nextPageToken`:
     *
     * - `key`:
     *
     * @return Success
     */
    VkApiService.prototype.GetLeadFormsLeadsAsync = function (params) {
        return this.GetLeadFormsLeadsAsyncResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    VkApiService.GetAccountsAsyncPath = '/apiv2/vk-api/getaccounts';
    VkApiService.GetClientsAsyncPath = '/apiv2/vk-api/getclients';
    VkApiService.GetGroupsAsyncPath = '/apiv2/vk-api/getgroups';
    VkApiService.GetAdsTargetGroupsAsyncPath = '/apiv2/vk-api/adstargetgroups';
    VkApiService.GetLeadFormsListAsyncPath = '/apiv2/vk-api/leadformslist';
    VkApiService.GetLeadFormsLeadsAsyncPath = '/apiv2/vk-api/leadformsgetleads';
    VkApiService.ngInjectableDef = i0.defineInjectable({ factory: function VkApiService_Factory() { return new VkApiService(i0.inject(i1.ApiConfiguration), i0.inject(i2.HttpClient)); }, token: VkApiService, providedIn: "root" });
    return VkApiService;
}(__BaseService));
export { VkApiService };
