import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AdManagerService, AutomationVkService} from '../../../../../api/services';
import {
  AdTargeting,
  APIReponseWrapperListAdLayout,
  APIReponseWrapperListWallPost,
  PostMonitorBackend_V2ModelsAdManagerUpdateAdViewModel
} from '../../../../../api/models';
import {AdViewModel} from '../../../../models/view-models/ad-view-model';
import {of} from 'rxjs/internal/observable/of';
import {flatMap} from 'rxjs/operators';
import {UrlExctractorService} from '../../../../../vk/services/url-exctractor.service';

@Component({
  selector: 'app-update-ad-lite',
  templateUrl: './update-ad-lite.component.html',
  styleUrls: ['./update-ad-lite.component.scss']
})
export class UpdateAdLiteComponent implements OnInit {

  private accountId: number;
  private campaignId: number;
  private adId: number;

  private adParamsData;
  private ad: AdViewModel;
  private adTargetingData: AdTargeting;
  public loading: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private automationVkService: AutomationVkService,
    private urlExtractorService: UrlExctractorService,
    private adManagerService: AdManagerService
  ) {
  }

  ngOnInit() {
    this.initRouterData();
    this.LoadAdData();
  }

  public initRouterData() {
    this.accountId = parseInt(this.route.snapshot.queryParams.account_id, 10);
    this.campaignId = parseInt(this.route.snapshot.queryParams.campaign_id, 10);
    this.adId = parseInt(this.route.snapshot.queryParams.adId, 10);

    this.adParamsData = {
      clientId: null,
      accountId: this.accountId,
      campaignIds: [this.campaignId],
      adIds: [this.adId],
      offset: 0,
      limit: 0,
      includeDeleted: false
    };
  }

  public LoadAdData() {
    this.loading = true;
    this.LoadAd()
      .pipe(
        flatMap(() => this.LoadAdsLayout()),
        flatMap(() => this.LoadWallPost()),
        flatMap(() => this.LoadTargetingStats()),
        flatMap(() => this.LoadAdImage())
      )
      .subscribe(() => {
        console.log('LOADING FALSE');
        this.loading = false;
      });
  }

  public LoadAd() {
    console.log(this.adParamsData);
    return this.automationVkService.GetAds(this.adParamsData)
      .do((adResponse) => {
        this.ad = new AdViewModel();
        this.ad.ConvertAd(adResponse.data[0]);
      })
      .delay(1000);
  }

  public LoadAdsLayout() {
    return this.automationVkService.GetAdsLayout(this.adParamsData)
      .do((adLayoutResponse: APIReponseWrapperListAdLayout) => {
        this.ad.AddLayout(adLayoutResponse.data[0], this.urlExtractorService);
      })
      .delay(1000);
  }

  public LoadWallPost() {
    let result = of({});
    if (this.IsAdPromopost()) {
      const postId = this.GetWallPostId(this.ad);
      result = this.automationVkService.GetWallById(postId)
        .do((wallResponse: APIReponseWrapperListWallPost) => {
          this.ad.AddWall(wallResponse.data[0]);
        })
        .delay(1000);
    }
    return result;
  }

  public LoadTargetingStats() {
    return this.automationVkService.GetAdsTargeting(this.adParamsData)
      .do((targetingStatsResponse) => {
        this.adTargetingData = targetingStatsResponse['data'][0];
      })
      .delay(1000);
  }

  private GetWallPostId(ad: AdViewModel) {
    const postId = ad.link_url
      .replace('http://vk.com/wall', '')
      .replace('https://vk.com/wall', '');
    return postId;
  }

  private LoadAdImage() {
    console.log('LOAD IMAGES');
    return this.adManagerService.GetImage({
      accountId: this.accountId,
      clientId: 0,
      adId: this.adId
    })
      .do(image => {
        if (this.IsAdPromopost()) {
          this.ad.image_src = image.src;
        }
      });
  }

  private IsAdPromopost() {
    if (this.ad) {
      return this.ad.ad_format === 9;
    }
    return false;
  }

  private UpdateAd(ad: PostMonitorBackend_V2ModelsAdManagerUpdateAdViewModel) {
    return this.adManagerService.UpdateAd([ad]);
  }

  SaveAd(ad: PostMonitorBackend_V2ModelsAdManagerUpdateAdViewModel) {
    ad.adSpecifications[0].ad_id = this.adId;
    this.UpdateAd(ad)
      .subscribe(() => {
        this.NavigateToAdsPage(this.accountId, this.campaignId, this.adId);
      });
  }

  private NavigateToAdsPage(accountId: any, campaignId: number, adId) {
    this.router.navigate(['/automation/dashboard'], {
      queryParams: {
        sendModeration: false,
        startAfterModeration: false,
        account_id: parseInt(accountId, 10),
        client_id: null,
        campaign_id: campaignId,
        adId
      }
    });
  }
}
