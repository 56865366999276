var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Query } from '@datorama/akita';
import { UserBalanceStore } from './user-balance.store';
import * as i0 from "@angular/core";
import * as i1 from "./user-balance.store";
var UserBalanceQuery = /** @class */ (function (_super) {
    __extends(UserBalanceQuery, _super);
    function UserBalanceQuery(store) {
        var _this = _super.call(this, store) || this;
        _this.store = store;
        return _this;
    }
    Object.defineProperty(UserBalanceQuery.prototype, "registrated$", {
        get: function () {
            return this.select(function (state) { return state.registrated; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserBalanceQuery.prototype, "visited$", {
        get: function () {
            return this.select(function (state) { return state.visited; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserBalanceQuery.prototype, "currentUserBalance$", {
        get: function () {
            return this.select(function (state) { return state.amount; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserBalanceQuery.prototype, "fullBalance$", {
        get: function () {
            return this.select(function (state) { return state.fullBalance; });
        },
        enumerable: true,
        configurable: true
    });
    UserBalanceQuery.ngInjectableDef = i0.defineInjectable({ factory: function UserBalanceQuery_Factory() { return new UserBalanceQuery(i0.inject(i1.UserBalanceStore)); }, token: UserBalanceQuery, providedIn: "root" });
    return UserBalanceQuery;
}(Query));
export { UserBalanceQuery };
