/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./new-ad-format-preview.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./new-ad-format-preview.component";
var styles_NewAdFormatPreviewComponent = [i0.styles];
var RenderType_NewAdFormatPreviewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NewAdFormatPreviewComponent, data: {} });
export { RenderType_NewAdFormatPreviewComponent as RenderType_NewAdFormatPreviewComponent };
export function View_NewAdFormatPreviewComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "ad-format-preview"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["class", "ad-format-preview__image"]], [[8, "src", 4], [8, "alt", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "ad-format-preview__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u041F\u0440\u0438\u043C\u0435\u0440 \u0442\u043E\u0433\u043E, \u043A\u0430\u043A \u043E\u0431\u044A\u044F\u0432\u043B\u0435\u043D\u0438\u0435 \u0431\u0443\u0434\u0435\u0442 \u0432\u044B\u0433\u043B\u044F\u0434\u0435\u0442\u044C \u0412\u041A\u043E\u043D\u0442\u0430\u043A\u0442\u0435 "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.adFormat.preview_image; var currVal_1 = _co.adFormat.title; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_NewAdFormatPreviewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-new-ad-format-preview", [], null, null, null, View_NewAdFormatPreviewComponent_0, RenderType_NewAdFormatPreviewComponent)), i1.ɵdid(1, 114688, null, 0, i2.NewAdFormatPreviewComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NewAdFormatPreviewComponentNgFactory = i1.ɵccf("app-new-ad-format-preview", i2.NewAdFormatPreviewComponent, View_NewAdFormatPreviewComponent_Host_0, { adFormat: "adFormat" }, {}, []);
export { NewAdFormatPreviewComponentNgFactory as NewAdFormatPreviewComponentNgFactory };
