<ng-container *ngIf="!isLoading; else loading;">
  <h3><strong>Для завершения регистрации введите Ваш</strong> e-mail</h3>
  <form (submit)="Submit($event)" [formGroup]="form">

    <div class="fx-row">
      <div class="form-block">
        <ng-container *ngIf="errors?.system">
          <div class="error active" *ngFor="let error of errors.system">
            {{error}}
          </div>
        </ng-container>
        <ng-container *ngIf="errors?.email">
          <div class="error active" *ngFor="let error of errors.email">
            {{error}}
          </div>
        </ng-container>
        <div class="form-group">
          <div>
            <label for="email">Ваше имя</label>
          </div>
          <div>
            <input type="text" id="name" placeholder="Ваше имя" formControlName="name"/>
          </div>
        </div>

        <div class="form-group">
          <div>
            <label for="email">Email</label>
          </div>
          <div>
            <input type="email" id="email" placeholder="Введите e-mail" formControlName="email"/>
          </div>
        </div>

        <div class="form-group">
          <div>
            <label for="phone">Номер телефона</label>
          </div>
          <div>
            <input type="text" id="phone" placeholder="Введите номер телефона" formControlName="phone"/>
          </div>
        </div>

        <div>
          <button class="btn btn-primary">ЗАВЕРШИТЬ РЕГИСТРАЦИЮ</button>
        </div>
      </div>

    </div>
  </form>
</ng-container>
<ng-template #loading>
    <span class="spinner">
        Loading...
    </span>
</ng-template>
