import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AmoService} from '../../../../api/services';
import {MatDialogRef} from '@angular/material';
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'app-amo-crm-assignation-dialog',
  templateUrl: './amo-crm-assignation-dialog.component.html',
  styleUrls: ['./amo-crm-assignation-dialog.component.scss']
})
export class AmoCrmAssignationDialogComponent implements OnInit {

  public loading: boolean = false;

  public form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private amo: AmoService,
    private dialogRef: MatDialogRef<AmoCrmAssignationDialogComponent>
  ) {
  }

  ngOnInit() {
    this.form = this.fb.group({
      domain: this.fb.control('', [Validators.required])
    });
  }

  Assign() {
    if (this.form.valid) {
      this.loading = true;
      const value = this.form.value;
      value.domain = value
        .domain
        .replace('https://', '')
        .replace('.amocrm.ru', '');

      this.amo.AuthorizeAsync(value)
        .subscribe(data => {
          this.loading = false;
          console.log(`${environment.backendUri}/${environment.apiPrefix}/OAuth/authorize/amo?domain=${data}`);
          // http://localhost:6856/apiv2/OAuth/authorize/amo?domain=testalymaev
          window.location.href = `${environment.backendUri}/${environment.apiPrefix}/OAuth/authorize/amo?domain=${data}`;
        }, err => {
          this.loading = false;
        });
    }
  }
}
