import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ChatConversationMessagesService} from "../../services/chat-conversation-messages.service";
import {ChatConversationModel} from "../../models/chat-conversation-model";

@Component({
  selector: 'app-chat-control-panel',
  templateUrl: './chat-control-panel.component.html',
  styleUrls: ['./chat-control-panel.component.scss']
})
export class ChatControlPanelComponent implements OnInit {
  @Input() public conversationWithUserId: number = null;
  @Output() public onSendNotificationOverEmailChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(public chatApi: ChatConversationMessagesService) {

  }

  ngOnInit() {
    this.chatApi.GetMarks();
    this.chatApi.GetConversations(this.conversationWithUserId);
  }

  public Conversation(): ChatConversationModel {
    if (this.chatApi.conversations && this.chatApi.conversations.length > 0) {
      return this.chatApi.conversations[0];
    }

    return null;
  }

  public ChangeConversationMark(markId: number): void {
    this.chatApi.AssignNewMarkToConversation(this.conversationWithUserId, markId);
  }

  public ChangeSendNotificationViaEmail(state: boolean): void {
    this.onSendNotificationOverEmailChange.emit(state);
  }
}
