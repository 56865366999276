import { OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AutomationVkService, AutomationYandexService, StrategiesService, UserStrategiesService } from '../../../api/services';
import { ActivatedRoute } from '@angular/router';
import { AdViewModel } from '../../models/view-models/ad-view-model';
import { DexieCabinetsService } from '../../../shared/services/dexie/dexie-cabinets.service';
import { DexieCampaignsService } from '../../../shared/services/dexie/dexie-campaigns.service';
import { SecondsToTimePipe } from '../../../shared/pipes/seconds-to-time.pipe';
import { VkUtmExtractorService } from '../../../vk/services/vk-utm-extractor.service';
import { AutomationExpirationManagerService } from '../../services/automation-expiration-manager.service';
import { ExcelService } from '../../services/excel-service.service';
import { UrlExctractorService } from '../../../vk/services/url-exctractor.service';
import { YandexMetrikaWrapperService } from '../../services/yandex-metrika-wrapper.service';
import { UtmExtractorService } from '../../../shared/services/utm-extractor.service';
import { isNullOrUndefined } from 'util';
import * as moment from 'moment';
import { NgrChip } from '../../../ngr-ui/models/ngr-chip.model';
import { UtilsService } from '../../../shared/services/utils.service';
var DEFAULT_TIMEOUT = 1000;
var DEFAULT_HIDDEN_FIELDS = {
    reach: { hidden: false },
    name: { hidden: false },
    yandex_cr: { hidden: false },
    yandex_cpa: { hidden: false },
    status: { hidden: false },
    cpm: { hidden: false },
    ecpc: { hidden: false },
    ctr: { hidden: false },
    clicks: { hidden: false },
    impressions: { hidden: false },
    day_limit: { hidden: false },
    all_limit: { hidden: false },
    spent: { hidden: false },
    to_group: { hidden: false },
    join_group: { hidden: false },
    hide: { hidden: false },
    report: { hidden: false },
    unsubscribe: { hidden: false },
    reach_subscribers: { hidden: false },
    reach_total: { hidden: false },
    links: { hidden: false },
    pricing_subscribe: { hidden: false },
    price_click: { hidden: false },
    price_to_group: { hidden: false },
    yandex_reched_goals: { hidden: false },
    likes: { hidden: false },
    reposts: { hidden: false },
    comments: { hidden: false },
    yandex_cr_from_click: { hidden: false },
    yandex_cr_from_view: { hidden: false },
    yandex_average_duration: { hidden: false },
};
var NewAutomationAdsComponent = /** @class */ (function () {
    function NewAutomationAdsComponent(vkService, route, localCabinetsDb, localCampaignsDb, excel, yandex, extractService, vkUrlExtractor, vkExtractService, accessManager, strategiesService, yandexService, usiService) {
        this.vkService = vkService;
        this.route = route;
        this.localCabinetsDb = localCabinetsDb;
        this.localCampaignsDb = localCampaignsDb;
        this.excel = excel;
        this.yandex = yandex;
        this.extractService = extractService;
        this.vkUrlExtractor = vkUrlExtractor;
        this.vkExtractService = vkExtractService;
        this.accessManager = accessManager;
        this.strategiesService = strategiesService;
        this.yandexService = yandexService;
        this.usiService = usiService;
        this.accountId = null;
        this.campaignId = null;
        this.clients = [];
        this.currentCabinet = null;
        this.onlyTizers = true;
        this.goalId = null;
        this.showLink = true;
        this.showUtms = true;
        this.showMin = true;
        this.disabled = false;
        this.hiddenColumns = DEFAULT_HIDDEN_FIELDS;
        this.counterId = null;
        this.BidManagmentModalOpened = false;
        this.strategyType = 'bid_retention';
        this.inputs = {};
        this.strategiesUpdate = false;
        this.SelectedGroups = [];
        this.errors = [];
        this.ShowStrategyColumns = false;
        this.modifyFunctions = {
            BID_MIN: function (value) { return parseFloat(value) * 100; },
            BID_START: function (value) { return parseFloat(value) * 100; },
            BID_END: function (value) { return parseFloat(value) * 100; },
            BID_MAX: function (value) { return parseFloat(value) * 100; },
            BID_STEP: function (value) { return parseFloat(value) * 100; },
            ECPC_LIMIT: function (value) { return parseFloat(value) * 100; },
        };
        this.displayFunctions = {
            BID_MIN: function (value) { return parseFloat(value) / 100; },
            BID_MAX: function (value) { return parseFloat(value) / 100; },
            BID_START: function (value) { return parseFloat(value) / 100; },
            BID_END: function (value) { return parseFloat(value) / 100; },
            BID_STEP: function (value) { return parseFloat(value) / 100; },
            ECPC_LIMIT: function (value) { return parseFloat(value) / 100; },
        };
        this.period = {
            period: 'overall',
            dateFrom: '0',
            dateTo: '0'
        };
        this.IsYandexMetrikaLoading = false;
        this.alternatives = {
            id: {
                name: 'Id объявдения'
            },
            name: {
                name: 'Название'
            },
            cpm: {
                name: 'CPM'
            },
            all_limit: {
                name: 'Общий лимит'
            },
            day_limit: {
                name: 'Дневной лимит'
            },
            spent: {
                name: 'Потрачено'
            },
            clicks: {
                name: 'Переходы'
            },
            ctr: {
                name: 'CTR'
            },
            ecpc: {
                name: 'eCPC'
            },
            reach: {
                name: 'Охват записи'
            },
            links: {
                name: 'Переходы по ссылке'
            },
            price_click: {
                name: 'Цена перехода по ссылке'
            },
            price_to_group: {
                name: 'Цена перехода в группу'
            },
            join_group: {
                name: 'Вступлений в группу'
            },
            pricing_subscribe: {
                name: 'Цена подписчика'
            },
            likes: {
                name: 'Лайки'
            },
            reposts: {
                name: 'Репосты'
            },
            comments: {
                name: 'Комментарии'
            },
            report: {
                name: 'Жалоб'
            },
            unsubscribe: {
                name: 'Скрытых записей всех'
            },
            reach_subscribers: {
                name: 'Охват подписчиков'
            },
            reach_total: {
                name: 'Охват не подписчиков'
            },
            yandex_reched_goals: {
                name: 'Yandex.Целей достигнуто'
            },
            yandex_cpa: {
                name: 'Yandex.Стоимость цели'
            },
            yandex_cr_from_click: {
                name: 'Yandex.CR переход в цель'
            },
            yandex_cr_from_view: {
                name: 'Yandex.CR просмотр в цель'
            },
            yandex_page_depth: {
                name: 'Yandex.Глубина просмотра'
            },
            yandex_average_duration: {
                name: 'Yandex.Время на сайте',
                pipe: SecondsToTimePipe
            }
        };
        this.adsViewModels = null;
        this.showGroupJoinData = false;
        this.counters = [];
        this.goals = [];
        this.hasAnyStrategies = false;
    }
    Object.defineProperty(NewAutomationAdsComponent.prototype, "SelectedGroupsStrategy", {
        get: function () {
            var _this = this;
            if (this.SelectedGroups.length === 1) {
                return this.SelectedGroups[0].strategy.inputs.map(function (x) {
                    return {
                        key: x.type.code,
                        value: x.value
                    };
                })
                    .reduce(function (a, b) {
                    if (_this.displayFunctions[b.key]) {
                        a[b.key] = _this.displayFunctions[b.key](b.value);
                    }
                    else {
                        a[b.key] = b.value;
                    }
                    return a;
                }, {});
            }
            else {
                return null;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewAutomationAdsComponent.prototype, "HasAllSelectedAdsStrategies", {
        get: function () {
            return this.SelectedGroups.filter(function (x) { return x.strategy == null; }).length === 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewAutomationAdsComponent.prototype, "SelectedGroupsChips", {
        get: function () {
            return this.SelectedGroups.map(function (x) { return new NgrChip(x.id, x.name); });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewAutomationAdsComponent.prototype, "SelectedStrategy", {
        get: function () {
            var _this = this;
            if (this.strategies && this.strategies.length > 0) {
                var fields_1 = {};
                var inputs = this.strategies.filter(function (x) { return x.code === _this.strategyType; })[0].inputs;
                inputs.forEach(function (x) {
                    var input = {
                        label: '',
                        name: '',
                        description: '',
                        type: '',
                        value: 0,
                        options: null,
                        modyfyFunction: null
                    };
                    input.label = x.title;
                    input.name = UtilsService.DashToCamelCase(x.code);
                    input.description = x.description;
                    input.type = 'number';
                    input.options = x.options;
                    if (_this.modifyFunctions[x.code]) {
                        input.modyfyFunction = _this.modifyFunctions[x.code];
                    }
                    if (_this.displayFunctions[x.code]) {
                        input.value = _this.displayFunctions[x.code](input.value);
                    }
                    fields_1[input.name] = input;
                });
                return fields_1;
            }
            return new Array();
        },
        enumerable: true,
        configurable: true
    });
    NewAutomationAdsComponent.prototype.IsAnalyticServiceAvaialbe = function (analyticService) {
        return analyticService.IsAvaliable();
    };
    Object.defineProperty(NewAutomationAdsComponent.prototype, "IsBlockedStrategy", {
        get: function () {
            return this.SelectedGroups.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewAutomationAdsComponent.prototype, "IsPeriodOverall", {
        get: function () {
            return this.period.period === 'overall';
        },
        enumerable: true,
        configurable: true
    });
    NewAutomationAdsComponent.prototype.ToggleDisabled = function () {
        this.disabled = !this.disabled;
    };
    NewAutomationAdsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.accountId = parseInt(this.route.snapshot.queryParams.account_id, 10);
        this.campaignId = parseInt(this.route.snapshot.queryParams.campaign_id, 10);
        this.clientId = parseInt(this.route.snapshot.queryParams.client_id, 10);
        this.LoadCurrentCabinet()
            .then(function () {
            _this.InitPage();
        });
        this.LoadCurrentCampaign();
        this.LoadActionValues();
    };
    NewAutomationAdsComponent.prototype.SetActionValues = function () {
        window.localStorage.setItem('show_actions', JSON.stringify({
            showUtms: this.showUtms,
            showMin: this.showMin,
            showLink: this.showLink
        }));
    };
    NewAutomationAdsComponent.prototype.LoadCurrentCampaign = function () {
        var _this = this;
        this.localCampaignsDb.findCampaignById(this.campaignId)
            .then(function (data) {
            _this.currentCampaigns = data;
        });
    };
    NewAutomationAdsComponent.prototype.LoadCurrentCabinet = function () {
        var _this = this;
        return this.localCabinetsDb.getCabinetById(this.accountId)
            .then(function (cabinet) {
            _this.currentCabinet = cabinet;
        });
    };
    NewAutomationAdsComponent.prototype.InitPage = function () {
        var _this = this;
        this.LoadClients()
            .then(function () {
            return new Promise(function (resolve) {
                _this.LoadData(_this.clientId)
                    .then(function () {
                    resolve();
                });
            });
        });
        this.LoadCounters();
    };
    NewAutomationAdsComponent.prototype.LoadClients = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.currentCabinet && _this.currentCabinet.account_type === 'agency') {
                _this.vkService.getClients(_this.accountId)
                    .subscribe(function (data) {
                    _this.clients = data.data;
                    resolve();
                });
            }
            else {
                resolve();
            }
        });
    };
    NewAutomationAdsComponent.prototype.LoadData = function (clientId) {
        var _this = this;
        this.ToggleDisabled();
        return this.LoadAds(clientId)
            .then(this.LoadStatistics.bind(this))
            .then(this.LoadReach.bind(this))
            .then(this.LoadLayouts.bind(this))
            .then(this.LoadWallPost.bind(this))
            .then(this.LoadYandex.bind(this, 2))
            .then(function () {
            _this.adsViewModels = _this.adsViewModels
                .map(function (ad, i) {
                ad.AddGroupsJoin();
                ad.ad_url = _this.GetAdUrls(ad);
                return ad;
            });
            _this.ToggleDisabled();
            _this.LoadStrategies();
            _this.LoadStrategiesForAds();
        });
    };
    NewAutomationAdsComponent.prototype.LoadAds = function (clientId) {
        var _this = this;
        this.adsViewModels = [];
        return new Promise(function (resolve) {
            setTimeout(function () {
                _this.vkService.GetAds({
                    accountId: _this.accountId,
                    campaignIds: [_this.campaignId],
                    adIds: null,
                    clientId: clientId,
                    includeDeleted: false,
                    limit: 2000,
                    offset: 0
                })
                    .subscribe(function (data) {
                    _this.adsViewModels = data.data.map(function (ad) {
                        _this.onlyTizers = !(ad.ad_format === 9);
                        var newAd = new AdViewModel();
                        newAd.ConvertAd(ad);
                        return newAd;
                    });
                    resolve(clientId);
                });
            }, DEFAULT_TIMEOUT);
        });
    };
    NewAutomationAdsComponent.prototype.LoadStatistics = function (clientId) {
        var _this = this;
        return new Promise(function (resolve) {
            var adsIds = _this.GetIds();
            if (adsIds.length > 0) {
                // const period = this.period;
                var period_1 = '';
                var period_date_from_1 = _this.period.dateFrom;
                var period_date_to_1 = _this.period.dateTo;
                if (_this.period.period === 'overall') {
                    period_1 = 'day';
                    period_date_from_1 = '2012-06-01';
                    period_date_to_1 = moment(new Date()).format('YYYY-MM-DD');
                }
                else {
                    period_1 = _this.period.period;
                    period_date_from_1 = _this.period.dateFrom;
                    period_date_to_1 = _this.period.dateTo;
                }
                setTimeout(function () {
                    _this.vkService.GetStatistics({
                        accountId: _this.accountId,
                        ids: adsIds,
                        idsType: 'ad',
                        period: period_1,
                        dateFrom: period_date_from_1,
                        dateTo: period_date_to_1,
                        clientId: _this.clientId
                    })
                        .subscribe(function (data) {
                        _this.adsViewModels.forEach(function (ad) {
                            var stat = data.data.find(function (x) { return x.id === ad.id; });
                            ad.AddStatistic(stat);
                        });
                        resolve(clientId);
                    });
                }, DEFAULT_TIMEOUT);
            }
            else {
                resolve(clientId);
            }
        });
    };
    NewAutomationAdsComponent.prototype.LoadReach = function (clientId) {
        var _this = this;
        return new Promise(function (resolve) {
            var ids = _this.adsViewModels.filter(function (x) { return x.ad_format === 9; }).map(function (x) { return x.id; }).join(',');
            if (ids && ids.length > 0) {
                setTimeout(function () {
                    _this.vkService.GetPostReach({
                        ids: ids,
                        accountId: _this.accountId,
                        idsType: 'ad',
                        clientId: _this.clientId
                    })
                        .subscribe(function (data) {
                        _this.adsViewModels = _this.adsViewModels.map(function (ad) {
                            var reach = data.data.find(function (x) { return x.id === ad.id; });
                            ad.AddReach(reach);
                            return ad;
                        });
                        resolve(clientId);
                    });
                }, DEFAULT_TIMEOUT);
            }
            else {
                resolve(clientId);
            }
        });
    };
    NewAutomationAdsComponent.prototype.LoadWallPost = function (clientId) {
        var _this = this;
        return new Promise(function (resolve) {
            var ids = _this.GetWithFormatIds();
            if (ids.length > 0) {
                _this.vkService.GetWallById(ids)
                    .subscribe(function (data) {
                    _this.adsViewModels = _this.adsViewModels.map(function (ad) {
                        var newIds = ad.link_url.replace('https://vk.com/wall', '')
                            .replace('http://vk.com/wall', '')
                            .split('_')
                            .map(function (adl) { return parseInt(adl, 10); });
                        var w = data.data.find(function (wall) { return wall.owner_id === newIds[0] && wall.id === newIds[1]; });
                        ad.AddWall(w);
                        ad.AddUtms(_this.vkExtractService, _this.extractService, w);
                        return ad;
                    });
                    resolve(clientId);
                });
            }
            else {
                _this.adsViewModels = _this.adsViewModels.map(function (ad) {
                    ad.AddUtms(_this.vkExtractService, _this.extractService, null);
                    return ad;
                });
                resolve(clientId);
            }
        });
    };
    NewAutomationAdsComponent.prototype.LoadLayouts = function (clientId) {
        var _this = this;
        this.showGroupJoinData = false;
        return new Promise(function (resolve) {
            var ids = _this.GetIds().split(',').map(function (x) { return parseInt(x, 10); });
            setTimeout(function () {
                _this.vkService.GetAdsLayout({
                    adIds: ids,
                    offset: 0,
                    includeDeleted: false,
                    limit: 2000,
                    clientId: clientId,
                    campaignIds: [_this.campaignId],
                    accountId: _this.accountId
                })
                    .subscribe(function (data) {
                    _this.adsViewModels = _this.adsViewModels.map(function (ad) {
                        var layout = data.data.find(function (x) { return x.id === ad.id; });
                        if (!_this.showGroupJoinData && ad.ad_format !== 9) {
                            if (layout && layout.link_url.indexOf('vk.com/') >= 0) {
                                _this.showGroupJoinData = true;
                            }
                        }
                        ad.AddLayout(layout, _this.vkUrlExtractor);
                        return ad;
                    });
                    resolve(clientId);
                });
            }, DEFAULT_TIMEOUT);
        });
    };
    NewAutomationAdsComponent.prototype.LoadYandex = function (countOfRepeats) {
        var _this = this;
        if (countOfRepeats === void 0) { countOfRepeats = 1; }
        this.StartYandexMetrikaLoading();
        return new Promise(function (resolve) {
            if (_this.goalId) {
                setTimeout(function () {
                    _this.yandex.LoadGoalReach({
                        counterId: _this.counterId,
                        goalId: _this.goalId,
                        dateFrom: (_this.period.dateFrom === '0') ? '2012-06-01' : _this.period.dateFrom,
                        dateTo: (_this.period.dateTo === '0') ? 'today' : _this.period.dateTo
                    })
                        .then(function (data) {
                        countOfRepeats = countOfRepeats - 1;
                        if (countOfRepeats > 0 && data.data.length === 0) {
                            setTimeout(function () {
                                _this.LoadYandex(countOfRepeats);
                            }, 2000);
                        }
                        else {
                            _this.adsViewModels = _this.adsViewModels.map(function (ad) {
                                ad.AddYandexData(null, _this.yandex);
                                return ad;
                            });
                            _this.StopYandexMetrikaLoading();
                        }
                        resolve();
                    });
                }, DEFAULT_TIMEOUT);
            }
            resolve();
        });
    };
    NewAutomationAdsComponent.prototype.LoadCounters = function () {
        this.yandex.LoadCounters();
    };
    NewAutomationAdsComponent.prototype.LoadGoals = function () {
        this.yandex.LoadGoals(this.counterId);
    };
    NewAutomationAdsComponent.prototype.GetIds = function () {
        return this.adsViewModels.map(function (x) { return x.id; }).join();
    };
    NewAutomationAdsComponent.prototype.OnChangeShowUtms = function (showUtms) {
        this.showUtms = showUtms;
        this.SetActionValues();
    };
    NewAutomationAdsComponent.prototype.OnChangeShowMin = function (showMin) {
        this.showMin = showMin;
        this.SetActionValues();
    };
    NewAutomationAdsComponent.prototype.OnChangeShowLink = function (showLink) {
        this.showLink = showLink;
        this.SetActionValues();
    };
    NewAutomationAdsComponent.prototype.LoadActionValues = function () {
        var _this = this;
        var values = JSON.parse(localStorage.getItem('show_actions'));
        if (values) {
            Object.keys(values).forEach(function (x) {
                _this[x] = values[x];
            });
        }
    };
    NewAutomationAdsComponent.prototype.GetAdUrls = function (ad) {
        if (!ad) {
            return null;
        }
        if (ad.ad_format === 9) {
            return this.vkUrlExtractor.ExtractUrl(ad.wall);
        }
        else {
            return [ad.link_url];
        }
    };
    NewAutomationAdsComponent.prototype.IsArticle = function (post, link) {
        var containOnlyLink = post && post.attachments.filter(function (x) { return isNullOrUndefined(x.link); }).length === 0;
        return link.url.indexOf('vk.com/@') >= 0 && containOnlyLink;
    };
    NewAutomationAdsComponent.prototype.GetLinkClass = function (classname, post, link) {
        return classname + (this.IsArticle(post, link) ? " " + classname + "_article-mode" : '');
    };
    NewAutomationAdsComponent.prototype.GetLinkTitleLength = function (post, link) {
        return this.IsArticle(post, link) ? 16 : 28;
    };
    NewAutomationAdsComponent.prototype.ExportToExcel = function () {
        var _this = this;
        var data = this.adsViewModels.map(function (x) {
            return Object.keys(_this.alternatives).reduce(function (a, b) {
                if (_this.alternatives[b].pipe) {
                    a[_this.alternatives[b].name] = new _this.alternatives[b].pipe().transform(parseInt(x[b], 10));
                }
                else {
                    a[_this.alternatives[b].name] = x[b];
                }
                return a;
            }, {});
        });
        this.excel.exportAsExcelFile(data, this.currentCampaigns.name.replace(' ', '_'));
    };
    NewAutomationAdsComponent.prototype.SelectCounter = function (data) {
        this.counterId = data;
        this.LoadGoals();
    };
    NewAutomationAdsComponent.prototype.SelectGoal = function (data) {
        this.goalId = data;
        this.LoadYandex(5);
    };
    NewAutomationAdsComponent.prototype.ChangeDate = function (date) {
        this.period = date;
        this.InitPage();
    };
    NewAutomationAdsComponent.prototype.GetAdUrlInVkCabinet = function (ad) {
        return "https://vk.com/ads?act=office&union_id=" + ad.id;
    };
    NewAutomationAdsComponent.prototype.StartYandexMetrikaLoading = function () {
        this.IsYandexMetrikaLoading = true;
    };
    NewAutomationAdsComponent.prototype.StopYandexMetrikaLoading = function () {
        this.IsYandexMetrikaLoading = false;
    };
    NewAutomationAdsComponent.prototype.GetWithFormatIds = function () {
        return this.adsViewModels
            .filter(function (x) { return x.ad_format === 9; })
            .map(function (x) { return x.link_url.replace('http://vk.com/wall', ''); })
            .join(',');
    };
    NewAutomationAdsComponent.prototype.OpenBidManager = function (isUpdating) {
        var _this = this;
        this.strategiesUpdate = isUpdating;
        if (this.strategiesUpdate) {
            this.inputs = {};
            this.strategies.forEach(function (x) {
                var fields = {};
                x.inputs.forEach(function (y) {
                    fields[y.code] = {
                        name: y.code,
                        type: y['frontendInputType'],
                        label: y.title,
                        value: (_this.SelectedGroupsStrategy) ? _this.SelectedGroupsStrategy[y.code] : 0,
                        options: y.options
                    };
                    if (_this.modifyFunctions[y.code]) {
                        fields[y.code].modyfyFunction = _this.modifyFunctions[y.code];
                    }
                });
                _this.inputs[x.code] = fields;
            });
        }
        this.BidManagmentModalOpened = true;
    };
    NewAutomationAdsComponent.prototype.HideSelected = function () {
        throw new Error('Not implemented');
    };
    NewAutomationAdsComponent.prototype.LoadStrategies = function () {
        var _this = this;
        this.strategiesService.GetAllStrategies({ showInactive: false, onlyAllowedInCreationModule: false })
            .subscribe(function (data) {
            _this.strategies = data;
            _this.inputs = {};
            _this.strategies.forEach(function (x) {
                var fields = {};
                x.inputs.forEach(function (y) {
                    fields[y.code] = {
                        name: y.code,
                        type: y['frontendInputType'],
                        label: y.title,
                        value: (_this.SelectedGroupsStrategy) ? _this.SelectedGroupsStrategy[y.code] : 0,
                        options: y.options
                    };
                });
                _this.inputs[x.code] = fields;
            });
        });
    };
    NewAutomationAdsComponent.prototype.SuccessHandle = function (formValue) {
        var _this = this;
        formValue.strategyType = this.strategyType;
        Object
            .keys(formValue)
            .forEach(function (x) {
            if (_this.modifyFunctions[x]) {
                formValue[x] = _this.modifyFunctions[x](formValue[x]);
            }
        });
        var data = {
            properties: formValue,
            accountId: this.accountId,
            clientId: this.clientId || 0,
            strategyType: this.strategyType,
            ads: this.SelectedGroups.map(function (x) { return x.id; })
        };
        this.errors = [];
        formValue.ads = this.SelectedGroups.map(function (x) { return x.id; });
        formValue.accountId = this.accountId;
        formValue.clientId = this.clientId;
        if (!this.strategiesUpdate) {
            this.usiService.CreateUserStrategy(data)
                .subscribe(function (data1) {
                _this.ToggleModalOpened();
                _this.LoadStrategiesForAds();
            }, function (err) {
                _this.errors.push(err.error.description);
            });
        }
        else {
            this.usiService.UpdateUserStrategies(data)
                .subscribe(function (data1) {
                _this.ToggleModalOpened();
                _this.LoadStrategiesForAds();
            }, function (err) {
                _this.errors.push(err.error.description);
            });
        }
    };
    NewAutomationAdsComponent.prototype.LoadStrategiesForAds = function () {
        var _this = this;
        this.usiService
            .GetStrategiesByAdAndAccountIds({
            accountId: this.accountId,
            clientId: this.clientId,
            viewModel: {
                adIds: this.adsViewModels.map(function (x) { return x.id; })
            }
        })
            .subscribe(function (data) {
            if (data.length > 0) {
                _this.adsViewModels.forEach(function (x) {
                    var strategy = data.find(function (y) { return y.adId === x.id; });
                    x.AddStrategy(strategy);
                });
                _this.ShowStrategyColumns = true;
            }
            else {
                _this.adsViewModels.forEach(function (x) {
                    x.AddStrategy(null);
                });
                _this.ShowStrategyColumns = false;
            }
        });
    };
    NewAutomationAdsComponent.prototype.CancelHandle = function () {
        this.ToggleModalOpened();
    };
    NewAutomationAdsComponent.prototype.ToggleModalOpened = function () {
        this.BidManagmentModalOpened = !this.BidManagmentModalOpened;
    };
    NewAutomationAdsComponent.prototype.CreateStrategy = function (data) {
        data.ads = this.SelectedGroups;
        this.usiService.CreateUserStrategy(data)
            .subscribe(function (data1) {
        });
    };
    NewAutomationAdsComponent.prototype.RemoveStrategies = function () {
        var _this = this;
        var promises = this.SelectedGroups.map(function (x) {
            return new Promise(function (resolve) {
                _this.usiService.RemoveStrategy({
                    accountId: _this.accountId,
                    adId: x.id
                })
                    .subscribe(function (data) {
                    resolve();
                });
            });
        });
        Promise.all(promises)
            .then(function (x) {
            _this.LoadStrategiesForAds();
        })
            .catch(function (x) {
            _this.LoadStrategiesForAds();
        });
    };
    NewAutomationAdsComponent.prototype.SelectedRow = function (data) {
        this.LoadStrategies();
    };
    NewAutomationAdsComponent.prototype.GetSelectedOptionValue = function (input) {
        return input.type.options.find(function (x) { return parseInt(x.value, 10) === input.value; }).title;
    };
    NewAutomationAdsComponent.prototype.ngOnChanges = function (changes) {
    };
    NewAutomationAdsComponent.prototype.ShowAutomationInputs = function () {
        return this.route.snapshot.queryParams.noonewillguessthatthisparameteropenssecretmethods;
    };
    return NewAutomationAdsComponent;
}());
export { NewAutomationAdsComponent };
