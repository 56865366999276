import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ExportCrmComponent} from './components/export-crm/export-crm.component';
import {ImportCrmComponent} from './components/import-crm/import-crm.component';
import {ImportCrmSectionSettingsComponent} from './components/import-crm-section-settings/import-crm-section-settings.component';
import {MatCheckboxModule, MatRadioModule, MatSelectModule, MatStepperModule} from '@angular/material';
import {VkControlsModule} from '../../../vk-controls/vk-controls.module';
import {ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '../../../shared/shared.module';
import {NewExportCrmComponent} from './components/new-export-crm/new-export-crm.component';
import {CustomFieldControlComponent} from './components/custom-field-control/custom-field-control.component';
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {OverlayModule} from "@angular/cdk/overlay";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatPaginatorModule} from "@angular/material/paginator";

@NgModule({
  declarations: [ExportCrmComponent, ImportCrmComponent, ImportCrmSectionSettingsComponent, NewExportCrmComponent, CustomFieldControlComponent],
  exports: [
    ExportCrmComponent,
    ImportCrmComponent,
    ImportCrmSectionSettingsComponent,
    NewExportCrmComponent
  ],
    imports: [
        CommonModule,
        MatStepperModule,
        VkControlsModule,
        MatSelectModule,
        ReactiveFormsModule,
        MatCheckboxModule,
        MatRadioModule,
        SharedModule,
        MatButtonModule,
        MatIconModule,
        OverlayModule,
        MatExpansionModule,
        MatPaginatorModule
    ]
})
export class SharedCrmModule {
}
