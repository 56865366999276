import {ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {UserAutomationCabinetSlot} from '../../../../../api/models/user-automation-cabinet-slot';
import {isNullOrUndefined} from 'util';
import {AccountViewModel} from '../../../../models/view-models/account-view-model';
import {Client} from '../../../../../api/models/client';
import {Cabinet} from '../../../../../api/models/cabinet';
import {Statistic} from '../../../../models/statistic';
import {PickListComponent} from '../../../../components/pick-list/pick-list.component';
import {DgPaginationComponent} from '../../../../../ngr-ui/components/dg-pagination/dg-pagination.component';
import {AutomationVkService} from '../../../../../api/services/automation-vk.service';
import {DexieCabinetsService} from '../../../../../shared/services/dexie/dexie-cabinets.service';
import {AutomationVkSlotService} from '../../../../../api/services/automation-vk-slot.service';
import {AutomationExpirationManagerService} from '../../../../services/automation-expiration-manager.service';
import {DexieClientsService} from '../../../../../shared/services/dexie/dexie-clients.service';
import {AdAccountSettingsViewModel} from '../../../models/ad-account-settings.view-model';
import {AdManagerService} from '../../../../../api/services';
import {MatDialog} from '@angular/material';
import {CreateCampaignFormComponent} from '../../create-campaign-form/create-campaign-form.component';
import {AdViewModel} from '../../../../models/view-models/ad-view-model';
import {ActivatedRoute} from '@angular/router';
import {ControlContainer} from '@angular/forms';
import {debounceTime, distinctUntilChanged, flatMap, tap} from 'rxjs/operators';

@Component({
  selector: 'app-ad-account-manager',
  templateUrl: './ad-account-manager.component.html',
  styleUrls: ['./ad-account-manager.component.scss']
})
export class AdAccountManagerComponent implements OnInit, OnChanges {

  public token = '2d74c771895089eb9c237b388499b6877a967ea5b25935215fd489276327ceed6c4cf49708b44cb4f1a6b';
  @Input() public accounts: Array<Cabinet> = null;
  public accountsViewModels: Array<AccountViewModel> = [];
  public accountsStatistics: Array<Statistic> = [];
  public IsExpired = true;
  public opened = false;
  public pickItems = [];
  public slots: Array<UserAutomationCabinetSlot>;
  public countOfMaxSlots = 0;
  public countOfFree = 0;
  public firstLoading = true;
  public loading = false;
  public openedActivationCabinet = false;

  @Input() public accountId = null;
  @Input() public account_role = null;
  @Input() public campaignId = null;

  @Input() public disabled: boolean = false;
  public loadingCampaigns = false;

  public campaigns = [];

  @Input() selectedCampaignsType = '';
  @Input() ad: AdViewModel = null;

  @Output() onNext: EventEmitter<AdAccountSettingsViewModel> = new EventEmitter<AdAccountSettingsViewModel>();
  @Output() onSave: EventEmitter<AdAccountSettingsViewModel> = new EventEmitter<AdAccountSettingsViewModel>();

  @ViewChild('pickList') public pickList: PickListComponent;

  @ViewChild('dgPagination') public dgPagination: DgPaginationComponent;

  public get CampaingsFiltered() {
    return this.campaigns.filter(campaign => campaign.type === this.selectedCampaignsType);
  }

  public get AccountsFiltered() {
    return this.accounts.filter(x => x.account_type !== 'agency');
  }

  constructor(
    private _api: AutomationVkService,
    private localDb: DexieCabinetsService,
    private _db: AutomationVkSlotService,
    public accessManager: AutomationExpirationManagerService,
    private localClientsDb: DexieClientsService,
    private adManagerService: AdManagerService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    public controlContainer: ControlContainer,
    private changeDetector: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    // this.LoadData();
    if (this.accounts.length > 0) {
      const account = this.accounts.find(x => !x.is_blocked);
      console.log(account, 'ACCOUNT ID');
      if (account) {
        this.controlContainer.control.get('accountId').setValue(`${account.account_id}_${account.client_id}`);
        this.changeDetector.detectChanges();
      }
    }
    this.controlContainer.control.get('accountId')
      .valueChanges
      .pipe(
        distinctUntilChanged(),
      )
      .subscribe(data => {
        console.log('CHANGING CAMPAIGN');
        this.LoadCampaign();
      });
  }

  public LoadData() {
    this.LoadSlots()
      .pipe(
        debounceTime(1000),
        flatMap(data => this.LoadAccounts())
      )
      .subscribe(data => {
        console.log(data);
      });
  }

  public SelectFirstCampaignByType(param) {
  }

  public LoadSlots() {
    return this._db.GetSlots()
      .pipe(
        tap(slots => {
          this.slots = slots.data;
          console.log(this.slots);
        })
      );
  }

  public LoadAccounts() {
    console.log(this.HaveActivatedCabinets());
    if (this.HaveActivatedCabinets()) {
      return this._api.GetAccounts()
        .pipe(
          tap(accounts => {
            this.accounts = accounts.data.map((account, i) => {
              console.log(account, `Account ${i}`, this.slots);
              return new AccountViewModel(
                account.account_id,
                account.account_name,
                account.access_role,
                account.account_status,
                account.account_type,
                this.CanCabinetSelected(account) || account.account_type === 'agency  ',
                account.client_id,
                i
              );
            });

            if (!this.controlContainer.control.get('accountId').value || this.controlContainer.control.get('accountId').value.includes('undefined')) {
              const account = this.accounts.filter(acc => !acc.is_blocked && acc.account_type !== 'agency');
              console.log(account);
              this.controlContainer.control.get('accountId').setValue(`${account[0].account_id}_${account[0].client_id}`);
            }
          })
        );
    }
    return [];
  }

  private CanCabinetSelected(account) {
    console.log(account.account_id, account.client_id, 'CLIEN ID');
    account.client_id = account.client_id === undefined ? null : account.client_id;
    const slot1 = this.slots
      .find(slot => slot.bindedCabinetId === account.account_id && slot.bindedClientId === account.client_id);
    console.log(isNullOrUndefined(slot1), account.account_type === 'agency', 'CAN BE SELECTED');
    return isNullOrUndefined(slot1) || account.account_type === 'agency';
  }

  public LoadCampaign() {
    this.loadingCampaigns = true;
    const [accountId, clientId] = this.controlContainer.control.get('accountId').value.split('_');
    this.controlContainer.control.get('campaignId').setValue(null);
    this._api.GetCampaigns({
      accountId,
      clientId
    })
      .delay(1500)
      .subscribe(campaigns => {
        this.campaigns = campaigns.data.sort((a, b) => b.id - a.id);
        if (isNullOrUndefined(this.controlContainer.control.get('campaignId').value) && this.CampaingsFiltered.length > 0) {
          this.controlContainer.control.get('campaignId').setValue(this.CampaingsFiltered[0].id);
        }
        this.loadingCampaigns = false;
      });
  }

  public CreateCompany() {
    const [accountId, clientId] = this.controlContainer.control.get('accountId').value.split('_');
    this.dialog
      .open(CreateCampaignFormComponent, {
        width: '600px',
        data: {
          accountId,
          clientId,
          type: this.selectedCampaignsType
        }
      })
      .afterClosed()
      .subscribe(data => {
        this.LoadCampaign();
        const campaign = data[0];
        if (campaign) {
          this.controlContainer.control.get('campaignId').setValue(data[0].id);
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedCampaignsType && this.CampaingsFiltered.length > 0) {
      console.log(this.CampaingsFiltered);
      this.controlContainer.control.get('campaignId').setValue(this.CampaingsFiltered[0].id);
    }

    if (changes.accounts) {
      const account = this.accounts.find(x => !x.is_blocked);
      console.log(account, 'ACCOUNT ID');
      if (account) {
        this.controlContainer.control.get('accountId').setValue(`${account.account_id}_${account.client_id}`);
        this.changeDetector.detectChanges();
      }
    }
  }

  GetCampaignTitle(account: Cabinet) {
    if (account.is_blocked) {
      return 'Данный кабинет не подключен к системе.';
    }
  }

  OnSaveCabinetsBined(data: any) {
    if (!data.isSelected) {
      this._db.AssignCabinetToSlot({
        id: data.account_id,
        clientId: data.client_id
      })
        .subscribe(data1 => {
          this.LoadData();
        }, (err) => {
          this.pickList.SetError(err.error);
        });
    } else {
      this._db.UnBindCabinetFromSlot({
        id: data.account_id,
        clientId: data.client_id
      })
        .subscribe(data1 => {
          this.LoadData();
        }, (err) => {
          this.pickList.SetError(err.error);
        });
    }
    // this.LoadSlots();
  }

  OnClose() {
    this.ToggleOpened();
  }

  public ToggleOpened() {
    this.openedActivationCabinet = !this.openedActivationCabinet;
  }

  public HaveActivatedCabinets() {
    return (this.slots) ? this.slots.filter(slot => slot.bindedCabinetId !== null).length > 0 : false;
  }
}
