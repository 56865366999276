import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-affiliate-navigation',
  templateUrl: './affiliate-navigation.component.html',
  styleUrls: ['./affiliate-navigation.component.scss']
})
export class AffiliateNavigationComponent implements OnInit {
  links: any[] = [
    {
      path: '/affiliate/settings',
      title: 'Главная'
    },
    {
      path: '/affiliate/statistics',
      title: 'Статистика'
    },
    // {
    //   path: '/affiliate/settings/info',
    //   title: 'Информация о пользователе'
    // },
    // {
    //   path: '/affiliate/settings/payment-requests',
    //   title: 'Запросы на оплату'
    // }
  ];

  constructor() {
  }

  ngOnInit() {
  }

}
