<div class="teazers">
  <div class="teazers__form">
    <!--<div class="vk-container">-->
      <!--<div class="vk-strip-wrapper vk-strip-wrapper_ad-cabinet-style">-->
        <!--<label class="vk-label">-->
          <!--Выберите тип групировки-->
        <!--</label>-->
        <!--<div class="vk-strip-wrapper__item vk-strip-wrapper__item_ad-cabinet-style">-->
          <!--<mat-radio-group [formControl]="controlContainer.control.get('type')">-->
            <!--<div class="vk-radio-container">-->
              <!--<mat-radio-button value="linear" color="primary">Линейный</mat-radio-button>-->
            <!--</div>-->

            <!--<div class="vk-radio-container">-->
              <!--<mat-radio-button value="quadratic" color="primary">Квадратичный (m x n)</mat-radio-button>-->
            <!--</div>-->
          <!--</mat-radio-group>-->
        <!--</div>-->
      <!--</div>-->
    <!--</div>-->
    <app-create-teazer-form
      (validStatusChanged)="OnValidStatusChange($event)"
      (teazerInfoCreated)="OnTeazerChange($event)"
      [formGroup]="controlContainer.control"

      [categories]="categories"
    ></app-create-teazer-form>
  </div>
  <div class="teazers__list">
    <ng-container *ngFor="let teazer1 of teazers">
      <div class="teaser-container">
          <app-teazer
            [teazer]="teazer1.teazer"
            [title]="teazer1.title"
            [image]="teazer1.image"
          ></app-teazer>
      </div>
    </ng-container>
  </div>
</div>
<div class="vk-container">
  <app-images-manager [minWidth]="145" [minHeight]="165" (selected)="OnSelectImage($event)"></app-images-manager>
</div>
