import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from "@angular/forms";
import {ConnectedPositionStrategy, FlexibleConnectedPositionStrategy} from "@angular/cdk/overlay";
import {CustomFieldValueQuery} from "../../../../stores/custom-field-value/custom-field-value.query";
import {pipe} from "rxjs";
import {delay} from "rxjs/operators";

@Component({
    selector: 'app-custom-field-control',
    templateUrl: './custom-field-control.component.html',
    styleUrls: ['./custom-field-control.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CustomFieldControlComponent),
            multi: true
        }
    ]
})
export class CustomFieldControlComponent implements OnInit, ControlValueAccessor {

    @Input() public FieldName: string = '';
    @Input() public Description: string = '';
    
    public Control: FormControl = new FormControl();
    
    public CustomFieldsValues$ = this.customFieldValueQuery.CustomFieldsValues$;

    private change;
    private touch;

    public Disabled = false;
    public IsAutocompleteOptionsOpened: boolean = false;
    overlayPosition: FlexibleConnectedPositionStrategy;
    // positions = [
    //     new ConnectedPosition()
    // ]

    constructor(
        private customFieldValueQuery: CustomFieldValueQuery
    ) {
    }

    ngOnInit() {
        this.Control.valueChanges
            .pipe(
                delay(500)
            )
            .subscribe(value => {
            if (this.change) {
                this.change(value)
            }
        });
    }

    registerOnChange(fn: any): void {
        this.change = fn;
    }

    registerOnTouched(fn: any): void {
        this.touch = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.Disabled = isDisabled;
    }

    writeValue(obj: any): void {
        this.Control.setValue(obj);
    }
    
    public GenerateOption(optionValue) {
        return `${this.Control.value}{{${optionValue}}}`
    }
    
    SelectValue(optionValue) {
        const newValue = this.GenerateOption(optionValue);
        this.Control.patchValue(newValue);
    }
    
    public OpenAutoCompleteOptions() {
        console.log('AUTO_COMPLETE IS OPENED');
        this.IsAutocompleteOptionsOpened = true;
    }

    CloseAutoCompleteOptions($event: any) {
        console.log('CLOSED');
        this.IsAutocompleteOptionsOpened = false;
    }
}
