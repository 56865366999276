<form [formGroup]="controlContainer.control" class="teazer-form">
  <div class="teasers-form">
    <!--<div class="vk-container">-->
    <!--<label class="vk-label">-->
    <!--Введите заголовок объявления:-->
    <!--</label>-->
    <!--</div>-->
    <div class="vk-container" *ngIf="categories">
      <div class="vk-strip-wrapper vk-strip-wrapper_ad-cabinet-style vk-strip-wrapper_top" *ngIf="!isUpdating">
        <div class="vk-label">
          Выберите тематику объявления:
        </div>
        <div class="vk-strip-wrapper__item vk-strip-wrapper__item_ad-cabinet-style">
          <select formControlName="categoryId" [disabled]="true" class="vk-select vk-select_full-width">
            <ng-container *ngFor="let category of categories">
              <option [value]="category.id">
                {{category.name}}
              </option>
            </ng-container>
          </select>
          <div *ngIf="errors.groupId && controlContainer.control.get('groupId').dirty">
            <div *ngFor="let error of errors.groupId" class="vk-error">{{error}}</div>
          </div>
        </div>
      </div>
    </div>

    <div formArrayName="title">
      <ng-container *ngFor="let control of TitleControls; let i=index">
        <div class="vk-container">
          <div class="vk-strip-wrapper vk-strip-wrapper_ad-cabinet-style vk-strip-wrapper_top">
            <ng-container *ngIf="i === 0">
              <label class="vk-label">
                Заголовок объявления
              </label>
            </ng-container>
            <div class="vk-strip-wrapper__item vk-strip-wrapper__item_ad-cabinet-style">
              <div class="vk-strip-wrapper vk-strip-wrapper_top">
                <div class="vk-strip-wrapper__item vk-strip-wrapper__item_wide">
                  <input type="text" [formControlName]="i" class="vk-input vk-input_full-width"
                         placeholder="Введите заголовок объявления" minlength="1" maxlength="33" required>
                </div>

                <!--<div class="vk-strip-wrapper__item">-->
                  <!--<button (click)="AddControl()" *ngIf="CanVisibleAdd(i)" class="create-ad-icons"-->
                          <!--title="Добавить ещё один заголовок">-->
                    <!--<mat-icon>add</mat-icon>-->
                  <!--</button>-->
                <!--</div>-->
                <!--<div class="vk-strip-wrapper__item">-->
                  <!--<button (click)="RemoveControl(i)" *ngIf="CanBeRemoved()"-->
                          <!--class="create-ad-icons create-ad-icons_negative" title="Удалить этот заголовок">-->
                    <!--<mat-icon>close</mat-icon>-->
                  <!--</button>-->
                <!--</div>-->
              </div>
              <div class="teser-input-tip">
                Осталось символов: {{33 - controlContainer.control.value.title[i].length}}
              </div>
              <ng-container *ngIf="errors.title && controlContainer.control.get('title').controls[i].dirty">
                <div *ngFor="let error of errors.title[i]" class="vk-error">{{error}}</div>
              </ng-container>
            </div>
          </div>
        </div>

      </ng-container>
    </div>


    <div class="vk-container">
      <div class="vk-strip-wrapper vk-strip-wrapper_ad-cabinet-style vk-strip-wrapper_top">
        <label for="teaser-url" class="vk-label">
          Введите ссылку
        </label>
        <div class="vk-strip-wrapper__item vk-strip-wrapper__item_ad-cabinet-style">
          <input id="teaser-url" type="text" formControlName="link" class="vk-input vk-input_full-width"
                 placeholder="Введите ссылку на рекламируемый ресурс" minlength="1" required>

          <ng-container *ngIf="errors.link && controlContainer.control.get('link').dirty">
            <div *ngFor="let error of errors.link" class="vk-error">{{error}}</div>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="vk-container">
      <div class="vk-strip-wrapper vk-strip-wrapper_ad-cabinet-style vk-strip-wrapper_top">
        <div class="vk-label">Название объявления</div>
        <div class="vk-strip-wrapper__item vk-strip-wrapper__item_ad-cabinet-style">
          <div class="vk-strip-wrapper">
            <div class="vk-strip-wrapper__item vk-strip-wrapper__item_wide">
              <input type="text" formControlName="name" class="vk-input vk-input_full-width">
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</form>
