import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ControlContainer, FormArray, FormControl, FormControlName, FormGroup, Validators} from '@angular/forms';
import {ITeazerViewModel} from '../../models/teazer.view-model';
import {url} from '../../validators/url-validator';
import {UtilsService} from '../../../../shared/services/utils.service';
import {Utils} from 'ngx-bootstrap';
import {AdManagerService} from '../../../../api/services';

@Component({
  selector: 'app-create-teazer-form',
  templateUrl: './create-teazer-form.component.html',
  styleUrls: ['./create-teazer-form.component.scss']
})
export class CreateTeazerFormComponent implements OnInit {

  @Input() public teazer;
  @Output() public teazerInfoCreated: EventEmitter<ITeazerViewModel> = new EventEmitter();
  @Output() public validStatusChanged: EventEmitter<any> = new EventEmitter<any>();

  @Input() public categories;

  public form: FormGroup;
  public errors: any = {};

  public get TitleControls(): any {
    return (this.controlContainer.control.get('title') as FormArray).controls;
  }

  constructor(
    private utilsService: UtilsService,
    public controlContainer: ControlContainer,
    private adManagerService: AdManagerService
  ) {
  }

  ngOnInit() {
    console.log(this.controlContainer.control.get('title'));
    this.controlContainer.control.valueChanges.subscribe(value => {
      this.validStatusChanged.emit(this.controlContainer.control.valid);
      this.errors = UtilsService.GetErrorsByForm('create-teazer-form', this.controlContainer.control, null);
      this.teazerInfoCreated.emit(value);
    });

    this.controlContainer.control.get('title').valueChanges.subscribe(data => {
      data.forEach((d, i) => {
        if (d.length > 33) {
          (this.controlContainer.control.get('title') as FormArray).controls[i].setValue(d.substring(0, 33));
        }
      });
    });
  }

  public ClearFormErrors() {
    this.errors = {};
  }

  public OnSelectImage(image) {
    console.log(image);
    this.controlContainer.control.get('image').patchValue(image);
  }

  public AddControl() {
    (this.controlContainer.control.get('title') as FormArray)
      .push(new FormControl('', [Validators.required, Validators.maxLength(33)]));
  }

  public RemoveControl(i) {
    (this.controlContainer.control.get('title') as FormArray).removeAt(i);
  }

  public CanBeRemoved() {
    return (this.controlContainer.control.get('title') as FormArray).controls.length > 1;
  }

  public CanVisibleAdd(i) {
    return (this.controlContainer.control.get('title') as FormArray).controls.length === i + 1;
  }
}
