import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AdImageViewModel} from '../../../../ad-manager/models/ad-image.view-model';

@Component({
  selector: 'app-new-ad-image',
  templateUrl: './new-ad-image.component.html',
  styleUrls: ['./new-ad-image.component.scss']
})
export class NewAdImageComponent implements OnInit {

  @Input() public image: AdImageViewModel;
  @Input() public minWidth: number;
  @Input() public minHeight: number;
  @Input() public maxImageSize: number;
  @Input() public selected: any;
  @Output() public selectedChange: EventEmitter<any> = new EventEmitter();
  @Output() public removeImage: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('imageContent') img: ElementRef;

  constructor() {
  }

  ngOnInit() {
  }

  public isValid() {
    return this.image.isValid(this.minWidth, this.minHeight, this.maxImageSize);
  }

  public Errors() {
    return this.image.Errors(this.minWidth, this.minHeight, this.maxImageSize);
  }

  public IsSelected(val) {
    return this.selected.includes(val);
  }

  OnChange(val) {
    if (this.isValid()) {
      this.selectedChange.emit(val);
    }
  }

  RemoveImage() {
    this.removeImage.emit(this.image);
  }
}
