var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { QueryEntity } from '@datorama/akita';
import { PaymentSystemsStore } from './payment-systems.store';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./payment-systems.store";
var PaymentSystemsQuery = /** @class */ (function (_super) {
    __extends(PaymentSystemsQuery, _super);
    function PaymentSystemsQuery(store) {
        var _this = _super.call(this, store) || this;
        _this.store = store;
        /**
         * Список всех платежных систем
         */
        _this.paymentSystems$ = _this.selectAll();
        /**
         * Выбранная платежная система
         */
        _this.selectedPaymentSystem$ = _this.selectActive();
        /**
         * Id выбранной платежной системы
         */
        _this.selectedPaymentSystemId$ = _this.selectedPaymentSystem$
            .pipe(map(function (x) { return x.id; }));
        /**
         * Статус загрузки
         */
        _this.paymentSystemLoading$ = _this.select(function (state) { return state.ui.prepareLinkLoading; });
        /**
         * Ссылка на страницу оплаты (ссылка на Paymaster)
         */
        _this.linkToPayment$ = _this.select(function (state) { return state.ui.linkToPayment; });
        return _this;
    }
    PaymentSystemsQuery.ngInjectableDef = i0.defineInjectable({ factory: function PaymentSystemsQuery_Factory() { return new PaymentSystemsQuery(i0.inject(i1.PaymentSystemsStore)); }, token: PaymentSystemsQuery, providedIn: "root" });
    return PaymentSystemsQuery;
}(QueryEntity));
export { PaymentSystemsQuery };
