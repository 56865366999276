<div class="ad-main-data-container">
  <div class="ad-main-data-container__item">
    <div class="preview-container">
      <ng-container *ngIf="ad.image_src && showMin">
        <div class="preview-container__teaser-container">
          <img [src]="ad.image_src" class="teaser-image">
        </div>
      </ng-container>

      <ng-container *ngIf="ad.wall">
        <div class="preview-container__post-container">
          <div class="post-box" *ngIf="showMin">
            <div class="post-group">
              <div class="post-group__item">
                <div class="post-group-logo-placeholder"></div>
              </div>
              <div class="post-group__item">
                <div class="post-group-name">
                  Название группы
                </div>
              </div>
            </div>
            <div class="post-text">
                    <span class="post-text__message"
                          [innerHtml]="ad.wall.text | nameShorter : 100 | postificate"></span>
            </div>

            <div class="post-attachemnts">
              <ng-container *ngFor="let attachment of ad.wall.attachments; let i = index">
                <!--photo attachment-->
                <ng-container *ngIf="attachment?.photo && i === 0">
                  <img [src]="GetPhotoHighestResolutionImage(attachment.photo)" class="post-photo">
                </ng-container>

                <!--Link attachemnt-->
                <ng-container *ngIf="attachment?.link">
                  <div [ngClass]="GetLinkClass('post-link-attachment', ad.wall, attachment.link)">
                    <div [ngClass]="GetLinkClass('post-link-attachment__image', ad.wall, attachment.link)"
                         [style.background-image]="'url(' + GetPhotoHighestResolutionImage(attachment.link.photo) + ')'">
                    </div>

                    <ng-container *ngIf="attachment.link.title">
                      <!--&& attachment.link.title.trim().length > 1-->
                      <div [ngClass]="GetLinkClass('post-link-attachment__body', ad.wall, attachment.link)">
                        <div [ngClass]="GetLinkClass('post-link-attachment-title', ad.wall, attachment.link)">
                          {{attachment.link.title | nameShorter : 16}}
                        </div>
                        <ng-container *ngIf="attachment.link.button">
                          <div [ngClass]="GetLinkClass('post-link-attachment-button', ad.wall, attachment.link)">
                            {{attachment.link.button.title}}
                          </div>
                        </ng-container>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>

                <!--Pretty cards attachment-->
                <ng-container *ngIf="attachment.pretty_cards">
                  <div class="post-prettycards">
                    <ng-container *ngFor="let card of attachment.pretty_cards.cards">
                      <div class="post-prettycards__item">
                        <div class="post-prettycard">
                          <div class="prettycard-preview" *ngIf="showMin">
                            <img [src]="card.images[0].url"
                                 class="prettycard-preview__image">
                          </div>

                          <ng-container *ngIf="card.title">
                            <div class="prettycard-title">
                              {{card.title}}
                            </div>
                          </ng-container>

                          <ng-container *ngIf="card.price">
                            <div class="prettycard-price">
                                <span class="prettycard-price__current">
                                  {{card.price}}
                                </span>
                              <span class="prettycard-price__old">
                                  {{card.price_old}}
                                </span>
                            </div>
                          </ng-container>

                          <ng-container *ngIf="card.button">
                            <div class="prettycard-button">
                              {{card.button.title | nameShorter : 12}}
                            </div>
                          </ng-container>

                        </div>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>

      <!--<div class="ad-preview-url" *ngIf="showMin">-->
        <!--<a [href]="ad.preview_link" target="_blank" rel="nofollow noopener">просмотр</a>-->
      <!--</div>-->
    </div>
  </div>

  <div class="ad-main-data-container__item">
    <div>
      <strong>
        <a [href]="GetAdUrlInVkCabinet(ad)" title="Открыть объявление в кабинете ВКонтакте"
           target="_blank" rel="nofollow noopener" class="ad-title">
          {{ad.name}}
        </a>
        <br>
        <a [routerLink]="['/automation/new-ad-manager/pro/update']" [queryParams]="{account_id: accountId, campaign_id: campaignId, client_id: clientId, adId: ad.id}" title="Редактировать объявление" *ngIf="ad.can_be_editable" class="ad-meta-data__edit-link">
          Редактировать
        </a>

      </strong>
    </div>
    <!--<div class="ad-main-data-container__item">-->
      <!--<ng-container *ngIf="ad.canBeCopied; else adCannotBeCopied">Вы можете скопировать данное объявление</ng-container>-->
      <!--<ng-template #adCannotBeCopied>Вы не можете скопировать данное объявление</ng-template>-->
    <!--</div>-->

    <div class="ad-meta-data">
      <ng-container *ngIf="ad.ad_url && showLink">
        <div class="ad-meta-data__domain">
          <a [href]="ad.ad_url[0]" [title]="ad.ad_url[0]"
             target="_blank" rel="nofollow noopener">
            {{ad.ad_url[0] | domainOnly}}
          </a>
        </div>
      </ng-container>

      <ng-container *ngIf="ad?.utms?.length > 0 && showUtms">
        <div class="ad-meta-data__utms">
          <div class="utm-list">
            <ng-container *ngFor="let utmMark of ad.utms">
              <div class="utm-list__item">
                <span [title]="utmMark.type + '=' + utmMark.value" class="utm-mark">{{utmMark.value}}</span>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="isImpossibleCopyingMessageVisible">
        <ng-container *ngIf="!ad?.CanBeCopied() && showCopyingMessage">
          Вы не можете скопировать данное объявление
        </ng-container>
      </ng-container>

      <ng-container *ngIf="ad.approved === 3">
        <ng-container *ngIf="!moderationLoading; else loading">
          Модерация не прошла <button class="btn btn-primary btn-sm" (click)="moderationDetails()">Подробнее</button>
        </ng-container>
        <ng-template #loading>
          <div class="fx-row">
            <mat-spinner [diameter]="20"></mat-spinner> Загрузка
          </div>
        </ng-template>
      </ng-container>
    </div>
  </div>
</div>

