import {Directive, TemplateRef} from '@angular/core';

@Directive({
  selector: '[appPopoverMenuContent]'
})
export class PopoverMenuContentDirective {
  constructor(
    public template: TemplateRef<any>
  ) {
  }

}
