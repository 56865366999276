import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {OauthBindEmailComponent} from './routes/oauth-bind-email/oauth-bind-email.component';
import {TokenReceiverComponent} from './routes/token-receiver/token-receiver.component';
import {SecuredOauthBindEmailComponent} from './routes/secured-oauth-bind-email/secured-oauth-bind-email.component';
import {AuthGuardGuard} from '../shared/guards/auth-guard.guard';

const routes: Routes = [
    {
        path: '',
        children: [
            {
                path: 'oauth-token-receive',
                component: TokenReceiverComponent
            },
            {
                path: 'oauth',
                component: OauthBindEmailComponent,
            },
            {
                path: 'oauth-bind',
                component: SecuredOauthBindEmailComponent,
                canActivate: [AuthGuardGuard]
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class OauthRoutingModule {
}
