import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ControlContainer, FormGroup} from "@angular/forms";
import {environment} from "../../../../../environments/environment";
import {AgeSegment} from "../../../models/view-models/age-segment";

@Component({
  selector: 'app-age-segmentation-item',
  templateUrl: './age-segmentation-item.component.html',
  styleUrls: ['./age-segmentation-item.component.scss']
})
export class AgeSegmentationItemComponent implements OnInit {

  @Input() public index = 0;
  @Input() public crossedSegments: Array<AgeSegment>;
  @Input() public formGroup: FormGroup;
  @Output() public removeTriggered: EventEmitter<any> = new EventEmitter<any>();
  @Output() public formValueChanged: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public controlContainer: ControlContainer
  ) {
  }

  public get ageFromName() {
    return `ageFrom_${this.index}`;
  }

  public get ageToName() {
    return `ageTo_${this.index}`;
  }

  public get agesFrom() {
    let ages = environment.vkAges;
    if (this.ageToValue) {
      ages = ages.filter(age => age <= this.ageToValue);
    }
    return ages;
  }

  public get agesTo() {
    let ages = environment.vkAges;
    if (this.ageFromValue) {
      ages = ages.filter(age => age >= this.ageFromValue);
    }
    return ages;
  }

  public get ageFromValue() {
    return this.controlContainer.control.get('ageFrom').value;
  }

  public get ageToValue() {
    return this.controlContainer.control.get('ageTo').value;
  }

  ngOnInit() {
    console.log(this.controlContainer);
    this.formGroup.valueChanges.subscribe(x => {
      this.formValueChanged.emit({index: this.index, value: x});
    })
  }

  public IsEnabledEachAges() {
    const ageFrom = this.ageFromValue;
    const ageTo = this.ageToValue;

    return ageFrom !== null && ageTo !== null;
  }

  public Remove() {
    this.removeTriggered.emit(this.index);
  }
}
