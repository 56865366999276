export class IncredibleCopyNotificationDataViewModel {
  public isAllAdsIncredibleToCopy: boolean;
  public incredibleToCopyTypes: Array<IncrediblesCopyType>;
}

export enum IncrediblesCopyType {
  PRETTY_CARD = 'Карусель',
  STORIES = 'Реклама в историях',
  LINK_WITH_BUTTON = 'Запись с кнопкой',
  REQUEST_COLLECTING = 'Сбор заявок',
  ADVERTISING_SITE = 'Реклама сайта',
  AD_TARGETING_COUNT = 'Аудитория объявления меньше 100 человек'
}
