<div class="affiliate-program-statistics">
  <h2 class="affiliate-program-statistics__title">Партнерская программа</h2>

  <mat-card class="affiliate-program-statistics__navigation">
    <mat-card-content>
      <app-affiliate-navigation></app-affiliate-navigation>
    </mat-card-content>
  </mat-card>

  <mat-card class="affiliate-program-statistics__content">
    <mat-card-content>
      <router-outlet></router-outlet>
    </mat-card-content>
  </mat-card>
</div>
