import {Component, OnInit} from '@angular/core';
import {UserBalanceQuery} from "../../../../../storage/user-balance/state/user-balance.query";

@Component({
  selector: 'app-to-pay-tile-content',
  templateUrl: './to-pay-tile-content.component.html',
  styleUrls: ['./to-pay-tile-content.component.scss']
})
export class ToPayTileContentComponent implements OnInit {

  public get amount$() {
    return this.userBalanceQuery.currentUserBalance$;
  }

  constructor(
    private userBalanceQuery: UserBalanceQuery
  ) {
  }

  ngOnInit() {
  }

}
