import {Component, OnInit} from '@angular/core';
import {AdManagerDataServiceService} from "../../../../ad-manager/services/ad-manager-data-service.service";
import {InterestCategoriesService} from "../../../services/interest-categories.service";
import {CopyingService} from "../../../services/copying.service";

@Component({
  selector: 'app-copying-by-interest-categories-list-checkboxes',
  templateUrl: './copying-by-interest-categories-list-checkboxes.component.html',
  styleUrls: ['./copying-by-interest-categories-list-checkboxes.component.scss']
})
export class CopyingByInterestCategoriesListCheckboxesComponent implements OnInit {

  constructor(
    private adDataManager: AdManagerDataServiceService,
    private interestCategoriesService: InterestCategoriesService,
    private copyingService: CopyingService
  ) {
  }

  public get interestCategories() {
    return this.interestCategoriesService.categoriesTree
  }

  ngOnInit() {
  }

  isTreeNodeOpened(category: any) {
    return this.interestCategoriesService.isCategoryOpened(category)
  }

  toggleTreeCategory(category: any) {
    console.log(this.interestCategoriesService.openedCategories, 'OPENED CATEGORIES');
    if (this.isTreeNodeOpened(category)) {
      this.interestCategoriesService.closeCategory(category);
    } else {
      this.interestCategoriesService.openCategory(category);
    }
  }

  isAllChildrenChecked(category: any) {
    return this.interestCategoriesService.isCategoryChildrensAllSelected(category);
  }

  isChildrenPartlyChecked(category: any) {
    return this.interestCategoriesService.isCategoryChildrensPartlySelected(category);
  }

  ToggleCategorySelect(category: any) {
    if (this.interestCategoriesService.isSelected(category)) {
      this.interestCategoriesService.removeSelected(category);
    } else {
      this.interestCategoriesService.selectCategory(category);
    }
    const interestIds = this.interestCategoriesService.getSelectedCategories()
    this.copyingService.form.patchValue({
      interestCategoriesCheckboxes: {
        interestIds
      }
    })
  }

  ToggleParentCategorySelect(category: any) {
    if (this.interestCategoriesService.isSelected(category)) {
      this.interestCategoriesService.removeParentSelected(category);
    } else {
      this.interestCategoriesService.selectParentCategory(category);
    }
    const interestIds = this.interestCategoriesService.getSelectedCategories();
    this.copyingService.form.patchValue({
      interestCategoriesCheckboxes: {
        interestIds
      }
    })
  }

  isSelected(category: any) {
    return this.interestCategoriesService.isSelected(category);
  }

  hasChildren(category: any) {
    return this.interestCategoriesService.hasChildren(category);
  }
}
