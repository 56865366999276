/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./new-create-ad.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./new-create-ad.component";
import * as i3 from "../../../../api/services/automation-vk.service";
import * as i4 from "../../../../api/services/automation-vk-slot.service";
var styles_NewCreateAdComponent = [i0.styles];
var RenderType_NewCreateAdComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NewCreateAdComponent, data: {} });
export { RenderType_NewCreateAdComponent as RenderType_NewCreateAdComponent };
export function View_NewCreateAdComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" new-create-ad works!\n"]))], null, null); }
export function View_NewCreateAdComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-new-create-ad", [], null, null, null, View_NewCreateAdComponent_0, RenderType_NewCreateAdComponent)), i1.ɵdid(1, 114688, null, 0, i2.NewCreateAdComponent, [i3.AutomationVkService, i4.AutomationVkSlotService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NewCreateAdComponentNgFactory = i1.ɵccf("app-new-create-ad", i2.NewCreateAdComponent, View_NewCreateAdComponent_Host_0, {}, {}, []);
export { NewCreateAdComponentNgFactory as NewCreateAdComponentNgFactory };
