import {PortalModule} from '@angular/cdk/portal';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {
  MatCheckboxModule,
  MatDialogModule,
  MatIconModule,
  MatProgressSpinnerModule,
  MatSelectModule
} from '@angular/material';
import {SharedModule} from '../../../shared/shared.module';
import {VkControlsModule} from '../../../vk-controls/vk-controls.module';
import {IntegrationsListComponent} from '../../routes/integrations-list/integrations-list.component';
import { IntegrationCreateEditFormComponent } from './components/integration-create-edit-form/integration-create-edit-form.component';
import {IntegrationFormNameComponent} from './components/integration-form-name/integration-form-name.component';
import {IntegrationServicesSelectorComponent} from './components/integration-services-selector/integration-services-selector.component';
import { VkLeadFormsComponent } from './components/services-integration-forms/vk-lead-forms/vk-lead-forms.component';
import { MtLeadFormsComponent } from './components/services-integration-forms/mt-lead-forms/mt-lead-forms.component';
import { AmoCrmContactsExportComponent } from './components/services-integration-forms/amo-crm-contacts-export/amo-crm-contacts-export.component';
import { AmoCrmContactsImportComponent } from './components/services-integration-forms/amo-crm-contacts-import/amo-crm-contacts-import.component';
import { VkRetargetingGroupsComponent } from './components/services-integration-forms/vk-retargeting-groups/vk-retargeting-groups.component';
import { MtRemarketingGroupsComponent } from './components/services-integration-forms/mt-remarketing-groups/mt-remarketing-groups.component';
import {ImportCrmSectionComponent} from "./components/import-crm-section/import-crm-section.component";
import {PopoverModule} from "../../../popover/popover.module";
import {NgxMatSelectSearchModule} from "ngx-mat-select-search";
import {CheckboxesGroupModule} from "../../../checkboxes-group/checkboxes-group.module";
import { UserIntegrationStatusComponent } from './components/components/user-integration-status/user-integration-status.component';
import {ClarityModule} from "@clr/angular";

@NgModule({
  declarations: [IntegrationCreateEditFormComponent, IntegrationFormNameComponent, IntegrationServicesSelectorComponent, VkLeadFormsComponent, MtLeadFormsComponent, AmoCrmContactsExportComponent, AmoCrmContactsImportComponent, VkRetargetingGroupsComponent, MtRemarketingGroupsComponent, ImportCrmSectionComponent, UserIntegrationStatusComponent],
  exports: [IntegrationCreateEditFormComponent, IntegrationFormNameComponent, IntegrationServicesSelectorComponent, UserIntegrationStatusComponent, ImportCrmSectionComponent, MtLeadFormsComponent],
  imports: [
    CommonModule,
    VkControlsModule,
    MatSelectModule,
    ReactiveFormsModule,
    SharedModule,
    MatIconModule,
    PortalModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    PopoverModule,
    FormsModule,
    NgxMatSelectSearchModule,
    MatDialogModule,
    CheckboxesGroupModule,
    ClarityModule
  ],
  entryComponents: [VkLeadFormsComponent, MtLeadFormsComponent, AmoCrmContactsExportComponent, AmoCrmContactsImportComponent, VkRetargetingGroupsComponent, MtRemarketingGroupsComponent, IntegrationCreateEditFormComponent]
})
export class IntegrationFormModule { }
