<!--<ng-container *ngIf="AdBlockEnabled">-->
<!--<div class="alert alert-danger adb-alert">-->
<!--<div class="alert-items">-->
<!--<div class="alert-item static">-->
<!--<div class="alert-icon-wrapper">-->
<!--<clr-icon class="alert-icon" shape="exclamation-circle"></clr-icon>-->
<!--</div>-->
<!--<span class="alert-text">-->
<!--Возможно, Вы используете расширение, блокирующее рекламу, например, AdBlock или подобное. Это может вызвать <br>-->
<!--некорректную работу сайта. Рекомендуем отключить расширение или добавить сайт postmonitor.ru в исключения.-->
<!--</span>-->
<!--</div>-->
<!--</div>-->
<!--</div>-->
<!--</ng-container>-->
<ng-container *ngIf="!cabinetsBinded; else cabinetsAreBinded">
  <ng-container *ngIf="!cabinetsChecked; else cabinetsAlreadyChecked">
    <h1>Проверка необходимых данных...</h1>
  </ng-container>
  <ng-template #cabinetsAlreadyChecked>
    <h1>
      Для продолжения работы привяжите рекламный кабинет ВКонтакте
    </h1>
    <div class="vk-container">
      <!--Смотрите всю статистику по рекламе и управляйте ставками в одном кабинете!<br>-->
      Привязав рекламный кабинет ВКонтакте Вам станет доступно:
    </div>
    <ul class="vk-container">
      <li>Быстрое создание объявлений;</li>
      <li>Управление ставками рекламных объявлений;</li>
      <li>Выгрузка всей статистики в Excel;</li>
      <li>Отображение всех параметров объявлений в одной строке, включая изображения, ссылки и UTM-метки.</li>
    </ul>
    <div class="vk-container">
      Подключение происходит через официальный доступ и <strong>абсолютно безопасно</strong>.
    </div>
    <div class="vk-container">
      <button class="vk-standart-button" (click)="AddVkAdCabinetLinkClick()" target="_blank" rel="noopener nofollow">
        Подключить рекламный кабинет ВКонтакте
      </button>
    </div>
  </ng-template>
</ng-container>
<ng-template #cabinetsAreBinded>
  <ng-container *ngIf="ShowHeaderCard() && !AdBlockEnabled">
    <div class="card card_no-top-margin">
      <div class="card-block">
        <div class="automation-wrapper-strip">
          <div class="automation-wrapper-strip__item">
            <app-breadcrumbs></app-breadcrumbs>
          </div>
          <div class="automation-wrapper-strip__item automation-wrapper-strip__item_right">
            <div class="alert">
              <div class="alert-items">
                <a href="https://postmonitor.ru/blog/opisanie-funkcionala-avtomatizacii-postmonitor" target="_blank"
                   rel="noopener nofollow">
                  Инструкция по работе с функционалом
                </a>
              </div>
            </div>
          </div>

<!--          <div class="automation-wrapper-strip__item automation-wrapper-strip__item" [style.width]="'170px'">-->
<!--            <a mat-raised-button color="primary" routerLink="/automation/new-ad-manager/pro/create" [style.width]="'170px'">Создать-->
<!--              объявление</a>-->
<!--          </div>-->
<!--          <div class="automation-wrapper-strip__item automation-wrapper-strip__item">-->
<!--            <a mat-raised-button color="primary" routerLink="/automation/ad-manager/create">Создать объявление</a>-->
<!--          </div>-->
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="accessManager.expirationErrorOccured; else notExpired">
    <app-expiration-notification [isShowed]="true" [title]="'Доступ истёк'">
      Для продолжения работы с функционалом перейдите к <a routerLink="/payments-create">выбору тарифного плана</a>
    </app-expiration-notification>
  </ng-container>
  <ng-template #notExpired>
    <!--<app-expiration-notification [isShowed]="accessManager.demoModeActive">-->
    <!--Вам доступен один рекламный кабинет на 3 дня. <br>-->
    <!--Для получения полного доступа перейдите к <a routerLink="/payments-create">выбору тарифного плана</a>-->
    <!--</app-expiration-notification>-->
  </ng-template>

  <ng-container *ngIf="AdBlockEnabled; else noAdBlocker">
    <app-new-style-modal [isVisible]="true" [headerBackgroundColor]="'#ff8a80'">
      <div class="new-modal-header-content">
        ВНИМАНИЕ!
      </div>
      <div class="new-modal-body-content">
        Вы используете расширение, блокирующее рекламу, например, AdBlock или подобное.
        Это может вызвать некорректную работу сайта.<br>
        <br>
        Для продолжения работы отключите, пожалуйста, расширение или добавьте сайт ng.postmonitor.ru в исключения.<br>
        После того, как вы это сделаете обновите, пожалуйста, страницу.<br>
        <br>
        Если вы не знаете как это сделать, то нажмите на кнопку "Инструкция".
      </div>
      <div class="new-modal-buttons">
        <a class="vk-standart-button" href="https://postmonitor.ru/blog/instrukciya-kak-dobavit-postmonitor-v-isklyucheniya-adblock" target="_blank" rel="noopener nofollow">
          Инструкция
        </a>
      </div>
    </app-new-style-modal>
  </ng-container>
  <ng-template #noAdBlocker>
    <router-outlet></router-outlet>
  </ng-template>
</ng-template>
