import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {isNullOrUndefined} from 'util';
import {Counter} from '../../../api/models/counter';
import {Goal} from '../../../api/models/goal';
import {environment} from '../../../../environments/environment';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-analytic-services-header',
  templateUrl: './analytic-services-header.component.html',
  styleUrls: ['./analytic-services-header.component.scss']
})
export class AnalyticServicesHeaderComponent implements OnInit {
  @Input() public provider: string = null;

  @Input() public yandexMetrikaConnected = false;

  @Input() public availabeCounter: Array<Counter> = null;
  @Input() public availabeGoals: Array<Goal> = null;

  @Input() public yandexSelectedCounterId: number = null;
  @Input() public yandexSelectedGoalId: number = null;

  @Output() public SelectCounter = new EventEmitter<number>();
  @Output() public SelectGoal = new EventEmitter<number>();

  public baseUrl = environment.frontEndUrl;

  constructor(
      private route: Router
  ) { }

  ngOnInit() {
      
  }

  public IsSelectorDisabled(isAvailable: any): boolean {
    return isNullOrUndefined(isAvailable);
  }

  public SelectCounterEvent(): void {
    this.SelectCounter.emit(this.yandexSelectedCounterId);
  }

  public SelectGoalEvent(): void {
    this.SelectGoal.emit(this.yandexSelectedGoalId);
  }

  public GetBindUrl(): string {
    return `${this.baseUrl}/apiv2/OAuth/authorize/${this.provider}`;
  }

  public BindUri($event) {
    $event.preventDefault();
    window.localStorage.setItem('yandex_metrika_url', window.location.href);
    window.location.assign($event.target.href);
  }
}
