/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./user-call.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/menu/typings/index.ngfactory";
import * as i3 from "@angular/material/menu";
import * as i4 from "@angular/common";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "../../../../shared/pipes/moment-date.pipe";
import * as i7 from "../../../../shared/pipes/utc-to-local-date.pipe";
import * as i8 from "@angular/cdk/overlay";
import * as i9 from "@angular/cdk/bidi";
import * as i10 from "./user-call.component";
import * as i11 from "../../../../api/services/calls-controller-1.service";
var styles_UserCallComponent = [i0.styles];
var RenderType_UserCallComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UserCallComponent, data: {} });
export { RenderType_UserCallComponent as RenderType_UserCallComponent };
function View_UserCallComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "button", [["class", "mat-menu-item"], ["mat-menu-item", ""]], [[1, "role", 0], [2, "mat-menu-item-highlighted", null], [2, "mat-menu-item-submenu-trigger", null], [1, "tabindex", 0], [1, "aria-disabled", 0], [1, "disabled", 0]], [[null, "click"], [null, "mouseenter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2)._checkDisabled($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2)._handleMouseEnter() !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (_co.ChangeCallStatus(_v.context.$implicit.id) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_MatMenuItem_0, i2.RenderType_MatMenuItem)), i1.ɵdid(2, 180224, [[1, 4]], 0, i3.MatMenuItem, [i1.ElementRef, i4.DOCUMENT, i5.FocusMonitor, [2, i3.ɵf24]], null, null), (_l()(), i1.ɵted(3, 0, ["", ""]))], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).role; var currVal_1 = i1.ɵnov(_v, 2)._highlighted; var currVal_2 = i1.ɵnov(_v, 2)._triggersSubmenu; var currVal_3 = i1.ɵnov(_v, 2)._getTabIndex(); var currVal_4 = i1.ɵnov(_v, 2).disabled.toString(); var currVal_5 = (i1.ɵnov(_v, 2).disabled || null); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = _v.context.$implicit.title; _ck(_v, 3, 0, currVal_6); }); }
export function View_UserCallComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i6.MomentDatePipe, []), i1.ɵpid(0, i7.UtcToLocalDatePipe, []), (_l()(), i1.ɵeld(2, 0, null, null, 18, "div", [["class", "user-call"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "user-call__date"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵppd(5, 1), i1.ɵppd(6, 2), (_l()(), i1.ɵeld(7, 0, null, null, 11, "div", [["class", "user-call__status"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 16777216, null, null, 2, "a", [["aria-haspopup", "true"]], [[1, "aria-expanded", 0]], [[null, "mousedown"], [null, "keydown"], [null, "click"]], function (_v, en, $event) { var ad = true; if (("mousedown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9)._handleMousedown($event) !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 9)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (i1.ɵnov(_v, 9)._handleClick($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 1196032, null, 0, i3.MatMenuTrigger, [i8.Overlay, i1.ElementRef, i1.ViewContainerRef, i3.MAT_MENU_SCROLL_STRATEGY, [2, i3.MatMenu], [8, null], [2, i9.Directionality], i5.FocusMonitor], { menu: [0, "menu"] }, null), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵeld(11, 0, null, null, 7, "mat-menu", [], null, null, null, i2.View_MatMenu_0, i2.RenderType_MatMenu)), i1.ɵprd(6144, null, i3.ɵf24, null, [i3.MatMenu]), i1.ɵdid(13, 1294336, [["statusMenu", 4]], 2, i3.MatMenu, [i1.ElementRef, i1.NgZone, i3.MAT_MENU_DEFAULT_OPTIONS], null, null), i1.ɵqud(603979776, 1, { items: 1 }), i1.ɵqud(335544320, 2, { lazyContent: 0 }), (_l()(), i1.ɵand(16777216, null, 0, 2, null, View_UserCallComponent_1)), i1.ɵdid(17, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(19, 0, null, null, 1, "div", [["class", "user-call__comment"]], null, null, null, null, null)), (_l()(), i1.ɵted(20, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵnov(_v, 13); _ck(_v, 9, 0, currVal_2); _ck(_v, 13, 0); var currVal_4 = i1.ɵunv(_v, 17, 0, i1.ɵnov(_v, 18).transform(_co.callStatuses)); _ck(_v, 17, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 4, 0, _ck(_v, 6, 0, i1.ɵnov(_v, 0), i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v, 1), _co.userCall.callDate)), "DD.MM.YYYY [\u0432] HH:mm")); _ck(_v, 4, 0, currVal_0); var currVal_1 = (i1.ɵnov(_v, 9).menuOpen || null); _ck(_v, 8, 0, currVal_1); var currVal_3 = ((_co.userCall == null) ? null : ((_co.userCall.callStatus == null) ? null : _co.userCall.callStatus.title)); _ck(_v, 10, 0, currVal_3); var currVal_5 = _co.userCall.comment; _ck(_v, 20, 0, currVal_5); }); }
export function View_UserCallComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-user-call", [], null, null, null, View_UserCallComponent_0, RenderType_UserCallComponent)), i1.ɵdid(1, 114688, null, 0, i10.UserCallComponent, [i11.CallsController1Service], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UserCallComponentNgFactory = i1.ɵccf("app-user-call", i10.UserCallComponent, View_UserCallComponent_Host_0, { userCall: "userCall", callStatuses: "callStatuses" }, { callStatusChanged: "callStatusChanged" }, []);
export { UserCallComponentNgFactory as UserCallComponentNgFactory };
