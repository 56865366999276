import {
  Component,
  ContentChild,
  ElementRef,
  forwardRef,
  Input,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {PopoverMenuComponent} from "../../../../../popover/components/popover-menu/popover-menu.component";
import {LinkQueryParamTemplateDirective} from "../../../directives/link-query-param-template.directive";

@Component({
  selector: 'app-link-query-param-input',
  templateUrl: './link-query-param-input.component.html',
  styleUrls: ['./link-query-param-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LinkQueryParamInputComponent),
      multi: true
    }
  ]
})
export class LinkQueryParamInputComponent implements OnInit, ControlValueAccessor {

  @Input() public items = [];
  @ViewChild('textField') public input: ElementRef;

  @ContentChild(LinkQueryParamTemplateDirective) public queryParamTemplates: LinkQueryParamTemplateDirective;

  public selectedItemTemplate;

  @ViewChild(PopoverMenuComponent) popover: PopoverMenuComponent;

  public value: string;
  public selectedItem = {}
  public onChange;
  public onTouch;

  public get ShowTextField(): any {
    return !this.value.includes('{{');
  }

  constructor() {
  }

  ngOnInit() {
    console.log(this.popover, 'POPOVER  ');
    console.log(this.queryParamTemplates, 'QUERY PARAMS');
    if (this.queryParamTemplates) {
      this.selectedItemTemplate = this.queryParamTemplates.template;
    }
    // if (this.queryParamTemplates && this.queryParamTemplates.changes) {
    //   this.queryParamTemplates.changes.subscribe(x => {
    //     console.log(x, 'PARAMS');
    //     this.selectedItemTemplate = x;
    //   })
    // }
  }

  SelectItem(item: any) {
    this.value = item.value;
    this.selectedItem = item;
    this.emitChange();
    this.popover.close();
  }

  insertTextAtCursor(el, text, offset) {
    var val = el.value, endIndex, range, doc = el.ownerDocument;
    if (typeof el.selectionStart == "number"
      && typeof el.selectionEnd == "number") {
      endIndex = el.selectionEnd;
      el.value = val.slice(0, endIndex) + text + val.slice(endIndex);
      el.selectionStart = el.selectionEnd = endIndex + text.length + (offset ? offset : 0);
    } else if (doc.selection != "undefined" && doc.selection.createRange) {
      el.focus();
      range = doc.selection.createRange();
      range.collapse(false);
      range.text = text;
      range.select();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  emitChange() {
    this.onChange(this.value);
  }

  ClearField() {
    this.value = '';
  }
}
