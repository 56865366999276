/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./vk-lead-forms-settings.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../ad-network-group/ad-network-group.component.ngfactory";
import * as i3 from "../ad-network-group/ad-network-group.component";
import * as i4 from "../../../../stores/ad-network-groups/ad-network-groups.query";
import * as i5 from "../../../../stores/ad-network-forms/ad-network-forms.query";
import * as i6 from "../../../../stores/ad-network-groups/ad-network-groups.service";
import * as i7 from "../../../../stores/ad-network-forms/ad-network-forms.service";
import * as i8 from "../../../../stores/platforms/platforms.query";
import * as i9 from "@angular/common";
import * as i10 from "../../../../../shared/components/loading-screen/loading-screen.component.ngfactory";
import * as i11 from "../../../../../shared/components/loading-screen/loading-screen.component";
import * as i12 from "./vk-lead-forms-settings.component";
import * as i13 from "@angular/forms";
import * as i14 from "@datorama/akita-ng-forms-manager";
import * as i15 from "../../../../stores/platform-assignations/platform-assignations.query";
var styles_VkLeadFormsSettingsComponent = [i0.styles];
var RenderType_VkLeadFormsSettingsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_VkLeadFormsSettingsComponent, data: {} });
export { RenderType_VkLeadFormsSettingsComponent as RenderType_VkLeadFormsSettingsComponent };
function View_VkLeadFormsSettingsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-ad-network-group", [], null, null, null, i2.View_AdNetworkGroupComponent_0, i2.RenderType_AdNetworkGroupComponent)), i1.ɵdid(2, 245760, null, 0, i3.AdNetworkGroupComponent, [i4.AdNetworkGroupsQuery, i5.AdNetworkFormsQuery, i6.AdNetworkGroupsService, i7.AdNetworkFormsService, i8.PlatformsQuery], { group: [0, "group"], control: [1, "control"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.form; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_VkLeadFormsSettingsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "ad-network-groups__list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_VkLeadFormsSettingsComponent_3)), i1.ɵdid(3, 278528, null, 0, i9.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i9.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(_co.groups$)); _ck(_v, 3, 0, currVal_0); }, null); }
function View_VkLeadFormsSettingsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-loading-screen", [], null, null, null, i10.View_LoadingScreenComponent_0, i10.RenderType_LoadingScreenComponent)), i1.ɵdid(1, 114688, null, 0, i11.LoadingScreenComponent, [], null, null), (_l()(), i1.ɵted(-1, 0, [" \u041F\u043E\u0434\u043E\u0436\u0434\u0438\u0442\u0435, \u0438\u0434\u0435\u0442 \u043F\u043E\u0438\u0441\u043A \u0433\u0440\u0443\u043F\u043F "]))], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_VkLeadFormsSettingsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_VkLeadFormsSettingsComponent_2)), i1.ɵdid(2, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i1.ɵpid(131072, i9.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, [["loader", 2]], null, 0, null, View_VkLeadFormsSettingsComponent_4))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.isLoading$)); var currVal_1 = i1.ɵnov(_v, 4); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_VkLeadFormsSettingsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "empty-screen"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u0413\u0440\u0443\u043F\u043F\u044B \u043D\u0435 \u043D\u0430\u0439\u0434\u0435\u043D\u044B "]))], null, null); }
export function View_VkLeadFormsSettingsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "ad-network-groups"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_VkLeadFormsSettingsComponent_1)), i1.ɵdid(2, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i1.ɵpid(131072, i9.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, [["emptyScreen", 2]], null, 0, null, View_VkLeadFormsSettingsComponent_5))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.isEmpty$)); var currVal_1 = i1.ɵnov(_v, 4); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_VkLeadFormsSettingsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-vk-lead-forms-settings", [], null, null, null, View_VkLeadFormsSettingsComponent_0, RenderType_VkLeadFormsSettingsComponent)), i1.ɵdid(1, 14401536, null, 0, i12.VkLeadFormsSettingsComponent, [i6.AdNetworkGroupsService, i4.AdNetworkGroupsQuery, i13.FormBuilder, i14.AkitaNgFormsManager, i1.ChangeDetectorRef, i15.PlatformAssignationsQuery], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var VkLeadFormsSettingsComponentNgFactory = i1.ɵccf("app-vk-lead-forms-settings", i12.VkLeadFormsSettingsComponent, View_VkLeadFormsSettingsComponent_Host_0, { type: "type", platform: "platform" }, {}, []);
export { VkLeadFormsSettingsComponentNgFactory as VkLeadFormsSettingsComponentNgFactory };
