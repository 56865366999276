import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {MatVerticalStepper} from '@angular/material';
import {AdFormManagerService} from '../../../new-ad-manager-shared/services/ad-form-manager/ad-form-manager.service';
import {flatMap} from "rxjs/operators";
import {StepperSelectionEvent} from "@angular/cdk/stepper";
import {LoggingService} from "../../../../../shared/services/logging.service";

@Component({
  selector: 'app-new-ad-form-lite',
  templateUrl: './new-ad-form-lite.component.html',
  styleUrls: ['./new-ad-form-lite.component.scss']
})
export class NewAdFormLiteComponent implements OnInit {

  @ViewChild(MatVerticalStepper) stepper: MatVerticalStepper;

  @Output() public adsSaved: EventEmitter<any> = new EventEmitter();
  public checkingAccount: boolean = false;

  public get ShowActiveAccounts() {
    return this.adFormManagerService.ShowActiveAccounts();
  }

  public get form() {
    return this.adFormManagerService.adForm;
  }

  public get IsPromopost() {
    return this.adFormManagerService.IsSelectFormatPromopost;
  }

  public get IsUpdate() {
    return this.adFormManagerService.IsUpdate;
  }

  public get createAdLoading() {
    return this.adFormManagerService.loadingCreateAd;
  }

  public get IsAdValid() {
    return this.adFormManagerService.IsValidaAd();
  }

  public get IsValidTargeting() {
    return this.adFormManagerService.IsValidTargeting();
  }

  public get errors() {
    return this.adFormManagerService.getErrors();
  }

  public get HasAssignedAccounts() {
    return this.adFormManagerService.HasAssignedAccounts()
  }

  public get IsAssignedCabinetProcessLoading() {
    return this.adFormManagerService.assignAccountLoadingLite;
  }

  constructor(
    private adFormManagerService: AdFormManagerService,
    private logger: LoggingService
  ) {
  }

  ngOnInit() {
    this.stepper.selectionChange.subscribe((selectionState: StepperSelectionEvent) => {
      const countOfSteps = this.stepper.steps.length;
      const selectedStepIndex = selectionState.selectedIndex + 1;
      if (countOfSteps === selectedStepIndex) {
        this.checkForExistingSystemCampaignsAndCreateIfNotExists(true);

        // if (!this.adFormManagerService.IsValidCampaignData()) {
        //
        // }
      }
    })
  }

  public createAd() {
    const specifications = this.adFormManagerService.getSpecifications();
    this.adsSaved.emit(specifications);
  }

  public checkForExistingSystemCampaignsAndCreateIfNotExists(check: boolean = true) {
    this.adFormManagerService.assignAccountLoadingLite = true;
    const {accountId} = this.adFormManagerService.getAccountData();
    console.log('CONSOLE LOG CHECKING CAMPAIGN SYSTEM', accountId);
    if (check) {
      this.adFormManagerService.LoadAdCabinetData()
        .pipe(
          flatMap(() => this.adFormManagerService.checkForSystemCampaignsAndCreateIfNotExitis()),
          flatMap(() => this.adFormManagerService.LoadTargetingStats())
        )
        .subscribe(() => {
          console.log('subscribe');
          this.checkingAccount = false;
          if (!this.adFormManagerService.IsValidCampaignData()) {
            this.logger.AddExtra('campaign', this.adFormManagerService.campaigns);
            this.logger.AddExtra('account', this.adFormManagerService.accounts);
            this.logger.Error('Не удалось загрузить кампании');
          }
          this.adFormManagerService.assignAccountLoadingLite = false;
        });
    } else {
      console.log('STEPPER NEXT');
      this.stepper.next();
    }
  }

  public checkForExistingSystemCampaignsAndCreateIfNotExistsAndContainsAccounts() {
    console.log(this.ShowActiveAccounts);
    if (this.ShowActiveAccounts) {
      this.checkForExistingSystemCampaignsAndCreateIfNotExists();
    } else {
      console.log('STEPPER NEXT FUCK');
      this.stepper.next();
    }
  }
}
