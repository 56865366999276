import {Injectable} from '@angular/core';
import {VkApiPost} from '../models/api/vk-api-post';

@Injectable()
export class VkAdDetectorService {

  private adsTermRegex = new RegExp('(купи[а-я]{0,2})|(скидк[а-я]{1,3})|((спец)?предложение)|(акци(я|ю))|(зака[зж][а-я]{0,4})|(покуп[а-я]{2,4})|(цен[аеы])|(сумм[уаы])|(успе(й|ть|ете|ешь|л))|(рубл(ь|я|ей))|(товар)|(подар)|(получ[еи])|((за)?регистр(ир|ац))|((с)?кача(й|ть|вай))|(клик(н|а))|(работ)|(выгод)|(бесплатн)|(присоедин)|(приз)|(конкурс)|(рекоменд)|(подпис)|(прибыл)|(присоедин)|(прода(м|ж|ю|[её]|м))|(магаз)|(розыгр)|(репост)|(сда(м|ю|[её]))|(дар(ю|им|ите))|((по|про)смотр)|(популяр)|(новинк)|(дешев(о|ле|ых|ые|ый))|(эконом)');
  private urlDetector = new RegExp('(www[.])?([a-zа-я0-9-_.]+)([.]([a-z]{2,}|(рф|укр|бел)))([/][a-zа-я0-9-_/?&=.%#~+]+)?');

  constructor() {
  }

  public IsPostAnAd(post: VkApiPost, useCustomAdCriteria: boolean = true, forceAd: boolean = false): boolean {
    if (!post) {
      return false;
    }

    if (forceAd) {
      return true;
    }

    if (post.marked_as_ads === 1 || post.post_type === 'post_ads') {
      return true;
    }

    if (useCustomAdCriteria) {
      let containLink = false;
      let containPhoto = false;
      let containVideo = false;

      if (post.attachments && post.attachments.length > 0) {
        for (let i = 0; i < post.attachments.length && !(containLink && (containPhoto || containVideo)); i = i + 1) {
          if (post.attachments[i].link) {
            containLink = true;
          }

          if (post.attachments[i].photo) {
            containPhoto = true;
          }

          if (post.attachments[i].video) {
            containVideo = true;
          }
        }

        if (!containLink) {
          containLink = this.TextContainUrl(post.text);
        }
      }

      if ((containPhoto || containVideo) && containLink) {
        if (post.text != null && this.adsTermRegex.test(post.text)) {
          return true;
        }

        if (post.copy_history && post.copy_history.length > 0) {
          post.copy_history.forEach(repost => {
            if (repost.text && this.adsTermRegex.test(repost.text)) {
              return true;
            }
          });
        }
      }
    }

    return false;
  }

  private TextContainUrl(text: string): boolean {
    return this.urlDetector.test(text);
  }
}
