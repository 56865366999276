import { Component, OnInit } from '@angular/core';
import {CopyingService} from "../../../services/copying.service";

@Component({
  selector: 'app-copying-by-links',
  templateUrl: './copying-by-links.component.html',
  styleUrls: ['./copying-by-links.component.scss']
})
export class CopyingByLinksComponent implements OnInit {

  public get form() {
    return this.copyingService.form.get('linkSettings')
  }

  constructor(
    private copyingService: CopyingService
  ) { }

  ngOnInit() {
    console.log(this.form, 'LINK FORMS');
    console.log(this.copyingService.form, 'LINK FORMS');
    this.form.valueChanges.subscribe(data => {
      console.log(data);
    })
  }

}
