import { OnInit } from '@angular/core';
import { ControlContainer } from '@angular/forms';
var TeaserFormLiteComponent = /** @class */ (function () {
    function TeaserFormLiteComponent(controlContainer) {
        this.controlContainer = controlContainer;
        this.isUpdating = false;
        this.labelWidth = '200px';
        this.controlWidth = '256px';
    }
    Object.defineProperty(TeaserFormLiteComponent.prototype, "ShowGroupControls", {
        get: function () {
            return this.adTargetResource === 'vk_group';
        },
        enumerable: true,
        configurable: true
    });
    TeaserFormLiteComponent.prototype.ngOnInit = function () {
        console.log(this.controlContainer);
    };
    TeaserFormLiteComponent.prototype.SetTeaserImage = function (imageContent) {
        this.controlContainer.control.get('image').setValue(imageContent);
    };
    TeaserFormLiteComponent.prototype.StartImageErrorTimer = function () {
        var _this = this;
        setTimeout(function () {
            var imageControl = _this.controlContainer.control.get('image');
            imageControl.markAsDirty();
        }, 10000);
    };
    TeaserFormLiteComponent.prototype.CountOfOpenedGroups = function () {
        var countOfOpenedGroups = 0;
        if (this.groups && this.groups.length > 0) {
            var openedGroups = this.groups.filter(function (group) { return group.is_closed === 0; });
            countOfOpenedGroups = openedGroups.length;
        }
        return countOfOpenedGroups;
    };
    return TeaserFormLiteComponent;
}());
export { TeaserFormLiteComponent };
