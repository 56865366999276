/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dg-pagination.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./dg-pagination.component";
var styles_DgPaginationComponent = [i0.styles];
var RenderType_DgPaginationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DgPaginationComponent, data: {} });
export { RenderType_DgPaginationComponent as RenderType_DgPaginationComponent };
function View_DgPaginationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "ngr-pagination__item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.SetPage(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "ngr-pagination__item_active": 0 }), (_l()(), i1.ɵted(3, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "ngr-pagination__item"; var currVal_1 = _ck(_v, 2, 0, _co.IsPageActive(_v.context.$implicit)); _ck(_v, 1, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit; _ck(_v, 3, 0, currVal_2); }); }
export function View_DgPaginationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "ngr-pagination"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DgPaginationComponent_1)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.pages; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_DgPaginationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ngr-dg-pagination", [], null, null, null, View_DgPaginationComponent_0, RenderType_DgPaginationComponent)), i1.ɵdid(1, 114688, null, 0, i3.DgPaginationComponent, [i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DgPaginationComponentNgFactory = i1.ɵccf("ngr-dg-pagination", i3.DgPaginationComponent, View_DgPaginationComponent_Host_0, { countOfPages: "countOfPages", currentPage: "currentPage", maxShowedPages: "maxShowedPages" }, { currentPageChange: "currentPageChange" }, []);
export { DgPaginationComponentNgFactory as DgPaginationComponentNgFactory };
