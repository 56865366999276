<form [formGroup]="controlContainer.control" class="createPromopostForm">

  <!--<div class="select">-->
  <!--<select formControlName="groupId">-->
  <!--<option [value]="group.id" *ngFor="let group of adminGroups$ | async">-->
  <!--{{group.name}}-->
  <!--</option>-->
  <!--</select>-->
  <!--</div>-->
  <a [routerLink]="'/vk/hidden'" target="_blank" rel="nofollow noopener" style="text-align:right; margin-bottom: 8px;">Посмотреть примеры объявлений</a>
<!--  <div class="vk-container" *ngIf="categories">-->
<!--    <div class="vk-strip-wrapper vk-strip-wrapper_ad-cabinet-style vk-strip-wrapper_top">-->
<!--      <div class="vk-label">-->
<!--        Выберите тематику объявления:-->
<!--      </div>-->
<!--      <div class="vk-strip-wrapper__item vk-strip-wrapper__item_ad-cabinet-style">-->
<!--        <select formControlName="categoryId" [disabled]="true" class="vk-select vk-select_full-width">-->
<!--          <ng-container *ngFor="let category of categories">-->
<!--            <option [value]="category.id">-->
<!--              {{category.name}}-->
<!--            </option>-->
<!--          </ng-container>-->
<!--        </select>-->
<!--        <div *ngIf="errors.groupId && controlContainer.control.get('groupId').dirty">-->
<!--          <div *ngFor="let error of errors.groupId" class="vk-error">{{error}}</div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->

  <div class="vk-container">
    <div class="vk-strip-wrapper vk-strip-wrapper_ad-cabinet-style vk-strip-wrapper_top" *ngIf="!isUpdating">
      <div class="vk-label">
        Выберите сообщество:
      </div>
      <div class="vk-strip-wrapper__item vk-strip-wrapper__item_ad-cabinet-style">
        <select formControlName="groupId" [disabled]="true" class="vk-select vk-select_full-width" (onSelectionChange)="SelectPromostGroup($event)">
          <ng-container *ngFor="let group of userGroups">
            <option [value]="group.id">
              {{group.name}}
            </option>
            <!--<ng-container *ngIf="group.is_closed === 0; else closedGroupTemplate">-->
              <!--<option [value]="group.id">-->
                <!--{{group.name}}-->
              <!--</option>-->
            <!--</ng-container>-->
            <!--<ng-template #closedGroupTemplate>-->
              <!--<option [value]="group.id" disabled title="Данная группа не является открытой. Публикация рекламы во Вонтакте возможна только в открытых группах">-->
                <!--{{group.name}}-->
              <!--</option>-->
            <!--</ng-template>-->
          </ng-container>
        </select>
        <div *ngIf="errors.groupId && controlContainer.control.get('groupId').dirty">
          <div *ngFor="let error of errors.groupId" class="vk-error">{{error}}</div>
        </div>
      </div>
    </div>
  </div>

  <div class="vk-container">
    <div class="vk-strip-wrapper vk-strip-wrapper_ad-cabinet-style vk-strip-wrapper_top">
      <label for="create-link-title" class="vk-label">
        <div>
          Введите заголовок ссылки:
        </div>
      </label>

    <div class="vk-strip-wrapper__item vk-strip-wrapper__item_ad-cabinet-style">
      <input id="create-link-title" type="text" formControlName="linkTitle" class="vk-input vk-input_full-width"
             placeholder="Введите заголовок ссылки" minlength="1" required>
      <div *ngIf="errors.linkTitle && controlContainer.control.get('linkTitle').dirty">
        <div *ngFor="let error of errors.linkTitle" class="vk-error">{{error}}</div>
      </div>
    </div>
  </div>
  </div>

  <div class="vk-container">
    <div class="vk-strip-wrapper vk-strip-wrapper_ad-cabinet-style vk-strip-wrapper_top">
      <label for="create-link-url" class="vk-label">
        Введите ссылку на сайт или группу:
      </label>

      <div class="vk-strip-wrapper__item vk-strip-wrapper__item_ad-cabinet-style">
        <input id="create-link-url" type="text" formControlName="linkAddress" class="vk-input vk-input_full-width" placeholder="Введите ссылку" minlength="1" required>
        <div *ngIf="errors.linkAddress && controlContainer.control.get('linkAddress').dirty">
          <div *ngFor="let error of errors.linkAddress" class="vk-error">{{error}}</div>
        </div>
      </div>
    </div>
  </div>

  <div formArrayName="text">
    <ng-container *ngFor="let control of PromopostTexts; let i = index;">
      <div class="vk-container">
        <div class="vk-strip-wrapper vk-strip-wrapper_ad-cabinet-style vk-strip-wrapper_top">
          <ng-container *ngIf="i === 0">
            <label class="vk-label" style="max-width: 200px; text-align: right;">
              <div>
                Текст объявления:
              </div>
              <div class="label-alert" [innerHTML]="getEmojjiContent()">

              </div>
            </label>
          </ng-container>

          <div class="vk-strip-wrapper__item vk-strip-wrapper__item_ad-cabinet-style">
            <div class="vk-strip-wrapper">
              <div class="vk-strip-wrapper__item vk-strip-wrapper__item_wide">
                <textarea
                  class="vk-textarea vk-textarea_full-width vk-textarea_extended-height"
                  (input)="SaveText($event, i)"
                  placeholder="Введите текст объявления..."
                  [innerHTML]="controlContainer.control.get('text').controls[i].value"
                ></textarea>
              </div>

              <!--<div class="vk-strip-wrapper__item">-->
                <!--<button class="create-ad-icons" (click)="addControl();">-->
                  <!--<mat-icon>add</mat-icon>-->
                <!--</button>-->
              <!--</div>-->

              <!--<div class="vk-strip-wrapper__item">-->
                <!--<button class="create-ad-icons create-ad-icons_negative" (click)="removeControl(i);">-->
                  <!--<mat-icon>close</mat-icon>-->
                <!--</button>-->
              <!--</div>-->
            </div>

            <div *ngIf="errors.text && controlContainer.control.get('text').controls[i].dirty">
              <div *ngFor="let error of errors.text[i]" class="vk-error">{{error}}</div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="vk-container">
    <div class="vk-strip-wrapper vk-strip-wrapper_ad-cabinet-style vk-strip-wrapper_top">
      <div class="vk-label">Название объявления</div>
      <div class="vk-strip-wrapper__item vk-strip-wrapper__item_ad-cabinet-style">
        <div class="vk-strip-wrapper">
          <div class="vk-strip-wrapper__item vk-strip-wrapper__item_wide">
            <input type="text" formControlName="name" class="vk-input vk-input_full-width">
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--<div formArrayName="text">-->
  <!--<div *ngFor="let control of controlContainer.control.get('text').controls; let i = index;">-->
  <!--<div fxLayout="row" class="text-field">-->
  <!--<textarea id="promopost-text" [formControlName]="i" (keyup)="SaveText($event, i)">{{controlContainer.control.get('text').controls[i].value}}</textarea>-->
  <!--<button mat-icon-button (click)="addControl();">-->
  <!--<mat-icon>add</mat-icon>-->
  <!--</button>-->
  <!--<button mat-icon-button (click)="removeControl(i);">-->
  <!--<mat-icon>close</mat-icon>-->
  <!--</button>-->
  <!--</div>-->
  <!--<div *ngIf="errors.text && controlContainer.control.get('text').controls[i].dirty">-->
  <!--<div *ngFor="let error of errors.text[i]">{{error}}</div>-->
  <!--</div>-->
  <!--</div>-->
  <!--</div>-->
  <!--<app-images-manager (selected)="ImageSelected($event)"></app-images-manager>-->
</form>

<!--<app-images-manager></app-images-manager>-->
