import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-new-style-modal',
  templateUrl: './new-style-modal.component.html',
  styleUrls: ['./new-style-modal.component.scss']
})
export class NewStyleModalComponent implements OnInit {
  @Input() public headerBackgroundColor: string = null;
  @Input() public isVisible: boolean = false;
  @Input() public showCloseButton: boolean = false;

  @Output() public onCloseButtonClick: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
  }

}
