import { Observable } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import * as i0 from "@angular/core";
var minForms = {
    min: 0,
    max: 7
};
var MockService = /** @class */ (function () {
    function MockService() {
    }
    MockService.prototype.LoadServices = function () {
        return of([
            {
                name: 'Формы заявок ВКонтакте',
                code: 'VK_LEAD_FORMS',
                leftStyle: '',
                rightStyle: '',
                icon: '',
                left: true,
                namePart: 'Интеграция форм заявок ВКонтакте'
            },
            {
                name: 'Формы заявок MyTarget',
                code: 'MT_LEAD_FORMS',
                leftStyle: '',
                rightStyle: '',
                icon: '',
                left: true,
                namePart: 'Интеграция форм заявок MyTarget'
            },
            {
                name: 'Контакты AmoCrm (Экспорт)',
                code: 'AMO_CRM_CONTACTS_EXPORT',
                leftStyle: '',
                rightStyle: '',
                icon: '',
                left: true,
                namePart: 'Интеграция каонтактов AmoCrm'
            },
            {
                name: 'Группы ретаргетинга ВК',
                code: 'VK_RETARGETING_GROUPS',
                leftStyle: '',
                rightStyle: '',
                icon: '',
                left: false,
                namePart: 'с группами ретаргетинга ВКонтакте'
            },
            {
                name: 'Группы ремаркетинга MyTarget',
                code: 'MT_REMARKETING_GROUPS',
                leftStyle: '',
                rightStyle: '',
                icon: '',
                left: false,
                namePart: 'с группами ремаркетинга MyTarget'
            },
            {
                name: 'Контакты AMO Crm (Импорт)',
                code: 'AMO_CRM_CONTACTS_IMPORT',
                leftStyle: '',
                rightStyle: '',
                icon: '',
                left: false,
                namePart: 'с сервисом AmoCrm'
            }
        ]);
    };
    MockService.prototype.LoadServicesRelations = function () {
        return of([
            {
                service1: 'VK_LEAD_FORMS',
                service2: 'AMO_CRM_CONTACTS_IMPORT',
                isTwoWay: true
            },
            {
                service1: 'MT_LEAD_FORMS',
                service2: 'AMO_CRM_CONTACTS_IMPORT',
                isTwoWay: true
            },
            {
                service1: 'AMO_CRM_CONTACTS_EXPORT',
                service2: 'VK_RETARGETING_GROUPS',
                isTwoWay: true
            },
            {
                service1: 'AMO_CRM_CONTACTS_EXPORT',
                service2: 'MT_REMARKETING_GROUPS',
                isTwoWay: true
            },
            {
                service1: 'AMO_CRM_CONTACTS_IMPORT',
                service2: 'MT_REMARKETING_GROUPS',
                isTwoWay: true
            }
        ]);
    };
    MockService.prototype.LoadForms = function (groupId) {
        var countOfForms = 3;
        var groups = [];
        for (var i = 0; i < countOfForms; i++) {
            groups.push({
                name: "Faker Form " + i,
                formId: 0,
            });
        }
        console.log(groups, 'GROUPS');
        return Observable.create(function (observer) {
            observer.next(groups);
            observer.complete();
        });
    };
    MockService.prototype.LoadMtAccounts = function () {
        return Observable.create(function (observer) {
            var accounts = [
                {
                    id: 1,
                    name: 'Личный кабинет'
                },
                {
                    id: 2,
                    name: 'Лымарев Алексей'
                },
                {
                    id: 3,
                    name: 'Иванов Иван Иванович'
                },
                {
                    id: 4,
                    name: 'Личный кабинет 1'
                },
                {
                    id: 5,
                    name: 'Личный кабинет 2'
                },
            ];
            observer.next(accounts);
            observer.complete();
        });
    };
    MockService.prototype.LoadMtCampaigns = function () {
        var campaigns = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(function (x) { return ({
            id: x,
            name: "\u041A\u0430\u043C\u043F\u0430\u043D\u0438\u044F " + x
        }); });
        return Observable.create(function (observer) {
            observer.next(campaigns);
            observer.complete();
        });
    };
    MockService.prototype.LoadMtForms = function () {
        var forms = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(function (x) { return ({
            id: x,
            name: "\u0424\u043E\u0440\u043C\u044B " + x
        }); });
        return Observable.create(function (observer) {
            observer.next(forms);
            observer.complete();
        });
    };
    MockService.prototype.LoadCrmSections = function () {
        var sections = [
            {
                id: 1,
                code: 'AMO_CRM_CONTACT',
                title: 'Новый контакт'
            },
            {
                id: 2,
                code: 'AMO_CRM_COMPANY',
                title: 'Новая компания'
            },
            {
                id: 3,
                code: 'AMO_CRM_DEAL',
                title: 'Новая сделка'
            },
            {
                id: 4,
                code: 'AMO_CRM_TASK',
                title: 'Новая задача'
            },
            {
                id: 5,
                code: 'AMO_CRM_CALL',
                title: 'Запись звонка'
            },
            {
                id: 6,
                code: 'AMO_CRM_NOTE',
                title: 'Новое примечания'
            },
            {
                id: 7,
                code: 'AMO_CRM_NOT_SORTED_CALL',
                title: 'Звонок в неразобранном'
            },
            {
                id: 8,
                code: 'AMO_CRM_NOT_SORTED_LEAD',
                title: 'Заявка в неразобранных'
            },
            {
                id: 9,
                code: 'AMO_CRM_CALL_CARD',
                title: 'Всплывающая карточка звонка'
            }
        ];
        return Observable.create(function (observer) {
            observer.next(sections);
            observer.complete();
        });
    };
    MockService.prototype.LoadFormFields = function () {
        var formFields = [
            {
                id: 1,
                title: 'Ф.И.О.',
                code: 'FULL_NAME'
            },
            {
                id: 1,
                title: 'Email',
                code: 'EMAIL'
            },
            {
                id: 1,
                title: 'Телефон',
                code: 'PHONE'
            },
            {
                id: 1,
                title: 'День рождения',
                code: 'BIRTHDAY'
            },
            {
                id: 1,
                title: 'Возраст',
                code: 'AGE'
            },
            {
                id: 1,
                title: 'Город, страна',
                code: 'COUNTRY'
            },
        ];
        return Observable.create(function (observer) {
            observer.next(formFields);
            observer.complete();
        });
    };
    MockService.prototype.LoadMtRemarketingGroups = function (accountId) {
        return Observable.create(function (observer) {
            observer.next([
                {
                    id: 1,
                    name: 'Аудитория ремаркетинга 1'
                },
                {
                    id: 2,
                    name: 'Аудитория ремаркетинга  2'
                },
                {
                    id: 3,
                    name: 'Аудитория ремаркетинга  3'
                },
                {
                    id: 4,
                    name: 'Аудитория ремаркетинга 4'
                },
                {
                    id: 5,
                    name: 'Аудитория ремаркетинга 5'
                }
            ]);
            observer.complete();
        });
    };
    MockService.ngInjectableDef = i0.defineInjectable({ factory: function MockService_Factory() { return new MockService(); }, token: MockService, providedIn: "root" });
    return MockService;
}());
export { MockService };
