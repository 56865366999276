/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./test.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../projects/new-payments/src/lib/new-payments.component.ngfactory";
import * as i3 from "../../../../projects/new-payments/src/lib/new-payments.component";
import * as i4 from "./test.component";
var styles_TestComponent = [i0.styles];
var RenderType_TestComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TestComponent, data: {} });
export { RenderType_TestComponent as RenderType_TestComponent };
export function View_TestComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "lib-new-payments", [], null, null, null, i2.View_NewPaymentsComponent_0, i2.RenderType_NewPaymentsComponent)), i1.ɵdid(1, 114688, null, 0, i3.NewPaymentsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_TestComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-test", [], null, null, null, View_TestComponent_0, RenderType_TestComponent)), i1.ɵdid(1, 114688, null, 0, i4.TestComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TestComponentNgFactory = i1.ɵccf("app-test", i4.TestComponent, View_TestComponent_Host_0, {}, {}, []);
export { TestComponentNgFactory as TestComponentNgFactory };
