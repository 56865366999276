import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BlogRoutingModule } from './blog-routing.module';
import { CreatePostComponent } from './routes/create-post/create-post.component';
import { UpdatePostComponent } from './routes/update-post/update-post.component';
import { UpdateTagComponent } from './routes/update-tag/update-tag.component';
import { TagsComponent } from './routes/tags/tags.component';
import { SitemapComponent } from './routes/sitemap/sitemap.component';
import { BlogLayoutComponent } from './routes/blog-layout/blog-layout.component';
import {NgrUiModule} from '../../ngr-ui/ngr-ui.module';
import { PostsListComponent } from './routes/posts-list/posts-list.component';
import {ClarityModule} from '@clr/angular';

@NgModule({
  imports: [
    CommonModule,
    BlogRoutingModule,
    NgrUiModule,
    ClarityModule
  ],
  declarations: [CreatePostComponent, UpdatePostComponent, UpdateTagComponent, TagsComponent, SitemapComponent, BlogLayoutComponent, PostsListComponent]
})
export class BlogModule { }
