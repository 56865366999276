import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {ControlContainer, FormControl, FormGroup} from '@angular/forms';
import {IVkGroupViewModel, VkGroupViewModel} from '../../../models/vk-group.view-model';
import {AdManagerService, AutomationVkService} from '../../../../../api/services';
import {Subject} from 'rxjs/Subject';
import {Observable} from 'rxjs/Observable';
import 'rxjs-compat/add/operator/delay';
import 'rxjs-compat/add/operator/skipUntil';
import {environment} from '../../../../../../environments/environment';
import {debounceTime, distinctUntilChanged, flatMap, map, pairwise, take} from 'rxjs/operators';
import {GroupsSelectorService} from '../../../services/groups-selector/groups-selector.service';
import {MatSort, MatTableDataSource} from '@angular/material';
import {TargetingViewModel} from '../../../../models/view-models/targeting.view-model';
import 'rxjs-compat/add/operator/pairwise';
import 'rxjs-compat/add/operator/map';

@Component({
  selector: 'app-targeting-settings',
  templateUrl: './targeting-settings.component.html',
  styleUrls: ['./targeting-settings.component.scss']
})
export class TargetingSettingsComponent implements OnInit, OnChanges {

  @Input() public accountId: any;
  @Input() public adFormat: number;

  public countrySearchString = new FormControl();
  public citySearchString = new FormControl();

  public citiesParams = new Subject();

  public countries$: Observable<Array<any>>;
  public cities$;
  public targetingStats$: Observable<any>;

  public cities = [];

  public ages = environment.vkAges;
  public audience = null;

  public get AgesFrom() {
    const ageTo = parseInt(this.controlContainer.control.get('ageTo').value, 10);
    if (ageTo !== 0) {
      return this.ages.filter(x => x <= ageTo);
    } else {
      return this.ages;
    }
  }

  public get AgesTo() {
    const ageFrom = parseInt(this.controlContainer.control.get('ageFrom').value, 10);
    if (ageFrom !== 0) {
      return this.ages.filter(x => x >= ageFrom);
    } else {
      return this.ages;
    }
  }

  public dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private adManagerService: AdManagerService,
    private vkService: AutomationVkService,
    public controlContainer: ControlContainer,
    public groupsService: GroupsSelectorService
  ) {
  }

  ngOnInit() {
    this.LoadCountries();
    this.LoadCities(1, '');

    this.groupsService.selectedGroupsSubject.subscribe(data => {
      console.log(data);
      this.controlContainer.control.get('groups').setValue(data.map(x => x.id).join());
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.sort = this.sort;
    });

    this.controlContainer.control.valueChanges
      .pipe(
        distinctUntilChanged(),
        debounceTime(1000)
      )
      .subscribe((data) => {
        let [accountId, clientId] = this.accountId.split('_');
        accountId = parseInt(accountId, 10);
        clientId = parseInt(clientId, 10);
        this.LoadStats(accountId, clientId, data);
      });

    this.controlContainer.control.get('country')
      .valueChanges
      .filter(x => parseInt(x, 10) >= 0)
      .subscribe(data => {
        if (parseInt(data, 10) === 0) {
          this.controlContainer
            .control
            .get('cities')
            .setValue([0]);
        }

        console.log(this.controlContainer.control.value, data, 'TARGETING SETTINGS');

        if (parseInt(data, 10) > 0) {
          this.citiesParams.next({country: data, searchString: this.citySearchString.value});
        }
      });

    this.citySearchString.valueChanges
      .subscribe(searchString => {
        this.citiesParams.next({searchString, country: this.controlContainer.control.get('country').value});
      });

    this.citiesParams.subscribe(({country, searchString}) => {
      console.log(country, searchString);
      this.LoadCities(country, searchString);
    });

    this.controlContainer.control.get('cities').valueChanges.subscribe(data => {
      if (data[0] === 0) {
        this.controlContainer.control.get('cities').setValue([0], {emitEvent: false});
      }

      if (data.length > 1) {
        this.controlContainer.control.get('cities').setValue(data.filter(val => val > 0), {emitEvent: false});
      }

      // if (data.length === 1 && data[0] === 0) {
      //
      // }

      if (data.length === 0) {
        this.controlContainer.control.get('cities').setValue([0], {emitEvent: false});
      }
    });
    // combineLatest(
    //     this.controlContainer.control.get('country').valueChanges,
    //     this.citySearchString.valueChanges)
    //   .pipe(
    //     distinctUntilChanged(),
    //   )
    //   .subscribe(([country, searchString]) => {
    //     console.log(searchString, country);
    //   });
  }

  public LoadStats(accountId: any, clientId: any, data) {
    console.log('TARGETING STATS');
    setTimeout(() => {
      this.targetingStats$ = this.adManagerService.GetTargetingStats({
        accountId,
        clientId,
        linkUrl: 'https://vk.com',
        linkDomain: 'vk.com',
        adFormat: this.adFormat,
        criteria: {
          age_from: data ? data.ageFrom : 0,
          age_to: data ? data.ageTo : 0,
          cities: data ? data.cities ? data.cities.filter(x => x >= 0).join() : '' : '',
          country: (data && parseInt(data.country, 10) >= 0) ? data.country : 0,
          sex: data ? data.sex : 0,
          groups: data ? data.groups : '',
        },

      })
        .pipe(
          distinctUntilChanged(),
          debounceTime(1000),
          map((targetingStats) => {
            if (targetingStats !== null) {
              this.controlContainer.control.get('audience').setValue(targetingStats.audience_count, {
                emitEvent: false
              });
              this.controlContainer.control.get('audience').markAsDirty();
              // this.audience = targetingStats.audience_count;
            }
            return targetingStats;
          })
        );
    }, 1300);
  }

  public RemoveGroupFromSelected(group) {
    this.groupsService.SelectGroups([group]);
  }

  public LoadCountries() {
    this.countries$ = this.adManagerService.GetCountries()
      .map(response => response.items);
  }

  public get DisplayedCities() {
    return (this.citySearchString.value)
      ? this.cities.filter(x => x.title.toLowerCase().includes(this.citySearchString.value.toLowerCase()))
      : this.cities;
  }

  public LoadCities(countryId, searchString) {
    this.adManagerService.GetCities({
      countryId,
      searchString
    })
      .subscribe(data => {
        data.forEach((city: any) => {
          if (!this.cities.map(x => x.id).includes(city.id)) {
            this.cities.push(city);
          }
        });
      });
  }

  public TipGroups: string = 'Вы можете показывать объявление, подписчикам групп конкурентов или смежных по вашей тематике групп. Для этого введите названия групп, либо слова которые могут содержать названия групп.\n' +
    '\n' +
    'Пример:\n' +
    'Если ваш вид деятельности - салон красоты, то в поле Название группы введите “Салон красоты”, “Наращивание ресниц” или “Брови” и т.п. \n' +
    '\n' +
    'Выберите страну и город где находятся ваши клиенты. \n' +
    'Если вы и ваши клиенты находятся, например, в городе Екатеринбург, то выберите в поле Страна - “Россия”, а в поле Город или регион: Екатеринбург.\n' +
    '\n' +
    'После выбора групп, справа, будет показываться какому количеству пользователей может быть показано ваше объявление.\n';

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);
    if ((changes.accountId || changes.adFormat)) {
      if (!this.accountId.includes('undefined')) {
        let [accountId, clientId] = this.accountId.split('_');
        accountId = parseInt(accountId, 10);
        clientId = parseInt(clientId, 10);
        this.LoadStats(accountId, clientId, this.controlContainer.control.value);
      }
    }
  }
}
