<div mat-dialog-title>Новый звонок пользователю</div>

<div>
  <form class="call-form" [formGroup]="form">
    <div class="call-form__group">
      <mat-form-field class="call-form__input" [style.width]="'45%'" appearance="fill">
        <mat-label>Дата звонка</mat-label>
        <input type="text" matInput [matDatepicker]="picker" placheolder="Выберите дату следующего звонка" class="call-input" formControlName="date">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker [disabled]="false"></mat-datepicker>
      </mat-form-field>

      <mat-form-field class="call-form__input" [style.width]="'25%'" appearance="fill">
        <mat-label>Часы</mat-label>
        <input type="number" matInput min="0" max="23" formControlName="hours">
      </mat-form-field>

      <mat-form-field class="call-form__input" [style.width]="'25%'" appearance="fill">
        <mat-label>Минуты</mat-label>
        <input type="number" matInput min="0" max="59" formControlName="minutes">
      </mat-form-field>
    </div>

    <mat-form-field class="call-form__input" appearance="fill" [style.width]="'100%'">
      <mat-label>Комментарий к звонку</mat-label>
      <textarea matInput class="call-textarea" formControlName="comment"></textarea>
    </mat-form-field>
    <button mat-raised-button color="primary" [style.width]="'100%'" (click)="SaveCall()">Сохранить</button>
  </form>
</div>
