import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../services/api.service';
import {Account} from '../../models/account';
import {Campaign} from '../../models/campaign';
import {Ad} from '../../models/ad';

@Component({
    selector: 'app-automation-home',
    templateUrl: './automation-home.component.html',
    styleUrls: ['./automation-home.component.scss']
})
export class AutomationHomeComponent implements OnInit {

    ngOnInit () {
    }
}
