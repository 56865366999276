import {Component, OnInit} from '@angular/core';
import {AdFormatViewModel} from '../../../../ad-manager/models/ad-format.view-model';
import {AdFormManagerService} from '../../services/ad-form-manager/ad-form-manager.service';

@Component({
  selector: 'app-new-ad-format-step',
  templateUrl: './new-ad-format-step.component.html',
  styleUrls: ['./new-ad-format-step.component.scss']
})
export class NewAdFormatStepComponent implements OnInit {

  constructor(
    private adFormManagerService: AdFormManagerService
  ) {
  }

  public get formats() {
    return this.adFormManagerService.adFormats;
  }

  public get form() {
    return this.adFormManagerService.adForm;
  }

  ngOnInit() {
  }

  public SelectField($event: any) {
    this.adFormManagerService.SelectFormat($event);
  }

  public get SelectedAdFormat(): AdFormatViewModel {
    return this.adFormManagerService.SelectedAdFormat;
  }
}
