import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, EventEmitter, OnChanges, OnInit, QueryList, SimpleChanges, TemplateRef } from '@angular/core';
import { DgColumnComponent } from '../dg-column/dg-column.component';
import { List } from 'linqts';
import { isNullOrUndefined } from 'util';
import { DgPaginationComponent } from '../dg-pagination/dg-pagination.component';
import { ExcelService } from '../../../automation/services/excel-service.service';
import { Subject } from 'rxjs/Subject';
import * as moment from 'moment';
var DataGridComponent = /** @class */ (function () {
    function DataGridComponent(excel, cd) {
        this.excel = excel;
        this.cd = cd;
        this.data = [];
        this.selectable = false;
        this.currentPage = 1;
        this.countOfResults = 10;
        this.filterString = '';
        this.loading = false;
        this.showEditForm = false;
        this.showFilterString = true;
        this.exportId = false;
        this.exportIdHeader = '';
        this.selectedRows = [];
        this.selectedRowsChange = new EventEmitter();
        this.showedColumns = null;
        this.showedColumnsChange = new EventEmitter();
        this.withoutPagination = new EventEmitter();
        this.parent = null;
        this.filterStrings = {};
        this.dataWithoutPagination = [];
        this.pagination = null;
        this.sortingField = '';
        this.sortingDescend = false;
        this.countOfPages = 1;
        this.IsSidebarHidden = true;
        this.displayedData = [];
        this.loadDataSubject = new Subject();
        this.dataNotFoundMessage = 'Данные не найдены';
    }
    Object.defineProperty(DataGridComponent.prototype, "displayedColumns", {
        get: function () {
            if (this.columns) {
                return this.columns.toArray();
            }
            else {
                return [];
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataGridComponent.prototype, "IsFilterLoaded", {
        get: function () {
            return Object.keys(this.filterStrings).length === this.displayedColumns.length;
        },
        enumerable: true,
        configurable: true
    });
    DataGridComponent.prototype.LoadDisplayedData = function () {
        console.log('START DISPLAING DATA');
        if (!isNullOrUndefined(this.data)) {
            var data = new List(this.data);
            if (this.sortingField) {
                data = this.SortData(data, this.sortingField, this.sortingDescend);
            }
            data = this.FilterData(data, this.filterStrings);
            this.countOfPages = Math.ceil(data.Count() / this.countOfResults);
            this.withoutPagination.emit(data.ToArray());
            data = this.GetPaginatedData(data, this.currentPage - 1, this.countOfResults);
            console.log(data, 'DISPLAYED DATA');
            if (data) {
                this.displayedData = data.ToArray();
            }
            else {
                this.displayedData = [];
            }
        }
        this.cd.detectChanges();
        // this.displayedData = [];
    };
    DataGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        console.log(this.pagination);
        var columnsSettings = JSON.parse(localStorage.getItem('showed_columns'));
        console.log(columnsSettings, 'SHOWED COLUMNS SETTINGS');
        if (columnsSettings && columnsSettings[this.currentColumnsStore]) {
            this.showedColumns = columnsSettings[this.currentColumnsStore];
        }
        this.displayedColumns.forEach(function (column) {
            _this.filterStrings[column.name] = _this.CreateFilterModule(column);
        });
        console.log(this.displayedColumns);
        this.cd.detectChanges();
    };
    DataGridComponent.prototype.ToggleSidebar = function () {
        this.IsSidebarHidden = !this.IsSidebarHidden;
    };
    DataGridComponent.prototype.HideColumnByName = function (name) {
        this.showedColumns[name] = false;
        var columnsStorage = JSON.parse(localStorage.getItem('showed_columns'));
        if (!columnsStorage) {
            columnsStorage = {};
        }
        columnsStorage[this.currentColumnsStore] = this.showedColumns;
        localStorage.setItem('showed_columns', JSON.stringify(columnsStorage));
    };
    DataGridComponent.prototype.GetColumnData = function (columnName) {
        if (this.data !== null) {
            return this.data
                .map(function (x) { return x[columnName]; });
        }
        return [];
    };
    Object.defineProperty(DataGridComponent.prototype, "currentColumnsStore", {
        get: function () {
            return location.pathname.substr(1).replace('/', '-');
        },
        enumerable: true,
        configurable: true
    });
    DataGridComponent.prototype.GetColumnDataFromPage = function (columnName) {
        if (this.data !== null) {
            return this.displayedData
                .map(function (x) { return x[columnName]; });
        }
        return [];
    };
    DataGridComponent.prototype.GetSummaryFuncByColumn = function (column) {
        if (column.summaryFunction) {
            if (column.formatFunction) {
                return column.formatFunction(column.summaryFunction(this.GetColumnData(column.name), this.displayedData));
            }
            return column.summaryFunction(this.GetColumnData(column.name), this.displayedData);
        }
    };
    Object.defineProperty(DataGridComponent.prototype, "SummaryData", {
        get: function () {
            var _this = this;
            var data = this.displayedColumns
                .filter(function (column) { return column.HasSummaryFunction; })
                .map(function (column) { return ({
                column: column,
                key: column.name,
                value: column.summaryFunction(_this.GetColumnData(column.name), _this.displayedData)
            }); })
                .reduce(function (summaryObject, _a) {
                var column = _a.column, key = _a.key, value = _a.value;
                var itemValue = value;
                if (column.formatFunction) {
                    itemValue = column.formatFunction(itemValue);
                }
                summaryObject[key] = itemValue;
                return summaryObject;
            }, {});
            return data;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataGridComponent.prototype, "PagingSummaryData", {
        get: function () {
            var _this = this;
            var data = this.displayedColumns
                .filter(function (column) { return column.HasPagingSummaryFunction || column.HasSummaryFunction; })
                .map(function (column) { return ({
                column: column,
                key: column.name,
                value: column.GetPagingSummaryFunctionResult(_this.GetColumnDataFromPage(column.name), _this.displayedData)
            }); })
                .reduce(function (summaryObject, _a) {
                var column = _a.column, key = _a.key, value = _a.value;
                var itemValue = value;
                if (column.formatFunction) {
                    itemValue = column.formatFunction(itemValue);
                }
                summaryObject[key] = itemValue;
                return summaryObject;
            }, {});
            return data;
        },
        enumerable: true,
        configurable: true
    });
    DataGridComponent.prototype.GetPagingSummaryFunction = function (column) {
        if (column.pagingSummaryFunction) {
            if (column.formatFunction) {
                return column.formatFunction(column.pagingSummaryFunction(this.GetColumnDataFromPage(column.name)));
            }
            return column.pagingSummaryFunction(this.GetColumnDataFromPage(column.name));
        }
        else {
            if (column.summaryFunction) {
                if (column.formatFunction) {
                    return column.formatFunction(column.summaryFunction(this.GetColumnDataFromPage(column.name)));
                }
                return column.summaryFunction(this.GetColumnDataFromPage(column.name));
            }
        }
        return 0;
    };
    DataGridComponent.prototype.GetCellContent = function () {
    };
    DataGridComponent.prototype.SetSorting = function (field, sortable, sortDescending) {
        if (sortDescending === void 0) { sortDescending = false; }
        if (sortable) {
            if (this.sortingField === field) {
                this.sortingDescend = !this.sortingDescend;
            }
            else {
                this.sortingField = field;
                this.sortingDescend = false;
            }
            if (sortDescending) {
                this.sortingDescend = true;
            }
            this.loadDataSubject.next();
            if (!isNullOrUndefined(this.pagination)) {
                this.pagination.FirstPage();
            }
        }
    };
    DataGridComponent.prototype.getCellContent = function (column, row) {
        // console.log(column);
        var cellData = row[column.name];
        if (column.formatFunction) {
            return column.formatFunction(cellData);
        }
        else {
            return cellData;
        }
    };
    DataGridComponent.prototype.OnChangeFilter = function () {
        var _this = this;
        Object.keys(this.filterStrings)
            .forEach(function (filterStringName) {
            if (_this.filterStrings[filterStringName].from) {
                _this.filterStrings[filterStringName].from = Math.max(_this.filterStrings[filterStringName].from, 0);
            }
            if (_this.filterStrings[filterStringName].to) {
                _this.filterStrings[filterStringName].to = Math.max(_this.filterStrings[filterStringName].to, 0);
            }
        });
        this.loadDataSubject.next();
        this.LoadDisplayedData();
        console.log('FUCKING GO TO FIRST PAGE');
        this.GoToFirstPage();
    };
    DataGridComponent.prototype.GoToFirstPage = function () {
        console.log(this.pagination, 'PAGINATION DG');
        if (this.pagination) {
            console.log('FUCKING FIRST PAGE');
            this.pagination.FirstPage();
        }
    };
    DataGridComponent.prototype.IsShowedColumn = function (columnName) {
        return this.showedColumns && this.showedColumns[columnName];
    };
    Object.defineProperty(DataGridComponent.prototype, "HiddableColumns", {
        get: function () {
            return this.columns ? this.columns
                .filter(function (column) { return column.hiddable; }) : [];
        },
        enumerable: true,
        configurable: true
    });
    DataGridComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.paginationComponents.changes.subscribe(function (x) {
            console.log(x, ' PAGINATION');
        });
        console.log(this.pagination, 'PAGINATION AFTER INIT');
        if (this.showedColumns === null) {
            this.showedColumns = {};
            this.ShowAllColumns();
        }
        this.columns.changes.subscribe(function (columns) {
            _this.ShowAllColumns();
            _this.displayedColumns.forEach(function (column) {
                if (!_this.filterStrings[column.name]) {
                    _this.filterStrings[column.name] = _this.CreateFilterModule(column);
                }
            });
        });
        this.displayedColumns.forEach(function (column) {
            _this.filterStrings[column.name] = _this.CreateFilterModule(column);
        });
        this.LoadDisplayedData();
        this.loadDataSubject.subscribe(function () {
            _this.LoadDisplayedData();
        });
    };
    DataGridComponent.prototype.ShowAllColumns = function () {
        var _this = this;
        this.columns.forEach(function (column) {
            _this.showedColumns[column.name] = true;
        });
    };
    /**
     * Return sorted data
     * @param data
     * @param field
     * @param isDescending
     * @constructor
     */
    DataGridComponent.prototype.SortData = function (data, field, isDescending) {
        var _this = this;
        var result = null;
        if (!isDescending) {
            result = data.ToArray().sort(function (a, b) { return _this.NormalizeField(field, b[field]) - _this.NormalizeField(field, a[field]); });
        }
        else {
            result = data.ToArray().sort(function (a, b) { return _this.NormalizeField(field, a[field]) - _this.NormalizeField(field, b[field]); });
        }
        return new List(result);
    };
    DataGridComponent.prototype.NormalizeField = function (field, fieldValue) {
        var column = this.columns.find(function (x) { return x.name === field; });
        if (column && column.type === 'date') {
            return parseInt(moment(new Date(fieldValue)).format('x'), 10);
        }
        return fieldValue;
    };
    /**
     * Return all data matches for filter object
     * @param data - Data of data-grid
     * @param fieldsOfFilter filter object
     * @constructor
     */
    DataGridComponent.prototype.FilterData = function (data, fieldsOfFilter) {
        var _this = this;
        Object
            .keys(fieldsOfFilter)
            .filter(function (field) {
            return fieldsOfFilter[field].value || fieldsOfFilter[field].from || fieldsOfFilter[field].to || fieldsOfFilter[field].type === 'custom';
        })
            .forEach(function (field) {
            var column = _this.columns.find(function (column) { return column.name === field; });
            console.log(fieldsOfFilter[field], 'field of filter');
            data = data.Where(function (dataItem) { return _this.filterValue(dataItem, fieldsOfFilter[column.name], column.name); });
        });
        //
        // if (this.filterString) {
        //   data = data.Where(dataItem => dataItem.name.match(this.filterString));
        // }
        return data;
    };
    DataGridComponent.prototype.filterValue = function (row, filter, columnName) {
        if (filter.type === 'custom') {
            return filter.filterFunc.apply(this.parent, [row]);
        }
        else {
            return this.MatchesTheFilter(filter, row[columnName]);
        }
    };
    DataGridComponent.prototype.matchFilters = function (filterValue, dataItemFilter, cloumn) {
        this.MatchesTheFilter(filterValue, dataItemFilter);
    };
    /**
     *
     * @param dataItemFilter
     * @param filterValue
     * @constructor
     */
    DataGridComponent.prototype.MatchesTheFilter = function (filterValue, dataItemFilter) {
        var isMatched = false;
        if (!isNullOrUndefined(dataItemFilter)) {
            if (filterValue.type === 'between') {
                var isMatchedFrom = true;
                var isMatchedTo = true;
                if (filterValue.from) {
                    isMatchedFrom = parseFloat(dataItemFilter.toFixed(3)) >= parseFloat(filterValue.from.toFixed(3));
                }
                if (filterValue.to) {
                    isMatchedTo = parseFloat(dataItemFilter.toFixed(3)) <= parseFloat(filterValue.to.toFixed(3));
                }
                isMatched = isMatchedFrom && isMatchedTo;
            }
            else if (filterValue.type === 'select') {
                if (filterValue.value === 'null') {
                    isMatched = true;
                }
                else {
                    isMatched = dataItemFilter.toString() === filterValue.value.toString();
                }
            }
            else {
                if (typeof dataItemFilter === 'string') {
                    var reg = new RegExp(filterValue.value.toLowerCase());
                    // isMatched = dataItemFilter.toLowerCase().match(filterValue.value.toLowerCase()).length > 0;
                    isMatched = reg.test(dataItemFilter.toLowerCase());
                }
                else {
                    isMatched = parseFloat(dataItemFilter) === parseFloat(filterValue.value);
                }
            }
        }
        return isMatched;
    };
    /**
     *
     * @param data
     * @param currentPage
     * @param countOfResults
     * @constructor
     */
    DataGridComponent.prototype.GetPaginatedData = function (data, currentPage, countOfResults) {
        console.log(countOfResults, 'DATA GRID COUNT OF RESULTS');
        var toRender = data
            .Skip(currentPage * countOfResults);
        if (countOfResults) {
            toRender = toRender
                .Take(countOfResults);
        }
        return toRender;
    };
    DataGridComponent.prototype.CreateFilterModule = function (column) {
        this.cd.detectChanges();
        if (column.filterFunction) {
            return this.CreateCustomFilter(column.filterFunction);
        }
        else {
            if (column.filterType === 'between') {
                return this.CreateFilterBetween();
            }
            else if (column.filterType === 'checkbox') {
                return this.CreateChecbkoxFilter();
            }
            else if (column.filterType === 'select') {
                return this.CreateSelectFilter();
            }
            else {
                return this.CreateFilterDefault();
            }
        }
    };
    DataGridComponent.prototype.CreateFilterDefault = function () {
        return {
            value: '',
            type: 'default'
        };
    };
    DataGridComponent.prototype.CreateFilterBetween = function () {
        return {
            from: '',
            to: '',
            type: 'between'
        };
    };
    DataGridComponent.prototype.CreateChecbkoxFilter = function () {
        return {
            value: null,
            type: 'checkbox'
        };
    };
    DataGridComponent.prototype.CreateSelectFilter = function () {
        return {
            value: null,
            type: 'select'
        };
    };
    DataGridComponent.prototype.CreateCustomFilter = function (filterFunc) {
        return {
            type: 'custom',
            filterFunc: filterFunc,
        };
    };
    DataGridComponent.prototype.intToBool = function (value) {
        return value === 1;
    };
    DataGridComponent.prototype.stringToBool = function (value) {
        return value === 'true';
    };
    DataGridComponent.prototype.findColumnByName = function (columnName) {
        return this.columns.find(function (column) { return column.name === columnName; });
    };
    DataGridComponent.prototype.ExportToExcel = function () {
        var _this = this;
        console.log('Start Excel');
        var dataToExport = this.data.map(function (dataItem) {
            var excelDataItem = Object.keys(dataItem)
                .map(function (dataItemKey) {
                var column = _this.findColumnByName(dataItemKey);
                if (column) {
                    var result = { key: column.header || 'CPM', value: _this.getCellContent(column, dataItem) };
                    return result;
                }
                else {
                    return null;
                }
            })
                .filter(function (columnData) { return columnData !== null; })
                .reduce(function (resultObject, _a) {
                var key = _a.key, value = _a.value;
                resultObject[key] = value;
                return resultObject;
            }, {});
            return excelDataItem;
        });
        this.excel.exportAsExcelFile(dataToExport, 'excel');
    };
    DataGridComponent.prototype.ngOnChanges = function (changes) {
        this.LoadDisplayedData();
        console.log(this.displayedColumns, 'DISPLAYED COLUMNS');
    };
    Object.defineProperty(DataGridComponent.prototype, "ShowFooter", {
        get: function () {
            return this.data !== null && this.data.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    DataGridComponent.prototype.OnPageChange = function (page) {
        this.currentPage = page;
        this.loadDataSubject.next();
    };
    DataGridComponent.prototype.ngAfterViewChecked = function () {
        this.cd.detectChanges();
    };
    DataGridComponent.prototype.UpdateStorage = function () {
        var columnsStorage = JSON.parse(localStorage.getItem('showed_columns'));
        if (!columnsStorage) {
            columnsStorage = {};
        }
        columnsStorage[this.currentColumnsStore] = this.showedColumns;
        localStorage.setItem('showed_columns', JSON.stringify(columnsStorage));
    };
    return DataGridComponent;
}());
export { DataGridComponent };
