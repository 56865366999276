<nav class="affiliate-program-statistics__navigation" mat-tab-nav-bar>
  <ng-container *ngFor="let link of links">
    <a
      mat-tab-link
      [routerLink]="link.path"
      [routerLinkActiveOptions]="{
        exact: true
      }"
      routerLinkActive
      #rla="routerLinkActive"
      [active]="rla.isActive"
    >
      {{link.title}}
    </a>
  </ng-container>
</nav>
