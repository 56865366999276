export function CrmSectionValidator(sectionGroup) {
    var status = false;
    var errors = {
        errors: {}
    };
    if (sectionGroup.get('selected').value) {
        var fieldsGroup_1 = sectionGroup.get('fields');
        Object
            .keys(fieldsGroup_1.controls)
            .map(function (key) { return ({ key: key, fieldControl: fieldsGroup_1.controls[key] }); })
            .forEach(function (_a) {
            var key = _a.key, fieldControl = _a.fieldControl;
            var controlValid = !isNullOrUndefined(fieldControl.value) && fieldControl.valid;
            status = status || controlValid;
            if (fieldControl.errors) {
                errors.errors[key] = fieldControl.errors;
            }
        });
        if (!status) {
            return errors;
        }
    }
    return true;
}
/**
 * Равно ли значение переменной null или undefined
 * @param val
 */
function isNullOrUndefined(val) {
    return val === null || val === undefined;
}
