import {Component, Input, OnInit} from '@angular/core';
import {PaymentSystem} from "../../../api/models/payment-system";

@Component({
  selector: 'app-easy-payment-system',
  templateUrl: './easy-payment-system.component.html',
  styleUrls: ['./easy-payment-system.component.scss']
})
export class EasyPaymentSystemComponent implements OnInit {
  @Input() public system: PaymentSystem = null;

  constructor() { }

  ngOnInit() {
  }

}
