var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { map as __map, filter as __filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../api-configuration";
import * as i2 from "@angular/common/http";
var CallsController1Service = /** @class */ (function (_super) {
    __extends(CallsController1Service, _super);
    function CallsController1Service(config, http) {
        return _super.call(this, config, http) || this;
    }
    /**
     * @return Success
     */
    CallsController1Service.prototype.GetUserCallsResponse = function () {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/calls", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @return Success
     */
    CallsController1Service.prototype.GetUserCalls = function () {
        return this.GetUserCallsResponse().pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @return Success
     */
    CallsController1Service.prototype.GetCallsStatusesResponse = function () {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/calls/statuses", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @return Success
     */
    CallsController1Service.prototype.GetCallsStatuses = function () {
        return this.GetCallsStatusesResponse().pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param statusDto undefined
     * @return Success
     */
    CallsController1Service.prototype.ChangeCallStatusResponse = function (statusDto) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = statusDto;
        var req = new HttpRequest('PUT', this.rootUrl + "/apiv2/calls/change-status", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param statusDto undefined
     * @return Success
     */
    CallsController1Service.prototype.ChangeCallStatus = function (statusDto) {
        return this.ChangeCallStatusResponse(statusDto).pipe(__map(function (_r) { return _r.body; }));
    };
    CallsController1Service.GetUserCallsPath = '/apiv2/calls';
    CallsController1Service.GetCallsStatusesPath = '/apiv2/calls/statuses';
    CallsController1Service.ChangeCallStatusPath = '/apiv2/calls/change-status';
    CallsController1Service.ngInjectableDef = i0.defineInjectable({ factory: function CallsController1Service_Factory() { return new CallsController1Service(i0.inject(i1.ApiConfiguration), i0.inject(i2.HttpClient)); }, token: CallsController1Service, providedIn: "root" });
    return CallsController1Service;
}(__BaseService));
export { CallsController1Service };
