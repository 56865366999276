import {AfterViewInit, ChangeDetectorRef, Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {PlatformAssignationsQuery} from '../../../../stores/platform-assignations/platform-assignations.query';
import {PlatformsTypes} from '../../../../platforms-types';
import {BitrixService} from '../../../../../api/services';
import {CrmPipelinesQuery} from '../../../../stores/crm-pipelines/crm-pipelines.query';
import {CrmPipelinesService} from '../../../../stores/crm-pipelines/crm-pipelines.service';
import {AkitaNgFormsManager} from '@datorama/akita-ng-forms-manager';
import {PlatformsService} from '../../../../stores/platforms/platforms.service';
import {AssignationPlatforms} from '../../../../stores/platform-assignations/platform-assignation.model';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators';

export enum BitrixPipelinesSettingsMode {
  CLASSIC_MODE = 1,
  SIMPLE_MODE = 2
}

@Component({
  selector: 'app-bitrix-export-settings',
  templateUrl: './bitrix-export-settings.component.html',
  styleUrls: ['./bitrix-export-settings.component.scss']
})
export class BitrixExportSettingsComponent implements OnInit, OnChanges, AfterViewInit {

  public pipelines$ = this.pipelinesQuery.pipelines$
    .pipe(
      map(x => x.filter(pipeline => pipeline.name !== 'PM_SYSTEM_PIPELINE'))
    );

  public isAssigned: boolean = false;
  public form: FormGroup;
  public hasLeads: boolean = false;
  public isDeals = false;
  public isFirst = true;

  constructor(
    private fb: FormBuilder,
    private fm: AkitaNgFormsManager,
    private assignationQuery: PlatformAssignationsQuery,
    private bitrixService: BitrixService,
    private pipelinesQuery: CrmPipelinesQuery,
    private pipelinesService: CrmPipelinesService,
    private cd: ChangeDetectorRef,
    private platformsService: PlatformsService
  ) {
  }

  ngOnInit() {
    this.CheckAssignations();
    setTimeout(() => {
      this.InitForm();
    }, 100);
  }

  private InitForm() {
    this.form = this.fb.group({
      section: this.fb.control('CONTACTS'),
      isClassicMode: this.fb.control(false),
      selectedPipelineId: this.fb.control(null)
    });
    this.fm.upsert('originPlatformSettings', this.form);
    this.form.reset({
      section: 'CONTACTS'
    });

    const sectionControl = this.form.get('section');
    const selectedPipelineIdControl = this.form.get('selectedPipelineId');

    /**
     * При измнении секции выбранная воронка должна отчищаться
     */
    sectionControl.valueChanges.subscribe(section => {
      selectedPipelineIdControl.reset();
      this.isDeals = section === 'DEALS';
      this.pipelines$
        .subscribe(data => {
          if (data.length === 1 && this.isDeals) {
            selectedPipelineIdControl.setValue(data[0].id);
          }
        });
    });
    this.cd.detectChanges();
  }

  /**
   *
   * @constructor
   */
  public CheckAssignations() {
    this.assignationQuery.isPlatformAssigned$(PlatformsTypes.BITRIX_EXPORT)
      .pipe(
        distinctUntilChanged(),
        debounceTime(this.isFirst ? 0 : 5000)
      )
      .subscribe(data => {
        if (!this.isFirst) {
          this.isFirst = true
        }
        this.isAssigned = data;
        this.pipelinesService.LoadPipelines(PlatformsTypes.BITRIX_EXPORT);
        this.CheckSettingsMode();
      });
  }

  /**
   *
   * @constructor
   */
  public CheckSettingsMode() {
    this.bitrixService.GetCrmSettingsMode()
      .pipe(map(response => response['result']))
      .subscribe(data => {
        const isClassicMode = data === BitrixPipelinesSettingsMode.CLASSIC_MODE;
        this.form.get('isClassicMode').setValue(isClassicMode);
        if (isClassicMode) {
          this.hasLeads = true;
          this.platformsService.SetParameters(AssignationPlatforms.BITRIX24, {
            hasLeads: this.hasLeads
          });
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngAfterViewInit(): void {
    this.InitForm();
  }

}
