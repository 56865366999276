export interface IContactLinkViewModel {
    title: string;
    subtitle: string;
    icon: string;
    linkUrl: string;
}

export class ContactLinkViewModel implements IContactLinkViewModel {
    icon: string;
    linkUrl: string;
    subtitle: string;
    title: string;

    /**
     *
     * @param {string} icon - Иконка
     * @param {string} linkUrl - Ссылка на контакт
     * @param {string} subtitle - 2 название
     * @param {string} title - название
     */
    constructor(icon: string, linkUrl: string, subtitle: string, title: string) {
        this.icon = icon;
        this.linkUrl = linkUrl;
        this.subtitle = subtitle;
        this.title = title;
    }
}
