<div class="suggestion-info">
  <clr-signpost>
    <clr-icon shape="info-standard" clrSignpostTrigger></clr-icon>
    <clr-signpost-content *clrIfOpen [clrPosition]="position" [style.width]="width">
      <ng-container *ngIf="suggestion; else warning">
        <br>
        <div [innerHtml]="suggestion?.htmlContent"></div>
      </ng-container>
      <ng-template #warning>
        Внимание для подсказки {{code}} нет подсказки.
      </ng-template>
    </clr-signpost-content>
  </clr-signpost>
</div>
