import {Component, ElementRef, Input, OnInit} from '@angular/core';

@Component({
  selector: 'vk-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  @Input() public validator: string;
  @Input() public name: string = 'default';

  constructor(
    public elementRef: ElementRef
  ) {
  }

  ngOnInit() {
  }

}
