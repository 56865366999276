export enum AgeSegmentSex {
  ALL = 0,
  MALE = 1,
  FEMALE = 2,
  SPLIT = 3
}

export class AgeSegment {
  public ageFrom: number;
  public ageTo: number;
  public eachAge: boolean;
  public sex: AgeSegmentSex;
}
