import {Injectable, OnInit} from '@angular/core';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import * as moment from 'moment';
import {merge, Observable, Subject, throwError} from 'rxjs';
import {of} from 'rxjs/internal/observable/of';
import {startWith} from 'rxjs/internal/operators/startWith';
import {tap} from 'rxjs/internal/operators/tap';
import {
  catchError,
  concatAll,
  debounceTime,
  delay,
  distinctUntilChanged,
  flatMap,
  map,
  pairwise,
  retry,
  share,
} from 'rxjs/operators';
import {isNullOrUndefined} from 'util';
import {environment} from '../../../../../../environments/environment';
import {
  BidRange,
  Campaign,
  Group,
  PostMonitorBackend_V2ModelsAdManagerCreateAdViewModel,
  PostMonitorBackend_V2ModelsvkVKAPIAdTargetGroup,
  PostMonitorBackend_V2ModelsvkVKAPIAdTargetingStats,
  PostMonitorBackend_V2ModelsvkVKAPICityModel,
  PostMonitorBackend_V2ModelsvkVKAPICountryModel,
  PostMonitorBackend_V2ModelsvkVKAPIUpdateAds,
  UserAutomationCabinetSlot,
  WallAttachment,
  WallPost
} from '../../../../../api/models';
import {PostMonitorBackend_V2ModelsvkVKAPIGetCountriesResponse} from '../../../../../api/models/post-monitor-backend-_v2modelsvk-vkapiget-countries-response';
import {PostMonitorBackend_V2ModelsvkVKAPIGroupModel} from '../../../../../api/models/post-monitor-backend-_v2modelsvk-vkapigroup-model';
import {
  AdManagerService,
  AutomationVkService,
  AutomationVkSlotService,
  UserStrategiesService
} from '../../../../../api/services';
import {DomainOnlyPipe} from '../../../../../shared/pipes/domain-only.pipe';
import {PostificatePipe} from '../../../../../shared/pipes/postificate.pipe';
import {LoggingService} from '../../../../../shared/services/logging.service';
import {AdFormatViewModel} from '../../../../ad-manager/models/ad-format.view-model';
import {PromopostViewModel} from '../../../../ad-manager/models/promopost.view-model';
import {TeazerViewModel} from '../../../../ad-manager/models/teazer.view-model';
import {emojiCount, url} from '../../../../ad-manager/validators/url-validator';
import {AccountViewModel} from '../../../../models/view-models/account-view-model';

const PROMOPOST_TEXT_VALIDATORS = [Validators.required, Validators.minLength(1), Validators.maxLength(650)];
const DEFAULT_AD_CATEGORY_ID = 126;

enum StrategyAction {
  CREATE_STRATEGY = 'CREATE_STRATEGY',
  UPDATE_STRATEGY = 'UPDATE_STRATEGY',
  REMOVE_STRATEGY = 'REMOVE_STRATEGY',
  DO_NOTHING = 'DO_NOTHING'
}

const strategyDefaultValues = {
  ECPC_LIMIT: 0,
  CUSTOM_OVERALL_LIMIT: null,
  ECPC_LIMIT_EXTENSION: 0,
  IMPRESSION_LIMIT: null,
  IMPRESSION_LIMIT_EXTENSION: 0,
  REACH_LIMIT: null,
  REACH_LIMIT_EXTENSION: 0
}

@Injectable({
  providedIn: 'root'
})
export class AdFormManagerService implements OnInit {
  public targetingStats: PostMonitorBackend_V2ModelsvkVKAPIAdTargetingStats;

  public promopostLabelTextForm = new PostificatePipe().transform('Внимание! Рекламная запись должна содержать не более 6 эмодзи. Например: 🔥😏📅💃💪👁😱😈 или другие.');
  public budget: number = 0;
  public targetingStatsLoading: boolean = true;
  public strategyAction: StrategyAction = StrategyAction.DO_NOTHING;
  public searchGroupsCities$: Observable<Array<Group>> = null;
  public targetingGroups: Array<PostMonitorBackend_V2ModelsvkVKAPIAdTargetGroup>;

  public errors = {
    format: 'Вы не выбрали формат объявления',
    teazer: 'Вы не ввели данные при создании объвления',
    promopost: 'Вы не ввели данные при создании объвления',
    targetingData: 'Вы не выбрали кому показывать объявление',
    campaignData: 'Вы не выбрали где сохранить объявление',
    rate: 'Вы не выбрали стоимость и способ показа объявления',
    moderationSettings: 'Вы не выбрали способ запуска и сохранения объявления'
  };
  public loadingProgress = 0;
  public isPro = false;

  public PROMOPOST_CAMPAIGN_NAME = 'Кампания PostMonitor - Запись в новостной ленте';
  public TEAZER_CAMPAIGN_NAME = 'Кампания PostMonitor - Объявления на страницах ВКонтакте';
  private adName: string = '';
  public adDataLoading: boolean = false;

  public accountId: number;
  public clientId: number;
  public campaignId: number;
  public adId: number;

  public promopostWallLink = '';
  public IsUpdate: boolean = false;
  public strategyCreate: boolean = true;
  private PostId: number = null;
  private RemoveStrategy: boolean = false;
  private rates: Array<BidRange> = [];
  private hasStrategy: boolean = false;
  public assignAccountLoadingLite = false;

  public get selectedGroup() {
    const promopostGroupId = this.getControl('promopost', 'groupId').value;
    let group = null;
    if (this.adminGroups && this.adminGroups.length > 0) {
      group = this.adminGroups.find(adminGroup => adminGroup['id'] === parseInt(promopostGroupId, 10));
    }
    return group;
  }

  public get teazers(): Array<TeazerViewModel> {
    const teazers = [];
    const teazerTexts = this.getControl('teazer', 'text').value;
    const teazerImages = this.getControl('teazer', 'image').value;
    const teazerLink = this.getControl('teazer', 'linkAddress').value;
    for (let i = 0; i < teazerTexts.length; i++) {
      const teazer = new TeazerViewModel(teazerImages[i], teazerTexts[i], teazerLink);
      teazers.push(teazer);
    }
    return teazers;
  }

  public get MinRateValue() {
    const adFormat = this.currentFormat().ad_format;
    const rate = this.rates.find(x => x.adFormat === adFormat);
    return rate ? rate.cpmMin / 100 : 0;
  }

  public get MaxRateValue() {
    const adFormat = this.currentFormat().ad_format;
    const rate = this.rates.find(x => x.adFormat === adFormat);
    return rate ? rate.cpmMax / 100 : 100;
  }

  public get agesFrom() {
    const ageTo = this.getControl('targetingData', 'ageTo').value;
    let ages = this.ages;
    if (ageTo > 0) {
      ages = ages.filter(age => age <= ageTo);
    }
    return ages;
  }

  public get agesTo() {
    const ageFrom = this.getControl('targetingData', 'ageFrom').value;
    let ages = this.ages;
    if (ageFrom > 0) {
      ages = ages.filter(age => age >= ageFrom);
    }
    return ages;
  }

  public get promoposts(): Array<PromopostViewModel> {
    const teazers = [];
    const teazerTexts = this.adForm.controls.promopost['controls'].text.value;
    const teazerImages = this.adForm.controls.promopost['controls'].image.value;
    const teazerLink = this.adForm.controls.promopost['controls'].linkAddress.value;
    const teazerGroup = this.adForm.controls.promopost['controls'].groupId.value;
    const teazerTitle = this.adForm.controls.promopost['controls'].linkTitle.value;

    for (let i = 0; i < teazerTexts.length; i++) {
      const promopost = new PromopostViewModel();
      promopost.groupId = teazerGroup;
      promopost.image = teazerImages[i];
      promopost.linkAddress = teazerLink;
      promopost.linkTitle = teazerTitle;
      promopost.text = teazerTexts[i];
      teazers.push(promopost);
    }

    return teazers;
  }

  public get SelectedAdFormat(): AdFormatViewModel {
    const currentAdFormat = this.adForm.controls.format.value;
    const selectedFormat = this.adFormats.find(format => format.value === currentAdFormat);
    return selectedFormat;
  }

  public get PromopostTextControls() {
    return ((this.adForm.controls.promopost as FormGroup).controls.text as FormArray).controls;
  }

  public get IsSelectFormatPromopost() {
    const formatControl = this.getControl('format');
    return formatControl.value === 'promopost';
  }

  public get displayedCities() {
    let cities = this.cities;
    const searchString = this.searchCitiesControl.value;
    if (searchString !== '' && searchString !== null) {
      cities = cities.filter(city => {
        return city.title.toLowerCase().match(searchString.toLowerCase());
      });
    }
    return cities;
  }

  public get searchGroupsDisplayCities() {
    const {title, countryId} = this.searchGroupsForm.value;
    return this.cities.filter(city => city.title.includes(title));
  }

  constructor(
    private adManagerService: AdManagerService,
    private automationVkService: AutomationVkService,
    private automationVkSlotService: AutomationVkSlotService,
    private userStrategyService: UserStrategiesService,
    private router: Router,
    private route: ActivatedRoute,
    private logger: LoggingService
  ) {
    this.LoadBidRanges();
    this.adName = `Объявление ${moment(new Date()).format('DD.MM.YYYY HH:mm:ss')}`;
    this.adForm = new FormGroup({
      format: new FormControl('promopost'),
      promopost: new FormGroup({
        text: new FormArray([
          new FormControl('', [
            Validators.required,
            Validators.minLength(1),
            Validators.maxLength(600),
            emojiCount
          ])
        ]),
        image: new FormControl([], [Validators.required]),
        groupId: new FormControl(null, [Validators.required]),
        linkAddress: new FormControl(null, [Validators.required, url]),
        linkTitle: new FormControl(null, [Validators.required]),
        name: new FormControl(this.adName),
      }),
      teazer: new FormGroup({
        text: new FormArray([
          new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(33)])
        ]),
        image: new FormControl([], [Validators.required]),
        linkAddress: new FormControl('', [Validators.required, url]),
        groupId: new FormControl(),
        name: new FormControl(this.adName),
      }),
      targetingData: new FormGroup({
        country: new FormControl(1),
        cities: new FormControl([0]),
        groups: new FormControl(''),
        ageFrom: new FormControl(0),
        ageTo: new FormControl(0),
        sex: new FormControl(0),
        targetGroups: new FormControl([]),
        targetGroupsNot: new FormControl([]),
        auditory: new FormControl(0, [Validators.required, Validators.min(100)])
      }),
      campaignData: new FormGroup({
        accountId: new FormControl(null, [Validators.required]),
        campaignId: new FormControl(null, [Validators.required])
      }),
      rateData: new FormGroup({
        cpm: new FormControl(),
        strategyValue: new FormGroup({
          ECPC_LIMIT: new FormControl(null),
          CUSTOM_OVERALL_LIMIT: new FormControl(null),
          ECPC_LIMIT_EXTENSION: new FormControl('0'),
          IMPRESSION_LIMIT: new FormControl(null),
          IMPRESSION_LIMIT_EXTENSION: new FormControl('0'),
          REACH_LIMIT: new FormControl(null),
          REACH_LIMIT_EXTENSION: new FormControl('0')
        }),
        mode: new FormControl('auto')
      }),
      moderationData: new FormGroup({
        sendModeration: new FormControl(false),
        startAfterModeration: new FormControl(false)
      })
    });

    this.searchGroupsForm = new FormGroup({
      title: new FormControl('', [Validators.required, Validators.minLength(1)]),
      countryId: new FormControl(0),
      cityId: new FormControl(0)
    });
    console.log('INIT DATA constructor');
    this.InitAdData();
    this.subscribeToCitiesSearchStirng();
    this.subscribeSearchGroupForm();
    this.subscribeFormEvents();
    this.subscribeTargetingForm();
    this.subscribeAccountForm();
    this.subscribeFormatChange();
    this.subscribeRateModeData();
  }

  public get link() {
    const format = this.getControl('format').value;
    const linkControl = this.getControl(format, 'linkAddress');
    return linkControl.value || '';
  }

  public adForm: FormGroup = new FormGroup({});
  public searchGroupsForm: FormGroup;
  public adminGroups: Array<PostMonitorBackend_V2ModelsvkVKAPIGroupModel> = [];
  public searchCitiesControl = new FormControl();
  public searchGroupsCity = new FormControl();
  public loadingCreateAd: boolean = false;
  public loadingSearchGroups: boolean = false;

  public modifyFunctions = {
    BID_MIN: (value) => parseFloat(value) * 100,
    BID_START: (value) => parseFloat(value) * 100,
    BID_MAX: (value) => parseFloat(value) * 100,
    BID_STEP: (value) => parseFloat(value) * 100,
    ECPC_LIMIT: (value) => parseFloat(value) * 100,
  };

  public displayFunctions = {
    BID_MIN: (value) => parseFloat(value) / 100,
    BID_MAX: (value) => parseFloat(value) / 100,
    BID_START: (value) => parseFloat(value) / 100,
    BID_STEP: (value) => parseFloat(value) / 100,
    ECPC_LIMIT: (value) => parseFloat(value) / 100,
  };

  public groupToggled = new Subject();

  public selectedGroups = [];

  public ages = environment.vkAges;

  public adFormats = [
    {
      title: 'Рекламная запись в новостной ленте',
      description: 'Объявление будет показываться в ВКонтакте в новостной ленте, на стенах сообществ и в рекламной сети.',
      image: 'assets/images/promopost.jpg',
      selected: true,
      value: 'promopost',
      ad_format: 9,
      min_cpm: 30,
      ad_platform: 'all',
      type: 'promoted_posts',
      preview_image: 'assets/images/ad-format-preview-promopost.jpg'
    },
    {
      title: 'Рекламное объявление на страницах ВКонтакте',
      description: 'Объявление будет показываться в ВКонтакте в левом рекламном блоке',
      image: 'assets/images/ad_teazer.jpg',
      selected: false,
      value: 'teazer',
      ad_format: 2,
      min_cpm: 1.2,
      ad_platform: '0',
      type: 'normal',
      preview_image: 'assets/images/ad-format-preview-teazer.jpg'
    },
  ];

  public countries: Array<PostMonitorBackend_V2ModelsvkVKAPICountryModel> = [];
  public cities: Array<PostMonitorBackend_V2ModelsvkVKAPICityModel> = [];
  public groups: Array<PostMonitorBackend_V2ModelsvkVKAPIGroupModel> = [];
  public slots: Array<UserAutomationCabinetSlot> = [];
  public accounts: Array<AccountViewModel> = [];
  public campaigns: Array<Campaign> = [];
  public progressStep = 0;

  /**
   * Задает текущий шаг процесса
   * @param {number} progressStep
   * @constructor
   */
  public SetLoadingProgress(progressStep: number) {
    const maxSteps = this.IsUpdate ? 10 : 6;
    this.progressStep = progressStep;
    this.loadingProgress = progressStep / maxSteps * 100;
  }

  /**
   * Текущее сообщение для шага экрана загрузки
   * @returns {string}
   * @constructor
   */
  public get LoadingStepMessage() {
    let message = '';
    switch (this.progressStep) {
      case 0:
        message = 'Загрузка ваших групп';
        break;
      case 1:
        message = 'Загрузка стран';
        break;
      case 2:
        message = 'Загрузка городов и регионов';
        break;
      case 3:
        message = 'Загрузка слотов';
        break;
      case 4:
        message = 'Загрузка кабинетов Вконтакте';
        break;
      case 5:
        message = 'Загрузка кампаний';
        break;
      case 6:
        message = 'Загрузка баланса кабиета ВКонтакте';
        break;
      case 7:
        message = 'Загрузка объявления';
        break;
      case 8:
        message = 'Загрузка внешнего вида объявления';
        break;
      case 9:
        message = 'Загрузка внешнего вида объявления';
        break;
      case 10:
        message = 'Загрузка настроек таргета';
        break;
    }

    return message;
  }

  /**
   * Текущий выбранный формат рекламной записи
   * @returns {{ad_format: number; image: string; preview_image: string; ad_platform: string; description: string; title: string; type: string; value: string; min_cpm: number; selected: boolean}}
   */
  public currentFormat() {
    const format = this.getControl('format').value;
    return this.adFormats.find(formatItem => formatItem.value === format);
  }

  /**
   * Кампании которые должны быть показаны на экране
   * @returns {Campaign[]}
   */
  public get displayedCampaigns() {
    return this.campaigns.filter(campaign => this.currentFormat().type === campaign.type);
  }

  /**
   * Возвращает спецификации для создания кабинетов ВКонтакте
   * @returns {any[]}
   */
  public getSpecifications() {
    const value = this.adForm.value;
    const adLayouts = this.currentFormat().ad_format === 9 ? this.promoposts : this.teazers;
    const specifications = [];
    if (this.currentFormat().ad_format === 9) {
      for (const ad of this.promoposts) {
        specifications.push(this.createPromopostSpecification(ad, value, ad.text, ad.image));
      }
    } else {
      for (const ad of this.teazers) {
        specifications.push(this.createTeazerSpecification(ad, value, ad.title, ad.image));
      }
    }

    return specifications;
  }

  /**
   * Подключение к отслеживанию изменения в форме кампании
   */
  public subscribeAccountForm() {
    const control = this.getControl('campaignData', 'accountId');
    control.valueChanges.subscribe(data => {
      console.log(data, 'STARTING CAMPAIGN LOADING');
      if (data) {
        this.LoadCampaigns()
          .pipe(
            flatMap(() => this.LoadAdTargetGroups()),
            flatMap(() => this.LoadTargetingStats())
          )
          .subscribe();
      }
    });
  }

  private createPromopostSpecification(promopost: PromopostViewModel, formValue, promopostText, promopostImage) {
    const [accountId, clientId] = formValue.campaignData.accountId.split('_');
    let postId = null;
    if (this.IsUpdate) {
      postId = this.PostId;
    }
    console.log(postId, 'POST ID');
    return {
      linkTitle: formValue.promopost.linkTitle,
      linkUrl: formValue.promopost.linkAddress,
      accountId,
      clientId: (clientId === 'null') ? 0 : clientId,
      text: promopostText.replace('%', '%25'),
      groupId: formValue.promopost.groupId,
      postId,
      adSpecifications: [
        {
          ad_id: this.adId,
          ad_format: this.currentFormat().ad_format,
          age_from: formValue.targetingData.ageFrom,
          age_to: formValue.targetingData.ageTo,
          campaign_id: formValue.campaignData.campaignId,
          category1_id: 126,
          cities: formValue.targetingData.cities.join(),
          country: formValue.targetingData.country === 'null' ? 0 : formValue.targetingData.country,
          cpm: formValue.rateData.cpm,
          cost_type: 1,
          impressions_limited: (this.currentFormat().ad_format === 2) ? 1 : null,
          impressions_limit: (this.currentFormat().ad_format === 9) ? 1 : null,
          groups: formValue.targetingData.groups,
          image: promopostImage,
          title: promopostText.replace('%', '%25'),
          link_url: formValue.teazer.linkAddress,
          link_domain: new DomainOnlyPipe().transform(formValue.teazer.linkAddress),
          name: formValue.promopost.name,
          sex: formValue.targetingData.sex,
          retargeting_groups: formValue.targetingData.targetGroups !== null && formValue.targetingData.targetGroups.length > 0
            ? formValue.targetingData.targetGroups.filter(x => x > 0).join()
            : 'null',
          retargeting_groups_not: formValue.targetingData.targetGroupsNot !== null && formValue.targetingData.targetGroupsNot.length > 0
            ? formValue.targetingData.targetGroupsNot.filter(x => x > 0).join()
            : 'null'
        }
      ]
    };
  }

  private createTeazerSpecification(teazer: TeazerViewModel, formValue, teazerText, teazerImage): PostMonitorBackend_V2ModelsAdManagerCreateAdViewModel {
    const [accountId, clientId] = formValue.campaignData.accountId.split('_');
    return {
      linkTitle: '',
      linkUrl: formValue.promopost.linkAddress,
      accountId,
      clientId: (clientId === 'null') ? 0 : clientId,
      text: '',
      groupId: 0,
      adSpecifications: [
        ({
          ad_id: this.adId,
          ad_format: this.currentFormat().ad_format,
          age_from: formValue.targetingData.ageFrom,
          age_to: formValue.targetingData.ageTo,
          campaign_id: formValue.campaignData.campaignId,
          category1_id: 126,
          cities: formValue.targetingData.cities.join(),
          country: formValue.targetingData.country === 'null' ? 0 : formValue.targetingData.country,
          cpm: formValue.rateData.cpm,
          cost_type: 1,
          impressions_limited: (formValue.format.ad_format === 2) ? 1 : null,
          impressions_limit: (formValue.format.ad_format === 9) ? 1 : null,
          groups: formValue.targetingData.groups,
          image: teazerImage,
          title: teazerText,
          link_url: formValue.teazer.linkAddress,
          link_domain: new DomainOnlyPipe().transform(formValue.teazer.linkAddress),
          name: formValue.promopost.name,
          retargeting_groups: formValue.targetingData.targetGroups !== null ? formValue.targetingData.targetGroups.filter(x => x > 0).join() : '',
          retargeting_groups_not: formValue.targetingData.targetGroups !== null ? formValue.targetingData.targetGroupsNot.filter(x => x > 0).join() : '',
        } as any)
      ]
    };
  } // 1012179702 - 1011699351

  private subscribeSearchGroupForm() {
    this.searchGroupsForm.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(data => {
        if (this.searchGroupsForm.valid) {
          this.searchGroups();
        }
      });
    this.searchGroupsForm.controls.countryId
      .valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(data => {
        const countryId = parseInt(data, 10);
        if (countryId > 0) {
          this.searchGroupsCities$ = this.LoadCities(data);
        } else {
          this.searchGroupsCities$ = of([]);
        }
      });

    this.searchGroupsCity.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(data => {
        this.searchGroupsCities$ = this.LoadCities(this.searchGroupsForm.controls.countryId.value, data);
      });
  }

  private getLinkDomain(link) {
    return new DomainOnlyPipe().transform(link);
  }

  public IsSearchGroupTitleValid() {
    return this.searchGroupsForm.controls.title.value.length > 0;
  }

  public IsSearchGroupCountryValid() {
    const countryControl = this.searchGroupsForm.controls.countryId;
    if (countryControl) {
      return countryControl.value !== 0;
    }
    return false;
  }

  private subscribeToCitiesSearchStirng() {
    this.searchCitiesControl
      .valueChanges
      .pipe(
        distinctUntilChanged(),
        debounceTime(500),
        flatMap((searchString) => this.searchCities(searchString))
      )
      .subscribe((searchString => {
      }));
  }

  private subscribeFormEvents() {
    const promopostGroupControl = this.getControl('promopost', 'groupId');
    const teazerGroupControl = this.getControl('teazer', 'groupId');

    promopostGroupControl.valueChanges.subscribe(this.onChangeGroupId.bind(this));
    teazerGroupControl.valueChanges.subscribe(this.onChangeGroupId.bind(this));
  }

  public onChangeGroupId(groupId) {
    if (this.selectedGroup) {
      const selectedGroupAddress = `https://vk.com/${this.selectedGroup.screen_name}`;
      this.setLinkUrls(selectedGroupAddress);
    }
  }

  public setLinkUrls(linkUrl) {
    const promopostLinkAddressControl = this.getControl('promopost', 'linkAddress');
    const teazerLinkAddressControl = this.getControl('teazer', 'linkAddress');

    if (teazerLinkAddressControl.value !== '') {
      teazerLinkAddressControl.setValue(linkUrl, {emitEvent: false});
    }

    if (promopostLinkAddressControl.value !== '') {
      promopostLinkAddressControl.setValue(linkUrl, {emitEvent: false});
    }
  }

  public SelectFormat(format: any) {
    this.adForm.controls.format.setValue(format);
  }

  public LoadAdminGroups(): Observable<any> {
    return this.adManagerService.GetAdminGroups()
      .pipe(
        tap(groupsResponse => {
          this.adminGroups = groupsResponse;
          this.logger.AddBreadcrumb('Администрируемые группы загружены', {countOfGroups: this.adminGroups.length});
          if (this.adminGroups && this.adminGroups.length > 0) {
            const groupId = this.adminGroups[0]['id'];
            this.setGroupId(groupId);
          }
          this.SetLoadingProgress(1);
        }),
        catchError(err => {
          this.logger.SetTag('Operation', 'LoadAdminGroups');
          this.logger.AddExtra('ErrorData', err);
          this.logger.Error('Loading Admin Groups Error', err);
          return throwError(err);
        }),
        delay(1000)
      );
  }

  public LoadCountries() {
    return this.adManagerService.GetCountries()
      .pipe(
        tap((countriesResponse: PostMonitorBackend_V2ModelsvkVKAPIGetCountriesResponse) => {
          this.logger.AddBreadcrumb('Страны загружены');
          this.countries = countriesResponse.items;
          this.SetLoadingProgress(2);
        }),
        catchError(err => {
          this.logger.SetTag('Operation', 'LoadCountries');
          this.logger.AddExtra('ErrorData', err);
          this.logger.Error('Loading Countries Error', err);
          return throwError(err);
        }),
        delay(1000)
      );
  }

  public LoadCities(countryId: number, cityQuery: string = null) {
    return this.adManagerService.GetCities({countryId, searchString: cityQuery})
      .pipe(
        tap((citiesResponse: any) => {
          citiesResponse.forEach(city => {
            this.logger.AddBreadcrumb('Города загружены');
            if (!this.cities.map(x => x.id).includes(city.id)) {
              this.cities.push(city);
            } else {
              const city1 = this.cities.find(x => x.id === city.id);
              if (city1 && city1.region === null) {
                const cityIndex = this.cities.map(cityItem => cityItem.id).indexOf(city1.id);
                this.cities[cityIndex] = city;
              }
            }
          });
        }),
        catchError(err => {
          this.logger.SetTag('Operation', 'LoadCities');
          this.logger.AddExtra('ErrorData', err);
          this.logger.Error('Loading Cities Error', err);
          return throwError(err);
        }),
        delay(1000)
      );
  }

  public LoadData(): Observable<any> {
    console.log('LOADING DATA');
    this.adDataLoading = true;
    return this.LoadAdminGroups()
      .pipe(
        flatMap(() => this.LoadCountries()),
        flatMap(() => this.LoadCities(this.countries[0].id)),
        flatMap(() => this.LoadAdCabinetData()),
        flatMap(() => this.LoadFullAdData()),
        flatMap(() => this.LoadAdTargetGroups()),
        flatMap(() => this.LoadTargetingStats()),
        tap(() => {
          console.log('DATA LOADING ???');
          this.adDataLoading = false;
        }),
      );
  }

  public searchCities(searchCityName) {
    const countryControl = this.getControl('targetingData', 'country');
    return this.LoadCities(countryControl.value, searchCityName);
  }

  public getControl(...args: Array<string>) {
    const firstControlName = args.shift();
    let control = this.adForm.controls[firstControlName];
    for (const controlName of args) {
      if (control) {
        control = control.get(controlName);
      }
    }
    return control;
  }

  public setGroupId(groupId: number) {
    const promopostGroupIdControl = this.getControl('promopost', 'groupId');
    const teazerGroupIdControl = this.getControl('teazer', 'groupId');

    teazerGroupIdControl.setValue(groupId);
    promopostGroupIdControl.setValue(groupId);
  }

  public searchGroups() {
    this.loadingSearchGroups = true;
    console.log(this.searchGroupsForm.value);
    this.adManagerService.SearchGroups(this.searchGroupsForm.value)
      .subscribe(groupsResponse => {
        this.groups = groupsResponse;
        this.loadingSearchGroups = false;
      });
  }

  public toggleSelectedRow(group) {
    const index = this.selectedGroups.indexOf(group);
    console.log(index, 'SELECTED GROUP');
    if (index >= 0) {
      this.selectedGroups = this.selectedGroups.filter((x, i) => i !== index);
    } else {
      this.selectedGroups.push(group);
    }
    this.groupToggled.next();
    const control = this.getControl('targetingData', 'groups');
    control.setValue(this.selectedGroups.map(x => x.id).join());
  }

  public toggleSelectedAll() {
  }

  public LoadTargetingStats() {
    console.log('LOADING FUCKING TARGET ');
    this.targetingStatsLoading = true;
    const accountControl = this.getControl('campaignData', 'accountId');
    const targetingAuditoryControl = this.getControl('targetingData', 'auditory');
    if (!isNullOrUndefined(accountControl.value)) {
      const [accountId, clientId] = accountControl.value.split('_').map(x => {
        if (!isNullOrUndefined(x)) {
          x = parseInt(x, 10);
        }
        return x;
      });
      const adTargeting = this.getControl('targetingData').value;
      const adFormat = this.currentFormat();
      const linkUrl = this.getLinkUrl();
      console.log(linkUrl, 'FUCKING URL');
      if (linkUrl !== '' && linkUrl !== null) {
        console.log(adTargeting);
        return this.adManagerService.GetTargetingStats({
          accountId,
          adFormat: adFormat.ad_format,
          clientId,
          criteria: {
            age_from: adTargeting.ageFrom,
            age_to: adTargeting.ageTo,
            sex: parseInt(adTargeting.sex, 10),
            groups: adTargeting.groups,
            country: adTargeting.country === 'null' ? 0 : adTargeting.country,
            cities: adTargeting.cities.filter(x => x > 0).join(','),
            retargeting_groups: adTargeting.targetGroups !== null ? adTargeting.targetGroups.filter(x => x > 0).join() : '',
            retargeting_groups_not: adTargeting.targetGroupsNot !== null ? adTargeting.targetGroupsNot.filter(x => x > 0).join() : ''
          },
          linkDomain: this.getLinkDomain(this.link),
          linkUrl: this.link
        })
          .pipe(
            tap((targetingStatsResponse) => {
              console.log('TARGET LOADED');
              this.targetingStats = targetingStatsResponse;
              targetingAuditoryControl.setValue(targetingStatsResponse.audience_count, {emitEvent: false});
              this.targetingStatsLoading = false;
            }),
            catchError(err => {
              this.logger.SetTag('Operation', 'LoadingTargetingStats');
              this.logger.AddExtra('ErrorData', err);
              this.logger.Error('Loading Targeting Data Error', err);
              return throwError(err);
            }),
          );
      } else {
        return of({});
      }

    } else {
      return of({});
    }
  }

  public getLinkUrl() {
    let linkUrl = '';
    let linkAddressControl = null;
    if (this.IsSelectFormatPromopost) {
      linkAddressControl = this.getControl('promopost', 'linkAddress');
    } else {
      linkAddressControl = this.getControl('teazer', 'linkAddress');
    }
    linkUrl = linkAddressControl.value;
    return linkUrl;
  }

  public LoadAdCabinetData() {
    return this.LoadSlots()
      .pipe(
        flatMap(() => this.LoadAccounts()),
        flatMap(() => this.LoadClients()),
        flatMap(() => this.LoadCampaigns()),
        delay(2000),
        flatMap(() => this.LoadCampaigns()),
        flatMap(() => this.LoadUserBudget()),
        catchError(err => {
          this.logger.SetTag('Operation', 'LoadAdCabinetData');
          this.logger.AddExtra('ErrorData', err);
          this.logger.Error('Loading Ad Cabinet Data Error', err);
          return throwError(err);
        }),
        share(),
      );
  }

  public LoadSlots() {
    return this.automationVkSlotService.GetSlots()
      .pipe(
        tap((slotsResponse) => {
          this.slots = slotsResponse.data;
          this.SetLoadingProgress(3);
        }),
        catchError(err => {
          this.logger.SetTag('Operation', 'LoadSlots');
          this.logger.AddExtra('ErrorData', err);
          this.logger.Error('Loading System Slots Error', err);
          return throwError(err);
        }),
        delay(1000)
      );
  }

  public SetCampaign(campaignId) {
    const campaignControl = this.getControl('campaignData', 'campaignId');
    campaignControl.setValue(campaignId, {emitEvent: false});
  }

  public GetAccountId(account: AccountViewModel) {
    return `${account.account_id}_${account.client_id}`;
  }

  public LoadAccounts() {
    this.assignAccountLoadingLite = true;
    return this.automationVkService.GetAccounts()
      .pipe(
        share(),
        tap((accountsResponse: any) => {
          this.accounts = accountsResponse.data.map(({account_id, client_id, access_role, account_name, account_status, account_type, is_blocked}, i) => {
            this.logger.AddBreadcrumb('Страны загружены');
            return this.CreateNewAccountViewModel(client_id, account_id, account_name, access_role, account_status, account_type, is_blocked, i);
          });
        }),
        tap(() => {
          let account = null;
          const accounts = this.GetAccountsAssignedToSystem();
          if (this.accountId) {
            account = accounts.find(x => x.account_id === this.accountId && x.client_id === this.clientId);
          } else {
            account = accounts.find(x => x.is_blocked === false);
          }
          if (account) {
            this.setCurrentAccount(account);
          }
          this.SetLoadingProgress(4);
        }),
        catchError(err => {
          this.logger.SetTag('Operation', 'LoadAccounts');
          this.logger.AddExtra('ErrorData', err);
          this.logger.Error('Loading Accounts Error', err);
          return throwError(err);
        }),
        flatMap(() => this.AssignAdminAccountToSystem()),
        delay(1000)
      );
  }

  private AssignAdminAccountToSystem() {
    console.log('ASSIGN ACCOUNTS');
    let result = null;
    console.log(this.isPro);
    if (!this.isPro) {
      const adminAccount = this.GetAdminAccount();
      if (adminAccount) {
        const isAccountAssigned = this.IsAccountAssigned(adminAccount);
        if (!isAccountAssigned) {
          this.assignAccountLoadingLite = true;
          result = this.automationVkSlotService.AssignCabinetToSlot({
            id: adminAccount.account_id,
            clientId: adminAccount.client_id
          })
            .pipe(
              delay(1000),
              catchError(err => {
                this.logger.SetTag('Operation', 'Loading');
                this.logger.AddExtra('ErrorData', err);
                this.logger.Error('Loading Admin Groups Error', err);
                return throwError(err);
              }),
              flatMap(() => this.LoadAdCabinetData()),
              delay(1000),
              flatMap(() => this.checkForSystemCampaignsAndCreateIfNotExitis()),
              delay(1000),
              flatMap(() => this.LoadTargetingStats()),
              delay(1000),
              tap(() => {
                this.assignAccountLoadingLite = false;
              })
            )
        } else {
          result = of({});
        }
      } else {
        // this.assignAccountLoadingLite = false;
        this.logger.SetTag('Кабинет администратора', 'Отсутствует');
        result = of({});
      }
    } else {
      // this.assignAccountLoadingLite = false;
      result = of({});
    }
    // this.assignAccountLoadingLite = false;
    return result;
  }

  private GetAdminAccount() {
    return this.accounts.find(account => account.account_role === 'admin');
  }

  private IsAccountAssigned(account: AccountViewModel) {
    const systemSlot = this.slots.find(slot => slot.bindedCabinetId === account.account_id && slot.bindedClientId === account.client_id);
    return !isNullOrUndefined(systemSlot);
  }

  private CreateNewAccountViewModel(client_id, account_id, account_name, access_role, account_status, account_type, is_blocked, i) {
    const slots = this.slots.find(slotsItem => slotsItem.bindedClientId === client_id && slotsItem.bindedCabinetId === account_id);
    return new AccountViewModel(
      account_id,
      account_name,
      access_role,
      account_status,
      account_type,
      (isNullOrUndefined(slots)) ? true : is_blocked,
      client_id,
      i
    );
  }

  public GetAccountsAssignedToSystem() {
    const accountsBindedToAnySlots = this.accounts.filter(account => this.IsAccountAssignedToAnySlot(account));
    return accountsBindedToAnySlots;
  }

  public IsAccountAssignedToAnySlot(account) {
    const slot = this.slots.find(slot => this.IsAccountAssignedToSlot(slot, account));
    const isAccountAssignedToSlot = !isNullOrUndefined(slot);
    return isAccountAssignedToSlot;
  }

  public IsAccountAssignedToSlot(slot, account): boolean {
    const assignedSlot = slot.bindedCabinetId === account.account_id && slot.bindedClientId === account.client_id;
    return assignedSlot;
  }

  public setCurrentAccount(account) {
    const accountIdControl = this.getControl('campaignData', 'accountId');
    accountIdControl.setValue(this.GetAccountId(account), {emitEvent: false});
  }

  public LoadCampaigns() {
    const accountData = this.getControl('campaignData', 'accountId').value;
    if (!isNullOrUndefined(accountData)) {
      const [accountId, clientId] = accountData.split('_').map(x => {
        if (!isNullOrUndefined(x)) {
          x = parseInt(x, 10);
        }
        return x;
      });

      return this.automationVkService.GetCampaigns({
        accountId,
        clientId
      })
        .pipe(
          tap((campaignsResponse) => {
            this.campaigns = campaignsResponse.data.sort((a, b) => b.id - a.id);
          }),
          catchError(err => {
            this.logger.SetTag('Operation', 'Loading Campaigns');
            this.logger.AddExtra('ErrorData', err);
            this.logger.Error('Loading Campaigns Error', err);
            return throwError(err);
          }),
          tap(() => {
            this.SelectCampaign();
            this.SetLoadingProgress(4);
          }),
          delay(1000)
        );
    } else {
      return of({});
    }
  }

  private SelectCampaign() {
    if (this.campaigns && this.campaigns.length > 0) {
      if (this.isPro) {
        let campaignId = null;
        if (this.IsUpdate) {
          campaignId = this.campaignId;
        } else {
          if (this.displayedCampaigns.length > 0) {
            campaignId = this.displayedCampaigns[0].id;
          }
        }
        this.SetCampaign(campaignId);
      } else {
        const campaign = this.getSystemCampaign();
        if (campaign) {
          this.SetCampaign(campaign.id);
        }
      }
    } else {
    }
  }

  public setCurrentCampaign(campaigns) {
    if (this.isPro) {

    } else {

    }
  }

  private subscribeTargetingForm() {
    const targetingForm = this.getControl('targetingData');
    const format = this.getControl('format');
    const citiesFormatControl = this.getControl('targetingData', 'cities');

    citiesFormatControl.valueChanges.subscribe(data => {
      if (data) {
        if (data[0] === 0) {
          citiesFormatControl.setValue([0], {emitEvent: false});
        }

        if (data.length > 1) {
          citiesFormatControl.setValue(data.filter(val => val > 0), {emitEvent: false});
        }

        if (data.length === 0) {
          citiesFormatControl.setValue([0], {emitEvent: false});
        }
      }
    });

    merge(
      targetingForm.valueChanges,
      format.valueChanges
    )
      .pipe(
        debounceTime(1000),
        distinctUntilChanged()
      )
      .subscribe(() => {
        this.LoadTargetingStats().subscribe();
      });
  }

  public IsSearchGroupsCountryDisabled() {
    const searchQueryTextControl = this.searchGroupsForm.controls.title;
    return searchQueryTextControl.value === '';
  }

  public IsSearchCitiesDisabled() {
    const searchQueryCountryControl = this.searchGroupsForm.controls.title;
    return isNullOrUndefined(searchQueryCountryControl.value);
  }

  public AddPromopostImage(image: string) {
    const promopostImageControl = this.getControl('promopost', 'image');
    const images = promopostImageControl.value;
    images.push(image);
    promopostImageControl.setValue(image);
  }

  public AddTeazerImage(image: string) {
    const teazerImageControl = this.getControl('teazer', 'image');
    const images = teazerImageControl.value;
    images.push(image);
    teazerImageControl.setValue(image);
  }

  public IsStrategyModeAuto() {
    const modeControl = this.getControl('rateData', 'mode');
    return modeControl.value === 'auto';
  }

  public LoadUserBudget() {
    const {accountId, clientId} = this.getAccountData();
    const loadBudget = this.isAccountAssigned(accountId, clientId);
    if (loadBudget) {
      return this.automationVkService.GetBudget({
        accountId,
        clientId
      })
        .pipe(
          tap(budgetResponse => {
            this.budget = budgetResponse.data;
            this.configureModerationGroup();
            this.SetLoadingProgress(5);
          }),
          catchError(err => {
            this.logger.SetTag('Operation', 'LoadUserBudget');
            this.logger.AddExtra('ErrorData', err);
            this.logger.Error('Loading User Budget Error', err);
            return throwError(err);
          }),
          delay(1000)
        );
    } else {
      return of({})
        .pipe(
          tap(() => {
            this.SetLoadingProgress(5);
          })
        );
    }
  }

  public isAccountAssigned(accountId, clientId) {
    return accountId !== null &&
      !isNullOrUndefined(this.slots.find(x => x.bindedCabinetId === accountId && x.bindedClientId === clientId));
  }

  public configureModerationGroup() {
    const sendModerationControl = this.getControl('moderationData', 'sendModeration');
    if (this.budget === 0 && this.IsSelectedAccountAdmin()) {
      sendModerationControl.disable();
    }
  }

  public CreateAd() {
    this.loadingCreateAd = true;
    return this.adManagerService.CreateAd(this.getSpecifications())
      .pipe(
        tap((data: any) => {
          const adResponse = data[0];
          if (adResponse.error_code) {
            throw new Error(adResponse.error_desc);
          }
        }),
        delay(1000),
        flatMap(ad => this.SendModeration(ad)),
        flatMap(ad => this.StartAfterModeration(ad)),
        flatMap(ad => this.CreateStrategy(ad)),
        map(ad => {
          this.NavigateToAd();
          this.loadingCreateAd = false;
        }, err => {
          this.loadingCreateAd = false;
          this.logger.AddExtra('Error data', err);
          this.logger.Error('Ошибка при создании объявления');
          console.log(err);
        })
      );
  }

  public UpdateAd() {
    this.loadingCreateAd = true;
    return this.adManagerService.UpdateAd(this.getSpecifications())
      .pipe(
        tap((data: any) => {
          const adResponse = data[0];
          if (adResponse.error_code) {
            throw new Error(adResponse.error_desc);
          }
        }),
        delay(1000),
        flatMap(ad => this.SendModeration(ad)),
        flatMap(ad => this.StartAfterModeration(ad)),
        flatMap(ad => this.UpdateStrategy(ad)),
        map(ad => {
          console.log('FINISH LOG', ad);
          this.NavigateToAd();
          this.loadingCreateAd = false;
        }, err => {
          console.log(err);
          this.logger.AddExtra('Error data', err);
          this.logger.Error('Ошибка при реактировании объявления');
          this.loadingCreateAd = false;
        })
      );
  }

  public UpdateStrategy(ad) {
    console.log('UPDATE STRATEGY');
    const strategyControl = this.getControl('rateData', 'strategyValue');
    const mode = this.getControl('rateData', 'mode').value;
    let result = null;
    console.log('FUCKING STRATEGY');
    console.log(this.isPro, this.strategyCreate, this.RemoveStrategy, this.hasStrategy);
    if (this.isPro) {
      console.log(this.strategyAction, 'ACTION');
      switch (this.strategyAction) {
        case StrategyAction.CREATE_STRATEGY:
          result = this.CreateStrategy(ad);
          break;
        case StrategyAction.UPDATE_STRATEGY:
          result = this.HandleUpdateStrategy(ad);
          break;
        case StrategyAction.REMOVE_STRATEGY:
          result = this.RemoveAdStrategy(ad);
          break;
        case StrategyAction.DO_NOTHING:
        default:
          result = of([]);
      }
//      if (!this.RemoveStrategy) {
//        if (this.strategyCreate) {
//          result = this.CreateStrategy(ad);
//        } else {
//          if (this.hasStrategy) {
//            result = this.HandleUpdateStrategy(ad);
//          } else {
//            result = of([]);
//          }
//        }
//
//      } else {
//        if (this.hasStrategy) {
//          result = this.RemoveAdStrategy(ad);
//        }
//      }
    } else {
      result = of([]);
    }

    return result;
  }

  public CreateStrategy(ad) {
    const accountIdValue = this.getControl('campaignData', 'accountId').value;
    const campaignId = this.getControl('campaignData', 'campaignId').value;
    const properties = this.getControl('rateData', 'strategyValue').value;
    const rateType = this.getControl('rateData', 'mode').value;

    const [accountId, clientId] = accountIdValue.split('_').map(x => x === 'null' ? 0 : parseInt(x, 10));
    let result = null;
    if (rateType === 'auto') {
      result = this.userStrategyService.CreateUserStrategy({
        accountId,
        clientId,
        ads: [ad[0].id],
        properties: Object.keys(properties)
          .map(key => ({key, value: properties[key] === null ? null : this.getRateStrategyValue(key, properties[key])}))
          .reduce((curr, {key, value}) => {
            console.log(key, value, 'KEY VALUE LOG');
            curr[key] = value || strategyDefaultValues[key];
            return curr;
          }, {}),
        strategyType: 'INTELEGENT_MARK_I_MOD_I',
        updateStep: 10
      });
    } else {
      result = of([]);
    }

    return result;
  }

  public SendModeration(adResponse: PostMonitorBackend_V2ModelsvkVKAPIUpdateAds) {
    console.log('SEND MODERATION');
    const accountIdValue = this.getControl('campaignData', 'accountId').value;
    const campaignId = this.getControl('campaignData', 'campaignId').value;
    const [accountId, clientId] = accountIdValue.split('_').map(x => x === 'null' ? null : parseInt(x, 10));
    const sendModeration = this.getControl('moderationData', 'sendModeration').value;
    if (sendModeration) {
      return this.userStrategyService.ToggleAdStatus({
        accountId,
        adIds: [adResponse[0].id],
        status: true
      })
        .pipe(
          delay(1000),
          map(() => adResponse)
        );
    } else {
      return of(adResponse);
    }
  }

  public NavigateToAd() {
    const {accountId, clientId} = this.getAccountData();
    const campaignId = this.getControl('campaignData', 'campaignId').value;

    if (this.isPro) {
      this.NavigateToProAds(accountId, clientId, campaignId);
    } else {
      this.NavigateToLiteAds();
    }
  }

  public NavigateToLiteAds() {
    this.router.navigate(['/automation/dashboard']);
  }

  private NavigateToProAds(accountId, clientId, campaignId) {
    this.router.navigate(['/automation/ads'], {
      queryParams: {
        account_id: accountId,
        client_id: clientId,
        campaign_id: campaignId
      }
    });
  }

  public StartAfterModeration(adResponse) {
    console.log('START AFTER MODERATION');
    const accountIdValue = this.getControl('campaignData', 'accountId').value;
    const campaignId = this.getControl('campaignData', 'campaignId').value;
    const [accountId, clientId] = accountIdValue.split('_').map(x => x === 'null' ? null : parseInt(x, 10));
    const sendModeration = this.getControl('moderationData', 'sendModeration').value;
    if (sendModeration) {
      return this.userStrategyService.ToggleAdStatus({
        accountId,
        adIds: [adResponse[0].id],
        status: false
      })
        .pipe(
          delay(1000),
          map(() => adResponse)
        );
    } else {
      return of(adResponse);
    }
  }

  public hasOpenedAdminGroups() {
    return this.adminGroups.length > 0;
  }

  public IsValidaAd() {
    if (this.IsSelectFormatPromopost) {
      return this.getControl('promopost').valid;
    } else {
      return this.getControl('teazer').valid;
    }
  }

  public IsValidTargeting() {
    const targetingFormGroup = this.getControl('targetingData');
    return targetingFormGroup.valid;
  }

  public getAccountData() {
    const accountControl = this.getControl('campaignData', 'accountId');
    if (accountControl.value !== null) {
      const [accountId, clientId] = accountControl.value.split('_').map(x => x === null || x === 'null' ? null : parseInt(x, 10));
      return {accountId, clientId};
    } else {
      return {accountId: null, clientId: null};
    }

  }

  public ShowActiveAccounts() {
    const slotsWithAssignedCabinets = this.slots.filter(slot => !isNullOrUndefined(slot.bindedCabinetId));
    return slotsWithAssignedCabinets.length === 0 || this.accounts.length === 0;
  }

  public IsValidCampaignData() {
    return this.getControl('campaignData').valid;
  }

  public IsValidRate() {
    return this.getControl('rateData').valid;
  }

  public getErrors() {
    const errors = [];
    if (!this.IsValidaAd()) {
      errors.push(this.errors.promopost);
    }
    if (!this.IsValidTargeting()) {
      errors.push(this.errors.targetingData);
    }
    if (!this.IsValidCampaignData()) {
      errors.push(this.errors.campaignData);
    }
    if (!this.IsValidRate()) {
      errors.push(this.errors.rate);
    }

    return errors;
  }

  public checkForSystemCampaigns() {
    const campaign = this.campaigns.find(c => c.name === this.GetSystemCampaignName());
    console.log(campaign);
    return !isNullOrUndefined(campaign);
  }

  public checkForSystemCampaignsAndCreateIfNotExitis(): Observable<any> {
    const exists = this.checkForSystemCampaigns();
    let res = null;
    if (exists) {
      res = of(null);
    } else {
      res = this.CreateSystemCampaign()
        .pipe(
          delay(1000)
        );
    }

    return res;
  }

  public CreateSystemCampaign() {
    console.log('CREATING ');
    const accountControl = this.getControl('campaignData', 'accountId');
    const {accountId, clientId} = this.getAccountData();
    return this.adManagerService.CreateCampaign({
      accountId: accountId,
      campaigns: [
        {
          name: this.GetSystemCampaignName(),
          client_id: clientId,
          type: this.currentFormat().type,
          status: 1
        }
      ]
    })
      .pipe(
        delay(1000),
        tap(data => {
          this.LoadCampaigns().subscribe();
        })
      );
  }

  private GetSystemCampaignName() {
    console.log(this.IsSelectFormatPromopost, 'IS PROMOPOST');
    if (this.IsSelectFormatPromopost) {
      return this.PROMOPOST_CAMPAIGN_NAME;
    } else {
      return this.TEAZER_CAMPAIGN_NAME;
    }
  }

  public getTeazerTextLength(i) {
    return this.getControl('teazer', 'text', i.toString()).value.length;
  }

  private getSystemCampaign() {
    return this.campaigns.find(c => c.name === this.GetSystemCampaignName());
  }

  public reset() {
    this.groups = [];
    this.adminGroups = [];
    this.IsUpdate = false;
    this.adForm.reset({
      format: 'promopost',
      promopost: {
        text: [''],
        image: [],
        name: this.adName
      },
      teazer: {
        text: [''],
        image: [],
        name: this.adName
      },
      targetingData: {
        cities: [0],
        country: 1,
        ageFrom: 0,
        ageTo: 0,
        groups: '',
        sex: 0,
        auditory: 0
      },
      rateData: {
        mode: 'auto',
        cpm: 30
      }
    });
    const moderationControl = this.getControl('moderationData', 'sendModeration');
    const startAfterModeration = this.getControl('moderationData', 'startAfterModeration');
    moderationControl.enable();
    startAfterModeration.enable();
    this.searchGroupsForm.reset({
      title: '',
      countryId: 0,
      cityId: 0
    }, {emitEvent: false});
    this.selectedGroups = [];
    this.groups = [];
  }

  public isGroupSelected(group) {
    return this.selectedGroups
      .map(x => x.id)
      .includes(group.id);
  }

  public IsAdLayoutDirty() {
    let status = null;
    if (this.IsSelectFormatPromopost) {
      status = this.IsPromopostDirty();
    } else {
      status = this.IsTeazerDirty();
    }
    return status;
  }

  public IsPromopostDirty() {
    const promopost = this.getControl('promopost');
    return promopost.dirty;
  }

  public IsTeazerDirty() {
    const teazerControl = this.getControl('teazer');
    return teazerControl.dirty;
  }

  private subscribeFormatChange() {
    const formatControl = this.getControl('format');
    formatControl.valueChanges.subscribe(format => {
      this.ChangeRateCpmValidators();
      this.SelectCampaign();
    });
  }

  private ChangeRateCpmValidators() {
    const rateCpmControl = this.getControl('rateData', 'cpm');
    rateCpmControl.setValidators([Validators.required, Validators.min(this.MinRateValue), Validators.max(this.MaxRateValue)]);
    console.log(this.MinRateValue, this.MaxRateValue, 'MIN MAX');
    rateCpmControl.setValue(this.currentFormat().min_cpm, {eventEmit: false});
  }

  public LoadBidRanges() {
    this.adManagerService.GetAdRates()
      .subscribe(rates => {
        this.rates = rates;
        this.ChangeRateCpmValidators();
      });
  }

  public LoadAdData() {
    return this.automationVkService.GetAds(this.getAdParams())
      .pipe(
        tap((adsResponse) => {
          if (this.CheckForAdData(adsResponse)) {
            const ad = adsResponse.data[0];
            const adFormat = this.adFormats.find(x => x.ad_format === ad.ad_format);
            this.adForm.patchValue({
              format: adFormat.value,
              teazer: {
                name: ad.name
              },
              promopost: {
                name: ad.name
              },
              rateData: {
                cpm: ad.cpm / 100,
              }
            }, {emitEvent: false});
          } else {
            this.ThrowNotFoundAdError();
          }
        }),
        tap(() => {
          this.SetLoadingProgress(6);
        }),
        catchError(err => {
          this.logger.SetTag('Operation', 'LoadAdData');
          this.logger.AddExtra('ErrorData', err);
          this.logger.Error('Loading Ad Error', err);
          return throwError(err);
        }),
        delay(2000)
      );
  }

  private ThrowNotFoundAdError() {
    throw new Error(`Ad with in account ${this.accountId} and campaign ${this.campaignId} with id ${this.adId} not found`);
  }

  private CheckForAdData(adsResponse) {
    return adsResponse.data && adsResponse.data.length > 0;
  }

  private getAdParams() {
    return {
      accountId: this.accountId,
      clientId: this.clientId === 0 ? null : this.clientId,
      campaignIds: [this.campaignId],
      adIds: [this.adId],
      offset: 0,
      limit: 0,
      includeDeleted: false
    };
  }

  public LoadAdLayout() {
    return this.automationVkService.GetAdsLayout(this.getAdParams())
      .pipe(
        tap(adsLayoutResponse => {
          if (this.CheckForAdData(adsLayoutResponse)) {
            const adLayout = adsLayoutResponse.data[0];
            if (this.currentFormat().ad_format === 2) {

              this.adForm.patchValue({
                teazer: {
                  text: [adLayout.title],
                  linkAddress: adLayout.link_url,
                  image: [adLayout.image_src]
                }
              }, {emitEvent: false});
            } else {
              this.promopostWallLink = adLayout.link_url;
            }
          } else {
            this.ThrowNotFoundAdError();
          }
        }),
        tap(() => this.SetLoadingProgress(7)),
        catchError(err => {
          this.logger.SetTag('Operation', 'LoadAdLayout');
          this.logger.AddExtra('ErrorData', err);
          this.logger.Error('Loading Ad Layout Error', err);
          return throwError(err);
        }),
        delay(2000)
      );
  }

  public LoadAdWall() {
    let obs = null;
    if (this.IsSelectFormatPromopost) {
      const wallId = this.promopostWallLink.replace('http://vk.com/wall', '');
      obs = this.automationVkService.GetWallById(wallId)
        .pipe(
          tap((adsWalls: any) => {
            if (this.CheckForAdData(adsWalls)) {
              const wall = adsWalls.data[0];
              if (wall) {
                this.PostId = wall.id;
                const attachmentLink = this.GetAttachmentLink(wall);
                if (attachmentLink !== null) {
                  const linkAddress = attachmentLink.link.url;
                  const title = attachmentLink.link.title;
                  const image = this.GetLinkImage(attachmentLink);

                  const value = {
                    promopost: {
                      text: [wall.text],
                      linkTitle: title,
                      linkAddress,
                      image: [image]
                    }
                  };

                  console.log(value);

                  this.adForm.patchValue(value, {emitEvent: false});
                }
              }
            }
          }),
          catchError(err => {
            this.logger.SetTag('Operation', 'LoadAdWall');
            this.logger.AddExtra('ErrorData', err);
            this.logger.Error('Loading Ad Wall Error', err);
            return throwError(err);
          }),
          tap(() => this.SetLoadingProgress(8)),
          delay(2000)
        );
    } else {
      obs = of(null)
        .pipe(
          tap(() => this.SetLoadingProgress(8)),
        );
    }

    return obs;
  }

  public LoadAdTargeting() {
    return this.automationVkService.GetAdsTargeting(this.getAdParams())
      .pipe(
        tap((targetingResponse) => {
          if (this.CheckForAdData(targetingResponse)) {
            const target = targetingResponse.data[0];
            if (target) {
              this.adForm.patchValue({
                targetingData: {
                  cities: target.cities ? target.cities.split(',').map(x => parseInt(x, 10)) : [0],
                  groups: target.groups,
                  country: target.country,
                  ageTo: target.age_to,
                  ageFrom: target.age_from,
                  sex: parseInt(target.sex, 10),
                  targetGroups: target.retargeting_groups_not !== null ? target.retargeting_groups.split(',').map(x => parseInt(x, 10)) : [],
                  targetGroupsNot: target.retargeting_groups_not !== null ? target.retargeting_groups_not.split(',').map(x => parseInt(x, 10)) : []
                }
              }, {emitEvent: false});
            }
          }
        }),
        catchError(err => {
          this.logger.SetTag('Operation', 'LoadAdTargeting');
          this.logger.AddExtra('ErrorData', err);
          this.logger.Error('Loading Ad Targeting Error', err);
          return throwError(err);
        }),
        tap(() => this.SetLoadingProgress(9)),
        delay(2000)
      );
  }

  public LoadStrategy() {
    return this.userStrategyService.GetStrategiesByAdAndAccountIds({
      accountId: this.accountId,
      clientId: this.clientId,
      viewModel: {adIds: [this.adId]}
    })
      .pipe(
        tap((userStrategiesResponse) => {
          const adRateControl = this.getControl('rateData', 'mode');
          if (userStrategiesResponse && userStrategiesResponse.length > 0) {
            this.strategyAction = StrategyAction.UPDATE_STRATEGY;
            adRateControl.setValue('auto', {emitEvent: false});
            this.strategyCreate = true;
            const strategy = userStrategiesResponse[0];
            console.log(strategy);
            strategy.inputs.forEach(strategyInput => {
              const userStrategyInputControl = this.getControl('rateData', 'strategyValue', strategyInput.type.code);
              if (userStrategyInputControl && strategyInput.value !== null) {
                userStrategyInputControl.patchValue(this.getDisplayedStrategVlaue(strategyInput.type.code, strategyInput.value), {emitEvent: false});
              }
            });
          } else {
            adRateControl.setValue('custom', {emitEvent: false});
            this.strategyAction = StrategyAction.DO_NOTHING;
          }
        }),
        catchError(err => {
          this.logger.SetTag('Operation', 'LoadStrategy');
          this.logger.AddExtra('ErrorData', err);
          this.logger.Error('Loading Strategy Error', err);
          return throwError(err);
        }),
        tap(() => this.SetLoadingProgress(10))
      );
  }

  public LoadFullAdData() {
    let data = null;
    console.log('IS UPDATE ', this.IsUpdate);
    if (this.IsUpdate) {
      data = this.LoadAdData()
        .pipe(
          flatMap(() => this.LoadAdLayout()),
          flatMap(() => this.LoadAdWall()),
          flatMap(() => this.LoadAdTargeting()),
          flatMap(() => this.LoadAdGroups()),
          flatMap(() => this.LoadStrategy()),
          tap(() => {
            this.ChangeRateCpmValidators();
          })
        );
    } else {
      data = of(null);
    }
    return data;
  }

  private LoadAdGroups() {
    const groupsControl = this.getControl('targetingData', 'groups');
    const groupIds = groupsControl.value !== null ? groupsControl.value.split(',').map(groupId => parseInt(groupId, 10)) : null;
    if (this.currentFormat().ad_format === 9 && groupIds && groupIds.length > 0) {
      return this.adManagerService.GetGroupsById(groupIds)
        .pipe(
          tap((groupsResponse: Array<PostMonitorBackend_V2ModelsvkVKAPIGroupModel>) => {
            this.selectedGroups = groupsResponse;
            this.groupToggled.next();
          }),
          delay(2000)
        );
    } else {
      return of(null);
    }
  }

  private GetAttachmentLink(wall: WallPost): WallAttachment {
    const linkAttachment = wall.attachments.find(attachment => attachment.type === 'link');
    return linkAttachment;
  }

  private GetLinkImage(attachmentLink: WallAttachment): string {
    if (attachmentLink && attachmentLink.link && attachmentLink.link.photo) {
      const linkImageSizes = attachmentLink.link.photo.sizes;
      const sizeKeys = linkImageSizes.sort((imageSizeA, imageSizeB) => imageSizeB.width - imageSizeA.width);
      return sizeKeys[0].url;
    } else {
      return '';
    }
  }

  private InitAdData() {
    this.accountId = null;
    this.clientId = null;
    this.campaignId = null;
    this.adId = null;

    this.route.queryParams.subscribe(({client_id, account_id, campaign_id, adId}) => {
      const clientId = (client_id === 'NaN' || isNullOrUndefined(client_id)) ? null : parseInt(client_id, 10);
      this.accountId = parseInt(account_id, 10);
      this.clientId = clientId;
      this.campaignId = parseInt(campaign_id, 10);
      this.adId = parseInt(adId, 10);
    });
  }

  private HandleUpdateStrategy(ad: any) {
    const accountIdValue = this.getControl('campaignData', 'accountId').value;
    const campaignId = this.getControl('campaignData', 'campaignId').value;
    const properties = this.getControl('rateData', 'strategyValue').value;
    const rateType = this.getControl('rateData', 'mode').value;

    const [accountId, clientId] = accountIdValue.split('_').map(x => x === 'null' ? 0 : parseInt(x, 10));
    return this.userStrategyService.UpdateUserStrategies({
      accountId,
      clientId,
      ads: [ad[0].id],
      properties: Object.keys(properties)
        .map(key => ({key, value: properties[key] === null ? null : this.getRateStrategyValue(key, properties[key])}))
        .reduce((curr, {key, value}) => {
          curr[key] = value;
          return curr;
        }, {}),
      strategyType: 'INTELEGENT_MARK_I_MOD_I',
      updateStep: 10
    });
  }

  private subscribeRateModeData() {
    const rateData = this.getControl('rateData', 'mode');
    console.log('RATE MODE DATA SUBSCRIBE');
    rateData.valueChanges
      .pipe(
        startWith('auto'),
        pairwise()
      )
      .subscribe(([prev, curr]) => {
        if (prev !== curr && curr === 'custom') {
          this.strategyAction = StrategyAction.REMOVE_STRATEGY;
        } else {
          if (this.hasStrategy) {
            this.strategyAction = StrategyAction.UPDATE_STRATEGY;
          } else {
            this.strategyAction = StrategyAction.CREATE_STRATEGY;
          }
        }
      });
  }

  private RemoveAdStrategy(ad: any) {
    const {accountId} = this.getAccountData();
    return this.userStrategyService.RemoveStrategy({
      accountId,
      adId: ad[0].id
    });
  }

  private getRateStrategyValue(key, value) {
    const modifyFunction = this.modifyFunctions[key];
    if (modifyFunction) {
      value = modifyFunction(value);
    }

    return value;
  }

  private getDisplayedStrategVlaue(key, value) {
    const displayFunction = this.displayFunctions[key];
    if (displayFunction) {
      value = displayFunction(value || strategyDefaultValues[key]);
    }

    return value || strategyDefaultValues[key];
  }

  public EnableProMode() {
    this.isPro = true;
    this.SelectCampaign();
  }

  public ngOnInit(): void {
    this.LoadBidRanges();
    this.adName = `Объявление ${moment(new Date()).format('DD.MM.YYYY HH:mm:ss')}`;
    this.InitAdData();
    this.subscribeToCitiesSearchStirng();
    this.subscribeSearchGroupForm();
    this.subscribeFormEvents();
    this.subscribeTargetingForm();
    this.subscribeAccountForm();
    this.subscribeFormatChange();
    this.subscribeRateModeData();
  }

  private LoadClients() {
    console.log('campaigns loaded');
    const agencyAccounts = this.accounts.filter(account => account.account_type === 'agency');
    const hasAgencyAccounts = agencyAccounts.length > 0;
    if (hasAgencyAccounts && this.isPro) {
      return of(agencyAccounts)
        .pipe(
          concatAll(),
          map((account, i) => of(account).pipe(delay(2000 * i))),
          flatMap(account => account),
          flatMap(account => this.LoadClient(account)),
          delay(2000),
          tap(() => {
            console.log('LOADING CLIENTS');
          }),
          tap(() => {
            console.log(this.accounts);
            this.accounts = this.accounts.map(x => {
              x.is_blocked = !this.IsAccountAssigned(x);
              return x;
            });
            const accounts = this.GetAccountsAssignedToSystem();
            console.log(accounts);
            if (accounts && accounts.length > 0) {
              this.setCurrentAccount(accounts[0]);
            }
            console.log(this.accounts, 'WAS UPDATED');
          }),
          catchError(err => {
            console.log(err);
            return err;
          })
        );
    } else {
      return of([]);
    }
  }

  private LoadClient(account) {
    return this.automationVkService.getClients(account.account_id)
      .pipe(
        tap((accountsResponse: any) => {
          accountsResponse.data.forEach(({account_id, name, id}, i) => {
            const slots = this.slots.find(slotsItem => slotsItem.bindedClientId === id && slotsItem.bindedCabinetId === account_id);
            const acc = new AccountViewModel(
              account.account_id,
              name,
              account.role,
              account.account_status,
              account.account_type,
              account.is_blocked || isNullOrUndefined(slots),
              id,
              i
            );

            this.accounts.push(acc);
          });
        }),
        catchError(err => {
          this.logger.SetTag('Operation', 'Loading Client');
          this.logger.AddExtra('ErrorData', err);
          this.logger.Error('Loading Clients Error', err);
          return throwError(err);
        }),
        delay(2000)
      );
  }

  public ToggleAll() {
    if (!this.IsAllToggled()) {
      const maxCount = 100;
      let i = 0;
      while (this.selectedGroups.length < maxCount) {
        if (!this.isGroupSelected(this.groups[i])) {
          this.selectedGroups.push(this.groups[i]);
        }
        i++;
      }
      this.groupToggled.next();
      console.log(i);
    } else {
      this.selectedGroups = [];
    }
  }

  public IsAllToggled() {
    return this.selectedGroups.length === 100;
  }

  public IsRowDisabled(group) {
    return this.IsAllToggled() && !this.isGroupSelected(group);
  }

  public countOfSelected() {
    return this.selectedGroups.length;
  }

  public HasAssignedAccounts() {
    const {accountId, clientId} = this.getAccountData();
    if (accountId !== null) {
      return this.isAccountAssigned(accountId, clientId);
    } else {
      return false;
    }
  }

  /**
   * Показывает имеет ли пользователь Личный кабинет рекламодателя (access_role = admin)0
   * @constructor
   */
  public HasAssignedAdminAccount() {
    const adminAccount = this.GetAdminAccount();
    if (!isNullOrUndefined(adminAccount)) {
      return this.IsAccountAssigned(adminAccount);
    }
    return false;
  }

  public RefreshAccountsData() {
    return this.LoadSlots()
      .pipe(
        flatMap(() => this.LoadAccounts()),
        flatMap(() => this.AssignAdminAccountToSystem())
      )
  }

  IsSelectedAccountAdmin() {
    const adminAccount = this.GetAdminAccount();
    const {accountId, clientId} = this.getAccountData();
    return adminAccount && adminAccount.account_id === accountId && adminAccount.client_id === clientId;
  }

  public LoadAdTargetGroups() {
    const {accountId, clientId} = this.getAccountData()
    console.log(accountId, clientId);
    return this.adManagerService.GetTargetingGroup({
      accountId,
      clientId
    })
      .pipe(
        delay(2000),
        retry(5),
        tap(targetGroups => {
          this.targetingGroups = targetGroups
            .sort((a, b) => b.id - a.id);
        }),
        catchError(err => {
          if (err) {
            const {accountId, clientId} = this.getAccountData();
            const assignedToSystemAccounts = this.GetAccountsAssignedToSystem();
            const countOfAssignedCabinets = assignedToSystemAccounts !== null ? assignedToSystemAccounts.length : 0;
            const debugData = {
              count_of_assigned_to_system_cabinets: countOfAssignedCabinets,
              accountId,
              clientId
            };
            this.logger.AddExtra('debug', debugData);
            this.logger.Error(err);
            return throwError(err);
          }
        })
      );
  }

  public get SelectedTargetGroups() {
    const selected = this.getControl('targetingData', 'targetGroupsNot').value;
    if (selected) {
      return this.targetingGroups.filter(x => !selected.includes(x.id));
    } else {
      return this.targetingGroups;
    }
  }

  public get SelectedTargetGroupsNot() {
    const selected = this.getControl('targetingData', 'targetGroups').value;
    if (selected) {
      return this.targetingGroups.filter(x => {
        return !selected.includes(x.id)
      });
    } else {
      return this.targetingGroups;
    }
  }
}
