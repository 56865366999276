import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AmoService} from '../../../../api/services/amo.service';
import {MatDialogRef} from '@angular/material/dialog';
import {UonTravelService} from '../../../services/uon-travel/uon-travel.service';
import {MoySkladService} from '../../../../api/services';
import {delay} from 'rxjs/operators';

@Component({
  selector: 'app-moi-sklad-assignation-dialog',
  templateUrl: './moi-sklad-assignation-dialog.component.html',
  styleUrls: ['./moi-sklad-assignation-dialog.component.scss']
})
export class MoiSkladAssignationDialogComponent implements OnInit {

  public loading: boolean = false;

  public form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private amo: AmoService,
    private dialogRef: MatDialogRef<MoiSkladAssignationDialogComponent>,
    private moySkladService: MoySkladService
  ) {
  }

  ngOnInit() {
    this.form = this.fb.group({
      login: this.fb.control('', [Validators.required]),
      password: this.fb.control('', [Validators.required])
    });
  }

  Assign() {
    console.log(this.form.value, this.form.valid);
    if (this.form.valid) {
      this.loading = true;
      const authData = this.form.value;
      this.moySkladService.AuthorizeAsync(authData)
        .pipe(
          delay(1200)
        )
        .subscribe(data => {
          setTimeout(() => {
            this.loading = false;
            console.log('WTF');
            // localStorage.setItem('bind-return-to', '/new-integration');
            this.dialogRef.close();
          }, 100);
        }, err => {
          console.log(err);
          this.loading = false;
        });
    }
  }
}
