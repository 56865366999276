import {AbstractCopyingConfigurator} from "./abstract-copying-configurator";
import {PostMonitorBackend_V2ModelsAdManagerCopyAdsViewModel} from "../../../api/models";


export class DefaultCopyingConfigurator extends AbstractCopyingConfigurator {

  constructor(props) {
    super(props);
  }

  public generate(): PostMonitorBackend_V2ModelsAdManagerCopyAdsViewModel[] {
    const copyingAdsSpecifications: PostMonitorBackend_V2ModelsAdManagerCopyAdsViewModel[] = [];
    for (let i = 0; i < this.copyingData.defaultConfiguration.countOfCopies; i++) {
      this.copyingData.adIds.forEach(adId => {
        let specification = this.generateDefeault();
        specification = Object.assign(specification, {
          numberOfCopy: i + 1,
          adIds: [adId]
        });
        copyingAdsSpecifications.push(Object.assign({}, specification));
      })
    }
    return copyingAdsSpecifications;
  }

}
