import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AccountsService} from '../../../../api/services/accounts.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {UtilsService} from '../../../../shared/services/utils.service';

@Component({
  selector: 'app-auth-change-password',
  templateUrl: './auth-change-password.component.html',
  styleUrls: ['./auth-change-password.component.scss']
})
export class AuthChangePasswordComponent implements OnInit {

    public form: FormGroup;
    public errors = null;
    public loading = false;
    public message = false;

    constructor(
        private route: ActivatedRoute,
        private accountsService: AccountsService
    ) {
    }

    ngOnInit() {
        this.form = new FormGroup({
            password: new FormControl('', [
                Validators.required,
                Validators.maxLength(32),
                Validators.minLength(4)
            ]),
            passwordRepeat: new FormControl('', [
                Validators.required,
                Validators.maxLength(32),
                Validators.minLength(4),
            ]),
        });
    }

    Submit($event) {
        $event.preventDefault();
        this.ToggleLoading();
        
        if (this.form.valid) {
            this.accountsService.ChangePasswordAuth(this.form.value)
                .subscribe(x => {
                    this.ToggleLoading();
                    this.message = true;
                }, (err) => {
                    this.errors = {
                        system: [JSON.parse(err.error)]
                    };
                    this.ToggleLoading();
                });
        } else {
            this.errors = UtilsService.GetErrorsByForm('forgot-password', this.form.controls);
            this.ToggleLoading();
        }
    }

    public ToggleLoading () {
        this.loading = !this.loading;
    }
}
