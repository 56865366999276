import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { AdNetworkFormsQuery } from "../../../../stores/ad-network-forms/ad-network-forms.query";
import { untilDestroyed } from "ngx-take-until-destroy";
import { PlatformsQuery } from "../../../../stores/platforms/platforms.query";
import { AkitaNgFormsManager } from "@datorama/akita-ng-forms-manager";
import { combineLatest } from "rxjs";
import { unionAllArraysFromObjectsToArray } from "../../../../../utils/js/arrays";
import { PlatformsService } from "../../../../stores/platforms/platforms.service";
import { PlatformsTypes } from "../../../../platforms-types";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { environment } from "../../../../../../environments/environment";
import { CrmUsersService } from "../../../../stores/crm-users/crm-users.service";
import { CrmPipelinesService } from "../../../../stores/crm-pipelines/crm-pipelines.service";
import { CrmUsersQuery } from "../../../../stores/crm-users/crm-users.query";
import { CrmPipelinesQuery } from "../../../../stores/crm-pipelines/crm-pipelines.query";
import { NewImportCrmService } from "../../services/new-import-crm/new-import-crm.service";
import { distinctUntilChanged, tap } from "rxjs/operators";
import { CustomFieldsQuery } from "../../../../stores/custom-fields/custom-fields.query";
import { isNullOrUndefined } from "util";
import { PlatformAssignationsQuery } from "../../../../stores/platform-assignations/platform-assignations.query";
var NewImportCrmComponent = /** @class */ (function () {
    function NewImportCrmComponent(formsQuery, platformsQuery, cdr, fm, crmUsersService, crmPipelinesService, crmUsersQuery, crmPipelinesQuery, newImportCrmService, customFieldsQuery, platformService, assginationsQuery) {
        var _this = this;
        this.formsQuery = formsQuery;
        this.platformsQuery = platformsQuery;
        this.cdr = cdr;
        this.fm = fm;
        this.crmUsersService = crmUsersService;
        this.crmPipelinesService = crmPipelinesService;
        this.crmUsersQuery = crmUsersQuery;
        this.crmPipelinesQuery = crmPipelinesQuery;
        this.newImportCrmService = newImportCrmService;
        this.customFieldsQuery = customFieldsQuery;
        this.platformService = platformService;
        this.assginationsQuery = assginationsQuery;
        this.formGroup = new FormGroup({});
        this.originPlatformCode$ = this.platformsQuery.selectedOriginPlatformCode$
            .pipe(tap(function (originCode) { return _this.originCode = originCode; }), untilDestroyed(this));
        this.destinationPlatformCode$ = this.platformsQuery.selectedDestinationPlatformCode$
            .pipe(tap(function (destinationCode) { return _this.destinationCode = destinationCode; }), untilDestroyed(this));
        this.selectedForms$ = this.formsQuery.selectedForms
            .pipe(untilDestroyed(this));
        this.destinationPlatformSections$ = this.platformsQuery.destinationPlatformSections$
            .pipe(untilDestroyed(this));
        this.crmUsers$ = this.crmUsersQuery.users$
            .pipe(untilDestroyed(this));
        this.crmPipelines$ = this.crmPipelinesQuery.pipelines$
            .pipe(untilDestroyed(this));
        this.advancedSettingsFields = null;
        this.forms = [];
        this.platformSections = [];
    }
    NewImportCrmComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.selectedForms$
            .subscribe(function (forms) {
            _this.forms = forms;
            _this.cdr.markForCheck();
        });
        this.destinationPlatformSections$
            .subscribe(function (sections) {
            _this.platformSections = sections;
            _this.cdr.markForCheck();
        });
        this.destinationPlatformCode$.subscribe();
        this.fm.selectValue('originPlatformSettings')
            .subscribe(function (originPlatformSettings) {
            combineLatest(_this.originPlatformCode$, _this.destinationPlatformSections$, _this.customFieldsQuery.CustomFields$)
                .subscribe(function (_a) {
                var originPlatformCode = _a[0], sections = _a[1], customFields = _a[2];
                console.log('DATA LOG', originPlatformSettings);
                _this.initializeForm(originPlatformSettings, originPlatformCode, sections, customFields);
            });
        });
        this.crmUsers$.subscribe(function (users) {
            var usersOptions = users.map(function (usr) { return ({
                Key: usr.id.toString(),
                Label: usr.name
            }); });
            _this.newImportCrmService.SetUsersOptions(usersOptions);
        });
        this.crmPipelines$.subscribe(function (pipelines) {
            var pipelinesOptions = pipelines.map(function (pipeline) { return ({
                Key: pipeline.id.toString(),
                Label: pipeline.name
            }); });
            _this.newImportCrmService.SetPipelinesOptions(pipelinesOptions);
        });
        this.advancedSettingsFields = this.newImportCrmService.GetAdvancedSettings(this.originCode, this.destinationCode);
        this.assginationsQuery
            .isPlatformAssigned$(this.type)
            .pipe(distinctUntilChanged(), untilDestroyed(this))
            .subscribe(function () {
            _this.crmUsersService.LoadUsers(_this.type);
            _this.crmPipelinesService.LoadPipelines(_this.type);
            _this.newImportCrmService.LoadData();
        });
    };
    NewImportCrmComponent.prototype.ngOnDestroy = function () {
    };
    NewImportCrmComponent.prototype.initializeForm = function (originPlatformSettings, originPlatformCode, sections, customFields) {
        this.platformService.ClearRequiredFields();
        var selectedFormIds = this.getSelectedFormIds(originPlatformSettings, originPlatformCode);
        console.log(environment.integrations.destinationFormKey, 'FORM KEY', this.fm.getForm(environment.integrations.destinationFormKey));
        var form = this.fm.getForm(environment.integrations.destinationFormKey);
        var destinationForm = new FormGroup({});
        for (var _i = 0, selectedFormIds_1 = selectedFormIds; _i < selectedFormIds_1.length; _i++) {
            var formId = selectedFormIds_1[_i];
            var selectedFormGroup = new FormGroup({
                includeCampaignName: new FormControl(this.getPreviousFormValue(form, formId, 'includeCampaignName')),
                includeLeadFormName: new FormControl(this.getPreviousFormValue(form, formId, 'includeLeadFormName')),
                bindContact: new FormControl(this.getPreviousFormValue(form, formId, 'bindContact'))
            });
            for (var _a = 0, sections_1 = sections; _a < sections_1.length; _a++) {
                var section = sections_1[_a];
                var selected = this.GetSelectionSectionStatus(form, formId, section);
                console.log(selected, section.isRequired, 'SELECTED');
                var sectionForm = new FormGroup({
                    selected: new FormControl(isNullOrUndefined(selected) ? section.isRequired : selected, section.isRequired ? [Validators.required] : []),
                    fields: new FormGroup({}),
                    customFields: new FormGroup({})
                });
                for (var _b = 0, _c = section.fields; _b < _c.length; _b++) {
                    var field = _c[_b];
                    var fieldValue = this.GetFieldValue(formId, section.code, 'fields', field.code, form);
                    console.log(fieldValue);
                    var fieldValidators = [];
                    if (field.isRequired) {
                        this.platformService.AddRequiredField(formId + "_" + section.code + "_" + field.code);
                        fieldValidators.push(Validators.required);
                    }
                    sectionForm.get('fields').addControl(field.code, new FormControl(fieldValue, fieldValidators));
                }
                for (var _d = 0, customFields_1 = customFields; _d < customFields_1.length; _d++) {
                    var customField = customFields_1[_d];
                    var fieldValue = this.GetFieldValue(formId, section.code, 'customFields', customField.Key, form);
                    sectionForm.get('customFields').addControl(customField.Key, new FormControl(fieldValue, []));
                }
                console.log(sectionForm, 'SECTION FORM GROUP');
                selectedFormGroup.addControl(section.code, sectionForm);
            }
            destinationForm.addControl(formId, selectedFormGroup);
        }
        this.fm.remove(environment.integrations.destinationFormKey);
        this.fm.upsert(environment.integrations.destinationFormKey, destinationForm);
        console.log(destinationForm, 'FORM WORLD', selectedFormIds);
        this.formGroup = destinationForm;
        console.log(originPlatformSettings, originPlatformCode, 'INITIALIZE FORM');
    };
    NewImportCrmComponent.prototype.GetSelectionSectionStatus = function (form, formId, section) {
        var value = null;
        if (form) {
            var formValue = form.value;
            if (formValue && formValue[formId] && formValue[formId][section.code]) {
                value = formValue[formId][section.code].selected;
            }
        }
        return value;
    };
    NewImportCrmComponent.prototype.getPreviousFormValue = function (previousForm, formId, selectedFormFieldKey) {
        if (previousForm) {
            var form = previousForm.value[formId];
            if (form) {
                return form[selectedFormFieldKey];
            }
        }
        return null;
    };
    /**
     * Возвращает массив id выделенных форм (в левой части конфигуратора)
     * @param originPlatformSettings - настройки левой части конфигуратора
     * @param originPlatformCode - код выбранной в левой части платформы
     * @private
     */
    NewImportCrmComponent.prototype.getSelectedFormIds = function (originPlatformSettings, originPlatformCode) {
        var formIds = [];
        if (originPlatformCode === PlatformsTypes.MT_LEAD_FORMS) {
            var originPlatformSettingsKeys = Object.keys(originPlatformSettings);
            for (var _i = 0, originPlatformSettingsKeys_1 = originPlatformSettingsKeys; _i < originPlatformSettingsKeys_1.length; _i++) {
                var originPlatformSettingsKey = originPlatformSettingsKeys_1[_i];
                if (originPlatformSettings[originPlatformSettingsKey]) {
                    formIds.push(originPlatformSettingsKey);
                }
            }
        }
        else {
            formIds = unionAllArraysFromObjectsToArray(originPlatformSettings);
        }
        return formIds;
    };
    /**
     * Получить значение поля из формы
     * @param formId - id формы
     * @param sectionCode - код секции
     * @param fieldsSection
     * @param fieldCode - код поля
     * @param form - глобальная форма
     * @constructor
     * @private
     */
    NewImportCrmComponent.prototype.GetFieldValue = function (formId, sectionCode, fieldsSection, fieldCode, form) {
        var value = '';
        // console.log('GetFieldValue: form', form)
        if (form) {
            var formValue = form.value;
            // console.log('GetFieldValue: value', formId, sectionCode, fieldCode, value);
            if (formValue && formValue[formId] && formValue[formId][sectionCode] && formValue[formId][sectionCode][fieldsSection][fieldCode]) {
                // console.log(`GetFieldValue: formValue`, formValue);
                // console.log(`GetFieldValue: formValue[${formId}]`, formValue[formId]);
                // console.log(`GetFieldValue: formValue[${formId}][${sectionCode}]`, formValue[formId][sectionCode]);
                // console.log(`GetFieldValue: formValue[${formId}][${sectionCode}][${fieldsSection}]`, formValue[formId][sectionCode][fieldsSection]);
                // console.log(`GetFieldValue: formValue[${formId}][${sectionCode}][${fieldsSection}][${fieldCode}]`, formValue[formId][sectionCode][fieldsSection][fieldCode]);
                value = formValue[formId][sectionCode][fieldsSection][fieldCode];
            }
        }
        return value;
    };
    NewImportCrmComponent.prototype.GetFormGroup = function (id) {
        return this.formGroup.get(id);
    };
    return NewImportCrmComponent;
}());
export { NewImportCrmComponent };
