import * as _ from 'lodash';

/**
 * Содержиться ли элемент во всех массивах
 * @param arrays
 * @param element
 */
export const containedElementInAllArrays = (arrays: any[][], element: any) =>
  arrays
    .map(array => array.includes(element))
    .reduce((summ, current) => summ && current, true);

export function unionAllArraysFromObjectsToArray(obj) {
  const keys = Object.keys(obj);
  let res = [];
  keys.forEach(key => {
    res = _.union(res, obj[key]);
  });
  return res;
}
