/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./notification-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@clr/angular";
import * as i4 from "./notification-list.component";
import * as i5 from "../../services/notifications.service";
var styles_NotificationListComponent = [i0.styles];
var RenderType_NotificationListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotificationListComponent, data: {} });
export { RenderType_NotificationListComponent as RenderType_NotificationListComponent };
function View_NotificationListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "alert"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "alert-items"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "alert-item static"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "alert-text"]], null, null, null, null, null)), i1.ɵdid(5, 16384, null, 0, i3.ɵbd, [], null, null), (_l()(), i1.ɵted(6, null, [" ", " "]))], function (_ck, _v) { var currVal_0 = "alert"; var currVal_1 = _v.context.$implicit.type; _ck(_v, 1, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.title; _ck(_v, 6, 0, currVal_2); }); }
export function View_NotificationListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "alerts"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationListComponent_1)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.notifications; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_NotificationListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-notification-list", [], null, null, null, View_NotificationListComponent_0, RenderType_NotificationListComponent)), i1.ɵdid(1, 114688, null, 0, i4.NotificationListComponent, [i5.NotificationsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NotificationListComponentNgFactory = i1.ɵccf("app-notification-list", i4.NotificationListComponent, View_NotificationListComponent_Host_0, {}, {}, []);
export { NotificationListComponentNgFactory as NotificationListComponentNgFactory };
