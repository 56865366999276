<app-new-style-modal [isVisible]="isVisible"
                     showCloseButton="true"
                     (onCloseButtonClick)="CloseModal()">
  <div class="new-modal-header-content">
    Удаление профиля
  </div>
  <div class="new-modal-body-content">
    Вы собираетесь удалить свой профиль PostMonitor.<br>
    Это действие нельзя будет отменить.<br>
    Вы действительно хотите удалить свой профиль PostMonitor?
  </div>
  <div class="new-modal-buttons">
    <div class="remove-account-button-strip">
      <div class="remove-account-button-strip__item">
        <button class="vk-standart-button" (click)="RemoveAccountClick()">
          Да, удалить мой профиль
        </button>
      </div>

      <div class="remove-account-button-strip__item">
        <button class="vk-standart-button vk-standart-button_cancel" (click)="CloseModal()">
          Нет, не удалять мой профиль
        </button>
      </div>
    </div>
  </div>
</app-new-style-modal>
