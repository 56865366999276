import {Component, OnInit, ViewChild} from '@angular/core';
import {Statistic} from '../../models/statistic';
import {AutomationVkService} from '../../../api/services/automation-vk.service';
import {Cabinet} from '../../../api/models/cabinet';
import {DexieCabinetsService} from '../../../shared/services/dexie/dexie-cabinets.service';
import {AccountViewModel} from '../../models/view-models/account-view-model';
import {AutomationVkSlotService} from '../../../api/services/automation-vk-slot.service';
import {Client, UserAutomationCabinetSlot} from '../../../api/models';
import 'rxjs/add/observable/forkJoin';
import {AutomationExpirationManagerService} from '../../services/automation-expiration-manager.service';
import {DexieClientsService} from '../../../shared/services/dexie/dexie-clients.service';
import {PickListComponent} from '../../components/pick-list/pick-list.component';
import {CurrencyPipe, DecimalPipe} from '@angular/common';
import {DgPaginationComponent} from '../../../ngr-ui/components/dg-pagination/dg-pagination.component';
import {BrowserClient, Hub} from '@sentry/browser';
import * as Sentry from '@sentry/browser'

// import {AccountsService} from "../../services/store/accounts.service";
import {isNullOrUndefined} from "util";
import {LoggingService} from "../../../shared/services/logging.service";

let sentry = null;
let hub = null;

export enum ACCOUNT_VIEW_MODE {
  ALL = 'ALL',
  ACTIVE = 'ACTIVE'
}

const client = JSON.parse(`{
"clicks":0,
"spent":0,
"ctr":0,
"impressions":0,
"loaded":true,
"is_blocked":false,
"isSelected":false,
"priority":6,
"account_id":1900013339,
"account_name":"zaleycash_alekseylymarev@gmail.com_282079040",
"account_role":"manager",
"account_status":true,
"account_type":"client",
"client_id":1605088703
}`);

function random(min, max) // min and max included
{
  return Math.floor(Math.random() * (max - min + 1) + min);
}


@Component({
  selector: 'app-automation-accounts',
  templateUrl: './automation-accounts.component.html',
  styleUrls: ['./automation-accounts.component.scss'],
})
export class AutomationAccountsComponent implements OnInit {

  public token = '2d74c771895089eb9c237b388499b6877a967ea5b25935215fd489276327ceed6c4cf49708b44cb4f1a6b';
  public accounts: Array<Cabinet> = null;
  public accountsViewModels: Array<AccountViewModel> = [];
  public accountsStatistics: Array<Statistic> = [];
  public IsExpired = true;
  public opened = false;
  public pickItems = [];
  public slots: Array<UserAutomationCabinetSlot>;
  public countOfMaxSlots = 0;
  public countOfFree = 0;
  public firstLoading = true;
  public loading = false;
  @ViewChild('pickList') public pickList: PickListComponent;

  @ViewChild('dgPagination') public dgPagination: DgPaginationComponent;
  public viewMode: ACCOUNT_VIEW_MODE = ACCOUNT_VIEW_MODE.ALL;
  countOfResults: number = 10;

  availableSizes = [
    {
      value: 5,
      label: '5'
    },
    {
      value: 10,
      label: '10'
    },
    {
      value: 25,
      label: '25'
    },
    {
      value: 50,
      label: '50'
    },
    {
      value: 100,
      label: '100'
    }
  ];

  public clientsLoading: boolean = false;

  public get data() {
    let data = this.accountsViewModels || [];
    switch (this.viewMode) {
      case ACCOUNT_VIEW_MODE.ACTIVE:
        data = data.filter(x => x.is_blocked == false && x.account_type !== 'agency');
        break;
    }

    return data;
  }


  constructor(
    private _api: AutomationVkService,
    private localDb: DexieCabinetsService,
    private _db: AutomationVkSlotService,
    public accessManager: AutomationExpirationManagerService,
    private localClientsDb: DexieClientsService,
    private logger: LoggingService
  ) {
    sentry = new BrowserClient({
      dsn: 'https://b654dfe1c9af48b38da372bb697ff503@sentry.io/1262782'
    });
    hub = new Hub(sentry);
  }

  public ToggleOpened() {
    this.opened = !this.opened;
  }

  public ToggleLoading() {
    this.loading = !this.loading;
  }

  public get PickListItems() {
    return [];
  }

  public OnPickListItemChanged(data) {
  }

  ngOnInit() {
    try {
      // this.accountsService.LoadAdCabinetsData()
      //   .subscribe(data => {
      //     console.log(data);
      //   })
      this.LoadData();
    } catch (e) {
    }
  }

  public LoadData() {
    this.ToggleLoading();
    this.LoadSlots()
      .then(this.LoadAccounts.bind(this))
      .then(this.LoadClients.bind(this))
      .then(this.LoadStatistic.bind(this))
      .then(() => {
        this.accountsViewModels = this.accountsViewModels.map(account => {
          this.slots.forEach(slot => {
            const cabinet = this.accountsViewModels
              .find(cabinetItem => cabinetItem.account_id === slot.bindedCabinetId && cabinetItem.client_id === slot.bindedClientId);

            if (cabinet) {
              cabinet.is_blocked = false;
            }
          });
          // let slot: UserAutomationCabinetSlot = null;
          // if (account && account.account_type === 'client') {
          //   slot = this.slots.find(x => x.bindedCabinetId === account.account_id && x.bindedClientId === account.client_id);
          // } else {
          //   slot = this.slots.find(x => x.bindedCabinetId === account.account_id);
          // }
          // account.is_blocked = isNullOrUndefined(slot);
          // this.accountsViewModels = this.accountsViewModels.sort((a, b) => a.priority - b.priority);
          // console.log(JSON.stringify(this.accountsViewModels));
          return account;
        });
      });
    // this.LoadSlots();
  }

  private isAccountAssigned(account: AccountViewModel) {
    const slot = this.slots.find(x => x.bindedClientId === account.client_id && x.bindedCabinetId === account.account_id);
    return !isNullOrUndefined(slot);
  }

  public LoadAccounts() {
    // this.accountsViewModels = [];
    return new Promise(resolve => {
      this._api.GetAccounts()
        .subscribe(x => {
          this.accountsViewModels = x.data.map((y, index) => {
            const acc = new AccountViewModel(
              y.account_id,
              y.account_name,
              y.access_role,
              y.account_status,
              y.account_type,
              true,
              null,
              index
            );
            acc.is_blocked = !this.isAccountAssigned(acc);
            return acc;
          });
          // this.accountsViewModels
          this.ToggleLoading();
          console.log('QUOTA');
          if (x.userMeta) {
            this.IsExpired = x.userMeta.isExpired || false;
          }
          this.localDb.setCabinets(this.accountsViewModels)
            .then(() => {
            })
            .catch((e) => {
              navigator.storage.estimate()
                .then(data => {
                  hub.withScope(scope => {
                    scope.addBreadcrumb({
                      data,
                      message: 'Не удалось добавить кабинеты в локальную бд'
                    });
                    hub.captureException(e);
                  });
                })
                .catch((err) => {
                  hub.withScope(scope => {
                    scope.addBreadcrumb({
                      message: 'Не удалось получить данные о квоте на текущее устройство  '
                    });
                    hub.captureException(err);
                  });
                });
            });
          // const storageEstimate = StorageManager.estimate();
          resolve();
        });
    });
  }

  public LoadClients() {
    console.log('LOAD CLIENTS');
    this.logger.AddBreadcrumb('Начало загрузки клиентов');

    return new Promise(resolve => {
      this.clientsLoading = true;
      this.logger.AddBreadcrumb('Получение данных об агентских кабинетах');
      const promises = this.accountsViewModels.filter(x => x.account_type === 'agency')
        .map((account, i) => {
          this.logger.AddBreadcrumb(`Получение данных о клиентах агентского кабинета ${account.account_id}`);
          return new Promise(resolve1 => {
            setTimeout(() => {
              this._api.getClients(account.account_id)
                .subscribe(clients => {
                  this.logger.AddBreadcrumb(`Данные о клиентах агентского кабинета ${account.account_id} получены.`);
                  this.logger.AddBreadcrumb(`Получено: ${clients}`);
                  clients.data.forEach((client: Client) => {
                    this.accountsViewModels.push(new AccountViewModel(
                      account.account_id,
                      client.name,
                      account.account_role,
                      account.account_status,
                      'client',
                      account.is_blocked,
                      client.id,
                      account.priority
                    ));
                  });
                  this.logger.AddBreadcrumb(`Клиенты добавлены в хранилище`);

                  const clientsData = clients.data.map(x => {
                    x.account_id = account.account_id;
                    return x;
                  });
                  console.log(clientsData, 'CLIENTS DATA');
                  this.localClientsDb.AddClients(clientsData)
                    .then(data => {
                      resolve1();
                    })
                    .catch(e => {
                      navigator.storage.estimate()
                        .then(data => {
                          // data.userAgent = navigation.userAgent;
                          hub.withScope(scope => {
                            scope.addBreadcrumb({
                              data,
                              message: 'Не удалось добавить клиентов в локальную бд'
                            });
                            hub.captureException(e);
                          });
                        });
                    });
                  this.logger.Log('Завершение процеса добавления клиентов');
                }, err => {
                  this.logger.Error(`Клиенты не получены`, err);
                });
            }, 1500 * i);
          });
        });
      return Promise.all(promises)
        .then(() => {
          this.clientsLoading = false;
          resolve();
        });
    });
  }

  public LoadStatistic() {
    return new Promise(resolve => {
      console.log(this.slots.filter(x => x.bindedCabinetId !== null), 'LOG');
      const promises = this.slots.filter(x => x.bindedCabinetId !== null)
        .map((x, i) => {
          setTimeout(() => {
            return new Promise((resolve1) => {
              const cabinet = this.accountsViewModels
                .find(a => a.account_id === x.bindedCabinetId && a.client_id === x.bindedClientId);
              console.log(this.accountsViewModels.find(x => x.client_id === 1605088790));
              // console.log((x.bindedClientId || x.bindedCabinetId).toString(), cabinet.account_name);
              return this._api.GetStatistics({
                accountId: x.bindedCabinetId,
                clientId: x.bindedClientId,
                idsType: x.bindedClientId ? 'client' : 'office',
                ids: (x.bindedClientId || x.bindedCabinetId).toString(),
                dateFrom: '0',
                dateTo: '0',
                period: 'overall'
              })
                .subscribe((y) => {
                  const stats = y.data[0].stats[0];
                  if (y.userMeta) {
                    this.IsExpired = y.userMeta.isExpired;
                  }
                  if (cabinet) {
                    if (stats) {
                      cabinet.clicks = stats.clicks;
                      cabinet.spent = stats.spent;
                      cabinet.ctr = (stats.impressions > 0) ? (stats.clicks / stats.impressions * 100) : 0;
                      cabinet.impressions = stats.impressions;
                    }
                    cabinet.loaded = true;
                  }
                  resolve1();
                }, (err) => {
                  this.accessManager.SetOccured(err.error);
                  if (cabinet) {
                    cabinet.is_blocked = true;
                  }
                  resolve1();
                });
            })
          }, 1000 * i)
        })
      // const promises = this.accountsViewModels
      //   .filter(x => !x.is_blocked && x.account_type !== 'agency')
      //   .map((x, i) => {
      //     return new Promise(resolve1 => {
      //       setTimeout(() => {
      //         let idsType = 'office';
      //         let ids = null;
      //         if (x && x.account_type === 'client') {
      //           idsType = 'client';
      //           ids = x.client_id.toString();
      //         } else {
      //           ids = x.account_id.toString();
      //         }
      //         return this._api.GetStatistics({
      //           accountId: x.account_id,
      //           idsType: idsType,
      //           dateTo: '0',
      //           ids: ids,
      //           dateFrom: '0',
      //           period: 'overall',
      //           clientId: x.client_id
      //         }).subscribe((y) => {
      //           const stats = y.data[0].stats[0];
      //           if (y.userMeta) {
      //             this.IsExpired = y.userMeta.isExpired;
      //           }
      //           if (stats) {
      //             x.clicks = stats.clicks;
      //             x.spent = stats.spent;
      //             x.ctr = (stats.impressions > 0) ? (stats.clicks / stats.impressions * 100) : 0;
      //             x.impressions = stats.impressions;
      //           }
      //           x.loaded = true;
      //           resolve1();
      //         }, (err) => {
      //           this.accessManager.SetOccured(err.error);
      //           x.is_blocked = true;
      //           resolve1();
      //         });
      //       }, i * 1000);
      //     });
      //   });
      Promise
        .all(promises)
        .then(() => {
          resolve();
        })
        .catch(err => {
          console.log(err, 'CATCH ERROR');
        });
    });
  }

  public LoadSlots() {
    return new Promise(resolve => {
      this._db.GetSlots()
        .subscribe(x => {
          this.slots = x.data;
          this.countOfMaxSlots = this.slots.length;
          if (this.firstLoading) {
            this.opened = this.slots.filter(slot => slot.bindedCabinetId !== null).length === 0;
          }
          this.countOfFree = this.slots.filter(slot => slot.bindedCabinetId === null).length;
          this.firstLoading = false;
          resolve();
        });
    });
  }

  OnSaveCabinetsBined(data: any) {
    if (!data.isSelected) {
      this._db.AssignCabinetToSlot({
        id: data.account_id,
        clientId: data.client_id
      })
        .subscribe(data1 => {
          this.LoadData();
        }, (err) => {
          this.pickList.SetError(err.error);
        });
    } else {
      this._db.UnBindCabinetFromSlot({
        id: data.account_id,
        clientId: data.client_id
      })
        .subscribe(data1 => {
          this.LoadData();
        }, (err) => {
          this.pickList.SetError(err.error);
        });
    }
    // this.LoadSlots();
  }

  OnClose() {
    this.ToggleOpened();
  }

  public SummaryFunction(data: Array<number>) {
    if (data != null) {
      return data.reduce((a, b) => a + b, 0);
    }
    return 0;
  }

  public SummaryTitleFunction(data) {
    return 'Итого:';
  }

  public CurrencyFunction(data) {
    if (!isNaN(data)) {
      return new CurrencyPipe('ru').transform(data, ' Руб.');
    }
    return '';
  }

  public PaginSummaryTitleFunction() {
    return 'Итого на странице:';
  }

  public NumberFunction(data) {
    if (!isNaN(data)) {
      return new DecimalPipe('ru').transform(data, '0.0-2');
    }
    return '';
  }

  public PercentFunction(data) {
    if (!isNaN(data)) {
      return new DecimalPipe('ru').transform(data, '0.0-2') + '%';
    }
    return '';
    // return new DecimalPipe('ru').transform(data, '0.0-2') + ' %'
  }

  public AvgSummaryFunction(data: Array<number>) {
    if (data != null) {
      return (data.reduce((a, b) => a + b, 0) / data.length).toFixed(2);
    }
    return 0;
  }

  public CtrFormatFunction(data) {
    if (!isNaN(data) && data !== null) {
      return new DecimalPipe('ru').transform(data, '0.3') + '%';
    }
  }
}
