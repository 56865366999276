import {Injectable} from '@angular/core';
import {PaymentsSystem} from './payment-system.model';
import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';

export interface PaymentSystemsState extends EntityState<PaymentsSystem> {
  ui: {
    linkToPayment: string,
    prepareLinkLoading: boolean
  };
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'payment-systems'})
export class PaymentSystemsStore extends EntityStore<PaymentSystemsState> {

  constructor() {
    super({
      ui: {
        linkToPayment: null,
        prepareLinkLoading: false
      }
    });
  }

  /**
   * Установить индикатор загрузки ссылки
   * @param status - статус загрузки
   */
  setPrepareLinkLoading(status) {
    this.update(state => ({ui: {...state.ui, prepareLinkLoading: status}}));
  }

}

