import {NgModule, TemplateRef} from '@angular/core';
import {CommonModule} from '@angular/common';

import {AutomationRoutingModule} from './automation-routing.module';
import {HttpClientModule} from '@angular/common/http';
import {NewAdManagerModule} from './new-ad-manager/new-ad-manager.module';
import {AutomationHomeComponent} from './routes/automation-home/automation-home.component';
import {ApiService} from './services/api.service';
import {AutomationWrapperComponent} from '../shared/components/automation-wrapper/automation-wrapper.component';
import {AutomationAccountsComponent} from './routes/automation-accounts/automation-accounts.component';
import {AutomationCampaignsComponent} from './routes/automation-campaigns/automation-campaigns.component';
import {AutomationAdsComponent} from './routes/automation-ads/automation-ads.component';
import {ApiModule} from '../api/api.module';
import {ClarityModule} from '@clr/angular';
import {DateFilterComponent} from './components/forms/date-filter/date-filter.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '../shared/shared.module';
import {YandexMetrikaWrapperService} from './services/yandex-metrika-wrapper.service';
import {AutomationHeaderComponent} from './components/automation-header/automation-header.component';
import {AnalyticServicesHeaderComponent} from './components/analytic-services-header/analytic-services-header.component';
import {AccessExpirationGuard} from './guards/access-expiration.guard';
import {ExpirationManagerService} from './services/expiration-manager.service';
import {SlotsFormComponent} from './components/slots-form/slots-form.component';
import {PickListComponent} from './components/pick-list/pick-list.component';
import {ExcelService} from './services/excel-service.service';
import {AutomationExpirationManagerService} from './services/automation-expiration-manager.service';
import {NewAutomationAdsComponent} from './routes/new-automation-ads/new-automation-ads.component';
import {NgrUiModule} from '../ngr-ui/ngr-ui.module';
import {BidFormGeneratorComponent} from './components/bid-form-generator/bid-form-generator.component';
import {AdMetaInfoComponent} from './components/ad-meta-info/ad-meta-info.component';
import {StrategySettingsComponent} from './components/strategy-settings/strategy-settings.component';
import {AdblockService} from './services/adblock.service';
// import {StrategyParamsComponent} from './components/strategy-params/strategy-params.component';
import {DatepickerComponent} from './components/datepicker/datepicker.component';
import {NgxMyDatePickerModule} from 'ngx-mydatepicker';
import {NewDateFilterComponent} from './components/forms/new-date-filter/new-date-filter.component';
import {InstructionsComponent} from './components/instructions/instructions.component';
import {AdManagerModule} from './ad-manager/ad-manager.module';
import {ReadyComponent} from './ad-manager/components/steps/ready/ready.component';
import {
  MatButtonModule, MatButtonToggleModule,
  MatCheckboxModule, MatDialogModule,
  MatGridListModule,
  MatIconModule, MatListModule,
  MatMenuModule, MatProgressBarModule,
  MatProgressSpinnerModule, MatRadioModule, MatSelectModule,
  MatTooltipModule
} from '@angular/material';
import {FlexLayoutModule} from '@angular/flex-layout';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {ModerationDetailsComponent} from './ad-manager/components/moderation-details/moderation-details.component';
import {StrategyPreviewComponent} from '../shared/components/strategy-preview/strategy-preview.component';
import {ModerationModalComponent} from './components/moderation-modal/moderation-modal.component';
import {EasyAdCardComponent} from './components/easy-ad-card/easy-ad-card.component';
import {EasyAdsDashboardComponent} from './routes/easy-ads-dashboard/easy-ads-dashboard.component';
import {EasyAdPreviewTeaserComponent} from './components/easy-ad-preview-teaser/easy-ad-preview-teaser.component';
import {EasyAdPreviewPromoComponent} from './components/easy-ad-preview-promo/easy-ad-preview-promo.component';
import {EasyAdsDataService} from './services/easy-ads-data.service';
import {MultipleCopyFormComponent} from './components/forms/mltiple-copy-form/multiple-copy-form.component';
import {VkControlsModule} from '../vk-controls/vk-controls.module';
import { AdCostComponent } from './components/ad-cost/ad-cost.component';
import {IncredibleCopyNotificationComponent} from './components/modals/incredible-copy-notification/incredible-copy-notification.component';
import { NotRemovedStrategiesModalComponent } from './components/modals/not-removed-strategies-modal/not-removed-strategies-modal.component';
import { StrategySelectionFormComponent } from './components/strategy-selection-form/strategy-selection-form.component';
import { StrategyInputsFormComponent } from './components/strategy-inputs-form/strategy-inputs-form.component';
import { StrategyCreationFormComponent } from './components/strategy-creation-form/strategy-creation-form.component';
import { StrategyCreationFormInputComponent } from './components/strategy-creation-form-input/strategy-creation-form-input.component';
import {ScrollingModule} from "@angular/cdk/scrolling";
import { TestingRouteComponent } from './routes/testing-route/testing-route.component';
import {CopyingModuleModule} from "./copying-module/copying-module.module";
import { LoadingDialogComponent } from './components/modals/loading-dialog/loading-dialog.component';
import {MatConfirmDialogComponent} from "./ad-manager/components/modals/configrm-dialog/mat-confirm-dialog.component";
import {TestingComponentsComponent} from "./routes/testing-components/testing-components.component";

@NgModule({
  imports: [
    CommonModule,
    AutomationRoutingModule,
    HttpClientModule,
    ApiModule,
    ClarityModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    NgrUiModule,
    NgxMyDatePickerModule.forRoot(),
    AdManagerModule,
    FlexLayoutModule,
    NgxMatSelectSearchModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatTooltipModule,
    MatMenuModule,
    MatButtonModule,
    MatRadioModule,
    VkControlsModule,
    MatProgressBarModule,
    NewAdManagerModule,
    MatDialogModule,
    MatListModule,
    ScrollingModule,
    MatButtonToggleModule,
    MatSelectModule,
    CopyingModuleModule
  ],
  declarations: [
    AutomationHomeComponent,
    AutomationAccountsComponent,
    AutomationCampaignsComponent,
    AutomationAdsComponent,
    DateFilterComponent,
    AutomationHeaderComponent,
    AnalyticServicesHeaderComponent,
    SlotsFormComponent,
    PickListComponent,
    NewAutomationAdsComponent,
    BidFormGeneratorComponent,
    AdMetaInfoComponent,
    StrategySettingsComponent,
    DatepickerComponent,
    NewDateFilterComponent,
    InstructionsComponent,
    ModerationModalComponent,
    EasyAdCardComponent,
    EasyAdsDashboardComponent,
    EasyAdPreviewTeaserComponent,
    EasyAdPreviewPromoComponent,
    MultipleCopyFormComponent,
    AdCostComponent,
    IncredibleCopyNotificationComponent,
    NotRemovedStrategiesModalComponent,
    StrategySelectionFormComponent,
    StrategyInputsFormComponent,
    StrategyCreationFormComponent,
    StrategyCreationFormInputComponent,
    TestingRouteComponent,
    LoadingDialogComponent,
    TestingComponentsComponent
  ],
  providers: [
    DatepickerComponent,
    NewDateFilterComponent,
    ApiService,
    YandexMetrikaWrapperService,
    AccessExpirationGuard,
    ExpirationManagerService,
    ExcelService,
    AutomationExpirationManagerService,
    AdblockService,
    EasyAdsDataService
  ],
  entryComponents: [
    ModerationModalComponent,
    ModerationDetailsComponent,
    MultipleCopyFormComponent,
    IncredibleCopyNotificationComponent,
    NotRemovedStrategiesModalComponent,
    MatConfirmDialogComponent,
    LoadingDialogComponent
  ],
  exports: [
    NewDateFilterComponent,
    DatepickerComponent,
  ]
})
export class AutomationModule {
}
