import { LeadsService } from "../../../api/services";
import * as i0 from "@angular/core";
import * as i1 from "../../../api/services/leads.service";
var AdminUsersService = /** @class */ (function () {
    function AdminUsersService(leadsService) {
        this.leadsService = leadsService;
    }
    AdminUsersService.prototype.LoadUsers = function () {
    };
    AdminUsersService.prototype.LoadUser = function () {
    };
    AdminUsersService.prototype.CreateCall = function (callData) {
        console.log('CREATE CALL');
        return this.leadsService.CreateCall(callData);
    };
    AdminUsersService.prototype.LoadCallsByUserId = function (userId) {
        return this.leadsService.GetUserCalls(userId);
    };
    AdminUsersService.ngInjectableDef = i0.defineInjectable({ factory: function AdminUsersService_Factory() { return new AdminUsersService(i0.inject(i1.LeadsService)); }, token: AdminUsersService, providedIn: "root" });
    return AdminUsersService;
}());
export { AdminUsersService };
