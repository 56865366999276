import { ChangeDetectorRef, EventEmitter, OnInit } from '@angular/core';
import { PromopostViewModel } from '../../../models/promopost.view-model';
import { ControlContainer } from '@angular/forms';
import { ImagesManagerComponent } from '../../images-manager/images-manager.component';
import { AutomationVkService } from '../../../../../api/services/automation-vk.service';
var CreatePromopostComponent = /** @class */ (function () {
    function CreatePromopostComponent(controlContainer, cd, api) {
        this.controlContainer = controlContainer;
        this.cd = cd;
        this.api = api;
        this.promopost = {
            text: 'Здесь будет текст вашего поста',
            image: 'https://via.placeholder.com/506x460',
            groupId: '',
            albumId: '',
            linkTitle: '',
            linkAddress: ''
        };
        this.isUpdating = null;
        this.promopostChange = new EventEmitter();
        this.ControlAdded = new EventEmitter();
        this.ControlRemoved = new EventEmitter();
        this.onNext = new EventEmitter();
        this.selectedGroup = null;
        this.type = 'linear';
    }
    Object.defineProperty(CreatePromopostComponent.prototype, "Posts", {
        get: function () {
            var _this = this;
            var promoposts = [];
            var images = this.controlContainer.control.get('image').value;
            var texts = this.controlContainer.control.get('text').value;
            if (this.controlContainer.control.get('type').value === 'linear') {
                var min = Math.min(images.length, texts.length);
                for (var i = 0; i < min; i++) {
                    promoposts.push({
                        promopost: this.controlContainer.control.value,
                        image: images[i],
                        text: texts[i]
                    });
                }
            }
            else if (this.controlContainer.control.get('type').value === 'quadratic') {
                images.forEach(function (image) {
                    texts.forEach(function (text) {
                        promoposts.push({
                            promopost: _this.controlContainer.control.value,
                            image: image,
                            text: text
                        });
                    });
                });
            }
            return promoposts;
        },
        enumerable: true,
        configurable: true
    });
    CreatePromopostComponent.prototype.ngOnInit = function () {
        // if (this.isUpdating) {
        //   console.log(this.controlContainer.control.value.groupId, 'GROUP ID');
        //   this.api
        //     ([this.controlContainer.control.value.groupId])
        //     .subscribe(data => {
        //       this.selectedGroup = data[0];
        //     });
        // }
        this.controlContainer.control.get('text').valueChanges.subscribe(function (data) {
        });
    };
    CreatePromopostComponent.prototype.clickNext = function () {
        this.promopostChange.emit(this.promopost);
        this.onNext.emit();
    };
    CreatePromopostComponent.prototype.onGroupSelect = function ($event) {
        this.selectedGroup = $event;
    };
    CreatePromopostComponent.prototype.OnControlAdd = function () {
        this.ControlAdded.emit();
        this.cd.detectChanges();
    };
    CreatePromopostComponent.prototype.OnControlRemove = function (id) {
        this.ControlRemoved.emit(id);
    };
    CreatePromopostComponent.prototype.ImageSelected = function ($event) {
        this.controlContainer.control.get('image').enable();
        this.controlContainer.control.get('image').setValue($event);
        this.controlContainer.control.updateValueAndValidity(this.controlContainer.control.value);
        // console.log(this.controlContainer.control.get('text').controls[0]);
        // this.controlContainer.control.get('text').controls[0].markAsDirty();
        this.controlContainer.control.get('image').markAsDirty();
        console.log(this.controlContainer.control.get('image'), '94');
    };
    CreatePromopostComponent.prototype.ImageRemove = function ($event) {
        this.ImagesManager.OnSelectImage($event);
    };
    return CreatePromopostComponent;
}());
export { CreatePromopostComponent };
