import { OnInit } from '@angular/core';
var MultipleEditRejectComponent = /** @class */ (function () {
    function MultipleEditRejectComponent(data) {
        this.data = data;
    }
    Object.defineProperty(MultipleEditRejectComponent.prototype, "Data", {
        get: function () {
            var _this = this;
            return Object.keys(this.data)
                .filter(function (key) { return _this.data[key] !== null; })
                .filter(function (key) { return ({ key: key, value: _this.data[key] }); })
                .reduce(function (arr, curr) { return Object.assign(arr, curr); }, {});
        },
        enumerable: true,
        configurable: true
    });
    MultipleEditRejectComponent.prototype.ngOnInit = function () {
    };
    return MultipleEditRejectComponent;
}());
export { MultipleEditRejectComponent };
