/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PostMonitorBackend_V2ModelsAdminViewModelsUserViewModel } from '../models/post-monitor-backend-_v2models-admin-view-models-user-view-model';
import { Comment } from '../models/comment';
import { PostMonitorBackend_V2ModelsAdminViewModelsUserReportViewModel } from '../models/post-monitor-backend-_v2models-admin-view-models-user-report-view-model';
import { PostMonitorBackend_V2ModelsCrmEntitesLead } from '../models/post-monitor-backend-_v2models-crm-entites-lead';
import { PostMonitorBackend_V2ModelsCrmEntitesLeadDetailData } from '../models/post-monitor-backend-_v2models-crm-entites-lead-detail-data';
@Injectable({
  providedIn: 'root',
})
class UsersService extends __BaseService {
  static readonly GetAdminUsersInfoPath = '/apiv2/admin/users';
  static readonly CreateCommentPath = '/apiv2/admin/users';
  static readonly GetNewUsersPath = '/apiv2/admin/users/new';
  static readonly GetUserInfoByIdPath = '/apiv2/admin/users/{id}';
  static readonly GetUserCommentsPath = '/apiv2/admin/users/comments';
  static readonly AddInterestsPath = '/apiv2/admin/users/{id}/interests';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `UsersService.GetAdminUsersInfoParams` containing the following parameters:
   *
   * - `dateTo`:
   *
   * - `dateFrom`:
   *
   * @return Success
   */
  GetAdminUsersInfoResponse(params: UsersService.GetAdminUsersInfoParams): __Observable<__StrictHttpResponse<Array<PostMonitorBackend_V2ModelsAdminViewModelsUserViewModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.dateTo != null) __params = __params.set('dateTo', params.dateTo.toString());
    if (params.dateFrom != null) __params = __params.set('dateFrom', params.dateFrom.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/admin/users`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PostMonitorBackend_V2ModelsAdminViewModelsUserViewModel>>;
      })
    );
  }
  /**
   * @param params The `UsersService.GetAdminUsersInfoParams` containing the following parameters:
   *
   * - `dateTo`:
   *
   * - `dateFrom`:
   *
   * @return Success
   */
  GetAdminUsersInfo(params: UsersService.GetAdminUsersInfoParams): __Observable<Array<PostMonitorBackend_V2ModelsAdminViewModelsUserViewModel>> {
    return this.GetAdminUsersInfoResponse(params).pipe(
      __map(_r => _r.body as Array<PostMonitorBackend_V2ModelsAdminViewModelsUserViewModel>)
    );
  }

  /**
   * @param comment undefined
   * @return Success
   */
  CreateCommentResponse(comment?: Comment): __Observable<__StrictHttpResponse<Comment>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = comment;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/apiv2/admin/users`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Comment>;
      })
    );
  }
  /**
   * @param comment undefined
   * @return Success
   */
  CreateComment(comment?: Comment): __Observable<Comment> {
    return this.CreateCommentResponse(comment).pipe(
      __map(_r => _r.body as Comment)
    );
  }

  /**
   * @param params The `UsersService.GetNewUsersParams` containing the following parameters:
   *
   * - `dateTo`:
   *
   * - `dateFrom`:
   *
   * @return Success
   */
  GetNewUsersResponse(params: UsersService.GetNewUsersParams): __Observable<__StrictHttpResponse<Array<PostMonitorBackend_V2ModelsAdminViewModelsUserReportViewModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.dateTo != null) __params = __params.set('dateTo', params.dateTo.toString());
    if (params.dateFrom != null) __params = __params.set('dateFrom', params.dateFrom.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/admin/users/new`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PostMonitorBackend_V2ModelsAdminViewModelsUserReportViewModel>>;
      })
    );
  }
  /**
   * @param params The `UsersService.GetNewUsersParams` containing the following parameters:
   *
   * - `dateTo`:
   *
   * - `dateFrom`:
   *
   * @return Success
   */
  GetNewUsers(params: UsersService.GetNewUsersParams): __Observable<Array<PostMonitorBackend_V2ModelsAdminViewModelsUserReportViewModel>> {
    return this.GetNewUsersResponse(params).pipe(
      __map(_r => _r.body as Array<PostMonitorBackend_V2ModelsAdminViewModelsUserReportViewModel>)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetUserInfoByIdResponse(id: number): __Observable<__StrictHttpResponse<PostMonitorBackend_V2ModelsCrmEntitesLead>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/admin/users/${(id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PostMonitorBackend_V2ModelsCrmEntitesLead>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetUserInfoById(id: number): __Observable<PostMonitorBackend_V2ModelsCrmEntitesLead> {
    return this.GetUserInfoByIdResponse(id).pipe(
      __map(_r => _r.body as PostMonitorBackend_V2ModelsCrmEntitesLead)
    );
  }

  /**
   * @param userId undefined
   * @return Success
   */
  GetUserCommentsResponse(userId: number): __Observable<__StrictHttpResponse<Array<Comment>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (userId != null) __params = __params.set('userId', userId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/admin/users/comments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Comment>>;
      })
    );
  }
  /**
   * @param userId undefined
   * @return Success
   */
  GetUserComments(userId: number): __Observable<Array<Comment>> {
    return this.GetUserCommentsResponse(userId).pipe(
      __map(_r => _r.body as Array<Comment>)
    );
  }

  /**
   * @param params The `UsersService.AddInterestsParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   *
   * @return Success
   */
  AddInterestsResponse(params: UsersService.AddInterestsParams): __Observable<__StrictHttpResponse<Array<PostMonitorBackend_V2ModelsCrmEntitesLeadDetailData>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/apiv2/admin/users/${(params.id)}/interests`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PostMonitorBackend_V2ModelsCrmEntitesLeadDetailData>>;
      })
    );
  }
  /**
   * @param params The `UsersService.AddInterestsParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   *
   * @return Success
   */
  AddInterests(params: UsersService.AddInterestsParams): __Observable<Array<PostMonitorBackend_V2ModelsCrmEntitesLeadDetailData>> {
    return this.AddInterestsResponse(params).pipe(
      __map(_r => _r.body as Array<PostMonitorBackend_V2ModelsCrmEntitesLeadDetailData>)
    );
  }
}

module UsersService {

  /**
   * Parameters for GetAdminUsersInfo
   */
  export interface GetAdminUsersInfoParams {
    dateTo: string;
    dateFrom: string;
  }

  /**
   * Parameters for GetNewUsers
   */
  export interface GetNewUsersParams {
    dateTo: string;
    dateFrom: string;
  }

  /**
   * Parameters for AddInterests
   */
  export interface AddInterestsParams {
    id: number;
    data?: Array<PostMonitorBackend_V2ModelsCrmEntitesLeadDetailData>;
  }
}

export { UsersService }
