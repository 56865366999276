var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { map as __map, filter as __filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../api-configuration";
import * as i2 from "@angular/common/http";
var IntegrationAccessService = /** @class */ (function (_super) {
    __extends(IntegrationAccessService, _super);
    function IntegrationAccessService(config, http) {
        return _super.call(this, config, http) || this;
    }
    /**
     * @param params The `IntegrationAccessService.GetIntegrationsAccessSessionsParams` containing the following parameters:
     *
     * - `sortByDescend`:
     *
     * - `offset`:
     *
     * - `limit`:
     *
     * - `accessToBeforeOrEqual`:
     *
     * - `accessToAfterOrEqual`:
     *
     * - `accessFromBeforeOrEqual`:
     *
     * - `accessFromAfterOrEqual`:
     *
     * - `updatedBefore`:
     *
     * - `updatedAfter`:
     *
     * - `ids`:
     *
     * - `createdBefore`:
     *
     * - `createdAfter`:
     *
     * - `accessToBefore`:
     *
     * - `accessToAfter`:
     *
     * - `accessFromBefore`:
     *
     * - `accessFromAfter`:
     *
     * @return Success
     */
    IntegrationAccessService.prototype.GetIntegrationsAccessSessionsResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.sortByDescend != null)
            __params = __params.set('sortByDescend', params.sortByDescend.toString());
        if (params.offset != null)
            __params = __params.set('offset', params.offset.toString());
        if (params.limit != null)
            __params = __params.set('limit', params.limit.toString());
        if (params.accessToBeforeOrEqual != null)
            __params = __params.set('accessToBeforeOrEqual', params.accessToBeforeOrEqual.toString());
        if (params.accessToAfterOrEqual != null)
            __params = __params.set('accessToAfterOrEqual', params.accessToAfterOrEqual.toString());
        if (params.accessFromBeforeOrEqual != null)
            __params = __params.set('accessFromBeforeOrEqual', params.accessFromBeforeOrEqual.toString());
        if (params.accessFromAfterOrEqual != null)
            __params = __params.set('accessFromAfterOrEqual', params.accessFromAfterOrEqual.toString());
        if (params.updatedBefore != null)
            __params = __params.set('updatedBefore', params.updatedBefore.toString());
        if (params.updatedAfter != null)
            __params = __params.set('updatedAfter', params.updatedAfter.toString());
        (params.ids || []).forEach(function (val) { if (val != null)
            __params = __params.append('ids', val.toString()); });
        if (params.createdBefore != null)
            __params = __params.set('createdBefore', params.createdBefore.toString());
        if (params.createdAfter != null)
            __params = __params.set('createdAfter', params.createdAfter.toString());
        if (params.accessToBefore != null)
            __params = __params.set('accessToBefore', params.accessToBefore.toString());
        if (params.accessToAfter != null)
            __params = __params.set('accessToAfter', params.accessToAfter.toString());
        if (params.accessFromBefore != null)
            __params = __params.set('accessFromBefore', params.accessFromBefore.toString());
        if (params.accessFromAfter != null)
            __params = __params.set('accessFromAfter', params.accessFromAfter.toString());
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/integration-access", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `IntegrationAccessService.GetIntegrationsAccessSessionsParams` containing the following parameters:
     *
     * - `sortByDescend`:
     *
     * - `offset`:
     *
     * - `limit`:
     *
     * - `accessToBeforeOrEqual`:
     *
     * - `accessToAfterOrEqual`:
     *
     * - `accessFromBeforeOrEqual`:
     *
     * - `accessFromAfterOrEqual`:
     *
     * - `updatedBefore`:
     *
     * - `updatedAfter`:
     *
     * - `ids`:
     *
     * - `createdBefore`:
     *
     * - `createdAfter`:
     *
     * - `accessToBefore`:
     *
     * - `accessToAfter`:
     *
     * - `accessFromBefore`:
     *
     * - `accessFromAfter`:
     *
     * @return Success
     */
    IntegrationAccessService.prototype.GetIntegrationsAccessSessions = function (params) {
        return this.GetIntegrationsAccessSessionsResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `IntegrationAccessService.AppendIntegrationsAccessSessionsParams` containing the following parameters:
     *
     * - `userId`:
     *
     * - `accessTo`:
     *
     * - `accessFrom`:
     *
     * @return Success
     */
    IntegrationAccessService.prototype.AppendIntegrationsAccessSessionsResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.userId != null)
            __params = __params.set('userId', params.userId.toString());
        if (params.accessTo != null)
            __params = __params.set('accessTo', params.accessTo.toString());
        if (params.accessFrom != null)
            __params = __params.set('accessFrom', params.accessFrom.toString());
        var req = new HttpRequest('POST', this.rootUrl + "/apiv2/integration-access", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `IntegrationAccessService.AppendIntegrationsAccessSessionsParams` containing the following parameters:
     *
     * - `userId`:
     *
     * - `accessTo`:
     *
     * - `accessFrom`:
     *
     * @return Success
     */
    IntegrationAccessService.prototype.AppendIntegrationsAccessSessions = function (params) {
        return this.AppendIntegrationsAccessSessionsResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @return Success
     */
    IntegrationAccessService.prototype.GetCurrentAccessSessionResponse = function () {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/integration-access/current", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @return Success
     */
    IntegrationAccessService.prototype.GetCurrentAccessSession = function () {
        return this.GetCurrentAccessSessionResponse().pipe(__map(function (_r) { return _r.body; }));
    };
    IntegrationAccessService.GetIntegrationsAccessSessionsPath = '/apiv2/integration-access';
    IntegrationAccessService.AppendIntegrationsAccessSessionsPath = '/apiv2/integration-access';
    IntegrationAccessService.GetCurrentAccessSessionPath = '/apiv2/integration-access/current';
    IntegrationAccessService.ngInjectableDef = i0.defineInjectable({ factory: function IntegrationAccessService_Factory() { return new IntegrationAccessService(i0.inject(i1.ApiConfiguration), i0.inject(i2.HttpClient)); }, token: IntegrationAccessService, providedIn: "root" });
    return IntegrationAccessService;
}(__BaseService));
export { IntegrationAccessService };
