<div class="dialog-header">
  <h1 mat-dialog-title>Создание кампании</h1>
  <div class="dialog-header__divider"></div>
  <!--<a href="javascript:void(0)" (click)="ToggleAdvanced()">Использовать расширенные настройки</a>-->
  <button mat-dialog-close mat-icon-button matRipple="none">
    <mat-icon>
      close
    </mat-icon>
  </button>
</div>
<div mat-dialog-content>
  <ng-container [ngSwitch]="state">
    <ng-container *ngSwitchCase="'form'">
      <form [formGroup]="form" class="form" *ngIf="!isSuccessed; else successed">

        <mat-form-field appearance="standard">
          <mat-label>Название</mat-label>
          <input matInput formControlName="name"/>
        </mat-form-field>

        <ng-container *ngIf="isAdvanced">
          <mat-form-field>
            <mat-label>Дневной лимит (рублей)</mat-label>
            <input matInput formControlName="day_limit"/>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Общий лимит (рублей)</mat-label>
            <input matInput formControlName="all_limit"/>
          </mat-form-field>

          <mat-checkbox formControlName="status">Запустить объявление</mat-checkbox>
        </ng-container>
      </form>
    </ng-container>
    <ng-container *ngSwitchCase="'loading'">
      <mat-spinner></mat-spinner>
      Ваш запрос обрабатывается. Пожалуйста подождите ...
    </ng-container>
    <ng-container *ngSwitchCase="'success'">
      Кампания успешно создана
    </ng-container>
    <ng-container *ngSwitchCase="'error'">
      Во время создания кампании произошла ошибка
    </ng-container>
  </ng-container>
</div>

<div mat-dialog-actions *ngIf="state === 'form'">
  <button mat-button mat-flat-button color="primary" (click)="Save()" [disabled]="form.invalid">Создать</button>
  <button mat-button mat-flat-button color="warn" (click)="Close()">Отмена</button>
</div>

<div mat-dialog-actions *ngIf="state === 'success'">
  <button mat-button mat-flat-button color="primary" (click)="Ok()">Ок</button>
</div>
