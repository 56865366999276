import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {ChatConversationMessagesService} from "../../services/chat-conversation-messages.service";

@Component({
  selector: 'app-conversation',
  templateUrl: './conversation.component.html',
  styleUrls: ['./conversation.component.scss']
})
export class ConversationComponent implements OnInit {
  public conversationWithUserId: number = null;

  public featureBanExpand: boolean = false;
  public featureBanRequireConfirmation: boolean = false;
  public featureBanReason: string = null;

  public sendNotificationOverEmail: boolean = false;

  constructor(private route: ActivatedRoute, public conversationMessagesService: ChatConversationMessagesService) {
    const parameter = this.route.snapshot.queryParamMap.get('user_id');

    if (parameter && parameter.length > 0) {
      this.conversationWithUserId = parseInt(parameter, 10);
    }

    this.conversationMessagesService.GetMessages(this.conversationWithUserId);
  }

  ngOnInit() {
  }

  public GetIconClassname(state: boolean): string {
    return `option-icon icon ion-md-arrow-${(state) ? 'dropdown' : 'dropright'}`;
  }

  public BanUser(): void {
    console.log(`Baning user ${this.conversationWithUserId}`);

    if (!this.conversationWithUserId)
      return;

    this.conversationMessagesService.BanConversation(this.conversationWithUserId, this.featureBanReason);

    this.featureBanExpand = false;
    this.featureBanRequireConfirmation = false;
    this.featureBanReason = null;
  }
}
