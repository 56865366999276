import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ControlContainer, FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {PromopostViewModel} from '../../models/promopost.view-model';
import {Observable} from 'rxjs/Observable';
import {AdManagerService} from '../../../../api/services';
import {emojiCount} from '../../validators/url-validator';
import {UtilsService} from '../../../../shared/services/utils.service';
import 'rxjs-compat/add/operator/distinctUntilChanged';
import 'rxjs-compat/add/operator/filter';
import {ActivatedRoute} from '@angular/router';
import {delay} from 'rxjs/operators';
import {PostificatePipe} from '../../../../shared/pipes/postificate.pipe';

@Component({
  selector: 'app-create-promopost-form',
  templateUrl: './create-promopost-form.component.html',
  styleUrls: ['./create-promopost-form.component.scss']
})
export class CreatePromopostFormComponent implements OnInit {

  constructor(
    private adManagerService: AdManagerService,
    public controlContainer: ControlContainer,
    private route: ActivatedRoute,
  ) {
  }

  @Input() promopost: PromopostViewModel;
  @Input() isUpdating: boolean = false;
  @Output() promopostChange: EventEmitter<PromopostViewModel> = new EventEmitter<PromopostViewModel>();
  @Output() onGroupSelect: EventEmitter<any> = new EventEmitter<any>();

  @Output() public ControlAdded: EventEmitter<any> = new EventEmitter();
  @Output() public ControlRemoved: EventEmitter<any> = new EventEmitter();

  @Input() public categories;

  public errors: any = {};

  public form: FormGroup;
  public groupAlbums$: Observable<any>;

  public userGroups: any = [];

  public get PromopostTexts(): any {
    return (this.controlContainer.control.get('text') as FormArray).controls;
  }

  ngOnInit() {
    const {account_id, client_id, adId} = this.route.snapshot.queryParams;

    this.adManagerService.GetAdminGroups()
      .pipe(
        delay(1000)
      )
      .subscribe((data: any) => {

      if (data !== null) {
        this.userGroups = data.sort((firstGroup, secondGroup) => firstGroup.is_closed - secondGroup.is_closed);
        if (this.isUpdating) {
          const selectedGroup = this.userGroups.find(group => group.id === this.controlContainer.control.value.groupId);
          console.log(selectedGroup);
          this.onGroupSelect.emit(selectedGroup);
        }
      }
    });

    this.adManagerService.GetImage({
      accountId: account_id,
      clientId: client_id,
      adId
    })
      .subscribe(data => {
        if (data) {
          this.controlContainer.control.get('image').setValue([data.src]);
        }
        // (this.controlContainer.control.get('image') as FormArray).controls[0].setValue(data.src);
        // (this.controlContainer.control.get('image') as FormArray).controls[0].markAsDirty();
        // console.log(this.controlContainer.control.value);
      });

    this.form = new FormGroup({
      text: new FormControl(this.promopost.text, [Validators.required, Validators.maxLength(16734)]),
      image: new FormControl(this.promopost.image, [Validators.required]),
      groupId: new FormControl(this.promopost.groupId, [Validators.required]),
      albumId: new FormControl(this.promopost.albumId)
    });

    this.form
      .valueChanges
      .subscribe((value) => {
        this.promopostChange.emit(value);
      });

    this.controlContainer
      .control
      .get('groupId')
      .valueChanges
      .subscribe(data => {
        const group = this.userGroups.find(group1 => group1.id === parseInt(data, 10));
        this.onGroupSelect.emit(group);
      });

    this.controlContainer
      .control
      .valueChanges
      .subscribe(data => {
        this.errors = UtilsService.GetErrorsByForm('create-promopost-form', this.controlContainer.control);
        console.log(this.errors, data);
      });
  }

  public SelectPromostGroup($event): void {
    if (!$event.source.selected) {
      console.log($event.source.selected);
      return;
    }
    const groupId = $event.source.value;
    const group = this.userGroups.find(x => x.id === groupId);
    console.log(group, 'groups');
    if (group) {
      this.onGroupSelect.emit(group);
    }
  }

  public ImageSelected($event: any) {
    console.log(this.controlContainer.control.get('image'));
    this.controlContainer.control.get('image').enable();
    this.controlContainer.control.get('image').setValue({value: $event, disabled: true});
    console.log(this.controlContainer.control.get('image'), 'MUST BE ENABLED');
  }

  SaveText(text, i) {
    (this.controlContainer.control.get('text') as FormArray)
      .controls[i]
      .setValue(text.target.value.replace(/(?:\r\n|\r|\n)/g, '<br>').trim());
    (this.controlContainer.control.get('text') as FormArray)
      .controls[i].markAsDirty();
  }

  public addControl() {
    (this.controlContainer.control.get('text') as FormArray).push(new FormControl('', [Validators.required, Validators.maxLength(16687)]));
  }

  public removeControl(controlI) {
    (this.controlContainer.control.get('text') as FormArray).removeAt(controlI);
  }

  getEmojjiContent() {
    return new PostificatePipe().transform('Внимание! Рекламная запись должна содержать не более 6 эмодзи. Например: 🔥😏📅💃💪🏻🏻👁😱😈 или другие.');
  }
}
