import { OnInit } from '@angular/core';
import { StoreService } from "../../../../../services/store.service";
import { UsersIntegrationsService } from "../../../../../store/user-integrations/user-integration.service";
import { IntegrationSessionQuery } from "../../../../../store/integrations-session/integration-session.query";
import { map } from "rxjs/operators";
var styles = [
    {
        id: 0,
        name: 'Остановлено',
        style: { "color": "red" },
        icon: 'stop'
    },
    {
        id: 1,
        name: 'Запущено',
        style: { "color": "green" },
        icon: 'play_arrow'
    }
];
var UserIntegrationStatusComponent = /** @class */ (function () {
    function UserIntegrationStatusComponent(store, userIntegrationsService, integrationSessionQuery) {
        this.store = store;
        this.userIntegrationsService = userIntegrationsService;
        this.integrationSessionQuery = integrationSessionQuery;
        this.isSessionExhausted$ = this.integrationSessionQuery.currentSession$
            .pipe(map(function (x) { return x.isLimitExhausted; }));
    }
    Object.defineProperty(UserIntegrationStatusComponent.prototype, "statusData", {
        get: function () {
            return status;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserIntegrationStatusComponent.prototype, "StatusStyle", {
        get: function () {
            return JSON.parse(this.status.style);
        },
        enumerable: true,
        configurable: true
    });
    UserIntegrationStatusComponent.prototype.ngOnInit = function () {
    };
    UserIntegrationStatusComponent.prototype.ChangeStatus = function (status) {
        this.userIntegrationsService.ChangeUserIntegrationStatus(this.integrationId, status);
    };
    return UserIntegrationStatusComponent;
}());
export { UserIntegrationStatusComponent };
