<div class="pick-list">
  <div class="pick-list__start">

    <ng-container *ngIf="error">
      <div class="pick-list-notification">
        <div class="alert alert-danger">
          <div class="alert-items">
            {{error}}
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="FreeSlots === 0">
      <div class="pick-list-notification">
        <div class="alert alert-warning">
          <div class="alert-items">
            <div class="pick-list-notification-header">Если Вы хотите активировать ещё один кабинет</div>
            <div>
              <a routerLink="/payments-create">Смените тарифный план</a> или деактивируйте один из ранее активированных
              кабинетов.
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="fx-row">
      <h3>Ваши кабинеты</h3>
      <div class="fx-divider"></div>
      <div>Доступно кабинетов: {{FreeSlots}} / {{maxSelectedCount}}</div>
    </div>
    <div class="pick-list__item" *ngFor="let item of Items;">
      <div class="pick-item">
        <div class="pick-item__checkbox">
          <mat-checkbox [ngModel]="item.isSelected" (ngModelChange)="Change($event, item)"
                        [disabled]="CanBeSelected(item)">
            {{item.account_name}}
          </mat-checkbox>
        </div>
        <div class="pick-item__notification">
          <ng-container *ngIf="item.isSelected">
            <ng-container *ngIf="CanBeSelected(item); else ItemCannotBeSelected">
            <span class="pick-list-item-activation-notification pick-list-item-activation-notification_negative">
              Можно отключить {{GetDeactivationTimeString(item.account_id)}}
            </span>
            </ng-container>
            <ng-template #ItemCannotBeSelected>
            <span class="pick-list-item-activation-notification">
              Можно отключить
            </span>
            </ng-template>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <button class="btn btn-primary btn-block" (click)="Save()">Применить</button>
  <!--<ng-container *ngIf="FreeSlots === 0">-->
  <!--<div class="">-->
  <!--<div class="message__smile">-->
  <!--:(-->
  <!--</div>-->
  <!--<div class="message__text">-->
  <!--Лимит доступных для подключения кабинетов в вашем тарифе закончился!-->
  <!--</div>-->
  <!--</div>-->
  <!--</ng-container>-->
</div>
