
<ng-container *ngIf="loaded; else loadingScreen">
  <app-ad-form
    [isUpdating]="true"
    [ads]="ads"
    [targetingSettings]="targetingSettings"
    [accountId]="accountId"
    [clientId]="clientId"
    [strategy]="strategy"
    [image]="image"
    (adSaved)="OnSaveAd($event)"
    [loading]="loading"
    [accounts]="accounts"
    [slots]="slots"
  ></app-ad-form>

</ng-container>

<ng-template #loadingScreen>
  <div class="update-loading-screen">
    <mat-spinner></mat-spinner>
    Подождите идет загрузка данных
  </div>
</ng-template>

<!--&lt;!&ndash;<app-ad-adForm></app-ad-adForm>&ndash;&gt;-->

<!--<mat-vertical-stepper [linear]="false" #stepper>-->
  <!--<mat-step>-->
    <!--<ng-template matStepLabel><span class="stepper-title">Выберите формат рекламы</span></ng-template>-->

    <!--<ng-template matStepperIcon="edit">-->
      <!--<mat-icon>done_all</mat-icon>-->
    <!--</ng-template>-->

    <!--<app-selecting-format-->
      <!--[adFormatList]="adFormatsList"-->
      <!--[(format)]="format"-->
      <!--[disabled]="true"-->
      <!--(formatChange)="OnFormatChange($event)"-->
      <!--(onNext)="stepper.next()"-->
    <!--&gt;</app-selecting-format>-->
  <!--</mat-step>-->
  <!--<mat-step *ngIf="format === 'promopost'">-->
    <!--<ng-template matStepLabel><span class="stepper-title">Создайте промопост</span></ng-template>-->

    <!--<ng-template matStepperIcon="edit">-->
      <!--<mat-icon>done_all</mat-icon>-->
    <!--</ng-template>-->

    <!--<app-create-promopost [(promopost)]="promopost" (onNext)="stepper.next()" *ngIf="dataLoaded"></app-create-promopost>-->
  <!--</mat-step>-->
  <!--<mat-step *ngIf="format === 'teazer'">-->
    <!--<ng-template matStepLabel><span class="stepper-title">Создайте тизер</span></ng-template>-->
    <!--<app-create-teazer-->
      <!--*ngIf="dataLoaded"-->
      <!--[(teazer)]="teazer"-->
      <!--(onNext)="stepper.next()"-->
    <!--&gt;</app-create-teazer>-->
  <!--</mat-step>-->
  <!--<mat-step>-->
    <!--<ng-template matStepLabel><span class="stepper-title">Настройте таргетинг</span></ng-template>-->
    <!--<ng-template matStepperIcon="edit">-->
      <!--<mat-icon>done_all</mat-icon>-->
    <!--</ng-template>-->

    <!--<app-targeting-settings-->
      <!--[targetingValue]="targetingValues"-->
      <!--[targetingData]="targetingData"-->
      <!--(onNext)="SaveTargeting($event)"-->
      <!--*ngIf="targetingSettingsLoaded"-->
    <!--&gt;</app-targeting-settings>-->
  <!--</mat-step>-->
  <!--<mat-step>-->
    <!--<ng-template matStepLabel><span class="stepper-title">Выберите кампанию</span></ng-template>-->
    <!--<app-ad-account-manager-->
      <!--[selectedCampaignsType]="AdType"-->
      <!--[accountId]="accountId + ' ' + clientId"-->
      <!--[campaignId]="campaignId"-->
      <!--[disabled]="true"-->
      <!--(onSave)="OnSave($event)"-->
      <!--(onNext)="OnSaveCampaign($event)"-->
      <!--#accountManager-->
    <!--&gt;</app-ad-account-manager>-->
  <!--</mat-step>-->
  <!--<mat-step>-->
    <!--<ng-template matStepLabel><span class="stepper-title">Задайте ставку</span></ng-template>-->
    <!--<app-rate-manager-->
      <!--[adType]="AdFormat"-->
      <!--(onNext)="OnSaveRate($event)"-->
    <!--&gt;</app-rate-manager>-->
  <!--</mat-step>-->
  <!--<mat-step>-->
    <!--<ng-template matStepLabel><span class="stepper-title">Настройки модерации</span></ng-template>-->
    <!--<app-ad-start-settings (onNext)="OnSaveSettings($event)"></app-ad-start-settings>-->
  <!--</mat-step>-->
  <!--<mat-step>-->
    <!--<ng-template matStepLabel><span class="stepper-title">Готово!</span></ng-template>-->
    <!--<app-ready-->
      <!--[accountId]="campaignData.accountId"-->
      <!--[campaignId]="campaignData.campaignId"-->
      <!--[adId]="adId"-->
    <!--&gt;</app-ready>-->
  <!--</mat-step>-->
<!--</mat-vertical-stepper>-->

<!--<ng-template *matStepperIcon="'edit'">-->
  <!--<mat-icon>done_all</mat-icon>-->
<!--</ng-template>-->

<!--&lt;!&ndash;<app-create-promopost></app-create-promopost>&ndash;&gt;-->
