import { OnInit } from '@angular/core';
import { FormMessage } from "../../models/form-message";
import { FormMessageType } from "../../models/form-message-type";
var FormMessageComponent = /** @class */ (function () {
    function FormMessageComponent() {
    }
    Object.defineProperty(FormMessageComponent.prototype, "FormMessageClass", {
        get: function () {
            return "form-message_" + this.Message.Type.toLowerCase();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormMessageComponent.prototype, "FormMessageIconClass", {
        get: function () {
            return "form-message__icon_" + this.Message.Type.toLowerCase();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormMessageComponent.prototype, "FormMessageTextClass", {
        get: function () {
            return "form-message__text_" + this.Message.Type.toLowerCase();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormMessageComponent.prototype, "MessageIcon", {
        get: function () {
            var icon = '';
            switch (this.Message.Type) {
                case FormMessageType.SUCCESS:
                    icon = 'done';
                    break;
                case FormMessageType.WARNING:
                    icon = 'warning';
                    break;
                case FormMessageType.ERROR:
                    icon = 'error';
                    break;
                case FormMessageType.INFO:
                    icon = 'info';
                    break;
            }
            return icon;
        },
        enumerable: true,
        configurable: true
    });
    FormMessageComponent.prototype.ngOnInit = function () {
    };
    return FormMessageComponent;
}());
export { FormMessageComponent };
