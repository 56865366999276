import {Component, OnInit} from '@angular/core';
import {EasyAdsDataService} from '../../services/easy-ads-data.service';
import {ActivatedRoute, Router} from '@angular/router';
import {EasyAdContainerModel} from '../../models/easy-ad-container-model';
import {MatDialog} from '@angular/material';
import {ModerationDetailsComponent} from '../../ad-manager/components/moderation-details/moderation-details.component';
import {AdManagerService} from '../../../api/services/ad-manager.service';
import {UserStrategiesService} from "../../../api/services/user-strategies.service";
import {AccountsService} from "../../../api/services/accounts.service";
import {TariffPlanExpirationMeta} from "../../../api/models/tariff-plan-expiration-meta";
import * as moment from 'moment';
import {AutomationVkService} from '../../../api/services/automation-vk.service';
import {AdIndexModel} from '../../models/ad-index-model';
import {Subject} from "rxjs/Rx";
import {StrategiesService} from "../../../api/services/strategies.service";
import {BidRange} from "../../../api/models/bid-range";
import {AutomationVkSlotService} from "../../../api/services/automation-vk-slot.service";

@Component({
  selector: 'app-easy-ads-dashboard',
  templateUrl: './easy-ads-dashboard.component.html',
  styleUrls: ['./easy-ads-dashboard.component.scss']
})
export class EasyAdsDashboardComponent implements OnInit {
  public functionalyAccessDataIsLoading: boolean = false;
  private functionalyAccessData: TariffPlanExpirationMeta = null;

  private rescanTrigger: Subject<any> = new Subject();

  private adIndexesToRescan: Array<AdIndexModel> = [];

  public bidRanges: Array<BidRange> = [];

  public showBudgetLoading: boolean = true;

  public confrimedAccountId: number = null;
  public confirmedClientId: number = null;

  public temploraryBLockApiInteraction: boolean = false;

  public nonBlockingAdId: number = null;
  public showModal: boolean = false;
  public modalHeader: string = 'Default header';
  public modalText: string = 'Default text';
  public modalShowPaymentButton: boolean = false;

  constructor(private dialog: MatDialog, private adManagerService: AdManagerService,
              private automationService: AutomationVkService,
              public easyAdsDataService: EasyAdsDataService, private userStrategyService: UserStrategiesService,
              private router: Router, private activatedRoute: ActivatedRoute, private accountsService: AccountsService,
              private strategiesApi: StrategiesService, private automationSlots: AutomationVkSlotService) {
  }

  async ngOnInit() {
    const accountIdQuery = this.activatedRoute.snapshot.queryParams.accountId;
    const clientIdQuery = this.activatedRoute.snapshot.queryParams.clientId;

    // Поулчить слоты автоматизации
    this.automationSlots.GetSlots().subscribe(response => {

      // Убедиться, что слоты есть
      if (response && response.data && response.data.length > 0) {
        const slots = response.data;

        // Получить все не пустые слоты
        const occupiedSlots = slots.filter(x => x.bindedCabinetId !== null);

        // Проверить, что есть хотя бы один не пустой слот
        if (occupiedSlots && occupiedSlots.length > 0) {
          const accountId = (accountIdQuery) ? parseInt(accountIdQuery, 10) : null;
          const clientId = (clientIdQuery) ? parseInt(clientIdQuery, 10) : null;

          // Получить абинеты пользователя от ВК
          this.automationService.GetAccounts().subscribe(async accountsResponse => {

            // Удостовериться, что ответ сервера корректный
            if (accountsResponse && accountsResponse.data) {
              const accounts = accountsResponse.data;

              // Удостовериться, что у пользователя есть хотя бы один кабинет
              if (accounts.length > 0) {
                // Выбрать личный кабинет, как кабиент по-умолчанию
                let accountToProcess = accounts.find(x => x.access_role === 'admin');

                // Если в query указан id аккаунта для которого необходимо произвести загрузку
                if (accountId !== null) {
                  // занулить кабинет для обработки, так как мы хотим работать не с кабинетом по-умолчанию
                  accountToProcess = null;

                  // Проверить, что такой аккаунт есть у пользователя в списке доступных
                  const customAccountToProcess = accounts.find(x => x.account_id === accountId && x.client_id === clientId);

                  if (customAccountToProcess) {
                    // Проверить, что такой кабинет подключен к слоту
                    const accountInSlot = occupiedSlots.find(x => x.bindedCabinetId === accountId && x.bindedClientId === clientId);

                    if (accountInSlot) {
                      // Установить указанный кабнет ВКонтакте как кабинет, с которого будем грузитьб информацию
                      accountToProcess = customAccountToProcess;
                    } else {
                      console.warn('Указанный в query кабинет не привязан к слоту в PsotMonitor');
                      this.showBudgetLoading = false;
                      // TODO добавить вывод текста ошибки для ситуации, когда кабинет доступен в ВКонтакте, но не подключен в слот у нас
                    }
                  } else {
                    console.warn('Указанный в query кабинет не был представлен в списке доступных пользователю кабинетов ВКонтакте');
                    this.showBudgetLoading = false;
                    // TODO добавить вывод текста ошибки для ситуации, когда у пользователя нет указанного кабинета в списке доступных ему (на стороне ВКонтакте)
                  }
                }

                // Если указан кабинет, с которго нужно загружать информацию
                if (accountToProcess) {
                  this.confrimedAccountId = accountToProcess.account_id;
                  this.confirmedClientId = accountToProcess.client_id;

                  await this.easyAdsDataService.LoadData(accountToProcess.account_id, accountToProcess.client_id);
                  let adIds = [];
                  if (this.easyAdsDataService.adsList) {
                    adIds = this.easyAdsDataService.adsList.map(x => x.adId);
                    this.adManagerService.CabBeEditable({
                      accountId: accountToProcess.account_id,
                      clientId: accountToProcess.client_id,
                      adIds
                    })
                      .subscribe(data => {
                        if (data && this.easyAdsDataService.adsList) {
                          this.easyAdsDataService.adsList.forEach(ad => {
                            ad.canBeEditable = this.CanAdBeEditable(data, ad)
                            ;
                          });
                        }
                      });
                  }

                } else {
                  console.warn('Не найден кабинет, для которого необходимо получить объявления');
                  this.showBudgetLoading = false;
                  // TODO Добавить обработку в том случае, если нет кабинета для которого нужно загрузить данные
                }
              } else {
                console.warn('У пользователя нет ни одного кабинета ВКонтакте');
                this.showBudgetLoading = false;
                // TODO Доабвить обработку, когда от ВК не пришло ни одного аккаунта
              }
            } else {
              console.warn('Ответ на запрос кабинетов ВКонтакте вернул пустой ответ');
              // TODO Добавить обработку если нет accountsResponse или accountsResponse.data
            }
          });

          // // Проверить содержится ли accountId из queryParams в слотах
          // const slotsContainsStatedCabinet = (accountId !== null && occupiedSlots.includes(x =>
          //   x.bindedCabinetId === accountId &&
          //   x.bindedClientId === clientId)
          // );
          //
          // if (accountId === null || slotsContainsStatedCabinet) {
          //   // Запросить данные
          //   this.easyAdsDataService.LoadData(accountId, clientId);
          // } else if (!slotsContainsStatedCabinet) {
          //   // TODO Здесь можно произвести обработку того момента, когда указан acconutId черезquery params, н его не в слотах
          //   console.warn(`Указанный вами accountId=${accountId} не подключен к системе!`);
          // }
        } else {
          console.warn('У пользователя не занят ни один слот в PostMonitor');
          this.showBudgetLoading = false;
          // TODO Доабвить обработку ошибки, когда у ползьователя ни один кабинет не назначен в слот
        }
      } else {
        // TODO Доабвить обработку ошибки, когда массив слотов пришёл пустым
        console.warn('Ответ на запрос слотов оказался пустым');
        this.showBudgetLoading = false;
      }
    }, error => {
      // TODO Доабвить обработку ошибки, когда не удалось поулчить слоты
      console.warn('Не удалось получить слоты');
      this.showBudgetLoading = false;
    });

    this.functionalyAccessDataIsLoading = true;
    this.accountsService.GetUserTariffAccess(['VK_AUTOMATION']).subscribe(response => {
      this.functionalyAccessDataIsLoading = false;

      if (response && response.data && response.data.length > 0) {
        this.functionalyAccessData = response.data[0];
      }
    });

    this.strategiesApi.GetBidRanges().subscribe(response => {
      this.bidRanges = response;
    });

    this.rescanTrigger
      .debounceTime(10000)
      .subscribe(x => {
        this.RescanAdIndexes();
      });
  }

  private CanAdBeEditable(data, ad) {
    const adCanEditable = (ad.ad.ad_format === 2) ? !ad.adLayout.link_url.includes('vk.com/club') : true;
    return adCanEditable && (data[ad.adId] ? data[ad.adId] : false);
  }

  public AddIndexToRescan(ad: EasyAdContainerModel) {
    const index = new AdIndexModel();
    index.accountId = ad.accountId;
    index.clientId = ad.clientId;
    index.adId = ad.adId;

    console.log('Adding inex to rescan', index);

    this.adIndexesToRescan.push(index);

    console.log('index added, triggering delayed rescan', this.adIndexesToRescan);
    this.rescanTrigger.next();
  }

  public UpdateCpm(accountId: number, clientId: number, adId: number, value: number): void {
    const cpm = Number((value / 100).toFixed(2));

    this.automationService.UpdateAds({
      accountId: accountId,
      clientId: clientId,
      specifications: [{
        ad_id: adId,
        cpm: cpm
      }]
    }).subscribe(response => {
      const relatedAd = this.easyAdsDataService.adsList.find(x => x.accountId == accountId && x.clientId == clientId && x.adId == adId);

      if (relatedAd && relatedAd.ad) {
        relatedAd.ad.cpm = value;
      }
    });
  }

  public UpdateEcpcLimit(accountId: number, clientId: number, adId: number, value: number): void {
    const relatedAd = this.easyAdsDataService.adsList
      .find(x => x.accountId === accountId && x.clientId === clientId && x.adId === adId);

    if (relatedAd) {
      const relatedStrategy = relatedAd.userStrategy;

      if (relatedStrategy && relatedStrategy.inputs) {
        const requiredInput = relatedStrategy.inputs.find(x => x.type && x.type.code === 'ECPC_LIMIT');

        if (requiredInput) {
          this.userStrategyService.UpdateUserStrategyInput({
            accountId: accountId,
            clientId: clientId,
            adId: adId,
            inputId: requiredInput.id,
            value: value
          }).subscribe(x => {
            requiredInput.value = value;
          }, error => {
            // TODO Add error handling
          });
        } else {
          console.warn('Ad strategy\s input not found');
        }
      } else {
        console.warn('Ad\'s strategy not found');
      }
    } else {
      console.warn('Ad not found');
    }
  }

  public GetAdBidLimit(adFormat: number): BidRange {
    if (adFormat === null || adFormat === undefined)
      return null;

    return this.bidRanges.find(x => x.adFormat === adFormat);
  }

  private async RescanAdIndexes() {
    console.log('Rescan inited');
    if (this.adIndexesToRescan && this.adIndexesToRescan.length > 0) {
      console.log('Indexes to rescan', this.adIndexesToRescan);
      const accountId = this.adIndexesToRescan[0].accountId;
      const clientId = this.adIndexesToRescan[0].clientId;

      const adIds = this.adIndexesToRescan.map(x => x.adId);

      console.log('Calling API');
      const response = await this.automationService.GetAds({
        accountId: accountId,
        clientId: clientId,
        campaignIds: null,
        adIds: adIds,
        limit: 1000,
        offset: 0,
        includeDeleted: false
      }).toPromise();

      console.log('Response recieved', response);
      if (response && response.data && response.data.length > 0) {
        response.data.forEach(ad => {
          const relatedEasyAd = this.easyAdsDataService.adsList.find(x => x.adId === ad.id);

          if (relatedEasyAd && relatedEasyAd.ad) {
            console.log('Easy ad status changed', relatedEasyAd);
            console.log(`EasyAd status=${relatedEasyAd.ad.status}; ad status = ${ad.status}`);
            relatedEasyAd.ad.status = ad.status;
            relatedEasyAd.forceLoadingAbort = true;
          }
        });
      }

      // Clear indexes
      this.adIndexesToRescan = [];
    }
  }

  public ExpirationAt(): string {
    return moment(new Date(this.functionalyAccessData.expiration)).fromNow();
  }

  public IsDateExpired(date: Date): boolean {
    return moment(new Date(this.functionalyAccessData.expiration)).isBefore(moment());
  }

  public UpdateAdName(ad: EasyAdContainerModel): void {
    if (ad && ad.ad && ad.ad.name && ad.ad.name.length > 0) {
      // TODO Implement VKAPI Usage
      const specification: any = {
        ad_id: ad.adId,
        name: ad.ad.name
      };

      this.automationService.UpdateAds({
        accountId: ad.accountId,
        clientId: ad.clientId,
        specifications: [specification]
      }).subscribe(response => {

      }, error => {
        console.error('error', error);
      });
    }
  }

  public GetShownCategorySelectorClass(mode: string): string {
    return `easy-ads-shown-category-selector-item ${(this.easyAdsDataService.filerMode === mode) ? 'easy-ads-shown-category-selector-item_active' : ''}`;
  }

  public SetUpFilter(mode: string): void {
    this.easyAdsDataService.AssignNewFilter(mode);
  }

  public CreateAdClick(): void {
    this.router.navigate(['/automation/new-ad-manager/lite/create']);
  }

  public GetRejectionReason(ad: EasyAdContainerModel): void {
    if (ad) {
      this.adManagerService
        .GetAdRejection({
          accountId: ad.accountId,
          adId: ad.adId
        }).subscribe((rejectionRules) => {
        if (rejectionRules) {
          this.dialog.open(ModerationDetailsComponent, {
            data: rejectionRules,
            width: '640px'
          });
        } else {
          console.warn(`No rejection reason was recieved for ad ${ad.accountId}_${ad.adId}`);
        }
      });
    }
  }

  public async AdStatusChange(ad: EasyAdContainerModel) {
    this.modalShowPaymentButton = true;
    this.nonBlockingAdId = null;

    if (ad) {
      this.nonBlockingAdId = ad.adId;
      this.SetAdsBlockingStatus(true);

      const currentStatus = (ad.ad.status === 1);

      // If currenlty ad is stopped & user wants to start it
      if (!currentStatus) {
        // Check if user can start ad
        if (this.easyAdsDataService.budget === 0) {
          this.showModal = true;
          ad.forceLoadingAbort = true;

          this.modalHeader = `Невозможно запустить объявление`;
          this.modalText = `У вас не достаточно средств для запуска объявления. По условиям ВКонтакте, на каждое запущенное объявление должно быть не менее 100 руб. на балансе.<br>
<br>
Вам необходимо либо пополнить баланс, либо выключить любое из запущенных объявлений.<br>
<br>
Пример: Если баланс 350 руб. и уже запущено 3 объявления, то четвертое объявление невозможно будет запустить. Для запуска четвертого объявления необходимо, чтобы баланс был больше 400 руб.`;
          this.SetAdsBlockingStatus(false);
          return;
        } else {
          let result = await this.CheckIfAdCanBeStarted();

          if (result === false) {
            this.showModal = true;
            this.modalHeader = `Невозможно запустить объявление`;
            this.modalText = `У вас не достаточно средств для запуска объявления. По условиям ВКонтакте, на каждое запущенное объявление должно быть не менее 100 руб. на балансе.<br>
<br>
Вам необходимо либо пополнить баланс, либо выключить любое из запущенных объявлений.<br>
<br>
Пример: Если баланс 350 руб. и уже запущено 3 объявления, то четвертое объявление невозможно будет запустить. Для запуска четвертого объявления необходимо, чтобы баланс был больше 400 руб.`;
            ad.forceLoadingAbort = true;

            this.SetAdsBlockingStatus(false);
            return;
          }
        }

        // Check if this is first ad launching (any ad)
        try {
          const key = 'easy-ads-first-ad-launching';

          const firstAdLaunching = localStorage.getItem(key);

          if (!firstAdLaunching) {
            this.showModal = true;
            this.modalHeader = 'Внимание';
            this.modalText = `Вы запускаете объявление ВКонтакте. При этом с вашего баланса ВКонтакте будут списываться средства в зависимости от количества показов объявлений и заданных настроек при создании объявления.<br>
<br>
Рекомендуем следить за балансом ВКонтакте и при необходимости пополнять бюджет.`;
            this.modalShowPaymentButton = false;
            localStorage.setItem(key, new Date().toUTCString());
          }
        } catch (ex) {

        }
      }

      if (ad.userStrategy) {
        // Call strategy start ToggleStrategies
        this.userStrategyService.ToggleStrategies({
          accountId: ad.accountId,
          clientId: (ad.clientId === null) ? 0 : ad.clientId,
          adIds: [ad.adId],
          status: !currentStatus
        }).subscribe(response => {
          this.SetAdsBlockingStatus(false);

        }, error => {
          this.SetAdsBlockingStatus(false);
          ad.errors = ['Не удалось запустить/остановить объявление и управление ставкой'];
        });
      } else {
        // Call ad start ToggleAdStatus
        this.userStrategyService.ToggleAdStatus({
          accountId: ad.accountId,
          adIds: [ad.adId],
          status: !currentStatus
        }).subscribe(response => {
          this.SetAdsBlockingStatus(false);
        }, error => {
          this.SetAdsBlockingStatus(false);
          ad.errors = ['Не удалось запустить/остановить объявление'];
        });
      }

      ad.forceLoadingAbort = false;
      this.AddIndexToRescan(ad);
    }
  }

  public async AdSendToModeration(ad: EasyAdContainerModel) {
    this.nonBlockingAdId = null;
    this.modalShowPaymentButton = true;

    if (ad) {
      ad.forceLoadingAbort = false;

      this.nonBlockingAdId = ad.adId;
      this.SetAdsBlockingStatus(true);

      if (this.easyAdsDataService.budget <= 0) {
        console.warn(`Send to moderation failed, reason: budget = ${this.easyAdsDataService.budget}; budget === null = ${this.easyAdsDataService.budget === null}; budget <= 0 = ${this.easyAdsDataService.budget <= 0}`);
        // ad.errors = [`Для отправки объявления на модерацию, необходимо пополнить рекламный бюджет в ВКонтакте. <a href="https://vk.com/ads?act=payments" target="_blank" rel="noopener nofollow">пополнить</a>`,
        //   '<i>Данная оплата производится не нашему сервису, а в ВКонтакте. Без пополнения бюджета не возможно запустить объявление.</i>'];

        this.showModal = true;
        this.modalHeader = `Невозможно отправить объявление на модерацию`;
        this.modalText = `Чтобы запустить рекламу в ВКонтакте, необходимо пополнить бюджет рекламного кабинета ВКонтакте.<br>
Без пополнения бюджета, соцсеть ВКонтакте объявления не показывает.<br>
<br>
При этом весь функционал нашего сервиса предоставляется <strong>бесплатно</strong> на 3 дня.`;
        ad.forceLoadingAbort = true;
        this.SetAdsBlockingStatus(false);
        return;
      }

      const budgetCheck = await this.CheckIfAdCanBeStarted();

      if (!budgetCheck) {
        this.SetAdsBlockingStatus(false);

        this.showModal = true;
        this.modalHeader = `Невозможно отправить объявление на модерацию`
        this.modalText = `У вас не достаточно средств для запуска объявления. По условиям ВКонтакте, на каждое запущенное объявление должно быть не менее 100 руб. на балансе.<br>
<br>
Вам необходимо либо пополнить баланс, либо выключить любое из запущенных объявлений.<br>
<br>
Пример: Если баланс 350 руб. и уже запущено 3 объявления, то четвертое объявление невозможно будет запустить. Для запуска четвертого объявления необходимо, чтобы баланс был больше 400 руб.`;
        ad.forceLoadingAbort = true;
        return;
      }

      const params = {
        accountId: ad.accountId,
        adIds: [ad.adId],
        status: true
      };

      // Set ad's st
      const adStartRequest = this.userStrategyService.ToggleAdStatus(params).toPromise();

      adStartRequest.catch(error => {
        this.SetAdsBlockingStatus(false);
        ad.errors = ['Возникла ошибка при отправке объявления на модерацию.', 'Попробуйте обновить страницу и повторить ещё раз.'];
      });

      const adStartResponse = await adStartRequest;

      // Check if response contains errors - show them
      if (adStartResponse && adStartResponse.length > 0 && adStartResponse[0].error_code) {
        // TODO If no money
        if (this.easyAdsDataService.budget !== null && this.easyAdsDataService.budget > 0) {
          ad.errors = ['Возникла ошибка при попытке отправки объявления на модерацию.', 'Попробуйте обновить страницу и повторить ещё раз.'];
        } else {
          ad.errors = [`Для отправки объявления на модерацию, необходимо пополнить рекламный бюджет в ВКонтакте. <a href="https://vk.com/ads?act=payments" target="_blank" rel="noopener nofollow">пополнить</a>`,
            '<i>Данная оплата производится не нашему сервису, а в ВКонтакте. Без пополнения бюджета не возможно запустить объявление.</i>'];
        }

        this.SetAdsBlockingStatus(false);
        return;
      }

      // TODO Add timeout
      await this.easyAdsDataService.Timeout(2000);

      // Await some time & stop ad
      params.status = false;

      const adStopRequest = this.userStrategyService.ToggleAdStatus(params).toPromise();

      adStopRequest.catch(error => {
        this.SetAdsBlockingStatus(false);
        ad.errors = ['Возникла ошибка во время отправки объявления на модерацию.', 'Попробуйте обновить страницу и повторить ещё раз.'];
      });

      const adStopReponse = await adStopRequest;

      // Set status as sent to moderation
      ad.ad.approved = 1;
      this.SetAdsBlockingStatus(false);
    }
  }

  public async CheckIfAdCanBeStarted(): Promise<boolean> {
    this.temploraryBLockApiInteraction = true;

    const result = await this.CheckRunningAdsBudget(false);

    this.temploraryBLockApiInteraction = false;

    return result;
  }

  public async CheckRunningAdsBudget(takeModeratingAdsIntoAccount: boolean = false): Promise<boolean> {
    await this.easyAdsDataService.Timeout();

    const adsRespnose = await this.automationService.GetAds({
      accountId: this.confrimedAccountId,
      clientId: this.confirmedClientId,
      campaignIds: null,
      adIds: null,
      limit: 1000,
      offset: 0,
      includeDeleted: false
    }).toPromise();

    if (adsRespnose && adsRespnose.data) {
      const ads = adsRespnose.data;

      const runningAds = ads.filter(x => x.status === 1);

      const adsCountThatRequiresMoney = runningAds.length;

      let leastRequiredBudget = 100 + adsCountThatRequiresMoney * 100;

      // Если нет запущенных, то нужно иметь не нулевой баланс
      if (adsCountThatRequiresMoney === 0) {
        leastRequiredBudget = 1;
      }

      await this.easyAdsDataService.Timeout();

      await this.easyAdsDataService.LoadBudgetViaApi(this.confrimedAccountId, this.confirmedClientId);

      const realBudget = this.easyAdsDataService.budget;

      // Compare real budget against leastRequiredBudget
      return realBudget >= leastRequiredBudget;
    }

    return false;
  }

  private SetAdsBlockingStatus(state: boolean = false): void {
    this.easyAdsDataService.adsList.forEach(ad => {
      if (state === true) {
        if (ad.adId !== this.nonBlockingAdId) {
          ad.blockAd = true;
        }
      } else {
        ad.blockAd = false;
      }
    });
  }
}
