/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { APIReponseWrapperListIntegrationPlatform } from '../models/apireponse-wrapper-list-integration-platform';
import { APIReponseWrapperListIntegrationPlatformCompability } from '../models/apireponse-wrapper-list-integration-platform-compability';
import { APIReponseWrapperSystemBoolean } from '../models/apireponse-wrapper-system-boolean';
@Injectable({
  providedIn: 'root',
})
class IntegrationService extends __BaseService {
  static readonly GetIntegrationServicesPath = '/apiv2/integration/service';
  static readonly GetCompableIntegrationsPath = '/apiv2/integration/compability';
  static readonly HasUserAccessPath = '/apiv2/integration/has-access';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `IntegrationService.GetIntegrationServicesParams` containing the following parameters:
   *
   * - `sortFieldsAsc`:
   *
   * - `filterOnlyActive`:
   *
   * - `allFields`:
   *
   * @return Success
   */
  GetIntegrationServicesResponse(params: IntegrationService.GetIntegrationServicesParams): __Observable<__StrictHttpResponse<APIReponseWrapperListIntegrationPlatform>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortFieldsAsc != null) __params = __params.set('sortFieldsAsc', params.sortFieldsAsc.toString());
    if (params.filterOnlyActive != null) __params = __params.set('filterOnlyActive', params.filterOnlyActive.toString());
    if (params.allFields != null) __params = __params.set('allFields', params.allFields.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/integration/service`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<APIReponseWrapperListIntegrationPlatform>;
      })
    );
  }
  /**
   * @param params The `IntegrationService.GetIntegrationServicesParams` containing the following parameters:
   *
   * - `sortFieldsAsc`:
   *
   * - `filterOnlyActive`:
   *
   * - `allFields`:
   *
   * @return Success
   */
  GetIntegrationServices(params: IntegrationService.GetIntegrationServicesParams): __Observable<APIReponseWrapperListIntegrationPlatform> {
    return this.GetIntegrationServicesResponse(params).pipe(
      __map(_r => _r.body as APIReponseWrapperListIntegrationPlatform)
    );
  }

  /**
   * @return Success
   */
  GetCompableIntegrationsResponse(): __Observable<__StrictHttpResponse<APIReponseWrapperListIntegrationPlatformCompability>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/integration/compability`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<APIReponseWrapperListIntegrationPlatformCompability>;
      })
    );
  }
  /**
   * @return Success
   */
  GetCompableIntegrations(): __Observable<APIReponseWrapperListIntegrationPlatformCompability> {
    return this.GetCompableIntegrationsResponse().pipe(
      __map(_r => _r.body as APIReponseWrapperListIntegrationPlatformCompability)
    );
  }

  /**
   * @param accessDate undefined
   * @return Success
   */
  HasUserAccessResponse(accessDate: string): __Observable<__StrictHttpResponse<APIReponseWrapperSystemBoolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (accessDate != null) __params = __params.set('accessDate', accessDate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/integration/has-access`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<APIReponseWrapperSystemBoolean>;
      })
    );
  }
  /**
   * @param accessDate undefined
   * @return Success
   */
  HasUserAccess(accessDate: string): __Observable<APIReponseWrapperSystemBoolean> {
    return this.HasUserAccessResponse(accessDate).pipe(
      __map(_r => _r.body as APIReponseWrapperSystemBoolean)
    );
  }
}

module IntegrationService {

  /**
   * Parameters for GetIntegrationServices
   */
  export interface GetIntegrationServicesParams {
    sortFieldsAsc: boolean;
    filterOnlyActive: boolean;
    allFields: boolean;
  }
}

export { IntegrationService }
