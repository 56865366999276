var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { QueryEntity } from '@datorama/akita';
import { PlatformAssignationsStore } from './platform-assignations.store';
import { distinctUntilChanged, filter, flatMap, map } from 'rxjs/operators';
import { PlatformsTypes } from '../../platforms-types';
import { AssignationPlatforms } from './platform-assignation.model';
import { of } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "./platform-assignations.store";
var PlatformAssignationsQuery = /** @class */ (function (_super) {
    __extends(PlatformAssignationsQuery, _super);
    function PlatformAssignationsQuery(store) {
        var _this = _super.call(this, store) || this;
        _this.store = store;
        /**
         * Проверка подключена ли данная платформа
         * @param platformId
         */
        _this.isAssigned$ = function (platformId) { return _this
            .selectAll({ filterBy: function (entity) { return entity.id === platformId; } })
            .pipe(distinctUntilChanged(), 
        // debounceTime(3000),
        map(function (x) { return x[0]; }), map(function (x) { return x ? x.assignationStatus : false; }), filter(function (x) { return x === true; })); };
        _this.assingnationStatus$ = function (platformId) { return _this
            .selectAll({ filterBy: function (entity) { return entity.id === platformId; } })
            .pipe(distinctUntilChanged(), map(function (x) { return x[0]; }), map(function (x) { return x ? x.assignationStatus : false; })); };
        /**
         * Аодключена ли платформа с кодом
         * @param platformCode
         */
        _this.isPlatformAssigned$ = function (platformCode) {
            return of(_this.platform(platformCode))
                .pipe(flatMap(function (platform) { return _this.isAssigned$(platform); }));
        };
        return _this;
    }
    PlatformAssignationsQuery.prototype.platform = function (code) {
        switch (code) {
            case PlatformsTypes.AMO_CRM_IMPORT:
            case PlatformsTypes.AMO_CRM_EXPORT:
                return AssignationPlatforms.AMOCRM;
            case PlatformsTypes.BITRIX_EXPORT:
            case PlatformsTypes.BITRIX_IMPORT:
                return AssignationPlatforms.BITRIX24;
            case PlatformsTypes.MEGAPLAN_EXPORT:
            case PlatformsTypes.MEGAPLAN_IMPORT:
                return AssignationPlatforms.MEGAPLAN;
            case PlatformsTypes.MT_LEAD_FORMS:
            case PlatformsTypes.MT_REMARKETING_GROUPS:
                return AssignationPlatforms.MY_TAREGT;
            case PlatformsTypes.VK_LEAD_FORMS:
            case PlatformsTypes.VK_RETARGETING_GROUPS:
                return AssignationPlatforms.VK;
            case PlatformsTypes.UON_IMPORT:
            case PlatformsTypes.UON_EXPORT:
                return AssignationPlatforms.UON;
            case PlatformsTypes.FB_CUSTOM_AUDIENCES:
            case PlatformsTypes.FB_LEAD_FORMS:
                return AssignationPlatforms.FACEBOOK;
            // case PlatformsTypes.MOY_SKLAD_EXPORT:
            // case PlatformsTypes.MOY_SKLAD_IMPORT:
            //   return AssignationPlatforms.MOI_SKLAD;
        }
    };
    PlatformAssignationsQuery.ngInjectableDef = i0.defineInjectable({ factory: function PlatformAssignationsQuery_Factory() { return new PlatformAssignationsQuery(i0.inject(i1.PlatformAssignationsStore)); }, token: PlatformAssignationsQuery, providedIn: "root" });
    return PlatformAssignationsQuery;
}(QueryEntity));
export { PlatformAssignationsQuery };
