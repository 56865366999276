/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { APIReponseWrapperListUserAutomationCabinetSlot } from '../models/apireponse-wrapper-list-user-automation-cabinet-slot';
import { ErrorDescription } from '../models/error-description';
@Injectable({
  providedIn: 'root',
})
class AutomationVkSlotService extends __BaseService {
  static readonly GetSlotsPath = '/apiv2/automation-vk-slot';
  static readonly AssignCabinetToSlotPath = '/apiv2/automation-vk-slot/cabinet';
  static readonly UnBindCabinetFromSlotPath = '/apiv2/automation-vk-slot/cabinet';
  static readonly GetAssignedAccountsPath = '/apiv2/automation-vk-slot/cabinets/bined';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return Success
   */
  GetSlotsResponse(): __Observable<__StrictHttpResponse<APIReponseWrapperListUserAutomationCabinetSlot>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/automation-vk-slot`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<APIReponseWrapperListUserAutomationCabinetSlot>;
      })
    );
  }
  /**
   * @return Success
   */
  GetSlots(): __Observable<APIReponseWrapperListUserAutomationCabinetSlot> {
    return this.GetSlotsResponse().pipe(
      __map(_r => _r.body as APIReponseWrapperListUserAutomationCabinetSlot)
    );
  }

  /**
   * @param params The `AutomationVkSlotService.AssignCabinetToSlotParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `clientId`:
   *
   * @return Success
   */
  AssignCabinetToSlotResponse(params: AutomationVkSlotService.AssignCabinetToSlotParams): __Observable<__StrictHttpResponse<APIReponseWrapperListUserAutomationCabinetSlot>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.id != null) __params = __params.set('id', params.id.toString());
    if (params.clientId != null) __params = __params.set('clientId', params.clientId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/apiv2/automation-vk-slot/cabinet`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<APIReponseWrapperListUserAutomationCabinetSlot>;
      })
    );
  }
  /**
   * @param params The `AutomationVkSlotService.AssignCabinetToSlotParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `clientId`:
   *
   * @return Success
   */
  AssignCabinetToSlot(params: AutomationVkSlotService.AssignCabinetToSlotParams): __Observable<APIReponseWrapperListUserAutomationCabinetSlot> {
    return this.AssignCabinetToSlotResponse(params).pipe(
      __map(_r => _r.body as APIReponseWrapperListUserAutomationCabinetSlot)
    );
  }

  /**
   * @param params The `AutomationVkSlotService.UnBindCabinetFromSlotParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `clientId`:
   *
   * @return Success
   */
  UnBindCabinetFromSlotResponse(params: AutomationVkSlotService.UnBindCabinetFromSlotParams): __Observable<__StrictHttpResponse<ErrorDescription>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.id != null) __params = __params.set('id', params.id.toString());
    if (params.clientId != null) __params = __params.set('clientId', params.clientId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/apiv2/automation-vk-slot/cabinet`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ErrorDescription>;
      })
    );
  }
  /**
   * @param params The `AutomationVkSlotService.UnBindCabinetFromSlotParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `clientId`:
   *
   * @return Success
   */
  UnBindCabinetFromSlot(params: AutomationVkSlotService.UnBindCabinetFromSlotParams): __Observable<ErrorDescription> {
    return this.UnBindCabinetFromSlotResponse(params).pipe(
      __map(_r => _r.body as ErrorDescription)
    );
  }

  /**
   * @return Success
   */
  GetAssignedAccountsResponse(): __Observable<__StrictHttpResponse<APIReponseWrapperListUserAutomationCabinetSlot>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/automation-vk-slot/cabinets/bined`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<APIReponseWrapperListUserAutomationCabinetSlot>;
      })
    );
  }
  /**
   * @return Success
   */
  GetAssignedAccounts(): __Observable<APIReponseWrapperListUserAutomationCabinetSlot> {
    return this.GetAssignedAccountsResponse().pipe(
      __map(_r => _r.body as APIReponseWrapperListUserAutomationCabinetSlot)
    );
  }
}

module AutomationVkSlotService {

  /**
   * Parameters for AssignCabinetToSlot
   */
  export interface AssignCabinetToSlotParams {
    id: number;
    clientId?: number;
  }

  /**
   * Parameters for UnBindCabinetFromSlot
   */
  export interface UnBindCabinetFromSlotParams {
    id: number;
    clientId?: number;
  }
}

export { AutomationVkSlotService }
