/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PostMonitorBackend_V2ModelsCrmEntitesLeadStatus } from '../models/post-monitor-backend-_v2models-crm-entites-lead-status';
import { PostMonitorBackend_V2ModelsCrmEntitesLead } from '../models/post-monitor-backend-_v2models-crm-entites-lead';
import { PostMonitorBackend_V2ModelsCrmEntitesLeadCall } from '../models/post-monitor-backend-_v2models-crm-entites-lead-call';
import { PostMonitorBackend_V2ModelsCrmEntitesLeadDetail } from '../models/post-monitor-backend-_v2models-crm-entites-lead-detail';
@Injectable({
  providedIn: 'root',
})
class LeadsService extends __BaseService {
  static readonly GetAllLeadStatusesPath = '/statuses';
  static readonly UpdateLeadPath = '/{id}';
  static readonly CreateCallPath = '/create-call';
  static readonly GetUserCallsPath = '/{id}/calls';
  static readonly GetLeadDetailsDataPath = '/get-lead-details-data';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return Success
   */
  GetAllLeadStatusesResponse(): __Observable<__StrictHttpResponse<Array<PostMonitorBackend_V2ModelsCrmEntitesLeadStatus>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/statuses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PostMonitorBackend_V2ModelsCrmEntitesLeadStatus>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetAllLeadStatuses(): __Observable<Array<PostMonitorBackend_V2ModelsCrmEntitesLeadStatus>> {
    return this.GetAllLeadStatusesResponse().pipe(
      __map(_r => _r.body as Array<PostMonitorBackend_V2ModelsCrmEntitesLeadStatus>)
    );
  }

  /**
   * @param params The `LeadsService.UpdateLeadParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `lead`:
   *
   * @return Success
   */
  UpdateLeadResponse(params: LeadsService.UpdateLeadParams): __Observable<__StrictHttpResponse<PostMonitorBackend_V2ModelsCrmEntitesLead>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.lead;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/${(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PostMonitorBackend_V2ModelsCrmEntitesLead>;
      })
    );
  }
  /**
   * @param params The `LeadsService.UpdateLeadParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `lead`:
   *
   * @return Success
   */
  UpdateLead(params: LeadsService.UpdateLeadParams): __Observable<PostMonitorBackend_V2ModelsCrmEntitesLead> {
    return this.UpdateLeadResponse(params).pipe(
      __map(_r => _r.body as PostMonitorBackend_V2ModelsCrmEntitesLead)
    );
  }

  /**
   * @param call undefined
   * @return Success
   */
  CreateCallResponse(call?: PostMonitorBackend_V2ModelsCrmEntitesLeadCall): __Observable<__StrictHttpResponse<PostMonitorBackend_V2ModelsCrmEntitesLeadCall>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = call;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/create-call`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PostMonitorBackend_V2ModelsCrmEntitesLeadCall>;
      })
    );
  }
  /**
   * @param call undefined
   * @return Success
   */
  CreateCall(call?: PostMonitorBackend_V2ModelsCrmEntitesLeadCall): __Observable<PostMonitorBackend_V2ModelsCrmEntitesLeadCall> {
    return this.CreateCallResponse(call).pipe(
      __map(_r => _r.body as PostMonitorBackend_V2ModelsCrmEntitesLeadCall)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetUserCallsResponse(id: number): __Observable<__StrictHttpResponse<Array<PostMonitorBackend_V2ModelsCrmEntitesLeadCall>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/${(id)}/calls`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PostMonitorBackend_V2ModelsCrmEntitesLeadCall>>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetUserCalls(id: number): __Observable<Array<PostMonitorBackend_V2ModelsCrmEntitesLeadCall>> {
    return this.GetUserCallsResponse(id).pipe(
      __map(_r => _r.body as Array<PostMonitorBackend_V2ModelsCrmEntitesLeadCall>)
    );
  }

  /**
   * @return Success
   */
  GetLeadDetailsDataResponse(): __Observable<__StrictHttpResponse<Array<PostMonitorBackend_V2ModelsCrmEntitesLeadDetail>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/get-lead-details-data`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PostMonitorBackend_V2ModelsCrmEntitesLeadDetail>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetLeadDetailsData(): __Observable<Array<PostMonitorBackend_V2ModelsCrmEntitesLeadDetail>> {
    return this.GetLeadDetailsDataResponse().pipe(
      __map(_r => _r.body as Array<PostMonitorBackend_V2ModelsCrmEntitesLeadDetail>)
    );
  }
}

module LeadsService {

  /**
   * Parameters for UpdateLead
   */
  export interface UpdateLeadParams {
    id: number;
    lead?: PostMonitorBackend_V2ModelsCrmEntitesLead;
  }
}

export { LeadsService }
