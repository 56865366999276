/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./expiration-notification.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./expiration-notification.component";
var styles_ExpirationNotificationComponent = [i0.styles];
var RenderType_ExpirationNotificationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ExpirationNotificationComponent, data: {} });
export { RenderType_ExpirationNotificationComponent as RenderType_ExpirationNotificationComponent };
function View_ExpirationNotificationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "notification-block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "notification-title"]], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "notification-message"]], null, null, null, null, null)), i1.ɵncd(null, 1)], null, null); }
export function View_ExpirationNotificationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ExpirationNotificationComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isShowed; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ExpirationNotificationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-expiration-notification", [], null, null, null, View_ExpirationNotificationComponent_0, RenderType_ExpirationNotificationComponent)), i1.ɵdid(1, 114688, null, 0, i3.ExpirationNotificationComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ExpirationNotificationComponentNgFactory = i1.ɵccf("app-expiration-notification", i3.ExpirationNotificationComponent, View_ExpirationNotificationComponent_Host_0, { title: "title", isShowed: "isShowed" }, {}, [".notification-title-container", "*"]);
export { ExpirationNotificationComponentNgFactory as ExpirationNotificationComponentNgFactory };
