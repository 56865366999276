import {Injectable} from '@angular/core';
import {AffiliateMemberStatisticsByUserFacade} from "../../../../storage/affiliate-member-statistics/state/affiliate-member-statistics-by-user.facade";

@Injectable({
  providedIn: 'root'
})
export class AffiliatePromgramStatisticsByUserService {

  public statistics$ = this.affiliateMembersStatisticByUserFacade.statistics$;

  constructor(
    private affiliateMembersStatisticByUserFacade: AffiliateMemberStatisticsByUserFacade
  ) {
  }

  public LoadStatistic() {
    this.affiliateMembersStatisticByUserFacade.LoadStatistics();
  }
}
