<div class="integration-form">
  <div class="integration-form__header">
    <div class="integration-form__title">
      <app-integration-form-name></app-integration-form-name>
    </div>
    <button class="integration-form__close" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="integration-form__content">
    <div class="integration-form__services">
      <div class="integration-form-service integration-form-service_left">
        <app-integration-services-selector
          label="Выберите откуда собирать данные"
          controlName="leftService"
          [services$]="leftServices"
        ></app-integration-services-selector>
      </div>
      <div class="integration-form-service integration-form-service_right">
        <app-integration-services-selector
          label="Выберите куда отправлять данные"
          controlName="rightService"
          [services$]="rightServices"
        ></app-integration-services-selector>
      </div>
    </div>
  </div>
  <div class="integration-form__actions">
    <button class="vk-standart-button" (click)="SaveIntegrations()">Сохранить интеграцию</button>
  </div>
</div>
