import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {imagesSelected, url, urlWithHttpOrHttps} from '../../validators/url-validator';
import {AdViewModel} from '../../../models/view-models/ad-view-model';
import {RateManagerComponent} from '../steps/rate-manager/rate-manager.component';
import {TargetingSettingsComponent} from '../steps/targeting-settings/targeting-settings.component';
import {VkApiService} from '../../../../vk/services/vk-api.service';
import {GroupsSelectorService} from '../../services/groups-selector/groups-selector.service';
import {VkGroupViewModel} from '../../models/vk-group.view-model';
import {AdManagerService, AutomationVkService} from '../../../../api/services';
import {MatVerticalStepper} from '@angular/material';
import {UtilsService} from '../../../../shared/services/utils.service';
import {isNullOrUndefined} from 'util';
import * as moment from 'moment';

@Component({
  selector: 'app-ad-form',
  templateUrl: './ad-form.component.html',
  styleUrls: ['./ad-form.component.scss'],
})
export class AdFormComponent implements OnInit {

  public showActivatedCabinets: boolean = false;

  @Input() public isUpdating: boolean = false;
  @Input() public ads: Array<AdViewModel>;
  @Input() public loading: boolean = false;
  @Input() public targetingSettings;
  @Input() public accountId: number;
  @Input() public campaignId: number;
  @Input() public clientId: number;
  @Input() public strategy: any;
  @Input() public image: string;
  @Input() public slots: Array<any>;
  @Input() public accounts: Array<any> = [];
  @Output() public adSaved: EventEmitter<any> = new EventEmitter<any>();
  @Output() public bindChanged: EventEmitter<any> = new EventEmitter();

  public CanBeNext = false;
  public categories = [];

  @ViewChild(RateManagerComponent) public RateManager: RateManagerComponent = null;
  @ViewChild('adTargetingForm') public AdTargeting: TargetingSettingsComponent;
  @ViewChild(MatVerticalStepper) public stepper: MatVerticalStepper;

  public errors = {
    format: 'Вы не выбрали формат объявления в пункте 1',
    teazer: 'Вы не ввели данные при создании объвления в пункте 2',
    promopost: 'Вы не ввели данные при создании объвления в пункте 2',
    targetingData: 'Вы не выбрали кому показывать объявление в пункте 3',
    campaignData: 'Вы не выбрали где сохранить объявление в пункте 4',
    rate: 'Вы не выбрали стоимость и способ показа объявления в пункте 5',
    moderationSettings: 'Вы не выбрали способ запуска и сохранения объявления в пункте 6'
  };

  public adFormats = [
    {
      title: 'Рекламная запись в новостной ленте',
      description: 'Объявление будет показываться в ВКонтакте в новостной ленте, на стенах сообществ и в рекламной сети.',
      image: 'assets/images/promopost.jpg',
      selected: true,
      value: 'promopost',
      ad_format: 9,
      min_cpm: 30,
      ad_platform: 'all',
      type: 'promoted_posts'
    },
    {
      title: 'Рекламное объявление на страницах ВКонтакте',
      description: 'Объявление будет показываться в ВКонтакте в левом рекламном блоке',
      image: 'assets/images/ad_teazer.jpg',
      selected: false,
      value: 'teazer',
      ad_format: 2,
      min_cpm: 1.2,
      ad_platform: '0',
      type: 'normal'
    },
  ];

  public get AccountRole() {
    const [accountId, clientId] = this.form.value.campaignData.accountId.split('_')
      .map(x => parseInt(x, 10));
    let selectedAccount = null;
    if (isNaN(clientId)) {
      selectedAccount = this.accounts.find(account => account.account_id === accountId && account.client_id === null);
    } else {
      selectedAccount = this.accounts.find(account => account.account_id === accountId && account.client_id === clientId);
    }
    if (selectedAccount) {
      return selectedAccount.account_role;
    }
    return null;
  }

  public form;

  constructor(
    private cd: ChangeDetectorRef,
    private vk: AdManagerService,
    private groups: GroupsSelectorService
  ) {
  }

  ngOnInit() {
    let type = 'promopost';
    console.log(this.slots.filter(x => isNullOrUndefined(x.bindedCabinetId)), this.slots.filter(x => isNullOrUndefined(x.cabinetId)).length, this.slots.length, 'EMPTY SLOTS');
    this.showActivatedCabinets = this.slots.filter(x => isNullOrUndefined(x.bindedCabinetId)).length === this.slots.length && this.slots.length > 0;
    let teazersTitles = [
      new FormControl('', [Validators.required, Validators.maxLength(33)])
    ];
    const images = new FormControl([''], [Validators.required, imagesSelected]);
    const linkUrl = new FormControl('', [Validators.required, url]);
    const cpm = new FormControl('');
    const strategy = new FormControl();
    const campaignControl = new FormControl(null, [Validators.required]);
    const group = new FormControl();
    const categoryId = new FormControl(null, [Validators.required]);
    const nameControl = new FormControl(`Объявление ${moment(new Date()).format('DD.MM.YYYY HH:mm:SS')}`);
    let ids = [];

    if (this.ads) {
      console.log(this.ads[0].ad_format === 9);
      ids = this.ads.map(ad => new FormControl(ad.id));
      categoryId.setValue(this.ads[0].category1_id || 126);
      nameControl.setValue(this.ads[0].name);
      if (this.ads[0].ad_format === 9) {
        console.log(this.image, 'IMAGE_SRC');
        const b = [this.image];
        linkUrl.setValue(this.ads[0].link_url);
        images.setValue(b);
      } else {
        teazersTitles = this.ads.map(ad => new FormControl(ad.title, [Validators.required, Validators.maxLength(33)]));
        ids = this.ads.map(ad => new FormControl(ad.id));
        images.setValue(this.ads.map(x => x.image_src));
        linkUrl.setValue(this.ads[0].link_url);
        if (this.ads[0].approved === 2) {
          linkUrl.disable();
        }
      }
      type = this.adFormats.find(adFormat => this.ads[0].ad_format === adFormat.ad_format).value;

      cpm.setValue(this.ads[0].cpm);
      campaignControl.setValue(this.ads[0].campaign_id);
    }

    if (this.strategy) {
      const inputs = this.strategy.inputs.map(input => [input.type.code, input.value])
        .reduce((acc, [key, val]) => {
          acc[key] = val;
          return acc;
        });
      strategy.setValue(inputs);
    }

    setTimeout(() => {
      this.vk.GetCategories()
        .subscribe(data => {
          this.categories = data['v1']
          // .reduce((arr, category) => arr.concat(category), [])
          // .filter(x => x !== undefined)
            .sort((a, b) => a.name < b.name ? -1 : 1);

          console.log(this.categories);
        });
    }, 4000);

    console.log(this.campaignId);
    const accountIdControl = new FormControl(`${this.accountId}_${this.clientId}` || null, [Validators.required]);
    // this.cd.detectChanges();
    this.form = new FormGroup({
      format: new FormControl(type, [Validators.required]),
      adId: new FormArray(ids),
      promopost: new FormGroup({
        text: new FormArray([
          new FormControl('', [Validators.required, Validators.maxLength(650)]),
        ]),
        name: nameControl,
        image: images,
        groupId: new FormControl('', [Validators.required]),
        linkTitle: new FormControl('', [Validators.required]),
        linkAddress: new FormControl('', [Validators.required, urlWithHttpOrHttps]),
        type: new FormControl('linear'),
        categoryId
      }),
      teazer: new FormGroup({
        categoryId,
        title: new FormArray(teazersTitles),
        name: nameControl,
        link: linkUrl,
        image: images,
        type: new FormControl('linear')
      }),
      targetingData: new FormGroup({
        country: new FormControl((this.targetingSettings) ? this.targetingSettings.country : 1),
        cities: new FormControl((this.targetingSettings) ? this.targetingSettings.cities : [0]),
        sex: new FormControl((this.targetingSettings && this.targetingSettings.sex) ? parseInt(this.targetingSettings.sex, 10) : 0),
        ageFrom: new FormControl((this.targetingSettings) ? this.targetingSettings.age_from : 0),
        ageTo: new FormControl((this.targetingSettings) ? this.targetingSettings.age_to : 0),
        groups: new FormControl((this.targetingSettings) ? this.targetingSettings.groups : ''),
        audience: new FormControl(0, [Validators.min(100)])
      }),
      campaignData: new FormGroup({
        accountId: accountIdControl,
        campaignId: campaignControl
      }),
      rate: new FormGroup({
        type: new FormControl('auto'),
        value: strategy,
        cpm
      }),
      moderationSettings: new FormGroup({
        sendModeration: new FormControl(false),
        startAfterModeration: new FormControl(false)
      })
    });
    this.cd.detectChanges();
    this.form.valueChanges
      .subscribe(value => {
        if (this.form.controls.rate.controls.type.value === 'auto') {
          if (
            this.RateManager &&
            this.RateManager.adManagerComp &&
            this.RateManager.adManagerComp.adForm
          ) {
            this.cd.detectChanges();
            this.CanBeNext = this.form.controls.rate.valid && this.RateManager.adManagerComp.adForm.form.valid;
          } else {
            this.cd.detectChanges();
            this.CanBeNext = false;
          }
        } else {
          this.cd.detectChanges();
          this.CanBeNext = this.form.controls.rate.controls.cpm.valid;
        }
        this.cd.detectChanges();
      });

    this.form.controls.campaignData.controls.accountId.valueChanges.subscribe(data => {
      const [accountId, clientId] = data.split('_');
      this.accountId = accountId;
      this.clientId = clientId;
      this.cd.detectChanges();
    });

    // this.DisableTeazerOrPromopost(this.form.value.format);
    //
    // this.form.controls.format.valueChanges.subscribe(data => {
    //   this.DisableTeazerOrPromopost(data);
    // });

    this.form.controls.moderationSettings.controls.sendModeration.valueChanges.subscribe(data => {
      if (!data) {
        this.form.controls.moderationSettings.controls.startAfterModeration.setValue(false);
      }
    });

    if (!this.strategy && this.isUpdating) {
      console.log(this.form.controls.rate.controls, 'STRATEGY');
      this.form.controls.rate.controls.type.setValue('custom');
    }

    if (this.ads && this.ads[0].ad_format === 9) {
      this.form.controls['promopost'].controls['groupId'].setValue(Math.abs(this.ads[0].wall.owner_id));
      this.form.controls['promopost'].controls['linkTitle'].setValue(this.ads[0].wall.attachments[0].link.title);
      this.form.controls['promopost'].controls['linkAddress'].setValue(this.ads[0].wall.attachments[0].link.url);
      this.ads.forEach((ad, i) => {
        console.log(this.form.controls['promopost'].controls['text']);
        this.form.controls['promopost'].controls['text'].controls[i].setValue(ad.wall.text);
      });
      console.log(this.ads[0].wall.text);
    }


    if (this.isUpdating) {
      console.log(this.AdTargeting);
      console.log(this.targetingSettings);
      this.AdTargeting.LoadStats(this.accountId, this.clientId, this.targetingSettings);
      if (this.targetingSettings.groups && this.targetingSettings.groups.length > 0) {
        setTimeout(() => {
          this.vk.GetGroupsById(this.targetingSettings.groups.split(','))
            .subscribe(groups => {
              this.groups.SelectGroups(groups.map(group => new VkGroupViewModel().fill(group)));
            });
        }, 750);
      }

    }

    this.form.valueChanges.subscribe(data => {
      console.log(data);
    });

    this.form.controls.format.valueChanges.subscribe(data => {
      this.form.controls.teazer.controls.image.setValue(['']);
      this.form.controls.promopost.controls.image.setValue(['']);
    });
  }

  public GetSelectedFormatePreview(): string {
    return `assets/images/ad-format-preview-${this.form.value.format}.jpg`;
  }

  // private DisableTeazerOrPromopost(data) {
  //   console.log(data, 'AD_FORM 234');
  //   if (data === 'teazer') {
  //     this.form.controls.teazer.enable();
  //     this.form.controls.teazer.controls.image.enable();
  //     this.form.controls.promopost.disable();
  //   } else {
  //     this.form.controls.promopost.enable();
  //     console.log(this.form.controls.promopost.controls.image, 'PROMOPOST_240');
  //     this.form.controls.promopost.controls.image.enable();
  //     console.log(this.form.controls.promopost.controls.image, 'PROMOPOST_240');
  //     this.form.controls.teazer.disable();
  //   }
  // }

  public get SelectedFormatType() {
    return this.adFormats.find(x => x.value === this.form.controls.format.value).type;
  }

  public get FormatAdType() {
    return this.adFormats.find(format => this.form.controls.format.value === format.value).ad_format;
  }

  public get ModerationSettingsDisabled() {
    const validKeys = ['text', 'image', 'title'];
    const format1 = this.adFormats.find(format => format.value === this.form.controls.format.value);
    let dataKey = 'promopost';
    if (format1.ad_format === 9) {
      dataKey = 'promopost';
    } else {
      dataKey = 'teazer';
    }
    const canBeModerationChanged = Object.keys(this.form.controls[dataKey].controls)
      .filter(control => validKeys.includes(control))
      .reduce((result, control) => result + this.form.controls[dataKey].controls[control].dirty, false);

    return canBeModerationChanged;
  }

  public SaveAd() {
    const val = Object.assign({}, this.form.value);
    val.targetingData.cities = this.form.value.targetingData.cities.filter(x => x >= 0).join();
    console.log(this.RateManager);
    val.format = this.adFormats.find(format => format.value === val.format);
    console.log(this.form.controls.rate.controls.value.dirty);
    this.adSaved.emit({
      ad: val,
      strategy: this.RateManager.strategy,
      updateStrategy: this.form.controls.rate.controls.value.dirty
    });
  }

  public OnAddTextControl() {
    const control = new FormControl('', [Validators.required, Validators.maxLength(16687)]);
    this.form.controls.promopost.controls.text.controls.push(control);
    control.setValue('');
    console.log(this.form.controls.promopost.controls.text.controls);
    this.form.controls.promopost.controls.text.valueChanges.subscribe(data => {
      console.log(data);
    });
  }

  public OnRemoveTextControl(id) {
    this.form.controls.promopost.controls.text.controls.slice(id, 1);
    this.form.valueChanges.subscribe(data => {
      console.log(data);
    });
  }

  public OnAddTitleControl() {
    this.form.controls.teazer.text.controls.push(new FormControl('', [Validators.required, Validators.maxLength(16687)]));
    this.form.valueChanges.subscribe(data => {
      console.log(data);
    });
  }

  public OnRemoveTitleControl(id) {
    this.form.controls.teazer.text.controls.slice(id, 1);
    this.form.valueChanges.subscribe(data => {
      console.log(data);
    });
  }

  SelectRate() {
    this.stepper.next();
    console.log(this.form.controls.rate.controls.type.value === 'custom', 'SELECT CAMPAIGN');
    if (this.form.controls.rate.controls.type.value === 'custom') {
      this.form.controls.rate.controls.value.setValue(null);
      this.form.controls.rate.controls.value.markAsDirty();
    }
  }

  NextSlideAfterTarget(doNext: boolean) {
    if (doNext) {
      this.stepper.next();
    }
  }

  public CanBeModerated() {
    let stat = false;
    if (this.isUpdating) {
      const type = this.form.controls.format.value;
      if (type === 'promopost') {
        stat = this.form.controls.promopost.dirty;
      } else {
        stat = this.form.controls.teazer.dirty;
      }
    } else {
      stat = true;
    }
    return stat;
  }


  public getErrors() {
    let errorsMessages = Object.keys(this.form.controls)
      .map((key) => ({key, control: this.form.controls[key]}))
      .filter(({key, control}) => control.invalid && key)
      .map(({key}) => ({key, message: this.errors[key]}));

    if (this.form.value.format === 'promopost') {
      errorsMessages = errorsMessages
        .filter(x => x.key !== 'teazer');
    } else {
      errorsMessages = errorsMessages
        .filter(x => x.key !== 'promopost');
    }

    return errorsMessages
      .map(x => x.message);
  }

  public isFormInvalid() {
    let controlNames = Object.keys(this.form.controls);

    if (this.form.value.format === 'promopost') {
      controlNames = controlNames.filter(x => x !== 'teazer');
    } else {
      controlNames = controlNames.filter(x => x !== 'promopost');
    }

    return controlNames.reduce((arr, curr) => {
      return arr && this.form.controls[curr].invalid;
    }, true);
  }

  // CanBeNext() {
  //   if (this.form.controls.rate.controls.type.value === 'auto') {
  //     if (
  //       this.RateManager &&
  //       this.RateManager.adManagerComp &&
  //       this.RateManager.adManagerComp.adForm
  //     ) {
  //       this.cd.detectChanges();
  //       return this.form.controls.rate.valid && this.RateManager.adManagerComp.adForm.form.valid;
  //     } else {
  //       this.cd.detectChanges();
  //       return false;
  //     }
  //   } else {
  //     this.cd.detectChanges();
  //     return this.form.controls.rate.controls.cpm.valid;
  //   }
  // }

  HaveActivatedAccounts() {
    return this.slots.filter(x => !isNullOrUndefined(x.bindedCabinetId)).length > 0;
  }
}
