<ng-container *ngIf="openedAdvancedMode">
  <ng-container *ngIf="!isLoading; else loadingScreen">
    <form action="" class="target-account-ad-campaign" [formGroup]="form">
      <div class="target-account-and-campaign__account-control">
        <vk-form-field [orientation]="'vertical'" [controlsWidth]="'470px'">
          <vk-label>Выберите рекламный кабинет</vk-label>
          <select name="" id="" vkInput formControlName="targetAccountId">
            <ng-container *ngFor="let account of accounts">
              <option [value]="getAccountId(account)" [disabled]="account.is_blocked">{{account.account_name}}</option>
            </ng-container>
          </select>
        </vk-form-field>
      </div>

      <div class="target-account-and-campaign__campaign target-campaign">
        <div class="target-campaign__campaign-control">
          <vk-form-field [orientation]="'vertical'" [controlsWidth]="'250px'">
            <vk-label>Выберите кампанию</vk-label>
            <select formControlName="targetCampaignId" vkInput>
              <ng-container *ngFor="let campaign of campaigns">
                <option [value]="campaign.id">{{campaign.name}}</option>
              </ng-container>
            </select>
          </vk-form-field>
        </div>
        <div class="target-campaign__create-area create-area">
          <span class="create-area__or">или</span>
          <button class="vk-standart-button create-area__button" (click)="OpenCreateNewCampaign()">Создать новую кампанию</button>
        </div>
      </div>
    </form>
  </ng-container>
  <ng-template #loadingScreen>
    <app-loading-screen diameter="18">
      Подождите, идет загрузка кабинетов
    </app-loading-screen>
  </ng-template>
</ng-container>
