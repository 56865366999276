import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {
  AdManagerService,
  AutomationVkService,
  AutomationVkSlotService,
  StrategiesService
} from '../../../../../../api/services';
import {concat} from 'rxjs/internal/observable/concat';
import {AdSourceViewModel} from '../../../../models/ad-source.view-model';
import {emojiCount, url, urlWithHttpOrHttps} from '../../../../validators/url-validator';
import {delay, flatMap} from 'rxjs/operators';
import {VkApiGroup} from '../../../../../../vk/models/api/vk-api-group';
import {APIReponseWrapperListCabinet} from '../../../../../../api/models/apireponse-wrapper-list-cabinet';
import {
  AdTargeting,
  APIReponseWrapperListCampaign,
  APIReponseWrapperListUserAutomationCabinetSlot,
  BidRange,
  Cabinet,
  Campaign,
  Group,
  PostMonitorBackend_V2ModelsAdManagerAdEditSecificationCustomizableViewModel,
  PostMonitorBackend_V2ModelsvkVKAPIGroupModel,
  UserAutomationCabinetSlot
} from '../../../../../../api/models';
import {Observable} from 'rxjs/Observable';
import 'rxjs-compat/add/observable/empty';
import {DomainOnlyPipe} from '../../../../../../shared/pipes/domain-only.pipe';
import {MatVerticalStepper} from '@angular/material';
import {AccountViewModel} from '../../../../../models/view-models/account-view-model';
import {isNullOrUndefined} from 'util';
import * as moment from 'moment';
import {AdViewModel} from '../../../../../models/view-models/ad-view-model';
import {Router} from '@angular/router';
import {PromopostFormLiteComponent} from '../promopost-form-lite/promopost-form-lite.component';
import {TeaserFormLiteComponent} from '../teaser-form-lite/teaser-form-lite.component';
import {ChangeDetection} from '@angular/cli/lib/config/schema';

import * as Sentry from '@sentry/browser';
import {GroupsSelectorService} from '../../../../services/groups-selector/groups-selector.service';
import {VkGroupViewModel} from '../../../../models/vk-group.view-model';

enum adSourceTypes {
  VK_GROUP = 'vk_group',
  WEB_SITE = 'web_site'
}

@Component({
  selector: 'app-ad-form-lite',
  templateUrl: './ad-form-lite.component.html',
  styleUrls: ['./ad-form-lite.component.scss']
})
export class AdFormLiteComponent implements OnInit {

  @ViewChild(MatVerticalStepper) public stepper: MatVerticalStepper;
  @ViewChild(PromopostFormLiteComponent) public promopostForm: PromopostFormLiteComponent;
  @ViewChild(TeaserFormLiteComponent) public teaserForm: TeaserFormLiteComponent;

  @Input() public ad: AdViewModel;
  @Input() public targetingData: AdTargeting;
  @Input() public isSubmitLoading: boolean = false;
  @Input() public isUpdating: boolean = false;

  @Output() public AdSaved: EventEmitter<any> = new EventEmitter<any>();

  public IsCampaignsLoading = false;

  public errors = {
    format: 'Вы не выбрали формат объявления в пункте 1',
    teazer: 'Вы не ввели данные при создании объвления в пункте 2',
    promopost: 'Вы не ввели данные при создании объвления в пункте 2',
    targetingData: 'Вы не выбрали кому показывать объявление в пункте 3',
    campaignData: 'Вы не выбрали где сохранить объявление в пункте 4',
    rate: 'Вы не выбрали стоимость и способ показа объявления в пункте 5',
    moderationSettings: 'Вы не выбрали способ запуска и сохранения объявления в пункте 6'
  };

  public adSources: Array<AdSourceViewModel> = [
    {
      title: 'На вашу группу в ВКонтакте',
      description: '',
      name: adSourceTypes.VK_GROUP
    },
    {
      title: 'На сайт',
      description: 'Вы можете уазать ссылку на ваш сайт',
      name: adSourceTypes.WEB_SITE
    }
  ];

  public adFormats = [
    {
      title: 'Рекламная запись в новостной ленте',
      description: 'Объявление будет показываться в ВКонтакте в новостной ленте, на стенах сообществ и в рекламной сети.',
      image: 'assets/images/promopost.jpg',
      selected: true,
      value: 'promopost',
      ad_format: 9,
      min_cpm: 30,
      ad_platform: 'all',
      type: 'promoted_posts'
    },
    {
      title: 'Рекламное объявление на страницах ВКонтакте',
      description: 'Объявление будет показываться в ВКонтакте в левом рекламном блоке',
      image: 'assets/images/ad_teazer.jpg',
      selected: false,
      value: 'teazer',
      ad_format: 2,
      min_cpm: 1.2,
      ad_platform: '0',
      type: 'normal'
    },
  ];

  public PROMOPOST_CAMPAIGN_NAME = 'Кампания PostMonitor - Запись в новостной ленте';
  public TEAZER_CAMPAIGN_NAME = 'Кампания PostMonitor - Объявления на страницах ВКонтакте';

  public currentResource = 'vk_group';

  public groups: Array<VkApiGroup>;

  public form: FormGroup;

  private lastGroupId = null;
  public currentAccount: Cabinet;
  public accounts: Array<AccountViewModel>;
  public campaign: Campaign;
  public campaigns: Array<Campaign>;
  public slots: Array<UserAutomationCabinetSlot>;
  private bidRanges: Array<BidRange> = [];
  private groupIdValidators = [Validators.required];
  public displayedErrors: any[];
  public IsPromopost: boolean = true;
  public isAdValid: boolean;
  public countOfOpenedGroups: number = 0;
  public systemErrors = [];

  public get AccountId() {
    return `${this.currentAccount.account_id}_null`;
  }

  constructor(
    private adsManagerService: AdManagerService,
    private automationVkService: AutomationVkService,
    private automationVkSlotService: AutomationVkSlotService,
    private strategiesService: StrategiesService,
    private router: Router,
    private cd: ChangeDetectorRef,
    private groupsSelectorService: GroupsSelectorService
  ) {
  }

  public validatorsInfo = {
    promopost: {
      groupId: this.groupIdValidators,
      linkAddress: [
        Validators.required,
        url
      ],
      text: [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(16384),
        emojiCount
      ],
      image: [
        Validators.required,
      ]
    },
    teazer: {
      title: [
        Validators.required,
      ],
      link: [
        Validators.required,
        url,
      ],
      image: [
        Validators.required
      ]
    }
  };

  ngOnInit() {
    this.InitStepsFormErrors();

    this.LoadRanges();
    this.InitForm();
    this.LoadGroups()
      .pipe(
        flatMap(() => this.LoadAccountsData()),
      )
      .subscribe(() => {
        if (this.groups && this.groups.length > 0) {
          const firstGroupId = this.groups[0].id;
          this.SetSelectedGroup(firstGroupId, null);
          if (this.groups.length === 1) {
            this.addGroupInfoToVkGroupAddTargetResource(this.groups[0]);
          }
          this.lastGroupId = firstGroupId;
        }
        if (this.ad) {
          if (this.IsAdLinkLeadForAdminGroup()) {
            this.currentResource = adSourceTypes.VK_GROUP;
          } else {
            this.currentResource = adSourceTypes.WEB_SITE;
          }
          this.FillForm();
        }
      });
  }

  private InitStepsFormErrors() {
    this.stepper.selectionChange.subscribe(({selectedIndex}) => {
      const triggerSelectedIndex = (this.isUpdating) ? 1 : 2;
      if (selectedIndex >= triggerSelectedIndex) {
        const teazer = this.getFormControl('teazer');
        const promopost = this.getFormControl('promopost');
        console.log(teazer.valid, promopost.valid);
        this.isAdValid = teazer.valid || promopost.valid;
        if (!this.isAdValid) {
          this.displayedErrors = ['Вы не ввели данные при создании объвления в пункте 2'];
          this.LoadSystemErrors();
        }
        console.log(this.displayedErrors, 'ERRORS');
      }
    });
  }

  private LoadSystemErrors() {
    this.systemErrors = [];
    console.log(this.HasAnyAdminCabinetAssgnedToSystem(), 'NO FUCKING ACCOUNT');
    if (!this.HasAnyAdminCabinetAssgnedToSystem()) {
      this.systemErrors.push('У вас нет ни одного рекламного кабинета. Вернитесь на шаг 3');
    }
    console.log((this.HasAnyOpenedGroups() && !this.IsAdForPromo()), this.HasAnyOpenedGroups(), this.IsAdForPromo());
    if (!(this.HasAnyOpenedGroups())) {
      if (this.IsAdForPromo()) {
        this.systemErrors.push('У вас нет ни одной открытой группы для публикации объявления.');
      }
    }
    if (!this.IsFormValid()) {
      this.systemErrors.push('Вы не верно заполнили данные объявления. Вернитесь на шаг 2');
    }
  }

  private InitAdTimers() {
    this.stepper.selectionChange.subscribe(({selectedIndex}) => {
      this.StartPromopostTimer(selectedIndex);
      this.StartTeaserTimer(selectedIndex);
    });
  }

  private StartPromopostTimer(selectedIndex: any) {
    if (selectedIndex === 1 && this.IsSelectedFormatPromopost()) {
      this.promopostForm.StartImageErrorTimer();
    }
  }

  private StartTeaserTimer(selectedIndex: any) {
    if (selectedIndex === 1 && !this.IsSelectedFormatPromopost()) {
      this.teaserForm.StartImageErrorTimer();
    }
  }

  public GetSelectedFormatePreview(): string {
    return `assets/images/ad-format-preview-${this.form.value.format}.jpg`;
  }

  private InitForm() {
    this.form = new FormGroup({
      format: new FormControl('promopost', [Validators.required]),
      promopost: new FormGroup({
        groupId: new FormControl(null, this.groupIdValidators),
        linkAddress: new FormControl(null, [Validators.required, urlWithHttpOrHttps]),
        linkTitle: new FormControl(null),
        text: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(650), emojiCount]),
        image: new FormControl('', [Validators.required])
      }),
      teazer: new FormGroup({
        groupId: new FormControl(),
        title: new FormControl('', [Validators.required]),
        link: new FormControl('', [Validators.required, url]),
        image: new FormControl('', [Validators.required])
      }),
      targetingData: new FormGroup({
        country: new FormControl(),
        cities: new FormControl(),
        sex: new FormControl(),
        ageFrom: new FormControl(),
        ageTo: new FormControl(),
        groups: new FormControl(),
        audience: new FormControl()
      })
    });

    const adFormContol = this.getFormControl('format');
    adFormContol.valueChanges.subscribe(data => {
      this.ToggleValidationMode();
      console.log(data, 'IS PROMO POST ');
      this.IsPromopost = data === 'promopost';
    });

    (this.form.controls.promopost as FormGroup).controls.groupId
      .valueChanges
      .subscribe((groupId) => {
        if (this.countOfOpenedGroups > 0 && this.groups && !this.ad) {
          const newGroupId = parseInt(groupId, 10);
          const lastSelectedGroup = this.findGroupById(this.lastGroupId);
          this.SetSelectedGroup(newGroupId, lastSelectedGroup);
          this.lastGroupId = newGroupId;
        }
      });
    const promopostLinkControl = this.getFormControl('promopost', 'linkAddress');
    const teazerLinkControl = this.getFormControl('teazer', 'link');

    promopostLinkControl.valueChanges.subscribe(() => {
      this.LoadTargetAfterChangeLinkValue();
    });

    teazerLinkControl.valueChanges.subscribe(() => {
      this.LoadTargetAfterChangeLinkValue();
    });

    this.initTargetingData();
  }

  private LoadTargetAfterChangeLinkValue() {
    if (this.campaign) {
      this.GetTargetingStats()
        .subscribe();
    }
  }

  private findGroupById(lastGroupId: any) {
    console.log(this.groups, lastGroupId);
    return this.groups.find(group => group.id === lastGroupId);
  }

  private findGroupByLink(groupLink: string) {
    const foundedGroup = this.groups.find(groupItem => groupLink.includes(groupItem.screen_name));
    return foundedGroup;
  }

  public SelectFormat() {
    this.stepper.next();
  }

  private LoadGroups() {
    return this.adsManagerService
      .GetAdminGroups()
      .debounceTime(1000)
      .do((groups: Array<any>) => {
        this.groups = groups;
        console.log('Sending Groups');
        Sentry.addBreadcrumb({
          category: 'ad-form-lite',
          message: '',
          data: groups
        });
        this.countOfOpenedGroups = this.groups.filter(group => group.is_closed === 0).length;
        if (this.ad) {
          const link = this.ad.GetTeazerVkLink();
          if (link) {
            const teazerGroup: VkApiGroup = this.groups.find(group => link.includes(group.screen_name));
            if (teazerGroup) {
              const teazerGroupIdControl = this.getFormControl('teazer', 'groupId');
              teazerGroupIdControl.setValue(teazerGroup.id);
            }
          }
        }
      });
  }

  private LoadSlots() {
    return this.automationVkSlotService.GetSlots()
      .do((slotsResponse: APIReponseWrapperListUserAutomationCabinetSlot) => {
        this.slots = slotsResponse.data;
      });
  }

  private LoadAccounts() {
    return this.automationVkService.GetAccounts()
      .debounceTime(1000)
      .do((accountsResponse: APIReponseWrapperListCabinet) => {
        this.accounts = accountsResponse.data
          .filter(account => account.access_role === 'admin')
          .map((account, index) => this.ConvertAccountToViewModel(account, index));
        this.currentAccount = this.accounts[0];
      });
  }

  private ConvertAccountToViewModel(account, index) {
    console.log(this);
    const accountViewModel = new AccountViewModel(
      account.account_id,
      account.account_name,
      account.access_role,
      account.account_status,
      account.account_type,
      !this.IsAccountAssignedToSystem(account),
      account.client_id,
      index
    );

    return accountViewModel;
  }

  private LoadCampaign() {
    return this.automationVkService.GetCampaigns({
      clientId: this.currentAccount.client_id,
      accountId: this.currentAccount.account_id
    })
      .debounceTime(1000)
      .do((campaignsResponse: APIReponseWrapperListCampaign) => {
        this.campaigns = campaignsResponse.data;
        this.campaign = this.CurrentCampaign();
      });
  }

  private CurrentCampaign() {
    return this.campaigns.find(campaign => campaign.name === this.CurrentCampaignName);
  }

  private SetSelectedGroup(selectedGroupId, lastSelectedGroup = null) {
    const selectedGroup = this.groups.find(group => group.id === selectedGroupId);
    const promopostFormGroup = (this.form.controls.promopost as FormGroup);
    const lastSelectedGroupTitle = (lastSelectedGroup) ? lastSelectedGroup.name : '';

    promopostFormGroup.controls.groupId.setValue(selectedGroupId, {emitEvent: false});
    this.SetPromopostLinkTitle(selectedGroup.name, lastSelectedGroupTitle);
    if (this.currentResource === adSourceTypes.VK_GROUP) {
      this.SetPromopostLinkAddress(`https://vk.com/${selectedGroup.screen_name}`);
      console.log(selectedGroupId, 'LOG SELECTED GROUP ID');
      this.SetSelectedGroupLinkForTeaser(selectedGroupId);
      this.SetSelectedGroupIdToTeazer(selectedGroupId);
    }
  }

  private SetPromopostLinkTitle(selectedGroupTitle, lastSelectedGroupTitle = '') {
    const promopostFormGroupLinkTitleControl = (this.form.controls.promopost as FormGroup).get('linkTitle');
    if (promopostFormGroupLinkTitleControl.value === null || promopostFormGroupLinkTitleControl.value === lastSelectedGroupTitle) {
      promopostFormGroupLinkTitleControl
        .setValue(selectedGroupTitle);
    }
  }

  private SetPromopostLinkAddress(linkUrl: string) {
    const promopostFormGroupLinkAddressControl = (this.form.controls.promopost as FormGroup).get('linkAddress');
    promopostFormGroupLinkAddressControl.setValue(linkUrl);
  }

  private addGroupInfoToVkGroupAddTargetResource(group) {
    const targetResource = this.adSources.find(x => x.name === 'vk_group');
    targetResource.title = `На вашу группу в ВКонтакте. На группу <a target="_blank" rel="noopener nofolow" href="https://vk.com/${group.screen_name}">${group.name}</a>`;
  }

  private SetSelectedGroupLinkForTeaser(groupId: number) {
    const selectedGroup: VkApiGroup = this.groups.find(group => group.id === groupId);
    const teazerLinkControl = this.getFormControl('teazer', 'link');
    teazerLinkControl.setValue(`https://vk.com/${selectedGroup.screen_name}`);
  }

  private SetSelectedGroupIdToTeazer(groupId) {
    const teazerGroupIdControl = this.getFormControl('teazer', 'groupId');
    teazerGroupIdControl.setValue(groupId);
  }

  private getFormControl(groupName, controlKey = null) {
    let teazerFormControl = this.form.controls[groupName];
    if (controlKey) {
      teazerFormControl = teazerFormControl.get(controlKey);
    }
    if (!teazerFormControl) {
      throw new Error(`Control with key ${controlKey} is not defined`);
    }
    return teazerFormControl;
  }

  private initTargetingData() {
    if (this.targetingData) {
      console.log(this.targetingData, 'WTF TARGETING');
      const targetingData = {
        country: this.targetingData.country,
        cities: this.targetingData.cities ? this.targetingData.cities.split(',') : [0],
        groups: this.targetingData.groups,
        ageFrom: this.targetingData.age_from,
        ageTo: this.targetingData.age_to,
        sex: parseInt(this.targetingData.sex, 10),
        audience: this.targetingData.count
      };
      this.setTargetingData(targetingData);
      this.LoadGroupsInfo();
    } else {
      this.setTargetingData({
        country: 1,
        cities: [0],
        sex: 0,
        groups: '',
        ageFrom: 0,
        ageTo: 0,
        audience: 0
      });
    }
  }

  private setTargetingData(targetingData) {
    const targetingDataControlGroup = this.getFormControl('targetingData');
    targetingDataControlGroup.setValue(targetingData);
  }

  public IsSelectedFormatPromopost() {
    const adFormatControl = this.getFormControl('format');
    return adFormatControl.value === 'promopost';
  }

  public get CurrentAdFormat() {
    const adFormatControl = this.getFormControl('format');
    return this.adFormats.find(adFormat => adFormat.value === adFormatControl.value);
  }

  public get CurrentCampaignName() {
    let currentCampaignName = '';
    console.log('Is Promopost', this.IsSelectedFormatPromopost());
    if (this.IsSelectedFormatPromopost()) {
      currentCampaignName = this.PROMOPOST_CAMPAIGN_NAME;
    } else {
      currentCampaignName = this.TEAZER_CAMPAIGN_NAME;
    }
    return currentCampaignName;
  }

  private CreateCampaignIfNotExists() {
    let result = Observable.of({});
    if (!this.CurrentCampaign()) {
      result = this.CreateCampaignWithName(this.CurrentCampaignName)
        .pipe(
          delay(1000),
          flatMap(() => this.LoadCampaign())
        );
    }
    return result;
  }

  private CreateCampaignWithName(name: string) {
    return this.adsManagerService.CreateCampaign({
      accountId: this.currentAccount.account_id,
      campaigns: [
        {
          type: this.CurrentAdFormat.type,
          name,
          status: 1
        },
      ]
    });
  }

  private GetTargetingStats() {
    const linkUrl = this.GetLinkUrl();
    const TargetingControl = this.getFormControl('targetingData');
    const criteria = TargetingControl.value;
    console.log('GROUPS', typeof criteria.groups);
    return this.adsManagerService.GetTargetingStats({
      adFormat: this.CurrentAdFormat.ad_format,
      accountId: this.currentAccount.account_id,
      linkUrl,
      clientId: null,
      linkDomain: new DomainOnlyPipe().transform(linkUrl),
      criteria: {
        age_to: criteria.age_to,
        age_from: criteria.age_from,
        groups: (typeof criteria.groups === 'string') ? criteria.groups : criteria.groups.join(),
        country: criteria.country,
        cities: criteria.cities.join(),
        sex: criteria.sex
      }
    })
      .do(targetingData => {
        (TargetingControl as FormGroup).controls.audience.setValue(targetingData.audience_count);
      });
  }

  private GetLinkUrl() {
    const IsPromopost = this.IsSelectedFormatPromopost();
    let linkUrl = '';
    if (IsPromopost) {
      linkUrl = this.GetPromopostLinkUrl();
    } else {
      linkUrl = this.GetTeazerLinkUrl();
    }
    return linkUrl;
  }

  private GetPromopostLinkUrl() {
    const promopostControl = this.getFormControl('promopost', 'linkAddress');
    console.log(promopostControl.value, 'PROMOPOST TARGETING URL');
    return promopostControl.value;
  }

  private GetTeazerLinkUrl() {
    const teazerControl = this.getFormControl('teazer', 'link');
    return teazerControl.value;
  }

  private GetTargetingCities(criteria) {
    return criteria.cities.join();
  }

  private IsAccountAssignedToSystem(account: Cabinet) {
    if (account) {
      const accountId = account.account_id;
      const clientId = account.client_id;
      let slot = null;
      if (clientId) {
        slot = this.slots.find(slotsItem => slotsItem.bindedCabinetId === accountId && slotsItem.bindedClientId === clientId);
      } else {
        slot = this.slots.find(slotsItem => slotsItem.bindedCabinetId === accountId);
      }

      console.log(slot);

      return !isNullOrUndefined(slot);
    } else {
      return false;
    }
  }

  public BindCabinet($event: any) {
    this.LoadAccountsData()
      .subscribe();
  }

  private LoadAccountsData() {
    return this.LoadSlots()
      .pipe(
        flatMap(() => this.LoadAccounts())
      );
  }

  public NextWithCheckingCampaigns() {
    this.IsCampaignsLoading = true;
    console.log(this.IsCampaignsLoading);
    this.LoadCampaignsAndCheckForExistingSystemCampaign()
      .subscribe(() => {
        this.stepper.next();
        this.IsCampaignsLoading = false;
      });
  }

  private LoadCampaignsAndCheckForExistingSystemCampaign() {
    return this.LoadCampaign()
      .pipe(
        flatMap(() => this.CreateCampaignIfNotExists())
      );
  }

  public HasAssignedAccounts() {
    if (this.accounts && this.slots && this.slots.length > 0) {
      const countOfAssignedSlots = this.slots
        .filter(x => x.bindedCabinetId !== null)
        .length;

      const adminCabinet = this.accounts.find(account => account.account_role === 'admin');
      if (adminCabinet) {
        const slotWithAdminCabinet = this.slots
          .find(slot => slot.bindedCabinetId === adminCabinet.account_id && slot.bindedClientId === adminCabinet.client_id);

        if (slotWithAdminCabinet) {
          return true;
        } else {
          console.warn('Кабинет с доступом администартора не подключен к системе');
          return false;
        }
      }
      console.warn('Нет кабинета с доступом администратора');
      return false;

      // let hasAssignedAdminCabinet = false;
      // const hasAssignedSlots = countOfAssignedSlots > 0;
      // const adminNotAgencyCabinets = this.accounts.filter(account => account.account_role === 'admin' && account.account_type !== 'agency');
      // adminNotAgencyCabinets.forEach(adminCabinet => {
      //   const slotWithAdminCabinet = this.slots.find(slot => slot.bindedCabinetId === adminCabinet.account_id);
      //   hasAssignedAdminCabinet = slotWithAdminCabinet !== null;
      // });
      // const hasAdminAccounts = (adminNotAgencyCabinets) ? adminNotAgencyCabinets.length > 0 : false;
      // return hasAssignedSlots && hasAdminAccounts && hasAssignedAdminCabinet;
    } else {
      return false;
    }
  }

  public FinishEditingAd() {
    console.log('HasAssignedAccounts', this.HasAssignedAccounts());
    if (this.HasAssignedAccounts()) {
      this.NextWithCheckingCampaigns();
    } else {
      this.stepper.next();
    }
  }

  private GenerateGeneralData() {
    const promopost = this.getFormControl('promopost').value;

    const adData = {
      adSpecifications: [
        this.GenerateSpecification()
      ],
      accountId: this.currentAccount.account_id,
      text: promopost.text,
      groupId: promopost.groupId,
      postId: (this.ad && this.ad.ad_format === 9) ? this.ad.GetPostId() : 0,
      linkTitle: promopost.linkTitle,
      linkUrl: promopost.linkAddress
    };

    return adData;
  }

  public GetAdImage() {
    const promopostImage = this.getFormControl('promopost', 'image').value;
    const teazerImage = this.getFormControl('teazer', 'image').value;

    let image = '';
    if (this.IsSelectedFormatPromopost()) {
      image = promopostImage;
    } else {
      image = teazerImage;
    }

    return image[0];
  }

  public GetLink() {
    const promopostLinkUrl = this.getFormControl('promopost', 'linkAddress').value;
    const teazerlinkUrl = this.getFormControl('teazer', 'link').value;

    let link = '';
    if (this.IsSelectedFormatPromopost()) {
      link = promopostLinkUrl;
    } else {
      link = teazerlinkUrl;
    }
    return link;
  }

  public GenerateSpecification() {
    const currentBidRange = this.GetCurrentBidRange();
    const formatAd = this.CurrentAdFormat;
    const targetingData = this.getFormControl('targetingData').value;
    const campaign_id = this.campaign.id;
    const teazer = this.getFormControl('teazer').value;
    const image = this.GetAdImage();
    const link_url = this.GetLink();
    const link_domain = new DomainOnlyPipe().transform(link_url);

    console.log(targetingData, 'AD Format');

    const data = {
      image,
      campaign_id,
      link_url,
      link_domain,
      ad_format: formatAd.ad_format,
      age_from: targetingData.ageFrom,
      age_to: targetingData.ageTo,
      cities: targetingData.cities.filter(cityId => cityId > 0).join(),
      country: targetingData.country,
      sex: parseInt(targetingData.sex, 10),
      groups: targetingData.groups,
      title: teazer.title,
      cpm: currentBidRange.cpmMin / 100,
      cost_type: 1,
      impressions_limited: (formatAd.ad_format === 2) ? 1 : null,
      impressions_limit: (formatAd.ad_format === 9) ? 1 : null,
      category1_id: 126,
      name: `Новое объявление ${moment(new Date()).format('DD.MM.YYYY HH:mm:ss')}`
    };


    if (this.isUpdating) {
      data['attachments'] = '';
    }

    return data;
  }

  private LoadRanges() {
    this.strategiesService.GetBidRanges()
      .subscribe(bidRanges => {
        this.bidRanges = bidRanges;
      });
  }

  private GetCurrentBidRange(): BidRange {
    const currentBidRange = this.bidRanges.find(bidRange => bidRange.adFormat === this.CurrentAdFormat.ad_format);
    return currentBidRange;
  }

  SaveAd() {
    if (this.CanSaveAd()) {
      if (!this.campaign) {
        this.LoadCampaignsAndCheckForExistingSystemCampaign()
          .subscribe(() => {
            this.AdSaved.emit(this.GenerateGeneralData());
          });
      } else {
        this.AdSaved.emit(this.GenerateGeneralData());
      }
    } else {
      this.LoadSystemErrors();
    }
  }

  private FillForm() {
    const teaserControl = this.getFormControl('teazer');
    const promopostControl = this.getFormControl('promopost');
    const format = this.getFormControl('format');

    const formatValue = this.GetFormatByAdFormat(this.ad.ad_format);

    format.setValue(formatValue.value);

    if (this.IsSelectedFormatPromopost()) {
      if (this.groups) {
        console.log(this.ad.GetAdGroupId());
        const groupId = this.findGroupById(-this.ad.GetAdGroupId());
        console.log(groupId, ' GROUPasdasd');
      }
      promopostControl.setValue({
        text: this.ad.wall.text,
        linkAddress: this.ad.GetAdAttachmentLinkUrl(),
        linkTitle: this.ad.GetAdAttachmentLinkTitle(),
        groupId: -this.ad.GetAdGroupId(),
        image: [this.ad.image_src]
      }, {emitEvent: false, onlySelf: true});
    } else {
      let group1 = null;
      if (this.groups) {
        console.log(this.groups, this.ad.link_url);
        group1 = this.groups.find(group => `https://vk.com/${group.screen_name}` === this.ad.link_url);
      }
      teaserControl.setValue({
        title: this.ad.title,
        link: this.ad.link_url,
        image: [this.ad.image_src],
        groupId: group1 ? group1.id : ''
      });
    }
  }

  private GetFormatByAdFormat(ad_format: number) {
    const format = this.adFormats.find(adFormat => adFormat.ad_format === ad_format);
    return format;
  }

  private getPromopostGroupByLink() {
    const promopostLink = this.ad.link_url.replace('https://vk.com/wall-', '');
    const groupId = promopostLink.split('_')[0];
    return groupId;
  }

  private IsAdLinkLeadForAdminGroup() {
    let isAdminGroupLead = false;
    console.log(isAdminGroupLead, this.ad.link_url.includes('vk.com'));
    if (this.ad.link_url.includes('vk.com')) {
      const adminGroup = this.findGroupByLink(this.ad.getOwnerLink());
      console.log(adminGroup, 'ADMIN GROUP');
      if (adminGroup) {
        isAdminGroupLead = true;
      } else {
        isAdminGroupLead = false;
      }
    } else {
      isAdminGroupLead = false;
    }

    return isAdminGroupLead;
  }

  private EnableGroupValidation() {
    const teazerControl = this.getFormControl('teazer', 'groupId');
    teazerControl.setValidators(this.groupIdValidators);
  }

  private DisableGroupValidation() {
    const teazerControl = this.getFormControl('teazer', 'groupId');
    teazerControl.setValidators(null);
  }

  OnChangeCurrentResource() {
    if (this.currentResource === adSourceTypes.VK_GROUP) {
      this.SetAdLink();
      this.EnableGroupValidation();
    } else {
      this.DisableGroupValidation();
    }
  }

  public SetAdLink() {
    let linkControl = null;
    if (this.IsPromopost) {
      linkControl = this.getFormControl('promopost', 'linkAddress') as FormControl;
    } else {
      linkControl = this.getFormControl('teazer', 'link') as FormControl;
    }

    if (this.groups && this.groups.length > 0) {
      const firstGroupScreenName = this.groups[0].screen_name;
      const groupLinkUrl = `https://vk.com/${firstGroupScreenName}`;
      linkControl.setValue(groupLinkUrl);
    }
  }

  public isPromopostValid() {
    const promopostControl = this.getFormControl('promopost');
    return promopostControl.valid;
  }

  public isTeazerValid() {
    const teazerControl = this.getFormControl('teazer');
    return teazerControl.valid;
  }

  IsAdValid() {
    let status = false;
    if (this.IsSelectedFormatPromopost()) {
      status = this.isPromopostValid();
    } else {
      status = this.isTeazerValid();
    }

    return status;
  }

  public getErrors() {
    let errorsMessages = Object.keys(this.form.controls)
      .map((key) => ({key, control: this.form.controls[key]}))
      .filter(({key, control}) => control.invalid && key)
      .map(({key}) => ({key, message: this.errors[key]}));

    if (this.form.value.format === 'promopost') {
      errorsMessages = errorsMessages
        .filter(x => x.key !== 'teazer');
    } else {
      errorsMessages = errorsMessages
        .filter(x => x.key !== 'promopost');
    }

    return errorsMessages
      .map(x => x.message);
  }

  private ToggleValidationMode() {
    console.log('Validation ToggleD');
    if (this.IsSelectedFormatPromopost()) {
      this.EnableValidationPromopost();
    } else {
      this.EnableVaidationTeaser();
    }
    // this.cd.();
  }

  private EnableValidationPromopost() {
    this.EnableFormValidators('promopost');
    this.DisableFormValidators('teazer');
  }

  private EnableVaidationTeaser() {
    this.EnableFormValidators('teazer');
    this.DisableFormValidators('promopost');
  }

  private EnableFormValidators(formName) {
    const formValidators = this.validatorsInfo[formName];
    Object.keys(formValidators)
      .map((formFieldKey) => ({key: formFieldKey, validators: formValidators[formFieldKey]}))
      .forEach(({key, validators}) => {
        const formFieldControl = this.getFormControl(formName, key);
        formFieldControl.setValidators(validators);
      });
  }

  private DisableFormValidators(formName) {
    const formValidators = this.validatorsInfo[formName];
    Object.keys(formValidators)
      .forEach((key) => {
        const formFieldControl = this.getFormControl(formName, key);
        formFieldControl.setValidators(null);
        formFieldControl.updateValueAndValidity();
      });
  }

  private CanSaveAd() {
    const hasAnyAdminAccount = this.HasAnyAdminCabinetAssgnedToSystem();
    const hasAnyOpenedGroups = this.HasAnyOpenedGroups();
    const isAdPromo = this.IsAdForPromo();
    const isFormValid = this.IsFormValid();
    console.log(hasAnyAdminAccount, hasAnyOpenedGroups, isFormValid, 'CAN BE SAVED');
    return hasAnyAdminAccount && (hasAnyOpenedGroups || !isAdPromo) && isFormValid;
  }

  private IsFormValid() {
    const teazerFormControl = this.getFormControl('teazer');
    const promopostFormControl = this.getFormControl('promopost');
    return (teazerFormControl.valid || promopostFormControl.valid);
  }

  private HasAnyOpenedGroups() {
    return this.countOfOpenedGroups > 0;
  }

  private HasAnyAdminCabinetAssgnedToSystem() {
    console.log(this.currentAccount);
    const hasAnyAdminAccount = this.currentAccount !== null;
    const AssignedToSystem = this.IsAccountAssignedToSystem(this.currentAccount);
    return hasAnyAdminAccount && AssignedToSystem;
  }

  public LoadGroupsInfo() {
    if (this.targetingData.groups) {
      const groupsIds = this.targetingData.groups
        .split(',')
        .map(groupId => parseInt(groupId, 10));

      this.adsManagerService
        .GetGroupsById(groupsIds)
        .subscribe((groups: Array<PostMonitorBackend_V2ModelsvkVKAPIGroupModel>) => {
          const groupsToSelect = groups.map(group => new VkGroupViewModel().fill(group));
          this.groupsSelectorService.SelectGroups(groupsToSelect);
        });
    }
  }

  private IsAdForPromo() {
    let isAdForPromo = false;
    const formatControl = this.getFormControl('format');
    if (formatControl) {
      isAdForPromo = formatControl.value === 'promopost';
    }
    return isAdForPromo;
  }
}
