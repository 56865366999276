import { AffiliateMemberStatisticsByUserFacade } from "../../../../storage/affiliate-member-statistics/state/affiliate-member-statistics-by-user.facade";
import * as i0 from "@angular/core";
import * as i1 from "../../../../storage/affiliate-member-statistics/state/affiliate-member-statistics-by-user.facade";
var AffiliatePromgramStatisticsByUserService = /** @class */ (function () {
    function AffiliatePromgramStatisticsByUserService(affiliateMembersStatisticByUserFacade) {
        this.affiliateMembersStatisticByUserFacade = affiliateMembersStatisticByUserFacade;
        this.statistics$ = this.affiliateMembersStatisticByUserFacade.statistics$;
    }
    AffiliatePromgramStatisticsByUserService.prototype.LoadStatistic = function () {
        this.affiliateMembersStatisticByUserFacade.LoadStatistics();
    };
    AffiliatePromgramStatisticsByUserService.ngInjectableDef = i0.defineInjectable({ factory: function AffiliatePromgramStatisticsByUserService_Factory() { return new AffiliatePromgramStatisticsByUserService(i0.inject(i1.AffiliateMemberStatisticsByUserFacade)); }, token: AffiliatePromgramStatisticsByUserService, providedIn: "root" });
    return AffiliatePromgramStatisticsByUserService;
}());
export { AffiliatePromgramStatisticsByUserService };
