import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";

@Component({
  selector: 'app-rate-manager-explain',
  templateUrl: './rate-manager-explain.component.html',
  styleUrls: ['./rate-manager-explain.component.scss']
})
export class RateManagerExplainComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<RateManagerExplainComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }

}
