import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MoiSkladExportComponent } from './components/moi-sklad-export/moi-sklad-export.component';
import { MoiSkladImportComponent } from './components/moi-sklad-import/moi-sklad-import.component';
import {MatRadioModule} from '@angular/material';
import {ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '../../../shared/shared.module';

@NgModule({
    declarations: [MoiSkladExportComponent, MoiSkladImportComponent],
  exports: [
    MoiSkladExportComponent,
    MoiSkladImportComponent
  ],
    imports: [
        CommonModule,
        MatRadioModule,
        ReactiveFormsModule,
        SharedModule
    ]
})
export class MoiSkladModule { }
