<div class="vk-container">
  <div class="vk-strip-wrapper vk-strip-wrapper_top" [ngClass]="'vk-strip-wrapper_' + orientation">
    <div [class]="'vk-label vk-label_' + labelPosition" [ngStyle]="{
      width: !useMin ? labelWidth : null,
      minWidth: useMin ? labelWidth: null,
      maxWidth: orientation === 'vertical' ? null : labelWidth
    }">
      <ng-content select="vk-label"></ng-content>
    </div>
    <div [style.width]="'100%'">
      <div class="vk-strip-wrapper__item vk-strip-wrapper__item_row" [style.width]="'100%'">
        <div [ngStyle]="{width: controlsWidth, display: 'flex', flex: flexible ? '1' : null}">
          <ng-content select="[vkInput]"></ng-content>
        </div>
        <div class="vk-input-options">
          <ng-content select="vk-input-options"></ng-content>
        </div>
      </div>
      <div class="vk-hints">
        <ng-content select="vk-hint"></ng-content>
      </div>
      <div class="vk-errors" *ngIf="IsDirty">
        <ng-container *ngFor="let error of errorsMessages">
          <div class="vk-error" [innerHTML]="error.content" *ngIf="IsInvalid(error.inputName, error.validator)"
               [style.width]="controlsWidth"></div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
