import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'ngr-loading-screen',
  templateUrl: './loading-screen.component.html',
  styleUrls: ['./loading-screen.component.scss']
})
export class LoadingScreenComponent implements OnInit {

  @Input() public message: string = '';

  constructor() {
  }

  ngOnInit() {
  }

}
