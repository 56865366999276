import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SignInFormComponent} from './components/forms/sign-in-form/sign-in-form.component';
import {SignUpFormComponent} from './components/forms/sign-up-form/sign-up-form.component';
import {SignInComponent} from './routes/sign-in/sign-in.component';
import {SignUpComponent} from './routes/sign-up/sign-up.component';
import {AuthorizationRoutingModule} from './authorization-routing.module';
import {AuthorizationLayoutComponent} from './routes/authorization-layout/authorization-layout.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {EqualValidatorDirective} from './validators/equal-validator.directive';
import {ForgotPasswordComponent} from './routes/forgot-password/forgot-password.component';
import {ForgotPasswordFormComponent} from './components/forms/forgot-password-form/forgot-password-form.component';
import {ChangePasswordComponent} from './components/forms/change-password/change-password.component';
import {ClarityModule} from '@clr/angular';
import {ExternalLoginFormComponent} from './components/forms/external-login-form/external-login-form.component';
import {SharedModule} from '../shared/shared.module';
import {CounterRegisterGoalProxyComponent} from './routes/counter-register-goal-proxy/counter-register-goal-proxy.component';
import {MatCheckboxModule} from '@angular/material';

@NgModule({
  imports: [
    CommonModule,
    AuthorizationRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    ClarityModule,
    SharedModule,
    ReactiveFormsModule,
    MatCheckboxModule
  ],
  declarations: [SignInFormComponent, SignUpFormComponent, SignInComponent, SignUpComponent, AuthorizationLayoutComponent, EqualValidatorDirective, ForgotPasswordComponent, ForgotPasswordFormComponent, ChangePasswordComponent, ExternalLoginFormComponent, CounterRegisterGoalProxyComponent]
})
export class AuthorizationModule {
}
