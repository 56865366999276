import {Component, OnInit} from '@angular/core';
import {AccountsService} from '../../../api/services/accounts.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AccountToken} from '../../../api/models/account-token';
import {RegistrationSuccessed} from '../../../api/models/registration-successed';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {

  constructor(
    private accountService: AccountsService,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit() {
  }

  handleSubmit(data) {
    console.log(this.route.snapshot, 'SNAPSHOT');
    if (this.route.snapshot.queryParams['affiliate_member_code']) {
      data['affiliate_member_code'] = this.route.snapshot.queryParams['affiliate_member_code'];
    }
    this.accountService.signUp(data)
      .subscribe((data1: RegistrationSuccessed) => {
      });
  }

}
