import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {PlatformsTypes} from "../../../../platforms-types";
import {FormBuilder, FormGroup} from "@angular/forms";
import {CrmPipelinesService} from "../../../../stores/crm-pipelines/crm-pipelines.service";
import {PlatformAssignationsQuery} from "../../../../stores/platform-assignations/platform-assignations.query";
import {CrmPipelinesQuery} from "../../../../stores/crm-pipelines/crm-pipelines.query";
import {BehaviorSubject} from "rxjs";
import {distinctUntilChanged, flatMap} from "rxjs/operators";
import {AkitaNgFormsManager} from "@datorama/akita-ng-forms-manager";
import {environment} from "../../../../../../environments/environment";
import {PlatformsService} from "../../../../stores/platforms/platforms.service";
import {AssignationPlatforms} from "../../../../stores/platform-assignations/platform-assignation.model";

@Component({
    selector: 'app-new-export-crm',
    templateUrl: './new-export-crm.component.html',
    styleUrls: ['./new-export-crm.component.scss']
})
export class NewExportCrmComponent implements OnInit, OnChanges {

    @Input() public type: PlatformsTypes;

    public form: FormGroup;
    private type$ = new BehaviorSubject(this.type);
    private section$ = new BehaviorSubject('CONTACTS');
    private countOfPipelines: number;

    // public get pipelineLabel() {
    //     if (this.countOfPipelines > 1) {
    //         return 'Выбрать воронку, из которой выгружать контакты';
    //     } else {
    //         return 'Передавать данные контактов из воронки продаж';
    //     }
    // }
    //
    public get pipelineLabelSuggestionCode() {
        if (this.countOfPipelines > 1) {
            return 'AMO_BY_PIPELINES';
        } else {
            return 'BY_PIPELINES';
        }
    }
    //
    // public get LeadValue() {
    //     switch (this.type) {
    //         case PlatformsTypes.AMO_CRM_EXPORT:
    //         default:
    //             return 'LEADS';
    //         case PlatformsTypes.MEGAPLAN_EXPORT:
    //             return 'DEALS';
    //         case PlatformsTypes.UON_EXPORT:
    //             const sectionCode = this.pipelinesQuery.pipelineSectionCodeById(this.form.get('selectedPipelineId').value);
    //             return sectionCode === 'CONTACTS' ? 'DEALS' : sectionCode;
    //     }
    // }
    
    public pipelines$ = this.section$
        .pipe(
            flatMap(section => this.pipelinesQuery.pipelinesBySection$(section))
        );

    constructor(
        private fb: FormBuilder,
        private pipelinesService: CrmPipelinesService,
        private pipelinesQuery: CrmPipelinesQuery,
        private assignationsQuery: PlatformAssignationsQuery,
        private fm: AkitaNgFormsManager,
        private platformsService: PlatformsService
    ) {
    }

    ngOnInit() {
        console.log(this.type);
        this.form = this.fb.group({
            section: this.fb.control(''),
            isClassicMode: this.fb.control(true),
            selectedPipelineId: this.fb.control(null)
        });
        
        this.form
            .get('section')
            .valueChanges
            .subscribe((section) => {
                this.selectSection(section)
            });
        
        this.form
            .get('selectedPipelineId')
            .valueChanges
            .subscribe(pipelineId => {
                this.selectPipeline(pipelineId);
            })
        
        this.fm.upsert(environment.integrations.originFormKey, this.form);
        
        this.form.get('section').patchValue('CONTACTS')

        this.type$
            .pipe(
                flatMap(type => this.assignationsQuery.isPlatformAssigned$(type)),
                distinctUntilChanged()
            )
            .subscribe((isAssigned: boolean) => {
                console.log('isAssigned');
                if (isAssigned) {
                    this.AssignationCheckingSuccessed();
                } else {
                    this.AssignationCheckingFailure();
                }
            })
        
        this.pipelines$.subscribe(pipelines => {
            this.countOfPipelines = pipelines.length;
        })
    }

    private selectPipeline(pipelineId) {
        this.form.patchValue({selectedPipelineId: pipelineId}, {emitEvent: false});
    }

    private selectSection(section) {
        const pipeline = this.pipelinesQuery.GetPipelineBySectionCode(section);
        if (pipeline) {
            this.selectPipeline(pipeline.id);
        } else {
            this.selectPipeline(null);
        }

        if (this.type === PlatformsTypes.UON_EXPORT) {
            this.platformsService.SetParameters(AssignationPlatforms.UON, {hasLeads: section !== 'UON_MARKS_EXPORT'});
            this.form.patchValue({isClassicMode: !(section === 'UON_MARKS_EXPORT' || section === 'CONTACTS')})
        }
        
        this.form.patchValue({section}, {emitEvent: false});
    }
    

    /**
     * Load pipelines required for work
     * @constructor
     */
    private LoadPipelines() {
        this.pipelinesService.LoadPipelines(this.type)
        setTimeout(() => {
            this.selectSection('CONTACTS')
        }, 5)
    }

    private AssignationCheckingSuccessed() {
        console.log('Platform is assigned');
        this.LoadPipelines();
    }

    private AssignationCheckingFailure() {
        console.log('Platform assignation is failured')
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.type) {
            this.type$.next(changes.type.currentValue);
        }
    }
}
