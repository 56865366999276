import { EventEmitter, OnInit } from '@angular/core';
var TeazerComponent = /** @class */ (function () {
    function TeazerComponent() {
        this.defaultTitle = 'Заголовок объявления';
        this.ImageRemoved = new EventEmitter();
    }
    Object.defineProperty(TeazerComponent.prototype, "TeazerImage", {
        get: function () {
            return (this.image !== '') ? this.image : 'assets/images/ads_size_m.png';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TeazerComponent.prototype, "TeazerTitle", {
        get: function () {
            return (this.teazer.title && this.title !== '') ? this.title : this.defaultTitle;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TeazerComponent.prototype, "TeazerLink", {
        get: function () {
            return (this.teazer.link && this.teazer.link !== '') ? this.teazer.link : 'Ссылка на сайт';
        },
        enumerable: true,
        configurable: true
    });
    TeazerComponent.prototype.ngOnInit = function () {
    };
    TeazerComponent.prototype.removeImage = function () {
        this.ImageRemoved.emit(this.image);
        this.teazer.image = '';
        this.image = '';
    };
    return TeazerComponent;
}());
export { TeazerComponent };
