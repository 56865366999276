<ng-container *ngIf="!isLoading; else loading">
  <!--<h4>Выберите способ указания ставки</h4>-->
  <form [formGroup]="controlContainer.control">
    <div class="vk-container">
      <label for="" class="vk-label">Как устанавливаем цену за показы объявления:</label>

      <mat-radio-group formControlName="type">
        <div class="vk-container">
          <mat-radio-button color="primary" value="auto">
            Автоматическая установка стоимости 1000 показов объявления
          </mat-radio-button>
          <app-generic-tooltip [text]="GetTipAutomatic()"></app-generic-tooltip>
        </div>

        <div class="vk-container">
          <mat-radio-button color="primary" value="custom">
            Ручная установка стоимости 1000 показов объявления
          </mat-radio-button>
          <app-generic-tooltip [text]="GetTipManual()"></app-generic-tooltip>
        </div>
      </mat-radio-group>
    </div>

    <!--<div class="vk-container">-->
      <!---->
    <!--</div>-->

    <ng-container *ngIf="controlContainer.control.value.type === 'custom'">
      <div class="vk-container">
        <div class="tip-field">
          Стоимость 1000 показов объявления устанавливается вручную и <strong>не регулируется автоматически</strong>.
        </div>
      </div>
      <div class="vk-container">
        <label for="define-cpm" class="vk-label">Введите стоимость (руб.) 1000 показов объявления:</label>
        <input id="define-cpm" class="vk-input" type="number" formControlName="cpm" placeholder="Значение в рублях"/>

        <ng-container *ngIf="errors?.rate">
          <mat-error *ngFor="let error of errors.rate">{{error}}</mat-error>
        </ng-container>
      </div>
    </ng-container>
    <div [ngStyle]="{
        display: (controlContainer.control.value.type === 'auto') ? 'block' : 'none'
      }">
      <div class="vk-container">
        <div class="tip-field">
          Стоимость 1000 показов объявления будет устанавливаться <strong>автоматически</strong> таким образом, чтобы <strong>снизить расход рекламного бюджета</strong>.
        </div>
      </div>
      <app-strategies-manager
        [bidRange]="currentRate"
        [strategies]="strategies"
        [currentStrategy]="currentStrategy"
        (OnSave)="OnSave($event)"
        (Inited)="OnInitForm($event)"
        #strategiesManager
      ></app-strategies-manager>
    </div>


    <!--<mat-radio-group formControlName="type">-->
      <!--<mat-radio-button color="primary" value="auto">Автоматически</mat-radio-button>-->
      <!--<br>-->
      <!--<mat-radio-button color="primary" value="custom">В ручную</mat-radio-button>-->
    <!--</mat-radio-group>-->
    <!--<br>-->
    <!--<ng-container *ngIf="controlContainer.control.value.type === 'custom'">-->
      <!--<mat-form-field>-->
        <!--<mat-label>Введите ставку</mat-label>-->
        <!--<input matInput id="rate" type="number" formControlName="cpm"/>-->
      <!--</mat-form-field>-->
      <!--<ng-container *ngIf="errors?.rate">-->
        <!--<mat-error *ngFor="let error of errors.rate">{{error}}</mat-error>-->
      <!--</ng-container>-->
    <!--</ng-container>-->
    <!--<ng-container *ngIf="controlContainer.control.value.type === 'auto'">-->
      <!--<app-strategies-manager-->
        <!--[bidRange]="currentRate"-->
        <!--[strategies]="strategies"-->
        <!--(OnSave)="OnSave($event)"-->
        <!--(Inited)="OnInitForm($event)"-->
      <!--&gt;</app-strategies-manager>-->
    <!--</ng-container>-->
  </form>
</ng-container>
<ng-template #loading></ng-template>


