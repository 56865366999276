import { AutomationYandexService } from '../../api/services/automation-yandex.service';
import { UtmMark } from '../models/utm-mark';
var YandexMetrikaWrapperService = /** @class */ (function () {
    function YandexMetrikaWrapperService(yandex) {
        this.yandex = yandex;
        /***
         * Indicates if yandex metrika is avaliable (connected to user profile)
         * @type {boolean}
         */
        this.isAvailable = true;
    }
    YandexMetrikaWrapperService.prototype.IsAvaliable = function () {
        return this.isAvailable;
    };
    YandexMetrikaWrapperService.prototype.LoadCounters = function () {
        var _this = this;
        this.yandex.GetCounters()
            .subscribe(function (x) {
            _this.Counters = x.counters;
            _this.isAvailable = true;
        }, function (err) {
            _this.isAvailable = false;
        });
    };
    YandexMetrikaWrapperService.prototype.LoadGoals = function (counterId) {
        var _this = this;
        this.yandex.GetGoals(counterId)
            .subscribe(function (x) {
            _this.Goals = x.goals;
        });
    };
    /**
     * Load all goal reaches by counter id ang goal id
     * @constructor
     * @param params
     */
    YandexMetrikaWrapperService.prototype.LoadGoalReach = function (params) {
        var _this = this;
        return new Promise(function (resolve) {
            _this.yandex.GetGoalsReach(params)
                .subscribe(function (x) {
                _this.GoalReach = x;
                resolve(_this.GoalReach);
            });
        });
    };
    YandexMetrikaWrapperService.prototype.GetMetrikaValues = function (utms) {
        if (!utms) {
            return null;
        }
        if (!this.GoalReach || !this.GoalReach.data) {
            return null;
        }
        utms = utms.sort(function (a, b) { return a.priority - b.priority; });
        var filterResults = null;
        // Normalize UTM set (we assume that metrika always contains dimensions length of 5)
        var metrikaDimensionSize = 5; // TODO Detect biggest size of dimensions
        var normalizedUtmSet = [];
        var _loop_1 = function (i) {
            var priorityUtm = utms.find(function (x) { return x.priority === i; });
            var utm = new UtmMark(null, null);
            if (priorityUtm) {
                utm = priorityUtm;
            }
            else {
                utm.priority = i;
                utm.type = utm.GetTypeAccoringToPriority(utm.priority);
            }
            normalizedUtmSet.push(utm);
        };
        for (var i = 1; i <= metrikaDimensionSize; i = i + 1) {
            _loop_1(i);
        }
        // Filter off utm sets with less dimensions
        var correctLengthGoalReach = this.GoalReach.data.filter(function (x) { return x.dimensions.length === normalizedUtmSet.length; });
        normalizedUtmSet.forEach(function (utm, index) {
            if (!filterResults) {
                filterResults = correctLengthGoalReach
                    .filter(function (grd) { return grd.dimensions[index].name === utm.value; });
            }
            else {
                var tmp = filterResults
                    .filter(function (fr) { return fr.dimensions[index].name === utm.value; });
                filterResults = tmp;
            }
        });
        return filterResults;
    };
    return YandexMetrikaWrapperService;
}());
export { YandexMetrikaWrapperService };
