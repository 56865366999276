import { AkitaNgFormsManager } from '@datorama/akita-ng-forms-manager';
import { environment } from '../../../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@datorama/akita-ng-forms-manager";
var MoiSkladExportService = /** @class */ (function () {
    function MoiSkladExportService(fm) {
        this.fm = fm;
    }
    MoiSkladExportService.prototype.valid = function () {
        var form = this.fm.getForm(environment.integrations.originFormKey);
        console.log(form, 'EXPORT MOI SKLAD');
        return form.valid;
    };
    MoiSkladExportService.ngInjectableDef = i0.defineInjectable({ factory: function MoiSkladExportService_Factory() { return new MoiSkladExportService(i0.inject(i1.AkitaNgFormsManager)); }, token: MoiSkladExportService, providedIn: "root" });
    return MoiSkladExportService;
}());
export { MoiSkladExportService };
