var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { map as __map, filter as __filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../api-configuration";
import * as i2 from "@angular/common/http";
var OAuthService = /** @class */ (function (_super) {
    __extends(OAuthService, _super);
    function OAuthService(config, http) {
        return _super.call(this, config, http) || this;
    }
    /**
     * @param params The `OAuthService.Apiv2OauthAuthorizeByProviderGetParams` containing the following parameters:
     *
     * - `provider`:
     *
     * - `domain`:
     *
     * @return Success
     */
    OAuthService.prototype.Apiv2OauthAuthorizeByProviderGetResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.domain != null)
            __params = __params.set('domain', params.domain.toString());
        var req = new HttpRequest('GET', this.rootUrl + ("/apiv2/oauth/authorize/" + encodeURIComponent(params.provider)), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `OAuthService.Apiv2OauthAuthorizeByProviderGetParams` containing the following parameters:
     *
     * - `provider`:
     *
     * - `domain`:
     *
     * @return Success
     */
    OAuthService.prototype.Apiv2OauthAuthorizeByProviderGet = function (params) {
        return this.Apiv2OauthAuthorizeByProviderGetResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param provider undefined
     */
    OAuthService.prototype.Apiv2OauthAuthorizeByProviderHeadResponse = function (provider) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('HEAD', this.rootUrl + ("/apiv2/oauth/authorize/" + encodeURIComponent(provider)), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param provider undefined
     */
    OAuthService.prototype.Apiv2OauthAuthorizeByProviderHead = function (provider) {
        return this.Apiv2OauthAuthorizeByProviderHeadResponse(provider).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `OAuthService.Apiv2OauthCodeByProviderGetParams` containing the following parameters:
     *
     * - `provider`:
     *
     * - `state`:
     *
     * - `referer`:
     *
     * - `code`:
     */
    OAuthService.prototype.Apiv2OauthCodeByProviderGetResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.state != null)
            __params = __params.set('state', params.state.toString());
        if (params.referer != null)
            __params = __params.set('referer', params.referer.toString());
        if (params.code != null)
            __params = __params.set('code', params.code.toString());
        var req = new HttpRequest('GET', this.rootUrl + ("/apiv2/oauth/code/" + encodeURIComponent(params.provider)), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `OAuthService.Apiv2OauthCodeByProviderGetParams` containing the following parameters:
     *
     * - `provider`:
     *
     * - `state`:
     *
     * - `referer`:
     *
     * - `code`:
     */
    OAuthService.prototype.Apiv2OauthCodeByProviderGet = function (params) {
        return this.Apiv2OauthCodeByProviderGetResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param provider undefined
     */
    OAuthService.prototype.Apiv2OauthCodeByProviderHeadResponse = function (provider) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('HEAD', this.rootUrl + ("/apiv2/oauth/code/" + encodeURIComponent(provider)), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param provider undefined
     */
    OAuthService.prototype.Apiv2OauthCodeByProviderHead = function (provider) {
        return this.Apiv2OauthCodeByProviderHeadResponse(provider).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param provider undefined
     * @return Success
     */
    OAuthService.prototype.CheckProviderTokenResponse = function (provider) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('POST', this.rootUrl + ("/apiv2/oauth/check/" + encodeURIComponent(provider)), __body, {
            headers: __headers,
            params: __params,
            responseType: 'text'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r.clone({ body: _r.body === 'true' });
        }));
    };
    /**
     * @param provider undefined
     * @return Success
     */
    OAuthService.prototype.CheckProviderToken = function (provider) {
        return this.CheckProviderTokenResponse(provider).pipe(__map(function (_r) { return _r.body; }));
    };
    OAuthService.Apiv2OauthAuthorizeByProviderGetPath = '/apiv2/oauth/authorize/{provider}';
    OAuthService.Apiv2OauthAuthorizeByProviderHeadPath = '/apiv2/oauth/authorize/{provider}';
    OAuthService.Apiv2OauthCodeByProviderGetPath = '/apiv2/oauth/code/{provider}';
    OAuthService.Apiv2OauthCodeByProviderHeadPath = '/apiv2/oauth/code/{provider}';
    OAuthService.CheckProviderTokenPath = '/apiv2/oauth/check/{provider}';
    OAuthService.ngInjectableDef = i0.defineInjectable({ factory: function OAuthService_Factory() { return new OAuthService(i0.inject(i1.ApiConfiguration), i0.inject(i2.HttpClient)); }, token: OAuthService, providedIn: "root" });
    return OAuthService;
}(__BaseService));
export { OAuthService };
