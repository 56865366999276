import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {ControlContainer} from '@angular/forms';
import {PostificatePipe} from '../../../../../../shared/pipes/postificate.pipe';

@Component({
  selector: 'app-promopost-form-lite',
  templateUrl: './promopost-form-lite.component.html',
  styleUrls: ['./promopost-form-lite.component.scss']
})
export class PromopostFormLiteComponent implements OnInit, AfterViewInit {

  public labelWidth = '140px';
  public controlsWidth = '256px';

  @Input() public groups: Array<any>;
  @Input() public adTargetResource: string;
  @Input() public isUpdating: boolean;

  public get TextAreaDefaultHeight(): string {
    return (this.ShowLinkUrl) ? '324px' : '368px';
  }

  public get ShowLinkUrl() {
    return this.adTargetResource === 'web_site';
  }

  constructor(
    public controlContainer: ControlContainer
  ) {
  }

  public get selectedGroup() {
    return (this.groups) ? this.groups.find(group => group.id === parseInt(this.controlContainer.control.get('groupId').value, 10)) : null;
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
  }

  OnSaveLinkTitle(linkTitle: string) {
    this.controlContainer.control.get('linkTitle')
      .setValue(linkTitle);
  }

  public OnSelectImage(image: string) {
    this.controlContainer.control.get('image')
      .setValue(image);
  }

  StartImageErrorTimer() {
    setTimeout(() => {
      const imageControl = this.controlContainer.control.get('image');
      imageControl.markAsDirty();
    }, 10000);
  }

  public CountOfOpenedGroups() {
    let countOfOpenedGroups = 0;
    if (this.groups && this.groups.length > 0) {
      const openedGroups = this.groups.filter(group => group.is_closed === 0);
      countOfOpenedGroups = openedGroups.length;
    }

    return countOfOpenedGroups;
  }

  getEmojjiContent() {
    return new PostificatePipe().transform('Внимание! Рекламная запись должна содержать не более 6 эмодзи. Например: 🔥😏📅💃💪🏻🏻👁😱😈 или другие.');
  }
}
