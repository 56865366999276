import {Component, Input, OnInit} from '@angular/core';
import {PostMonitorBackend_V2ModelsPaymentsTariffPackage} from "../../../../../../src/app/api/models/post-monitor-backend-_v2models-payments-tariff-package";
import {PaymentsService} from "../../services/payments.service";

@Component({
  selector: 'app-payments-tariff-header',
  templateUrl: './payments-tariff-header.component.html',
  styleUrls: ['./payments-tariff-header.component.scss']
})
export class PaymentsTariffHeaderComponent implements OnInit {

  @Input() public tariff: PostMonitorBackend_V2ModelsPaymentsTariffPackage;

  public get price() {
    const price = this.paymentsService.GetPriceByTariffPlan(this.tariff.id);
    console.log(price, 'PRICE');
    if (price) {
      return price.price
    } else {
      return 0
    }
  }

  public get customPrice() {
    const price = this.paymentsService.GetPriceByTariffPlan(this.tariff.id);
    if (price) {
      return price['customPrice'];
    } else {
      return null;
    }
  }

  constructor(
    private paymentsService: PaymentsService
  ) {
  }

  ngOnInit() {
  }

}
