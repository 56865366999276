import { Injectable } from '@angular/core';
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {map} from "rxjs/operators";
import {TariffGroupModel} from "../../models/tariff-group.model";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class TariffGroupsService {

  private baseUrl: string = `${environment.backendUri}/${environment.apiPrefix}/tariff-packages/stuff`;

  constructor(
    private http: HttpClient
  ) { }

  /***
   * Get tariff groups from server
   * @constructor
   */
  public LoadTariffGroups(): Observable<TariffGroupModel[]> {
    return this.http.get<TariffGroupModel[]>(this.baseUrl);
  }
}
