import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AffiliateUserInfoStore} from './affiliate-user-info.store';
import {map, tap} from 'rxjs/operators';
import {environment} from "../../../environments/environment";

@Injectable({providedIn: 'root'})
export class AffiliateUserInfoService {

  private baseUrl = `${environment.backendUri}/${environment.apiPrefix}/affiliate-user-infos`;

  constructor(private affiliateUserInfoStore: AffiliateUserInfoStore,
              private http: HttpClient) {
  }

  public LoadUserDataAsync() {
    return this.http.get(`${this.baseUrl}/current`)
      .pipe(
        map(response => response['data']),
        tap(affiliateUserInfo => this.affiliateUserInfoStore.update(affiliateUserInfo))
      )
  }

  public LoadUserData() {
    this.LoadUserDataAsync()
      .subscribe();
  }

  public CreateAffiliateUserAsync(affiliateUserInfo) {
    return this.http.post(`${this.baseUrl}`, affiliateUserInfo)
      .pipe(
        tap((data) => this.LoadUserData())
      );
  }

  public UpdateAffiliateUserAsync(affiliateUserInfo) {
    return this.http.put(this.baseUrl, affiliateUserInfo)
      .pipe(
        tap(() => this.LoadUserData())
      )
  }

}
