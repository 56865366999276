/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./banner-item.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./banner-item.component";
import * as i3 from "@angular/platform-browser";
import * as i4 from "../../services/banners.service";
var styles_BannerItemComponent = [i0.styles];
var RenderType_BannerItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BannerItemComponent, data: {} });
export { RenderType_BannerItemComponent as RenderType_BannerItemComponent };
export function View_BannerItemComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "banner-item"]], [[8, "innerHTML", 1], [4, "width", null], [4, "height", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.RedirecToToBannerTargetUrl() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.bannerCode; var currVal_1 = _co.bannerWidth; var currVal_2 = _co.bannerHeight; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_BannerItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-banner-item", [], null, [[null, "mousedown"]], function (_v, en, $event) { var ad = true; if (("mousedown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).OnBannerClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_BannerItemComponent_0, RenderType_BannerItemComponent)), i1.ɵdid(1, 114688, null, 0, i2.BannerItemComponent, [i3.DomSanitizer, i4.BannersService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BannerItemComponentNgFactory = i1.ɵccf("app-banner-item", i2.BannerItemComponent, View_BannerItemComponent_Host_0, { banner: "banner" }, {}, []);
export { BannerItemComponentNgFactory as BannerItemComponentNgFactory };
