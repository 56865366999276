import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AffiliateProgrammRoutingModule } from './affiliate-programm-routing.module';
import { AffiliateProgrammAnnouncementComponent } from './routes/affiliate-programm-announcement/affiliate-programm-announcement.component';
import { AffiliateProgramLayoutComponent } from './components/affiliate-program-layout/affiliate-program-layout.component';
import {MatTabsModule} from "@angular/material/tabs";
import {MatCardModule} from "@angular/material/card";
import {AffiliateProgramStatisticsModule} from "./affiliate-program-statistics/affiliate-program-statistics.module";
import {AffiliateProgramSettingsModule} from "./affiliate-program-settings/affiliate-program-settings.module";
import { AffiliateNavigationComponent } from './components/affiliate-navigation/affiliate-navigation.component';
import {AffiliateProgramSettingsRoutingModule} from "./affiliate-program-settings/affiliate-program-settings-routing.module";

@NgModule({
    declarations: [AffiliateProgrammAnnouncementComponent, AffiliateProgramLayoutComponent, AffiliateNavigationComponent],
    exports: [
    ],
    imports: [
        CommonModule,
        AffiliateProgrammRoutingModule,
        MatTabsModule,
        MatCardModule,
        AffiliateProgramStatisticsModule,
        AffiliateProgramSettingsModule,
        AffiliateProgramSettingsRoutingModule
    ]
})
export class AffiliateProgrammModule { }
