/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PostMonitorBackend_V2ModelsvkVKAPIGetCountriesResponse } from '../models/post-monitor-backend-_v2modelsvk-vkapiget-countries-response';
import { PostMonitorBackend_V2ModelsvkVKAPIGetCitiesResponse } from '../models/post-monitor-backend-_v2modelsvk-vkapiget-cities-response';
import { PostMonitorBackend_V2ModelsvkVKAPIGroupModel } from '../models/post-monitor-backend-_v2modelsvk-vkapigroup-model';
import { PostMonitorBackend_V2ModelsvkVKAPIAdTargetingStats } from '../models/post-monitor-backend-_v2modelsvk-vkapiad-targeting-stats';
import { PostMonitorBackend_V2ModelsAdManagerTargetingStatsViewModel } from '../models/post-monitor-backend-_v2models-ad-manager-targeting-stats-view-model';
import { PostMonitorBackend_V2ModelsvkVKAPIUpdateAds } from '../models/post-monitor-backend-_v2modelsvk-vkapiupdate-ads';
import { PostMonitorBackend_V2ModelsAdManagerUpdateAdViewModel } from '../models/post-monitor-backend-_v2models-ad-manager-update-ad-view-model';
import { PostMonitorBackend_V2ModelsAdManagerCreateAdViewModel } from '../models/post-monitor-backend-_v2models-ad-manager-create-ad-view-model';
import { PostMonitorBackend_V2ModelsCreateCampaignViewModel } from '../models/post-monitor-backend-_v2models-create-campaign-view-model';
import { BidRange } from '../models/bid-range';
import { PostMonitorBackend_V2ModelsautomationEntityAdResource } from '../models/post-monitor-backend-_v2modelsautomation-entity-ad-resource';
import { PostMonitorBackend_V2ModelsvkVKAPICategoryModel } from '../models/post-monitor-backend-_v2modelsvk-vkapicategory-model';
import { PostMonitorBackend_V2ModelsAdManagerMultipleEditAdsViewModel } from '../models/post-monitor-backend-_v2models-ad-manager-multiple-edit-ads-view-model';
import { UserStrategy } from '../models/user-strategy';
import { Ad } from '../models/ad';
import { PostMonitorBackend_V2ModelsAdManagerCopyAdsViewModel } from '../models/post-monitor-backend-_v2models-ad-manager-copy-ads-view-model';
import { PostMonitorBackend_V2ModelsvkVKAPIAdTargetGroup } from '../models/post-monitor-backend-_v2modelsvk-vkapiad-target-group';
import { PostMonitorBackend_V2ModelsAdManagerEntitiesInterestCategory } from '../models/post-monitor-backend-_v2models-ad-manager-entities-interest-category';
@Injectable({
  providedIn: 'root',
})
class AdManagerService extends __BaseService {
  static readonly GetCountriesPath = '/apiv2/ad-manager/countries';
  static readonly GetCitiesPath = '/apiv2/ad-manager/cities';
  static readonly SearchGroupsPath = '/apiv2/ad-manager/groups';
  static readonly GetGroupsByIdPath = '/apiv2/ad-manager/groups';
  static readonly GetTargetingStatsPath = '/apiv2/ad-manager/targeting';
  static readonly GetAdRejectionPath = '/apiv2/ad-manager/rejection';
  static readonly UpdateAdPath = '/apiv2/ad-manager/ad';
  static readonly CreateAdPath = '/apiv2/ad-manager/ad';
  static readonly GetAdminGroupsPath = '/apiv2/ad-manager/admin-groups';
  static readonly GetGroupAlbumsPath = '/apiv2/ad-manager/albums';
  static readonly CreateCampaignPath = '/apiv2/ad-manager/campaign';
  static readonly GetAdRatesPath = '/apiv2/ad-manager/rates';
  static readonly CabBeEditablePath = '/apiv2/ad-manager/can-be-editable';
  static readonly GetImagePath = '/apiv2/ad-manager/get-image';
  static readonly GetCategoriesPath = '/apiv2/ad-manager/get-categories';
  static readonly MultipleUpdateAdsPath = '/apiv2/ad-manager/multiple-update-ads';
  static readonly GetUserStrategyInputKeysPath = '/apiv2/ad-manager/strategy-inputs-keys';
  static readonly UpdateStrategiesPath = '/apiv2/ad-manager/update-strategies';
  static readonly CopyAdsPath = '/apiv2/ad-manager/copy-ads';
  static readonly GetTargetingGroupPath = '/apiv2/ad-manager/get-targeting-groups';
  static readonly GetInterestCategoriesPath = '/apiv2/ad-manager/get-interest-categories';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return Success
   */
  GetCountriesResponse(): __Observable<__StrictHttpResponse<PostMonitorBackend_V2ModelsvkVKAPIGetCountriesResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/ad-manager/countries`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PostMonitorBackend_V2ModelsvkVKAPIGetCountriesResponse>;
      })
    );
  }
  /**
   * @return Success
   */
  GetCountries(): __Observable<PostMonitorBackend_V2ModelsvkVKAPIGetCountriesResponse> {
    return this.GetCountriesResponse().pipe(
      __map(_r => _r.body as PostMonitorBackend_V2ModelsvkVKAPIGetCountriesResponse)
    );
  }

  /**
   * @param params The `AdManagerService.GetCitiesParams` containing the following parameters:
   *
   * - `countryId`:
   *
   * - `searchString`:
   *
   * @return Success
   */
  GetCitiesResponse(params: AdManagerService.GetCitiesParams): __Observable<__StrictHttpResponse<Array<PostMonitorBackend_V2ModelsvkVKAPIGetCitiesResponse>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.countryId != null) __params = __params.set('countryId', params.countryId.toString());
    if (params.searchString != null) __params = __params.set('searchString', params.searchString.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/ad-manager/cities`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PostMonitorBackend_V2ModelsvkVKAPIGetCitiesResponse>>;
      })
    );
  }
  /**
   * @param params The `AdManagerService.GetCitiesParams` containing the following parameters:
   *
   * - `countryId`:
   *
   * - `searchString`:
   *
   * @return Success
   */
  GetCities(params: AdManagerService.GetCitiesParams): __Observable<Array<PostMonitorBackend_V2ModelsvkVKAPIGetCitiesResponse>> {
    return this.GetCitiesResponse(params).pipe(
      __map(_r => _r.body as Array<PostMonitorBackend_V2ModelsvkVKAPIGetCitiesResponse>)
    );
  }

  /**
   * @param params The `AdManagerService.SearchGroupsParams` containing the following parameters:
   *
   * - `countryId`:
   *
   * - `cityId`:
   *
   * - `title`:
   *
   * @return Success
   */
  SearchGroupsResponse(params: AdManagerService.SearchGroupsParams): __Observable<__StrictHttpResponse<Array<PostMonitorBackend_V2ModelsvkVKAPIGroupModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.countryId != null) __params = __params.set('countryId', params.countryId.toString());
    if (params.cityId != null) __params = __params.set('cityId', params.cityId.toString());
    if (params.title != null) __params = __params.set('title', params.title.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/ad-manager/groups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PostMonitorBackend_V2ModelsvkVKAPIGroupModel>>;
      })
    );
  }
  /**
   * @param params The `AdManagerService.SearchGroupsParams` containing the following parameters:
   *
   * - `countryId`:
   *
   * - `cityId`:
   *
   * - `title`:
   *
   * @return Success
   */
  SearchGroups(params: AdManagerService.SearchGroupsParams): __Observable<Array<PostMonitorBackend_V2ModelsvkVKAPIGroupModel>> {
    return this.SearchGroupsResponse(params).pipe(
      __map(_r => _r.body as Array<PostMonitorBackend_V2ModelsvkVKAPIGroupModel>)
    );
  }

  /**
   * @param groupIds undefined
   * @return Success
   */
  GetGroupsByIdResponse(groupIds?: Array<number>): __Observable<__StrictHttpResponse<Array<PostMonitorBackend_V2ModelsvkVKAPIGroupModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (groupIds || []).forEach(val => {if (val != null) __params = __params.append('groupIds', val.toString())});
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/apiv2/ad-manager/groups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PostMonitorBackend_V2ModelsvkVKAPIGroupModel>>;
      })
    );
  }
  /**
   * @param groupIds undefined
   * @return Success
   */
  GetGroupsById(groupIds?: Array<number>): __Observable<Array<PostMonitorBackend_V2ModelsvkVKAPIGroupModel>> {
    return this.GetGroupsByIdResponse(groupIds).pipe(
      __map(_r => _r.body as Array<PostMonitorBackend_V2ModelsvkVKAPIGroupModel>)
    );
  }

  /**
   * @param targetingData undefined
   * @return Success
   */
  GetTargetingStatsResponse(targetingData?: PostMonitorBackend_V2ModelsAdManagerTargetingStatsViewModel): __Observable<__StrictHttpResponse<PostMonitorBackend_V2ModelsvkVKAPIAdTargetingStats>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = targetingData;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/apiv2/ad-manager/targeting`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PostMonitorBackend_V2ModelsvkVKAPIAdTargetingStats>;
      })
    );
  }
  /**
   * @param targetingData undefined
   * @return Success
   */
  GetTargetingStats(targetingData?: PostMonitorBackend_V2ModelsAdManagerTargetingStatsViewModel): __Observable<PostMonitorBackend_V2ModelsvkVKAPIAdTargetingStats> {
    return this.GetTargetingStatsResponse(targetingData).pipe(
      __map(_r => _r.body as PostMonitorBackend_V2ModelsvkVKAPIAdTargetingStats)
    );
  }

  /**
   * @param params The `AdManagerService.GetAdRejectionParams` containing the following parameters:
   *
   * - `adId`:
   *
   * - `accountId`:
   */
  GetAdRejectionResponse(params: AdManagerService.GetAdRejectionParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.adId != null) __params = __params.set('adId', params.adId.toString());
    if (params.accountId != null) __params = __params.set('accountId', params.accountId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/ad-manager/rejection`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `AdManagerService.GetAdRejectionParams` containing the following parameters:
   *
   * - `adId`:
   *
   * - `accountId`:
   */
  GetAdRejection(params: AdManagerService.GetAdRejectionParams): __Observable<null> {
    return this.GetAdRejectionResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param ads1 undefined
   * @return Success
   */
  UpdateAdResponse(ads1?: Array<PostMonitorBackend_V2ModelsAdManagerUpdateAdViewModel>): __Observable<__StrictHttpResponse<Array<PostMonitorBackend_V2ModelsvkVKAPIUpdateAds>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = ads1;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/apiv2/ad-manager/ad`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PostMonitorBackend_V2ModelsvkVKAPIUpdateAds>>;
      })
    );
  }
  /**
   * @param ads1 undefined
   * @return Success
   */
  UpdateAd(ads1?: Array<PostMonitorBackend_V2ModelsAdManagerUpdateAdViewModel>): __Observable<Array<PostMonitorBackend_V2ModelsvkVKAPIUpdateAds>> {
    return this.UpdateAdResponse(ads1).pipe(
      __map(_r => _r.body as Array<PostMonitorBackend_V2ModelsvkVKAPIUpdateAds>)
    );
  }

  /**
   * @param ads1 undefined
   * @return Success
   */
  CreateAdResponse(ads1?: Array<PostMonitorBackend_V2ModelsAdManagerCreateAdViewModel>): __Observable<__StrictHttpResponse<PostMonitorBackend_V2ModelsvkVKAPIUpdateAds>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = ads1;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/apiv2/ad-manager/ad`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PostMonitorBackend_V2ModelsvkVKAPIUpdateAds>;
      })
    );
  }
  /**
   * @param ads1 undefined
   * @return Success
   */
  CreateAd(ads1?: Array<PostMonitorBackend_V2ModelsAdManagerCreateAdViewModel>): __Observable<PostMonitorBackend_V2ModelsvkVKAPIUpdateAds> {
    return this.CreateAdResponse(ads1).pipe(
      __map(_r => _r.body as PostMonitorBackend_V2ModelsvkVKAPIUpdateAds)
    );
  }
  GetAdminGroupsResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/ad-manager/admin-groups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }  GetAdminGroups(): __Observable<null> {
    return this.GetAdminGroupsResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param groupId undefined
   */
  GetGroupAlbumsResponse(groupId: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (groupId != null) __params = __params.set('groupId', groupId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/ad-manager/albums`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param groupId undefined
   */
  GetGroupAlbums(groupId: number): __Observable<null> {
    return this.GetGroupAlbumsResponse(groupId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param campaignData undefined
   */
  CreateCampaignResponse(campaignData?: PostMonitorBackend_V2ModelsCreateCampaignViewModel): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = campaignData;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/apiv2/ad-manager/campaign`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param campaignData undefined
   */
  CreateCampaign(campaignData?: PostMonitorBackend_V2ModelsCreateCampaignViewModel): __Observable<null> {
    return this.CreateCampaignResponse(campaignData).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @return Success
   */
  GetAdRatesResponse(): __Observable<__StrictHttpResponse<Array<BidRange>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/ad-manager/rates`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<BidRange>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetAdRates(): __Observable<Array<BidRange>> {
    return this.GetAdRatesResponse().pipe(
      __map(_r => _r.body as Array<BidRange>)
    );
  }

  /**
   * @param params The `AdManagerService.CabBeEditableParams` containing the following parameters:
   *
   * - `clientId`:
   *
   * - `accountId`:
   *
   * - `adIds`:
   *
   * @return Success
   */
  CabBeEditableResponse(params: AdManagerService.CabBeEditableParams): __Observable<__StrictHttpResponse<{[key: string]: boolean}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.clientId != null) __params = __params.set('clientId', params.clientId.toString());
    if (params.accountId != null) __params = __params.set('accountId', params.accountId.toString());
    (params.adIds || []).forEach(val => {if (val != null) __params = __params.append('adIds', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/ad-manager/can-be-editable`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{[key: string]: boolean}>;
      })
    );
  }
  /**
   * @param params The `AdManagerService.CabBeEditableParams` containing the following parameters:
   *
   * - `clientId`:
   *
   * - `accountId`:
   *
   * - `adIds`:
   *
   * @return Success
   */
  CabBeEditable(params: AdManagerService.CabBeEditableParams): __Observable<{[key: string]: boolean}> {
    return this.CabBeEditableResponse(params).pipe(
      __map(_r => _r.body as {[key: string]: boolean})
    );
  }

  /**
   * @param params The `AdManagerService.GetImageParams` containing the following parameters:
   *
   * - `clientId`:
   *
   * - `adId`:
   *
   * - `accountId`:
   *
   * @return Success
   */
  GetImageResponse(params: AdManagerService.GetImageParams): __Observable<__StrictHttpResponse<PostMonitorBackend_V2ModelsautomationEntityAdResource>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.clientId != null) __params = __params.set('clientId', params.clientId.toString());
    if (params.adId != null) __params = __params.set('adId', params.adId.toString());
    if (params.accountId != null) __params = __params.set('accountId', params.accountId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/ad-manager/get-image`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PostMonitorBackend_V2ModelsautomationEntityAdResource>;
      })
    );
  }
  /**
   * @param params The `AdManagerService.GetImageParams` containing the following parameters:
   *
   * - `clientId`:
   *
   * - `adId`:
   *
   * - `accountId`:
   *
   * @return Success
   */
  GetImage(params: AdManagerService.GetImageParams): __Observable<PostMonitorBackend_V2ModelsautomationEntityAdResource> {
    return this.GetImageResponse(params).pipe(
      __map(_r => _r.body as PostMonitorBackend_V2ModelsautomationEntityAdResource)
    );
  }

  /**
   * @return Success
   */
  GetCategoriesResponse(): __Observable<__StrictHttpResponse<Array<PostMonitorBackend_V2ModelsvkVKAPICategoryModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/ad-manager/get-categories`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PostMonitorBackend_V2ModelsvkVKAPICategoryModel>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetCategories(): __Observable<Array<PostMonitorBackend_V2ModelsvkVKAPICategoryModel>> {
    return this.GetCategoriesResponse().pipe(
      __map(_r => _r.body as Array<PostMonitorBackend_V2ModelsvkVKAPICategoryModel>)
    );
  }

  /**
   * @param adsData undefined
   * @return Success
   */
  MultipleUpdateAdsResponse(adsData?: PostMonitorBackend_V2ModelsAdManagerMultipleEditAdsViewModel): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = adsData;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/apiv2/ad-manager/multiple-update-ads`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * @param adsData undefined
   * @return Success
   */
  MultipleUpdateAds(adsData?: PostMonitorBackend_V2ModelsAdManagerMultipleEditAdsViewModel): __Observable<{}> {
    return this.MultipleUpdateAdsResponse(adsData).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * @return Success
   */
  GetUserStrategyInputKeysResponse(): __Observable<__StrictHttpResponse<{[key: string]: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/ad-manager/strategy-inputs-keys`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{[key: string]: string}>;
      })
    );
  }
  /**
   * @return Success
   */
  GetUserStrategyInputKeys(): __Observable<{[key: string]: string}> {
    return this.GetUserStrategyInputKeysResponse().pipe(
      __map(_r => _r.body as {[key: string]: string})
    );
  }

  /**
   * @param adsData undefined
   * @return Success
   */
  UpdateStrategiesResponse(adsData?: PostMonitorBackend_V2ModelsAdManagerMultipleEditAdsViewModel): __Observable<__StrictHttpResponse<Array<UserStrategy>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = adsData;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/apiv2/ad-manager/update-strategies`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<UserStrategy>>;
      })
    );
  }
  /**
   * @param adsData undefined
   * @return Success
   */
  UpdateStrategies(adsData?: PostMonitorBackend_V2ModelsAdManagerMultipleEditAdsViewModel): __Observable<Array<UserStrategy>> {
    return this.UpdateStrategiesResponse(adsData).pipe(
      __map(_r => _r.body as Array<UserStrategy>)
    );
  }

  /**
   * @param copyAdsViewModel undefined
   * @return Success
   */
  CopyAdsResponse(copyAdsViewModel?: PostMonitorBackend_V2ModelsAdManagerCopyAdsViewModel): __Observable<__StrictHttpResponse<Array<Ad>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = copyAdsViewModel;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/apiv2/ad-manager/copy-ads`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Ad>>;
      })
    );
  }
  /**
   * @param copyAdsViewModel undefined
   * @return Success
   */
  CopyAds(copyAdsViewModel?: PostMonitorBackend_V2ModelsAdManagerCopyAdsViewModel): __Observable<Array<Ad>> {
    return this.CopyAdsResponse(copyAdsViewModel).pipe(
      __map(_r => _r.body as Array<Ad>)
    );
  }

  /**
   * @param params The `AdManagerService.GetTargetingGroupParams` containing the following parameters:
   *
   * - `accountId`:
   *
   * - `clientId`:
   *
   * @return Success
   */
  GetTargetingGroupResponse(params: AdManagerService.GetTargetingGroupParams): __Observable<__StrictHttpResponse<Array<PostMonitorBackend_V2ModelsvkVKAPIAdTargetGroup>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.accountId != null) __params = __params.set('accountId', params.accountId.toString());
    if (params.clientId != null) __params = __params.set('clientId', params.clientId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/ad-manager/get-targeting-groups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PostMonitorBackend_V2ModelsvkVKAPIAdTargetGroup>>;
      })
    );
  }
  /**
   * @param params The `AdManagerService.GetTargetingGroupParams` containing the following parameters:
   *
   * - `accountId`:
   *
   * - `clientId`:
   *
   * @return Success
   */
  GetTargetingGroup(params: AdManagerService.GetTargetingGroupParams): __Observable<Array<PostMonitorBackend_V2ModelsvkVKAPIAdTargetGroup>> {
    return this.GetTargetingGroupResponse(params).pipe(
      __map(_r => _r.body as Array<PostMonitorBackend_V2ModelsvkVKAPIAdTargetGroup>)
    );
  }

  /**
   * @return Success
   */
  GetInterestCategoriesResponse(): __Observable<__StrictHttpResponse<Array<PostMonitorBackend_V2ModelsAdManagerEntitiesInterestCategory>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/ad-manager/get-interest-categories`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PostMonitorBackend_V2ModelsAdManagerEntitiesInterestCategory>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetInterestCategories(): __Observable<Array<PostMonitorBackend_V2ModelsAdManagerEntitiesInterestCategory>> {
    return this.GetInterestCategoriesResponse().pipe(
      __map(_r => _r.body as Array<PostMonitorBackend_V2ModelsAdManagerEntitiesInterestCategory>)
    );
  }
}

module AdManagerService {

  /**
   * Parameters for GetCities
   */
  export interface GetCitiesParams {
    countryId: number;
    searchString?: string;
  }

  /**
   * Parameters for SearchGroups
   */
  export interface SearchGroupsParams {
    countryId: number;
    cityId: number;
    title?: string;
  }

  /**
   * Parameters for GetAdRejection
   */
  export interface GetAdRejectionParams {
    adId: number;
    accountId: number;
  }

  /**
   * Parameters for CabBeEditable
   */
  export interface CabBeEditableParams {
    clientId: number;
    accountId: number;
    adIds?: Array<number>;
  }

  /**
   * Parameters for GetImage
   */
  export interface GetImageParams {
    clientId: number;
    adId: number;
    accountId: number;
  }

  /**
   * Parameters for GetTargetingGroup
   */
  export interface GetTargetingGroupParams {
    accountId: number;
    clientId?: number;
  }
}

export { AdManagerService }
