/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dg-column.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./dg-column.component";
var styles_DgColumnComponent = [i0.styles];
var RenderType_DgColumnComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DgColumnComponent, data: {} });
export { RenderType_DgColumnComponent as RenderType_DgColumnComponent };
export function View_DgColumnComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" dg-column works!\n"]))], null, null); }
export function View_DgColumnComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "ngr-dg-column", [], null, null, null, View_DgColumnComponent_0, RenderType_DgColumnComponent)), i1.ɵdid(1, 114688, null, 4, i2.DgColumnComponent, [], null, null), i1.ɵqud(603979776, 1, { template: 1 }), i1.ɵqud(603979776, 2, { headerTemplate: 1 }), i1.ɵqud(603979776, 3, { editTemplate: 1 }), i1.ɵqud(603979776, 4, { filterTemplate: 1 })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DgColumnComponentNgFactory = i1.ɵccf("ngr-dg-column", i2.DgColumnComponent, View_DgColumnComponent_Host_0, { header: "header", name: "name", align: "align", summaryFunction: "summaryFunction", formatFunction: "formatFunction", pagingSummaryFunction: "pagingSummaryFunction", filterPlaceholderText: "filterPlaceholderText", filterType: "filterType", filterOptions: "filterOptions", type: "type", width: "width", hiddable: "hiddable", hidden: "hidden", loading: "loading", sortable: "sortable", sortingType: "sortingType", sortFunction: "sortFunction", filterFunction: "filterFunction", columnHeader: "columnHeader" }, {}, []);
export { DgColumnComponentNgFactory as DgColumnComponentNgFactory };
