/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./promopost-link-widget.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../shared/pipes/domain-only.pipe";
import * as i3 from "@angular/common";
import * as i4 from "./promopost-link-widget.component";
import * as i5 from "@angular/platform-browser";
var styles_PromopostLinkWidgetComponent = [i0.styles];
var RenderType_PromopostLinkWidgetComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PromopostLinkWidgetComponent, data: {} });
export { RenderType_PromopostLinkWidgetComponent as RenderType_PromopostLinkWidgetComponent };
function View_PromopostLinkWidgetComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.linkWidgetData.linkTitle; _ck(_v, 1, 0, currVal_0); }); }
function View_PromopostLinkWidgetComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "link-title-form"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "input", [["class", "link-title-form__text-field"], ["type", "text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "button", [["class", "vk-standart-button"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C"]))], null, null); }
export function View_PromopostLinkWidgetComponent_0(_l) { return i1.ɵvid(2, [i1.ɵpid(0, i2.DomainOnlyPipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "link-widget"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "link-widget__image"]], [[4, "background", null]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["class", "link-widget__meta-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "link-widget__link-title"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PromopostLinkWidgetComponent_1)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "link-widget__link-domain"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, [" ", " "])), i1.ɵppd(9, 1), (_l()(), i1.ɵand(0, [["linkTitleForm", 2]], null, 0, null, View_PromopostLinkWidgetComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.enableEditingModeForm < 5); var currVal_2 = i1.ɵnov(_v, 10); _ck(_v, 6, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.Image; _ck(_v, 2, 0, currVal_0); var currVal_3 = i1.ɵunv(_v, 8, 0, _ck(_v, 9, 0, i1.ɵnov(_v, 0), _co.linkWidgetData.linkDomain)); _ck(_v, 8, 0, currVal_3); }); }
export function View_PromopostLinkWidgetComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-promopost-link-widget", [], null, null, null, View_PromopostLinkWidgetComponent_0, RenderType_PromopostLinkWidgetComponent)), i1.ɵdid(1, 114688, null, 0, i4.PromopostLinkWidgetComponent, [i5.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PromopostLinkWidgetComponentNgFactory = i1.ɵccf("app-promopost-link-widget", i4.PromopostLinkWidgetComponent, View_PromopostLinkWidgetComponent_Host_0, { linkWidgetData: "linkWidgetData" }, { linkTitleSave: "linkTitleSave" }, []);
export { PromopostLinkWidgetComponentNgFactory as PromopostLinkWidgetComponentNgFactory };
