import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-easy-ads-wrapper',
  templateUrl: './easy-ads-wrapper.component.html',
  styleUrls: ['./easy-ads-wrapper.component.scss']
})
export class EasyAdsWrapperComponent implements OnInit {
  @Input() public useWrapper: boolean = true;

  public menuItems: Array<any> = [
    {
      route: '/automation/dashboard',
      params: null,
      name: 'Мои объявления',
      enabled: true
    },
    {
      route: '/vk/easy-stealth',
      params: null,
      name: 'Примеры объявлений',
      enabled: true
    },
    {
      route: '/chat/conversation',
      params: null,
      name: 'Поддержка',
      enabled: true
    },
    {
      route: '/easy-settings',
      params: null,
      name: 'Настройки',
      enabled: true
    },
    {
      route: '/easy-payments-create',
      params: null,
      name: 'Оплата',
      enabled: true
    },
    {
      route: '/contacts',
      params: null,
      name: 'Контакты',
      enabled: true
    },
    {
      route: '/redefine-experience',
      params: {
        experienceFieldId: 1,
        experienceLevelId: 2,
        redirect: '/dashboard'
      },
      name: 'PostMonitor для профи',
      enabled: true
    }
  ];

  constructor() { }

  ngOnInit() {
  }

}
