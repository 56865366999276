import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {SystemBanner} from "../models/system-banner.model";
import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {APIReponseWrapper} from "../../shared/models/APIReponseWrapper";
import {BannerDialogComponent} from "../components/banner-dialog/banner-dialog.component";
import {MatDialog} from "@angular/material/dialog";

@Injectable({
  providedIn: 'root'
})
export class BannersService {

  private baseUrl = `${environment.backendUri}/${environment.apiPrefix}/system-banners`;
  private banners = new BehaviorSubject<SystemBanner[]>([]);

  public get banners$() {
    return this.banners.asObservable();
  }

  constructor(
    private http: HttpClient,
    private dialog: MatDialog
  ) {
  }

  public LoadAllBanners() {
    // this.http.get(this.baseUrl)
    //   .subscribe((response: any) => {
    //     const banners = response.data;
    //     this.banners.next(banners);
    //     if (banners.length > 0) {
    //       this.dialog
    //         .open(BannerDialogComponent)
    //         .afterClosed()
    //         .subscribe(data => {
    //           this.banners.getValue()
    //             .map(x => {
    //               this.ConfirmViewByUser(x.id);
    //             });
    //         });
    //     }
    //   })
  }

  public ConfirmViewByUser(bannerId: number) {
    const params = new HttpParams();
    params.append('bannerId', bannerId.toString());
    console.log(params);
    this.http.post(`${this.baseUrl}?bannerId=${bannerId}`, null)
      .subscribe(data => {
        console.log(data);
      })
  }
}
