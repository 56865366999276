var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Query } from '@datorama/akita';
import { UserTariffStore } from './user-tariff.store';
import * as i0 from "@angular/core";
import * as i1 from "./user-tariff.store";
var UserTariffQuery = /** @class */ (function (_super) {
    __extends(UserTariffQuery, _super);
    function UserTariffQuery(store) {
        var _this = _super.call(this, store) || this;
        _this.store = store;
        /**
         * Тарифный план пользователя
         */
        _this.tariff$ = function (groupId) { return _this.select(function (x) { return x.tariff != null ? x.tariff.find(function (tariff) { return tariff['groupId'] === groupId; }) : null; }); };
        _this.expiredAt$ = _this.select(function (x) { return x.expiredAt; });
        return _this;
    }
    UserTariffQuery.ngInjectableDef = i0.defineInjectable({ factory: function UserTariffQuery_Factory() { return new UserTariffQuery(i0.inject(i1.UserTariffStore)); }, token: UserTariffQuery, providedIn: "root" });
    return UserTariffQuery;
}(Query));
export { UserTariffQuery };
