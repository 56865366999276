import { OnInit } from '@angular/core';
import { PaymentsService } from '../../services/payments.service';
var PaymentsTariffTimePeriodsSelectorComponent = /** @class */ (function () {
    function PaymentsTariffTimePeriodsSelectorComponent(paymentService) {
        this.paymentService = paymentService;
    }
    PaymentsTariffTimePeriodsSelectorComponent.prototype.isSelected = function (timePeriodOd) {
        return this.paymentService.isSelected(timePeriodOd);
    };
    Object.defineProperty(PaymentsTariffTimePeriodsSelectorComponent.prototype, "timePeriods$", {
        get: function () {
            return this.paymentService.timePeriods$.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaymentsTariffTimePeriodsSelectorComponent.prototype, "currentSelectedTimePeriodName$", {
        get: function () {
            return this.paymentService.selectedTimePeriod$;
        },
        enumerable: true,
        configurable: true
    });
    PaymentsTariffTimePeriodsSelectorComponent.prototype.ngOnInit = function () {
    };
    PaymentsTariffTimePeriodsSelectorComponent.prototype.SelectTimePeriod = function (timePeriodId) {
        this.paymentService.selectTimePeriod(timePeriodId);
    };
    return PaymentsTariffTimePeriodsSelectorComponent;
}());
export { PaymentsTariffTimePeriodsSelectorComponent };
