var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { AccountsService } from '../../../api/services/accounts.service';
import * as moment from 'moment';
import { EasyAdsDataService } from '../../../automation/services/easy-ads-data.service';
import { AutomationVkService } from "../../../api/services/automation-vk.service";
import { TokenCheckerService } from "../../../shared/services/token-checker.service";
import { Router } from "@angular/router";
var EasySettingsComponent = /** @class */ (function () {
    function EasySettingsComponent(accountsService, easyAdsDataService, automationApi, tokenService, router) {
        this.accountsService = accountsService;
        this.easyAdsDataService = easyAdsDataService;
        this.automationApi = automationApi;
        this.tokenService = tokenService;
        this.router = router;
        this.account = null;
        this.automationTariffExpiration = null;
        this.analyticsTariffExpiration = null;
        this.openedChangePasswordForm = false;
        this.showRemoveAccountDataModal = false;
    }
    EasySettingsComponent.prototype.ngOnInit = function () {
        this.LoadAccountInformation();
        this.LoadExpirationData();
        this.LoadVkData();
    };
    EasySettingsComponent.prototype.RemoveAccountInitiate = function () {
        this.showRemoveAccountDataModal = true;
    };
    EasySettingsComponent.prototype.ExpirationAt = function (meta) {
        return moment.utc(meta.expiration).format('D MMMM YYYY, hh:mm');
    };
    EasySettingsComponent.prototype.LoadAccountInformation = function () {
        var _this = this;
        this.accountsService.GetUserInfo()
            .subscribe(function (x) {
            _this.account = x;
        });
    };
    EasySettingsComponent.prototype.LoadExpirationData = function () {
        var _this = this;
        this.accountsService.GetUserTariffAccess(['VK_AUTOMATION', 'VK_ANALYTICS']).subscribe(function (response) {
            if (response && response.data && response.data.length > 0) {
                _this.automationTariffExpiration = response.data.find(function (x) { return x.code === 'VK_AUTOMATION'; });
                _this.analyticsTariffExpiration = response.data.find(function (x) { return x.code === 'VK_ANALYTICS'; });
            }
        });
    };
    EasySettingsComponent.prototype.LoadVkData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.automationApi.GetAccounts().subscribe(function (response) {
                    if (response && response.data && response.data.length > 0) {
                        var personalCabinet = response.data.find(function (x) { return x.access_role === 'admin'; });
                        if (personalCabinet) {
                            _this.easyAdsDataService.LoadBudgetViaApi(personalCabinet.account_id, personalCabinet.client_id);
                        }
                    }
                });
                return [2 /*return*/];
            });
        });
    };
    return EasySettingsComponent;
}());
export { EasySettingsComponent };
