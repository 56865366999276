import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from "@angular/forms";
import {FlexibleConnectedPositionStrategy} from "@angular/cdk/overlay";
import {delay} from "rxjs/operators";

@Component({
  selector: 'app-new-custom-field-control',
  templateUrl: './new-custom-field-control.component.html',
  styleUrls: ['./new-custom-field-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NewCustomFieldControlComponent),
      multi: true
    }
  ]
})
export class NewCustomFieldControlComponent implements OnInit, ControlValueAccessor {

  @Input() public FieldName: string = '';
  @Input() public Description: string = '';
  @Input() public CustomFieldsValues;

  public Control: FormControl = new FormControl();

  private change;
  private touch;

  public Disabled = false;
  public IsAutocompleteOptionsOpened: boolean = false;
  overlayPosition: FlexibleConnectedPositionStrategy;
  // positions = [
  //     new ConnectedPosition()
  // ]

  public get IsButtonDisabled() {
    return this.CustomFieldsValues.length === 0
  }

  constructor() {
  }

  ngOnInit() {
    this.Control.valueChanges
      .pipe(
        delay(500)
      )
      .subscribe(value => {
        if (this.change) {
          this.change(value)
        }
      });
  }

  registerOnChange(fn: any): void {
    this.change = fn;
  }

  registerOnTouched(fn: any): void {
    this.touch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.Disabled = isDisabled;
  }

  writeValue(obj: any): void {
    this.Control.setValue(obj);
  }

  public GenerateOption(optionValue, useFormating = true) {
    let field = optionValue;
    if (useFormating) {
      field = `{{${field}}}`
    }
    return field;
  }

  SelectValue(optionValue, UseFormating = true) {
    const newValue = this.GenerateOption(optionValue, UseFormating);
    this.Control.patchValue(`${this.Control.value}${newValue}`);
  }

  public OpenAutoCompleteOptions() {
    console.log('AUTO_COMPLETE IS OPENED');
    this.IsAutocompleteOptionsOpened = true;
  }

  CloseAutoCompleteOptions($event: any) {
    console.log('CLOSED');
    this.IsAutocompleteOptionsOpened = false;
  }
}
