import { OnInit } from '@angular/core';
import { AdFormManagerService } from '../../services/ad-form-manager/ad-form-manager.service';
var NewPriceAndStrategySettingsComponent = /** @class */ (function () {
    function NewPriceAndStrategySettingsComponent(adFormManagerService) {
        this.adFormManagerService = adFormManagerService;
        this.controlWidth = '150px';
        this.labelWidth = '280px';
    }
    Object.defineProperty(NewPriceAndStrategySettingsComponent.prototype, "RateMin", {
        get: function () {
            return this.adFormManagerService.MinRateValue;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewPriceAndStrategySettingsComponent.prototype, "RateMax", {
        get: function () {
            return this.adFormManagerService.MaxRateValue;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewPriceAndStrategySettingsComponent.prototype, "form", {
        get: function () {
            return this.adFormManagerService.getControl('rateData');
        },
        enumerable: true,
        configurable: true
    });
    NewPriceAndStrategySettingsComponent.prototype.ngOnInit = function () {
    };
    NewPriceAndStrategySettingsComponent.prototype.IsStrategyModeAuto = function () {
        return this.adFormManagerService.IsStrategyModeAuto();
    };
    return NewPriceAndStrategySettingsComponent;
}());
export { NewPriceAndStrategySettingsComponent };
