<div mat-dialog-title>Выбранные объявления успешно изменены</div>

<div mat-dialog-content>
  Были изменены следующие параметры:
  <br>
  <ul class="properties-list">
    <li *ngFor="let item of Data" class="properties-list__item">
      {{item.key}}: {{item.value}}
    </li>
  </ul>
  <button class="vk-standart-button" (click)="Close()">Ок</button>
</div>
