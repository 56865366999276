<div>
  <div class="date-filter">
    <div class="btn-group">
      <div class="radio btn btn-sm">
        <input type="radio" value="today" name="period" id="period-1" [(ngModel)]="period" (change)="SelectToday()">
        <label for="period-1">Сегодня</label>
      </div>
      <div class="radio btn btn-sm">
        <input type="radio" value="yesterday" name="period" id="period-2" [(ngModel)]="period" (change)="SelectYesterday()">
        <label for="period-2">Вчера</label>
      </div>
      <div class="radio btn btn-sm">
        <input type="radio" value="7_day" name="period" id="period-3" [(ngModel)]="period" (change)="SelectWeek()">
        <label for="period-3">Неделя</label>
      </div>
      <div class="radio btn btn-sm">
        <input type="radio" value="1_month" name="period" id="period-4" [(ngModel)]="period" (change)="SelectMonth()">
        <label for="period-4">Месяц</label>
      </div>
    </div>
    <app-datepicker [(ngModel)]="dateFrom" (ngModelChange)="DateSelected($event)"></app-datepicker>
    <app-datepicker [(ngModel)]="dateTo" (ngModelChange)="DateSelected($event)"></app-datepicker>
  </div>
</div>
