import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BitrixService} from '../../../../api/services';
import {MatDialogRef} from '@angular/material';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'app-bitrix-assignation-dialog',
  templateUrl: './bitrix-assignation-dialog.component.html',
  styleUrls: ['./bitrix-assignation-dialog.component.scss']
})
export class BitrixAssignationDialogComponent implements OnInit {

  public loading: boolean = false;
  public form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private bitrix: BitrixService,
    private dialogRef: MatDialogRef<BitrixAssignationDialogComponent>
  ) {
  }

  ngOnInit() {
    this.form = this.fb.group({
      domain: this.fb.control('', [Validators.required]),
    });
  }

  Assign() {
    if (this.form.valid) {
      this.loading = true;
      const value = this.form.value;
      value.domain = value
        .domain
        .replace('https://', '')
        .replace('.bitrix24.ru', '');

      this.bitrix.AuthorizeAsync(this.form.value)
        .subscribe(data => {
          this.loading = false;
          localStorage.setItem('bind-return-to', '/new-integration');
          window.location.href = `${environment.backendUri}/${environment.apiPrefix}/OAuth/authorize/bitrix?domain=${data}`;
        }, err => {
          this.loading = false;
        });
    }
  }

}
