<div class="alert alert-info">
  <div class="alert-items">
    <div class="alert-item static">
      <div class="alert-text">
        <div class="segmentation-info">
          <div class="segmentation-info__title">
            Вы выбрали сегментировать {{segmentationType}}
<!--            <ng-container [ngSwitch]="segmentationType">-->
<!--              <ng-container *ngSwitchCase="'ad_group'"> по группам</ng-container>-->
<!--              <ng-container *ngSwitchCase="'file'"> по группам из файла</ng-container>-->
<!--              <ng-container *ngSwitchCase="'age'"> по полу и возрасту</ng-container>-->
<!--              <ng-container *ngSwitchCase="'retargeting_groups'"> по аудиториям таргета</ng-container>-->
<!--            </ng-container>-->
          </div>
        </div>
      </div>
      <div class="alert-actions">
        <div class="alert-action">
          <a href="javascript:void(0)" (click)="ToggleAdvancedMode()">Посмотреть</a>
        </div>
      </div>
    </div>
  </div>
</div>
