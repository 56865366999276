import {Injectable} from '@angular/core';

@Injectable()
export class ExpirationManagerService {

    public userMetaExpiration = {};

    constructor() {
        const ls = localStorage.getItem('user_meta');
        this.userMetaExpiration = (ls !== 'undefined') ? JSON.parse(ls) : {IsExpired: true};
    }

    public SetUserMetaExpiration(ume) {
        this.userMetaExpiration = ume;
    }

}
