import {Directive, ElementRef, OnInit} from '@angular/core';
import {fromEvent, Subject} from "rxjs";

@Directive({
  selector: '[appPopoverMenuTrigger]'
})
export class PopoverMenuTriggerDirective implements OnInit {

  public triggerSubject = new Subject();

  constructor(
    public el: ElementRef
  ) {
  }

  ngOnInit(): void {
    fromEvent(this.el.nativeElement, 'click')
      .subscribe(x => {
        this.triggerSubject.next(x);
      });
  }

}
