/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./generic-tooltip.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/material/tooltip";
import * as i4 from "@angular/cdk/overlay";
import * as i5 from "@angular/cdk/scrolling";
import * as i6 from "@angular/cdk/platform";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "@angular/cdk/bidi";
import * as i9 from "@angular/platform-browser";
import * as i10 from "./generic-tooltip.component";
var styles_GenericTooltipComponent = [i0.styles];
var RenderType_GenericTooltipComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GenericTooltipComponent, data: {} });
export { RenderType_GenericTooltipComponent as RenderType_GenericTooltipComponent };
export function View_GenericTooltipComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 6, "span", [["matTooltipClass", "tooltip-with-line-breaks"]], [[1, "title", 0]], [[null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 3)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(2, { "input-tip": 0, "input-tip_clickable": 1, "input-tip_no-margin": 2 }), i1.ɵdid(3, 147456, null, 0, i3.MatTooltip, [i4.Overlay, i1.ElementRef, i5.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i6.Platform, i7.AriaDescriber, i7.FocusMonitor, i3.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i8.Directionality], [2, i3.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i9.HAMMER_LOADER]], { message: [0, "message"], tooltipClass: [1, "tooltipClass"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "i", [], null, null, null, null, null)), i1.ɵdid(5, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵted(6, null, [" ", "\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _ck(_v, 2, 0, true, _co.clickable, _co.noMargin); _ck(_v, 1, 0, currVal_1); var currVal_2 = _co.text; var currVal_3 = "tooltip-with-line-breaks"; _ck(_v, 3, 0, currVal_2, currVal_3); var currVal_4 = ("input-tip__icon " + _co.icon); _ck(_v, 5, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.clickable ? _co.clickTitle : null); _ck(_v, 0, 0, currVal_0); var currVal_5 = _co.header; _ck(_v, 6, 0, currVal_5); }); }
export function View_GenericTooltipComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-generic-tooltip", [], null, null, null, View_GenericTooltipComponent_0, RenderType_GenericTooltipComponent)), i1.ɵdid(1, 114688, null, 0, i10.GenericTooltipComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GenericTooltipComponentNgFactory = i1.ɵccf("app-generic-tooltip", i10.GenericTooltipComponent, View_GenericTooltipComponent_Host_0, { clickable: "clickable", clickTitle: "clickTitle", header: "header", text: "text", icon: "icon", noMargin: "noMargin" }, {}, []);
export { GenericTooltipComponentNgFactory as GenericTooltipComponentNgFactory };
