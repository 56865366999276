import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingDirective } from './directives/loading.directive';
import { LoadingContentDirective } from './directives/loading-content.directive';

@NgModule({
  declarations: [LoadingDirective, LoadingContentDirective],
  imports: [
    CommonModule
  ],
  exports: [LoadingDirective, LoadingContentDirective]
})
export class LoadingModule { }
