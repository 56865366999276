<ng-container *ngIf="!loading; else loadingScreen">
  <ng-container *ngIf="assigned; else content">
    <ng-content></ng-content>
  </ng-container>
  <ng-template #content>
    <div class="assignation-screen">
      <h1 class="assignation-screen__title">
        Для продолжения работы подключите рекламный кабинет ВКонтакте
      </h1>
      <div class="assignation-screen__content">
        <div class="vk-container">
          Привязав рекламный кабинет ВКонтакте Вам станет доступно cоздание интеграци с этим сервисом.
        </div>
        <div class="vk-container">
          Подключение происходит через официальный доступ и <strong>абсолютно безопасно</strong>.
        </div>
      </div>
      <div class="assignation-screen__actions">
        <div class="vk-container">
          <a class="vk-standart-button vk-standart-button_margin" [href]="GetBindUrl()" target="_blank" rel="noopener nofollow">
            Подключить рекламный кабинет ВКонтакте
          </a>
          <button class="vk-standart-button" (click)="CheckAssignation()">Обновить</button>
        </div>
      </div>
    </div>
  </ng-template>
</ng-container>
<ng-template #loadingScreen>
  <app-loading-screen>
    Подождите, идет проверка подключения ...
  </app-loading-screen>
</ng-template>
