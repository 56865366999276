<ng-container *ngIf="data && data.configurations">
  <div class="easy-settings-data-container">
    <div class="easy-ads-page-sub-header">
      Тариф: <strong>{{data.title}}</strong>
    </div>
  </div>

  <ng-container *ngIf="data.configurations && data.configurations.length > 1">
    <div class="easy-settings-data-container" style="display: none">
      <div class="easy-settings-data-strip">
        <div class="easy-settings-data-strip__label">
          Выберите вариант тарифного плана
        </div>

        <div class="easy-settings-data-strip__content">
          <select [(ngModel)]="data.selectedOptionId" class="vk-select" (ngModelChange)="SelectOption($event)" [disabled]="disableOptionSelection">
            <ng-container *ngFor="let option of data.configurations">
              <option [value]="option.optionId" [disabled]="option.isDisabled">
                {{option.title}}
              </option>
            </ng-container>
          </select>
        </div>
      </div>
    </div>
  </ng-container>

  <div class="easy-settings-data-container">
    <div class="easy-settings-data-strip">
      <div class="easy-settings-data-strip__label">
        Выберите период продления доступа
      </div>

      <div class="easy-settings-data-strip__content">
        <select [(ngModel)]="data.selectedTimeId" class="vk-select" (ngModelChange)="SelectTime($event)" [disabled]="disableTimeSelection">
          <ng-container *ngFor="let option of data.optionTimes">
            <option [value]="option.timeId">
              {{option.title}}
            </option>
          </ng-container>
        </select>
      </div>
    </div>
  </div>

  <div class="easy-settings-data-container">
    <div class="easy-settings-data-strip">
      <div class="easy-settings-data-strip__label">
        Стоимость
      </div>

      <div class="easy-settings-data-strip__content">
        <ng-container *ngIf="data.selectedTime">
          <span [ngClass]='{"easy-price": true, "easy-price_old": data.appliedPromocode !== null && data.appliedPromocode !== undefined }'>{{data.selectedTime.price | number: '1.0-2'}} руб.</span>

          <ng-container *ngIf="data.appliedPromocode">
            <span class="easy-price">{{data.selectedTime.price - (data.selectedTime.price * data.appliedPromocode.value) / 100 | number: '1.0-2'}} руб.</span>
            <span class="easy-price">Скидка <strong>{{data.appliedPromocode.value}}%</strong> по промокоду <i>{{data.appliedPromocode.code}}</i></span>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>

  <ng-container *ngIf="!disablePromocodeSelection">
    <div class="easy-settings-data-container">
    <div class="easy-settings-data-strip">
      <div class="easy-settings-data-strip__label">
        Промокод
      </div>

      <div class="easy-settings-data-strip__content">
        <ng-container *ngIf="!data.promocodeMessage; else showPromocodeMessage">
          <input type="text" [(ngModel)]="promocode" class="vk-input" placeholder="Промокод">
          <button class="vk-standart-button" (click)="CheckPromocode()">Применить промокод</button>
        </ng-container>
        <ng-template #showPromocodeMessage>
          <div [ngClass]='{
            "easy-promocode-message-container": true,
            "easy-promocode-message-container_error": data.promocodeMessageIsError
          }'>
            <span class="easy-promocode-message-text">{{data.promocodeMessage}}</span>
            <span class="easy-promocode-message-text easy-promocode-message-text_clickable" (click)="EnterAnotherPromocode()">Ввести другой промокод</span>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
  </ng-container>

  <ng-container *ngIf="showSelectTariffButton">
    <div class="easy-settings-data-container">
      <button class="vk-standart-button" (click)="SelectTariff()">
        Перейти к оплате
      </button>
    </div>
  </ng-container>
</ng-container>
