<form class="ad-form" [formGroup]="form">
  <mat-vertical-stepper #stepper>
    <ng-template matStepperIcon="edit" let-index="index">
      {{index + 1}}
    </ng-template>

    <mat-step [stepControl]="form" *ngIf="!isUpdating">
      <ng-template matStepLabel>Выберите формат объявления</ng-template>

      <div class="vk-container">
        <div class="ad-format-strip">
          <div class="ad-format-strip__item ad-format-strip__item_full">
            <div class="ad-format-list">
              <ng-container *ngFor="let adFormat of adFormats">
                <div class="ad-format-list__item">
                  <app-ad-format
                    [adFormat]="adFormat"
                    [selectedField]="form.value.format"
                    formControlName="format"
                    [disabled]="isUpdating"
                  ></app-ad-format>
                </div>
              </ng-container>
            </div>
          </div>

          <div class="ad-format-strip__item">
            <div class="ad-format-preview-container">
              <img [src]="GetSelectedFormatePreview()" class="ad-format-preview">
              <div class="ad-format-preview-tip">
                Пример того, как объявление будет выглядеть ВКонтакте
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="vk-container">
        <button [disabled]="!form.controls.format.valid" class="vk-standart-button" (click)="stepper.next()">
          Далее
        </button>
      </div>
    </mat-step>

    <mat-step *ngIf="form.controls.format.value === 'promopost'">
      <ng-template matStepLabel>Создайте объявление</ng-template>
      <app-create-promopost
        [isUpdating]="isUpdating"
        [categories]="categories"
        formGroupName="promopost"
        (ControlAdded)="OnAddTextControl()"
        (ControlRemoved)="OnRemoveTextControl($event)"
      ></app-create-promopost>
      <button [disabled]="!form.controls.promopost.valid" class="vk-standart-button" (click)="stepper.next()">Далее
      </button>
    </mat-step>

    <mat-step *ngIf="form.controls.format.value === 'teazer'">
      <ng-template matStepLabel>Создайте объявление</ng-template>
      <app-create-teazer
        [categories]="categories"
        formGroupName="teazer"
      ></app-create-teazer>
      <button [disabled]="!form.controls.teazer.valid" class="vk-standart-button" (click)="stepper.next()">Далее
      </button>
    </mat-step>

    <mat-step *ngIf="showActivatedCabinets">
      <ng-template matStepLabel>Активируйте кабинеты</ng-template>
      <ng-container *ngIf="accounts?.length === 0 && slots?.length === 0">
        <app-activate-cabinets
          [accounts]="accounts"
          [slots]="slots"
          [countOfMaxSlots]="slots.length"

          (bindingChanged)="bindChanged.emit()"
        ></app-activate-cabinets>
      </ng-container>

      <button class="vk-standart-button" [disabled]="!HaveActivatedAccounts()" matStepperNext>Далее</button>
    </mat-step>

    <mat-step [stepControl]="form.controls.targetingData">
      <ng-template matStepLabel>Выберите кому показывать объявление</ng-template>
      <app-targeting-settings
        formGroupName="targetingData"
        [accountId]="form.controls.campaignData.controls.accountId.value"
        [adFormat]="FormatAdType"
        #adTargetingForm
      ></app-targeting-settings>
      <!--<button [disabled]="!form.controls.targetingData.valid" mat-raised-button color="primary" (click)="stepper.next()">Далее</button>-->
      <button class="vk-standart-button" (click)="NextSlideAfterTarget(form.controls.targetingData.valid)">Далее
      </button>
    </mat-step>

    <mat-step [stepControl]="form.controls.campaignData" *ngIf="!isUpdating">
      <ng-template matStepLabel>Выберите где сохранить объявление</ng-template>
      <app-ad-account-manager
        formGroupName="campaignData"
        [accounts]="accounts"
        [selectedCampaignsType]="SelectedFormatType"
        [disabled]="isUpdating"
      ></app-ad-account-manager>
      <!--<button [disabled]="!form.controls.campaignData.valid" mat-raised-button color="primary" (click)="stepper.next()">Выбрать кампанию</button>-->
      <button [disabled]="!form.controls.campaignData.valid" class="vk-standart-button" (click)="stepper.next()">Далее
      </button>
    </mat-step>

    <mat-step [stepControl]="form.controls.rate">
      <ng-template matStepLabel>Выберите стоимость и способ показа объявления</ng-template>
      <app-rate-manager
        [adType]="FormatAdType"
        [currentStrategy]="strategy"
        formGroupName="rate"
        #rateManager
      ></app-rate-manager>
      <!--<button [disabled]="!form.controls.rate.valid" mat-raised-button color="primary" (click)="stepper.next()">Далее</button>-->
      <ng-container *ngIf="CanBeModerated(); else saveButton">
        <button [disabled]="!CanBeNext" class="vk-standart-button" (click)="SelectRate()">Далее</button>
      </ng-container>
    </mat-step>
    <mat-step [stepControl]="form.controls.moderationSettings" *ngIf="CanBeModerated()">
      <ng-template matStepLabel>Выберите способ запуска и сохранения объявления</ng-template>
      <app-ad-start-settings
        [disabled]="ModerationSettingsDisabled"
        formGroupName="moderationSettings"
        [accountId]="accountId"
        [clientId]="clientId"
        [AccountRole]="AccountRole"
      ></app-ad-start-settings>
      <ng-container [ngTemplateOutlet]="saveButton"></ng-container>
    </mat-step>
  </mat-vertical-stepper>
</form>

<ng-template #saveButton>
  <ng-container *ngIf="!loading; else loadingTemplate">
    <ng-container *ngFor="let error of getErrors()">
      <div class="vk-container">
        <div class="vk-error">
          {{error}}
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="isUpdating; else createButtonText">
      <button [disabled]="getErrors().length > 0 && !CanBeNext" class="vk-standart-button" (click)="SaveAd()">
        Сохранить изменения
      </button>
    </ng-container>
    <ng-template #createButtonText>
      <button [disabled]="getErrors().length > 0 && !CanBeNext" class="vk-standart-button" (click)="SaveAd()"
              onclick="ym(45402093, 'reachGoal', 'metrika-js-pressed-create-ad-at-ad-manager-create');">
              <!-- onclick="yaCounter45402093.reachGoal('metrika-js-pressed-create-ad-at-ad-manager-create');"> -->
        Создать объявление
      </button>
    </ng-template>
  </ng-container>
  <ng-template #loadingTemplate>
    <mat-spinner [diameter]="20"></mat-spinner>
  </ng-template>
</ng-template>
