import {Injectable} from '@angular/core';
import {TariffPlan} from '../../../../../src/app/api/models/tariff-plan';
import {PostMonitorBackend_V2ModelsPaymentsTimePeriod} from '../../../../../src/app/api/models/post-monitor-backend-_v2models-payments-time-period';
import {PostMonitorBackend_V2ModelsPaymentsTariffPackageVisisbleFeature} from '../../../../../src/app/api/models/post-monitor-backend-_v2models-payments-tariff-package-visisble-feature';
import {FeaturesService, TariffPackagesService, TimePeriodsService} from '../../../../../src/app/api/services';
import {PostMonitorBackend_V2ModelsPaymentsFeature} from '../../../../../src/app/api/models/post-monitor-backend-_v2models-payments-feature';
import {isNullOrUndefined} from 'util';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {

  public tariffPlans$: BehaviorSubject<TariffPlan[]> = new BehaviorSubject<TariffPlan[]>([]);
  public timePeriods$: BehaviorSubject<PostMonitorBackend_V2ModelsPaymentsTimePeriod[]> = new BehaviorSubject<PostMonitorBackend_V2ModelsPaymentsTimePeriod[]>([]);
  public visibleFeatures$: BehaviorSubject<PostMonitorBackend_V2ModelsPaymentsTariffPackageVisisbleFeature[]> = new BehaviorSubject<PostMonitorBackend_V2ModelsPaymentsTariffPackageVisisbleFeature[]>([]);
  public features$: BehaviorSubject<PostMonitorBackend_V2ModelsPaymentsFeature[]> = new BehaviorSubject<PostMonitorBackend_V2ModelsPaymentsFeature[]>([]);

  public selectedTimePeriodId$: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  public selectedTimePeriod$ = combineLatest(
    this.timePeriods$,
    this.selectedTimePeriodId$
  )
    .pipe(
      map(([timePeriods, selectedTimePeriodId]) => {
        return timePeriods.find(x => x.id === selectedTimePeriodId);
      }),
      map(x => !isNullOrUndefined(x) ? x.name : '')
    );

  constructor(
    private tariffPlanService: TariffPackagesService,
    private timePeriodsService: TimePeriodsService,
    private featureService: FeaturesService
  ) {
  }

  /**
   * Загрузить все тарифные планы
   * @constructor
   */
  public LoadTariffPlans() {
    this.tariffPlanService.GetAllTariffPackages()
      .subscribe((tariffPlans) => {
        this.tariffPlans$.next(tariffPlans);
      });
  }

  /**
   * Загрузить все периоды вемени
   * @constructor
   */
  public LoadTimePeriod() {
    this.timePeriodsService.GetTimePeriods()
      .subscribe((timePeriods) => {
        this.timePeriods$.next(timePeriods);
        this.selectTimePeriod(timePeriods[0].id);
      });
  }

  /**
   * Загрузить все функции
   * @constructor
   */
  public LoadVisibleFeatures() {
    this.featureService.GetVisibleFeature()
      .subscribe(features => {
        this.visibleFeatures$.next(features);
      });
  }

  /**
   *
   * @constructor
   */
  public LoadFeatures() {
    this.featureService.GetAllTariffSFeatures()
      .subscribe(data => {
        this.features$.next(data);
      });
  }

  /**
   * Выбрать активный период времени
   * @param timePeriodId - id тарифного плана
   */
  public selectTimePeriod(timePeriodId: number) {
    this.selectedTimePeriodId$.next(timePeriodId);
  }

  /**
   * Получить цену по тарифному плану и периоду времени
   * @param tariffPlanId - id тарифного плана
   * @param timePeriodId - id периода времени
   * @constructor
   */
  public GetPriceByTariffPlanAndTimePeriod(tariffPlanId, timePeriodId) {
    const tariffPlansList = this.tariffPlans$.getValue();
    const timePeriodsList = this.timePeriods$.getValue();

    if (tariffPlansList) {
      const tariffPlan = tariffPlansList.find(x => x.id === tariffPlanId);
      if (tariffPlan) {
        const price = tariffPlan.prices.find(x => x['periodId'] === timePeriodId);
        return price;
      }
    }

    return null;
    // if (this.timePeriods$.getValue() && this.timePeriods$.getValue().length > 0) {
    //   const timePeriod = this.timePeriods$.getValue().find(tp => tp.id === timePeriodId);
    //   if (timePeriod && timePeriod.prices) {
    //     const price = timePeriod.prices.find(x => x.tariffPlanId === tariffPlanId);
    //     if (price) {
    //       return price.price;
    //     } else {
    //       return 0;
    //     }
    //   }
    //
    // } else {
    //   return 0;
    // }
  }

  /**
   * Получить цену по тарифному плану
   * @param tariffPlan - id тарифного плана
   * @constructor
   */
  public GetPriceByTariffPlan(tariffPlan) {
    return this.GetPriceByTariffPlanAndTimePeriod(tariffPlan, this.selectedTimePeriodId$.getValue());
  }

  /**
   *
   * @param featureId
   * @param tariffPlanId
   * @constructor
   */
  public GetFeatureTariffPlanStatus(featureId: number, tariffPlanId: number) {
    if (this.visibleFeatures$ && this.visibleFeatures$.getValue().length > 0) {
      const featureStatus = this.visibleFeatures$.getValue().find(vf => vf.featureId === featureId && vf.tariffId === tariffPlanId);
      console.log(featureStatus, 'FEATURE STATUS WTF ???');
      return featureStatus;
    }
    return null;
  }

  /**
   *
   * @param timePeriodId
   */
  public isSelected(timePeriodId) {
    return this.selectedTimePeriodId$.getValue() === timePeriodId;
  }

  public LoadData() {
    this.LoadVisibleFeatures();
    this.LoadTariffPlans();
    this.LoadTimePeriod();
    this.LoadFeatures();
  }
}
