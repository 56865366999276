import { PlatformsTypes } from '../../../platforms-types';
import { AkitaNgFormsManager } from '@datorama/akita-ng-forms-manager';
import { PlatformsQuery } from '../../../stores/platforms/platforms.query';
import { CrmPipelinesQuery } from '../../../stores/crm-pipelines/crm-pipelines.query';
import * as i0 from "@angular/core";
import * as i1 from "@datorama/akita-ng-forms-manager";
import * as i2 from "../../../stores/platforms/platforms.query";
import * as i3 from "../../../stores/crm-pipelines/crm-pipelines.query";
var VkRetargetingGroupsConfiguratorService = /** @class */ (function () {
    function VkRetargetingGroupsConfiguratorService(fm, platformsService, pipelinesQuery) {
        this.fm = fm;
        this.platformsService = platformsService;
        this.pipelinesQuery = pipelinesQuery;
    }
    VkRetargetingGroupsConfiguratorService.prototype.configure = function (type) {
        var _this = this;
        var form = this.fm.getForm('destinationPlatformSettings').value;
        var pipelineForm = this.fm.getForm('originPlatformSettings').value;
        var _a = form.accountId, accountId = _a[0], clientId = _a[1];
        var data = {
            code: type.toString(),
            data: []
        };
        var classicData = null;
        if (form.isClassicMode && form.splitByPipelines) {
            classicData = ['LEADS', 'DEALS'].map(function (filter) { return ({
                code: type.toString(),
                filter: [filter],
                data: _this.generateGeneralForm(form, data, pipelineForm, accountId, clientId, type)
                    .filter(function (x) { return x.pipelineType === filter; })
                    .map(function (x) {
                    x.pipelineType = undefined;
                    return x;
                })
            }); });
        }
        else {
            data.data = this.generateGeneralForm(form, data, pipelineForm, accountId, clientId, type).map(function (x) {
                x.pipelineType = undefined;
                return x;
            });
        }
        return form.isClassicMode && form.splitByPipelines ? classicData : [data];
    };
    VkRetargetingGroupsConfiguratorService.prototype.generateGeneralForm = function (form, data, pipelineForm, accountId, clientId, type) {
        var _this = this;
        if (type === void 0) { type = null; }
        return this.getPipelineStatuses(form)
            .map(function (pipelineStatus) { return ({
            code: pipelineStatus.pipelineId ? (_this.checkBitrixType(pipelineStatus.pipelineType) ? 0 : _this.getSelectedPipelineId(pipelineForm)) + "_" + pipelineStatus.pipelineId : null,
            pipelineType: pipelineStatus.pipelineType,
            data: pipelineStatus.retargetingGroups.map(function (group) { return _this.GetRetargetingGroupConfigurationItem(accountId, clientId, group, type); })
        }); });
    };
    VkRetargetingGroupsConfiguratorService.prototype.GetRetargetingGroupConfigurationItem = function (accountId, clientId, group, type) {
        if (type === void 0) { type = null; }
        if (type) {
            switch (type) {
                case PlatformsTypes.FB_CUSTOM_AUDIENCES:
                    return "" + group;
                    break;
                case PlatformsTypes.VK_RETARGETING_GROUPS:
                    return accountId + "_" + (clientId || 0) + "_" + group;
                    break;
            }
        }
        else {
            return accountId + "_" + (clientId || 0) + "_" + group;
        }
    };
    VkRetargetingGroupsConfiguratorService.prototype.getSelectedPipelineId = function (pipelineForm) {
        return this.pipelinesQuery.getEntityIdByPipelineId$(pipelineForm.selectedPipelineId);
    };
    VkRetargetingGroupsConfiguratorService.prototype.getPipelineStatuses = function (form) {
        return Object.keys(form.pipelineStatuses)
            .map(function (key) { return form.pipelineStatuses[key]; })
            .filter(function (x) { return x.retargetingGroups && x.retargetingGroups.length > 0; });
    };
    VkRetargetingGroupsConfiguratorService.prototype.valid = function (type) {
        var form = this.fm.getForm('destinationPlatformSettings');
        var value = form ? form.value : null;
        if (value && value.pipelineStatuses) {
            return value ? Object.keys(value.pipelineStatuses)
                .map(function (key) { return value.pipelineStatuses[key]; })
                .filter(function (x) { return x.retargetingGroups && x.retargetingGroups.length > 0; })
                .map(function (status) { return status.retargetingGroups.length > 0; })
                .reduce(function (acc, curr) { return acc || curr; }, false) : false;
        }
        return false;
    };
    VkRetargetingGroupsConfiguratorService.prototype.checkBitrixType = function (section) {
        return this.platformsService.getValue().ui.selectedOriginPlatform === PlatformsTypes.BITRIX_EXPORT && section === 'LEADS';
    };
    VkRetargetingGroupsConfiguratorService.ngInjectableDef = i0.defineInjectable({ factory: function VkRetargetingGroupsConfiguratorService_Factory() { return new VkRetargetingGroupsConfiguratorService(i0.inject(i1.AkitaNgFormsManager), i0.inject(i2.PlatformsQuery), i0.inject(i3.CrmPipelinesQuery)); }, token: VkRetargetingGroupsConfiguratorService, providedIn: "root" });
    return VkRetargetingGroupsConfiguratorService;
}());
export { VkRetargetingGroupsConfiguratorService };
