<form [formGroup]="form" class="user-comment-form" (submit)="submit()">
  <label class="user-comment-form__wrapper">
    <span class="user-comment-form__label">Комментарий к пользователю:</span>
    <textarea class="user-comment-form__text-field" formControlName="text"></textarea>
  </label>
  <div class="fx-row">
    <div class="fx-row__divider"></div>
    <button class="btn" [disabled]="!form.valid">Сохранить</button>
  </div>
</form>
