import { AccountData } from '../../ad-manager/models/account-data';
var AbstractCopyingConfigurator = /** @class */ (function () {
    function AbstractCopyingConfigurator(copyingData) {
        this.copyingData = copyingData;
        console.log(copyingData, 'COPYING DATA');
        var _a = copyingData.accountId.split('_')
            .map(function (x) { return x !== 'null' ? parseInt(x, 10) : null; }), accountId = _a[0], clientId = _a[1];
        var _b = copyingData.targetAccountId.split('_')
            .map(function (x) { return x !== 'null' ? parseInt(x, 10) : null; }), targetAccountId = _b[0], targetClientId = _b[1];
        this.accountData = new AccountData(accountId, clientId);
        this.targetAccountData = new AccountData(targetAccountId, targetClientId);
        console.log(this.accountData, this.targetAccountData, 'ACCOUNTS DATA');
    }
    AbstractCopyingConfigurator.prototype.generateDefeault = function () {
        return {
            accountId: this.accountData.accountId,
            clientId: this.accountData.clientId,
            targetAccountId: this.targetAccountData.accountId,
            targetClientId: this.targetAccountData.clientId,
            campaignId: this.copyingData.campaignId,
            targetCampaignId: this.copyingData.targetCampaignId,
            adIds: this.copyingData.adIds,
            countOfCopies: 1,
            costType: this.copyingData.costType,
            costValue: this.copyingData.costValue ? parseFloat(this.copyingData.costValue.toString().replace(',', '.')) : null,
            numberOfCopy: 1,
            // Не используются в стандартной стратегии копирования
            ageFrom: null,
            ageTo: null,
            groupId: null,
            groupsNot: null,
            retargetingGroup: null,
            retargetingGroupNot: null,
            sex: null,
            linkUrl: this.generateLink()
        };
    };
    AbstractCopyingConfigurator.prototype.generateLink = function () {
        var link = null;
        if (this.copyingData.generateLinks) {
            link = this.copyingData.linkSettings.linksData.host;
            if (link.substr(link.length - 1) !== '/') {
                link = link + '/';
            }
            var linkQueryParams = this.copyingData.linkSettings.linksData.linkQueryParams.map(function (_a) {
                var name = _a.name, value = _a.value;
                return name + "=" + value;
            }).join('&');
            link = (link + "?" + linkQueryParams).replace('/?', '?');
        }
        return link;
    };
    return AbstractCopyingConfigurator;
}());
export { AbstractCopyingConfigurator };
export var CopyingType;
(function (CopyingType) {
    CopyingType["DEFAULT_COPYING"] = "DEFAULT_COPYING";
    CopyingType["BY_GROUPS"] = "BY_GROUPS";
    CopyingType["BY_GROUPS_FILE"] = "BY_GROUPS_FILE";
    CopyingType["BY_SEX_AND_AGE"] = "BY_SEX_AND_AGE";
    CopyingType["BY_RETARGETING_GROUPS"] = "BY_RETARGETING_GROUPS";
    CopyingType["BY_ACTIVE_GROUPS"] = "BY_ACTIVE_GROUPS";
    CopyingType["BY_ACTIVE_GROUPS_FILES"] = "BY_ACTIVE_GROUPS_FILES";
    CopyingType["BY_INTEREST_CATEGORIES"] = "BY_INTEREST_CATEGORIES";
    CopyingType["BY_INTEREST_CATEGORIES_CHECKBOXES_TREE"] = "BY_INTEREST_CATEGORIES_CHECKBOXES_TREE";
    CopyingType["BY_LINK_SEGMENTATION"] = "BY_LINK_SEGMENTATION";
})(CopyingType || (CopyingType = {}));
