import {Directive, TemplateRef} from '@angular/core';

@Directive({
  selector: 'vkFormGroupLabel,[vkFormGroupLabel]'
})
export class VkFormGroupLabelDirective {

  constructor() {
  }

}
