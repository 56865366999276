import { ChangeDetectorRef, EventEmitter, OnInit } from '@angular/core';
var DgPaginationComponent = /** @class */ (function () {
    function DgPaginationComponent(cd) {
        this.cd = cd;
        this.maxShowedPages = 10;
        this.currentPageChange = new EventEmitter();
    }
    Object.defineProperty(DgPaginationComponent.prototype, "pages", {
        get: function () {
            return Array.from(new Array(this.countOfPages), function (x, i) { return i + 1; });
        },
        enumerable: true,
        configurable: true
    });
    DgPaginationComponent.prototype.ngOnInit = function () {
    };
    DgPaginationComponent.prototype.SetPage = function (page) {
        if (page > this.countOfPages) {
            this.currentPage = this.countOfPages;
        }
        else if (page < 1) {
            this.countOfPages = 1;
        }
        else {
            this.currentPage = page;
        }
        this.cd.markForCheck();
        this.currentPageChange.emit(this.currentPage);
    };
    DgPaginationComponent.prototype.NextPage = function () {
        this.SetPage(this.currentPage + 1);
    };
    DgPaginationComponent.prototype.PrevPage = function () {
        this.SetPage(this.currentPage - 1);
    };
    DgPaginationComponent.prototype.LastPage = function () {
        this.SetPage(this.countOfPages);
    };
    DgPaginationComponent.prototype.FirstPage = function () {
        this.SetPage(1);
    };
    DgPaginationComponent.prototype.IsPageActive = function (page) {
        return this.currentPage === page;
    };
    return DgPaginationComponent;
}());
export { DgPaginationComponent };
