import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {AbstractControl, FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';
import {AdManagerService, AutomationVkService, AutomationVkSlotService} from '../../../../api/services';
import {CreateCampaignFormComponent} from '../../../ad-manager/components/create-campaign-form/create-campaign-form.component';
import {ActivatedRoute} from '@angular/router';
import {MatConfirmDialogComponent} from '../../../ad-manager/components/modals/configrm-dialog/mat-confirm-dialog.component';
import {MultipleCopyStatus} from '../../../utils/types/multiple-copy-status';
import {delay, retry} from 'rxjs/operators';
import {AccountViewModel} from '../../../models/view-models/account-view-model';
import {UserAutomationCabinetSlot} from '../../../../api/models/user-automation-cabinet-slot';
import {isNullOrUndefined} from 'util';
import {Client} from '../../../../api/models/client';
import {environment} from "../../../../../environments/environment";
import {AdViewModel} from "../../../models/view-models/ad-view-model";
import * as _ from 'lodash';
import {AgeSegment, AgeSegmentSex} from "../../../models/view-models/age-segment";
import {PostMonitorBackend_V2ModelsAdManagerEntitiesInterestCategory} from "../../../../api/models";

export enum MultipleCopyState {
  LOADING = 'LOADING',
  COPY_FORM = 'COPY_FORM',
  READY = 'READY',
  ERROR = 'ERROR'
}

@Component({
  selector: 'app-mltiple-copy-form',
  templateUrl: './multiple-copy-form.component.html',
  styleUrls: ['./multiple-copy-form.component.scss']
})
export class MultipleCopyFormComponent implements OnInit {
  public form: FormGroup;
  public accountId;
  public campaignId;
  public selectedAds;
  public state: MultipleCopyState = MultipleCopyState.COPY_FORM;
  public openedAdvancedMode: boolean = false;

  public slots: Array<UserAutomationCabinetSlot> = [];
  public accounts: Array<AccountViewModel> = [];
  public campaigns: Array<any> = [];
  public showRedirectLink = false;
  public NewCampaignQueryParams: any;
  public errorMessage: string = null;
  public countOfCreatedAds: number = 0;
  public countOfNewAd: number = 0;
  public clients: Array<Client>;
  public loadingAdvancedScreen: boolean = true;
  public errorsOnCopy = [];
  public isAllNotCopied = false;
  public adsWithCopyingErrors = [];
  public copiesData = [];
  public segmentAudience: boolean = false;
  public ages = [];
  public showSegment = false;
  public fileGroups = [];
  public showCostValue = false;
  public costValueLabelText = '';
  public costValueMaxMessage = '';
  public costValueMinMessage = '';
  public showErrors = false;
  changeCostType: boolean = false;
  private interestCategories: Array<PostMonitorBackend_V2ModelsAdManagerEntitiesInterestCategory>;

  constructor(
    public dialog: MatDialogRef<MultipleCopyFormComponent>,
    private adManagerService: AdManagerService,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private automationVkService: AutomationVkService,
    private automationVkSlotService: AutomationVkSlotService,
    private dialogService: MatDialog,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef
  ) {
  }

  public get SegmentByGroups() {
    return this.form.controls.segmentByGroups.value;
  }

  public get AgeSegments(): Array<AgeSegment> {
    return this.form.get('ages').value as Array<AgeSegment>;
  }

  ngOnInit() {
    this.showSegment = this.route.snapshot.queryParams.segments;
    const clientId = this.dialogData.clientId ? this.dialogData.clientId : 'null';
    console.log(`${this.dialogData.accountId}_${clientId}`, 'INITIATING DATA');
    this.form = new FormGroup({
      accountId: new FormControl(`${this.dialogData.accountId}_${this.dialogData.clientId === 0 ? 'null' : this.dialogData.clientId}`),
      campaignId: new FormControl(this.dialogData.campaignId),
      countOfCopies: new FormControl(1, [Validators.required, Validators.min(1), Validators.max(this.GetMaxCountOfCopiedAds())]),
      targetAccountId: new FormControl(`${this.dialogData.accountId}_${clientId}`),
      targetCampaignId: new FormControl(this.dialogData.campaignId),
      segmentByGroups: new FormControl('age'),
      adIds: new FormControl(this.dialogData.selectedAds),
      byGroups: new FormControl(false),
      ages: new FormArray([]),
      excludeOtherRetargetingGroups: new FormControl(false),
      excludeOtherGroups: new FormControl(false),
      costType: new FormControl(null),
      costValue: new FormControl(0),
    });

    this.form = new FormGroup({
      accountId: new FormControl(`${this.dialogData.accountId}_${this.dialogData.clientId === 0 ? 'null' : this.dialogData.clientId}`),
      campaignId: new FormControl(this.dialogData.campaignId),
      targetAccountId: new FormControl(`${this.dialogData.accountId}_${clientId}`),
      targetCampaignId: new FormControl(this.dialogData.campaignId),
      adIds: new FormControl(this.dialogData.selectedAds),
      copyingMode: new FormControl('DEFAULT_COPYING'),
      defaultConfiguration: new FormGroup({
        countOfCopies: new FormControl(1)
      }),
      groupsConfiguration: new FormGroup({
        excludeOtherGroups: new FormControl(false),
      }),
      groupsFromFileConfiguration: new FormGroup({
        groupsList: new FormControl(''),
        excludeOtherGroups: new FormControl(false),
      }),
      sexAndAgeConfiguration: new FormGroup({
        sexAndAgeItems: new FormArray([
          new FormGroup({
            ageFrom: new FormControl(),
            ageTo: new FormControl(),
            eachAge: new FormControl(false),
            sex: new FormControl(),
          })
        ])
      }),
      retargetingGroupsConfiguration: new FormGroup({
        excludeOtherRetargetingGroups: new FormControl(false)
      })
    });
    this.AddAgeSegmentation();

    this.countOfNewAd = this.dialogData.selectedAds.length;

    this.form.valueChanges.subscribe(({targetAccountId, targetCampaignId, countOfCopies}) => {
      this.accountId = targetAccountId;
      this.campaignId = targetCampaignId;
      this.countOfNewAd = this.dialogData.selectedAds.length * countOfCopies;
      if (parseInt(targetAccountId, 10) !== parseInt(this.dialogData.accountId, 10) || parseInt(targetCampaignId, 10) !== parseInt(this.dialogData.campaignId, 10)) {
        this.NewCampaignQueryParams = {
          campaign_id: this.campaignId,
          account_id: this.accountId
        };
        this.showRedirectLink = true;
      }
    });

    this.form.get('costType').valueChanges.subscribe(costType => {
      console.log(costType);
      switch (costType) {
        case 0:
          this.showCostValue = true;
          this.showErrors = false;
          this.setCpcValidators();
          this.showErrors = true;
          break;
        case 1:
          this.showCostValue = true;
          this.showErrors = false;
          this.setCpmValidators();
          this.showErrors = true;
          break;
        case null:
          this.showCostValue = false;
          this.setDefaultValidators();
          break;
      }
      this.setCostValueMessageText();
      console.log(this.costValueMinMessage);
      console.log(this.costValueMaxMessage);
    });

    this.form.controls.targetAccountId.valueChanges.subscribe(account => {
      console.log(account, 'ACCOUNT');
      const [accountId, clientId] = account.split('_');
      console.log(accountId, clientId);
      this.LoadCampaigns(accountId, clientId)
        .subscribe((data) => console.log);
    });

    console.log(this.dialogData, 'DIALOG DATA');
  }

  public LoadInterestCategoreis() {
    this.adManagerService.GetInterestCategories()
      .subscribe(x => {
        this.interestCategories = x;
      });
  }

  public get agesControls() {
    return (this.form.get('ages') as FormArray).controls;
  }

  public ToggleAdvancedMode() {
    this.openedAdvancedMode = !this.openedAdvancedMode;
    if (this.openedAdvancedMode) {
      this.LoadCabinetData()
        .subscribe(() => {
          setTimeout(() => {
            this.loadingAdvancedScreen = false;
          }, 3000);
        });
    }
  }

  public IsSegmentDisabled() {
    return this.dialogData.selectedAds.length > 1
  }

  public ConfirmCopy() {
    const copies = this.PrepearData();
    this.copiesData = [];
    for (const cop of copies) {
      for (const d of cop) {
        this.copiesData.push(d);
      }
    }
    this.dialogService.open(MatConfirmDialogComponent, {
      data: {
        title: `Подтвердите копирование объявлений`,
        description: `Скопировать выбранные объявления в колличестве ${this.copiesData.length} шт.`,
        confirmActionText: 'Скопировать',
        rejectActionText: 'Отменить'
      }
    })
      .afterClosed()
      .subscribe(status => {
        if (status) {
          this.CopyAds();
        }
      });
  }

  private GetCountOfCopiedAds() {
    return this.form.value.countOfCopies * this.dialogData.selectedAds.length;
  }

  async CopyAds() {
    if (this.form.valid) {
      this.SetLoadingState();
      const copiesData = [];
      const copies = this.PrepearData();
      for (const cop of copies) {
        for (const d of cop) {
          copiesData.push(d);
        }
      }
      this.countOfNewAd = this.copiesData.length > 0 ? this.copiesData.length : copiesData.length;
      for (const copy of copiesData) {
        try {
          console.log(copy, 'LOG');
          copy.costValue = this.form.value.costValue;
          const copyingResponse = await this.adManagerService
            .CopyAds(copy)
            .pipe(
              delay(2000),
              retry(2),
            )
            .toPromise();
          this.countOfCreatedAds++;
        } catch (e) {
          const newError = e.error;
          if (newError && newError.code) {
            const isErrorForAdding = this.errorsOnCopy.map(err => err.code).includes(newError.code);
            if (isErrorForAdding) {
              this.errorsOnCopy.push(newError);
            }
          }
          const ad = this.dialogData.selectedAds.find(x => x.id === copy.adIds[0]);
          const newAd = {
            name: `Копия ${copy.numberOfCopy} ${ad.name}`
          }
          this.adsWithCopyingErrors.push({
            ad: newAd,
            error: newError
          });
        }
      }
      if (this.adsWithCopyingErrors.length > 0) {
        this.isAllNotCopied = this.adsWithCopyingErrors.length === copiesData.length;
        console.warn(this.isAllNotCopied, 'IS NOT ALL COPIED', this.adsWithCopyingErrors.length, copiesData.length);
        this.SetState(MultipleCopyState.ERROR);
      } else {
        this.SetState(MultipleCopyState.READY);
      }
    }
  }

  /**
   * Создает данные для копирования 1 объявления
   * @param {string} accountId - id текущего рекламного кабинета ВК.
   * @param {string} clientId - id текущего клиента рекламного кабинета ВК.
   * @param {string} campaignId - id текущей рекламной кампании в текущем кабинете ВК.
   * @param {string} targetAccountId - id кабинета в который бует копироваться объявление
   * @param {string} targetClientId - id клиента рекламного кабинета в который будет копироваться объявление
   * @param {string} targetCampaignId - id кампании рекламного кабинета в которую будет копироватьс объявление
   * @param selectedAd - id копируемого объявления
   * @param {number} adNumber - номер копии
   * @param groupId
   * @param ageFrom
   * @param ageTo
   * @param sex
   * @param retargetingGroup
   * @param retargetingGroupNot
   * @param excludeOtherGroups
   * @param excludeOtherRetargetingGroup
   * @constructor
   */
  private CreateCopyingAdItem(
    accountId,
    clientId,
    campaignId,
    targetAccountId,
    targetClientId,
    targetCampaignId,
    selectedAd,
    adNumber,
    groupId = null,
    ageFrom = null,
    ageTo = null,
    sex = null,
    retargetingGroup = null,
    retargetingGroupNot = null,
    groupsNot = null,
    costType = null
  ) {
    return {
      accountId,
      clientId: clientId === 'null' ? null : clientId,
      campaignId,
      targetAccountId,
      targetClientId: targetClientId === 'null' ? null : targetClientId,
      targetCampaignId,
      adIds: [selectedAd.id],
      countOfCopies: 1,
      numberOfCopy: adNumber + 1,
      groupId,
      ageFrom,
      ageTo,
      sex,
      retargetingGroup,
      retargetingGroupNot: retargetingGroupNot ? retargetingGroupNot : null,
      groupsNot,
      costType
    };
  }


  public PrepearData() {
    this.copiesData = [];
    const copyingOptions = this.form.value;
    console.log(copyingOptions, 'COPYING');
    const [accountId, clientId] = copyingOptions.accountId.split('_');
    const [targetAccountId, targetClientId] = copyingOptions.targetAccountId.split('_');
    const data = this.dialogData.selectedAds.map((selectedAd: AdViewModel, i) => {
      if (this.segmentAudience) {
        switch (copyingOptions.segmentByGroups) {
          case 'file':
          case 'ad_groups':
            let groups = [];
            if (this.fileGroups.length > 0) {
              groups = this.fileGroups
                .map(x => x.replace('https://vk.com/', '').replace('http://vk.com/', ''));
            } else {
              groups = selectedAd.groups.split(',');
            }
            return groups.map(groupName => {
              let groupsNot = null;
              if (copyingOptions.excludeOtherGroups) {
                groupsNot = groups.filter(x => x !== groupName).join(',')
              }
              return this.CreateCopyingAdItem(
                accountId,
                clientId,
                copyingOptions.campaignId,
                targetAccountId,
                targetClientId,
                copyingOptions.targetCampaignId,
                selectedAd,
                i,
                groupName,
                null,
                null,
                null,
                null,
                null,
                groupsNot,
                copyingOptions.costType
              )
            });
          case 'age':
            return this.PrepearAgeSexSegmentsData(copyingOptions);
          case 'retargeting_groups':
            return this.PrepearRetargetingGroupsData(copyingOptions);
            break;
        }
      } else {
        console.log('STANDART COPY');
        const copies = []
        for (let j = 0; j < copyingOptions.countOfCopies; j++) {
          copies.push(this.CreateCopyingAdItem(
            accountId,
            clientId,
            copyingOptions.campaignId,
            targetAccountId,
            targetClientId,
            copyingOptions.targetCampaignId,
            selectedAd,
            j,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            copyingOptions.costType,
          ));
        }
        return copies
      }
    });
    console.log(data, 'DATA');
    return data;
  }

  private PrepearAgeSexSegmentsData(copyingOptions) {
    const [accountId, clientId] = copyingOptions.accountId.split('_');
    const [targetAccountId, targetClientId] = copyingOptions.targetAccountId.split('_');
    const selectedAd = this.dialogData.selectedAds[0];
    const segments = [];
    this.ages.forEach((segment, j) => {
      console.log(segment);
      console.log(segment.eachAge, 'AGE SEGMTENT EACH AGE');
      if (segment.eachAge) {
        for (let ageItem = segment.ageFrom; ageItem <= segment.ageTo; ageItem++) {
          this.CreateSegmentData(accountId, clientId, copyingOptions, targetAccountId, targetClientId, selectedAd, j, ageItem, segments, segment, ageItem, ageItem);
        }
      } else {
        this.CreateSegmentData(accountId, clientId, copyingOptions, targetAccountId, targetClientId, selectedAd, j, null, segments, segment, segment.ageFrom, segment.ageTo);
      }
    });
    return segments;
  }

  private CreateSegmentData(accountId, clientId, copyingOptions, targetAccountId, targetClientId, selectedAd, j, i, segments, segment: AgeSegment, ageFrom = null, ageTo = null) {
    console.log(segment.sex, 'SEGMENT');
    switch (segment.sex) {
      case AgeSegmentSex.ALL:
        let seg = this.CreateCopyingAdItem(
          accountId,
          clientId,
          copyingOptions.campaignId,
          targetAccountId,
          targetClientId,
          copyingOptions.targetCampaignId,
          selectedAd,
          j,
          null,
          ageFrom,
          ageTo,
          null,
          null,
          null,
          null,
          copyingOptions.costType
        );
        segments.push(seg);
        break;
      case AgeSegmentSex.FEMALE:
      case AgeSegmentSex.MALE:
        const seg1 = this.CreateCopyingAdItem(
          accountId,
          clientId,
          copyingOptions.campaignId,
          targetAccountId,
          targetClientId,
          copyingOptions.targetCampaignId,
          selectedAd,
          j,
          null,
          ageFrom,
          ageTo,
          segment.sex,
          null,
          null,
          null,
          copyingOptions.costType
        );
        segments.push(seg1);
        break;
      case AgeSegmentSex.SPLIT:
        console.log('SPLIT');
        for (let z = 1; z <= 2; z++) {
          console.log('SEGMENT FUCKING SEX', z);
          const seg = this.CreateCopyingAdItem(
            accountId,
            clientId,
            copyingOptions.campaignId,
            targetAccountId,
            targetClientId,
            copyingOptions.targetCampaignId,
            selectedAd,
            j,
            null,
            ageFrom > 0 ? ageFrom : null,
            ageTo > 0 ? ageTo : null,
            z,
            null,
            null,
            null,
            copyingOptions.costType
          );
          segments.push(seg);
        }
        console.log(segments, 'SEGMENTS ADDED');
        break;
    }
  }

  public CreateSexCopiesData() {

  }

  private SetLoadingState() {
    this.SetState(MultipleCopyState.LOADING);
  }

  private SetState(state: MultipleCopyState) {
    this.state = state;
  }

  CloseWithStatus(status: MultipleCopyStatus = MultipleCopyStatus.NOTHING_TO_DO) {
    let link = '';
    if (status === MultipleCopyStatus.NAVIGATE_TO) {
      const [accountId, clientId] = this.form.value.targetAccountId.split('_');
      link = `/automation/ads?account_id=${accountId}&campaign_id=${this.form.value.targetCampaignId}&client_id=${clientId}`;
    }
    this.dialog.close({
      status: status,
      link
    });
  }

  public LoadCabinetData() {
    this.loadingAdvancedScreen = true;
    return this.LoadSlots()
      .flatMap(() => this.LoadAccounts())
      .flatMap(() => {
        const [accountId, clientId] = this.form.value.targetAccountId.split('_');
        return this.LoadCampaigns(accountId, clientId);
      });
  }

  public LoadSlots() {
    return this.automationVkSlotService.GetSlots()
      .do(slotsResponse => {
        this.slots = slotsResponse.data;
      });
  }

  public LoadAccounts() {
    return this.automationVkService.GetAccounts()
      .do(async accountsResponse => {
        const accounts = accountsResponse.data
          .map((cabinet, i) => this.CreateNewCabinet(cabinet, i));

        const agencies = accounts.filter(account => account.account_type === 'agency');

        for (const account of agencies) {
          const clients = await this.LoadClients(account.account_id);
          for (const client of clients) {
            accounts.push(new AccountViewModel(
              account.account_id,
              client.name,
              account.account_role,
              account.account_status,
              'client',
              this.IsAccountBlocked(account.account_id, client.id),
              client.id,
              account.priority
            ));
          }
        }

        this.accounts = accounts.filter(x => x.account_type !== 'agency');
      })
      .delay(1000);
  }

  private CreateNewCabinet(cabinet, i) {
    const {account_id, account_name, access_role, account_status, account_type, is_blocked, client_id} = cabinet;
    return new AccountViewModel(
      account_id,
      account_name,
      access_role,
      account_status,
      account_type,
      this.IsAccountBlocked(account_id, client_id),
      client_id,
      i
    );
  }

  public LoadClients(accountId) {
    return this.automationVkService.getClients(accountId)
      .map(clientsResponse => clientsResponse.data)
      .delay(1000)
      .toPromise();
  }

  public LoadCampaigns(accountId, clientId) {
    console.log(accountId);
    return this.automationVkService.GetCampaigns({
      accountId,
      clientId
    })
      .do(campaignResponse => {
        console.log('Setting Campaigns');
        console.log(campaignResponse.data);
        this.campaigns = campaignResponse.data.filter(campaign => campaign.type === this.getType())
          .sort((a, b) => b.id - a.id);

        this.form.controls.targetCampaignId.setValue(this.campaigns[0].id);
      })
      .delay(1000);
  }

  OpenCreateCampaignForm() {
    const [accountId, clientId] = this.form.value.targetAccountId.split('_');
    this.dialogService.open(CreateCampaignFormComponent, {
      data: {
        clientId,
        type: this.getType(),
        accountId
      }
    })
      .afterClosed()
      .subscribe(data => {
        const [accountId, clientId] = this.form.value.targetAccountId.split('_');
        this.LoadCampaigns(accountId, clientId)
          .subscribe(() => {

          });
      });
  }

  private getType() {
    return this.dialogData.ad_format === 9 ? 'promoted_posts' : 'normal';
  }

  private GetMaxCountOfCopiedAds(): number {
    return 100 / this.dialogData.selectedAds.length;
  }

  private IsAccountBlocked(accountId: number, clientId: number) {
    const slotWithAccount = this.slots.find(slot => slot.bindedCabinetId === accountId && slot.bindedClientId === clientId);
    console.log(`Slot for account ${accountId} and client ${clientId}: `, slotWithAccount);
    return isNullOrUndefined(slotWithAccount);
  }

  private AccountTypeIsAgency(account_type: any) {
    return account_type === 'agency';
  }

  public GetAccountValue(accountId, clientId) {
    return `${accountId}_${clientId}`;
  }

  public IsCopyingToAnotherCabinet() {
    const clientId = (this.dialogData.clientId) ? this.dialogData.clientId : 'null';
    const accountId = `${this.dialogData.accountId}_${clientId}`;
    return this.form.value.targetAccountId !== accountId;
  }

  public AddAgeSegmentation() {
    const formAges = this.form.controls.ages as FormArray;
    formAges.controls.push(new FormGroup({
      ageFrom: new FormControl(),
      ageTo: new FormControl(),
      eachAge: new FormControl(false),
      sex: new FormControl(0)
    }));
    formAges.valueChanges.subscribe(x => {
      console.log(x);
    });
    console.log(formAges.value, 'FUCKING AGES');
  }

  public RemoveAgeSegmentation(i) {
    const formAges = this.form.controls.ages as FormArray;
    formAges.removeAt(i);
  }

  GetAgeItems(control: AbstractControl, isAgeFrom: boolean) {
    const {ageFrom, ageTo} = control.value;
    console.log({ageFrom, ageTo});
    if (isAgeFrom) {
      return this.GetAgesFrom(ageTo);
    } else {
      return this.GetAgesTo(ageFrom);
    }
  }

  public GetAgesFrom(ageTo: number) {
    let ages = environment.vkAges;
    if (ageTo) {
      ages = ages.filter(age => age <= ageTo);
    }
    return ages;
  }

  public GetAgesTo(ageFrom: number) {
    let ages = environment.vkAges;
    if (ageFrom) {
      ages = ages.filter(age => age >= ageFrom);
    }
    return ages;
  }

  public IsSegmentCrossedWithExisting(ageSegment: AgeSegment, index) {
    console.log(this.AgeSegments);
    const segments = this.AgeSegments
      .filter((x, i) => i !== index)
      .map((ageSegmentItem, i) => ({
        ageIndex: i,
        ageCrossed: this.IsAgeSegmentsCrossed(ageSegment, ageSegmentItem),
        ageSegmentItem
      }));

    console.log(segments);

    return segments;
    // .filter(x => x.ageCrossed === true);
  }

  public IsAgeSegmentsCrossed(ageSegmentA: AgeSegment, ageSegmentB: AgeSegment): boolean {
    return this.IsNumberInRange(ageSegmentA.ageFrom, ageSegmentB.ageFrom, ageSegmentB.ageTo)
      || this.IsNumberInRange(ageSegmentA.ageTo, ageSegmentB.ageFrom, ageSegmentB.ageTo);
  }

  public IsNumberInRange(n: number, rangeMin: number, rangeMax: number) {
    return rangeMin <= n && n <= rangeMax;
  }

  AgeSegmentChanged({index, value}) {
    this.ages[index] = value;
  }

  public fileChanged(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.fileGroups = e.target.result.split('\n');
        console.log(this.fileGroups);
      };
      reader.readAsText(fileInput.target.files[0]);
    }
  }

  ContainsSelectedRetargetingGroups() {
    if (this.dialogData.selectedAds) {
      console.log(this.dialogData.selectedAds[0].retargeting_groups);
      return this.dialogData.selectedAds[0] && this.dialogData.selectedAds[0].ContainsRetargetingGroups;
    } else {
      return false;
    }
  }

  private PrepearRetargetingGroupsData(copyingOptions: any) {
    const [accountId, clientId] = copyingOptions.accountId.split('_');
    const [targetAccountId, targetClientId] = copyingOptions.targetAccountId.split('_');
    const selectedAd: AdViewModel = this.dialogData.selectedAds[0];
    let ads = [];
    const retargetingGroups = selectedAd.retargeting_groups.split(',');
    ads = retargetingGroups.map((retargetingGroup, i) => {
      let retargetingGroupsNot = null;
      if (copyingOptions.excludeOtherRetargetingGroups) {
        retargetingGroupsNot = retargetingGroups.filter(x => x !== retargetingGroup).join(',');
      }
      console.log(retargetingGroupsNot, 'RETARGETING GROUPS');
      return this.CreateCopyingAdItem(
        accountId,
        clientId,
        copyingOptions.campaignId,
        targetAccountId,
        targetClientId,
        copyingOptions.targetCampaignId,
        selectedAd,
        i,
        null,
        null,
        null,
        null,
        retargetingGroup,
        retargetingGroupsNot,
        copyingOptions.costType
      )
    });
    return ads;
  }

  public setCpmValidators() {
    const control = this.form.get('costValue');
    const minCpm = this.dialogData.bidRange.cpmMin / 100;
    const maxCpm = this.dialogData.bidRange.cpmMax / 100;
    console.log(minCpm, maxCpm);
    this.costValueMinMessage = `Минимальная цена 1000 просмотров равна ${minCpm} руб.`;
    this.costValueMaxMessage = `Максимальная цена 1000 просмотров равна ${maxCpm} руб.`;
    control.setValidators([Validators.required, Validators.min(minCpm), Validators.max(maxCpm)]);
    control.setValue(null);
    control.updateValueAndValidity();
    this.cd.detectChanges();
  }

  public setCpcValidators() {
    const control = this.form.get('costValue');
    const minCpc = this.dialogData.bidRange.cpcMin / 100;
    const maxCpc = this.dialogData.bidRange.cpcMax / 100;
    console.log(minCpc, maxCpc);
    this.costValueMinMessage = `Минимальная цена 1 перехода равна ${minCpc} руб.`;
    this.costValueMaxMessage = `Максимальная цена 1 перехода равна ${maxCpc} руб.`;
    control.setValidators([Validators.required, Validators.min(minCpc), Validators.max(maxCpc)]);
    control.setValue(null);
    control.updateValueAndValidity();
    this.cd.detectChanges();
  }

  public setDefaultValidators() {
    const control = this.form.get('costValue');
    control.setValidators([])
  }

  public setCostValueMessageText() {
    const costType = this.form.get('costType').value;
    if (costType === 0) {
      this.costValueLabelText = 'Введите цену 1 перехода'
    } else {
      this.costValueLabelText = 'Введите цену 1000 показов'
    }
  }
}
