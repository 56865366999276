import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-expiration-notification',
  templateUrl: './expiration-notification.component.html',
  styleUrls: ['./expiration-notification.component.scss']
})
export class ExpirationNotificationComponent implements OnInit {
  @Input() public title: string = 'Доступ в демо-режиме';
  @Input() isShowed = true;

  constructor() { }

  ngOnInit() {
  }

  hide () {
    this.isShowed = false;
  }
}
