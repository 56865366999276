import { Component, OnInit } from '@angular/core';
import {AffiliateMembersStatisticsByDateService} from "../../../../../storage/affiliate-member-statistics-by-date/state/affiliate-members-statistics-by-date.service";
import {AffiliateProgramStatisticsByDateService} from "../../services/affiliate-program-statistics-by-date.service";

@Component({
  selector: 'app-affiliate-program-statistics-by-date-route',
  templateUrl: './affiliate-program-statistics-by-date-route.component.html',
  styleUrls: ['./affiliate-program-statistics-by-date-route.component.scss']
})
export class AffiliateProgramStatisticsByDateRouteComponent implements OnInit {

  constructor(
    private service: AffiliateProgramStatisticsByDateService
  ) { }

  ngOnInit() {
    this.service.LoadStatistics();
  }

}
