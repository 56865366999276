/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./label.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./label.component";
var styles_LabelComponent = [i0.styles];
var RenderType_LabelComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LabelComponent, data: {} });
export { RenderType_LabelComponent as RenderType_LabelComponent };
function View_LabelComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "vk-label__required"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["*"]))], null, null); }
export function View_LabelComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "vk-label"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "vk-label__text"]], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LabelComponent_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.required; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_LabelComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "vk-label", [], null, null, null, View_LabelComponent_0, RenderType_LabelComponent)), i1.ɵdid(1, 114688, null, 0, i3.LabelComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LabelComponentNgFactory = i1.ɵccf("vk-label", i3.LabelComponent, View_LabelComponent_Host_0, { required: "required" }, {}, ["*"]);
export { LabelComponentNgFactory as LabelComponentNgFactory };
