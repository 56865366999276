import { Pipe, PipeTransform } from '@angular/core';
import {emojies} from '../../../assets/emojies';

@Pipe({
  name: 'postificate'
})
export class PostificatePipe implements PipeTransform {

  public emojies: Array<{ emoji: string, src: string }> = emojies;

  transform(value: string = ''): string {

    if (value && value.length > 0 && value.replace) {
      // JS new line to HTML new line
      value = value.replace(new RegExp('\n', 'g'), '<br>');

      value = this.emojify(value);
    }

    return value;
  }

  emojify(value): string {
    if (value && value.length > 0 && this.emojies && this.emojies.length > 0) {
      for (let i = 0; i < this.emojies.length; i++) {
          value = this.replaceEmoji(value, this.emojies[i].emoji, this.emojies[i].src);
      }
    }

    return value;
  }

  replaceEmoji(text: string, emojy: string, emojiSrc: string): string {
    if (text && emojy && emojiSrc) {
      text = text.replace(new RegExp(emojy, 'g'), '<img class=\'emoji\' src=\'' + emojiSrc + '.png\' alt=\'' + emojy + '\'>');
    }

    return text;
  }

}
