import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-loading-screen',
  templateUrl: './loading-screen.component.html',
  styleUrls: ['./loading-screen.component.scss']
})
export class LoadingScreenComponent implements OnInit {
  @Input() public diameter: number = 18;

  constructor() { }

  ngOnInit() {
  }

}
