import { OnInit } from '@angular/core';
import { PostificatePipe } from '../../../../../shared/pipes/postificate.pipe';
import { PromopostViewModel } from '../../../../ad-manager/models/promopost.view-model';
var PromopostComponent = /** @class */ (function () {
    function PromopostComponent() {
        this.showEditForm = false;
    }
    Object.defineProperty(PromopostComponent.prototype, "meta", {
        get: function () {
            var metaData = null;
            if (this.selectedGroup) {
                metaData = ({
                    groupName: this.selectedGroup.name,
                    avatar: this.selectedGroup.photo_50
                });
            }
            return metaData;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PromopostComponent.prototype, "linkWidget", {
        get: function () {
            return ({
                linkDomain: this.promopost.linkAddress || 'Здесь будет домен ссылки',
                linkTitle: this.promopost.linkTitle || 'Здесь будет название вашей ссылки',
                linkImage: this.promopost.image || 'assets/images/ad_size_promo.jpg'
            });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PromopostComponent.prototype, "text", {
        get: function () {
            return new PostificatePipe().transform(this.promopost.text || '');
        },
        enumerable: true,
        configurable: true
    });
    PromopostComponent.prototype.ngOnInit = function () {
    };
    return PromopostComponent;
}());
export { PromopostComponent };
