import {Injectable} from '@angular/core';
import {AppNotification, NotificationType} from '../models/Notification';

@Injectable()
export class NotificationsService {

    TIME_TO_REMOVE = 30000;

    public notifications: Map<string, AppNotification> = new Map<string, AppNotification>();

    constructor() {
    }

    public Error(title: string) {
        this.AddNotification(title, NotificationType.ERROR);
    }

    public Success(title: string) {
        this.AddNotification(title, NotificationType.SUCCESS);
    }

    public Warning(title: string) {
        this.AddNotification(title, NotificationType.WARNING);
    }

    public Info(title: string) {
        this.AddNotification(title, NotificationType.INFO);
    }

    private AddNotification(title: string, type: NotificationType) {
        const nextNotificationId = `id_${this.notifications.size}`;
        this.notifications.set(nextNotificationId, new AppNotification(title, type));
        setTimeout(() => {
            this.CloseNotification(nextNotificationId);
        }, this.TIME_TO_REMOVE);
    }

    public CloseNotification(i: string) {
        this.notifications.delete(i);
    }

}
