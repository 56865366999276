var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from '@angular/core';
import { AdManagerService, AutomationVkService, AutomationVkSlotService, IntegrationService, MyTargetService, UserIntegrationService } from '../../api/services';
import { delay, flatMap, map, tap } from 'rxjs/operators';
import { waterfall } from '../../utils/rxjs-operators/waterfall';
import { isNullOrUndefined } from 'util';
import { MockService } from './mock/mock.service';
import { forkJoin, Observable } from 'rxjs';
import * as _ from 'lodash';
import { IntegrationFormService } from '../modules/integration-form/services/integration-form.service';
import * as i0 from "@angular/core";
import * as i1 from "../../api/services/automation-vk-slot.service";
import * as i2 from "../../api/services/automation-vk.service";
import * as i3 from "./mock/mock.service";
import * as i4 from "../../api/services/ad-manager.service";
import * as i5 from "../../api/services/my-target.service";
import * as i6 from "../../api/services/integration.service";
import * as i7 from "../modules/integration-form/services/integration-form.service";
import * as i8 from "../../api/services/user-integration.service";
var StoreService = /** @class */ (function () {
    function StoreService(vkSlotService, vkService, mock, adManagerService, mtService, integrationService, integrationForm, userIntegration) {
        this.vkSlotService = vkSlotService;
        this.vkService = vkService;
        this.mock = mock;
        this.adManagerService = adManagerService;
        this.mtService = mtService;
        this.integrationService = integrationService;
        this.integrationForm = integrationForm;
        this.userIntegration = userIntegration;
        this.integrations = [];
        this.loading = false;
        this.loadingMessage = '';
    }
    Object.defineProperty(StoreService.prototype, "assignedAccounts", {
        get: function () {
            var _this = this;
            return this.slots.filter(function (x) { return x.bindedCabinetId != null; })
                .map(function (slot) { return _this.accounts.find(function (x) { return x.account_id === slot.bindedCabinetId && slot.bindedClientId === x.client_id; }); })
                .filter(function (cabinet) { return !isNullOrUndefined(cabinet); });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StoreService.prototype, "IsOriginServiceSelected", {
        get: function () {
            return this.integrationForm.form.value.leftService !== '';
        },
        enumerable: true,
        configurable: true
    });
    StoreService.prototype.ngOnInit = function () {
        var _this = this;
        console.log('INT SERVICE');
        forkJoin(this.integrationForm.leftServiceChanged, this.integrationForm.rightServiceChanged)
            .subscribe(function (_a) {
            var leftServicesCode = _a[0], rightServicesCode = _a[1];
            var relation = _this.getRealtionByServiceCodes(leftServicesCode, rightServicesCode);
            console.log('relation', relation);
            if (relation) {
                _this.integrationForm.form.patchValue({ name: relation.name });
            }
        });
    };
    StoreService.prototype.LoadVkAccountData = function () {
        var _this = this;
        return this.LoadSlots()
            .pipe(flatMap(function () { return _this.LoadAccounts(); }), flatMap(function () { return _this.LoadClients(); }));
    };
    Object.defineProperty(StoreService.prototype, "selectedFormFields", {
        //
        get: function () {
            var a = _.chain(this.formFields)
                .groupBy(function (field) { return field.key; })
                .map(function (data, item) {
                return {
                    data: data.map(function (x) { return x.groupId + "_" + x.formId; }),
                    item: item
                };
            })
                .value()
                .reduce(function (acc, _a) {
                var item = _a.item, data = _a.data;
                acc[item] = data;
                return acc;
            }, {});
            var selectedForms = this.integrationForm.formService.getControl('vk_forms', 'forms_ids').value;
            return Object.keys(a).map(function (x) { return _.isEqual(a[x], []); });
        },
        enumerable: true,
        configurable: true
    });
    StoreService.prototype.LoadSlots = function () {
        var _this = this;
        return this.vkSlotService.GetSlots()
            .pipe(tap(function (_a) {
            var data = _a.data;
            _this.slots = data;
        }), delay(1000));
    };
    StoreService.prototype.LoadServices = function () {
        var _this = this;
        return this.integrationService.GetIntegrationServices({
            allFields: false, sortFieldsAsc: true, filterOnlyActive: true
        })
            .pipe(tap(function (servicesResponse) {
            console.log(servicesResponse);
            _this.services = servicesResponse.data;
        }));
    };
    StoreService.prototype.LoadServiceRelations = function () {
        var _this = this;
        return this.integrationService
            .GetCompableIntegrations()
            .pipe(tap(function (relationResponse) {
            console.log(relationResponse);
            _this.relations = relationResponse.data;
        }));
    };
    StoreService.prototype.LoadServicesData = function () {
        var _this = this;
        return forkJoin([this.LoadServices(),
            this.LoadServiceRelations()])
            .pipe(tap(function () {
            console.log(_this.services, _this.relations, 'RELATIONS');
        }));
    };
    StoreService.prototype.LoadAccounts = function () {
        var _this = this;
        return this.vkService.GetAccounts()
            .pipe(tap(function (_a) {
            var data = _a.data;
            _this.accounts = data;
        }), delay(1000));
    };
    StoreService.prototype.LoadClients = function () {
        var _this = this;
        var agencyAccounts = this.accounts.filter(function (x) { return x.account_type === 'agency'; });
        var clients = agencyAccounts.map(function (account) { return function () { return _this.vkService.getClients(account.account_id)
            .pipe(delay(1000), tap(function (_a) {
            var data = _a.data;
            data.forEach(function (client) {
                _this.accounts.push(__assign({}, account, client));
            });
        })); }; });
        return waterfall(clients);
    };
    StoreService.prototype.LoadCampaigns = function (accountId, clientId) {
        return this.vkService.GetCampaigns({
            accountId: accountId,
            clientId: clientId
        })
            .map(function (_a) {
            var data = _a.data;
            return data.sort(function (a, b) { return b.id - a.id; });
        });
    };
    StoreService.prototype.LoadVkForms = function (accountId, clientId, campaignId) {
        var _this = this;
        return this.vkService.GetAds({
            accountId: accountId,
            clientId: clientId,
            campaignIds: [campaignId],
            adIds: [],
            limit: null,
            offset: null,
            includeDeleted: false
        })
            .pipe(delay(1000), map(function (_a) {
            var ads = _a.data;
            return ads.map(function (x) { return x.id; });
        }), flatMap(function (adIds) { return _this.vkService.GetAdsLayout({
            accountId: accountId,
            clientId: clientId,
            campaignIds: [campaignId],
            adIds: [],
            limit: null,
            offset: null,
            includeDeleted: false
        }); }), delay(1000), map(function (_a) {
            var adsLayouts = _a.data;
            return adsLayouts.map(function (x) { return x.link_url.replace('http://vk.com/wall', ''); }).join(',');
        }), flatMap(function (wallPostIds) { return _this.vkService.GetWallById(wallPostIds); }), delay(1000), map(function (_a) {
            var wallPosts = _a.data;
            return wallPosts.map(function (wallPost) { return -wallPost.owner_id; });
        }), map(function (data) { return Array.from(new Set(data)); }), map(function (groupIds) { return groupIds.map(function (groupId) { return _this.vkService.GetLeadForms(groupId).pipe(delay(1000)); }); }), map(function (formsObservables) { return formsObservables.map(function (obs) { return function () { return obs; }; }); }), flatMap(function (formsObservable) { return waterfall(formsObservable); }), tap(function (forms) {
            _this.formFields = [];
            forms.forEach(function (form) {
                form.questions.forEach(function (question) {
                    _this.AddFormField(form, question);
                });
            });
            var groupedFields = _.chain(_this.formFields)
                .groupBy(function (field) { return field.key; })
                .map(function (data, item) {
                return {
                    data: data.map(function (x) { return x.groupId + "_" + x.formId; }),
                    item: item
                };
            })
                .value()
                .reduce(function (acc, _a) {
                var item = _a.item, data = _a.data;
                acc[item] = data;
                return acc;
            }, {});
            var selectedForms = _this.integrationForm.formService.getControl('vk_forms', 'forms_ids').value;
            console.log(selectedForms, 'SELECTED FORMS');
            console.log(groupedFields, 'GROUPED');
            console.log(forms, 'LOADING VK FORMS');
        }));
    };
    StoreService.prototype.AddFormField = function (form, question) {
        var formField = this.formFields.find(function (field) { return field.key === question.key; });
        if (!formField) {
            this.formFields.push({
                groupId: form.group_id,
                formId: form.form_id,
                key: question.key,
                name: question.label,
                type: question.type
            });
        }
    };
    StoreService.prototype.LoadMtAccounts = function () {
        return this.mock.LoadMtAccounts();
    };
    StoreService.prototype.LoadMtCampaigns = function () {
        return this.mtService.GetCampaigns({
            limit: 20,
            offset: 0
        })
            .map(function (_a) {
            var data = _a.data;
            return data.map(function (x) { return ({ name: x.name, id: x.id }); });
        });
    };
    StoreService.prototype.LoadMtBanners = function (campaignIds) {
        return this.mtService.GetBanners({
            limit: 20,
            offset: 0,
            campaignIds: campaignIds
        });
    };
    StoreService.prototype.LoadMtForms = function (campaignIds, bannerIds) {
        var _this = this;
        return this.LoadMtBanners(campaignIds)
            .pipe(tap(function (data) {
            console.log(data);
        }), flatMap(function (_a) {
            var data = _a.data;
            return _this.mtService.GetLeadAdsForms({
                campaignIds: campaignIds,
                bannerIds: data.map(function (x) { return x.id; }),
                limit: 20,
                offset: 0,
            });
        }), map(function (_a) {
            var data = _a.data;
            return data.map(function (x) { return ({ name: 'Form 1', id: 1 }); });
        }));
    };
    StoreService.prototype.LoadLeadFormFields = function () {
        return this.mock.LoadFormFields();
    };
    StoreService.prototype.LoadVkRetargetingGroups = function (accountId, clientId) {
        return this.adManagerService.GetTargetingGroup({
            accountId: accountId,
            clientId: clientId
        });
    };
    StoreService.prototype.LoadMtRemarketingGroups = function (accountId) {
        return this.mock.LoadMtRemarketingGroups(accountId);
    };
    StoreService.prototype.getFormsField = function () {
        console.log(this.forms, 'FORMS_FIELDS');
    };
    StoreService.prototype.getRealtionByServiceCodes = function (leftServiceCode, rightServiceCode) {
        var leftService = this.getServiceByCode(leftServiceCode);
        var rightService = this.getServiceByCode(rightServiceCode);
        var relation = this.relations
            .find(function (relationItem) { return relationItem.integrationServiceFromId === leftService.integrationServiceId && relationItem.integrationServiceToId === rightService.integrationServiceId; });
        if (relation) {
            return relation;
        }
        else {
            throw new Error("Relation " + leftServiceCode + " and " + rightServiceCode + " not found");
        }
    };
    StoreService.prototype.getServiceByCode = function (rightServiceCode) {
        var service = this.services.find(function (service1) { return service1.code === rightServiceCode; });
        if (service) {
            return this.services.find(function (service1) { return service1.code === rightServiceCode; });
        }
        else {
            throw new Error("Service with code " + rightServiceCode + " not found");
        }
    };
    StoreService.prototype.changeUserIntegrationStatus = function (integrationId, status) {
        var _this = this;
        return this.userIntegration.ChangeUserIntegrationStatus({
            integrationId: integrationId,
            status: status
        })
            .pipe(tap(function (data) {
            _this.LoadUsersIntegrations();
        }));
    };
    /**
     * Загрузка ползовательских интеграций
     * @constructor
     */
    StoreService.prototype.LoadUsersIntegrations = function () {
        var _this = this;
        this.userIntegration.GetUserIntegrations({ showRemoved: false })
            .subscribe(function (response) {
            _this.integrations = response.data;
            console.log('Loaded user integrations', _this.integrations);
        });
    };
    /**
     * Получение название интеграции по коду платформы импорта и платформы экспорта
     * @param leftServiceName
     * @param rightServiceName
     */
    StoreService.prototype.getRelationNameByServiceCodes = function (leftServiceName, rightServiceName) {
        var relation = this.getRealtionByServiceCodes(leftServiceName, rightServiceName);
        if (relation) {
            return relation.name;
        }
        return null;
    };
    /**
     * Удаление интеграции по id
     * @param id - id интеграции в базе данных
     */
    StoreService.prototype.removeIntegration = function (id) {
        var _this = this;
        this.loading = true;
        this.loadingMessage = 'Подождите, идет удаление интеграции';
        this.userIntegration.RemoveUserIntegration(id)
            .subscribe(function () {
            _this.loading = false;
            _this.loadingMessage = '';
            _this.LoadUsersIntegrations();
        }, function (err) {
            if (err.status === 400 && err.error && err.error.description) {
                alert(err.error.description);
            }
            else {
                alert('Неизвестная ошибка! Обратитесь в службу технической поддержки.');
            }
        });
    };
    StoreService.ngInjectableDef = i0.defineInjectable({ factory: function StoreService_Factory() { return new StoreService(i0.inject(i1.AutomationVkSlotService), i0.inject(i2.AutomationVkService), i0.inject(i3.MockService), i0.inject(i4.AdManagerService), i0.inject(i5.MyTargetService), i0.inject(i6.IntegrationService), i0.inject(i7.IntegrationFormService), i0.inject(i8.UserIntegrationService)); }, token: StoreService, providedIn: "root" });
    return StoreService;
}());
export { StoreService };
