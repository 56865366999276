import {Injectable} from '@angular/core';
import {AffiliateLinksStore, AffiliateLinksState} from './affiliate-links.store';
import {HttpClient} from "@angular/common/http";
import {AffiliateLink} from "./affiliate-link.model";
import {environment} from "../../../../environments/environment";

@Injectable({providedIn: 'root'})
export class AffiliateLinksService {

  private baseUrl = `${environment.backendUri}/${environment.apiPrefix}`;

  constructor(
    protected store: AffiliateLinksStore,
    private http: HttpClient
  ) {
  }

  public LoadAffiliateLink() {
    this.http.get(`${this.baseUrl}/affiliate-links`)
      .subscribe((data: AffiliateLink) => {
        if (data) {
          this.store.set([data]);
        }
      })
  }

}
