import {Component, ContentChildren, Input, OnInit, QueryList, TemplateRef} from '@angular/core';
import {DgColumnHeaderDirective} from '../../directives/dg-column-header.directive';
import {DgContentDirective} from '../../directives/dg-content.directive';
import {EditableColumnDirective} from '../../directives/editable-column.directive';
import {SortingType} from '../../models/SortingType';
import {DgColumnFilterDirective} from "../../directives/dg-column-filter.directive";

@Component({
  selector: 'ngr-dg-column',
  templateUrl: './dg-column.component.html',
  styleUrls: ['./dg-column.component.scss']
})
export class DgColumnComponent implements OnInit {

  /**
   * Заголовок колонки
   */
  @Input() public header: string;
  @Input() public name: string;
  @Input() public align: string = 'left';
  @Input() public summaryFunction: Function;
  @Input() public formatFunction: Function;
  @Input() public pagingSummaryFunction: Function;
  @Input() public filterPlaceholderText: string;
  @Input() public filterType: string;
  @Input() public filterOptions: string;
  @Input() public type: string;
  @Input() public width: string;
  @Input() public hiddable: boolean = true;
  @Input() public hidden: boolean = false;
  @Input() public loading: boolean = false;
  @Input() public sortable: boolean = true;
  @Input() public sortingType: SortingType = SortingType.DEFAULT;
  @Input() public sortFunction: Function = null;
  @Input() public filterFunction: Function = null;

  @ContentChildren(DgContentDirective, {read: TemplateRef}) public template: QueryList<TemplateRef<any>>;
  @ContentChildren(DgColumnHeaderDirective, {read: TemplateRef}) public headerTemplate: QueryList<TemplateRef<any>>;
  @ContentChildren(EditableColumnDirective) public editTemplate: QueryList<EditableColumnDirective>;
  @ContentChildren(DgColumnFilterDirective) public filterTemplate: QueryList<DgColumnFilterDirective>;
  @Input('columnHeader') public columnHeader: TemplateRef<any>;

  constructor() {
  }

  public get displayedTemplate() {
    return this.template.first;
  }

  public get HeaderTemplate() {
    return this.headerTemplate.first;
  }

  public get EditTemplate() {
    const templates = this.editTemplate.toArray();
    if (templates.length > 0) {
      return templates[0].template;
    } else {
      return null;
    }
  }

  public get FilterTemplate() {
    return this.filterTemplate.first.template;
  }

  public IsFilterMatch() {
    return this.filterType === 'match';
  }

  public IsFilterEquals() {
    return this.filterType === 'equals';
  }

  public IsFilterBetween() {
    return this.filterType === 'between';
  }

  public ToggleHidden() {
    this.hidden = !this.hidden;
  }

  public get HasSummaryFunction() {
    return this.summaryFunction && typeof this.summaryFunction === 'function';
  }

  public get useCustomSortingFunction() {
    return this.sortFunction !== null;
  }

  public get HasPagingSummaryFunction() {
    return this.pagingSummaryFunction && typeof this.pagingSummaryFunction === 'function';
  }

  public GetPagingSummaryFunctionResult(data, fullData) {
    if (this.HasPagingSummaryFunction) {
      return this.pagingSummaryFunction(data, fullData);
    } else {
      return this.summaryFunction(data, fullData);
    }
  }

  ngOnInit() {
  }

}
