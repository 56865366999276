import * as _ from 'lodash';
import { isNullOrUndefined } from 'util';
import { AdCostTypeModes } from '../ad-cost-type-modes';
import { IncrediblesCopyType } from './incredible-copy-notification-data-view.model';
var URL_REGEXP = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
var AdViewModel = /** @class */ (function () {
    function AdViewModel() {
        this.can_be_editable = false;
        this.canBeCopied = false;
        this.count = null;
        this.auditoryPercent = null;
        this.price = 0;
        this.retargeting_groups = '';
        this.retargeting_groups_not = '';
        this.groups_active = '';
    }
    Object.defineProperty(AdViewModel.prototype, "IsActive", {
        get: function () {
            return this.status === 1;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdViewModel.prototype, "ContainsRetargetingGroups", {
        get: function () {
            return this.retargeting_groups !== '' && !isNullOrUndefined(this.retargeting_groups);
        },
        enumerable: true,
        configurable: true
    });
    AdViewModel.prototype.ConvertAd = function (ad) {
        this.id = ad.id;
        this.name = ad.name;
        this.status = ad.status;
        this.cpm = ad.cpm / 100;
        this.cpc = ad.cpc;
        this.ad_format = ad.ad_format;
        this.ad_platform = ad.ad_platform;
        this.ad_platform_no_wall = ad.ad_platform_no_wall;
        this.autobidding = ad.autobidding;
        this.campaign_id = ad.campaign_id;
        this.category1_id = ad.category1_id;
        this.category2_id = ad.category2_id;
        this.all_limit = ad.all_limit;
        this.day_limit = ad.day_limit;
        this.video = ad.video;
        this.impressions_limited = ad.impressions_limited;
        this.impressions_limit = ad.impressions_limit;
        this.events_retargeting_groups = ad.events_retargeting_groups;
        this.desclaimer_medical = ad.desclaimer_specialist;
        this.desclaimer_medical = ad.desclaimer_medical;
        this.cost_type = ad.cost_type;
        this.approved = ad.approved;
        this.disclaimer_supplements = ad.disclaimer_supplements;
        this.className = (this.approved === 3) ? 'ad_rejected' : '';
        if (ad.cost_type) {
            this.costTypeMode = AdCostTypeModes.CPM_ONLY_MODE;
            this.price = this.cpm;
        }
        else {
            this.costTypeMode = AdCostTypeModes.CPC_ONLY_MODE;
            this.price = this.cpc / 100;
        }
    };
    AdViewModel.prototype.AddStatistic = function (adStatistic) {
        var statistic = this.SumStatistic(adStatistic.stats);
        if (statistic) {
            this.impressions = statistic.impressions || 0;
            this.clicks = statistic.clicks || 0;
            this.spent = statistic.spent || 0;
            this.reach = statistic.reach;
            this.join_rate = statistic.join_rate;
            this.pricing_subscribe = this.spent / this.join_rate;
            this.ctr = (statistic.impressions > 0) ? (statistic.clicks / statistic.impressions * 100) : 0;
        }
        else {
            this.impressions = 0;
            this.clicks = 0;
            this.spent = 0;
            this.reach = 0;
            this.join_rate = 0;
            this.pricing_subscribe = 0;
            this.ctr = 0;
        }
    };
    AdViewModel.prototype.AddReach = function (adReach) {
        if (adReach) {
            this.video_views_3s = adReach.video_views_3s;
            this.video_views_25p = adReach.video_views_25p;
            this.video_views_50p = adReach.video_views_50p;
            this.video_views_75p = adReach.video_views_75p;
            this.video_views_100p = adReach.video_views_100p;
            this.video_views_start = adReach.video_views_start;
            this.hide = adReach.hide;
            this.join_group = adReach.join_group || 0;
            this.links = adReach.links;
            this.reach_subscribers = adReach.reach_subscribers;
            this.reach_total = adReach.reach_total;
            this.unsubscribe = adReach.unsubscribe;
            this.report = adReach.report;
            this.reach_not_subscribers = (adReach) ? adReach.reach_total - adReach.reach_subscribers : 0;
            this.to_group = adReach.to_group || 0;
        }
    };
    AdViewModel.prototype.AddLayout = function (adLayout, vkUrlExtractor) {
        if (adLayout) {
            this.image_src = adLayout.image_src;
            this.image_src_2x = adLayout.image_src_2x;
            this.link_domain = adLayout.link_domain;
            this.link_url = adLayout.link_url;
            this.preview_link = adLayout.preview_link;
            this.title = adLayout.title;
        }
        this.ecpc = (this.clicks > 0) ? this.spent / this.clicks : 0;
    };
    AdViewModel.prototype.AddUtms = function (vkExtractService, extractService, adWall) {
        var utm;
        if (this.ad_format === 9) {
            var ids1 = this.link_url
                .replace('https://vk.com/wall', '')
                .replace('http://vk.com/wall', '')
                .split('_')
                .map(function (adl) { return parseInt(adl, 10); });
            utm = vkExtractService.ExtractUtm(adWall);
        }
        else {
            utm = extractService.ExtractUtm(this.link_url);
        }
        this.utms = utm;
    };
    // AddWall(adWall: WallPost, buttons: Array<PostMonitorBackend_V2ModelsAdManagerEntitiesVkPromopostButton> = null) {
    AdViewModel.prototype.AddWall = function (adWall) {
        this.likes = (adWall && adWall.likes) ? adWall.likes.count : 0;
        this.reposts = (adWall && adWall.reposts) ? adWall.reposts.count : 0;
        this.comments = (adWall && adWall.comments) ? adWall.comments.count : 0;
        this.price_click = (this.clicks > 0) ? this.spent / this.clicks : 0;
        this.price_to_group = (this.to_group > 0) ? this.spent / this.to_group : 0;
        this.wall = adWall;
        if (this.ad_format === 9) {
            this.canBeCopied = this.CanBeCopied();
        }
    };
    AdViewModel.prototype.AddGroupsJoin = function () {
        var groupJoins = this.join_group || this.join_rate || 0;
        var groupJoinPrice = 0;
        if (groupJoins > 0) {
            groupJoinPrice = this.spent / groupJoins;
        }
        this.join_group = groupJoins;
        this.pricing_subscribe = groupJoinPrice;
    };
    AdViewModel.prototype.AddYandexData = function (yandexData, yandex) {
        var t = yandex.GetMetrikaValues(this.utms);
        this.yandex_reched_goals = 0;
        this.yandex_cr_from_view = 0;
        this.yandex_cr_from_click = 0;
        this.yandex_cpa = 0;
        this.yandex_page_depth = 0;
        this.yandex_average_duration = 0;
        if (t && t.length > 0 && t[0].metrics && t[0].metrics.length > 0) {
            try {
                this.yandex_reched_goals = t[0].metrics[0];
                var pageDepthMetric = t[0].metrics[1];
                var averageDurationMetric = t[0].metrics[2];
                if (pageDepthMetric && averageDurationMetric) {
                    this.yandex_page_depth = (pageDepthMetric) ? pageDepthMetric : 0;
                    this.yandex_average_duration = (averageDurationMetric) ? averageDurationMetric : 0;
                }
                // Calculate ConversionRate from view (impression) & site visit (click)
                this.yandex_cr_from_view = (this.impressions > 0) ? this.yandex_reched_goals / this.impressions * 100 : 0;
                this.yandex_cr_from_click = (this.clicks > 0) ? this.yandex_reched_goals / this.clicks * 100 : 0;
                this.yandex_cpa = (this.yandex_reched_goals > 0) ? this.spent / this.yandex_reched_goals : 0;
            }
            catch (ex) {
                console.error(ex);
            }
        }
    };
    AdViewModel.prototype.Fill = function (data) {
        var _this = this;
        if (data !== undefined) {
            Object.keys(data)
                .forEach(function (field) {
                _this[field] = data[field];
            });
        }
    };
    AdViewModel.prototype.SumStatistic = function (statistics) {
        if (statistics.length > 0) {
            return Object.keys(statistics[0]).reduce(function (a, b) {
                a[b] = _.sumBy(statistics, b);
                return a;
            }, {});
        }
        else {
            return null;
        }
    };
    AdViewModel.prototype.AddStrategy = function (Strategy) {
        this.strategy = Strategy;
    };
    AdViewModel.prototype.GetAdGroupId = function () {
        return this.wall.owner_id;
    };
    AdViewModel.prototype.GetAdAttachmentLinkTitle = function () {
        return this.wall.attachments[0].link.title;
    };
    AdViewModel.prototype.GetAdAttachmentLinkUrl = function () {
        return this.wall.attachments[0].link.url;
    };
    AdViewModel.prototype.GetTeazerVkLink = function () {
        var teazerLink = this.link_url;
        if (teazerLink.includes('vk.com')) {
            return teazerLink;
        }
        else {
            return null;
        }
    };
    AdViewModel.prototype.GetPostId = function () {
        return this.wall.id;
    };
    AdViewModel.prototype.getOwnerLink = function () {
        var link = '';
        if (this.IsPromopost()) {
            link = this.GetAdAttachmentLinkUrl();
        }
        else {
            link = this.link_url;
        }
        return link;
    };
    AdViewModel.prototype.IsPromopost = function () {
        return this.ad_format === 9;
    };
    AdViewModel.prototype.AddTargetingData = function (adTargetingData) {
        this.count = adTargetingData.count;
        this.groups = adTargetingData.groups;
        this.groups_not = adTargetingData.groups_not;
        this.groups_active = adTargetingData.groups_active;
        this.auditoryPercent = this.reach / this.count * 100;
        this.retargeting_groups = adTargetingData.retargeting_groups;
        this.retargeting_groups_not = adTargetingData.retargeting_groups_not;
        this.interestGroups = adTargetingData.interest_categories;
    };
    AdViewModel.prototype.CanBeCopied = function () {
        return !(this.IsPrettyCards() || this.IsStories() || this.IsAdaptiveFormat() || this.IsTargetingCountLess100());
    };
    AdViewModel.prototype.IsAdaptiveFormat = function () {
        var isAdaptiveFormat = this.ad_format === 11;
        if (isAdaptiveFormat) {
            this.incrediblesCopyType = IncrediblesCopyType.ADVERTISING_SITE;
        }
        return isAdaptiveFormat;
    };
    AdViewModel.prototype.HasButtons = function () {
        var hasButtons = false;
        if (this.wall && this.wall.attachments) {
            var linkAttachment = this.wall.attachments.find(function (attachment) { return attachment.type === 'link'; });
            if (linkAttachment) {
                hasButtons = !isNullOrUndefined(linkAttachment.link.button);
            }
            if (hasButtons) {
                this.incrediblesCopyType = IncrediblesCopyType.LINK_WITH_BUTTON;
            }
        }
        return hasButtons;
    };
    AdViewModel.prototype.IsPrettyCards = function () {
        var isPrettyCard = false;
        if (this.wall && this.wall.attachments) {
            var prettyCardAttachment = this.wall.attachments.find(function (attachment) { return attachment.type === PromopostAttachmentTypes.PRETTY_CARDS; });
            isPrettyCard = !isNullOrUndefined(prettyCardAttachment);
        }
        if (isPrettyCard) {
            this.incrediblesCopyType = IncrediblesCopyType.PRETTY_CARD;
        }
        return isPrettyCard;
    };
    AdViewModel.prototype.IsTargetingCountLess100 = function () {
        var isTargetingCountLess100 = this.count < 100;
        if (isTargetingCountLess100) {
            this.incrediblesCopyType = IncrediblesCopyType.AD_TARGETING_COUNT;
        }
        return isTargetingCountLess100;
    };
    AdViewModel.prototype.IsStories = function () {
        var isStories = this.ad_format === 12;
        if (isStories) {
            this.incrediblesCopyType = IncrediblesCopyType.STORIES;
        }
        return isStories;
    };
    AdViewModel.prototype.containsActiveGroups = function () {
        console.log(this.groups_active, this.groups_active !== '' && this.groups_active !== null, 'CONTAINS ACTIVE GROUPS');
        return this.groups_active !== '' && this.groups_active !== null;
    };
    AdViewModel.prototype.containsInterestGroups = function () {
        console.log(this.interestGroups, this.interestGroups !== '' && this.interestGroups !== null, 'CONTAINS INTEREST GROUPS');
        return this.interestGroups !== '' && this.interestGroups !== null;
    };
    AdViewModel.prototype.containsRetargetingGroups = function () {
        return this.retargeting_groups !== '' && this.retargeting_groups !== null;
    };
    AdViewModel.prototype.containsLinks = function () {
        return this.textContainsLinks() || this.hasLinkAttachment();
    };
    AdViewModel.prototype.textContainsLinks = function () {
        return this.wall.text.match(URL_REGEXP);
    };
    AdViewModel.prototype.hasLinkAttachment = function () {
        return this.wall && this.wall.attachments ? !isNullOrUndefined(this.wall.attachments.find(function (x) { return x.type === 'link'; })) : false;
    };
    AdViewModel.prototype.GetLinkUrl = function () {
        var link = '';
        if (this.ad_format === 9) {
            if (this.hasLinkAttachment()) {
                var linkAttachment = this.wall.attachments.find(function (x) { return x.type === 'link'; });
                if (linkAttachment && linkAttachment.link) {
                    link = linkAttachment.link.url;
                }
            }
        }
        else {
            link = this.link_url;
        }
        return link;
    };
    AdViewModel.prototype.CanGenerateLink = function () {
        return this.GetLinkUrl() !== '';
    };
    return AdViewModel;
}());
export { AdViewModel };
export var PromopostAttachmentTypes;
(function (PromopostAttachmentTypes) {
    PromopostAttachmentTypes["PRETTY_CARDS"] = "pretty_cards";
    PromopostAttachmentTypes["LINK"] = "link";
    PromopostAttachmentTypes["BUTTON"] = "button";
    PromopostAttachmentTypes["VIDEO"] = "video";
    PromopostAttachmentTypes["PHOTO"] = "photo";
})(PromopostAttachmentTypes || (PromopostAttachmentTypes = {}));
