import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'vk-hint',
  templateUrl: './hint.component.html',
  styleUrls: ['./hint.component.scss']
})
export class HintComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
