/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./update-post.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../ngr-ui/components/dynamic-form/dynamic-form.component.ngfactory";
import * as i3 from "../../../../ngr-ui/components/dynamic-form/dynamic-form.component";
import * as i4 from "@angular/common";
import * as i5 from "./update-post.component";
import * as i6 from "../../../../api/services/api-blog.service";
import * as i7 from "@angular/router";
var styles_UpdatePostComponent = [i0.styles];
var RenderType_UpdatePostComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UpdatePostComponent, data: {} });
export { RenderType_UpdatePostComponent as RenderType_UpdatePostComponent };
function View_UpdatePostComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dynamic-form", [], null, [[null, "Successed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("Successed" === en)) {
        var pd_0 = (_co.HandleSuccess($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_DynamicFormComponent_0, i2.RenderType_DynamicFormComponent)), i1.ɵdid(1, 4964352, null, 0, i3.DynamicFormComponent, [], { inputs: [0, "inputs"] }, { Successed: "Successed" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.formModel; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_UpdatePostComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_UpdatePostComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "button", [["class", "btn btn-success"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.Save() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.Loaded; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_UpdatePostComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-update-post", [], null, null, null, View_UpdatePostComponent_0, RenderType_UpdatePostComponent)), i1.ɵdid(1, 114688, null, 0, i5.UpdatePostComponent, [i6.ApiBlogService, i7.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UpdatePostComponentNgFactory = i1.ɵccf("app-update-post", i5.UpdatePostComponent, View_UpdatePostComponent_Host_0, {}, {}, []);
export { UpdatePostComponentNgFactory as UpdatePostComponentNgFactory };
