import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AutomationVkSlotService} from '../../../api/services/automation-vk-slot.service';
import {ErrorDescription} from '../../../api/models/error-description';
import {UserAutomationCabinetSlot} from '../../../api/models';
import {AccountViewModel} from '../../models/view-models/account-view-model';
import {isNullOrUndefined} from 'util';
import * as moment from 'moment';

moment.locale('ru');

@Component({
  selector: 'app-pick-list-ngr',
  templateUrl: './pick-list-ngr.component.html',
  styleUrls: ['./pick-list-ngr.component.scss']
})
export class PickListNgrComponent implements OnInit {
  /**
   * Элементы списка
   * @type {PickListItemModel[]}
   */
  @Input() public dataList: AccountViewModel[] = [];
  /**
   * Максимально возможная длинна массива с выделенными элементами списка
   * @type {number}
   */
  @Input('maxCount') public maxSelectedCount;

  /**
   * Количество свободных слотов
   * @type {number}
   */
  @Input('slots') public slots: UserAutomationCabinetSlot[];

  /**
   * Событие сохранения кабинетов
   * @type {EventEmitter<any>}
   */
  @Output() public dataListChanged: EventEmitter<any> = new EventEmitter<any>();


  /**
   * Событие закрытия окна
   * @type {EventEmitter<any>}
   */
  @Output() public onClose: EventEmitter<any> = new EventEmitter<any>();

  // /**
  //  * Выделенные элементами списка
  //  * @returns {IPickListItemModel[]}
  //  * @constructor
  //  */
  // public get SelectedItems() {
  //     return this.dataList.filter(x => x.IsSelected);
  // }

  public get Items() {
    return this.dataList.filter(x => x.account_type !== 'agency').map(x => {
      x.isSelected = this.IsAccountAssigned(x.account_id, x.client_id);
      return x;
    });
  }

  public get FreeSlots() {
    return this.slots ? this.slots.filter(x => x.bindedCabinetId === null).length : 0;
  }

  /**
   * Индикатор показывающий может ли быть выделен еще один элемент
   * @returns {boolean}
   * @constructor
   */
    // public get CanBeSelected() {
    //     return this.slots.filter(x => x.bindedCabinetId === null).length > 0;
    // }

  public error = '';

  public IsAccountAssigned(accountId, clientId) {
    const systemSlot = this.slots.find(slot => slot.bindedCabinetId === accountId && slot.bindedClientId === clientId);
    return !isNullOrUndefined(systemSlot);
  }

  constructor(
    private _service: AutomationVkSlotService
  ) {
  }

  ngOnInit() {
  }

  Change(status: boolean, data: UserAutomationCabinetSlot) {
    this.dataListChanged.emit(data);
  }

  /**
   * Сохраняет значения списка
   * @constructor
   */
  Save(): void {
    this.onClose.emit();
  }

  public GetSlotByAccountId(account_id: number): UserAutomationCabinetSlot {
    return this.slots.find(x => x.bindedCabinetId === account_id);
  }

  public GetDeactivationTimeString(account_id: number): string {
    const slot = this.GetSlotByAccountId(account_id);

    if (slot) {
      const date = moment(slot.canRebindAfter).local();

      return moment().to(date);

      //return moment(slot.canRebindAfter).local().format('MM-DD HH:mm:ss');
    } else {
      return '';
    }
  }

  public SetError(err: ErrorDescription) {
    this.error = err.description;
  }

  public CanBeSelected(account: AccountViewModel) {
    let slot: UserAutomationCabinetSlot = null;
    if (account.account_type === 'client') {
      slot = this.slots.find(x => x.bindedCabinetId === account.account_id && x.bindedClientId === account.client_id);
    } else {
      slot = this.slots.find(x => x.bindedCabinetId === account.account_id);
    }
    if (slot) {
      return (!isNullOrUndefined(slot.bindedCabinetId)) ? !slot.canRebindCabinet : this.FreeSlots === 0;
      ;
    } else {
      return this.FreeSlots === 0;
    }
  }
}
