<form [formGroup]="form">
  <div class="vk-container">
    <div class="vk-strip-wrapper vk-strip-wrapper_ad-cabinet-style">
      <label for="target-groups" class="vk-label">Название группы:</label>
      <div class="vk-strip-wrapper__item vk-strip-wrapper__item_ad-cabinet-style">
        <input id="target-groups" type="text" class="vk-input vk-input_full-width" formControlName="title" placeholder="Введите название группы">
      </div>
    </div>
  </div>

  <div class="vk-container">
    <div class="vk-strip-wrapper vk-strip-wrapper_ad-cabinet-style">
      <label for="target-groups-county" class="vk-label">
        Страна:
      </label>
      <div class="vk-strip-wrapper__item vk-strip-wrapper__item_ad-cabinet-style vk-strip-wrapper__item_wide">
        <select id="target-groups-county" class="vk-select vk-select_full-width" formControlName="countryId">
          <option value="-1">Любая</option>
          <ng-container *ngFor="let country of countries$ | async">
            <option [value]="country.id">
              {{country.title}}
            </option>
          </ng-container>
        </select>
      </div>
    </div>
  </div>

  <div class="vk-container">
    <div class="vk-strip-wrapper vk-strip-wrapper_ad-cabinet-style">
      <label for="target-groups-cities" class="vk-label">
        Город или регион:
      </label>

      <div class="vk-strip-wrapper__item vk-strip-wrapper__item_ad-cabinet-style vk-strip-wrapper__item_wide">
        <mat-form-field>
          <mat-select id="target-groups-cities" formControlName="cityId">
            <ngx-mat-select-search
              [formControl]="searchCityField"
              placeholderLabel="Введите название города"
              noEntriesFoundLabel="Городов с таким названием нет в выьранной стране"
            ></ngx-mat-select-search>
            <mat-option [value]="-1">Любой</mat-option>
            <mat-option [value]="city.id" *ngFor="let city of DisplayedCities">
              {{city.title}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="vk-container">
    <div class="groups-selector__list">
      <ng-container *ngIf="loading">
        <div class="loading-screen">
          <div class="loading-screen__spinner">
            <mat-spinner [diameter]="24"></mat-spinner>
          </div>
          <div class="loading-screen__label">
            Загрузка
          </div>
        </div>
      </ng-container>

      <div class="vk-container">
        Найдено сообществ: {{dataSource.data.length | number}}
      </div>

      <table mat-table [dataSource]="dataSource" matSort matSortStart="desc" [matSortDisableClear]="true" class="mat-elevation-z8">

        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <div class="selection">
              <mat-checkbox (change)="$event ? masterToggle() : null"
                            [checked]="selection.hasValue() && isAllSelected()"
                            [indeterminate]="selection.hasValue() && !isAllSelected()"
              >
              </mat-checkbox>
            </div>
          </th>
          <td mat-cell *matCellDef="let row">
            <div class="selection">
              <mat-checkbox (click)="$event.stopPropagation()"
                            (change)="$event ? SelectRow(row) : null"
                            [checked]="IsChecked(row)"
                            [disabled]="!CanBeSelected(row)"
              >
              </mat-checkbox>
              <!--[checked]="selection.isSelected(row)"-->
            </div>
          </td>
        </ng-container>

        <!-- Position Column -->
        <ng-container matColumnDef="avatar">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <div class="avatar">
              <img [src]="element.photo_50" alt="">
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Название</th>
          <td mat-cell *matCellDef="let element"> {{element.name | nameShorter:35}} </td>
        </ng-container>

        <ng-container matColumnDef="members_count">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="members_count">Подписчиков</th>
          <td mat-cell *matCellDef="let element"> {{element.members_count | number}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="['select', 'avatar', 'name', 'members_count'];"></tr>
        <tr mat-row *matRowDef="let row; columns: ['select', 'avatar', 'name', 'members_count'];"
            (click)="selection.toggle(row)">
        </tr>
      </table>
      <!--<ng-container *ngIf="loading; else table">-->
        <!--Загрузка ...-->
      <!--</ng-container>-->
      <!--<ng-template #table>-->
        <!--<ng-container *ngIf="dataSource.data.length <= 0; else dataFound">-->
          <!--Данные не найдены-->
        <!--</ng-container>-->
        <!--<ng-template #dataFound>-->
          <!--<div>-->
            <!--Найдено: <strong>{{dataSource.data.length}}</strong>-->
          <!--</div>-->
          <!--&lt;!&ndash;<h6>До {{maxCountOfSelectedGroups}}</h6>&ndash;&gt;-->
          <!--&lt;!&ndash;<h6>Выберите сообщества, в которых состоит ваша целевай аудитория</h6>&ndash;&gt;-->
        <!--</ng-template>-->
      <!--</ng-template>-->
      <mat-paginator
        [pageSize]="10"
        [pageSizeOptions]="[5, 10, 25, 100]"
        showFirstLastButtons></mat-paginator>
    </div>
  </div>
</form>


<!--<div class="groups-selector">-->
<!--<div class="groups-selector__form groups-selector-form">-->
<!--&lt;!&ndash;<h3>Подобрать сообщества</h3>&ndash;&gt;-->
<!--<form [formGroup]="form">-->
<!--<div class="fx-row">-->
<!--<div class="groups-selector-form__input groups-selector-form__name">-->
<!--<mat-form-field class="example-full-width">-->
<!--<mat-label>Название группы</mat-label>-->
<!--<input matInput formControlName="title">-->
<!--</mat-form-field>-->
<!--</div>-->
<!--<div class="groups-selector-form__input">-->
<!--<mat-form-field>-->
<!--<mat-label>Страна</mat-label>-->
<!--<mat-select formControlName="countryId" matNativeControl>-->
<!--<mat-option [value]="-1">Любая</mat-option>-->
<!--<mat-option [value]="country.id" *ngFor="let country of countries$ | async">{{country.title}}</mat-option>-->
<!--</mat-select>-->
<!--</mat-form-field>-->
<!--</div>-->
<!--<div class="groups-selector-form__input groups-selector-form__geo">-->
<!--<mat-form-field>-->
<!--<mat-label>Город</mat-label>-->
<!--<mat-select formControlName="cityId">-->
<!--<ngx-mat-select-search-->
<!--[formControl]="searchCityField"-->
<!--placeholderLabel="Введите название города"-->
<!--noEntriesFoundLabel="Городов с таким названием нет в выьранной стране"-->
<!--&gt;</ngx-mat-select-search>-->
<!--<mat-option [value]="-1">Любой</mat-option>-->
<!--<mat-option [value]="city.id" *ngFor="let city of cities$ | async">-->
<!--{{city.title}}-->
<!--</mat-option>-->
<!--</mat-select>-->
<!--</mat-form-field>-->
<!--</div>-->
<!--</div>-->
<!--</form>-->
<!--</div>-->
<!--<div class="groups-selector__list">-->
<!--<ng-container *ngIf="loading; else table">-->
<!--Загрузка ...-->
<!--</ng-container>-->
<!--<ng-template #table>-->
<!--<ng-container *ngIf="dataSource.data.length <= 0; else dataFound">-->
<!--Данные не найдены-->
<!--</ng-container>-->
<!--<ng-template #dataFound>-->
<!--<div>-->
<!--Найдено: <strong>{{dataSource.data.length}}</strong>-->
<!--</div>-->
<!--&lt;!&ndash;<h6>До {{maxCountOfSelectedGroups}}</h6>&ndash;&gt;-->
<!--&lt;!&ndash;<h6>Выберите сообщества, в которых состоит ваша целевай аудитория</h6>&ndash;&gt;-->
<!--<table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">-->

<!--&lt;!&ndash; Checkbox Column &ndash;&gt;-->
<!--<ng-container matColumnDef="select">-->
<!--<th mat-header-cell *matHeaderCellDef mat-sort-header>-->
<!--<div class="selection">-->
<!--<mat-checkbox (change)="$event ? masterToggle() : null"-->
<!--[checked]="selection.hasValue() && isAllSelected()"-->
<!--[indeterminate]="selection.hasValue() && !isAllSelected()"-->
<!--&gt;-->
<!--</mat-checkbox>-->
<!--</div>-->
<!--</th>-->
<!--<td mat-cell *matCellDef="let row">-->
<!--<div class="selection">-->
<!--<mat-checkbox (click)="$event.stopPropagation()"-->
<!--(change)="$event ? SelectRow(row) : null"-->
<!--[checked]="IsChecked(row)"-->
<!--[disabled]="!CanBeSelected(row)"-->
<!--&gt;-->
<!--</mat-checkbox>-->
<!--&lt;!&ndash;[checked]="selection.isSelected(row)"&ndash;&gt;-->
<!--</div>-->
<!--</td>-->
<!--</ng-container>-->

<!--&lt;!&ndash; Position Column &ndash;&gt;-->
<!--<ng-container matColumnDef="avatar">-->
<!--<th mat-header-cell *matHeaderCellDef>Аватар</th>-->
<!--<td mat-cell *matCellDef="let element">-->
<!--<div class="avatar">-->
<!--<img [src]="element.photo_50" alt="">-->
<!--</div>-->
<!--</td>-->
<!--</ng-container>-->

<!--<ng-container matColumnDef="name">-->
<!--<th mat-header-cell *matHeaderCellDef  mat-sort-header>Название</th>-->
<!--<td mat-cell *matCellDef="let element"> {{element.name}} </td>-->
<!--</ng-container>-->

<!--<ng-container matColumnDef="members_count">-->
<!--<th mat-header-cell *matHeaderCellDef mat-sort-header>Подписчиков</th>-->
<!--<td mat-cell *matCellDef="let element"> {{element.members_count | number}} </td>-->
<!--</ng-container>-->

<!--<tr mat-header-row *matHeaderRowDef="['select', 'avatar', 'name', 'members_count'];"></tr>-->
<!--<tr mat-row *matRowDef="let row; columns: ['select', 'avatar', 'name', 'members_count'];"-->
<!--(click)="selection.toggle(row)">-->
<!--</tr>-->
<!--</table>-->
<!--</ng-template>-->
<!--</ng-template>-->
<!--<mat-paginator-->
<!--[pageSize]="10"-->
<!--[pageSizeOptions]="[5, 10, 25, 100]"-->
<!--showFirstLastButtons></mat-paginator>-->
<!--&lt;!&ndash;<cdk-virtual-scroll-viewport itemSize="5" class="group">&ndash;&gt;-->
<!--&lt;!&ndash;<div *cdkVirtualFor="let group of dataSource">&ndash;&gt;-->
<!--&lt;!&ndash;<app-selectable-group [(group)]="group" (groupChange)="GroupSelected()"></app-selectable-group>&ndash;&gt;-->
<!--&lt;!&ndash;</div>&ndash;&gt;-->
<!--&lt;!&ndash;</cdk-virtual-scroll-viewport>&ndash;&gt;-->
<!--</div>-->
<!--</div>-->
