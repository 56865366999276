var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpClient } from '@angular/common/http';
import { UserBalanceStore } from './user-balance.store';
import { environment } from "../../../../environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "./user-balance.store";
import * as i2 from "@angular/common/http";
var UserBalanceService = /** @class */ (function () {
    function UserBalanceService(store, http) {
        this.store = store;
        this.http = http;
        this.baseUrl = environment.backendUri + "/" + environment.apiPrefix;
    }
    /**
     * Получение данных о балансе пользователя
     * @constructor
     */
    UserBalanceService.prototype.GetUserBalance = function () {
        var _this = this;
        this.http.get(this.baseUrl + "/user-balance")
            .subscribe(function (userBalanceInfo) {
            _this.store.update(__assign({}, userBalanceInfo));
        });
    };
    UserBalanceService.prototype.GetRegistratedTodayUsers = function () {
        this.http.get('/api/');
    };
    UserBalanceService.ngInjectableDef = i0.defineInjectable({ factory: function UserBalanceService_Factory() { return new UserBalanceService(i0.inject(i1.UserBalanceStore), i0.inject(i2.HttpClient)); }, token: UserBalanceService, providedIn: "root" });
    return UserBalanceService;
}());
export { UserBalanceService };
