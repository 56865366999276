var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { map as __map, filter as __filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../api-configuration";
import * as i2 from "@angular/common/http";
var MyTargetService = /** @class */ (function (_super) {
    __extends(MyTargetService, _super);
    function MyTargetService(config, http) {
        return _super.call(this, config, http) || this;
    }
    /**
     * @param params The `MyTargetService.GetCampaignsParams` containing the following parameters:
     *
     * - `offset`:
     *
     * - `limit`:
     *
     * - `status__ne`:
     *
     * - `status__in`:
     *
     * - `status`:
     *
     * - `sorting`:
     *
     * - `last_updated__lte`:
     *
     * - `last_updated__lt`:
     *
     * - `last_updated__gte`:
     *
     * - `last_updated__gt`:
     *
     * - `ids`:
     *
     * @return Success
     */
    MyTargetService.prototype.GetCampaignsResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.offset != null)
            __params = __params.set('offset', params.offset.toString());
        if (params.limit != null)
            __params = __params.set('limit', params.limit.toString());
        if (params.statusNe != null)
            __params = __params.set('status__ne', params.statusNe.toString());
        if (params.statusIn != null)
            __params = __params.set('status__in', params.statusIn.toString());
        if (params.status != null)
            __params = __params.set('status', params.status.toString());
        if (params.sorting != null)
            __params = __params.set('sorting', params.sorting.toString());
        if (params.lastUpdatedLte != null)
            __params = __params.set('last_updated__lte', params.lastUpdatedLte.toString());
        if (params.lastUpdatedLt != null)
            __params = __params.set('last_updated__lt', params.lastUpdatedLt.toString());
        if (params.lastUpdatedGte != null)
            __params = __params.set('last_updated__gte', params.lastUpdatedGte.toString());
        if (params.lastUpdatedGt != null)
            __params = __params.set('last_updated__gt', params.lastUpdatedGt.toString());
        (params.ids || []).forEach(function (val) { if (val != null)
            __params = __params.append('ids', val.toString()); });
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/my-target/campaigns", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `MyTargetService.GetCampaignsParams` containing the following parameters:
     *
     * - `offset`:
     *
     * - `limit`:
     *
     * - `status__ne`:
     *
     * - `status__in`:
     *
     * - `status`:
     *
     * - `sorting`:
     *
     * - `last_updated__lte`:
     *
     * - `last_updated__lt`:
     *
     * - `last_updated__gte`:
     *
     * - `last_updated__gt`:
     *
     * - `ids`:
     *
     * @return Success
     */
    MyTargetService.prototype.GetCampaigns = function (params) {
        return this.GetCampaignsResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `MyTargetService.GetBannersParams` containing the following parameters:
     *
     * - `offset`:
     *
     * - `limit`:
     *
     * - `updated__lte`:
     *
     * - `updated__lt`:
     *
     * - `updated__gte`:
     *
     * - `updated__gt`:
     *
     * - `status__ne`:
     *
     * - `status__in`:
     *
     * - `status`:
     *
     * - `ids`:
     *
     * - `campaign_status__ne`:
     *
     * - `campaign_status__in`:
     *
     * - `campaign_status`:
     *
     * - `campaignIds`:
     *
     * - `_url`:
     *
     * - `_textblock`:
     *
     * @return Success
     */
    MyTargetService.prototype.GetBannersResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.offset != null)
            __params = __params.set('offset', params.offset.toString());
        if (params.limit != null)
            __params = __params.set('limit', params.limit.toString());
        if (params.updatedLte != null)
            __params = __params.set('updated__lte', params.updatedLte.toString());
        if (params.updatedLt != null)
            __params = __params.set('updated__lt', params.updatedLt.toString());
        if (params.updatedGte != null)
            __params = __params.set('updated__gte', params.updatedGte.toString());
        if (params.updatedGt != null)
            __params = __params.set('updated__gt', params.updatedGt.toString());
        if (params.statusNe != null)
            __params = __params.set('status__ne', params.statusNe.toString());
        if (params.statusIn != null)
            __params = __params.set('status__in', params.statusIn.toString());
        if (params.status != null)
            __params = __params.set('status', params.status.toString());
        (params.ids || []).forEach(function (val) { if (val != null)
            __params = __params.append('ids', val.toString()); });
        if (params.campaignStatusNe != null)
            __params = __params.set('campaign_status__ne', params.campaignStatusNe.toString());
        if (params.campaignStatusIn != null)
            __params = __params.set('campaign_status__in', params.campaignStatusIn.toString());
        if (params.campaignStatus != null)
            __params = __params.set('campaign_status', params.campaignStatus.toString());
        (params.campaignIds || []).forEach(function (val) { if (val != null)
            __params = __params.append('campaignIds', val.toString()); });
        if (params.Url != null)
            __params = __params.set('_url', params.Url.toString());
        if (params.Textblock != null)
            __params = __params.set('_textblock', params.Textblock.toString());
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/my-target/banners", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `MyTargetService.GetBannersParams` containing the following parameters:
     *
     * - `offset`:
     *
     * - `limit`:
     *
     * - `updated__lte`:
     *
     * - `updated__lt`:
     *
     * - `updated__gte`:
     *
     * - `updated__gt`:
     *
     * - `status__ne`:
     *
     * - `status__in`:
     *
     * - `status`:
     *
     * - `ids`:
     *
     * - `campaign_status__ne`:
     *
     * - `campaign_status__in`:
     *
     * - `campaign_status`:
     *
     * - `campaignIds`:
     *
     * - `_url`:
     *
     * - `_textblock`:
     *
     * @return Success
     */
    MyTargetService.prototype.GetBanners = function (params) {
        return this.GetBannersResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `MyTargetService.GetLeadAdsFormsParams` containing the following parameters:
     *
     * - `offset`:
     *
     * - `limit`:
     *
     * - `campaignIds`:
     *
     * - `bannerIds`:
     *
     * @return Success
     */
    MyTargetService.prototype.GetLeadAdsFormsResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.offset != null)
            __params = __params.set('offset', params.offset.toString());
        if (params.limit != null)
            __params = __params.set('limit', params.limit.toString());
        (params.campaignIds || []).forEach(function (val) { if (val != null)
            __params = __params.append('campaignIds', val.toString()); });
        (params.bannerIds || []).forEach(function (val) { if (val != null)
            __params = __params.append('bannerIds', val.toString()); });
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/my-target/lead-ads-forms", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `MyTargetService.GetLeadAdsFormsParams` containing the following parameters:
     *
     * - `offset`:
     *
     * - `limit`:
     *
     * - `campaignIds`:
     *
     * - `bannerIds`:
     *
     * @return Success
     */
    MyTargetService.prototype.GetLeadAdsForms = function (params) {
        return this.GetLeadAdsFormsResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `MyTargetService.GetLeadInfoAsyncParams` containing the following parameters:
     *
     * - `offset`:
     *
     * - `limit`:
     *
     * - `formId`:
     *
     * - `campaignIds`:
     *
     * - `bannerIds`:
     *
     * @return Success
     */
    MyTargetService.prototype.GetLeadInfoAsyncResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.offset != null)
            __params = __params.set('offset', params.offset.toString());
        if (params.limit != null)
            __params = __params.set('limit', params.limit.toString());
        if (params.formId != null)
            __params = __params.set('formId', params.formId.toString());
        (params.campaignIds || []).forEach(function (val) { if (val != null)
            __params = __params.append('campaignIds', val.toString()); });
        (params.bannerIds || []).forEach(function (val) { if (val != null)
            __params = __params.append('bannerIds', val.toString()); });
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/my-target/lead-info", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `MyTargetService.GetLeadInfoAsyncParams` containing the following parameters:
     *
     * - `offset`:
     *
     * - `limit`:
     *
     * - `formId`:
     *
     * - `campaignIds`:
     *
     * - `bannerIds`:
     *
     * @return Success
     */
    MyTargetService.prototype.GetLeadInfoAsync = function (params) {
        return this.GetLeadInfoAsyncResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @return Success
     */
    MyTargetService.prototype.GetRemarketingGroupsResponse = function () {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/my-target/remarketing-groups", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @return Success
     */
    MyTargetService.prototype.GetRemarketingGroups = function () {
        return this.GetRemarketingGroupsResponse().pipe(__map(function (_r) { return _r.body; }));
    };
    MyTargetService.GetCampaignsPath = '/apiv2/my-target/campaigns';
    MyTargetService.GetBannersPath = '/apiv2/my-target/banners';
    MyTargetService.GetLeadAdsFormsPath = '/apiv2/my-target/lead-ads-forms';
    MyTargetService.GetLeadInfoAsyncPath = '/apiv2/my-target/lead-info';
    MyTargetService.GetRemarketingGroupsPath = '/apiv2/my-target/remarketing-groups';
    MyTargetService.ngInjectableDef = i0.defineInjectable({ factory: function MyTargetService_Factory() { return new MyTargetService(i0.inject(i1.ApiConfiguration), i0.inject(i2.HttpClient)); }, token: MyTargetService, providedIn: "root" });
    return MyTargetService;
}(__BaseService));
export { MyTargetService };
