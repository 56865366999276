/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PostMonitorBackend_V2ModelsMoySkladMoySkladAuthData } from '../models/post-monitor-backend-_v2models-moy-sklad-moy-sklad-auth-data';
import { PostMonitorBackend_V2ModelsMoySkladApiMoySkladResponseWrapperSystemObject } from '../models/post-monitor-backend-_v2models-moy-sklad-api-moy-sklad-response-wrapper-system-object';
import { PostMonitorBackend_V2ModelsMoySkladApiMoySkladResponseWrapperListPostMonitorBackend_V2ModelsMoySkladApiMoySkladEmployee } from '../models/post-monitor-backend-_v2models-moy-sklad-api-moy-sklad-response-wrapper-list-post-monitor-backend-_v2models-moy-sklad-api-moy-sklad-employee';
import { PostMonitorBackend_V2ModelsMoySkladApiMoySkladResponseWrapperListPostMonitorBackend_V2ModelsMoySkladApiMoySkladOrganization } from '../models/post-monitor-backend-_v2models-moy-sklad-api-moy-sklad-response-wrapper-list-post-monitor-backend-_v2models-moy-sklad-api-moy-sklad-organization';
@Injectable({
  providedIn: 'root',
})
class MoySkladService extends __BaseService {
  static readonly AuthorizeAsyncPath = '/apiv2/moy-sklad/auth';
  static readonly GetCounterpartyMetaPath = '/apiv2/moy-sklad/counterparty-meta';
  static readonly GetCustomerOrderMetaPath = '/apiv2/moy-sklad/customer-order-meta';
  static readonly GetEmployeesPath = '/apiv2/moy-sklad/employees';
  static readonly GetOrganizationsPath = '/apiv2/moy-sklad/organizations';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param credentials undefined
   */
  AuthorizeAsyncResponse(credentials?: PostMonitorBackend_V2ModelsMoySkladMoySkladAuthData): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = credentials;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/apiv2/moy-sklad/auth`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param credentials undefined
   */
  AuthorizeAsync(credentials?: PostMonitorBackend_V2ModelsMoySkladMoySkladAuthData): __Observable<null> {
    return this.AuthorizeAsyncResponse(credentials).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @return Success
   */
  GetCounterpartyMetaResponse(): __Observable<__StrictHttpResponse<PostMonitorBackend_V2ModelsMoySkladApiMoySkladResponseWrapperSystemObject>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/moy-sklad/counterparty-meta`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PostMonitorBackend_V2ModelsMoySkladApiMoySkladResponseWrapperSystemObject>;
      })
    );
  }
  /**
   * @return Success
   */
  GetCounterpartyMeta(): __Observable<PostMonitorBackend_V2ModelsMoySkladApiMoySkladResponseWrapperSystemObject> {
    return this.GetCounterpartyMetaResponse().pipe(
      __map(_r => _r.body as PostMonitorBackend_V2ModelsMoySkladApiMoySkladResponseWrapperSystemObject)
    );
  }

  /**
   * @return Success
   */
  GetCustomerOrderMetaResponse(): __Observable<__StrictHttpResponse<PostMonitorBackend_V2ModelsMoySkladApiMoySkladResponseWrapperSystemObject>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/moy-sklad/customer-order-meta`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PostMonitorBackend_V2ModelsMoySkladApiMoySkladResponseWrapperSystemObject>;
      })
    );
  }
  /**
   * @return Success
   */
  GetCustomerOrderMeta(): __Observable<PostMonitorBackend_V2ModelsMoySkladApiMoySkladResponseWrapperSystemObject> {
    return this.GetCustomerOrderMetaResponse().pipe(
      __map(_r => _r.body as PostMonitorBackend_V2ModelsMoySkladApiMoySkladResponseWrapperSystemObject)
    );
  }

  /**
   * @return Success
   */
  GetEmployeesResponse(): __Observable<__StrictHttpResponse<PostMonitorBackend_V2ModelsMoySkladApiMoySkladResponseWrapperListPostMonitorBackend_V2ModelsMoySkladApiMoySkladEmployee>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/moy-sklad/employees`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PostMonitorBackend_V2ModelsMoySkladApiMoySkladResponseWrapperListPostMonitorBackend_V2ModelsMoySkladApiMoySkladEmployee>;
      })
    );
  }
  /**
   * @return Success
   */
  GetEmployees(): __Observable<PostMonitorBackend_V2ModelsMoySkladApiMoySkladResponseWrapperListPostMonitorBackend_V2ModelsMoySkladApiMoySkladEmployee> {
    return this.GetEmployeesResponse().pipe(
      __map(_r => _r.body as PostMonitorBackend_V2ModelsMoySkladApiMoySkladResponseWrapperListPostMonitorBackend_V2ModelsMoySkladApiMoySkladEmployee)
    );
  }

  /**
   * @return Success
   */
  GetOrganizationsResponse(): __Observable<__StrictHttpResponse<PostMonitorBackend_V2ModelsMoySkladApiMoySkladResponseWrapperListPostMonitorBackend_V2ModelsMoySkladApiMoySkladOrganization>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/moy-sklad/organizations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PostMonitorBackend_V2ModelsMoySkladApiMoySkladResponseWrapperListPostMonitorBackend_V2ModelsMoySkladApiMoySkladOrganization>;
      })
    );
  }
  /**
   * @return Success
   */
  GetOrganizations(): __Observable<PostMonitorBackend_V2ModelsMoySkladApiMoySkladResponseWrapperListPostMonitorBackend_V2ModelsMoySkladApiMoySkladOrganization> {
    return this.GetOrganizationsResponse().pipe(
      __map(_r => _r.body as PostMonitorBackend_V2ModelsMoySkladApiMoySkladResponseWrapperListPostMonitorBackend_V2ModelsMoySkladApiMoySkladOrganization)
    );
  }
}

module MoySkladService {
}

export { MoySkladService }
