import {Component, forwardRef, OnInit} from '@angular/core';
import {ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';
import {TimeValidators} from '../../validators/TimeValidators';

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimePickerComponent),
      multi: true
    }
  ]
})
export class TimePickerComponent implements OnInit, ControlValueAccessor {

  public form: FormGroup;
  public onChange;

  public get timeToMinutes() {
    return this.form.value.hour * 60 + parseInt(this.form.value.minutes, 10);
  }

  constructor() {
  }

  ngOnInit() {
    this.form = new FormGroup({
      hour: new FormControl(0, [TimeValidators.hour]),
      minutes: new FormControl(0, [TimeValidators.minutes])
    });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
  }

  writeValue(obj: number): void {
    const hour = Math.floor(obj / 60);
    const minutes = obj % 60;
    this.form.setValue({
      hour: (hour < 10) ? `0${hour}` : hour,
      minutes: (minutes < 10) ? `0${minutes}` : minutes
    });
  }

  public change() {
    this.onChange(this.timeToMinutes);
  }

  public changeHour($event) {
    const hour = this.valueToNumber($event.target.value);
    this.form.setValue({
      hour: (hour > 23) ? 23 : this.formatToTime(hour),
      minutes: this.formatToTime(this.form.value.minutes)
    });
    this.change();
  }

  public changeMinutes($event) {
    const minutes = this.valueToNumber($event.target.value);
    this.form.setValue({
      hour: this.formatToTime(this.form.value.hour),
      minutes: (minutes > 59) ? 59 : this.formatToTime(minutes)
    });
    this.change();
  }

  private valueToNumber(value: string) {
    value = value.slice(-2).replace(/D/, '');
    if (value.length === 0) {
      return 0;
    } else {
      return parseInt(value, 10);
    }
  }

  private formatToTime(value) {
    value = parseInt(value, 10);
    return (value < 10) ? `0${value}` : value;
  }

}
