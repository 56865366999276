import {Injectable} from '@angular/core';
import Dexie from 'dexie';
import {CoreService} from './core.service';
import {Client} from '../../../api/models';

@Injectable()
export class DexieClientsService {

  table: Dexie.Table<Client, number>;

  constructor(
    private core: CoreService
  ) {
    this.table = core.table('clients');
  }

  public SetClients(clients) {
    this.table.clear();
    return this.AddClients(clients);
  }

  public AddClient(client) {
    return this.table.put(client);
  }

  public GetClientById(client_id) {
    console.log(client_id, 'CLIENT');
    return this.table.where('id').equals(client_id).first();
  }

  public AddClients(clients: Client[]) {
    return Promise.all(clients.map(client => {
      return this.AddClient(client);
    }));
  }
}
