import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';
import {
  IncredibleCopyNotificationDataViewModel,
  IncrediblesCopyType
} from '../../../models/view-models/incredible-copy-notification-data-view.model';

@Component({
  selector: 'app-incredible-copy-notification',
  templateUrl: './incredible-copy-notification.component.html',
  styleUrls: ['./incredible-copy-notification.component.scss']
})
export class IncredibleCopyNotificationComponent implements OnInit {

  public typesOfIncredibleToCopyAds = IncrediblesCopyType;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IncredibleCopyNotificationDataViewModel,
    public dialog: MatDialogRef<IncredibleCopyNotificationComponent>
  ) {
  }

  ngOnInit() {
  }

  CancelCopying() {
    this.dialog.close(false);
  }

  StartCopying() {
    this.dialog.close(true);
  }

  public ContainsInIncredibleTypes(incrediblesCopyType: IncrediblesCopyType) {
    return {
      'incredible-list__item_bold': this.data.incredibleToCopyTypes.includes(incrediblesCopyType)
    };
  }
}
