/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./counter-register-goal-proxy.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./counter-register-goal-proxy.component";
import * as i3 from "@angular/router";
var styles_CounterRegisterGoalProxyComponent = [i0.styles];
var RenderType_CounterRegisterGoalProxyComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CounterRegisterGoalProxyComponent, data: {} });
export { RenderType_CounterRegisterGoalProxyComponent as RenderType_CounterRegisterGoalProxyComponent };
export function View_CounterRegisterGoalProxyComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u0412\u044B\u043F\u043E\u043B\u043D\u044F\u0435\u0442\u0441\u044F \u043F\u0435\u0440\u0435\u043D\u0430\u043F\u0440\u0430\u0432\u043B\u0435\u043D\u0438\u0435...\n"]))], null, null); }
export function View_CounterRegisterGoalProxyComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-counter-register-goal-proxy", [], null, null, null, View_CounterRegisterGoalProxyComponent_0, RenderType_CounterRegisterGoalProxyComponent)), i1.ɵdid(1, 4243456, null, 0, i2.CounterRegisterGoalProxyComponent, [i3.ActivatedRoute, i3.Router, i1.ElementRef], null, null)], null, null); }
var CounterRegisterGoalProxyComponentNgFactory = i1.ɵccf("app-counter-register-goal-proxy", i2.CounterRegisterGoalProxyComponent, View_CounterRegisterGoalProxyComponent_Host_0, {}, {}, []);
export { CounterRegisterGoalProxyComponentNgFactory as CounterRegisterGoalProxyComponentNgFactory };
