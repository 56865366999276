<!--<div class="card">-->
<!--<div class="card-header">-->
<!--<div class="card-title">Регистрация</div>-->
<!--</div>-->
<!--<div class="card-block">-->
<!--<adForm [formGroup]="adForm">-->
<!--<div class="adForm-block">-->
<!--<label for="email">Email</label>-->
<!--<div class="adForm-group">-->
<!--<input type="email" class="adForm-control" name="email" id="email" formControlName="email">-->
<!--</div>-->
<!--</div>-->
<!--<div class="adForm-block">-->
<!--<label for="password">Password</label>-->
<!--<div class="adForm-group">-->
<!--<input type="password" class="adForm-control" name="password" id="password"-->
<!--formControlName="password">-->
<!--</div>-->
<!--</div>-->
<!--<div class="adForm-block">-->
<!--<label for="password">Confirm password</label>-->
<!--<div class="adForm-group">-->
<!--<input type="password" class="adForm-control" name="confirmPassword" id="confirmPassword"-->
<!--formControlName="confirmPassword">-->
<!--</div>-->
<!--</div>-->
<!--<button class="btn btn-primary" (click)="submitForm()">Зарегистрироваться</button>-->
<!--</adForm>-->
<!--</div>-->
<!--</div>-->

<ng-container *ngIf="!loading; else loadingScreen">
  <form [formGroup]="form" (submit)="submit($event)">
    <div class="title">
      <h3 class="welcome">Регистрация</h3>
    </div>
    <ng-container *ngIf="messages">
      <div class="alert alert-success">
        <div class="alert-items">
          <div class="alert-item static">
            <div class="alert-icon-wrapper">
              <clr-icon class="alert-icon" shape="check-circle"></clr-icon>
            </div>
            <span class="alert-text">
                        На указанный Вами адрес было отправлено письмо с ссылкой для подтверждения e-mail. Для того, чтобы начать пользоваться сервисом,
                        пожалуйста,перейдите по этой <a routerLink="/sign-in">ссылке</a>
                    </span>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="login-group" *ngIf="!messages">
      <ng-container *ngIf="Errors?.system">
        <div class="error active" *ngFor="let error of Errors.system">{{error}}</div>
      </ng-container>
      <ng-container *ngIf="formSubmited && Errors?.name">
        <div class="error active" *ngFor="let error of Errors.name">
          {{error}}
        </div>
      </ng-container>
      <div class="fx-row">
        <div class="required">*</div>
        <input class="username" type="text" id="user_name" placeholder="Ваше имя" formControlName="name">
      </div>

      <ng-container *ngIf="formSubmited && Errors?.email">
        <div class="error active" *ngFor="let error of Errors.email">
          {{error}}
        </div>
      </ng-container>
      <div class="fx-row">
        <div class="required">*</div>
        <input class="username" type="text" id="login_username" placeholder="Введите e-mail" formControlName="email">
      </div>

      <ng-container *ngIf="formSubmited && Errors?.phone">
        <div class="error active" *ngFor="let error of Errors.phone">
          {{error}}
        </div>
      </ng-container>
      <div class="fx-row">
        <div class="required">*</div>
        <input class="username" type="text" id="phonenumber" placeholder="Номер телефона (+7 904 074 25 53)" formControlName="phone">
      </div>
      <ng-container *ngIf="formSubmited && Errors?.password">
        <div class="error active" *ngFor="let error of Errors.password">
          {{error}}
        </div>
      </ng-container>
      <div class="fx-row">
        <div class="required">*</div>
        <input class="password" type="password" id="login_password" placeholder="Введите пароль"
               formControlName="password">
      </div>
      <ng-container *ngIf="formSubmited && Errors?.confirmPassword">
        <div class="error active" *ngFor="let error of Errors.password">
          {{error}}
        </div>
      </ng-container>
      <div class="fx-row">
        <div class="required">*</div>
        <input class="password" type="password" id="confirmPassword" placeholder="Повторите пароль"
               formControlName="confirmPassword">
      </div>
      <!--<div>-->
      <!--<label for="occupation">Выбирите ваш род деятельности</label>-->
      <!--<div class="select">-->
      <!--<select name="" id="occupation" formControlName="occupationId">-->
      <!--<option [value]="occupation.id" *ngFor="let occupation of occupations">{{occupation.title}}</option>-->
      <!--</select>-->
      <!--</div>-->
      <!--</div>-->
      <br>
      <mat-checkbox [(ngModel)]="accepted" [ngModelOptions]="{standalone: true}">
        Согласен с <a routerLink="/privacy">политикой конфиденциальности</a> и
        <br><a href="/assets/oferta.pdf" target="_blank" rel="noopener nofollow">договором оферты</a>
      </mat-checkbox>
      <button type="submit" class="btn btn-primary" [disabled]="!accepted">Зарегистрироваться</button>
    </div>
    <a routerLink="/sign-in" class="signup">Войти</a>
  </form>
</ng-container>
<ng-template #loadingScreen>
  <div class="loading-screen">
    <div class="spinner"></div>
  </div>
</ng-template>

<ng-container *ngIf="showExternalLogins">
  <app-external-login-form></app-external-login-form>
</ng-container>
