// Defines actual sorting way
export class SortingInstance {
  public sortBy: string;
  public sortDescend: boolean;

  constructor(sortBy: string, sortDescend: boolean) {
    this.sortBy = sortBy;
    this.sortDescend = sortDescend;
  }
}
