<form [formGroup]="form">
  <div class="form-block" [ngClass]="{
    'invalid': form.controls[input.name].invalid && form.controls[input.name].dirty
  }" *ngFor="let input of InputFields">

    <label [attr.for]="input.name">
      <ng-container *ngIf="input.required">
        <span class="input-required">*</span>
      </ng-container>
      {{input.label}}
      <clr-signpost *ngIf="input.description">
        <clr-signpost-content>
          <div class="field-required-label">
            <ng-container *ngIf="input.required; else notRequired">Обязательное поле</ng-container>
            <ng-template #notRequired>Необязательное поле</ng-template>
          </div>
          <div>
            {{input.description}}
          </div>
        </clr-signpost-content>
      </clr-signpost>
    </label>
    <ng-container [ngSwitch]="input.type">
      <ng-container *ngSwitchCase="'time_of_day_in_minutes'">
        <app-time-picker [formControlName]="input.name"></app-time-picker>
      </ng-container>
      <ng-container *ngSwitchCase="'select'">
        <div class="select">
          <select
            [name]="input.name"
            [id]="input.name"
            [formControlName]="input.name"
          >
            <option
              *ngFor="let option of input.options"
              [value]="GetSelectOptionNumber(option.value)"
              [selected]="IsOptionSelected(option.value, input.name)"
            >
              {{option.title}}
            </option>
          </select>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="'html'">
        <ckeditor
          [formControlName]="input.name"
          [config]="input.config"
          (fileUploadRequest)="input.config.onUploadRequest($event)"
          (fileUploadResponse)="input.config.onUploadResponse($event)"
        ></ckeditor>
      </ng-container>
      <ng-container *ngSwitchCase="'datetimepicker'">
        <app-datetime-picker [formControlName]="input.name"></app-datetime-picker>
      </ng-container>
      <ng-container *ngSwitchCase="'textarea'">
        <textarea
          [name]="input.name"
          [formControlName]="input.name"
          [id]="input.name"
          [attr.placeholder]="input.placeholder"
          class="form-input"
        >
        </textarea>
      </ng-container>
      <ng-container *ngSwitchCase="'checkbox-list'">
        <div>
          <div >
            <label [formArrayName]="input.name" *ngFor="let item of form.controls[input.name].controls; let i = index">
              <input
                type="checkbox"
                [value]="input.options[i]"
                [formControlName]="i"
                [checked]="CheckedCheckbox(input, input.options[i])"
              >
              {{input.options[i].title}}
            </label>
          </div>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="'number'">
        <div class="">
          <input
            [type]="'number'"
            [id]="input.name"
            [attr.placeholder]="input.placeholder"
            [formControlName]="input.name"
            step="0"
            class="form-input"
          >
        </div>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <div class="">
          <input
            [type]="input.type"
            [id]="input.name"
            [attr.placeholder]="input.placeholder"
            [formControlName]="input.name"
            step="0"
            class="form-input"
          >
        </div>
      </ng-container>

    </ng-container>
    <div class="input__errors">
      <div class="input__error" *ngFor="let error of GetErrors(input.name)">{{error}}</div>
    </div>
  </div>
  <div class="fx-row">
    <button class="btn btn-block btn-primary" *ngIf="OkActionText" (click)="SuccessAction()" [disabled]="form.invalid">{{OkActionText}}</button>
    <button class="btn btn-block btn-danger" *ngIf="CancelActionText" (click)="CancelAction()">{{CancelActionText}}
    </button>
  </div>
</form>
