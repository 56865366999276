import {Component, OnInit} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {AutomationVkService} from '../../../api/services/automation-vk.service';
import {ExpirationManagerService} from '../../../automation/services/expiration-manager.service';
import {AutomationExpirationManagerService} from '../../../automation/services/automation-expiration-manager.service';
import {AdblockService} from '../../../automation/services/adblock.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-automation-wrapper',
  templateUrl: './automation-wrapper.component.html',
  styleUrls: ['./automation-wrapper.component.scss']
})
export class  AutomationWrapperComponent implements OnInit {
  public baseUrl = environment.frontEndUrl;
  public cabinetsChecked: boolean = false;
  public cabinetsBinded = false;
  public ShowCreateButton: boolean = true;

  constructor(
    private api: AutomationVkService,
    public expirationManager: ExpirationManagerService,
    public accessManager: AutomationExpirationManagerService,
    public adBlock: AdblockService,
    private route: ActivatedRoute
  ) {
  }

  public get AdBlockEnabled() {
    return this.adBlock.AdBlockEnabled;
  }

  ngOnInit() {
    this.CheckCabinetsBinded();
    this.ShowCreateButton = this.route.snapshot.queryParams['show'] === 'true';
  }

  public AddVkAdCabinetLinkClick(): void {
    const route = this.route.snapshot['_routerState'].url;
    console.log('Proceeding to OAuth binding, remebering current url', route);
    localStorage.setItem('bind-return-to', route);

    window.location.href = this.GetBindUrl();
  }

  public GetBindUrl(): string {
    return `${this.baseUrl}/apiv2/OAuth/authorize/vk-ad-cabinet`;
  }

  private CheckCabinetsBinded(): void {
    this.cabinetsChecked = false;
    this.api.CheckCabinetsBinded()
      .subscribe(data => {
          this.cabinetsChecked = true;
          this.cabinetsBinded = true;
        },
        err => {
          this.cabinetsChecked = true;
          this.cabinetsBinded = false;
        });
  }

  public ShowHeaderCard(): boolean {
    const route = this.route.snapshot['_routerState'];

    if (route) {
      const url = route.url;

      if (url) {
        return !url.includes('ad-manager') && !url.includes('dashboard');
      }
    }

    return true;
  }
}
