import {Injectable} from '@angular/core';
import {QueryEntity} from '@datorama/akita';
import {UserIntegrationState, UserIntegrationStore} from './user-integration.store';
import {tap} from "rxjs/operators";

@Injectable({providedIn: 'root'})
export class UserIntegrationQuery extends QueryEntity<UserIntegrationState> {

  public userIntegrations$ = this.selectAll()
    .pipe(
      tap(x => {
        console.log(x);
      })
    );

  constructor(protected store: UserIntegrationStore) {
    super(store);
  }

}
