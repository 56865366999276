import { OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CrmPipelinesQuery } from '../../../../stores/crm-pipelines/crm-pipelines.query';
import { CrmPipelinesService } from '../../../../stores/crm-pipelines/crm-pipelines.service';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { CrmUsersService } from '../../../../stores/crm-users/crm-users.service';
import { CrmUsersQuery } from '../../../../stores/crm-users/crm-users.query';
import { AkitaNgFormsManager } from '@datorama/akita-ng-forms-manager';
import { CrmOrganisationQuery } from '../../../../stores/crm-organisation/crm-organisation.query';
import { PlatformsQuery } from '../../../../stores/platforms/platforms.query';
import { CustomFieldsQuery } from "../../../../stores/custom-fields/custom-fields.query";
var ImportCrmSectionSettingsComponent = /** @class */ (function () {
    function ImportCrmSectionSettingsComponent(pipelinesQuery, pipelinesService, crmUsersService, crmUsersQuery, crmOrganisationQuery, formManager, platformQuery, customFieldsQuery) {
        this.pipelinesQuery = pipelinesQuery;
        this.pipelinesService = pipelinesService;
        this.crmUsersService = crmUsersService;
        this.crmUsersQuery = crmUsersQuery;
        this.crmOrganisationQuery = crmOrganisationQuery;
        this.formManager = formManager;
        this.platformQuery = platformQuery;
        this.customFieldsQuery = customFieldsQuery;
        this.showAdditionalFields = false;
        this.pipelines$ = this.pipelinesQuery.pipelines$
            .pipe(map(function (pipelines) { return pipelines.filter(function (pipeline) { return pipeline.name !== 'PM_SYSTEM_PIPELINE'; }); }));
        this.users$ = this.crmUsersQuery.users$;
        this.firstPipelineStatuses$ = this.pipelinesQuery.pipelines$.pipe(filter(function (x) { return x.length > 0; }), map(function (x) { return x[0].statuses; }));
        this.opened = false;
        this.organisations$ = this.crmOrganisationQuery.organisations$;
    }
    Object.defineProperty(ImportCrmSectionSettingsComponent.prototype, "sectionStages", {
        get: function () {
            var pipeline = this.section ? this.pipelinesQuery.GetPipelineBySectionCode(this.section.code, this.pipelineId) : null;
            return pipeline ? pipeline.statuses : [];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ImportCrmSectionSettingsComponent.prototype, "pipelineId", {
        get: function () {
            var pipelineId = null;
            if (this.form && this.form.get('fields') && this.form.get('fields').get('PM_PIPELINE_ID')) {
                pipelineId = this.form.get('fields').get('PM_PIPELINE_ID').value;
            }
            return pipelineId;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ImportCrmSectionSettingsComponent.prototype, "CustomFields", {
        get: function () {
            return this.customFieldsQuery.CustomFields;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ImportCrmSectionSettingsComponent.prototype, "sectionSuggestionCode", {
        get: function () {
            switch (this.platformQuery.selectedDestinationPlatformCode) {
                case 'MOY_SKLAD_IMPORT':
                    return 'CRM_MOY_SKLAD_' + this.section.code;
                default:
                    return 'CRM_' + this.section.code;
            }
        },
        enumerable: true,
        configurable: true
    });
    ImportCrmSectionSettingsComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.form && this.form.get('fields') && this.form.get('fields').get('PM_PIPELINE_ID') && this.form.get('fields').get('PM_STAGE_ID')) {
            this.form.get('fields').get('PM_PIPELINE_ID').valueChanges
                .pipe(distinctUntilChanged())
                .subscribe(function () {
                _this.form.get('fields').get('PM_STAGE_ID').setValue(null);
            });
        }
    };
    ImportCrmSectionSettingsComponent.prototype.ToggleSettings = function () {
        this.opened = !this.opened;
    };
    ImportCrmSectionSettingsComponent.prototype.isPipeline = function (field) {
        return field.code === 'PIPELINE_ID';
    };
    return ImportCrmSectionSettingsComponent;
}());
export { ImportCrmSectionSettingsComponent };
