<div class="conversation-list">
  <div class="conversation-list__item">
    <div class="conversation-container">
      <ng-container *ngIf="conversationWithUserId">
        <app-chat-control-panel
          [conversationWithUserId]="conversationWithUserId"
          (onSendNotificationOverEmailChange)="sendNotificationOverEmail = $event;"
        ></app-chat-control-panel>
      </ng-container>
      <app-chat-form [sendNotificationOverEmail]="sendNotificationOverEmail" [conversationWithUserId]="conversationWithUserId"></app-chat-form>
    </div>
  </div>

  <ng-container *ngIf="conversationWithUserId">
    <div class="conversation-list__item">
      <div class="conversation-container">
        <div class="vk-container vk-container_clickable" (click)="featureBanExpand = !featureBanExpand">
          <div class="vk-ad-cabinet-header">
            <i [ngClass]="GetIconClassname(featureBanExpand)"></i> Блокировка чата у пользователя
          </div>
        </div>

        <ng-container *ngIf="featureBanExpand">
          <ng-container *ngIf="conversationMessagesService.isBanned; else banned">
            <div class="vk-container">
              <div class="vk-error">
                Пользователь уже заблокирован
              </div>
            </div>
          </ng-container>
          <ng-template #banned>
            <div class="vk-container">
              <div class="tip-field">
                Заблокированные пользователи не могут отправять и получать сообщения.
              </div>
            </div>

            <ng-container *ngIf="!featureBanRequireConfirmation; else banRequireConfirm">
              <div class="vk-container">
                <div class="vk-strip-wrapper">
                  <div class="vk-strip-wrapper__item vk-strip-wrapper__item_wide">
                    <textarea id="feature-block-user" [(ngModel)]="featureBanReason" rows="1" class="vk-textarea" placeholder="Напишите причину блокировки (будет отображена у пользователя)." minlength="5" maxlength="1024"></textarea>
                  </div>

                  <div class="vk-strip-wrapper__item">
                    <button class="vk-standart-button" (click)="featureBanRequireConfirmation = true" [disabled]="!featureBanReason || featureBanReason.length < 5">Заблокировать</button>
                  </div>
                </div>
              </div>
            </ng-container>

            <ng-template #banRequireConfirm>
              <div class="vk-container">
                <div class="vk-strip-wrapper">
                  <div class="vk-strip-wrapper__item vk-strip-wrapper__item_wide">
                    Вы уверены, что хотите заблокировать пользователя?
                  </div>

                  <div class="vk-strip-wrapper__item">
                    <button class="vk-standart-button" (click)="BanUser()">Подтвердить</button>
                  </div>
                  <div class="vk-strip-wrapper__item">
                    <button class="vk-standart-button vk-standart-button_cancel" (click)="featureBanRequireConfirmation = false">Отмена</button>
                  </div>
                </div>
              </div>
            </ng-template>
          </ng-template>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>

