/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
class ChatService extends __BaseService {
  static readonly Apiv2ChatOnlinePostPath = '/apiv2/chat/online';
  static readonly Apiv2ChatConversationsUpdatesGetPath = '/apiv2/chat/conversations-updates';
  static readonly Apiv2ChatConversationsGetPath = '/apiv2/chat/conversations';
  static readonly Apiv2ChatConversationsDeletePath = '/apiv2/chat/conversations';
  static readonly Apiv2ChatMessagesGetPath = '/apiv2/chat/messages';
  static readonly Apiv2ChatMessagesPostPath = '/apiv2/chat/messages';
  static readonly Apiv2ChatLatestMessagesGetPath = '/apiv2/chat/latest-messages';
  static readonly Apiv2ChatMessageDeletePath = '/apiv2/chat/message';
  static readonly Apiv2ChatUnreadGetPath = '/apiv2/chat/unread';
  static readonly Apiv2ChatMarkGetPath = '/apiv2/chat/mark';
  static readonly Apiv2ChatMarkPostPath = '/apiv2/chat/mark';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }
  Apiv2ChatOnlinePostResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/apiv2/chat/online`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }  Apiv2ChatOnlinePost(): __Observable<null> {
    return this.Apiv2ChatOnlinePostResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ChatService.Apiv2ChatConversationsUpdatesGetParams` containing the following parameters:
   *
   * - `updatesFrom`:
   *
   * - `onlyReadUpdates`:
   *
   * - `userId`:
   */
  Apiv2ChatConversationsUpdatesGetResponse(params: ChatService.Apiv2ChatConversationsUpdatesGetParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.updatesFrom != null) __params = __params.set('updatesFrom', params.updatesFrom.toString());
    if (params.onlyReadUpdates != null) __params = __params.set('onlyReadUpdates', params.onlyReadUpdates.toString());
    if (params.userId != null) __params = __params.set('userId', params.userId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/chat/conversations-updates`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ChatService.Apiv2ChatConversationsUpdatesGetParams` containing the following parameters:
   *
   * - `updatesFrom`:
   *
   * - `onlyReadUpdates`:
   *
   * - `userId`:
   */
  Apiv2ChatConversationsUpdatesGet(params: ChatService.Apiv2ChatConversationsUpdatesGetParams): __Observable<null> {
    return this.Apiv2ChatConversationsUpdatesGetResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ChatService.Apiv2ChatConversationsGetParams` containing the following parameters:
   *
   * - `unAsnweredFirst`:
   *
   * - `offset`:
   *
   * - `markFilterId`:
   *
   * - `userId`:
   *
   * - `limit`:
   *
   * - `filterMode`:
   */
  Apiv2ChatConversationsGetResponse(params: ChatService.Apiv2ChatConversationsGetParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.unAsnweredFirst != null) __params = __params.set('unAsnweredFirst', params.unAsnweredFirst.toString());
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.markFilterId != null) __params = __params.set('markFilterId', params.markFilterId.toString());
    if (params.userId != null) __params = __params.set('userId', params.userId.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    if (params.filterMode != null) __params = __params.set('filterMode', params.filterMode.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/chat/conversations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ChatService.Apiv2ChatConversationsGetParams` containing the following parameters:
   *
   * - `unAsnweredFirst`:
   *
   * - `offset`:
   *
   * - `markFilterId`:
   *
   * - `userId`:
   *
   * - `limit`:
   *
   * - `filterMode`:
   */
  Apiv2ChatConversationsGet(params: ChatService.Apiv2ChatConversationsGetParams): __Observable<null> {
    return this.Apiv2ChatConversationsGetResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ChatService.Apiv2ChatConversationsDeleteParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `reason`:
   */
  Apiv2ChatConversationsDeleteResponse(params: ChatService.Apiv2ChatConversationsDeleteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.userId != null) __params = __params.set('userId', params.userId.toString());
    if (params.reason != null) __params = __params.set('reason', params.reason.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/apiv2/chat/conversations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ChatService.Apiv2ChatConversationsDeleteParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `reason`:
   */
  Apiv2ChatConversationsDelete(params: ChatService.Apiv2ChatConversationsDeleteParams): __Observable<null> {
    return this.Apiv2ChatConversationsDeleteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ChatService.Apiv2ChatMessagesGetParams` containing the following parameters:
   *
   * - `offset`:
   *
   * - `includeRemoved`:
   *
   * - `userId`:
   *
   * - `limit`:
   */
  Apiv2ChatMessagesGetResponse(params: ChatService.Apiv2ChatMessagesGetParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.includeRemoved != null) __params = __params.set('includeRemoved', params.includeRemoved.toString());
    if (params.userId != null) __params = __params.set('userId', params.userId.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/chat/messages`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ChatService.Apiv2ChatMessagesGetParams` containing the following parameters:
   *
   * - `offset`:
   *
   * - `includeRemoved`:
   *
   * - `userId`:
   *
   * - `limit`:
   */
  Apiv2ChatMessagesGet(params: ChatService.Apiv2ChatMessagesGetParams): __Observable<null> {
    return this.Apiv2ChatMessagesGetResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ChatService.Apiv2ChatMessagesPostParams` containing the following parameters:
   *
   * - `sendNotificationOverEmail`:
   *
   * - `userId`:
   *
   * - `text`:
   */
  Apiv2ChatMessagesPostResponse(params: ChatService.Apiv2ChatMessagesPostParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sendNotificationOverEmail != null) __params = __params.set('sendNotificationOverEmail', params.sendNotificationOverEmail.toString());
    if (params.userId != null) __params = __params.set('userId', params.userId.toString());
    __body = params.text;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/apiv2/chat/messages`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ChatService.Apiv2ChatMessagesPostParams` containing the following parameters:
   *
   * - `sendNotificationOverEmail`:
   *
   * - `userId`:
   *
   * - `text`:
   */
  Apiv2ChatMessagesPost(params: ChatService.Apiv2ChatMessagesPostParams): __Observable<null> {
    return this.Apiv2ChatMessagesPostResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ChatService.Apiv2ChatLatestMessagesGetParams` containing the following parameters:
   *
   * - `lastMessageId`:
   *
   * - `userId`:
   */
  Apiv2ChatLatestMessagesGetResponse(params: ChatService.Apiv2ChatLatestMessagesGetParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.lastMessageId != null) __params = __params.set('lastMessageId', params.lastMessageId.toString());
    if (params.userId != null) __params = __params.set('userId', params.userId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/chat/latest-messages`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ChatService.Apiv2ChatLatestMessagesGetParams` containing the following parameters:
   *
   * - `lastMessageId`:
   *
   * - `userId`:
   */
  Apiv2ChatLatestMessagesGet(params: ChatService.Apiv2ChatLatestMessagesGetParams): __Observable<null> {
    return this.Apiv2ChatLatestMessagesGetResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ChatService.Apiv2ChatMessageDeleteParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `messageId`:
   */
  Apiv2ChatMessageDeleteResponse(params: ChatService.Apiv2ChatMessageDeleteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.userId != null) __params = __params.set('userId', params.userId.toString());
    if (params.messageId != null) __params = __params.set('messageId', params.messageId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/apiv2/chat/message`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ChatService.Apiv2ChatMessageDeleteParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `messageId`:
   */
  Apiv2ChatMessageDelete(params: ChatService.Apiv2ChatMessageDeleteParams): __Observable<null> {
    return this.Apiv2ChatMessageDeleteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
  Apiv2ChatUnreadGetResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/chat/unread`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }  Apiv2ChatUnreadGet(): __Observable<null> {
    return this.Apiv2ChatUnreadGetResponse().pipe(
      __map(_r => _r.body as null)
    );
  }
  Apiv2ChatMarkGetResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/chat/mark`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }  Apiv2ChatMarkGet(): __Observable<null> {
    return this.Apiv2ChatMarkGetResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ChatService.Apiv2ChatMarkPostParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `markId`:
   */
  Apiv2ChatMarkPostResponse(params: ChatService.Apiv2ChatMarkPostParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.userId != null) __params = __params.set('userId', params.userId.toString());
    if (params.markId != null) __params = __params.set('markId', params.markId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/apiv2/chat/mark`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ChatService.Apiv2ChatMarkPostParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `markId`:
   */
  Apiv2ChatMarkPost(params: ChatService.Apiv2ChatMarkPostParams): __Observable<null> {
    return this.Apiv2ChatMarkPostResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module ChatService {

  /**
   * Parameters for Apiv2ChatConversationsUpdatesGet
   */
  export interface Apiv2ChatConversationsUpdatesGetParams {
    updatesFrom: number;
    onlyReadUpdates: boolean;
    userId?: number;
  }

  /**
   * Parameters for Apiv2ChatConversationsGet
   */
  export interface Apiv2ChatConversationsGetParams {
    unAsnweredFirst: boolean;
    offset: number;
    markFilterId: number;
    userId?: number;
    limit?: number;
    filterMode?: string;
  }

  /**
   * Parameters for Apiv2ChatConversationsDelete
   */
  export interface Apiv2ChatConversationsDeleteParams {
    userId: number;
    reason?: string;
  }

  /**
   * Parameters for Apiv2ChatMessagesGet
   */
  export interface Apiv2ChatMessagesGetParams {
    offset: number;
    includeRemoved: boolean;
    userId?: number;
    limit?: number;
  }

  /**
   * Parameters for Apiv2ChatMessagesPost
   */
  export interface Apiv2ChatMessagesPostParams {
    sendNotificationOverEmail: boolean;
    userId?: number;
    text?: string;
  }

  /**
   * Parameters for Apiv2ChatLatestMessagesGet
   */
  export interface Apiv2ChatLatestMessagesGetParams {
    lastMessageId: number;
    userId?: number;
  }

  /**
   * Parameters for Apiv2ChatMessageDelete
   */
  export interface Apiv2ChatMessageDeleteParams {
    userId: number;
    messageId: number;
  }

  /**
   * Parameters for Apiv2ChatMarkPost
   */
  export interface Apiv2ChatMarkPostParams {
    userId: number;
    markId: number;
  }
}

export { ChatService }
