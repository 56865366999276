/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./custom-field-control.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../vk-controls/components/form-field/form-field.component.ngfactory";
import * as i4 from "../../../../../vk-controls/components/form-field/form-field.component";
import * as i5 from "../../../../../vk-controls/components/label/label.component.ngfactory";
import * as i6 from "../../../../../vk-controls/components/label/label.component";
import * as i7 from "@angular/forms";
import * as i8 from "../../../../../vk-controls/directives/input.directive";
import * as i9 from "../../../../../vk-controls/components/input-options/input-options.component.ngfactory";
import * as i10 from "../../../../../vk-controls/components/input-options/input-options.component";
import * as i11 from "@angular/cdk/overlay";
import * as i12 from "../../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i13 from "@angular/material/icon";
import * as i14 from "@angular/cdk/bidi";
import * as i15 from "./custom-field-control.component";
import * as i16 from "../../../../stores/custom-field-value/custom-field-value.query";
var styles_CustomFieldControlComponent = [i0.styles];
var RenderType_CustomFieldControlComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CustomFieldControlComponent, data: {} });
export { RenderType_CustomFieldControlComponent as RenderType_CustomFieldControlComponent };
function View_CustomFieldControlComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "custom-field-control-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.SelectValue(_v.context.$implicit.Code) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "custom-field-control-item__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "custom-field-control-item__description"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.Name; _ck(_v, 3, 0, currVal_0); var currVal_1 = _v.context.$implicit.Description; _ck(_v, 5, 0, currVal_1); }); }
function View_CustomFieldControlComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "custom-field-control__autocomplete"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_CustomFieldControlComponent_2)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.CustomFieldsValues$)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_CustomFieldControlComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 23, "div", [["class", "custom-field-control"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 22, "div", [["class", "custom-field-control__control"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 21, "vk-form-field", [["controlsWidth", "320px"]], null, null, null, i3.View_FormFieldComponent_0, i3.RenderType_FormFieldComponent)), i1.ɵdid(3, 4308992, null, 2, i4.FormFieldComponent, [], { labelWidth: [0, "labelWidth"], controlsWidth: [1, "controlsWidth"] }, null), i1.ɵqud(603979776, 1, { errors: 1 }), i1.ɵqud(603979776, 2, { inputs: 1 }), (_l()(), i1.ɵeld(6, 0, null, 0, 2, "vk-label", [], null, null, null, i5.View_LabelComponent_0, i5.RenderType_LabelComponent)), i1.ɵdid(7, 114688, null, 0, i6.LabelComponent, [], null, null), (_l()(), i1.ɵted(8, 0, ["", ""])), (_l()(), i1.ɵeld(9, 0, null, 1, 6, "input", [["type", "text"], ["vkInput", ""]], [[1, "disabled", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 10).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 10)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 10)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 16384, null, 0, i7.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i7.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i7.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i7.DefaultValueAccessor]), i1.ɵdid(12, 540672, null, 0, i7.FormControlDirective, [[8, null], [8, null], [6, i7.NG_VALUE_ACCESSOR], [2, i7.ɵangular_packages_forms_forms_k]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i7.NgControl, null, [i7.FormControlDirective]), i1.ɵdid(14, 81920, [[2, 4]], 0, i8.InputDirective, [i1.Renderer2, i1.ElementRef, i7.ControlContainer, i7.NgControl], null, null), i1.ɵdid(15, 16384, null, 0, i7.NgControlStatus, [[4, i7.NgControl]], null, null), (_l()(), i1.ɵeld(16, 0, null, 2, 7, "vk-input-options", [], null, null, null, i9.View_InputOptionsComponent_0, i9.RenderType_InputOptionsComponent)), i1.ɵdid(17, 114688, null, 0, i10.InputOptionsComponent, [], null, null), (_l()(), i1.ɵeld(18, 0, null, 0, 5, "button", [["cdkOverlayOrigin", ""], ["class", "custom-field-control__trigger"]], [[1, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.OpenAutoCompleteOptions() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(19, 16384, [["trigger", 4]], 0, i11.CdkOverlayOrigin, [i1.ElementRef], null, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(21, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i12.View_MatIcon_0, i12.RenderType_MatIcon)), i1.ɵdid(22, 9158656, null, 0, i13.MatIcon, [i1.ElementRef, i13.MatIconRegistry, [8, null], [2, i13.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["more_vert"])), (_l()(), i1.ɵand(16777216, null, null, 3, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("backdropClick" === en)) {
        var pd_0 = (_co.CloseAutoCompleteOptions($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_CustomFieldControlComponent_1)), i1.ɵdid(25, 671744, null, 0, i11.CdkConnectedOverlay, [i11.Overlay, i1.TemplateRef, i1.ViewContainerRef, i11.ɵc, [2, i14.Directionality]], { origin: [0, "origin"], positions: [1, "positions"], backdropClass: [2, "backdropClass"], open: [3, "open"], hasBackdrop: [4, "hasBackdrop"] }, { backdropClick: "backdropClick" }), i1.ɵpod(26, { originX: 0, originY: 1, overlayX: 2, overlayY: 3 }), i1.ɵpad(27, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "169px"; var currVal_1 = "320px"; _ck(_v, 3, 0, currVal_0, currVal_1); _ck(_v, 7, 0); var currVal_11 = _co.Control; _ck(_v, 12, 0, currVal_11); _ck(_v, 14, 0); _ck(_v, 17, 0); _ck(_v, 22, 0); var currVal_15 = i1.ɵnov(_v, 19); var currVal_16 = _ck(_v, 27, 0, _ck(_v, 26, 0, "end", "bottom", "end", "top")); var currVal_17 = "popover-menu__backdrop"; var currVal_18 = _co.IsAutocompleteOptionsOpened; var currVal_19 = true; _ck(_v, 25, 0, currVal_15, currVal_16, currVal_17, currVal_18, currVal_19); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.FieldName; _ck(_v, 8, 0, currVal_2); var currVal_3 = _co.Disabled; var currVal_4 = i1.ɵnov(_v, 15).ngClassUntouched; var currVal_5 = i1.ɵnov(_v, 15).ngClassTouched; var currVal_6 = i1.ɵnov(_v, 15).ngClassPristine; var currVal_7 = i1.ɵnov(_v, 15).ngClassDirty; var currVal_8 = i1.ɵnov(_v, 15).ngClassValid; var currVal_9 = i1.ɵnov(_v, 15).ngClassInvalid; var currVal_10 = i1.ɵnov(_v, 15).ngClassPending; _ck(_v, 9, 0, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10); var currVal_12 = (i1.ɵunv(_v, 18, 0, i1.ɵnov(_v, 20).transform(_co.CustomFieldsValues$)).length === 0); _ck(_v, 18, 0, currVal_12); var currVal_13 = i1.ɵnov(_v, 22).inline; var currVal_14 = (((i1.ɵnov(_v, 22).color !== "primary") && (i1.ɵnov(_v, 22).color !== "accent")) && (i1.ɵnov(_v, 22).color !== "warn")); _ck(_v, 21, 0, currVal_13, currVal_14); }); }
export function View_CustomFieldControlComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-custom-field-control", [], null, null, null, View_CustomFieldControlComponent_0, RenderType_CustomFieldControlComponent)), i1.ɵprd(5120, null, i7.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i15.CustomFieldControlComponent]), i1.ɵdid(2, 114688, null, 0, i15.CustomFieldControlComponent, [i16.CustomFieldValueQuery], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var CustomFieldControlComponentNgFactory = i1.ɵccf("app-custom-field-control", i15.CustomFieldControlComponent, View_CustomFieldControlComponent_Host_0, { FieldName: "FieldName", Description: "Description" }, {}, []);
export { CustomFieldControlComponentNgFactory as CustomFieldControlComponentNgFactory };
