import { ChangeDetectorRef, OnInit } from '@angular/core';
import { CallsController1Service, LeadsService, UsersService } from '../../../../api/services';
import * as moment from 'moment';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DgPaginationComponent } from '../../../../ngr-ui/components/dg-pagination/dg-pagination.component';
import { MatDialog } from "@angular/material";
import { CallFormComponent } from "../../components/modals/call-form/call-form.component";
import { of } from "rxjs";
import { DataGridComponent } from "../../../../ngr-ui/components/data-grid/data-grid.component";
import { isNullOrUndefined } from "util";
import { publish, refCount } from "rxjs/operators";
export var LeadCallStatus;
(function (LeadCallStatus) {
    LeadCallStatus[LeadCallStatus["ALL"] = 0] = "ALL";
    LeadCallStatus[LeadCallStatus["WAITING_FOR_A_CALL"] = 1] = "WAITING_FOR_A_CALL";
    LeadCallStatus[LeadCallStatus["CALL_IS_MADE"] = 2] = "CALL_IS_MADE";
    LeadCallStatus[LeadCallStatus["DIDNT_PICK_UP"] = 3] = "DIDNT_PICK_UP";
    LeadCallStatus[LeadCallStatus["PHONE_NUMBER_UNAVAILABLE"] = 4] = "PHONE_NUMBER_UNAVAILABLE";
})(LeadCallStatus || (LeadCallStatus = {}));
var UsersListComponent = /** @class */ (function () {
    function UsersListComponent(usersService, leadsService, cd, dialog, callsService) {
        this.usersService = usersService;
        this.leadsService = leadsService;
        this.cd = cd;
        this.dialog = dialog;
        this.callsService = callsService;
        this.users = [];
        this.dateFrom = new Date();
        this.dateTo = new Date();
        this.leadCallsStatus = new FormControl(LeadCallStatus.ALL);
        this.leadCallsStatuses$ = of([]);
        this.yandexMetrikaOptions = [
            {
                value: null,
                title: 'Все'
            },
            {
                value: true,
                title: 'Только подключили'
            },
            {
                value: false,
                title: 'Только не подключили'
            },
        ];
        this.activeAdCabinet = [
            {
                value: null,
                title: 'Все'
            },
            {
                value: 'true',
                title: 'Только активированные'
            },
            {
                value: 'false',
                title: 'Только не активированные'
            },
        ];
        this.profiOrNoob = [
            {
                value: null,
                title: 'Все'
            },
            {
                value: 'Новичок',
                title: 'Новички'
            },
            {
                value: 'Профессионал',
                title: 'Проффесионалы'
            },
        ];
        this.callFormOpened = false;
        this.usersListPeriod = {
            period: 'today',
            dateFrom: new Date(),
            dateTo: new Date()
        };
    }
    Object.defineProperty(UsersListComponent.prototype, "currentPage", {
        get: function () {
            var currentPage = 1;
            if (this.pagination) {
                if (this.pagination.currentPage) {
                    currentPage = this.pagination.currentPage;
                }
            }
            return currentPage;
        },
        enumerable: true,
        configurable: true
    });
    UsersListComponent.prototype.LoadCallStatuses = function () {
        this.leadCallsStatuses$ = this.callsService.GetCallsStatuses()
            .pipe(publish(), refCount());
    };
    UsersListComponent.prototype.UserStatustChanged = function () {
        this.UpdateData();
    };
    Object.defineProperty(UsersListComponent.prototype, "CountOfPages", {
        get: function () {
            return Math.ceil(this.users.length / 10);
        },
        enumerable: true,
        configurable: true
    });
    UsersListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.formCall = new FormGroup({
            userId: new FormControl(),
            callDate: new FormControl('', [Validators.required]),
            comment: new FormControl('')
        });
        this.LoadData();
        this.LoadCallStatuses();
        console.log(this.leadCallsStatus, 'LEAD CALLS STATUS');
        this.leadCallsStatus.valueChanges
            .subscribe(function (data) {
            console.log(data);
            _this.dg.LoadDisplayedData();
            _this.dg.SetSorting('nextContactDate', true, true);
        });
    };
    UsersListComponent.prototype.CreateForm = function (userId) {
        var _this = this;
        this.dialog.open(CallFormComponent, {
            data: {
                userId: userId
            },
            width: '350px',
            height: '340px'
        })
            .afterClosed()
            .subscribe(function () {
            'CALL WAS CREATED';
            _this.UpdateData();
        });
    };
    UsersListComponent.prototype.SaveCall = function () {
        var _this = this;
        if (this.formCall.valid) {
            var callData = this.formCall.value;
            console.log(callData);
            callData.callDate = new Date(moment(callData).add(3, 'day').toDate());
            this.leadsService.CreateCall(callData)
                .subscribe(function (data) {
                _this.UpdateData();
                alert('Звонок сохранен!');
                _this.callFormOpened = !_this.callFormOpened;
            });
        }
    };
    UsersListComponent.prototype.LoadData = function () {
        var _this = this;
        this.users = [];
        this.loading = true;
        this.usersService.GetNewUsers({
            dateFrom: this.dateToStart(this.dateFrom),
            dateTo: this.dateToEnd(this.dateTo)
        })
            .subscribe(function (data) {
            if (data !== null) {
                _this.handleData(data);
                // this.cd.detectChanges();
            }
            _this.loading = false;
        });
    };
    UsersListComponent.prototype.handleData = function (data) {
        var _this = this;
        this.users = data.map(function (user) {
            var u = user.report;
            u.registrationDate = _this.dateToUtc(u.registrationDate);
            u.lead = user.lead;
            u.requestsPerDayAutomation = user.requestsPerDayAutomation;
            u.requestsPerDayGroups = user.requestsPerDayGroups;
            u.requestsPerDayPosts = user.requestsPerDayPosts;
            u.requestsPerDayPromoPosts = user.requestsPerDayPromoPosts;
            u.requestsPerDayBidManagement = user.requestsPerDayBidManagement;
            u.lastActivityAd = _this.dateToUtc(user.lastActivityAd);
            u.lastActivityGroups = _this.dateToUtc(user.lastActivityGroups);
            u.lastActivityPosts = _this.dateToUtc(user.lastActivityPosts);
            u.lastActivityPromo = _this.dateToUtc(user.lastActivityPromo);
            u.createdStrategies = user.createdStrategies;
            u.nextContactDate = _this.getNextContactDate(user) || Number.MAX_SAFE_INTEGER;
            console.log(u.nextContactDate, 'NEXT CONTACT DATE');
            return u;
        });
    };
    UsersListComponent.prototype.getNextContactDate = function (user) {
        if (user && user.lead && user.lead.calls && user.lead.calls.length > 0) {
            var userCalls = this.getUserCalls(user);
            return this.getDatesDifferent(new Date(), new Date(userCalls[0].callDate));
        }
        else {
            return null;
        }
    };
    UsersListComponent.prototype.getUserCalls = function (user) {
        var _this = this;
        var userCalls = [];
        if (user && user.lead && user.lead.calls) {
            userCalls = user.lead.calls.sort(function (a, b) { return _this.compareDate(a, b); });
        }
        return userCalls;
    };
    UsersListComponent.prototype.compareDate = function (b, a) {
        var differenceDateB = this.getDatesDifferent(new Date(), new Date(b.callDate));
        var differenceDateA = this.getDatesDifferent(new Date(), new Date(a.callDate));
        return differenceDateB - differenceDateA;
    };
    UsersListComponent.prototype.getDatesDifferent = function (dateA, dateB) {
        return this.DateToNumber(dateB) - this.DateToNumber(dateA);
    };
    UsersListComponent.prototype.dateToUtc = function (date) {
        if (date) {
            return moment.utc(date).toDate().toString();
        }
        return null;
    };
    UsersListComponent.prototype.DateToNumber = function (date) {
        var millisecondsFrom1970 = moment(date).format('x');
        return parseInt(millisecondsFrom1970, 10);
    };
    UsersListComponent.prototype.IsNew = function (user) {
        if (user) {
            return moment(user.registrationDate).isBefore(new Date()) && Math.abs(moment(user.registrationDate).diff(new Date(), 'hour')) <= 24;
        }
    };
    Object.defineProperty(UsersListComponent.prototype, "context", {
        get: function () {
            return this;
        },
        enumerable: true,
        configurable: true
    });
    UsersListComponent.prototype.DateChanged = function ($event) {
        this.dateFrom = $event.dateFrom;
        this.dateTo = $event.dateTo;
        this.LoadData();
    };
    UsersListComponent.prototype.dateToStart = function (date) {
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);
        return moment(date).format('YYYY-MM-DD[T]HH:mm:ss[Z]');
    };
    UsersListComponent.prototype.dateToEnd = function (date) {
        date.setHours(23);
        date.setMinutes(59);
        date.setSeconds(59);
        date.setMilliseconds(99);
        return moment(date).format('YYYY-MM-DD[T]HH:mm:ss[Z]');
    };
    UsersListComponent.prototype.UpdateData = function () {
        var _this = this;
        this.usersService.GetNewUsers({
            dateFrom: this.dateToStart(this.dateFrom),
            dateTo: this.dateToEnd(this.dateTo)
        })
            .subscribe(function (data) {
            if (data !== null) {
                _this.handleData(data);
                // this.cd.detectChanges();
            }
        });
    };
    UsersListComponent.prototype.SortByLastCallFunction = function (userA, userB) {
        console.log(userA, userB, 'USERS A AND B');
    };
    UsersListComponent.prototype.FilterByCall = function (user) {
        var callStatus = this.leadCallsStatus.value;
        if (callStatus === LeadCallStatus.ALL) {
            return true;
        }
        else {
            var userCalls = this.getUserCalls(user);
            if (userCalls && userCalls.length > 0) {
                var callWithSelectedStatus = userCalls.find(function (call) { return call.callStatusId === callStatus; });
                return !isNullOrUndefined(callWithSelectedStatus);
            }
            else {
                return false;
            }
        }
    };
    return UsersListComponent;
}());
export { UsersListComponent };
