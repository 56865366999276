import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckboxesGroupComponent } from './components/checkboxes-group/checkboxes-group.component';
import { CheckboxItemDirective } from './directives/checkbox-item.directive';



@NgModule({
  declarations: [CheckboxesGroupComponent, CheckboxItemDirective],
  exports: [CheckboxesGroupComponent, CheckboxItemDirective],
  imports: [
    CommonModule
  ]
})
export class CheckboxesGroupModule { }
