var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { map as __map, filter as __filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../api-configuration";
import * as i2 from "@angular/common/http";
var CabinetsService = /** @class */ (function (_super) {
    __extends(CabinetsService, _super);
    function CabinetsService(config, http) {
        return _super.call(this, config, http) || this;
    }
    /**
     * @return Success
     */
    CabinetsService.prototype.GetCabinetsResponse = function () {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/cabinets", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @return Success
     */
    CabinetsService.prototype.GetCabinets = function () {
        return this.GetCabinetsResponse().pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param value undefined
     */
    CabinetsService.prototype.Apiv2CabinetsPostResponse = function (value) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = value;
        var req = new HttpRequest('POST', this.rootUrl + "/apiv2/cabinets", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param value undefined
     */
    CabinetsService.prototype.Apiv2CabinetsPost = function (value) {
        return this.Apiv2CabinetsPostResponse(value).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @return Success
     */
    CabinetsService.prototype.GetUserCabinetsResponse = function () {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/cabinets/getusercabinets", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @return Success
     */
    CabinetsService.prototype.GetUserCabinets = function () {
        return this.GetUserCabinetsResponse().pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param id undefined
     * @return Success
     */
    CabinetsService.prototype.Apiv2CabinetsByIdGetResponse = function (id) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('GET', this.rootUrl + ("/apiv2/cabinets/" + id), __body, {
            headers: __headers,
            params: __params,
            responseType: 'text'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param id undefined
     * @return Success
     */
    CabinetsService.prototype.Apiv2CabinetsByIdGet = function (id) {
        return this.Apiv2CabinetsByIdGetResponse(id).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `CabinetsService.Apiv2CabinetsByIdPutParams` containing the following parameters:
     *
     * - `id`:
     *
     * - `value`:
     */
    CabinetsService.prototype.Apiv2CabinetsByIdPutResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.value;
        var req = new HttpRequest('PUT', this.rootUrl + ("/apiv2/cabinets/" + params.id), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `CabinetsService.Apiv2CabinetsByIdPutParams` containing the following parameters:
     *
     * - `id`:
     *
     * - `value`:
     */
    CabinetsService.prototype.Apiv2CabinetsByIdPut = function (params) {
        return this.Apiv2CabinetsByIdPutResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param id undefined
     */
    CabinetsService.prototype.Apiv2CabinetsByIdDeleteResponse = function (id) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('DELETE', this.rootUrl + ("/apiv2/cabinets/" + id), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param id undefined
     */
    CabinetsService.prototype.Apiv2CabinetsByIdDelete = function (id) {
        return this.Apiv2CabinetsByIdDeleteResponse(id).pipe(__map(function (_r) { return _r.body; }));
    };
    CabinetsService.GetCabinetsPath = '/apiv2/cabinets';
    CabinetsService.Apiv2CabinetsPostPath = '/apiv2/cabinets';
    CabinetsService.GetUserCabinetsPath = '/apiv2/cabinets/getusercabinets';
    CabinetsService.Apiv2CabinetsByIdGetPath = '/apiv2/cabinets/{id}';
    CabinetsService.Apiv2CabinetsByIdPutPath = '/apiv2/cabinets/{id}';
    CabinetsService.Apiv2CabinetsByIdDeletePath = '/apiv2/cabinets/{id}';
    CabinetsService.ngInjectableDef = i0.defineInjectable({ factory: function CabinetsService_Factory() { return new CabinetsService(i0.inject(i1.ApiConfiguration), i0.inject(i2.HttpClient)); }, token: CabinetsService, providedIn: "root" });
    return CabinetsService;
}(__BaseService));
export { CabinetsService };
