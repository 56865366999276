import {Injectable} from '@angular/core';
import {PlatformsTypes} from '../../../platforms-types';
import {AkitaNgFormsManager} from '@datorama/akita-ng-forms-manager';
import {PlatformsQuery} from '../../../stores/platforms/platforms.query';
import {CrmPipelinesQuery} from '../../../stores/crm-pipelines/crm-pipelines.query';

@Injectable({
  providedIn: 'root'
})
export class VkRetargetingGroupsConfiguratorService {

  constructor(
    private fm: AkitaNgFormsManager,
    private platformsService: PlatformsQuery,
    private pipelinesQuery: CrmPipelinesQuery
  ) {
  }

  public configure(type: PlatformsTypes) {
    const form = this.fm.getForm('destinationPlatformSettings').value;
    const pipelineForm = this.fm.getForm('originPlatformSettings').value;
    const [accountId, clientId] = form.accountId;
    const data = {
      code: type.toString(),
      data: []
    };
    let classicData = null;
    if (form.isClassicMode && form.splitByPipelines) {
      classicData = ['LEADS', 'DEALS'].map(filter => ({
        code: type.toString(),
        filter: [filter],
        data: this.generateGeneralForm(form, data, pipelineForm, accountId, clientId, type)
          .filter(x => x.pipelineType === filter)
          .map(x => {
            x.pipelineType = undefined;
            return x;
          })
      }));
    } else {
      data.data = this.generateGeneralForm(form, data, pipelineForm, accountId, clientId, type).map(x => {
        x.pipelineType = undefined;
        return x;
      });
    }

    return form.isClassicMode && form.splitByPipelines ? classicData : [data];
  }

  private generateGeneralForm(form, data, pipelineForm, accountId, clientId, type: PlatformsTypes = null) {
    return this.getPipelineStatuses(form)
      .map(pipelineStatus => ({
        code: pipelineStatus.pipelineId ? `${this.checkBitrixType(pipelineStatus.pipelineType) ? 0 : this.getSelectedPipelineId(pipelineForm)}_${pipelineStatus.pipelineId}` : null,
        pipelineType: pipelineStatus.pipelineType,
        data: pipelineStatus.retargetingGroups.map(group => this.GetRetargetingGroupConfigurationItem(accountId, clientId, group, type))
      }));
  }

  private GetRetargetingGroupConfigurationItem(accountId: any, clientId: any, group: any, type: PlatformsTypes = null) {
    if (type) {
      switch(type) {
        case PlatformsTypes.FB_CUSTOM_AUDIENCES:
          return `${group}`;
          break;
        case PlatformsTypes.VK_RETARGETING_GROUPS:
          return `${accountId}_${clientId || 0}_${group}`;
          break;
      }
    } else {
      return `${accountId}_${clientId || 0}_${group}`;
    }
  }

  private getSelectedPipelineId(pipelineForm) {
    return this.pipelinesQuery.getEntityIdByPipelineId$(pipelineForm.selectedPipelineId);
  }

  private getPipelineStatuses(form) {
    return Object.keys(form.pipelineStatuses)
      .map(key => form.pipelineStatuses[key])
      .filter(x => x.retargetingGroups && x.retargetingGroups.length > 0);
  }

  valid(type: PlatformsTypes) {
    const form = this.fm.getForm('destinationPlatformSettings');
    const value = form ? form.value : null;
    if (value && value.pipelineStatuses) {
      return value ? Object.keys(value.pipelineStatuses)
        .map(key => value.pipelineStatuses[key])
        .filter(x => x.retargetingGroups && x.retargetingGroups.length > 0)
        .map(status => status.retargetingGroups.length > 0)
        .reduce((acc, curr) => acc || curr, false) : false;
    }
    return false;
  }

  private checkBitrixType(section: string) {
    return this.platformsService.getValue().ui.selectedOriginPlatform === PlatformsTypes.BITRIX_EXPORT && section === 'LEADS';
  }
}
