import {AfterViewInit, Component, ElementRef, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {isNullOrUndefined} from 'util';

@Component({
  selector: 'app-counter-register-goal-proxy',
  templateUrl: './counter-register-goal-proxy.component.html',
  styleUrls: ['./counter-register-goal-proxy.component.scss']
})
export class CounterRegisterGoalProxyComponent implements AfterViewInit {
  private userDashboardRoute = '/dashboard';
  private yandexMetrikaJS = '';

  private defaultRedirectUrl = 'https://ng.postmonitor.ru/dashboard';
  private defaulGoalType = 'reg-completed';
  constructor (
    private route: ActivatedRoute,
    private router: Router,
    private elementRef: ElementRef
  ) { }

  ngAfterViewInit() {
    let source = this.route.snapshot.queryParams.source;

    if (isNullOrUndefined(source)) {
      source = 'unknown';
    }

    // Retrieve goal type
    let goalType = this.route.snapshot.queryParams.type;

    // If not provided define default registration type
    if (isNullOrUndefined(goalType)) {
      goalType = this.defaulGoalType;
    }

    

    // Append script that indicates that goal reached
    const scriptMessasges = document.createElement('script');
    scriptMessasges.type = 'text/javascript';
    scriptMessasges.innerHTML = `ym(45402093,'reachGoal','metrika-js-${goalType}-${source}');console.warn('ymGoal executed');`;
    console.warn(`CounterRegisterGoalProxy: goalType=${goalType};source=${source}`);
    this.elementRef.nativeElement.appendChild(scriptMessasges);

    // Retrieve redirect URL
    let redirect = this.route.snapshot.queryParams.redirect;

    // If not provided define a default redirect to dashboard
    if (isNullOrUndefined(redirect)) {
      redirect = this.defaultRedirectUrl;
    }

    

    // Redirect
    window.location.assign(redirect);
  }
}
