import {Injectable} from '@angular/core';
import {AkitaNgFormsManager} from '@datorama/akita-ng-forms-manager';
import {environment} from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MoiSkladExportService {

  constructor(
    private fm: AkitaNgFormsManager
  ) {
  }

  public valid() {
    const form = this.fm.getForm(environment.integrations.originFormKey);
    console.log(form, 'EXPORT MOI SKLAD');
    return form.valid;
  }
}
