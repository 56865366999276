import {Injectable} from '@angular/core';
import {QueryEntity} from '@datorama/akita';
import {CrmOrganisationStore, CrmOrganisationState} from './crm-organisation.store';

@Injectable({providedIn: 'root'})
export class CrmOrganisationQuery extends QueryEntity<CrmOrganisationState> {

  public organisations$ = this.selectAll();

  constructor(protected store: CrmOrganisationStore) {
    super(store);
  }

}
