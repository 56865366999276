import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {ChatConversationMessagesService} from "../../services/chat-conversation-messages.service";
import {ChatConversationModel} from "../../models/chat-conversation-model";
import {Observable, Subject} from "rxjs/Rx";


@Component({
  selector: 'app-conversations-list',
  templateUrl: './conversations-list.component.html',
  styleUrls: ['./conversations-list.component.scss']
})
export class ConversationsListComponent implements OnInit {
  public refreshTimeout = 5000;

  private refreshTimer: any = null;
  private lastChangesRecievedAt: number = null;

  public perPageSelectOptions = [
    {
      value: 10,
      name: 'До 10'
    },
    {
      value: 20,
      name: 'До 20'
    },
    {
      value: 50,
      name: 'До 50'
    },
    {
      value: 100,
      name: 'До 100'
    },
    {
      value: null,
      name: 'Все'
    }
  ];

  public filterMode: string = 'all';
  public filterLimit: number = 100;
  public filterOffset: number = 0;

  public filterMark: number = null;

  public fillterOffMarks: Array<number> = [];

  private filterChanged: Subject<any> = new Subject<any>();

  public loadingConversations: boolean = false;

  public filterOptions = [
    {
      mode: 'all',
      text: 'Все'
    },
    {
      mode: 'unread',
      text: 'Непрочтенные'
    },
    {
      mode: 'unanswered',
      text: 'Неотвеченные'
    },
    {
      mode: 'answered',
      text: 'Отвеченные'
    }
  ];

  public markFilterId: number = 0;

  constructor(private route: ActivatedRoute, public chatApi: ChatConversationMessagesService) { }

  ngOnInit() {
    this.chatApi.GetMarks();

    this.filterChanged
      .debounceTime(1000)
      .subscribe(x => {
        this.chatApi.GetConversations(null, this.filterMode, this.filterLimit , this.filterOffset, this.markFilterId);
      });

    this.filterChanged.next(true);

    this.lastChangesRecievedAt = Math.round((new Date()).getTime() / 1000);

    this.refreshTimer = Observable.interval(this.refreshTimeout)
      .takeWhile(() => true)
      .subscribe(i => {
        this.chatApi.GetConversationChanges(this.lastChangesRecievedAt, null, false, true);
        this.lastChangesRecievedAt = Math.round((new Date()).getTime() / 1000);
      });
  }

  public IsMarkExcluded(id: number): boolean {
    return (this.fillterOffMarks.indexOf(id) >= 0);
  }

  public ProcessMarkExclusion(): void {
    // const index = this.fillterOffMarks.indexOf(id);
    //
    // if (index >= 0) {
    //   this.fillterOffMarks.splice(index, 1);
    // } else {
    //   this.fillterOffMarks.push(id);
    // }

    this.filterChanged.next(true);
  }

  public SetFitlerMode(mode: string): void {
    this.filterMode = mode;

    this.filterChanged.next(true);
  }

  public GotConversations(): boolean {
    return (this.chatApi.conversations && this.chatApi.conversations.length > 0);
  }

  public GetConverstaions(): Array<ChatConversationModel> {
    if (!this.GotConversations())
      return [];

    return this.chatApi.conversations;
  }
}
