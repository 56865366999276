import {Injectable} from '@angular/core';
import {PlatformsRelation} from './platforms-relation.model';
import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';

export interface PlatformsRelationsState extends EntityState<PlatformsRelation> {
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'platforms-relations'})
export class PlatformsRelationsStore extends EntityStore<PlatformsRelationsState> {

  constructor() {
    super();
  }

}

