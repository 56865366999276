import { Injectable } from '@angular/core';
import { CrmOrganisation } from './crm-organisation.model';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

export interface CrmOrganisationState extends EntityState<CrmOrganisation> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'crm-organisation' })
export class CrmOrganisationStore extends EntityStore<CrmOrganisationState> {

  constructor() {
    super();
  }

}

