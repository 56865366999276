/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PostMonitorBackend_V2ModelsCrmEntitesLeadCall } from '../models/post-monitor-backend-_v2models-crm-entites-lead-call';
import { PostMonitorBackend_V2ModelsCrmEntitesLeadCallStatus } from '../models/post-monitor-backend-_v2models-crm-entites-lead-call-status';
import { PostMonitorBackend_V2ModelsAdmindtosChangeCallStatusDto } from '../models/post-monitor-backend-_v2models-admindtos-change-call-status-dto';
@Injectable({
  providedIn: 'root',
})
class CallsController1Service extends __BaseService {
  static readonly GetUserCallsPath = '/apiv2/calls';
  static readonly GetCallsStatusesPath = '/apiv2/calls/statuses';
  static readonly ChangeCallStatusPath = '/apiv2/calls/change-status';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return Success
   */
  GetUserCallsResponse(): __Observable<__StrictHttpResponse<Array<PostMonitorBackend_V2ModelsCrmEntitesLeadCall>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/calls`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PostMonitorBackend_V2ModelsCrmEntitesLeadCall>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetUserCalls(): __Observable<Array<PostMonitorBackend_V2ModelsCrmEntitesLeadCall>> {
    return this.GetUserCallsResponse().pipe(
      __map(_r => _r.body as Array<PostMonitorBackend_V2ModelsCrmEntitesLeadCall>)
    );
  }

  /**
   * @return Success
   */
  GetCallsStatusesResponse(): __Observable<__StrictHttpResponse<Array<PostMonitorBackend_V2ModelsCrmEntitesLeadCallStatus>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/calls/statuses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PostMonitorBackend_V2ModelsCrmEntitesLeadCallStatus>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetCallsStatuses(): __Observable<Array<PostMonitorBackend_V2ModelsCrmEntitesLeadCallStatus>> {
    return this.GetCallsStatusesResponse().pipe(
      __map(_r => _r.body as Array<PostMonitorBackend_V2ModelsCrmEntitesLeadCallStatus>)
    );
  }

  /**
   * @param statusDto undefined
   * @return Success
   */
  ChangeCallStatusResponse(statusDto?: PostMonitorBackend_V2ModelsAdmindtosChangeCallStatusDto): __Observable<__StrictHttpResponse<PostMonitorBackend_V2ModelsCrmEntitesLeadCall>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = statusDto;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/apiv2/calls/change-status`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PostMonitorBackend_V2ModelsCrmEntitesLeadCall>;
      })
    );
  }
  /**
   * @param statusDto undefined
   * @return Success
   */
  ChangeCallStatus(statusDto?: PostMonitorBackend_V2ModelsAdmindtosChangeCallStatusDto): __Observable<PostMonitorBackend_V2ModelsCrmEntitesLeadCall> {
    return this.ChangeCallStatusResponse(statusDto).pipe(
      __map(_r => _r.body as PostMonitorBackend_V2ModelsCrmEntitesLeadCall)
    );
  }
}

module CallsController1Service {
}

export { CallsController1Service }
