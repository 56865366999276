import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AffiliatePromgramStatisticsByUserRouteComponent} from "./routes/affiliate-promgram-statistics-by-user-route/affiliate-promgram-statistics-by-user-route.component";

const routes: Routes = [
  {
    path: '',
    component: AffiliatePromgramStatisticsByUserRouteComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AffiliateProgramStatisticsByUserRoutingModule {
}
