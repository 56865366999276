<div class="ngr-datagrid">
  <!--<div class="ngr-datagrid__action-bars">-->
  <!--&lt;!&ndash;<ng-container&ndash;&gt;-->
  <!--&lt;!&ndash;*ngFor="let actionBar of ActionBars"&ndash;&gt;-->
  <!--&lt;!&ndash;[ngTemplateOutlet]="actionBar"&ndash;&gt;-->
  <!--&lt;!&ndash;&gt;</ng-container>&ndash;&gt;-->
  <!--</div>-->
  <div class="ngr-datagrid-relative-wrapper">
    <div class="ngr-datagrid__table" #table>
      <table class="ngr-table" [style.minHeight]="height">
        <thead class="ngr-table__head">
        <tr class="ngr-table__row ngr-table__row_heading">
          <ng-container *ngFor="let column of displayedColumns">
            <th
              class="ngr-table__cell ngr-cell ngr-cell_heading"
              [ngClass]="{'ngr-cell_hidden': !IsShowedColumn(column.name)}"
              [ngStyle]="{maxWidth: column.width}"
            >
              <div class="ngr-cell-wrapper">
                <span class="ngr-column-hide ion-md-eye-off" (click)="HideColumnByName(column.name)"
                      *ngIf="column.hiddable"></span>
                <div class="ngr-cell__content">
                  <div (click)="SetSorting(column.name, column.sortable)">
                    <ng-container *ngIf="column.HeaderTemplate; else defaultHeaderTemplate">
                      <ng-container [ngTemplateOutlet]="column.HeaderTemplate"></ng-container>
                    </ng-container>
                    <ng-template #defaultHeaderTemplate>
                      {{column.header}}
                    </ng-template>
                  </div>
                  <div class="ngr-cell__actions">
                    <ng-container
                      [ngTemplateOutlet]="column.columnHeader"
                    ></ng-container>
                  </div>
                </div>
                <div class="ngr-cell__sorting-arrow" *ngIf="column.sortable">
                  <span [ngClass]="{
                    'ion-md-arrow-up': sortingDescend,
                    'ion-md-arrow-down': !sortingDescend
                  }"
                        *ngIf="sortingField === column.name"
                  ></span>
                </div>
              </div>
            </th>
          </ng-container>
        </tr>
        <tr class="ngr-table__row ngr-table__row_heading" *ngIf="IsFilterLoaded && showFilterString">
          <ng-container *ngFor="let column of displayedColumns">
            <th
              class="ngr-table__cell ngr-cell"
              [ngStyle]="{maxWidth: column.width}"
              [ngClass]="{'ngr-cell_hidden': !IsShowedColumn(column.name)}"
            >
              <ng-container [ngSwitch]="column.filterType">
                <ng-container
                  *ngSwitchCase="'between'"
                  [ngTemplateOutlet]="betweenFilterColumn"
                  [ngTemplateOutletContext]="{column: column}"
                ></ng-container>

                <ng-container
                  *ngSwitchCase="'checkbox'"
                  [ngTemplateOutlet]="checkboxFilterColumn"
                  [ngTemplateOutletContext]="{column: column}"
                ></ng-container>

                <ng-container
                  *ngSwitchCase="'select'"
                  [ngTemplateOutlet]="selectFilterColumn"
                  [ngTemplateOutletContext]="{column: column}"
                ></ng-container>

                <ng-container
                  *ngSwitchCase="'custom'"
                  [ngTemplateOutlet]="column.FilterTemplate"
                >

                </ng-container>

                <ng-container
                  *ngSwitchCase="'none'"
                ></ng-container>

                <ng-container
                  *ngSwitchDefault
                  [ngTemplateOutlet]="defaultFilterColumn"
                  [ngTemplateOutletContext]="{column: column}"
                ></ng-container>
              </ng-container>
            </th>
          </ng-container>
        </tr>
        <tr class="ngr-table__row ngr-table__row_heading" *ngIf="showEditForm">
          <ng-container *ngFor="let column of displayedColumns">
            <th
              class="ngr-table__cell ngr-cell ngr-cell_heading"
              [ngClass]="{'ngr-cell_hidden': !IsShowedColumn(column.name)}"
              [ngStyle]="{maxWidth: column.width}"
            >
              <ng-container [ngTemplateOutlet]="column.EditTemplate"></ng-container>
            </th>
          </ng-container>
        </tr>
        </thead>
        <tbody class="ngr-table__body">
        <ng-container *ngIf="!loading; else loadingScreen">
          <ng-container *ngIf="displayedData.length > 0; else dataNotFound">
            <ng-container *ngFor="let row of displayedData">
              <tr class="ngr-table__row" [ngClass]="row.className">
                <ng-container *ngFor="let column of displayedColumns">
                  <td class="ngr-table__cell ngr-cell"
                      [ngClass]="{
              'ngr-cell_hidden': !IsShowedColumn(column.name),
              'ngr-cell_align_left': column.align === 'left',
              'ngr-cell_align_center': column.align === 'center',
              'ngr-cell_align_right': column.align === 'right'
            }"
                  >
                    <ng-container
                      [ngTemplateOutlet]="columnContent"
                      [ngTemplateOutletContext]="{column: column, row: row, cell: row[column.name]}"
                    ></ng-container>
                  </td>
                </ng-container>
              </tr>
              <!--<tr class="ngr-table__details">-->
              <!--</tr>-->
            </ng-container>
          </ng-container>
        </ng-container>
        </tbody>
        <tfoot class="ngr-table__footer" *ngIf="ShowFooter">
        <tr
          class="ngr-table__row ngr-table__row_sum"
          *ngIf="countOfPages > 1"
        >
          <ng-container *ngFor="let column of displayedColumns">
            <td
              class="ngr-table__cell ngr-cell ngr-table__cell_sum"
              [ngClass]="{
              'ngr-cell_bold': true,
              'ngr-cell_hidden': !IsShowedColumn(column.name),
              'ngr-cell_align_left': column.align === 'left',
              'ngr-cell_align_center': column.align === 'center',
              'ngr-cell_align_right': column.align === 'right'
            }"
            >
              {{PagingSummaryData[column.name]}}
              <!--<ng-container *ngIf="column.summaryFunction">-->
              <!---->
              <!--</ng-container>-->
            </td>
          </ng-container>
        </tr>
        <tr
          class="ngr-table__row ngr-table__row_sum">
          <td *ngIf="selectable"></td>
          <ng-container *ngFor="let column of displayedColumns">
            <td
              class="ngr-table__cell ngr-cell ngr-table__cell_sum"
              [ngClass]="{
              'ngr-cell_bold': true,
              'ngr-cell_hidden': !IsShowedColumn(column.name),
              'ngr-cell_align_left': column.align === 'left',
              'ngr-cell_align_center': column.align === 'center',
              'ngr-cell_align_right': column.align === 'right'
            }"
            >
              {{SummaryData[column.name]}}
            </td>
          </ng-container>
        </tr>
        </tfoot>
      </table>
    </div>
    <div class="ngr-datagrid__drawer"
         [ngClass]="{
        'ngr-datagrid__drawer_hidden': IsSidebarHidden
      }"
    >
      <ng-container *ngIf="showedColumns !== null">
        <h3>Колонки</h3>
        <ng-container *ngFor="let column of HiddableColumns">
          <label class="datagrid-field">
            <input type="checkbox" [(ngModel)]="showedColumns[column.name]" (ngModelChange)="UpdateStorage()">
            <span>{{column.header}}</span>
            <br>
          </label>
        </ng-container>
      </ng-container>
      <button class="btn btn-primary" (click)="ToggleSidebar()">Применить</button>
    </div>
    <div [style.visible]="countOfPages">
      <ng-content select="ngr-dg-pagination"></ng-content>
    </div>
  </div>
</div>

<ng-template #columnContent let-column="column" let-row="row">
  <ng-container
    [ngTemplateOutlet]="column.displayedTemplate"
    [ngTemplateOutletContext]="{column: column, row: row, cell: row[column.name]}"
    *ngIf="column.displayedTemplate; else defaultCellContent"
  >

  </ng-container>

  <ng-template #defaultCellContent>
    <ng-container *ngIf="getCellContent(column, row) && !column.loading; else inlineLoading">
      {{getCellContent(column, row)}}
    </ng-container>
  </ng-template>

  <ng-template #inlineLoading>
    <div class="spinner spinner-inline"></div>
  </ng-template>
</ng-template>

<ng-template #defaultFilterColumn let-column="column">
  <input
    class="ngr-filter__input"
    type="text"
    [attr.placeholder]="column.filterPlaceholderText"
    [(ngModel)]="filterStrings[column.name].value"
    (keyup)="OnChangeFilter()"
  >
</ng-template>

<ng-template #betweenFilterColumn let-column="column">
  <div class="ngr-table__filter ngr-filter">
    <input
      class="ngr-filter__input ngr-filter__input_between"
      type="number"
      lang='en-150'
      min="0"
      placeholder="От"
      [(ngModel)]="filterStrings[column.name].from"
      (keyup)="OnChangeFilter()"
    >
    <input
      class="ngr-filter__input ngr-filter__input_between"
      type="number"
      min="0"
      lang='en-150'
      placeholder="До"
      [(ngModel)]="filterStrings[column.name].to"
      (keyup)="OnChangeFilter()"
    >
  </div>
</ng-template>

<ng-template #checkboxFilterColumn let-column="column">
  <div class="ngr-table-filter ngr-filter">

    <div class="checkbox">
      <input type="checkbox"
             id="checkrads_10"
             [indeterminate]="true"
             [(ngModel)]="filterStrings[column.name].value"
             (change)="OnChangeFilter()"
      >
      <label for="checkrads_10"></label>
    </div>
  </div>
</ng-template>

<ng-template #selectFilterColumn let-column="column">
  <select [(ngModel)]="filterStrings[column.name].value" (change)="OnChangeFilter()" class="ngr-filter__input">
    <option
      [value]="option.value"
      *ngFor="let option of column.filterOptions">
      {{option.title}}
    </option>
  </select>
</ng-template>


<ng-template #loadingScreen>
  <tr class="ngr-table__row">
    <td class="ngr-table__cell ngr-cell" [attr.colspan]="displayedColumns.length">
      <ngr-loading-screen message="Подождите, идет загрузка данных ..."></ngr-loading-screen>
    </td>
  </tr>
</ng-template>

<ng-template #dataNotFound>
  <tr class="ngr-table__row">
    <td class="ngr-table__cell ngr-cell ngr-cell ngr-cell_align_center" [attr.colspan]="displayedColumns.length">
      {{dataNotFoundMessage}}
    </td>
  </tr>
</ng-template>
