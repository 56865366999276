<div class="strategy-input-title">
  <ng-container *ngIf="input.required">
    <span class="strategy-input-required-asterisk">*</span>
  </ng-container>

  <span>{{input.title}}</span>

  <clr-signpost *ngIf="input.description">
    <clr-signpost-content>
      <div class="field-required-label">
        <ng-container *ngIf="input.required; else notRequired">Обязательное поле</ng-container>
        <ng-template #notRequired>Необязательное поле</ng-template>
      </div>
      <div>
        {{input.description}}
      </div>
    </clr-signpost-content>
  </clr-signpost>
</div>


<div class="strategy-input-control" [ngSwitch]="input.frontendInputType">
  <ng-template ngSwitchCase="number">
    <input type="number" class="vk-input vk-input_full-width" [placeholder]="input.placeholderContent"
           [(ngModel)]="model.value" (ngModelChange)="InputTouched()" (keyup)="EmitValidation()" [disabled]="disabled">
  </ng-template>
  <ng-template ngSwitchCase="datetimepicker">
    <app-datetime-picker [disabled]="disabled" [(ngModel)]="model.value" (ngModelChange)="InputTouched()"></app-datetime-picker>
  </ng-template>
  <ng-template ngSwitchCase="checkbox">
    <input type="checkbox" class="vk-checkbox"
           [checked]="model.value === 1"
           (change)="(model.value === 0 || model.value === null) ? model.value = 1 : model.value = 0;InputTouched()"
           [disabled]="disabled"
    >
  </ng-template>
</div>

<div class="strategy-input-error">
  <ng-container *ngFor="let error of model.errors">
    <div class="strategy-input-error__item">
      <div class="strategy-input-error-text">
        {{error}}
      </div>
    </div>
  </ng-container>
</div>

