import {AbstractCopyingConfigurator} from "./abstract-copying-configurator";
import {PostMonitorBackend_V2ModelsAdManagerCopyAdsViewModel} from "../../../api/models";
import {MultipleCopyingData} from "../models/multiple-copying-data";

export class GroupSegmentationConfigurator extends AbstractCopyingConfigurator {

  constructor(props) {
    super(props);
  }

  generate(): PostMonitorBackend_V2ModelsAdManagerCopyAdsViewModel[] {
    const adsCopyingSpecifications: PostMonitorBackend_V2ModelsAdManagerCopyAdsViewModel[] = [];
    this.copyingData.groupsConfiguration.groupsList.forEach((groupId, numberOfCopy) => {
      let adSpecification = this.generateDefeault();
      adSpecification = Object.assign(adSpecification, {
        groupId,
        groupsNot: (this.copyingData.groupsConfiguration.excludeOtherGroups)
          ? this.copyingData.groupsConfiguration.groupsList.filter(x => x != groupId).join(',')
          : '',
        numberOfCopy,
      });
      console.log(adSpecification, 'SPECS');
      adsCopyingSpecifications.push(adSpecification);
    });
    return adsCopyingSpecifications;
  }


}
