import {Component, Input, OnInit} from '@angular/core';
import {IntegrationPlatformSection} from '../../../../../api/models/integration-platform-section';
import {FormGroup} from '@angular/forms';
import {AdNetworkForm} from '../../../../stores/ad-network-forms/ad-network-form.model';
import {IntegrationPlatformField} from '../../../../../api/models';
import {CrmPipelinesQuery} from '../../../../stores/crm-pipelines/crm-pipelines.query';
import {CrmPipelinesService} from '../../../../stores/crm-pipelines/crm-pipelines.service';
import {distinctUntilChanged, filter, map, tap} from 'rxjs/operators';
import {CrmPipelineStatus} from '../../../../stores/crm-pipelines/crm-pipeline.model';
import {CrmUsersService} from '../../../../stores/crm-users/crm-users.service';
import {CrmUsersQuery} from '../../../../stores/crm-users/crm-users.query';
import {AkitaNgFormsManager} from '@datorama/akita-ng-forms-manager';
import {CrmOrganisationQuery} from '../../../../stores/crm-organisation/crm-organisation.query';
import {PlatformsQuery} from '../../../../stores/platforms/platforms.query';
import {CustomFieldsQuery} from "../../../../stores/custom-fields/custom-fields.query";

@Component({
  selector: 'app-import-crm-section-settings',
  templateUrl: './import-crm-section-settings.component.html',
  styleUrls: ['./import-crm-section-settings.component.scss']
})
export class ImportCrmSectionSettingsComponent implements OnInit {

  @Input() section: IntegrationPlatformSection;
  @Input() form: FormGroup;
  @Input() adNetworkForm: AdNetworkForm;
  @Input() showAdditionalFields: boolean = false;

  public pipelines$ = this.pipelinesQuery.pipelines$
    .pipe(
      map(pipelines => pipelines.filter(pipeline => pipeline.name !== 'PM_SYSTEM_PIPELINE'))
    );

  public get sectionStages() {
    const pipeline = this.section ? this.pipelinesQuery.GetPipelineBySectionCode(this.section.code, this.pipelineId) : null;
    return pipeline ? pipeline.statuses : [];
  }

  public get pipelineId() {
    let pipelineId = null;
    if (this.form && this.form.get('fields') && this.form.get('fields').get('PM_PIPELINE_ID')) {
      pipelineId = this.form.get('fields').get('PM_PIPELINE_ID').value;
    }
    return pipelineId;
  }

  public users$ = this.crmUsersQuery.users$;

  public firstPipelineStatuses$ = this.pipelinesQuery.pipelines$.pipe(
    filter(x => x.length > 0),
    map(x => x[0].statuses as CrmPipelineStatus[])
  );
  public opened = false;

  public organisations$ = this.crmOrganisationQuery.organisations$;

  public get CustomFields() {
    return this.customFieldsQuery.CustomFields;
  }

  public get sectionSuggestionCode() {
    switch (this.platformQuery.selectedDestinationPlatformCode) {
      case 'MOY_SKLAD_IMPORT':
        return 'CRM_MOY_SKLAD_' + this.section.code;
      default:
        return 'CRM_' + this.section.code;
    }
  }

  constructor(
    private pipelinesQuery: CrmPipelinesQuery,
    private pipelinesService: CrmPipelinesService,
    private crmUsersService: CrmUsersService,
    private crmUsersQuery: CrmUsersQuery,
    private crmOrganisationQuery: CrmOrganisationQuery,
    private formManager: AkitaNgFormsManager,
    private platformQuery: PlatformsQuery,
    private customFieldsQuery: CustomFieldsQuery
  ) {
  }

  ngOnInit() {
    if (this.form && this.form.get('fields') && this.form.get('fields').get('PM_PIPELINE_ID') && this.form.get('fields').get('PM_STAGE_ID')) {
      this.form.get('fields').get('PM_PIPELINE_ID').valueChanges
        .pipe(
          distinctUntilChanged()
        )
        .subscribe(() => {
          this.form.get('fields').get('PM_STAGE_ID').setValue(null);
        })
    }
  }

  ToggleSettings() {
    this.opened = !this.opened;
  }

  isPipeline(field: IntegrationPlatformField) {
    return field.code === 'PIPELINE_ID';
  }
}
