import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TeazerViewModel} from '../../../../ad-manager/models/teazer.view-model';

@Component({
  selector: 'app-teazer',
  templateUrl: './teazer.component.html',
  styleUrls: ['./teazer.component.scss']
})
export class TeazerComponent implements OnInit {

  @Input() public teazer: TeazerViewModel;
  @Input() public defaultTitle = 'Заголовок объявления';

  @Output() public ImageRemoved: EventEmitter<string> = new EventEmitter<string>();

  public get TeazerImage(): string {
    return (this.teazer.image && this.teazer.image !== '') ? this.teazer.image : 'assets/images/ads_size_m.png';
  }

  public get TeazerTitle(): string {
    return (this.teazer.title) ? this.teazer.title : this.defaultTitle;
  }

  public get TeazerLink(): string {
    return (this.teazer.link && this.teazer.link !== '') ? this.teazer.link : 'Ссылка на сайт';
  }

  constructor() {
  }

  ngOnInit() {
  }

  public removeImage() {
    this.ImageRemoved.emit(this.teazer.image);
  }
}
