import { Injectable } from '@angular/core';
import { UserIntegrationStatistic } from './user-integration-statistic.model';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

export interface UserIntegrationStatisticsState extends EntityState<UserIntegrationStatistic> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'user-integration-statistics' })
export class UserIntegrationStatisticsStore extends EntityStore<UserIntegrationStatisticsState> {

  constructor() {
    super();
  }

}

