import { ChangeDetectorRef, EventEmitter, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { imagesSelected, url, urlWithHttpOrHttps } from '../../validators/url-validator';
import { RateManagerComponent } from '../steps/rate-manager/rate-manager.component';
import { TargetingSettingsComponent } from '../steps/targeting-settings/targeting-settings.component';
import { GroupsSelectorService } from '../../services/groups-selector/groups-selector.service';
import { VkGroupViewModel } from '../../models/vk-group.view-model';
import { AdManagerService } from '../../../../api/services';
import { MatVerticalStepper } from '@angular/material';
import { isNullOrUndefined } from 'util';
import * as moment from 'moment';
var AdFormComponent = /** @class */ (function () {
    function AdFormComponent(cd, vk, groups) {
        this.cd = cd;
        this.vk = vk;
        this.groups = groups;
        this.showActivatedCabinets = false;
        this.isUpdating = false;
        this.loading = false;
        this.accounts = [];
        this.adSaved = new EventEmitter();
        this.bindChanged = new EventEmitter();
        this.CanBeNext = false;
        this.categories = [];
        this.RateManager = null;
        this.errors = {
            format: 'Вы не выбрали формат объявления в пункте 1',
            teazer: 'Вы не ввели данные при создании объвления в пункте 2',
            promopost: 'Вы не ввели данные при создании объвления в пункте 2',
            targetingData: 'Вы не выбрали кому показывать объявление в пункте 3',
            campaignData: 'Вы не выбрали где сохранить объявление в пункте 4',
            rate: 'Вы не выбрали стоимость и способ показа объявления в пункте 5',
            moderationSettings: 'Вы не выбрали способ запуска и сохранения объявления в пункте 6'
        };
        this.adFormats = [
            {
                title: 'Рекламная запись в новостной ленте',
                description: 'Объявление будет показываться в ВКонтакте в новостной ленте, на стенах сообществ и в рекламной сети.',
                image: 'assets/images/promopost.jpg',
                selected: true,
                value: 'promopost',
                ad_format: 9,
                min_cpm: 30,
                ad_platform: 'all',
                type: 'promoted_posts'
            },
            {
                title: 'Рекламное объявление на страницах ВКонтакте',
                description: 'Объявление будет показываться в ВКонтакте в левом рекламном блоке',
                image: 'assets/images/ad_teazer.jpg',
                selected: false,
                value: 'teazer',
                ad_format: 2,
                min_cpm: 1.2,
                ad_platform: '0',
                type: 'normal'
            },
        ];
    }
    Object.defineProperty(AdFormComponent.prototype, "AccountRole", {
        get: function () {
            var _a = this.form.value.campaignData.accountId.split('_')
                .map(function (x) { return parseInt(x, 10); }), accountId = _a[0], clientId = _a[1];
            var selectedAccount = null;
            if (isNaN(clientId)) {
                selectedAccount = this.accounts.find(function (account) { return account.account_id === accountId && account.client_id === null; });
            }
            else {
                selectedAccount = this.accounts.find(function (account) { return account.account_id === accountId && account.client_id === clientId; });
            }
            if (selectedAccount) {
                return selectedAccount.account_role;
            }
            return null;
        },
        enumerable: true,
        configurable: true
    });
    AdFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        var type = 'promopost';
        console.log(this.slots.filter(function (x) { return isNullOrUndefined(x.bindedCabinetId); }), this.slots.filter(function (x) { return isNullOrUndefined(x.cabinetId); }).length, this.slots.length, 'EMPTY SLOTS');
        this.showActivatedCabinets = this.slots.filter(function (x) { return isNullOrUndefined(x.bindedCabinetId); }).length === this.slots.length && this.slots.length > 0;
        var teazersTitles = [
            new FormControl('', [Validators.required, Validators.maxLength(33)])
        ];
        var images = new FormControl([''], [Validators.required, imagesSelected]);
        var linkUrl = new FormControl('', [Validators.required, url]);
        var cpm = new FormControl('');
        var strategy = new FormControl();
        var campaignControl = new FormControl(null, [Validators.required]);
        var group = new FormControl();
        var categoryId = new FormControl(null, [Validators.required]);
        var nameControl = new FormControl("\u041E\u0431\u044A\u044F\u0432\u043B\u0435\u043D\u0438\u0435 " + moment(new Date()).format('DD.MM.YYYY HH:mm:SS'));
        var ids = [];
        if (this.ads) {
            console.log(this.ads[0].ad_format === 9);
            ids = this.ads.map(function (ad) { return new FormControl(ad.id); });
            categoryId.setValue(this.ads[0].category1_id || 126);
            nameControl.setValue(this.ads[0].name);
            if (this.ads[0].ad_format === 9) {
                console.log(this.image, 'IMAGE_SRC');
                var b = [this.image];
                linkUrl.setValue(this.ads[0].link_url);
                images.setValue(b);
            }
            else {
                teazersTitles = this.ads.map(function (ad) { return new FormControl(ad.title, [Validators.required, Validators.maxLength(33)]); });
                ids = this.ads.map(function (ad) { return new FormControl(ad.id); });
                images.setValue(this.ads.map(function (x) { return x.image_src; }));
                linkUrl.setValue(this.ads[0].link_url);
                if (this.ads[0].approved === 2) {
                    linkUrl.disable();
                }
            }
            type = this.adFormats.find(function (adFormat) { return _this.ads[0].ad_format === adFormat.ad_format; }).value;
            cpm.setValue(this.ads[0].cpm);
            campaignControl.setValue(this.ads[0].campaign_id);
        }
        if (this.strategy) {
            var inputs = this.strategy.inputs.map(function (input) { return [input.type.code, input.value]; })
                .reduce(function (acc, _a) {
                var key = _a[0], val = _a[1];
                acc[key] = val;
                return acc;
            });
            strategy.setValue(inputs);
        }
        setTimeout(function () {
            _this.vk.GetCategories()
                .subscribe(function (data) {
                _this.categories = data['v1']
                    // .reduce((arr, category) => arr.concat(category), [])
                    // .filter(x => x !== undefined)
                    .sort(function (a, b) { return a.name < b.name ? -1 : 1; });
                console.log(_this.categories);
            });
        }, 4000);
        console.log(this.campaignId);
        var accountIdControl = new FormControl(this.accountId + "_" + this.clientId || null, [Validators.required]);
        // this.cd.detectChanges();
        this.form = new FormGroup({
            format: new FormControl(type, [Validators.required]),
            adId: new FormArray(ids),
            promopost: new FormGroup({
                text: new FormArray([
                    new FormControl('', [Validators.required, Validators.maxLength(650)]),
                ]),
                name: nameControl,
                image: images,
                groupId: new FormControl('', [Validators.required]),
                linkTitle: new FormControl('', [Validators.required]),
                linkAddress: new FormControl('', [Validators.required, urlWithHttpOrHttps]),
                type: new FormControl('linear'),
                categoryId: categoryId
            }),
            teazer: new FormGroup({
                categoryId: categoryId,
                title: new FormArray(teazersTitles),
                name: nameControl,
                link: linkUrl,
                image: images,
                type: new FormControl('linear')
            }),
            targetingData: new FormGroup({
                country: new FormControl((this.targetingSettings) ? this.targetingSettings.country : 1),
                cities: new FormControl((this.targetingSettings) ? this.targetingSettings.cities : [0]),
                sex: new FormControl((this.targetingSettings && this.targetingSettings.sex) ? parseInt(this.targetingSettings.sex, 10) : 0),
                ageFrom: new FormControl((this.targetingSettings) ? this.targetingSettings.age_from : 0),
                ageTo: new FormControl((this.targetingSettings) ? this.targetingSettings.age_to : 0),
                groups: new FormControl((this.targetingSettings) ? this.targetingSettings.groups : ''),
                audience: new FormControl(0, [Validators.min(100)])
            }),
            campaignData: new FormGroup({
                accountId: accountIdControl,
                campaignId: campaignControl
            }),
            rate: new FormGroup({
                type: new FormControl('auto'),
                value: strategy,
                cpm: cpm
            }),
            moderationSettings: new FormGroup({
                sendModeration: new FormControl(false),
                startAfterModeration: new FormControl(false)
            })
        });
        this.cd.detectChanges();
        this.form.valueChanges
            .subscribe(function (value) {
            if (_this.form.controls.rate.controls.type.value === 'auto') {
                if (_this.RateManager &&
                    _this.RateManager.adManagerComp &&
                    _this.RateManager.adManagerComp.adForm) {
                    _this.cd.detectChanges();
                    _this.CanBeNext = _this.form.controls.rate.valid && _this.RateManager.adManagerComp.adForm.form.valid;
                }
                else {
                    _this.cd.detectChanges();
                    _this.CanBeNext = false;
                }
            }
            else {
                _this.cd.detectChanges();
                _this.CanBeNext = _this.form.controls.rate.controls.cpm.valid;
            }
            _this.cd.detectChanges();
        });
        this.form.controls.campaignData.controls.accountId.valueChanges.subscribe(function (data) {
            var _a = data.split('_'), accountId = _a[0], clientId = _a[1];
            _this.accountId = accountId;
            _this.clientId = clientId;
            _this.cd.detectChanges();
        });
        // this.DisableTeazerOrPromopost(this.form.value.format);
        //
        // this.form.controls.format.valueChanges.subscribe(data => {
        //   this.DisableTeazerOrPromopost(data);
        // });
        this.form.controls.moderationSettings.controls.sendModeration.valueChanges.subscribe(function (data) {
            if (!data) {
                _this.form.controls.moderationSettings.controls.startAfterModeration.setValue(false);
            }
        });
        if (!this.strategy && this.isUpdating) {
            console.log(this.form.controls.rate.controls, 'STRATEGY');
            this.form.controls.rate.controls.type.setValue('custom');
        }
        if (this.ads && this.ads[0].ad_format === 9) {
            this.form.controls['promopost'].controls['groupId'].setValue(Math.abs(this.ads[0].wall.owner_id));
            this.form.controls['promopost'].controls['linkTitle'].setValue(this.ads[0].wall.attachments[0].link.title);
            this.form.controls['promopost'].controls['linkAddress'].setValue(this.ads[0].wall.attachments[0].link.url);
            this.ads.forEach(function (ad, i) {
                console.log(_this.form.controls['promopost'].controls['text']);
                _this.form.controls['promopost'].controls['text'].controls[i].setValue(ad.wall.text);
            });
            console.log(this.ads[0].wall.text);
        }
        if (this.isUpdating) {
            console.log(this.AdTargeting);
            console.log(this.targetingSettings);
            this.AdTargeting.LoadStats(this.accountId, this.clientId, this.targetingSettings);
            if (this.targetingSettings.groups && this.targetingSettings.groups.length > 0) {
                setTimeout(function () {
                    _this.vk.GetGroupsById(_this.targetingSettings.groups.split(','))
                        .subscribe(function (groups) {
                        _this.groups.SelectGroups(groups.map(function (group) { return new VkGroupViewModel().fill(group); }));
                    });
                }, 750);
            }
        }
        this.form.valueChanges.subscribe(function (data) {
            console.log(data);
        });
        this.form.controls.format.valueChanges.subscribe(function (data) {
            _this.form.controls.teazer.controls.image.setValue(['']);
            _this.form.controls.promopost.controls.image.setValue(['']);
        });
    };
    AdFormComponent.prototype.GetSelectedFormatePreview = function () {
        return "assets/images/ad-format-preview-" + this.form.value.format + ".jpg";
    };
    Object.defineProperty(AdFormComponent.prototype, "SelectedFormatType", {
        // private DisableTeazerOrPromopost(data) {
        //   console.log(data, 'AD_FORM 234');
        //   if (data === 'teazer') {
        //     this.form.controls.teazer.enable();
        //     this.form.controls.teazer.controls.image.enable();
        //     this.form.controls.promopost.disable();
        //   } else {
        //     this.form.controls.promopost.enable();
        //     console.log(this.form.controls.promopost.controls.image, 'PROMOPOST_240');
        //     this.form.controls.promopost.controls.image.enable();
        //     console.log(this.form.controls.promopost.controls.image, 'PROMOPOST_240');
        //     this.form.controls.teazer.disable();
        //   }
        // }
        get: function () {
            var _this = this;
            return this.adFormats.find(function (x) { return x.value === _this.form.controls.format.value; }).type;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdFormComponent.prototype, "FormatAdType", {
        get: function () {
            var _this = this;
            return this.adFormats.find(function (format) { return _this.form.controls.format.value === format.value; }).ad_format;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdFormComponent.prototype, "ModerationSettingsDisabled", {
        get: function () {
            var _this = this;
            var validKeys = ['text', 'image', 'title'];
            var format1 = this.adFormats.find(function (format) { return format.value === _this.form.controls.format.value; });
            var dataKey = 'promopost';
            if (format1.ad_format === 9) {
                dataKey = 'promopost';
            }
            else {
                dataKey = 'teazer';
            }
            var canBeModerationChanged = Object.keys(this.form.controls[dataKey].controls)
                .filter(function (control) { return validKeys.includes(control); })
                .reduce(function (result, control) { return result + _this.form.controls[dataKey].controls[control].dirty; }, false);
            return canBeModerationChanged;
        },
        enumerable: true,
        configurable: true
    });
    AdFormComponent.prototype.SaveAd = function () {
        var val = Object.assign({}, this.form.value);
        val.targetingData.cities = this.form.value.targetingData.cities.filter(function (x) { return x >= 0; }).join();
        console.log(this.RateManager);
        val.format = this.adFormats.find(function (format) { return format.value === val.format; });
        console.log(this.form.controls.rate.controls.value.dirty);
        this.adSaved.emit({
            ad: val,
            strategy: this.RateManager.strategy,
            updateStrategy: this.form.controls.rate.controls.value.dirty
        });
    };
    AdFormComponent.prototype.OnAddTextControl = function () {
        var control = new FormControl('', [Validators.required, Validators.maxLength(16687)]);
        this.form.controls.promopost.controls.text.controls.push(control);
        control.setValue('');
        console.log(this.form.controls.promopost.controls.text.controls);
        this.form.controls.promopost.controls.text.valueChanges.subscribe(function (data) {
            console.log(data);
        });
    };
    AdFormComponent.prototype.OnRemoveTextControl = function (id) {
        this.form.controls.promopost.controls.text.controls.slice(id, 1);
        this.form.valueChanges.subscribe(function (data) {
            console.log(data);
        });
    };
    AdFormComponent.prototype.OnAddTitleControl = function () {
        this.form.controls.teazer.text.controls.push(new FormControl('', [Validators.required, Validators.maxLength(16687)]));
        this.form.valueChanges.subscribe(function (data) {
            console.log(data);
        });
    };
    AdFormComponent.prototype.OnRemoveTitleControl = function (id) {
        this.form.controls.teazer.text.controls.slice(id, 1);
        this.form.valueChanges.subscribe(function (data) {
            console.log(data);
        });
    };
    AdFormComponent.prototype.SelectRate = function () {
        this.stepper.next();
        console.log(this.form.controls.rate.controls.type.value === 'custom', 'SELECT CAMPAIGN');
        if (this.form.controls.rate.controls.type.value === 'custom') {
            this.form.controls.rate.controls.value.setValue(null);
            this.form.controls.rate.controls.value.markAsDirty();
        }
    };
    AdFormComponent.prototype.NextSlideAfterTarget = function (doNext) {
        if (doNext) {
            this.stepper.next();
        }
    };
    AdFormComponent.prototype.CanBeModerated = function () {
        var stat = false;
        if (this.isUpdating) {
            var type = this.form.controls.format.value;
            if (type === 'promopost') {
                stat = this.form.controls.promopost.dirty;
            }
            else {
                stat = this.form.controls.teazer.dirty;
            }
        }
        else {
            stat = true;
        }
        return stat;
    };
    AdFormComponent.prototype.getErrors = function () {
        var _this = this;
        var errorsMessages = Object.keys(this.form.controls)
            .map(function (key) { return ({ key: key, control: _this.form.controls[key] }); })
            .filter(function (_a) {
            var key = _a.key, control = _a.control;
            return control.invalid && key;
        })
            .map(function (_a) {
            var key = _a.key;
            return ({ key: key, message: _this.errors[key] });
        });
        if (this.form.value.format === 'promopost') {
            errorsMessages = errorsMessages
                .filter(function (x) { return x.key !== 'teazer'; });
        }
        else {
            errorsMessages = errorsMessages
                .filter(function (x) { return x.key !== 'promopost'; });
        }
        return errorsMessages
            .map(function (x) { return x.message; });
    };
    AdFormComponent.prototype.isFormInvalid = function () {
        var _this = this;
        var controlNames = Object.keys(this.form.controls);
        if (this.form.value.format === 'promopost') {
            controlNames = controlNames.filter(function (x) { return x !== 'teazer'; });
        }
        else {
            controlNames = controlNames.filter(function (x) { return x !== 'promopost'; });
        }
        return controlNames.reduce(function (arr, curr) {
            return arr && _this.form.controls[curr].invalid;
        }, true);
    };
    // CanBeNext() {
    //   if (this.form.controls.rate.controls.type.value === 'auto') {
    //     if (
    //       this.RateManager &&
    //       this.RateManager.adManagerComp &&
    //       this.RateManager.adManagerComp.adForm
    //     ) {
    //       this.cd.detectChanges();
    //       return this.form.controls.rate.valid && this.RateManager.adManagerComp.adForm.form.valid;
    //     } else {
    //       this.cd.detectChanges();
    //       return false;
    //     }
    //   } else {
    //     this.cd.detectChanges();
    //     return this.form.controls.rate.controls.cpm.valid;
    //   }
    // }
    AdFormComponent.prototype.HaveActivatedAccounts = function () {
        return this.slots.filter(function (x) { return !isNullOrUndefined(x.bindedCabinetId); }).length > 0;
    };
    return AdFormComponent;
}());
export { AdFormComponent };
