import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges, ViewChild
} from '@angular/core';
import {FormArray, FormControl, FormGroup} from '@angular/forms';
import * as _ from 'lodash';
import {isNullOrUndefined} from 'util';
import {DynamicFormValidator} from '../../models/dynamic-form-validator';
import {DynamicFormInput} from '../../models/dynapic-form-input';
import {TargetingSettingsComponent} from '../../../automation/ad-manager/components/steps/targeting-settings/targeting-settings.component';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss']
})
export class DynamicFormComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {

  @Input() public inputs: { string: DynamicFormInput };
  @Input() public OkActionText;
  @Input() public CancelActionText;

  public errorMessages: object = {};

  @Output() public Successed: EventEmitter<any> = new EventEmitter<any>();
  @Output() public Canceled: EventEmitter<any> = new EventEmitter<any>();

  @Output() public Inited: EventEmitter<any> = new EventEmitter<any>();

  public get InputFields() {
    if (this.inputs) {
      return Object
        .keys(this.inputs)
        .map(x => this.inputs[x]);
    } else {
      return [];
    }
  }

  public form: FormGroup = new FormGroup({});

  constructor() {
  }

  ngOnInit() {
    // if (this.inputs) {
    //   const formInputs = {};
    //   Object.keys(this.inputs)
    //     .forEach(x => {
    //       const input = this.inputs[x];
    //       formInputs[x] = new FormControl(input.value || '');
    //     });
    //   this.adForm = new FormGroup(formInputs);
    // }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const valueChanges = this.form.valueChanges;
    const formInputs = {};
    if (this.inputs) {
      Object.keys(this.inputs)
        .forEach(x => {
          const input: DynamicFormInput = this.inputs[x];
          switch (input.type) {
            case 'checkbox-list':
              formInputs[x] = new FormArray(input.options.map(() => {
                return new FormControl(false);
              }));
              break;
            default:
              console.log(input, input.Validators);
              formInputs[x] = new FormControl(input.value || '', (input.Validators) ? input.Validators.map(val => val.validator) : []);
              break;
          }

          this.errorMessages[input.name] = input.Validators.reduce((store, {code, message}) => {
            console.log(code, message, 'validator messages');
            store[code] = message;
            return store;
          }, {});
        });
      this.form = new FormGroup(formInputs);
      this.form.valueChanges.subscribe(data => {
        this.SuccessAction();
      });
      this.Inited.emit(this.form);

    }
  }

  public SuccessAction() {
    const emittedValue = {};
    if (this.form.valid) {
      for (const key of Object.keys(this.form.value)) {
        const input = this.inputs[key];
        let value = this.form.value[key];

        if (input.type === 'checkbox-list') {
          value = Object.keys(value)
            .filter(x => value[x])
            .map(x => Object.keys(x)[0])
            .map(x => input.options[x]);

        }
        if (input.type === 'number') {
          console.log(value);
          if (typeof value === 'string') {
            value = parseFloat(value.replace(',', '.'));
          }
          console.log(value);
        }
        if (input.modifyFunction) {
          emittedValue[key] = input.modifyFunction(value);
        } else {
          emittedValue[key] = value;
        }
      }

      if (!_.isEmpty(emittedValue)) {
        this.Successed.emit(emittedValue);
      }
    }
  }

  public CancelAction() {
    this.Canceled.emit();
  }

  public IsOptionSelected(optionId, inputName) {
    return parseInt(optionId, 10) === parseInt(this.form.value[inputName], 10);
  }

  public GetSelectOptionNumber(optionId) {
    return parseInt(optionId, 10);
  }

  public ValidateInput(value, inputName) {
    const val = this.form.value;
    console.log(val[inputName], val[inputName].match(/[^\d\.,]/gm));
    val[inputName] = val[inputName].replace(/[^\d\.,]/gm, '');
    console.log(val);
    this.form.setValue(val);
  }

  public CheckedCheckbox(input, checkbox) {
    let a = null;
    if (!isNullOrUndefined(input.value)) {
      a = input.value.find(x => x === checkbox);
    }
    return a !== null;
  }

  public GetErrors(inputName) {
    const input = this.form.controls[inputName];
    if (input.dirty) {
      const errors = input.errors;
      if (errors) {
        const errorKeys = Object.keys(errors);
        console.log(errorKeys, 'errorKeys');
        if (errorKeys) {
          const errorMessages = errorKeys.map(err => {
            console.log(this.errorMessages[inputName], err);
            return this.errorMessages[inputName][err.toUpperCase()];
          });
          console.log(errorMessages);
          return errorMessages;
        }
        return [];
      }
      return [];
    }
    return [];
  }


  ngOnDestroy(): void {
    // this.InputFields = [];
    this.form = null;
  }

  ngAfterViewInit(): void {
    const numberInputs = Array.from(document.querySelectorAll('input[type=number]'));
    numberInputs.forEach(input => {
      if (input.addEventListener) {
        if ('onwheel' in document) {
          // IE9+, FF17+, Ch31+
          input.addEventListener('wheel', this.onWheel);
        } else if ('onmousewheel' in document) {
          // устаревший вариант события
          input.addEventListener('mousewheel', this.onWheel);
        } else {
          // Firefox < 17
          input.addEventListener('MozMousePixelScroll', this.onWheel);
        }
      }
    });
  }

  onWheel(e) {
    e = e || window.event;
    e.preventDefault ? e.preventDefault() : (e.returnValue = false);
  }

}
