import { Component, OnInit } from '@angular/core';
import {UserBalanceFacade} from "../../../../../storage/user-balance/state/user-balance.facade";
import {AffiliateLinksService} from "../../../../../storage/affiliate-links/state/affiliate-links.service";
import {AffiliateLinksQuery} from "../../../../../storage/affiliate-links/state/affiliate-links.query";
import {AffiliateLink} from "../../../../../storage/affiliate-links/state/affiliate-link.model";
import {Observable} from "rxjs";
import {environment} from "../../../../../../environments/environment";

@Component({
  selector: 'app-affiliate-program-settings-report-route',
  templateUrl: './affiliate-program-settings-report-route.component.html',
  styleUrls: ['./affiliate-program-settings-report-route.component.scss']
})
export class AffiliateProgramSettingsReportRouteComponent implements OnInit {

  public get affiliateLinks$(): Observable<AffiliateLink> {
    return this.affiliateLinksQuery.selectFirst();
  }

  public get affiliateBaseUrl() {
    return environment.affiliateProgram.affiliateRootLink;
  }

  constructor(
    private userBalanceFacade: UserBalanceFacade,
    private affiliateLinksService: AffiliateLinksService,
    private affiliateLinksQuery: AffiliateLinksQuery
  ) { }

  ngOnInit() {
    this.userBalanceFacade.LoadCurrentBalance();
    this.affiliateLinksService.LoadAffiliateLink();
  }
}
