/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./multiple-copy-form.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./multiple-copy-form.component";
import * as i3 from "@angular/material/dialog";
import * as i4 from "../../../../api/services/ad-manager.service";
import * as i5 from "../../../../api/services/automation-vk.service";
import * as i6 from "../../../../api/services/automation-vk-slot.service";
import * as i7 from "@angular/router";
var styles_MultipleCopyFormComponent = [i0.styles];
var RenderType_MultipleCopyFormComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MultipleCopyFormComponent, data: {} });
export { RenderType_MultipleCopyFormComponent as RenderType_MultipleCopyFormComponent };
export function View_MultipleCopyFormComponent_0(_l) { return i1.ɵvid(0, [], null, null); }
export function View_MultipleCopyFormComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-mltiple-copy-form", [], null, null, null, View_MultipleCopyFormComponent_0, RenderType_MultipleCopyFormComponent)), i1.ɵdid(1, 114688, null, 0, i2.MultipleCopyFormComponent, [i3.MatDialogRef, i4.AdManagerService, i3.MAT_DIALOG_DATA, i5.AutomationVkService, i6.AutomationVkSlotService, i3.MatDialog, i7.ActivatedRoute, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MultipleCopyFormComponentNgFactory = i1.ɵccf("app-mltiple-copy-form", i2.MultipleCopyFormComponent, View_MultipleCopyFormComponent_Host_0, {}, {}, []);
export { MultipleCopyFormComponentNgFactory as MultipleCopyFormComponentNgFactory };
