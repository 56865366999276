<div class="ad-network-group" [hidden]="!(isGroupSearched$ | async)">
  <div class="ad-network-group__info" (click)="OpenGroup()">
    <ng-container *ngIf="group?.avatar">
      <img [src]="group.avatar" [alt]="group.name" class="ad-network-group__avatar">
    </ng-container>
    <div class="ad-network-group__name">{{group.name}}</div>
  </div>
  <div class="ad-network-group__forms" [hidden]="!(isOpened$ | async)">
    <div *ngIf="!(isLoading$ | async); else loader">
      <ng-container *ngIf="(forms$ | async) as forms">
        <div [formGroup]="control" [hidden]="!forms.length > 0">
<!--          <cdk-virtual-scroll-viewport itemSize="40" style="height: 500px;">-->
<!--            <div *cdkVirtualFor="let form of data">-->
<!--              {{form}}-->
<!--            </div>-->
<!--          </cdk-virtual-scroll-viewport>-->
          <app-checkboxes-group [formControlName]="group.id">
            <ng-container *ngFor="let form of forms$ | async; trackBy: formTrackByFunc">
              <div>
                <mat-checkbox
                  class="no-margin-checkbox"
                  [id]="'form-' + form.id"
                  [name]="'form-' + form.id"
                  [formControl]="controlOfForm"
                  *appCheckboxItem="form.id; let controlOfForm = control"
                >
                  {{form.name}}
                </mat-checkbox>
              </div>
            </ng-container>
          </app-checkboxes-group>

        </div>
        <ng-container *ngIf="!(isLoading$ | async)">
          <div [hidden]="forms.length > 0">
            {{notFoundMessage}}
          </div>
        </ng-container>
      </ng-container>
    </div>
    <ng-template #loader>
      <app-loading-screen [hidden]="!(isLoading$  | async)">
        {{loadingMessage}}
      </app-loading-screen>
    </ng-template>
  </div>
</div>
