import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {AdFormManagerService} from "../../../new-ad-manager-shared/services/ad-form-manager/ad-form-manager.service";

@Component({
  selector: 'app-ad-accounts-not-found',
  templateUrl: './ad-accounts-not-found.component.html',
  styleUrls: ['./ad-accounts-not-found.component.scss']
})
export class AdAccountsNotFoundComponent implements OnInit {

  @Output() public adminAccountBinded: EventEmitter<any> = new EventEmitter<any>();

  public loading: boolean;

  constructor(
    private adFormManagerService: AdFormManagerService
  ) {
  }

  ngOnInit() {
  }

  Refresh() {
    this.loading = true;
    this.adFormManagerService.RefreshAccountsData()
      .subscribe(data => {
        if (this.adFormManagerService.HasAssignedAdminAccount()) {
          this.adminAccountBinded.emit();
        }
        this.loading = false;
      })
  }
}
