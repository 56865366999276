var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Query } from '@datorama/akita';
import { AffiliateUserInfoStore } from './affiliate-user-info.store';
import * as i0 from "@angular/core";
import * as i1 from "./affiliate-user-info.store";
var AffiliateUserInfoQuery = /** @class */ (function (_super) {
    __extends(AffiliateUserInfoQuery, _super);
    function AffiliateUserInfoQuery(store) {
        var _this = _super.call(this, store) || this;
        _this.store = store;
        _this.affiliateUserInfo$ = _this.select(function (x) { return x; });
        return _this;
    }
    Object.defineProperty(AffiliateUserInfoQuery.prototype, "affiliateUserInfo", {
        get: function () {
            return this.getValue();
        },
        enumerable: true,
        configurable: true
    });
    AffiliateUserInfoQuery.ngInjectableDef = i0.defineInjectable({ factory: function AffiliateUserInfoQuery_Factory() { return new AffiliateUserInfoQuery(i0.inject(i1.AffiliateUserInfoStore)); }, token: AffiliateUserInfoQuery, providedIn: "root" });
    return AffiliateUserInfoQuery;
}(Query));
export { AffiliateUserInfoQuery };
