/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
class CustomFormService extends __BaseService {
  static readonly Apiv2CustomFormCustomFunctionalPostPath = '/apiv2/custom-form/custom-functional';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `CustomFormService.Apiv2CustomFormCustomFunctionalPostParams` containing the following parameters:
   *
   * - `Phone`:
   *
   * - `FullName`:
   *
   * - `Email`:
   *
   * - `Description`:
   *
   * - `CompanyWebsite`:
   *
   * - `CompanyPosition`:
   *
   * - `CompanyName`:
   */
  Apiv2CustomFormCustomFunctionalPostResponse(params: CustomFormService.Apiv2CustomFormCustomFunctionalPostParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;
    if (params.Phone != null) { __formData.append('Phone', params.Phone as string | Blob);}
    if (params.FullName != null) { __formData.append('FullName', params.FullName as string | Blob);}
    if (params.Email != null) { __formData.append('Email', params.Email as string | Blob);}
    if (params.Description != null) { __formData.append('Description', params.Description as string | Blob);}
    if (params.CompanyWebsite != null) { __formData.append('CompanyWebsite', params.CompanyWebsite as string | Blob);}
    if (params.CompanyPosition != null) { __formData.append('CompanyPosition', params.CompanyPosition as string | Blob);}
    if (params.CompanyName != null) { __formData.append('CompanyName', params.CompanyName as string | Blob);}
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/apiv2/custom-form/custom-functional`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `CustomFormService.Apiv2CustomFormCustomFunctionalPostParams` containing the following parameters:
   *
   * - `Phone`:
   *
   * - `FullName`:
   *
   * - `Email`:
   *
   * - `Description`:
   *
   * - `CompanyWebsite`:
   *
   * - `CompanyPosition`:
   *
   * - `CompanyName`:
   */
  Apiv2CustomFormCustomFunctionalPost(params: CustomFormService.Apiv2CustomFormCustomFunctionalPostParams): __Observable<null> {
    return this.Apiv2CustomFormCustomFunctionalPostResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module CustomFormService {

  /**
   * Parameters for Apiv2CustomFormCustomFunctionalPost
   */
  export interface Apiv2CustomFormCustomFunctionalPostParams {
    Phone?: string;
    FullName?: string;
    Email?: string;
    Description?: string;
    CompanyWebsite?: string;
    CompanyPosition?: string;
    CompanyName?: string;
  }
}

export { CustomFormService }
