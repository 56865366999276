import {Injectable} from '@angular/core';
import {VkApiService} from './vk-api.service';
import {VkApiPost} from '../models/api/vk-api-post';
import {VkApiWallGetResponse} from '../models/api/vk-api-wall-get-response';

@Injectable()
export class VkStealthParserHelperService {
  private readonly gapTreshold: number = 100;
  private readonly gapCountPreAPIRequest: number = 100;
  private readonly lookaheadPostsPerRequestAmount: number = 100;
  private readonly lookaheadMaximalUselessRequestsPerRow: number = 3;

  public visitedPostsIdList: Array<number> = [];

  constructor(private VkApi: VkApiService) {
  }

  public async LookaheadScan(group_id: number, scan_from_id: number): Promise<Array<VkApiPost>> {
    this.visitedPostsIdList = [];

    let uselessRequestsLeft = this.lookaheadMaximalUselessRequestsPerRow;

    const stealthAds: Array<VkApiPost> = [];

    do {
      
      const idsList = this.GenerateArray(scan_from_id, scan_from_id + this.lookaheadPostsPerRequestAmount);

      this.visitedPostsIdList.push.apply(this.visitedPostsIdList, idsList);

      const ids = this.ConvertIdListToWallGetByIdParameter(group_id, idsList);

      const response = await this.VkApi
        .WallGetById(ids, false, 1, null)
        .toPromise();

      scan_from_id += this.lookaheadPostsPerRequestAmount;

      if (response && response.length > 0) {
        const ads = response.filter(x => x.post_type === 'post_ads');
        

        if (ads && ads.length > 0) {
          ads.forEach(ad => {
            stealthAds.push(ad);
          });

          uselessRequestsLeft = this.lookaheadMaximalUselessRequestsPerRow + 1;
        }
      }

      await this.Delay(this.VkApi.timeout);

      uselessRequestsLeft -= 1;
    } while (uselessRequestsLeft >= 0);

    return stealthAds;
  }

  public async RetrieveStealthAds(gaps: Array<number>, group_id: number): Promise<Array<VkApiPost>> {
    let index = 0;

    const stealthAds: Array<VkApiPost> = [];

    // Collect post with certain id & retrieve stealth
    while (index < gaps.length) {
      

      // Take portion of gaps
      const idsList = gaps.slice(index, index + this.gapCountPreAPIRequest);

      const ids = this.ConvertIdListToWallGetByIdParameter(group_id, idsList);

      // Parse via api
      const response = await this.VkApi
        .WallGetById(ids, false, 1, null)
        .toPromise();

      if (response && response.length > 0) {
        const ads = response.filter(x => x.post_type === 'post_ads');

        if (ads && ads.length > 0) {
          
          ads.forEach(ad => {
            stealthAds.push(ad);
          });
        }
      }

      await this.Delay(this.VkApi.timeout);

      index += this.gapCountPreAPIRequest;
    }

    return stealthAds;
  }

  public async Delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  public DetectGapsInWall(postIdList: Array<number>): Array<number> {
    const gaps: Array<number> = [];

    let currentId = postIdList[0] - 1;

    if (postIdList.length >= 2) {
      for (let i = 1; i < postIdList.length; i = i + 1) {
        const value = postIdList[i];

        if (currentId !== value) {
          const difference = Math.abs(currentId - value);
          if (difference <= this.gapTreshold) {
            const newGaps = this.GenerateArray(value + 1, value + 1 + difference);
            gaps.push.apply(gaps, newGaps);
          }
        }

        currentId = value - 1;
      }
    }

    return gaps;
  }

  private GenerateArray(start: number, end: number): Array<number> {
    const foo: Array<number> = [];

    for (let i = start; i <= end; i++) {
      foo.push(i);
    }

    return foo;
  }

  private ConvertIdListToWallGetByIdParameter(group_id: number, ids: Array<number>): Array<string> {
    return ids.map(function (element) {
      return `${-group_id}_${element}`;
    });
  }
}
