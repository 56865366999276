import { Injectable } from '@angular/core';
import {FormGroup} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class AbstractFormServiceService {

  constructor(
    public adForm: FormGroup
  ) { }

  public getControl(...args: Array<string>) {
    const firstControlName = args.shift();
    let control = this.adForm.controls[firstControlName];
    for (const controlName of args) {
      if (control) {
        control = control.get(controlName);
      }
    }
    return control;
  }
}
