<ng-container *ngIf="(statistics$ | async).length > 0; else emptyDataTable">
  <table mat-table [dataSource]="statistics$" width="100%">

    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

    <!-- Position Column -->
    <!--    -->
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef width="auto">Email клиента</th>
      <td mat-cell *matCellDef="let element">
        {{element.email}}
      </td>
    </ng-container>

    <ng-container matColumnDef="invitedAt">
      <th mat-header-cell *matHeaderCellDef width="auto">Дата регистрации</th>
      <td mat-cell *matCellDef="let element"> {{element.invitedAt | momentDate:"DD MMMM YYYY"}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef width="auto">Сумма вознаграждения</th>
      <td mat-cell *matCellDef="let element"> {{element.amount | currency:"руб."}} </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="countOfTransactions">
      <th mat-header-cell *matHeaderCellDef width="auto">Колличество оплат</th>
      <td mat-cell *matCellDef="let element"> {{element.countOfTransactions}} </td>
    </ng-container>

    <ng-container matColumnDef="invitingFinishing">
      <th mat-header-cell *matHeaderCellDef width="auto">Окончание срока привязки клиента</th>
      <td mat-cell *matCellDef="let element"> {{element.invitingFinishedAt | momentDate:"DD MMMM YYYY"}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</ng-container>
<ng-template #emptyDataTable>
  <table mat-table [dataSource]="[1]" width="100%">

    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

    <!-- Position Column -->
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef width="auto">Email клиента</th>
      <td mat-cell *matCellDef="let element" colspan="4">
        <strong>Пока ни один пользователь не произвел оплату</strong>
      </td>
    </ng-container>

    <ng-container matColumnDef="invitedAt">
      <th mat-header-cell *matHeaderCellDef width="auto">Дата регистрации</th>
      <td mat-cell *matCellDef="let element" colspan="0"></td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef width="auto">Сумма вознаграждения</th>
      <td mat-cell *matCellDef="let element" colspan="0"></td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="countOfTransactions">
      <th mat-header-cell *matHeaderCellDef width="auto">Колличество оплат</th>
      <td mat-cell *matCellDef="let element" colspan="0"></td>
    </ng-container>

    <ng-container matColumnDef="invitingFinishing">
      <th mat-header-cell *matHeaderCellDef width="auto">Окончание срока привязки клиента</th>
      <td mat-cell *matCellDef="let element" colspan="0"></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</ng-template>
