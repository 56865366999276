import { Injectable } from '@angular/core';
import {WallPost} from '../../api/models/wall-post';
import {WallLink} from '../../api/models/wall-link';
import {WallPrettyCards} from '../../api/models/wall-pretty-cards';
import {WallCard} from '../../api/models/wall-card';

@Injectable()
export class UrlExctractorService {

  constructor() { }

  public ExtractUrl(post: WallPost): Array<string> {
    if (!post) {
      return null;
    }

    // Extract UTM from TEXT
    const textUrl = this.ExtractUrlFromText(post.text);

    const urls = [];
    urls.push.apply(urls, textUrl);

    // Extract UTM from attachments
    // Current procssed: link, carousel (pretty_cards)
    if (post.attachments) {
      post.attachments.forEach(attachment => {
        // Exctract from link
        if (attachment.link) {
          urls.push(this.ExtractUrlFromLink(attachment.link));

        } else if (attachment.pretty_cards) {
          urls.push.apply(urls, this.ExtractUrlFromPrettyCards(attachment.pretty_cards));
        }

        // TODO Decide if we should process photo, video & animation
      });
    }

    // Clear identical urls
    return urls.filter(function (item, i, ar) {
      return ar.indexOf(item) === i;
    });
  }

  private ExtractUrlFromText(text: string): Array<string> {
    return text.match(/(http(s)?:\/\/)?(www[.])?([a-zа-я0-9\-\_\.]+)([.]([a-z]{2,}|(рф|укр|бел)))(|:[0-9]{2,8})?([/]([a-zа-я0-9\-\_\/?&=.%#~+\@]+)?)?/ig);
  }

  private ExtractUrlFromLink(link: WallLink): string {
    // Check if  all necessary properties are provided
    if (!link) {
      console.error(`ExtractUTMFromLink ERROR: link is not presented!`);
      return null;
    }

    return link.url;
  }

  private ExtractUrlFromPrettyCards(PrettyCards: WallPrettyCards): Array<string> {
    // Check if  all necessary properties are provided
    if (!PrettyCards || !PrettyCards.cards) {
      console.error(`ExtractUTMFromPrettyCards ERROR: PrettyCards or PrettyCards.cards are not presnted!`);
      return [];
    }

    const marks = [];

    PrettyCards.cards.forEach((card: WallCard) => {
      // Extract UTM from card's url
      const cardUtmMark = card.link_url;

      // If we found some UTM - append it to previously found UTM
      if (cardUtmMark) {
        marks.push(cardUtmMark);
      }
    });

    return marks;
  }
}
