import { Component, OnInit } from '@angular/core';
import { IntegrationAccessService } from '../../../api/services';

@Component({
  selector: 'app-integration-access-denied',
  templateUrl: './integration-access-denied.component.html',
  styleUrls: ['./integration-access-denied.component.scss'],
  //providers: [IntegrationAccessService]
})
export class IntegrationAccessDeniedComponent implements OnInit {
  private _hasNoAccessHistory: boolean = true;
  public get hasNoAccessHistory(): boolean {
    return this._hasNoAccessHistory;
  }

  constructor(private integrationAccessService: IntegrationAccessService) {
  }

  ngOnInit() {
    this._hasNoAccessHistory = true;
    this.integrationAccessService
      .GetCurrentAccessSession()
      .subscribe((response) => {
        if (response) {
          if (response.data) {
            this._hasNoAccessHistory = false;
            // TODO Redirect back
            // try {
            //   const accessTo =  new Date(response.data.accessTo);
            //   accessTo.setHours(0,0,0,0);

            //   const currentDate = new Date();
            //   currentDate.setHours(0,0,0,0);

            //   const hasAccess = currentDate < accessTo;

            //   if (hasAccess) {
            //     //this.fillSessionData(response.data);

            //     return;
            //   }
            // } catch(exception) {
            //   console.error(exception);
            // }
          }
        }

        //this.NavigateToAccessDeniedPage();
      });
  }

}
