import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AdNetworkForm} from "../../../../stores/ad-network-forms/ad-network-form.model";
import {IntegrationPlatformSection} from "../../../../../api/models/integration-platform-section";
import {FormGroup} from "@angular/forms";
import {CrmFieldOptionModel} from "../../models/crm-field-option.model";
import {NewImportCrmService} from "../../services/new-import-crm/new-import-crm.service";
import {CrmPipelinesQuery} from "../../../../stores/crm-pipelines/crm-pipelines.query";
import {distinctUntilChanged} from "rxjs/operators";
import {untilDestroyed} from "ngx-take-until-destroy";
import {isNullOrUndefined} from "util";

@Component({
  selector: 'app-new-import-crm-section',
  templateUrl: './new-import-crm-section.component.html',
  styleUrls: ['./new-import-crm-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewImportCrmSectionComponent implements OnInit, OnDestroy {

  public isVisibleSettings = false;
  private formValues = [];

  @Input() public form: AdNetworkForm;
  @Input() public formGroup: FormGroup;
  @Input() public parentForm: FormGroup;
  @Input() public formQuestions: CrmFieldOptionModel[];
  @Input() public section: IntegrationPlatformSection;
  @Input() public advancedSettings: any[];

  public get CustomFields() {
    return this.newImportCrmService.customFields;
  }

  public get isSectionSelected() {
    return this.formGroup.get('selected').value;
  }

  public CustomFieldsValues(Id: number | string) {
    let fields = this.newImportCrmService.customFieldValues;
    if (fields.find(field => field.CustomFieldId === Id)) {
      fields = fields.filter(x => x.CustomFieldId === Id);
    } else {
      fields = fields.filter(x => isNullOrUndefined(x.CustomFieldId));
    }
    return fields;
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private newImportCrmService: NewImportCrmService,
    private pipelinesQuery: CrmPipelinesQuery
  ) {
  }

  ngOnInit() {
    if (this.formGroup && this.formGroup.get('fields') && this.formGroup.get('fields').get('PM_PIPELINE_ID') && this.formGroup.get('fields').get('PM_STAGE_ID')) {
      this.formGroup.get('fields').get('PM_PIPELINE_ID')
        .valueChanges
        .pipe(
          distinctUntilChanged(),
          untilDestroyed(this)
        )
        .subscribe((pipelineId) => {
        const stages = this.pipelinesQuery.GetPipelineBySectionCode(this.section.code, pipelineId).statuses.map((stage) => ({
          Key: stage.selectable_id,
          Label: stage.name
        } as CrmFieldOptionModel));
        this.newImportCrmService.SetStagesOptions(stages);
      })
    }
  }

  ToggleSettings() {
    this.isVisibleSettings = !this.isVisibleSettings;
    this.cdr.markForCheck();
  }

  public GetOptionFields(fieldCode: string) {
    return this.newImportCrmService.getFieldOptions(fieldCode, this.formQuestions);
  }

  ngOnDestroy(): void {
  }
}
