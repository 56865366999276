import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {AffiliateProgramStatisticsRoutingModule} from './affiliate-program-statistics-routing.module';
import {AffiliateProgramStatisticsLayoutComponent} from './components/affiliate-program-statistics-layout/affiliate-program-statistics-layout.component';
import {MatTabsModule} from "@angular/material/tabs";
import {AffiliateProgramStatisticsByDateModule} from "./affiliate-program-statistics-by-date/affiliate-program-statistics-by-date.module";
import {AffiliateProgramStatisticsByUserModule} from "./affiliate-program-statistics-by-user/affiliate-program-statistics-by-user.module";

@NgModule({
  declarations: [AffiliateProgramStatisticsLayoutComponent],
  imports: [
    CommonModule,
    AffiliateProgramStatisticsRoutingModule,
    MatTabsModule,
    AffiliateProgramStatisticsByDateModule,
    AffiliateProgramStatisticsByUserModule,
  ]
})
export class AffiliateProgramStatisticsModule {
}
