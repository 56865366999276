<ng-container *ngIf="!selectedStrategy; else strategySelected">
  <app-strategy-selection-form [strategies]="suitableStrategies"
                               (onStrategyConfirmed)="StrategySelected($event)"></app-strategy-selection-form>
</ng-container>
<ng-template #strategySelected>
  <ng-container *ngIf="strategyCreated === 0; else strategyCreationInProcess">
    <div class="selected-strategy">
      <div class="selected-strategy__name">
        <div class="selected-strategy-name">
          {{selectedStrategy.title}}
        </div>
      </div>
      <div class="selected-strategy__controls">
        <ng-container *ngIf="allowChangeSelectedStrategy">
          <button class="vk-standart-button" (click)="RemoveSelectedStrategy()">
            Изменить способ
          </button>
        </ng-container>
        <ng-template #selectedStrategyChangingDisabled>
          <span class="disabled-text">Невозможно изменить способ</span>
        </ng-template>
      </div>
    </div>

    <div class="selected-strategy-inputs">
      <div class="selected-strategy-inputs__header">
        Основные параметры
      </div>

      <ng-container *ngFor="let input of selectedStrategy.inputs">
        <ng-container *ngIf="GetInputFromPrimaries(input.id) as model">
          <div [ngClass]='{
              "selected-strategy-inputs__item": true,
              "selected-strategy-inputs__item_disabled": !input.allowEditing && mode === "UPDATE"
            }' [ngStyle]="{ 'flex-basis': '' + input.frontendFlexWidth + '%'}">
            <app-strategy-creation-form-input
              [disabled]="mode === 'UPDATE' && !input.allowEditing"
              [input]="input"
              [model]="model"
              (onValidationTriggered)="Validate(model)"
              (onInputChanged)="AddUserStrategyEditedValue($event)"
            >

            </app-strategy-creation-form-input>
          </div>
        </ng-container>

        <!--<div class="selected-strategy-inputs__item" [ngStyle]="{ 'flex-basis': '' + input.frontendFlexWidth + '%'}">-->
          <!--<div class="strategy-input-title">-->
            <!--<ng-container *ngIf="input.required">-->
              <!--<span class="strategy-input-required-asterisk">*</span>-->
            <!--</ng-container>-->

            <!--<span>{{input.title}}</span>-->
          <!--</div>-->

          <!--<ng-container *ngIf="GetInputModel(input.id) as model">-->
            <!--<div class="strategy-input-control" [ngSwitch]="input.frontendInputType">-->
              <!--<ng-template ngSwitchCase="number">-->
                <!--<input type="number" class="vk-input vk-input_full-width" [placeholder]="input.placeholderContent"-->
                       <!--[(ngModel)]="model.value" (keyup)="Validate(model)">-->
              <!--</ng-template>-->
              <!--<ng-template ngSwitchCase="datetimepicker">-->
                <!--<app-datetime-picker [(ngModel)]="model.value"></app-datetime-picker>-->
              <!--</ng-template>-->
              <!--<ng-template ngSwitchCase="checkbox">-->
                <!--<input type="checkbox" class="vk-checkbox"-->
                       <!--[checked]="model.value === 1"-->
                       <!--(change)="(model.value === 0) ? model.value = 1 : model.value = 0">-->
              <!--</ng-template>-->
            <!--</div>-->

            <!--<div class="strategy-input-error">-->
              <!--<ng-container *ngFor="let error of model.errors">-->
                <!--<div class="strategy-input-error__item">-->
                  <!--<div class="strategy-input-error">-->
                    <!--{{error}}-->
                  <!--</div>-->
                <!--</div>-->
              <!--</ng-container>-->
            <!--</div>-->
          <!--</ng-container>-->
        <!--</div>-->
      </ng-container>

      <ng-container *ngIf="valuesExtra && valuesExtra.length > 0">
        <div class="selected-strategy-hidden-inputs">
          <div class="selected-strategy-hidden-inputs__heading" (click)="SwitchExtraInputListExpanding()">
            <div class="hidden-input-title">
              Дополнительные параметры
            </div>

            <div class="hidden-input-control">
              {{extraInputsListExpanded ? 'Скрыть' : 'Показать'}}
            </div>
          </div>

          <ng-container *ngIf="extraInputsListExpanded">
            <div class="selected-strategy-hidden-inputs__inputs">
            <div class="selected-strategy-extra-inputs-list">
              <ng-container *ngFor="let input of selectedStrategy.inputs">
                <ng-container *ngIf="GetInputFromExtras(input.id) as model">
                  <div class="selected-strategy-extra-inputs-list__item" [ngStyle]="{ 'flex-basis': '' + input.frontendFlexWidth + '%'}">
                    <app-strategy-creation-form-input
                      [disabled]="mode === 'UPDATE' && !input.allowEditing"
                      [input]="input"
                      [model]="model"
                      (onValidationTriggered)="Validate(model)"
                      (onInputChanged)="AddUserStrategyEditedValue($event)"
                    >

                    </app-strategy-creation-form-input>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </div>
          </ng-container>
        </div>
      </ng-container>

      <div class="selected-strategy-inputs__save-button">
        <ng-container *ngIf="mode === 'CREATE'">
          <button class="vk-standart-button" (click)="Save()">
            Сохранить
          </button>
        </ng-container>

        <ng-container *ngIf="mode === 'UPDATE'">
          <button class="vk-standart-button" (click)="SaveEditedStrategy()">
            Применить изменения
          </button>
        </ng-container>
      </div>
    </div>
  </ng-container>
  <ng-template #strategyCreationInProcess>
    <ng-container *ngIf="strategyCreated === 1; else strategyCreationConfirmed">
      <div>
        Создание способа управления ставки, пожалуйста подождите...
      </div>
      <div class="spinner spinner-inline"></div>
    </ng-container>
  </ng-template>
  <ng-template #strategyCreationConfirmed>
    <div class="created-strategy-inputs">
      <ng-container *ngFor="let input of GetFilledValues()">
        <div class="created-strategy-inputs__item">
          <div class="created-strategy-input">
            <div class="created-strategy-input__name">
              {{input.name}}
            </div>
            <div class="created-strategy-input__value">
              <ng-container [ngSwitch]="input.type">
                <ng-template ngSwitchCase="number">
                  {{input.value / input.valueMultiplicator}}
                </ng-template>

                <ng-template ngSwitchCase="datetimepicker">
                  {{input.value | secondsToDate | utcToLocalDate:"DD MMM YYYY [в] HH:mm"}}
                </ng-template>

                <ng-template ngSwitchCase="checkbox">
                  {{input.value === 1 ? 'Да' : 'Нет'}}
                </ng-template>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>

      <div class="created-strategy-question">
        Хотите запустить объявление и управление ставкой?
      </div>

      <div class="created-strategy-inputs__controls">
        <div class="created-strategy-controler">
          <button class="vk-standart-button" (click)="StartNowClick()">
            Запустить сейчас
          </button>
        </div>

        <button class="vk-standart-button vk-standart-button_cancel" (click)="StartLaterClick()">
          Запустить позже
        </button>
      </div>
    </div>
  </ng-template>
</ng-template>
