<div class="datepicker_r">
  <input class="form-control" style="float:none" placeholder="ДД.ММ.ГГГГ" ngx-mydatepicker name="mydate"
         [ngModel]="value" [options]="options" #dp="ngx-mydatepicker" (dateChanged)="onDateChanged($event)" readonly/>
  <button (click)="dp.toggleCalendar()">
    <span class="ion-md-calendar"></span>
  </button>
  <!--<ng-container>-->
    <!--<input type="text" formControlName="hour" (change)="OnChangeHour($event.target.value)">:-->
    <!--<input type="text" formControlName="minute" (change)="OnChangeMinute($event.target.value)">-->
  <!--</ng-container>-->
</div>
