<div class="ad-format-preview">
  <img
    [src]="adFormat.preview_image"
    [alt]="adFormat.title"
    class="ad-format-preview__image"
  />
  <div class="ad-format-preview__text">
    Пример того, как объявление будет выглядеть ВКонтакте
  </div>
</div>
