<div class="integrations-list-page">

  <div class="integrations-list-page__actions">
    <div class="integrations-list-page__title">
      Интеграции сервиса
    </div>
    <a
      class="alert alert-info"
      href="https://postmonitor.ru/blog/instrukcii-po-integraciyam-postmonitor"
      target="_blank"
      rel="noopener nofollow"
    >
      <div class="alert-items">
        <div class="alert-item static">
          Инструкция по настройке
        </div>
      </div>
    </a>
  </div>
  <br>
  <div class="alert alert-danger" *ngIf="(integrationSessionQuery.currentSession$ | async)?.isLimitExhausted">
    <div class="alert-items">
      <div class="alert-item static">
        Превышен допустимый лимит транзакций.
      </div>
    </div>
  </div>

  <div class="integrations-list-page__content">
    <!--    <app-integration-create-edit-form></app-integration-create-edit-form>-->
    <div class="integrations-list-page__integrations-table">
      <app-integrations-table></app-integrations-table>
    </div>
    <div class="integration-button-list">
      <div class="integration-button-list__item integration-button-list__item_compact">
        <a href="/new-integration" class="vk-standart-button integration-action-button">
          <mat-icon>
            add
          </mat-icon>
          Добавить интеграцию
        </a>

        <p class="integration-action-button-description">
          Перейдя в этот раздел вы сможете создать интеграции с такими сервисами как: <strong>ВКонтакте, myTarget, amoCRM, Битрикс24, Мегаплан, U-On.Travel</strong>.
        </p>
      </div>

      <div class="integration-button-list__item">
        <span class="integration-action-button-seprator">
          или
        </span>
      </div>

      <div class="integration-button-list__item integration-button-list__item_compact">
        <a href="/app/integrations" class="vk-standart-button integration-action-button">
          <mat-icon>launch</mat-icon>

          Перейти
        </a>

        <p class="integration-action-button-description">
          Перейдя в этот раздел вы сможете создать интеграцию <strong>amoCRM или Битрикс24 с Яндекс.Аудиториями</strong>.
        </p>
      </div>
      

      
      <!--      <button class="vk-standart-button integrations-list-page__add-integration-button" (click)="AddForm()">-->
      <!--        -->
      <!--      </button>-->
    </div>
  </div>
</div>
