/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PostMonitorBackend_V2ModelsPaymentsTariffPackage } from '../models/post-monitor-backend-_v2models-payments-tariff-package';
@Injectable({
  providedIn: 'root',
})
class TariffPackagesService extends __BaseService {
  static readonly GetAllTariffPackagesPath = '/apiv2/tariff-packages';
  static readonly Apiv2TariffPackagesStuffGetPath = '/apiv2/tariff-packages/stuff';
  static readonly Apiv2TariffPackagesUserGetPath = '/apiv2/tariff-packages/user';
  static readonly Apiv2TariffPackagesGroupsGetPath = '/apiv2/tariff-packages/groups';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return Success
   */
  GetAllTariffPackagesResponse(): __Observable<__StrictHttpResponse<Array<PostMonitorBackend_V2ModelsPaymentsTariffPackage>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/tariff-packages`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PostMonitorBackend_V2ModelsPaymentsTariffPackage>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetAllTariffPackages(): __Observable<Array<PostMonitorBackend_V2ModelsPaymentsTariffPackage>> {
    return this.GetAllTariffPackagesResponse().pipe(
      __map(_r => _r.body as Array<PostMonitorBackend_V2ModelsPaymentsTariffPackage>)
    );
  }
  Apiv2TariffPackagesStuffGetResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/tariff-packages/stuff`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }  Apiv2TariffPackagesStuffGet(): __Observable<null> {
    return this.Apiv2TariffPackagesStuffGetResponse().pipe(
      __map(_r => _r.body as null)
    );
  }
  Apiv2TariffPackagesUserGetResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/tariff-packages/user`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }  Apiv2TariffPackagesUserGet(): __Observable<null> {
    return this.Apiv2TariffPackagesUserGetResponse().pipe(
      __map(_r => _r.body as null)
    );
  }
  Apiv2TariffPackagesGroupsGetResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/tariff-packages/groups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }  Apiv2TariffPackagesGroupsGet(): __Observable<null> {
    return this.Apiv2TariffPackagesGroupsGetResponse().pipe(
      __map(_r => _r.body as null)
    );
  }
}

module TariffPackagesService {
}

export { TariffPackagesService }
