import {Injectable} from '@angular/core';
import {EasyAdContainerModel} from '../models/easy-ad-container-model';
import {AutomationVkService} from '../../api/services/automation-vk.service';
import {AutomationEasyAdsService} from '../../api/services/automation-easy-ads.service';
import {Ad} from '../../api/models/ad';
import {AdLayout} from '../../api/models/ad-layout';
import {UserStrategy} from "../../api/models/user-strategy";
import {UserStrategiesService} from "../../api/services/user-strategies.service";
import {APIReponseWrapperListCabinet} from "../../api/models/apireponse-wrapper-list-cabinet";

@Injectable({
  providedIn: 'root'
})
export class EasyAdsDataService {
  public adsRerievingError: string = null;
  public budgetRerievingError: string = null;

  public adsList: Array<EasyAdContainerModel> = null;

  public filtredAdsList: Array<EasyAdContainerModel> = null;

  public filterShowActive: boolean = true;
  public filterShowInactive: boolean = false;
  public filterShowModerating: boolean = false;
  public filterShowModerationFailed: boolean = false;

  public filerMode: string = '';

  public filterOptions = [
    {
      name: 'Все',
      mode: 'all',
      count: 0,
      active: true,
      inactive: true,
      moderating: true,
      moderation_failed: true
    },
    {
      name: 'Активные',
      mode: 'active',
      count: 0,
      active: true,
      inactive: false,
      moderating: false,
      moderation_failed: false
    },
    {
      name: 'Неактивные',
      mode: 'inactive',
      count: 0,
      active: false,
      inactive: true,
      moderating: false,
      moderation_failed: false
    },
    {
      name: 'На модерации',
      mode: 'moderating',
      count: 0,
      active: false,
      inactive: false,
      moderating: true,
      moderation_failed: false
    },
    {
      name: 'Не прошедшие модерацию',
      mode: 'moderation_failed',
      count: 0,
      active: false,
      inactive: false,
      moderating: false,
      moderation_failed: true
    }
  ];

  public budgetIsLoading: boolean = true;
  public budget: number = 0;

  public accessExpired: boolean = false;

  constructor(private automationApi: AutomationVkService, private easyAdsApi: AutomationEasyAdsService,
              private userStrategyApi: UserStrategiesService) {
    this.filerMode = this.filterOptions[0].mode;
  }

  public AdsListNotEmpty(): boolean {
    if (this.adsList) {
      return this.adsList.length > 0;
    }

    return false;
  }

  public async AdStatusChange(easyAd: EasyAdContainerModel) {
    console.log('AdStatusChange', easyAd);
  }

  public async AdSendToModeration(easyAd: EasyAdContainerModel) {
    console.log('AdSendToModeration', easyAd);

    // Если объявление запущено, то остановить его. Иначе запустить
    const newStatus = (easyAd.ad.status) === 1 ? 0 : 1;

    console.log(`Setting status=${newStatus} for ad ${easyAd.accountId}_${easyAd.clientId}_${easyAd.adId}. Previous status=${easyAd.ad.status}`);
  }

  public async LoadAccounts(): Promise<APIReponseWrapperListCabinet> {
    const accountRequest = this.automationApi.GetAccounts().toPromise();

    accountRequest.catch(err => {
      // TODO Добавить обработку этой ошибки
      return null;
    });

    return await accountRequest;
  }

  public async LoadData(accountId: number, clientId: number = null) {

    // Секция загрузки данных для объявлений
    this.adsList = null;
    this.filtredAdsList = null;
    this.adsRerievingError = null;

    // Загрузить объявления и прилагающуюся  к ним информацию
    try {
      await this.LoadAdsAndRelatedData(accountId, clientId);
    } catch (exception) {
      console.warn(`Error during LoadAdsAndRelatedData(${accountId}),${clientId}`, exception);
      if (exception && exception.error && exception.error.errorCode === 'AUTOMATION_VK_CABINET_ACCESS_EXPIRED') {
        this.accessExpired = true;
        this.adsRerievingError = 'Доступ к функционалу истек. Для продолжения работы с функционалом оплатите доступ на странице оплаты.';
      } else {
        this.adsRerievingError = 'Ошибка во время загрузки объявлений - попробуйте обновить страницу. При повторном возникновении ошибки, пожалуйста, обратитесь в поддержку.';
      }
    }

    // Загрузить бюджет кабинета
    try {
      await this.LoadBudgetViaApi(accountId, clientId);
    } catch (exception) {
      console.warn(`Error during LoadBudgetViaApi(${accountId}),${clientId}`, exception);
    }
  }

  private async LoadAdsAndRelatedData(accountId: number, clientId: number) {
    // Загружаем объявления, созданные с помощью нашего сервиса
    const createdAds = await this.LoadAdsCreatedViaService(accountId, clientId);

    let adIds = createdAds.map(x => x.adId);

    // Filter off bad ids
    if (adIds && adIds.length > 0) {
      adIds = adIds.filter(x => x > 0);
    }

    console.log('LoadAds', accountId, clientId, adIds);

    // Продолжаем только в случае, если через сервис было создано хоть что-то
    if (adIds && adIds.length > 0) {
      // Загружаем объекты объявлений из ВКонтакте
      await this.LoadAds(accountId, clientId, adIds);

      // Назначим к полученным объявлениям даты их создания в сервисе
      this.adsList.forEach(easyAd => {
        const relatedCreatedAd = createdAds.find(x => x.adId === easyAd.ad.id);

        if (relatedCreatedAd) {
          easyAd.createdAt = relatedCreatedAd.createdAt;
        }
      });

      // Сортируем по дате
      this.adsList.sort(function (a, b) {
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
      });

      // Объявления загружены, фильтруем новые данные, согласно заданному фильтру
      // После выполнения этого шага объявления уже будут видны на странице
      this.AssignNewFilter(this.filerMode);

      // Загрузка визуальных частей может пройти с ошибками,
      // но из-за этого не должен остановиться процесс загрузки сопутствующих данных
      try {
        // Теперь необходимо подгрузить визуальные данные
        await this.LoadAdVisuals(accountId, clientId, adIds);
      } catch (exception) {
        console.warn(`Error during LoadAdVisuals()`, exception);
      }

      try {
        console.log('Trying to retrieve strategies', accountId, clientId, adIds);
        const strategies = await this.LoadAdsStrategies(accountId, clientId, adIds);

        console.log('Recieved strategies', strategies);

        if (strategies && strategies.length > 0) {
          strategies.forEach(strategy => {
            if (strategy) {
              const relatedAd = this.adsList.find(x =>
                x.accountId === strategy.accountId &&
                x.clientId === ((strategy.clientId === 0) ? null : strategy.clientId) &&
                x.adId === strategy.adId
              );

              if (relatedAd) {
                relatedAd.userStrategy = strategy;
              }
            }
          });
        }
      } catch (exception) {
        console.warn('Error during strategy retrieving', exception);
      }

      this.adsList.forEach(x => {
        x.strategyRecieved = true;
      });

      console.log('LoadAdsStatistics', accountId, clientId, adIds);
      // Загружаем статиску ообъявлений
      await this.LoadAdsStatistics(accountId, clientId, adIds);
    } else {
      this.adsList = [];
      this.AssignNewFilter(this.filerMode);
    }
  }

  private async LoadAdsCreatedViaService(accountId: number, clientId: number) {
    const request = this.easyAdsApi.GetAdsCreatedViaService({
      accountId: accountId,
      clientId: clientId,
      offset: 0,
      limit: 1000
    }).toPromise();

    request.catch(err => {
      console.warn('Error during GetAdsCreatedViaService', err);
      this.adsList = [];
      this.AssignNewFilter(this.filerMode);
    });

    const response = await request;

    let result = [];

    if (response && response.data) {
      result = response.data;
    } else {
      // Если не создано ничего, то дальнейший процесс не имеет смысла
      this.adsList = [];
      this.AssignNewFilter(this.filerMode);
    }

    return result;
  }

  private async LoadAdsStrategies(accountId: number, clientId: number, adIds: Array<number>): Promise<Array<UserStrategy>> {
    const request = this.userStrategyApi.GetStrategiesByAdAndAccountIds({
      accountId: accountId,
      clientId: clientId,
      viewModel: {
        adIds
      }
    }).toPromise();

    request.catch(err => {

    });

    const response = await request;

    return response;
  }

  // TODO Протестировать макс длину запроса на сервере и написать ограничитель на клиенте
  public async LoadAds(accountId: number, clientId: number, adIds: Array<number>) {
    await this.Timeout();

    // TODO Загрузка ads с vk.com
    const adsResponse = await this.automationApi.GetAds({
      accountId: accountId,
      clientId: clientId,
      campaignIds: null,
      adIds: adIds,
      limit: 1000,
      offset: 0,
      includeDeleted: false
    }).toPromise();

    this.adsList = [];

    if (adsResponse && adsResponse.data) {
      adsResponse.data.forEach(ad => {
        const model: EasyAdContainerModel = new EasyAdContainerModel();
        model.accountId = accountId;
        model.clientId = clientId;
        model.campaignId = ad.campaign_id;
        model.adId = ad.id;

        model.ad = ad;

        this.adsList.push(model);
      });
    }

    this.RecalculateFilterCount();
  }

  private async LoadAdVisuals(accountId: number, clientId: number, adIds: Array<number>) {
    const ads = this.adsList
      .filter(x => x.ad)
      .map(x => x.ad);

    if (ads && ads.length > 0) {
      const teasers = ads.filter(x => x.ad_format === 1 || x.ad_format === 2);
      const promo = ads.filter(x => x.ad_format === 9);

      // Может так сложиться, что не будет объявлений нужного формата - тогда загрузка не должна происходить
      if (teasers.length > 0 || promo.length > 0) {

        // Загрузка описания внешнего вида объявления
        const adsLayouts = await this.LoadAdLayouts(accountId, clientId, adIds);

        // Если загрузкапроизойдет с ошибкой или не вернёт данных - то прерывание функции не страшно,
        // Ведь без результата этой операции не получится отобразить ни одно объявление (его превью)
        if (adsLayouts && adsLayouts.length > 0) {
          adsLayouts.forEach(layout => {
            const related = this.adsList.find(x => x.ad.id === layout.id);

            if (related) {
              related.adLayout = layout;

              // Если это тизер - то сообщаем ему, что его графика подготовлена
              if (teasers.some(x => x.id === layout.id)) {
                related.graphicsLoaded = true;
              }
            }
          });

          // Загружаем описание постов для объявлений-промопостов
          await this.LoadAdVisualForPromoposts(promo, adsLayouts);
        }
      }

      // Для всех: убедиться в том, что процесс загрузки графики окончен
      this.adsList.forEach(easyAd => {
        easyAd.graphicsLoaded = true;
      });
    }
  }

  private async LoadAdLayouts(accountId: number, clientId: number, adIds: Array<number>): Promise<Array<AdLayout>> {
    if (adIds && adIds.length > 0) {
      await this.Timeout();

      const adsLayoutResponse = await this.automationApi.GetAdsLayout({
        accountId: accountId,
        clientId: clientId,
        campaignIds: null,
        adIds: adIds,
        limit: 1000,
        offset: 0,
        includeDeleted: false
      }).toPromise();

      if (adsLayoutResponse && adsLayoutResponse.data) {
        return adsLayoutResponse.data;
      }
    }

    return null;
  }

  private async LoadAdVisualForPromoposts(ads: Array<Ad>, adsLayouts: Array<AdLayout>) {
    if (ads && ads.length > 0 && adsLayouts && adsLayouts.length > 0) {

      const wallPosts = [];

      // Собираем ссылки на посты из всех объявлений-промопостов
      ads.forEach(ad => {
        const relatedLayout = adsLayouts.find(layout => layout.id === ad.id);

        if (relatedLayout && relatedLayout.link_url) {

          // Вычленияем id группы и id поста, заносим в массив
          try {
            const linkSplitted = relatedLayout.link_url.split('wall');

            const postData = linkSplitted[1];

            const postDataSplitted = postData.split('_');

            const groupId = parseInt(postDataSplitted[0], 10);
            const postId = parseInt(postDataSplitted[1], 10);

            if (linkSplitted && linkSplitted.length > 1) {
              wallPosts.push({
                adId: ad.id,
                groupId: groupId,
                postId: postId,
                post: linkSplitted[1]
              });
            }
          } catch (exception) {
          }
        }
      });

      console.log('Data gathered', wallPosts);

      if (wallPosts.length > 0) {
        let index = 0;
        const step = 50;

        // обходим ранее собранный массив и получаем информацию
        console.log('Iterating gathered posts data...');
        while (index < wallPosts.length) {
          console.log(`Retrieving from ${index} to ${index + step} of ${wallPosts.length}`);
          await this.Timeout();

          // Если загрузка этого блока не удастся важно не останавливать процесс сбора информации
          try {
            const posts = wallPosts.slice(index, step);

            if (posts && posts.length > 0) {
              const ids = posts.map(x => x.post).join(',');
              console.log('ids', ids);

              if (ids && ids.length > 0) {
                const wallResponse = await this.automationApi.GetWallById(ids).toPromise();

                console.log('response', wallResponse);

                if (wallResponse && wallResponse.data && wallResponse.data.length > 0) {
                  wallResponse.data.forEach(post => {
                    const relatedWallPost = wallPosts.find(wallPost => wallPost.groupId === post.owner_id && wallPost.postId === post.id);

                    if (relatedWallPost) {
                      const relatedAd = this.adsList.find(x => x.ad.id === relatedWallPost.adId);

                      if (relatedAd) {
                        relatedAd.post = post;
                      }
                    }
                  });
                }
              }
            }
          } catch (exception) {
            console.warn('Error during wallPosts iterating', exception);
          }

          index += step;
        }
      }
    }
  }

  private async LoadAdsStatistics(accountId: number, clientId: number, adIds: Array<number>) {
    // Загружаем статистику
    await this.Timeout();

    const adIdsParameter = adIds.join(',');

    const statsResponse = await this.automationApi.GetStatistics({
      accountId: accountId,
      clientId: clientId,
      idsType: 'ad',
      ids: adIdsParameter,
      period: 'overall',
      dateFrom: '0',
      dateTo: '0'
    }).toPromise();

    if (statsResponse && statsResponse.data) {
      console.log('statsResponse.data', statsResponse.data);
      statsResponse.data.forEach(stat => {
        const relatedAd = this.adsList.find(x => x.ad.id === stat.id);

        if (relatedAd && stat.stats && stat.stats.length > 0) {
          relatedAd.statistics = stat.stats[0];
        }
      });
    } else {
      console.error(`LoadAdsStatistics(${accountId}, ${clientId}, adIdsParameter) has no response; adIdsParameter=${adIdsParameter}`);
    }
  }

  public async LoadBudgetViaApi(accountId: number, clientId: number) {
    await this.Timeout();

    this.budgetRerievingError = null;
    this.budgetIsLoading = true;
    this.budget = null;

    const budgetRequest = this.automationApi.GetBudget({
      accountId: accountId,
      clientId: clientId
    }).toPromise();

    // Обработка ошибки запроса к бюджету
    budgetRequest.catch(err => {
      console.warn('Budget request failure', err);
      this.budgetIsLoading = false;

      if (err && err.error && err.error.description) {
        this.budgetRerievingError = err.error.description;
      }
    });

    const budgetResponse = await budgetRequest;

    this.budgetIsLoading = false;
    this.budgetRerievingError = null;

    if (budgetResponse && budgetResponse.data !== null && budgetResponse.data !== undefined) {
      this.budget = budgetResponse.data;
    } else {
      this.budgetRerievingError = 'Не удалось загрузить данные';
      console.warn(`budgetResponse или budgetResponse.data не были заданы`, budgetResponse);
    }
  }

  public AssignNewFilter(mode: string) {
    this.filterShowActive = false;
    this.filterShowInactive = false;
    this.filterShowModerating = false;
    this.filterShowModerationFailed = false;

    this.filerMode = mode;

    switch (this.filerMode) {
      case 'all':
        this.filterShowInactive = true;
        this.filterShowActive = true;
        this.filterShowModerating = true;
        this.filterShowModerationFailed = true;
        break;

      case 'active':
        this.filterShowActive = true;
        break;

      case 'inactive':
        this.filterShowInactive = true;
        break;

      case 'moderating':
        this.filterShowModerating = true;
        break;

      case 'moderation_failed':
        this.filterShowModerationFailed = true;
        break;
    }

    this.FilterAds(this.filterShowActive, this.filterShowInactive, this.filterShowModerating, this.filterShowModerationFailed);
  }

  public RecalculateFilterCount() {
    this.filterOptions.forEach(option => {
      let count = 0;

      if (this.adsList && this.adsList.length > 0) {
        const filteredResults = this.GetAds(this.adsList, option.active, option.inactive, option.moderating, option.moderation_failed);
        count = filteredResults.length;
      }

      option.count = count;
    });
  }

  public FilterAds(showActive: boolean, showInactive: boolean, showModerating: boolean, showModerationFailed: boolean): void {
    this.filtredAdsList = this.GetAds(this.adsList, showActive, showInactive, showModerating, showModerationFailed);
  }

  private GetAds(adsListToFilter: Array<EasyAdContainerModel>, showActive: boolean = true, showInactive: boolean = false, showModerating: boolean = false, showModerationFailed: boolean = false): Array<EasyAdContainerModel> {
    if (!adsListToFilter || adsListToFilter.length === 0) {
      console.log('All is bad', !adsListToFilter, adsListToFilter.length === 0);
      return [];
    }

    return adsListToFilter.filter(easyAd => easyAd.ad && (
        (showActive && easyAd.ad.status === 1) ||
        (showInactive && easyAd.ad.status === 0 && (easyAd.ad.approved === 0 || easyAd.ad.approved === 2)) ||
        (showModerating && easyAd.ad.approved === 1) ||
        (showModerationFailed && easyAd.ad.approved === 3)
      )
    );
  }

  public async Timeout(ms: number = 500) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}
