import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { AffiliateLinksStore, AffiliateLinksState } from './affiliate-links.store';

@Injectable({ providedIn: 'root' })
export class AffiliateLinksQuery extends QueryEntity<AffiliateLinksState> {

  constructor(protected store: AffiliateLinksStore) {
    super(store);
  }

}
