<ng-container>
  <form [formGroup]="controlContainer.control">
    <div class="vk-container" *ngIf="accounts && accounts.length > 1">
      <div class="vk-strip-wrapper">
        <div class="vk-strip-wrapper__item">
          <label for="chose-account" class="vk-label">Выберите рекламный кабинет:</label>
          <select id="chose-account" class="vk-select" [formControl]="controlContainer.control.get('accountId')">
            <ng-container *ngFor="let account of AccountsFiltered">
              <option [value]="account.account_id+'_'+account.client_id" [disabled]="account.is_blocked"
                      [title]="GetCampaignTitle(account)">
                {{account.account_name}}
              </option>
            </ng-container>
          </select>
        </div>
      </div>
    </div>

    <div class="vk-container">
      <div class="vk-strip-wrapper">
        <div class="vk-strip-wrapper__item">
          <ng-container *ngIf="!loadingCampaigns; else loadingScreen;">
            <ng-container *ngIf="CampaingsFiltered.length > 0">
              <label for="chose-campaign" class="vk-label">Выберите кампанию:</label>
              <select id="chose-campaign" class="vk-select" [formControl]="controlContainer.control.get('campaignId')">
                <ng-container *ngFor="let campaign of CampaingsFiltered">
                  <option [value]="campaign.id">
                    {{campaign.name}}
                  </option>
                </ng-container>
              </select>
            </ng-container>
          </ng-container>
          <ng-template #loadingScreen>
            <mat-spinner [diameter]="16"></mat-spinner>
          </ng-template>
        </div>

        <div class="vk-strip-wrapper__item">
          <div class="vk-strip-wrapper">
            <ng-container *ngIf="CampaingsFiltered.length > 0">
              <label for="create-new-campaign-button" class="vk-label">или</label>
            </ng-container>

            <button id="create-new-campaign-button" class="vk-standart-button" (click)="CreateCompany()"
                    [disabled]="disabled">
              Создайте новую кампанию
            </button>
          </div>
        </div>
      </div>
    </div>

    <!--<div class="ad-account-manager">-->
    <!--<div class="ad-account-manager__widget">-->
    <!--<label>Выберите рекламный кабинет</label>-->
    <!--<br>-->
    <!--<mat-form-field >-->
    <!--<mat-select [formControl]="controlContainer.control.get('accountId')">-->
    <!--<mat-option [value]="account.account_id+'_'+account.client_id" *ngFor="let account of accounts">-->
    <!--{{account.account_name}}-->
    <!--</mat-option>-->
    <!--</mat-select>-->
    <!--</mat-form-field>-->
    <!--</div>-->
    <!--<div class="ad-account-manager__widget">-->
    <!--<label>Выберите кампанию</label>-->
    <!--<br>-->
    <!--<div class="fx-row">-->
    <!--<ng-container *ngIf="CampaingsFiltered.length > 0">-->
    <!--<div>-->
    <!--<mat-form-field>-->
    <!--<mat-select [formControl]="controlContainer.control.get('campaignId')">-->
    <!--<mat-option [value]="campaign.id" *ngFor="let campaign of CampaingsFiltered">-->
    <!--{{campaign.name}}-->
    <!--</mat-option>-->
    <!--</mat-select>-->
    <!--</mat-form-field>-->
    <!--</div>-->
    <!--или-->
    <!--</ng-container>-->
    <!--<button mat-flat-button color="primary" class="create-button" (click)="CreateCompany()" [disabled]="disabled">создайте новую</button>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->

  </form>
</ng-container>
