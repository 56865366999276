import {Injectable} from '@angular/core';
import {Store, StoreConfig} from '@datorama/akita';
import {Promocode} from '../../../api/models';

export interface PromocodeMessage {
  message: string;
  is_error: boolean;
}

export interface PromocodeState {
  messages: PromocodeMessage[];
  promocode: Promocode;
}

export function createInitialState(): PromocodeState {
  return {
    messages: [],
    promocode: null
  };
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'promocode'})
export class PromocodeStore extends Store<PromocodeState> {

  constructor() {
    super(createInitialState());
  }

}

