import { HttpClient, HttpParams } from '@angular/common/http';
import { AffiliateMemberStattisticsByUserStore } from './affiliate-member-stattistics-by-user.store';
import { createAffiliateMemberStattisticsByUser } from './affiliate-member-stattistics-by-user.model';
import { tap } from 'rxjs/operators';
import { environment } from "../../../../environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "./affiliate-member-stattistics-by-user.store";
import * as i2 from "@angular/common/http";
var AffiliateMemberStattisticsByUserService = /** @class */ (function () {
    function AffiliateMemberStattisticsByUserService(affiliateMemberStattisticsByUserStore, http) {
        this.affiliateMemberStattisticsByUserStore = affiliateMemberStattisticsByUserStore;
        this.http = http;
        this.baseUrl = environment.backendUri + "/" + environment.apiPrefix;
    }
    /**
     * Метод позволяет получить все данные о статистике участника партнерской программы
     * @param dateFrom - дата начала периода
     * @param dateTo - дата окончания периода
     */
    AffiliateMemberStattisticsByUserService.prototype.get = function (dateFrom, dateTo) {
        var _this = this;
        var params = this.GenerateQueryParams(dateFrom, dateTo);
        return this.http.get(this.baseUrl + "/user-affiliate-statistics/by-user", {
            params: params
        })
            .pipe(tap(function (entities) {
            var statistics = entities.map(function (x, i) { return createAffiliateMemberStattisticsByUser(x, i); });
            _this.affiliateMemberStattisticsByUserStore.set(statistics);
        }));
    };
    /**
     * Формирование параметров
     * @param dateFrom
     * @param dateTo
     * @constructor
     */
    AffiliateMemberStattisticsByUserService.prototype.GenerateQueryParams = function (dateFrom, dateTo) {
        var params = new HttpParams();
        params.set('dateFrom', dateFrom ? dateFrom.toString() : null);
        params.set('dateTo', dateTo ? dateTo.toString() : null);
        return params;
    };
    /**
     *
     * @param affiliateMemberStattisticsByUser
     */
    AffiliateMemberStattisticsByUserService.prototype.add = function (affiliateMemberStattisticsByUser) {
        this.affiliateMemberStattisticsByUserStore.add(affiliateMemberStattisticsByUser);
    };
    AffiliateMemberStattisticsByUserService.prototype.update = function (id, affiliateMemberStattisticsByUser) {
        this.affiliateMemberStattisticsByUserStore.update(id, affiliateMemberStattisticsByUser);
    };
    AffiliateMemberStattisticsByUserService.prototype.remove = function (id) {
        this.affiliateMemberStattisticsByUserStore.remove(id);
    };
    AffiliateMemberStattisticsByUserService.ngInjectableDef = i0.defineInjectable({ factory: function AffiliateMemberStattisticsByUserService_Factory() { return new AffiliateMemberStattisticsByUserService(i0.inject(i1.AffiliateMemberStattisticsByUserStore), i0.inject(i2.HttpClient)); }, token: AffiliateMemberStattisticsByUserService, providedIn: "root" });
    return AffiliateMemberStattisticsByUserService;
}());
export { AffiliateMemberStattisticsByUserService };
