var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { map as __map, filter as __filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../api-configuration";
import * as i2 from "@angular/common/http";
var FacebookAdsService = /** @class */ (function (_super) {
    __extends(FacebookAdsService, _super);
    function FacebookAdsService(config, http) {
        return _super.call(this, config, http) || this;
    }
    /**
     * @param params The `FacebookAdsService.FacebookGetAdsAsyncParams` containing the following parameters:
     *
     * - `summary`:
     *
     * - `pagingBefore`:
     *
     * - `pagingAfter`:
     *
     * - `limit`:
     *
     * - `fields`:
     *
     * - `adAccountId`:
     *
     * @return Success
     */
    FacebookAdsService.prototype.FacebookGetAdsAsyncResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        (params.summary || []).forEach(function (val) { if (val != null)
            __params = __params.append('summary', val.toString()); });
        if (params.pagingBefore != null)
            __params = __params.set('pagingBefore', params.pagingBefore.toString());
        if (params.pagingAfter != null)
            __params = __params.set('pagingAfter', params.pagingAfter.toString());
        if (params.limit != null)
            __params = __params.set('limit', params.limit.toString());
        (params.fields || []).forEach(function (val) { if (val != null)
            __params = __params.append('fields', val.toString()); });
        if (params.adAccountId != null)
            __params = __params.set('adAccountId', params.adAccountId.toString());
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/facebook/ad", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `FacebookAdsService.FacebookGetAdsAsyncParams` containing the following parameters:
     *
     * - `summary`:
     *
     * - `pagingBefore`:
     *
     * - `pagingAfter`:
     *
     * - `limit`:
     *
     * - `fields`:
     *
     * - `adAccountId`:
     *
     * @return Success
     */
    FacebookAdsService.prototype.FacebookGetAdsAsync = function (params) {
        return this.FacebookGetAdsAsyncResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `FacebookAdsService.FacebookGetAdLeadsAsyncParams` containing the following parameters:
     *
     * - `adId`:
     *
     * - `pagingBefore`:
     *
     * - `pagingAfter`:
     *
     * - `limit`:
     *
     * - `filter`:
     *
     * - `fields`:
     *
     * @return Success
     */
    FacebookAdsService.prototype.FacebookGetAdLeadsAsyncResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.pagingBefore != null)
            __params = __params.set('pagingBefore', params.pagingBefore.toString());
        if (params.pagingAfter != null)
            __params = __params.set('pagingAfter', params.pagingAfter.toString());
        if (params.limit != null)
            __params = __params.set('limit', params.limit.toString());
        (params.filter || []).forEach(function (val) { if (val != null)
            __params = __params.append('filter', val.toString()); });
        (params.fields || []).forEach(function (val) { if (val != null)
            __params = __params.append('fields', val.toString()); });
        var req = new HttpRequest('GET', this.rootUrl + ("/apiv2/facebook/ad/" + encodeURIComponent(params.adId) + "/leads"), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `FacebookAdsService.FacebookGetAdLeadsAsyncParams` containing the following parameters:
     *
     * - `adId`:
     *
     * - `pagingBefore`:
     *
     * - `pagingAfter`:
     *
     * - `limit`:
     *
     * - `filter`:
     *
     * - `fields`:
     *
     * @return Success
     */
    FacebookAdsService.prototype.FacebookGetAdLeadsAsync = function (params) {
        return this.FacebookGetAdLeadsAsyncResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    FacebookAdsService.FacebookGetAdsAsyncPath = '/apiv2/facebook/ad';
    FacebookAdsService.FacebookGetAdLeadsAsyncPath = '/apiv2/facebook/ad/{adId}/leads';
    FacebookAdsService.ngInjectableDef = i0.defineInjectable({ factory: function FacebookAdsService_Factory() { return new FacebookAdsService(i0.inject(i1.ApiConfiguration), i0.inject(i2.HttpClient)); }, token: FacebookAdsService, providedIn: "root" });
    return FacebookAdsService;
}(__BaseService));
export { FacebookAdsService };
