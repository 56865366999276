import {AfterViewInit, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {PlatformsTypes} from '../../../../platforms-types';
import {FormArray, FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {RetargetingGroupsService} from '../../../../stores/retargeting-groups/retargeting-groups.service';
import {RetargetingGroupsQuery} from '../../../../stores/retargeting-groups/retargeting-groups.query';
import {AkitaNgFormsManager} from '@datorama/akita-ng-forms-manager';
import {CrmPipelinesQuery} from '../../../../stores/crm-pipelines/crm-pipelines.query';
import {AssignationPlatforms} from '../../../../stores/platform-assignations/platform-assignation.model';
import {PlatformAssignationsQuery} from '../../../../stores/platform-assignations/platform-assignations.query';
import {PlatformsQuery} from '../../../../stores/platforms/platforms.query';

@Component({
  selector: 'app-ad-network-import',
  templateUrl: './ad-network-import.component.html',
  styleUrls: ['./ad-network-import.component.scss']
})
export class AdNetworkImportComponent {

  public accounts: any = [];
  public loading: boolean = false;
  public originPlatformCode$ = this.platformsQuery.selectedOriginPlatformCode$;

  public form: FormGroup;

  @Input() public type: PlatformsTypes;
  @Input() public platform: AssignationPlatforms;

  public pipelineStatuses = null;

  constructor(
    private platformsQuery: PlatformsQuery
  ) {
  }
}
