import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PostMonitorBackend_V2ModelsCrmEntitesLeadCall} from '../../../../api/models/post-monitor-backend-_v2models-crm-entites-lead-call';
import {PostMonitorBackend_V2ModelsCrmEntitesLeadCallStatus} from "../../../../api/models/post-monitor-backend-_v2models-crm-entites-lead-call-status";
import {Observable} from "rxjs";
import {CallsController1Service} from "../../../../api/services";

@Component({
  selector: 'app-user-call',
  templateUrl: './user-call.component.html',
  styleUrls: ['./user-call.component.scss']
})
export class UserCallComponent implements OnInit {

  @Input() public userCall: PostMonitorBackend_V2ModelsCrmEntitesLeadCall;
  @Input() public callStatuses: Observable<Array<PostMonitorBackend_V2ModelsCrmEntitesLeadCallStatus>>;

  @Output() public callStatusChanged: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private callsService: CallsController1Service
  ) {
  }

  ngOnInit() {
  }

  ChangeCallStatus(statusId: number) {
    this.callsService.ChangeCallStatus({
      callId: this.userCall.id,
      userId: this.userCall.userId,
      callStatusId: statusId
    })
      .subscribe(call => {
          this.userCall = call;
          this.callStatusChanged.emit()
        },
        err => {
          throw err;
        })
  }
}
