/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./easy-payment-system.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./easy-payment-system.component";
var styles_EasyPaymentSystemComponent = [i0.styles];
var RenderType_EasyPaymentSystemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EasyPaymentSystemComponent, data: {} });
export { RenderType_EasyPaymentSystemComponent as RenderType_EasyPaymentSystemComponent };
function View_EasyPaymentSystemComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "easy-payment-system-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "easy-payment-system-container__image"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["class", "easy-payment-system-image"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "easy-payment-system-container__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "easy-payment-system-container__content"]], null, null, null, null, null)), i1.ɵncd(null, 0)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.system.image; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.system.title; _ck(_v, 5, 0, currVal_1); }); }
export function View_EasyPaymentSystemComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_EasyPaymentSystemComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.system; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_EasyPaymentSystemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-easy-payment-system", [], null, null, null, View_EasyPaymentSystemComponent_0, RenderType_EasyPaymentSystemComponent)), i1.ɵdid(1, 114688, null, 0, i3.EasyPaymentSystemComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EasyPaymentSystemComponentNgFactory = i1.ɵccf("app-easy-payment-system", i3.EasyPaymentSystemComponent, View_EasyPaymentSystemComponent_Host_0, { system: "system" }, {}, ["*"]);
export { EasyPaymentSystemComponentNgFactory as EasyPaymentSystemComponentNgFactory };
