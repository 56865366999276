import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AffiliateStatisticDatepickerComponent } from './components/affiliate-statistic-datepicker/affiliate-statistic-datepicker.component';
import {MatDatepickerModule} from "@angular/material/datepicker";

@NgModule({
  declarations: [AffiliateStatisticDatepickerComponent],
    imports: [
        CommonModule,
        MatDatepickerModule
    ],
  exports: [AffiliateStatisticDatepickerComponent]
})
export class AffiliateProgramStatisticsSharedModule { }
