import { Component, OnInit } from '@angular/core';
import {AffiliatePromgramStatisticsByUserService} from "../../services/affiliate-promgram-statistics-by-user.service";

@Component({
  selector: 'app-affiliate-promgram-statistics-by-user-route',
  templateUrl: './affiliate-promgram-statistics-by-user-route.component.html',
  styleUrls: ['./affiliate-promgram-statistics-by-user-route.component.scss']
})
export class AffiliatePromgramStatisticsByUserRouteComponent implements OnInit {

  constructor(
    private service: AffiliatePromgramStatisticsByUserService
  ) { }

  ngOnInit() {
    this.service.LoadStatistic();
  }

}
