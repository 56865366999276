import {ChangeDetectorRef, Injectable} from '@angular/core';
import {ExportConfiguratorService} from '../platforms-module/shared-crm/services/export-configurator.service';
import {CrmImportConfiguratorService} from '../platforms-module/shared-crm/services/crm-import-configurator.service';
import {AdNetworkImportConfiguratorService} from '../platforms-module/shared-ad-network/services/ad-network-import-configurator.service';
import {AdNetworkExportConfiguratorService} from '../platforms-module/shared-ad-network/services/ad-network-export-configurator.service';
import {PlatformsTypes} from '../platforms-types';
import {MoiSkladExportService} from '../platforms-module/moi-sklad/services/moi-sklad-export.service';
import {FormMessagesService} from "../services/form-messages/form-messages.service";

@Injectable({
  providedIn: 'root'
})
export class ConfiguratorService {

  constructor(
    private crmExport: ExportConfiguratorService,
    private crmImport: CrmImportConfiguratorService,
    private adNetworkImport: AdNetworkImportConfiguratorService,
    private adNetworkExport: AdNetworkExportConfiguratorService,
    private moiSkladExportService: MoiSkladExportService,
    private moiSkladImportService: MoiSkladExportService,
    private formsMessagesService: FormMessagesService,
  ) {
  }

  public configure(type: PlatformsTypes) {
    switch (type) {
      case PlatformsTypes.BITRIX_EXPORT:
      case PlatformsTypes.AMO_CRM_EXPORT:
      case PlatformsTypes.MEGAPLAN_EXPORT:
      case PlatformsTypes.UON_EXPORT:
      case PlatformsTypes.MOY_SKLAD_EXPORT:
        return this.crmExport.configure(type);
        break;
      case PlatformsTypes.BITRIX_IMPORT:
      case PlatformsTypes.AMO_CRM_IMPORT:
      case PlatformsTypes.MEGAPLAN_IMPORT:
      case PlatformsTypes.UON_IMPORT:
      case PlatformsTypes.MOY_SKLAD_IMPORT:
        return this.crmImport.configure(type);
        break;
      case PlatformsTypes.VK_LEAD_FORMS:
      case PlatformsTypes.MT_LEAD_FORMS:
      case PlatformsTypes.FB_LEAD_FORMS:
        return this.adNetworkExport.configure(type);
        break;
      case PlatformsTypes.VK_RETARGETING_GROUPS:
      case PlatformsTypes.MT_REMARKETING_GROUPS:
      case PlatformsTypes.FB_CUSTOM_AUDIENCES:
      case PlatformsTypes.YANDEX_AUDIENCES:
        return this.adNetworkImport.configure(type);
        break;
    }
  }

  public valid(type: PlatformsTypes) {
    this.formsMessagesService.Clear()
    switch (type) {
      case PlatformsTypes.BITRIX_EXPORT:
      case PlatformsTypes.AMO_CRM_EXPORT:
      case PlatformsTypes.MEGAPLAN_EXPORT:
      case PlatformsTypes.UON_EXPORT:
        return this.crmExport.valid(type);
        break;
      case PlatformsTypes.BITRIX_IMPORT:
      case PlatformsTypes.AMO_CRM_IMPORT:
      case PlatformsTypes.MEGAPLAN_IMPORT:
      case PlatformsTypes.UON_IMPORT:
      case PlatformsTypes.MOY_SKLAD_IMPORT:
        return this.crmImport.valid(type);
        break;
      case PlatformsTypes.VK_LEAD_FORMS:
      case PlatformsTypes.MT_LEAD_FORMS:
      case PlatformsTypes.FB_LEAD_FORMS:
        return this.adNetworkExport.valid(type);
        break;
      case PlatformsTypes.VK_RETARGETING_GROUPS:
      case PlatformsTypes.MT_REMARKETING_GROUPS:
      case PlatformsTypes.FB_CUSTOM_AUDIENCES:
      case PlatformsTypes.YANDEX_AUDIENCES:
        return this.adNetworkImport.valid(type);
        break;
      case PlatformsTypes.MOY_SKLAD_EXPORT:
        return this.moiSkladExportService.valid();
        break;
    }
  }

}
