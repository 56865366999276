/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';
import { FacebookResponseWrapperIEnumerableAdsFacebookAdFacebookGraphAPIVersion00190CultureNeutralPublicKeyTokenNull } from '../models/facebook-response-wrapper-ienumerable-ads-facebook-ad-facebook-graph-apiversion-00190culture-neutral-public-key-token-null';
import { FacebookResponseWrapperIEnumerableAdsFacebookLeadFacebookGraphAPIVersion00190CultureNeutralPublicKeyTokenNull } from '../models/facebook-response-wrapper-ienumerable-ads-facebook-lead-facebook-graph-apiversion-00190culture-neutral-public-key-token-null';

@Injectable({
  providedIn: 'root',
})
class FacebookAdsService extends __BaseService {
  static readonly FacebookGetAdsAsyncPath = '/apiv2/facebook/ad';
  static readonly FacebookGetAdLeadsAsyncPath = '/apiv2/facebook/ad/{adId}/leads';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `FacebookAdsService.FacebookGetAdsAsyncParams` containing the following parameters:
   *
   * - `summary`:
   *
   * - `pagingBefore`:
   *
   * - `pagingAfter`:
   *
   * - `limit`:
   *
   * - `fields`:
   *
   * - `adAccountId`:
   *
   * @return Success
   */
  FacebookGetAdsAsyncResponse(params: FacebookAdsService.FacebookGetAdsAsyncParams): __Observable<__StrictHttpResponse<FacebookResponseWrapperIEnumerableAdsFacebookAdFacebookGraphAPIVersion00190CultureNeutralPublicKeyTokenNull>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.summary || []).forEach(val => {if (val != null) __params = __params.append('summary', val.toString())});
    if (params.pagingBefore != null) __params = __params.set('pagingBefore', params.pagingBefore.toString());
    if (params.pagingAfter != null) __params = __params.set('pagingAfter', params.pagingAfter.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    (params.fields || []).forEach(val => {if (val != null) __params = __params.append('fields', val.toString())});
    if (params.adAccountId != null) __params = __params.set('adAccountId', params.adAccountId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/facebook/ad`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FacebookResponseWrapperIEnumerableAdsFacebookAdFacebookGraphAPIVersion00190CultureNeutralPublicKeyTokenNull>;
      })
    );
  }
  /**
   * @param params The `FacebookAdsService.FacebookGetAdsAsyncParams` containing the following parameters:
   *
   * - `summary`:
   *
   * - `pagingBefore`:
   *
   * - `pagingAfter`:
   *
   * - `limit`:
   *
   * - `fields`:
   *
   * - `adAccountId`:
   *
   * @return Success
   */
  FacebookGetAdsAsync(params: FacebookAdsService.FacebookGetAdsAsyncParams): __Observable<FacebookResponseWrapperIEnumerableAdsFacebookAdFacebookGraphAPIVersion00190CultureNeutralPublicKeyTokenNull> {
    return this.FacebookGetAdsAsyncResponse(params).pipe(
      __map(_r => _r.body as FacebookResponseWrapperIEnumerableAdsFacebookAdFacebookGraphAPIVersion00190CultureNeutralPublicKeyTokenNull)
    );
  }

  /**
   * @param params The `FacebookAdsService.FacebookGetAdLeadsAsyncParams` containing the following parameters:
   *
   * - `adId`:
   *
   * - `pagingBefore`:
   *
   * - `pagingAfter`:
   *
   * - `limit`:
   *
   * - `filter`:
   *
   * - `fields`:
   *
   * @return Success
   */
  FacebookGetAdLeadsAsyncResponse(params: FacebookAdsService.FacebookGetAdLeadsAsyncParams): __Observable<__StrictHttpResponse<FacebookResponseWrapperIEnumerableAdsFacebookLeadFacebookGraphAPIVersion00190CultureNeutralPublicKeyTokenNull>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.pagingBefore != null) __params = __params.set('pagingBefore', params.pagingBefore.toString());
    if (params.pagingAfter != null) __params = __params.set('pagingAfter', params.pagingAfter.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    (params.filter || []).forEach(val => {if (val != null) __params = __params.append('filter', val.toString())});
    (params.fields || []).forEach(val => {if (val != null) __params = __params.append('fields', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/facebook/ad/${encodeURIComponent(params.adId)}/leads`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FacebookResponseWrapperIEnumerableAdsFacebookLeadFacebookGraphAPIVersion00190CultureNeutralPublicKeyTokenNull>;
      })
    );
  }
  /**
   * @param params The `FacebookAdsService.FacebookGetAdLeadsAsyncParams` containing the following parameters:
   *
   * - `adId`:
   *
   * - `pagingBefore`:
   *
   * - `pagingAfter`:
   *
   * - `limit`:
   *
   * - `filter`:
   *
   * - `fields`:
   *
   * @return Success
   */
  FacebookGetAdLeadsAsync(params: FacebookAdsService.FacebookGetAdLeadsAsyncParams): __Observable<FacebookResponseWrapperIEnumerableAdsFacebookLeadFacebookGraphAPIVersion00190CultureNeutralPublicKeyTokenNull> {
    return this.FacebookGetAdLeadsAsyncResponse(params).pipe(
      __map(_r => _r.body as FacebookResponseWrapperIEnumerableAdsFacebookLeadFacebookGraphAPIVersion00190CultureNeutralPublicKeyTokenNull)
    );
  }
}

module FacebookAdsService {

  /**
   * Parameters for FacebookGetAdsAsync
   */
  export interface FacebookGetAdsAsyncParams {
    summary?: Array<string>;
    pagingBefore?: string;
    pagingAfter?: string;
    limit?: number;
    fields?: Array<string>;
    adAccountId?: string;
  }

  /**
   * Parameters for FacebookGetAdLeadsAsync
   */
  export interface FacebookGetAdLeadsAsyncParams {
    adId: string;
    pagingBefore?: string;
    pagingAfter?: string;
    limit?: number;
    filter?: Array<any>;
    fields?: Array<string>;
  }
}

export { FacebookAdsService }
