import { Component, OnInit } from '@angular/core';
import {AccountViewModel} from '../../../api/models/account-view-model';
import {AccountsService} from '../../../api/services/accounts.service';
import {TariffPlanExpirationMeta} from '../../../api/models/tariff-plan-expiration-meta';
import * as moment from 'moment';
import {EasyAdsDataService} from '../../../automation/services/easy-ads-data.service';
import {AutomationVkService} from "../../../api/services/automation-vk.service";
import {TokenCheckerService} from "../../../shared/services/token-checker.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-easy-settings',
  templateUrl: './easy-settings.component.html',
  styleUrls: ['./easy-settings.component.scss']
})
export class EasySettingsComponent implements OnInit {
  public account: AccountViewModel = null;
  public automationTariffExpiration: TariffPlanExpirationMeta = null;
  public analyticsTariffExpiration: TariffPlanExpirationMeta = null;

  public openedChangePasswordForm: boolean = false;
  public showRemoveAccountDataModal: boolean = false;

  constructor(private accountsService: AccountsService, public easyAdsDataService: EasyAdsDataService,
              private automationApi: AutomationVkService, private tokenService: TokenCheckerService,
              private router: Router) { }

  ngOnInit() {
    this.LoadAccountInformation();
    this.LoadExpirationData();
    this.LoadVkData();
  }

  public RemoveAccountInitiate(): void {
    this.showRemoveAccountDataModal = true;
  }

  public ExpirationAt(meta: TariffPlanExpirationMeta): string {
    return moment.utc(meta.expiration).format('D MMMM YYYY, hh:mm');
  }

  private LoadAccountInformation() {
    this.accountsService.GetUserInfo()
      .subscribe(x => {
        this.account = x;
      });
  }

  private LoadExpirationData() {
    this.accountsService.GetUserTariffAccess(['VK_AUTOMATION', 'VK_ANALYTICS']).subscribe(response => {
      if (response && response.data && response.data.length  > 0) {
        this.automationTariffExpiration = response.data.find(x => x.code === 'VK_AUTOMATION');
        this.analyticsTariffExpiration = response.data.find(x => x.code === 'VK_ANALYTICS');
      }
    });
  }

  private async LoadVkData() {
    this.automationApi.GetAccounts().subscribe(response => {
      if (response && response.data && response.data.length > 0) {
        const personalCabinet = response.data.find(x => x.access_role === 'admin');

        if (personalCabinet) {
          this.easyAdsDataService.LoadBudgetViaApi(personalCabinet.account_id, personalCabinet.client_id);
        }
      }
    });
  }
}
