<div class="tariff-plan-expiration">
  <!--<div class="tariff-plan-expiration__color" [ngStyle]="styles"></div>-->
  <div class="tariff-plan-expiration__info">
    <ng-container *ngIf="tariffPlan; else tariffInfoNotFound">
      <div class="tariff-plan-expiration__title title">
        {{tariffPlan.name}}
      </div>
      <div class="tariff-plan-expiration__expiration-date">
        Истекает: <strong>{{expiredAt | utcToLocalDate | dateFormating}}</strong>
      </div>
    </ng-container>
    <ng-template #tariffInfoNotFound>
      Нет данных о вашем тарифе
    </ng-template>
  </div>

  <div class="tariff-plan-expiration__actions">
    <a [routerLink]="['/new-payment']" class="vk-standart-button">
      {{tariffPlan ? 'Продлить' : 'Подключить'}}
    </a>
  </div>
</div>
