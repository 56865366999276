<div class="error">
  {{errorMessage}}
</div>
<div class="datetime-picker">
  <div class="datetime-picker__datepicker datetime-input">
    <div class="datetime-input__label">Дата</div>
    <div class="datepicker-group">
      <div class="input-group">
        <input class="form-control" style="float:none" placeholder="ДД.ММ.ГГГГ" ngx-mydatepicker name="mydate"
               [attr.disabled]="disabled?'':null"
               [ngModel]="dateTemp" [options]="datepickerConfig" (ngModelChange)="OnDateChange($event)"
               #dp="ngx-mydatepicker" (dateChange)="OnDateChange($event)" required/>
      </div>
      <button (click)="dp.toggleCalendar()" class="calendar-button">
        <span class="ion-md-calendar"></span>
      </button>
    </div>
  </div>
  <div class="datetime-picker__timepicker datetime-input">
    <div class="datetime-input__label">Час</div>
    <div class="select">
      <select [(ngModel)]="hour" (change)="changeHour()" [disabled]="disabled || IsDateSelected" required>
        <option [value]="hour1" *ngFor="let hour1 of [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23]">
          {{hour1}}
        </option>
      </select>
    </div>
  </div>
</div>
