import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {BlogLayoutComponent} from './routes/blog-layout/blog-layout.component';
import {CreatePostComponent} from './routes/create-post/create-post.component';
import {UpdatePostComponent} from './routes/update-post/update-post.component';
import {TagsComponent} from './routes/tags/tags.component';
import {SitemapComponent} from './routes/sitemap/sitemap.component';
import {PostsListComponent} from './routes/posts-list/posts-list.component';

const routes: Routes = [
  {
    path: 'admin',
    children: [
      {
        path: 'blog',
        component: BlogLayoutComponent,
        children: [
          {
            path: 'posts',
            children: [
              {
                component: PostsListComponent,
                path: ''
              },
              {
                path: 'create',
                component: CreatePostComponent
              },
              {
                path: ':id',
                component: UpdatePostComponent
              }
            ]
          },
          {
            path: 'tags',
            component: TagsComponent
          },
          {
            path: 'sitemaps',
            component: SitemapComponent
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BlogRoutingModule { }
