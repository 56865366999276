import {Injectable} from '@angular/core';
import {PlatformsTypes} from '../../../platforms-types';
import {AkitaNgFormsManager} from '@datorama/akita-ng-forms-manager';
import {CrmPipelinesQuery} from '../../../stores/crm-pipelines/crm-pipelines.query';

@Injectable({
  providedIn: 'root'
})
export class ExportConfiguratorService {

  constructor(
    private fm: AkitaNgFormsManager,
    private pipelinesQuery: CrmPipelinesQuery
  ) {
  }


  public configure(type: PlatformsTypes) {
    const form = this.fm.getForm('originPlatformSettings');
    const destinationFormValue = this.fm.getForm('destinationPlatformSettings').value;
    console.log(form.value, 'CONFIGURATION FORM VALUE FOR EXPORT');
    const data = [
      {
        code: form.value.section,
        data: {}
      }
    ];
    console.log(form.value.isClassicMode, destinationFormValue.splitByPipelines, destinationFormValue.split_by_pipeline_statuses, 'IS CLASSIC MODE');
    if (form.value.isClassicMode && (destinationFormValue.splitByPipelines || destinationFormValue.split_by_pipeline_statuses)) {
      data.push({
        code: 'LEADS',
        data: {}
      });
    }

    return data;
  }

  public valid(type: PlatformsTypes) {
    const form = this.fm.getForm('originPlatformSettings');
    console.log(form.value, 'Validation form value');
    if (form) {
      console.log(form.value.section === 'LEADS' || form.value.section === 'DEALS' || form.value.section === 'UON_MARKS_EXPORT', 'STATUS VALIDATION');
      return form.value.section === 'LEADS' || form.value.section === 'DEALS' || form.value.section === 'UON_MARKS_EXPORT' ?
        form.value.selectedPipelineId !== null : this.pipelinesQuery.hasPipelines;
    } else {
      return false;
    }
  }
}
