import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {
  MatButtonModule,
  MatCheckboxModule,
  MatDividerModule,
  MatIconModule,
  MatOptionModule,
  MatPaginatorModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatSelectModule,
  MatSortModule,
  MatTableModule,
  MatTooltipModule
} from '@angular/material';
import {ClarityModule} from '@clr/angular';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {NgrUiModule} from '../../../ngr-ui/ngr-ui.module';
import {SharedModule} from '../../../shared/shared.module';
import {VkControlsModule} from '../../../vk-controls/vk-controls.module';
import {AdManagerModule} from '../../ad-manager/ad-manager.module';
import { NewAdFormatStepComponent } from './steps/new-ad-format-step/new-ad-format-step.component';
import { NewAdFormatComponent } from './components/new-ad-format/new-ad-format.component';
import { NewAdFormatPreviewComponent } from './components/new-ad-format-preview/new-ad-format-preview.component';
import { AdTeazersListComponent } from './components/ad-teazers-list/ad-teazers-list.component';
import { AdPromopostsListComponent } from './components/ad-promoposts-list/ad-promoposts-list.component';
import { TeazerComponent } from './components/teazer/teazer.component';
import { PromopostComponent } from './components/promopost/promopost.component';
import { PromopostMetaDataComponent } from './components/promopost-meta-data/promopost-meta-data.component';
import { PromopostLinkWidgetComponent } from './components/promopost-link-widget/promopost-link-widget.component';
import { NewTargetingSettingsComponent } from './steps/new-targeting-settings/new-targeting-settings.component';
import { GroupsTableComponent } from './components/groups-table/groups-table.component';
import { SelectedGroupsTableComponent } from './components/selected-groups-table/selected-groups-table.component';
import { NewTargetingStatsComponent } from './components/new-targeting-stats/new-targeting-stats.component';
import { NewAccountSettingsStepComponent } from './steps/new-account-settings-step/new-account-settings-step.component';
import { NewPriceAndStrategySettingsComponent } from './steps/new-price-and-startegy-settings/new-price-and-strategy-settings.component';
import { ModerationSettingsComponent } from './steps/moderation-settings/moderation-settings.component';
import { BudgetPanelComponent } from './components/budget-panel/budget-panel.component';
import { NewImagesManagerComponent } from './components/new-images-manager/new-images-manager.component';
import { NewAdImageComponent } from './components/new-ad-image/new-ad-image.component';
import { NewAccountsManagerComponent } from './components/new-accounts-manager/new-accounts-manager.component';
import { MissingAdminGroupsAlertComponent } from './components/missing-admin-groups-alert/missing-admin-groups-alert.component';
import { TargetGroupComponent } from './components/target-group/target-group.component';

@NgModule({
  declarations: [NewAdFormatStepComponent, NewAdFormatComponent, NewAdFormatPreviewComponent, AdTeazersListComponent, AdPromopostsListComponent, TeazerComponent, PromopostComponent, PromopostMetaDataComponent, PromopostLinkWidgetComponent, NewTargetingSettingsComponent, GroupsTableComponent, SelectedGroupsTableComponent, NewTargetingStatsComponent, NewAccountSettingsStepComponent, NewPriceAndStrategySettingsComponent, ModerationSettingsComponent, BudgetPanelComponent, NewImagesManagerComponent, NewAdImageComponent, NewAccountsManagerComponent, MissingAdminGroupsAlertComponent, TargetGroupComponent],
  imports: [
    CommonModule,
    AdManagerModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    MatIconModule,
    ClarityModule,
    MatButtonModule,
    VkControlsModule,
    MatOptionModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    MatRadioModule,
    MatTableModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatSortModule,
    MatTooltipModule,
    NgrUiModule,
    MatProgressSpinnerModule,
    MatDividerModule
  ],
  exports: [NewAdFormatComponent, NewAdFormatStepComponent, NewAdFormatPreviewComponent, AdTeazersListComponent, AdPromopostsListComponent, TeazerComponent, PromopostComponent, NewTargetingSettingsComponent, NewAccountSettingsStepComponent, NewPriceAndStrategySettingsComponent, ModerationSettingsComponent, NewImagesManagerComponent, NewAccountsManagerComponent, MissingAdminGroupsAlertComponent]
})
export class NewAdManagerSharedModule {
}
