import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from "@angular/forms";
import { NewImportCrmService } from "../../services/new-import-crm/new-import-crm.service";
import { CrmPipelinesQuery } from "../../../../stores/crm-pipelines/crm-pipelines.query";
import { distinctUntilChanged } from "rxjs/operators";
import { untilDestroyed } from "ngx-take-until-destroy";
import { isNullOrUndefined } from "util";
var NewImportCrmSectionComponent = /** @class */ (function () {
    function NewImportCrmSectionComponent(cdr, newImportCrmService, pipelinesQuery) {
        this.cdr = cdr;
        this.newImportCrmService = newImportCrmService;
        this.pipelinesQuery = pipelinesQuery;
        this.isVisibleSettings = false;
        this.formValues = [];
    }
    Object.defineProperty(NewImportCrmSectionComponent.prototype, "CustomFields", {
        get: function () {
            return this.newImportCrmService.customFields;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewImportCrmSectionComponent.prototype, "isSectionSelected", {
        get: function () {
            return this.formGroup.get('selected').value;
        },
        enumerable: true,
        configurable: true
    });
    NewImportCrmSectionComponent.prototype.CustomFieldsValues = function (Id) {
        var fields = this.newImportCrmService.customFieldValues;
        if (fields.find(function (field) { return field.CustomFieldId === Id; })) {
            fields = fields.filter(function (x) { return x.CustomFieldId === Id; });
        }
        else {
            fields = fields.filter(function (x) { return isNullOrUndefined(x.CustomFieldId); });
        }
        return fields;
    };
    NewImportCrmSectionComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.formGroup && this.formGroup.get('fields') && this.formGroup.get('fields').get('PM_PIPELINE_ID') && this.formGroup.get('fields').get('PM_STAGE_ID')) {
            this.formGroup.get('fields').get('PM_PIPELINE_ID')
                .valueChanges
                .pipe(distinctUntilChanged(), untilDestroyed(this))
                .subscribe(function (pipelineId) {
                var stages = _this.pipelinesQuery.GetPipelineBySectionCode(_this.section.code, pipelineId).statuses.map(function (stage) { return ({
                    Key: stage.selectable_id,
                    Label: stage.name
                }); });
                _this.newImportCrmService.SetStagesOptions(stages);
            });
        }
    };
    NewImportCrmSectionComponent.prototype.ToggleSettings = function () {
        this.isVisibleSettings = !this.isVisibleSettings;
        this.cdr.markForCheck();
    };
    NewImportCrmSectionComponent.prototype.GetOptionFields = function (fieldCode) {
        return this.newImportCrmService.getFieldOptions(fieldCode, this.formQuestions);
    };
    NewImportCrmSectionComponent.prototype.ngOnDestroy = function () {
    };
    return NewImportCrmSectionComponent;
}());
export { NewImportCrmSectionComponent };
