import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {PopoverMenuComponent} from "./components/popover-menu/popover-menu.component";
import {PopoverMenuContentComponent} from "./components/popover-menu-content/popover-menu-content.component";
import {PopoverMenuTriggerDirective} from "./directives/popover-menu-trigger.directive";
import {PopoverMenuContentDirective} from "./directives/popover-menu-content.directive";

@NgModule({
  declarations: [
    PopoverMenuComponent,
    PopoverMenuContentComponent,
    PopoverMenuTriggerDirective,
    PopoverMenuContentDirective
  ],
  exports: [
    PopoverMenuComponent,
    PopoverMenuContentComponent,
    PopoverMenuTriggerDirective,
    PopoverMenuContentDirective
  ],
  imports: [
    CommonModule
  ]
})
export class PopoverModule { }
