import {ID} from '@datorama/akita';
import {PostMonitorBackend_V2ModelsvkVKAPILeadForm} from '../../../api/models/post-monitor-backend-_v2modelsvk-vkapilead-form';
import {
  PostMonitorBackend_V2ModelsvkMyTargetAPILeadForm,
  PostMonitorBackend_V2ModelsvkVKAPILeadFormQuestion
} from '../../../api/models';

export interface AdNetworkForm {
  id: ID;
  name: string;
  group_id: number | number[];
  form_id: number;
  questions: Question[];
  lastLeadId: number | null;
  lastLeadCreateDate: Date | null
}

export interface Question {
  id: ID;
  label: string;
  key: string;
  options: any[];
}


/**
 * A factory function that creates AdNetworkForms
 */
export function createAdNetworkFormFromVk(params: Partial<PostMonitorBackend_V2ModelsvkVKAPILeadForm>) {
  return {
    id: `${params.group_id}_${params.form_id}`,
    group_id: params.group_id,
    form_id: params.form_id,
    name: params.name,
    questions: params.questions.map(question => createVkFormQuestion(question))
  } as AdNetworkForm;
}

/**
 *
 * @param question
 */
export function createVkFormQuestion(question: PostMonitorBackend_V2ModelsvkVKAPILeadFormQuestion) {
  return {
    id: question.label,
    key: question.key,
    label: question.label,
    options: question.options
  } as Question;
}

export function createMtForm(mtForm: Partial<PostMonitorBackend_V2ModelsvkMyTargetAPILeadForm>, formFields) {
  return {
    id: mtForm.form_id,
    name: `Форма ${mtForm.form_id.toString()}`,
    group_id: mtForm.campaign_ids,
    questions: formFields
  } as AdNetworkForm;
}

export function createFbFrom(forms: Partial<any>, questions) {
  return {
    ...forms,
    questions
  }
}
