import { EventEmitter, OnInit } from '@angular/core';
import { AutomationVkSlotService } from '../../../../../api/services';
import { PickListNgrComponent } from '../../../../../ngr-ui/components/pick-list/pick-list-ngr.component';
import { AdFormManagerService } from '../../services/ad-form-manager/ad-form-manager.service';
import { delay } from 'rxjs/operators';
var NewAccountsManagerComponent = /** @class */ (function () {
    function NewAccountsManagerComponent(adFormManagerService, automationSlotsService) {
        this.adFormManagerService = adFormManagerService;
        this.automationSlotsService = automationSlotsService;
        // public get slots() {
        //   return this.adFormManagerService.slots;
        // }
        //
        // public get accounts() {
        //   return this.adFormManagerService.accounts
        //     .filter(account => account.account_role === 'admin');
        // }
        this.showAccounts = true;
        this.bindingChanged = new EventEmitter();
    }
    Object.defineProperty(NewAccountsManagerComponent.prototype, "countOfMaxSlots", {
        get: function () {
            return this.adFormManagerService.slots.length;
        },
        enumerable: true,
        configurable: true
    });
    NewAccountsManagerComponent.prototype.ngOnInit = function () {
    };
    NewAccountsManagerComponent.prototype.changed = function ($event) {
        //    this.automationSlotsService.AssignCabinetToSlot({
        //      id:
        //    })
    };
    NewAccountsManagerComponent.prototype.OnSaveCabinetsBined = function (data) {
        var _this = this;
        if (!data.isSelected) {
            this.automationSlotsService.AssignCabinetToSlot({
                id: data.account_id,
                clientId: data.client_id
            })
                .pipe(delay(1000))
                .subscribe(function (data1) {
                _this.bindingChanged.emit();
            }, function (err) {
                _this.pickList.SetError(err.error);
            });
        }
        else {
            this.automationSlotsService.UnBindCabinetFromSlot({
                id: data.account_id,
                clientId: data.client_id
            })
                .subscribe(function (data1) {
                _this.bindingChanged.emit();
            }, function (err) {
                _this.pickList.SetError(err.error);
            });
        }
    };
    NewAccountsManagerComponent.prototype.Refresh = function () {
        if (this.showAccounts) {
            this.adFormManagerService.LoadAdCabinetData()
                .subscribe(function (data) {
            });
        }
        else {
            this.bindingChanged.emit();
        }
    };
    return NewAccountsManagerComponent;
}());
export { NewAccountsManagerComponent };
