import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {IAdFormatViewModel} from '../../models/ad-format.view-model';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-ad-format',
  templateUrl: './ad-format.component.html',
  styleUrls: ['./ad-format.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AdFormatComponent),
      multi: true
    }
  ]
})
export class AdFormatComponent implements OnInit, ControlValueAccessor {

  @Input() public disabled: boolean = false;
  @Input() public selectedField: string;
  @Output() public selectedFieldChange: EventEmitter<string> = new EventEmitter();
  @Input() public adFormat: IAdFormatViewModel;

  public onChange: Function;
  public onTouch: Function;

  constructor() {
  }

  ngOnInit() {
  }

  public SelectFormat(value): void {
    this.selectedField = value;
    this.onChange(value);
  }

  public IsSelected(val) {
    return this.selectedField === val;
  }

  OnChangeValue(val) {
    if (!this.disabled) {
      this.selectedField = val;
      this.onChange(val);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: any): void {
    this.selectedField = obj;
  }

}
