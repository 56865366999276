import { PlatformsTypes } from '../../../platforms-types';
import { AkitaNgFormsManager } from '@datorama/akita-ng-forms-manager';
import { MtRemarketingGroupsConfiguratorService } from '../../my-target/services/mt-remarketing-groups.configurator.service';
import { VkRetargetingGroupsConfiguratorService } from '../../vk/services/vk-retargeting-groups.configurator.service';
import * as i0 from "@angular/core";
import * as i1 from "@datorama/akita-ng-forms-manager";
import * as i2 from "../../my-target/services/mt-remarketing-groups.configurator.service";
import * as i3 from "../../vk/services/vk-retargeting-groups.configurator.service";
var AdNetworkImportConfiguratorService = /** @class */ (function () {
    function AdNetworkImportConfiguratorService(fm, mtRemarketingGroupsConfigurator, vkRetargetingGroupsConfigurator) {
        this.fm = fm;
        this.mtRemarketingGroupsConfigurator = mtRemarketingGroupsConfigurator;
        this.vkRetargetingGroupsConfigurator = vkRetargetingGroupsConfigurator;
    }
    AdNetworkImportConfiguratorService.prototype.configure = function (type) {
        console.log(type, 'CONFIGURE GENERAL FUNCTION');
        switch (type) {
            case PlatformsTypes.MT_REMARKETING_GROUPS:
            case PlatformsTypes.YANDEX_AUDIENCES:
                return this.mtRemarketingGroupsConfigurator.configure(type);
                break;
            case PlatformsTypes.VK_RETARGETING_GROUPS:
            case PlatformsTypes.FB_CUSTOM_AUDIENCES:
                return this.vkRetargetingGroupsConfigurator.configure(type);
                break;
        }
    };
    AdNetworkImportConfiguratorService.prototype.valid = function (type) {
        console.log(type, 'VALIDATION GENERAL FUNCTION');
        switch (type) {
            case PlatformsTypes.MT_REMARKETING_GROUPS:
            case PlatformsTypes.YANDEX_AUDIENCES:
                return this.mtRemarketingGroupsConfigurator.valid(type);
                break;
            case PlatformsTypes.VK_RETARGETING_GROUPS:
            case PlatformsTypes.FB_CUSTOM_AUDIENCES:
                return this.vkRetargetingGroupsConfigurator.valid(type);
                break;
        }
    };
    AdNetworkImportConfiguratorService.ngInjectableDef = i0.defineInjectable({ factory: function AdNetworkImportConfiguratorService_Factory() { return new AdNetworkImportConfiguratorService(i0.inject(i1.AkitaNgFormsManager), i0.inject(i2.MtRemarketingGroupsConfiguratorService), i0.inject(i3.VkRetargetingGroupsConfiguratorService)); }, token: AdNetworkImportConfiguratorService, providedIn: "root" });
    return AdNetworkImportConfiguratorService;
}());
export { AdNetworkImportConfiguratorService };
