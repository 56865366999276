import {Injectable} from '@angular/core';
import {QueryEntity} from '@datorama/akita';
import {TimePeriodsStore, TimePeriodsState} from './time-periods.store';
import {flatMap, map, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {TimePeriod} from './time-period.model';
import {time} from "ionicons/icons";

@Injectable({providedIn: 'root'})
export class TimePeriodsQuery extends QueryEntity<TimePeriodsState> {

  public timePeriods$ = this.selectAll();

  public  activePeriod$ = (tariffGroupId: number) =>
    this.select(state => state.ui.selectedTimePeriods)
      .pipe(
        map(selectedTimePeriods => selectedTimePeriods[`tariffGroup${tariffGroupId}`]),
        flatMap(selectedTimePeriodId => this.getTimePeriodById$(selectedTimePeriodId)),
        tap(data => {
          console.log(data, 'WHAT IS GOOD');
        })
      );

  public selectedPeriodId = (tariffGroupId) => this.activePeriod$(tariffGroupId)
    .pipe(
      map(x => x.id)
    );

  public get selectedTariffPlans$() {
    return this.select(state => state.ui.selectedTimePeriods)
  }

  public getTimePeriodById$ = timePeriodId =>
    this.selectEntity(timePeriod => timePeriod.id === timePeriodId);

  public getTimePeriodNameById = timePeriodId => {
    const timePeriod = this.getEntity(timePeriodId);
    console.log(timePeriod, timePeriodId, 'GET TIME PERIOD NAME');
    return timePeriod.name;
  }



  constructor(protected store: TimePeriodsStore) {
    super(store);
  }

}
