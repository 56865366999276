import {Injectable} from '@angular/core';
import {AkitaNgFormsManager} from "@datorama/akita-ng-forms-manager";
import {isNullOrUndefined} from "util";
import {AdNetworkFormsService} from "../../../stores/ad-network-forms/ad-network-forms.service";
import {AdNetworkFormsQuery} from "../../../stores/ad-network-forms/ad-network-forms.query";

@Injectable({
  providedIn: 'root'
})
export class LeadFormsService {

  constructor(
    private fm: AkitaNgFormsManager,
    private adNetworkForms: AdNetworkFormsQuery
  ) {
  }

  public configure() {
    const form = this.fm.getForm('originPlatformSettings');
    if (!isNullOrUndefined(form)) {
      const configurationData = this.GetSelectedFormIds(form)
        .map((formId) => ({form_id: formId, last_lead_id: this.adNetworkForms.GetLastLeadIdByGroupAndFormIds(parseInt(formId, 10), null)}));

      return {
        forms_ids: configurationData
      };
    }
    return [];
  }

  public valid() {
    const form = this.fm.getForm('originPlatformSettings');
    if (!isNullOrUndefined(form)) {
      const configurationData = this.GetSelectedFormIds(form);

      return configurationData.length > 0;
    }
    return false;
  }

  private GetSelectedFormIds(form) {
    const settings = form.value;
    const formIds = Object.keys(settings)
      .map((formId) => ({formId, isSelected: settings[formId]}))
      .filter(({isSelected}) => isSelected)
      .map(({formId}) => formId);

    return formIds;
  }
}
