/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./affiliate-program-settings-user-info-route.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../components/affiliate-program-settings-user-form/affiliate-program-settings-user-form.component.ngfactory";
import * as i3 from "../../components/affiliate-program-settings-user-form/affiliate-program-settings-user-form.component";
import * as i4 from "@angular/forms";
import * as i5 from "../../../../../storage/affiliate-user-info/affiliate-user-info.query";
import * as i6 from "../../../../../storage/affiliate-user-info/affiliate-user-info.service";
import * as i7 from "@angular/material/snack-bar";
import * as i8 from "./affiliate-program-settings-user-info-route.component";
var styles_AffiliateProgramSettingsUserInfoRouteComponent = [i0.styles];
var RenderType_AffiliateProgramSettingsUserInfoRouteComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AffiliateProgramSettingsUserInfoRouteComponent, data: {} });
export { RenderType_AffiliateProgramSettingsUserInfoRouteComponent as RenderType_AffiliateProgramSettingsUserInfoRouteComponent };
export function View_AffiliateProgramSettingsUserInfoRouteComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-affiliate-program-settings-user-form", [], null, null, null, i2.View_AffiliateProgramSettingsUserFormComponent_0, i2.RenderType_AffiliateProgramSettingsUserFormComponent)), i1.ɵdid(1, 114688, null, 0, i3.AffiliateProgramSettingsUserFormComponent, [i4.FormBuilder, i5.AffiliateUserInfoQuery, i6.AffiliateUserInfoService, i7.MatSnackBar], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_AffiliateProgramSettingsUserInfoRouteComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-affiliate-program-settings-user-info-route", [], null, null, null, View_AffiliateProgramSettingsUserInfoRouteComponent_0, RenderType_AffiliateProgramSettingsUserInfoRouteComponent)), i1.ɵdid(1, 114688, null, 0, i8.AffiliateProgramSettingsUserInfoRouteComponent, [i6.AffiliateUserInfoService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AffiliateProgramSettingsUserInfoRouteComponentNgFactory = i1.ɵccf("app-affiliate-program-settings-user-info-route", i8.AffiliateProgramSettingsUserInfoRouteComponent, View_AffiliateProgramSettingsUserInfoRouteComponent_Host_0, {}, {}, []);
export { AffiliateProgramSettingsUserInfoRouteComponentNgFactory as AffiliateProgramSettingsUserInfoRouteComponentNgFactory };
