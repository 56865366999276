import { PlatformAssignationsStore } from './platform-assignations.store';
import * as i0 from "@angular/core";
import * as i1 from "./platform-assignations.store";
var PlatformAssignationsService = /** @class */ (function () {
    function PlatformAssignationsService(store) {
        this.store = store;
    }
    PlatformAssignationsService.prototype.assignPlatform = function (platformId) {
        this.store.upsert(platformId, { assignationStatus: true });
    };
    /**
     * Отключить платформу от системы
     * @param platformId
     */
    PlatformAssignationsService.prototype.unsubscribePlatform = function (platformId) {
        this.store.upsert(platformId, { assignationStatus: false });
    };
    PlatformAssignationsService.ngInjectableDef = i0.defineInjectable({ factory: function PlatformAssignationsService_Factory() { return new PlatformAssignationsService(i0.inject(i1.PlatformAssignationsStore)); }, token: PlatformAssignationsService, providedIn: "root" });
    return PlatformAssignationsService;
}());
export { PlatformAssignationsService };
