<form [formGroup]="controlContainer.control">
  <div class="targeting-manager">
    <div class="targeting-manager__general targeting-manager__general_targeting-section">
      <div class="vk-container">
        <div class="vk-strip-wrapper vk-strip-wrapper_ad-cabinet-style">
          <div class="vk-ad-cabinet-header">
            Выберите в какой стране и городах будет показываться объявление
          </div>
        </div>
      </div>

      <div class="vk-container">
        <div class="vk-strip-wrapper vk-strip-wrapper_ad-cabinet-style">
          <label for="target-city" class="vk-label">Выберите страну:</label>

          <div class="vk-strip-wrapper__item vk-strip-wrapper__item_ad-cabinet-style">
            <select id="target-city" class="vk-select vk-select_full-width" formControlName="country">
              <option [value]="0">Любая</option>
              <ng-container *ngFor="let country of countries$ | async">
                <option [value]="country.id">
                  {{country.title}}
                </option>
              </ng-container>
            </select>
          </div>
        </div>
      </div>
      <div class="vk-container">
        <div class="vk-strip-wrapper vk-strip-wrapper_ad-cabinet-style">
          <label for="target-cities" class="vk-label">Выберите город или регион:</label>
          <div class="vk-strip-wrapper__item vk-strip-wrapper__item_ad-cabinet-style vk-strip-wrapper__item_wide">
            <mat-form-field>
              <mat-select id="target-cities" formControlName="cities" multiple>
                <ngx-mat-select-search
                  [formControl]="citySearchString"
                  placeholderLabel="Введите название города"
                ></ngx-mat-select-search>
                <mat-option [value]="0">Любой</mat-option>
                <mat-option [value]="city.id" *ngFor="let city of DisplayedCities">{{city.title}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="vk-container">
        <div class="vk-strip-wrapper vk-strip-wrapper_ad-cabinet-style">
          <div class="vk-ad-cabinet-header">
            Выберите пользователям какого пола и возраста будет показываться объявление
          </div>
        </div>
      </div>

      <div class="vk-container">
        <div class="vk-strip-wrapper vk-strip-wrapper_ad-cabinet-style">
          <label class="vk-label">Выберите пол:</label>

          <div class="vk-strip-wrapper__item vk-strip-wrapper__item_ad-cabinet-style">
            <mat-radio-group formControlName="sex" fxFlex="row nowrap" class="vk-group-radio">
              <div class="vk-radio-container">
                <mat-radio-button color="primary" [disableRipple]="true" [value]="0" class="targeting-radio">Любой</mat-radio-button>
              </div>
              <div class="vk-radio-container">
                <mat-radio-button color="primary" [disableRipple]="true" [value]="2" class="targeting-radio">Мужской</mat-radio-button>
              </div>
              <div class="vk-radio-container">
                <mat-radio-button color="primary" [disableRipple]="true" [value]="1" class="targeting-radio">Женский</mat-radio-button>
              </div>
            </mat-radio-group>
          </div>
        </div>
      </div>
      <div class="vk-container">
        <div class="vk-strip-wrapper vk-strip-wrapper_ad-cabinet-style">
          <label for="target-age-to" class="vk-label">Возраст:</label>
          <div class="vk-strip-wrapper__item vk-strip-wrapper__item_ad-cabinet-style">
            <div class="vk-strip-wrapper">
              <div class="vk-strip-wrapper__item vk-strip-wrapper__item_wide">
                <select id="target-age-to" class="vk-select vk-select_full-width" formControlName="ageFrom">
                  <option value="0">
                    Любой
                  </option>

                  <ng-container *ngFor="let age of AgesFrom">
                    <option [value]="age">
                      От {{age}}
                    </option>
                  </ng-container>
                </select>
              </div>

              <div class="vk-strip-wrapper__item vk-strip-wrapper__item_wide">
                <!--<label for="target-age-from" class="vk-label">Возраст до:</label>-->
                <select id="target-age-from" class="vk-select vk-select_full-width" formControlName="ageTo">
                  <option value="0">
                    Любой
                  </option>

                  <ng-container *ngFor="let age of AgesTo">
                    <option [value]="age">
                      До {{age}}
                    </option>
                  </ng-container>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="vk-container">
        <div class="">
          <div class="">
            <span class="vk-ad-cabinet-header">
              Выберите группы, подписчикам которых будет показываться объявление
            </span>
            <app-generic-tooltip [header]="'Как выбрать группы'" [text]="TipGroups" [noMargin]="true"></app-generic-tooltip>
          </div>
        </div>
      </div>
      <div class="vk-container">
        <app-groups-selector #groupsSelector></app-groups-selector>
      </div>
    </div>

    <div class="targeting-manager__groups">
      <div class="vk-container">
        <app-ad-audience [targeting]="targetingStats$ | async" [isDirty]="controlContainer.control.dirty"></app-ad-audience>
      </div>

      <div>Выбрано {{dataSource.data.length}} из 100</div>
      <div class="vk-container vk-container_table">
        <table mat-table [dataSource]="dataSource" class="selected">
          <ng-container matColumnDef="avatar">
            <th mat-header-cell *matHeaderCellDef width="70px">Аватар</th>
            <td mat-cell *matCellDef="let element">
              <!--4567-->
              <div class="avatar">
                <img [src]="element.photo_50" alt="">
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="name">
            <!--<th mat-header-cell *matHeaderCellDef>Название</th>-->
            <!--<td mat-cell *matCellDef="let element">1234</td>-->
            <th mat-header-cell *matHeaderCellDef>Название</th>
            <td mat-cell *matCellDef="let element">
              <span [title]="element.name">{{element.name | nameShorter:56}}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="functions">
            <th mat-header-cell *matHeaderCellDef width="50px"></th>
            <td mat-cell *matCellDef="let element">
              <button mat-icon-button color="warn" (click)="RemoveGroupFromSelected(element)">
                <mat-icon>close</mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="['avatar', 'name', 'functions']; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: ['avatar', 'name', 'functions'];">
          </tr>
        </table>
      </div>
    </div>
  </div>




  <!--<div class="targeting-manager">-->
  <!--<div class="targeting-manager__general targeting-manager__general_targeting-section">-->
  <!--<div class="targeting__panel">-->
  <!--<div class="targeting-control">-->
  <!--<div class="targeting-control__label">Выберите страну</div>-->
  <!--<div class="targeting-control__content">-->
  <!--<mat-form-field style="width: 100%;">-->
  <!--<mat-select formControlName="country">-->
  <!--<mat-option [value]="-1">Любая</mat-option>-->
  <!--<mat-option [value]="country.id" *ngFor="let country of countries$ | async">{{country.title}}</mat-option>-->
  <!--</mat-select>-->
  <!--</mat-form-field>-->
  <!--</div>-->
  <!--</div>-->
  <!--<div class="targeting-control">-->
  <!--<div class="targeting-control__label">Выберите город или регион</div>-->
  <!--<div class="targeting-control__content">-->
  <!--<mat-form-field style="width: 100%;">-->
  <!--<mat-select formControlName="cities" multiple>-->
  <!--<ngx-mat-select-search-->
  <!--[formControl]="citySearchString"-->
  <!--&gt;</ngx-mat-select-search>-->
  <!--<mat-option [value]="-1">Любой</mat-option>-->
  <!--<mat-option [value]="city.id" *ngFor="let city of cities$ | async">{{city.title}}</mat-option>-->
  <!--</mat-select>-->
  <!--</mat-form-field>-->
  <!--</div>-->
  <!--</div>-->
  <!--</div>-->
  <!--<div class="targeting__panel">-->
  <!--<div class="targeting-control">-->
  <!--<div class="targeting-control__label">Пол</div>-->
  <!--<div class="targeting-control__content">-->
  <!--<mat-radio-group formControlName="sex">-->
  <!--<mat-radio-button color="primary" [value]="0" class="targeting-radio">Любой</mat-radio-button>-->
  <!--<mat-radio-button color="primary" [value]="2" class="targeting-radio">Мужской</mat-radio-button>-->
  <!--<mat-radio-button color="primary" [value]="1" class="targeting-radio">Женский</mat-radio-button>-->
  <!--</mat-radio-group>-->
  <!--</div>-->
  <!--</div>-->
  <!--</div>-->

  <!--<div class="targeting__panel targeting__panel_row">-->
  <!--<div class="targeting-control">-->
  <!--<div class="targeting-control__label">Возраст от</div>-->
  <!--<div class="targeting-control__content">-->
  <!--<mat-form-field  style="width: 100%">-->
  <!--<mat-select formControlName="ageFrom">-->
  <!--<mat-option [value]="0">Любой</mat-option>-->
  <!--<mat-option [value]="age" *ngFor="let age of agesFrom">От {{age}}</mat-option>-->
  <!--</mat-select>-->
  <!--</mat-form-field>-->
  <!--</div>-->
  <!--</div>-->
  <!--<div class="targeting-control">-->
  <!--<div class="targeting-control__label">Возраст до</div>-->
  <!--<div class="targeting-control__content">-->
  <!--<div class="select">-->
  <!--<mat-form-field style="width: 100%">-->
  <!--<mat-select formControlName="ageTo">-->
  <!--<mat-option [value]="0">Любой</mat-option>-->
  <!--<mat-option [value]="age" *ngFor="let age of agesTo">До {{age}}</mat-option>-->
  <!--</mat-select>-->
  <!--</mat-form-field>-->
  <!--</div>-->
  <!--</div>-->
  <!--</div>-->
  <!--</div>-->
  <!--<div class="targeting__panel targeting__panel_groups">-->
  <!--<div class="targeting-control">-->
  <!--<div class="targeting-control__label">Сообщества целевой аудитории</div>-->
  <!--&lt;!&ndash;<div class="targeting-control__label">Выберите сообщества аудитории</div>&ndash;&gt;-->
  <!--<div class="targeting-control__content targeting-control__content_full">-->
  <!--<app-groups-selector-->
  <!--#groupsSelector-->
  <!--&gt;</app-groups-selector>-->


  <!--</div>-->
  <!--</div>-->
  <!--</div>-->
  <!--</div>-->
  <!--<div class="targeting-manager__groups">-->
  <!--<div class="targeting__panel">-->
  <!--<app-ad-audience [targeting]="targetingStats$ | async" [isDirty]="controlContainer.control.dirty"></app-ad-audience>-->
  <!--</div>-->
  <!--<table mat-table [dataSource]="dataSource" matSort class="selected">-->
  <!--<ng-container matColumnDef="avatar">-->
  <!--<th mat-header-cell *matHeaderCellDef>Аватар</th>-->
  <!--<td mat-cell *matCellDef="let element">-->
  <!--&lt;!&ndash;4567&ndash;&gt;-->
  <!--<div class="avatar">-->
  <!--<img [src]="element.photo_50" alt="">-->
  <!--</div>-->
  <!--</td>-->
  <!--</ng-container>-->

  <!--<ng-container matColumnDef="name">-->
  <!--&lt;!&ndash;<th mat-header-cell *matHeaderCellDef>Название</th>&ndash;&gt;-->
  <!--&lt;!&ndash;<td mat-cell *matCellDef="let element">1234</td>&ndash;&gt;-->
  <!--<th mat-header-cell *matHeaderCellDef mat-sort-header>Название</th>-->
  <!--<td mat-cell *matCellDef="let element">{{element.name | nameShorter:20}}</td>-->
  <!--</ng-container>-->

  <!--<ng-container matColumnDef="functions">-->
  <!--<th mat-header-cell mat-sort-header *matHeaderCellDef></th>-->
  <!--<td mat-cell *matCellDef="let element">-->
  <!--<button mat-icon-button color="warn" (click)="RemoveGroupFromSelected(element)">-->
  <!--<mat-icon>close</mat-icon>-->
  <!--</button>-->
  <!--</td>-->
  <!--</ng-container>-->

  <!--<tr mat-header-row *matHeaderRowDef="['avatar', 'name', 'functions']"></tr>-->
  <!--<tr mat-row *matRowDef="let row; columns: ['avatar', 'name', 'functions'];">-->
  <!--</tr>-->
  <!--</table>-->
  <!--&lt;!&ndash;<app-groups-selector&ndash;&gt;-->
  <!--&lt;!&ndash;[maxCountOfSelectedGroups]="maxCountOfSelectedGroups"&ndash;&gt;-->
  <!--&lt;!&ndash;[selectedGroups]="selectedGroups"&ndash;&gt;-->
  <!--&lt;!&ndash;[countries]="countries"&ndash;&gt;-->
  <!--&lt;!&ndash;(groupsSelected)="GroupsSelected($event)"&ndash;&gt;-->
  <!--&lt;!&ndash;(groupsUnselected)="OnGroupUnselected($event)"&ndash;&gt;-->
  <!--&lt;!&ndash;#groupsSelector&ndash;&gt;-->
  <!--&lt;!&ndash;&gt;</app-groups-selector>&ndash;&gt;-->
  <!--</div>-->
  <!--</div>-->



  <!--<mat-grid-list cols="4" rowHeight="100px">-->
  <!--<mat-grid-tile colspan="2" rowspan="1">-->
  <!--<b>Выберите страну</b>-->
  <!--<ng-select formControlName="country">-->
  <!--<ng-option [value]="country.id" *ngFor="let country of countries">{{country.title}}</ng-option>-->
  <!--</ng-select>-->
  <!--</mat-grid-tile>-->
  <!--<mat-grid-tile [colspan]="2" [rowspan]="2"></mat-grid-tile>-->
  <!--<mat-grid-tile [colspan]="2" [rowspan]="1">-->
  <!--<b>Выберите город или регион</b>-->

  <!--</mat-grid-tile>-->
  <!--</mat-grid-list>-->
  <!--<div class="fx-row targeting-settings">-->
  <!--<div class="targeting__general">-->
  <!--<b>Выберите страну</b>-->
  <!--<ng-select formControlName="country">-->
  <!--<ng-option [value]="country.id" *ngFor="let country of countries">{{country.title}}</ng-option>-->
  <!--</ng-select>-->
  <!--<b>Выберите город или регион</b>-->
  <!--<ng-select-->
  <!--[items]="cities$ | async"-->
  <!--[multiple]="true"-->
  <!--[closeOnSelect]="false"-->
  <!--[searchable]="true"-->
  <!--[searchFn]="SearchCities"-->
  <!--[typeahead]="cities$input"-->
  <!--bindLabel="title"-->
  <!--bindValue="id"-->
  <!--formControlName="cities"-->
  <!--&gt;-->
  <!--<ng-option [value]="city.id" *ngFor="let city of cities">{{city.title}}</ng-option>-->
  <!--</ng-select>-->
  <!--<div>-->
  <!--<b>Пол</b> <br>-->
  <!--<mat-radio-group formControlName="sex">-->
  <!--<mat-radio-button class="radio" value="0" color="primary">Любой</mat-radio-button>-->
  <!--<mat-radio-button class="radio" value="1" color="primary">Мужской</mat-radio-button>-->
  <!--<mat-radio-button class="radio" value="2" color="primary">Женский</mat-radio-button>-->
  <!--</mat-radio-group>-->
  <!--</div>-->
  <!--<div>-->
  <!--<b>Возраст от</b>-->
  <!--<mat-form-field>-->
  <!--<select matNativeControl>-->
  <!--<option>Любой</option>-->
  <!--</select>-->
  <!--</mat-form-field>-->
  <!--</div>-->
  <!--<div>-->
  <!--<b>Возраст до</b>-->
  <!--<mat-form-field>-->
  <!--<select matNativeControl>-->
  <!--<option>Любой</option>-->
  <!--</select>-->
  <!--</mat-form-field>-->
  <!--</div>-->
  <!--<div class="groups-list">-->
  <!--<div *ngFor="let group of SelectedGroups">-->
  <!--<app-selectable-group [group]="group"></app-selectable-group>-->
  <!--</div>-->
  <!--</div>-->
  <!--<button class="btn btn-success" (click)="next()">Даелее</button>-->
  <!--</div>-->
  <!--<div class="targeting__groups">-->
  <!--<app-groups-selector-->
  <!--[$groups]="groups"-->
  <!--(groupsSearched)="SearchGroups($event)"-->
  <!--&gt;</app-groups-selector>-->
  <!--</div>-->
  <!--</div>-->
  <!--<mat-form-field>-->
  <!--<mat-label>Страна</mat-label>-->
  <!--<mat-select formControlName="country">-->
  <!--<mat-option value="ru">Россия</mat-option>-->
  <!--<mat-option value="ua">Украина</mat-option>-->
  <!--<mat-option value="by">Беларусь</mat-option>-->
  <!--<mat-option value="kz">Казахстан</mat-option>-->
  <!--</mat-select>-->
  <!--</mat-form-field>-->

  <!--<mat-form-field>-->
  <!--<mat-label>Города</mat-label>-->
  <!--<mat-select formControlName="cities" multiple>-->
  <!--<mat-option value="omsk">Омск</mat-option>-->
  <!--<mat-option value="moscow">Москва</mat-option>-->
  <!--<mat-option value="st. Peterburg">Питер</mat-option>-->
  <!--<mat-option value="kazan">Казань</mat-option>-->
  <!--</mat-select>-->
  <!--</mat-form-field>-->
  <!--{{Cities}}-->
</form>
