import { AffiliateLinksStore } from './affiliate-links.store';
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "./affiliate-links.store";
import * as i2 from "@angular/common/http";
var AffiliateLinksService = /** @class */ (function () {
    function AffiliateLinksService(store, http) {
        this.store = store;
        this.http = http;
        this.baseUrl = environment.backendUri + "/" + environment.apiPrefix;
    }
    AffiliateLinksService.prototype.LoadAffiliateLink = function () {
        var _this = this;
        this.http.get(this.baseUrl + "/affiliate-links")
            .subscribe(function (data) {
            if (data) {
                _this.store.set([data]);
            }
        });
    };
    AffiliateLinksService.ngInjectableDef = i0.defineInjectable({ factory: function AffiliateLinksService_Factory() { return new AffiliateLinksService(i0.inject(i1.AffiliateLinksStore), i0.inject(i2.HttpClient)); }, token: AffiliateLinksService, providedIn: "root" });
    return AffiliateLinksService;
}());
export { AffiliateLinksService };
