var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from '@angular/common/http';
import { CustomFieldsStore } from './custom-fields.store';
import { map } from 'rxjs/operators';
import { AssignationPlatforms } from "../platform-assignations/platform-assignation.model";
import { MegaplanService } from "../../../api/services";
import { of } from "rxjs";
import { Paginatable } from "../../../utils/js/paginatable";
import { CustomFieldValueService } from "../custom-field-value/custom-field-value.service";
import * as i0 from "@angular/core";
import * as i1 from "./custom-fields.store";
import * as i2 from "@angular/common/http";
import * as i3 from "../../../api/services/megaplan.service";
import * as i4 from "../custom-field-value/custom-field-value.service";
var CustomFieldsService = /** @class */ (function (_super) {
    __extends(CustomFieldsService, _super);
    function CustomFieldsService(customFieldsStore, http, megaplanApi, customFieldValueService) {
        var _this = _super.call(this, null) || this;
        _this.customFieldsStore = customFieldsStore;
        _this.http = http;
        _this.megaplanApi = megaplanApi;
        _this.customFieldValueService = customFieldValueService;
        _this.platform = null;
        return _this;
    }
    CustomFieldsService.prototype.LoadCustomFields = function (platform, page) {
        if (page === void 0) { page = null; }
        this.platform = platform;
        switch (platform) {
            case AssignationPlatforms.MEGAPLAN:
                return this.LoadMegaplanCustomFields(page);
            default:
                return of([]);
        }
    };
    CustomFieldsService.prototype.LoadMegaplanCustomFields = function (page) {
        var _this = this;
        console.log('LOAD CUSTOM FIELDS');
        return this.megaplanApi.GetContractorDealsExtraFieldsAsync({
            limit: null,
            contractorId: '1',
            pageAfter: page,
            contentType: 'StringField'
        })
            .pipe(map(function (customFields) {
            console.log(customFields);
            var hasNext = customFields.meta.pagination.hasMoreNext;
            // const fields = customFields.data
            //   .filter(x => ['StringField', 'EnumField'].includes(x.contentType))
            //   .map(customField => ({
            //     Id: customField.id,
            //     Name: customField.hrName,
            //     Key: customField.name,
            //     PlatformCode: 'FB_LEAD_FORMS'
            //   } as CustomField));
            var fields = [];
            var _loop_1 = function (field) {
                if (['StringField', 'EnumField'].includes(field.contentType)) {
                    var formatedField = {
                        Id: field.id,
                        Name: field.hrName,
                        Key: field.name,
                        PlatformCode: 'FB_LEAD_FORMS'
                    };
                    fields.push(formatedField);
                    if (field.contentType === 'EnumField') {
                        console.log(field.enumValues, 'ENUM FIELD');
                        field.enumValues.forEach(function (EnumValue, index) {
                            var customValue = {
                                Id: index * 100,
                                Code: EnumValue,
                                Name: EnumValue,
                                PlatformCode: 'FB_LEAD_FORMS',
                                CustomFieldId: field.id,
                                UseFormating: false
                            };
                            console.log(customValue, index, 'CUSTOM VALUE');
                            _this.customFieldValueService.add(customValue);
                        });
                    }
                }
            };
            for (var _i = 0, _a = customFields.data; _i < _a.length; _i++) {
                var field = _a[_i];
                _loop_1(field);
            }
            console.log(fields, 'FIELDS');
            var lastField = fields[fields.length - 1];
            _this.customFieldsStore.add(fields);
            console.log(hasNext, 'HAS NEXT');
            if (hasNext) {
                console.log('WTF');
                _this.NextToken(lastField.Id);
            }
            return fields;
        }));
    };
    CustomFieldsService.prototype.OnNextToken = function (tokenValue) {
        this.LoadCustomFields(this.platform, tokenValue)
            .subscribe();
    };
    CustomFieldsService.ngInjectableDef = i0.defineInjectable({ factory: function CustomFieldsService_Factory() { return new CustomFieldsService(i0.inject(i1.CustomFieldsStore), i0.inject(i2.HttpClient), i0.inject(i3.MegaplanService), i0.inject(i4.CustomFieldValueService)); }, token: CustomFieldsService, providedIn: "root" });
    return CustomFieldsService;
}(Paginatable));
export { CustomFieldsService };
