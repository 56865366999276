import { OnInit } from "@angular/core";
import { AffiliateMembersStatisticsByDateQuery } from "./affiliate-members-statistics-by-date.query";
import { AffiliateMembersStatisticsByDateService } from "./affiliate-members-statistics-by-date.service";
import { combineLatest } from "rxjs";
import * as i0 from "@angular/core";
import * as i1 from "./affiliate-members-statistics-by-date.query";
import * as i2 from "./affiliate-members-statistics-by-date.service";
var AffiliateMemberStatisticsByDateFacade = /** @class */ (function () {
    function AffiliateMemberStatisticsByDateFacade(query, service) {
        this.query = query;
        this.service = service;
    }
    Object.defineProperty(AffiliateMemberStatisticsByDateFacade.prototype, "statistics$", {
        get: function () {
            return this.query.statistics$;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AffiliateMemberStatisticsByDateFacade.prototype, "dateFrom$", {
        get: function () {
            return this.query.dateFrom$;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AffiliateMemberStatisticsByDateFacade.prototype, "dateTo$", {
        get: function () {
            return this.query.dateTo$;
        },
        enumerable: true,
        configurable: true
    });
    AffiliateMemberStatisticsByDateFacade.prototype.ngOnInit = function () {
        var _this = this;
        combineLatest(this.dateFrom$, this.dateTo$)
            .subscribe(function () {
            _this.LoadStatistics();
        });
    };
    AffiliateMemberStatisticsByDateFacade.prototype.LoadStatistics = function () {
        var dateFrom = this.query.dateFrom;
        var dateTo = this.query.dateTo;
        this.service.get(dateFrom, dateTo)
            .subscribe();
    };
    AffiliateMemberStatisticsByDateFacade.ngInjectableDef = i0.defineInjectable({ factory: function AffiliateMemberStatisticsByDateFacade_Factory() { return new AffiliateMemberStatisticsByDateFacade(i0.inject(i1.AffiliateMembersStatisticsByDateQuery), i0.inject(i2.AffiliateMembersStatisticsByDateService)); }, token: AffiliateMemberStatisticsByDateFacade, providedIn: "root" });
    return AffiliateMemberStatisticsByDateFacade;
}());
export { AffiliateMemberStatisticsByDateFacade };
