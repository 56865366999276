import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PickListItemModel} from '../../models/pick-list-item-model';
import {AutomationVkSlotService} from '../../../api/services/automation-vk-slot.service';
import {ErrorDescription} from '../../../api/models/error-description';
import {UserAutomationCabinetSlot} from '../../../api/models';
import {AccountViewModel} from '../../models/view-models/account-view-model';
import {isNullOrUndefined} from 'util';
import * as moment from 'moment';
moment.locale('ru');

@Component({
    selector: 'app-pick-list',
    templateUrl: './pick-list.component.html',
    styleUrls: ['./pick-list.component.scss']
})
export class PickListComponent implements OnInit {

  /**
     * Элементы списка
     * @type {PickListItemModel[]}
     */
    @Input() public dataList: AccountViewModel[] = [];

    /**
     * Максимально возможная длинна массива с выделенными элементами списка
     * @type {number}
     */
    @Input('maxCount') public maxSelectedCount;

    /**
     * Количество свободных слотов
     * @type {number}
     */
    @Input('slots') public slots: UserAutomationCabinetSlot[];

    /**
     * Событие сохранения кабинетов
     * @type {EventEmitter<any>}
     */
    @Output() public dataListChanged: EventEmitter<any> = new EventEmitter<any>();


    /**
     * Событие закрытия окна
     * @type {EventEmitter<any>}
     */
    @Output() public onClose: EventEmitter<any> = new EventEmitter<any>();

    public get Items() {
        return this.dataList.filter(x => x.account_type !== 'agency').map(x => {
            x.isSelected = !x.is_blocked;
            return x;
        });
    }

    public get FreeSlots() {
        return this.slots ? this.slots.filter(x => x.bindedCabinetId === null).length : 0;
    }

    public error = '';

    constructor(
        private _service: AutomationVkSlotService
    ) {
    }

    ngOnInit() {
    }

    Change(status: boolean, data: UserAutomationCabinetSlot) {
        this.dataListChanged.emit(data);
    }

    /**
     * Сохраняет значения списка
     * @constructor
     */
    Save(): void {
        this.onClose.emit();
    }

    public GetSlotByAccountId(account_id: number): UserAutomationCabinetSlot {
      return this.slots.find(x => x.bindedCabinetId === account_id);
    }

    public GetDeactivationTimeString(account_id: number): string {
      const slot = this.GetSlotByAccountId(account_id);

      if (slot) {
        const date = moment(slot.canRebindAfter).local();
        return moment().to(date);
      } else {
        return '';
      }
    }

    public SetError(err: ErrorDescription) {
        this.error = err.description;
    }

    public CanBeSelected(account: AccountViewModel) {
        let slot: UserAutomationCabinetSlot = null;
        if (account.account_type === 'client') {
            slot = this.slots.find(x => x.bindedCabinetId === account.account_id && x.bindedClientId === account.client_id);
        } else {
            slot = this.slots.find(x => x.bindedCabinetId === account.account_id);
        }
        if (slot) {
            return (!isNullOrUndefined(slot.bindedCabinetId)) ? !slot.canRebindCabinet : this.FreeSlots === 0;;
        } else {
            return this.FreeSlots === 0;
        }
    }
}
