export class ImageValidationViewModel {
  constructor(
    public width: boolean,
    public height: boolean,
    public size: boolean
  ) {
  }

  public get status() {
    return this.width && this.height && this.size;
  }
}
