/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./copying-by-group-actives-file.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "./copying-by-group-actives-file.component";
import * as i4 from "../../../services/copying.service";
var styles_CopyingByGroupActivesFileComponent = [i0.styles];
var RenderType_CopyingByGroupActivesFileComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CopyingByGroupActivesFileComponent, data: {} });
export { RenderType_CopyingByGroupActivesFileComponent as RenderType_CopyingByGroupActivesFileComponent };
export function View_CopyingByGroupActivesFileComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.ɵangular_packages_forms_forms_bh, [], null, null), i1.ɵdid(2, 540672, null, 0, i2.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i2.ControlContainer, null, [i2.FormGroupDirective]), i1.ɵdid(4, 16384, null, 0, i2.NgControlStatusGroup, [[4, i2.ControlContainer]], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 0, "input", [["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.SelectFile($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.form; _ck(_v, 2, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 4).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 4).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 4).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 4).ngClassValid; var currVal_5 = i1.ɵnov(_v, 4).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 4).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_CopyingByGroupActivesFileComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-copying-by-group-actives-file", [], null, null, null, View_CopyingByGroupActivesFileComponent_0, RenderType_CopyingByGroupActivesFileComponent)), i1.ɵdid(1, 114688, null, 0, i3.CopyingByGroupActivesFileComponent, [i4.CopyingService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CopyingByGroupActivesFileComponentNgFactory = i1.ɵccf("app-copying-by-group-actives-file", i3.CopyingByGroupActivesFileComponent, View_CopyingByGroupActivesFileComponent_Host_0, {}, {}, []);
export { CopyingByGroupActivesFileComponentNgFactory as CopyingByGroupActivesFileComponentNgFactory };
