import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RemarketingGroupsSettingsComponent } from './components/remarketing-groups-settings/remarketing-groups-settings.component';
import {MatCheckboxModule, MatRadioModule, MatSelectModule} from '@angular/material';
import {VkControlsModule} from '../../../vk-controls/vk-controls.module';
import {ReactiveFormsModule} from '@angular/forms';
import { PipelineStatusSelectorComponent } from './components/pipeline-status-selector/pipeline-status-selector.component';
import { LeadFormsConfiguratorComponent } from './components/lead-forms-configurator/lead-forms-configurator.component';
import { LeadFormSettingsComponent } from './components/lead-form-settings/lead-form-settings.component';
import {SharedModule} from "../../../shared/shared.module";
import {MatIconModule} from "@angular/material/icon";

@NgModule({
  declarations: [RemarketingGroupsSettingsComponent, PipelineStatusSelectorComponent, LeadFormsConfiguratorComponent, LeadFormSettingsComponent],
  exports: [
    RemarketingGroupsSettingsComponent,
    LeadFormsConfiguratorComponent,
    PipelineStatusSelectorComponent
  ],
    imports: [
        CommonModule,
        MatRadioModule,
        VkControlsModule,
        ReactiveFormsModule,
        MatCheckboxModule,
        MatSelectModule,
        SharedModule,
        MatIconModule
    ]
})
export class MyTargetModule { }
