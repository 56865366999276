<div class="alerts">
  <div class="alert" [ngClass]="notification.type" *ngFor="let notification of notifications">
    <div class="alert-items">
      <div class="alert-item static">
        <div class="alert-text">
          {{notification.title}}
        </div>
      </div>
    </div>
  </div>
</div>