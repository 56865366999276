var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { VkApiService } from './vk-api.service';
var VkStealthParserHelperService = /** @class */ (function () {
    function VkStealthParserHelperService(VkApi) {
        this.VkApi = VkApi;
        this.gapTreshold = 100;
        this.gapCountPreAPIRequest = 100;
        this.lookaheadPostsPerRequestAmount = 100;
        this.lookaheadMaximalUselessRequestsPerRow = 3;
        this.visitedPostsIdList = [];
    }
    VkStealthParserHelperService.prototype.LookaheadScan = function (group_id, scan_from_id) {
        return __awaiter(this, void 0, void 0, function () {
            var uselessRequestsLeft, stealthAds, idsList, ids, response, ads;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.visitedPostsIdList = [];
                        uselessRequestsLeft = this.lookaheadMaximalUselessRequestsPerRow;
                        stealthAds = [];
                        _a.label = 1;
                    case 1:
                        idsList = this.GenerateArray(scan_from_id, scan_from_id + this.lookaheadPostsPerRequestAmount);
                        this.visitedPostsIdList.push.apply(this.visitedPostsIdList, idsList);
                        ids = this.ConvertIdListToWallGetByIdParameter(group_id, idsList);
                        return [4 /*yield*/, this.VkApi
                                .WallGetById(ids, false, 1, null)
                                .toPromise()];
                    case 2:
                        response = _a.sent();
                        scan_from_id += this.lookaheadPostsPerRequestAmount;
                        if (response && response.length > 0) {
                            ads = response.filter(function (x) { return x.post_type === 'post_ads'; });
                            if (ads && ads.length > 0) {
                                ads.forEach(function (ad) {
                                    stealthAds.push(ad);
                                });
                                uselessRequestsLeft = this.lookaheadMaximalUselessRequestsPerRow + 1;
                            }
                        }
                        return [4 /*yield*/, this.Delay(this.VkApi.timeout)];
                    case 3:
                        _a.sent();
                        uselessRequestsLeft -= 1;
                        _a.label = 4;
                    case 4:
                        if (uselessRequestsLeft >= 0) return [3 /*break*/, 1];
                        _a.label = 5;
                    case 5: return [2 /*return*/, stealthAds];
                }
            });
        });
    };
    VkStealthParserHelperService.prototype.RetrieveStealthAds = function (gaps, group_id) {
        return __awaiter(this, void 0, void 0, function () {
            var index, stealthAds, idsList, ids, response, ads;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        index = 0;
                        stealthAds = [];
                        _a.label = 1;
                    case 1:
                        if (!(index < gaps.length)) return [3 /*break*/, 4];
                        idsList = gaps.slice(index, index + this.gapCountPreAPIRequest);
                        ids = this.ConvertIdListToWallGetByIdParameter(group_id, idsList);
                        return [4 /*yield*/, this.VkApi
                                .WallGetById(ids, false, 1, null)
                                .toPromise()];
                    case 2:
                        response = _a.sent();
                        if (response && response.length > 0) {
                            ads = response.filter(function (x) { return x.post_type === 'post_ads'; });
                            if (ads && ads.length > 0) {
                                ads.forEach(function (ad) {
                                    stealthAds.push(ad);
                                });
                            }
                        }
                        return [4 /*yield*/, this.Delay(this.VkApi.timeout)];
                    case 3:
                        _a.sent();
                        index += this.gapCountPreAPIRequest;
                        return [3 /*break*/, 1];
                    case 4: return [2 /*return*/, stealthAds];
                }
            });
        });
    };
    VkStealthParserHelperService.prototype.Delay = function (ms) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) { return setTimeout(resolve, ms); })];
            });
        });
    };
    VkStealthParserHelperService.prototype.DetectGapsInWall = function (postIdList) {
        var gaps = [];
        var currentId = postIdList[0] - 1;
        if (postIdList.length >= 2) {
            for (var i = 1; i < postIdList.length; i = i + 1) {
                var value = postIdList[i];
                if (currentId !== value) {
                    var difference = Math.abs(currentId - value);
                    if (difference <= this.gapTreshold) {
                        var newGaps = this.GenerateArray(value + 1, value + 1 + difference);
                        gaps.push.apply(gaps, newGaps);
                    }
                }
                currentId = value - 1;
            }
        }
        return gaps;
    };
    VkStealthParserHelperService.prototype.GenerateArray = function (start, end) {
        var foo = [];
        for (var i = start; i <= end; i++) {
            foo.push(i);
        }
        return foo;
    };
    VkStealthParserHelperService.prototype.ConvertIdListToWallGetByIdParameter = function (group_id, ids) {
        return ids.map(function (element) {
            return -group_id + "_" + element;
        });
    };
    return VkStealthParserHelperService;
}());
export { VkStealthParserHelperService };
