import {Component, Input, OnInit} from '@angular/core';
import {ImportCrmItem} from '../../../../models/import-crm-item';
import {LeadFormField} from '../../../../models/lead-form-field';
import {FormGroup} from '@angular/forms';
import {IntegrationFormService} from '../../services/integration-form.service';
import {IntegrationPlatformSection} from '../../../../../api/models/integration-platform-section';

@Component({
  selector: 'app-import-crm-section',
  templateUrl: './import-crm-section.component.html',
  styleUrls: ['./import-crm-section.component.scss']
})
export class ImportCrmSectionComponent implements OnInit {

  @Input() public section: IntegrationPlatformSection;
  @Input() public formFields: LeadFormField[];
  public loaded = false;
  public settingsOpened: boolean;
  public wwww: any = '';

  public get form() {
    return this.integrationForm.formService.getControl('amoImport', this.section.code) as FormGroup;
  }

  constructor(
    private integrationForm: IntegrationFormService
  ) {
  }

  ngOnInit() {
    console.log('amoImport', this.section.code);
    // this.form = this.integrationForm.formService.getControl('amoImport', this.section.code) as FormGroup;
    console.log(this.form);
  }

  ToggleSettings() {
    this.settingsOpened = !this.settingsOpened;
  }
}
