<mat-vertical-stepper>
  <ng-template matStepperIcon="edit" let-index="index">
    {{index + 1}}
  </ng-template>
  <mat-step *ngIf="!IsUpdate">
    <div *matStepLabel>
      <ng-container *ngIf="!IsUpdate; else editTitleStep">
        Выберите формат объявления
      </ng-container>
    </div>
    <div>
      <a
        href="https://postmonitor.ru/blog/instrukciya-dlya-1-shaga-sozdaniya-obyavleniya---vyberite-format-obyavleniya"
        target="_blank"
        rel="nofollow noopener"
        class="ad-form-step-instruction"
      >
        <span class="ion-md-bulb"></span>
        Рекомендации по выбору формата объявления.
      </a>
      <app-new-ad-format-step></app-new-ad-format-step>
      <button class="vk-standart-button" matStepperNext>Далее</button>
    </div>
  </mat-step>

  <ng-container *ngIf="IsPromopost; else teazerForm">
    <mat-step>
      <div *matStepLabel>
        <ng-container *ngIf="!IsUpdate; else editTitleStep">
          Создайте объявление
        </ng-container>
      </div>
      <div>
        <a
          href="https://postmonitor.ru/blog/instrukciya-dlya-2-shaga-sozdaniya-obyavleniya---sozdajjte-obyavlenie"
          target="_blank"
          rel="nofollow noopener"
          class="ad-form-step-instruction"
        >
          <span class="ion-md-bulb"></span>
          Рекомендации по созданию объявления.
        </a>
        <app-new-promopost-form-lite></app-new-promopost-form-lite>
        <button class="vk-standart-button" matStepperNext>Далее</button>
<!--        <ng-container *ngIf="!checkingAccount; else checkingLoadingScreen">-->
<!--          <ng-container *ngIf="ShowActiveAccounts; else nextWithCheckCampaign">-->
<!--            <button class="vk-standart-button" matStepperNext [disabled]="!IsAdValid">Далее</button>-->
<!--          </ng-container>-->
<!--        </ng-container>-->
      </div>
    </mat-step>
  </ng-container>

  <ng-template #teazerForm>
    <mat-step>
      <div *matStepLabel>Создайте объявление</div>
      <div>
        <a
          href="https://postmonitor.ru/blog/instrukciya-dlya-2-shaga-sozdaniya-obyavleniya---sozdajjte-obyavlenie"
          target="_blank"
          rel="nofollow noopener"
          class="ad-form-step-instruction"
        >
          <span class="ion-md-bulb"></span>
          Рекомендации по созданию объявления.
        </a>
        <app-new-teazer-form-lite></app-new-teazer-form-lite>
        <button class="vk-standart-button" matStepperNext>Далее</button>
      </div>
    </mat-step>
  </ng-template>

  <ng-template #nextWithCheckCampaign>
    <button class="vk-standart-button" matStepperNext (click)="checkForExistingSystemCampaignsAndCreateIfNotExists(true)" [disabled]="!IsAdValid">Далее</button>
  </ng-template>

  <mat-step *ngIf="ShowActiveAccounts">
    <div *matStepLabel>Активируйте кабинет</div>
    <div>

      <ng-container *ngIf="!checkingAccount; else checkingLoadingScreen">
        <app-ad-accounts-not-found (adminAccountBinded)="stepper.next()"></app-ad-accounts-not-found>
<!--        <app-new-accounts-manager (bindingChanged)="checkForExistingSystemCampaignsAndCreateIfNotExists(true)"></app-new-accounts-manager>-->
<!--        <button class="vk-standart-button" matStepperNext [disabled]="!HasAssignedAccounts">Далее</button>-->
      </ng-container>
    </div>
  </mat-step>

  <mat-step>
    <div *matStepLabel>Выберите свою аудиторию</div>
    <a
      href="https://postmonitor.ru/blog/instrukciya-dlya-3-shaga-sozdaniya-obyavleniya---vyberite-komu-pokazyvat-obyavlenie"
      target="_blank"
      rel="nofollow noopener"
      class="ad-form-step-instruction"
    >
      <span class="ion-md-bulb"></span>
      Рекомендации по выбору кому показывать объявление.
    </a>
    <ng-container *ngIf="!IsAssignedCabinetProcessLoading; else assignLoadingData">
      <app-new-targeting-settings></app-new-targeting-settings>
      <ng-container *ngIf="!createAdLoading; else loadingScreen">
        <ng-container *ngFor="let error of errors">
          <div class="vk-error">{{error}}</div>
        </ng-container>
        <button class="vk-standart-button" (click)="createAd()" [disabled]="!IsValidTargeting || errors.length > 0">
          <ng-container *ngIf="!IsUpdate; else updateButtonText">
            Создать объявления
          </ng-container>
          <ng-template #updateButtonText>
            Сохранить объявление
          </ng-template>
        </button>
      </ng-container>
      <ng-template #loadingScreen>
        <div class="loading-screen">
          <div class="loading-screen__spinner">
            <mat-spinner diameter="18"></mat-spinner>
          </div>
          <div class="loading-screen__message">
            <ng-container *ngIf="!IsUpdate; else savingText">
              Подождите, идет создание объявления ...
            </ng-container>
            <ng-template #savingText>
              Подождите, идет сохранение объявления ...
            </ng-template>
          </div>
        </div>
      </ng-template>
    </ng-container>
    <ng-template #assignLoadingData>
      <div class="loading-screen">
        <div class="loading-screen__spinner">
          <mat-spinner diameter="18"></mat-spinner>
        </div>
        <div class="loading-screen__message">Подождите, идет обработка данных ...</div>
      </div>
    </ng-template>
  </mat-step>
</mat-vertical-stepper>

<ng-template #editTitleStep>
  Отредактируйте объявление
</ng-template>

<ng-template #checkingLoadingScreen>
  <div class="loading-screen">
    <div class="loading-screen__spinner">
      <mat-spinner diameter="18"></mat-spinner>
    </div>
    <div class="loading-screen__message">Подождите идет проверка кампаний</div>
  </div>
</ng-template>
