export class VkApiMethodParameter {
  /**
   * Название параметра
   */
  public name: string;

  /**
   * Значение параметра
   */
  public value: string;

  /**
   *
   * @param {string} name - Название параметра
   * @param {string} value - Значение параметра
   */
  public constructor(name: string, value: string) {
    this.name = name;
    this.value = value;
  }

  public ToString(): string {
    return `${this.name}=${this.value.toString()}`;
  }
}
