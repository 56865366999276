var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { QueryEntity } from '@datorama/akita';
import { RetargetingGroupsStore } from './retargeting-groups.store';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./retargeting-groups.store";
var RetargetingGroupsQuery = /** @class */ (function (_super) {
    __extends(RetargetingGroupsQuery, _super);
    function RetargetingGroupsQuery(store) {
        var _this = _super.call(this, store) || this;
        _this.store = store;
        _this.retargetingGroups$ = _this.selectAll();
        _this.filteredRetargetingGroups$ = combineLatest(_this.select(function (state) { return state.ui.filter; }), _this.selectAll())
            .pipe(map(function (_a) {
            var filter = _a[0], groups = _a[1];
            return groups.filter(function (x) {
                return x.type === filter;
            });
        }));
        return _this;
    }
    RetargetingGroupsQuery.ngInjectableDef = i0.defineInjectable({ factory: function RetargetingGroupsQuery_Factory() { return new RetargetingGroupsQuery(i0.inject(i1.RetargetingGroupsStore)); }, token: RetargetingGroupsQuery, providedIn: "root" });
    return RetargetingGroupsQuery;
}(QueryEntity));
export { RetargetingGroupsQuery };
