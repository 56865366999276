<ng-container *ngIf="visibility">
  <div class="memory-alert-wrapper">
    <div class="alert alert-success">
      <div class="alert-items">
        <div class="memory-alert-upper-strip">
          <div class="memory-alert-upper-strip__item">
            <div class="memory-content">
            <ng-content></ng-content>
            </div>
          </div>
          <div class="memory-alert-upper-strip__item memory-alert-upper-strip__item_last">
            <div class="memory-alert-close-button">
              <button class="btn btn-sm btn-success-outline" (click)="CloseAlert()">
                Закрыть
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
