<div class="new-crm-import" *ngIf="formGroup">
  <div class="new-crm-import__forms">
    <mat-accordion>
      <cdk-virtual-scroll-viewport
        itemSize="50"
        maxBufferPx="1200"
        minBufferPx="1200"
        class="new-crm-import__forms"
      >
        <mat-expansion-panel *cdkVirtualFor="let form of forms;">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{form.name}}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ng-container *ngIf="GetFormGroup(form.id) as formGroup">
            <app-new-import-crm-form
              [form]="form"
              [sections]="platformSections"
              [formGroup]="formGroup"
              [advancedSettings]="advancedSettingsFields"
            >
            </app-new-import-crm-form>
          </ng-container>
        </mat-expansion-panel>
      </cdk-virtual-scroll-viewport>
    </mat-accordion>
  </div>
</div>
