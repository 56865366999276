export const environment = {
  production: true,
  backendUri: 'https://postmonitor.ru',
  frontEndUrl: 'https://postmonitor.ru',
  apiPrefix: 'apiv2',
  indexedDB: {
    tableName: 'postmonitor',
    tableVersion: 1
  },
  initVkWidget: true,

  ngxMyDatePickerConfig: {
    dayLabels: {
      mo: 'Пн.',
      tu: 'Вт.',
      we: 'Ср.',
      th: 'Чт.',
      fr: 'Пт.',
      sa: 'Сб.',
      su: 'Вс.'
    },
    monthLabels: [
      '',
      'Янв',
      'Фев',
      'Мар',
      'Апр',
      'Май',
      'Июн',
      'Июл',
      'Авг',
      'Сен',
      'Окт',
      'Ноя',
      'Дек'
    ],
    dateFormat: 'dd.mm.yyyy',
    showTodayBtn: true,
    todayBtnTxt: 'Сегодня',
  },
  vkAges: [14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80],
  sentrySettings: {
    dsn: 'https://1b7fea24df604d18ba5b6251ded56675@sentry.io/1407277',
    release: 'postmonitor@1.0.0'
  },
  availableSizes: [
    {
      value: 5,
      label: '5'
    },
    {
      value: 10,
      label: '10'
    },
    {
      value: 25,
      label: '25'
    },
    {
      value: 50,
      label: '50'
    },
    {
      value: 100,
      label: '100'
    }
  ],
  affiliateProgram: {
    affiliateRootLink: 'https://postmonitor.ru/affiliate'
  },
  integrations: {
    originFormKey: 'originPlatformSettings',
    destinationFormKey: 'destinationPlatformSettings'
  }
};
