import {AbstractCopyingConfigurator} from "./abstract-copying-configurator";
import {PostMonitorBackend_V2ModelsAdManagerCopyAdsViewModel} from "../../../api/models";

export class GroupsInFileSegmentationConfigurator extends AbstractCopyingConfigurator {

  constructor(props) {
    super(props)
  }

  generate(): PostMonitorBackend_V2ModelsAdManagerCopyAdsViewModel[] {
    const specifications: PostMonitorBackend_V2ModelsAdManagerCopyAdsViewModel[] = [];
    this.copyingData.groupsFromFileConfiguration.groupsList.forEach(groupId => {
      let spec = this.generateDefeault();
      spec = Object.assign(spec, {
        groupId,
        groupsNot: this.copyingData.groupsFromFileConfiguration.excludeOtherGroups
          ? this.copyingData.groupsFromFileConfiguration.groupsList.filter(x => x !== groupId).join(',')
          : null
      });
      specifications.push(spec);
    });
    return specifications;
  }

}
