var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { map as __map, filter as __filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../api-configuration";
import * as i2 from "@angular/common/http";
var UserTariffPlanService = /** @class */ (function (_super) {
    __extends(UserTariffPlanService, _super);
    function UserTariffPlanService(config, http) {
        return _super.call(this, config, http) || this;
    }
    UserTariffPlanService.prototype.Apiv2UserTariffPlanGetResponse = function () {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/user-tariff-plan", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    UserTariffPlanService.prototype.Apiv2UserTariffPlanGet = function () {
        return this.Apiv2UserTariffPlanGetResponse().pipe(__map(function (_r) { return _r.body; }));
    };
    UserTariffPlanService.Apiv2UserTariffPlanGetPath = '/apiv2/user-tariff-plan';
    UserTariffPlanService.ngInjectableDef = i0.defineInjectable({ factory: function UserTariffPlanService_Factory() { return new UserTariffPlanService(i0.inject(i1.ApiConfiguration), i0.inject(i2.HttpClient)); }, token: UserTariffPlanService, providedIn: "root" });
    return UserTariffPlanService;
}(__BaseService));
export { UserTariffPlanService };
