<app-memory-alert [id]="'automation-accounts-tip'">
  Выберите кабинет, с которым будете работать.
</app-memory-alert>

<ngr-dg-action-bar>
  <div class="fx-row">
    <div class="filter-row">
      <div class="btn-group">
        <button class="btn btn-sm" (click)="ToggleOpened()" [disabled]="accessManager.expirationErrorOccured">
          Активировать кабинеты
        </button>
      </div>
    </div>
    <div class="filter-row" *ngIf="clientsLoading">
      <div class="fx-row">
        <div class="loading-screen__spinner">
          <mat-spinner diameter="18"></mat-spinner>
        </div>
        <div class="loading-screen__message">Подождите, идет загрузка клиентов</div>
      </div>
    </div>
    <div class="fx-divider"></div>
    <div class="actions">
      <div class="fx-row">
        <div class="count-label">
          Выберите количество запией на странице
        </div>
        <mat-form-field  [style.width]="'50px'">
          <mat-select [(ngModel)]="countOfResults">
            <ng-container *ngFor="let size of availableSizes">
              <mat-option [value]="size.value">{{size.label}}</mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <mat-button-toggle-group [(ngModel)]="viewMode">
      <mat-button-toggle value="ALL" color="primary">Все</mat-button-toggle>
      <mat-button-toggle value="ACTIVE" color="primary">Активные</mat-button-toggle>
    </mat-button-toggle-group>
  </div>
</ngr-dg-action-bar>

<ngr-data-grid
  [data]="data"
  [loading]="loading"
  [countOfResults]="countOfResults"
  [pagination]="dgPagination"
  #dg
>
  <ngr-dg-column
    header="Название"
    name="account_name"
    [summaryFunction]="SummaryTitleFunction"
    [pagingSummaryFunction]="PaginSummaryTitleFunction"
    [hiddable]="false"
    filterPlaceholderText="Название кабинета"
  >
    <div *ngrDgContent="let account=row">
      <ng-container *ngIf="!account?.is_blocked && account?.account_type !== 'agency'; else expired">
        <a routerLink="/automation/campaigns"
           [queryParams]="{account_id: account.account_id, client_id: account.client_id}"
           [ngClass]="{'disabled': IsExpired}">{{account.account_name}}</a>
        <span class="spinner spinner-inline" *ngIf="!account.loaded"></span>
      </ng-container>
      <ng-template #expired>
        <span
          [ngClass]="{'disabled': account?.is_blocked || account?.account_type === 'agency'}">{{account.account_name}}</span>
      </ng-template>
    </div>
  </ngr-dg-column>
  <ngr-dg-column
    header="Потрачено"
    name="spent"
    filterType="between"
    [summaryFunction]="SummaryFunction"
    [formatFunction]="CurrencyFunction"
    [hiddable]="false"
    align="left"
  ></ngr-dg-column>
  <ngr-dg-column
    header="CTR"
    name="ctr"
    filterType="between"
    [summaryFunction]="SummaryFunction"
    [formatFunction]="CtrFormatFunction"
    [hiddable]="false"
  ></ngr-dg-column>
  <ngr-dg-column
    header="Переходы"
    name="clicks"
    filterType="between"
    [summaryFunction]="SummaryFunction"
    [formatFunction]="NumberFunction"
    [hiddable]="false"
  ></ngr-dg-column>
  <ngr-dg-column
    header="Показы"
    name="impressions"
    filterType="between"
    [summaryFunction]="SummaryFunction"
    [formatFunction]="NumberFunction"
    [hiddable]="false"
  ></ngr-dg-column>

  <ngr-dg-pagination
    [(currentPage)]="dg.currentPage"
    (currentPageChange)="dg.OnPageChange($event)"
    [countOfPages]="dg.countOfPages"
    #dgPagination
  >
  </ngr-dg-pagination>
</ngr-data-grid>

<clr-modal [(clrModalOpen)]="opened" [clrModalSize]="'md'">
  <div class="modal-title">Активация кабинетов</div>
  <div class="modal-body">
    <ng-container *ngIf="accountsViewModels.length > 0; else emptyCabinets">
      <app-memory-alert [id]="'automation-accounts-activation-tip'">
        Выберите кабинеты, с которыми будете работать
      </app-memory-alert>
      <app-pick-list
        #pickList
        [dataList]="accountsViewModels"
        [maxCount]="countOfMaxSlots"
        [slots]="slots"
        (dataListChanged)="OnSaveCabinetsBined($event)"
        (onClose)="OnClose()"
      ></app-pick-list>
    </ng-container>
    <ng-template #emptyCabinets>
      <div>
        К сожалению у вас нет ни одного кабинета.
        Для того чтобы создать новый кабинет перейдите <a
        href="https://postmonitor.ru/blog/instrukciya-sozdaniya-reklamnogo-kabineta-vkontakte" target="_blank"
        rel="nofollow noopener">по ссылке</a>
      </div>
      <button class="vk-standart-button" (click)="LoadData()">Обновить</button>
    </ng-template>
  </div>
</clr-modal>
