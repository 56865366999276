import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var UonTravelService = /** @class */ (function () {
    function UonTravelService(http) {
        this.http = http;
        this.baseApiUrl = environment.backendUri + "/" + environment.apiPrefix + "/u-on-travel";
    }
    UonTravelService.prototype.Authorize = function (data) {
        console.log(this.baseApiUrl + "/authorize, 'URL'");
        return this.http.post(this.baseApiUrl + "/authorize", data);
    };
    UonTravelService.ngInjectableDef = i0.defineInjectable({ factory: function UonTravelService_Factory() { return new UonTravelService(i0.inject(i1.HttpClient)); }, token: UonTravelService, providedIn: "root" });
    return UonTravelService;
}());
export { UonTravelService };
