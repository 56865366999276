export const emojies = [
  {'emoji': '😉', 'src': 'https://vk.com/images/emoji/D83DDE09'},
  {'emoji': '😆', 'src': 'https://vk.com/images/emoji/D83DDE06'},
  {'emoji': '😜', 'src': 'https://vk.com/images/emoji/D83DDE1C'},
  {'emoji': '😋', 'src': 'https://vk.com/images/emoji/D83DDE0B'},
  {'emoji': '😍', 'src': 'https://vk.com/images/emoji/D83DDE0D'},
  {'emoji': '😎', 'src': 'https://vk.com/images/emoji/D83DDE0E'},
  {'emoji': '😒', 'src': 'https://vk.com/images/emoji/D83DDE12'},
  {'emoji': '😏', 'src': 'https://vk.com/images/emoji/D83DDE0F'},
  {'emoji': '😔', 'src': 'https://vk.com/images/emoji/D83DDE14'},
  {'emoji': '😢', 'src': 'https://vk.com/images/emoji/D83DDE22'},
  {'emoji': '😭', 'src': 'https://vk.com/images/emoji/D83DDE2D'},
  {'emoji': '😩', 'src': 'https://vk.com/images/emoji/D83DDE29'},
  {'emoji': '😨', 'src': 'https://vk.com/images/emoji/D83DDE28'},
  {'emoji': '😐', 'src': 'https://vk.com/images/emoji/D83DDE10'},
  {'emoji': '😌', 'src': 'https://vk.com/images/emoji/D83DDE0C'},
  {'emoji': '😄', 'src': 'https://vk.com/images/emoji/D83DDE04'},
  {'emoji': '😇', 'src': 'https://vk.com/images/emoji/D83DDE07'},
  {'emoji': '😰', 'src': 'https://vk.com/images/emoji/D83DDE30'},
  {'emoji': '😲', 'src': 'https://vk.com/images/emoji/D83DDE32'},
  {'emoji': '😳', 'src': 'https://vk.com/images/emoji/D83DDE33'},
  {'emoji': '😷', 'src': 'https://vk.com/images/emoji/D83DDE37'},
  {'emoji': '😂', 'src': 'https://vk.com/images/emoji/D83DDE02'},
  {'emoji': '❤', 'src': 'https://vk.com/images/emoji/2764'},
  {'emoji': '😚', 'src': 'https://vk.com/images/emoji/D83DDE1A'},
  {'emoji': '😕', 'src': 'https://vk.com/images/emoji/D83DDE15'},
  {'emoji': '😯', 'src': 'https://vk.com/images/emoji/D83DDE2F'},
  {'emoji': '😦', 'src': 'https://vk.com/images/emoji/D83DDE26'},
  {'emoji': '😵', 'src': 'https://vk.com/images/emoji/D83DDE35'},
  {'emoji': '😠', 'src': 'https://vk.com/images/emoji/D83DDE20'},
  {'emoji': '😡', 'src': 'https://vk.com/images/emoji/D83DDE21'},
  {'emoji': '😝', 'src': 'https://vk.com/images/emoji/D83DDE1D'},
  {'emoji': '😴', 'src': 'https://vk.com/images/emoji/D83DDE34'},
  {'emoji': '😘', 'src': 'https://vk.com/images/emoji/D83DDE18'},
  {'emoji': '😟', 'src': 'https://vk.com/images/emoji/D83DDE1F'},
  {'emoji': '😣', 'src': 'https://vk.com/images/emoji/D83DDE23'},
  {'emoji': '😧', 'src': 'https://vk.com/images/emoji/D83DDE27'},
  {'emoji': '😑', 'src': 'https://vk.com/images/emoji/D83DDE11'},
  {'emoji': '😅', 'src': 'https://vk.com/images/emoji/D83DDE05'},
  {'emoji': '😮', 'src': 'https://vk.com/images/emoji/D83DDE2E'},
  {'emoji': '😞', 'src': 'https://vk.com/images/emoji/D83DDE1E'},
  {'emoji': '😙', 'src': 'https://vk.com/images/emoji/D83DDE19'},
  {'emoji': '😓', 'src': 'https://vk.com/images/emoji/D83DDE13'},
  {'emoji': '😁', 'src': 'https://vk.com/images/emoji/D83DDE01'},
  {'emoji': '😱', 'src': 'https://vk.com/images/emoji/D83DDE31'},
  {'emoji': '😈', 'src': 'https://vk.com/images/emoji/D83DDE08'},
  {'emoji': '👿', 'src': 'https://vk.com/images/emoji/D83DDC7F'},
  {'emoji': '👽', 'src': 'https://vk.com/images/emoji/D83DDC7D'},
  {'emoji': '👍', 'src': 'https://vk.com/images/emoji/D83DDC4D'},
  {'emoji': '👎', 'src': 'https://vk.com/images/emoji/D83DDC4E'},
  {'emoji': '☝', 'src': 'https://vk.com/images/emoji/261D'},
  {'emoji': '✌', 'src': 'https://vk.com/images/emoji/270C'},
  {'emoji': '👌', 'src': 'https://vk.com/images/emoji/D83DDC4C'},
  {'emoji': '👏', 'src': 'https://vk.com/images/emoji/D83DDC4F'},
  {'emoji': '👊', 'src': 'https://vk.com/images/emoji/D83DDC4A'},
  {'emoji': '✋', 'src': 'https://vk.com/images/emoji/270B'},
  {'emoji': '🙏', 'src': 'https://vk.com/images/emoji/D83DDE4F'},
  {'emoji': '👃', 'src': 'https://vk.com/images/emoji/D83DDC43'},
  {'emoji': '👆', 'src': 'https://vk.com/images/emoji/D83DDC46'},
  {'emoji': '😬', 'src': 'https://vk.com/images/emoji/D83DDE2C'},
  {'emoji': '😶', 'src': 'https://vk.com/images/emoji/D83DDE36'},
  {'emoji': '😪', 'src': 'https://vk.com/images/emoji/D83DDE2A'},
  {'emoji': '😫', 'src': 'https://vk.com/images/emoji/D83DDE2B'},
  {'emoji': '☺', 'src': 'https://vk.com/images/emoji/263A'},
  {'emoji': '😀', 'src': 'https://vk.com/images/emoji/D83DDE00'},
  {'emoji': '😥', 'src': 'https://vk.com/images/emoji/D83DDE25'},
  {'emoji': '😛', 'src': 'https://vk.com/images/emoji/D83DDE1B'},
  {'emoji': '😖', 'src': 'https://vk.com/images/emoji/D83DDE16'},
  {'emoji': '😤', 'src': 'https://vk.com/images/emoji/D83DDE24'},
  {'emoji': '🐃', 'src': 'https://vk.com/images/emoji/D83DDC03'},
  {'emoji': '🐻', 'src': 'https://vk.com/images/emoji/D83DDC3B'},
  {'emoji': '🐼', 'src': 'https://vk.com/images/emoji/D83DDC3C'},
  {'emoji': '🐅', 'src': 'https://vk.com/images/emoji/D83DDC05'},
  {'emoji': '🐓', 'src': 'https://vk.com/images/emoji/D83DDC13'},
  {'emoji': '🐘', 'src': 'https://vk.com/images/emoji/D83DDC18'},
  {'emoji': '💔', 'src': 'https://vk.com/images/emoji/D83DDC94'},
  {'emoji': '💭', 'src': 'https://vk.com/images/emoji/D83DDCAD'},
  {'emoji': '🐶', 'src': 'https://vk.com/images/emoji/D83DDC36'},
  {'emoji': '🐱', 'src': 'https://vk.com/images/emoji/D83DDC31'},
  {'emoji': '🐷', 'src': 'https://vk.com/images/emoji/D83DDC37'},
  {'emoji': '🐑', 'src': 'https://vk.com/images/emoji/D83DDC11'},
  {'emoji': '⏳', 'src': 'https://vk.com/images/emoji/23F3'},
  {'emoji': '⚾', 'src': 'https://vk.com/images/emoji/26BE'},
  {'emoji': '⛄', 'src': 'https://vk.com/images/emoji/26C4'},
  {'emoji': '☀', 'src': 'https://vk.com/images/emoji/2600'},
  {'emoji': '🌺', 'src': 'https://vk.com/images/emoji/D83CDF3A'},
  {'emoji': '🌻', 'src': 'https://vk.com/images/emoji/D83CDF3B'},
  {'emoji': '🌼', 'src': 'https://vk.com/images/emoji/D83CDF3C'},
  {'emoji': '🌽', 'src': 'https://vk.com/images/emoji/D83CDF3D'},
  {'emoji': '🍋', 'src': 'https://vk.com/images/emoji/D83CDF4B'},
  {'emoji': '🍍', 'src': 'https://vk.com/images/emoji/D83CDF4D'},
  {'emoji': '🍎', 'src': 'https://vk.com/images/emoji/D83CDF4E'},
  {'emoji': '🍏', 'src': 'https://vk.com/images/emoji/D83CDF4F'},
  {'emoji': '🍭', 'src': 'https://vk.com/images/emoji/D83CDF6D'},
  {'emoji': '🌷', 'src': 'https://vk.com/images/emoji/D83CDF37'},
  {'emoji': '🌸', 'src': 'https://vk.com/images/emoji/D83CDF38'},
  {'emoji': '🍆', 'src': 'https://vk.com/images/emoji/D83CDF46'},
  {'emoji': '🍉', 'src': 'https://vk.com/images/emoji/D83CDF49'},
  {'emoji': '🍐', 'src': 'https://vk.com/images/emoji/D83CDF50'},
  {'emoji': '🍑', 'src': 'https://vk.com/images/emoji/D83CDF51'},
  {'emoji': '🍓', 'src': 'https://vk.com/images/emoji/D83CDF53'},
  {'emoji': '🍔', 'src': 'https://vk.com/images/emoji/D83CDF54'},
  {'emoji': '🍕', 'src': 'https://vk.com/images/emoji/D83CDF55'},
  {'emoji': '🍖', 'src': 'https://vk.com/images/emoji/D83CDF56'},
  {'emoji': '🍗', 'src': 'https://vk.com/images/emoji/D83CDF57'},
  {'emoji': '🍩', 'src': 'https://vk.com/images/emoji/D83CDF69'},
  {'emoji': '🎃', 'src': 'https://vk.com/images/emoji/D83CDF83'},
  {'emoji': '🎪', 'src': 'https://vk.com/images/emoji/D83CDFAA'},
  {'emoji': '🎱', 'src': 'https://vk.com/images/emoji/D83CDFB1'},
  {'emoji': '🎲', 'src': 'https://vk.com/images/emoji/D83CDFB2'},
  {'emoji': '🎷', 'src': 'https://vk.com/images/emoji/D83CDFB7'},
  {'emoji': '🎸', 'src': 'https://vk.com/images/emoji/D83CDFB8'},
  {'emoji': '🎾', 'src': 'https://vk.com/images/emoji/D83CDFBE'},
  {'emoji': '🏀', 'src': 'https://vk.com/images/emoji/D83CDFC0'},
  {'emoji': '🏦', 'src': 'https://vk.com/images/emoji/D83CDFE6'},
  {'emoji': '😸', 'src': 'https://vk.com/images/emoji/D83DDE38'},
  {'emoji': '😹', 'src': 'https://vk.com/images/emoji/D83DDE39'},
  {'emoji': '😼', 'src': 'https://vk.com/images/emoji/D83DDE3C'},
  {'emoji': '😽', 'src': 'https://vk.com/images/emoji/D83DDE3D'},
  {'emoji': '😾', 'src': 'https://vk.com/images/emoji/D83DDE3E'},
  {'emoji': '😿', 'src': 'https://vk.com/images/emoji/D83DDE3F'},
  {'emoji': '😻', 'src': 'https://vk.com/images/emoji/D83DDE3B'},
  {'emoji': '🙀', 'src': 'https://vk.com/images/emoji/D83DDE40'},
  {'emoji': '😺', 'src': 'https://vk.com/images/emoji/D83DDE3A'},
  {'emoji': '⏰', 'src': 'https://vk.com/images/emoji/23F0'},
  {'emoji': '☁', 'src': 'https://vk.com/images/emoji/2601'},
  {'emoji': '☎', 'src': 'https://vk.com/images/emoji/260E'},
  {'emoji': '☕', 'src': 'https://vk.com/images/emoji/2615'},
  {'emoji': '♻', 'src': 'https://vk.com/images/emoji/267B'},
  {'emoji': '⚠', 'src': 'https://vk.com/images/emoji/26A0'},
  {'emoji': '⚡', 'src': 'https://vk.com/images/emoji/26A1'},
  {'emoji': '⛔', 'src': 'https://vk.com/images/emoji/26D4'},
  {'emoji': '⛪', 'src': 'https://vk.com/images/emoji/26EA'},
  {'emoji': '⛳', 'src': 'https://vk.com/images/emoji/26F3'},
  {'emoji': '⛵', 'src': 'https://vk.com/images/emoji/26F5'},
  {'emoji': '✂', 'src': 'https://vk.com/images/emoji/2702'},
  {'emoji': '✈', 'src': 'https://vk.com/images/emoji/2708'},
  {'emoji': '✉', 'src': 'https://vk.com/images/emoji/2709'},
  {'emoji': '✊', 'src': 'https://vk.com/images/emoji/270A'},
  {'emoji': '✏', 'src': 'https://vk.com/images/emoji/270F'},
  {'emoji': '✒', 'src': 'https://vk.com/images/emoji/2712'},
  {'emoji': '✨', 'src': 'https://vk.com/images/emoji/2728'},
  {'emoji': '🀄', 'src': 'https://vk.com/images/emoji/D83CDC04'},
  {'emoji': '🃏', 'src': 'https://vk.com/images/emoji/D83CDCCF'},
  {'emoji': '🆘', 'src': 'https://vk.com/images/emoji/D83CDD98'},
  {'emoji': '🌂', 'src': 'https://vk.com/images/emoji/D83CDF02'},
  {'emoji': '🌍', 'src': 'https://vk.com/images/emoji/D83CDF0D'},
  {'emoji': '🌛', 'src': 'https://vk.com/images/emoji/D83CDF1B'},
  {'emoji': '🌝', 'src': 'https://vk.com/images/emoji/D83CDF1D'},
  {'emoji': '🌞', 'src': 'https://vk.com/images/emoji/D83CDF1E'},
  {'emoji': '🌰', 'src': 'https://vk.com/images/emoji/D83CDF30'},
  {'emoji': '🌱', 'src': 'https://vk.com/images/emoji/D83CDF31'},
  {'emoji': '🌲', 'src': 'https://vk.com/images/emoji/D83CDF32'},
  {'emoji': '🌳', 'src': 'https://vk.com/images/emoji/D83CDF33'},
  {'emoji': '🌴', 'src': 'https://vk.com/images/emoji/D83CDF34'},
  {'emoji': '🌵', 'src': 'https://vk.com/images/emoji/D83CDF35'},
  {'emoji': '🌾', 'src': 'https://vk.com/images/emoji/D83CDF3E'},
  {'emoji': '🌿', 'src': 'https://vk.com/images/emoji/D83CDF3F'},
  {'emoji': '🍀', 'src': 'https://vk.com/images/emoji/D83CDF40'},
  {'emoji': '🍁', 'src': 'https://vk.com/images/emoji/D83CDF41'},
  {'emoji': '🍂', 'src': 'https://vk.com/images/emoji/D83CDF42'},
  {'emoji': '🍃', 'src': 'https://vk.com/images/emoji/D83CDF43'},
  {'emoji': '🍄', 'src': 'https://vk.com/images/emoji/D83CDF44'},
  {'emoji': '🍇', 'src': 'https://vk.com/images/emoji/D83CDF47'},
  {'emoji': '🍈', 'src': 'https://vk.com/images/emoji/D83CDF48'},
  {'emoji': '🍚', 'src': 'https://vk.com/images/emoji/D83CDF5A'},
  {'emoji': '🍛', 'src': 'https://vk.com/images/emoji/D83CDF5B'},
  {'emoji': '🍜', 'src': 'https://vk.com/images/emoji/D83CDF5C'},
  {'emoji': '🍝', 'src': 'https://vk.com/images/emoji/D83CDF5D'},
  {'emoji': '🍞', 'src': 'https://vk.com/images/emoji/D83CDF5E'},
  {'emoji': '🍟', 'src': 'https://vk.com/images/emoji/D83CDF5F'},
  {'emoji': '🍠', 'src': 'https://vk.com/images/emoji/D83CDF60'},
  {'emoji': '🍡', 'src': 'https://vk.com/images/emoji/D83CDF61'},
  {'emoji': '🍢', 'src': 'https://vk.com/images/emoji/D83CDF62'},
  {'emoji': '🍣', 'src': 'https://vk.com/images/emoji/D83CDF63'},
  {'emoji': '🍤', 'src': 'https://vk.com/images/emoji/D83CDF64'},
  {'emoji': '🍥', 'src': 'https://vk.com/images/emoji/D83CDF65'},
  {'emoji': '🍦', 'src': 'https://vk.com/images/emoji/D83CDF66'},
  {'emoji': '🍧', 'src': 'https://vk.com/images/emoji/D83CDF67'},
  {'emoji': '🍨', 'src': 'https://vk.com/images/emoji/D83CDF68'},
  {'emoji': '🍪', 'src': 'https://vk.com/images/emoji/D83CDF6A'},
  {'emoji': '🍫', 'src': 'https://vk.com/images/emoji/D83CDF6B'},
  {'emoji': '🍬', 'src': 'https://vk.com/images/emoji/D83CDF6C'},
  {'emoji': '🍮', 'src': 'https://vk.com/images/emoji/D83CDF6E'},
  {'emoji': '🍯', 'src': 'https://vk.com/images/emoji/D83CDF6F'},
  {'emoji': '🍰', 'src': 'https://vk.com/images/emoji/D83CDF70'},
  {'emoji': '🍱', 'src': 'https://vk.com/images/emoji/D83CDF71'},
  {'emoji': '🍲', 'src': 'https://vk.com/images/emoji/D83CDF72'},
  {'emoji': '🍳', 'src': 'https://vk.com/images/emoji/D83CDF73'},
  {'emoji': '🍴', 'src': 'https://vk.com/images/emoji/D83CDF74'},
  {'emoji': '🍵', 'src': 'https://vk.com/images/emoji/D83CDF75'},
  {'emoji': '🍶', 'src': 'https://vk.com/images/emoji/D83CDF76'},
  {'emoji': '🍹', 'src': 'https://vk.com/images/emoji/D83CDF79'},
  {'emoji': '🍼', 'src': 'https://vk.com/images/emoji/D83CDF7C'},
  {'emoji': '🎀', 'src': 'https://vk.com/images/emoji/D83CDF80'},
  {'emoji': '🎈', 'src': 'https://vk.com/images/emoji/D83CDF88'},
  {'emoji': '🎉', 'src': 'https://vk.com/images/emoji/D83CDF89'},
  {'emoji': '🎊', 'src': 'https://vk.com/images/emoji/D83CDF8A'},
  {'emoji': '🎋', 'src': 'https://vk.com/images/emoji/D83CDF8B'},
  {'emoji': '🎌', 'src': 'https://vk.com/images/emoji/D83CDF8C'},
  {'emoji': '🎍', 'src': 'https://vk.com/images/emoji/D83CDF8D'},
  {'emoji': '🎎', 'src': 'https://vk.com/images/emoji/D83CDF8E'},
  {'emoji': '🎏', 'src': 'https://vk.com/images/emoji/D83CDF8F'},
  {'emoji': '🎐', 'src': 'https://vk.com/images/emoji/D83CDF90'},
  {'emoji': '🎒', 'src': 'https://vk.com/images/emoji/D83CDF92'},
  {'emoji': '🎓', 'src': 'https://vk.com/images/emoji/D83CDF93'},
  {'emoji': '🎣', 'src': 'https://vk.com/images/emoji/D83CDFA3'},
  {'emoji': '🎤', 'src': 'https://vk.com/images/emoji/D83CDFA4'},
  {'emoji': '🎧', 'src': 'https://vk.com/images/emoji/D83CDFA7'},
  {'emoji': '🎨', 'src': 'https://vk.com/images/emoji/D83CDFA8'},
  {'emoji': '🎩', 'src': 'https://vk.com/images/emoji/D83CDFA9'},
  {'emoji': '🎫', 'src': 'https://vk.com/images/emoji/D83CDFAB'},
  {'emoji': '🎬', 'src': 'https://vk.com/images/emoji/D83CDFAC'},
  {'emoji': '🎭', 'src': 'https://vk.com/images/emoji/D83CDFAD'},
  {'emoji': '🎯', 'src': 'https://vk.com/images/emoji/D83CDFAF'},
  {'emoji': '🎰', 'src': 'https://vk.com/images/emoji/D83CDFB0'},
  {'emoji': '🎳', 'src': 'https://vk.com/images/emoji/D83CDFB3'},
  {'emoji': '🎴', 'src': 'https://vk.com/images/emoji/D83CDFB4'},
  {'emoji': '🎹', 'src': 'https://vk.com/images/emoji/D83CDFB9'},
  {'emoji': '🎺', 'src': 'https://vk.com/images/emoji/D83CDFBA'},
  {'emoji': '🎻', 'src': 'https://vk.com/images/emoji/D83CDFBB'},
  {'emoji': '🎽', 'src': 'https://vk.com/images/emoji/D83CDFBD'},
  {'emoji': '🎿', 'src': 'https://vk.com/images/emoji/D83CDFBF'},
  {'emoji': '🏂', 'src': 'https://vk.com/images/emoji/D83CDFC2'},
  {'emoji': '🏃', 'src': 'https://vk.com/images/emoji/D83CDFC3'},
  {'emoji': '🏄', 'src': 'https://vk.com/images/emoji/D83CDFC4'},
  {'emoji': '🏇', 'src': 'https://vk.com/images/emoji/D83CDFC7'},
  {'emoji': '🏈', 'src': 'https://vk.com/images/emoji/D83CDFC8'},
  {'emoji': '🏉', 'src': 'https://vk.com/images/emoji/D83CDFC9'},
  {'emoji': '🏊', 'src': 'https://vk.com/images/emoji/D83CDFCA'},
  {'emoji': '🐀', 'src': 'https://vk.com/images/emoji/D83DDC00'},
  {'emoji': '🐁', 'src': 'https://vk.com/images/emoji/D83DDC01'},
  {'emoji': '🐂', 'src': 'https://vk.com/images/emoji/D83DDC02'},
  {'emoji': '🐄', 'src': 'https://vk.com/images/emoji/D83DDC04'},
  {'emoji': '🐆', 'src': 'https://vk.com/images/emoji/D83DDC06'},
  {'emoji': '🐇', 'src': 'https://vk.com/images/emoji/D83DDC07'},
  {'emoji': '🐈', 'src': 'https://vk.com/images/emoji/D83DDC08'},
  {'emoji': '🐉', 'src': 'https://vk.com/images/emoji/D83DDC09'},
  {'emoji': '🐊', 'src': 'https://vk.com/images/emoji/D83DDC0A'},
  {'emoji': '🐋', 'src': 'https://vk.com/images/emoji/D83DDC0B'},
  {'emoji': '🐌', 'src': 'https://vk.com/images/emoji/D83DDC0C'},
  {'emoji': '🐍', 'src': 'https://vk.com/images/emoji/D83DDC0D'},
  {'emoji': '🐐', 'src': 'https://vk.com/images/emoji/D83DDC10'},
  {'emoji': '🐒', 'src': 'https://vk.com/images/emoji/D83DDC12'},
  {'emoji': '🐔', 'src': 'https://vk.com/images/emoji/D83DDC14'},
  {'emoji': '🐕', 'src': 'https://vk.com/images/emoji/D83DDC15'},
  {'emoji': '🐖', 'src': 'https://vk.com/images/emoji/D83DDC16'},
  {'emoji': '🐗', 'src': 'https://vk.com/images/emoji/D83DDC17'},
  {'emoji': '🐙', 'src': 'https://vk.com/images/emoji/D83DDC19'},
  {'emoji': '🐚', 'src': 'https://vk.com/images/emoji/D83DDC1A'},
  {'emoji': '🐛', 'src': 'https://vk.com/images/emoji/D83DDC1B'},
  {'emoji': '🐝', 'src': 'https://vk.com/images/emoji/D83DDC1D'},
  {'emoji': '🐞', 'src': 'https://vk.com/images/emoji/D83DDC1E'},
  {'emoji': '🐟', 'src': 'https://vk.com/images/emoji/D83DDC1F'},
  {'emoji': '🐠', 'src': 'https://vk.com/images/emoji/D83DDC20'},
  {'emoji': '🐡', 'src': 'https://vk.com/images/emoji/D83DDC21'},
  {'emoji': '🐢', 'src': 'https://vk.com/images/emoji/D83DDC22'},
  {'emoji': '🐣', 'src': 'https://vk.com/images/emoji/D83DDC23'},
  {'emoji': '🐤', 'src': 'https://vk.com/images/emoji/D83DDC24'},
  {'emoji': '🐥', 'src': 'https://vk.com/images/emoji/D83DDC25'},
  {'emoji': '🐦', 'src': 'https://vk.com/images/emoji/D83DDC26'},
  {'emoji': '🐧', 'src': 'https://vk.com/images/emoji/D83DDC27'},
  {'emoji': '🐩', 'src': 'https://vk.com/images/emoji/D83DDC29'},
  {'emoji': '🐪', 'src': 'https://vk.com/images/emoji/D83DDC2A'},
  {'emoji': '🐬', 'src': 'https://vk.com/images/emoji/D83DDC2C'},
  {'emoji': '🐭', 'src': 'https://vk.com/images/emoji/D83DDC2D'},
  {'emoji': '🐯', 'src': 'https://vk.com/images/emoji/D83DDC2F'},
  {'emoji': '🐰', 'src': 'https://vk.com/images/emoji/D83DDC30'},
  {'emoji': '🐲', 'src': 'https://vk.com/images/emoji/D83DDC32'},
  {'emoji': '🐳', 'src': 'https://vk.com/images/emoji/D83DDC33'},
  {'emoji': '🐴', 'src': 'https://vk.com/images/emoji/D83DDC34'},
  {'emoji': '🐵', 'src': 'https://vk.com/images/emoji/D83DDC35'},
  {'emoji': '🐸', 'src': 'https://vk.com/images/emoji/D83DDC38'},
  {'emoji': '🐹', 'src': 'https://vk.com/images/emoji/D83DDC39'},
  {'emoji': '🐺', 'src': 'https://vk.com/images/emoji/D83DDC3A'},
  {'emoji': '🐽', 'src': 'https://vk.com/images/emoji/D83DDC3D'},
  {'emoji': '🐾', 'src': 'https://vk.com/images/emoji/D83DDC3E'},
  {'emoji': '👀', 'src': 'https://vk.com/images/emoji/D83DDC40'},
  {'emoji': '👄', 'src': 'https://vk.com/images/emoji/D83DDC44'},
  {'emoji': '👅', 'src': 'https://vk.com/images/emoji/D83DDC45'},
  {'emoji': '👋', 'src': 'https://vk.com/images/emoji/D83DDC4B'},
  {'emoji': '👐', 'src': 'https://vk.com/images/emoji/D83DDC50'},
  {'emoji': '👑', 'src': 'https://vk.com/images/emoji/D83DDC51'},
  {'emoji': '👒', 'src': 'https://vk.com/images/emoji/D83DDC52'},
  {'emoji': '👓', 'src': 'https://vk.com/images/emoji/D83DDC53'},
  {'emoji': '👔', 'src': 'https://vk.com/images/emoji/D83DDC54'},
  {'emoji': '👕', 'src': 'https://vk.com/images/emoji/D83DDC55'},
  {'emoji': '👖', 'src': 'https://vk.com/images/emoji/D83DDC56'},
  {'emoji': '👗', 'src': 'https://vk.com/images/emoji/D83DDC57'},
  {'emoji': '👘', 'src': 'https://vk.com/images/emoji/D83DDC58'},
  {'emoji': '👙', 'src': 'https://vk.com/images/emoji/D83DDC59'},
  {'emoji': '👚', 'src': 'https://vk.com/images/emoji/D83DDC5A'},
  {'emoji': '👛', 'src': 'https://vk.com/images/emoji/D83DDC5B'},
  {'emoji': '👜', 'src': 'https://vk.com/images/emoji/D83DDC5C'},
  {'emoji': '👝', 'src': 'https://vk.com/images/emoji/D83DDC5D'},
  {'emoji': '👞', 'src': 'https://vk.com/images/emoji/D83DDC5E'},
  {'emoji': '👟', 'src': 'https://vk.com/images/emoji/D83DDC5F'},
  {'emoji': '👠', 'src': 'https://vk.com/images/emoji/D83DDC60'},
  {'emoji': '👡', 'src': 'https://vk.com/images/emoji/D83DDC61'},
  {'emoji': '👢', 'src': 'https://vk.com/images/emoji/D83DDC62'},
  {'emoji': '👣', 'src': 'https://vk.com/images/emoji/D83DDC63'},
  {'emoji': '👦', 'src': 'https://vk.com/images/emoji/D83DDC66'},
  {'emoji': '👧', 'src': 'https://vk.com/images/emoji/D83DDC67'},
  {'emoji': '👨', 'src': 'https://vk.com/images/emoji/D83DDC68'},
  {'emoji': '👩', 'src': 'https://vk.com/images/emoji/D83DDC69'},
  {'emoji': '👪', 'src': 'https://vk.com/images/emoji/D83DDC6A'},
  {'emoji': '👫', 'src': 'https://vk.com/images/emoji/D83DDC6B'},
  {'emoji': '👬', 'src': 'https://vk.com/images/emoji/D83DDC6C'},
  {'emoji': '👭', 'src': 'https://vk.com/images/emoji/D83DDC6D'},
  {'emoji': '👮', 'src': 'https://vk.com/images/emoji/D83DDC6E'},
  {'emoji': '👯', 'src': 'https://vk.com/images/emoji/D83DDC6F'},
  {'emoji': '👰', 'src': 'https://vk.com/images/emoji/D83DDC70'},
  {'emoji': '👱', 'src': 'https://vk.com/images/emoji/D83DDC71'},
  {'emoji': '👲', 'src': 'https://vk.com/images/emoji/D83DDC72'},
  {'emoji': '👳', 'src': 'https://vk.com/images/emoji/D83DDC73'},
  {'emoji': '👴', 'src': 'https://vk.com/images/emoji/D83DDC74'},
  {'emoji': '👵', 'src': 'https://vk.com/images/emoji/D83DDC75'},
  {'emoji': '👶', 'src': 'https://vk.com/images/emoji/D83DDC76'},
  {'emoji': '👷', 'src': 'https://vk.com/images/emoji/D83DDC77'},
  {'emoji': '👸', 'src': 'https://vk.com/images/emoji/D83DDC78'},
  {'emoji': '👻', 'src': 'https://vk.com/images/emoji/D83DDC7B'},
  {'emoji': '👼', 'src': 'https://vk.com/images/emoji/D83DDC7C'},
  {'emoji': '👾', 'src': 'https://vk.com/images/emoji/D83DDC7E'},
  {'emoji': '💀', 'src': 'https://vk.com/images/emoji/D83DDC80'},
  {'emoji': '💁', 'src': 'https://vk.com/images/emoji/D83DDC81'},
  {'emoji': '💂', 'src': 'https://vk.com/images/emoji/D83DDC82'},
  {'emoji': '💃', 'src': 'https://vk.com/images/emoji/D83DDC83'},
  {'emoji': '💄', 'src': 'https://vk.com/images/emoji/D83DDC84'},
  {'emoji': '💅', 'src': 'https://vk.com/images/emoji/D83DDC85'},
  {'emoji': '💆', 'src': 'https://vk.com/images/emoji/D83DDC86'},
  {'emoji': '💇', 'src': 'https://vk.com/images/emoji/D83DDC87'},
  {'emoji': '💈', 'src': 'https://vk.com/images/emoji/D83DDC88'},
  {'emoji': '💉', 'src': 'https://vk.com/images/emoji/D83DDC89'},
  {'emoji': '💊', 'src': 'https://vk.com/images/emoji/D83DDC8A'},
  {'emoji': '💌', 'src': 'https://vk.com/images/emoji/D83DDC8C'},
  {'emoji': '💍', 'src': 'https://vk.com/images/emoji/D83DDC8D'},
  {'emoji': '💎', 'src': 'https://vk.com/images/emoji/D83DDC8E'},
  {'emoji': '💏', 'src': 'https://vk.com/images/emoji/D83DDC8F'},
  {'emoji': '💐', 'src': 'https://vk.com/images/emoji/D83DDC90'},
  {'emoji': '💑', 'src': 'https://vk.com/images/emoji/D83DDC91'},
  {'emoji': '💒', 'src': 'https://vk.com/images/emoji/D83DDC92'},
  {'emoji': '💓', 'src': 'https://vk.com/images/emoji/D83DDC93'},
  {'emoji': '💕', 'src': 'https://vk.com/images/emoji/D83DDC95'},
  {'emoji': '💖', 'src': 'https://vk.com/images/emoji/D83DDC96'},
  {'emoji': '💗', 'src': 'https://vk.com/images/emoji/D83DDC97'},
  {'emoji': '💘', 'src': 'https://vk.com/images/emoji/D83DDC98'},
  {'emoji': '💙', 'src': 'https://vk.com/images/emoji/D83DDC99'},
  {'emoji': '💚', 'src': 'https://vk.com/images/emoji/D83DDC9A'},
  {'emoji': '💛', 'src': 'https://vk.com/images/emoji/D83DDC9B'},
  {'emoji': '💜', 'src': 'https://vk.com/images/emoji/D83DDC9C'},
  {'emoji': '💝', 'src': 'https://vk.com/images/emoji/D83DDC9D'},
  {'emoji': '💞', 'src': 'https://vk.com/images/emoji/D83DDC9E'},
  {'emoji': '💟', 'src': 'https://vk.com/images/emoji/D83DDC9F'},
  {'emoji': '💡', 'src': 'https://vk.com/images/emoji/D83DDCA1'},
  {'emoji': '💣', 'src': 'https://vk.com/images/emoji/D83DDCA3'},
  {'emoji': '💥', 'src': 'https://vk.com/images/emoji/D83DDCA5'},
  {'emoji': '💧', 'src': 'https://vk.com/images/emoji/D83DDCA7'},
  {'emoji': '💨', 'src': 'https://vk.com/images/emoji/D83DDCA8'},
  {'emoji': '💬', 'src': 'https://vk.com/images/emoji/D83DDCAC'},
  {'emoji': '💰', 'src': 'https://vk.com/images/emoji/D83DDCB0'},
  {'emoji': '💳', 'src': 'https://vk.com/images/emoji/D83DDCB3'},
  {'emoji': '💴', 'src': 'https://vk.com/images/emoji/D83DDCB4'},
  {'emoji': '💵', 'src': 'https://vk.com/images/emoji/D83DDCB5'},
  {'emoji': '💶', 'src': 'https://vk.com/images/emoji/D83DDCB6'},
  {'emoji': '💷', 'src': 'https://vk.com/images/emoji/D83DDCB7'},
  {'emoji': '💸', 'src': 'https://vk.com/images/emoji/D83DDCB8'},
  {'emoji': '💺', 'src': 'https://vk.com/images/emoji/D83DDCBA'},
  {'emoji': '💻', 'src': 'https://vk.com/images/emoji/D83DDCBB'},
  {'emoji': '💼', 'src': 'https://vk.com/images/emoji/D83DDCBC'},
  {'emoji': '💽', 'src': 'https://vk.com/images/emoji/D83DDCBD'},
  {'emoji': '💾', 'src': 'https://vk.com/images/emoji/D83DDCBE'},
  {'emoji': '💿', 'src': 'https://vk.com/images/emoji/D83DDCBF'},
  {'emoji': '📄', 'src': 'https://vk.com/images/emoji/D83DDCC4'},
  {'emoji': '📅', 'src': 'https://vk.com/images/emoji/D83DDCC5'},
  {'emoji': '📇', 'src': 'https://vk.com/images/emoji/D83DDCC7'},
  {'emoji': '📈', 'src': 'https://vk.com/images/emoji/D83DDCC8'},
  {'emoji': '📉', 'src': 'https://vk.com/images/emoji/D83DDCC9'},
  {'emoji': '📊', 'src': 'https://vk.com/images/emoji/D83DDCCA'},
  {'emoji': '📋', 'src': 'https://vk.com/images/emoji/D83DDCCB'},
  {'emoji': '📌', 'src': 'https://vk.com/images/emoji/D83DDCCC'},
  {'emoji': '📍', 'src': 'https://vk.com/images/emoji/D83DDCCD'},
  {'emoji': '📎', 'src': 'https://vk.com/images/emoji/D83DDCCE'},
  {'emoji': '📐', 'src': 'https://vk.com/images/emoji/D83DDCD0'},
  {'emoji': '📑', 'src': 'https://vk.com/images/emoji/D83DDCD1'},
  {'emoji': '📒', 'src': 'https://vk.com/images/emoji/D83DDCD2'},
  {'emoji': '📓', 'src': 'https://vk.com/images/emoji/D83DDCD3'},
  {'emoji': '📔', 'src': 'https://vk.com/images/emoji/D83DDCD4'},
  {'emoji': '📕', 'src': 'https://vk.com/images/emoji/D83DDCD5'},
  {'emoji': '📖', 'src': 'https://vk.com/images/emoji/D83DDCD6'},
  {'emoji': '📗', 'src': 'https://vk.com/images/emoji/D83DDCD7'},
  {'emoji': '📘', 'src': 'https://vk.com/images/emoji/D83DDCD8'},
  {'emoji': '📙', 'src': 'https://vk.com/images/emoji/D83DDCD9'},
  {'emoji': '📚', 'src': 'https://vk.com/images/emoji/D83DDCDA'},
  {'emoji': '📜', 'src': 'https://vk.com/images/emoji/D83DDCDC'},
  {'emoji': '📝', 'src': 'https://vk.com/images/emoji/D83DDCDD'},
  {'emoji': '📟', 'src': 'https://vk.com/images/emoji/D83DDCDF'},
  {'emoji': '📠', 'src': 'https://vk.com/images/emoji/D83DDCE0'},
  {'emoji': '📡', 'src': 'https://vk.com/images/emoji/D83DDCE1'},
  {'emoji': '📢', 'src': 'https://vk.com/images/emoji/D83DDCE2'},
  {'emoji': '📦', 'src': 'https://vk.com/images/emoji/D83DDCE6'},
  {'emoji': '📭', 'src': 'https://vk.com/images/emoji/D83DDCED'},
  {'emoji': '📮', 'src': 'https://vk.com/images/emoji/D83DDCEE'},
  {'emoji': '📯', 'src': 'https://vk.com/images/emoji/D83DDCEF'},
  {'emoji': '📰', 'src': 'https://vk.com/images/emoji/D83DDCF0'},
  {'emoji': '📱', 'src': 'https://vk.com/images/emoji/D83DDCF1'},
  {'emoji': '📷', 'src': 'https://vk.com/images/emoji/D83DDCF7'},
  {'emoji': '📹', 'src': 'https://vk.com/images/emoji/D83DDCF9'},
  {'emoji': '📺', 'src': 'https://vk.com/images/emoji/D83DDCFA'},
  {'emoji': '📻', 'src': 'https://vk.com/images/emoji/D83DDCFB'},
  {'emoji': '📼', 'src': 'https://vk.com/images/emoji/D83DDCFC'},
  {'emoji': '🔆', 'src': 'https://vk.com/images/emoji/D83DDD06'},
  {'emoji': '🔎', 'src': 'https://vk.com/images/emoji/D83DDD0E'},
  {'emoji': '🔑', 'src': 'https://vk.com/images/emoji/D83DDD11'},
  {'emoji': '🔔', 'src': 'https://vk.com/images/emoji/D83DDD14'},
  {'emoji': '🔖', 'src': 'https://vk.com/images/emoji/D83DDD16'},
  {'emoji': '🔥', 'src': 'https://vk.com/images/emoji/D83DDD25'},
  {'emoji': '🔦', 'src': 'https://vk.com/images/emoji/D83DDD26'},
  {'emoji': '🔧', 'src': 'https://vk.com/images/emoji/D83DDD27'},
  {'emoji': '🔨', 'src': 'https://vk.com/images/emoji/D83DDD28'},
  {'emoji': '🔩', 'src': 'https://vk.com/images/emoji/D83DDD29'},
  {'emoji': '🔪', 'src': 'https://vk.com/images/emoji/D83DDD2A'},
  {'emoji': '🔫', 'src': 'https://vk.com/images/emoji/D83DDD2B'},
  {'emoji': '🔬', 'src': 'https://vk.com/images/emoji/D83DDD2C'},
  {'emoji': '🔭', 'src': 'https://vk.com/images/emoji/D83DDD2D'},
  {'emoji': '🔮', 'src': 'https://vk.com/images/emoji/D83DDD2E'},
  {'emoji': '🔱', 'src': 'https://vk.com/images/emoji/D83DDD31'},
  {'emoji': '🗿', 'src': 'https://vk.com/images/emoji/D83DDDFF'},
  {'emoji': '🙅', 'src': 'https://vk.com/images/emoji/D83DDE45'},
  {'emoji': '🙆', 'src': 'https://vk.com/images/emoji/D83DDE46'},
  {'emoji': '🙇', 'src': 'https://vk.com/images/emoji/D83DDE47'},
  {'emoji': '🙈', 'src': 'https://vk.com/images/emoji/D83DDE48'},
  {'emoji': '🙉', 'src': 'https://vk.com/images/emoji/D83DDE49'},
  {'emoji': '🙊', 'src': 'https://vk.com/images/emoji/D83DDE4A'},
  {'emoji': '🙋', 'src': 'https://vk.com/images/emoji/D83DDE4B'},
  {'emoji': '🙌', 'src': 'https://vk.com/images/emoji/D83DDE4C'},
  {'emoji': '🙎', 'src': 'https://vk.com/images/emoji/D83DDE4E'},
  {'emoji': '🚀', 'src': 'https://vk.com/images/emoji/D83DDE80'},
  {'emoji': '🚁', 'src': 'https://vk.com/images/emoji/D83DDE81'},
  {'emoji': '🚂', 'src': 'https://vk.com/images/emoji/D83DDE82'},
  {'emoji': '🚃', 'src': 'https://vk.com/images/emoji/D83DDE83'},
  {'emoji': '🚄', 'src': 'https://vk.com/images/emoji/D83DDE84'},
  {'emoji': '🚅', 'src': 'https://vk.com/images/emoji/D83DDE85'},
  {'emoji': '🚆', 'src': 'https://vk.com/images/emoji/D83DDE86'},
  {'emoji': '🚇', 'src': 'https://vk.com/images/emoji/D83DDE87'},
  {'emoji': '🚈', 'src': 'https://vk.com/images/emoji/D83DDE88'},
  {'emoji': '🚊', 'src': 'https://vk.com/images/emoji/D83DDE8A'},
  {'emoji': '🚌', 'src': 'https://vk.com/images/emoji/D83DDE8C'},
  {'emoji': '🚍', 'src': 'https://vk.com/images/emoji/D83DDE8D'},
  {'emoji': '🚎', 'src': 'https://vk.com/images/emoji/D83DDE8E'},
  {'emoji': '🚏', 'src': 'https://vk.com/images/emoji/D83DDE8F'},
  {'emoji': '🚐', 'src': 'https://vk.com/images/emoji/D83DDE90'},
  {'emoji': '🚑', 'src': 'https://vk.com/images/emoji/D83DDE91'},
  {'emoji': '🚒', 'src': 'https://vk.com/images/emoji/D83DDE92'},
  {'emoji': '🚓', 'src': 'https://vk.com/images/emoji/D83DDE93'},
  {'emoji': '🚔', 'src': 'https://vk.com/images/emoji/D83DDE94'},
  {'emoji': '🚕', 'src': 'https://vk.com/images/emoji/D83DDE95'},
  {'emoji': '🚖', 'src': 'https://vk.com/images/emoji/D83DDE96'},
  {'emoji': '🚗', 'src': 'https://vk.com/images/emoji/D83DDE97'},
  {'emoji': '🚘', 'src': 'https://vk.com/images/emoji/D83DDE98'},
  {'emoji': '🚙', 'src': 'https://vk.com/images/emoji/D83DDE99'},
  {'emoji': '🚚', 'src': 'https://vk.com/images/emoji/D83DDE9A'},
  {'emoji': '🚛', 'src': 'https://vk.com/images/emoji/D83DDE9B'},
  {'emoji': '🚜', 'src': 'https://vk.com/images/emoji/D83DDE9C'},
  {'emoji': '🚝', 'src': 'https://vk.com/images/emoji/D83DDE9D'},
  {'emoji': '🚞', 'src': 'https://vk.com/images/emoji/D83DDE9E'},
  {'emoji': '🚟', 'src': 'https://vk.com/images/emoji/D83DDE9F'},
  {'emoji': '🚠', 'src': 'https://vk.com/images/emoji/D83DDEA0'},
  {'emoji': '🚡', 'src': 'https://vk.com/images/emoji/D83DDEA1'},
  {'emoji': '🚣', 'src': 'https://vk.com/images/emoji/D83DDEA3'},
  {'emoji': '🚤', 'src': 'https://vk.com/images/emoji/D83DDEA4'},
  {'emoji': '🚧', 'src': 'https://vk.com/images/emoji/D83DDEA7'},
  {'emoji': '🚨', 'src': 'https://vk.com/images/emoji/D83DDEA8'},
  {'emoji': '🚪', 'src': 'https://vk.com/images/emoji/D83DDEAA'},
  {'emoji': '🚬', 'src': 'https://vk.com/images/emoji/D83DDEAC'},
  {'emoji': '🚴', 'src': 'https://vk.com/images/emoji/D83DDEB4'},
  {'emoji': '🚵', 'src': 'https://vk.com/images/emoji/D83DDEB5'},
  {'emoji': '🚶', 'src': 'https://vk.com/images/emoji/D83DDEB6'},
  {'emoji': '🚽', 'src': 'https://vk.com/images/emoji/D83DDEBD'},
  {'emoji': '🚿', 'src': 'https://vk.com/images/emoji/D83DDEBF'},
  {'emoji': '🛀', 'src': 'https://vk.com/images/emoji/D83DDEC0'},
  {'emoji': '🇨🇳', 'src': 'https://vk.com/images/emoji/D83CDDE8D83CDDF3'},
  {'emoji': '🇩🇪', 'src': 'https://vk.com/images/emoji/D83CDDE9D83CDDEA'},
  {'emoji': '🇪🇸', 'src': 'https://vk.com/images/emoji/D83CDDEAD83CDDF8'},
  {'emoji': '🇫🇷', 'src': 'https://vk.com/images/emoji/D83CDDEBD83CDDF7'},
  {'emoji': '🇬🇧', 'src': 'https://vk.com/images/emoji/D83CDDECD83CDDE7'},
  {'emoji': '🇮🇹', 'src': 'https://vk.com/images/emoji/D83CDDEED83CDDF9'},
  {'emoji': '🇯🇵', 'src': 'https://vk.com/images/emoji/D83CDDEFD83CDDF5'},
  {'emoji': '🇰🇷', 'src': 'https://vk.com/images/emoji/D83CDDF0D83CDDF7'},
  {'emoji': '🇷🇺', 'src': 'https://vk.com/images/emoji/D83CDDF7D83CDDFA'},
  {'emoji': '🇺🇸', 'src': 'https://vk.com/images/emoji/D83CDDFAD83CDDF8'},
  {'emoji': '🇺🇦', 'src': 'https://vk.com/images/emoji/D83CDDFAD83CDDE6'},
  {'emoji': '‼', 'src': 'https://vk.com/images/emoji/203C'},
  {'emoji': '⁉', 'src': 'https://vk.com/images/emoji/2049'},
  {'emoji': '↔', 'src': 'https://vk.com/images/emoji/2194'},
  {'emoji': '↕', 'src': 'https://vk.com/images/emoji/2195'},
  {'emoji': '↖', 'src': 'https://vk.com/images/emoji/2196'},
  {'emoji': '↗', 'src': 'https://vk.com/images/emoji/2197'},
  {'emoji': '↘', 'src': 'https://vk.com/images/emoji/2198'},
  {'emoji': '↙', 'src': 'https://vk.com/images/emoji/2199'},
  {'emoji': '↩', 'src': 'https://vk.com/images/emoji/21A9'},
  {'emoji': '↪', 'src': 'https://vk.com/images/emoji/21AA'},
  {'emoji': '⌚', 'src': 'https://vk.com/images/emoji/231A'},
  {'emoji': '⌛', 'src': 'https://vk.com/images/emoji/231B'},
  {'emoji': '⏩', 'src': 'https://vk.com/images/emoji/23E9'},
  {'emoji': '⏪', 'src': 'https://vk.com/images/emoji/23EA'},
  {'emoji': '⏫', 'src': 'https://vk.com/images/emoji/23EB'},
  {'emoji': '⏬', 'src': 'https://vk.com/images/emoji/23EC'},
  {'emoji': 'Ⓜ', 'src': 'https://vk.com/images/emoji/24C2'},
  {'emoji': '▪', 'src': 'https://vk.com/images/emoji/25AA'},
  {'emoji': '▫', 'src': 'https://vk.com/images/emoji/25AB'},
  {'emoji': '▶', 'src': 'https://vk.com/images/emoji/25B6'},
  {'emoji': '◀', 'src': 'https://vk.com/images/emoji/25C0'},
  {'emoji': '◻', 'src': 'https://vk.com/images/emoji/25FB'},
  {'emoji': '◼', 'src': 'https://vk.com/images/emoji/25FC'},
  {'emoji': '◽', 'src': 'https://vk.com/images/emoji/25FD'},
  {'emoji': '◾', 'src': 'https://vk.com/images/emoji/25FE'},
  {'emoji': '☑', 'src': 'https://vk.com/images/emoji/2611'},
  {'emoji': '☔', 'src': 'https://vk.com/images/emoji/2614'},
  {'emoji': '♈', 'src': 'https://vk.com/images/emoji/2648'},
  {'emoji': '♉', 'src': 'https://vk.com/images/emoji/2649'},
  {'emoji': '♊', 'src': 'https://vk.com/images/emoji/264A'},
  {'emoji': '♋', 'src': 'https://vk.com/images/emoji/264B'},
  {'emoji': '♌', 'src': 'https://vk.com/images/emoji/264C'},
  {'emoji': '♍', 'src': 'https://vk.com/images/emoji/264D'},
  {'emoji': '♎', 'src': 'https://vk.com/images/emoji/264E'},
  {'emoji': '♏', 'src': 'https://vk.com/images/emoji/264F'},
  {'emoji': '♐', 'src': 'https://vk.com/images/emoji/2650'},
  {'emoji': '♑', 'src': 'https://vk.com/images/emoji/2651'},
  {'emoji': '♒', 'src': 'https://vk.com/images/emoji/2652'},
  {'emoji': '♓', 'src': 'https://vk.com/images/emoji/2653'},
  {'emoji': '♠', 'src': 'https://vk.com/images/emoji/2660'},
  {'emoji': '♣', 'src': 'https://vk.com/images/emoji/2663'},
  {'emoji': '♥', 'src': 'https://vk.com/images/emoji/2665'},
  {'emoji': '♦', 'src': 'https://vk.com/images/emoji/2666'},
  {'emoji': '♨', 'src': 'https://vk.com/images/emoji/2668'},
  {'emoji': '♿', 'src': 'https://vk.com/images/emoji/267F'},
  {'emoji': '⚓', 'src': 'https://vk.com/images/emoji/2693'},
  {'emoji': '⚪', 'src': 'https://vk.com/images/emoji/26AA'},
  {'emoji': '⚫', 'src': 'https://vk.com/images/emoji/26AB'},
  {'emoji': '⛎', 'src': 'https://vk.com/images/emoji/26CE'},
  {'emoji': '⛲', 'src': 'https://vk.com/images/emoji/26F2'},
  {'emoji': '⛺', 'src': 'https://vk.com/images/emoji/26FA'},
  {'emoji': '✅', 'src': 'https://vk.com/images/emoji/2705'},
  {'emoji': '✔', 'src': 'https://vk.com/images/emoji/2714'},
  {'emoji': '✖', 'src': 'https://vk.com/images/emoji/2716'},
  {'emoji': '✳', 'src': 'https://vk.com/images/emoji/2733'},
  {'emoji': '✴', 'src': 'https://vk.com/images/emoji/2734'},
  {'emoji': '❇', 'src': 'https://vk.com/images/emoji/2747'},
  {'emoji': '❌', 'src': 'https://vk.com/images/emoji/274C'},
  {'emoji': '❎', 'src': 'https://vk.com/images/emoji/274E'},
  {'emoji': '❓', 'src': 'https://vk.com/images/emoji/2753'},
  {'emoji': '❔', 'src': 'https://vk.com/images/emoji/2754'},
  {'emoji': '❕', 'src': 'https://vk.com/images/emoji/2755'},
  {'emoji': '❗', 'src': 'https://vk.com/images/emoji/2757'},
  {'emoji': '➕', 'src': 'https://vk.com/images/emoji/2795'},
  {'emoji': '➖', 'src': 'https://vk.com/images/emoji/2796'},
  {'emoji': '➗', 'src': 'https://vk.com/images/emoji/2797'},
  {'emoji': '➡', 'src': 'https://vk.com/images/emoji/27A1'},
  {'emoji': '➿', 'src': 'https://vk.com/images/emoji/27BF'},
  {'emoji': '⤴', 'src': 'https://vk.com/images/emoji/2934'},
  {'emoji': '⤵', 'src': 'https://vk.com/images/emoji/2935'},
  {'emoji': '⬅', 'src': 'https://vk.com/images/emoji/2B05'},
  {'emoji': '⬆', 'src': 'https://vk.com/images/emoji/2B06'},
  {'emoji': '⬇', 'src': 'https://vk.com/images/emoji/2B07'},
  {'emoji': '⬛', 'src': 'https://vk.com/images/emoji/2B1B'},
  {'emoji': '⭐', 'src': 'https://vk.com/images/emoji/2B50'},
  {'emoji': '⭕', 'src': 'https://vk.com/images/emoji/2B55'},
  {'emoji': '〽', 'src': 'https://vk.com/images/emoji/303D'},
  {'emoji': '🅰', 'src': 'https://vk.com/images/emoji/D83CDD70'},
  {'emoji': '🅱', 'src': 'https://vk.com/images/emoji/D83CDD71'},
  {'emoji': '🅾', 'src': 'https://vk.com/images/emoji/D83CDD7E'},
  {'emoji': '🅿', 'src': 'https://vk.com/images/emoji/D83CDD7F'},
  {'emoji': '🆎', 'src': 'https://vk.com/images/emoji/D83CDD8E'},
  {'emoji': '🆑', 'src': 'https://vk.com/images/emoji/D83CDD91'},
  {'emoji': '🆒', 'src': 'https://vk.com/images/emoji/D83CDD92'},
  {'emoji': '🆓', 'src': 'https://vk.com/images/emoji/D83CDD93'},
  {'emoji': '🆔', 'src': 'https://vk.com/images/emoji/D83CDD94'},
  {'emoji': '🆕', 'src': 'https://vk.com/images/emoji/D83CDD95'},
  {'emoji': '🆖', 'src': 'https://vk.com/images/emoji/D83CDD96'},
  {'emoji': '🆗', 'src': 'https://vk.com/images/emoji/D83CDD97'},
  {'emoji': '🆙', 'src': 'https://vk.com/images/emoji/D83CDD99'},
  {'emoji': '🆚', 'src': 'https://vk.com/images/emoji/D83CDD9A'},
  {'emoji': '🇦🇪', 'src': 'https://vk.com/images/emoji/D83CDDE6D83CDDEA'},
  {'emoji': '🇦🇹', 'src': 'https://vk.com/images/emoji/D83CDDE6D83CDDF9'},
  {'emoji': '🇦🇺', 'src': 'https://vk.com/images/emoji/D83CDDE6D83CDDFA'},
  {'emoji': '🇧🇪', 'src': 'https://vk.com/images/emoji/D83CDDE7D83CDDEA'},
  {'emoji': '🇧🇷', 'src': 'https://vk.com/images/emoji/D83CDDE7D83CDDF7'},
  {'emoji': '🇨🇦', 'src': 'https://vk.com/images/emoji/D83CDDE8D83CDDE6'},
  {'emoji': '🇨🇭', 'src': 'https://vk.com/images/emoji/D83CDDE8D83CDDED'},
  {'emoji': '🇨🇱', 'src': 'https://vk.com/images/emoji/D83CDDE8D83CDDF1'},
  {'emoji': '🇨🇴', 'src': 'https://vk.com/images/emoji/D83CDDE8D83CDDF4'},
  {'emoji': '🇩🇰', 'src': 'https://vk.com/images/emoji/D83CDDE9D83CDDF0'},
  {'emoji': '🇫🇮', 'src': 'https://vk.com/images/emoji/D83CDDEBD83CDDEE'},
  {'emoji': '🇭🇰', 'src': 'https://vk.com/images/emoji/D83CDDEDD83CDDF0'},
  {'emoji': '🇮🇩', 'src': 'https://vk.com/images/emoji/D83CDDEED83CDDE9'},
  {'emoji': '🇮🇪', 'src': 'https://vk.com/images/emoji/D83CDDEED83CDDEA'},
  {'emoji': '🇮🇳', 'src': 'https://vk.com/images/emoji/D83CDDEED83CDDF3'},
  {'emoji': '🇲🇴', 'src': 'https://vk.com/images/emoji/D83CDDF2D83CDDF4'},
  {'emoji': '🇲🇽', 'src': 'https://vk.com/images/emoji/D83CDDF2D83CDDFD'},
  {'emoji': '🇲🇾', 'src': 'https://vk.com/images/emoji/D83CDDF2D83CDDFE'},
  {'emoji': '🇳🇱', 'src': 'https://vk.com/images/emoji/D83CDDF3D83CDDF1'},
  {'emoji': '🇳🇴', 'src': 'https://vk.com/images/emoji/D83CDDF3D83CDDF4'},
  {'emoji': '🇳🇿', 'src': 'https://vk.com/images/emoji/D83CDDF3D83CDDFF'},
  {'emoji': '🇵🇭', 'src': 'https://vk.com/images/emoji/D83CDDF5D83CDDED'},
  {'emoji': '🇵🇱', 'src': 'https://vk.com/images/emoji/D83CDDF5D83CDDF1'},
  {'emoji': '🇵🇷', 'src': 'https://vk.com/images/emoji/D83CDDF5D83CDDF7'},
  {'emoji': '🇵🇹', 'src': 'https://vk.com/images/emoji/D83CDDF5D83CDDF9'},
  {'emoji': '🇸🇦', 'src': 'https://vk.com/images/emoji/D83CDDF8D83CDDE6'},
  {'emoji': '🇸🇪', 'src': 'https://vk.com/images/emoji/D83CDDF8D83CDDEA'},
  {'emoji': '🇸🇬', 'src': 'https://vk.com/images/emoji/D83CDDF8D83CDDEC'},
  {'emoji': '🇻🇳', 'src': 'https://vk.com/images/emoji/D83CDDFBD83CDDF3'},
  {'emoji': '🇿🇦', 'src': 'https://vk.com/images/emoji/D83CDDFFD83CDDE6'},
  {'emoji': '🈁', 'src': 'https://vk.com/images/emoji/D83CDE01'},
  {'emoji': '🌀', 'src': 'https://vk.com/images/emoji/D83CDF00'},
  {'emoji': '🌁', 'src': 'https://vk.com/images/emoji/D83CDF01'},
  {'emoji': '🌃', 'src': 'https://vk.com/images/emoji/D83CDF03'},
  {'emoji': '🌄', 'src': 'https://vk.com/images/emoji/D83CDF04'},
  {'emoji': '🌅', 'src': 'https://vk.com/images/emoji/D83CDF05'},
  {'emoji': '🌆', 'src': 'https://vk.com/images/emoji/D83CDF06'},
  {'emoji': '🌇', 'src': 'https://vk.com/images/emoji/D83CDF07'},
  {'emoji': '🌈', 'src': 'https://vk.com/images/emoji/D83CDF08'},
  {'emoji': '🌉', 'src': 'https://vk.com/images/emoji/D83CDF09'},
  {'emoji': '🌊', 'src': 'https://vk.com/images/emoji/D83CDF0A'},
  {'emoji': '🌋', 'src': 'https://vk.com/images/emoji/D83CDF0B'},
  {'emoji': '🌌', 'src': 'https://vk.com/images/emoji/D83CDF0C'},
  {'emoji': '🌎', 'src': 'https://vk.com/images/emoji/D83CDF0E'},
  {'emoji': '🌏', 'src': 'https://vk.com/images/emoji/D83CDF0F'},
  {'emoji': '🌐', 'src': 'https://vk.com/images/emoji/D83CDF10'},
  {'emoji': '🌑', 'src': 'https://vk.com/images/emoji/D83CDF11'},
  {'emoji': '🌒', 'src': 'https://vk.com/images/emoji/D83CDF12'},
  {'emoji': '🌓', 'src': 'https://vk.com/images/emoji/D83CDF13'},
  {'emoji': '🌔', 'src': 'https://vk.com/images/emoji/D83CDF14'},
  {'emoji': '🌕', 'src': 'https://vk.com/images/emoji/D83CDF15'},
  {'emoji': '🌖', 'src': 'https://vk.com/images/emoji/D83CDF16'},
  {'emoji': '🌗', 'src': 'https://vk.com/images/emoji/D83CDF17'},
  {'emoji': '🌘', 'src': 'https://vk.com/images/emoji/D83CDF18'},
  {'emoji': '🌙', 'src': 'https://vk.com/images/emoji/D83CDF19'},
  {'emoji': '🌚', 'src': 'https://vk.com/images/emoji/D83CDF1A'},
  {'emoji': '🌜', 'src': 'https://vk.com/images/emoji/D83CDF1C'},
  {'emoji': '🌠', 'src': 'https://vk.com/images/emoji/D83CDF20'},
  {'emoji': '🍘', 'src': 'https://vk.com/images/emoji/D83CDF58'},
  {'emoji': '🍙', 'src': 'https://vk.com/images/emoji/D83CDF59'},
  {'emoji': '🎆', 'src': 'https://vk.com/images/emoji/D83CDF86'},
  {'emoji': '🎇', 'src': 'https://vk.com/images/emoji/D83CDF87'},
  {'emoji': '🎑', 'src': 'https://vk.com/images/emoji/D83CDF91'},
  {'emoji': '🎠', 'src': 'https://vk.com/images/emoji/D83CDFA0'},
  {'emoji': '🎡', 'src': 'https://vk.com/images/emoji/D83CDFA1'},
  {'emoji': '🎢', 'src': 'https://vk.com/images/emoji/D83CDFA2'},
  {'emoji': '🎥', 'src': 'https://vk.com/images/emoji/D83CDFA5'},
  {'emoji': '🎦', 'src': 'https://vk.com/images/emoji/D83CDFA6'},
  {'emoji': '🎮', 'src': 'https://vk.com/images/emoji/D83CDFAE'},
  {'emoji': '🎵', 'src': 'https://vk.com/images/emoji/D83CDFB5'},
  {'emoji': '🎶', 'src': 'https://vk.com/images/emoji/D83CDFB6'},
  {'emoji': '🎼', 'src': 'https://vk.com/images/emoji/D83CDFBC'},
  {'emoji': '🏠', 'src': 'https://vk.com/images/emoji/D83CDFE0'},
  {'emoji': '🏡', 'src': 'https://vk.com/images/emoji/D83CDFE1'},
  {'emoji': '🏢', 'src': 'https://vk.com/images/emoji/D83CDFE2'},
  {'emoji': '🏣', 'src': 'https://vk.com/images/emoji/D83CDFE3'},
  {'emoji': '🏤', 'src': 'https://vk.com/images/emoji/D83CDFE4'},
  {'emoji': '🏥', 'src': 'https://vk.com/images/emoji/D83CDFE5'},
  {'emoji': '🏧', 'src': 'https://vk.com/images/emoji/D83CDFE7'},
  {'emoji': '🏨', 'src': 'https://vk.com/images/emoji/D83CDFE8'},
  {'emoji': '🏩', 'src': 'https://vk.com/images/emoji/D83CDFE9'},
  {'emoji': '🏪', 'src': 'https://vk.com/images/emoji/D83CDFEA'},
  {'emoji': '🏫', 'src': 'https://vk.com/images/emoji/D83CDFEB'},
  {'emoji': '🏬', 'src': 'https://vk.com/images/emoji/D83CDFEC'},
  {'emoji': '🏭', 'src': 'https://vk.com/images/emoji/D83CDFED'},
  {'emoji': '🏮', 'src': 'https://vk.com/images/emoji/D83CDFEE'},
  {'emoji': '🏯', 'src': 'https://vk.com/images/emoji/D83CDFEF'},
  {'emoji': '🏰', 'src': 'https://vk.com/images/emoji/D83CDFF0'},
  {'emoji': '👉', 'src': 'https://vk.com/images/emoji/D83DDC49'},
  {'emoji': '👥', 'src': 'https://vk.com/images/emoji/D83DDC65'},
  {'emoji': '💠', 'src': 'https://vk.com/images/emoji/D83DDCA0'},
  {'emoji': '💢', 'src': 'https://vk.com/images/emoji/D83DDCA2'},
  {'emoji': '💤', 'src': 'https://vk.com/images/emoji/D83DDCA4'},
  {'emoji': '💫', 'src': 'https://vk.com/images/emoji/D83DDCAB'},
  {'emoji': '💮', 'src': 'https://vk.com/images/emoji/D83DDCAE'},
  {'emoji': '💯', 'src': 'https://vk.com/images/emoji/D83DDCAF'},
  {'emoji': '💱', 'src': 'https://vk.com/images/emoji/D83DDCB1'},
  {'emoji': '💲', 'src': 'https://vk.com/images/emoji/D83DDCB2'},
  {'emoji': '💹', 'src': 'https://vk.com/images/emoji/D83DDCB9'},
  {'emoji': '📀', 'src': 'https://vk.com/images/emoji/D83DDCC0'},
  {'emoji': '📁', 'src': 'https://vk.com/images/emoji/D83DDCC1'},
  {'emoji': '📂', 'src': 'https://vk.com/images/emoji/D83DDCC2'},
  {'emoji': '📃', 'src': 'https://vk.com/images/emoji/D83DDCC3'},
  {'emoji': '📆', 'src': 'https://vk.com/images/emoji/D83DDCC6'},
  {'emoji': '📏', 'src': 'https://vk.com/images/emoji/D83DDCCF'},
  {'emoji': '📛', 'src': 'https://vk.com/images/emoji/D83DDCDB'},
  {'emoji': '📞', 'src': 'https://vk.com/images/emoji/D83DDCDE'},
  {'emoji': '📣', 'src': 'https://vk.com/images/emoji/D83DDCE3'},
  {'emoji': '📤', 'src': 'https://vk.com/images/emoji/D83DDCE4'},
  {'emoji': '📥', 'src': 'https://vk.com/images/emoji/D83DDCE5'},
  {'emoji': '📧', 'src': 'https://vk.com/images/emoji/D83DDCE7'},
  {'emoji': '📨', 'src': 'https://vk.com/images/emoji/D83DDCE8'},
  {'emoji': '📩', 'src': 'https://vk.com/images/emoji/D83DDCE9'},
  {'emoji': '📪', 'src': 'https://vk.com/images/emoji/D83DDCEA'},
  {'emoji': '📫', 'src': 'https://vk.com/images/emoji/D83DDCEB'},
  {'emoji': '📬', 'src': 'https://vk.com/images/emoji/D83DDCEC'},
  {'emoji': '📲', 'src': 'https://vk.com/images/emoji/D83DDCF2'},
  {'emoji': '📳', 'src': 'https://vk.com/images/emoji/D83DDCF3'},
  {'emoji': '📴', 'src': 'https://vk.com/images/emoji/D83DDCF4'},
  {'emoji': '📵', 'src': 'https://vk.com/images/emoji/D83DDCF5'},
  {'emoji': '📶', 'src': 'https://vk.com/images/emoji/D83DDCF6'},
  {'emoji': '🔀', 'src': 'https://vk.com/images/emoji/D83DDD00'},
  {'emoji': '🔁', 'src': 'https://vk.com/images/emoji/D83DDD01'},
  {'emoji': '🔂', 'src': 'https://vk.com/images/emoji/D83DDD02'},
  {'emoji': '🔃', 'src': 'https://vk.com/images/emoji/D83DDD03'},
  {'emoji': '🔄', 'src': 'https://vk.com/images/emoji/D83DDD04'},
  {'emoji': '🔅', 'src': 'https://vk.com/images/emoji/D83DDD05'},
  {'emoji': '🔇', 'src': 'https://vk.com/images/emoji/D83DDD07'},
  {'emoji': '🔈', 'src': 'https://vk.com/images/emoji/D83DDD08'},
  {'emoji': '🔉', 'src': 'https://vk.com/images/emoji/D83DDD09'},
  {'emoji': '🔊', 'src': 'https://vk.com/images/emoji/D83DDD0A'},
  {'emoji': '🔋', 'src': 'https://vk.com/images/emoji/D83DDD0B'},
  {'emoji': '🔌', 'src': 'https://vk.com/images/emoji/D83DDD0C'},
  {'emoji': '🔍', 'src': 'https://vk.com/images/emoji/D83DDD0D'},
  {'emoji': '🔏', 'src': 'https://vk.com/images/emoji/D83DDD0F'},
  {'emoji': '🔐', 'src': 'https://vk.com/images/emoji/D83DDD10'},
  {'emoji': '🔒', 'src': 'https://vk.com/images/emoji/D83DDD12'},
  {'emoji': '🔓', 'src': 'https://vk.com/images/emoji/D83DDD13'},
  {'emoji': '🔕', 'src': 'https://vk.com/images/emoji/D83DDD15'},
  {'emoji': '🔗', 'src': 'https://vk.com/images/emoji/D83DDD17'},
  {'emoji': '🔘', 'src': 'https://vk.com/images/emoji/D83DDD18'},
  {'emoji': '🔙', 'src': 'https://vk.com/images/emoji/D83DDD19'},
  {'emoji': '🔚', 'src': 'https://vk.com/images/emoji/D83DDD1A'},
  {'emoji': '🔛', 'src': 'https://vk.com/images/emoji/D83DDD1B'},
  {'emoji': '🔜', 'src': 'https://vk.com/images/emoji/D83DDD1C'},
  {'emoji': '🔝', 'src': 'https://vk.com/images/emoji/D83DDD1D'},
  {'emoji': '🔟', 'src': 'https://vk.com/images/emoji/D83DDD1F'},
  {'emoji': '🔠', 'src': 'https://vk.com/images/emoji/D83DDD20'},
  {'emoji': '🔡', 'src': 'https://vk.com/images/emoji/D83DDD21'},
  {'emoji': '🔢', 'src': 'https://vk.com/images/emoji/D83DDD22'},
  {'emoji': '🔣', 'src': 'https://vk.com/images/emoji/D83DDD23'},
  {'emoji': '🔤', 'src': 'https://vk.com/images/emoji/D83DDD24'},
  {'emoji': '🔯', 'src': 'https://vk.com/images/emoji/D83DDD2F'},
  {'emoji': '🔲', 'src': 'https://vk.com/images/emoji/D83DDD32'},
  {'emoji': '🔳', 'src': 'https://vk.com/images/emoji/D83DDD33'},
  {'emoji': '🔴', 'src': 'https://vk.com/images/emoji/D83DDD34'},
  {'emoji': '🔵', 'src': 'https://vk.com/images/emoji/D83DDD35'},
  {'emoji': '🔶', 'src': 'https://vk.com/images/emoji/D83DDD36'},
  {'emoji': '🔷', 'src': 'https://vk.com/images/emoji/D83DDD37'},
  {'emoji': '🔸', 'src': 'https://vk.com/images/emoji/D83DDD38'},
  {'emoji': '🔹', 'src': 'https://vk.com/images/emoji/D83DDD39'},
  {'emoji': '🔺', 'src': 'https://vk.com/images/emoji/D83DDD3A'},
  {'emoji': '🔻', 'src': 'https://vk.com/images/emoji/D83DDD3B'},
  {'emoji': '🔼', 'src': 'https://vk.com/images/emoji/D83DDD3C'},
  {'emoji': '🔽', 'src': 'https://vk.com/images/emoji/D83DDD3D'},
  {'emoji': '🗻', 'src': 'https://vk.com/images/emoji/D83DDDFB'},
  {'emoji': '🗼', 'src': 'https://vk.com/images/emoji/D83DDDFC'},
  {'emoji': '🗽', 'src': 'https://vk.com/images/emoji/D83DDDFD'},
  {'emoji': '🗾', 'src': 'https://vk.com/images/emoji/D83DDDFE'},
  {'emoji': '😗', 'src': 'https://vk.com/images/emoji/D83DDE17'},
  {'emoji': '🙍', 'src': 'https://vk.com/images/emoji/D83DDE4D'},
  {'emoji': '🚉', 'src': 'https://vk.com/images/emoji/D83DDE89'},
  {'emoji': '🚋', 'src': 'https://vk.com/images/emoji/D83DDE8B'},
  {'emoji': '🚢', 'src': 'https://vk.com/images/emoji/D83DDEA2'},
  {'emoji': '🚥', 'src': 'https://vk.com/images/emoji/D83DDEA5'},
  {'emoji': '🚦', 'src': 'https://vk.com/images/emoji/D83DDEA6'},
  {'emoji': '🚩', 'src': 'https://vk.com/images/emoji/D83DDEA9'},
  {'emoji': '🚫', 'src': 'https://vk.com/images/emoji/D83DDEAB'},
  {'emoji': '🚭', 'src': 'https://vk.com/images/emoji/D83DDEAD'},
  {'emoji': '🚮', 'src': 'https://vk.com/images/emoji/D83DDEAE'},
  {'emoji': '🚯', 'src': 'https://vk.com/images/emoji/D83DDEAF'},
  {'emoji': '🚰', 'src': 'https://vk.com/images/emoji/D83DDEB0'},
  {'emoji': '🚱', 'src': 'https://vk.com/images/emoji/D83DDEB1'},
  {'emoji': '🚲', 'src': 'https://vk.com/images/emoji/D83DDEB2'},
  {'emoji': '🚳', 'src': 'https://vk.com/images/emoji/D83DDEB3'},
  {'emoji': '🚷', 'src': 'https://vk.com/images/emoji/D83DDEB7'},
  {'emoji': '🚸', 'src': 'https://vk.com/images/emoji/D83DDEB8'},
  {'emoji': '🚹', 'src': 'https://vk.com/images/emoji/D83DDEB9'},
  {'emoji': '🚺', 'src': 'https://vk.com/images/emoji/D83DDEBA'},
  {'emoji': '🚻', 'src': 'https://vk.com/images/emoji/D83DDEBB'},
  {'emoji': '🚼', 'src': 'https://vk.com/images/emoji/D83DDEBC'},
  {'emoji': '🚾', 'src': 'https://vk.com/images/emoji/D83DDEBE'},
  {'emoji': '🛁', 'src': 'https://vk.com/images/emoji/D83DDEC1'},
  {'emoji': '🛂', 'src': 'https://vk.com/images/emoji/D83DDEC2'},
  {'emoji': '🛃', 'src': 'https://vk.com/images/emoji/D83DDEC3'},
  {'emoji': '🛄', 'src': 'https://vk.com/images/emoji/D83DDEC4'},
  {'emoji': '🛅', 'src': 'https://vk.com/images/emoji/D83DDEC5'},
  {'emoji': '🇰🇿', 'src': 'https://vk.com/images/emoji/D83CDDF0D83CDDFF'},
  {'emoji': '🇧🇾', 'src': 'https://vk.com/images/emoji/D83CDDE7D83CDDFE'},
  {'emoji': '🇮🇱', 'src': 'https://vk.com/images/emoji/D83CDDEED83CDDF1'},
  {'emoji': '🇹🇷', 'src': 'https://vk.com/images/emoji/D83CDDF9D83CDDF7'},
  {'emoji': '⌨', 'src': 'https://vk.com/images/emoji/2328'},
  {'emoji': '⏭', 'src': 'https://vk.com/images/emoji/23ED'},
  {'emoji': '⏮', 'src': 'https://vk.com/images/emoji/23EE'},
  {'emoji': '⏯', 'src': 'https://vk.com/images/emoji/23EF'},
  {'emoji': '⏱', 'src': 'https://vk.com/images/emoji/23F1'},
  {'emoji': '⏲', 'src': 'https://vk.com/images/emoji/23F2'},
  {'emoji': '⏸', 'src': 'https://vk.com/images/emoji/23F8'},
  {'emoji': '⏹', 'src': 'https://vk.com/images/emoji/23F9'},
  {'emoji': '⏺', 'src': 'https://vk.com/images/emoji/23FA'},
  {'emoji': '☂', 'src': 'https://vk.com/images/emoji/2602'},
  {'emoji': '☃', 'src': 'https://vk.com/images/emoji/2603'},
  {'emoji': '☄', 'src': 'https://vk.com/images/emoji/2604'},
  {'emoji': '☘', 'src': 'https://vk.com/images/emoji/2618'},
  {'emoji': '☢', 'src': 'https://vk.com/images/emoji/2622'},
  {'emoji': '☣', 'src': 'https://vk.com/images/emoji/2623'},
  {'emoji': '☦', 'src': 'https://vk.com/images/emoji/2626'},
  {'emoji': '☪', 'src': 'https://vk.com/images/emoji/262A'},
  {'emoji': '☮', 'src': 'https://vk.com/images/emoji/262E'},
  {'emoji': '☯', 'src': 'https://vk.com/images/emoji/262F'},
  {'emoji': '☸', 'src': 'https://vk.com/images/emoji/2638'},
  {'emoji': '☹', 'src': 'https://vk.com/images/emoji/2639'},
  {'emoji': '⚒', 'src': 'https://vk.com/images/emoji/2692'},
  {'emoji': '⚔', 'src': 'https://vk.com/images/emoji/2694'},
  {'emoji': '⚖', 'src': 'https://vk.com/images/emoji/2696'},
  {'emoji': '⚗', 'src': 'https://vk.com/images/emoji/2697'},
  {'emoji': '⚙', 'src': 'https://vk.com/images/emoji/2699'},
  {'emoji': '⚛', 'src': 'https://vk.com/images/emoji/269B'},
  {'emoji': '⚜', 'src': 'https://vk.com/images/emoji/269C'},
  {'emoji': '⚰', 'src': 'https://vk.com/images/emoji/26B0'},
  {'emoji': '⚱', 'src': 'https://vk.com/images/emoji/26B1'},
  {'emoji': '⛈', 'src': 'https://vk.com/images/emoji/26C8'},
  {'emoji': '⛏', 'src': 'https://vk.com/images/emoji/26CF'},
  {'emoji': '⛑', 'src': 'https://vk.com/images/emoji/26D1'},
  {'emoji': '⛓', 'src': 'https://vk.com/images/emoji/26D3'},
  {'emoji': '⛩', 'src': 'https://vk.com/images/emoji/26E9'},
  {'emoji': '⛰', 'src': 'https://vk.com/images/emoji/26F0'},
  {'emoji': '⛱', 'src': 'https://vk.com/images/emoji/26F1'},
  {'emoji': '⛴', 'src': 'https://vk.com/images/emoji/26F4'},
  {'emoji': '⛷', 'src': 'https://vk.com/images/emoji/26F7'},
  {'emoji': '⛸', 'src': 'https://vk.com/images/emoji/26F8'},
  {'emoji': '⛹', 'src': 'https://vk.com/images/emoji/26F9'},
  {'emoji': '✍', 'src': 'https://vk.com/images/emoji/270D'},
  {'emoji': '✝', 'src': 'https://vk.com/images/emoji/271D'},
  {'emoji': '✡', 'src': 'https://vk.com/images/emoji/2721'},
  {'emoji': '❣', 'src': 'https://vk.com/images/emoji/2763'},
  {'emoji': '🌡', 'src': 'https://vk.com/images/emoji/D83CDF21'},
  {'emoji': '🌤', 'src': 'https://vk.com/images/emoji/D83CDF24'},
  {'emoji': '🌥', 'src': 'https://vk.com/images/emoji/D83CDF25'},
  {'emoji': '🌦', 'src': 'https://vk.com/images/emoji/D83CDF26'},
  {'emoji': '🌧', 'src': 'https://vk.com/images/emoji/D83CDF27'},
  {'emoji': '🌨', 'src': 'https://vk.com/images/emoji/D83CDF28'},
  {'emoji': '🌩', 'src': 'https://vk.com/images/emoji/D83CDF29'},
  {'emoji': '🌪', 'src': 'https://vk.com/images/emoji/D83CDF2A'},
  {'emoji': '🌫', 'src': 'https://vk.com/images/emoji/D83CDF2B'},
  {'emoji': '🌬', 'src': 'https://vk.com/images/emoji/D83CDF2C'},
  {'emoji': '🌭', 'src': 'https://vk.com/images/emoji/D83CDF2D'},
  {'emoji': '🌮', 'src': 'https://vk.com/images/emoji/D83CDF2E'},
  {'emoji': '🌯', 'src': 'https://vk.com/images/emoji/D83CDF2F'},
  {'emoji': '🌶', 'src': 'https://vk.com/images/emoji/D83CDF36'},
  {'emoji': '🍽', 'src': 'https://vk.com/images/emoji/D83CDF7D'},
  {'emoji': '🍾', 'src': 'https://vk.com/images/emoji/D83CDF7E'},
  {'emoji': '🍿', 'src': 'https://vk.com/images/emoji/D83CDF7F'},
  {'emoji': '🎖', 'src': 'https://vk.com/images/emoji/D83CDF96'},
  {'emoji': '🎗', 'src': 'https://vk.com/images/emoji/D83CDF97'},
  {'emoji': '🎙', 'src': 'https://vk.com/images/emoji/D83CDF99'},
  {'emoji': '🎚', 'src': 'https://vk.com/images/emoji/D83CDF9A'},
  {'emoji': '🎛', 'src': 'https://vk.com/images/emoji/D83CDF9B'},
  {'emoji': '🎞', 'src': 'https://vk.com/images/emoji/D83CDF9E'},
  {'emoji': '🎟', 'src': 'https://vk.com/images/emoji/D83CDF9F'},
  {'emoji': '🏅', 'src': 'https://vk.com/images/emoji/D83CDFC5'},
  {'emoji': '🏋', 'src': 'https://vk.com/images/emoji/D83CDFCB'},
  {'emoji': '🏌', 'src': 'https://vk.com/images/emoji/D83CDFCC'},
  {'emoji': '🏍', 'src': 'https://vk.com/images/emoji/D83CDFCD'},
  {'emoji': '🏎', 'src': 'https://vk.com/images/emoji/D83CDFCE'},
  {'emoji': '🏏', 'src': 'https://vk.com/images/emoji/D83CDFCF'},
  {'emoji': '🏐', 'src': 'https://vk.com/images/emoji/D83CDFD0'},
  {'emoji': '🏑', 'src': 'https://vk.com/images/emoji/D83CDFD1'},
  {'emoji': '🏒', 'src': 'https://vk.com/images/emoji/D83CDFD2'},
  {'emoji': '🏓', 'src': 'https://vk.com/images/emoji/D83CDFD3'},
  {'emoji': '🏔', 'src': 'https://vk.com/images/emoji/D83CDFD4'},
  {'emoji': '🏕', 'src': 'https://vk.com/images/emoji/D83CDFD5'},
  {'emoji': '🏖', 'src': 'https://vk.com/images/emoji/D83CDFD6'},
  {'emoji': '🏗', 'src': 'https://vk.com/images/emoji/D83CDFD7'},
  {'emoji': '🏘', 'src': 'https://vk.com/images/emoji/D83CDFD8'},
  {'emoji': '🏙', 'src': 'https://vk.com/images/emoji/D83CDFD9'},
  {'emoji': '🏚', 'src': 'https://vk.com/images/emoji/D83CDFDA'},
  {'emoji': '🏛', 'src': 'https://vk.com/images/emoji/D83CDFDB'},
  {'emoji': '🏜', 'src': 'https://vk.com/images/emoji/D83CDFDC'},
  {'emoji': '🏝', 'src': 'https://vk.com/images/emoji/D83CDFDD'},
  {'emoji': '🏞', 'src': 'https://vk.com/images/emoji/D83CDFDE'},
  {'emoji': '🏟', 'src': 'https://vk.com/images/emoji/D83CDFDF'},
  {'emoji': '🏳', 'src': 'https://vk.com/images/emoji/D83CDFF3'},
  {'emoji': '🏴', 'src': 'https://vk.com/images/emoji/D83CDFF4'},
  {'emoji': '🏵', 'src': 'https://vk.com/images/emoji/D83CDFF5'},
  {'emoji': '🏷', 'src': 'https://vk.com/images/emoji/D83CDFF7'},
  {'emoji': '🏸', 'src': 'https://vk.com/images/emoji/D83CDFF8'},
  {'emoji': '🏹', 'src': 'https://vk.com/images/emoji/D83CDFF9'},
  {'emoji': '🏺', 'src': 'https://vk.com/images/emoji/D83CDFFA'},
  {'emoji': '🐿', 'src': 'https://vk.com/images/emoji/D83DDC3F'},
  {'emoji': '👁', 'src': 'https://vk.com/images/emoji/D83DDC41'},
  {'emoji': '📸', 'src': 'https://vk.com/images/emoji/D83DDCF8'},
  {'emoji': '📽', 'src': 'https://vk.com/images/emoji/D83DDCFD'},
  {'emoji': '📿', 'src': 'https://vk.com/images/emoji/D83DDCFF'},
  {'emoji': '🕉', 'src': 'https://vk.com/images/emoji/D83DDD49'},
  {'emoji': '🕊', 'src': 'https://vk.com/images/emoji/D83DDD4A'},
  {'emoji': '🕋', 'src': 'https://vk.com/images/emoji/D83DDD4B'},
  {'emoji': '🕌', 'src': 'https://vk.com/images/emoji/D83DDD4C'},
  {'emoji': '🕍', 'src': 'https://vk.com/images/emoji/D83DDD4D'},
  {'emoji': '🕎', 'src': 'https://vk.com/images/emoji/D83DDD4E'},
  {'emoji': '🕯', 'src': 'https://vk.com/images/emoji/D83DDD6F'},
  {'emoji': '🕰', 'src': 'https://vk.com/images/emoji/D83DDD70'},
  {'emoji': '🕳', 'src': 'https://vk.com/images/emoji/D83DDD73'},
  {'emoji': '🕴', 'src': 'https://vk.com/images/emoji/D83DDD74'},
  {'emoji': '🕵', 'src': 'https://vk.com/images/emoji/D83DDD75'},
  {'emoji': '🕶', 'src': 'https://vk.com/images/emoji/D83DDD76'},
  {'emoji': '🕷', 'src': 'https://vk.com/images/emoji/D83DDD77'},
  {'emoji': '🕸', 'src': 'https://vk.com/images/emoji/D83DDD78'},
  {'emoji': '🕹', 'src': 'https://vk.com/images/emoji/D83DDD79'},
  {'emoji': '🖇', 'src': 'https://vk.com/images/emoji/D83DDD87'},
  {'emoji': '🖊', 'src': 'https://vk.com/images/emoji/D83DDD8A'},
  {'emoji': '🖋', 'src': 'https://vk.com/images/emoji/D83DDD8B'},
  {'emoji': '🖌', 'src': 'https://vk.com/images/emoji/D83DDD8C'},
  {'emoji': '🖍', 'src': 'https://vk.com/images/emoji/D83DDD8D'},
  {'emoji': '🖐', 'src': 'https://vk.com/images/emoji/D83DDD90'},
  {'emoji': '🖖', 'src': 'https://vk.com/images/emoji/D83DDD96'},
  {'emoji': '🖥', 'src': 'https://vk.com/images/emoji/D83DDDA5'},
  {'emoji': '🖨', 'src': 'https://vk.com/images/emoji/D83DDDA8'},
  {'emoji': '🖱', 'src': 'https://vk.com/images/emoji/D83DDDB1'},
  {'emoji': '🖲', 'src': 'https://vk.com/images/emoji/D83DDDB2'},
  {'emoji': '🖼', 'src': 'https://vk.com/images/emoji/D83DDDBC'},
  {'emoji': '🗂', 'src': 'https://vk.com/images/emoji/D83DDDC2'},
  {'emoji': '🗃', 'src': 'https://vk.com/images/emoji/D83DDDC3'},
  {'emoji': '🗄', 'src': 'https://vk.com/images/emoji/D83DDDC4'},
  {'emoji': '🗑', 'src': 'https://vk.com/images/emoji/D83DDDD1'},
  {'emoji': '🗒', 'src': 'https://vk.com/images/emoji/D83DDDD2'},
  {'emoji': '🗓', 'src': 'https://vk.com/images/emoji/D83DDDD3'},
  {'emoji': '🗜', 'src': 'https://vk.com/images/emoji/D83DDDDC'},
  {'emoji': '🗝', 'src': 'https://vk.com/images/emoji/D83DDDDD'},
  {'emoji': '🗞', 'src': 'https://vk.com/images/emoji/D83DDDDE'},
  {'emoji': '🗡', 'src': 'https://vk.com/images/emoji/D83DDDE1'},
  {'emoji': '🗣', 'src': 'https://vk.com/images/emoji/D83DDDE3'},
  {'emoji': '🗯', 'src': 'https://vk.com/images/emoji/D83DDDEF'},
  {'emoji': '🗳', 'src': 'https://vk.com/images/emoji/D83DDDF3'},
  {'emoji': '🗺', 'src': 'https://vk.com/images/emoji/D83DDDFA'},
  {'emoji': '🙁', 'src': 'https://vk.com/images/emoji/D83DDE41'},
  {'emoji': '🙂', 'src': 'https://vk.com/images/emoji/D83DDE42'},
  {'emoji': '🙃', 'src': 'https://vk.com/images/emoji/D83DDE43'},
  {'emoji': '🙄', 'src': 'https://vk.com/images/emoji/D83DDE44'},
  {'emoji': '🛋', 'src': 'https://vk.com/images/emoji/D83DDECB'},
  {'emoji': '🛌', 'src': 'https://vk.com/images/emoji/D83DDECC'},
  {'emoji': '🛍', 'src': 'https://vk.com/images/emoji/D83DDECD'},
  {'emoji': '🛎', 'src': 'https://vk.com/images/emoji/D83DDECE'},
  {'emoji': '🛏', 'src': 'https://vk.com/images/emoji/D83DDECF'},
  {'emoji': '🛐', 'src': 'https://vk.com/images/emoji/D83DDED0'},
  {'emoji': '🛠', 'src': 'https://vk.com/images/emoji/D83DDEE0'},
  {'emoji': '🛡', 'src': 'https://vk.com/images/emoji/D83DDEE1'},
  {'emoji': '🛢', 'src': 'https://vk.com/images/emoji/D83DDEE2'},
  {'emoji': '🛣', 'src': 'https://vk.com/images/emoji/D83DDEE3'},
  {'emoji': '🛤', 'src': 'https://vk.com/images/emoji/D83DDEE4'},
  {'emoji': '🛥', 'src': 'https://vk.com/images/emoji/D83DDEE5'},
  {'emoji': '🛩', 'src': 'https://vk.com/images/emoji/D83DDEE9'},
  {'emoji': '🛫', 'src': 'https://vk.com/images/emoji/D83DDEEB'},
  {'emoji': '🛬', 'src': 'https://vk.com/images/emoji/D83DDEEC'},
  {'emoji': '🛰', 'src': 'https://vk.com/images/emoji/D83DDEF0'},
  {'emoji': '🛳', 'src': 'https://vk.com/images/emoji/D83DDEF3'},
  {'emoji': '🤐', 'src': 'https://vk.com/images/emoji/D83EDD10'},
  {'emoji': '🤑', 'src': 'https://vk.com/images/emoji/D83EDD11'},
  {'emoji': '🤒', 'src': 'https://vk.com/images/emoji/D83EDD12'},
  {'emoji': '🤓', 'src': 'https://vk.com/images/emoji/D83EDD13'},
  {'emoji': '🤔', 'src': 'https://vk.com/images/emoji/D83EDD14'},
  {'emoji': '🤕', 'src': 'https://vk.com/images/emoji/D83EDD15'},
  {'emoji': '🤖', 'src': 'https://vk.com/images/emoji/D83EDD16'},
  {'emoji': '🤗', 'src': 'https://vk.com/images/emoji/D83EDD17'},
  {'emoji': '🤘', 'src': 'https://vk.com/images/emoji/D83EDD18'},
  {'emoji': '🦀', 'src': 'https://vk.com/images/emoji/D83EDD80'},
  {'emoji': '🦁', 'src': 'https://vk.com/images/emoji/D83EDD81'},
  {'emoji': '🦂', 'src': 'https://vk.com/images/emoji/D83EDD82'},
  {'emoji': '🦃', 'src': 'https://vk.com/images/emoji/D83EDD83'},
  {'emoji': '🦄', 'src': 'https://vk.com/images/emoji/D83EDD84'},
  {'emoji': '🧀', 'src': 'https://vk.com/images/emoji/D83EDDC0'},
  {'emoji': '🗨', 'src': 'https://vk.com/images/emoji/D83DDDE8'},
  {'emoji': '㊗', 'src': 'https://vk.com/images/emoji/3297'},
  {'emoji': '㊙', 'src': 'https://vk.com/images/emoji/3299'},
  {'emoji': '🈂', 'src': 'https://vk.com/images/emoji/D83CDE02'},
  {'emoji': '🈚', 'src': 'https://vk.com/images/emoji/D83CDE1A'},
  {'emoji': '🈯', 'src': 'https://vk.com/images/emoji/D83CDE2F'},
  {'emoji': '🈲', 'src': 'https://vk.com/images/emoji/D83CDE32'},
  {'emoji': '🈳', 'src': 'https://vk.com/images/emoji/D83CDE33'},
  {'emoji': '🈴', 'src': 'https://vk.com/images/emoji/D83CDE34'},
  {'emoji': '🈵', 'src': 'https://vk.com/images/emoji/D83CDE35'},
  {'emoji': '🈶', 'src': 'https://vk.com/images/emoji/D83CDE36'},
  {'emoji': '🈷', 'src': 'https://vk.com/images/emoji/D83CDE37'},
  {'emoji': '🈸', 'src': 'https://vk.com/images/emoji/D83CDE38'},
  {'emoji': '🈹', 'src': 'https://vk.com/images/emoji/D83CDE39'},
  {'emoji': '🈺', 'src': 'https://vk.com/images/emoji/D83CDE3A'},
  {'emoji': '🉐', 'src': 'https://vk.com/images/emoji/D83CDE50'},
  {'emoji': '🉑', 'src': 'https://vk.com/images/emoji/D83CDE51'},
  {'emoji': '🕐', 'src': 'https://vk.com/images/emoji/D83DDD50'},
  {'emoji': '🕑', 'src': 'https://vk.com/images/emoji/D83DDD51'},
  {'emoji': '🕒', 'src': 'https://vk.com/images/emoji/D83DDD52'},
  {'emoji': '🕓', 'src': 'https://vk.com/images/emoji/D83DDD53'},
  {'emoji': '🕔', 'src': 'https://vk.com/images/emoji/D83DDD54'},
  {'emoji': '🕕', 'src': 'https://vk.com/images/emoji/D83DDD55'},
  {'emoji': '🕖', 'src': 'https://vk.com/images/emoji/D83DDD56'},
  {'emoji': '🕗', 'src': 'https://vk.com/images/emoji/D83DDD57'},
  {'emoji': '🕘', 'src': 'https://vk.com/images/emoji/D83DDD58'},
  {'emoji': '🕙', 'src': 'https://vk.com/images/emoji/D83DDD59'},
  {'emoji': '🕚', 'src': 'https://vk.com/images/emoji/D83DDD5A'},
  {'emoji': '🕛', 'src': 'https://vk.com/images/emoji/D83DDD5B'},
  {'emoji': '🕜', 'src': 'https://vk.com/images/emoji/D83DDD5C'},
  {'emoji': '🕝', 'src': 'https://vk.com/images/emoji/D83DDD5D'},
  {'emoji': '🕞', 'src': 'https://vk.com/images/emoji/D83DDD5E'},
  {'emoji': '🕟', 'src': 'https://vk.com/images/emoji/D83DDD5F'},
  {'emoji': '🕠', 'src': 'https://vk.com/images/emoji/D83DDD60'},
  {'emoji': '🕡', 'src': 'https://vk.com/images/emoji/D83DDD61'},
  {'emoji': '🕢', 'src': 'https://vk.com/images/emoji/D83DDD62'},
  {'emoji': '🕣', 'src': 'https://vk.com/images/emoji/D83DDD63'},
  {'emoji': '🕤', 'src': 'https://vk.com/images/emoji/D83DDD64'},
  {'emoji': '🕥', 'src': 'https://vk.com/images/emoji/D83DDD65'},
  {'emoji': '🕦', 'src': 'https://vk.com/images/emoji/D83DDD66'},
  {'emoji': '🕧', 'src': 'https://vk.com/images/emoji/D83DDD67'},
  {'emoji': '🔰', 'src': 'https://vk.com/images/emoji/D83DDD30'},
  {'emoji': '🥇', 'src': 'https://vk.com/images/emoji/D83EDD47'},
  {'emoji': '🥈', 'src': 'https://vk.com/images/emoji/D83EDD48'},
  {'emoji': '🥉', 'src': 'https://vk.com/images/emoji/D83EDD49'},
  {'emoji': '💋', 'src': 'https://vk.com/images/emoji/D83DDC8B'},
  {'emoji': '🍒', 'src': 'https://vk.com/images/emoji/D83CDF52'},
  {'emoji': '💪', 'src': 'https://vk.com/images/emoji/D83DDCAA'},
  {'emoji': '🎁', 'src': 'https://vk.com/images/emoji/D83CDF81'},
  {'emoji': '👈', 'src': 'https://vk.com/images/emoji/D83DDC48'},
  {'emoji': '🥙', 'src': 'https://vk.com/images/emoji/D83EDD59'},
  {'emoji': '🌟', 'src': 'https://vk.com/images/emoji/D83CDF1F'},
  {'emoji': '👇', 'src': 'https://vk.com/images/emoji/D83DDC47'},
  {'emoji': '‍♂️', 'src': 'https://vk.com/images/emoji/D83EDD26D83CDFFB200D2642FE0F'},
  {'emoji': '🖤', 'src': 'https://vk.com/images/emoji/D83DDDA4'},
  {'emoji': '😃', 'src': 'https://vk.com/images/emoji/D83DDE03'},
  {'emoji': '‍♀️', 'src': 'https://vk.com/images/emoji/D83EDD3DD83CDFFB200D2640FE0F'},
  {'emoji': '😊', 'src': 'https://vk.com/images/emoji/D83DDE0A'},
  {'emoji': '🤣', 'src': 'https://vk.com/images/emoji/D83EDD23'},
  {'emoji': '💦', 'src': 'https://vk.com/images/emoji/D83DDCA6'},
  {'emoji': '🤤', 'src': 'https://vk.com/images/emoji/D83EDD24'},
  {'emoji': '🏆', 'src': 'https://vk.com/images/emoji/D83CDFC6'},
  {'emoji': '🥂', 'src': 'https://vk.com/images/emoji/D83EDD42'},
  {'emoji': '🥞', 'src': 'https://vk.com/images/emoji/D83EDD5E'},
  {'emoji': '1⃣', 'src': 'https://vk.com/images/emoji/003120E3'},
  {'emoji': '2⃣', 'src': 'https://vk.com/images/emoji/003220E3'},
  {'emoji': '3⃣', 'src': 'https://vk.com/images/emoji/003320E3'},
  {'emoji': '🦐', 'src': 'https://vk.com/images/emoji/D83EDD90'},
  {'emoji': '🦋', 'src': 'https://vk.com/images/emoji/D83EDD8B'},
  {'emoji': '9⃣', 'src': 'https://vk.com/images/emoji/003920E3'},
  {'emoji': '8⃣', 'src': 'https://vk.com/images/emoji/003820E3'},
  {'emoji': '7⃣', 'src': 'https://vk.com/images/emoji/003720E3'},
  {'emoji': '6⃣', 'src': 'https://vk.com/images/emoji/003620E3'},
  {'emoji': '5⃣', 'src': 'https://vk.com/images/emoji/003520E3'},
  {'emoji': '4⃣', 'src': 'https://vk.com/images/emoji/003420E3'},
  {'emoji': '0⃣', 'src': 'https://vk.com/images/emoji/003020E3'},
  {'emoji': '⬜', 'src': 'https://vk.com/images/emoji/2B1C'},
  {'emoji': '〰', 'src': 'https://vk.com/images/emoji/3030'},
  {'emoji': '➰', 'src': 'https://vk.com/images/emoji/27B0'},
  {'emoji': '™', 'src': 'https://vk.com/images/emoji/2122'},
  {'emoji': '®', 'src': 'https://vk.com/images/emoji/00AE'},
  {'emoji': '®', 'src': 'https://vk.com/images/emoji/00A9'},
  {'emoji': 'ℹ', 'src': 'https://vk.com/images/emoji/2139'},
  {'emoji': '👤', 'src': 'https://vk.com/images/emoji/D83DDC64'},
  {'emoji': '⛵', 'src': 'https://vk.com/images/emoji/26FD'},
  {'emoji': '🐮', 'src': 'https://vk.com/images/emoji/D83DDC2E'},
  {'emoji': '🐫', 'src': 'https://vk.com/images/emoji/D83DDC2B'},
  {'emoji': '🐜', 'src': 'https://vk.com/images/emoji/D83DDC1C'},
  {'emoji': '🐏', 'src': 'https://vk.com/images/emoji/D83DDC0F'},
  {'emoji': '🐎', 'src': 'https://vk.com/images/emoji/D83DDC0E'},
  {'emoji': '🏁', 'src': 'https://vk.com/images/emoji/D83CDFC1'},
  {'emoji': '🎄', 'src': 'https://vk.com/images/emoji/D83CDF84'},
  {'emoji': '🎂', 'src': 'https://vk.com/images/emoji/D83CDF82'},
  {'emoji': '🍅', 'src': 'https://vk.com/images/emoji/D83CDF45'},
  {'emoji': '🌹', 'src': 'https://vk.com/images/emoji/D83CDF39'},
  {'emoji': '🍻', 'src': 'https://vk.com/images/emoji/D83CDF7B'},
  {'emoji': '🍺', 'src': 'https://vk.com/images/emoji/D83CDF7A'},
  {'emoji': '🍌', 'src': 'https://vk.com/images/emoji/D83CDF4C'},
  {'emoji': '⛅', 'src': 'https://vk.com/images/emoji/26C5'},
  {'emoji': '⚽', 'src': 'https://vk.com/images/emoji/26BD'},
  {'emoji': '👹', 'src': 'https://vk.com/images/emoji/D83DDC79'},
  {'emoji': '🔞', 'src': 'https://vk.com/images/emoji/D83DDD1E'},
  {'emoji': '🐨', 'src': 'https://vk.com/images/emoji/D83DDC28'},
  {'emoji': '👺', 'src': 'https://vk.com/images/emoji/D83DDC7A'},
  {'emoji': '🎅', 'src': 'https://vk.com/images/emoji/D83CDF85'},
  {'emoji': '🍸', 'src': 'https://vk.com/images/emoji/D83CDF78'},
  {'emoji': '🍷', 'src': 'https://vk.com/images/emoji/D83CDF77'},
  {'emoji': '🍊', 'src': 'https://vk.com/images/emoji/D83CDF4A'},
  {'emoji': '❄', 'src': 'https://vk.com/images/emoji/2744'},
  {'emoji': '💩', 'src': 'https://vk.com/images/emoji/D83DDCA9'},
  {'emoji': '👂', 'src': 'https://vk.com/images/emoji/D83DDC42'},
  {'emoji': '🤩', 'src': 'https://vk.com/emoji/e/f09fa4a9'},
  {'emoji': '🤨', 'src': 'https://vk.com/emoji/e/f09fa4a8'},
  {'emoji': '🤯', 'src': 'https://vk.com/emoji/e/f09fa4af'},
  {'emoji': '🤪', 'src': 'https://vk.com/emoji/e/f09fa4aa'},
  {'emoji': '🤬', 'src': 'https://vk.com/emoji/e/f09fa4ac'},
  {'emoji': '🤮', 'src': 'https://vk.com/emoji/e/f09fa4ae'},
  {'emoji': '🤫', 'src': 'https://vk.com/emoji/e/f09fa4ab'},
  {'emoji': '🤭', 'src': 'https://vk.com/emoji/e/f09fa4ad'},
  {'emoji': '🧐', 'src': 'https://vk.com/emoji/e/f09fa790'},
  {'emoji': '🧒', 'src': 'https://vk.com/emoji/e/f09fa792'},
  {'emoji': '🧑', 'src': 'https://vk.com/emoji/e/f09fa791'},
  {'emoji': '🧓', 'src': 'https://vk.com/emoji/e/f09fa793'},
  {'emoji': '🧕', 'src': 'https://vk.com/emoji/e/f09fa795'},
  {'emoji': '🧔', 'src': 'https://vk.com/emoji/e/f09fa794'},
  {'emoji': '🤱', 'src': 'https://vk.com/emoji/e/f09fa4b1'},
  {'emoji': '🧙', 'src': 'https://vk.com/emoji/e/f09fa799'},
  {'emoji': '🧙‍♀', 'src': 'https://vk.com/emoji/e/f09fa799e2808de29980'},
  {'emoji': '🧙‍♂', 'src': 'https://vk.com/emoji/e/f09fa799e2808de29982'},
  {'emoji': '🧟', 'src': 'https://vk.com/emoji/e/f09fa79f'},
  {'emoji': '🧞‍♂', 'src': 'https://vk.com/emoji/e/f09fa79ee2808de29982'},
  {'emoji': '🧞‍♀', 'src': 'https://vk.com/emoji/e/f09fa79ee2808de29980'},
  {'emoji': '🧞', 'src': 'https://vk.com/emoji/e/f09fa79e'},
  {'emoji': '🧝‍♂', 'src': 'https://vk.com/emoji/e/f09fa79de2808de29982'},
  {'emoji': '🧝‍♀', 'src': 'https://vk.com/emoji/e/f09fa79de2808de29980'},
  {'emoji': '🧝', 'src': 'https://vk.com/emoji/e/f09fa79d'},
  {'emoji': '🧜‍♂', 'src': 'https://vk.com/emoji/e/f09fa79ce2808de29982'},
  {'emoji': '🧜‍♀', 'src': 'https://vk.com/emoji/e/f09fa79ce2808de29980'},
  {'emoji': '🧜', 'src': 'https://vk.com/emoji/e/f09fa79c'},
  {'emoji': '🧛‍♂', 'src': 'https://vk.com/emoji/e/f09fa79be2808de29982'},
  {'emoji': '🧛‍♀', 'src': 'https://vk.com/emoji/e/f09fa79be2808de29980'},
  {'emoji': '🧛', 'src': 'https://vk.com/emoji/e/f09fa79b'},
  {'emoji': '🧚‍♂', 'src': 'https://vk.com/emoji/e/f09fa79ae2808de29982'},
  {'emoji': '🧚‍♀', 'src': 'https://vk.com/emoji/e/f09fa79ae2808de29980'},
  {'emoji': '🧖', 'src': 'https://vk.com/emoji/e/f09fa796'},
  {'emoji': '🧖‍♀', 'src': 'https://vk.com/emoji/e/f09fa796e2808de29980'},
  {'emoji': '🧖‍♂', 'src': 'https://vk.com/emoji/e/f09fa796e2808de29982'},
  {'emoji': '🧗', 'src': 'https://vk.com/emoji/e/f09fa797'},
  {'emoji': '🧗‍♀', 'src': 'https://vk.com/emoji/e/f09fa797e2808de29980'},
  {'emoji': '🧗‍♂', 'src': 'https://vk.com/emoji/e/f09fa797e2808de29982'},
  {'emoji': '🧘‍♀', 'src': 'https://vk.com/emoji/e/f09fa798e2808de29980'},
  {'emoji': '🧘‍♂', 'src': 'https://vk.com/emoji/e/f09fa798e2808de29982'},
  {'emoji': '🤟', 'src': 'https://vk.com/emoji/e/f09fa49f'},
  {'emoji': '🤲', 'src': 'https://vk.com/emoji/e/f09fa4b2'},
  {'emoji': '🧠', 'src': 'https://vk.com/emoji/e/f09fa7a0'},
  {'emoji': '🧣', 'src': 'https://vk.com/emoji/e/f09fa7a3'},
  {'emoji': '🧤', 'src': 'https://vk.com/emoji/e/f09fa7a4'},
  {'emoji': '🧥', 'src': 'https://vk.com/emoji/e/f09fa7a5'},
  {'emoji': '🧦', 'src': 'https://vk.com/emoji/e/f09fa7a6'},
  {'emoji': '🧢', 'src': 'https://vk.com/emoji/e/f09fa7a2'},
  {'emoji': '🇧🇧', 'src': 'https://vk.com/emoji/e/f09f87a7f09f87a7'},
  {'emoji': '🇧🇩', 'src': 'https://vk.com/emoji/e/f09f87a7f09f87a7'},
  {'emoji': '👨🏾', 'src': '/emoji/e/f09f91a8f09f8fbee2808de29a95'},
// Новые
  {'emoji': '👨🏾', 'src': 'https://vk.com/emoji/e/f09f91a8f09f8fbee2808de29a95'},
  {'emoji': '👨🏽', 'src': 'https://vk.com/emoji/e/f09f91a8f09f8fbde2808de29a95'},
  {'emoji': '👨🏼', 'src': 'https://vk.com/emoji/e/f09f91a8f09f8fbce2808de29a95'},
  {'emoji': '👨🏻', 'src': 'https://vk.com/emoji/e/f09f91a8f09f8fbbe2808de29a95'},
  {'emoji': '🤲🏿', 'src': 'https://vk.com/emoji/e/f09fa4b2f09f8fbf'},
  {'emoji': '🤲🏾', 'src': 'https://vk.com/emoji/e/f09fa4b2f09f8fbe'},
  {'emoji': '🤲🏽', 'src': 'https://vk.com/emoji/e/f09fa4b2f09f8fbd'},
  {'emoji': '🤲🏼', 'src': 'https://vk.com/emoji/e/f09fa4b2f09f8fbc'},
  {'emoji': '🤲🏻', 'src': 'https://vk.com/emoji/e/f09fa4b2f09f8fbb'},
  {'emoji': '🤟🏿', 'src': 'https://vk.com/emoji/e/f09fa49ff09f8fbf'},
  {'emoji': '🤟🏾', 'src': 'https://vk.com/emoji/e/f09fa49ff09f8fbe'},
  {'emoji': '🤟🏽', 'src': 'https://vk.com/emoji/e/f09fa49ff09f8fbd'},
  {'emoji': '🤟🏼', 'src': 'https://vk.com/emoji/e/f09fa49ff09f8fbc'},
  {'emoji': '🤟🏻', 'src': 'https://vk.com/emoji/e/f09fa49ff09f8fbb'},
  {'emoji': '🧘🏿♂', 'src': 'https://vk.com/emoji/e/f09fa798f09f8fbfe2808de29982'},
  {'emoji': '🧘🏾♂', 'src': 'https://vk.com/emoji/e/f09fa798f09f8fbee2808de29982'},
  {'emoji': '🧘🏽♂', 'src': 'https://vk.com/emoji/e/f09fa798f09f8fbde2808de29982'},
  {'emoji': '🧘🏼♂', 'src': 'https://vk.com/emoji/e/f09fa798f09f8fbce2808de29982'},
  {'emoji': '🧘🏻♂', 'src': 'https://vk.com/emoji/e/f09fa798f09f8fbbe2808de29982'},
  {'emoji': '🧘🏿♀', 'src': 'https://vk.com/emoji/e/f09fa798f09f8fbfe2808de29980'},
  {'emoji': '🧘🏾♀', 'src': 'https://vk.com/emoji/e/f09fa798f09f8fbee2808de29980'},
  {'emoji': '🧘🏽♀', 'src': 'https://vk.com/emoji/e/f09fa798f09f8fbde2808de29980'},
  {'emoji': '🧘🏼♀', 'src': 'https://vk.com/emoji/e/f09fa798f09f8fbce2808de29980'},
  {'emoji': '🧘🏻♀', 'src': 'https://vk.com/emoji/e/f09fa798f09f8fbbe2808de29980'},
  {'emoji': '🧘🏿', 'src': 'https://vk.com/emoji/e/f09fa798f09f8fbf'},
  {'emoji': '🧘🏾', 'src': 'https://vk.com/emoji/e/f09fa798f09f8fbe'},
  {'emoji': '🧘🏽', 'src': 'https://vk.com/emoji/e/f09fa798f09f8fbd'},
  {'emoji': '🧘🏼', 'src': 'https://vk.com/emoji/e/f09fa798f09f8fbc'},
  {'emoji': '🧘🏻', 'src': 'https://vk.com/emoji/e/f09fa798f09f8fbb'},
  {'emoji': '🧗🏿♂', 'src': 'https://vk.com/emoji/e/f09fa797f09f8fbfe2808de29982'},
  {'emoji': '🧗🏾♂', 'src': 'https://vk.com/emoji/e/f09fa797f09f8fbee2808de29982'},
  {'emoji': '🧗🏽♂', 'src': 'https://vk.com/emoji/e/f09fa797f09f8fbde2808de29982'},
  {'emoji': '🧗🏼♂', 'src': 'https://vk.com/emoji/e/f09fa797f09f8fbce2808de29982'},
  {'emoji': '🧗🏻♂', 'src': 'https://vk.com/emoji/e/f09fa797f09f8fbbe2808de29982'},
  {'emoji': '🧗🏿♀', 'src': 'https://vk.com/emoji/e/f09fa797f09f8fbfe2808de29980'},
  {'emoji': '🧗🏾♀', 'src': 'https://vk.com/emoji/e/f09fa797f09f8fbee2808de29980'},
  {'emoji': '🧗🏽♀', 'src': 'https://vk.com/emoji/e/f09fa797f09f8fbde2808de29980'},
  {'emoji': '🧗🏼♀', 'src': 'https://vk.com/emoji/e/f09fa797f09f8fbce2808de29980'},
  {'emoji': '🧗🏻♀', 'src': 'https://vk.com/emoji/e/f09fa797f09f8fbbe2808de29980'},
  {'emoji': '🧗🏿', 'src': 'https://vk.com/emoji/e/f09fa797f09f8fbf'},
  {'emoji': '🧗🏾', 'src': 'https://vk.com/emoji/e/f09fa797f09f8fbe'},
  {'emoji': '🧗🏽', 'src': 'https://vk.com/emoji/e/f09fa797f09f8fbd'},
  {'emoji': '🧗🏼', 'src': 'https://vk.com/emoji/e/f09fa797f09f8fbc'},
  {'emoji': '🧗🏻', 'src': 'https://vk.com/emoji/e/f09fa797f09f8fbb'},
  {'emoji': '🧖🏿♂', 'src': 'https://vk.com/emoji/e/f09fa796f09f8fbfe2808de29982'},
  {'emoji': '🧖🏾♂', 'src': 'https://vk.com/emoji/e/f09fa796f09f8fbee2808de29982'},
  {'emoji': '🧖🏽♂', 'src': 'https://vk.com/emoji/e/f09fa796f09f8fbde2808de29982'},
  {'emoji': '🧖🏼♂', 'src': 'https://vk.com/emoji/e/f09fa796f09f8fbce2808de29982'},
  {'emoji': '🧖🏻♂', 'src': 'https://vk.com/emoji/e/f09fa796f09f8fbbe2808de29982'},
  {'emoji': '🧖🏿♀', 'src': 'https://vk.com/emoji/e/f09fa796f09f8fbfe2808de29980'},
  {'emoji': '🧖🏾♀', 'src': 'https://vk.com/emoji/e/f09fa796f09f8fbee2808de29980'},
  {'emoji': '🧖🏽♀', 'src': 'https://vk.com/emoji/e/f09fa796f09f8fbde2808de29980'},
  {'emoji': '🧖🏼♀', 'src': 'https://vk.com/emoji/e/f09fa796f09f8fbce2808de29980'},
  {'emoji': '🧖🏻♀', 'src': 'https://vk.com/emoji/e/f09fa796f09f8fbbe2808de29980'},
  {'emoji': '🧖🏿', 'src': 'https://vk.com/emoji/e/f09fa796f09f8fbf'},
  {'emoji': '🧖🏾', 'src': 'https://vk.com/emoji/e/f09fa796f09f8fbe'},
  {'emoji': '🧖🏽', 'src': 'https://vk.com/emoji/e/f09fa796f09f8fbd'},
  {'emoji': '🧖🏼', 'src': 'https://vk.com/emoji/e/f09fa796f09f8fbc'},
  {'emoji': '🧖🏻', 'src': 'https://vk.com/emoji/e/f09fa796f09f8fbb'},
  {'emoji': '🧝🏿♂', 'src': 'https://vk.com/emoji/e/f09fa79df09f8fbfe2808de29982'},
  {'emoji': '🧝🏾♂', 'src': 'https://vk.com/emoji/e/f09fa79df09f8fbee2808de29982'},
  {'emoji': '🧝🏽♂', 'src': 'https://vk.com/emoji/e/f09fa79df09f8fbde2808de29982'},
  {'emoji': '🧝🏼♂', 'src': 'https://vk.com/emoji/e/f09fa79df09f8fbce2808de29982'},
  {'emoji': '🧝🏻♂', 'src': 'https://vk.com/emoji/e/f09fa79df09f8fbbe2808de29982'},
  {'emoji': '🧝🏿♀', 'src': 'https://vk.com/emoji/e/f09fa79df09f8fbfe2808de29980'},
  {'emoji': '🧝🏾♀', 'src': 'https://vk.com/emoji/e/f09fa79df09f8fbee2808de29980'},
  {'emoji': '🧝🏽♀', 'src': 'https://vk.com/emoji/e/f09fa79df09f8fbde2808de29980'},
  {'emoji': '🧝🏼♀', 'src': 'https://vk.com/emoji/e/f09fa79df09f8fbce2808de29980'},
  {'emoji': '🧝🏻♀', 'src': 'https://vk.com/emoji/e/f09fa79df09f8fbbe2808de29980'},
  {'emoji': '🧝🏿', 'src': 'https://vk.com/emoji/e/f09fa79df09f8fbf'},
  {'emoji': '🧝🏾', 'src': 'https://vk.com/emoji/e/f09fa79df09f8fbe'},
  {'emoji': '🧝🏽', 'src': 'https://vk.com/emoji/e/f09fa79df09f8fbd'},
  {'emoji': '🧝🏼', 'src': 'https://vk.com/emoji/e/f09fa79df09f8fbc'},
  {'emoji': '🧝🏻', 'src': 'https://vk.com/emoji/e/f09fa79df09f8fbb'},
  {'emoji': '🧜🏿♂', 'src': 'https://vk.com/emoji/e/f09fa79cf09f8fbfe2808de29982'},
  {'emoji': '🧜🏾♂', 'src': 'https://vk.com/emoji/e/f09fa79cf09f8fbee2808de29982'},
  {'emoji': '🧜🏽♂', 'src': 'https://vk.com/emoji/e/f09fa79cf09f8fbde2808de29982'},
  {'emoji': '🧜🏼♂', 'src': 'https://vk.com/emoji/e/f09fa79cf09f8fbce2808de29982'},
  {'emoji': '🧜🏻♂', 'src': 'https://vk.com/emoji/e/f09fa79cf09f8fbbe2808de29982'},
  {'emoji': '🧜🏿♀', 'src': 'https://vk.com/emoji/e/f09fa79cf09f8fbfe2808de29980'},
  {'emoji': '🧜🏾♀', 'src': 'https://vk.com/emoji/e/f09fa79cf09f8fbee2808de29980'},
  {'emoji': '🧜🏽♀', 'src': 'https://vk.com/emoji/e/f09fa79cf09f8fbde2808de29980'},
  {'emoji': '🧜🏼♀', 'src': 'https://vk.com/emoji/e/f09fa79cf09f8fbce2808de29980'},
  {'emoji': '🧜🏻♀', 'src': 'https://vk.com/emoji/e/f09fa79cf09f8fbbe2808de29980'},
  {'emoji': '🧜🏿', 'src': 'https://vk.com/emoji/e/f09fa79cf09f8fbf'},
  {'emoji': '🧜🏾', 'src': 'https://vk.com/emoji/e/f09fa79cf09f8fbe'},
  {'emoji': '🧜🏽', 'src': 'https://vk.com/emoji/e/f09fa79cf09f8fbd'},
  {'emoji': '🧜🏼', 'src': 'https://vk.com/emoji/e/f09fa79cf09f8fbc'},
  {'emoji': '🧜🏻', 'src': 'https://vk.com/emoji/e/f09fa79cf09f8fbb'},
  {'emoji': '🧛🏿♂', 'src': 'https://vk.com/emoji/e/f09fa79bf09f8fbfe2808de29982'},
  {'emoji': '🧛🏾♂', 'src': 'https://vk.com/emoji/e/f09fa79bf09f8fbee2808de29982'},
  {'emoji': '🧛🏽♂', 'src': 'https://vk.com/emoji/e/f09fa79bf09f8fbde2808de29982'},
  {'emoji': '🧛🏼♂', 'src': 'https://vk.com/emoji/e/f09fa79bf09f8fbce2808de29982'},
  {'emoji': '🧛🏻♂', 'src': 'https://vk.com/emoji/e/f09fa79bf09f8fbbe2808de29982'},
  {'emoji': '🧛🏿♀', 'src': 'https://vk.com/emoji/e/f09fa79bf09f8fbfe2808de29980'},
  {'emoji': '🧛🏾♀', 'src': 'https://vk.com/emoji/e/f09fa79bf09f8fbee2808de29980'},
  {'emoji': '🧛🏽♀', 'src': 'https://vk.com/emoji/e/f09fa79bf09f8fbde2808de29980'},
  {'emoji': '🧛🏼♀', 'src': 'https://vk.com/emoji/e/f09fa79bf09f8fbce2808de29980'},
  {'emoji': '🧛🏻♀', 'src': 'https://vk.com/emoji/e/f09fa79bf09f8fbbe2808de29980'},
  {'emoji': '🧛🏿', 'src': 'https://vk.com/emoji/e/f09fa79bf09f8fbf'},
  {'emoji': '🧛🏾', 'src': 'https://vk.com/emoji/e/f09fa79bf09f8fbe'},
  {'emoji': '🧛🏽', 'src': 'https://vk.com/emoji/e/f09fa79bf09f8fbd'},
  {'emoji': '🧛🏼', 'src': 'https://vk.com/emoji/e/f09fa79bf09f8fbc'},
  {'emoji': '🧛🏻', 'src': 'https://vk.com/emoji/e/f09fa79bf09f8fbb'},
  {'emoji': '🧚🏿♂', 'src': 'https://vk.com/emoji/e/f09fa79af09f8fbfe2808de29982'},
  {'emoji': '🧚🏾♂', 'src': 'https://vk.com/emoji/e/f09fa79af09f8fbee2808de29982'},
  {'emoji': '🧚🏽♂', 'src': 'https://vk.com/emoji/e/f09fa79af09f8fbde2808de29982'},
  {'emoji': '🧚🏼♂', 'src': 'https://vk.com/emoji/e/f09fa79af09f8fbce2808de29982'},
  {'emoji': '🧚🏻♂', 'src': 'https://vk.com/emoji/e/f09fa79af09f8fbbe2808de29982'},
  {'emoji': '🧚🏿♀', 'src': 'https://vk.com/emoji/e/f09fa79af09f8fbfe2808de29980'},
  {'emoji': '🧚🏾♀', 'src': 'https://vk.com/emoji/e/f09fa79af09f8fbee2808de29980'},
  {'emoji': '🧚🏽♀', 'src': 'https://vk.com/emoji/e/f09fa79af09f8fbde2808de29980'},
  {'emoji': '🧚🏼♀', 'src': 'https://vk.com/emoji/e/f09fa79af09f8fbce2808de29980'},
  {'emoji': '🤶🏾', 'src': 'https://vk.com/emoji/e/f09fa4b6f09f8fbe'},
  {'emoji': '🤶🏽', 'src': 'https://vk.com/emoji/e/f09fa4b6f09f8fbd'},
  {'emoji': '🤶🏼', 'src': 'https://vk.com/emoji/e/f09fa4b6f09f8fbc'},
  {'emoji': '🤶🏻', 'src': 'https://vk.com/emoji/e/f09fa4b6f09f8fbb'},
  {'emoji': '🤰🏿', 'src': 'https://vk.com/emoji/e/f09fa4b0f09f8fbf'},
  {'emoji': '🤰🏾', 'src': 'https://vk.com/emoji/e/f09fa4b0f09f8fbe'},
  {'emoji': '🤰🏽', 'src': 'https://vk.com/emoji/e/f09fa4b0f09f8fbd'},
  {'emoji': '🤰🏼', 'src': 'https://vk.com/emoji/e/f09fa4b0f09f8fbc'},
  {'emoji': '🤰🏻', 'src': 'https://vk.com/emoji/e/f09fa4b0f09f8fbb'},
  {'emoji': '🤵🏿', 'src': 'https://vk.com/emoji/e/f09fa4b5f09f8fbf'},
  {'emoji': '🤵🏾', 'src': 'https://vk.com/emoji/e/f09fa4b5f09f8fbe'},
  {'emoji': '🤵🏽', 'src': 'https://vk.com/emoji/e/f09fa4b5f09f8fbd'},
  {'emoji': '🤵🏼', 'src': 'https://vk.com/emoji/e/f09fa4b5f09f8fbc'},
  {'emoji': '🤵🏻', 'src': 'https://vk.com/emoji/e/f09fa4b5f09f8fbb'},
  {'emoji': '👱🏿♀', 'src': 'https://vk.com/emoji/e/f09f91b1f09f8fbfe2808de29980'},
  {'emoji': '👱🏾♀', 'src': 'https://vk.com/emoji/e/f09f91b1f09f8fbee2808de29980'},
  {'emoji': '👱🏽♀', 'src': 'https://vk.com/emoji/e/f09f91b1f09f8fbde2808de29980'},
  {'emoji': '👱🏼♀', 'src': 'https://vk.com/emoji/e/f09f91b1f09f8fbce2808de29980'},
  {'emoji': '👱🏻♀', 'src': 'https://vk.com/emoji/e/f09f91b1f09f8fbbe2808de29980'},
  {'emoji': '👱🏿♂', 'src': 'https://vk.com/emoji/e/f09f91b1f09f8fbfe2808de29982'},
  {'emoji': '👱🏾♂', 'src': 'https://vk.com/emoji/e/f09f91b1f09f8fbee2808de29982'},
  {'emoji': '👱🏽♂', 'src': 'https://vk.com/emoji/e/f09f91b1f09f8fbde2808de29982'},
  {'emoji': '👱🏼♂', 'src': 'https://vk.com/emoji/e/f09f91b1f09f8fbce2808de29982'},
  {'emoji': '👱🏻♂', 'src': 'https://vk.com/emoji/e/f09f91b1f09f8fbbe2808de29982'},
  {'emoji': '👳🏿♀', 'src': 'https://vk.com/emoji/e/f09f91b3f09f8fbfe2808de29980'},
  {'emoji': '👳🏾♀', 'src': 'https://vk.com/emoji/e/f09f91b3f09f8fbee2808de29980'},
  {'emoji': '👳🏽♀', 'src': 'https://vk.com/emoji/e/f09f91b3f09f8fbde2808de29980'},
  {'emoji': '👳🏼♀', 'src': 'https://vk.com/emoji/e/f09f91b3f09f8fbce2808de29980'},
  {'emoji': '👳🏻♀', 'src': 'https://vk.com/emoji/e/f09f91b3f09f8fbbe2808de29980'},
  {'emoji': '👳🏿♂', 'src': 'https://vk.com/emoji/e/f09f91b3f09f8fbfe2808de29982'},
  {'emoji': '👳🏾♂', 'src': 'https://vk.com/emoji/e/f09f91b3f09f8fbee2808de29982'},
  {'emoji': '👳🏽♂', 'src': 'https://vk.com/emoji/e/f09f91b3f09f8fbde2808de29982'},
  {'emoji': '👳🏼♂', 'src': 'https://vk.com/emoji/e/f09f91b3f09f8fbce2808de29982'},
  {'emoji': '👳🏻♂', 'src': 'https://vk.com/emoji/e/f09f91b3f09f8fbbe2808de29982'},
  {'emoji': '🤴🏿', 'src': 'https://vk.com/emoji/e/f09fa4b4f09f8fbf'},
  {'emoji': '🤴🏾', 'src': 'https://vk.com/emoji/e/f09fa4b4f09f8fbe'},
  {'emoji': '🤴🏼', 'src': 'https://vk.com/emoji/e/f09fa4b4f09f8fbc'},
  {'emoji': '🤴🏻', 'src': 'https://vk.com/emoji/e/f09fa4b4f09f8fbb'},
  {'emoji': '👷🏿♀', 'src': 'https://vk.com/emoji/e/f09f91b7f09f8fbfe2808de29980'},
  {'emoji': '👷🏾♀', 'src': 'https://vk.com/emoji/e/f09f91b7f09f8fbee2808de29980'},
  {'emoji': '👷🏽♀', 'src': 'https://vk.com/emoji/e/f09f91b7f09f8fbde2808de29980'},
  {'emoji': '👷🏼♀', 'src': 'https://vk.com/emoji/e/f09f91b7f09f8fbce2808de29980'},
  {'emoji': '👷🏻♀', 'src': 'https://vk.com/emoji/e/f09f91b7f09f8fbbe2808de29980'},
  {'emoji': '👷🏿♂', 'src': 'https://vk.com/emoji/e/f09f91b7f09f8fbfe2808de29982'},
  {'emoji': '👷🏾♂', 'src': 'https://vk.com/emoji/e/f09f91b7f09f8fbee2808de29982'},
  {'emoji': '👷🏽♂', 'src': 'https://vk.com/emoji/e/f09f91b7f09f8fbde2808de29982'},
  {'emoji': '👷🏼♂', 'src': 'https://vk.com/emoji/e/f09f91b7f09f8fbce2808de29982'},
  {'emoji': '👷🏻♂', 'src': 'https://vk.com/emoji/e/f09f91b7f09f8fbbe2808de29982'},
  {'emoji': '💂🏿♀', 'src': 'https://vk.com/emoji/e/f09f9282f09f8fbfe2808de29980'},
  {'emoji': '💂🏾♀', 'src': 'https://vk.com/emoji/e/f09f9282f09f8fbee2808de29980'},
  {'emoji': '💂🏽♀', 'src': 'https://vk.com/emoji/e/f09f9282f09f8fbde2808de29980'},
  {'emoji': '💂🏼♀', 'src': 'https://vk.com/emoji/e/f09f9282f09f8fbce2808de29980'},
  {'emoji': '💂🏻♀', 'src': 'https://vk.com/emoji/e/f09f9282f09f8fbbe2808de29980'},
  {'emoji': '💂🏿♂', 'src': 'https://vk.com/emoji/e/f09f9282f09f8fbfe2808de29982'},
  {'emoji': '💂🏾♂', 'src': 'https://vk.com/emoji/e/f09f9282f09f8fbee2808de29982'},
  {'emoji': '💂🏽♂', 'src': 'https://vk.com/emoji/e/f09f9282f09f8fbde2808de29982'},
  {'emoji': '💂🏼♂', 'src': 'https://vk.com/emoji/e/f09f9282f09f8fbce2808de29982'},
  {'emoji': '💂🏻♂', 'src': 'https://vk.com/emoji/e/f09f9282f09f8fbbe2808de29982'},
  {'emoji': '🕵🏿♀', 'src': 'https://vk.com/emoji/e/f09f95b5f09f8fbfe2808de29980'},
  {'emoji': '🕵🏾♀', 'src': 'https://vk.com/emoji/e/f09f95b5f09f8fbee2808de29980'},
  {'emoji': '🕵🏽♀', 'src': 'https://vk.com/emoji/e/f09f95b5f09f8fbde2808de29980'},
  {'emoji': '🕵🏼♀', 'src': 'https://vk.com/emoji/e/f09f95b5f09f8fbce2808de29980'},
  {'emoji': '🕵🏻♀', 'src': 'https://vk.com/emoji/e/f09f95b5f09f8fbbe2808de29980'},
  {'emoji': '🕵🏿♂', 'src': 'https://vk.com/emoji/e/f09f95b5f09f8fbfe2808de29982'},
  {'emoji': '🕵🏾♂', 'src': 'https://vk.com/emoji/e/f09f95b5f09f8fbee2808de29982'},
  {'emoji': '🕵🏽♂', 'src': 'https://vk.com/emoji/e/f09f95b5f09f8fbde2808de29982'},
  {'emoji': '🕵🏼♂', 'src': 'https://vk.com/emoji/e/f09f95b5f09f8fbce2808de29982'},
  {'emoji': '🕵🏻♂', 'src': 'https://vk.com/emoji/e/f09f95b5f09f8fbbe2808de29982'},
  {'emoji': '🕵🏿', 'src': 'https://vk.com/emoji/e/f09f95b5f09f8fbf'},
  {'emoji': '🕵🏾', 'src': 'https://vk.com/emoji/e/f09f95b5f09f8fbe'},
  {'emoji': '🕵🏽', 'src': 'https://vk.com/emoji/e/f09f95b5f09f8fbd'},
  {'emoji': '🕵🏼', 'src': 'https://vk.com/emoji/e/f09f95b5f09f8fbc'},
  {'emoji': '🕵🏻', 'src': 'https://vk.com/emoji/e/f09f95b5f09f8fbb'},
  {'emoji': '👮🏿♀', 'src': 'https://vk.com/emoji/e/f09f91aef09f8fbfe2808de29980'},
  {'emoji': '👮🏾♀', 'src': 'https://vk.com/emoji/e/f09f91aef09f8fbee2808de29980'},
  {'emoji': '👮🏽♀', 'src': 'https://vk.com/emoji/e/f09f91aef09f8fbde2808de29980'},
  {'emoji': '👮🏼♀', 'src': 'https://vk.com/emoji/e/f09f91aef09f8fbce2808de29980'},
  {'emoji': '👮🏻♀', 'src': 'https://vk.com/emoji/e/f09f91aef09f8fbbe2808de29980'},
  {'emoji': '👮🏿♂', 'src': 'https://vk.com/emoji/e/f09f91aef09f8fbfe2808de29982'},
  {'emoji': '👮🏾♂', 'src': 'https://vk.com/emoji/e/f09f91aef09f8fbee2808de29982'},
  {'emoji': '👮🏽♂', 'src': 'https://vk.com/emoji/e/f09f91aef09f8fbde2808de29982'},
  {'emoji': '👮🏼♂', 'src': 'https://vk.com/emoji/e/f09f91aef09f8fbce2808de29982'},
  {'emoji': '👮🏻♂', 'src': 'https://vk.com/emoji/e/f09f91aef09f8fbbe2808de29982'},
  {'emoji': '👩🏿🚒', 'src': 'https://vk.com/emoji/e/f09f91a9f09f8fbfe2808df09f9a92'},
  {'emoji': '👩🏾🚒', 'src': 'https://vk.com/emoji/e/f09f91a9f09f8fbee2808df09f9a92'},
  {'emoji': '👩🏽🚒', 'src': 'https://vk.com/emoji/e/f09f91a9f09f8fbde2808df09f9a92'},
  {'emoji': '👩🏼🚒', 'src': 'https://vk.com/emoji/e/f09f91a9f09f8fbce2808df09f9a92'},
  {'emoji': '👩🏻🚒', 'src': 'https://vk.com/emoji/e/f09f91a9f09f8fbbe2808df09f9a92'},
  {'emoji': '👨🏿🚒', 'src': 'https://vk.com/emoji/e/f09f91a8f09f8fbfe2808df09f9a92'},
  {'emoji': '👩🏻✈', 'src': 'https://vk.com/emoji/e/f09f91a9f09f8fbbe2808de29c88'},
  {'emoji': '👨🏿✈', 'src': 'https://vk.com/emoji/e/f09f91a8f09f8fbfe2808de29c88'},
  {'emoji': '👨🏾✈', 'src': 'https://vk.com/emoji/e/f09f91a8f09f8fbee2808de29c88'},
  {'emoji': '👨🏽✈', 'src': 'https://vk.com/emoji/e/f09f91a8f09f8fbde2808de29c88'},
  {'emoji': '👨🏼✈', 'src': 'https://vk.com/emoji/e/f09f91a8f09f8fbce2808de29c88'},
  {'emoji': '👨🏻✈', 'src': 'https://vk.com/emoji/e/f09f91a8f09f8fbbe2808de29c88'},
  {'emoji': '👩🏿🎨', 'src': 'https://vk.com/emoji/e/f09f91a9f09f8fbfe2808df09f8ea8'},
  {'emoji': '👩🏾🎨', 'src': 'https://vk.com/emoji/e/f09f91a9f09f8fbee2808df09f8ea8'},
  {'emoji': '👩🏻🎨', 'src': 'https://vk.com/emoji/e/f09f91a9f09f8fbbe2808df09f8ea8'},
  {'emoji': '👨🏿🎨', 'src': 'https://vk.com/emoji/e/f09f91a8f09f8fbfe2808df09f8ea8'},
  {'emoji': '👨🏾🎨', 'src': 'https://vk.com/emoji/e/f09f91a8f09f8fbee2808df09f8ea8'},
  {'emoji': '👨🏽🎨', 'src': 'https://vk.com/emoji/e/f09f91a8f09f8fbde2808df09f8ea8'},
  {'emoji': '👨🏼🎨', 'src': 'https://vk.com/emoji/e/f09f91a8f09f8fbce2808df09f8ea8'},
  {'emoji': '👨🏻🎨', 'src': 'https://vk.com/emoji/e/f09f91a8f09f8fbbe2808df09f8ea8'},
  {'emoji': '👩🏿🎤', 'src': 'https://vk.com/emoji/e/f09f91a9f09f8fbfe2808df09f8ea4'},
  {'emoji': '👩🏾🎤', 'src': 'https://vk.com/emoji/e/f09f91a9f09f8fbee2808df09f8ea4'},
  {'emoji': '👩🏽🎤', 'src': 'https://vk.com/emoji/e/f09f91a9f09f8fbde2808df09f8ea4'},
  {'emoji': '👩🏼🎤', 'src': 'https://vk.com/emoji/e/f09f91a9f09f8fbce2808df09f8ea4'},
  {'emoji': '👩🏻🎤', 'src': 'https://vk.com/emoji/e/f09f91a9f09f8fbbe2808df09f8ea4'},
  {'emoji': '👨🏿🎤', 'src': 'https://vk.com/emoji/e/f09f91a8f09f8fbfe2808df09f8ea4'},
  {'emoji': '👨🏾🎤', 'src': 'https://vk.com/emoji/e/f09f91a8f09f8fbee2808df09f8ea4'},
  {'emoji': '👨🏽🎤', 'src': 'https://vk.com/emoji/e/f09f91a8f09f8fbde2808df09f8ea4'},
  {'emoji': '👨🏼🎤', 'src': 'https://vk.com/emoji/e/f09f91a8f09f8fbce2808df09f8ea4'},
  {'emoji': '👨🏻🎤', 'src': 'https://vk.com/emoji/e/f09f91a8f09f8fbbe2808df09f8ea4'},
  {'emoji': '👩🏿💻', 'src': 'https://vk.com/emoji/e/f09f91a9f09f8fbfe2808df09f92bb'},
  {'emoji': '👩🏾💻', 'src': 'https://vk.com/emoji/e/f09f91a9f09f8fbee2808df09f92bb'},
  {'emoji': '👩🏽💻', 'src': 'https://vk.com/emoji/e/f09f91a9f09f8fbde2808df09f92bb'},
  {'emoji': '👩🏼💻', 'src': 'https://vk.com/emoji/e/f09f91a9f09f8fbce2808df09f92bb'},
  {'emoji': '👩🏻💻', 'src': 'https://vk.com/emoji/e/f09f91a9f09f8fbbe2808df09f92bb'},
  {'emoji': '👨🏿💻', 'src': 'https://vk.com/emoji/e/f09f91a8f09f8fbfe2808df09f92bb'},
  {'emoji': '👨🏾💻', 'src': 'https://vk.com/emoji/e/f09f91a8f09f8fbee2808df09f92bb'},
  {'emoji': '👨🏽💻', 'src': 'https://vk.com/emoji/e/f09f91a8f09f8fbde2808df09f92bb'},
  {'emoji': '👨🏼💻', 'src': 'https://vk.com/emoji/e/f09f91a8f09f8fbce2808df09f92bb'},
  {'emoji': '👨🏻💻', 'src': 'https://vk.com/emoji/e/f09f91a8f09f8fbbe2808df09f92bb'},
  {'emoji': '👩🏿🔬', 'src': 'https://vk.com/emoji/e/f09f91a9f09f8fbfe2808df09f94ac'},
  {'emoji': '👩🏾🔬', 'src': 'https://vk.com/emoji/e/f09f91a9f09f8fbee2808df09f94ac'},
  {'emoji': '👩🏽🔬', 'src': 'https://vk.com/emoji/e/f09f91a9f09f8fbde2808df09f94ac'},
  {'emoji': '👩🏼🔬', 'src': 'https://vk.com/emoji/e/f09f91a9f09f8fbce2808df09f94ac'},
  {'emoji': '👩🏻🔬', 'src': 'https://vk.com/emoji/e/f09f91a9f09f8fbbe2808df09f94ac'},
  {'emoji': '👨🏿🔬', 'src': 'https://vk.com/emoji/e/f09f91a8f09f8fbfe2808df09f94ac'},
  {'emoji': '👨🏾🔬', 'src': 'https://vk.com/emoji/e/f09f91a8f09f8fbee2808df09f94ac'},
  {'emoji': '👨🏽🔬', 'src': 'https://vk.com/emoji/e/f09f91a8f09f8fbde2808df09f94ac'},
  {'emoji': '👨🏼🔬', 'src': 'https://vk.com/emoji/e/f09f91a8f09f8fbce2808df09f94ac'},
  {'emoji': '👨🏻🔬', 'src': 'https://vk.com/emoji/e/f09f91a8f09f8fbbe2808df09f94ac'},
  {'emoji': '👩🏿💼', 'src': 'https://vk.com/emoji/e/f09f91a9f09f8fbfe2808df09f92bc'},
  {'emoji': '👩🏾💼', 'src': 'https://vk.com/emoji/e/f09f91a9f09f8fbee2808df09f92bc'},
  {'emoji': '👩🏽💼', 'src': 'https://vk.com/emoji/e/f09f91a9f09f8fbde2808df09f92bc'},
  {'emoji': '👩🏼💼', 'src': 'https://vk.com/emoji/e/f09f91a9f09f8fbce2808df09f92bc'},
  {'emoji': '👩🏻💼', 'src': 'https://vk.com/emoji/e/f09f91a9f09f8fbbe2808df09f92bc'},
  {'emoji': '👨🏿💼', 'src': 'https://vk.com/emoji/e/f09f91a8f09f8fbfe2808df09f92bc'},
  {'emoji': '👨🏾💼', 'src': 'https://vk.com/emoji/e/f09f91a8f09f8fbee2808df09f92bc'},
  {'emoji': '👨🏽💼', 'src': 'https://vk.com/emoji/e/f09f91a8f09f8fbde2808df09f92bc'},
  {'emoji': '👨🏼💼', 'src': 'https://vk.com/emoji/e/f09f91a8f09f8fbce2808df09f92bc'},
  {'emoji': '👨🏻💼', 'src': 'https://vk.com/emoji/e/f09f91a8f09f8fbbe2808df09f92bc'},
  {'emoji': '👩🏿🏭', 'src': 'https://vk.com/emoji/e/f09f91a9f09f8fbfe2808df09f8fad'},
  {'emoji': '👩🏾🏭', 'src': 'https://vk.com/emoji/e/f09f91a9f09f8fbee2808df09f8fad'},
  {'emoji': '👩🏽🏭', 'src': 'https://vk.com/emoji/e/f09f91a9f09f8fbde2808df09f8fad'},
  {'emoji': '👩🏼🏭', 'src': 'https://vk.com/emoji/e/f09f91a9f09f8fbce2808df09f8fad'},
  {'emoji': '👩🏿🍳', 'src': 'https://vk.com/emoji/e/f09f91a9f09f8fbfe2808df09f8db3'},
  {'emoji': '👩🏾🍳', 'src': 'https://vk.com/emoji/e/f09f91a9f09f8fbee2808df09f8db3'},
  {'emoji': '👩🏽🍳', 'src': 'https://vk.com/emoji/e/f09f91a9f09f8fbde2808df09f8db3'},
  {'emoji': '👩🏼🍳', 'src': 'https://vk.com/emoji/e/f09f91a9f09f8fbce2808df09f8db3'},
  {'emoji': '👩🏻🍳', 'src': 'https://vk.com/emoji/e/f09f91a9f09f8fbbe2808df09f8db3'},
  {'emoji': '👨🏿🍳', 'src': 'https://vk.com/emoji/e/f09f91a8f09f8fbfe2808df09f8db3'},
  {'emoji': '👨🏾🍳', 'src': 'https://vk.com/emoji/e/f09f91a8f09f8fbee2808df09f8db3'},
  {'emoji': '👨🏽🍳', 'src': 'https://vk.com/emoji/e/f09f91a8f09f8fbde2808df09f8db3'},
  {'emoji': '👨🏼🍳', 'src': 'https://vk.com/emoji/e/f09f91a8f09f8fbce2808df09f8db3'},
  {'emoji': '👨🏻🍳', 'src': 'https://vk.com/emoji/e/f09f91a8f09f8fbbe2808df09f8db3'},
  {'emoji': '👩🏿🌾', 'src': 'https://vk.com/emoji/e/f09f91a9f09f8fbfe2808df09f8cbe'},
  {'emoji': '👩🏾🌾', 'src': 'https://vk.com/emoji/e/f09f91a9f09f8fbee2808df09f8cbe'},
  {'emoji': '👩🏽🌾', 'src': 'https://vk.com/emoji/e/f09f91a9f09f8fbde2808df09f8cbe'},
  {'emoji': '👩🏼🌾', 'src': 'https://vk.com/emoji/e/f09f91a9f09f8fbce2808df09f8cbe'},
  {'emoji': '👩🏻🌾', 'src': 'https://vk.com/emoji/e/f09f91a9f09f8fbbe2808df09f8cbe'},
  {'emoji': '👨🏿🌾', 'src': 'https://vk.com/emoji/e/f09f91a8f09f8fbfe2808df09f8cbe'},
  {'emoji': '👨🏾🌾', 'src': 'https://vk.com/emoji/e/f09f91a8f09f8fbee2808df09f8cbe'},
  {'emoji': '👨🏽🌾', 'src': 'https://vk.com/emoji/e/f09f91a8f09f8fbde2808df09f8cbe'},
  {'emoji': '👨🏼🌾', 'src': 'https://vk.com/emoji/e/f09f91a8f09f8fbce2808df09f8cbe'},
  {'emoji': '👨🏻🌾', 'src': 'https://vk.com/emoji/e/f09f91a8f09f8fbbe2808df09f8cbe'},
  {'emoji': '👩🏿⚖', 'src': 'https://vk.com/emoji/e/f09f91a9f09f8fbfe2808de29a96'},
  {'emoji': '👩🏾⚖', 'src': 'https://vk.com/emoji/e/f09f91a9f09f8fbee2808de29a96'},
  {'emoji': '👩🏽⚖', 'src': 'https://vk.com/emoji/e/f09f91a9f09f8fbde2808de29a96'},
  {'emoji': '👩🏼⚖', 'src': 'https://vk.com/emoji/e/f09f91a9f09f8fbce2808de29a96'},
  {'emoji': '👩🏻⚖', 'src': 'https://vk.com/emoji/e/f09f91a9f09f8fbbe2808de29a96'},
  {'emoji': '👨🏿⚖', 'src': 'https://vk.com/emoji/e/f09f91a8f09f8fbfe2808de29a96'},
  {'emoji': '👨🏾⚖', 'src': 'https://vk.com/emoji/e/f09f91a8f09f8fbee2808de29a96'},
  {'emoji': '👨🏽⚖', 'src': 'https://vk.com/emoji/e/f09f91a8f09f8fbde2808de29a96'},
  {'emoji': '👨🏼⚖', 'src': 'https://vk.com/emoji/e/f09f91a8f09f8fbce2808de29a96'},
  {'emoji': '👨🏻⚖', 'src': 'https://vk.com/emoji/e/f09f91a8f09f8fbbe2808de29a96'},
  {'emoji': '👩🏿🏫', 'src': 'https://vk.com/emoji/e/f09f91a9f09f8fbfe2808df09f8fab'},
  {'emoji': '👩🏾🏫', 'src': 'https://vk.com/emoji/e/f09f91a9f09f8fbee2808df09f8fab'},
  {'emoji': '👩🏽🏫', 'src': 'https://vk.com/emoji/e/f09f91a9f09f8fbde2808df09f8fab'},
  {'emoji': '👩🏼🏫', 'src': 'https://vk.com/emoji/e/f09f91a9f09f8fbce2808df09f8fab'},
  {'emoji': '👩🏻🏫', 'src': 'https://vk.com/emoji/e/f09f91a9f09f8fbbe2808df09f8fab'},
  {'emoji': '👨🏿🏫', 'src': 'https://vk.com/emoji/e/f09f91a8f09f8fbfe2808df09f8fab'},
  {'emoji': '👨🏾🏫', 'src': 'https://vk.com/emoji/e/f09f91a8f09f8fbee2808df09f8fab'},
  {'emoji': '👨🏽🏫', 'src': 'https://vk.com/emoji/e/f09f91a8f09f8fbde2808df09f8fab'},
  {'emoji': '👨🏼🏫', 'src': 'https://vk.com/emoji/e/f09f91a8f09f8fbce2808df09f8fab'},
  {'emoji': '👨🏻🏫', 'src': 'https://vk.com/emoji/e/f09f91a8f09f8fbbe2808df09f8fab'},
  {'emoji': '👩🏿🎓', 'src': 'https://vk.com/emoji/e/f09f91a9f09f8fbfe2808df09f8e93'},
  {'emoji': '👩🏾🎓', 'src': 'https://vk.com/emoji/e/f09f91a9f09f8fbee2808df09f8e93'},
  {'emoji': '👩🏽🎓', 'src': 'https://vk.com/emoji/e/f09f91a9f09f8fbde2808df09f8e93'},
  {'emoji': '👩🏼🎓', 'src': 'https://vk.com/emoji/e/f09f91a9f09f8fbce2808df09f8e93'},
  {'emoji': '👩🏻🎓', 'src': 'https://vk.com/emoji/e/f09f91a9f09f8fbbe2808df09f8e93'},
  {'emoji': '👨🏿🎓', 'src': 'https://vk.com/emoji/e/f09f91a8f09f8fbfe2808df09f8e93'},
  {'emoji': '👨🏾🎓', 'src': 'https://vk.com/emoji/e/f09f91a8f09f8fbee2808df09f8e93'},
  {'emoji': '👨🏽🎓', 'src': 'https://vk.com/emoji/e/f09f91a8f09f8fbde2808df09f8e93'},
  {'emoji': '👨🏼🎓', 'src': 'https://vk.com/emoji/e/f09f91a8f09f8fbce2808df09f8e93'},
  {'emoji': '👨🏻🎓', 'src': 'https://vk.com/emoji/e/f09f91a8f09f8fbbe2808df09f8e93'},
  {'emoji': '👩🏿', 'src': 'https://vk.com/emoji/e/f09f91a9f09f8fbfe2808de29a95'},
  {'emoji': '👩🏾', 'src': 'https://vk.com/emoji/e/f09f91a9f09f8fbee2808de29a95'},
  {'emoji': '👩🏽', 'src': 'https://vk.com/emoji/e/f09f91a9f09f8fbde2808de29a95'},
  {'emoji': '👩🏼', 'src': 'https://vk.com/emoji/e/f09f91a9f09f8fbce2808de29a95'},
  {'emoji': '👩🏻', 'src': 'https://vk.com/emoji/e/f09f91a9f09f8fbbe2808de29a95'},
  {'emoji': '👨🏿', 'src': 'https://vk.com/emoji/e/f09f91a8f09f8fbfe2808de29a95'},
  {'emoji': '🧙🏾♂', 'src': 'https://vk.com/emoji/e/f09fa799f09f8fbee2808de29982'},
  {'emoji': '🧙🏽♂', 'src': 'https://vk.com/emoji/e/f09fa799f09f8fbde2808de29982'},
  {'emoji': '🧙🏼♂', 'src': 'https://vk.com/emoji/e/f09fa799f09f8fbce2808de29982'},
  {'emoji': '🧙🏻♂', 'src': 'https://vk.com/emoji/e/f09fa799f09f8fbbe2808de29982'},
  {'emoji': '🧙🏿♀', 'src': 'https://vk.com/emoji/e/f09fa799f09f8fbfe2808de29980'},
  {'emoji': '🧙🏾♀', 'src': 'https://vk.com/emoji/e/f09fa799f09f8fbee2808de29980'},
  {'emoji': '🧙🏽♀', 'src': 'https://vk.com/emoji/e/f09fa799f09f8fbde2808de29980'},
  {'emoji': '🧙🏼♀', 'src': 'https://vk.com/emoji/e/f09fa799f09f8fbce2808de29980'},
  {'emoji': '🧙🏻♀', 'src': 'https://vk.com/emoji/e/f09fa799f09f8fbbe2808de29980'},
  {'emoji': '🧙🏿', 'src': 'https://vk.com/emoji/e/f09fa799f09f8fbf'},
  {'emoji': '🧙🏾', 'src': 'https://vk.com/emoji/e/f09fa799f09f8fbe'},
  {'emoji': '🧙🏽', 'src': 'https://vk.com/emoji/e/f09fa799f09f8fbd'},
  {'emoji': '🧙🏼', 'src': 'https://vk.com/emoji/e/f09fa799f09f8fbc'},
  {'emoji': '🧙🏻', 'src': 'https://vk.com/emoji/e/f09fa799f09f8fbb'},
  {'emoji': '🤱🏿', 'src': 'https://vk.com/emoji/e/f09fa4b1f09f8fbf'},
  {'emoji': '🤱🏾', 'src': 'https://vk.com/emoji/e/f09fa4b1f09f8fbe'},
  {'emoji': '🤱🏽', 'src': 'https://vk.com/emoji/e/f09fa4b1f09f8fbd'},
  {'emoji': '🤱🏼', 'src': 'https://vk.com/emoji/e/f09fa4b1f09f8fbc'},
  {'emoji': '🤱🏻', 'src': 'https://vk.com/emoji/e/f09fa4b1f09f8fbb'},
  {'emoji': '🧔🏿', 'src': 'https://vk.com/emoji/e/f09fa794f09f8fbf'},
  {'emoji': '🧔🏾', 'src': 'https://vk.com/emoji/e/f09fa794f09f8fbe'},
  {'emoji': '🧔🏽', 'src': 'https://vk.com/emoji/e/f09fa794f09f8fbd'},
  {'emoji': '🧔🏼', 'src': 'https://vk.com/emoji/e/f09fa794f09f8fbc'},
  {'emoji': '🧔🏻', 'src': 'https://vk.com/emoji/e/f09fa794f09f8fbb'},
  {'emoji': '🧕🏿', 'src': 'https://vk.com/emoji/e/f09fa795f09f8fbf'},
  {'emoji': '🧕🏾', 'src': 'https://vk.com/emoji/e/f09fa795f09f8fbe'},
  {'emoji': '🧕🏽', 'src': 'https://vk.com/emoji/e/f09fa795f09f8fbd'},
  {'emoji': '🧕🏼', 'src': 'https://vk.com/emoji/e/f09fa795f09f8fbc'},
  {'emoji': '🧕🏻', 'src': 'https://vk.com/emoji/e/f09fa795f09f8fbb'},
  {'emoji': '🧓🏿', 'src': 'https://vk.com/emoji/e/f09fa793f09f8fbf'},
  {'emoji': '🧓🏾', 'src': 'https://vk.com/emoji/e/f09fa793f09f8fbe'},
  {'emoji': '🧓🏽', 'src': 'https://vk.com/emoji/e/f09fa793f09f8fbd'},
  {'emoji': '🧓🏼', 'src': 'https://vk.com/emoji/e/f09fa793f09f8fbc'},
  {'emoji': '🧓🏻', 'src': 'https://vk.com/emoji/e/f09fa793f09f8fbb'},
  {'emoji': '🧑🏿', 'src': 'https://vk.com/emoji/e/f09fa791f09f8fbf'},
  {'emoji': '🧑🏾', 'src': 'https://vk.com/emoji/e/f09fa791f09f8fbe'},
  {'emoji': '🧑🏽', 'src': 'https://vk.com/emoji/e/f09fa791f09f8fbd'},
  {'emoji': '🧑🏼', 'src': 'https://vk.com/emoji/e/f09fa791f09f8fbc'},
  {'emoji': '🧑🏻', 'src': 'https://vk.com/emoji/e/f09fa791f09f8fbb'},
  {'emoji': '🧒🏿', 'src': 'https://vk.com/emoji/e/f09fa792f09f8fbf'},
  {'emoji': '🧒🏾', 'src': 'https://vk.com/emoji/e/f09fa792f09f8fbe'},
  {'emoji': '🧒🏽', 'src': 'https://vk.com/emoji/e/f09fa792f09f8fbd'},
  {'emoji': '🧒🏼', 'src': 'https://vk.com/emoji/e/f09fa792f09f8fbc'},
  {'emoji': '🧒🏻', 'src': 'https://vk.com/emoji/e/f09fa792f09f8fbb'},
  {'emoji': '⛹🏿', 'src': 'https://vk.com/emoji/e/e29bb9f09f8fbf'},
  {'emoji': '⛹🏾', 'src': 'https://vk.com/emoji/e/e29bb9f09f8fbe'},
  {'emoji': '⛹🏽', 'src': 'https://vk.com/emoji/e/e29bb9f09f8fbd'},
  {'emoji': '⛹🏼', 'src': 'https://vk.com/emoji/e/e29bb9f09f8fbc'},
  {'emoji': '⛹🏻', 'src': 'https://vk.com/emoji/e/e29bb9f09f8fbb'},
  {'emoji': '🏋🏿', 'src': 'https://vk.com/emoji/e/f09f8f8bf09f8fbf'},
  {'emoji': '🏋🏾', 'src': 'https://vk.com/emoji/e/f09f8f8bf09f8fbe'},
  {'emoji': '🏋🏽', 'src': 'https://vk.com/emoji/e/f09f8f8bf09f8fbd'},
  {'emoji': '🏋🏼', 'src': 'https://vk.com/emoji/e/f09f8f8bf09f8fbc'},
  {'emoji': '🏋🏻', 'src': 'https://vk.com/emoji/e/f09f8f8bf09f8fbb'},
  {'emoji': '👨🏽', 'src': '/emoji/e/f09f91a8f09f8fbde2808de29a95'},
  {'emoji': '👨🏼', 'src': '/emoji/e/f09f91a8f09f8fbce2808de29a95'},
  {'emoji': '👨🏻', 'src': '/emoji/e/f09f91a8f09f8fbbe2808de29a95'},
  {'emoji': '🤲🏿', 'src': '/emoji/e/f09fa4b2f09f8fbf'},
  {'emoji': '🤲🏾', 'src': '/emoji/e/f09fa4b2f09f8fbe'},
  {'emoji': '🤲🏽', 'src': '/emoji/e/f09fa4b2f09f8fbd'},
  {'emoji': '🤲🏼', 'src': '/emoji/e/f09fa4b2f09f8fbc'},
  {'emoji': '🤲🏻', 'src': '/emoji/e/f09fa4b2f09f8fbb'},
  {'emoji': '🤟🏿', 'src': '/emoji/e/f09fa49ff09f8fbf'},
  {'emoji': '🤟🏾', 'src': '/emoji/e/f09fa49ff09f8fbe'},
  {'emoji': '🤟🏽', 'src': '/emoji/e/f09fa49ff09f8fbd'},
  {'emoji': '🤟🏼', 'src': '/emoji/e/f09fa49ff09f8fbc'},
  {'emoji': '🤟🏻', 'src': '/emoji/e/f09fa49ff09f8fbb'},
  {'emoji': '🧘🏿&zwj;♂', 'src': '/emoji/e/f09fa798f09f8fbfe2808de29982'},
  {'emoji': '🧘🏾&zwj;♂', 'src': '/emoji/e/f09fa798f09f8fbee2808de29982'},
  {'emoji': '🧘🏽&zwj;♂', 'src': '/emoji/e/f09fa798f09f8fbde2808de29982'},
  {'emoji': '🧘🏼&zwj;♂', 'src': '/emoji/e/f09fa798f09f8fbce2808de29982'},
  {'emoji': '🧘🏻&zwj;♂', 'src': '/emoji/e/f09fa798f09f8fbbe2808de29982'},
  {'emoji': '🧘🏿&zwj;♀', 'src': '/emoji/e/f09fa798f09f8fbfe2808de29980'},
  {'emoji': '🧘🏾&zwj;♀', 'src': '/emoji/e/f09fa798f09f8fbee2808de29980'},
  {'emoji': '🧘🏽&zwj;♀', 'src': '/emoji/e/f09fa798f09f8fbde2808de29980'},
  {'emoji': '🧘🏼&zwj;♀', 'src': '/emoji/e/f09fa798f09f8fbce2808de29980'},
  {'emoji': '🧘🏻&zwj;♀', 'src': '/emoji/e/f09fa798f09f8fbbe2808de29980'},
  {'emoji': '🧘🏿', 'src': '/emoji/e/f09fa798f09f8fbf'},
  {'emoji': '🧘🏾', 'src': '/emoji/e/f09fa798f09f8fbe'},
  {'emoji': '🧘🏽', 'src': '/emoji/e/f09fa798f09f8fbd'},
  {'emoji': '🧘🏼', 'src': '/emoji/e/f09fa798f09f8fbc'},
  {'emoji': '🧘🏻', 'src': '/emoji/e/f09fa798f09f8fbb'},
  {'emoji': '🧗🏿&zwj;♂', 'src': '/emoji/e/f09fa797f09f8fbfe2808de29982'},
  {'emoji': '🧗🏾&zwj;♂', 'src': '/emoji/e/f09fa797f09f8fbee2808de29982'},
  {'emoji': '🧗🏽&zwj;♂', 'src': '/emoji/e/f09fa797f09f8fbde2808de29982'},
  {'emoji': '🧗🏼&zwj;♂', 'src': '/emoji/e/f09fa797f09f8fbce2808de29982'},
  {'emoji': '🧗🏻&zwj;♂', 'src': '/emoji/e/f09fa797f09f8fbbe2808de29982'},
  {'emoji': '🧗🏿&zwj;♀', 'src': '/emoji/e/f09fa797f09f8fbfe2808de29980'},
  {'emoji': '🧗🏾&zwj;♀', 'src': '/emoji/e/f09fa797f09f8fbee2808de29980'},
  {'emoji': '🧗🏽&zwj;♀', 'src': '/emoji/e/f09fa797f09f8fbde2808de29980'},
  {'emoji': '🧗🏼&zwj;♀', 'src': '/emoji/e/f09fa797f09f8fbce2808de29980'},
  {'emoji': '🧗🏻&zwj;♀', 'src': '/emoji/e/f09fa797f09f8fbbe2808de29980'},
  {'emoji': '🧗🏿', 'src': '/emoji/e/f09fa797f09f8fbf'},
  {'emoji': '🧗🏾', 'src': '/emoji/e/f09fa797f09f8fbe'},
  {'emoji': '🧗🏽', 'src': '/emoji/e/f09fa797f09f8fbd'},
  {'emoji': '🧗🏼', 'src': '/emoji/e/f09fa797f09f8fbc'},
  {'emoji': '🧗🏻', 'src': '/emoji/e/f09fa797f09f8fbb'},
  {'emoji': '🧖🏿&zwj;♂', 'src': '/emoji/e/f09fa796f09f8fbfe2808de29982'},
  {'emoji': '🧖🏾&zwj;♂', 'src': '/emoji/e/f09fa796f09f8fbee2808de29982'},
  {'emoji': '🧖🏽&zwj;♂', 'src': '/emoji/e/f09fa796f09f8fbde2808de29982'},
  {'emoji': '🧖🏼&zwj;♂', 'src': '/emoji/e/f09fa796f09f8fbce2808de29982'},
  {'emoji': '🧖🏻&zwj;♂', 'src': '/emoji/e/f09fa796f09f8fbbe2808de29982'},
  {'emoji': '🧖🏿&zwj;♀', 'src': '/emoji/e/f09fa796f09f8fbfe2808de29980'},
  {'emoji': '🧖🏾&zwj;♀', 'src': '/emoji/e/f09fa796f09f8fbee2808de29980'},
  {'emoji': '🧖🏽&zwj;♀', 'src': '/emoji/e/f09fa796f09f8fbde2808de29980'},
  {'emoji': '🧖🏼&zwj;♀', 'src': '/emoji/e/f09fa796f09f8fbce2808de29980'},
  {'emoji': '🧖🏻&zwj;♀', 'src': '/emoji/e/f09fa796f09f8fbbe2808de29980'},
  {'emoji': '🧖🏿', 'src': '/emoji/e/f09fa796f09f8fbf'},
  {'emoji': '🧖🏾', 'src': '/emoji/e/f09fa796f09f8fbe'},
  {'emoji': '🧖🏽', 'src': '/emoji/e/f09fa796f09f8fbd'},
  {'emoji': '🧖🏼', 'src': '/emoji/e/f09fa796f09f8fbc'},
  {'emoji': '🧖🏻', 'src': '/emoji/e/f09fa796f09f8fbb'},
  {'emoji': '🧝🏿&zwj;♂', 'src': '/emoji/e/f09fa79df09f8fbfe2808de29982'},
  {'emoji': '🧝🏾&zwj;♂', 'src': '/emoji/e/f09fa79df09f8fbee2808de29982'},
  {'emoji': '🧝🏽&zwj;♂', 'src': '/emoji/e/f09fa79df09f8fbde2808de29982'},
  {'emoji': '🧝🏼&zwj;♂', 'src': '/emoji/e/f09fa79df09f8fbce2808de29982'},
  {'emoji': '🧝🏻&zwj;♂', 'src': '/emoji/e/f09fa79df09f8fbbe2808de29982'},
  {'emoji': '🧝🏿&zwj;♀', 'src': '/emoji/e/f09fa79df09f8fbfe2808de29980'},
  {'emoji': '🧝🏾&zwj;♀', 'src': '/emoji/e/f09fa79df09f8fbee2808de29980'},
  {'emoji': '🧝🏽&zwj;♀', 'src': '/emoji/e/f09fa79df09f8fbde2808de29980'},
  {'emoji': '🧝🏼&zwj;♀', 'src': '/emoji/e/f09fa79df09f8fbce2808de29980'},
  {'emoji': '🧝🏻&zwj;♀', 'src': '/emoji/e/f09fa79df09f8fbbe2808de29980'},
  {'emoji': '🧝🏿', 'src': '/emoji/e/f09fa79df09f8fbf'},
  {'emoji': '🧝🏾', 'src': '/emoji/e/f09fa79df09f8fbe'},
  {'emoji': '🧝🏽', 'src': '/emoji/e/f09fa79df09f8fbd'},
  {'emoji': '🧝🏼', 'src': '/emoji/e/f09fa79df09f8fbc'},
  {'emoji': '🧝🏻', 'src': '/emoji/e/f09fa79df09f8fbb'},
  {'emoji': '🧜🏿&zwj;♂', 'src': '/emoji/e/f09fa79cf09f8fbfe2808de29982'},
  {'emoji': '🧜🏾&zwj;♂', 'src': '/emoji/e/f09fa79cf09f8fbee2808de29982'},
  {'emoji': '🧜🏽&zwj;♂', 'src': '/emoji/e/f09fa79cf09f8fbde2808de29982'},
  {'emoji': '🧜🏼&zwj;♂', 'src': '/emoji/e/f09fa79cf09f8fbce2808de29982'},
  {'emoji': '🧜🏻&zwj;♂', 'src': '/emoji/e/f09fa79cf09f8fbbe2808de29982'},
  {'emoji': '🧜🏿&zwj;♀', 'src': '/emoji/e/f09fa79cf09f8fbfe2808de29980'},
  {'emoji': '🧜🏾&zwj;♀', 'src': '/emoji/e/f09fa79cf09f8fbee2808de29980'},
  {'emoji': '🧜🏽&zwj;♀', 'src': '/emoji/e/f09fa79cf09f8fbde2808de29980'},
  {'emoji': '🧜🏼&zwj;♀', 'src': '/emoji/e/f09fa79cf09f8fbce2808de29980'},
  {'emoji': '🧜🏻&zwj;♀', 'src': '/emoji/e/f09fa79cf09f8fbbe2808de29980'},
  {'emoji': '🧜🏿', 'src': '/emoji/e/f09fa79cf09f8fbf'},
  {'emoji': '🧜🏾', 'src': '/emoji/e/f09fa79cf09f8fbe'},
  {'emoji': '🧜🏽', 'src': '/emoji/e/f09fa79cf09f8fbd'},
  {'emoji': '🧜🏼', 'src': '/emoji/e/f09fa79cf09f8fbc'},
  {'emoji': '🧜🏻', 'src': '/emoji/e/f09fa79cf09f8fbb'},
  {'emoji': '🧛🏿&zwj;♂', 'src': '/emoji/e/f09fa79bf09f8fbfe2808de29982'},
  {'emoji': '🧛🏾&zwj;♂', 'src': '/emoji/e/f09fa79bf09f8fbee2808de29982'},
  {'emoji': '🧛🏽&zwj;♂', 'src': '/emoji/e/f09fa79bf09f8fbde2808de29982'},
  {'emoji': '🧛🏼&zwj;♂', 'src': '/emoji/e/f09fa79bf09f8fbce2808de29982'},
  {'emoji': '🧛🏻&zwj;♂', 'src': '/emoji/e/f09fa79bf09f8fbbe2808de29982'},
  {'emoji': '🧛🏿&zwj;♀', 'src': '/emoji/e/f09fa79bf09f8fbfe2808de29980'},
  {'emoji': '🧛🏾&zwj;♀', 'src': '/emoji/e/f09fa79bf09f8fbee2808de29980'},
  {'emoji': '🧛🏽&zwj;♀', 'src': '/emoji/e/f09fa79bf09f8fbde2808de29980'},
  {'emoji': '🧛🏼&zwj;♀', 'src': '/emoji/e/f09fa79bf09f8fbce2808de29980'},
  {'emoji': '🧛🏻&zwj;♀', 'src': '/emoji/e/f09fa79bf09f8fbbe2808de29980'},
  {'emoji': '🧛🏿', 'src': '/emoji/e/f09fa79bf09f8fbf'},
  {'emoji': '🧛🏾', 'src': '/emoji/e/f09fa79bf09f8fbe'},
  {'emoji': '🧛🏽', 'src': '/emoji/e/f09fa79bf09f8fbd'},
  {'emoji': '🧛🏼', 'src': '/emoji/e/f09fa79bf09f8fbc'},
  {'emoji': '🧛🏻', 'src': '/emoji/e/f09fa79bf09f8fbb'},
  {'emoji': '🧚🏿&zwj;♂', 'src': '/emoji/e/f09fa79af09f8fbfe2808de29982'},
  {'emoji': '🧚🏾&zwj;♂', 'src': '/emoji/e/f09fa79af09f8fbee2808de29982'},
  {'emoji': '🧚🏽&zwj;♂', 'src': '/emoji/e/f09fa79af09f8fbde2808de29982'},
  {'emoji': '🧚🏼&zwj;♂', 'src': '/emoji/e/f09fa79af09f8fbce2808de29982'},
  {'emoji': '🧚🏻&zwj;♂', 'src': '/emoji/e/f09fa79af09f8fbbe2808de29982'},
  {'emoji': '🧚🏿&zwj;♀', 'src': '/emoji/e/f09fa79af09f8fbfe2808de29980'},
  {'emoji': '🧚🏾&zwj;♀', 'src': '/emoji/e/f09fa79af09f8fbee2808de29980'},
  {'emoji': '🧚🏽&zwj;♀', 'src': '/emoji/e/f09fa79af09f8fbde2808de29980'},
  {'emoji': '🧚🏼&zwj;♀', 'src': '/emoji/e/f09fa79af09f8fbce2808de29980'},
  {'emoji': '🤶🏾', 'src': '/emoji/e/f09fa4b6f09f8fbe'},
  {'emoji': '🤶🏽', 'src': '/emoji/e/f09fa4b6f09f8fbd'},
  {'emoji': '🤶🏼', 'src': '/emoji/e/f09fa4b6f09f8fbc'},
  {'emoji': '🤶🏻', 'src': '/emoji/e/f09fa4b6f09f8fbb'},
  {'emoji': '🤰🏿', 'src': '/emoji/e/f09fa4b0f09f8fbf'},
  {'emoji': '🤰🏾', 'src': '/emoji/e/f09fa4b0f09f8fbe'},
  {'emoji': '🤰🏽', 'src': '/emoji/e/f09fa4b0f09f8fbd'},
  {'emoji': '🤰🏼', 'src': '/emoji/e/f09fa4b0f09f8fbc'},
  {'emoji': '🤰🏻', 'src': '/emoji/e/f09fa4b0f09f8fbb'},
  {'emoji': '🤵🏿', 'src': '/emoji/e/f09fa4b5f09f8fbf'},
  {'emoji': '🤵🏾', 'src': '/emoji/e/f09fa4b5f09f8fbe'},
  {'emoji': '🤵🏽', 'src': '/emoji/e/f09fa4b5f09f8fbd'},
  {'emoji': '🤵🏼', 'src': '/emoji/e/f09fa4b5f09f8fbc'},
  {'emoji': '🤵🏻', 'src': '/emoji/e/f09fa4b5f09f8fbb'},
  {'emoji': '👱🏿&zwj;♀', 'src': '/emoji/e/f09f91b1f09f8fbfe2808de29980'},
  {'emoji': '👱🏾&zwj;♀', 'src': '/emoji/e/f09f91b1f09f8fbee2808de29980'},
  {'emoji': '👱🏽&zwj;♀', 'src': '/emoji/e/f09f91b1f09f8fbde2808de29980'},
  {'emoji': '👱🏼&zwj;♀', 'src': '/emoji/e/f09f91b1f09f8fbce2808de29980'},
  {'emoji': '👱🏻&zwj;♀', 'src': '/emoji/e/f09f91b1f09f8fbbe2808de29980'},
  {'emoji': '👱🏿&zwj;♂', 'src': '/emoji/e/f09f91b1f09f8fbfe2808de29982'},
  {'emoji': '👱🏾&zwj;♂', 'src': '/emoji/e/f09f91b1f09f8fbee2808de29982'},
  {'emoji': '👱🏽&zwj;♂', 'src': '/emoji/e/f09f91b1f09f8fbde2808de29982'},
  {'emoji': '👱🏼&zwj;♂', 'src': '/emoji/e/f09f91b1f09f8fbce2808de29982'},
  {'emoji': '👱🏻&zwj;♂', 'src': '/emoji/e/f09f91b1f09f8fbbe2808de29982'},
  {'emoji': '👳🏿&zwj;♀', 'src': '/emoji/e/f09f91b3f09f8fbfe2808de29980'},
  {'emoji': '👳🏾&zwj;♀', 'src': '/emoji/e/f09f91b3f09f8fbee2808de29980'},
  {'emoji': '👳🏽&zwj;♀', 'src': '/emoji/e/f09f91b3f09f8fbde2808de29980'},
  {'emoji': '👳🏼&zwj;♀', 'src': '/emoji/e/f09f91b3f09f8fbce2808de29980'},
  {'emoji': '👳🏻&zwj;♀', 'src': '/emoji/e/f09f91b3f09f8fbbe2808de29980'},
  {'emoji': '👳🏿&zwj;♂', 'src': '/emoji/e/f09f91b3f09f8fbfe2808de29982'},
  {'emoji': '👳🏾&zwj;♂', 'src': '/emoji/e/f09f91b3f09f8fbee2808de29982'},
  {'emoji': '👳🏽&zwj;♂', 'src': '/emoji/e/f09f91b3f09f8fbde2808de29982'},
  {'emoji': '👳🏼&zwj;♂', 'src': '/emoji/e/f09f91b3f09f8fbce2808de29982'},
  {'emoji': '👳🏻&zwj;♂', 'src': '/emoji/e/f09f91b3f09f8fbbe2808de29982'},
  {'emoji': '🤴🏿', 'src': '/emoji/e/f09fa4b4f09f8fbf'},
  {'emoji': '🤴🏾', 'src': '/emoji/e/f09fa4b4f09f8fbe'},
  {'emoji': '🤴🏼', 'src': '/emoji/e/f09fa4b4f09f8fbc'},
  {'emoji': '🤴🏻', 'src': '/emoji/e/f09fa4b4f09f8fbb'},
  {'emoji': '👷🏿&zwj;♀', 'src': '/emoji/e/f09f91b7f09f8fbfe2808de29980'},
  {'emoji': '👷🏾&zwj;♀', 'src': '/emoji/e/f09f91b7f09f8fbee2808de29980'},
  {'emoji': '👷🏽&zwj;♀', 'src': '/emoji/e/f09f91b7f09f8fbde2808de29980'},
  {'emoji': '👷🏼&zwj;♀', 'src': '/emoji/e/f09f91b7f09f8fbce2808de29980'},
  {'emoji': '👷🏻&zwj;♀', 'src': '/emoji/e/f09f91b7f09f8fbbe2808de29980'},
  {'emoji': '👷🏿&zwj;♂', 'src': '/emoji/e/f09f91b7f09f8fbfe2808de29982'},
  {'emoji': '👷🏾&zwj;♂', 'src': '/emoji/e/f09f91b7f09f8fbee2808de29982'},
  {'emoji': '👷🏽&zwj;♂', 'src': '/emoji/e/f09f91b7f09f8fbde2808de29982'},
  {'emoji': '👷🏼&zwj;♂', 'src': '/emoji/e/f09f91b7f09f8fbce2808de29982'},
  {'emoji': '👷🏻&zwj;♂', 'src': '/emoji/e/f09f91b7f09f8fbbe2808de29982'},
  {'emoji': '💂🏿&zwj;♀', 'src': '/emoji/e/f09f9282f09f8fbfe2808de29980'},
  {'emoji': '💂🏾&zwj;♀', 'src': '/emoji/e/f09f9282f09f8fbee2808de29980'},
  {'emoji': '💂🏽&zwj;♀', 'src': '/emoji/e/f09f9282f09f8fbde2808de29980'},
  {'emoji': '💂🏼&zwj;♀', 'src': '/emoji/e/f09f9282f09f8fbce2808de29980'},
  {'emoji': '💂🏻&zwj;♀', 'src': '/emoji/e/f09f9282f09f8fbbe2808de29980'},
  {'emoji': '💂🏿&zwj;♂', 'src': '/emoji/e/f09f9282f09f8fbfe2808de29982'},
  {'emoji': '💂🏾&zwj;♂', 'src': '/emoji/e/f09f9282f09f8fbee2808de29982'},
  {'emoji': '💂🏽&zwj;♂', 'src': '/emoji/e/f09f9282f09f8fbde2808de29982'},
  {'emoji': '💂🏼&zwj;♂', 'src': '/emoji/e/f09f9282f09f8fbce2808de29982'},
  {'emoji': '💂🏻&zwj;♂', 'src': '/emoji/e/f09f9282f09f8fbbe2808de29982'},
  {'emoji': '🕵🏿&zwj;♀', 'src': '/emoji/e/f09f95b5f09f8fbfe2808de29980'},
  {'emoji': '🕵🏾&zwj;♀', 'src': '/emoji/e/f09f95b5f09f8fbee2808de29980'},
  {'emoji': '🕵🏽&zwj;♀', 'src': '/emoji/e/f09f95b5f09f8fbde2808de29980'},
  {'emoji': '🕵🏼&zwj;♀', 'src': '/emoji/e/f09f95b5f09f8fbce2808de29980'},
  {'emoji': '🕵🏻&zwj;♀', 'src': '/emoji/e/f09f95b5f09f8fbbe2808de29980'},
  {'emoji': '🕵🏿&zwj;♂', 'src': '/emoji/e/f09f95b5f09f8fbfe2808de29982'},
  {'emoji': '🕵🏾&zwj;♂', 'src': '/emoji/e/f09f95b5f09f8fbee2808de29982'},
  {'emoji': '🕵🏽&zwj;♂', 'src': '/emoji/e/f09f95b5f09f8fbde2808de29982'},
  {'emoji': '🕵🏼&zwj;♂', 'src': '/emoji/e/f09f95b5f09f8fbce2808de29982'},
  {'emoji': '🕵🏻&zwj;♂', 'src': '/emoji/e/f09f95b5f09f8fbbe2808de29982'},
  {'emoji': '🕵🏿', 'src': '/emoji/e/f09f95b5f09f8fbf'},
  {'emoji': '🕵🏾', 'src': '/emoji/e/f09f95b5f09f8fbe'},
  {'emoji': '🕵🏽', 'src': '/emoji/e/f09f95b5f09f8fbd'},
  {'emoji': '🕵🏼', 'src': '/emoji/e/f09f95b5f09f8fbc'},
  {'emoji': '🕵🏻', 'src': '/emoji/e/f09f95b5f09f8fbb'},
  {'emoji': '👮🏿&zwj;♀', 'src': '/emoji/e/f09f91aef09f8fbfe2808de29980'},
  {'emoji': '👮🏾&zwj;♀', 'src': '/emoji/e/f09f91aef09f8fbee2808de29980'},
  {'emoji': '👮🏽&zwj;♀', 'src': '/emoji/e/f09f91aef09f8fbde2808de29980'},
  {'emoji': '👮🏼&zwj;♀', 'src': '/emoji/e/f09f91aef09f8fbce2808de29980'},
  {'emoji': '👮🏻&zwj;♀', 'src': '/emoji/e/f09f91aef09f8fbbe2808de29980'},
  {'emoji': '👮🏿&zwj;♂', 'src': '/emoji/e/f09f91aef09f8fbfe2808de29982'},
  {'emoji': '👮🏾&zwj;♂', 'src': '/emoji/e/f09f91aef09f8fbee2808de29982'},
  {'emoji': '👮🏽&zwj;♂', 'src': '/emoji/e/f09f91aef09f8fbde2808de29982'},
  {'emoji': '👮🏼&zwj;♂', 'src': '/emoji/e/f09f91aef09f8fbce2808de29982'},
  {'emoji': '👮🏻&zwj;♂', 'src': '/emoji/e/f09f91aef09f8fbbe2808de29982'},
  {'emoji': '👩🏿&zwj;🚒', 'src': '/emoji/e/f09f91a9f09f8fbfe2808df09f9a92'},
  {'emoji': '👩🏾&zwj;🚒', 'src': '/emoji/e/f09f91a9f09f8fbee2808df09f9a92'},
  {'emoji': '👩🏽&zwj;🚒', 'src': '/emoji/e/f09f91a9f09f8fbde2808df09f9a92'},
  {'emoji': '👩🏼&zwj;🚒', 'src': '/emoji/e/f09f91a9f09f8fbce2808df09f9a92'},
  {'emoji': '👩🏻&zwj;🚒', 'src': '/emoji/e/f09f91a9f09f8fbbe2808df09f9a92'},
  {'emoji': '👨🏿&zwj;🚒', 'src': '/emoji/e/f09f91a8f09f8fbfe2808df09f9a92'},
  {'emoji': '👩🏻&zwj;✈', 'src': '/emoji/e/f09f91a9f09f8fbbe2808de29c88'},
  {'emoji': '👨🏿&zwj;✈', 'src': '/emoji/e/f09f91a8f09f8fbfe2808de29c88'},
  {'emoji': '👨🏾&zwj;✈', 'src': '/emoji/e/f09f91a8f09f8fbee2808de29c88'},
  {'emoji': '👨🏽&zwj;✈', 'src': '/emoji/e/f09f91a8f09f8fbde2808de29c88'},
  {'emoji': '👨🏼&zwj;✈', 'src': '/emoji/e/f09f91a8f09f8fbce2808de29c88'},
  {'emoji': '👨🏻&zwj;✈', 'src': '/emoji/e/f09f91a8f09f8fbbe2808de29c88'},
  {'emoji': '👩🏿&zwj;🎨', 'src': '/emoji/e/f09f91a9f09f8fbfe2808df09f8ea8'},
  {'emoji': '👩🏾&zwj;🎨', 'src': '/emoji/e/f09f91a9f09f8fbee2808df09f8ea8'},
  {'emoji': '👩🏻&zwj;🎨', 'src': '/emoji/e/f09f91a9f09f8fbbe2808df09f8ea8'},
  {'emoji': '👨🏿&zwj;🎨', 'src': '/emoji/e/f09f91a8f09f8fbfe2808df09f8ea8'},
  {'emoji': '👨🏾&zwj;🎨', 'src': '/emoji/e/f09f91a8f09f8fbee2808df09f8ea8'},
  {'emoji': '👨🏽&zwj;🎨', 'src': '/emoji/e/f09f91a8f09f8fbde2808df09f8ea8'},
  {'emoji': '👨🏼&zwj;🎨', 'src': '/emoji/e/f09f91a8f09f8fbce2808df09f8ea8'},
  {'emoji': '👨🏻&zwj;🎨', 'src': '/emoji/e/f09f91a8f09f8fbbe2808df09f8ea8'},
  {'emoji': '👩🏿&zwj;🎤', 'src': '/emoji/e/f09f91a9f09f8fbfe2808df09f8ea4'},
  {'emoji': '👩🏾&zwj;🎤', 'src': '/emoji/e/f09f91a9f09f8fbee2808df09f8ea4'},
  {'emoji': '👩🏽&zwj;🎤', 'src': '/emoji/e/f09f91a9f09f8fbde2808df09f8ea4'},
  {'emoji': '👩🏼&zwj;🎤', 'src': '/emoji/e/f09f91a9f09f8fbce2808df09f8ea4'},
  {'emoji': '👩🏻&zwj;🎤', 'src': '/emoji/e/f09f91a9f09f8fbbe2808df09f8ea4'},
  {'emoji': '👨🏿&zwj;🎤', 'src': '/emoji/e/f09f91a8f09f8fbfe2808df09f8ea4'},
  {'emoji': '👨🏾&zwj;🎤', 'src': '/emoji/e/f09f91a8f09f8fbee2808df09f8ea4'},
  {'emoji': '👨🏽&zwj;🎤', 'src': '/emoji/e/f09f91a8f09f8fbde2808df09f8ea4'},
  {'emoji': '👨🏼&zwj;🎤', 'src': '/emoji/e/f09f91a8f09f8fbce2808df09f8ea4'},
  {'emoji': '👨🏻&zwj;🎤', 'src': '/emoji/e/f09f91a8f09f8fbbe2808df09f8ea4'},
  {'emoji': '👩🏿&zwj;💻', 'src': '/emoji/e/f09f91a9f09f8fbfe2808df09f92bb'},
  {'emoji': '👩🏾&zwj;💻', 'src': '/emoji/e/f09f91a9f09f8fbee2808df09f92bb'},
  {'emoji': '👩🏽&zwj;💻', 'src': '/emoji/e/f09f91a9f09f8fbde2808df09f92bb'},
  {'emoji': '👩🏼&zwj;💻', 'src': '/emoji/e/f09f91a9f09f8fbce2808df09f92bb'},
  {'emoji': '👩🏻&zwj;💻', 'src': '/emoji/e/f09f91a9f09f8fbbe2808df09f92bb'},
  {'emoji': '👨🏿&zwj;💻', 'src': '/emoji/e/f09f91a8f09f8fbfe2808df09f92bb'},
  {'emoji': '👨🏾&zwj;💻', 'src': '/emoji/e/f09f91a8f09f8fbee2808df09f92bb'},
  {'emoji': '👨🏽&zwj;💻', 'src': '/emoji/e/f09f91a8f09f8fbde2808df09f92bb'},
  {'emoji': '👨🏼&zwj;💻', 'src': '/emoji/e/f09f91a8f09f8fbce2808df09f92bb'},
  {'emoji': '👨🏻&zwj;💻', 'src': '/emoji/e/f09f91a8f09f8fbbe2808df09f92bb'},
  {'emoji': '👩🏿&zwj;🔬', 'src': '/emoji/e/f09f91a9f09f8fbfe2808df09f94ac'},
  {'emoji': '👩🏾&zwj;🔬', 'src': '/emoji/e/f09f91a9f09f8fbee2808df09f94ac'},
  {'emoji': '👩🏽&zwj;🔬', 'src': '/emoji/e/f09f91a9f09f8fbde2808df09f94ac'},
  {'emoji': '👩🏼&zwj;🔬', 'src': '/emoji/e/f09f91a9f09f8fbce2808df09f94ac'},
  {'emoji': '👩🏻&zwj;🔬', 'src': '/emoji/e/f09f91a9f09f8fbbe2808df09f94ac'},
  {'emoji': '👨🏿&zwj;🔬', 'src': '/emoji/e/f09f91a8f09f8fbfe2808df09f94ac'},
  {'emoji': '👨🏾&zwj;🔬', 'src': '/emoji/e/f09f91a8f09f8fbee2808df09f94ac'},
  {'emoji': '👨🏽&zwj;🔬', 'src': '/emoji/e/f09f91a8f09f8fbde2808df09f94ac'},
  {'emoji': '👨🏼&zwj;🔬', 'src': '/emoji/e/f09f91a8f09f8fbce2808df09f94ac'},
  {'emoji': '👨🏻&zwj;🔬', 'src': '/emoji/e/f09f91a8f09f8fbbe2808df09f94ac'},
  {'emoji': '👩🏿&zwj;💼', 'src': '/emoji/e/f09f91a9f09f8fbfe2808df09f92bc'},
  {'emoji': '👩🏾&zwj;💼', 'src': '/emoji/e/f09f91a9f09f8fbee2808df09f92bc'},
  {'emoji': '👩🏽&zwj;💼', 'src': '/emoji/e/f09f91a9f09f8fbde2808df09f92bc'},
  {'emoji': '👩🏼&zwj;💼', 'src': '/emoji/e/f09f91a9f09f8fbce2808df09f92bc'},
  {'emoji': '👩🏻&zwj;💼', 'src': '/emoji/e/f09f91a9f09f8fbbe2808df09f92bc'},
  {'emoji': '👨🏿&zwj;💼', 'src': '/emoji/e/f09f91a8f09f8fbfe2808df09f92bc'},
  {'emoji': '👨🏾&zwj;💼', 'src': '/emoji/e/f09f91a8f09f8fbee2808df09f92bc'},
  {'emoji': '👨🏽&zwj;💼', 'src': '/emoji/e/f09f91a8f09f8fbde2808df09f92bc'},
  {'emoji': '👨🏼&zwj;💼', 'src': '/emoji/e/f09f91a8f09f8fbce2808df09f92bc'},
  {'emoji': '👨🏻&zwj;💼', 'src': '/emoji/e/f09f91a8f09f8fbbe2808df09f92bc'},
  {'emoji': '👩🏿&zwj;🏭', 'src': '/emoji/e/f09f91a9f09f8fbfe2808df09f8fad'},
  {'emoji': '👩🏾&zwj;🏭', 'src': '/emoji/e/f09f91a9f09f8fbee2808df09f8fad'},
  {'emoji': '👩🏽&zwj;🏭', 'src': '/emoji/e/f09f91a9f09f8fbde2808df09f8fad'},
  {'emoji': '👩🏼&zwj;🏭', 'src': '/emoji/e/f09f91a9f09f8fbce2808df09f8fad'},
  {'emoji': '👩🏿&zwj;🍳', 'src': '/emoji/e/f09f91a9f09f8fbfe2808df09f8db3'},
  {'emoji': '👩🏾&zwj;🍳', 'src': '/emoji/e/f09f91a9f09f8fbee2808df09f8db3'},
  {'emoji': '👩🏽&zwj;🍳', 'src': '/emoji/e/f09f91a9f09f8fbde2808df09f8db3'},
  {'emoji': '👩🏼&zwj;🍳', 'src': '/emoji/e/f09f91a9f09f8fbce2808df09f8db3'},
  {'emoji': '👩🏻&zwj;🍳', 'src': '/emoji/e/f09f91a9f09f8fbbe2808df09f8db3'},
  {'emoji': '👨🏿&zwj;🍳', 'src': '/emoji/e/f09f91a8f09f8fbfe2808df09f8db3'},
  {'emoji': '👨🏾&zwj;🍳', 'src': '/emoji/e/f09f91a8f09f8fbee2808df09f8db3'},
  {'emoji': '👨🏽&zwj;🍳', 'src': '/emoji/e/f09f91a8f09f8fbde2808df09f8db3'},
  {'emoji': '👨🏼&zwj;🍳', 'src': '/emoji/e/f09f91a8f09f8fbce2808df09f8db3'},
  {'emoji': '👨🏻&zwj;🍳', 'src': '/emoji/e/f09f91a8f09f8fbbe2808df09f8db3'},
  {'emoji': '👩🏿&zwj;🌾', 'src': '/emoji/e/f09f91a9f09f8fbfe2808df09f8cbe'},
  {'emoji': '👩🏾&zwj;🌾', 'src': '/emoji/e/f09f91a9f09f8fbee2808df09f8cbe'},
  {'emoji': '👩🏽&zwj;🌾', 'src': '/emoji/e/f09f91a9f09f8fbde2808df09f8cbe'},
  {'emoji': '👩🏼&zwj;🌾', 'src': '/emoji/e/f09f91a9f09f8fbce2808df09f8cbe'},
  {'emoji': '👩🏻&zwj;🌾', 'src': '/emoji/e/f09f91a9f09f8fbbe2808df09f8cbe'},
  {'emoji': '👨🏿&zwj;🌾', 'src': '/emoji/e/f09f91a8f09f8fbfe2808df09f8cbe'},
  {'emoji': '👨🏾&zwj;🌾', 'src': '/emoji/e/f09f91a8f09f8fbee2808df09f8cbe'},
  {'emoji': '👨🏽&zwj;🌾', 'src': '/emoji/e/f09f91a8f09f8fbde2808df09f8cbe'},
  {'emoji': '👨🏼&zwj;🌾', 'src': '/emoji/e/f09f91a8f09f8fbce2808df09f8cbe'},
  {'emoji': '👨🏻&zwj;🌾', 'src': '/emoji/e/f09f91a8f09f8fbbe2808df09f8cbe'},
  {'emoji': '👩🏿&zwj;⚖', 'src': '/emoji/e/f09f91a9f09f8fbfe2808de29a96'},
  {'emoji': '👩🏾&zwj;⚖', 'src': '/emoji/e/f09f91a9f09f8fbee2808de29a96'},
  {'emoji': '👩🏽&zwj;⚖', 'src': '/emoji/e/f09f91a9f09f8fbde2808de29a96'},
  {'emoji': '👩🏼&zwj;⚖', 'src': '/emoji/e/f09f91a9f09f8fbce2808de29a96'},
  {'emoji': '👩🏻&zwj;⚖', 'src': '/emoji/e/f09f91a9f09f8fbbe2808de29a96'},
  {'emoji': '👨🏿&zwj;⚖', 'src': '/emoji/e/f09f91a8f09f8fbfe2808de29a96'},
  {'emoji': '👨🏾&zwj;⚖', 'src': '/emoji/e/f09f91a8f09f8fbee2808de29a96'},
  {'emoji': '👨🏽&zwj;⚖', 'src': '/emoji/e/f09f91a8f09f8fbde2808de29a96'},
  {'emoji': '👨🏼&zwj;⚖', 'src': '/emoji/e/f09f91a8f09f8fbce2808de29a96'},
  {'emoji': '👨🏻&zwj;⚖', 'src': '/emoji/e/f09f91a8f09f8fbbe2808de29a96'},
  {'emoji': '👩🏿&zwj;🏫', 'src': '/emoji/e/f09f91a9f09f8fbfe2808df09f8fab'},
  {'emoji': '👩🏾&zwj;🏫', 'src': '/emoji/e/f09f91a9f09f8fbee2808df09f8fab'},
  {'emoji': '👩🏽&zwj;🏫', 'src': '/emoji/e/f09f91a9f09f8fbde2808df09f8fab'},
  {'emoji': '👩🏼&zwj;🏫', 'src': '/emoji/e/f09f91a9f09f8fbce2808df09f8fab'},
  {'emoji': '👩🏻&zwj;🏫', 'src': '/emoji/e/f09f91a9f09f8fbbe2808df09f8fab'},
  {'emoji': '👨🏿&zwj;🏫', 'src': '/emoji/e/f09f91a8f09f8fbfe2808df09f8fab'},
  {'emoji': '👨🏾&zwj;🏫', 'src': '/emoji/e/f09f91a8f09f8fbee2808df09f8fab'},
  {'emoji': '👨🏽&zwj;🏫', 'src': '/emoji/e/f09f91a8f09f8fbde2808df09f8fab'},
  {'emoji': '👨🏼&zwj;🏫', 'src': '/emoji/e/f09f91a8f09f8fbce2808df09f8fab'},
  {'emoji': '👨🏻&zwj;🏫', 'src': '/emoji/e/f09f91a8f09f8fbbe2808df09f8fab'},
  {'emoji': '👩🏿&zwj;🎓', 'src': '/emoji/e/f09f91a9f09f8fbfe2808df09f8e93'},
  {'emoji': '👩🏾&zwj;🎓', 'src': '/emoji/e/f09f91a9f09f8fbee2808df09f8e93'},
  {'emoji': '👩🏽&zwj;🎓', 'src': '/emoji/e/f09f91a9f09f8fbde2808df09f8e93'},
  {'emoji': '👩🏼&zwj;🎓', 'src': '/emoji/e/f09f91a9f09f8fbce2808df09f8e93'},
  {'emoji': '👩🏻&zwj;🎓', 'src': '/emoji/e/f09f91a9f09f8fbbe2808df09f8e93'},
  {'emoji': '👨🏿&zwj;🎓', 'src': '/emoji/e/f09f91a8f09f8fbfe2808df09f8e93'},
  {'emoji': '👨🏾&zwj;🎓', 'src': '/emoji/e/f09f91a8f09f8fbee2808df09f8e93'},
  {'emoji': '👨🏽&zwj;🎓', 'src': '/emoji/e/f09f91a8f09f8fbde2808df09f8e93'},
  {'emoji': '👨🏼&zwj;🎓', 'src': '/emoji/e/f09f91a8f09f8fbce2808df09f8e93'},
  {'emoji': '👨🏻&zwj;🎓', 'src': '/emoji/e/f09f91a8f09f8fbbe2808df09f8e93'},
  {'emoji': '👩🏿', 'src': '/emoji/e/f09f91a9f09f8fbfe2808de29a95'},
  {'emoji': '👩🏾', 'src': '/emoji/e/f09f91a9f09f8fbee2808de29a95'},
  {'emoji': '👩🏽', 'src': '/emoji/e/f09f91a9f09f8fbde2808de29a95'},
  {'emoji': '👩🏼', 'src': '/emoji/e/f09f91a9f09f8fbce2808de29a95'},
  {'emoji': '👩🏻', 'src': '/emoji/e/f09f91a9f09f8fbbe2808de29a95'},
  {'emoji': '👨🏿', 'src': '/emoji/e/f09f91a8f09f8fbfe2808de29a95'},
  {'emoji': '🧙🏾&zwj;♂', 'src': '/emoji/e/f09fa799f09f8fbee2808de29982'},
  {'emoji': '🧙🏽&zwj;♂', 'src': '/emoji/e/f09fa799f09f8fbde2808de29982'},
  {'emoji': '🧙🏼&zwj;♂', 'src': '/emoji/e/f09fa799f09f8fbce2808de29982'},
  {'emoji': '🧙🏻&zwj;♂', 'src': '/emoji/e/f09fa799f09f8fbbe2808de29982'},
  {'emoji': '🧙🏿&zwj;♀', 'src': '/emoji/e/f09fa799f09f8fbfe2808de29980'},
  {'emoji': '🧙🏾&zwj;♀', 'src': '/emoji/e/f09fa799f09f8fbee2808de29980'},
  {'emoji': '🧙🏽&zwj;♀', 'src': '/emoji/e/f09fa799f09f8fbde2808de29980'},
  {'emoji': '🧙🏼&zwj;♀', 'src': '/emoji/e/f09fa799f09f8fbce2808de29980'},
  {'emoji': '🧙🏻&zwj;♀', 'src': '/emoji/e/f09fa799f09f8fbbe2808de29980'},
  {'emoji': '🧙🏿', 'src': '/emoji/e/f09fa799f09f8fbf'},
  {'emoji': '🧙🏾', 'src': '/emoji/e/f09fa799f09f8fbe'},
  {'emoji': '🧙🏽', 'src': '/emoji/e/f09fa799f09f8fbd'},
  {'emoji': '🧙🏼', 'src': '/emoji/e/f09fa799f09f8fbc'},
  {'emoji': '🧙🏻', 'src': '/emoji/e/f09fa799f09f8fbb'},
  {'emoji': '🤱🏿', 'src': '/emoji/e/f09fa4b1f09f8fbf'},
  {'emoji': '🤱🏾', 'src': '/emoji/e/f09fa4b1f09f8fbe'},
  {'emoji': '🤱🏽', 'src': '/emoji/e/f09fa4b1f09f8fbd'},
  {'emoji': '🤱🏼', 'src': '/emoji/e/f09fa4b1f09f8fbc'},
  {'emoji': '🤱🏻', 'src': '/emoji/e/f09fa4b1f09f8fbb'},
  {'emoji': '🧔🏿', 'src': '/emoji/e/f09fa794f09f8fbf'},
  {'emoji': '🧔🏾', 'src': '/emoji/e/f09fa794f09f8fbe'},
  {'emoji': '🧔🏽', 'src': '/emoji/e/f09fa794f09f8fbd'},
  {'emoji': '🧔🏼', 'src': '/emoji/e/f09fa794f09f8fbc'},
  {'emoji': '🧔🏻', 'src': '/emoji/e/f09fa794f09f8fbb'},
  {'emoji': '🧕🏿', 'src': '/emoji/e/f09fa795f09f8fbf'},
  {'emoji': '🧕🏾', 'src': '/emoji/e/f09fa795f09f8fbe'},
  {'emoji': '🧕🏽', 'src': '/emoji/e/f09fa795f09f8fbd'},
  {'emoji': '🧕🏼', 'src': '/emoji/e/f09fa795f09f8fbc'},
  {'emoji': '🧕🏻', 'src': '/emoji/e/f09fa795f09f8fbb'},
  {'emoji': '🧓🏿', 'src': '/emoji/e/f09fa793f09f8fbf'},
  {'emoji': '🧓🏾', 'src': '/emoji/e/f09fa793f09f8fbe'},
  {'emoji': '🧓🏽', 'src': '/emoji/e/f09fa793f09f8fbd'},
  {'emoji': '🧓🏼', 'src': '/emoji/e/f09fa793f09f8fbc'},
  {'emoji': '🧓🏻', 'src': '/emoji/e/f09fa793f09f8fbb'},
  {'emoji': '🧑🏿', 'src': '/emoji/e/f09fa791f09f8fbf'},
  {'emoji': '🧑🏾', 'src': '/emoji/e/f09fa791f09f8fbe'},
  {'emoji': '🧑🏽', 'src': '/emoji/e/f09fa791f09f8fbd'},
  {'emoji': '🧑🏼', 'src': '/emoji/e/f09fa791f09f8fbc'},
  {'emoji': '🧑🏻', 'src': '/emoji/e/f09fa791f09f8fbb'},
  {'emoji': '🧒🏿', 'src': '/emoji/e/f09fa792f09f8fbf'},
  {'emoji': '🧒🏾', 'src': '/emoji/e/f09fa792f09f8fbe'},
  {'emoji': '🧒🏽', 'src': '/emoji/e/f09fa792f09f8fbd'},
  {'emoji': '🧒🏼', 'src': '/emoji/e/f09fa792f09f8fbc'},
  {'emoji': '🧒🏻', 'src': '/emoji/e/f09fa792f09f8fbb'},
  {'emoji': '⛹🏿', 'src': '/emoji/e/e29bb9f09f8fbf'},
  {'emoji': '⛹🏾', 'src': '/emoji/e/e29bb9f09f8fbe'},
  {'emoji': '⛹🏽', 'src': '/emoji/e/e29bb9f09f8fbd'},
  {'emoji': '⛹🏼', 'src': '/emoji/e/e29bb9f09f8fbc'},
  {'emoji': '⛹🏻', 'src': '/emoji/e/e29bb9f09f8fbb'},
  {'emoji': '🏋🏿', 'src': '/emoji/e/f09f8f8bf09f8fbf'},
  {'emoji': '🏋🏾', 'src': '/emoji/e/f09f8f8bf09f8fbe'},
  {'emoji': '🏋🏽', 'src': '/emoji/e/f09f8f8bf09f8fbd'},
  {'emoji': '🏋🏼', 'src': '/emoji/e/f09f8f8bf09f8fbc'},
  {'emoji': '🏋🏻', 'src': '/emoji/e/f09f8f8bf09f8fbb'},
];
