import {ApplicationRef, DoBootstrap, Injector, NgModule} from '@angular/core';
import { PaymentsTableComponent } from './components/payments-table/payments-table.component';
import { PaymentsTariffHeaderComponent } from './components/payments-tariff-header/payments-tariff-header.component';
import { PaymentsTariffFeatureComponent } from './components/payments-tariff-feature/payments-tariff-feature.component';
import { PaymentsTariffFeatureStatusComponent } from './components/payments-tariff-feature-status/payments-tariff-feature-status.component';
import { PaymentsTariffTimePeriodsSelectorComponent } from './components/payments-tariff-time-periods-selector/payments-tariff-time-periods-selector.component';
import {CommonModule} from "@angular/common";
import {MatIconModule} from '@angular/material';
import {createCustomElement} from '@angular/elements';

@NgModule({
  declarations: [PaymentsTableComponent, PaymentsTariffHeaderComponent, PaymentsTariffFeatureComponent, PaymentsTariffFeatureStatusComponent, PaymentsTariffTimePeriodsSelectorComponent],
  imports: [
    CommonModule,
    MatIconModule
  ],
  exports: [PaymentsTableComponent],
  entryComponents: [PaymentsTableComponent]
})
export class PaymentsModule implements DoBootstrap {

  ngDoBootstrap(appRef: ApplicationRef): void {
  }

  constructor(
    injector: Injector
  ) {
    const custom = createCustomElement(PaymentsTableComponent, {injector: injector});
    customElements.define('app-payments-table', custom);
  }
}
