import {Injectable} from '@angular/core';
import {MoySkladService} from '../../../api/services';
import {createCrmOrganisation} from './crm-organisation.model';
import {CrmOrganisationStore} from './crm-organisation.store';
import {PlatformsTypes} from '../../platforms-types';

@Injectable({
  providedIn: 'root'
})
export class CrmOrganisationService {

  constructor(
    private moySkladService: MoySkladService,
    private store: CrmOrganisationStore
  ) {
  }

  public LoadOrganisations(type: PlatformsTypes) {
    switch (type) {
      case PlatformsTypes.MOY_SKLAD_IMPORT:
      case PlatformsTypes.MOY_SKLAD_EXPORT:
        this.LoadMoiSkladOrganistations();
        break;
    }
  }

  public LoadMoiSkladOrganistations() {
    this.moySkladService.GetOrganizations()
      .subscribe((response) => {
        const organisations = response.rows;
        organisations
          .map(organisation => createCrmOrganisation(organisation))
          .forEach(organisation => this.store.add(organisation));
      });
  }
}
