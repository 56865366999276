import { OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl } from "@angular/forms";
import { delay } from "rxjs/operators";
var NewCustomFieldControlComponent = /** @class */ (function () {
    function NewCustomFieldControlComponent() {
        this.FieldName = '';
        this.Description = '';
        this.Control = new FormControl();
        this.Disabled = false;
        this.IsAutocompleteOptionsOpened = false;
    }
    Object.defineProperty(NewCustomFieldControlComponent.prototype, "IsButtonDisabled", {
        // positions = [
        //     new ConnectedPosition()
        // ]
        get: function () {
            return this.CustomFieldsValues.length === 0;
        },
        enumerable: true,
        configurable: true
    });
    NewCustomFieldControlComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.Control.valueChanges
            .pipe(delay(500))
            .subscribe(function (value) {
            if (_this.change) {
                _this.change(value);
            }
        });
    };
    NewCustomFieldControlComponent.prototype.registerOnChange = function (fn) {
        this.change = fn;
    };
    NewCustomFieldControlComponent.prototype.registerOnTouched = function (fn) {
        this.touch = fn;
    };
    NewCustomFieldControlComponent.prototype.setDisabledState = function (isDisabled) {
        this.Disabled = isDisabled;
    };
    NewCustomFieldControlComponent.prototype.writeValue = function (obj) {
        this.Control.setValue(obj);
    };
    NewCustomFieldControlComponent.prototype.GenerateOption = function (optionValue, useFormating) {
        if (useFormating === void 0) { useFormating = true; }
        var field = optionValue;
        if (useFormating) {
            field = "{{" + field + "}}";
        }
        return field;
    };
    NewCustomFieldControlComponent.prototype.SelectValue = function (optionValue, UseFormating) {
        if (UseFormating === void 0) { UseFormating = true; }
        var newValue = this.GenerateOption(optionValue, UseFormating);
        this.Control.patchValue("" + this.Control.value + newValue);
    };
    NewCustomFieldControlComponent.prototype.OpenAutoCompleteOptions = function () {
        console.log('AUTO_COMPLETE IS OPENED');
        this.IsAutocompleteOptionsOpened = true;
    };
    NewCustomFieldControlComponent.prototype.CloseAutoCompleteOptions = function ($event) {
        console.log('CLOSED');
        this.IsAutocompleteOptionsOpened = false;
    };
    return NewCustomFieldControlComponent;
}());
export { NewCustomFieldControlComponent };
