import { UserIntegrationStatisticsStore } from './user-integration-statistics.store';
import { UserIntegrationService } from "../../../api/services";
import { delay, tap } from "rxjs/operators";
import { createUserIntegrationStatistic } from "./user-integration-statistic.model";
import { forkJoin } from "rxjs";
import * as i0 from "@angular/core";
import * as i1 from "./user-integration-statistics.store";
import * as i2 from "../../../api/services/user-integration.service";
var UserIntegrationStatisticsService = /** @class */ (function () {
    function UserIntegrationStatisticsService(store, integrationService) {
        this.store = store;
        this.integrationService = integrationService;
    }
    /**
     * Загрузка статистики по id
     * @param ids
     * @param dateFrom
     * @param dateTo
     * @constructor
     */
    UserIntegrationStatisticsService.prototype.LoadIntegrationStatisticsById = function (ids, dateFrom, dateTo) {
        var _this = this;
        if (dateFrom === void 0) { dateFrom = null; }
        if (dateTo === void 0) { dateTo = null; }
        var integrationStatistics = function () { return ids.map(function (id, i) { return _this.integrationService.GetIntegrationSummaryStatistics({
            integrationId: id,
            dateFrom: dateFrom,
            dateTo: dateTo
        }).pipe(delay(150), tap(function (_a) {
            var data = _a.data;
            return _this.store.add(createUserIntegrationStatistic({ id: id, value: data }));
        })); }); };
        forkJoin(integrationStatistics())
            .subscribe(function (data) {
        });
    };
    UserIntegrationStatisticsService.ngInjectableDef = i0.defineInjectable({ factory: function UserIntegrationStatisticsService_Factory() { return new UserIntegrationStatisticsService(i0.inject(i1.UserIntegrationStatisticsStore), i0.inject(i2.UserIntegrationService)); }, token: UserIntegrationStatisticsService, providedIn: "root" });
    return UserIntegrationStatisticsService;
}());
export { UserIntegrationStatisticsService };
