/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./payments-tariff-feature-status.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i4 from "@angular/material/icon";
import * as i5 from "./payments-tariff-feature-status.component";
import * as i6 from "../../services/payments.service";
var styles_PaymentsTariffFeatureStatusComponent = [i0.styles];
var RenderType_PaymentsTariffFeatureStatusComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PaymentsTariffFeatureStatusComponent, data: {} });
export { RenderType_PaymentsTariffFeatureStatusComponent as RenderType_PaymentsTariffFeatureStatusComponent };
function View_PaymentsTariffFeatureStatusComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "feature-status__indicator"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "feature-status__indicator_green": 0, "feature-status__indicator_red": 1 }), (_l()(), i1.ɵeld(3, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i3.View_MatIcon_0, i3.RenderType_MatIcon)), i1.ɵdid(4, 9158656, null, 0, i4.MatIcon, [i1.ElementRef, i4.MatIconRegistry, [8, null], [2, i4.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(5, 0, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "feature-status__indicator"; var currVal_1 = _ck(_v, 2, 0, _co.contains, !_co.contains); _ck(_v, 1, 0, currVal_0, currVal_1); _ck(_v, 4, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵnov(_v, 4).inline; var currVal_3 = (((i1.ɵnov(_v, 4).color !== "primary") && (i1.ɵnov(_v, 4).color !== "accent")) && (i1.ɵnov(_v, 4).color !== "warn")); _ck(_v, 3, 0, currVal_2, currVal_3); var currVal_4 = (_co.featureTariffPlanStatus ? "check" : "close"); _ck(_v, 5, 0, currVal_4); }); }
export function View_PaymentsTariffFeatureStatusComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "feature-status"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PaymentsTariffFeatureStatusComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "feature-status__description"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !((_co.featureTariffPlanStatus == null) ? null : _co.featureTariffPlanStatus.restrictions); _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_co.featureTariffPlanStatus == null) ? null : _co.featureTariffPlanStatus.restrictions); _ck(_v, 4, 0, currVal_1); }); }
export function View_PaymentsTariffFeatureStatusComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-payments-tariff-feature-status", [], null, null, null, View_PaymentsTariffFeatureStatusComponent_0, RenderType_PaymentsTariffFeatureStatusComponent)), i1.ɵdid(1, 114688, null, 0, i5.PaymentsTariffFeatureStatusComponent, [i6.PaymentsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PaymentsTariffFeatureStatusComponentNgFactory = i1.ɵccf("app-payments-tariff-feature-status", i5.PaymentsTariffFeatureStatusComponent, View_PaymentsTariffFeatureStatusComponent_Host_0, { tariff: "tariff", feature: "feature" }, {}, []);
export { PaymentsTariffFeatureStatusComponentNgFactory as PaymentsTariffFeatureStatusComponentNgFactory };
