<ng-container *ngIf="experienceLevels !== null">
  <ng-container *ngIf="experienceLevels !== null">
    <app-new-style-modal [isVisible]="true">
      <div class="new-modal-header-content">
        Добро пожаловать!
      </div>
      <div class="new-modal-body-content">
        <div class="new-modal-content-container">
          Прежде чем продолжить работу с сервисом, укажите, пожалуйста, ваш опыт в настройке рекламы ВКонтакте.
        </div>

        <div class="experience-option-list">
          <ng-container *ngFor="let item of experienceLevels">
            <div class="experience-option-list__item">
              <div class="experience-option">
                <div class="experience-option-inputs">
                  <div class="experience-option-inputs__item">
                    <input [id]="'experience-form-radio-'+item.id" type="radio" name="experience-radio-group" class="experience-option-input"
                           [value]="item.id" [(ngModel)]="selectedExperience" [disabled]="sending">
                  </div>
                  <div class="experience-option-inputs__item">
                    <label [for]="'experience-form-radio-'+item.id" class="experience-option__name">
                      {{item.name}}
                    </label>
                  </div>
                </div>
                <div class="experience-option__description">
                  {{item.description}}
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="new-modal-buttons">
        <ng-container *ngIf="sending; else notSending">
          <button class="vk-standart-button vk-standart-button_cancel">
            Отправка...
          </button>
        </ng-container>
        <ng-template #notSending>
          <button class="vk-standart-button" (click)="AssignExperienceLevel()" [disabled]="selectedExperience === null">
            Выбрать
          </button>
        </ng-template>
      </div>
    </app-new-style-modal>
  </ng-container>

</ng-container>
