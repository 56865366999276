var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { map as __map, filter as __filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../api-configuration";
import * as i2 from "@angular/common/http";
var ChatService = /** @class */ (function (_super) {
    __extends(ChatService, _super);
    function ChatService(config, http) {
        return _super.call(this, config, http) || this;
    }
    ChatService.prototype.Apiv2ChatOnlinePostResponse = function () {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('POST', this.rootUrl + "/apiv2/chat/online", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ChatService.prototype.Apiv2ChatOnlinePost = function () {
        return this.Apiv2ChatOnlinePostResponse().pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `ChatService.Apiv2ChatConversationsUpdatesGetParams` containing the following parameters:
     *
     * - `updatesFrom`:
     *
     * - `onlyReadUpdates`:
     *
     * - `userId`:
     */
    ChatService.prototype.Apiv2ChatConversationsUpdatesGetResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.updatesFrom != null)
            __params = __params.set('updatesFrom', params.updatesFrom.toString());
        if (params.onlyReadUpdates != null)
            __params = __params.set('onlyReadUpdates', params.onlyReadUpdates.toString());
        if (params.userId != null)
            __params = __params.set('userId', params.userId.toString());
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/chat/conversations-updates", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `ChatService.Apiv2ChatConversationsUpdatesGetParams` containing the following parameters:
     *
     * - `updatesFrom`:
     *
     * - `onlyReadUpdates`:
     *
     * - `userId`:
     */
    ChatService.prototype.Apiv2ChatConversationsUpdatesGet = function (params) {
        return this.Apiv2ChatConversationsUpdatesGetResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `ChatService.Apiv2ChatConversationsGetParams` containing the following parameters:
     *
     * - `unAsnweredFirst`:
     *
     * - `offset`:
     *
     * - `markFilterId`:
     *
     * - `userId`:
     *
     * - `limit`:
     *
     * - `filterMode`:
     */
    ChatService.prototype.Apiv2ChatConversationsGetResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.unAsnweredFirst != null)
            __params = __params.set('unAsnweredFirst', params.unAsnweredFirst.toString());
        if (params.offset != null)
            __params = __params.set('offset', params.offset.toString());
        if (params.markFilterId != null)
            __params = __params.set('markFilterId', params.markFilterId.toString());
        if (params.userId != null)
            __params = __params.set('userId', params.userId.toString());
        if (params.limit != null)
            __params = __params.set('limit', params.limit.toString());
        if (params.filterMode != null)
            __params = __params.set('filterMode', params.filterMode.toString());
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/chat/conversations", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `ChatService.Apiv2ChatConversationsGetParams` containing the following parameters:
     *
     * - `unAsnweredFirst`:
     *
     * - `offset`:
     *
     * - `markFilterId`:
     *
     * - `userId`:
     *
     * - `limit`:
     *
     * - `filterMode`:
     */
    ChatService.prototype.Apiv2ChatConversationsGet = function (params) {
        return this.Apiv2ChatConversationsGetResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `ChatService.Apiv2ChatConversationsDeleteParams` containing the following parameters:
     *
     * - `userId`:
     *
     * - `reason`:
     */
    ChatService.prototype.Apiv2ChatConversationsDeleteResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.userId != null)
            __params = __params.set('userId', params.userId.toString());
        if (params.reason != null)
            __params = __params.set('reason', params.reason.toString());
        var req = new HttpRequest('DELETE', this.rootUrl + "/apiv2/chat/conversations", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `ChatService.Apiv2ChatConversationsDeleteParams` containing the following parameters:
     *
     * - `userId`:
     *
     * - `reason`:
     */
    ChatService.prototype.Apiv2ChatConversationsDelete = function (params) {
        return this.Apiv2ChatConversationsDeleteResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `ChatService.Apiv2ChatMessagesGetParams` containing the following parameters:
     *
     * - `offset`:
     *
     * - `includeRemoved`:
     *
     * - `userId`:
     *
     * - `limit`:
     */
    ChatService.prototype.Apiv2ChatMessagesGetResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.offset != null)
            __params = __params.set('offset', params.offset.toString());
        if (params.includeRemoved != null)
            __params = __params.set('includeRemoved', params.includeRemoved.toString());
        if (params.userId != null)
            __params = __params.set('userId', params.userId.toString());
        if (params.limit != null)
            __params = __params.set('limit', params.limit.toString());
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/chat/messages", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `ChatService.Apiv2ChatMessagesGetParams` containing the following parameters:
     *
     * - `offset`:
     *
     * - `includeRemoved`:
     *
     * - `userId`:
     *
     * - `limit`:
     */
    ChatService.prototype.Apiv2ChatMessagesGet = function (params) {
        return this.Apiv2ChatMessagesGetResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `ChatService.Apiv2ChatMessagesPostParams` containing the following parameters:
     *
     * - `sendNotificationOverEmail`:
     *
     * - `userId`:
     *
     * - `text`:
     */
    ChatService.prototype.Apiv2ChatMessagesPostResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.sendNotificationOverEmail != null)
            __params = __params.set('sendNotificationOverEmail', params.sendNotificationOverEmail.toString());
        if (params.userId != null)
            __params = __params.set('userId', params.userId.toString());
        __body = params.text;
        var req = new HttpRequest('POST', this.rootUrl + "/apiv2/chat/messages", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `ChatService.Apiv2ChatMessagesPostParams` containing the following parameters:
     *
     * - `sendNotificationOverEmail`:
     *
     * - `userId`:
     *
     * - `text`:
     */
    ChatService.prototype.Apiv2ChatMessagesPost = function (params) {
        return this.Apiv2ChatMessagesPostResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `ChatService.Apiv2ChatLatestMessagesGetParams` containing the following parameters:
     *
     * - `lastMessageId`:
     *
     * - `userId`:
     */
    ChatService.prototype.Apiv2ChatLatestMessagesGetResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.lastMessageId != null)
            __params = __params.set('lastMessageId', params.lastMessageId.toString());
        if (params.userId != null)
            __params = __params.set('userId', params.userId.toString());
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/chat/latest-messages", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `ChatService.Apiv2ChatLatestMessagesGetParams` containing the following parameters:
     *
     * - `lastMessageId`:
     *
     * - `userId`:
     */
    ChatService.prototype.Apiv2ChatLatestMessagesGet = function (params) {
        return this.Apiv2ChatLatestMessagesGetResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `ChatService.Apiv2ChatMessageDeleteParams` containing the following parameters:
     *
     * - `userId`:
     *
     * - `messageId`:
     */
    ChatService.prototype.Apiv2ChatMessageDeleteResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.userId != null)
            __params = __params.set('userId', params.userId.toString());
        if (params.messageId != null)
            __params = __params.set('messageId', params.messageId.toString());
        var req = new HttpRequest('DELETE', this.rootUrl + "/apiv2/chat/message", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `ChatService.Apiv2ChatMessageDeleteParams` containing the following parameters:
     *
     * - `userId`:
     *
     * - `messageId`:
     */
    ChatService.prototype.Apiv2ChatMessageDelete = function (params) {
        return this.Apiv2ChatMessageDeleteResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ChatService.prototype.Apiv2ChatUnreadGetResponse = function () {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/chat/unread", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ChatService.prototype.Apiv2ChatUnreadGet = function () {
        return this.Apiv2ChatUnreadGetResponse().pipe(__map(function (_r) { return _r.body; }));
    };
    ChatService.prototype.Apiv2ChatMarkGetResponse = function () {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/chat/mark", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ChatService.prototype.Apiv2ChatMarkGet = function () {
        return this.Apiv2ChatMarkGetResponse().pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `ChatService.Apiv2ChatMarkPostParams` containing the following parameters:
     *
     * - `userId`:
     *
     * - `markId`:
     */
    ChatService.prototype.Apiv2ChatMarkPostResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.userId != null)
            __params = __params.set('userId', params.userId.toString());
        if (params.markId != null)
            __params = __params.set('markId', params.markId.toString());
        var req = new HttpRequest('POST', this.rootUrl + "/apiv2/chat/mark", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `ChatService.Apiv2ChatMarkPostParams` containing the following parameters:
     *
     * - `userId`:
     *
     * - `markId`:
     */
    ChatService.prototype.Apiv2ChatMarkPost = function (params) {
        return this.Apiv2ChatMarkPostResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ChatService.Apiv2ChatOnlinePostPath = '/apiv2/chat/online';
    ChatService.Apiv2ChatConversationsUpdatesGetPath = '/apiv2/chat/conversations-updates';
    ChatService.Apiv2ChatConversationsGetPath = '/apiv2/chat/conversations';
    ChatService.Apiv2ChatConversationsDeletePath = '/apiv2/chat/conversations';
    ChatService.Apiv2ChatMessagesGetPath = '/apiv2/chat/messages';
    ChatService.Apiv2ChatMessagesPostPath = '/apiv2/chat/messages';
    ChatService.Apiv2ChatLatestMessagesGetPath = '/apiv2/chat/latest-messages';
    ChatService.Apiv2ChatMessageDeletePath = '/apiv2/chat/message';
    ChatService.Apiv2ChatUnreadGetPath = '/apiv2/chat/unread';
    ChatService.Apiv2ChatMarkGetPath = '/apiv2/chat/mark';
    ChatService.Apiv2ChatMarkPostPath = '/apiv2/chat/mark';
    ChatService.ngInjectableDef = i0.defineInjectable({ factory: function ChatService_Factory() { return new ChatService(i0.inject(i1.ApiConfiguration), i0.inject(i2.HttpClient)); }, token: ChatService, providedIn: "root" });
    return ChatService;
}(__BaseService));
export { ChatService };
