import {TariffPlanOptionTimeValue} from './tariff-plan-option-time-value';

export class TariffPlanOptionSet {
  public optionId: number;

  public title: string;

  public isDisabled: boolean = false;

  public times: Array<TariffPlanOptionTimeValue>;
}
