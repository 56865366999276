import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';
import {PostMonitorBackend_V2ModelsAdManagerMultipleEditAdsViewModel} from '../../../../../api/models';

@Component({
  selector: 'app-multiple-edit-reject',
  templateUrl: './multiple-edit-reject.component.html',
  styleUrls: ['./multiple-edit-reject.component.scss']
})
export class MultipleEditRejectComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PostMonitorBackend_V2ModelsAdManagerMultipleEditAdsViewModel
  ) {
  }

  public get Data() {
    return Object.keys(this.data)
      .filter(key => this.data[key] !== null)
      .filter(key => ({key, value: this.data[key]}))
      .reduce((arr, curr) => Object.assign(arr, curr), {});
  }

  ngOnInit() {
  }

}
