var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { map as __map, filter as __filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../api-configuration";
import * as i2 from "@angular/common/http";
var MegaplanService = /** @class */ (function (_super) {
    __extends(MegaplanService, _super);
    function MegaplanService(config, http) {
        return _super.call(this, config, http) || this;
    }
    /**
     * @param credentials undefined
     */
    MegaplanService.prototype.AuthorizeAsyncResponse = function (credentials) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = credentials;
        var req = new HttpRequest('POST', this.rootUrl + "/apiv2/mega-plan/auth", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param credentials undefined
     */
    MegaplanService.prototype.AuthorizeAsync = function (credentials) {
        return this.AuthorizeAsyncResponse(credentials).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `MegaplanService.GetContractorDealsExtraFieldsAsyncParams` containing the following parameters:
     *
     * - `limit`:
     *
     * - `contractorId`:
     *
     * - `pageBefore`:
     *
     * - `pageAfter`:
     *
     * - `fields`:
     *
     * - `contentType`:
     */
    MegaplanService.prototype.GetContractorDealsExtraFieldsAsyncResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.limit != null)
            __params = __params.set('limit', params.limit.toString());
        if (params.pageBefore != null)
            __params = __params.set('pageBefore', params.pageBefore.toString());
        if (params.pageAfter != null)
            __params = __params.set('pageAfter', params.pageAfter.toString());
        (params.fields || []).forEach(function (val) { if (val != null)
            __params = __params.append('fields', val.toString()); });
        if (params.contentType != null)
            __params = __params.set('contentType', params.contentType.toString());
        var req = new HttpRequest('GET', this.rootUrl + ("/apiv2/mega-plan/contractor/" + encodeURIComponent(params.contractorId) + "/deals/extrafields"), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `MegaplanService.GetContractorDealsExtraFieldsAsyncParams` containing the following parameters:
     *
     * - `limit`:
     *
     * - `contractorId`:
     *
     * - `pageBefore`:
     *
     * - `pageAfter`:
     *
     * - `fields`:
     *
     * - `contentType`:
     */
    MegaplanService.prototype.GetContractorDealsExtraFieldsAsync = function (params) {
        return this.GetContractorDealsExtraFieldsAsyncResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `MegaplanService.GetContractorTypeAsyncParams` containing the following parameters:
     *
     * - `limit`:
     *
     * - `pageBefore`:
     *
     * - `pageAfter`:
     */
    MegaplanService.prototype.GetContractorTypeAsyncResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.limit != null)
            __params = __params.set('limit', params.limit.toString());
        if (params.pageBefore != null)
            __params = __params.set('pageBefore', params.pageBefore.toString());
        if (params.pageAfter != null)
            __params = __params.set('pageAfter', params.pageAfter.toString());
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/mega-plan/contractor-type", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `MegaplanService.GetContractorTypeAsyncParams` containing the following parameters:
     *
     * - `limit`:
     *
     * - `pageBefore`:
     *
     * - `pageAfter`:
     */
    MegaplanService.prototype.GetContractorTypeAsync = function (params) {
        return this.GetContractorTypeAsyncResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `MegaplanService.GetProgramsAsyncParams` containing the following parameters:
     *
     * - `limit`:
     *
     * - `pageBefore`:
     *
     * - `pageAfter`:
     *
     * - `fields`:
     */
    MegaplanService.prototype.GetProgramsAsyncResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.limit != null)
            __params = __params.set('limit', params.limit.toString());
        if (params.pageBefore != null)
            __params = __params.set('pageBefore', params.pageBefore.toString());
        if (params.pageAfter != null)
            __params = __params.set('pageAfter', params.pageAfter.toString());
        (params.fields || []).forEach(function (val) { if (val != null)
            __params = __params.append('fields', val.toString()); });
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/mega-plan/program", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `MegaplanService.GetProgramsAsyncParams` containing the following parameters:
     *
     * - `limit`:
     *
     * - `pageBefore`:
     *
     * - `pageAfter`:
     *
     * - `fields`:
     */
    MegaplanService.prototype.GetProgramsAsync = function (params) {
        return this.GetProgramsAsyncResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param id undefined
     */
    MegaplanService.prototype.GetProgramAsyncResponse = function (id) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('GET', this.rootUrl + ("/apiv2/mega-plan/program/" + encodeURIComponent(id)), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param id undefined
     */
    MegaplanService.prototype.GetProgramAsync = function (id) {
        return this.GetProgramAsyncResponse(id).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `MegaplanService.GetEmployeesAsyncParams` containing the following parameters:
     *
     * - `limit`:
     *
     * - `pageBefore`:
     *
     * - `pageAfter`:
     */
    MegaplanService.prototype.GetEmployeesAsyncResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.limit != null)
            __params = __params.set('limit', params.limit.toString());
        if (params.pageBefore != null)
            __params = __params.set('pageBefore', params.pageBefore.toString());
        if (params.pageAfter != null)
            __params = __params.set('pageAfter', params.pageAfter.toString());
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/mega-plan/employee", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `MegaplanService.GetEmployeesAsyncParams` containing the following parameters:
     *
     * - `limit`:
     *
     * - `pageBefore`:
     *
     * - `pageAfter`:
     */
    MegaplanService.prototype.GetEmployeesAsync = function (params) {
        return this.GetEmployeesAsyncResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    MegaplanService.AuthorizeAsyncPath = '/apiv2/mega-plan/auth';
    MegaplanService.GetContractorDealsExtraFieldsAsyncPath = '/apiv2/mega-plan/contractor/{contractorId}/deals/extrafields';
    MegaplanService.GetContractorTypeAsyncPath = '/apiv2/mega-plan/contractor-type';
    MegaplanService.GetProgramsAsyncPath = '/apiv2/mega-plan/program';
    MegaplanService.GetProgramAsyncPath = '/apiv2/mega-plan/program/{id}';
    MegaplanService.GetEmployeesAsyncPath = '/apiv2/mega-plan/employee';
    MegaplanService.ngInjectableDef = i0.defineInjectable({ factory: function MegaplanService_Factory() { return new MegaplanService(i0.inject(i1.ApiConfiguration), i0.inject(i2.HttpClient)); }, token: MegaplanService, providedIn: "root" });
    return MegaplanService;
}(__BaseService));
export { MegaplanService };
