import { OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { AutomationVkService } from '../../../api/services/automation-vk.service';
import { ExpirationManagerService } from '../../../automation/services/expiration-manager.service';
import { AutomationExpirationManagerService } from '../../../automation/services/automation-expiration-manager.service';
import { AdblockService } from '../../../automation/services/adblock.service';
import { ActivatedRoute } from '@angular/router';
var AutomationWrapperComponent = /** @class */ (function () {
    function AutomationWrapperComponent(api, expirationManager, accessManager, adBlock, route) {
        this.api = api;
        this.expirationManager = expirationManager;
        this.accessManager = accessManager;
        this.adBlock = adBlock;
        this.route = route;
        this.baseUrl = environment.frontEndUrl;
        this.cabinetsChecked = false;
        this.cabinetsBinded = false;
        this.ShowCreateButton = true;
    }
    Object.defineProperty(AutomationWrapperComponent.prototype, "AdBlockEnabled", {
        get: function () {
            return this.adBlock.AdBlockEnabled;
        },
        enumerable: true,
        configurable: true
    });
    AutomationWrapperComponent.prototype.ngOnInit = function () {
        this.CheckCabinetsBinded();
        this.ShowCreateButton = this.route.snapshot.queryParams['show'] === 'true';
    };
    AutomationWrapperComponent.prototype.AddVkAdCabinetLinkClick = function () {
        var route = this.route.snapshot['_routerState'].url;
        console.log('Proceeding to OAuth binding, remebering current url', route);
        localStorage.setItem('bind-return-to', route);
        window.location.href = this.GetBindUrl();
    };
    AutomationWrapperComponent.prototype.GetBindUrl = function () {
        return this.baseUrl + "/apiv2/OAuth/authorize/vk-ad-cabinet";
    };
    AutomationWrapperComponent.prototype.CheckCabinetsBinded = function () {
        var _this = this;
        this.cabinetsChecked = false;
        this.api.CheckCabinetsBinded()
            .subscribe(function (data) {
            _this.cabinetsChecked = true;
            _this.cabinetsBinded = true;
        }, function (err) {
            _this.cabinetsChecked = true;
            _this.cabinetsBinded = false;
        });
    };
    AutomationWrapperComponent.prototype.ShowHeaderCard = function () {
        var route = this.route.snapshot['_routerState'];
        if (route) {
            var url = route.url;
            if (url) {
                return !url.includes('ad-manager') && !url.includes('dashboard');
            }
        }
        return true;
    };
    return AutomationWrapperComponent;
}());
export { AutomationWrapperComponent };
