/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./strategies-manager.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../strategy-preview/strategy-preview.component.ngfactory";
import * as i3 from "../strategy-preview/strategy-preview.component";
import * as i4 from "@angular/common";
import * as i5 from "../../../ngr-ui/components/dynamic-form/dynamic-form.component.ngfactory";
import * as i6 from "../../../ngr-ui/components/dynamic-form/dynamic-form.component";
import * as i7 from "./strategies-manager.component";
var styles_StrategiesManagerComponent = [i0.styles];
var RenderType_StrategiesManagerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StrategiesManagerComponent, data: {} });
export { RenderType_StrategiesManagerComponent as RenderType_StrategiesManagerComponent };
function View_StrategiesManagerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-strategy-preview", [], null, [[null, "selectedChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedChange" === en)) {
        var pd_0 = ((_co.selectedStrategy = $event) !== false);
        ad = (pd_0 && ad);
    } if (("selectedChange" === en)) {
        var pd_1 = (_co.AcceptStrategy($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_StrategyPreviewComponent_0, i2.RenderType_StrategyPreviewComponent)), i1.ɵdid(2, 114688, null, 0, i3.StrategyPreviewComponent, [], { strategy: [0, "strategy"], selected: [1, "selected"] }, { selectedChange: "selectedChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.context.$implicit; var currVal_1 = _co.selectedStrategy; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_StrategiesManagerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "vk-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StrategiesManagerComponent_2)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [], null, null, null, null, null)), i1.ɵdid(5, 278528, null, 0, i4.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(6, { "form_hidden": 0 }), (_l()(), i1.ɵeld(7, 0, null, null, 1, "app-dynamic-form", [], null, [[null, "Successed"], [null, "Inited"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("Successed" === en)) {
        var pd_0 = (_co.Save($event) !== false);
        ad = (pd_0 && ad);
    } if (("Inited" === en)) {
        var pd_1 = (_co.OnInit($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i5.View_DynamicFormComponent_0, i5.RenderType_DynamicFormComponent)), i1.ɵdid(8, 4964352, [[1, 4], ["adForm", 4]], 0, i6.DynamicFormComponent, [], { inputs: [0, "inputs"] }, { Successed: "Successed", Inited: "Inited" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.strategies.length > 1); _ck(_v, 3, 0, currVal_0); var currVal_1 = _ck(_v, 6, 0, (_co.selectedStrategy !== _v.context.$implicit.code)); _ck(_v, 5, 0, currVal_1); var currVal_2 = (_co.inputs[_v.context.$implicit.code] || _co.inputs); _ck(_v, 8, 0, currVal_2); }, null); }
function View_StrategiesManagerComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "strategies-manager__form"]], null, null, null, null, null))], null, null); }
export function View_StrategiesManagerComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { adForm: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StrategiesManagerComponent_1)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StrategiesManagerComponent_3)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.strategies; _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.displayForm && _co.strategySelected); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_StrategiesManagerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-strategies-manager", [], null, null, null, View_StrategiesManagerComponent_0, RenderType_StrategiesManagerComponent)), i1.ɵdid(1, 114688, null, 0, i7.StrategiesManagerComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StrategiesManagerComponentNgFactory = i1.ɵccf("app-strategies-manager", i7.StrategiesManagerComponent, View_StrategiesManagerComponent_Host_0, { strategies: "strategies", currentStrategy: "currentStrategy", bidRange: "bidRange", selectedStrategy: "selectedStrategy" }, { OnSave: "OnSave", Inited: "Inited" }, []);
export { StrategiesManagerComponentNgFactory as StrategiesManagerComponentNgFactory };
