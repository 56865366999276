import {ID} from '@datorama/akita';
import {PostMonitorBackend_V2ModelsvkVKAPIAdTargetGroup} from '../../../api/models/post-monitor-backend-_v2modelsvk-vkapiad-target-group';
import {APIReponseWrapperListPostMonitorBackend_V2ModelsvkMyTargetAPIRemarketingUsersList} from '../../../api/models/apireponse-wrapper-list-post-monitor-backend-_v2modelsvk-my-target-apiremarketing-users-list';
import {PostMonitorBackend_V2ModelsvkMyTargetAPIRemarketingUsersList} from '../../../api/models/post-monitor-backend-_v2modelsvk-my-target-apiremarketing-users-list';
import {AudiencesFacebookCustomAudience} from "../../../api/models/audiences-facebook-custom-audience";
import { EquinoxStudiosExternalAPIProviderYandexAudienceModelsSegmentsSegment } from '../../../api/models';

export interface RetargetingGroup {
  id: ID;
  name: string;
  type: string;
}

/**
 * A factory function that creates RetargetingGroups
 */
export function createRetargetingGroupFromVk(params: Partial<PostMonitorBackend_V2ModelsvkVKAPIAdTargetGroup>) {
  return {
    id: params.id,
    name: params.name,
  } as RetargetingGroup;
}

export function createRemarketingGroupFromMt(params: Partial<PostMonitorBackend_V2ModelsvkMyTargetAPIRemarketingUsersList>) {
  return {
    id: params.id,
    name: params.name,
    type: params.type
  } as RetargetingGroup;
}

export function createCustomAudienceFromFacebook(params: Partial<AudiencesFacebookCustomAudience>) {
  return {
    id: params.id,
    name: params.name
  } as RetargetingGroup;
}


export function createRemarketingGroupFromYandexAudience(params: EquinoxStudiosExternalAPIProviderYandexAudienceModelsSegmentsSegment) {
  return {
    id: params.id,
    name: params.name
  } as RetargetingGroup;
}