/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./automation-header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./automation-header.component";
var styles_AutomationHeaderComponent = [i0.styles];
var RenderType_AutomationHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AutomationHeaderComponent, data: {} });
export { RenderType_AutomationHeaderComponent as RenderType_AutomationHeaderComponent };
export function View_AutomationHeaderComponent_0(_l) { return i1.ɵvid(0, [], null, null); }
export function View_AutomationHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-automation-header", [], null, null, null, View_AutomationHeaderComponent_0, RenderType_AutomationHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i2.AutomationHeaderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AutomationHeaderComponentNgFactory = i1.ɵccf("app-automation-header", i2.AutomationHeaderComponent, View_AutomationHeaderComponent_Host_0, { backLink: "backLink", backLinkQuery: "backLinkQuery" }, {}, []);
export { AutomationHeaderComponentNgFactory as AutomationHeaderComponentNgFactory };
