import {Injectable} from '@angular/core';
import {QueryEntity} from '@datorama/akita';
import {UserIntegrationStatisticsState, UserIntegrationStatisticsStore} from './user-integration-statistics.store';
import {map} from "rxjs/operators";

@Injectable({providedIn: 'root'})
export class UserIntegrationStatisticsQuery extends QueryEntity<UserIntegrationStatisticsState> {

  /**
   * получить данные по колличеству транзакций для интеграции
   * @param integrationId
   */
  public integrationStatistic = (integrationId) => this.selectAll({filterBy: e => e.id === integrationId})
    .pipe(
      map(x => x[0] ? x[0].value : 0)
    );

  constructor(protected store: UserIntegrationStatisticsStore) {
    super(store);
  }

}
