import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {CreateAdNewLiteComponent} from '../new-ad-manager-lite/routes/create-ad-new-lite/create-ad-new-lite.component';
import {NewCreateAdProComponent} from './routes/new-create-ad-pro/new-create-ad-pro.component';
import {NewUpdateAdProComponent} from './routes/new-update-ad-pro/new-update-ad-pro.component';

const routes: Routes = [
  {
    path: 'automation',
    children: [
      {
        path: 'new-ad-manager',
        children: [
          {
            path: 'pro',
            children: [
              {
                path: 'update',
                component: NewUpdateAdProComponent
              },
              {
                path: 'create',
                component: NewCreateAdProComponent
              },
            ]
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NewAdManagerProRoutingModule {
}
