import {Component, OnInit} from '@angular/core';
import {AdFormManagerService} from '../../services/ad-form-manager/ad-form-manager.service';

@Component({
  selector: 'app-missing-admin-groups-alert',
  templateUrl: './missing-admin-groups-alert.component.html',
  styleUrls: ['./missing-admin-groups-alert.component.scss']
})
export class MissingAdminGroupsAlertComponent implements OnInit {

  constructor(
    private adFromManagerService: AdFormManagerService
  ) {
  }

  ngOnInit() {
  }

  public RefreshButton() {
    this.adFromManagerService.LoadAdminGroups()
      .subscribe(() => {
      });
  }

}
