import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import {HttpClient, HttpParams} from '@angular/common/http';
import { AffiliateMembersStatisticsByDateStore } from './affiliate-members-statistics-by-date.store';
import {
  AffiliateMembersStatisticsByDate,
  createAffiliateMembersStatisticsByDate
} from './affiliate-members-statistics-by-date.model';
import { tap } from 'rxjs/operators';
import {
  AffiliateMemberStattisticsByUser,
  createAffiliateMemberStattisticsByUser
} from "../../affiliate-member-statistics/state/affiliate-member-stattistics-by-user.model";
import {environment} from "../../../../environments/environment";

@Injectable({ providedIn: 'root' })
export class AffiliateMembersStatisticsByDateService {

  private baseUrl = `${environment.backendUri}/${environment.apiPrefix}`;

  constructor(private affiliateMembersStatisticsByDateStore: AffiliateMembersStatisticsByDateStore,
              private http: HttpClient) {
  }

  get(dateFrom: Date, dateTo: Date) {
    const params = this.GenerateQueryParams(dateFrom, dateTo);
    return this.http.get<AffiliateMemberStattisticsByUser[]>(`${this.baseUrl}/user-affiliate-statistics/by-date`, {
      params
    })
      .pipe(tap(entities => {
        const statistics = entities.map((x, i) => createAffiliateMembersStatisticsByDate(x, i));
        this.affiliateMembersStatisticsByDateStore.set(statistics);
      }));
  }

  /**
   * Формирование параметров
   * @param dateFrom
   * @param dateTo
   * @constructor
   */
  private GenerateQueryParams(dateFrom: Date, dateTo: Date) {
    const params = new HttpParams();
    params.set('dateFrom', dateFrom ? dateFrom.toString() : null);
    params.set('dateTo', dateTo ? dateTo.toString() : null);
    return params;
  }

  add(affiliateMembersStatisticsByDate: AffiliateMembersStatisticsByDate) {
    this.affiliateMembersStatisticsByDateStore.add(affiliateMembersStatisticsByDate);
  }

  update(id, affiliateMembersStatisticsByDate: Partial<AffiliateMembersStatisticsByDate>) {
    this.affiliateMembersStatisticsByDateStore.update(id, affiliateMembersStatisticsByDate);
  }

  remove(id: ID) {
    this.affiliateMembersStatisticsByDateStore.remove(id);
  }
}
