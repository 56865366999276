var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { PlatformsStore } from './platforms.store';
import { IntegrationService } from '../../../api/services';
import { createPlatform } from './platform.model';
import { PlatformsQuery } from './platforms.query';
import { ActivatedRoute } from '@angular/router';
import { CustomFieldValueService } from "../custom-field-value/custom-field-value.service";
import * as i0 from "@angular/core";
import * as i1 from "./platforms.store";
import * as i2 from "./platforms.query";
import * as i3 from "../../../api/services/integration.service";
import * as i4 from "@angular/router";
import * as i5 from "../custom-field-value/custom-field-value.service";
export var PlatformTypes;
(function (PlatformTypes) {
    PlatformTypes[PlatformTypes["ORIGIN"] = 1] = "ORIGIN";
    PlatformTypes[PlatformTypes["DESTINATION"] = 2] = "DESTINATION";
})(PlatformTypes || (PlatformTypes = {}));
var PlatformsService = /** @class */ (function () {
    function PlatformsService(store, query, integrationsService, route, customFieldValueService) {
        this.store = store;
        this.query = query;
        this.integrationsService = integrationsService;
        this.route = route;
        this.customFieldValueService = customFieldValueService;
    }
    /**
     * Load All Platforms for integration
     * @param {boolean} allFields - получать ли все поля
     * @constructor
     */
    PlatformsService.prototype.LoadIntegrations = function (allFields) {
        var _this = this;
        var filterOnlyActive = (this.route.snapshot.queryParams.filterOnlyActive !== 'false');
        this.integrationsService.GetIntegrationServices({
            allFields: allFields,
            sortFieldsAsc: true,
            filterOnlyActive: filterOnlyActive
        })
            .subscribe(function (integrations) {
            _this.store.set(integrations.data.map(function (x) { return createPlatform(x); }));
            integrations.data.forEach(function (integration) {
                integration.sections.forEach(function (section) {
                    if (section && section['meta']) {
                        var customFieldsValues = section['meta'].map(function (x) { return ({
                            Id: x.integrationServiceSectionMetaId,
                            Code: x.code,
                            Name: x.name,
                            PlatformCode: integration.code
                        }); });
                        _this.customFieldValueService.AddRange(customFieldsValues);
                    }
                });
            });
        });
    };
    /**
     * Select platform
     * @param platformCode
     * @param isOrigin
     * @constructor
     */
    PlatformsService.prototype.SelectPlatform = function (platformCode, isOrigin) {
        if (isOrigin) {
            this.SelectOriginPlatform(platformCode);
        }
        else {
            this.SelectDestinationPlatform(platformCode);
        }
    };
    /**
     * Select Origin Platform
     * @param platformCode
     * @constructor
     */
    PlatformsService.prototype.SelectOriginPlatform = function (platformCode) {
        this.store.update(function (state) { return (__assign({}, state, { ui: __assign({}, state.ui, { selectedOriginPlatform: platformCode, selectedDestinationPlatform: '' }) })); });
    };
    /**
     * Select Destionation Platform
     * @param platformCode
     * @constructor
     */
    PlatformsService.prototype.SelectDestinationPlatform = function (platformCode) {
        this.store.update(function (state) {
            var newState = __assign({}, state, { ui: __assign({}, state.ui, { selectedDestinationPlatform: platformCode }) });
            return newState;
        });
    };
    /**
     * Задает дополнительные параметры для платформы
     * @param {AssignationPlatforms} platformId - id платформы
     * @param {any} params - данные для хранения
     * @constructor
     */
    PlatformsService.prototype.SetParameters = function (platformId, params) {
        this.store.setParameters(platformId, params);
    };
    /**
     * Добавить обязательное поле
     * @param field
     * @constructor
     */
    PlatformsService.prototype.AddRequiredField = function (field) {
        this.store.update(function (state) {
            var requiredFields = Object.assign([], state.ui.requiredFields);
            if (!requiredFields.includes(field)) {
                requiredFields.push(field);
            }
            return __assign({}, state, { ui: __assign({}, state.ui, { requiredFields: requiredFields }) });
        });
    };
    /**
     * Отчистить обязательные поля
     * @constructor
     */
    PlatformsService.prototype.ClearRequiredFields = function () {
        this.store.update(function (state) { return (__assign({}, state, { ui: __assign({}, state.ui, { requiredFields: [] }) })); });
    };
    PlatformsService.ngInjectableDef = i0.defineInjectable({ factory: function PlatformsService_Factory() { return new PlatformsService(i0.inject(i1.PlatformsStore), i0.inject(i2.PlatformsQuery), i0.inject(i3.IntegrationService), i0.inject(i4.ActivatedRoute), i0.inject(i5.CustomFieldValueService)); }, token: PlatformsService, providedIn: "root" });
    return PlatformsService;
}());
export { PlatformsService };
