import { Injectable } from '@angular/core';
import { AffiliateLink } from './affiliate-link.model';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

export interface AffiliateLinksState extends EntityState<AffiliateLink> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'affiliate-links' })
export class AffiliateLinksStore extends EntityStore<AffiliateLinksState> {

  constructor() {
    super();
  }

}

