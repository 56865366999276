/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Strategy } from '../models/strategy';
import { BidRange } from '../models/bid-range';
@Injectable({
  providedIn: 'root',
})
class StrategiesService extends __BaseService {
  static readonly GetAllStrategiesPath = '/apiv2/strategies';
  static readonly UpdateStrategyPath = '/apiv2/strategies';
  static readonly CreateStrategyPath = '/apiv2/strategies';
  static readonly GetStrategyByIdPath = '/apiv2/strategies/{id}';
  static readonly RemoveStrategyPath = '/apiv2/strategies/{id}';
  static readonly GetBidRangesPath = '/apiv2/strategies/getbidranges';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `StrategiesService.GetAllStrategiesParams` containing the following parameters:
   *
   * - `showInactive`:
   *
   * - `onlyAllowedInCreationModule`:
   *
   * @return Success
   */
  GetAllStrategiesResponse(params: StrategiesService.GetAllStrategiesParams): __Observable<__StrictHttpResponse<Array<Strategy>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.showInactive != null) __params = __params.set('showInactive', params.showInactive.toString());
    if (params.onlyAllowedInCreationModule != null) __params = __params.set('onlyAllowedInCreationModule', params.onlyAllowedInCreationModule.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/strategies`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Strategy>>;
      })
    );
  }
  /**
   * @param params The `StrategiesService.GetAllStrategiesParams` containing the following parameters:
   *
   * - `showInactive`:
   *
   * - `onlyAllowedInCreationModule`:
   *
   * @return Success
   */
  GetAllStrategies(params: StrategiesService.GetAllStrategiesParams): __Observable<Array<Strategy>> {
    return this.GetAllStrategiesResponse(params).pipe(
      __map(_r => _r.body as Array<Strategy>)
    );
  }

  /**
   * @param strategy undefined
   * @return Success
   */
  UpdateStrategyResponse(strategy?: Strategy): __Observable<__StrictHttpResponse<Strategy>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = strategy;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/apiv2/strategies`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Strategy>;
      })
    );
  }
  /**
   * @param strategy undefined
   * @return Success
   */
  UpdateStrategy(strategy?: Strategy): __Observable<Strategy> {
    return this.UpdateStrategyResponse(strategy).pipe(
      __map(_r => _r.body as Strategy)
    );
  }

  /**
   * @param strategy undefined
   * @return Success
   */
  CreateStrategyResponse(strategy?: Strategy): __Observable<__StrictHttpResponse<Strategy>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = strategy;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/apiv2/strategies`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Strategy>;
      })
    );
  }
  /**
   * @param strategy undefined
   * @return Success
   */
  CreateStrategy(strategy?: Strategy): __Observable<Strategy> {
    return this.CreateStrategyResponse(strategy).pipe(
      __map(_r => _r.body as Strategy)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetStrategyByIdResponse(id: number): __Observable<__StrictHttpResponse<Array<Strategy>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/strategies/${(id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Strategy>>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetStrategyById(id: number): __Observable<Array<Strategy>> {
    return this.GetStrategyByIdResponse(id).pipe(
      __map(_r => _r.body as Array<Strategy>)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  RemoveStrategyResponse(id: number): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/apiv2/strategies/${(id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  RemoveStrategy(id: number): __Observable<{}> {
    return this.RemoveStrategyResponse(id).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * @return Success
   */
  GetBidRangesResponse(): __Observable<__StrictHttpResponse<Array<BidRange>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/strategies/getbidranges`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<BidRange>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetBidRanges(): __Observable<Array<BidRange>> {
    return this.GetBidRangesResponse().pipe(
      __map(_r => _r.body as Array<BidRange>)
    );
  }
}

module StrategiesService {

  /**
   * Parameters for GetAllStrategies
   */
  export interface GetAllStrategiesParams {
    showInactive: boolean;
    onlyAllowedInCreationModule: boolean;
  }
}

export { StrategiesService }
