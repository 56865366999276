<div fxLayout="row nowrap" fxLayoutAlign="space-between" fxFlexAlign="center">
  <h2 mat-dialog-title>Объявление создано</h2>
  <button mat-icon-button mat-dialog-close (click)="CloseButton()">
    <mat-icon>close</mat-icon>
  </button>
</div>

<div mat-dialog-content>

  <ng-container *ngIf="!sendModerationError && !startAfterModerationError; else errors">
    <div *ngIf="data.sendModeration === 'true'">Объявление отправлено на модерацию</div>
    <div *ngIf="data.startAfterModeration === 'true'">Объявление будет запущено после модерации</div>
  </ng-container>
  <ng-template #errors>
    <div *ngIf="sendModerationError">
      Ошибка! Не удалось отправить на модерацию объявление! Убедитесь, что кампания запущена,
      не достигнуты ограничения в общем и дневном лимитах и
      баланс рекламного кабинета не равен 0. Узнать баланс вы можете перейдя по этой
      <a href="https://vk.com/ads?act=budget" target="_blank" rel="nofollow noopener">ссылке</a>
    </div>
    <div *ngIf="startAfterModerationError">Ошибка! не удалось запустить объявление</div>
  </ng-template>

</div>

<div mat-dialog-actions fxLayout="row" fxLayoutAlign="flex-end">
  <button mat-button mat-raised-button color="primary" (click)="CloseButton()">Ок</button>
</div>
