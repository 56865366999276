import {Component, OnInit} from '@angular/core';
import {AutomationVkService, AutomationVkSlotService} from '../../../../api/services';
import {AccountViewModel} from '../../../models/view-models/account-view-model';
import 'rxjs-compat/add/operator/mergeMap';
import 'rxjs-compat/add/operator/map';
import {of} from 'rxjs/internal/observable/of';
import {map, mergeMap} from 'rxjs/operators';

@Component({
  selector: 'app-new-create-ad',
  templateUrl: './new-create-ad.component.html',
  styleUrls: ['./new-create-ad.component.scss']
})
export class NewCreateAdComponent implements OnInit {

  public accounts = [];

  constructor(
    private automationVkService: AutomationVkService,
    private automationSlotsVkService: AutomationVkSlotService
  ) {
  }

  ngOnInit() {
    this.LoadData();
  }

  public LoadData() {
    this.LoadSlots()
      .flatMap(this.LoadAccounts.bind(this))
      .flatMap(this.LoadClients.bind(this))
      .subscribe(data => {
        console.log(data);
      })
    ;
  }

  public LoadSlots() {
    return this.automationSlotsVkService
      .GetSlots();
  }

  public LoadAccounts() {
    return this.automationVkService
      .GetAccounts()
      .map(accounts => {
        this.accounts = accounts.data.map((account, index) => new AccountViewModel(
          account.account_id,
          account.account_name,
          account.access_role,
          account.account_status,
          account.account_type,
          false,
          index
        ));

        return accounts.data;
      });
  }

  public LoadClients(accounts) {
    return of(accounts)
      .pipe();
  }

}
