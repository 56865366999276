/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./payment-successed.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./payment-successed.component";
import * as i3 from "@angular/router";
var styles_PaymentSuccessedComponent = [i0.styles];
var RenderType_PaymentSuccessedComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PaymentSuccessedComponent, data: {} });
export { RenderType_PaymentSuccessedComponent as RenderType_PaymentSuccessedComponent };
export function View_PaymentSuccessedComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "payments-successed-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "payments-successed-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "payments-sucessed-header"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u0421\u043F\u0430\u0441\u0438\u0431\u043E! "])), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "payments-sucessed-message"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u0412\u0430\u0448 \u043F\u043B\u0430\u0442\u0435\u0436 \u043F\u0440\u043E\u0432\u0435\u0434\u0451\u043D."])), (_l()(), i1.ɵeld(6, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u041A\u0430\u043A \u0442\u043E\u043B\u044C\u043A\u043E \u043C\u044B \u043F\u043E\u043B\u0443\u0447\u0438\u043C \u0441\u0440\u0435\u0434\u0441\u0442\u0432\u0430 \u0434\u043E\u0441\u0442\u0443\u043F \u0431\u0443\u0434\u0435\u0442 \u0430\u0432\u0442\u043E\u043C\u0430\u0442\u0438\u0447\u0435\u0441\u043A\u0438 \u043F\u0440\u043E\u0434\u043B\u0451\u043D. "])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "payments-sucessed-button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "button", [["class", "vk-standart-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.GoToDashboard() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u041F\u0440\u043E\u0434\u043E\u043B\u0436\u0438\u0442\u044C \u0440\u0430\u0431\u043E\u0442\u0443 "]))], null, null); }
export function View_PaymentSuccessedComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-payment-successed", [], null, null, null, View_PaymentSuccessedComponent_0, RenderType_PaymentSuccessedComponent)), i1.ɵdid(1, 114688, null, 0, i2.PaymentSuccessedComponent, [i3.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PaymentSuccessedComponentNgFactory = i1.ɵccf("app-payment-successed", i2.PaymentSuccessedComponent, View_PaymentSuccessedComponent_Host_0, {}, {}, []);
export { PaymentSuccessedComponentNgFactory as PaymentSuccessedComponentNgFactory };
