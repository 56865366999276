<div class="user-call">
  <div class="user-call__date">{{userCall.callDate | utcToLocalDate | momentDate:"DD.MM.YYYY [в] HH:mm"}}</div>
  <div class="user-call__status">
    <a [matMenuTriggerFor]="statusMenu">{{userCall?.callStatus?.title}}</a>

    <mat-menu #statusMenu="matMenu">
      <ng-container *ngFor="let status of callStatuses | async">
        <button mat-menu-item (click)="ChangeCallStatus(status.id)">{{status.title}}</button>
      </ng-container>
    </mat-menu>
  </div>
  <div class="user-call__comment">
    {{userCall.comment}}
  </div>
</div>
