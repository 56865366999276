/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./new-import-crm-form.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../new-import-crm-section/new-import-crm-section.component.ngfactory";
import * as i3 from "@angular/forms";
import * as i4 from "../new-import-crm-section/new-import-crm-section.component";
import * as i5 from "../../services/new-import-crm/new-import-crm.service";
import * as i6 from "../../../../stores/crm-pipelines/crm-pipelines.query";
import * as i7 from "@angular/common";
import * as i8 from "./new-import-crm-form.component";
import * as i9 from "@datorama/akita-ng-forms-manager";
var styles_NewImportCrmFormComponent = [i0.styles];
var RenderType_NewImportCrmFormComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NewImportCrmFormComponent, data: {} });
export { RenderType_NewImportCrmFormComponent as RenderType_NewImportCrmFormComponent };
function View_NewImportCrmFormComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "app-new-import-crm-section", [], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_NewImportCrmSectionComponent_0, i2.RenderType_NewImportCrmSectionComponent)), i1.ɵdid(2, 540672, null, 0, i3.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i3.ControlContainer, null, [i3.FormGroupDirective]), i1.ɵdid(4, 16384, null, 0, i3.NgControlStatusGroup, [[4, i3.ControlContainer]], null, null), i1.ɵdid(5, 245760, null, 0, i4.NewImportCrmSectionComponent, [i1.ChangeDetectorRef, i5.NewImportCrmService, i6.CrmPipelinesQuery], { form: [0, "form"], formGroup: [1, "formGroup"], parentForm: [2, "parentForm"], formQuestions: [3, "formQuestions"], section: [4, "section"], advancedSettings: [5, "advancedSettings"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _v.context.ngIf; _ck(_v, 2, 0, currVal_7); var currVal_8 = _co.form; var currVal_9 = _v.context.ngIf; var currVal_10 = _co.formGroup; var currVal_11 = _co.formQuestions; var currVal_12 = _v.parent.context.$implicit; var currVal_13 = _co.GetAdvancedSettingsForSection(_v.parent.context.$implicit.code); _ck(_v, 5, 0, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 4).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 4).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 4).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 4).ngClassValid; var currVal_5 = i1.ɵnov(_v, 4).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 4).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_NewImportCrmFormComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "crm-section-settings__item"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NewImportCrmFormComponent_3)), i1.ɵdid(3, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.GetSectionFrom(_v.context.$implicit.code); _ck(_v, 3, 0, currVal_0); }, null); }
function View_NewImportCrmFormComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "crm-sections-settings"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 540672, null, 0, i3.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i3.ControlContainer, null, [i3.FormGroupDirective]), i1.ɵdid(3, 16384, null, 0, i3.NgControlStatusGroup, [[4, i3.ControlContainer]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NewImportCrmFormComponent_2)), i1.ɵdid(5, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.formGroup; _ck(_v, 1, 0, currVal_7); var currVal_8 = _co.sections; _ck(_v, 5, 0, currVal_8); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 3).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 3).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 3).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 3).ngClassValid; var currVal_5 = i1.ɵnov(_v, 3).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 3).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_NewImportCrmFormComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_NewImportCrmFormComponent_1)), i1.ɵdid(1, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.formGroup; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_NewImportCrmFormComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-new-import-crm-form", [], null, null, null, View_NewImportCrmFormComponent_0, RenderType_NewImportCrmFormComponent)), i1.ɵdid(1, 114688, null, 0, i8.NewImportCrmFormComponent, [i9.AkitaNgFormsManager], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NewImportCrmFormComponentNgFactory = i1.ɵccf("app-new-import-crm-form", i8.NewImportCrmFormComponent, View_NewImportCrmFormComponent_Host_0, { form: "form", formGroup: "formGroup", sections: "sections", advancedSettings: "advancedSettings" }, {}, []);
export { NewImportCrmFormComponentNgFactory as NewImportCrmFormComponentNgFactory };
