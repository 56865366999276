import {AfterViewInit, ChangeDetectorRef, Component, Input, ViewChild} from '@angular/core';
import {MatPaginator, MatSort} from '@angular/material';
import {CallDataListDataSource} from './call-data-list-datasource';

@Component({
  selector: 'call-data-list',
  templateUrl: './call-data-list.component.html',
  styleUrls: ['./call-data-list.component.css']
})
export class CallDataListComponent implements AfterViewInit {
  public showTable = false;
  @Input() public calls = [];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: CallDataListDataSource;

  constructor(
    private cd: ChangeDetectorRef
  ) {
  }

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['id', 'name'];

  ngAfterViewInit() {
    this.dataSource = new CallDataListDataSource(this.paginator, this.sort);
    this.dataSource.data = this.calls;
    // this.showTable = true;
    // this.cd.reattach();
  }
}
