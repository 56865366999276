import {Validators} from '@angular/forms';
import {Validator} from '../../api/models';

export function createValidator(validator: Validator, params) {
  const validatorInfo = {
    code: validator.code,
    validator: null,
    message: validator.invalidMessage
  };
  switch (validator.code.toLowerCase()) {
    case 'required':
      validatorInfo.validator = Validators.required;
      break;
    case 'min':
      validatorInfo.validator = Validators.min(params);
      break;
    case 'max':
      validatorInfo.validator = Validators.max(params);
      break;
    default:
      throw new Error(`Validator with code: ${validator.code}`);
  }
  return validatorInfo;
}

export function renderMessage(message, params: object) {
  Object.keys(params)
    .forEach(key => {
      message = message.replace(`{{${key}}}`, params[key]);
    });

  return message;
}
