import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-automation-header',
    templateUrl: './automation-header.component.html',
    styleUrls: ['./automation-header.component.scss']
})
export class AutomationHeaderComponent implements OnInit {

    @Input() public backLink: string;
    @Input() public backLinkQuery: object;

    constructor() {
    }

    ngOnInit() {
    }

}
