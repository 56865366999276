/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./ad-promoposts-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../promopost/promopost.component.ngfactory";
import * as i3 from "../promopost/promopost.component";
import * as i4 from "@angular/common";
import * as i5 from "./ad-promoposts-list.component";
import * as i6 from "../../services/ad-form-manager/ad-form-manager.service";
var styles_AdPromopostsListComponent = [i0.styles];
var RenderType_AdPromopostsListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AdPromopostsListComponent, data: {} });
export { RenderType_AdPromopostsListComponent as RenderType_AdPromopostsListComponent };
function View_AdPromopostsListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-promopost", [], null, null, null, i2.View_PromopostComponent_0, i2.RenderType_PromopostComponent)), i1.ɵdid(2, 114688, null, 0, i3.PromopostComponent, [], { promopost: [0, "promopost"], selectedGroup: [1, "selectedGroup"], showEditForm: [2, "showEditForm"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.selectedGroup; var currVal_2 = true; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_AdPromopostsListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "ad-promopost-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AdPromopostsListComponent_1)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.promoposts; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_AdPromopostsListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-ad-promoposts-list", [], null, null, null, View_AdPromopostsListComponent_0, RenderType_AdPromopostsListComponent)), i1.ɵdid(1, 114688, null, 0, i5.AdPromopostsListComponent, [i6.AdFormManagerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AdPromopostsListComponentNgFactory = i1.ɵccf("app-ad-promoposts-list", i5.AdPromopostsListComponent, View_AdPromopostsListComponent_Host_0, {}, {}, []);
export { AdPromopostsListComponentNgFactory as AdPromopostsListComponentNgFactory };
