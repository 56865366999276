<ng-container *ngIf="accountsLoaded; else loadingScreen">
  <ng-container *ngIf="accounts.length > 0; else accountsManager">
    <form class="ad-network-import" [formGroup]="form" *ngIf="form">
      <div class="ad-network-import__accounts">
        <vk-form-field orientation="vertical" controlsWidth="100%" labelPosition="left">
          <vk-label>
            Рекламный кабинет
            <app-suggestion-info code="AD_NETWORK_ACCOUNT"></app-suggestion-info>
          </vk-label>
          <mat-select formControlName="accountId" vkInput placeholder="Выберите рекламный кабинет">
            <ng-container *ngFor="let account of accounts">
              <mat-option [value]="[account.account_id, account.client_id]">{{account.account_name}}</mat-option>
            </ng-container>
          </mat-select>
          <vk-input-options>
            <div>
              <mat-spinner diameter="18" class="vk-input__spinner" *ngIf="loading"></mat-spinner>
            </div>
          </vk-input-options>
        </vk-form-field>
      </div>
      
      <ng-container *ngIf="isPipeliningCheckboxVisible()">
        <mat-checkbox class="no-margin-checkbox" formControlName="splitByPipelines">Разбить по этапам воронки продаж
        </mat-checkbox>
        <app-suggestion-info code="SPLIT_BY_PIPELINE_STATUSES"></app-suggestion-info>
      </ng-container>

      <div class="ad-network-import__retargeting-groups" [hidden]="!form.get('accountId').value"
           formGroupName="pipelineStatuses">
        <ng-container *ngIf="splitByPipelines; else withoutSplit">
          <ng-container *ngIf="!(isSelectedUonTravel$ | async); else uonTravelFuckingTemplate">
            <div *ngIf="hasLeads">
              <div class="ad-network-import__retargeting-groups-heading">Лиды</div>
              <ng-container *ngFor="let pipeline of leadPipelineStatuses$ | async; let i = index">
                <div [formGroupName]="'pipeline_'  + pipeline.type + '_' + +pipeline.id">
                  <vk-form-field controlsWidth="300px" labelWidth="300px" labelPosition="left" [flexible]="true">
                    <vk-label>
                      {{pipeline.name}}
                      <app-suggestion-info [code]="type + '_SELECTOR_PIPELINE'"></app-suggestion-info>
                    </vk-label>
                    <mat-select formControlName="retargetingGroups" vkInput multiple
                                [placeholder]="pipelineStatusPlaceholder">
                      <ng-container *ngFor="let retargetingGroup of retargetingGroups$ | async">
                        <mat-option [value]="retargetingGroup.id">{{retargetingGroup.name}}</mat-option>
                      </ng-container>
                    </mat-select>
                  </vk-form-field>
                </div>
              </ng-container>
              <br><br>
            </div>
            <div>
              <div class="ad-network-import__retargeting-groups-heading" [hidden]="!hasLeads">{{dealsTitle}}</div>
              <ng-container *ngFor="let pipeline of pipelineStatuses$ | async; let i = index">
                <div [formGroupName]="'pipeline_' + pipeline.type + '_'  +pipeline.id">
                  <vk-form-field controlsWidth="300px" labelWidth="300px" labelPosition="left" [flexible]="true">
                    <vk-label>
                      {{pipeline.name}}
                      <app-suggestion-info [code]="type + '_SELECTOR_PIPELINE'"></app-suggestion-info>
                    </vk-label>
                    <mat-select formControlName="retargetingGroups" vkInput multiple
                                [placeholder]="pipelineStatusPlaceholder">
                      <ng-container *ngFor="let retargetingGroup of retargetingGroups$ | async">
                        <mat-option [value]="retargetingGroup.id">{{retargetingGroup.name}}</mat-option>
                      </ng-container>
                    </mat-select>
                  </vk-form-field>
                </div>
              </ng-container>
            </div>
          </ng-container>
          <ng-template #uonTravelFuckingTemplate>
            <div>
              <div class="ad-network-import__retargeting-groups-heading" [hidden]="!hasLeads">Обращения</div>
              <ng-container *ngFor="let pipeline of pipelineStatuses$ | async; let i = index">
                <div [formGroupName]="'pipeline_' + pipeline.type + '_'  + pipeline.id">
                  <vk-form-field controlsWidth="300px" labelWidth="300px" labelPosition="left" [flexible]="true">
                    <vk-label>
                      {{pipeline.name}}
                      <app-suggestion-info [code]="type + '_SELECTOR_PIPELINE'"></app-suggestion-info>
                    </vk-label>
                    <mat-select formControlName="retargetingGroups" vkInput multiple
                                [placeholder]="pipelineStatusPlaceholder">
                      <ng-container *ngFor="let retargetingGroup of retargetingGroups$ | async">
                        <mat-option [value]="retargetingGroup.id">{{retargetingGroup.name}}</mat-option>
                      </ng-container>
                    </mat-select>
                  </vk-form-field>
                </div>
              </ng-container>
            </div>
            <div *ngIf="hasLeads">
              <div class="ad-network-import__retargeting-groups-heading">Заявки</div>
              <ng-container *ngFor="let pipeline of leadPipelineStatuses$ | async; let i = index">
                <div [formGroupName]="'pipeline_'  + pipeline.type + '_' + +pipeline.id">
                  <vk-form-field controlsWidth="300px" labelWidth="300px" labelPosition="left" [flexible]="true">
                    <vk-label>
                      {{pipeline.name}}
                      <app-suggestion-info [code]="type + '_SELECTOR_PIPELINE'"></app-suggestion-info>
                    </vk-label>
                    <mat-select formControlName="retargetingGroups" vkInput multiple
                                [placeholder]="pipelineStatusPlaceholder">
                      <ng-container *ngFor="let retargetingGroup of retargetingGroups$ | async">
                        <mat-option [value]="retargetingGroup.id">{{retargetingGroup.name}}</mat-option>
                      </ng-container>
                    </mat-select>
                  </vk-form-field>
                </div>
              </ng-container>
              <br><br>
            </div>
          </ng-template>
        </ng-container>
        <ng-template #withoutSplit>
          <vk-form-field controlsWidth="250px" labelWidth="300px" labelPosition="left" formGroupName="pipeline_null"
                         [flexible]="true">
            <vk-label>
              {{withoutSplitLabel}}
              <app-suggestion-info [code]="withoutSplitSuggestionCode()"></app-suggestion-info>
            </vk-label>
            <mat-select formControlName="retargetingGroups" vkInput multiple
                        [placeholder]="pipelineStatusPlaceholder">
              <ng-container *ngFor="let retargetingGroup of retargetingGroups$ | async">
                <mat-option [value]="retargetingGroup.id">{{retargetingGroup.name}}</mat-option>
              </ng-container>
            </mat-select>
          </vk-form-field>
        </ng-template>
      </div>
    </form>
  </ng-container>
  <ng-template #accountsManager>
    <app-new-accounts-manager
      (bindingChanged)="InitAll()"
      [accounts]="allAccounts"
      [slots]="slots"
    >
    </app-new-accounts-manager>
  </ng-template>

</ng-container>
<ng-template #loadingScreen>
  <app-loading-screen diameter="18">
    Подождите, идет загрузка данных
  </app-loading-screen>
</ng-template>
