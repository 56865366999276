/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./ad-network-import.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../vk/components/retargeting-groups-settings/retargeting-groups-settings.component.ngfactory";
import * as i3 from "../../../vk/components/retargeting-groups-settings/retargeting-groups-settings.component";
import * as i4 from "../../../../stores/retargeting-groups/retargeting-groups.service";
import * as i5 from "../../../../stores/retargeting-groups/retargeting-groups.query";
import * as i6 from "@angular/forms";
import * as i7 from "@datorama/akita-ng-forms-manager";
import * as i8 from "../../../../stores/crm-pipelines/crm-pipelines.query";
import * as i9 from "../../../../stores/platform-assignations/platform-assignations.query";
import * as i10 from "../../../../stores/platforms/platforms.query";
import * as i11 from "../../../../../api/services/facebook-ad-account.service";
import * as i12 from "../../../my-target/components/remarketing-groups-settings/remarketing-groups-settings.component.ngfactory";
import * as i13 from "../../../my-target/components/remarketing-groups-settings/remarketing-groups-settings.component";
import * as i14 from "../../../../stores/crm-pipelines/crm-pipelines.service";
import * as i15 from "../../../yandex-metrika/components/yandex-audiences-import/yandex-audiences-import.component.ngfactory";
import * as i16 from "../../../yandex-metrika/components/yandex-audiences-import/yandex-audiences-import.component";
import * as i17 from "@angular/common";
import * as i18 from "./ad-network-import.component";
var styles_AdNetworkImportComponent = [i0.styles];
var RenderType_AdNetworkImportComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AdNetworkImportComponent, data: {} });
export { RenderType_AdNetworkImportComponent as RenderType_AdNetworkImportComponent };
function View_AdNetworkImportComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-retargeting-groups-settings", [], null, null, null, i2.View_RetargetingGroupsSettingsComponent_0, i2.RenderType_RetargetingGroupsSettingsComponent)), i1.ɵdid(2, 4964352, null, 0, i3.RetargetingGroupsSettingsComponent, [i4.RetargetingGroupsService, i5.RetargetingGroupsQuery, i6.FormBuilder, i7.AkitaNgFormsManager, i8.CrmPipelinesQuery, i1.ChangeDetectorRef, i9.PlatformAssignationsQuery, i10.PlatformsQuery, i11.FacebookAdAccountService], { type: [0, "type"], platform: [1, "platform"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.type; var currVal_1 = _co.platform; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_AdNetworkImportComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-retargeting-groups-settings", [], null, null, null, i2.View_RetargetingGroupsSettingsComponent_0, i2.RenderType_RetargetingGroupsSettingsComponent)), i1.ɵdid(2, 4964352, null, 0, i3.RetargetingGroupsSettingsComponent, [i4.RetargetingGroupsService, i5.RetargetingGroupsQuery, i6.FormBuilder, i7.AkitaNgFormsManager, i8.CrmPipelinesQuery, i1.ChangeDetectorRef, i9.PlatformAssignationsQuery, i10.PlatformsQuery, i11.FacebookAdAccountService], { type: [0, "type"], platform: [1, "platform"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.type; var currVal_1 = _co.platform; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_AdNetworkImportComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-remarketing-groups-settings", [], null, null, null, i12.View_RemarketingGroupsSettingsComponent_0, i12.RenderType_RemarketingGroupsSettingsComponent)), i1.ɵdid(2, 245760, null, 0, i13.RemarketingGroupsSettingsComponent, [i5.RetargetingGroupsQuery, i4.RetargetingGroupsService, i8.CrmPipelinesQuery, i14.CrmPipelinesService, i7.AkitaNgFormsManager, i10.PlatformsQuery, i9.PlatformAssignationsQuery], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_AdNetworkImportComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-yandex-audiences-import", [], null, null, null, i15.View_YandexAudiencesImportComponent_0, i15.RenderType_YandexAudiencesImportComponent)), i1.ɵdid(2, 245760, null, 0, i16.YandexAudiencesImportComponent, [i5.RetargetingGroupsQuery, i4.RetargetingGroupsService, i8.CrmPipelinesQuery, i14.CrmPipelinesService, i7.AkitaNgFormsManager, i10.PlatformsQuery, i9.PlatformAssignationsQuery, i4.RetargetingGroupsService, i9.PlatformAssignationsQuery], { type: [0, "type"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.type; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_AdNetworkImportComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AdNetworkImportComponent_1)), i1.ɵdid(1, 16384, null, 0, i17.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AdNetworkImportComponent_2)), i1.ɵdid(3, 16384, null, 0, i17.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AdNetworkImportComponent_3)), i1.ɵdid(5, 16384, null, 0, i17.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AdNetworkImportComponent_4)), i1.ɵdid(7, 16384, null, 0, i17.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.type === "VK_RETARGETING_GROUPS"); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.type === "FB_CUSTOM_AUDIENCES"); _ck(_v, 3, 0, currVal_1); var currVal_2 = (_co.type === "MT_REMARKETING_GROUPS"); _ck(_v, 5, 0, currVal_2); var currVal_3 = (_co.type === "YANDEX_AUDIENCES"); _ck(_v, 7, 0, currVal_3); }, null); }
export function View_AdNetworkImportComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-ad-network-import", [], null, null, null, View_AdNetworkImportComponent_0, RenderType_AdNetworkImportComponent)), i1.ɵdid(1, 49152, null, 0, i18.AdNetworkImportComponent, [i10.PlatformsQuery], null, null)], null, null); }
var AdNetworkImportComponentNgFactory = i1.ɵccf("app-ad-network-import", i18.AdNetworkImportComponent, View_AdNetworkImportComponent_Host_0, { type: "type", platform: "platform" }, {}, []);
export { AdNetworkImportComponentNgFactory as AdNetworkImportComponentNgFactory };
