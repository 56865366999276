import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-new-payments',
  template: `
    <p>
      new-payments works!
    </p>
  `,
  styles: []
})
export class NewPaymentsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
