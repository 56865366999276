import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Strategy} from '../../../api/models/strategy';
import {AdViewModel} from "../../models/view-models/ad-view-model";

@Component({
  selector: 'app-strategy-selection-form',
  templateUrl: './strategy-selection-form.component.html',
  styleUrls: ['./strategy-selection-form.component.scss']
})
export class StrategySelectionFormComponent implements OnInit {
  @Input() public strategies: Array<Strategy>;
  @Input() public showInactiveStrategies: boolean = false;
  @Output() public onStrategyConfirmed: EventEmitter<Strategy> = new EventEmitter<Strategy>();

  public showMore: Array<number> = [];

  public selectedStrategyId: number = 0;

  constructor() { }

  ngOnInit() {
  }

  public IsShowingMore(strategyId: number): boolean {
    return this.showMore.indexOf(strategyId) >= 0;
  }

  public SetShowMoreStatus(strategyId: number, showFullDescription: boolean): void {
    console.log(`SetShowMoreStatus set ${showFullDescription} to ${strategyId}`);

    const index = this.showMore.indexOf(strategyId);

    if (showFullDescription) {
      if (index < 0) {
        this.showMore.push(strategyId);
      }
    } else {
      if (index >= 0) {
        this.showMore.splice(index, 1);
      }
    }
  }

  public ConfirmStrategySelection(): void {
    console.log('Emiting strategy selection', this.selectedStrategyId);
    const selectedStrategy = this.strategies.find(x => x.id === this.selectedStrategyId);
    console.log('Emiting strategy selected', selectedStrategy);

    this.onStrategyConfirmed.emit(selectedStrategy);
  }
}
