/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { APIReponseWrapperListGroup } from '../models/apireponse-wrapper-list-group';
import { VKGroupRequest } from '../models/vkgroup-request';
import { APIReponseWrapperListGroupAudienceHistory } from '../models/apireponse-wrapper-list-group-audience-history';
import { APIReponseWrapperListVKPostAnalytics } from '../models/apireponse-wrapper-list-vkpost-analytics';
import { APIReponseWrapperListEngagementByDateVKEngagement } from '../models/apireponse-wrapper-list-engagement-by-date-vkengagement';
@Injectable({
  providedIn: 'root',
})
class VKGroupService extends __BaseService {
  static readonly findByFilterPath = '/apiv2/vk/group/byfilter';
  static readonly getMembersHistoryPath = '/apiv2/vk/group/membershistory';
  static readonly getGroupPostAnalyticsPath = '/apiv2/vk/group/postanalytics';
  static readonly getGroupEngagementAnalyticsPath = '/apiv2/vk/group/engagementanalytics';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param request undefined
   * @return Success
   */
  findByFilterResponse(request?: VKGroupRequest): __Observable<__StrictHttpResponse<APIReponseWrapperListGroup>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/apiv2/vk/group/byfilter`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<APIReponseWrapperListGroup>;
      })
    );
  }
  /**
   * @param request undefined
   * @return Success
   */
  findByFilter(request?: VKGroupRequest): __Observable<APIReponseWrapperListGroup> {
    return this.findByFilterResponse(request).pipe(
      __map(_r => _r.body as APIReponseWrapperListGroup)
    );
  }

  /**
   * @param params The `VKGroupService.GetMembersHistoryParams` containing the following parameters:
   *
   * - `sortDescend`:
   *
   * - `id`:
   *
   * - `to`:
   *
   * - `from`:
   *
   * @return Success
   */
  getMembersHistoryResponse(params: VKGroupService.GetMembersHistoryParams): __Observable<__StrictHttpResponse<APIReponseWrapperListGroupAudienceHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortDescend != null) __params = __params.set('sortDescend', params.sortDescend.toString());
    if (params.id != null) __params = __params.set('id', params.id.toString());
    if (params.to != null) __params = __params.set('to', params.to.toString());
    if (params.from != null) __params = __params.set('from', params.from.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/apiv2/vk/group/membershistory`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<APIReponseWrapperListGroupAudienceHistory>;
      })
    );
  }
  /**
   * @param params The `VKGroupService.GetMembersHistoryParams` containing the following parameters:
   *
   * - `sortDescend`:
   *
   * - `id`:
   *
   * - `to`:
   *
   * - `from`:
   *
   * @return Success
   */
  getMembersHistory(params: VKGroupService.GetMembersHistoryParams): __Observable<APIReponseWrapperListGroupAudienceHistory> {
    return this.getMembersHistoryResponse(params).pipe(
      __map(_r => _r.body as APIReponseWrapperListGroupAudienceHistory)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  getGroupPostAnalyticsResponse(id: number): __Observable<__StrictHttpResponse<APIReponseWrapperListVKPostAnalytics>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set('id', id.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/apiv2/vk/group/postanalytics`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<APIReponseWrapperListVKPostAnalytics>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  getGroupPostAnalytics(id: number): __Observable<APIReponseWrapperListVKPostAnalytics> {
    return this.getGroupPostAnalyticsResponse(id).pipe(
      __map(_r => _r.body as APIReponseWrapperListVKPostAnalytics)
    );
  }

  /**
   * @param params The `VKGroupService.GetGroupEngagementAnalyticsParams` containing the following parameters:
   *
   * - `sortDescend`:
   *
   * - `id`:
   *
   * - `to`:
   *
   * - `from`:
   *
   * @return Success
   */
  getGroupEngagementAnalyticsResponse(params: VKGroupService.GetGroupEngagementAnalyticsParams): __Observable<__StrictHttpResponse<APIReponseWrapperListEngagementByDateVKEngagement>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortDescend != null) __params = __params.set('sortDescend', params.sortDescend.toString());
    if (params.id != null) __params = __params.set('id', params.id.toString());
    if (params.to != null) __params = __params.set('to', params.to.toString());
    if (params.from != null) __params = __params.set('from', params.from.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/apiv2/vk/group/engagementanalytics`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<APIReponseWrapperListEngagementByDateVKEngagement>;
      })
    );
  }
  /**
   * @param params The `VKGroupService.GetGroupEngagementAnalyticsParams` containing the following parameters:
   *
   * - `sortDescend`:
   *
   * - `id`:
   *
   * - `to`:
   *
   * - `from`:
   *
   * @return Success
   */
  getGroupEngagementAnalytics(params: VKGroupService.GetGroupEngagementAnalyticsParams): __Observable<APIReponseWrapperListEngagementByDateVKEngagement> {
    return this.getGroupEngagementAnalyticsResponse(params).pipe(
      __map(_r => _r.body as APIReponseWrapperListEngagementByDateVKEngagement)
    );
  }
}

module VKGroupService {

  /**
   * Parameters for getMembersHistory
   */
  export interface GetMembersHistoryParams {
    sortDescend: boolean;
    id: number;
    to?: string;
    from?: string;
  }

  /**
   * Parameters for getGroupEngagementAnalytics
   */
  export interface GetGroupEngagementAnalyticsParams {
    sortDescend: boolean;
    id: number;
    to?: string;
    from?: string;
  }
}

export { VKGroupService }
