import {Component, Input, OnInit} from '@angular/core';
import {PostMonitorBackend_V2ModelsPaymentsTariffPackage} from "../../../api/models/post-monitor-backend-_v2models-payments-tariff-package";

@Component({
  selector: 'app-tariff-plan',
  templateUrl: './tariff-plan.component.html',
  styleUrls: ['./tariff-plan.component.scss']
})
export class TariffPlanComponent implements OnInit {

  @Input() public tariffPlan: PostMonitorBackend_V2ModelsPaymentsTariffPackage;
  @Input() public expiredAt: Date;

  constructor() { }

  ngOnInit() {
  }

}
