import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {PromopostViewModel} from '../../../models/promopost.view-model';
import {ControlContainer} from '@angular/forms';
import {ImagesManagerComponent} from '../../images-manager/images-manager.component';
import {VkApiService} from '../../../../../vk/services/vk-api.service';
import {AutomationVkService} from '../../../../../api/services/automation-vk.service';

@Component({
  selector: 'app-create-promopost',
  templateUrl: './create-promopost.component.html',
  styleUrls: ['./create-promopost.component.scss']
})
export class CreatePromopostComponent implements OnInit {

  @Input() public promopost: PromopostViewModel = {
    text: 'Здесь будет текст вашего поста',
    image: 'https://via.placeholder.com/506x460',
    groupId: '',
    albumId: '',
    linkTitle: '',
    linkAddress: ''
  };
  @Input() public isUpdating: boolean = null;
  @Input() public categories: Array<any>;
  @Output() public promopostChange: EventEmitter<PromopostViewModel> = new EventEmitter<PromopostViewModel>();

  @Output() public ControlAdded: EventEmitter<any> = new EventEmitter();
  @Output() public ControlRemoved: EventEmitter<any> = new EventEmitter();

  @Output() onNext: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('imagesManager') ImagesManager: ImagesManagerComponent;

  public selectedGroup: any = null;

  public type: string = 'linear';

  constructor(
    public controlContainer: ControlContainer,
    private cd: ChangeDetectorRef,
    private api: AutomationVkService
  ) {
  }

  public get Posts() {
    const promoposts = [];
    const images = this.controlContainer.control.get('image').value;
    const texts = this.controlContainer.control.get('text').value;
    if (this.controlContainer.control.get('type').value === 'linear') {
      const min = Math.min(images.length, texts.length);
      for (let i = 0; i < min; i++) {
        promoposts.push({
          promopost: this.controlContainer.control.value,
          image: images[i],
          text: texts[i]
        });
      }
    } else if (this.controlContainer.control.get('type').value === 'quadratic') {
      images.forEach(image => {
        texts.forEach(text => {
          promoposts.push({
            promopost: this.controlContainer.control.value,
            image,
            text
          });
        });
      });
    }
    return promoposts;
  }

  ngOnInit() {
    // if (this.isUpdating) {
    //   console.log(this.controlContainer.control.value.groupId, 'GROUP ID');
    //   this.api
    //     ([this.controlContainer.control.value.groupId])
    //     .subscribe(data => {
    //       this.selectedGroup = data[0];
    //     });
    // }
    this.controlContainer.control.get('text').valueChanges.subscribe(data => {
    });
  }

  public clickNext() {
    this.promopostChange.emit(this.promopost);
    this.onNext.emit();
  }

  public onGroupSelect($event) {
    this.selectedGroup = $event;
  }

  public OnControlAdd() {
    this.ControlAdded.emit();
    this.cd.detectChanges();
  }

  public OnControlRemove(id) {
    this.ControlRemoved.emit(id);
  }

  public ImageSelected($event: any) {
    this.controlContainer.control.get('image').enable();
    this.controlContainer.control.get('image').setValue($event);
    this.controlContainer.control.updateValueAndValidity(this.controlContainer.control.value);
    // console.log(this.controlContainer.control.get('text').controls[0]);
    // this.controlContainer.control.get('text').controls[0].markAsDirty();
    this.controlContainer.control.get('image').markAsDirty();
    console.log(this.controlContainer.control.get('image'), '94');
  }

  ImageRemove($event: string) {
    this.ImagesManager.OnSelectImage($event);
  }
}
