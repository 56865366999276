import { Component, OnInit } from '@angular/core';
import {environment} from "../../../../../environments/environment";
import {ActivatedRoute} from "@angular/router";
import {OAuthService} from "../../../../api/services/oauth.service";
import {PlatformAssignationsService} from "../../../stores/platform-assignations/platform-assignations.service";
import {AssignationPlatforms} from "../../../stores/platform-assignations/platform-assignation.model";

@Component({
  selector: 'app-facebook-assignation-screen',
  templateUrl: './facebook-assignation-screen.component.html',
  styleUrls: ['./facebook-assignation-screen.component.scss']
})
export class FacebookAssignationScreenComponent implements OnInit {


  public baseUrl = environment.frontEndUrl;
  public provider = 'facebook';
  public assigned = false;
  public loading: boolean = true;

  constructor(
    private route: ActivatedRoute,
    private oauth: OAuthService,
    private assignationService: PlatformAssignationsService
  ) {
  }

  ngOnInit() {
    setTimeout(() => {
      this.CheckAssignation();
    }, 3000);
  }

  public AddVkAdCabinetLinkClick(): void {
    const route = this.route.snapshot['_routerState'].url;
    localStorage.setItem('bind-return-to', route);

    window.location.href = this.GetBindUrl();
  }

  public GetBindUrl(): string {
    return `${this.baseUrl}/${environment.apiPrefix}/OAuth/authorize/${this.provider}`;
  }

  public CheckAssignation() {
    this.loading = true;
    this.oauth.CheckProviderToken(this.provider)
      .subscribe(data => {
          setTimeout(() => {
            this.assigned = data;
            if (data) {
              this.assignationService.assignPlatform(AssignationPlatforms.FACEBOOK);
            }
            this.loading = false;
          }, 2000);
        },
        err => {
          this.loading = false;
        }
      );
  }
}
