import { Component, OnInit } from '@angular/core';
import {AdFormManagerService} from '../../services/ad-form-manager/ad-form-manager.service';

@Component({
  selector: 'app-ad-teazers-list',
  templateUrl: './ad-teazers-list.component.html',
  styleUrls: ['./ad-teazers-list.component.scss']
})
export class AdTeazersListComponent implements OnInit {

  public get teazers() {
    return this.adFormManagerService.teazers;
  }

  constructor(
    private adFormManagerService: AdFormManagerService
  ) { }

  ngOnInit() {
  }

}
