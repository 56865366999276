import { ControlValueAccessor } from '@angular/forms';
import { OnInit } from '@angular/core';
import * as moment from 'moment';
import { isNullOrUndefined } from 'util';
import { environment } from '../../../../environments/environment';
import { Subject } from 'rxjs';
var DatetimePickerComponent = /** @class */ (function () {
    function DatetimePickerComponent() {
        this.disabled = false;
        this.dateSubject = new Subject();
        this.datepickerConfig = environment.ngxMyDatePickerConfig;
    }
    Object.defineProperty(DatetimePickerComponent.prototype, "IsDateSelected", {
        get: function () {
            return isNullOrUndefined(this.date);
        },
        enumerable: true,
        configurable: true
    });
    DatetimePickerComponent.prototype.writeValue = function (obj) {
        console.log(obj, 'OBJ');
        if (obj) {
            this.date = this.secondsToDate(obj);
            this.dateTemp = { jsdate: this.secondsToDate(obj) };
            this.hour = this.date.getHours();
        }
    };
    DatetimePickerComponent.prototype.registerOnChange = function (fn) {
        this.onChanges = fn;
    };
    DatetimePickerComponent.prototype.registerOnTouched = function (fn) {
        this.onTouched = fn;
    };
    DatetimePickerComponent.prototype.setDisabledState = function (isDisabled) {
        this.disabled = isDisabled;
    };
    DatetimePickerComponent.prototype.change = function () {
        console.log('datepicker changed');
        console.log(this.dateTemp.date.month);
        this.date = new Date(this.dateTemp.date.year, this.dateTemp.date.month - 1, this.dateTemp.date.day);
        console.log(this.hour, this.date, !isNullOrUndefined(this.hour), !isNullOrUndefined(this.date));
        console.log(this.hour, this.date, !isNullOrUndefined(this.hour) && !isNullOrUndefined(this.date));
        if (!isNullOrUndefined(this.hour) && !isNullOrUndefined(this.date)) {
            console.log(this.date);
            this.setError('');
            this.date.setHours(this.hour || 0);
            this.onChanges(this.date.getTime() / 1000);
        }
        else {
            this.setError('Все поля обязательны');
            console.log(this.errorMessage);
        }
    };
    DatetimePickerComponent.prototype.OnDateChange = function (date) {
        this.dateTemp = date;
        this.date = date.jsdate;
        this.dateSubject.next();
    };
    DatetimePickerComponent.prototype.changeHour = function () {
        this.dateSubject.next();
    };
    DatetimePickerComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.dateSubject
            .subscribe(function () {
            _this.change();
        });
    };
    DatetimePickerComponent.prototype.secondsToDate = function (seconds) {
        return moment(seconds * 1000).toDate();
    };
    DatetimePickerComponent.prototype.setError = function (message) {
        this.errorMessage = message;
    };
    return DatetimePickerComponent;
}());
export { DatetimePickerComponent };
