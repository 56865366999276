import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Strategy} from '../../../api/models';

@Component({
  selector: 'app-strategy-preview',
  templateUrl: './strategy-preview.component.html',
  styleUrls: ['./strategy-preview.component.scss']
})
export class StrategyPreviewComponent implements OnInit {

  @Input() strategy: Strategy;
  @Input() selected;
  @Output() selectedChange: EventEmitter<any> = new EventEmitter<any>();

  public detailsShowed = false;

  public get StrategyDescription(): any {
    return (this.detailsShowed) ? this.strategy.description : this.strategy.descriptionPreview;
  }

  constructor() {
  }

  ngOnInit() {
  }

  showMore() {
    this.detailsShowed = !this.detailsShowed;
  }

  public change() {
    this.selectedChange.emit(this.strategy.code);
  }
}
