import {ID} from '@datorama/akita';
import {PostMonitorBackend_V2ModelsvkVKAPIGroupModel} from '../../../api/models/post-monitor-backend-_v2modelsvk-vkapigroup-model';

export interface AdNetworkGroup {
  id: ID;
  name: string;
  avatar: string | null
}

/**
 * A factory function that creates AdNetworkGroups
 */
export function createAdNetworkGroupFromVk(params: Partial<PostMonitorBackend_V2ModelsvkVKAPIGroupModel>) {
  return {
    id: params.id,
    name: params.name,
    avatar: params.photo_50
  } as AdNetworkGroup;
}

export function createAdNetworkGroupFromMt() {
}
