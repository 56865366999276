<!-- <app-payment-info-panel></app-payment-info-panel> -->

<h1>
  Оплата тарифов осуществляется по безналичному расчету
</h1>

<p>
  Для получения счета просим на почту <strong>support@postmonitor.ru</strong> отправить следующую информацию:
</p>
<br>
<ol>
  <li>
    Выбранный тариф со <a href="https://postmonitor.ru/tariffs" target="_blank">страницы тарифов</a>.
  </li>

  <li>
    Срок (1 мес, 3 мес, 6 мес или 12 месяцев).
  </li>

  <li>
    E-mail для отправки счёта.
  </li>

  <li>
    Реквизиты вашей организации.
  </li>
</ol>

<p>
  После получения информации, мы вышлем вам счет на указанный e-mail.
</p>

<!-- <ng-container *ngIf="!(prepareLinkLoading$ | async); else loadingScreen">
  <ng-container *ngIf="!(linkToPayment$ | async); else linkTemplate">
    <ng-container *ngIf="!(paymentsService.paymentsSelected); else selectedTariffPlanTemplate">
      <app-tariff-plan-selector></app-tariff-plan-selector>
    </ng-container>
    <ng-template #selectedTariffPlanTemplate>
      <app-payment-system-selector></app-payment-system-selector>
    </ng-template>
    <ng-container *ngIf="!(tariffPlan$ | async);">
      <app-promocode-form></app-promocode-form>
      <app-payments-list></app-payments-list>
    </ng-container>
  </ng-container>

</ng-container>
<ng-template #loadingScreen>
  <app-loading-screen [diameter]="16">Подождите, идет подготовка данных для оплаты</app-loading-screen>
</ng-template>

<ng-template #linkTemplate>
  <app-payment-link-result></app-payment-link-result>
</ng-template> -->
