import {Injector, NgModule} from '@angular/core';
import { NewPaymentsComponent } from './new-payments.component';
import { createCustomElement } from '@angular/elements';
import {HttpClientModule} from "@angular/common/http";

@NgModule({
  declarations: [NewPaymentsComponent],
  entryComponents: [NewPaymentsComponent],
  imports: [
    HttpClientModule
  ],
  exports: [NewPaymentsComponent]
})
export class NewPaymentsUiModule {
  constructor(injector: Injector) {
    const custom = createCustomElement(NewPaymentsComponent, {injector});
    customElements.define('app-new-payments-route', custom)
  }
}
