<!--<div class="vk-container">-->
  <!--<label class="vk-label">-->
    <!--Выберите способ управления ставкой:-->
  <!--</label>-->
<!--</div>-->

<ng-container *ngFor="let strategy of strategies">
  <div class="vk-container">
    <ng-container *ngIf="strategies.length > 1">
      <app-strategy-preview [(selected)]="selectedStrategy" [strategy]="strategy" (selectedChange)="AcceptStrategy($event)"></app-strategy-preview>
    </ng-container>
    <div [ngClass]="{
      'form_hidden': selectedStrategy !== strategy.code
    }">
      <app-dynamic-form
        [inputs]="inputs[strategy.code] || inputs"
        (Successed)="Save($event)"
        (Inited)="OnInit($event)"
        #adForm
      ></app-dynamic-form>
    </div>
  </div>
</ng-container>

<!--<div class="strategies-manager__actions">-->
  <!--<div class="fx-row">-->
  <!--<div class="fx-divider"></div>-->
  <!--<button mat-raised-button color="primary" (click)="AcceptStrategy(!strategySelected)">Выбрать способ</button>-->
  <!--</div>-->
<!--</div>-->

<div class="strategies-manager__form" *ngIf="displayForm && strategySelected">

</div>

<!--<div class="strategies-manager">-->
  <!--<div class="strategies-manager__title"></div>-->
  <!--<div class="strategies-manager__list strategies-list">-->
    <!--<div class="strategies-list__title">Выберите способ управления ставкой</div>-->
    <!--<div class="strategies-list__items" *ngIf="!strategySelected">-->
      <!--<ng-container *ngFor="let strategy of strategies">-->
        <!--<app-strategy-preview [(selected)]="selectedStrategy" [strategy]="strategy"></app-strategy-preview>-->
      <!--</ng-container>-->
    <!--</div>-->
  <!--</div>-->
  <!--<div class="strategies-manager__actions">-->
    <!--<div class="fx-row">-->
      <!--<div class="fx-divider"></div>-->
      <!--<button mat-raised-button color="primary" (click)="AcceptStrategy(!strategySelected)">Выбрать способ</button>-->
    <!--</div>-->
  <!--</div>-->
  <!--<div class="strategies-manager__form" *ngIf="displayForm && strategySelected">-->
    <!--Способ управления: <strong>{{SelectedStrategyTitle}}</strong>-->
    <!--<app-dynamic-form-->
      <!--[inputs]="inputs"-->
      <!--[OkActionText]="'Сохранить'"-->
      <!--(Successed)="Save($event)"-->
      <!--(Inited)="OnInit($event)"-->
      <!--#adForm-->
    <!--&gt;</app-dynamic-form>-->
  <!--</div>-->
<!--</div>-->
