var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AbstractCopyingConfigurator } from "./abstract-copying-configurator";
var DefaultCopyingConfigurator = /** @class */ (function (_super) {
    __extends(DefaultCopyingConfigurator, _super);
    function DefaultCopyingConfigurator(props) {
        return _super.call(this, props) || this;
    }
    DefaultCopyingConfigurator.prototype.generate = function () {
        var _this = this;
        var copyingAdsSpecifications = [];
        var _loop_1 = function (i) {
            this_1.copyingData.adIds.forEach(function (adId) {
                var specification = _this.generateDefeault();
                specification = Object.assign(specification, {
                    numberOfCopy: i + 1,
                    adIds: [adId]
                });
                copyingAdsSpecifications.push(Object.assign({}, specification));
            });
        };
        var this_1 = this;
        for (var i = 0; i < this.copyingData.defaultConfiguration.countOfCopies; i++) {
            _loop_1(i);
        }
        return copyingAdsSpecifications;
    };
    return DefaultCopyingConfigurator;
}(AbstractCopyingConfigurator));
export { DefaultCopyingConfigurator };
