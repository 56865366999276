/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sign-up.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../components/forms/sign-up-form/sign-up-form.component.ngfactory";
import * as i3 from "../../components/forms/sign-up-form/sign-up-form.component";
import * as i4 from "../../../api/services/accounts.service";
import * as i5 from "../../../api/services/occupations.service";
import * as i6 from "@angular/router";
import * as i7 from "./sign-up.component";
var styles_SignUpComponent = [i0.styles];
var RenderType_SignUpComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SignUpComponent, data: {} });
export { RenderType_SignUpComponent as RenderType_SignUpComponent };
export function View_SignUpComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-sign-up-form", [], null, [[null, "onSubmit"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onSubmit" === en)) {
        var pd_0 = (_co.handleSubmit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_SignUpFormComponent_0, i2.RenderType_SignUpFormComponent)), i1.ɵdid(1, 114688, null, 0, i3.SignUpFormComponent, [i4.AccountsService, i5.OccupationsService, i6.ActivatedRoute], null, { onSubmit: "onSubmit" })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_SignUpComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-sign-up", [], null, null, null, View_SignUpComponent_0, RenderType_SignUpComponent)), i1.ɵdid(1, 114688, null, 0, i7.SignUpComponent, [i4.AccountsService, i6.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SignUpComponentNgFactory = i1.ɵccf("app-sign-up", i7.SignUpComponent, View_SignUpComponent_Host_0, {}, {}, []);
export { SignUpComponentNgFactory as SignUpComponentNgFactory };
