/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./hint.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./hint.component";
var styles_HintComponent = [i0.styles];
var RenderType_HintComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HintComponent, data: {} });
export { RenderType_HintComponent as RenderType_HintComponent };
export function View_HintComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "vk-hint"]], null, null, null, null, null)), i1.ɵncd(null, 0)], null, null); }
export function View_HintComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "vk-hint", [], null, null, null, View_HintComponent_0, RenderType_HintComponent)), i1.ɵdid(1, 114688, null, 0, i2.HintComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HintComponentNgFactory = i1.ɵccf("vk-hint", i2.HintComponent, View_HintComponent_Host_0, {}, {}, ["*"]);
export { HintComponentNgFactory as HintComponentNgFactory };
