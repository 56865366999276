/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./user-calls.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../user-call/user-call.component.ngfactory";
import * as i3 from "../user-call/user-call.component";
import * as i4 from "../../../../api/services/calls-controller-1.service";
import * as i5 from "@angular/common";
import * as i6 from "@clr/angular";
import * as i7 from "./user-calls.component";
var styles_UserCallsComponent = [i0.styles];
var RenderType_UserCallsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UserCallsComponent, data: {} });
export { RenderType_UserCallsComponent as RenderType_UserCallsComponent };
function View_UserCallsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-user-call", [], null, [[null, "callStatusChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("callStatusChanged" === en)) {
        var pd_0 = (_co.ChangeStatus() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_UserCallComponent_0, i2.RenderType_UserCallComponent)), i1.ɵdid(2, 114688, null, 0, i3.UserCallComponent, [i4.CallsController1Service], { userCall: [0, "userCall"], callStatuses: [1, "callStatuses"] }, { callStatusChanged: "callStatusChanged" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.callStatuses; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_UserCallsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "user-calls"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "user-calls__list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_UserCallsComponent_1)), i1.ɵdid(3, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null), i1.ɵdid(4, 16384, null, 0, i6.ɵdf, [[2, i6.ɵck]], { trackBy: [0, "trackBy"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.userCalls; var currVal_1 = _co.trackFn; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = _co.trackFn; _ck(_v, 4, 0, currVal_2); }, null); }
export function View_UserCallsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-user-calls", [], null, null, null, View_UserCallsComponent_0, RenderType_UserCallsComponent)), i1.ɵdid(1, 114688, null, 0, i7.UserCallsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UserCallsComponentNgFactory = i1.ɵccf("app-user-calls", i7.UserCallsComponent, View_UserCallsComponent_Host_0, { userCalls: "userCalls", callStatuses: "callStatuses" }, { callStatusChanged: "callStatusChanged" }, []);
export { UserCallsComponentNgFactory as UserCallsComponentNgFactory };
