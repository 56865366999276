import { PlatformsTypes } from '../../../platforms-types';
import { AkitaNgFormsManager } from '@datorama/akita-ng-forms-manager';
import { unionAllArraysFromObjectsToArray } from '../../../../utils/js/arrays';
import { LeadFormsService } from '../../my-target/services/lead-forms.service';
import { AdNetworkFormsQuery } from '../../../stores/ad-network-forms/ad-network-forms.query';
import * as i0 from "@angular/core";
import * as i1 from "@datorama/akita-ng-forms-manager";
import * as i2 from "../../my-target/services/lead-forms.service";
import * as i3 from "../../../stores/ad-network-forms/ad-network-forms.query";
var AdNetworkExportConfiguratorService = /** @class */ (function () {
    function AdNetworkExportConfiguratorService(fm, mtLeadFormsService, adNetworkFormsQuery) {
        this.fm = fm;
        this.mtLeadFormsService = mtLeadFormsService;
        this.adNetworkFormsQuery = adNetworkFormsQuery;
    }
    AdNetworkExportConfiguratorService.prototype.configure = function (type) {
        switch (type) {
            case PlatformsTypes.VK_LEAD_FORMS:
            case PlatformsTypes.FB_LEAD_FORMS:
                var forms = this.fm.getForm('originPlatformSettings').value;
                return [
                    {
                        code: type.toString(),
                        data: this.getConfigurationData(forms, type)
                    }
                ];
                break;
            case PlatformsTypes.MT_LEAD_FORMS:
                return [{
                        code: type.toString(),
                        data: this.mtLeadFormsService.configure()
                    }];
                break;
        }
    };
    AdNetworkExportConfiguratorService.prototype.getConfigurationData = function (forms, type) {
        var _this = this;
        var result = {};
        var formsDestinationSettings = this.fm.getForm('destinationPlatformSettings').value;
        if (type === PlatformsTypes.FB_LEAD_FORMS) {
            console.log(forms, formsDestinationSettings, 'FB_LEAD_FORMS');
            result['ad_ids'] = unionAllArraysFromObjectsToArray(forms)
                .map(function (form) { return form.split('_'); })
                .map(function (_a) {
                var group_id = _a[0], form_id = _a[1];
                // tslint:disable-next-line:no-shadowed-variable
                var result = {
                    ad_id: "" + (group_id || form_id)
                };
                if (formsDestinationSettings["" + (group_id || form_id)].LEADS && formsDestinationSettings["" + (group_id || form_id)].LEADS.selected) {
                    result['include_lead_form_name'] = formsDestinationSettings["" + (group_id || form_id)].includeLeadFormName;
                    result['include_lead_campaign_name'] = formsDestinationSettings["" + (group_id || form_id)].includeCampaignName;
                }
                var lastLeadId = _this.adNetworkFormsQuery.GetLastCreateDateByGroupAndFormIds(form_id, group_id);
                if (lastLeadId) {
                    result.last_lead_created_at = new Date();
                }
                return result;
            });
        }
        else {
            result['forms_ids'] = unionAllArraysFromObjectsToArray(forms)
                .map(function (form) { return form.split('_'); })
                .map(function (_a) {
                var group_id = _a[0], form_id = _a[1];
                // tslint:disable-next-line:no-shadowed-variable
                var result = {
                    group_id: group_id,
                    form_id: form_id,
                    include_lead_form_name: formsDestinationSettings[group_id + "_" + form_id] ? formsDestinationSettings[group_id + "_" + form_id].includeLeadFormName : false,
                    include_lead_campaign_name: formsDestinationSettings[group_id + "_" + form_id] ? formsDestinationSettings[group_id + "_" + form_id].includeCampaignName : false
                };
                var lastLeadId = _this.adNetworkFormsQuery.GetLastLeadIdByGroupAndFormIds(form_id, group_id);
                if (lastLeadId) {
                    result.last_lead_id = lastLeadId;
                }
                return result;
            });
        }
        // {
        //   forms_ids:
        // };
        return result;
    };
    AdNetworkExportConfiguratorService.prototype.valid = function (type) {
        switch (type) {
            case PlatformsTypes.MT_LEAD_FORMS:
                return this.mtLeadFormsService.valid();
                break;
            case PlatformsTypes.VK_LEAD_FORMS:
            case PlatformsTypes.FB_LEAD_FORMS:
                var form = this.fm.getForm('originPlatformSettings');
                var forms = form ? form.value : {};
                return unionAllArraysFromObjectsToArray(forms).length > 0;
                break;
        }
    };
    AdNetworkExportConfiguratorService.prototype.getForms = function () {
        return this;
    };
    AdNetworkExportConfiguratorService.ngInjectableDef = i0.defineInjectable({ factory: function AdNetworkExportConfiguratorService_Factory() { return new AdNetworkExportConfiguratorService(i0.inject(i1.AkitaNgFormsManager), i0.inject(i2.LeadFormsService), i0.inject(i3.AdNetworkFormsQuery)); }, token: AdNetworkExportConfiguratorService, providedIn: "root" });
    return AdNetworkExportConfiguratorService;
}());
export { AdNetworkExportConfiguratorService };
