import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import * as moment from 'moment';
import {Subject} from 'rxjs/Subject';

@Component({
  selector: 'app-new-date-filter',
  templateUrl: './new-date-filter.component.html',
  styleUrls: ['./new-date-filter.component.scss']
})
export class NewDateFilterComponent implements OnInit {

  @Input() public period: string = 'today';
  @Input() public dateFrom: Date = new Date();
  @Input() public dateTo: Date = new Date();

  @Output() public dateChanged: EventEmitter<any> = new EventEmitter<any>();

  public subject = new Subject();

  @Input() value = {
    period: 'today',
    dateFrom: new Date(),
    dateTo: new Date()
  };

  public form: FormGroup;

  constructor() {
  }

  ngOnInit() {
    // this.adForm = new FormGroup({
    //   period: new FormControl(this.value.period),
    //   dateFrom: new FormControl(this.value.dateFrom),
    //   dateTo: new FormControl(this.value.dateTo),
    // });
    //
    // this.adForm.valueChanges
    //   .debounceTime(500)
    //   .subscribe(((value) => {
    //     console.log(value);
    //     if (moment(value.dateFrom).isAfter(value.dateTo)) {
    //       const tempDate = value.dateTo;
    //       value.dateTo = value.dateFrom;
    //       value.dateFrom = tempDate;
    //       this.adForm.patchValue(value);
    //     }
    //   }));

    // this.adForm.controls.dateFrom.valueChanges.subscribe((value) => {
    //   this.DateSelected();
    // });
    //
    // this.adForm.controls.dateTo.valueChanges.subscribe((value) => {
    //   this.DateSelected();
    // });

    this.subject.subscribe(() => {
      this.dateChanged.emit({
        period: 'day',
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
        periodState: this.period
      });
    });
  }

  SelectToday() {
    this.period = 'today';
    this.dateFrom = new Date();
    this.dateTo = new Date();
    this.subject.next();
  }

  SelectYesterday() {
    const date = moment(new Date()).subtract(1, 'days').toDate();
    this.period = 'yesterday';
    this.dateFrom = date;
    this.dateTo = date;
    this.subject.next();
  }

  SelectWeek() {
    this.period = '7_day';
    this.dateFrom = moment(new Date()).subtract(6, 'day').toDate();
    this.dateTo = new Date();
    this.subject.next();
  }

  SelectMonth() {
    this.period = '1_month';
    this.dateFrom = moment(new Date()).subtract(1, 'month').toDate();
    this.dateTo = new Date();
    this.subject.next();
  }

  DateSelected(date) {
    this.period = 'day';
    this.subject.next();
    // console.log('day');
    // console.log(Object.keys(this.adForm.controls).map((x) => ({formControlName: x, dirty: this.adForm.controls[x].dirty, touched: this.adForm.controls[x].touched})));
    // this.adForm.patchValue({period: 'day'});
  }
}
