<div class="chat-container">
  <div class="chat">
    <div class="chat__messages">
      <ng-container *ngIf="IsLoading(); else messagesLoaded">
        <div class="chat-messages-list chat-messages-list_reverse">
          <div class="placeholder-text">
            <ng-container *ngIf="!conversationMessagesService.isBanned; else userBanned">
              Загрузка истории переписки...
            </ng-container>
            <ng-template #userBanned>
              Сообщения не могут быть загружены
            </ng-template>
          </div>
        </div>
      </ng-container>
      <ng-template #messagesLoaded>
        <ng-container *ngIf="MessagesExists(); else noMessages">
          <div class="chat-messages-list">
            <ng-container *ngFor="let message of GetMessasges()">
              <div [ngClass]='{
                "chat-messages-list__item": true,
                "chat-messages-list__item_my-message": message.isMyMessage,
                "chat-messages-list__item_not-my-message": !message.isMyMessage
              }'>
                <app-chat-message [message]="message" [showReadStatus]="conversationWithUserId !== null"></app-chat-message>
              </div>
            </ng-container>
          </div>
        </ng-container>
        <ng-template #noMessages>
          <div class="chat-messages-list chat-messages-list_reverse">
            <div class="placeholder-text">
              Если у вас есть вопросы по работе с сервисом, то вы можете задать их здесь.<br>Чтобы задать вопрос введите текст в поле ввода.
            </div>
          </div>
        </ng-template>
      </ng-template>
    </div>
    <div class="chat__controls">
      <ng-container *ngIf="conversationMessagesService.isBanned; else notBanned">
        <div class="chat-ban-container">
          <div class="vk-container">
            Вы были заблокированы
          </div>
          <ng-container *ngIf="conversationMessagesService.banReason">
            <div>
              <strong>Причина:</strong> <i> {{conversationMessagesService.banReason}}</i>
            </div>
          </ng-container>
        </div>
      </ng-container>
      <ng-template #notBanned>
        <div class="vk-container">
          <textarea class="vk-textarea vk-textarea_full-width" placeholder="Введите текст сообщения..." [(ngModel)]="formMessage" minlength="1" maxlength="2048" rows="3" autofocus></textarea>
        </div>

        <div class="vk-container">
          <button class="vk-standart-button vk-standart-button_full-width" (click)="SendMessage()">
            Отправить
          </button>
        </div>
      </ng-template>
    </div>
  </div>
</div>
