<div class="payments-selector">
<!--  <div class="payments-selector__dropdown payment-dropdown">-->
<!--    <div class="payments-selector__current-value">-->
<!--      {{currentSelectedTimePeriodName$ | async}}-->
<!--    </div>-->
<!--    <div class="payment-dropdown__items">-->
<!--      <ng-container *ngFor="let timePeriod of timePeriods$ | async">-->
<!--        <div class="payment-dropdown__item">-->
<!--          {{timePeriod.name}}-->
<!--        </div>-->
<!--      </ng-container>-->
<!--    </div>-->
<!--  </div>-->
  <ng-container *ngFor="let timePeriod of timePeriods$ | async">
    <button class="payments-selector__trigger" (click)="SelectTimePeriod(timePeriod.id)" [ngClass]="{
      'payments-selector__trigger_selected': isSelected(timePeriod.id)
    }">
      {{timePeriod.name}}
    </button>
  </ng-container>
</div>
