import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BannerDialogComponent} from './components/banner-dialog/banner-dialog.component';
import {HttpClientModule} from "@angular/common/http";
import {MatDialogModule} from "@angular/material/dialog";
import { BannersContentComponent } from './components/banners-content/banners-content.component';
import { BannerItemComponent } from './components/banner-item/banner-item.component';
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";

@NgModule({
  declarations: [BannerDialogComponent, BannersContentComponent, BannerItemComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule
  ],
  entryComponents: [BannerDialogComponent]
})
export class SystemBannersModule {
}
