import {AfterViewInit, Component, ContentChildren, forwardRef, OnInit, QueryList} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {CheckboxItemDirective} from '../../directives/checkbox-item.directive';

@Component({
  selector: 'app-checkboxes-group',
  templateUrl: './checkboxes-group.component.html',
  styleUrls: ['./checkboxes-group.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => CheckboxesGroupComponent)
    }
  ]
})
export class CheckboxesGroupComponent<T> implements OnInit, ControlValueAccessor, AfterViewInit {

  @ContentChildren(CheckboxItemDirective) public checkboxes: QueryList<CheckboxItemDirective>;
  public value = [];
  public checkboxesValues = {};

  public onChange;
  public onTouch;

  constructor() {
  }

  ngOnInit() {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  ngAfterViewInit(): void {
    this.checkboxes.changes.subscribe(checkboxes => {
      console.log(checkboxes, 'CHECKBOXES');
    })
    this.subscribeToCheckboxes();
    this.checkboxes.changes.subscribe((data: CheckboxItemDirective[]) => {
      this.subscribeToCheckboxes();
    });
  }

  public subscribeToCheckboxes() {
    const checkboxes = this.checkboxes.toArray();
    for (const checkbox of checkboxes) {
      const chekboxItemKey = checkbox.appCheckboxItem;
      checkbox.control.valueChanges.subscribe(value => {
        this.checkboxesValues[chekboxItemKey] = value;
        this.changed();
      })
      this.checkboxesValues[chekboxItemKey] = false;
    }
    // this.checkboxesValues = checkboxes.map(checkbox => ({key: checkbox.appCheckboxItem, value: false}))
    //   .reduce((acc, {key, value}) => {
    //     acc[key] = value;
    //     return acc;
    //   }, {});
    // checkboxes.forEach(checkbox => {
    //   checkbox.control.valueChanges.subscribe(checkboxData => {
    //     this.checkboxesValues[checkbox.appCheckboxItem] = checkboxData;
    //     this.changed();
    //   });
    // });
  }

  public changed() {
    const selectedGroupIds = Object.keys(this.checkboxesValues).filter(x => this.checkboxesValues[x]);
    this.onChange(selectedGroupIds);
  }

}
