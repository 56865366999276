import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AffiliateProgramSettingsUserInfoRoutingModule } from './affiliate-program-settings-user-info-routing.module';
import { AffiliateProgramSettingsUserInfoRouteComponent } from './routes/affiliate-program-settings-user-info-route/affiliate-program-settings-user-info-route.component';
import { AffiliateProgramSettingsUserFormComponent } from './components/affiliate-program-settings-user-form/affiliate-program-settings-user-form.component';
import {ReactiveFormsModule} from "@angular/forms";
import {VkControlsModule} from "../../../vk-controls/vk-controls.module";
import {MatSelectModule} from "@angular/material/select";
import {NgxMaskModule} from "ngx-mask";

@NgModule({
  declarations: [AffiliateProgramSettingsUserInfoRouteComponent, AffiliateProgramSettingsUserFormComponent],
  imports: [
    CommonModule,
    AffiliateProgramSettingsUserInfoRoutingModule,
    ReactiveFormsModule,
    VkControlsModule,
    MatSelectModule,
    NgxMaskModule.forRoot({
    })
  ]
})
export class AffiliateProgramSettingsUserInfoModule { }
