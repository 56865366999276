<ng-container *ngIf="system">
  <div class="easy-payment-system-container">
    <div class="easy-payment-system-container__image">
      <img [src]="system.image" class="easy-payment-system-image">
    </div>
    <div class="easy-payment-system-container__title">
      {{system.title}}
    </div>
    <div class="easy-payment-system-container__content">
      <ng-content></ng-content>
    </div>
  </div>
</ng-container>
