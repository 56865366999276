<div class="crm-sections-settings" *ngIf="formGroup" [formGroup]="formGroup">
  <ng-container *ngFor="let section of sections">
    <div class="crm-section-settings__item">
     <ng-container *ngIf="GetSectionFrom(section.code) as sectionForm">
       <app-new-import-crm-section
         [advancedSettings]="GetAdvancedSettingsForSection(section.code)"
         [formGroup]="sectionForm"
         [formQuestions]="formQuestions"
         [form]="form"
         [parentForm]="formGroup"
         [section]="section"
       >
       </app-new-import-crm-section>
     </ng-container>
    </div>
  </ng-container>
</div>
