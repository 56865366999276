var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { QueryEntity } from '@datorama/akita';
import { CrmOrganisationStore } from './crm-organisation.store';
import * as i0 from "@angular/core";
import * as i1 from "./crm-organisation.store";
var CrmOrganisationQuery = /** @class */ (function (_super) {
    __extends(CrmOrganisationQuery, _super);
    function CrmOrganisationQuery(store) {
        var _this = _super.call(this, store) || this;
        _this.store = store;
        _this.organisations$ = _this.selectAll();
        return _this;
    }
    CrmOrganisationQuery.ngInjectableDef = i0.defineInjectable({ factory: function CrmOrganisationQuery_Factory() { return new CrmOrganisationQuery(i0.inject(i1.CrmOrganisationStore)); }, token: CrmOrganisationQuery, providedIn: "root" });
    return CrmOrganisationQuery;
}(QueryEntity));
export { CrmOrganisationQuery };
