import {Injectable} from '@angular/core';
import {isNullOrUndefined} from 'util';
import {UtmMark} from '../../automation/models/utm-mark';

@Injectable()
export class UtmExtractorService {

    constructor() {
    }

    public ExtractUtm(text: string): Array<UtmMark> {
      const matches = text.match(/utm[\\\-|\\\_]*[a-z]*=[а-яa-z0-9\\\-\\\_\\\.]+/ig);

      if (isNullOrUndefined(matches)) {
            return null;
        }


        const uniqueUtmMarks = this.GetUniqueUtmMarks(matches).map(x => {
          const a = x.split('=');
          return new UtmMark(a[0], a[1]);
        });

        return this.GetUniqueUtmMarks(matches).map(x => {
            const a = x.split('=');
            return new UtmMark(a[0], a[1]);
        });
    }

    public GetUniqueUtmMarks(marks: Array<string>): Array<string> {
        // Check if  all necessary properties are provided
        if (!marks || marks.length === 0) {
            return null;
        }

        // Filter off non unique values
        return marks.filter(function (item, i, ar) {
            return ar.indexOf(item) === i;
        });
    }
}
