import {Component, OnInit} from '@angular/core';
import {AffiliateProgramStatisticsByDateService} from "../../services/affiliate-program-statistics-by-date.service";

@Component({
  selector: 'app-affiliate-program-statistics-by-date-table',
  templateUrl: './affiliate-program-statistics-by-date-table.component.html',
  styleUrls: ['./affiliate-program-statistics-by-date-table.component.scss']
})
export class AffiliateProgramStatisticsByDateTableComponent implements OnInit {

  public displayedColumns = ['statisticDate', 'amount', 'countOfTransactions'];

  public get statistics$() {
    return this.service.statistics$;
  }

  constructor(
    private service: AffiliateProgramStatisticsByDateService
  ) {
  }

  ngOnInit() {
  }

}
