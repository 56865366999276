var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { RetargetingGroupsStore } from './retargeting-groups.store';
import { AdManagerService, AutomationVkService, AutomationVkSlotService, FacebookCustomAudienceService, MyTargetService, VkApiService, YandexAudienceSegmentService, } from '../../../api/services';
import { delay, flatMap, tap } from 'rxjs/operators';
import { waterfall } from '../../../utils/rxjs-operators/waterfall';
import { createCustomAudienceFromFacebook, createRemarketingGroupFromMt, createRetargetingGroupFromVk, createRemarketingGroupFromYandexAudience } from './retargeting-group.model';
import { PlatformsTypes } from '../../platforms-types';
import { of } from 'rxjs';
import { toBoolean } from "@datorama/akita";
import * as i0 from "@angular/core";
import * as i1 from "./retargeting-groups.store";
import * as i2 from "../../../api/services/ad-manager.service";
import * as i3 from "../../../api/services/automation-vk-slot.service";
import * as i4 from "../../../api/services/automation-vk.service";
import * as i5 from "../../../api/services/my-target.service";
import * as i6 from "../../../api/services/vk-api.service";
import * as i7 from "../../../api/services/facebook-custom-audience.service";
import * as i8 from "../../../api/services/yandex-audience-segment.service";
var RetargetingGroupsService = /** @class */ (function () {
    function RetargetingGroupsService(store, adManager, vkSlotService, vkService, mt, vkApi, fbAudience, yandexService) {
        this.store = store;
        this.adManager = adManager;
        this.vkSlotService = vkSlotService;
        this.vkService = vkService;
        this.mt = mt;
        this.vkApi = vkApi;
        this.fbAudience = fbAudience;
        this.yandexService = yandexService;
    }
    RetargetingGroupsService.prototype.LoadVkAccountData = function () {
        var _this = this;
        return this.LoadSlots()
            .pipe(flatMap(function () { return _this.LoadAccounts(); }), flatMap(function () { return _this.LoadClients(); }), tap(function () {
            _this.accounts = _this.accounts
                .filter(function (x) { return _this.slots.find(function (slot) { return slot.bindedCabinetId === x.account_id && slot.bindedClientId === x.client_id; }); });
        }));
    };
    RetargetingGroupsService.prototype.LoadSlots = function () {
        var _this = this;
        return this.vkSlotService.GetSlots()
            .pipe(tap(function (_a) {
            var data = _a.data;
            _this.slots = data;
        }), delay(1000));
    };
    RetargetingGroupsService.prototype.LoadRetargetingGroupsFromVk = function (accountId, clientId) {
        var _this = this;
        this.vkApi.GetAdsTargetGroupsAsync({
            accountId: accountId,
            clientId: clientId,
            key: 'integration'
        })
            .subscribe(function (data) {
            var retargetingGroups = data
                .filter(function (x) { return !x.is_shared; })
                .sort(function (a, b) { return b.id - a.id; }).map(function (x) { return createRetargetingGroupFromVk(x); });
            _this.store.set(retargetingGroups);
        });
    };
    RetargetingGroupsService.prototype.setRemarketingGroupsFilter = function (remarketingGroups) {
        var containsEmails = toBoolean(remarketingGroups.find(function (x) { return x.type === 'emails'; }));
        var containsPhones = toBoolean(remarketingGroups.find(function (x) { return x.type === 'phones'; }));
        if (!(containsPhones && containsEmails)) {
            var filter = remarketingGroups[0].type;
            this.SetFilter(filter);
        }
    };
    RetargetingGroupsService.prototype.LoadRemarketingGroupsFromMt = function () {
        var _this = this;
        this.store.setLoading(true);
        this.mt.GetRemarketingGroups()
            .subscribe(function (_a) {
            var data = _a.data;
            if (data) {
                var remarketingGroups = data
                    .sort(function (a, b) { return a.id - b.id; })
                    .map(function (x) { return createRemarketingGroupFromMt(x); });
                _this.store.set(remarketingGroups);
                if (remarketingGroups.length > 0) {
                    _this.setRemarketingGroupsFilter(remarketingGroups);
                }
                else {
                }
            }
            _this.store.setLoading(false);
        });
    };
    RetargetingGroupsService.prototype.LoadRemarketingGroups = function (type) {
        var params = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            params[_i - 1] = arguments[_i];
        }
        console.log('LOAD REMARKETING GROUPS', type, params);
        switch (type) {
            case PlatformsTypes.VK_RETARGETING_GROUPS:
                this.LoadRetargetingGroupsFromVk(params[0], params[1]);
                break;
            case PlatformsTypes.MT_REMARKETING_GROUPS:
                this.LoadRemarketingGroupsFromMt();
                break;
            case PlatformsTypes.FB_CUSTOM_AUDIENCES:
                this.LoadCustomAudiences(params[0]);
                break;
            case PlatformsTypes.YANDEX_AUDIENCES:
                this.LoadYandexAudiences();
                break;
        }
    };
    RetargetingGroupsService.prototype.LoadAccounts = function () {
        var _this = this;
        return this.vkApi.GetAccountsAsync('integration')
            .pipe(tap(function (data) {
            _this.accounts = data;
            _this.allAccounts = data;
        }), delay(1000));
    };
    RetargetingGroupsService.prototype.LoadClients = function () {
        var _this = this;
        var agencyAccounts = this.accounts.filter(function (x) { return x.account_type === 'agency'; });
        var clients = agencyAccounts.map(function (account) { return function () { return _this.vkApi.GetClientsAsync({
            accountId: account.account_id,
            key: 'integration'
        })
            .pipe(delay(1000), tap(function (data) {
            console.log(data, 'LOAD CLIENTS', account.account_id);
            data.forEach(function (client) {
                var toAdd = __assign({}, account, client, { account_name: client.name, account_id: account.account_id, client_id: client.id });
                console.log(toAdd, 'TO ADD');
                _this.accounts.push(toAdd);
                console.log(_this.accounts);
            });
        })); }; });
        if (clients && clients.length > 0) {
            return waterfall(clients);
        }
        else {
            return of([]);
        }
    };
    RetargetingGroupsService.prototype.SetFilter = function (filter) {
        this.store.update(function (state) { return (__assign({}, state, { ui: {
                filter: filter
            } })); });
    };
    RetargetingGroupsService.prototype.LoadCustomAudiences = function (adAccountId) {
        var _this = this;
        console.log('LOAD RESTARGETING GROUPS');
        this.fbAudience.FacebookGetCustomAudiencesAsync({
            fields: ['name'],
            adAccountId: adAccountId
        })
            .subscribe(function (data) {
            _this.store.set(data.data.data.map(function (x) { return createCustomAudienceFromFacebook(x); }));
        });
    };
    RetargetingGroupsService.prototype.LoadYandexAudiences = function () {
        var _this = this;
        this.yandexService.YandexAudienceGetSegments()
            .subscribe(function (data) {
            var audiences = data['data'].segments.map(function (x) { return createRemarketingGroupFromYandexAudience(x); });
            _this.store.set(audiences);
        });
    };
    RetargetingGroupsService.ngInjectableDef = i0.defineInjectable({ factory: function RetargetingGroupsService_Factory() { return new RetargetingGroupsService(i0.inject(i1.RetargetingGroupsStore), i0.inject(i2.AdManagerService), i0.inject(i3.AutomationVkSlotService), i0.inject(i4.AutomationVkService), i0.inject(i5.MyTargetService), i0.inject(i6.VkApiService), i0.inject(i7.FacebookCustomAudienceService), i0.inject(i8.YandexAudienceSegmentService)); }, token: RetargetingGroupsService, providedIn: "root" });
    return RetargetingGroupsService;
}());
export { RetargetingGroupsService };
