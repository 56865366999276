import { HttpClient, HttpParams } from '@angular/common/http';
import { AffiliateMembersStatisticsByDateStore } from './affiliate-members-statistics-by-date.store';
import { createAffiliateMembersStatisticsByDate } from './affiliate-members-statistics-by-date.model';
import { tap } from 'rxjs/operators';
import { AffiliateMemberStattisticsByUser } from "../../affiliate-member-statistics/state/affiliate-member-stattistics-by-user.model";
import { environment } from "../../../../environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "./affiliate-members-statistics-by-date.store";
import * as i2 from "@angular/common/http";
var AffiliateMembersStatisticsByDateService = /** @class */ (function () {
    function AffiliateMembersStatisticsByDateService(affiliateMembersStatisticsByDateStore, http) {
        this.affiliateMembersStatisticsByDateStore = affiliateMembersStatisticsByDateStore;
        this.http = http;
        this.baseUrl = environment.backendUri + "/" + environment.apiPrefix;
    }
    AffiliateMembersStatisticsByDateService.prototype.get = function (dateFrom, dateTo) {
        var _this = this;
        var params = this.GenerateQueryParams(dateFrom, dateTo);
        return this.http.get(this.baseUrl + "/user-affiliate-statistics/by-date", {
            params: params
        })
            .pipe(tap(function (entities) {
            var statistics = entities.map(function (x, i) { return createAffiliateMembersStatisticsByDate(x, i); });
            _this.affiliateMembersStatisticsByDateStore.set(statistics);
        }));
    };
    /**
     * Формирование параметров
     * @param dateFrom
     * @param dateTo
     * @constructor
     */
    AffiliateMembersStatisticsByDateService.prototype.GenerateQueryParams = function (dateFrom, dateTo) {
        var params = new HttpParams();
        params.set('dateFrom', dateFrom ? dateFrom.toString() : null);
        params.set('dateTo', dateTo ? dateTo.toString() : null);
        return params;
    };
    AffiliateMembersStatisticsByDateService.prototype.add = function (affiliateMembersStatisticsByDate) {
        this.affiliateMembersStatisticsByDateStore.add(affiliateMembersStatisticsByDate);
    };
    AffiliateMembersStatisticsByDateService.prototype.update = function (id, affiliateMembersStatisticsByDate) {
        this.affiliateMembersStatisticsByDateStore.update(id, affiliateMembersStatisticsByDate);
    };
    AffiliateMembersStatisticsByDateService.prototype.remove = function (id) {
        this.affiliateMembersStatisticsByDateStore.remove(id);
    };
    AffiliateMembersStatisticsByDateService.ngInjectableDef = i0.defineInjectable({ factory: function AffiliateMembersStatisticsByDateService_Factory() { return new AffiliateMembersStatisticsByDateService(i0.inject(i1.AffiliateMembersStatisticsByDateStore), i0.inject(i2.HttpClient)); }, token: AffiliateMembersStatisticsByDateService, providedIn: "root" });
    return AffiliateMembersStatisticsByDateService;
}());
export { AffiliateMembersStatisticsByDateService };
