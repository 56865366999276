import { OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { PlatformsTypes } from "../../../../platforms-types";
import { FormBuilder } from "@angular/forms";
import { CrmPipelinesService } from "../../../../stores/crm-pipelines/crm-pipelines.service";
import { PlatformAssignationsQuery } from "../../../../stores/platform-assignations/platform-assignations.query";
import { CrmPipelinesQuery } from "../../../../stores/crm-pipelines/crm-pipelines.query";
import { BehaviorSubject } from "rxjs";
import { distinctUntilChanged, flatMap } from "rxjs/operators";
import { AkitaNgFormsManager } from "@datorama/akita-ng-forms-manager";
import { environment } from "../../../../../../environments/environment";
import { PlatformsService } from "../../../../stores/platforms/platforms.service";
import { AssignationPlatforms } from "../../../../stores/platform-assignations/platform-assignation.model";
var NewExportCrmComponent = /** @class */ (function () {
    function NewExportCrmComponent(fb, pipelinesService, pipelinesQuery, assignationsQuery, fm, platformsService) {
        var _this = this;
        this.fb = fb;
        this.pipelinesService = pipelinesService;
        this.pipelinesQuery = pipelinesQuery;
        this.assignationsQuery = assignationsQuery;
        this.fm = fm;
        this.platformsService = platformsService;
        this.type$ = new BehaviorSubject(this.type);
        this.section$ = new BehaviorSubject('CONTACTS');
        //
        // public get LeadValue() {
        //     switch (this.type) {
        //         case PlatformsTypes.AMO_CRM_EXPORT:
        //         default:
        //             return 'LEADS';
        //         case PlatformsTypes.MEGAPLAN_EXPORT:
        //             return 'DEALS';
        //         case PlatformsTypes.UON_EXPORT:
        //             const sectionCode = this.pipelinesQuery.pipelineSectionCodeById(this.form.get('selectedPipelineId').value);
        //             return sectionCode === 'CONTACTS' ? 'DEALS' : sectionCode;
        //     }
        // }
        this.pipelines$ = this.section$
            .pipe(flatMap(function (section) { return _this.pipelinesQuery.pipelinesBySection$(section); }));
    }
    Object.defineProperty(NewExportCrmComponent.prototype, "pipelineLabelSuggestionCode", {
        // public get pipelineLabel() {
        //     if (this.countOfPipelines > 1) {
        //         return 'Выбрать воронку, из которой выгружать контакты';
        //     } else {
        //         return 'Передавать данные контактов из воронки продаж';
        //     }
        // }
        //
        get: function () {
            if (this.countOfPipelines > 1) {
                return 'AMO_BY_PIPELINES';
            }
            else {
                return 'BY_PIPELINES';
            }
        },
        enumerable: true,
        configurable: true
    });
    NewExportCrmComponent.prototype.ngOnInit = function () {
        var _this = this;
        console.log(this.type);
        this.form = this.fb.group({
            section: this.fb.control(''),
            isClassicMode: this.fb.control(true),
            selectedPipelineId: this.fb.control(null)
        });
        this.form
            .get('section')
            .valueChanges
            .subscribe(function (section) {
            _this.selectSection(section);
        });
        this.form
            .get('selectedPipelineId')
            .valueChanges
            .subscribe(function (pipelineId) {
            _this.selectPipeline(pipelineId);
        });
        this.fm.upsert(environment.integrations.originFormKey, this.form);
        this.form.get('section').patchValue('CONTACTS');
        this.type$
            .pipe(flatMap(function (type) { return _this.assignationsQuery.isPlatformAssigned$(type); }), distinctUntilChanged())
            .subscribe(function (isAssigned) {
            console.log('isAssigned');
            if (isAssigned) {
                _this.AssignationCheckingSuccessed();
            }
            else {
                _this.AssignationCheckingFailure();
            }
        });
        this.pipelines$.subscribe(function (pipelines) {
            _this.countOfPipelines = pipelines.length;
        });
    };
    NewExportCrmComponent.prototype.selectPipeline = function (pipelineId) {
        this.form.patchValue({ selectedPipelineId: pipelineId }, { emitEvent: false });
    };
    NewExportCrmComponent.prototype.selectSection = function (section) {
        var pipeline = this.pipelinesQuery.GetPipelineBySectionCode(section);
        if (pipeline) {
            this.selectPipeline(pipeline.id);
        }
        else {
            this.selectPipeline(null);
        }
        if (this.type === PlatformsTypes.UON_EXPORT) {
            this.platformsService.SetParameters(AssignationPlatforms.UON, { hasLeads: section !== 'UON_MARKS_EXPORT' });
            this.form.patchValue({ isClassicMode: !(section === 'UON_MARKS_EXPORT' || section === 'CONTACTS') });
        }
        this.form.patchValue({ section: section }, { emitEvent: false });
    };
    /**
     * Load pipelines required for work
     * @constructor
     */
    NewExportCrmComponent.prototype.LoadPipelines = function () {
        var _this = this;
        this.pipelinesService.LoadPipelines(this.type);
        setTimeout(function () {
            _this.selectSection('CONTACTS');
        }, 5);
    };
    NewExportCrmComponent.prototype.AssignationCheckingSuccessed = function () {
        console.log('Platform is assigned');
        this.LoadPipelines();
    };
    NewExportCrmComponent.prototype.AssignationCheckingFailure = function () {
        console.log('Platform assignation is failured');
    };
    NewExportCrmComponent.prototype.ngOnChanges = function (changes) {
        if (changes.type) {
            this.type$.next(changes.type.currentValue);
        }
    };
    return NewExportCrmComponent;
}());
export { NewExportCrmComponent };
