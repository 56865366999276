import {Injectable} from '@angular/core';
import {ID} from '@datorama/akita';
import {HttpClient} from '@angular/common/http';
import {CustomFieldValueStore} from './custom-field-value.store';
import {CustomFieldValue} from './custom-field-value.model';
import {tap} from 'rxjs/operators';
import {of} from "rxjs";

@Injectable({providedIn: 'root'})
export class CustomFieldValueService {

    constructor(private customFieldValueStore: CustomFieldValueStore,
                private http: HttpClient) {
    }

    get() {
        // return of([
        //     {
        //         Id: 1,
        //         Name: 'Название кампании',
        //         Description: 'Вы можете получить название кампании в вашем рекламном кабинете',
        //         Code: '{{CAMPAIGN_NAME}}'
        //     },
        //     {
        //         Id: 2,
        //         Name: 'Название рекламного объявления',
        //         Description: 'Вы можете получить название рекламного объявления в вашем рекламном кабинете',
        //         Code: '{{AD_NAME}}'
        //     }
        // ])
        //     .pipe(
        //         tap(fieldsValues => {
        //             this.customFieldValueStore.set(fieldsValues);
        //         })
        //     )
    }

    add(customFieldValue: CustomFieldValue) {
        this.customFieldValueStore.add(customFieldValue);
    }
    
    AddRange(customFieldValues: CustomFieldValue[]) {
        this.customFieldValueStore.add(customFieldValues)
    }

    update(id, customFieldValue: Partial<CustomFieldValue>) {
        this.customFieldValueStore.update(id, customFieldValue);
    }

    remove(id: ID) {
        this.customFieldValueStore.remove(id);
    }
}
