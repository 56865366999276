import {Injectable} from '@angular/core';
import {CrmPipeline} from './crm-pipeline.model';
import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';

export interface CrmPipelinesState extends EntityState<CrmPipeline> {
  ui: {
    selectedPipelineId: number | null
  };
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'crm-pipelines'})
export class CrmPipelinesStore extends EntityStore<CrmPipelinesState> {

  constructor() {
    super({
      ui: {
        selectedPipelineId: null
      }
    });
  }

}

