<ng-container *ngIf="!loading; else loadingScreen">
  <div class="accounts-not-found">
    <div>
      Для продолжения работы вам необходимо создать рекламный кабинет ВКонтакте. Для этого перейдите <a
      href="https://postmonitor.ru/blog/instrukciya-po-sozdaniyu-reklamnogo-kabineta-vkontakte" target="_blank"
      rel="nofollow noopener">по ссылке</a>.
    </div>
    <div class="activate-cabinets__toolbox">
      <div class="divider"></div>
      <button class="vk-standart-button activate-cabinets__refresh-button" (click)="Refresh()">Обновить</button>
    </div>
  </div>
</ng-container>
<ng-template #loadingScreen>
  <app-loading-screen>
    Подождите идет подключение рекламного кабинета к системе PostMonitor
  </app-loading-screen>
</ng-template>

