/**
 * Класс предоставляет возможность автоматизировать постраничное получение данных
 */
export abstract class Paginatable<T> {

  /**
   * Subject содержит в себе токен
   */
  protected token: T;

  /**
   * @param defaultToken - стандартное значение токена
   * @protected
   */
  protected constructor(defaultToken: T = null) {
    this.token = defaultToken;
  }

  /**
   * Метод реализуемый внутри класса наследующего функционал
   * @param tokenValue - новое значение токена пагинации
   * @constructor
   */
  public OnNextToken(tokenValue: T) {
    throw new Error("On next token function not overrided on this class")
  };


  /**
   * Задать новое значение токена
   * @param token
   * @constructor
   */
  public NextToken(token: T) {
    this.token = token;
    console.log(this.OnNextToken, 'ON NEXT');
    this.OnNextToken(token);
  }
}
