var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ChangeDetectorRef, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material';
import { AdManagerService, AutomationVkService, AutomationVkSlotService } from '../../../../api/services';
import { CreateCampaignFormComponent } from '../../../ad-manager/components/create-campaign-form/create-campaign-form.component';
import { ActivatedRoute } from '@angular/router';
import { MatConfirmDialogComponent } from '../../../ad-manager/components/modals/configrm-dialog/mat-confirm-dialog.component';
import { MultipleCopyStatus } from '../../../utils/types/multiple-copy-status';
import { delay, retry } from 'rxjs/operators';
import { AccountViewModel } from '../../../models/view-models/account-view-model';
import { isNullOrUndefined } from 'util';
import { environment } from "../../../../../environments/environment";
import { AgeSegmentSex } from "../../../models/view-models/age-segment";
export var MultipleCopyState;
(function (MultipleCopyState) {
    MultipleCopyState["LOADING"] = "LOADING";
    MultipleCopyState["COPY_FORM"] = "COPY_FORM";
    MultipleCopyState["READY"] = "READY";
    MultipleCopyState["ERROR"] = "ERROR";
})(MultipleCopyState || (MultipleCopyState = {}));
var MultipleCopyFormComponent = /** @class */ (function () {
    function MultipleCopyFormComponent(dialog, adManagerService, dialogData, automationVkService, automationVkSlotService, dialogService, route, cd) {
        this.dialog = dialog;
        this.adManagerService = adManagerService;
        this.dialogData = dialogData;
        this.automationVkService = automationVkService;
        this.automationVkSlotService = automationVkSlotService;
        this.dialogService = dialogService;
        this.route = route;
        this.cd = cd;
        this.state = MultipleCopyState.COPY_FORM;
        this.openedAdvancedMode = false;
        this.slots = [];
        this.accounts = [];
        this.campaigns = [];
        this.showRedirectLink = false;
        this.errorMessage = null;
        this.countOfCreatedAds = 0;
        this.countOfNewAd = 0;
        this.loadingAdvancedScreen = true;
        this.errorsOnCopy = [];
        this.isAllNotCopied = false;
        this.adsWithCopyingErrors = [];
        this.copiesData = [];
        this.segmentAudience = false;
        this.ages = [];
        this.showSegment = false;
        this.fileGroups = [];
        this.showCostValue = false;
        this.costValueLabelText = '';
        this.costValueMaxMessage = '';
        this.costValueMinMessage = '';
        this.showErrors = false;
        this.changeCostType = false;
    }
    Object.defineProperty(MultipleCopyFormComponent.prototype, "SegmentByGroups", {
        get: function () {
            return this.form.controls.segmentByGroups.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MultipleCopyFormComponent.prototype, "AgeSegments", {
        get: function () {
            return this.form.get('ages').value;
        },
        enumerable: true,
        configurable: true
    });
    MultipleCopyFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.showSegment = this.route.snapshot.queryParams.segments;
        var clientId = this.dialogData.clientId ? this.dialogData.clientId : 'null';
        console.log(this.dialogData.accountId + "_" + clientId, 'INITIATING DATA');
        this.form = new FormGroup({
            accountId: new FormControl(this.dialogData.accountId + "_" + (this.dialogData.clientId === 0 ? 'null' : this.dialogData.clientId)),
            campaignId: new FormControl(this.dialogData.campaignId),
            countOfCopies: new FormControl(1, [Validators.required, Validators.min(1), Validators.max(this.GetMaxCountOfCopiedAds())]),
            targetAccountId: new FormControl(this.dialogData.accountId + "_" + clientId),
            targetCampaignId: new FormControl(this.dialogData.campaignId),
            segmentByGroups: new FormControl('age'),
            adIds: new FormControl(this.dialogData.selectedAds),
            byGroups: new FormControl(false),
            ages: new FormArray([]),
            excludeOtherRetargetingGroups: new FormControl(false),
            excludeOtherGroups: new FormControl(false),
            costType: new FormControl(null),
            costValue: new FormControl(0),
        });
        this.form = new FormGroup({
            accountId: new FormControl(this.dialogData.accountId + "_" + (this.dialogData.clientId === 0 ? 'null' : this.dialogData.clientId)),
            campaignId: new FormControl(this.dialogData.campaignId),
            targetAccountId: new FormControl(this.dialogData.accountId + "_" + clientId),
            targetCampaignId: new FormControl(this.dialogData.campaignId),
            adIds: new FormControl(this.dialogData.selectedAds),
            copyingMode: new FormControl('DEFAULT_COPYING'),
            defaultConfiguration: new FormGroup({
                countOfCopies: new FormControl(1)
            }),
            groupsConfiguration: new FormGroup({
                excludeOtherGroups: new FormControl(false),
            }),
            groupsFromFileConfiguration: new FormGroup({
                groupsList: new FormControl(''),
                excludeOtherGroups: new FormControl(false),
            }),
            sexAndAgeConfiguration: new FormGroup({
                sexAndAgeItems: new FormArray([
                    new FormGroup({
                        ageFrom: new FormControl(),
                        ageTo: new FormControl(),
                        eachAge: new FormControl(false),
                        sex: new FormControl(),
                    })
                ])
            }),
            retargetingGroupsConfiguration: new FormGroup({
                excludeOtherRetargetingGroups: new FormControl(false)
            })
        });
        this.AddAgeSegmentation();
        this.countOfNewAd = this.dialogData.selectedAds.length;
        this.form.valueChanges.subscribe(function (_a) {
            var targetAccountId = _a.targetAccountId, targetCampaignId = _a.targetCampaignId, countOfCopies = _a.countOfCopies;
            _this.accountId = targetAccountId;
            _this.campaignId = targetCampaignId;
            _this.countOfNewAd = _this.dialogData.selectedAds.length * countOfCopies;
            if (parseInt(targetAccountId, 10) !== parseInt(_this.dialogData.accountId, 10) || parseInt(targetCampaignId, 10) !== parseInt(_this.dialogData.campaignId, 10)) {
                _this.NewCampaignQueryParams = {
                    campaign_id: _this.campaignId,
                    account_id: _this.accountId
                };
                _this.showRedirectLink = true;
            }
        });
        this.form.get('costType').valueChanges.subscribe(function (costType) {
            console.log(costType);
            switch (costType) {
                case 0:
                    _this.showCostValue = true;
                    _this.showErrors = false;
                    _this.setCpcValidators();
                    _this.showErrors = true;
                    break;
                case 1:
                    _this.showCostValue = true;
                    _this.showErrors = false;
                    _this.setCpmValidators();
                    _this.showErrors = true;
                    break;
                case null:
                    _this.showCostValue = false;
                    _this.setDefaultValidators();
                    break;
            }
            _this.setCostValueMessageText();
            console.log(_this.costValueMinMessage);
            console.log(_this.costValueMaxMessage);
        });
        this.form.controls.targetAccountId.valueChanges.subscribe(function (account) {
            console.log(account, 'ACCOUNT');
            var _a = account.split('_'), accountId = _a[0], clientId = _a[1];
            console.log(accountId, clientId);
            _this.LoadCampaigns(accountId, clientId)
                .subscribe(function (data) { return console.log; });
        });
        console.log(this.dialogData, 'DIALOG DATA');
    };
    MultipleCopyFormComponent.prototype.LoadInterestCategoreis = function () {
        var _this = this;
        this.adManagerService.GetInterestCategories()
            .subscribe(function (x) {
            _this.interestCategories = x;
        });
    };
    Object.defineProperty(MultipleCopyFormComponent.prototype, "agesControls", {
        get: function () {
            return this.form.get('ages').controls;
        },
        enumerable: true,
        configurable: true
    });
    MultipleCopyFormComponent.prototype.ToggleAdvancedMode = function () {
        var _this = this;
        this.openedAdvancedMode = !this.openedAdvancedMode;
        if (this.openedAdvancedMode) {
            this.LoadCabinetData()
                .subscribe(function () {
                setTimeout(function () {
                    _this.loadingAdvancedScreen = false;
                }, 3000);
            });
        }
    };
    MultipleCopyFormComponent.prototype.IsSegmentDisabled = function () {
        return this.dialogData.selectedAds.length > 1;
    };
    MultipleCopyFormComponent.prototype.ConfirmCopy = function () {
        var _this = this;
        var copies = this.PrepearData();
        this.copiesData = [];
        for (var _i = 0, copies_1 = copies; _i < copies_1.length; _i++) {
            var cop = copies_1[_i];
            for (var _a = 0, cop_1 = cop; _a < cop_1.length; _a++) {
                var d = cop_1[_a];
                this.copiesData.push(d);
            }
        }
        this.dialogService.open(MatConfirmDialogComponent, {
            data: {
                title: "\u041F\u043E\u0434\u0442\u0432\u0435\u0440\u0434\u0438\u0442\u0435 \u043A\u043E\u043F\u0438\u0440\u043E\u0432\u0430\u043D\u0438\u0435 \u043E\u0431\u044A\u044F\u0432\u043B\u0435\u043D\u0438\u0439",
                description: "\u0421\u043A\u043E\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C \u0432\u044B\u0431\u0440\u0430\u043D\u043D\u044B\u0435 \u043E\u0431\u044A\u044F\u0432\u043B\u0435\u043D\u0438\u044F \u0432 \u043A\u043E\u043B\u043B\u0438\u0447\u0435\u0441\u0442\u0432\u0435 " + this.copiesData.length + " \u0448\u0442.",
                confirmActionText: 'Скопировать',
                rejectActionText: 'Отменить'
            }
        })
            .afterClosed()
            .subscribe(function (status) {
            if (status) {
                _this.CopyAds();
            }
        });
    };
    MultipleCopyFormComponent.prototype.GetCountOfCopiedAds = function () {
        return this.form.value.countOfCopies * this.dialogData.selectedAds.length;
    };
    MultipleCopyFormComponent.prototype.CopyAds = function () {
        return __awaiter(this, void 0, void 0, function () {
            var copiesData, copies, _i, copies_2, cop, _a, cop_2, d, _loop_1, this_1, _b, copiesData_1, copy;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!this.form.valid) return [3 /*break*/, 5];
                        this.SetLoadingState();
                        copiesData = [];
                        copies = this.PrepearData();
                        for (_i = 0, copies_2 = copies; _i < copies_2.length; _i++) {
                            cop = copies_2[_i];
                            for (_a = 0, cop_2 = cop; _a < cop_2.length; _a++) {
                                d = cop_2[_a];
                                copiesData.push(d);
                            }
                        }
                        this.countOfNewAd = this.copiesData.length > 0 ? this.copiesData.length : copiesData.length;
                        _loop_1 = function (copy) {
                            var copyingResponse, e_1, newError, isErrorForAdding, ad, newAd;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        _a.trys.push([0, 2, , 3]);
                                        console.log(copy, 'LOG');
                                        copy.costValue = this_1.form.value.costValue;
                                        return [4 /*yield*/, this_1.adManagerService
                                                .CopyAds(copy)
                                                .pipe(delay(2000), retry(2))
                                                .toPromise()];
                                    case 1:
                                        copyingResponse = _a.sent();
                                        this_1.countOfCreatedAds++;
                                        return [3 /*break*/, 3];
                                    case 2:
                                        e_1 = _a.sent();
                                        newError = e_1.error;
                                        if (newError && newError.code) {
                                            isErrorForAdding = this_1.errorsOnCopy.map(function (err) { return err.code; }).includes(newError.code);
                                            if (isErrorForAdding) {
                                                this_1.errorsOnCopy.push(newError);
                                            }
                                        }
                                        ad = this_1.dialogData.selectedAds.find(function (x) { return x.id === copy.adIds[0]; });
                                        newAd = {
                                            name: "\u041A\u043E\u043F\u0438\u044F " + copy.numberOfCopy + " " + ad.name
                                        };
                                        this_1.adsWithCopyingErrors.push({
                                            ad: newAd,
                                            error: newError
                                        });
                                        return [3 /*break*/, 3];
                                    case 3: return [2 /*return*/];
                                }
                            });
                        };
                        this_1 = this;
                        _b = 0, copiesData_1 = copiesData;
                        _c.label = 1;
                    case 1:
                        if (!(_b < copiesData_1.length)) return [3 /*break*/, 4];
                        copy = copiesData_1[_b];
                        return [5 /*yield**/, _loop_1(copy)];
                    case 2:
                        _c.sent();
                        _c.label = 3;
                    case 3:
                        _b++;
                        return [3 /*break*/, 1];
                    case 4:
                        if (this.adsWithCopyingErrors.length > 0) {
                            this.isAllNotCopied = this.adsWithCopyingErrors.length === copiesData.length;
                            console.warn(this.isAllNotCopied, 'IS NOT ALL COPIED', this.adsWithCopyingErrors.length, copiesData.length);
                            this.SetState(MultipleCopyState.ERROR);
                        }
                        else {
                            this.SetState(MultipleCopyState.READY);
                        }
                        _c.label = 5;
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Создает данные для копирования 1 объявления
     * @param {string} accountId - id текущего рекламного кабинета ВК.
     * @param {string} clientId - id текущего клиента рекламного кабинета ВК.
     * @param {string} campaignId - id текущей рекламной кампании в текущем кабинете ВК.
     * @param {string} targetAccountId - id кабинета в который бует копироваться объявление
     * @param {string} targetClientId - id клиента рекламного кабинета в который будет копироваться объявление
     * @param {string} targetCampaignId - id кампании рекламного кабинета в которую будет копироватьс объявление
     * @param selectedAd - id копируемого объявления
     * @param {number} adNumber - номер копии
     * @param groupId
     * @param ageFrom
     * @param ageTo
     * @param sex
     * @param retargetingGroup
     * @param retargetingGroupNot
     * @param excludeOtherGroups
     * @param excludeOtherRetargetingGroup
     * @constructor
     */
    MultipleCopyFormComponent.prototype.CreateCopyingAdItem = function (accountId, clientId, campaignId, targetAccountId, targetClientId, targetCampaignId, selectedAd, adNumber, groupId, ageFrom, ageTo, sex, retargetingGroup, retargetingGroupNot, groupsNot, costType) {
        if (groupId === void 0) { groupId = null; }
        if (ageFrom === void 0) { ageFrom = null; }
        if (ageTo === void 0) { ageTo = null; }
        if (sex === void 0) { sex = null; }
        if (retargetingGroup === void 0) { retargetingGroup = null; }
        if (retargetingGroupNot === void 0) { retargetingGroupNot = null; }
        if (groupsNot === void 0) { groupsNot = null; }
        if (costType === void 0) { costType = null; }
        return {
            accountId: accountId,
            clientId: clientId === 'null' ? null : clientId,
            campaignId: campaignId,
            targetAccountId: targetAccountId,
            targetClientId: targetClientId === 'null' ? null : targetClientId,
            targetCampaignId: targetCampaignId,
            adIds: [selectedAd.id],
            countOfCopies: 1,
            numberOfCopy: adNumber + 1,
            groupId: groupId,
            ageFrom: ageFrom,
            ageTo: ageTo,
            sex: sex,
            retargetingGroup: retargetingGroup,
            retargetingGroupNot: retargetingGroupNot ? retargetingGroupNot : null,
            groupsNot: groupsNot,
            costType: costType
        };
    };
    MultipleCopyFormComponent.prototype.PrepearData = function () {
        var _this = this;
        this.copiesData = [];
        var copyingOptions = this.form.value;
        console.log(copyingOptions, 'COPYING');
        var _a = copyingOptions.accountId.split('_'), accountId = _a[0], clientId = _a[1];
        var _b = copyingOptions.targetAccountId.split('_'), targetAccountId = _b[0], targetClientId = _b[1];
        var data = this.dialogData.selectedAds.map(function (selectedAd, i) {
            if (_this.segmentAudience) {
                switch (copyingOptions.segmentByGroups) {
                    case 'file':
                    case 'ad_groups':
                        var groups_1 = [];
                        if (_this.fileGroups.length > 0) {
                            groups_1 = _this.fileGroups
                                .map(function (x) { return x.replace('https://vk.com/', '').replace('http://vk.com/', ''); });
                        }
                        else {
                            groups_1 = selectedAd.groups.split(',');
                        }
                        return groups_1.map(function (groupName) {
                            var groupsNot = null;
                            if (copyingOptions.excludeOtherGroups) {
                                groupsNot = groups_1.filter(function (x) { return x !== groupName; }).join(',');
                            }
                            return _this.CreateCopyingAdItem(accountId, clientId, copyingOptions.campaignId, targetAccountId, targetClientId, copyingOptions.targetCampaignId, selectedAd, i, groupName, null, null, null, null, null, groupsNot, copyingOptions.costType);
                        });
                    case 'age':
                        return _this.PrepearAgeSexSegmentsData(copyingOptions);
                    case 'retargeting_groups':
                        return _this.PrepearRetargetingGroupsData(copyingOptions);
                        break;
                }
            }
            else {
                console.log('STANDART COPY');
                var copies = [];
                for (var j = 0; j < copyingOptions.countOfCopies; j++) {
                    copies.push(_this.CreateCopyingAdItem(accountId, clientId, copyingOptions.campaignId, targetAccountId, targetClientId, copyingOptions.targetCampaignId, selectedAd, j, null, null, null, null, null, null, null, copyingOptions.costType));
                }
                return copies;
            }
        });
        console.log(data, 'DATA');
        return data;
    };
    MultipleCopyFormComponent.prototype.PrepearAgeSexSegmentsData = function (copyingOptions) {
        var _this = this;
        var _a = copyingOptions.accountId.split('_'), accountId = _a[0], clientId = _a[1];
        var _b = copyingOptions.targetAccountId.split('_'), targetAccountId = _b[0], targetClientId = _b[1];
        var selectedAd = this.dialogData.selectedAds[0];
        var segments = [];
        this.ages.forEach(function (segment, j) {
            console.log(segment);
            console.log(segment.eachAge, 'AGE SEGMTENT EACH AGE');
            if (segment.eachAge) {
                for (var ageItem = segment.ageFrom; ageItem <= segment.ageTo; ageItem++) {
                    _this.CreateSegmentData(accountId, clientId, copyingOptions, targetAccountId, targetClientId, selectedAd, j, ageItem, segments, segment, ageItem, ageItem);
                }
            }
            else {
                _this.CreateSegmentData(accountId, clientId, copyingOptions, targetAccountId, targetClientId, selectedAd, j, null, segments, segment, segment.ageFrom, segment.ageTo);
            }
        });
        return segments;
    };
    MultipleCopyFormComponent.prototype.CreateSegmentData = function (accountId, clientId, copyingOptions, targetAccountId, targetClientId, selectedAd, j, i, segments, segment, ageFrom, ageTo) {
        if (ageFrom === void 0) { ageFrom = null; }
        if (ageTo === void 0) { ageTo = null; }
        console.log(segment.sex, 'SEGMENT');
        switch (segment.sex) {
            case AgeSegmentSex.ALL:
                var seg = this.CreateCopyingAdItem(accountId, clientId, copyingOptions.campaignId, targetAccountId, targetClientId, copyingOptions.targetCampaignId, selectedAd, j, null, ageFrom, ageTo, null, null, null, null, copyingOptions.costType);
                segments.push(seg);
                break;
            case AgeSegmentSex.FEMALE:
            case AgeSegmentSex.MALE:
                var seg1 = this.CreateCopyingAdItem(accountId, clientId, copyingOptions.campaignId, targetAccountId, targetClientId, copyingOptions.targetCampaignId, selectedAd, j, null, ageFrom, ageTo, segment.sex, null, null, null, copyingOptions.costType);
                segments.push(seg1);
                break;
            case AgeSegmentSex.SPLIT:
                console.log('SPLIT');
                for (var z = 1; z <= 2; z++) {
                    console.log('SEGMENT FUCKING SEX', z);
                    var seg_1 = this.CreateCopyingAdItem(accountId, clientId, copyingOptions.campaignId, targetAccountId, targetClientId, copyingOptions.targetCampaignId, selectedAd, j, null, ageFrom > 0 ? ageFrom : null, ageTo > 0 ? ageTo : null, z, null, null, null, copyingOptions.costType);
                    segments.push(seg_1);
                }
                console.log(segments, 'SEGMENTS ADDED');
                break;
        }
    };
    MultipleCopyFormComponent.prototype.CreateSexCopiesData = function () {
    };
    MultipleCopyFormComponent.prototype.SetLoadingState = function () {
        this.SetState(MultipleCopyState.LOADING);
    };
    MultipleCopyFormComponent.prototype.SetState = function (state) {
        this.state = state;
    };
    MultipleCopyFormComponent.prototype.CloseWithStatus = function (status) {
        if (status === void 0) { status = MultipleCopyStatus.NOTHING_TO_DO; }
        var link = '';
        if (status === MultipleCopyStatus.NAVIGATE_TO) {
            var _a = this.form.value.targetAccountId.split('_'), accountId = _a[0], clientId = _a[1];
            link = "/automation/ads?account_id=" + accountId + "&campaign_id=" + this.form.value.targetCampaignId + "&client_id=" + clientId;
        }
        this.dialog.close({
            status: status,
            link: link
        });
    };
    MultipleCopyFormComponent.prototype.LoadCabinetData = function () {
        var _this = this;
        this.loadingAdvancedScreen = true;
        return this.LoadSlots()
            .flatMap(function () { return _this.LoadAccounts(); })
            .flatMap(function () {
            var _a = _this.form.value.targetAccountId.split('_'), accountId = _a[0], clientId = _a[1];
            return _this.LoadCampaigns(accountId, clientId);
        });
    };
    MultipleCopyFormComponent.prototype.LoadSlots = function () {
        var _this = this;
        return this.automationVkSlotService.GetSlots()
            .do(function (slotsResponse) {
            _this.slots = slotsResponse.data;
        });
    };
    MultipleCopyFormComponent.prototype.LoadAccounts = function () {
        var _this = this;
        return this.automationVkService.GetAccounts()
            .do(function (accountsResponse) { return __awaiter(_this, void 0, void 0, function () {
            var accounts, agencies, _i, agencies_1, account, clients, _a, clients_1, client;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        accounts = accountsResponse.data
                            .map(function (cabinet, i) { return _this.CreateNewCabinet(cabinet, i); });
                        agencies = accounts.filter(function (account) { return account.account_type === 'agency'; });
                        _i = 0, agencies_1 = agencies;
                        _b.label = 1;
                    case 1:
                        if (!(_i < agencies_1.length)) return [3 /*break*/, 4];
                        account = agencies_1[_i];
                        return [4 /*yield*/, this.LoadClients(account.account_id)];
                    case 2:
                        clients = _b.sent();
                        for (_a = 0, clients_1 = clients; _a < clients_1.length; _a++) {
                            client = clients_1[_a];
                            accounts.push(new AccountViewModel(account.account_id, client.name, account.account_role, account.account_status, 'client', this.IsAccountBlocked(account.account_id, client.id), client.id, account.priority));
                        }
                        _b.label = 3;
                    case 3:
                        _i++;
                        return [3 /*break*/, 1];
                    case 4:
                        this.accounts = accounts.filter(function (x) { return x.account_type !== 'agency'; });
                        return [2 /*return*/];
                }
            });
        }); })
            .delay(1000);
    };
    MultipleCopyFormComponent.prototype.CreateNewCabinet = function (cabinet, i) {
        var account_id = cabinet.account_id, account_name = cabinet.account_name, access_role = cabinet.access_role, account_status = cabinet.account_status, account_type = cabinet.account_type, is_blocked = cabinet.is_blocked, client_id = cabinet.client_id;
        return new AccountViewModel(account_id, account_name, access_role, account_status, account_type, this.IsAccountBlocked(account_id, client_id), client_id, i);
    };
    MultipleCopyFormComponent.prototype.LoadClients = function (accountId) {
        return this.automationVkService.getClients(accountId)
            .map(function (clientsResponse) { return clientsResponse.data; })
            .delay(1000)
            .toPromise();
    };
    MultipleCopyFormComponent.prototype.LoadCampaigns = function (accountId, clientId) {
        var _this = this;
        console.log(accountId);
        return this.automationVkService.GetCampaigns({
            accountId: accountId,
            clientId: clientId
        })
            .do(function (campaignResponse) {
            console.log('Setting Campaigns');
            console.log(campaignResponse.data);
            _this.campaigns = campaignResponse.data.filter(function (campaign) { return campaign.type === _this.getType(); })
                .sort(function (a, b) { return b.id - a.id; });
            _this.form.controls.targetCampaignId.setValue(_this.campaigns[0].id);
        })
            .delay(1000);
    };
    MultipleCopyFormComponent.prototype.OpenCreateCampaignForm = function () {
        var _this = this;
        var _a = this.form.value.targetAccountId.split('_'), accountId = _a[0], clientId = _a[1];
        this.dialogService.open(CreateCampaignFormComponent, {
            data: {
                clientId: clientId,
                type: this.getType(),
                accountId: accountId
            }
        })
            .afterClosed()
            .subscribe(function (data) {
            var _a = _this.form.value.targetAccountId.split('_'), accountId = _a[0], clientId = _a[1];
            _this.LoadCampaigns(accountId, clientId)
                .subscribe(function () {
            });
        });
    };
    MultipleCopyFormComponent.prototype.getType = function () {
        return this.dialogData.ad_format === 9 ? 'promoted_posts' : 'normal';
    };
    MultipleCopyFormComponent.prototype.GetMaxCountOfCopiedAds = function () {
        return 100 / this.dialogData.selectedAds.length;
    };
    MultipleCopyFormComponent.prototype.IsAccountBlocked = function (accountId, clientId) {
        var slotWithAccount = this.slots.find(function (slot) { return slot.bindedCabinetId === accountId && slot.bindedClientId === clientId; });
        console.log("Slot for account " + accountId + " and client " + clientId + ": ", slotWithAccount);
        return isNullOrUndefined(slotWithAccount);
    };
    MultipleCopyFormComponent.prototype.AccountTypeIsAgency = function (account_type) {
        return account_type === 'agency';
    };
    MultipleCopyFormComponent.prototype.GetAccountValue = function (accountId, clientId) {
        return accountId + "_" + clientId;
    };
    MultipleCopyFormComponent.prototype.IsCopyingToAnotherCabinet = function () {
        var clientId = (this.dialogData.clientId) ? this.dialogData.clientId : 'null';
        var accountId = this.dialogData.accountId + "_" + clientId;
        return this.form.value.targetAccountId !== accountId;
    };
    MultipleCopyFormComponent.prototype.AddAgeSegmentation = function () {
        var formAges = this.form.controls.ages;
        formAges.controls.push(new FormGroup({
            ageFrom: new FormControl(),
            ageTo: new FormControl(),
            eachAge: new FormControl(false),
            sex: new FormControl(0)
        }));
        formAges.valueChanges.subscribe(function (x) {
            console.log(x);
        });
        console.log(formAges.value, 'FUCKING AGES');
    };
    MultipleCopyFormComponent.prototype.RemoveAgeSegmentation = function (i) {
        var formAges = this.form.controls.ages;
        formAges.removeAt(i);
    };
    MultipleCopyFormComponent.prototype.GetAgeItems = function (control, isAgeFrom) {
        var _a = control.value, ageFrom = _a.ageFrom, ageTo = _a.ageTo;
        console.log({ ageFrom: ageFrom, ageTo: ageTo });
        if (isAgeFrom) {
            return this.GetAgesFrom(ageTo);
        }
        else {
            return this.GetAgesTo(ageFrom);
        }
    };
    MultipleCopyFormComponent.prototype.GetAgesFrom = function (ageTo) {
        var ages = environment.vkAges;
        if (ageTo) {
            ages = ages.filter(function (age) { return age <= ageTo; });
        }
        return ages;
    };
    MultipleCopyFormComponent.prototype.GetAgesTo = function (ageFrom) {
        var ages = environment.vkAges;
        if (ageFrom) {
            ages = ages.filter(function (age) { return age >= ageFrom; });
        }
        return ages;
    };
    MultipleCopyFormComponent.prototype.IsSegmentCrossedWithExisting = function (ageSegment, index) {
        var _this = this;
        console.log(this.AgeSegments);
        var segments = this.AgeSegments
            .filter(function (x, i) { return i !== index; })
            .map(function (ageSegmentItem, i) { return ({
            ageIndex: i,
            ageCrossed: _this.IsAgeSegmentsCrossed(ageSegment, ageSegmentItem),
            ageSegmentItem: ageSegmentItem
        }); });
        console.log(segments);
        return segments;
        // .filter(x => x.ageCrossed === true);
    };
    MultipleCopyFormComponent.prototype.IsAgeSegmentsCrossed = function (ageSegmentA, ageSegmentB) {
        return this.IsNumberInRange(ageSegmentA.ageFrom, ageSegmentB.ageFrom, ageSegmentB.ageTo)
            || this.IsNumberInRange(ageSegmentA.ageTo, ageSegmentB.ageFrom, ageSegmentB.ageTo);
    };
    MultipleCopyFormComponent.prototype.IsNumberInRange = function (n, rangeMin, rangeMax) {
        return rangeMin <= n && n <= rangeMax;
    };
    MultipleCopyFormComponent.prototype.AgeSegmentChanged = function (_a) {
        var index = _a.index, value = _a.value;
        this.ages[index] = value;
    };
    MultipleCopyFormComponent.prototype.fileChanged = function (fileInput) {
        var _this = this;
        if (fileInput.target.files && fileInput.target.files[0]) {
            var reader = new FileReader();
            reader.onload = function (e) {
                _this.fileGroups = e.target.result.split('\n');
                console.log(_this.fileGroups);
            };
            reader.readAsText(fileInput.target.files[0]);
        }
    };
    MultipleCopyFormComponent.prototype.ContainsSelectedRetargetingGroups = function () {
        if (this.dialogData.selectedAds) {
            console.log(this.dialogData.selectedAds[0].retargeting_groups);
            return this.dialogData.selectedAds[0] && this.dialogData.selectedAds[0].ContainsRetargetingGroups;
        }
        else {
            return false;
        }
    };
    MultipleCopyFormComponent.prototype.PrepearRetargetingGroupsData = function (copyingOptions) {
        var _this = this;
        var _a = copyingOptions.accountId.split('_'), accountId = _a[0], clientId = _a[1];
        var _b = copyingOptions.targetAccountId.split('_'), targetAccountId = _b[0], targetClientId = _b[1];
        var selectedAd = this.dialogData.selectedAds[0];
        var ads = [];
        var retargetingGroups = selectedAd.retargeting_groups.split(',');
        ads = retargetingGroups.map(function (retargetingGroup, i) {
            var retargetingGroupsNot = null;
            if (copyingOptions.excludeOtherRetargetingGroups) {
                retargetingGroupsNot = retargetingGroups.filter(function (x) { return x !== retargetingGroup; }).join(',');
            }
            console.log(retargetingGroupsNot, 'RETARGETING GROUPS');
            return _this.CreateCopyingAdItem(accountId, clientId, copyingOptions.campaignId, targetAccountId, targetClientId, copyingOptions.targetCampaignId, selectedAd, i, null, null, null, null, retargetingGroup, retargetingGroupsNot, copyingOptions.costType);
        });
        return ads;
    };
    MultipleCopyFormComponent.prototype.setCpmValidators = function () {
        var control = this.form.get('costValue');
        var minCpm = this.dialogData.bidRange.cpmMin / 100;
        var maxCpm = this.dialogData.bidRange.cpmMax / 100;
        console.log(minCpm, maxCpm);
        this.costValueMinMessage = "\u041C\u0438\u043D\u0438\u043C\u0430\u043B\u044C\u043D\u0430\u044F \u0446\u0435\u043D\u0430 1000 \u043F\u0440\u043E\u0441\u043C\u043E\u0442\u0440\u043E\u0432 \u0440\u0430\u0432\u043D\u0430 " + minCpm + " \u0440\u0443\u0431.";
        this.costValueMaxMessage = "\u041C\u0430\u043A\u0441\u0438\u043C\u0430\u043B\u044C\u043D\u0430\u044F \u0446\u0435\u043D\u0430 1000 \u043F\u0440\u043E\u0441\u043C\u043E\u0442\u0440\u043E\u0432 \u0440\u0430\u0432\u043D\u0430 " + maxCpm + " \u0440\u0443\u0431.";
        control.setValidators([Validators.required, Validators.min(minCpm), Validators.max(maxCpm)]);
        control.setValue(null);
        control.updateValueAndValidity();
        this.cd.detectChanges();
    };
    MultipleCopyFormComponent.prototype.setCpcValidators = function () {
        var control = this.form.get('costValue');
        var minCpc = this.dialogData.bidRange.cpcMin / 100;
        var maxCpc = this.dialogData.bidRange.cpcMax / 100;
        console.log(minCpc, maxCpc);
        this.costValueMinMessage = "\u041C\u0438\u043D\u0438\u043C\u0430\u043B\u044C\u043D\u0430\u044F \u0446\u0435\u043D\u0430 1 \u043F\u0435\u0440\u0435\u0445\u043E\u0434\u0430 \u0440\u0430\u0432\u043D\u0430 " + minCpc + " \u0440\u0443\u0431.";
        this.costValueMaxMessage = "\u041C\u0430\u043A\u0441\u0438\u043C\u0430\u043B\u044C\u043D\u0430\u044F \u0446\u0435\u043D\u0430 1 \u043F\u0435\u0440\u0435\u0445\u043E\u0434\u0430 \u0440\u0430\u0432\u043D\u0430 " + maxCpc + " \u0440\u0443\u0431.";
        control.setValidators([Validators.required, Validators.min(minCpc), Validators.max(maxCpc)]);
        control.setValue(null);
        control.updateValueAndValidity();
        this.cd.detectChanges();
    };
    MultipleCopyFormComponent.prototype.setDefaultValidators = function () {
        var control = this.form.get('costValue');
        control.setValidators([]);
    };
    MultipleCopyFormComponent.prototype.setCostValueMessageText = function () {
        var costType = this.form.get('costType').value;
        if (costType === 0) {
            this.costValueLabelText = 'Введите цену 1 перехода';
        }
        else {
            this.costValueLabelText = 'Введите цену 1000 показов';
        }
    };
    return MultipleCopyFormComponent;
}());
export { MultipleCopyFormComponent };
