import {AbstractCopyingConfigurator} from "./abstract-copying-configurator";
import {PostMonitorBackend_V2ModelsAdManagerCopyAdsViewModel} from "../../../api/models";

export class  GroupActivesCopyingConfigurator extends AbstractCopyingConfigurator{
  constructor(props) {
    super(props);
  }

  public generate(): PostMonitorBackend_V2ModelsAdManagerCopyAdsViewModel[] {
    const ads = [];
    this.copyingData.groupActive.groupActiveList.map(groupActiveId => {
      const ad = this.generateDefeault();
      ad.groupsActive = groupActiveId.toString();
      ads.push(ad);
    });
    return ads;
  }

}
