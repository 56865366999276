import {Injectable} from '@angular/core';
import {AkitaNgFormsManager} from '@datorama/akita-ng-forms-manager';
import {PlatformsTypes} from '../../../platforms-types';
import {IMtRemarketingGroupSettingsModel} from '../models/mt-remarketing-group-settings.model';
import {IPipelineStatusModel} from '../models/i-pipeline-status-model';
import {PlatformsQuery} from '../../../stores/platforms/platforms.query';
import {Log} from "@angular/core/testing/src/logger";
import {CrmPipelinesQuery} from "../../../stores/crm-pipelines/crm-pipelines.query";

@Injectable({
  providedIn: 'root'
})
export class MtRemarketingGroupsConfiguratorService {
  constructor(
    private fm: AkitaNgFormsManager,
    private platformsService: PlatformsQuery,
    private pipelinesQuery: CrmPipelinesQuery
  ) {
  }

  public configure(type: PlatformsTypes) {
    console.log('YANDEX AUDIENCE TESTINGS');
    
    const destinationPlatformSettingsValue = this.getValue();
    const originPlatformSettingsValue = this.fm.getForm('originPlatformSettings').value;
    const selectedPipelineId = this.getSelectedPipelineId();
    let result = [];
    const pipelineStatuses = Object
      .keys(destinationPlatformSettingsValue.pipeline_statuses)
      .map(key => this.getPipelineStatus(destinationPlatformSettingsValue, key))
      .map(pipelineStatus => this.createPipelineStatusData(pipelineStatus, selectedPipelineId))
      .filter(pipelineStatus => this.IsNotPipelineStatusDataEmpty(pipelineStatus));

      console.log(pipelineStatuses, selectedPipelineId, 'PIPELINE STATUS');

    console.log(this.isClassicModeAndSplitByPipelines(originPlatformSettingsValue, destinationPlatformSettingsValue), 'IS CLASSICAND SPLITTING');
    if (this.isClassicModeAndSplitByPipelines(originPlatformSettingsValue, destinationPlatformSettingsValue)) {
      console.log(pipelineStatuses, 'PIPELINE STATUSES !!!!');
      result = ['LEADS', 'DEALS'].map(filter => ({
        code: PlatformsTypes[type],
        filter: [filter],
        properties: {
          BASES_TYPE: destinationPlatformSettingsValue.type
        },
        data: pipelineStatuses
          .filter(x => x.pipelineType === filter)
          .map(x => {
            x.pipelineType = undefined;
            return x;
          })
      }));
    } else {
      console.log(pipelineStatuses, 'PIPELINES STATUSES WTF?????ZZZZ');
      result = [{
        code: PlatformsTypes[type],
        properties: {
          BASES_TYPE: destinationPlatformSettingsValue.type
        },
        data: pipelineStatuses.map(x => {
          x.pipelineType = undefined;
          return x;
        })
      }];
    }

    console.log(result, 'RESULT');
    

    return result;
  }

  public isClassicModeAndSplitByPipelines(origin, destination) {
    return origin.isClassicMode && destination.split_by_pipeline_statuses;
  }

  private IsNotPipelineStatusDataEmpty(pipelineStatus) {
    return pipelineStatus.data !== null && pipelineStatus.data.length > 0;
  }

  private getPipelineStatus(destinationPlatformSettingsValue, key) {
    return destinationPlatformSettingsValue.pipeline_statuses[key] as IPipelineStatusModel;
  }

  private createPipelineStatusData(pipelineStatus: IPipelineStatusModel, selectedPipelineId) {
    return {
      code: pipelineStatus.pipeline_id ? `${this.checkBitrixType(pipelineStatus.pipeline_type) ? 0 : selectedPipelineId}_${pipelineStatus.pipeline_id}` : null,
      pipelineType: pipelineStatus.pipeline_type,
      data: pipelineStatus.pipeline_retargeting_groups
        ? pipelineStatus.pipeline_retargeting_groups.map(x => x.toString())
        : null
    };
  }

  private getValue() {
    const form = this.fm.getForm('destinationPlatformSettings');
    return form ? form.value as IMtRemarketingGroupSettingsModel : null;
  }

  private getSelectedPipelineId() {
    const selectedPipelineId = this.fm.getForm('originPlatformSettings').value.selectedPipelineId;
    console.log(selectedPipelineId, 'SELECTED PIPELINE');
    
    return this.pipelinesQuery.getEntityIdByPipelineId$(selectedPipelineId);
  }

  public valid(type: PlatformsTypes) {
    const val = this.getValue();
    console.log(val, 'VALUE value TYPE');
    if (val && val.pipeline_statuses) {
      return Object.keys(val.pipeline_statuses)
        .map(key => this.getPipelineStatus(val, key))
        .map(pipelineStatus => this.hasPipelineStatusRetargetingGroups(pipelineStatus))
        .reduce((acc, curr) => acc || curr, false);
    } else {
      return false;
    }
  }

  private hasPipelineStatusRetargetingGroups(pipelineStatus) {
    if (pipelineStatus && pipelineStatus.pipeline_retargeting_groups) {
      return pipelineStatus.pipeline_retargeting_groups.length > 0;
    } else {
      return false;
    }
  }

  private checkBitrixType(section: string) {
    return this.platformsService.getValue().ui.selectedOriginPlatform === PlatformsTypes.BITRIX_EXPORT && section === 'LEADS';
  }
}
