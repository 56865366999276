/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./teazer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../shared/pipes/domain-only.pipe";
import * as i3 from "@angular/common";
import * as i4 from "../../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i5 from "@angular/material/icon";
import * as i6 from "./teazer.component";
var styles_TeazerComponent = [i0.styles];
var RenderType_TeazerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TeazerComponent, data: {} });
export { RenderType_TeazerComponent as RenderType_TeazerComponent };
export function View_TeazerComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DomainOnlyPipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 12, "div", [["class", "teazer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "teazer__image"]], null, null, null, null, null)), i1.ɵdid(3, 278528, null, 0, i3.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(4, { "background-image": 0 }), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "teazer-image__remove-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeImage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i4.View_MatIcon_0, i4.RenderType_MatIcon)), i1.ɵdid(7, 9158656, null, 0, i5.MatIcon, [i1.ElementRef, i5.MatIconRegistry, [8, null], [2, i5.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["close"])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "div", [["class", "teazer__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, [" ", " "])), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [["class", "teazer__link"]], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, [" ", " "])), i1.ɵppd(13, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 4, 0, (("url(" + _co.TeazerImage) + ")")); _ck(_v, 3, 0, currVal_0); _ck(_v, 7, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵnov(_v, 7).inline; var currVal_2 = (((i1.ɵnov(_v, 7).color !== "primary") && (i1.ɵnov(_v, 7).color !== "accent")) && (i1.ɵnov(_v, 7).color !== "warn")); _ck(_v, 6, 0, currVal_1, currVal_2); var currVal_3 = _co.TeazerTitle; _ck(_v, 10, 0, currVal_3); var currVal_4 = i1.ɵunv(_v, 12, 0, _ck(_v, 13, 0, i1.ɵnov(_v, 0), _co.TeazerLink)); _ck(_v, 12, 0, currVal_4); }); }
export function View_TeazerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-teazer", [], null, null, null, View_TeazerComponent_0, RenderType_TeazerComponent)), i1.ɵdid(1, 114688, null, 0, i6.TeazerComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TeazerComponentNgFactory = i1.ɵccf("app-teazer", i6.TeazerComponent, View_TeazerComponent_Host_0, { teazer: "teazer", defaultTitle: "defaultTitle" }, { ImageRemoved: "ImageRemoved" }, []);
export { TeazerComponentNgFactory as TeazerComponentNgFactory };
