var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { map as __map, filter as __filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../api-configuration";
import * as i2 from "@angular/common/http";
var CustomFormService = /** @class */ (function (_super) {
    __extends(CustomFormService, _super);
    function CustomFormService(config, http) {
        return _super.call(this, config, http) || this;
    }
    /**
     * @param params The `CustomFormService.Apiv2CustomFormCustomFunctionalPostParams` containing the following parameters:
     *
     * - `Phone`:
     *
     * - `FullName`:
     *
     * - `Email`:
     *
     * - `Description`:
     *
     * - `CompanyWebsite`:
     *
     * - `CompanyPosition`:
     *
     * - `CompanyName`:
     */
    CustomFormService.prototype.Apiv2CustomFormCustomFunctionalPostResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var __formData = new FormData();
        __body = __formData;
        if (params.Phone != null) {
            __formData.append('Phone', params.Phone);
        }
        if (params.FullName != null) {
            __formData.append('FullName', params.FullName);
        }
        if (params.Email != null) {
            __formData.append('Email', params.Email);
        }
        if (params.Description != null) {
            __formData.append('Description', params.Description);
        }
        if (params.CompanyWebsite != null) {
            __formData.append('CompanyWebsite', params.CompanyWebsite);
        }
        if (params.CompanyPosition != null) {
            __formData.append('CompanyPosition', params.CompanyPosition);
        }
        if (params.CompanyName != null) {
            __formData.append('CompanyName', params.CompanyName);
        }
        var req = new HttpRequest('POST', this.rootUrl + "/apiv2/custom-form/custom-functional", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `CustomFormService.Apiv2CustomFormCustomFunctionalPostParams` containing the following parameters:
     *
     * - `Phone`:
     *
     * - `FullName`:
     *
     * - `Email`:
     *
     * - `Description`:
     *
     * - `CompanyWebsite`:
     *
     * - `CompanyPosition`:
     *
     * - `CompanyName`:
     */
    CustomFormService.prototype.Apiv2CustomFormCustomFunctionalPost = function (params) {
        return this.Apiv2CustomFormCustomFunctionalPostResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    CustomFormService.Apiv2CustomFormCustomFunctionalPostPath = '/apiv2/custom-form/custom-functional';
    CustomFormService.ngInjectableDef = i0.defineInjectable({ factory: function CustomFormService_Factory() { return new CustomFormService(i0.inject(i1.ApiConfiguration), i0.inject(i2.HttpClient)); }, token: CustomFormService, providedIn: "root" });
    return CustomFormService;
}(__BaseService));
export { CustomFormService };
