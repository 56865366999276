import {Injectable} from '@angular/core';
import {QueryEntity} from '@datorama/akita';
import {CustomFieldsStore, CustomFieldsState} from './custom-fields.store';
import {PlatformsQuery} from "../platforms/platforms.query";
import {flatMap, tap} from "rxjs/operators";

@Injectable({providedIn: 'root'})
export class CustomFieldsQuery extends QueryEntity<CustomFieldsState> {

    public CustomFields$ = this.selectAll();
    
    public get CustomFields() {
        return this.getAll();
    }

    constructor(
        protected store: CustomFieldsStore,
        private platformsQuery: PlatformsQuery) {
        super(store);
    }

}
