import {Component, Input, OnInit} from '@angular/core';
import {AppSuggestionsService} from '../../../shared/services/suggestions-service/app-suggestions.service';
import {PostMonitorBackend_V2ModelsAdManagerEntitiesSuggestion} from '../../../api/models/post-monitor-backend-_v2models-ad-manager-entities-suggestion';

@Component({
  selector: 'app-suggestion-info',
  templateUrl: './suggestion-info.component.html',
  styleUrls: ['./suggestion-info.component.scss']
})
export class SuggestionInfoComponent implements OnInit {

  @Input() public code: string;
  @Input() public position: string = 'right-middle';
  @Input() public width: any = null;
  public suggestion: PostMonitorBackend_V2ModelsAdManagerEntitiesSuggestion;
  public isOpened = false;

  constructor(
    private suggestionService: AppSuggestionsService
  ) {
  }

  ngOnInit() {
    setInterval(() => {
      this.suggestion = this.suggestionService.GetSuggestion(this.code);
    }, 1000);
  }

  public ToggleSuggestion() {
    this.isOpened = !this.isOpened;
  }

}
