/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./affiliate-navigation.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "@angular/material/tabs";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/material/core";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "../../../../../node_modules/@angular/material/tabs/typings/index.ngfactory";
import * as i9 from "@angular/cdk/bidi";
import * as i10 from "@angular/cdk/scrolling";
import * as i11 from "./affiliate-navigation.component";
var styles_AffiliateNavigationComponent = [i0.styles];
var RenderType_AffiliateNavigationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AffiliateNavigationComponent, data: {} });
export { RenderType_AffiliateNavigationComponent as RenderType_AffiliateNavigationComponent };
function View_AffiliateNavigationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "a", [["class", "mat-tab-link"], ["mat-tab-link", ""], ["routerLinkActive", ""]], [[1, "target", 0], [8, "href", 4], [1, "aria-current", 0], [1, "aria-disabled", 0], [1, "tabIndex", 0], [2, "mat-tab-disabled", null], [2, "mat-tab-label-active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 671744, [[3, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(3, 1720320, [["rla", 4]], 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActiveOptions: [0, "routerLinkActiveOptions"], routerLinkActive: [1, "routerLinkActive"] }, null), i1.ɵqud(603979776, 2, { links: 1 }), i1.ɵqud(603979776, 3, { linksWithHrefs: 1 }), i1.ɵpod(6, { exact: 0 }), i1.ɵdid(7, 147456, [[1, 4]], 0, i4.MatTabLink, [i4.MatTabNav, i1.ElementRef, i1.NgZone, i5.Platform, [2, i6.MAT_RIPPLE_GLOBAL_OPTIONS], [8, null], i7.FocusMonitor], { active: [0, "active"] }, null), (_l()(), i1.ɵted(8, null, [" ", " "]))], function (_ck, _v) { var currVal_7 = _v.context.$implicit.path; _ck(_v, 2, 0, currVal_7); var currVal_8 = _ck(_v, 6, 0, true); var currVal_9 = ""; _ck(_v, 3, 0, currVal_8, currVal_9); var currVal_10 = i1.ɵnov(_v, 3).isActive; _ck(_v, 7, 0, currVal_10); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).target; var currVal_1 = i1.ɵnov(_v, 2).href; var currVal_2 = i1.ɵnov(_v, 7).active; var currVal_3 = i1.ɵnov(_v, 7).disabled.toString(); var currVal_4 = i1.ɵnov(_v, 7).tabIndex; var currVal_5 = i1.ɵnov(_v, 7).disabled; var currVal_6 = i1.ɵnov(_v, 7).active; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_11 = _v.context.$implicit.title; _ck(_v, 8, 0, currVal_11); }); }
export function View_AffiliateNavigationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "nav", [["class", "affiliate-program-statistics__navigation mat-tab-nav-bar"], ["mat-tab-nav-bar", ""]], null, null, null, i8.View_MatTabNav_0, i8.RenderType_MatTabNav)), i1.ɵdid(1, 3325952, null, 1, i4.MatTabNav, [i1.ElementRef, [2, i9.Directionality], i1.NgZone, i1.ChangeDetectorRef, i10.ViewportRuler], null, null), i1.ɵqud(603979776, 1, { _tabLinks: 1 }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_AffiliateNavigationComponent_1)), i1.ɵdid(4, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.links; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_AffiliateNavigationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-affiliate-navigation", [], null, null, null, View_AffiliateNavigationComponent_0, RenderType_AffiliateNavigationComponent)), i1.ɵdid(1, 114688, null, 0, i11.AffiliateNavigationComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AffiliateNavigationComponentNgFactory = i1.ɵccf("app-affiliate-navigation", i11.AffiliateNavigationComponent, View_AffiliateNavigationComponent_Host_0, {}, {}, []);
export { AffiliateNavigationComponentNgFactory as AffiliateNavigationComponentNgFactory };
