<ng-container *ngIf="!loading; else loadingScreen">
  <form [formGroup]="form" class="form">
    <h2 class="form__title">Подключение МойСклад</h2>

    <vk-form-field orientation="vertical" controlsWidth="100%">
      <vk-label>
        Логин
        <app-suggestion-info code="MOI_SKLAD_LOGIN"></app-suggestion-info>
      </vk-label>
      <input type="text" formControlName="login" vkInput placeholder="Введите имя пользователя">
      <vk-error validator="required">Вы не имя пользователя</vk-error>
    </vk-form-field>

    <vk-form-field orientation="vertical" controlsWidth="100%">
      <vk-label>
        Пароль
        <app-suggestion-info code="MOI_SKLAD_PASSWORD"></app-suggestion-info>
      </vk-label>
      <input type="password" formControlName="password" vkInput placeholder="Введите пароль">
      <vk-error validator="required">Вы не имя пользователя</vk-error>
    </vk-form-field>

    <button class="vk-standart-button vk-standart-button-strip" (click)="Assign()" [disabled]="form.invalid">
      Подключить МойСклад
    </button>
  </form>
</ng-container>

<ng-template #loadingScreen>
  <app-loading-screen>
    Подождите, идет подключение МойСклад
  </app-loading-screen>
</ng-template>
