import {Component, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';
import {AutomationVkService} from '../../api/services/automation-vk.service';
import {YandexMetrikaWrapperService} from '../../automation/services/yandex-metrika-wrapper.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-test-period-starts-now',
  templateUrl: './test-period-starts-now.component.html',
  styleUrls: ['./test-period-starts-now.component.scss']
})
export class TestPeriodStartsNowComponent implements OnInit {

  public listItems = [
    {
      code: 'vk-ad-cabinet',
      expand: false,
      isBinded: false,
      title: 'Автоматизировать рекламу ВКонтакте',
      description: 'Смотрите всю статистику по рекламе и управляйте ставками в одном кабинете!<br>Вам станут доступны:<br>' +
      '<ul>' +
      '<li>Управление ставками рекламных объявлений;</li>' +
      '<li>Выгрузка всей статистики в Excel;</li>' +
      '<li>Отображение всех параметров объявлений в одной строке, включая изображения, ссылки и UTM-метки.</li>' +
      '</ul>',
      binded_title: 'Рекламный кабинет ВКонтакте подключен!'
    },
    {
      code: 'yandex-metrika',
      expand: false,
      isBinded: false,
      title: 'Подключить Яндекс.Метрику',
      description: 'Добавьте доступ к Яндекс.Метрике, чтобы просматривать полные отчёты об эффективности рекламы.<br>Вам станут доступны:<br>' +
      '<ul>' +
      '<li>Автоматическое определение количества и цены достигнутых целей - заявки, покупки, регистрации и т.п.</li>' +
      '</ul>',
      binded_title: 'Яндекс.Метрика подключена!'
    }
  ];

  private baseUrl = environment.frontEndUrl;

  constructor(private apiAutomation: AutomationVkService,
              private yandex: YandexMetrikaWrapperService,
              private router: Router) {

    this.apiAutomation.CheckCabinetsBinded()
      .subscribe(data => {
        const automationItem = this.listItems.find(x => x.code === 'vk-ad-cabinet');

        if (automationItem) {
          automationItem.expand = true;
          automationItem.isBinded = true;
        }
      });

    if (this.yandex.IsAvaliable()) {
      const metrikaItem = this.listItems.find(x => x.code === 'yandex-metrika');

      if (metrikaItem) {
        metrikaItem.expand = true;
        metrikaItem.isBinded = true;
      }
    }
  }

  ngOnInit() {
  }

  public GetBindUrl(provider: string): string {
    return `${this.baseUrl}/apiv2/OAuth/authorize/${provider}`;
  }

  public BindProvider(provider: string): void {
    // Mark that we should return to THIS page
    localStorage.setItem('bind-return-to', this.router.url);

    // Redirect to provider binding URL
    window.location.assign(this.GetBindUrl(provider));
  }

  public StartButtonDisabled(): boolean {
    const automationItem = this.listItems.find(x => x.code === 'vk-ad-cabinet');

    return !(automationItem && automationItem.isBinded);
  }

  public StartWorkButtonClick(): void {
    this.router.navigate(['/automation/accounts']);
  }
}
