import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {List} from 'linqts';

@Component({
  selector: 'ngr-dg-pagination',
  templateUrl: './dg-pagination.component.html',
  styleUrls: ['./dg-pagination.component.scss']
})
export class DgPaginationComponent implements OnInit {

  @Input() public countOfPages;
  @Input() public currentPage;
  @Input() public maxShowedPages = 10;

  @Output() public currentPageChange: EventEmitter<number> = new EventEmitter<number>();

  public get pages() {
    return Array.from(new Array(this.countOfPages), (x, i) => i + 1);
  }

  constructor(
    private cd: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
  }

  public SetPage(page: number) {
    if (page > this.countOfPages) {
      this.currentPage = this.countOfPages;
    } else if (page < 1) {
      this.countOfPages = 1;
    } else {
      this.currentPage = page;
    }
    this.cd.markForCheck();
    this.currentPageChange.emit(this.currentPage);
  }

  public NextPage() {
    this.SetPage(this.currentPage + 1);
  }

  public PrevPage() {
    this.SetPage(this.currentPage - 1);
  }

  public LastPage() {
    this.SetPage(this.countOfPages);
  }

  public FirstPage() {
    this.SetPage(1);
  }

  public IsPageActive(page): boolean {
    return this.currentPage === page;
  }
}
