<ng-container *ngIf="!loading; else loadingScreen">
    <ng-container *ngIf="ContainsSource()">
      <div class="alert alert-success">
        <div class="alert-items">
          <span class="alert-text">
            E-mail подтверждён, теперь Вы можете войти в сервис PostMonitor.ru!
          </span>
        </div>
      </div>
    </ng-container>
    <form [formGroup]="form" (submit)="submit($event)">
        <label class="title">
            <h3 class="welcome">Вход в систему</h3>
        </label>
        <div class="login-group">
            <ng-container *ngIf="errors?.system">
                <div class="error active" *ngFor="let error of errors.system">{{error}}</div>
            </ng-container>
            <ng-container *ngIf="errors?.email">
                <div class="error active" *ngFor="let error of errors.email">
                    {{error}}
                </div>
            </ng-container>
            <input class="username" type="text" id="login_username" placeholder="Введите e-mail" formControlName="email">
            <ng-container *ngIf="errors?.password">
                <div class="error active" *ngFor="let error of errors.password">
                    {{error}}
                </div>
            </ng-container>
            <input class="password" type="password" id="login_password" placeholder="Введите пароль" formControlName="password">
            <button type="submit" class="btn btn-primary" [clrLoading]="loading">Войти</button>
            <a routerLink="/sign-up" class="signup">Зарегистироваться</a>
            <a routerLink="/forgot-password" class="signup">Забыли пароль?</a>
        </div>
    </form>
</ng-container>
<ng-template #loadingScreen>
    <div class="loading-screen">
        <div class="spinner"></div>
    </div>
</ng-template>
<app-external-login-form></app-external-login-form>
