import {Component, OnInit} from '@angular/core';
import {IDynamicFormInput} from '../../../../ngr-ui/models/DynamicFormInput';
import {ApiBlogService} from '../../../../api/services';

@Component({
  selector: 'app-create-post',
  templateUrl: './create-post.component.html',
  styleUrls: ['./create-post.component.scss']
})
export class CreatePostComponent implements OnInit {

  public postData = {};

  public constructor(
    private postsService: ApiBlogService
  ) {
  }


  public LoadTags() {
    return new Promise(resolve => {
      this.postsService.GetTags()
        .subscribe(x => {
          resolve(x);
        });
    });
  }

  public FormModel = {};

  public tags: Array<any>;

  ngOnInit() {
    this.LoadTags()
      .then(data => {
        this.FormModel = {
          title: {
            type: 'text',
            name: 'title',
            label: 'Название',
            placeholder: 'Название',
            Validators: []
          },
          previewImage: {
            type: 'text',
            name: 'previewImage',
            label: 'Картинка для поста (Preview)',
            placeholder: 'Описание',
            Validators: []
          },
          previewText: {
            type: 'textarea',
            name: 'previewText',
            label: 'Краткий текст поста (отобразится в списке всех постов)',
            placeholder: 'Описание',
            Validators: []
          },
          body: {
            type: 'html',
            name: 'body',
            label: 'Поный текст поста',
            placeholder: 'Полный текст поста',
            config: {
              filebrowserUploadUrl: 'http://localhost:6856/api/files'
            },
            Validators: []
          },
          tags: {
            type: 'checkbox-list',
            name: 'tags',
            options: data,
            Validators: []
          },
          robots: {
            type: 'text',
            name: 'robots',
            label: 'Мета теги для роботов (если нуджно что бы индексировалось то введите index, folow)',
            value: 'index, follow',
            Validators: []
          },
          metaDescription: {
            type: 'textarea',
            name: 'metaDescription',
            label: 'Описание',
            placeholder: 'Описание',
            Validators: []
          },
          metaKeywords: {
            type: 'text',
            name: 'metaKeywords',
            label: 'Ключевые слова',
            placeholder: '',
            Validators: []
          }
        };
      });
  }

  public HandleCancel() {
  }

  public HandleSuccess(data) {
    this.postData = data;
    console.log('WAS ADDED');
  }

  public Save() {
    this.postsService.CreatePost(this.postData)
      .subscribe(() => {
        alert('Пост добавлен');
      });
  }
}
