import { OnInit } from '@angular/core';
import { AdManagerDataServiceService } from "../../../../ad-manager/services/ad-manager-data-service.service";
import { InterestCategoriesService } from "../../../services/interest-categories.service";
import { CopyingService } from "../../../services/copying.service";
var CopyingByInterestCategoriesListCheckboxesComponent = /** @class */ (function () {
    function CopyingByInterestCategoriesListCheckboxesComponent(adDataManager, interestCategoriesService, copyingService) {
        this.adDataManager = adDataManager;
        this.interestCategoriesService = interestCategoriesService;
        this.copyingService = copyingService;
    }
    Object.defineProperty(CopyingByInterestCategoriesListCheckboxesComponent.prototype, "interestCategories", {
        get: function () {
            return this.interestCategoriesService.categoriesTree;
        },
        enumerable: true,
        configurable: true
    });
    CopyingByInterestCategoriesListCheckboxesComponent.prototype.ngOnInit = function () {
    };
    CopyingByInterestCategoriesListCheckboxesComponent.prototype.isTreeNodeOpened = function (category) {
        return this.interestCategoriesService.isCategoryOpened(category);
    };
    CopyingByInterestCategoriesListCheckboxesComponent.prototype.toggleTreeCategory = function (category) {
        console.log(this.interestCategoriesService.openedCategories, 'OPENED CATEGORIES');
        if (this.isTreeNodeOpened(category)) {
            this.interestCategoriesService.closeCategory(category);
        }
        else {
            this.interestCategoriesService.openCategory(category);
        }
    };
    CopyingByInterestCategoriesListCheckboxesComponent.prototype.isAllChildrenChecked = function (category) {
        return this.interestCategoriesService.isCategoryChildrensAllSelected(category);
    };
    CopyingByInterestCategoriesListCheckboxesComponent.prototype.isChildrenPartlyChecked = function (category) {
        return this.interestCategoriesService.isCategoryChildrensPartlySelected(category);
    };
    CopyingByInterestCategoriesListCheckboxesComponent.prototype.ToggleCategorySelect = function (category) {
        if (this.interestCategoriesService.isSelected(category)) {
            this.interestCategoriesService.removeSelected(category);
        }
        else {
            this.interestCategoriesService.selectCategory(category);
        }
        var interestIds = this.interestCategoriesService.getSelectedCategories();
        this.copyingService.form.patchValue({
            interestCategoriesCheckboxes: {
                interestIds: interestIds
            }
        });
    };
    CopyingByInterestCategoriesListCheckboxesComponent.prototype.ToggleParentCategorySelect = function (category) {
        if (this.interestCategoriesService.isSelected(category)) {
            this.interestCategoriesService.removeParentSelected(category);
        }
        else {
            this.interestCategoriesService.selectParentCategory(category);
        }
        var interestIds = this.interestCategoriesService.getSelectedCategories();
        this.copyingService.form.patchValue({
            interestCategoriesCheckboxes: {
                interestIds: interestIds
            }
        });
    };
    CopyingByInterestCategoriesListCheckboxesComponent.prototype.isSelected = function (category) {
        return this.interestCategoriesService.isSelected(category);
    };
    CopyingByInterestCategoriesListCheckboxesComponent.prototype.hasChildren = function (category) {
        return this.interestCategoriesService.hasChildren(category);
    };
    return CopyingByInterestCategoriesListCheckboxesComponent;
}());
export { CopyingByInterestCategoriesListCheckboxesComponent };
