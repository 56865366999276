import { Component, OnInit } from '@angular/core';
import {BannersService} from "../../services/banners.service";

@Component({
  selector: 'app-banners-content',
  templateUrl: './banners-content.component.html',
  styleUrls: ['./banners-content.component.scss']
})
export class BannersContentComponent implements OnInit {

  banners$ = this.bannersService.banners$;

  constructor(
    private bannersService: BannersService
  ) { }

  ngOnInit() {
    // this.bannersService.LoadAllBanners();
  }

}
