import { AfterViewInit, OnInit, QueryList } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { CheckboxItemDirective } from '../../directives/checkbox-item.directive';
var CheckboxesGroupComponent = /** @class */ (function () {
    function CheckboxesGroupComponent() {
        this.value = [];
        this.checkboxesValues = {};
    }
    CheckboxesGroupComponent.prototype.ngOnInit = function () {
    };
    CheckboxesGroupComponent.prototype.registerOnChange = function (fn) {
        this.onChange = fn;
    };
    CheckboxesGroupComponent.prototype.registerOnTouched = function (fn) {
        this.onTouch = fn;
    };
    CheckboxesGroupComponent.prototype.setDisabledState = function (isDisabled) {
    };
    CheckboxesGroupComponent.prototype.writeValue = function (obj) {
        this.value = obj;
    };
    CheckboxesGroupComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.checkboxes.changes.subscribe(function (checkboxes) {
            console.log(checkboxes, 'CHECKBOXES');
        });
        this.subscribeToCheckboxes();
        this.checkboxes.changes.subscribe(function (data) {
            _this.subscribeToCheckboxes();
        });
    };
    CheckboxesGroupComponent.prototype.subscribeToCheckboxes = function () {
        var _this = this;
        var checkboxes = this.checkboxes.toArray();
        var _loop_1 = function (checkbox) {
            var chekboxItemKey = checkbox.appCheckboxItem;
            checkbox.control.valueChanges.subscribe(function (value) {
                _this.checkboxesValues[chekboxItemKey] = value;
                _this.changed();
            });
            this_1.checkboxesValues[chekboxItemKey] = false;
        };
        var this_1 = this;
        for (var _i = 0, checkboxes_1 = checkboxes; _i < checkboxes_1.length; _i++) {
            var checkbox = checkboxes_1[_i];
            _loop_1(checkbox);
        }
        // this.checkboxesValues = checkboxes.map(checkbox => ({key: checkbox.appCheckboxItem, value: false}))
        //   .reduce((acc, {key, value}) => {
        //     acc[key] = value;
        //     return acc;
        //   }, {});
        // checkboxes.forEach(checkbox => {
        //   checkbox.control.valueChanges.subscribe(checkboxData => {
        //     this.checkboxesValues[checkbox.appCheckboxItem] = checkboxData;
        //     this.changed();
        //   });
        // });
    };
    CheckboxesGroupComponent.prototype.changed = function () {
        var _this = this;
        var selectedGroupIds = Object.keys(this.checkboxesValues).filter(function (x) { return _this.checkboxesValues[x]; });
        this.onChange(selectedGroupIds);
    };
    return CheckboxesGroupComponent;
}());
export { CheckboxesGroupComponent };
