import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {
  AdManagerService,
  AutomationVkService,
  AutomationVkSlotService,
  UserStrategiesService
} from '../../../../api/services';
import {AdViewModel} from '../../../models/view-models/ad-view-model';
import {delay, flatMap, map} from 'rxjs/operators';
import {Observable} from 'rxjs/Observable';
import {of} from 'rxjs/internal/observable/of';
import {DomainOnlyPipe} from '../../../../shared/pipes/domain-only.pipe';
import {PostMonitorBackend_V2ModelsAdManagerAdEditSecificationCustomizableViewModel} from '../../../../api/models/post-monitor-backend-_v2models-ad-manager-ad-edit-secification-customizable-view-model';
import {isNullOrUndefined} from 'util';
import {GroupsSelectorService} from '../../services/groups-selector/groups-selector.service';
import {UserAutomationCabinetSlot} from '../../../../api/models/user-automation-cabinet-slot';
import {AccountViewModel} from '../../../models/view-models/account-view-model';
import {Client} from '../../../../api/models/client';
import {DexieCabinetsService} from '../../../../shared/services/dexie/dexie-cabinets.service';
import {AutomationExpirationManagerService} from '../../../services/automation-expiration-manager.service';
import {DexieClientsService} from '../../../../shared/services/dexie/dexie-clients.service';

@Component({
  selector: 'app-update-ad',
  templateUrl: './update-ad.component.html',
  styleUrls: ['./update-ad.component.scss']
})
export class UpdateAdComponent implements OnInit {

  public accountId: number;
  public clientId: number;
  public campaignId: number;
  public adIds: Array<number>;
  public targetingSettings: any;
  public loading = false;
  public strategy = null;
  public imageLoaded = false;
  public image = null;
  public accounts;
  public slots;
  public loadingAccounts;

  public ads: Array<AdViewModel>;
  public willBeUpdateStrategies = false;

  public loaded = false;

  constructor(
    public route: ActivatedRoute,
    public vkService: AutomationVkService,
    public adManagerService: AdManagerService,
    public usService: UserStrategiesService,
    public router: Router,
    public groupService: GroupsSelectorService,
    private _api: AutomationVkService,
    private localDb: DexieCabinetsService,
    private _db: AutomationVkSlotService,
    public accessManager: AutomationExpirationManagerService,
    private localClientsDb: DexieClientsService
  ) {
  }

  ngOnInit(): void {
    this.accountId = parseInt(this.route.snapshot.queryParams.account_id, 10);
    this.clientId = parseInt(this.route.snapshot.queryParams.client_id, 10);
    if (isNaN(this.clientId)) {
      this.clientId = null;
    }
    this.campaignId = parseInt(this.route.snapshot.queryParams.campaign_id, 10);
    this.adIds = this.route.snapshot.queryParams.adId.split(',').map(adId => parseInt(adId, 10));

    this.groupService.ClearSelectedGroups();
    this.LoadData();
    setTimeout(() => {
      this.LoadAds();
    }, 2000);
  }

  public LoadData() {
    this.loadingAccounts = true;
    this.LoadSlots()
      .then(this.LoadAccounts.bind(this))
      .then(this.LoadClients.bind(this))
      .then(() => {
        this.accounts = this.accounts.map(account => {
          let slot: UserAutomationCabinetSlot = null;
          if (account.account_type === 'client') {
            slot = this.slots.find(x => x.bindedCabinetId === account.account_id && x.bindedClientId === account.client_id);
          } else {
            slot = this.slots.find(x => x.bindedCabinetId === account.account_id);
          }
          account.is_blocked = isNullOrUndefined(slot);
          this.accounts = this.accounts.sort((a, b) => a.priority - b.priority);
          this.loadingAccounts = false;
          return account;
        });
      });
    // this.LoadSlots();
  }

  public LoadSlots() {
    return new Promise(resolve => {
      this._db.GetSlots()
        .subscribe(x => {
          this.slots = x.data;
          // this.countOfMaxSlots = this.slots.length;
          // if (this.firstLoading) {
          //   this.opened = this.slots.filter(slot => slot.bindedCabinetId !== null).length === 0;
          // }
          // this.countOfFree = this.slots.filter(slot => slot.bindedCabinetId === null).length;
          // this.firstLoading = false;
          resolve();
        });
    });
  }

  public LoadAccounts() {
    // this.accountsViewModels = [];
    return new Promise(resolve => {
      this._api.GetAccounts()
        .subscribe(x => {
          this.accounts = x.data.map((y, index) => {
            return new AccountViewModel(
              y.account_id,
              y.account_name,
              y.access_role,
              y.account_status,
              y.account_type,
              y.is_blocked,
              null,
              index
            );
          });

          resolve();
        });
    });
  }

  public LoadClients() {
    return new Promise(resolve => {
      const promises = this.accounts.filter(x => x.account_type === 'agency')
        .map((account, i) => {
          return new Promise(resolve1 => {
            setTimeout(() => {
              this._api.getClients(account.account_id)
                .delay(3000)
                .subscribe(clients => {
                  clients.data.forEach((client: Client) => {
                    this.accounts.push(new AccountViewModel(
                      account.account_id,
                      client.name,
                      account.account_role,
                      account.account_status,
                      'client',
                      account.is_blocked,
                      client.id,
                      account.priority
                    ));
                  });
                  this.localClientsDb.SetClients(clients.data)
                    .then(data => {
                      resolve1();
                    });
                });
            }, 1500 * i);
          });
        });
      return Promise.all(promises)
        .then(() => {
          resolve();
        });
    });
  }

  public LoadAds() {
    this.vkService.GetAds({
      accountId: this.accountId,
      campaignIds: [this.campaignId],
      clientId: this.clientId,
      adIds: this.adIds,
      includeDeleted: false,
      limit: 0,
      offset: 0
    })
      .pipe(
        delay(1000),
        map(({data}) => {
          this.ads = data.map(x => {
            const ad = new AdViewModel();
            ad.ConvertAd(x);
            return ad;
          });
          return this.ads;
        }),
        flatMap(ads => {
          return this.vkService.GetAdsTargeting({
            accountId: this.accountId,
            campaignIds: [this.campaignId],
            clientId: this.clientId,
            adIds: this.adIds,
            includeDeleted: false,
            limit: 0,
            offset: 0
          })
            .delay(2000)
            .map((data) => {
              console.log(data);
              this.targetingSettings = data['data'][0];
              if (this.targetingSettings) {
                this.targetingSettings.country = this.targetingSettings ? parseInt(this.targetingSettings.country, 10) : 0;
                this.targetingSettings.cities = this.targetingSettings && this.targetingSettings.cities ? this.targetingSettings.cities.split(',').map(city => parseInt(city, 10)) : [];
              }
              return ads;
            })
            .delay(1000);
        }),
        flatMap(ads => {
          console.log('Start loading Layouts');
          console.log(ads);
          let obs = this.vkService.GetAdsLayout({
            accountId: this.accountId,
            campaignIds: [this.campaignId],
            clientId: this.clientId,
            adIds: this.adIds,
            includeDeleted: false,
            limit: 0,
            offset: 0
          })
            .map(({data}) => {
              data.map(adLayout => {
                const ad = this.ads.find(ad1 => ad1.id === adLayout.id);
                ad.AddLayout(adLayout, null);
                return ads;
              });
              console.log(this.ads);
              return this.ads;
            })
            .delay(1000);
          return obs;
        }),
        flatMap(ads => {
          console.log(ads);
          console.log('Start loading Wall');
          let obs: Observable<any> = of(ads);
          if (ads[0].ad_format === 9) {
            console.log('Start will be loaded');
            const postIds = ads
              .map(x => {
                console.log(x);
                return x.link_url.replace('http://vk.com/wall', '')
              })
              .join();
            obs = this.vkService.GetWallById(postIds)
              .map(({data}) => {
                this.ads.map(ad => {
                  const newIds = ad.link_url
                    .replace('http://vk.com/wall', '')
                    .split('_')
                    .map(adl => parseInt(adl, 10));

                  const w = data.find(wall => wall.owner_id === newIds[0] && wall.id === newIds[1]);
                  ad.AddWall(w);

                  console.log(newIds);

                });
                return data;
              })
              .delay(1000);
          }
          return obs;
        }),
        flatMap(() => this.LoadStrategy())
      )
      .subscribe(data => {
        console.log(data);
        this.loaded = true;
      });
  }

  public OnSaveAd({ad, strategy, updateStrategy}) {
    const adData = ad;
    this.loading = true;
    const images = adData.teazer.image || adData.promopost.image;
    const texts = (this.ads[0].ad_format !== 9) ? adData.teazer.title : adData.promopost.text;

    const ads = [];

    images.forEach(image => {
      texts.forEach(text => {
        console.log(image, text);
        ads.push(this.CreateData(adData, text, image));
      });
    });

    console.log(ads);

    this.adManagerService.UpdateAd(ads)
      .subscribe(data => {
        this.loading = false;
        console.log(updateStrategy);
        const [accountId, clientId] = adData.campaignData.accountId.split('_');
        console.log(strategy && strategy !== {}, strategy, strategy !== {});
        console.log(adData.moderationSettings, adData);
        this.SendModeration(adData.moderationSettings, accountId, clientId, data.map(ad1 => ad1.id))
          .subscribe(() => {
            console.log(adData.rate.type === 'auto' && !(strategy === null || Object.keys(strategy).length === 0), adData.rate.type, strategy);
            if (adData.rate.type === 'auto' && !(strategy === null || Object.keys(strategy).length === 0)) {
              console.log('strategy update');
              let obs = null;
              console.log(isNullOrUndefined(this.strategy), this.strategy);
              if (!isNullOrUndefined(this.strategy)) {
                obs = this.usService.UpdateUserStrategies({
                  accountId: parseInt(accountId, 10),
                  clientId: clientId !== 'null' ? parseInt(clientId, 10) : 0,
                  ads: (data as Array<any>).map(x => x.id),
                  properties: strategy.data,
                  strategyType: strategy.type,
                  updateStep: 10
                });
              } else {
                obs = this.usService.CreateUserStrategy({
                  accountId: parseInt(accountId, 10),
                  clientId: clientId !== 'null' ? parseInt(clientId, 10) : 0,
                  ads: (data as Array<any>).map(x => x.id),
                  properties: strategy.data,
                  strategyType: strategy.type,
                  updateStep: 10
                });
              }
              obs
                .subscribe(data1 => {
                  this.NavigateToAds(adData, accountId, clientId, data);
                });
            } else {
              console.log('Strategies not remove');
              console.log(strategy);
              console.log((strategy !== null && Object.keys(strategy).length === 0) && updateStrategy);
              if (this.strategy && (strategy !== null && Object.keys(strategy).length === 0) && updateStrategy) {
                console.log('Strategies to remove');
                this.RemoveStrategy(accountId, data[0].id)
                  .subscribe(() => {
                    setTimeout(() => {
                      this.NavigateToAds(adData, accountId, clientId, data);
                    }, 1000);
                  });
              } else {
                setTimeout(() => {
                  this.NavigateToAds(adData, accountId, clientId, data);
                }, 1000);
              }
            }
          }, () => {
            if (strategy !== null && Object.keys(strategy).length > 0) {
              this.usService.UpdateUserStrategies({
                accountId: parseInt(accountId, 10),
                clientId: clientId !== 'null' ? parseInt(clientId, 10) : 0,
                ads: (data as Array<any>).map(x => x.id),
                properties: strategy.data,
                strategyType: strategy.type,
                updateStep: 10
              })
                .subscribe(data1 => {
                  this.NavigateToAds(adData, accountId, clientId, data);
                });
            } else {
              setTimeout(() => {
                this.NavigateToAds(adData, accountId, clientId, data);
              }, 1000);
            }
          });

      });
  }

  public SendModeration(moderationSettings, accountId, clientId, adIds) {
    if (moderationSettings.sendModeration) {
      return this.usService.ToggleAdStatus({
        accountId,
        adIds,
        status: true
      })
        .map(toggleStatusResponse => {
          const errorCodes = toggleStatusResponse
            .filter(error => error.error_code !== null)
            .map(response => response.error_code);

          if (errorCodes.length > 0) {
            window.localStorage.setItem('send_moderation', JSON.stringify(errorCodes));
          }

          return toggleStatusResponse;
        })
        .pipe(
          flatMap(() => {
            if (!moderationSettings.startAfterModeration) {
              return this.usService.ToggleAdStatus({
                accountId,
                adIds,
                status: false
              })
                .map(toggleStatusResponse => {
                  const errorCodes = toggleStatusResponse
                    .filter(error => error.error_code !== null)
                    .map(response => response.error_code);

                  if (errorCodes.length > 0) {
                    window.localStorage.setItem('start_after_moderation', JSON.stringify(errorCodes));
                  }

                  return toggleStatusResponse;
                })
                .map(data => false);
            } else {
              return of(true);
            }
          })
        );
    } else {
      return of(false);
    }
  }

  private NavigateToAds(adData, accountId: any, clientId: any, data) {
    console.log(adData.campaignData.campaignId, 'campaign');
    this.router.navigate(['/automation/ads'], {
      queryParams: {
        sendModeration: adData.moderationSettings.sendModeration,
        startAfterModeration: adData.moderationSettings.startAfterModeration,
        account_id: parseInt(accountId, 10),
        client_id: clientId !== 'null' ? parseInt(clientId, 10) : null,
        campaign_id: adData.campaignData.campaignId,
        adId: data[0].id
      }
    });
  }

  public CreateData(adData, text, image) {
    console.log(adData);
    const ad: PostMonitorBackend_V2ModelsAdManagerAdEditSecificationCustomizableViewModel = {
      ad_id: adData.adId[0],
      ad_format: adData.format.ad_format,
      age_from: adData.targetingData.ageFrom,
      age_to: adData.targetingData.ageTo,
      attachments: '',
      // campaign_id: adData.campaignData.campaignId,
      cities: adData.targetingData.cities.split(',').filter(x => parseInt(x, 10) >= 0).join(),
      country: adData.targetingData.country.toString().split(',').filter(x => parseInt(x, 10) >= 0).join(),
      sex: adData.targetingData.sex,
      groups: adData.targetingData.groups,
      title: text,
      image: image,
      link_url: encodeURIComponent(adData.teazer.link),
      link_domain: new DomainOnlyPipe().transform(adData.teazer.link),
      cpm: adData.rate.cpm,
      category1_id: adData.format.ad_format === 9 ? adData.promopost.categoryId : adData.teazer.categoryId,
      name: adData.format.ad_format === 9 ? adData.promopost.name : adData.teazer.name
      // cost_type: 1
    };
    console.log(adData, adData.targetingData.cities, 'TARGET CITIES');

    const adViewModel = {
      adSpecifications: [ad],
      accountId: adData.campaignData.accountId.split('_')[0],
      groupId: adData.promopost.groupId,
      text: text,
      linkTitle: adData.promopost.linkTitle,
      linkUrl: encodeURIComponent(adData.promopost.linkAddress),
      postId: (this.ads[0].ad_format === 9) ? parseInt(this.ads[0].link_url.split('_')[1], 10) : 0
    };

    return adViewModel;
  }

  public LoadStrategy() {
    return this.usService.GetStrategiesByAdAndAccountIds({
      accountId: this.accountId,
      viewModel: {
        adIds: this.adIds
      },
      clientId: this.clientId
    })
      .map(strategies => {
        console.log(strategies);
        this.strategy = strategies[0];
      });
  }

  private RemoveStrategy(accountId, adId: number) {
    return this.usService.RemoveStrategy({
      accountId,
      adId
    });
  }
}
