/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./affiliate-program-layout.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i3 from "@angular/material/card";
import * as i4 from "../affiliate-navigation/affiliate-navigation.component.ngfactory";
import * as i5 from "../affiliate-navigation/affiliate-navigation.component";
import * as i6 from "@angular/router";
import * as i7 from "./affiliate-program-layout.component";
var styles_AffiliateProgramLayoutComponent = [i0.styles];
var RenderType_AffiliateProgramLayoutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AffiliateProgramLayoutComponent, data: {} });
export { RenderType_AffiliateProgramLayoutComponent as RenderType_AffiliateProgramLayoutComponent };
export function View_AffiliateProgramLayoutComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "affiliate-program-statistics"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [["class", "affiliate-program-statistics__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u041F\u0430\u0440\u0442\u043D\u0435\u0440\u0441\u043A\u0430\u044F \u043F\u0440\u043E\u0433\u0440\u0430\u043C\u043C\u0430"])), (_l()(), i1.ɵeld(3, 0, null, null, 5, "mat-card", [["class", "affiliate-program-statistics__navigation mat-card"]], null, null, null, i2.View_MatCard_0, i2.RenderType_MatCard)), i1.ɵdid(4, 49152, null, 0, i3.MatCard, [], null, null), (_l()(), i1.ɵeld(5, 0, null, 0, 3, "mat-card-content", [["class", "mat-card-content"]], null, null, null, null, null)), i1.ɵdid(6, 16384, null, 0, i3.MatCardContent, [], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "app-affiliate-navigation", [], null, null, null, i4.View_AffiliateNavigationComponent_0, i4.RenderType_AffiliateNavigationComponent)), i1.ɵdid(8, 114688, null, 0, i5.AffiliateNavigationComponent, [], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 5, "mat-card", [["class", "affiliate-program-statistics__content mat-card"]], null, null, null, i2.View_MatCard_0, i2.RenderType_MatCard)), i1.ɵdid(10, 49152, null, 0, i3.MatCard, [], null, null), (_l()(), i1.ɵeld(11, 0, null, 0, 3, "mat-card-content", [["class", "mat-card-content"]], null, null, null, null, null)), i1.ɵdid(12, 16384, null, 0, i3.MatCardContent, [], null, null), (_l()(), i1.ɵeld(13, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(14, 212992, null, 0, i6.RouterOutlet, [i6.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 8, 0); _ck(_v, 14, 0); }, null); }
export function View_AffiliateProgramLayoutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-affiliate-program-layout", [], null, null, null, View_AffiliateProgramLayoutComponent_0, RenderType_AffiliateProgramLayoutComponent)), i1.ɵdid(1, 114688, null, 0, i7.AffiliateProgramLayoutComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AffiliateProgramLayoutComponentNgFactory = i1.ɵccf("app-affiliate-program-layout", i7.AffiliateProgramLayoutComponent, View_AffiliateProgramLayoutComponent_Host_0, {}, {}, []);
export { AffiliateProgramLayoutComponentNgFactory as AffiliateProgramLayoutComponentNgFactory };
