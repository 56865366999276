<div class="targeting-settings">
  <div class="targeting-settings__controls">
    <form [formGroup]="form">
      <div class="vk-strip-wrapper vk-strip-wrapper_ad-cabinet-style">
        <div class="vk-ad-cabinet-header">
          Выберите в какой стране и городах будет показываться объявление
        </div>
      </div>
      <vk-form-field [controlsWidth]="controlWidth" [labelWidth]="labelWidth">
        <vk-label>Выберите страну</vk-label>
        <select vkInput formControlName="country">
          <option [value]="null">Любая</option>
          <ng-container *ngFor="let country of countries">
            <option [value]="country.id">{{country.title}}</option>
          </ng-container>
        </select>
      </vk-form-field>
      <vk-form-field [controlsWidth]="controlWidth" [labelWidth]="labelWidth">
        <vk-label>
          Выберите город или регион:
        </vk-label>
        <mat-select vkInput formControlName="cities" [multiple]="true">
          <ngx-mat-select-search
            [formControl]="citySearchString"
            placeholderLabel="Введите название города"
          ></ngx-mat-select-search>
          <mat-option [value]="0">Любой</mat-option>
          <ng-container *ngFor="let city of cities">
            <mat-option [value]="city.id">
              <div class="city-option">
                <div class="city-option__title">
                  {{city.title}}
                </div>
                <ng-container *ngIf="city.region">
                  -
                  <div class="city-option__region">
                    {{city.region}}
                  </div>
                </ng-container>
              </div>
            </mat-option>
          </ng-container>
        </mat-select>
      </vk-form-field>

      <div class="vk-strip-wrapper vk-strip-wrapper_ad-cabinet-style">
        <div class="vk-ad-cabinet-header">
          Выберите пользователям какого пола и возраста будет показываться объявление
        </div>
      </div>

      <vk-form-field [controlsWidth]="controlWidth" [labelWidth]="labelWidth">
        <vk-label>Выберите пол:</vk-label>
        <mat-radio-group vkInput [useStyles]="false" formControlName="sex" class="radio-group">
          <mat-radio-button color="primary" [value]="0">Любой</mat-radio-button>
          <mat-radio-button color="primary" [value]="2">Мужской</mat-radio-button>
          <mat-radio-button color="primary" [value]="1">Женский</mat-radio-button>
        </mat-radio-group>
      </vk-form-field>

      <vk-form-field [controlsWidth]="controlWidth" [labelWidth]="labelWidth">
        <vk-label>Возраст:</vk-label>
        <select vkInput formControlName="ageFrom">
          <option [value]="0">Любой</option>
          <ng-container *ngFor="let age of agesFrom">
            <option [value]="age">От {{age}}</option>
          </ng-container>
        </select>
        <select vkInput formControlName="ageTo">
          <option [value]="0">Любой</option>
          <ng-container *ngFor="let age of agesTo">
            <option [value]="age">До {{age}}</option>
          </ng-container>
        </select>
      </vk-form-field>

      <vk-form-field [controlsWidth]="controlWidth" [labelWidth]="labelWidth">
        <vk-label>Аудитория ретаргетинга</vk-label>
        <mat-select [multiple]="true" formControlName="targetGroups" vkInput>
          <ngx-mat-select-search [formControl]="searchTargetGroups" placeholderLabel="Введите название таргетинговой аудитории">

          </ngx-mat-select-search>
          <mat-select-trigger>
            Выбрано {{SelectedTargetingGroupsName.length}} аудиторий.
          </mat-select-trigger>
          <ng-container *ngFor="let targetGroup of TargetGroups">
            <mat-option [value]="targetGroup.id">
              <app-target-group [targetGroup]="targetGroup"></app-target-group>
            </mat-option>
          </ng-container>
        </mat-select>
      </vk-form-field>

      <vk-form-field [controlsWidth]="controlWidth" [labelWidth]="labelWidth">
        <vk-label>За исключением аудиторий <br> ретаргетинга</vk-label>
        <mat-select [multiple]="true" formControlName="targetGroupsNot" vkInput>
          <ngx-mat-select-search [formControl]="searchTargetGroups" placeholderLabel="Введите название таргетинговой аудитории">

          </ngx-mat-select-search>
          <mat-select-trigger>
            Выбрано {{SelectedTargetingGroupsNotName.length}} аудиторий.
          </mat-select-trigger>
          <ng-container *ngFor="let targetGroup of TargetGroupsNot">
            <mat-option [value]="targetGroup.id">
              <app-target-group [targetGroup]="targetGroup"></app-target-group>
            </mat-option>
          </ng-container>
        </mat-select>
      </vk-form-field>
    </form>

    <div class="vk-strip-wrapper vk-strip-wrapper_ad-cabinet-style">
      <div class="vk-ad-cabinet-header">
        Выберите группы, подписчикам которых будет показываться объявление
      </div>
    </div>
    <app-generic-tooltip [header]="'Как выбрать группы'" [text]="TipGroups" [noMargin]="true"></app-generic-tooltip>

    <form [formGroup]="searchForm">
      <vk-form-field [controlsWidth]="controlWidth" [labelWidth]="labelWidth">
        <vk-label>Название группы:</vk-label>
        <input type="text" formControlName="title" placeholder="Введите название группы" vkInput>
      </vk-form-field>

      <vk-form-field [controlsWidth]="controlWidth" [labelWidth]="labelWidth">
        <vk-label>Страна:</vk-label>
        <select vkInput formControlName="countryId" [class.select_disabled]="!GetIsTitleValid()">
          <option [value]="0">Любая</option>
          <ng-container *ngFor="let city of countries">
            <option [value]="city.id">{{city.title}}</option>
          </ng-container>
        </select>
      </vk-form-field>

      <vk-form-field [controlsWidth]="controlWidth" [labelWidth]="labelWidth">
        <vk-label>Город: </vk-label>
        <mat-select vkInput formControlName="cityId" color="primary" [disabled]="!GetIsCountryValid()">
          <ngx-mat-select-search
            [formControl]="citySearchGroupCities"
            placeholderLabel="Введите название города"
          ></ngx-mat-select-search>
          <mat-option [value]="0">Любой</mat-option>
          <ng-container *ngFor="let city of searchGroupsCities | async">
            <mat-option [value]="city.id">{{city.title}}</mat-option>
          </ng-container>
        </mat-select>
      </vk-form-field>
    </form>

    <ng-container *ngIf="!loadingSearchGroups; else loadingScreen;">
      <app-groups-table [groups]="groups"></app-groups-table>
    </ng-container>
    <ng-template #loadingScreen>
      <div class="loading-screen">
        <div class="loading-screen__spinner">
          <mat-spinner diameter="18"></mat-spinner>
        </div>
        <div class="loading-screen__message">
          Подождите, идет поиск групп ...
        </div>
      </div>
    </ng-template>
  </div>
  <div class="targeting-settings__data">
    <app-new-targeting-stats [targeting]="targetingStats" [isDirty]="form.dirty"
                             [loading]="loadingTargetingStats"></app-new-targeting-stats>
    <app-selected-groups-table [displayColumns]="resultColumns"></app-selected-groups-table>
  </div>
</div>
