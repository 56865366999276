<div class="settings">
  <div class="card privacy">
    <div class="card-header">
      <div class="title">Личный кабинет</div>
    </div>
    <div class="card-block">
      <ng-container *ngIf="AccountLoading; else loadingScreen">
        <div class="account">
          <div class="account__info">
            Ваш email: {{Account?.email}}
          </div>
        </div>
      </ng-container>
    </div>
    <ng-container>
      <div class="card-header">
        <div class="title">
          Ваш тарифный план
        </div>
      </div>
      <ng-container *ngFor="let tariffGroup of tariffGroups">
        <div class="card-block">
          <strong>{{tariffGroup['paymentGroupName']}}</strong>

<!--          {{tariff$(tariffGroup.id) | async | json}}-->
          <app-tariff-plan [tariffPlan]="(tariff$(tariffGroup.id) | async)?.tariffPackage" [expiredAt]="(tariff$(tariffGroup.id) | async)?.expiredAt"></app-tariff-plan>
        </div>
      </ng-container>
    </ng-container>
<!--    <div class="card-block" *ngFor="let te of tariffPlanExpirations">-->
<!--      <div class="account__tariff-plans-expirations">-->
<!--&lt;!&ndash;        <app-tariff-plan-expiration [tariffPlanExpiration]="te"></app-tariff-plan-expiration>&ndash;&gt;-->
<!--      </div>-->
<!--    </div>-->
    <div class="card-header">
      <app-affiliate-program-link [isTitle]="true">
      </app-affiliate-program-link>
    </div>
    <div class="card-footer">
      <div class="vk-container">
        <button class="btn btn-primary btn-block" (click)="ToggleChangePasswordForm()">
          Сменить пароль
        </button>
      </div>
      <div class="vk-container">
        <button class="btn btn-danger btn-block" (click)="RemoveAccountInitiate()">
          Удалить профиль
        </button>
      </div>
    </div>
  </div>
  <div class="card accounts">
    <div class="card-header">
      <div class="title">Профили</div>
    </div>
    <div class="card-block">
      <ng-container *ngIf="CabinetsLoading; else loadingScreen">
        <div class="fx-row">
          <app-personal-cabinet
                  *ngFor="let Cabinet of Cabinets"
                  [cabinet]="Cabinet"
                  [Link]="Cabinet.link"
                  [IsLoggedIn]="IsLoggedIn(Cabinet.id)"
          ></app-personal-cabinet>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<clr-modal [(clrModalOpen)]="openedChangePasswordForm">
  <div class="modal-title">Изменение пароля</div>
  <div class="modal-body">
    <app-auth-change-password></app-auth-change-password>
  </div>
</clr-modal>

<app-remove-account-modal [isVisible]="showRemoveAccountDataModal"
                          (onModalClose)="showRemoveAccountDataModal = false"
></app-remove-account-modal>

<ng-template #loadingScreen>
  <div class="loading-screen">
    <div class="spinner"></div>
  </div>
</ng-template>

