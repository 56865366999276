/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./update-ad.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../components/ad-form/ad-form.component.ngfactory";
import * as i3 from "../../components/ad-form/ad-form.component";
import * as i4 from "../../../../api/services/ad-manager.service";
import * as i5 from "../../services/groups-selector/groups-selector.service";
import * as i6 from "../../../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i7 from "@angular/material/progress-spinner";
import * as i8 from "@angular/cdk/platform";
import * as i9 from "@angular/common";
import * as i10 from "@angular/platform-browser/animations";
import * as i11 from "./update-ad.component";
import * as i12 from "@angular/router";
import * as i13 from "../../../../api/services/automation-vk.service";
import * as i14 from "../../../../api/services/user-strategies.service";
import * as i15 from "../../../../shared/services/dexie/dexie-cabinets.service";
import * as i16 from "../../../../api/services/automation-vk-slot.service";
import * as i17 from "../../../services/automation-expiration-manager.service";
import * as i18 from "../../../../shared/services/dexie/dexie-clients.service";
var styles_UpdateAdComponent = [i0.styles];
var RenderType_UpdateAdComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UpdateAdComponent, data: {} });
export { RenderType_UpdateAdComponent as RenderType_UpdateAdComponent };
function View_UpdateAdComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-ad-form", [], null, [[null, "adSaved"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("adSaved" === en)) {
        var pd_0 = (_co.OnSaveAd($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_AdFormComponent_0, i2.RenderType_AdFormComponent)), i1.ɵdid(2, 114688, null, 0, i3.AdFormComponent, [i1.ChangeDetectorRef, i4.AdManagerService, i5.GroupsSelectorService], { isUpdating: [0, "isUpdating"], ads: [1, "ads"], loading: [2, "loading"], targetingSettings: [3, "targetingSettings"], accountId: [4, "accountId"], clientId: [5, "clientId"], strategy: [6, "strategy"], image: [7, "image"], slots: [8, "slots"], accounts: [9, "accounts"] }, { adSaved: "adSaved" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = true; var currVal_1 = _co.ads; var currVal_2 = _co.loading; var currVal_3 = _co.targetingSettings; var currVal_4 = _co.accountId; var currVal_5 = _co.clientId; var currVal_6 = _co.strategy; var currVal_7 = _co.image; var currVal_8 = _co.slots; var currVal_9 = _co.accounts; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }, null); }
function View_UpdateAdComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "update-loading-screen"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "mat-spinner", [["class", "mat-spinner mat-progress-spinner"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i6.View_MatSpinner_0, i6.RenderType_MatSpinner)), i1.ɵdid(2, 49152, null, 0, i7.MatSpinner, [i1.ElementRef, i8.Platform, [2, i9.DOCUMENT], [2, i10.ANIMATION_MODULE_TYPE], i7.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], null, null), (_l()(), i1.ɵted(-1, null, [" \u041F\u043E\u0434\u043E\u0436\u0434\u0438\u0442\u0435 \u0438\u0434\u0435\u0442 \u0437\u0430\u0433\u0440\u0443\u0437\u043A\u0430 \u0434\u0430\u043D\u043D\u044B\u0445 "]))], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2)._noopAnimations; var currVal_1 = i1.ɵnov(_v, 2).diameter; var currVal_2 = i1.ɵnov(_v, 2).diameter; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_UpdateAdComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_UpdateAdComponent_1)), i1.ɵdid(1, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["loadingScreen", 2]], null, 0, null, View_UpdateAdComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loaded; var currVal_1 = i1.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_UpdateAdComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-update-ad", [], null, null, null, View_UpdateAdComponent_0, RenderType_UpdateAdComponent)), i1.ɵdid(1, 114688, null, 0, i11.UpdateAdComponent, [i12.ActivatedRoute, i13.AutomationVkService, i4.AdManagerService, i14.UserStrategiesService, i12.Router, i5.GroupsSelectorService, i13.AutomationVkService, i15.DexieCabinetsService, i16.AutomationVkSlotService, i17.AutomationExpirationManagerService, i18.DexieClientsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UpdateAdComponentNgFactory = i1.ɵccf("app-update-ad", i11.UpdateAdComponent, View_UpdateAdComponent_Host_0, {}, {}, []);
export { UpdateAdComponentNgFactory as UpdateAdComponentNgFactory };
