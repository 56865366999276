import {Component, OnInit} from '@angular/core';
import {AdSourceViewModel} from '../../../models/ad-source.view-model';
import {AdManagerService, UserStrategiesService} from '../../../../../api/services';
import {delay, flatMap} from 'rxjs/internal/operators';
import {Router} from '@angular/router';
import {GroupsSelectorService} from '../../../services/groups-selector/groups-selector.service';
import {MatConfirmDialogComponent} from '../../../components/modals/configrm-dialog/mat-confirm-dialog.component';
import {MatDialog} from '@angular/material';

@Component({
  selector: 'app-create-ad-lite',
  templateUrl: './create-ad-lite.component.html',
  styleUrls: ['./create-ad-lite.component.scss']
})
export class CreateAdLiteComponent implements OnInit {

  public adId: number = 0;
  private accountId = 0;
  private campaignId: any;
  public isLoading = false;
  // public visibleExpirationNotification: boolean = false;

  constructor(
    private adManagerService: AdManagerService,
    private userStrategiesService: UserStrategiesService,
    private router: Router,
    private groupsSelectorService: GroupsSelectorService,
    public dialog: MatDialog
  ) {
  }

  ngOnInit() {
    this.groupsSelectorService.ClearSelectedGroups();
    this.OpenInstructionDialog();
  }

  SaveAd($event: any) {
    this.isLoading = true;
    this.CreateAd($event)
      .pipe(
        delay(1000),
        flatMap(() => this.CreateStrategy(this.accountId, this.adId))
      )
      .subscribe(data => {
        this.NavigateToAdsPage(this.accountId, this.campaignId, this.adId);
      }, error => {
        this.isLoading = false;
        let message = ''
        if (error.error) {
          message = error.error.description;
        } else {
          message = 'Не удалось создать объявление. Попробуйте повторить это действие позже или обратитесь в поддержку.';
        }
        alert(message);
        throw error;
      });
  }

  private CreateAd(ad) {
    return this.adManagerService
      .CreateAd([ad])
      .do((response) => {
        console.log(response);
        this.accountId = ad.accountId;
        this.adId = response[0].id;
        this.campaignId = ad.adSpecifications[0].campaign_id;
      })
      .delay(1000);
  }

  private CreateStrategy(accountId, adId) {
    console.log({accountId, adId});
    return this.userStrategiesService.CreateUserStrategy(this.getUserStrategy(accountId, adId));
  }

  private getUserStrategy(accountId, ad) {
    return {
      accountId,
      ads: [ad],
      clientId: 0,
      properties: {
        ECPC_LIMIT: 1000
      },
      strategyType: 'INTELEGENT_MARK_I_MOD_I',
      updateStep: 10
    };
  }

  private NavigateToAdsPage(accountId: any, campaignId: number, adId) {
    this.router.navigate(['/automation/dashboard'], {
      queryParams: {
        sendModeration: false,
        startAfterModeration: false,
        account_id: parseInt(accountId, 10),
        client_id: null,
        campaign_id: campaignId,
        adId
      }
    });
  }

  private OpenInstructionDialog() {
    const HasStatus = localStorage.getItem('was_showed_instruction_lite');
    if (!HasStatus) {
      setTimeout(() => {
        this.dialog.open(MatConfirmDialogComponent, {
          data: {
            title: 'Ознакомьтесь с инструкцией',
            description: `Перед созданием объявления, рекомендуем ознакомиться с инструкцией.
      Также Вы сможете ознакомиться с инструкцией позже, нажав на кнопку “Инструкция по созданию объявлений”,
      которая находится в правом верхнем углу на странице создания объявления`,
            confirmActionText: 'Ознакомится сейчас',
            rejectActionText: 'Ознакомится позже'
          },
          width: '650px'
        })
          .afterClosed()
          .subscribe(status => {
            localStorage.setItem('was_showed_instruction_lite', '1');
            if (status) {
              const win = window.open('https://postmonitor.ru/blog/instrukciya-po-sozdaniyu-obyavleniya-v-vkontakte-cherez-servis-postmonitor-dlya-novichkov', '_blank');
            }
          })
        ;
      }, 1500);
    }
  }
}
