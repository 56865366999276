import {Component, OnInit} from '@angular/core';
import {NotificationsService} from '../../services/notifications.service';
import {AppNotification} from '../../models/Notification';

@Component({
    selector: 'app-notification-list',
    templateUrl: './notification-list.component.html',
    styleUrls: ['./notification-list.component.scss']
})
export class NotificationListComponent implements OnInit {

    constructor(
        public notificationsService: NotificationsService
    ) {
    }

    ngOnInit() {
    }

    get notifications() {
        return Array.from(this.notificationsService.notifications)
            .map(x => x[1]);
    }

}
