import {ID} from '@datorama/akita';
import {PostMonitorBackend_V2ModelsMoySkladApiMoySkladOrganization} from '../../../api/models/post-monitor-backend-_v2models-moy-sklad-api-moy-sklad-organization';

export interface CrmOrganisation {
  id: ID;
  name: string;
}

/**
 * A factory function that creates CrmOrganisation
 */
export function createCrmOrganisation(params: Partial<PostMonitorBackend_V2ModelsMoySkladApiMoySkladOrganization>) {
  return {
    id: params.id,
    name: params.name
  } as CrmOrganisation;
}
