import {Component, OnInit} from '@angular/core';
import {PaymentsService} from '../../services/payments.service';
import {PostMonitorBackend_V2ModelsPaymentsTimePeriod} from '../../../../../../src/app/api/models/post-monitor-backend-_v2models-payments-time-period';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-payments-tariff-time-periods-selector',
  templateUrl: './payments-tariff-time-periods-selector.component.html',
  styleUrls: ['./payments-tariff-time-periods-selector.component.scss']
})
export class PaymentsTariffTimePeriodsSelectorComponent implements OnInit {

  public isSelected(timePeriodOd) {
    return this.paymentService.isSelected(timePeriodOd);
  }

  public get timePeriods$(): Observable<PostMonitorBackend_V2ModelsPaymentsTimePeriod[]> {
    return this.paymentService.timePeriods$.asObservable();
  }

  public get currentSelectedTimePeriodName$(): Observable<string> {
    return this.paymentService.selectedTimePeriod$;
  }

  constructor(
    private paymentService: PaymentsService
  ) {
  }

  ngOnInit() {
  }

  SelectTimePeriod(timePeriodId: number) {
    this.paymentService.selectTimePeriod(timePeriodId);
  }
}
