<ng-container *ngIf="!loading; else loadingScreen">
  <form [formGroup]="form" class="form">
    <h2 class="form__title">Подключение amoCRM</h2>
    <vk-form-field orientation="vertical" controlsWidth="100%">
      <vk-label>
        Домен вашей учетной записи в CRM
        <app-suggestion-info code="AMO_CRM_DOMAIN"></app-suggestion-info>
      </vk-label>
      <input type="text" formControlName="domain" vkInput placeholder="Введите домен">
      <vk-error validator="required">Вы не ввели домен amoCRM</vk-error>
    </vk-form-field>

<!--    <vk-form-field orientation="vertical" controlsWidth="100%">-->
<!--      <vk-label>-->
<!--        E-mail вашей учетной записи в CRM-->
<!--        <app-suggestion-info code="AMO_CRM_EMAIL"></app-suggestion-info>-->
<!--      </vk-label>-->
<!--      <input type="text" formControlName="email" vkInput placeholder="Введите e-mail">-->
<!--      <vk-error validator="required">Вы не email</vk-error>-->
<!--      <vk-error validator="email">Вы ввели не правильный email</vk-error>-->
<!--    </vk-form-field>-->

<!--    <vk-form-field orientation="vertical" controlsWidth="100%">-->
<!--      <vk-label>-->
<!--        Ключ API amoCRM-->
<!--        <app-suggestion-info code="AMO_CRM_API_KEY"></app-suggestion-info>-->
<!--      </vk-label>-->
<!--      <input type="text" formControlName="apiKey" vkInput placeholder="Введите ключ">-->
<!--      <vk-error validator="required">Вы не ввели ключ API</vk-error>-->
<!--    </vk-form-field>-->

    <button class="vk-standart-button vk-standart-button-strip" (click)="Assign()" [disabled]="form.invalid">
      Подключить amoCRM
    </button>
  </form>
</ng-container>

<ng-template #loadingScreen>
  <app-loading-screen>
    Подождите, идет подключение amoCRM
  </app-loading-screen>
</ng-template>
