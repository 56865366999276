import { AfterViewInit, EventEmitter, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { isNullOrUndefined } from 'util';
var DynamicFormComponent = /** @class */ (function () {
    function DynamicFormComponent() {
        this.errorMessages = {};
        this.Successed = new EventEmitter();
        this.Canceled = new EventEmitter();
        this.Inited = new EventEmitter();
        this.form = new FormGroup({});
    }
    Object.defineProperty(DynamicFormComponent.prototype, "InputFields", {
        get: function () {
            var _this = this;
            if (this.inputs) {
                return Object
                    .keys(this.inputs)
                    .map(function (x) { return _this.inputs[x]; });
            }
            else {
                return [];
            }
        },
        enumerable: true,
        configurable: true
    });
    DynamicFormComponent.prototype.ngOnInit = function () {
        // if (this.inputs) {
        //   const formInputs = {};
        //   Object.keys(this.inputs)
        //     .forEach(x => {
        //       const input = this.inputs[x];
        //       formInputs[x] = new FormControl(input.value || '');
        //     });
        //   this.adForm = new FormGroup(formInputs);
        // }
    };
    DynamicFormComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        var valueChanges = this.form.valueChanges;
        var formInputs = {};
        if (this.inputs) {
            Object.keys(this.inputs)
                .forEach(function (x) {
                var input = _this.inputs[x];
                switch (input.type) {
                    case 'checkbox-list':
                        formInputs[x] = new FormArray(input.options.map(function () {
                            return new FormControl(false);
                        }));
                        break;
                    default:
                        console.log(input, input.Validators);
                        formInputs[x] = new FormControl(input.value || '', (input.Validators) ? input.Validators.map(function (val) { return val.validator; }) : []);
                        break;
                }
                _this.errorMessages[input.name] = input.Validators.reduce(function (store, _a) {
                    var code = _a.code, message = _a.message;
                    console.log(code, message, 'validator messages');
                    store[code] = message;
                    return store;
                }, {});
            });
            this.form = new FormGroup(formInputs);
            this.form.valueChanges.subscribe(function (data) {
                _this.SuccessAction();
            });
            this.Inited.emit(this.form);
        }
    };
    DynamicFormComponent.prototype.SuccessAction = function () {
        var emittedValue = {};
        if (this.form.valid) {
            var _loop_1 = function (key) {
                var input = this_1.inputs[key];
                var value = this_1.form.value[key];
                if (input.type === 'checkbox-list') {
                    value = Object.keys(value)
                        .filter(function (x) { return value[x]; })
                        .map(function (x) { return Object.keys(x)[0]; })
                        .map(function (x) { return input.options[x]; });
                }
                if (input.type === 'number') {
                    console.log(value);
                    if (typeof value === 'string') {
                        value = parseFloat(value.replace(',', '.'));
                    }
                    console.log(value);
                }
                if (input.modifyFunction) {
                    emittedValue[key] = input.modifyFunction(value);
                }
                else {
                    emittedValue[key] = value;
                }
            };
            var this_1 = this;
            for (var _i = 0, _a = Object.keys(this.form.value); _i < _a.length; _i++) {
                var key = _a[_i];
                _loop_1(key);
            }
            if (!_.isEmpty(emittedValue)) {
                this.Successed.emit(emittedValue);
            }
        }
    };
    DynamicFormComponent.prototype.CancelAction = function () {
        this.Canceled.emit();
    };
    DynamicFormComponent.prototype.IsOptionSelected = function (optionId, inputName) {
        return parseInt(optionId, 10) === parseInt(this.form.value[inputName], 10);
    };
    DynamicFormComponent.prototype.GetSelectOptionNumber = function (optionId) {
        return parseInt(optionId, 10);
    };
    DynamicFormComponent.prototype.ValidateInput = function (value, inputName) {
        var val = this.form.value;
        console.log(val[inputName], val[inputName].match(/[^\d\.,]/gm));
        val[inputName] = val[inputName].replace(/[^\d\.,]/gm, '');
        console.log(val);
        this.form.setValue(val);
    };
    DynamicFormComponent.prototype.CheckedCheckbox = function (input, checkbox) {
        var a = null;
        if (!isNullOrUndefined(input.value)) {
            a = input.value.find(function (x) { return x === checkbox; });
        }
        return a !== null;
    };
    DynamicFormComponent.prototype.GetErrors = function (inputName) {
        var _this = this;
        var input = this.form.controls[inputName];
        if (input.dirty) {
            var errors = input.errors;
            if (errors) {
                var errorKeys = Object.keys(errors);
                console.log(errorKeys, 'errorKeys');
                if (errorKeys) {
                    var errorMessages = errorKeys.map(function (err) {
                        console.log(_this.errorMessages[inputName], err);
                        return _this.errorMessages[inputName][err.toUpperCase()];
                    });
                    console.log(errorMessages);
                    return errorMessages;
                }
                return [];
            }
            return [];
        }
        return [];
    };
    DynamicFormComponent.prototype.ngOnDestroy = function () {
        // this.InputFields = [];
        this.form = null;
    };
    DynamicFormComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        var numberInputs = Array.from(document.querySelectorAll('input[type=number]'));
        numberInputs.forEach(function (input) {
            if (input.addEventListener) {
                if ('onwheel' in document) {
                    // IE9+, FF17+, Ch31+
                    input.addEventListener('wheel', _this.onWheel);
                }
                else if ('onmousewheel' in document) {
                    // устаревший вариант события
                    input.addEventListener('mousewheel', _this.onWheel);
                }
                else {
                    // Firefox < 17
                    input.addEventListener('MozMousePixelScroll', _this.onWheel);
                }
            }
        });
    };
    DynamicFormComponent.prototype.onWheel = function (e) {
        e = e || window.event;
        e.preventDefault ? e.preventDefault() : (e.returnValue = false);
    };
    return DynamicFormComponent;
}());
export { DynamicFormComponent };
