<form [formGroup]="form" (submit)="Submit($event)">
    <ng-container *ngIf="!loading; else loadingScreen">
        <ng-container *ngIf="!message; else messagesScreen">
            <ng-container *ngIf="errors?.system">
                <div class="alert alert-danger">
                    <div class="alert-items">
                        <div class="alert-item static">
                            <div class="alert-icon-wrapper">
                                <clr-icon class="alert-icon" shape="check-circle"></clr-icon>
                            </div>
                            <span class="alert-text" *ngFor="let error of errors.system">
                                {{error.description}}
                            </span>
                        </div>
                    </div>
                </div>
            </ng-container>
            <div class="login-group">
                <ng-container *ngIf="errors?.password">
                    <div class="error active" *ngFor="let error of errors.password">
                        {{error}}
                    </div>
                </ng-container>
                <input class="username" type="password" id="password" placeholder="Введите пароль"
                       formControlName="password">

                <ng-container *ngIf="errors?.passwordRepeat">
                    <div class="error active" *ngFor="let error of errors.passwordRepeat">
                        {{error}}
                    </div>
                </ng-container>
                <input class="username" type="password" id="password-confirm" placeholder="Повторите пароль"
                       formControlName="passwordRepeat">
                <button type="submit" class="btn btn-primary btn-block">СМЕНИТЬ ПАРОЛЬ</button>
            </div>
        </ng-container>
        <ng-template #messagesScreen>
            <ng-container *ngIf="message">
                <div class="alert alert-success">
                    <div class="alert-items">
                        <div class="alert-item static">
                            <div class="alert-icon-wrapper">
                                <clr-icon class="alert-icon" shape="check-circle"></clr-icon>
                            </div>
                            <span class="alert-text">
                                Ваш пароль успешно изменен!
                            </span>
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-template>
    </ng-container>
    <ng-template #loadingScreen>
        <div class="loading-screen">
            <div class="spinner"></div>
        </div>
    </ng-template>
</form>