var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { QueryEntity } from '@datorama/akita';
import { AdNetworkFormsStore } from './ad-network-forms.store';
import { AkitaNgFormsManager } from '@datorama/akita-ng-forms-manager';
import { flatMap, map } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { unionAllArraysFromObjectsToArray } from '../../../utils/js/arrays';
import { PlatformsQuery } from "../platforms/platforms.query";
import { PlatformsTypes } from "../../platforms-types";
import * as moment from 'moment';
import * as i0 from "@angular/core";
import * as i1 from "./ad-network-forms.store";
import * as i2 from "@datorama/akita-ng-forms-manager";
import * as i3 from "../platforms/platforms.query";
/**
 *
 * @param data
 * @constructor
 */
function GetMtForms(data) {
    var selected = Object.keys(data)
        .map(function (formId) { return ({ formId: formId, isSelected: data[formId] }); })
        .filter(function (_a) {
        var isSelected = _a.isSelected;
        return isSelected;
    })
        .map(function (_a) {
        var formId = _a.formId;
        return parseInt(formId, 10);
    });
    return selected;
}
var AdNetworkFormsQuery = /** @class */ (function (_super) {
    __extends(AdNetworkFormsQuery, _super);
    function AdNetworkFormsQuery(store, fm, platformsQuery) {
        var _this = _super.call(this, store) || this;
        _this.store = store;
        _this.fm = fm;
        _this.platformsQuery = platformsQuery;
        _this.forms$ = _this.selectAll();
        _this.selectedForms = combineLatest(_this.fm.selectValue('originPlatformSettings'), _this.platformsQuery.selectedOriginPlatformCode$)
            .pipe(map(function (_a) {
            var data = _a[0], code = _a[1];
            return code === PlatformsTypes.MT_LEAD_FORMS ? GetMtForms(data) : unionAllArraysFromObjectsToArray(data);
        }), 
        // map(data => data.map(x => parseInt(x, 10))),
        flatMap(function (formIds) { return _this.selectAll({
            filterBy: function (entity) {
                return formIds.map(function (x) { return x.toString(); }).includes(entity.id.toString());
            }
        }); }));
        _this.groupForms$ = function (groupId) { return _this.selectAll({ filterBy: function (entity) { return entity.group_id === groupId; } }); };
        _this.isEmpty$ = function (groupId) { return combineLatest(_this.groupForms$(groupId), _this.selectLoading())
            .pipe(map(function (_a) {
            var groups = _a[0], loading = _a[1];
            return groups.length === 0;
        })); };
        _this.isOpened$ = function (formId) { return _this.select(function (state) {
            return state.ui.opened === formId;
        }); };
        return _this;
    }
    /**
     * Получить id последнего лида
     * @param formId - id формы
     * @param groupId - id группы
     * @constructor
     */
    AdNetworkFormsQuery.prototype.GetLastLeadIdByGroupAndFormIds = function (formId, groupId) {
        var group = '';
        if (groupId) {
            group = groupId + "_" + formId;
        }
        else {
            group = "" + formId;
        }
        var groupId1 = group.replace('_undefined', '');
        var entity = this.getEntity(group.replace('_undefined', ''));
        return entity ? entity.lastLeadId : null;
    };
    /**
     * Получить id последнего лида
     * @param formId - id формы
     * @param groupId - id группы
     * @constructor
     */
    AdNetworkFormsQuery.prototype.GetLastCreateDateByGroupAndFormIds = function (formId, groupId) {
        var group = '';
        if (groupId) {
            group = groupId + "_" + formId;
        }
        else {
            group = "" + formId;
        }
        var groupId1 = group.replace('_undefined', '');
        var entity = this.getEntity(group.replace('_undefined', ''));
        return entity ? moment.utc(entity.lastLeadCreateDate).format('YYYY-MM-DD[T]HH:mm:ss') : null;
    };
    AdNetworkFormsQuery.prototype.ContainsFormsWithGroupId = function (group) {
        return this.getCount(function (x) { return x.group_id === group; }) > 0;
    };
    AdNetworkFormsQuery.prototype.GetForm = function (id) {
        return this.getAll().find(function (x) { return x.id === id; });
    };
    AdNetworkFormsQuery.ngInjectableDef = i0.defineInjectable({ factory: function AdNetworkFormsQuery_Factory() { return new AdNetworkFormsQuery(i0.inject(i1.AdNetworkFormsStore), i0.inject(i2.AkitaNgFormsManager), i0.inject(i3.PlatformsQuery)); }, token: AdNetworkFormsQuery, providedIn: "root" });
    return AdNetworkFormsQuery;
}(QueryEntity));
export { AdNetworkFormsQuery };
