import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import {UserIntegration} from "../../../api/models";

export interface UserIntegrationState extends EntityState<UserIntegration> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'user-integration' })
export class UserIntegrationStore extends EntityStore<UserIntegrationState> {

  constructor() {
    super();
  }

}

