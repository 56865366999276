import { OnInit } from '@angular/core';
import { AdManagerService } from '../../../../../api/services/ad-manager.service';
import { UserStrategiesService } from '../../../../../api/services/user-strategies.service';
import { AdFormManagerService } from '../../../new-ad-manager-shared/services/ad-form-manager/ad-form-manager.service';
var NewUpdateAdProComponent = /** @class */ (function () {
    function NewUpdateAdProComponent(adFormManagerService, adManagerService, userStrategiesService) {
        this.adFormManagerService = adFormManagerService;
        this.adManagerService = adManagerService;
        this.userStrategiesService = userStrategiesService;
        console.log('Is Update', this.adFormManagerService.IsUpdate);
        this.adFormManagerService.IsUpdate = true;
        console.log('Is Update', this.adFormManagerService.IsUpdate);
    }
    Object.defineProperty(NewUpdateAdProComponent.prototype, "IsLoading", {
        get: function () {
            return this.adFormManagerService.adDataLoading;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewUpdateAdProComponent.prototype, "LoadingProgress", {
        get: function () {
            return this.adFormManagerService.loadingProgress;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewUpdateAdProComponent.prototype, "LoadingStep", {
        get: function () {
            return this.adFormManagerService.LoadingStepMessage;
        },
        enumerable: true,
        configurable: true
    });
    NewUpdateAdProComponent.prototype.ngOnInit = function () {
        console.log('Is Update', this.adFormManagerService.IsUpdate);
        this.adFormManagerService.IsUpdate = true;
        console.log('Is Update', this.adFormManagerService.IsUpdate);
        this.adFormManagerService.reset();
        this.adFormManagerService.IsUpdate = true;
        this.adFormManagerService.LoadData()
            .subscribe(function (data) {
            console.log(data, 'PRO');
        });
    };
    NewUpdateAdProComponent.prototype.UpdateAds = function ($event) {
        var _this = this;
        this.adFormManagerService.UpdateAd()
            .subscribe(function (data) {
        }, function (err) {
            _this.adFormManagerService.loadingCreateAd = false;
            alert('Во время изменения объявления произошла ошибка');
        });
    };
    return NewUpdateAdProComponent;
}());
export { NewUpdateAdProComponent };
