export enum NotificationType {
    SUCCESS = 'alert-success',
    ERROR = 'alert-danger',
    INFO = 'alert-info',
    WARNING = 'warning'
}

export class INotification {
    title: string;
    description: string;
    type: NotificationType;
}

export class AppNotification implements INotification {
    description: string;
    title: string;
    type: NotificationType;


    constructor(title: string, type?: NotificationType, description?: string) {
        this.description = description;
        this.title = title;
        this.type = type;
    }
}
