import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {isNullOrUndefined} from 'util';
import {AdFormManagerService} from '../../../new-ad-manager-shared/services/ad-form-manager/ad-form-manager.service';

@Component({
  selector: 'app-ad-form-pro',
  templateUrl: './ad-form-pro.component.html',
  styleUrls: ['./ad-form-pro.component.scss']
})
export class AdFormProComponent implements OnInit {

  @Output() public onSave: EventEmitter<any> = new EventEmitter<any>();

  public get slots() {
    return this.adFormManagerService.slots;
  }

  public get accounts() {
    return this.adFormManagerService.accounts
      .filter(account => account.account_role === 'admin');
  }

  public get IsCampaignsValid(): any {
    return this.adFormManagerService.IsValidCampaignData();
  }

  public get IsPromopost(): any {
    return this.adFormManagerService.IsSelectFormatPromopost;
  }

  public get IsUpdate() {
    return this.adFormManagerService.IsUpdate;
  }

  public get IsAdLayoutDirty() {
    return this.adFormManagerService.IsAdLayoutDirty();
  }

  public get IsValidAd() {
    return this.adFormManagerService.IsValidaAd();
  }

  public get IsValidTargeting() {
    return this.adFormManagerService.IsValidTargeting();
  }

  public get ShowActiveAccounts() {
    return this.adFormManagerService.ShowActiveAccounts();
  }

  public get errors() {
    return this.adFormManagerService.getErrors();
  }

  public get createAdLoading() {
    return this.adFormManagerService.loadingCreateAd;
  }

  public get HasAssignedAccounts() {
    return this.adFormManagerService.HasAssignedAccounts();
  }

  constructor(
    private adFormManagerService: AdFormManagerService
  ) {
  }

  ngOnInit() {
    this.adFormManagerService.EnableProMode();
  }

  public CreateAd() {
    this.onSave.emit(this.adFormManagerService.getSpecifications());
  }

  public Bindend() {
    this.adFormManagerService.LoadAdCabinetData()
      .subscribe(() => {
        console.log('acounts loaded');
      });
  }
}
