/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./popover-menu.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./popover-menu.component";
import * as i3 from "@angular/cdk/overlay";
var styles_PopoverMenuComponent = [i0.styles];
var RenderType_PopoverMenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PopoverMenuComponent, data: {} });
export { RenderType_PopoverMenuComponent as RenderType_PopoverMenuComponent };
export function View_PopoverMenuComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { popoverElement: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["popover", 1]], null, 1, "div", [["class", "app-popover-menu"]], null, [[null, "clickOutside"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clickOutside" === en)) {
        var pd_0 = (_co.ClickOutside() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵncd(null, 0)], null, null); }
export function View_PopoverMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 3, "app-popover-menu", [], null, null, null, View_PopoverMenuComponent_0, RenderType_PopoverMenuComponent)), i1.ɵdid(1, 114688, null, 2, i2.PopoverMenuComponent, [i3.Overlay, i1.ViewContainerRef], null, null), i1.ɵqud(335544320, 1, { menuTrigger: 0 }), i1.ɵqud(335544320, 2, { menuContent: 0 })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PopoverMenuComponentNgFactory = i1.ɵccf("app-popover-menu", i2.PopoverMenuComponent, View_PopoverMenuComponent_Host_0, {}, {}, ["*"]);
export { PopoverMenuComponentNgFactory as PopoverMenuComponentNgFactory };
