import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AccountsService} from '../../../api/services/accounts.service';
import {TokenCheckerService} from '../../../shared/services/token-checker.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {UtilsService} from '../../../shared/services/utils.service';
import {isNullOrUndefined} from "util";

@Component({
  selector: 'app-oauth-bind-email',
  templateUrl: './oauth-bind-email.component.html',
  styleUrls: ['./oauth-bind-email.component.scss']
})
export class OauthBindEmailComponent implements OnInit {

  public email = '';
  public code = '';
  public errors;
  public isLoading = false;

  public form: FormGroup;

  constructor(private route: ActivatedRoute,
              private api: AccountsService,
              private token: TokenCheckerService,
              private router: Router) {
  }

  ngOnInit() {
    this.form = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      phone: new FormControl('', [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(24)
      ]),
      name: new FormControl('', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(64)
      ])
    });
    this.Load();
  }

  public ToggleLoading() {
    this.isLoading = !this.isLoading;
  }

  public Load() {
    this.code = this.route.snapshot.queryParams.code;

    // TODO Ensure that we should show email bind adForm (maybe we authorized & want to bind provider login
    this.api.BindExternalToken(this.code).subscribe(data => {
      // We binded authorizing provider!!!!

      // Skip email binding
      this.router.navigate(['/dashboard']);
    }, error => {
      // TODO Most like we failed because of non authorized - show email adForm
    });
  }

  public Submit($event) {
    $event.preventDefault();
    this.ToggleLoading();
    this.errors = [];
    if (this.form.valid) {
      this.api.ExternalConfirmToken({
        TicketCode: this.code,
        email: this.form.value.email,
        phone: this.form.value.phone,
        name: this.form.value.name,
        affiliateMemberCode: this.getCookie('affiliate_member_code')
      })
        .subscribe(x => {
          try {

            this.token.SaveToken(x['token'], x['expiredAt']);
            this.ToggleLoading();

            let source = this.route.snapshot.queryParams.source;

            if (isNullOrUndefined(source)) {
              source = 'unknown';
            }

            this.router.navigate(['/sign-up-completed'], {queryParams: {source: source}});
            //this.router.navigate(['/dashboard']);
          } catch (e) {
            throw e;
          }
        }, err => {
          this.errors.system = [];
          this.errors.system.push(err.error.description);
          this.ToggleLoading();
        });
    } else {
      this.errors = UtilsService.GetErrorsByForm('bind-email', this.form.controls);
      this.ToggleLoading();
    }
  }

  private getCookie(name: string) {
    var pair = document.cookie.match(new RegExp(name + '=([^;]+)'));
    return !!pair ? pair[1] : null;
  }

}
