var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { EasyAdContainerModel } from '../models/easy-ad-container-model';
import { AutomationVkService } from '../../api/services/automation-vk.service';
import { AutomationEasyAdsService } from '../../api/services/automation-easy-ads.service';
import { UserStrategiesService } from "../../api/services/user-strategies.service";
import * as i0 from "@angular/core";
import * as i1 from "../../api/services/automation-vk.service";
import * as i2 from "../../api/services/automation-easy-ads.service";
import * as i3 from "../../api/services/user-strategies.service";
var EasyAdsDataService = /** @class */ (function () {
    function EasyAdsDataService(automationApi, easyAdsApi, userStrategyApi) {
        this.automationApi = automationApi;
        this.easyAdsApi = easyAdsApi;
        this.userStrategyApi = userStrategyApi;
        this.adsRerievingError = null;
        this.budgetRerievingError = null;
        this.adsList = null;
        this.filtredAdsList = null;
        this.filterShowActive = true;
        this.filterShowInactive = false;
        this.filterShowModerating = false;
        this.filterShowModerationFailed = false;
        this.filerMode = '';
        this.filterOptions = [
            {
                name: 'Все',
                mode: 'all',
                count: 0,
                active: true,
                inactive: true,
                moderating: true,
                moderation_failed: true
            },
            {
                name: 'Активные',
                mode: 'active',
                count: 0,
                active: true,
                inactive: false,
                moderating: false,
                moderation_failed: false
            },
            {
                name: 'Неактивные',
                mode: 'inactive',
                count: 0,
                active: false,
                inactive: true,
                moderating: false,
                moderation_failed: false
            },
            {
                name: 'На модерации',
                mode: 'moderating',
                count: 0,
                active: false,
                inactive: false,
                moderating: true,
                moderation_failed: false
            },
            {
                name: 'Не прошедшие модерацию',
                mode: 'moderation_failed',
                count: 0,
                active: false,
                inactive: false,
                moderating: false,
                moderation_failed: true
            }
        ];
        this.budgetIsLoading = true;
        this.budget = 0;
        this.accessExpired = false;
        this.filerMode = this.filterOptions[0].mode;
    }
    EasyAdsDataService.prototype.AdsListNotEmpty = function () {
        if (this.adsList) {
            return this.adsList.length > 0;
        }
        return false;
    };
    EasyAdsDataService.prototype.AdStatusChange = function (easyAd) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                console.log('AdStatusChange', easyAd);
                return [2 /*return*/];
            });
        });
    };
    EasyAdsDataService.prototype.AdSendToModeration = function (easyAd) {
        return __awaiter(this, void 0, void 0, function () {
            var newStatus;
            return __generator(this, function (_a) {
                console.log('AdSendToModeration', easyAd);
                newStatus = (easyAd.ad.status) === 1 ? 0 : 1;
                console.log("Setting status=" + newStatus + " for ad " + easyAd.accountId + "_" + easyAd.clientId + "_" + easyAd.adId + ". Previous status=" + easyAd.ad.status);
                return [2 /*return*/];
            });
        });
    };
    EasyAdsDataService.prototype.LoadAccounts = function () {
        return __awaiter(this, void 0, void 0, function () {
            var accountRequest;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        accountRequest = this.automationApi.GetAccounts().toPromise();
                        accountRequest.catch(function (err) {
                            // TODO Добавить обработку этой ошибки
                            return null;
                        });
                        return [4 /*yield*/, accountRequest];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    EasyAdsDataService.prototype.LoadData = function (accountId, clientId) {
        if (clientId === void 0) { clientId = null; }
        return __awaiter(this, void 0, void 0, function () {
            var exception_1, exception_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        // Секция загрузки данных для объявлений
                        this.adsList = null;
                        this.filtredAdsList = null;
                        this.adsRerievingError = null;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.LoadAdsAndRelatedData(accountId, clientId)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        exception_1 = _a.sent();
                        console.warn("Error during LoadAdsAndRelatedData(" + accountId + ")," + clientId, exception_1);
                        if (exception_1 && exception_1.error && exception_1.error.errorCode === 'AUTOMATION_VK_CABINET_ACCESS_EXPIRED') {
                            this.accessExpired = true;
                            this.adsRerievingError = 'Доступ к функционалу истек. Для продолжения работы с функционалом оплатите доступ на странице оплаты.';
                        }
                        else {
                            this.adsRerievingError = 'Ошибка во время загрузки объявлений - попробуйте обновить страницу. При повторном возникновении ошибки, пожалуйста, обратитесь в поддержку.';
                        }
                        return [3 /*break*/, 4];
                    case 4:
                        _a.trys.push([4, 6, , 7]);
                        return [4 /*yield*/, this.LoadBudgetViaApi(accountId, clientId)];
                    case 5:
                        _a.sent();
                        return [3 /*break*/, 7];
                    case 6:
                        exception_2 = _a.sent();
                        console.warn("Error during LoadBudgetViaApi(" + accountId + ")," + clientId, exception_2);
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    EasyAdsDataService.prototype.LoadAdsAndRelatedData = function (accountId, clientId) {
        return __awaiter(this, void 0, void 0, function () {
            var createdAds, adIds, exception_3, strategies, exception_4;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.LoadAdsCreatedViaService(accountId, clientId)];
                    case 1:
                        createdAds = _a.sent();
                        adIds = createdAds.map(function (x) { return x.adId; });
                        // Filter off bad ids
                        if (adIds && adIds.length > 0) {
                            adIds = adIds.filter(function (x) { return x > 0; });
                        }
                        console.log('LoadAds', accountId, clientId, adIds);
                        if (!(adIds && adIds.length > 0)) return [3 /*break*/, 11];
                        // Загружаем объекты объявлений из ВКонтакте
                        return [4 /*yield*/, this.LoadAds(accountId, clientId, adIds)];
                    case 2:
                        // Загружаем объекты объявлений из ВКонтакте
                        _a.sent();
                        // Назначим к полученным объявлениям даты их создания в сервисе
                        this.adsList.forEach(function (easyAd) {
                            var relatedCreatedAd = createdAds.find(function (x) { return x.adId === easyAd.ad.id; });
                            if (relatedCreatedAd) {
                                easyAd.createdAt = relatedCreatedAd.createdAt;
                            }
                        });
                        // Сортируем по дате
                        this.adsList.sort(function (a, b) {
                            return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
                        });
                        // Объявления загружены, фильтруем новые данные, согласно заданному фильтру
                        // После выполнения этого шага объявления уже будут видны на странице
                        this.AssignNewFilter(this.filerMode);
                        _a.label = 3;
                    case 3:
                        _a.trys.push([3, 5, , 6]);
                        // Теперь необходимо подгрузить визуальные данные
                        return [4 /*yield*/, this.LoadAdVisuals(accountId, clientId, adIds)];
                    case 4:
                        // Теперь необходимо подгрузить визуальные данные
                        _a.sent();
                        return [3 /*break*/, 6];
                    case 5:
                        exception_3 = _a.sent();
                        console.warn("Error during LoadAdVisuals()", exception_3);
                        return [3 /*break*/, 6];
                    case 6:
                        _a.trys.push([6, 8, , 9]);
                        console.log('Trying to retrieve strategies', accountId, clientId, adIds);
                        return [4 /*yield*/, this.LoadAdsStrategies(accountId, clientId, adIds)];
                    case 7:
                        strategies = _a.sent();
                        console.log('Recieved strategies', strategies);
                        if (strategies && strategies.length > 0) {
                            strategies.forEach(function (strategy) {
                                if (strategy) {
                                    var relatedAd = _this.adsList.find(function (x) {
                                        return x.accountId === strategy.accountId &&
                                            x.clientId === ((strategy.clientId === 0) ? null : strategy.clientId) &&
                                            x.adId === strategy.adId;
                                    });
                                    if (relatedAd) {
                                        relatedAd.userStrategy = strategy;
                                    }
                                }
                            });
                        }
                        return [3 /*break*/, 9];
                    case 8:
                        exception_4 = _a.sent();
                        console.warn('Error during strategy retrieving', exception_4);
                        return [3 /*break*/, 9];
                    case 9:
                        this.adsList.forEach(function (x) {
                            x.strategyRecieved = true;
                        });
                        console.log('LoadAdsStatistics', accountId, clientId, adIds);
                        // Загружаем статиску ообъявлений
                        return [4 /*yield*/, this.LoadAdsStatistics(accountId, clientId, adIds)];
                    case 10:
                        // Загружаем статиску ообъявлений
                        _a.sent();
                        return [3 /*break*/, 12];
                    case 11:
                        this.adsList = [];
                        this.AssignNewFilter(this.filerMode);
                        _a.label = 12;
                    case 12: return [2 /*return*/];
                }
            });
        });
    };
    EasyAdsDataService.prototype.LoadAdsCreatedViaService = function (accountId, clientId) {
        return __awaiter(this, void 0, void 0, function () {
            var request, response, result;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        request = this.easyAdsApi.GetAdsCreatedViaService({
                            accountId: accountId,
                            clientId: clientId,
                            offset: 0,
                            limit: 1000
                        }).toPromise();
                        request.catch(function (err) {
                            console.warn('Error during GetAdsCreatedViaService', err);
                            _this.adsList = [];
                            _this.AssignNewFilter(_this.filerMode);
                        });
                        return [4 /*yield*/, request];
                    case 1:
                        response = _a.sent();
                        result = [];
                        if (response && response.data) {
                            result = response.data;
                        }
                        else {
                            // Если не создано ничего, то дальнейший процесс не имеет смысла
                            this.adsList = [];
                            this.AssignNewFilter(this.filerMode);
                        }
                        return [2 /*return*/, result];
                }
            });
        });
    };
    EasyAdsDataService.prototype.LoadAdsStrategies = function (accountId, clientId, adIds) {
        return __awaiter(this, void 0, void 0, function () {
            var request, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        request = this.userStrategyApi.GetStrategiesByAdAndAccountIds({
                            accountId: accountId,
                            clientId: clientId,
                            viewModel: {
                                adIds: adIds
                            }
                        }).toPromise();
                        request.catch(function (err) {
                        });
                        return [4 /*yield*/, request];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    // TODO Протестировать макс длину запроса на сервере и написать ограничитель на клиенте
    EasyAdsDataService.prototype.LoadAds = function (accountId, clientId, adIds) {
        return __awaiter(this, void 0, void 0, function () {
            var adsResponse;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.Timeout()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.automationApi.GetAds({
                                accountId: accountId,
                                clientId: clientId,
                                campaignIds: null,
                                adIds: adIds,
                                limit: 1000,
                                offset: 0,
                                includeDeleted: false
                            }).toPromise()];
                    case 2:
                        adsResponse = _a.sent();
                        this.adsList = [];
                        if (adsResponse && adsResponse.data) {
                            adsResponse.data.forEach(function (ad) {
                                var model = new EasyAdContainerModel();
                                model.accountId = accountId;
                                model.clientId = clientId;
                                model.campaignId = ad.campaign_id;
                                model.adId = ad.id;
                                model.ad = ad;
                                _this.adsList.push(model);
                            });
                        }
                        this.RecalculateFilterCount();
                        return [2 /*return*/];
                }
            });
        });
    };
    EasyAdsDataService.prototype.LoadAdVisuals = function (accountId, clientId, adIds) {
        return __awaiter(this, void 0, void 0, function () {
            var ads, teasers_1, promo, adsLayouts;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        ads = this.adsList
                            .filter(function (x) { return x.ad; })
                            .map(function (x) { return x.ad; });
                        if (!(ads && ads.length > 0)) return [3 /*break*/, 4];
                        teasers_1 = ads.filter(function (x) { return x.ad_format === 1 || x.ad_format === 2; });
                        promo = ads.filter(function (x) { return x.ad_format === 9; });
                        if (!(teasers_1.length > 0 || promo.length > 0)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.LoadAdLayouts(accountId, clientId, adIds)];
                    case 1:
                        adsLayouts = _a.sent();
                        if (!(adsLayouts && adsLayouts.length > 0)) return [3 /*break*/, 3];
                        adsLayouts.forEach(function (layout) {
                            var related = _this.adsList.find(function (x) { return x.ad.id === layout.id; });
                            if (related) {
                                related.adLayout = layout;
                                // Если это тизер - то сообщаем ему, что его графика подготовлена
                                if (teasers_1.some(function (x) { return x.id === layout.id; })) {
                                    related.graphicsLoaded = true;
                                }
                            }
                        });
                        // Загружаем описание постов для объявлений-промопостов
                        return [4 /*yield*/, this.LoadAdVisualForPromoposts(promo, adsLayouts)];
                    case 2:
                        // Загружаем описание постов для объявлений-промопостов
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        // Для всех: убедиться в том, что процесс загрузки графики окончен
                        this.adsList.forEach(function (easyAd) {
                            easyAd.graphicsLoaded = true;
                        });
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    EasyAdsDataService.prototype.LoadAdLayouts = function (accountId, clientId, adIds) {
        return __awaiter(this, void 0, void 0, function () {
            var adsLayoutResponse;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(adIds && adIds.length > 0)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.Timeout()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.automationApi.GetAdsLayout({
                                accountId: accountId,
                                clientId: clientId,
                                campaignIds: null,
                                adIds: adIds,
                                limit: 1000,
                                offset: 0,
                                includeDeleted: false
                            }).toPromise()];
                    case 2:
                        adsLayoutResponse = _a.sent();
                        if (adsLayoutResponse && adsLayoutResponse.data) {
                            return [2 /*return*/, adsLayoutResponse.data];
                        }
                        _a.label = 3;
                    case 3: return [2 /*return*/, null];
                }
            });
        });
    };
    EasyAdsDataService.prototype.LoadAdVisualForPromoposts = function (ads, adsLayouts) {
        return __awaiter(this, void 0, void 0, function () {
            var wallPosts_1, index, step, posts, ids, wallResponse, exception_5;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(ads && ads.length > 0 && adsLayouts && adsLayouts.length > 0)) return [3 /*break*/, 8];
                        wallPosts_1 = [];
                        // Собираем ссылки на посты из всех объявлений-промопостов
                        ads.forEach(function (ad) {
                            var relatedLayout = adsLayouts.find(function (layout) { return layout.id === ad.id; });
                            if (relatedLayout && relatedLayout.link_url) {
                                // Вычленияем id группы и id поста, заносим в массив
                                try {
                                    var linkSplitted = relatedLayout.link_url.split('wall');
                                    var postData = linkSplitted[1];
                                    var postDataSplitted = postData.split('_');
                                    var groupId = parseInt(postDataSplitted[0], 10);
                                    var postId = parseInt(postDataSplitted[1], 10);
                                    if (linkSplitted && linkSplitted.length > 1) {
                                        wallPosts_1.push({
                                            adId: ad.id,
                                            groupId: groupId,
                                            postId: postId,
                                            post: linkSplitted[1]
                                        });
                                    }
                                }
                                catch (exception) {
                                }
                            }
                        });
                        console.log('Data gathered', wallPosts_1);
                        if (!(wallPosts_1.length > 0)) return [3 /*break*/, 8];
                        index = 0;
                        step = 50;
                        // обходим ранее собранный массив и получаем информацию
                        console.log('Iterating gathered posts data...');
                        _a.label = 1;
                    case 1:
                        if (!(index < wallPosts_1.length)) return [3 /*break*/, 8];
                        console.log("Retrieving from " + index + " to " + (index + step) + " of " + wallPosts_1.length);
                        return [4 /*yield*/, this.Timeout()];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        _a.trys.push([3, 6, , 7]);
                        posts = wallPosts_1.slice(index, step);
                        if (!(posts && posts.length > 0)) return [3 /*break*/, 5];
                        ids = posts.map(function (x) { return x.post; }).join(',');
                        console.log('ids', ids);
                        if (!(ids && ids.length > 0)) return [3 /*break*/, 5];
                        return [4 /*yield*/, this.automationApi.GetWallById(ids).toPromise()];
                    case 4:
                        wallResponse = _a.sent();
                        console.log('response', wallResponse);
                        if (wallResponse && wallResponse.data && wallResponse.data.length > 0) {
                            wallResponse.data.forEach(function (post) {
                                var relatedWallPost = wallPosts_1.find(function (wallPost) { return wallPost.groupId === post.owner_id && wallPost.postId === post.id; });
                                if (relatedWallPost) {
                                    var relatedAd = _this.adsList.find(function (x) { return x.ad.id === relatedWallPost.adId; });
                                    if (relatedAd) {
                                        relatedAd.post = post;
                                    }
                                }
                            });
                        }
                        _a.label = 5;
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        exception_5 = _a.sent();
                        console.warn('Error during wallPosts iterating', exception_5);
                        return [3 /*break*/, 7];
                    case 7:
                        index += step;
                        return [3 /*break*/, 1];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    EasyAdsDataService.prototype.LoadAdsStatistics = function (accountId, clientId, adIds) {
        return __awaiter(this, void 0, void 0, function () {
            var adIdsParameter, statsResponse;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: 
                    // Загружаем статистику
                    return [4 /*yield*/, this.Timeout()];
                    case 1:
                        // Загружаем статистику
                        _a.sent();
                        adIdsParameter = adIds.join(',');
                        return [4 /*yield*/, this.automationApi.GetStatistics({
                                accountId: accountId,
                                clientId: clientId,
                                idsType: 'ad',
                                ids: adIdsParameter,
                                period: 'overall',
                                dateFrom: '0',
                                dateTo: '0'
                            }).toPromise()];
                    case 2:
                        statsResponse = _a.sent();
                        if (statsResponse && statsResponse.data) {
                            console.log('statsResponse.data', statsResponse.data);
                            statsResponse.data.forEach(function (stat) {
                                var relatedAd = _this.adsList.find(function (x) { return x.ad.id === stat.id; });
                                if (relatedAd && stat.stats && stat.stats.length > 0) {
                                    relatedAd.statistics = stat.stats[0];
                                }
                            });
                        }
                        else {
                            console.error("LoadAdsStatistics(" + accountId + ", " + clientId + ", adIdsParameter) has no response; adIdsParameter=" + adIdsParameter);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    EasyAdsDataService.prototype.LoadBudgetViaApi = function (accountId, clientId) {
        return __awaiter(this, void 0, void 0, function () {
            var budgetRequest, budgetResponse;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.Timeout()];
                    case 1:
                        _a.sent();
                        this.budgetRerievingError = null;
                        this.budgetIsLoading = true;
                        this.budget = null;
                        budgetRequest = this.automationApi.GetBudget({
                            accountId: accountId,
                            clientId: clientId
                        }).toPromise();
                        // Обработка ошибки запроса к бюджету
                        budgetRequest.catch(function (err) {
                            console.warn('Budget request failure', err);
                            _this.budgetIsLoading = false;
                            if (err && err.error && err.error.description) {
                                _this.budgetRerievingError = err.error.description;
                            }
                        });
                        return [4 /*yield*/, budgetRequest];
                    case 2:
                        budgetResponse = _a.sent();
                        this.budgetIsLoading = false;
                        this.budgetRerievingError = null;
                        if (budgetResponse && budgetResponse.data !== null && budgetResponse.data !== undefined) {
                            this.budget = budgetResponse.data;
                        }
                        else {
                            this.budgetRerievingError = 'Не удалось загрузить данные';
                            console.warn("budgetResponse \u0438\u043B\u0438 budgetResponse.data \u043D\u0435 \u0431\u044B\u043B\u0438 \u0437\u0430\u0434\u0430\u043D\u044B", budgetResponse);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    EasyAdsDataService.prototype.AssignNewFilter = function (mode) {
        this.filterShowActive = false;
        this.filterShowInactive = false;
        this.filterShowModerating = false;
        this.filterShowModerationFailed = false;
        this.filerMode = mode;
        switch (this.filerMode) {
            case 'all':
                this.filterShowInactive = true;
                this.filterShowActive = true;
                this.filterShowModerating = true;
                this.filterShowModerationFailed = true;
                break;
            case 'active':
                this.filterShowActive = true;
                break;
            case 'inactive':
                this.filterShowInactive = true;
                break;
            case 'moderating':
                this.filterShowModerating = true;
                break;
            case 'moderation_failed':
                this.filterShowModerationFailed = true;
                break;
        }
        this.FilterAds(this.filterShowActive, this.filterShowInactive, this.filterShowModerating, this.filterShowModerationFailed);
    };
    EasyAdsDataService.prototype.RecalculateFilterCount = function () {
        var _this = this;
        this.filterOptions.forEach(function (option) {
            var count = 0;
            if (_this.adsList && _this.adsList.length > 0) {
                var filteredResults = _this.GetAds(_this.adsList, option.active, option.inactive, option.moderating, option.moderation_failed);
                count = filteredResults.length;
            }
            option.count = count;
        });
    };
    EasyAdsDataService.prototype.FilterAds = function (showActive, showInactive, showModerating, showModerationFailed) {
        this.filtredAdsList = this.GetAds(this.adsList, showActive, showInactive, showModerating, showModerationFailed);
    };
    EasyAdsDataService.prototype.GetAds = function (adsListToFilter, showActive, showInactive, showModerating, showModerationFailed) {
        if (showActive === void 0) { showActive = true; }
        if (showInactive === void 0) { showInactive = false; }
        if (showModerating === void 0) { showModerating = false; }
        if (showModerationFailed === void 0) { showModerationFailed = false; }
        if (!adsListToFilter || adsListToFilter.length === 0) {
            console.log('All is bad', !adsListToFilter, adsListToFilter.length === 0);
            return [];
        }
        return adsListToFilter.filter(function (easyAd) { return easyAd.ad && ((showActive && easyAd.ad.status === 1) ||
            (showInactive && easyAd.ad.status === 0 && (easyAd.ad.approved === 0 || easyAd.ad.approved === 2)) ||
            (showModerating && easyAd.ad.approved === 1) ||
            (showModerationFailed && easyAd.ad.approved === 3)); });
    };
    EasyAdsDataService.prototype.Timeout = function (ms) {
        if (ms === void 0) { ms = 500; }
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) { return setTimeout(resolve, ms); })];
            });
        });
    };
    EasyAdsDataService.ngInjectableDef = i0.defineInjectable({ factory: function EasyAdsDataService_Factory() { return new EasyAdsDataService(i0.inject(i1.AutomationVkService), i0.inject(i2.AutomationEasyAdsService), i0.inject(i3.UserStrategiesService)); }, token: EasyAdsDataService, providedIn: "root" });
    return EasyAdsDataService;
}());
export { EasyAdsDataService };
