import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import * as moment from 'moment';
import {environment} from "../../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class AffiliateStatusService {
    public lastLoadingDate = null;
    public isPartner = false;

    private _baseUrl = `${environment.backendUri}/${environment.apiPrefix}/affiliate-status`

    constructor(
        private http: HttpClient
    ) {
    }

    public LoadAffiliateMemberStatus() {
        if (this.lastLoadingDate === null || this.IsLastLoadingDateAfter(new Date(), 5)) {
            this
                .http
                .get(this._baseUrl)
                .subscribe(affiliateStatus => {
                    this.isPartner = affiliateStatus ? affiliateStatus['isActivated'] : false;
                    this.lastLoadingDate = new Date();
                })
        }
    }
    
    public IsLastLoadingDateAfter(date: Date, minutes: number) {
        const date1 = moment(date).diff(moment(this.lastLoadingDate), 'minutes');
        console.log(date1, 'IS LAST LOADING DATE AFTER');
        return date1  > minutes;
    }
}
