import {Component, OnInit} from '@angular/core';
import {CabinetsService} from '../../../api/services/cabinets.service';
import {AuthCabinet} from '../../../api/models/auth-cabinet';
import {environment} from '../../../../environments/environment';

@Component({
    selector: 'app-personal-cabinets',
    templateUrl: './personal-cabinets.component.html',
    styleUrls: ['./personal-cabinets.component.scss']
})
export class PersonalCabinetsComponent implements OnInit {


    public Cabinets: Array<AuthCabinet>;
    public LoggedInCabinets: Array<AuthCabinet>;

    public baseLink = '';

    constructor(
        private cabinetsService: CabinetsService
    ) {
    }

    ngOnInit() {
        this.LoadCabinets();
        this.LoadUserCabinets();
        this.baseLink = environment.backendUri || '';
    }

    public LoadCabinets() {
        this.cabinetsService.GetCabinets()
            .subscribe(x => {
                this.Cabinets = x;
            });
    }

    public LoadUserCabinets() {
        this.cabinetsService.GetUserCabinets()
            .subscribe(x => {
                this.LoggedInCabinets = x;
            });
    }

    public IsLoggedIn(cabinetId) {
        return (this.LoggedInCabinets) ? this.LoggedInCabinets.map(x => x.id).includes(cabinetId) : 0;
    }

}
