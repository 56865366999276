var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Query } from '@datorama/akita';
import { PromocodeStore } from './promocode.store';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./promocode.store";
var PromocodeQuery = /** @class */ (function (_super) {
    __extends(PromocodeQuery, _super);
    function PromocodeQuery(store) {
        var _this = _super.call(this, store) || this;
        _this.store = store;
        _this.messages$ = _this.select(function (state) { return state.messages; });
        _this.promocode$ = _this.select(function (state) { return state.promocode; });
        _this.promocodeText$ = _this.promocode$
            .pipe(map(function (x) { return x ? x.code : null; }));
        return _this;
    }
    Object.defineProperty(PromocodeQuery.prototype, "promocode", {
        get: function () {
            var promocode = this.getValue().promocode;
            return promocode;
        },
        enumerable: true,
        configurable: true
    });
    ;
    PromocodeQuery.ngInjectableDef = i0.defineInjectable({ factory: function PromocodeQuery_Factory() { return new PromocodeQuery(i0.inject(i1.PromocodeStore)); }, token: PromocodeQuery, providedIn: "root" });
    return PromocodeQuery;
}(Query));
export { PromocodeQuery };
