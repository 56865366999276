import {Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {isNullOrUndefined} from 'util';
import {DexieCampaignsService} from '../../services/dexie/dexie-campaigns.service';
import {DexieCabinetsService} from '../../services/dexie/dexie-cabinets.service';
import {ActivatedRoute} from '@angular/router';
import {DexieClientsService} from '../../services/dexie/dexie-clients.service';
import {LoggingService} from '../../services/logging.service';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {
  public breadcrumbs: Array<any>;
  public queryParams;

  constructor(
    private campaignsService: DexieCampaignsService,
    private cabinetsService: DexieCabinetsService,
    private clientsService: DexieClientsService,
    private route: ActivatedRoute,
    private logger: LoggingService
  ) {
  }

  ngOnInit() {
    this.route.queryParams.subscribe(x => {
      const accountId = parseInt(x.account_id, 10);
      const clientId = parseInt(x.client_id, 10);
      const campaignId = parseInt(x.campaign_id, 10);
      this.InitBreadCrumbs(accountId, clientId, campaignId);
    });
  }

  async InitBreadCrumbs(accountId: number, clientId: number, campaignId: number) {
    this.breadcrumbs = [
      {
        link: '/automation/accounts',
        title: 'Рекламные кабинеты'
      }
    ];
    if (accountId) {
      await this.addCabinet(accountId, clientId);
    }
    if (campaignId) {
      await this.addCampaign(accountId, clientId, campaignId);
    }
  }

  public async addCabinet(accountId: number, clientId: number) {
    let account = null;
    let client = null;
    account = await this.cabinetsService.getCabinetById(accountId);
    if (clientId) {
      client = await this.clientsService.GetClientById(clientId);

      if (client === null || client === undefined) {
        this.logger.Error('Can\'t add client to bread crumbs');
      }
    }
    console.log(accountId, clientId);
    
    if (client === null) {
      console.log('Adding standard cabinet to breadCrumbs');
      this.breadcrumbs.push({
        link: '/automation/campaigns',
        title: account.account_name,
        queryParams: {
          account_id: accountId,
          client_id: clientId
        }
      });
    } else {
      console.log('Adding agency cabinet to breadCrumbs');
      this.breadcrumbs.push({
        link: '/automation/campaigns',
        title: client.name,
        queryParams: {
          account_id: accountId,
          client_id: clientId
        }
      });
    }
  }

  public async addCampaign(accountId: number, clientId: number, campaignId: number) {
    console.log(campaignId);
    const campaign = await this.campaignsService.findCampaignById(campaignId);
    if (campaign) {
      this.breadcrumbs.push({
        link: `/automation/ads`,
        title: campaign.name,
        queryParams: {
          account_id: accountId,
          campaign_id: campaignId,
          client_id: clientId
        }
      });
    }
  }

}
