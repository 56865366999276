<div class="fx-row fx-row_start" *ngIf="promopost">
  <div class="main-bar main-bar_big">
    <app-side-form-wrapper>
      <!--<div class="vk-container">-->
        <!--<div class="vk-strip-wrapper vk-strip-wrapper_ad-cabinet-style">-->
          <!--<div class="vk-label">-->
            <!--Выберите тип групировки:-->
          <!--</div>-->
          <!--<div class="vk-strip-wrapper__item vk-strip-wrapper__item_ad-cabinet-style">-->
            <!--<mat-radio-group [formControl]="controlContainer.control.get('type')">-->
            <!--<div class="vk-radio-container">-->
              <!--<mat-radio-button value="linear" color="primary">Линейный</mat-radio-button>-->
            <!--</div>-->

            <!--<div class="vk-radio-container">-->
              <!--<mat-radio-button value="quadratic" color="primary">Квадратичный (m x n)</mat-radio-button>-->
            <!--</div>-->
          <!--</mat-radio-group>-->
          <!--</div>-->
        <!--</div>-->
      <!--</div>-->
      <app-create-promopost-form
        [(promopost)]="promopost"
        *ngIf="promopost"
        (onGroupSelect)="onGroupSelect($event)"
        [formGroup]="controlContainer.control"
        [isUpdating]="isUpdating"
        [categories]="categories"
        #form

        (ControlAdded)="OnControlAdd()"
        (ControlRemoved)="OnControlRemove($event)"
      ></app-create-promopost-form>
    </app-side-form-wrapper>
  </div>
  <div class="sidebar">
    <cdk-virtual-scroll-viewport [itemSize]="200" style="height: 440px; width: 650px">
      <app-promopost
        [promopost]="promo.promopost"
        [text]="promo.text"
        [image]="promo.image"
        [selectedGroup]="selectedGroup"
        *cdkVirtualFor="let promo of Posts"
      ></app-promopost>
    </cdk-virtual-scroll-viewport>
  </div>
</div>
<app-images-manager (selected)="ImageSelected($event)" [minWidth]="500" [minHeight]="240"></app-images-manager>
