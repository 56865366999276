import { PlatformsTypes } from '../../../platforms-types';
import { AkitaNgFormsManager } from '@datorama/akita-ng-forms-manager';
import { isNullOrUndefined } from 'util';
import { PlatformsQuery } from '../../../stores/platforms/platforms.query';
import { CustomFieldsQuery } from "../../../stores/custom-fields/custom-fields.query";
import { FormMessagesService } from "../../../services/form-messages/form-messages.service";
import { AdNetworkFormsQuery } from "../../../stores/ad-network-forms/ad-network-forms.query";
import * as i0 from "@angular/core";
import * as i1 from "@datorama/akita-ng-forms-manager";
import * as i2 from "../../../stores/platforms/platforms.query";
import * as i3 from "../../../stores/custom-fields/custom-fields.query";
import * as i4 from "../../../services/form-messages/form-messages.service";
import * as i5 from "../../../stores/ad-network-forms/ad-network-forms.query";
var CrmImportConfiguratorService = /** @class */ (function () {
    function CrmImportConfiguratorService(fm, platformsQuery, customFieldsQuery, formMessagesService, formsQuery) {
        this.fm = fm;
        this.platformsQuery = platformsQuery;
        this.customFieldsQuery = customFieldsQuery;
        this.formMessagesService = formMessagesService;
        this.formsQuery = formsQuery;
    }
    CrmImportConfiguratorService.prototype.configure = function (type) {
        var form = this.fm.getForm('destinationPlatformSettings');
        if (form) {
            var settings = form.value;
            var sections = [];
            var result = {};
            for (var _i = 0, _a = Object.keys(settings); _i < _a.length; _i++) {
                var groupFormId = _a[_i];
                var formSettings = settings[groupFormId];
                if (formSettings) {
                    for (var _b = 0, _c = Object.keys(formSettings); _b < _c.length; _b++) {
                        var sectionKey = _c[_b];
                        if (formSettings[sectionKey] && formSettings[sectionKey].selected) {
                            var sectionResult = {
                                code: sectionKey,
                                data: {
                                    id: this.GetGroupId(groupFormId),
                                    fields: this.GetSectionResultFields(formSettings, sectionKey),
                                },
                            };
                            if (sectionKey === 'LEADS') {
                                sectionResult.data['additional_data'] = [];
                                if (formSettings.includeCampaignName) {
                                    sectionResult.data['additional_data'].push({
                                        name: 'SourceCampaignName',
                                        include: formSettings.includeCampaignName
                                    });
                                }
                                if (formSettings.includeLeadFormName) {
                                    sectionResult.data['additional_data'].push({
                                        name: 'SourceLeadFormName',
                                        include: formSettings.includeLeadFormName
                                    });
                                }
                                sectionResult.data['additional_data'].push({
                                    name: 'ShouldBindContact',
                                    include: formSettings.bindContact
                                });
                            }
                            sectionResult['customFieldsSettings'] = formSettings[sectionKey].customFields;
                            sections.push(sectionResult);
                        }
                    }
                }
            }
            return this.unionTasks(sections);
        }
        else {
            return [{}];
        }
    };
    CrmImportConfiguratorService.prototype.GetSectionResultFields = function (formSettings, sectionKey) {
        var customFields = formSettings[sectionKey].customFields || {};
        var fields = Object.assign(formSettings[sectionKey].fields, customFields);
        var fieldsKeys = Object.keys(fields);
        var result = {};
        for (var _i = 0, fieldsKeys_1 = fieldsKeys; _i < fieldsKeys_1.length; _i++) {
            var fieldsKey = fieldsKeys_1[_i];
            if (formSettings[fieldsKey] !== null) {
                result[fieldsKey] = fields[fieldsKey];
            }
        }
        return result;
    };
    CrmImportConfiguratorService.prototype.GetGroupId = function (groupId) {
        return (this.platformsQuery.selectedOriginPlatformCode === PlatformsTypes.FB_LEAD_FORMS) ? "ad_" + groupId : groupId;
    };
    CrmImportConfiguratorService.prototype.unionTasks = function (tasks) {
        var _this = this;
        var result = [];
        var taskCodes = [];
        for (var _i = 0, tasks_1 = tasks; _i < tasks_1.length; _i++) {
            var task = tasks_1[_i];
            if (!taskCodes.includes(task.code)) {
                taskCodes.push(task.code);
            }
        }
        taskCodes.forEach(function (taskCode) {
            var taskData = tasks.filter(function (x) { return x.code === taskCode; }).map(function (x) { return x.data; });
            var sectionSettings = {
                code: taskCode,
                data: taskData
            };
            if (taskCode === 'DEALS') {
                sectionSettings['customFieldsSettings'] = _this.customFieldsQuery.CustomFields.map(function (x) { return x.Key; });
            }
            result.push(sectionSettings);
        });
        return result;
    };
    CrmImportConfiguratorService.prototype.valid = function (type) {
        var form = this.fm.getForm('destinationPlatformSettings');
        if (form) {
            var destinationSettings = form.value;
            var data = [];
            var destinationSettingsKeys = Object.keys(destinationSettings);
            console.log(destinationSettings, 'DESTINATION SETTINGS');
            var status_1 = false;
            for (var _i = 0, destinationSettingsKeys_1 = destinationSettingsKeys; _i < destinationSettingsKeys_1.length; _i++) {
                var formId = destinationSettingsKeys_1[_i];
                var formSectionSettings = this.GetFormSectionSettings(formId, destinationSettings[formId]);
                if (formSectionSettings.length > 0) {
                    data.push(formSectionSettings);
                }
            }
            // const data = Object.keys(destinationSettings)
            //   .map(formId => ({formId, formSettings: destinationSettings[formId]}))
            //   .filter(x => !isNullOrUndefined(x))
            //   .map(({formId, formSettings}) => this.GetFormSectionSettings(formId, formSettings))
            //   .filter(x => x.length > 0);
            if (data.length > 0) {
                return data.map(function (x) { return x.reduce(function (acc, curr) { return acc && curr; }, true); })
                    .reduce(function (acc, curr) { return acc && curr; }, true);
            }
            else {
                return false;
            }
        }
        return false;
    };
    CrmImportConfiguratorService.prototype.GetFormSectionSettings = function (formId, formSettings) {
        if (formSettings) {
            var isAllRequiredFieldsFilled = [];
            var formSettingsKeys = Object.keys(formSettings);
            for (var _i = 0, formSettingsKeys_1 = formSettingsKeys; _i < formSettingsKeys_1.length; _i++) {
                var sectionCode = formSettingsKeys_1[_i];
                var sectionSettings = formSettings[sectionCode];
                if (sectionSettings && sectionSettings.selected) {
                    isAllRequiredFieldsFilled.push(this.GetStatus(sectionSettings, formId, sectionCode));
                }
            }
            // const isAllRequiredFieldsFilled = Object.keys(formSettings)
            //   .map(sectionCode => ({sectionCode, sectionSettings: formSettings[sectionCode]}))
            //   .filter(({sectionCode, sectionSettings}) => sectionSettings && sectionSettings.selected)
            //   .map(({sectionCode, sectionSettings}) => {
            //     return this.GetStatus(sectionSettings, formId, sectionCode);
            //   });
            //
            // if (!isAllRequiredFieldsFilled) {
            //   // this.formMessagesService.AddErrorMessage(`В форме "<strong>${this.GetFormTitle(formId)}</strong>" обнаружена ошибка при заполнении данных! Проверьте правильность заполнения данных!`)
            // }
            //
            return isAllRequiredFieldsFilled;
        }
        else {
            return [];
        }
    };
    CrmImportConfiguratorService.prototype.GetStatus = function (sectionSettings, formId, sectionCode) {
        var IsFormSectionFilled = this.IsFormSectionField(sectionSettings.fields);
        var isFilledAllRequiredFieldsOfSection = this.IsFilledAllRequiredFieldsOfSection(formId, sectionCode, sectionSettings);
        var status = isFilledAllRequiredFieldsOfSection;
        if (!status) {
            this.formMessagesService.AddErrorMessage("\u0412 \u043D\u0430\u0441\u0442\u0440\u043E\u0439\u043A\u0430\u0445 \u0444\u043E\u0440\u043C\u044B \u043E\u0431\u044A\u044F\u0432\u043B\u0435\u043D\u0438\u044F \"<strong>" + this.GetFormTitle(formId) + "</strong>\" \u043D\u0435 \u0437\u0430\u043F\u043E\u043B\u043D\u0435\u043D\u044B \u0432\u0441\u0435 \u043E\u0431\u044F\u0437\u0430\u0442\u0435\u043B\u044C\u043D\u044B\u0435 \u043F\u043E\u043B\u044F, \u0437\u0430\u043F\u043E\u043B\u043D\u0438\u0442\u0435 \u0438\u0445 \u0438 \u043F\u043E\u043F\u0440\u043E\u0431\u0443\u0439\u0442\u0435 \u0435\u0449\u0435 \u0440\u0430\u0437 \u0441\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C \u0438\u043D\u0442\u0435\u0433\u0440\u0430\u0446\u0438\u044E.");
        }
        return status;
    };
    CrmImportConfiguratorService.prototype.IsFormSectionField = function (destinationFormSection) {
        var destinationFormSectionKeys = Object.keys(destinationFormSection);
        var sectionFields = false;
        for (var _i = 0, destinationFormSectionKeys_1 = destinationFormSectionKeys; _i < destinationFormSectionKeys_1.length; _i++) {
            var destinationFormSectionKey = destinationFormSectionKeys_1[_i];
            sectionFields = sectionFields && !isNullOrUndefined(destinationFormSection[destinationFormSectionKey]);
        }
        return sectionFields;
        // return destinationFormSectionKeys
        //   .map(key => ({key, value: destinationFormSection[key]}))
        //   .filter(x => !isNullOrUndefined(x.value))
        //   .length > 0;
    };
    /**
     * Проверка на заполенность всех обязательных полей
     * Алгоритм следующий:
     * 1. Переводим полученный объект в массив из ключей данного объекта
     * 2. Переводим массив ключей в массив объектов с полями key - ключ поля и value - значение поля
     * 3. Отфильтровывем все не обязательные поля
     * 4. Для отсавшихся полей проверяем заполнены ли они
     * 5. Проводим операцию логического умножения для всех полей, если хотя бы одно поле не заполенено
     *    возвращаем false
     *
     * @param formId - Id формы
     * @param sectionCode - код секции
     * @param sectionSettings - объект настроек
     * @constructor
     */
    CrmImportConfiguratorService.prototype.IsFilledAllRequiredFieldsOfSection = function (formId, sectionCode, sectionSettings) {
        var _this = this;
        var data = Object.keys(sectionSettings.fields)
            .map(function (key) { return ({ key: key, value: sectionSettings.fields[key] }); })
            .filter(function (fieldCode) { return _this.CheckIsFieldRequired(formId, sectionCode, fieldCode); })
            .map(function (fieldWrapper) {
            var isFiledValid = !(isNullOrUndefined(fieldWrapper.value) || fieldWrapper.value === '');
            // if (!isFiledValid) {
            //   this.formMessagesService.AddErrorMessage(`В форме "<strong>${this.GetFormTitle(formId)}</strong>" обнаружена ошибка при заполнении данных! Проверьте правильность заполнения данных!`)
            // }
            return isFiledValid;
        })
            .reduce(function (acc, curr) { return acc && curr; }, true);
        return data;
    };
    CrmImportConfiguratorService.prototype.CheckIsFieldRequired = function (formId, sectionCode, x) {
        return this.platformsQuery.requiredFields.includes(formId + "_" + sectionCode + "_" + x.key);
    };
    CrmImportConfiguratorService.prototype.GetFormTitle = function (formId) {
        return this.formsQuery.GetForm(formId).name;
    };
    CrmImportConfiguratorService.ngInjectableDef = i0.defineInjectable({ factory: function CrmImportConfiguratorService_Factory() { return new CrmImportConfiguratorService(i0.inject(i1.AkitaNgFormsManager), i0.inject(i2.PlatformsQuery), i0.inject(i3.CustomFieldsQuery), i0.inject(i4.FormMessagesService), i0.inject(i5.AdNetworkFormsQuery)); }, token: CrmImportConfiguratorService, providedIn: "root" });
    return CrmImportConfiguratorService;
}());
export { CrmImportConfiguratorService };
