/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { UserStrategy } from '../models/user-strategy';
import { PostMonitorBackend_V2Modelsbit_managementCreateStrategyForAdModel } from '../models/post-monitor-backend-_v2modelsbit-_management-create-strategy-for-ad-model';
import { PostMonitorBackend_V2Modelsbit_managementViewModelsUserStrategyEditSpecification } from '../models/post-monitor-backend-_v2modelsbit-_management-view-models-user-strategy-edit-specification';
import { PostMonitorBackend_V2Modelsbit_managementStrategiesByAccountsAndAdsViewModel } from '../models/post-monitor-backend-_v2modelsbit-_management-strategies-by-accounts-and-ads-view-model';
import { PostMonitorBackend_V2ModelsvkVKAPIUpdateAds } from '../models/post-monitor-backend-_v2modelsvk-vkapiupdate-ads';
import { PostMonitorBackend_V2Modelsbit_managementToggleAdsModel } from '../models/post-monitor-backend-_v2modelsbit-_management-toggle-ads-model';
import { PostMonitorBackend_V2Modelsbit_managementToggleStrategiesViewModels } from '../models/post-monitor-backend-_v2modelsbit-_management-toggle-strategies-view-models';
@Injectable({
  providedIn: 'root',
})
class UserStrategiesService extends __BaseService {
  static readonly GetAllUserStrategiesPath = '/apiv2/user-strategies';
  static readonly UpdateUserStrategiesPath = '/apiv2/user-strategies';
  static readonly CreateUserStrategyPath = '/apiv2/user-strategies';
  static readonly UpdateUserStrategyInputPath = '/apiv2/user-strategies/input';
  static readonly EditUserStrategyInputsPath = '/apiv2/user-strategies/edit';
  static readonly GetStrategiesByAdAndAccountIdsPath = '/apiv2/user-strategies/{accountId}';
  static readonly RemoveStrategyPath = '/apiv2/user-strategies/{accountId}';
  static readonly ToggleAdStatusPath = '/apiv2/user-strategies/toggle-ad-status';
  static readonly ToggleStrategiesPath = '/apiv2/user-strategies/toggle-strategies';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return Success
   */
  GetAllUserStrategiesResponse(): __Observable<__StrictHttpResponse<Array<UserStrategy>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/user-strategies`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<UserStrategy>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetAllUserStrategies(): __Observable<Array<UserStrategy>> {
    return this.GetAllUserStrategiesResponse().pipe(
      __map(_r => _r.body as Array<UserStrategy>)
    );
  }

  /**
   * @param strategy undefined
   * @return Success
   */
  UpdateUserStrategiesResponse(strategy?: PostMonitorBackend_V2Modelsbit_managementCreateStrategyForAdModel): __Observable<__StrictHttpResponse<UserStrategy>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = strategy;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/apiv2/user-strategies`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserStrategy>;
      })
    );
  }
  /**
   * @param strategy undefined
   * @return Success
   */
  UpdateUserStrategies(strategy?: PostMonitorBackend_V2Modelsbit_managementCreateStrategyForAdModel): __Observable<UserStrategy> {
    return this.UpdateUserStrategiesResponse(strategy).pipe(
      __map(_r => _r.body as UserStrategy)
    );
  }

  /**
   * @param strategy undefined
   * @return Success
   */
  CreateUserStrategyResponse(strategy?: PostMonitorBackend_V2Modelsbit_managementCreateStrategyForAdModel): __Observable<__StrictHttpResponse<UserStrategy>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = strategy;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/apiv2/user-strategies`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserStrategy>;
      })
    );
  }
  /**
   * @param strategy undefined
   * @return Success
   */
  CreateUserStrategy(strategy?: PostMonitorBackend_V2Modelsbit_managementCreateStrategyForAdModel): __Observable<UserStrategy> {
    return this.CreateUserStrategyResponse(strategy).pipe(
      __map(_r => _r.body as UserStrategy)
    );
  }

  /**
   * @param params The `UserStrategiesService.UpdateUserStrategyInputParams` containing the following parameters:
   *
   * - `value`:
   *
   * - `inputId`:
   *
   * - `adId`:
   *
   * - `accountId`:
   *
   * - `clientId`:
   *
   * @return Success
   */
  UpdateUserStrategyInputResponse(params: UserStrategiesService.UpdateUserStrategyInputParams): __Observable<__StrictHttpResponse<UserStrategy>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.value != null) __params = __params.set('value', params.value.toString());
    if (params.inputId != null) __params = __params.set('inputId', params.inputId.toString());
    if (params.adId != null) __params = __params.set('adId', params.adId.toString());
    if (params.accountId != null) __params = __params.set('accountId', params.accountId.toString());
    if (params.clientId != null) __params = __params.set('clientId', params.clientId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/apiv2/user-strategies/input`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserStrategy>;
      })
    );
  }
  /**
   * @param params The `UserStrategiesService.UpdateUserStrategyInputParams` containing the following parameters:
   *
   * - `value`:
   *
   * - `inputId`:
   *
   * - `adId`:
   *
   * - `accountId`:
   *
   * - `clientId`:
   *
   * @return Success
   */
  UpdateUserStrategyInput(params: UserStrategiesService.UpdateUserStrategyInputParams): __Observable<UserStrategy> {
    return this.UpdateUserStrategyInputResponse(params).pipe(
      __map(_r => _r.body as UserStrategy)
    );
  }

  /**
   * @param specification undefined
   * @return Success
   */
  EditUserStrategyInputsResponse(specification?: PostMonitorBackend_V2Modelsbit_managementViewModelsUserStrategyEditSpecification): __Observable<__StrictHttpResponse<Array<UserStrategy>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = specification;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/apiv2/user-strategies/edit`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<UserStrategy>>;
      })
    );
  }
  /**
   * @param specification undefined
   * @return Success
   */
  EditUserStrategyInputs(specification?: PostMonitorBackend_V2Modelsbit_managementViewModelsUserStrategyEditSpecification): __Observable<Array<UserStrategy>> {
    return this.EditUserStrategyInputsResponse(specification).pipe(
      __map(_r => _r.body as Array<UserStrategy>)
    );
  }

  /**
   * @param params The `UserStrategiesService.GetStrategiesByAdAndAccountIdsParams` containing the following parameters:
   *
   * - `clientId`:
   *
   * - `accountId`:
   *
   * - `viewModel`:
   *
   * @return Success
   */
  GetStrategiesByAdAndAccountIdsResponse(params: UserStrategiesService.GetStrategiesByAdAndAccountIdsParams): __Observable<__StrictHttpResponse<Array<UserStrategy>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.clientId != null) __params = __params.set('clientId', params.clientId.toString());

    __body = params.viewModel;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/apiv2/user-strategies/${params.accountId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<UserStrategy>>;
      })
    );
  }
  /**
   * @param params The `UserStrategiesService.GetStrategiesByAdAndAccountIdsParams` containing the following parameters:
   *
   * - `clientId`:
   *
   * - `accountId`:
   *
   * - `viewModel`:
   *
   * @return Success
   */
  GetStrategiesByAdAndAccountIds(params: UserStrategiesService.GetStrategiesByAdAndAccountIdsParams): __Observable<Array<UserStrategy>> {
    return this.GetStrategiesByAdAndAccountIdsResponse(params).pipe(
      __map(_r => _r.body as Array<UserStrategy>)
    );
  }

  /**
   * @param params The `UserStrategiesService.RemoveStrategyParams` containing the following parameters:
   *
   * - `adId`:
   *
   * - `accountId`:
   *
   * @return Success
   */
  RemoveStrategyResponse(params: UserStrategiesService.RemoveStrategyParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.adId != null) __params = __params.set('adId', params.adId.toString());

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/apiv2/user-strategies/${(params.accountId)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * @param params The `UserStrategiesService.RemoveStrategyParams` containing the following parameters:
   *
   * - `adId`:
   *
   * - `accountId`:
   *
   * @return Success
   */
  RemoveStrategy(params: UserStrategiesService.RemoveStrategyParams): __Observable<{}> {
    return this.RemoveStrategyResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * @param toggleAdsData undefined
   * @return Success
   */
  ToggleAdStatusResponse(toggleAdsData?: PostMonitorBackend_V2Modelsbit_managementToggleAdsModel): __Observable<__StrictHttpResponse<Array<PostMonitorBackend_V2ModelsvkVKAPIUpdateAds>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = toggleAdsData;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/apiv2/user-strategies/toggle-ad-status`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PostMonitorBackend_V2ModelsvkVKAPIUpdateAds>>;
      })
    );
  }
  /**
   * @param toggleAdsData undefined
   * @return Success
   */
  ToggleAdStatus(toggleAdsData?: PostMonitorBackend_V2Modelsbit_managementToggleAdsModel): __Observable<Array<PostMonitorBackend_V2ModelsvkVKAPIUpdateAds>> {
    return this.ToggleAdStatusResponse(toggleAdsData).pipe(
      __map(_r => _r.body as Array<PostMonitorBackend_V2ModelsvkVKAPIUpdateAds>)
    );
  }

  /**
   * @param toggleStrategiesData undefined
   * @return Success
   */
  ToggleStrategiesResponse(toggleStrategiesData?: PostMonitorBackend_V2Modelsbit_managementToggleStrategiesViewModels): __Observable<__StrictHttpResponse<Array<UserStrategy>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = toggleStrategiesData;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/apiv2/user-strategies/toggle-strategies`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<UserStrategy>>;
      })
    );
  }
  /**
   * @param toggleStrategiesData undefined
   * @return Success
   */
  ToggleStrategies(toggleStrategiesData?: PostMonitorBackend_V2Modelsbit_managementToggleStrategiesViewModels): __Observable<Array<UserStrategy>> {
    return this.ToggleStrategiesResponse(toggleStrategiesData).pipe(
      __map(_r => _r.body as Array<UserStrategy>)
    );
  }
}

module UserStrategiesService {

  /**
   * Parameters for UpdateUserStrategyInput
   */
  export interface UpdateUserStrategyInputParams {
    value: number;
    inputId: number;
    adId: number;
    accountId: number;
    clientId?: number;
  }

  /**
   * Parameters for GetStrategiesByAdAndAccountIds
   */
  export interface GetStrategiesByAdAndAccountIdsParams {
    clientId: number;
    accountId: number;
    viewModel?: PostMonitorBackend_V2Modelsbit_managementStrategiesByAccountsAndAdsViewModel;
  }

  /**
   * Parameters for RemoveStrategy
   */
  export interface RemoveStrategyParams {
    adId: number;
    accountId: number;
  }
}

export { UserStrategiesService }
