/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./multiple-edit-reject.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "./multiple-edit-reject.component";
var styles_MultipleEditRejectComponent = [i0.styles];
var RenderType_MultipleEditRejectComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MultipleEditRejectComponent, data: {} });
export { RenderType_MultipleEditRejectComponent as RenderType_MultipleEditRejectComponent };
export function View_MultipleEditRejectComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵted(-1, null, ["\u041E\u0448\u0438\u0431\u043A\u0430"])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "p", [["class", "mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i1.ɵdid(4, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵted(-1, null, ["\u041F\u043E\u043F\u0440\u043E\u0431\u0443\u0439\u0442\u0435 \u043F\u043E\u0432\u0442\u043E\u0440\u0438\u0442\u044C \u043F\u043E\u0437\u0436\u0435 \u0438\u043B\u0438 \u043E\u0431\u0440\u0430\u0442\u0438\u0442\u0435\u0441\u044C \u043A \u0430\u0434\u043C\u0438\u043D\u0438\u0441\u0442\u0440\u0430\u0446\u0438\u0438 \u0441\u0435\u0440\u0432\u0438\u0441\u0430!"]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); }); }
export function View_MultipleEditRejectComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-multiple-edit-reject", [], null, null, null, View_MultipleEditRejectComponent_0, RenderType_MultipleEditRejectComponent)), i1.ɵdid(1, 114688, null, 0, i3.MultipleEditRejectComponent, [i2.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MultipleEditRejectComponentNgFactory = i1.ɵccf("app-multiple-edit-reject", i3.MultipleEditRejectComponent, View_MultipleEditRejectComponent_Host_0, {}, {}, []);
export { MultipleEditRejectComponentNgFactory as MultipleEditRejectComponentNgFactory };
