import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {of} from 'rxjs/internal/observable/of';
import {filter} from 'rxjs/internal/operators/filter';
import {Observable} from 'rxjs/Observable';
import {iif, throwError, timer} from 'rxjs';
import {catchError, delay, flatMap, retryWhen, take, tap} from 'rxjs/operators';
import {ExpirationManagerService} from './automation/services/expiration-manager.service';
import {Router} from '@angular/router';
import {ErrorDescription} from './api/models/error-description';
import {AutomationExpirationManagerService} from './automation/services/automation-expiration-manager.service';
import * as Raven from 'raven-js';
import * as Sentry from '@sentry/browser';
import {isNullOrUndefined} from 'util';
import {RavenErrorHandler} from './app.module';
import {environment} from '../environments/environment';
import {LoggingService} from './shared/services/logging.service';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {


  constructor(
    private expirationManager: ExpirationManagerService,
    private accessManager: AutomationExpirationManagerService,
    private router: Router,
    private logger: LoggingService
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem('token');
    if (req.url.includes('/api')) {
      if (token) {
        req = req.clone({
          setHeaders: {
            'Authorization': `Bearer ${token}`
          }
        });
      }
    }

    // Also handle errors globally
    return next.handle(req).pipe(
      tap(x => {
        if (x instanceof HttpResponse) {
          const a = x.body;
          if (a) {
            if (!isNullOrUndefined(a.userMeta)) {
              const containedUserId = (a && a.userMeta && a.userMeta.userId > 0) ? a.userMeta.userId : null;
              if (containedUserId) {
                localStorage.setItem('sentry_user_id', containedUserId);
              }
              this.expirationManager.SetUserMetaExpiration(a.userMeta);
              this.accessManager.SetDemoMode(a.userMeta);
            }
          }
        }
        return x;
      }, err => {
        // Handle this err
        if (err.status === 401 && !window.location.href.includes('sign-in') && !window.location.href.includes('oauth')) {
          this.router.navigate(['sign-in']);
        } else {
          const error: ErrorDescription = err.error;
          if (err.error && typeof err.error === 'string') {
            this.logger.Error(err.error);
          } else if (err.error && err.error.error_desc) {
            this.logger.Error(err.error.error_desc);
          }
          return throwError(error);
        }
      }),
      retryWhen(attempts => {
        let count = 0;

        return attempts
          .pipe(
            flatMap(error => {
              let errorMessage = null;
              let error1 = null;
              if (Array.isArray(error.error)) {
                error1 = error.error[0];
              } else {
                error1 = error.error;
              }
              if (this.IsErrorFromVk(error1)) {
                errorMessage = error1.error_desc;
              } else {
                errorMessage = error1;
              }
              if (this.IsErrorForRetry(errorMessage)) {
                return ++count >= 5 ? throwError(error) : of(error).pipe(delay(1000));
              } else {
                return throwError(error);
              }
            })
          );
      })
    );
  }

  private IsErrorForRetry(error) {
    return this.IsErrorFloodControl(error) ||
      this.IsErrorTemporaryError(error) ||
      this.IsErrorInternalServerError(error) ||
      this.IsTooManyRequest(error);
  }

  private IsErrorFloodControl(error) {
    if (error && error.includes) {
      return error.includes('Flood control');
    } else {
      return false;
    }
  }

  private IsErrorTemporaryError(error) {
    if (error && error.includes) {
      return error.includes('Temporary error (t1)');
    } else {
      return false;
    }
  }

  private IsErrorInternalServerError(error) {
    if (error && error.includes) {
      return error.includes('Internal server error');
    } else {
      return false;
    }
  }

  private IsTooManyRequest(error) {
    if (error && error.includes) {
      return error.includes('Too many requests per second');
    } else {
      return false;
    }
  }

  private IsErrorFromVk(error) {
    if (error) {
      return !isNullOrUndefined(error.error_code);
    }
    return false;
  }
}
