import { AdManagerService } from "../../../api/services";
import * as i0 from "@angular/core";
import * as i1 from "../../../api/services/ad-manager.service";
var AdManagerDataServiceService = /** @class */ (function () {
    function AdManagerDataServiceService(adManagerService) {
        this.adManagerService = adManagerService;
    }
    AdManagerDataServiceService.prototype.LoadInterests = function () {
        var _this = this;
        this.adManagerService.GetInterestCategories()
            .subscribe(function (x) {
            _this.interestCategories = x;
        });
    };
    AdManagerDataServiceService.ngInjectableDef = i0.defineInjectable({ factory: function AdManagerDataServiceService_Factory() { return new AdManagerDataServiceService(i0.inject(i1.AdManagerService)); }, token: AdManagerDataServiceService, providedIn: "root" });
    return AdManagerDataServiceService;
}());
export { AdManagerDataServiceService };
