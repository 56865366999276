import {Injectable, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {AbstractFormServiceService} from '../../../../shared/services/abstract-form-service.service';
import {combineLatest} from "rxjs";
import {StoreService} from "../../../services/store.service";

@Injectable({
  providedIn: 'root'
})
export class IntegrationFormService implements OnInit {

  public leftServiceChanged;
  public rightServiceChanged;

  public form: FormGroup;
  public formService: AbstractFormServiceService;

  constructor(
    private fb: FormBuilder,
  ) {
    this.form = this.fb.group({
      name: this.fb.control('', [Validators.required]),
      leftService: this.fb.control('', [Validators.required]),
      rightService: this.fb.control('', [Validators.required]),
      vk_forms: this.fb.group({
        forms_ids: this.fb.control([]),
      }),
      mt_forms: this.fb.group({
        forms_ids: this.fb.control(null),
      }),
      amoImport: this.fb.group({}),
      amoExport: this.fb.group({
        pipelineIds: [[], [Validators.required]]
      }),
      vkRetargetingGroups: this.fb.group({
        groupIds: this.fb.control([])
      }),
      mtRemarketingGroups: this.fb.group({
        groupIds: this.fb.control([])
      })
    });
    this.formService = new AbstractFormServiceService(this.form);
    this.leftServiceChanged = this.formService.getControl('leftService').valueChanges;
    this.rightServiceChanged = this.formService.getControl('rightService').valueChanges;
    this.leftServiceChanged.subscribe(x => console.log('Left Service Changed', x));
    this.rightServiceChanged.subscribe(x => console.log('Right Service Changed', x));

    this.subscribeToForm();
  }

  public ngOnInit(): void {
  }

  public subscribeToForm() {
    this.subscribeToServicesChange();
  }

  public subscribeToServicesChange() {
    // combineLatest(
    //   this.leftServiceChanged,
    //   this.rightServiceChanged
    // )
    //   .subscribe(([leftService, rightService]) => {
    //     const relation = this.store.getRealtionByServiceCodes(leftService, rightService);
    //     this.form.patchValue({name: relation.name});
    //   });
  }
}
