import {Injectable} from '@angular/core';
import {AffiliateMemberStatisticsByDateFacade} from "../../../../storage/affiliate-member-statistics-by-date/state/affiliate-member-statistics-by-date.facade";

@Injectable({
  providedIn: 'root'
})
export class AffiliateProgramStatisticsByDateService {

  public get statistics$ () {
    return this.statisticsByDateFacade.statistics$;
  }

  constructor(
    private statisticsByDateFacade: AffiliateMemberStatisticsByDateFacade
  ) {
  }

  public LoadStatistics() {
    this.statisticsByDateFacade.LoadStatistics();
  }
}
