import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AffiliateProgramStatisticsByUserRoutingModule } from './affiliate-program-statistics-by-user-routing.module';
import { AffiliatePromgramStatisticsByUserRouteComponent } from './routes/affiliate-promgram-statistics-by-user-route/affiliate-promgram-statistics-by-user-route.component';
import { AffiliatePromgramStatisticsByUserTableComponent } from './components/affiliate-promgram-statistics-by-user-table/affiliate-promgram-statistics-by-user-table.component';
import {MatTableModule} from "@angular/material/table";
import {AffiliateProgramStatisticsSharedModule} from "../affiliate-program-statistics-shared/affiliate-program-statistics-shared.module";
import {SharedModule} from "../../../shared/shared.module";

@NgModule({
  declarations: [AffiliatePromgramStatisticsByUserRouteComponent, AffiliatePromgramStatisticsByUserTableComponent],
    imports: [
        CommonModule,
        AffiliateProgramStatisticsByUserRoutingModule,
        MatTableModule,
        AffiliateProgramStatisticsSharedModule,
        SharedModule
    ]
})
export class AffiliateProgramStatisticsByUserModule { }
