/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./easy-ad-preview-promo.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../shared/pipes/domain-only.pipe";
import * as i4 from "./easy-ad-preview-promo.component";
var styles_EasyAdPreviewPromoComponent = [i0.styles];
var RenderType_EasyAdPreviewPromoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EasyAdPreviewPromoComponent, data: {} });
export { RenderType_EasyAdPreviewPromoComponent as RenderType_EasyAdPreviewPromoComponent };
function View_EasyAdPreviewPromoComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "easy-ad-promo-link-snippet"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "easy-ad-promo-link-snippet__image-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["class", "easy-ad-promo-link-snippet-image"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 5, "div", [["class", "easy-ad-promo-link-snippet__meta-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "easy-ad-promo-link-snippet-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" ", " "])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "easy-ad-promo-link-snippet-url"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, [" ", " "])), i1.ɵppd(9, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.GetSpinnetLink(_v.context.ngIf); _ck(_v, 3, 0, currVal_0); var currVal_1 = _v.context.ngIf.title; _ck(_v, 6, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 8, 0, _ck(_v, 9, 0, i1.ɵnov(_v.parent.parent, 0), _v.context.ngIf.url)); _ck(_v, 8, 0, currVal_2); }); }
function View_EasyAdPreviewPromoComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "easy-ad-promo-loading-placeholder"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u041D\u0435\u0442 \u0438\u0437\u043E\u0431\u0440\u0430\u0436\u0435\u043D\u0438\u044F "]))], null, null); }
function View_EasyAdPreviewPromoComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EasyAdPreviewPromoComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["noLinkSnippet", 2]], null, 0, null, View_EasyAdPreviewPromoComponent_3))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.GetLinkSnippet(); var currVal_1 = i1.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_EasyAdPreviewPromoComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "easy-ad-promo-loading-placeholder"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u0417\u0430\u0433\u0440\u0443\u0437\u043A\u0430... "]))], null, null); }
export function View_EasyAdPreviewPromoComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i3.DomainOnlyPipe, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EasyAdPreviewPromoComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["graphicsNotLoaded", 2]], null, 0, null, View_EasyAdPreviewPromoComponent_4))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.graphicsLoaded; var currVal_1 = i1.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_EasyAdPreviewPromoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-easy-ad-preview-promo", [], null, null, null, View_EasyAdPreviewPromoComponent_0, RenderType_EasyAdPreviewPromoComponent)), i1.ɵdid(1, 114688, null, 0, i4.EasyAdPreviewPromoComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EasyAdPreviewPromoComponentNgFactory = i1.ɵccf("app-easy-ad-preview-promo", i4.EasyAdPreviewPromoComponent, View_EasyAdPreviewPromoComponent_Host_0, { graphicsLoaded: "graphicsLoaded", post: "post" }, {}, []);
export { EasyAdPreviewPromoComponentNgFactory as EasyAdPreviewPromoComponentNgFactory };
