import {UserIntegration} from "../../../api/models";
import {ID} from "@datorama/akita";

export interface UserIntegrationItem extends UserIntegration {
  id: ID
}

/**
 * A factory function that creates UserIntegration
 */
export function createUserIntegration(params: Partial<UserIntegrationItem>) {
  const ui = {
    id: params.userIntegrationId,
    ...params
  } as UserIntegration;

  console.log(ui, 'USER INTEGRATION');

  return ui;
}
