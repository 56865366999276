import {AbstractCopyingConfigurator} from "./abstract-copying-configurator";
import {PostMonitorBackend_V2ModelsAdManagerCopyAdsViewModel} from "../../../api/models";

/**
 *
 */
export class RetargetingGroupsCopyingConfigurator extends AbstractCopyingConfigurator {

  constructor(props) {
    super(props);
  }

  generate(): PostMonitorBackend_V2ModelsAdManagerCopyAdsViewModel[] {
    const specifications: PostMonitorBackend_V2ModelsAdManagerCopyAdsViewModel[] = [];
    console.log(this.copyingData.retargetingGroupConfiguration, 'RETARGETING_GROUP');
    this.copyingData.retargetingGroupConfiguration.retargetingGroups.forEach(retargetingGroupId => {
      let spec = this.generateDefeault();
      spec = Object.assign(spec, {
        retargetingGroup: retargetingGroupId,
        retargetingGroupNot: (this.copyingData.retargetingGroupConfiguration.excludeOtherGroups)
          ? this.copyingData.retargetingGroupConfiguration.retargetingGroups.filter(x => x != retargetingGroupId).join()
          : ''
      })
      specifications.push(spec);
    });
    return specifications;
  }
}
