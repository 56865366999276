var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { QueryEntity } from '@datorama/akita';
import { AffiliateMembersStatisticsByDateStore } from './affiliate-members-statistics-by-date.store';
import * as i0 from "@angular/core";
import * as i1 from "./affiliate-members-statistics-by-date.store";
var AffiliateMembersStatisticsByDateQuery = /** @class */ (function (_super) {
    __extends(AffiliateMembersStatisticsByDateQuery, _super);
    function AffiliateMembersStatisticsByDateQuery(store) {
        var _this = _super.call(this, store) || this;
        _this.store = store;
        return _this;
    }
    Object.defineProperty(AffiliateMembersStatisticsByDateQuery.prototype, "statistics$", {
        get: function () {
            return this.selectAll();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AffiliateMembersStatisticsByDateQuery.prototype, "dateFrom$", {
        get: function () {
            return this.select(function (state) { return state.ui.dateFrom; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AffiliateMembersStatisticsByDateQuery.prototype, "dateTo$", {
        get: function () {
            return this.select(function (state) { return state.ui.dateTo; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AffiliateMembersStatisticsByDateQuery.prototype, "dateFrom", {
        get: function () {
            return this.getValue().ui.dateFrom;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AffiliateMembersStatisticsByDateQuery.prototype, "dateTo", {
        get: function () {
            return this.getValue().ui.dateTo;
        },
        enumerable: true,
        configurable: true
    });
    AffiliateMembersStatisticsByDateQuery.ngInjectableDef = i0.defineInjectable({ factory: function AffiliateMembersStatisticsByDateQuery_Factory() { return new AffiliateMembersStatisticsByDateQuery(i0.inject(i1.AffiliateMembersStatisticsByDateStore)); }, token: AffiliateMembersStatisticsByDateQuery, providedIn: "root" });
    return AffiliateMembersStatisticsByDateQuery;
}(QueryEntity));
export { AffiliateMembersStatisticsByDateQuery };
