import {Component, OnInit} from '@angular/core';
import {ContactLinkViewModel, IContactLinkViewModel} from '../../models/contact-link-view-model';

@Component({
    selector: 'app-contacts',
    templateUrl: './contacts.component.html',
    styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit {
    emailContacts: Array<IContactLinkViewModel>;
    socialContacts: Array<IContactLinkViewModel>;

    constructor() {
    }

    ngOnInit() {
        this.emailContacts = [
            new ContactLinkViewModel(
                'ion-ios-cog',
                'mailto:support@postmonitor.ru',
                'support@postmonitor.ru',
                'Техническая поддержка'
            )
        ];

        this.socialContacts = [
            new ContactLinkViewModel(
                'ion-logo-vk',
                'https://vk.com/postmonitor',
                'vk.com',
                'Группа ВКонтакте'
            ),

            new ContactLinkViewModel(
                'ion-logo-youtube',
                'https://www.youtube.com/channel/UC3lTmTwLorRd3ZYg0lsRdlA',
                'youtube.com',
                'Канал YouTube'
            )
        ];
    }

}
