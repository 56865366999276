<ng-container *ngIf="hasNoAccessHistory; else hasAccessHistory">
  <h1>
    Доступ к данному функционалу предоставляется по запросу.
  </h1>
  <div class="vk-container">
    Оставьте заявку и мы расскажем о функционале CRM-маркетинга, покажем как работать с платформой, а также сделаем расчет стоимости под ваши задачи.
  </div>
  <div class="vk-container">
    <a class="vk-standart-button" href="https://b24-4t928r.bitrix24.site/crm_form_lo05y/" target="_blank" rel="noopener norefferer nofollow">
        Оставить заявку
    </a>
  </div>
</ng-container>
<ng-template #hasAccessHistory>
  <h1>
    У вас нет доступа к функционалу "Интеграции".
  </h1>
  <div class="vk-container">
    Для продолжения работы с функционалом перейдите к выбору тарифного плана и оплатите тариф.
  </div>
  <div class="vk-container">
    <a class="vk-standart-button" routerLink="/new-payment">
      Перейти к оплате
    </a>
  </div>
</ng-template>
