import { UserBalanceQuery } from "./user-balance.query";
import { UserBalanceService } from "./user-balance.service";
import * as i0 from "@angular/core";
import * as i1 from "./user-balance.query";
import * as i2 from "./user-balance.service";
var UserBalanceFacade = /** @class */ (function () {
    function UserBalanceFacade(query, service) {
        this.query = query;
        this.service = service;
    }
    Object.defineProperty(UserBalanceFacade.prototype, "currentUserBalance$", {
        get: function () {
            return this.query.currentUserBalance$;
        },
        enumerable: true,
        configurable: true
    });
    UserBalanceFacade.prototype.LoadCurrentBalance = function () {
        this.service.GetUserBalance();
    };
    UserBalanceFacade.ngInjectableDef = i0.defineInjectable({ factory: function UserBalanceFacade_Factory() { return new UserBalanceFacade(i0.inject(i1.UserBalanceQuery), i0.inject(i2.UserBalanceService)); }, token: UserBalanceFacade, providedIn: "root" });
    return UserBalanceFacade;
}());
export { UserBalanceFacade };
