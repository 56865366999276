<div class="alert alert-danger">
  <div class="alert-items">
    <div class="alert-item">
      <div class="alert-text">
        Нет ни одной группы для создания объявления.
        Вам необходимо
        <a href="https://vk.com/groups?w=groups_create" target="_blank" rel="nofollow, noopener">создать группу</a>
        и вернуться на данную страницу.
        Примечание: Не возможно создать объявление если у вас нет группы или она закрытая.
      </div>
      <div class="alert-actions">
        <a href="javascript:void(0)" class="alert-action" (click)="RefreshButton()">Обновить группы</a>
      </div>
    </div>
  </div>
</div>
