import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {MtCampaign} from '../../../../../models/mt-campaign';
import {MtLeadForm} from '../../../../../models/mt-lead-form';
import {IntegrationFormService} from '../../../services/integration-form.service';
import {VkFormModel} from '../../../../../models/vk-form.model';
import {MtAccount} from '../../../../../models/mt-account';
import {StoreService} from '../../../../../services/store.service';

@Component({
  selector: 'app-mt-lead-forms',
  templateUrl: './mt-lead-forms.component.html',
  styleUrls: ['./mt-lead-forms.component.scss']
})
export class MtLeadFormsComponent implements OnInit {

  form: FormGroup;

  accountsLoading: boolean = true;
  campaignsLoading: boolean;
  formsLoading: boolean;

  accountId: FormControl = new FormControl();
  campaignId: FormControl = new FormControl();

  campaigns: MtCampaign[];
  forms: MtLeadForm[];
  accounts: MtAccount[];

  constructor(
    private integrationFormService: IntegrationFormService,
    private store: StoreService
  ) {
  }

  ngOnInit() {
    this.form = this.integrationFormService.form;
    this.accountsLoading = true;
    this.store.LoadMtAccounts()
      .subscribe((accounts) => {
        this.accounts = accounts;
        this.accountsLoading = false;
      });

    this.accountId.valueChanges
      .subscribe(data => {
        this.LoadCampaigns();
      });

    this.campaignId.valueChanges
      .subscribe(data => {
        this.formsLoading = true;
        this.LoadLeadForms();
      });
  }

  private LoadLeadForms() {
    this.store.LoadMtForms([this.campaignId.value.id], [])
      .subscribe((d: MtLeadForm[]) => {
        console.log(d, 'DATA');
        this.forms = [
          {
            id: 1,
            name: 'Форма 1'
          },
          {
            id: 2,
            name: 'Форма 2'
          },
          {
            id: 3,
            name: 'Форма 3'
          },
        ];
        this.formsLoading = false;
      });
  }

  private LoadCampaigns() {
    this.store.LoadMtCampaigns()
      .subscribe(data => {
        this.campaigns = data;
      });
  }
}
