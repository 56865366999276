import { OnDestroy, OnInit } from '@angular/core';
import { AdNetworkGroupsQuery } from '../../../../stores/ad-network-groups/ad-network-groups.query';
import { AdNetworkFormsQuery } from '../../../../stores/ad-network-forms/ad-network-forms.query';
import { AdNetworkGroupsService } from '../../../../stores/ad-network-groups/ad-network-groups.service';
import { AdNetworkFormsService } from '../../../../stores/ad-network-forms/ad-network-forms.service';
import { FormGroup } from '@angular/forms';
import { PlatformsQuery } from '../../../../stores/platforms/platforms.query';
import { PlatformsTypes } from '../../../../platforms-types';
import { of } from 'rxjs';
import { filter } from "rxjs/operators";
var AdNetworkGroupComponent = /** @class */ (function () {
    function AdNetworkGroupComponent(groupsQuery, formsQuery, groupsService, formsService, platformsQuery) {
        this.groupsQuery = groupsQuery;
        this.formsQuery = formsQuery;
        this.groupsService = groupsService;
        this.formsService = formsService;
        this.platformsQuery = platformsQuery;
        this.data = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0];
        this.isEmpty$ = of(true);
        this.isLoading$ = this.formsQuery.selectLoading();
        this.loadingMessage = "Подождите, идет загрузка форм ...";
        this.formTrackByFunc = (function (index, item) { return index; });
    }
    Object.defineProperty(AdNetworkGroupComponent.prototype, "notFoundMessage", {
        get: function () {
            var text = "Лид-формы не обнаружены";
            if (this.platformsQuery.selectedOriginPlatformCode === PlatformsTypes.FB_LEAD_FORMS) {
                text = 'Объявления не обнаружены';
            }
            return text;
        },
        enumerable: true,
        configurable: true
    });
    AdNetworkGroupComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isGroupSearched$ = this.groupsQuery.isGroupSearched$(this.group);
        this.forms$ = this.formsQuery.groupForms$(this.group.id);
        this.isOpened$ = this.groupsQuery.isOpened$(this.group.id);
        this.isEmpty$ = this.formsQuery.isEmpty$(this.group.id);
        this.isOpened$
            .pipe(filter(function (x) { return x === true; }))
            .subscribe(function (data) {
            _this.formsService.LoadForms(_this.group.id, PlatformsTypes[_this.platformsQuery.selectedOriginPlatformCode]);
        });
        if (this.platformsQuery.selectedOriginPlatformCode === PlatformsTypes.FB_LEAD_FORMS) {
            this.loadingMessage = 'Подождите, идет загрузка объявлений';
        }
    };
    AdNetworkGroupComponent.prototype.OpenGroup = function () {
        this.groupsService.OpenGroup(this.group.id);
    };
    AdNetworkGroupComponent.prototype.ngOnDestroy = function () {
    };
    return AdNetworkGroupComponent;
}());
export { AdNetworkGroupComponent };
