/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PostMonitorBackend_V2ModelsblogEntityPost } from '../models/post-monitor-backend-_v2modelsblog-entity-post';
import { PostMonitorBackend_V2ModelsblogEntityTag } from '../models/post-monitor-backend-_v2modelsblog-entity-tag';
@Injectable({
  providedIn: 'root',
})
class ApiBlogService extends __BaseService {
  static readonly GetAllPostsPath = '/apiv2/apiblog';
  static readonly CreatePostPath = '/apiv2/apiblog';
  static readonly GetPostByIdPath = '/apiv2/apiblog/{id}';
  static readonly UpdatePostPath = '/apiv2/apiblog/{id}';
  static readonly Apiv2ApiblogByIdDeletePath = '/apiv2/apiblog/{id}';
  static readonly GetTagsPath = '/apiv2/apiblog/gettags';
  static readonly PublishPostPath = '/apiv2/apiblog/{id}/publish';
  static readonly RemoveFromPublicationPath = '/apiv2/apiblog/{id}/remove-from-publication';
  static readonly GenerateSitemapPath = '/apiv2/apiblog/generate-sitemap';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return Success
   */
  GetAllPostsResponse(): __Observable<__StrictHttpResponse<Array<PostMonitorBackend_V2ModelsblogEntityPost>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/apiblog`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PostMonitorBackend_V2ModelsblogEntityPost>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetAllPosts(): __Observable<Array<PostMonitorBackend_V2ModelsblogEntityPost>> {
    return this.GetAllPostsResponse().pipe(
      __map(_r => _r.body as Array<PostMonitorBackend_V2ModelsblogEntityPost>)
    );
  }

  /**
   * @param post undefined
   * @return Success
   */
  CreatePostResponse(post?: PostMonitorBackend_V2ModelsblogEntityPost): __Observable<__StrictHttpResponse<PostMonitorBackend_V2ModelsblogEntityPost>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = post;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/apiv2/apiblog`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PostMonitorBackend_V2ModelsblogEntityPost>;
      })
    );
  }
  /**
   * @param post undefined
   * @return Success
   */
  CreatePost(post?: PostMonitorBackend_V2ModelsblogEntityPost): __Observable<PostMonitorBackend_V2ModelsblogEntityPost> {
    return this.CreatePostResponse(post).pipe(
      __map(_r => _r.body as PostMonitorBackend_V2ModelsblogEntityPost)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetPostByIdResponse(id: number): __Observable<__StrictHttpResponse<PostMonitorBackend_V2ModelsblogEntityPost>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/apiblog/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PostMonitorBackend_V2ModelsblogEntityPost>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetPostById(id: number): __Observable<PostMonitorBackend_V2ModelsblogEntityPost> {
    return this.GetPostByIdResponse(id).pipe(
      __map(_r => _r.body as PostMonitorBackend_V2ModelsblogEntityPost)
    );
  }

  /**
   * @param params The `ApiBlogService.UpdatePostParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `post`:
   *
   * @return Success
   */
  UpdatePostResponse(params: ApiBlogService.UpdatePostParams): __Observable<__StrictHttpResponse<PostMonitorBackend_V2ModelsblogEntityPost>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.post;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/apiv2/apiblog/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PostMonitorBackend_V2ModelsblogEntityPost>;
      })
    );
  }
  /**
   * @param params The `ApiBlogService.UpdatePostParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `post`:
   *
   * @return Success
   */
  UpdatePost(params: ApiBlogService.UpdatePostParams): __Observable<PostMonitorBackend_V2ModelsblogEntityPost> {
    return this.UpdatePostResponse(params).pipe(
      __map(_r => _r.body as PostMonitorBackend_V2ModelsblogEntityPost)
    );
  }

  /**
   * @param id undefined
   */
  Apiv2ApiblogByIdDeleteResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/apiv2/apiblog/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  Apiv2ApiblogByIdDelete(id: number): __Observable<null> {
    return this.Apiv2ApiblogByIdDeleteResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @return Success
   */
  GetTagsResponse(): __Observable<__StrictHttpResponse<Array<PostMonitorBackend_V2ModelsblogEntityTag>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/apiblog/gettags`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PostMonitorBackend_V2ModelsblogEntityTag>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetTags(): __Observable<Array<PostMonitorBackend_V2ModelsblogEntityTag>> {
    return this.GetTagsResponse().pipe(
      __map(_r => _r.body as Array<PostMonitorBackend_V2ModelsblogEntityTag>)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  PublishPostResponse(id: number): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/apiv2/apiblog/${id}/publish`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  PublishPost(id: number): __Observable<{}> {
    return this.PublishPostResponse(id).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  RemoveFromPublicationResponse(id: number): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/apiv2/apiblog/${id}/remove-from-publication`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  RemoveFromPublication(id: number): __Observable<{}> {
    return this.RemoveFromPublicationResponse(id).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * @return Success
   */
  GenerateSitemapResponse(): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/apiblog/generate-sitemap`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * @return Success
   */
  GenerateSitemap(): __Observable<{}> {
    return this.GenerateSitemapResponse().pipe(
      __map(_r => _r.body as {})
    );
  }
}

module ApiBlogService {

  /**
   * Parameters for UpdatePost
   */
  export interface UpdatePostParams {
    id: number;
    post?: PostMonitorBackend_V2ModelsblogEntityPost;
  }
}

export { ApiBlogService }
