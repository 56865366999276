var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { map as __map, filter as __filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../api-configuration";
import * as i2 from "@angular/common/http";
var TariffPackagesService = /** @class */ (function (_super) {
    __extends(TariffPackagesService, _super);
    function TariffPackagesService(config, http) {
        return _super.call(this, config, http) || this;
    }
    /**
     * @return Success
     */
    TariffPackagesService.prototype.GetAllTariffPackagesResponse = function () {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/tariff-packages", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @return Success
     */
    TariffPackagesService.prototype.GetAllTariffPackages = function () {
        return this.GetAllTariffPackagesResponse().pipe(__map(function (_r) { return _r.body; }));
    };
    TariffPackagesService.prototype.Apiv2TariffPackagesStuffGetResponse = function () {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/tariff-packages/stuff", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    TariffPackagesService.prototype.Apiv2TariffPackagesStuffGet = function () {
        return this.Apiv2TariffPackagesStuffGetResponse().pipe(__map(function (_r) { return _r.body; }));
    };
    TariffPackagesService.prototype.Apiv2TariffPackagesUserGetResponse = function () {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/tariff-packages/user", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    TariffPackagesService.prototype.Apiv2TariffPackagesUserGet = function () {
        return this.Apiv2TariffPackagesUserGetResponse().pipe(__map(function (_r) { return _r.body; }));
    };
    TariffPackagesService.prototype.Apiv2TariffPackagesGroupsGetResponse = function () {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/tariff-packages/groups", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    TariffPackagesService.prototype.Apiv2TariffPackagesGroupsGet = function () {
        return this.Apiv2TariffPackagesGroupsGetResponse().pipe(__map(function (_r) { return _r.body; }));
    };
    TariffPackagesService.GetAllTariffPackagesPath = '/apiv2/tariff-packages';
    TariffPackagesService.Apiv2TariffPackagesStuffGetPath = '/apiv2/tariff-packages/stuff';
    TariffPackagesService.Apiv2TariffPackagesUserGetPath = '/apiv2/tariff-packages/user';
    TariffPackagesService.Apiv2TariffPackagesGroupsGetPath = '/apiv2/tariff-packages/groups';
    TariffPackagesService.ngInjectableDef = i0.defineInjectable({ factory: function TariffPackagesService_Factory() { return new TariffPackagesService(i0.inject(i1.ApiConfiguration), i0.inject(i2.HttpClient)); }, token: TariffPackagesService, providedIn: "root" });
    return TariffPackagesService;
}(__BaseService));
export { TariffPackagesService };
