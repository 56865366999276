import { AfterViewChecked, EventEmitter, OnInit } from '@angular/core';
import { ControlContainer, FormGroup } from '@angular/forms';
import { AutomationVkService } from '../../../../../api/services';
var AdStartSettingsComponent = /** @class */ (function () {
    function AdStartSettingsComponent(controlContainer, automationVkService) {
        this.controlContainer = controlContainer;
        this.automationVkService = automationVkService;
        this.budget = 0;
        this.disabled = false;
        this.onNext = new EventEmitter();
        this.budgetInfo = "\u0414\u043B\u044F \u0442\u043E\u0433\u043E, \u0447\u0442\u043E\u0431\u044B \u043C\u043E\u0436\u043D\u043E \u0431\u044B\u043B\u043E \u043F\u043E\u043A\u0430\u0437\u044B\u0432\u0430\u0442\u044C \u043E\u0431\u044A\u044F\u0432\u043B\u0435\u043D\u0438\u0435 \u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u0435\u043B\u044F\u043C \u0412\u041A\u043E\u043D\u0442\u0430\u043A\u0442\u0435,\n   \u043D\u0435\u043E\u0431\u0445\u043E\u0434\u0438\u043C\u043E \u043F\u043E\u043F\u043E\u043B\u043D\u0438\u0442\u044C \u0431\u044E\u0434\u0436\u0435\u0442 \u0432\u0430\u0448\u0435\u0433\u043E \u0440\u0435\u043A\u043B\u0430\u043C\u043D\u043E\u0433\u043E \u043A\u0430\u0431\u0438\u043D\u0435\u0442\u0430. \n\u0421\u0440\u0435\u0434\u0441\u0442\u0432\u0430 \u0431\u0443\u0434\u0443\u0442 \u0441\u043F\u0438\u0441\u044B\u0432\u0430\u0442\u044C\u0441\u044F \u043A\u043E\u0433\u0434\u0430 \u043E\u0431\u044A\u044F\u0432\u043B\u0435\u043D\u0438\u0435 \u0431\u0443\u0434\u0435\u0442 \u043F\u043E\u043A\u0430\u0437\u044B\u0432\u0430\u0442\u044C\u0441\u044F \n\u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u0435\u043B\u044F\u043C.\n";
        this.Tip = 'Все объявления проходят обязательную модерацию (проверку объявления на соответствия правилам размещения) сотрудниками ВКонтакте. \n' +
            'Если объявление не соответствует правилам, то его могут отклонить и оно не может быть включено. \n' +
            '\n' +
            'Если вы выберите “Отправить на модерацию сразу после создания объявления” и “Запустить объявление сразу после прохождения модерации”. То, если модераторы одобрят объявление, оно будет запущено и начнутся показы объявления. \n' +
            'Если не будет одобрено, то вам необходимо изменить объявление и повторно отправить на модерацию.\n';
    }
    Object.defineProperty(AdStartSettingsComponent.prototype, "IsAdmin", {
        get: function () {
            return this.AccountRole === 'admin';
        },
        enumerable: true,
        configurable: true
    });
    AdStartSettingsComponent.prototype.ngOnInit = function () {
        var _this = this;
        setTimeout(function () {
            _this.automationVkService.GetBudget({
                accountId: _this.accountId,
                clientId: _this.clientId
            })
                .subscribe(function (budgetResponse) {
                _this.budget = budgetResponse.data;
                console.log(budgetResponse);
            });
        }, 10000);
    };
    AdStartSettingsComponent.prototype.ngAfterViewChecked = function () {
    };
    AdStartSettingsComponent.prototype.next = function () {
        this.onNext.emit(this.form.value);
    };
    AdStartSettingsComponent.prototype.IsBudgetZero = function () {
        return this.budget === 0;
    };
    AdStartSettingsComponent.prototype.Update = function () {
        this.automationVkService.GetBudget({
            accountId: this.accountId,
            clientId: this.clientId
        })
            .subscribe(function (budgetResponse) {
            console.log(budgetResponse);
        });
    };
    return AdStartSettingsComponent;
}());
export { AdStartSettingsComponent };
