import {Component, OnInit} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {IntegrationFormService} from "../../../services/integration-form.service";

@Component({
  selector: 'app-amo-crm-contacts-export',
  templateUrl: './amo-crm-contacts-export.component.html',
  styleUrls: ['./amo-crm-contacts-export.component.scss']
})
export class AmoCrmContactsExportComponent implements OnInit {

  public pipelines = [
    {
      id: 1,
      name: 'Воронка 1',
    }
  ];

  public pipelineStatuses = [
    {
      id: 1,
      name: 'Первичный контакт'
    },
    {
      id: 2,
      name: 'Согласование'
    },
    {
      id: 3,
      name: 'Сделка успешна'
    },
    {
      id: 4,
      name: 'Сделка сорвалась'
    }
  ];

  form: FormGroup;

  constructor(
    private integrationForm: IntegrationFormService
  ) {
  }

  ngOnInit() {
    this.form = this.integrationForm.form.get('amoExport') as FormGroup;
  }

}
