import {Injectable} from '@angular/core';
import {ID} from '@datorama/akita';
import {HttpClient} from '@angular/common/http';
import {CustomFieldsStore} from './custom-fields.store';
import {CustomField} from './custom-field.model';
import {map, tap} from 'rxjs/operators';
import {PlatformsTypes} from "../../platforms-types";
import {AssignationPlatforms} from "../platform-assignations/platform-assignation.model";
import {MegaplanService} from "../../../api/services";
import {of} from "rxjs";
import {Paginatable} from "../../../utils/js/paginatable";
import {CustomFieldValueService} from "../custom-field-value/custom-field-value.service";
import {CustomFieldValue} from "../custom-field-value/custom-field-value.model";
import {EnumValue} from "@angular/compiler-cli/src/ngtsc/metadata";

@Injectable({providedIn: 'root'})
export class CustomFieldsService extends Paginatable<string>{

  private platform = null;

  constructor(
    private customFieldsStore: CustomFieldsStore,
    private http: HttpClient,
    private megaplanApi: MegaplanService,
    private customFieldValueService: CustomFieldValueService
  ) {
    super(null);
  }

  LoadCustomFields(platform: AssignationPlatforms, page = null) {
    this.platform = platform;
    switch (platform) {
      case AssignationPlatforms.MEGAPLAN:
        return this.LoadMegaplanCustomFields(page);
      default:
        return of([])
    }
  }

  LoadMegaplanCustomFields(page: any) {
    console.log('LOAD CUSTOM FIELDS');
    return this.megaplanApi.GetContractorDealsExtraFieldsAsync({
      limit: null,
      contractorId: '1',
      pageAfter: page,
      contentType: 'StringField'
    })
      .pipe(
        map((customFields: any) => {
          console.log(customFields);
          const hasNext = customFields.meta.pagination.hasMoreNext;
          // const fields = customFields.data
          //   .filter(x => ['StringField', 'EnumField'].includes(x.contentType))
          //   .map(customField => ({
          //     Id: customField.id,
          //     Name: customField.hrName,
          //     Key: customField.name,
          //     PlatformCode: 'FB_LEAD_FORMS'
          //   } as CustomField));
          const fields = [];
          for (const field of customFields.data) {
            if (['StringField', 'EnumField'].includes(field.contentType)) {
              const formatedField = {
                Id: field.id,
                Name: field.hrName,
                Key: field.name,
                PlatformCode: 'FB_LEAD_FORMS'
              } as CustomField;
              fields.push(formatedField);
              if (field.contentType === 'EnumField') {
                console.log(field.enumValues, 'ENUM FIELD');
                field.enumValues.forEach((EnumValue, index) => {
                  const customValue = {
                    Id: index * 100,
                    Code: EnumValue,
                    Name: EnumValue,
                    PlatformCode: 'FB_LEAD_FORMS',
                    CustomFieldId: field.id,
                    UseFormating: false
                  } as CustomFieldValue;
                  console.log(customValue, index, 'CUSTOM VALUE');
                  this.customFieldValueService.add(customValue);
                })

              }
            }
          }
          console.log(fields, 'FIELDS');
          const lastField = fields[fields.length - 1]
          this.customFieldsStore.add(fields);
          console.log(hasNext, 'HAS NEXT');
          if (hasNext) {
            console.log('WTF');
            this.NextToken(lastField.Id)
          }
          return fields;
        })
      )
  }

  OnNextToken(tokenValue: string) {
    this.LoadCustomFields(this.platform, tokenValue)
      .subscribe()
  }
}
