import {ID} from '@datorama/akita';
import {PostMonitorBackend_V2ModelsvkAmoCrmAPIAmoPipeline} from '../../../api/models/post-monitor-backend-_v2modelsvk-amo-crm-apiamo-pipeline';
import {PostMonitorBackend_V2ModelsvkAmoCrmAPIAmoPipelineStatus} from '../../../api/models/post-monitor-backend-_v2modelsvk-amo-crm-apiamo-pipeline-status';
import {PostMonitorBackend_V2ModelsBitrixApiBitrixCrmStatus} from '../../../api/models/post-monitor-backend-_v2models-bitrix-api-bitrix-crm-status';
import {UOnTravelLeadStatusModel} from './backend-models/u-on-travel-lead-status.model';
import {UOnTravelRequestStatus} from './backend-models/u-on-travel-request-status.model';
import {MoiSkladState} from '../../../models/moi-sklad-state';

export interface CrmPipeline {
  id: ID;
  name: string;
  sort: number;
  entity_id: ID;
  statuses: CrmPipelineStatus[];
  type: 'LEADS' | 'DEALS' | 'ORDERS' | 'CONTACTS' | 'UON_MARKS_EXPORT';
}

export interface CrmPipelineStatus {
  id: ID;
  selectable_id: ID;
  name: string;
  sort: number;
  type: 'LEADS' | 'DEALS' | 'ORDERS' | 'CONTACTS' | 'UON_MARKS_EXPORT';
}

/**
 * A factory function that creates CrmPipelines
 */
export function createCrmPipelineFromAmoCrm(params: Partial<PostMonitorBackend_V2ModelsvkAmoCrmAPIAmoPipeline>) {
  return {
    id: params.id,
    entity_id: params.id,
    name: params.name,
    sort: params.sort,
    statuses: Object.keys(params.statuses)
      .map(x => params.statuses[x])
      .sort((a, b) => a.sort - b.sort)
      .map(x => createCrmPipelineFormAmoCrmStatus(x) as any),
    type: 'LEADS'
  } as CrmPipeline;
}

/**
 * Создать элемент воронки продаж из сервиса amoCrm
 * @param params
 */
export function createCrmPipelineFormAmoCrmStatus(params: Partial<PostMonitorBackend_V2ModelsvkAmoCrmAPIAmoPipelineStatus>) {
  return {
    id: params.id,
    selectable_id: params.id,
    name: params.name,
    sort: params.sort,
    type: 'LEADS'
  } as CrmPipelineStatus;
}

export function createCrmPipelineStatusFromBitrix(status: PostMonitorBackend_V2ModelsBitrixApiBitrixCrmStatus, params: Partial<any>, type) {
  return {
    id: status.ID,
    name: status.NAME,
    selectable_id: status.STATUS_ID,
    sort: 0,
    type
  } as CrmPipelineStatus;
}

export function createCrmPipelineFromBitrix(params: Partial<any>, statuses: PostMonitorBackend_V2ModelsBitrixApiBitrixCrmStatus[]) {
  return {
    id: params.ID,
    entity_id: params.ID,
    name: params.NAME,
    sort: params.SORT,
    statuses: statuses.map(x => createCrmPipelineStatusFromBitrix(x, params, 'DEALS')),
    type: 'DEALS'
  } as CrmPipeline;
}

export function createCrmPipelineFromMegaplan(params: Partial<any>) {
  return {
    id: params.id,
    entity_id: params.id,
    name: params.name,
    statuses: params.states ? params.states.map(x => createCrmPipelineStatusFromMegaplan(x)) : [],
    type: 'DEALS'
  } as CrmPipeline;
}

export function createCrmPipelineStatusFromMegaplan(params: Partial<any>) {
  return {
    id: params.id,
    name: params.name,
    sort: 0,
    type: 'DEALS'
  } as CrmPipelineStatus;
}

export function createUOnCrmPipelineFromLead(lead: Partial<UOnTravelLeadStatusModel>) {
  return {
    id: lead.id,
    name: lead.name.replace('&rarr;', '->'),
    type: 'LEADS'
  } as CrmPipelineStatus;
}

export function createUOnCrmPipelineFromMark(label) {
  return {
    id: label.id,
    name: label.name,
    type: 'UON_MARKS_EXPORT'
  } as CrmPipelineStatus;
}

export function createUOnCrmPipelineFromRequest(request: Partial<UOnTravelRequestStatus>) {
  return {
    id: request.id,
    name: request.name.replace('&rarr;', '->'),
    type: 'DEALS',
  } as CrmPipelineStatus;
}

export function createMoiSkladPipeline(name: string, section: string, pipelineStatus: MoiSkladState[]) {

}

export function createMoiSkladPipelineStatus(pipelineStatus: MoiSkladState) {
 return {
   id: pipelineStatus.accountId,
   name: pipelineStatus.name,
   selectable_id: pipelineStatus.accountId
 } as CrmPipelineStatus;
}
