import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {ChatFormComponent} from './components/chat-form/chat-form.component';
import {ChatWrapperComponent} from './components/chat-wrapper/chat-wrapper.component';
import {ConversationComponent} from "./routes/conversation/conversation.component";
import {ConversationsListComponent} from "./routes/conversations-list/conversations-list.component";

const routes: Routes = [
  {
    path: 'chat',
    component: ChatWrapperComponent,
    children: [
      {
        path: 'conversation',
        component: ConversationComponent
      },
      {
        path: 'conversations-list',
        component: ConversationsListComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ChatRoutingModule { }
