import { OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup } from '@angular/forms';
import { TimeValidators } from '../../validators/TimeValidators';
var TimePickerComponent = /** @class */ (function () {
    function TimePickerComponent() {
    }
    Object.defineProperty(TimePickerComponent.prototype, "timeToMinutes", {
        get: function () {
            return this.form.value.hour * 60 + parseInt(this.form.value.minutes, 10);
        },
        enumerable: true,
        configurable: true
    });
    TimePickerComponent.prototype.ngOnInit = function () {
        this.form = new FormGroup({
            hour: new FormControl(0, [TimeValidators.hour]),
            minutes: new FormControl(0, [TimeValidators.minutes])
        });
    };
    TimePickerComponent.prototype.registerOnChange = function (fn) {
        this.onChange = fn;
    };
    TimePickerComponent.prototype.registerOnTouched = function (fn) {
    };
    TimePickerComponent.prototype.setDisabledState = function (isDisabled) {
    };
    TimePickerComponent.prototype.writeValue = function (obj) {
        var hour = Math.floor(obj / 60);
        var minutes = obj % 60;
        this.form.setValue({
            hour: (hour < 10) ? "0" + hour : hour,
            minutes: (minutes < 10) ? "0" + minutes : minutes
        });
    };
    TimePickerComponent.prototype.change = function () {
        this.onChange(this.timeToMinutes);
    };
    TimePickerComponent.prototype.changeHour = function ($event) {
        var hour = this.valueToNumber($event.target.value);
        this.form.setValue({
            hour: (hour > 23) ? 23 : this.formatToTime(hour),
            minutes: this.formatToTime(this.form.value.minutes)
        });
        this.change();
    };
    TimePickerComponent.prototype.changeMinutes = function ($event) {
        var minutes = this.valueToNumber($event.target.value);
        this.form.setValue({
            hour: this.formatToTime(this.form.value.hour),
            minutes: (minutes > 59) ? 59 : this.formatToTime(minutes)
        });
        this.change();
    };
    TimePickerComponent.prototype.valueToNumber = function (value) {
        value = value.slice(-2).replace(/D/, '');
        if (value.length === 0) {
            return 0;
        }
        else {
            return parseInt(value, 10);
        }
    };
    TimePickerComponent.prototype.formatToTime = function (value) {
        value = parseInt(value, 10);
        return (value < 10) ? "0" + value : value;
    };
    return TimePickerComponent;
}());
export { TimePickerComponent };
