import {NgModule} from '@angular/core';
import {CommonModule, registerLocaleData} from '@angular/common';

import {VkRoutingModule} from './vk-routing.module';
import {VkHomeRouteComponent} from './routes/vk-home-route/vk-home-route.component';
import {VkLayoutRouteComponent} from './routes/vk-layout-route/vk-layout-route.component';
import {ClarityModule} from '@clr/angular';
import {GroupsSearchRouteComponent} from './routes/groups-search-route/groups-search-route.component';
import {SharedModule} from '../shared/shared.module';
import {GroupFilterComponent} from './components/group-filter/group-filter.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {GroupCardComponent} from './components/group-card/group-card.component';
import {PostFilterComponent} from './components/post-filter/post-filter.component';
import {PostSearchPageComponent} from './routes/post-search-page/post-search-page.component';
import {PostCardComponent} from './components/post-card/post-card.component';
import {PostBodyComponent} from './components/post-body/post-body.component';
import {HiddenPostsSearchComponent} from './routes/hidden-posts-search/hidden-posts-search.component';
import ruLocale from '@angular/common/locales/ru';
import {VkUrlWrapperPipe} from './pipes/vk-url-wrapper.pipe';
import {PostAttachmentImageComponent} from './components/post-attachment-image/post-attachment-image.component';
import {VkReadMorePipe} from './pipes/vk-read-more.pipe';
import {PostColumnsComponent} from './components/post-columns/post-columns.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MembersGraphicComponent} from './components/graphics/members-graphic/members-graphic.component';
import {ChartsModule} from 'ng2-charts';
import {SexAgeStatisticsGraphicComponent} from './components/graphics/sex-age-statistics-graphic/sex-age-statistics-graphic.component';
import {PostAnalitycsGraphicComponent} from './components/graphics/post-analitycs-graphic/post-analitycs-graphic.component';
import {EnagementAnalyticsGraphicComponent} from './components/graphics/enagement-analytics-graphic/enagement-analytics-graphic.component';
import {HashtagPipe} from './pipes/hashtag.pipe';
import {UrlExctractorService} from './services/url-exctractor.service';
import {CpmStatisticGraphicComponent} from './components/graphics/cpm-statistic-graphic/cpm-statistic-graphic.component';
import {VkApiService} from './services/vk-api.service';
import {VkApiConvertorService} from './services/vk-api-convertor.service';
import {VkAdDetectorService} from './services/vk-ad-detector.service';
import {VkStealthParserHelperService} from './services/vk-stealth-parser-helper.service';
import {VkAdvancedSearchReportWrapperService} from './services/vk-advanced-search-report-wrapper.service';
import {MatCheckboxModule, MatRadioModule} from '@angular/material';
import { EasyStealthComponent } from './routes/easy-stealth/easy-stealth.component';

registerLocaleData(ruLocale);

@NgModule({
  imports: [
    CommonModule,
    VkRoutingModule,
    ClarityModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ChartsModule,
    MatCheckboxModule,
    MatRadioModule
  ],
  declarations: [VkHomeRouteComponent, VkLayoutRouteComponent, GroupsSearchRouteComponent, GroupFilterComponent, GroupCardComponent, PostFilterComponent, PostSearchPageComponent, PostCardComponent, PostBodyComponent, HiddenPostsSearchComponent, VkUrlWrapperPipe, PostAttachmentImageComponent, VkReadMorePipe, PostColumnsComponent, MembersGraphicComponent, SexAgeStatisticsGraphicComponent, PostAnalitycsGraphicComponent, EnagementAnalyticsGraphicComponent, HashtagPipe, CpmStatisticGraphicComponent, EasyStealthComponent],
  exports: [
    SexAgeStatisticsGraphicComponent
  ],
  providers: [UrlExctractorService, VkApiService, VkApiConvertorService, VkAdDetectorService, VkStealthParserHelperService, VkAdvancedSearchReportWrapperService]
})
export class VkModule {
}
