/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./payments-tariff-header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./payments-tariff-header.component";
import * as i4 from "../../services/payments.service";
var styles_PaymentsTariffHeaderComponent = [i0.styles];
var RenderType_PaymentsTariffHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PaymentsTariffHeaderComponent, data: {} });
export { RenderType_PaymentsTariffHeaderComponent as RenderType_PaymentsTariffHeaderComponent };
function View_PaymentsTariffHeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " \u0420\u0443\u0431. "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.customPrice; _ck(_v, 1, 0, currVal_0); }); }
function View_PaymentsTariffHeaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(0, null, [" ", " \u0420\u0443\u0431. "])), i1.ɵppd(1, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i1.ɵnov(_v.parent, 0), _co.price, "1.0-0")); _ck(_v, 0, 0, currVal_0); }); }
export function View_PaymentsTariffHeaderComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DecimalPipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "tariff-plan-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "tariff-plan-header__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "tariff-plan-header__price"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PaymentsTariffHeaderComponent_1)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["defaultPrice", 2]], null, 0, null, View_PaymentsTariffHeaderComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.customPrice; var currVal_2 = i1.ɵnov(_v, 7); _ck(_v, 6, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tariff.name; _ck(_v, 3, 0, currVal_0); }); }
export function View_PaymentsTariffHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-payments-tariff-header", [], null, null, null, View_PaymentsTariffHeaderComponent_0, RenderType_PaymentsTariffHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.PaymentsTariffHeaderComponent, [i4.PaymentsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PaymentsTariffHeaderComponentNgFactory = i1.ɵccf("app-payments-tariff-header", i3.PaymentsTariffHeaderComponent, View_PaymentsTariffHeaderComponent_Host_0, { tariff: "tariff" }, {}, []);
export { PaymentsTariffHeaderComponentNgFactory as PaymentsTariffHeaderComponentNgFactory };
