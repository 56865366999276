import {Injectable} from '@angular/core';
import {QueryEntity} from '@datorama/akita';
import {PlatformsState, PlatformsStore} from './platforms.store';
import {PlatformsRelationsQuery} from '../platforms-relations/platforms-relations.query';
import {delay, flatMap, map} from 'rxjs/operators';
import {combineLatest, of} from 'rxjs';
import {AssignationPlatforms} from '../platform-assignations/platform-assignation.model';
import {isNullOrUndefined} from 'util';

@Injectable({providedIn: 'root'})
export class PlatformsQuery extends QueryEntity<PlatformsState> {

  public originPlatforms$ = this.selectAll({filterBy: entity => entity.isDataOrigin});



  public destinationPlatforms$ = this.selectAll({filterBy: entity => !entity.isDataOrigin});

  public selectedOriginPlatformCode$ = this.select(state => state.ui.selectedOriginPlatform);

  public selectedDestinationPlatformCode$ = this.select(state => {
    return state.ui.selectedDestinationPlatform;
  });
  public isDestinationPlatformSelected$ = this.selectedDestinationPlatformCode$
    .pipe(
      map(code => code !== null)
    )

  public get selectedOriginPlatformCode() {
    const code = this.getValue().ui.selectedOriginPlatform;
    return code;
  };

  public get selectedDestinationPlatformCode() {
    const code = this.getValue().ui.selectedDestinationPlatform;
    return code;
  }

  public selectedOriginPlatform = this.selectedOriginPlatformCode$
    .pipe(
      flatMap(selectedOriginPlatformCode => this.selectAll({filterBy: entity => entity.code === selectedOriginPlatformCode})),
      map(platforms => platforms[0])
    );

  public selectedDestinationPlatform$ = this.selectedDestinationPlatformCode$
    .pipe(
      flatMap(selectedDestinationPlatformCode => this.selectAll({filterBy: entity => entity.code === selectedDestinationPlatformCode})),
      map(platforms => platforms[0])
    );

  public relatedPlatforms$ = this.selectedOriginPlatform
    .pipe(
      flatMap(platform => platform ? this.relationsQuery.relatedPlatformsIds(platform.id) : of([])),
      flatMap(platformIds => this.selectAll({filterBy: entity => platformIds.includes(entity.integrationServiceId)}))
    );

  /**
   * Возвращает дополнительные параметры платформы
   * @example this.platformsQuery.getPlatformParams(AssignationPlatforms.BITRIX24
   * @param {AssignationPlatforms} platformId - id платформы к которой привязаны параметры
   */
  public getPlatformParams = (platformId: AssignationPlatforms) => this.select(state => state.ui.params[platformId]);


  public get requiredFields() {
    return this.getValue().ui.requiredFields;
  }

  public get HasLeads() {
    const state = this.getValue();
    let status = true;

    if (state.ui.selectedOriginPlatform === 'BITRIX_EXPORT' || state.ui.selectedDestinationPlatform === 'BITRIX_IMPORT') {
      if (state.ui && state.ui.params && state.ui.params.BITRIX24) {
        status = state.ui.params.BITRIX24.hasLeads;
      }
    }

    return status;
  }

  public hasLeads$ = this.select(state => state.ui.params)
    .pipe(
      map(params => {
        let status = true;

        if (!isNullOrUndefined(params.BITRIX24)) {
          status = params.BITRIX24.hasLeads;
        }

        return status;
      })
    );

  public destinationPlatformSections$ = combineLatest(
    this.hasLeads$,
    this.selectedDestinationPlatform$
  )
    .pipe(
      map(([hasLeads, platform]) => platform.sections.filter(section => hasLeads ? true : section.code !== 'LEADS'))
    )

  constructor(
    protected store: PlatformsStore,
    private relationsQuery: PlatformsRelationsQuery
  ) {
    super(store);
  }

}
