/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./easy-ads-wrapper.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./easy-ads-wrapper.component";
var styles_EasyAdsWrapperComponent = [i0.styles];
var RenderType_EasyAdsWrapperComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EasyAdsWrapperComponent, data: {} });
export { RenderType_EasyAdsWrapperComponent as RenderType_EasyAdsWrapperComponent };
function View_EasyAdsWrapperComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "easy-ads-side-menu__item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "a", [["class", "easy-ads-side-menu-link"], ["routerLinkActive", "easy-ads-side-menu-link_active"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 671744, [[2, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { queryParams: [0, "queryParams"], routerLink: [1, "routerLink"] }, null), i1.ɵdid(4, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 1, { links: 1 }), i1.ɵqud(603979776, 2, { linksWithHrefs: 1 }), (_l()(), i1.ɵted(7, null, [" ", " "]))], function (_ck, _v) { var currVal_2 = _v.parent.context.$implicit.params; var currVal_3 = _v.parent.context.$implicit.route; _ck(_v, 3, 0, currVal_2, currVal_3); var currVal_4 = "easy-ads-side-menu-link_active"; _ck(_v, 4, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).target; var currVal_1 = i1.ɵnov(_v, 3).href; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_5 = _v.parent.context.$implicit.name; _ck(_v, 7, 0, currVal_5); }); }
function View_EasyAdsWrapperComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EasyAdsWrapperComponent_3)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.enabled; _ck(_v, 2, 0, currVal_0); }, null); }
function View_EasyAdsWrapperComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "easy-ads-layout"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "easy-ads-layout__side-menu"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "nav", [["class", "easy-ads-side-menu"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EasyAdsWrapperComponent_2)), i1.ɵdid(5, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "easy-ads-layout__content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(8, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.menuItems; _ck(_v, 5, 0, currVal_0); _ck(_v, 8, 0); }, null); }
function View_EasyAdsWrapperComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(1, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_EasyAdsWrapperComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_EasyAdsWrapperComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["noWrapper", 2]], null, 0, null, View_EasyAdsWrapperComponent_4))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.useWrapper; var currVal_1 = i1.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_EasyAdsWrapperComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-easy-ads-wrapper", [], null, null, null, View_EasyAdsWrapperComponent_0, RenderType_EasyAdsWrapperComponent)), i1.ɵdid(1, 114688, null, 0, i4.EasyAdsWrapperComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EasyAdsWrapperComponentNgFactory = i1.ɵccf("app-easy-ads-wrapper", i4.EasyAdsWrapperComponent, View_EasyAdsWrapperComponent_Host_0, { useWrapper: "useWrapper" }, {}, []);
export { EasyAdsWrapperComponentNgFactory as EasyAdsWrapperComponentNgFactory };
