<p>Чтобы начать  работу с управлением ставкой “Охват к заданному времени”, вам необходимо выполнить 2 шага:</p>
<ol>
  <li>Ввод значений</li>
  <li>Запуск управления ставкой и корректировка значений (если требуется)</li>
</ol>

<h3>1 шаг. Ввод значений.</h3>

<p>
  Необходимо ввести следующие значения: <br>
  <strong>Дата остановки объявления.</strong> <br>
  Укажите дату и час, к которому необходимо набрать нужный вам охват. Выбор этой даты зависит от величины охвата и определяется индивидуально. <br>
  <strong>Необходимый охват.</strong> <br>
  Задайте какой охват должно набрать объявление. Значение зависит от величины целевой аудитории объявления и задач, которые стоят перед вами. Например, при аудитории в 100 000 человек, можете указать охват 10 000 человек. Но мы рекомендуем тестировать и выявлять необходимый охват индивидуально под ваши задачи и вашу аудиторию. <br>
  <strong>Начальная ставка.</strong> <br>
  Установите ставку объявления, с которой начнется его показ после запуска. Начальная ставка определяется индивидуально. Можете, например, поставить  минимальную для вашего формата (для формата Рекламная запись — 30 руб., для формата ТГБ (тизеры) — 1,20 руб.), или можете поставить значение по своему усмотрению.
</p>
<strong>Шаг изменения ставки.</strong><br>
Установите значение, на величину которой будет изменяться ваша начальная ставка. Величина шага изменения ставки определяется индивидуально. Можете, например, для тизеров поставить 0,1 руб., для промо-постов - 1 руб., или можете поставить значение по своему усмотрению.
<hr>
<strong>
  Все эти значения обязательны для ввода. Есть еще один параметр, значение которого вы можете ввести по своему усмотрению:
</strong>
<br>
<strong>Максимальная стоимость перехода.</strong>
Укажите ваше значение в поле “Максимальная стоимость перехода (eCPC)”. Система управления ставками сама отключит объявления, которые будут превышать заданное значение.
Устанавливается индивидуально, так как в зависимости от задач и сферы, приемлемая стоимость перехода может быть разной. Мы рекомендуем использовать этот параметр, во избежание сливания бюджета.
<br>
<strong>Шаг 2. Запуск управления ставкой и корректировка значений.</strong>
<br>
Как только все необходимые значения будут введены, запустите Управление ставкой и следите за показателями. При этом рекламная кампания в личном кабинете ВК должна быть запущена.
Если, после запуска управления ставкой, охват будет набираться очень медленно, то рекомендуем повысить начальную ставку или значение шага изменения ставки.
Если прирост охвата идет с нормальной скоростью, то все настройки заданы верно, и вы можете оставить работать управление ставкой в автоматическом режиме.
Можете провести тесты с разными значениями параметров, для поиска наиболее эффективной работы управления ставками.
<br>
<br>
<br>
<strong>Пример настройки.</strong> <br>
<strong>Дано:</strong> <br>
Аудитория объявления - 50 000 человек <br>
Рекламное объявление - тизер (ТГБ). <br>
Дата Запуска управления ставками 10.10.2018 в 10:00 <br>
Рекомендуемая ставка - 17.10-20.55 руб. <br>
<br>
<strong>Установленные значения для запуска объявления:</strong> <br>
Необходимый охват - 25 000. <br>
Дата остановки объявления - 15.10.2018 в 10:00 <br>
Начальная ставка - 1,2 руб. <br>
Шаг изменения ставки - 0,1 руб. <br>
Максимальная стоимость перехода (eCPC) - 5 руб. <br>
<br>
или <br>
<br>
<strong>Дано:</strong> <br>
Аудитория объявления- 50 000 человек <br>
Рекламное объявление - промо-пост. <br>
Дата Запуска управления ставками 10.10.2018 в 10:00 <br>
Рекомендуемая ставка - 293,20-301.50 руб. <br>
<br>
<strong>Установленные значения для запуска объявления:</strong> <br>
Необходимый охват - 25 000. <br>
Дата остановки объявления - 15.10.2018 в 10:00 <br>
Начальная ставка - 30 руб. <br>
Шаг изменения ставки - 1 руб. <br>
Максимальная стоимость перехода (eCPC) - 25 руб. <br>
