import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AbstractFormServiceService } from '../../../../shared/services/abstract-form-service.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
var IntegrationFormService = /** @class */ (function () {
    function IntegrationFormService(fb) {
        this.fb = fb;
        this.form = this.fb.group({
            name: this.fb.control('', [Validators.required]),
            leftService: this.fb.control('', [Validators.required]),
            rightService: this.fb.control('', [Validators.required]),
            vk_forms: this.fb.group({
                forms_ids: this.fb.control([]),
            }),
            mt_forms: this.fb.group({
                forms_ids: this.fb.control(null),
            }),
            amoImport: this.fb.group({}),
            amoExport: this.fb.group({
                pipelineIds: [[], [Validators.required]]
            }),
            vkRetargetingGroups: this.fb.group({
                groupIds: this.fb.control([])
            }),
            mtRemarketingGroups: this.fb.group({
                groupIds: this.fb.control([])
            })
        });
        this.formService = new AbstractFormServiceService(this.form);
        this.leftServiceChanged = this.formService.getControl('leftService').valueChanges;
        this.rightServiceChanged = this.formService.getControl('rightService').valueChanges;
        this.leftServiceChanged.subscribe(function (x) { return console.log('Left Service Changed', x); });
        this.rightServiceChanged.subscribe(function (x) { return console.log('Right Service Changed', x); });
        this.subscribeToForm();
    }
    IntegrationFormService.prototype.ngOnInit = function () {
    };
    IntegrationFormService.prototype.subscribeToForm = function () {
        this.subscribeToServicesChange();
    };
    IntegrationFormService.prototype.subscribeToServicesChange = function () {
        // combineLatest(
        //   this.leftServiceChanged,
        //   this.rightServiceChanged
        // )
        //   .subscribe(([leftService, rightService]) => {
        //     const relation = this.store.getRealtionByServiceCodes(leftService, rightService);
        //     this.form.patchValue({name: relation.name});
        //   });
    };
    IntegrationFormService.ngInjectableDef = i0.defineInjectable({ factory: function IntegrationFormService_Factory() { return new IntegrationFormService(i0.inject(i1.FormBuilder)); }, token: IntegrationFormService, providedIn: "root" });
    return IntegrationFormService;
}());
export { IntegrationFormService };
