import { MoySkladService } from '../../../api/services';
import { createCrmOrganisation } from './crm-organisation.model';
import { CrmOrganisationStore } from './crm-organisation.store';
import { PlatformsTypes } from '../../platforms-types';
import * as i0 from "@angular/core";
import * as i1 from "../../../api/services/moy-sklad.service";
import * as i2 from "./crm-organisation.store";
var CrmOrganisationService = /** @class */ (function () {
    function CrmOrganisationService(moySkladService, store) {
        this.moySkladService = moySkladService;
        this.store = store;
    }
    CrmOrganisationService.prototype.LoadOrganisations = function (type) {
        switch (type) {
            case PlatformsTypes.MOY_SKLAD_IMPORT:
            case PlatformsTypes.MOY_SKLAD_EXPORT:
                this.LoadMoiSkladOrganistations();
                break;
        }
    };
    CrmOrganisationService.prototype.LoadMoiSkladOrganistations = function () {
        var _this = this;
        this.moySkladService.GetOrganizations()
            .subscribe(function (response) {
            var organisations = response.rows;
            organisations
                .map(function (organisation) { return createCrmOrganisation(organisation); })
                .forEach(function (organisation) { return _this.store.add(organisation); });
        });
    };
    CrmOrganisationService.ngInjectableDef = i0.defineInjectable({ factory: function CrmOrganisationService_Factory() { return new CrmOrganisationService(i0.inject(i1.MoySkladService), i0.inject(i2.CrmOrganisationStore)); }, token: CrmOrganisationService, providedIn: "root" });
    return CrmOrganisationService;
}());
export { CrmOrganisationService };
