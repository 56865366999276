<div class="easy-ads-page-header">
  Оплата
</div>

<ng-container *ngIf="!selectedTariff; else showSelectedTariff">
  <ng-container *ngIf="!tariffViewModel; else tariffPlanExists">
    <div class="easy-settings-data-container">
      Загрузка...
    </div>
  </ng-container>
  <ng-template #tariffPlanExists>
    <ng-container *ngFor="let tariffPlan of tariffViewModel">
      <app-easy-tarif-configurator-card [data]="tariffPlan"
                                        (onCheckPromocode)="CheckPromocode(tariffPlan, $event)"
                                        (onTarifSelected)="SelectTariff($event)"
      ></app-easy-tarif-configurator-card>
    </ng-container>
  </ng-template>
</ng-container>
<ng-template #showSelectedTariff>
  <div class="easy-settings-data-container">
    <div class="easy-settings-data-strip">
      <div class="easy-settings-data-strip__label">
        Тариф
      </div>

      <div class="easy-settings-data-strip__content">
        <span>{{selectedTariff.title}}</span>
        <span class="easy-edit" (click)="SelectAnotherTariff()">Изменить</span>
      </div>
    </div>
  </div>

  <div class="easy-settings-data-container" style="display: none">
    <div class="easy-settings-data-strip">
      <div class="easy-settings-data-strip__label">
        Вариант
      </div>

      <div class="easy-settings-data-strip__content">
        <span>{{selectedTariff.selectedOption.title}}</span>
        <span class="easy-edit" (click)="SelectAnotherTariff()">Изменить</span>
      </div>
    </div>
  </div>

  <div class="easy-settings-data-container">
    <div class="easy-settings-data-strip">
      <div class="easy-settings-data-strip__label">
        Период
      </div>

      <div class="easy-settings-data-strip__content">
        <span>{{selectedTariff.selectedTime.title}}</span>
        <span class="easy-edit" (click)="SelectAnotherTariff()">Изменить</span>
      </div>
    </div>
  </div>

  <ng-container *ngIf="selectedPaymentSystem">
    <div class="easy-settings-data-container">
    <div class="easy-settings-data-strip">
      <div class="easy-settings-data-strip__label">
        Платежная система
      </div>

      <div class="easy-settings-data-strip__content">
        <span>{{selectedPaymentSystem.title}}</span>
        <span class="easy-edit" (click)="SelectAnotherPaymentSystem()">Изменить</span>
      </div>
    </div>
  </div>
  </ng-container>

  <div class="easy-settings-data-container">
    <div class="easy-settings-data-strip">
      <div class="easy-settings-data-strip__label">
        Стоимость
      </div>

      <div class="easy-settings-data-strip__content">
        <ng-container *ngIf="selectedTariff.appliedPromocode; else noPromocode">
          <span class="easy-price easy-price_old">{{selectedTariff.selectedTime.price | number: '1.0-2'}} руб.</span>
          <span class="easy-price">{{selectedTariff.selectedTime.price - (selectedTariff.selectedTime.price
            *selectedTariff.appliedPromocode.value) / 100 | number: '1.0-2'}} руб.</span>
          <span class="easy-price">Скидка <strong>{{selectedTariff.appliedPromocode.value}}%</strong> по промокоду <i>{{selectedTariff.appliedPromocode.code}}</i></span>
        </ng-container>
        <ng-template #noPromocode>
          <span class="easy-price">{{selectedTariff.selectedTime.price | number: '1.0-2'}} руб.</span>
        </ng-template>
      </div>
    </div>
  </div>

  <ng-container *ngIf="!selectedPaymentSystem; else paymentSystemSelected">
    <!--<app-easy-tarif-configurator-card [data]="selectedTariff"-->
                                      <!--[disableOptionSelection]="true"-->
                                      <!--[disableTimeSelection]="true"-->
                                      <!--[disablePromocodeSelection]="true"-->
                                      <!--[showSelectTariffButton]="false"-->
    <!--&gt;</app-easy-tarif-configurator-card>-->

    <ng-container *ngIf="paymentSystems">
      <div class="easy-settings-data-container">
        <div class="easy-systems-list">
          <ng-container *ngFor="let system of paymentSystems">
            <div class="easy-systems-list__item">
              <app-easy-payment-system [system]=system>
                <button class="vk-standart-button" (click)="SelectPaymentSystem(system)">Выбрать</button>
              </app-easy-payment-system>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <ng-template #paymentSystemSelected>
    <div class="easy-settings-data-container">
      <ng-container *ngIf="paymentIsRequesting; else paymentRequestCompleted">
        <span>Подождите, подготавливается платеж...</span>
      </ng-container>
      <ng-template #paymentRequestCompleted>
        <ng-container *ngIf="!paymentRequested; else paymentAlreadyRequested">
          <button class="vk-standart-button" (click)="ConfirmSelectionAndRequestPayment()">
            Подтвердить
          </button>
        </ng-container>
        <ng-template #paymentAlreadyRequested>
          <a [href]="securePaymentUrl" target="_blank" rel="nofollow noopener"
             onclick="ym(45402093, 'reachGoal', 'easy-payments-create-confirm-button-click');">
            Перейти к оплате
          </a>
        </ng-template>
      </ng-template>
    </div>
  </ng-template>
</ng-template>
