import {MultipleCopyingData} from '../models/multiple-copying-data';
import {PostMonitorBackend_V2ModelsAdManagerCopyAdsViewModel} from '../../../api/models/post-monitor-backend-_v2models-ad-manager-copy-ads-view-model';
import {AccountData} from '../../ad-manager/models/account-data';

export abstract class AbstractCopyingConfigurator {

  protected accountData: AccountData;
  protected targetAccountData: AccountData;

  protected constructor(
    protected copyingData: MultipleCopyingData
  ) {
    console.log(copyingData, 'COPYING DATA');
    const [accountId, clientId] = copyingData.accountId.split('_')
      .map(x => x !== 'null' ? parseInt(x, 10) : null);

    const [targetAccountId, targetClientId] = copyingData.targetAccountId.split('_')
      .map(x => x !== 'null' ? parseInt(x, 10) : null);

    this.accountData = new AccountData(accountId, clientId);
    this.targetAccountData = new AccountData(targetAccountId, targetClientId);

    console.log(this.accountData, this.targetAccountData, 'ACCOUNTS DATA');
  }

  public abstract generate(): PostMonitorBackend_V2ModelsAdManagerCopyAdsViewModel[];

  protected generateDefeault(): PostMonitorBackend_V2ModelsAdManagerCopyAdsViewModel {
    return {
      accountId: this.accountData.accountId,
      clientId: this.accountData.clientId,
      targetAccountId: this.targetAccountData.accountId,
      targetClientId: this.targetAccountData.clientId
      ,
      campaignId: this.copyingData.campaignId,
      targetCampaignId: this.copyingData.targetCampaignId,
      adIds: this.copyingData.adIds,
      countOfCopies: 1,
      costType: this.copyingData.costType,
      costValue: this.copyingData.costValue ? parseFloat(this.copyingData.costValue.toString().replace(',', '.')) : null,
      numberOfCopy: 1,
      // Не используются в стандартной стратегии копирования
      ageFrom: null,
      ageTo: null,
      groupId: null,
      groupsNot: null,
      retargetingGroup: null,
      retargetingGroupNot: null,
      sex: null,
      linkUrl: this.generateLink()
    };
  }

  protected generateLink() {
    let link = null;
    if (this.copyingData.generateLinks) {
      link = this.copyingData.linkSettings.linksData.host;
      if (link.substr(link.length - 1) !== '/') {
        link = link + '/';
      }
      const linkQueryParams = this.copyingData.linkSettings.linksData.linkQueryParams.map(({name, value}) => `${name}=${value}`).join('&');
      link = `${link}?${linkQueryParams}`.replace('/?', '?');
    }
    return link;
  }
}

export enum CopyingType {
  DEFAULT_COPYING = 'DEFAULT_COPYING',
  BY_GROUPS = 'BY_GROUPS',
  BY_GROUPS_FILE = 'BY_GROUPS_FILE',
  BY_SEX_AND_AGE = 'BY_SEX_AND_AGE',
  BY_RETARGETING_GROUPS = 'BY_RETARGETING_GROUPS',
  BY_ACTIVE_GROUPS = 'BY_ACTIVE_GROUPS',
  BY_ACTIVE_GROUPS_FILES = 'BY_ACTIVE_GROUPS_FILES',
  BY_INTEREST_CATEGORIES = 'BY_INTEREST_CATEGORIES',
  BY_INTEREST_CATEGORIES_CHECKBOXES_TREE = 'BY_INTEREST_CATEGORIES_CHECKBOXES_TREE',
  BY_LINK_SEGMENTATION = 'BY_LINK_SEGMENTATION'
}
