import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { CurrencyPipe } from '@angular/common';
var MultipleEditSuccessComponent = /** @class */ (function () {
    function MultipleEditSuccessComponent(data, dialogRef) {
        this.data = data;
        this.dialogRef = dialogRef;
        this.filteredKeys = [
            'accountId',
            'campaignId',
            'adIds'
        ];
        this.keyTitles = {
            'all_limit': 'Общий лимит ',
            'day_limit': 'Дневной лимит',
            'cpm': 'CPM',
            'cpc': 'CPC',
            'status': 'Статус'
        };
        this.status = {
            0: 'Остановлены',
            1: 'Запущены'
        };
        this.modifyFunctions = {
            BID_MIN: function (value) { return parseFloat(value) * 100; },
            BID_START: function (value) { return parseFloat(value) * 100; },
            BID_END: function (value) { return parseFloat(value) * 100; },
            BID_MAX: function (value) { return parseFloat(value) * 100; },
            BID_STEP: function (value) { return parseFloat(value) * 100; },
            ECPC_LIMIT: function (value) { return parseFloat(value) * 100; },
            CUSTOM_OVERALL_LIMIT: function (value) { return parseFloat(value) * 100; },
        };
        this.displayFunctions = {
            BID_MIN: function (value) { return parseFloat(value) / 100; },
            BID_MAX: function (value) { return parseFloat(value) / 100; },
            BID_START: function (value) { return parseFloat(value) / 100; },
            BID_END: function (value) { return parseFloat(value) / 100; },
            BID_STEP: function (value) { return parseFloat(value) / 100; },
            ECPC_LIMIT: function (value) { return parseFloat(value) / 100; },
            CUSTOM_OVERALL_LIMIT: function (value) { return parseFloat(value) / 100; },
        };
    }
    Object.defineProperty(MultipleEditSuccessComponent.prototype, "Data", {
        get: function () {
            var _this = this;
            console.log(this.data.formValue);
            var properties = Object.keys(this.data.formValue)
                .filter(function (key) { return key !== '' && key !== undefined && key !== null; })
                .filter(function (key) { return !_this.filteredKeys.includes(key); })
                .filter(function (key) { return _this.data.formValue[key] !== null; })
                .map(function (key) { return ({ key: _this.keyTitles[key], value: _this.transform(key, _this.data.formValue[key]) }); })
                .filter(function (_a) {
                var key = _a.key;
                console.log(key !== undefined, key !== null);
                return key !== undefined && key !== null;
            });
            console.log(properties);
            return properties;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MultipleEditSuccessComponent.prototype, "Strategy", {
        get: function () {
            return this.data.strategyInputs;
        },
        enumerable: true,
        configurable: true
    });
    MultipleEditSuccessComponent.prototype.ngOnInit = function () {
    };
    MultipleEditSuccessComponent.prototype.transform = function (key, value) {
        console.log(key, value);
        switch (key) {
            case 'status':
                return this.status[value];
            case 'all_limit':
            case 'day_limit':
            case 'cpm':
            case 'cpc':
                return new CurrencyPipe('ru').transform(value, ' Руб.');
        }
    };
    MultipleEditSuccessComponent.prototype.Close = function () {
        this.dialogRef.close();
    };
    return MultipleEditSuccessComponent;
}());
export { MultipleEditSuccessComponent };
