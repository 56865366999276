/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./affiliate-tile.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./affiliate-tile.component";
var styles_AffiliateTileComponent = [i0.styles];
var RenderType_AffiliateTileComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AffiliateTileComponent, data: {} });
export { RenderType_AffiliateTileComponent as RenderType_AffiliateTileComponent };
function View_AffiliateTileComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "affiliate-tile__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 2, 0, currVal_0); }); }
export function View_AffiliateTileComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "affiliate-tile affiliate-tile_primary"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AffiliateTileComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "affiliate-tile__content"]], null, null, null, null, null)), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_AffiliateTileComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-affiliate-tile", [], null, null, null, View_AffiliateTileComponent_0, RenderType_AffiliateTileComponent)), i1.ɵdid(1, 114688, null, 0, i3.AffiliateTileComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AffiliateTileComponentNgFactory = i1.ɵccf("app-affiliate-tile", i3.AffiliateTileComponent, View_AffiliateTileComponent_Host_0, { title: "title", value: "value" }, {}, ["*"]);
export { AffiliateTileComponentNgFactory as AffiliateTileComponentNgFactory };
