import {Injectable} from '@angular/core';
import {QueryEntity} from '@datorama/akita';
import {PlatformsRelationsStore, PlatformsRelationsState} from './platforms-relations.store';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class PlatformsRelationsQuery extends QueryEntity<PlatformsRelationsState> {

  public relatedPlatformsIds = (originPlatformId) => this
    .selectAll({filterBy: entity => entity.integrationServiceFromId === originPlatformId})
    .pipe(
      map(x => x.map(y => y.integrationServiceToId))
    );

  public RelationPlatformsName = (origin, dest) => this.selectAll({filterBy: e => e.integrationServiceToId === dest && e.integrationServiceFromId === origin})
    .pipe(
      map(rel => rel[0]),
      map(rel => rel ? rel.name : null)
    );

  constructor(protected store: PlatformsRelationsStore) {
    super(store);
  }

}
