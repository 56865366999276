/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./payments-table.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../payments-tariff-header/payments-tariff-header.component.ngfactory";
import * as i3 from "../payments-tariff-header/payments-tariff-header.component";
import * as i4 from "../../services/payments.service";
import * as i5 from "../payments-tariff-feature-status/payments-tariff-feature-status.component.ngfactory";
import * as i6 from "../payments-tariff-feature-status/payments-tariff-feature-status.component";
import * as i7 from "@angular/common";
import * as i8 from "../payments-tariff-time-periods-selector/payments-tariff-time-periods-selector.component.ngfactory";
import * as i9 from "../payments-tariff-time-periods-selector/payments-tariff-time-periods-selector.component";
import * as i10 from "./payments-table.component";
var styles_PaymentsTableComponent = [i0.styles];
var RenderType_PaymentsTableComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PaymentsTableComponent, data: {} });
export { RenderType_PaymentsTableComponent as RenderType_PaymentsTableComponent };
function View_PaymentsTableComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "th", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-payments-tariff-header", [], null, null, null, i2.View_PaymentsTariffHeaderComponent_0, i2.RenderType_PaymentsTariffHeaderComponent)), i1.ɵdid(3, 114688, null, 0, i3.PaymentsTariffHeaderComponent, [i4.PaymentsService], { tariff: [0, "tariff"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 3, 0, currVal_0); }, null); }
function View_PaymentsTableComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-payments-tariff-feature-status", [], null, null, null, i5.View_PaymentsTariffFeatureStatusComponent_0, i5.RenderType_PaymentsTariffFeatureStatusComponent)), i1.ɵdid(3, 114688, null, 0, i6.PaymentsTariffFeatureStatusComponent, [i4.PaymentsService], { tariff: [0, "tariff"], feature: [1, "feature"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; var currVal_1 = _v.parent.context.$implicit; _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
function View_PaymentsTableComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "td", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_PaymentsTableComponent_3)), i1.ɵdid(4, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i7.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform(_co.tariffPlans)); _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.name); _ck(_v, 2, 0, currVal_0); }); }
export function View_PaymentsTableComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "table", [["class", "payments-table"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "thead", [["class", "payments-table__header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "th", [["class", "payments-table__cell_header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-payments-tariff-time-periods-selector", [], null, null, null, i8.View_PaymentsTariffTimePeriodsSelectorComponent_0, i8.RenderType_PaymentsTariffTimePeriodsSelectorComponent)), i1.ɵdid(5, 114688, null, 0, i9.PaymentsTariffTimePeriodsSelectorComponent, [i4.PaymentsService], null, null), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_PaymentsTableComponent_1)), i1.ɵdid(7, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i7.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(9, 0, null, null, 3, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_PaymentsTableComponent_2)), i1.ɵdid(11, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i7.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; _ck(_v, 5, 0); var currVal_0 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform(_co.tariffPlans)); _ck(_v, 7, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 11, 0, i1.ɵnov(_v, 12).transform(_co.features)); _ck(_v, 11, 0, currVal_1); }, null); }
export function View_PaymentsTableComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-payments-table", [], null, null, null, View_PaymentsTableComponent_0, RenderType_PaymentsTableComponent)), i1.ɵdid(1, 114688, null, 0, i10.PaymentsTableComponent, [i4.PaymentsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PaymentsTableComponentNgFactory = i1.ɵccf("app-payments-table", i10.PaymentsTableComponent, View_PaymentsTableComponent_Host_0, {}, {}, []);
export { PaymentsTableComponentNgFactory as PaymentsTableComponentNgFactory };
