import {ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {PlatformsTypes} from '../../../../platforms-types';
import {FormBuilder, FormGroup} from '@angular/forms';
import {AkitaNgFormsManager} from '@datorama/akita-ng-forms-manager';
import {CrmPipelinesQuery} from '../../../../stores/crm-pipelines/crm-pipelines.query';
import {CrmPipelinesService} from '../../../../stores/crm-pipelines/crm-pipelines.service';
import {AssignationPlatforms} from '../../../../stores/platform-assignations/platform-assignation.model';
import {PlatformAssignationsQuery} from '../../../../stores/platform-assignations/platform-assignations.query';
import {PlatformsQuery} from '../../../../stores/platforms/platforms.query';
import {CrmUsersQuery} from '../../../../stores/crm-users/crm-users.query';
import {CrmUsersService} from '../../../../stores/crm-users/crm-users.service';
import {untilDestroyed} from 'ngx-take-until-destroy';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators';
import {BitrixService} from '../../../../../api/services';
import {PlatformsService} from '../../../../stores/platforms/platforms.service';
import {CrmPipeline} from '../../../../stores/crm-pipelines/crm-pipeline.model';

@Component({
    selector: 'app-export-crm',
    templateUrl: './export-crm.component.html',
    styleUrls: ['./export-crm.component.scss']
})
export class ExportCrmComponent implements OnInit, OnDestroy, OnChanges {

    @Input() public type: PlatformsTypes;
    @Input() public platform: AssignationPlatforms;

    public form: FormGroup;
    public isSelectedLeads = false;
    public pipelines$ = this.pipelinesQuery.pipelines$
        .pipe(
            untilDestroyed(this)
        );
    public users$ = this.crmUsersQuery.users$;
    public loading: boolean = false;

    public isAssigned: boolean = false;

    public countOfPipelines = 0;
    public firstPipelineId = null;

    public get pipelineLabel() {
        if (this.countOfPipelines > 1) {
            return 'Выбрать воронку, из которой выгружать контакты';
        } else {
            return 'Передавать данные контактов из воронки продаж';
        }
    }

    public get pipelineLabelSuggestionCode() {
        if (this.countOfPipelines > 1) {
            return 'AMO_BY_PIPELINES';
        } else {
            return 'BY_PIPELINES';
        }
    }

    public get LeadValue() {
        switch (this.type) {
            case PlatformsTypes.AMO_CRM_EXPORT:
            default:
                return 'LEADS';
            case PlatformsTypes.MEGAPLAN_EXPORT:
                return 'DEALS';
            case PlatformsTypes.UON_EXPORT:
                const sectionCode = this.pipelinesQuery.pipelineSectionCodeById(this.form.get('selectedPipelineId').value);
                return sectionCode === 'CONTACTS' ? 'DEALS' : sectionCode;
        }
    }

    constructor(
        private fb: FormBuilder,
        private fm: AkitaNgFormsManager,
        private pipelinesQuery: CrmPipelinesQuery,
        private pipelinesService: CrmPipelinesService,
        private assignationQuery: PlatformAssignationsQuery,
        private platformsQuery: PlatformsQuery,
        private cd: ChangeDetectorRef,
        private crmUsersQuery: CrmUsersQuery,
        private crmUsersService: CrmUsersService,
        private bitrixService: BitrixService,
        private platformsService: PlatformsService
    ) {
    }

    ngOnInit() {
        this.loading = true;

        if (this.type === PlatformsTypes.UON_EXPORT) {
            this.pipelines$ = this.pipelines$.pipe(
                map(x => x.filter(y => y.type === 'DEALS'))
            );
        }

        this.pipelines$.subscribe(data => {
            this.countOfPipelines = data.length;
            if (data.length > 0) {
                this.firstPipelineId = data[0].id;
            }
        });

        this.assignationQuery
            .assingnationStatus$(this.platform)
            .pipe(
                distinctUntilChanged(),
                untilDestroyed(this)
            )
            .subscribe(data => {
                this.isAssigned = data;
                if (data) {
                    this.HandleChanges();
                    this.pipelinesService.LoadPipelines(this.type);
                }
            });

        setTimeout(() => {
            this.HandleChanges();
            this.loading = false;
        }, 1000);
    }

    ngOnDestroy(): void {
        this.fm.unsubscribe('originPlatformSettings');
    }

    public initForm() {
        if (this.isAssigned) {
            this.fm.unsubscribe('originPlatformSettings');
            this.loading = true;
            this.form = null;
            this.form = this.fb.group({
                section: this.fb.control('CONTACTS'),
                isClassicMode: this.fb.control(PlatformsTypes.UON_EXPORT === this.type),
                selectedPipelineId: this.fb.control(null)
            });
            this.ChangeDetection();
            this.form.get('section').setValue('CONTACTS');

            this.form
                .get('selectedPipelineId')
                .valueChanges
                .pipe(
                    distinctUntilChanged(),
                    debounceTime(1000)
                )
                .subscribe(selectedPipleineId => {
                    const sectionCode = this.pipelinesQuery.pipelineSectionCodeById(selectedPipleineId);
                    console.log(sectionCode, 'WTF ???');
                    this.form.get('section').patchValue(sectionCode, {emitEvent: false});
                });

            this.form.get('section').valueChanges
                .pipe(
                    distinctUntilChanged(),
                    untilDestroyed(this)
                )
                .subscribe(section => {
                    switch (this.type) {
                        case PlatformsTypes.BITRIX_EXPORT:
                            this.isSelectedLeads = section === 'DEALS';
                            break;
                        case PlatformsTypes.UON_EXPORT:
                            this.form.patchValue({isClassicMode: true});
                            if (section === 'UON_MARKS_EXPORT') {
                                this.platformsService.SetParameters(AssignationPlatforms.UON, {hasLeads: false});
                                this.form.get('selectedPipelineId').patchValue(3, {onlySelf: false, emitEvent: false});
                            } else {
                                this.platformsService.SetParameters(AssignationPlatforms.UON, {hasLeads: true});
                            }
                            break;
                        // tslint:disable-next-line:no-switch-case-fall-through
                        default:
                            this.isSelectedLeads = section === 'LEADS';
                            break;
                    }
                    if (section === 'CONTACTS') {
                        this.form.get('selectedPipelineId').setValue(null);
                    } else if (section === 'UON_MARKS_EXPORT') {
                        // this.form.get('selectedPipelineId').setValue(3, {onlySelf: false, emitEvent: false});
                    } else {
                        this.form.get('selectedPipelineId').setValue(this.firstPipelineId);
                    }
                });

            this.pipelines$.subscribe((data: CrmPipeline[]) => {
                console.log(`Count of pipelines ${data.length}`);
                if (this.type === PlatformsTypes.UON_EXPORT) {
                    const deals = data.find(x => x.type === 'DEALS');
                    this.form.patchValue({selectedPipelineId: deals ? deals.id : null});
                } else {
                    this.form.patchValue({selectedPipelineId: data.length === 1 ? data[0].id : null});
                }
                this.loading = false;
            });
            this.fm.upsert('originPlatformSettings', this.form);
            this.form.patchValue({
                section: 'CONTACTS'
            });
        }
    }

    private ChangeDetection() {
        if (!this.cd['destroyed']) {
            this.cd.detectChanges();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.platform) {
            this.CheckAssignation();
            if (this.isAssigned) {
                this.HandleChanges();
            }
        }
    }

    private CheckAssignation() {
        const assignationsData = this.assignationQuery.getAll({filterBy: entity => entity.id === this.platform});
        if (assignationsData && assignationsData.length > 0) {
            this.isAssigned = assignationsData[0].assignationStatus;
        }
    }

    private HandleChanges() {
        this.loading = true;
        this.ChangeDetection();
        if (this.form) {
            this.form.patchValue({section: 'CONTACTS', selectedPipelineId: null});
            this.loading = false;
        } else {
            this.initForm();
            this.loading = false;
        }
    }
}
