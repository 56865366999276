import {Component, OnInit} from '@angular/core';
import {AffiliateUserInfoService} from "../../../../../storage/affiliate-user-info/affiliate-user-info.service";

@Component({
  selector: 'app-affiliate-program-settings-user-info-route',
  templateUrl: './affiliate-program-settings-user-info-route.component.html',
  styleUrls: ['./affiliate-program-settings-user-info-route.component.scss']
})
export class AffiliateProgramSettingsUserInfoRouteComponent implements OnInit {

  constructor(
    private affiliateUserInfoService: AffiliateUserInfoService
  ) {
  }

  ngOnInit() {
    this.affiliateUserInfoService.LoadUserData();
  }

}
