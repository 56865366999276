import {Injectable} from '@angular/core';
import {QueryEntity} from '@datorama/akita';
import {PlatformAssignationsState, PlatformAssignationsStore} from './platform-assignations.store';
import {distinctUntilChanged, filter, flatMap, map} from 'rxjs/operators';
import {PlatformsTypes} from '../../platforms-types';
import {AssignationPlatforms} from './platform-assignation.model';
import {of} from 'rxjs';

@Injectable({providedIn: 'root'})
export class PlatformAssignationsQuery extends QueryEntity<PlatformAssignationsState> {

  /**
   * Проверка подключена ли данная платформа
   * @param platformId
   */
  public isAssigned$ = (platformId: AssignationPlatforms) => this
    .selectAll({filterBy: entity => entity.id === platformId})
    .pipe(
      distinctUntilChanged(),
      // debounceTime(3000),
      map(x => x[0]),
      map(x => x ? x.assignationStatus : false),
      filter(x => x === true),
    )

  public assingnationStatus$ = (platformId: AssignationPlatforms) => this
    .selectAll({filterBy: entity => entity.id === platformId})
    .pipe(
      distinctUntilChanged(),
      map(x => x[0]),
      map(x => x ? x.assignationStatus : false)
    );


  /**
   * Аодключена ли платформа с кодом
   * @param platformCode
   */
  public isPlatformAssigned$ = (platformCode: PlatformsTypes) => {
    return of(this.platform(platformCode))
        .pipe(
            flatMap(platform => this.isAssigned$(platform)),
        );
  }

  constructor(protected store: PlatformAssignationsStore) {
    super(store);
  }

  public platform(code) {
    switch (code) {
      case PlatformsTypes.AMO_CRM_IMPORT:
      case PlatformsTypes.AMO_CRM_EXPORT:
        return AssignationPlatforms.AMOCRM;
      case PlatformsTypes.BITRIX_EXPORT:
      case PlatformsTypes.BITRIX_IMPORT:
        return AssignationPlatforms.BITRIX24;
      case PlatformsTypes.MEGAPLAN_EXPORT:
      case PlatformsTypes.MEGAPLAN_IMPORT:
        return AssignationPlatforms.MEGAPLAN;
      case PlatformsTypes.MT_LEAD_FORMS:
      case PlatformsTypes.MT_REMARKETING_GROUPS:
        return AssignationPlatforms.MY_TAREGT;
      case PlatformsTypes.VK_LEAD_FORMS:
      case PlatformsTypes.VK_RETARGETING_GROUPS:
        return AssignationPlatforms.VK;
      case PlatformsTypes.UON_IMPORT:
      case PlatformsTypes.UON_EXPORT:
        return AssignationPlatforms.UON;
      case PlatformsTypes.FB_CUSTOM_AUDIENCES:
      case PlatformsTypes.FB_LEAD_FORMS:
        return AssignationPlatforms.FACEBOOK;
      // case PlatformsTypes.MOY_SKLAD_EXPORT:
      // case PlatformsTypes.MOY_SKLAD_IMPORT:
      //   return AssignationPlatforms.MOI_SKLAD;
    }
  }

}
