Операция выполняется, подождите...


<app-new-style-modal [isVisible]="showWarningModal">
  <div class="new-modal-header-content">
    Важно!
  </div>
  <div class="new-modal-body-content">
    <ng-container *ngIf="CheckExperienceLevelMatch(1,1)">
      Вы переходите в раздел для работы с рекламой ВКонтакте для Новичков.<br>
      <br>
      Для возвращения в функционал для Профессионалов, необходимо нажать в верхнем правом углу “<strong>Для профессионалов</strong>”.
    </ng-container>
    <ng-container *ngIf="CheckExperienceLevelMatch(1,2)">
      Вы переходите в раздел для работы с рекламой ВКонтакте и работе с интеграциями для Профессионалов. <br><br>
      Для возвращения в функционал для Новичков, необходимо нажать в верхнем правом углу “<strong>Для новичков</strong>”

<!--      Вы переходите в раздел для работы с рекламой ВКонтакте для Профессионалов.<br>-->
<!--      <br>-->
<!--      Для возвращения в функционал для Новичков, необходимо нажать в верхнем правом углу “<strong>Для новичков</strong>”.-->
    </ng-container>
  </div>
  <div class="new-modal-buttons">
    <button class="vk-standart-button" (click)="Accept()">
      Понятно
    </button>
  </div>
</app-new-style-modal>
