/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./personal-cabinet.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./personal-cabinet.component";
var styles_PersonalCabinetComponent = [i0.styles];
var RenderType_PersonalCabinetComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PersonalCabinetComponent, data: {} });
export { RenderType_PersonalCabinetComponent as RenderType_PersonalCabinetComponent };
function View_PersonalCabinetComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [["class", "btn btn-primary btn-block btn-sm"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u041F\u043E\u0434\u043A\u043B\u044E\u0447\u0438\u0442\u044C"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ("https://postmonitor.ru" + _co.Link); _ck(_v, 1, 0, currVal_0); }); }
function View_PersonalCabinetComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" \u0412\u044B \u043F\u043E\u0434\u043A\u043B\u044E\u0447\u0435\u043D\u044B "]))], null, null); }
export function View_PersonalCabinetComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "card-media-block personal-cabinet__media"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "card-block personal-cabinet__description"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "card-footer personal-cabinet__status"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PersonalCabinetComponent_1)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["loggedIn", 2]], null, 0, null, View_PersonalCabinetComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_2 = !_co.IsLoggedIn; var currVal_3 = i1.ɵnov(_v, 7); _ck(_v, 6, 0, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ("card personal-cabinet personal-cabinet_" + _co.cabinet.provider); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.cabinet.description; _ck(_v, 3, 0, currVal_1); }); }
export function View_PersonalCabinetComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-personal-cabinet", [], null, null, null, View_PersonalCabinetComponent_0, RenderType_PersonalCabinetComponent)), i1.ɵdid(1, 114688, null, 0, i3.PersonalCabinetComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PersonalCabinetComponentNgFactory = i1.ɵccf("app-personal-cabinet", i3.PersonalCabinetComponent, View_PersonalCabinetComponent_Host_0, { cabinet: "cabinet", IsLoggedIn: "IsLoggedIn", Link: "Link" }, {}, []);
export { PersonalCabinetComponentNgFactory as PersonalCabinetComponentNgFactory };
