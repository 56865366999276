import {ImageValidationViewModel} from './image-validation.view-model';

export interface IAdImageViewModel {
  name: string;
  content: any;
}

export class AdImageViewModel implements IAdImageViewModel {

  public ImageValidationStatus: ImageValidationViewModel;

  constructor(
    public name: string,
    public content: any,
    public width: number,
    public height: number,
    public size: number
  ) {
  }

  public isValid(minWidth: number, minHeight: number, maxImageSize: number): boolean {
    this.ImageValidationStatus = new ImageValidationViewModel(this.width >= minWidth, this.height >= minHeight, this.size <= maxImageSize);
    return this.ImageValidationStatus.status;
  }

  public Errors(minWidth: number, minHeight: number, maxImageSize: number) {
    let errors = '';
    if (!this.ImageValidationStatus.width) {
      errors += `Минимальная длинна изображения ${minWidth}\n`;
    }
    if (!this.ImageValidationStatus.height) {
      errors += `Минимальная высота изображения ${minHeight}\n`;
    }
    if (!this.ImageValidationStatus.size) {
      errors += `${this.size} Максимальный размер изображения ${maxImageSize / 1024 / 1024} Mb\n`;
    }
    return errors;
  }

}
