export class DateFilterViewModel {

    public dateFrom: string; // YYYY-MM-DD OR YYYY-MM
    public dateTo: string;  // YYYY-MM-DD OR YYYY-MM
    public period: string;


    constructor(dateFrom: string, dateTo: string, period: string) {
        this.dateFrom = dateFrom;
        this.dateTo = dateTo;
        this.period = period;
    }
}
