import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {CreateAdComponent} from './routes/create-ad/create-ad.component';
import {UpdateAdComponent} from './routes/update-ad/update-ad.component';
import {AutomationWrapperComponent} from '../../shared/components/automation-wrapper/automation-wrapper.component';
import {NewCreateAdComponent} from './routes/new-create-ad/new-create-ad.component';
import {CreateAdLiteComponent} from './routes/lite/create-ad/create-ad-lite.component';
import {UpdateAdLiteComponent} from './routes/lite/update-ad-lite/update-ad-lite.component';

const routes: Routes = [
  {
    path: 'automation',
    component: AutomationWrapperComponent,
    children: [
      {
        path: 'ad-manager',
        children: [
          {
            path: '',
            component: UpdateAdComponent
          },
          {
            path: 'create',
            component: CreateAdComponent
          },
          {
            path: 'create-new',
            component: NewCreateAdComponent
          },
          {
            path: 'create-ad-lite',
            component: CreateAdLiteComponent
          },
          {
            path: 'update-ad-lite',
            component: UpdateAdLiteComponent
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdManagerRoutingModule { }
