/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./chat-form.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../chat-message/chat-message.component.ngfactory";
import * as i4 from "../chat-message/chat-message.component";
import * as i5 from "@angular/forms";
import * as i6 from "./chat-form.component";
import * as i7 from "../../services/chat-conversation-messages.service";
var styles_ChatFormComponent = [i0.styles];
var RenderType_ChatFormComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ChatFormComponent, data: {} });
export { RenderType_ChatFormComponent as RenderType_ChatFormComponent };
function View_ChatFormComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u0417\u0430\u0433\u0440\u0443\u0437\u043A\u0430 \u0438\u0441\u0442\u043E\u0440\u0438\u0438 \u043F\u0435\u0440\u0435\u043F\u0438\u0441\u043A\u0438... "]))], null, null); }
function View_ChatFormComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" \u0421\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u044F \u043D\u0435 \u043C\u043E\u0433\u0443\u0442 \u0431\u044B\u0442\u044C \u0437\u0430\u0433\u0440\u0443\u0436\u0435\u043D\u044B "]))], null, null); }
function View_ChatFormComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "chat-messages-list chat-messages-list_reverse"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "placeholder-text"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChatFormComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["userBanned", 2]], null, 0, null, View_ChatFormComponent_3))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.conversationMessagesService.isBanned; var currVal_1 = i1.ɵnov(_v, 5); _ck(_v, 4, 0, currVal_0, currVal_1); }, null); }
function View_ChatFormComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [], null, null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { "chat-messages-list__item": 0, "chat-messages-list__item_my-message": 1, "chat-messages-list__item_not-my-message": 2 }), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-chat-message", [], null, null, null, i3.View_ChatMessageComponent_0, i3.RenderType_ChatMessageComponent)), i1.ɵdid(5, 114688, null, 0, i4.ChatMessageComponent, [], { showReadStatus: [0, "showReadStatus"], message: [1, "message"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, true, _v.context.$implicit.isMyMessage, !_v.context.$implicit.isMyMessage); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.conversationWithUserId !== null); var currVal_2 = _v.context.$implicit; _ck(_v, 5, 0, currVal_1, currVal_2); }, null); }
function View_ChatFormComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "chat-messages-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChatFormComponent_6)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.GetMessasges(); _ck(_v, 3, 0, currVal_0); }, null); }
function View_ChatFormComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "chat-messages-list chat-messages-list_reverse"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "placeholder-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u0415\u0441\u043B\u0438 \u0443 \u0432\u0430\u0441 \u0435\u0441\u0442\u044C \u0432\u043E\u043F\u0440\u043E\u0441\u044B \u043F\u043E \u0440\u0430\u0431\u043E\u0442\u0435 \u0441 \u0441\u0435\u0440\u0432\u0438\u0441\u043E\u043C, \u0442\u043E \u0432\u044B \u043C\u043E\u0436\u0435\u0442\u0435 \u0437\u0430\u0434\u0430\u0442\u044C \u0438\u0445 \u0437\u0434\u0435\u0441\u044C."])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u0427\u0442\u043E\u0431\u044B \u0437\u0430\u0434\u0430\u0442\u044C \u0432\u043E\u043F\u0440\u043E\u0441 \u0432\u0432\u0435\u0434\u0438\u0442\u0435 \u0442\u0435\u043A\u0441\u0442 \u0432 \u043F\u043E\u043B\u0435 \u0432\u0432\u043E\u0434\u0430. "]))], null, null); }
function View_ChatFormComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChatFormComponent_5)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["noMessages", 2]], null, 0, null, View_ChatFormComponent_7))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.MessagesExists(); var currVal_1 = i1.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_ChatFormComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u041F\u0440\u0438\u0447\u0438\u043D\u0430:"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "i", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.conversationMessagesService.banReason; _ck(_v, 5, 0, currVal_0); }); }
function View_ChatFormComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "chat-ban-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "vk-container"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u0412\u044B \u0431\u044B\u043B\u0438 \u0437\u0430\u0431\u043B\u043E\u043A\u0438\u0440\u043E\u0432\u0430\u043D\u044B "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChatFormComponent_9)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.conversationMessagesService.banReason; _ck(_v, 5, 0, currVal_0); }, null); }
function View_ChatFormComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "vk-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "textarea", [["autofocus", ""], ["class", "vk-textarea vk-textarea_full-width"], ["maxlength", "2048"], ["minlength", "1"], ["placeholder", "\u0412\u0432\u0435\u0434\u0438\u0442\u0435 \u0442\u0435\u043A\u0441\u0442 \u0441\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u044F..."], ["rows", "3"]], [[1, "minlength", 0], [1, "maxlength", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 2)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.formMessage = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i5.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i5.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵdid(3, 540672, null, 0, i5.MinLengthValidator, [], { minlength: [0, "minlength"] }, null), i1.ɵdid(4, 540672, null, 0, i5.MaxLengthValidator, [], { maxlength: [0, "maxlength"] }, null), i1.ɵprd(1024, null, i5.NG_VALIDATORS, function (p0_0, p1_0) { return [p0_0, p1_0]; }, [i5.MinLengthValidator, i5.MaxLengthValidator]), i1.ɵprd(1024, null, i5.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.DefaultValueAccessor]), i1.ɵdid(7, 671744, null, 0, i5.NgModel, [[8, null], [6, i5.NG_VALIDATORS], [8, null], [6, i5.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i5.NgControl, null, [i5.NgModel]), i1.ɵdid(9, 16384, null, 0, i5.NgControlStatus, [[4, i5.NgControl]], null, null), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "vk-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "button", [["class", "vk-standart-button vk-standart-button_full-width"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.SendMessage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u041E\u0442\u043F\u0440\u0430\u0432\u0438\u0442\u044C "]))], function (_ck, _v) { var _co = _v.component; var currVal_9 = "1"; _ck(_v, 3, 0, currVal_9); var currVal_10 = "2048"; _ck(_v, 4, 0, currVal_10); var currVal_11 = _co.formMessage; _ck(_v, 7, 0, currVal_11); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 3).minlength ? i1.ɵnov(_v, 3).minlength : null); var currVal_1 = (i1.ɵnov(_v, 4).maxlength ? i1.ɵnov(_v, 4).maxlength : null); var currVal_2 = i1.ɵnov(_v, 9).ngClassUntouched; var currVal_3 = i1.ɵnov(_v, 9).ngClassTouched; var currVal_4 = i1.ɵnov(_v, 9).ngClassPristine; var currVal_5 = i1.ɵnov(_v, 9).ngClassDirty; var currVal_6 = i1.ɵnov(_v, 9).ngClassValid; var currVal_7 = i1.ɵnov(_v, 9).ngClassInvalid; var currVal_8 = i1.ɵnov(_v, 9).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }); }
export function View_ChatFormComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "chat-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "chat"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "chat__messages"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChatFormComponent_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["messagesLoaded", 2]], null, 0, null, View_ChatFormComponent_4)), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [["class", "chat__controls"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChatFormComponent_8)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["notBanned", 2]], null, 0, null, View_ChatFormComponent_10))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.IsLoading(); var currVal_1 = i1.ɵnov(_v, 5); _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_2 = _co.conversationMessagesService.isBanned; var currVal_3 = i1.ɵnov(_v, 9); _ck(_v, 8, 0, currVal_2, currVal_3); }, null); }
export function View_ChatFormComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-chat-form", [], null, null, null, View_ChatFormComponent_0, RenderType_ChatFormComponent)), i1.ɵdid(1, 245760, null, 0, i6.ChatFormComponent, [i7.ChatConversationMessagesService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ChatFormComponentNgFactory = i1.ɵccf("app-chat-form", i6.ChatFormComponent, View_ChatFormComponent_Host_0, { sendNotificationOverEmail: "sendNotificationOverEmail", conversationWithUserId: "conversationWithUserId" }, {}, []);
export { ChatFormComponentNgFactory as ChatFormComponentNgFactory };
