import {ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators} from '@angular/forms';
import {Component, OnInit, Input, forwardRef} from '@angular/core';
import * as moment from 'moment';
import {isNullOrUndefined} from 'util';
import {environment} from '../../../../environments/environment';
import {BehaviorSubject, Subject} from 'rxjs';

@Component({
  selector: 'app-datetime-picker',
  templateUrl: './datetime-picker.component.html',
  styleUrls: ['./datetime-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatetimePickerComponent),
      multi: true
    }
  ]
})
export class DatetimePickerComponent implements OnInit, ControlValueAccessor {
  @Input() public disabled: boolean = false;

  public date: Date;
  public hour: number;
  public onChanges;
  public onTouched;
  //public disabled: boolean;
  public dateTemp;
  public dateSubject: Subject<any> = new Subject<any>();

  public datepickerConfig = environment.ngxMyDatePickerConfig;
  public errorMessage: string;

  public get IsDateSelected() {
    return isNullOrUndefined(this.date);
  }

  writeValue(obj: Date): void {
    console.log(obj, 'OBJ');
    if (obj) {
      this.date = this.secondsToDate(obj);
      this.dateTemp = {jsdate: this.secondsToDate(obj)};
      this.hour = this.date.getHours();
    }
  }

  registerOnChange(fn: any): void {
    this.onChanges = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public change() {
    console.log('datepicker changed');
    console.log(this.dateTemp.date.month);
    this.date = new Date(this.dateTemp.date.year, this.dateTemp.date.month - 1, this.dateTemp.date.day);
    console.log(this.hour, this.date, !isNullOrUndefined(this.hour), !isNullOrUndefined(this.date));
    console.log(this.hour, this.date, !isNullOrUndefined(this.hour) && !isNullOrUndefined(this.date));
    if (!isNullOrUndefined(this.hour) && !isNullOrUndefined(this.date)) {
      console.log(this.date);
      this.setError('');
      this.date.setHours(this.hour || 0);
      this.onChanges(this.date.getTime() / 1000);
    } else {
      this.setError('Все поля обязательны');
      console.log(this.errorMessage);
    }
  }

  constructor() {
  }

  OnDateChange(date) {
    this.dateTemp = date;
    this.date = date.jsdate;
    this.dateSubject.next();
  }

  changeHour() {
    this.dateSubject.next();
  }

  ngOnInit() {
    this.dateSubject
      .subscribe(() => {
        this.change();
      });
  }

  private secondsToDate(seconds) {
    return moment(seconds * 1000).toDate();
  }

  private setError(message: string) {
    this.errorMessage = message;
  }
}
