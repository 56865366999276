/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./amo-crm-contacts-import.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../import-crm-section/import-crm-section.component.ngfactory";
import * as i3 from "../../import-crm-section/import-crm-section.component";
import * as i4 from "../../../services/integration-form.service";
import * as i5 from "@angular/common";
import * as i6 from "./amo-crm-contacts-import.component";
import * as i7 from "../../../../../services/store.service";
import * as i8 from "../../../../../services/mock/mock.service";
var styles_AmoCrmContactsImportComponent = [i0.styles];
var RenderType_AmoCrmContactsImportComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AmoCrmContactsImportComponent, data: {} });
export { RenderType_AmoCrmContactsImportComponent as RenderType_AmoCrmContactsImportComponent };
function View_AmoCrmContactsImportComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-import-crm-section", [], null, null, null, i2.View_ImportCrmSectionComponent_0, i2.RenderType_ImportCrmSectionComponent)), i1.ɵdid(2, 114688, null, 0, i3.ImportCrmSectionComponent, [i4.IntegrationFormService], { section: [0, "section"], formFields: [1, "formFields"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.formFields; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_AmoCrmContactsImportComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "amo-crm-contacts-import"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "amo-crm-contacts-import__items"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AmoCrmContactsImportComponent_1)), i1.ɵdid(3, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.sections; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_AmoCrmContactsImportComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-amo-crm-contacts-import", [], null, null, null, View_AmoCrmContactsImportComponent_0, RenderType_AmoCrmContactsImportComponent)), i1.ɵdid(1, 114688, null, 0, i6.AmoCrmContactsImportComponent, [i7.StoreService, i8.MockService, i4.IntegrationFormService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AmoCrmContactsImportComponentNgFactory = i1.ɵccf("app-amo-crm-contacts-import", i6.AmoCrmContactsImportComponent, View_AmoCrmContactsImportComponent_Host_0, {}, {}, []);
export { AmoCrmContactsImportComponentNgFactory as AmoCrmContactsImportComponentNgFactory };
