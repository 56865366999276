/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./ad-manager-middleware.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./ad-manager-middleware.component";
var styles_AdManagerMiddlewareComponent = [i0.styles];
var RenderType_AdManagerMiddlewareComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AdManagerMiddlewareComponent, data: {} });
export { RenderType_AdManagerMiddlewareComponent as RenderType_AdManagerMiddlewareComponent };
export function View_AdManagerMiddlewareComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "vk-ad-cabinet-header"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u041D\u043E\u0432\u043E\u0435 \u043E\u0431\u044A\u044F\u0432\u043B\u0435\u043D\u0438\u0435\n"])), (_l()(), i1.ɵeld(2, 0, null, null, 8, "div", [["class", "vk-container vk-container_large"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u041F\u043E\u0436\u0430\u043B\u0443\u0439\u0441\u0442\u0430, "])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "a", [["routerLink", "/sign-in"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["\u0432\u043E\u0439\u0434\u0438\u0442\u0435"])), (_l()(), i1.ɵted(-1, null, [" \u0438\u043B\u0438 "])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "a", [["routerLink", "/sign-up"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["\u0437\u0430\u0440\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u0443\u0439\u0442\u0435\u0441\u044C"])), (_l()(), i1.ɵeld(11, 0, null, null, 0, "div", [["class", "ad-manager-placeholder-image"]], null, null, null, null, null))], function (_ck, _v) { var currVal_2 = "/sign-in"; _ck(_v, 5, 0, currVal_2); var currVal_5 = "/sign-up"; _ck(_v, 9, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 5).target; var currVal_1 = i1.ɵnov(_v, 5).href; _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 9).target; var currVal_4 = i1.ɵnov(_v, 9).href; _ck(_v, 8, 0, currVal_3, currVal_4); }); }
export function View_AdManagerMiddlewareComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-ad-manager-middleware", [], null, null, null, View_AdManagerMiddlewareComponent_0, RenderType_AdManagerMiddlewareComponent)), i1.ɵdid(1, 114688, null, 0, i4.AdManagerMiddlewareComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AdManagerMiddlewareComponentNgFactory = i1.ɵccf("app-ad-manager-middleware", i4.AdManagerMiddlewareComponent, View_AdManagerMiddlewareComponent_Host_0, {}, {}, []);
export { AdManagerMiddlewareComponentNgFactory as AdManagerMiddlewareComponentNgFactory };
