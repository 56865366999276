<div class="vk-strip-wrapper vk-strip-wrapper_">
  <div class="vk-strip-wrapper__item vk-strip-wrapper__item_wide">
    <div class="vk-ad-cabinet-header">
      {{data.header}}
    </div>
  </div>

  <div class="vk-strip-wrapper__item">
    <button mat-dialog-close class="modal-close-button">
      <i class="icon ion-md-close"></i>
    </button>
  </div>
</div>

<p [innerHtml]="data.text"></p>
