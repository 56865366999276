/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./ad-network-export.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../vk/components/vk-lead-forms-settings/vk-lead-forms-settings.component.ngfactory";
import * as i3 from "../../../vk/components/vk-lead-forms-settings/vk-lead-forms-settings.component";
import * as i4 from "../../../../stores/ad-network-groups/ad-network-groups.service";
import * as i5 from "../../../../stores/ad-network-groups/ad-network-groups.query";
import * as i6 from "@angular/forms";
import * as i7 from "@datorama/akita-ng-forms-manager";
import * as i8 from "../../../../stores/platform-assignations/platform-assignations.query";
import * as i9 from "../../../my-target/components/lead-forms-configurator/lead-forms-configurator.component.ngfactory";
import * as i10 from "../../../my-target/components/lead-forms-configurator/lead-forms-configurator.component";
import * as i11 from "../../../../stores/ad-network-forms/ad-network-forms.query";
import * as i12 from "../../../../stores/ad-network-forms/ad-network-forms.service";
import * as i13 from "../../../../stores/platform-assignations/platform-assignations.service";
import * as i14 from "@angular/common";
import * as i15 from "./ad-network-export.component";
import * as i16 from "../../../../stores/platforms/platforms.query";
var styles_AdNetworkExportComponent = [i0.styles];
var RenderType_AdNetworkExportComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AdNetworkExportComponent, data: {} });
export { RenderType_AdNetworkExportComponent as RenderType_AdNetworkExportComponent };
function View_AdNetworkExportComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-vk-lead-forms-settings", [], null, null, null, i2.View_VkLeadFormsSettingsComponent_0, i2.RenderType_VkLeadFormsSettingsComponent)), i1.ɵdid(2, 14401536, null, 0, i3.VkLeadFormsSettingsComponent, [i4.AdNetworkGroupsService, i5.AdNetworkGroupsQuery, i6.FormBuilder, i7.AkitaNgFormsManager, i1.ChangeDetectorRef, i8.PlatformAssignationsQuery], { type: [0, "type"], platform: [1, "platform"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.type; var currVal_1 = _co.platform; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_AdNetworkExportComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-lead-forms-configurator", [], null, null, null, i9.View_LeadFormsConfiguratorComponent_0, i9.RenderType_LeadFormsConfiguratorComponent)), i1.ɵdid(2, 245760, null, 0, i10.LeadFormsConfiguratorComponent, [i11.AdNetworkFormsQuery, i12.AdNetworkFormsService, i13.PlatformAssignationsService, i8.PlatformAssignationsQuery, i4.AdNetworkGroupsService, i6.FormBuilder, i7.AkitaNgFormsManager], { type: [0, "type"], platform: [1, "platform"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.type; var currVal_1 = _co.platform; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_AdNetworkExportComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u043E\u0431\u044A\u044F\u0432\u043B\u0435\u043D\u0438\u044F \u0441\u043E\u0434\u0435\u0440\u0436\u0430\u0449\u0438\u0435 \u043B\u0438\u0434-\u0444\u043E\u0440\u043C\u0443: "])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-vk-lead-forms-settings", [], null, null, null, i2.View_VkLeadFormsSettingsComponent_0, i2.RenderType_VkLeadFormsSettingsComponent)), i1.ɵdid(4, 14401536, null, 0, i3.VkLeadFormsSettingsComponent, [i4.AdNetworkGroupsService, i5.AdNetworkGroupsQuery, i6.FormBuilder, i7.AkitaNgFormsManager, i1.ChangeDetectorRef, i8.PlatformAssignationsQuery], { type: [0, "type"], platform: [1, "platform"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.type; var currVal_1 = _co.platform; _ck(_v, 4, 0, currVal_0, currVal_1); }, null); }
export function View_AdNetworkExportComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i14.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AdNetworkExportComponent_1)), i1.ɵdid(3, 278528, null, 0, i14.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i14.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AdNetworkExportComponent_2)), i1.ɵdid(5, 278528, null, 0, i14.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i14.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AdNetworkExportComponent_3)), i1.ɵdid(7, 278528, null, 0, i14.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i14.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.type; _ck(_v, 1, 0, currVal_0); var currVal_1 = "VK_LEAD_FORMS"; _ck(_v, 3, 0, currVal_1); var currVal_2 = "MT_LEAD_FORMS"; _ck(_v, 5, 0, currVal_2); var currVal_3 = "FB_LEAD_FORMS"; _ck(_v, 7, 0, currVal_3); }, null); }
export function View_AdNetworkExportComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-ad-network-export", [], null, null, null, View_AdNetworkExportComponent_0, RenderType_AdNetworkExportComponent)), i1.ɵdid(1, 14270464, null, 0, i15.AdNetworkExportComponent, [i16.PlatformsQuery, i4.AdNetworkGroupsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AdNetworkExportComponentNgFactory = i1.ɵccf("app-ad-network-export", i15.AdNetworkExportComponent, View_AdNetworkExportComponent_Host_0, { type: "type", platform: "platform" }, {}, []);
export { AdNetworkExportComponentNgFactory as AdNetworkExportComponentNgFactory };
