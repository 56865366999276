import {Injectable} from '@angular/core';
import {PlatformsTypes} from '../../../platforms-types';
import {AkitaNgFormsManager} from '@datorama/akita-ng-forms-manager';
import {isNullOrUndefined} from 'util';
import {PlatformsQuery} from '../../../stores/platforms/platforms.query';
import {CustomFieldsQuery} from "../../../stores/custom-fields/custom-fields.query";
import {FormMessagesService} from "../../../services/form-messages/form-messages.service";
import {AdNetworkFormsQuery} from "../../../stores/ad-network-forms/ad-network-forms.query";

@Injectable({
  providedIn: 'root'
})
export class CrmImportConfiguratorService {

  constructor(
    private fm: AkitaNgFormsManager,
    private platformsQuery: PlatformsQuery,
    private customFieldsQuery: CustomFieldsQuery,
    private formMessagesService: FormMessagesService,
    private formsQuery: AdNetworkFormsQuery
  ) {
  }


  public configure(type: PlatformsTypes) {
    const form = this.fm.getForm('destinationPlatformSettings');
    if (form) {
      const settings = form.value;
      const sections = [];
      const result = {};

      for (const groupFormId of Object.keys(settings)) {
        const formSettings = settings[groupFormId];
        if (formSettings) {
          for (const sectionKey of Object.keys(formSettings)) {
            if (formSettings[sectionKey] && formSettings[sectionKey].selected) {
              const sectionResult = {
                code: sectionKey,
                data: {
                  id: this.GetGroupId(groupFormId),
                  fields: this.GetSectionResultFields(formSettings, sectionKey),
                },
              };
              if (sectionKey === 'LEADS') {
                sectionResult.data['additional_data'] = [];
                if (formSettings.includeCampaignName) {
                  sectionResult.data['additional_data'].push({
                    name: 'SourceCampaignName',
                    include: formSettings.includeCampaignName
                  })
                }
                if (formSettings.includeLeadFormName) {
                  sectionResult.data['additional_data'].push({
                    name: 'SourceLeadFormName',
                    include: formSettings.includeLeadFormName
                  })
                }
                sectionResult.data['additional_data'].push({
                  name: 'ShouldBindContact',
                  include: formSettings.bindContact
                });
              }
              sectionResult['customFieldsSettings'] = formSettings[sectionKey].customFields

              sections.push(sectionResult);
            }
          }
        }
      }
      return this.unionTasks(sections);
    } else {
      return [{}];
    }
  }

  private GetSectionResultFields(formSettings, sectionKey: string) {
    const customFields = formSettings[sectionKey].customFields || {};
    const fields = Object.assign(formSettings[sectionKey].fields, customFields);
    const fieldsKeys = Object.keys(fields)
    const result = {};
    for (const fieldsKey of fieldsKeys) {
      if (formSettings[fieldsKey] !== null) {
        result[fieldsKey] = fields[fieldsKey]
      }
    }
    return result;
  }

  public GetGroupId(groupId) {
    return (this.platformsQuery.selectedOriginPlatformCode === PlatformsTypes.FB_LEAD_FORMS) ? `ad_${groupId}` : groupId;
  }

  public unionTasks(tasks: any[]) {
    const result = [];
    const taskCodes = [];
    for (const task of tasks) {
      if (!taskCodes.includes(task.code)) {
        taskCodes.push(task.code);
      }
    }
    taskCodes.forEach(taskCode => {
      const taskData = tasks.filter(x => x.code === taskCode).map(x => x.data);
      const sectionSettings = {
        code: taskCode,
        data: taskData
      }
      if (taskCode === 'DEALS') {
        sectionSettings['customFieldsSettings'] = this.customFieldsQuery.CustomFields.map(x => x.Key)
      }
      result.push(sectionSettings);
    });
    return result;
  }

  public valid(type: PlatformsTypes) {
    const form = this.fm.getForm('destinationPlatformSettings');
    if (form) {
      const destinationSettings = form.value;
      const data = [];
      const destinationSettingsKeys = Object.keys(destinationSettings)
      console.log(destinationSettings, 'DESTINATION SETTINGS');
      let status = false;
      for (const formId of destinationSettingsKeys) {
        const formSectionSettings = this.GetFormSectionSettings(formId, destinationSettings[formId]);
        if (formSectionSettings.length > 0) {
          data.push(formSectionSettings);
        }
      }
      // const data = Object.keys(destinationSettings)
      //   .map(formId => ({formId, formSettings: destinationSettings[formId]}))
      //   .filter(x => !isNullOrUndefined(x))
      //   .map(({formId, formSettings}) => this.GetFormSectionSettings(formId, formSettings))
      //   .filter(x => x.length > 0);

      if (data.length > 0) {
        return data.map(x => x.reduce((acc, curr) => acc && curr, true))
          .reduce((acc, curr) => acc && curr, true);
      } else {
        return false;
      }
    }

    return false;
  }

  private GetFormSectionSettings(formId, formSettings) {
    if (formSettings) {
      const isAllRequiredFieldsFilled = [];
      const formSettingsKeys = Object.keys(formSettings);
      for (const sectionCode of formSettingsKeys) {
        const sectionSettings = formSettings[sectionCode];
        if (sectionSettings && sectionSettings.selected) {
          isAllRequiredFieldsFilled.push(this.GetStatus(sectionSettings, formId, sectionCode))
        }
      }
      // const isAllRequiredFieldsFilled = Object.keys(formSettings)
      //   .map(sectionCode => ({sectionCode, sectionSettings: formSettings[sectionCode]}))
      //   .filter(({sectionCode, sectionSettings}) => sectionSettings && sectionSettings.selected)
      //   .map(({sectionCode, sectionSettings}) => {
      //     return this.GetStatus(sectionSettings, formId, sectionCode);
      //   });
      //
      // if (!isAllRequiredFieldsFilled) {
      //   // this.formMessagesService.AddErrorMessage(`В форме "<strong>${this.GetFormTitle(formId)}</strong>" обнаружена ошибка при заполнении данных! Проверьте правильность заполнения данных!`)
      // }
      //
      return isAllRequiredFieldsFilled;
    } else {
      return [];
    }
  }

  private GetStatus(sectionSettings, formId, sectionCode: string) {
    const IsFormSectionFilled = this.IsFormSectionField(sectionSettings.fields);
    const isFilledAllRequiredFieldsOfSection = this.IsFilledAllRequiredFieldsOfSection(formId, sectionCode, sectionSettings);
    let status = isFilledAllRequiredFieldsOfSection;

    if (!status) {
      this.formMessagesService.AddErrorMessage(`В настройках формы объявления "<strong>${this.GetFormTitle(formId)}</strong>" не заполнены все обязательные поля, заполните их и попробуйте еще раз сохранить интеграцию.`)
    }
    return status
  }

  private IsFormSectionField(destinationFormSection) {
    const destinationFormSectionKeys = Object.keys(destinationFormSection);
    let sectionFields = false;
    for (const destinationFormSectionKey of destinationFormSectionKeys) {
      sectionFields = sectionFields && !isNullOrUndefined(destinationFormSection[destinationFormSectionKey])
    }
    return sectionFields

    // return destinationFormSectionKeys
    //   .map(key => ({key, value: destinationFormSection[key]}))
    //   .filter(x => !isNullOrUndefined(x.value))
    //   .length > 0;
  }

  /**
   * Проверка на заполенность всех обязательных полей
   * Алгоритм следующий:
   * 1. Переводим полученный объект в массив из ключей данного объекта
   * 2. Переводим массив ключей в массив объектов с полями key - ключ поля и value - значение поля
   * 3. Отфильтровывем все не обязательные поля
   * 4. Для отсавшихся полей проверяем заполнены ли они
   * 5. Проводим операцию логического умножения для всех полей, если хотя бы одно поле не заполенено
   *    возвращаем false
   *
   * @param formId - Id формы
   * @param sectionCode - код секции
   * @param sectionSettings - объект настроек
   * @constructor
   */
  private IsFilledAllRequiredFieldsOfSection(formId, sectionCode, sectionSettings) {
    const data = Object.keys(sectionSettings.fields)
      .map(key => ({key, value: sectionSettings.fields[key]}))
      .filter(fieldCode => this.CheckIsFieldRequired(formId, sectionCode, fieldCode))
      .map(fieldWrapper => {
        const isFiledValid = !(isNullOrUndefined(fieldWrapper.value) || fieldWrapper.value === '')
        // if (!isFiledValid) {
        //   this.formMessagesService.AddErrorMessage(`В форме "<strong>${this.GetFormTitle(formId)}</strong>" обнаружена ошибка при заполнении данных! Проверьте правильность заполнения данных!`)
        // }
        return isFiledValid;
      })
      .reduce((acc, curr) => acc && curr, true);

    return data;
  }

  private CheckIsFieldRequired(formId, sectionCode, x) {
    return this.platformsQuery.requiredFields.includes(`${formId}_${sectionCode}_${x.key}`);
  }

  private GetFormTitle(formId) {
    return this.formsQuery.GetForm(formId).name
  }
}
