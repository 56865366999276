import { OnInit } from '@angular/core';
import { CabinetsService } from '../../../api/services/cabinets.service';
import { AccountsService } from '../../../api/services/accounts.service';
import { TokenCheckerService } from "../../../shared/services/token-checker.service";
import { Router } from "@angular/router";
import { UserTariffQuery } from "../../stores/user-tariff/state/user-tariff.query";
import { UserTariffService } from "../../stores/user-tariff/user-tariff/user-tariff.service";
import { TariffGroupsService } from "../../../tariff-groups-payments/services/tariff-groups/tariff-groups.service";
import { AffiliateStatusService } from "../../services/affiliate-status.service";
var PersonalSettingsComponent = /** @class */ (function () {
    function PersonalSettingsComponent(cabinetsService, accountsService, tokenService, router, tariffQuery, tariffService, tariffGroupsService, affiliateStatusService) {
        this.cabinetsService = cabinetsService;
        this.accountsService = accountsService;
        this.tokenService = tokenService;
        this.router = router;
        this.tariffQuery = tariffQuery;
        this.tariffService = tariffService;
        this.tariffGroupsService = tariffGroupsService;
        this.affiliateStatusService = affiliateStatusService;
        this.tariff$ = this.tariffQuery.tariff$;
        this.expiredAt$ = this.tariffQuery.expiredAt$;
        this.AccountLoading = true;
        this.CabinetsLoading = true;
        this.openedChangePasswordForm = false;
        this.tariffGroups = [];
        this.showRemoveAccountDataModal = false;
    }
    Object.defineProperty(PersonalSettingsComponent.prototype, "affiliateStatus", {
        get: function () {
            return this.affiliateStatusService.isPartner;
        },
        enumerable: true,
        configurable: true
    });
    PersonalSettingsComponent.prototype.ToggleChangePasswordForm = function () {
        this.openedChangePasswordForm = !this.openedChangePasswordForm;
    };
    PersonalSettingsComponent.prototype.ngOnInit = function () {
        this.LoadCabinets();
        this.LoadUserCabinets();
        this.LoadAccountInformation();
        this.LoadExpirations();
        this.LoadTariffGroups();
        this.affiliateStatusService.LoadAffiliateMemberStatus();
        this.tariffService.LoadUserTariffPackage();
    };
    PersonalSettingsComponent.prototype.RemoveAccountInitiate = function () {
        this.showRemoveAccountDataModal = true;
    };
    PersonalSettingsComponent.prototype.ToggleAccountsLoading = function () {
        this.AccountLoading = !this.AccountLoading;
    };
    PersonalSettingsComponent.prototype.ToggleCabinetsLoading = function () {
        this.CabinetsLoading = !this.CabinetsLoading;
    };
    PersonalSettingsComponent.prototype.LoadCabinets = function () {
        var _this = this;
        this.ToggleCabinetsLoading();
        this.cabinetsService.GetCabinets()
            .subscribe(function (x) {
            _this.Cabinets = x;
            _this.ToggleCabinetsLoading();
        });
    };
    PersonalSettingsComponent.prototype.LoadUserCabinets = function () {
        var _this = this;
        this.ToggleAccountsLoading();
        this.cabinetsService.GetUserCabinets()
            .subscribe(function (x) {
            _this.LoggedInCabinets = x;
            _this.ToggleAccountsLoading();
        });
    };
    PersonalSettingsComponent.prototype.LoadExpirations = function () {
        var _this = this;
        this.accountsService.GetUserTariffPlansExpirations()
            .subscribe(function (data) {
            _this.tariffPlanExpirations = data;
        });
    };
    PersonalSettingsComponent.prototype.IsLoggedIn = function (cabinetId) {
        return (this.LoggedInCabinets) ? this.LoggedInCabinets.map(function (x) { return x.id; }).includes(cabinetId) : 0;
    };
    PersonalSettingsComponent.prototype.LoadAccountInformation = function () {
        var _this = this;
        this.accountsService.GetUserInfo()
            .subscribe(function (x) {
            _this.Account = x;
        });
    };
    PersonalSettingsComponent.prototype.LoadTariffGroups = function () {
        var _this = this;
        this.tariffGroupsService.LoadTariffGroups()
            .subscribe(function (tariffGroups) {
            _this.tariffGroups = tariffGroups;
        });
    };
    return PersonalSettingsComponent;
}());
export { PersonalSettingsComponent };
