import {ComponentPortal} from '@angular/cdk/portal';
import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Observable} from 'rxjs';
import {IIntegrationServiceModel} from '../../../../models/integration-service.model';
import {SelectedServiceFormEnum} from '../../../../models/selected-service-form.enum';
import {IntegrationFormService} from '../../services/integration-form.service';
import {AmoCrmContactsExportComponent} from '../services-integration-forms/amo-crm-contacts-export/amo-crm-contacts-export.component';
import {AmoCrmContactsImportComponent} from '../services-integration-forms/amo-crm-contacts-import/amo-crm-contacts-import.component';
import {MtLeadFormsComponent} from '../services-integration-forms/mt-lead-forms/mt-lead-forms.component';
import {MtRemarketingGroupsComponent} from '../services-integration-forms/mt-remarketing-groups/mt-remarketing-groups.component';
import {VkLeadFormsComponent} from '../services-integration-forms/vk-lead-forms/vk-lead-forms.component';
import {VkRetargetingGroupsComponent} from '../services-integration-forms/vk-retargeting-groups/vk-retargeting-groups.component';

@Component({
  selector: 'app-integration-services-selector',
  templateUrl: './integration-services-selector.component.html',
  styleUrls: ['./integration-services-selector.component.scss']
})
export class IntegrationServicesSelectorComponent implements OnInit {

  @Input() public label: string;
  @Input() public controlName: string;
  @Input() public services$: IIntegrationServiceModel[];

  private integrationServiceControl;
  public selectedForm;

  public form: FormGroup;

  public get service() {
    return this.integrationService.formService
      .getControl(this.controlName)
      .value;
  }

  constructor(
    private integrationService: IntegrationFormService,
  ) {
  }

  ngOnInit() {
    this.form = this.integrationService.form;
    console.log(this.services$, 'FUCKING SERVICES');
    this.integrationService
      .formService
      .getControl(this.controlName)
      .valueChanges
      .subscribe(selectedService => {
        console.log(this.form);
        // this.selectedForm = new ComponentPortal(this.getSelectedService(selectedService));
      });
  }

  private getSelectedService(selectedService: string) {
    // switch (selectedService) {
    //   case SelectedServiceFormEnum.VK_LEAD_FORMS:
    //     return VkLeadFormsComponent;
    //   case SelectedServiceFormEnum.MT_LEAD_FORMS:
    //     return MtLeadFormsComponent;
    //   case SelectedServiceFormEnum.AMO_CRM_CONTACTS_EXPORT:
    //     return AmoCrmContactsExportComponent;
    //   case SelectedServiceFormEnum.VK_RETARGETING_GROUPS:
    //     return VkRetargetingGroupsComponent;
    //   case SelectedServiceFormEnum.MT_REMARKETING_GROUPS:
    //     return MtRemarketingGroupsComponent;
    //   case SelectedServiceFormEnum.AMO_CRM_CONTACTS_IMPORT:
    //     return AmoCrmContactsImportComponent;
    //   default:
    //     throw new Error(`Service form with type ${selectedService} not found`);
    // }
  }
}
