import {Directive, TemplateRef} from '@angular/core';

@Directive({
  selector: '[appDgColumnFilter]'
})
export class DgColumnFilterDirective {

  constructor(
    public template: TemplateRef<any>
  ) { }

}
