import { OnInit } from '@angular/core';
import { UserBalanceQuery } from "../../../../../storage/user-balance/state/user-balance.query";
var PayedTileContentComponent = /** @class */ (function () {
    function PayedTileContentComponent(userBalanceQuery) {
        this.userBalanceQuery = userBalanceQuery;
    }
    Object.defineProperty(PayedTileContentComponent.prototype, "visited$", {
        get: function () {
            return this.userBalanceQuery.visited$;
        },
        enumerable: true,
        configurable: true
    });
    PayedTileContentComponent.prototype.ngOnInit = function () {
    };
    return PayedTileContentComponent;
}());
export { PayedTileContentComponent };
