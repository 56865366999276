import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import * as _ from 'lodash';
import * as i0 from "@angular/core";
var GroupsSelectorService = /** @class */ (function () {
    function GroupsSelectorService() {
        this.MAX_COUNT_OF_GROUPS = 100;
        this.groups = [];
        this.groupsSubject = new BehaviorSubject([]);
        this.selectedGroups = [];
        this.selectedGroupsSubject = new BehaviorSubject([]);
    }
    GroupsSelectorService.prototype.AddGroups = function (groups) {
        this.groups = this.groups.concat(groups);
        this.groupsSubject.next(this.groups);
    };
    GroupsSelectorService.prototype.RemoveGroup = function (groupId) {
        this.groups = this.groups.filter(function (group) {
            return group['id'] !== groupId;
        });
        this.groupsSubject.next(this.groups);
    };
    GroupsSelectorService.prototype.ClearSelectedGroups = function () {
        this.selectedGroups = [];
        this.selectedGroupsSubject.next(this.selectedGroups);
        console.log(this.selectedGroups, 'SELECTED_GROUPS');
    };
    GroupsSelectorService.prototype.SelectGroups = function (groups) {
        var _this = this;
        groups.forEach(function (group) {
            var index = _this.selectedGroups.map(function (x) { return x['id']; }).indexOf(group.id);
            if (index < 0) {
                if (_this.selectedGroups.length < _this.MAX_COUNT_OF_GROUPS) {
                    _this.selectedGroups.push(group);
                }
            }
            else {
                _this.selectedGroups.splice(index, 1);
            }
        });
        this.selectedGroups = _.uniq(this.selectedGroups);
        this.selectedGroupsSubject.next(this.selectedGroups);
    };
    GroupsSelectorService.prototype.IsChecked = function (group) {
        return this.selectedGroups.map(function (x) { return x['id']; }).includes(group.id);
    };
    GroupsSelectorService.prototype.CanBeSelected = function (group) {
        return this.selectedGroups.length < this.MAX_COUNT_OF_GROUPS || this.IsChecked(group);
    };
    GroupsSelectorService.prototype.ClearGroups = function () {
        this.groups = [];
        this.groupsSubject.next(this.selectedGroups);
    };
    GroupsSelectorService.ngInjectableDef = i0.defineInjectable({ factory: function GroupsSelectorService_Factory() { return new GroupsSelectorService(); }, token: GroupsSelectorService, providedIn: "root" });
    return GroupsSelectorService;
}());
export { GroupsSelectorService };
