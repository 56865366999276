import {TariffPlansQuery} from './../../stores/tariff-plans/tariff-plans.query';
import {Component, OnInit} from '@angular/core';
import {PaymentSystemsQuery} from '../../stores/payment-systems/payment-systems.query';
import {NewPaymentsModelService} from "../../services/new-payments/new-payments-model.service";

@Component({
  selector: 'app-create-payment',
  templateUrl: './create-payment.component.html',
  styleUrls: ['./create-payment.component.scss']
})
export class CreatePaymentComponent implements OnInit {

  public tariffPlan$ = this.tariffPlanQuery.selectedTariffPlan$;
  public prepareLinkLoading$ = this.paymentSystemQuery.paymentSystemLoading$;
  public linkToPayment$ = this.paymentSystemQuery.linkToPayment$;

  constructor(
    private tariffPlanQuery: TariffPlansQuery,
    private paymentSystemQuery: PaymentSystemsQuery,
    public paymentsService: NewPaymentsModelService
  ) {
  }

  ngOnInit() {
  }

}
