/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./ad-accounts-not-found.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../shared/components/loading-screen/loading-screen.component.ngfactory";
import * as i3 from "../../../../../shared/components/loading-screen/loading-screen.component";
import * as i4 from "@angular/common";
import * as i5 from "./ad-accounts-not-found.component";
import * as i6 from "../../../new-ad-manager-shared/services/ad-form-manager/ad-form-manager.service";
var styles_AdAccountsNotFoundComponent = [i0.styles];
var RenderType_AdAccountsNotFoundComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AdAccountsNotFoundComponent, data: {} });
export { RenderType_AdAccountsNotFoundComponent as RenderType_AdAccountsNotFoundComponent };
function View_AdAccountsNotFoundComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "accounts-not-found"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u0414\u043B\u044F \u043F\u0440\u043E\u0434\u043E\u043B\u0436\u0435\u043D\u0438\u044F \u0440\u0430\u0431\u043E\u0442\u044B \u0432\u0430\u043C \u043D\u0435\u043E\u0431\u0445\u043E\u0434\u0438\u043C\u043E \u0441\u043E\u0437\u0434\u0430\u0442\u044C \u0440\u0435\u043A\u043B\u0430\u043C\u043D\u044B\u0439 \u043A\u0430\u0431\u0438\u043D\u0435\u0442 \u0412\u041A\u043E\u043D\u0442\u0430\u043A\u0442\u0435. \u0414\u043B\u044F \u044D\u0442\u043E\u0433\u043E \u043F\u0435\u0440\u0435\u0439\u0434\u0438\u0442\u0435 "])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "a", [["href", "https://postmonitor.ru/blog/instrukciya-po-sozdaniyu-reklamnogo-kabineta-vkontakte"], ["rel", "nofollow noopener"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u043F\u043E \u0441\u0441\u044B\u043B\u043A\u0435"])), (_l()(), i1.ɵted(-1, null, [". "])), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [["class", "activate-cabinets__toolbox"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "div", [["class", "divider"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "button", [["class", "vk-standart-button activate-cabinets__refresh-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.Refresh() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\u041E\u0431\u043D\u043E\u0432\u0438\u0442\u044C"]))], null, null); }
function View_AdAccountsNotFoundComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-loading-screen", [], null, null, null, i2.View_LoadingScreenComponent_0, i2.RenderType_LoadingScreenComponent)), i1.ɵdid(1, 114688, null, 0, i3.LoadingScreenComponent, [], null, null), (_l()(), i1.ɵted(-1, 0, [" \u041F\u043E\u0434\u043E\u0436\u0434\u0438\u0442\u0435 \u0438\u0434\u0435\u0442 \u043F\u043E\u0434\u043A\u043B\u044E\u0447\u0435\u043D\u0438\u0435 \u0440\u0435\u043A\u043B\u0430\u043C\u043D\u043E\u0433\u043E \u043A\u0430\u0431\u0438\u043D\u0435\u0442\u0430 \u043A \u0441\u0438\u0441\u0442\u0435\u043C\u0435 PostMonitor "]))], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_AdAccountsNotFoundComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AdAccountsNotFoundComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["loadingScreen", 2]], null, 0, null, View_AdAccountsNotFoundComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.loading; var currVal_1 = i1.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_AdAccountsNotFoundComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-ad-accounts-not-found", [], null, null, null, View_AdAccountsNotFoundComponent_0, RenderType_AdAccountsNotFoundComponent)), i1.ɵdid(1, 114688, null, 0, i5.AdAccountsNotFoundComponent, [i6.AdFormManagerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AdAccountsNotFoundComponentNgFactory = i1.ɵccf("app-ad-accounts-not-found", i5.AdAccountsNotFoundComponent, View_AdAccountsNotFoundComponent_Host_0, {}, { adminAccountBinded: "adminAccountBinded" }, []);
export { AdAccountsNotFoundComponentNgFactory as AdAccountsNotFoundComponentNgFactory };
