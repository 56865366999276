import {Injectable} from '@angular/core';
import {UserIntegrationStatisticsStore} from './user-integration-statistics.store';
import {UserIntegrationService} from "../../../api/services";
import {delay, tap} from "rxjs/operators";
import {createUserIntegrationStatistic} from "./user-integration-statistic.model";
import {forkJoin} from "rxjs";

@Injectable({providedIn: 'root'})
export class UserIntegrationStatisticsService {

  constructor(
    protected store: UserIntegrationStatisticsStore,
    private integrationService: UserIntegrationService
  ) {
  }

  /**
   * Загрузка статистики по id
   * @param ids
   * @param dateFrom
   * @param dateTo
   * @constructor
   */
  public LoadIntegrationStatisticsById(ids: number[], dateFrom: string = null, dateTo: string = null) {
    const integrationStatistics = () => ids.map((id, i) => this.integrationService.GetIntegrationSummaryStatistics({
      integrationId: id,
      dateFrom,
      dateTo
    }).pipe(
      delay(150),
      tap(({data}) => this.store.add(createUserIntegrationStatistic({id, value: data})))
    ));
    forkJoin(
      integrationStatistics()
    )
      .subscribe(data => {
      })
  }

}
