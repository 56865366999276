import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs/Observable';
import * as moment from 'moment';


@Injectable()
export class AccessExpirationGuard implements CanActivate {


    constructor(private router: Router) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        // const token = JSON.parse(localStorage.getItem('user_meta'));
        // 
        // if (token) {
        //     if (token.isExpired /**|| moment(new Date()).isBefore(new Date(token.expirationDate))**/) {
        //         this.router.navigate(['automation/accounts']);
        //         return false;
        //     } else {
        //         return true;
        //     }
        // }
        // this.router.navigate(['automation/accounts']);
        // return false;
        return true;
    }
}
