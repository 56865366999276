import { OnInit } from '@angular/core';
var NewTargetingStatsComponent = /** @class */ (function () {
    function NewTargetingStatsComponent() {
    }
    Object.defineProperty(NewTargetingStatsComponent.prototype, "TargetingAudienceCount", {
        get: function () {
            return this.targeting ? this.targeting.audience_count : 0;
        },
        enumerable: true,
        configurable: true
    });
    NewTargetingStatsComponent.prototype.ngOnInit = function () {
    };
    return NewTargetingStatsComponent;
}());
export { NewTargetingStatsComponent };
