import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UserBalanceStore} from './user-balance.store';
import {tap} from 'rxjs/operators';
import {environment} from "../../../../environments/environment";

@Injectable({providedIn: 'root'})
export class UserBalanceService {

  private baseUrl = `${environment.backendUri}/${environment.apiPrefix}`;

  constructor(private store: UserBalanceStore,
              private http: HttpClient) {
  }

  /**
   * Получение данных о балансе пользователя
   * @constructor
   */
  public GetUserBalance() {
    this.http.get(`${this.baseUrl}/user-balance`)
      .subscribe((userBalanceInfo: any) => {
        this.store.update({...userBalanceInfo});
      })
  }

  public GetRegistratedTodayUsers() {
    this.http.get('/api/')
  }

}
