import { OnInit } from '@angular/core';
import { UserBalanceQuery } from "../../../../../storage/user-balance/state/user-balance.query";
var RegistratedTileContentComponent = /** @class */ (function () {
    function RegistratedTileContentComponent(balanceQuery) {
        this.balanceQuery = balanceQuery;
    }
    Object.defineProperty(RegistratedTileContentComponent.prototype, "registrated$", {
        get: function () {
            return this.balanceQuery.registrated$;
        },
        enumerable: true,
        configurable: true
    });
    RegistratedTileContentComponent.prototype.ngOnInit = function () {
    };
    return RegistratedTileContentComponent;
}());
export { RegistratedTileContentComponent };
