import { OnInit } from '@angular/core';
import { AffiliateStatusService } from "../../services/affiliate-status.service";
var AffiliateProgramLinkComponent = /** @class */ (function () {
    function AffiliateProgramLinkComponent(affiliateStatusService) {
        this.affiliateStatusService = affiliateStatusService;
        this.isTitle = false;
    }
    Object.defineProperty(AffiliateProgramLinkComponent.prototype, "IsPartner", {
        get: function () {
            return this.affiliateStatusService.isPartner;
        },
        enumerable: true,
        configurable: true
    });
    AffiliateProgramLinkComponent.prototype.ngOnInit = function () {
        this.affiliateStatusService.LoadAffiliateMemberStatus();
    };
    return AffiliateProgramLinkComponent;
}());
export { AffiliateProgramLinkComponent };
