import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import {Account} from '../models/account';
import {Campaign} from '../models/campaign';
import {Ad} from '../models/ad';
import {StatisticResponse} from '../models/statistic-response';

@Injectable()
export class ApiService {
    private baseUrl = '/vk-api/method';
    private version = '5.74';

    constructor(private client: HttpClient) {

    }

    /**
     * Returns list of cabinets
     * @param {string} token
     * @returns {Observable<Object>}
     * @constructor
     */
    public GetAccounts(token: string): Observable<Array<Account>> {
        return this.MapResponse(this.client.get(this.GetUrl('ads.getAccounts', token)));
    }

    /**
     * Returns campaigns list of cabined with account_id
     * @param {number} account_id
     * @param {string} token
     * @returns {Observable<Object>}
     * @constructor
     */
    public GetCampaigns(account_id: number, token: string): Observable<Array<Campaign>> {
        return this.MapResponse(this.client.get(this.GetUrl('ads.getCampaigns', token,
            `account_id=${account_id}`)));
    }

    /**
     * Returns list of adv records
     * @param {number} account_id
     * @param {string} token
     * @param {string} campaign_ids
     * @param {number} limit
     * @param {number} offset
     * @param {number} include_deleted
     * @param {string} ad_ids
     * @returns {Observable<Object>}
     * @constructor
     */
    public GetAds(account_id: number, token: string,
                  campaign_ids?: string, limit?: number, offset?: number,
                  include_deleted?: number, ad_ids?: string): Observable<Array<Ad>> {
        const campaign_idsParameter = (campaign_ids) ? `&campaign_ids=${campaign_ids}` : '';
        const limitParameter = (limit) ? `&limit=${limit}` : '';
        const offsetParameter = (offset) ? `&offset=${offset}` : '';
        const include_deletedParameter = (include_deleted) ? `&include_deleted=${include_deleted}` : '';
        const ad_idsParameter = (ad_ids) ? `&ad_ids=${ad_ids}` : '';

        return this.MapResponse(this.client.get(this.GetUrl('ads.getAds', token,
            `account_id=${account_id}${campaign_idsParameter}
      ${limitParameter}${offsetParameter}
      ${include_deletedParameter}${ad_idsParameter}`)));
    }

    /**
     *
     * @param {number} account_id
     * @param {string} ids_type
     * @param {string} ids
     * @param {string} period
     * @param {string} date_from
     * @param {string} date_to
     * @param {string} token
     * @returns {Observable<any>}
     * @constructor
     */
    public GetStatistics(account_id: number, ids_type: string, ids: string, period: string, date_from: string, date_to: string, token: string): Observable<StatisticResponse> {
        return this.MapResponse(this.client.get(this.GetUrl('ads.getStatistics', token,
            `account_id=${account_id}&ids_type=${ids_type}&ids=${ids}&period=${period}&date_from=${date_from}&date_to=${date_to}`)));
    }

    private GetUrl(method: string, token: string, parameters?: string): string {
        const params = (parameters) ? `${parameters}&` : '';
        const generatedUri = `${this.baseUrl}/${method}?${params}access_token=${token}&v=${this.version}`;

        

        return generatedUri;
    }

    private MapResponse(observable: Observable<any>): Observable<any> {
        return observable.map(res => {
            return res.response;
        });
    }
}
