import {Injectable} from '@angular/core';
import {PostMonitorBackend_V2ModelsAdManagerEntitiesSuggestion} from '../../../api/models/post-monitor-backend-_v2models-ad-manager-entities-suggestion';
import {SuggestionsService} from '../../../api/services';
import {publish, refCount} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppSuggestionsService {

  public suggestions: Array<PostMonitorBackend_V2ModelsAdManagerEntitiesSuggestion>;

  constructor(
    private suggestionsService: SuggestionsService
  ) {
    if (!this.suggestions) {
      this.suggestionsService.GetAllSuggestions()
        .pipe(
          publish(),
          refCount()
        )
        .subscribe(suggestions => {
          this.suggestions = suggestions;
        });
    }
  }

  public GetSuggestion(code: string) {
    if (this.suggestions) {
      const suggestion = this.suggestions.find(x => x.code === code);
      return suggestion;
    } else {
      return null;
    }
  }
}
