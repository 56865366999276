import { OnInit } from '@angular/core';
import { IntegrationFormService } from '../../services/integration-form.service';
var IntegrationServicesSelectorComponent = /** @class */ (function () {
    function IntegrationServicesSelectorComponent(integrationService) {
        this.integrationService = integrationService;
    }
    Object.defineProperty(IntegrationServicesSelectorComponent.prototype, "service", {
        get: function () {
            return this.integrationService.formService
                .getControl(this.controlName)
                .value;
        },
        enumerable: true,
        configurable: true
    });
    IntegrationServicesSelectorComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.form = this.integrationService.form;
        console.log(this.services$, 'FUCKING SERVICES');
        this.integrationService
            .formService
            .getControl(this.controlName)
            .valueChanges
            .subscribe(function (selectedService) {
            console.log(_this.form);
            // this.selectedForm = new ComponentPortal(this.getSelectedService(selectedService));
        });
    };
    IntegrationServicesSelectorComponent.prototype.getSelectedService = function (selectedService) {
        // switch (selectedService) {
        //   case SelectedServiceFormEnum.VK_LEAD_FORMS:
        //     return VkLeadFormsComponent;
        //   case SelectedServiceFormEnum.MT_LEAD_FORMS:
        //     return MtLeadFormsComponent;
        //   case SelectedServiceFormEnum.AMO_CRM_CONTACTS_EXPORT:
        //     return AmoCrmContactsExportComponent;
        //   case SelectedServiceFormEnum.VK_RETARGETING_GROUPS:
        //     return VkRetargetingGroupsComponent;
        //   case SelectedServiceFormEnum.MT_REMARKETING_GROUPS:
        //     return MtRemarketingGroupsComponent;
        //   case SelectedServiceFormEnum.AMO_CRM_CONTACTS_IMPORT:
        //     return AmoCrmContactsImportComponent;
        //   default:
        //     throw new Error(`Service form with type ${selectedService} not found`);
        // }
    };
    return IntegrationServicesSelectorComponent;
}());
export { IntegrationServicesSelectorComponent };
