var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { map as __map, filter as __filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../api-configuration";
import * as i2 from "@angular/common/http";
var AccountsService = /** @class */ (function (_super) {
    __extends(AccountsService, _super);
    function AccountsService(config, http) {
        return _super.call(this, config, http) || this;
    }
    /**
     * @param model undefined
     * @return Success
     */
    AccountsService.prototype.signUpResponse = function (model) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = model;
        var req = new HttpRequest('POST', this.rootUrl + "/apiv2/account/registration", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param model undefined
     * @return Success
     */
    AccountsService.prototype.signUp = function (model) {
        return this.signUpResponse(model).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param model undefined
     * @return Success
     */
    AccountsService.prototype.signInResponse = function (model) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = model;
        var req = new HttpRequest('POST', this.rootUrl + "/apiv2/account/login", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param model undefined
     * @return Success
     */
    AccountsService.prototype.signIn = function (model) {
        return this.signInResponse(model).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param email undefined
     */
    AccountsService.prototype.forgotPasswordResponse = function (email) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (email != null)
            __params = __params.set('email', email.toString());
        var req = new HttpRequest('POST', this.rootUrl + "/apiv2/account/forgotpassword", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param email undefined
     */
    AccountsService.prototype.forgotPassword = function (email) {
        return this.forgotPasswordResponse(email).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `AccountsService.ChangePasswordParams` containing the following parameters:
     *
     * - `passwordRepeat`:
     *
     * - `password`:
     *
     * - `code`:
     */
    AccountsService.prototype.ChangePasswordResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.passwordRepeat != null)
            __params = __params.set('passwordRepeat', params.passwordRepeat.toString());
        if (params.password != null)
            __params = __params.set('password', params.password.toString());
        if (params.code != null)
            __params = __params.set('code', params.code.toString());
        var req = new HttpRequest('POST', this.rootUrl + "/apiv2/account/change-password", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `AccountsService.ChangePasswordParams` containing the following parameters:
     *
     * - `passwordRepeat`:
     *
     * - `password`:
     *
     * - `code`:
     */
    AccountsService.prototype.ChangePassword = function (params) {
        return this.ChangePasswordResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `AccountsService.ExternalConfirmTokenParams` containing the following parameters:
     *
     * - `TicketCode`:
     *
     * - `utmData`:
     *
     * - `phone`:
     *
     * - `name`:
     *
     * - `email`:
     *
     * - `affiliateMemberCode`:
     *
     * @return Success
     */
    AccountsService.prototype.ExternalConfirmTokenResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.utmData != null)
            __params = __params.set('utmData', params.utmData.toString());
        if (params.phone != null)
            __params = __params.set('phone', params.phone.toString());
        if (params.name != null)
            __params = __params.set('name', params.name.toString());
        if (params.email != null)
            __params = __params.set('email', params.email.toString());
        if (params.affiliateMemberCode != null)
            __params = __params.set('affiliateMemberCode', params.affiliateMemberCode.toString());
        var req = new HttpRequest('POST', this.rootUrl + ("/apiv2/account/external-confirm/" + encodeURIComponent(params.TicketCode)), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `AccountsService.ExternalConfirmTokenParams` containing the following parameters:
     *
     * - `TicketCode`:
     *
     * - `utmData`:
     *
     * - `phone`:
     *
     * - `name`:
     *
     * - `email`:
     *
     * - `affiliateMemberCode`:
     *
     * @return Success
     */
    AccountsService.prototype.ExternalConfirmToken = function (params) {
        return this.ExternalConfirmTokenResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param provider undefined
     */
    AccountsService.prototype.UnBindExternalTokenResponse = function (provider) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('DELETE', this.rootUrl + ("/apiv2/account/external-unbind/" + encodeURIComponent(provider)), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param provider undefined
     */
    AccountsService.prototype.UnBindExternalToken = function (provider) {
        return this.UnBindExternalTokenResponse(provider).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param TicketCode undefined
     * @return Success
     */
    AccountsService.prototype.BindExternalTokenResponse = function (TicketCode) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('POST', this.rootUrl + ("/apiv2/account/external-bind/" + encodeURIComponent(TicketCode)), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param TicketCode undefined
     * @return Success
     */
    AccountsService.prototype.BindExternalToken = function (TicketCode) {
        return this.BindExternalTokenResponse(TicketCode).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param provider undefined
     */
    AccountsService.prototype.GetExternalTokensResponse = function (provider) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('GET', this.rootUrl + ("/apiv2/account/external-list/" + encodeURIComponent(provider)), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param provider undefined
     */
    AccountsService.prototype.GetExternalTokens = function (provider) {
        return this.GetExternalTokensResponse(provider).pipe(__map(function (_r) { return _r.body; }));
    };
    AccountsService.prototype.RemoveAccountDataResponse = function () {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('DELETE', this.rootUrl + "/apiv2/account/remove", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    AccountsService.prototype.RemoveAccountData = function () {
        return this.RemoveAccountDataResponse().pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @return Success
     */
    AccountsService.prototype.GetUserInfoResponse = function () {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/account/info", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @return Success
     */
    AccountsService.prototype.GetUserInfo = function () {
        return this.GetUserInfoResponse().pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `AccountsService.ChangePasswordAuthParams` containing the following parameters:
     *
     * - `passwordRepeat`:
     *
     * - `password`:
     *
     * @return Success
     */
    AccountsService.prototype.ChangePasswordAuthResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.passwordRepeat != null)
            __params = __params.set('passwordRepeat', params.passwordRepeat.toString());
        if (params.password != null)
            __params = __params.set('password', params.password.toString());
        var req = new HttpRequest('POST', this.rootUrl + "/apiv2/account/change-password-auth", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `AccountsService.ChangePasswordAuthParams` containing the following parameters:
     *
     * - `passwordRepeat`:
     *
     * - `password`:
     *
     * @return Success
     */
    AccountsService.prototype.ChangePasswordAuth = function (params) {
        return this.ChangePasswordAuthResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @return Success
     */
    AccountsService.prototype.GetUserTariffPlansExpirationsResponse = function () {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/account/expiration-dates", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @return Success
     */
    AccountsService.prototype.GetUserTariffPlansExpirations = function () {
        return this.GetUserTariffPlansExpirationsResponse().pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param codes undefined
     * @return Success
     */
    AccountsService.prototype.GetUserTariffAccessResponse = function (codes) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        (codes || []).forEach(function (val) { if (val != null)
            __params = __params.append('codes', val.toString()); });
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/account/tariff-access", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param codes undefined
     * @return Success
     */
    AccountsService.prototype.GetUserTariffAccess = function (codes) {
        return this.GetUserTariffAccessResponse(codes).pipe(__map(function (_r) { return _r.body; }));
    };
    AccountsService.signUpPath = '/apiv2/account/registration';
    AccountsService.signInPath = '/apiv2/account/login';
    AccountsService.forgotPasswordPath = '/apiv2/account/forgotpassword';
    AccountsService.ChangePasswordPath = '/apiv2/account/change-password';
    AccountsService.ExternalConfirmTokenPath = '/apiv2/account/external-confirm/{TicketCode}';
    AccountsService.UnBindExternalTokenPath = '/apiv2/account/external-unbind/{provider}';
    AccountsService.BindExternalTokenPath = '/apiv2/account/external-bind/{TicketCode}';
    AccountsService.GetExternalTokensPath = '/apiv2/account/external-list/{provider}';
    AccountsService.RemoveAccountDataPath = '/apiv2/account/remove';
    AccountsService.GetUserInfoPath = '/apiv2/account/info';
    AccountsService.ChangePasswordAuthPath = '/apiv2/account/change-password-auth';
    AccountsService.GetUserTariffPlansExpirationsPath = '/apiv2/account/expiration-dates';
    AccountsService.GetUserTariffAccessPath = '/apiv2/account/tariff-access';
    AccountsService.ngInjectableDef = i0.defineInjectable({ factory: function AccountsService_Factory() { return new AccountsService(i0.inject(i1.ApiConfiguration), i0.inject(i2.HttpClient)); }, token: AccountsService, providedIn: "root" });
    return AccountsService;
}(__BaseService));
export { AccountsService };
