import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ExperienceLevelBasedDashboardRedirectionService} from '../../../shared/services/experience-level-based-dashboard-redirection.service';
import {PersonalDashboardMenuList} from '../../models/personal-dashboard-menu-list';

@Component({
  selector: 'app-personal-dashboard',
  templateUrl: './personal-dashboard.component.html',
  styleUrls: ['./personal-dashboard.component.scss']
})
export class PersonalDashboardComponent implements OnInit {
  public items: Array<any> = [
    {
      visible: true,
      width: 'full',
      align: 'center',
      color: 'light-blue',
      icon: 'ion-ios-create',
      notification: null,
      header: 'Создать объявление ВКонтакте',
      description: null,
      route: '/automation/new-ad-manager/pro/create'
    },
    {
      visible: true,
      width: 'half',
      align: 'left',
      color: 'green',
      icon: 'ion-md-cog',
      notification: null,
      header: 'Автоматизация таргетированной рекламы',
      description: 'Автоматизация работы с рекламными кабинетами ВКонтакте',
      route: '/automation/accounts'
    },
    {
      visible: true,
      width: 'half',
      align: 'left',
      color: 'blue',
      icon: 'ion-ios-people',
      notification: null,
      header: 'Сообщества ВКонтакте',
      description: 'Поиск сообществ по различным критериям',
      route: '/vk/group'
    },
    {
      visible: true,
      width: 'half',
      align: 'left',
      color: 'blue',
      icon: 'ion-md-chatbubbles',
      notification: null,
      header: 'Рекламные посты ВКонтакте',
      description: 'Поиск постов которые используются при рекламе в сообществах',
      route: '/vk/post'
    },
    {
      visible: true,
      width: 'half',
      align: 'left',
      color: 'blue',
      icon: 'ion-ios-today',
      notification: null,
      header: 'Рекламные записи (Промо посты) ВКонтакте',
      description: 'Поиск постов, которые используются в таргетированной рекламе',
      route: '/vk/hidden'
    }
  ];

  public automationMenuList: PersonalDashboardMenuList[] = [
    {
      header: 'Автоматизация таргетированной рекламы',
      marginRight: true,
      marginBottom: true,
      items: [
        {
          backgroundColor: '#1B7B25',
          header: 'Автоматизация таргетированной рекламы',
          description: 'Автоматизация работы с рекламными кабинетами ВКонтакте',
          icon: 'ion-md-cog',
          route: '/automation/accounts'
        }
      ]
    },
    // {
    //   header: 'Интеграции',
    //   marginRight: true,
    //   marginBottom: false,
    //   items: [
    //     {
    //       backgroundColor: '#74ACE9',
    //       header: 'Интеграция CRM-систем с рекламными платфорами.',
    //       description: 'Автоматическая передача данных из CRM-систем в аудитории Facebook/Instagram, myTarget, ВКонтакте и Яндекс.Аудитории.<br>' +
    //         'Автоматическая передача данных из лид-форм Facebook/Instagram, myTarget и ВКонтакте в CRM-системы.',
    //       icon: 'ion-ios-create',
    //       route: '/integrations'
    //     },
    //   ]
    // }
  ];

  public analyticsMenuList: PersonalDashboardMenuList[] = [
    {
      header: 'Интеграции',
      marginRight: true,
      marginBottom: false,
      items: [
        {
          backgroundColor: '#74ACE9',
          header: 'Интеграция CRM-систем с рекламными платфорами.',
          description: 'Автоматическая передача данных из CRM-систем в аудитории myTarget, ВКонтакте и Яндекс.Аудитории.<br>' +
            'Автоматическая передача данных из лид-форм myTarget и ВКонтакте в CRM-системы.',
          icon: 'ion-ios-create',
          route: '/integrations'
        },
      ]
    }
    // {
    //   header: 'Аналитика постов и сообществ ВКонтакте',
    //   marginRight: false,
    //   marginBottom: false,
    //   items: [
    //     {
    //       backgroundColor: null,
    //       header: 'Рекламные записи (Промо посты) ВКонтакте',
    //       description: 'Поиск постов, которые используются в таргетированной рекламе',
    //       icon: 'ion-ios-today',
    //       route: '/vk/hidden'
    //     },
    //     {
    //       backgroundColor: null,
    //       header: 'Рекламные посты ВКонтакте',
    //       description: 'Поиск постов которые используются при рекламе в сообществах',
    //       icon: 'ion-md-chatbubbles',
    //       route: '/vk/post'
    //     },
    //     {
    //       backgroundColor: null,
    //       header: 'Сообщества ВКонтакте',
    //       description: 'Поиск сообществ по различным критериям',
    //       icon: 'ion-ios-people',
    //       route: '/vk/group'
    //     },
    //   ],
    // }
  ];

  constructor(private router: Router, private experienceRedirection: ExperienceLevelBasedDashboardRedirectionService) {
    const redirect = localStorage.getItem('dashboard-redirect-route');

    if (redirect) {
      localStorage.removeItem('dashboard-redirect-route');

      this.router.navigate([redirect]);
    }

    // this.experienceRedirection.CheckUserTargetingExperienceLevel();
  }

  ngOnInit() {
  }

}
