/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./ad-teazers-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../teazer/teazer.component.ngfactory";
import * as i3 from "../teazer/teazer.component";
import * as i4 from "@angular/common";
import * as i5 from "./ad-teazers-list.component";
import * as i6 from "../../services/ad-form-manager/ad-form-manager.service";
var styles_AdTeazersListComponent = [i0.styles];
var RenderType_AdTeazersListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AdTeazersListComponent, data: {} });
export { RenderType_AdTeazersListComponent as RenderType_AdTeazersListComponent };
function View_AdTeazersListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-teazer", [], null, null, null, i2.View_TeazerComponent_0, i2.RenderType_TeazerComponent)), i1.ɵdid(2, 114688, null, 0, i3.TeazerComponent, [], { teazer: [0, "teazer"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_AdTeazersListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "ad-teazer-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AdTeazersListComponent_1)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.teazers; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_AdTeazersListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-ad-teazers-list", [], null, null, null, View_AdTeazersListComponent_0, RenderType_AdTeazersListComponent)), i1.ɵdid(1, 114688, null, 0, i5.AdTeazersListComponent, [i6.AdFormManagerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AdTeazersListComponentNgFactory = i1.ɵccf("app-ad-teazers-list", i5.AdTeazersListComponent, View_AdTeazersListComponent_Host_0, {}, {}, []);
export { AdTeazersListComponentNgFactory as AdTeazersListComponentNgFactory };
