<div class="promopost-form-lite">
  <div class="promopost-form-lite__controls">
    <mat-radio-group [(ngModel)]="IsAdToGroup">
      <mat-radio-button color="primary" [value]="true">На вашу группу в ВКонтакте</mat-radio-button>
      <br>
      <mat-radio-button color="primary" [value]="false">На сайт</mat-radio-button>
      <br>
    </mat-radio-group>

    <form [formGroup]="form">
      <div class="promopost-form__examples-container">
        <a class="btn btn-success btn-sm" href="/vk/hidden" rel="nofollow noopener" style="text-align:right; margin: 0; margin-bottom: 8px;" target="_blank"> Посмотреть примеры объявлений </a>
      </div>
      <ng-container *ngIf="HasAdminOpenedGroups() === true; else adminOpenedGroupsError;">
        <vk-form-field [controlsWidth]="controlsWidth" [labelWidth]="labelsWidth">
          <vk-label>Выберите группу</vk-label>
          <select formControlName="groupId" vkInput class="vk-select">
            <ng-container *ngFor="let group of adminGroups">
              <option [value]="group.id">
                {{group.name}}
              </option>
            </ng-container>
          </select>
        </vk-form-field>
      </ng-container>

      <ng-container *ngIf="!IsAdToGroup">
        <vk-form-field [controlsWidth]="controlsWidth" [labelWidth]="labelsWidth">
          <vk-label>Ссылка</vk-label>
          <input
            type="url"
            vkInput
            formControlName="linkAddress"
          >
        </vk-form-field>
      </ng-container>

      <vk-form-field [controlsWidth]="controlsWidth" [labelWidth]="labelsWidth">
        <vk-label>Название ссылки</vk-label>
        <input
          type="url"
          vkInput
          formControlName="linkTitle"
        >
      </vk-form-field>

      <vk-form-field [controlsWidth]="controlsWidth" [labelWidth]="labelsWidth" formArrayName="text">
        <vk-label>
          Текст объявления
          <div class="label-alert" [innerHtml]="label"></div>
        </vk-label>
        <textarea
          id="website_promopost_text"
          vkInput
          [multiline]="true"
          formControlName="0"
          placeholder='Введите текст объявления. Примеры текстов вы можете посмотреть нажав выше кнопку “Посмотреть примеры объявлений"'
          [style.height]="'273px'"
          maxlength="600"
        ></textarea>
        <vk-hint>
          Осталось символов: {{textLength(0)}}
        </vk-hint>
        <vk-error name="text" validator="required">Вы не ввели текст объявления</vk-error>
        <vk-error name="text" validator="minLength">Текст рекламной записи должен содержать хотя бы 1 символ</vk-error>
        <vk-error name="text" validator="maxLength">Текст рекламной записи не должен содержать более 600 символов
        </vk-error>
        <vk-error name="text" validator="emojiCount">Текст рекламной записи не должен содержать более 6 символов emoji
        </vk-error>
      </vk-form-field>
    </form>
  </div>
  <div class="promopost-form-lite__values">
    <app-ad-promoposts-list></app-ad-promoposts-list>
  </div>
</div>

<ng-template #adminOpenedGroupsError>
  <app-missing-admin-groups-alert></app-missing-admin-groups-alert>
  <br>
</ng-template>

<app-new-images-manager
  (selected)="ImageSelected($event)"
  [minWidth]="145"
  [minHeight]="165"
></app-new-images-manager>
