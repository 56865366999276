<ng-container *ngIf="!IsLoading; else loadingScreen">
  <app-new-ad-form-lite (adsSaved)="OnSave($event)"></app-new-ad-form-lite>
</ng-container>
<ng-template #loadingScreen>
  <div class="loading-screen">
    <div class="loading-screen__spinner">
      <mat-spinner diameter="18"></mat-spinner>
    </div>
    <div class="loading-screen__message">Подождите, идет загрузка данных</div>
  </div>
</ng-template>
