<form [formGroup]="form" class="export-settings">
    <mat-radio-group formControlName="section">
        <div>
            <mat-radio-button value="CONTACTS">Выбрать все контакты</mat-radio-button>
            <app-suggestion-info code="ALL_CONTACTS"></app-suggestion-info>
        </div>
        <div>
            <mat-radio-button [value]="'DEALS'">Передавать данные контактов из воронки продаж</mat-radio-button>
            <app-suggestion-info [code]="pipelineLabelSuggestionCode"></app-suggestion-info>
        </div>
        <div>
            <mat-radio-button value="UON_MARKS_EXPORT">По меткам</mat-radio-button>
            <app-suggestion-info code="UON_MARKS_EXPORT"></app-suggestion-info>
        </div>
    </mat-radio-group>
</form>