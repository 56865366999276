<form action="">
<!--  <mat-tree>-->
<!--    <ng-container *ngFor="let category of interestCategories">-->
<!--      <mat-nested-tree-node>-->
<!--        {{category.title}}-->
<!--        <ng-container *ngFor="let child of category.children">-->
<!--          <mat-nested-tree-node>-->
<!--            {{child.title}}-->
<!--          </mat-nested-tree-node>-->
<!--        </ng-container>-->
<!--      </mat-nested-tree-node>-->
<!--    </ng-container>-->
<!--  </mat-tree>-->
  <div class="interest-category-tree">
    <ng-container *ngFor="let category of interestCategories">
      <div class="interest-category-node interest-category-node_level_1">
        <div (click)="toggleTreeCategory(category)" class="interest-category-node__toggle-trigger-area">
          <button class="interest-category-node__toggle-button" *ngIf="hasChildren(category)">
            <mat-icon>
              {{isTreeNodeOpened(category) ? 'keyboard_arrow_down' : 'keyboard_arrow_right' }}
            </mat-icon>
          </button>
        </div>
        <div class="interest-category-node__content">
          <mat-checkbox
            [checked]="isSelected(category) ||isAllChildrenChecked(category)"
            [indeterminate]="isChildrenPartlyChecked(category)"
            (change)="ToggleParentCategorySelect(category)"
          >
            {{category.title}}
          </mat-checkbox>
        </div>
      </div>
      <ng-container *ngFor="let childrenCategory of category.children">
        <div class="interest-category-node interest-category-node_level_2" [ngClass]="{
          'interest-category-node_visible': isTreeNodeOpened(category)
        }">
          <div class="interest-category-node__toggle-trigger-area"></div>
          <div class="interest-category-node__content">
            <mat-checkbox
              [checked]="isSelected(childrenCategory)"
              (change)="ToggleCategorySelect(childrenCategory)"
            >
              {{childrenCategory.title}}
            </mat-checkbox>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</form>
