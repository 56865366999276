var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { IntegrationAccessService } from "../../../api/services";
import { Router } from "@angular/router";
import { IntegrationSessionStore } from "./integration-session.store";
import * as i0 from "@angular/core";
import * as i1 from "../../../api/services/integration-access.service";
import * as i2 from "@angular/router";
import * as i3 from "./integration-session.store";
var IntegrationSessionService = /** @class */ (function () {
    function IntegrationSessionService(integrationAccessService, router, store) {
        this.integrationAccessService = integrationAccessService;
        this.router = router;
        this.store = store;
    }
    IntegrationSessionService.prototype.LoadIntegrationSessionData = function () {
        var _this = this;
        this.integrationAccessService
            .GetCurrentAccessSession()
            .subscribe(function (response) {
            if (response) {
                if (response.data) {
                    try {
                        var accessTo = new Date(response.data.accessTo);
                        accessTo.setHours(0, 0, 0, 0);
                        var currentDate = new Date();
                        currentDate.setHours(0, 0, 0, 0);
                        var hasAccess = currentDate < accessTo;
                        if (hasAccess) {
                            _this.fillSessionData(response.data);
                            return;
                        }
                    }
                    catch (exception) {
                        console.error(exception);
                    }
                }
            }
            _this.NavigateToAccessDeniedPage();
        });
    };
    IntegrationSessionService.prototype.fillSessionData = function (data) {
        this.store.update(function (state) { return (__assign({}, state, { session: data })); });
    };
    IntegrationSessionService.prototype.NavigateToAccessDeniedPage = function () {
        this.router.navigate(['/integrations/access-denied']);
    };
    IntegrationSessionService.ngInjectableDef = i0.defineInjectable({ factory: function IntegrationSessionService_Factory() { return new IntegrationSessionService(i0.inject(i1.IntegrationAccessService), i0.inject(i2.Router), i0.inject(i3.IntegrationSessionStore)); }, token: IntegrationSessionService, providedIn: "root" });
    return IntegrationSessionService;
}());
export { IntegrationSessionService };
