/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./link-query-param-input.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/forms";
import * as i4 from "../../../../../popover/components/popover-menu/popover-menu.component.ngfactory";
import * as i5 from "../../../../../popover/components/popover-menu/popover-menu.component";
import * as i6 from "@angular/cdk/overlay";
import * as i7 from "../../../../../popover/directives/popover-menu-content.directive";
import * as i8 from "../../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i9 from "@angular/material/icon";
import * as i10 from "../../../../../popover/directives/popover-menu-trigger.directive";
import * as i11 from "./link-query-param-input.component";
var styles_LinkQueryParamInputComponent = [i0.styles];
var RenderType_LinkQueryParamInputComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LinkQueryParamInputComponent, data: {} });
export { RenderType_LinkQueryParamInputComponent as RenderType_LinkQueryParamInputComponent };
function View_LinkQueryParamInputComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "link-param"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.SelectItem(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "link-param__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "link-param__description"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.title; _ck(_v, 3, 0, currVal_0); var currVal_1 = _v.context.$implicit.description; _ck(_v, 4, 0, currVal_1); }); }
function View_LinkQueryParamInputComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LinkQueryParamInputComponent_2)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.items; _ck(_v, 2, 0, currVal_0); }, null); }
function View_LinkQueryParamInputComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["textField", 1]], null, 5, "input", [["class", "link-query-param-input__text-field"], ["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 2)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.value = $event) !== false);
        ad = (pd_4 && ad);
    } if (("ngModelChange" === en)) {
        var pd_5 = (_co.emitChange() !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i3.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i3.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.DefaultValueAccessor]), i1.ɵdid(4, 671744, null, 0, i3.NgModel, [[8, null], [8, null], [8, null], [6, i3.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i3.NgControl, null, [i3.NgModel]), i1.ɵdid(6, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.value; _ck(_v, 4, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 6).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 6).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 6).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 6).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 6).ngClassValid; var currVal_5 = i1.ɵnov(_v, 6).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 6).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_LinkQueryParamInputComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "link-query-param-input__text-field link-query-param-input__text-field_value"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 2, null, null, null, null, null, null, null)), i1.ɵdid(2, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i1.ɵpod(3, { selectedItem: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _co.selectedItem); var currVal_1 = _co.selectedItemTemplate; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_LinkQueryParamInputComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { input: 0 }), i1.ɵqud(402653184, 2, { popover: 0 }), (_l()(), i1.ɵeld(2, 16777216, null, null, 18, "app-popover-menu", [], null, null, null, i4.View_PopoverMenuComponent_0, i4.RenderType_PopoverMenuComponent)), i1.ɵdid(3, 114688, [[2, 4]], 2, i5.PopoverMenuComponent, [i6.Overlay, i1.ViewContainerRef], null, null), i1.ɵqud(335544320, 3, { menuTrigger: 0 }), i1.ɵqud(335544320, 4, { menuContent: 0 }), (_l()(), i1.ɵand(0, null, 0, 1, null, View_LinkQueryParamInputComponent_1)), i1.ɵdid(7, 16384, [[4, 4]], 0, i7.PopoverMenuContentDirective, [i1.TemplateRef], null, null), (_l()(), i1.ɵeld(8, 0, null, 0, 12, "div", [["class", "link-query-param-input"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LinkQueryParamInputComponent_3)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["valueTemplate", 2]], null, 0, null, View_LinkQueryParamInputComponent_4)), (_l()(), i1.ɵeld(12, 0, null, null, 3, "button", [["class", "link-query-param-input__clear-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.ClearField() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i8.View_MatIcon_0, i8.RenderType_MatIcon)), i1.ɵdid(14, 9158656, null, 0, i9.MatIcon, [i1.ElementRef, i9.MatIconRegistry, [8, null], [2, i9.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, [" close "])), (_l()(), i1.ɵeld(16, 0, null, null, 4, "button", [["appPopoverMenuTrigger", ""], ["class", "link-query-param-input__menu-trigger"]], null, null, null, null, null)), i1.ɵdid(17, 81920, [[3, 4]], 0, i10.PopoverMenuTriggerDirective, [i1.ElementRef], null, null), (_l()(), i1.ɵeld(18, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i8.View_MatIcon_0, i8.RenderType_MatIcon)), i1.ɵdid(19, 9158656, null, 0, i9.MatIcon, [i1.ElementRef, i9.MatIconRegistry, [8, null], [2, i9.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["more_vert"]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 3, 0); var currVal_0 = _co.ShowTextField; var currVal_1 = i1.ɵnov(_v, 11); _ck(_v, 10, 0, currVal_0, currVal_1); _ck(_v, 14, 0); _ck(_v, 17, 0); _ck(_v, 19, 0); }, function (_ck, _v) { var currVal_2 = i1.ɵnov(_v, 14).inline; var currVal_3 = (((i1.ɵnov(_v, 14).color !== "primary") && (i1.ɵnov(_v, 14).color !== "accent")) && (i1.ɵnov(_v, 14).color !== "warn")); _ck(_v, 13, 0, currVal_2, currVal_3); var currVal_4 = i1.ɵnov(_v, 19).inline; var currVal_5 = (((i1.ɵnov(_v, 19).color !== "primary") && (i1.ɵnov(_v, 19).color !== "accent")) && (i1.ɵnov(_v, 19).color !== "warn")); _ck(_v, 18, 0, currVal_4, currVal_5); }); }
export function View_LinkQueryParamInputComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-link-query-param-input", [], null, null, null, View_LinkQueryParamInputComponent_0, RenderType_LinkQueryParamInputComponent)), i1.ɵprd(5120, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i11.LinkQueryParamInputComponent]), i1.ɵdid(2, 114688, null, 1, i11.LinkQueryParamInputComponent, [], null, null), i1.ɵqud(335544320, 1, { queryParamTemplates: 0 })], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var LinkQueryParamInputComponentNgFactory = i1.ɵccf("app-link-query-param-input", i11.LinkQueryParamInputComponent, View_LinkQueryParamInputComponent_Host_0, { items: "items" }, {}, []);
export { LinkQueryParamInputComponentNgFactory as LinkQueryParamInputComponentNgFactory };
