import { PlatformsTypes } from '../../platforms-types';
import { AmoService, BitrixService, MegaplanService } from '../../../api/services';
import { createCrmUserFromBitrix, createCrmUserFromCrmUser, createCrmUserFromMegaplan, createCrmUserFromMoiSklad, createCrmUserFromUOn } from './crm-user.model';
import { CrmUsersStore } from './crm-users.store';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { BehaviorSubject } from "rxjs";
import { distinctUntilChanged, filter } from "rxjs/operators";
import * as i0 from "@angular/core";
import * as i1 from "./crm-users.store";
import * as i2 from "../../../api/services/amo.service";
import * as i3 from "../../../api/services/bitrix.service";
import * as i4 from "../../../api/services/megaplan.service";
import * as i5 from "@angular/common/http";
var CrmUsersService = /** @class */ (function () {
    function CrmUsersService(store, amoService, bitrixService, megaplanService, http) {
        var _this = this;
        this.store = store;
        this.amoService = amoService;
        this.bitrixService = bitrixService;
        this.megaplanService = megaplanService;
        this.http = http;
        this.moiSkladBaseUrl = environment.backendUri + "/" + environment.apiPrefix + "/moy-sklad";
        this.pageToken = new BehaviorSubject(null);
        this.platformType = null;
        this.pageToken$ = this.pageToken
            .asObservable()
            .pipe(distinctUntilChanged(), filter(function (v) { return v !== null; }));
        this.pageToken$.subscribe(function (pageToken) {
            _this.LoadUsers(_this.platformType, pageToken);
        });
    }
    CrmUsersService.prototype.LoadUsers = function (type, pageToken) {
        if (pageToken === void 0) { pageToken = null; }
        this.platformType = type;
        switch (type) {
            case PlatformsTypes.AMO_CRM_EXPORT:
            case PlatformsTypes.AMO_CRM_IMPORT:
                this.LoadAmoCrmUsers(pageToken);
                break;
            case PlatformsTypes.MEGAPLAN_IMPORT:
            case PlatformsTypes.MEGAPLAN_EXPORT:
                this.LoadMegaplanUsers(pageToken);
                break;
            case PlatformsTypes.BITRIX_EXPORT:
            case PlatformsTypes.BITRIX_IMPORT:
                this.LoadBitrixUsers(pageToken);
                break;
            case PlatformsTypes.UON_IMPORT:
            case PlatformsTypes.UON_EXPORT:
                this.LoadUOnManagers(pageToken);
                break;
            case PlatformsTypes.MOY_SKLAD_EXPORT:
            case PlatformsTypes.MOY_SKLAD_IMPORT:
                this.LoadMoiSkladEmployees(pageToken);
                break;
            default:
                throw new Error("Unknown platform type: " + type);
        }
    };
    CrmUsersService.prototype.LoadAmoCrmUsers = function (pageToken) {
        var _this = this;
        this.amoService.GetUsers()
            .subscribe(function (data) {
            if (data) {
                var users = data.map(function (x) { return createCrmUserFromCrmUser(x); });
                _this.store.set(users);
            }
        });
    };
    CrmUsersService.prototype.LoadBitrixUsers = function (page) {
        var _this = this;
        if (page === void 0) { page = null; }
        if (!page) {
            this.store.set([]);
        }
        this.bitrixService.GetUsers(page)
            .subscribe(function (data) {
            if (data) {
                var users = data['result'] ? data['result'].map(function (x) { return createCrmUserFromBitrix(x); }) : [];
                _this.store.upsertMany(users);
                if (data['next']) {
                    _this.pageToken.next(data['next']);
                }
            }
        });
    };
    CrmUsersService.prototype.LoadMegaplanUsers = function (pageToken) {
        var _this = this;
        this.megaplanService.GetEmployeesAsync({
            limit: null,
            pageAfter: null
        })
            .subscribe(function (data) {
            if (data) {
                var employees = data.data.map(function (employee) { return createCrmUserFromMegaplan(employee); });
                _this.store.set(employees);
            }
        });
    };
    CrmUsersService.prototype.LoadUOnManagers = function (pageToken) {
        var _this = this;
        this.store.set([]);
        this.http.get(environment.backendUri + "/" + environment.apiPrefix + "/u-on-travel/managers")
            .subscribe(function (data) {
            if (data) {
                var employees = data.map(function (e) { return createCrmUserFromUOn(e); });
                _this.store.set(employees);
            }
        });
    };
    CrmUsersService.prototype.LoadMoiSkladEmployees = function (pageToken) {
        var _this = this;
        this.http.get(this.moiSkladBaseUrl + "/employees")
            .subscribe(function (response) {
            console.log(response);
            response.rows
                .map(function (employee) { return createCrmUserFromMoiSklad(employee); })
                .forEach(function (employee) { return _this.store.add(employee); });
        });
    };
    CrmUsersService.ngInjectableDef = i0.defineInjectable({ factory: function CrmUsersService_Factory() { return new CrmUsersService(i0.inject(i1.CrmUsersStore), i0.inject(i2.AmoService), i0.inject(i3.BitrixService), i0.inject(i4.MegaplanService), i0.inject(i5.HttpClient)); }, token: CrmUsersService, providedIn: "root" });
    return CrmUsersService;
}());
export { CrmUsersService };
