import {Injectable, OnInit} from '@angular/core';
import {UserIntegrationService} from "../../../api/services";
import {UserIntegrationStore} from "./user-integration.store";
import {createUserIntegration} from "./user-integration.model";
import {UserIntegrationStatisticsService} from "../user-integration-statistics/user-integration-statistics.service";
import {ActivatedRoute} from "@angular/router";
import {toBoolean} from "@datorama/akita";
import {IntegrationSessionService} from "../integrations-session/integration-session.service";
import {IntegrationSessionQuery} from "../integrations-session/integration-session.query";
import {forkJoin} from "rxjs";
import {filter} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class UsersIntegrationsService implements OnInit {

  public showRemoved = false;

  constructor(
    private store: UserIntegrationStore,
    private usersIntegrationService: UserIntegrationService,
    private statistics: UserIntegrationStatisticsService,
    private route: ActivatedRoute,
    private integrationSessionService: IntegrationSessionService,
    private integrationSessionQuery: IntegrationSessionQuery
  ) {
  }

  /**
   * Загрузка всех не удаленных интеграций пользователя
   * @param showRemoved - показывать удаленные
   * @constructor
   */
  public LoadUserIntegrations(showRemoved = false) {
    this.store.setLoading(true);
    this.usersIntegrationService.GetUserIntegrations({
      showRemoved: showRemoved || this.showRemoved
    })
      .subscribe(({data}) => {
        this.integrationSessionQuery.currentSession$
          .pipe(
            filter(x => x !== null)
          )
          .subscribe(accessSession => {
            console.log(data, 'USER INTEGRATIONS');
            this.store.set(data.map(x => createUserIntegration(x)));
            this.statistics.LoadIntegrationStatisticsById(data.map(x => x.userIntegrationId), accessSession.accessFrom, accessSession.accessTo)
            this.store.setLoading(false);
          });
      })
  }

  /**
   * Удаление пользовательской стратегии по id
   * @param integrationId
   * @constructor
   */
  public RemoveUserIntegration(integrationId) {
    this.usersIntegrationService.RemoveUserIntegration(integrationId)
      .subscribe((data) => {
        this.store.remove(integrationId)
      }, err => {
        console.log(err);
      })
  }

  /**
   * Запуск/Остановка пользовательской интеграции
   * @param integrationId - id интеграции
   * @param status - статус
   * @constructor
   */
  public ChangeUserIntegrationStatus(integrationId, status) {
    this.usersIntegrationService.ChangeUserIntegrationStatus({
      integrationId,
      status
    })
      .subscribe(({data}) => {
        const newStatus = data.status;
        this.LoadUserIntegrations()
      })
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(({showRemoved}) => {
      this.showRemoved = toBoolean(showRemoved);
    })
  }
}
