import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {UonTravelAuthorizationDataModel} from "../../models/uon-travel-authorization-data.model";
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class UonTravelService {

  private baseApiUrl = `${environment.backendUri}/${environment.apiPrefix}/u-on-travel`;

  constructor(
    private http: HttpClient
  ) {
  }

  public Authorize(data: UonTravelAuthorizationDataModel) {
    console.log(`${this.baseApiUrl}/authorize, 'URL'`);
    return this.http.post(`${this.baseApiUrl}/authorize`, data);
  }
}
