import {Injectable, OnInit} from "@angular/core";
import {AffiliateMembersStatisticsByDateQuery} from "./affiliate-members-statistics-by-date.query";
import {AffiliateMembersStatisticsByDateService} from "./affiliate-members-statistics-by-date.service";
import {combineLatest} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AffiliateMemberStatisticsByDateFacade implements OnInit {

  public get statistics$() {
    return this.query.statistics$;
  }

  public get dateFrom$() {
    return this.query.dateFrom$;
  }

  public get dateTo$() {
    return this.query.dateTo$;
  }

  constructor(
    private query: AffiliateMembersStatisticsByDateQuery,
    private service: AffiliateMembersStatisticsByDateService
  ) {
  }

  ngOnInit(): void {
    combineLatest(
      this.dateFrom$,
      this.dateTo$
    )
      .subscribe(() => {
        this.LoadStatistics()
      })
  }

  public LoadStatistics() {
    const dateFrom = this.query.dateFrom;
    const dateTo = this.query.dateTo;

    this.service.get(dateFrom, dateTo)
      .subscribe();
  }

}
