import {Injectable} from '@angular/core';
import {QueryEntity} from '@datorama/akita';
import {CustomFieldValueStore, CustomFieldValueState} from './custom-field-value.store';
import {PlatformsQuery} from "../platforms/platforms.query";
import {flatMap, tap} from "rxjs/operators";

@Injectable({providedIn: 'root'})
export class CustomFieldValueQuery extends QueryEntity<CustomFieldValueState> {

    public CustomFieldsValues$ = this.platformsQuery.selectedOriginPlatformCode$
        .pipe(
            flatMap(code => this.selectAll({
                filterBy: customValue => customValue.PlatformCode === code
            }))
        )

    constructor(
        protected store: CustomFieldValueStore,
        private platformsQuery: PlatformsQuery
    ) {
        super(store);
    }

}
