import {ID} from '@datorama/akita';
import {IntegrationPlatform} from '../../../api/models/integration-platform';

export interface Platform extends IntegrationPlatform {
  id: ID;
}

/**
 * A factory function that creates Platforms
 */
export function createPlatform(params: Partial<Platform>): Platform {
  params.id = params.integrationServiceId;
  return params as Platform;
}
