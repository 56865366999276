<!--<div class="strategy-preview">-->
  <!--<div class="strategy-preview__title">-->
    <!--<mat-radio-button color="primary" [checked]="selected === strategy.code" (change)="change()">-->
      <!--{{strategy.title}}-->
    <!--</mat-radio-button>-->
  <!--</div>-->
  <!--<div class="strategy-preview__description" [innerHtml]="StrategyDescription">-->
  <!--</div>-->
  <!--<a href="javascript:void(0)" class="strategy-preview__show-more" (click)="showMore()">-->
    <!--<ng-container *ngIf="detailsShowed; else detailsHidden">Скрыть</ng-container>-->
    <!--<ng-template #detailsHidden>Подробнее</ng-template>-->
  <!--</a>-->
<!--</div>-->


<div class="vk-container">
  <mat-radio-button color="primary" [checked]="selected === strategy.code" (change)="change()">
  </mat-radio-button>
  <label class="vk-label">{{strategy.title}}</label>
</div>
<div class="vk-container">
  <div [innerHtml]="StrategyDescription"></div>
  <a href="javascript:void(0)" class="strategy-preview__show-more" (click)="showMore()">
    <ng-container *ngIf="detailsShowed; else detailsHidden">Скрыть</ng-container>
    <ng-template #detailsHidden>Подробнее</ng-template>
  </a>
</div>
