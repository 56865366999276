import {Component, Input, OnInit} from '@angular/core';
import {ExperienceLevelService} from '../../../api/services/experience-level.service';
import {UserExperienceLevelService} from '../../../api/services/user-experience-level.service';
import {ExperienceLevelBasedDashboardRedirectionService} from '../../services/experience-level-based-dashboard-redirection.service';

@Component({
  selector: 'app-experience-define-modal',
  templateUrl: './experience-define-modal.component.html',
  styleUrls: ['./experience-define-modal.component.scss']
})
export class ExperienceDefineModalComponent implements OnInit {
  @Input() public experienceField: number = 1;

  public selectedExperience: number = null;
  public experienceLevels: Array<any> = null;

  public sending: boolean = false;

  public done: boolean = true;

  constructor(private experienceApi: ExperienceLevelService, private userExperienceApi: UserExperienceLevelService,
              private experienceRedirection: ExperienceLevelBasedDashboardRedirectionService) {
  }

  ngOnInit() {
    console.log('GetExperiencelLevels');
    this.experienceApi.GetExperiencelLevels({
      experienceFieldId: this.experienceField,
      includeUnselectable: false
    }).subscribe(response => {
      console.log('GetExperiencelLevels RESPONSE', response);
      if (response && response.data) {
        this.experienceLevels = response.data;
      }
    }, error => {
      console.log('GetExperiencelLevels ERROR', error);
      this.experienceLevels = null;
    });
  }

  public AssignExperienceLevel(): void {
    if (this.selectedExperience !== null) {

      this.sending = true;

      localStorage.setItem('experience-targeting-experience-level-id', this.selectedExperience.toString());

      this.userExperienceApi.AssignUserExperienceLevel({
        experienceFieldId: this.experienceField,
        experienceLevelId: this.selectedExperience
      }).subscribe(response => {
        this.experienceRedirection.showExperienceLevelDialog = false;
        this.sending = false;

        this.experienceRedirection.RedirectToDashboardBasedOnExperienceLevel(this.selectedExperience);
      }, error => {
        this.sending = false;
      });
    }
  }
}
