import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AccountsService} from '../../../../api/services/accounts.service';
import {UtilsService} from '../../../../shared/services/utils.service';
import {Occupation} from '../../../../api/models/occupation';
import {OccupationsService} from '../../../../api/services/occupations.service';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-sign-up-form',
  templateUrl: './sign-up-form.component.html',
  styleUrls: ['./sign-up-form.component.scss']
})
export class SignUpFormComponent implements OnInit {

  public form: FormGroup;

  public errors: any;
  public occupations: Array<Occupation>;
  public messages = null;
  public loading = false;
  public formSubmited = false

  @Output()
  public onSubmit: EventEmitter<any> = new EventEmitter<any>();

  public accepted = true;
  public showExternalLogins: boolean = true;

  constructor(
    private accountsService: AccountsService,
    private occupationsService: OccupationsService,
    private route: ActivatedRoute,
  ) {
  }

  public get Errors() {
    return this.errors || UtilsService.GetErrorsByForm('registration', this.form.controls);
  }

  ngOnInit() {
    this.form = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(32)
      ]),
      confirmPassword: new FormControl('', [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(32)
      ]),
      occupationId: new FormControl(null),
      phone: new FormControl('', [
        Validators.required,
      ]),
      name: new FormControl('', [
        Validators.required,
      ])
    });
    this.LoadOccupations();
  }

  submit($event) {
    $event.preventDefault();
    this.errors = {};
    console.log(this.form.valid);
    console.log(this.form.valid);
    this.formSubmited = true;
    if (this.form.valid) {
      this.ToggleLoading();
      this.showExternalLogins = false;
      const formValue = this.form.value;
      const affiliateMemberCode = this.getCookie('affiliate_member_code');
      const utmData = this.getCookie('utm_codes')
      if (affiliateMemberCode) {
        formValue['affiliate_member_code'] = affiliateMemberCode;
        formValue['utmData'] = utmData ? JSON.parse(utmData) : {}
      }
      this.accountsService
        .signUp(this.form.value)
        .subscribe(data => {
          this.messages = data.message;
          this.form.reset();
          this.ToggleLoading();
        }, err => {
          console.log(err);
          this.errors['system'] = err.error.map(x => x.description);
          this.ToggleLoading();
        });
    } else {
      this.errors = UtilsService.GetErrorsByForm('registration', this.form.controls);
      console.log(this.form.touched);
      console.log(this.form.dirty);
    }
  }

  public LoadOccupations() {
    this.ToggleLoading();
    this.occupationsService.GetOccupations()
      .subscribe(x => {
        this.ToggleLoading();
        this.occupations = x;
      });
  }

  public ToggleLoading() {
    this.loading = !this.loading;
  }

  private getCookie(name: string) {
    var pair = document.cookie.match(new RegExp(name + '=([^;]+)'));
    return !!pair ? pair[1] : null;
  }
}
