var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/**
 * A factory function that creates UserIntegration
 */
export function createUserIntegration(params) {
    var ui = __assign({ id: params.userIntegrationId }, params);
    console.log(ui, 'USER INTEGRATION');
    return ui;
}
