import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {DateFilterViewModel} from '../../../models/view-models/date-filter-view-model';
import * as moment from 'moment';
import {Subject} from 'rxjs/Subject';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.scss']
})
export class DateFilterComponent implements OnInit {

  public period = 'today';
  public by = 'day';

  @Input() public date: DateFilterViewModel;
  @Input() public disabled: boolean;
  @Output() public changingDate: EventEmitter<DateFilterViewModel> = new EventEmitter<DateFilterViewModel>();
  public dateFrom = moment(new Date()).format('DD.MM.YYYY');
  public dateTo = moment(new Date()).format('DD.MM.YYYY');
  public fromDateChanged = true;
  public toDateChanged = true;
  public subject: Subject<any> = new Subject<any>();

  public form: FormGroup;

  // public groupToggled: Subject<DateFilterViewModel>
  constructor() {
  }

  ngOnInit() {
    this.form = new FormGroup({
      period: new FormControl(),
      dateFrom: new FormControl(),
      dateTo: new FormControl()
    });
    this.subject.subscribe(() => {
      console.log({
        period: this.period,
        dateFrom: moment(this.dateFrom, 'DD.MM.YYYY').format('YYYY-MM-DD'),
        dateTo: moment(this.dateTo, 'DD.MM.YYYY').format('YYYY-MM-DD'),
      });
      if (this.fromDateChanged && this.toDateChanged) {
        console.log({
          period: this.period,
          dateFrom: moment(this.dateFrom, 'DD.MM.YYYY', true).format('YYYY-MM-DD'),
          dateTo: moment(this.dateTo, 'DD.MM.YYYY', true).format('YYYY-MM-DD'),
        });
        console.log(this.dateFrom, this.dateTo);
        let dateFrom = moment(this.dateFrom, 'DD.MM.YYYY');
        let dateTo = moment(this.dateTo, 'DD.MM.YYYY');
        if (dateFrom.isAfter(dateTo)) {
          const tempDate = dateTo;
          dateTo = dateFrom;
          dateFrom = tempDate;
        }
        console.log(this.dateFrom, this.dateTo);
        this.dateFrom = dateFrom.format('YYYY-MM-DD');
        this.dateTo = dateTo.format('YYYY-MM-DD');
        this.changingDate.emit({
          period: this.period,
          dateFrom: dateFrom.format('YYYY-MM-DD'),
          dateTo: dateTo.format('YYYY-MM-DD'),
        });
      }
    });
  }

  public get startDateButton() {
    return moment(this.dateFrom, 'DD.MM.YYYY', true).locale('ru').format('DD MMM YYYY');
  }

  public get stopDateButton() {
    return moment(this.dateTo, 'DD.MM.YYYY', true).locale('ru').format('DD MMM YYYY');
  }

  ChangeDate() {
    console.log('DateChanged');
    const data = new DateFilterViewModel('', '', '');
    data['period'] = this.by;
    if (this.period.includes('_')) {
      const date = this.period.split('_');
      const units = <moment.unitOfTime.DurationConstructor>date[1];
      this.dateTo = moment(new Date()).format('DD.MM.YYYY');
      console.log(parseInt(date[0], 10), units);
      // const date1 = (date[0] === '7') ? '6' : date[0];
      this.dateFrom = moment(this.dateTo, 'DD.MM.YYYY').clone().subtract(parseInt(date[0], 10), units).format('DD.MM.YYYY');
      console.log(parseInt(date[0], 10), 'DATE CHANGING');
      console.log(parseInt(date[0], 10) > 1, date[1] !== 'day', date);
      if (date[1] !== 'day') {
        this.dateFrom = moment(this.dateFrom, 'DD.MM.YYYY').clone().add(1, 'day').format('DD.MM.YYYY');
        console.log(this.dateFrom);
      } else {
        if (parseInt(date[0], 10) > 1) {
          this.dateFrom = moment(this.dateFrom, 'DD.MM.YYYY').clone().add(1, 'day').format('DD.MM.YYYY');
          console.log(this.dateFrom);
        }
      }
      let dateFrom = moment(this.dateFrom, 'DD.MM.YYYY');
      let dateTo = moment(this.dateTo, 'DD.MM.YYYY');
      if (dateFrom.isAfter(dateTo)) {
        const tempDate = dateTo;
        dateTo = dateFrom;
        dateFrom = tempDate;
      }
      data.dateFrom = dateFrom.format('YYYY-MM-DD');
      data.dateTo = dateTo.format('YYYY-MM-DD');
    } else {
      console.log(this.period, 'ERRORO!');
      switch (this.period) {
        case 'today':
          this.dateFrom = moment(new Date()).format('DD.MM.YYYY');
          console.log(this.dateFrom);
          data.dateFrom = moment(this.dateFrom, 'DD.MM.YYYY').format('YYYY-MM-DD');
          data.dateTo = moment(this.dateFrom, 'DD.MM.YYYY').format('YYYY-MM-DD');
          data['period'] = 'day';
          console.log(data);
          break;
        case 'overall':
          this.dateFrom = '01.06.2012';
          this.dateTo = moment(new Date()).format('DD.MM.YYYY');
          data.dateFrom = moment(this.dateFrom, 'DD.MM.YYYY').format('YYYY-MM-DD');
          data.dateTo = moment(this.dateTo, 'DD.MM.YYYY').format('YYYY-MM-DD');
          data['period'] = 'overall';
          break;
        case 'yesterday':
          this.dateFrom = moment(new Date()).subtract(1, 'days').format('DD.MM.YYYY');
          this.dateTo = moment(new Date()).subtract(1, 'days').format('DD.MM.YYYY');
          data.dateFrom = moment(this.dateFrom, 'DD.MM.YYYY').format('YYYY-MM-DD');
          data.dateTo = moment(this.dateTo, 'DD.MM.YYYY').format('YYYY-MM-DD');
          data['period'] = 'day';
          break;
      }
    }
    this.changingDate.emit(data);
  }

  public ChangeFromDate(date) {
    this.period = 'day';
    this.dateFrom = date;
    this.fromDateChanged = true;
    this.subject.next();
  }

  public ChangeToDate(date) {
    this.period = 'day';
    this.dateTo = date;
    this.toDateChanged = true;
    this.subject.next();
  }

  public stringToDate(str) {
    if (str) {
      const date = str.split('.');
      return new Date(`${date[2]}-${date[1]}-${date[0]}`);
    } else {
      return null;
    }
  }

  ChangeFromDate1(date) {
    console.log(date);
  }
}
