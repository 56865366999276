import { OnInit } from '@angular/core';
import { UserBalanceQuery } from "../../../../../storage/user-balance/state/user-balance.query";
var ToPayTileContentComponent = /** @class */ (function () {
    function ToPayTileContentComponent(userBalanceQuery) {
        this.userBalanceQuery = userBalanceQuery;
    }
    Object.defineProperty(ToPayTileContentComponent.prototype, "amount$", {
        get: function () {
            return this.userBalanceQuery.currentUserBalance$;
        },
        enumerable: true,
        configurable: true
    });
    ToPayTileContentComponent.prototype.ngOnInit = function () {
    };
    return ToPayTileContentComponent;
}());
export { ToPayTileContentComponent };
