import { AkitaNgFormsManager } from '@datorama/akita-ng-forms-manager';
import { PlatformsTypes } from '../../../platforms-types';
import { PlatformsQuery } from '../../../stores/platforms/platforms.query';
import { CrmPipelinesQuery } from "../../../stores/crm-pipelines/crm-pipelines.query";
import * as i0 from "@angular/core";
import * as i1 from "@datorama/akita-ng-forms-manager";
import * as i2 from "../../../stores/platforms/platforms.query";
import * as i3 from "../../../stores/crm-pipelines/crm-pipelines.query";
var MtRemarketingGroupsConfiguratorService = /** @class */ (function () {
    function MtRemarketingGroupsConfiguratorService(fm, platformsService, pipelinesQuery) {
        this.fm = fm;
        this.platformsService = platformsService;
        this.pipelinesQuery = pipelinesQuery;
    }
    MtRemarketingGroupsConfiguratorService.prototype.configure = function (type) {
        var _this = this;
        console.log('YANDEX AUDIENCE TESTINGS');
        var destinationPlatformSettingsValue = this.getValue();
        var originPlatformSettingsValue = this.fm.getForm('originPlatformSettings').value;
        var selectedPipelineId = this.getSelectedPipelineId();
        var result = [];
        var pipelineStatuses = Object
            .keys(destinationPlatformSettingsValue.pipeline_statuses)
            .map(function (key) { return _this.getPipelineStatus(destinationPlatformSettingsValue, key); })
            .map(function (pipelineStatus) { return _this.createPipelineStatusData(pipelineStatus, selectedPipelineId); })
            .filter(function (pipelineStatus) { return _this.IsNotPipelineStatusDataEmpty(pipelineStatus); });
        console.log(pipelineStatuses, selectedPipelineId, 'PIPELINE STATUS');
        console.log(this.isClassicModeAndSplitByPipelines(originPlatformSettingsValue, destinationPlatformSettingsValue), 'IS CLASSICAND SPLITTING');
        if (this.isClassicModeAndSplitByPipelines(originPlatformSettingsValue, destinationPlatformSettingsValue)) {
            console.log(pipelineStatuses, 'PIPELINE STATUSES !!!!');
            result = ['LEADS', 'DEALS'].map(function (filter) { return ({
                code: PlatformsTypes[type],
                filter: [filter],
                properties: {
                    BASES_TYPE: destinationPlatformSettingsValue.type
                },
                data: pipelineStatuses
                    .filter(function (x) { return x.pipelineType === filter; })
                    .map(function (x) {
                    x.pipelineType = undefined;
                    return x;
                })
            }); });
        }
        else {
            console.log(pipelineStatuses, 'PIPELINES STATUSES WTF?????ZZZZ');
            result = [{
                    code: PlatformsTypes[type],
                    properties: {
                        BASES_TYPE: destinationPlatformSettingsValue.type
                    },
                    data: pipelineStatuses.map(function (x) {
                        x.pipelineType = undefined;
                        return x;
                    })
                }];
        }
        console.log(result, 'RESULT');
        return result;
    };
    MtRemarketingGroupsConfiguratorService.prototype.isClassicModeAndSplitByPipelines = function (origin, destination) {
        return origin.isClassicMode && destination.split_by_pipeline_statuses;
    };
    MtRemarketingGroupsConfiguratorService.prototype.IsNotPipelineStatusDataEmpty = function (pipelineStatus) {
        return pipelineStatus.data !== null && pipelineStatus.data.length > 0;
    };
    MtRemarketingGroupsConfiguratorService.prototype.getPipelineStatus = function (destinationPlatformSettingsValue, key) {
        return destinationPlatformSettingsValue.pipeline_statuses[key];
    };
    MtRemarketingGroupsConfiguratorService.prototype.createPipelineStatusData = function (pipelineStatus, selectedPipelineId) {
        return {
            code: pipelineStatus.pipeline_id ? (this.checkBitrixType(pipelineStatus.pipeline_type) ? 0 : selectedPipelineId) + "_" + pipelineStatus.pipeline_id : null,
            pipelineType: pipelineStatus.pipeline_type,
            data: pipelineStatus.pipeline_retargeting_groups
                ? pipelineStatus.pipeline_retargeting_groups.map(function (x) { return x.toString(); })
                : null
        };
    };
    MtRemarketingGroupsConfiguratorService.prototype.getValue = function () {
        var form = this.fm.getForm('destinationPlatformSettings');
        return form ? form.value : null;
    };
    MtRemarketingGroupsConfiguratorService.prototype.getSelectedPipelineId = function () {
        var selectedPipelineId = this.fm.getForm('originPlatformSettings').value.selectedPipelineId;
        console.log(selectedPipelineId, 'SELECTED PIPELINE');
        return this.pipelinesQuery.getEntityIdByPipelineId$(selectedPipelineId);
    };
    MtRemarketingGroupsConfiguratorService.prototype.valid = function (type) {
        var _this = this;
        var val = this.getValue();
        console.log(val, 'VALUE value TYPE');
        if (val && val.pipeline_statuses) {
            return Object.keys(val.pipeline_statuses)
                .map(function (key) { return _this.getPipelineStatus(val, key); })
                .map(function (pipelineStatus) { return _this.hasPipelineStatusRetargetingGroups(pipelineStatus); })
                .reduce(function (acc, curr) { return acc || curr; }, false);
        }
        else {
            return false;
        }
    };
    MtRemarketingGroupsConfiguratorService.prototype.hasPipelineStatusRetargetingGroups = function (pipelineStatus) {
        if (pipelineStatus && pipelineStatus.pipeline_retargeting_groups) {
            return pipelineStatus.pipeline_retargeting_groups.length > 0;
        }
        else {
            return false;
        }
    };
    MtRemarketingGroupsConfiguratorService.prototype.checkBitrixType = function (section) {
        return this.platformsService.getValue().ui.selectedOriginPlatform === PlatformsTypes.BITRIX_EXPORT && section === 'LEADS';
    };
    MtRemarketingGroupsConfiguratorService.ngInjectableDef = i0.defineInjectable({ factory: function MtRemarketingGroupsConfiguratorService_Factory() { return new MtRemarketingGroupsConfiguratorService(i0.inject(i1.AkitaNgFormsManager), i0.inject(i2.PlatformsQuery), i0.inject(i3.CrmPipelinesQuery)); }, token: MtRemarketingGroupsConfiguratorService, providedIn: "root" });
    return MtRemarketingGroupsConfiguratorService;
}());
export { MtRemarketingGroupsConfiguratorService };
