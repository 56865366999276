import {Injectable} from '@angular/core';

@Injectable()
export class SortingService {

    public sortBy: string;
    public sortDescend: boolean;

    constructor() {
    }

    public ChangeSorting(sortBy: string, sortDescend: boolean) {
        this.sortBy = sortBy;
        this.sortDescend = sortDescend;
    }

    GetParams() {
        return {
            sortBy: this.sortBy,
            sortDescend: this.sortDescend
        };
    }
}
