import {AfterViewInit, Component, forwardRef, Input, OnInit} from '@angular/core';
import {AdSourceViewModel} from '../../../../models/ad-source.view-model';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-ad-target-resource-selector',
  templateUrl: './ad-target-resource-selector.component.html',
  styleUrls: ['./ad-target-resource-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AdTargetResourceSelectorComponent),
      multi: true
    }
  ]
})
export class AdTargetResourceSelectorComponent implements OnInit, ControlValueAccessor {

  @Input() public adSources: Array<AdSourceViewModel>;

  public currentValue: string;
  public onChange;
  public onTouch;

  constructor() {
  }

  ngOnInit() {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
    console.log(this.onChange);
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
  }

  writeValue(obj: any): void {
    this.currentValue = obj;
  }

  GetItemId(resourceName: string) {
    return `ad-target-selector-item_${resourceName}`;
  }

  Change() {
    this.onChange(this.currentValue);
  }
}
