var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { map as __map, filter as __filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../api-configuration";
import * as i2 from "@angular/common/http";
var FeaturesService = /** @class */ (function (_super) {
    __extends(FeaturesService, _super);
    function FeaturesService(config, http) {
        return _super.call(this, config, http) || this;
    }
    /**
     * @return Success
     */
    FeaturesService.prototype.GetAllTariffSFeaturesResponse = function () {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/visiblefeatures", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @return Success
     */
    FeaturesService.prototype.GetAllTariffSFeatures = function () {
        return this.GetAllTariffSFeaturesResponse().pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @return Success
     */
    FeaturesService.prototype.GetVisibleFeatureResponse = function () {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/visiblefeatures/stub", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @return Success
     */
    FeaturesService.prototype.GetVisibleFeature = function () {
        return this.GetVisibleFeatureResponse().pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @return Success
     */
    FeaturesService.prototype.GetVisibleFeature_1Response = function () {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/visiblefeatures/visible-feature", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @return Success
     */
    FeaturesService.prototype.GetVisibleFeature_1 = function () {
        return this.GetVisibleFeature_1Response().pipe(__map(function (_r) { return _r.body; }));
    };
    FeaturesService.GetAllTariffSFeaturesPath = '/apiv2/visiblefeatures';
    FeaturesService.GetVisibleFeaturePath = '/apiv2/visiblefeatures/stub';
    FeaturesService.GetVisibleFeature_1Path = '/apiv2/visiblefeatures/visible-feature';
    FeaturesService.ngInjectableDef = i0.defineInjectable({ factory: function FeaturesService_Factory() { return new FeaturesService(i0.inject(i1.ApiConfiguration), i0.inject(i2.HttpClient)); }, token: FeaturesService, providedIn: "root" });
    return FeaturesService;
}(__BaseService));
export { FeaturesService };
