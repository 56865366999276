import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';
import {ConfirmDialogViewModel} from '../../../models/confirm-dialog.view-model';

@Component({
  selector: 'app-configrm-dialog',
  templateUrl: './mat-confirm-dialog.component.html',
  styleUrls: ['./mat-confirm-dialog.component.scss']
})
export class MatConfirmDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogConfig: ConfirmDialogViewModel,
    public dialogRef: MatDialogRef<MatConfirmDialogComponent>
  ) {
  }

  ngOnInit() {
  }

  public Confirm() {
    this.dialogRef.close(true);
  }

  public Reject() {
    this.dialogRef.close(false);
  }
}
