/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./images-manager.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../ad-image/ad-image.component.ngfactory";
import * as i3 from "../ad-image/ad-image.component";
import * as i4 from "@angular/common";
import * as i5 from "@clr/angular";
import * as i6 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i7 from "@angular/material/icon";
import * as i8 from "./images-manager.component";
var styles_ImagesManagerComponent = [i0.styles];
var RenderType_ImagesManagerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ImagesManagerComponent, data: {} });
export { RenderType_ImagesManagerComponent as RenderType_ImagesManagerComponent };
function View_ImagesManagerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-ad-image", [], null, [[null, "selectedChange"], [null, "removeImage"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedChange" === en)) {
        var pd_0 = (_co.OnSelectImage($event) !== false);
        ad = (pd_0 && ad);
    } if (("removeImage" === en)) {
        var pd_1 = (_co.RemoveImage($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_AdImageComponent_0, i2.RenderType_AdImageComponent)), i1.ɵdid(2, 114688, null, 0, i3.AdImageComponent, [], { image: [0, "image"], minWidth: [1, "minWidth"], minHeight: [2, "minHeight"], maxImageSize: [3, "maxImageSize"], selected: [4, "selected"] }, { selectedChange: "selectedChange", removeImage: "removeImage" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.minWidth; var currVal_2 = _co.minHeight; var currVal_3 = _co.maxImageSize; var currVal_4 = _co.selectedImages; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_ImagesManagerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "images-manager"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "vk-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "vk-label"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u043E\u0434\u043D\u043E \u0438\u043B\u0438 \u043D\u0435\u0441\u043A\u043E\u043B\u044C\u043A\u043E \u0438\u0437\u043E\u0431\u0440\u0430\u0436\u0435\u043D\u0438\u0439 \u0434\u043B\u044F \u043E\u0431\u044A\u044F\u0432\u043B\u0435\u043D\u0438\u044F: "])), (_l()(), i1.ɵeld(4, 0, null, null, 10, "div", [["class", "images-manager__list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImagesManagerComponent_1)), i1.ɵdid(6, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 7, "label", [], [[1, "for", 0]], null, null, null, null)), i1.ɵdid(8, 212992, null, 0, i5.ClrLabel, [[2, i5.ɵbe], [2, i5.ɵbf], [2, i5.ɵbg], i1.Renderer2, i1.ElementRef], null, null), (_l()(), i1.ɵeld(9, 0, [["file", 1]], null, 0, "input", [["accept", "image/jpeg,image/png,image/bmp,image/tif,image/gif"], ["class", "hidden"], ["multiple", ""], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.FilesSelected(i1.ɵnov(_v, 9).files) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 4, "span", [["class", "image-loader-button"], ["matRipple", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 3, "span", [["class", "image-loader-button__image"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["color", "primary"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i6.View_MatIcon_0, i6.RenderType_MatIcon)), i1.ɵdid(13, 9158656, null, 0, i7.MatIcon, [i1.ElementRef, i7.MatIconRegistry, [8, null], [2, i7.MAT_ICON_LOCATION]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["add_a_photo"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.files; _ck(_v, 6, 0, currVal_0); _ck(_v, 8, 0); var currVal_4 = "primary"; _ck(_v, 13, 0, currVal_4); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 8).forAttr; _ck(_v, 7, 0, currVal_1); var currVal_2 = i1.ɵnov(_v, 13).inline; var currVal_3 = (((i1.ɵnov(_v, 13).color !== "primary") && (i1.ɵnov(_v, 13).color !== "accent")) && (i1.ɵnov(_v, 13).color !== "warn")); _ck(_v, 12, 0, currVal_2, currVal_3); }); }
export function View_ImagesManagerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-images-manager", [], null, null, null, View_ImagesManagerComponent_0, RenderType_ImagesManagerComponent)), i1.ɵdid(1, 114688, null, 0, i8.ImagesManagerComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ImagesManagerComponentNgFactory = i1.ɵccf("app-images-manager", i8.ImagesManagerComponent, View_ImagesManagerComponent_Host_0, { minWidth: "minWidth", minHeight: "minHeight", maxCountOfSelected: "maxCountOfSelected" }, { selected: "selected" }, []);
export { ImagesManagerComponentNgFactory as ImagesManagerComponentNgFactory };
