import {Pipe, PipeTransform} from '@angular/core';
import {DatePipe} from '@angular/common';



@Pipe({
  name: 'dateFormating'
})
export class DateFormatingPipe implements PipeTransform {

  transform(value: Date): string {
    const datePipe = new DatePipe('ru-RU');
    const formatedDate = datePipe.transform(value, 'dd MMM yyyy в hh:mm');

    const day = datePipe.transform(value, 'dd');
    const monthDigit: number = parseInt(datePipe.transform(value, 'MM'), 10);
    const year = datePipe.transform(value, 'yyyy');

    let finalDate = day + ' ' + this.GetMonthLiteral(monthDigit);

    if (parseInt(year, 10) !== parseInt(datePipe.transform(Date.now(), 'yyyy'), 10))
      finalDate += ' ' + year;

    finalDate += ' в ' + datePipe.transform(value, 'HH:mm')
    return finalDate;
  }

  GetMonthLiteral(monthNumber: number) {
    let monthLiteral = 'Default';

    switch (monthNumber) {
      case 1:
        monthLiteral = 'Января';
        break;
      case 2:
        monthLiteral = 'Февраля';
        break;
      case 3:
        monthLiteral = 'Марта';
        break;
      case 4:
        monthLiteral = 'Апреля';
        break;
      case 5:
        monthLiteral = 'Мая';
        break;
      case 6:
        monthLiteral = 'Июня';
        break;
      case 7:
        monthLiteral = 'Июля';
        break;
      case 8:
        monthLiteral = 'Августа';
        break;
      case 9:
        monthLiteral = 'Сентября';
        break;
      case 10:
        monthLiteral = 'Октября';
        break;
      case 11:
        monthLiteral = 'Ноября';
        break;
      case 12:
        monthLiteral = 'Декабря';
        break;
    }

    return monthLiteral;
  }
}
