<form class="time-picker" [formGroup]="form">
  <label class="time-picker__hours">
    <button class="time-picker__button">
      <span class="ion-md-arrow-gropup"></span>
    </button>
    <input type="text" class="time-picker__input" formControlName="hour" (input)="changeHour($event)" placeholder="00" maxlength="4">
    <button class="time-picker__button">
      <span class="ion-md-arrow-gropdown"></span>
    </button>
  </label>
  <div class="time-picker__divider">
    :
  </div>
  <label class="time-picker__minutes">
    <button class="time-picker__button">
      <span class="ion-md-arrow-gropup"></span>
    </button>
    <input type="text" class="time-picker__input" formControlName="minutes" (input)="changeMinutes($event)" placeholder="00" maxlength="4">
    <button class="time-picker__button">
      <span class="ion-md-arrow-gropdown"></span>
    </button>
  </label>
</form>
