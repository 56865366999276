import {Injectable} from '@angular/core';
import {Query} from '@datorama/akita';
import {AffiliateUserInfoStore, AffiliateUserInfoState} from './affiliate-user-info.store';

@Injectable({providedIn: 'root'})
export class AffiliateUserInfoQuery extends Query<AffiliateUserInfoState> {

  public affiliateUserInfo$ = this.select(x => x);

  public get affiliateUserInfo() {
    return this.getValue();
  }

  constructor(protected store: AffiliateUserInfoStore) {
    super(store);
  }

}
