import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {IIntegrationServiceModel} from '../../../../models/integration-service.model';
// import {ServicesFacade} from '../../../../store/facades/services.facade';
import {StoreService} from "../../../../services/store.service";
import {IntegrationFormService} from "../../services/integration-form.service";
import {IntegrationService, UserIntegrationService} from "../../../../../api/services";
import {IntegrationConfigurator} from "../../configurators/Integration-configurator";
import {MatDialogRef} from "@angular/material";

@Component({
  selector: 'app-integration-create-edit-form',
  templateUrl: './integration-create-edit-form.component.html',
  styleUrls: ['./integration-create-edit-form.component.scss']
})
export class IntegrationCreateEditFormComponent implements OnInit {
  public leftServices$: Observable<IIntegrationServiceModel[]>;
  public rightServices$: Observable<IIntegrationServiceModel[]>;

  public get leftServices() {
    return (this.store.services || []).filter(x => x.isDataOrigin)
  }

  public get isOriginServiceSelected() {
    return this.store.IsOriginServiceSelected;
  }

  public get rightServices() {
    const relations = (this.store.relations || []).filter(x => x.fromService.code === this.formsService.form.get('leftService').value).map(x => x.toService.code);
    const rightServices = (this.store.services || []).filter(x => relations.includes(x.code));
    return rightServices;
  }

  constructor(
    private store: StoreService,
    private formsService: IntegrationFormService,
    private integrationService: IntegrationService,
    private userIntegrationService: UserIntegrationService,
    private dialogRef: MatDialogRef<IntegrationCreateEditFormComponent>
  ) {
  }

  ngOnInit() {
    // this.leftServices$ = this.services.leftServices$;
    // this.rightServices$ = this.services.rightServices$;
  }

  SaveIntegrations() {
    const data = this.formsService.form.value;
    console.log(data);
    this.userIntegrationService.AddUserIntegration({
      fromId: this.store.services.find(x => x.code === data.leftService).integrationServiceId,
      toId: this.store.services.find(x => x.code === data.rightService).integrationServiceId,
      model: {
        name: data.name,
        originServiceData: new IntegrationConfigurator(data.leftService, data).configure(),
        destinationServiceData: new IntegrationConfigurator(data.rightService, data).configure()
      }
    })
      .subscribe(data => {
        this.dialogRef.close(true)
      });
    console.log();
  }
}
