import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-segmentation-info',
  templateUrl: './segmentation-info.component.html',
  styleUrls: ['./segmentation-info.component.scss']
})
export class SegmentationInfoComponent implements OnInit {

  @Input() public segmentationType: string;
  @Output() advancedModeToggled: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit() {
  }

  ToggleAdvancedMode() {
    this.advancedModeToggled.emit();
  }
}
