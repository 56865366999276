import { Injectable } from '@angular/core';

@Injectable()
export class AdblockService {

  constructor() { }

  public get AdBlockEnabled () {
    // @ts-ignore
    return window['adBlockDisabled'] === undefined;
  }

}
