import { OnInit } from '@angular/core';
import { FormArray, FormGroup } from "@angular/forms";
import { CopyingService } from "../../../services/copying.service";
var CopyingBySexAndAgeComponent = /** @class */ (function () {
    function CopyingBySexAndAgeComponent(copyingService) {
        this.copyingService = copyingService;
        this.form = null;
    }
    Object.defineProperty(CopyingBySexAndAgeComponent.prototype, "ages", {
        get: function () {
            return this.form ? this.form.get('ageItems').controls : [];
        },
        enumerable: true,
        configurable: true
    });
    CopyingBySexAndAgeComponent.prototype.ngOnInit = function () {
        this.form = this.copyingService.form.get('sexAndAgeConfiguration');
    };
    CopyingBySexAndAgeComponent.prototype.addControl = function () {
        this.copyingService.addSexAndAgeGroup();
    };
    CopyingBySexAndAgeComponent.prototype.removeControl = function (i) {
        this.copyingService.removeSexAndAgeGroup(i);
    };
    return CopyingBySexAndAgeComponent;
}());
export { CopyingBySexAndAgeComponent };
