import { OnInit } from '@angular/core';
import { PaymentsService } from '../../services/payments.service';
import { isNullOrUndefined } from 'util';
var PaymentsTariffFeatureStatusComponent = /** @class */ (function () {
    function PaymentsTariffFeatureStatusComponent(paymentsService) {
        this.paymentsService = paymentsService;
        this.featureTariffPlanStatus = null;
    }
    Object.defineProperty(PaymentsTariffFeatureStatusComponent.prototype, "contains", {
        get: function () {
            return !isNullOrUndefined(this.featureTariffPlanStatus);
        },
        enumerable: true,
        configurable: true
    });
    PaymentsTariffFeatureStatusComponent.prototype.ngOnInit = function () {
        this.featureTariffPlanStatus = this.paymentsService.GetFeatureTariffPlanStatus(this.feature.id, this.tariff.id);
        console.log(this.featureTariffPlanStatus, 'FEATURE STATUS');
    };
    return PaymentsTariffFeatureStatusComponent;
}());
export { PaymentsTariffFeatureStatusComponent };
