import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-ad-audience',
  templateUrl: './ad-audience.component.html',
  styleUrls: ['./ad-audience.component.scss']
})
export class AdAudienceComponent implements OnInit {

  @Input() public targeting: any;
  @Input() public isDirty: any;
  public get TargetingAudienceCount () {
    return this.targeting ? this.targeting.audience_count : 0;
  }

  constructor() { }

  ngOnInit() {
  }

}
