import { FormGroup } from '@angular/forms';
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
var AbstractFormServiceService = /** @class */ (function () {
    function AbstractFormServiceService(adForm) {
        this.adForm = adForm;
    }
    AbstractFormServiceService.prototype.getControl = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var firstControlName = args.shift();
        var control = this.adForm.controls[firstControlName];
        for (var _a = 0, args_1 = args; _a < args_1.length; _a++) {
            var controlName = args_1[_a];
            if (control) {
                control = control.get(controlName);
            }
        }
        return control;
    };
    AbstractFormServiceService.ngInjectableDef = i0.defineInjectable({ factory: function AbstractFormServiceService_Factory() { return new AbstractFormServiceService(i0.inject(i1.FormGroup)); }, token: AbstractFormServiceService, providedIn: "root" });
    return AbstractFormServiceService;
}());
export { AbstractFormServiceService };
