var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { map as __map, filter as __filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../api-configuration";
import * as i2 from "@angular/common/http";
var UserIntegrationService = /** @class */ (function (_super) {
    __extends(UserIntegrationService, _super);
    function UserIntegrationService(config, http) {
        return _super.call(this, config, http) || this;
    }
    /**
     * @param params The `UserIntegrationService.GetUserIntegrationsParams` containing the following parameters:
     *
     * - `showRemoved`:
     *
     * - `integrationId`:
     *
     * @return Success
     */
    UserIntegrationService.prototype.GetUserIntegrationsResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.showRemoved != null)
            __params = __params.set('showRemoved', params.showRemoved.toString());
        if (params.integrationId != null)
            __params = __params.set('integrationId', params.integrationId.toString());
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/user-integration", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `UserIntegrationService.GetUserIntegrationsParams` containing the following parameters:
     *
     * - `showRemoved`:
     *
     * - `integrationId`:
     *
     * @return Success
     */
    UserIntegrationService.prototype.GetUserIntegrations = function (params) {
        return this.GetUserIntegrationsResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `UserIntegrationService.AddUserIntegrationParams` containing the following parameters:
     *
     * - `toId`:
     *
     * - `fromId`:
     *
     * - `model`:
     *
     * @return Success
     */
    UserIntegrationService.prototype.AddUserIntegrationResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.toId != null)
            __params = __params.set('toId', params.toId.toString());
        if (params.fromId != null)
            __params = __params.set('fromId', params.fromId.toString());
        __body = params.model;
        var req = new HttpRequest('POST', this.rootUrl + "/apiv2/user-integration", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `UserIntegrationService.AddUserIntegrationParams` containing the following parameters:
     *
     * - `toId`:
     *
     * - `fromId`:
     *
     * - `model`:
     *
     * @return Success
     */
    UserIntegrationService.prototype.AddUserIntegration = function (params) {
        return this.AddUserIntegrationResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param integrationId undefined
     * @return Success
     */
    UserIntegrationService.prototype.RemoveUserIntegrationResponse = function (integrationId) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (integrationId != null)
            __params = __params.set('integrationId', integrationId.toString());
        var req = new HttpRequest('DELETE', this.rootUrl + "/apiv2/user-integration", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param integrationId undefined
     * @return Success
     */
    UserIntegrationService.prototype.RemoveUserIntegration = function (integrationId) {
        return this.RemoveUserIntegrationResponse(integrationId).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `UserIntegrationService.GetIntegrationDetailedStatisticsParams` containing the following parameters:
     *
     * - `sortDesc`:
     *
     * - `offset`:
     *
     * - `integrationId`:
     *
     * - `dateTo`:
     *
     * - `dateFrom`:
     *
     * @return Success
     */
    UserIntegrationService.prototype.GetIntegrationDetailedStatisticsResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.sortDesc != null)
            __params = __params.set('sortDesc', params.sortDesc.toString());
        if (params.offset != null)
            __params = __params.set('offset', params.offset.toString());
        if (params.dateTo != null)
            __params = __params.set('dateTo', params.dateTo.toString());
        if (params.dateFrom != null)
            __params = __params.set('dateFrom', params.dateFrom.toString());
        var req = new HttpRequest('GET', this.rootUrl + ("/apiv2/user-integration/" + (params.integrationId) + "/stats/detailed"), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `UserIntegrationService.GetIntegrationDetailedStatisticsParams` containing the following parameters:
     *
     * - `sortDesc`:
     *
     * - `offset`:
     *
     * - `integrationId`:
     *
     * - `dateTo`:
     *
     * - `dateFrom`:
     *
     * @return Success
     */
    UserIntegrationService.prototype.GetIntegrationDetailedStatistics = function (params) {
        return this.GetIntegrationDetailedStatisticsResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `UserIntegrationService.GetIntegrationSummaryStatisticsParams` containing the following parameters:
     *
     * - `integrationId`:
     *
     * - `dateTo`:
     *
     * - `dateFrom`:
     *
     * @return Success
     */
    UserIntegrationService.prototype.GetIntegrationSummaryStatisticsResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.dateTo != null)
            __params = __params.set('dateTo', params.dateTo.toString());
        if (params.dateFrom != null)
            __params = __params.set('dateFrom', params.dateFrom.toString());
        var req = new HttpRequest('GET', this.rootUrl + ("/apiv2/user-integration/" + (params.integrationId) + "/stats"), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `UserIntegrationService.GetIntegrationSummaryStatisticsParams` containing the following parameters:
     *
     * - `integrationId`:
     *
     * - `dateTo`:
     *
     * - `dateFrom`:
     *
     * @return Success
     */
    UserIntegrationService.prototype.GetIntegrationSummaryStatistics = function (params) {
        return this.GetIntegrationSummaryStatisticsResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `UserIntegrationService.RenameUserIntegrationParams` containing the following parameters:
     *
     * - `integrationId`:
     *
     * - `name`:
     *
     * @return Success
     */
    UserIntegrationService.prototype.RenameUserIntegrationResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.integrationId != null)
            __params = __params.set('integrationId', params.integrationId.toString());
        __body = params.name;
        var req = new HttpRequest('PUT', this.rootUrl + "/apiv2/user-integration/name", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `UserIntegrationService.RenameUserIntegrationParams` containing the following parameters:
     *
     * - `integrationId`:
     *
     * - `name`:
     *
     * @return Success
     */
    UserIntegrationService.prototype.RenameUserIntegration = function (params) {
        return this.RenameUserIntegrationResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `UserIntegrationService.ChangeUserIntegrationStatusParams` containing the following parameters:
     *
     * - `status`:
     *
     * - `integrationId`:
     *
     * @return Success
     */
    UserIntegrationService.prototype.ChangeUserIntegrationStatusResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.status != null)
            __params = __params.set('status', params.status.toString());
        if (params.integrationId != null)
            __params = __params.set('integrationId', params.integrationId.toString());
        var req = new HttpRequest('POST', this.rootUrl + "/apiv2/user-integration/status", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `UserIntegrationService.ChangeUserIntegrationStatusParams` containing the following parameters:
     *
     * - `status`:
     *
     * - `integrationId`:
     *
     * @return Success
     */
    UserIntegrationService.prototype.ChangeUserIntegrationStatus = function (params) {
        return this.ChangeUserIntegrationStatusResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    UserIntegrationService.GetUserIntegrationsPath = '/apiv2/user-integration';
    UserIntegrationService.AddUserIntegrationPath = '/apiv2/user-integration';
    UserIntegrationService.RemoveUserIntegrationPath = '/apiv2/user-integration';
    UserIntegrationService.GetIntegrationDetailedStatisticsPath = '/apiv2/user-integration/{integrationId}/stats/detailed';
    UserIntegrationService.GetIntegrationSummaryStatisticsPath = '/apiv2/user-integration/{integrationId}/stats';
    UserIntegrationService.RenameUserIntegrationPath = '/apiv2/user-integration/name';
    UserIntegrationService.ChangeUserIntegrationStatusPath = '/apiv2/user-integration/status';
    UserIntegrationService.ngInjectableDef = i0.defineInjectable({ factory: function UserIntegrationService_Factory() { return new UserIntegrationService(i0.inject(i1.ApiConfiguration), i0.inject(i2.HttpClient)); }, token: UserIntegrationService, providedIn: "root" });
    return UserIntegrationService;
}(__BaseService));
export { UserIntegrationService };
