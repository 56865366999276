import {Component, Input, OnInit} from '@angular/core';
import {ChatMessageModel} from '../../models/chat-message-model';
import {DateFormatingPipe} from "../../../shared/pipes/date-formating.pipe";

@Component({
  selector: 'app-chat-message',
  templateUrl: './chat-message.component.html',
  styleUrls: ['./chat-message.component.scss']
})
export class ChatMessageComponent implements OnInit {
  @Input() public showReadStatus: boolean = false;
  @Input() public message: ChatMessageModel = null;

  constructor() { }

  ngOnInit() {
  }

  public GetMessageReadTip(): string {
    return (this.message!.isRead) ? `Прочитано в ${new DateFormatingPipe().transform(this.message.readAt)}` : 'Не прочитано';
  }
}
