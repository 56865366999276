import { OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AdFormManagerService } from '../../services/ad-form-manager/ad-form-manager.service';
var NewTargetingSettingsComponent = /** @class */ (function () {
    function NewTargetingSettingsComponent(adFormManagerService) {
        this.adFormManagerService = adFormManagerService;
        this.searchTargetGroups = new FormControl('');
        this.searchTargetGroupsNot = new FormControl('');
        this.controlWidth = '280px';
        this.labelWidth = '200px';
        this.TipGroups = 'Вы можете показывать объявление, подписчикам групп конкурентов или смежных по вашей тематике групп. Для этого введите названия групп, либо слова которые могут содержать названия групп.\n' +
            '\n' +
            'Пример:\n' +
            'Если ваш вид деятельности - салон красоты, то в поле Название группы введите “Салон красоты”, “Наращивание ресниц” или “Брови” и т.п. \n' +
            '\n' +
            'Выберите страну и город где находятся ваши клиенты. \n' +
            'Если вы и ваши клиенты находятся, например, в городе Екатеринбург, то выберите в поле Страна - “Россия”, а в поле Город или регион: Екатеринбург.\n' +
            '\n' +
            'После выбора групп, справа, будет показываться какому количеству пользователей может быть показано ваше объявление.\n';
        this.resultColumns = ['avatar', 'name', 'functions'];
    }
    Object.defineProperty(NewTargetingSettingsComponent.prototype, "loadingSearchGroups", {
        get: function () {
            return this.adFormManagerService.loadingSearchGroups;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewTargetingSettingsComponent.prototype, "loadingTargetingStats", {
        get: function () {
            return this.adFormManagerService.targetingStatsLoading;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewTargetingSettingsComponent.prototype, "groups", {
        get: function () {
            return this.adFormManagerService.groups;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewTargetingSettingsComponent.prototype, "IsSearchGroupsCitiesDisabled", {
        get: function () {
            return this.adFormManagerService.IsSearchCitiesDisabled();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewTargetingSettingsComponent.prototype, "IsSearchGroupCountryDisabled", {
        get: function () {
            return this.adFormManagerService.IsSearchGroupsCountryDisabled();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewTargetingSettingsComponent.prototype, "targetingStats", {
        get: function () {
            return this.adFormManagerService.targetingStats;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewTargetingSettingsComponent.prototype, "selectedGroups", {
        get: function () {
            return this.adFormManagerService.selectedGroups;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewTargetingSettingsComponent.prototype, "citySearchString", {
        get: function () {
            return this.adFormManagerService.searchCitiesControl;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewTargetingSettingsComponent.prototype, "citySearchGroupCities", {
        get: function () {
            return this.adFormManagerService.searchGroupsCity;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewTargetingSettingsComponent.prototype, "searchGroupsCities", {
        get: function () {
            return this.adFormManagerService.searchGroupsCities$;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewTargetingSettingsComponent.prototype, "countries", {
        get: function () {
            return this.adFormManagerService.countries;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewTargetingSettingsComponent.prototype, "cities", {
        get: function () {
            return this.adFormManagerService.displayedCities;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewTargetingSettingsComponent.prototype, "form", {
        get: function () {
            return this.adFormManagerService.getControl('targetingData');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewTargetingSettingsComponent.prototype, "searchForm", {
        get: function () {
            return this.adFormManagerService.searchGroupsForm;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewTargetingSettingsComponent.prototype, "agesFrom", {
        get: function () {
            return this.adFormManagerService.agesFrom;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewTargetingSettingsComponent.prototype, "agesTo", {
        get: function () {
            return this.adFormManagerService.agesTo;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewTargetingSettingsComponent.prototype, "ShowRetargetingGroups", {
        get: function () {
            return this.adFormManagerService.isPro;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewTargetingSettingsComponent.prototype, "TargetGroups", {
        get: function () {
            var groups = this.adFormManagerService.SelectedTargetGroups;
            var targetGroupName = this.searchTargetGroups.value.toLowerCase();
            if (targetGroupName !== '') {
                groups = groups.filter(function (targetGroup) { return targetGroup.name.toLowerCase().match(targetGroupName); });
            }
            return groups;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewTargetingSettingsComponent.prototype, "TargetGroupsNot", {
        get: function () {
            var groups = this.adFormManagerService.SelectedTargetGroupsNot;
            var targetGroupName = this.searchTargetGroupsNot.value.toLowerCase();
            if (targetGroupName !== '') {
                groups = groups.filter(function (targetGroup) { return targetGroup.name.toLowerCase().match(targetGroupName); });
            }
            return groups;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewTargetingSettingsComponent.prototype, "SelectedTargetingGroupsName", {
        get: function () {
            var targetingGroups = this.adFormManagerService.getControl('targetingData', 'targetGroups').value || [];
            if (this.adFormManagerService.targetingGroups) {
                return this.adFormManagerService
                    .targetingGroups
                    .filter(function (group) { return targetingGroups.includes(group.id); })
                    .map(function (group) { return group.name; });
            }
            else {
                return [];
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewTargetingSettingsComponent.prototype, "SelectedTargetingGroupsNotName", {
        get: function () {
            var targetingGroups = this.adFormManagerService.getControl('targetingData', 'targetGroupsNot').value || [];
            if (this.adFormManagerService.targetingGroups) {
                return this.adFormManagerService
                    .targetingGroups
                    .filter(function (group) { return targetingGroups.includes(group.id); })
                    .map(function (group) { return group.name; });
            }
            else {
                return [];
            }
        },
        enumerable: true,
        configurable: true
    });
    NewTargetingSettingsComponent.prototype.GetIsTitleValid = function () {
        return this.adFormManagerService.IsSearchGroupTitleValid();
    };
    NewTargetingSettingsComponent.prototype.GetIsCountryValid = function () {
        return this.adFormManagerService.IsSearchGroupCountryValid();
    };
    NewTargetingSettingsComponent.prototype.ngOnInit = function () {
        console.log(this.TargetGroups, 'TARGETING GROUPS');
    };
    NewTargetingSettingsComponent.prototype.isGroupSelected = function (group) {
        return this.adFormManagerService.isGroupSelected(group);
    };
    return NewTargetingSettingsComponent;
}());
export { NewTargetingSettingsComponent };
