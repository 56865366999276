import { Component, OnInit } from '@angular/core';
import {FormGroup} from "@angular/forms";
import {CopyingService} from "../../../services/copying.service";

@Component({
  selector: 'app-copying-by-group-actives-file',
  templateUrl: './copying-by-group-actives-file.component.html',
  styleUrls: ['./copying-by-group-actives-file.component.scss']
})
export class CopyingByGroupActivesFileComponent implements OnInit {

  public form: FormGroup;

  constructor(
    private copyingService: CopyingService
  ) {
  }

  ngOnInit() {
    this.form = this.copyingService.form.get('groupActiveFromFile') as FormGroup;
  }

  SelectFile(fileInput) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.form.patchValue({
          groupActiveList: e.target.result.split('\n')
            .map(x => x.replace('https://vk.com/', ''))
        })
      };
      reader.readAsText(fileInput.target.files[0]);
    }
  }

}
