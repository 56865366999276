import {Directive, ElementRef, forwardRef, HostListener, Inject, OnInit, Renderer2} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Directive({
  selector: '[contentEditable][formControlName],[contentEditable][formControl],[contentEditable][ngModel]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ContentEditableDirective),
      multi: true
    }
  ]
})
export class ContentEditableDirective implements ControlValueAccessor, OnInit {
  private onTouched = () => {
  };
  private onChange: (value: string) => void = () => {
  };

  constructor(
    @Inject(ElementRef) public readonly elementRef: ElementRef,
    @Inject(Renderer2) public readonly renderer: Renderer2,
  ) {
  }

  @HostListener('input')
  onInput() {
    this.onChange(this.elementRef.nativeElement.innerText);
  }

  @HostListener('blur')
  onBlur() {
    this.onTouched();
  }

  setDisabledState(disabled: boolean) {
    this.renderer.setAttribute(
      this.elementRef.nativeElement,
      'contenteditable',
      String(!disabled),
    );
  }

  writeValue(value: string) {
    this.elementRef.nativeElement.getAttribute('placeholder');
    this.renderer.setProperty(
      this.elementRef.nativeElement,
      'innerHTML',
      value === '' ? '' : value,
    );
  }

  registerOnChange(onChange: (value: string) => void) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void) {
    this.onTouched = onTouched;
  }

  ngOnInit(): void {
    this.elementRef.nativeElement.addEventListener('paste', function (pasteEvent) {
      pasteEvent.preventDefault();
      const pasteEventExecutable = (pasteEvent.originalEvent || pasteEvent)
      let content = "";
      if (pasteEvent.clipboardData) {
        content = pasteEventExecutable.clipboardData.getData('text/plain');
        document.execCommand('insertText', false, content)
      }
    })
  }

}
