import {Component, ContentChild, OnInit, QueryList, ViewChildren} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {ContentEditableDirective} from '../../../../../shared/directives/content-editable.directive';
import {IntegrationFormService} from '../../services/integration-form.service';
import {combineLatest, forkJoin} from "rxjs";
import {StoreService} from "../../../../services/store.service";

@Component({
  selector: 'app-integration-form-name',
  templateUrl: './integration-form-name.component.html',
  styleUrls: ['./integration-form-name.component.scss']
})
export class IntegrationFormNameComponent implements OnInit {

  public form: FormGroup;
  @ViewChildren(ContentEditableDirective) public contentEditable: QueryList<ContentEditableDirective>;
  editable: boolean = true;

  constructor(
    private integrationFormService: IntegrationFormService,
    private store: StoreService
  ) {
    this.form = this.integrationFormService.form;
  }

  ngOnInit() {
    // console.log('NAME INTEGRAION ');
    // this.form.get('leftService').valueChanges.subscribe(x => {
    //   console.log(x, 'NAME WWW');
    // });
    combineLatest(
      this.form.get('leftService').valueChanges,
      this.form.get('rightService').valueChanges
    )
      .subscribe(([leftServiceName, rightServiceName]) => {
        const relationName = this.store.getRelationNameByServiceCodes(leftServiceName, rightServiceName);
        if (relationName) {
          this.form.patchValue({name: relationName});
        }
      })
  }

  public focusOnEditable() {
    this.editable = true;
    this.contentEditable.first.elementRef.nativeElement.focus();
  }
}
