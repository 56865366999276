<div fxLayout="row" fxLayoutAlign="space-between">
  <div class="vk-ad-cabinet-header vk-ad-cabinet-header_large">
    Новое объявление
  </div>
  <div class="alert">
    <div class="alert-items">
      <a href="https://postmonitor.ru/blog/instrukciya-po-sozdaniyu-obyavleniya-v-vkontakte-cherez-servis-postmonitor-dlya-novichkov" target="_blank" rel="nofollow noopener">
        Инструкция по созданию объявлений
      </a>
    </div>
  </div>
</div>

<!--<app-new-style-modal [isVisible]="visibleExpirationNotification" headerBackgroundColor="#ff8a80">-->
  <!--<div class="new-modal-header-content">-->
    <!--Истек срок доступа-->
  <!--</div>-->
  <!--<div class="new-modal-body-content">-->
    <!--Для продолжения работы с функционалом перейдите к выбору тарифного плана-->
  <!--</div>-->
  <!--<div class="new-modal-buttons">-->
    <!--<a class="vk-standart-button" href="/payments-create" target="_blank" rel="noopener nofollow">-->
      <!--Перейти к выбору тарифного плана-->
    <!--</a>-->
  <!--</div>-->
<!--</app-new-style-modal>-->

<app-ad-form-lite
  (AdSaved)="SaveAd($event)"
  [isSubmitLoading]="isLoading"
></app-ad-form-lite>
