import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormFieldComponent} from './components/form-field/form-field.component';
import {InputDirective} from './directives/input.directive';
import {LabelComponent} from './components/label/label.component';
import {ErrorComponent} from './components/error/error.component';
import {ControlContainer, FormsModule, ReactiveFormsModule} from '@angular/forms';
import { HintComponent } from './components/hint/hint.component';
import { InputOptionsComponent } from './components/input-options/input-options.component';
import { VkFormGroupComponent } from './components/vk-form-group/vk-form-group.component';
import { VkFormGroupLabelDirective } from './directives/vk-form-group-label.directive';

@NgModule({
  declarations: [FormFieldComponent, InputDirective, LabelComponent, ErrorComponent, HintComponent, InputOptionsComponent, VkFormGroupComponent, VkFormGroupLabelDirective],
  exports: [FormFieldComponent, InputDirective, LabelComponent, ErrorComponent, HintComponent, InputOptionsComponent, VkFormGroupComponent, VkFormGroupLabelDirective],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule
  ]
})
export class VkControlsModule {
}
