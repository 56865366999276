import {Injectable} from '@angular/core';
import {VkApiGroup} from '../models/api/vk-api-group';
import {VkApiResponseWrapper} from '../models/api/vk-api-response-wrapper';
import {VkApiMethodParameter} from '../models/api/vk-api-method-parameter';
import {isNullOrUndefined} from 'util';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {GroupSearch} from '../models/api/group-search';
import {VkApiGroupStatistics} from '../models/api/vk-api-group-statistics';
import {ResolveResponse} from '../models/api/resolve-response';
import {VkApiPost} from '../models/api/vk-api-post';
import {VkApiWallGetResponse} from '../models/api/vk-api-wall-get-response';
import {VkApiGetGroupByIdResponse} from '../models/api/execute/vk-api-get-group-by-id-response';
import {TokenCenterService} from '../../api/services/token-center.service';
import {VkApiGetGroupWallResponse} from '../models/api/execute/vk-api-get-group-wall-response';

@Injectable()
export class VkApiService {
  /**
   * Формат даты для API ВК
   * @type {string}
   */
  public date_format = 'YYYY-MM-DD';

  /**
   * Токен для вызова метода. Обязательно нужно задать его перед использованием методов.
   */
  public token: string = null;

  /**
   * Recomended timeout between API requests (in ms.)
   * @type {number}
   */
  public timeout = 500;

  /**
   * Используемая версия API ВКонтакте
   * @type {string}
   */
  private version = '5.80';

  /**
   * Адрес сервера API ВКонтакте
   * @type {string}
   */
  private request_pattern = '/vk-api';

  /**
   * Название провайдера в Postmonitor API для получения токена
   * @type {string}
   */
  private provider = 'vk';

  constructor(private http: HttpClient,
              private TokenService: TokenCenterService) {
    this.RecieveTokenViaAPI();
  }

  public LoadToken(): void {
    // TODO Get token from LocalStorage

    // TODO If no token in LocalStorage - load it via Postmonitor API

    // TODO If no token via Postmonitor API log error
  }

  public RecieveTokenViaAPI(): void {
    this.token = null;

    this.TokenService.GetToken(this.provider).subscribe(response => {
      if (response && response.data) {
        this.token = response.data;
      }
    });
  }

  /**
   * groups.getById - Возвращает информацию о заданном сообществе или о нескольких сообществах.
   * @param {Array<number>} group_ids - идентификаторы или короткие имена сообществ. Максимальное число идентификаторов — 500.
   * @param {number} group_id - идентификатор или короткое имя сообщества.
   * @param {string} fields - список дополнительных полей, которые необходимо вернуть.
   * @returns {Observable<Array<VkApiGroup>>}
   * @constructor
   */
  public GroupsGetById(group_ids: Array<number>, group_id: number = null, fields: string = null): Observable<Array<VkApiGroup>> {
    const parameters: Array<VkApiMethodParameter> = [
      new VkApiMethodParameter('group_ids', this.ParameterMapSimpleArray(group_ids)),
      new VkApiMethodParameter('group_id', this.ParameterMapSimple(group_id)),
      new VkApiMethodParameter('fields', this.ParameterMapSimple(fields))
    ];

    const request = this.BuildRequest('groups.getById', parameters);

    return this.PerformRequest<Array<VkApiGroup>>(request);
  }

  /**
   * groups.search - Осуществляет поиск сообществ по заданной подстроке
   * @param {string} q - текст поискового запроса, обязательный параметр
   * @param {string} type - тип сообщества. Возможные значения: group, page, event.
   * @param {number} country_id - идентификатор страны
   * @param {number} city_id - идентификатор города. При передаче этого параметра поле country_id игнорируется.
   * @param {boolean} future - будут выведены предстоящие события. Учитывается только при передаче в качестве type значения event.
   * @param {boolean} market - будут выведены сообщества с включенными товарами.
   * @param {number} sort - сортировка
   * @param {number} offset - смещение, необходимое для выборки определённого подмножества результатов поиска. По умолчанию — 0.
   * @param {number} count - количество результатов поиска, которое необходимо вернуть. Обратите внимание — даже при использовании параметра offset для получения информации доступны только первые 1000 результатов.
   * @returns {Observable<GroupSearch>}
   * @constructor
   */
  public GroupsSearch(q: string, type: string = null, country_id: number = null, city_id: number = null,
                      future: boolean = false, market: boolean = false, sort: number = 0,
                      offset: number = 0, count: number = 20): Observable<GroupSearch> {
    const parameters: Array<VkApiMethodParameter> = [
      new VkApiMethodParameter('q', this.ParameterMapSimple(q)),
      new VkApiMethodParameter('type', this.ParameterMapSimple(type)),
      new VkApiMethodParameter('country_id', this.ParameterMapSimple(country_id)),
      new VkApiMethodParameter('city_id', this.ParameterMapSimple(city_id)),
      new VkApiMethodParameter('future', this.ParameterMapBoolean(future)),
      new VkApiMethodParameter('market', this.ParameterMapBoolean(market)),
      new VkApiMethodParameter('sort', this.ParameterMapSimple(sort)),
      new VkApiMethodParameter('offset', this.ParameterMapSimple(offset)),
      new VkApiMethodParameter('count', this.ParameterMapSimple(count))
    ];

    const request = this.BuildRequest('groups.search', parameters);

    return this.PerformRequest<GroupSearch>(request);
  }

  /**
   * stats.get - Возвращает статистику сообщества или приложения
   * @param {number} group_id - Идентификатор сообщества
   * @param {number} app_id - Идентификатор приложения
   * @param {string} date_from - период начала отсчёта в формате YYYY-MM-DD
   * @param {string} date_to - период окончания отсчёта в формате YYYY-MM-DD
   * @returns {Observable<VkApiGroupStatistics>}
   * @constructor
   */
  public StatsStatsGet(group_id: number, app_id: number = null,
                       date_from: string, date_to: string): Observable<Array<VkApiGroupStatistics>> {
    const parameters: Array<VkApiMethodParameter> = [
      new VkApiMethodParameter('group_id', this.ParameterMapSimple(group_id)),
      new VkApiMethodParameter('app_id', this.ParameterMapSimple(app_id)),
      new VkApiMethodParameter('date_from', this.ParameterMapSimple(date_from)),
      new VkApiMethodParameter('date_to', this.ParameterMapSimple(date_to))
    ];

    const request = this.BuildRequest('stats.get', parameters);

    return this.PerformRequest<Array<VkApiGroupStatistics>>(request);
  }

  /**
   * wall.getById - Возвращает список записей со стен пользователей или сообществ по их идентификаторам
   * @param {Array<string>} posts - перечисленные через запятую идентификаторы, которые представляют собой идущие через знак подчеркивания id владельцев стен и id самих записей на стене. Максимум 100 идентификаторов.
   * @param {boolean} extended - в ответе будут возвращены дополнительные поля profiles и groups, содержащие информацию о пользователях и сообществах
   * @param {number} copy_history_depth - определяет размер массива copy_history, возвращаемого в ответе, если запись является репостом записи с другой стены.
   * @param {string} fields - список дополнительных полей для профилей и групп, которые необходимо вернуть
   * @returns {Observable<Array<VkApiPost>>}
   * @constructor
   */
  public WallGetById(posts: Array<string>, extended: boolean, copy_history_depth: number = 2, fields: string = null): Observable<Array<VkApiPost>> {
    const parameters: Array<VkApiMethodParameter> = [
      new VkApiMethodParameter('posts', this.ParameterMapSimpleArray(posts)),
      new VkApiMethodParameter('extended', this.ParameterMapBoolean(extended)),
      new VkApiMethodParameter('copy_history_depth', this.ParameterMapSimple(copy_history_depth)),
      new VkApiMethodParameter('fields', this.ParameterMapSimple(fields))
    ];

    const request = this.BuildRequest('wall.getById', parameters);

    return this.PerformRequest<Array<VkApiPost>>(request);
  }

  /**
   * wall.get - идентификатор пользователя или сообщества, со стены которого необходимо получить записи (по умолчанию — текущий пользователь).
   * @param {number} owner_id - идентификатор пользователя или сообщества, со стены которого необходимо получить записи (по умолчанию — текущий пользователь).
   * @param {number} count - количество записей, которое необходимо получить. Максимальное значение: 100
   * @param {number} offset - смещение, необходимое для выборки определенного подмножества записей.
   * @param {string} filter - определяет, какие типы записей на стене необходимо получить. Возможные значения:
   * suggests — предложенные записи на стене сообщества (доступно только при вызове с передачей access_token);
   * postponed — отложенные записи (доступно только при вызове с передачей access_token);
   * owner — записи владельца стены; others — записи не от владельца стены;
   * all — все записи на стене (owner + others).
   * @param {boolean} extended - 1 — в ответе будут возвращены дополнительные поля profiles и groups, содержащие информацию о пользователях и сообществах. По умолчанию: 0.
   * @param {string} fields - список дополнительных полей для профилей и сообществ, которые необходимо вернуть. Обратите внимание, этот параметр учитывается только при extended=1.
   * @param {string} domain - короткий адрес пользователя или сообщества.
   * @returns {Observable<Array<VkApiPost>>}
   * @constructor
   */
  public WallGet(owner_id: number, count: number = 100, offset: number = 0,
                 filter: string = 'all', extended: boolean = false,
                 fields: string = null, domain: string = null): Observable<VkApiWallGetResponse> {
    const parameters: Array<VkApiMethodParameter> = [
      new VkApiMethodParameter('owner_id', this.ParameterMapSimple(owner_id)),
      new VkApiMethodParameter('count', this.ParameterMapSimple(count)),
      new VkApiMethodParameter('offset', this.ParameterMapSimple(offset)),
      new VkApiMethodParameter('filter', this.ParameterMapSimple(filter)),
      new VkApiMethodParameter('extended', this.ParameterMapBoolean(extended)),
      new VkApiMethodParameter('fields', this.ParameterMapSimple(fields)),
      new VkApiMethodParameter('domain', this.ParameterMapSimple(domain))
    ];

    const request = this.BuildRequest('wall.get', parameters);

    return this.PerformRequest<VkApiWallGetResponse>(request);
  }

  /**
   * utils.resolveScreenName - Определяет тип объекта (пользователь, сообщество, приложение) и его идентификатор по короткому имени screen_name.
   * @param {string} screen_name - короткое имя пользователя, группы или приложения
   * @returns {Observable<ResolveResponse>}
   * @constructor
   */
  public UtilsResolveScreenName(screen_name: string): Observable<ResolveResponse> {
    const parameters: Array<VkApiMethodParameter> = [
      new VkApiMethodParameter('screen_name', this.ParameterMapSimple(screen_name))
    ];

    const request = this.BuildRequest('utils.resolveScreenName', parameters);

    return this.PerformRequest<ResolveResponse>(request);
  }

  /**
   * execute.GetGroupById - Получение основных данных о группе, статистики и последней 100 записей на стене
   * @param {number} group_id - идентификатор сообщества
   * @param {string} date_from - период начала отсчёта в формате YYYY-MM-DD (для полчения статистики)
   * @param {string} date_to - период окончания отсчёта в формате YYYY-MM-DD (для полчения статистики)
   * @returns {Observable<VkApiGetGroupByIdResponse>}
   * @constructor
   */
  public ExecuteGetGroupById(group_id: number, date_from: string, date_to: string): Observable<VkApiGetGroupByIdResponse> {
    const parameters: Array<VkApiMethodParameter> = [
      new VkApiMethodParameter('group_id', this.ParameterMapSimple(group_id)),
      new VkApiMethodParameter('date_from', this.ParameterMapSimple(date_from)),
      new VkApiMethodParameter('date_to', this.ParameterMapSimple(date_to))
    ];

    const request = this.BuildRequest('execute.GetGroupById', parameters);

    return this.PerformRequest<VkApiGetGroupByIdResponse>(request);
  }

  /**
   * execute.GetGroupWall - Получение информации о сообществе и последних постах
   * @param {number} group_id - идентификатор согобщества
   * @param {number} wall_requests - количество подзапросов wall.get к сообществу. Один подзапрос получает 100 постов
   * @returns {Observable<VkApiGetGroupWallResponse>}
   * @constructor
   */
  public ExecuteGetGroupWall(group_id: number, wall_requests: number = 5): Observable<VkApiGetGroupWallResponse> {
    const parameters: Array<VkApiMethodParameter> = [
      new VkApiMethodParameter('group_id', this.ParameterMapSimple(group_id)),
      new VkApiMethodParameter('wall_requests', this.ParameterMapSimple(wall_requests))
    ];

    const request = this.BuildRequest('execute.GetGroupWall', parameters);

    return this.PerformRequest<VkApiGetGroupWallResponse>(request);
  }

  /**
   * Перевод простого значения в строку
   * @param value - значение
   * @returns {string} Значение приведённое к строке или null
   * @constructor
   */
  private ParameterMapSimple(value: any): string {
    return (value) ? value.toString() : null;
  }

  private ParameterMapBoolean(value: boolean): string {
    return (!isNullOrUndefined(value)) ? ((value) ? '1' : '0') : null;
  }

  /**
   * Перевод массива в строку
   * @param {Array<any>} value - массив значений
   * @returns {string}
   * @constructor
   */
  private ParameterMapSimpleArray(value: Array<any>): string {
    return (value) ? value.join(',') : null;
  }

  /**
   * Создаёт типизированный HTTP запос к API
   * @param {string} request - URL запроса, собранного при помощи BuildRequest()
   * @returns {Observable<T>}
   * @constructor
   */
  private PerformRequest<T>(request: string): Observable<T> {
    return this.http
      .get<VkApiResponseWrapper<T>>(request)
      .map(x => x.response);
  }

  /**
   * Создаёт из названия метода и переданных параметров URL запроса
   * @param {string} method - название метода в API
   * @param {Array<VkApiMethodParameter>} parameters - параметры запроса
   * @returns {string} - URL запроса
   * @constructor
   */
  private BuildRequest(method: string, parameters?: Array<VkApiMethodParameter>): string {
    let request = `${this.request_pattern}/method/${method}?`;

    // If parameters were passed
    if (parameters && parameters.length > 0) {

      // Iterate through all parameters
      parameters.forEach(parameter => {

        // Check if parameter's value is provided
        if (!isNullOrUndefined(parameter.value)) {

          // Append parameter to request
          request += `${parameter.ToString()}&`;
        }
      });
    }

    // Append token, version & return builded request
    return `${request}access_token=${this.GetToken()}&v=${this.version}`;
  }

  /**
   * Проверяет наличие токена и возвращает его в случае наличия
   * @returns {string} Токен для построения запроса
   */
  public GetToken(): string {
    if (isNullOrUndefined(this.token)) {
      throw new Error('No token was provided to VkApiService, please provide token before API calling.');
    }

    return this.token;
  }
}
