import {Component, Input, OnInit} from '@angular/core';
import {AffiliateStatusService} from "../../services/affiliate-status.service";

@Component({
    selector: 'app-affiliate-program-link',
    templateUrl: './affiliate-program-link.component.html',
    styleUrls: ['./affiliate-program-link.component.scss']
})
export class AffiliateProgramLinkComponent implements OnInit {

    @Input() public isTitle = false;
    
    public get IsPartner() {
        return this.affiliateStatusService.isPartner;
    }

    constructor(
        private affiliateStatusService: AffiliateStatusService
    ) {
    }

    ngOnInit() {
        this.affiliateStatusService.LoadAffiliateMemberStatus();
    }

}
