import {Component, OnInit} from '@angular/core';
import {StoreService} from '../../services/store.service';
import {UsersIntegrationsService} from "../../store/user-integrations/user-integration.service";
import {UserIntegrationQuery} from "../../store/user-integrations/user-integration.query";
import {UserIntegrationStatisticsQuery} from "../../store/user-integration-statistics/user-integration-statistics.query";
import {ActivatedRoute} from "@angular/router";
import {toBoolean} from "@datorama/akita";
import {IntegrationSessionQuery} from "../../store/integrations-session/integration-session.query";
import {map} from "rxjs/operators";

@Component({
  selector: 'app-integrations-table',
  templateUrl: './integrations-table.component.html',
  styleUrls: ['./integrations-table.component.scss']
})
export class IntegrationsTableComponent implements OnInit {

  public userIntegrations$ = this.userIntegrationsQuery.userIntegrations$;

  public isSessionExhausted$ = this.integrationSessionQuery.currentSession$
    .pipe(
      map(x => x.isLimitExhausted)
    );

  private showRemoved: boolean = false;

  constructor(
    private store: StoreService,
    private userIntegrationsService: UsersIntegrationsService,
    private userIntegrationsQuery: UserIntegrationQuery,
    private userIntegrationsStatistic: UserIntegrationStatisticsQuery,
    private integrationSessionQuery: IntegrationSessionQuery,
    private route: ActivatedRoute
  ) {
  }

  public get integrations() {
    return this.store.integrations || [];
  }

  public get loading() {
    return this.store.loading || false;
  }

  public get loadingMessage() {
    return this.store.loadingMessage;
  }

  ngOnInit() {
    this.route.queryParams.subscribe(({showRemoved}) => {
      this.showRemoved = toBoolean(showRemoved);
      this.LoadUsersIntegrations();
    });
  }

  public UserIntegrationStatistic$(id) {
    return this.userIntegrationsStatistic.integrationStatistic(id)
  }

  public LoadUsersIntegrations() {
    this.userIntegrationsService.LoadUserIntegrations(this.showRemoved);
  }

  Remove(integrationId) {
    this.userIntegrationsService.RemoveUserIntegration(integrationId)
  }
}
