import { IntegrationService } from '../../../api/services';
import { PlatformsRelationsStore } from './platforms-relations.store';
import { createPlatformsRelation } from './platforms-relation.model';
import * as i0 from "@angular/core";
import * as i1 from "./platforms-relations.store";
import * as i2 from "../../../api/services/integration.service";
var PlatformsRelationsService = /** @class */ (function () {
    function PlatformsRelationsService(store, integrationService) {
        this.store = store;
        this.integrationService = integrationService;
    }
    /**
     *
     * @constructor
     */
    PlatformsRelationsService.prototype.LoadIntegrationsRelations = function () {
        var _this = this;
        this.integrationService.GetCompableIntegrations()
            .subscribe(function (_a) {
            var data = _a.data;
            _this.store.set(data.map(function (x) { return createPlatformsRelation(x); }));
        });
    };
    PlatformsRelationsService.ngInjectableDef = i0.defineInjectable({ factory: function PlatformsRelationsService_Factory() { return new PlatformsRelationsService(i0.inject(i1.PlatformsRelationsStore), i0.inject(i2.IntegrationService)); }, token: PlatformsRelationsService, providedIn: "root" });
    return PlatformsRelationsService;
}());
export { PlatformsRelationsService };
