import { OnInit } from '@angular/core';
import { AutomationVkService } from '../../../api/services/automation-vk.service';
import { DexieCabinetsService } from '../../../shared/services/dexie/dexie-cabinets.service';
import { AccountViewModel } from '../../models/view-models/account-view-model';
import { AutomationVkSlotService } from '../../../api/services/automation-vk-slot.service';
import 'rxjs/add/observable/forkJoin';
import { AutomationExpirationManagerService } from '../../services/automation-expiration-manager.service';
import { DexieClientsService } from '../../../shared/services/dexie/dexie-clients.service';
import { PickListComponent } from '../../components/pick-list/pick-list.component';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { DgPaginationComponent } from '../../../ngr-ui/components/dg-pagination/dg-pagination.component';
import { BrowserClient, Hub } from '@sentry/browser';
// import {AccountsService} from "../../services/store/accounts.service";
import { isNullOrUndefined } from "util";
import { LoggingService } from "../../../shared/services/logging.service";
var sentry = null;
var hub = null;
export var ACCOUNT_VIEW_MODE;
(function (ACCOUNT_VIEW_MODE) {
    ACCOUNT_VIEW_MODE["ALL"] = "ALL";
    ACCOUNT_VIEW_MODE["ACTIVE"] = "ACTIVE";
})(ACCOUNT_VIEW_MODE || (ACCOUNT_VIEW_MODE = {}));
var client = JSON.parse("{\n\"clicks\":0,\n\"spent\":0,\n\"ctr\":0,\n\"impressions\":0,\n\"loaded\":true,\n\"is_blocked\":false,\n\"isSelected\":false,\n\"priority\":6,\n\"account_id\":1900013339,\n\"account_name\":\"zaleycash_alekseylymarev@gmail.com_282079040\",\n\"account_role\":\"manager\",\n\"account_status\":true,\n\"account_type\":\"client\",\n\"client_id\":1605088703\n}");
function random(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
}
var AutomationAccountsComponent = /** @class */ (function () {
    function AutomationAccountsComponent(_api, localDb, _db, accessManager, localClientsDb, logger) {
        this._api = _api;
        this.localDb = localDb;
        this._db = _db;
        this.accessManager = accessManager;
        this.localClientsDb = localClientsDb;
        this.logger = logger;
        this.token = '2d74c771895089eb9c237b388499b6877a967ea5b25935215fd489276327ceed6c4cf49708b44cb4f1a6b';
        this.accounts = null;
        this.accountsViewModels = [];
        this.accountsStatistics = [];
        this.IsExpired = true;
        this.opened = false;
        this.pickItems = [];
        this.countOfMaxSlots = 0;
        this.countOfFree = 0;
        this.firstLoading = true;
        this.loading = false;
        this.viewMode = ACCOUNT_VIEW_MODE.ALL;
        this.countOfResults = 10;
        this.availableSizes = [
            {
                value: 5,
                label: '5'
            },
            {
                value: 10,
                label: '10'
            },
            {
                value: 25,
                label: '25'
            },
            {
                value: 50,
                label: '50'
            },
            {
                value: 100,
                label: '100'
            }
        ];
        this.clientsLoading = false;
        sentry = new BrowserClient({
            dsn: 'https://b654dfe1c9af48b38da372bb697ff503@sentry.io/1262782'
        });
        hub = new Hub(sentry);
    }
    Object.defineProperty(AutomationAccountsComponent.prototype, "data", {
        get: function () {
            var data = this.accountsViewModels || [];
            switch (this.viewMode) {
                case ACCOUNT_VIEW_MODE.ACTIVE:
                    data = data.filter(function (x) { return x.is_blocked == false && x.account_type !== 'agency'; });
                    break;
            }
            return data;
        },
        enumerable: true,
        configurable: true
    });
    AutomationAccountsComponent.prototype.ToggleOpened = function () {
        this.opened = !this.opened;
    };
    AutomationAccountsComponent.prototype.ToggleLoading = function () {
        this.loading = !this.loading;
    };
    Object.defineProperty(AutomationAccountsComponent.prototype, "PickListItems", {
        get: function () {
            return [];
        },
        enumerable: true,
        configurable: true
    });
    AutomationAccountsComponent.prototype.OnPickListItemChanged = function (data) {
    };
    AutomationAccountsComponent.prototype.ngOnInit = function () {
        try {
            // this.accountsService.LoadAdCabinetsData()
            //   .subscribe(data => {
            //     console.log(data);
            //   })
            this.LoadData();
        }
        catch (e) {
        }
    };
    AutomationAccountsComponent.prototype.LoadData = function () {
        var _this = this;
        this.ToggleLoading();
        this.LoadSlots()
            .then(this.LoadAccounts.bind(this))
            .then(this.LoadClients.bind(this))
            .then(this.LoadStatistic.bind(this))
            .then(function () {
            _this.accountsViewModels = _this.accountsViewModels.map(function (account) {
                _this.slots.forEach(function (slot) {
                    var cabinet = _this.accountsViewModels
                        .find(function (cabinetItem) { return cabinetItem.account_id === slot.bindedCabinetId && cabinetItem.client_id === slot.bindedClientId; });
                    if (cabinet) {
                        cabinet.is_blocked = false;
                    }
                });
                // let slot: UserAutomationCabinetSlot = null;
                // if (account && account.account_type === 'client') {
                //   slot = this.slots.find(x => x.bindedCabinetId === account.account_id && x.bindedClientId === account.client_id);
                // } else {
                //   slot = this.slots.find(x => x.bindedCabinetId === account.account_id);
                // }
                // account.is_blocked = isNullOrUndefined(slot);
                // this.accountsViewModels = this.accountsViewModels.sort((a, b) => a.priority - b.priority);
                // console.log(JSON.stringify(this.accountsViewModels));
                return account;
            });
        });
        // this.LoadSlots();
    };
    AutomationAccountsComponent.prototype.isAccountAssigned = function (account) {
        var slot = this.slots.find(function (x) { return x.bindedClientId === account.client_id && x.bindedCabinetId === account.account_id; });
        return !isNullOrUndefined(slot);
    };
    AutomationAccountsComponent.prototype.LoadAccounts = function () {
        var _this = this;
        // this.accountsViewModels = [];
        return new Promise(function (resolve) {
            _this._api.GetAccounts()
                .subscribe(function (x) {
                _this.accountsViewModels = x.data.map(function (y, index) {
                    var acc = new AccountViewModel(y.account_id, y.account_name, y.access_role, y.account_status, y.account_type, true, null, index);
                    acc.is_blocked = !_this.isAccountAssigned(acc);
                    return acc;
                });
                // this.accountsViewModels
                _this.ToggleLoading();
                console.log('QUOTA');
                if (x.userMeta) {
                    _this.IsExpired = x.userMeta.isExpired || false;
                }
                _this.localDb.setCabinets(_this.accountsViewModels)
                    .then(function () {
                })
                    .catch(function (e) {
                    navigator.storage.estimate()
                        .then(function (data) {
                        hub.withScope(function (scope) {
                            scope.addBreadcrumb({
                                data: data,
                                message: 'Не удалось добавить кабинеты в локальную бд'
                            });
                            hub.captureException(e);
                        });
                    })
                        .catch(function (err) {
                        hub.withScope(function (scope) {
                            scope.addBreadcrumb({
                                message: 'Не удалось получить данные о квоте на текущее устройство  '
                            });
                            hub.captureException(err);
                        });
                    });
                });
                // const storageEstimate = StorageManager.estimate();
                resolve();
            });
        });
    };
    AutomationAccountsComponent.prototype.LoadClients = function () {
        var _this = this;
        console.log('LOAD CLIENTS');
        this.logger.AddBreadcrumb('Начало загрузки клиентов');
        return new Promise(function (resolve) {
            _this.clientsLoading = true;
            _this.logger.AddBreadcrumb('Получение данных об агентских кабинетах');
            var promises = _this.accountsViewModels.filter(function (x) { return x.account_type === 'agency'; })
                .map(function (account, i) {
                _this.logger.AddBreadcrumb("\u041F\u043E\u043B\u0443\u0447\u0435\u043D\u0438\u0435 \u0434\u0430\u043D\u043D\u044B\u0445 \u043E \u043A\u043B\u0438\u0435\u043D\u0442\u0430\u0445 \u0430\u0433\u0435\u043D\u0442\u0441\u043A\u043E\u0433\u043E \u043A\u0430\u0431\u0438\u043D\u0435\u0442\u0430 " + account.account_id);
                return new Promise(function (resolve1) {
                    setTimeout(function () {
                        _this._api.getClients(account.account_id)
                            .subscribe(function (clients) {
                            _this.logger.AddBreadcrumb("\u0414\u0430\u043D\u043D\u044B\u0435 \u043E \u043A\u043B\u0438\u0435\u043D\u0442\u0430\u0445 \u0430\u0433\u0435\u043D\u0442\u0441\u043A\u043E\u0433\u043E \u043A\u0430\u0431\u0438\u043D\u0435\u0442\u0430 " + account.account_id + " \u043F\u043E\u043B\u0443\u0447\u0435\u043D\u044B.");
                            _this.logger.AddBreadcrumb("\u041F\u043E\u043B\u0443\u0447\u0435\u043D\u043E: " + clients);
                            clients.data.forEach(function (client) {
                                _this.accountsViewModels.push(new AccountViewModel(account.account_id, client.name, account.account_role, account.account_status, 'client', account.is_blocked, client.id, account.priority));
                            });
                            _this.logger.AddBreadcrumb("\u041A\u043B\u0438\u0435\u043D\u0442\u044B \u0434\u043E\u0431\u0430\u0432\u043B\u0435\u043D\u044B \u0432 \u0445\u0440\u0430\u043D\u0438\u043B\u0438\u0449\u0435");
                            var clientsData = clients.data.map(function (x) {
                                x.account_id = account.account_id;
                                return x;
                            });
                            console.log(clientsData, 'CLIENTS DATA');
                            _this.localClientsDb.AddClients(clientsData)
                                .then(function (data) {
                                resolve1();
                            })
                                .catch(function (e) {
                                navigator.storage.estimate()
                                    .then(function (data) {
                                    // data.userAgent = navigation.userAgent;
                                    hub.withScope(function (scope) {
                                        scope.addBreadcrumb({
                                            data: data,
                                            message: 'Не удалось добавить клиентов в локальную бд'
                                        });
                                        hub.captureException(e);
                                    });
                                });
                            });
                            _this.logger.Log('Завершение процеса добавления клиентов');
                        }, function (err) {
                            _this.logger.Error("\u041A\u043B\u0438\u0435\u043D\u0442\u044B \u043D\u0435 \u043F\u043E\u043B\u0443\u0447\u0435\u043D\u044B", err);
                        });
                    }, 1500 * i);
                });
            });
            return Promise.all(promises)
                .then(function () {
                _this.clientsLoading = false;
                resolve();
            });
        });
    };
    AutomationAccountsComponent.prototype.LoadStatistic = function () {
        var _this = this;
        return new Promise(function (resolve) {
            console.log(_this.slots.filter(function (x) { return x.bindedCabinetId !== null; }), 'LOG');
            var promises = _this.slots.filter(function (x) { return x.bindedCabinetId !== null; })
                .map(function (x, i) {
                setTimeout(function () {
                    return new Promise(function (resolve1) {
                        var cabinet = _this.accountsViewModels
                            .find(function (a) { return a.account_id === x.bindedCabinetId && a.client_id === x.bindedClientId; });
                        console.log(_this.accountsViewModels.find(function (x) { return x.client_id === 1605088790; }));
                        // console.log((x.bindedClientId || x.bindedCabinetId).toString(), cabinet.account_name);
                        return _this._api.GetStatistics({
                            accountId: x.bindedCabinetId,
                            clientId: x.bindedClientId,
                            idsType: x.bindedClientId ? 'client' : 'office',
                            ids: (x.bindedClientId || x.bindedCabinetId).toString(),
                            dateFrom: '0',
                            dateTo: '0',
                            period: 'overall'
                        })
                            .subscribe(function (y) {
                            var stats = y.data[0].stats[0];
                            if (y.userMeta) {
                                _this.IsExpired = y.userMeta.isExpired;
                            }
                            if (cabinet) {
                                if (stats) {
                                    cabinet.clicks = stats.clicks;
                                    cabinet.spent = stats.spent;
                                    cabinet.ctr = (stats.impressions > 0) ? (stats.clicks / stats.impressions * 100) : 0;
                                    cabinet.impressions = stats.impressions;
                                }
                                cabinet.loaded = true;
                            }
                            resolve1();
                        }, function (err) {
                            _this.accessManager.SetOccured(err.error);
                            if (cabinet) {
                                cabinet.is_blocked = true;
                            }
                            resolve1();
                        });
                    });
                }, 1000 * i);
            });
            // const promises = this.accountsViewModels
            //   .filter(x => !x.is_blocked && x.account_type !== 'agency')
            //   .map((x, i) => {
            //     return new Promise(resolve1 => {
            //       setTimeout(() => {
            //         let idsType = 'office';
            //         let ids = null;
            //         if (x && x.account_type === 'client') {
            //           idsType = 'client';
            //           ids = x.client_id.toString();
            //         } else {
            //           ids = x.account_id.toString();
            //         }
            //         return this._api.GetStatistics({
            //           accountId: x.account_id,
            //           idsType: idsType,
            //           dateTo: '0',
            //           ids: ids,
            //           dateFrom: '0',
            //           period: 'overall',
            //           clientId: x.client_id
            //         }).subscribe((y) => {
            //           const stats = y.data[0].stats[0];
            //           if (y.userMeta) {
            //             this.IsExpired = y.userMeta.isExpired;
            //           }
            //           if (stats) {
            //             x.clicks = stats.clicks;
            //             x.spent = stats.spent;
            //             x.ctr = (stats.impressions > 0) ? (stats.clicks / stats.impressions * 100) : 0;
            //             x.impressions = stats.impressions;
            //           }
            //           x.loaded = true;
            //           resolve1();
            //         }, (err) => {
            //           this.accessManager.SetOccured(err.error);
            //           x.is_blocked = true;
            //           resolve1();
            //         });
            //       }, i * 1000);
            //     });
            //   });
            Promise
                .all(promises)
                .then(function () {
                resolve();
            })
                .catch(function (err) {
                console.log(err, 'CATCH ERROR');
            });
        });
    };
    AutomationAccountsComponent.prototype.LoadSlots = function () {
        var _this = this;
        return new Promise(function (resolve) {
            _this._db.GetSlots()
                .subscribe(function (x) {
                _this.slots = x.data;
                _this.countOfMaxSlots = _this.slots.length;
                if (_this.firstLoading) {
                    _this.opened = _this.slots.filter(function (slot) { return slot.bindedCabinetId !== null; }).length === 0;
                }
                _this.countOfFree = _this.slots.filter(function (slot) { return slot.bindedCabinetId === null; }).length;
                _this.firstLoading = false;
                resolve();
            });
        });
    };
    AutomationAccountsComponent.prototype.OnSaveCabinetsBined = function (data) {
        var _this = this;
        if (!data.isSelected) {
            this._db.AssignCabinetToSlot({
                id: data.account_id,
                clientId: data.client_id
            })
                .subscribe(function (data1) {
                _this.LoadData();
            }, function (err) {
                _this.pickList.SetError(err.error);
            });
        }
        else {
            this._db.UnBindCabinetFromSlot({
                id: data.account_id,
                clientId: data.client_id
            })
                .subscribe(function (data1) {
                _this.LoadData();
            }, function (err) {
                _this.pickList.SetError(err.error);
            });
        }
        // this.LoadSlots();
    };
    AutomationAccountsComponent.prototype.OnClose = function () {
        this.ToggleOpened();
    };
    AutomationAccountsComponent.prototype.SummaryFunction = function (data) {
        if (data != null) {
            return data.reduce(function (a, b) { return a + b; }, 0);
        }
        return 0;
    };
    AutomationAccountsComponent.prototype.SummaryTitleFunction = function (data) {
        return 'Итого:';
    };
    AutomationAccountsComponent.prototype.CurrencyFunction = function (data) {
        if (!isNaN(data)) {
            return new CurrencyPipe('ru').transform(data, ' Руб.');
        }
        return '';
    };
    AutomationAccountsComponent.prototype.PaginSummaryTitleFunction = function () {
        return 'Итого на странице:';
    };
    AutomationAccountsComponent.prototype.NumberFunction = function (data) {
        if (!isNaN(data)) {
            return new DecimalPipe('ru').transform(data, '0.0-2');
        }
        return '';
    };
    AutomationAccountsComponent.prototype.PercentFunction = function (data) {
        if (!isNaN(data)) {
            return new DecimalPipe('ru').transform(data, '0.0-2') + '%';
        }
        return '';
        // return new DecimalPipe('ru').transform(data, '0.0-2') + ' %'
    };
    AutomationAccountsComponent.prototype.AvgSummaryFunction = function (data) {
        if (data != null) {
            return (data.reduce(function (a, b) { return a + b; }, 0) / data.length).toFixed(2);
        }
        return 0;
    };
    AutomationAccountsComponent.prototype.CtrFormatFunction = function (data) {
        if (!isNaN(data) && data !== null) {
            return new DecimalPipe('ru').transform(data, '0.3') + '%';
        }
    };
    return AutomationAccountsComponent;
}());
export { AutomationAccountsComponent };
