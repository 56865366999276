/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./teazer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../shared/pipes/domain-only.pipe";
import * as i3 from "@angular/common";
import * as i4 from "@angular/flex-layout/extended";
import * as i5 from "@angular/flex-layout/core";
import * as i6 from "@angular/platform-browser";
import * as i7 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i8 from "@angular/material/icon";
import * as i9 from "./teazer.component";
var styles_TeazerComponent = [i0.styles];
var RenderType_TeazerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TeazerComponent, data: {} });
export { RenderType_TeazerComponent as RenderType_TeazerComponent };
export function View_TeazerComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DomainOnlyPipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 14, "div", [["class", "teazer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 8, "div", [["class", "teazer__image"]], null, null, null, null, null)), i1.ɵdid(3, 278528, null, 0, i3.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(4, { "background-image": 0 }), i1.ɵdid(5, 933888, null, 0, i4.DefaultStyleDirective, [i1.ElementRef, i5.StyleUtils, i5.MediaMarshaller, i1.KeyValueDiffers, i1.Renderer2, i6.DomSanitizer, [6, i3.NgStyle], [2, i5.SERVER_TOKEN], i1.PLATFORM_ID], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(6, { "background-image": 0 }), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [["class", "teazer-image__remove-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeImage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i7.View_MatIcon_0, i7.RenderType_MatIcon)), i1.ɵdid(9, 9158656, null, 0, i8.MatIcon, [i1.ElementRef, i8.MatIconRegistry, [8, null], [2, i8.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["close"])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "div", [["class", "teazer__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, [" ", " "])), (_l()(), i1.ɵeld(13, 0, null, null, 2, "div", [["class", "teazer__link"]], null, null, null, null, null)), (_l()(), i1.ɵted(14, null, [" ", " "])), i1.ɵppd(15, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 4, 0, (("url(" + _co.TeazerImage) + ")")); _ck(_v, 3, 0, currVal_0); var currVal_1 = _ck(_v, 6, 0, (("url(" + _co.TeazerImage) + ")")); _ck(_v, 5, 0, currVal_1); _ck(_v, 9, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵnov(_v, 9).inline; var currVal_3 = (((i1.ɵnov(_v, 9).color !== "primary") && (i1.ɵnov(_v, 9).color !== "accent")) && (i1.ɵnov(_v, 9).color !== "warn")); _ck(_v, 8, 0, currVal_2, currVal_3); var currVal_4 = _co.TeazerTitle; _ck(_v, 12, 0, currVal_4); var currVal_5 = i1.ɵunv(_v, 14, 0, _ck(_v, 15, 0, i1.ɵnov(_v, 0), _co.TeazerLink)); _ck(_v, 14, 0, currVal_5); }); }
export function View_TeazerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-teazer", [], null, null, null, View_TeazerComponent_0, RenderType_TeazerComponent)), i1.ɵdid(1, 114688, null, 0, i9.TeazerComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TeazerComponentNgFactory = i1.ɵccf("app-teazer", i9.TeazerComponent, View_TeazerComponent_Host_0, { teazer: "teazer", title: "title", image: "image", defaultTitle: "defaultTitle" }, { ImageRemoved: "ImageRemoved" }, []);
export { TeazerComponentNgFactory as TeazerComponentNgFactory };
