<div class="copying-by-sex-and-age-configuration" [formGroup]="form">
  <div class="copying-by-sex-and-age-configuration__items" formArrayName="ageItems">
    <ng-container *ngFor="let age of ages; let i = index">
      <app-age-segmentation-item
        [index]="i"
        [formGroup]="age"
        (removeTriggered)="removeControl(i)"
      ></app-age-segmentation-item>
    </ng-container>
  </div>
  <div class="copying-by-sex-and-age__configuration">
    <button mat-button (click)="addControl()" class="copying-by-sex-and-age__add-button" color="primary">
      <mat-icon>add</mat-icon>
      Добавить
    </button>
    <app-suggestion-info code="ADD_AGE_SEGMENT_BUTTON"></app-suggestion-info>
  </div>
</div>
