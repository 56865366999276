import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UOnTravelImportScreenComponent } from './components/u-on-travel-import-screen/u-on-travel-import-screen.component';
import {MatCheckboxModule} from "@angular/material/checkbox";

@NgModule({
  declarations: [UOnTravelImportScreenComponent],
  exports: [
    UOnTravelImportScreenComponent,
    UOnTravelImportScreenComponent,
    UOnTravelImportScreenComponent
  ],
  imports: [
    CommonModule,
    MatCheckboxModule
  ]
})
export class UOnTravelModule { }
