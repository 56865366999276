import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-new-targeting-stats',
  templateUrl: './new-targeting-stats.component.html',
  styleUrls: ['./new-targeting-stats.component.scss']
})
export class NewTargetingStatsComponent implements OnInit {

  @Input() public targeting: any;
  @Input() public isDirty: any;
  @Input() public loading: boolean;

  public get TargetingAudienceCount() {
    return this.targeting ? this.targeting.audience_count : 0;
  }

  constructor() {
  }

  ngOnInit() {
  }

}
