/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./lead-forms-configurator.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../lead-form-settings/lead-form-settings.component.ngfactory";
import * as i3 from "../lead-form-settings/lead-form-settings.component";
import * as i4 from "../../../../stores/ad-network-groups/ad-network-groups.query";
import * as i5 from "../../../../stores/ad-network-forms/ad-network-forms.query";
import * as i6 from "../../../../stores/ad-network-forms/ad-network-forms.service";
import * as i7 from "../../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i8 from "@angular/material/icon";
import * as i9 from "@angular/forms";
import * as i10 from "@angular/common";
import * as i11 from "./lead-forms-configurator.component";
import * as i12 from "../../../../stores/platform-assignations/platform-assignations.service";
import * as i13 from "../../../../stores/platform-assignations/platform-assignations.query";
import * as i14 from "../../../../stores/ad-network-groups/ad-network-groups.service";
import * as i15 from "@datorama/akita-ng-forms-manager";
var styles_LeadFormsConfiguratorComponent = [i0.styles];
var RenderType_LeadFormsConfiguratorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LeadFormsConfiguratorComponent, data: {} });
export { RenderType_LeadFormsConfiguratorComponent as RenderType_LeadFormsConfiguratorComponent };
function View_LeadFormsConfiguratorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-lead-form-settings", [], null, null, null, i2.View_LeadFormSettingsComponent_0, i2.RenderType_LeadFormSettingsComponent)), i1.ɵdid(2, 114688, null, 0, i3.LeadFormSettingsComponent, [i4.AdNetworkGroupsQuery, i5.AdNetworkFormsQuery, i6.AdNetworkFormsService], { form: [0, "form"], control: [1, "control"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.GetControl(_v.context.$implicit.id); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_LeadFormsConfiguratorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "lead-forms-configurator"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "lead-forms-configurator__info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "mat-icon", [["class", "warning-icon mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i7.View_MatIcon_0, i7.RenderType_MatIcon)), i1.ɵdid(3, 9158656, null, 0, i8.MatIcon, [i1.ElementRef, i8.MatIconRegistry, [8, null], [2, i8.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["warning"])), (_l()(), i1.ɵted(-1, null, [" \u0415\u0441\u043B\u0438 \u0432 \u043B\u0438\u0434-\u0444\u043E\u0440\u043C\u0435 \u043D\u0435\u0442 \u043D\u0438 \u043E\u0434\u043D\u043E\u0433\u043E \u043B\u0438\u0434\u0430, \u0442\u043E \u043E\u043D\u0430 \u043D\u0435 \u0431\u0443\u0434\u0435\u0442 \u043E\u0442\u043E\u0431\u0440\u0430\u0436\u0430\u0442\u044C\u0441\u044F "])), (_l()(), i1.ɵeld(6, 0, null, null, 7, "form", [["class", "lead-forms-configurator__settings"], ["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 8).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 16384, null, 0, i9.ɵangular_packages_forms_forms_bh, [], null, null), i1.ɵdid(8, 540672, null, 0, i9.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i9.ControlContainer, null, [i9.FormGroupDirective]), i1.ɵdid(10, 16384, null, 0, i9.NgControlStatusGroup, [[4, i9.ControlContainer]], null, null), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_LeadFormsConfiguratorComponent_1)), i1.ɵdid(12, 278528, null, 0, i10.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i10.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; _ck(_v, 3, 0); var currVal_9 = _co.form; _ck(_v, 8, 0, currVal_9); var currVal_10 = i1.ɵunv(_v, 12, 0, i1.ɵnov(_v, 13).transform(_co.forms$)); _ck(_v, 12, 0, currVal_10); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).inline; var currVal_1 = (((i1.ɵnov(_v, 3).color !== "primary") && (i1.ɵnov(_v, 3).color !== "accent")) && (i1.ɵnov(_v, 3).color !== "warn")); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵnov(_v, 10).ngClassUntouched; var currVal_3 = i1.ɵnov(_v, 10).ngClassTouched; var currVal_4 = i1.ɵnov(_v, 10).ngClassPristine; var currVal_5 = i1.ɵnov(_v, 10).ngClassDirty; var currVal_6 = i1.ɵnov(_v, 10).ngClassValid; var currVal_7 = i1.ɵnov(_v, 10).ngClassInvalid; var currVal_8 = i1.ɵnov(_v, 10).ngClassPending; _ck(_v, 6, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }); }
export function View_LeadFormsConfiguratorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-lead-forms-configurator", [], null, null, null, View_LeadFormsConfiguratorComponent_0, RenderType_LeadFormsConfiguratorComponent)), i1.ɵdid(1, 245760, null, 0, i11.LeadFormsConfiguratorComponent, [i5.AdNetworkFormsQuery, i6.AdNetworkFormsService, i12.PlatformAssignationsService, i13.PlatformAssignationsQuery, i14.AdNetworkGroupsService, i9.FormBuilder, i15.AkitaNgFormsManager], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LeadFormsConfiguratorComponentNgFactory = i1.ɵccf("app-lead-forms-configurator", i11.LeadFormsConfiguratorComponent, View_LeadFormsConfiguratorComponent_Host_0, { type: "type", platform: "platform" }, {}, []);
export { LeadFormsConfiguratorComponentNgFactory as LeadFormsConfiguratorComponentNgFactory };
