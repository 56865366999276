import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {AdManagerRoutingModule} from './ad-manager-routing.module';
import {SelectingFormatComponent} from './components/steps/selecting-format/selecting-format.component';
import {CreateAdComponent} from './routes/create-ad/create-ad.component';
import {AdFormatComponent} from './components/ad-format/ad-format.component';
import {CreateTeazerComponent} from './components/steps/create-teazer/create-teazer.component';
import {TeazerComponent} from './components/teazer/teazer.component';
import {CreateTeazerFormComponent} from './components/create-teazer-form/create-teazer-form.component';
import {AdInputComponent} from './components/ad-input/ad-input.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '../../shared/shared.module';
import {ImagesManagerComponent} from './components/images-manager/images-manager.component';
import {AdImageComponent} from './components/ad-image/ad-image.component';
import {CreatePromopostComponent} from './components/steps/create-promopost/create-promopost.component';
import {PromopostComponent} from './components/promopost/promopost.component';
import {ClarityModule} from '@clr/angular';
import {CreatePromopostFormComponent} from './components/create-promopost-form/create-promopost-form.component';
import {SideFormWrapperComponent} from './components/side-form-wrapper/side-form-wrapper.component';
import {TargetingSettingsComponent} from './components/steps/targeting-settings/targeting-settings.component';
import {NgrUiModule} from '../../ngr-ui/ngr-ui.module';
import {
  MatBadgeModule,
  MatButton, MatButtonModule,
  MatCardModule,
  MatCheckboxModule, MatDialogModule,
  MatFormFieldModule, MatGridListModule,
  MatIconModule, MatInputModule, MatListModule, MatPaginatorIntl, MatPaginatorModule, MatProgressSpinnerModule,
  MatRadioModule,
  MatSelectModule, MatSnackBarModule, MatSortModule,
  MatStepperModule, MatTableModule, MatToolbarModule, MatTooltipModule
} from '@angular/material';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {NgSelectModule} from '@ng-select/ng-select';
import {SelectableGroupComponent} from './components/selectable-group/selectable-group.component';
import {GroupsSelectorComponent} from './components/groups-selector/groups-selector.component';
import {ReadyComponent} from './components/steps/ready/ready.component';
import {AdAccountManagerComponent} from './components/steps/ad-account-manager/ad-account-manager.component';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {getRussianPaginatorIntl} from './utils/paginator.intl';
import {CreateCampaignFormComponent} from './components/create-campaign-form/create-campaign-form.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {GroupOptionViewComponent} from './components/group-option-view/group-option-view.component';
import {AdAudienceComponent} from './components/ad-audience/ad-audience.component';
import {ModerationDetailsComponent} from './components/moderation-details/moderation-details.component';
import {AddStrategyComponent} from './components/steps/add-strategy/add-strategy.component';
import {RateManagerComponent} from './components/steps/rate-manager/rate-manager.component';
import {AutomationModule} from '../automation.module';
import {AdStartSettingsComponent} from './components/steps/ad-start-settings/ad-start-settings.component';
import {AdFormComponent} from './components/ad-form/ad-form.component';
import {UpdateAdComponent} from './routes/update-ad/update-ad.component';
import {RateManagerExplainComponent} from './components/rate-manager-explain/rate-manager-explain.component';
import {ActivateCabinetsComponent} from './components/steps/activate-cabinets/activate-cabinets.component';
import {MultipleEditSuccessComponent} from './components/modals/multiple-edit-success/multiple-edit-success.component';
import {MultipleEditRejectComponent} from './components/modals/multiple-edit-reject/multiple-edit-reject.component';
import {MatConfirmDialogComponent} from './components/modals/configrm-dialog/mat-confirm-dialog.component';
import {CreateAdInstructionComponent} from './components/modals/create-ad-instruction/create-ad-instruction.component';
import {ModerationDetailItemComponent} from './components/moderation-detail-item/moderation-detail-item.component';
import {NewCreateAdComponent} from './routes/new-create-ad/new-create-ad.component';
import {AdTargetResourceSelectorComponent} from './components/lite/forms/ad-target-resource-selector/ad-target-resource-selector.component';
import {CreateAdLiteComponent} from './routes/lite/create-ad/create-ad-lite.component';
import { PromopostFormLiteComponent } from './components/lite/forms/promopost-form-lite/promopost-form-lite.component';
import {VkControlsModule} from '../../vk-controls/vk-controls.module';
import { AdFormLiteComponent } from './components/lite/forms/ad-form-lite/ad-form-lite.component';
import {ClickOutsideModule} from 'ng4-click-outside';
import { TeaserFormLiteComponent } from './components/lite/forms/teaser-form-lite/teaser-form-lite.component';
import { UpdateAdLiteComponent } from './routes/lite/update-ad-lite/update-ad-lite.component';
import {VkModule} from "../../vk/vk.module";

@NgModule({
  imports: [
    CommonModule,
    AdManagerRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    ClarityModule,
    NgrUiModule,
    MatSelectModule,
    MatIconModule,
    MatFormFieldModule,
    NgSelectModule,
    MatCardModule,
    MatCheckboxModule,
    MatStepperModule,
    MatRadioModule,
    MatInputModule,
    MatSnackBarModule,
    ScrollingModule,
    MatSelectModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatIconModule,
    NgxMatSelectSearchModule,
    MatGridListModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatDialogModule,
    MatButtonModule,
    FlexLayoutModule,
    MatProgressSpinnerModule,
    SharedModule,
    MatSelectModule,
    MatFormFieldModule,
    NgxMatSelectSearchModule,
    MatTooltipModule,
    MatListModule,
    VkControlsModule,
    ClickOutsideModule,
    MatBadgeModule,
    VkModule,
  ],
  providers: [
    {provide: MatPaginatorIntl, useValue: getRussianPaginatorIntl()}
  ],
  declarations: [
    SelectingFormatComponent,
    CreateAdComponent,
    AdFormatComponent,
    CreateTeazerComponent,
    TeazerComponent,
    CreateTeazerFormComponent,
    AdInputComponent,
    ImagesManagerComponent,
    AdImageComponent,
    CreatePromopostComponent,
    PromopostComponent,
    CreatePromopostFormComponent,
    SideFormWrapperComponent,
    TargetingSettingsComponent,
    SelectableGroupComponent,
    GroupsSelectorComponent,
    ReadyComponent,
    AdAccountManagerComponent,
    CreateCampaignFormComponent,
    GroupOptionViewComponent,
    AdAudienceComponent,
    ModerationDetailsComponent,
    AddStrategyComponent,
    RateManagerComponent,
    AdStartSettingsComponent,
    AdFormComponent,
    UpdateAdComponent,
    RateManagerExplainComponent,
    ActivateCabinetsComponent,
    MultipleEditSuccessComponent,
    MultipleEditRejectComponent,
    MatConfirmDialogComponent,
    CreateAdInstructionComponent,
    ModerationDetailItemComponent,
    NewCreateAdComponent,
    AdTargetResourceSelectorComponent,
    CreateAdLiteComponent,
    PromopostFormLiteComponent,
    AdFormLiteComponent,
    TeaserFormLiteComponent,
    UpdateAdLiteComponent
  ],
  exports: [
    AdTargetResourceSelectorComponent,
    AdFormatComponent,
    ImagesManagerComponent
  ],
  entryComponents: [
    CreateCampaignFormComponent,
    RateManagerExplainComponent,
    MultipleEditSuccessComponent,
    MultipleEditRejectComponent,
    MatConfirmDialogComponent
  ]
})
export class AdManagerModule {
}
