/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
class OAuthService extends __BaseService {
  static readonly Apiv2OauthAuthorizeByProviderGetPath = '/apiv2/oauth/authorize/{provider}';
  static readonly Apiv2OauthAuthorizeByProviderHeadPath = '/apiv2/oauth/authorize/{provider}';
  static readonly Apiv2OauthCodeByProviderGetPath = '/apiv2/oauth/code/{provider}';
  static readonly Apiv2OauthCodeByProviderHeadPath = '/apiv2/oauth/code/{provider}';
  static readonly CheckProviderTokenPath = '/apiv2/oauth/check/{provider}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `OAuthService.Apiv2OauthAuthorizeByProviderGetParams` containing the following parameters:
   *
   * - `provider`:
   *
   * - `domain`:
   *
   * @return Success
   */
  Apiv2OauthAuthorizeByProviderGetResponse(params: OAuthService.Apiv2OauthAuthorizeByProviderGetParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.domain != null) __params = __params.set('domain', params.domain.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/oauth/authorize/${encodeURIComponent(params.provider)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * @param params The `OAuthService.Apiv2OauthAuthorizeByProviderGetParams` containing the following parameters:
   *
   * - `provider`:
   *
   * - `domain`:
   *
   * @return Success
   */
  Apiv2OauthAuthorizeByProviderGet(params: OAuthService.Apiv2OauthAuthorizeByProviderGetParams): __Observable<{}> {
    return this.Apiv2OauthAuthorizeByProviderGetResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * @param provider undefined
   */
  Apiv2OauthAuthorizeByProviderHeadResponse(provider: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'HEAD',
      this.rootUrl + `/apiv2/oauth/authorize/${encodeURIComponent(provider)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param provider undefined
   */
  Apiv2OauthAuthorizeByProviderHead(provider: string): __Observable<null> {
    return this.Apiv2OauthAuthorizeByProviderHeadResponse(provider).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `OAuthService.Apiv2OauthCodeByProviderGetParams` containing the following parameters:
   *
   * - `provider`:
   *
   * - `state`:
   *
   * - `referer`:
   *
   * - `code`:
   */
  Apiv2OauthCodeByProviderGetResponse(params: OAuthService.Apiv2OauthCodeByProviderGetParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.state != null) __params = __params.set('state', params.state.toString());
    if (params.referer != null) __params = __params.set('referer', params.referer.toString());
    if (params.code != null) __params = __params.set('code', params.code.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/oauth/code/${encodeURIComponent(params.provider)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `OAuthService.Apiv2OauthCodeByProviderGetParams` containing the following parameters:
   *
   * - `provider`:
   *
   * - `state`:
   *
   * - `referer`:
   *
   * - `code`:
   */
  Apiv2OauthCodeByProviderGet(params: OAuthService.Apiv2OauthCodeByProviderGetParams): __Observable<null> {
    return this.Apiv2OauthCodeByProviderGetResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param provider undefined
   */
  Apiv2OauthCodeByProviderHeadResponse(provider: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'HEAD',
      this.rootUrl + `/apiv2/oauth/code/${encodeURIComponent(provider)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param provider undefined
   */
  Apiv2OauthCodeByProviderHead(provider: string): __Observable<null> {
    return this.Apiv2OauthCodeByProviderHeadResponse(provider).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param provider undefined
   * @return Success
   */
  CheckProviderTokenResponse(provider: string): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/apiv2/oauth/check/${encodeURIComponent(provider)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param provider undefined
   * @return Success
   */
  CheckProviderToken(provider: string): __Observable<boolean> {
    return this.CheckProviderTokenResponse(provider).pipe(
      __map(_r => _r.body as boolean)
    );
  }
}

module OAuthService {

  /**
   * Parameters for Apiv2OauthAuthorizeByProviderGet
   */
  export interface Apiv2OauthAuthorizeByProviderGetParams {
    provider: string;
    domain?: string;
  }

  /**
   * Parameters for Apiv2OauthCodeByProviderGet
   */
  export interface Apiv2OauthCodeByProviderGetParams {
    provider: string;
    state?: string;
    referer?: string;
    code?: string;
  }
}

export { OAuthService }
