import { OnInit } from '@angular/core';
import { StoreService } from '../../../../../services/store.service';
import { MockService } from '../../../../../services/mock/mock.service';
import { IntegrationFormService } from "../../../services/integration-form.service";
import { FormControl, FormGroup } from "@angular/forms";
var serviceCode = 'AMO_CRM_IMPORT';
var AmoCrmContactsImportComponent = /** @class */ (function () {
    function AmoCrmContactsImportComponent(store, mock, integrationForm) {
        this.store = store;
        this.mock = mock;
        this.integrationForm = integrationForm;
    }
    Object.defineProperty(AmoCrmContactsImportComponent.prototype, "formFields", {
        get: function () {
            return this.store.formFields;
        },
        enumerable: true,
        configurable: true
    });
    AmoCrmContactsImportComponent.prototype.ngOnInit = function () {
        this.LoadCrmSections();
        this.LoadCrmFormFields();
    };
    AmoCrmContactsImportComponent.prototype.LoadCrmSections = function () {
        var _this = this;
        this.sections = this.store.services.find(function (service) { return service.code === serviceCode; }).sections;
        var sectionsControls = {};
        this.sections.forEach(function (section) {
            console.warn(section.code);
            _this.integrationForm.form.get('amoImport').addControl(section.code, _this.sectionToFormGroup(section));
        });
        // this.integrationF
        // console.log(sectionsControls);
        // this.integrationForm.form.controls.amoImport = new FormGroup(sectionsControls);
        // this.integrationForm.form.controls.amoImport.valueChanges.subscribe((data => {
        //   console.log(this.integrationForm.form);
        // }));
        console.warn(this.integrationForm.form, 'ALL FIELDS INITED');
    };
    AmoCrmContactsImportComponent.prototype.sectionToFormGroup = function (section) {
        var sectionFormGroup = new FormGroup({
            status: new FormControl(),
            fields: new FormGroup({})
        });
        section.fields.forEach(function (field) {
            sectionFormGroup.get('fields').addControl(field.code, new FormControl());
        });
        return sectionFormGroup;
    };
    AmoCrmContactsImportComponent.prototype.LoadCrmFormFields = function () {
        // this.formFields = this.store.formFields;
    };
    AmoCrmContactsImportComponent.prototype.getFormsField = function () {
        this.store.getFormsField();
    };
    return AmoCrmContactsImportComponent;
}());
export { AmoCrmContactsImportComponent };
