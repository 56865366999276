import { OnInit } from '@angular/core';
import { AdFormManagerService } from '../../services/ad-form-manager/ad-form-manager.service';
var NewAdFormatStepComponent = /** @class */ (function () {
    function NewAdFormatStepComponent(adFormManagerService) {
        this.adFormManagerService = adFormManagerService;
    }
    Object.defineProperty(NewAdFormatStepComponent.prototype, "formats", {
        get: function () {
            return this.adFormManagerService.adFormats;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewAdFormatStepComponent.prototype, "form", {
        get: function () {
            return this.adFormManagerService.adForm;
        },
        enumerable: true,
        configurable: true
    });
    NewAdFormatStepComponent.prototype.ngOnInit = function () {
    };
    NewAdFormatStepComponent.prototype.SelectField = function ($event) {
        this.adFormManagerService.SelectFormat($event);
    };
    Object.defineProperty(NewAdFormatStepComponent.prototype, "SelectedAdFormat", {
        get: function () {
            return this.adFormManagerService.SelectedAdFormat;
        },
        enumerable: true,
        configurable: true
    });
    return NewAdFormatStepComponent;
}());
export { NewAdFormatStepComponent };
