import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TokenCheckerService} from '../../../shared/services/token-checker.service';

@Component({
  selector: 'app-token-receiver',
  templateUrl: './token-receiver.component.html',
  styleUrls: ['./token-receiver.component.scss']
})
export class TokenReceiverComponent implements OnInit {

  constructor(
      private route: ActivatedRoute,
      private tokenChecker: TokenCheckerService,
      private router: Router
  ) { }

  ngOnInit() {
    this.Load();
  }

  Load () {
    const token = this.route.snapshot.queryParams.token;
    const expiredAt = this.route.snapshot.queryParams.expiredAt;

    this.tokenChecker.SaveToken(token, expiredAt);
        this.router.navigate(['dashboard']);
  }

}
