import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {StrategyInput} from '../../../api/models/strategy-input';
import {StrategyInputValue} from '../../models/view-models/strategy-input-value';

@Component({
  selector: 'app-strategy-creation-form-input',
  templateUrl: './strategy-creation-form-input.component.html',
  styleUrls: ['./strategy-creation-form-input.component.scss']
})
export class StrategyCreationFormInputComponent implements OnInit {
  @Input() public disabled: boolean = false;
  @Input() public input: StrategyInput = null;
  @Input() public model: StrategyInputValue = null;

  @Output() public onValidationTriggered: EventEmitter<StrategyInputValue> = new EventEmitter<StrategyInputValue>();
  @Output() public onInputChanged: EventEmitter<number> = new EventEmitter<number>();

  constructor() { }

  ngOnInit() {
  }

  public EmitValidation(): void {
    this.onValidationTriggered.emit(this.model);
  }

  public InputTouched(): void {
    this.onInputChanged.emit(this.input.id);
  }
}
