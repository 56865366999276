import {Component, OnInit} from '@angular/core';
import {AdFormManagerService} from '../../services/ad-form-manager/ad-form-manager.service';

@Component({
  selector: ' app-moderation-settings',
  templateUrl: './moderation-settings.component.html',
  styleUrls: ['./moderation-settings.component.scss']
})
export class ModerationSettingsComponent implements OnInit {
  public get form(): any {
    return this.adFormManagerService.getControl('moderationData');
  }

  public get budget() {
    return this.adFormManagerService.budget;
  }

  public Tip: string = 'Все объявления проходят обязательную модерацию (проверку объявления на соответствия правилам размещения) сотрудниками ВКонтакте. \n' +
    'Если объявление не соответствует правилам, то его могут отклонить и оно не может быть включено. \n' +
    '\n' +
    'Если вы выберите “Отправить на модерацию сразу после создания объявления” и “Запустить объявление сразу после прохождения модерации”. То, если модераторы одобрят объявление, оно будет запущено и начнутся показы объявления. \n' +
    'Если не будет одобрено, то вам необходимо изменить объявление и повторно отправить на модерацию.\n';

  public get IsBudgetZero(): boolean {
    if (this.IsSelectedAccountAdmin()) {
      return this.budget === 0;
    }
    return false;
  }

  public get beSendingToModeration() {
    const sendModeration = this.adFormManagerService.getControl('moderationData', 'sendModeration').value;
    return !sendModeration;
  }

  public IsSelectedAccountAdmin() {
    return this.adFormManagerService
      .IsSelectedAccountAdmin()
  }

  constructor(
    private adFormManagerService: AdFormManagerService
  ) {
  }

  ngOnInit() {
    console.log('ENABLING MODERATION SEND');
    const moderationSend = this.adFormManagerService.getControl('moderationData', 'sendModeration');
    moderationSend.enable();
    console.log(moderationSend);
  }

  public RefreshBudget($event: any) {
    this.adFormManagerService.LoadUserBudget()
      .subscribe();
  }
}
