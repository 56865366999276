import {Component, OnInit} from '@angular/core';
import {MatDialog} from "@angular/material";
import {CopyingAdsDialogComponent} from "../../copying-module/components/copying-ads-dialog/copying-ads-dialog.component";

@Component({
  selector: 'app-testing-route',
  templateUrl: './testing-route.component.html',
  styleUrls: ['./testing-route.component.scss']
})
export class TestingRouteComponent implements OnInit {

  constructor(
    private dialog: MatDialog
  ) {
  }

  ngOnInit() {
  }

  public openDialog() {
    this.dialog.open(CopyingAdsDialogComponent, {
      data: {
        ad_format: 9,
        account_id: 1604618883,
        campaign_id: 1012615942,
        client_id: null,
        ads: [
          {
            id: 0
          }
        ],
        bid_range: {
          adFormat: 9,
          cpcMax: 150000,
          cpcMin: 100,
          cpmMax: 1000000,
          cpmMin: 3000
        }
      }
    })
  }

}
