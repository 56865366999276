/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { APIReponseWrapperListPostMonitorBackend_V2ModelsvkMyTargetAPICampaign } from '../models/apireponse-wrapper-list-post-monitor-backend-_v2modelsvk-my-target-apicampaign';
import { APIReponseWrapperListPostMonitorBackend_V2ModelsvkMyTargetAPIBanner } from '../models/apireponse-wrapper-list-post-monitor-backend-_v2modelsvk-my-target-apibanner';
import { APIReponseWrapperListPostMonitorBackend_V2ModelsvkMyTargetAPILeadForm } from '../models/apireponse-wrapper-list-post-monitor-backend-_v2modelsvk-my-target-apilead-form';
import { APIReponseWrapperListPostMonitorBackend_V2ModelsvkMyTargetAPILeadInfo } from '../models/apireponse-wrapper-list-post-monitor-backend-_v2modelsvk-my-target-apilead-info';
import { APIReponseWrapperListPostMonitorBackend_V2ModelsvkMyTargetAPIRemarketingUsersList } from '../models/apireponse-wrapper-list-post-monitor-backend-_v2modelsvk-my-target-apiremarketing-users-list';
@Injectable({
  providedIn: 'root',
})
class MyTargetService extends __BaseService {
  static readonly GetCampaignsPath = '/apiv2/my-target/campaigns';
  static readonly GetBannersPath = '/apiv2/my-target/banners';
  static readonly GetLeadAdsFormsPath = '/apiv2/my-target/lead-ads-forms';
  static readonly GetLeadInfoAsyncPath = '/apiv2/my-target/lead-info';
  static readonly GetRemarketingGroupsPath = '/apiv2/my-target/remarketing-groups';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `MyTargetService.GetCampaignsParams` containing the following parameters:
   *
   * - `offset`:
   *
   * - `limit`:
   *
   * - `status__ne`:
   *
   * - `status__in`:
   *
   * - `status`:
   *
   * - `sorting`:
   *
   * - `last_updated__lte`:
   *
   * - `last_updated__lt`:
   *
   * - `last_updated__gte`:
   *
   * - `last_updated__gt`:
   *
   * - `ids`:
   *
   * @return Success
   */
  GetCampaignsResponse(params: MyTargetService.GetCampaignsParams): __Observable<__StrictHttpResponse<APIReponseWrapperListPostMonitorBackend_V2ModelsvkMyTargetAPICampaign>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    if (params.statusNe != null) __params = __params.set('status__ne', params.statusNe.toString());
    if (params.statusIn != null) __params = __params.set('status__in', params.statusIn.toString());
    if (params.status != null) __params = __params.set('status', params.status.toString());
    if (params.sorting != null) __params = __params.set('sorting', params.sorting.toString());
    if (params.lastUpdatedLte != null) __params = __params.set('last_updated__lte', params.lastUpdatedLte.toString());
    if (params.lastUpdatedLt != null) __params = __params.set('last_updated__lt', params.lastUpdatedLt.toString());
    if (params.lastUpdatedGte != null) __params = __params.set('last_updated__gte', params.lastUpdatedGte.toString());
    if (params.lastUpdatedGt != null) __params = __params.set('last_updated__gt', params.lastUpdatedGt.toString());
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/my-target/campaigns`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<APIReponseWrapperListPostMonitorBackend_V2ModelsvkMyTargetAPICampaign>;
      })
    );
  }
  /**
   * @param params The `MyTargetService.GetCampaignsParams` containing the following parameters:
   *
   * - `offset`:
   *
   * - `limit`:
   *
   * - `status__ne`:
   *
   * - `status__in`:
   *
   * - `status`:
   *
   * - `sorting`:
   *
   * - `last_updated__lte`:
   *
   * - `last_updated__lt`:
   *
   * - `last_updated__gte`:
   *
   * - `last_updated__gt`:
   *
   * - `ids`:
   *
   * @return Success
   */
  GetCampaigns(params: MyTargetService.GetCampaignsParams): __Observable<APIReponseWrapperListPostMonitorBackend_V2ModelsvkMyTargetAPICampaign> {
    return this.GetCampaignsResponse(params).pipe(
      __map(_r => _r.body as APIReponseWrapperListPostMonitorBackend_V2ModelsvkMyTargetAPICampaign)
    );
  }

  /**
   * @param params The `MyTargetService.GetBannersParams` containing the following parameters:
   *
   * - `offset`:
   *
   * - `limit`:
   *
   * - `updated__lte`:
   *
   * - `updated__lt`:
   *
   * - `updated__gte`:
   *
   * - `updated__gt`:
   *
   * - `status__ne`:
   *
   * - `status__in`:
   *
   * - `status`:
   *
   * - `ids`:
   *
   * - `campaign_status__ne`:
   *
   * - `campaign_status__in`:
   *
   * - `campaign_status`:
   *
   * - `campaignIds`:
   *
   * - `_url`:
   *
   * - `_textblock`:
   *
   * @return Success
   */
  GetBannersResponse(params: MyTargetService.GetBannersParams): __Observable<__StrictHttpResponse<APIReponseWrapperListPostMonitorBackend_V2ModelsvkMyTargetAPIBanner>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    if (params.updatedLte != null) __params = __params.set('updated__lte', params.updatedLte.toString());
    if (params.updatedLt != null) __params = __params.set('updated__lt', params.updatedLt.toString());
    if (params.updatedGte != null) __params = __params.set('updated__gte', params.updatedGte.toString());
    if (params.updatedGt != null) __params = __params.set('updated__gt', params.updatedGt.toString());
    if (params.statusNe != null) __params = __params.set('status__ne', params.statusNe.toString());
    if (params.statusIn != null) __params = __params.set('status__in', params.statusIn.toString());
    if (params.status != null) __params = __params.set('status', params.status.toString());
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    if (params.campaignStatusNe != null) __params = __params.set('campaign_status__ne', params.campaignStatusNe.toString());
    if (params.campaignStatusIn != null) __params = __params.set('campaign_status__in', params.campaignStatusIn.toString());
    if (params.campaignStatus != null) __params = __params.set('campaign_status', params.campaignStatus.toString());
    (params.campaignIds || []).forEach(val => {if (val != null) __params = __params.append('campaignIds', val.toString())});
    if (params.Url != null) __params = __params.set('_url', params.Url.toString());
    if (params.Textblock != null) __params = __params.set('_textblock', params.Textblock.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/my-target/banners`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<APIReponseWrapperListPostMonitorBackend_V2ModelsvkMyTargetAPIBanner>;
      })
    );
  }
  /**
   * @param params The `MyTargetService.GetBannersParams` containing the following parameters:
   *
   * - `offset`:
   *
   * - `limit`:
   *
   * - `updated__lte`:
   *
   * - `updated__lt`:
   *
   * - `updated__gte`:
   *
   * - `updated__gt`:
   *
   * - `status__ne`:
   *
   * - `status__in`:
   *
   * - `status`:
   *
   * - `ids`:
   *
   * - `campaign_status__ne`:
   *
   * - `campaign_status__in`:
   *
   * - `campaign_status`:
   *
   * - `campaignIds`:
   *
   * - `_url`:
   *
   * - `_textblock`:
   *
   * @return Success
   */
  GetBanners(params: MyTargetService.GetBannersParams): __Observable<APIReponseWrapperListPostMonitorBackend_V2ModelsvkMyTargetAPIBanner> {
    return this.GetBannersResponse(params).pipe(
      __map(_r => _r.body as APIReponseWrapperListPostMonitorBackend_V2ModelsvkMyTargetAPIBanner)
    );
  }

  /**
   * @param params The `MyTargetService.GetLeadAdsFormsParams` containing the following parameters:
   *
   * - `offset`:
   *
   * - `limit`:
   *
   * - `campaignIds`:
   *
   * - `bannerIds`:
   *
   * @return Success
   */
  GetLeadAdsFormsResponse(params: MyTargetService.GetLeadAdsFormsParams): __Observable<__StrictHttpResponse<APIReponseWrapperListPostMonitorBackend_V2ModelsvkMyTargetAPILeadForm>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    (params.campaignIds || []).forEach(val => {if (val != null) __params = __params.append('campaignIds', val.toString())});
    (params.bannerIds || []).forEach(val => {if (val != null) __params = __params.append('bannerIds', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/my-target/lead-ads-forms`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<APIReponseWrapperListPostMonitorBackend_V2ModelsvkMyTargetAPILeadForm>;
      })
    );
  }
  /**
   * @param params The `MyTargetService.GetLeadAdsFormsParams` containing the following parameters:
   *
   * - `offset`:
   *
   * - `limit`:
   *
   * - `campaignIds`:
   *
   * - `bannerIds`:
   *
   * @return Success
   */
  GetLeadAdsForms(params: MyTargetService.GetLeadAdsFormsParams): __Observable<APIReponseWrapperListPostMonitorBackend_V2ModelsvkMyTargetAPILeadForm> {
    return this.GetLeadAdsFormsResponse(params).pipe(
      __map(_r => _r.body as APIReponseWrapperListPostMonitorBackend_V2ModelsvkMyTargetAPILeadForm)
    );
  }

  /**
   * @param params The `MyTargetService.GetLeadInfoAsyncParams` containing the following parameters:
   *
   * - `offset`:
   *
   * - `limit`:
   *
   * - `formId`:
   *
   * - `campaignIds`:
   *
   * - `bannerIds`:
   *
   * @return Success
   */
  GetLeadInfoAsyncResponse(params: MyTargetService.GetLeadInfoAsyncParams): __Observable<__StrictHttpResponse<APIReponseWrapperListPostMonitorBackend_V2ModelsvkMyTargetAPILeadInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    if (params.formId != null) __params = __params.set('formId', params.formId.toString());
    (params.campaignIds || []).forEach(val => {if (val != null) __params = __params.append('campaignIds', val.toString())});
    (params.bannerIds || []).forEach(val => {if (val != null) __params = __params.append('bannerIds', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/my-target/lead-info`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<APIReponseWrapperListPostMonitorBackend_V2ModelsvkMyTargetAPILeadInfo>;
      })
    );
  }
  /**
   * @param params The `MyTargetService.GetLeadInfoAsyncParams` containing the following parameters:
   *
   * - `offset`:
   *
   * - `limit`:
   *
   * - `formId`:
   *
   * - `campaignIds`:
   *
   * - `bannerIds`:
   *
   * @return Success
   */
  GetLeadInfoAsync(params: MyTargetService.GetLeadInfoAsyncParams): __Observable<APIReponseWrapperListPostMonitorBackend_V2ModelsvkMyTargetAPILeadInfo> {
    return this.GetLeadInfoAsyncResponse(params).pipe(
      __map(_r => _r.body as APIReponseWrapperListPostMonitorBackend_V2ModelsvkMyTargetAPILeadInfo)
    );
  }

  /**
   * @return Success
   */
  GetRemarketingGroupsResponse(): __Observable<__StrictHttpResponse<APIReponseWrapperListPostMonitorBackend_V2ModelsvkMyTargetAPIRemarketingUsersList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/my-target/remarketing-groups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<APIReponseWrapperListPostMonitorBackend_V2ModelsvkMyTargetAPIRemarketingUsersList>;
      })
    );
  }
  /**
   * @return Success
   */
  GetRemarketingGroups(): __Observable<APIReponseWrapperListPostMonitorBackend_V2ModelsvkMyTargetAPIRemarketingUsersList> {
    return this.GetRemarketingGroupsResponse().pipe(
      __map(_r => _r.body as APIReponseWrapperListPostMonitorBackend_V2ModelsvkMyTargetAPIRemarketingUsersList)
    );
  }
}

module MyTargetService {

  /**
   * Parameters for GetCampaigns
   */
  export interface GetCampaignsParams {
    offset: number;
    limit: number;
    statusNe?: string;
    statusIn?: string;
    status?: string;
    sorting?: string;
    lastUpdatedLte?: string;
    lastUpdatedLt?: string;
    lastUpdatedGte?: string;
    lastUpdatedGt?: string;
    ids?: Array<number>;
  }

  /**
   * Parameters for GetBanners
   */
  export interface GetBannersParams {
    offset: number;
    limit: number;
    updatedLte?: string;
    updatedLt?: string;
    updatedGte?: string;
    updatedGt?: string;
    statusNe?: string;
    statusIn?: string;
    status?: string;
    ids?: Array<number>;
    campaignStatusNe?: string;
    campaignStatusIn?: string;
    campaignStatus?: string;
    campaignIds?: Array<number>;
    Url?: string;
    Textblock?: string;
  }

  /**
   * Parameters for GetLeadAdsForms
   */
  export interface GetLeadAdsFormsParams {
    offset: number;
    limit: number;
    campaignIds?: Array<number>;
    bannerIds?: Array<number>;
  }

  /**
   * Parameters for GetLeadInfoAsync
   */
  export interface GetLeadInfoAsyncParams {
    offset: number;
    limit: number;
    formId: number;
    campaignIds?: Array<number>;
    bannerIds?: Array<number>;
  }
}

export { MyTargetService }
