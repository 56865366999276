/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./activate-cabinets.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../ngr-ui/components/pick-list/pick-list-ngr.component.ngfactory";
import * as i3 from "../../../../../ngr-ui/components/pick-list/pick-list-ngr.component";
import * as i4 from "../../../../../api/services/automation-vk-slot.service";
import * as i5 from "@angular/common";
import * as i6 from "./activate-cabinets.component";
import * as i7 from "../../../../../api/services/automation-vk.service";
import * as i8 from "../../../../../shared/services/dexie/dexie-cabinets.service";
import * as i9 from "../../../../services/automation-expiration-manager.service";
import * as i10 from "../../../../../shared/services/dexie/dexie-clients.service";
var styles_ActivateCabinetsComponent = [i0.styles];
var RenderType_ActivateCabinetsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ActivateCabinetsComponent, data: {} });
export { RenderType_ActivateCabinetsComponent as RenderType_ActivateCabinetsComponent };
function View_ActivateCabinetsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-pick-list-ngr", [], null, [[null, "dataListChanged"], [null, "onClose"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("dataListChanged" === en)) {
        var pd_0 = (_co.OnSaveCabinetsBined($event) !== false);
        ad = (pd_0 && ad);
    } if (("onClose" === en)) {
        var pd_1 = (_co.OnClose() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_PickListNgrComponent_0, i2.RenderType_PickListNgrComponent)), i1.ɵdid(2, 114688, [[1, 4], ["pickList", 4]], 0, i3.PickListNgrComponent, [i4.AutomationVkSlotService], { dataList: [0, "dataList"], maxSelectedCount: [1, "maxSelectedCount"], slots: [2, "slots"] }, { dataListChanged: "dataListChanged", onClose: "onClose" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.accounts; var currVal_1 = _co.countOfMaxSlots; var currVal_2 = _co.slots; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_ActivateCabinetsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" \u0414\u043B\u044F \u043F\u0440\u043E\u0434\u043E\u043B\u0436\u0435\u043D\u0438\u044F \u0440\u0430\u0431\u043E\u0442\u044B \u0432\u0430\u043C \u043D\u0435\u043E\u0431\u0445\u043E\u0434\u0438\u043C\u043E \u0441\u043E\u0437\u0434\u0430\u0442\u044C \u0440\u0435\u043A\u043B\u0430\u043C\u043D\u044B\u0439 \u043A\u0430\u0431\u0438\u043D\u0435\u0442 \u0412\u041A\u043E\u043D\u0442\u0430\u043A\u0442\u0435. \u0414\u043B\u044F \u044D\u0442\u043E\u0433\u043E \u043F\u0435\u0440\u0435\u0439\u0434\u0438\u0442\u0435 "])), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [["href", "https://postmonitor.ru/blog/instrukciya-po-sozdaniyu-reklamnogo-kabineta-vkontakte"], ["rel", "nofollow noopener"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u043F\u043E \u0441\u0441\u044B\u043B\u043A\u0435"])), (_l()(), i1.ɵted(-1, null, [". "]))], null, null); }
function View_ActivateCabinetsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ActivateCabinetsComponent_2)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["emptyCabinets", 2]], null, 0, null, View_ActivateCabinetsComponent_3)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "activate-cabinets__toolbox"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "button", [["class", "vk-standart-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.Refresh() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\u041E\u0431\u043D\u043E\u0432\u0438\u0442\u044C"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.accounts.length > 0); var currVal_1 = i1.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_ActivateCabinetsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { pickList: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "activate-cabinets"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ActivateCabinetsComponent_1)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.accounts && _co.slots); _ck(_v, 3, 0, currVal_0); }, null); }
export function View_ActivateCabinetsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-activate-cabinets", [], null, null, null, View_ActivateCabinetsComponent_0, RenderType_ActivateCabinetsComponent)), i1.ɵdid(1, 114688, null, 0, i6.ActivateCabinetsComponent, [i7.AutomationVkService, i4.AutomationVkSlotService, i7.AutomationVkService, i8.DexieCabinetsService, i4.AutomationVkSlotService, i9.AutomationExpirationManagerService, i10.DexieClientsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ActivateCabinetsComponentNgFactory = i1.ɵccf("app-activate-cabinets", i6.ActivateCabinetsComponent, View_ActivateCabinetsComponent_Host_0, { slots: "slots", countOfMaxSlots: "countOfMaxSlots", accounts: "accounts" }, { bindingChanged: "bindingChanged" }, []);
export { ActivateCabinetsComponentNgFactory as ActivateCabinetsComponentNgFactory };
