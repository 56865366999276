<app-popover-menu>
  <div *appPopoverMenuContent>
    <ng-container *ngFor="let item of items">
      <div class="link-param" (click)="SelectItem(item)">
        <div class="link-param__title">{{item.title}}</div>
        <div class="link-param__description" [innerHtml]="item.description"></div>
      </div>
    </ng-container>
  </div>
  <div class="link-query-param-input">
    <ng-container *ngIf="ShowTextField; else valueTemplate">
      <input type="text" #textField class="link-query-param-input__text-field" [(ngModel)]="value"
             (ngModelChange)="emitChange()"/>
    </ng-container>
    <ng-template #valueTemplate>
      <div class="link-query-param-input__text-field link-query-param-input__text-field_value">
        <ng-container
          [ngTemplateOutlet]="selectedItemTemplate"
          [ngTemplateOutletContext]="{selectedItem: selectedItem}"
        ></ng-container>
      </div>
    </ng-template>
    <button class="link-query-param-input__clear-button" (click)="ClearField()">
      <mat-icon>
        close
      </mat-icon>
    </button>
    <button class="link-query-param-input__menu-trigger" appPopoverMenuTrigger>
      <mat-icon>more_vert</mat-icon>
    </button>
  </div>
</app-popover-menu>
