import {Injectable} from '@angular/core';
import {PlatformsTypes} from '../../platforms-types';
import {AmoService, BitrixService, MegaplanService} from '../../../api/services';
import {
  createCrmUserFromBitrix,
  createCrmUserFromCrmUser,
  createCrmUserFromMegaplan, createCrmUserFromMoiSklad,
  createCrmUserFromUOn, MoiSkladEmployee
} from './crm-user.model';
import {CrmUsersStore} from './crm-users.store';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {UonManager} from '../../models/uon-manager.model';
import {MoiSkladResponseWrapper} from '../../../models/moi-sklad-response-wrapper';
import {BehaviorSubject} from "rxjs";
import {distinctUntilChanged, filter} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class CrmUsersService {

  public moiSkladBaseUrl = `${environment.backendUri}/${environment.apiPrefix}/moy-sklad`;
  private pageToken = new BehaviorSubject(null);
  private platformType: PlatformsTypes = null;
  private pageToken$ = this.pageToken
    .asObservable()
    .pipe(
      distinctUntilChanged(),
      filter(v => v !== null)
    )

  constructor(
    private store: CrmUsersStore,
    private amoService: AmoService,
    private bitrixService: BitrixService,
    private megaplanService: MegaplanService,
    private http: HttpClient
  ) {
    this.pageToken$.subscribe(pageToken => {
      this.LoadUsers(this.platformType, pageToken)
    })
  }

  public LoadUsers(type: PlatformsTypes, pageToken: any = null) {
    this.platformType = type;
    switch (type) {
      case PlatformsTypes.AMO_CRM_EXPORT:
      case PlatformsTypes.AMO_CRM_IMPORT:
        this.LoadAmoCrmUsers(pageToken);
        break;
      case PlatformsTypes.MEGAPLAN_IMPORT:
      case PlatformsTypes.MEGAPLAN_EXPORT:
        this.LoadMegaplanUsers(pageToken);
        break;
      case PlatformsTypes.BITRIX_EXPORT:
      case PlatformsTypes.BITRIX_IMPORT:
        this.LoadBitrixUsers(pageToken);
        break;
      case PlatformsTypes.UON_IMPORT:
      case PlatformsTypes.UON_EXPORT:
        this.LoadUOnManagers(pageToken);
        break;
      case PlatformsTypes.MOY_SKLAD_EXPORT:
      case PlatformsTypes.MOY_SKLAD_IMPORT:
        this.LoadMoiSkladEmployees(pageToken);
        break;
      default:
        throw new Error(`Unknown platform type: ${type}`);
    }
  }

  public LoadAmoCrmUsers(pageToken: any) {
    this.amoService.GetUsers()
      .subscribe((data) => {
        if (data) {
          const users = data.map(x => createCrmUserFromCrmUser(x));
          this.store.set(users);
        }
      });
  }

  private LoadBitrixUsers(page: any = null) {
    if (!page) {
      this.store.set([]);
    }
    this.bitrixService.GetUsers(page)
      .subscribe(data => {
        if (data) {
          const users = data['result'] ? data['result'].map(x => createCrmUserFromBitrix(x)) : [];
          this.store.upsertMany(users);
          if (data['next']) {
            this.pageToken.next(data['next']);
          }
        }
      });
  }

  private LoadMegaplanUsers(pageToken: any) {
    this.megaplanService.GetEmployeesAsync({
      limit: null,
      pageAfter: null
    })
      .subscribe((data: any) => {
        if (data) {
          const employees = data.data.map(employee => createCrmUserFromMegaplan(employee));
          this.store.set(employees);
        }
      });
  }

  private LoadUOnManagers(pageToken: any) {
    this.store.set([]);
    this.http.get(`${environment.backendUri}/${environment.apiPrefix}/u-on-travel/managers`)
      .subscribe((data: UonManager[]) => {
        if (data) {
          const employees = data.map(e => createCrmUserFromUOn(e));
          this.store.set(employees);
        }
      });
  }

  private LoadMoiSkladEmployees(pageToken: any) {
    this.http.get(`${this.moiSkladBaseUrl}/employees`)
      .subscribe((response: MoiSkladResponseWrapper<MoiSkladEmployee>) => {
        console.log(response);
        response.rows
          .map(employee => createCrmUserFromMoiSklad(employee))
          .forEach(employee => this.store.add(employee));
      });
  }
}
