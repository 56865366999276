import {Component, OnInit} from '@angular/core';
import {AccountsService} from '../../../api/services/accounts.service';
import {Router} from '@angular/router';
import {AccountToken} from '../../../api/models/account-token';

@Component({
    selector: 'app-sign-in',
    templateUrl: './sign-in.component.html',
    styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {

    constructor(
        private accountService: AccountsService,
        private router: Router
    ) {
    }

    ngOnInit() {
    }

    handleSubmit(data) {
        this.accountService.signIn(data)
            .subscribe((data1: AccountToken) => {
                localStorage.setItem('token', data1.token);
                localStorage.setItem('token_expired_at', data1.expiredAt);
                this.router.navigate(['/vk/group']);
            });
    }

}
