import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AdNetworkExportComponent} from './components/ad-network-export/ad-network-export.component';
import {AdNetworkImportComponent} from './components/ad-network-import/ad-network-import.component';
import {VkControlsModule} from '../../../vk-controls/vk-controls.module';
import {ReactiveFormsModule} from '@angular/forms';
import {MatCheckboxModule, MatProgressSpinnerModule, MatSelectModule} from '@angular/material';
import {CheckboxesGroupModule} from '../../../checkboxes-group/checkboxes-group.module';
import {SharedModule} from '../../../shared/shared.module';
import {VkModule} from '../vk/vk.module';
import {MyTargetModule} from '../my-target/my-target.module';
import {IntegrationFormModule} from "../../../integrations/modules/integration-form/integration-form.module";
import {NewAdNetworkImportComponent} from './components/new-ad-network-import/new-ad-network-import.component';
import {SectionAuditorySettingsComponent} from './components/section-auditory-settings/section-auditory-settings.component';
import {PipelineStatusSettingsComponent} from './components/pipeline-status-settings/pipeline-status-settings.component';
import {SharedCrmModule} from "../shared-crm/shared-crm.module";
import {YandexMetrikaModule} from '../yandex-metrika/yandex-metrika.module';
import {NewAdNetworkExportComponent} from './components/new-ad-network-export/new-ad-network-export.component';

@NgModule({
  declarations: [AdNetworkExportComponent, AdNetworkImportComponent, NewAdNetworkImportComponent, SectionAuditorySettingsComponent, PipelineStatusSettingsComponent, NewAdNetworkExportComponent],
  exports: [
    AdNetworkExportComponent,
    AdNetworkImportComponent
  ],
  imports: [
    CommonModule,
    VkControlsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    CheckboxesGroupModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    SharedModule,
    VkModule,
    MyTargetModule,
    IntegrationFormModule,
    SharedCrmModule,
    YandexMetrikaModule
  ]
})
export class SharedAdNetworkModule {
}
