<form class="import-crm-section" [formGroup]="form" *ngIf="form">
  <div class="import-crm-section__heading">
    <div class="import-crm-section__title">
      <mat-checkbox class="no-margin-checkbox" formControlName="status">{{section.name}}</mat-checkbox>asdasd
    </div>
    <a href="javascript:void(0)" class="import-crm-section__trigger" (click)="ToggleSettings()">
      {{settingsOpened ? 'Скрыть' : 'Показать'}} настройки
    </a>
  </div>
  <div class="import-crm-section__settings" *ngIf="settingsOpened">
    <table [formGroup]="form.get('fields')">
      <ng-container *ngFor="let field of section.fields">
        <tr class="form-field">
          <td class="form-field__label">asdasd {{field.name}} {{form.value}}</td>
          <td class="form-field__value">
            <vk-form-field controlsWidth="250px">
              <mat-select vkInput [formControlName]="field.code" [disabled]="!form.get('status').value">
                <ng-container *ngFor="let formField of formFields">
                  <mat-option [value]="formField.key">{{formField.name}}</mat-option>
                </ng-container>
              </mat-select>
            </vk-form-field>
          </td>
        </tr>
      </ng-container>
    </table>
  </div>
</form>
