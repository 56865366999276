<h2>Платежи</h2>

<clr-datagrid>
  <clr-dg-column>Платежная система</clr-dg-column>
  <clr-dg-column>Тариф</clr-dg-column>
  <clr-dg-column>Время</clr-dg-column>
  <clr-dg-column>Дата платежа</clr-dg-column>
  <clr-dg-column>Цена</clr-dg-column>
  <clr-dg-column>Подтвержден</clr-dg-column>

  <clr-dg-row *ngFor="let Payment of Payments">
    <clr-dg-cell>{{Payment.paymentSystem.title}}</clr-dg-cell>
    <clr-dg-cell>{{Payment.tariff.title}}</clr-dg-cell>
    <clr-dg-cell>{{Payment.time.time?.title}}</clr-dg-cell>
    <clr-dg-cell>{{Payment.createdAt | date}}</clr-dg-cell>
    <clr-dg-cell>{{Payment.price | currency:" руб."}}</clr-dg-cell>
    <clr-dg-cell>
      <!--{{Payment.confirmedAt}}-->
      <ng-template *ngIf="Payment.confirmedAt; else notConfirmed;">
        <clr-icon shape="check" [style.fill]="'#0f0'"></clr-icon>
        {{Payment.confirmedAt | date}}
      </ng-template>
      <ng-template #notConfirmed>
        <clr-icon shape="times" [style.fill]="'#f00'"></clr-icon>
      </ng-template>
    </clr-dg-cell>
  </clr-dg-row>
</clr-datagrid>