import { environment } from "../../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var TariffGroupsService = /** @class */ (function () {
    function TariffGroupsService(http) {
        this.http = http;
        this.baseUrl = environment.backendUri + "/" + environment.apiPrefix + "/tariff-packages/stuff";
    }
    /***
     * Get tariff groups from server
     * @constructor
     */
    TariffGroupsService.prototype.LoadTariffGroups = function () {
        return this.http.get(this.baseUrl);
    };
    TariffGroupsService.ngInjectableDef = i0.defineInjectable({ factory: function TariffGroupsService_Factory() { return new TariffGroupsService(i0.inject(i1.HttpClient)); }, token: TariffGroupsService, providedIn: "root" });
    return TariffGroupsService;
}());
export { TariffGroupsService };
