import {Component, Input, OnInit} from '@angular/core';
import {UserStrategyInput} from '../../../api/models/user-strategy-input';

@Component({
  selector: 'app-strategy-params',
  templateUrl: './strategy-params.component.html',
  styleUrls: ['./strategy-params.component.scss']
})
export class StrategyParamsComponent implements OnInit {

  @Input() displayFunctions: object = {};
  @Input() public userStrategyInputs: Array<UserStrategyInput>;
  
  constructor() {
  }

  ngOnInit() {
  }


  public GetFormatedUserStrategyInputTitle(title: string) {
    return title.split(',')[0];
  }

  public GetInputValue(input: UserStrategyInput) {
    console.log(input);
    let value = input.value;
    if (this.displayFunctions[input.type.code]) {
      console.log(this.displayFunctions[input.type.code]);
      value = this.displayFunctions[input.type.code](value);
    }
    return value;
  }

  GetSelectedOptionValue(input: UserStrategyInput) {
    return input.type.options.find(x => parseInt(x.value, 10) === input.value).title;
  }
}
