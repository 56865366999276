<div class="ad-network-groups">
  <!--  <div class="ad-network-groups__search-form">-->
  <!--    <form [formGroup]="searchForm">-->
  <!--      <vk-form-field orientation="vertical" controlsWidth="100%">-->
  <!--        <vk-label>Введите название группы</vk-label>-->
  <!--        <input type="text" formControlName="searchName" placeholder="Введите название группы" vkInput>-->
  <!--      </vk-form-field>-->
  <!--    </form>-->
  <!--  </div>-->
  <ng-container *ngIf="!(isEmpty$ | async); else emptyScreen">
<!--    <ng-container *ngIf="!loading; else loadingScreen">-->
<!--      -->
<!--    </ng-container>-->
<!--    <ng-template #loadingScreen>-->
<!--      <app-loading-screen>-->
<!--        Подождите, идет поиск групп-->
<!--      </app-loading-screen>-->
<!--    </ng-template>-->
    <ng-container *ngIf="!(isLoading$ | async); else loader">
      <div class="ad-network-groups__list">
        <ng-container *ngFor="let group of groups$ | async">
          <app-ad-network-group
            [group]="group"
            [control]="form"
          ></app-ad-network-group>
        </ng-container>
      </div>
    </ng-container>
    <ng-template #loader>
      <app-loading-screen>
        Подождите, идет поиск групп
      </app-loading-screen>
    </ng-template>
  </ng-container>
  <ng-template #emptyScreen>
    <div class="empty-screen">
      Группы не найдены
    </div>
  </ng-template>
</div>
