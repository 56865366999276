/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./promopost.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../promopost-meta-data/promopost-meta-data.component.ngfactory";
import * as i3 from "../promopost-meta-data/promopost-meta-data.component";
import * as i4 from "../promopost-link-widget/promopost-link-widget.component.ngfactory";
import * as i5 from "../promopost-link-widget/promopost-link-widget.component";
import * as i6 from "@angular/platform-browser";
import * as i7 from "./promopost.component";
var styles_PromopostComponent = [i0.styles];
var RenderType_PromopostComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PromopostComponent, data: {} });
export { RenderType_PromopostComponent as RenderType_PromopostComponent };
export function View_PromopostComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "promopost"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "promopost__header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-promopost-meta-data", [], null, null, null, i2.View_PromopostMetaDataComponent_0, i2.RenderType_PromopostMetaDataComponent)), i1.ɵdid(3, 114688, null, 0, i3.PromopostMetaDataComponent, [], { promopostMeta: [0, "promopostMeta"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "promopost__content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "promopost__text"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-promopost-link-widget", [], null, null, null, i4.View_PromopostLinkWidgetComponent_0, i4.RenderType_PromopostLinkWidgetComponent)), i1.ɵdid(7, 114688, null, 0, i5.PromopostLinkWidgetComponent, [i6.DomSanitizer], { linkWidgetData: [0, "linkWidgetData"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "promopost__footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "div", [["class", "promopost__icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "div", [["class", "promopost__icon"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.meta; _ck(_v, 3, 0, currVal_0); var currVal_2 = _co.linkWidget; _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.text; _ck(_v, 5, 0, currVal_1); }); }
export function View_PromopostComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-promopost", [], null, null, null, View_PromopostComponent_0, RenderType_PromopostComponent)), i1.ɵdid(1, 114688, null, 0, i7.PromopostComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PromopostComponentNgFactory = i1.ɵccf("app-promopost", i7.PromopostComponent, View_PromopostComponent_Host_0, { promopost: "promopost", selectedGroup: "selectedGroup", showEditForm: "showEditForm" }, {}, []);
export { PromopostComponentNgFactory as PromopostComponentNgFactory };
