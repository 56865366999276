<ng-container *ngIf="!IsLoading; else loadingScreen">
  <app-ad-form-pro (onSave)="CreateAds($event)"></app-ad-form-pro>
</ng-container>
<ng-template #loadingScreen>
<!--  <div class="loading-screen">-->
<!--    <div class="loading-screen__spinner">-->
<!--      &lt;!&ndash;      <mat-progspinner diameter="18"></mat-progspinner>&ndash;&gt;-->
<!--      <mat-progress-spinner-->
<!--        [diameter]="30"-->
<!--        [value]="LoadingProgress"-->
<!--        mode="determinate"-->
<!--      ></mat-progress-spinner>-->
<!--    </div>-->
<!--    <div class="loading-screen__message">-->
<!--      <div class="loading-screen__title">Подождите, идет загрузка данных</div>-->
<!--      <div class="loading-screen__step">{{LoadingStep}}</div>-->
<!--    </div>-->
<!--  </div>-->

  <div class="loading-screen">
    <div class="loading-screen__spinner">
      <mat-spinner diameter="18"></mat-spinner>
    </div>
    <div class="loading-screen__message">Подождите, идет загрузка данных</div>
  </div>
</ng-template>
