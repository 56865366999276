import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {
  IPromopostLinkWidgetViewModel
} from '../../models/promopost-link-widget.view-model';

@Component({
  selector: 'app-promopost-link-widget',
  templateUrl: './promopost-link-widget.component.html',
  styleUrls: ['./promopost-link-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PromopostLinkWidgetComponent implements OnInit {

  public enableEditingModeForm = 1;

  @Input() linkWidgetData: IPromopostLinkWidgetViewModel;
  @Output() public linkTitleSave: EventEmitter<string> = new EventEmitter();

  public get Image() {
    return this._sanitizer.bypassSecurityTrustStyle(`url(${this.linkWidgetData.linkImage})`);
  }

  constructor(
    private _sanitizer: DomSanitizer
  ) {
  }

  ngOnInit() {
  }

  public startEditingTitle() {
    console.log(this.enableEditingModeForm, 'click before');
    this.enableEditingModeForm = this.enableEditingModeForm + 1;
    console.log(this.enableEditingModeForm, 'click');
  }
}
