import {Component, OnInit} from '@angular/core';
import {CopyingService} from "../../services/copying.service";
import {FormControl, FormGroup} from "@angular/forms";
import {AccountViewModel} from "../../../models/view-models/account-view-model";

@Component({
  selector: 'app-target-account-and-campaign',
  templateUrl: './target-account-and-campaign.component.html',
  styleUrls: ['./target-account-and-campaign.component.scss']
})
export class TargetAccountAndCampaignComponent implements OnInit {

  public form: FormGroup;
  public targetAccountId: FormControl;

  public get openedAdvancedMode() {
    return this.copyingService.openedAdvancedMode;
  }

  public get isLoading() {
    return this.copyingService.loadingAdvancedScreen;
  }

  public get accounts() {
    return this.copyingService.accounts;
  }

  public get campaigns() {
    return this.copyingService.campaigns;
  }

  constructor(
    private copyingService: CopyingService
  ) {
  }

  public getAccountId(account: AccountViewModel) {
    return `${account.account_id}_${account.client_id}`;
  }

  ngOnInit() {
    this.form = this.copyingService.form;
    this.targetAccountId = new FormControl(this.form.value.accountId);
    this.targetAccountId.valueChanges.subscribe(x => {
      const [targetAccountId, targetClientId] = x.split('_').map(x => x === 'null' ? null : parseInt(x, 10));
      this.copyingService.LoadCampaigns(targetAccountId, targetClientId)
        .subscribe();
    })
  }

  OpenCreateNewCampaign() {
    this.copyingService.OpenCreateCampaignForm();
  }
}
