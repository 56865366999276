import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {PostMonitorBackend_V2ModelsAdManagerMultipleEditAdsViewModel} from '../../../../../api/models/post-monitor-backend-_v2models-ad-manager-multiple-edit-ads-view-model';
import {CurrencyPipe} from '@angular/common';

@Component({
  selector: 'app-multiple-edit-success',
  templateUrl: './multiple-edit-success.component.html',
  styleUrls: ['./multiple-edit-success.component.scss']
})
export class MultipleEditSuccessComponent implements OnInit {

  public filteredKeys = [
    'accountId',
    'campaignId',
    'adIds'
  ];

  public keyTitles = {
    'all_limit': 'Общий лимит ',
    'day_limit': 'Дневной лимит',
    'cpm': 'CPM',
    'cpc': 'CPC',
    'status': 'Статус'
  };

  public status = {
    0: 'Остановлены',
    1: 'Запущены'
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<MultipleEditSuccessComponent>
  ) {
  }

  public get Data() {
    console.log(this.data.formValue);
    const properties = Object.keys(this.data.formValue)
      .filter((key) => key !== '' && key !== undefined && key !== null)
      .filter(key => !this.filteredKeys.includes(key))
      .filter(key => this.data.formValue[key] !== null)
      .map(key => ({key: this.keyTitles[key], value: this.transform(key, this.data.formValue[key])}))
      .filter(({key}) => {
        console.log(key !== undefined, key !== null);
        return key !== undefined && key !== null;
      });

    console.log(properties);

    return properties;
  }

  public get Strategy() {
    return this.data.strategyInputs;
  }

  ngOnInit() {
  }

  public transform(key, value) {
    console.log(key, value);
    switch (key) {
      case 'status':
        return this.status[value];
      case 'all_limit':
      case 'day_limit':
      case 'cpm':
      case 'cpc':
        return new CurrencyPipe('ru').transform(value, ' Руб.');
    }
  }

  public modifyFunctions = {
    BID_MIN: (value) => parseFloat(value) * 100,
    BID_START: (value) => parseFloat(value) * 100,
    BID_END: (value) => parseFloat(value) * 100,
    BID_MAX: (value) => parseFloat(value) * 100,
    BID_STEP: (value) => parseFloat(value) * 100,
    ECPC_LIMIT: (value) => parseFloat(value) * 100,
    CUSTOM_OVERALL_LIMIT: (value) => parseFloat(value) * 100,
  };

  public displayFunctions = {
    BID_MIN: (value) => parseFloat(value) / 100,
    BID_MAX: (value) => parseFloat(value) / 100,
    BID_START: (value) => parseFloat(value) / 100,
    BID_END: (value) => parseFloat(value) / 100,
    BID_STEP: (value) => parseFloat(value) / 100,
    ECPC_LIMIT: (value) => parseFloat(value) / 100,
    CUSTOM_OVERALL_LIMIT: (value) => parseFloat(value) / 100,
  };

  public Close() {
    this.dialogRef.close();
  }
}
