<!--<div class="card">-->
<!--<div class="card-block">-->
<!--<div class="automation-block">-->
<!--<h3 class="automation-block__title">Кабинет компании / Ретаргетинг</h3>-->
<!--<button class="btn btn-success">Обновить</button>-->
<!--</div>-->
<!--</div>-->
<!--<div class="card-block">-->
<!--<div class="option">-->
<!--<div class="option__name">Дневной лимит:</div>-->
<!--<div class="option__value">Не задан</div>-->
<!--</div>-->
<!--<div class="option">-->
<!--<div class="option__name">Общий лимит:</div>-->
<!--<div class="option__value">Не задан</div>-->
<!--</div>-->
<!--<div class="option">-->
<!--<div class="option__name">Статус:</div>-->
<!--<div class="option__value">Остановлено</div>-->
<!--</div>-->
<!--<div class="option">-->
<!--<div class="option__name">Дата запуска:</div>-->
<!--<div class="option__value">Не задана</div>-->
<!--</div>-->
<!--<div class="option">-->
<!--<div class="option__name">Дата остановки:</div>-->
<!--<div class="option__value">Не задана</div>-->
<!--</div>-->
<!--</div>-->
<!--</div>-->

<!--<div class="card">-->
<!--<div class="card-block">-->
<!--<a href="" class="btn btn-icon-link">-->
<!--<clr-icon shape="arrow" style="transform: rotate(270deg);" size="28"></clr-icon>-->
<!--</a>-->
<!--<div class="title">-->
<!--<a class="button">{{currentCampaigns?.name}}</a>-->
<!--</div>-->
<!--</div>-->
<!--</div>-->
<app-analytic-services-header [provider]="'yandex-metrika'" [yandexMetrikaConnected]="IsAnalyticServiceAvaialbe(yandex)"
    [availabeCounter]="yandex.Counters" [availabeGoals]="yandex.Goals" [yandexSelectedCounterId]="counterId"
    (SelectCounter)="SelectCounter($event)" (SelectGoal)="SelectGoal($event)">

</app-analytic-services-header>

<clr-datagrid [(clrDgSelected)]="SelectedGroups" (clrDgSelectedChanged)="SelectedRow($event)">
    <clr-dg-action-bar>
        <div class="fx-row fx-row_block">
            <app-date-filter [date]="period" (changingDate)="ChangeDate($event)" [disabled]="disabled"></app-date-filter>
            <div class="fx-divider"></div>
            <button class="btn btn-sm btn-primary btn-update btn-success" (click)="InitPage()" [disabled]="disabled">
                Обновить
            </button>
            <button class="btn btn-sm btn-export" (click)="ExportToExcel()" [disabled]="disabled">Экспортировать в
                excel
            </button>
        </div>
    </clr-dg-action-bar>
    <clr-dg-action-bar>
        <div class="fx-row fx-row_block">
            <div class="action-bar__action">
                <!--<clr-checkbox [ngModel]="showUtms" (ngModelChange)="OnChangeShowUtms($event)">Показывать UTM-метки-->
                <!--</clr-checkbox>-->
            </div>
            <div class="action-bar__action">
                <!--<clr-checkbox [ngModel]="showMin" (ngModelChange)="OnChangeShowMin($event)">Показывать миниатюры-->
                <!--</clr-checkbox>-->
            </div>
            <div class="action-bar__action">
                <!--<clr-checkbox [ngModel]="showLink" (ngModelChange)="OnChangeShowLink($event)">Показывать ссылки-->
                <!--</clr-checkbox>-->
            </div>
        </div>
    </clr-dg-action-bar>
    <clr-dg-action-bar *ngIf="ShowAutomationInputs()">
        <div class="fx-row fx-row_block">
            <div>
                Выбрано: {{SelectedGroups.length}}
            </div>
            <div class="fx-divider"></div>
            <div class="chip-list-actions">
                <button class="btn btn-primary btn-sm" [disabled]="!IsBlockedStrategy || HasAllSelectedAdsStrategies === true"
                    (click)="OpenBidManager(false)">Добавить стратегии</button>
                <button class="btn btn-primary btn-sm" [disabled]="!IsBlockedStrategy || HasAllSelectedAdsStrategies === false"
                    (click)="OpenBidManager(true)">Изменить стратегии</button>
                <button class="btn btn-danger btn-sm" [disabled]="!IsBlockedStrategy" (click)="RemoveStrategies()">Удалить
                    стратегии</button>
            </div>
        </div>
    </clr-dg-action-bar>
    <clr-dg-column clrDgField="name" style="min-width: 250px">
        <ng-container *clrDgHideableColumn="hiddenColumns.name">
            Название
        </ng-container>
    </clr-dg-column>
    <clr-dg-column clrDgField="status">
        <ng-container *clrDgHideableColumn="hiddenColumns.status">
            Статус
        </ng-container>
    </clr-dg-column>
    <clr-dg-column clrDgField="strategy.strategy.title" style="min-width: 268px;" *ngIf="ShowStrategyColumns && ShowAutomationInputs()">
        Стратегия
    </clr-dg-column>
    <clr-dg-column clrDgField="cpm">
        <ng-container *clrDgHideableColumn="hiddenColumns.cpm">
            CPM
        </ng-container>
    </clr-dg-column>
    <clr-dg-column clrDgField="day_limit">
        <ng-container *clrDgHideableColumn="hiddenColumns.day_limit">
            Дневной лимит
        </ng-container>
    </clr-dg-column>
    <clr-dg-column clrDgField="all_limit">
        <ng-container *clrDgHideableColumn="hiddenColumns.all_limit">
            Общий лимит
        </ng-container>
    </clr-dg-column>
    <clr-dg-column clrDgField="spent">
        <ng-container *clrDgHideableColumn="hiddenColumns.spent">
            Потрачено
        </ng-container>
    </clr-dg-column>
    <clr-dg-column clrDgField="impressions">
        <ng-container *clrDgHideableColumn="hiddenColumns.impressions">
            Показы
        </ng-container>
    </clr-dg-column>
    <clr-dg-column clrDgField="clicks">
        <ng-container *clrDgHideableColumn="hiddenColumns.clicks">
            Переходы
        </ng-container>
    </clr-dg-column>
    <clr-dg-column clrDgField="ctr">
        <ng-container *clrDgHideableColumn="hiddenColumns.ctr">
            CTR
        </ng-container>
    </clr-dg-column>
    <clr-dg-column clrDgField="ecpc">
        <ng-container *clrDgHideableColumn="hiddenColumns.ecpc">
            eCPC
        </ng-container>
    </clr-dg-column>
    <clr-dg-column clrDgField="reach">
        <ng-container *clrDgHideableColumn="hiddenColumns.reach">
            Охват
        </ng-container>
    </clr-dg-column>

    <clr-dg-column clrDgField="links" *ngIf="!onlyTizers && IsPeriodOverall">
        <ng-container *clrDgHideableColumn="hiddenColumns.links">
            Переходы по ссылке
        </ng-container>
    </clr-dg-column>
    <clr-dg-column clrDgField="price_click" *ngIf="!onlyTizers && IsPeriodOverall">
        <ng-container *clrDgHideableColumn="hiddenColumns.price_click">
            Цена перехода по ссылке
        </ng-container>
    </clr-dg-column>

    <clr-dg-column clrDgField="to_group" *ngIf="!onlyTizers && IsPeriodOverall">
        <ng-container *clrDgHideableColumn="hiddenColumns.to_group">
            Переходы в группу
        </ng-container>
    </clr-dg-column>
    <clr-dg-column clrDgField="price_to_group" *ngIf="!onlyTizers && IsPeriodOverall">
        <ng-container *clrDgHideableColumn="hiddenColumns.price_to_group">
            Цена перехода в группу
        </ng-container>
    </clr-dg-column>

    <clr-dg-column clrDgField="join_group" *ngIf="(showGroupJoinData || !onlyTizers) && IsPeriodOverall">
        <ng-container *clrDgHideableColumn="hiddenColumns.join_group">
            Вступлений в группу
        </ng-container>
    </clr-dg-column>
    <clr-dg-column clrDgField="pricing_subscribe" *ngIf="(showGroupJoinData || !onlyTizers) && IsPeriodOverall">
        <ng-container *clrDgHideableColumn="hiddenColumns.pricing_subscribe">
            Цена подписчика
        </ng-container>
    </clr-dg-column>

    <clr-dg-column clrDgField="likes" *ngIf="!onlyTizers">
        <ng-container *clrDgHideableColumn="hiddenColumns.likes">
            Лайки
        </ng-container>
    </clr-dg-column>
    <clr-dg-column clrDgField="reposts" *ngIf="!onlyTizers">
        <ng-container *clrDgHideableColumn="hiddenColumns.reposts">
            Репосты
        </ng-container>
    </clr-dg-column>
    <clr-dg-column clrDgField="comments" *ngIf="!onlyTizers">
        <ng-container *clrDgHideableColumn="hiddenColumns.comments">
            Комментарии
        </ng-container>
    </clr-dg-column>

    <clr-dg-column clrDgField="hide" *ngIf="!onlyTizers && IsPeriodOverall">
        <ng-container *clrDgHideableColumn="hiddenColumns.hide">
            Скрытий
        </ng-container>
    </clr-dg-column>
    <clr-dg-column clrDgField="report" *ngIf="!onlyTizers && IsPeriodOverall">
        <ng-container *clrDgHideableColumn="hiddenColumns.report">
            Жалоб
        </ng-container>
    </clr-dg-column>
    <clr-dg-column clrDgField="unsubscribe" *ngIf="!onlyTizers && IsPeriodOverall">
        <ng-container *clrDgHideableColumn="hiddenColumns.unsubscribe">
            Скрытых всех записей
        </ng-container>
    </clr-dg-column>
    <clr-dg-column clrDgField="reach_subscribers" *ngIf="!onlyTizers && IsPeriodOverall">
        <ng-container *clrDgHideableColumn="hiddenColumns.reach_subscribers">
            Охват подписчиков
        </ng-container>
    </clr-dg-column>
    <clr-dg-column clrDgField="reach_not_subscribers" *ngIf="!onlyTizers && IsPeriodOverall ">
        <ng-container *clrDgHideableColumn="hiddenColumns.reach_total">
            Охват не подписчиков
        </ng-container>
    </clr-dg-column>

    <clr-dg-column clrDgField="yandex_reched_goals" *ngIf="goalId">
        <ng-container *clrDgHideableColumn="hiddenColumns.yandex_reched_goals">
            Yandex.Целей достигнуто
        </ng-container>
    </clr-dg-column>
    <clr-dg-column clrDgField="yandex_cpa" *ngIf="goalId">
        <ng-container *clrDgHideableColumn="hiddenColumns.yandex_cpa">
            Yandex.Стоимость цели
        </ng-container>
    </clr-dg-column>

    <clr-dg-column clrDgField="yandex_cr_from_click" *ngIf="goalId">
        <ng-container *clrDgHideableColumn="hiddenColumns.yandex_cr_from_click">
            Yandex.CR переход в цель
        </ng-container>
    </clr-dg-column>
    <clr-dg-column clrDgField="yandex_cr_from_view" *ngIf="goalId">
        <ng-container *clrDgHideableColumn="hiddenColumns.yandex_cr_from_view">
            Yandex.CR просмотр в цель
        </ng-container>
    </clr-dg-column>

    <clr-dg-column clrDgField="yandex_page_depth" *ngIf="goalId">
        <ng-container *clrDgHideableColumn="hiddenColumns.yandex_page_depth">
            Yandex.Глубина просмотра
        </ng-container>
    </clr-dg-column>
    <clr-dg-column clrDgField="yandex_average_duration" *ngIf="goalId">
        <ng-container *clrDgHideableColumn="hiddenColumns.yandex_average_duration">
            Yandex.Время на сайте
        </ng-container>
    </clr-dg-column>
    <!--31-->

    <clr-dg-placeholder>
        <span class="spinner"></span>
        <!--<span>{{loadingStatus}}</span>-->
    </clr-dg-placeholder>

    <clr-dg-row *clrDgItems="let ad of adsViewModels" [clrDgItem]="ad">
        <clr-dg-cell style="min-width: 250px">
            <div class="ad-main-data-container">
                <div class="ad-main-data-container__item">
                    <div class="preview-container">
                        <ng-container *ngIf="ad.image_src && showMin">
                            <div class="preview-container__teaser-container">
                                <img [src]="ad.image_src" class="teaser-image">
                            </div>
                        </ng-container>

                        <ng-container *ngIf="ad.wall">
                            <div class="preview-container__post-container">
                                <div class="post-box" *ngIf="showMin">
                                    <div class="post-group">
                                        <div class="post-group__item">
                                            <div class="post-group-logo-placeholder"></div>
                                        </div>
                                        <div class="post-group__item">
                                            <div class="post-group-name">
                                                Название группы
                                            </div>
                                        </div>
                                    </div>
                                    <div class="post-text">
                                        <span class="post-text__message" [innerHtml]="ad.wall.text | nameShorter : 100 | postificate"></span>
                                    </div>

                                    <div class="post-attachemnts">
                                        <ng-container *ngFor="let attachment of ad.wall.attachments">
                                            <!--photo attachment-->
                                            <ng-container *ngIf="attachment.photo">
                                                <img [src]="attachment.photo.photo_130" class="post-photo">
                                            </ng-container>

                                            <!--Link attachemnt-->
                                            <ng-container *ngIf="attachment.link">
                                                <div [ngClass]="GetLinkClass('post-link-attachment', ad.wall, attachment.link)">
                                                    <div [ngClass]="GetLinkClass('post-link-attachment__image', ad.wall, attachment.link)"
                                                        [style.background-image]="'url(' + attachment.link.photo.photo_130 + ')'">
                                                    </div>

                                                    <ng-container *ngIf="attachment.link.title && attachment.link.title.trim().length > 1">
                                                        <div [ngClass]="GetLinkClass('post-link-attachment__body', ad.wall, attachment.link)">
                                                            <div [ngClass]="GetLinkClass('post-link-attachment-title', ad.wall, attachment.link)">
                                                                {{attachment.link.title | nameShorter : 16}}
                                                            </div>
                                                            <ng-container *ngIf="attachment.link.button">
                                                                <div [ngClass]="GetLinkClass('post-link-attachment-button', ad.wall, attachment.link)">
                                                                    {{attachment.link.button.title}}
                                                                </div>
                                                            </ng-container>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </ng-container>

                                            <!--Pretty cards attachment-->
                                            <ng-container *ngIf="attachment.pretty_cards">
                                                <div class="post-prettycards">
                                                    <ng-container *ngFor="let card of attachment.pretty_cards.cards">
                                                        <div class="post-prettycards__item">
                                                            <div class="post-prettycard">
                                                                <div class="prettycard-preview" *ngIf="showMin">
                                                                    <img [src]="card.images[0].url" class="prettycard-preview__image">
                                                                </div>

                                                                <ng-container *ngIf="card.title">
                                                                    <div class="prettycard-title">
                                                                        {{card.title}}
                                                                    </div>
                                                                </ng-container>

                                                                <ng-container *ngIf="card.price">
                                                                    <div class="prettycard-price">
                                                                        <span class="prettycard-price__current">
                                                                            {{card.price}}
                                                                        </span>
                                                                        <span class="prettycard-price__old">
                                                                            {{card.price_old}}
                                                                        </span>
                                                                    </div>
                                                                </ng-container>

                                                                <ng-container *ngIf="card.button">
                                                                    <div class="prettycard-button">
                                                                        {{card.button.title | nameShorter : 12}}
                                                                    </div>
                                                                </ng-container>

                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </ng-container>

                        <div class="ad-preview-url" *ngIf="showMin">
                            <a [href]="ad.preview_link" target="_blank" rel="nofollow noopener">просмотр</a>
                        </div>
                    </div>
                </div>

                <div class="ad-main-data-container__item">
                    <div>
                        <strong>
                            <a [href]="GetAdUrlInVkCabinet(ad)" title="Открыть объявление в кабинете ВКонтакте" target="_blank"
                                rel="nofollow noopener">
                                {{ad.name}}
                            </a>
                        </strong>
                    </div>

                    <div class="ad-meta-data">
                        <ng-container *ngIf="ad.ad_url && showLink">
                            <div class="ad-meta-data__domain">
                                <a [href]="ad.ad_url[0]" [title]="ad.ad_url[0]" target="_blank" rel="nofollow noopener">
                                    {{ad.ad_url[0] | domainOnly}}
                                </a>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="ad?.utms?.length > 0 && showUtms">
                            <div class="ad-meta-data__utms">
                                <div class="utm-list">
                                    <ng-container *ngFor="let utmMark of ad.utms">
                                        <div class="utm-list__item">
                                            <span [title]="utmMark.type + '=' + utmMark.value" class="utm-mark">{{utmMark.value}}</span>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </clr-dg-cell>
        <clr-dg-cell>
            <ng-container *ngIf="ad.status === 0">
                <clr-icon shape="stop" class="is-solid" style="color: #F52F22;"></clr-icon>
            </ng-container>
            <ng-container *ngIf="ad.status === 1">
                <clr-icon shape="play" class="is-solid" style="color: #85C81A;"></clr-icon>
            </ng-container>
            <ng-container *ngIf="ad.status === 2">
                <clr-icon shape="pause" class="is-solid" style="color: #00f"></clr-icon>
            </ng-container>
        </clr-dg-cell>
        <clr-dg-cell style="min-width: 268px;" *ngIf="ShowStrategyColumns && ShowAutomationInputs()">
            <b>{{ad?.strategy?.strategy?.title}}</b>
            <br>
            <ng-container *ngFor="let input of ad.strategy?.inputs">
                {{input.type?.title}}:
                <ng-container [ngSwitch]="input.type.frontendInputType">
                    <ng-container *ngSwitchCase="'number'">
                        <strong>
                            <ng-container *ngIf="displayFunctions[input.type?.code]; else defaultValue">
                                {{displayFunctions[input.type?.code](input.value)}}
                            </ng-container>
                            <ng-template #defaultValue>{{input.value}}</ng-template>
                            {{input.type?.placeholder}}
                        </strong><br>
                    </ng-container>

                    <ng-container *ngSwitchCase="'time_of_day_in_minutes'">
                        <strong>{{input.value | minutesToTime}} {{input.type?.placeholder}}</strong><br>
                    </ng-container>

                    <ng-container *ngSwitchCase="'datetimepicker'">
                        <strong>{{input.value | secondsToDate | utcToLocalDate:"DD MMM YYYY [в] HH"}} {{input.type?.placeholder}}</strong><br>
                    </ng-container>

                    <ng-container *ngSwitchCase="'select'">
                        {{GetSelectedOptionValue(input)}}
                    </ng-container>

                    <ng-container *ngSwitchDefault>
                        <strong>
                            <ng-container *ngIf="displayFunctions[input.type?.code]; else defaultValue">
                                {{displayFunctions[input.type?.code](input.value)}}
                            </ng-container>
                            <ng-template #defaultValue>{{input.value}}</ng-template>
                            {{input.type?.placeholder}}
                        </strong><br>
                    </ng-container>
                </ng-container>
            </ng-container>
        </clr-dg-cell>
        <clr-dg-cell>
            <span class="money-color">{{ad.cpm | currency:" Руб."}}</span>
        </clr-dg-cell>
        <clr-dg-cell>
            <span class="money-color">{{ad.day_limit | currency:" Руб."}}</span>
        </clr-dg-cell>
        <clr-dg-cell>
            <span class="money-color">{{ad.all_limit | currency:" Руб."}}</span>
        </clr-dg-cell>
        <clr-dg-cell>
            <span class="money-color">{{ad.spent | currency:" Руб."}}</span>
        </clr-dg-cell>
        <clr-dg-cell>{{ad.impressions| number:"1.0-2"}}</clr-dg-cell>
        <clr-dg-cell>{{ad.clicks | number:"1.0-2"}}</clr-dg-cell>
        <clr-dg-cell>{{ad.ctr | number:"1.0-2"}} %</clr-dg-cell>
        <clr-dg-cell>
            <span class="money-color">{{ad.ecpc | currency:" Руб."}}</span>
        </clr-dg-cell>
        <clr-dg-cell>{{ad.reach | number:"1.0-2"}}</clr-dg-cell>

        <clr-dg-cell *ngIf="!onlyTizers && IsPeriodOverall">{{ad.links | number:"1.0-2"}}</clr-dg-cell>
        <clr-dg-cell *ngIf="!onlyTizers && IsPeriodOverall">
            <span class="money-color">{{ad.price_click | currency:" Руб."}}</span>
        </clr-dg-cell>

        <clr-dg-cell *ngIf="!onlyTizers && IsPeriodOverall">{{ad.to_group | number:"1.0-2"}}</clr-dg-cell>
        <clr-dg-cell *ngIf="!onlyTizers && IsPeriodOverall">
            <span class="money-color">{{ad.price_to_group | currency:" Руб."}}</span>
        </clr-dg-cell>

        <clr-dg-cell *ngIf="(showGroupJoinData || !onlyTizers) && IsPeriodOverall">
            {{ad.join_group || 0 | number:"1.0-2"}}
        </clr-dg-cell>
        <clr-dg-cell *ngIf="(showGroupJoinData || !onlyTizers) && IsPeriodOverall">
            <span class="money-color">{{ad.pricing_subscribe | currency:" Руб."}}</span>
        </clr-dg-cell>

        <clr-dg-cell *ngIf="!onlyTizers">{{ad.likes | number:"1.0-2"}}</clr-dg-cell>
        <clr-dg-cell *ngIf="!onlyTizers">{{ad.reposts | number:"1.0-2"}}</clr-dg-cell>
        <clr-dg-cell *ngIf="!onlyTizers">{{ad.comments | number:"1.0-2"}}</clr-dg-cell>

        <clr-dg-cell *ngIf="!onlyTizers && IsPeriodOverall">{{ad.hide | number:"1.0-2"}}</clr-dg-cell>
        <clr-dg-cell *ngIf="!onlyTizers && IsPeriodOverall">{{ad.report | number:"1.0-2"}}</clr-dg-cell>
        <clr-dg-cell *ngIf="!onlyTizers && IsPeriodOverall">{{ad.unsubscribe | number:"1.0-2"}}</clr-dg-cell>
        <clr-dg-cell *ngIf="!onlyTizers && IsPeriodOverall">{{ad.reach_subscribers | number:"1.0-2"}}</clr-dg-cell>
        <clr-dg-cell *ngIf="!onlyTizers && IsPeriodOverall">{{ad.reach_not_subscribers | number:"1.0-2"}}</clr-dg-cell>

        <clr-dg-cell *ngIf="goalId">
            <ng-container *ngIf="!IsYandexMetrikaLoading; else yandexLoading">
                {{ad.yandex_reched_goals | number:"1.0-2"}}
            </ng-container>
        </clr-dg-cell>
        <clr-dg-cell *ngIf="goalId">
            <ng-container *ngIf="!IsYandexMetrikaLoading; else yandexLoading">
                <span class="money-color">{{ad.yandex_cpa | currency:" руб."}}</span>
            </ng-container>

        </clr-dg-cell>
        <clr-dg-cell *ngIf="goalId">
            <ng-container *ngIf="!IsYandexMetrikaLoading; else yandexLoading">
                {{ad.yandex_cr_from_click | number:"1.0-2"}}%
            </ng-container>

        </clr-dg-cell>
        <clr-dg-cell *ngIf="goalId">
            <ng-container *ngIf="!IsYandexMetrikaLoading; else yandexLoading">
                {{ad.yandex_cr_from_view | number:"1.0-4"}}%
            </ng-container>
        </clr-dg-cell>

        <clr-dg-cell *ngIf="goalId">
            <ng-container *ngIf="!IsYandexMetrikaLoading; else yandexLoading">
                {{ad.yandex_page_depth | number:"1.0-2"}}
            </ng-container>
        </clr-dg-cell>
        <clr-dg-cell *ngIf="goalId">
            <ng-container *ngIf="!IsYandexMetrikaLoading; else yandexLoading">
                {{ad.yandex_average_duration | secondsToTime}}
            </ng-container>
        </clr-dg-cell>
    </clr-dg-row>

    <clr-dg-footer>
        <clr-dg-column-toggle>
            <clr-dg-column-toggle-title>Отобразить/Cкрыть колонки</clr-dg-column-toggle-title>
            <clr-dg-column-toggle-button clrType="selectAll">Выбрать все</clr-dg-column-toggle-button>
            <clr-dg-column-toggle-button clrType="ok">
                ОК
            </clr-dg-column-toggle-button>
        </clr-dg-column-toggle>
    </clr-dg-footer>
</clr-datagrid>

<clr-modal [(clrModalOpen)]="BidManagmentModalOpened" [clrModalSize]="'md'" *ngIf="strategies">
    <div class="modal-title">Управление ставками</div>
    <div class="modal-body">
        <div class="alert alert-danger" *ngIf="errors.length > 0">
            <div class="alert-items">
                <div class="alert-item static" *ngFor="let error of errors">
                    <div class="alert-icon-wrapper">
                        <clr-icon class="alert-icon" shape="exclamation-circle"></clr-icon>
                    </div>
                    <span class="alert-text">{{error}}</span>
                </div>
            </div>
        </div>
        <div class="radio" *ngFor="let strategy of strategies">
            <input type="radio" name="strategyType" [id]="strategy.code" [(ngModel)]="strategyType" [value]="strategy.code">
            <label [attr.for]="strategy.code">{{strategy.title}}</label>
        </div>
        <app-dynamic-form [inputs]="inputs[strategyType]" OkActionText="Сохранить" (Successed)="SuccessHandle($event)"
            (Canceled)="CancelHandle()"></app-dynamic-form>
    </div>
</clr-modal>

<!--<clr-modal [(clrModalOpen)]="BidManagmentModalOpened" [clrModalSize]="'sm'" *ngIf="strategies$">-->
<!--<div class="modal-title">Управление ставками</div>-->
<!--<div class="modal-body">-->
<!--<div class="adForm-block">-->
<!--<label for="type">Тип стратегии: </label>-->
<!--<div class="adForm-group">-->
<!--<div class="radio" *ngFor="let userStrategy of strategies$">-->
<!--<input type="radio" [id]="userStrategy.code" name="strategyType" [value]="userStrategy.code"-->
<!--[(ngModel)]="strategyType">-->
<!--<label [for]="userStrategy.code">-->
<!--{{userStrategy.title}}-->
<!--</label>-->
<!--</div>-->
<!--</div>-->
<!--</div>-->
<!--<app-dynamic-adForm-->
<!--[inputs]="SelectedStrategy"-->
<!--OkActionText="Сохранить"-->
<!--&gt;</app-dynamic-adForm>-->
<!--</div>-->
<!--</clr-modal>-->


<ng-template #yandexLoading>
    <span class="spinner spinner-sm spinner-inline"></span>
</ng-template>
