import { AdManagerDataServiceService } from "../../ad-manager/services/ad-manager-data-service.service";
import * as i0 from "@angular/core";
import * as i1 from "../../ad-manager/services/ad-manager-data-service.service";
var InterestCategoriesService = /** @class */ (function () {
    function InterestCategoriesService(adDataService) {
        this.adDataService = adDataService;
        this.categoriesTree = null;
        this.selectedCategories = [];
        this.openedCategories = [];
        this.categories = [];
        if (this.categoriesTree === null) {
            this.generateCategoriesTree();
        }
    }
    InterestCategoriesService.prototype.generateCategoriesTree = function () {
        var _this = this;
        this.categoriesTree = this.adDataService.interestCategories
            .filter(function (cat) { return cat.parentId === null; })
            .map(function (cat) {
            cat.children = _this.getChildrens(cat);
            return cat;
        });
    };
    InterestCategoriesService.prototype.selectCategory = function (category) {
        if (!this.selectedCategories.includes(category.id)) {
            this.selectedCategories.push(category.id);
            if (category.parentId) {
                this.selectParent(category);
            }
        }
    };
    InterestCategoriesService.prototype.isSelected = function (category) {
        return this.selectedCategories.includes(category.id);
    };
    InterestCategoriesService.prototype.isCategoryChildrensPartlySelected = function (category) {
        var _this = this;
        var childrens = this.getChildrens(category);
        var selectedStatuses = childrens.map(function (x) { return _this.isSelected(x); })
            .filter(function (x) { return x; });
        return selectedStatuses.length >= 1 && selectedStatuses.length < childrens.length;
    };
    InterestCategoriesService.prototype.isCategoryChildrensAllSelected = function (category) {
        var _this = this;
        var childrens = this.getChildrens(category);
        console.log(childrens.reduce(function (stat, currentChild) { return stat && _this.isSelected(currentChild); }, false));
        return childrens.length > 0 && childrens.reduce(function (stat, currentChild) { return stat && _this.isSelected(currentChild); }, true) || false;
    };
    InterestCategoriesService.prototype.getChildrens = function (node) {
        return this.adDataService.interestCategories.filter(function (cat) { return cat.parentId === node.id; });
    };
    InterestCategoriesService.prototype.removeSelected = function (category) {
        this.selectedCategories = this.selectedCategories.filter(function (x) { return x !== category.id; });
        if (category.parentId) {
            this.removeParent(category);
        }
    };
    InterestCategoriesService.prototype.isCategoryOpened = function (category) {
        return this.openedCategories.includes(category.id);
    };
    InterestCategoriesService.prototype.openCategory = function (category) {
        this.openedCategories.push(category.id);
    };
    InterestCategoriesService.prototype.closeCategory = function (category) {
        this.openedCategories = this.openedCategories.filter(function (x) { return x !== category.id; });
    };
    InterestCategoriesService.prototype.removeParentSelected = function (category) {
        var _this = this;
        this.removeSelected(category);
        category.children.forEach(function (x) {
            _this.removeSelected(x);
        });
    };
    InterestCategoriesService.prototype.selectParentCategory = function (category) {
        var _this = this;
        this.selectCategory(category);
        category.children.forEach(function (x) {
            _this.selectCategory(x);
        });
    };
    InterestCategoriesService.prototype.hasChildren = function (category) {
        var children = this.getChildrens(category);
        return children.length > 0;
    };
    InterestCategoriesService.prototype.selectParent = function (childCategory) {
        var parent = this.getParent(childCategory);
        console.log(parent, this.isCategoryChildrensAllSelected(parent));
        if (parent && this.isCategoryChildrensAllSelected(parent)) {
            this.selectCategory(parent);
        }
    };
    InterestCategoriesService.prototype.removeParent = function (childCategory) {
        if (this.selectedCategories.includes(childCategory.parentId)) {
            var parent_1 = this.getParent(childCategory);
            if (parent_1) {
                this.removeSelected(parent_1);
            }
        }
    };
    InterestCategoriesService.prototype.getParent = function (childCategory) {
        return this.adDataService.interestCategories.find(function (x) { return x.id === childCategory.parentId; });
    };
    InterestCategoriesService.prototype.getSelectedCategories = function () {
        var _this = this;
        console.log(this.selectedCategories);
        var selected = this.selectedCategories
            .map(function (x) { return _this.adDataService.interestCategories.find(function (cat) { return cat.id === x; }); })
            .filter(function (x) {
            var stat = (_this.isParentWithAllChildrenSelected(x) ||
                _this.isParentWithoutChildrens(x) ||
                _this.isChildrenWithoutSelectedParent(x));
            console.log(x.title, _this.isParentWithAllChildrenSelected(x), _this.isParentWithoutChildrens(x), _this.isChildrenWithoutSelectedParent(x), x, 'stat');
            return stat;
        })
            .map(function (x) { return x.value; });
        console.log(selected, 'selected');
        return selected;
    };
    InterestCategoriesService.prototype.isParentWithAllChildrenSelected = function (category) {
        return category.children && category.children.length > 0 && this.isCategoryChildrensAllSelected(category);
    };
    InterestCategoriesService.prototype.isParentWithoutChildrens = function (category) {
        return category.parentId === null && (category.children === null || category.children.length === 0);
    };
    InterestCategoriesService.prototype.isChildrenWithoutSelectedParent = function (category) {
        return category.parentId !== null && !this.selectedCategories.includes(category.parentId);
    };
    InterestCategoriesService.ngInjectableDef = i0.defineInjectable({ factory: function InterestCategoriesService_Factory() { return new InterestCategoriesService(i0.inject(i1.AdManagerDataServiceService)); }, token: InterestCategoriesService, providedIn: "root" });
    return InterestCategoriesService;
}());
export { InterestCategoriesService };
