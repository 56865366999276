import {Injectable} from '@angular/core';
import {AdvancedSearchReportModel} from '../models/AdvancedSearchReportModel';
import {AdvancedSearchUserExpirienceService} from '../../api/services/advanced-search-user-expirience.service';

@Injectable()
export class AdvancedSearchReportService {

  constructor(private advancedSearchAPI: AdvancedSearchUserExpirienceService) {
  }

  public SendReport(entries: Array<AdvancedSearchReportModel>): void {
    if (!entries || entries.length === 0) {
      
    }

    this.advancedSearchAPI.sendAdvancedSearchReport(entries)
      .subscribe(data => {
          
        },
        err => {
          console.error('Error occured during reporting', err);
        });
  }
}
