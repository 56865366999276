import {Campaign} from '../../../api/models';

export class CampaignViewModel {

    public id;

    public name;

    public status;

    public day_limit;

    public all_limit;

    public spent;

    public impressions;

    public clicks;

    public ctr = 0;

    public ecpc;

    public yandex_cr_from_view = 0;

    public yandex_cr_from_click = 0;

    public yandex_cpa = 0;

    public yandex_reched_goals = 0;

    public yandex_page_depth = 0;

    public yandex_average_duration = 0;

    public utms = [];

    public accountId = null;

    public loaded = false;

    public clientId = null;

    constructor(id = null, name = null, status = null, day_limit = null, all_limit = null, spent = null, impressions = null, clicks = null,
                ctr = null, ecpc = null) {
        this.id = id;
        this.name = name;
        this.status = status;
        this.day_limit = day_limit;
        this.all_limit = all_limit;
        this.spent = spent;
        this.impressions = impressions;
        this.clicks = clicks;
        this.ctr = ctr;
        this.ecpc = ecpc;
    }

    public FillFromCampaign(campaign: Campaign) {
        this.id = campaign.id;
        this.name = campaign.name;
        this.status = campaign.status;
        this.day_limit = campaign.day_limit;
        this.all_limit = campaign.all_limit;
        return this;
    }
}
