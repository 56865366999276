import { EventEmitter, OnInit } from '@angular/core';
import { UserStrategiesService } from '../../../api/services';
import { AdViewModel } from '../../models/view-models/ad-view-model';
var StrategySettingsComponent = /** @class */ (function () {
    function StrategySettingsComponent(userStrategiesService) {
        this.userStrategiesService = userStrategiesService;
        this.displayFunctions = {};
        this.OnToggleStrategyActive = new EventEmitter();
        this.OnCreateStrategy = new EventEmitter();
        this.OnUpdateStrategy = new EventEmitter();
        this.OnRemoveStrategy = new EventEmitter();
        this.OnEditStrategy = new EventEmitter();
        this.ConfirmRemoveDialogShowed = false;
        this.ConfirmStartAdDialog = false;
        this.OpenedFormShowed = false;
        this.IsModelUpdating = false;
        this.IsShowedInputs = false;
    }
    Object.defineProperty(StrategySettingsComponent.prototype, "IsDisabledChangeStatusButton", {
        get: function () {
            if (!this.userStrategy.strategy.isAvailable) {
                return !this.userStrategy.isActive;
            }
            else {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    StrategySettingsComponent.prototype.ngOnInit = function () {
    };
    StrategySettingsComponent.prototype.ToggleStrategyActiveStatus = function () {
        if (this.userStrategy.isActive) {
            this.OnConfirmStartAd();
        }
        else {
            this.OnConfirmStartAd();
            // if (this.ad.IsActive) {
            //   this.ToggleConfirmStartAdDialog();
            // } else {
            //   this.OnConfirmStartAd();
            // }
        }
    };
    StrategySettingsComponent.prototype.ToggleConfirmStartAdDialog = function () {
        this.ConfirmStartAdDialog = !this.ConfirmStartAdDialog;
    };
    StrategySettingsComponent.prototype.OnConfirmStartAd = function () {
        console.log({ status: !this.userStrategy.isActive, adIds: this.userStrategy.adId }, 'strategy-settings');
        this.OnToggleStrategyActive.emit({ status: !this.userStrategy.isActive, adIds: this.userStrategy.adId });
    };
    StrategySettingsComponent.prototype.OnRejectStartAd = function () {
        this.ToggleConfirmStartAdDialog();
    };
    StrategySettingsComponent.prototype.RemoveStrategy = function () {
        this.OnRemoveStrategy.emit(this.ad);
    };
    StrategySettingsComponent.prototype.UpdateStrategy = function () {
        this.OnUpdateStrategy.emit(this.ad);
    };
    StrategySettingsComponent.prototype.CreateStrategy = function () {
        this.OnCreateStrategy.emit(this.ad);
    };
    StrategySettingsComponent.prototype.EditStrategy = function () {
        this.OnEditStrategy.emit(this.ad);
    };
    StrategySettingsComponent.prototype.GetInputValue = function (input) {
        var value = input.value;
        if (this.displayFunctions[input.type.code]) {
            value = this.displayFunctions[input.type.code](value);
        }
        return value;
    };
    StrategySettingsComponent.prototype.GetSelectedOptionValue = function (input) {
        return input.type.options.find(function (x) { return parseInt(x.value, 10) === input.value; }).title;
    };
    StrategySettingsComponent.prototype.ToggleInputsVisible = function () {
        this.IsShowedInputs = !this.IsShowedInputs;
    };
    StrategySettingsComponent.prototype.GetFormatedUserStrategyInputTitle = function (title) {
        return title.split(',')[0];
    };
    StrategySettingsComponent.prototype.GetToggleInputsText = function () {
        return (this.IsShowedInputs) ? 'Свернуть' : 'Развернуть';
    };
    return StrategySettingsComponent;
}());
export { StrategySettingsComponent };
