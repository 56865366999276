import {Component, OnInit} from '@angular/core';
import {UtilsService} from '../../../../api/services/utils.service';
import {AccountsService} from '../../../../api/services/accounts.service';
import {OAuthService} from '../../../../api/services/oauth.service';
import {TokenCheckerService} from '../../../../shared/services/token-checker.service';
import {Router} from '@angular/router';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'app-external-login-form',
  templateUrl: './external-login-form.component.html',
  styleUrls: ['./external-login-form.component.scss']
})
export class ExternalLoginFormComponent implements OnInit {

  public providers = [];
  public baseUrl = environment.frontEndUrl;

  constructor(
    private utilsService: UtilsService,
    private api: OAuthService,
    private tokenChecker: TokenCheckerService,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.LoadExternalProviders();
  }

  LoadExternalProviders() {
    this.utilsService.GetAuthorizationProviders()
      .subscribe(x => {
        this.providers = x;
      });
  }

  public AuthorizeWithProvider(provider: string) {
    this.api.Apiv2OauthAuthorizeByProviderGet({provider})
      .subscribe((x: any) => {
        if (x && x.token && x.expiredAt) {
          const token = x.token;
          const expiredAt = x.expiredAt;

          this.tokenChecker.SaveToken(token, expiredAt);
          this.router.navigate(['vk/groups']);
        }
      });
  }

}
