<form class="age-segmentation-item" [formGroup]="formGroup">
  <div class="age-segmentation-item__ages">
    <vk-form-field orientation="vertical">
      <vk-label>
        <strong>Возраст</strong>
        <app-suggestion-info code="AGE_LABEL"></app-suggestion-info>
      </vk-label>
      <select [name]="ageFromName" [id]="ageFromName" vkInput formControlName="ageFrom">
        <option [value]="0">Любой</option>
        <ng-container *ngFor="let age of agesFrom">
          <option [value]="age">От {{age}}</option>
        </ng-container>
      </select>
      <select [name]="ageToName" [id]="ageToName" vkInput formControlName="ageTo">
        <option [value]="0">Любой</option>
        <ng-container *ngFor="let age of agesTo">
          <option [value]="age">До {{age}}</option>
        </ng-container>
      </select>
    </vk-form-field>
  </div>
  <div class="age-segmentation-item__split">
    <mat-checkbox formControlName="eachAge" [disabled]="!IsEnabledEachAges()" class="no-margin-checkbox" title="Чтобы разбить по возрастам, нужно сначала выбрать Возраст От и До">Разбить по возрастам</mat-checkbox>
  </div>
  <div class="age-segmentation-item__sex">
    <vk-form-field orientation="vertical">
      <vk-label>
        <strong>Пол</strong>
        <app-suggestion-info code="SEX_LABEL"></app-suggestion-info>
      </vk-label>
      <mat-radio-group vkInput [useStyles]="false" formControlName="sex" class="age-segmentation-item__radio-group">
        <mat-radio-button [value]="0">Любой</mat-radio-button>
        <mat-radio-button [value]="1">Женский</mat-radio-button>
        <mat-radio-button [value]="2">Мужской</mat-radio-button>
        <mat-radio-button [value]="3">Разбить по полу</mat-radio-button>
      </mat-radio-group>
    </vk-form-field>
  </div>
  <button mat-button color="warn" class="age-segmentation-item__remove-button" (click)="Remove()">
    <mat-icon>remove</mat-icon>
    Удалить сегмент
  </button>
  <div class="age-sgmentation-item__help">
    <app-suggestion-info code="REMOVE_SEGMENT"></app-suggestion-info>
  </div>
</form>
