import { EventEmitter, OnInit } from '@angular/core';
import { AutomationVkSlotService } from '../../../api/services/automation-vk-slot.service';
import { isNullOrUndefined } from 'util';
import * as moment from 'moment';
moment.locale('ru');
var PickListNgrComponent = /** @class */ (function () {
    function PickListNgrComponent(_service) {
        this._service = _service;
        /**
         * Элементы списка
         * @type {PickListItemModel[]}
         */
        this.dataList = [];
        /**
         * Событие сохранения кабинетов
         * @type {EventEmitter<any>}
         */
        this.dataListChanged = new EventEmitter();
        /**
         * Событие закрытия окна
         * @type {EventEmitter<any>}
         */
        this.onClose = new EventEmitter();
        /**
         * Индикатор показывающий может ли быть выделен еще один элемент
         * @returns {boolean}
         * @constructor
         */
        // public get CanBeSelected() {
        //     return this.slots.filter(x => x.bindedCabinetId === null).length > 0;
        // }
        this.error = '';
    }
    Object.defineProperty(PickListNgrComponent.prototype, "Items", {
        // /**
        //  * Выделенные элементами списка
        //  * @returns {IPickListItemModel[]}
        //  * @constructor
        //  */
        // public get SelectedItems() {
        //     return this.dataList.filter(x => x.IsSelected);
        // }
        get: function () {
            var _this = this;
            return this.dataList.filter(function (x) { return x.account_type !== 'agency'; }).map(function (x) {
                x.isSelected = _this.IsAccountAssigned(x.account_id, x.client_id);
                return x;
            });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PickListNgrComponent.prototype, "FreeSlots", {
        get: function () {
            return this.slots ? this.slots.filter(function (x) { return x.bindedCabinetId === null; }).length : 0;
        },
        enumerable: true,
        configurable: true
    });
    PickListNgrComponent.prototype.IsAccountAssigned = function (accountId, clientId) {
        var systemSlot = this.slots.find(function (slot) { return slot.bindedCabinetId === accountId && slot.bindedClientId === clientId; });
        return !isNullOrUndefined(systemSlot);
    };
    PickListNgrComponent.prototype.ngOnInit = function () {
    };
    PickListNgrComponent.prototype.Change = function (status, data) {
        this.dataListChanged.emit(data);
    };
    /**
     * Сохраняет значения списка
     * @constructor
     */
    PickListNgrComponent.prototype.Save = function () {
        this.onClose.emit();
    };
    PickListNgrComponent.prototype.GetSlotByAccountId = function (account_id) {
        return this.slots.find(function (x) { return x.bindedCabinetId === account_id; });
    };
    PickListNgrComponent.prototype.GetDeactivationTimeString = function (account_id) {
        var slot = this.GetSlotByAccountId(account_id);
        if (slot) {
            var date = moment(slot.canRebindAfter).local();
            return moment().to(date);
            //return moment(slot.canRebindAfter).local().format('MM-DD HH:mm:ss');
        }
        else {
            return '';
        }
    };
    PickListNgrComponent.prototype.SetError = function (err) {
        this.error = err.description;
    };
    PickListNgrComponent.prototype.CanBeSelected = function (account) {
        var slot = null;
        if (account.account_type === 'client') {
            slot = this.slots.find(function (x) { return x.bindedCabinetId === account.account_id && x.bindedClientId === account.client_id; });
        }
        else {
            slot = this.slots.find(function (x) { return x.bindedCabinetId === account.account_id; });
        }
        if (slot) {
            return (!isNullOrUndefined(slot.bindedCabinetId)) ? !slot.canRebindCabinet : this.FreeSlots === 0;
            ;
        }
        else {
            return this.FreeSlots === 0;
        }
    };
    return PickListNgrComponent;
}());
export { PickListNgrComponent };
