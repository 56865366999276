<div class="link-widget">
  <div class="link-widget__image" [style.background]="Image"></div>
  <div class="link-widget__meta-wrapper">
    <div class="link-widget__link-title">
      <ng-container *ngIf="enableEditingModeForm < 5; else linkTitleForm">
        {{linkWidgetData.linkTitle}}
<!--        <button class="link-widget__edit-title" (click)="startEditingTitle()">-->
<!--          <mat-icon>edit</mat-icon>-->
<!--        </button>-->
      </ng-container>
    </div>
    <div class="link-widget__link-domain">
      {{linkWidgetData.linkDomain | domainOnly}}
    </div>
  </div>
</div>

<ng-template #linkTitleForm>
  <div class="link-title-form">
    <input type="text" class="link-title-form__text-field"/>
    <button class="vk-standart-button">Сохранить</button>
  </div>
</ng-template>
