/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./create-ad-new-lite.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../components/new-ad-form-lite/new-ad-form-lite.component.ngfactory";
import * as i3 from "../../components/new-ad-form-lite/new-ad-form-lite.component";
import * as i4 from "../../../new-ad-manager-shared/services/ad-form-manager/ad-form-manager.service";
import * as i5 from "../../../../../shared/services/logging.service";
import * as i6 from "../../../../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i7 from "@angular/material/progress-spinner";
import * as i8 from "@angular/cdk/platform";
import * as i9 from "@angular/common";
import * as i10 from "@angular/platform-browser/animations";
import * as i11 from "./create-ad-new-lite.component";
import * as i12 from "../../../../../api/services/ad-manager.service";
var styles_CreateAdNewLiteComponent = [i0.styles];
var RenderType_CreateAdNewLiteComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CreateAdNewLiteComponent, data: {} });
export { RenderType_CreateAdNewLiteComponent as RenderType_CreateAdNewLiteComponent };
function View_CreateAdNewLiteComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-new-ad-form-lite", [], null, [[null, "adsSaved"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("adsSaved" === en)) {
        var pd_0 = (_co.OnSave($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_NewAdFormLiteComponent_0, i2.RenderType_NewAdFormLiteComponent)), i1.ɵdid(2, 114688, null, 0, i3.NewAdFormLiteComponent, [i4.AdFormManagerService, i5.LoggingService], null, { adsSaved: "adsSaved" })], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_CreateAdNewLiteComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "loading-screen"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "loading-screen__spinner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "mat-spinner", [["class", "mat-spinner mat-progress-spinner"], ["diameter", "18"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i6.View_MatSpinner_0, i6.RenderType_MatSpinner)), i1.ɵdid(3, 49152, null, 0, i7.MatSpinner, [i1.ElementRef, i8.Platform, [2, i9.DOCUMENT], [2, i10.ANIMATION_MODULE_TYPE], i7.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], { diameter: [0, "diameter"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "loading-screen__message"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u041F\u043E\u0434\u043E\u0436\u0434\u0438\u0442\u0435, \u0438\u0434\u0435\u0442 \u0437\u0430\u0433\u0440\u0443\u0437\u043A\u0430 \u0434\u0430\u043D\u043D\u044B\u0445"]))], function (_ck, _v) { var currVal_3 = "18"; _ck(_v, 3, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3)._noopAnimations; var currVal_1 = i1.ɵnov(_v, 3).diameter; var currVal_2 = i1.ɵnov(_v, 3).diameter; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_CreateAdNewLiteComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_CreateAdNewLiteComponent_1)), i1.ɵdid(1, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["loadingScreen", 2]], null, 0, null, View_CreateAdNewLiteComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.IsLoading; var currVal_1 = i1.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_CreateAdNewLiteComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-create-ad-new-lite", [], null, null, null, View_CreateAdNewLiteComponent_0, RenderType_CreateAdNewLiteComponent)), i1.ɵdid(1, 114688, null, 0, i11.CreateAdNewLiteComponent, [i4.AdFormManagerService, i12.AdManagerService, i5.LoggingService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CreateAdNewLiteComponentNgFactory = i1.ɵccf("app-create-ad-new-lite", i11.CreateAdNewLiteComponent, View_CreateAdNewLiteComponent_Host_0, {}, {}, []);
export { CreateAdNewLiteComponentNgFactory as CreateAdNewLiteComponentNgFactory };
