var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { QueryEntity } from '@datorama/akita';
import { TimePeriodsStore } from './time-periods.store';
import { flatMap, map, tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./time-periods.store";
var TimePeriodsQuery = /** @class */ (function (_super) {
    __extends(TimePeriodsQuery, _super);
    function TimePeriodsQuery(store) {
        var _this = _super.call(this, store) || this;
        _this.store = store;
        _this.timePeriods$ = _this.selectAll();
        _this.activePeriod$ = function (tariffGroupId) {
            return _this.select(function (state) { return state.ui.selectedTimePeriods; })
                .pipe(map(function (selectedTimePeriods) { return selectedTimePeriods["tariffGroup" + tariffGroupId]; }), flatMap(function (selectedTimePeriodId) { return _this.getTimePeriodById$(selectedTimePeriodId); }), tap(function (data) {
                console.log(data, 'WHAT IS GOOD');
            }));
        };
        _this.selectedPeriodId = function (tariffGroupId) { return _this.activePeriod$(tariffGroupId)
            .pipe(map(function (x) { return x.id; })); };
        _this.getTimePeriodById$ = function (timePeriodId) {
            return _this.selectEntity(function (timePeriod) { return timePeriod.id === timePeriodId; });
        };
        _this.getTimePeriodNameById = function (timePeriodId) {
            var timePeriod = _this.getEntity(timePeriodId);
            console.log(timePeriod, timePeriodId, 'GET TIME PERIOD NAME');
            return timePeriod.name;
        };
        return _this;
    }
    Object.defineProperty(TimePeriodsQuery.prototype, "selectedTariffPlans$", {
        get: function () {
            return this.select(function (state) { return state.ui.selectedTimePeriods; });
        },
        enumerable: true,
        configurable: true
    });
    TimePeriodsQuery.ngInjectableDef = i0.defineInjectable({ factory: function TimePeriodsQuery_Factory() { return new TimePeriodsQuery(i0.inject(i1.TimePeriodsStore)); }, token: TimePeriodsQuery, providedIn: "root" });
    return TimePeriodsQuery;
}(QueryEntity));
export { TimePeriodsQuery };
