import { ChangeDetectorRef, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { PlatformsTypes } from '../../../../platforms-types';
import { FormBuilder } from '@angular/forms';
import { AkitaNgFormsManager } from '@datorama/akita-ng-forms-manager';
import { CrmPipelinesQuery } from '../../../../stores/crm-pipelines/crm-pipelines.query';
import { CrmPipelinesService } from '../../../../stores/crm-pipelines/crm-pipelines.service';
import { AssignationPlatforms } from '../../../../stores/platform-assignations/platform-assignation.model';
import { PlatformAssignationsQuery } from '../../../../stores/platform-assignations/platform-assignations.query';
import { PlatformsQuery } from '../../../../stores/platforms/platforms.query';
import { CrmUsersQuery } from '../../../../stores/crm-users/crm-users.query';
import { CrmUsersService } from '../../../../stores/crm-users/crm-users.service';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { BitrixService } from '../../../../../api/services';
import { PlatformsService } from '../../../../stores/platforms/platforms.service';
var ExportCrmComponent = /** @class */ (function () {
    function ExportCrmComponent(fb, fm, pipelinesQuery, pipelinesService, assignationQuery, platformsQuery, cd, crmUsersQuery, crmUsersService, bitrixService, platformsService) {
        this.fb = fb;
        this.fm = fm;
        this.pipelinesQuery = pipelinesQuery;
        this.pipelinesService = pipelinesService;
        this.assignationQuery = assignationQuery;
        this.platformsQuery = platformsQuery;
        this.cd = cd;
        this.crmUsersQuery = crmUsersQuery;
        this.crmUsersService = crmUsersService;
        this.bitrixService = bitrixService;
        this.platformsService = platformsService;
        this.isSelectedLeads = false;
        this.pipelines$ = this.pipelinesQuery.pipelines$
            .pipe(untilDestroyed(this));
        this.users$ = this.crmUsersQuery.users$;
        this.loading = false;
        this.isAssigned = false;
        this.countOfPipelines = 0;
        this.firstPipelineId = null;
    }
    Object.defineProperty(ExportCrmComponent.prototype, "pipelineLabel", {
        get: function () {
            if (this.countOfPipelines > 1) {
                return 'Выбрать воронку, из которой выгружать контакты';
            }
            else {
                return 'Передавать данные контактов из воронки продаж';
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ExportCrmComponent.prototype, "pipelineLabelSuggestionCode", {
        get: function () {
            if (this.countOfPipelines > 1) {
                return 'AMO_BY_PIPELINES';
            }
            else {
                return 'BY_PIPELINES';
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ExportCrmComponent.prototype, "LeadValue", {
        get: function () {
            switch (this.type) {
                case PlatformsTypes.AMO_CRM_EXPORT:
                default:
                    return 'LEADS';
                case PlatformsTypes.MEGAPLAN_EXPORT:
                    return 'DEALS';
                case PlatformsTypes.UON_EXPORT:
                    var sectionCode = this.pipelinesQuery.pipelineSectionCodeById(this.form.get('selectedPipelineId').value);
                    return sectionCode === 'CONTACTS' ? 'DEALS' : sectionCode;
            }
        },
        enumerable: true,
        configurable: true
    });
    ExportCrmComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loading = true;
        if (this.type === PlatformsTypes.UON_EXPORT) {
            this.pipelines$ = this.pipelines$.pipe(map(function (x) { return x.filter(function (y) { return y.type === 'DEALS'; }); }));
        }
        this.pipelines$.subscribe(function (data) {
            _this.countOfPipelines = data.length;
            if (data.length > 0) {
                _this.firstPipelineId = data[0].id;
            }
        });
        this.assignationQuery
            .assingnationStatus$(this.platform)
            .pipe(distinctUntilChanged(), untilDestroyed(this))
            .subscribe(function (data) {
            _this.isAssigned = data;
            if (data) {
                _this.HandleChanges();
                _this.pipelinesService.LoadPipelines(_this.type);
            }
        });
        setTimeout(function () {
            _this.HandleChanges();
            _this.loading = false;
        }, 1000);
    };
    ExportCrmComponent.prototype.ngOnDestroy = function () {
        this.fm.unsubscribe('originPlatformSettings');
    };
    ExportCrmComponent.prototype.initForm = function () {
        var _this = this;
        if (this.isAssigned) {
            this.fm.unsubscribe('originPlatformSettings');
            this.loading = true;
            this.form = null;
            this.form = this.fb.group({
                section: this.fb.control('CONTACTS'),
                isClassicMode: this.fb.control(PlatformsTypes.UON_EXPORT === this.type),
                selectedPipelineId: this.fb.control(null)
            });
            this.ChangeDetection();
            this.form.get('section').setValue('CONTACTS');
            this.form
                .get('selectedPipelineId')
                .valueChanges
                .pipe(distinctUntilChanged(), debounceTime(1000))
                .subscribe(function (selectedPipleineId) {
                var sectionCode = _this.pipelinesQuery.pipelineSectionCodeById(selectedPipleineId);
                console.log(sectionCode, 'WTF ???');
                _this.form.get('section').patchValue(sectionCode, { emitEvent: false });
            });
            this.form.get('section').valueChanges
                .pipe(distinctUntilChanged(), untilDestroyed(this))
                .subscribe(function (section) {
                switch (_this.type) {
                    case PlatformsTypes.BITRIX_EXPORT:
                        _this.isSelectedLeads = section === 'DEALS';
                        break;
                    case PlatformsTypes.UON_EXPORT:
                        _this.form.patchValue({ isClassicMode: true });
                        if (section === 'UON_MARKS_EXPORT') {
                            _this.platformsService.SetParameters(AssignationPlatforms.UON, { hasLeads: false });
                            _this.form.get('selectedPipelineId').patchValue(3, { onlySelf: false, emitEvent: false });
                        }
                        else {
                            _this.platformsService.SetParameters(AssignationPlatforms.UON, { hasLeads: true });
                        }
                        break;
                    // tslint:disable-next-line:no-switch-case-fall-through
                    default:
                        _this.isSelectedLeads = section === 'LEADS';
                        break;
                }
                if (section === 'CONTACTS') {
                    _this.form.get('selectedPipelineId').setValue(null);
                }
                else if (section === 'UON_MARKS_EXPORT') {
                    // this.form.get('selectedPipelineId').setValue(3, {onlySelf: false, emitEvent: false});
                }
                else {
                    _this.form.get('selectedPipelineId').setValue(_this.firstPipelineId);
                }
            });
            this.pipelines$.subscribe(function (data) {
                console.log("Count of pipelines " + data.length);
                if (_this.type === PlatformsTypes.UON_EXPORT) {
                    var deals = data.find(function (x) { return x.type === 'DEALS'; });
                    _this.form.patchValue({ selectedPipelineId: deals ? deals.id : null });
                }
                else {
                    _this.form.patchValue({ selectedPipelineId: data.length === 1 ? data[0].id : null });
                }
                _this.loading = false;
            });
            this.fm.upsert('originPlatformSettings', this.form);
            this.form.patchValue({
                section: 'CONTACTS'
            });
        }
    };
    ExportCrmComponent.prototype.ChangeDetection = function () {
        if (!this.cd['destroyed']) {
            this.cd.detectChanges();
        }
    };
    ExportCrmComponent.prototype.ngOnChanges = function (changes) {
        if (changes.platform) {
            this.CheckAssignation();
            if (this.isAssigned) {
                this.HandleChanges();
            }
        }
    };
    ExportCrmComponent.prototype.CheckAssignation = function () {
        var _this = this;
        var assignationsData = this.assignationQuery.getAll({ filterBy: function (entity) { return entity.id === _this.platform; } });
        if (assignationsData && assignationsData.length > 0) {
            this.isAssigned = assignationsData[0].assignationStatus;
        }
    };
    ExportCrmComponent.prototype.HandleChanges = function () {
        this.loading = true;
        this.ChangeDetection();
        if (this.form) {
            this.form.patchValue({ section: 'CONTACTS', selectedPipelineId: null });
            this.loading = false;
        }
        else {
            this.initForm();
            this.loading = false;
        }
    };
    return ExportCrmComponent;
}());
export { ExportCrmComponent };
