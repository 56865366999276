import {ConfigurationForm} from "../models/configuration-form";

export abstract class AbstractConfigurator {

  constructor(
    protected config: ConfigurationForm
  ) {
  }

  abstract configure();
}
