import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PromopostViewModel} from '../../models/promopost.view-model';
import {DomainOnlyPipe} from '../../../../shared/pipes/domain-only.pipe';
import {DomSanitizer} from '@angular/platform-browser';
import {PostificatePipe} from '../../../../shared/pipes/postificate.pipe';

@Component({
  selector: 'app-promopost',
  templateUrl: './promopost.component.html',
  styleUrls: ['./promopost.component.scss']
})
export class PromopostComponent implements OnInit {
  @Input() public selectedGroup: any = null;
  @Input() promopost: PromopostViewModel;
  @Input() group: any;

  @Input() public text: string;
  @Input() public image: string;
  @Input() public showEditForm: boolean;

  @Output() public ImageRemoved: EventEmitter<string> = new EventEmitter<string>();
  @Output() public linkTitleChanged: EventEmitter<string> = new EventEmitter<string>();

  public editLinkTitle = false;
  public editLinkTitleValue = '';

  constructor(
    public _sanitizer: DomSanitizer
  ) {
  }

  ngOnInit() {
    console.log(this.promopost);
    this.editLinkTitleValue = this.promopost.linkTitle;
  }

  public get PromopostText() {
    return new PostificatePipe().transform(this.promopost.text);
  }

  public GetPromosotGroupName(): string {
    return (this.selectedGroup) ? this.selectedGroup.name : 'Сообщество не выбрано';
  }

  public get Image() {
    return this.promopost.image ? `url("${this.promopost.image}")` : 'url("assets/images/ad_size_promo.jpg")';
  }

  public GetPromosotGroupAvatar(): string {
    return (this.selectedGroup) ? this.selectedGroup.photo_50 : 'https://via.placeholder.com/50';
  }

  public GetPromopostAddress(): string {
    return (this.promopost.linkAddress === '') ? 'Здесь будет домен ссылки' : new DomainOnlyPipe().transform(this.promopost.linkAddress);
  }

  public GetPromopostTitle(): string {
    return this.promopost.linkTitle === '' ? 'Здесь будет название вашей ссылки' : this.promopost.linkTitle;
  }

  removeImage() {
    this.ImageRemoved.emit(this.image);
    this.promopost.image = '';
    this.image = '';
  }

  EnableLinkTitleEditor() {
    this.editLinkTitleValue = this.GetPromopostTitle();
    this.ToggleEditLinkTitleForm();
  }

  private ToggleEditLinkTitleForm() {
    console.log('Clicked');
    this.editLinkTitle = !this.editLinkTitle;
  }

  SaveLinkTitle() {
    this.linkTitleChanged.emit(this.editLinkTitleValue);
    this.ToggleEditLinkTitleForm();
  }

  ClickOutSideForm() {
    console.log(this.editLinkTitle);
    if (this.editLinkTitle) {
      console.log('outside clicked');
      this.ToggleEditLinkTitleForm();
    }
  }
}
