import { OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { flatMap } from 'rxjs/operators';
import { AdManagerService } from '../../../../../api/services';
import { CreateCampaignFormComponent } from '../../../../ad-manager/components/create-campaign-form/create-campaign-form.component';
import { AdFormManagerService } from '../../services/ad-form-manager/ad-form-manager.service';
var NewAccountSettingsStepComponent = /** @class */ (function () {
    function NewAccountSettingsStepComponent(adFormManagerService, adManagerService, dialog) {
        this.adFormManagerService = adFormManagerService;
        this.adManagerService = adManagerService;
        this.dialog = dialog;
        this.controlWidth = '200px';
        this.labelWidth = '250px';
    }
    Object.defineProperty(NewAccountSettingsStepComponent.prototype, "form", {
        get: function () {
            return this.adFormManagerService.getControl('campaignData');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewAccountSettingsStepComponent.prototype, "accounts", {
        get: function () {
            return this.adFormManagerService
                .accounts
                .filter(function (account) { return account.account_type !== 'agency' || (account.account_type === 'agency' && account.client_id != null); });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewAccountSettingsStepComponent.prototype, "campaigns", {
        get: function () {
            return this.adFormManagerService.displayedCampaigns;
        },
        enumerable: true,
        configurable: true
    });
    NewAccountSettingsStepComponent.prototype.getAccountId = function (account) {
        return this.adFormManagerService.GetAccountId(account);
    };
    NewAccountSettingsStepComponent.prototype.IsAccountAssigned = function (account) {
        return this.adFormManagerService.isAccountAssigned(account.account_id, account.client_id);
    };
    NewAccountSettingsStepComponent.prototype.OpenCreateCampaignDialog = function () {
        var _this = this;
        var _a = this.adFormManagerService.getAccountData(), accountId = _a.accountId, clientId = _a.clientId;
        this.dialog.open(CreateCampaignFormComponent, {
            data: {
                clientId: clientId,
                type: this.adFormManagerService.currentFormat().type,
                accountId: accountId
            }
        })
            .afterClosed()
            .pipe(flatMap(function (data) { return _this.adFormManagerService.LoadCampaigns(); }))
            .subscribe(function (data) {
        });
    };
    NewAccountSettingsStepComponent.prototype.ngOnInit = function () {
    };
    return NewAccountSettingsStepComponent;
}());
export { NewAccountSettingsStepComponent };
