import {Injectable} from '@angular/core';
import {RetargetingGroup} from './retargeting-group.model';
import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';

export interface RetargetingGroupsState extends EntityState<RetargetingGroup> {
  ui: {
    filter: RemarketingBaseType
  };
}

export enum RemarketingBaseType {
  EMAILS = 'emails',
  PHONES = 'phones'
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'retargeting-groups'})
export class RetargetingGroupsStore extends EntityStore<RetargetingGroupsState> {

  constructor() {
    super({
      ui: {
        filter: RemarketingBaseType.EMAILS
      }
    });
  }

}

