var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { QueryEntity } from '@datorama/akita';
import { PlatformsRelationsStore } from './platforms-relations.store';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./platforms-relations.store";
var PlatformsRelationsQuery = /** @class */ (function (_super) {
    __extends(PlatformsRelationsQuery, _super);
    function PlatformsRelationsQuery(store) {
        var _this = _super.call(this, store) || this;
        _this.store = store;
        _this.relatedPlatformsIds = function (originPlatformId) { return _this
            .selectAll({ filterBy: function (entity) { return entity.integrationServiceFromId === originPlatformId; } })
            .pipe(map(function (x) { return x.map(function (y) { return y.integrationServiceToId; }); })); };
        _this.RelationPlatformsName = function (origin, dest) { return _this.selectAll({ filterBy: function (e) { return e.integrationServiceToId === dest && e.integrationServiceFromId === origin; } })
            .pipe(map(function (rel) { return rel[0]; }), map(function (rel) { return rel ? rel.name : null; })); };
        return _this;
    }
    PlatformsRelationsQuery.ngInjectableDef = i0.defineInjectable({ factory: function PlatformsRelationsQuery_Factory() { return new PlatformsRelationsQuery(i0.inject(i1.PlatformsRelationsStore)); }, token: PlatformsRelationsQuery, providedIn: "root" });
    return PlatformsRelationsQuery;
}(QueryEntity));
export { PlatformsRelationsQuery };
