<form [formGroup]="form">
  <mat-radio-group formControlName="mode">
    <mat-radio-button value="auto" color="primary">Автоматическая установка стоимости 1000 показов объявления</mat-radio-button>
    <br>
    <br>
    <mat-radio-button value="custom" color="primary">Ручная установка стоимости 1000 показов объявления</mat-radio-button>
    <br>
    <br>
  </mat-radio-group>

  <div class="tip-field">
    Стоимость 1000 показов объявления будет устанавливаться <strong>автоматически</strong> таким образом, чтобы <strong>снизить
    расход рекламного бюджета.</strong>
  </div>

  <br>
  <ng-container *ngIf="IsStrategyModeAuto(); else customCpm">
    <div formGroupName="strategyValue">
      <vk-form-field [controlsWidth]="controlWidth" [labelWidth]="labelWidth">
        <vk-label>Остановить объявление при eCPC больше, руб</vk-label>
        <input type="number" vkInput formControlName="ECPC_LIMIT">
        <vk-input-options>
          <clr-signpost>
            <clr-icon shape="info-standard" clrSignpostTrigger></clr-icon>
            <clr-signpost-content>
              <strong>Не обязательное поле</strong><br>
              Стоимость перехода при достижении которой объявление будет автоматически остановлено.
            </clr-signpost-content>
          </clr-signpost>
        </vk-input-options>
      </vk-form-field>

      <vk-form-field [controlsWidth]="controlWidth" [labelWidth]="labelWidth">
        <vk-label>Остановить объявление при "Потрачено", руб.</vk-label>
        <input type="number" vkInput formControlName="CUSTOM_OVERALL_LIMIT">
        <vk-input-options>
          <clr-signpost>
            <clr-icon shape="info-standard" clrSignpostTrigger></clr-icon>
            <clr-signpost-content>
              <strong>Не обязательное поле</strong><br>
              Данный параметр следит за показателем "Потрачено". <br>
              Если показатель "Потрачено" превысит введённое Вами значение - объявление будет остановлено. <br>
              Примечание: "Потрачено" берётся за всё время работы объявления. <br>
            </clr-signpost-content>
          </clr-signpost>
        </vk-input-options>
      </vk-form-field>
    </div>
  </ng-container>
  <ng-template #customCpm>
    <vk-form-field>
      <vk-label>Введите стоимость (руб.) 1000 показов объявления</vk-label>
      <input type="number" vkInput formControlName="cpm">
      <vk-error validator="required">Вы не ввели данные о стоимости 1000 показов</vk-error>
      <vk-error validator="min">Стоимость не должна быть ниже чем {{RateMin}}</vk-error>
      <vk-error validator="max">Стоимость не должна быть больше чем {{RateMax}}</vk-error>
    </vk-form-field>
  </ng-template>
</form>
