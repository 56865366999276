import { OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { merge, Subject, throwError } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { startWith } from 'rxjs/internal/operators/startWith';
import { tap } from 'rxjs/internal/operators/tap';
import { catchError, concatAll, debounceTime, delay, distinctUntilChanged, flatMap, map, pairwise, retry, share, } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { environment } from '../../../../../../environments/environment';
import { AdManagerService, AutomationVkService, AutomationVkSlotService, UserStrategiesService } from '../../../../../api/services';
import { DomainOnlyPipe } from '../../../../../shared/pipes/domain-only.pipe';
import { PostificatePipe } from '../../../../../shared/pipes/postificate.pipe';
import { LoggingService } from '../../../../../shared/services/logging.service';
import { PromopostViewModel } from '../../../../ad-manager/models/promopost.view-model';
import { TeazerViewModel } from '../../../../ad-manager/models/teazer.view-model';
import { emojiCount, url } from '../../../../ad-manager/validators/url-validator';
import { AccountViewModel } from '../../../../models/view-models/account-view-model';
import * as i0 from "@angular/core";
import * as i1 from "../../../../../api/services/ad-manager.service";
import * as i2 from "../../../../../api/services/automation-vk.service";
import * as i3 from "../../../../../api/services/automation-vk-slot.service";
import * as i4 from "../../../../../api/services/user-strategies.service";
import * as i5 from "@angular/router";
import * as i6 from "../../../../../shared/services/logging.service";
var PROMOPOST_TEXT_VALIDATORS = [Validators.required, Validators.minLength(1), Validators.maxLength(650)];
var DEFAULT_AD_CATEGORY_ID = 126;
var StrategyAction;
(function (StrategyAction) {
    StrategyAction["CREATE_STRATEGY"] = "CREATE_STRATEGY";
    StrategyAction["UPDATE_STRATEGY"] = "UPDATE_STRATEGY";
    StrategyAction["REMOVE_STRATEGY"] = "REMOVE_STRATEGY";
    StrategyAction["DO_NOTHING"] = "DO_NOTHING";
})(StrategyAction || (StrategyAction = {}));
var strategyDefaultValues = {
    ECPC_LIMIT: 0,
    CUSTOM_OVERALL_LIMIT: null,
    ECPC_LIMIT_EXTENSION: 0,
    IMPRESSION_LIMIT: null,
    IMPRESSION_LIMIT_EXTENSION: 0,
    REACH_LIMIT: null,
    REACH_LIMIT_EXTENSION: 0
};
var AdFormManagerService = /** @class */ (function () {
    function AdFormManagerService(adManagerService, automationVkService, automationVkSlotService, userStrategyService, router, route, logger) {
        this.adManagerService = adManagerService;
        this.automationVkService = automationVkService;
        this.automationVkSlotService = automationVkSlotService;
        this.userStrategyService = userStrategyService;
        this.router = router;
        this.route = route;
        this.logger = logger;
        this.promopostLabelTextForm = new PostificatePipe().transform('Внимание! Рекламная запись должна содержать не более 6 эмодзи. Например: 🔥😏📅💃💪👁😱😈 или другие.');
        this.budget = 0;
        this.targetingStatsLoading = true;
        this.strategyAction = StrategyAction.DO_NOTHING;
        this.searchGroupsCities$ = null;
        this.errors = {
            format: 'Вы не выбрали формат объявления',
            teazer: 'Вы не ввели данные при создании объвления',
            promopost: 'Вы не ввели данные при создании объвления',
            targetingData: 'Вы не выбрали кому показывать объявление',
            campaignData: 'Вы не выбрали где сохранить объявление',
            rate: 'Вы не выбрали стоимость и способ показа объявления',
            moderationSettings: 'Вы не выбрали способ запуска и сохранения объявления'
        };
        this.loadingProgress = 0;
        this.isPro = false;
        this.PROMOPOST_CAMPAIGN_NAME = 'Кампания PostMonitor - Запись в новостной ленте';
        this.TEAZER_CAMPAIGN_NAME = 'Кампания PostMonitor - Объявления на страницах ВКонтакте';
        this.adName = '';
        this.adDataLoading = false;
        this.promopostWallLink = '';
        this.IsUpdate = false;
        this.strategyCreate = true;
        this.PostId = null;
        this.RemoveStrategy = false;
        this.rates = [];
        this.hasStrategy = false;
        this.assignAccountLoadingLite = false;
        this.adForm = new FormGroup({});
        this.adminGroups = [];
        this.searchCitiesControl = new FormControl();
        this.searchGroupsCity = new FormControl();
        this.loadingCreateAd = false;
        this.loadingSearchGroups = false;
        this.modifyFunctions = {
            BID_MIN: function (value) { return parseFloat(value) * 100; },
            BID_START: function (value) { return parseFloat(value) * 100; },
            BID_MAX: function (value) { return parseFloat(value) * 100; },
            BID_STEP: function (value) { return parseFloat(value) * 100; },
            ECPC_LIMIT: function (value) { return parseFloat(value) * 100; },
        };
        this.displayFunctions = {
            BID_MIN: function (value) { return parseFloat(value) / 100; },
            BID_MAX: function (value) { return parseFloat(value) / 100; },
            BID_START: function (value) { return parseFloat(value) / 100; },
            BID_STEP: function (value) { return parseFloat(value) / 100; },
            ECPC_LIMIT: function (value) { return parseFloat(value) / 100; },
        };
        this.groupToggled = new Subject();
        this.selectedGroups = [];
        this.ages = environment.vkAges;
        this.adFormats = [
            {
                title: 'Рекламная запись в новостной ленте',
                description: 'Объявление будет показываться в ВКонтакте в новостной ленте, на стенах сообществ и в рекламной сети.',
                image: 'assets/images/promopost.jpg',
                selected: true,
                value: 'promopost',
                ad_format: 9,
                min_cpm: 30,
                ad_platform: 'all',
                type: 'promoted_posts',
                preview_image: 'assets/images/ad-format-preview-promopost.jpg'
            },
            {
                title: 'Рекламное объявление на страницах ВКонтакте',
                description: 'Объявление будет показываться в ВКонтакте в левом рекламном блоке',
                image: 'assets/images/ad_teazer.jpg',
                selected: false,
                value: 'teazer',
                ad_format: 2,
                min_cpm: 1.2,
                ad_platform: '0',
                type: 'normal',
                preview_image: 'assets/images/ad-format-preview-teazer.jpg'
            },
        ];
        this.countries = [];
        this.cities = [];
        this.groups = [];
        this.slots = [];
        this.accounts = [];
        this.campaigns = [];
        this.progressStep = 0;
        this.LoadBidRanges();
        this.adName = "\u041E\u0431\u044A\u044F\u0432\u043B\u0435\u043D\u0438\u0435 " + moment(new Date()).format('DD.MM.YYYY HH:mm:ss');
        this.adForm = new FormGroup({
            format: new FormControl('promopost'),
            promopost: new FormGroup({
                text: new FormArray([
                    new FormControl('', [
                        Validators.required,
                        Validators.minLength(1),
                        Validators.maxLength(600),
                        emojiCount
                    ])
                ]),
                image: new FormControl([], [Validators.required]),
                groupId: new FormControl(null, [Validators.required]),
                linkAddress: new FormControl(null, [Validators.required, url]),
                linkTitle: new FormControl(null, [Validators.required]),
                name: new FormControl(this.adName),
            }),
            teazer: new FormGroup({
                text: new FormArray([
                    new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(33)])
                ]),
                image: new FormControl([], [Validators.required]),
                linkAddress: new FormControl('', [Validators.required, url]),
                groupId: new FormControl(),
                name: new FormControl(this.adName),
            }),
            targetingData: new FormGroup({
                country: new FormControl(1),
                cities: new FormControl([0]),
                groups: new FormControl(''),
                ageFrom: new FormControl(0),
                ageTo: new FormControl(0),
                sex: new FormControl(0),
                targetGroups: new FormControl([]),
                targetGroupsNot: new FormControl([]),
                auditory: new FormControl(0, [Validators.required, Validators.min(100)])
            }),
            campaignData: new FormGroup({
                accountId: new FormControl(null, [Validators.required]),
                campaignId: new FormControl(null, [Validators.required])
            }),
            rateData: new FormGroup({
                cpm: new FormControl(),
                strategyValue: new FormGroup({
                    ECPC_LIMIT: new FormControl(null),
                    CUSTOM_OVERALL_LIMIT: new FormControl(null),
                    ECPC_LIMIT_EXTENSION: new FormControl('0'),
                    IMPRESSION_LIMIT: new FormControl(null),
                    IMPRESSION_LIMIT_EXTENSION: new FormControl('0'),
                    REACH_LIMIT: new FormControl(null),
                    REACH_LIMIT_EXTENSION: new FormControl('0')
                }),
                mode: new FormControl('auto')
            }),
            moderationData: new FormGroup({
                sendModeration: new FormControl(false),
                startAfterModeration: new FormControl(false)
            })
        });
        this.searchGroupsForm = new FormGroup({
            title: new FormControl('', [Validators.required, Validators.minLength(1)]),
            countryId: new FormControl(0),
            cityId: new FormControl(0)
        });
        console.log('INIT DATA constructor');
        this.InitAdData();
        this.subscribeToCitiesSearchStirng();
        this.subscribeSearchGroupForm();
        this.subscribeFormEvents();
        this.subscribeTargetingForm();
        this.subscribeAccountForm();
        this.subscribeFormatChange();
        this.subscribeRateModeData();
    }
    Object.defineProperty(AdFormManagerService.prototype, "selectedGroup", {
        get: function () {
            var promopostGroupId = this.getControl('promopost', 'groupId').value;
            var group = null;
            if (this.adminGroups && this.adminGroups.length > 0) {
                group = this.adminGroups.find(function (adminGroup) { return adminGroup['id'] === parseInt(promopostGroupId, 10); });
            }
            return group;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdFormManagerService.prototype, "teazers", {
        get: function () {
            var teazers = [];
            var teazerTexts = this.getControl('teazer', 'text').value;
            var teazerImages = this.getControl('teazer', 'image').value;
            var teazerLink = this.getControl('teazer', 'linkAddress').value;
            for (var i = 0; i < teazerTexts.length; i++) {
                var teazer = new TeazerViewModel(teazerImages[i], teazerTexts[i], teazerLink);
                teazers.push(teazer);
            }
            return teazers;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdFormManagerService.prototype, "MinRateValue", {
        get: function () {
            var adFormat = this.currentFormat().ad_format;
            var rate = this.rates.find(function (x) { return x.adFormat === adFormat; });
            return rate ? rate.cpmMin / 100 : 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdFormManagerService.prototype, "MaxRateValue", {
        get: function () {
            var adFormat = this.currentFormat().ad_format;
            var rate = this.rates.find(function (x) { return x.adFormat === adFormat; });
            return rate ? rate.cpmMax / 100 : 100;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdFormManagerService.prototype, "agesFrom", {
        get: function () {
            var ageTo = this.getControl('targetingData', 'ageTo').value;
            var ages = this.ages;
            if (ageTo > 0) {
                ages = ages.filter(function (age) { return age <= ageTo; });
            }
            return ages;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdFormManagerService.prototype, "agesTo", {
        get: function () {
            var ageFrom = this.getControl('targetingData', 'ageFrom').value;
            var ages = this.ages;
            if (ageFrom > 0) {
                ages = ages.filter(function (age) { return age >= ageFrom; });
            }
            return ages;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdFormManagerService.prototype, "promoposts", {
        get: function () {
            var teazers = [];
            var teazerTexts = this.adForm.controls.promopost['controls'].text.value;
            var teazerImages = this.adForm.controls.promopost['controls'].image.value;
            var teazerLink = this.adForm.controls.promopost['controls'].linkAddress.value;
            var teazerGroup = this.adForm.controls.promopost['controls'].groupId.value;
            var teazerTitle = this.adForm.controls.promopost['controls'].linkTitle.value;
            for (var i = 0; i < teazerTexts.length; i++) {
                var promopost = new PromopostViewModel();
                promopost.groupId = teazerGroup;
                promopost.image = teazerImages[i];
                promopost.linkAddress = teazerLink;
                promopost.linkTitle = teazerTitle;
                promopost.text = teazerTexts[i];
                teazers.push(promopost);
            }
            return teazers;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdFormManagerService.prototype, "SelectedAdFormat", {
        get: function () {
            var currentAdFormat = this.adForm.controls.format.value;
            var selectedFormat = this.adFormats.find(function (format) { return format.value === currentAdFormat; });
            return selectedFormat;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdFormManagerService.prototype, "PromopostTextControls", {
        get: function () {
            return this.adForm.controls.promopost.controls.text.controls;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdFormManagerService.prototype, "IsSelectFormatPromopost", {
        get: function () {
            var formatControl = this.getControl('format');
            return formatControl.value === 'promopost';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdFormManagerService.prototype, "displayedCities", {
        get: function () {
            var cities = this.cities;
            var searchString = this.searchCitiesControl.value;
            if (searchString !== '' && searchString !== null) {
                cities = cities.filter(function (city) {
                    return city.title.toLowerCase().match(searchString.toLowerCase());
                });
            }
            return cities;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdFormManagerService.prototype, "searchGroupsDisplayCities", {
        get: function () {
            var _a = this.searchGroupsForm.value, title = _a.title, countryId = _a.countryId;
            return this.cities.filter(function (city) { return city.title.includes(title); });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdFormManagerService.prototype, "link", {
        get: function () {
            var format = this.getControl('format').value;
            var linkControl = this.getControl(format, 'linkAddress');
            return linkControl.value || '';
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Задает текущий шаг процесса
     * @param {number} progressStep
     * @constructor
     */
    AdFormManagerService.prototype.SetLoadingProgress = function (progressStep) {
        var maxSteps = this.IsUpdate ? 10 : 6;
        this.progressStep = progressStep;
        this.loadingProgress = progressStep / maxSteps * 100;
    };
    Object.defineProperty(AdFormManagerService.prototype, "LoadingStepMessage", {
        /**
         * Текущее сообщение для шага экрана загрузки
         * @returns {string}
         * @constructor
         */
        get: function () {
            var message = '';
            switch (this.progressStep) {
                case 0:
                    message = 'Загрузка ваших групп';
                    break;
                case 1:
                    message = 'Загрузка стран';
                    break;
                case 2:
                    message = 'Загрузка городов и регионов';
                    break;
                case 3:
                    message = 'Загрузка слотов';
                    break;
                case 4:
                    message = 'Загрузка кабинетов Вконтакте';
                    break;
                case 5:
                    message = 'Загрузка кампаний';
                    break;
                case 6:
                    message = 'Загрузка баланса кабиета ВКонтакте';
                    break;
                case 7:
                    message = 'Загрузка объявления';
                    break;
                case 8:
                    message = 'Загрузка внешнего вида объявления';
                    break;
                case 9:
                    message = 'Загрузка внешнего вида объявления';
                    break;
                case 10:
                    message = 'Загрузка настроек таргета';
                    break;
            }
            return message;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Текущий выбранный формат рекламной записи
     * @returns {{ad_format: number; image: string; preview_image: string; ad_platform: string; description: string; title: string; type: string; value: string; min_cpm: number; selected: boolean}}
     */
    AdFormManagerService.prototype.currentFormat = function () {
        var format = this.getControl('format').value;
        return this.adFormats.find(function (formatItem) { return formatItem.value === format; });
    };
    Object.defineProperty(AdFormManagerService.prototype, "displayedCampaigns", {
        /**
         * Кампании которые должны быть показаны на экране
         * @returns {Campaign[]}
         */
        get: function () {
            var _this = this;
            return this.campaigns.filter(function (campaign) { return _this.currentFormat().type === campaign.type; });
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Возвращает спецификации для создания кабинетов ВКонтакте
     * @returns {any[]}
     */
    AdFormManagerService.prototype.getSpecifications = function () {
        var value = this.adForm.value;
        var adLayouts = this.currentFormat().ad_format === 9 ? this.promoposts : this.teazers;
        var specifications = [];
        if (this.currentFormat().ad_format === 9) {
            for (var _i = 0, _a = this.promoposts; _i < _a.length; _i++) {
                var ad = _a[_i];
                specifications.push(this.createPromopostSpecification(ad, value, ad.text, ad.image));
            }
        }
        else {
            for (var _b = 0, _c = this.teazers; _b < _c.length; _b++) {
                var ad = _c[_b];
                specifications.push(this.createTeazerSpecification(ad, value, ad.title, ad.image));
            }
        }
        return specifications;
    };
    /**
     * Подключение к отслеживанию изменения в форме кампании
     */
    AdFormManagerService.prototype.subscribeAccountForm = function () {
        var _this = this;
        var control = this.getControl('campaignData', 'accountId');
        control.valueChanges.subscribe(function (data) {
            console.log(data, 'STARTING CAMPAIGN LOADING');
            if (data) {
                _this.LoadCampaigns()
                    .pipe(flatMap(function () { return _this.LoadAdTargetGroups(); }), flatMap(function () { return _this.LoadTargetingStats(); }))
                    .subscribe();
            }
        });
    };
    AdFormManagerService.prototype.createPromopostSpecification = function (promopost, formValue, promopostText, promopostImage) {
        var _a = formValue.campaignData.accountId.split('_'), accountId = _a[0], clientId = _a[1];
        var postId = null;
        if (this.IsUpdate) {
            postId = this.PostId;
        }
        console.log(postId, 'POST ID');
        return {
            linkTitle: formValue.promopost.linkTitle,
            linkUrl: formValue.promopost.linkAddress,
            accountId: accountId,
            clientId: (clientId === 'null') ? 0 : clientId,
            text: promopostText.replace('%', '%25'),
            groupId: formValue.promopost.groupId,
            postId: postId,
            adSpecifications: [
                {
                    ad_id: this.adId,
                    ad_format: this.currentFormat().ad_format,
                    age_from: formValue.targetingData.ageFrom,
                    age_to: formValue.targetingData.ageTo,
                    campaign_id: formValue.campaignData.campaignId,
                    category1_id: 126,
                    cities: formValue.targetingData.cities.join(),
                    country: formValue.targetingData.country === 'null' ? 0 : formValue.targetingData.country,
                    cpm: formValue.rateData.cpm,
                    cost_type: 1,
                    impressions_limited: (this.currentFormat().ad_format === 2) ? 1 : null,
                    impressions_limit: (this.currentFormat().ad_format === 9) ? 1 : null,
                    groups: formValue.targetingData.groups,
                    image: promopostImage,
                    title: promopostText.replace('%', '%25'),
                    link_url: formValue.teazer.linkAddress,
                    link_domain: new DomainOnlyPipe().transform(formValue.teazer.linkAddress),
                    name: formValue.promopost.name,
                    sex: formValue.targetingData.sex,
                    retargeting_groups: formValue.targetingData.targetGroups !== null && formValue.targetingData.targetGroups.length > 0
                        ? formValue.targetingData.targetGroups.filter(function (x) { return x > 0; }).join()
                        : 'null',
                    retargeting_groups_not: formValue.targetingData.targetGroupsNot !== null && formValue.targetingData.targetGroupsNot.length > 0
                        ? formValue.targetingData.targetGroupsNot.filter(function (x) { return x > 0; }).join()
                        : 'null'
                }
            ]
        };
    };
    AdFormManagerService.prototype.createTeazerSpecification = function (teazer, formValue, teazerText, teazerImage) {
        var _a = formValue.campaignData.accountId.split('_'), accountId = _a[0], clientId = _a[1];
        return {
            linkTitle: '',
            linkUrl: formValue.promopost.linkAddress,
            accountId: accountId,
            clientId: (clientId === 'null') ? 0 : clientId,
            text: '',
            groupId: 0,
            adSpecifications: [
                {
                    ad_id: this.adId,
                    ad_format: this.currentFormat().ad_format,
                    age_from: formValue.targetingData.ageFrom,
                    age_to: formValue.targetingData.ageTo,
                    campaign_id: formValue.campaignData.campaignId,
                    category1_id: 126,
                    cities: formValue.targetingData.cities.join(),
                    country: formValue.targetingData.country === 'null' ? 0 : formValue.targetingData.country,
                    cpm: formValue.rateData.cpm,
                    cost_type: 1,
                    impressions_limited: (formValue.format.ad_format === 2) ? 1 : null,
                    impressions_limit: (formValue.format.ad_format === 9) ? 1 : null,
                    groups: formValue.targetingData.groups,
                    image: teazerImage,
                    title: teazerText,
                    link_url: formValue.teazer.linkAddress,
                    link_domain: new DomainOnlyPipe().transform(formValue.teazer.linkAddress),
                    name: formValue.promopost.name,
                    retargeting_groups: formValue.targetingData.targetGroups !== null ? formValue.targetingData.targetGroups.filter(function (x) { return x > 0; }).join() : '',
                    retargeting_groups_not: formValue.targetingData.targetGroups !== null ? formValue.targetingData.targetGroupsNot.filter(function (x) { return x > 0; }).join() : '',
                }
            ]
        };
    }; // 1012179702 - 1011699351
    AdFormManagerService.prototype.subscribeSearchGroupForm = function () {
        var _this = this;
        this.searchGroupsForm.valueChanges
            .pipe(debounceTime(500), distinctUntilChanged())
            .subscribe(function (data) {
            if (_this.searchGroupsForm.valid) {
                _this.searchGroups();
            }
        });
        this.searchGroupsForm.controls.countryId
            .valueChanges
            .pipe(debounceTime(500), distinctUntilChanged())
            .subscribe(function (data) {
            var countryId = parseInt(data, 10);
            if (countryId > 0) {
                _this.searchGroupsCities$ = _this.LoadCities(data);
            }
            else {
                _this.searchGroupsCities$ = of([]);
            }
        });
        this.searchGroupsCity.valueChanges
            .pipe(debounceTime(500), distinctUntilChanged())
            .subscribe(function (data) {
            _this.searchGroupsCities$ = _this.LoadCities(_this.searchGroupsForm.controls.countryId.value, data);
        });
    };
    AdFormManagerService.prototype.getLinkDomain = function (link) {
        return new DomainOnlyPipe().transform(link);
    };
    AdFormManagerService.prototype.IsSearchGroupTitleValid = function () {
        return this.searchGroupsForm.controls.title.value.length > 0;
    };
    AdFormManagerService.prototype.IsSearchGroupCountryValid = function () {
        var countryControl = this.searchGroupsForm.controls.countryId;
        if (countryControl) {
            return countryControl.value !== 0;
        }
        return false;
    };
    AdFormManagerService.prototype.subscribeToCitiesSearchStirng = function () {
        var _this = this;
        this.searchCitiesControl
            .valueChanges
            .pipe(distinctUntilChanged(), debounceTime(500), flatMap(function (searchString) { return _this.searchCities(searchString); }))
            .subscribe((function (searchString) {
        }));
    };
    AdFormManagerService.prototype.subscribeFormEvents = function () {
        var promopostGroupControl = this.getControl('promopost', 'groupId');
        var teazerGroupControl = this.getControl('teazer', 'groupId');
        promopostGroupControl.valueChanges.subscribe(this.onChangeGroupId.bind(this));
        teazerGroupControl.valueChanges.subscribe(this.onChangeGroupId.bind(this));
    };
    AdFormManagerService.prototype.onChangeGroupId = function (groupId) {
        if (this.selectedGroup) {
            var selectedGroupAddress = "https://vk.com/" + this.selectedGroup.screen_name;
            this.setLinkUrls(selectedGroupAddress);
        }
    };
    AdFormManagerService.prototype.setLinkUrls = function (linkUrl) {
        var promopostLinkAddressControl = this.getControl('promopost', 'linkAddress');
        var teazerLinkAddressControl = this.getControl('teazer', 'linkAddress');
        if (teazerLinkAddressControl.value !== '') {
            teazerLinkAddressControl.setValue(linkUrl, { emitEvent: false });
        }
        if (promopostLinkAddressControl.value !== '') {
            promopostLinkAddressControl.setValue(linkUrl, { emitEvent: false });
        }
    };
    AdFormManagerService.prototype.SelectFormat = function (format) {
        this.adForm.controls.format.setValue(format);
    };
    AdFormManagerService.prototype.LoadAdminGroups = function () {
        var _this = this;
        return this.adManagerService.GetAdminGroups()
            .pipe(tap(function (groupsResponse) {
            _this.adminGroups = groupsResponse;
            _this.logger.AddBreadcrumb('Администрируемые группы загружены', { countOfGroups: _this.adminGroups.length });
            if (_this.adminGroups && _this.adminGroups.length > 0) {
                var groupId = _this.adminGroups[0]['id'];
                _this.setGroupId(groupId);
            }
            _this.SetLoadingProgress(1);
        }), catchError(function (err) {
            _this.logger.SetTag('Operation', 'LoadAdminGroups');
            _this.logger.AddExtra('ErrorData', err);
            _this.logger.Error('Loading Admin Groups Error', err);
            return throwError(err);
        }), delay(1000));
    };
    AdFormManagerService.prototype.LoadCountries = function () {
        var _this = this;
        return this.adManagerService.GetCountries()
            .pipe(tap(function (countriesResponse) {
            _this.logger.AddBreadcrumb('Страны загружены');
            _this.countries = countriesResponse.items;
            _this.SetLoadingProgress(2);
        }), catchError(function (err) {
            _this.logger.SetTag('Operation', 'LoadCountries');
            _this.logger.AddExtra('ErrorData', err);
            _this.logger.Error('Loading Countries Error', err);
            return throwError(err);
        }), delay(1000));
    };
    AdFormManagerService.prototype.LoadCities = function (countryId, cityQuery) {
        var _this = this;
        if (cityQuery === void 0) { cityQuery = null; }
        return this.adManagerService.GetCities({ countryId: countryId, searchString: cityQuery })
            .pipe(tap(function (citiesResponse) {
            citiesResponse.forEach(function (city) {
                _this.logger.AddBreadcrumb('Города загружены');
                if (!_this.cities.map(function (x) { return x.id; }).includes(city.id)) {
                    _this.cities.push(city);
                }
                else {
                    var city1 = _this.cities.find(function (x) { return x.id === city.id; });
                    if (city1 && city1.region === null) {
                        var cityIndex = _this.cities.map(function (cityItem) { return cityItem.id; }).indexOf(city1.id);
                        _this.cities[cityIndex] = city;
                    }
                }
            });
        }), catchError(function (err) {
            _this.logger.SetTag('Operation', 'LoadCities');
            _this.logger.AddExtra('ErrorData', err);
            _this.logger.Error('Loading Cities Error', err);
            return throwError(err);
        }), delay(1000));
    };
    AdFormManagerService.prototype.LoadData = function () {
        var _this = this;
        console.log('LOADING DATA');
        this.adDataLoading = true;
        return this.LoadAdminGroups()
            .pipe(flatMap(function () { return _this.LoadCountries(); }), flatMap(function () { return _this.LoadCities(_this.countries[0].id); }), flatMap(function () { return _this.LoadAdCabinetData(); }), flatMap(function () { return _this.LoadFullAdData(); }), flatMap(function () { return _this.LoadAdTargetGroups(); }), flatMap(function () { return _this.LoadTargetingStats(); }), tap(function () {
            console.log('DATA LOADING ???');
            _this.adDataLoading = false;
        }));
    };
    AdFormManagerService.prototype.searchCities = function (searchCityName) {
        var countryControl = this.getControl('targetingData', 'country');
        return this.LoadCities(countryControl.value, searchCityName);
    };
    AdFormManagerService.prototype.getControl = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var firstControlName = args.shift();
        var control = this.adForm.controls[firstControlName];
        for (var _a = 0, args_1 = args; _a < args_1.length; _a++) {
            var controlName = args_1[_a];
            if (control) {
                control = control.get(controlName);
            }
        }
        return control;
    };
    AdFormManagerService.prototype.setGroupId = function (groupId) {
        var promopostGroupIdControl = this.getControl('promopost', 'groupId');
        var teazerGroupIdControl = this.getControl('teazer', 'groupId');
        teazerGroupIdControl.setValue(groupId);
        promopostGroupIdControl.setValue(groupId);
    };
    AdFormManagerService.prototype.searchGroups = function () {
        var _this = this;
        this.loadingSearchGroups = true;
        console.log(this.searchGroupsForm.value);
        this.adManagerService.SearchGroups(this.searchGroupsForm.value)
            .subscribe(function (groupsResponse) {
            _this.groups = groupsResponse;
            _this.loadingSearchGroups = false;
        });
    };
    AdFormManagerService.prototype.toggleSelectedRow = function (group) {
        var index = this.selectedGroups.indexOf(group);
        console.log(index, 'SELECTED GROUP');
        if (index >= 0) {
            this.selectedGroups = this.selectedGroups.filter(function (x, i) { return i !== index; });
        }
        else {
            this.selectedGroups.push(group);
        }
        this.groupToggled.next();
        var control = this.getControl('targetingData', 'groups');
        control.setValue(this.selectedGroups.map(function (x) { return x.id; }).join());
    };
    AdFormManagerService.prototype.toggleSelectedAll = function () {
    };
    AdFormManagerService.prototype.LoadTargetingStats = function () {
        var _this = this;
        console.log('LOADING FUCKING TARGET ');
        this.targetingStatsLoading = true;
        var accountControl = this.getControl('campaignData', 'accountId');
        var targetingAuditoryControl = this.getControl('targetingData', 'auditory');
        if (!isNullOrUndefined(accountControl.value)) {
            var _a = accountControl.value.split('_').map(function (x) {
                if (!isNullOrUndefined(x)) {
                    x = parseInt(x, 10);
                }
                return x;
            }), accountId = _a[0], clientId = _a[1];
            var adTargeting = this.getControl('targetingData').value;
            var adFormat = this.currentFormat();
            var linkUrl = this.getLinkUrl();
            console.log(linkUrl, 'FUCKING URL');
            if (linkUrl !== '' && linkUrl !== null) {
                console.log(adTargeting);
                return this.adManagerService.GetTargetingStats({
                    accountId: accountId,
                    adFormat: adFormat.ad_format,
                    clientId: clientId,
                    criteria: {
                        age_from: adTargeting.ageFrom,
                        age_to: adTargeting.ageTo,
                        sex: parseInt(adTargeting.sex, 10),
                        groups: adTargeting.groups,
                        country: adTargeting.country === 'null' ? 0 : adTargeting.country,
                        cities: adTargeting.cities.filter(function (x) { return x > 0; }).join(','),
                        retargeting_groups: adTargeting.targetGroups !== null ? adTargeting.targetGroups.filter(function (x) { return x > 0; }).join() : '',
                        retargeting_groups_not: adTargeting.targetGroupsNot !== null ? adTargeting.targetGroupsNot.filter(function (x) { return x > 0; }).join() : ''
                    },
                    linkDomain: this.getLinkDomain(this.link),
                    linkUrl: this.link
                })
                    .pipe(tap(function (targetingStatsResponse) {
                    console.log('TARGET LOADED');
                    _this.targetingStats = targetingStatsResponse;
                    targetingAuditoryControl.setValue(targetingStatsResponse.audience_count, { emitEvent: false });
                    _this.targetingStatsLoading = false;
                }), catchError(function (err) {
                    _this.logger.SetTag('Operation', 'LoadingTargetingStats');
                    _this.logger.AddExtra('ErrorData', err);
                    _this.logger.Error('Loading Targeting Data Error', err);
                    return throwError(err);
                }));
            }
            else {
                return of({});
            }
        }
        else {
            return of({});
        }
    };
    AdFormManagerService.prototype.getLinkUrl = function () {
        var linkUrl = '';
        var linkAddressControl = null;
        if (this.IsSelectFormatPromopost) {
            linkAddressControl = this.getControl('promopost', 'linkAddress');
        }
        else {
            linkAddressControl = this.getControl('teazer', 'linkAddress');
        }
        linkUrl = linkAddressControl.value;
        return linkUrl;
    };
    AdFormManagerService.prototype.LoadAdCabinetData = function () {
        var _this = this;
        return this.LoadSlots()
            .pipe(flatMap(function () { return _this.LoadAccounts(); }), flatMap(function () { return _this.LoadClients(); }), flatMap(function () { return _this.LoadCampaigns(); }), delay(2000), flatMap(function () { return _this.LoadCampaigns(); }), flatMap(function () { return _this.LoadUserBudget(); }), catchError(function (err) {
            _this.logger.SetTag('Operation', 'LoadAdCabinetData');
            _this.logger.AddExtra('ErrorData', err);
            _this.logger.Error('Loading Ad Cabinet Data Error', err);
            return throwError(err);
        }), share());
    };
    AdFormManagerService.prototype.LoadSlots = function () {
        var _this = this;
        return this.automationVkSlotService.GetSlots()
            .pipe(tap(function (slotsResponse) {
            _this.slots = slotsResponse.data;
            _this.SetLoadingProgress(3);
        }), catchError(function (err) {
            _this.logger.SetTag('Operation', 'LoadSlots');
            _this.logger.AddExtra('ErrorData', err);
            _this.logger.Error('Loading System Slots Error', err);
            return throwError(err);
        }), delay(1000));
    };
    AdFormManagerService.prototype.SetCampaign = function (campaignId) {
        var campaignControl = this.getControl('campaignData', 'campaignId');
        campaignControl.setValue(campaignId, { emitEvent: false });
    };
    AdFormManagerService.prototype.GetAccountId = function (account) {
        return account.account_id + "_" + account.client_id;
    };
    AdFormManagerService.prototype.LoadAccounts = function () {
        var _this = this;
        this.assignAccountLoadingLite = true;
        return this.automationVkService.GetAccounts()
            .pipe(share(), tap(function (accountsResponse) {
            _this.accounts = accountsResponse.data.map(function (_a, i) {
                var account_id = _a.account_id, client_id = _a.client_id, access_role = _a.access_role, account_name = _a.account_name, account_status = _a.account_status, account_type = _a.account_type, is_blocked = _a.is_blocked;
                _this.logger.AddBreadcrumb('Страны загружены');
                return _this.CreateNewAccountViewModel(client_id, account_id, account_name, access_role, account_status, account_type, is_blocked, i);
            });
        }), tap(function () {
            var account = null;
            var accounts = _this.GetAccountsAssignedToSystem();
            if (_this.accountId) {
                account = accounts.find(function (x) { return x.account_id === _this.accountId && x.client_id === _this.clientId; });
            }
            else {
                account = accounts.find(function (x) { return x.is_blocked === false; });
            }
            if (account) {
                _this.setCurrentAccount(account);
            }
            _this.SetLoadingProgress(4);
        }), catchError(function (err) {
            _this.logger.SetTag('Operation', 'LoadAccounts');
            _this.logger.AddExtra('ErrorData', err);
            _this.logger.Error('Loading Accounts Error', err);
            return throwError(err);
        }), flatMap(function () { return _this.AssignAdminAccountToSystem(); }), delay(1000));
    };
    AdFormManagerService.prototype.AssignAdminAccountToSystem = function () {
        var _this = this;
        console.log('ASSIGN ACCOUNTS');
        var result = null;
        console.log(this.isPro);
        if (!this.isPro) {
            var adminAccount = this.GetAdminAccount();
            if (adminAccount) {
                var isAccountAssigned = this.IsAccountAssigned(adminAccount);
                if (!isAccountAssigned) {
                    this.assignAccountLoadingLite = true;
                    result = this.automationVkSlotService.AssignCabinetToSlot({
                        id: adminAccount.account_id,
                        clientId: adminAccount.client_id
                    })
                        .pipe(delay(1000), catchError(function (err) {
                        _this.logger.SetTag('Operation', 'Loading');
                        _this.logger.AddExtra('ErrorData', err);
                        _this.logger.Error('Loading Admin Groups Error', err);
                        return throwError(err);
                    }), flatMap(function () { return _this.LoadAdCabinetData(); }), delay(1000), flatMap(function () { return _this.checkForSystemCampaignsAndCreateIfNotExitis(); }), delay(1000), flatMap(function () { return _this.LoadTargetingStats(); }), delay(1000), tap(function () {
                        _this.assignAccountLoadingLite = false;
                    }));
                }
                else {
                    result = of({});
                }
            }
            else {
                // this.assignAccountLoadingLite = false;
                this.logger.SetTag('Кабинет администратора', 'Отсутствует');
                result = of({});
            }
        }
        else {
            // this.assignAccountLoadingLite = false;
            result = of({});
        }
        // this.assignAccountLoadingLite = false;
        return result;
    };
    AdFormManagerService.prototype.GetAdminAccount = function () {
        return this.accounts.find(function (account) { return account.account_role === 'admin'; });
    };
    AdFormManagerService.prototype.IsAccountAssigned = function (account) {
        var systemSlot = this.slots.find(function (slot) { return slot.bindedCabinetId === account.account_id && slot.bindedClientId === account.client_id; });
        return !isNullOrUndefined(systemSlot);
    };
    AdFormManagerService.prototype.CreateNewAccountViewModel = function (client_id, account_id, account_name, access_role, account_status, account_type, is_blocked, i) {
        var slots = this.slots.find(function (slotsItem) { return slotsItem.bindedClientId === client_id && slotsItem.bindedCabinetId === account_id; });
        return new AccountViewModel(account_id, account_name, access_role, account_status, account_type, (isNullOrUndefined(slots)) ? true : is_blocked, client_id, i);
    };
    AdFormManagerService.prototype.GetAccountsAssignedToSystem = function () {
        var _this = this;
        var accountsBindedToAnySlots = this.accounts.filter(function (account) { return _this.IsAccountAssignedToAnySlot(account); });
        return accountsBindedToAnySlots;
    };
    AdFormManagerService.prototype.IsAccountAssignedToAnySlot = function (account) {
        var _this = this;
        var slot = this.slots.find(function (slot) { return _this.IsAccountAssignedToSlot(slot, account); });
        var isAccountAssignedToSlot = !isNullOrUndefined(slot);
        return isAccountAssignedToSlot;
    };
    AdFormManagerService.prototype.IsAccountAssignedToSlot = function (slot, account) {
        var assignedSlot = slot.bindedCabinetId === account.account_id && slot.bindedClientId === account.client_id;
        return assignedSlot;
    };
    AdFormManagerService.prototype.setCurrentAccount = function (account) {
        var accountIdControl = this.getControl('campaignData', 'accountId');
        accountIdControl.setValue(this.GetAccountId(account), { emitEvent: false });
    };
    AdFormManagerService.prototype.LoadCampaigns = function () {
        var _this = this;
        var accountData = this.getControl('campaignData', 'accountId').value;
        if (!isNullOrUndefined(accountData)) {
            var _a = accountData.split('_').map(function (x) {
                if (!isNullOrUndefined(x)) {
                    x = parseInt(x, 10);
                }
                return x;
            }), accountId = _a[0], clientId = _a[1];
            return this.automationVkService.GetCampaigns({
                accountId: accountId,
                clientId: clientId
            })
                .pipe(tap(function (campaignsResponse) {
                _this.campaigns = campaignsResponse.data.sort(function (a, b) { return b.id - a.id; });
            }), catchError(function (err) {
                _this.logger.SetTag('Operation', 'Loading Campaigns');
                _this.logger.AddExtra('ErrorData', err);
                _this.logger.Error('Loading Campaigns Error', err);
                return throwError(err);
            }), tap(function () {
                _this.SelectCampaign();
                _this.SetLoadingProgress(4);
            }), delay(1000));
        }
        else {
            return of({});
        }
    };
    AdFormManagerService.prototype.SelectCampaign = function () {
        if (this.campaigns && this.campaigns.length > 0) {
            if (this.isPro) {
                var campaignId = null;
                if (this.IsUpdate) {
                    campaignId = this.campaignId;
                }
                else {
                    if (this.displayedCampaigns.length > 0) {
                        campaignId = this.displayedCampaigns[0].id;
                    }
                }
                this.SetCampaign(campaignId);
            }
            else {
                var campaign = this.getSystemCampaign();
                if (campaign) {
                    this.SetCampaign(campaign.id);
                }
            }
        }
        else {
        }
    };
    AdFormManagerService.prototype.setCurrentCampaign = function (campaigns) {
        if (this.isPro) {
        }
        else {
        }
    };
    AdFormManagerService.prototype.subscribeTargetingForm = function () {
        var _this = this;
        var targetingForm = this.getControl('targetingData');
        var format = this.getControl('format');
        var citiesFormatControl = this.getControl('targetingData', 'cities');
        citiesFormatControl.valueChanges.subscribe(function (data) {
            if (data) {
                if (data[0] === 0) {
                    citiesFormatControl.setValue([0], { emitEvent: false });
                }
                if (data.length > 1) {
                    citiesFormatControl.setValue(data.filter(function (val) { return val > 0; }), { emitEvent: false });
                }
                if (data.length === 0) {
                    citiesFormatControl.setValue([0], { emitEvent: false });
                }
            }
        });
        merge(targetingForm.valueChanges, format.valueChanges)
            .pipe(debounceTime(1000), distinctUntilChanged())
            .subscribe(function () {
            _this.LoadTargetingStats().subscribe();
        });
    };
    AdFormManagerService.prototype.IsSearchGroupsCountryDisabled = function () {
        var searchQueryTextControl = this.searchGroupsForm.controls.title;
        return searchQueryTextControl.value === '';
    };
    AdFormManagerService.prototype.IsSearchCitiesDisabled = function () {
        var searchQueryCountryControl = this.searchGroupsForm.controls.title;
        return isNullOrUndefined(searchQueryCountryControl.value);
    };
    AdFormManagerService.prototype.AddPromopostImage = function (image) {
        var promopostImageControl = this.getControl('promopost', 'image');
        var images = promopostImageControl.value;
        images.push(image);
        promopostImageControl.setValue(image);
    };
    AdFormManagerService.prototype.AddTeazerImage = function (image) {
        var teazerImageControl = this.getControl('teazer', 'image');
        var images = teazerImageControl.value;
        images.push(image);
        teazerImageControl.setValue(image);
    };
    AdFormManagerService.prototype.IsStrategyModeAuto = function () {
        var modeControl = this.getControl('rateData', 'mode');
        return modeControl.value === 'auto';
    };
    AdFormManagerService.prototype.LoadUserBudget = function () {
        var _this = this;
        var _a = this.getAccountData(), accountId = _a.accountId, clientId = _a.clientId;
        var loadBudget = this.isAccountAssigned(accountId, clientId);
        if (loadBudget) {
            return this.automationVkService.GetBudget({
                accountId: accountId,
                clientId: clientId
            })
                .pipe(tap(function (budgetResponse) {
                _this.budget = budgetResponse.data;
                _this.configureModerationGroup();
                _this.SetLoadingProgress(5);
            }), catchError(function (err) {
                _this.logger.SetTag('Operation', 'LoadUserBudget');
                _this.logger.AddExtra('ErrorData', err);
                _this.logger.Error('Loading User Budget Error', err);
                return throwError(err);
            }), delay(1000));
        }
        else {
            return of({})
                .pipe(tap(function () {
                _this.SetLoadingProgress(5);
            }));
        }
    };
    AdFormManagerService.prototype.isAccountAssigned = function (accountId, clientId) {
        return accountId !== null &&
            !isNullOrUndefined(this.slots.find(function (x) { return x.bindedCabinetId === accountId && x.bindedClientId === clientId; }));
    };
    AdFormManagerService.prototype.configureModerationGroup = function () {
        var sendModerationControl = this.getControl('moderationData', 'sendModeration');
        if (this.budget === 0 && this.IsSelectedAccountAdmin()) {
            sendModerationControl.disable();
        }
    };
    AdFormManagerService.prototype.CreateAd = function () {
        var _this = this;
        this.loadingCreateAd = true;
        return this.adManagerService.CreateAd(this.getSpecifications())
            .pipe(tap(function (data) {
            var adResponse = data[0];
            if (adResponse.error_code) {
                throw new Error(adResponse.error_desc);
            }
        }), delay(1000), flatMap(function (ad) { return _this.SendModeration(ad); }), flatMap(function (ad) { return _this.StartAfterModeration(ad); }), flatMap(function (ad) { return _this.CreateStrategy(ad); }), map(function (ad) {
            _this.NavigateToAd();
            _this.loadingCreateAd = false;
        }, function (err) {
            _this.loadingCreateAd = false;
            _this.logger.AddExtra('Error data', err);
            _this.logger.Error('Ошибка при создании объявления');
            console.log(err);
        }));
    };
    AdFormManagerService.prototype.UpdateAd = function () {
        var _this = this;
        this.loadingCreateAd = true;
        return this.adManagerService.UpdateAd(this.getSpecifications())
            .pipe(tap(function (data) {
            var adResponse = data[0];
            if (adResponse.error_code) {
                throw new Error(adResponse.error_desc);
            }
        }), delay(1000), flatMap(function (ad) { return _this.SendModeration(ad); }), flatMap(function (ad) { return _this.StartAfterModeration(ad); }), flatMap(function (ad) { return _this.UpdateStrategy(ad); }), map(function (ad) {
            console.log('FINISH LOG', ad);
            _this.NavigateToAd();
            _this.loadingCreateAd = false;
        }, function (err) {
            console.log(err);
            _this.logger.AddExtra('Error data', err);
            _this.logger.Error('Ошибка при реактировании объявления');
            _this.loadingCreateAd = false;
        }));
    };
    AdFormManagerService.prototype.UpdateStrategy = function (ad) {
        console.log('UPDATE STRATEGY');
        var strategyControl = this.getControl('rateData', 'strategyValue');
        var mode = this.getControl('rateData', 'mode').value;
        var result = null;
        console.log('FUCKING STRATEGY');
        console.log(this.isPro, this.strategyCreate, this.RemoveStrategy, this.hasStrategy);
        if (this.isPro) {
            console.log(this.strategyAction, 'ACTION');
            switch (this.strategyAction) {
                case StrategyAction.CREATE_STRATEGY:
                    result = this.CreateStrategy(ad);
                    break;
                case StrategyAction.UPDATE_STRATEGY:
                    result = this.HandleUpdateStrategy(ad);
                    break;
                case StrategyAction.REMOVE_STRATEGY:
                    result = this.RemoveAdStrategy(ad);
                    break;
                case StrategyAction.DO_NOTHING:
                default:
                    result = of([]);
            }
            //      if (!this.RemoveStrategy) {
            //        if (this.strategyCreate) {
            //          result = this.CreateStrategy(ad);
            //        } else {
            //          if (this.hasStrategy) {
            //            result = this.HandleUpdateStrategy(ad);
            //          } else {
            //            result = of([]);
            //          }
            //        }
            //
            //      } else {
            //        if (this.hasStrategy) {
            //          result = this.RemoveAdStrategy(ad);
            //        }
            //      }
        }
        else {
            result = of([]);
        }
        return result;
    };
    AdFormManagerService.prototype.CreateStrategy = function (ad) {
        var _this = this;
        var accountIdValue = this.getControl('campaignData', 'accountId').value;
        var campaignId = this.getControl('campaignData', 'campaignId').value;
        var properties = this.getControl('rateData', 'strategyValue').value;
        var rateType = this.getControl('rateData', 'mode').value;
        var _a = accountIdValue.split('_').map(function (x) { return x === 'null' ? 0 : parseInt(x, 10); }), accountId = _a[0], clientId = _a[1];
        var result = null;
        if (rateType === 'auto') {
            result = this.userStrategyService.CreateUserStrategy({
                accountId: accountId,
                clientId: clientId,
                ads: [ad[0].id],
                properties: Object.keys(properties)
                    .map(function (key) { return ({ key: key, value: properties[key] === null ? null : _this.getRateStrategyValue(key, properties[key]) }); })
                    .reduce(function (curr, _a) {
                    var key = _a.key, value = _a.value;
                    console.log(key, value, 'KEY VALUE LOG');
                    curr[key] = value || strategyDefaultValues[key];
                    return curr;
                }, {}),
                strategyType: 'INTELEGENT_MARK_I_MOD_I',
                updateStep: 10
            });
        }
        else {
            result = of([]);
        }
        return result;
    };
    AdFormManagerService.prototype.SendModeration = function (adResponse) {
        console.log('SEND MODERATION');
        var accountIdValue = this.getControl('campaignData', 'accountId').value;
        var campaignId = this.getControl('campaignData', 'campaignId').value;
        var _a = accountIdValue.split('_').map(function (x) { return x === 'null' ? null : parseInt(x, 10); }), accountId = _a[0], clientId = _a[1];
        var sendModeration = this.getControl('moderationData', 'sendModeration').value;
        if (sendModeration) {
            return this.userStrategyService.ToggleAdStatus({
                accountId: accountId,
                adIds: [adResponse[0].id],
                status: true
            })
                .pipe(delay(1000), map(function () { return adResponse; }));
        }
        else {
            return of(adResponse);
        }
    };
    AdFormManagerService.prototype.NavigateToAd = function () {
        var _a = this.getAccountData(), accountId = _a.accountId, clientId = _a.clientId;
        var campaignId = this.getControl('campaignData', 'campaignId').value;
        if (this.isPro) {
            this.NavigateToProAds(accountId, clientId, campaignId);
        }
        else {
            this.NavigateToLiteAds();
        }
    };
    AdFormManagerService.prototype.NavigateToLiteAds = function () {
        this.router.navigate(['/automation/dashboard']);
    };
    AdFormManagerService.prototype.NavigateToProAds = function (accountId, clientId, campaignId) {
        this.router.navigate(['/automation/ads'], {
            queryParams: {
                account_id: accountId,
                client_id: clientId,
                campaign_id: campaignId
            }
        });
    };
    AdFormManagerService.prototype.StartAfterModeration = function (adResponse) {
        console.log('START AFTER MODERATION');
        var accountIdValue = this.getControl('campaignData', 'accountId').value;
        var campaignId = this.getControl('campaignData', 'campaignId').value;
        var _a = accountIdValue.split('_').map(function (x) { return x === 'null' ? null : parseInt(x, 10); }), accountId = _a[0], clientId = _a[1];
        var sendModeration = this.getControl('moderationData', 'sendModeration').value;
        if (sendModeration) {
            return this.userStrategyService.ToggleAdStatus({
                accountId: accountId,
                adIds: [adResponse[0].id],
                status: false
            })
                .pipe(delay(1000), map(function () { return adResponse; }));
        }
        else {
            return of(adResponse);
        }
    };
    AdFormManagerService.prototype.hasOpenedAdminGroups = function () {
        return this.adminGroups.length > 0;
    };
    AdFormManagerService.prototype.IsValidaAd = function () {
        if (this.IsSelectFormatPromopost) {
            return this.getControl('promopost').valid;
        }
        else {
            return this.getControl('teazer').valid;
        }
    };
    AdFormManagerService.prototype.IsValidTargeting = function () {
        var targetingFormGroup = this.getControl('targetingData');
        return targetingFormGroup.valid;
    };
    AdFormManagerService.prototype.getAccountData = function () {
        var accountControl = this.getControl('campaignData', 'accountId');
        if (accountControl.value !== null) {
            var _a = accountControl.value.split('_').map(function (x) { return x === null || x === 'null' ? null : parseInt(x, 10); }), accountId = _a[0], clientId = _a[1];
            return { accountId: accountId, clientId: clientId };
        }
        else {
            return { accountId: null, clientId: null };
        }
    };
    AdFormManagerService.prototype.ShowActiveAccounts = function () {
        var slotsWithAssignedCabinets = this.slots.filter(function (slot) { return !isNullOrUndefined(slot.bindedCabinetId); });
        return slotsWithAssignedCabinets.length === 0 || this.accounts.length === 0;
    };
    AdFormManagerService.prototype.IsValidCampaignData = function () {
        return this.getControl('campaignData').valid;
    };
    AdFormManagerService.prototype.IsValidRate = function () {
        return this.getControl('rateData').valid;
    };
    AdFormManagerService.prototype.getErrors = function () {
        var errors = [];
        if (!this.IsValidaAd()) {
            errors.push(this.errors.promopost);
        }
        if (!this.IsValidTargeting()) {
            errors.push(this.errors.targetingData);
        }
        if (!this.IsValidCampaignData()) {
            errors.push(this.errors.campaignData);
        }
        if (!this.IsValidRate()) {
            errors.push(this.errors.rate);
        }
        return errors;
    };
    AdFormManagerService.prototype.checkForSystemCampaigns = function () {
        var _this = this;
        var campaign = this.campaigns.find(function (c) { return c.name === _this.GetSystemCampaignName(); });
        console.log(campaign);
        return !isNullOrUndefined(campaign);
    };
    AdFormManagerService.prototype.checkForSystemCampaignsAndCreateIfNotExitis = function () {
        var exists = this.checkForSystemCampaigns();
        var res = null;
        if (exists) {
            res = of(null);
        }
        else {
            res = this.CreateSystemCampaign()
                .pipe(delay(1000));
        }
        return res;
    };
    AdFormManagerService.prototype.CreateSystemCampaign = function () {
        var _this = this;
        console.log('CREATING ');
        var accountControl = this.getControl('campaignData', 'accountId');
        var _a = this.getAccountData(), accountId = _a.accountId, clientId = _a.clientId;
        return this.adManagerService.CreateCampaign({
            accountId: accountId,
            campaigns: [
                {
                    name: this.GetSystemCampaignName(),
                    client_id: clientId,
                    type: this.currentFormat().type,
                    status: 1
                }
            ]
        })
            .pipe(delay(1000), tap(function (data) {
            _this.LoadCampaigns().subscribe();
        }));
    };
    AdFormManagerService.prototype.GetSystemCampaignName = function () {
        console.log(this.IsSelectFormatPromopost, 'IS PROMOPOST');
        if (this.IsSelectFormatPromopost) {
            return this.PROMOPOST_CAMPAIGN_NAME;
        }
        else {
            return this.TEAZER_CAMPAIGN_NAME;
        }
    };
    AdFormManagerService.prototype.getTeazerTextLength = function (i) {
        return this.getControl('teazer', 'text', i.toString()).value.length;
    };
    AdFormManagerService.prototype.getSystemCampaign = function () {
        var _this = this;
        return this.campaigns.find(function (c) { return c.name === _this.GetSystemCampaignName(); });
    };
    AdFormManagerService.prototype.reset = function () {
        this.groups = [];
        this.adminGroups = [];
        this.IsUpdate = false;
        this.adForm.reset({
            format: 'promopost',
            promopost: {
                text: [''],
                image: [],
                name: this.adName
            },
            teazer: {
                text: [''],
                image: [],
                name: this.adName
            },
            targetingData: {
                cities: [0],
                country: 1,
                ageFrom: 0,
                ageTo: 0,
                groups: '',
                sex: 0,
                auditory: 0
            },
            rateData: {
                mode: 'auto',
                cpm: 30
            }
        });
        var moderationControl = this.getControl('moderationData', 'sendModeration');
        var startAfterModeration = this.getControl('moderationData', 'startAfterModeration');
        moderationControl.enable();
        startAfterModeration.enable();
        this.searchGroupsForm.reset({
            title: '',
            countryId: 0,
            cityId: 0
        }, { emitEvent: false });
        this.selectedGroups = [];
        this.groups = [];
    };
    AdFormManagerService.prototype.isGroupSelected = function (group) {
        return this.selectedGroups
            .map(function (x) { return x.id; })
            .includes(group.id);
    };
    AdFormManagerService.prototype.IsAdLayoutDirty = function () {
        var status = null;
        if (this.IsSelectFormatPromopost) {
            status = this.IsPromopostDirty();
        }
        else {
            status = this.IsTeazerDirty();
        }
        return status;
    };
    AdFormManagerService.prototype.IsPromopostDirty = function () {
        var promopost = this.getControl('promopost');
        return promopost.dirty;
    };
    AdFormManagerService.prototype.IsTeazerDirty = function () {
        var teazerControl = this.getControl('teazer');
        return teazerControl.dirty;
    };
    AdFormManagerService.prototype.subscribeFormatChange = function () {
        var _this = this;
        var formatControl = this.getControl('format');
        formatControl.valueChanges.subscribe(function (format) {
            _this.ChangeRateCpmValidators();
            _this.SelectCampaign();
        });
    };
    AdFormManagerService.prototype.ChangeRateCpmValidators = function () {
        var rateCpmControl = this.getControl('rateData', 'cpm');
        rateCpmControl.setValidators([Validators.required, Validators.min(this.MinRateValue), Validators.max(this.MaxRateValue)]);
        console.log(this.MinRateValue, this.MaxRateValue, 'MIN MAX');
        rateCpmControl.setValue(this.currentFormat().min_cpm, { eventEmit: false });
    };
    AdFormManagerService.prototype.LoadBidRanges = function () {
        var _this = this;
        this.adManagerService.GetAdRates()
            .subscribe(function (rates) {
            _this.rates = rates;
            _this.ChangeRateCpmValidators();
        });
    };
    AdFormManagerService.prototype.LoadAdData = function () {
        var _this = this;
        return this.automationVkService.GetAds(this.getAdParams())
            .pipe(tap(function (adsResponse) {
            if (_this.CheckForAdData(adsResponse)) {
                var ad_1 = adsResponse.data[0];
                var adFormat = _this.adFormats.find(function (x) { return x.ad_format === ad_1.ad_format; });
                _this.adForm.patchValue({
                    format: adFormat.value,
                    teazer: {
                        name: ad_1.name
                    },
                    promopost: {
                        name: ad_1.name
                    },
                    rateData: {
                        cpm: ad_1.cpm / 100,
                    }
                }, { emitEvent: false });
            }
            else {
                _this.ThrowNotFoundAdError();
            }
        }), tap(function () {
            _this.SetLoadingProgress(6);
        }), catchError(function (err) {
            _this.logger.SetTag('Operation', 'LoadAdData');
            _this.logger.AddExtra('ErrorData', err);
            _this.logger.Error('Loading Ad Error', err);
            return throwError(err);
        }), delay(2000));
    };
    AdFormManagerService.prototype.ThrowNotFoundAdError = function () {
        throw new Error("Ad with in account " + this.accountId + " and campaign " + this.campaignId + " with id " + this.adId + " not found");
    };
    AdFormManagerService.prototype.CheckForAdData = function (adsResponse) {
        return adsResponse.data && adsResponse.data.length > 0;
    };
    AdFormManagerService.prototype.getAdParams = function () {
        return {
            accountId: this.accountId,
            clientId: this.clientId === 0 ? null : this.clientId,
            campaignIds: [this.campaignId],
            adIds: [this.adId],
            offset: 0,
            limit: 0,
            includeDeleted: false
        };
    };
    AdFormManagerService.prototype.LoadAdLayout = function () {
        var _this = this;
        return this.automationVkService.GetAdsLayout(this.getAdParams())
            .pipe(tap(function (adsLayoutResponse) {
            if (_this.CheckForAdData(adsLayoutResponse)) {
                var adLayout = adsLayoutResponse.data[0];
                if (_this.currentFormat().ad_format === 2) {
                    _this.adForm.patchValue({
                        teazer: {
                            text: [adLayout.title],
                            linkAddress: adLayout.link_url,
                            image: [adLayout.image_src]
                        }
                    }, { emitEvent: false });
                }
                else {
                    _this.promopostWallLink = adLayout.link_url;
                }
            }
            else {
                _this.ThrowNotFoundAdError();
            }
        }), tap(function () { return _this.SetLoadingProgress(7); }), catchError(function (err) {
            _this.logger.SetTag('Operation', 'LoadAdLayout');
            _this.logger.AddExtra('ErrorData', err);
            _this.logger.Error('Loading Ad Layout Error', err);
            return throwError(err);
        }), delay(2000));
    };
    AdFormManagerService.prototype.LoadAdWall = function () {
        var _this = this;
        var obs = null;
        if (this.IsSelectFormatPromopost) {
            var wallId = this.promopostWallLink.replace('http://vk.com/wall', '');
            obs = this.automationVkService.GetWallById(wallId)
                .pipe(tap(function (adsWalls) {
                if (_this.CheckForAdData(adsWalls)) {
                    var wall = adsWalls.data[0];
                    if (wall) {
                        _this.PostId = wall.id;
                        var attachmentLink = _this.GetAttachmentLink(wall);
                        if (attachmentLink !== null) {
                            var linkAddress = attachmentLink.link.url;
                            var title = attachmentLink.link.title;
                            var image = _this.GetLinkImage(attachmentLink);
                            var value = {
                                promopost: {
                                    text: [wall.text],
                                    linkTitle: title,
                                    linkAddress: linkAddress,
                                    image: [image]
                                }
                            };
                            console.log(value);
                            _this.adForm.patchValue(value, { emitEvent: false });
                        }
                    }
                }
            }), catchError(function (err) {
                _this.logger.SetTag('Operation', 'LoadAdWall');
                _this.logger.AddExtra('ErrorData', err);
                _this.logger.Error('Loading Ad Wall Error', err);
                return throwError(err);
            }), tap(function () { return _this.SetLoadingProgress(8); }), delay(2000));
        }
        else {
            obs = of(null)
                .pipe(tap(function () { return _this.SetLoadingProgress(8); }));
        }
        return obs;
    };
    AdFormManagerService.prototype.LoadAdTargeting = function () {
        var _this = this;
        return this.automationVkService.GetAdsTargeting(this.getAdParams())
            .pipe(tap(function (targetingResponse) {
            if (_this.CheckForAdData(targetingResponse)) {
                var target = targetingResponse.data[0];
                if (target) {
                    _this.adForm.patchValue({
                        targetingData: {
                            cities: target.cities ? target.cities.split(',').map(function (x) { return parseInt(x, 10); }) : [0],
                            groups: target.groups,
                            country: target.country,
                            ageTo: target.age_to,
                            ageFrom: target.age_from,
                            sex: parseInt(target.sex, 10),
                            targetGroups: target.retargeting_groups_not !== null ? target.retargeting_groups.split(',').map(function (x) { return parseInt(x, 10); }) : [],
                            targetGroupsNot: target.retargeting_groups_not !== null ? target.retargeting_groups_not.split(',').map(function (x) { return parseInt(x, 10); }) : []
                        }
                    }, { emitEvent: false });
                }
            }
        }), catchError(function (err) {
            _this.logger.SetTag('Operation', 'LoadAdTargeting');
            _this.logger.AddExtra('ErrorData', err);
            _this.logger.Error('Loading Ad Targeting Error', err);
            return throwError(err);
        }), tap(function () { return _this.SetLoadingProgress(9); }), delay(2000));
    };
    AdFormManagerService.prototype.LoadStrategy = function () {
        var _this = this;
        return this.userStrategyService.GetStrategiesByAdAndAccountIds({
            accountId: this.accountId,
            clientId: this.clientId,
            viewModel: { adIds: [this.adId] }
        })
            .pipe(tap(function (userStrategiesResponse) {
            var adRateControl = _this.getControl('rateData', 'mode');
            if (userStrategiesResponse && userStrategiesResponse.length > 0) {
                _this.strategyAction = StrategyAction.UPDATE_STRATEGY;
                adRateControl.setValue('auto', { emitEvent: false });
                _this.strategyCreate = true;
                var strategy = userStrategiesResponse[0];
                console.log(strategy);
                strategy.inputs.forEach(function (strategyInput) {
                    var userStrategyInputControl = _this.getControl('rateData', 'strategyValue', strategyInput.type.code);
                    if (userStrategyInputControl && strategyInput.value !== null) {
                        userStrategyInputControl.patchValue(_this.getDisplayedStrategVlaue(strategyInput.type.code, strategyInput.value), { emitEvent: false });
                    }
                });
            }
            else {
                adRateControl.setValue('custom', { emitEvent: false });
                _this.strategyAction = StrategyAction.DO_NOTHING;
            }
        }), catchError(function (err) {
            _this.logger.SetTag('Operation', 'LoadStrategy');
            _this.logger.AddExtra('ErrorData', err);
            _this.logger.Error('Loading Strategy Error', err);
            return throwError(err);
        }), tap(function () { return _this.SetLoadingProgress(10); }));
    };
    AdFormManagerService.prototype.LoadFullAdData = function () {
        var _this = this;
        var data = null;
        console.log('IS UPDATE ', this.IsUpdate);
        if (this.IsUpdate) {
            data = this.LoadAdData()
                .pipe(flatMap(function () { return _this.LoadAdLayout(); }), flatMap(function () { return _this.LoadAdWall(); }), flatMap(function () { return _this.LoadAdTargeting(); }), flatMap(function () { return _this.LoadAdGroups(); }), flatMap(function () { return _this.LoadStrategy(); }), tap(function () {
                _this.ChangeRateCpmValidators();
            }));
        }
        else {
            data = of(null);
        }
        return data;
    };
    AdFormManagerService.prototype.LoadAdGroups = function () {
        var _this = this;
        var groupsControl = this.getControl('targetingData', 'groups');
        var groupIds = groupsControl.value !== null ? groupsControl.value.split(',').map(function (groupId) { return parseInt(groupId, 10); }) : null;
        if (this.currentFormat().ad_format === 9 && groupIds && groupIds.length > 0) {
            return this.adManagerService.GetGroupsById(groupIds)
                .pipe(tap(function (groupsResponse) {
                _this.selectedGroups = groupsResponse;
                _this.groupToggled.next();
            }), delay(2000));
        }
        else {
            return of(null);
        }
    };
    AdFormManagerService.prototype.GetAttachmentLink = function (wall) {
        var linkAttachment = wall.attachments.find(function (attachment) { return attachment.type === 'link'; });
        return linkAttachment;
    };
    AdFormManagerService.prototype.GetLinkImage = function (attachmentLink) {
        if (attachmentLink && attachmentLink.link && attachmentLink.link.photo) {
            var linkImageSizes = attachmentLink.link.photo.sizes;
            var sizeKeys = linkImageSizes.sort(function (imageSizeA, imageSizeB) { return imageSizeB.width - imageSizeA.width; });
            return sizeKeys[0].url;
        }
        else {
            return '';
        }
    };
    AdFormManagerService.prototype.InitAdData = function () {
        var _this = this;
        this.accountId = null;
        this.clientId = null;
        this.campaignId = null;
        this.adId = null;
        this.route.queryParams.subscribe(function (_a) {
            var client_id = _a.client_id, account_id = _a.account_id, campaign_id = _a.campaign_id, adId = _a.adId;
            var clientId = (client_id === 'NaN' || isNullOrUndefined(client_id)) ? null : parseInt(client_id, 10);
            _this.accountId = parseInt(account_id, 10);
            _this.clientId = clientId;
            _this.campaignId = parseInt(campaign_id, 10);
            _this.adId = parseInt(adId, 10);
        });
    };
    AdFormManagerService.prototype.HandleUpdateStrategy = function (ad) {
        var _this = this;
        var accountIdValue = this.getControl('campaignData', 'accountId').value;
        var campaignId = this.getControl('campaignData', 'campaignId').value;
        var properties = this.getControl('rateData', 'strategyValue').value;
        var rateType = this.getControl('rateData', 'mode').value;
        var _a = accountIdValue.split('_').map(function (x) { return x === 'null' ? 0 : parseInt(x, 10); }), accountId = _a[0], clientId = _a[1];
        return this.userStrategyService.UpdateUserStrategies({
            accountId: accountId,
            clientId: clientId,
            ads: [ad[0].id],
            properties: Object.keys(properties)
                .map(function (key) { return ({ key: key, value: properties[key] === null ? null : _this.getRateStrategyValue(key, properties[key]) }); })
                .reduce(function (curr, _a) {
                var key = _a.key, value = _a.value;
                curr[key] = value;
                return curr;
            }, {}),
            strategyType: 'INTELEGENT_MARK_I_MOD_I',
            updateStep: 10
        });
    };
    AdFormManagerService.prototype.subscribeRateModeData = function () {
        var _this = this;
        var rateData = this.getControl('rateData', 'mode');
        console.log('RATE MODE DATA SUBSCRIBE');
        rateData.valueChanges
            .pipe(startWith('auto'), pairwise())
            .subscribe(function (_a) {
            var prev = _a[0], curr = _a[1];
            if (prev !== curr && curr === 'custom') {
                _this.strategyAction = StrategyAction.REMOVE_STRATEGY;
            }
            else {
                if (_this.hasStrategy) {
                    _this.strategyAction = StrategyAction.UPDATE_STRATEGY;
                }
                else {
                    _this.strategyAction = StrategyAction.CREATE_STRATEGY;
                }
            }
        });
    };
    AdFormManagerService.prototype.RemoveAdStrategy = function (ad) {
        var accountId = this.getAccountData().accountId;
        return this.userStrategyService.RemoveStrategy({
            accountId: accountId,
            adId: ad[0].id
        });
    };
    AdFormManagerService.prototype.getRateStrategyValue = function (key, value) {
        var modifyFunction = this.modifyFunctions[key];
        if (modifyFunction) {
            value = modifyFunction(value);
        }
        return value;
    };
    AdFormManagerService.prototype.getDisplayedStrategVlaue = function (key, value) {
        var displayFunction = this.displayFunctions[key];
        if (displayFunction) {
            value = displayFunction(value || strategyDefaultValues[key]);
        }
        return value || strategyDefaultValues[key];
    };
    AdFormManagerService.prototype.EnableProMode = function () {
        this.isPro = true;
        this.SelectCampaign();
    };
    AdFormManagerService.prototype.ngOnInit = function () {
        this.LoadBidRanges();
        this.adName = "\u041E\u0431\u044A\u044F\u0432\u043B\u0435\u043D\u0438\u0435 " + moment(new Date()).format('DD.MM.YYYY HH:mm:ss');
        this.InitAdData();
        this.subscribeToCitiesSearchStirng();
        this.subscribeSearchGroupForm();
        this.subscribeFormEvents();
        this.subscribeTargetingForm();
        this.subscribeAccountForm();
        this.subscribeFormatChange();
        this.subscribeRateModeData();
    };
    AdFormManagerService.prototype.LoadClients = function () {
        var _this = this;
        console.log('campaigns loaded');
        var agencyAccounts = this.accounts.filter(function (account) { return account.account_type === 'agency'; });
        var hasAgencyAccounts = agencyAccounts.length > 0;
        if (hasAgencyAccounts && this.isPro) {
            return of(agencyAccounts)
                .pipe(concatAll(), map(function (account, i) { return of(account).pipe(delay(2000 * i)); }), flatMap(function (account) { return account; }), flatMap(function (account) { return _this.LoadClient(account); }), delay(2000), tap(function () {
                console.log('LOADING CLIENTS');
            }), tap(function () {
                console.log(_this.accounts);
                _this.accounts = _this.accounts.map(function (x) {
                    x.is_blocked = !_this.IsAccountAssigned(x);
                    return x;
                });
                var accounts = _this.GetAccountsAssignedToSystem();
                console.log(accounts);
                if (accounts && accounts.length > 0) {
                    _this.setCurrentAccount(accounts[0]);
                }
                console.log(_this.accounts, 'WAS UPDATED');
            }), catchError(function (err) {
                console.log(err);
                return err;
            }));
        }
        else {
            return of([]);
        }
    };
    AdFormManagerService.prototype.LoadClient = function (account) {
        var _this = this;
        return this.automationVkService.getClients(account.account_id)
            .pipe(tap(function (accountsResponse) {
            accountsResponse.data.forEach(function (_a, i) {
                var account_id = _a.account_id, name = _a.name, id = _a.id;
                var slots = _this.slots.find(function (slotsItem) { return slotsItem.bindedClientId === id && slotsItem.bindedCabinetId === account_id; });
                var acc = new AccountViewModel(account.account_id, name, account.role, account.account_status, account.account_type, account.is_blocked || isNullOrUndefined(slots), id, i);
                _this.accounts.push(acc);
            });
        }), catchError(function (err) {
            _this.logger.SetTag('Operation', 'Loading Client');
            _this.logger.AddExtra('ErrorData', err);
            _this.logger.Error('Loading Clients Error', err);
            return throwError(err);
        }), delay(2000));
    };
    AdFormManagerService.prototype.ToggleAll = function () {
        if (!this.IsAllToggled()) {
            var maxCount = 100;
            var i = 0;
            while (this.selectedGroups.length < maxCount) {
                if (!this.isGroupSelected(this.groups[i])) {
                    this.selectedGroups.push(this.groups[i]);
                }
                i++;
            }
            this.groupToggled.next();
            console.log(i);
        }
        else {
            this.selectedGroups = [];
        }
    };
    AdFormManagerService.prototype.IsAllToggled = function () {
        return this.selectedGroups.length === 100;
    };
    AdFormManagerService.prototype.IsRowDisabled = function (group) {
        return this.IsAllToggled() && !this.isGroupSelected(group);
    };
    AdFormManagerService.prototype.countOfSelected = function () {
        return this.selectedGroups.length;
    };
    AdFormManagerService.prototype.HasAssignedAccounts = function () {
        var _a = this.getAccountData(), accountId = _a.accountId, clientId = _a.clientId;
        if (accountId !== null) {
            return this.isAccountAssigned(accountId, clientId);
        }
        else {
            return false;
        }
    };
    /**
     * Показывает имеет ли пользователь Личный кабинет рекламодателя (access_role = admin)0
     * @constructor
     */
    AdFormManagerService.prototype.HasAssignedAdminAccount = function () {
        var adminAccount = this.GetAdminAccount();
        if (!isNullOrUndefined(adminAccount)) {
            return this.IsAccountAssigned(adminAccount);
        }
        return false;
    };
    AdFormManagerService.prototype.RefreshAccountsData = function () {
        var _this = this;
        return this.LoadSlots()
            .pipe(flatMap(function () { return _this.LoadAccounts(); }), flatMap(function () { return _this.AssignAdminAccountToSystem(); }));
    };
    AdFormManagerService.prototype.IsSelectedAccountAdmin = function () {
        var adminAccount = this.GetAdminAccount();
        var _a = this.getAccountData(), accountId = _a.accountId, clientId = _a.clientId;
        return adminAccount && adminAccount.account_id === accountId && adminAccount.client_id === clientId;
    };
    AdFormManagerService.prototype.LoadAdTargetGroups = function () {
        var _this = this;
        var _a = this.getAccountData(), accountId = _a.accountId, clientId = _a.clientId;
        console.log(accountId, clientId);
        return this.adManagerService.GetTargetingGroup({
            accountId: accountId,
            clientId: clientId
        })
            .pipe(delay(2000), retry(5), tap(function (targetGroups) {
            _this.targetingGroups = targetGroups
                .sort(function (a, b) { return b.id - a.id; });
        }), catchError(function (err) {
            if (err) {
                var _a = _this.getAccountData(), accountId_1 = _a.accountId, clientId_1 = _a.clientId;
                var assignedToSystemAccounts = _this.GetAccountsAssignedToSystem();
                var countOfAssignedCabinets = assignedToSystemAccounts !== null ? assignedToSystemAccounts.length : 0;
                var debugData = {
                    count_of_assigned_to_system_cabinets: countOfAssignedCabinets,
                    accountId: accountId_1,
                    clientId: clientId_1
                };
                _this.logger.AddExtra('debug', debugData);
                _this.logger.Error(err);
                return throwError(err);
            }
        }));
    };
    Object.defineProperty(AdFormManagerService.prototype, "SelectedTargetGroups", {
        get: function () {
            var selected = this.getControl('targetingData', 'targetGroupsNot').value;
            if (selected) {
                return this.targetingGroups.filter(function (x) { return !selected.includes(x.id); });
            }
            else {
                return this.targetingGroups;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdFormManagerService.prototype, "SelectedTargetGroupsNot", {
        get: function () {
            var selected = this.getControl('targetingData', 'targetGroups').value;
            if (selected) {
                return this.targetingGroups.filter(function (x) {
                    return !selected.includes(x.id);
                });
            }
            else {
                return this.targetingGroups;
            }
        },
        enumerable: true,
        configurable: true
    });
    AdFormManagerService.ngInjectableDef = i0.defineInjectable({ factory: function AdFormManagerService_Factory() { return new AdFormManagerService(i0.inject(i1.AdManagerService), i0.inject(i2.AutomationVkService), i0.inject(i3.AutomationVkSlotService), i0.inject(i4.UserStrategiesService), i0.inject(i5.Router), i0.inject(i5.ActivatedRoute), i0.inject(i6.LoggingService)); }, token: AdFormManagerService, providedIn: "root" });
    return AdFormManagerService;
}());
export { AdFormManagerService };
