import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {IntegrationFormService} from '../../../services/integration-form.service';
import {StoreService} from '../../../../../services/store.service';
import {Campaign} from '../../../../../../api/models/campaign';
import {VkFormModel} from '../../../../../models/vk-form.model';

@Component({
  selector: 'app-vk-lead-forms',
  templateUrl: './vk-lead-forms.component.html',
  styleUrls: ['./vk-lead-forms.component.scss']
})
export class VkLeadFormsComponent implements OnInit {

  public form: FormGroup;
  public accountId = new FormControl();
  public campaignId = new FormControl();

  public accounts = [];
  public campaigns: Campaign[];
  public accountsLoading: boolean = false;
  public campaignsLoading: boolean = false;
  public forms: VkFormModel[];
  public formsLoading: boolean = false;

  constructor(
    private integrationFormService: IntegrationFormService,
    private store: StoreService
  ) {
  }

  ngOnInit() {
    this.form = this.integrationFormService.form.get('vk_forms') as FormGroup;
    console.log(this.form);
    this.accountsLoading = true;
    this.store.LoadVkAccountData()
      .subscribe(() => {
        this.accounts = this.store.assignedAccounts;
        this.accountsLoading = false;
      });

    this.accountId.valueChanges
      .subscribe(data => {
        this.LoadCampaigns(data.account_id, data.client_id);
      });

    this.campaignId.valueChanges
      .subscribe(data => {
        this.formsLoading = true;
        this.store.LoadVkForms(this.accountId.value.account_id, this.accountId.value.client_id, this.campaignId.value.id)
          .subscribe((d: VkFormModel[]) => {
            console.log(d, 'DATA');
            this.forms = d;
            this.formsLoading = false;
          });
      });

    this.form.valueChanges
      .subscribe(data => {
        console.log(data);
      })
  }

  private LoadCampaigns(accountId: number, clientId: number) {
    this.campaignsLoading = true;
    this.store.LoadCampaigns(accountId, clientId)
      .subscribe(campaigns => {
        this.campaigns = campaigns.filter(x => x.type === 'promoted_posts');
        this.campaignsLoading = false;
      });
  }
}
