<ng-container *ngIf="!(isLoading$ | async); else loadingScreen">
  <form class="remarketing-settings" [formGroup]="form" [hidden]="(groups$ | async).length === 0">
    <div class="remarketing-settings__type" *ngIf="containsPhones && containsEmails">
      <vk-form-field [orientation]="'vertical'">
        <vk-label>
          Выберите тип списка пользователей
          <app-suggestion-info code="MT_REMARKETING_GROUPS_TYPE_OF_CLIENTS"></app-suggestion-info>
        </vk-label>
        <mat-radio-group
          class="radio-group"
          vkInput
          formControlName="type"
          [useStyles]="false"
        >
          <div class="radio-group__item">
            <mat-radio-button [value]="remarketingBasesTypes.PHONES" i18n="@@PHONE_BASES_TITLE">
              Список телефонных номеров
            </mat-radio-button>
          </div>

          <div class="radio-group__item">
            <mat-radio-button [value]="remarketingBasesTypes.EMAILS" i18n="@@EMAIL_BASES_TITLE">
              Список емейлов
            </mat-radio-button>
          </div>
        </mat-radio-group>
      </vk-form-field>
    </div>
    <div class="remarketing-settings__split-by-pipelines" *ngIf="(isPipelinesCheckboxVisible$ | async)">
      <mat-checkbox formControlName="split_by_pipeline_statuses">
        Разбить по этапам воронки продаж
      </mat-checkbox>
      <app-suggestion-info code="MT_SPLI_BY_PIPELINES"></app-suggestion-info>
    </div>

    <div class="remarketing-settings__pipelines" formGroupName="pipeline_statuses">
      <ng-container>
        <ng-container *ngIf="splitByPipelines; else withoutSpliting">
          <ng-container *ngIf="!(isSelectedUonTravel$ | async) ; else fuckingUonTravelTemplate">
            <ng-container *ngIf="hasLeads">
              <div class="ad-network-import__retargeting-groups-heading" [hidden]="hasLeads">Лиды</div>
              <ng-container *ngFor="let status of leadPipelines$ | async">
                <app-pipeline-status-selector
                  [statusName]="status.name"
                  [options]="groups$ | async"
                  [control]="getControlByPipelineId(status.type + '_' + status.id)"
                  [selectedRemarketingGroups]="selectedRemarketingGroups"
                  suggestionCode="MT_PIPELINE_STATUS_LEADS"
                ></app-pipeline-status-selector>
              </ng-container>
              <br><br>
            </ng-container>

            <div class="ad-network-import__retargeting-groups-heading" [hidden]="!hasLeads">Сделки</div>
            <ng-container *ngFor="let status of pipelinesStatuses$ | async">
              <app-pipeline-status-selector
                [statusName]="status.name"
                [options]="groups$ | async"
                [control]="getControlByPipelineId(status.type + '_' + status.id)"
                [selectedRemarketingGroups]="selectedRemarketingGroups"
                suggestionCode="MT_PIPELINE_STATUS"
              >
              </app-pipeline-status-selector>
            </ng-container>
          </ng-container>
          <ng-template #fuckingUonTravelTemplate>
            <div class="ad-network-import__retargeting-groups-heading" [hidden]="!hasLeads"><strong>Обращения</strong></div>
            <ng-container *ngFor="let status of pipelinesStatuses$ | async">
              <app-pipeline-status-selector
                [statusName]="status.name"
                [options]="groups$ | async"
                [control]="getControlByPipelineId(status.type + '_' + status.id)"
                [selectedRemarketingGroups]="selectedRemarketingGroups"
                suggestionCode="MT_PIPELINE_STATUS"
              >
              </app-pipeline-status-selector>
            </ng-container>
            <ng-container *ngIf="hasLeads">
              <div class="ad-network-import__retargeting-groups-heading" [hidden]="!hasLeads"><strong>Заявки</strong></div>
              <ng-container *ngFor="let status of leadPipelines$ | async">
                <app-pipeline-status-selector
                  [statusName]="status.name"
                  [options]="groups$ | async"
                  [control]="getControlByPipelineId(status.type + '_' + status.id)"
                  [selectedRemarketingGroups]="selectedRemarketingGroups"
                  suggestionCode="MT_PIPELINE_STATUS_LEADS"
                ></app-pipeline-status-selector>
              </ng-container>
              <br><br>
            </ng-container>
          </ng-template>
        </ng-container>
        <ng-template #withoutSpliting>
          <app-pipeline-status-selector
            [statusName]="withoutSplittingLabel"
            [options]="groups$ | async"
            [control]="getControlByPipelineId(null)"
            [selectedRemarketingGroups]="selectedRemarketingGroups"
            [suggestionCode]="withoutSplitSuggestionCode()"
          ></app-pipeline-status-selector>
        </ng-template>
      </ng-container>
      <ng-template #emptyGroups>
        Аудитории ремаркетинга выбранного типа отсутствуют
      </ng-template>
    </div>
  </form>
  <div class="remarketing-settings__empty remarketing-settings-empty" [hidden]="(groups$ | async).length !== 0">
    <div class="remarketing-settings-empty__message">
      У вас нет ни одного списка пользователей в myTarget. Перейдите по этой <a
      href="https://target.my.com/segments/users_list">ссылке</a> и создайте список пользователей.
      Поддерживаются следующие типы списков: <br><br>
      1. Список телефонных номеров; <br>
      2. Список емейлов. <br>
      <br>
      Как только создадите список, нажмите кнопку "Обновить".
    </div>
    <div class="remarketing-settings-empty__actions">
      <button class="vk-standart-button" (click)="LoadRemarketingGroups()">Обновить</button>
    </div>
  </div>
</ng-container>
<ng-template #loadingScreen>
  <app-loading-screen>
    Подождите, данные загружаются
  </app-loading-screen>
</ng-template>
