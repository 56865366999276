<div class="payments-successed-wrapper">
  <div class="payments-successed-container">
    <div class="payments-sucessed-header">
      Спасибо!
    </div>

    <div class="payments-sucessed-message">
      Ваш платеж проведён.<br>
      Как только мы получим средства доступ будет автоматически продлён.
    </div>

    <div class="payments-sucessed-button">
      <button class="vk-standart-button" (click)="GoToDashboard()">
        Продолжить работу
      </button>
    </div>
  </div>
</div>
