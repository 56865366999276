import {Injectable} from '@angular/core';
import {IntegrationAccessService} from "../../../api/services";
import {Router} from "@angular/router";
import {IntegrationSessionStore} from "./integration-session.store";

@Injectable({
  providedIn: 'root'
})
export class IntegrationSessionService {

  constructor(
    private integrationAccessService: IntegrationAccessService,
    private router: Router,
    private store: IntegrationSessionStore
  ) {
  }

  public LoadIntegrationSessionData() {
    this.integrationAccessService
      .GetCurrentAccessSession()
      .subscribe((response) => {
        if (response) {
          if (response.data) {
            try {
              const accessTo =  new Date(response.data.accessTo);
              accessTo.setHours(0,0,0,0);

              const currentDate = new Date();
              currentDate.setHours(0,0,0,0);

              const hasAccess = currentDate < accessTo;

              if (hasAccess) {
                this.fillSessionData(response.data);

                return;
              }
            } catch(exception) {
              console.error(exception);
            }
          }
        }

        this.NavigateToAccessDeniedPage();
      })
  }

  private fillSessionData(data) {
    this.store.update(state => ({
      ...state,
      session: data
    }));
  }

  private NavigateToAccessDeniedPage() {
    this.router.navigate(['/integrations/access-denied']);
  }
}
