import { OnInit } from '@angular/core';
import { PaymentsService } from "../../services/payments.service";
var PaymentsTariffHeaderComponent = /** @class */ (function () {
    function PaymentsTariffHeaderComponent(paymentsService) {
        this.paymentsService = paymentsService;
    }
    Object.defineProperty(PaymentsTariffHeaderComponent.prototype, "price", {
        get: function () {
            var price = this.paymentsService.GetPriceByTariffPlan(this.tariff.id);
            console.log(price, 'PRICE');
            if (price) {
                return price.price;
            }
            else {
                return 0;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaymentsTariffHeaderComponent.prototype, "customPrice", {
        get: function () {
            var price = this.paymentsService.GetPriceByTariffPlan(this.tariff.id);
            if (price) {
                return price['customPrice'];
            }
            else {
                return null;
            }
        },
        enumerable: true,
        configurable: true
    });
    PaymentsTariffHeaderComponent.prototype.ngOnInit = function () {
    };
    return PaymentsTariffHeaderComponent;
}());
export { PaymentsTariffHeaderComponent };
