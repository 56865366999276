<div class="easy-ads-container">
  <div class="vk-container">
    <div class="easy-ads-heading-strip">
      <div class="easy-ads-heading-strip__item">
        <div class="easy-ads-page-header">
          Мои объявления
        </div>
      </div>
      <div class="easy-ads-heading-strip__item easy-ads-heading-strip__item_push-right">
        <a id="easy-ads-dashboard-instruction" class="vk-standart-button vk-standart-button_cancel"
           href="https://postmonitor.ru/blog/instrukciya-po-sozdaniyu-obyavleniya-v-vkontakte-cherez-servis-postmonitor-dlya-novichkov"
           target="_blank" rel="noopener">
          Инструкция по созданию объявлений
        </a>
      </div>
      <div class="easy-ads-heading-strip__item">
        <button id="easy-ads-dashboard-create-ad-button-top" class="vk-standart-button"
                [disabled]="easyAdsDataService.accessExpired"
                onclick="ym(45402093, 'reachGoal','easy-dashboard-create-ad-top-button-click');"
                (click)="CreateAdClick()">
          Создать объявление
        </button>
      </div>
    </div>
  </div>

  <div class="vk-container" *ngIf="showBudgetLoading">
    Баланс в ВКонтакте:
    <!--Происходит ли загрузка данных о бюджете?-->
    <ng-container *ngIf="easyAdsDataService.budgetIsLoading; else budgetDataRecieved">
      загрузка...
    </ng-container>
    <!--Загрузка данных о бюджете закончена-->
    <ng-template #budgetDataRecieved>
      <!--Произошла ли ошибка во время загрузки данных о бюджете?-->
      <ng-container *ngIf="easyAdsDataService.budgetRerievingError !== null; else noErrorDuringBudgetLoading">
        <span class="easy-ads-service-process-notification-text easy-ads-service-process-notification-text_error">
          {{easyAdsDataService.budgetRerievingError}}
        </span>
      </ng-container>
      <!--Во время загрузки данных о бюджете не произошло ошибок-->
      <ng-template #noErrorDuringBudgetLoading>
        <strong>{{easyAdsDataService.budget | number: '1.0-2'}} руб. </strong> <a href="https://vk.com/ads?act=payments"
                                                                                  target="_blank"
                                                                                  rel="noopener nofollow"
                                                                                  title="Перейти на сайт ВКонтакте для пополнения баланса рекламного кабинета">пополнить</a>

        <app-generic-tooltip header="Зачем и как пополнить?" text="ВКонтакте показывает объявления взимая плату за это, поэтому, для того, чтобы можно было показывать объявление пользователям ВКонтакте, необходимо пополнить бюджет вашего рекламного кабинета ВКонтакте. Пополнение бюджета происходит на странице ВКонтакте и данные средства будут списываться когда объявление будет показываться пользователям.
Если бюджет не пополнен, то ВКонтакте не будет показывать объявление, также невозможно отправить объявление на модерацию и включить объявление."></app-generic-tooltip>
      </ng-template>
    </ng-template>
  </div>

  <div class="vk-container">
    Тариф в PostMonitor:
    <ng-container *ngIf="functionalyAccessDataIsLoading; else functionalityDataLoaded">Загрузка...</ng-container>

    <ng-template #functionalityDataLoaded>
      <ng-container *ngIf="functionalyAccessData; else functionalityDataCorrupted">
        <ng-container *ngIf="IsDateExpired(); else tariffNotExpired">
          Доступ окончился
        </ng-container>
        <ng-template #tariffNotExpired>
          <ng-container *ngIf="functionalyAccessData.isDemo !== null && functionalyAccessData.isDemo">
            Тестовый период окончится {{ExpirationAt() }}
          </ng-container>
          <ng-container *ngIf="functionalyAccessData.isDemo !== null && !functionalyAccessData.isDemo">
            Доступ окончится {{ExpirationAt() }}
          </ng-container>
        </ng-template>

        <a routerLink="/payments-create" title="Перейти к странице с выбором тарифного плана">продлить</a>
      </ng-container>
      <ng-template #functionalityDataCorrupted>
        Ошибка при получении данных о тарифе
      </ng-template>
    </ng-template>
  </div>

  <ng-container *ngIf="easyAdsDataService.AdsListNotEmpty() && easyAdsDataService.adsRerievingError === null">
    <div class="vk-container">
      <div class="easy-ads-shown-category-selector">
        <div class="easy-ads-shown-category-selector__item">
          Показывать:
        </div>

        <ng-container *ngFor="let filterOption of easyAdsDataService.filterOptions">
          <div class="easy-ads-shown-category-selector__item">
            <div [ngClass]="GetShownCategorySelectorClass(filterOption.mode)" (click)="SetUpFilter(filterOption.mode)">
              {{filterOption.name}} ({{filterOption.count}})
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>

  <!--<ng-container-->
    <!--*ngIf="easyAdsDataService.filtredAdsList && easyAdsDataService.filtredAdsList.length > 0 && easyAdsDataService.adsRerievingError === null">-->
    <!--<div class="vk-container">-->
      <!--Кол-во объявлений: <strong>{{easyAdsDataService.filtredAdsList.length | number: '1.0-0'}}</strong>-->
    <!--</div>-->
  <!--</ng-container>-->

  <div class="vk-container" *ngIf="showBudgetLoading">
    <!--Загружа.тся ли данные?-->
    <ng-container *ngIf="easyAdsDataService.filtredAdsList === null; else adsListLoaded">
      <div class="easy-ads-service-process-notification-text">
        Загрузка объявлений...
      </div>
    </ng-container>
    <!--Процесс загрузки данных завершен-->
    <ng-template #adsListLoaded>
      <!--Произошла ли ошибка во время загрузки данных?-->
      <ng-container *ngIf="easyAdsDataService.adsRerievingError !== null; else noErrorDuringDataLoading">
        <div class="easy-ads-service-process-notification-text easy-ads-service-process-notification-text_error">
          {{easyAdsDataService.adsRerievingError}}
        </div>
      </ng-container>
      <!--Ошибки во время загрузки данных не произошло-->
      <ng-template #noErrorDuringDataLoading>
        <!--В полученных данных есть хотя бы одно объявление?-->
        <ng-container *ngIf="easyAdsDataService.filtredAdsList.length === 0; else adsListIsNotEmpty">
          <div class="easy-ads-service-process-notification-text">
            Нет ни одного объявления
          </div>
        </ng-container>
        <!--В полученных данных нет ни одного объявления-->
        <ng-template #adsListIsNotEmpty>
          <div class="easy-ads-list">
            <ng-container *ngFor="let data of easyAdsDataService.filtredAdsList">
              <div class="easy-ads-list__item">
                <app-easy-ad-card [data]="data"
                                  [bidLimit]="GetAdBidLimit(data!.ad!.ad_format)"
                                  [forcedLoadingAbort]="data.forceLoadingAbort"
                                  (OnAdStatusChange)="AdStatusChange($event)"
                                  (OnAdSendToModeration)="AdSendToModeration($event)"
                                  (OnAdGetRejectionReason)="GetRejectionReason($event)"
                                  (OnAdNameChange)="UpdateAdName($event)"
                                  (OnCpmChange)="UpdateCpm(data.accountId, data.clientId, data.adId, $event)"
                                  (OnEcpcLimitChange)="UpdateEcpcLimit(data.accountId, data.clientId, data.adId, $event)"
                ></app-easy-ad-card>
              </div>
            </ng-container>
          </div>
        </ng-template>
      </ng-template>
    </ng-template>
  </div>

  <div class="vk-container">
    <button id="easy-ads-dashboard-create-ad-button-content" class="vk-standart-button"
            [disabled]="easyAdsDataService.accessExpired"
            onclick="ym(45402093, 'reachGoal', 'easy-dashboard-create-ad-bottom-button-click');"
            (click)="CreateAdClick()">
      Создать объявление
    </button>
  </div>
</div>

<app-new-style-modal [isVisible]="showModal"
                     showCloseButton="true"
                     (onCloseButtonClick)="showModal = false">
  <div class="new-modal-header-content">
    {{modalHeader}}
  </div>
  <div class="new-modal-body-content" [innerHtml]="modalText">
  </div>
  <div class="new-modal-buttons">
    <ng-container *ngIf="modalShowPaymentButton; else showUndersatndButton">
      <a href="https://vk.com/ads?act=payments" class="vk-standart-button"
         target="_blank" rel="noopener nofollow">Пополнить</a>
    </ng-container>
    <ng-template #showUndersatndButton>
      <button class="vk-standart-button" (click)="showModal = false">
        Понятно
      </button>
    </ng-template>
  </div>
</app-new-style-modal>
