<div class="strategy-list">
  <ng-container *ngFor="let strategy of strategies">
    <div class="strategy-list__item">
      <div class="strategy-block">
        <div class="strategy-block__input">
          <input [id]="'strategy-' + strategy.id" name="strategy-selection" type="radio" [(ngModel)]="selectedStrategyId" [value]="strategy.id">
        </div>
        <div class="strategy-block__name">
          <label [for]="'strategy-' + strategy.id">{{strategy.title}}</label>
        </div>
        <div class="strategy-block__description">
          <span [innerHtml]="IsShowingMore(strategy.id) ? strategy.description : strategy.descriptionPreview"></span>
          <span class="strategy-block__show-more" (click)="SetShowMoreStatus(strategy.id, !IsShowingMore(strategy.id))">
            {{IsShowingMore(strategy.id) ? "Скрыть" : "Подробнее"}}
          </span>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<div class="strategy-confirm-selection">
  <button class="vk-standart-button" (click)="ConfirmStrategySelection()" [disabled]="selectedStrategyId <= 0">
    Выбрать способ управления ставкой
  </button>
</div>
