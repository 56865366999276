import { OnInit } from '@angular/core';
var AdAudienceComponent = /** @class */ (function () {
    function AdAudienceComponent() {
    }
    Object.defineProperty(AdAudienceComponent.prototype, "TargetingAudienceCount", {
        get: function () {
            return this.targeting ? this.targeting.audience_count : 0;
        },
        enumerable: true,
        configurable: true
    });
    AdAudienceComponent.prototype.ngOnInit = function () {
    };
    return AdAudienceComponent;
}());
export { AdAudienceComponent };
