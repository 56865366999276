import {Injectable} from '@angular/core';
import {IntegrationPlatformCompability} from '../../../api/models/integration-platform-compability';
import {IntegrationService} from '../../../api/services';
import {PlatformsRelationsStore} from './platforms-relations.store';
import {createPlatformsRelation} from './platforms-relation.model';

@Injectable({
  providedIn: 'root'
})
export class PlatformsRelationsService {

  constructor(
    private store: PlatformsRelationsStore,
    private integrationService: IntegrationService
  ) {
  }

  /**
   *
   * @constructor
   */
  public LoadIntegrationsRelations() {
    this.integrationService.GetCompableIntegrations()
      .subscribe(({data}) => {
        this.store.set(data.map(x => createPlatformsRelation(x)));
      });
  }
}
