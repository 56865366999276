import { SuggestionsService } from '../../../api/services';
import { publish, refCount } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../../../api/services/suggestions.service";
var AppSuggestionsService = /** @class */ (function () {
    function AppSuggestionsService(suggestionsService) {
        var _this = this;
        this.suggestionsService = suggestionsService;
        if (!this.suggestions) {
            this.suggestionsService.GetAllSuggestions()
                .pipe(publish(), refCount())
                .subscribe(function (suggestions) {
                _this.suggestions = suggestions;
            });
        }
    }
    AppSuggestionsService.prototype.GetSuggestion = function (code) {
        if (this.suggestions) {
            var suggestion = this.suggestions.find(function (x) { return x.code === code; });
            return suggestion;
        }
        else {
            return null;
        }
    };
    AppSuggestionsService.ngInjectableDef = i0.defineInjectable({ factory: function AppSuggestionsService_Factory() { return new AppSuggestionsService(i0.inject(i1.SuggestionsService)); }, token: AppSuggestionsService, providedIn: "root" });
    return AppSuggestionsService;
}());
export { AppSuggestionsService };
