import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {PersonalAreaRoutingModule} from './personal-area-routing.module';
import {PaymentsComponent} from './routes/payments/payments.component';
import {PaymentsCreateComponent} from './routes/payments-create/payments-create.component';
import {PersonalAreaLayoutComponent} from './routes/personal-area-layout/personal-area-layout.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ClarityModule} from '@clr/angular';
import { PaymentSuccessedComponent } from './routes/payment-successed/payment-successed.component';
import {SelectedTarifCardComponent} from './components/selected-tarif-card/selected-tarif-card.component';
import {SharedModule} from '../shared/shared.module';
import { PersonalDashboardComponent } from './routes/personal-dashboard/personal-dashboard.component';
import { PersonalCabinetsComponent } from './routes/personal-cabinets/personal-cabinets.component';
import { PersonalCabinetComponent } from './components/personal-cabinet/personal-cabinet.component';
import { PersonalSettingsComponent } from './routes/personal-settings/personal-settings.component';
import { AuthChangePasswordComponent } from './components/forms/auth-change-password/auth-change-password.component';
import { TariffPlanExpirationComponent } from './components/tariff-plan-expiration/tariff-plan-expiration.component';
import { TestPeriodStartsNowComponent } from './test-period-starts-now/test-period-starts-now.component';
import { EasySettingsComponent } from './routes/easy-settings/easy-settings.component';
import { EasyPaymentsCreateComponent } from './routes/easy-payments-create/easy-payments-create.component';
import { EasyTarifConfiguratorCardComponent } from './components/easy-tarif-configurator-card/easy-tarif-configurator-card.component';
import { EasyPaymentSystemComponent } from './components/easy-payment-system/easy-payment-system.component';
import { TariffPlanComponent } from './components/tariff-plan/tariff-plan.component';
import {AffiliateProgramSharedModule} from "../affiliate-programm/affiliate-program-shared/affiliate-program-shared.module";

@NgModule({
    imports: [
        CommonModule,
        PersonalAreaRoutingModule,
        FormsModule,
        ClarityModule,
        SharedModule,
        ReactiveFormsModule,
        AffiliateProgramSharedModule
    ],
    declarations: [PaymentsComponent, PaymentsCreateComponent, PersonalAreaLayoutComponent, PaymentSuccessedComponent, SelectedTarifCardComponent, PersonalDashboardComponent, PersonalCabinetsComponent, PersonalCabinetComponent, PersonalSettingsComponent, AuthChangePasswordComponent, TariffPlanExpirationComponent, TestPeriodStartsNowComponent, EasySettingsComponent, EasyPaymentsCreateComponent, EasyTarifConfiguratorCardComponent, EasyPaymentSystemComponent, TariffPlanComponent]
})
export class PersonalAreaModule {
}
