import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {CallsController1Service, LeadsService, UsersService} from '../../../../api/services';
import {
  PostMonitorBackend_V2ModelsAdminViewModelsUserReportViewModel,
  PostMonitorBackend_V2ModelsAdminViewModelsUserViewModel
} from '../../../../api/models';
import * as moment from 'moment';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {DgPaginationComponent} from '../../../../ngr-ui/components/dg-pagination/dg-pagination.component';
import {MatDialog} from "@angular/material";
import {CallFormComponent} from "../../components/modals/call-form/call-form.component";
import {Observable, of} from "rxjs";
import {DataGridComponent} from "../../../../ngr-ui/components/data-grid/data-grid.component";
import {isNullOrUndefined} from "util";
import {publish, refCount} from "rxjs/operators";

export enum LeadCallStatus {
  ALL = 0,
  WAITING_FOR_A_CALL = 1,
  CALL_IS_MADE = 2,
  DIDNT_PICK_UP = 3,
  PHONE_NUMBER_UNAVAILABLE = 4
}

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss']
})
export class UsersListComponent implements OnInit {

  @ViewChild(DgPaginationComponent) public pagination: DgPaginationComponent;
  @ViewChild(DataGridComponent) public dg: DataGridComponent;

  public users = [];
  public dateFrom = new Date();
  public dateTo = new Date();
  public leadCallsStatus: FormControl = new FormControl(LeadCallStatus.ALL);

  public leadCallsStatuses$: Observable<Array<any>> = of([]);

  public yandexMetrikaOptions = [
    {
      value: null,
      title: 'Все'
    },
    {
      value: true,
      title: 'Только подключили'
    },
    {
      value: false,
      title: 'Только не подключили'
    },
  ];

  public activeAdCabinet = [
    {
      value: null,
      title: 'Все'
    },
    {
      value: 'true',
      title: 'Только активированные'
    },
    {
      value: 'false',
      title: 'Только не активированные'
    },
  ];

  public profiOrNoob = [
    {
      value: null,
      title: 'Все'
    },
    {
      value: 'Новичок',
      title: 'Новички'
    },
    {
      value: 'Профессионал',
      title: 'Проффесионалы'
    },
  ];

  public get currentPage() {
    let currentPage = 1;
    if (this.pagination) {
      if (this.pagination.currentPage) {
        currentPage = this.pagination.currentPage;
      }
    }

    return currentPage;
  }

  public LoadCallStatuses() {
    this.leadCallsStatuses$ = this.callsService.GetCallsStatuses()
      .pipe(
        publish(),
        refCount()
      )
  }

  UserStatustChanged() {
    this.UpdateData();
  }

  public callFormOpened = false;

  public formCall: FormGroup;

  public usersListPeriod = {
    period: 'today',
    dateFrom: new Date(),
    dateTo: new Date()
  };
  public loading: boolean;

  constructor(
    private usersService: UsersService,
    private leadsService: LeadsService,
    private cd: ChangeDetectorRef,
    private dialog: MatDialog,
    private callsService: CallsController1Service
  ) {
  }

  public get CountOfPages() {
    return Math.ceil(this.users.length / 10);
  }

  ngOnInit() {
    this.formCall = new FormGroup({
      userId: new FormControl(),
      callDate: new FormControl('', [Validators.required]),
      comment: new FormControl('')
    });
    this.LoadData();
    this.LoadCallStatuses();
    console.log(this.leadCallsStatus, 'LEAD CALLS STATUS');
    this.leadCallsStatus.valueChanges
      .subscribe(data => {
        console.log(data);
        this.dg.LoadDisplayedData();
        this.dg.SetSorting('nextContactDate', true, true);
      })
  }

  public CreateForm(userId) {
    this.dialog.open(CallFormComponent, {
      data: {
        userId
      },
      width: '350px',
      height: '340px'
    })
      .afterClosed()
      .subscribe(() => {
        'CALL WAS CREATED'
        this.UpdateData();
      })
  }

  SaveCall() {
    if (this.formCall.valid) {
      const callData = this.formCall.value;
      console.log(callData);
      callData.callDate = new Date(moment(callData).add(3, 'day').toDate());
      this.leadsService.CreateCall(callData)
        .subscribe(data => {
          this.UpdateData();
          alert('Звонок сохранен!');
          this.callFormOpened = !this.callFormOpened;
        });
    }
  }

  public LoadData() {
    this.users = [];
    this.loading = true;

    this.usersService.GetNewUsers({
      dateFrom: this.dateToStart(this.dateFrom),
      dateTo: this.dateToEnd(this.dateTo)
    })
      .subscribe((data: Array<PostMonitorBackend_V2ModelsAdminViewModelsUserReportViewModel>) => {
        if (data !== null) {
          this.handleData(data);
          // this.cd.detectChanges();
        }
        this.loading = false;
      });
  }

  private handleData(data: Array<PostMonitorBackend_V2ModelsAdminViewModelsUserReportViewModel>) {
    this.users = data.map(user => {
      const u: any = user.report;
      u.registrationDate = this.dateToUtc(u.registrationDate);
      u.lead = user.lead;
      u.requestsPerDayAutomation = user.requestsPerDayAutomation;
      u.requestsPerDayGroups = user.requestsPerDayGroups;
      u.requestsPerDayPosts = user.requestsPerDayPosts;
      u.requestsPerDayPromoPosts = user.requestsPerDayPromoPosts;
      u.requestsPerDayBidManagement = user.requestsPerDayBidManagement;

      u.lastActivityAd = this.dateToUtc(user.lastActivityAd);
      u.lastActivityGroups = this.dateToUtc(user.lastActivityGroups);
      u.lastActivityPosts = this.dateToUtc(user.lastActivityPosts);
      u.lastActivityPromo = this.dateToUtc(user.lastActivityPromo);
      u.createdStrategies = user.createdStrategies;
      u.nextContactDate = this.getNextContactDate(user) || Number.MAX_SAFE_INTEGER;
      console.log(u.nextContactDate, 'NEXT CONTACT DATE');
      return u;
    });
  }

  private getNextContactDate(user) {
    if (user && user.lead && user.lead.calls && user.lead.calls.length > 0) {
      const userCalls = this.getUserCalls(user);
      return this.getDatesDifferent(new Date(), new Date(userCalls[0].callDate));
    } else {
      return null;
    }
  }

  public getUserCalls(user) {
    let userCalls = [];
    if (user && user.lead && user.lead.calls) {
      userCalls = user.lead.calls.sort((a, b) => this.compareDate(a, b));
    }

    return userCalls;
  }

  private compareDate(b, a) {
    const differenceDateB = this.getDatesDifferent(new Date(), new Date(b.callDate));
    const differenceDateA = this.getDatesDifferent(new Date(), new Date(a.callDate));
    return differenceDateB - differenceDateA;
  }

  private getDatesDifferent(dateA: Date, dateB: Date) {
    return this.DateToNumber(dateB) - this.DateToNumber(dateA);
  }

  private dateToUtc(date) {
    if (date) {
      return moment.utc(date).toDate().toString();
    }
    return null;
  }

  private DateToNumber(date: Date): number {
    const millisecondsFrom1970 = moment(date).format('x');
    return parseInt(millisecondsFrom1970, 10);
  }

  public IsNew(user: PostMonitorBackend_V2ModelsAdminViewModelsUserViewModel) {
    if (user) {
      return moment(user.registrationDate).isBefore(new Date()) && Math.abs(moment(user.registrationDate).diff(new Date(), 'hour')) <= 24;
    }
  }

  public get context() {
    return this;
  }

  DateChanged($event: any) {
    this.dateFrom = $event.dateFrom;
    this.dateTo = $event.dateTo;
    this.LoadData();
  }

  public dateToStart(date: Date) {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return moment(date).format('YYYY-MM-DD[T]HH:mm:ss[Z]');
  }

  public dateToEnd(date: Date) {
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    date.setMilliseconds(99);
    return moment(date).format('YYYY-MM-DD[T]HH:mm:ss[Z]');
  }

  public UpdateData() {
    this.usersService.GetNewUsers({
      dateFrom: this.dateToStart(this.dateFrom),
      dateTo: this.dateToEnd(this.dateTo)
    })
      .subscribe(data => {
        if (data !== null) {
          this.handleData(data);
          // this.cd.detectChanges();
        }
      });
  }

  public SortByLastCallFunction(userA, userB) {
    console.log(userA, userB, 'USERS A AND B');
  }

  public FilterByCall(user) {
    const callStatus = this.leadCallsStatus.value;
    if (callStatus === LeadCallStatus.ALL) {
      return true;
    } else {
      const userCalls = this.getUserCalls(user);
      if (userCalls && userCalls.length > 0) {
        const callWithSelectedStatus = userCalls.find(call => call.callStatusId === callStatus);
        return !isNullOrUndefined(callWithSelectedStatus);
      } else {
        return false;
      }
    }
  }
}
