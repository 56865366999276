import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FacebookLeadAdsComponent} from './components/facebook-lead-ads/facebook-lead-ads.component';
import {FacebookCustomAudienceComponent} from './components/facebook-custom-audience/facebook-custom-audience.component';

@NgModule({
  declarations: [FacebookLeadAdsComponent, FacebookCustomAudienceComponent],
  exports: [
    FacebookLeadAdsComponent,
    FacebookCustomAudienceComponent
  ],
  imports: [
    CommonModule
  ]
})
export class FacebookModule {
}
