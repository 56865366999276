import {Component, Input, OnInit} from '@angular/core';
import {PostMonitorBackend_V2ModelsPaymentsTariffPackage} from '../../../../../../src/app/api/models/post-monitor-backend-_v2models-payments-tariff-package';
import {PostMonitorBackend_V2ModelsPaymentsFeature} from '../../../../../../src/app/api/models/post-monitor-backend-_v2models-payments-feature';
import {PaymentsService} from '../../services/payments.service';
import {isNullOrUndefined} from 'util';

@Component({
  selector: 'app-payments-tariff-feature-status',
  templateUrl: './payments-tariff-feature-status.component.html',
  styleUrls: ['./payments-tariff-feature-status.component.scss']
})
export class PaymentsTariffFeatureStatusComponent implements OnInit {

  public featureTariffPlanStatus = null;

  public get contains() {
    return !isNullOrUndefined(this.featureTariffPlanStatus);
  }

  @Input() public tariff: PostMonitorBackend_V2ModelsPaymentsTariffPackage;
  @Input() public feature: PostMonitorBackend_V2ModelsPaymentsFeature;

  constructor(
    private paymentsService: PaymentsService
  ) {
  }

  ngOnInit() {
    this.featureTariffPlanStatus = this.paymentsService.GetFeatureTariffPlanStatus(this.feature.id, this.tariff.id);
    console.log(this.featureTariffPlanStatus, 'FEATURE STATUS');
  }

}
