import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UserStrategy, UserStrategyInput} from '../../../api/models';
import {UserStrategiesService} from '../../../api/services';
import {AdViewModel} from '../../models/view-models/ad-view-model';

@Component({
  selector: 'app-strategy-settings',
  templateUrl: './strategy-settings.component.html',
  styleUrls: ['./strategy-settings.component.scss']
})
export class StrategySettingsComponent implements OnInit {

  @Input() public userStrategy: UserStrategy;
  @Input() public ad: AdViewModel;
  @Input() public accountId;
  @Input() public adId;
  @Input() public clientId;
  @Input() displayFunctions: object = {};
  @Input() public disabled: boolean;

  @Output() public OnToggleStrategyActive: EventEmitter<any> = new EventEmitter<any>();
  @Output() public OnCreateStrategy: EventEmitter<any> = new EventEmitter<any>();
  @Output() public OnUpdateStrategy: EventEmitter<any> = new EventEmitter<any>();
  @Output() public OnRemoveStrategy: EventEmitter<any> = new EventEmitter<any>();
  @Output() public OnEditStrategy: EventEmitter<any> = new EventEmitter<any>();

  public ConfirmRemoveDialogShowed: boolean = false;
  public ConfirmStartAdDialog: boolean = false;
  public OpenedFormShowed: boolean = false;
  public IsModelUpdating: boolean = false;

  public IsShowedInputs: boolean = false;

  public get IsDisabledChangeStatusButton() {
    if (!this.userStrategy.strategy.isAvailable) {
      return !this.userStrategy.isActive;
    } else {
      return false;
    }
  }

  constructor(
    private userStrategiesService: UserStrategiesService
  ) {
  }

  ngOnInit() {
  }

  public ToggleStrategyActiveStatus() {
    if (this.userStrategy.isActive) {
      this.OnConfirmStartAd();
    } else {
      this.OnConfirmStartAd();
      // if (this.ad.IsActive) {
      //   this.ToggleConfirmStartAdDialog();
      // } else {
      //   this.OnConfirmStartAd();
      // }
    }
  }

  public ToggleConfirmStartAdDialog() {
    this.ConfirmStartAdDialog = !this.ConfirmStartAdDialog;
  }

  public OnConfirmStartAd() {
    console.log({status: !this.userStrategy.isActive, adIds: this.userStrategy.adId}, 'strategy-settings');
    this.OnToggleStrategyActive.emit({status: !this.userStrategy.isActive, adIds: this.userStrategy.adId});
  }

  public OnRejectStartAd() {
    this.ToggleConfirmStartAdDialog();
  }

  public RemoveStrategy() {
    this.OnRemoveStrategy.emit(this.ad);
  }

  public UpdateStrategy() {
    this.OnUpdateStrategy.emit(this.ad);
  }

  public CreateStrategy() {
    this.OnCreateStrategy.emit(this.ad);
  }

  public EditStrategy() {
    this.OnEditStrategy.emit(this.ad);
  }

  public GetInputValue(input: UserStrategyInput) {
    let value = input.value;
    if (this.displayFunctions[input.type.code]) {
      value = this.displayFunctions[input.type.code](value);
    }
    return value;
  }

  GetSelectedOptionValue(input: UserStrategyInput) {
    return input.type.options.find(x => parseInt(x.value, 10) === input.value).title;
  }

  public ToggleInputsVisible() {
    this.IsShowedInputs = !this.IsShowedInputs;
  }

  public GetFormatedUserStrategyInputTitle(title: string) {
    return title.split(',')[0];
  }

  public GetToggleInputsText(): string {
    return (this.IsShowedInputs) ? 'Свернуть' : 'Развернуть';
  }
}
