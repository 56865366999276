<form class="export-settings" [formGroup]="form" *ngIf="form">
  <mat-radio-group class="export-settings__radio-buttons" formControlName="section">
    <mat-radio-button value="CONTACTS" class="export-settings__radio-button">
      Выбрать все контакты
    </mat-radio-button>
    <mat-radio-button value="DEALS" class="export-settings__radio-button">
      <ng-container *ngIf="(pipelines$ | async).length === 1; else manyPipelines">
        Передавать данные контактов из воронки продаж
      </ng-container>
      <ng-template #manyPipelines>
        Выбрать воронку, из которой выгружать контакты
      </ng-template>
    </mat-radio-button>
  </mat-radio-group>

  <ng-container *ngIf="isDeals">
    <vk-form-field orientation="vertical" controlsWidth="100%" *ngIf="(pipelines$ | async).length > 1">
      <vk-label>Воронка продаж</vk-label>
      <mat-select formControlName="selectedPipelineId" vkInput placeholder="Выберите воронку продаж">
        <ng-container *ngFor="let pipeline of pipelines$ | async">
          <mat-option [value]="pipeline.id">{{pipeline.name}}</mat-option>
        </ng-container>
      </mat-select>
    </vk-form-field>
  </ng-container>
</form>
