import { Injectable } from '@angular/core';
import { CrmUser } from './crm-user.model';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

export interface CrmUsersState extends EntityState<CrmUser> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'crm-users' })
export class CrmUsersStore extends EntityStore<CrmUsersState> {

  constructor() {
    super();
  }

}

