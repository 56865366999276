var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { QueryEntity } from '@datorama/akita';
import { CrmUsersStore } from './crm-users.store';
import * as i0 from "@angular/core";
import * as i1 from "./crm-users.store";
var CrmUsersQuery = /** @class */ (function (_super) {
    __extends(CrmUsersQuery, _super);
    function CrmUsersQuery(store) {
        var _this = _super.call(this, store) || this;
        _this.store = store;
        _this.users$ = _this.selectAll();
        return _this;
    }
    CrmUsersQuery.ngInjectableDef = i0.defineInjectable({ factory: function CrmUsersQuery_Factory() { return new CrmUsersQuery(i0.inject(i1.CrmUsersStore)); }, token: CrmUsersQuery, providedIn: "root" });
    return CrmUsersQuery;
}(QueryEntity));
export { CrmUsersQuery };
