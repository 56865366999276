<ng-container *ngIf="IsSelectedAccountAdmin()">
  <app-budget-panel
    [budget]="budget"
    (refreshTriggered)="RefreshBudget($event)"
  ></app-budget-panel>

</ng-container>
<form [formGroup]="form">

  <div class="tip-field">
    <div class="vk-container">Если вы хотите включить объявление сразу, то выберите “Отправить на модерацию сразу после
      создания объявления” и “Запустить объявление сразу после прохождения модерации".
    </div>
    <div class="vk-container">Если вы хотите отправить объявление на модерацию, но не хотите сразу его включать, то
      отметьте только “Отправить на модерацию сразу после создания объявления".
    </div>
    <div class="vk-container">Если вы хотите только создать объявление и не отправлять его на модерацию, то не отмечайте
      ни одно поле.
    </div>
  </div>
  <br>
  <mat-checkbox formControlName="sendModeration" [disabled]="IsBudgetZero">Отправить на модерацию сразу после создания объявления</mat-checkbox>
  <app-generic-tooltip clickTitle="Как это работает?" [text]="Tip"></app-generic-tooltip>
  <br>
  <br>
  <mat-checkbox formControlName="startAfterModeration" [disabled]="beSendingToModeration">Запустить объявление сразу после прохождения модерации</mat-checkbox>
  <br>
  <br>
</form>
