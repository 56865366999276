import {Component, OnInit} from '@angular/core';
import {first} from 'rxjs/internal/operators/first';
import {AdManagerService} from '../../../../../api/services';
import {LoggingService} from '../../../../../shared/services/logging.service';
import {AdFormManagerService} from '../../../new-ad-manager-shared/services/ad-form-manager/ad-form-manager.service';

@Component({
  selector: 'app-create-ad-new-lite',
  templateUrl: './create-ad-new-lite.component.html',
  styleUrls: ['./create-ad-new-lite.component.scss']
})
export class CreateAdNewLiteComponent implements OnInit {

  constructor(
    private adFormManagerService: AdFormManagerService,
    private adManagerService: AdManagerService,
    private logger: LoggingService
  ) {
  }

  public get IsLoading() {
    return this.adFormManagerService.adDataLoading;
  }

  ngOnInit() {
    console.log('NG ON INIT LIGHT');
    this.adFormManagerService.reset();
    this.adFormManagerService.LoadData()
      .pipe(
        first()
      )
      .subscribe((data) => {
        console.log(data);
      }, err => {
        console.log(err);
        this.logger.AddBreadcrumb('Loading Data Error Object', JSON.stringify(err.error));
        this.logger.Error('Loading Ads data init error');
      });
  }

  public OnSave(specifications: any) {
    this.adFormManagerService
      .CreateAd()
      .subscribe(data => {
        console.log(data);
      }, err => {
        console.log(err);
        this.logger.Error('Creating Ad error', err);
      });
  }
}
