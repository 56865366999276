<ng-container *ngIf="!loading; else loadingScreen">
  <form class="export-crm-settings" [formGroup]="form" *ngIf="form">
    <div class="export-crm-settings__radio">
      <mat-radio-group formControlName="section" class="export-crm-settings__radio-group">
        <div class="export-crm-settings__radio-item-wrapper">
          <mat-radio-button class="no-margin-checkbox export-crm-settings__radio-item" value="CONTACTS">Выбрать все
            контакты
          </mat-radio-button>
          <app-suggestion-info code="ALL_CONTACTS"></app-suggestion-info>
        </div>
        <div class="export-crm-settings__radio-item-wrapper">
          <mat-radio-button class="no-margin-checkbox export-crm-settings__radio-item" [value]="LeadValue">
            {{pipelineLabel}}
          </mat-radio-button>
          <app-suggestion-info [code]="pipelineLabelSuggestionCode"></app-suggestion-info>
        </div>
      </mat-radio-group>
    </div>

    <div class="export-crm-settings__content">
      <ng-container *ngIf="form.get('section').value === 'LEADS' || form.get('section').value === 'DEALS'">
        <ng-container [ngSwitch]="(pipelines$ | async).length">
          <ng-container *ngSwitchCase="0">
            Воронки не найдены
          </ng-container>
          <ng-container *ngSwitchCase="1"></ng-container>
          <ng-container *ngSwitchDefault>
            <vk-form-field orientation="vertical" controlsWidth="100%">
              <vk-label>
                Воронка продаж
                <app-suggestion-info code="CRM_PIPELINE_ID"></app-suggestion-info>
              </vk-label>
              <mat-select formControlName="selectedPipelineId" vkInput placeholder="Выберите воронку продаж">
                <ng-container *ngFor="let pipeline of pipelines$ | async">
                  <mat-option [value]="pipeline.id">
                    {{pipeline.name}}
                  </mat-option>
                </ng-container>
              </mat-select>
            </vk-form-field>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </form>
</ng-container>
<ng-template #loadingScreen>
  <app-loading-screen diameter="16">
    Подождите, операция выполняется ...
  </app-loading-screen>
</ng-template>
