import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import {AdFormManagerService} from '../../services/ad-form-manager/ad-form-manager.service';

@Component({
  selector: 'app-selected-groups-table',
  templateUrl: './selected-groups-table.component.html',
  styleUrls: ['./selected-groups-table.component.scss']
})
export class SelectedGroupsTableComponent implements OnInit, OnChanges {

//  @Input() public groups: Array<any> = [];
  @Input() public displayColumns = ['checkbox', 'avatar', 'name', 'members_count'];

  @ViewChild(MatPaginator) paginator;
  @ViewChild(MatSort) sort;

  public get groups() {
    return this.adFormManagerService.selectedGroups;
  }

  public dataSource: MatTableDataSource<any>;

  public columns = ['checkbox', 'avatar', 'name', 'members_count'];

  constructor(
    private adFormManagerService: AdFormManagerService
  ) {
  }

  ngOnInit() {
    this.FillDataSource();
    this.adFormManagerService.groupToggled
      .subscribe(() => {
        this.FillDataSource();
      });
  }

  private FillDataSource() {
    this.dataSource = new MatTableDataSource(this.adFormManagerService.selectedGroups);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);
  }

  public toggleSelectedRow(group) {
    this.adFormManagerService.toggleSelectedRow(group);
  }

}
