import { AkitaNgFormsManager } from "@datorama/akita-ng-forms-manager";
import { isNullOrUndefined } from "util";
import { AdNetworkFormsQuery } from "../../../stores/ad-network-forms/ad-network-forms.query";
import * as i0 from "@angular/core";
import * as i1 from "@datorama/akita-ng-forms-manager";
import * as i2 from "../../../stores/ad-network-forms/ad-network-forms.query";
var LeadFormsService = /** @class */ (function () {
    function LeadFormsService(fm, adNetworkForms) {
        this.fm = fm;
        this.adNetworkForms = adNetworkForms;
    }
    LeadFormsService.prototype.configure = function () {
        var _this = this;
        var form = this.fm.getForm('originPlatformSettings');
        if (!isNullOrUndefined(form)) {
            var configurationData = this.GetSelectedFormIds(form)
                .map(function (formId) { return ({ form_id: formId, last_lead_id: _this.adNetworkForms.GetLastLeadIdByGroupAndFormIds(parseInt(formId, 10), null) }); });
            return {
                forms_ids: configurationData
            };
        }
        return [];
    };
    LeadFormsService.prototype.valid = function () {
        var form = this.fm.getForm('originPlatformSettings');
        if (!isNullOrUndefined(form)) {
            var configurationData = this.GetSelectedFormIds(form);
            return configurationData.length > 0;
        }
        return false;
    };
    LeadFormsService.prototype.GetSelectedFormIds = function (form) {
        var settings = form.value;
        var formIds = Object.keys(settings)
            .map(function (formId) { return ({ formId: formId, isSelected: settings[formId] }); })
            .filter(function (_a) {
            var isSelected = _a.isSelected;
            return isSelected;
        })
            .map(function (_a) {
            var formId = _a.formId;
            return formId;
        });
        return formIds;
    };
    LeadFormsService.ngInjectableDef = i0.defineInjectable({ factory: function LeadFormsService_Factory() { return new LeadFormsService(i0.inject(i1.AkitaNgFormsManager), i0.inject(i2.AdNetworkFormsQuery)); }, token: LeadFormsService, providedIn: "root" });
    return LeadFormsService;
}());
export { LeadFormsService };
