import {Component, OnInit} from '@angular/core';
import {CopyingService} from "../../../services/copying.service";
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'app-copying-by-groups-in-file',
  templateUrl: './copying-by-groups-in-file.component.html',
  styleUrls: ['./copying-by-groups-in-file.component.scss']
})
export class CopyingByGroupsInFileComponent implements OnInit {

  public form: FormGroup;

  constructor(
    private copyingService: CopyingService
  ) {
  }

  ngOnInit() {
    this.form = this.copyingService.form.get('groupsFromFileConfiguration') as FormGroup;
  }

  SelectFile(fileInput) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.form.patchValue({
          groupsList: e.target.result.split('\n')
            .map(x => x.replace('https://vk.com/', ''))
        })
      };
      reader.readAsText(fileInput.target.files[0]);
    }
  }
}
