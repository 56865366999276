<div mat-dialog-title>{{dialogConfig.title}}</div>

<div>
  {{dialogConfig.description}}
</div>

<div mat-dialog-actions>
  <button class="btn btn-success btn-sm" (click)="Confirm()">{{dialogConfig.confirmActionText}}</button>
  <button class="btn btn-warning btn-sm" (click)="Reject()">{{dialogConfig.rejectActionText}}</button>
</div>
