import {Injectable} from '@angular/core';
import {AuthToken} from '../models/AuthToken';
import * as moment from 'moment';

@Injectable()
export class TokenCheckerService {
  private authToken: AuthToken = null;

  constructor() {
  }

  /***
   * Checks if user got unexpired token
   * @returns {boolean}
   * @constructor
   */
  public IsAuthorized(): boolean {
    this.ReloadToken();

    if (!this.authToken) {
      return false;
    }

    return this.IsNotTokenExpired();
  }

  /***
   * Removes token from localStorage & memory
   * @constructor
   */
  public RemoveToken(): void {
    localStorage.removeItem('token');
    localStorage.removeItem('token_expired_at');
    this.authToken = null;
  }

  /***
   * Save token to localSotrage & loads it in memory
   * @param {string} token
   * @param {string} token_expired_at
   * @constructor
   */
  public SaveToken(token: string, token_expired_at: string): void {
    localStorage.setItem('token', token);
    localStorage.setItem('token_expired_at', token_expired_at);

    this.authToken = new AuthToken(token, token_expired_at);
  }

  /***
   * Returns token if it exists & not expired. If it is expired - trying to refresh it
   * @returns {string}
   * @constructor
   */
  public GetToken(): string {
    this.ReloadToken();

    // If token not exists
    if (!this.authToken) {
      return null;
    } else {
      // If token exists & not expired
      if (this.IsNotTokenExpired()) {
        return this.authToken.token;
      } else {
        // If token exists but is expired
        // Refresh token
        this.RefreshToken();

        // Return valid token
        return this.authToken.token;
      }
    }
  }

  /***
   * Calling server with a refresh token & recieves fresh refresh token & token pair
   * @constructor
   */
  private RefreshToken(): void {
    // TODO Implement RefreshMechanism
    
  }

  /***
   * Checks if token isn't loaded into memory, than loads it from localStorage
   * @constructor
   */
  private ReloadToken(): void {
    if (!this.authToken || !this.IsNotTokenExpired()) {
      this.authToken = this.ExtractToken();
    }
  }

  /***
   * Loads token from localSotrage
   * @returns {AuthToken}
   * @constructor
   */
  private ExtractToken(): AuthToken {
    const token = localStorage.getItem('token');
    const token_expired_at = localStorage.getItem('token_expired_at');

    if (!token || !token_expired_at) {
      return null;
    }

    return new AuthToken(token, token_expired_at);
  }

  private IsNotTokenExpired(): boolean {
      return moment(new Date()).isBefore(new Date(this.authToken.token_expired_at));
  }
}
