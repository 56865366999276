import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NewImportCrmComponent} from './components/new-import-crm/new-import-crm.component';
import {NewImportCrmSectionComponent} from './components/new-import-crm-section/new-import-crm-section.component';
import {NewImportCrmSectionControlComponent} from './components/new-import-crm-section-control/new-import-crm-section-control.component';
import { NewImportCrmFormComponent } from './components/new-import-crm-form/new-import-crm-form.component';
import {ScrollingModule} from "@angular/cdk/scrolling";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {VkControlsModule} from "../../../vk-controls/vk-controls.module";
import {ReactiveFormsModule} from "@angular/forms";
import {MatSelectModule} from "@angular/material/select";
import {OverlayModule} from "@angular/cdk/overlay";
import {NewCustomFieldControlComponent} from "./custom-field-control/new-custom-field-control.component";
import {MatIconModule} from "@angular/material/icon";

@NgModule({
  declarations: [NewImportCrmComponent, NewImportCrmSectionComponent, NewImportCrmSectionControlComponent, NewImportCrmFormComponent, NewCustomFieldControlComponent],
  exports: [NewImportCrmComponent],
  imports: [
    CommonModule,
    ScrollingModule,
    MatExpansionModule,
    MatCheckboxModule,
    VkControlsModule,
    ReactiveFormsModule,
    MatSelectModule,
    OverlayModule,
    MatIconModule,
  ]
})
export class NewImportCrmModule {
}
