import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PaymentsComponent} from './routes/payments/payments.component';
import {PaymentsCreateComponent} from './routes/payments-create/payments-create.component';
import {PersonalAreaLayoutComponent} from './routes/personal-area-layout/personal-area-layout.component';
import {PaymentSuccessedComponent} from './routes/payment-successed/payment-successed.component';
import {PersonalDashboardComponent} from './routes/personal-dashboard/personal-dashboard.component';
import {AuthGuardGuard} from '../shared/guards/auth-guard.guard';
import {PersonalCabinetsComponent} from './routes/personal-cabinets/personal-cabinets.component';
import {PersonalSettingsComponent} from './routes/personal-settings/personal-settings.component';
import {TestPeriodStartsNowComponent} from './test-period-starts-now/test-period-starts-now.component';
import {EasySettingsComponent} from "./routes/easy-settings/easy-settings.component";
import {EasyPaymentsCreateComponent} from "./routes/easy-payments-create/easy-payments-create.component";
import {UserExperienceSwitcherComponent} from "../shared/components/user-experience-switcher/user-experience-switcher.component";

const routes: Routes = [
  {
    path: '',
    component: PersonalAreaLayoutComponent,
    canActivate: [AuthGuardGuard],
    children: [
      {
        path: 'test-period-welcome',
        component: TestPeriodStartsNowComponent
      },
      {
        path: 'dashboard',
        component: PersonalDashboardComponent
      },
      {
        path: 'payments',
        component: PaymentsComponent
      },
      {
        path: 'payments-create',
        redirectTo: '/new-payment', pathMatch: 'full'
      },
      {
        path: 'payment-performed',
        component: PaymentSuccessedComponent
      },
      {
        path: 'cabinets',
        component: PersonalCabinetsComponent
      },
      {
        path: 'settings',
        component: PersonalSettingsComponent
      },
      {
        path: 'easy-settings',
        component: EasySettingsComponent
      },
      {
        path: 'easy-payments-create',
        component: EasyPaymentsCreateComponent
      },
      {
        path: 'redefine-experience',
        component: UserExperienceSwitcherComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PersonalAreaRoutingModule {
}
