var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { map as __map, filter as __filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../api-configuration";
import * as i2 from "@angular/common/http";
var AutomationVkService = /** @class */ (function (_super) {
    __extends(AutomationVkService, _super);
    function AutomationVkService(config, http) {
        return _super.call(this, config, http) || this;
    }
    /**
     * @param params The `AutomationVkService.UpdateAdsParams` containing the following parameters:
     *
     * - `accountId`:
     *
     * - `specifications`:
     *
     * - `clientId`:
     *
     * @return Success
     */
    AutomationVkService.prototype.UpdateAdsResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.accountId != null)
            __params = __params.set('accountId', params.accountId.toString());
        __body = params.specifications;
        if (params.clientId != null)
            __params = __params.set('clientId', params.clientId.toString());
        var req = new HttpRequest('POST', this.rootUrl + "/apiv2/automationvk/updateads", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `AutomationVkService.UpdateAdsParams` containing the following parameters:
     *
     * - `accountId`:
     *
     * - `specifications`:
     *
     * - `clientId`:
     *
     * @return Success
     */
    AutomationVkService.prototype.UpdateAds = function (params) {
        return this.UpdateAdsResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    AutomationVkService.prototype.CheckCabinetsBindedResponse = function () {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/automationvk/checkcabinetsbinded", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    AutomationVkService.prototype.CheckCabinetsBinded = function () {
        return this.CheckCabinetsBindedResponse().pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param customClaim undefined
     * @return Success
     */
    AutomationVkService.prototype.GetAccountsResponse = function (customClaim) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (customClaim != null)
            __params = __params.set('customClaim', customClaim.toString());
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/automationvk/getaccounts", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param customClaim undefined
     * @return Success
     */
    AutomationVkService.prototype.GetAccounts = function (customClaim) {
        return this.GetAccountsResponse(customClaim).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param accountId undefined
     * @return Success
     */
    AutomationVkService.prototype.getClientsResponse = function (accountId) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (accountId != null)
            __params = __params.set('accountId', accountId.toString());
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/automationvk/clients", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param accountId undefined
     * @return Success
     */
    AutomationVkService.prototype.getClients = function (accountId) {
        return this.getClientsResponse(accountId).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `AutomationVkService.GetCampaignsParams` containing the following parameters:
     *
     * - `accountId`:
     *
     * - `clientId`:
     *
     * @return Success
     */
    AutomationVkService.prototype.GetCampaignsResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.accountId != null)
            __params = __params.set('accountId', params.accountId.toString());
        if (params.clientId != null)
            __params = __params.set('clientId', params.clientId.toString());
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/automationvk/getcampaigns", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `AutomationVkService.GetCampaignsParams` containing the following parameters:
     *
     * - `accountId`:
     *
     * - `clientId`:
     *
     * @return Success
     */
    AutomationVkService.prototype.GetCampaigns = function (params) {
        return this.GetCampaignsResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `AutomationVkService.GetAdsParams` containing the following parameters:
     *
     * - `offset`:
     *
     * - `limit`:
     *
     * - `includeDeleted`:
     *
     * - `accountId`:
     *
     * - `clientId`:
     *
     * - `campaignIds`:
     *
     * - `adIds`:
     *
     * @return Success
     */
    AutomationVkService.prototype.GetAdsResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.offset != null)
            __params = __params.set('offset', params.offset.toString());
        if (params.limit != null)
            __params = __params.set('limit', params.limit.toString());
        if (params.includeDeleted != null)
            __params = __params.set('includeDeleted', params.includeDeleted.toString());
        if (params.accountId != null)
            __params = __params.set('accountId', params.accountId.toString());
        if (params.clientId != null)
            __params = __params.set('clientId', params.clientId.toString());
        (params.campaignIds || []).forEach(function (val) { if (val != null)
            __params = __params.append('campaignIds', val.toString()); });
        (params.adIds || []).forEach(function (val) { if (val != null)
            __params = __params.append('adIds', val.toString()); });
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/automationvk/getads", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `AutomationVkService.GetAdsParams` containing the following parameters:
     *
     * - `offset`:
     *
     * - `limit`:
     *
     * - `includeDeleted`:
     *
     * - `accountId`:
     *
     * - `clientId`:
     *
     * - `campaignIds`:
     *
     * - `adIds`:
     *
     * @return Success
     */
    AutomationVkService.prototype.GetAds = function (params) {
        return this.GetAdsResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `AutomationVkService.GetFloodStatsParams` containing the following parameters:
     *
     * - `accountId`:
     *
     * - `clientId`:
     *
     * @return Success
     */
    AutomationVkService.prototype.GetFloodStatsResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.accountId != null)
            __params = __params.set('accountId', params.accountId.toString());
        if (params.clientId != null)
            __params = __params.set('clientId', params.clientId.toString());
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/automationvk/getfloodstats", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `AutomationVkService.GetFloodStatsParams` containing the following parameters:
     *
     * - `accountId`:
     *
     * - `clientId`:
     *
     * @return Success
     */
    AutomationVkService.prototype.GetFloodStats = function (params) {
        return this.GetFloodStatsResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `AutomationVkService.GetBudgetParams` containing the following parameters:
     *
     * - `accountId`:
     *
     * - `clientId`:
     *
     * @return Success
     */
    AutomationVkService.prototype.GetBudgetResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.accountId != null)
            __params = __params.set('accountId', params.accountId.toString());
        if (params.clientId != null)
            __params = __params.set('clientId', params.clientId.toString());
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/automationvk/getbudget", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `AutomationVkService.GetBudgetParams` containing the following parameters:
     *
     * - `accountId`:
     *
     * - `clientId`:
     *
     * @return Success
     */
    AutomationVkService.prototype.GetBudget = function (params) {
        return this.GetBudgetResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `AutomationVkService.GetDemographicsParams` containing the following parameters:
     *
     * - `accountId`:
     *
     * - `period`:
     *
     * - `idsType`:
     *
     * - `dateTo`:
     *
     * - `dateFrom`:
     *
     * - `clientId`:
     *
     * @return Success
     */
    AutomationVkService.prototype.GetDemographicsResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.accountId != null)
            __params = __params.set('accountId', params.accountId.toString());
        if (params.period != null)
            __params = __params.set('period', params.period.toString());
        if (params.idsType != null)
            __params = __params.set('idsType', params.idsType.toString());
        if (params.dateTo != null)
            __params = __params.set('dateTo', params.dateTo.toString());
        if (params.dateFrom != null)
            __params = __params.set('dateFrom', params.dateFrom.toString());
        if (params.clientId != null)
            __params = __params.set('clientId', params.clientId.toString());
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/automationvk/getdemograpics", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `AutomationVkService.GetDemographicsParams` containing the following parameters:
     *
     * - `accountId`:
     *
     * - `period`:
     *
     * - `idsType`:
     *
     * - `dateTo`:
     *
     * - `dateFrom`:
     *
     * - `clientId`:
     *
     * @return Success
     */
    AutomationVkService.prototype.GetDemographics = function (params) {
        return this.GetDemographicsResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `AutomationVkService.GetStatisticsParams` containing the following parameters:
     *
     * - `accountId`:
     *
     * - `period`:
     *
     * - `idsType`:
     *
     * - `ids`:
     *
     * - `dateTo`:
     *
     * - `dateFrom`:
     *
     * - `clientId`:
     *
     * @return Success
     */
    AutomationVkService.prototype.GetStatisticsResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.accountId != null)
            __params = __params.set('accountId', params.accountId.toString());
        if (params.period != null)
            __params = __params.set('period', params.period.toString());
        if (params.idsType != null)
            __params = __params.set('idsType', params.idsType.toString());
        if (params.ids != null)
            __params = __params.set('ids', params.ids.toString());
        if (params.dateTo != null)
            __params = __params.set('dateTo', params.dateTo.toString());
        if (params.dateFrom != null)
            __params = __params.set('dateFrom', params.dateFrom.toString());
        if (params.clientId != null)
            __params = __params.set('clientId', params.clientId.toString());
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/automationvk/getstatistics", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `AutomationVkService.GetStatisticsParams` containing the following parameters:
     *
     * - `accountId`:
     *
     * - `period`:
     *
     * - `idsType`:
     *
     * - `ids`:
     *
     * - `dateTo`:
     *
     * - `dateFrom`:
     *
     * - `clientId`:
     *
     * @return Success
     */
    AutomationVkService.prototype.GetStatistics = function (params) {
        return this.GetStatisticsResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `AutomationVkService.GetAdsTargetingParams` containing the following parameters:
     *
     * - `offset`:
     *
     * - `limit`:
     *
     * - `includeDeleted`:
     *
     * - `accountId`:
     *
     * - `clientId`:
     *
     * - `campaignIds`:
     *
     * - `adIds`:
     *
     * @return Success
     */
    AutomationVkService.prototype.GetAdsTargetingResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.offset != null)
            __params = __params.set('offset', params.offset.toString());
        if (params.limit != null)
            __params = __params.set('limit', params.limit.toString());
        if (params.includeDeleted != null)
            __params = __params.set('includeDeleted', params.includeDeleted.toString());
        if (params.accountId != null)
            __params = __params.set('accountId', params.accountId.toString());
        if (params.clientId != null)
            __params = __params.set('clientId', params.clientId.toString());
        (params.campaignIds || []).forEach(function (val) { if (val != null)
            __params = __params.append('campaignIds', val.toString()); });
        (params.adIds || []).forEach(function (val) { if (val != null)
            __params = __params.append('adIds', val.toString()); });
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/automationvk/getadstargeting", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `AutomationVkService.GetAdsTargetingParams` containing the following parameters:
     *
     * - `offset`:
     *
     * - `limit`:
     *
     * - `includeDeleted`:
     *
     * - `accountId`:
     *
     * - `clientId`:
     *
     * - `campaignIds`:
     *
     * - `adIds`:
     *
     * @return Success
     */
    AutomationVkService.prototype.GetAdsTargeting = function (params) {
        return this.GetAdsTargetingResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `AutomationVkService.GetAdsLayoutParams` containing the following parameters:
     *
     * - `offset`:
     *
     * - `limit`:
     *
     * - `includeDeleted`:
     *
     * - `accountId`:
     *
     * - `clientId`:
     *
     * - `campaignIds`:
     *
     * - `adIds`:
     *
     * @return Success
     */
    AutomationVkService.prototype.GetAdsLayoutResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.offset != null)
            __params = __params.set('offset', params.offset.toString());
        if (params.limit != null)
            __params = __params.set('limit', params.limit.toString());
        if (params.includeDeleted != null)
            __params = __params.set('includeDeleted', params.includeDeleted.toString());
        if (params.accountId != null)
            __params = __params.set('accountId', params.accountId.toString());
        if (params.clientId != null)
            __params = __params.set('clientId', params.clientId.toString());
        (params.campaignIds || []).forEach(function (val) { if (val != null)
            __params = __params.append('campaignIds', val.toString()); });
        (params.adIds || []).forEach(function (val) { if (val != null)
            __params = __params.append('adIds', val.toString()); });
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/automationvk", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `AutomationVkService.GetAdsLayoutParams` containing the following parameters:
     *
     * - `offset`:
     *
     * - `limit`:
     *
     * - `includeDeleted`:
     *
     * - `accountId`:
     *
     * - `clientId`:
     *
     * - `campaignIds`:
     *
     * - `adIds`:
     *
     * @return Success
     */
    AutomationVkService.prototype.GetAdsLayout = function (params) {
        return this.GetAdsLayoutResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `AutomationVkService.GetPostReachParams` containing the following parameters:
     *
     * - `accountId`:
     *
     * - `idsType`:
     *
     * - `ids`:
     *
     * - `clientId`:
     *
     * @return Success
     */
    AutomationVkService.prototype.GetPostReachResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.accountId != null)
            __params = __params.set('accountId', params.accountId.toString());
        if (params.idsType != null)
            __params = __params.set('idsType', params.idsType.toString());
        if (params.ids != null)
            __params = __params.set('ids', params.ids.toString());
        if (params.clientId != null)
            __params = __params.set('clientId', params.clientId.toString());
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/automationvk/getpostreach", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `AutomationVkService.GetPostReachParams` containing the following parameters:
     *
     * - `accountId`:
     *
     * - `idsType`:
     *
     * - `ids`:
     *
     * - `clientId`:
     *
     * @return Success
     */
    AutomationVkService.prototype.GetPostReach = function (params) {
        return this.GetPostReachResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param ids undefined
     * @return Success
     */
    AutomationVkService.prototype.GetWallByIdResponse = function (ids) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (ids != null)
            __params = __params.set('ids', ids.toString());
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/automationvk/getwallbyid", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param ids undefined
     * @return Success
     */
    AutomationVkService.prototype.GetWallById = function (ids) {
        return this.GetWallByIdResponse(ids).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param data undefined
     * @return Success
     */
    AutomationVkService.prototype.ToggleCampaignResponse = function (data) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = data;
        var req = new HttpRequest('POST', this.rootUrl + "/apiv2/automationvk/toggle-campaigns", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param data undefined
     * @return Success
     */
    AutomationVkService.prototype.ToggleCampaign = function (data) {
        return this.ToggleCampaignResponse(data).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param groupId undefined
     * @return Success
     */
    AutomationVkService.prototype.GetLeadFormsResponse = function (groupId) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (groupId != null)
            __params = __params.set('groupId', groupId.toString());
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/automationvk/lead-forms", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param groupId undefined
     * @return Success
     */
    AutomationVkService.prototype.GetLeadForms = function (groupId) {
        return this.GetLeadFormsResponse(groupId).pipe(__map(function (_r) { return _r.body; }));
    };
    AutomationVkService.UpdateAdsPath = '/apiv2/automationvk/updateads';
    AutomationVkService.CheckCabinetsBindedPath = '/apiv2/automationvk/checkcabinetsbinded';
    AutomationVkService.GetAccountsPath = '/apiv2/automationvk/getaccounts';
    AutomationVkService.getClientsPath = '/apiv2/automationvk/clients';
    AutomationVkService.GetCampaignsPath = '/apiv2/automationvk/getcampaigns';
    AutomationVkService.GetAdsPath = '/apiv2/automationvk/getads';
    AutomationVkService.GetFloodStatsPath = '/apiv2/automationvk/getfloodstats';
    AutomationVkService.GetBudgetPath = '/apiv2/automationvk/getbudget';
    AutomationVkService.GetDemographicsPath = '/apiv2/automationvk/getdemograpics';
    AutomationVkService.GetStatisticsPath = '/apiv2/automationvk/getstatistics';
    AutomationVkService.GetAdsTargetingPath = '/apiv2/automationvk/getadstargeting';
    AutomationVkService.GetAdsLayoutPath = '/apiv2/automationvk';
    AutomationVkService.GetPostReachPath = '/apiv2/automationvk/getpostreach';
    AutomationVkService.GetWallByIdPath = '/apiv2/automationvk/getwallbyid';
    AutomationVkService.ToggleCampaignPath = '/apiv2/automationvk/toggle-campaigns';
    AutomationVkService.GetLeadFormsPath = '/apiv2/automationvk/lead-forms';
    AutomationVkService.ngInjectableDef = i0.defineInjectable({ factory: function AutomationVkService_Factory() { return new AutomationVkService(i0.inject(i1.ApiConfiguration), i0.inject(i2.HttpClient)); }, token: AutomationVkService, providedIn: "root" });
    return AutomationVkService;
}(__BaseService));
export { AutomationVkService };
