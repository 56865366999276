<ng-container *ngIf="chatApi.conversations; else noConversatinos; let conversations">
  <div class="filter">
    <ng-container *ngFor="let option of filterOptions">
      <div class="filter__item">
        <span [ngClass]='{
          "filter-mode": true,
          "filter-mode_active": option.mode === filterMode
        }' (click)="SetFitlerMode(option.mode)">{{option.text}}</span>
      </div>
    </ng-container>

    <div class="filter__item">
      <select class="vk-select" [(ngModel)]="markFilterId" (change)="ProcessMarkExclusion()">
        <option [value]="0">Все</option>

        <ng-container *ngFor="let mark of chatApi.marks">
          <option [value]="mark.id">{{mark.title}}</option>
        </ng-container>
      </select>
    </div>
  </div>

  <ng-container *ngIf="conversations.length > 0; else emptyConversationList">
    <ng-container *ngFor="let conversation of conversations">
      <app-chat-conversation [conversation]="conversation"></app-chat-conversation>
    </ng-container>
  </ng-container>
  <ng-template #emptyConversationList>
    Список пуст
  </ng-template>
</ng-container>
<ng-template #noConversatinos>
  <ng-container *ngIf="chatApi.loadingConversations; else conversationLoaded">
    Загрузка...
  </ng-container>
  <ng-template #conversationLoaded>
    Нет диалогов
  </ng-template>
</ng-template>
