import { EventEmitter, OnInit } from '@angular/core';
import { PromopostViewModel } from '../../models/promopost.view-model';
import { DomainOnlyPipe } from '../../../../shared/pipes/domain-only.pipe';
import { DomSanitizer } from '@angular/platform-browser';
import { PostificatePipe } from '../../../../shared/pipes/postificate.pipe';
var PromopostComponent = /** @class */ (function () {
    function PromopostComponent(_sanitizer) {
        this._sanitizer = _sanitizer;
        this.selectedGroup = null;
        this.ImageRemoved = new EventEmitter();
        this.linkTitleChanged = new EventEmitter();
        this.editLinkTitle = false;
        this.editLinkTitleValue = '';
    }
    PromopostComponent.prototype.ngOnInit = function () {
        console.log(this.promopost);
        this.editLinkTitleValue = this.promopost.linkTitle;
    };
    Object.defineProperty(PromopostComponent.prototype, "PromopostText", {
        get: function () {
            return new PostificatePipe().transform(this.promopost.text);
        },
        enumerable: true,
        configurable: true
    });
    PromopostComponent.prototype.GetPromosotGroupName = function () {
        return (this.selectedGroup) ? this.selectedGroup.name : 'Сообщество не выбрано';
    };
    Object.defineProperty(PromopostComponent.prototype, "Image", {
        get: function () {
            return this.promopost.image ? "url(\"" + this.promopost.image + "\")" : 'url("assets/images/ad_size_promo.jpg")';
        },
        enumerable: true,
        configurable: true
    });
    PromopostComponent.prototype.GetPromosotGroupAvatar = function () {
        return (this.selectedGroup) ? this.selectedGroup.photo_50 : 'https://via.placeholder.com/50';
    };
    PromopostComponent.prototype.GetPromopostAddress = function () {
        return (this.promopost.linkAddress === '') ? 'Здесь будет домен ссылки' : new DomainOnlyPipe().transform(this.promopost.linkAddress);
    };
    PromopostComponent.prototype.GetPromopostTitle = function () {
        return this.promopost.linkTitle === '' ? 'Здесь будет название вашей ссылки' : this.promopost.linkTitle;
    };
    PromopostComponent.prototype.removeImage = function () {
        this.ImageRemoved.emit(this.image);
        this.promopost.image = '';
        this.image = '';
    };
    PromopostComponent.prototype.EnableLinkTitleEditor = function () {
        this.editLinkTitleValue = this.GetPromopostTitle();
        this.ToggleEditLinkTitleForm();
    };
    PromopostComponent.prototype.ToggleEditLinkTitleForm = function () {
        console.log('Clicked');
        this.editLinkTitle = !this.editLinkTitle;
    };
    PromopostComponent.prototype.SaveLinkTitle = function () {
        this.linkTitleChanged.emit(this.editLinkTitleValue);
        this.ToggleEditLinkTitleForm();
    };
    PromopostComponent.prototype.ClickOutSideForm = function () {
        console.log(this.editLinkTitle);
        if (this.editLinkTitle) {
            console.log('outside clicked');
            this.ToggleEditLinkTitleForm();
        }
    };
    return PromopostComponent;
}());
export { PromopostComponent };
