import {Injectable} from '@angular/core';
import {PlatformsStore} from './platforms.store';
import {IntegrationService} from '../../../api/services';
import {createPlatform} from './platform.model';
import {PlatformsQuery} from './platforms.query';
import {AssignationPlatforms} from '../platform-assignations/platform-assignation.model';
import {ActivatedRoute} from '@angular/router';
import {toBoolean} from "@datorama/akita";
import {CustomFieldValue} from "../custom-field-value/custom-field-value.model";
import {CustomFieldsService} from "../custom-fields/custom-fields.service";
import {CustomFieldValueService} from "../custom-field-value/custom-field-value.service";

export enum PlatformTypes {
  ORIGIN = 1,
  DESTINATION
}

@Injectable({
  providedIn: 'root'
})
export class PlatformsService {

  constructor(
    private store: PlatformsStore,
    private query: PlatformsQuery,
    private integrationsService: IntegrationService,
    private route: ActivatedRoute,
    private customFieldValueService: CustomFieldValueService
  ) {
  }

  /**
   * Load All Platforms for integration
   * @param {boolean} allFields - получать ли все поля
   * @constructor
   */
  public LoadIntegrations(allFields) {
    const filterOnlyActive = (this.route.snapshot.queryParams.filterOnlyActive !== 'false');
    this.integrationsService.GetIntegrationServices({
      allFields,
      sortFieldsAsc: true,
      filterOnlyActive: filterOnlyActive
    })
      .subscribe((integrations) => {
        this.store.set(integrations.data.map(x => createPlatform(x)));
        integrations.data.forEach(integration => {
          integration.sections.forEach(section => {
            if (section && section['meta']) {
              const customFieldsValues = section['meta'].map(x => ({
                Id: x.integrationServiceSectionMetaId,
                Code: x.code,
                Name: x.name,
                PlatformCode: integration.code
              } as CustomFieldValue));
              this.customFieldValueService.AddRange(customFieldsValues);
            }
          })
        })
      });
  }

  /**
   * Select platform
   * @param platformCode
   * @param isOrigin
   * @constructor
   */
  public SelectPlatform(platformCode: string, isOrigin: boolean) {
    if (isOrigin) {
      this.SelectOriginPlatform(platformCode);
    } else {
      this.SelectDestinationPlatform(platformCode);
    }
  }

  /**
   * Select Origin Platform
   * @param platformCode
   * @constructor
   */
  public SelectOriginPlatform(platformCode: string) {
    this.store.update(state => ({
      ...state,
      ui: {
        ...state.ui,
        selectedOriginPlatform: platformCode,
        selectedDestinationPlatform: ''
      }
    }));
  }

  /**
   * Select Destionation Platform
   * @param platformCode
   * @constructor
   */
  public SelectDestinationPlatform(platformCode: string) {
    this.store.update(state => {
      const newState = {
        ...state,
        ui: {
          ...state.ui,
          selectedDestinationPlatform: platformCode,
        }
      };
      return newState;
    });
  }

  /**
   * Задает дополнительные параметры для платформы
   * @param {AssignationPlatforms} platformId - id платформы
   * @param {any} params - данные для хранения
   * @constructor
   */
  public SetParameters(platformId: AssignationPlatforms, params: any) {
    this.store.setParameters(platformId, params);
  }

  /**
   * Добавить обязательное поле
   * @param field
   * @constructor
   */
  public AddRequiredField(field) {
    this.store.update(state => {
      const requiredFields = Object.assign([], state.ui.requiredFields);
      if (!requiredFields.includes(field)) {
        requiredFields.push(field);
      }
      return {
        ...state,
        ui: {
          ...state.ui,
          requiredFields
        }
      }
    })
  }

  /**
   * Отчистить обязательные поля
   * @constructor
   */
  public ClearRequiredFields() {
    this.store.update(state => ({
      ...state,
      ui: {
        ...state.ui,
        requiredFields: []
      }
    }))
  }
}
