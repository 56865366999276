import {Injectable} from '@angular/core';
import {PlatformsTypes} from '../../platforms-types';
import {CrmPipelinesStore} from './crm-pipelines.store';
import {AmoService, BitrixService, HashService, MegaplanService, MoySkladService} from '../../../api/services';
import {
  createCrmPipelineFromAmoCrm,
  createCrmPipelineFromBitrix,
  createCrmPipelineFromMegaplan,
  createCrmPipelineStatusFromBitrix,
  createUOnCrmPipelineFromLead, createUOnCrmPipelineFromMark,
  createUOnCrmPipelineFromRequest,
  CrmPipeline,
  CrmPipelineStatus
} from './crm-pipeline.model';
import {combineLatest, forkJoin} from 'rxjs';
import {PlatformAssignationsService} from '../platform-assignations/platform-assignations.service';
import {AssignationPlatforms} from '../platform-assignations/platform-assignation.model';
import {isNullOrUndefined} from 'util';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {UOnTravelLeadStatusModel} from './backend-models/u-on-travel-lead-status.model';
import {flatMap, map, tap} from 'rxjs/operators';
import {UOnTravelRequestStatus} from './backend-models/u-on-travel-request-status.model';
import {MoiSkladResponseWrapper} from '../../../models/moi-sklad-response-wrapper';
import {APIReponseWrapperDictionaryStringWithULong} from '../../../api/models/apireponse-wrapper-dictionary-string-with-ulong';
import {LoggingService} from '../../../shared/services/logging.service';
import {Severity} from '@sentry/types';

@Injectable({
  providedIn: 'root'
})
export class CrmPipelinesService {

  private uOnTravelApiPath = `${environment.backendUri}/${environment.apiPrefix}/u-on-travel`;

  constructor(
    private store: CrmPipelinesStore,
    private amoService: AmoService,
    private bitrixService: BitrixService,
    private megaplanService: MegaplanService,
    private assignationService: PlatformAssignationsService,
    private http: HttpClient,
    private moiSkladService: MoySkladService,
    private hashService: HashService,
    private logger: LoggingService
  ) {
  }

  /**
   * Загрузка воронок продаж по типу платформы
   * @param type type of platform
   */
  public LoadPipelines(type: PlatformsTypes) {
    console.log(type, 'LOADING PIPELINES');
    switch (type) {
      case PlatformsTypes.AMO_CRM_EXPORT:
      case PlatformsTypes.AMO_CRM_IMPORT:
        this.LoadAmoCrmPipelines();
        break;
      case PlatformsTypes.BITRIX_EXPORT:
      case PlatformsTypes.BITRIX_IMPORT:
        this.LoadBitrixPipelines();
        break;
      case PlatformsTypes.UON_IMPORT:
      case PlatformsTypes.UON_EXPORT:
        this.LoadUonCrmPipelines();
        break;
      case PlatformsTypes.MEGAPLAN_EXPORT:
      case PlatformsTypes.MEGAPLAN_IMPORT:
        this.LoadMegaPlanPipelines();
        break;
      case PlatformsTypes.MOY_SKLAD_IMPORT:
      case PlatformsTypes.MOY_SKLAD_EXPORT:
        this.LoadMoiSkladCrm();
        break;
    }
  }

  public LoadUonCrmPipelines() {
    this.store.setLoading(true);
    this.store.set([]);

    forkJoin(
      this.http.get(`${this.uOnTravelApiPath}/lead-statuses`),
      this.http.get(`${this.uOnTravelApiPath}/request-statuses`),
      this.http.get(`${this.uOnTravelApiPath}/user-labels`)
    )
      .pipe(
        map(([leadStatuses, requestStatuses, userLabels]) => {
          const response = [];
          response[1] = leadStatuses as UOnTravelLeadStatusModel[];
          response[0] = requestStatuses as UOnTravelRequestStatus[];
          response[2] = userLabels;
          return response;
        })
      )
      .subscribe(([leadStatuses, requestStatuses, userLables]) => {
        const crmPipeline: CrmPipeline[] = [
          {
            id: 1,
            name: 'Заявки',
            entity_id: 0,
            sort: 2,
            statuses: requestStatuses.map(x => createUOnCrmPipelineFromRequest(x)),
            type: 'DEALS'
          },
          {
            id: 2,
            name: 'Обращения',
            entity_id: 0,
            sort: 1,
            statuses: leadStatuses
              .map(x => createUOnCrmPipelineFromLead(x)),
            type: 'LEADS'
          },
          {
            id: 3,
            name: 'Метки',
            entity_id: 0,
            sort: 3,
            statuses: userLables.map(x => createUOnCrmPipelineFromMark(x)),
            type: 'UON_MARKS_EXPORT'
          }
        ];

        this.store.set(crmPipeline);
      });
  }

  /**
   * Загрузка всех воронок из сервиса amoCrm
   * @constructor
   */
  public  LoadAmoCrmPipelines() {
    this.store.setLoading(true);
    this.store.set([]);
    this.amoService.GetPipelines()
      .subscribe((data) => {
        console.log(data, 'DATA,kig');
        if (data) {
          const pipelines = Object.keys(data)
            .map(x => data[x])
            .map(x => createCrmPipelineFromAmoCrm(x));
          this.store.set(pipelines);
        }
        this.store.setLoading(false);
      }, err => {
        this.assignationService.unsubscribePlatform(AssignationPlatforms.AMOCRM.toString());
        this.store.setLoading(false);
      });
  }

  /**
   * Загрузка всех воронок из сервиса Битрикс24
   */
  public LoadBitrixPipelines() {
    this.store.setLoading(true);
    this.store.set([]);
    forkJoin(
      this.bitrixService.GetDealCategoryList(),
      this.bitrixService.GetCrmStatuses({
        start: null,
        filterData: {}
      }),
      this.bitrixService.GetDealCategoryDefault()
    )
      .subscribe(([dealCategories, crmStatuses, defaultPipelineName]) => {
        console.log(dealCategories, crmStatuses, isNullOrUndefined(dealCategories), isNullOrUndefined(crmStatuses), 'Is deals categories empty');
        if (!isNullOrUndefined(dealCategories) || !isNullOrUndefined(crmStatuses)) {
          dealCategories['result'].unshift({
            ID: '0',
            NAME: defaultPipelineName['result'].NAME,
            SORT: 0,
          });
          const pipelines = dealCategories['result'].map(category => ({
            pipelineData: category,
            statuses: crmStatuses['result'].filter(x => category.ID !== '0' ? x.ENTITY_ID === `DEAL_STAGE_${category.ID}` : x.ENTITY_ID === 'DEAL_STAGE')
          }))
            .map(({pipelineData, statuses}) => createCrmPipelineFromBitrix(pipelineData, statuses));

          pipelines.push({
            id: 9999,
            sort: 0,
            entity_id: 9999,
            name: 'PM_SYSTEM_PIPELINE',
            statuses: crmStatuses['result'].filter(x => x.ENTITY_ID === 'STATUS')
              .map(x => createCrmPipelineStatusFromBitrix(x, null, 'LEADS')),
            type: 'LEADS'
          });

          this.store.set(pipelines);
        } else {
          console.log('Unsubscribe bitrix24');
          this.assignationService.unsubscribePlatform(AssignationPlatforms.BITRIX24.toString());
        }
      }, err => {
        this.assignationService.unsubscribePlatform(AssignationPlatforms.BITRIX24.toString());
        this.store.setLoading(false);
      });
  }

  /**
   * Загрузить все воронки из севиса мегаплан
   * @constructor
   */
  public LoadMegaPlanPipelines() {
    this.store.setLoading(true);
    this.megaplanService.GetProgramsAsync({
      fields: ['states'],
      limit: 100
    })
      .subscribe((data: any) => {
        if (data) {
          const pipelines = data.data.map(program => createCrmPipelineFromMegaplan(program));
          this.store.set(pipelines);
        } else {
          this.assignationService.unsubscribePlatform(AssignationPlatforms.MEGAPLAN.toString());
        }
        this.store.setLoading(false);
      }, err => {
        this.assignationService.unsubscribePlatform(AssignationPlatforms.MEGAPLAN.toString());
        this.store.setLoading(false);
      });
    // this.megaplanService.GetPrograms()
    //   .subscribe(data => {
    //     const pipelines = data.map(program => createCrmPipelineFromMegaplan(program));
    //     this.store.set(pipelines);
    //     this.store.setLoading(false);
    //   }, err => {
    //     this.store.setError(err.error);
    //   });
  }

  private LoadMoiSkladCrm() {
    this.logger.AddBreadcrumb('Начался процесс получения данных о статусах воронок продаж системы "МойСклад".');
    this.store.setLoading(true);
    combineLatest(
      this.LoadCounterpartyMeta(),
      this.LoadCustomerOrderMeta()
    )
      .subscribe(data => {
        this.logger.AddBreadcrumb('Данные о статусах получены и обработаны.');
        this.store.add(data);
        this.store.setLoading(false);
      }, err => {
        console.log(err);
        this.assignationService.unsubscribePlatform(AssignationPlatforms.MOI_SKLAD.toString());
        this.logger.AddBreadcrumb('Не удалось загрузить данные.', err, 'default', Severity.Error);
        this.logger.Error('Ошибка!', err);
        this.store.setLoading(false);
      });
    // this.LoadCounterpartyMeta()
    //   .pipe(
    //     delay(500),
    //     flatMap(() => this.LoadCustomerOrderMeta())
    //   )
    //   .subscribe(data => {
    //     console.log(data);
    //   });
  }

  private LoadCounterpartyMeta() {
    return this.moiSkladService.GetCounterpartyMeta()
      .pipe(
        flatMap(this.GetIds()),
        map(statuses => {
          return {
            name: 'Контрагенты',
            id: 1,
            entity_id: 0,
            sort: 0,
            type: 'CONTACTS',
            statuses: statuses.map((status, index) => ({
              id: status.id,
              selectable_id: status.guid,
              name: status.name,
              type: 'CONTACTS',
              sort: index
            }) as CrmPipelineStatus)
          } as CrmPipeline;
        })
      );
  }

  private GetIds() {
    return (data: MoiSkladResponseWrapper<any>) => {
      const uids = [...data.states.map(x => x.accountId), ...data.states.map(x => x.id)];
      return this.hashService.ConvertUUIDsToId(uids)
        .pipe(
          map((response: APIReponseWrapperDictionaryStringWithULong) => response.data),
          map(idsDictionary => {
            return data.states.map(x => ({
              ...x,
              accountId: idsDictionary[x.accountId],
              id: idsDictionary[x.id],
              guid: x.id,
              accountGuid: x.accountId
            }));
          }),
          // tap(statuses => {
          //   const pipeline = {
          //     name: 'Контрагенты',
          //     id: 1,
          //     entity_id: 0,
          //     sort: 0,
          //     type: 'CONTACTS',
          //     statuses: statuses.map((status, index) => ({
          //       id: status.id,
          //       selectable_id: status.id,
          //       name: status.name,
          //       type: 'CONTACTS',
          //       sort: index
          //     }) as CrmPipelineStatus)
          //   } as CrmPipeline;
          //
          //   this.store.add(pipeline);
          // })
        );
    };
  }

  private LoadCustomerOrderMeta() {
    return this.moiSkladService.GetCustomerOrderMeta()
      .pipe(
        flatMap(this.GetIds()),
        map(statuses => {
          return {
            name: 'Заказы',
            id: 2,
            entity_id: 0,
            sort: 0,
            type: 'ORDERS',
            statuses: statuses.map((status, index) => ({
              id: status.id,
              selectable_id: status.guid,
              name: status.name,
              type: 'ORDERS',
              sort: index
            }) as CrmPipelineStatus)
          } as CrmPipeline;
        })
      );
  }
}
