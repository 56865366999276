import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdminRoutingModule } from './admin-routing.module';
import {ClarityModule} from '@clr/angular';
import { PaymentsComponent } from './routes/payments/payments.component';
import { PromocodesComponent } from './routes/promocodes/promocodes.component';
import {BlogModule} from './blog/blog.module';
import {UsersModule} from './users/users.module';
import {SharedModule} from '../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    AdminRoutingModule,
    ClarityModule,
    BlogModule,
    UsersModule,
    SharedModule
  ],
  declarations: [PaymentsComponent, PromocodesComponent]
})
export class AdminModule { }
