/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PaymentSystem } from '../models/payment-system';
import { TariffPlan } from '../models/tariff-plan';
import { PaymentCreatedResponse } from '../models/payment-created-response';
import { UserPayments } from '../models/user-payments';
@Injectable({
  providedIn: 'root',
})
class PaymentsService extends __BaseService {
  static readonly getPaymentSystemsPath = '/apiv2/payments/systems';
  static readonly getTariffPlansPath = '/apiv2/payments/plans';
  static readonly createPaymentPath = '/apiv2/payments/createpayment';
  static readonly Apiv2PaymentsPaymentconfirmationPostPath = '/apiv2/payments/paymentconfirmation';
  static readonly getUserPaymentsPath = '/apiv2/payments';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return Success
   */
  getPaymentSystemsResponse(): __Observable<__StrictHttpResponse<Array<PaymentSystem>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/payments/systems`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PaymentSystem>>;
      })
    );
  }
  /**
   * @return Success
   */
  getPaymentSystems(): __Observable<Array<PaymentSystem>> {
    return this.getPaymentSystemsResponse().pipe(
      __map(_r => _r.body as Array<PaymentSystem>)
    );
  }

  /**
   * @return Success
   */
  getTariffPlansResponse(): __Observable<__StrictHttpResponse<Array<TariffPlan>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/payments/plans`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TariffPlan>>;
      })
    );
  }
  /**
   * @return Success
   */
  getTariffPlans(): __Observable<Array<TariffPlan>> {
    return this.getTariffPlansResponse().pipe(
      __map(_r => _r.body as Array<TariffPlan>)
    );
  }

  /**
   * @param params The `PaymentsService.CreatePaymentParams` containing the following parameters:
   *
   * - `timeId`:
   *
   * - `tariffPlanId`:
   *
   * - `paymentSystemId`:
   *
   * - `optionId`:
   *
   * - `promocodeId`:
   *
   * @return Success
   */
  createPaymentResponse(params: PaymentsService.CreatePaymentParams): __Observable<__StrictHttpResponse<PaymentCreatedResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.timeId != null) __params = __params.set('timeId', params.timeId.toString());
    if (params.tariffPlanId != null) __params = __params.set('tariffPlanId', params.tariffPlanId.toString());
    if (params.paymentSystemId != null) __params = __params.set('paymentSystemId', params.paymentSystemId.toString());
    if (params.optionId != null) __params = __params.set('optionId', params.optionId.toString());
    if (params.promocodeId != null) __params = __params.set('promocodeId', params.promocodeId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/apiv2/payments/createpayment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PaymentCreatedResponse>;
      })
    );
  }
  /**
   * @param params The `PaymentsService.CreatePaymentParams` containing the following parameters:
   *
   * - `timeId`:
   *
   * - `tariffPlanId`:
   *
   * - `paymentSystemId`:
   *
   * - `optionId`:
   *
   * - `promocodeId`:
   *
   * @return Success
   */
  createPayment(params: PaymentsService.CreatePaymentParams): __Observable<PaymentCreatedResponse> {
    return this.createPaymentResponse(params).pipe(
      __map(_r => _r.body as PaymentCreatedResponse)
    );
  }

  /**
   * @param params The `PaymentsService.Apiv2PaymentsPaymentconfirmationPostParams` containing the following parameters:
   *
   * - `log`:
   *
   * - `paymentDataJSON`:
   *
   * @return Success
   */
  Apiv2PaymentsPaymentconfirmationPostResponse(params: PaymentsService.Apiv2PaymentsPaymentconfirmationPostParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.log != null) __params = __params.set('log', params.log.toString());
    __body = params.paymentDataJSON;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/apiv2/payments/paymentconfirmation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `PaymentsService.Apiv2PaymentsPaymentconfirmationPostParams` containing the following parameters:
   *
   * - `log`:
   *
   * - `paymentDataJSON`:
   *
   * @return Success
   */
  Apiv2PaymentsPaymentconfirmationPost(params: PaymentsService.Apiv2PaymentsPaymentconfirmationPostParams): __Observable<string> {
    return this.Apiv2PaymentsPaymentconfirmationPostResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @return Success
   */
  getUserPaymentsResponse(): __Observable<__StrictHttpResponse<Array<UserPayments>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/payments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<UserPayments>>;
      })
    );
  }
  /**
   * @return Success
   */
  getUserPayments(): __Observable<Array<UserPayments>> {
    return this.getUserPaymentsResponse().pipe(
      __map(_r => _r.body as Array<UserPayments>)
    );
  }
}

module PaymentsService {

  /**
   * Parameters for createPayment
   */
  export interface CreatePaymentParams {
    timeId: number;
    tariffPlanId: number;
    paymentSystemId: number;
    optionId: number;
    promocodeId?: number;
  }

  /**
   * Parameters for Apiv2PaymentsPaymentconfirmationPost
   */
  export interface Apiv2PaymentsPaymentconfirmationPostParams {
    log: boolean;
    paymentDataJSON?: string;
  }
}

export { PaymentsService }
