import {AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {PlatformsTypes} from '../../../../platforms-types';
import {AkitaNgFormsManager} from '@datorama/akita-ng-forms-manager';
import {unionAllArraysFromObjectsToArray} from '../../../../../utils/js/arrays';
import {PlatformsQuery} from '../../../../stores/platforms/platforms.query';
import {AdNetworkFormsQuery} from '../../../../stores/ad-network-forms/ad-network-forms.query';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AssignationPlatforms} from '../../../../stores/platform-assignations/platform-assignation.model';
import {CrmPipelinesService} from '../../../../stores/crm-pipelines/crm-pipelines.service';
import {PlatformAssignationsQuery} from '../../../../stores/platform-assignations/platform-assignations.query';
import {CrmUsersService} from '../../../../stores/crm-users/crm-users.service';
import {untilDestroyed} from 'ngx-take-until-destroy';
import {combineLatest, forkJoin} from 'rxjs';
import {IntegrationPlatformField} from '../../../../../api/models/integration-platform-field';
import {IntegrationPlatformSection} from '../../../../../api/models/integration-platform-section';
import {CrmSectionValidator} from '../../../../validators/crm-section-validator';
import {PlatformsService} from '../../../../stores/platforms/platforms.service';
import {CrmPipelinesQuery} from '../../../../stores/crm-pipelines/crm-pipelines.query';
import {combineQueries, toBoolean} from '@datorama/akita';
import {debounceTime, distinctUntilChanged, map, tap} from 'rxjs/operators';
import {BitrixPipelinesSettingsMode} from '../../../bitrix/components/bitrix-export-settings/bitrix-export-settings.component';
import {BitrixService} from '../../../../../api/services';
import {CrmOrganisationService} from '../../../../stores/crm-organisation/crm-organisation.service';
import {CustomFieldsService} from "../../../../stores/custom-fields/custom-fields.service";
import {CustomFieldsQuery} from "../../../../stores/custom-fields/custom-fields.query";
import {CustomFieldValueService} from "../../../../stores/custom-field-value/custom-field-value.service";
import {PageEvent} from "@angular/material/paginator";

@Component({
  selector: 'app-import-crm',
  templateUrl: './import-crm.component.html',
  styleUrls: ['./import-crm.component.scss']
})
export class ImportCrmComponent implements OnInit, AfterViewInit, OnDestroy {

  public destinationPlatform$ = this.platformsQuery.selectedDestinationPlatform$
    .pipe(
      // map(x => {
      //   console.log(x, 'SECTIONS OF COMPONENT');
      //   x.sections = x.sections.filter(x => this.ShowLeads() ? true : x.code !== 'LEADS');
      //   return x;
      // })
    );

  public sections$ = combineLatest(
    this.platformsQuery.hasLeads$,
    this.platformsQuery.selectedDestinationPlatform$
  )
    .pipe(
      map(([hasLeads, x]) => x.sections.filter(section => {
        return hasLeads ? true : section.code !== 'LEADS';
      }))
    );

  public forms$ = this.formsQuery.selectedForms;
  public forms = [];
  public pipelines;
  public showPipelines = false;

  @Input() public type: PlatformsTypes;
  @Input() public platform: AssignationPlatforms;

  public get visibleForms() {
    return this.forms.filter((_, i) => {
      return i >= this.currentPage * 10 && i < (this.currentPage + 1) * 10
    })
  }

  public resultForm: FormGroup;
  public showAdditionalQuery$ = this.platformsQuery.selectedOriginPlatformCode$
    .pipe(
      map(originalPlatformCode => ['FB_LEAD_FORMS'].includes(originalPlatformCode))
    );
  currentPage: number = 0;
  countOfPages: number = 1;

  public IsLeadSelected(formId) {
    return this.fm.selectValue('destinationPlatformSettings')
      .pipe(
        // distinctUntilChanged(),
        map(formData => {
          // this.cdr.detectChanges();
          return formData && formData[formId] && formData[formId].LEADS ? formData[formId].LEADS.selected : false;
        }),
      );
  }

  constructor(
    private fm: AkitaNgFormsManager,
    private platformsQuery: PlatformsQuery,
    private formsQuery: AdNetworkFormsQuery,
    private fb: FormBuilder,
    private pipelineSettings: CrmPipelinesService,
    private crmUsersService: CrmUsersService,
    private assginationsQuery: PlatformAssignationsQuery,
    private platformService: PlatformsService,
    private pipelineQuery: CrmPipelinesQuery,
    private bitrixService: BitrixService,
    private organisationService: CrmOrganisationService,
    private cdr: ChangeDetectorRef,
    private customFieldsService: CustomFieldsService,
    private customFieldsQuery: CustomFieldsQuery,
    private customFieldsValuesService: CustomFieldValueService,
  ) {
  }

  ngOnInit() {
    this.fm.upsert('destinationPlatformSettings', this.fb.group({}));
    this.pipelineQuery.pipelines$.subscribe(data => {
      this.pipelines = data;
      this.showPipelines = data.length > 1;
    });
    this.formsQuery.selectedForms.subscribe(forms => {
      this.forms = forms;
      this.countOfPages = Math.ceil(forms.length / 10)
      console.log(this.countOfPages, 'COUNT OF PAGES');
    })
    combineLatest(
      this.fm.selectValue('originPlatformSettings'),
      this.platformsQuery.selectedOriginPlatformCode$
    )
      .pipe(
        untilDestroyed(this)
      )
      .subscribe(([data, code]) => {
        const form = this.fm.getForm('destinationPlatformSettings');
        if (form) {
          const lastFormValue = form.value;
          this.fm.remove('destinationPlatformSettings');
          this.resultForm = this.fb.group({});
          let formIds = unionAllArraysFromObjectsToArray(data);
          if (code === PlatformsTypes.MT_LEAD_FORMS) {
            formIds = Object.keys(data)
              .map(x => ({formId: x, isSelected: data[x]}))
              .filter(({isSelected}) => isSelected)
              .map(({formId}) => formId);
          }
          combineLatest(
            this.destinationPlatform$,
            this.customFieldsQuery.CustomFields$
          )
            .pipe(
              // distinctUntilChanged(),
              // debounceTime(1000)
            )
            .subscribe(([destinationPlatform, customFields]) => {
              this.platformService.ClearRequiredFields();
              const globalForm = {};
              formIds
                .forEach(formId => {
                  globalForm[formId] = {};
                  // console.log(, 'FORM VALUE');
                  const formSettings = {
                    includeCampaignName: this.fb.control(form.value[formId] ? form.value[formId].includeCampaignName : false),
                    includeLeadFormName: this.fb.control(form.value[formId] ? form.value[formId].includeLeadFormName : false),
                    bindContact: this.fb.control(form.value[formId] ? form.value[formId].bindContact : true)
                  };
                  if (destinationPlatform) {
                    const currentFormValue = lastFormValue ? lastFormValue[formId] : null;
                    destinationPlatform.sections.forEach(section => {
                      const sectionForm = {};
                      const customFieldsForm = [];
                      const currentSectionValue = currentFormValue ? currentFormValue[section.code] : null;
                      section.fields.forEach(field => {
                        let fieldValue = currentSectionValue ? currentSectionValue.fields[field.code] : null;
                        if (field.isRequired) {
                          this.platformService.AddRequiredField(`${formId}_${section.code}_${field.code}`);
                        }

                        if (field.code === 'PM_PIPELINE_ID') {
                          fieldValue = fieldValue || this.pipelines.length > 0 ? this.pipelines[0].id : null;
                        }

                        sectionForm[field.code] = this.fb.control(fieldValue, []);
                      });
                      customFields.forEach(CustomField => {
                        // customFieldsForm[CustomField.Key] = this.fb.control('')
                        sectionForm[CustomField.Key] = this.fb.control(null, []);
                        customFieldsForm.push(CustomField.Key);
                      })
                      formSettings[section.code] = this.GenerateSectionForm(section, sectionForm, currentSectionValue ? currentSectionValue.selected : false, customFieldsForm);
                    });
                    globalForm[formId] = this.fb.group(formSettings);
                  }

                  this.resultForm = this.fb.group(globalForm);
                });

              this.fm
                .upsert('destinationPlatformSettings', this.resultForm, {arrControlFactory: () => this.fb.control(null)});
            });
        }
      });
    this.assginationsQuery
      .isPlatformAssigned$(this.type)
      .pipe(
        distinctUntilChanged(),
        untilDestroyed(this)
      )
      .subscribe(data => {
        this.pipelineSettings.LoadPipelines(this.type);
        this.crmUsersService.LoadUsers(this.type, null);
        this.organisationService.LoadOrganisations(this.type);
        this.customFieldsService.LoadCustomFields(this.platform)
          .subscribe();
        if (this.platform === AssignationPlatforms.BITRIX24) {
          this.bitrixService.GetCrmSettingsMode()
            .pipe(map(response => {
              return response['result']
            }))
            .subscribe(crmSettingsMode => {
              this.platformService
                .SetParameters(AssignationPlatforms.BITRIX24, {
                  hasLeads: BitrixPipelinesSettingsMode.CLASSIC_MODE === crmSettingsMode
                });
            });
        }
      });
  }

  public ClearFromsControls(formIds) {
    Object.keys(this.resultForm.controls)
      .forEach(formControlName => {
        if (!formIds.includes(formControlName)) {
          this.resultForm.removeControl(formControlName);
        }
      });
  }

  private GetFieldValidators(field: IntegrationPlatformField) {
    const validators = [];
    if (field.isRequired) {
      validators.push(Validators.required);
    }

    return validators;
  }

  private GenerateSectionForm(section: IntegrationPlatformSection, sectionForm, selected, sectionCustomFields = []) {
    const fieldsFormGroup = this.fb.group(sectionForm);
    const form = this.fb.group({
      selected: this.fb.control(section.isRequired || selected),
      fields: fieldsFormGroup,
      customFields: this.fb.control(sectionCustomFields)
    }, {
      validators: [
        CrmSectionValidator
      ]
    });

    form.get('selected')
      .valueChanges
      .subscribe(selectedData => {
        if (selectedData) {
          this.EnableSectionValidators(section, form);
        } else {
          this.DisabeSectionValidators(section, form);
        }
      });

    return form;
  }

  private DisabeSectionValidators(section: IntegrationPlatformSection, form) {
    section.fields.forEach(field => {
      form.get('fields').get(field.code).setValidators([]);
    });
  }

  private EnableSectionValidators(section: IntegrationPlatformSection, form) {
    section.fields.forEach(field => {
      const validators = this.GetFieldValidators(field);
      form.get('fields').get(field.code).setValidators(validators);
    });
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
  }

  GetFormControl(id: number | string, code: any) {
    const form = this.resultForm.controls[id] as FormGroup;
    if (form) {
      const group = form.controls[code];
      if (group) {
        return group;
      }
      return null;
    }
    return null;
  }

  GetFormGroup(id: number) {
    const form = this.resultForm.controls[id] as FormGroup;
    if (form) {
      return form;
    } else {
      return null;
    }
  }

  private IsInitedFormId(x: any) {
    const isFormInited = toBoolean(this.resultForm.get(x));
    return isFormInited;
  }

  public ShowLeads() {
    return this.platformsQuery.HasLeads;
  }

  ShowAdditionalSettings() {
    return this.type === PlatformsTypes.BITRIX_IMPORT &&
      (this.platformsQuery.selectedOriginPlatformCode === PlatformsTypes.FB_LEAD_FORMS)
      ;
  }

  ChangePage($event: PageEvent) {
    this.currentPage = $event.pageIndex;
  }
}
