/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./new-images-manager.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../new-ad-image/new-ad-image.component.ngfactory";
import * as i3 from "../new-ad-image/new-ad-image.component";
import * as i4 from "@angular/common";
import * as i5 from "@clr/angular";
import * as i6 from "../../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i7 from "@angular/material/icon";
import * as i8 from "./new-images-manager.component";
var styles_NewImagesManagerComponent = [i0.styles];
var RenderType_NewImagesManagerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NewImagesManagerComponent, data: {} });
export { RenderType_NewImagesManagerComponent as RenderType_NewImagesManagerComponent };
function View_NewImagesManagerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "images-manager__image"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-new-ad-image", [], null, [[null, "selectedChange"], [null, "removeImage"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedChange" === en)) {
        var pd_0 = (_co.OnSelectImage($event) !== false);
        ad = (pd_0 && ad);
    } if (("removeImage" === en)) {
        var pd_1 = (_co.RemoveImage($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_NewAdImageComponent_0, i2.RenderType_NewAdImageComponent)), i1.ɵdid(3, 114688, null, 0, i3.NewAdImageComponent, [], { image: [0, "image"], minWidth: [1, "minWidth"], minHeight: [2, "minHeight"], maxImageSize: [3, "maxImageSize"], selected: [4, "selected"] }, { selectedChange: "selectedChange", removeImage: "removeImage" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.minWidth; var currVal_2 = _co.minHeight; var currVal_3 = _co.maxImageSize; var currVal_4 = _co.selectedImages; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_NewImagesManagerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "images-manager"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, "div", [["class", "images-manager__images"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NewImagesManagerComponent_1)), i1.ɵdid(3, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 8, "div", [["class", "images-manager__trigger"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 7, "label", [["class", "images-manager-trigger"]], [[1, "for", 0]], null, null, null, null)), i1.ɵdid(6, 212992, null, 0, i5.ClrLabel, [[2, i5.ɵbe], [2, i5.ɵbf], [2, i5.ɵbg], i1.Renderer2, i1.ElementRef], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [["class", "images-manager-trigger__icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["color", "primary"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i6.View_MatIcon_0, i6.RenderType_MatIcon)), i1.ɵdid(9, 9158656, null, 0, i7.MatIcon, [i1.ElementRef, i7.MatIconRegistry, [8, null], [2, i7.MAT_ICON_LOCATION]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["add_a_photo"])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "div", [["class", "images-manager-trigger__hidden"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, [["file", 1]], null, 0, "input", [["accept", "image/jpeg,image/png,image/bmp,image/tif,image/gif"], ["multiple", ""], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.FilesSelected(i1.ɵnov(_v, 12).files) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.files; _ck(_v, 3, 0, currVal_0); _ck(_v, 6, 0); var currVal_4 = "primary"; _ck(_v, 9, 0, currVal_4); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 6).forAttr; _ck(_v, 5, 0, currVal_1); var currVal_2 = i1.ɵnov(_v, 9).inline; var currVal_3 = (((i1.ɵnov(_v, 9).color !== "primary") && (i1.ɵnov(_v, 9).color !== "accent")) && (i1.ɵnov(_v, 9).color !== "warn")); _ck(_v, 8, 0, currVal_2, currVal_3); }); }
export function View_NewImagesManagerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-new-images-manager", [], null, null, null, View_NewImagesManagerComponent_0, RenderType_NewImagesManagerComponent)), i1.ɵdid(1, 114688, null, 0, i8.NewImagesManagerComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NewImagesManagerComponentNgFactory = i1.ɵccf("app-new-images-manager", i8.NewImagesManagerComponent, View_NewImagesManagerComponent_Host_0, { minWidth: "minWidth", minHeight: "minHeight", maxCountOfSelected: "maxCountOfSelected" }, { selected: "selected" }, []);
export { NewImagesManagerComponentNgFactory as NewImagesManagerComponentNgFactory };
