var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { TariffPlansQuery } from "../../stores/tariff-plans/tariff-plans.query";
import { TimePeriodsQuery } from "../../stores/time-periods/time-periods.query";
import { map } from "rxjs/operators";
import { combineLatest } from "rxjs";
import { PromocodeQuery } from "../../stores/promocode/promocode.query";
import * as i0 from "@angular/core";
import * as i1 from "../../stores/tariff-plans/tariff-plans.query";
import * as i2 from "../../stores/time-periods/time-periods.query";
import * as i3 from "../../stores/promocode/promocode.query";
var NewPaymentsModelService = /** @class */ (function () {
    function NewPaymentsModelService(tariffPlansQuery, timePeriodsQuery, promocodesQuery) {
        this.tariffPlansQuery = tariffPlansQuery;
        this.timePeriodsQuery = timePeriodsQuery;
        this.promocodesQuery = promocodesQuery;
        this.paymentsSelected = false;
    }
    Object.defineProperty(NewPaymentsModelService.prototype, "groupsWithPrices", {
        get: function () {
            var _this = this;
            var selectedTariffGroups$ = this.tariffPlansQuery.selectedTariffPlans$;
            return combineLatest(this.GetSelecteTariffPlans(selectedTariffGroups$), this.timePeriodsQuery.selectedTariffPlans$, this.promocodesQuery.promocode$)
                .pipe(map(function (_a) {
                var selectedTariffPlans = _a[0], selectedTimePeriods = _a[1];
                return selectedTariffPlans.map(function (selectedTariffPlan) { return (__assign({}, selectedTariffPlan, { selectedTimePeriod: selectedTimePeriods["tariffGroup" + selectedTariffPlan.tariffGroupId] })); });
            }), map(function (selectedTariffPlans) { return _this.FormatSelectedTariffPlans(selectedTariffPlans); }));
        },
        enumerable: true,
        configurable: true
    });
    NewPaymentsModelService.prototype.GetSelecteTariffPlans = function (selectedTariffGroups$) {
        return selectedTariffGroups$
            .pipe(map(function (selectedTariffGroups) { return Object.keys(selectedTariffGroups)
            .map(function (x) { return x.replace('tariffGroup', ''); })
            .map(function (tariffGroupId) { return ({
            tariffGroupId: parseInt(tariffGroupId, 10),
            selectedTariffPlan: parseInt(selectedTariffGroups["tariffGroup" + tariffGroupId], 10)
        }); })
            .filter(function (x) { return x.selectedTariffPlan !== 0; }); }));
    };
    NewPaymentsModelService.prototype.FormatSelectedTariffPlans = function (selectedTariffPlans) {
        var _this = this;
        console.log(selectedTariffPlans, 'SELECTED TARIFF PLANS');
        var data = selectedTariffPlans.map(function (selectedTariffPlan) { return (__assign({}, selectedTariffPlan, { name: _this.tariffPlansQuery.getTariffGroupName(selectedTariffPlan.selectedTariffPlan), price: _this.tariffPlansQuery.getTariffPlanPrice(selectedTariffPlan.selectedTariffPlan, selectedTariffPlan.selectedTimePeriod), timePeriod: _this.timePeriodsQuery.getTimePeriodNameById(selectedTariffPlan.selectedTimePeriod) })); });
        console.log(data, 'PRICE CHECKING');
        return data;
    };
    NewPaymentsModelService.prototype.TogglePayment = function () {
        this.paymentsSelected = !this.paymentsSelected;
    };
    NewPaymentsModelService.ngInjectableDef = i0.defineInjectable({ factory: function NewPaymentsModelService_Factory() { return new NewPaymentsModelService(i0.inject(i1.TariffPlansQuery), i0.inject(i2.TimePeriodsQuery), i0.inject(i3.PromocodeQuery)); }, token: NewPaymentsModelService, providedIn: "root" });
    return NewPaymentsModelService;
}());
export { NewPaymentsModelService };
