import {Injectable} from '@angular/core';
import {RetargetingGroupsStore} from './retargeting-groups.store';
import {
  AdManagerService,
  AutomationVkService,
  AutomationVkSlotService, FacebookCustomAudienceService,
  MyTargetService,
  VkApiService,
  YandexAudienceSegmentService,
} from '../../../api/services';
import {delay, flatMap, tap} from 'rxjs/operators';
import {waterfall} from '../../../utils/rxjs-operators/waterfall';
import {
  createCustomAudienceFromFacebook,
  createRemarketingGroupFromMt,
  createRetargetingGroupFromVk,
  RetargetingGroup,
  createRemarketingGroupFromYandexAudience
} from './retargeting-group.model';
import {PlatformsTypes} from '../../platforms-types';
import {of} from 'rxjs';
import {toBoolean} from "@datorama/akita";

@Injectable({
  providedIn: 'root'
})
export class RetargetingGroupsService {
  public accounts: any;
  public slots: any;
  public allAccounts: any;

  constructor(
    private store: RetargetingGroupsStore,
    private adManager: AdManagerService,
    private vkSlotService: AutomationVkSlotService,
    private vkService: AutomationVkService,
    private mt: MyTargetService,
    private vkApi: VkApiService,
    private fbAudience: FacebookCustomAudienceService,
    private yandexService: YandexAudienceSegmentService
  ) {
  }

  public LoadVkAccountData() {
    return this.LoadSlots()
      .pipe(
        flatMap(() => this.LoadAccounts()),
        flatMap(() => this.LoadClients()),
        tap(() => {
          this.accounts = this.accounts
            .filter(x => this.slots.find(slot => slot.bindedCabinetId === x.account_id && slot.bindedClientId === x.client_id));
        })
      );
  }

  public LoadSlots() {
    return this.vkSlotService.GetSlots()
      .pipe(
        tap(({data}) => {
          this.slots = data;
        }),
        delay(1000)
      );
  }

  public LoadRetargetingGroupsFromVk(accountId, clientId) {
    this.vkApi.GetAdsTargetGroupsAsync({
      accountId,
      clientId,
      key: 'integration'
    })
      .subscribe(data => {
        const retargetingGroups = data
          .filter(x => ! x.is_shared)
          .sort((a, b) => b.id - a.id).map(x => createRetargetingGroupFromVk(x))
        this.store.set(retargetingGroups);
      });
  }

  public setRemarketingGroupsFilter(remarketingGroups: RetargetingGroup[]) {
    const containsEmails = toBoolean(remarketingGroups.find(x => x.type === 'emails'));
    const containsPhones = toBoolean(remarketingGroups.find(x => x.type === 'phones'));

    if (!(containsPhones && containsEmails)) {
      const filter = remarketingGroups[0].type;
      this.SetFilter(filter)
    }
  }

  public LoadRemarketingGroupsFromMt() {
    this.store.setLoading(true);
    this.mt.GetRemarketingGroups()
      .subscribe(({data}) => {
        if (data) {
          const remarketingGroups = data
            .sort((a, b) => a.id - b.id)
            .map(x => createRemarketingGroupFromMt(x));

          this.store.set(remarketingGroups);
          if (remarketingGroups.length > 0) {
            this.setRemarketingGroupsFilter(remarketingGroups);
          } else {
          }
        }
        this.store.setLoading(false);
      });
  }

  public LoadRemarketingGroups(type: PlatformsTypes, ...params) {
    console.log('LOAD REMARKETING GROUPS', type, params);
    switch (type) {
      case PlatformsTypes.VK_RETARGETING_GROUPS:
        this.LoadRetargetingGroupsFromVk(params[0], params[1]);
        break;

      case PlatformsTypes.MT_REMARKETING_GROUPS:
        this.LoadRemarketingGroupsFromMt();
        break;

      case PlatformsTypes.FB_CUSTOM_AUDIENCES:
        this.LoadCustomAudiences(params[0]);
        break;

      case PlatformsTypes.YANDEX_AUDIENCES:
        this.LoadYandexAudiences();
        break;
    }
  }

  private LoadAccounts() {
    return this.vkApi.GetAccountsAsync('integration')
      .pipe(
        tap((data) => {
          this.accounts = data;
          this.allAccounts = data;
        }),
        delay(1000)
      );
  }

  private LoadClients() {
    const agencyAccounts = this.accounts.filter(x => x.account_type === 'agency');
    const clients = agencyAccounts.map(account => () => this.vkApi.GetClientsAsync({
        accountId: account.account_id,
        key: 'integration'
      })
        .pipe(
          delay(1000),
          tap((data) => {
            console.log(data, 'LOAD CLIENTS', account.account_id);
            data.forEach(client => {
              const toAdd = {
                ...account,
                ...client,
                account_name: client.name,
                account_id: account.account_id,
                client_id: client.id
              };
              console.log(toAdd, 'TO ADD');
              this.accounts.push(toAdd);
              console.log(this.accounts);
            });
          })
        )
    );

    if (clients && clients.length > 0) {
      return waterfall(clients);
    } else {
      return of([]);
    }
  }

  SetFilter(filter: any) {
    this.store.update(state => ({
      ...state,
      ui: {
        filter
      }
    }));
  }

  public LoadCustomAudiences(adAccountId) {
    console.log('LOAD RESTARGETING GROUPS');
    this.fbAudience.FacebookGetCustomAudiencesAsync({
      fields: ['name'],
      adAccountId
    })
      .subscribe((data: any) => {
        this.store.set(data.data.data.map(x => createCustomAudienceFromFacebook(x)))
      })

  }

  public LoadYandexAudiences() {
    this.yandexService.YandexAudienceGetSegments()
    .subscribe((data) => {
      const audiences = data['data'].segments.map(x => createRemarketingGroupFromYandexAudience(x));
      this.store.set(audiences);
    })
  }
}
