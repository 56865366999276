<div class="easy-ads-page-header">
  Контактная информация
</div>

<div class="easy-settings-data-container">
  <div class="easy-settings-data-strip">
    <div class="easy-settings-data-strip__label">
      Напишите нам в ВКонтакте
    </div>

    <div class="easy-settings-data-strip__content">
      <a href="https://vk.me/postmonitor" target="_blank" rel="noopener nofollow" title="Перейти к диалогу в ВКонаткте">vk.me/postmonitor</a>
    </div>
  </div>
</div>

<div class="easy-settings-data-container">
  <div class="easy-settings-data-strip">
    <div class="easy-settings-data-strip__label">
      Напишите нам на E-mail
    </div>

    <div class="easy-settings-data-strip__content">
      support@postmonitor.ru
    </div>
  </div>
</div>
