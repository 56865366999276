import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AffiliateProgramSettingsUserInfoRouteComponent} from "./routes/affiliate-program-settings-user-info-route/affiliate-program-settings-user-info-route.component";
import {AffiliateProgramLayoutComponent} from "../../components/affiliate-program-layout/affiliate-program-layout.component";

const routes: Routes = [
  {
    path: 'affiliate',
    component: AffiliateProgramLayoutComponent,
    children: [
      {
        path: 'settings/info',
        component: AffiliateProgramSettingsUserInfoRouteComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AffiliateProgramSettingsUserInfoRoutingModule { }
