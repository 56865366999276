import { EventEmitter, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
var PromopostLinkWidgetComponent = /** @class */ (function () {
    function PromopostLinkWidgetComponent(_sanitizer) {
        this._sanitizer = _sanitizer;
        this.enableEditingModeForm = 1;
        this.linkTitleSave = new EventEmitter();
    }
    Object.defineProperty(PromopostLinkWidgetComponent.prototype, "Image", {
        get: function () {
            return this._sanitizer.bypassSecurityTrustStyle("url(" + this.linkWidgetData.linkImage + ")");
        },
        enumerable: true,
        configurable: true
    });
    PromopostLinkWidgetComponent.prototype.ngOnInit = function () {
    };
    PromopostLinkWidgetComponent.prototype.startEditingTitle = function () {
        console.log(this.enableEditingModeForm, 'click before');
        this.enableEditingModeForm = this.enableEditingModeForm + 1;
        console.log(this.enableEditingModeForm, 'click');
    };
    return PromopostLinkWidgetComponent;
}());
export { PromopostLinkWidgetComponent };
