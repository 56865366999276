<ng-container *ngIf="conversation">
  <div class="chat-conversation">
    <div class="chat-conversation-owner-meta">
      <a routerLink="/chat/conversation" [queryParams]="{user_id: conversation.userId}" title="Перейти к диалогу"
         class="chat-conversation-owner-meta__owner"
         target="_blank" rel="noopener nofollow">
        {{conversation.userMetaName}}
      </a>

      <ng-container *ngIf="conversation.experience && conversation.experience.level">
        <span class="conversation-owner-experience">
          {{conversation.experience.level.name}}
        </span>
      </ng-container>

      <ng-container *ngIf="conversation.lastMessage && conversation.lastMessage.createdByUser && !conversation.lastMessage.isRead">
        <span class="chat-conversation-owner-meta__unread-conversation">Есть непрочтённые сообщения</span>
      </ng-container>
    </div>
    <ng-container *ngIf="conversation.lastMessage; else noLastMessage">
      <a [ngClass]='{
      "chat-conversation-last-message": true,
      "chat-conversation-last-message_unread": conversation.lastMessage.createdByUser && !conversation.lastMessage.isRead,
      "chat-conversation-last-message_answered-by-admin": !conversation.lastMessage.createdByUser
      }' routerLink="/chat/conversation" [queryParams]="{user_id: conversation.userId}" title="Перейти к диалогу">
        <div class="chat-conversation-last-message__icon-container">
          <i [ngClass]="'icon '+ conversation.mark.icon"></i>
        </div>
        <div class="chat-conversation-last-message__author">
          <span class="chat-conversation-last-message-meta-data chat-conversation-last-message-meta-data_author">
            {{conversation.lastMessage.author}}
          </span>
          <span class="chat-conversation-last-message-meta-data chat-conversation-last-message-meta-data_date">
            {{conversation.lastMessage.createdAt | dateFormating}}
          </span>
        </div>
        <div class="chat-conversation-last-message__text" [innerHtml]="conversation.lastMessage.text | nameShorter : 90"></div>
      </a>
      <!--<app-chat-message [message]="conversation.lastMessage"></app-chat-message>-->
    </ng-container>
    <ng-template #noLastMessage>
      Нет сообщений
    </ng-template>
  </div>
</ng-container>
