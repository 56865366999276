import {Component, Input, OnInit} from '@angular/core';
import {AdNetworkGroupsQuery} from "../../../../stores/ad-network-groups/ad-network-groups.query";
import {AdNetworkForm} from "../../../../stores/ad-network-forms/ad-network-form.model";
import {AdNetworkFormsService} from "../../../../stores/ad-network-forms/ad-network-forms.service";
import {AdNetworkFormsQuery} from "../../../../stores/ad-network-forms/ad-network-forms.query";
import {FormControl} from "@angular/forms";

@Component({
  selector: 'app-lead-form-settings',
  templateUrl: './lead-form-settings.component.html',
  styleUrls: ['./lead-form-settings.component.scss']
})
export class LeadFormSettingsComponent implements OnInit {

  @Input() public form: AdNetworkForm;
  @Input() public control: FormControl;

  public groups$ = this.groupsQuery.groups$;
  public campaigns$;
  public isOpened$;

  constructor(
    private groupsQuery: AdNetworkGroupsQuery,
    private formsQuery: AdNetworkFormsQuery,
    private formsService: AdNetworkFormsService
  ) {
  }

  ngOnInit() {
    this.campaigns$ = this.groupsQuery.byIds$(this.form.group_id);
    this.isOpened$ = this.formsQuery.isOpened$(this.form.id);
  }

  ShowCampaigns() {
    this.formsService.OpenForm(this.form.id)
  }
}
