import {Injectable} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UtilsService} from './utils.service';

@Injectable()
export class FiltersService {

    public params: object;
    public filter;
    constructor(
        private router: Router
    ) {
    }

    public SetQueryParams(filter, params) {
        this.params = Object.assign(filter, params);
        this.Navigate();
    }

    public Navigate() {
        const queryParams = this.GetNotNullKeys(this.params);
        this.router.navigate([this.router.url.split('?')[0]], {queryParams});
    }

    public GetNotNullKeys(object): object {
        const result = Object.create({});

        Object
            .keys(object)
            .filter(k => object[k] !== null)
            .filter(k => {
                
                return object[k] !== 'NaN';
            })// Filter of all null keys
            .filter(k => !k.startsWith('_') && k !== 'closed' && k !== 'isStopped' && k !== 'hasError' && k !== 'observers')
            .forEach(key => {
                result[key] = object[key];
            });

        return result;
    }

}
