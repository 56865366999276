import { OnInit } from '@angular/core';
import { UserBalanceQuery } from "../../../../../storage/user-balance/state/user-balance.query";
var FullBalanceTileContentComponent = /** @class */ (function () {
    function FullBalanceTileContentComponent(userBalanceQuery) {
        this.userBalanceQuery = userBalanceQuery;
    }
    Object.defineProperty(FullBalanceTileContentComponent.prototype, "amount$", {
        get: function () {
            return this.userBalanceQuery.fullBalance$;
        },
        enumerable: true,
        configurable: true
    });
    FullBalanceTileContentComponent.prototype.ngOnInit = function () {
    };
    return FullBalanceTileContentComponent;
}());
export { FullBalanceTileContentComponent };
