import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NewPaymentsRoutingModule } from './new-payments-routing.module';
import {PaymentSystemSelectorComponent} from './components/payment-system-selector/payment-system-selector.component';
import {PaymentSystemSelectorTriggerComponent} from './components/payment-system-selector-trigger/payment-system-selector-trigger.component';
import {PaymentSystemMiniComponent} from './components/payment-system-mini/payment-system-mini.component';
import {TimePeriodSelectorComponent} from './components/time-period-selector/time-period-selector.component';
import {TariffPlanSelectorComponent} from './components/tariff-plan-selector/tariff-plan-selector.component';
import {TariffPlanHeaderComponent} from './components/tariff-plan-header/tariff-plan-header.component';
import { CreatePaymentComponent } from './routes/create-payment/create-payment.component';
import { PaymentsListComponent } from './components/payments-list/payments-list.component';
import { TarifPlanFeatureNameComponent } from './components/tarif-plan-feature-name/tarif-plan-feature-name.component';
import { TariffPlanFeatureStatusComponent } from './components/tariff-plan-feature-status/tariff-plan-feature-status.component';
import {MatIconModule} from '@angular/material';
import { PromocodeFormComponent } from './components/promocode-form/promocode-form.component';
import {VkControlsModule} from '../vk-controls/vk-controls.module';
import {ReactiveFormsModule} from '@angular/forms';
import { PromocodeMessageComponent } from './components/promocode-message/promocode-message.component';
import {SelectedTariffPlanComponent} from './components/selected-tariff-plan/selected-tariff-plan.component';
import { SelectedPaymentSystemComponent } from './components/selected-payment-system/selected-payment-system.component';
import {SharedModule} from '../shared/shared.module';
import { PaymentLinkResultComponent } from './components/payment-link-result/payment-link-result.component';
import { PaymentInfoPanelComponent } from './components/payment-info-panel/payment-info-panel.component';

@NgModule({
  declarations: [
    TimePeriodSelectorComponent,
    TariffPlanSelectorComponent,
    TariffPlanHeaderComponent,
    PaymentSystemMiniComponent,
    PaymentSystemSelectorComponent,
    PaymentSystemSelectorTriggerComponent,
    CreatePaymentComponent,
    PaymentsListComponent,
    TarifPlanFeatureNameComponent,
    TariffPlanFeatureStatusComponent,
    PromocodeFormComponent,
    PromocodeMessageComponent,
    SelectedTariffPlanComponent,
    SelectedPaymentSystemComponent,
    PaymentLinkResultComponent,
    PaymentInfoPanelComponent
  ],
  imports: [
    CommonModule,
    NewPaymentsRoutingModule,
    MatIconModule,
    VkControlsModule,
    ReactiveFormsModule,
    SharedModule
  ]
})
export class NewPaymentsModule { }
