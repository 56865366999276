import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ChipListComponent} from './components/chip-list/chip-list.component';
import {ChipComponent} from './components/chip/chip.component';
import {DynamicFormComponent} from './components/dynamic-form/dynamic-form.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ClarityModule, ClrFormsModule} from '@clr/angular';
import { TimePickerComponent } from './components/time-picker/time-picker.component';
import { DataGridComponent } from './components/data-grid/data-grid.component';
import { DgColumnComponent } from './components/dg-column/dg-column.component';
import { DgPaginationComponent } from './components/dg-pagination/dg-pagination.component';
import { DgActionBarComponent } from './components/dg-action-bar/dg-action-bar.component';
import {CKEditorModule} from 'ng2-ckeditor';
import {DatetimePickerComponent} from './components/datetime-picker/datetime-picker.component';
import {NgxMyDatePickerModule} from 'ngx-mydatepicker';
import { LoadingScreenComponent } from './components/loading-screen/loading-screen.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import {MatCheckboxModule, MatProgressSpinner, MatProgressSpinnerModule} from '@angular/material';
import {PickListNgrComponent} from './components/pick-list/pick-list-ngr.component';
import {PicklistOptionComponent} from './components/picklist-option/picklist-option.component';
import { EditableColumnDirective } from './directives/editable-column.directive';
import { DgContentDirective } from './directives/dg-content.directive';
import { DgColumnHeaderDirective } from './directives/dg-column-header.directive';
import { LoadingDirective } from './directives/loading.directive';
import { DgLoaderComponent } from './components/dg-loader/dg-loader.component';
import { DgColumnFilterDirective } from './directives/dg-column-filter.directive';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ClarityModule,
    ClrFormsModule,
    CKEditorModule,
    NgxMyDatePickerModule.forRoot(),
    MatCheckboxModule,
    MatProgressSpinnerModule
  ],
  declarations: [ChipListComponent, ChipComponent, DynamicFormComponent, TimePickerComponent, DataGridComponent, DgColumnComponent, DgPaginationComponent, DgActionBarComponent, DatetimePickerComponent, LoadingScreenComponent, ConfirmDialogComponent, PickListNgrComponent, PicklistOptionComponent, EditableColumnDirective, DgContentDirective, DgColumnHeaderDirective, LoadingDirective, DgLoaderComponent, DgColumnFilterDirective],
  exports: [ChipListComponent, ChipComponent, DynamicFormComponent, DataGridComponent, DgColumnComponent, DgColumnComponent, DgPaginationComponent, DgActionBarComponent, DatetimePickerComponent, LoadingScreenComponent, ConfirmDialogComponent, PickListNgrComponent, PicklistOptionComponent, EditableColumnDirective, DgContentDirective, DgColumnHeaderDirective, DgColumnFilterDirective],
})
export class NgrUiModule {
}
