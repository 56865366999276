<div class="analytic-services-strip">
  <div class="analytic-services-strip__item">
    <div class="analytic-service">
      <div class="analytic-service-name">
        <span>Яндекс.Метрика</span>
        <span *ngIf="yandexMetrikaConnected"></span>
      </div>
      <div class="analytic-service-data-block">
        <ng-container *ngIf="yandexMetrikaConnected;else connectYandexMetrika">
          <div class="analytic-service-selectors">
            <div class="analytic-service-selectors__item">
              <div class="analytic-service-selector">
                <!--<div class="analytic-service-selector__item">-->
                  <!--<label for="service-yandex-metrika-counter-selector">Счётчик</label>-->
                <!--</div>-->
                <div class="analytic-service-selector__item">
                  <div class="select">
                    <select id="service-yandex-metrika-counter-selector"
                            (change)="SelectCounterEvent()" [(ngModel)]="yandexSelectedCounterId">
                      <option value="null">Выберите счётчик</option>

                      <ng-container *ngFor="let option of availabeCounter">
                        <option [value]="option.id">{{option.name}}</option>
                      </ng-container>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="analytic-service-selectors__item">
              <div class="analytic-service-selector">
                <!--<div class="analytic-service-selector__item">-->
                  <!--<label for="service-yandex-metrika-goal-selector">Цель</label>-->
                <!--</div>-->
                <div class="analytic-service-selector__item">
                  <div class="select">
                    <select id="service-yandex-metrika-goal-selector" [disabled]="IsSelectorDisabled(yandexSelectedCounterId)"
                            (change)="SelectGoalEvent()" [(ngModel)]="yandexSelectedGoalId">
                      <option value="null">Выберите цель</option>

                      <ng-container *ngFor="let option of availabeGoals">
                        <option [value]="option.id">{{option.name}}</option>
                      </ng-container>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-template #connectYandexMetrika>
          <a class="btn btn-success" [href]="GetBindUrl()" (click)="BindUri($event)">Подключить</a>
        </ng-template>
      </div>
    </div>
  </div>
</div>
