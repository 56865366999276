var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { QueryEntity } from '@datorama/akita';
import { TariffPlansStore } from './tariff-plans.store';
import { TimePeriodsQuery } from '../time-periods/time-periods.query';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { PromocodeQuery } from '../promocode/promocode.query';
import * as i0 from "@angular/core";
import * as i1 from "./tariff-plans.store";
import * as i2 from "../time-periods/time-periods.query";
import * as i3 from "../promocode/promocode.query";
var TariffPlansQuery = /** @class */ (function (_super) {
    __extends(TariffPlansQuery, _super);
    function TariffPlansQuery(store, timePeriods, promocodesQuery) {
        var _this = _super.call(this, store) || this;
        _this.store = store;
        _this.timePeriods = timePeriods;
        _this.promocodesQuery = promocodesQuery;
        _this.tariifPlans$ = _this.selectAll();
        _this.selectedTariffPlan$ = _this.selectActive();
        _this.selectedTariffPlanId$ = _this.selectedTariffPlan$
            .pipe(map(function (x) { return x.id; }));
        _this.tariffPlanPrice = function (tariffPlanId, tariffId) {
            return combineLatest(_this.timePeriods.activePeriod$(tariffPlanId), _this.selectEntity(tariffPlanId)
                .pipe(map(function (x) { return x.packages.find(function (x) { return x.id === tariffId; }); })), _this.promocodesQuery.promocode$)
                .pipe(map(function (_a) {
                var timePeriod = _a[0], tariff = _a[1], promocode = _a[2];
                return CalculateTarifPrice(timePeriod, tariff, promocode);
            }));
        };
        _this.tariffCustomPrice$ = function (tariffGroupId, tariffId) {
            return combineLatest(_this.timePeriods.activePeriod$, _this.selectEntity(tariffGroupId).pipe(map(function (tariffGroup) { return tariffGroup.packages.find(function (tariffPackage) { return tariffPackage.id === tariffId; }); })))
                .pipe(map(function (_a) {
                var timePeriod = _a[0], tariff = _a[1];
                return GetCustomPrice(timePeriod, tariff);
            }));
        };
        return _this;
    }
    Object.defineProperty(TariffPlansQuery.prototype, "selectedTariffPlans", {
        get: function () {
            return this.getValue().ui.selectedTariffPlans;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TariffPlansQuery.prototype, "selectedTariffPlans$", {
        get: function () {
            return this.select(function (state) { return state.ui.selectedTariffPlans; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TariffPlansQuery.prototype, "tariffGroups", {
        get: function () {
            return this.getAll();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TariffPlansQuery.prototype, "tariffPlans", {
        get: function () {
            return this.tariffGroups
                .map(function (x) { return x.packages; })
                .reduce(function (all, current) { return all.concat(current); });
        },
        enumerable: true,
        configurable: true
    });
    TariffPlansQuery.prototype.getTariffPlanPrice = function (tariffPlanId, timePeriodId) {
        var tariffPlan = this.tariffPlans.find(function (x) { return x.id === tariffPlanId; });
        var timePeriod = this.timePeriods.getEntity(timePeriodId);
        var promocode = this.promocodesQuery.promocode;
        console.log(promocode, 'PROMOCODE');
        // let price = null;
        // let result = null;
        // if (tariffPlan) {
        //   price = tariffPlan.prices.find(x => x.periodId === timePeriodId)
        // }
        // console.log(price, 'CURRENT PRICE DATA', timePeriodId);
        // if (price) {
        //   result = price.customPrice ? price.customPrice : price.price
        // }
        return CalculateTarifPrice(timePeriod, tariffPlan, promocode);
    };
    TariffPlansQuery.prototype.getTariffGroupName = function (selectedTariffPlan) {
        var tariffPlan = this.tariffPlans.find(function (x) { return x.id === selectedTariffPlan; });
        console.log(tariffPlan, selectedTariffPlan, 'getTariffGroupName');
        return this
            .getAll()
            .find(function (tariffGroup) { return tariffGroup.id === tariffPlan['groupId']; })
            .paymentGroupName + (" - " + tariffPlan.name);
    };
    TariffPlansQuery.ngInjectableDef = i0.defineInjectable({ factory: function TariffPlansQuery_Factory() { return new TariffPlansQuery(i0.inject(i1.TariffPlansStore), i0.inject(i2.TimePeriodsQuery), i0.inject(i3.PromocodeQuery)); }, token: TariffPlansQuery, providedIn: "root" });
    return TariffPlansQuery;
}(QueryEntity));
export { TariffPlansQuery };
/**
 * Расчет стоимости тарифа
 * @param timePeriod - период (1, 3, 6 или 12 месяцев)
 * @param tariff - тариф
 * @param promocode - данные промокода
 * @constructor
 */
export function CalculateTarifPrice(timePeriod, tariff, promocode) {
    console.log(timePeriod, tariff, promocode, 'PRICE GOOD');
    var price = timePeriod ? tariff.prices.find(function (x) { return x.periodId === timePeriod.id; }).price : 0;
    if (promocode) {
        console.log(price, promocode.value, 'PRICE');
        price = price - (price * (promocode.value / 100));
        console.log(price, 'PRICE');
    }
    return price;
}
function GetCustomPrice(timePeriod, tariffPlan) {
    var currentPeriod = tariffPlan.prices.find(function (x) { return x.periodId === timePeriod.id; });
    console.log(currentPeriod, 'CURRENT PERIOD');
    var price = timePeriod ? currentPeriod.customPrice : null;
    console.log(tariffPlan, timePeriod, price);
    return price;
}
