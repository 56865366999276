<div class="card">
    <div class="card-block">
        <div class="partner">
            <div class="partner__image">
                <img [src]="partner.image_src" [alt]="partner.title">
            </div>
            <h2 class="partner__title">{{partner.title}}</h2>
            <div class="partner__description" [innerHtml]="partner.description"></div>
            <div class="partner__link">
                <a [href]="partner.link" class="btn btn-primary btn-block" target="_blank" rel="noopener">Перейти</a>
            </div>
        </div>
    </div>
</div>