import {AbstractCopyingConfigurator} from "./abstract-copying-configurator";
import {PostMonitorBackend_V2ModelsAdManagerCopyAdsViewModel} from "../../../api/models";
import {AgeSegmentSex} from "../../models/view-models/age-segment";

export class SexAndAgeCopyingConfigurator extends AbstractCopyingConfigurator {

  constructor(props) {
    super(props)
  }

  generate(): PostMonitorBackend_V2ModelsAdManagerCopyAdsViewModel[] {
    const adsSpecifications: PostMonitorBackend_V2ModelsAdManagerCopyAdsViewModel[] = [];
    this.copyingData.sexAndAgeConfiguration.ageItems.forEach(ageAndSexSegment => {
      const specification = this.generateDefeault();
      if (ageAndSexSegment.eachAge) {
        for(let i = ageAndSexSegment.ageFrom; i <= ageAndSexSegment.ageTo;i++) {
          const specs = this.createSegments(specification, i, i, ageAndSexSegment.sex);
          for (let spec of specs) {
            adsSpecifications.push(spec);
          }
        }
      } else {
        const specs = this.createSegments(specification, ageAndSexSegment.ageFrom, ageAndSexSegment.ageTo, ageAndSexSegment.sex)
        for (let spec of specs) {
          console.log(spec, 'SPECA');
          adsSpecifications.push(Object.assign({}, spec));
        }
      }
    });
    return adsSpecifications;
  }

  private createSegments(specification: PostMonitorBackend_V2ModelsAdManagerCopyAdsViewModel, ageFrom, ageTo, sex) {
    let specs = [];
    switch (sex) {
      case AgeSegmentSex.ALL:
        specification = Object.assign({
          sex: null,
          ageFrom,
          ageTo
        }, specification, {
          sex: null,
          ageFrom,
          ageTo
        });
        specs = [Object.assign({}, specification)];
        break;
      case AgeSegmentSex.MALE:
      case AgeSegmentSex.FEMALE:
        specification = Object.assign({
          sex,
          ageFrom,
          ageTo
        }, specification, {
          sex,
          ageFrom,
          ageTo
        });
        console.log(specification);
        specs = [Object.assign({}, specification)];
        break;
      case AgeSegmentSex.SPLIT:
        console.log('SPLIT SEX');
        for (let z = 1; z <= 2; z++) {
          const spec = Object.assign(specification, {
            sex: z,
            ageFrom,
            ageTo
          });
          specs.push(Object.assign({}, spec));
          console.log(spec);
        }
        break;
    }
    return specs;
  }

}
