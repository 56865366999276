import {Injectable} from '@angular/core';
import {QueryEntity} from '@datorama/akita';
import {PaymentSystemsStore, PaymentSystemsState} from './payment-systems.store';
import {Observable} from 'rxjs';
import {PaymentsSystem} from './payment-system.model';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class PaymentSystemsQuery extends QueryEntity<PaymentSystemsState> {

  /**
   * Список всех платежных систем
   */
  public paymentSystems$ = this.selectAll();

  /**
   * Выбранная платежная система
   */
  public selectedPaymentSystem$ = this.selectActive() as Observable<PaymentsSystem>;

  /**
   * Id выбранной платежной системы
   */
  public selectedPaymentSystemId$ = this.selectedPaymentSystem$
    .pipe(
      map(x => x.id)
    );

  /**
   * Статус загрузки
   */
  public paymentSystemLoading$ = this.select(state => state.ui.prepareLinkLoading);

  /**
   * Ссылка на страницу оплаты (ссылка на Paymaster)
   */
  public linkToPayment$ = this.select(state => state.ui.linkToPayment);

  constructor(protected store: PaymentSystemsStore) {
    super(store);
  }

}
