import {AbstractCopyingConfigurator} from "./abstract-copying-configurator";
import {PostMonitorBackend_V2ModelsAdManagerCopyAdsViewModel} from "../../../api/models";

export class GroupActivesFileCopyingConfigurator extends AbstractCopyingConfigurator {

  constructor(props) {
    super(props);
  }

  generate(): PostMonitorBackend_V2ModelsAdManagerCopyAdsViewModel[] {
    const ads = [];
    console.log(this.copyingData);
    this.copyingData.groupActiveFromFile.groupActiveList.forEach(groupId => {
      const ad = this.generateDefeault();
      ad.groupsActive = groupId.toString();
      ads.push(ad);
    });
    return ads;
  }

}
