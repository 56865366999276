/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./new-ad-format-step.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../ad-manager/components/ad-format/ad-format.component.ngfactory";
import * as i3 from "../../../../ad-manager/components/ad-format/ad-format.component";
import * as i4 from "@angular/forms";
import * as i5 from "@angular/common";
import * as i6 from "../../components/new-ad-format-preview/new-ad-format-preview.component.ngfactory";
import * as i7 from "../../components/new-ad-format-preview/new-ad-format-preview.component";
import * as i8 from "./new-ad-format-step.component";
import * as i9 from "../../services/ad-form-manager/ad-form-manager.service";
var styles_NewAdFormatStepComponent = [i0.styles];
var RenderType_NewAdFormatStepComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NewAdFormatStepComponent, data: {} });
export { RenderType_NewAdFormatStepComponent as RenderType_NewAdFormatStepComponent };
function View_NewAdFormatStepComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "app-ad-format", [["formControlName", "format"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "selectedFieldChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedFieldChange" === en)) {
        var pd_0 = (_co.SelectField($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_AdFormatComponent_0, i2.RenderType_AdFormatComponent)), i1.ɵdid(2, 114688, null, 0, i3.AdFormatComponent, [], { selectedField: [0, "selectedField"], adFormat: [1, "adFormat"] }, { selectedFieldChange: "selectedFieldChange" }), i1.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.AdFormatComponent]), i1.ɵdid(4, 671744, null, 0, i4.FormControlName, [[3, i4.ControlContainer], [8, null], [8, null], [6, i4.NG_VALUE_ACCESSOR], [2, i4.ɵangular_packages_forms_forms_k]], { name: [0, "name"] }, null), i1.ɵprd(2048, null, i4.NgControl, null, [i4.FormControlName]), i1.ɵdid(6, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.SelectedAdFormat.value; var currVal_8 = _v.context.$implicit; _ck(_v, 2, 0, currVal_7, currVal_8); var currVal_9 = "format"; _ck(_v, 4, 0, currVal_9); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 6).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 6).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 6).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 6).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 6).ngClassValid; var currVal_5 = i1.ɵnov(_v, 6).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 6).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_NewAdFormatStepComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i4.ɵangular_packages_forms_forms_bh, [], null, null), i1.ɵdid(2, 540672, null, 0, i4.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i4.ControlContainer, null, [i4.FormGroupDirective]), i1.ɵdid(4, 16384, null, 0, i4.NgControlStatusGroup, [[4, i4.ControlContainer]], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 6, "div", [["class", "ad-form-format"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "ad-form-format__formats-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NewAdFormatStepComponent_1)), i1.ɵdid(8, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "ad-form-format__preview"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "app-new-ad-format-preview", [], null, null, null, i6.View_NewAdFormatPreviewComponent_0, i6.RenderType_NewAdFormatPreviewComponent)), i1.ɵdid(11, 114688, null, 0, i7.NewAdFormatPreviewComponent, [], { adFormat: [0, "adFormat"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.form; _ck(_v, 2, 0, currVal_7); var currVal_8 = _co.formats; _ck(_v, 8, 0, currVal_8); var currVal_9 = _co.SelectedAdFormat; _ck(_v, 11, 0, currVal_9); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 4).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 4).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 4).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 4).ngClassValid; var currVal_5 = i1.ɵnov(_v, 4).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 4).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_NewAdFormatStepComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-new-ad-format-step", [], null, null, null, View_NewAdFormatStepComponent_0, RenderType_NewAdFormatStepComponent)), i1.ɵdid(1, 114688, null, 0, i8.NewAdFormatStepComponent, [i9.AdFormManagerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NewAdFormatStepComponentNgFactory = i1.ɵccf("app-new-ad-format-step", i8.NewAdFormatStepComponent, View_NewAdFormatStepComponent_Host_0, {}, {}, []);
export { NewAdFormatStepComponentNgFactory as NewAdFormatStepComponentNgFactory };
