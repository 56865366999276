<ng-container *ngIf="type === 'VK_RETARGETING_GROUPS'">
  <app-retargeting-groups-settings [platform]="platform" [type]="type"></app-retargeting-groups-settings>
</ng-container>

<ng-container *ngIf="type === 'FB_CUSTOM_AUDIENCES'">
  <app-retargeting-groups-settings [platform]="platform" [type]="type"></app-retargeting-groups-settings>
</ng-container>
<ng-container *ngIf="type === 'MT_REMARKETING_GROUPS'">
  <app-remarketing-groups-settings></app-remarketing-groups-settings>
</ng-container>
<ng-container *ngIf="type === 'YANDEX_AUDIENCES'">
  <app-yandex-audiences-import [type]="type"></app-yandex-audiences-import>
</ng-container>