<form [formGroup]="controlContainer.control">
  <div class="teaser-form-lite">
    <div class="teaser-form-lite__left">
      <!--<a-->
      <!--rel="nofollow noopener"-->
      <!--style="text-align:right; margin: 0; margin-bottom: 8px;"-->
      <!--target="_blank"-->
      <!--href="/vk/hidden"-->
      <!--class="btn btn-success btn-sm"-->
      <!--&gt;-->
      <!--Посмотреть примеры объявлений-->
      <!--</a>-->
      <ng-container *ngIf="!isUpdating">

        <ng-container *ngIf="ShowGroupControls">
          <ng-container [ngSwitch]="CountOfOpenedGroups()">
            <ng-container *ngSwitchCase="0">
              <div class="alert alert-danger alert_ad-form-lite">
                <div class="alert-items">
                  <div class="alert-item static">
                    <div class="alert-text">
                      Нет ни одной группы для создания объявления.
                      Вам необходимо создать группу и вернуться на данную страницу.
                      Примечание: Не возможно создать объявление если у вас нет группы или она закрытая.
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="1"></ng-container>
            <ng-container *ngSwitchDefault>
              <vk-form-field [labelWidth]="labelWidth" [controlsWidth]="controlWidth">
                <vk-label>Выберите сообщество</vk-label>
                <select vkInput formControlName="groupId">
                  <ng-container *ngFor="let group of groups">
                    <option [value]="group.id">{{group.name}}</option>
                  </ng-container>
                </select>
              </vk-form-field>
            </ng-container>
          </ng-container>
        </ng-container>

        <vk-form-field [labelWidth]="labelWidth" [controlsWidth]="controlWidth" *ngIf="!ShowGroupControls">
          <vk-label>Введите ссылку на объявление</vk-label>
          <input type="text" maxlength="33" vkInput formControlName="link"/>

          <vk-error name="link" validator="required">Вы не ввели ссылку на объявление</vk-error>
          <vk-error name="link" validator="url">Введенная вами ссылка не валидна</vk-error>
        </vk-form-field>
      </ng-container>

      <vk-form-field [labelWidth]="labelWidth" [controlsWidth]="controlWidth">
        <vk-label>Текст объявления</vk-label>
        <input type="text" maxlength="33" vkInput #adText formControlName="title"/>
        <vk-hint>Осталось символов: {{33 - adText.value.length}}</vk-hint>

        <vk-error name="title" validator="required">Вы не ввели текст вашего объявления</vk-error>
      </vk-form-field>
    </div>
    <div class="teaser-form-lite__right">
      <app-teazer
        [teazer]="controlContainer.control.value"
        [image]="controlContainer.control.value.image"
        [title]="controlContainer.control.value.title"
        [defaultTitle]="'Текст объявления'"
      ></app-teazer>
    </div>
  </div>
  <ng-container *ngIf="controlContainer.control.get('image').invalid && controlContainer.control.get('image').dirty">
    <div class="vk-error">Вы не выбрали изображение для вашего объявления</div>
  </ng-container>
  <app-images-manager
    (selected)="SetTeaserImage($event)"
    [minHeight]="165"
    [minWidth]="145"
  >

  </app-images-manager>
</form>
