import {AbstractControl, ValidatorFn} from '@angular/forms';

export class TimeValidators {
  public static hour(params): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } => {
      const hoursValue = parseInt(control.value, 10);
      return {
        hours: control.value !== undefined && hoursValue >= 0 && hoursValue <= 23
      };
    };
  }

  public static minutes(params): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } => {
      const minutesValue = parseInt(control.value, 10);
      return {
        hours: control.value !== undefined && minutesValue >= 0 && minutesValue <= 59
      };
    };
  }
}
