import {Injectable} from '@angular/core';
import {Store, StoreConfig} from '@datorama/akita';

export interface AffiliateUserInfoState {
  affiliateUserId: number;
  organisationName: string;
  organisationFullName: string;
  organisationSpecialisation: string;
  organisationDescription: string;
  organisationWebSite: string;
  organisationInn: string;
  organisationOgrn: string;
  organisationKpp: string;
  ndsPayerTypeId: string;
  organisationLegalAddress: string;
  organisationActualAddress: string;
  organisationPhone: string;
  organisationEmail: string;
  contactPersonFirstName: string;
  contactPersonLastName: string;
  contactPersonMiddleName: string;
}

export function createInitialState(): AffiliateUserInfoState {
  return {
    affiliateUserId: null,
    organisationName: null,
    organisationFullName: null,
    organisationSpecialisation: null,
    organisationDescription: null,
    organisationWebSite: null,
    organisationInn: null,
    organisationOgrn: null,
    organisationKpp: null,
    ndsPayerTypeId: null,
    organisationLegalAddress: null,
    organisationActualAddress: null,
    organisationPhone: null,
    organisationEmail: null,
    contactPersonFirstName: null,
    contactPersonLastName: null,
    contactPersonMiddleName: null
  };
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'affiliate-user-info'})
export class AffiliateUserInfoStore extends Store<AffiliateUserInfoState> {

  constructor() {
    super(createInitialState());
  }

}

