var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { PlatformsTypes } from '../../platforms-types';
import { CrmPipelinesStore } from './crm-pipelines.store';
import { AmoService, BitrixService, HashService, MegaplanService, MoySkladService } from '../../../api/services';
import { createCrmPipelineFromAmoCrm, createCrmPipelineFromBitrix, createCrmPipelineFromMegaplan, createCrmPipelineStatusFromBitrix, createUOnCrmPipelineFromLead, createUOnCrmPipelineFromMark, createUOnCrmPipelineFromRequest } from './crm-pipeline.model';
import { combineLatest, forkJoin } from 'rxjs';
import { PlatformAssignationsService } from '../platform-assignations/platform-assignations.service';
import { AssignationPlatforms } from '../platform-assignations/platform-assignation.model';
import { isNullOrUndefined } from 'util';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { flatMap, map } from 'rxjs/operators';
import { LoggingService } from '../../../shared/services/logging.service';
import { Severity } from '@sentry/types';
import * as i0 from "@angular/core";
import * as i1 from "./crm-pipelines.store";
import * as i2 from "../../../api/services/amo.service";
import * as i3 from "../../../api/services/bitrix.service";
import * as i4 from "../../../api/services/megaplan.service";
import * as i5 from "../platform-assignations/platform-assignations.service";
import * as i6 from "@angular/common/http";
import * as i7 from "../../../api/services/moy-sklad.service";
import * as i8 from "../../../api/services/hash.service";
import * as i9 from "../../../shared/services/logging.service";
var CrmPipelinesService = /** @class */ (function () {
    function CrmPipelinesService(store, amoService, bitrixService, megaplanService, assignationService, http, moiSkladService, hashService, logger) {
        this.store = store;
        this.amoService = amoService;
        this.bitrixService = bitrixService;
        this.megaplanService = megaplanService;
        this.assignationService = assignationService;
        this.http = http;
        this.moiSkladService = moiSkladService;
        this.hashService = hashService;
        this.logger = logger;
        this.uOnTravelApiPath = environment.backendUri + "/" + environment.apiPrefix + "/u-on-travel";
    }
    /**
     * Загрузка воронок продаж по типу платформы
     * @param type type of platform
     */
    CrmPipelinesService.prototype.LoadPipelines = function (type) {
        console.log(type, 'LOADING PIPELINES');
        switch (type) {
            case PlatformsTypes.AMO_CRM_EXPORT:
            case PlatformsTypes.AMO_CRM_IMPORT:
                this.LoadAmoCrmPipelines();
                break;
            case PlatformsTypes.BITRIX_EXPORT:
            case PlatformsTypes.BITRIX_IMPORT:
                this.LoadBitrixPipelines();
                break;
            case PlatformsTypes.UON_IMPORT:
            case PlatformsTypes.UON_EXPORT:
                this.LoadUonCrmPipelines();
                break;
            case PlatformsTypes.MEGAPLAN_EXPORT:
            case PlatformsTypes.MEGAPLAN_IMPORT:
                this.LoadMegaPlanPipelines();
                break;
            case PlatformsTypes.MOY_SKLAD_IMPORT:
            case PlatformsTypes.MOY_SKLAD_EXPORT:
                this.LoadMoiSkladCrm();
                break;
        }
    };
    CrmPipelinesService.prototype.LoadUonCrmPipelines = function () {
        var _this = this;
        this.store.setLoading(true);
        this.store.set([]);
        forkJoin(this.http.get(this.uOnTravelApiPath + "/lead-statuses"), this.http.get(this.uOnTravelApiPath + "/request-statuses"), this.http.get(this.uOnTravelApiPath + "/user-labels"))
            .pipe(map(function (_a) {
            var leadStatuses = _a[0], requestStatuses = _a[1], userLabels = _a[2];
            var response = [];
            response[1] = leadStatuses;
            response[0] = requestStatuses;
            response[2] = userLabels;
            return response;
        }))
            .subscribe(function (_a) {
            var leadStatuses = _a[0], requestStatuses = _a[1], userLables = _a[2];
            var crmPipeline = [
                {
                    id: 1,
                    name: 'Заявки',
                    entity_id: 0,
                    sort: 2,
                    statuses: requestStatuses.map(function (x) { return createUOnCrmPipelineFromRequest(x); }),
                    type: 'DEALS'
                },
                {
                    id: 2,
                    name: 'Обращения',
                    entity_id: 0,
                    sort: 1,
                    statuses: leadStatuses
                        .map(function (x) { return createUOnCrmPipelineFromLead(x); }),
                    type: 'LEADS'
                },
                {
                    id: 3,
                    name: 'Метки',
                    entity_id: 0,
                    sort: 3,
                    statuses: userLables.map(function (x) { return createUOnCrmPipelineFromMark(x); }),
                    type: 'UON_MARKS_EXPORT'
                }
            ];
            _this.store.set(crmPipeline);
        });
    };
    /**
     * Загрузка всех воронок из сервиса amoCrm
     * @constructor
     */
    CrmPipelinesService.prototype.LoadAmoCrmPipelines = function () {
        var _this = this;
        this.store.setLoading(true);
        this.store.set([]);
        this.amoService.GetPipelines()
            .subscribe(function (data) {
            console.log(data, 'DATA,kig');
            if (data) {
                var pipelines = Object.keys(data)
                    .map(function (x) { return data[x]; })
                    .map(function (x) { return createCrmPipelineFromAmoCrm(x); });
                _this.store.set(pipelines);
            }
            _this.store.setLoading(false);
        }, function (err) {
            _this.assignationService.unsubscribePlatform(AssignationPlatforms.AMOCRM.toString());
            _this.store.setLoading(false);
        });
    };
    /**
     * Загрузка всех воронок из сервиса Битрикс24
     */
    CrmPipelinesService.prototype.LoadBitrixPipelines = function () {
        var _this = this;
        this.store.setLoading(true);
        this.store.set([]);
        forkJoin(this.bitrixService.GetDealCategoryList(), this.bitrixService.GetCrmStatuses({
            start: null,
            filterData: {}
        }), this.bitrixService.GetDealCategoryDefault())
            .subscribe(function (_a) {
            var dealCategories = _a[0], crmStatuses = _a[1], defaultPipelineName = _a[2];
            console.log(dealCategories, crmStatuses, isNullOrUndefined(dealCategories), isNullOrUndefined(crmStatuses), 'Is deals categories empty');
            if (!isNullOrUndefined(dealCategories) || !isNullOrUndefined(crmStatuses)) {
                dealCategories['result'].unshift({
                    ID: '0',
                    NAME: defaultPipelineName['result'].NAME,
                    SORT: 0,
                });
                var pipelines = dealCategories['result'].map(function (category) { return ({
                    pipelineData: category,
                    statuses: crmStatuses['result'].filter(function (x) { return category.ID !== '0' ? x.ENTITY_ID === "DEAL_STAGE_" + category.ID : x.ENTITY_ID === 'DEAL_STAGE'; })
                }); })
                    .map(function (_a) {
                    var pipelineData = _a.pipelineData, statuses = _a.statuses;
                    return createCrmPipelineFromBitrix(pipelineData, statuses);
                });
                pipelines.push({
                    id: 9999,
                    sort: 0,
                    entity_id: 9999,
                    name: 'PM_SYSTEM_PIPELINE',
                    statuses: crmStatuses['result'].filter(function (x) { return x.ENTITY_ID === 'STATUS'; })
                        .map(function (x) { return createCrmPipelineStatusFromBitrix(x, null, 'LEADS'); }),
                    type: 'LEADS'
                });
                _this.store.set(pipelines);
            }
            else {
                console.log('Unsubscribe bitrix24');
                _this.assignationService.unsubscribePlatform(AssignationPlatforms.BITRIX24.toString());
            }
        }, function (err) {
            _this.assignationService.unsubscribePlatform(AssignationPlatforms.BITRIX24.toString());
            _this.store.setLoading(false);
        });
    };
    /**
     * Загрузить все воронки из севиса мегаплан
     * @constructor
     */
    CrmPipelinesService.prototype.LoadMegaPlanPipelines = function () {
        var _this = this;
        this.store.setLoading(true);
        this.megaplanService.GetProgramsAsync({
            fields: ['states'],
            limit: 100
        })
            .subscribe(function (data) {
            if (data) {
                var pipelines = data.data.map(function (program) { return createCrmPipelineFromMegaplan(program); });
                _this.store.set(pipelines);
            }
            else {
                _this.assignationService.unsubscribePlatform(AssignationPlatforms.MEGAPLAN.toString());
            }
            _this.store.setLoading(false);
        }, function (err) {
            _this.assignationService.unsubscribePlatform(AssignationPlatforms.MEGAPLAN.toString());
            _this.store.setLoading(false);
        });
        // this.megaplanService.GetPrograms()
        //   .subscribe(data => {
        //     const pipelines = data.map(program => createCrmPipelineFromMegaplan(program));
        //     this.store.set(pipelines);
        //     this.store.setLoading(false);
        //   }, err => {
        //     this.store.setError(err.error);
        //   });
    };
    CrmPipelinesService.prototype.LoadMoiSkladCrm = function () {
        var _this = this;
        this.logger.AddBreadcrumb('Начался процесс получения данных о статусах воронок продаж системы "МойСклад".');
        this.store.setLoading(true);
        combineLatest(this.LoadCounterpartyMeta(), this.LoadCustomerOrderMeta())
            .subscribe(function (data) {
            _this.logger.AddBreadcrumb('Данные о статусах получены и обработаны.');
            _this.store.add(data);
            _this.store.setLoading(false);
        }, function (err) {
            console.log(err);
            _this.assignationService.unsubscribePlatform(AssignationPlatforms.MOI_SKLAD.toString());
            _this.logger.AddBreadcrumb('Не удалось загрузить данные.', err, 'default', Severity.Error);
            _this.logger.Error('Ошибка!', err);
            _this.store.setLoading(false);
        });
        // this.LoadCounterpartyMeta()
        //   .pipe(
        //     delay(500),
        //     flatMap(() => this.LoadCustomerOrderMeta())
        //   )
        //   .subscribe(data => {
        //     console.log(data);
        //   });
    };
    CrmPipelinesService.prototype.LoadCounterpartyMeta = function () {
        return this.moiSkladService.GetCounterpartyMeta()
            .pipe(flatMap(this.GetIds()), map(function (statuses) {
            return {
                name: 'Контрагенты',
                id: 1,
                entity_id: 0,
                sort: 0,
                type: 'CONTACTS',
                statuses: statuses.map(function (status, index) { return ({
                    id: status.id,
                    selectable_id: status.guid,
                    name: status.name,
                    type: 'CONTACTS',
                    sort: index
                }); })
            };
        }));
    };
    CrmPipelinesService.prototype.GetIds = function () {
        var _this = this;
        return function (data) {
            var uids = data.states.map(function (x) { return x.accountId; }).concat(data.states.map(function (x) { return x.id; }));
            return _this.hashService.ConvertUUIDsToId(uids)
                .pipe(map(function (response) { return response.data; }), map(function (idsDictionary) {
                return data.states.map(function (x) { return (__assign({}, x, { accountId: idsDictionary[x.accountId], id: idsDictionary[x.id], guid: x.id, accountGuid: x.accountId })); });
            }));
        };
    };
    CrmPipelinesService.prototype.LoadCustomerOrderMeta = function () {
        return this.moiSkladService.GetCustomerOrderMeta()
            .pipe(flatMap(this.GetIds()), map(function (statuses) {
            return {
                name: 'Заказы',
                id: 2,
                entity_id: 0,
                sort: 0,
                type: 'ORDERS',
                statuses: statuses.map(function (status, index) { return ({
                    id: status.id,
                    selectable_id: status.guid,
                    name: status.name,
                    type: 'ORDERS',
                    sort: index
                }); })
            };
        }));
    };
    CrmPipelinesService.ngInjectableDef = i0.defineInjectable({ factory: function CrmPipelinesService_Factory() { return new CrmPipelinesService(i0.inject(i1.CrmPipelinesStore), i0.inject(i2.AmoService), i0.inject(i3.BitrixService), i0.inject(i4.MegaplanService), i0.inject(i5.PlatformAssignationsService), i0.inject(i6.HttpClient), i0.inject(i7.MoySkladService), i0.inject(i8.HashService), i0.inject(i9.LoggingService)); }, token: CrmPipelinesService, providedIn: "root" });
    return CrmPipelinesService;
}());
export { CrmPipelinesService };
