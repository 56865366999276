/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./new-payments.component";
var styles_NewPaymentsComponent = [];
var RenderType_NewPaymentsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NewPaymentsComponent, data: {} });
export { RenderType_NewPaymentsComponent as RenderType_NewPaymentsComponent };
export function View_NewPaymentsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" new-payments works! "]))], null, null); }
export function View_NewPaymentsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "lib-new-payments", [], null, null, null, View_NewPaymentsComponent_0, RenderType_NewPaymentsComponent)), i0.ɵdid(1, 114688, null, 0, i1.NewPaymentsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NewPaymentsComponentNgFactory = i0.ɵccf("lib-new-payments", i1.NewPaymentsComponent, View_NewPaymentsComponent_Host_0, {}, {}, []);
export { NewPaymentsComponentNgFactory as NewPaymentsComponentNgFactory };
