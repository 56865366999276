import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {VkLayoutRouteComponent} from './routes/vk-layout-route/vk-layout-route.component';
import {VkHomeRouteComponent} from './routes/vk-home-route/vk-home-route.component';
import {GroupsSearchRouteComponent} from './routes/groups-search-route/groups-search-route.component';
import {PostSearchPageComponent} from './routes/post-search-page/post-search-page.component';
import {HiddenPostsSearchComponent} from './routes/hidden-posts-search/hidden-posts-search.component';
import {AuthGuardGuard} from '../shared/guards/auth-guard.guard';
import {EasyStealthComponent} from "./routes/easy-stealth/easy-stealth.component";

const routes: Routes = [
    // {
    //     path: 'vk',
    //     component: VkLayoutRouteComponent,
    //     children: [
    //         {
    //             path: '',
    //             component: VkHomeRouteComponent
    //         },
    //         // {
    //         //     path: 'group',
    //         //     component: GroupsSearchRouteComponent,
    //         //     canActivate: [AuthGuardGuard]
    //         // },
    //         // {
    //         //     path: 'post',
    //         //     component: PostSearchPageComponent,
    //         //     canActivate: [AuthGuardGuard]
    //         // },
    //         // {
    //         //     path: 'hidden',
    //         //     component: HiddenPostsSearchComponent,
    //         //     canActivate: [AuthGuardGuard]
    //         // },
    //         // {
    //         //   path: 'easy-stealth',
    //         //   component: EasyStealthComponent,
    //         //   canActivate: [AuthGuardGuard]
    //         // }
    //     ]
    // }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class VkRoutingModule {
}
