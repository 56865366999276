<ng-container *ngIf="data.isAllAdsIncredibleToCopy; else notAllIncredibleToCopyMessage">
  Выбранный тип объявлений не возможно скопировать. <br>
  <br>
  В данный момент не возможно скопировать:
  <br>
  Рекламные записи в новостной ленте: <br>
  <ol class="incredible-list">
    <li class="incredible-list__item" [ngClass]="ContainsInIncredibleTypes(typesOfIncredibleToCopyAds.PRETTY_CARD)">Карусель.</li>
<!--    <li class="incredible-list__item" [ngClass]="ContainsInIncredibleTypes(typesOfIncredibleToCopyAds.LINK_WITH_BUTTON)">Запись с кнопкой.</li>-->
    <li class="incredible-list__item" [ngClass]="ContainsInIncredibleTypes(typesOfIncredibleToCopyAds.STORIES)">Реклама в Историях.</li>
    <li class="incredible-list__item" [ngClass]="ContainsInIncredibleTypes(typesOfIncredibleToCopyAds.ADVERTISING_SITE)">Реклама сайта.</li>
  </ol>
  <br>
  Рекламные объявления на страницах сайта:
  <br>
  <ol>
    <li>Приложение.</li>
  </ol>

  <button class="btn btn-warning btn-sm" (click)="CancelCopying()">Отменить</button>
</ng-container>
<ng-template #notAllIncredibleToCopyMessage>
  В выбранных объявлениях есть тип объявлений, который не возможно скопировать. <br>
  Не будут скопированы объявления с форматами: <br>
  <ul>
    <li *ngFor="let incredibleType of data.incredibleToCopyTypes">
      {{incredibleType}}
    </li>
  </ul>

  <button class="btn btn-success btn-sm" (click)="StartCopying()">Продолжить копирование</button>
  <button class="btn btn-warning btn-sm" (click)="CancelCopying()">Отменить копирование</button>
</ng-template>

