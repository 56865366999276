import {Component, OnInit} from '@angular/core';
import {ApiBlogService} from '../../../../api/services';
import {ActivatedRoute} from '@angular/router';
import {Post} from '../../../../api/models';

@Component({
  selector: 'app-update-post',
  templateUrl: './update-post.component.html',
  styleUrls: ['./update-post.component.scss']
})
export class UpdatePostComponent implements OnInit {
  public postData = {};
  public Loaded = false;
  public post: any;
  public formModel;

  constructor(
    private postServices: ApiBlogService,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.LoadPost()
      .then(this.LoadTags.bind(this))
      .then(({post, tags}) => {
        this.InitForm(post, tags);
      });
  }

  public LoadPost() {

    this.Loaded = false;
    return new Promise(resolve => {
      this.postServices.GetPostById(this.route.snapshot.params['id'])
        .subscribe((post) => {
          this.post = post;
          resolve(post);
        });
    });
  }

  private LoadTags(post) {
    return new Promise(resolve => {
      this.postServices.GetTags()
        .subscribe(tags => {
          resolve({post, tags});
        });
    });
  }

  private InitForm(post, tags) {

    this.formModel = {
      title: {
        type: 'text',
        name: 'title',
        label: 'Название',
        placeholder: 'Название',
        value: this.post.title,
        Validators: []
      },
      previewImage: {
        type: 'text',
        name: 'previewImage',
        label: 'Картинка для поста (Preview)',
        value: this.post.previewImage,
        Validators: []
      },
      previewText: {
        type: 'textarea',
        name: 'previewText',
        label: 'Краткий текст поста (отобразится в списке всех постов)',
        placeholder: 'Описание',
        value: this.post.previewText,
        Validators: []
      },
      body: {
        type: 'html',
        name: 'body',
        label: 'Поный текст поста',
        placeholder: 'Полный текст поста',
        config: {
          filebrowserUploadUrl: 'http://localhost:6856/api/files',
          onUploadRequest(data) {

          },
          onUploadResponse() {
          }
        },
        value: this.post.body,
        Validators: []
      },
      tags: {
        type: 'checkbox-list',
        name: 'tags',
        options: tags || [],
        label: 'Теги',
        value: this.post.tags,
        Validators: []
      },
      robots: {
        type: 'text',
        name: 'robots',
        label: 'Мета теги для роботов (если нуджно что бы индексировалось то введите index, folow)',
        value: this.post.robots,
        Validators: []
      },
      metaDescription: {
        type: 'textarea',
        name: 'metaDescription',
        label: 'Описание',
        placeholder: 'Описание',
        value: this.post.metaDescription,
        Validators: []
      },
      metaKeywords: {
        type: 'text',
        name: 'metaKeywords',
        label: 'Ключевые слова',
        placeholder: '',
        value: this.post.metaKeywords,
        Validators: []
      }
    };
    this.Loaded = true;
  }

  public HandleSuccess(data) {

    data.postId = this.post.postId;
    this.postData = data;
    console.log(this.postData, 'SUCCESS');
  }

  Save() {
    console.log(this.postData, 'SAVE');
    this.postServices.UpdatePost({
      id: this.post.postId,
      post: this.postData
    })
      .subscribe(qwert => {

        alert('Пост изменен');
      }, err => {
        alert('Ошибка! Обратитесь к программисту!');
      });
  }
}
