import {Injectable} from '@angular/core';
import {TariffPlansQuery} from "../../stores/tariff-plans/tariff-plans.query";
import {TimePeriodsQuery} from "../../stores/time-periods/time-periods.query";
import {flatMap, map} from "rxjs/operators";
import {combineLatest, Observable} from "rxjs";
import {PromocodeQuery} from "../../stores/promocode/promocode.query";

@Injectable({
  providedIn: 'root'
})
export class NewPaymentsModelService {

  public paymentsSelected = false;

  constructor(
    private tariffPlansQuery: TariffPlansQuery,
    private timePeriodsQuery: TimePeriodsQuery,
    private promocodesQuery: PromocodeQuery
  ) { }

  public get groupsWithPrices() {
    const selectedTariffGroups$ = this.tariffPlansQuery.selectedTariffPlans$;
    return combineLatest(
      this.GetSelecteTariffPlans(selectedTariffGroups$),
      this.timePeriodsQuery.selectedTariffPlans$,
      this.promocodesQuery.promocode$
    )
      .pipe(
        map(([selectedTariffPlans, selectedTimePeriods]) => selectedTariffPlans.map(selectedTariffPlan => ({
          ...selectedTariffPlan,
          selectedTimePeriod: selectedTimePeriods[`tariffGroup${selectedTariffPlan.tariffGroupId}`]
        }))),
        map(selectedTariffPlans => this.FormatSelectedTariffPlans(selectedTariffPlans))
      )
  }

  private GetSelecteTariffPlans(selectedTariffGroups$: Observable<{ tariffGroup1: number; tariffGroup2: number; tariffGroup3: number }>) {
    return selectedTariffGroups$
      .pipe(
        map(selectedTariffGroups => Object.keys(selectedTariffGroups)
          .map(x => x.replace('tariffGroup', ''))
          .map(tariffGroupId => ({
            tariffGroupId: parseInt(tariffGroupId, 10),
            selectedTariffPlan: parseInt(selectedTariffGroups[`tariffGroup${tariffGroupId}`], 10)
          }))
          .filter(x => x.selectedTariffPlan !== 0)
        )
      );
  }

  private FormatSelectedTariffPlans(selectedTariffPlans: any[]) {
    console.log(selectedTariffPlans, 'SELECTED TARIFF PLANS');
    const data = selectedTariffPlans.map(selectedTariffPlan => ({
      ...selectedTariffPlan,
      name: this.tariffPlansQuery.getTariffGroupName(selectedTariffPlan.selectedTariffPlan),
      price: this.tariffPlansQuery.getTariffPlanPrice(selectedTariffPlan.selectedTariffPlan, selectedTariffPlan.selectedTimePeriod),
      timePeriod: this.timePeriodsQuery.getTimePeriodNameById(selectedTariffPlan.selectedTimePeriod)
    }));
    console.log(data, 'PRICE CHECKING');
    return data;
  }

  public TogglePayment() {
    this.paymentsSelected = !this.paymentsSelected;
  }
}
