var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { map as __map, filter as __filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../api-configuration";
import * as i2 from "@angular/common/http";
var PresentationsService = /** @class */ (function (_super) {
    __extends(PresentationsService, _super);
    function PresentationsService(config, http) {
        return _super.call(this, config, http) || this;
    }
    /**
     * @param code undefined
     */
    PresentationsService.prototype.PresentationByCodeGetResponse = function (code) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('GET', this.rootUrl + ("/presentation/" + encodeURIComponent(code)), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param code undefined
     */
    PresentationsService.prototype.PresentationByCodeGet = function (code) {
        return this.PresentationByCodeGetResponse(code).pipe(__map(function (_r) { return _r.body; }));
    };
    PresentationsService.PresentationByCodeGetPath = '/presentation/{code}';
    PresentationsService.ngInjectableDef = i0.defineInjectable({ factory: function PresentationsService_Factory() { return new PresentationsService(i0.inject(i1.ApiConfiguration), i0.inject(i2.HttpClient)); }, token: PresentationsService, providedIn: "root" });
    return PresentationsService;
}(__BaseService));
export { PresentationsService };
