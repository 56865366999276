import {AfterViewChecked, AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ControlContainer, FormControl, FormGroup} from '@angular/forms';
import {AutomationVkService} from '../../../../../api/services';

@Component({
  selector: 'app-ad-start-settings',
  templateUrl: './ad-start-settings.component.html',
  styleUrls: ['./ad-start-settings.component.scss']
})
export class AdStartSettingsComponent implements OnInit, AfterViewChecked {

  public budget: number = 0;

  @Input() public accountId;
  @Input() public clientId;
  @Input() public AccountRole;

  @Input() disabled: boolean = false;
  @Output() public onNext: EventEmitter<any> = new EventEmitter();

  public budgetInfo = `Для того, чтобы можно было показывать объявление пользователям ВКонтакте,
   необходимо пополнить бюджет вашего рекламного кабинета. 
Средства будут списываться когда объявление будет показываться \nпользователям.
`;

  public get IsAdmin() {
    return this.AccountRole === 'admin';
  }

  public form: FormGroup;

  constructor(
    public controlContainer: ControlContainer,
    private automationVkService: AutomationVkService
  ) {
  }

  ngOnInit() {
    setTimeout(() => {
      this.automationVkService.GetBudget({
        accountId: this.accountId,
        clientId: this.clientId
      })
        .subscribe(budgetResponse => {
          this.budget = budgetResponse.data
          console.log(budgetResponse);
        });
    }, 10000);
  }

  ngAfterViewChecked(): void {

  }

  public next() {
    this.onNext.emit(this.form.value);
  }

  public Tip: string = 'Все объявления проходят обязательную модерацию (проверку объявления на соответствия правилам размещения) сотрудниками ВКонтакте. \n' +
    'Если объявление не соответствует правилам, то его могут отклонить и оно не может быть включено. \n' +
    '\n' +
    'Если вы выберите “Отправить на модерацию сразу после создания объявления” и “Запустить объявление сразу после прохождения модерации”. То, если модераторы одобрят объявление, оно будет запущено и начнутся показы объявления. \n' +
    'Если не будет одобрено, то вам необходимо изменить объявление и повторно отправить на модерацию.\n';


  public IsBudgetZero() {
    return this.budget === 0;
  }

  Update() {
    this.automationVkService.GetBudget({
      accountId: this.accountId,
      clientId: this.clientId
    })
      .subscribe(budgetResponse => {
        console.log(budgetResponse);
      });
  }
}
