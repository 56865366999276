import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {UsersListComponent} from './routes/users-list/users-list.component';
import {UserCommentsComponent} from './routes/user-comments/user-comments.component';

const routes: Routes = [
  {
    path: 'admin',
    children: [
      {
        path: 'users',
        children: [
          {
            path: '',
            component: UsersListComponent,
          },
          {
            path: ':id/comments',
            component: UserCommentsComponent
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UsersRoutingModule { }
