/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./integration-access-denied.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./integration-access-denied.component";
import * as i5 from "../../../api/services/integration-access.service";
var styles_IntegrationAccessDeniedComponent = [i0.styles];
var RenderType_IntegrationAccessDeniedComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_IntegrationAccessDeniedComponent, data: {} });
export { RenderType_IntegrationAccessDeniedComponent as RenderType_IntegrationAccessDeniedComponent };
function View_IntegrationAccessDeniedComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u0414\u043E\u0441\u0442\u0443\u043F \u043A \u0434\u0430\u043D\u043D\u043E\u043C\u0443 \u0444\u0443\u043D\u043A\u0446\u0438\u043E\u043D\u0430\u043B\u0443 \u043F\u0440\u0435\u0434\u043E\u0441\u0442\u0430\u0432\u043B\u044F\u0435\u0442\u0441\u044F \u043F\u043E \u0437\u0430\u043F\u0440\u043E\u0441\u0443. "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "vk-container"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u041E\u0441\u0442\u0430\u0432\u044C\u0442\u0435 \u0437\u0430\u044F\u0432\u043A\u0443 \u0438 \u043C\u044B \u0440\u0430\u0441\u0441\u043A\u0430\u0436\u0435\u043C \u043E \u0444\u0443\u043D\u043A\u0446\u0438\u043E\u043D\u0430\u043B\u0435 CRM-\u043C\u0430\u0440\u043A\u0435\u0442\u0438\u043D\u0433\u0430, \u043F\u043E\u043A\u0430\u0436\u0435\u043C \u043A\u0430\u043A \u0440\u0430\u0431\u043E\u0442\u0430\u0442\u044C \u0441 \u043F\u043B\u0430\u0442\u0444\u043E\u0440\u043C\u043E\u0439, \u0430 \u0442\u0430\u043A\u0436\u0435 \u0441\u0434\u0435\u043B\u0430\u0435\u043C \u0440\u0430\u0441\u0447\u0435\u0442 \u0441\u0442\u043E\u0438\u043C\u043E\u0441\u0442\u0438 \u043F\u043E\u0434 \u0432\u0430\u0448\u0438 \u0437\u0430\u0434\u0430\u0447\u0438. "])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "vk-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "a", [["class", "vk-standart-button"], ["href", "https://b24-4t928r.bitrix24.site/crm_form_lo05y/"], ["rel", "noopener norefferer nofollow"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u041E\u0441\u0442\u0430\u0432\u0438\u0442\u044C \u0437\u0430\u044F\u0432\u043A\u0443 "]))], null, null); }
function View_IntegrationAccessDeniedComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u0423 \u0432\u0430\u0441 \u043D\u0435\u0442 \u0434\u043E\u0441\u0442\u0443\u043F\u0430 \u043A \u0444\u0443\u043D\u043A\u0446\u0438\u043E\u043D\u0430\u043B\u0443 \"\u0418\u043D\u0442\u0435\u0433\u0440\u0430\u0446\u0438\u0438\". "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "vk-container"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u0414\u043B\u044F \u043F\u0440\u043E\u0434\u043E\u043B\u0436\u0435\u043D\u0438\u044F \u0440\u0430\u0431\u043E\u0442\u044B \u0441 \u0444\u0443\u043D\u043A\u0446\u0438\u043E\u043D\u0430\u043B\u043E\u043C \u043F\u0435\u0440\u0435\u0439\u0434\u0438\u0442\u0435 \u043A \u0432\u044B\u0431\u043E\u0440\u0443 \u0442\u0430\u0440\u0438\u0444\u043D\u043E\u0433\u043E \u043F\u043B\u0430\u043D\u0430 \u0438 \u043E\u043F\u043B\u0430\u0442\u0438\u0442\u0435 \u0442\u0430\u0440\u0438\u0444. "])), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "vk-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "a", [["class", "vk-standart-button"], ["routerLink", "/new-payment"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, [" \u041F\u0435\u0440\u0435\u0439\u0442\u0438 \u043A \u043E\u043F\u043B\u0430\u0442\u0435 "]))], function (_ck, _v) { var currVal_2 = "/new-payment"; _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 6).target; var currVal_1 = i1.ɵnov(_v, 6).href; _ck(_v, 5, 0, currVal_0, currVal_1); }); }
export function View_IntegrationAccessDeniedComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_IntegrationAccessDeniedComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["hasAccessHistory", 2]], null, 0, null, View_IntegrationAccessDeniedComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hasNoAccessHistory; var currVal_1 = i1.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_IntegrationAccessDeniedComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-integration-access-denied", [], null, null, null, View_IntegrationAccessDeniedComponent_0, RenderType_IntegrationAccessDeniedComponent)), i1.ɵdid(1, 114688, null, 0, i4.IntegrationAccessDeniedComponent, [i5.IntegrationAccessService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var IntegrationAccessDeniedComponentNgFactory = i1.ɵccf("app-integration-access-denied", i4.IntegrationAccessDeniedComponent, View_IntegrationAccessDeniedComponent_Host_0, {}, {}, []);
export { IntegrationAccessDeniedComponentNgFactory as IntegrationAccessDeniedComponentNgFactory };
