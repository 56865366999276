import { ID } from '@datorama/akita';

export interface UserIntegrationStatistic {
  id: ID;
  value: number
}

/**
 * A factory function that creates UserIntegrationStatistics
 */
export function createUserIntegrationStatistic(params: Partial<UserIntegrationStatistic>) {
  return {
    ...params
  } as UserIntegrationStatistic;
}
