/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./ad-format.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/flex-layout/extended";
import * as i4 from "@angular/flex-layout/core";
import * as i5 from "@angular/forms";
import * as i6 from "./ad-format.component";
var styles_AdFormatComponent = [i0.styles];
var RenderType_AdFormatComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AdFormatComponent, data: {} });
export { RenderType_AdFormatComponent as RenderType_AdFormatComponent };
export function View_AdFormatComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "ad-format-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 13, "div", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.SelectFormat(_co.adFormat.value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { "ad-format": 0, "ad-format_selected": 1 }), i1.ɵdid(4, 933888, null, 0, i3.DefaultClassDirective, [i1.ElementRef, i4.StyleUtils, i4.MediaMarshaller, i1.IterableDiffers, i1.KeyValueDiffers, i1.Renderer2, [6, i2.NgClass]], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(5, { "ad-format": 0, "ad-format_selected": 1 }), (_l()(), i1.ɵeld(6, 0, null, null, 8, "div", [["class", "ad-format-strip"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "ad-format-strip__item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "ad-format-icon-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "img", [["class", "ad-format-icon"]], [[8, "src", 4], [8, "alt", 0]], null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 4, "div", [["class", "ad-format-strip__item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "div", [["class", "ad-format-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, [" ", " "])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "div", [["class", "ad-format-description"]], null, null, null, null, null)), (_l()(), i1.ɵted(14, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, true, _co.IsSelected(_co.adFormat.value)); _ck(_v, 2, 0, currVal_0); var currVal_1 = _ck(_v, 5, 0, true, _co.IsSelected(_co.adFormat.value)); _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.adFormat.image; var currVal_3 = _co.adFormat.title; _ck(_v, 9, 0, currVal_2, currVal_3); var currVal_4 = _co.adFormat.title; _ck(_v, 12, 0, currVal_4); var currVal_5 = _co.adFormat.description; _ck(_v, 14, 0, currVal_5); }); }
export function View_AdFormatComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-ad-format", [], null, null, null, View_AdFormatComponent_0, RenderType_AdFormatComponent)), i1.ɵprd(5120, null, i5.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i6.AdFormatComponent]), i1.ɵdid(2, 114688, null, 0, i6.AdFormatComponent, [], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var AdFormatComponentNgFactory = i1.ɵccf("app-ad-format", i6.AdFormatComponent, View_AdFormatComponent_Host_0, { disabled: "disabled", selectedField: "selectedField", adFormat: "adFormat" }, { selectedFieldChange: "selectedFieldChange" }, []);
export { AdFormatComponentNgFactory as AdFormatComponentNgFactory };
