import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges
} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {PlatformsTypes} from '../../../../platforms-types';
import {AssignationPlatforms} from '../../../../stores/platform-assignations/platform-assignation.model';
import {RetargetingGroupsService} from '../../../../stores/retargeting-groups/retargeting-groups.service';
import {RetargetingGroupsQuery} from '../../../../stores/retargeting-groups/retargeting-groups.query';
import {AkitaNgFormsManager} from '@datorama/akita-ng-forms-manager';
import {CrmPipelinesQuery} from '../../../../stores/crm-pipelines/crm-pipelines.query';
import {PlatformAssignationsQuery} from '../../../../stores/platform-assignations/platform-assignations.query';
import {distinctUntilChanged, map, tap} from 'rxjs/operators';
import {PlatformsQuery} from '../../../../stores/platforms/platforms.query';
import {combineLatest} from 'rxjs';
import {CrmPipelineStatus} from '../../../../stores/crm-pipelines/crm-pipeline.model';
import {untilDestroyed} from 'ngx-take-until-destroy';
import {FacebookAdAccountService} from "../../../../../api/services";
import {environment} from "../../../../../../environments/environment";

@Component({
    selector: 'app-retargeting-groups-settings',
    templateUrl: './retargeting-groups-settings.component.html',
    styleUrls: ['./retargeting-groups-settings.component.scss']
})
export class RetargetingGroupsSettingsComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

    public accounts: any = [];
    public loading: boolean = false;
    public retargetingGroups$ = this.retargetingGroupsQuery.retargetingGroups$;
    public pipelineStatuses$ = this.pipelinesQuery.selectedPipeLineStatuses$;
    public leadPipelineStatuses$ = this.pipelinesQuery.leadsPipelineStatuses$;
    public isSelectedUonTravel$ = this.platformsQuery.selectedOriginPlatformCode$
        .pipe(map(x => x === 'UON_EXPORT'));
    public isAssigned = false;

    public form: FormGroup;

    @Input() public type: PlatformsTypes;
    @Input() public platform: AssignationPlatforms;

    public hasLeads = false;

    accountCtrl: FormControl = new FormControl();
    splitByPipelines = false;

    public pipelineStatuses: CrmPipelineStatus[] = null;
    public accountsLoaded: boolean = false;
    dealsTitle: any = 'Сделки';
    withoutSplitLabel = 'Передавать данные в аудитории';

    public get pipelineStatusPlaceholder() {
        return this.platformsQuery.selectedDestinationPlatformCode === PlatformsTypes.FB_CUSTOM_AUDIENCES ? 'Выберите пользовательские аудитории' : 'Выберите аудитории ретаргетинга';
    }

    public get isCrmMoiSklad() {
        return this.platformsQuery.selectedOriginPlatformCode === 'MOY_SKLAD_EXPORT';
    }

    public get labelText() {
        return 'Аудитории ретаргетинга';
    }

    public get slots() {
        return this.retargetingGroupsService.slots;
    }

    public get allAccounts() {
        return this.retargetingGroupsService.allAccounts;
    }

    public get isAllContacts() {
        return this.fm.getForm('originPlatformSettings').value.section === 'CONTACTS' && this.isCrmMoiSklad === false;
    }

    constructor(
        private retargetingGroupsService: RetargetingGroupsService,
        private retargetingGroupsQuery: RetargetingGroupsQuery,
        private fb: FormBuilder,
        private fm: AkitaNgFormsManager,
        private pipelinesQuery: CrmPipelinesQuery,
        private cd: ChangeDetectorRef,
        private assignationsQuery: PlatformAssignationsQuery,
        private platformsQuery: PlatformsQuery,
        private facebookAccountsService: FacebookAdAccountService
    ) {
    }

    withoutSplitSuggestionCode() {
        if (!this.isAllContacts) {
            return 'VK_RETARGETING_GROUPS_SELECTOR_PIPELINE_NO_SPLIT';
        }
        return 'VK_RETARGETING_GROUPS_SELECTOR_ALL';
    }

    public InitForm() {
        this.form = this.fb.group({
            accountId: this.fb.control(null),
            splitByPipelines: this.fb.control(false),
            isClassicMode: this.fb.control(this.hasLeads),
            pipelineStatuses: this.fb.group({
                pipeline_null: this.fb.group({
                    pipelineId: this.fb.control(null),
                    pipelineType: this.fb.control('DEALS'),
                    retargetingGroups: this.fb.control(null)
                })
            })
        });

        this.form.get('accountId')
            .valueChanges
            .pipe(
                distinctUntilChanged()
            )
            .subscribe(data => {
                this.ResetAllPipelineStatuses();
            });

        this.form
            .get('splitByPipelines')
            .valueChanges
            .subscribe(data => {
                this.splitByPipelines = data;
                this.ResetAllPipelineStatuses();
            });

        this.form
            .get('accountId')
            .valueChanges
            .subscribe(([accountId, clientId]) => {
                if (this.type === PlatformsTypes.FB_CUSTOM_AUDIENCES) {
                    this.retargetingGroupsService.LoadCustomAudiences(accountId);
                } else {
                    this.retargetingGroupsService.LoadRetargetingGroupsFromVk(accountId, clientId);
                }
            });

        this.InitSections();
        
        this.fm.upsert('destinationPlatformSettings', this.form);
    }

    private ResetAllPipelineStatuses() {
        const pipelineStatusesGroup = this.form.get('pipelineStatuses') as FormGroup;
        if (pipelineStatusesGroup) {
            Object
                .keys(pipelineStatusesGroup.controls)
                .map(key => pipelineStatusesGroup.get(key) as FormGroup)
                .forEach(pipelineStatusGroup => {
                    pipelineStatusGroup.get('retargetingGroups').reset();
                });
        }
    }

    public InitPipelines() {
        combineLatest(
            this.pipelineStatuses$,
            this.leadPipelineStatuses$
        )
            .subscribe(([pipelineStatuses, leadPipelineStatuses]) => {
                const statuses = [...pipelineStatuses, ...leadPipelineStatuses] as CrmPipelineStatus[];
                this.pipelineStatuses = statuses;
                this.UpdatePipelineStatusesForm();
            });
        this.pipelineStatuses$.subscribe(pipelineStatuses => {
        });
    }

    private UpdatePipelineStatusesForm() {
        if (this.pipelineStatuses.length === 0) {
            this.form.get('splitByPipelines').setValue(false, {emitEvent: false});
        }
        this.pipelineStatuses.forEach(pipelineStatus => {
            const pipelineStatusesGroup = this.form.get('pipelineStatuses') as FormGroup;
            pipelineStatusesGroup.addControl(`pipeline_${pipelineStatus.type}_${pipelineStatus.id}`, new FormGroup({
                pipelineId: new FormControl(pipelineStatus.id),
                pipelineType: new FormControl(pipelineStatus.type),
                retargetingGroups: new FormControl()
            }));
        });
    }

    ngOnInit() {
        
    }

    private InitSections() {
        this.fm.selectValue('originPlatformSettings')
            .subscribe(({section}) => {
                if (section !== 'CONTACTS') {
                    if (section === 'UON_MARKS_EXPORT') {
                        this.withoutSplitLabel = 'Передавать все в аудитории';
                        if (this.form) {
                            this.form.get('splitByPipelines').setValue(true);
                        } else {
                            setTimeout(() => {
                                this.form.get('splitByPipelines').setValue(true);
                            }, 1000)
                        }
                    } else {
                        this.withoutSplitLabel = 'Передавать данные всей воронки в аудитории';
                    }
                } else {
                    if (this.form) {
                        this.form.patchValue({splitByPipelines: false});
                    }
                }
            });
    }

    public LoadAccounts() {
        this.loading = true;
        this.detectChanges();
        console.log(this.type, 'FACEBOOK');
        switch (this.type) {
            case PlatformsTypes.FB_CUSTOM_AUDIENCES:
                return this.LoadFaceBookAccounts();
                break;
            case PlatformsTypes.VK_RETARGETING_GROUPS:
                return this.LoadVkData();
                break;
        }
    }

    private LoadVkData() {
        return this.retargetingGroupsService.LoadVkAccountData()
            .pipe(
                tap(data => {
                    this.accountsLoaded = true;
                    this.accounts = this.retargetingGroupsService.accounts;
                    this.loading = false;
                    this.detectChanges();
                })
            );
    }

    public getPipelineStatusControl(i) {
        const pipelineStatusesForm = ((this.form.get('pipelineStatuses') as FormArray).at(i) as FormGroup);
        if (pipelineStatusesForm) {
            return pipelineStatusesForm.get('retargetingGroups') as FormControl;
        } else {
            return null;
        }
    }

    ngAfterViewInit(): void {
        this.InitAll();
    }

    public InitAll() {
        this.CheckAssignation();
        this.assignationsQuery
            .isAssigned$(this.platform)
            .pipe(
                untilDestroyed(this)
            )
            .subscribe(isAssigned => {
                this.accountsLoaded = false;
                this.platformsQuery
                    .selectedOriginPlatformCode$
                    .pipe(
                        untilDestroyed(this)
                    )
                    .subscribe(code => {
                        const platform = this.assignationsQuery.platform(code);
                        this.platformsQuery.getPlatformParams(platform)
                            .pipe(
                                untilDestroyed(this)
                            )
                            .subscribe(params => {
                                if (params) {
                                    this.hasLeads = params.hasLeads;
                                }
                            });
                    });

                this.LoadAccounts()
                    .pipe(
                        untilDestroyed(this)
                    )
                    .subscribe(accounts => {
                        if (this.accounts.length > 0) {
                            this.InitForm();
                            this.InitPipelines();
                        }
                    });
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
    }

    private CheckAssignation() {
        const assignationsData = this.assignationsQuery.getAll({filterBy: entity => entity.id === this.platform});

        if (assignationsData && assignationsData.length > 0) {
            this.isAssigned = assignationsData[0].assignationStatus;
        }
    }

    private detectChanges() {
        if (!this.cd['destroyed']) {
            this.cd.detectChanges();
        }
    }

    hasControlWithName(controlName: string) {
        return this.form.get('pipelineStatuses').get(controlName);
    }

    public isPipeliningCheckboxVisible() {
        const section = this.fm.getForm(environment.integrations.originFormKey).value.section;
        console.log(section, 'IS PIPELINE CHECKBOX VISIBLE');
        if (section === 'UON_MARKS_EXPORT') {
            return false;
        } else if (section === 'CONTACTS') {
            return false;
        } else {
            console.log(this.pipelineStatuses.length > 0, this.form.get('accountId').value !== null);
            return this.pipelineStatuses.length > 0 && this.form.get('accountId').value !== null;
        }
    }

    ngOnDestroy(): void {
    }

    private LoadFaceBookAccounts() {
        return this.facebookAccountsService.FacebookGetAdAccountsAsync({
            fields: ['name']
        })
            .pipe(
                tap(((data: any) => {
                    this.accountsLoaded = true;
                    this.accounts = data.data.data.map(x => ({account_id: x.id, account_name: x.name}))
                    this.loading = false;
                }))
            );
    }
}
