/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationInterface } from './api-configuration';

import { AccountsService } from './services/accounts.service';
import { AdManagerService } from './services/ad-manager.service';
import { AdvancedSearchUserExpirienceService } from './services/advanced-search-user-expirience.service';
import { AffiliateLinksService } from './services/affiliate-links.service';
import { AffiliatePartnerStatusService } from './services/affiliate-partner-status.service';
import { AmoService } from './services/amo.service';
import { ApiBlogService } from './services/api-blog.service';
import { AutomationEasyAdsService } from './services/automation-easy-ads.service';
import { AutomationVkService } from './services/automation-vk.service';
import { AutomationVkSlotService } from './services/automation-vk-slot.service';
import { AutomationYandexService } from './services/automation-yandex.service';
import { BitrixService } from './services/bitrix.service';
import { CabinetsService } from './services/cabinets.service';
import { CallsController1Service } from './services/calls-controller-1.service';
import { ChatService } from './services/chat.service';
import { ClaimsService } from './services/claims.service';
import { CustomFormService } from './services/custom-form.service';
import { ExperienceLevelService } from './services/experience-level.service';
import { FacebookAdAccountService } from './services/facebook-ad-account.service';
import { FacebookAdsService } from './services/facebook-ads.service';
import { FacebookCustomAudienceService } from './services/facebook-custom-audience.service';
import { FeaturesService } from './services/features.service';
import { FilesService } from './services/files.service';
import { HashService } from './services/hash.service';
import { IntegrationService } from './services/integration.service';
import { IntegrationAccessService } from './services/integration-access.service';
import { IntegrationExtraDataService } from './services/integration-extra-data.service';
import { LeadsService } from './services/leads.service';
import { MacroCommandsService } from './services/macro-commands.service';
import { MegaplanService } from './services/megaplan.service';
import { MethodsService } from './services/methods.service';
import { MoySkladService } from './services/moy-sklad.service';
import { MyTargetService } from './services/my-target.service';
import { NewPaymentsService } from './services/new-payments.service';
import { OAuthService } from './services/oauth.service';
import { OccupationsService } from './services/occupations.service';
import { PartnersService } from './services/partners.service';
import { PaymentsService } from './services/payments.service';
import { PresentationsService } from './services/presentations.service';
import { PromocodesService } from './services/promocodes.service';
import { RolesService } from './services/roles.service';
import { StrategiesService } from './services/strategies.service';
import { SuggestionsService } from './services/suggestions.service';
import { SystemBannersService } from './services/system-banners.service';
import { TariffPackagesService } from './services/tariff-packages.service';
import { TimePeriodsService } from './services/time-periods.service';
import { TokenCenterService } from './services/token-center.service';
import { UOnTravelService } from './services/uon-travel.service';
import { UserAffiliateStatisticsService } from './services/user-affiliate-statistics.service';
import { UserAffiliateTransactionsService } from './services/user-affiliate-transactions.service';
import { UserBalanceService } from './services/user-balance.service';
import { UserExperienceLevelService } from './services/user-experience-level.service';
import { UserIntegrationService } from './services/user-integration.service';
import { UsersService } from './services/users.service';
import { UserStrategiesService } from './services/user-strategies.service';
import { UserStrategyInputService } from './services/user-strategy-input.service';
import { UserTariffPlanService } from './services/user-tariff-plan.service';
import { UtilsService } from './services/utils.service';
import { UtmParamsService } from './services/utm-params.service';
import { VkApiService } from './services/vk-api.service';
import { VKGroupService } from './services/vkgroup.service';
import { VKPostService } from './services/vkpost.service';
import { YandexAudienceSegmentService } from './services/yandex-audience-segment.service';

/**
 * Provider for all Api services, plus ApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    ApiConfiguration,
    AccountsService,
    AdManagerService,
    AdvancedSearchUserExpirienceService,
    AffiliateLinksService,
    AffiliatePartnerStatusService,
    AmoService,
    ApiBlogService,
    AutomationEasyAdsService,
    AutomationVkService,
    AutomationVkSlotService,
    AutomationYandexService,
    BitrixService,
    CabinetsService,
    CallsController1Service,
    ChatService,
    ClaimsService,
    CustomFormService,
    ExperienceLevelService,
    FacebookAdAccountService,
    FacebookAdsService,
    FacebookCustomAudienceService,
    FeaturesService,
    FilesService,
    HashService,
    IntegrationService,
    IntegrationAccessService,
    IntegrationExtraDataService,
    LeadsService,
    MacroCommandsService,
    MegaplanService,
    MethodsService,
    MoySkladService,
    MyTargetService,
    NewPaymentsService,
    OAuthService,
    OccupationsService,
    PartnersService,
    PaymentsService,
    PresentationsService,
    PromocodesService,
    RolesService,
    StrategiesService,
    SuggestionsService,
    SystemBannersService,
    TariffPackagesService,
    TimePeriodsService,
    TokenCenterService,
    UOnTravelService,
    UserAffiliateStatisticsService,
    UserAffiliateTransactionsService,
    UserBalanceService,
    UserExperienceLevelService,
    UserIntegrationService,
    UsersService,
    UserStrategiesService,
    UserStrategyInputService,
    UserTariffPlanService,
    UtilsService,
    UtmParamsService,
    VkApiService,
    VKGroupService,
    VKPostService,
    YandexAudienceSegmentService
  ],
})
export class ApiModule {
  static forRoot(customParams: ApiConfigurationInterface): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
