import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {IntegrationFormService} from '../../../services/integration-form.service';
import {StoreService} from '../../../../../services/store.service';
import {Cabinet, PostMonitorBackend_V2ModelsvkVKAPIAdTargetGroup} from '../../../../../../api/models';
import {distinctUntilChanged} from 'rxjs/operators';

@Component({
  selector: 'app-vk-retargeting-groups',
  templateUrl: './vk-retargeting-groups.component.html',
  styleUrls: ['./vk-retargeting-groups.component.scss']
})
export class VkRetargetingGroupsComponent implements OnInit {

  public form: FormGroup;
  public accounts: Cabinet[];
  public account: FormControl = new FormControl();
  public accountsLoading: boolean = false;
  public retargetingGroups: PostMonitorBackend_V2ModelsvkVKAPIAdTargetGroup[] = [];
  public searchTargetGroups: FormControl = new FormControl();

  public sortByPipeline = new FormControl(false);

  public get useSortingByPipelines() {
    return this.sortByPipeline.value;
  }

  public pipelineStatuses = [
    {
      id: 1,
      name: 'Первичный контакт'
    },
    {
      id: 2,
      name: 'Согласование'
    },
    {
      id: 3,
      name: 'Сделка успешна'
    },
    {
      id: 4,
      name: 'Сделка сорвалась'
    }
  ]

  public get filteredRetargetingGroup() {
    const search = this.searchTargetGroups.value;
    if (search && search !== '') {
      return this.retargetingGroups.filter(x => x.name.toLowerCase().match(search));
    } else {
      return this.retargetingGroups;
    }
  }

  constructor(
    private service: IntegrationFormService,
    private store: StoreService
  ) {
  }

  ngOnInit() {
    this.form = this.service.formService.getControl('vkRetargetingGroups') as FormGroup;
    this.LoadAccounts();
    this.account.valueChanges
      .pipe(
        distinctUntilChanged()
      )
      .subscribe(() => {
        this.LoadRetargetingGroups();
      });
  }

  private LoadAccounts() {
    this.accountsLoading = true;
    this.store.LoadVkAccountData()
      .subscribe(accounts => {
        this.accounts = this.store.assignedAccounts;
        this.accountsLoading = false;
      });
  }

  private LoadRetargetingGroups() {
    const {account_id, client_id} = this.account.value;
    this.store.LoadVkRetargetingGroups(account_id, client_id)
      .subscribe(groups => {
        this.retargetingGroups = groups.sort((a, b) => b.id - a.id);
      });
  }
}
