/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./mat-confirm-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "./mat-confirm-dialog.component";
var styles_MatConfirmDialogComponent = [i0.styles];
var RenderType_MatConfirmDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MatConfirmDialogComponent, data: {} });
export { RenderType_MatConfirmDialogComponent as RenderType_MatConfirmDialogComponent };
export function View_MatConfirmDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", "\n"])), (_l()(), i1.ɵeld(5, 0, null, null, 5, "div", [["class", "mat-dialog-actions"], ["mat-dialog-actions", ""]], null, null, null, null, null)), i1.ɵdid(6, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "button", [["class", "btn btn-success btn-sm"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.Confirm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "button", [["class", "btn btn-warning btn-sm"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.Reject() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(10, null, ["", ""]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.dialogConfig.title; _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.dialogConfig.description; _ck(_v, 4, 0, currVal_2); var currVal_3 = _co.dialogConfig.confirmActionText; _ck(_v, 8, 0, currVal_3); var currVal_4 = _co.dialogConfig.rejectActionText; _ck(_v, 10, 0, currVal_4); }); }
export function View_MatConfirmDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-configrm-dialog", [], null, null, null, View_MatConfirmDialogComponent_0, RenderType_MatConfirmDialogComponent)), i1.ɵdid(1, 114688, null, 0, i3.MatConfirmDialogComponent, [i2.MAT_DIALOG_DATA, i2.MatDialogRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MatConfirmDialogComponentNgFactory = i1.ɵccf("app-configrm-dialog", i3.MatConfirmDialogComponent, View_MatConfirmDialogComponent_Host_0, {}, {}, []);
export { MatConfirmDialogComponentNgFactory as MatConfirmDialogComponentNgFactory };
