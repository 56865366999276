import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {AffiliateProgramSettingsRoutingModule} from './affiliate-program-settings-routing.module';
import {AffiliateProgramSettingsReportModule} from "./affiliate-program-settings-report/affiliate-program-settings-report.module";
import {AffiliateProgramSettingsUserInfoModule} from "./affiliate-program-settings-user-info/affiliate-program-settings-user-info.module";

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    AffiliateProgramSettingsRoutingModule,
    AffiliateProgramSettingsReportModule,
    AffiliateProgramSettingsUserInfoModule
  ]
})
export class AffiliateProgramSettingsModule {
}
