import {Component, Input, OnInit} from '@angular/core';
import {PostMonitorBackend_V2ModelsvkVKAPIAdTargetGroup} from "../../../../../api/models/post-monitor-backend-_v2modelsvk-vkapiad-target-group";

@Component({
  selector: 'app-target-group',
  templateUrl: './target-group.component.html',
  styleUrls: ['./target-group.component.scss']
})
export class TargetGroupComponent implements OnInit {

  @Input() public targetGroup: PostMonitorBackend_V2ModelsvkVKAPIAdTargetGroup;

  constructor() {
  }

  ngOnInit() {
  }

}
