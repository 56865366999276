<form class="import-crm-section" [formGroup]="form" *ngIf="form">
  <div class="import-crm-section__settings">
    <div class="import-crm-section__title">
      <mat-checkbox class="no-margin-checkbox" formControlName="selected" [checked]="section.isRequired"
                    [disabled]="section.isRequired">
        {{section.name}}
      </mat-checkbox>
    </div>
    <a class="import-crm-section__toggle-trigger" (click)="ToggleSettings()">{{opened ? 'Скрыть настройки' : 'Показать настройки'}}</a>
    <app-suggestion-info [code]="sectionSuggestionCode" width="200px"></app-suggestion-info>
  </div>

  <div [hidden]="!opened" formGroupName="fields">
    <ng-container *ngFor="let field of section.fields">
      <div class="import-crm-section__field" [hidden]="!opened">
        <vk-form-field labelWidth="360px" controlsWidth="360px" [useMin]="false">
          <vk-label [required]="field.isRequired && form.value.selected">{{field.name}}</vk-label>
          <mat-select [formControlName]="field.code" vkInput [attr.disabled]="!form.get('selected').value">
            <mat-option [value]="null">Не выбрано</mat-option>
            <ng-container [ngSwitch]="field.code">
              <ng-container *ngSwitchCase="'PM_PIPELINE_ID'">
                <ng-container *ngFor="let pipeline of pipelines$ | async">
                  <mat-option [value]="pipeline.id">{{pipeline.name}}</mat-option>
                </ng-container>
              </ng-container>

              <ng-container *ngSwitchCase="'PM_RESPONSIBLE_USER_ID'">
                <ng-container *ngFor="let user of users$ | async">
                  <mat-option [value]="user.id">{{user.name}}</mat-option>
                </ng-container>
              </ng-container>

              <ng-container *ngSwitchCase="'PM_RESPONSIBLE_COMPANY_ID'">
                <ng-container *ngFor="let organisation of organisations$ | async">
                  <mat-option [value]="organisation.id">{{organisation.name}}</mat-option>
                </ng-container>
              </ng-container>

              <ng-container *ngSwitchCase="'PM_STAGE_ID'">
                <ng-container *ngFor="let pipeline of sectionStages">
                  <mat-option [value]="pipeline.selectable_id">{{pipeline.name}}</mat-option>
                </ng-container>
              </ng-container>

              <ng-container *ngSwitchDefault>
                <ng-container *ngFor="let question of adNetworkForm.questions">
                  <mat-option [value]="'\{\{' + question.key + '\}\}'">{{question.label}}</mat-option>
                </ng-container>
              </ng-container>
            </ng-container>
          </mat-select>
          <vk-error validator="required">Это поле обязательно для заполнения</vk-error>
        </vk-form-field>
      </div>
    </ng-container>
    <div *ngIf="section.code === 'DEALS' && showAdditionalFields">
      <strong>Дополнительные поля</strong>
      <ng-container *ngFor="let field of CustomFields">
        <app-custom-field-control
                [FieldName]="field.Name"
                [formControlName]="field.Key"
                [attr.disabled]="!form.get('selected').value"
        ></app-custom-field-control>
      </ng-container>
    </div>
  </div>
</form>
