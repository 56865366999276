import {Directive, ElementRef, Input, OnInit, Renderer2} from '@angular/core';
import {ControlContainer, FormControl, NgControl} from '@angular/forms';

@Directive({
  selector: 'vkInput,[vkInput]'
})
export class InputDirective implements OnInit {

  @Input() public multiline: boolean = false;
  @Input() public useStyles: boolean = true;

  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
    public controlContainer: ControlContainer,
    public formControl: NgControl
  ) {
  }

  ngOnInit(): void {
    if (this.useStyles) {
      let className = '';
      switch (this.el.nativeElement.tagName) {
        case 'SELECT':
          className = 'vk-select';
          break;
        case 'TEXTAREA':
          className = 'vk-textarea';
          break;
        default:
          className = 'vk-input';
          break;
      }

      this.renderer.addClass(this.el.nativeElement, className);
      this.renderer.addClass(this.el.nativeElement, `vk-control`);
    }
  }

}
