<div class="new-import-crm-section" *ngIf="formGroup" [formGroup]="formGroup">
  <div class="new-import-crm-section__header">
    <mat-checkbox
      class="new-import-crm-section__checkbox no-margin-checkbox"
      [disabled]="section.isRequired"
      [required]="section.isRequired"
      formControlName="selected"
      #selected
    >
      {{section.name}}
    </mat-checkbox>
    <a class="new-import-crm-section__visibility-trigger" (click)="ToggleSettings()">
      {{isVisibleSettings ? 'Скрыть' : 'Показать'}} настройки
    </a>
  </div>
  <div class="new-import-crm-section__settings-content" *ngIf="isVisibleSettings" formGroupName="fields">
    <ng-container *ngFor="let field of section.fields">
      <vk-form-field labelWidth="120px" controlsWidth="360px">
        <vk-label [required]="field.isRequired">{{field.name}}</vk-label>
        <mat-select vkInput [formControlName]="field.code" [disabled]="!isSectionSelected">
          <ng-container *ngFor="let option of GetOptionFields(field.code)">
            <mat-option [value]="option.Key">
              {{option.Label}}
            </mat-option>
          </ng-container>
        </mat-select>
      </vk-form-field>
    </ng-container>
  </div>
  <div class="new-import-crm-section__additional-settings" *ngIf="isVisibleSettings">
    <ng-container *ngIf="section.code === 'DEALS'">
      <div class="new-import-crm-section__title">Дополнительные настройки</div>
      <div class="new-import-crm-section__custom-fields" formGroupName="customFields">
        <ng-container *ngFor="let customField of CustomFields">
          <app-new-custom-field-control
            [FieldName]="customField.Name"
            [formControlName]="customField.Key"
            [CustomFieldsValues]="CustomFieldsValues(customField.Id)"
            [disabled]="!isSectionSelected"
          >
          </app-new-custom-field-control>
        </ng-container>
      </div>
    </ng-container>
    <div class="new-import-crm-section__other-settings" [formGroup]="parentForm" *ngIf="isVisibleSettings && advancedSettings">
      <ng-container *ngFor="let advancedSettingsItem of advancedSettings">
        <div class="advanced-settings-item">
          <mat-checkbox [formControlName]="advancedSettingsItem.key" class="no-margin-checkbox" [disabled]="!isSectionSelected">
            {{advancedSettingsItem.label}}
          </mat-checkbox>
        </div>
      </ng-container>
    </div>
  </div>
</div>
