export class UtmMark {
    type: string;
    value: string;
    priority = 99999;

    constructor(type: string, value: string) {
        if (type) {
          this.type = type.toLowerCase();
          if (this.type.endsWith('source')) {
            this.priority = 1;
          } else if (this.type.endsWith('medium')) {
            this.priority = 2;
          } else if (this.type.endsWith('campaign')) {
            this.priority = 3;
          } else if (this.type.endsWith('content')) {
            this.priority = 4;
          } else if (this.type.endsWith('term')) {
            this.priority = 5;
          }
        }
        this.value = value;


    }

    public GetTypeAccoringToPriority(priority: number): string {
      switch (priority) {
        case 1: return 'source';
        case 2: return 'medium';
        case 3: return 'campaign';
        case 4: return 'content';
        case 5: return 'term';
        default: return null;
      }
    }
}
