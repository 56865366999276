/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./strategy-selection-form.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "@clr/angular";
import * as i4 from "@angular/common";
import * as i5 from "./strategy-selection-form.component";
var styles_StrategySelectionFormComponent = [i0.styles];
var RenderType_StrategySelectionFormComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StrategySelectionFormComponent, data: {} });
export { RenderType_StrategySelectionFormComponent as RenderType_StrategySelectionFormComponent };
function View_StrategySelectionFormComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 17, "div", [["class", "strategy-list__item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 16, "div", [["class", "strategy-block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 7, "div", [["class", "strategy-block__input"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 6, "input", [["name", "strategy-selection"], ["type", "radio"]], [[8, "id", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"], [null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 5).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 5)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 5)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("change" === en)) {
        var pd_4 = (i1.ɵnov(_v, 6).onChange() !== false);
        ad = (pd_4 && ad);
    } if (("blur" === en)) {
        var pd_5 = (i1.ɵnov(_v, 6).onTouched() !== false);
        ad = (pd_5 && ad);
    } if (("ngModelChange" === en)) {
        var pd_6 = ((_co.selectedStrategyId = $event) !== false);
        ad = (pd_6 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 16384, null, 0, i2.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵdid(6, 212992, null, 0, i2.RadioControlValueAccessor, [i1.Renderer2, i1.ElementRef, i2.ɵangular_packages_forms_forms_j, i1.Injector], { name: [0, "name"], value: [1, "value"] }, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0, p1_0) { return [p0_0, p1_0]; }, [i2.DefaultValueAccessor, i2.RadioControlValueAccessor]), i1.ɵdid(8, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(10, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵeld(11, 0, null, null, 3, "div", [["class", "strategy-block__name"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 2, "label", [], [[1, "for", 0]], null, null, null, null)), i1.ɵdid(13, 212992, null, 0, i3.ClrLabel, [[2, i3.ɵbe], [2, i3.ɵbf], [2, i3.ɵbg], i1.Renderer2, i1.ElementRef], { forAttr: [0, "forAttr"] }, null), (_l()(), i1.ɵted(14, null, ["", ""])), (_l()(), i1.ɵeld(15, 0, null, null, 3, "div", [["class", "strategy-block__description"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 0, "span", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 1, "span", [["class", "strategy-block__show-more"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.SetShowMoreStatus(_v.context.$implicit.id, !_co.IsShowingMore(_v.context.$implicit.id)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(18, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_8 = "strategy-selection"; var currVal_9 = _v.context.$implicit.id; _ck(_v, 6, 0, currVal_8, currVal_9); var currVal_10 = "strategy-selection"; var currVal_11 = _co.selectedStrategyId; _ck(_v, 8, 0, currVal_10, currVal_11); var currVal_13 = ("strategy-" + _v.context.$implicit.id); _ck(_v, 13, 0, currVal_13); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ("strategy-" + _v.context.$implicit.id); var currVal_1 = i1.ɵnov(_v, 10).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 10).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 10).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 10).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 10).ngClassValid; var currVal_6 = i1.ɵnov(_v, 10).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 10).ngClassPending; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_12 = i1.ɵnov(_v, 13).forAttr; _ck(_v, 12, 0, currVal_12); var currVal_14 = _v.context.$implicit.title; _ck(_v, 14, 0, currVal_14); var currVal_15 = (_co.IsShowingMore(_v.context.$implicit.id) ? _v.context.$implicit.description : _v.context.$implicit.descriptionPreview); _ck(_v, 16, 0, currVal_15); var currVal_16 = (_co.IsShowingMore(_v.context.$implicit.id) ? "\u0421\u043A\u0440\u044B\u0442\u044C" : "\u041F\u043E\u0434\u0440\u043E\u0431\u043D\u0435\u0435"); _ck(_v, 18, 0, currVal_16); }); }
export function View_StrategySelectionFormComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "strategy-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StrategySelectionFormComponent_1)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "strategy-confirm-selection"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "button", [["class", "vk-standart-button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.ConfirmStrategySelection() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u0412\u044B\u0431\u0440\u0430\u0442\u044C \u0441\u043F\u043E\u0441\u043E\u0431 \u0443\u043F\u0440\u0430\u0432\u043B\u0435\u043D\u0438\u044F \u0441\u0442\u0430\u0432\u043A\u043E\u0439 "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.strategies; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.selectedStrategyId <= 0); _ck(_v, 4, 0, currVal_1); }); }
export function View_StrategySelectionFormComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-strategy-selection-form", [], null, null, null, View_StrategySelectionFormComponent_0, RenderType_StrategySelectionFormComponent)), i1.ɵdid(1, 114688, null, 0, i5.StrategySelectionFormComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StrategySelectionFormComponentNgFactory = i1.ɵccf("app-strategy-selection-form", i5.StrategySelectionFormComponent, View_StrategySelectionFormComponent_Host_0, { strategies: "strategies", showInactiveStrategies: "showInactiveStrategies" }, { onStrategyConfirmed: "onStrategyConfirmed" }, []);
export { StrategySelectionFormComponentNgFactory as StrategySelectionFormComponentNgFactory };
