/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./forgot-password.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../components/forms/change-password/change-password.component.ngfactory";
import * as i3 from "../../components/forms/change-password/change-password.component";
import * as i4 from "@angular/router";
import * as i5 from "../../../api/services/accounts.service";
import * as i6 from "../../components/forms/forgot-password-form/forgot-password-form.component.ngfactory";
import * as i7 from "../../components/forms/forgot-password-form/forgot-password-form.component";
import * as i8 from "@angular/common";
import * as i9 from "./forgot-password.component";
var styles_ForgotPasswordComponent = [i0.styles];
var RenderType_ForgotPasswordComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ForgotPasswordComponent, data: {} });
export { RenderType_ForgotPasswordComponent as RenderType_ForgotPasswordComponent };
function View_ForgotPasswordComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-change-password", [], null, null, null, i2.View_ChangePasswordComponent_0, i2.RenderType_ChangePasswordComponent)), i1.ɵdid(2, 114688, null, 0, i3.ChangePasswordComponent, [i4.ActivatedRoute, i5.AccountsService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_ForgotPasswordComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-forgot-password-form", [], null, null, null, i6.View_ForgotPasswordFormComponent_0, i6.RenderType_ForgotPasswordFormComponent)), i1.ɵdid(1, 114688, null, 0, i7.ForgotPasswordFormComponent, [i5.AccountsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_ForgotPasswordComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ForgotPasswordComponent_1)), i1.ɵdid(1, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["forgotPasswordForm", 2]], null, 0, null, View_ForgotPasswordComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hasCode; var currVal_1 = i1.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_ForgotPasswordComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-forgot-password", [], null, null, null, View_ForgotPasswordComponent_0, RenderType_ForgotPasswordComponent)), i1.ɵdid(1, 114688, null, 0, i9.ForgotPasswordComponent, [i4.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ForgotPasswordComponentNgFactory = i1.ɵccf("app-forgot-password", i9.ForgotPasswordComponent, View_ForgotPasswordComponent_Host_0, {}, {}, []);
export { ForgotPasswordComponentNgFactory as ForgotPasswordComponentNgFactory };
