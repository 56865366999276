import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
var ForgotPasswordComponent = /** @class */ (function () {
    function ForgotPasswordComponent(route) {
        this.route = route;
    }
    ForgotPasswordComponent.prototype.ngOnInit = function () {
    };
    Object.defineProperty(ForgotPasswordComponent.prototype, "hasCode", {
        get: function () {
            return this.route.snapshot.queryParams.code;
        },
        enumerable: true,
        configurable: true
    });
    return ForgotPasswordComponent;
}());
export { ForgotPasswordComponent };
