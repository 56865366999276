import { OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { AdFormManagerService } from '../../services/ad-form-manager/ad-form-manager.service';
var SelectedGroupsTableComponent = /** @class */ (function () {
    function SelectedGroupsTableComponent(adFormManagerService) {
        this.adFormManagerService = adFormManagerService;
        //  @Input() public groups: Array<any> = [];
        this.displayColumns = ['checkbox', 'avatar', 'name', 'members_count'];
        this.columns = ['checkbox', 'avatar', 'name', 'members_count'];
    }
    Object.defineProperty(SelectedGroupsTableComponent.prototype, "groups", {
        get: function () {
            return this.adFormManagerService.selectedGroups;
        },
        enumerable: true,
        configurable: true
    });
    SelectedGroupsTableComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.FillDataSource();
        this.adFormManagerService.groupToggled
            .subscribe(function () {
            _this.FillDataSource();
        });
    };
    SelectedGroupsTableComponent.prototype.FillDataSource = function () {
        this.dataSource = new MatTableDataSource(this.adFormManagerService.selectedGroups);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    };
    SelectedGroupsTableComponent.prototype.ngOnChanges = function (changes) {
        console.log(changes);
    };
    SelectedGroupsTableComponent.prototype.toggleSelectedRow = function (group) {
        this.adFormManagerService.toggleSelectedRow(group);
    };
    return SelectedGroupsTableComponent;
}());
export { SelectedGroupsTableComponent };
