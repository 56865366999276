<div class="ngr-pagination">
  <div
    class="ngr-pagination__item"
    [ngClass]="{'ngr-pagination__item_active': IsPageActive(page)}"
    (click)="SetPage(page)"
    *ngFor="let page of pages"
  >
    {{page}}
  </div>
</div>
