import { HttpClient } from "@angular/common/http";
import * as moment from 'moment';
import { environment } from "../../../../environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var AffiliateStatusService = /** @class */ (function () {
    function AffiliateStatusService(http) {
        this.http = http;
        this.lastLoadingDate = null;
        this.isPartner = false;
        this._baseUrl = environment.backendUri + "/" + environment.apiPrefix + "/affiliate-status";
    }
    AffiliateStatusService.prototype.LoadAffiliateMemberStatus = function () {
        var _this = this;
        if (this.lastLoadingDate === null || this.IsLastLoadingDateAfter(new Date(), 5)) {
            this
                .http
                .get(this._baseUrl)
                .subscribe(function (affiliateStatus) {
                _this.isPartner = affiliateStatus ? affiliateStatus['isActivated'] : false;
                _this.lastLoadingDate = new Date();
            });
        }
    };
    AffiliateStatusService.prototype.IsLastLoadingDateAfter = function (date, minutes) {
        var date1 = moment(date).diff(moment(this.lastLoadingDate), 'minutes');
        console.log(date1, 'IS LAST LOADING DATE AFTER');
        return date1 > minutes;
    };
    AffiliateStatusService.ngInjectableDef = i0.defineInjectable({ factory: function AffiliateStatusService_Factory() { return new AffiliateStatusService(i0.inject(i1.HttpClient)); }, token: AffiliateStatusService, providedIn: "root" });
    return AffiliateStatusService;
}());
export { AffiliateStatusService };
