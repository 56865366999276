import {Injectable} from '@angular/core';
import {FormMessage} from "../../models/form-message";
import {FormMessageType} from "../../models/form-message-type";
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: "root"
})
export class FormMessagesService {

  public Messages$: BehaviorSubject<FormMessage[]> = new BehaviorSubject<FormMessage[]>([]);

  constructor() {
  }

  public AddSuccessMessage(text: string) {
    const message = FormMessage.CreateSuccessMessage(text);
    this.AddMessage(message);
  }

  private AddMessage(message: FormMessage) {
    const messages = this.Messages$.getValue();
    messages.push(message)
    this.Messages$.next(messages);
  }

  public AddWarningMessage(text: string) {
    const message = FormMessage.CreateWarningMessage(text);
    this.AddMessage(message);
  }

  public AddErrorMessage(text: string) {
    const message = FormMessage.CreateErrorMessage(text);
    console.log(message, this.Messages$, 'MESSAGE');
    this.AddMessage(message);
  }

  public AddInfoMessage(text: string) {
    const message = FormMessage.CreateInfoMessage(text);
    this.AddMessage(message);
  }

  public Clear() {
    console.log('CLEAR MESSAGES');
    this.Messages$.next([]);
  }
}
