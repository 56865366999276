import {Injectable} from '@angular/core';
import {Query} from '@datorama/akita';
import {UserTariffStore, UserTariffState} from './user-tariff.store';
import {Observable} from "rxjs";
import {PostMonitorBackend_V2ModelsPaymentsTariffPackage} from "../../../../api/models/post-monitor-backend-_v2models-payments-tariff-package";

@Injectable({providedIn: 'root'})
export class  UserTariffQuery extends Query<UserTariffState> {

  /**
   * Тарифный план пользователя
   */
  public tariff$ = (groupId) => this.select(x => x.tariff != null ? x.tariff.find(tariff => tariff['groupId'] === groupId) : null);

  public expiredAt$: Observable<Date> = this.select(x => x.expiredAt);

  constructor(protected store: UserTariffStore) {
    super(store);
  }

}
