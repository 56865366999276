import {
  AfterContentInit,
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {PlatformsTypes} from "../../../../platforms-types";
import {AssignationPlatforms} from "../../../../stores/platform-assignations/platform-assignation.model";
import {AdNetworkGroupsService} from "../../../../stores/ad-network-groups/ad-network-groups.service";
import {AdNetworkGroupsQuery} from "../../../../stores/ad-network-groups/ad-network-groups.query";
import {AkitaNgFormsManager} from "@datorama/akita-ng-forms-manager";
import {PlatformAssignationsQuery} from "../../../../stores/platform-assignations/platform-assignations.query";
import {distinctUntilChanged} from "rxjs/operators";
import {untilDestroyed} from "ngx-take-until-destroy";

@Component({
  selector: 'app-vk-lead-forms-settings',
  templateUrl: './vk-lead-forms-settings.component.html',
  styleUrls: ['./vk-lead-forms-settings.component.scss']
})
export class VkLeadFormsSettingsComponent implements OnInit, AfterContentInit, AfterViewInit, AfterViewChecked, OnChanges, OnDestroy {

  public searchForm: FormGroup;
  public form: FormGroup;
  public loading = false;

  @Input() public type: PlatformsTypes;
  @Input() public platform: AssignationPlatforms;

  public groups$ = this.adNetworkGroupsQuery.groups$;
  public isEmpty$ = this.adNetworkGroupsQuery.isEmpty;
  public isLoading$ = this.adNetworkGroupsQuery.selectLoading() 
  public isAssigned: boolean = false;

  constructor(
    private adNetworkGroupsService: AdNetworkGroupsService,
    private adNetworkGroupsQuery: AdNetworkGroupsQuery,
    private fb: FormBuilder,
    private fm: AkitaNgFormsManager,
    private cd: ChangeDetectorRef,
    private assignationQuery: PlatformAssignationsQuery
  ) {
  }

  ngOnInit() {
    this.searchForm = this.fb.group({
      searchName: this.fb.control(''),
    });

    this.adNetworkGroupsQuery.selectLoading()
      .subscribe(data => {
        this.loading = data;
      });


    // this.searchForm.get('searchName').valueChanges.subscribe(groupName => {
    //   this.adNetworkGroupsService.SearchGroup(groupName);
    // });

    this.groups$.subscribe(groups => {
      const groupsControls = {};
      groups.forEach(group => {
        groupsControls[group.id] = this.fb.control([]);
      });
      this.form = this.fb.group(groupsControls);
      this.fm.upsert('originPlatformSettings', this.form);
    });
  }

  ngAfterContentInit(): void {
  }

  ngAfterViewInit(): void {
    this.assignationQuery
      .assingnationStatus$(this.platform)
      .pipe(
        distinctUntilChanged(),
        untilDestroyed(this)
      )
      .subscribe(data => {
        this.isAssigned = data;

        if (data && this) {
          this.adNetworkGroupsService.LoadGroups(this.type);
        }
      });
  }

  ngAfterViewChecked(): void {
  }


  public CheckAssignation() {
    const assignationData = this.assignationQuery.getAll({filterBy: entity => entity.id === this.platform});
    if (assignationData && assignationData.length > 0) {
      this.isAssigned = assignationData[0].assignationStatus;
    } else {
      this.isAssigned = false;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.platform) {
      if (this.isAssigned) {
        this.adNetworkGroupsService.LoadGroups(this.type);
      }
    }
  }

  ngOnDestroy(): void {
  }

}
