import {Component, Input, OnInit} from '@angular/core';
import {Partner} from '../../api/models';

@Component({
  selector: 'app-partner-view',
  templateUrl: './partner-view.component.html',
  styleUrls: ['./partner-view.component.scss']
})
export class PartnerViewComponent implements OnInit {

  @Input() public partner: Partner;

  constructor() { }

  ngOnInit() {
  }

}
