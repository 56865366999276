import {Injectable} from '@angular/core';
import {AutomationYandexService} from '../../api/services/automation-yandex.service';
import {Counter, Goal, GoalReachResponse} from '../../api/models';
import {UtmMark} from '../models/utm-mark';
import {GoalReachData} from '../../api/models/goal-reach-data';

@Injectable()
export class YandexMetrikaWrapperService {

    public Counters: Array<Counter>;

    public Goals: Array<Goal>;

    public GoalReach: GoalReachResponse;

    /***
     * Indicates if yandex metrika is avaliable (connected to user profile)
     * @type {boolean}
     */
    private isAvailable = true;

    constructor(
        private yandex: AutomationYandexService
    ) {
    }

    public IsAvaliable(): boolean {
        return this.isAvailable;
    }

    public LoadCounters() {
        this.yandex.GetCounters()
            .subscribe((x) => {
                this.Counters = x.counters;
                this.isAvailable = true;
            }, (err) => {
                this.isAvailable = false;
            });
    }

    public LoadGoals(counterId: number) {
        this.yandex.GetGoals(counterId)
            .subscribe(x => {
                this.Goals = x.goals;
            });
    }

    /**
     * Load all goal reaches by counter id ang goal id
     * @constructor
     * @param params
     */
    public LoadGoalReach(params) {
        return new Promise(resolve => {
            this.yandex.GetGoalsReach(params)
                .subscribe(x => {
                    this.GoalReach = x;
                    resolve(this.GoalReach);
                });
        });
    }

    public GetMetrikaValues(utms: Array<UtmMark>) {
        if (!utms) {
            return null;
        }
        if (!this.GoalReach || !this.GoalReach.data) {
            return null;
        }
        utms = utms.sort((a, b) => a.priority - b.priority);
        let filterResults = null;

        // Normalize UTM set (we assume that metrika always contains dimensions length of 5)
        const metrikaDimensionSize = 5; // TODO Detect biggest size of dimensions

        const normalizedUtmSet: Array<UtmMark> = [];

        for (let i = 1 ; i <= metrikaDimensionSize; i = i + 1) {
          const priorityUtm = utms.find(x => x.priority === i);

          let utm = new UtmMark(null, null);

          if (priorityUtm) {
            utm = priorityUtm;
          } else {
            utm.priority = i;
            utm.type = utm.GetTypeAccoringToPriority(utm.priority);
          }

          normalizedUtmSet.push(utm);

          
        }

        // Filter off utm sets with less dimensions
        const correctLengthGoalReach = this.GoalReach.data.filter(x => x.dimensions.length === normalizedUtmSet.length);

      normalizedUtmSet.forEach((utm, index) => {
            if (!filterResults) {
                filterResults = correctLengthGoalReach
                    .filter((grd: GoalReachData) => grd.dimensions[index].name === utm.value);
            } else {
                const tmp = filterResults
                    .filter((fr: GoalReachData) => fr.dimensions[index].name === utm.value);
                filterResults = tmp;
            }
        });
        return filterResults;
    }

}
