/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { APIReponseWrapperListPostMonitorBackend_V2ModelsAnalyticsUserFieldExperienceLevel } from '../models/apireponse-wrapper-list-post-monitor-backend-_v2models-analytics-user-field-experience-level';
import { APIReponseWrapperPostMonitorBackend_V2ModelsAnalyticsUserFieldExperienceLevel } from '../models/apireponse-wrapper-post-monitor-backend-_v2models-analytics-user-field-experience-level';
@Injectable({
  providedIn: 'root',
})
class UserExperienceLevelService extends __BaseService {
  static readonly GetUserExperienceLevelPath = '/apiv2/user-experience-level';
  static readonly AssignUserExperienceLevelPath = '/apiv2/user-experience-level';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param experienceFieldIds undefined
   * @return Success
   */
  GetUserExperienceLevelResponse(experienceFieldIds?: Array<number>): __Observable<__StrictHttpResponse<APIReponseWrapperListPostMonitorBackend_V2ModelsAnalyticsUserFieldExperienceLevel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (experienceFieldIds || []).forEach(val => {if (val != null) __params = __params.append('experienceFieldIds', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/user-experience-level`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<APIReponseWrapperListPostMonitorBackend_V2ModelsAnalyticsUserFieldExperienceLevel>;
      })
    );
  }
  /**
   * @param experienceFieldIds undefined
   * @return Success
   */
  GetUserExperienceLevel(experienceFieldIds?: Array<number>): __Observable<APIReponseWrapperListPostMonitorBackend_V2ModelsAnalyticsUserFieldExperienceLevel> {
    return this.GetUserExperienceLevelResponse(experienceFieldIds).pipe(
      __map(_r => _r.body as APIReponseWrapperListPostMonitorBackend_V2ModelsAnalyticsUserFieldExperienceLevel)
    );
  }

  /**
   * @param params The `UserExperienceLevelService.AssignUserExperienceLevelParams` containing the following parameters:
   *
   * - `experienceLevelId`:
   *
   * - `experienceFieldId`:
   *
   * @return Success
   */
  AssignUserExperienceLevelResponse(params: UserExperienceLevelService.AssignUserExperienceLevelParams): __Observable<__StrictHttpResponse<APIReponseWrapperPostMonitorBackend_V2ModelsAnalyticsUserFieldExperienceLevel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.experienceLevelId != null) __params = __params.set('experienceLevelId', params.experienceLevelId.toString());
    if (params.experienceFieldId != null) __params = __params.set('experienceFieldId', params.experienceFieldId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/apiv2/user-experience-level`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<APIReponseWrapperPostMonitorBackend_V2ModelsAnalyticsUserFieldExperienceLevel>;
      })
    );
  }
  /**
   * @param params The `UserExperienceLevelService.AssignUserExperienceLevelParams` containing the following parameters:
   *
   * - `experienceLevelId`:
   *
   * - `experienceFieldId`:
   *
   * @return Success
   */
  AssignUserExperienceLevel(params: UserExperienceLevelService.AssignUserExperienceLevelParams): __Observable<APIReponseWrapperPostMonitorBackend_V2ModelsAnalyticsUserFieldExperienceLevel> {
    return this.AssignUserExperienceLevelResponse(params).pipe(
      __map(_r => _r.body as APIReponseWrapperPostMonitorBackend_V2ModelsAnalyticsUserFieldExperienceLevel)
    );
  }
}

module UserExperienceLevelService {

  /**
   * Parameters for AssignUserExperienceLevel
   */
  export interface AssignUserExperienceLevelParams {
    experienceLevelId: number;
    experienceFieldId: number;
  }
}

export { UserExperienceLevelService }
