var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AbstractCopyingConfigurator } from "./abstract-copying-configurator";
import { AgeSegmentSex } from "../../models/view-models/age-segment";
var SexAndAgeCopyingConfigurator = /** @class */ (function (_super) {
    __extends(SexAndAgeCopyingConfigurator, _super);
    function SexAndAgeCopyingConfigurator(props) {
        return _super.call(this, props) || this;
    }
    SexAndAgeCopyingConfigurator.prototype.generate = function () {
        var _this = this;
        var adsSpecifications = [];
        this.copyingData.sexAndAgeConfiguration.ageItems.forEach(function (ageAndSexSegment) {
            var specification = _this.generateDefeault();
            if (ageAndSexSegment.eachAge) {
                for (var i = ageAndSexSegment.ageFrom; i <= ageAndSexSegment.ageTo; i++) {
                    var specs = _this.createSegments(specification, i, i, ageAndSexSegment.sex);
                    for (var _i = 0, specs_1 = specs; _i < specs_1.length; _i++) {
                        var spec = specs_1[_i];
                        adsSpecifications.push(spec);
                    }
                }
            }
            else {
                var specs = _this.createSegments(specification, ageAndSexSegment.ageFrom, ageAndSexSegment.ageTo, ageAndSexSegment.sex);
                for (var _a = 0, specs_2 = specs; _a < specs_2.length; _a++) {
                    var spec = specs_2[_a];
                    console.log(spec, 'SPECA');
                    adsSpecifications.push(Object.assign({}, spec));
                }
            }
        });
        return adsSpecifications;
    };
    SexAndAgeCopyingConfigurator.prototype.createSegments = function (specification, ageFrom, ageTo, sex) {
        var specs = [];
        switch (sex) {
            case AgeSegmentSex.ALL:
                specification = Object.assign({
                    sex: null,
                    ageFrom: ageFrom,
                    ageTo: ageTo
                }, specification, {
                    sex: null,
                    ageFrom: ageFrom,
                    ageTo: ageTo
                });
                specs = [Object.assign({}, specification)];
                break;
            case AgeSegmentSex.MALE:
            case AgeSegmentSex.FEMALE:
                specification = Object.assign({
                    sex: sex,
                    ageFrom: ageFrom,
                    ageTo: ageTo
                }, specification, {
                    sex: sex,
                    ageFrom: ageFrom,
                    ageTo: ageTo
                });
                console.log(specification);
                specs = [Object.assign({}, specification)];
                break;
            case AgeSegmentSex.SPLIT:
                console.log('SPLIT SEX');
                for (var z = 1; z <= 2; z++) {
                    var spec = Object.assign(specification, {
                        sex: z,
                        ageFrom: ageFrom,
                        ageTo: ageTo
                    });
                    specs.push(Object.assign({}, spec));
                    console.log(spec);
                }
                break;
        }
        return specs;
    };
    return SexAndAgeCopyingConfigurator;
}(AbstractCopyingConfigurator));
export { SexAndAgeCopyingConfigurator };
