import {Injectable} from '@angular/core';
import {PlatformsTypes} from '../../../platforms-types';
import {AkitaNgFormsManager} from '@datorama/akita-ng-forms-manager';
import {MtRemarketingGroupsConfiguratorService} from '../../my-target/services/mt-remarketing-groups.configurator.service';
import {VkRetargetingGroupsConfiguratorService} from '../../vk/services/vk-retargeting-groups.configurator.service';

@Injectable({
  providedIn: 'root'
})
export class AdNetworkImportConfiguratorService {

  constructor(
    private fm: AkitaNgFormsManager,
    private mtRemarketingGroupsConfigurator: MtRemarketingGroupsConfiguratorService,
    private vkRetargetingGroupsConfigurator: VkRetargetingGroupsConfiguratorService
  ) {
  }

  public configure(type: PlatformsTypes) {
    console.log(type, 'CONFIGURE GENERAL FUNCTION');
    switch (type) {
      case PlatformsTypes.MT_REMARKETING_GROUPS:
      case PlatformsTypes.YANDEX_AUDIENCES:
        return this.mtRemarketingGroupsConfigurator.configure(type);
        break;
      case PlatformsTypes.VK_RETARGETING_GROUPS:
      case PlatformsTypes.FB_CUSTOM_AUDIENCES:
        return this.vkRetargetingGroupsConfigurator.configure(type);
        break;
    }
  }

  public valid(type: PlatformsTypes) {
    console.log(type, 'VALIDATION GENERAL FUNCTION');
    
    switch (type) {
      case PlatformsTypes.MT_REMARKETING_GROUPS:
      case PlatformsTypes.YANDEX_AUDIENCES:
        return this.mtRemarketingGroupsConfigurator.valid(type);
        break;
      case PlatformsTypes.VK_RETARGETING_GROUPS:
      case PlatformsTypes.FB_CUSTOM_AUDIENCES:
        return this.vkRetargetingGroupsConfigurator.valid(type);
        break;
    }
  }
}
