/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./payments-tariff-time-periods-selector.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./payments-tariff-time-periods-selector.component";
import * as i4 from "../../services/payments.service";
var styles_PaymentsTariffTimePeriodsSelectorComponent = [i0.styles];
var RenderType_PaymentsTariffTimePeriodsSelectorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PaymentsTariffTimePeriodsSelectorComponent, data: {} });
export { RenderType_PaymentsTariffTimePeriodsSelectorComponent as RenderType_PaymentsTariffTimePeriodsSelectorComponent };
function View_PaymentsTariffTimePeriodsSelectorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "button", [["class", "payments-selector__trigger"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.SelectTimePeriod(_v.context.$implicit.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "payments-selector__trigger_selected": 0 }), (_l()(), i1.ɵted(4, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "payments-selector__trigger"; var currVal_1 = _ck(_v, 3, 0, _co.isSelected(_v.context.$implicit.id)); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.name; _ck(_v, 4, 0, currVal_2); }); }
export function View_PaymentsTariffTimePeriodsSelectorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "payments-selector"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_PaymentsTariffTimePeriodsSelectorComponent_1)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.timePeriods$)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_PaymentsTariffTimePeriodsSelectorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-payments-tariff-time-periods-selector", [], null, null, null, View_PaymentsTariffTimePeriodsSelectorComponent_0, RenderType_PaymentsTariffTimePeriodsSelectorComponent)), i1.ɵdid(1, 114688, null, 0, i3.PaymentsTariffTimePeriodsSelectorComponent, [i4.PaymentsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PaymentsTariffTimePeriodsSelectorComponentNgFactory = i1.ɵccf("app-payments-tariff-time-periods-selector", i3.PaymentsTariffTimePeriodsSelectorComponent, View_PaymentsTariffTimePeriodsSelectorComponent_Host_0, {}, {}, []);
export { PaymentsTariffTimePeriodsSelectorComponentNgFactory as PaymentsTariffTimePeriodsSelectorComponentNgFactory };
