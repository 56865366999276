<div class="integration-table">
  <div class="integration-table__status-bar">
    <ng-container *ngIf="loading">
      <div class="status-bar__spinner">
        <mat-spinner diameter="18"></mat-spinner>
      </div>
      <div class="status-bar__message">
        {{loadingMessage}}
      </div>
    </ng-container>
  </div>
  <div class="integration-table__content">
    <ngr-data-grid
      [data]="userIntegrations$ | async"
      dataNotFoundMessage="Ни одной интеграции не добавлено"
      [showFilterString]="false"
      height="150px"
      [countOfResults]="null"
    >
      <ngr-dg-column
        name="name"
        header="Название интеграции"
        filterType="none"
        [sortable]="false"
        [hiddable]="false"
      >
        <div *ngrDgContent="let r = row">
          <mat-icon *ngIf="r.isRemoved">
            close
          </mat-icon>
          {{r.name}}
        </div>
      </ngr-dg-column>
      <ngr-dg-column
        name="status"
        header="Статус"
        [sortable]="false"
        [hiddable]="false"
      >
        <div *appDgColumnHeader>
          Статус
          <app-suggestion-info code="INTEGRATION_TABLE_STATUS_COLUMN_HEADER"
                               position="bottom-left"></app-suggestion-info>
        </div>
        <div *ngrDgContent="let elem=row;">
          <app-user-integration-status [status]="elem.statusDescription"
                                       [integrationId]="elem.userIntegrationId"
                                       [isRemoved]="elem.isRemoved"
          ></app-user-integration-status>
        </div>
      </ngr-dg-column>
      <ngr-dg-column
        name="countOfErrors"
        header="Количество транзакций"
        [sortable]="false"
        [hiddable]="false"
        [align]="'right'"
      >
        <div *appDgColumnHeader>
          Количество транзакций
          <app-suggestion-info code="COUNT_OF_TRANSACTIONS" position="bottom-right"></app-suggestion-info>
        </div>
        <div *ngrDgContent="let r = row">
          {{UserIntegrationStatistic$(r.userIntegrationId) | async}}
        </div>
      </ngr-dg-column>
      <ngr-dg-column
        [sortable]="false"
        [hiddable]="false"
        header="Изменение интеграции"
      >
        <div *ngrDgContent="let r = row">
          <div class="integration-table__actions">
            <button class="btn btn-warning btn-sm" (click)="Remove(r.userIntegrationId)" *ngIf="!r.isRemoved">
              Удалить
            </button>
          </div>
        </div>
      </ngr-dg-column>
    </ngr-data-grid>
  </div>
</div>
