<div fxLayout="row" fxLayoutAlign="space-between">
  <div class="vk-ad-cabinet-header vk-ad-cabinet-header_large">
    Новое объявление
  </div>
  <div class="alert">
    <div class="alert-items">
      <a href="https://postmonitor.ru/blog/instrukciya-po-sozdaniyu-obyavlenijj-v-vkontakte-cherez-servis-postmonitor" target="_blank" rel="nofollow noopener">
        Инструкция по созданию объявлений
      </a>
    </div>
  </div>
</div>

<app-ad-form
  (adSaved)="OnSaveAd($event)"
  [slots]="slots"
  [accounts]="accounts"

  [loading]="loading"
  (bindChanged)="ChangeBind()"
  *ngIf="slots && accounts"
></app-ad-form>

<!--<mat-vertical-stepper [linear]="false" #stepper>-->
  <!--<mat-step>-->
    <!--<ng-template matStepLabel><span class="stepper-title">Выберите формат рекламы</span></ng-template>-->

    <!--<ng-template matStepperIcon="edit">-->
      <!--<mat-icon>done_all</mat-icon>-->
    <!--</ng-template>-->

    <!--<app-selecting-format-->
      <!--[adFormatList]="adFormatsList"-->
      <!--[(format)]="format"-->
      <!--(formatChange)="OnFormatChange($event)"-->
      <!--(onNext)="stepper.next()"-->
    <!--&gt;</app-selecting-format>-->
  <!--</mat-step>-->
  <!--<mat-step *ngIf="format === 'promopost'">-->
    <!--<ng-template matStepLabel><span class="stepper-title">Создайте рекламную запись</span></ng-template>-->

    <!--<ng-template matStepperIcon="edit">-->
      <!--<mat-icon>done_all</mat-icon>-->
    <!--</ng-template>-->

    <!--<app-create-promopost [(promopost)]="promopost" (onNext)="OnCreatePromopost($event)"></app-create-promopost>-->
  <!--</mat-step>-->
  <!--<mat-step *ngIf="format === 'teazer'">-->
    <!--<ng-template matStepLabel><span class="stepper-title">Создайте рекламное объявление</span></ng-template>-->
    <!--<app-create-teazer-->
      <!--[(teazer)]="teazer"-->
      <!--(onNext)="OnCreateTeazer($event)"-->
    <!--&gt;</app-create-teazer>-->
  <!--</mat-step>-->
  <!--<mat-step>-->
    <!--<ng-template matStepLabel><span class="stepper-title">Настройте таргетинг</span></ng-template>-->
    <!--<ng-template matStepperIcon="edit">-->
      <!--<mat-icon>done_all</mat-icon>-->
    <!--</ng-template>-->

    <!--<app-targeting-settings-->
      <!--[targetingData]="targetingData"-->
      <!--(onNext)="SaveTargeting($event)"-->
      <!--#targetSettings-->
    <!--&gt;</app-targeting-settings>-->
  <!--</mat-step>-->
  <!--<mat-step>-->
    <!--<ng-template matStepLabel><span class="stepper-title">Выберите кампанию</span></ng-template>-->
    <!--<app-ad-account-manager-->
      <!--[selectedCampaignsType]="AdType"-->
      <!--(onSave)="OnSave($event)"-->
      <!--(onNext)="OnSaveCampaign($event)"-->
      <!--#accountManager-->
    <!--&gt;</app-ad-account-manager>-->
  <!--</mat-step>-->
  <!--<mat-step>-->
    <!--<ng-template matStepLabel><span class="stepper-title">Задайте ставку</span></ng-template>-->
    <!--<app-rate-manager-->
      <!--[adType]="AdFormat"-->
      <!--(onNext)="OnSaveRate($event)"-->
    <!--&gt;</app-rate-manager>-->
  <!--</mat-step>-->
  <!--<mat-step>-->
    <!--<ng-template matStepLabel><span class="stepper-title">Настройки модерации</span></ng-template>-->
    <!--<app-ad-start-settings (onNext)="CreateAd($event)"></app-ad-start-settings>-->
  <!--</mat-step>-->
  <!--<mat-step>-->
    <!--<ng-template matStepLabel><span class="stepper-title">Готово!</span></ng-template>-->
    <!--<app-ready-->
      <!--[accountId]="campaignData.accountId"-->
      <!--[campaignId]="campaignData.campaignId"-->
      <!--[adId]="adId"-->
    <!--&gt;</app-ready>-->
  <!--</mat-step>-->
<!--</mat-vertical-stepper>-->

<!--<ng-template *matStepperIcon="'edit'">-->
  <!--<mat-icon>done_all</mat-icon>-->
<!--</ng-template>-->

<!--<app-create-promopost></app-create-promopost>-->
