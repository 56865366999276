import { OnDestroy, OnInit } from '@angular/core';
import { RetargetingGroupsQuery } from '../../../../stores/retargeting-groups/retargeting-groups.query';
import { RetargetingGroupsService } from '../../../../stores/retargeting-groups/retargeting-groups.service';
import { FormControl, FormGroup } from '@angular/forms';
import { CrmPipelinesQuery } from '../../../../stores/crm-pipelines/crm-pipelines.query';
import { CrmPipelinesService } from '../../../../stores/crm-pipelines/crm-pipelines.service';
import { combineLatest } from 'rxjs';
import { RemarketingBaseType } from '../../../../stores/retargeting-groups/retargeting-groups.store';
import { AkitaNgFormsManager } from '@datorama/akita-ng-forms-manager';
import { map } from 'rxjs/operators';
import { PlatformsQuery } from '../../../../stores/platforms/platforms.query';
import { PlatformAssignationsQuery } from '../../../../stores/platform-assignations/platform-assignations.query';
import { toBoolean } from '@datorama/akita';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { AssignationPlatforms } from '../../../../stores/platform-assignations/platform-assignation.model';
import { environment } from "../../../../../../environments/environment";
var RemarketingGroupsSettingsComponent = /** @class */ (function () {
    function RemarketingGroupsSettingsComponent(remarketingGroupsQuery, remakertingGroupsService, pipelinesQuery, pipelinesService, fm, platformsQuery, assignationsQuery) {
        this.remarketingGroupsQuery = remarketingGroupsQuery;
        this.remakertingGroupsService = remakertingGroupsService;
        this.pipelinesQuery = pipelinesQuery;
        this.pipelinesService = pipelinesService;
        this.fm = fm;
        this.platformsQuery = platformsQuery;
        this.assignationsQuery = assignationsQuery;
        this.containsEmails = false;
        this.containsPhones = false;
        this.remarketingBasesTypes = RemarketingBaseType;
        this.pipelinesStatuses$ = this.pipelinesQuery.selectedPipeLineStatuses$;
        this.leadPipelines$ = this.pipelinesQuery.leadsPipelineStatuses$;
        this.isNotEmptyPipelines$ = this.pipelinesQuery.selectedPipeLineStatuses$
            .pipe(map(function (pipelines) { return pipelines.length > 0; }));
        this.isPipelinesCheckboxVisible$ = combineLatest(this.isNotEmptyPipelines$, this.fm.selectValue(environment.integrations.originFormKey)
            .pipe(map(function (_a) {
            var section = _a.section;
            return section;
        })))
            .pipe(map(function (_a) {
            var isPipelinesNotEmpty = _a[0], section = _a[1];
            if (section === 'UON_MARKS_EXPORT') {
                return false;
            }
            else {
                return isPipelinesNotEmpty;
            }
        }));
        this.isLoading$ = this.remarketingGroupsQuery.selectLoading();
        this.groups$ = this.remarketingGroupsQuery.filteredRetargetingGroups$;
        this.form = new FormGroup({
            type: new FormControl(RemarketingBaseType.EMAILS),
            split_by_pipeline_statuses: new FormControl(false),
            pipeline_statuses: new FormGroup({
                pipeline_null: new FormGroup({
                    pipeline_id: new FormControl(),
                    pipeline_retargeting_groups: new FormControl()
                })
            })
        });
        this.splitByPipelines = false;
        this.hasLeads = false;
        this.isSelectedUonTravel$ = this.platformsQuery.selectedOriginPlatformCode$
            .pipe(map(function (x) { return x === 'UON_EXPORT'; }));
        this.withoutSplittingLabel = 'Передавать данные в списки пользователей';
    }
    Object.defineProperty(RemarketingGroupsSettingsComponent.prototype, "isAllContacts", {
        get: function () {
            return this.fm.getForm('originPlatformSettings').value.section === 'CONTACTS';
        },
        enumerable: true,
        configurable: true
    });
    RemarketingGroupsSettingsComponent.prototype.ngOnInit = function () {
        this.initComponentOnAssignPlatform();
        this.clearDestinationSettingsOnChangeDestinationCode();
        this.clearDestinationSettingsOnRemarketingGroupsListChanged();
        this.subscribeToOriginPlatformCodeChanging();
        this.subscribeToPipelineStatusesChanging();
        this.subscribeToPipelineStatusType();
        this.combinePipelineStatusesOnChangingLeadAndDealsPipelines();
    };
    RemarketingGroupsSettingsComponent.prototype.combinePipelineStatusesOnChangingLeadAndDealsPipelines = function () {
        var _this = this;
        combineLatest(this.pipelinesStatuses$, this.leadPipelines$)
            .subscribe(function (_a) {
            var pipelines = _a[0], leadPipelines = _a[1];
            var statuses = pipelines.concat(leadPipelines);
            _this.changePipelineStatusesFormGroups(statuses);
        });
    };
    RemarketingGroupsSettingsComponent.prototype.subscribeToPipelineStatusesChanging = function () {
        var _this = this;
        this.pipelinesStatuses$.subscribe(function (data) {
            if (data.length === 0) {
                _this.form.get('split_by_pipeline_statuses').reset();
            }
            _this.subscribeAllPipelineStatusesControls();
        });
    };
    RemarketingGroupsSettingsComponent.prototype.subscribeToOriginPlatformCodeChanging = function () {
        var _this = this;
        this.platformsQuery.selectedOriginPlatformCode$
            .subscribe(function (code) {
            var platform = _this.assignationsQuery.platform(code);
            _this.platformsQuery.getPlatformParams(platform)
                .subscribe(function (params) {
                if (params) {
                    _this.hasLeads = params.hasLeads;
                }
            });
        });
    };
    RemarketingGroupsSettingsComponent.prototype.clearDestinationSettingsOnRemarketingGroupsListChanged = function () {
        var _this = this;
        this.remarketingGroupsQuery.retargetingGroups$.subscribe(function (data) {
            _this.containsPhones = toBoolean(data.find(function (x) { return x.type === 'phones'; }));
            _this.containsEmails = toBoolean(data.find(function (x) { return x.type === 'emails'; }));
            if (!(_this.containsPhones && _this.containsEmails)) {
                if (data.length > 0) {
                    _this.form.get('type').setValue(data[0].type);
                }
            }
        });
    };
    RemarketingGroupsSettingsComponent.prototype.clearDestinationSettingsOnChangeDestinationCode = function () {
        var _this = this;
        this.platformsQuery.selectedDestinationPlatformCode$
            .pipe(untilDestroyed(this))
            .subscribe(function (data) {
            _this.form.get('type').setValue(RemarketingBaseType.EMAILS);
        });
    };
    RemarketingGroupsSettingsComponent.prototype.initComponentOnAssignPlatform = function () {
        var _this = this;
        this.assignationsQuery.isAssigned$(AssignationPlatforms.MY_TAREGT)
            .subscribe(function (isPlatformAssigned) {
            if (isPlatformAssigned) {
                _this.LoadRemarketingGroups();
                _this.subscribeToSplitByPipelinesControl();
                _this.subscribeToPipelineStatusesChanging();
                _this.subscribeToPipelineStatusType();
                _this.combinePipelineStatusesOnChangingLeadAndDealsPipelines();
                _this.subscribeToTypeControl();
            }
        });
    };
    RemarketingGroupsSettingsComponent.prototype.LoadRemarketingGroups = function () {
        this.remakertingGroupsService.LoadRemarketingGroupsFromMt();
    };
    RemarketingGroupsSettingsComponent.prototype.subscribeToSplitByPipelinesControl = function () {
        var _this = this;
        this.form.get('split_by_pipeline_statuses')
            .valueChanges
            .subscribe(function (data) {
            console.log('SELECT WTF???');
            _this.splitByPipelines = data;
            _this.resetRetargetingGroupsForAllControls();
        });
    };
    RemarketingGroupsSettingsComponent.prototype.subscribeToTypeControl = function () {
        var _this = this;
        this.form.get('type')
            .valueChanges
            .subscribe(function (data) {
            _this.remakertingGroupsService.SetFilter(data);
            _this.resetRetargetingGroupsForAllControls();
        });
    };
    RemarketingGroupsSettingsComponent.prototype.subscribeToPipelineStatusType = function () {
        var _this = this;
        this.fm.selectValue('originPlatformSettings')
            .subscribe(function (_a) {
            var section = _a.section;
            console.log(section, 'SECTION');
            if (section === 'CONTACTS') {
                _this.withoutSplittingLabel = 'Передавать данные в списки пользователей';
            }
            else if (section === 'UON_MARKS_EXPORT') {
                _this.form.get('split_by_pipeline_statuses').setValue(true);
            }
            else {
                _this.withoutSplittingLabel = 'Передавать данные всей воронки в списки';
            }
        });
    };
    /**
     *
     * @param pipelineStatuses
     */
    RemarketingGroupsSettingsComponent.prototype.changePipelineStatusesFormGroups = function (pipelineStatuses) {
        var _this = this;
        var pipelineStatusesFG = this.form.get('pipeline_statuses');
        this.resetPipelineStatusesGroups();
        pipelineStatuses.map(function (pipelineStatus) {
            _this.AddPipelineStatus(pipelineStatus);
        });
        this.fm.upsert('destinationPlatformSettings', this.form);
    };
    RemarketingGroupsSettingsComponent.prototype.resetPipelineStatusesGroups = function () {
        var groups = {
            pipeline_null: new FormGroup({
                pipeline_id: new FormControl(),
                pipeline_type: new FormControl(),
                pipeline_retargeting_groups: new FormControl()
            })
        };
        this.form.setControl('pipeline_statuses', new FormGroup(groups));
    };
    /**
     * Add new pipeline status to form
     * @param pipelineStatus
     * @constructor
     */
    RemarketingGroupsSettingsComponent.prototype.AddPipelineStatus = function (pipelineStatus) {
        var pipelineStatusesFG = this.form.get('pipeline_statuses');
        var pipelineStatusControlName = pipelineStatus !== null ? "pipeline_" + pipelineStatus.type + "_" + pipelineStatus.id : 'pipeline_null';
        var pipelineId = pipelineStatus !== null ? pipelineStatus.id : null;
        var pipelineType = pipelineStatus !== null ? pipelineStatus.type : 'LEADS';
        pipelineStatusesFG.addControl(pipelineStatusControlName, this.createPipelineStatusFormGroup(pipelineId, pipelineType));
    };
    /**
     * Создать новую группу статуса воронки продаж
     * @param pipelineId
     * @param pipelineType
     */
    RemarketingGroupsSettingsComponent.prototype.createPipelineStatusFormGroup = function (pipelineId, pipelineType) {
        return new FormGroup({
            pipeline_id: new FormControl(pipelineId),
            pipeline_type: new FormControl(pipelineType),
            pipeline_retargeting_groups: new FormControl()
        });
    };
    /**
     * Reset retargeting groups value for all controls
     */
    RemarketingGroupsSettingsComponent.prototype.resetRetargetingGroupsForAllControls = function () {
        var _this = this;
        var keys = Object.keys(this.form.get('pipeline_statuses').controls);
        keys
            .map(function (key) { return key.replace('pipeline_', ''); })
            .map(function (key) { return _this.getControlByPipelineId(key); })
            .forEach(function (control) { return control.reset(); });
    };
    RemarketingGroupsSettingsComponent.prototype.getControlByPipelineId = function (pipelineId) {
        if (pipelineId === void 0) { pipelineId = null; }
        var formControl = this.form
            .get('pipeline_statuses')
            .get("pipeline_" + pipelineId)
            .get('pipeline_retargeting_groups');
        return formControl;
    };
    RemarketingGroupsSettingsComponent.prototype.GetSelectedRemarketingGroups = function () {
        var formValue = this.form.value;
        var pipelineStatuses = formValue.pipeline_statuses;
        var selectedRemarketingGroups = Object.keys(pipelineStatuses)
            .map(function (pipelineId) { return pipelineStatuses[pipelineId]; })
            .filter(function (pipelineStatus) { return pipelineStatus.pipeline_retargeting_groups !== null; })
            .map(function (pipelineStatus) { return pipelineStatus.pipeline_retargeting_groups; })
            .reduce(function (acc, curr) { return acc.concat(curr); }, []);
        return Array.from(new Set(selectedRemarketingGroups)) || [];
    };
    RemarketingGroupsSettingsComponent.prototype.subscribeAllPipelineStatusesControls = function () {
        var _this = this;
        var pipelineStatusesControls = this.form.get('pipeline_statuses').controls;
        Object.keys(pipelineStatusesControls)
            .map(function (key) { return pipelineStatusesControls[key]; })
            .map(function (x) { return x.get('pipeline_retargeting_groups'); })
            .forEach(function (control) {
            control.valueChanges
                .subscribe(function (data) {
                if (data && data.length > 10) {
                    control.setValue(data.filter(function (item, index) { return index < 10; }));
                }
                _this.selectedRemarketingGroups = _this.GetSelectedRemarketingGroups();
            });
        });
    };
    RemarketingGroupsSettingsComponent.prototype.ngOnDestroy = function () {
    };
    RemarketingGroupsSettingsComponent.prototype.withoutSplitSuggestionCode = function () {
        if (!this.isAllContacts) {
            return 'MT_REMARKETING_GROUPS_GROUPS_SELECTOR_PIPELINES';
        }
        return 'MT_REMARKETING_GROUPS_GROUPS_SELECTOR_ALL_CONTACTS';
    };
    return RemarketingGroupsSettingsComponent;
}());
export { RemarketingGroupsSettingsComponent };
