import { OnInit } from '@angular/core';
import { StoreService } from '../../services/store.service';
import { UsersIntegrationsService } from "../../store/user-integrations/user-integration.service";
import { UserIntegrationQuery } from "../../store/user-integrations/user-integration.query";
import { UserIntegrationStatisticsQuery } from "../../store/user-integration-statistics/user-integration-statistics.query";
import { ActivatedRoute } from "@angular/router";
import { toBoolean } from "@datorama/akita";
import { IntegrationSessionQuery } from "../../store/integrations-session/integration-session.query";
import { map } from "rxjs/operators";
var IntegrationsTableComponent = /** @class */ (function () {
    function IntegrationsTableComponent(store, userIntegrationsService, userIntegrationsQuery, userIntegrationsStatistic, integrationSessionQuery, route) {
        this.store = store;
        this.userIntegrationsService = userIntegrationsService;
        this.userIntegrationsQuery = userIntegrationsQuery;
        this.userIntegrationsStatistic = userIntegrationsStatistic;
        this.integrationSessionQuery = integrationSessionQuery;
        this.route = route;
        this.userIntegrations$ = this.userIntegrationsQuery.userIntegrations$;
        this.isSessionExhausted$ = this.integrationSessionQuery.currentSession$
            .pipe(map(function (x) { return x.isLimitExhausted; }));
        this.showRemoved = false;
    }
    Object.defineProperty(IntegrationsTableComponent.prototype, "integrations", {
        get: function () {
            return this.store.integrations || [];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IntegrationsTableComponent.prototype, "loading", {
        get: function () {
            return this.store.loading || false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IntegrationsTableComponent.prototype, "loadingMessage", {
        get: function () {
            return this.store.loadingMessage;
        },
        enumerable: true,
        configurable: true
    });
    IntegrationsTableComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.queryParams.subscribe(function (_a) {
            var showRemoved = _a.showRemoved;
            _this.showRemoved = toBoolean(showRemoved);
            _this.LoadUsersIntegrations();
        });
    };
    IntegrationsTableComponent.prototype.UserIntegrationStatistic$ = function (id) {
        return this.userIntegrationsStatistic.integrationStatistic(id);
    };
    IntegrationsTableComponent.prototype.LoadUsersIntegrations = function () {
        this.userIntegrationsService.LoadUserIntegrations(this.showRemoved);
    };
    IntegrationsTableComponent.prototype.Remove = function (integrationId) {
        this.userIntegrationsService.RemoveUserIntegration(integrationId);
    };
    return IntegrationsTableComponent;
}());
export { IntegrationsTableComponent };
