import { EventEmitter, OnInit } from '@angular/core';
import { DateFilterViewModel } from '../../../models/view-models/date-filter-view-model';
import * as moment from 'moment';
import { Subject } from 'rxjs/Subject';
import { FormControl, FormGroup } from '@angular/forms';
var DateFilterComponent = /** @class */ (function () {
    // public groupToggled: Subject<DateFilterViewModel>
    function DateFilterComponent() {
        this.period = 'today';
        this.by = 'day';
        this.changingDate = new EventEmitter();
        this.dateFrom = moment(new Date()).format('DD.MM.YYYY');
        this.dateTo = moment(new Date()).format('DD.MM.YYYY');
        this.fromDateChanged = true;
        this.toDateChanged = true;
        this.subject = new Subject();
    }
    DateFilterComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.form = new FormGroup({
            period: new FormControl(),
            dateFrom: new FormControl(),
            dateTo: new FormControl()
        });
        this.subject.subscribe(function () {
            console.log({
                period: _this.period,
                dateFrom: moment(_this.dateFrom, 'DD.MM.YYYY').format('YYYY-MM-DD'),
                dateTo: moment(_this.dateTo, 'DD.MM.YYYY').format('YYYY-MM-DD'),
            });
            if (_this.fromDateChanged && _this.toDateChanged) {
                console.log({
                    period: _this.period,
                    dateFrom: moment(_this.dateFrom, 'DD.MM.YYYY', true).format('YYYY-MM-DD'),
                    dateTo: moment(_this.dateTo, 'DD.MM.YYYY', true).format('YYYY-MM-DD'),
                });
                console.log(_this.dateFrom, _this.dateTo);
                var dateFrom = moment(_this.dateFrom, 'DD.MM.YYYY');
                var dateTo = moment(_this.dateTo, 'DD.MM.YYYY');
                if (dateFrom.isAfter(dateTo)) {
                    var tempDate = dateTo;
                    dateTo = dateFrom;
                    dateFrom = tempDate;
                }
                console.log(_this.dateFrom, _this.dateTo);
                _this.dateFrom = dateFrom.format('YYYY-MM-DD');
                _this.dateTo = dateTo.format('YYYY-MM-DD');
                _this.changingDate.emit({
                    period: _this.period,
                    dateFrom: dateFrom.format('YYYY-MM-DD'),
                    dateTo: dateTo.format('YYYY-MM-DD'),
                });
            }
        });
    };
    Object.defineProperty(DateFilterComponent.prototype, "startDateButton", {
        get: function () {
            return moment(this.dateFrom, 'DD.MM.YYYY', true).locale('ru').format('DD MMM YYYY');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateFilterComponent.prototype, "stopDateButton", {
        get: function () {
            return moment(this.dateTo, 'DD.MM.YYYY', true).locale('ru').format('DD MMM YYYY');
        },
        enumerable: true,
        configurable: true
    });
    DateFilterComponent.prototype.ChangeDate = function () {
        console.log('DateChanged');
        var data = new DateFilterViewModel('', '', '');
        data['period'] = this.by;
        if (this.period.includes('_')) {
            var date = this.period.split('_');
            var units = date[1];
            this.dateTo = moment(new Date()).format('DD.MM.YYYY');
            console.log(parseInt(date[0], 10), units);
            // const date1 = (date[0] === '7') ? '6' : date[0];
            this.dateFrom = moment(this.dateTo, 'DD.MM.YYYY').clone().subtract(parseInt(date[0], 10), units).format('DD.MM.YYYY');
            console.log(parseInt(date[0], 10), 'DATE CHANGING');
            console.log(parseInt(date[0], 10) > 1, date[1] !== 'day', date);
            if (date[1] !== 'day') {
                this.dateFrom = moment(this.dateFrom, 'DD.MM.YYYY').clone().add(1, 'day').format('DD.MM.YYYY');
                console.log(this.dateFrom);
            }
            else {
                if (parseInt(date[0], 10) > 1) {
                    this.dateFrom = moment(this.dateFrom, 'DD.MM.YYYY').clone().add(1, 'day').format('DD.MM.YYYY');
                    console.log(this.dateFrom);
                }
            }
            var dateFrom = moment(this.dateFrom, 'DD.MM.YYYY');
            var dateTo = moment(this.dateTo, 'DD.MM.YYYY');
            if (dateFrom.isAfter(dateTo)) {
                var tempDate = dateTo;
                dateTo = dateFrom;
                dateFrom = tempDate;
            }
            data.dateFrom = dateFrom.format('YYYY-MM-DD');
            data.dateTo = dateTo.format('YYYY-MM-DD');
        }
        else {
            console.log(this.period, 'ERRORO!');
            switch (this.period) {
                case 'today':
                    this.dateFrom = moment(new Date()).format('DD.MM.YYYY');
                    console.log(this.dateFrom);
                    data.dateFrom = moment(this.dateFrom, 'DD.MM.YYYY').format('YYYY-MM-DD');
                    data.dateTo = moment(this.dateFrom, 'DD.MM.YYYY').format('YYYY-MM-DD');
                    data['period'] = 'day';
                    console.log(data);
                    break;
                case 'overall':
                    this.dateFrom = '01.06.2012';
                    this.dateTo = moment(new Date()).format('DD.MM.YYYY');
                    data.dateFrom = moment(this.dateFrom, 'DD.MM.YYYY').format('YYYY-MM-DD');
                    data.dateTo = moment(this.dateTo, 'DD.MM.YYYY').format('YYYY-MM-DD');
                    data['period'] = 'overall';
                    break;
                case 'yesterday':
                    this.dateFrom = moment(new Date()).subtract(1, 'days').format('DD.MM.YYYY');
                    this.dateTo = moment(new Date()).subtract(1, 'days').format('DD.MM.YYYY');
                    data.dateFrom = moment(this.dateFrom, 'DD.MM.YYYY').format('YYYY-MM-DD');
                    data.dateTo = moment(this.dateTo, 'DD.MM.YYYY').format('YYYY-MM-DD');
                    data['period'] = 'day';
                    break;
            }
        }
        this.changingDate.emit(data);
    };
    DateFilterComponent.prototype.ChangeFromDate = function (date) {
        this.period = 'day';
        this.dateFrom = date;
        this.fromDateChanged = true;
        this.subject.next();
    };
    DateFilterComponent.prototype.ChangeToDate = function (date) {
        this.period = 'day';
        this.dateTo = date;
        this.toDateChanged = true;
        this.subject.next();
    };
    DateFilterComponent.prototype.stringToDate = function (str) {
        if (str) {
            var date = str.split('.');
            return new Date(date[2] + "-" + date[1] + "-" + date[0]);
        }
        else {
            return null;
        }
    };
    DateFilterComponent.prototype.ChangeFromDate1 = function (date) {
        console.log(date);
    };
    return DateFilterComponent;
}());
export { DateFilterComponent };
