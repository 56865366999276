var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { map as __map, filter as __filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../api-configuration";
import * as i2 from "@angular/common/http";
var UserStrategyInputService = /** @class */ (function (_super) {
    __extends(UserStrategyInputService, _super);
    function UserStrategyInputService(config, http) {
        return _super.call(this, config, http) || this;
    }
    /**
     * @param params The `UserStrategyInputService.GetAllUserStrategyInputsParams` containing the following parameters:
     *
     * - `adId`:
     *
     * - `accountId`:
     *
     * @return Success
     */
    UserStrategyInputService.prototype.GetAllUserStrategyInputsResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.adId != null)
            __params = __params.set('adId', params.adId.toString());
        if (params.accountId != null)
            __params = __params.set('accountId', params.accountId.toString());
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/user-strategies-input", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `UserStrategyInputService.GetAllUserStrategyInputsParams` containing the following parameters:
     *
     * - `adId`:
     *
     * - `accountId`:
     *
     * @return Success
     */
    UserStrategyInputService.prototype.GetAllUserStrategyInputs = function (params) {
        return this.GetAllUserStrategyInputsResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `UserStrategyInputService.GetUserStrategyInputParams` containing the following parameters:
     *
     * - `id`:
     *
     * - `adId`:
     *
     * - `accountId`:
     *
     * @return Success
     */
    UserStrategyInputService.prototype.GetUserStrategyInputResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.id != null)
            __params = __params.set('id', params.id.toString());
        if (params.adId != null)
            __params = __params.set('adId', params.adId.toString());
        if (params.accountId != null)
            __params = __params.set('accountId', params.accountId.toString());
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/user-strategies-input/get-inputs", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `UserStrategyInputService.GetUserStrategyInputParams` containing the following parameters:
     *
     * - `id`:
     *
     * - `adId`:
     *
     * - `accountId`:
     *
     * @return Success
     */
    UserStrategyInputService.prototype.GetUserStrategyInput = function (params) {
        return this.GetUserStrategyInputResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    UserStrategyInputService.GetAllUserStrategyInputsPath = '/apiv2/user-strategies-input';
    UserStrategyInputService.GetUserStrategyInputPath = '/apiv2/user-strategies-input/get-inputs';
    UserStrategyInputService.ngInjectableDef = i0.defineInjectable({ factory: function UserStrategyInputService_Factory() { return new UserStrategyInputService(i0.inject(i1.ApiConfiguration), i0.inject(i2.HttpClient)); }, token: UserStrategyInputService, providedIn: "root" });
    return UserStrategyInputService;
}(__BaseService));
export { UserStrategyInputService };
