import { Pipe, PipeTransform } from '@angular/core';
import {isNullOrUndefined} from 'util';

@Pipe({
  name: 'domainOnly'
})
export class DomainOnlyPipe implements PipeTransform {

  transform(value: string): string {
    if (isNullOrUndefined(value)) {
      return null;
    }

    value = value.replace('http://', '');
    value = value.replace('https://', '');

    const firstSlashIndex = value.indexOf('/');

    if (firstSlashIndex > 0) {
      value = value.substr(0, firstSlashIndex);
    }

    if (value.indexOf('?') >= 0) {
      value = value.split('?')[0];
    }

    return value;
  }

}
