import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AmoService} from '../../../../api/services/amo.service';
import {MatDialogRef} from '@angular/material';
import {MegaplanService} from "../../../../api/services";
import {startWith} from "../../../validators/start-with.validator";

@Component({
  selector: 'app-megaplan-assignation-dialog',
  templateUrl: './megaplan-assignation-dialog.component.html',
  styleUrls: ['./megaplan-assignation-dialog.component.scss']
})
export class MegaplanAssignationDialogComponent implements OnInit {

  public loading: boolean = false;

  public form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private amo: AmoService,
    private dialogRef: MatDialogRef<MegaplanAssignationDialogComponent>,
    private megaplan: MegaplanService
  ) {
  }

  ngOnInit() {
    this.form = this.fb.group({
      domain: this.fb.control('', [Validators.required]),
      server: this.fb.control(''),
      email: this.fb.control('', [Validators.required, Validators.email]),
      password: this.fb.control('', [Validators.required])
    });
  }

  Assign() {
    if (this.form.valid) {
      this.loading = true;
      const value = this.form.value;
      const serverUrl = value.domain.includes('https://') ? value.domain : `https://${value.domain}`;
      let portalData = new URL(serverUrl).hostname
          .split('.')
      const domain = portalData[0]
      const server = portalData[1] ? `${portalData[1]}.${portalData[2]}` : null;
      value.domain = domain;
      value.server = server;
      
      // value.server = new URL(value.server).hostname;

      // value.domain = new URL(value.domain).host

      console.log(value, 'DOMAIN FUCK');

      this.megaplan.AuthorizeAsync(value)
        .subscribe(data => {
          this.loading = false;
          // localStorage.setItem('bind-return-to', '/new-integration');
          this.dialogRef.close();
        }, err => {
          this.loading = false;
        });
    }
  }

}
