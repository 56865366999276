import {Injectable} from '@angular/core';
import {Query} from '@datorama/akita';
import {PromocodeStore, PromocodeState} from './promocode.store';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class PromocodeQuery extends Query<PromocodeState> {

  public messages$ = this.select(state => state.messages);

  public promocode$ = this.select(state => state.promocode);

  public promocodeText$ = this.promocode$
    .pipe(
      map(x => x ? x.code : null)
    );

  public get promocode() {
    const promocode = this.getValue().promocode
    return promocode;
  };

  constructor(protected store: PromocodeStore) {
    super(store);
  }

}
