import {ID} from '@datorama/akita';

export interface PlatformAssignation {
  id: ID;
  assignationStatus: boolean;
}

/**
 * Поддерживаемые для подключения платформы
 */
export enum AssignationPlatforms {
  MY_TAREGT = 'MY_TARGET',
  VK = 'VK',
  BITRIX24 = 'BITRIX24',
  AMOCRM = 'AMOCRM',
  MEGAPLAN = 'MEGAPLAN',
  UON = 'UON',
  FACEBOOK = 'FACEBOOK',
  MOI_SKLAD = 'MOI_SKLAD',
  YANDEX = 'YANDEX'
}

/**
 * A factory function that creates PlatformAssignations
 */
export function createPlatformAssignation(params: Partial<PlatformAssignation>) {
  return params as PlatformAssignation;
}
