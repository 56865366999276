import { OnInit } from '@angular/core';
import { AffiliateProgramStatisticsByDateService } from "../../services/affiliate-program-statistics-by-date.service";
var AffiliateProgramStatisticsByDateTableComponent = /** @class */ (function () {
    function AffiliateProgramStatisticsByDateTableComponent(service) {
        this.service = service;
        this.displayedColumns = ['statisticDate', 'amount', 'countOfTransactions'];
    }
    Object.defineProperty(AffiliateProgramStatisticsByDateTableComponent.prototype, "statistics$", {
        get: function () {
            return this.service.statistics$;
        },
        enumerable: true,
        configurable: true
    });
    AffiliateProgramStatisticsByDateTableComponent.prototype.ngOnInit = function () {
    };
    return AffiliateProgramStatisticsByDateTableComponent;
}());
export { AffiliateProgramStatisticsByDateTableComponent };
