import {Injectable} from '@angular/core';
import {IVkGroupViewModel, VkGroupViewModel} from '../../models/vk-group.view-model';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class GroupsSelectorService {

  public MAX_COUNT_OF_GROUPS = 100;

  public groups: Array<VkGroupViewModel> = [];
  public groupsSubject: BehaviorSubject<Array<IVkGroupViewModel>> = new BehaviorSubject<Array<IVkGroupViewModel>>([]);

  public selectedGroups: Array<VkGroupViewModel> = [];
  public selectedGroupsSubject: BehaviorSubject<Array<IVkGroupViewModel>> = new BehaviorSubject<Array<IVkGroupViewModel>>([]);

  constructor() {
  }

  public AddGroups(groups: Array<any>) {
    this.groups = this.groups.concat(groups);
    this.groupsSubject.next(this.groups);
  }

  public RemoveGroup(groupId) {
    this.groups = this.groups.filter(group => {
      return group['id'] !== groupId;
    });
    this.groupsSubject.next(this.groups);
  }

  public ClearSelectedGroups() {
    this.selectedGroups = [];
    this.selectedGroupsSubject.next(this.selectedGroups);
    console.log(this.selectedGroups, 'SELECTED_GROUPS');
  }

  public SelectGroups(groups: Array<any>) {
    groups.forEach(group => {
      const index = this.selectedGroups.map(x => x['id']).indexOf(group.id);
      if (index < 0) {
        if (this.selectedGroups.length < this.MAX_COUNT_OF_GROUPS) {
          this.selectedGroups.push(group);
        }
      } else {
        this.selectedGroups.splice(index, 1);
      }
    });

    this.selectedGroups = _.uniq(this.selectedGroups);
    this.selectedGroupsSubject.next(this.selectedGroups);
  }

  public IsChecked(group) {
    return this.selectedGroups.map(x => x['id']).includes(group.id);
  }

  public CanBeSelected(group) {
    return this.selectedGroups.length < this.MAX_COUNT_OF_GROUPS || this.IsChecked(group);
  }

  public ClearGroups() {
    this.groups = [];
    this.groupsSubject.next(this.selectedGroups);
  }
}
