import {Component, Input, OnInit} from '@angular/core';
import {isNullOrUndefined} from "util";

@Component({
  selector: 'app-memory-alert',
  templateUrl: './alert-with-memory.component.html',
  styleUrls: ['./alert-with-memory.component.scss']
})
export class AlertWithMemoryComponent implements OnInit {
  @Input() public id: string = null;
  public visibility: boolean = true;

  private readonly prefix: string = 'memory-alert';

  constructor() {
  }

  ngOnInit() {
    this.LoadAlertState();
  }

  public CloseAlert(): void {
    this.SaveAlertState();
    this.visibility = false;
  }

  private LoadAlertState(): void {
    if (this.id === null) {
      console.warn('Id not set up for memory-alert. It\'s going to be memory-less');
      return;
    }

    const state = localStorage.getItem(this.GetUniqueName());

    if (!isNullOrUndefined(state)) {
      this.visibility = state === 'true';
    }
  }

  private SaveAlertState(): void {
    if (this.id === null) {
      console.warn('Id not set up for memory-alert. It\'s going to be memory-less');
      return;
    }

    const state = localStorage.setItem(this.GetUniqueName(), 'false');
  }

  private GetUniqueName(): string {
    if (this.id === null) {
      return;
    }

    return `${this.prefix}-${this.id}`;
  }
}
