import { EventEmitter, OnInit } from '@angular/core';
import { ControlContainer, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { UtilsService } from '../../../../shared/services/utils.service';
import { AdManagerService } from '../../../../api/services';
var CreateTeazerFormComponent = /** @class */ (function () {
    function CreateTeazerFormComponent(utilsService, controlContainer, adManagerService) {
        this.utilsService = utilsService;
        this.controlContainer = controlContainer;
        this.adManagerService = adManagerService;
        this.teazerInfoCreated = new EventEmitter();
        this.validStatusChanged = new EventEmitter();
        this.errors = {};
    }
    Object.defineProperty(CreateTeazerFormComponent.prototype, "TitleControls", {
        get: function () {
            return this.controlContainer.control.get('title').controls;
        },
        enumerable: true,
        configurable: true
    });
    CreateTeazerFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        console.log(this.controlContainer.control.get('title'));
        this.controlContainer.control.valueChanges.subscribe(function (value) {
            _this.validStatusChanged.emit(_this.controlContainer.control.valid);
            _this.errors = UtilsService.GetErrorsByForm('create-teazer-form', _this.controlContainer.control, null);
            _this.teazerInfoCreated.emit(value);
        });
        this.controlContainer.control.get('title').valueChanges.subscribe(function (data) {
            data.forEach(function (d, i) {
                if (d.length > 33) {
                    _this.controlContainer.control.get('title').controls[i].setValue(d.substring(0, 33));
                }
            });
        });
    };
    CreateTeazerFormComponent.prototype.ClearFormErrors = function () {
        this.errors = {};
    };
    CreateTeazerFormComponent.prototype.OnSelectImage = function (image) {
        console.log(image);
        this.controlContainer.control.get('image').patchValue(image);
    };
    CreateTeazerFormComponent.prototype.AddControl = function () {
        this.controlContainer.control.get('title')
            .push(new FormControl('', [Validators.required, Validators.maxLength(33)]));
    };
    CreateTeazerFormComponent.prototype.RemoveControl = function (i) {
        this.controlContainer.control.get('title').removeAt(i);
    };
    CreateTeazerFormComponent.prototype.CanBeRemoved = function () {
        return this.controlContainer.control.get('title').controls.length > 1;
    };
    CreateTeazerFormComponent.prototype.CanVisibleAdd = function (i) {
        return this.controlContainer.control.get('title').controls.length === i + 1;
    };
    return CreateTeazerFormComponent;
}());
export { CreateTeazerFormComponent };
