/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PostMonitorBackend_V2ModelsBitrixBitrixAuthData } from '../models/post-monitor-backend-_v2models-bitrix-bitrix-auth-data';
import { PostMonitorBackend_V2ModelsBitrixApiBitrixApiResponseint } from '../models/post-monitor-backend-_v2models-bitrix-api-bitrix-api-responseint';
import { PostMonitorBackend_V2ModelsBitrixApiBitrixDealCategory } from '../models/post-monitor-backend-_v2models-bitrix-api-bitrix-deal-category';
import { PostMonitorBackend_V2ModelsBitrixApiBitrixUser } from '../models/post-monitor-backend-_v2models-bitrix-api-bitrix-user';
import { PostMonitorBackend_V2ModelsBitrixApiBitrixLead } from '../models/post-monitor-backend-_v2models-bitrix-api-bitrix-lead';
import { PostMonitorBackend_V2ModelsBitrixApiBitrixFilter } from '../models/post-monitor-backend-_v2models-bitrix-api-bitrix-filter';
import { PostMonitorBackend_V2ModelsBitrixApiBitrixDeal } from '../models/post-monitor-backend-_v2models-bitrix-api-bitrix-deal';
import { PostMonitorBackend_V2ModelsBitrixApiBitrixTask } from '../models/post-monitor-backend-_v2models-bitrix-api-bitrix-task';
import { PostMonitorBackend_V2ModelsBitrixApiBitrixTaskCreate } from '../models/post-monitor-backend-_v2models-bitrix-api-bitrix-task-create';
import { PostMonitorBackend_V2ModelsBitrixApiBitrixContact } from '../models/post-monitor-backend-_v2models-bitrix-api-bitrix-contact';
import { PostMonitorBackend_V2ModelsBitrixApiBitrixActivity } from '../models/post-monitor-backend-_v2models-bitrix-api-bitrix-activity';
import { PostMonitorBackend_V2ModelsBitrixApiBitrixCrmStatus } from '../models/post-monitor-backend-_v2models-bitrix-api-bitrix-crm-status';
@Injectable({
  providedIn: 'root',
})
class BitrixService extends __BaseService {
  static readonly AuthorizeAsyncPath = '/apiv2/bitrix/auth';
  static readonly GetCrmSettingsModePath = '/apiv2/bitrix/crm-settings-mode';
  static readonly GetDealCategoryListPath = '/apiv2/bitrix/deal-category-list';
  static readonly GetDealCategoryDefaultPath = '/apiv2/bitrix/deal-category-default';
  static readonly GetUsersPath = '/apiv2/bitrix/users';
  static readonly GetLeadsPath = '/apiv2/bitrix/get-leads';
  static readonly GetDealsPath = '/apiv2/bitrix/get-deals';
  static readonly CreateLeadPath = '/apiv2/bitrix/create-lead';
  static readonly CreateDealPath = '/apiv2/bitrix/create-deal';
  static readonly GetTasksPath = '/apiv2/bitrix/get-tasks';
  static readonly CreateTaskPath = '/apiv2/bitrix/create-task';
  static readonly CreateContactPath = '/apiv2/bitrix/create-contact';
  static readonly GetContactsPath = '/apiv2/bitrix/get-contacts';
  static readonly Apiv2BitrixGetActivitiesPostPath = '/apiv2/bitrix/get-activities';
  static readonly Apiv2BitrixCreateActivityPostPath = '/apiv2/bitrix/create-activity';
  static readonly GetCrmStatusesPath = '/apiv2/bitrix/get-crm-statuses';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param credentials undefined
   */
  AuthorizeAsyncResponse(credentials?: PostMonitorBackend_V2ModelsBitrixBitrixAuthData): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = credentials;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/apiv2/bitrix/auth`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param credentials undefined
   */
  AuthorizeAsync(credentials?: PostMonitorBackend_V2ModelsBitrixBitrixAuthData): __Observable<null> {
    return this.AuthorizeAsyncResponse(credentials).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @return Success
   */
  GetCrmSettingsModeResponse(): __Observable<__StrictHttpResponse<PostMonitorBackend_V2ModelsBitrixApiBitrixApiResponseint>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/bitrix/crm-settings-mode`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PostMonitorBackend_V2ModelsBitrixApiBitrixApiResponseint>;
      })
    );
  }
  /**
   * @return Success
   */
  GetCrmSettingsMode(): __Observable<PostMonitorBackend_V2ModelsBitrixApiBitrixApiResponseint> {
    return this.GetCrmSettingsModeResponse().pipe(
      __map(_r => _r.body as PostMonitorBackend_V2ModelsBitrixApiBitrixApiResponseint)
    );
  }

  /**
   * @param start undefined
   * @return Success
   */
  GetDealCategoryListResponse(start?: number): __Observable<__StrictHttpResponse<Array<PostMonitorBackend_V2ModelsBitrixApiBitrixDealCategory>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (start != null) __params = __params.set('start', start.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/bitrix/deal-category-list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PostMonitorBackend_V2ModelsBitrixApiBitrixDealCategory>>;
      })
    );
  }
  /**
   * @param start undefined
   * @return Success
   */
  GetDealCategoryList(start?: number): __Observable<Array<PostMonitorBackend_V2ModelsBitrixApiBitrixDealCategory>> {
    return this.GetDealCategoryListResponse(start).pipe(
      __map(_r => _r.body as Array<PostMonitorBackend_V2ModelsBitrixApiBitrixDealCategory>)
    );
  }

  /**
   * @return Success
   */
  GetDealCategoryDefaultResponse(): __Observable<__StrictHttpResponse<PostMonitorBackend_V2ModelsBitrixApiBitrixDealCategory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/bitrix/deal-category-default`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PostMonitorBackend_V2ModelsBitrixApiBitrixDealCategory>;
      })
    );
  }
  /**
   * @return Success
   */
  GetDealCategoryDefault(): __Observable<PostMonitorBackend_V2ModelsBitrixApiBitrixDealCategory> {
    return this.GetDealCategoryDefaultResponse().pipe(
      __map(_r => _r.body as PostMonitorBackend_V2ModelsBitrixApiBitrixDealCategory)
    );
  }

  /**
   * @param start undefined
   * @return Success
   */
  GetUsersResponse(start?: number): __Observable<__StrictHttpResponse<Array<PostMonitorBackend_V2ModelsBitrixApiBitrixUser>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (start != null) __params = __params.set('start', start.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/bitrix/users`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PostMonitorBackend_V2ModelsBitrixApiBitrixUser>>;
      })
    );
  }
  /**
   * @param start undefined
   * @return Success
   */
  GetUsers(start?: number): __Observable<Array<PostMonitorBackend_V2ModelsBitrixApiBitrixUser>> {
    return this.GetUsersResponse(start).pipe(
      __map(_r => _r.body as Array<PostMonitorBackend_V2ModelsBitrixApiBitrixUser>)
    );
  }

  /**
   * @param filterData undefined
   * @return Success
   */
  GetLeadsResponse(filterData?: PostMonitorBackend_V2ModelsBitrixApiBitrixFilter): __Observable<__StrictHttpResponse<Array<PostMonitorBackend_V2ModelsBitrixApiBitrixLead>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = filterData;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/apiv2/bitrix/get-leads`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PostMonitorBackend_V2ModelsBitrixApiBitrixLead>>;
      })
    );
  }
  /**
   * @param filterData undefined
   * @return Success
   */
  GetLeads(filterData?: PostMonitorBackend_V2ModelsBitrixApiBitrixFilter): __Observable<Array<PostMonitorBackend_V2ModelsBitrixApiBitrixLead>> {
    return this.GetLeadsResponse(filterData).pipe(
      __map(_r => _r.body as Array<PostMonitorBackend_V2ModelsBitrixApiBitrixLead>)
    );
  }

  /**
   * @param filterData undefined
   * @return Success
   */
  GetDealsResponse(filterData?: PostMonitorBackend_V2ModelsBitrixApiBitrixFilter): __Observable<__StrictHttpResponse<Array<PostMonitorBackend_V2ModelsBitrixApiBitrixDeal>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = filterData;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/apiv2/bitrix/get-deals`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PostMonitorBackend_V2ModelsBitrixApiBitrixDeal>>;
      })
    );
  }
  /**
   * @param filterData undefined
   * @return Success
   */
  GetDeals(filterData?: PostMonitorBackend_V2ModelsBitrixApiBitrixFilter): __Observable<Array<PostMonitorBackend_V2ModelsBitrixApiBitrixDeal>> {
    return this.GetDealsResponse(filterData).pipe(
      __map(_r => _r.body as Array<PostMonitorBackend_V2ModelsBitrixApiBitrixDeal>)
    );
  }

  /**
   * @param lead undefined
   * @return Success
   */
  CreateLeadResponse(lead?: PostMonitorBackend_V2ModelsBitrixApiBitrixLead): __Observable<__StrictHttpResponse<Array<PostMonitorBackend_V2ModelsBitrixApiBitrixDeal>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = lead;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/apiv2/bitrix/create-lead`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PostMonitorBackend_V2ModelsBitrixApiBitrixDeal>>;
      })
    );
  }
  /**
   * @param lead undefined
   * @return Success
   */
  CreateLead(lead?: PostMonitorBackend_V2ModelsBitrixApiBitrixLead): __Observable<Array<PostMonitorBackend_V2ModelsBitrixApiBitrixDeal>> {
    return this.CreateLeadResponse(lead).pipe(
      __map(_r => _r.body as Array<PostMonitorBackend_V2ModelsBitrixApiBitrixDeal>)
    );
  }

  /**
   * @param deal undefined
   * @return Success
   */
  CreateDealResponse(deal?: PostMonitorBackend_V2ModelsBitrixApiBitrixDeal): __Observable<__StrictHttpResponse<Array<PostMonitorBackend_V2ModelsBitrixApiBitrixDeal>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = deal;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/apiv2/bitrix/create-deal`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PostMonitorBackend_V2ModelsBitrixApiBitrixDeal>>;
      })
    );
  }
  /**
   * @param deal undefined
   * @return Success
   */
  CreateDeal(deal?: PostMonitorBackend_V2ModelsBitrixApiBitrixDeal): __Observable<Array<PostMonitorBackend_V2ModelsBitrixApiBitrixDeal>> {
    return this.CreateDealResponse(deal).pipe(
      __map(_r => _r.body as Array<PostMonitorBackend_V2ModelsBitrixApiBitrixDeal>)
    );
  }

  /**
   * @param filterData undefined
   * @return Success
   */
  GetTasksResponse(filterData?: PostMonitorBackend_V2ModelsBitrixApiBitrixFilter): __Observable<__StrictHttpResponse<Array<PostMonitorBackend_V2ModelsBitrixApiBitrixTask>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = filterData;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/apiv2/bitrix/get-tasks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PostMonitorBackend_V2ModelsBitrixApiBitrixTask>>;
      })
    );
  }
  /**
   * @param filterData undefined
   * @return Success
   */
  GetTasks(filterData?: PostMonitorBackend_V2ModelsBitrixApiBitrixFilter): __Observable<Array<PostMonitorBackend_V2ModelsBitrixApiBitrixTask>> {
    return this.GetTasksResponse(filterData).pipe(
      __map(_r => _r.body as Array<PostMonitorBackend_V2ModelsBitrixApiBitrixTask>)
    );
  }

  /**
   * @param task undefined
   * @return Success
   */
  CreateTaskResponse(task?: PostMonitorBackend_V2ModelsBitrixApiBitrixTaskCreate): __Observable<__StrictHttpResponse<Array<PostMonitorBackend_V2ModelsBitrixApiBitrixTask>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = task;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/apiv2/bitrix/create-task`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PostMonitorBackend_V2ModelsBitrixApiBitrixTask>>;
      })
    );
  }
  /**
   * @param task undefined
   * @return Success
   */
  CreateTask(task?: PostMonitorBackend_V2ModelsBitrixApiBitrixTaskCreate): __Observable<Array<PostMonitorBackend_V2ModelsBitrixApiBitrixTask>> {
    return this.CreateTaskResponse(task).pipe(
      __map(_r => _r.body as Array<PostMonitorBackend_V2ModelsBitrixApiBitrixTask>)
    );
  }

  /**
   * @param contact undefined
   * @return Success
   */
  CreateContactResponse(contact?: PostMonitorBackend_V2ModelsBitrixApiBitrixContact): __Observable<__StrictHttpResponse<Array<PostMonitorBackend_V2ModelsBitrixApiBitrixContact>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = contact;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/apiv2/bitrix/create-contact`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PostMonitorBackend_V2ModelsBitrixApiBitrixContact>>;
      })
    );
  }
  /**
   * @param contact undefined
   * @return Success
   */
  CreateContact(contact?: PostMonitorBackend_V2ModelsBitrixApiBitrixContact): __Observable<Array<PostMonitorBackend_V2ModelsBitrixApiBitrixContact>> {
    return this.CreateContactResponse(contact).pipe(
      __map(_r => _r.body as Array<PostMonitorBackend_V2ModelsBitrixApiBitrixContact>)
    );
  }

  /**
   * @param filterData undefined
   * @return Success
   */
  GetContactsResponse(filterData?: PostMonitorBackend_V2ModelsBitrixApiBitrixFilter): __Observable<__StrictHttpResponse<Array<PostMonitorBackend_V2ModelsBitrixApiBitrixContact>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = filterData;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/apiv2/bitrix/get-contacts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PostMonitorBackend_V2ModelsBitrixApiBitrixContact>>;
      })
    );
  }
  /**
   * @param filterData undefined
   * @return Success
   */
  GetContacts(filterData?: PostMonitorBackend_V2ModelsBitrixApiBitrixFilter): __Observable<Array<PostMonitorBackend_V2ModelsBitrixApiBitrixContact>> {
    return this.GetContactsResponse(filterData).pipe(
      __map(_r => _r.body as Array<PostMonitorBackend_V2ModelsBitrixApiBitrixContact>)
    );
  }

  /**
   * @param filterData undefined
   */
  Apiv2BitrixGetActivitiesPostResponse(filterData?: PostMonitorBackend_V2ModelsBitrixApiBitrixFilter): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = filterData;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/apiv2/bitrix/get-activities`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param filterData undefined
   */
  Apiv2BitrixGetActivitiesPost(filterData?: PostMonitorBackend_V2ModelsBitrixApiBitrixFilter): __Observable<null> {
    return this.Apiv2BitrixGetActivitiesPostResponse(filterData).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param activity undefined
   */
  Apiv2BitrixCreateActivityPostResponse(activity?: PostMonitorBackend_V2ModelsBitrixApiBitrixActivity): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = activity;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/apiv2/bitrix/create-activity`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param activity undefined
   */
  Apiv2BitrixCreateActivityPost(activity?: PostMonitorBackend_V2ModelsBitrixApiBitrixActivity): __Observable<null> {
    return this.Apiv2BitrixCreateActivityPostResponse(activity).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `BitrixService.GetCrmStatusesParams` containing the following parameters:
   *
   * - `start`:
   *
   * - `filterData`:
   *
   * @return Success
   */
  GetCrmStatusesResponse(params: BitrixService.GetCrmStatusesParams): __Observable<__StrictHttpResponse<Array<PostMonitorBackend_V2ModelsBitrixApiBitrixCrmStatus>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.start != null) __params = __params.set('start', params.start.toString());
    __body = params.filterData;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/apiv2/bitrix/get-crm-statuses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PostMonitorBackend_V2ModelsBitrixApiBitrixCrmStatus>>;
      })
    );
  }
  /**
   * @param params The `BitrixService.GetCrmStatusesParams` containing the following parameters:
   *
   * - `start`:
   *
   * - `filterData`:
   *
   * @return Success
   */
  GetCrmStatuses(params: BitrixService.GetCrmStatusesParams): __Observable<Array<PostMonitorBackend_V2ModelsBitrixApiBitrixCrmStatus>> {
    return this.GetCrmStatusesResponse(params).pipe(
      __map(_r => _r.body as Array<PostMonitorBackend_V2ModelsBitrixApiBitrixCrmStatus>)
    );
  }
}

module BitrixService {

  /**
   * Parameters for GetCrmStatuses
   */
  export interface GetCrmStatusesParams {
    start?: number;
    filterData?: PostMonitorBackend_V2ModelsBitrixApiBitrixFilter;
  }
}

export { BitrixService }
