import {Injectable} from '@angular/core';
import {AdManagerDataServiceService} from "../../ad-manager/services/ad-manager-data-service.service";
import {PostMonitorBackend_V2ModelsAdManagerEntitiesInterestCategory} from "../../../api/models/post-monitor-backend-_v2models-ad-manager-entities-interest-category";

@Injectable({
  providedIn: 'root'
})
export class InterestCategoriesService {

  public categoriesTree = null;
  public selectedCategories = [];
  public openedCategories = [];
  public categories = []

  constructor(
    private adDataService: AdManagerDataServiceService
  ) {
    if (this.categoriesTree === null) {
      this.generateCategoriesTree();
    }
  }

  public generateCategoriesTree() {
    this.categoriesTree = this.adDataService.interestCategories
      .filter(cat => cat.parentId === null)
      .map(cat => {
        cat.children = this.getChildrens(cat);
        return cat;
      })
  }

  public selectCategory(category: PostMonitorBackend_V2ModelsAdManagerEntitiesInterestCategory) {
    if (!this.selectedCategories.includes(category.id)) {
      this.selectedCategories.push(category.id);
      if (category.parentId) {
        this.selectParent(category);
      }
    }
  }

  public isSelected(category: PostMonitorBackend_V2ModelsAdManagerEntitiesInterestCategory) {
    return this.selectedCategories.includes(category.id);
  }

  public isCategoryChildrensPartlySelected(category: PostMonitorBackend_V2ModelsAdManagerEntitiesInterestCategory) {
    const childrens = this.getChildrens(category);
    const selectedStatuses = childrens.map(x => this.isSelected(x))
      .filter(x => x);
    return selectedStatuses.length >= 1 && selectedStatuses.length < childrens.length;
  }

  public isCategoryChildrensAllSelected(category: PostMonitorBackend_V2ModelsAdManagerEntitiesInterestCategory) {
    const childrens = this.getChildrens(category);
    console.log(childrens.reduce((stat, currentChild) => stat && this.isSelected(currentChild), false));
    return childrens.length > 0 && childrens.reduce((stat, currentChild) => stat && this.isSelected(currentChild), true) || false;
  }

  public getChildrens(node: PostMonitorBackend_V2ModelsAdManagerEntitiesInterestCategory) {
    return this.adDataService.interestCategories.filter(cat => cat.parentId === node.id);
  }

  public removeSelected(category: PostMonitorBackend_V2ModelsAdManagerEntitiesInterestCategory) {
    this.selectedCategories = this.selectedCategories.filter(x => x !== category.id);
    if (category.parentId) {
      this.removeParent(category)
    }
  }


  isCategoryOpened(category: any) {
    return this.openedCategories.includes(category.id)
  }

  openCategory(category: any) {
    this.openedCategories.push(category.id)
  }

  closeCategory(category: any) {
    this.openedCategories = this.openedCategories.filter(x => x !== category.id)
  }

  removeParentSelected(category: any) {
    this.removeSelected(category);
    category.children.forEach(x => {
      this.removeSelected(x);
    })
  }

  selectParentCategory(category: any) {
    this.selectCategory(category);
    category.children.forEach(x => {
      this.selectCategory(x);
    })
  }

  hasChildren(category: any) {
    const children = this.getChildrens(category);
    return children.length > 0;
  }

  private selectParent(childCategory) {
    const parent = this.getParent(childCategory);
    console.log(parent, this.isCategoryChildrensAllSelected(parent));
    if (parent && this.isCategoryChildrensAllSelected(parent)) {
      this.selectCategory(parent);
    }
  }

  private removeParent(childCategory: any) {
    if (this.selectedCategories.includes(childCategory.parentId)) {
      const parent = this.getParent(childCategory);
      if (parent) {
        this.removeSelected(parent);
      }
    }
  }

  private getParent(childCategory: any) {
    return this.adDataService.interestCategories.find(x => x.id === childCategory.parentId)
  }

  public getSelectedCategories() {
    console.log(this.selectedCategories);
    const selected = this.selectedCategories
      .map(x => this.adDataService.interestCategories.find(cat => cat.id === x))
      .filter(x => {
        const stat = (
          this.isParentWithAllChildrenSelected(x) ||
          this.isParentWithoutChildrens(x) ||
          this.isChildrenWithoutSelectedParent(x)
        )
        console.log(x.title, this.isParentWithAllChildrenSelected(x),
          this.isParentWithoutChildrens(x),
          this.isChildrenWithoutSelectedParent(x), x, 'stat');

        return stat;
      })
      .map(x => x.value);

    console.log(selected, 'selected');
    return selected;
  }

  private isParentWithAllChildrenSelected(category: PostMonitorBackend_V2ModelsAdManagerEntitiesInterestCategory) {
    return category.children && category.children.length > 0 && this.isCategoryChildrensAllSelected(category);
  }

  private isParentWithoutChildrens(category: PostMonitorBackend_V2ModelsAdManagerEntitiesInterestCategory) {
    return category.parentId === null && (category.children === null || category.children.length === 0);
  }

  private isChildrenWithoutSelectedParent(category: PostMonitorBackend_V2ModelsAdManagerEntitiesInterestCategory) {
    return category.parentId !== null && !this.selectedCategories.includes(category.parentId);
  }
}
