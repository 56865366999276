import {ChangeDetectorRef, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {pairwise, startWith} from "rxjs/operators";
import {CopyingModesGroupViewModel} from "../../models/copying-modes-group.view-model";
import {CopyingFormState, CopyingService} from "../../services/copying.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {CopyingAdDataViewModel} from "../../models/copying-ad-data.view-model";
import {CopyingType} from "../../copying-configurators/abstract-copying-configurator";
import {AdViewModel} from "../../../models/view-models/ad-view-model";


export enum CopyingCloseStatus {
  REFRESH_PAGE = 'REFRESH_PAGE',
  NAVIGATE_TO = 'NAVIGATE_TO',
  NOTHING_TO_DO = 'NOTHING_TO_DO'
}

@Component({
  selector: 'app-copying-ads-dialog',
  templateUrl: './copying-ads-dialog.component.html',
  styleUrls: ['./copying-ads-dialog.component.scss'],
  providers: [
    CopyingService
  ]
})
export class CopyingAdsDialogComponent implements OnInit, OnDestroy {
  public showCostValue: boolean;
  public showErrors: boolean;
  public costValueMinMessage: string;
  public costValueMaxMessage: string;
  public costValueLabelText: string;
  public enableSegmentation = false;
  public enabledGenerationLink = false;

  ngOnDestroy(): void {
    this.copyingService.state = CopyingFormState.DEFAULT_FORM;
    this.copyingService.adsToCopying = [];
    this.copyingService.form.reset({
      configurationMode: 'DEFAULT_COPYING',
      defaultConfiguration: {
        countOfCopies: 1
      }
    });
    this.cd.detach();
  }

  public segment: FormControl = new FormControl(false);
  public changeCpmCost: FormControl = new FormControl(false);
  public prevMode = 'BY_SEX_AND_AGE';

  public copyingAd: AdViewModel = new AdViewModel();
  public CloseDialogStatuse = CopyingCloseStatus;
  public showGenerationLinkForm: boolean = false;


  public get countOfAds() {
    return this.copyingService.adsToCopying.length;
  }

  public get countOfCopyedAds() {
    return this.copyingService.countOfCopyedAds;
  }

  // public get showGenerationLinkForm() {
  //   return this.form.value.generateLinks;
  // }

  public get ShowRedirectUrl() {
    const {accountId, campaignId, targetAccountId, targetCampaignId} = this.copyingService.form.value;
    return accountId !== targetAccountId || campaignId !== targetCampaignId;
  }

  public get isPromo() {
    return this.copyingService.ad_format === 9;
  }

  public get isAllNotCopied() {
    return this.copyingService.notCopyedAds.length === this.copyingService.adsToCopying.length;
  }

  public get formState() {
    return this.copyingService.state;
  }

  public get generateLinks() {
    return this.form.get('generateLinks').value
  }

  public messages = {
    BY_ACTIVE_GROUPS: 'по группам активности',
    BY_SEX_AND_AGE: 'по полу и возрасту',
    BY_RETARGETING_GROUPS: 'по группам ретаргетинга',
    BY_GROUPS: 'по группам',
    BY_ACTIVE_GROUPS_FILES: 'по группам активности из файла',
    BY_INTEREST_CATEGORIES: 'по категориям интересов',
    BY_INTEREST_CATEGORIES_CHECKBOXES_TREE: 'по категориям интересов из списка',
    BY_GROUPS_FILE: 'по группам из файла',
  }

  public get segmentName() {
    return this.messages[this.CurrentConfigurationMode];
  }

  public modes: CopyingModesGroupViewModel[] = [
    {
      title: 'По аудитории',
      suggestion_code: 'AUDITORIES_SEGMENTATION',
      children: [
        {
          title: 'По полу и возрасту',
          mode_code: 'BY_SEX_AND_AGE',
          suggestion_code: 'AGE_SEX_SEGMENTATION',
          is_new: false,
          can_be_selected: () => true,
          is_full_width: false
        },
        {
          title: 'По группам',
          mode_code: 'BY_GROUPS',
          suggestion_code: 'GROUPS_SEGMENT',
          is_new: false,
          can_be_selected: () => (this.copyingAd.groups === '' || this.copyingAd.groups === null),
          is_full_width: false
        },
        {
          title: 'По группам из файла',
          mode_code: 'BY_GROUPS_FILE',
          suggestion_code: 'GROUPS_FILE_SEGMENTATION',
          is_new: false,
          can_be_selected: () => true,
          is_full_width: false
        },
        {
          title: 'По группам ретаргетинга',
          mode_code: 'BY_RETARGETING_GROUPS',
          suggestion_code: 'RETARGETING_GROUPS_SEGMENTATION',
          is_new: false,
          can_be_selected: () => (this.copyingAd.containsRetargetingGroups()),
          is_full_width: false
        },
        {
          title: 'Активности в сообществах из списка групп в объявлении',
          mode_code: 'BY_ACTIVE_GROUPS',
          suggestion_code: 'ACTIVE_GROUPS_SEGMENTATION',
          is_new: true,
          can_be_selected: () => (this.copyingAd.containsActiveGroups()),
          is_full_width: true
        },
        {
          title: 'Активности в сообществах из файла',
          mode_code: 'BY_ACTIVE_GROUPS_FILES',
          suggestion_code: 'ACTIVE_GROUPS_FILE_SEGMENTATION',
          is_new: true,
          can_be_selected: () => true,
          is_full_width: true
        },
        {
          title: 'Категории интересов из списка в объявлении',
          mode_code: 'BY_INTEREST_CATEGORIES',
          suggestion_code: 'INTEREST_CATEGORIES_SEGMENT',
          is_new: true,
          can_be_selected: () => (this.copyingAd.containsInterestGroups()),
          is_full_width: true
        },
        {
          title: 'Категории интересов - выбор из списка',
          mode_code: 'BY_INTEREST_CATEGORIES_CHECKBOXES_TREE',
          suggestion_code: 'INTEREST_CATEGORIES_CHECKBOXES_TREE_SGEMENT',
          is_new: true,
          can_be_selected: () => true,
          is_full_width: true
        }
      ]
    }
  ];

  public get ShowDefaultConfigurationForms() {
    return !this.segment.value;
  }

  public get ShowCostConfiguration() {
    return this.changeCpmCost.value;
  }

  public get CurrentConfigurationMode() {
    return this.form.get('configurationMode').value;
  }

  public get errorMessage() {
    return this.copyingService.errorMessage;
  }

  public form: FormGroup;
  adsWithCopyingErrors: any[];

  public get ShowSegmentationInfo() {
    return (this.CopyingAdvancedMode || this.showGenerationLinkForm === true)
      && this.form.get('configurationMode').value !== CopyingType.DEFAULT_COPYING
      ;
  };

  get CopyingAdvancedMode() {
    return this.copyingService.openedAdvancedMode
  }

  get ShowCostValueControl() {
    return this.form.value.costType !== null;
  };

  public get ContainsLinks() {
    return this.copyingAd.ad_format === 9 && this.copyingAd.containsLinks();
  }

  constructor(
    public copyingService: CopyingService,
    @Inject(MAT_DIALOG_DATA) public dialogData: CopyingAdDataViewModel,
    public dialog: MatDialogRef<CopyingAdsDialogComponent>,
    private cd: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    const {
      account_id,
      ad_format,
      ads,
      campaign_id,
      client_id
    } = this.dialogData;
    this.copyingAd = ads[0];
    this.enableSegmentation = ads.length === 1;
    console.log(ads[0].CanGenerateLink(), 'ADS');
    this.enabledGenerationLink = ads[0].CanGenerateLink();
    const {adGroups, retargetingGroups, retargetingGroupsNot, activeGroups, interestGroups, link} = this.PrepareInitialData(ads);
    this.copyingService.init(
      `${account_id}_${client_id}`,
      `${campaign_id}`,
      `${account_id}_${client_id}`,
      `${campaign_id}`,
      ads,
      ad_format,
      adGroups[0],
      retargetingGroups[0].map(x => parseInt(x, 10)),
      retargetingGroupsNot[0],
      activeGroups[0],
      interestGroups[0],
      link
    );

    this.form = this.copyingService.form;

    this.SubscribToForm();
  }

  private SubscribToForm() {
    this.SubscribeToAgeSegments();
    this.SubscribeToCostType();
    this.SubscribeToLinkGeneration();
  }

  private SubscribeToCostType() {
    this.form.get('costType').valueChanges.subscribe(costType => {
      console.log(costType, 'COST TYPE');
      switch (costType) {
        case 0:
          this.showCostValue = true;
          this.showErrors = false;
          this.setCpcValidators();
          this.showErrors = true;
          break;
        case 1:
          this.showCostValue = true;
          this.showErrors = false;
          this.setCpmValidators();
          this.showErrors = true;
          break;
        case null:
          this.showCostValue = false;
          this.setDefaultValidators();
          break;
      }
      this.setCostValueMessageText();
    });
  }

  private SubscribeToAgeSegments() {
    this.segment.valueChanges
      .pipe(
        startWith(false),
        pairwise()
      )
      .subscribe(([segmentPrev, segmentCurr]) => {
        console.log(segmentPrev && !segmentCurr);
        if (segmentPrev && !segmentCurr) {
          this.prevMode = this.form.value.configurationMode;
          this.form.patchValue({
            configurationMode: 'DEFAULT_COPYING'
          })
        } else {
          this.form.patchValue({
            configurationMode: this.prevMode != 'DEFAULT_COPYING' ? this.prevMode : 'BY_SEX_AND_AGE'
          })
        }
      });
  }

  private PrepareInitialData(ads: Array<AdViewModel>) {
    const adGroups = ads.map(({groups}) => groups ? groups.split(',') : []);
    const retargetingGroups = ads.map(({retargeting_groups}) => retargeting_groups ? retargeting_groups.split(',') : []);
    const retargetingGroupsNot = ads.map(({retargeting_groups_not}) => retargeting_groups_not ? retargeting_groups_not.split(',') : []);
    const activeGroups = ads.map(({groups_active}) => groups_active ? groups_active.split(',') : []);
    const interestGroups = ads.map(({interestGroups}) => interestGroups ? interestGroups.split(',') : []);
    const link = ads.length > 1 ? '' : ads[0].GetLinkUrl()
    return {adGroups, retargetingGroups, retargetingGroupsNot, activeGroups, interestGroups, link};
  }

  save() {
    this.copyingService.CopyAds();
  }

  ToggleAdvancedMode() {
    this.copyingService.ToggleAdvancedMode();
  }

  CloseWithStatus(status: CopyingCloseStatus = CopyingCloseStatus.NAVIGATE_TO) {
    let link = '';
    if (status === CopyingCloseStatus.NAVIGATE_TO) {
      const [accountId, clientId] = this.form.value.targetAccountId.split('_');
      link = `/automation/ads?account_id=${accountId}&campaign_id=${this.form.value.targetCampaignId}&client_id=${clientId}`;
    }
    this.dialog.close({
      status: status,
      link
    });
  }

  public setCpmValidators() {
    const control = this.form.get('costValue');
    const minCpm = this.dialogData.bid_range.cpmMin / 100;
    const maxCpm = this.dialogData.bid_range.cpmMax / 100;
    this.costValueMinMessage = `Минимальная цена 1000 просмотров равна ${minCpm} руб.`;
    this.costValueMaxMessage = `Максимальная цена 1000 просмотров равна ${maxCpm} руб.`;
    control.setValidators([Validators.required, Validators.min(minCpm), Validators.max(maxCpm)]);
    control.setValue(minCpm);
    console.log(control.value);
    control.updateValueAndValidity();
    this.cd.detectChanges();
  }

  public setCpcValidators() {
    const control = this.form.get('costValue');
    const minCpc = this.dialogData.bid_range.cpcMin / 100;
    const maxCpc = this.dialogData.bid_range.cpcMax / 100;
    console.log(minCpc, maxCpc, 'MINMAX');
    this.costValueMinMessage = `Минимальная цена 1 перехода равна ${minCpc} руб.`;
    this.costValueMaxMessage = `Максимальная цена 1 перехода равна ${maxCpc} руб.`;
    control.setValidators([Validators.required, Validators.min(minCpc), Validators.max(maxCpc)]);
    control.setValue(minCpc);
    control.updateValueAndValidity();
    console.log(control.value);
    this.cd.detectChanges();
  }

  public setDefaultValidators() {
    const control = this.form.get('costValue');
    control.setValidators([])
  }

  public setCostValueMessageText() {
    const costType = this.form.get('costType').value;
    if (costType === 0) {
      this.costValueLabelText = 'Введите цену 1 перехода'
    } else {
      this.costValueLabelText = 'Введите цену 1000 показов'
    }
  }

  public ShowSegmentationData() {
    this.copyingService.openedAdvancedMode = false;
    this.showGenerationLinkForm = false;
  }

  public ToggleShowGenerationLink() {
    this.showGenerationLinkForm = !this.showGenerationLinkForm;
  }

  private SubscribeToLinkGeneration() {
    this.form
      .get('generateLinks')
      .valueChanges
      .subscribe(x => {
        this.showGenerationLinkForm = x;
      });
  }
}
