<form [formGroup]="form">
  <div class="teazer-form-lite">
    <div class="teazer-form-lite__form">
      <mat-radio-group [(ngModel)]="IsAdToGroup" [ngModelOptions]="{standalone: true}">
        <mat-radio-button color="primary" [value]="true">
          На вашу группу в ВКонтакте
          <ng-container *ngIf="HasOneGroup">
            <a [href]="'https://vk.com/'+FirstGroup.screen_name" target="_blank"
               rel="nofollow noopener">{{FirstGroup.name}}</a>
          </ng-container>
        </mat-radio-button>
        <br>
        <mat-radio-button color="primary" [value]="false">На сайт</mat-radio-button>
        <br>
      </mat-radio-group>

      <ng-container *ngIf="IsAdToGroup; else linkControl">
        <ng-container *ngIf="adminGroups.length > 0; else userHaveNotAdminGroups">
          <vk-form-field [controlsWidth]="controlsWidth" [labelWidth]="labelsWidth">
            <vk-label>Выберите группу</vk-label>
            <select vkInput class="vk-select" formControlName="linkAddress">
              <ng-container *ngFor="let group of adminGroups">
                <option [value]="'https://vk.com/' + group.screen_name">{{group.name}}</option>
              </ng-container>
            </select>
          </vk-form-field>
        </ng-container>
        <ng-template #userHaveNotAdminGroups>
          <div class="alert alert-danger">
            <div class="alert-items">
              <div class="alert-item">
                <div class="alert-text">
                  Нет ни одной группы для создания объявления.
                  Вам необходимо создать группу и вернуться на данную страницу.
                  Примечание: Не возможно создать объявление если у вас нет группы или она закрытая.
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </ng-container>

      <ng-template #linkControl>
        <vk-form-field [controlsWidth]="controlsWidth" [labelWidth]="labelsWidth">
          <vk-label>Введите ссылку на сайт</vk-label>
          <input type="url" vkInput formControlName="linkAddress">
          <vk-error validator="required">Вы не ввели ссылку на рекламируемый объект</vk-error>
          <vk-error validator="url">Вы не не корректную ссылку на рекламированный объект</vk-error>
        </vk-form-field>
      </ng-template>

      <vk-form-field formArrayName="text" [controlsWidth]="controlsWidth" [labelWidth]="labelsWidth">
        <vk-label>Текст объявления</vk-label>
        <input type="text" [formControlName]="0" vkInput maxlength="33">
        <vk-hint>Осталось символов: {{TeazerLength}}</vk-hint>
      </vk-form-field>

    </div>
    <div class="teazer-form-lite__example">
      <app-ad-teazers-list></app-ad-teazers-list>
    </div>
  </div>
</form>

<app-new-images-manager
  (selected)="ImageSelected($event)"
  [minWidth]="145"
  [minHeight]="165"
></app-new-images-manager>
