/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PostMonitorBackend_V2ModelsPaymentsViewModelsPrepearLinkPayment } from '../models/post-monitor-backend-_v2models-payments-view-models-prepear-link-payment';
@Injectable({
  providedIn: 'root',
})
class NewPaymentsService extends __BaseService {
  static readonly PrepareLinkPath = '/apiv2/new-payments';
  static readonly ConfirmPaymentPath = '/apiv2/new-payments/confirmpayment';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param prepearLinkPayment undefined
   * @return Success
   */
  PrepareLinkResponse(prepearLinkPayment?: PostMonitorBackend_V2ModelsPaymentsViewModelsPrepearLinkPayment): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = prepearLinkPayment;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/apiv2/new-payments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param prepearLinkPayment undefined
   * @return Success
   */
  PrepareLink(prepearLinkPayment?: PostMonitorBackend_V2ModelsPaymentsViewModelsPrepearLinkPayment): __Observable<string> {
    return this.PrepareLinkResponse(prepearLinkPayment).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param params The `NewPaymentsService.ConfirmPaymentParams` containing the following parameters:
   *
   * - `log`:
   *
   * - `paymasterInfo`:
   *
   * @return Success
   */
  ConfirmPaymentResponse(params: NewPaymentsService.ConfirmPaymentParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.log != null) __params = __params.set('log', params.log.toString());
    __body = params.paymasterInfo;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/apiv2/new-payments/confirmpayment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `NewPaymentsService.ConfirmPaymentParams` containing the following parameters:
   *
   * - `log`:
   *
   * - `paymasterInfo`:
   *
   * @return Success
   */
  ConfirmPayment(params: NewPaymentsService.ConfirmPaymentParams): __Observable<string> {
    return this.ConfirmPaymentResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module NewPaymentsService {

  /**
   * Parameters for ConfirmPayment
   */
  export interface ConfirmPaymentParams {
    log: boolean;
    paymasterInfo?: string;
  }
}

export { NewPaymentsService }
