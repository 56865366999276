import {Injectable} from '@angular/core';
import {AffiliateMembersStatisticsByDate} from './affiliate-members-statistics-by-date.model';
import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';

export interface AffiliateMembersStatisticsByDateState extends EntityState<AffiliateMembersStatisticsByDate> {
  ui: {
    dateFrom: Date;
    dateTo: Date;
  }
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'affiliate-members-statistics-by-date'})
export class AffiliateMembersStatisticsByDateStore extends EntityStore<AffiliateMembersStatisticsByDateState> {

  constructor() {
    super({
      ui: {
        dateFrom: null,
        dateTo: null
      }
    });
  }

}

