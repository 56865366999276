import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild, ViewChildren} from '@angular/core';
import {MatCheckboxChange, MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import {AdFormManagerService} from '../../services/ad-form-manager/ad-form-manager.service';

@Component({
  selector: 'app-groups-table',
  templateUrl: './groups-table.component.html',
  styleUrls: ['./groups-table.component.scss']
})
export class GroupsTableComponent implements OnInit, OnChanges {

  @Input() public groups: Array<any> = [];
  @Input() public displayColumns = ['checkbox', 'avatar', 'name', 'members_count'];

  @ViewChild(MatPaginator) paginator;
  @ViewChild(MatSort) sort;

  public dataSource: MatTableDataSource<any>;

  public columns = ['checkbox', 'avatar', 'name', 'members_count'];

  public get CountOfSelected() {
    return this.adFormManagerService.countOfSelected();
  }

  constructor(
    private adFormManagerService: AdFormManagerService
  ) {
  }

  ngOnInit() {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);
    this.dataSource = new MatTableDataSource(this.groups);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public toggleSelectedRow(group) {
    this.adFormManagerService.toggleSelectedRow(group);
  }

  public isGroupSelected(group) {
    return this.adFormManagerService.isGroupSelected(group);
  }

  public ToggleAll($event: Event | MatCheckboxChange) {
    this.adFormManagerService.ToggleAll();
  }

  public IsAllToggled() {
    return this.adFormManagerService.IsAllToggled();
  }

  public IsRowDisabled(group: any) {
    return this.adFormManagerService.IsRowDisabled(group);
  }
}
