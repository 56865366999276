/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./side-form-wrapper.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./side-form-wrapper.component";
var styles_SideFormWrapperComponent = [i0.styles];
var RenderType_SideFormWrapperComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SideFormWrapperComponent, data: {} });
export { RenderType_SideFormWrapperComponent as RenderType_SideFormWrapperComponent };
export function View_SideFormWrapperComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "side-form"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "side-form__content"]], null, null, null, null, null)), i1.ɵncd(null, 0)], null, null); }
export function View_SideFormWrapperComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-side-form-wrapper", [], null, null, null, View_SideFormWrapperComponent_0, RenderType_SideFormWrapperComponent)), i1.ɵdid(1, 114688, null, 0, i2.SideFormWrapperComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SideFormWrapperComponentNgFactory = i1.ɵccf("app-side-form-wrapper", i2.SideFormWrapperComponent, View_SideFormWrapperComponent_Host_0, {}, {}, ["*"]);
export { SideFormWrapperComponentNgFactory as SideFormWrapperComponentNgFactory };
