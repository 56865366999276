import { Component, OnInit } from '@angular/core';
import {AdManagerService} from '../../../../../api/services/ad-manager.service';
import {AdFormManagerService} from '../../../new-ad-manager-shared/services/ad-form-manager/ad-form-manager.service';

@Component({
  selector: 'app-new-update-lite',
  templateUrl: './new-update-lite.component.html',
  styleUrls: ['./new-update-lite.component.scss']
})
export class NewUpdateLiteComponent implements OnInit {

  constructor(
    private adFormManagerService: AdFormManagerService,
    private adManagerService: AdManagerService
  ) {
  }

  public get IsLoading() {
    return this.adFormManagerService.adDataLoading;
  }

  ngOnInit() {
    this.adFormManagerService.reset();
    this.adFormManagerService.IsUpdate = true;
    this.adFormManagerService.LoadData()
      .subscribe((data) => {
        console.log('LOADING DATA FUCK!!!!');
        console.log(data);
      });
  }

  public OnSave(specifications: any) {
    this.adFormManagerService
      .UpdateAd()
      .subscribe(data => {
        console.log(data);
      });
  }

}
