import {Injectable} from '@angular/core';
import {AffiliateMemberStattisticsByUser} from './affiliate-member-stattistics-by-user.model';
import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';

export interface AffiliateMemberStattisticsByUserState extends EntityState<AffiliateMemberStattisticsByUser> {
  ui: {
    dateFrom: Date,
    dateTo: Date
  }
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'affiliate-member-stattistics-by-user'})
export class AffiliateMemberStattisticsByUserStore extends EntityStore<AffiliateMemberStattisticsByUserState> {

  constructor() {
    super({
      ui: {
        dateFrom: null,
        dateTo: null
      }
    });
  }

}

