import {Component, Input, OnInit} from '@angular/core';
import {WallPost} from '../../../api/models/wall-post';
import {WallLink} from '../../../api/models/wall-link';

@Component({
  selector: 'app-easy-ad-preview-promo',
  templateUrl: './easy-ad-preview-promo.component.html',
  styleUrls: ['./easy-ad-preview-promo.component.scss']
})
export class EasyAdPreviewPromoComponent implements OnInit {
  @Input() public graphicsLoaded: boolean = false;

  @Input() public post: WallPost; // Where any is a correct format for ad

  constructor() {
  }

  ngOnInit() {
  }

  public GetSpinnetLink(link: WallLink): string {
    if (link == null || link === undefined) {
      link = this.GetLinkSnippet();
    }

    if (link) {
      return  this.GetLinkSnippetHighestResolutionImage(link);
    }

    return null;
  }

  public GetLinkSnippet(): WallLink {
    if (this.post && this.post.attachments) {
      const linkAttachment = this.post.attachments.find(x => x.type === 'link');

      if (linkAttachment) {
        return linkAttachment.link;
      }
    }

    return null;
  }

  public GetLinkSnippetHighestResolutionImage(link: WallLink): string {
    if (link && link.photo && link.photo.sizes) {
      let biggestWidth = 0;
      let url = null;

      link.photo.sizes.forEach(size => {
        if (size.width > biggestWidth) {
          biggestWidth = size.width;
          url = size.url;
        }
      });

      return url;
    }

    return null;
  }
}
