import { EventEmitter, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AccountsService } from '../../../../api/services/accounts.service';
import { UtilsService } from '../../../../shared/services/utils.service';
import { OccupationsService } from '../../../../api/services/occupations.service';
import { ActivatedRoute } from "@angular/router";
var SignUpFormComponent = /** @class */ (function () {
    function SignUpFormComponent(accountsService, occupationsService, route) {
        this.accountsService = accountsService;
        this.occupationsService = occupationsService;
        this.route = route;
        this.messages = null;
        this.loading = false;
        this.formSubmited = false;
        this.onSubmit = new EventEmitter();
        this.accepted = true;
        this.showExternalLogins = true;
    }
    Object.defineProperty(SignUpFormComponent.prototype, "Errors", {
        get: function () {
            return this.errors || UtilsService.GetErrorsByForm('registration', this.form.controls);
        },
        enumerable: true,
        configurable: true
    });
    SignUpFormComponent.prototype.ngOnInit = function () {
        this.form = new FormGroup({
            email: new FormControl('', [Validators.required, Validators.email]),
            password: new FormControl('', [
                Validators.required,
                Validators.minLength(4),
                Validators.maxLength(32)
            ]),
            confirmPassword: new FormControl('', [
                Validators.required,
                Validators.minLength(4),
                Validators.maxLength(32)
            ]),
            occupationId: new FormControl(null),
            phone: new FormControl('', [
                Validators.required,
            ]),
            name: new FormControl('', [
                Validators.required,
            ])
        });
        this.LoadOccupations();
    };
    SignUpFormComponent.prototype.submit = function ($event) {
        var _this = this;
        $event.preventDefault();
        this.errors = {};
        console.log(this.form.valid);
        console.log(this.form.valid);
        this.formSubmited = true;
        if (this.form.valid) {
            this.ToggleLoading();
            this.showExternalLogins = false;
            var formValue = this.form.value;
            var affiliateMemberCode = this.getCookie('affiliate_member_code');
            var utmData = this.getCookie('utm_codes');
            if (affiliateMemberCode) {
                formValue['affiliate_member_code'] = affiliateMemberCode;
                formValue['utmData'] = utmData ? JSON.parse(utmData) : {};
            }
            this.accountsService
                .signUp(this.form.value)
                .subscribe(function (data) {
                _this.messages = data.message;
                _this.form.reset();
                _this.ToggleLoading();
            }, function (err) {
                console.log(err);
                _this.errors['system'] = err.error.map(function (x) { return x.description; });
                _this.ToggleLoading();
            });
        }
        else {
            this.errors = UtilsService.GetErrorsByForm('registration', this.form.controls);
            console.log(this.form.touched);
            console.log(this.form.dirty);
        }
    };
    SignUpFormComponent.prototype.LoadOccupations = function () {
        var _this = this;
        this.ToggleLoading();
        this.occupationsService.GetOccupations()
            .subscribe(function (x) {
            _this.ToggleLoading();
            _this.occupations = x;
        });
    };
    SignUpFormComponent.prototype.ToggleLoading = function () {
        this.loading = !this.loading;
    };
    SignUpFormComponent.prototype.getCookie = function (name) {
        var pair = document.cookie.match(new RegExp(name + '=([^;]+)'));
        return !!pair ? pair[1] : null;
    };
    return SignUpFormComponent;
}());
export { SignUpFormComponent };
