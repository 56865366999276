/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./promopost-meta-data.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./promopost-meta-data.component";
var styles_PromopostMetaDataComponent = [i0.styles];
var RenderType_PromopostMetaDataComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PromopostMetaDataComponent, data: {} });
export { RenderType_PromopostMetaDataComponent as RenderType_PromopostMetaDataComponent };
export function View_PromopostMetaDataComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "promopost-meta"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["class", "promopost-meta__avatar"]], [[8, "src", 4], [8, "alt", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "promopost-meta__text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "promopost-meta__group-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "promopost-meta__ad-status"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u0420\u0435\u043A\u043B\u0430\u043C\u043D\u0430\u044F \u0437\u0430\u043F\u0438\u0441\u044C"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.meta == null) ? null : _co.meta.avatar); var currVal_1 = ((_co.meta == null) ? null : _co.meta.groupName); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = ((_co.meta == null) ? null : _co.meta.groupName); _ck(_v, 4, 0, currVal_2); }); }
export function View_PromopostMetaDataComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-promopost-meta-data", [], null, null, null, View_PromopostMetaDataComponent_0, RenderType_PromopostMetaDataComponent)), i1.ɵdid(1, 114688, null, 0, i2.PromopostMetaDataComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PromopostMetaDataComponentNgFactory = i1.ɵccf("app-promopost-meta-data", i2.PromopostMetaDataComponent, View_PromopostMetaDataComponent_Host_0, { promopostMeta: "promopostMeta" }, {}, []);
export { PromopostMetaDataComponentNgFactory as PromopostMetaDataComponentNgFactory };
