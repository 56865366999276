<ng-container *ngIf="useWrapper; else noWrapper">
  <div class="easy-ads-layout">
    <div class="easy-ads-layout__side-menu">
      <nav class="easy-ads-side-menu">
        <ng-container *ngFor="let item of menuItems">
          <ng-container *ngIf="item.enabled">
            <div class="easy-ads-side-menu__item">
              <a [routerLink]="item.route" [queryParams]="item.params" class="easy-ads-side-menu-link" routerLinkActive="easy-ads-side-menu-link_active">
                {{item.name}}
              </a>
            </div>
          </ng-container>
        </ng-container>
      </nav>
    </div>
    <div class="easy-ads-layout__content">
      <router-outlet></router-outlet>
    </div>
  </div>
</ng-container>
<ng-template #noWrapper>
  <router-outlet></router-outlet>
</ng-template>
