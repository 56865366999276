import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {PostificatePipe} from '../../../../../shared/pipes/postificate.pipe';
import {PromopostViewModel} from '../../../../ad-manager/models/promopost.view-model';
import {IPromopostLinkWidgetViewModel} from '../../models/promopost-link-widget.view-model';
import {IPromopostMetaDataViewModel} from '../../models/promopost-meta-data.view-model';

@Component({
  selector: 'app-promopost',
  templateUrl: './promopost.component.html',
  styleUrls: ['./promopost.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PromopostComponent implements OnInit {

  @Input() public promopost: PromopostViewModel;
  @Input() public selectedGroup: any;
  @Input() public showEditForm: boolean = false;

  public get meta(): IPromopostMetaDataViewModel {
    let metaData = null;
    if (this.selectedGroup) {
      metaData = ({
        groupName: this.selectedGroup.name,
        avatar: this.selectedGroup.photo_50
      }) as IPromopostMetaDataViewModel;
    }
    return metaData;
  }

  public get linkWidget(): IPromopostLinkWidgetViewModel {
    return ({
      linkDomain: this.promopost.linkAddress || 'Здесь будет домен ссылки',
      linkTitle: this.promopost.linkTitle || 'Здесь будет название вашей ссылки',
      linkImage: this.promopost.image || 'assets/images/ad_size_promo.jpg'
    }) as IPromopostLinkWidgetViewModel;
  }

  public get text() {
    return new PostificatePipe().transform(this.promopost.text || '');
  }

  public ngOnInit(): void {
  }

}
