import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {AdFormManagerService} from '../../services/ad-form-manager/ad-form-manager.service';

@Component({
  selector: 'app-new-targeting-settings',
  templateUrl: './new-targeting-settings.component.html',
  styleUrls: ['./new-targeting-settings.component.scss']
})
export class NewTargetingSettingsComponent implements OnInit {

  public searchTargetGroups = new FormControl('');
  public searchTargetGroupsNot = new FormControl('');

  public controlWidth = '280px';
  public labelWidth = '200px';

  public TipGroups: string = 'Вы можете показывать объявление, подписчикам групп конкурентов или смежных по вашей тематике групп. Для этого введите названия групп, либо слова которые могут содержать названия групп.\n' +
    '\n' +
    'Пример:\n' +
    'Если ваш вид деятельности - салон красоты, то в поле Название группы введите “Салон красоты”, “Наращивание ресниц” или “Брови” и т.п. \n' +
    '\n' +
    'Выберите страну и город где находятся ваши клиенты. \n' +
    'Если вы и ваши клиенты находятся, например, в городе Екатеринбург, то выберите в поле Страна - “Россия”, а в поле Город или регион: Екатеринбург.\n' +
    '\n' +
    'После выбора групп, справа, будет показываться какому количеству пользователей может быть показано ваше объявление.\n';

  public resultColumns = ['avatar', 'name', 'functions'];

  public get loadingSearchGroups() {
    return this.adFormManagerService.loadingSearchGroups;
  }

  public get loadingTargetingStats(): boolean {
    return this.adFormManagerService.targetingStatsLoading;
  }

  public get groups() {
    return this.adFormManagerService.groups;
  }

  public get IsSearchGroupsCitiesDisabled() {
    return this.adFormManagerService.IsSearchCitiesDisabled();
  }

  public get IsSearchGroupCountryDisabled() {
    return this.adFormManagerService.IsSearchGroupsCountryDisabled();
  }

  public get targetingStats() {
    return this.adFormManagerService.targetingStats;
  }

  public get selectedGroups() {
    return this.adFormManagerService.selectedGroups;
  }

  public get citySearchString(): FormControl {
    return this.adFormManagerService.searchCitiesControl;
  }

  public get citySearchGroupCities(): FormControl {
    return this.adFormManagerService.searchGroupsCity;
  }

  public get searchGroupsCities() {
    return this.adFormManagerService.searchGroupsCities$;
  }

  public get countries() {
    return this.adFormManagerService.countries;
  }

  public get cities() {
    return this.adFormManagerService.displayedCities;
  }

  public get form() {
    return this.adFormManagerService.getControl('targetingData') as FormGroup;
  }

  public get searchForm() {
    return this.adFormManagerService.searchGroupsForm;
  }

  public get agesFrom() {
    return this.adFormManagerService.agesFrom;
  }

  public get agesTo() {
    return this.adFormManagerService.agesTo;
  }

  public get ShowRetargetingGroups() {
    return this.adFormManagerService.isPro;
  }

  public get TargetGroups() {
    let groups = this.adFormManagerService.SelectedTargetGroups;
    const targetGroupName = this.searchTargetGroups.value.toLowerCase();
    if (targetGroupName !== '') {
      groups = groups.filter(targetGroup => targetGroup.name.toLowerCase().match(targetGroupName))
    }
    return groups;
  }

  public get TargetGroupsNot() {
    let groups = this.adFormManagerService.SelectedTargetGroupsNot;
    const targetGroupName = this.searchTargetGroupsNot.value.toLowerCase();
    if (targetGroupName !== '') {
      groups = groups.filter(targetGroup => targetGroup.name.toLowerCase().match(targetGroupName))
    }
    return groups;
  }

  public get SelectedTargetingGroupsName() {
    const targetingGroups = this.adFormManagerService.getControl('targetingData', 'targetGroups').value || [];
    if (this.adFormManagerService.targetingGroups) {
      return this.adFormManagerService
        .targetingGroups
        .filter(group => targetingGroups.includes(group.id))
        .map(group => group.name)
    } else {
      return []
    }
  }

  public get SelectedTargetingGroupsNotName() {
    const targetingGroups = this.adFormManagerService.getControl('targetingData', 'targetGroupsNot').value || [];
    if (this.adFormManagerService.targetingGroups) {
      return this.adFormManagerService
        .targetingGroups
        .filter(group => targetingGroups.includes(group.id))
        .map(group => group.name)
    } else {
      return []
    }
  }

  public GetIsTitleValid() {
    return this.adFormManagerService.IsSearchGroupTitleValid();
  }

  public GetIsCountryValid() {
    return this.adFormManagerService.IsSearchGroupCountryValid();
  }

  constructor(
    private adFormManagerService: AdFormManagerService
  ) {
  }

  ngOnInit() {
    console.log(this.TargetGroups, 'TARGETING GROUPS');
  }

  public isGroupSelected(group) {
    return this.adFormManagerService.isGroupSelected(group);
  }

}
