import { AfterViewInit, OnInit, QueryList } from '@angular/core';
import { ErrorComponent } from '../error/error.component';
import { InputDirective } from '../../directives/input.directive';
export var ControlOrientation;
(function (ControlOrientation) {
    ControlOrientation["horizontal"] = "horizontal";
    ControlOrientation["vertical"] = "vertical";
})(ControlOrientation || (ControlOrientation = {}));
var FormFieldComponent = /** @class */ (function () {
    function FormFieldComponent() {
        this.orientation = ControlOrientation.horizontal;
        this.labelPosition = 'right';
        this.flexible = false;
        this.useMin = true;
        this.errorsMessages = [];
    }
    FormFieldComponent.prototype.ngOnInit = function () {
    };
    FormFieldComponent.prototype.ngAfterViewInit = function () {
        this.GetErrors();
    };
    FormFieldComponent.prototype.GetErrors = function () {
        var _this = this;
        this.errors.forEach(function (error) {
            if (!_this.errorsMessages[error.name]) {
                _this.errorsMessages[error.name] = [];
            }
            _this.errorsMessages.push({
                validator: error.validator,
                inputName: error.name,
                content: error.elementRef.nativeElement.innerHTML
            });
        });
        this.errors.changes.subscribe(function (errors) {
            _this.errorsMessages = [];
            errors.forEach(function (error) {
                if (!_this.errorsMessages[error.name]) {
                    _this.errorsMessages[error.name] = [];
                }
                _this.errorsMessages.push({
                    validator: error.validator,
                    inputName: error.name,
                    content: error.elementRef.nativeElement.innerHTML
                });
            });
        });
    };
    Object.defineProperty(FormFieldComponent.prototype, "value", {
        get: function () {
            return this.inputs.map(function (input) { return input.controlContainer.control; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormFieldComponent.prototype, "IsDirty", {
        get: function () {
            return this.inputs.toArray()
                .map(function (input) {
                return input.formControl.control.dirty;
            })
                .reduce(function (isControlDirty, isInputDirty) { return isControlDirty || isInputDirty; }, false);
        },
        enumerable: true,
        configurable: true
    });
    FormFieldComponent.prototype.IsInvalid = function (controlName, validatorName) {
        return this.inputs.toArray()
            .map(function (input) { return input.formControl.control; })
            .filter(function (control) { return control !== null; })
            .filter(function (control) { return control.valid !== true; })
            .map(function (control) { return Object.keys(control.errors); })
            .map(function (controlErrorKeys) { return controlErrorKeys.includes(validatorName); })
            .reduce(function (isValidControl, errorKeyIncludesInErrorsKeys) { return isValidControl || errorKeyIncludesInErrorsKeys; }, false);
    };
    return FormFieldComponent;
}());
export { FormFieldComponent };
