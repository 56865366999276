import { EventEmitter, OnInit } from '@angular/core';
import { ControlContainer, FormGroup } from "@angular/forms";
import { environment } from "../../../../../environments/environment";
var AgeSegmentationItemComponent = /** @class */ (function () {
    function AgeSegmentationItemComponent(controlContainer) {
        this.controlContainer = controlContainer;
        this.index = 0;
        this.removeTriggered = new EventEmitter();
        this.formValueChanged = new EventEmitter();
    }
    Object.defineProperty(AgeSegmentationItemComponent.prototype, "ageFromName", {
        get: function () {
            return "ageFrom_" + this.index;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AgeSegmentationItemComponent.prototype, "ageToName", {
        get: function () {
            return "ageTo_" + this.index;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AgeSegmentationItemComponent.prototype, "agesFrom", {
        get: function () {
            var _this = this;
            var ages = environment.vkAges;
            if (this.ageToValue) {
                ages = ages.filter(function (age) { return age <= _this.ageToValue; });
            }
            return ages;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AgeSegmentationItemComponent.prototype, "agesTo", {
        get: function () {
            var _this = this;
            var ages = environment.vkAges;
            if (this.ageFromValue) {
                ages = ages.filter(function (age) { return age >= _this.ageFromValue; });
            }
            return ages;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AgeSegmentationItemComponent.prototype, "ageFromValue", {
        get: function () {
            return this.controlContainer.control.get('ageFrom').value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AgeSegmentationItemComponent.prototype, "ageToValue", {
        get: function () {
            return this.controlContainer.control.get('ageTo').value;
        },
        enumerable: true,
        configurable: true
    });
    AgeSegmentationItemComponent.prototype.ngOnInit = function () {
        var _this = this;
        console.log(this.controlContainer);
        this.formGroup.valueChanges.subscribe(function (x) {
            _this.formValueChanged.emit({ index: _this.index, value: x });
        });
    };
    AgeSegmentationItemComponent.prototype.IsEnabledEachAges = function () {
        var ageFrom = this.ageFromValue;
        var ageTo = this.ageToValue;
        return ageFrom !== null && ageTo !== null;
    };
    AgeSegmentationItemComponent.prototype.Remove = function () {
        this.removeTriggered.emit(this.index);
    };
    return AgeSegmentationItemComponent;
}());
export { AgeSegmentationItemComponent };
