<table mat-table [dataSource]="statistics$">

  <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

  <!-- Position Column -->
  <ng-container matColumnDef="statisticDate">
    <th mat-header-cell *matHeaderCellDef width="250px">Дата</th>
    <td mat-cell *matCellDef="let element"> {{element.statisticDate | momentDate:"DD MMMM YYYY"}}</td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="amount">
    <th mat-header-cell *matHeaderCellDef width="100px">Сумма</th>
    <td mat-cell *matCellDef="let element"> {{element.amount | currency:"руб."}} </td>
  </ng-container>

  <!-- Weight Column -->
  <ng-container matColumnDef="countOfTransactions">
    <th mat-header-cell *matHeaderCellDef>Колличество оплат</th>
    <td mat-cell *matCellDef="let element"> {{element.countOfTransactions}} </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
