/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./strategy-settings.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/flex-layout/extended";
import * as i4 from "@angular/flex-layout/core";
import * as i5 from "@angular/platform-browser";
import * as i6 from "../../../../../node_modules/@clr/angular/clr-angular.ngfactory";
import * as i7 from "@clr/angular";
import * as i8 from "../../../shared/components/strategy-params/strategy-params.component.ngfactory";
import * as i9 from "../../../shared/components/strategy-params/strategy-params.component";
import * as i10 from "./strategy-settings.component";
import * as i11 from "../../../api/services/user-strategies.service";
var styles_StrategySettingsComponent = [i0.styles];
var RenderType_StrategySettingsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StrategySettingsComponent, data: {} });
export { RenderType_StrategySettingsComponent as RenderType_StrategySettingsComponent };
function View_StrategySettingsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "button", [["class", "btn btn-sm btn-outline-primary strategy-action"], ["disabled", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u0423\u043F\u0440\u0430\u0432\u043B\u044F\u0435\u0442\u0441\u044F \u0441 \u0434\u0440\u0443\u0433\u043E\u0433\u043E \u0430\u043A\u043A\u0430\u0443\u043D\u0442\u0430 "]))], null, null); }
function View_StrategySettingsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "strategy-settings__type"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.userStrategy.strategy.title; _ck(_v, 2, 0, currVal_0); }); }
function View_StrategySettingsComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "span", [["class", "icon ion-md-time"]], null, null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(3, { color: 0 }), i1.ɵdid(4, 933888, null, 0, i3.DefaultStyleDirective, [i1.ElementRef, i4.StyleUtils, i4.MediaMarshaller, i1.KeyValueDiffers, i1.Renderer2, i5.DomSanitizer, [6, i2.NgStyle], [2, i4.SERVER_TOKEN], i1.PLATFORM_ID], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(5, { color: 0 }), (_l()(), i1.ɵted(-1, null, [" \u0417\u0430\u043F\u0443\u0441\u043A\u0430\u0435\u0442\u0441\u044F "]))], function (_ck, _v) { var currVal_0 = _ck(_v, 3, 0, "#5181B7"); _ck(_v, 2, 0, currVal_0); var currVal_1 = _ck(_v, 5, 0, "#5181B7"); _ck(_v, 4, 0, currVal_1); }, null); }
function View_StrategySettingsComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "span", [], [[8, "className", 0]], null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(2, { color: 0 }), i1.ɵdid(3, 933888, null, 0, i3.DefaultStyleDirective, [i1.ElementRef, i4.StyleUtils, i4.MediaMarshaller, i1.KeyValueDiffers, i1.Renderer2, i5.DomSanitizer, [6, i2.NgStyle], [2, i4.SERVER_TOKEN], i1.PLATFORM_ID], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(4, { color: 0 }), (_l()(), i1.ɵted(5, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _ck(_v, 2, 0, ("#" + _co.userStrategy.status.color)); _ck(_v, 1, 0, currVal_1); var currVal_2 = _ck(_v, 4, 0, ("#" + _co.userStrategy.status.color)); _ck(_v, 3, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.userStrategy.status.icon; _ck(_v, 0, 0, currVal_0); var currVal_3 = _co.userStrategy.status.title; _ck(_v, 5, 0, currVal_3); }); }
function View_StrategySettingsComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u0423\u043F\u0440\u0430\u0432\u043B\u0435\u043D\u0438\u0435 \u0441\u0442\u0430\u0432\u043A\u043E\u0439 \u0432 \u043F\u0440\u043E\u0446\u0435\u0441\u0441\u0435 \u0437\u0430\u043F\u0443\u0441\u043A\u0430. \u042D\u0442\u043E\u0442 \u043F\u0440\u043E\u0446\u0435\u0441\u0441 \u0437\u0430\u0439\u043C\u0435\u0442 \u043E\u043A\u043E\u043B\u043E \u043C\u0438\u043D\u0443\u0442\u044B."]))], null, null); }
function View_StrategySettingsComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "span", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.userStrategy.status.description; _ck(_v, 0, 0, currVal_0); }); }
function View_StrategySettingsComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "clr-signpost-content", [], [[2, "signpost-content", null], [8, "id", 0], [2, "is-off-screen", null]], null, null, i6.View_ClrSignpostContent_0, i6.RenderType_ClrSignpostContent)), i1.ɵprd(1024, null, i7.ɵo, i7.ɵp, []), i1.ɵdid(2, 8568832, null, 0, i7.ClrSignpostContent, [i1.Injector, [2, i7.ɵh], i7.ClrCommonStringsService, i7.ɵo, i7.ɵda], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_StrategySettingsComponent_9)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["regularDescription", 2]], 0, 0, null, View_StrategySettingsComponent_10))], function (_ck, _v) { var _co = _v.component; var currVal_3 = ((_co.userStrategy.status.code === "WORKING") && _co.userStrategy.isStarting); var currVal_4 = i1.ɵnov(_v, 5); _ck(_v, 4, 0, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = true; var currVal_1 = i1.ɵnov(_v, 2).signpostContentId; var currVal_2 = i1.ɵnov(_v, 2).isOffScreen; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_StrategySettingsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StrategySettingsComponent_6)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["regularStatus", 2]], null, 0, null, View_StrategySettingsComponent_7)), (_l()(), i1.ɵeld(4, 0, null, null, 7, "clr-signpost", [], [[2, "signpost", null]], null, null, i6.View_ClrSignpost_0, i6.RenderType_ClrSignpost)), i1.ɵprd(6144, null, i7.ɵh, null, [i1.ElementRef]), i1.ɵprd(4608, null, i7.ɵda, i7.ɵda, []), i1.ɵdid(7, 49152, null, 1, i7.ClrSignpost, [i7.ClrCommonStringsService], null, null), i1.ɵqud(335544320, 1, { customTrigger: 0 }), i1.ɵprd(512, null, i7.ɵd, i7.ɵd, []), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_StrategySettingsComponent_8)), i1.ɵdid(11, 147456, null, 0, i7.ClrIfOpen, [i7.ɵd, i1.TemplateRef, i1.ViewContainerRef], { open: [0, "open"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.userStrategy.status.code === "WORKING") && _co.userStrategy.isStarting); var currVal_1 = i1.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_3 = null; _ck(_v, 11, 0, currVal_3); }, function (_ck, _v) { var currVal_2 = true; _ck(_v, 4, 0, currVal_2); }); }
function View_StrategySettingsComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "strategy-settings__inputs"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-strategy-params", [], null, null, null, i8.View_StrategyParamsComponent_0, i8.RenderType_StrategyParamsComponent)), i1.ɵdid(2, 114688, null, 0, i9.StrategyParamsComponent, [], { displayFunctions: [0, "displayFunctions"], userStrategyInputs: [1, "userStrategyInputs"] }, null), (_l()(), i1.ɵeld(3, 16777216, null, null, 1, null, null, null, null, null, null, null)), i1.ɵdid(4, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.displayFunctions; var currVal_1 = _co.userStrategy.inputs; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵnov(_v.parent.parent.parent, 6); _ck(_v, 4, 0, currVal_2); }, null); }
function View_StrategySettingsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "strategy-settings__header"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StrategySettingsComponent_4)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 7, "div", [["class", "strategy-settings__status"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 6, "div", [["class", "stategy-status-list"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "stategy-status-list__item"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StrategySettingsComponent_5)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "stategy-status-list__item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [["class", "strategy-status"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.ToggleInputsVisible() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(10, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StrategySettingsComponent_11)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.userStrategy.strategy; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.userStrategy.status; _ck(_v, 7, 0, currVal_1); var currVal_3 = _co.IsShowedInputs; _ck(_v, 12, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.GetToggleInputsText(); _ck(_v, 10, 0, currVal_2); }); }
function View_StrategySettingsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StrategySettingsComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["userStrategyisOkay", 2]], null, 0, null, View_StrategySettingsComponent_3))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.userStrategy.userId === (0 - 1)); var currVal_1 = i1.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_StrategySettingsComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "strategy-action-group"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.CreateStrategy() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "button", [["class", "btn btn-sm btn-outline-primary strategy-action"]], [[8, "disabled", 0]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "span", [["class", "ion-md-add"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u0423\u043F\u0440\u0430\u0432\u043B\u0435\u043D\u0438\u0435 \u0441\u0442\u0430\u0432\u043A\u043E\u0439 "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.disabled; _ck(_v, 2, 0, currVal_0); }); }
function View_StrategySettingsComponent_14(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u041E\u0441\u0442\u0430\u043D\u043E\u0432\u0438\u0442\u044C "]))], null, null); }
function View_StrategySettingsComponent_15(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" \u0417\u0430\u043F\u0443\u0441\u0442\u0438\u0442\u044C "]))], null, null); }
function View_StrategySettingsComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "div", [["class", "strategy-action-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 12, "button", [["class", "btn btn-sm strategy-action"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.ToggleStrategyActiveStatus() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "btn-success": 0, "btn-danger": 1 }), i1.ɵdid(4, 933888, null, 0, i3.DefaultClassDirective, [i1.ElementRef, i4.StyleUtils, i4.MediaMarshaller, i1.IterableDiffers, i1.KeyValueDiffers, i1.Renderer2, [6, i2.NgClass]], { ngClass: [0, "ngClass"], klass: [1, "klass"] }, null), i1.ɵpod(5, { "btn-success": 0, "btn-danger": 1 }), (_l()(), i1.ɵeld(6, 0, null, null, 4, "span", [], null, null, null, null, null)), i1.ɵdid(7, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(8, { "ion-md-play": 0, "ion-md-square": 1 }), i1.ɵdid(9, 933888, null, 0, i3.DefaultClassDirective, [i1.ElementRef, i4.StyleUtils, i4.MediaMarshaller, i1.IterableDiffers, i1.KeyValueDiffers, i1.Renderer2, [6, i2.NgClass]], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(10, { "ion-md-play": 0, "ion-md-square": 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StrategySettingsComponent_14)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["StrategyActiveButtonText", 2]], null, 0, null, View_StrategySettingsComponent_15)), (_l()(), i1.ɵeld(14, 0, null, null, 2, "button", [["class", "btn btn-sm strategy-action"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.EditStrategy() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 0, "span", [["class", "ion-md-create"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u0420\u0435\u0434\u0430\u043A\u0442\u0438\u0440\u043E\u0432\u0430\u0442\u044C "])), (_l()(), i1.ɵeld(17, 0, null, null, 2, "button", [["class", "btn btn-sm btn-outline-danger strategy-action"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.RemoveStrategy() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 0, "span", [["class", "ion-md-trash"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u0423\u0434\u0430\u043B\u0438\u0442\u044C "]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = "btn btn-sm strategy-action"; var currVal_2 = _ck(_v, 3, 0, (_co.userStrategy.isActive === false), (_co.userStrategy.isActive === true)); _ck(_v, 2, 0, currVal_1, currVal_2); var currVal_3 = _ck(_v, 5, 0, (_co.userStrategy.isActive === false), (_co.userStrategy.isActive === true)); var currVal_4 = "btn btn-sm strategy-action"; _ck(_v, 4, 0, currVal_3, currVal_4); var currVal_5 = _ck(_v, 8, 0, (_co.userStrategy.isActive === false), (_co.userStrategy.isActive === true)); _ck(_v, 7, 0, currVal_5); var currVal_6 = _ck(_v, 10, 0, (_co.userStrategy.isActive === false), (_co.userStrategy.isActive === true)); _ck(_v, 9, 0, currVal_6); var currVal_7 = _co.userStrategy.isActive; var currVal_8 = i1.ɵnov(_v, 13); _ck(_v, 12, 0, currVal_7, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.disabled || _co.IsDisabledChangeStatusButton); _ck(_v, 1, 0, currVal_0); var currVal_9 = (_co.disabled || !_co.userStrategy.strategy.isAvailable); _ck(_v, 14, 0, currVal_9); var currVal_10 = _co.disabled; _ck(_v, 17, 0, currVal_10); }); }
export function View_StrategySettingsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "strategy-settings"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StrategySettingsComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "strategy-settings__actions"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StrategySettingsComponent_12)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, [["userStrategyControlPanel", 2]], null, 0, null, View_StrategySettingsComponent_13))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.userStrategy; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.userStrategy; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_StrategySettingsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-strategy-settings", [], null, null, null, View_StrategySettingsComponent_0, RenderType_StrategySettingsComponent)), i1.ɵdid(1, 114688, null, 0, i10.StrategySettingsComponent, [i11.UserStrategiesService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StrategySettingsComponentNgFactory = i1.ɵccf("app-strategy-settings", i10.StrategySettingsComponent, View_StrategySettingsComponent_Host_0, { userStrategy: "userStrategy", ad: "ad", accountId: "accountId", adId: "adId", clientId: "clientId", displayFunctions: "displayFunctions", disabled: "disabled" }, { OnToggleStrategyActive: "OnToggleStrategyActive", OnCreateStrategy: "OnCreateStrategy", OnUpdateStrategy: "OnUpdateStrategy", OnRemoveStrategy: "OnRemoveStrategy", OnEditStrategy: "OnEditStrategy" }, []);
export { StrategySettingsComponentNgFactory as StrategySettingsComponentNgFactory };
