<!--<div class="main-container">-->
  <!--<div class="content-container">-->
    <!--<div class="content-area">-->

    <!--</div>-->
  <!--</div>-->
<!--</div>-->
<div class="login-wrapper">
  <div class="login">
    <router-outlet></router-outlet>
  </div>
</div>