import {Component, Input, OnInit} from '@angular/core';
import {AdViewModel} from '../../models/view-models/ad-view-model';
import {WallPost} from '../../../api/models/wall-post';
import {WallLink} from '../../../api/models/wall-link';
import {isNullOrUndefined} from 'util';
import {Observable} from 'rxjs/Observable';
import {MatDialog} from '@angular/material';
import {ModerationDetailsComponent} from '../../ad-manager/components/moderation-details/moderation-details.component';
import {timer} from 'rxjs/internal/observable/timer';
import {of} from 'rxjs/internal/observable/of';
import {AdManagerService} from '../../../api/services';
import {ActivatedRoute} from '@angular/router';
import {WallPhoto} from '../../../api/models/wall-photo';

@Component({
  selector: 'app-ad-meta-info',
  templateUrl: './ad-meta-info.component.html',
  styleUrls: ['./ad-meta-info.component.scss']
})
export class AdMetaInfoComponent implements OnInit {

  @Input() public ad: AdViewModel;
  @Input() public showUtms: boolean = true;
  @Input() public showLink: boolean = true;
  @Input() public showMin: boolean = true;
  @Input() public showCopyingMessage: boolean = false;
  @Input() public isImpossibleCopyingMessageVisible: boolean = false;

  public accountId: number;
  public campaignId: number;
  public clientId: number;

  public moderationLoading: boolean = false;

  constructor(
    private dialog: MatDialog,
    private adManagerService: AdManagerService,
    private route: ActivatedRoute
  ) {
  }

  public GetAdUrlInVkCabinet(ad: AdViewModel): string {
    return `https://vk.com/ads?act=office&union_id=${ad.id}`;
  }

  public GetLinkClass(classname: string, post: WallPost, link: WallLink): string {
    if (!post || !link) {
      return '';
    }

    return classname + (this.IsArticle(post, link) ? ` ${classname}_article-mode` : '');
  }

  private IsArticle(post: WallPost, link: WallLink): boolean {
    if (!post || !link || !link.url || link.url.length === 0) {
      return false;
    }

    const containOnlyLink = post && post.attachments.filter(x => isNullOrUndefined(x.link)).length === 0;

    return link.url.indexOf('vk.com/@') >= 0 && containOnlyLink;
  }

  ngOnInit() {
    this.accountId = parseInt(this.route.snapshot.queryParams.account_id, 10);
    this.campaignId = parseInt(this.route.snapshot.queryParams.campaign_id, 10);
    this.clientId = parseInt(this.route.snapshot.queryParams.client_id, 10);
  }

  moderationDetails() {
    this.moderationLoading = true;
    this.adManagerService
      .GetAdRejection({
        accountId: parseInt(this.route.snapshot.queryParams.account_id, 10),
        adId: this.ad.id
      })
      .subscribe((rejectionRules) => {
        this.dialog.open(ModerationDetailsComponent, {
          data: rejectionRules,
          width: '640px'
        });
        this.moderationLoading = false;
      });
  }

  public GetPhotoHighestResolutionImage(photo: WallPhoto): string {
    if (photo && photo.sizes) {
      let biggestWidth = 0;
      let url = null;

      photo.sizes.forEach(size => {
        if (size.width > biggestWidth) {
          biggestWidth = size.width;
          url = size.url;
        }
      });

      return url;
    }

    return null;
  }
}
