import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-generic-tooltip',
  templateUrl: './generic-tooltip.component.html',
  styleUrls: ['./generic-tooltip.component.scss']
})
export class GenericTooltipComponent implements OnInit {
  @Input() public clickable: boolean = false;
  @Input() public clickTitle: string = 'Нажмите, чтобы посмотреть подсказку';

  @Input() public header: string = 'Как это работает?';
  @Input() public text: string = null;

  @Input() public icon: string = 'ion-md-bulb';
  @Input() public noMargin: boolean = false;
  constructor() { }

  ngOnInit() {
  }
}
