import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AccountsService} from '../../../api/services/accounts.service';
import {isNullOrUndefined} from 'util';

@Component({
  selector: 'app-secured-oauth-bind-email',
  templateUrl: './secured-oauth-bind-email.component.html',
  styleUrls: ['./secured-oauth-bind-email.component.scss']
})
export class SecuredOauthBindEmailComponent implements OnInit {

  public code = '';

  constructor(private route: ActivatedRoute,
              private api: AccountsService,
              private router: Router) {
  }

  ngOnInit() {
    this.api.BindExternalToken(this.route.snapshot.queryParams.code)
      .subscribe(x => {
        const firstbind = this.route.snapshot.queryParams.firstbind;
        const provider = this.route.snapshot.queryParams.provider;
        let redirect = this.route.snapshot.queryParams.redirect;

        const a = window.localStorage.getItem('yandex_metrika_url');
        if (a) {
          window.localStorage.removeItem('yandex_metrika_url');
          redirect = a;
        }

        // Check for RETURN_TO parameter
        const returnToKey = 'bind-return-to';
        const returnTo = localStorage.getItem(returnToKey);

        console.warn(returnTo, 'RETURN TO SOME');
        if (returnTo) {
          console.warn(returnTo, 'RETURN TO SOME TRIGGERED');
          window.localStorage.removeItem(returnToKey); // Remove that key, letting next redirect flow different
          this.router.navigate([returnTo]);
          return;
        }

        if (isNullOrUndefined((redirect))) {
          redirect = 'https://ng.postmonitor.ru/automation/accounts';
        }

        if (!isNullOrUndefined(firstbind)) {
          // build url proxy with parameters
          this.router.navigate(['sign-up-completed'], {
            queryParams:
              {
                source: provider,
                type: 'first-bind',
                redirect: redirect
              }
          });
        } else {
          // Or redirect directly without reaching the goal if this is not first bind
          window.location.assign(redirect);
        }
      });
  }
}
