import { Injectable } from '@angular/core';
import {TariffGroup, TariffPlan} from './tariff-plan.model';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

export interface TariffPlansState extends EntityState<TariffGroup> {
  ui: {
    selectedTariffPlans: {
      tariffGroup1: number,
      tariffGroup2: number,
      tariffGroup3: number,
    }
  }
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'tariff-plans' })
export class TariffPlansStore extends EntityStore<TariffPlansState> {

  constructor() {
    super({
      ui: {
        selectedTariffPlans: {
          tariffGroup1: 0,
          tariffGroup2: 0,
          tariffGroup3: 0,
        }
      }
    });
  }

  SelectTariffPlan(tariffGroupId, tariffPlanId) {
    const newTariffPlan = {};
    newTariffPlan[`tariffGroup${tariffGroupId}`] = tariffPlanId;
    this.update(state => ({
      ...state,
      ui: {
        ...state.ui,
        selectedTariffPlans: {
          ...state.ui.selectedTariffPlans,
          ...newTariffPlan
        }
      }
    }))
  }

}

