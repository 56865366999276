/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./alert-with-memory.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./alert-with-memory.component";
var styles_AlertWithMemoryComponent = [i0.styles];
var RenderType_AlertWithMemoryComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AlertWithMemoryComponent, data: {} });
export { RenderType_AlertWithMemoryComponent as RenderType_AlertWithMemoryComponent };
function View_AlertWithMemoryComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "memory-alert-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 9, "div", [["class", "alert alert-success"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 8, "div", [["class", "alert-items"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 7, "div", [["class", "memory-alert-upper-strip"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "memory-alert-upper-strip__item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "memory-content"]], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(8, 0, null, null, 3, "div", [["class", "memory-alert-upper-strip__item memory-alert-upper-strip__item_last"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "memory-alert-close-button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "button", [["class", "btn btn-sm btn-success-outline"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.CloseAlert() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u0417\u0430\u043A\u0440\u044B\u0442\u044C "]))], null, null); }
export function View_AlertWithMemoryComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertWithMemoryComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.visibility; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AlertWithMemoryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-memory-alert", [], null, null, null, View_AlertWithMemoryComponent_0, RenderType_AlertWithMemoryComponent)), i1.ɵdid(1, 114688, null, 0, i3.AlertWithMemoryComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AlertWithMemoryComponentNgFactory = i1.ɵccf("app-memory-alert", i3.AlertWithMemoryComponent, View_AlertWithMemoryComponent_Host_0, { id: "id" }, {}, ["*"]);
export { AlertWithMemoryComponentNgFactory as AlertWithMemoryComponentNgFactory };
