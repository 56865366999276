import { OnInit } from '@angular/core';
import { NotificationsService } from '../../services/notifications.service';
var NotificationListComponent = /** @class */ (function () {
    function NotificationListComponent(notificationsService) {
        this.notificationsService = notificationsService;
    }
    NotificationListComponent.prototype.ngOnInit = function () {
    };
    Object.defineProperty(NotificationListComponent.prototype, "notifications", {
        get: function () {
            return Array.from(this.notificationsService.notifications)
                .map(function (x) { return x[1]; });
        },
        enumerable: true,
        configurable: true
    });
    return NotificationListComponent;
}());
export { NotificationListComponent };
