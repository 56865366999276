import {Injectable} from '@angular/core';
import {LeadsService} from "../../../api/services";
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class AdminUsersService {

  constructor(
    private leadsService: LeadsService
  ) {
  }

  public LoadUsers() {
  }

  public LoadUser() {
  }

  public CreateCall(callData) {
    console.log('CREATE CALL');
    return this.leadsService.CreateCall(callData)
  }

  public LoadCallsByUserId(userId) {
    return this.leadsService.GetUserCalls(userId)
  }
}
