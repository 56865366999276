<ng-container *ngIf="message">
  <div [ngClass]='{
    "chat-message": true,
    "chat-message_my-message": message.isMyMessage,
    "chat-message_not-my-message": !message.isMyMessage,
    "chat-message_unread": !message.isRead && !message.createdByUser
  }'>
    <ng-container *ngIf="showReadStatus">
      <div [ngClass]='{
      "chat-mesage-read-indicator": true,
      "chat-mesage-read-indicator_my-message": message.isMyMessage,
      "chat-mesage-read-indicator_not-my-message": !message.isMyMessage,
      "chat-mesage-read-indicator_read": message.isRead
    }' [title]="GetMessageReadTip()">
        <ng-container>
          <i [ngClass]='{
            "icon": true,
            "ion-md-time": !message.isRead,
            "ion-md-checkmark": message.isRead
          }'></i>
        </ng-container>
      </div>
    </ng-container>

    <div class="chat-message__meta">
      <div class="chat-message-meta">
        <div class="chat-message-meta__sender">
          {{message.author}}
        </div>
        <div class="chat-message-meta__time">
          {{message.createdAt | dateFormating}}
        </div>
      </div>
    </div>
    <div class="chat-message__text" [innerHtml]="message.text | postificate">
    </div>
  </div>
</ng-container>
