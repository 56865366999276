import { OnInit } from '@angular/core';
import { PaymentsService } from "../../services/payments.service";
var PaymentsTableComponent = /** @class */ (function () {
    function PaymentsTableComponent(paymentsService) {
        this.paymentsService = paymentsService;
    }
    Object.defineProperty(PaymentsTableComponent.prototype, "tariffPlans", {
        get: function () {
            return this.paymentsService.tariffPlans$;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaymentsTableComponent.prototype, "features", {
        get: function () {
            return this.paymentsService.features$;
        },
        enumerable: true,
        configurable: true
    });
    PaymentsTableComponent.prototype.ngOnInit = function () {
        this.paymentsService.LoadData();
    };
    return PaymentsTableComponent;
}());
export { PaymentsTableComponent };
