import { Injectable } from '@angular/core';
import { CustomFieldValue } from './custom-field-value.model';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

export interface CustomFieldValueState extends EntityState<CustomFieldValue> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'custom-field-value', idKey: 'Id' })
export class CustomFieldValueStore extends EntityStore<CustomFieldValueState> {

  constructor() {
    super();
  }

}

