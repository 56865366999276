<ng-container *ngIf="(destinationPlatform$ | async) as platform">
<!--  CDK {{(forms).length}} {{visibleForms | json}}-->
  <!--  <cdk-virtual-scroll-viewport itemSize="50" style="height: 80vh">-->
  <!--    <div *cdkVirtualFor="let form of forms$ | async">-->
  <!--      {{form.name}}-->
  <!--    </div>-->
  <!--  </cdk-virtual-scroll-viewport>-->
<!--  <mat-accordion>-->
<!--    <ng-container *ngFor="let form of visibleForms">-->
<!--      <mat-expansion-panel>-->
<!--        <mat-expansion-panel-header>-->
<!--          {{form.name}}-->
<!--        </mat-expansion-panel-header>-->
<!--        <ng-container *ngFor="let section of sections$ | async">-->
<!--          <ng-container *ngIf="GetFormControl(form.id, section.code) as formGroup">-->
<!--            <app-import-crm-section-settings-->
<!--              [section]="section"-->
<!--              [form]="formGroup"-->
<!--              [adNetworkForm]="form"-->
<!--              [showAdditionalFields]="showAdditionalQuery$ | async"-->
<!--            ></app-import-crm-section-settings>-->
<!--          </ng-container>-->
<!--        </ng-container>-->
<!--        <div [hidden]="!(IsLeadSelected(form.id) | async)">-->
<!--          <ng-container>-->
<!--            <strong>Дополнительные настройки</strong>-->
<!--            <div *ngIf="GetFormGroup(form.id) as formGroup" [formGroup]="formGroup">-->
<!--              <div *ngIf="ShowAdditionalSettings()">-->
<!--                <mat-checkbox formControlName="includeCampaignName" class="no-margin-checkbox">Передавать название-->
<!--                  кампании-->
<!--                </mat-checkbox>-->
<!--              </div>-->
<!--              <div *ngIf="ShowAdditionalSettings()">-->
<!--                <mat-checkbox formControlName="includeLeadFormName" class="no-margin-checkbox">Передавать название-->
<!--                  лид-формы-->
<!--                </mat-checkbox>-->
<!--              </div>-->
<!--              <div>-->
<!--                <mat-checkbox formControlName="bindContact" class="no-margin-checkbox">Привязывать контакт к лиду-->
<!--                </mat-checkbox>-->
<!--              </div>-->
<!--            </div>-->
<!--          </ng-container>-->
<!--        </div>-->
<!--      </mat-expansion-panel>-->
<!--    </ng-container>-->
<!--  </mat-accordion>-->

<!--  <mat-paginator-->
<!--    (page)="ChangePage($event)"-->
<!--    [length]="forms.length"-->
<!--    [pageIndex]="currentPage"-->
<!--    [pageSize]="10"-->
<!--  ></mat-paginator>-->
<!--  {{countOfPages}} {{currentPage}}-->
<!--  <app-pagination-->
<!--    (OnPageChange)="ChangePage($event)"-->
<!--    [countOfPages]="countOfPages"-->
<!--    [currentPage]="currentPage"-->
<!--  >-->

<!--  </app-pagination>-->
    <mat-vertical-stepper>
      <ng-container *ngFor="let form of forms$ | async">
        <mat-step>
          <div *matStepLabel>{{form.name}}</div>
          <ng-container *ngFor="let section of sections$ | async">
            <ng-container *ngIf="GetFormControl(form.id, section.code) as formGroup">
              <app-import-crm-section-settings
                [section]="section"
                [form]="formGroup"
                [adNetworkForm]="form"
                [showAdditionalFields]="showAdditionalQuery$ | async"
              ></app-import-crm-section-settings>
            </ng-container>
          </ng-container>
          <div [hidden]="!(IsLeadSelected(form.id) | async)">
            <ng-container>
              <strong>Дополнительные настройки</strong>
              <div *ngIf="GetFormGroup(form.id) as formGroup" [formGroup]="formGroup">
                <div *ngIf="ShowAdditionalSettings()">
                  <mat-checkbox formControlName="includeCampaignName" class="no-margin-checkbox">Передавать название кампании</mat-checkbox>
                </div>
                <div *ngIf="ShowAdditionalSettings()">
                  <mat-checkbox formControlName="includeLeadFormName" class="no-margin-checkbox">Передавать название лид-формы</mat-checkbox>
                </div>
                <div>
                  <mat-checkbox formControlName="bindContact" class="no-margin-checkbox">Привязывать контакт к лиду</mat-checkbox>
                </div>
              </div>
            </ng-container>
          </div>
        </mat-step>
      </ng-container>
    </mat-vertical-stepper>
</ng-container>
