import * as Raven from 'raven-js';
import * as Sentry from '@sentry/browser';

import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, ErrorHandler, forwardRef, isDevMode, LOCALE_ID, NgModule, Provider} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';

import {AppComponent} from './app.component';
import {ClarityModule} from '@clr/angular';
import {IntegrationsModule} from './integrations/integrations.module';
import {SentryInterceptor} from './interceptors/sentry.interceptor';
import {VkModule} from './vk/vk.module';
import {AdminModule} from './admin/admin.module';

import {registerLocaleData} from '@angular/common';
import localeRu from '@angular/common/locales/ru';
import {ApiModule} from './api/api.module';
import {ApiConfiguration} from './api/api-configuration';
import {ApiInterceptor} from './api-interceptor';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {AuthorizationModule} from './authorization/authorization.module';
import {AuthGuardGuard} from './shared/guards/auth-guard.guard';
import {PersonalAreaModule} from './personal-area/personal-area.module';
import {environment} from '../environments/environment';
import {AutomationModule} from './automation/automation.module';
import {OauthModule} from './oauth/oauth.module';
import {PrivacyComponent} from './routes/privacy/privacy.component';
import {TermsComponent} from './routes/terms/terms.component';
import {ContactsComponent} from './routes/contacts/contacts.component';
import {ContactsLinkComponent} from './components/contacts-link/contacts-link.component';
import {PartnersPageComponent} from './routes/partners-page/partners-page.component';
import {PartnerViewComponent} from './components/partner-view/partner-view.component';
import {TestComponent} from './routes/test/test.component';
import {NgrUiModule} from './ngr-ui/ngr-ui.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatIconModule, MatInputModule, MatSelectModule} from '@angular/material';
import {AdManagerMiddlewareComponent} from './routes/ad-manager-middleware/ad-manager-middleware.component';
import {ChatModule} from './chat/chat.module';
import {SharedModule} from './shared/shared.module';
import {NewIntegrationModule} from './new-integration/new-integration.module';
import {NewPaymentsModule} from './new-payments/new-payments.module';
import {PaymentsModule} from "../../projects/payments/src/lib/payments.module";
import {AffiliateProgrammModule} from "./affiliate-programm/affiliate-programm.module";
import {SystemBannersModule} from "./system-banners/system-banners.module";
import {ToastrModule} from "ngx-toastr";
import {NewPaymentsUiModule} from "../../projects/new-payments/src/lib/new-payments-ui.module";
import {AffiliateProgramSharedModule} from "./affiliate-programm/affiliate-program-shared/affiliate-program-shared.module";


Sentry.init(environment.sentrySettings);

export class RavenErrorHandler implements ErrorHandler {


    public static captureException(scope: Sentry.Scope, err: any) {
        const containedUserId = localStorage.getItem('sentry_user_id');
        if (containedUserId !== null) {
            const UserData = {
                id: containedUserId
            };

            scope.setUser(UserData);
            Sentry.captureException(err);
        }
    }

    handleError(err: any): void {
        if (isDevMode() === false) {
            Sentry.withScope(scope => {
                // RavenErrorHandler.captureException(scope, err);
            });
        }
        throw err;
    }
}

const SentryProvider = {provide: ErrorHandler, useClass: RavenErrorHandler};

registerLocaleData(localeRu, 'ru');

export function initApiConfiguration(config: ApiConfiguration): Function {
    return () => {
        config.rootUrl = environment.backendUri;
    };
}

export const API_INTERCEPTOR_PROVIDER: Provider = {
    provide: HTTP_INTERCEPTORS,
    useExisting: forwardRef(() => ApiInterceptor),
    multi: true
};

export const SENTRY_INTERCEPTOR: Provider = {
    provide: HTTP_INTERCEPTORS,
    useExisting: forwardRef(() => SentryInterceptor),
    multi: true
};

export const INIT_API_CONFIGURATION: Provider = {
    provide: APP_INITIALIZER,
    useFactory: initApiConfiguration,
    deps: [ApiConfiguration],
    multi: true
};

@NgModule({
    declarations: [
        AppComponent,
        PrivacyComponent,
        TermsComponent,
        ContactsComponent,
        ContactsLinkComponent,
        PartnersPageComponent,
        PartnerViewComponent,
        ContactsLinkComponent,
        TestComponent,
        AdManagerMiddlewareComponent,
    ],
    imports: [
        SharedModule,
        BrowserModule,
        AppRoutingModule,
        ClarityModule,
        VkModule,
        AdminModule,
        ApiModule,
        AuthorizationModule,
        PersonalAreaModule,
        AutomationModule,
        OauthModule,
        NgrUiModule,
        FormsModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatIconModule,
        MatInputModule,
        ChatModule,
        IntegrationsModule,
        NewIntegrationModule,
        NewPaymentsModule,
        PaymentsModule,
        AffiliateProgrammModule,
        SystemBannersModule,
        NewPaymentsUiModule,
        AffiliateProgramSharedModule
    ],
    providers: [
//    SentryInterceptor,
        ApiInterceptor,
//    SENTRY_INTERCEPTOR,
        API_INTERCEPTOR_PROVIDER,
        INIT_API_CONFIGURATION,
        AuthGuardGuard,
        {provide: LOCALE_ID, useValue: 'ru'},
        SentryProvider,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
