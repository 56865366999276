/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PostMonitorBackend_V2AffiliateDatabaseAffiliateUserBalance } from '../models/post-monitor-backend-_v2affiliate-database-affiliate-user-balance';
@Injectable({
  providedIn: 'root',
})
class UserBalanceService extends __BaseService {
  static readonly GetUserBalancePath = '/apiv2/user-balance';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return Success
   */
  GetUserBalanceResponse(): __Observable<__StrictHttpResponse<PostMonitorBackend_V2AffiliateDatabaseAffiliateUserBalance>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/user-balance`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PostMonitorBackend_V2AffiliateDatabaseAffiliateUserBalance>;
      })
    );
  }
  /**
   * @return Success
   */
  GetUserBalance(): __Observable<PostMonitorBackend_V2AffiliateDatabaseAffiliateUserBalance> {
    return this.GetUserBalanceResponse().pipe(
      __map(_r => _r.body as PostMonitorBackend_V2AffiliateDatabaseAffiliateUserBalance)
    );
  }
}

module UserBalanceService {
}

export { UserBalanceService }
