/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./vk-form-group.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./vk-form-group.component";
var styles_VkFormGroupComponent = [i0.styles];
var RenderType_VkFormGroupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_VkFormGroupComponent, data: {} });
export { RenderType_VkFormGroupComponent as RenderType_VkFormGroupComponent };
export function View_VkFormGroupComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "vk-form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "vk-form-group__label"]], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "vk-form-group__content"]], null, null, null, null, null)), i1.ɵdid(4, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵncd(null, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "vk-form-group__content"; var currVal_1 = ("vk-form-group__content_orientation_" + _co.orientation); _ck(_v, 4, 0, currVal_0, currVal_1); }, null); }
export function View_VkFormGroupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "vk-form-group", [], null, null, null, View_VkFormGroupComponent_0, RenderType_VkFormGroupComponent)), i1.ɵdid(1, 114688, null, 0, i3.VkFormGroupComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var VkFormGroupComponentNgFactory = i1.ɵccf("vk-form-group", i3.VkFormGroupComponent, View_VkFormGroupComponent_Host_0, { orientation: "orientation" }, {}, ["vkFormGroupLabel,[vkFormGroupLabel]", "*"]);
export { VkFormGroupComponentNgFactory as VkFormGroupComponentNgFactory };
