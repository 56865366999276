import {Component, OnInit} from '@angular/core';
import {environment} from '../../../../../environments/environment';
import {ActivatedRoute} from '@angular/router';
import {OAuthService} from '../../../../api/services/oauth.service';
import {PlatformAssignationsService} from '../../../stores/platform-assignations/platform-assignations.service';
import {AssignationPlatforms} from '../../../stores/platform-assignations/platform-assignation.model';

@Component({
  selector: 'app-mt-assignation-screen',
  templateUrl: './mt-assignation-screen.component.html',
  styleUrls: ['./mt-assignation-screen.component.scss']
})
export class MtAssignationScreenComponent implements OnInit {

  public baseUrl = environment.frontEndUrl;
  public provider = 'my-target';
  public assigned = false;
  public loading: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private oauth: OAuthService,
    private assignationService: PlatformAssignationsService
  ) {
  }

  ngOnInit() {
    this.CheckAssignation();
  }

  public AddVkAdCabinetLinkClick(): void {
    const route = this.route.snapshot['_routerState'].url;
    localStorage.setItem('bind-return-to', route);

    window.open(this.GetBindUrl(), '_blank');
  }

  public GetBindUrl(): string {
    return `${this.baseUrl}/${environment.apiPrefix}/OAuth/authorize/${this.provider}`;
  }

  public CheckAssignation() {
    this.loading = true;
    this.oauth.CheckProviderToken(this.provider)
      .subscribe(data => {
          this.assigned = data;
          if (data) {
            this.assignationService.assignPlatform(AssignationPlatforms.MY_TAREGT);
          }
          this.loading = false;
        },
        err => {
          this.loading = false;
        }
      );
  }

}
