import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AutomationVkService} from '../../../../../api/services/automation-vk.service';
import {AutomationVkSlotService} from '../../../../../api/services/automation-vk-slot.service';
import {DexieCabinetsService} from '../../../../../shared/services/dexie/dexie-cabinets.service';
import {AutomationExpirationManagerService} from '../../../../services/automation-expiration-manager.service';
import {DexieClientsService} from '../../../../../shared/services/dexie/dexie-clients.service';
import {PickListNgrComponent} from '../../../../../ngr-ui/components/pick-list/pick-list-ngr.component';

@Component({
  selector: 'app-activate-cabinets',
  templateUrl: './activate-cabinets.component.html',
  styleUrls: ['./activate-cabinets.component.scss']
})
export class ActivateCabinetsComponent implements OnInit {
  @Input() slots: any;
  @Input() countOfMaxSlots: any;
  @Input() accounts: any;

  @Output() bindingChanged: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('pickList') public pickList: PickListNgrComponent;

  constructor(
    private automationVkService: AutomationVkService,
    private automationVkSlotsService: AutomationVkSlotService,
    private _api: AutomationVkService,
    private localDb: DexieCabinetsService,
    private _db: AutomationVkSlotService,
    public accessManager: AutomationExpirationManagerService,
    private localClientsDb: DexieClientsService
  ) {
  }

  ngOnInit() {
  }

  OnSaveCabinetsBined(data: any) {
    if (!data.isSelected) {
      this._db.AssignCabinetToSlot({
        id: data.account_id,
        clientId: data.client_id
      })
        .subscribe(data1 => {
          this.bindingChanged.emit();
        }, (err) => {
          this.pickList.SetError(err.error);
        });
    } else {
      this._db.UnBindCabinetFromSlot({
        id: data.account_id,
        clientId: data.client_id
      })
        .subscribe(data1 => {
          this.bindingChanged.emit();
        }, (err) => {
          this.pickList.SetError(err.error);
        });
    }
    // this.LoadSlots();
  }

  public LoadSlots() {
  }

  public LoadAccounts() {
  }

  public Refresh() {
    this.bindingChanged.emit();
  }
}
