import { AdCostTypeModes } from './../../models/ad-cost-type-modes';
import { OnInit } from '@angular/core';
import { AdViewModel } from '../../models/view-models/ad-view-model';
var AdCostComponent = /** @class */ (function () {
    function AdCostComponent() {
    }
    Object.defineProperty(AdCostComponent.prototype, "costTypeName", {
        get: function () {
            var costTypeName = '';
            if (this.costTypeMode === AdCostTypeModes.SHARE_COST_TYPE_MODE) {
                if (this.ad.costTypeMode === AdCostTypeModes.CPC_ONLY_MODE) {
                    costTypeName = 'за 1 переход';
                }
                else {
                    costTypeName = 'за 1000 показов';
                }
            }
            return costTypeName;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdCostComponent.prototype, "value", {
        get: function () {
            if (this.ad.costTypeMode === AdCostTypeModes.CPC_ONLY_MODE) {
                return this.ad.cpc / 100;
            }
            else {
                return this.ad.cpm;
            }
        },
        enumerable: true,
        configurable: true
    });
    AdCostComponent.prototype.ngOnInit = function () {
    };
    return AdCostComponent;
}());
export { AdCostComponent };
