import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../../environments/environment";
import { UserTariffStore } from "../state/user-tariff.store";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../state/user-tariff.store";
var UserTariffService = /** @class */ (function () {
    function UserTariffService(http, store) {
        this.http = http;
        this.store = store;
    }
    /**
     * Загрузка тарифного плана пользователя
     * @constructor
     */
    UserTariffService.prototype.LoadUserTariffPackage = function () {
        var _this = this;
        this.http.get(environment.backendUri + "/" + environment.apiPrefix + "/tariff-packages/user")
            .subscribe(function (data) {
            console.log(data, 'TARIFFS');
            _this.store.update(function (state) { return ({
                tariff: data,
                expiredAt: data.expiredAt
            }); });
        });
    };
    UserTariffService.ngInjectableDef = i0.defineInjectable({ factory: function UserTariffService_Factory() { return new UserTariffService(i0.inject(i1.HttpClient), i0.inject(i2.UserTariffStore)); }, token: UserTariffService, providedIn: "root" });
    return UserTariffService;
}());
export { UserTariffService };
