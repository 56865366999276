import { EventEmitter, OnInit } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { ImagesManagerComponent } from '../../images-manager/images-manager.component';
var CreateTeazerComponent = /** @class */ (function () {
    function CreateTeazerComponent(controlContainer) {
        this.controlContainer = controlContainer;
        this.valid = false;
        this.type = 'linear';
        this.isUpdating = false;
        this.onNext = new EventEmitter();
    }
    Object.defineProperty(CreateTeazerComponent.prototype, "teazers", {
        get: function () {
            var _this = this;
            var teazers = [];
            var images = this.controlContainer.control.get('image').value;
            var titles = this.controlContainer.control.get('title').value;
            if (this.type === 'linear') {
                var minTeazers = Math.min(images.length, titles.length);
                for (var i = 0; i < minTeazers; i++) {
                    teazers.push({
                        teazer: this.controlContainer.control.value,
                        image: images[i],
                        title: titles[i],
                    });
                }
            }
            else if (this.type === 'quadratic') {
                images.forEach(function (image) {
                    titles.forEach(function (title) {
                        teazers.push({
                            teazer: _this.controlContainer.control.value,
                            image: image,
                            title: title,
                        });
                    });
                });
            }
            return teazers;
        },
        enumerable: true,
        configurable: true
    });
    CreateTeazerComponent.prototype.ngOnInit = function () {
        console.log(this.controlContainer.control.get('title'));
    };
    CreateTeazerComponent.prototype.OnTeazerChange = function (data) {
        // if (data.image === '') {
        //   data.image = this.teazer.image;
        // }
        // if (data.link === '') {
        //   data.link = this.teazer.link;
        // }
        // this.teazer = Object.assign(this.teazer, data);
    };
    CreateTeazerComponent.prototype.clickNext = function () {
        this.onNext.emit();
    };
    CreateTeazerComponent.prototype.OnValidStatusChange = function ($event) {
        this.valid = $event;
    };
    CreateTeazerComponent.prototype.OnSelectImage = function (image) {
        console.log(image);
        this.controlContainer.control.get('image').patchValue(image);
        this.controlContainer.control.get('image').markAsDirty();
    };
    CreateTeazerComponent.prototype.RemoveImageFromSelect = function ($event) {
        this.ImagesManager.OnSelectImage($event);
    };
    return CreateTeazerComponent;
}());
export { CreateTeazerComponent };
