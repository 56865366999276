import { EventEmitter, OnInit } from '@angular/core';
import { createValidator } from '../../../ngr-ui/utils/validator-creator';
import { DynamicFormComponent } from '../../../ngr-ui/components/dynamic-form/dynamic-form.component';
import { isNullOrUndefined } from 'util';
var StrategiesManagerComponent = /** @class */ (function () {
    function StrategiesManagerComponent() {
        var _this = this;
        this.OnSave = new EventEmitter();
        this.Inited = new EventEmitter();
        this.selectedStrategy = '';
        this.inputs = {};
        this.displayForm = false;
        this.strategySelected = false;
        this.modifyFunctions = {
            BID_MIN: function (value) { return parseFloat(value) * 100; },
            BID_START: function (value) { return parseFloat(value) * 100; },
            BID_END: function (value) { return parseFloat(value) * 100; },
            BID_MAX: function (value) { return parseFloat(value) * 100; },
            BID_STEP: function (value) { return parseFloat(value) * 100; },
            ECPC_LIMIT: function (value) { return parseFloat(value) * 100; },
            CUSTOM_OVERALL_LIMIT: function (value) { return parseFloat(value) * 100; },
        };
        this.displayFunctions = {
            BID_MIN: function (value) { return parseFloat(value) / 100; },
            BID_MAX: function (value) { return parseFloat(value) / 100; },
            BID_START: function (value) { return parseFloat(value) / 100; },
            BID_END: function (value) { return parseFloat(value) / 100; },
            BID_STEP: function (value) { return parseFloat(value) / 100; },
            ECPC_LIMIT: function (value) { return parseFloat(value) / 100; },
            CUSTOM_OVERALL_LIMIT: function (value) { return parseFloat(value) / 100; },
        };
        this.customValidators = {
            BID_START: function (bidRange) { return [
                createValidator({
                    code: 'REQUIRED',
                    invalidMessage: 'Поле должно быть заполнено'
                }, null),
                createValidator({
                    code: 'MIN',
                    invalidMessage: "\u0417\u043D\u0430\u0447\u0435\u043D\u0438\u0435 \u043F\u043E\u043B\u044F \u0434\u043E\u043B\u0436\u043D\u043E \u0431\u044B\u0442\u044C \u0431\u043E\u043B\u044C\u0448\u0435 \u0447\u0435\u043C " + _this.displayFunctions.BID_START(bidRange.cpmMin) + "!"
                }, _this.displayFunctions.BID_START(bidRange.cpmMin)),
                createValidator({
                    code: 'MAX',
                    invalidMessage: "\u0417\u043D\u0430\u0447\u0435\u043D\u0438\u0435 \u043F\u043E\u043B\u044F \u0434\u043E\u043B\u0436\u043D\u043E \u0431\u044B\u0442\u044C \u043C\u0435\u043D\u044C\u0448\u0435 \u0447\u0435\u043C " + _this.displayFunctions.BID_START(bidRange.cpmMax) + "!"
                }, _this.displayFunctions.BID_START(bidRange.cpmMax))
            ]; }
        };
    }
    Object.defineProperty(StrategiesManagerComponent.prototype, "SelectedStrategyTitle", {
        get: function () {
            var _this = this;
            return this.strategies
                .find(function (strategy) { return strategy.code === _this.selectedStrategy; }).title;
        },
        enumerable: true,
        configurable: true
    });
    StrategiesManagerComponent.prototype.ngOnInit = function () {
        var _this = this;
        console.log('this.strategies', this.strategies);
        this.inputs = this.strategies
            .map(function (strategy) { return ({ key: strategy.code, value: _this.GetInputs(strategy) }); })
            .reduce(function (acc, _a) {
            var key = _a.key, value = _a.value;
            acc[key] = value;
            return acc;
        }, {});
        console.log(this.currentStrategy, 'CURRENT STRATEGY');
        if (this.currentStrategy) {
            this.selectedStrategy = this.currentStrategy.strategy.code;
            this.AcceptStrategy(this.currentStrategy.strategy.code);
        }
        else {
            if (this.strategies && this.strategies.length > 0) {
                this.selectedStrategy = this.strategies[0].code;
            }
        }
    };
    StrategiesManagerComponent.prototype.AcceptStrategy = function (selectedStrategy) {
        var _this = this;
        this.inputs = {};
        console.log(this.selectedStrategy, selectedStrategy, 'SELECTED STRAETGY CODE');
        this.displayForm = false;
        var strategy = this.strategies.find(function (x) { return x.code === _this.selectedStrategy; });
        this.strategySelected = false;
        strategy.inputs.forEach(function (y) {
            var currentInput = null;
            if (_this.currentStrategy) {
                currentInput = _this.currentStrategy.inputs.find(function (x) { return x.type.code === y.code; });
            }
            var validators = [];
            if (_this.customValidators[y.code]) {
                validators = _this.customValidators[y.code](_this.bidRange);
            }
            else {
                validators = y.validators
                    .map(function (validator) { return createValidator(validator, validator.value); });
            }
            console.log(y.defaultValue, 'DEFAULT VALUE');
            _this.inputs[y.code] = {
                name: y.code,
                type: y['frontendInputType'],
                label: y.title,
                options: y.options,
                placeholder: y.placeholderContent,
                description: y.description,
                required: y.required,
                Validators: validators,
            };
            if (currentInput) {
                console.log(_this.inputs, 'INPUTS');
                if (isNullOrUndefined(_this.inputs)) {
                    _this.inputs = {};
                }
                console.log(_this.inputs, 'INPUTS');
                _this.inputs[y.code].value = (_this.displayFunctions[y.code]) ? _this.displayFunctions[y.code](currentInput.value) : currentInput.value;
            }
        });
        setTimeout(function () {
            _this.displayForm = true;
            _this.strategySelected = true;
        }, 500);
    };
    StrategiesManagerComponent.prototype.Save = function (data) {
        var _this = this;
        console.log(data);
        data = Object.keys(data)
            .map(function (key) { return ({ key: key, value: data[key] }); })
            .reduce(function (acc, _a) {
            var key = _a.key, value = _a.value;
            acc[key] = _this.modifyFunctions[key] ? _this.modifyFunctions[key](value) : value;
            return acc;
        }, {});
        this.OnSave.emit({
            type: this.selectedStrategy,
            data: data
        });
    };
    StrategiesManagerComponent.prototype.OnInit = function (data) {
        console.log('inited');
        this.Inited.emit(data);
    };
    StrategiesManagerComponent.prototype.GetInputs = function (strategy) {
        var _this = this;
        var inputs = {};
        strategy.inputs.forEach(function (y) {
            var currentInput = null;
            console.log(_this.currentStrategy, 'CURRENT_STRATEGY');
            if (_this.currentStrategy) {
                currentInput = _this.currentStrategy.inputs.find(function (x) { return x.type.code === y.code; });
            }
            var validators = [];
            if (_this.customValidators[y.code]) {
                validators = _this.customValidators[y.code](_this.bidRange);
            }
            else {
                validators = y.validators
                    .map(function (validator) { return createValidator(validator, validator.value); });
            }
            inputs[y.code] = {
                name: y.code,
                type: y['frontendInputType'],
                label: y.title,
                options: y.options,
                placeholder: y.placeholderContent,
                description: y.description,
                required: y.required,
                Validators: validators,
            };
            if (currentInput) {
                console.log(_this.inputs, 'INPUTS');
                if (isNullOrUndefined(_this.inputs)) {
                    _this.inputs = {};
                }
                console.log(_this.inputs, 'INPUTS');
                inputs[y.code] = {};
                inputs[y.code].value = (_this.displayFunctions[y.code]) ? _this.displayFunctions[y.code](currentInput.value) : currentInput.value;
            }
        });
        return inputs;
    };
    return StrategiesManagerComponent;
}());
export { StrategiesManagerComponent };
