Выбрано групп: {{CountOfSelected}} из 100
<div class="groups-table-wrapper">
  <table mat-table [dataSource]="dataSource" matSort class="groups-table">

    <ng-container matColumnDef="checkbox">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          (change)="ToggleAll($event)"
          [checked]="IsAllToggled()"
        ></mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let group">
        <mat-checkbox
          [value]="group"
          [checked]="isGroupSelected(group) "
          (change)="toggleSelectedRow(group)"
          [disabled]="IsRowDisabled(group)"
        ></mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="avatar">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let group">
        <img [src]="group.photo_50" [alt]="group.name" class="group__avatar">
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Название группы</th>
      <td mat-cell *matCellDef="let group">{{group.name}}</td>
    </ng-container>

    <ng-container matColumnDef="members_count">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Подписчики</th>
      <td mat-cell *matCellDef="let group">{{group.members_count | number}}</td>
    </ng-container>


    <ng-container matColumnDef="functions">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Опции</th>
      <td mat-cell *matCellDef="let group">
        <mat-icon>
          remove
        </mat-icon>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayColumns"></tr>
  </table>

  <mat-paginator [pageSize]="10" showFirstLastButtons></mat-paginator>
</div>
