<div class="ad-image">
  <div class="ad-image__hidden">
    <input
      type="checkbox"
      name="ad_image"
      [id]="image.name"
      [value]="image.content"
      [checked]="IsSelected(image.content)"
      (change)="OnChange(image.content)"
      [disabled]="!isValid()"
    >
  </div>
  <label [for]="image.name" class="ad-image__content-wrapper">
    <span class="ad-image__radio" [ngClass]="{
      'ad-image__radio_selected': IsSelected(image.content),
      'ad-image__radio_disabled': !isValid()
    }" title="Выбрать изображение">
      <span class="ion-md-checkmark"></span>
    </span>
    <span [ngClass]='{
      "ad-image__remove": true,
      "ad-image__remove_broken": !isValid()
    }' (click)="RemoveImage()" [matTooltip]="Errors()" title="Удалить изображение">
      <mat-icon>close</mat-icon>
    </span>
    <img [ngClass]='{
      "ad-image__content": true,
      "ad-image__content_broken": !isValid()
    }' [src]="image.content" #imageContent>
    <!--<span *ngIf="!isValid()" class="ad-image__warning">-->
      <!--<mat-icon [matTooltip]="Errors()">warning</mat-icon>-->
    <!--</span>-->
  </label>
</div>

