<div class="dashboard-columns">
  <ng-container *ngFor="let items of [automationMenuList, analyticsMenuList]">
    <div class="dashboard-column">
      <ng-container *ngFor="let column of items">
        <div class="dashboard-columns__item" [ngClass]="{
          'dashboard-columns__item_margin-right_false': column.marginRight === false,
          'dashboard-columns__item_margin-bottom_false': column.marginBottom === false
        }">
          <div class="dashboard-column-header">
            {{column.header}}
          </div>

          <ng-container *ngIf="column.items">
            <div class="dashboard-columns-list">
              <ng-container *ngFor="let item of column.items">
                <div class="dashboard-columns-list__item">
                  <a class="dashboard-column-item" [style.background-color]="item.backgroundColor"
                     [routerLink]="item.route">
                    <div class="dashboard-column-item__icon">
                      <i [ngClass]="'icon ' + item.icon"></i>
                    </div>

                    <div class="dashboard-column-item__data-container">
                      <div class="dashboard-column-item-header">
                        {{item.header}}
                      </div>

                      <ng-container *ngIf="item.description">
                        <div class="dashboard-column-item-description" [innerHTML]="item.description">
                        </div>
                      </ng-container>
                    </div>
                  </a>
                </div>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>


<div class="dash-menu-strip" style="display: none">
  <ng-container *ngFor="let item of items">
    <ng-container *ngIf="item.visible">
      <div [ngClass]="'dash-menu-strip__item dash-menu-strip__item_' + item.width">
        <a
          [ngClass]="'dash-menu-item-container dash-menu-item-container_' + item.color + ' dash-menu-item-container_' + item.align"
          [routerLink]="item.route">
          <div class="dash-menu-item-strip">
            <div class="dash-menu-item-strip__item">
              <div class="dash-menu-item-icon">
                <i [ngClass]="'icon ' + item.icon"></i>
              </div>
            </div>
            <div class="dash-menu-item-strip__item">
              <ng-container *ngIf="item.notification">
                <div class="dash-menu-item-notification-container">
                  <div class="dash-menu-item-notification">
                    {{item.notification}}
                  </div>
                </div>
              </ng-container>
              <div class="dash-menu-item-meta">
                <div class="dash-menu-item-meta__header">
                  {{item.header}}
                </div>
                <ng-container *ngIf="item.description">
                  <div class="dash-menu-item-meta__description">
                    {{item.description}}
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </a>
      </div>
    </ng-container>
  </ng-container>
</div>

<section style="display: none" class="section">
  <div class="section__content">
    <div class="tiles tiles_2x2">
      <a class="menu-card menu-card_automation" routerLink="/automation/accounts">
        <div class="menu-card__icon">
          <clr-icon shape="cog" size="48"></clr-icon>
        </div>
        <h3 class="menu-card__title">
          <span class="new-menu-item-mark new-menu-item-mark_blocky-big">Добавлены новые функции</span><br>
          Автоматизация таргетированной рекламы
        </h3>
        <div class="menu-card__description">Автоматизация работы с рекламными кабинетами ВКонтакте</div>
      </a>
      <a class="menu-card menu-card_vk" routerLink="/vk/group">
        <div class="menu-card__icon">
          <clr-icon shape="users" size="48"></clr-icon>
        </div>
        <h3 class="menu-card__title">Сообщества ВКонтакте</h3>
        <div class="menu-card__description">Поиск сообществ по различным критериям</div>
      </a>
      <a class="menu-card menu-card_vk" routerLink="/vk/post">
        <div class="menu-card__icon">
          <clr-icon shape="talk-bubbles" size="48"></clr-icon>
        </div>
        <h3 class="menu-card__title">Рекламные посты ВКонтакте</h3>
        <div class="menu-card__description">
          Поиск постов которые используются при рекламе в сообществах
        </div>
      </a>
      <a class="menu-card menu-card_vk" routerLink="/vk/hidden">
        <div class="menu-card__icon">
          <clr-icon shape="view-list" size="48"></clr-icon>
        </div>
        <h3 class="menu-card__title">
          Рекламные записи (Промо посты) ВКонтакте
        </h3>
        <div class="menu-card__description">
          Поиск постов, которые используются в таргетированной рекламе
        </div>
      </a>
    </div>
  </div>
</section>

<!--<section class="section">-->
<!--<h2 class="section__title">Instagram</h2>-->
<!--<div class="section__content">-->
<!--<div class="menu-cards">-->
<!--<a class="menu-card menu-card_instagram">-->
<!--<div class="menu-card__icon">-->
<!--<clr-icon shape="view-list" size="48"></clr-icon>-->
<!--</div>-->
<!--<h3 class="menu-card__title">Рекламные посты</h3>-->
<!--<div class="menu-card__description">Поиск по всем рекламным постам</div>-->
<!--<div class="menu-card__count">{{64500125 | number}}-->
<!--<br> постов-->
<!--</div>-->
<!--</a>-->
<!--<a class="menu-card menu-card_instagram">-->
<!--<div class="menu-card__icon">-->
<!--<clr-icon shape="talk-bubbles" size="48"></clr-icon>-->
<!--</div>-->
<!--<h3 class="menu-card__title">Паблики Instagram</h3>-->
<!--<div class="menu-card__description">Поиск пабликов по множеству критериев</div>-->
<!--<div class="menu-card__count">{{250000 | number}}-->
<!--<br> постов-->
<!--</div>-->
<!--</a>-->
<!--<a class="menu-card menu-card_instagram">-->
<!--<div class="menu-card__icon">-->
<!--<clr-icon shape="user" size="48"></clr-icon>-->
<!--</div>-->
<!--<h3 class="menu-card__title">Таргет Instagram</h3>-->
<!--<div class="menu-card__description">Тизеры в instagram, включая <strong>мобильные</strong></div>-->
<!--<div class="menu-card__count">{{2458790 | number}}-->
<!--<br> постов-->
<!--</div>-->
<!--</a>-->
<!--</div>-->
<!--</div>-->
<!--</section>-->

<!--<section class="section">-->
<!--<h2 class="section__title">Наши сервисы</h2>-->
<!--<div class="section__content">-->
<!--<div class="menu-cards">-->
<!--<a class="menu-card menu-card_pm">-->
<!--<div class="menu-card__icon">-->
<!--<clr-icon shape="cog" size="48"></clr-icon>-->
<!--</div>-->
<!--<h3 class="menu-card__title">Автоматизация рекламы</h3>-->
<!--<div class="menu-card__description">Оптимиззируйте затраты на рекламу путем автоматизации</div>-->
<!--<div class="menu-card__count"></div>-->
<!--</a>-->
<!--</div>-->
<!--</div>-->
<!--</section>-->

<!--<section class="section">-->
<!--<h2 class="section__title">Одноклассники</h2>-->
<!--<div class="section__content">-->
<!--<div class="menu-cards">-->
<!--<a class="menu-card menu-card_ok">-->
<!--<div class="menu-card__icon">-->
<!--<clr-icon shape="view-list" size="48"></clr-icon>-->
<!--</div>-->
<!--<h3 class="menu-card__title">Рекламные посты</h3>-->
<!--<div class="menu-card__description">Поиск по всем рекламным постам</div>-->
<!--<div class="menu-card__count">{{64500125 | number}}-->
<!--<br> постов-->
<!--</div>-->
<!--</a>-->
<!--<a class="menu-card menu-card_ok">-->
<!--<div class="menu-card__icon">-->
<!--<clr-icon shape="talk-bubbles" size="48"></clr-icon>-->
<!--</div>-->
<!--<h3 class="menu-card__title">Паблики Одноклассники</h3>-->
<!--<div class="menu-card__description">Поиск пабликов по множеству критериев</div>-->
<!--<div class="menu-card__count">{{250000 | number}}-->
<!--<br> постов-->
<!--</div>-->
<!--</a>-->
<!--<a class="menu-card menu-card_ok">-->
<!--<div class="menu-card__icon">-->
<!--<clr-icon shape="user" size="48"></clr-icon>-->
<!--</div>-->
<!--<h3 class="menu-card__title">Таргет Одноклассники</h3>-->
<!--<div class="menu-card__description">Тизеры в Target@mail (myTarget), включая <strong>мобильные</strong></div>-->
<!--<div class="menu-card__count">{{2458790 | number}}-->
<!--<br> постов-->
<!--</div>-->
<!--</a>-->
<!--</div>-->
<!--</div>-->
<!--</section>-->
