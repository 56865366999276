import { Injectable } from '@angular/core';
import { CustomField } from './custom-field.model';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

export interface CustomFieldsState extends EntityState<CustomField> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'custom-fields', idKey: 'Id' })
export class CustomFieldsStore extends EntityStore<CustomFieldsState> {

  constructor() {
    super();
  }

}

