import { Component, OnInit } from '@angular/core';
import {IDynamicFormInput} from '../../../../ngr-ui/models/DynamicFormInput';

@Component({
  selector: 'app-blog-layout',
  templateUrl: './blog-layout.component.html',
  styleUrls: ['./blog-layout.component.scss']
})
export class BlogLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
