import { OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PlatformsService } from '../../stores/platforms/platforms.service';
import { PlatformsTypes } from '../../platforms-types';
import { PlatformsQuery } from '../../stores/platforms/platforms.query';
import { ActivatedRoute } from '@angular/router';
var IntegrationPlatformSelectorComponent = /** @class */ (function () {
    function IntegrationPlatformSelectorComponent(fb, platformsService, platformsQuery, route) {
        this.fb = fb;
        this.platformsService = platformsService;
        this.platformsQuery = platformsQuery;
        this.route = route;
        this.types = PlatformsTypes;
    }
    Object.defineProperty(IntegrationPlatformSelectorComponent.prototype, "uiPlatforms", {
        get: function () {
            return this.platforms ? this.platforms.filter(function (x) { return x.code && x.code !== 'YANDEX_AUDIENCES'; } /*&&  x.code !== 'BITRIX_EXPORT'*/) : [];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IntegrationPlatformSelectorComponent.prototype, "id", {
        get: function () {
            return this.isOrigin ? 'platform_selector_origin' : 'platform_selector_destination';
        },
        enumerable: true,
        configurable: true
    });
    IntegrationPlatformSelectorComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.form = this.fb.group({});
        var control = this.fb.control(null);
        control.valueChanges.subscribe(function (platformCode) { return _this.platformsService.SelectPlatform(platformCode, _this.isOrigin); });
        this.form.addControl(this.controlName, control);
        if (this.controlName === 'rightService') {
            this.platformsQuery.selectedOriginPlatform.subscribe(function (data) {
                _this.form.get(_this.controlName).setValue(null);
            });
        }
        //
        // this.route.queryParams
        //   .pipe(
        //     distinctUntilChanged()
        //   )
        //   .subscribe(({originPlatform}) => {
        //     if (originPlatform) {
        //       control.setValue(originPlatform);
        //     }
        //   });
    };
    return IntegrationPlatformSelectorComponent;
}());
export { IntegrationPlatformSelectorComponent };
