import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {delay} from 'rxjs/operators';
import {
    AdManagerService,
    AutomationVkService,
    AutomationYandexService, OAuthService,
    StrategiesService,
    UserStrategiesService
} from '../../../api/services';
import {ActivatedRoute, Router} from '@angular/router';
import {AdViewModel} from '../../models/view-models/ad-view-model';
import {DexieCabinetsService} from '../../../shared/services/dexie/dexie-cabinets.service';
import {DexieCampaignsService} from '../../../shared/services/dexie/dexie-campaigns.service';
import {
    BidRange,
    Client,
    Counter,
    Goal,
    GoalReachResponse,
    Strategy,
    UserStrategy,
    UserStrategyInput
} from '../../../api/models';
import {SecondsToTimePipe} from '../../../shared/pipes/seconds-to-time.pipe';
import {VkUtmExtractorService} from '../../../vk/services/vk-utm-extractor.service';
import {AutomationExpirationManagerService} from '../../services/automation-expiration-manager.service';
import {ExcelService} from '../../services/excel-service.service';
import {UrlExctractorService} from '../../../vk/services/url-exctractor.service';
import {YandexMetrikaWrapperService} from '../../services/yandex-metrika-wrapper.service';
import {UtmExtractorService} from '../../../shared/services/utm-extractor.service';
import {isNullOrUndefined} from 'util';
import {WallLink} from '../../../api/models/wall-link';
import {WallPost} from '../../../api/models/wall-post';
import * as moment from 'moment';
import {UtilsService} from '../../../shared/services/utils.service';
import {CurrencyPipe, DecimalPipe} from '@angular/common';
import {MinutesToTimePipe} from '../../../shared/pipes/minutes-to-time.pipe';
import {DgPaginationComponent} from '../../../ngr-ui/components/dg-pagination/dg-pagination.component';
import {NotificationsService} from '../../../shared/services/notifications.service';
import {createValidator} from '../../../ngr-ui/utils/validator-creator';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material';
import {ModerationModalComponent} from '../../components/moderation-modal/moderation-modal.component';
import {MultipleEditSuccessComponent} from '../../ad-manager/components/modals/multiple-edit-success/multiple-edit-success.component';
import {MultipleEditRejectComponent} from '../../ad-manager/components/modals/multiple-edit-reject/multiple-edit-reject.component';
import {MatConfirmDialogComponent} from '../../ad-manager/components/modals/configrm-dialog/mat-confirm-dialog.component';
import 'rxjs-compat/add/operator/debounceTime';
import {DataGridComponent} from '../../../ngr-ui/components/data-grid/data-grid.component';
import 'rxjs-compat/add/operator/delay';
import {MultipleCopyFormComponent} from '../../components/forms/mltiple-copy-form/multiple-copy-form.component';
import {MultipleCopyStatus} from '../../utils/types/multiple-copy-status';
import {AdCostTypeModes} from '../../models/ad-cost-type-modes';
import {IncredibleCopyNotificationComponent} from '../../components/modals/incredible-copy-notification/incredible-copy-notification.component';
import {
    IncredibleCopyNotificationDataViewModel,
    IncrediblesCopyType
} from '../../models/view-models/incredible-copy-notification-data-view.model';
import {NotRemovedStrategiesModalComponent} from '../../components/modals/not-removed-strategies-modal/not-removed-strategies-modal.component';
import {StrategyCreationModel} from "../../models/view-models/strategy-creation-model";
import {AppSuggestionsService} from "../../../shared/services/suggestions-service/app-suggestions.service";
import {environment} from "../../../../environments/environment";
import {AdManagerDataServiceService} from "../../ad-manager/services/ad-manager-data-service.service";
import {CopyingAdsDialogComponent} from "../../copying-module/components/copying-ads-dialog/copying-ads-dialog.component";
import {LoadingDialogComponent} from "../../components/modals/loading-dialog/loading-dialog.component";


const DEFAULT_TIMEOUT = 1000;
const DEFAULT_HIDDEN_FIELDS = {
    reach: {hidden: false},
    name: {hidden: false},
    yandex_cr: {hidden: false},
    yandex_cpa: {hidden: false},
    status: {hidden: false},
    cpm: {hidden: false},
    ecpc: {hidden: false},
    ctr: {hidden: false},
    clicks: {hidden: false},
    impressions: {hidden: false},
    day_limit: {hidden: false},
    all_limit: {hidden: false},
    spent: {hidden: false},
    to_group: {hidden: false},
    join_group: {hidden: false},
    hide: {hidden: false},
    report: {hidden: false},
    unsubscribe: {hidden: false},
    reach_subscribers: {hidden: false},
    reach_total: {hidden: false},
    links: {hidden: false},
    pricing_subscribe: {hidden: false},
    price_click: {hidden: false},
    price_to_group: {hidden: false},
    yandex_reched_goals: {hidden: false},
    likes: {hidden: false},
    reposts: {hidden: false},
    comments: {hidden: false},
    yandex_cr_from_click: {hidden: false},
    yandex_cr_from_view: {hidden: false},
    yandex_average_duration: {hidden: false},
};

const WAIT_STATUS = {
    color: '067CBA',
    description: 'Осталось только запустить управелние ставкой<br>Для этого нажмите на кнопку "запустить".',
    icon: 'ion-md-time',
    id: 1,
    title: 'Ожидает запуска'
};


@Component({
    selector: 'app-automation-ads',
    templateUrl: './automation-ads.component.html',
    styleUrls: ['./automation-ads.component.scss']
})
export class AutomationAdsComponent implements OnInit {

    public PercentAuditoryTooltipText = 'Рассчитывается по формуле: (Охват/Аудитория)*100 ' +
        '\n ' +
        'Примечание: Если у объявления выставлено Ограничение показов: До 2-х и более показов на человека, то возможно искажение статистики на периоде более 1 дня.';
    public selectedRows = [];
    public accountId = null;
    public campaignId = null;
    public clients: Array<Client> = [];
    public currentCabinet = null;
    public onlyTizers = true;
    public goalId = null;
    public showLink = true;
    public showUtms = true;
    public showMin = true;
    public disabled = false;
    public hiddenColumns = DEFAULT_HIDDEN_FIELDS;
    public counterId = null;
    public BidManagmentModalOpened = false;
    public strategyType;
    public strategies: Array<Strategy>;
    public inputs: any = {};
    public strategiesUpdate = false;
    public filterString: string = '';
    public isLoadingData: boolean = false;
    public IsStrategyTypeSelected = false;
    public CreatedStrategyOpened = false;
    public CreatedStrategyInputs = [];
    public IsCreatingStrategy = true;
    public saveProperties = {};
    public bidRanges = [];
    public sendModeration;
    public startAfterModeration;
    public selected: Array<AdViewModel> = [];
    public inputStrategyKeys = {};
    public UpdatingLoading: boolean = false;
    public IsPromopostCampaign = false;
    public loadingGeneralData = false;
    public enableMultipleEditingButton = false;

    @ViewChild('#dg') public dGrid: DataGridComponent;
    @ViewChild('dgPagination') public dgPagination: DgPaginationComponent;
    private withoutPagination: any;
    public loading: boolean = true;
    public targetingLoading: boolean = true;
    public ShowCpcStrategies: boolean = false;
    public CurrentMultipleEditCostTypeIsCpc: boolean;
    public CostTypeModes = AdCostTypeModes;
    public CostTypeMode: AdCostTypeModes;
    public SelectedCostTypeMode: AdCostTypeModes;

    public get IsAllSelected() {
        return (this.withoutPagination && this.selected.length > 0) ? this.selected.length === this.withoutPagination.length : false;
    }

    public get ShowEditUI() {
        return this.selected.length > 0;
    }

    public get CostTypeHeader() {
        let header = '';
        switch (this.CostTypeMode) {
            case AdCostTypeModes.CPC_ONLY_MODE:
                header = 'CPC';
                break;
            case AdCostTypeModes.CPM_ONLY_MODE:
                header = 'CPM';
                break;
            case AdCostTypeModes.SHARE_COST_TYPE_MODE:
            default:
                header = 'Цена';
        }

        return header;
    }

    public get CostTypeName() {
        let name = '';
        switch (this.CostTypeMode) {
            case AdCostTypeModes.CPC_ONLY_MODE:
                name = 'cpc';
                break;
            case AdCostTypeModes.CPM_ONLY_MODE:
                name = 'cpm';
                break;
            case AdCostTypeModes.SHARE_COST_TYPE_MODE:
            default:
                name = 'cpc_cpm';
        }

        return name;
    }

    public SelectedGroups: Array<AdViewModel> = [];
    public errors: Array<string> = [];
    public ShowStrategyColumns: boolean = false;
    public period = {
        period: 'day',
        dateFrom: moment(new Date()).format('YYYY-MM-DD'),
        dateTo: moment(new Date()).format('YYYY-MM-DD')
    };
    public instructionsOpened = false;

    public filterObject = {
        period: 'today',
        dateFrom: new Date(),
        dateTo: new Date()
    };
    public IsYandexMetrikaLoading = false;
    public IsStrategiesLoaded = false;
    public strategy = null;

    public alternatives = {
        id: {
            name: 'Id объявдения'
        },
        name: {
            name: 'Название'
        },
        cpm: {
            name: 'CPM'
        },
        all_limit: {
            name: 'Общий лимит'
        },
        day_limit: {
            name: 'Дневной лимит'
        },
        spent: {
            name: 'Потрачено'
        },
        clicks: {
            name: 'Переходы'
        },
        ctr: {
            name: 'CTR'
        },
        ecpc: {
            name: 'eCPC'
        },
        reach: {
            name: 'Охват записи'
        },
        links: {
            name: 'Переходы по ссылке'
        },
        price_click: {
            name: 'Цена перехода по ссылке'
        },
        price_to_group: {
            name: 'Цена перехода в группу'
        },
        join_group: {
            name: 'Вступлений в группу'
        },
        pricing_subscribe: {
            name: 'Цена подписчика'
        },
        likes: {
            name: 'Лайки'
        },
        reposts: {
            name: 'Репосты'
        },
        comments: {
            name: 'Комментарии'
        },
        report: {
            name: 'Жалоб'
        },
        unsubscribe: {
            name: 'Скрытых записей всех'
        },
        reach_subscribers: {
            name: 'Охват подписчиков'
        },
        reach_total: {
            name: 'Охват не подписчиков'
        },
        yandex_reched_goals: {
            name: 'Yandex.Целей достигнуто'
        },
        yandex_cpa: {
            name: 'Yandex.Стоимость цели'
        },
        yandex_cr_from_click: {
            name: 'Yandex.CR переход в цель'
        },
        yandex_cr_from_view: {
            name: 'Yandex.CR просмотр в цель'
        },
        yandex_page_depth: {
            name: 'Yandex.Глубина просмотра'
        },
        yandex_average_duration: {
            name: 'Yandex.Время на сайте',
            pipe: SecondsToTimePipe
        }
    };
    public adsViewModels: Array<AdViewModel> = null;
    public showGroupJoinData = false;
    public counters: Array<Counter> = [];
    public goals: Array<Goal> = [];
    public hasAnyStrategies: boolean = false;
    public statusFilterOptions = [
        {
            value: null,
            title: 'Все'
        },
        {
            value: 1,
            title: 'Только запущенные'
        },
        {
            value: 0,
            title: 'Только остановленные'
        }
    ];
    private currentCampaigns: any;
    public clientId: number;
    public hiddenColumnsDg = {};
    public confirmEditDialogOpened = false;
    public confirmRemoveDialogOpened = false;
    private adIds: number;
    public ShowMultipleCreateStrategyButton = true;
    public ShowMultipleEditStrategyButton = true;
    public StrategyHelpVisible = false;


    public editErrors = {};

    public ToggleConfirmEdit() {
        this.confirmEditDialogOpened = !this.confirmEditDialogOpened;
    }

    public ToggleConfirmRemove() {
        this.confirmRemoveDialogOpened = !this.confirmRemoveDialogOpened;
    }

    private StrategyDescriptionExpandedList = [];

    public modifyFunctions = {
        BID_MIN: (value) => parseFloat(value) * 100,
        BID_START: (value) => parseFloat(value) * 100,
        BID_END: (value) => parseFloat(value) * 100,
        BID_MAX: (value) => parseFloat(value) * 100,
        BID_STEP: (value) => parseFloat(value) * 100,
        ECPC_LIMIT: (value) => parseFloat(value) * 100,
        CUSTOM_OVERALL_LIMIT: (value) => parseFloat(value) * 100,
    };

    public displayFunctions = {
        BID_MIN: (value) => parseFloat(value) / 100,
        BID_MAX: (value) => parseFloat(value) / 100,
        BID_START: (value) => parseFloat(value) / 100,
        BID_END: (value) => parseFloat(value) / 100,
        BID_STEP: (value) => parseFloat(value) / 100,
        ECPC_LIMIT: (value) => parseFloat(value) / 100,
        CUSTOM_OVERALL_LIMIT: (value) => parseFloat(value) / 100,
    };

    public customValidators = {
        BID_START: (bidRange: BidRange) => {
            const bidMax = this.GetCurrentBidRangeMax(bidRange);
            const bidMin = this.GetCurrentBidRangeMin(bidRange);
            return [
                createValidator({
                    code: 'REQUIRED',
                    invalidMessage: 'Поле должно быть заполнено'
                }, null),
                createValidator({
                    code: 'MIN',
                    invalidMessage: `Значение поля должно быть больше чем ${this.displayFunctions.BID_START(bidMin)}!`
                }, this.displayFunctions.BID_START(bidMin)),
                createValidator({
                    code: 'MAX',
                    invalidMessage: `Значение поля должно быть меньше чем ${this.displayFunctions.BID_START(bidMax)}!`
                }, this.displayFunctions.BID_START(bidMax))
            ];
        }
    };

    public editForm: FormGroup;

    public GetCurrentBidRangeMin(bidRange: BidRange) {
        let min = null;
        if (this.ShowCpcStrategies) {
            min = bidRange.cpcMin;
        } else {
            min = bidRange.cpmMin;
        }
        return min;
    }

    public GetCurrentBidRangeMax(bidRange: BidRange) {
        let max = null;
        if (this.ShowCpcStrategies) {
            max = bidRange.cpcMax;
        } else {
            max = bidRange.cpmMax;
        }
        return max;
    }

    public get EditFormErrors() {
        let editErrorsKeys = [];
        if (this.editErrors) {
            editErrorsKeys = Object.keys(this.editErrors)
                .map(x => this.editErrors[x]);
        }
        return editErrorsKeys;
    }

    public get displayedStrategies() {
        return this.strategies
            .filter(strategy => this.DisplayedStrategyCostType(strategy));
    }

    public DisplayedStrategyCostType(strategy) {
        if (this.ShowCpcStrategies) {
            return strategy.cpcCompitable === true;
        } else {
            return strategy.cpmCompitable === true;
        }
    }

    public get CountOfErrors() {
        return this.EditFormErrors.reduce((a, i) => a + i.length, 0);
    }

    public strategyCreateRequest: number = 0;
    public selectedUserStrategies: Array<UserStrategy> = [];

    constructor(
        private vkService: AutomationVkService,
        private route: ActivatedRoute,
        private localCabinetsDb: DexieCabinetsService,
        private localCampaignsDb: DexieCampaignsService,
        private excel: ExcelService,
        public yandex: YandexMetrikaWrapperService,
        private extractService: UtmExtractorService,
        private vkUrlExtractor: UrlExctractorService,
        private vkExtractService: VkUtmExtractorService,
        public accessManager: AutomationExpirationManagerService,
        private strategiesService: StrategiesService,
        private yandexService: AutomationYandexService,
        private usiService: UserStrategiesService,
        private notificationService: NotificationsService,
        private dialog: MatDialog,
        private adManagerService: AdManagerService,
        private router: Router,
        private cd: ChangeDetectorRef,
        private suggestionsService: AppSuggestionsService,
        private adDataService: AdManagerDataServiceService,
        private oauthService: OAuthService
    ) {
    }

    public EditStrategyInit(data: Array<AdViewModel>): void {
        console.log('EDIT', data);

        this.BidManagmentModalOpened = true;
        this.SelectedGroups = data;
        this.selectedUserStrategies = data.map(x => x.strategy);
    }

    public SendUserStrategiesEditSpecification(specification: any): void {
        this.usiService.EditUserStrategyInputs(specification).subscribe((response) => {
            const viewModels = this.adsViewModels.filter(viewModel =>
                viewModel && // Вьюха не нулевая
                viewModel.strategy && // У вьюхи есть стратегия
                response.find(x => x.strategyId === viewModel.strategy.strategyId) && // Id этой стратегии содержится в ответе
                response.find(x => x.adId === viewModel.id) // Id объявления содержится в ответе
            );

            response.forEach(uStrategy => {
                const originalViewModel = viewModels.find(x => x.id === uStrategy.adId);

                if (originalViewModel) {
                    originalViewModel.strategy = uStrategy;
                }
            });

            this.BidManagmentModalOpened = false;
        }, err => {
            console.warn('Error during editing userStrategy', err);
            this.BidManagmentModalOpened = false;
        });
    }

    private ClearSelectedUserStrategies(): void {
        this.selectedUserStrategies = [];
    }

    public get SelectedGroupsStrategy() {
        if (this.ContainsUserStrategy()) {
            return this.SelectedGroups[0].strategy.inputs.map(x => {
                return {
                    key: x.type.code,
                    value: x.value
                };
            })
                .reduce((a, b) => {
                    a[b.key] = b.value;
                    return a;
                }, {});
        } else {
            return null;
        }
    }

    private ContainsUserStrategy() {
        return this.SelectedGroups.length === 1 && this.SelectedGroups[0].strategy;
    }

    public ToggleInstructions() {
        this.instructionsOpened = !this.instructionsOpened;
    }

    public ToggleLoadingData() {
        this.isLoadingData = !this.isLoadingData;
    }

    public IsAnalyticServiceAvaialbe(analyticService: any) {
        return analyticService.IsAvaliable();
    }

    ToggleDisabled() {
        this.disabled = !this.disabled;
    }

    public showInactive: boolean = false;
    public selectedStrategy: Strategy = null;

    countOfResults: number = 10;
    availableSizes = environment.availableSizes;
    yandexGoalId: number;

    ngOnInit() {
        this.accountId = parseInt(this.route.snapshot.queryParams.account_id, 10);
        this.campaignId = parseInt(this.route.snapshot.queryParams.campaign_id, 10);
        this.clientId = parseInt(this.route.snapshot.queryParams.client_id, 10);
        this.adIds = this.route.snapshot.queryParams.adId;
        this.sendModeration = this.route.snapshot.queryParams.sendModeration;
        this.startAfterModeration = this.route.snapshot.queryParams.startAfterModeration;

        this.showInactive = this.route.snapshot.queryParams.showAll === 'true';
        this.GetStrategyInputKeys();

        this.adManagerService
            .GetInterestCategories();

        this.adDataService.LoadInterests();

        this.editForm = new FormGroup({
            status: new FormControl(null),
            strategy: new FormControl(null),
            cpm: new FormControl(null, [Validators.min(1.2), Validators.max(30)]),
            cpc: new FormControl(null, [Validators.min(1.2), Validators.max(30)]),
            day_limit: new FormControl(null, [Validators.min(100)]),
            all_limit: new FormControl(null, [Validators.min(100)]),
        });


        if (this.adIds) {
            setTimeout(() => {
                const dialog = this.dialog.open(ModerationModalComponent, {
                    width: '400px',
                    data: {
                        sendModeration: this.sendModeration,
                        startAfterModeration: this.startAfterModeration,
                    }
                });

                dialog.afterClosed()
                    .subscribe(() => {
                        this.router.navigate(
                            ['/automation/ads'],
                            {
                                queryParams: {
                                    account_id: this.accountId,
                                    campaign_id: this.campaignId,
                                    client_id: this.clientId,
                                    sendModeration: null,
                                    startAfterModeration: null,
                                    adId: null
                                },
                                queryParamsHandling: 'merge'
                            }
                        );
                    });
            }, 1500);
        }

        this.LoadBidRanges();
        this.LoadCurrentCabinet()
            .then(() => {
                this.InitPage();
            });
        this.LoadCurrentCampaign();
        this.LoadActionValues();

        this.route.queryParams
            .distinctUntilChanged()
            .filter((val, index) => index > 0)
            .subscribe(params => {
                this.InitPage();
            });
    }

    public StrategySelected(strategy: Strategy): void {
        this.selectedStrategy = strategy;
        this.StrategyHelpVisible = this.selectedStrategy && this.selectedStrategy.code === 'BID_REACH_CONTROL';
        this.cd.detectChanges();
    }

    public ToggleStrategyDescription(id: number, status: boolean): void {
        // Find status
        const item = this.FindStrategyDescriptionStatusById(id);

        // Change status
        item.status = status;
    }

    public GetStrategyDescriptionStatus(id: number): boolean {
        // Find status
        const item = this.FindStrategyDescriptionStatusById(id);

        // return status
        return item.status;
    }

    private FindStrategyDescriptionStatusById(id: number): any {
        return this.StrategyDescriptionExpandedList.find(x => x.id === id);
    }

    public SetActionValues() {
        window.localStorage.setItem('show_actions', JSON.stringify({
            showUtms: this.showUtms,
            showMin: this.showMin,
            showLink: this.showLink
        }));
    }

    public LoadCurrentCampaign() {
        this.localCampaignsDb.findCampaignById(this.campaignId)
            .then(data => {
                this.currentCampaigns = data;
            });
    }

    public LoadCurrentCabinet() {
        return this
            .localCabinetsDb
            .getCabinetById(this.accountId)
            .then((cabinet) => {
                this.currentCabinet = cabinet;
            });
    }

    public InitPage() {
        this.LoadClients()
            .then(() => {
                return new Promise(resolve => {
                    this.LoadData(this.clientId)
                        .then(() => {
                            resolve();
                        });
                });
            });
        this.oauthService.CheckProviderToken('yandex-metrika')
            .subscribe(data => {
                console.log(data, 'YANDEX METROKA STATUS');
                if (data) {
                    this.LoadCounters();
                }
            })
    }

    public LoadClients() {
        return new Promise(resolve => {
            if (this.currentCabinet && this.currentCabinet.account_type === 'agency') {
                this.vkService.getClients(this.accountId)
                    .subscribe((data) => {
                        this.clients = data.data;
                        resolve();
                    });
            } else {
                resolve();
            }
        });
    }

    public async LoadData(clientId) {
        console.warn(`LoadData(${clientId})...`);
        this.disabled = true;

        //this.ToggleDisabled();
        // this.LoadButtons();
        try {
            return await this.LoadAds(clientId)
                .then(this.LoadStatistics.bind(this))
                .then(this.LoadReach.bind(this))
                .then(this.LoadLayouts.bind(this))
                .then(this.LoadWallPost.bind(this))
                .then(this.LoadYandex.bind(this, 2))
                .then(this.LoadTargetingAds.bind(this, 2))
                .then(() => {
                    this.adsViewModels = this.adsViewModels
                        .map((ad, i) => {
                            ad.AddGroupsJoin();
                            ad.ad_url = this.GetAdUrls(ad);
                            return ad;
                        });

                    this.adManagerService.CabBeEditable({
                        accountId: this.accountId,
                        clientId: this.clientId,
                        adIds: this.adsViewModels.map(x => x.id)
                    })
                        .subscribe(data => {
                            this.adsViewModels.forEach(ad => {
                                ad.can_be_editable = data[ad.id];
                            });
                        });

          //this.loadingGeneralData = false;
          //this.ToggleDisabled();
          this.disabled = false;
          this.loadingGeneralData = false;

          console.warn(`LoadData(${clientId}) completed! this.loadingGeneralData=${this.loadingGeneralData}`);
        });
    } catch (err) {
      //console.error('FUCKING ERROR');
      console.error(err, 'ERROR');
    }
  }

    public CheckCostTypeMode(ads: Array<AdViewModel>) {
        const costTypes = ads.map(x => x.cost_type);
        const IsArrayHomogeneous = UtilsService.IsAllArrayElementsHomogeneous(costTypes);

        if (IsArrayHomogeneous) {
            if (costTypes[0]) {
                this.CostTypeMode = AdCostTypeModes.CPM_ONLY_MODE;
            } else {
                this.CostTypeMode = AdCostTypeModes.CPC_ONLY_MODE;
            }
        } else {
            this.CostTypeMode = AdCostTypeModes.SHARE_COST_TYPE_MODE;
        }
    }

    public SelectedAdsHaveStrategies() {
        console.log(this.selected.map(x => x.strategy));
        const adsHaveStrategies = this.selected.map(ad => !isNullOrUndefined(ad.strategy));
        const status = adsHaveStrategies
            .reduce((allAdsHaveStrategies, adHaveStrategy) => {
                return allAdsHaveStrategies || adHaveStrategy
            }, false);
        console.log(status, 'STATUS_ SELECTED');
        return status;
    }

    public LoadAds(clientId) {
        console.warn(`LoadAds(${clientId})...`);

        this.ToggleLoadingData();
        this.loading = true;
        this.loadingGeneralData = true;
        this.adsViewModels = [];
        return new Promise(resolve => {
            setTimeout(() => {
                console.warn(`this.vkService.GetAds();`,{
                    accountId: this.accountId,
                    campaignIds: [this.campaignId],
                    adIds: null,
                    clientId: clientId,
                    includeDeleted: false,
                    limit: 2000,
                    offset: 0
                });

                this.vkService.GetAds({
                    accountId: this.accountId,
                    campaignIds: [this.campaignId],
                    adIds: null,
                    clientId: clientId,
                    includeDeleted: false,
                    limit: 2000,
                    offset: 0
                })
                    .subscribe(data => {
                        console.warn(`this.vkService.GetAds() responded`, JSON.stringify(data));
                        if (data.data.length > 0) {
                            this.IsPromopostCampaign = data.data[0].ad_format === 9;
                            this.adsViewModels = data.data.map(ad => {
                                this.onlyTizers = !(ad.ad_format === 9);
                                const newAd = new AdViewModel();
                                newAd.ConvertAd(ad);
                                return newAd;
                            });

                            this.CheckCostTypeMode(this.adsViewModels);
                            this.loading = false;

                            console.warn(`adsViewModels.length=${this.adsViewModels.length}`);

                            if (this.adsViewModels.length > 0) {
                                const bid = this.bidRanges.find(x => x.adFormat === this.adsViewModels[0].ad_format);
                                if (bid) {
                                    this.editForm.controls.cpm.setValidators([Validators.min(bid.cpmMin / 100), Validators.max(bid.cpmMax / 100)]);
                                    this.editForm.controls.cpc.setValidators([Validators.min(bid.cpcMin / 100), Validators.max(bid.cpcMax / 100)]);
                                    this.editForm.valueChanges
                                        .debounceTime(500)
                                        .subscribe(() => {
                                            let errorsParams = null;
                                            if (this.SelectedCostTypeMode === AdCostTypeModes.CPM_ONLY_MODE) {
                                                errorsParams = {
                                                    valueMin: bid.cpmMin / 100,
                                                    valueMax: bid.cpmMax / 100,
                                                };
                                            } else {
                                                errorsParams = {
                                                    valueMin: bid.cpcMin / 100,
                                                    valueMax: bid.cpcMax / 100,
                                                };
                                            }

                      this.editErrors = UtilsService.GetErrorsByForm('multipleAdEditForm', this.editForm.controls, errorsParams);
                    });
                }
              }

              this.LoadStrategies();
              this.LoadStrategiesForAds();

              console.warn(`this.adsViewModels[0].ad_format=${this.adsViewModels[0].ad_format}`);

              if (this.adsViewModels[0].ad_format === 2) {
                this.loadingGeneralData = false;
              }
              resolve(clientId);
            } else {
              this.loading = false;
              this.IsYandexMetrikaLoading = false;
              this.loadingGeneralData = false;
            }
          }, ({error}) => {
            this.notificationService.Error(error.description);
            this.ToggleLoadingData();

            console.error(`{error}`, JSON.stringify(error));
          });
      }, DEFAULT_TIMEOUT);
    });
  }

    public async LoadStatistics(clientId) {
        return new Promise(async resolve => {
            const adsIds = this.GetIds();
            if (adsIds.length > 0) {
                let period = '';
                let period_date_from = this.period.dateFrom;
                let period_date_to = this.period.dateTo;
                if (this.period.period === 'overall') {
                    period = 'day';
                    period_date_from = '2012-06-01';
                    period_date_to = moment(new Date()).format('YYYY-MM-DD');
                } else {
                    period = this.period.period;
                    period_date_from = this.period.dateFrom;
                    period_date_to = this.period.dateTo;
                }
                for (const adIdsGroup of adsIds) {
                    const data = await this.vkService.GetStatistics({
                        accountId: this.accountId,
                        ids: adIdsGroup,
                        idsType: 'ad',
                        period: period,
                        dateFrom: period_date_from,
                        dateTo: period_date_to,
                        clientId: this.clientId
                    })
                        .delay(DEFAULT_TIMEOUT)
                        .toPromise();
                    this.adsViewModels.forEach(ad => {
                        const stat = data.data.find(x => x.id === ad.id);
                        if (stat) {
                            ad.AddStatistic(stat);
                        }
                    });
                }
                resolve(clientId);
            } else {
                resolve(clientId);
            }
        });
    }

    public LoadReach(clientId) {
        return new Promise(async resolve => {
            const idsGroup = this.GetIds(true);
            if (idsGroup.length > 0 && idsGroup[0].length > 0) {
                for (const groupId of idsGroup) {
                    try {
                        const data = await this.vkService.GetPostReach({
                            ids: groupId,
                            accountId: this.accountId,
                            idsType: 'ad',
                            clientId: this.clientId
                        })
                            .delay(1000)
                            .toPromise();

                        this.adsViewModels = this.adsViewModels.map(ad => {
                            const reach = data.data.find(x => x.id === ad.id);
                            ad.AddReach(reach);
                            return ad;
                        });
                    }catch(ex) {
                        console.log(ex);
                    }
                }
            }
            resolve(clientId);
        });
    }

    public LoadWallPost(clientId) {
        return new Promise(async resolve => {
            const ids = this.GetWithFormatIds();
            if (ids.length > 0) {
                for (const idsGroup of ids) {
                    const data = await this.vkService
                        .GetWallById(idsGroup)
                        .delay(1000)
                        .toPromise();

                    data.data.forEach(wallData => {

                        const ad = this.adsViewModels.find(ad1 => ad1.link_url === `http://vk.com/wall${wallData.owner_id}_${wallData.id}`);
                        if (ad) {
                            wallData = this.ProcessPostLinkSnippet(wallData, ad);

                            ad.AddWall(wallData);
                            ad.AddUtms(this.vkExtractService, this.extractService, wallData);
                        }
                    });

                    // this.adsViewModels = this.adsViewModels.map(ad => {
                    //   const newIds = ad.link_url.replace('https://vk.com/wall', '')
                    //     .replace('http://vk.com/wall', '')
                    //     .split('_')
                    //     .map(adl => parseInt(adl, 10));
                    //   const w = data.data.find(wall => wall.owner_id === newIds[0] && wall.id === newIds[1]);
                    //   ad.AddWall(w);
                    //   ad.AddUtms(this.vkExtractService, this.extractService, w);
                    //   return ad;
                    // });
                }
                if (this.adsViewModels[0].ad_format === 9) {
                    this.loadingGeneralData = false;
                }
                resolve(clientId);
            } else {
                this.adsViewModels = this.adsViewModels.map(ad => {
                    ad.AddUtms(this.vkExtractService, this.extractService, null);
                    return ad;
                });
                if (this.adsViewModels[0].ad_format === 9) {
                    this.loadingGeneralData = false;
                }
                resolve(clientId);
            }
        });
    }

    private ProcessPostLinkSnippet(post: WallPost, ad: AdViewModel): WallPost {
        if (post && ad) {
            const adId = ad.id.toString();

            if (post.text) {
                post.text = post.text.replace('{ad_id}', adId);
            }

            if (post.attachments && post.attachments.length > 0) {
                const linkAttachments = post.attachments.filter(x => x.link).map(x => x.link);

                if (linkAttachments && linkAttachments.length > 0) {
                    linkAttachments.forEach(link => {
                        if (link.url) {
                            link.url = link.url.replace('{ad_id}', adId);
                        }
                    });
                }
            }
        }

        return post;
    }

    public LoadLayouts(clientId) {
        this.showGroupJoinData = false;
        return new Promise(async resolve => {
            const ids = this.GetIds();
            for (const idsGroup of ids) {
                const idsData = idsGroup.split(',').map(x => parseInt(x, 10));
                const data = await this.vkService.GetAdsLayout({
                    adIds: idsData,
                    offset: 0,
                    includeDeleted: false,
                    limit: 2000,
                    clientId,
                    campaignIds: [this.campaignId],
                    accountId: this.accountId
                })
                    .delay(DEFAULT_TIMEOUT)
                    .toPromise();

                this.adsViewModels = this.adsViewModels.map(ad => {
                    const layout = data.data.find(x => x.id === ad.id);
                    if (!this.showGroupJoinData && ad.ad_format !== 9) {
                        if (layout && layout.link_url.indexOf('vk.com/') >= 0) {
                            this.showGroupJoinData = true;
                        }
                    }
                    ad.AddLayout(layout, this.vkUrlExtractor);
                    return ad;
                });
            }
            resolve(clientId);
        });
    }

  public LoadYandex(countOfRepeats: number = 1) {
    console.log(countOfRepeats, 'COUNT OF REPEATS WTF ?????');
    this.StartYandexMetrikaLoading();
    return new Promise(resolve => {
      if (this.goalId) {
        setTimeout(() => {
          this.yandex.LoadGoalReach({
            counterId: this.counterId,
            goalId: this.goalId,
            dateFrom: (this.period.dateFrom === null) ? '2012-06-01' : this.period.dateFrom,
            dateTo: (this.period.dateTo === null) ? 'today' : this.period.dateTo
          })
            .then((data: GoalReachResponse) => {
              countOfRepeats = countOfRepeats - 1;
              if (this.CanReplay(countOfRepeats, data)) {
                setTimeout(() => {
                  this.LoadYandex(countOfRepeats);
                }, 2000);
              } else {
                this.adsViewModels = this.adsViewModels.map(ad => {
                  ad.AddYandexData(null, this.yandex);
                  return ad;
                });
                this.StopYandexMetrikaLoading();
              }
              resolve();
            });
        }, DEFAULT_TIMEOUT);
      }
      resolve();
    });
  }

    private CanReplay(countOfRepeats: number, data: GoalReachResponse): boolean {
        return countOfRepeats > 0 && (!data || !data.data || data.data.length === 0);
    }

    public LoadCounters() {
        this.yandex.LoadCounters();
    }

    public LoadGoals() {
        this.yandex.LoadGoals(this.counterId);
    }

    public OnChangeShowUtms(showUtms) {
        this.showUtms = showUtms;
        this.SetActionValues();
    }

    public OnChangeShowMin(showMin) {
        this.showMin = showMin;
        this.SetActionValues();
    }

    public OnChangeShowLink(showLink) {
        this.showLink = showLink;
        this.SetActionValues();
    }

    public LoadActionValues() {
        const values = JSON.parse(localStorage.getItem('show_actions'));
        if (values) {
            Object.keys(values).forEach(x => {
                this[x] = values[x];
            });
        }
    }

    public GetAdUrls(ad: AdViewModel): Array<string> {
        if (!ad) {
            return null;
        }

        if (this.IsPromopost(ad)) {
            return this.vkUrlExtractor.ExtractUrl(ad.wall);
        } else {
            // This is teasers
            return [ad.link_url];
        }
    }

    private IsPromopost(ad: AdViewModel): boolean {
        return ad.ad_format === 9;
    }

    public GetLinkClass(classname: string, post: WallPost, link: WallLink): string {
        return classname + (this.IsArticle(post, link) ? ` ${classname}_article-mode` : '');
    }

    public GetLinkTitleLength(post: WallPost, link: WallLink): number {
        return this.IsArticle(post, link) ? 16 : 28;
    }

    ExportToExcel() {
        const data = this.adsViewModels.map(x => {
            return Object.keys(this.alternatives).reduce((a, b) => {
                if (this.alternatives[b].pipe) {
                    a[this.alternatives[b].name] = new this.alternatives[b].pipe().transform(parseInt(x[b], 10));
                } else {
                    a[this.alternatives[b].name] = x[b];
                }
                return a;
            }, {});
        });
        this.excel.exportAsExcelFile(data, this.currentCampaigns.name.replace(' ', '_'));
    }

    public SelectCounter(data) {
        this.counterId = data;
        this.LoadGoals();
    }

  public SelectGoal(data) {
    console.log('START SELECT GOAL');
    this.goalId = data;
    this.LoadYandex(5);
  }

    public ChangeDate(date) {
        date.dateFrom = moment(date.dateFrom).format('YYYY-MM-DD');
        date.dateTo = moment(date.dateTo).format('YYYY-MM-DD');
        this.filterObject.period = date.periodState;
        this.period = date;
        this.InitPage();
    }

    public GetAdUrlInVkCabinet(ad: AdViewModel): string {
        return `https://vk.com/ads?act=office&union_id=${ad.id}`;
    }

    public OpenBidManager(isUpdating) {
        this.strategiesUpdate = isUpdating;
        const selectedGroupAdsFormat = (this.SelectedGroups.length > 0) ? this.SelectedGroups[0].ad_format : 9;
        const bidRange = this.bidRanges.find((range: BidRange) => range.adFormat === selectedGroupAdsFormat);
        if (this.strategiesUpdate) {
            this.inputs = {};
            this.strategies.forEach(x => {
                const fields = {};
                x.inputs.forEach(y => {
                    let inputValue = null;
                    if (isUpdating) {
                        inputValue = (this.SelectedGroupsStrategy) ? this.formatFormValues(this.SelectedGroupsStrategy[y.code], y.code) : 0;
                    }
                    let validators = [];
                    if (this.customValidators[y.code]) {
                        validators = this.customValidators[y.code](bidRange);
                    } else {
                        validators = y.validators
                            .map(validator => createValidator(validator, validator.value));
                    }
                    fields[y.code] = {
                        name: y.code,
                        type: y['frontendInputType'],
                        label: y.title,
                        value: inputValue,
                        options: y.options,
                        placeholder: y.placeholderContent,
                        description: y.description,
                        required: y.required,
                        Validators: validators
                    };
                });
                this.inputs[x.code] = fields;
            });
        } else {
            this.inputs = {};
            this.strategies.forEach(x => {
                const fields = {};
                x.inputs.forEach(y => {
                    let validators = [];
                    if (this.customValidators[y.code]) {
                        validators = this.customValidators[y.code](bidRange);
                    } else {
                        validators = y.validators
                            .map(validator => createValidator(validator, validator.value));
                    }
                    fields[y.code] = {
                        name: y.code,
                        type: y['frontendInputType'],
                        label: y.title,
                        value: 0,
                        options: y.options,
                        placeholder: y.placeholderContent,
                        description: y.description,
                        required: y.required,
                        Validators: validators
                    };
                });
                this.inputs[x.code] = fields;
            });
        }
        this.BidManagmentModalOpened = true;
    }

    public HideSelected() {
        throw new Error('Not implemented');
    }

    public LoadStrategies() {
        console.warn(`LoadStrategies()...`);
        this.IsStrategiesLoaded = false;
        this.strategiesService.GetAllStrategies({showInactive: this.showInactive, onlyAllowedInCreationModule: false})
            .subscribe(data => {
                console.warn(`LoadStrategies() responded`, JSON.stringify(data));

                this.strategies = data;
                this.inputs = {};
                this.strategies.forEach(x => {
                    this.StrategyDescriptionExpandedList.push({
                        id: x.id,
                        status: false
                    });
                    const fields = {};
                    x.inputs.forEach(y => {
                        fields[y.code] = {
                            name: y.code,
                            type: y['frontendInputType'],
                            label: y.title,
                            value: (this.SelectedGroupsStrategy) ? this.formatFormValues(this.SelectedGroupsStrategy[y.code], y.code) : 0,
                            options: y.options,
                            placeholder: y.placeholderContent,
                            required: y.required,
                            description: y.description
                        };
                    });
                    this.inputs[x.code] = fields;
                });
                this.IsStrategiesLoaded = true;

                console.warn(`LoadStrategies() completed!`);
            });
    }

    public formatFormValues(value, inputName) {
        if (value) {
            if (this.displayFunctions[inputName]) {
                return this.displayFunctions[inputName](value);
            }
            return value;
        }
        return 0;
    }

    public NormalizeValue(value) {
        if (typeof value === 'string') {
            value = value.replace('.', ',');
        }
        return value;
    }

    public SuccessHandle(formValue) {
        this.strategy = formValue;
    }

    public CreateStrategies(creationModel: StrategyCreationModel): void {
        console.log('CreateStrategies', creationModel);
        console.log('ADS', creationModel.ads.map(x => x.id));
        this.selectedStrategy = null;

        const params = {
            accountId: this.accountId,
            clientId: this.clientId || 0,
            ads: creationModel.ads.map(x => x.id),
            strategyType: creationModel.strategy.code,
            updateStep: 10,
            properties: {}
        };

        creationModel.inputs
            .forEach(input => {
                params.properties[input.code] = input.value;
            });

        this.strategyCreateRequest = 1;

        console.log('WILL_CREATE', params);
        this.usiService.CreateUserStrategy(params).subscribe(data => {
            console.log(data);
            this.strategyCreateRequest = 2;
            this.LoadStrategiesForAds(true);
        }, err => {
            this.strategyCreateRequest = 2;
            console.warn(err);
        });
    }

    public SaveStrategy() {
        let formValue = Object.assign(this.strategy, {adIds: []});
        formValue.adIds = [];
        formValue = Object
            .keys(this.strategy)
            .map(formItemKey => ({
                key: formItemKey,
                value: (this.modifyFunctions[formItemKey]) ? this.modifyFunctions[formItemKey](formValue[formItemKey]) : formValue[formItemKey]
            }))
            .reduce((resultObject, {key, value}) => {
                resultObject[key] = value;
                return resultObject;
            }, {});
        formValue.strategyType = this.strategyType;
        const data = {
            properties: Object.keys(formValue)
                .map(key => ({key, value: this.NormalizeValue(formValue[key])}))
                .reduce((summaryObject, {key, value}) => {
                    summaryObject[key] = value;
                    return summaryObject;
                }, {}),
            accountId: this.accountId,
            clientId: this.clientId || 0,
            strategyType: this.strategyType,
            ads: this.SelectedGroups.map(x => x.id),
            adIds: this.selected.map(x => x.id)
        };
        this.saveProperties = data;
        this.errors = [];
        formValue.ads = this.SelectedGroups.map(x => x.id);
        formValue.adIds = this.selected.map(x => x.id);
        formValue.accountId = this.accountId;
        formValue.clientId = this.clientId;
        if (this.selected.length === 0) {
            if (!this.strategiesUpdate) {
                this.usiService.CreateUserStrategy(data)
                    .subscribe((data1: UserStrategy) => {
                        this.CreatedStrategyInputs = data1.inputs;
                        this.ToggleModalOpened();
                        this.CreatedStrategyOpened = true;
                        this.strategyType = null;
                        this.LoadStrategiesForAds(true);
                    }, err => {
                        this.errors.push(err.error.description);
                    });
            } else {
                this.ToggleConfirmEdit();
            }
        } else {
            this.ToggleModalOpened();
            this.dialog.open(MatConfirmDialogComponent, {
                data: {
                    title: 'Подтвердите действие',
                    description: 'Применить настройки управления ставками для выбранных объявлений?',
                    confirmActionText: 'Применить настройки',
                    rejectActionText: 'Отменить'
                }
            })
                .afterClosed()
                .subscribe(async isConfirmed => {
                    if (isConfirmed) {
                        this.UpdatingLoading = true;
                        const props = Object.keys(formValue)
                            .filter(x => x === x.toUpperCase())
                            .map(key => ({key, value: formValue[key]}))
                            .reduce((arr, {key, value}) => {
                                arr[key] = value;
                                return arr;
                            }, {});
                        const req = {
                            accountId: this.accountId,
                            clientId: this.clientId,
                            properties: props,
                            strategyType: this.strategyType,
                            all_limit: 0,
                            day_limit: 0,
                        };
                        this.editForm.controls.strategy.setValue(formValue);
                        data['all_limit'] = 0;
                        data['day_limit'] = 0;
                        data['campaignId'] = this.campaignId;
                        data['status'] = 0;
                        data['cpm'] = 0;
                        let userInputs = null;
                        for (const ad of this.selected) {
                            const a = await this.adManagerService.UpdateStrategies({
                                accountId: this.accountId,
                                campaignId: this.campaignId,
                                clientId: this.clientId || 0,
                                adIds: [ad.id],
                                all_limit: 0,
                                day_limit: 0,
                                cpm: 0,
                                properties: data.properties,
                                strategyType: this.strategyType,
                                status: 0
                            })
                                .pipe(
                                    delay(2000)
                                )
                                .toPromise();

                            userInputs = a[0].inputs;
                        }

                        this.UpdatingLoading = false;
                        this.CreatedStrategyInputs = userInputs;
                        this.CreatedStrategyOpened = true;
                        this.strategyType = null;
                        this.LoadStrategiesForAds(true);

                    }
                });
        }
    }

    public LoadStrategiesForAds(resetStatus: boolean = false) {
        console.warn(`LoadStrategiesForAds(resetStatus)...`);
        this.usiService
            .GetStrategiesByAdAndAccountIds({
                accountId: this.accountId,
                clientId: this.clientId,
                viewModel: {
                    adIds: this.adsViewModels.map(x => x.id)
                }
            })
            .subscribe(data => {
                console.warn(`LoadStrategiesForAds(resetStatus) responded`, JSON.stringify(data));

                if (data.length > 0) {
                    this.adsViewModels.forEach(x => {
                        const strategy = data.find(y => y.adId === x.id);
                        if (strategy && resetStatus) {
                            x.status = (strategy.isActive) ? 1 : 0;
                        }
                        x.AddStrategy(strategy);
                    });
                    this.ShowStrategyColumns = true;
                } else {
                    this.adsViewModels.forEach(x => {
                        x.AddStrategy(null);
                    });
                    this.ShowStrategyColumns = false;
                }

                console.warn(`LoadStrategiesForAds(resetStatus) completed!`);
            }, err => {
                console.error(`LoadStrategiesForAds(resetStatus) error`, JSON.stringify(err));
            });
    }

    public CancelHandle() {
        this.ToggleModalOpened();
    }

    ToggleModalOpened() {
        this.BidManagmentModalOpened = !this.BidManagmentModalOpened;
    }

    public CreateStrategy(data) {
        data.ads = this.SelectedGroups;

        this.usiService.CreateUserStrategy(data)
            .subscribe(data1 => {
            });
    }

    public RemoveStrategies() {
        const dialog = this.dialog.open(LoadingDialogComponent);

        console.log('dialgo opened');
        const promises = this.SelectedGroups.map(x => this.usiService.RemoveStrategy({
            accountId: this.accountId,
            adId: x.id
        }).toPromise());

        Promise.all(promises)
            .then(x => {
                this.LoadStrategiesForAds();
                dialog.close();
            })
            .catch(x => {
                this.LoadStrategiesForAds();
                dialog.close();
            });
    }

    SelectedRow(data) {
        this.LoadStrategies();
    }

    GetSelectedOptionValue(input: UserStrategyInput) {
        return input.type.options.find(x => parseInt(x.value, 10) === input.value).title;
    }

    public ShowAllStrategies(): boolean {
        return this.route.snapshot.queryParams.showAll;
    }

    public SummaryFunction(data: Array<number>) {
        if (data != null) {
            return data.reduce((a, b) => a + b, 0);
        }
        return 0;
    }

    public SummaryTitleFunction(data) {
        return 'Итого:';
    }

    public CurrencyFunction(data) {
        if (!isNaN(data)) {
            return new CurrencyPipe('ru').transform(data, ' Руб.');
        }
        return '0 Руб.';
    }

    public PaginSummaryTitleFunction() {
        return 'Итого на странице:';
    }

    public NumberFunction(data) {
        if (!isNaN(data)) {
            return new DecimalPipe('ru').transform(data, '0.0-3');
        }
        return '0';
    }

    public PercentFunction(data) {
        if (!isNaN(data)) {
            return new DecimalPipe('ru').transform(data, '0.0-2') + '%';
        }
        return '0%';
        // return new DecimalPipe('ru').transform(data, '0.0-2') + ' %'
    }

    public AvgSummaryFunction(data: Array<number>) {
        if (data != null) {
            data = data.filter(x => x !== 0);
            return (data.reduce((a, b) => a + b, 0) / data.length).toFixed(2);
        }
        return 0;
    }

    public YandexTime(data) {
        const date = Math.round(data);
        if (!isNaN(date)) {
            return new MinutesToTimePipe().transform(Math.round(data));
        }
        return '00:00';
    }

    public EcpcFormatFunction(data) {
        if (!isNaN(data)) {
            return new DecimalPipe('ru').transform(data, '1.3') + '%';
        }
        return '0.000%';
    }

    private GetIds(findOnlyPromoposts = false) {
        const COUNT_OF_ITEMS_IN_ARRAY = 100;
        const countOfArrays = Math.ceil(this.adsViewModels.length / COUNT_OF_ITEMS_IN_ARRAY);
        return this.adsViewModels
            .filter(x => (findOnlyPromoposts) ? x.ad_format === 9 : true)
            .map(x => x.id)
            .reduce((a, b, i) => {
                const currentIndex = i % countOfArrays;
                if (a[currentIndex] === undefined) {
                    a[currentIndex] = [];
                }
                a[currentIndex].push(b);
                return a;
            }, [])
            .map(x => x.join());
    }

    private IsArticle(post: WallPost, link: WallLink): boolean {
        const containOnlyLink = post && post.attachments.filter(x => isNullOrUndefined(x.link)).length === 0;

        return link.url.indexOf('vk.com/@') >= 0 && containOnlyLink;
    }

    private GetWithFormatIds() {
        const COUNT_OF_ITEMS_IN_ARRAY = 100;
        const countOfArrays = Math.ceil(this.adsViewModels.length / COUNT_OF_ITEMS_IN_ARRAY);

        return this.adsViewModels
            .filter(x => x.ad_format === 9)
            .map(x => x.link_url.replace('http://vk.com/wall', ''))
            .reduce((a, b, i) => {
                const currentIndex = i % countOfArrays;
                if (a[currentIndex] === undefined) {
                    a[currentIndex] = [];
                }
                a[currentIndex].push(b);
                return a;
            }, [])
            .map(x => x.join());
    }

    private StartYandexMetrikaLoading() {
        this.IsYandexMetrikaLoading = true;
    }

    private StopYandexMetrikaLoading() {
        this.IsYandexMetrikaLoading = false;
    }

    public changeAdStatus(adId, status) {
        this.adsViewModels = this.adsViewModels.map(ad => {
            if (ad.id === adId) {
                ad.status = status;
            }
            return ad;
        });
    }

    public HandleToggleStrategyActive({status, adIds}) {
        this.changeAdStatus(adIds, 2);
        this.usiService.ToggleStrategies({
            status: status,
            adIds: (adIds.length) ? adIds : [adIds],
            clientId: this.clientId || 0,
            accountId: this.accountId
        })
            .subscribe(data => {
                this.LoadStrategiesForAds();
            }, err => {
                this.notificationService.Error(err.error.description);
            });
    }

    public HandleCreateStrategy(ad: AdViewModel) {
        this.strategyCreateRequest = 0;
        this.selectedStrategy = null;
        this.IsCreatingStrategy = true;
        this.IsStrategyTypeSelected = false;
        this.strategyType = null;
        this.SelectedGroups = [ad];
        this.ShowCpcStrategies = ad.cost_type === 0;
        this.ClearSelectedUserStrategies();
        this.OpenBidManager(false);
    }

    public HandleUpdateStrategy(ad: AdViewModel) {
        this.SelectedGroups = [ad];
        this.strategyType = ad.strategy.strategy.code;
        this.IsStrategyTypeSelected = true;
        this.IsCreatingStrategy = false;
        this.OpenBidManager(true);
    }

    public HandleRemoveStrategy(ad: AdViewModel) {
        this.SelectedGroups = [ad];
        this.ToggleConfirmRemove();
    }

    public ToggleAd(adId, status) {
        const adIds = [adId];
        this.ChangeAdsStatus(adIds, status)
            .subscribe(() => {
            });
    }

    private ChangeAdsStatus(adIds, status) {
        return this.usiService.ToggleAdStatus({
            accountId: this.accountId,
            adIds,
            status
        })
            .map((response) => {
                if (response[0].error_code !== null) {
                    this.notificationService.Error('Не удалось запустить объявление. Убедитесь что кампания запущена и не превышены дневной или общий лимит');
                } else {
                    adIds.forEach(adId => {
                        this.ChangeStatusToWait(adId);
                    });
                }
            });
    }

    public ShowAllColumns() {

    }

    public CrFromViewFunction(data) {
        if (!isNaN(data)) {
            return new DecimalPipe('ru').transform(data, '1.0-4') + '%';
        }
        return '0%';
    }

    public GetSelectedStrategyType() {
        return this.strategies.find(strategy => strategy.code === this.strategyType);
    }

    public ToggleSelectedStrategy(status) {
        this.IsStrategyTypeSelected = status;
    }

    public ChangeStatusToWait(adId) {
        this.adsViewModels = this.adsViewModels.map(ad => {
            if (ad.id === adId) {
                ad.status = 2;
            }
            if (ad.strategy && ad.strategy.adId === adId) {
                ad.strategy.status = WAIT_STATUS;
            }
            return ad;
        });
    }

    public StartStrategyNow() {
        if (this.selected && this.selected.length > 0) {
            this.selected.forEach(x => {
                this.ChangeStatusToWait(x.id);
            });
        } else {
            this.ChangeStatusToWait(this.SelectedGroups[0].id);
        }
        this.BidManagmentModalOpened = false;
        this.HandleToggleStrategyActive({
            status: true,
            adIds: (this.selected && this.selected.length > 0) ? this.selected.map(x => x.id) : this.SelectedGroups.map(x => x.id)
        });
    }

    public StartStrategyLater() {
        this.strategyCreateRequest = 0;
        this.BidManagmentModalOpened = false;
    }

    public UpdateAdsInfo() {
        this.vkService.GetAds({
            accountId: this.accountId,
            campaignIds: [this.campaignId],
            adIds: null,
            clientId: this.clientId,
            includeDeleted: false,
            limit: 2000,
            offset: 0
        })
            .subscribe(data => {
                this.adsViewModels = data.data.map(ad => {
                    this.onlyTizers = !(ad.ad_format === 9);
                    const newAd = new AdViewModel();
                    newAd.ConvertAd(ad);
                    return newAd;
                });
            });
    }

    OnEditConfirm() {
        this.usiService.UpdateUserStrategies(this.saveProperties)
            .subscribe(data1 => {
                this.ToggleModalOpened();
                this.LoadStrategiesForAds(true);
                this.strategyType = null;
            }, err => {
                this.errors.push(err.error.description);
            });
        this.ToggleConfirmEdit();
    }

    OnEditReject() {
        this.ToggleConfirmEdit();
    }

    OnRemoveConfirm() {
        this.RemoveStrategies();
        this.ToggleConfirmRemove();
    }

    OnRemoveReject() {
        this.ToggleConfirmRemove();
    }

    LoadBidRanges() {
        this.strategiesService.GetBidRanges()
            .subscribe((data => {
                this.bidRanges = data;
            }));
    }

    public OpenAfterCreateModule() {
    }

    public CtrSummaryFunction(data, fullData) {
        const sumClicks = fullData
            .map(x => x.clicks)
            .reduce((a, b) => a + b, 0);

        const sumImpressions = fullData
            .map(x => x.impressions)
            .reduce((a, b) => a + b, 0);

        return (sumImpressions > 0) ? sumClicks / sumImpressions * 100 : 0;
    }

    public EcpcSummaryFunction(data, fullData) {
        const sumClicks = fullData
            .map(x => x.clicks)
            .reduce((a, b) => a + b, 0);

        const sumSpent = fullData
            .map(x => x.spent)
            .reduce((a, b) => a + b, 0);

        return (sumClicks) ? sumSpent / sumClicks : 0;
    }

    public IsSelected(row) {
        return this.selected.map(x => x.id).includes(row.id);
    }

    public SetWithoutPagination(data) {
        this.withoutPagination = data;
    }

    public ToggleAll() {
        if (!this.IsAllSelected) {
            this.selected = this.withoutPagination.map(x => x);
        } else {
            this.selected = [];
        }
        const selectedCostTypes = this.selected.map(x => x.cost_type);
        this.ShowMultipleCreateStrategyButton = UtilsService.IsAllArrayElementsHomogeneous(selectedCostTypes);
        this.ShowMultipleEditStrategyButton = this.HaveAllSelectedAdsStrategy() && this.IsSelectedStrategiesHomogeneous();
        this.ToggleCostType(selectedCostTypes);
    }

    public CountOfSelected() {
        return this.selected.length;
    }

    public ToggleSelected(row: any) {
        const index = this.selected.map(x => x.id).indexOf(row.id);
        if (index >= 0) {
            this.selected.splice(index, 1);
        } else {
            this.selected.push(row);
        }
        const selectedCostTypes = this.selected.map(x => x.cost_type);
        this.ShowMultipleCreateStrategyButton = UtilsService.IsAllArrayElementsHomogeneous(selectedCostTypes);
        this.ShowMultipleEditStrategyButton = this.HaveAllSelectedAdsStrategy() && this.IsSelectedStrategiesHomogeneous();
        this.ToggleCostType(selectedCostTypes);
        this.CurrentMultipleEditCostTypeIsCpc = selectedCostTypes[0] === 0;
    }

    private ToggleCostType(selectedCostTypes) {
        if (!this.ShowMultipleCreateStrategyButton) {
            this.SelectedCostTypeMode = AdCostTypeModes.SHARE_COST_TYPE_MODE;
        } else {
            if (selectedCostTypes[0]) {
                this.SelectedCostTypeMode = AdCostTypeModes.CPM_ONLY_MODE;
            } else {
                this.SelectedCostTypeMode = AdCostTypeModes.CPC_ONLY_MODE;
            }
        }
    }

    public UpdateAds() {
        if (this.editForm.valid) {
            this.dialog.open(MatConfirmDialogComponent, {
                data: {
                    title: 'Подтверждение действия',
                    description: 'Изменить параметры для выделенных объявлений',
                    confirmActionText: 'Изменить',
                    rejectActionText: 'Отменить'
                }
            })
                .afterClosed()
                .subscribe(status => {
                    if (status) {
                        this.UpdatingLoading = true;
                        const formValue = this.editForm.value;
                        formValue.accountId = this.accountId;
                        formValue.campaignId = this.campaignId;
                        formValue.adIds = this.selected.map(x => x.id);
                        if (formValue.strategy) {
                            this.StrategyToProperties(formValue);
                        }
                        formValue.costTypeMode = this.SelectedCostTypeMode;
                        const dialog = this.dialog.open(LoadingDialogComponent);
                        this.adManagerService.MultipleUpdateAds(formValue)
                            .subscribe(data => {
                                    this.UpdatingLoading = false;
                                    const dialogSuccessEdit = this.dialog.open(MultipleEditSuccessComponent, {
                                        data: {
                                            formValue,
                                            strategyInputs: formValue.strategy ? this.GetUserStrategy().inputs : null
                                        },
                                        width: '560px',
                                    });

                                    dialog.close();
                                    this.editForm.reset();
                                    this.InitPage();
                                },
                                err => {
                                    const dialogRejectEdit = this.dialog.open(MultipleEditRejectComponent, {
                                        data: formValue,
                                        width: '480px',
                                    });
                                }
                            );
                    }
                });
        }
    }

    private StrategyToProperties(formValue) {
        formValue.strategyType = formValue.strategy.strategyType;
        formValue.properties = Object.keys(formValue.strategy)
            .filter(x => x === x.toUpperCase())
            .map(key => ({key, value: formValue.strategy[key]}))
            .reduce((arr, {key, value}) => {
                arr[key] = value;
                return arr;
            }, {});
    }

    public HandleEditStrategy() {
        this.strategyCreateRequest = 0;
        this.selectedStrategy = null;
        this.IsCreatingStrategy = true;
        this.IsStrategyTypeSelected = false;
        this.strategyType = null;
        this.SelectedGroups = this.selected;
        this.ShowCpcStrategies = this.CurrentMultipleEditCostTypeIsCpc;
        this.strategyCreateRequest = 0;
        this.ClearSelectedUserStrategies();
        this.OpenBidManager(false);
    }

    public SetStatusMultiple(status) {
        const action = (status) ? 'запуск' : 'остановку';
        const action1 = (status) ? 'Запустить' : 'Остановить';
        this.dialog.open(MatConfirmDialogComponent, {
            data: {
                title: `Подтвердите ${action} объявлений`,
                description: `${action1} выбранные объявления?`,
                confirmActionText: action1,
                rejectActionText: 'Отменить'
            }
        })
            .afterClosed()
            .subscribe(confirmed => {
                if (confirmed) {
                    const dialog = this.dialog.open(LoadingDialogComponent);
                    this.UpdatingLoading = true;
                    const adIds = this.selected.map(x => x.id);
                    this.ChangeAdsStatus(adIds, status)
                        .subscribe(x => {
                            dialog.close();
                            this.UpdatingLoading = false;
                        });
                }
            });
        // this.editForm.controls.status.setValue(status);
    }

    GetMultipleStrategiesInputs() {
        const res = Object.keys(this.editForm.controls.strategy.value)
            .filter(key => key.includes('_'))
            .map(x => {
                const val = this.displayFunctions[x] ? this.displayFunctions[x](this.editForm.controls.strategy.value[x]) : this.editForm.controls.strategy.value[x];
                return {key: this.inputStrategyKeys[x], value: this.editForm.controls.strategy.value[x]};
            });
        return res;
    }

    public GetStrategyInputKeys() {
        this.adManagerService.GetUserStrategyInputKeys()
            .subscribe(inputKeys => {
                this.inputStrategyKeys = inputKeys;
            });
    }

    public propertiesToUserStrategyInputs(properties) {
        const strategyType = this.editForm.value.strategy.strategyType;
        const strategy = this.strategies.find(x => x.code === strategyType);
        return {
            strategy,
            inputs: strategy.inputs.map(input => {
                return {
                    type: input,
                    value: properties[input.code]
                };
            })
        };
    }

    public GetUserStrategy() {
        const formValue = this.editForm.value;
        if (formValue.strategy) {
            const properties = Object.keys(formValue.strategy)
                .filter(x => x === x.toUpperCase())
                .map(key => ({key, value: formValue.strategy[key]}))
                .reduce((arr, {key, value}) => {
                    arr[key] = value;
                    return arr;
                }, {});
            return this.propertiesToUserStrategyInputs(properties);
        } else {
            return null;
        }
    }

    public HasSelectedStrategy() {
        const selectedIds = this.selected.map(x => x.id);
        const strategies = this.adsViewModels.filter(x => selectedIds.includes(x.id)).map(x => {
            return x.strategy !== undefined && x.strategy !== null;
        });
        return strategies ? strategies.reduce((a, i) => a && i, true) : false;
    }

    public async RemoveStrategiesMany() {
        this.dialog.open(MatConfirmDialogComponent, {
            data: {
                title: 'Подтвердите удаление',
                description: 'Удалить способы управления ставками в выбранных объявлениях?',
                confirmActionText: 'Удалить',
                rejectActionText: 'Отменить'
            }
        })
            .afterClosed()
            .subscribe(async status => {
                const notRemovedStrategies = [];
                if (status) {
                    const dialog = this.dialog.open(LoadingDialogComponent);
                    this.UpdatingLoading = true;
                    for (const adId of this.selected.map(x => x.id)) {
                        try {
                            await this.usiService.RemoveStrategy({
                                accountId: this.accountId,
                                adId: adId
                            })
                                .delay(2000)
                                .retry(2)
                                .toPromise();
                        } catch (e) {
                            console.warn('AdIdNotRemoved', adId);
                            notRemovedStrategies.push(adId);
                            console.error(e);
                        }
                    }
                    if (notRemovedStrategies.length > 0) {
                        const notRemovedStrategiesAds = this.adsViewModels.filter(ad => notRemovedStrategies.includes(ad.id));
                        this.OpenNotRemovedStrategiesNotificationDialog(notRemovedStrategiesAds)
                    }
                    this.UpdatingLoading = false;
                    dialog.close();
                    this.LoadStrategiesForAds();
                    //
                    // const promises = this.selected.map((x, i) => {
                    //   return new Promise(resolve => {
                    //     setTimeout(() => {
                    //       this.usiService.RemoveStrategy({
                    //         accountId: this.accountId,
                    //         adId: x.id
                    //       })
                    //         .subscribe(data => {
                    //           resolve();
                    //         });
                    //     }, 1200 * i);
                    //   });
                    // });
                    //
                    // Promise.all(promises)
                    //   .then(x => {
                    //     this.UpdatingLoading = false;
                    //     this.LoadStrategiesForAds();
                    //   })
                    //   .catch(x => {
                    //     this.UpdatingLoading = false;
                    //     this.LoadStrategiesForAds();
                    //   });
                }
            });
    }

    public OpenNotRemovedStrategiesNotificationDialog(notRemovedStrategies: Array<AdViewModel>) {
        this.dialog.open(NotRemovedStrategiesModalComponent, {
            data: {
                ads: notRemovedStrategies
            }
        })
    }

    public ToggleStrategiesMany(status) {
        const action = (status) ? 'запуск' : 'остановку';
        const action1 = (status) ? 'Запустить' : 'Остановить';
        this.dialog.open(MatConfirmDialogComponent, {
            data: {
                title: `Подтвердите ${action}`,
                description: `${action1} автоматическое управление ставками для выбранных объявлений`,
                confirmActionText: action1,
                rejectActionText: 'Отменить'
            }
        })
            .afterClosed()
            .subscribe(async isConfirmed => {
                if (isConfirmed) {
                    try {
                        const dialog = this.dialog.open(LoadingDialogComponent);
                        this.UpdatingLoading = true;
                        const adIds = this.selected.map(x => x.id);
                        for (const adId of adIds) {
                            this.changeAdStatus(adId, 2);
                            try {
                                await this.usiService.ToggleStrategies({
                                    status: status,
                                    adIds: [adId],
                                    clientId: this.clientId || 0,
                                    accountId: this.accountId
                                })
                                    .delay(2000)
                                    .catch(err => {
                                        this.UpdatingLoading = false;
                                        this.notificationService.Error(err.error.description);
                                        dialog.close();
                                        return err;
                                    })
                                    .toPromise()
                                    .then((res) => res, err => {
                                        console.log(err, 'FUCKING ERROR');
                                        dialog.close();
                                    })
                                    .catch(err => {
                                        console.log('TOGGLE ERROR');
                                        this.UpdatingLoading = false;
                                        this.notificationService.Error(err.error.description);
                                        dialog.close();
                                    });
                                dialog.close()
                            } catch (err) {
                                console.log('TOGGLE ERROR');
                                dialog.close();
                                this.UpdatingLoading = false;
                                this.notificationService.Error(err.error.description);
                            }
                        }
                    } catch (err) {
                        console.log('TOGGLE ERROR');
                        this.UpdatingLoading = false;
                        this.notificationService.Error(err.error.description);
                    }
                    this.UpdatingLoading = false;
                    this.LoadStrategiesForAds();
                }
            });
    }

    public IsUniforms() {
        return this.selected.map(x => x.strategy && x.strategy.isActive);
    }

    public StrategyStatusStarted() {
        return (this.selected[0] && this.selected[0].strategy) ? this.selected[0].strategy.isActive : false;
    }

    public HasEditFormFilledField() {
        const fields = ['cpc', 'cpm', 'all_limit', 'day_limit'];
        let stat = false;
        fields.forEach(field => stat = stat || this.editForm.value[field] !== null);
        return stat;
    }

    public OpenCopyAdsDialog(selected = null, segmentation = false) {
        const adsToCopy = selected || this.selected;
        const ad_format = this.adsViewModels.find(ad => ad.ad_format !== null).ad_format;
        const isClientIdIsNan = isNaN(this.clientId);
        console.log(this.clientId, isClientIdIsNan);
        // this.dialog.open(MultipleCopyFormComponent, {
        //   data: {
        //     accountId: this.accountId,
        //     campaignId: this.campaignId,
        //     clientId: isClientIdIsNan ? 0 : this.clientId,
        //     selectedAds: adsToCopy,
        //     ad_format,
        //     segmentation,
        //     bidRange: this.bidRanges.find(x => x.adFormat === ad_format)
        //   }
        // })
        this.dialog.open(CopyingAdsDialogComponent, {
            data: {
                ad_format,
                account_id: this.accountId,
                client_id: isClientIdIsNan ? null : this.clientId,
                campaign_id: this.campaignId,
                ads: adsToCopy,
                bid_range: this.bidRanges.find(x => x.adFormat === ad_format)
            }
        })
            .afterClosed()
            .subscribe(({status, link}) => {
                console.log('STATUSES: ', {status, link});
                switch (status) {
                    case MultipleCopyStatus.NOTHING_TO_DO:
                        break;
                    case MultipleCopyStatus.NAVIGATE_TO:
                        window.location.href = link;
                        break;
                    case MultipleCopyStatus.REFRESH_PAGE:
                        this.InitPage();
                        break;
                }
                if (status) {
                } else {
                }
            });
    }

    public async LoadTargetingAds() {
        this.targetingLoading = true;
        return new Promise(async (resolve, reject) => {
            setTimeout(async () => {
                const adsIds = this.GetIds();
                for (const adIds of adsIds) {
                    const adsTargetingResponse = await this.vkService.GetAdsTargeting({
                        adIds: adIds.split(','),
                        accountId: this.accountId,
                        campaignIds: [this.campaignId],
                        clientId: this.clientId,
                        includeDeleted: false,
                        limit: 0,
                        offset: 0
                    })
                        .delay(1000)
                        .toPromise();
                    adsTargetingResponse.data.forEach(adTargetingData => {
                        const ad = this.adsViewModels.find(adItem => adItem.id === adTargetingData.id);
                        if (!isNullOrUndefined(ad)) {
                            ad.AddTargetingData(adTargetingData);
                        }
                    });
                }
                this.targetingLoading = false;
                resolve();
            }, DEFAULT_TIMEOUT);
        });
    }

    public CountOfIncredibleToCopy() {
        return this.selected.filter(x => !x.canBeCopied).length;
    }

    public IsAllAdsIncredibleToCopy() {
        return this.selected.length === this.CountOfIncredibleToCopy();
    }

    // public LoadButtons() {
    //   this.adManagerService.GetAllPromopostButtons()
    //     .subscribe(buttons => {
    //       this.buttons = buttons;
    //     });
    // }
    CheckOnCanBeCopied(segmentation = false) {
        const countOfIncredibleToCopyingAds = this.selected.filter(selected => !selected.CanBeCopied()).length;

        if (countOfIncredibleToCopyingAds === 0) {
            this.OpenCopyAdsDialog(null, segmentation);
        } else {
            this.OpenIncredibleToCopyNotification(countOfIncredibleToCopyingAds);
        }
    }

    public OpenIncredibleToCopyNotification(countOfIncredible: number) {
        const incredibleToCopyData: IncredibleCopyNotificationDataViewModel = {
            incredibleToCopyTypes: this.GetUniqueIncredibleTypes(),
            isAllAdsIncredibleToCopy: this.selected.length === countOfIncredible
        };
        this.dialog.open(IncredibleCopyNotificationComponent, {
            data: incredibleToCopyData,
            width: '640px'
        })
            .afterClosed()
            .subscribe((status: boolean) => {
                if (status) {
                    const selected = this.selected.filter(x => x.CanBeCopied());
                    this.OpenCopyAdsDialog(selected);
                }
            });
    }

    public GetUniqueIncredibleTypes(): Array<IncrediblesCopyType> {
        const uniqueIncredibleTypes = this.selected
            .filter(selectedAd => !selectedAd.canBeCopied)
            .map(selectedAd => selectedAd.incrediblesCopyType)
            .reduce((arr: Array<IncrediblesCopyType>, current: IncrediblesCopyType) => {
                if (!arr.includes(current)) {
                    arr.push(current);
                }
                return arr;
            }, []);

        return uniqueIncredibleTypes;
    }

    public IsSelectedStrategiesHomogeneous() {
        const selectedStrategiesIds = this.selected.map(x => x.strategy ? x.strategy.strategyId : null);
        return UtilsService.IsAllArrayElementsHomogeneous(selectedStrategiesIds)
    }

    public HaveAllSelectedAdsStrategy() {
        const hasAllSelecteStrategies = this.selected.map(x => x.strategy !== null);
        return UtilsService.IsAllArrayElementsHomogeneous(hasAllSelecteStrategies) && hasAllSelecteStrategies[0] === true;
    }

    // public ShowSelectedStrategyHelp(): boolean {
    //   const result = this.selectedStrategy && this.selectedStrategy.code === 'BID_REACH_CONTROL';
    //   this.cd.detectChanges();
    //   return result;
    // }
}
