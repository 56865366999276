<ng-container *ngIf="isShowed">
  <div class="notification-block">
    <div class="notification-title">
      <ng-content select=".notification-title-container"></ng-content>
    </div>
    <div class="notification-message">
      <ng-content></ng-content>
    </div>
  </div>
</ng-container>
