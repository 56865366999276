import { OnInit } from '@angular/core';
import { AdManagerService } from '../../../../api/services';
import { FormControl, FormGroup } from '@angular/forms';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { GroupsSelectorService } from '../../services/groups-selector/groups-selector.service';
import { Subject } from 'rxjs/Subject';
import { of } from 'rxjs/internal/observable/of';
var GroupsSelectorComponent = /** @class */ (function () {
    function GroupsSelectorComponent(adManagerService, groupsSelectorService) {
        this.adManagerService = adManagerService;
        this.groupsSelectorService = groupsSelectorService;
        this.dataSource = new MatTableDataSource([]);
        this.selection = new SelectionModel(true, []);
        this.countries$ = of([]);
        this.searchCityField = new FormControl();
        this.countryId = new FormControl();
        this.citiesSubject = new Subject();
        this.loading = false;
        this.cities = [];
    }
    Object.defineProperty(GroupsSelectorComponent.prototype, "DisplayedCities", {
        get: function () {
            var _this = this;
            return (this.searchCityField.value)
                ? this.cities.filter(function (x) { return x.title.toLowerCase().includes(_this.searchCityField.value.toLowerCase()); })
                : this.cities;
        },
        enumerable: true,
        configurable: true
    });
    GroupsSelectorComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.LoadCountries();
        this.form = new FormGroup({
            title: new FormControl(),
            countryId: new FormControl(-1),
            cityId: new FormControl(-1)
        });
        // this.selection = new SelectionModel(true, this.selectedGroups);
        // // this.selection.changed.subscribe(selections => {
        // // });
        var searchCityField$ = this.searchCityField.valueChanges.pipe(distinctUntilChanged(), debounceTime(500))
            .subscribe(function (searchString) {
            _this.citiesSubject.next({ searchString: searchString, countryId: (_this.form.value.countryId > 0) ? _this.form.value.countryId : null });
        });
        this.form.controls.countryId.valueChanges
            .subscribe(function (val) {
            _this.citiesSubject.next({ searchString: _this.searchCityField.value, countryId: (val > 0) ? val : null });
        });
        this.citiesSubject.subscribe(function (searchCitiesParams) {
            console.log(searchCitiesParams);
            if (searchCitiesParams.countryId !== null || searchCitiesParams.searchString !== null) {
                _this.adManagerService.GetCities(searchCitiesParams)
                    .subscribe(function (data) {
                    data.forEach(function (city) {
                        if (!_this.cities.map(function (x) { return x.id; }).includes(city.id)) {
                            _this.cities.push(city);
                        }
                    });
                });
            }
        });
        this.form.valueChanges.debounceTime(500).subscribe(function (value) {
            if (value.title) {
                _this.groupsSelectorService.ClearGroups();
                _this.loading = true;
                if (_this.searchSubscriber) {
                    _this.searchSubscriber.unsubscribe();
                }
                value.countryId = (value.countryId > 0) ? value.countryId : null;
                value.cityId = (value.cityId > 0) ? value.cityId : null;
                _this.searchSubscriber = _this.adManagerService.SearchGroups(value)
                    .subscribe(function (data) {
                    _this.groupsSelectorService.AddGroups(data);
                    _this.dataSource.data = data;
                    _this.dataSource.paginator = _this.paginator;
                    _this.dataSource.sort = _this.sort;
                    _this.loading = false;
                }, function (err) {
                    _this.loading = false;
                });
                // this.dataSource.changeFilter(value);
            }
        });
    };
    GroupsSelectorComponent.prototype.LoadCountries = function () {
        this.countries$ = this.adManagerService.GetCountries()
            .map(function (data) { return data.items; });
    };
    GroupsSelectorComponent.prototype.isAllSelected = function () {
        var numSelected = this.groupsSelectorService.selectedGroups.length;
        var numRows = this.dataSource.data.length;
        return numSelected === numRows;
    };
    /** Selects all rows if they are not all selected; otherwise clear selection. */
    GroupsSelectorComponent.prototype.masterToggle = function () {
        this.isAllSelected() ?
            this.groupsSelectorService.ClearSelectedGroups() :
            this.groupsSelectorService.SelectGroups(this.dataSource.filteredData);
    };
    GroupsSelectorComponent.prototype.SelectRow = function (row) {
        this.groupsSelectorService.SelectGroups([row]);
    };
    GroupsSelectorComponent.prototype.IsChecked = function (group) {
        return this.groupsSelectorService.IsChecked(group);
    };
    GroupsSelectorComponent.prototype.CanBeSelected = function (row) {
        return this.groupsSelectorService.CanBeSelected(row);
    };
    return GroupsSelectorComponent;
}());
export { GroupsSelectorComponent };
