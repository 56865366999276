import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AffiliateProgramLinkComponent} from "./components/affiliate-program-link/affiliate-program-link.component";
import {RouterModule} from "@angular/router";

@NgModule({
    declarations: [
        AffiliateProgramLinkComponent
    ],
    exports: [
        AffiliateProgramLinkComponent
    ],
    imports: [
        CommonModule,
        RouterModule
    ]
})
export class AffiliateProgramSharedModule {
}
