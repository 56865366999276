import {Component, OnInit} from '@angular/core';
import {UserPayments} from '../../../api/models/user-payments';
import {PaymentsService} from '../../../api/services/payments.service';

@Component({
    selector: 'app-payments',
    templateUrl: './payments.component.html',
    styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent implements OnInit {

    public Payments: Array<UserPayments>;

    constructor(
        private paymetnsService: PaymentsService
    ) {
    }

    ngOnInit() {
        this.LoadPayments();
    }

    public LoadPayments() {
        this.paymetnsService.getUserPayments()
            .subscribe(data => {
                this.Payments = data;
            });
    }

}
