var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { SortingInstance } from '../models/SortingInstance';
import { Subject } from 'rxjs/Subject';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { VkApiService } from '../../vk/services/vk-api.service';
import { VkApiConvertorService } from '../../vk/services/vk-api-convertor.service';
import { MathService } from './math.service';
import { VkAdDetectorService } from '../../vk/services/vk-ad-detector.service';
import { VkStealthParserHelperService } from '../../vk/services/vk-stealth-parser-helper.service';
import { VkAdvancedSearchReportWrapperService } from '../../vk/services/vk-advanced-search-report-wrapper.service';
import { isNullOrUndefined } from 'util';
import { AdvancedTextSearchService } from './advanced-text-search.service';
var VkRequestService = /** @class */ (function () {
    function VkRequestService(route, router, VkApi, VkApiConvert, MathService, AdDetector, StealthParserHelper, ReportService, advancedSearchAPI) {
        this.route = route;
        this.router = router;
        this.VkApi = VkApi;
        this.VkApiConvert = VkApiConvert;
        this.MathService = MathService;
        this.AdDetector = AdDetector;
        this.StealthParserHelper = StealthParserHelper;
        this.ReportService = ReportService;
        this.advancedSearchAPI = advancedSearchAPI;
        this.page = 1;
        this.filter = {};
        this.data = [];
        this.Search = new Subject();
        this.recordsFound = null;
        this.loading = false;
        this.defaultSortingField = '';
        this.subscribed = null;
        this.isExpired = false;
        this.ExpirationDate = null;
        this.error = null;
        this.excluded = [];
        this.maxCountOfChips = 3;
        this.IsAllChipsShowed = false;
        this.IsChanged = false;
        this.ConfirmedExcludedGroups = [];
        this.countOfResults = 10;
        this.countOfPages = 1;
        this.countPerPageItems = [
            10, 20, 30, 40, 50
        ];
        this.countOfMinutesToExpiration = null;
        this.expirationString = null;
        /**
         *
         * @type {null}
         */
        this.DataObserver = null;
        this.mode = 'groups';
        this.parsedDataCached = [];
        this.parsedDataCahceSortedBy = '';
        this.parsedDataCahceSortedDescend = true;
        this.apiParsedGroupAddress = null;
    }
    Object.defineProperty(VkRequestService.prototype, "ExcludedToShow", {
        get: function () {
            var _this = this;
            if (this.IsAllChipsShowed) {
                return this.excluded;
            }
            else {
                return this.excluded.filter(function (x, i) { return i < _this.maxCountOfChips; });
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VkRequestService.prototype, "Request", {
        get: function () {
            return Object.assign(this.filter, this.sorting, { page: this.page });
        },
        enumerable: true,
        configurable: true
    });
    VkRequestService.prototype.Init = function () {
        var _this = this;
        this.sorting = new SortingInstance(this.route.snapshot.queryParams.sortBy || '', this.route.snapshot.queryParams.sortDescend === 'true');
        if (this.route.snapshot.queryParams.page) {
            this.page = parseInt(this.route.snapshot.queryParams.page, 10);
        }
        this.Search.subscribe(function (data) {
            _this.LoadData();
        });
    };
    VkRequestService.prototype.OfferProviderLogin = function () {
        return isNullOrUndefined(this.VkApi.token);
    };
    VkRequestService.prototype.SetDefaultSortingField = function (field) {
        this.defaultSortingField = field;
    };
    VkRequestService.prototype.AddObserver = function (observer) {
        this.DataObserver = observer;
    };
    VkRequestService.prototype.LoadData = function () {
        var _this = this;
        this.error = null;
        this.loading = true;
        // this.cd.detectChanges()
        this.recordsFound = null;
        if (this.subscribed !== null) {
            this.subscribed.unsubscribe();
        }
        this.Navigate();
        this.filter.excludedGroups = this.ConfirmedExcludedGroups;
        this.filter.perPage = this.countOfResults;
        if (this.apiParsedGroupAddress && this.filter.groupAddress && this.filter.groupAddress === this.apiParsedGroupAddress) {
            // Check if we already for group
            // TODO Optimize this condition - If group not contains stealth ads (for real 0 ads) - each fitler change will cause RE parsing
            if (!this.parsedDataCached || this.parsedDataCached.length === 0) {
                this.LoadGroupsByAPI();
            }
            else {
                // Else if we have data just retrieve portion of this data
                this.AddDataToView();
            }
        }
        else {
            this.apiParsedGroupAddress = null;
            this.parsedDataCached = [];
            this.subscribed = this.DataObserver.findByFilter(this.Request)
                .subscribe(function (data) {
                _this.data = data.data;
                _this.recordsFound = data.records_found;
                _this.loading = false;
                _this.isExpired = data.userMeta.isExpired;
                _this.ExpirationDate = new Date(data.userMeta.expirationDate);
                _this.GetTimerToExpirationFromNow();
            }, function (err) {
                _this.loading = false;
                _this.error = err.error;
                _this.data = [];
                _this.recordsFound = 0;
                if (err.status === 404) {
                    _this.apiParsedGroupAddress = _this.filter.groupAddress;
                    _this.LoadGroupsByAPI();
                }
            });
        }
    };
    VkRequestService.prototype.SetSorting = function (field) {
        if (this.sorting.sortBy === field) {
            this.sorting.sortDescend = !this.sorting.sortDescend;
        }
        else {
            this.sorting.sortBy = field;
            this.sorting.sortDescend = true;
        }
        if (this.parsedDataCached && this.parsedDataCached.length > 0) {
            this.SetSortingForParsedData(this.parsedDataCached);
            this.AddDataToView();
        }
        this.Search.next();
    };
    VkRequestService.prototype.SetDefaultSorting = function () {
        this.sorting.sortBy = this.defaultSortingField;
        this.sorting.sortDescend = true;
    };
    VkRequestService.prototype.SetFilter = function (filter) {
        this.filter = filter;
        this.ResetPagination();
        this.Search.next();
    };
    VkRequestService.prototype.SetPage = function (page) {
        this.page = page;
        this.Search.next();
    };
    VkRequestService.prototype.Navigate = function () {
        var a = this.GetNotNullKeys();
        this.router.navigate([this.router.url.split('?')[0]], {
            queryParams: a
        });
    };
    VkRequestService.prototype.ResetPagination = function () {
        this.page = 1;
    };
    // public GetNotNullKeys(): object {
    //     const result = Object.create({});
    //     Object
    //         .keys(this.Request)
    //         .filter(k => this.Request[k] !== null)
    //         .filter(k => {
    //             // 
    //             return (k === 'postType') ? !isNaN(this.Request[k]) : true;
    //         })// Filter of all null keys
    //         .filter(k => !k.startsWith('_') && k !== 'closed' && k !== 'isStopped' && k !== 'hasError' && k !== 'observers')
    //         .forEach(key => {
    //             if (!isNaN(parseInt(this.Request[key], 10))) {
    //                 result[key] = this.Request[key];
    //             } else {
    //                 result[key] = this.Request[key];
    //             }
    //         });
    //     
    //     return result;
    // }
    VkRequestService.prototype.Reset = function () {
        this.SetPage(1);
        this.SetDefaultSorting();
    };
    VkRequestService.prototype.RemoveFromExcludes = function (groupId) {
        this.IsChanged = true;
        this.excluded = this.excluded.filter(function (x) { return x.id !== groupId; });
        if (this.excluded.length === 0) {
            this.ApplyExcluded();
        }
    };
    // public SetFilter(filter) {
    //     
    //     this.filter = filter;
    //     this.ResetPagination();
    //     this.Search.next();
    // }
    // 242 812
    VkRequestService.prototype.RemoveAllFromExcludes = function () {
        this.IsChanged = true;
        this.excluded = [];
        this.ApplyExcluded();
    };
    VkRequestService.prototype.AddToExcludes = function (data) {
        this.IsChanged = true;
        this.excluded.push(data);
    };
    VkRequestService.prototype.IsExcluded = function (data) {
        return this.excluded.filter(function (x) { return x.id === data.id; }).length > 0;
    };
    VkRequestService.prototype.ApplyExcluded = function () {
        this.ConfirmedExcludedGroups = this.excluded.map(function (x) { return x.id; });
        this.IsChanged = false;
        this.SetPage(1);
        if (this.ConfirmedExcludedGroups.length === 0 && this.filter.excludedGroups.length === 0) {
            return;
        }
        this.Search.next(this.filter);
    };
    VkRequestService.prototype.GetNotNullKeys = function () {
        var _this = this;
        var result = Object.create({});
        Object
            .keys(this.Request)
            .filter(function (k) { return _this.Request[k] !== null; })
            .filter(function (k) {
            return (k === 'postType') ? !isNaN(_this.Request[k]) : true;
        }) // Filter of all null keys
            .filter(function (k) { return !k.startsWith('_') && k !== 'closed' && k !== 'isStopped' && k !== 'hasError' && k !== 'observers'; })
            .forEach(function (key) {
            result[key] = _this.Request[key];
        });
        return result;
    };
    Object.defineProperty(VkRequestService.prototype, "CountOfHiddenExcluded", {
        get: function () {
            return this.excluded.length - this.maxCountOfChips;
        },
        enumerable: true,
        configurable: true
    });
    VkRequestService.prototype.ShowAllExcluded = function () {
        this.IsAllChipsShowed = true;
    };
    VkRequestService.prototype.HideAllExcluded = function () {
        this.IsAllChipsShowed = false;
    };
    VkRequestService.prototype.SetCountOfResults = function (data) {
        this.countOfResults = data;
        this.SetPage(1);
        this.Search.next();
    };
    VkRequestService.prototype.SetDefaultPerPage = function () {
        this.countOfResults = 10;
    };
    VkRequestService.prototype.SetCountOfMinutesToExpiration = function (countOfMinutesToExpiration) {
        this.countOfMinutesToExpiration = countOfMinutesToExpiration;
    };
    VkRequestService.prototype.GetTimerToExpirationFromNow = function () {
        var minutesToExpiration = moment(this.ExpirationDate).diff(new Date(), 'minutes');
        if (minutesToExpiration > 0 && this.countOfMinutesToExpiration > minutesToExpiration) {
            this.expirationString = moment().to(this.ExpirationDate);
        }
        return null;
    };
    VkRequestService.prototype.LoadGroupsByAPI = function () {
        var _this = this;
        if (this.filter.groupAddress && this.filter.groupAddress.length >= 2 && this.VkApi.token) {
            this.loading = true;
            this.data = [];
            this.recordsFound = 0;
            var split = this.filter.groupAddress.split('/');
            var screen_name = split[split.length - 1];
            this.VkApi.UtilsResolveScreenName(screen_name).subscribe(function (x) {
                if (x) {
                    var group_id_1 = x.object_id;
                    var timeout = _this.VkApi.timeout;
                    switch (_this.mode) {
                        case 'groups':
                            setTimeout(function () {
                                _this.GetGroupData(group_id_1);
                            }, timeout);
                            break;
                        case 'posts':
                            setTimeout(function () {
                                _this.GetPostsData(group_id_1);
                            }, timeout);
                            break;
                        case 'stealth':
                            setTimeout(function () {
                                _this.GetStealthPostData(group_id_1);
                            }, timeout);
                            break;
                        default:
                            _this.loading = false;
                            break;
                    }
                }
                else {
                    _this.loading = false;
                }
            }, function (error) {
                _this.loading = false;
            });
        }
    };
    VkRequestService.prototype.GetStealthPostData = function (group_id) {
        var _this = this;
        var posts = [];
        this.parsedDataCached = [];
        this.VkApi.ExecuteGetGroupWall(group_id)
            .subscribe(function (response) { return __awaiter(_this, void 0, void 0, function () {
            var group_1, latestPost, ads, flatenedPosts, postIdList, gaps, ads;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!response) return [3 /*break*/, 5];
                        group_1 = null;
                        if (response.group) {
                            group_1 = this.VkApiConvert.ConvertGroup(response.group);
                        }
                        if (!response.wall) return [3 /*break*/, 5];
                        if (!(response.wall.length > 0)) return [3 /*break*/, 2];
                        if (!(response.wall[0] && response.wall[0].length > 0)) return [3 /*break*/, 2];
                        latestPost = response.wall[0][0];
                        if (!latestPost) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.StealthParserHelper.LookaheadScan(group_id, latestPost.id)];
                    case 1:
                        ads = _a.sent();
                        if (ads && ads.length > 0) {
                            ads.forEach(function (post) {
                                var converted = _this.VkApiConvert.ConvertPost(group_1, post);
                                if (converted) {
                                    posts.push(converted);
                                }
                            });
                        }
                        _a.label = 2;
                    case 2:
                        flatenedPosts = [].concat.apply([], response.wall);
                        if (!(flatenedPosts && flatenedPosts.length > 0)) return [3 /*break*/, 4];
                        postIdList = flatenedPosts.map(function (post) { return post.id; });
                        gaps = this.StealthParserHelper.DetectGapsInWall(postIdList);
                        // Exclude already visited posts from
                        if (this.StealthParserHelper.visitedPostsIdList && this.StealthParserHelper.visitedPostsIdList.length > 0) {
                            gaps = gaps.filter(function (element) { return !_this.StealthParserHelper.visitedPostsIdList.includes(element); });
                        }
                        if (!(gaps && gaps.length > 0)) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.StealthParserHelper.RetrieveStealthAds(gaps, group_id)];
                    case 3:
                        ads = _a.sent();
                        // Convert VkApiPost to Pm posts
                        if (ads && ads.length > 0) {
                            ads.forEach(function (post) {
                                var converted = _this.VkApiConvert.ConvertPost(group_1, post);
                                if (converted) {
                                    posts.push(converted);
                                }
                            });
                        }
                        _a.label = 4;
                    case 4:
                        this.ReportService.ReportPosts(posts);
                        this.SetSortingForParsedData(posts);
                        this.parsedDataCached = posts;
                        this.AddDataToView();
                        _a.label = 5;
                    case 5: return [2 /*return*/];
                }
            });
        }); });
    };
    VkRequestService.prototype.SetSortingForParsedData = function (data) {
        var sortBy = this.sorting.sortBy;
        var sortDesc = this.sorting.sortDescend;
        switch (this.mode) {
            case 'groups':
                this.SortGroups(data, sortBy, sortDesc);
                break;
            case 'posts':
                this.SortPosts(data, sortBy, sortDesc);
                break;
            case 'stealth':
                this.SortPosts(data, sortBy, sortDesc);
                break;
        }
        this.parsedDataCahceSortedBy = sortBy;
        this.parsedDataCahceSortedDescend = sortDesc;
    };
    VkRequestService.prototype.SortGroups = function (posts, sortBy, sortDesc) {
    };
    VkRequestService.prototype.SortPosts = function (data, sortBy, sortDesc) {
        switch (sortBy) {
            case 'date':
                data.sort(function (a, b) {
                    return (sortDesc) ? (parseInt(moment(b.date).format('x'), 10) - parseInt(moment(a.date).format('x'), 10)) :
                        (parseInt(moment(a.date).format('x'), 10) - parseInt(moment(b.date).format('x'), 10));
                });
                break;
            case 'likes':
                data.sort(function (a, b) {
                    return (sortDesc) ? (b.likes - a.likes) : (a.likes - b.likes);
                });
                break;
            case 'comments':
                data.sort(function (a, b) {
                    return (sortDesc) ? (b.comments - a.comments) : (a.comments - b.comments);
                });
                break;
            case 'reposts':
                data.sort(function (a, b) {
                    return (sortDesc) ? (b.reposts - a.reposts) : (a.reposts - b.reposts);
                });
                break;
            case 'views':
                data.sort(function (a, b) {
                    return (sortDesc) ? (b.views - a.views) : (a.views - b.views);
                });
                break;
        }
    };
    VkRequestService.prototype.GetPostsData = function (group_id) {
        var _this = this;
        var posts = [];
        this.VkApi.ExecuteGetGroupWall(group_id)
            .subscribe(function (data) {
            if (data) {
                var group_2 = _this.GetEmptyGroupInstace(group_id);
                group_2 = _this.SetEmptyGroupValuesFromApiGroup(group_2, data.group);
                if (data.wall && data.wall.length > 0) {
                    data.wall.forEach(function (wall) {
                        wall.forEach(function (post) {
                            var isAdv = _this.AdDetector.IsPostAnAd(post);
                            if (isAdv) {
                                var convertedPost = _this.VkApiConvert
                                    .ConvertPost(group_2, post);
                                posts.push(convertedPost);
                            }
                        });
                    });
                    // Collect all reposted groups
                    if (posts && posts.length > 0) {
                        var postWithReposts = posts.filter(function (x) { return x.copy_history && x.copy_history.length > 0; });
                        if (postWithReposts && postWithReposts.length > 0) {
                            var repostedGroupIdList_1 = [];
                            postWithReposts.forEach(function (post) {
                                if (post.copy_history) {
                                    post.copy_history.forEach(function (repost) {
                                        if (repostedGroupIdList_1.indexOf(repost.group_id) === -1) {
                                            repostedGroupIdList_1.push(repost.group_id);
                                        }
                                    });
                                }
                            });
                            // Retrieve data for all repost groups
                            if (repostedGroupIdList_1.length > 0) {
                                setTimeout(function () {
                                    _this.FillRepostsWithGroupData(posts, repostedGroupIdList_1);
                                }, _this.VkApi.timeout);
                            }
                        }
                    }
                }
                _this.SetSortingForParsedData(posts);
                _this.parsedDataCached = posts;
                _this.AddDataToView();
            }
        });
    };
    VkRequestService.prototype.FillRepostsWithGroupData = function (posts, repostedGroupIdList) {
        var _this = this;
        this.VkApi.GroupsGetById(repostedGroupIdList, null, 'members_count').subscribe(function (groups) {
            if (groups && groups.length > 0) {
                var convertedGroups_1 = [];
                groups.forEach(function (group) {
                    var convertedGroup = _this.VkApiConvert.ConvertGroup(group);
                    if (convertedGroup) {
                        convertedGroups_1.push(convertedGroup);
                    }
                });
                if (convertedGroups_1.length > 0) {
                    var reposts = posts.filter(function (x) { return x.copy_history && x.copy_history.length > 0; }).map(function (x) { return x.copy_history; });
                    if (reposts && reposts.length > 0) {
                        reposts.forEach(function (repost) {
                            repost.forEach(function (entry) {
                                if (entry) {
                                    var repostGroup = convertedGroups_1.find(function (group) { return group.id === entry.group_id; });
                                    if (repostGroup && entry && entry.child_post) {
                                        entry.child_post.group = repostGroup;
                                    }
                                }
                            });
                        });
                    }
                }
            }
        });
    };
    VkRequestService.prototype.GetGroupData = function (group_id) {
        var _this = this;
        var group = this.GetEmptyGroupInstace(group_id);
        var currentDay = moment(new Date());
        var previousDay = moment(new Date()).subtract(7, 'days');
        // Request data from API
        this.VkApi.ExecuteGetGroupById(group_id, currentDay.format(this.VkApi.date_format), previousDay.format(this.VkApi.date_format))
            .subscribe(function (data) {
            if (data) {
                // If main group's data provided
                group = _this.SetEmptyGroupValuesFromApiGroup(group, data.group);
                // If statistics provided
                if (data.statistics) {
                    var source = data.statistics;
                    var convertedStats = _this.VkApiConvert.ConvertStatistics(group_id, source);
                    var male = convertedStats.sex_statistics.find(function (x) { return x.id === 'male'; });
                    var female = convertedStats.sex_statistics.find(function (x) { return x.id === 'female'; });
                    group.statistics = convertedStats;
                    group.sex = {
                        id: group_id,
                        male: male.total,
                        female: female.total
                    };
                    group.geo = _this.VkApiConvert.ConvertGeo(group_id, source);
                }
                if (data.wall) {
                    var commentsIsOpened = false;
                    group.engagement.commentsOpened = false;
                    if (data.wall.items && data.wall.items.length > 0) {
                        if (data.wall.items.find(function (x) { return x.comments.count > 0; })) {
                            group.engagement.commentsOpened = true;
                        }
                    }
                    var source = data.wall;
                    var posts = source.items;
                    var maxTimeStamp_1 = moment().startOf('day').unix();
                    var minTimeStamp_1 = moment().subtract(1, 'days').startOf('day').unix();
                    var previousDayPosts = posts.filter(function (post) { return post.date >= minTimeStamp_1 && post.date <= maxTimeStamp_1; });
                    if (previousDayPosts && previousDayPosts.length > 0) {
                        // Get the posts with views data
                        var postsWithViews = previousDayPosts.filter(function (x) { return x.views; });
                        if (postsWithViews && postsWithViews.length > 0) {
                            // Aggregate all viewsfrom these posts
                            var views = postsWithViews.map(function (x) { return x.views.count; });
                            // Get average views for one (previous, this is important) day - this is post reach
                            var medianViewsForOneDay = _this.MathService.GetMedianValue(views);
                            // Assign value
                            group.statistics.reach_post = medianViewsForOneDay;
                        }
                        // Calculate post per day
                        var maxDaysToCapture = Math.floor(100 / previousDayPosts.length);
                        var dayStep = 60 * 60 * 24;
                        // Redefine minTimeStamp
                        minTimeStamp_1 = moment().add(-maxDaysToCapture, 'days').startOf('day').unix();
                        var postsToCalculate = posts.filter(function (post) { return post.date >= minTimeStamp_1; });
                        var postsPerDay_1 = [];
                        for (var i = maxTimeStamp_1; i >= minTimeStamp_1; i -= dayStep) {
                            postsPerDay_1.push({
                                day: i,
                                count: 0,
                                likes: [],
                                comments: [],
                                reposts: [],
                                averageLikes: 0,
                                averageComments: 0,
                                averageReposts: 0
                            });
                        }
                        var commentsOpened = null;
                        postsToCalculate.forEach(function (post) {
                            var date = postsPerDay_1.find(function (x) { return post.date >= x.day; });
                            if (date) {
                                date.count = date.count + 1;
                                date.likes.push(post.likes.count);
                                date.comments.push(post.comments.count);
                                date.reposts.push(post.reposts.count);
                                // 
                                // if (commentsOpened === null && post.comments) {
                                //
                                //   commentsOpened = post.comments.can_post === 1;
                                // }
                            }
                        });
                        // Count average values
                        postsPerDay_1.forEach(function (x) {
                            x.averageLikes = _this.MathService.GetAverageValue(x.likes);
                            x.averageComments = _this.MathService.GetAverageValue(x.comments);
                            x.averageReposts = _this.MathService.GetAverageValue(x.reposts);
                        });
                        var postPerDay = postsPerDay_1.map(function (x) { return x.count; });
                        var likes = postsPerDay_1.map(function (x) { return x.averageLikes; });
                        var comments = postsPerDay_1.map(function (x) { return x.averageComments; });
                        var reposts = postsPerDay_1.map(function (x) { return x.averageReposts; });
                        var averagePostPerDay = _this.MathService.GetAverageValue(postPerDay);
                        var averageLikes = _this.MathService.GetAverageValue(likes);
                        var averageComments = _this.MathService.GetAverageValue(comments);
                        var averageReposts = _this.MathService.GetAverageValue(reposts);
                        group.engagement.posts_per_day = averagePostPerDay;
                        group.engagement.likes = averageLikes;
                        group.engagement.comments = averageComments;
                        group.engagement.reposts = averageReposts;
                        // if (commentsOpened != null) {
                        //   group.engagement.commentsOpened = commentsIsOpened;
                        // }
                    }
                }
                // This is meaningless to sort 1 group. But if later there will be serach by term via API - uncomment this code
                //this.SetSortingForParsedData([group]);
                _this.ReportService.ReportGroups([group]);
                _this.parsedDataCached = [group];
                _this.AddDataToView();
            }
        }, function (error) {
            _this.loading = false;
        });
    };
    VkRequestService.prototype.GetEmptyGroupInstace = function (group_id) {
        var group = {
            id: group_id,
            members_history: [],
            links: [],
            contacts: [],
            engagement: {
                id: group_id,
                likes: 0,
                reposts: 0,
                comments: 0,
                posts_per_day: 0,
                er: 0,
                commentsOpened: null
            },
            statistics: {
                group_id: group_id
            },
            geo: {
                id: group_id
            },
            sex: { id: group_id },
            meta_data: null,
            pricing: null,
            inactive: null
        };
        return group;
    };
    VkRequestService.prototype.SetEmptyGroupValuesFromApiGroup = function (group, source) {
        if (source) {
            group.name = source.name;
            group.screen_name = source.screen_name;
            group.photo_50 = source.photo_50;
            group.photo_100 = source.photo_100;
            group.photo_200 = source.photo_200;
            group.members_count = source.members_count;
        }
        return group;
    };
    VkRequestService.prototype.FilterResults = function () {
        switch (this.mode) {
            case 'groups':
                return this.parsedDataCached;
            case 'posts':
                return this.FilterPosts(this.parsedDataCached, this.filter);
            case 'stealth':
                return this.FilterPosts(this.parsedDataCached, this.filter);
            default:
                return null;
        }
    };
    VkRequestService.prototype.FilterPosts = function (data, request) {
        if (!this.parsedDataCached || this.parsedDataCached.length === 0) {
            return;
        }
        var searchEngine = this.advancedSearchAPI;
        return this.parsedDataCached.filter(function (post) {
            if (!post) {
                return false;
            }
            if (request && request.text && request.text.length > 0 && !searchEngine.TextContainsTerm(post.text, request.text)) {
                return false;
            }
            // Likes
            if (request.likesFrom && post.likes < request.likesFrom) {
                return false;
            }
            if (request.likesTo && post.likes > request.likesTo) {
                return false;
            }
            // Reposts
            if (request.repostsFrom && post.reposts < request.repostsFrom) {
                return false;
            }
            if (request.repostsTo && post.reposts > request.repostsTo) {
                return false;
            }
            // Comments
            if (request.commentsFrom && post.comments < request.commentsFrom) {
                return false;
            }
            if (request.commentsTo && post.comments > request.commentsTo) {
                return false;
            }
            // Views
            if (request.viewsFrom && post.views < request.viewsFrom) {
                return false;
            }
            if (request.viewsTo && post.views > request.viewsTo) {
                return false;
            }
            // Date
            if (request.dateFrom || request.dateTo) {
                var convertedDate = parseInt(moment(post.date).format('x'), 10);
                if (request.dateFrom) {
                    var convertedDateFrom = parseInt(moment(request.dateFrom).format('x'), 10);
                    if (convertedDate < convertedDateFrom) {
                        return false;
                    }
                }
                if (request.dateTo) {
                    var convertedDateTo = parseInt(moment(request.dateTo).add(60 * 23 + 59, 'minutes').format('x'), 10);
                    if (convertedDate > convertedDateTo) {
                        return false;
                    }
                }
            }
            if (request.containVideo) {
                if (!post.videos || post.videos.lenght === 0) {
                    return false;
                }
            }
            if (request.markedAsAds) {
                if (!post.marked_as_ads) {
                    return false;
                }
            }
            if (request.postType && post.post_type !== request.postType) {
                return false;
            }
            return true;
        });
    };
    VkRequestService.prototype.AddDataToView = function () {
        var filteredResults = this.FilterResults();
        var paginationStart = (this.page - 1) * this.countOfResults;
        if (filteredResults) {
            var maxAvailableItems = Math.min(filteredResults.length - paginationStart, this.countOfResults);
            var viewResult = filteredResults.slice(paginationStart).slice(0, maxAvailableItems);
            this.data = viewResult;
            this.recordsFound = filteredResults.length;
        }
        else {
            this.data = [];
            this.recordsFound = 0;
        }
        this.loading = false;
    };
    return VkRequestService;
}());
export { VkRequestService };
