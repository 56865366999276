<div class="chat-controll-panel-container">
  <div class="chat-controll-panel">
    <div class="chat-controll-panel-strip">
      <div class="chat-controll-panel-strip__item">
        <input id="sendEmailOption" type="checkbox" ngModel (ngModelChange)="ChangeSendNotificationViaEmail($event)">
        <label for="sendEmailOption"> Отправить оповещение на Email</label>
      </div>
      <ng-container *ngIf="Conversation(); let conversation">
        <div class="chat-controll-panel-strip__item">
          <ng-container *ngIf="conversation.experience && conversation.experience.level">
            <span class="conversation-owner-experience">
              {{conversation.experience.level.name}}
            </span>
          </ng-container>
        </div>
        <div class="chat-controll-panel-strip__item">
          <label for="conversation-mark-select">Пометка: </label>
          <select name="conversation-mark" id="conversation-mark-select" [(ngModel)]="conversation.markId" (ngModelChange)="ChangeConversationMark($event)">
            <option value="null" disabled>Не выбрано</option>
            <ng-container *ngFor="let option of chatApi.marks">
              <option [value]="option.id">
                {{option.title}}
              </option>
            </ng-container>
          </select>
        </div>
      </ng-container>
      <div class="chat-controll-panel-strip__item chat-controll-panel-strip__item_tilt-right">
        <a routerLink="/chat/conversations-list">Назад к списку бесед</a>
      </div>
    </div>
  </div>
</div>
