import {Component, Input, OnInit} from '@angular/core';
import {AuthCabinet} from '../../../api/models/auth-cabinet';

@Component({
    selector: 'app-personal-cabinet',
    templateUrl: './personal-cabinet.component.html',
    styleUrls: ['./personal-cabinet.component.scss']
})
export class PersonalCabinetComponent implements OnInit {

    @Input() public cabinet: AuthCabinet;
    @Input() public IsLoggedIn: boolean;
    @Input() public Link: string;

    constructor() {
    }

    ngOnInit() {
    }

}
