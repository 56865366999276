var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { AdManagerService, AutomationVkService, AutomationVkSlotService } from '../../../api/services';
import { CopyingConfigurator } from '../copying-configurators/copying-configurator';
import { AccountViewModel } from '../../models/view-models/account-view-model';
import { CreateCampaignFormComponent } from '../../ad-manager/components/create-campaign-form/create-campaign-form.component';
import { isNullOrUndefined } from 'util';
import { MatDialog } from '@angular/material';
import { delay, distinctUntilChanged, filter, retry } from 'rxjs/operators';
import { MatConfirmDialogComponent } from '../../ad-manager/components/modals/configrm-dialog/mat-confirm-dialog.component';
import { combineLatest } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "../../../api/services/ad-manager.service";
import * as i2 from "../../../api/services/automation-vk.service";
import * as i3 from "../../../api/services/automation-vk-slot.service";
import * as i4 from "@angular/material/dialog";
export var CopyingFormState;
(function (CopyingFormState) {
    CopyingFormState["DEFAULT_FORM"] = "DEFAULT_FORM";
    CopyingFormState["COPYING_PROCESS"] = "COPYING_PROCESS";
    CopyingFormState["COPYING_SUCCESS"] = "COPYING_SUCCESS";
    CopyingFormState["COPYING_FAILURE"] = "COPYING_FAILURE";
})(CopyingFormState || (CopyingFormState = {}));
var CopyingService = /** @class */ (function () {
    function CopyingService(adManagerService, automationVkService, automationVkSlotService, dialogService) {
        var _this = this;
        this.adManagerService = adManagerService;
        this.automationVkService = automationVkService;
        this.automationVkSlotService = automationVkSlotService;
        this.dialogService = dialogService;
        this.adsToCopying = [];
        this.state = CopyingFormState.DEFAULT_FORM;
        this.countOfCopyedAds = 0;
        this.ShowRedirectUrl = false;
        this.notCopyedAds = [];
        this.errorMessage = '';
        this.copyesWithErrors = [];
        this.ads = [];
        this.form = new FormGroup({
            accountId: new FormControl(),
            campaignId: new FormControl(),
            targetAccountId: new FormControl(),
            targetCampaignId: new FormControl(),
            adIds: new FormControl(),
            configurationMode: new FormControl('DEFAULT_COPYING'),
            costType: new FormControl(null),
            costValue: new FormControl(0),
            generateLinks: new FormControl(false),
            defaultConfiguration: new FormGroup({
                countOfCopies: new FormControl(1, [Validators.required, Validators.min(1), Validators.max(100)])
            }),
            groupsConfiguration: new FormGroup({
                groupsList: new FormControl(),
                excludeOtherGroups: new FormControl(false)
            }),
            groupsFromFileConfiguration: new FormGroup({
                groupsList: new FormControl(),
                excludeOtherGroups: new FormControl(false)
            }),
            sexAndAgeConfiguration: new FormGroup({
                ageItems: new FormArray([])
            }),
            retargetingGroupConfiguration: new FormGroup({
                retargetingGroups: new FormControl(),
                excludeOtherGroups: new FormControl()
            }),
            groupActiveFromFile: new FormGroup({
                groupActiveList: new FormControl()
            }),
            groupActive: new FormGroup({
                groupActiveList: new FormControl()
            }),
            interestCategoriesList: new FormGroup({
                interestIds: new FormControl()
            }),
            interestCategoriesCheckboxes: new FormGroup({
                interestIds: new FormControl()
            }),
            linkSettings: new FormGroup({
                linksData: new FormControl()
            })
        });
        this.form.get('targetAccountId').valueChanges
            .pipe(distinctUntilChanged())
            .subscribe(function (value) {
            if (value) {
                var _a = value.split('_').map(function (x) { return x === 'null' ? null : parseInt(x, 10); }), accountId = _a[0], clientId = _a[1];
                _this.LoadCampaigns(accountId, clientId)
                    .subscribe();
            }
        });
        combineLatest(this.form.get('targetAccountId').valueChanges, this.form.get('targetCampaignId').valueChanges)
            .pipe(filter(function (_a) {
            var accountId = _a[0], campaignId = _a[1];
            return accountId !== false || campaignId !== false;
        }))
            .subscribe(function (_a) {
            var targetAccountId = _a[0], targetCampaignId = _a[1];
            var _b = _this.form.value, accountId = _b.accountId, campaignId = _b.campaignId;
            console.log(targetAccountId, accountId, targetCampaignId, campaignId);
            console.log(targetAccountId !== accountId, targetCampaignId !== campaignId);
            console.log(targetAccountId !== accountId || targetCampaignId !== campaignId);
            _this.ShowRedirectUrl = targetAccountId === accountId || targetCampaignId === campaignId;
        });
    }
    CopyingService.prototype.reset = function () {
        this.form = new FormGroup({
            accountId: new FormControl(),
            campaignId: new FormControl(),
            targetAccountId: new FormControl(),
            targetCampaignId: new FormControl(),
            adIds: new FormControl(),
            configurationMode: new FormControl('DEFAULT_COPYING'),
            costType: new FormControl(null),
            costValue: new FormControl(0),
            generateLinks: new FormControl(false),
            defaultConfiguration: new FormGroup({
                countOfCopies: new FormControl(1)
            }),
            groupsConfiguration: new FormGroup({
                groupsList: new FormControl(),
                excludeOtherGroups: new FormControl(false)
            }),
            groupsFromFileConfiguration: new FormGroup({
                groupsList: new FormControl(),
                excludeOtherGroups: new FormControl(false)
            }),
            sexAndAgeConfiguration: new FormGroup({
                ageItems: new FormArray([])
            }),
            retargetingGroupConfiguration: new FormGroup({
                retargetingGroups: new FormControl(),
                excludeOtherGroups: new FormControl()
            }),
            groupActiveFromFile: new FormGroup({
                groupActiveList: new FormControl()
            }),
            groupActive: new FormGroup({
                groupActiveList: new FormControl()
            }),
            interestCategoriesList: new FormGroup({
                interestIds: new FormControl()
            }),
            interestCategoriesCheckboxes: new FormGroup({
                interestIds: new FormControl()
            }),
            linkSettings: new FormGroup({
                linksData: new FormControl()
            })
        });
    };
    CopyingService.prototype.addSexAndAgeGroup = function () {
        this.form.get('sexAndAgeConfiguration').get('ageItems').push(new FormGroup({
            ageFrom: new FormControl(),
            ageTo: new FormControl(),
            eachAge: new FormControl(false),
            sex: new FormControl(0)
        }));
        console.log(this.form.value, 'FORM VALUE');
    };
    CopyingService.prototype.removeSexAndAgeGroup = function (i) {
        this.form.get('sexAndAgeConfiguration').get('ageItems').removeAt(i);
    };
    CopyingService.prototype.PrepearData = function () {
        this.adsToCopying = [];
        this.adsToCopying = CopyingConfigurator.configure(this.form.value.configurationMode, this.form.value);
    };
    CopyingService.prototype.CopyAds = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.PrepearData();
                this.dialogService.open(MatConfirmDialogComponent, {
                    data: {
                        title: "\u041F\u043E\u0434\u0442\u0432\u0435\u0440\u0434\u0438\u0442\u0435 \u043A\u043E\u043F\u0438\u0440\u043E\u0432\u0430\u043D\u0438\u0435 \u043E\u0431\u044A\u044F\u0432\u043B\u0435\u043D\u0438\u0439",
                        description: "\u0421\u043A\u043E\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C \u0432\u044B\u0431\u0440\u0430\u043D\u043D\u044B\u0435 \u043E\u0431\u044A\u044F\u0432\u043B\u0435\u043D\u0438\u044F \u0432 \u043A\u043E\u043B\u043B\u0438\u0447\u0435\u0441\u0442\u0432\u0435 " + this.adsToCopying.length + " \u0448\u0442.",
                        confirmActionText: 'Скопировать',
                        rejectActionText: 'Отменить'
                    }
                })
                    .afterClosed()
                    .subscribe(function (status) { return __awaiter(_this, void 0, void 0, function () {
                    var containsErrors, _loop_1, this_1, _i, _a, ad;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                if (!status) return [3 /*break*/, 5];
                                this.state = CopyingFormState.COPYING_PROCESS;
                                containsErrors = false;
                                _loop_1 = function (ad) {
                                    var copyedAd, e_1, newError, isErrorForAdding, ad1, newAd;
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0:
                                                _a.trys.push([0, 2, , 3]);
                                                return [4 /*yield*/, this_1.adManagerService.CopyAds(ad)
                                                        .pipe(delay(1000), retry(2))
                                                        .toPromise()];
                                            case 1:
                                                copyedAd = _a.sent();
                                                this_1.countOfCopyedAds++;
                                                return [3 /*break*/, 3];
                                            case 2:
                                                e_1 = _a.sent();
                                                containsErrors = true;
                                                this_1.notCopyedAds.push(ad);
                                                newError = e_1.error;
                                                if (newError && newError.code) {
                                                    isErrorForAdding = this_1.copyesWithErrors.map(function (err) { return err.code; }).includes(newError.code);
                                                    if (isErrorForAdding) {
                                                        this_1.copyesWithErrors.push(newError);
                                                    }
                                                }
                                                ad1 = this_1.ads.find(function (x) { return x.id === ad.adIds[0]; });
                                                newAd = {
                                                    name: "\u041A\u043E\u043F\u0438\u044F " + ad.numberOfCopy + " " + ad1.name
                                                };
                                                this_1.adsWithCopyingErrors.push({
                                                    ad: newAd,
                                                    error: newError
                                                });
                                                return [3 /*break*/, 3];
                                            case 3:
                                                console.log('asd');
                                                return [2 /*return*/];
                                        }
                                    });
                                };
                                this_1 = this;
                                _i = 0, _a = this.adsToCopying;
                                _b.label = 1;
                            case 1:
                                if (!(_i < _a.length)) return [3 /*break*/, 4];
                                ad = _a[_i];
                                return [5 /*yield**/, _loop_1(ad)];
                            case 2:
                                _b.sent();
                                _b.label = 3;
                            case 3:
                                _i++;
                                return [3 /*break*/, 1];
                            case 4:
                                if (containsErrors) {
                                    this.state = CopyingFormState.COPYING_FAILURE;
                                }
                                else {
                                    this.state = CopyingFormState.COPYING_SUCCESS;
                                }
                                _b.label = 5;
                            case 5: return [2 /*return*/];
                        }
                    });
                }); });
                return [2 /*return*/];
            });
        });
    };
    CopyingService.prototype.init = function (accountId, campaignId, targetAccountId, targetCampaignId, ads, ad_format, groups, retargeting_groups, retargeting_groups_not, groupActives, interestGroups, link) {
        console.log(ad_format, groups, retargeting_groups, retargeting_groups_not, groupActives, interestGroups);
        var adIds = ads.map(function (x) { return x.id; });
        console.log({
            accountId: accountId,
            campaignId: campaignId,
            targetAccountId: targetAccountId,
            targetCampaignId: targetCampaignId
        });
        this.form.patchValue({
            accountId: accountId,
            campaignId: campaignId,
            adIds: adIds,
            groupsConfiguration: {
                groupsList: groups
            },
            retargetingGroupConfiguration: {
                retargetingGroups: retargeting_groups,
                groups_not: retargeting_groups_not
            },
            groupActive: {
                groupActiveList: groupActives
            },
            interestCategoriesList: {
                interestIds: interestGroups
            }
        });
        this.form.patchValue({
            targetAccountId: targetAccountId,
            targetCampaignId: targetCampaignId,
        }, { emitEvent: false });
        this.form.patchValue({
            linkSettings: {
                linksData: {
                    host: link
                }
            }
        }, { emitEvent: true });
        console.log({
            linkSettings: {
                linksData: {
                    host: link
                }
            }
        }, 'LINK SETTINGS');
        this.ad_format = ad_format;
        this.ads = ads;
        this.countOfCopyedAds = 0;
    };
    CopyingService.prototype.LoadCabinetData = function () {
        var _this = this;
        this.loadingAdvancedScreen = true;
        return this.LoadSlots()
            .flatMap(function () { return _this.LoadAccounts(); })
            .flatMap(function () {
            var _a = _this.form.value.targetAccountId.split('_'), accountId = _a[0], clientId = _a[1];
            return _this.LoadCampaigns(accountId, clientId);
        });
    };
    CopyingService.prototype.LoadSlots = function () {
        var _this = this;
        return this.automationVkSlotService.GetSlots()
            .do(function (slotsResponse) {
            _this.slots = slotsResponse.data;
        });
    };
    CopyingService.prototype.LoadAccounts = function () {
        var _this = this;
        return this.automationVkService.GetAccounts()
            .do(function (accountsResponse) { return __awaiter(_this, void 0, void 0, function () {
            var accounts, agencies, _i, agencies_1, account, clients, _a, clients_1, client;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        accounts = accountsResponse.data
                            .map(function (cabinet, i) { return _this.CreateNewCabinet(cabinet, i); });
                        agencies = accounts.filter(function (account) { return account.account_type === 'agency'; });
                        _i = 0, agencies_1 = agencies;
                        _b.label = 1;
                    case 1:
                        if (!(_i < agencies_1.length)) return [3 /*break*/, 4];
                        account = agencies_1[_i];
                        return [4 /*yield*/, this.LoadClients(account.account_id)];
                    case 2:
                        clients = _b.sent();
                        for (_a = 0, clients_1 = clients; _a < clients_1.length; _a++) {
                            client = clients_1[_a];
                            accounts.push(new AccountViewModel(account.account_id, client.name, account.account_role, account.account_status, 'client', this.IsAccountBlocked(account.account_id, client.id), client.id, account.priority));
                        }
                        _b.label = 3;
                    case 3:
                        _i++;
                        return [3 /*break*/, 1];
                    case 4:
                        this.accounts = accounts.filter(function (x) { return x.account_type !== 'agency'; });
                        return [2 /*return*/];
                }
            });
        }); })
            .delay(1000);
    };
    CopyingService.prototype.CreateNewCabinet = function (cabinet, i) {
        var account_id = cabinet.account_id, account_name = cabinet.account_name, access_role = cabinet.access_role, account_status = cabinet.account_status, account_type = cabinet.account_type, is_blocked = cabinet.is_blocked, client_id = cabinet.client_id;
        return new AccountViewModel(account_id, account_name, access_role, account_status, account_type, this.IsAccountBlocked(account_id, client_id), client_id, i);
    };
    CopyingService.prototype.LoadClients = function (accountId) {
        return this.automationVkService.getClients(accountId)
            .map(function (clientsResponse) { return clientsResponse.data; })
            .delay(1000)
            .toPromise();
    };
    CopyingService.prototype.LoadCampaigns = function (accountId, clientId) {
        var _this = this;
        console.log(accountId);
        return this.automationVkService.GetCampaigns({
            accountId: accountId,
            clientId: clientId
        })
            .do(function (campaignResponse) {
            console.log('Setting Campaigns');
            console.log(campaignResponse.data);
            _this.campaigns = campaignResponse.data.filter(function (campaign) { return campaign.type === _this.getType(); })
                .sort(function (a, b) { return b.id - a.id; });
            _this.form.controls.targetCampaignId.setValue(_this.campaigns[0].id);
        })
            .delay(1000);
    };
    CopyingService.prototype.OpenCreateCampaignForm = function () {
        var _this = this;
        var _a = this.form.value.targetAccountId.split('_'), accountId = _a[0], clientId = _a[1];
        this.dialogService.open(CreateCampaignFormComponent, {
            data: {
                clientId: clientId,
                type: this.getType(),
                accountId: accountId
            }
        })
            .afterClosed()
            .subscribe(function (data) {
            var _a = _this.form.value.targetAccountId.split('_'), accountId = _a[0], clientId = _a[1];
            _this.LoadCampaigns(accountId, clientId)
                .subscribe(function () {
            });
        });
    };
    CopyingService.prototype.IsAccountBlocked = function (accountId, clientId) {
        var slotWithAccount = this.slots.find(function (slot) { return slot.bindedCabinetId === accountId && slot.bindedClientId === clientId; });
        console.log("Slot for account " + accountId + " and client " + clientId + ": ", slotWithAccount);
        return isNullOrUndefined(slotWithAccount);
    };
    CopyingService.prototype.getType = function () {
        return this.ad_format === 9 ? 'promoted_posts' : 'normal';
    };
    CopyingService.prototype.ToggleAdvancedMode = function () {
        var _this = this;
        this.openedAdvancedMode = !this.openedAdvancedMode;
        if (this.openedAdvancedMode) {
            this.LoadCabinetData()
                .subscribe(function () {
                setTimeout(function () {
                    _this.loadingAdvancedScreen = false;
                }, 3000);
            });
        }
    };
    CopyingService.ngInjectableDef = i0.defineInjectable({ factory: function CopyingService_Factory() { return new CopyingService(i0.inject(i1.AdManagerService), i0.inject(i2.AutomationVkService), i0.inject(i3.AutomationVkSlotService), i0.inject(i4.MatDialog)); }, token: CopyingService, providedIn: "root" });
    return CopyingService;
}());
export { CopyingService };
