import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ChatRoutingModule } from './chat-routing.module';
import { ChatFormComponent } from './components/chat-form/chat-form.component';
import { ChatWrapperComponent } from './components/chat-wrapper/chat-wrapper.component';
import { ChatMessageComponent } from './components/chat-message/chat-message.component';
import { ChatControlPanelComponent } from './components/chat-control-panel/chat-control-panel.component';
import { ConversationComponent } from './routes/conversation/conversation.component';
import {ChatConversationMessagesService} from "./services/chat-conversation-messages.service";
import {DateFormatingPipe} from "../shared/pipes/date-formating.pipe";
import {FormsModule} from "@angular/forms";
import {SharedModule} from "../shared/shared.module";
import { ConversationsListComponent } from './routes/conversations-list/conversations-list.component';
import { ChatNotificationComponent } from './components/chat-notification/chat-notification.component';
import { ChatConversationComponent } from './components/chat-conversation/chat-conversation.component';

@NgModule({
  declarations: [ChatFormComponent, ChatWrapperComponent, ChatMessageComponent, ChatControlPanelComponent, ConversationComponent, ConversationsListComponent, ChatNotificationComponent, ChatConversationComponent],
  imports: [
    SharedModule,
    FormsModule,
    CommonModule,
    ChatRoutingModule
  ],
  exports: [
    ChatNotificationComponent
  ],
  providers: [ChatConversationMessagesService]
})
export class ChatModule { }
