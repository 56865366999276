/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./easy-ad-preview-teaser.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./easy-ad-preview-teaser.component";
var styles_EasyAdPreviewTeaserComponent = [i0.styles];
var RenderType_EasyAdPreviewTeaserComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EasyAdPreviewTeaserComponent, data: {} });
export { RenderType_EasyAdPreviewTeaserComponent as RenderType_EasyAdPreviewTeaserComponent };
function View_EasyAdPreviewTeaserComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "easy-ad-teaser-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["class", "easy-ad-teaser"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "easy-ad-teaser-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.ngIf; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.adLayout.title; _ck(_v, 4, 0, currVal_1); }); }
function View_EasyAdPreviewTeaserComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "easy-ad-teaser"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u041D\u0435\u0442 \u0438\u0437\u043E\u0431\u0440\u0430\u0436\u0435\u043D\u0438\u044F "]))], null, null); }
function View_EasyAdPreviewTeaserComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EasyAdPreviewTeaserComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["noVisual", 2]], null, 0, null, View_EasyAdPreviewTeaserComponent_3))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.GetHighestResolutionImage(); var currVal_1 = i1.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_EasyAdPreviewTeaserComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "easy-ad-teaser"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u0417\u0430\u0433\u0440\u0443\u0437\u043A\u0430... "]))], null, null); }
export function View_EasyAdPreviewTeaserComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_EasyAdPreviewTeaserComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["graphicsNotLoaded", 2]], null, 0, null, View_EasyAdPreviewTeaserComponent_4))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.graphicsLoaded; var currVal_1 = i1.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_EasyAdPreviewTeaserComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-easy-ad-preview-teaser", [], null, null, null, View_EasyAdPreviewTeaserComponent_0, RenderType_EasyAdPreviewTeaserComponent)), i1.ɵdid(1, 114688, null, 0, i3.EasyAdPreviewTeaserComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EasyAdPreviewTeaserComponentNgFactory = i1.ɵccf("app-easy-ad-preview-teaser", i3.EasyAdPreviewTeaserComponent, View_EasyAdPreviewTeaserComponent_Host_0, { graphicsLoaded: "graphicsLoaded", adLayout: "adLayout" }, {}, []);
export { EasyAdPreviewTeaserComponentNgFactory as EasyAdPreviewTeaserComponentNgFactory };
