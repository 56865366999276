/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { UserStrategyInput } from '../models/user-strategy-input';
@Injectable({
  providedIn: 'root',
})
class UserStrategyInputService extends __BaseService {
  static readonly GetAllUserStrategyInputsPath = '/apiv2/user-strategies-input';
  static readonly GetUserStrategyInputPath = '/apiv2/user-strategies-input/get-inputs';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `UserStrategyInputService.GetAllUserStrategyInputsParams` containing the following parameters:
   *
   * - `adId`:
   *
   * - `accountId`:
   *
   * @return Success
   */
  GetAllUserStrategyInputsResponse(params: UserStrategyInputService.GetAllUserStrategyInputsParams): __Observable<__StrictHttpResponse<Array<UserStrategyInput>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.adId != null) __params = __params.set('adId', params.adId.toString());
    if (params.accountId != null) __params = __params.set('accountId', params.accountId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/user-strategies-input`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<UserStrategyInput>>;
      })
    );
  }
  /**
   * @param params The `UserStrategyInputService.GetAllUserStrategyInputsParams` containing the following parameters:
   *
   * - `adId`:
   *
   * - `accountId`:
   *
   * @return Success
   */
  GetAllUserStrategyInputs(params: UserStrategyInputService.GetAllUserStrategyInputsParams): __Observable<Array<UserStrategyInput>> {
    return this.GetAllUserStrategyInputsResponse(params).pipe(
      __map(_r => _r.body as Array<UserStrategyInput>)
    );
  }

  /**
   * @param params The `UserStrategyInputService.GetUserStrategyInputParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `adId`:
   *
   * - `accountId`:
   *
   * @return Success
   */
  GetUserStrategyInputResponse(params: UserStrategyInputService.GetUserStrategyInputParams): __Observable<__StrictHttpResponse<Array<UserStrategyInput>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.id != null) __params = __params.set('id', params.id.toString());
    if (params.adId != null) __params = __params.set('adId', params.adId.toString());
    if (params.accountId != null) __params = __params.set('accountId', params.accountId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/user-strategies-input/get-inputs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<UserStrategyInput>>;
      })
    );
  }
  /**
   * @param params The `UserStrategyInputService.GetUserStrategyInputParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `adId`:
   *
   * - `accountId`:
   *
   * @return Success
   */
  GetUserStrategyInput(params: UserStrategyInputService.GetUserStrategyInputParams): __Observable<Array<UserStrategyInput>> {
    return this.GetUserStrategyInputResponse(params).pipe(
      __map(_r => _r.body as Array<UserStrategyInput>)
    );
  }
}

module UserStrategyInputService {

  /**
   * Parameters for GetAllUserStrategyInputs
   */
  export interface GetAllUserStrategyInputsParams {
    adId: number;
    accountId: number;
  }

  /**
   * Parameters for GetUserStrategyInput
   */
  export interface GetUserStrategyInputParams {
    id: number;
    adId: number;
    accountId: number;
  }
}

export { UserStrategyInputService }
