import {Injectable} from '@angular/core';
import {ID} from '@datorama/akita';
import {HttpClient, HttpParams} from '@angular/common/http';
import {AffiliateMemberStattisticsByUserStore} from './affiliate-member-stattistics-by-user.store';
import {
  AffiliateMemberStattisticsByUser,
  createAffiliateMemberStattisticsByUser
} from './affiliate-member-stattistics-by-user.model';
import {tap} from 'rxjs/operators';
import {environment} from "../../../../environments/environment";

@Injectable({providedIn: 'root'})
export class AffiliateMemberStattisticsByUserService {

  private baseUrl = `${environment.backendUri}/${environment.apiPrefix}`;

  constructor(private affiliateMemberStattisticsByUserStore: AffiliateMemberStattisticsByUserStore,
              private http: HttpClient) {
  }

  /**
   * Метод позволяет получить все данные о статистике участника партнерской программы
   * @param dateFrom - дата начала периода
   * @param dateTo - дата окончания периода
   */
  get(dateFrom: Date, dateTo: Date) {
    const params = this.GenerateQueryParams(dateFrom, dateTo);
    return this.http.get<AffiliateMemberStattisticsByUser[]>(`${this.baseUrl}/user-affiliate-statistics/by-user`, {
      params
    })
      .pipe(tap(entities => {
        const statistics = entities.map((x, i) => createAffiliateMemberStattisticsByUser(x, i))
        this.affiliateMemberStattisticsByUserStore.set(statistics);
      }));
  }

  /**
   * Формирование параметров
   * @param dateFrom
   * @param dateTo
   * @constructor
   */
  private GenerateQueryParams(dateFrom: Date, dateTo: Date) {
    const params = new HttpParams();
    params.set('dateFrom', dateFrom ? dateFrom.toString() : null);
    params.set('dateTo', dateTo ? dateTo.toString() : null);
    return params;
  }

  /**
   *
   * @param affiliateMemberStattisticsByUser
   */
  add(affiliateMemberStattisticsByUser: AffiliateMemberStattisticsByUser) {
    this.affiliateMemberStattisticsByUserStore.add(affiliateMemberStattisticsByUser);
  }

  update(id, affiliateMemberStattisticsByUser: Partial<AffiliateMemberStattisticsByUser>) {
    this.affiliateMemberStattisticsByUserStore.update(id, affiliateMemberStattisticsByUser);
  }

  remove(id: ID) {
    this.affiliateMemberStattisticsByUserStore.remove(id);
  }
}
