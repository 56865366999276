import {Component, OnInit} from '@angular/core';
import {environment} from '../../../../../environments/environment';
import {ActivatedRoute} from '@angular/router';
import {OAuthService} from '../../../../api/services/oauth.service';
import {PlatformAssignationsService} from '../../../stores/platform-assignations/platform-assignations.service';
import {PlatformAssignationsQuery} from '../../../stores/platform-assignations/platform-assignations.query';
import {AssignationPlatforms} from '../../../stores/platform-assignations/platform-assignation.model';
import {UonTravelAssignationScreenDialogComponent} from '../../assignation-dialogs/uon-travel-assignation-screen-dialog/uon-travel-assignation-screen-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {PlatformsService} from '../../../stores/platforms/platforms.service';
import {ClaimsService} from "../../../../api/services";

@Component({
  selector: 'app-uon-travel-assignation-screen',
  templateUrl: './uon-travel-assignation-screen.component.html',
  styleUrls: ['./uon-travel-assignation-screen.component.scss']
})
export class UonTravelAssignationScreenComponent implements OnInit {

  public baseUrl = environment.frontEndUrl;
  public provider = 'Token-uon-api-key';
  public assigned = true;
  public loading: boolean = false;

  // @ts-ignore
  constructor(
    private route: ActivatedRoute,
    private oauth: OAuthService,
    private dialog: MatDialog,
    private assignationService: PlatformAssignationsService,
    private assignationQuery: PlatformAssignationsQuery,
    private platformsService: PlatformsService,
    private claimsService: ClaimsService
  ) {
  }

  ngOnInit() {
    this.CheckAssignation();
    this.assignationQuery.assingnationStatus$(AssignationPlatforms.UON)
      .subscribe(data => {
        this.assigned = data;
      });
  }

  public AddVkAdCabinetLinkClick(): void {
    this.dialog.open(UonTravelAssignationScreenDialogComponent)
      .afterClosed()
      .subscribe(data => {
        this.CheckAssignation();
      });
  }

  public GetBindUrl(): string {
    return `${this.baseUrl}/${environment.apiPrefix}/OAuth/authorize/${this.provider}`;
  }

  public CheckAssignation() {
    this.loading = true;
    // setTimeout(() => {
    //   this.assignationService.assignPlatform(AssignationPlatforms.UON);
    //   this.loading = false;
    // }, 1000)
    this.claimsService.ExistsClaim(this.provider)
      .subscribe(data => {
          this.assigned = data;
          if (data) {
            this.assignationService.assignPlatform(AssignationPlatforms.UON);
          }
          this.loading = false;
        },
        err => {
          this.loading = false;
        }
      );
  }
}
