import {Component, OnInit} from '@angular/core';
import {AdFormManagerService} from '../../services/ad-form-manager/ad-form-manager.service';

@Component({
  selector: 'app-ad-promoposts-list',
  templateUrl: './ad-promoposts-list.component.html',
  styleUrls: ['./ad-promoposts-list.component.scss']
})
export class AdPromopostsListComponent implements OnInit {

  public get promoposts() {
    return this.adFormManagerService.promoposts;
  }

  public get selectedGroup() {
    return this.adFormManagerService.selectedGroup;
  }

  constructor(
    private adFormManagerService: AdFormManagerService
  ) {
  }

  ngOnInit() {
  }

}
