import {Injectable} from '@angular/core';
import {Store, StoreConfig} from '@datorama/akita';

export interface UserBalanceState {
  amount: number;
  fullBalance: number;
  updatedAt: Date;
  createdAt: Date;
  registrated: number;
  visited: number;
}

export function createInitialState(): UserBalanceState {
  return {
    amount: 0,
    fullBalance: 0,
    updatedAt: null,
    createdAt: null,
    registrated: 0,
    visited: 0
  };
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'user-balance'})
export class UserBalanceStore extends Store<UserBalanceState> {

  constructor() {
    super(createInitialState());
  }

}

