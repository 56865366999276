<app-notification-list></app-notification-list>

<app-memory-alert [id]="'automation-ads-tip'">
  Чтобы настроить <strong>управление ставками</strong> для объявлений - нажмите на кнопку <strong>+ УПРАВЛЕНИЕ
  СТАВКОЙ</strong>
</app-memory-alert>

<ngr-dg-action-bar *ngIf="!ShowEditUI">
  <div class="card">
    <div class="card-block">
      <div class="action-bar action-bar_center">
        <div class="action-bar__action action-bar__action_compact">
          <app-new-date-filter
            [period]="filterObject.period"
            [dateFrom]="filterObject.dateFrom"
            [dateTo]="filterObject.dateTo"
            (dateChanged)="ChangeDate($event)"
          ></app-new-date-filter>
          <!--<app-date-filter [date]="period" (changingDate)="ChangeDate($event)"-->
          <!--[disabled]="disabled"></app-date-filter>-->
        </div>

        <div class="action-bar__divider"></div>

        <div class="action-bar__action action-bar__action_compact">
          <ng-container *ngIf="!loadingGeneralData; else loadingYandexScreen">
            <app-analytic-services-header [provider]="'yandex-metrika'"
                                          [yandexMetrikaConnected]="IsAnalyticServiceAvaialbe(yandex)"
                                          [availabeCounter]="yandex.Counters" [availabeGoals]="yandex.Goals"
                                          [yandexSelectedCounterId]="counterId"
                                          [yandexSelectedGoalId]="goalId"
                                          (SelectCounter)="SelectCounter($event)" (SelectGoal)="SelectGoal($event)">

            </app-analytic-services-header>
          </ng-container>
          <ng-template #loadingYandexScreen>
            <div class="yandex-loading">
              <div class="yandex-loading__spinner">
                <mat-spinner diameter="18"></mat-spinner>
              </div>
              <div class="yandex-loading__message">
                Подождите, идет загрузка данных ...
              </div>
            </div>
          </ng-template>

        </div>

        <div class="action-bar__action action-bar__action_compact">
          <button class="btn btn-sm btn-success action" (click)="InitPage()">
            <span class="ion-md-refresh"></span>
            Обновить
          </button>
        </div>
      </div>
    </div>
  </div>
</ngr-dg-action-bar>

<ng-container *ngIf="ShowEditUI">
  <div class="alert alert-danger" *ngIf="CountOfErrors > 0">
    <div class="alert-items">
      <ng-container *ngFor="let fieldErrors of EditFormErrors">
        <ng-container *ngFor="let error of fieldErrors">
          <div class="alert-item static">
            <div class="alert-text">
              {{error}}
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
  <br>
  <ngr-dg-action-bar>
    <div class="fx-row">
      <div style="margin-right: 16px; font-size: 18px">Режим массового редактирования</div>
      <ng-container *ngIf="!UpdatingLoading ; else loadingScreenButton">
        <div class="actions">
          <button class="vk-standart-button vk-standart-button_ultra-sm" (click)="CheckOnCanBeCopied()" [disabled]="disabled">
            Скопировать объявления
          </button>
<!--          <button class="vk-standart-button vk-standart-button_ultra-sm" (click)="CheckOnCanBeCopied(true)">-->
<!--            Скопировать объявления-->
<!--          </button>-->
          <button class="btn btn-success btn-sm" (click)="UpdateAds()" *ngIf="editForm.valid && HasEditFormFilledField()">
            Сохранить изменения
          </button>
        </div>
      </ng-container>
      <ng-template #loadingScreenButton>
        <div style="margin-right: 16px; display: inline;">
          <mat-spinner [diameter]="20"></mat-spinner>
        </div>
        Подождите, операция выполняется!
      </ng-template>
    </div>
  </ngr-dg-action-bar>
  <ngr-dg-action-bar *ngIf="CountOfSelected() > 0">
    Объявлений выбрано: {{CountOfSelected()}}
  </ngr-dg-action-bar>
</ng-container>

<ngr-dg-action-bar>
  <div class="fx-row">
    <div class="fx-divider"></div>
    <div class="actions">
      <div class="fx-row">
        <div class="count-label">
          Выберите количество запией на странице
        </div>
        <mat-form-field  [style.width]="'50px'">
          <mat-select [(ngModel)]="countOfResults">
            <ng-container *ngFor="let size of availableSizes">
              <mat-option [value]="size.value">{{size.label}}</mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
</ngr-dg-action-bar>

<form [formGroup]="editForm">
  <ngr-data-grid
    height="80vh"

    [data]="adsViewModels"
    [filterString]="filterString"
    [loading]="loading"
    [countOfResults]="countOfResults"
    [(selectedRows)]="selectedRows"
    [showEditForm]="ShowEditUI"
    (withoutPagination)="SetWithoutPagination($event)"
    dataNotFoundMessage="Объявления не найдены."
    [pagination]="dgPagination"
    [exportId]="true"
    exportIdHeader="Id объявления"
    #dg
  >
    <ngr-dg-column
      name="selected"
      [hiddable]="false"
      [filterType]="'none'"
      [sortable]="false"
      width="13px"
    >
      <div *appDgColumnHeader>
        <input
          type="checkbox"
          [checked]="IsAllSelected"
          (change)="ToggleAll()"
        >
      </div>
      <div *ngrDgContent="let row=row">
        <input
          type="checkbox"
          [checked]="IsSelected(row)"
          (change)="ToggleSelected(row)">
      </div>
    </ngr-dg-column>
    <ngr-dg-column
      header="Название"
      name="name"
      [summaryFunction]="SummaryTitleFunction"
      [pagingSummaryFunction]="PaginSummaryTitleFunction"
      filterPlaceholderText="Найти объявление ..."
      filterType="default"
      [hiddable]="false"
      [columnHeader]="columnHeader"
      width="210px"
    >
      <div *ngrDgContent="let ad = row">
        <app-ad-meta-info
          [showLink]="showLink"
          [showMin]="showMin"
          [showUtms]="showUtms"
          [ad]="ad"
          [showCopyingMessage]="ShowEditUI"
          [isImpossibleCopyingMessageVisible]="!disabled"
          *ngIf="ad"
        ></app-ad-meta-info>
      </div>
      <ng-template #columnHeader>
        <div class="cell-actions">
          <div class="preview-option-list">
            <div class="preview-option-list__item">
              <mat-checkbox [ngModel]="showMin" (ngModelChange)="OnChangeShowMin($event)"
                            [ngModelOptions]="{standalone: true}" title="Показывать мииатюры">
                <span class="preview-option-icon ion-md-images"></span>
              </mat-checkbox>
            </div>

            <div class="preview-option-list__item">
              <mat-checkbox [ngModel]="showLink" (ngModelChange)="OnChangeShowLink($event)"
                            [ngModelOptions]="{standalone: true}" title="Показывать ссылки">
                <span class="preview-option-icon ion-ios-link"></span>
              </mat-checkbox>
            </div>

            <div class="preview-option-list__item">
              <mat-checkbox [ngModel]="showUtms" (ngModelChange)="OnChangeShowUtms($event)"
                            [ngModelOptions]="{standalone: true}" title="Показывать UTM-метки">
                <span class="preview-option-icon preview-option-icon_text-logo">UTM</span>
              </mat-checkbox>
            </div>
          </div>
        </div>
      </ng-template>
    </ngr-dg-column>
    <ngr-dg-column
      header="Аудитория"
      name="count"
      align="right"
      filterType="between"
      [formatFunction]="NumberFunction"
    >

    </ngr-dg-column>
    <ngr-dg-column
      header="Статус"
      name="status"
      align="center"
      filterType="select"
      width="100px"
      [filterOptions]="statusFilterOptions"
    >
      <div *ngrDgContent="let ad=row; let status=cell;">
        <ng-container *ngIf="ad.approved !== 1; else moderation">
          <clr-dropdown>
            <button class="ad-status-trigger" clrDropdownTrigger [disabled]="ShowEditUI">
              <ng-container [ngSwitch]="status">
                <ng-container *ngSwitchCase="1">
                  <i class="ion-md-play" [style.color]="!ShowEditUI ? '#00ff00' : '#cecece'"></i>
                </ng-container>
                <ng-container *ngSwitchCase="0">
                  <i class="ion-md-square" [style.color]="!ShowEditUI ? '#ff0000' : '#cecece'"></i>
                </ng-container>
                <ng-container *ngSwitchCase="2">
                  <div class="ad_status_wait"></div>
                </ng-container>
              </ng-container>
            </button>
            <clr-dropdown-menu clrPosition="bottom">
              <button clrDropdownItem (click)="ToggleAd(ad.id, false)">
                <i class="ion-md-square" style="color: #ff0000"></i>
                Остановить
              </button>
              <button clrDropdownItem (click)="ToggleAd(ad.id, true)">
                <i class="ion-md-play" style="color: #00ff00"></i>
                Запустить
              </button>
            </clr-dropdown-menu>
          </clr-dropdown>
        </ng-container>
        <ng-template #moderation>
          <ng-container *ngIf="ad.approved === 3; else moderationWait">
            <mat-icon>close</mat-icon>
          </ng-container>
          <ng-template #moderationWait>
            <div class="ad_status_wait" matTooltip="Ожидает модерации"></div>
          </ng-template>
        </ng-template>
      </div>
      <div *appEditableColumn>
        <clr-dropdown>
          <button class="ad-status-trigger" clrDropdownTrigger>
            <ng-container [ngSwitch]="editForm.controls.status.value">
              <ng-container *ngSwitchCase="1">
                <i class="ion-md-play" style="color: #00ff00"></i>
              </ng-container>
              <ng-container *ngSwitchCase="0">
                <i class="ion-md-square" style="color: #ff0000"></i>
              </ng-container>
              <ng-container *ngSwitchCase="2">
                <div class="ad_status_wait"></div>
              </ng-container>
              <ng-container *ngSwitchDefault>
                <i class="ion-md-square" style="color: #ff0000"></i>
              </ng-container>
            </ng-container>
          </button>
          <clr-dropdown-menu clrPosition="bottom">
            <button clrDropdownItem (click)="SetStatusMultiple(0)">
              <i class="ion-md-square" style="color: #ff0000"></i>
              Остановить
            </button>
            <button clrDropdownItem (click)="SetStatusMultiple(1)">
              <i class="ion-md-play" style="color: #00ff00"></i>
              Запустить
            </button>
          </clr-dropdown-menu>
        </clr-dropdown>
      </div>
    </ngr-dg-column>
    <ngr-dg-column
      header="Управление ставками"
      [sortable]="false"
    >
      <div *ngrDgContent="let ad = row">
        <ng-container *ngIf="IsStrategiesLoaded; else loadingScreen">
          <ng-container *ngIf="ad.autobidding === 1; else manualBidding">
            Авто-управлется на стороне ВКонтакте
          </ng-container>
          <ng-template #manualBidding>
            <app-strategy-settings
            [userStrategy]="ad.strategy"
            [accountId]="accountId"
            [clientId]="clientId || 0"
            [adId]="ad.id"
            [ad]="ad"
            [displayFunctions]="displayFunctions"
            [disabled]="ShowEditUI"

            (OnToggleStrategyActive)="HandleToggleStrategyActive($event)"
            (OnCreateStrategy)="HandleCreateStrategy($event)"
            (OnUpdateStrategy)="HandleUpdateStrategy($event)"
            (OnRemoveStrategy)="HandleRemoveStrategy($event)"
            (OnEditStrategy)="EditStrategyInit([$event])"
          ></app-strategy-settings>
          </ng-template>
        </ng-container>
        <ng-template #loadingScreen>
          <div class="spinner spinner-inline"></div>
        </ng-template>
      </div>
      <div *appEditableColumn>
        <div>
          <ng-container *ngIf="ShowMultipleCreateStrategyButton && !SelectedAdsHaveStrategies(); else CannotBeShowedMultipleEditButtonTemplate;">
            <button class="btn btn-sm btn-outline-primary" style="width: 100%;" (click)="HandleEditStrategy($event)">
              + Управление ставками
            </button>
          </ng-container>
          <ng-template #CannotBeShowedMultipleEditButtonTemplate>
            <ng-container *ngIf="!ShowMultipleCreateStrategyButton">
              Для редактирования "Управления ставками" <br>
              вам необходимо выбрать объявления с<br>
              одинаковым типом оплаты (CPC или CPM)
            </ng-container>
            <br>
            <ng-container *ngIf="SelectedAdsHaveStrategies()">
              Для создания способа управления ставками <br>
              вам необходимо выбрать объявления<br>
              у которых нет способов управления
            </ng-container>
          </ng-template>
          <br>
          <div>
            <ng-container *ngIf="HasSelectedStrategy()">
              <button class="btn btn-success btn-sm" (click)="ToggleStrategiesMany(!StrategyStatusStarted())"
                      *ngIf="IsUniforms()"
                      [ngClass]="{
              'btn-success': StrategyStatusStarted() === false,
              'btn-warning': StrategyStatusStarted() === true
              }">
                <ng-container *ngIf="StrategyStatusStarted() === true; else strategiesStopped">
                  Остановить
                </ng-container>
                <ng-template #strategiesStopped>
                  Запустить
                </ng-template>
              </button>
              <button class="btn btn-primary-outline btn-sm" (click)="EditStrategyInit(selected)" [disabled]="!ShowMultipleEditStrategyButton">
                Редактировать
              </button>
              <button class="btn btn-warning-outline btn-sm" (click)="RemoveStrategiesMany()" style="margin-right: 0;">
                Удалить
              </button>
            </ng-container>
          </div>
          <!--{{GetUserStrategy().inputs | json}}-->

          <!--</ng-container>-->
        </div>
        <!--<div fxLayout="row">-->
        <!--button.btn.btn-success-->
        <!--</div>-->

        <!--<ng-container *ngIf="editForm.controls.strategy.value">-->
        <!--&lt;!&ndash;{{editForm.controls.strategy.value | json}}&ndash;&gt;-->
        <!--<ng-container *ngFor="let strategyInput of GetMultipleStrategiesInputs() ">-->
        <!--<div>{{strategyInput.key}}: {{strategyInput.value}}</div>-->
        <!--</ng-container>-->

        <!--</ng-container>-->
        <!--<app-strategy-params-->
        <!--[displayFunctions]="displayFunctions"-->
        <!--[userStrategyInputs]="GetMultipleStrategiesInputs();"-->
        <!--&gt;-->

        <!--</app-strategy-params>-->
        <!--<app-strategy-settings-->
        <!--[userStrategy]="ad.strategy"-->
        <!--[accountId]="accountId"-->
        <!--[clientId]="clientId || 0"-->
        <!--[adId]="ad.id"-->
        <!--[ad]="ad"-->
        <!--[displayFunctions]="displayFunctions"-->

        <!--(OnToggleStrategyActive)="HandleToggleStrategyActive($event)"-->
        <!--(OnCreateStrategy)="HandleCreateStrategy($event)"-->
        <!--(OnUpdateStrategy)="HandleUpdateStrategy($event)"-->
        <!--(OnRemoveStrategy)="HandleRemoveStrategy($event)"-->
        <!--&gt;</app-strategy-settings>-->
      </div>
    </ngr-dg-column>
    <ngr-dg-column
      name="price"
      align="right"
      [formatFunction]="CurrencyFunction"
      [header]="CostTypeHeader"
      filterType="between"
    >
<!--      <ng-template #cpmHeader>-->
<!--        <ng-container [ngSwitch]="CostTypeMode">-->
<!--          <ng-container *ngSwitchCase="CostTypeModes.SHARE_COST_TYPE_MODE">Цена</ng-container>-->
<!--          <ng-container *ngSwitchCase="CostTypeModes.CPC_ONLY_MODE">CPC</ng-container>-->
<!--          <ng-container *ngSwitchCase="CostTypeModes.CPM_ONLY_MODE">CPM</ng-container>-->
<!--          <ng-container *ngSwitchDefault>-->
<!--            <mat-spinner diameter="18"></mat-spinner>-->
<!--          </ng-container>-->
<!--        </ng-container>-->
<!--      </ng-template>-->
      <div *appEditableColumn>
        <ng-container [ngSwitch]="SelectedCostTypeMode">
          <ng-container *ngSwitchCase="CostTypeModes.CPM_ONLY_MODE">
            <input type="number" formControlName="cpm" class="vk-input vk-input_full-width">
          </ng-container>
          <ng-container *ngSwitchCase="CostTypeModes.CPC_ONLY_MODE">
            <input type="number" formControlName="cpc" class="vk-input vk-input_full-width">
          </ng-container>
          <ng-container *ngSwitchCase="CostTypeModes.SHARE_COST_TYPE_MODE">
            Для редактирования CPM или CPC <br>
            вам необходимо выбрать объявления с<br>
            одинаковым типом оплаты (CPC или CPM)
          </ng-container>
        </ng-container>
      </div>
      <div *ngrDgContent="let ad = row">
        <app-ad-cost
          [ad]="ad"
          [costTypeMode]="CostTypeMode"
        ></app-ad-cost>
      </div>
    </ngr-dg-column>
    <ngr-dg-column
      header="Дневной лимит"
      name="day_limit"
      align="right"
      [formatFunction]="CurrencyFunction"
      filterType="between"
    >
      <!--<ng-template let-day_limit="cell">-->
      <!--{{day_limit | currency : " Руб."}}-->
      <!--</ng-template>-->
      <div *appEditableColumn>
        <input type="number" formControlName="day_limit" class="vk-input vk-input_full-width">
      </div>
    </ngr-dg-column>
    <ngr-dg-column
      header="Общий лимит"
      name="all_limit"
      align="right"
      [formatFunction]="CurrencyFunction"
      filterType="between"
    >
      <!--<ng-template let-all_limit="cell">-->
      <!--{{all_limit | currency : " Руб."}}-->
      <!--</ng-template>-->
      <div *appEditableColumn>
        <input type="number" formControlName="all_limit" class="vk-input vk-input_full-width">
      </div>
    </ngr-dg-column>

    <ng-container *ngIf="!ShowEditUI">
      <ngr-dg-column
        header="Потрачено"
        name="spent"
        align="right"
        [summaryFunction]="SummaryFunction"
        [formatFunction]="CurrencyFunction"
        filterType="between"
      >
        <!--<ng-template let-spent="cell">-->
        <!--{{spent | currency : " Руб."}}-->
        <!--</ng-template>-->
      </ngr-dg-column>

      <ngr-dg-column
        header="Показано"
        name="impressions"
        align="right"
        [summaryFunction]="SummaryFunction"
        [formatFunction]="NumberFunction"
        filterType="between"
      >
        <!--<ng-template let-impressions="cell">-->
        <!--{{impressions | number}}-->
        <!--</ng-template>-->
      </ngr-dg-column>

      <ngr-dg-column
        header="Переходы"
        name="clicks"
        align="right"
        [summaryFunction]="SummaryFunction"
        [formatFunction]="NumberFunction"
        filterType="between"
      >
        <!--<ng-template let-clicks="cell">-->
        <!--{{clicks | number}}-->
        <!--</ng-template>-->
      </ngr-dg-column>

      <ngr-dg-column
        header="CTR"
        name="ctr"
        align="right"
        [summaryFunction]="CtrSummaryFunction"
        [formatFunction]="EcpcFormatFunction"
        filterType="between"
      >
        <!--<ng-template let-ctr="cell">-->
        <!--{{ctr | number:'0.1-2'}} %-->
        <!--</ng-template>-->
      </ngr-dg-column>

      <ngr-dg-column
        header="eCPC"
        name="ecpc"
        align="right"
        [summaryFunction]="EcpcSummaryFunction"
        [formatFunction]="CurrencyFunction"
        filterType="between"
      >
        <!--<ng-template let-ecpc="cell">-->
        <!--{{ecpc | currency : 'Руб.'}}-->
        <!--</ng-template>-->
      </ngr-dg-column>

      <ngr-dg-column
        header="Охват"
        name="reach"
        align="right"
        [summaryFunction]="SummaryFunction"
        [formatFunction]="NumberFunction"
        filterType="between"
      >
      </ngr-dg-column>

      <ngr-dg-column
        header="Процент охваченной аудитории"
        name="auditoryPercent"
        align="right"
        [summaryFunction]="SummaryFunction"
        [formatFunction]="PercentFunction"
        filterType="between"
        *ngIf="IsPromopostCampaign"
      >
        <div *appDgColumnHeader>
          Процент охваченной аудитории
          <app-generic-tooltip [header]="'(?)'" [text]="PercentAuditoryTooltipText" [icon]="null"></app-generic-tooltip>
        </div>
        <div *ngrDgContent="let auditory = cell">
          <ng-container *ngIf="auditory === null; else cellValue">
            <div class="spinner spinner-inline"></div>
          </ng-container>
          <ng-template #cellValue>
            {{auditory | number}} %
          </ng-template>
        </div>
      </ngr-dg-column>

      <ngr-dg-column
        header="Вступившие"
        name="join_rate"
        align="right"
        [summaryFunction]="SummaryFunction"
        [formatFunction]="NumberFunction"
        filterType="between"
        *ngIf="(showGroupJoinData)"
      >
      </ngr-dg-column>

      <ngr-dg-column
        header="Цена подписчика"
        name="pricing_subscribe"
        align="right"
        [summaryFunction]="SummaryFunction"
        [formatFunction]="CurrencyFunction"
        filterType="between"
        *ngIf="(showGroupJoinData)"
      >
      </ngr-dg-column>

      <ngr-dg-column
        header="Яндекс  Достигнуто целей"
        name="yandex_reched_goals"
        align="right"
        [summaryFunction]="SummaryFunction"
        [formatFunction]="NumberFunction"
        filterType="between"
        [loading]="IsYandexMetrikaLoading"
        *ngIf="goalId"
      >
      </ngr-dg-column>

      <ngr-dg-column
        header="Яндекс  Стоимость цели"
        name="yandex_cpa"
        align="right"
        [summaryFunction]="AvgSummaryFunction"
        [formatFunction]="CurrencyFunction"
        filterType="between"
        [loading]="IsYandexMetrikaLoading"
        *ngIf="goalId"
      >
      </ngr-dg-column>

      <ngr-dg-column
        header="Яндекс  CR переходов в цель"
        name="yandex_cr_from_click"
        align="right"
        [summaryFunction]="AvgSummaryFunction"
        [formatFunction]="PercentFunction"
        filterType="between"
        [loading]="IsYandexMetrikaLoading"
        *ngIf="goalId"
      ></ngr-dg-column>

      <ngr-dg-column
        header="Яндекс  CR просмотр в цель"
        name="yandex_cr_from_view"
        align="right"
        [summaryFunction]="AvgSummaryFunction"
        [formatFunction]="CrFromViewFunction"
        filterType="between"
        [loading]="IsYandexMetrikaLoading"
        *ngIf="goalId"
      >
      </ngr-dg-column>

      <ngr-dg-column
        header="Яндекс  Глубина просмотра"
        name="yandex_page_depth"
        align="right"
        [summaryFunction]="AvgSummaryFunction"
        [formatFunction]="NumberFunction"
        filterType="between"
        [loading]="IsYandexMetrikaLoading"
        *ngIf="goalId"
      >
      </ngr-dg-column>

      <ngr-dg-column
        header="Яндекс  Время на сайте"
        name="yandex_average_duration"
        align="right"
        [summaryFunction]="AvgSummaryFunction"
        [formatFunction]="YandexTime"
        [loading]="IsYandexMetrikaLoading"
        *ngIf="goalId"
      >
      </ngr-dg-column>
    </ng-container>
    <ngr-dg-pagination
      [(currentPage)]="dg.currentPage"
      (currentPageChange)="dg.OnPageChange($event)"
      [countOfPages]="dg.countOfPages"
      #dgPagination
    ></ngr-dg-pagination>
  </ngr-data-grid>
</form>

<div class="action-bar">
  <!--<ngr-dg-pagination-->
  <!--[(currentPage)]="dg.currentPage"-->
  <!--(currentPageChange)="dg.OnPageChange($event)"-->
  <!--[countOfPages]="dg.countOfPages"-->
  <!--#dgPagination-->
  <!--*ngIf="dg.countOfPages > 1"-->
  <!--&gt;</ngr-dg-pagination>-->
  <div class="action-bar__divider"></div>
  <div class="action-bar__action">
    <button class="btn btn-sm btn-primary action action_margin" (click)="dg.ToggleSidebar()" style="margin-right: 16px;">
      <span class="ion-md-settings"></span>
      Настройки таблицы
    </button>
    <button class="btn btn-sm btn-primary action" (click)="dg.ExportToExcel()">
      <span class="ion-md-download"></span>
      Экспортировать в excel
    </button>
  </div>
</div>

<div class="fx-row">
  <clr-modal [(clrModalOpen)]="BidManagmentModalOpened" [clrModalSize]="'md'" *ngIf="strategies">
    <div class="modal-title">
      <div class="fx-row">
        Управление ставкой
        <div class="fx-divider"></div>
        <!--TODO Вернуть инструкцию, несмотря на то, что оно вызывает баг-->
        <a class="btn btn-sm btn-outline-success instruction-button" (click)="ToggleInstructions()"
           *ngIf="StrategyHelpVisible">Инструкция по настройке</a>
      </div>
    </div>
    <div class="modal-body">
      <app-strategy-creation-form
        [strategies]="strategies"
        [ads]="SelectedGroups"
        [bidRanges]="bidRanges"
        [strategyCreated]="strategyCreateRequest"
        [userStrategies]="selectedUserStrategies"
        (onStrategySelected)="StrategySelected($event)"
        (onStrategySave)="CreateStrategies($event)"
        (onStartButtonStartNowPress)="StartStrategyNow();StartStrategyLater();"
        (onStartButtonStartLaterPress)="StartStrategyLater()"
        (onEditedStrategySave)="SendUserStrategiesEditSpecification($event)"
      >
      </app-strategy-creation-form>
      <!--<div class="vk-container">-->
        <!--<ng-container *ngIf="!IsStrategyTypeSelected; else selectedStrategyTitle">-->
          <!--<strong>Выберите способ управления ставкой:</strong>-->
        <!--</ng-container>-->
        <!--<ng-template #selectedStrategyTitle>-->
          <!--Способ управления: <strong>{{GetSelectedStrategyType()?.title}}</strong>-->
        <!--</ng-template>-->
      <!--</div>-->
      <!--<div class="alert alert-danger" *ngIf="errors.length > 0">-->
        <!--<div class="alert-items">-->
          <!--<div class="alert-item static" *ngFor="let error of errors">-->
            <!--<div class="alert-icon-wrapper">-->
              <!--<clr-icon class="alert-icon" shape="exclamation-circle"></clr-icon>-->
            <!--</div>-->
            <!--<span class="alert-text">{{error}}</span>-->
          <!--</div>-->
        <!--</div>-->
      <!--</div>-->
      <!--<ng-container *ngIf="!IsStrategyTypeSelected && !strategiesUpdate">-->
        <!--<mat-radio-group [(ngModel)]="strategyType">-->
          <!--<ng-container *ngFor="let strategy of displayedStrategies">-->
            <!--<div class="vk-container vk-container_double-offset">-->
              <!--<div class="vk-container">-->
                <!--<mat-radio-button [value]="strategy.code" color="primary">-->
                  <!--<strong>{{strategy.title}}</strong>-->
                <!--</mat-radio-button>-->
              <!--</div>-->
              <!--<div class="vk-container">-->
                <!--<ng-container *ngIf="!GetStrategyDescriptionStatus(strategy.id)">-->
                  <!--<span [innerHtml]="strategy.descriptionPreview"></span>-->
                  <!--<span class="strategy-description-toggle" (click)="ToggleStrategyDescription(strategy.id, true)">Подробнее</span>-->
                <!--</ng-container>-->
                <!--<ng-container *ngIf="GetStrategyDescriptionStatus(strategy.id)">-->
                  <!--<span [innerHtml]="strategy.description"></span>-->
                  <!--<span class="strategy-description-toggle" (click)="ToggleStrategyDescription(strategy.id, false)">Свернуть</span>-->
                <!--</ng-container>-->
              <!--</div>-->
            <!--</div>-->
          <!--</ng-container>-->
        <!--</mat-radio-group>-->
      <!--</ng-container>-->
      <!--<div class="fx-row">-->
        <!--<button class="btn btn-danger btn-link" (click)="ToggleSelectedStrategy(false)"-->
                <!--*ngIf="IsStrategyTypeSelected && IsCreatingStrategy">Выбрать другой способ-->
        <!--</button>-->
        <!--&lt;!&ndash;<button class="btn btn-danger btn-link" (click)="ToggleInstructions()" *ngIf="IsStrategyTypeSelected && IsCreatingStrategy && strategyType === 'BID_REACH_CONTROL'">инструкция</button>&ndash;&gt;-->
        <!--<div class="fx-divider"></div>-->
        <!--<button class="btn btn-primary" (click)="ToggleSelectedStrategy(true)" [disabled]="!strategyType"-->
                <!--*ngIf="!IsStrategyTypeSelected">Выбрать способ-->
        <!--</button>-->
      <!--</div>-->

      <!--<ng-container *ngIf="IsStrategyTypeSelected">-->
        <!--<div>-->
          <!--<strong>Укажите настройки управления ставкой:</strong>-->
        <!--</div>-->
        <!--<app-dynamic-form-->
          <!--[inputs]="inputs[strategyType]"-->

          <!--(Successed)="SuccessHandle($event)"-->
          <!--(Canceled)="CancelHandle()"-->

        <!--&gt;</app-dynamic-form>-->
        <!--<button class="vk-standart-button vk-standart-button_full-width" (click)="SaveStrategy()">Сохранить</button>-->
      <!--</ng-container>-->
    </div>
  </clr-modal>

  <div class="sidebar-instructions" *ngIf="instructionsOpened">
    <button class="btn btn-sm" (click)="ToggleInstructions()"> Закрыть</button>
    <app-instructions></app-instructions>
  </div>
</div>

<clr-modal [(clrModalOpen)]="CreatedStrategyOpened" [clrModalSize]="'md'">
  <div class="modal-title">
    Управление ставкой настроено
  </div>
  <div class="modal-body">
    <div>
      Выбраны следующие параметры:
    </div>
    <app-strategy-params
      [userStrategyInputs]="CreatedStrategyInputs"
      [displayFunctions]="displayFunctions"
    ></app-strategy-params>
  </div>
  <div class="modal-footer">
    <button class="btn btn-success" (click)="StartStrategyNow()">Запустить сейчас</button>
    <button class="btn btn-primary" (click)="StartStrategyLater()">Запустить позже</button>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="confirmEditDialogOpened">
  <div class="modal-title">Подтвердите изменение</div>
  <div class="modal-body">Вы действительно хотите внести изменения?</div>
  <div class="modal-footer">
    <div class="fx-row">
      <div class="fx-divider"></div>
      <button class="btn btn-success btn-sm" (click)="OnEditConfirm()">Изменить</button>
      <button class="btn btn-danger btn-sm" (click)="OnEditReject()">Отменить</button>
    </div>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="confirmRemoveDialogOpened">
  <div class="modal-title">Подтвердите удаление</div>
  <div class="modal-body">Вы действительно хотите прекратить управление ставкой для данного объявления?</div>
  <div class="modal-footer">
    <div class="fx-row">
      <div class="fx-divider"></div>
      <button class="btn btn-success btn-sm" (click)="OnRemoveConfirm()">Удалить</button>
      <button class="btn btn-danger btn-sm" (click)="OnRemoveReject()">Отменить</button>
    </div>
  </div>
</clr-modal>
