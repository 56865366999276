import {Pipe, PipeTransform} from '@angular/core';
import {isNullOrUndefined} from 'util';

@Pipe({
  name: 'nameShorter'
})
export class NameShorterPipe implements PipeTransform {
  private readonly defaultTrailingString: string = '...';

  transform(value: string, maxLength: number, trailingString?: string): string {
    if (isNullOrUndefined(trailingString)) {
      trailingString = this.defaultTrailingString;
    }

    const maxValueLength = maxLength - trailingString.length;

    if (value && value.length > maxLength) {
      value = `${value.substr(0, maxValueLength)}${trailingString}`;
    }

    return value;
  }

}
