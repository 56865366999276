import { AfterViewInit, OnInit } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { PostificatePipe } from '../../../../../../shared/pipes/postificate.pipe';
var PromopostFormLiteComponent = /** @class */ (function () {
    function PromopostFormLiteComponent(controlContainer) {
        this.controlContainer = controlContainer;
        this.labelWidth = '140px';
        this.controlsWidth = '256px';
    }
    Object.defineProperty(PromopostFormLiteComponent.prototype, "TextAreaDefaultHeight", {
        get: function () {
            return (this.ShowLinkUrl) ? '324px' : '368px';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PromopostFormLiteComponent.prototype, "ShowLinkUrl", {
        get: function () {
            return this.adTargetResource === 'web_site';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PromopostFormLiteComponent.prototype, "selectedGroup", {
        get: function () {
            var _this = this;
            return (this.groups) ? this.groups.find(function (group) { return group.id === parseInt(_this.controlContainer.control.get('groupId').value, 10); }) : null;
        },
        enumerable: true,
        configurable: true
    });
    PromopostFormLiteComponent.prototype.ngOnInit = function () {
    };
    PromopostFormLiteComponent.prototype.ngAfterViewInit = function () {
    };
    PromopostFormLiteComponent.prototype.OnSaveLinkTitle = function (linkTitle) {
        this.controlContainer.control.get('linkTitle')
            .setValue(linkTitle);
    };
    PromopostFormLiteComponent.prototype.OnSelectImage = function (image) {
        this.controlContainer.control.get('image')
            .setValue(image);
    };
    PromopostFormLiteComponent.prototype.StartImageErrorTimer = function () {
        var _this = this;
        setTimeout(function () {
            var imageControl = _this.controlContainer.control.get('image');
            imageControl.markAsDirty();
        }, 10000);
    };
    PromopostFormLiteComponent.prototype.CountOfOpenedGroups = function () {
        var countOfOpenedGroups = 0;
        if (this.groups && this.groups.length > 0) {
            var openedGroups = this.groups.filter(function (group) { return group.is_closed === 0; });
            countOfOpenedGroups = openedGroups.length;
        }
        return countOfOpenedGroups;
    };
    PromopostFormLiteComponent.prototype.getEmojjiContent = function () {
        return new PostificatePipe().transform('Внимание! Рекламная запись должна содержать не более 6 эмодзи. Например: 🔥😏📅💃💪🏻🏻👁😱😈 или другие.');
    };
    return PromopostFormLiteComponent;
}());
export { PromopostFormLiteComponent };
