var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { map as __map, filter as __filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../api-configuration";
import * as i2 from "@angular/common/http";
var UOnTravelService = /** @class */ (function (_super) {
    __extends(UOnTravelService, _super);
    function UOnTravelService(config, http) {
        return _super.call(this, config, http) || this;
    }
    UOnTravelService.prototype.Apiv2UOnTravelLeadStatusesGetResponse = function () {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/u-on-travel/lead-statuses", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    UOnTravelService.prototype.Apiv2UOnTravelLeadStatusesGet = function () {
        return this.Apiv2UOnTravelLeadStatusesGetResponse().pipe(__map(function (_r) { return _r.body; }));
    };
    UOnTravelService.prototype.Apiv2UOnTravelUserLabelsGetResponse = function () {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/u-on-travel/user-labels", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    UOnTravelService.prototype.Apiv2UOnTravelUserLabelsGet = function () {
        return this.Apiv2UOnTravelUserLabelsGetResponse().pipe(__map(function (_r) { return _r.body; }));
    };
    UOnTravelService.prototype.Apiv2UOnTravelRequestStatusesGetResponse = function () {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/u-on-travel/request-statuses", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    UOnTravelService.prototype.Apiv2UOnTravelRequestStatusesGet = function () {
        return this.Apiv2UOnTravelRequestStatusesGetResponse().pipe(__map(function (_r) { return _r.body; }));
    };
    UOnTravelService.prototype.Apiv2UOnTravelManagersGetResponse = function () {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/u-on-travel/managers", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    UOnTravelService.prototype.Apiv2UOnTravelManagersGet = function () {
        return this.Apiv2UOnTravelManagersGetResponse().pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param data undefined
     */
    UOnTravelService.prototype.Apiv2UOnTravelAuthorizePostResponse = function (data) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = data;
        var req = new HttpRequest('POST', this.rootUrl + "/apiv2/u-on-travel/authorize", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param data undefined
     */
    UOnTravelService.prototype.Apiv2UOnTravelAuthorizePost = function (data) {
        return this.Apiv2UOnTravelAuthorizePostResponse(data).pipe(__map(function (_r) { return _r.body; }));
    };
    UOnTravelService.Apiv2UOnTravelLeadStatusesGetPath = '/apiv2/u-on-travel/lead-statuses';
    UOnTravelService.Apiv2UOnTravelUserLabelsGetPath = '/apiv2/u-on-travel/user-labels';
    UOnTravelService.Apiv2UOnTravelRequestStatusesGetPath = '/apiv2/u-on-travel/request-statuses';
    UOnTravelService.Apiv2UOnTravelManagersGetPath = '/apiv2/u-on-travel/managers';
    UOnTravelService.Apiv2UOnTravelAuthorizePostPath = '/apiv2/u-on-travel/authorize';
    UOnTravelService.ngInjectableDef = i0.defineInjectable({ factory: function UOnTravelService_Factory() { return new UOnTravelService(i0.inject(i1.ApiConfiguration), i0.inject(i2.HttpClient)); }, token: UOnTravelService, providedIn: "root" });
    return UOnTravelService;
}(__BaseService));
export { UOnTravelService };
