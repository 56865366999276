/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./comment-view.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./comment-view.component";
var styles_CommentViewComponent = [i0.styles];
var RenderType_CommentViewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CommentViewComponent, data: {} });
export { RenderType_CommentViewComponent as RenderType_CommentViewComponent };
export function View_CommentViewComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 12, "div", [["class", "card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "card-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["class", "fx-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "comment__author"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [["class", "fx-divider"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "comment__created-at"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), i1.ɵppd(9, 2), (_l()(), i1.ɵeld(10, 0, null, null, 3, "div", [["class", "card-block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [["class", "comment"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "div", [["class", "comment__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.comment == null) ? null : ((_co.comment.author == null) ? null : _co.comment.author.email)); _ck(_v, 5, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 8, 0, _ck(_v, 9, 0, i1.ɵnov(_v, 0), _co.comment.createdAt, "medium")); _ck(_v, 8, 0, currVal_1); var currVal_2 = _co.comment.text; _ck(_v, 13, 0, currVal_2); }); }
export function View_CommentViewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-comment-view", [], null, null, null, View_CommentViewComponent_0, RenderType_CommentViewComponent)), i1.ɵdid(1, 114688, null, 0, i3.CommentViewComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CommentViewComponentNgFactory = i1.ɵccf("app-comment-view", i3.CommentViewComponent, View_CommentViewComponent_Host_0, { comment: "comment" }, {}, []);
export { CommentViewComponentNgFactory as CommentViewComponentNgFactory };
