import { ExportConfiguratorService } from '../platforms-module/shared-crm/services/export-configurator.service';
import { CrmImportConfiguratorService } from '../platforms-module/shared-crm/services/crm-import-configurator.service';
import { AdNetworkImportConfiguratorService } from '../platforms-module/shared-ad-network/services/ad-network-import-configurator.service';
import { AdNetworkExportConfiguratorService } from '../platforms-module/shared-ad-network/services/ad-network-export-configurator.service';
import { PlatformsTypes } from '../platforms-types';
import { MoiSkladExportService } from '../platforms-module/moi-sklad/services/moi-sklad-export.service';
import { FormMessagesService } from "../services/form-messages/form-messages.service";
import * as i0 from "@angular/core";
import * as i1 from "../platforms-module/shared-crm/services/export-configurator.service";
import * as i2 from "../platforms-module/shared-crm/services/crm-import-configurator.service";
import * as i3 from "../platforms-module/shared-ad-network/services/ad-network-import-configurator.service";
import * as i4 from "../platforms-module/shared-ad-network/services/ad-network-export-configurator.service";
import * as i5 from "../platforms-module/moi-sklad/services/moi-sklad-export.service";
import * as i6 from "../services/form-messages/form-messages.service";
var ConfiguratorService = /** @class */ (function () {
    function ConfiguratorService(crmExport, crmImport, adNetworkImport, adNetworkExport, moiSkladExportService, moiSkladImportService, formsMessagesService) {
        this.crmExport = crmExport;
        this.crmImport = crmImport;
        this.adNetworkImport = adNetworkImport;
        this.adNetworkExport = adNetworkExport;
        this.moiSkladExportService = moiSkladExportService;
        this.moiSkladImportService = moiSkladImportService;
        this.formsMessagesService = formsMessagesService;
    }
    ConfiguratorService.prototype.configure = function (type) {
        switch (type) {
            case PlatformsTypes.BITRIX_EXPORT:
            case PlatformsTypes.AMO_CRM_EXPORT:
            case PlatformsTypes.MEGAPLAN_EXPORT:
            case PlatformsTypes.UON_EXPORT:
            case PlatformsTypes.MOY_SKLAD_EXPORT:
                return this.crmExport.configure(type);
                break;
            case PlatformsTypes.BITRIX_IMPORT:
            case PlatformsTypes.AMO_CRM_IMPORT:
            case PlatformsTypes.MEGAPLAN_IMPORT:
            case PlatformsTypes.UON_IMPORT:
            case PlatformsTypes.MOY_SKLAD_IMPORT:
                return this.crmImport.configure(type);
                break;
            case PlatformsTypes.VK_LEAD_FORMS:
            case PlatformsTypes.MT_LEAD_FORMS:
            case PlatformsTypes.FB_LEAD_FORMS:
                return this.adNetworkExport.configure(type);
                break;
            case PlatformsTypes.VK_RETARGETING_GROUPS:
            case PlatformsTypes.MT_REMARKETING_GROUPS:
            case PlatformsTypes.FB_CUSTOM_AUDIENCES:
            case PlatformsTypes.YANDEX_AUDIENCES:
                return this.adNetworkImport.configure(type);
                break;
        }
    };
    ConfiguratorService.prototype.valid = function (type) {
        this.formsMessagesService.Clear();
        switch (type) {
            case PlatformsTypes.BITRIX_EXPORT:
            case PlatformsTypes.AMO_CRM_EXPORT:
            case PlatformsTypes.MEGAPLAN_EXPORT:
            case PlatformsTypes.UON_EXPORT:
                return this.crmExport.valid(type);
                break;
            case PlatformsTypes.BITRIX_IMPORT:
            case PlatformsTypes.AMO_CRM_IMPORT:
            case PlatformsTypes.MEGAPLAN_IMPORT:
            case PlatformsTypes.UON_IMPORT:
            case PlatformsTypes.MOY_SKLAD_IMPORT:
                return this.crmImport.valid(type);
                break;
            case PlatformsTypes.VK_LEAD_FORMS:
            case PlatformsTypes.MT_LEAD_FORMS:
            case PlatformsTypes.FB_LEAD_FORMS:
                return this.adNetworkExport.valid(type);
                break;
            case PlatformsTypes.VK_RETARGETING_GROUPS:
            case PlatformsTypes.MT_REMARKETING_GROUPS:
            case PlatformsTypes.FB_CUSTOM_AUDIENCES:
            case PlatformsTypes.YANDEX_AUDIENCES:
                return this.adNetworkImport.valid(type);
                break;
            case PlatformsTypes.MOY_SKLAD_EXPORT:
                return this.moiSkladExportService.valid();
                break;
        }
    };
    ConfiguratorService.ngInjectableDef = i0.defineInjectable({ factory: function ConfiguratorService_Factory() { return new ConfiguratorService(i0.inject(i1.ExportConfiguratorService), i0.inject(i2.CrmImportConfiguratorService), i0.inject(i3.AdNetworkImportConfiguratorService), i0.inject(i4.AdNetworkExportConfiguratorService), i0.inject(i5.MoiSkladExportService), i0.inject(i5.MoiSkladExportService), i0.inject(i6.FormMessagesService)); }, token: ConfiguratorService, providedIn: "root" });
    return ConfiguratorService;
}());
export { ConfiguratorService };
