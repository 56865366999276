import { Component, OnInit } from '@angular/core';
import {FormGroup} from '@angular/forms';
import {AdFormManagerService} from '../../../new-ad-manager-shared/services/ad-form-manager/ad-form-manager.service';

@Component({
  selector: 'app-new-teazer-form-pro',
  templateUrl: './new-teazer-form-pro.component.html',
  styleUrls: ['./new-teazer-form-pro.component.scss']
})
export class NewTeazerFormProComponent implements OnInit {

  public controlsWidth: string = '256px';
  public labelsWidth: string = '220px';

  public textAreaHeight = '237px';

  public get form(): FormGroup {
    return this.adFormManagerService.getControl('teazer') as FormGroup;
  }

  constructor(
    private adFormManagerService: AdFormManagerService
  ) {
  }

  ngOnInit() {
  }

  public ImageSelected(images: any) {
    this.adFormManagerService.AddTeazerImage(images);
  }

  public get TeazerLength() {
    return 33 - this.adFormManagerService.getTeazerTextLength(0);
  }
}
