import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CopyingAdsDialogComponent} from "./components/copying-ads-dialog/copying-ads-dialog.component";
import {CopyingByGropsConfigurationComponent} from "./components/copying-configurations/copying-by-grops-configuration/copying-by-grops-configuration.component";
import {CopyingByGroupsInFileComponent} from "./components/copying-configurations/copying-by-groups-in-file/copying-by-groups-in-file.component";
import {CopyingByRetaretingGroupsComponent} from "./components/copying-configurations/copying-by-retareting-groups/copying-by-retareting-groups.component";
import {CopyingBySexAndAgeComponent} from "./components/copying-configurations/copying-by-sex-and-age/copying-by-sex-and-age.component";
import {AgeSegmentationItemComponent} from "./components/age-segmentation-item/age-segmentation-item.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {
  MatButtonModule,
  MatCheckboxModule, MatDialogModule,
  MatIconModule, MatListModule,
  MatProgressSpinnerModule,
  MatRadioButton,
  MatRadioModule, MatTreeModule
} from "@angular/material";
import {SharedModule} from "../../shared/shared.module";
import {VkControlsModule} from "../../vk-controls/vk-controls.module";
import { TargetAccountAndCampaignComponent } from './components/target-account-and-campaign/target-account-and-campaign.component';
import { CopyingByGroupActivesListComponent } from './components/copying-configurations/copying-by-group-actives-list/copying-by-group-actives-list.component';
import { CopyingByGroupActivesFileComponent } from './components/copying-configurations/copying-by-group-actives-file/copying-by-group-actives-file.component';
import { CopyingByInterestCategoriesListComponent } from './components/copying-configurations/copying-by-interest-categories-list/copying-by-interest-categories-list.component';
import { CopyingByInterestCategoriesListCheckboxesComponent } from './components/copying-configurations/copying-by-interest-categories-list-checkboxes/copying-by-interest-categories-list-checkboxes.component';
import { InterestCategoriesTreeComponent } from './components/interest-categories-tree/interest-categories-tree.component';
import {SegmentationInfoComponent} from "./components/segmentation-info/segmentation-info.component";
import { CopyingByLinksComponent } from './components/copying-configurations/copying-by-links/copying-by-links.component';
import {LinkControlComponent} from "./components/link-data-control/link-control/link-control.component";
import {LinkQueryParamInputComponent} from "./components/link-data-control/link-query-param-input/link-query-param-input.component";
import {PopoverModule} from "../../popover/popover.module";
import { LinkQueryParamTemplateDirective } from './directives/link-query-param-template.directive';

@NgModule({
  declarations: [
    CopyingAdsDialogComponent,
    CopyingByGropsConfigurationComponent,
    CopyingByGroupsInFileComponent,
    CopyingByRetaretingGroupsComponent,
    CopyingBySexAndAgeComponent,
    AgeSegmentationItemComponent,
    TargetAccountAndCampaignComponent,
    CopyingByGroupActivesListComponent,
    CopyingByGroupActivesFileComponent,
    CopyingByInterestCategoriesListComponent,
    CopyingByInterestCategoriesListCheckboxesComponent,
    InterestCategoriesTreeComponent,
    SegmentationInfoComponent,
    CopyingByLinksComponent,
    LinkControlComponent,
    LinkQueryParamInputComponent,
    LinkQueryParamTemplateDirective
  ],
  exports: [
    CopyingAdsDialogComponent
  ],
  entryComponents:[
    CopyingAdsDialogComponent
  ],
  imports: [
    CommonModule,
    VkControlsModule,
    FormsModule,
    ReactiveFormsModule,
    MatRadioModule,
    MatCheckboxModule,
    SharedModule,
    MatIconModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatListModule,
    MatTreeModule,
    MatDialogModule,
    PopoverModule
  ]
})
export class CopyingModuleModule { }
