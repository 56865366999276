/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./create-teazer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../teazer/teazer.component.ngfactory";
import * as i3 from "../../teazer/teazer.component";
import * as i4 from "../../create-teazer-form/create-teazer-form.component.ngfactory";
import * as i5 from "@angular/forms";
import * as i6 from "../../create-teazer-form/create-teazer-form.component";
import * as i7 from "../../../../../shared/services/utils.service";
import * as i8 from "../../../../../api/services/ad-manager.service";
import * as i9 from "@angular/common";
import * as i10 from "../../images-manager/images-manager.component.ngfactory";
import * as i11 from "../../images-manager/images-manager.component";
import * as i12 from "./create-teazer.component";
var styles_CreateTeazerComponent = [i0.styles];
var RenderType_CreateTeazerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CreateTeazerComponent, data: {} });
export { RenderType_CreateTeazerComponent as RenderType_CreateTeazerComponent };
function View_CreateTeazerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "teaser-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-teazer", [], null, null, null, i2.View_TeazerComponent_0, i2.RenderType_TeazerComponent)), i1.ɵdid(3, 114688, null, 0, i3.TeazerComponent, [], { teazer: [0, "teazer"], title: [1, "title"], image: [2, "image"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit.teazer; var currVal_1 = _v.context.$implicit.title; var currVal_2 = _v.context.$implicit.image; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_CreateTeazerComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { ImagesManager: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "teazers"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "teazers__form"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "app-create-teazer-form", [], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "validStatusChanged"], [null, "teazerInfoCreated"], [null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 4).onReset() !== false);
        ad = (pd_1 && ad);
    } if (("validStatusChanged" === en)) {
        var pd_2 = (_co.OnValidStatusChange($event) !== false);
        ad = (pd_2 && ad);
    } if (("teazerInfoCreated" === en)) {
        var pd_3 = (_co.OnTeazerChange($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i4.View_CreateTeazerFormComponent_0, i4.RenderType_CreateTeazerFormComponent)), i1.ɵdid(4, 540672, null, 0, i5.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i5.ControlContainer, null, [i5.FormGroupDirective]), i1.ɵdid(6, 16384, null, 0, i5.NgControlStatusGroup, [[4, i5.ControlContainer]], null, null), i1.ɵdid(7, 114688, null, 0, i6.CreateTeazerFormComponent, [i7.UtilsService, i5.ControlContainer, i8.AdManagerService], { categories: [0, "categories"] }, { teazerInfoCreated: "teazerInfoCreated", validStatusChanged: "validStatusChanged" }), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "teazers__list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CreateTeazerComponent_1)), i1.ɵdid(10, 278528, null, 0, i9.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [["class", "vk-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "app-images-manager", [], null, [[null, "selected"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selected" === en)) {
        var pd_0 = (_co.OnSelectImage($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_ImagesManagerComponent_0, i10.RenderType_ImagesManagerComponent)), i1.ɵdid(13, 114688, [[1, 4]], 0, i11.ImagesManagerComponent, [], { minWidth: [0, "minWidth"], minHeight: [1, "minHeight"] }, { selected: "selected" })], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.controlContainer.control; _ck(_v, 4, 0, currVal_7); var currVal_8 = _co.categories; _ck(_v, 7, 0, currVal_8); var currVal_9 = _co.teazers; _ck(_v, 10, 0, currVal_9); var currVal_10 = 145; var currVal_11 = 165; _ck(_v, 13, 0, currVal_10, currVal_11); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 6).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 6).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 6).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 6).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 6).ngClassValid; var currVal_5 = i1.ɵnov(_v, 6).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 6).ngClassPending; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_CreateTeazerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-create-teazer", [], null, null, null, View_CreateTeazerComponent_0, RenderType_CreateTeazerComponent)), i1.ɵdid(1, 114688, null, 0, i12.CreateTeazerComponent, [i5.ControlContainer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CreateTeazerComponentNgFactory = i1.ɵccf("app-create-teazer", i12.CreateTeazerComponent, View_CreateTeazerComponent_Host_0, { isUpdating: "isUpdating", categories: "categories", teazer: "teazer" }, { onNext: "onNext" }, []);
export { CreateTeazerComponentNgFactory as CreateTeazerComponentNgFactory };
