/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Cabinet } from '../models/cabinet';
import { Client } from '../models/client';
import { PostMonitorBackend_V2ModelsvkVKAPIGroupModel } from '../models/post-monitor-backend-_v2modelsvk-vkapigroup-model';
import { PostMonitorBackend_V2ModelsvkVKAPIAdTargetGroup } from '../models/post-monitor-backend-_v2modelsvk-vkapiad-target-group';
import { PostMonitorBackend_V2ModelsvkVKAPILeadForm } from '../models/post-monitor-backend-_v2modelsvk-vkapilead-form';
import { PostMonitorBackend_V2ModelsvkVKAPILeadResponse } from '../models/post-monitor-backend-_v2modelsvk-vkapilead-response';
@Injectable({
  providedIn: 'root',
})
class VkApiService extends __BaseService {
  static readonly GetAccountsAsyncPath = '/apiv2/vk-api/getaccounts';
  static readonly GetClientsAsyncPath = '/apiv2/vk-api/getclients';
  static readonly GetGroupsAsyncPath = '/apiv2/vk-api/getgroups';
  static readonly GetAdsTargetGroupsAsyncPath = '/apiv2/vk-api/adstargetgroups';
  static readonly GetLeadFormsListAsyncPath = '/apiv2/vk-api/leadformslist';
  static readonly GetLeadFormsLeadsAsyncPath = '/apiv2/vk-api/leadformsgetleads';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param key undefined
   * @return Success
   */
  GetAccountsAsyncResponse(key?: string): __Observable<__StrictHttpResponse<Array<Cabinet>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (key != null) __params = __params.set('key', key.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/vk-api/getaccounts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Cabinet>>;
      })
    );
  }
  /**
   * @param key undefined
   * @return Success
   */
  GetAccountsAsync(key?: string): __Observable<Array<Cabinet>> {
    return this.GetAccountsAsyncResponse(key).pipe(
      __map(_r => _r.body as Array<Cabinet>)
    );
  }

  /**
   * @param params The `VkApiService.GetClientsAsyncParams` containing the following parameters:
   *
   * - `accountId`:
   *
   * - `key`:
   *
   * @return Success
   */
  GetClientsAsyncResponse(params: VkApiService.GetClientsAsyncParams): __Observable<__StrictHttpResponse<Array<Client>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.accountId != null) __params = __params.set('accountId', params.accountId.toString());
    if (params.key != null) __params = __params.set('key', params.key.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/vk-api/getclients`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Client>>;
      })
    );
  }
  /**
   * @param params The `VkApiService.GetClientsAsyncParams` containing the following parameters:
   *
   * - `accountId`:
   *
   * - `key`:
   *
   * @return Success
   */
  GetClientsAsync(params: VkApiService.GetClientsAsyncParams): __Observable<Array<Client>> {
    return this.GetClientsAsyncResponse(params).pipe(
      __map(_r => _r.body as Array<Client>)
    );
  }

  /**
   * @param params The `VkApiService.GetGroupsAsyncParams` containing the following parameters:
   *
   * - `offset`:
   *
   * - `key`:
   *
   * - `filter`:
   *
   * - `fields`:
   *
   * - `count`:
   *
   * @return Success
   */
  GetGroupsAsyncResponse(params: VkApiService.GetGroupsAsyncParams): __Observable<__StrictHttpResponse<Array<PostMonitorBackend_V2ModelsvkVKAPIGroupModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.key != null) __params = __params.set('key', params.key.toString());
    if (params.filter != null) __params = __params.set('filter', params.filter.toString());
    if (params.fields != null) __params = __params.set('fields', params.fields.toString());
    if (params.count != null) __params = __params.set('count', params.count.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/vk-api/getgroups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PostMonitorBackend_V2ModelsvkVKAPIGroupModel>>;
      })
    );
  }
  /**
   * @param params The `VkApiService.GetGroupsAsyncParams` containing the following parameters:
   *
   * - `offset`:
   *
   * - `key`:
   *
   * - `filter`:
   *
   * - `fields`:
   *
   * - `count`:
   *
   * @return Success
   */
  GetGroupsAsync(params: VkApiService.GetGroupsAsyncParams): __Observable<Array<PostMonitorBackend_V2ModelsvkVKAPIGroupModel>> {
    return this.GetGroupsAsyncResponse(params).pipe(
      __map(_r => _r.body as Array<PostMonitorBackend_V2ModelsvkVKAPIGroupModel>)
    );
  }

  /**
   * @param params The `VkApiService.GetAdsTargetGroupsAsyncParams` containing the following parameters:
   *
   * - `accountId`:
   *
   * - `key`:
   *
   * - `clientId`:
   *
   * @return Success
   */
  GetAdsTargetGroupsAsyncResponse(params: VkApiService.GetAdsTargetGroupsAsyncParams): __Observable<__StrictHttpResponse<Array<PostMonitorBackend_V2ModelsvkVKAPIAdTargetGroup>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.accountId != null) __params = __params.set('accountId', params.accountId.toString());
    if (params.key != null) __params = __params.set('key', params.key.toString());
    if (params.clientId != null) __params = __params.set('clientId', params.clientId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/vk-api/adstargetgroups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PostMonitorBackend_V2ModelsvkVKAPIAdTargetGroup>>;
      })
    );
  }
  /**
   * @param params The `VkApiService.GetAdsTargetGroupsAsyncParams` containing the following parameters:
   *
   * - `accountId`:
   *
   * - `key`:
   *
   * - `clientId`:
   *
   * @return Success
   */
  GetAdsTargetGroupsAsync(params: VkApiService.GetAdsTargetGroupsAsyncParams): __Observable<Array<PostMonitorBackend_V2ModelsvkVKAPIAdTargetGroup>> {
    return this.GetAdsTargetGroupsAsyncResponse(params).pipe(
      __map(_r => _r.body as Array<PostMonitorBackend_V2ModelsvkVKAPIAdTargetGroup>)
    );
  }

  /**
   * @param params The `VkApiService.GetLeadFormsListAsyncParams` containing the following parameters:
   *
   * - `groupId`:
   *
   * - `key`:
   *
   * @return Success
   */
  GetLeadFormsListAsyncResponse(params: VkApiService.GetLeadFormsListAsyncParams): __Observable<__StrictHttpResponse<Array<PostMonitorBackend_V2ModelsvkVKAPILeadForm>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.groupId != null) __params = __params.set('groupId', params.groupId.toString());
    if (params.key != null) __params = __params.set('key', params.key.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/vk-api/leadformslist`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PostMonitorBackend_V2ModelsvkVKAPILeadForm>>;
      })
    );
  }
  /**
   * @param params The `VkApiService.GetLeadFormsListAsyncParams` containing the following parameters:
   *
   * - `groupId`:
   *
   * - `key`:
   *
   * @return Success
   */
  GetLeadFormsListAsync(params: VkApiService.GetLeadFormsListAsyncParams): __Observable<Array<PostMonitorBackend_V2ModelsvkVKAPILeadForm>> {
    return this.GetLeadFormsListAsyncResponse(params).pipe(
      __map(_r => _r.body as Array<PostMonitorBackend_V2ModelsvkVKAPILeadForm>)
    );
  }

  /**
   * @param params The `VkApiService.GetLeadFormsLeadsAsyncParams` containing the following parameters:
   *
   * - `limit`:
   *
   * - `groupId`:
   *
   * - `formId`:
   *
   * - `nextPageToken`:
   *
   * - `key`:
   *
   * @return Success
   */
  GetLeadFormsLeadsAsyncResponse(params: VkApiService.GetLeadFormsLeadsAsyncParams): __Observable<__StrictHttpResponse<PostMonitorBackend_V2ModelsvkVKAPILeadResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    if (params.groupId != null) __params = __params.set('groupId', params.groupId.toString());
    if (params.formId != null) __params = __params.set('formId', params.formId.toString());
    if (params.nextPageToken != null) __params = __params.set('nextPageToken', params.nextPageToken.toString());
    if (params.key != null) __params = __params.set('key', params.key.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/vk-api/leadformsgetleads`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PostMonitorBackend_V2ModelsvkVKAPILeadResponse>;
      })
    );
  }
  /**
   * @param params The `VkApiService.GetLeadFormsLeadsAsyncParams` containing the following parameters:
   *
   * - `limit`:
   *
   * - `groupId`:
   *
   * - `formId`:
   *
   * - `nextPageToken`:
   *
   * - `key`:
   *
   * @return Success
   */
  GetLeadFormsLeadsAsync(params: VkApiService.GetLeadFormsLeadsAsyncParams): __Observable<PostMonitorBackend_V2ModelsvkVKAPILeadResponse> {
    return this.GetLeadFormsLeadsAsyncResponse(params).pipe(
      __map(_r => _r.body as PostMonitorBackend_V2ModelsvkVKAPILeadResponse)
    );
  }
}

module VkApiService {

  /**
   * Parameters for GetClientsAsync
   */
  export interface GetClientsAsyncParams {
    accountId: number;
    key?: string;
  }

  /**
   * Parameters for GetGroupsAsync
   */
  export interface GetGroupsAsyncParams {
    offset?: number;
    key?: string;
    filter?: string;
    fields?: string;
    count?: number;
  }

  /**
   * Parameters for GetAdsTargetGroupsAsync
   */
  export interface GetAdsTargetGroupsAsyncParams {
    accountId: number;
    key?: string;
    clientId?: number;
  }

  /**
   * Parameters for GetLeadFormsListAsync
   */
  export interface GetLeadFormsListAsyncParams {
    groupId: number;
    key?: string;
  }

  /**
   * Parameters for GetLeadFormsLeadsAsync
   */
  export interface GetLeadFormsLeadsAsyncParams {
    limit: number;
    groupId: number;
    formId: number;
    nextPageToken?: string;
    key?: string;
  }
}

export { VkApiService }
