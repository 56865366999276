import {CopyingType} from "./abstract-copying-configurator";
import {MultipleCopyingData} from "../models/multiple-copying-data";
import {DefaultCopyingConfigurator} from "./default-copying-configurator";
import {GroupSegmentationConfigurator} from "./group-segmentation-configurator";
import {GroupsInFileSegmentationConfigurator} from "./groups-in-file-segmentation-configurator";
import {RetargetingGroupsCopyingConfigurator} from "./retargeting-groups-copying-configurator";
import {SexAndAgeCopyingConfigurator} from "./sex-and-age-copying-configurator";
import {PostMonitorBackend_V2ModelsAdManagerCopyAdsViewModel} from "../../../api/models/post-monitor-backend-_v2models-ad-manager-copy-ads-view-model";
import {GroupActivesCopyingConfigurator} from "./group-actives-copying-configurator";
import {GroupActivesFileCopyingConfigurator} from "./group-actives-file-copying-configurator";
import {InterestGroupsListConfigurator} from "./interest-groups-list-configurator";
import {InterestGroupsCheckboxesCopyingConfigurator} from "./interest-groups-checkboxes-copying-configurator";

export class CopyingConfigurator {
  static configure(type: CopyingType, copyingConfiguration: MultipleCopyingData): PostMonitorBackend_V2ModelsAdManagerCopyAdsViewModel[] {
    switch (type) {
      case CopyingType.DEFAULT_COPYING:
        return new DefaultCopyingConfigurator(copyingConfiguration).generate();
      case CopyingType.BY_GROUPS:
        return new GroupSegmentationConfigurator(copyingConfiguration).generate();
      case CopyingType.BY_GROUPS_FILE:
        return new GroupsInFileSegmentationConfigurator(copyingConfiguration).generate();
      case CopyingType.BY_RETARGETING_GROUPS:
        return new RetargetingGroupsCopyingConfigurator(copyingConfiguration).generate();
      case CopyingType.BY_SEX_AND_AGE:
        return new SexAndAgeCopyingConfigurator(copyingConfiguration).generate();
      case CopyingType.BY_ACTIVE_GROUPS:
        return new GroupActivesCopyingConfigurator(copyingConfiguration).generate();
      case CopyingType.BY_ACTIVE_GROUPS_FILES:
        return new GroupActivesFileCopyingConfigurator(copyingConfiguration).generate();
      case CopyingType.BY_INTEREST_CATEGORIES:
        return new InterestGroupsListConfigurator(copyingConfiguration).generate();
      case CopyingType.BY_INTEREST_CATEGORIES_CHECKBOXES_TREE:
        return new InterestGroupsCheckboxesCopyingConfigurator(copyingConfiguration).generate();
      default:
        throw new Error(`Configurator with code '${type}' not found`);
    }
  }
}
