/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./create-ad.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../components/ad-form/ad-form.component.ngfactory";
import * as i3 from "../../components/ad-form/ad-form.component";
import * as i4 from "../../../../api/services/ad-manager.service";
import * as i5 from "../../services/groups-selector/groups-selector.service";
import * as i6 from "@angular/flex-layout/flex";
import * as i7 from "@angular/flex-layout/core";
import * as i8 from "@angular/common";
import * as i9 from "./create-ad.component";
import * as i10 from "../../../../api/services/user-strategies.service";
import * as i11 from "@angular/router";
import * as i12 from "../../../../api/services/automation-vk.service";
import * as i13 from "../../../../api/services/automation-vk-slot.service";
import * as i14 from "../../../../shared/services/dexie/dexie-cabinets.service";
import * as i15 from "../../../services/automation-expiration-manager.service";
import * as i16 from "../../../../shared/services/dexie/dexie-clients.service";
import * as i17 from "@angular/material/dialog";
var styles_CreateAdComponent = [i0.styles];
var RenderType_CreateAdComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CreateAdComponent, data: {} });
export { RenderType_CreateAdComponent as RenderType_CreateAdComponent };
function View_CreateAdComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-ad-form", [], null, [[null, "adSaved"], [null, "bindChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("adSaved" === en)) {
        var pd_0 = (_co.OnSaveAd($event) !== false);
        ad = (pd_0 && ad);
    } if (("bindChanged" === en)) {
        var pd_1 = (_co.ChangeBind() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_AdFormComponent_0, i2.RenderType_AdFormComponent)), i1.ɵdid(1, 114688, null, 0, i3.AdFormComponent, [i1.ChangeDetectorRef, i4.AdManagerService, i5.GroupsSelectorService], { loading: [0, "loading"], slots: [1, "slots"], accounts: [2, "accounts"] }, { adSaved: "adSaved", bindChanged: "bindChanged" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; var currVal_1 = _co.slots; var currVal_2 = _co.accounts; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_CreateAdComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["fxLayout", "row"], ["fxLayoutAlign", "space-between"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i6.DefaultLayoutDirective, [i1.ElementRef, i7.StyleUtils, [2, i6.LayoutStyleBuilder], i7.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(2, 671744, null, 0, i6.DefaultLayoutAlignDirective, [i1.ElementRef, i7.StyleUtils, [2, i6.LayoutAlignStyleBuilder], i7.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "vk-ad-cabinet-header vk-ad-cabinet-header_large"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u041D\u043E\u0432\u043E\u0435 \u043E\u0431\u044A\u044F\u0432\u043B\u0435\u043D\u0438\u0435 "])), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "alert"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "alert-items"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "a", [["href", "https://postmonitor.ru/blog/instrukciya-po-sozdaniyu-obyavlenijj-v-vkontakte-cherez-servis-postmonitor"], ["rel", "nofollow noopener"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u0418\u043D\u0441\u0442\u0440\u0443\u043A\u0446\u0438\u044F \u043F\u043E \u0441\u043E\u0437\u0434\u0430\u043D\u0438\u044E \u043E\u0431\u044A\u044F\u0432\u043B\u0435\u043D\u0438\u0439 "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CreateAdComponent_1)), i1.ɵdid(10, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "row"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "space-between"; _ck(_v, 2, 0, currVal_1); var currVal_2 = (_co.slots && _co.accounts); _ck(_v, 10, 0, currVal_2); }, null); }
export function View_CreateAdComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-create-ad", [], null, null, null, View_CreateAdComponent_0, RenderType_CreateAdComponent)), i1.ɵdid(1, 114688, null, 0, i9.CreateAdComponent, [i4.AdManagerService, i10.UserStrategiesService, i11.Router, i12.AutomationVkService, i13.AutomationVkSlotService, i10.UserStrategiesService, i5.GroupsSelectorService, i12.AutomationVkService, i14.DexieCabinetsService, i13.AutomationVkSlotService, i15.AutomationExpirationManagerService, i16.DexieClientsService, i17.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CreateAdComponentNgFactory = i1.ɵccf("app-create-ad", i9.CreateAdComponent, View_CreateAdComponent_Host_0, {}, {}, []);
export { CreateAdComponentNgFactory as CreateAdComponentNgFactory };
