import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatProgressSpinnerModule, MatStepperModule} from '@angular/material';
import {VkControlsModule} from '../../../vk-controls/vk-controls.module';
import {AdManagerModule} from '../../ad-manager/ad-manager.module';
import {NewAdManagerSharedModule} from '../new-ad-manager-shared/new-ad-manager-shared.module';

import { NewAdManagerProRoutingModule } from './new-ad-manager-pro-routing.module';
import { NewCreateAdProComponent } from './routes/new-create-ad-pro/new-create-ad-pro.component';
import { NewPromopostFormProComponent } from './components/new-promopost-form-pro/new-promopost-form-pro.component';
import { NewTeazerFormProComponent } from './components/new-teazer-form-pro/new-teazer-form-pro.component';
import { AdFormProComponent } from './components/ad-form-pro/ad-form-pro.component';
import { NewUpdateAdProComponent } from './routes/new-update-ad-pro/new-update-ad-pro.component';

@NgModule({
  declarations: [NewCreateAdProComponent, NewPromopostFormProComponent, NewTeazerFormProComponent, AdFormProComponent, NewUpdateAdProComponent],
  imports: [
    CommonModule,
    NewAdManagerProRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatStepperModule,
    NewAdManagerSharedModule,
    VkControlsModule,
    AdManagerModule,
    MatProgressSpinnerModule
  ]
})
export class NewAdManagerProModule { }
