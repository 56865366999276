import {Component, OnInit} from '@angular/core';
import {PaymentsService} from "../../services/payments.service";

@Component({
  selector: 'app-payments-table',
  templateUrl: './payments-table.component.html',
  styleUrls: ['./payments-table.component.scss']
})
export class PaymentsTableComponent implements OnInit {

  public get tariffPlans() {
    return this.paymentsService.tariffPlans$;
  }

  public get features() {
    return this.paymentsService.features$;
  }

  constructor(
    private paymentsService: PaymentsService
  ) {
  }

  ngOnInit() {
    this.paymentsService.LoadData();
  }

}
