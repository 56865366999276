/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./create-ad-lite.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "../../../components/lite/forms/ad-form-lite/ad-form-lite.component.ngfactory";
import * as i5 from "../../../components/lite/forms/ad-form-lite/ad-form-lite.component";
import * as i6 from "../../../../../api/services/ad-manager.service";
import * as i7 from "../../../../../api/services/automation-vk.service";
import * as i8 from "../../../../../api/services/automation-vk-slot.service";
import * as i9 from "../../../../../api/services/strategies.service";
import * as i10 from "@angular/router";
import * as i11 from "../../../services/groups-selector/groups-selector.service";
import * as i12 from "./create-ad-lite.component";
import * as i13 from "../../../../../api/services/user-strategies.service";
import * as i14 from "@angular/material/dialog";
var styles_CreateAdLiteComponent = [i0.styles];
var RenderType_CreateAdLiteComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CreateAdLiteComponent, data: {} });
export { RenderType_CreateAdLiteComponent as RenderType_CreateAdLiteComponent };
export function View_CreateAdLiteComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["fxLayout", "row"], ["fxLayoutAlign", "space-between"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(2, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i3.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "vk-ad-cabinet-header vk-ad-cabinet-header_large"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u041D\u043E\u0432\u043E\u0435 \u043E\u0431\u044A\u044F\u0432\u043B\u0435\u043D\u0438\u0435 "])), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "alert"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "alert-items"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "a", [["href", "https://postmonitor.ru/blog/instrukciya-po-sozdaniyu-obyavleniya-v-vkontakte-cherez-servis-postmonitor-dlya-novichkov"], ["rel", "nofollow noopener"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u0418\u043D\u0441\u0442\u0440\u0443\u043A\u0446\u0438\u044F \u043F\u043E \u0441\u043E\u0437\u0434\u0430\u043D\u0438\u044E \u043E\u0431\u044A\u044F\u0432\u043B\u0435\u043D\u0438\u0439 "])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "app-ad-form-lite", [], null, [[null, "AdSaved"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("AdSaved" === en)) {
        var pd_0 = (_co.SaveAd($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_AdFormLiteComponent_0, i4.RenderType_AdFormLiteComponent)), i1.ɵdid(10, 114688, null, 0, i5.AdFormLiteComponent, [i6.AdManagerService, i7.AutomationVkService, i8.AutomationVkSlotService, i9.StrategiesService, i10.Router, i1.ChangeDetectorRef, i11.GroupsSelectorService], { isSubmitLoading: [0, "isSubmitLoading"] }, { AdSaved: "AdSaved" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "row"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "space-between"; _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.isLoading; _ck(_v, 10, 0, currVal_2); }, null); }
export function View_CreateAdLiteComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-create-ad-lite", [], null, null, null, View_CreateAdLiteComponent_0, RenderType_CreateAdLiteComponent)), i1.ɵdid(1, 114688, null, 0, i12.CreateAdLiteComponent, [i6.AdManagerService, i13.UserStrategiesService, i10.Router, i11.GroupsSelectorService, i14.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CreateAdLiteComponentNgFactory = i1.ɵccf("app-create-ad-lite", i12.CreateAdLiteComponent, View_CreateAdLiteComponent_Host_0, {}, {}, []);
export { CreateAdLiteComponentNgFactory as CreateAdLiteComponentNgFactory };
