/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./affiliate-promgram-statistics-by-user-route.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../components/affiliate-promgram-statistics-by-user-table/affiliate-promgram-statistics-by-user-table.component.ngfactory";
import * as i3 from "../../components/affiliate-promgram-statistics-by-user-table/affiliate-promgram-statistics-by-user-table.component";
import * as i4 from "../../services/affiliate-promgram-statistics-by-user.service";
import * as i5 from "./affiliate-promgram-statistics-by-user-route.component";
var styles_AffiliatePromgramStatisticsByUserRouteComponent = [i0.styles];
var RenderType_AffiliatePromgramStatisticsByUserRouteComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AffiliatePromgramStatisticsByUserRouteComponent, data: {} });
export { RenderType_AffiliatePromgramStatisticsByUserRouteComponent as RenderType_AffiliatePromgramStatisticsByUserRouteComponent };
export function View_AffiliatePromgramStatisticsByUserRouteComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "page"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "page__content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h2", [["class", "page__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u0421\u0442\u0430\u0442\u0438\u0441\u0442\u0438\u043A\u0430 \u043F\u043E \u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u0435\u043B\u044F\u043C"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-affiliate-promgram-statistics-by-user-table", [], null, null, null, i2.View_AffiliatePromgramStatisticsByUserTableComponent_0, i2.RenderType_AffiliatePromgramStatisticsByUserTableComponent)), i1.ɵdid(5, 114688, null, 0, i3.AffiliatePromgramStatisticsByUserTableComponent, [i4.AffiliatePromgramStatisticsByUserService], null, null)], function (_ck, _v) { _ck(_v, 5, 0); }, null); }
export function View_AffiliatePromgramStatisticsByUserRouteComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-affiliate-promgram-statistics-by-user-route", [], null, null, null, View_AffiliatePromgramStatisticsByUserRouteComponent_0, RenderType_AffiliatePromgramStatisticsByUserRouteComponent)), i1.ɵdid(1, 114688, null, 0, i5.AffiliatePromgramStatisticsByUserRouteComponent, [i4.AffiliatePromgramStatisticsByUserService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AffiliatePromgramStatisticsByUserRouteComponentNgFactory = i1.ɵccf("app-affiliate-promgram-statistics-by-user-route", i5.AffiliatePromgramStatisticsByUserRouteComponent, View_AffiliatePromgramStatisticsByUserRouteComponent_Host_0, {}, {}, []);
export { AffiliatePromgramStatisticsByUserRouteComponentNgFactory as AffiliatePromgramStatisticsByUserRouteComponentNgFactory };
