import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ChatConversationMessagesService = /** @class */ (function () {
    function ChatConversationMessagesService(http) {
        this.http = http;
        this.loadingMessages = false;
        this.loadingConversations = false;
        this.unreadMessageAmount = 0;
        this.messages = [];
        this.conversationsCount = null;
        this.conversations = [];
        this.isBanned = false;
        this.banReason = false;
        this.marks = [];
        this.apiUrl = environment.backendUri;
        this.apiRoute = environment.apiPrefix + "/chat";
        this.soundNewMessage = new Audio();
        this.soundNewMessage.src = 'https://postmonitor.ru/static/sounds/message-notification.wav';
        this.soundNewMessage.load();
        this.soundNewMessage.volume = 0.5;
    }
    ChatConversationMessagesService.prototype.PlayIncomeMessageSound = function () {
        if (!this.soundNewMessage) {
            this.soundNewMessage.load();
        }
        this.soundNewMessage.play();
    };
    ChatConversationMessagesService.prototype.GetMarks = function () {
        var _this = this;
        this.http.get(this.apiUrl + "/" + this.apiRoute + "/mark").subscribe(function (response) {
            if (response) {
                _this.marks = response.data;
            }
        }, function (error) {
            if (!_this.CheckForBan(error)) {
            }
        });
    };
    ChatConversationMessagesService.prototype.AssignNewMarkToConversation = function (userId, markId) {
        var _this = this;
        this.http.post(this.apiUrl + "/" + this.apiRoute + "/mark?userId=" + userId + "&markId=" + markId, null).subscribe(function (response) {
            if (response) {
                var relatedConversation = _this.conversations.find(function (x) { return x.userId === userId; });
                if (relatedConversation) {
                    relatedConversation.markId = markId;
                }
            }
        }, function (error) {
            if (!_this.CheckForBan(error)) {
            }
        });
    };
    ChatConversationMessagesService.prototype.GetConversationChanges = function (updatesFrom, userId, onlyReadUpdates, allowSound, unAnsweredFirst) {
        var _this = this;
        if (onlyReadUpdates === void 0) { onlyReadUpdates = false; }
        if (allowSound === void 0) { allowSound = false; }
        if (unAnsweredFirst === void 0) { unAnsweredFirst = true; }
        // conversations-updates
        this.http.get(this.apiUrl + "/" + this.apiRoute + "/conversations-updates?updatesFrom=" + updatesFrom + "&userId=" + userId + "&onlyReadUpdates=" + onlyReadUpdates).subscribe(function (response) {
            if (response) {
                _this.conversationsCount = response.records_found;
                if (response.data && response.data.length > 0) {
                    var data = response.data;
                    if (data && data.length > 0) {
                        var playSound_1 = false;
                        data.forEach(function (update) {
                            var relatedConversation = _this.conversations.find(function (x) { return x.userId === update.userId; });
                            if (update.createdConversation) {
                                if (update.createdConversation.lastMessage && !update.createdConversation.lastMessage.isRead && update.createdConversation.lastMessage.createdByUser) {
                                    playSound_1 = true;
                                }
                                if (!relatedConversation) {
                                    _this.conversations.unshift(update.createdConversation);
                                }
                                else {
                                    relatedConversation.lastMessage = update.createdConversation.lastMessage;
                                }
                            }
                            else if (update.changedLastMessage) {
                                if (relatedConversation) {
                                    relatedConversation.lastMessage = update.changedLastMessage;
                                    if (relatedConversation.lastMessage && !relatedConversation.lastMessage.isRead && relatedConversation.lastMessage.createdByUser) {
                                        playSound_1 = true;
                                    }
                                }
                            }
                            else if (update.messageReadIdList.length > 0) {
                                update.messageReadIdList.forEach(function (item) {
                                    var relatedMessage = _this.messages.find(function (x) { return x.userId === update.userId && x.id === item; });
                                    if (relatedMessage) {
                                        if (!relatedMessage.isRead && relatedMessage.createdByUser) {
                                            playSound_1 = true;
                                        }
                                        relatedMessage.isRead = true;
                                        relatedMessage.readAt = update.messageReadDate;
                                    }
                                });
                            }
                        });
                        if (playSound_1 && allowSound) {
                            _this.PlayIncomeMessageSound();
                        }
                        if (unAnsweredFirst) {
                            _this.conversations.sort(function (a, b) {
                                var firstParameterA = a.lastMessage.createdByUser ? 1 : 0;
                                var firstParameterB = b.lastMessage.createdByUser ? 1 : 0;
                                var secondParameterA = new Date(a.lastMessage.createdAt).getTime();
                                var secondParameterB = new Date(b.lastMessage.createdAt).getTime();
                                return firstParameterA < firstParameterB ? 1 : firstParameterA > firstParameterB ? -1 :
                                    secondParameterA < secondParameterB ? 1 : secondParameterA > secondParameterB ? -1 : 0;
                            });
                        }
                        else {
                            _this.conversations.sort(function (a, b) {
                                var dateA = new Date(a.lastMessage.createdAt).getTime();
                                var dateB = new Date(b.lastMessage.createdAt).getTime();
                                return dateA < dateB ? 1 : -1;
                            });
                        }
                    }
                }
            }
        }, function (error) {
            if (!_this.CheckForBan(error)) {
            }
        });
    };
    ChatConversationMessagesService.prototype.GetConversations = function (userId, filterMode, limit, offset, markFilterId) {
        var _this = this;
        if (markFilterId === void 0) { markFilterId = 0; }
        this.loadingConversations = true;
        this.conversations = null;
        this.http.get(this.apiUrl + "/" + this.apiRoute + "/conversations?userId=" + userId + "&filterMode=" + filterMode + "&limit=" + limit + "&offset=" + offset + "&markFilterId=" + markFilterId).subscribe(function (response) {
            console.log('GetConversatinos');
            _this.loadingConversations = false;
            _this.conversations = [];
            if (response && response.data) {
                _this.conversations = response.data;
            }
        }, function (error) {
            _this.conversations = [];
            if (!_this.CheckForBan(error)) {
            }
        });
    };
    ChatConversationMessagesService.prototype.GetAmountOfUnReadMessages = function () {
        var _this = this;
        this.http.get(this.apiUrl + "/" + this.apiRoute + "/unread").subscribe(function (response) {
            if (response) {
                _this.unreadMessageAmount = response.data;
            }
        }, function (error) {
            if (!_this.CheckForBan(error)) {
            }
        });
    };
    ChatConversationMessagesService.prototype.LoadNewMessages = function (userId) {
        var _this = this;
        var latestMessageId = 0;
        if (this.messages && this.messages.length > 0) {
            latestMessageId = this.messages[0].id;
        }
        var userRequest = (userId === undefined || userId === null);
        var userParameter = (userRequest) ? '' : "&userId=" + userId;
        this.http.get(this.apiUrl + "/" + this.apiRoute + "/latest-messages?lastMessageId=" + latestMessageId + userParameter).subscribe(function (response) {
            if (response && response.data && response.data.length > 0) {
                response.data.forEach(function (message) {
                    if (!_this.messages.find(function (x) { return x.id === message.id; })) {
                        _this.messages.unshift(message);
                    }
                });
                // Mark all other as read (in frontend)
                _this.messages.forEach(function (message) {
                    message.isRead = true;
                });
                _this.unreadMessageAmount = 0;
            }
        }, function (error) {
            if (!_this.CheckForBan(error)) {
            }
        });
    };
    ChatConversationMessagesService.prototype.SendMessage = function (text, userId, sendNotificationOverEmail) {
        var _this = this;
        if (sendNotificationOverEmail === void 0) { sendNotificationOverEmail = false; }
        var body = text;
        this.http.post(this.apiUrl + "/" + this.apiRoute + "/messages?userId=" + userId + "&sendNotificationOverEmail=" + sendNotificationOverEmail, body).subscribe(function (response) {
            if (response && response.data) {
                var message_1 = response.data;
                var alreadyAddedMessage = _this.messages.find(function (x) { return x.id === message_1.id; });
                if (!alreadyAddedMessage) {
                    _this.messages.unshift(message_1);
                }
            }
        }, function (error) {
            if (!_this.CheckForBan(error)) {
            }
        });
    };
    ChatConversationMessagesService.prototype.GetMessages = function (userId) {
        var _this = this;
        this.loadingMessages = true;
        this.messages = [];
        var userRequest = (userId === undefined || userId === null);
        var userParameter = (userRequest) ? '' : "?userId=" + userId;
        this.http.get(this.apiUrl + "/" + this.apiRoute + "/messages" + userParameter).subscribe(function (response) {
            console.log('GetMessages');
            _this.loadingMessages = false;
            if (response && response.data) {
                _this.messages = response.data;
            }
            _this.unreadMessageAmount = 0;
        }, function (error) {
            if (!_this.CheckForBan(error)) {
            }
        });
    };
    ChatConversationMessagesService.prototype.BanConversation = function (userId, reason) {
        var _this = this;
        this.http.delete(this.apiUrl + "/" + this.apiRoute + "/conversations?userId=" + userId + "&reason=" + reason).subscribe(function (response) {
            if (response && response.data) {
            }
        }, function (error) {
            if (!_this.CheckForBan(error)) {
            }
        });
    };
    ChatConversationMessagesService.prototype.CheckForBan = function (response) {
        if (response && response.status === 403 && response.error && response.error.reason) {
            this.isBanned = true;
            this.banReason = response.error.reason;
            return true;
        }
        return false;
    };
    ChatConversationMessagesService.ngInjectableDef = i0.defineInjectable({ factory: function ChatConversationMessagesService_Factory() { return new ChatConversationMessagesService(i0.inject(i1.HttpClient)); }, token: ChatConversationMessagesService, providedIn: "root" });
    return ChatConversationMessagesService;
}());
export { ChatConversationMessagesService };
