/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./conversation.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../components/chat-control-panel/chat-control-panel.component.ngfactory";
import * as i3 from "../../components/chat-control-panel/chat-control-panel.component";
import * as i4 from "../../services/chat-conversation-messages.service";
import * as i5 from "@angular/forms";
import * as i6 from "@angular/common";
import * as i7 from "../../components/chat-form/chat-form.component.ngfactory";
import * as i8 from "../../components/chat-form/chat-form.component";
import * as i9 from "./conversation.component";
import * as i10 from "@angular/router";
var styles_ConversationComponent = [i0.styles];
var RenderType_ConversationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConversationComponent, data: {} });
export { RenderType_ConversationComponent as RenderType_ConversationComponent };
function View_ConversationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-chat-control-panel", [], null, [[null, "onSendNotificationOverEmailChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onSendNotificationOverEmailChange" === en)) {
        var pd_0 = ((_co.sendNotificationOverEmail = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ChatControlPanelComponent_0, i2.RenderType_ChatControlPanelComponent)), i1.ɵdid(2, 114688, null, 0, i3.ChatControlPanelComponent, [i4.ChatConversationMessagesService], { conversationWithUserId: [0, "conversationWithUserId"] }, { onSendNotificationOverEmailChange: "onSendNotificationOverEmailChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.conversationWithUserId; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ConversationComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "vk-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "vk-error"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u041F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u0435\u043B\u044C \u0443\u0436\u0435 \u0437\u0430\u0431\u043B\u043E\u043A\u0438\u0440\u043E\u0432\u0430\u043D "]))], null, null); }
function View_ConversationComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 14, "div", [["class", "vk-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 13, "div", [["class", "vk-strip-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 9, "div", [["class", "vk-strip-wrapper__item vk-strip-wrapper__item_wide"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 8, "textarea", [["class", "vk-textarea"], ["id", "feature-block-user"], ["maxlength", "1024"], ["minlength", "5"], ["placeholder", "\u041D\u0430\u043F\u0438\u0448\u0438\u0442\u0435 \u043F\u0440\u0438\u0447\u0438\u043D\u0443 \u0431\u043B\u043E\u043A\u0438\u0440\u043E\u0432\u043A\u0438 (\u0431\u0443\u0434\u0435\u0442 \u043E\u0442\u043E\u0431\u0440\u0430\u0436\u0435\u043D\u0430 \u0443 \u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u0435\u043B\u044F)."], ["rows", "1"]], [[1, "minlength", 0], [1, "maxlength", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 5).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 5)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 5)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.featureBanReason = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 16384, null, 0, i5.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i5.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵdid(6, 540672, null, 0, i5.MinLengthValidator, [], { minlength: [0, "minlength"] }, null), i1.ɵdid(7, 540672, null, 0, i5.MaxLengthValidator, [], { maxlength: [0, "maxlength"] }, null), i1.ɵprd(1024, null, i5.NG_VALIDATORS, function (p0_0, p1_0) { return [p0_0, p1_0]; }, [i5.MinLengthValidator, i5.MaxLengthValidator]), i1.ɵprd(1024, null, i5.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.DefaultValueAccessor]), i1.ɵdid(10, 671744, null, 0, i5.NgModel, [[8, null], [6, i5.NG_VALIDATORS], [8, null], [6, i5.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i5.NgControl, null, [i5.NgModel]), i1.ɵdid(12, 16384, null, 0, i5.NgControlStatus, [[4, i5.NgControl]], null, null), (_l()(), i1.ɵeld(13, 0, null, null, 2, "div", [["class", "vk-strip-wrapper__item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "button", [["class", "vk-standart-button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.featureBanRequireConfirmation = true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\u0417\u0430\u0431\u043B\u043E\u043A\u0438\u0440\u043E\u0432\u0430\u0442\u044C"]))], function (_ck, _v) { var _co = _v.component; var currVal_9 = "5"; _ck(_v, 6, 0, currVal_9); var currVal_10 = "1024"; _ck(_v, 7, 0, currVal_10); var currVal_11 = _co.featureBanReason; _ck(_v, 10, 0, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵnov(_v, 6).minlength ? i1.ɵnov(_v, 6).minlength : null); var currVal_1 = (i1.ɵnov(_v, 7).maxlength ? i1.ɵnov(_v, 7).maxlength : null); var currVal_2 = i1.ɵnov(_v, 12).ngClassUntouched; var currVal_3 = i1.ɵnov(_v, 12).ngClassTouched; var currVal_4 = i1.ɵnov(_v, 12).ngClassPristine; var currVal_5 = i1.ɵnov(_v, 12).ngClassDirty; var currVal_6 = i1.ɵnov(_v, 12).ngClassValid; var currVal_7 = i1.ɵnov(_v, 12).ngClassInvalid; var currVal_8 = i1.ɵnov(_v, 12).ngClassPending; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); var currVal_12 = (!_co.featureBanReason || (_co.featureBanReason.length < 5)); _ck(_v, 14, 0, currVal_12); }); }
function View_ConversationComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "vk-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "vk-strip-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "vk-strip-wrapper__item vk-strip-wrapper__item_wide"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u0412\u044B \u0443\u0432\u0435\u0440\u0435\u043D\u044B, \u0447\u0442\u043E \u0445\u043E\u0442\u0438\u0442\u0435 \u0437\u0430\u0431\u043B\u043E\u043A\u0438\u0440\u043E\u0432\u0430\u0442\u044C \u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u0435\u043B\u044F? "])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "vk-strip-wrapper__item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "button", [["class", "vk-standart-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.BanUser() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\u041F\u043E\u0434\u0442\u0432\u0435\u0440\u0434\u0438\u0442\u044C"])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "vk-strip-wrapper__item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "button", [["class", "vk-standart-button vk-standart-button_cancel"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.featureBanRequireConfirmation = false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\u041E\u0442\u043C\u0435\u043D\u0430"]))], null, null); }
function View_ConversationComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "vk-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "tip-field"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u0417\u0430\u0431\u043B\u043E\u043A\u0438\u0440\u043E\u0432\u0430\u043D\u043D\u044B\u0435 \u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u0435\u043B\u0438 \u043D\u0435 \u043C\u043E\u0433\u0443\u0442 \u043E\u0442\u043F\u0440\u0430\u0432\u044F\u0442\u044C \u0438 \u043F\u043E\u043B\u0443\u0447\u0430\u0442\u044C \u0441\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u044F. "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConversationComponent_6)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["banRequireConfirm", 2]], null, 0, null, View_ConversationComponent_7))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.featureBanRequireConfirmation; var currVal_1 = i1.ɵnov(_v, 5); _ck(_v, 4, 0, currVal_0, currVal_1); }, null); }
function View_ConversationComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConversationComponent_4)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["banned", 2]], null, 0, null, View_ConversationComponent_5))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.conversationMessagesService.isBanned; var currVal_1 = i1.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_ConversationComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "conversation-list__item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "conversation-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "vk-container vk-container_clickable"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.featureBanExpand = !_co.featureBanExpand) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "vk-ad-cabinet-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "i", [], null, null, null, null, null)), i1.ɵdid(6, 278528, null, 0, i6.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵted(-1, null, [" \u0411\u043B\u043E\u043A\u0438\u0440\u043E\u0432\u043A\u0430 \u0447\u0430\u0442\u0430 \u0443 \u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u0435\u043B\u044F "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConversationComponent_3)), i1.ɵdid(9, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.GetIconClassname(_co.featureBanExpand); _ck(_v, 6, 0, currVal_0); var currVal_1 = _co.featureBanExpand; _ck(_v, 9, 0, currVal_1); }, null); }
export function View_ConversationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "conversation-list"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "conversation-list__item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "conversation-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConversationComponent_1)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-chat-form", [], null, null, null, i7.View_ChatFormComponent_0, i7.RenderType_ChatFormComponent)), i1.ɵdid(6, 245760, null, 0, i8.ChatFormComponent, [i4.ChatConversationMessagesService], { sendNotificationOverEmail: [0, "sendNotificationOverEmail"], conversationWithUserId: [1, "conversationWithUserId"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConversationComponent_2)), i1.ɵdid(8, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.conversationWithUserId; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.sendNotificationOverEmail; var currVal_2 = _co.conversationWithUserId; _ck(_v, 6, 0, currVal_1, currVal_2); var currVal_3 = _co.conversationWithUserId; _ck(_v, 8, 0, currVal_3); }, null); }
export function View_ConversationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-conversation", [], null, null, null, View_ConversationComponent_0, RenderType_ConversationComponent)), i1.ɵdid(1, 114688, null, 0, i9.ConversationComponent, [i10.ActivatedRoute, i4.ChatConversationMessagesService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConversationComponentNgFactory = i1.ɵccf("app-conversation", i9.ConversationComponent, View_ConversationComponent_Host_0, {}, {}, []);
export { ConversationComponentNgFactory as ConversationComponentNgFactory };
