<div class="test-period-starts-now-title">
  Тестовый период начался!
</div>

<div class="test-period-starts-now-description">
  Вы можете пользоваться сервисом бесплатно 3 дня - просто выберите нужные функции.<br>
  Подключите рекламный кабинет ВКонтакте и ЯндексМетрику, чтобы управлять рекламой и получать подробные отчёты обо всех лидах.<br>
  Подключение происходит через официальный доступ и <strong>абсолютно безопасно</strong>.
</div>

<div class="test-period-starts-now-features-list">
  <ng-container *ngFor="let item of listItems">
    <div class="test-period-starts-now-features-list__item">
      <div class="test-period-starts-now-feature">
        <div class="test-period-starts-now-feature__controls">
          <div class="checkbox">
          <input [attr.id]="item.code + '-checkbox'" type="checkbox" [(ngModel)]="item.expand">
          <label [attr.for]="item.code + '-checkbox'">
            {{item.title}}
          </label>
          </div>
        </div>
        <ng-container *ngIf="item.expand">
          <div class="test-period-starts-now-feature-data">
            <div class="test-period-starts-now-feature-data__description" [innerHtml]="item.description">
            </div>
            <ng-container *ngIf="!item.isBinded">
              <div class="test-period-starts-now-feature-data__button">
                <button class="btn" (click)="BindProvider(item.code)">ПОДКЛЮЧИТЬ</button>
              </div>
            </ng-container>
            <ng-container *ngIf="item.isBinded">
              <div class="test-period-starts-now-feature-data__sucess">
                <div class="successfull-act">
                  <div class="successfull-act__icon">
                    <span class="icon ion-md-checkmark-circle-outline"></span>
                  </div>
                  <div class="successfull-act__title">
                    {{item.binded_title}}
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>

  <div class="successfull-act__button">
    <button class="btn btn-success" (click)="StartWorkButtonClick()" [disabled]="StartButtonDisabled()">
      Начать работу
    </button>
  </div>
</div>
