import {Injectable} from "@angular/core";
import {UserBalanceQuery} from "./user-balance.query";
import {UserBalanceService} from "./user-balance.service";

@Injectable({
  providedIn: 'root'
})
export class UserBalanceFacade {

  public get currentUserBalance$() {
    return this.query.currentUserBalance$;
  }

  constructor(
    private query: UserBalanceQuery,
    private service: UserBalanceService
  ) {
  }

  public LoadCurrentBalance() {
    this.service.GetUserBalance();
  }

}
