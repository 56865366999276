/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { APIReponseWrapperListAccessLimitation } from '../models/apireponse-wrapper-list-access-limitation';
import { APIReponseWrapperAccessLimitation } from '../models/apireponse-wrapper-access-limitation';
@Injectable({
  providedIn: 'root',
})
class IntegrationAccessService extends __BaseService {
  static readonly GetIntegrationsAccessSessionsPath = '/apiv2/integration-access';
  static readonly AppendIntegrationsAccessSessionsPath = '/apiv2/integration-access';
  static readonly GetCurrentAccessSessionPath = '/apiv2/integration-access/current';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `IntegrationAccessService.GetIntegrationsAccessSessionsParams` containing the following parameters:
   *
   * - `sortByDescend`:
   *
   * - `offset`:
   *
   * - `limit`:
   *
   * - `accessToBeforeOrEqual`:
   *
   * - `accessToAfterOrEqual`:
   *
   * - `accessFromBeforeOrEqual`:
   *
   * - `accessFromAfterOrEqual`:
   *
   * - `updatedBefore`:
   *
   * - `updatedAfter`:
   *
   * - `ids`:
   *
   * - `createdBefore`:
   *
   * - `createdAfter`:
   *
   * - `accessToBefore`:
   *
   * - `accessToAfter`:
   *
   * - `accessFromBefore`:
   *
   * - `accessFromAfter`:
   *
   * @return Success
   */
  GetIntegrationsAccessSessionsResponse(params: IntegrationAccessService.GetIntegrationsAccessSessionsParams): __Observable<__StrictHttpResponse<APIReponseWrapperListAccessLimitation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortByDescend != null) __params = __params.set('sortByDescend', params.sortByDescend.toString());
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    if (params.accessToBeforeOrEqual != null) __params = __params.set('accessToBeforeOrEqual', params.accessToBeforeOrEqual.toString());
    if (params.accessToAfterOrEqual != null) __params = __params.set('accessToAfterOrEqual', params.accessToAfterOrEqual.toString());
    if (params.accessFromBeforeOrEqual != null) __params = __params.set('accessFromBeforeOrEqual', params.accessFromBeforeOrEqual.toString());
    if (params.accessFromAfterOrEqual != null) __params = __params.set('accessFromAfterOrEqual', params.accessFromAfterOrEqual.toString());
    if (params.updatedBefore != null) __params = __params.set('updatedBefore', params.updatedBefore.toString());
    if (params.updatedAfter != null) __params = __params.set('updatedAfter', params.updatedAfter.toString());
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    if (params.createdBefore != null) __params = __params.set('createdBefore', params.createdBefore.toString());
    if (params.createdAfter != null) __params = __params.set('createdAfter', params.createdAfter.toString());
    if (params.accessToBefore != null) __params = __params.set('accessToBefore', params.accessToBefore.toString());
    if (params.accessToAfter != null) __params = __params.set('accessToAfter', params.accessToAfter.toString());
    if (params.accessFromBefore != null) __params = __params.set('accessFromBefore', params.accessFromBefore.toString());
    if (params.accessFromAfter != null) __params = __params.set('accessFromAfter', params.accessFromAfter.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/integration-access`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<APIReponseWrapperListAccessLimitation>;
      })
    );
  }
  /**
   * @param params The `IntegrationAccessService.GetIntegrationsAccessSessionsParams` containing the following parameters:
   *
   * - `sortByDescend`:
   *
   * - `offset`:
   *
   * - `limit`:
   *
   * - `accessToBeforeOrEqual`:
   *
   * - `accessToAfterOrEqual`:
   *
   * - `accessFromBeforeOrEqual`:
   *
   * - `accessFromAfterOrEqual`:
   *
   * - `updatedBefore`:
   *
   * - `updatedAfter`:
   *
   * - `ids`:
   *
   * - `createdBefore`:
   *
   * - `createdAfter`:
   *
   * - `accessToBefore`:
   *
   * - `accessToAfter`:
   *
   * - `accessFromBefore`:
   *
   * - `accessFromAfter`:
   *
   * @return Success
   */
  GetIntegrationsAccessSessions(params: IntegrationAccessService.GetIntegrationsAccessSessionsParams): __Observable<APIReponseWrapperListAccessLimitation> {
    return this.GetIntegrationsAccessSessionsResponse(params).pipe(
      __map(_r => _r.body as APIReponseWrapperListAccessLimitation)
    );
  }

  /**
   * @param params The `IntegrationAccessService.AppendIntegrationsAccessSessionsParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `accessTo`:
   *
   * - `accessFrom`:
   *
   * @return Success
   */
  AppendIntegrationsAccessSessionsResponse(params: IntegrationAccessService.AppendIntegrationsAccessSessionsParams): __Observable<__StrictHttpResponse<APIReponseWrapperAccessLimitation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.userId != null) __params = __params.set('userId', params.userId.toString());
    if (params.accessTo != null) __params = __params.set('accessTo', params.accessTo.toString());
    if (params.accessFrom != null) __params = __params.set('accessFrom', params.accessFrom.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/apiv2/integration-access`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<APIReponseWrapperAccessLimitation>;
      })
    );
  }
  /**
   * @param params The `IntegrationAccessService.AppendIntegrationsAccessSessionsParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `accessTo`:
   *
   * - `accessFrom`:
   *
   * @return Success
   */
  AppendIntegrationsAccessSessions(params: IntegrationAccessService.AppendIntegrationsAccessSessionsParams): __Observable<APIReponseWrapperAccessLimitation> {
    return this.AppendIntegrationsAccessSessionsResponse(params).pipe(
      __map(_r => _r.body as APIReponseWrapperAccessLimitation)
    );
  }

  /**
   * @return Success
   */
  GetCurrentAccessSessionResponse(): __Observable<__StrictHttpResponse<APIReponseWrapperAccessLimitation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/integration-access/current`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<APIReponseWrapperAccessLimitation>;
      })
    );
  }
  /**
   * @return Success
   */
  GetCurrentAccessSession(): __Observable<APIReponseWrapperAccessLimitation> {
    return this.GetCurrentAccessSessionResponse().pipe(
      __map(_r => _r.body as APIReponseWrapperAccessLimitation)
    );
  }
}

module IntegrationAccessService {

  /**
   * Parameters for GetIntegrationsAccessSessions
   */
  export interface GetIntegrationsAccessSessionsParams {
    sortByDescend: boolean;
    offset: number;
    limit: number;
    accessToBeforeOrEqual: boolean;
    accessToAfterOrEqual: boolean;
    accessFromBeforeOrEqual: boolean;
    accessFromAfterOrEqual: boolean;
    updatedBefore?: string;
    updatedAfter?: string;
    ids?: Array<number>;
    createdBefore?: string;
    createdAfter?: string;
    accessToBefore?: string;
    accessToAfter?: string;
    accessFromBefore?: string;
    accessFromAfter?: string;
  }

  /**
   * Parameters for AppendIntegrationsAccessSessions
   */
  export interface AppendIntegrationsAccessSessionsParams {
    userId: number;
    accessTo: string;
    accessFrom: string;
  }
}

export { IntegrationAccessService }
