import {Component, Input, OnInit} from '@angular/core';
import {Platform} from '../../stores/platforms/platform.model';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {PlatformsService} from '../../stores/platforms/platforms.service';
import {PlatformsTypes} from '../../platforms-types';
import {PlatformsQuery} from '../../stores/platforms/platforms.query';
import {ActivatedRoute} from '@angular/router';
import {distinctUntilChanged} from 'rxjs/operators';

@Component({
  selector: 'app-integration-platform-selector',
  templateUrl: './integration-platform-selector.component.html',
  styleUrls: ['./integration-platform-selector.component.scss']
})
export class IntegrationPlatformSelectorComponent implements OnInit {

  @Input() public label: string;
  @Input() public platforms: Platform[];
  @Input() public controlName: string;
  @Input() public isOrigin: boolean;
  @Input() public placeholder: string;
  @Input() public suggestionCode: string;

  public get uiPlatforms(): Platform[] {
    return this.platforms ? this.platforms.filter(x => x.code && x.code !== 'YANDEX_AUDIENCES' /*&&  x.code !== 'BITRIX_EXPORT'*/) : [];
  }

  public types = PlatformsTypes;

  public form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private platformsService: PlatformsService,
    private platformsQuery: PlatformsQuery,
    private route: ActivatedRoute
  ) {
  }

  public get id() {
    return this.isOrigin ? 'platform_selector_origin' : 'platform_selector_destination';
  }

  ngOnInit() {
    this.form = this.fb.group({});
    const control = this.fb.control(null);
    control.valueChanges.subscribe(platformCode => this.platformsService.SelectPlatform(platformCode, this.isOrigin));
    this.form.addControl(this.controlName, control);
    if (this.controlName === 'rightService') {
      this.platformsQuery.selectedOriginPlatform.subscribe(data => {
        this.form.get(this.controlName).setValue(null);
      });
    }
    //
    // this.route.queryParams
    //   .pipe(
    //     distinctUntilChanged()
    //   )
    //   .subscribe(({originPlatform}) => {
    //     if (originPlatform) {
    //       control.setValue(originPlatform);
    //     }
    //   });
  }
}
