/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./suggestion-info.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@clr/angular/clr-angular.ngfactory";
import * as i3 from "@clr/angular";
import * as i4 from "@angular/common";
import * as i5 from "./suggestion-info.component";
import * as i6 from "../../services/suggestions-service/app-suggestions.service";
var styles_SuggestionInfoComponent = [i0.styles];
var RenderType_SuggestionInfoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SuggestionInfoComponent, data: {} });
export { RenderType_SuggestionInfoComponent as RenderType_SuggestionInfoComponent };
function View_SuggestionInfoComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.suggestion == null) ? null : _co.suggestion.htmlContent); _ck(_v, 2, 0, currVal_0); }); }
function View_SuggestionInfoComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(0, null, [" \u0412\u043D\u0438\u043C\u0430\u043D\u0438\u0435 \u0434\u043B\u044F \u043F\u043E\u0434\u0441\u043A\u0430\u0437\u043A\u0438 ", " \u043D\u0435\u0442 \u043F\u043E\u0434\u0441\u043A\u0430\u0437\u043A\u0438. "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.code; _ck(_v, 0, 0, currVal_0); }); }
function View_SuggestionInfoComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "clr-signpost-content", [], [[4, "width", null], [2, "signpost-content", null], [8, "id", 0], [2, "is-off-screen", null]], null, null, i2.View_ClrSignpostContent_0, i2.RenderType_ClrSignpostContent)), i1.ɵprd(1024, null, i3.ɵo, i3.ɵp, []), i1.ɵdid(2, 8568832, null, 0, i3.ClrSignpostContent, [i1.Injector, [2, i3.ɵh], i3.ClrCommonStringsService, i3.ɵo, i3.ɵda], { position: [0, "position"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_SuggestionInfoComponent_2)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["warning", 2]], 0, 0, null, View_SuggestionInfoComponent_3))], function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.position; _ck(_v, 2, 0, currVal_4); var currVal_5 = _co.suggestion; var currVal_6 = i1.ɵnov(_v, 5); _ck(_v, 4, 0, currVal_5, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.width; var currVal_1 = true; var currVal_2 = i1.ɵnov(_v, 2).signpostContentId; var currVal_3 = i1.ɵnov(_v, 2).isOffScreen; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
export function View_SuggestionInfoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "suggestion-info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "clr-signpost", [], [[2, "signpost", null]], null, null, i2.View_ClrSignpost_0, i2.RenderType_ClrSignpost)), i1.ɵprd(6144, null, i3.ɵh, null, [i1.ElementRef]), i1.ɵdid(3, 49152, null, 1, i3.ClrSignpost, [i3.ClrCommonStringsService], null, null), i1.ɵqud(335544320, 1, { customTrigger: 0 }), i1.ɵprd(512, null, i3.ɵd, i3.ɵd, []), i1.ɵprd(512, null, i3.ɵda, i3.ɵda, []), (_l()(), i1.ɵeld(7, 0, null, 0, 2, "clr-icon", [["class", "signpost-trigger"], ["clrSignpostTrigger", ""], ["shape", "info-standard"]], [[1, "aria-label", 0], [1, "aria-expanded", 0], [1, "aria-controls", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).onSignpostTriggerClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 16384, null, 0, i3.ClrIconCustomTag, [], null, null), i1.ɵdid(9, 147456, [[1, 4]], 0, i3.ClrSignpostTrigger, [i3.ɵd, i1.Renderer2, i1.ElementRef, i3.ClrCommonStringsService, i3.ɵda, i1.PLATFORM_ID], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_SuggestionInfoComponent_1)), i1.ɵdid(11, 147456, null, 0, i3.ClrIfOpen, [i3.ɵd, i1.TemplateRef, i1.ViewContainerRef], { open: [0, "open"] }, null)], function (_ck, _v) { var currVal_4 = null; _ck(_v, 11, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = true; _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 9).commonStrings.keys.signpostToggle; var currVal_2 = i1.ɵnov(_v, 9).ariaExpanded; var currVal_3 = i1.ɵnov(_v, 9).ariaControl; _ck(_v, 7, 0, currVal_1, currVal_2, currVal_3); }); }
export function View_SuggestionInfoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-suggestion-info", [], null, null, null, View_SuggestionInfoComponent_0, RenderType_SuggestionInfoComponent)), i1.ɵdid(1, 114688, null, 0, i5.SuggestionInfoComponent, [i6.AppSuggestionsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SuggestionInfoComponentNgFactory = i1.ɵccf("app-suggestion-info", i5.SuggestionInfoComponent, View_SuggestionInfoComponent_Host_0, { code: "code", position: "position", width: "width" }, {}, []);
export { SuggestionInfoComponentNgFactory as SuggestionInfoComponentNgFactory };
