import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BitrixExportSettingsComponent } from './components/bitrix-export-settings/bitrix-export-settings.component';
import {MatRadioModule, MatSelectModule} from '@angular/material';
import {ReactiveFormsModule} from '@angular/forms';
import {VkControlsModule} from '../../../vk-controls/vk-controls.module';

@NgModule({
  declarations: [BitrixExportSettingsComponent],
  exports: [
    BitrixExportSettingsComponent
  ],
  imports: [
    CommonModule,
    MatRadioModule,
    ReactiveFormsModule,
    VkControlsModule,
    MatSelectModule
  ]
})
export class BitrixModule { }
