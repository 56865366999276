import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdManagerService, AutomationVkService, AutomationVkSlotService, UserStrategiesService } from '../../../../api/services';
import { AdViewModel } from '../../../models/view-models/ad-view-model';
import { delay, flatMap, map } from 'rxjs/operators';
import { of } from 'rxjs/internal/observable/of';
import { DomainOnlyPipe } from '../../../../shared/pipes/domain-only.pipe';
import { isNullOrUndefined } from 'util';
import { GroupsSelectorService } from '../../services/groups-selector/groups-selector.service';
import { AccountViewModel } from '../../../models/view-models/account-view-model';
import { DexieCabinetsService } from '../../../../shared/services/dexie/dexie-cabinets.service';
import { AutomationExpirationManagerService } from '../../../services/automation-expiration-manager.service';
import { DexieClientsService } from '../../../../shared/services/dexie/dexie-clients.service';
var UpdateAdComponent = /** @class */ (function () {
    function UpdateAdComponent(route, vkService, adManagerService, usService, router, groupService, _api, localDb, _db, accessManager, localClientsDb) {
        this.route = route;
        this.vkService = vkService;
        this.adManagerService = adManagerService;
        this.usService = usService;
        this.router = router;
        this.groupService = groupService;
        this._api = _api;
        this.localDb = localDb;
        this._db = _db;
        this.accessManager = accessManager;
        this.localClientsDb = localClientsDb;
        this.loading = false;
        this.strategy = null;
        this.imageLoaded = false;
        this.image = null;
        this.willBeUpdateStrategies = false;
        this.loaded = false;
    }
    UpdateAdComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.accountId = parseInt(this.route.snapshot.queryParams.account_id, 10);
        this.clientId = parseInt(this.route.snapshot.queryParams.client_id, 10);
        if (isNaN(this.clientId)) {
            this.clientId = null;
        }
        this.campaignId = parseInt(this.route.snapshot.queryParams.campaign_id, 10);
        this.adIds = this.route.snapshot.queryParams.adId.split(',').map(function (adId) { return parseInt(adId, 10); });
        this.groupService.ClearSelectedGroups();
        this.LoadData();
        setTimeout(function () {
            _this.LoadAds();
        }, 2000);
    };
    UpdateAdComponent.prototype.LoadData = function () {
        var _this = this;
        this.loadingAccounts = true;
        this.LoadSlots()
            .then(this.LoadAccounts.bind(this))
            .then(this.LoadClients.bind(this))
            .then(function () {
            _this.accounts = _this.accounts.map(function (account) {
                var slot = null;
                if (account.account_type === 'client') {
                    slot = _this.slots.find(function (x) { return x.bindedCabinetId === account.account_id && x.bindedClientId === account.client_id; });
                }
                else {
                    slot = _this.slots.find(function (x) { return x.bindedCabinetId === account.account_id; });
                }
                account.is_blocked = isNullOrUndefined(slot);
                _this.accounts = _this.accounts.sort(function (a, b) { return a.priority - b.priority; });
                _this.loadingAccounts = false;
                return account;
            });
        });
        // this.LoadSlots();
    };
    UpdateAdComponent.prototype.LoadSlots = function () {
        var _this = this;
        return new Promise(function (resolve) {
            _this._db.GetSlots()
                .subscribe(function (x) {
                _this.slots = x.data;
                // this.countOfMaxSlots = this.slots.length;
                // if (this.firstLoading) {
                //   this.opened = this.slots.filter(slot => slot.bindedCabinetId !== null).length === 0;
                // }
                // this.countOfFree = this.slots.filter(slot => slot.bindedCabinetId === null).length;
                // this.firstLoading = false;
                resolve();
            });
        });
    };
    UpdateAdComponent.prototype.LoadAccounts = function () {
        var _this = this;
        // this.accountsViewModels = [];
        return new Promise(function (resolve) {
            _this._api.GetAccounts()
                .subscribe(function (x) {
                _this.accounts = x.data.map(function (y, index) {
                    return new AccountViewModel(y.account_id, y.account_name, y.access_role, y.account_status, y.account_type, y.is_blocked, null, index);
                });
                resolve();
            });
        });
    };
    UpdateAdComponent.prototype.LoadClients = function () {
        var _this = this;
        return new Promise(function (resolve) {
            var promises = _this.accounts.filter(function (x) { return x.account_type === 'agency'; })
                .map(function (account, i) {
                return new Promise(function (resolve1) {
                    setTimeout(function () {
                        _this._api.getClients(account.account_id)
                            .delay(3000)
                            .subscribe(function (clients) {
                            clients.data.forEach(function (client) {
                                _this.accounts.push(new AccountViewModel(account.account_id, client.name, account.account_role, account.account_status, 'client', account.is_blocked, client.id, account.priority));
                            });
                            _this.localClientsDb.SetClients(clients.data)
                                .then(function (data) {
                                resolve1();
                            });
                        });
                    }, 1500 * i);
                });
            });
            return Promise.all(promises)
                .then(function () {
                resolve();
            });
        });
    };
    UpdateAdComponent.prototype.LoadAds = function () {
        var _this = this;
        this.vkService.GetAds({
            accountId: this.accountId,
            campaignIds: [this.campaignId],
            clientId: this.clientId,
            adIds: this.adIds,
            includeDeleted: false,
            limit: 0,
            offset: 0
        })
            .pipe(delay(1000), map(function (_a) {
            var data = _a.data;
            _this.ads = data.map(function (x) {
                var ad = new AdViewModel();
                ad.ConvertAd(x);
                return ad;
            });
            return _this.ads;
        }), flatMap(function (ads) {
            return _this.vkService.GetAdsTargeting({
                accountId: _this.accountId,
                campaignIds: [_this.campaignId],
                clientId: _this.clientId,
                adIds: _this.adIds,
                includeDeleted: false,
                limit: 0,
                offset: 0
            })
                .delay(2000)
                .map(function (data) {
                console.log(data);
                _this.targetingSettings = data['data'][0];
                if (_this.targetingSettings) {
                    _this.targetingSettings.country = _this.targetingSettings ? parseInt(_this.targetingSettings.country, 10) : 0;
                    _this.targetingSettings.cities = _this.targetingSettings && _this.targetingSettings.cities ? _this.targetingSettings.cities.split(',').map(function (city) { return parseInt(city, 10); }) : [];
                }
                return ads;
            })
                .delay(1000);
        }), flatMap(function (ads) {
            console.log('Start loading Layouts');
            console.log(ads);
            var obs = _this.vkService.GetAdsLayout({
                accountId: _this.accountId,
                campaignIds: [_this.campaignId],
                clientId: _this.clientId,
                adIds: _this.adIds,
                includeDeleted: false,
                limit: 0,
                offset: 0
            })
                .map(function (_a) {
                var data = _a.data;
                data.map(function (adLayout) {
                    var ad = _this.ads.find(function (ad1) { return ad1.id === adLayout.id; });
                    ad.AddLayout(adLayout, null);
                    return ads;
                });
                console.log(_this.ads);
                return _this.ads;
            })
                .delay(1000);
            return obs;
        }), flatMap(function (ads) {
            console.log(ads);
            console.log('Start loading Wall');
            var obs = of(ads);
            if (ads[0].ad_format === 9) {
                console.log('Start will be loaded');
                var postIds = ads
                    .map(function (x) {
                    console.log(x);
                    return x.link_url.replace('http://vk.com/wall', '');
                })
                    .join();
                obs = _this.vkService.GetWallById(postIds)
                    .map(function (_a) {
                    var data = _a.data;
                    _this.ads.map(function (ad) {
                        var newIds = ad.link_url
                            .replace('http://vk.com/wall', '')
                            .split('_')
                            .map(function (adl) { return parseInt(adl, 10); });
                        var w = data.find(function (wall) { return wall.owner_id === newIds[0] && wall.id === newIds[1]; });
                        ad.AddWall(w);
                        console.log(newIds);
                    });
                    return data;
                })
                    .delay(1000);
            }
            return obs;
        }), flatMap(function () { return _this.LoadStrategy(); }))
            .subscribe(function (data) {
            console.log(data);
            _this.loaded = true;
        });
    };
    UpdateAdComponent.prototype.OnSaveAd = function (_a) {
        var _this = this;
        var ad = _a.ad, strategy = _a.strategy, updateStrategy = _a.updateStrategy;
        var adData = ad;
        this.loading = true;
        var images = adData.teazer.image || adData.promopost.image;
        var texts = (this.ads[0].ad_format !== 9) ? adData.teazer.title : adData.promopost.text;
        var ads = [];
        images.forEach(function (image) {
            texts.forEach(function (text) {
                console.log(image, text);
                ads.push(_this.CreateData(adData, text, image));
            });
        });
        console.log(ads);
        this.adManagerService.UpdateAd(ads)
            .subscribe(function (data) {
            _this.loading = false;
            console.log(updateStrategy);
            var _a = adData.campaignData.accountId.split('_'), accountId = _a[0], clientId = _a[1];
            console.log(strategy && strategy !== {}, strategy, strategy !== {});
            console.log(adData.moderationSettings, adData);
            _this.SendModeration(adData.moderationSettings, accountId, clientId, data.map(function (ad1) { return ad1.id; }))
                .subscribe(function () {
                console.log(adData.rate.type === 'auto' && !(strategy === null || Object.keys(strategy).length === 0), adData.rate.type, strategy);
                if (adData.rate.type === 'auto' && !(strategy === null || Object.keys(strategy).length === 0)) {
                    console.log('strategy update');
                    var obs = null;
                    console.log(isNullOrUndefined(_this.strategy), _this.strategy);
                    if (!isNullOrUndefined(_this.strategy)) {
                        obs = _this.usService.UpdateUserStrategies({
                            accountId: parseInt(accountId, 10),
                            clientId: clientId !== 'null' ? parseInt(clientId, 10) : 0,
                            ads: data.map(function (x) { return x.id; }),
                            properties: strategy.data,
                            strategyType: strategy.type,
                            updateStep: 10
                        });
                    }
                    else {
                        obs = _this.usService.CreateUserStrategy({
                            accountId: parseInt(accountId, 10),
                            clientId: clientId !== 'null' ? parseInt(clientId, 10) : 0,
                            ads: data.map(function (x) { return x.id; }),
                            properties: strategy.data,
                            strategyType: strategy.type,
                            updateStep: 10
                        });
                    }
                    obs
                        .subscribe(function (data1) {
                        _this.NavigateToAds(adData, accountId, clientId, data);
                    });
                }
                else {
                    console.log('Strategies not remove');
                    console.log(strategy);
                    console.log((strategy !== null && Object.keys(strategy).length === 0) && updateStrategy);
                    if (_this.strategy && (strategy !== null && Object.keys(strategy).length === 0) && updateStrategy) {
                        console.log('Strategies to remove');
                        _this.RemoveStrategy(accountId, data[0].id)
                            .subscribe(function () {
                            setTimeout(function () {
                                _this.NavigateToAds(adData, accountId, clientId, data);
                            }, 1000);
                        });
                    }
                    else {
                        setTimeout(function () {
                            _this.NavigateToAds(adData, accountId, clientId, data);
                        }, 1000);
                    }
                }
            }, function () {
                if (strategy !== null && Object.keys(strategy).length > 0) {
                    _this.usService.UpdateUserStrategies({
                        accountId: parseInt(accountId, 10),
                        clientId: clientId !== 'null' ? parseInt(clientId, 10) : 0,
                        ads: data.map(function (x) { return x.id; }),
                        properties: strategy.data,
                        strategyType: strategy.type,
                        updateStep: 10
                    })
                        .subscribe(function (data1) {
                        _this.NavigateToAds(adData, accountId, clientId, data);
                    });
                }
                else {
                    setTimeout(function () {
                        _this.NavigateToAds(adData, accountId, clientId, data);
                    }, 1000);
                }
            });
        });
    };
    UpdateAdComponent.prototype.SendModeration = function (moderationSettings, accountId, clientId, adIds) {
        var _this = this;
        if (moderationSettings.sendModeration) {
            return this.usService.ToggleAdStatus({
                accountId: accountId,
                adIds: adIds,
                status: true
            })
                .map(function (toggleStatusResponse) {
                var errorCodes = toggleStatusResponse
                    .filter(function (error) { return error.error_code !== null; })
                    .map(function (response) { return response.error_code; });
                if (errorCodes.length > 0) {
                    window.localStorage.setItem('send_moderation', JSON.stringify(errorCodes));
                }
                return toggleStatusResponse;
            })
                .pipe(flatMap(function () {
                if (!moderationSettings.startAfterModeration) {
                    return _this.usService.ToggleAdStatus({
                        accountId: accountId,
                        adIds: adIds,
                        status: false
                    })
                        .map(function (toggleStatusResponse) {
                        var errorCodes = toggleStatusResponse
                            .filter(function (error) { return error.error_code !== null; })
                            .map(function (response) { return response.error_code; });
                        if (errorCodes.length > 0) {
                            window.localStorage.setItem('start_after_moderation', JSON.stringify(errorCodes));
                        }
                        return toggleStatusResponse;
                    })
                        .map(function (data) { return false; });
                }
                else {
                    return of(true);
                }
            }));
        }
        else {
            return of(false);
        }
    };
    UpdateAdComponent.prototype.NavigateToAds = function (adData, accountId, clientId, data) {
        console.log(adData.campaignData.campaignId, 'campaign');
        this.router.navigate(['/automation/ads'], {
            queryParams: {
                sendModeration: adData.moderationSettings.sendModeration,
                startAfterModeration: adData.moderationSettings.startAfterModeration,
                account_id: parseInt(accountId, 10),
                client_id: clientId !== 'null' ? parseInt(clientId, 10) : null,
                campaign_id: adData.campaignData.campaignId,
                adId: data[0].id
            }
        });
    };
    UpdateAdComponent.prototype.CreateData = function (adData, text, image) {
        console.log(adData);
        var ad = {
            ad_id: adData.adId[0],
            ad_format: adData.format.ad_format,
            age_from: adData.targetingData.ageFrom,
            age_to: adData.targetingData.ageTo,
            attachments: '',
            // campaign_id: adData.campaignData.campaignId,
            cities: adData.targetingData.cities.split(',').filter(function (x) { return parseInt(x, 10) >= 0; }).join(),
            country: adData.targetingData.country.toString().split(',').filter(function (x) { return parseInt(x, 10) >= 0; }).join(),
            sex: adData.targetingData.sex,
            groups: adData.targetingData.groups,
            title: text,
            image: image,
            link_url: encodeURIComponent(adData.teazer.link),
            link_domain: new DomainOnlyPipe().transform(adData.teazer.link),
            cpm: adData.rate.cpm,
            category1_id: adData.format.ad_format === 9 ? adData.promopost.categoryId : adData.teazer.categoryId,
            name: adData.format.ad_format === 9 ? adData.promopost.name : adData.teazer.name
            // cost_type: 1
        };
        console.log(adData, adData.targetingData.cities, 'TARGET CITIES');
        var adViewModel = {
            adSpecifications: [ad],
            accountId: adData.campaignData.accountId.split('_')[0],
            groupId: adData.promopost.groupId,
            text: text,
            linkTitle: adData.promopost.linkTitle,
            linkUrl: encodeURIComponent(adData.promopost.linkAddress),
            postId: (this.ads[0].ad_format === 9) ? parseInt(this.ads[0].link_url.split('_')[1], 10) : 0
        };
        return adViewModel;
    };
    UpdateAdComponent.prototype.LoadStrategy = function () {
        var _this = this;
        return this.usService.GetStrategiesByAdAndAccountIds({
            accountId: this.accountId,
            viewModel: {
                adIds: this.adIds
            },
            clientId: this.clientId
        })
            .map(function (strategies) {
            console.log(strategies);
            _this.strategy = strategies[0];
        });
    };
    UpdateAdComponent.prototype.RemoveStrategy = function (accountId, adId) {
        return this.usService.RemoveStrategy({
            accountId: accountId,
            adId: adId
        });
    };
    return UpdateAdComponent;
}());
export { UpdateAdComponent };
