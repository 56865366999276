import {Injectable} from '@angular/core';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {AdManagerService, AutomationVkService, AutomationVkSlotService} from '../../../api/services';
import {CopyingConfigurator} from '../copying-configurators/copying-configurator';
import {PostMonitorBackend_V2ModelsAdManagerCopyAdsViewModel} from '../../../api/models';
import {AccountViewModel} from '../../models/view-models/account-view-model';
import {CreateCampaignFormComponent} from '../../ad-manager/components/create-campaign-form/create-campaign-form.component';
import {isNullOrUndefined} from 'util';
import {MatDialog} from '@angular/material';
import {delay, distinctUntilChanged, filter, retry} from 'rxjs/operators';
import {MatConfirmDialogComponent} from '../../ad-manager/components/modals/configrm-dialog/mat-confirm-dialog.component';
import {combineLatest} from 'rxjs';
import {AdViewModel} from '../../models/view-models/ad-view-model';

export enum CopyingFormState {
  DEFAULT_FORM = 'DEFAULT_FORM',
  COPYING_PROCESS = 'COPYING_PROCESS',
  COPYING_SUCCESS = 'COPYING_SUCCESS',
  COPYING_FAILURE = 'COPYING_FAILURE'
}

@Injectable({
  providedIn: 'root'
})
export class CopyingService {

  public form: FormGroup;
  public adsToCopying: PostMonitorBackend_V2ModelsAdManagerCopyAdsViewModel[] = [];
  public slots: any;
  public loadingAdvancedScreen: boolean;
  public accounts: any;
  public campaigns: any;
  public ad_format: any;
  public openedAdvancedMode: boolean;
  public state: CopyingFormState = CopyingFormState.DEFAULT_FORM;
  public countOfCopyedAds: number = 0;
  public ShowRedirectUrl: boolean = false;
  public notCopyedAds = [];
  public errorMessage: string = '';
  public copyesWithErrors = [];
  public ads: AdViewModel[] = [];
  public adsWithCopyingErrors: any[];

  constructor(
    private adManagerService: AdManagerService,
    private automationVkService: AutomationVkService,
    private automationVkSlotService: AutomationVkSlotService,
    private dialogService: MatDialog,
  ) {
    this.form = new FormGroup({
      accountId: new FormControl(),
      campaignId: new FormControl(),
      targetAccountId: new FormControl(),
      targetCampaignId: new FormControl(),
      adIds: new FormControl(),
      configurationMode: new FormControl('DEFAULT_COPYING'),
      costType: new FormControl(null),
      costValue: new FormControl(0),
      generateLinks: new FormControl(false),

      defaultConfiguration: new FormGroup({
        countOfCopies: new FormControl(1, [Validators.required, Validators.min(1), Validators.max(100)])
      }),

      groupsConfiguration: new FormGroup({
        groupsList: new FormControl(),
        excludeOtherGroups: new FormControl(false)
      }),

      groupsFromFileConfiguration: new FormGroup({
        groupsList: new FormControl(),
        excludeOtherGroups: new FormControl(false)
      }),

      sexAndAgeConfiguration: new FormGroup({
        ageItems: new FormArray([])
      }),

      retargetingGroupConfiguration: new FormGroup({
        retargetingGroups: new FormControl(),
        excludeOtherGroups: new FormControl()
      }),

      groupActiveFromFile: new FormGroup({
        groupActiveList: new FormControl()
      }),

      groupActive: new FormGroup({
        groupActiveList: new FormControl()
      }),

      interestCategoriesList: new FormGroup({
        interestIds: new FormControl()
      }),

      interestCategoriesCheckboxes: new FormGroup({
        interestIds: new FormControl()
      }),

      linkSettings: new FormGroup({
        linksData: new FormControl()
      })
    });

    this.form.get('targetAccountId').valueChanges
      .pipe(
        distinctUntilChanged(),
      )
      .subscribe(value => {
        if (value) {
          const [accountId, clientId] = value.split('_').map(x => x === 'null' ? null : parseInt(x, 10));
          this.LoadCampaigns(accountId, clientId)
            .subscribe();
        }
      });

    combineLatest(
      this.form.get('targetAccountId').valueChanges,
      this.form.get('targetCampaignId').valueChanges
    )
      .pipe(
        filter(([accountId, campaignId]) => accountId !== false || campaignId !== false)
      )
      .subscribe(([targetAccountId, targetCampaignId]) => {
        const {accountId, campaignId} = this.form.value;
        console.log(targetAccountId, accountId, targetCampaignId, campaignId);
        console.log(targetAccountId !== accountId, targetCampaignId !== campaignId);
        console.log(targetAccountId !== accountId || targetCampaignId !== campaignId);
        this.ShowRedirectUrl = targetAccountId === accountId || targetCampaignId === campaignId;
      });
  }

  public reset() {
    this.form = new FormGroup({
      accountId: new FormControl(),
      campaignId: new FormControl(),
      targetAccountId: new FormControl(),
      targetCampaignId: new FormControl(),
      adIds: new FormControl(),
      configurationMode: new FormControl('DEFAULT_COPYING'),
      costType: new FormControl(null),
      costValue: new FormControl(0),
      generateLinks: new FormControl(false),

      defaultConfiguration: new FormGroup({
        countOfCopies: new FormControl(1)
      }),

      groupsConfiguration: new FormGroup({
        groupsList: new FormControl(),
        excludeOtherGroups: new FormControl(false)
      }),

      groupsFromFileConfiguration: new FormGroup({
        groupsList: new FormControl(),
        excludeOtherGroups: new FormControl(false)
      }),

      sexAndAgeConfiguration: new FormGroup({
        ageItems: new FormArray([])
      }),

      retargetingGroupConfiguration: new FormGroup({
        retargetingGroups: new FormControl(),
        excludeOtherGroups: new FormControl()
      }),

      groupActiveFromFile: new FormGroup({
        groupActiveList: new FormControl()
      }),

      groupActive: new FormGroup({
        groupActiveList: new FormControl()
      }),

      interestCategoriesList: new FormGroup({
        interestIds: new FormControl()
      }),

      interestCategoriesCheckboxes: new FormGroup({
        interestIds: new FormControl()
      }),

      linkSettings: new FormGroup({
        linksData: new FormControl()
      })
    });
  }

  public addSexAndAgeGroup() {
    (this.form.get('sexAndAgeConfiguration').get('ageItems') as FormArray).push(new FormGroup({
      ageFrom: new FormControl(),
      ageTo: new FormControl(),
      eachAge: new FormControl(false),
      sex: new FormControl(0)
    }));
    console.log(this.form.value, 'FORM VALUE');
  }

  public removeSexAndAgeGroup(i) {
    (this.form.get('sexAndAgeConfiguration').get('ageItems') as FormArray).removeAt(i);
  }

  public PrepearData() {
    this.adsToCopying = [];
    this.adsToCopying = CopyingConfigurator.configure(this.form.value.configurationMode, this.form.value);
  }

  public async CopyAds() {
    this.PrepearData();
    this.dialogService.open(MatConfirmDialogComponent, {
      data: {
        title: `Подтвердите копирование объявлений`,
        description: `Скопировать выбранные объявления в колличестве ${this.adsToCopying.length} шт.`,
        confirmActionText: 'Скопировать',
        rejectActionText: 'Отменить'
      }
    })
      .afterClosed()
      .subscribe(async status => {
        if (status) {
          this.state = CopyingFormState.COPYING_PROCESS;
          let containsErrors = false;
          for (const ad of this.adsToCopying) {
            try {
              const copyedAd = await this.adManagerService.CopyAds(ad)
                .pipe(
                  delay(1000),
                  retry(2)
                )
                .toPromise();
              this.countOfCopyedAds++;
            } catch (e) {
              containsErrors = true;
              this.notCopyedAds.push(ad);
              const newError = e.error;
              if (newError && newError.code) {
                const isErrorForAdding = this.copyesWithErrors.map(err => err.code).includes(newError.code);
                if (isErrorForAdding) {
                  this.copyesWithErrors.push(newError);
                }
              }
              const ad1 = this.ads.find(x => x.id === ad.adIds[0]);
              const newAd = {
                name: `Копия ${ad.numberOfCopy} ${ad1.name}`
              };
              this.adsWithCopyingErrors.push({
                ad: newAd,
                error: newError
              });
            }
            console.log('asd');
          }
          if (containsErrors) {
            this.state = CopyingFormState.COPYING_FAILURE;
          } else {
            this.state = CopyingFormState.COPYING_SUCCESS;
          }
        }
      });
  }

  public init(
    accountId: string,
    campaignId: string,
    targetAccountId,
    targetCampaignId: string,
    ads: AdViewModel[],
    ad_format,
    groups,
    retargeting_groups,
    retargeting_groups_not,
    groupActives,
    interestGroups,
    link
  ) {
    console.log(ad_format,
      groups,
      retargeting_groups,
      retargeting_groups_not,
      groupActives,
      interestGroups);
    const adIds = ads.map(x => x.id);
    console.log({
      accountId,
      campaignId,
      targetAccountId,
      targetCampaignId
    });
    this.form.patchValue({
      accountId,
      campaignId,
      adIds,
      groupsConfiguration: {
        groupsList: groups
      },
      retargetingGroupConfiguration: {
        retargetingGroups: retargeting_groups,
        groups_not: retargeting_groups_not
      },
      groupActive: {
        groupActiveList: groupActives
      },
      interestCategoriesList: {
        interestIds: interestGroups
      }
    });
    this.form.patchValue({
      targetAccountId,
      targetCampaignId,
    }, {emitEvent: false});
    this.form.patchValue({
      linkSettings: {
        linksData: {
          host: link
        }
      }
    }, {emitEvent: true});
    console.log({
      linkSettings: {
        linksData: {
          host: link
        }
      }
    }, 'LINK SETTINGS');
    this.ad_format = ad_format;
    this.ads = ads;
    this.countOfCopyedAds = 0;
  }

  public LoadCabinetData() {
    this.loadingAdvancedScreen = true;
    return this.LoadSlots()
      .flatMap(() => this.LoadAccounts())
      .flatMap(() => {
        const [accountId, clientId] = this.form.value.targetAccountId.split('_');
        return this.LoadCampaigns(accountId, clientId);
      });
  }

  public LoadSlots() {
    return this.automationVkSlotService.GetSlots()
      .do(slotsResponse => {
        this.slots = slotsResponse.data;
      });
  }

  public LoadAccounts() {
    return this.automationVkService.GetAccounts()
      .do(async accountsResponse => {
        const accounts = accountsResponse.data
          .map((cabinet, i) => this.CreateNewCabinet(cabinet, i));

        const agencies = accounts.filter(account => account.account_type === 'agency');

        for (const account of agencies) {
          const clients = await this.LoadClients(account.account_id);
          for (const client of clients) {
            accounts.push(new AccountViewModel(
              account.account_id,
              client.name,
              account.account_role,
              account.account_status,
              'client',
              this.IsAccountBlocked(account.account_id, client.id),
              client.id,
              account.priority
            ));
          }
        }

        this.accounts = accounts.filter(x => x.account_type !== 'agency');
      })
      .delay(1000);
  }

  private CreateNewCabinet(cabinet, i) {
    const {account_id, account_name, access_role, account_status, account_type, is_blocked, client_id} = cabinet;
    return new AccountViewModel(
      account_id,
      account_name,
      access_role,
      account_status,
      account_type,
      this.IsAccountBlocked(account_id, client_id),
      client_id,
      i
    );
  }

  public LoadClients(accountId) {
    return this.automationVkService.getClients(accountId)
      .map(clientsResponse => clientsResponse.data)
      .delay(1000)
      .toPromise();
  }

  public LoadCampaigns(accountId, clientId) {
    console.log(accountId);
    return this.automationVkService.GetCampaigns({
      accountId,
      clientId
    })
      .do(campaignResponse => {
        console.log('Setting Campaigns');
        console.log(campaignResponse.data);
        this.campaigns = campaignResponse.data.filter(campaign => campaign.type === this.getType())
          .sort((a, b) => b.id - a.id);

        this.form.controls.targetCampaignId.setValue(this.campaigns[0].id);
      })
      .delay(1000);
  }

  OpenCreateCampaignForm() {
    const [accountId, clientId] = this.form.value.targetAccountId.split('_');
    this.dialogService.open(CreateCampaignFormComponent, {
      data: {
        clientId,
        type: this.getType(),
        accountId
      }
    })
      .afterClosed()
      .subscribe(data => {
        const [accountId, clientId] = this.form.value.targetAccountId.split('_');
        this.LoadCampaigns(accountId, clientId)
          .subscribe(() => {
          });
      });
  }

  private IsAccountBlocked(accountId: number, clientId: number) {
    const slotWithAccount = this.slots.find(slot => slot.bindedCabinetId === accountId && slot.bindedClientId === clientId);
    console.log(`Slot for account ${accountId} and client ${clientId}: `, slotWithAccount);
    return isNullOrUndefined(slotWithAccount);
  }

  private getType() {
    return this.ad_format === 9 ? 'promoted_posts' : 'normal';
  }

  public ToggleAdvancedMode() {
    this.openedAdvancedMode = !this.openedAdvancedMode;
    if (this.openedAdvancedMode) {
      this.LoadCabinetData()
        .subscribe(() => {
          setTimeout(() => {
            this.loadingAdvancedScreen = false;
          }, 3000);
        });
    }
  }
}
