import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PostMonitorBackend_V2ModelsCrmEntitesLead} from '../../../../api/models/post-monitor-backend-_v2models-crm-entites-lead';
import {PostMonitorBackend_V2ModelsCrmEntitesLeadCall} from '../../../../api/models/post-monitor-backend-_v2models-crm-entites-lead-call';

@Component({
  selector: 'app-user-calls',
  templateUrl: './user-calls.component.html',
  styleUrls: ['./user-calls.component.scss']
})
export class UserCallsComponent implements OnInit {

  @Input() public userCalls: Array<PostMonitorBackend_V2ModelsCrmEntitesLeadCall>;
  @Input() public callStatuses: any;

  @Output() public callStatusChanged: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit() {
  }

  trackFn(index, item) {
    return index;
  }

  ChangeStatus() {
    this.callStatusChanged.emit();
  }
}
