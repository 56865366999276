import {Component, OnInit} from '@angular/core';
import {PostMonitorBackend_V2ModelsvkVKAPIGroupModel} from '../../../../../api/models/post-monitor-backend-_v2modelsvk-vkapigroup-model';
import {AdFormManagerService} from '../../../new-ad-manager-shared/services/ad-form-manager/ad-form-manager.service';

@Component({
  selector: 'app-new-teazer-form-lite',
  templateUrl: './new-teazer-form-lite.component.html',
  styleUrls: ['./new-teazer-form-lite.component.scss']
})
export class NewTeazerFormLiteComponent implements OnInit {

  public controlsWidth: string = '256px';
  public labelsWidth: string = '140px';
  public IsAdToGroup: boolean = true;

  public get HasOneGroup(): any {
    return this.adminGroups.length === 1;
  }

  public get FirstGroup() {
    return this.adminGroups[0];
  }

  public get adminGroups(): Array<PostMonitorBackend_V2ModelsvkVKAPIGroupModel> {
    return this.adFormManagerService.adminGroups;
  }

  public get form() {
    return this.adFormManagerService.getControl('teazer');
  }

  public get TeazerLength() {
    return 33 - this.adFormManagerService.getTeazerTextLength(0);
  }

  constructor(
    private adFormManagerService: AdFormManagerService
  ) {
  }

  ngOnInit() {
  }

  public ImageSelected(image: string) {
    console.log(image);
    this.adFormManagerService.AddTeazerImage(image);
  }
}
