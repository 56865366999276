import {PostMonitorBackend_V2ModelsvkVKAPIGroupModel} from '../../../api/models';

export interface IVkGroupViewModel extends PostMonitorBackend_V2ModelsvkVKAPIGroupModel {
  isSelected: boolean;
}

export class VkGroupViewModel implements IVkGroupViewModel {
  public isSelected: boolean = false;

  public fill(group: PostMonitorBackend_V2ModelsvkVKAPIGroupModel) {
    Object.keys(group)
      .forEach(key => {
        this[key] = group[key];
      });

    return this;
  }
}
