var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Query } from '@datorama/akita';
import { IntegrationSessionStore } from './integration-session.store';
import * as i0 from "@angular/core";
import * as i1 from "./integration-session.store";
var IntegrationSessionQuery = /** @class */ (function (_super) {
    __extends(IntegrationSessionQuery, _super);
    function IntegrationSessionQuery(store) {
        var _this = _super.call(this, store) || this;
        _this.store = store;
        /**
         * Текущая сессия пользователя
         */
        _this.currentSession$ = _this.select(function (state) { return state.session; });
        /**
         * Происходит ли загрузка данных
         */
        _this.isLoading$ = _this.selectLoading();
        return _this;
    }
    IntegrationSessionQuery.ngInjectableDef = i0.defineInjectable({ factory: function IntegrationSessionQuery_Factory() { return new IntegrationSessionQuery(i0.inject(i1.IntegrationSessionStore)); }, token: IntegrationSessionQuery, providedIn: "root" });
    return IntegrationSessionQuery;
}(Query));
export { IntegrationSessionQuery };
