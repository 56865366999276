<div class="ad-target-resource-selector">
  <h4 class="ad-target-resource-selector__title">Выберите куда будут переходить пользователи с объявления</h4>
  <mat-radio-group class="ad-target-resource-selector__list" [(ngModel)]="currentValue" (change)="Change()">
    <ng-container *ngFor="let adSource of adSources">
      <mat-radio-button
        [value]="adSource.name"
        class="ad-target-resource-selector__item ad-target-resource-selector-item"
        [id]="GetItemId(adSource.name)"
        color="primary"
      >
        <div class="ad-target-resource-selector-item__name" [innerHTML]="adSource.title"></div>
      </mat-radio-button>
    </ng-container>
  </mat-radio-group>
</div>
