import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {ControlContainer, FormControl, FormGroup, Validators} from '@angular/forms';
import {AdManagerService, StrategiesService} from '../../../../../api/services';
import {UtilsService} from '../../../../../shared/services/utils.service';
import {Strategy} from '../../../../../api/models';
import {forkJoin} from 'rxjs/internal/observable/forkJoin';
import {StrategiesManagerComponent} from '../../../../../shared/components/strategies-manager/strategies-manager.component';
import {pairwise} from 'rxjs/operators';
import {MatDialog} from "@angular/material";
import {CreateCampaignFormComponent} from "../../create-campaign-form/create-campaign-form.component";
import {RateManagerExplainComponent} from "../../rate-manager-explain/rate-manager-explain.component";
import {BidRange} from "../../../../../api/models/bid-range";

@Component({
  selector: 'app-rate-manager',
  templateUrl: './rate-manager.component.html',
  styleUrls: ['./rate-manager.component.scss']
})
export class RateManagerComponent implements OnInit, OnChanges, AfterViewInit {

  @Input() public adType = 9;
  @Input() public currentStrategy;
  @Output() public onNext: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('strategiesManager') adManagerComp: StrategiesManagerComponent;

  public rates: Array<BidRange> = [];
  public form: FormGroup;
  public isLoading = false;
  public errors: any;
  public strategies: Array<Strategy>;
  public displayedStrategies = [];
  public strategy = {};

  public get currentRate() {
    return this.rates.find(x => x.adFormat === this.adType) || null;
  }

  constructor(
    private adManagerService: AdManagerService,
    private strategiesService: StrategiesService,
    public controlContainer: ControlContainer,
    private dialog: MatDialog
  ) {
  }

  ngOnInit() {
    this.isLoading = true;
    console.log(this.currentStrategy);
    forkJoin(
      this.adManagerService.GetAdRates(),
      this.LoadStrategies()
    )
      .subscribe(([rates, strategies]) => {
        this.strategies = strategies;
        this.displayedStrategies = this.strategies.map(strategy => ({
          title: strategy.title,
          code: strategy.code,
          description: strategy.descriptionPreview,
          inputs: strategy.inputs.map(strategyInput => ({
            label: strategyInput.title,
            name: strategyInput.code,
          }))
        }));
        this.rates = rates;
        console.log(rates);

        if (this.rates.length > 0) {
          const rate1 = this.rates.find(rate => rate.adFormat === this.adType).cpmMin;
          this.controlContainer.control.get('cpm').setValue(rate1 / 100);
        }

        this.controlContainer.control.get('type').valueChanges
          .subscribe(rateType => {
            console.log(rateType);
            if (rateType === 'auto') {
              console.log(this.adManagerComp);
              this.adManagerComp.adForm.SuccessAction();
              console.log(this.adManagerComp.adForm.form);
              this.adManagerComp.adForm.form.valueChanges.subscribe(data => {
                console.log(data, 'LINE 75');
                this.controlContainer.control.get('value').setValue(data);
              });
            } else {
              this.controlContainer.control.get('value').setValue(null);
              this.controlContainer.control.get('value').markAsDirty();
            }
          });
        this.isLoading = false;
      });
  }

  public LoadStrategies() {
    return this.strategiesService.GetAllStrategies({
      onlyAllowedInCreationModule: true,
      showInactive: false
    });
  }

  OnSave(data) {
    console.log(data);
    this.strategy = data;
    this.controlContainer.control.get('value').setValue(data.data);
  }

  OnNext(data) {
    if (this.form.controls.rateType.value === 'auto') {
      this.adManagerComp.adForm.SuccessAction();
      this.onNext.emit({
        type: 'auto',
        value: this.strategy
      });
    } else {
      this.onNext.emit({
        type: 'custom',
        value: this.form.value.rate
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);
    if (changes.adType) {
      const valueMin = ((this.currentRate) ? this.currentRate.cpmMin : 0) / 100;
      const valueMax = ((this.currentRate) ? this.currentRate.cpmMax : 0) / 100;
      console.log(valueMax);
      const value = (this.form) ? this.form.value.rate : valueMin;
      if (this.rates.length > 0) {
        const rate1 = this.rates.find(rate => rate.adFormat === this.adType).cpmMin;
        this.controlContainer.control.get('cpm').setValue(rate1 / 100);
        this.controlContainer.control.get('cpm').setValidators([
          Validators.required,
          Validators.min(valueMin),
          Validators.max(valueMax),
        ]);
      }
      this.form = new FormGroup({
        rateType: new FormControl('auto', [Validators.required]),
        rate: new FormControl(valueMin, [Validators.required, Validators.min(valueMin), Validators.max(valueMax)])
      });
      this.controlContainer.control.valueChanges
        .subscribe(x => {
          console.log(this.controlContainer.control.valid);
          this.errors = {};
          if (!this.controlContainer.control.valid) {
            this.errors = UtilsService.GetErrorsByForm('rate-manager-form', this.controlContainer.control, {
              minValue: this.currentRate.cpmMin / 100,
              maxValue: this.currentRate.cpmMax / 100,
            });
          }
        });
    }
    if (changes.adManagerComp) {
      this.adManagerComp.AcceptStrategy(this.currentStrategy);
    }
  }

  public OnInitForm($event) {
    // console.log('inited');
    // console.log($event);
    // $event.valueChanges.subscribe(data => {
    //   this.controlContainer.control.get('value').setValue(data);
    // });
  }

  ngAfterViewInit(): void {
    console.log(this.adManagerComp);
  }


  private GetTipAutomatic(): string {
    return `Стоимость за 1000 показов объявления будет устанавливаться автоматически таким образом, чтобы снизить расход рекламного бюджета.
    
    Справка:
    ВКонтакте дает возможность устанавливать вручную, цену за 1000 показов объявления. Минимальная цена за 1000 показов ${this.currentRate!.cpmMin / 100} руб., максимальная ${this.currentRate!.cpmMax / 100} руб.
    
    От выбора цены за 1000 показов зависит как часто ваше объявление будет показываться пользователям ВКонтакте и за какую цену.
    Чем меньше цена за 1000 показов тем реже объявление будет показываться пользователям, но и дешевле. Соответственно, чем больше цена за 1000 показов, тем чаще объявление будет показываться пользователям, но и дороже.
    
    Автоматический способ установки цены за показы дает возможность с помощью умных алгоритмов устанавливать необходимую цену за 1000 показов для экономии бюджета.
    `;
  }

  private GetTipManual(): string {
    return `ВКонтакте дает возможность устанавливать вручную, цену за 1000 показов объявления. Минимальная цена за 1000 показов ${this.currentRate!.cpmMin / 100} руб., максимальная ${this.currentRate!.cpmMax / 100} руб.
    
    От выбора цены за 1000 показов зависит как часто ваше объявление будет показываться пользователям ВКонтакте и за какую цену.
    Чем меньше цена за 1000 показов тем реже объявление будет показываться пользователям, но и дешевле. Соответственно, чем больше цена за 1000 показов, тем чаще объявление будет показываться пользователям, но и дороже.`;
  }
}
