import {ConfigurationForm} from "../models/configuration-form";
import {AmoCrmImportConfigurator} from "./factories/amo-crm-import-configurator";
import {AmoCrmExportConfigurator} from "./factories/amo-crm-export-configurator";
import {VkFormsConfigurator} from "./factories/vk-forms-configurator";
import {MtFormsConfigurators} from "./factories/mt-forms-configurators";
import {VkRetargetingGroupsConfigurator} from "./factories/vk-retargeting-groups-configurator";
import {MtRemarketingGroupsConfigurator} from "./factories/mt-remarketing-groups-configurator";

export class IntegrationConfigurator {
  constructor(
    private serviceCode: string,
    private config: ConfigurationForm
  ) {
  }

  public configure(): any {
    switch (this.serviceCode) {
      case 'AMO_CRM_IMPORT':
        return new AmoCrmImportConfigurator(this.config).configure();
      case 'AMO_CRM_EXPORT':
        return new AmoCrmExportConfigurator(this.config).configure();
      case 'VK_LEAD_FORMS':
        return new VkFormsConfigurator(this.config).configure();
      case 'MT_LEAD_FORMS':
        return new MtFormsConfigurators(this.config).configure();
      case 'VK_RETARGETING_GROUPS':
        return new VkRetargetingGroupsConfigurator(this.config).configure();
      case 'MT_REMARKETING_GROUPS':
        const remGroups = new MtRemarketingGroupsConfigurator(this.config).configure();
        console.log(remGroups);
        return remGroups;
    }
  }
}
