import { OnInit } from '@angular/core';
import { UserIntegrationService } from "../../../api/services";
import { UserIntegrationStore } from "./user-integration.store";
import { createUserIntegration } from "./user-integration.model";
import { UserIntegrationStatisticsService } from "../user-integration-statistics/user-integration-statistics.service";
import { ActivatedRoute } from "@angular/router";
import { toBoolean } from "@datorama/akita";
import { IntegrationSessionService } from "../integrations-session/integration-session.service";
import { IntegrationSessionQuery } from "../integrations-session/integration-session.query";
import { filter } from "rxjs/operators";
import * as i0 from "@angular/core";
import * as i1 from "./user-integration.store";
import * as i2 from "../../../api/services/user-integration.service";
import * as i3 from "../user-integration-statistics/user-integration-statistics.service";
import * as i4 from "@angular/router";
import * as i5 from "../integrations-session/integration-session.service";
import * as i6 from "../integrations-session/integration-session.query";
var UsersIntegrationsService = /** @class */ (function () {
    function UsersIntegrationsService(store, usersIntegrationService, statistics, route, integrationSessionService, integrationSessionQuery) {
        this.store = store;
        this.usersIntegrationService = usersIntegrationService;
        this.statistics = statistics;
        this.route = route;
        this.integrationSessionService = integrationSessionService;
        this.integrationSessionQuery = integrationSessionQuery;
        this.showRemoved = false;
    }
    /**
     * Загрузка всех не удаленных интеграций пользователя
     * @param showRemoved - показывать удаленные
     * @constructor
     */
    UsersIntegrationsService.prototype.LoadUserIntegrations = function (showRemoved) {
        var _this = this;
        if (showRemoved === void 0) { showRemoved = false; }
        this.store.setLoading(true);
        this.usersIntegrationService.GetUserIntegrations({
            showRemoved: showRemoved || this.showRemoved
        })
            .subscribe(function (_a) {
            var data = _a.data;
            _this.integrationSessionQuery.currentSession$
                .pipe(filter(function (x) { return x !== null; }))
                .subscribe(function (accessSession) {
                console.log(data, 'USER INTEGRATIONS');
                _this.store.set(data.map(function (x) { return createUserIntegration(x); }));
                _this.statistics.LoadIntegrationStatisticsById(data.map(function (x) { return x.userIntegrationId; }), accessSession.accessFrom, accessSession.accessTo);
                _this.store.setLoading(false);
            });
        });
    };
    /**
     * Удаление пользовательской стратегии по id
     * @param integrationId
     * @constructor
     */
    UsersIntegrationsService.prototype.RemoveUserIntegration = function (integrationId) {
        var _this = this;
        this.usersIntegrationService.RemoveUserIntegration(integrationId)
            .subscribe(function (data) {
            _this.store.remove(integrationId);
        }, function (err) {
            console.log(err);
        });
    };
    /**
     * Запуск/Остановка пользовательской интеграции
     * @param integrationId - id интеграции
     * @param status - статус
     * @constructor
     */
    UsersIntegrationsService.prototype.ChangeUserIntegrationStatus = function (integrationId, status) {
        var _this = this;
        this.usersIntegrationService.ChangeUserIntegrationStatus({
            integrationId: integrationId,
            status: status
        })
            .subscribe(function (_a) {
            var data = _a.data;
            var newStatus = data.status;
            _this.LoadUserIntegrations();
        });
    };
    UsersIntegrationsService.prototype.ngOnInit = function () {
        var _this = this;
        this.route.queryParams.subscribe(function (_a) {
            var showRemoved = _a.showRemoved;
            _this.showRemoved = toBoolean(showRemoved);
        });
    };
    UsersIntegrationsService.ngInjectableDef = i0.defineInjectable({ factory: function UsersIntegrationsService_Factory() { return new UsersIntegrationsService(i0.inject(i1.UserIntegrationStore), i0.inject(i2.UserIntegrationService), i0.inject(i3.UserIntegrationStatisticsService), i0.inject(i4.ActivatedRoute), i0.inject(i5.IntegrationSessionService), i0.inject(i6.IntegrationSessionQuery)); }, token: UsersIntegrationsService, providedIn: "root" });
    return UsersIntegrationsService;
}());
export { UsersIntegrationsService };
