import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AccountsService} from "../../../api/services/accounts.service";
import {TokenCheckerService} from "../../services/token-checker.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-remove-account-modal',
  templateUrl: './remove-account-modal.component.html',
  styleUrls: ['./remove-account-modal.component.scss']
})
export class RemoveAccountModalComponent implements OnInit {
  @Input() public isVisible: boolean = false;
  @Output() public onModalClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private accountsService: AccountsService, private tokenService: TokenCheckerService,
              private router: Router) { }

  ngOnInit() {
  }

  public CloseModal(): void {
    this.onModalClose.emit(false);
  }

  public RemoveAccountClick(): void {
    this.accountsService.RemoveAccountData()
      .subscribe(x => {
        this.tokenService.RemoveToken();
        this.router.navigate(['/sign-in']);
      });
  }
}

