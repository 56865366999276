import { OnInit } from '@angular/core';
import { AdFormManagerService } from '../../services/ad-form-manager/ad-form-manager.service';
var ModerationSettingsComponent = /** @class */ (function () {
    function ModerationSettingsComponent(adFormManagerService) {
        this.adFormManagerService = adFormManagerService;
        this.Tip = 'Все объявления проходят обязательную модерацию (проверку объявления на соответствия правилам размещения) сотрудниками ВКонтакте. \n' +
            'Если объявление не соответствует правилам, то его могут отклонить и оно не может быть включено. \n' +
            '\n' +
            'Если вы выберите “Отправить на модерацию сразу после создания объявления” и “Запустить объявление сразу после прохождения модерации”. То, если модераторы одобрят объявление, оно будет запущено и начнутся показы объявления. \n' +
            'Если не будет одобрено, то вам необходимо изменить объявление и повторно отправить на модерацию.\n';
    }
    Object.defineProperty(ModerationSettingsComponent.prototype, "form", {
        get: function () {
            return this.adFormManagerService.getControl('moderationData');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModerationSettingsComponent.prototype, "budget", {
        get: function () {
            return this.adFormManagerService.budget;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModerationSettingsComponent.prototype, "IsBudgetZero", {
        get: function () {
            if (this.IsSelectedAccountAdmin()) {
                return this.budget === 0;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModerationSettingsComponent.prototype, "beSendingToModeration", {
        get: function () {
            var sendModeration = this.adFormManagerService.getControl('moderationData', 'sendModeration').value;
            return !sendModeration;
        },
        enumerable: true,
        configurable: true
    });
    ModerationSettingsComponent.prototype.IsSelectedAccountAdmin = function () {
        return this.adFormManagerService
            .IsSelectedAccountAdmin();
    };
    ModerationSettingsComponent.prototype.ngOnInit = function () {
        console.log('ENABLING MODERATION SEND');
        var moderationSend = this.adFormManagerService.getControl('moderationData', 'sendModeration');
        moderationSend.enable();
        console.log(moderationSend);
    };
    ModerationSettingsComponent.prototype.RefreshBudget = function ($event) {
        this.adFormManagerService.LoadUserBudget()
            .subscribe();
    };
    return ModerationSettingsComponent;
}());
export { ModerationSettingsComponent };
