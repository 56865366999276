var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { map as __map, filter as __filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../api-configuration";
import * as i2 from "@angular/common/http";
var PaymentsService = /** @class */ (function (_super) {
    __extends(PaymentsService, _super);
    function PaymentsService(config, http) {
        return _super.call(this, config, http) || this;
    }
    /**
     * @return Success
     */
    PaymentsService.prototype.getPaymentSystemsResponse = function () {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/payments/systems", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @return Success
     */
    PaymentsService.prototype.getPaymentSystems = function () {
        return this.getPaymentSystemsResponse().pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @return Success
     */
    PaymentsService.prototype.getTariffPlansResponse = function () {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/payments/plans", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @return Success
     */
    PaymentsService.prototype.getTariffPlans = function () {
        return this.getTariffPlansResponse().pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `PaymentsService.CreatePaymentParams` containing the following parameters:
     *
     * - `timeId`:
     *
     * - `tariffPlanId`:
     *
     * - `paymentSystemId`:
     *
     * - `optionId`:
     *
     * - `promocodeId`:
     *
     * @return Success
     */
    PaymentsService.prototype.createPaymentResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.timeId != null)
            __params = __params.set('timeId', params.timeId.toString());
        if (params.tariffPlanId != null)
            __params = __params.set('tariffPlanId', params.tariffPlanId.toString());
        if (params.paymentSystemId != null)
            __params = __params.set('paymentSystemId', params.paymentSystemId.toString());
        if (params.optionId != null)
            __params = __params.set('optionId', params.optionId.toString());
        if (params.promocodeId != null)
            __params = __params.set('promocodeId', params.promocodeId.toString());
        var req = new HttpRequest('POST', this.rootUrl + "/apiv2/payments/createpayment", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `PaymentsService.CreatePaymentParams` containing the following parameters:
     *
     * - `timeId`:
     *
     * - `tariffPlanId`:
     *
     * - `paymentSystemId`:
     *
     * - `optionId`:
     *
     * - `promocodeId`:
     *
     * @return Success
     */
    PaymentsService.prototype.createPayment = function (params) {
        return this.createPaymentResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `PaymentsService.Apiv2PaymentsPaymentconfirmationPostParams` containing the following parameters:
     *
     * - `log`:
     *
     * - `paymentDataJSON`:
     *
     * @return Success
     */
    PaymentsService.prototype.Apiv2PaymentsPaymentconfirmationPostResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.log != null)
            __params = __params.set('log', params.log.toString());
        __body = params.paymentDataJSON;
        var req = new HttpRequest('POST', this.rootUrl + "/apiv2/payments/paymentconfirmation", __body, {
            headers: __headers,
            params: __params,
            responseType: 'text'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `PaymentsService.Apiv2PaymentsPaymentconfirmationPostParams` containing the following parameters:
     *
     * - `log`:
     *
     * - `paymentDataJSON`:
     *
     * @return Success
     */
    PaymentsService.prototype.Apiv2PaymentsPaymentconfirmationPost = function (params) {
        return this.Apiv2PaymentsPaymentconfirmationPostResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @return Success
     */
    PaymentsService.prototype.getUserPaymentsResponse = function () {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/payments", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @return Success
     */
    PaymentsService.prototype.getUserPayments = function () {
        return this.getUserPaymentsResponse().pipe(__map(function (_r) { return _r.body; }));
    };
    PaymentsService.getPaymentSystemsPath = '/apiv2/payments/systems';
    PaymentsService.getTariffPlansPath = '/apiv2/payments/plans';
    PaymentsService.createPaymentPath = '/apiv2/payments/createpayment';
    PaymentsService.Apiv2PaymentsPaymentconfirmationPostPath = '/apiv2/payments/paymentconfirmation';
    PaymentsService.getUserPaymentsPath = '/apiv2/payments';
    PaymentsService.ngInjectableDef = i0.defineInjectable({ factory: function PaymentsService_Factory() { return new PaymentsService(i0.inject(i1.ApiConfiguration), i0.inject(i2.HttpClient)); }, token: PaymentsService, providedIn: "root" });
    return PaymentsService;
}(__BaseService));
export { PaymentsService };
