import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import Dexie from 'dexie';

@Injectable()
export class CoreService extends Dexie {

  constructor() {
    super(environment.indexedDB.tableName);
    this.version(1).stores({
      campaigns: '++id,name,status,day_limit,all_limit,spent,impressions,clicks,ctr,ecpc,yandex_cr_from_view,yandex_cr_from_click,yandex_cpa,yandex_reched_goals,yandex_page_depth,yandex_average_duration,utms,accountId'
    });
    this.version(2).stores({
      cabinets: '++account_id,account_name,account_type,account_status,access_role',
    })
      .upgrade((data) => {

      });

    this.version(3).stores({
      user: '++id,email'
    }).upgrade((data) => {

    });

    this.version(4).stores({
      cabinets: '++account_id,account_name,account_type,account_status,access_role,is_blocked'
    }).upgrade((data) => {

    });

    this.version(5)
      .stores({
        clients: 'id,name,day_limit,all_limit,account_id'
      })
      .upgrade(() => {
      });

    this.version(6)
      .stores({
        campaigns: '++id,name,status,day_limit,all_limit,spent,impressions,clicks,ctr,ecpc,yandex_cr_from_view,yandex_cr_from_click,yandex_cpa,yandex_reched_goals,yandex_page_depth,yandex_average_duration,utms,accountId,clientId'
      })
      .upgrade(() => {
      });

    this.version(7)
      .stores({
        campaigns: '++id,name,status,day_limit,all_limit,spent,impressions,clicks,ctr,ecpc,yandex_cr_from_view,yandex_cr_from_click,yandex_cpa,yandex_reched_goals,yandex_page_depth,yandex_average_duration,utms,[accountId+clientId]'
      })
      .upgrade(() => {
      });
    this.version(8)
      .stores({
        campaigns: '++id,name,status,day_limit,all_limit,spent,impressions,clicks,ctr,ecpc,yandex_cr_from_view,yandex_cr_from_click,yandex_cpa,yandex_reched_goals,yandex_page_depth,yandex_average_duration,utms,accountId,clientId,[accountId+clientId]'
      })
      .upgrade(() => {
      });
  }

}
