<div [ngClass]='{"easy-ad-container": true, "easy-ad-container_blocked": data.blockAd}'>
  <ng-container *ngIf="data; else noDataProvided">
    <div class="easy-ad-main-data">
      <div class="easy-ad-main-data__preview">
        <!--Существует ли информация для предпросмотра?-->
        <!--Принять во внимание, что для промопоста нужно больше информации, чем для промо-->
        <ng-container *ngIf="data.ad; else noAdToPreview">
          <!--<span routerLink="/automation/ad-manager/update-ad-lite" class="easy-ad-preview-link-container"-->
          <!--title="Перейти к редактированию объявления"-->
          <!--[queryParams]="{-->
          <!--account_id: data.accountId,-->
          <!--client_id: data.clientId,-->
          <!--campaign_id: data.campaignId,-->
          <!--adId: data.adId-->
          <!--}">-->
          <!---->
          <!--</span>-->
          <span class="easy-ad-preview-link-container">
              <!--Формат объявления - промопост?-->
              <ng-container *ngIf="IsAdPromo(); else adIsTeaser">
                <app-easy-ad-preview-promo [graphicsLoaded]="data.graphicsLoaded"
                                           [post]="data.post"></app-easy-ad-preview-promo>
              </ng-container>
            <!--Формат объявления не промопост, а это значит, что это тизер-->
              <ng-template #adIsTeaser>
                <app-easy-ad-preview-teaser [graphicsLoaded]="data.graphicsLoaded"
                                            [adLayout]="data.adLayout"></app-easy-ad-preview-teaser>
              </ng-template>

            <div class="easy-ad-preview-edit-link" *ngIf="data.ad.can_be_editable">
                <a routerLink="/automation/new-ad-manager/lite/update" [queryParams]="{
                      account_id: data.accountId,
                      client_id: data.clientId,
                      campaign_id: data.campaignId,
                      adId: data.adId
                     }" title="Перейти к редактированию объявления">
                  Редактировать объявление
                </a>
            </div>
            </span>
        </ng-container>
        <ng-template #noAdToPreview>
          Предпросмотр невозможен
        </ng-template>
      </div>
      <div class="easy-ad-main-data__meta-data">
        <div class="easy-ad-meta">
          <div class="easy-ad-meta__ad-name">
            <div class="easy-ad-meta-strip">
              <div class="easy-ad-meta-strip__item">
                <ng-container *ngIf="!editAdNameActive; else adEditModeActive">
                  <span class="easy-ad-name" (click)="SwitchToAdNameEditMode()">
                    {{data?.ad.name}}
                  </span>
                  <span class="easy-ad-name__tool" (click)="SwitchToAdNameEditMode()">
                    Переименовать
                  </span>
                  <a routerLink="/automation/new-ad-manager/lite/update" [queryParams]="{
                  account_id: data.accountId,
                  client_id: data.clientId,
                  campaign_id: data.campaignId,
                  adId: data.adId
                  }" title="Перейти к редактированию объявления" class="easy-ad-name__tool" *ngIf="data?.canBeEditable">
                    Редактировать объявление
                  </a>
                </ng-container>
                <ng-template #adEditModeActive>
                  <div class="easy-ad-edit-strip">
                    <div class="easy-ad-edit-strip__item">
                      <input [id]="GetAdIdentifier()+'-update-ad-name-input'" type="text" [(ngModel)]="editableAdName"
                             class="vk-input"
                             placeholder="Название объявления" minlength="1" maxlength="60" required>
                    </div>
                    <div class="easy-ad-edit-strip__item">
                      <button [id]="GetAdIdentifier()+'-update-ad-name-button'" class="vk-standart-button"
                              [disabled]="!IsAdNameValid(editableAdName)" (click)="ChangeAdName()">
                        Изменить
                      </button>
                    </div>
                    <div class="easy-ad-edit-strip__item">
                      <button [id]="GetAdIdentifier()+'-cancel-update-ad-name-button'"
                              class="vk-standart-button vk-standart-button_cancel" (click)="editAdNameActive = false;">
                        Отменить
                      </button>
                    </div>
                  </div>
                </ng-template>


                <!--<a routerLink="/automation/ad-manager" class="easy-ad-name" title="Перейти к редактированию объявления"-->
                   <!--[queryParams]="{-->
                <!--account_id: data.accountId,-->
                <!--client_id: data.clientId,-->
                <!--campaign_id: data.campaignId,-->
                <!--adId: data.adId-->
                <!--}">-->
                <!--<span class="easy-ad-name__name">-->
                <!--{{data?.ad.name}}-->
                <!--</span>-->
                  <!--<span class="easy-ad-name__tool">-->
                <!--Редактировать-->
                <!--</span>-->
                <!--</a>-->
              </div>
              <div class="easy-ad-meta-strip__item easy-ad-meta-strip__item_push-right">
                <ng-container *ngIf="data.adLayout && data.adLayout.preview_link">
                  <a [href]="data.adLayout.preview_link" target="_blank" rel="noopener nofollow"
                     class="easy-ad-view-ad-at-origin"
                     title="Открыть страницу ВКонтакте  в новой вкладке для просмотра объявления">
                    Посмотреть объявление
                  </a>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="easy-ad-meta__ad-status">
            <div class="easy-ad-status">

              <ng-container *ngIf="actionInProgress && !data.forceLoadingAbort; else statusNotLoading">
                <div class="easy-ad-status__status easy-ad-status__status_neutral">
                  <i class="icon ion-ios-timer easy-ad-status-icon easy-ad-status-icon_neutral"></i>
                  Выполняется операция
                </div>
                <div class="easy-ad-status__annotation">
                  Дождитесь окончания выполнения операции
                </div>
              </ng-container>
              <ng-template #statusNotLoading>
                <div [ngClass]='{
              "easy-ad-status__status": true,
              "easy-ad-status__status_negative": statusState < 0,
              "easy-ad-status__status_neutral": statusState === 0,
              "easy-ad-status__status_positive": statusState === 1,
              "easy-ad-status__status_moderation": statusState === 2
            }'>
                  <i [ngClass]='{
                  "icon": true,
                  "ion-ios-play": data.ad.status === 1,
                  "ion-md-checkmark": data.ad.status === 0 && data.ad.approved === 0,
                  "ion-md-time": data.ad.status === 0 && data.ad.approved === 1,
                  "ion-ios-square": data.ad.status === 0 && data.ad.approved === 2,
                  "ion-md-close": data.ad.status === 0 && data.ad.approved === 3,
                  "easy-ad-status-icon": true,
                  "easy-ad-status-icon_positive": statusState === 1,
                  "easy-ad-status-icon_moderation": statusState === 2,
                  "easy-ad-status-icon_neutral": statusState === 0,
                  "easy-ad-status-icon_negative": statusState < 0
                }'></i>
                  {{InterpretAdStatus()}}
                </div>

                <ng-container *ngIf="statusAnnotation != null">
                  <div class="easy-ad-status__annotation">
                    <ng-container *ngIf="statusAnnotationInPreviewMode; else annotationInFullMode">
                      <span class="easy-ad-annotation-data easy-ad-annotation-data_tip-text"
                            [innerHtml]="GetAnnotation(true)"></span>
                      <ng-container *ngIf="IsAnnotationHasFullMode()">
                        <span class="easy-ad-annotation-data easy-ad-annotation-data_switcher"
                              (click)="SetAnnotationMode(false)">подробнее</span>
                      </ng-container>
                    </ng-container>
                    <ng-template #annotationInFullMode>
                      <span class="easy-ad-annotation-data" [innerHtml]="GetAnnotation()"></span>
                      <span class="easy-ad-annotation-data easy-ad-annotation-data_switcher"
                            (click)="SetAnnotationMode(true)">скрыть</span>
                    </ng-template>
                  </div>
                </ng-container>
              </ng-template>
            </div>

            <div class="easy-ad-cpm">
              <!--<div class="easy-ad-cpm__label">-->
              <!--Режим показа объявления-->
              <!--</div>-->
              <div class="easy-ad-cpm__value">
                <div class="easy-ad-cpm-type">
                  <ng-container *ngIf="!data.strategyRecieved; else strategyNotYetRecieved">
                    <div>Загрузка...</div>
                  </ng-container>
                  <ng-template #strategyNotYetRecieved>
                    <div class="easy-ad-cpm-type__name">
                      Режим показа объявления:
                      <ng-container *ngIf="data.userStrategy; else noStrategyName">
                        <!--<i class="easy-ad-cpm-icon icon ion-md-sync"></i> -->
                        Автоматический
                        <app-generic-tooltip [text]="GetTipAutomatic()"></app-generic-tooltip>
                      </ng-container>
                      <ng-template #noStrategyName>
                        <!--<i class="easy-ad-cpm-icon icon ion-ios-hand"></i> -->
                        Вручную
                      </ng-template>
                    </div>
                    <div class="easy-ad-cpm-type__description">
                      <ng-container *ngIf="data.userStrategy; else noStrategyDescription">
                        <ng-container *ngIf="GetStrategyECPCLimit(); else noECPCLimitStated; let eCPCLImit;">
                          Объявление автоматически выключится, если цена перехода превысит {{eCPCLImit / 100 | number:
                          '1.0-2'}} руб.
                          <app-generic-tooltip text="Укажите стоимость перехода при достижении которой объявление будет автоматически остановлено.
Устанавливается для того, чтобы выключать объявления по которым мало переходят и не расходовать бюджет не рационально. Если вы не знаете какое установить значение, то оставьте уже установленное значение. Вы сможете оценить какая цена перехода приемлемая по вашей тематике, запустив несколько объявлений и сравнив показатели стоимости перехода."></app-generic-tooltip>
                        </ng-container>
                        <ng-template #noECPCLimitStated>
                          Объявление будет показываться пока не будет остановлено или не закончится бюджет
                        </ng-template>
                      </ng-container>
                      <ng-template #noStrategyDescription>
                        У объявления выставлена цена за 1000 показов {{data.ad.cpm / 100 | number: '1.0-2'}} руб.
                      </ng-template>

                      <!--Внимание! Этот функционал не добработан!-->
                      <!--Не раскомментируйте его, если не собираетесь его дописывать-->
                      <ng-container *ngIf="!inputParamterShowEditBar; else inputParamterHideEditBar">
                      <span class="easy-ad-cpm-edit" (click)="SetInputParameterShow(true)">
                        Изменить
                      </span>
                      </ng-container>
                      <ng-template #inputParamterHideEditBar>
                      <span class="easy-ad-cpm-edit" (click)="SetInputParameterShow(false)">
                        Скрыть
                      </span>
                      </ng-template>
                    </div>
                  </ng-template>
                </div>
              </div>

              <ng-container *ngIf="inputParamterShowEditBar">
                <div class="easy-ad-cpm__edit">
                  <ng-container *ngIf="!inputParameterProcessingRequest; else showInputParameterProcessingIcon">
                    <div class="easy-ad-cpm-edit-strip">
                      <div class="easy-ad-cpm-edit-strip__item">
                        <label [for]="data.accountId + '_' + data.adId">
                          <ng-container *ngIf="data.userStrategy; else noStrategyLabel">
                            Максимальная стоимость перехода, руб.:
                          </ng-container>
                          <ng-template #noStrategyLabel>
                            Стоимость 1000 показов, руб.:
                          </ng-template>
                        </label>
                      </div>

                      <div class="easy-ad-cpm-edit-strip__item">
                        <ng-container *ngIf="data.userStrategy; else cpmInput">
                          <input [id]="GetAdIdentifier()+'-update-parameter-input'" type="number"
                                 [(ngModel)]="inputParameter"
                                 min="0"
                                 class="vk-input" placeholder="Введите значение...">
                        </ng-container>
                        <ng-template #cpmInput>
                          <input [id]="GetAdIdentifier()+'-update-parameter-input'" type="number"
                                 [(ngModel)]="inputParameter"
                                 [min]="bidLimit.cpmMin / 100" [max]="bidLimit.cpmMax / 100"
                                 class="vk-input" placeholder="Введите значение...">
                        </ng-template>
                      </div>

                      <div class="easy-ad-cpm-edit-strip__item">
                        <ng-container *ngIf="data.userStrategy; else buttonCpm">
                          <button [id]="GetAdIdentifier()+'-update-parameter-save-button'" class="vk-standart-button"
                                  (click)="EasyAdInputParameterChange()">
                            Сохранить
                          </button>
                        </ng-container>
                        <ng-template #buttonCpm>
                          <button [id]="GetAdIdentifier()+'-update-parameter-save-button'" class="vk-standart-button"
                                  (click)="EasyAdInputParameterChange()"
                                  [disabled]="!IsCPMWithinLimits(inputParameter)">
                            Сохранить
                          </button>
                        </ng-template>
                      </div>
                    </div>
                    <ng-container
                      *ngIf="!data.userStrategy && inputParameter !== null && inputParameter !== undefined && !IsCPMWithinLimits(inputParameter)">
                      <div class="easy-ad-input-parameter-error">
                        <ng-container *ngIf="inputParameter < bidLimit.cpmMin / 100">
                          Значение не должно быть меньше {{bidLimit.cpmMin / 100}}
                        </ng-container>
                        <ng-container *ngIf="inputParameter > bidLimit.cpmMax / 100">
                          Значение не должно быть больше {{bidLimit.cpmMax / 100}}
                        </ng-container>
                      </div>
                    </ng-container>
                  </ng-container>
                  <ng-template #showInputParameterProcessingIcon>
                    <div class="easy-ad-cpm-edit-strip">
                      <div class="easy-ad-cpm-edit-strip__item">
                        <img src="/assets/images/fb.svg" class="easy-ads-cpm-edit-loading-icon">
                      </div>

                      <div class="easy-ad-cpm-edit-strip__item">
                        Обработка запроса...
                      </div>
                    </div>
                  </ng-template>
                </div>
              </ng-container>
            </div>

            <!--<div class="easy-ad-status-strip">-->
            <!--<div class="easy-ad-status-strip__icon">-->
            <!--<div class="easy-ad-status-icon"></div>-->
            <!--</div>-->
            <!--<div class="easy-ad-status-strip__data">-->
            <!--<div class="easy-ad-status">-->
            <!--<div [ngClass]='{-->
            <!--"easy-ad-status__status": true-->
            <!--}'>-->
            <!--{{InterpretAdStatus()}}-->
            <!--</div>-->

            <!--<ng-container *ngIf="statusAnnotation != null">-->
            <!--<div class="easy-ad-status__annotation">-->
            <!--{{statusAnnotation}}-->
            <!--</div>-->
            <!--</ng-container>-->
            <!--</div>-->
            <!--</div>-->
            <!--</div>-->
          </div>
        </div>

        <ng-container *ngIf="data.errors && data.errors.length > 0">
          <div class="easy-ad-errors">
            <ng-container *ngFor="let error of data.errors">
              <div class="easy-ad-errors__item" [innerHtml]="error"></div>
            </ng-container>
          </div>
        </ng-container>

        <div class="easy-ad-bottom-strip">

          <div class="easy-ad-bottom-strip__item easy-ad-bottom-strip__item_align-center-vertical">
            <!--Проверить есть ли статистика для показа-->
            <ng-container *ngIf="data.statistics; else noAdStatistics">
              <div class="easy-ad-statistic-strip">
                <div class="easy-ad-statistic-strip__item">
                  <span class="easy-ad-statistic-label">Потрачено:</span>
                  <span class="easy-ad-statistic-value">{{data.statistics.spent | number: '1.0-0'}} руб.</span>
                </div>
                <div class="easy-ad-statistic-strip__item">
                  <span class="easy-ad-statistic-label">Показано:</span>
                  <span class="easy-ad-statistic-value">{{data.statistics.impressions | number: '1.0-0'}}</span>
                </div>
                <div class="easy-ad-statistic-strip__item">
                  <span class="easy-ad-statistic-label">Переходов:</span>
                  <span class="easy-ad-statistic-value">{{data.statistics.clicks | number: '1.0-0'}}</span>
                </div>

                <ng-container *ngIf="data.statistics.clicks > 0">
                  <div class="easy-ad-statistic-strip__item">
                    <span class="easy-ad-statistic-label">Стоимость перехода:</span>
                    <span
                      class="easy-ad-statistic-value">{{data.statistics.spent / data.statistics.clicks | number: '1.0-2'}}
                      руб.</span>
                  </div>
                </ng-container>
              </div>
            </ng-container>
            <!--Статистики для показа нет-->
            <ng-template #noAdStatistics>
              <i>Позже здесь появится статистика объявления</i>
            </ng-template>
          </div>

          <!--Если есть информация б объявлении и его статусе-->
          <ng-container *ngIf="data.ad && data.ad.status !== null && data.ad.status !== undefined">
            <div class="easy-ad-bottom-strip__item easy-ad-bottom-strip__item_push-right">
              <ng-container *ngIf="!forceAwaitLabel; else showAwaitLabel">
                <!--Если прошли модерацию-->
                <ng-container *ngIf="data.ad.approved === 2; else moderationNotPassed">

                  <ng-container *ngIf="actionInProgress && !forcedLoadingAbort; else statusChangeNotTriggered">
                    <button class="vk-standart-button vk-standart-button_cancel vk-standart-button_no-click">
                      <!--Если объявление запущено-->
                      <ng-container *ngIf="data.ad.status === 1; else adNotRuningInProcess">
                        Останавливается...
                      </ng-container>
                      <!--Если объявление не запущено-->
                      <ng-template #adNotRuningInProcess>
                        Запускается...
                      </ng-template>
                    </button>
                  </ng-container>
                  <ng-template #statusChangeNotTriggered>
                    <button [id]="GetAdIdentifier()+'-status-change-button'" [ngClass]='{
                      "vk-standart-button": true,
                      "vk-standart-button_negative": data.ad.status === 1,
                      "vk-standart-button_positive": data.ad.status === 0
                    }' (click)="ChangeAdStatus()">
                      <!--Если объявление запущено-->
                      <ng-container *ngIf="data.ad.status === 1; else adNotRuning">
                        Остановить
                      </ng-container>
                      <!--Если объявление не запущено-->
                      <ng-template #adNotRuning>
                        Запустить
                      </ng-template>
                    </button>
                  </ng-template>
                </ng-container>
                <ng-template #moderationNotPassed>

                  <ng-container *ngIf="data.ad.approved === 0">
                    <ng-container
                      *ngIf="(!actionInProgress || (ad && ad.errors)) || data.forceLoadingAbort; else sendToModerationNotTriggered">
                      <button [id]="GetAdIdentifier()+'-send-to-moderation-button'"
                              class="vk-standart-button vk-standart-button_neutral" (click)="SendToModeration()">
                        Отправить на модерацию
                      </button>
                    </ng-container>
                    <ng-template #sendToModerationNotTriggered>
                      <button class="vk-standart-button vk-standart-button_cancel vk-standart-button_no-click">
                        Подождите...
                      </button>
                    </ng-template>

                  </ng-container>

                  <ng-container *ngIf="data.ad.approved === 1">
                    На модерации
                  </ng-container>

                  <ng-container *ngIf="data.ad.approved === 3">
                    <button [id]="GetAdIdentifier()+'-show-rejection-reason-button'"
                            class="vk-standart-button vk-standart-button_neutral" (click)="GetRejectionReason()">
                      Посмотреть причины отклонения
                    </button>
                  </ng-container>
                </ng-template>
              </ng-container>
              <ng-template #showAwaitLabel>
                Подождите...
              </ng-template>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #noDataProvided>
    <i>Системная ошибка, параметр data не был предоставлен в данном компоненте</i>
  </ng-template>
</div>
