import { Component, OnInit } from '@angular/core';
import {UserBalanceQuery} from "../../../../../storage/user-balance/state/user-balance.query";

@Component({
  selector: 'app-registrated-tile-content',
  templateUrl: './registrated-tile-content.component.html',
  styleUrls: ['./registrated-tile-content.component.scss']
})
export class RegistratedTileContentComponent implements OnInit {

  public get registrated$() {
    return this.balanceQuery.registrated$
  }

  constructor(
    private balanceQuery: UserBalanceQuery
  ) { }

  ngOnInit() {
  }

}
