<div class="lead-forms-configurator">
  <div class="lead-forms-configurator__info">
    <mat-icon class="warning-icon">warning</mat-icon>
    Если в лид-форме нет ни одного лида, то она не будет отображаться
  </div>
  <form class="lead-forms-configurator__settings" [formGroup]="form">
    <ng-container *ngFor="let okForm of forms$ | async">
      <app-lead-form-settings
        [form]="okForm"
        [control]="GetControl(okForm.id)"
      ></app-lead-form-settings>
    </ng-container>
  </form>
</div>
