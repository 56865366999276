import { OnInit } from "@angular/core";
import { AffiliateMemberStattisticsByUserQuery } from "./affiliate-member-stattistics-by-user.query";
import { AffiliateMemberStattisticsByUserService } from "./affiliate-member-stattistics-by-user.service";
import { combineLatest } from "rxjs";
import * as i0 from "@angular/core";
import * as i1 from "./affiliate-member-stattistics-by-user.query";
import * as i2 from "./affiliate-member-stattistics-by-user.service";
var AffiliateMemberStatisticsByUserFacade = /** @class */ (function () {
    function AffiliateMemberStatisticsByUserFacade(affiliateMembersStatisticByUserQuery, affiliateMemberStattisticByUserService) {
        this.affiliateMembersStatisticByUserQuery = affiliateMembersStatisticByUserQuery;
        this.affiliateMemberStattisticByUserService = affiliateMemberStattisticByUserService;
        this.statistics$ = this.affiliateMembersStatisticByUserQuery.statistics$;
    }
    Object.defineProperty(AffiliateMemberStatisticsByUserFacade.prototype, "dateFrom$", {
        get: function () {
            return this.affiliateMembersStatisticByUserQuery.dateFrom$;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AffiliateMemberStatisticsByUserFacade.prototype, "dateTo$", {
        get: function () {
            return this.affiliateMembersStatisticByUserQuery.dateFrom$;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AffiliateMemberStatisticsByUserFacade.prototype, "dateFrom", {
        get: function () {
            return this.affiliateMembersStatisticByUserQuery.dateFrom;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AffiliateMemberStatisticsByUserFacade.prototype, "dateTo", {
        get: function () {
            return this.affiliateMembersStatisticByUserQuery.dateTo;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Load statistics
     * @constructor
     */
    AffiliateMemberStatisticsByUserFacade.prototype.LoadStatistics = function () {
        var dateFrom = this.affiliateMembersStatisticByUserQuery.dateFrom;
        var dateTo = this.affiliateMembersStatisticByUserQuery.dateTo;
        this.affiliateMemberStattisticByUserService.get(dateFrom, dateTo)
            .subscribe();
    };
    AffiliateMemberStatisticsByUserFacade.prototype.ngOnInit = function () {
        var _this = this;
        combineLatest(this.dateFrom$, this.dateTo$)
            .subscribe(function (data) {
            _this.LoadStatistics();
        });
    };
    AffiliateMemberStatisticsByUserFacade.ngInjectableDef = i0.defineInjectable({ factory: function AffiliateMemberStatisticsByUserFacade_Factory() { return new AffiliateMemberStatisticsByUserFacade(i0.inject(i1.AffiliateMemberStattisticsByUserQuery), i0.inject(i2.AffiliateMemberStattisticsByUserService)); }, token: AffiliateMemberStatisticsByUserFacade, providedIn: "root" });
    return AffiliateMemberStatisticsByUserFacade;
}());
export { AffiliateMemberStatisticsByUserFacade };
