/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./new-style-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./new-style-modal.component";
var styles_NewStyleModalComponent = [i0.styles];
var RenderType_NewStyleModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NewStyleModalComponent, data: {} });
export { RenderType_NewStyleModalComponent as RenderType_NewStyleModalComponent };
function View_NewStyleModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "new-modal-close-container"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCloseButtonClick.emit(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "icon ion-md-close"]], null, null, null, null, null))], null, null); }
function View_NewStyleModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 13, "div", [["class", "new-modal-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 12, "div", [["class", "new-modal-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 9, "div", [["class", "new-modal-card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "new-modal-header"]], null, null, null, null, null)), i1.ɵdid(5, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(6, { "background-color": 0 }), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "new-modal-card__content"]], null, null, null, null, null)), i1.ɵncd(null, 1), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "new-modal-card__content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "div", [["class", "new-modal-bottom-button-strip"]], null, null, null, null, null)), i1.ɵncd(null, 2), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NewStyleModalComponent_2)), i1.ɵdid(14, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 6, 0, _co.headerBackgroundColor); _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.showCloseButton; _ck(_v, 14, 0, currVal_1); }, null); }
export function View_NewStyleModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_NewStyleModalComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isVisible; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_NewStyleModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-new-style-modal", [], null, null, null, View_NewStyleModalComponent_0, RenderType_NewStyleModalComponent)), i1.ɵdid(1, 114688, null, 0, i3.NewStyleModalComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NewStyleModalComponentNgFactory = i1.ɵccf("app-new-style-modal", i3.NewStyleModalComponent, View_NewStyleModalComponent_Host_0, { headerBackgroundColor: "headerBackgroundColor", isVisible: "isVisible", showCloseButton: "showCloseButton" }, { onCloseButtonClick: "onCloseButtonClick" }, [".new-modal-header-content", ".new-modal-body-content", ".new-modal-buttons"]);
export { NewStyleModalComponentNgFactory as NewStyleModalComponentNgFactory };
