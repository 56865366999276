<ng-container [ngSwitch]="type">
  <ng-container *ngSwitchCase="'VK_LEAD_FORMS'">
    <app-vk-lead-forms-settings [type]="type" [platform]="platform"></app-vk-lead-forms-settings>
  </ng-container>
  <ng-container *ngSwitchCase="'MT_LEAD_FORMS'">
    <app-lead-forms-configurator [type]="type" [platform]="platform"></app-lead-forms-configurator>
  </ng-container>
  <ng-container *ngSwitchCase="'FB_LEAD_FORMS'">
    Выберите объявления содержащие лид-форму:
    <br>
    <app-vk-lead-forms-settings [type]="type" [platform]="platform"></app-vk-lead-forms-settings>
  </ng-container>
</ng-container>
