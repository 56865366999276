import {AfterViewInit, Component, ContentChildren, Input, OnInit, QueryList} from '@angular/core';
import {ErrorComponent} from '../error/error.component';
import {InputDirective} from '../../directives/input.directive';

export enum ControlOrientation {
  horizontal = 'horizontal',
  vertical = 'vertical'
}

@Component({
  selector: 'vk-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss']
})
export class FormFieldComponent implements OnInit, AfterViewInit {

  @Input() public labelWidth;
  @Input() public controlsWidth;
  @Input() public orientation: ControlOrientation = ControlOrientation.horizontal;
  @Input() public labelPosition: string = 'right';
  @Input() public flexible: boolean = false;
  @Input() public useMin: boolean = true;
  @ContentChildren(ErrorComponent) public errors: QueryList<ErrorComponent>;
  @ContentChildren(InputDirective) public inputs: QueryList<InputDirective>;

  public errorsMessages: Array<any> = [];

  constructor() {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.GetErrors();
  }

  public GetErrors() {
    this.errors.forEach(error => {
      if (!this.errorsMessages[error.name]) {
        this.errorsMessages[error.name] = [];
      }
      this.errorsMessages.push({
        validator: error.validator,
        inputName: error.name,
        content: error.elementRef.nativeElement.innerHTML
      });
    });
    this.errors.changes.subscribe(errors => {
      this.errorsMessages = [];
      errors.forEach(error => {
        if (!this.errorsMessages[error.name]) {
          this.errorsMessages[error.name] = [];
        }
        this.errorsMessages.push({
          validator: error.validator,
          inputName: error.name,
          content: error.elementRef.nativeElement.innerHTML
        });
      });
    });
  }

  public get value() {
    return this.inputs.map(input => input.controlContainer.control);
  }

  public get IsDirty() {
    return this.inputs.toArray()
      .map(input => {
        return input.formControl.control.dirty;
      })
      .reduce((isControlDirty, isInputDirty) => isControlDirty || isInputDirty, false);
  }

  public IsInvalid(controlName, validatorName) {
    return this.inputs.toArray()
      .map(input => input.formControl.control)
      .filter(control => control !== null)
      .filter(control => control.valid !== true)
      .map(control => Object.keys(control.errors))
      .map(controlErrorKeys => controlErrorKeys.includes(validatorName))
      .reduce((isValidControl, errorKeyIncludesInErrorsKeys) => isValidControl || errorKeyIncludesInErrorsKeys, false);
  }
}
