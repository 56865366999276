/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./loading-screen.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-spinner";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/common";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "./loading-screen.component";
var styles_LoadingScreenComponent = [i0.styles];
var RenderType_LoadingScreenComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoadingScreenComponent, data: {} });
export { RenderType_LoadingScreenComponent as RenderType_LoadingScreenComponent };
export function View_LoadingScreenComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "loading-screen"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "loading-screen__spinner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "mat-spinner", [["class", "mat-spinner mat-progress-spinner"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i2.View_MatSpinner_0, i2.RenderType_MatSpinner)), i1.ɵdid(3, 49152, null, 0, i3.MatSpinner, [i1.ElementRef, i4.Platform, [2, i5.DOCUMENT], [2, i6.ANIMATION_MODULE_TYPE], i3.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], { diameter: [0, "diameter"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "loading-screen__content"]], null, null, null, null, null)), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.diameter; _ck(_v, 3, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3)._noopAnimations; var currVal_1 = i1.ɵnov(_v, 3).diameter; var currVal_2 = i1.ɵnov(_v, 3).diameter; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_LoadingScreenComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-loading-screen", [], null, null, null, View_LoadingScreenComponent_0, RenderType_LoadingScreenComponent)), i1.ɵdid(1, 114688, null, 0, i7.LoadingScreenComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoadingScreenComponentNgFactory = i1.ɵccf("app-loading-screen", i7.LoadingScreenComponent, View_LoadingScreenComponent_Host_0, { diameter: "diameter" }, {}, ["*"]);
export { LoadingScreenComponentNgFactory as LoadingScreenComponentNgFactory };
