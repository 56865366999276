import {Component, OnInit} from '@angular/core';
import * as Sentry from '@sentry/browser';

@Component({
  selector: 'app-authorization-layout',
  templateUrl: './authorization-layout.component.html',
  styleUrls: ['./authorization-layout.component.scss']
})
export class AuthorizationLayoutComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
    Sentry.configureScope(scope => {
      scope.setTag('module', 'authorization');
    });
  }

}
