import {ChangeDetectorRef, Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {PlatformsQuery} from '../../stores/platforms/platforms.query';
import {PlatformsService} from '../../stores/platforms/platforms.service';
import {PlatformsRelationsQuery} from '../../stores/platforms-relations/platforms-relations.query';
import {PlatformsRelationsService} from '../../stores/platforms-relations/platforms-relations.service';
import {UserIntegrationService} from '../../../api/services';
import {combineLatest, Observable, of} from 'rxjs';
import {ConfiguratorService} from '../../service/configurator.service';
import {debounceTime, delay, distinctUntilChanged, map, take, tap} from 'rxjs/operators';
import {PlatformsTypes} from '../../platforms-types';
import {AkitaNgFormsManager} from '@datorama/akita-ng-forms-manager';
import {ActivatedRoute, Router} from '@angular/router';
import {PlatformAssignationsQuery} from '../../stores/platform-assignations/platform-assignations.query';
import {isNullOrUndefined} from 'util';
import {AdNetworkFormsService} from '../../stores/ad-network-forms/ad-network-forms.service';
import {LoggingService} from '../../../shared/services/logging.service';
import {Severity} from '@sentry/types';
import {FormMessagesService} from "../../services/form-messages/form-messages.service";

@Component({
  selector: 'app-integration-form',
  templateUrl: './integration-form.component.html',
  styleUrls: ['./integration-form.component.scss']
})
export class IntegrationFormComponent implements OnInit, OnChanges {

  public isCreateLoading = false;

  public originPlatformCode = null;
  public originPlatforms$ = this.platformsQuery.originPlatforms$.pipe(
    // map(platforms => platforms.filter(platform => platform.code === PlatformsTypes.MT_LEAD_FORMS))
  );
  public destinationPlatforms$ = this.platformsQuery.relatedPlatforms$;
  public isValid = false;

  public originPlatformAssigned = false;
  public destinationPlatformAssigned = false;

  public allFields = false;

  public originPlatformSettingsValid$ = this.platformsQuery.selectedOriginPlatformCode$
    .pipe(
      map((code) => this.configurator.valid(PlatformsTypes[code])),
    );
  public isDestinationSettingsOpened: boolean = false;
  public destinationPlatformCode;
  public isNextButtonShowed = false;

  public get isOriginValid() {
    return this.configurator.valid(this.originPlatformCode);
  }

  public isPlatformAssigned$;

  public get isDestinationValid() {
    return this.configurator.valid(this.destinationPlatformCode);
  }

  public isOriginSettingsValid$ = this.fm.selectValue('originPlatformSettings')
    .pipe(
      map(form => this.configurator.valid(this.originPlatformCode))
    );

  public isDestinationSettingsValid$ = this.fm.selectValue('destinationPlatformSettings')
    .pipe(
      map(form => this.configurator.valid(this.destinationPlatformCode))
    );
  public ShowMessages: boolean = false;

  constructor(
    private platformsQuery: PlatformsQuery,
    private platformsService: PlatformsService,
    private platformsRelationsService: PlatformsRelationsService,
    private platformsRelationsQuery: PlatformsRelationsQuery,
    private userIntegrationForm: UserIntegrationService,
    private configurator: ConfiguratorService,
    private fm: AkitaNgFormsManager,
    private cd: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
    private assignationQuery: PlatformAssignationsQuery,
    private adNetworkFormsService: AdNetworkFormsService,
    private logger: LoggingService,
    private formMessagesService: FormMessagesService
  ) {
  }

  private isDestinationPlatformSelected$ = this.platformsQuery.isDestinationPlatformSelected$;

  public Messages = this.formMessagesService.Messages$
    .asObservable()
    .pipe(
      debounceTime(500),
      tap(console.log)
    )

  // public get Messages() {
  //   let formMessages = ;
  //   let messages = formMessages.asObservable();
  //   return messages
  //     .pipe(
  //       debounceTime(500),
  //       tap(console.log)
  //     )
  // }

  ngOnInit() {
    this.route.queryParams.subscribe(({allFields}) => {
      this.allFields = !isNullOrUndefined(allFields);
      this.platformsService.LoadIntegrations(allFields);
      this.platformsRelationsService.LoadIntegrationsRelations();
      this.platformsQuery.selectedOriginPlatformCode$.subscribe(code => {
        this.originPlatformCode = code;
        this.isPlatformAssigned$ =
          this.assignationQuery.isPlatformAssigned$(PlatformsTypes[code]);

        this.isPlatformAssigned$.subscribe(data => {
        });

        this.isDestinationSettingsOpened = false;
        this.fm.remove('originPlatformSettings');
      });
      this.platformsQuery.selectedDestinationPlatformCode$
        .pipe(
          distinctUntilChanged(),
        )
        .subscribe(code => {
          this.destinationPlatformCode = code;
          this.fm.remove('destinationPlatformSettings');
        });

      // combineLatest(
      //   this.platformsQuery.selectedOriginPlatformCode$,
      //   this.platformsQuery.selectedDestinationPlatformCode$
      // )
      //   .subscribe(([originPlatformCode, destinationPlatformCode]) => {
      //     console.log(originPlatformCode, destinationPlatformCode, 'SELECTED PLATFORM CODES');
      //     this.ShowMessages = !(isNullOrUndefined(originPlatformCode) && isNullOrUndefined((destinationPlatformCode)))
      //   })
    });

    this.isOriginSettingsValid$
      .pipe(
        distinctUntilChanged(),
        delay(100)
      )
      .subscribe(isOriginValid => {
        if (this.originPlatformCode === PlatformsTypes.MOY_SKLAD_EXPORT) {
          this.Open();
        }
        console.log(isOriginValid, 'Is origin valid', !this.isDestinationSettingsOpened, 'is destination closed');
        this.isNextButtonShowed = isOriginValid && !this.isDestinationSettingsOpened;
      });

    combineLatest(
      this.isOriginSettingsValid$,
      this.isDestinationSettingsValid$
    )
      .pipe(
        delay(100)
      )
      .subscribe(([isOriginValid, isDestionationValid]) => {
        this.isValid = isOriginValid && isDestionationValid;
        this.cd.detectChanges();
      });
  }

  SaveIntegration() {
    // this.formMessagesService.Clear()
    try {
      let t = 0;
      const originPlatformValidation = this.configurator.valid(this.originPlatformCode);
      t = 1;
      const destinationPlatformValidation = this.configurator.valid(this.destinationPlatformCode);
      t = 2;
      const validation = originPlatformValidation && destinationPlatformValidation;
      t = 3;
      console.error('VALIDATION WAS ' + validation, originPlatformValidation, destinationPlatformValidation, t);
      this.ShowMessages = true;
      // this.cd.detectChanges();
      if (validation) {
        this.logger.AddBreadcrumb('Начался процесс сохранения интеграции пользователя');
        this.SetCreateLoading(true);
        let leadFormsStatus: Observable<any> = of(true);
        if (this.originPlatformCode === PlatformsTypes.MT_LEAD_FORMS || this.originPlatformCode === PlatformsTypes.VK_LEAD_FORMS || this.originPlatformCode === PlatformsTypes.FB_LEAD_FORMS) {
          leadFormsStatus = this.adNetworkFormsService.LoadLastIdOfSelectedLeadForms(PlatformsTypes[this.originPlatformCode]);
        }

        leadFormsStatus
          .subscribe(() => {
            combineLatest(
              this.platformsQuery.selectedOriginPlatform,
              this.platformsQuery.selectedDestinationPlatform$,
              this.fm.selectValue('name', 'name'),
              this.platformsQuery.selectedOriginPlatformCode$
                .pipe(
                  map((code) => this.configurator.configure(PlatformsTypes[code]))),
              this.platformsQuery.selectedDestinationPlatformCode$
                .pipe(
                  map((code) => this.configurator.configure(PlatformsTypes[code]))),
            )
              .pipe(
                take(1)
              )
              .subscribe(([originPlatform, destinationPlatform, name, originData, destData]) => {
                this.logger.AddBreadcrumb('Сохраняется интеграция пользователя', {
                  originPlatform: originPlatform.code,
                  destinationPlatform: destinationPlatform.code,
                  name
                });
                this.SendAddUserIntegrationRequest(destinationPlatform, originPlatform, name, destData, originData);
              });
          })
      } else {
        combineLatest(
          this.platformsQuery.selectedOriginPlatform,
          this.platformsQuery.selectedDestinationPlatform$,
          this.fm.selectValue('name', 'name'),
          this.platformsQuery.selectedOriginPlatformCode$
            .pipe(
              map((code) => this.configurator.configure(PlatformsTypes[code]))),
          this.platformsQuery.selectedDestinationPlatformCode$
            .pipe(
              map((code) => this.configurator.configure(PlatformsTypes[code]))),
        )
          .pipe(
            take(1)
          )
          .subscribe(([originPlatform, destinationPlatform, name, originData, destData]) => {
            this.logger.AddBreadcrumb('Сохраняется интеграция пользователя', {
              originPlatform: originPlatform.code,
              destinationPlatform: destinationPlatform.code,
              name
            });
            this.logger.AddExtra('PlatformConfiguration', {
              destinationPlatform, originPlatform, name, destData, originData
            });
            this.logger.Error('Пользователь попытался создать интеграцию с не валидными данными', {
              destinationPlatform, originPlatform, name, destData, originData
            })
          });
      }
    } catch (e) {
      this.logger.AddBreadcrumb('Ошибка:', e, 'default', Severity.Error);
      this.logger.Error('При сохранении интеграции произошла ошибка.', e);
    }
  }

  private SendAddUserIntegrationRequest(destinationPlatform, originPlatform, name, destData, originData) {
    this.logger.AddBreadcrumb('Начался процесс отправки данных на создание интеграции');
    try {
      // throw new Error('WTF ???');
      this.userIntegrationForm.AddUserIntegration(this.GenerateModelData(destinationPlatform, originPlatform, name, destData, originData)).subscribe(data => {
        this.logger.Log('Интеграция успешно сохранена!');
        this.SetCreateLoading(false);
        this.router.navigate(['/integrations']);
      }, err => {
        this.SetCreateLoading(false);
        this.HandleSendAddUserIntegrtionRequestError(err);
        this.logger.AddBreadcrumb('Ошибка', err);
        this.logger.Error('При сохранении интеграции произошла ошибка!', err);
      });
    } catch (e) {
      this.SetCreateLoading(false);
      this.HandleSendAddUserIntegrtionRequestError(e);
      this.logger.AddBreadcrumb('Ошибка', e);
      this.logger.Error('При сохранении интеграции произошла ошибка!', e);
    }
  }

  /**
   * Генерация новой модели для отправки запроса на создание новой пользовательской интеграции
   * @param destinationPlatform
   * @param originPlatform
   * @param name
   * @param destData
   * @param originData
   * @constructor
   */
  private GenerateModelData(destinationPlatform, originPlatform, name, destData, originData) {
    return {
      toId: destinationPlatform.id as number,
      fromId: this.getOriginId(originPlatform, originData) as number,
      model: {
        name,
        destinationServiceData: this.getDestinationServiceData(destData, originData),
        originServiceData: this.getOriginServiceData(originData)
      }
    };
  }

  private getDestinationServiceData(destData, originData) {
    const isUonMarksExport = originData.map(({code}) => code)
      .filter(code => code === 'UON_MARKS_EXPORT').length > 0;
    console.log(originData, isUonMarksExport, 'ORIGIN DATA');
    if (isUonMarksExport) {
      return destData.map(x => {
        x.data = x.data.map(y => {
          const codes = y.code.split('_');
          y.code = `${codes[1]}_${codes[1]}`;

          return y;
        });
        return x;
      });
    }
    return destData;
  }

  private getOriginServiceData(originData) {
    const isUonMarksExport = originData.map(({code}) => code).filter(code => code === 'UON_MARKS_EXPORT').length > 0;
    if (isUonMarksExport) {
      return originData.map(section => {
        if (section.code === 'UON_MARKS_EXPORT') {
          section.code = 'CONTACTS';
        }

        return section;
      });
    } else {
      return originData;
    }
  }

  private getOriginId(originPlatform, originSettings) {
    // console.log(originSettings, 'ORIGIN SETTINGS');
    const isUonMarksExport = originSettings.map(({code}) => code).filter(code => code === 'UON_MARKS_EXPORT').length > 0;
    return isUonMarksExport ? 22 : originPlatform.id;
  }

  private HandleSendAddUserIntegrtionRequestError(err) {
    if (err.status === 400) {
      alert(err.error.description);
    } else {
      alert('Неизвестная ошибка! Обратитесь в службу технической поддержки');
    }
  }

  valid() {
    const valid = this.isOriginValid && this.isDestinationValid && this.isDestinationSettingsOpened;
    return valid || false;
  }

  Open() {
    this.isDestinationSettingsOpened = true;
    window.scrollTo(0, 0);
  }

  /**
   * Задать статус для индикатора загрузки
   * @param status - статус индикатора загрузки
   * @constructor
   */
  public SetCreateLoading(status: boolean) {
    this.isCreateLoading = status;
  }

  ngOnChanges(changes: SimpleChanges): void {
  }
}
