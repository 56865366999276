import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AffiliateProgrammAnnouncementComponent} from "./routes/affiliate-programm-announcement/affiliate-programm-announcement.component";
import {AffiliateProgramLayoutComponent} from "./components/affiliate-program-layout/affiliate-program-layout.component";
import {AffiliateProgramStatisticsByUserModule} from "./affiliate-program-statistics/affiliate-program-statistics-by-user/affiliate-program-statistics-by-user.module";
import {AffiliatePromgramStatisticsByUserRouteComponent} from "./affiliate-program-statistics/affiliate-program-statistics-by-user/routes/affiliate-promgram-statistics-by-user-route/affiliate-promgram-statistics-by-user-route.component";
import {AffiliateProgramStatisticsByDateRouteComponent} from "./affiliate-program-statistics/affiliate-program-statistics-by-date/routes/affiliate-program-statistics-by-date-route/affiliate-program-statistics-by-date-route.component";
import {AffiliateProgramSettingsReportRouteComponent} from "./affiliate-program-settings/affiliate-program-settings-report/routes/affiliate-program-settings-report-route/affiliate-program-settings-report-route.component";
import {AffiliateProgramSettingsUserInfoRouteComponent} from "./affiliate-program-settings/affiliate-program-settings-user-info/routes/affiliate-program-settings-user-info-route/affiliate-program-settings-user-info-route.component";

const routes: Routes = [
  {
    path: 'affiliate',
    children: [
      {
        path: '',
        redirectTo: 'statistics',
        pathMatch: 'full'
      },
      {
        path: 'announcement',
        component: AffiliateProgrammAnnouncementComponent
      },
      {
        path: 'statistics',
        component: AffiliateProgramLayoutComponent,
        children: [
          {
            path: '',
            redirectTo: 'by-user',
            pathMatch: 'full'
          },
          {
            path: 'by-user',
            component: AffiliatePromgramStatisticsByUserRouteComponent
          },
          {
            path: 'by-date',
            component: AffiliateProgramStatisticsByDateRouteComponent
          }
        ]
      },
      {
        path: 'settings',
        component: AffiliateProgramLayoutComponent,
        children: [
          {
            path: '',
            component: AffiliateProgramSettingsReportRouteComponent
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AffiliateProgrammRoutingModule {
}
