import {Component, OnInit} from '@angular/core';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material';
import {LeadsService, RolesService, UsersService} from '../../../../api/services';
import {Comment} from '../../../../api/models';
import {ActivatedRoute} from '@angular/router';
import {CallFormComponent} from '../../components/modals/call-form/call-form.component';

@Component({
  selector: 'app-user-comments',
  templateUrl: './user-comments.component.html',
  styleUrls: ['./user-comments.component.scss']
})
export class UserCommentsComponent implements OnInit {

  public comments: Array<Comment> = [];
  public userId = 0;
  public user: any = null;

  public roles = [];
  public statuses = [];

  public form: FormGroup = null;
  public formCall: FormGroup = null;
  public formInterests = null;

  public leadInterest = null;

  public callFormOpened;
  public interessFormOpened;

  public leadDetailsInterests = [];

  public selectedLeadDetail: any = {};

  public itemInputs = {};

  constructor(
    private route: ActivatedRoute,
    private adminUserService: UsersService,
    private rolesService: RolesService,
    private leadsService: LeadsService,
    private dialog: MatDialog
  ) {
  }

  ngOnInit() {
    this.userId = parseInt(this.route.snapshot.params.id, 10);
    this.form = new FormGroup({
      userId: new FormControl(this.userId),
      roleId: new FormControl(),
      leadStatusId: new FormControl()
    });

    this.formCall = new FormGroup({
      userId: new FormControl(this.userId),
      callDate: new FormControl('', [Validators.required]),
      comment: new FormControl('')
    });

    this.formInterests = new FormGroup({
      detailItemId: new FormControl(),
      items: new FormArray([])
    });
    this.Init();
  }

  public SelectLeadDetail(leadDetailId, force = false) {
    this.selectedLeadDetail = this.leadDetailsInterests.find(x => {
      console.log(x.id, leadDetailId);
      return x.id === parseInt(leadDetailId, 10);
    });
    // console.log(this.selectedLeadDetail);
    // console.log(this.user);
    // console.log(this.user.leadDetailData);
    if (this.user && this.user.leadDetailData[0] && force) {
      leadDetailId = this.user.leadDetailData[0].leadDetail.id;
    }
    this.formInterests = new FormGroup({
      leadDetailId: new FormControl(leadDetailId),
      items: new FormArray(this.selectedLeadDetail.items.map((item, i) => {
        let selectedState = false;
        let comment = '';
        if (this.user && this.user.leadDetailData[0] && this.user.leadDetailData[0].leadDetailId === leadDetailId && force) {
          const leadDetail = this.user.leadDetailData.find(data => data.leadDetailItem.id === item.id);
          if (leadDetail) {
            selectedState = true;
            comment = leadDetail.comment;
          }
        }
        return new FormGroup({
          selected: new FormControl(selectedState, []),
          comment: new FormControl(comment, []),
          id: new FormControl(item.id)
        });
      }))
    });
    // = new FormArray();
  }

  public Init() {
    this.LoadComments();
    this.LoadUserData();
    this.LoadRoles();
    this.LoadLeadStatuses();
  }

  SaveComment(comment: Comment) {
    comment.userId = this.userId;
    this.adminUserService.CreateComment(comment)
      .subscribe((data) => {
        this.LoadComments();
      });
  }

  public LoadComments() {
    this.adminUserService.GetUserComments(this.userId)
      .subscribe(data => {
        this.comments = data;
      });
  }

  public LoadUserData() {
    this.adminUserService.GetUserInfoById(this.userId)
      .subscribe(data => {
        console.log(data);
        this.form.setValue({
          userId: this.userId,
          roleId: data.roleId,
          leadStatusId: data.leadStatusId
        });
        this.user = data;
        this.LoadDetails();
      });
  }

  public LoadRoles() {
    this.rolesService.GetAllRoles()
      .subscribe(data => {
        this.roles = data;
      });
  }

  public LoadLeadStatuses() {
    this.leadsService.GetAllLeadStatuses()
      .subscribe(data => {
        this.statuses = data;
      });
  }

  public SaveGeneralInformation($event) {
    $event.preventDefault();
    this.leadsService.UpdateLead({
      id: this.userId,
      lead: this.form.value
    })
      .subscribe(data => {
        console.log(data);
      });
  }

  public ToggleCallForm() {
    this.dialog.open(CallFormComponent, {
      data: {
        userId: this.userId
      },
      width: '350px',
      height: '340px'
    })
      .afterClosed()
      .subscribe(data => {
        this.LoadUserData();
      });
  }

  public ToggleInteressForms() {
    this.interessFormOpened = !this.interessFormOpened;
  }

  public LoadDetails() {
    this.leadsService.GetLeadDetailsData()
      .subscribe(leadDetails => {
        this.leadDetailsInterests = leadDetails;
        this.SelectLeadDetail(leadDetails[0].id, true);
      });
  }

  SaveCall() {
    if (this.formCall.valid) {
      this.leadsService.CreateCall(this.formCall.value)
        .subscribe(data => {
          console.log(data);
          this.LoadUserData();
          this.ToggleCallForm();
          alert('Звонок сохранен!');
        });
    }
  }

  SaveInterests($event) {
    console.log(this.formInterests.value);
    const val = this.formInterests.value.items.filter(x => x.selected).map(x => {
      return {
        userId: this.userId,
        leadDetailId: this.formInterests.value.leadDetailId,
        leadDetailItemId: x.id,
        comment: x.comment
      };
    });

    this.adminUserService.AddInterests({
      id: this.userId,
      data: val
    })
      .subscribe(data => {
        console.log(data);
        this.ToggleInteressForms();
        alert('Интересы пользователя сохранены');
      });

    console.log(val);
  }
}
