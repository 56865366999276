/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { APIReponseWrapperListPostMonitorBackend_V2ModelsvkVKAPIUpdateAds } from '../models/apireponse-wrapper-list-post-monitor-backend-_v2modelsvk-vkapiupdate-ads';
import { PostMonitorBackend_V2ModelsvkVKAPIAdEditSecificationCustomizable } from '../models/post-monitor-backend-_v2modelsvk-vkapiad-edit-secification-customizable';
import { APIReponseWrapperListCabinet } from '../models/apireponse-wrapper-list-cabinet';
import { APIReponseWrapperListClient } from '../models/apireponse-wrapper-list-client';
import { APIReponseWrapperListCampaign } from '../models/apireponse-wrapper-list-campaign';
import { APIReponseWrapperListAd } from '../models/apireponse-wrapper-list-ad';
import { APIReponseWrapperFloodStats } from '../models/apireponse-wrapper-flood-stats';
import { APIReponseWrapperSystemDouble } from '../models/apireponse-wrapper-system-double';
import { APIReponseWrapperListStatistics } from '../models/apireponse-wrapper-list-statistics';
import { APIReponseWrapperListAdTargeting } from '../models/apireponse-wrapper-list-ad-targeting';
import { APIReponseWrapperListAdLayout } from '../models/apireponse-wrapper-list-ad-layout';
import { APIReponseWrapperListPostReach } from '../models/apireponse-wrapper-list-post-reach';
import { APIReponseWrapperListWallPost } from '../models/apireponse-wrapper-list-wall-post';
import { PostMonitorBackend_V2ModelsvkVKAPIUpdateAds } from '../models/post-monitor-backend-_v2modelsvk-vkapiupdate-ads';
import { ToggleCampaignViewModel } from '../models/toggle-campaign-view-model';
import { PostMonitorBackend_V2ModelsvkVKAPILeadForm } from '../models/post-monitor-backend-_v2modelsvk-vkapilead-form';
@Injectable({
  providedIn: 'root',
})
class AutomationVkService extends __BaseService {
  static readonly UpdateAdsPath = '/apiv2/automationvk/updateads';
  static readonly CheckCabinetsBindedPath = '/apiv2/automationvk/checkcabinetsbinded';
  static readonly GetAccountsPath = '/apiv2/automationvk/getaccounts';
  static readonly getClientsPath = '/apiv2/automationvk/clients';
  static readonly GetCampaignsPath = '/apiv2/automationvk/getcampaigns';
  static readonly GetAdsPath = '/apiv2/automationvk/getads';
  static readonly GetFloodStatsPath = '/apiv2/automationvk/getfloodstats';
  static readonly GetBudgetPath = '/apiv2/automationvk/getbudget';
  static readonly GetDemographicsPath = '/apiv2/automationvk/getdemograpics';
  static readonly GetStatisticsPath = '/apiv2/automationvk/getstatistics';
  static readonly GetAdsTargetingPath = '/apiv2/automationvk/getadstargeting';
  static readonly GetAdsLayoutPath = '/apiv2/automationvk';
  static readonly GetPostReachPath = '/apiv2/automationvk/getpostreach';
  static readonly GetWallByIdPath = '/apiv2/automationvk/getwallbyid';
  static readonly ToggleCampaignPath = '/apiv2/automationvk/toggle-campaigns';
  static readonly GetLeadFormsPath = '/apiv2/automationvk/lead-forms';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `AutomationVkService.UpdateAdsParams` containing the following parameters:
   *
   * - `accountId`:
   *
   * - `specifications`:
   *
   * - `clientId`:
   *
   * @return Success
   */
  UpdateAdsResponse(params: AutomationVkService.UpdateAdsParams): __Observable<__StrictHttpResponse<APIReponseWrapperListPostMonitorBackend_V2ModelsvkVKAPIUpdateAds>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.accountId != null) __params = __params.set('accountId', params.accountId.toString());
    __body = params.specifications;
    if (params.clientId != null) __params = __params.set('clientId', params.clientId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/apiv2/automationvk/updateads`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<APIReponseWrapperListPostMonitorBackend_V2ModelsvkVKAPIUpdateAds>;
      })
    );
  }
  /**
   * @param params The `AutomationVkService.UpdateAdsParams` containing the following parameters:
   *
   * - `accountId`:
   *
   * - `specifications`:
   *
   * - `clientId`:
   *
   * @return Success
   */
  UpdateAds(params: AutomationVkService.UpdateAdsParams): __Observable<APIReponseWrapperListPostMonitorBackend_V2ModelsvkVKAPIUpdateAds> {
    return this.UpdateAdsResponse(params).pipe(
      __map(_r => _r.body as APIReponseWrapperListPostMonitorBackend_V2ModelsvkVKAPIUpdateAds)
    );
  }
  CheckCabinetsBindedResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/automationvk/checkcabinetsbinded`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }  CheckCabinetsBinded(): __Observable<null> {
    return this.CheckCabinetsBindedResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param customClaim undefined
   * @return Success
   */
  GetAccountsResponse(customClaim?: string): __Observable<__StrictHttpResponse<APIReponseWrapperListCabinet>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (customClaim != null) __params = __params.set('customClaim', customClaim.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/automationvk/getaccounts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<APIReponseWrapperListCabinet>;
      })
    );
  }
  /**
   * @param customClaim undefined
   * @return Success
   */
  GetAccounts(customClaim?: string): __Observable<APIReponseWrapperListCabinet> {
    return this.GetAccountsResponse(customClaim).pipe(
      __map(_r => _r.body as APIReponseWrapperListCabinet)
    );
  }

  /**
   * @param accountId undefined
   * @return Success
   */
  getClientsResponse(accountId: number): __Observable<__StrictHttpResponse<APIReponseWrapperListClient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (accountId != null) __params = __params.set('accountId', accountId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/automationvk/clients`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<APIReponseWrapperListClient>;
      })
    );
  }
  /**
   * @param accountId undefined
   * @return Success
   */
  getClients(accountId: number): __Observable<APIReponseWrapperListClient> {
    return this.getClientsResponse(accountId).pipe(
      __map(_r => _r.body as APIReponseWrapperListClient)
    );
  }

  /**
   * @param params The `AutomationVkService.GetCampaignsParams` containing the following parameters:
   *
   * - `accountId`:
   *
   * - `clientId`:
   *
   * @return Success
   */
  GetCampaignsResponse(params: AutomationVkService.GetCampaignsParams): __Observable<__StrictHttpResponse<APIReponseWrapperListCampaign>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.accountId != null) __params = __params.set('accountId', params.accountId.toString());
    if (params.clientId != null) __params = __params.set('clientId', params.clientId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/automationvk/getcampaigns`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<APIReponseWrapperListCampaign>;
      })
    );
  }
  /**
   * @param params The `AutomationVkService.GetCampaignsParams` containing the following parameters:
   *
   * - `accountId`:
   *
   * - `clientId`:
   *
   * @return Success
   */
  GetCampaigns(params: AutomationVkService.GetCampaignsParams): __Observable<APIReponseWrapperListCampaign> {
    return this.GetCampaignsResponse(params).pipe(
      __map(_r => _r.body as APIReponseWrapperListCampaign)
    );
  }

  /**
   * @param params The `AutomationVkService.GetAdsParams` containing the following parameters:
   *
   * - `offset`:
   *
   * - `limit`:
   *
   * - `includeDeleted`:
   *
   * - `accountId`:
   *
   * - `clientId`:
   *
   * - `campaignIds`:
   *
   * - `adIds`:
   *
   * @return Success
   */
  GetAdsResponse(params: AutomationVkService.GetAdsParams): __Observable<__StrictHttpResponse<APIReponseWrapperListAd>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    if (params.includeDeleted != null) __params = __params.set('includeDeleted', params.includeDeleted.toString());
    if (params.accountId != null) __params = __params.set('accountId', params.accountId.toString());
    if (params.clientId != null) __params = __params.set('clientId', params.clientId.toString());
    (params.campaignIds || []).forEach(val => {if (val != null) __params = __params.append('campaignIds', val.toString())});
    (params.adIds || []).forEach(val => {if (val != null) __params = __params.append('adIds', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/automationvk/getads`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<APIReponseWrapperListAd>;
      })
    );
  }
  /**
   * @param params The `AutomationVkService.GetAdsParams` containing the following parameters:
   *
   * - `offset`:
   *
   * - `limit`:
   *
   * - `includeDeleted`:
   *
   * - `accountId`:
   *
   * - `clientId`:
   *
   * - `campaignIds`:
   *
   * - `adIds`:
   *
   * @return Success
   */
  GetAds(params: AutomationVkService.GetAdsParams): __Observable<APIReponseWrapperListAd> {
    return this.GetAdsResponse(params).pipe(
      __map(_r => _r.body as APIReponseWrapperListAd)
    );
  }

  /**
   * @param params The `AutomationVkService.GetFloodStatsParams` containing the following parameters:
   *
   * - `accountId`:
   *
   * - `clientId`:
   *
   * @return Success
   */
  GetFloodStatsResponse(params: AutomationVkService.GetFloodStatsParams): __Observable<__StrictHttpResponse<APIReponseWrapperFloodStats>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.accountId != null) __params = __params.set('accountId', params.accountId.toString());
    if (params.clientId != null) __params = __params.set('clientId', params.clientId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/automationvk/getfloodstats`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<APIReponseWrapperFloodStats>;
      })
    );
  }
  /**
   * @param params The `AutomationVkService.GetFloodStatsParams` containing the following parameters:
   *
   * - `accountId`:
   *
   * - `clientId`:
   *
   * @return Success
   */
  GetFloodStats(params: AutomationVkService.GetFloodStatsParams): __Observable<APIReponseWrapperFloodStats> {
    return this.GetFloodStatsResponse(params).pipe(
      __map(_r => _r.body as APIReponseWrapperFloodStats)
    );
  }

  /**
   * @param params The `AutomationVkService.GetBudgetParams` containing the following parameters:
   *
   * - `accountId`:
   *
   * - `clientId`:
   *
   * @return Success
   */
  GetBudgetResponse(params: AutomationVkService.GetBudgetParams): __Observable<__StrictHttpResponse<APIReponseWrapperSystemDouble>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.accountId != null) __params = __params.set('accountId', params.accountId.toString());
    if (params.clientId != null) __params = __params.set('clientId', params.clientId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/automationvk/getbudget`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<APIReponseWrapperSystemDouble>;
      })
    );
  }
  /**
   * @param params The `AutomationVkService.GetBudgetParams` containing the following parameters:
   *
   * - `accountId`:
   *
   * - `clientId`:
   *
   * @return Success
   */
  GetBudget(params: AutomationVkService.GetBudgetParams): __Observable<APIReponseWrapperSystemDouble> {
    return this.GetBudgetResponse(params).pipe(
      __map(_r => _r.body as APIReponseWrapperSystemDouble)
    );
  }

  /**
   * @param params The `AutomationVkService.GetDemographicsParams` containing the following parameters:
   *
   * - `accountId`:
   *
   * - `period`:
   *
   * - `idsType`:
   *
   * - `dateTo`:
   *
   * - `dateFrom`:
   *
   * - `clientId`:
   *
   * @return Success
   */
  GetDemographicsResponse(params: AutomationVkService.GetDemographicsParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.accountId != null) __params = __params.set('accountId', params.accountId.toString());
    if (params.period != null) __params = __params.set('period', params.period.toString());
    if (params.idsType != null) __params = __params.set('idsType', params.idsType.toString());
    if (params.dateTo != null) __params = __params.set('dateTo', params.dateTo.toString());
    if (params.dateFrom != null) __params = __params.set('dateFrom', params.dateFrom.toString());
    if (params.clientId != null) __params = __params.set('clientId', params.clientId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/automationvk/getdemograpics`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * @param params The `AutomationVkService.GetDemographicsParams` containing the following parameters:
   *
   * - `accountId`:
   *
   * - `period`:
   *
   * - `idsType`:
   *
   * - `dateTo`:
   *
   * - `dateFrom`:
   *
   * - `clientId`:
   *
   * @return Success
   */
  GetDemographics(params: AutomationVkService.GetDemographicsParams): __Observable<{}> {
    return this.GetDemographicsResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * @param params The `AutomationVkService.GetStatisticsParams` containing the following parameters:
   *
   * - `accountId`:
   *
   * - `period`:
   *
   * - `idsType`:
   *
   * - `ids`:
   *
   * - `dateTo`:
   *
   * - `dateFrom`:
   *
   * - `clientId`:
   *
   * @return Success
   */
  GetStatisticsResponse(params: AutomationVkService.GetStatisticsParams): __Observable<__StrictHttpResponse<APIReponseWrapperListStatistics>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.accountId != null) __params = __params.set('accountId', params.accountId.toString());
    if (params.period != null) __params = __params.set('period', params.period.toString());
    if (params.idsType != null) __params = __params.set('idsType', params.idsType.toString());
    if (params.ids != null) __params = __params.set('ids', params.ids.toString());
    if (params.dateTo != null) __params = __params.set('dateTo', params.dateTo.toString());
    if (params.dateFrom != null) __params = __params.set('dateFrom', params.dateFrom.toString());
    if (params.clientId != null) __params = __params.set('clientId', params.clientId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/automationvk/getstatistics`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<APIReponseWrapperListStatistics>;
      })
    );
  }
  /**
   * @param params The `AutomationVkService.GetStatisticsParams` containing the following parameters:
   *
   * - `accountId`:
   *
   * - `period`:
   *
   * - `idsType`:
   *
   * - `ids`:
   *
   * - `dateTo`:
   *
   * - `dateFrom`:
   *
   * - `clientId`:
   *
   * @return Success
   */
  GetStatistics(params: AutomationVkService.GetStatisticsParams): __Observable<APIReponseWrapperListStatistics> {
    return this.GetStatisticsResponse(params).pipe(
      __map(_r => _r.body as APIReponseWrapperListStatistics)
    );
  }

  /**
   * @param params The `AutomationVkService.GetAdsTargetingParams` containing the following parameters:
   *
   * - `offset`:
   *
   * - `limit`:
   *
   * - `includeDeleted`:
   *
   * - `accountId`:
   *
   * - `clientId`:
   *
   * - `campaignIds`:
   *
   * - `adIds`:
   *
   * @return Success
   */
  GetAdsTargetingResponse(params: AutomationVkService.GetAdsTargetingParams): __Observable<__StrictHttpResponse<APIReponseWrapperListAdTargeting>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    if (params.includeDeleted != null) __params = __params.set('includeDeleted', params.includeDeleted.toString());
    if (params.accountId != null) __params = __params.set('accountId', params.accountId.toString());
    if (params.clientId != null) __params = __params.set('clientId', params.clientId.toString());
    (params.campaignIds || []).forEach(val => {if (val != null) __params = __params.append('campaignIds', val.toString())});
    (params.adIds || []).forEach(val => {if (val != null) __params = __params.append('adIds', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/automationvk/getadstargeting`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<APIReponseWrapperListAdTargeting>;
      })
    );
  }
  /**
   * @param params The `AutomationVkService.GetAdsTargetingParams` containing the following parameters:
   *
   * - `offset`:
   *
   * - `limit`:
   *
   * - `includeDeleted`:
   *
   * - `accountId`:
   *
   * - `clientId`:
   *
   * - `campaignIds`:
   *
   * - `adIds`:
   *
   * @return Success
   */
  GetAdsTargeting(params: AutomationVkService.GetAdsTargetingParams): __Observable<APIReponseWrapperListAdTargeting> {
    return this.GetAdsTargetingResponse(params).pipe(
      __map(_r => _r.body as APIReponseWrapperListAdTargeting)
    );
  }

  /**
   * @param params The `AutomationVkService.GetAdsLayoutParams` containing the following parameters:
   *
   * - `offset`:
   *
   * - `limit`:
   *
   * - `includeDeleted`:
   *
   * - `accountId`:
   *
   * - `clientId`:
   *
   * - `campaignIds`:
   *
   * - `adIds`:
   *
   * @return Success
   */
  GetAdsLayoutResponse(params: AutomationVkService.GetAdsLayoutParams): __Observable<__StrictHttpResponse<APIReponseWrapperListAdLayout>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    if (params.includeDeleted != null) __params = __params.set('includeDeleted', params.includeDeleted.toString());
    if (params.accountId != null) __params = __params.set('accountId', params.accountId.toString());
    if (params.clientId != null) __params = __params.set('clientId', params.clientId.toString());
    (params.campaignIds || []).forEach(val => {if (val != null) __params = __params.append('campaignIds', val.toString())});
    (params.adIds || []).forEach(val => {if (val != null) __params = __params.append('adIds', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/automationvk`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<APIReponseWrapperListAdLayout>;
      })
    );
  }
  /**
   * @param params The `AutomationVkService.GetAdsLayoutParams` containing the following parameters:
   *
   * - `offset`:
   *
   * - `limit`:
   *
   * - `includeDeleted`:
   *
   * - `accountId`:
   *
   * - `clientId`:
   *
   * - `campaignIds`:
   *
   * - `adIds`:
   *
   * @return Success
   */
  GetAdsLayout(params: AutomationVkService.GetAdsLayoutParams): __Observable<APIReponseWrapperListAdLayout> {
    return this.GetAdsLayoutResponse(params).pipe(
      __map(_r => _r.body as APIReponseWrapperListAdLayout)
    );
  }

  /**
   * @param params The `AutomationVkService.GetPostReachParams` containing the following parameters:
   *
   * - `accountId`:
   *
   * - `idsType`:
   *
   * - `ids`:
   *
   * - `clientId`:
   *
   * @return Success
   */
  GetPostReachResponse(params: AutomationVkService.GetPostReachParams): __Observable<__StrictHttpResponse<APIReponseWrapperListPostReach>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.accountId != null) __params = __params.set('accountId', params.accountId.toString());
    if (params.idsType != null) __params = __params.set('idsType', params.idsType.toString());
    if (params.ids != null) __params = __params.set('ids', params.ids.toString());
    if (params.clientId != null) __params = __params.set('clientId', params.clientId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/automationvk/getpostreach`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<APIReponseWrapperListPostReach>;
      })
    );
  }
  /**
   * @param params The `AutomationVkService.GetPostReachParams` containing the following parameters:
   *
   * - `accountId`:
   *
   * - `idsType`:
   *
   * - `ids`:
   *
   * - `clientId`:
   *
   * @return Success
   */
  GetPostReach(params: AutomationVkService.GetPostReachParams): __Observable<APIReponseWrapperListPostReach> {
    return this.GetPostReachResponse(params).pipe(
      __map(_r => _r.body as APIReponseWrapperListPostReach)
    );
  }

  /**
   * @param ids undefined
   * @return Success
   */
  GetWallByIdResponse(ids?: string): __Observable<__StrictHttpResponse<APIReponseWrapperListWallPost>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (ids != null) __params = __params.set('ids', ids.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/automationvk/getwallbyid`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<APIReponseWrapperListWallPost>;
      })
    );
  }
  /**
   * @param ids undefined
   * @return Success
   */
  GetWallById(ids?: string): __Observable<APIReponseWrapperListWallPost> {
    return this.GetWallByIdResponse(ids).pipe(
      __map(_r => _r.body as APIReponseWrapperListWallPost)
    );
  }

  /**
   * @param data undefined
   * @return Success
   */
  ToggleCampaignResponse(data?: ToggleCampaignViewModel): __Observable<__StrictHttpResponse<Array<PostMonitorBackend_V2ModelsvkVKAPIUpdateAds>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/apiv2/automationvk/toggle-campaigns`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PostMonitorBackend_V2ModelsvkVKAPIUpdateAds>>;
      })
    );
  }
  /**
   * @param data undefined
   * @return Success
   */
  ToggleCampaign(data?: ToggleCampaignViewModel): __Observable<Array<PostMonitorBackend_V2ModelsvkVKAPIUpdateAds>> {
    return this.ToggleCampaignResponse(data).pipe(
      __map(_r => _r.body as Array<PostMonitorBackend_V2ModelsvkVKAPIUpdateAds>)
    );
  }

  /**
   * @param groupId undefined
   * @return Success
   */
  GetLeadFormsResponse(groupId: number): __Observable<__StrictHttpResponse<Array<PostMonitorBackend_V2ModelsvkVKAPILeadForm>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (groupId != null) __params = __params.set('groupId', groupId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/automationvk/lead-forms`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PostMonitorBackend_V2ModelsvkVKAPILeadForm>>;
      })
    );
  }
  /**
   * @param groupId undefined
   * @return Success
   */
  GetLeadForms(groupId: number): __Observable<Array<PostMonitorBackend_V2ModelsvkVKAPILeadForm>> {
    return this.GetLeadFormsResponse(groupId).pipe(
      __map(_r => _r.body as Array<PostMonitorBackend_V2ModelsvkVKAPILeadForm>)
    );
  }
}

module AutomationVkService {

  /**
   * Parameters for UpdateAds
   */
  export interface UpdateAdsParams {
    accountId: number;
    specifications?: Array<PostMonitorBackend_V2ModelsvkVKAPIAdEditSecificationCustomizable>;
    clientId?: number;
  }

  /**
   * Parameters for GetCampaigns
   */
  export interface GetCampaignsParams {
    accountId: number;
    clientId?: number;
  }

  /**
   * Parameters for GetAds
   */
  export interface GetAdsParams {
    offset: number;
    limit: number;
    includeDeleted: boolean;
    accountId: number;
    clientId?: number;
    campaignIds?: Array<number>;
    adIds?: Array<number>;
  }

  /**
   * Parameters for GetFloodStats
   */
  export interface GetFloodStatsParams {
    accountId: number;
    clientId?: number;
  }

  /**
   * Parameters for GetBudget
   */
  export interface GetBudgetParams {
    accountId: number;
    clientId?: number;
  }

  /**
   * Parameters for GetDemographics
   */
  export interface GetDemographicsParams {
    accountId: number;
    period?: string;
    idsType?: string;
    dateTo?: string;
    dateFrom?: string;
    clientId?: number;
  }

  /**
   * Parameters for GetStatistics
   */
  export interface GetStatisticsParams {
    accountId: number;
    period?: string;
    idsType?: string;
    ids?: string;
    dateTo?: string;
    dateFrom?: string;
    clientId?: number;
  }

  /**
   * Parameters for GetAdsTargeting
   */
  export interface GetAdsTargetingParams {
    offset: number;
    limit: number;
    includeDeleted: boolean;
    accountId: number;
    clientId?: number;
    campaignIds?: Array<number>;
    adIds?: Array<number>;
  }

  /**
   * Parameters for GetAdsLayout
   */
  export interface GetAdsLayoutParams {
    offset: number;
    limit: number;
    includeDeleted: boolean;
    accountId: number;
    clientId?: number;
    campaignIds?: Array<number>;
    adIds?: Array<number>;
  }

  /**
   * Parameters for GetPostReach
   */
  export interface GetPostReachParams {
    accountId: number;
    idsType?: string;
    ids?: string;
    clientId?: number;
  }
}

export { AutomationVkService }
