import {Component, OnInit} from '@angular/core';
import {StoreService} from '../../../../../services/store.service';
import {MockService} from '../../../../../services/mock/mock.service';
import {IntegrationFormService} from "../../../services/integration-form.service";
import {AbstractControl, FormControl, FormGroup} from "@angular/forms";

const serviceCode = 'AMO_CRM_IMPORT'

@Component({
  selector: 'app-amo-crm-contacts-import',
  templateUrl: './amo-crm-contacts-import.component.html',
  styleUrls: ['./amo-crm-contacts-import.component.scss']
})
export class AmoCrmContactsImportComponent implements OnInit {

  public sections: any[];

  public get formFields() {
    return this.store.formFields;
  }

  constructor(
    private store: StoreService,
    private mock: MockService,
    private integrationForm: IntegrationFormService
  ) {
  }

  ngOnInit() {
    this.LoadCrmSections();
    this.LoadCrmFormFields();
  }

  public LoadCrmSections() {
    this.sections = this.store.services.find(service => service.code === serviceCode).sections;
    const sectionsControls = {};
    this.sections.forEach(section => {
      console.warn(section.code);
      (this.integrationForm.form.get('amoImport') as FormGroup).addControl(section.code, this.sectionToFormGroup(section));
    });
    // this.integrationF
    // console.log(sectionsControls);
    // this.integrationForm.form.controls.amoImport = new FormGroup(sectionsControls);
    // this.integrationForm.form.controls.amoImport.valueChanges.subscribe((data => {
    //   console.log(this.integrationForm.form);
    // }));
    console.warn(this.integrationForm.form, 'ALL FIELDS INITED');
  }

  private sectionToFormGroup(section): AbstractControl {
    const sectionFormGroup = new FormGroup({
      status: new FormControl(),
      fields: new FormGroup({})
    });
    section.fields.forEach(field => {
      (sectionFormGroup.get('fields') as FormGroup).addControl(field.code, new FormControl());
    });
    return sectionFormGroup;
  }

  public LoadCrmFormFields() {
    // this.formFields = this.store.formFields;
  }

  public getFormsField() {
    this.store.getFormsField()
  }

}
