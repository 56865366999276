import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Observable, throwError} from 'rxjs';
import {tap} from 'rxjs/internal/operators/tap';
import {catchError} from 'rxjs/operators';
import {LoggingService} from '../shared/services/logging.service';

@Injectable()
export class SentryInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private logger: LoggingService
  ) {
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    console.log('interceptor works');
    return next.handle(req);
//      .pipe(
//        tap((data) => {
//          console.log('SUCCESS');
//          return data;
//        }),
//        catchError((error) => {
//          console.log('ERROR 25');
//          if (error.status && error.status === 401) {
//            this.NavigateToSignInPage();
//          } else {
//            if (error.error && typeof error.error === 'string') {
//              this.logger.Error(error.error);
//            } else if (error.error && error.error.error_desc) {
//              this.logger.Error(error.error.error_desc);
//            }
//          }
//
//          return throwError(error);
//        })
//      );
  }

  private handleErrors(error) {

  }

  private handleSuccess(response) {
  }

  private NavigateToSignInPage() {
    this.router.navigate(['/sign-in']);
  }
}
