import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {SignInComponent} from './routes/sign-in/sign-in.component';
import {SignUpComponent} from './routes/sign-up/sign-up.component';
import {AuthorizationLayoutComponent} from './routes/authorization-layout/authorization-layout.component';
import {ForgotPasswordComponent} from './routes/forgot-password/forgot-password.component';
import {CounterRegisterGoalProxyComponent} from './routes/counter-register-goal-proxy/counter-register-goal-proxy.component';

const routes: Routes = [
    {
        path: '',
        component: AuthorizationLayoutComponent,
        children: [
            {
                path: 'sign-in',
                component: SignInComponent
            },
            {
                path: 'sign-up',
                component: SignUpComponent
            },
            {
                path: 'forgot-password',
                component: ForgotPasswordComponent
            },
            {
                path: 'sign-up-completed',
                component: CounterRegisterGoalProxyComponent
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AuthorizationRoutingModule {
}
