var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { QueryEntity } from '@datorama/akita';
import { CrmPipelinesStore } from './crm-pipelines.store';
import { flatMap, map } from 'rxjs/operators';
import { AkitaNgFormsManager } from '@datorama/akita-ng-forms-manager';
import { isNullOrUndefined } from 'util';
import * as i0 from "@angular/core";
import * as i1 from "./crm-pipelines.store";
import * as i2 from "@datorama/akita-ng-forms-manager";
var CrmPipelinesQuery = /** @class */ (function (_super) {
    __extends(CrmPipelinesQuery, _super);
    function CrmPipelinesQuery(store, fm) {
        var _this = _super.call(this, store) || this;
        _this.store = store;
        _this.fm = fm;
        /**
         *
         */
        _this.pipelines$ = _this.selectAll()
            .pipe(map(function (pipelines) { return pipelines.sort(function (a, b) { return a.sort - b.sort; }); }));
        _this.pipelinesBySection$ = function (section) { return _this.selectAll({ filterBy: function (x) { return x.type === section; } })
            .pipe(map(function (x) { return x.sort(function (a, b) { return a.sort - b.sort; }); })); };
        /**
         *
         */
        _this.selectedPipeLineStatuses$ = _this.fm.selectValue('originPlatformSettings')
            .pipe(map(function (x) { return x.selectedPipelineId; }), flatMap(function (pipelineId) { return _this.selectAll({
            filterBy: function (e) { return e.id === pipelineId; }
        }); }), map(function (x) { return x[0] ? x[0].statuses : []; }));
        /**
         * Список всех статусов из воронки продаж с типом LEADS (Только для Битрикс24)
         * @return {CrmPipelineStatus[]} список этапов воронки продаж;
         */
        _this.leadsPipelineStatuses$ = _this.pipelines$
            .map(function (pipelines) { return pipelines.find(function (pipeline) { return pipeline.type === 'LEADS'; }); })
            .map(function (pipeline) { return pipeline ? pipeline.statuses : []; });
        return _this;
    }
    Object.defineProperty(CrmPipelinesQuery.prototype, "hasPipelines", {
        get: function () {
            console.log('HAS PIPELINES', this.getAll(), this.getValue());
            return this.getAll().length > 0;
        },
        enumerable: true,
        configurable: true
    });
    CrmPipelinesQuery.prototype.pipelineSectionCodeById = function (pipelineId) {
        var pipeline = this.getAll()
            .find(function (x) { return x.id === pipelineId; });
        var pipelineSectionCode = 'CONTACTS';
        if (pipeline != null) {
            pipelineSectionCode = pipeline.type;
        }
        return pipelineSectionCode;
    };
    /**
     * Getting entity id by pipeline id
     * @param pipelineId
     */
    CrmPipelinesQuery.prototype.getEntityIdByPipelineId$ = function (pipelineId) {
        var pipeline = this.getAll()
            .find(function (x) { return x.id === pipelineId; });
        if (!isNullOrUndefined(pipeline)) {
            return pipeline.entity_id;
        }
        else {
            return 0;
        }
    };
    /**
     *
     * @param section
     * @param pipelineId
     * @constructor
     */
    CrmPipelinesQuery.prototype.GetPipelineBySectionCode = function (section, pipelineId) {
        if (pipelineId === void 0) { pipelineId = null; }
        return this.getAll()
            .find(function (pipeline) { return pipelineId !== null ? pipeline.type === section && pipeline.id === pipelineId : pipeline.type === section; });
    };
    CrmPipelinesQuery.ngInjectableDef = i0.defineInjectable({ factory: function CrmPipelinesQuery_Factory() { return new CrmPipelinesQuery(i0.inject(i1.CrmPipelinesStore), i0.inject(i2.AkitaNgFormsManager)); }, token: CrmPipelinesQuery, providedIn: "root" });
    return CrmPipelinesQuery;
}(QueryEntity));
export { CrmPipelinesQuery };
