import {Component, Input, OnInit} from '@angular/core';
import {ChatConversationModel} from "../../models/chat-conversation-model";

@Component({
  selector: 'app-chat-conversation',
  templateUrl: './chat-conversation.component.html',
  styleUrls: ['./chat-conversation.component.scss']
})
export class ChatConversationComponent implements OnInit {
  @Input() public conversation: ChatConversationModel = null;

  constructor() { }

  ngOnInit() {
  }

}
