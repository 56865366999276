var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { QueryEntity } from '@datorama/akita';
import { UserIntegrationStore } from './user-integration.store';
import { tap } from "rxjs/operators";
import * as i0 from "@angular/core";
import * as i1 from "./user-integration.store";
var UserIntegrationQuery = /** @class */ (function (_super) {
    __extends(UserIntegrationQuery, _super);
    function UserIntegrationQuery(store) {
        var _this = _super.call(this, store) || this;
        _this.store = store;
        _this.userIntegrations$ = _this.selectAll()
            .pipe(tap(function (x) {
            console.log(x);
        }));
        return _this;
    }
    UserIntegrationQuery.ngInjectableDef = i0.defineInjectable({ factory: function UserIntegrationQuery_Factory() { return new UserIntegrationQuery(i0.inject(i1.UserIntegrationStore)); }, token: UserIntegrationQuery, providedIn: "root" });
    return UserIntegrationQuery;
}(QueryEntity));
export { UserIntegrationQuery };
