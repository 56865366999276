<form [formGroup]="form" class="vk-retargeting-groups">
  <div class="vk-retargeting-groups__accounts">
    <vk-form-field orientation="vertical" [controlsWidth]="'100%'" [style.width]="'100%'">
      <vk-label>Выберите рекламный кабинет</vk-label>
      <mat-select vkInput [formControl]="account">
        <ng-container *ngFor="let accountItem of accounts">
          <mat-option [value]="accountItem">{{accountItem.account_name}}</mat-option>
        </ng-container>
      </mat-select>
    </vk-form-field>
    <div class="vk-retargeting-groups__spinner" *ngIf="accountsLoading">
      <mat-spinner diameter="18"></mat-spinner>
    </div>
  </div>
  <div class="vk-retargeting-groups__groups-list" *ngIf="account.value">
    <div>
      <mat-checkbox [formControl]="sortByPipeline">Распределить по этапам воронки</mat-checkbox>
    </div>
    <ng-container *ngIf="useSortingByPipelines; else withoutSorting">
      <ng-container *ngFor="let status of pipelineStatuses">
        <vk-form-field orientation="vertical" [controlsWidth]="'100%'">
          <vk-label>{{status.name}}</vk-label>
          <mat-select vkInput formControlName="groupIds" [multiple]="true">
            <ngx-mat-select-search
              [formControl]="searchTargetGroups"
              placeholderLabel="Введите название таргетинговой аудитории"
              noEntriesFoundLabel="Группы ретаргетинга с таким названием не найдены"
            >
            </ngx-mat-select-search>
            <ng-container *ngFor="let group of filteredRetargetingGroup">
              <mat-option [value]="group">{{group.name}}</mat-option>
            </ng-container>
          </mat-select>
        </vk-form-field>
      </ng-container>
    </ng-container>
    <ng-template #withoutSorting>
      <vk-form-field orientation="vertical" [controlsWidth]="'100%'">
        <vk-label>Выберите группу аудитории</vk-label>
        <mat-select vkInput formControlName="groupIds" [multiple]="true">
          <ngx-mat-select-search
            [formControl]="searchTargetGroups"
            placeholderLabel="Введите название таргетинговой аудитории"
            noEntriesFoundLabel="Группы ретаргетинга с таким названием не найдены"
          >
          </ngx-mat-select-search>
          <ng-container *ngFor="let group of filteredRetargetingGroup">
            <mat-option [value]="group">{{group.name}}</mat-option>
          </ng-container>
        </mat-select>
      </vk-form-field>
    </ng-template>
  </div>
</form>
