import {Injectable, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import * as Sentry from '@sentry/browser';
import {Severity} from '@sentry/browser';
import {Breadcrumb} from '../models/models/breadcrumb';
import {Tag} from '../models/models/tag';
import {Extra} from '../models/models/extra';

@Injectable({
  providedIn: 'root'
})
export class LoggingService implements OnInit {

  public tags: Array<Tag> = [];
  public breadcrumbs: Array<Breadcrumb> = [];
  public extras: Array<Extra> = [];

  constructor(
    private router: Router
  ) {
  }

  public ngOnInit(): void {
  }

  /**
   *
   * @constructor
   */
  private SetUserData(scope) {
    const userId = this.getUserId();
    if (userId) {
      scope.setUser({
        id: userId
      });
    }
  }

  /**
   *
   * @param {string} tagName
   * @param tagValue
   * @constructor
   */
  public SetTag(tagName: string, tagValue) {
    this.tags.push({tagName, tagValue});
  }

  /**
   *
   * @param {string} message
   * @param data
   * @param {string} category
   * @param {Severity} level
   * @constructor
   */
  public AddBreadcrumb(message: string, data: any = null, category: string = 'default', level: Sentry.Severity = Severity.Info) {
    this.breadcrumbs.push({
      timestamp: Date.now(),
      message,
      category,
      data,
      level,
      type: null
    });
  }

  public AddExtra(key: string, data: any) {
    this.extras.push({key, data});
  }

  /**
   *
   * @returns {string}
   */
  private getUserId() {
    return localStorage.getItem('sentry_user_id') || null;
  }

  public Log(message: string) {
    console.log('Отправки логов!');
    Sentry.withScope(scope => {
      this.SetBreadCrumbs(scope);
      this.SetTags(scope);
      this.SetUserData(scope);
      Sentry.captureMessage(message);
    });
  }

  public Error(error: string, errorData: any = null) {
    console.log('Отправки логов! с ошибкой');
    Sentry.withScope(scope => {
      this.SetBreadCrumbs(scope);
      this.SetTags(scope);
      this.SetUserData(scope);
      this.SetExtras(scope);
      let err: Error = null;
      if (errorData) {
        err = errorData;
      } else {
        err = new Error(error);
      }
      this.AddExtra('error_data', err);
      Sentry.captureMessage(error, Severity.Error);
    });

  }

  private SetBreadCrumbs(scope: Sentry.Scope) {
    this.breadcrumbs.forEach(({level, category, message, timestamp, data, type}) => {
      scope.addBreadcrumb({level, category, message, timestamp, data});
    });
  }

  private SetTags(scope: Sentry.Scope) {
    this.tags.forEach(({tagName, tagValue}) => {
      scope.setTag(tagName, tagValue);
    });
  }

  private SetExtras(scope: Sentry.Scope) {
    this.extras.forEach(({key, data}) => {
      scope.setExtra(key, data);
    });
  }
}
