import { AfterViewInit, ChangeDetectorRef, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { PlatformsTypes } from '../../../../platforms-types';
import { AssignationPlatforms } from '../../../../stores/platform-assignations/platform-assignation.model';
import { RetargetingGroupsService } from '../../../../stores/retargeting-groups/retargeting-groups.service';
import { RetargetingGroupsQuery } from '../../../../stores/retargeting-groups/retargeting-groups.query';
import { AkitaNgFormsManager } from '@datorama/akita-ng-forms-manager';
import { CrmPipelinesQuery } from '../../../../stores/crm-pipelines/crm-pipelines.query';
import { PlatformAssignationsQuery } from '../../../../stores/platform-assignations/platform-assignations.query';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';
import { PlatformsQuery } from '../../../../stores/platforms/platforms.query';
import { combineLatest } from 'rxjs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { FacebookAdAccountService } from "../../../../../api/services";
import { environment } from "../../../../../../environments/environment";
var RetargetingGroupsSettingsComponent = /** @class */ (function () {
    function RetargetingGroupsSettingsComponent(retargetingGroupsService, retargetingGroupsQuery, fb, fm, pipelinesQuery, cd, assignationsQuery, platformsQuery, facebookAccountsService) {
        this.retargetingGroupsService = retargetingGroupsService;
        this.retargetingGroupsQuery = retargetingGroupsQuery;
        this.fb = fb;
        this.fm = fm;
        this.pipelinesQuery = pipelinesQuery;
        this.cd = cd;
        this.assignationsQuery = assignationsQuery;
        this.platformsQuery = platformsQuery;
        this.facebookAccountsService = facebookAccountsService;
        this.accounts = [];
        this.loading = false;
        this.retargetingGroups$ = this.retargetingGroupsQuery.retargetingGroups$;
        this.pipelineStatuses$ = this.pipelinesQuery.selectedPipeLineStatuses$;
        this.leadPipelineStatuses$ = this.pipelinesQuery.leadsPipelineStatuses$;
        this.isSelectedUonTravel$ = this.platformsQuery.selectedOriginPlatformCode$
            .pipe(map(function (x) { return x === 'UON_EXPORT'; }));
        this.isAssigned = false;
        this.hasLeads = false;
        this.accountCtrl = new FormControl();
        this.splitByPipelines = false;
        this.pipelineStatuses = null;
        this.accountsLoaded = false;
        this.dealsTitle = 'Сделки';
        this.withoutSplitLabel = 'Передавать данные в аудитории';
    }
    Object.defineProperty(RetargetingGroupsSettingsComponent.prototype, "pipelineStatusPlaceholder", {
        get: function () {
            return this.platformsQuery.selectedDestinationPlatformCode === PlatformsTypes.FB_CUSTOM_AUDIENCES ? 'Выберите пользовательские аудитории' : 'Выберите аудитории ретаргетинга';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RetargetingGroupsSettingsComponent.prototype, "isCrmMoiSklad", {
        get: function () {
            return this.platformsQuery.selectedOriginPlatformCode === 'MOY_SKLAD_EXPORT';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RetargetingGroupsSettingsComponent.prototype, "labelText", {
        get: function () {
            return 'Аудитории ретаргетинга';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RetargetingGroupsSettingsComponent.prototype, "slots", {
        get: function () {
            return this.retargetingGroupsService.slots;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RetargetingGroupsSettingsComponent.prototype, "allAccounts", {
        get: function () {
            return this.retargetingGroupsService.allAccounts;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RetargetingGroupsSettingsComponent.prototype, "isAllContacts", {
        get: function () {
            return this.fm.getForm('originPlatformSettings').value.section === 'CONTACTS' && this.isCrmMoiSklad === false;
        },
        enumerable: true,
        configurable: true
    });
    RetargetingGroupsSettingsComponent.prototype.withoutSplitSuggestionCode = function () {
        if (!this.isAllContacts) {
            return 'VK_RETARGETING_GROUPS_SELECTOR_PIPELINE_NO_SPLIT';
        }
        return 'VK_RETARGETING_GROUPS_SELECTOR_ALL';
    };
    RetargetingGroupsSettingsComponent.prototype.InitForm = function () {
        var _this = this;
        this.form = this.fb.group({
            accountId: this.fb.control(null),
            splitByPipelines: this.fb.control(false),
            isClassicMode: this.fb.control(this.hasLeads),
            pipelineStatuses: this.fb.group({
                pipeline_null: this.fb.group({
                    pipelineId: this.fb.control(null),
                    pipelineType: this.fb.control('DEALS'),
                    retargetingGroups: this.fb.control(null)
                })
            })
        });
        this.form.get('accountId')
            .valueChanges
            .pipe(distinctUntilChanged())
            .subscribe(function (data) {
            _this.ResetAllPipelineStatuses();
        });
        this.form
            .get('splitByPipelines')
            .valueChanges
            .subscribe(function (data) {
            _this.splitByPipelines = data;
            _this.ResetAllPipelineStatuses();
        });
        this.form
            .get('accountId')
            .valueChanges
            .subscribe(function (_a) {
            var accountId = _a[0], clientId = _a[1];
            if (_this.type === PlatformsTypes.FB_CUSTOM_AUDIENCES) {
                _this.retargetingGroupsService.LoadCustomAudiences(accountId);
            }
            else {
                _this.retargetingGroupsService.LoadRetargetingGroupsFromVk(accountId, clientId);
            }
        });
        this.InitSections();
        this.fm.upsert('destinationPlatformSettings', this.form);
    };
    RetargetingGroupsSettingsComponent.prototype.ResetAllPipelineStatuses = function () {
        var pipelineStatusesGroup = this.form.get('pipelineStatuses');
        if (pipelineStatusesGroup) {
            Object
                .keys(pipelineStatusesGroup.controls)
                .map(function (key) { return pipelineStatusesGroup.get(key); })
                .forEach(function (pipelineStatusGroup) {
                pipelineStatusGroup.get('retargetingGroups').reset();
            });
        }
    };
    RetargetingGroupsSettingsComponent.prototype.InitPipelines = function () {
        var _this = this;
        combineLatest(this.pipelineStatuses$, this.leadPipelineStatuses$)
            .subscribe(function (_a) {
            var pipelineStatuses = _a[0], leadPipelineStatuses = _a[1];
            var statuses = pipelineStatuses.concat(leadPipelineStatuses);
            _this.pipelineStatuses = statuses;
            _this.UpdatePipelineStatusesForm();
        });
        this.pipelineStatuses$.subscribe(function (pipelineStatuses) {
        });
    };
    RetargetingGroupsSettingsComponent.prototype.UpdatePipelineStatusesForm = function () {
        var _this = this;
        if (this.pipelineStatuses.length === 0) {
            this.form.get('splitByPipelines').setValue(false, { emitEvent: false });
        }
        this.pipelineStatuses.forEach(function (pipelineStatus) {
            var pipelineStatusesGroup = _this.form.get('pipelineStatuses');
            pipelineStatusesGroup.addControl("pipeline_" + pipelineStatus.type + "_" + pipelineStatus.id, new FormGroup({
                pipelineId: new FormControl(pipelineStatus.id),
                pipelineType: new FormControl(pipelineStatus.type),
                retargetingGroups: new FormControl()
            }));
        });
    };
    RetargetingGroupsSettingsComponent.prototype.ngOnInit = function () {
    };
    RetargetingGroupsSettingsComponent.prototype.InitSections = function () {
        var _this = this;
        this.fm.selectValue('originPlatformSettings')
            .subscribe(function (_a) {
            var section = _a.section;
            if (section !== 'CONTACTS') {
                if (section === 'UON_MARKS_EXPORT') {
                    _this.withoutSplitLabel = 'Передавать все в аудитории';
                    if (_this.form) {
                        _this.form.get('splitByPipelines').setValue(true);
                    }
                    else {
                        setTimeout(function () {
                            _this.form.get('splitByPipelines').setValue(true);
                        }, 1000);
                    }
                }
                else {
                    _this.withoutSplitLabel = 'Передавать данные всей воронки в аудитории';
                }
            }
            else {
                if (_this.form) {
                    _this.form.patchValue({ splitByPipelines: false });
                }
            }
        });
    };
    RetargetingGroupsSettingsComponent.prototype.LoadAccounts = function () {
        this.loading = true;
        this.detectChanges();
        console.log(this.type, 'FACEBOOK');
        switch (this.type) {
            case PlatformsTypes.FB_CUSTOM_AUDIENCES:
                return this.LoadFaceBookAccounts();
                break;
            case PlatformsTypes.VK_RETARGETING_GROUPS:
                return this.LoadVkData();
                break;
        }
    };
    RetargetingGroupsSettingsComponent.prototype.LoadVkData = function () {
        var _this = this;
        return this.retargetingGroupsService.LoadVkAccountData()
            .pipe(tap(function (data) {
            _this.accountsLoaded = true;
            _this.accounts = _this.retargetingGroupsService.accounts;
            _this.loading = false;
            _this.detectChanges();
        }));
    };
    RetargetingGroupsSettingsComponent.prototype.getPipelineStatusControl = function (i) {
        var pipelineStatusesForm = this.form.get('pipelineStatuses').at(i);
        if (pipelineStatusesForm) {
            return pipelineStatusesForm.get('retargetingGroups');
        }
        else {
            return null;
        }
    };
    RetargetingGroupsSettingsComponent.prototype.ngAfterViewInit = function () {
        this.InitAll();
    };
    RetargetingGroupsSettingsComponent.prototype.InitAll = function () {
        var _this = this;
        this.CheckAssignation();
        this.assignationsQuery
            .isAssigned$(this.platform)
            .pipe(untilDestroyed(this))
            .subscribe(function (isAssigned) {
            _this.accountsLoaded = false;
            _this.platformsQuery
                .selectedOriginPlatformCode$
                .pipe(untilDestroyed(_this))
                .subscribe(function (code) {
                var platform = _this.assignationsQuery.platform(code);
                _this.platformsQuery.getPlatformParams(platform)
                    .pipe(untilDestroyed(_this))
                    .subscribe(function (params) {
                    if (params) {
                        _this.hasLeads = params.hasLeads;
                    }
                });
            });
            _this.LoadAccounts()
                .pipe(untilDestroyed(_this))
                .subscribe(function (accounts) {
                if (_this.accounts.length > 0) {
                    _this.InitForm();
                    _this.InitPipelines();
                }
            });
        });
    };
    RetargetingGroupsSettingsComponent.prototype.ngOnChanges = function (changes) {
    };
    RetargetingGroupsSettingsComponent.prototype.CheckAssignation = function () {
        var _this = this;
        var assignationsData = this.assignationsQuery.getAll({ filterBy: function (entity) { return entity.id === _this.platform; } });
        if (assignationsData && assignationsData.length > 0) {
            this.isAssigned = assignationsData[0].assignationStatus;
        }
    };
    RetargetingGroupsSettingsComponent.prototype.detectChanges = function () {
        if (!this.cd['destroyed']) {
            this.cd.detectChanges();
        }
    };
    RetargetingGroupsSettingsComponent.prototype.hasControlWithName = function (controlName) {
        return this.form.get('pipelineStatuses').get(controlName);
    };
    RetargetingGroupsSettingsComponent.prototype.isPipeliningCheckboxVisible = function () {
        var section = this.fm.getForm(environment.integrations.originFormKey).value.section;
        console.log(section, 'IS PIPELINE CHECKBOX VISIBLE');
        if (section === 'UON_MARKS_EXPORT') {
            return false;
        }
        else if (section === 'CONTACTS') {
            return false;
        }
        else {
            console.log(this.pipelineStatuses.length > 0, this.form.get('accountId').value !== null);
            return this.pipelineStatuses.length > 0 && this.form.get('accountId').value !== null;
        }
    };
    RetargetingGroupsSettingsComponent.prototype.ngOnDestroy = function () {
    };
    RetargetingGroupsSettingsComponent.prototype.LoadFaceBookAccounts = function () {
        var _this = this;
        return this.facebookAccountsService.FacebookGetAdAccountsAsync({
            fields: ['name']
        })
            .pipe(tap((function (data) {
            _this.accountsLoaded = true;
            _this.accounts = data.data.data.map(function (x) { return ({ account_id: x.id, account_name: x.name }); });
            _this.loading = false;
        })));
    };
    return RetargetingGroupsSettingsComponent;
}());
export { RetargetingGroupsSettingsComponent };
