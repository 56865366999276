import {Injectable} from '@angular/core';
import {AdNetworkForm} from './ad-network-form.model';
import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';

export interface AdNetworkFormsState extends EntityState<AdNetworkForm> {
  ui: {
    opened: number;
  }
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'ad-network-forms'})
export class AdNetworkFormsStore extends EntityStore<AdNetworkFormsState> {

  constructor() {
    super({
      ui: {
        opened: null
      }
    });
  }

}

