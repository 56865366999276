import { OnInit } from '@angular/core';
import { AdFormManagerService } from '../../../new-ad-manager-shared/services/ad-form-manager/ad-form-manager.service';
var NewPromopostFormProComponent = /** @class */ (function () {
    function NewPromopostFormProComponent(adFormManagerService) {
        this.adFormManagerService = adFormManagerService;
        this.controlsWidth = '256px';
        this.labelsWidth = '220px';
        this.textAreaHeight = '237px';
    }
    Object.defineProperty(NewPromopostFormProComponent.prototype, "adminGroups", {
        get: function () {
            return this.adFormManagerService.adminGroups;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewPromopostFormProComponent.prototype, "form", {
        get: function () {
            return this.adFormManagerService.getControl('promopost');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewPromopostFormProComponent.prototype, "emojiNotification", {
        get: function () {
            return this.adFormManagerService.promopostLabelTextForm;
        },
        enumerable: true,
        configurable: true
    });
    NewPromopostFormProComponent.prototype.ngOnInit = function () {
    };
    NewPromopostFormProComponent.prototype.ImageSelected = function (images) {
        this.adFormManagerService.AddPromopostImage(images);
    };
    NewPromopostFormProComponent.prototype.HasAdminOpenedGroups = function () {
        return this.adFormManagerService.hasOpenedAdminGroups();
    };
    NewPromopostFormProComponent.prototype.textLength = function (i) {
        return 600 - this.adFormManagerService.getControl('promopost', 'text', i.toString()).value.length;
    };
    return NewPromopostFormProComponent;
}());
export { NewPromopostFormProComponent };
