import { OnInit } from '@angular/core';
import { AdFormManagerService } from '../../../new-ad-manager-shared/services/ad-form-manager/ad-form-manager.service';
var NewPromopostFormLiteComponent = /** @class */ (function () {
    function NewPromopostFormLiteComponent(adFormManagerService) {
        this.adFormManagerService = adFormManagerService;
        this.controlsWidth = '256px';
        this.labelsWidth = '140px';
        this.IsAdToGroup = true;
    }
    Object.defineProperty(NewPromopostFormLiteComponent.prototype, "form", {
        get: function () {
            return this.adFormManagerService.adForm.controls.promopost;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewPromopostFormLiteComponent.prototype, "adminGroups", {
        get: function () {
            return this.adFormManagerService.adminGroups;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewPromopostFormLiteComponent.prototype, "label", {
        get: function () {
            return this.adFormManagerService.promopostLabelTextForm;
        },
        enumerable: true,
        configurable: true
    });
    NewPromopostFormLiteComponent.prototype.ngOnInit = function () {
    };
    NewPromopostFormLiteComponent.prototype.ImageSelected = function (images) {
        this.adFormManagerService.AddPromopostImage(images);
    };
    NewPromopostFormLiteComponent.prototype.HasAdminOpenedGroups = function () {
        return this.adFormManagerService.hasOpenedAdminGroups();
    };
    NewPromopostFormLiteComponent.prototype.textLength = function (i) {
        return 600 - this.adFormManagerService.getControl('promopost', 'text', i.toString()).value.length;
    };
    return NewPromopostFormLiteComponent;
}());
export { NewPromopostFormLiteComponent };
