import {Component, OnInit} from '@angular/core';
import {PaymentsService} from "../../../api/services/payments.service";
import {TariffPlan} from "../../../api/models/tariff-plan";
import {TariffPlanViewModel} from "../../models/tariff-plan-view-model";
import {TariffPlanOptionSet} from "../../models/tariff-plan-option-set";
import {TariffPlanOptionTimeValue} from "../../models/tariff-plan-option-time-value";
import {PromocodesService} from "../../../api/services/promocodes.service";
import {PaymentSystem} from "../../../api/models/payment-system";
import {isNullOrUndefined} from 'util';

@Component({
  selector: 'app-easy-payments-create',
  templateUrl: './easy-payments-create.component.html',
  styleUrls: ['./easy-payments-create.component.scss']
})
export class EasyPaymentsCreateComponent implements OnInit {
  public tariffViewModel: Array<TariffPlanViewModel> = null;
  public paymentSystems: Array<PaymentSystem> = null;
  private discountPromocodeEffectIds: Array<number> = [1, 3, 4];

  public selectedTariff: TariffPlanViewModel = null;
  public selectedPaymentSystem: PaymentSystem = null;

  public securePaymentUrl: string = null;

  public paymentRequested: boolean = false;
  public paymentIsRequesting: boolean = false;

  constructor(private paymentsService: PaymentsService, private promoService: PromocodesService) {
  }

  ngOnInit() {
    this.LoadTariffPlans();
    this.LoadPaymentSystems();
  }

  private LoadPaymentSystems(): void {
    this.paymentsService.getPaymentSystems()
      .subscribe((response: Array<PaymentSystem>) => {
        this.paymentSystems = response;
      });
  }

  private LoadTariffPlans() {
    this.paymentsService.getTariffPlans()
      .subscribe((response: Array<TariffPlan>) => {
        if (response && response.length > 0) {

          this.tariffViewModel = [];

          response.forEach(tariff => {
            const viewModel: TariffPlanViewModel = new TariffPlanViewModel();
            viewModel.id = tariff.id;
            viewModel.code = tariff.code;
            viewModel.title = 'Заголовок';

            if (tariff.prices && tariff.prices.length > 0) {
              viewModel.configurations = [];

              const options = new Set(tariff.prices.map(x => x.optionId));

              options.forEach(optionId => {
                const option = tariff.prices.find(x => x.optionId === optionId);


                const configuration = new TariffPlanOptionSet();
                configuration.optionId = optionId;
                configuration.times = [];
                if (option && option.option) {
                  configuration.title = option.option.title;
                }

                const optionTimes = tariff.prices.filter(x => x.optionId === optionId);

                console.warn(`Option times of code: ${viewModel.code} and option id = ${optionId}`, optionTimes);

                if (optionTimes && optionTimes.length > 0) {
                  optionTimes.forEach(time => {
                    const optionTimeValue = new TariffPlanOptionTimeValue();
                    optionTimeValue.timeId = time.timeId;
                    optionTimeValue.price = time.price;
                    optionTimeValue.isAvailable = time.isAvailable;

                    if (time.time) {
                      optionTimeValue.title = time.time.title;
                      optionTimeValue.descriminator = time.time.descriminator;
                      optionTimeValue.value = time.time.value;
                    }

                    configuration.times.push(optionTimeValue);
                  });

                  configuration.isDisabled = !isNullOrUndefined(optionTimes.find(x => !x.isAvailable));
                } else {
                  configuration.isDisabled = true;
                }

                viewModel.configurations.push(configuration);
              });
            }

            const defaultOption =  viewModel.configurations.find(x => !x.isDisabled);

            if (defaultOption) {
              viewModel.selectedOptionId = defaultOption.optionId;
              viewModel.SelectOption(viewModel.selectedOptionId);

              if (defaultOption.times && defaultOption.times.length > 0) {
                viewModel.selectedTimeId = defaultOption.times[0].timeId;
                viewModel.SelectTime(viewModel.selectedTimeId);
              } else {
                console.warn(`Default option have no times (no times in option with id=${defaultOption.optionId})`, viewModel);
              }
            } else {
              console.warn(`No default option for viewModel (no option with isDisabled=false) ${viewModel.code}`, viewModel);
            }

            this.tariffViewModel.push(viewModel);
          });

          // Process automation tariffplan
          const automationTariffPlan = this.tariffViewModel.find(x => x.code === 'VK_AUTOMATION');

          if (automationTariffPlan) {
            automationTariffPlan.title = 'Создание, запуск и редактирования объявлений ВКонтакте';

            //automationTariffPlan.configurations = [automationTariffPlan.configurations[0]];
          }

          const analyticsTariffPlan = this.tariffViewModel.find(x => x.code === 'VK_ANALYTICS');

          if (analyticsTariffPlan) {
            analyticsTariffPlan.title = 'Просмотр примеров объявлений';
          }
        }
        // this.tariffPlans.forEach(x => {
        //   if (x.prices[0]) {
        //     this.tariffPalnSelectedTime[x.id] = x.prices[0].time;
        //     this.tariffPalnSelectedOption[x.id] = x.prices.find(x => x.isAvailable).optionId; // Choose fist available option
        //   }
        // });
      });
  }

  public CheckPromocode(tariffPlan: TariffPlanViewModel, promocode: string) {
    this.promoService.CheckPromocode(promocode).subscribe(response => {
      tariffPlan.promocodeMessage = null;

      // Check if this is discount promocode
      if (response) {
        if (this.discountPromocodeEffectIds.indexOf(response.promocodeEffectId) >= 0) {
          tariffPlan.promocodeMessage = 'Скидочный промокод применён';
          tariffPlan.promocodeMessageIsError = false;
          tariffPlan.appliedPromocode = response;
        } else {
          // Add ACCESS_EXTENDED message or ERROR message procession
          this.promoService.ApplyActionPromocode(promocode).subscribe(resp => {
            tariffPlan.promocodeMessage = 'Промокод успешно применён!';
            tariffPlan.promocodeMessageIsError = false;
          }, err => {
            tariffPlan.promocodeMessage = 'Не удалось применить промокод';
            tariffPlan.promocodeMessageIsError = true;
          });
        }
      }
    }, error => {
      if (error && error.error && error.error.description) {
        tariffPlan.promocodeMessage = error.error.description;
      } else {
        tariffPlan.promocodeMessage = 'Не удалось применить промокод';
      }

      tariffPlan.promocodeMessageIsError = true;
      tariffPlan.appliedPromocode = null;
    });
  }

  public SelectTariff(tariff: TariffPlanViewModel): void {
    this.selectedTariff = tariff;
  }

  public SelectAnotherTariff(): void {
    this.selectedTariff = null;
  }

  public SelectPaymentSystem(system: PaymentSystem): void {
    this.selectedPaymentSystem = system;
  }

  public SelectAnotherPaymentSystem(): void {
    this.selectedPaymentSystem = null;
  }

  public ConfirmSelectionAndRequestPayment(): void {
    this.securePaymentUrl = null;
  this.paymentRequested = true;
  this.paymentIsRequesting = true;
    this.paymentsService.createPayment({
      tariffPlanId: this.selectedTariff.id,
      paymentSystemId: this.selectedPaymentSystem.id,
      timeId: this.selectedTariff.selectedTimeId,
      optionId: this.selectedTariff.selectedOptionId,
      promocodeId: (this.selectedTariff.appliedPromocode) ? this.selectedTariff.appliedPromocode.id : null
    }).subscribe(data => {
      this.securePaymentUrl = data.url;
      this.paymentIsRequesting = false;
      try {
        window.location.href = this.securePaymentUrl;
      } catch (ex) {

      }
    }, err => {
      this.paymentIsRequesting = false;
    });
  }
}
