import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {BidRange, Strategy} from '../../../api/models';
import {createValidator} from '../../../ngr-ui/utils/validator-creator';
import {DynamicFormComponent} from '../../../ngr-ui/components/dynamic-form/dynamic-form.component';
import {isNullOrUndefined} from 'util';

@Component({
  selector: 'app-strategies-manager',
  templateUrl: './strategies-manager.component.html',
  styleUrls: ['./strategies-manager.component.scss']
})
export class StrategiesManagerComponent implements OnInit {

  @Input() public strategies: Array<Strategy>;
  @Input() public currentStrategy;
  @Input() public bidRange: BidRange;

  @Output() public OnSave: EventEmitter<any> = new EventEmitter<any>();
  @Output() public Inited: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('adForm') public adForm: DynamicFormComponent;

  @Input() public selectedStrategy = '';
  public inputs = {};
  public displayForm = false;
  public strategySelected = false;

  public modifyFunctions = {
    BID_MIN: (value) => parseFloat(value) * 100,
    BID_START: (value) => parseFloat(value) * 100,
    BID_END: (value) => parseFloat(value) * 100,
    BID_MAX: (value) => parseFloat(value) * 100,
    BID_STEP: (value) => parseFloat(value) * 100,
    ECPC_LIMIT: (value) => parseFloat(value) * 100,
    CUSTOM_OVERALL_LIMIT: (value) => parseFloat(value) * 100,
  };

  public displayFunctions = {
    BID_MIN: (value) => parseFloat(value) / 100,
    BID_MAX: (value) => parseFloat(value) / 100,
    BID_START: (value) => parseFloat(value) / 100,
    BID_END: (value) => parseFloat(value) / 100,
    BID_STEP: (value) => parseFloat(value) / 100,
    ECPC_LIMIT: (value) => parseFloat(value) / 100,
    CUSTOM_OVERALL_LIMIT: (value) => parseFloat(value) / 100,
  };

  public customValidators = {
    BID_START: (bidRange: BidRange) => [
      createValidator({
        code: 'REQUIRED',
        invalidMessage: 'Поле должно быть заполнено'
      }, null),
      createValidator({
        code: 'MIN',
        invalidMessage: `Значение поля должно быть больше чем ${this.displayFunctions.BID_START(bidRange.cpmMin)}!`
      }, this.displayFunctions.BID_START(bidRange.cpmMin)),
      createValidator({
        code: 'MAX',
        invalidMessage: `Значение поля должно быть меньше чем ${this.displayFunctions.BID_START(bidRange.cpmMax)}!`
      }, this.displayFunctions.BID_START(bidRange.cpmMax))
    ]
  };

  public get SelectedStrategyTitle(): any {
    return this.strategies
      .find(strategy => strategy.code === this.selectedStrategy).title;
  }

  constructor() {
  }

  ngOnInit() {
    console.log('this.strategies', this.strategies);
    this.inputs = this.strategies
      .map(strategy => ({key: strategy.code, value: this.GetInputs(strategy)}))
      .reduce((acc, {key, value}) => {
        acc[key] = value;
        return acc;
      }, {});
    console.log(this.currentStrategy, 'CURRENT STRATEGY');
    if (this.currentStrategy) {
      this.selectedStrategy = this.currentStrategy.strategy.code;
      this.AcceptStrategy(this.currentStrategy.strategy.code);
    } else {
      if (this.strategies && this.strategies.length > 0) {
        this.selectedStrategy = this.strategies[0].code;
      }
    }
  }

  AcceptStrategy(selectedStrategy) {
    this.inputs = {};
    console.log(this.selectedStrategy, selectedStrategy, 'SELECTED STRAETGY CODE');
    this.displayForm = false;
    const strategy = this.strategies.find(x => x.code === this.selectedStrategy);
    this.strategySelected = false;
    strategy.inputs.forEach(y => {
      let currentInput = null;
      if (this.currentStrategy) {
        currentInput = this.currentStrategy.inputs.find(x => x.type.code === y.code);
      }
      let validators = [];
      if (this.customValidators[y.code]) {
        validators = this.customValidators[y.code](this.bidRange);
      } else {
        validators = y.validators
          .map(validator => createValidator(validator, validator.value));
      }

      console.log(y.defaultValue, 'DEFAULT VALUE');

      this.inputs[y.code] = {
        name: y.code,
        type: y['frontendInputType'],
        label: y.title,
        options: y.options,
        placeholder: y.placeholderContent,
        description: y.description,
        required: y.required,
        Validators: validators,
      };

      if (currentInput) {
        console.log(this.inputs, 'INPUTS');
        if (isNullOrUndefined(this.inputs)) {
          this.inputs = {};
        }
        console.log(this.inputs, 'INPUTS');
        this.inputs[y.code].value = (this.displayFunctions[y.code]) ? this.displayFunctions[y.code](currentInput.value) : currentInput.value;
      }
    });
    setTimeout(() => {
      this.displayForm = true;
      this.strategySelected = true;
    }, 500);
  }

  public Save(data) {
    console.log(data);
    data = Object.keys(data)
      .map(key => ({key, value: data[key]}))
      .reduce((acc, {key, value}) => {
        acc[key] = this.modifyFunctions[key] ? this.modifyFunctions[key](value) : value
        return acc;
      }, {});
    this.OnSave.emit({
      type: this.selectedStrategy,
      data
    });
  }

  public OnInit(data) {
    console.log('inited');
    this.Inited.emit(data);
  }

  public GetInputs(strategy) {
    const inputs = {}
    strategy.inputs.forEach(y => {
      let currentInput = null;
      console.log(this.currentStrategy, 'CURRENT_STRATEGY');
      if (this.currentStrategy) {
        currentInput = this.currentStrategy.inputs.find(x => x.type.code === y.code);
      }
      let validators = [];
      if (this.customValidators[y.code]) {
        validators = this.customValidators[y.code](this.bidRange);
      } else {
        validators = y.validators
          .map(validator => createValidator(validator, validator.value));
      }

      inputs[y.code] = {
        name: y.code,
        type: y['frontendInputType'],
        label: y.title,
        options: y.options,
        placeholder: y.placeholderContent,
        description: y.description,
        required: y.required,
        Validators: validators,
      };



      if (currentInput) {

        console.log(this.inputs, 'INPUTS');
        if (isNullOrUndefined(this.inputs)) {
          this.inputs = {};
        }
        console.log(this.inputs, 'INPUTS');
        inputs[y.code] = {}
        inputs[y.code].value = (this.displayFunctions[y.code]) ? this.displayFunctions[y.code](currentInput.value) : currentInput.value;
      }
    });
    return inputs;
  }
}
