import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NewAdManagerLiteModule} from './new-ad-manager-lite/new-ad-manager-lite.module';
import {NewAdManagerProModule} from './new-ad-manager-pro/new-ad-manager-pro.module';

import {NewAdManagerRoutingModule} from './new-ad-manager-routing.module';
import {NewAdFormatStepComponent} from './new-ad-manager-shared/steps/new-ad-format-step/new-ad-format-step.component';

@NgModule({
  imports: [
    CommonModule,
    NewAdManagerRoutingModule,
    NewAdManagerLiteModule,
    NewAdManagerProModule,
  ]
})
export class NewAdManagerModule {
}
