import {Injectable} from '@angular/core';
import {VkApiGroupStatistics} from '../models/api/vk-api-group-statistics';
import {VkApiConvertSexDistributedData} from '../models/api-convertation/vk-api-convert-sex-distributed-data';
import {GroupStatisticsDemograph} from '../models/api/group-statistics-demograph';
import {GroupStatisticsSex} from '../../api/models';
import {MathService} from '../../shared/services/math.service';
import {GroupStatistics} from "../../api/models/group-statistics";
import {GroupGeo} from "../../api/models/group-geo";
import {VkApiPost} from "../models/api/vk-api-post";
import {Post} from "../../api/models/post";
import {Group} from "../../api/models/group";
import {PostPhoto} from "../../api/models/post-photo";
import {PostAttachmentPhoto} from "../models/api/post-attachment-photo";
import {PostAttachmentVideo} from "../models/api/post-attachment-video";
import {PostVideo} from "../../api/models/post-video";
import {PostAttachmentLink} from "../models/api/post-attachment-link";
import {PostLink} from "../../api/models/post-link";
import {PostLinkButton} from "../../api/models/post-link-button";
import {PostButton} from "../models/api/post-button";
import {PostCopyHistory} from "../../api/models/post-copy-history";
import {PostAttachmentDocument} from "../models/api/post-attachment-document";
import {PostAnimation} from "../../api/models/post-animation";
import {VkApiGroup} from "../models/api/vk-api-group";
import {VkApiPostPrettyCards} from "../models/api/vk-api-post-pretty-cards";
import {PostCarousel} from "../../api/models/post-carousel";
import {VkApiPostPrettyCardsCards} from "../models/api/vk-api-post-pretty-cards-cards";
import {PostCarouselCard} from "../../api/models/post-carousel-card";
import {PostCarouselCardButton} from "../../api/models/post-carousel-card-button";
import {PostCarouselCardImage} from "../../api/models/post-carousel-card-image";

@Injectable()
export class VkApiConvertorService {

  constructor(private mathService: MathService) {
  }

  public ConvertStatistics(group_id: number, statistics: Array<VkApiGroupStatistics>): GroupStatistics {
    const convertredStatistics: GroupStatistics = {
      group_id: group_id,
      is_opened: true
    };

    const sexStat = this.ExtractStatisticsSexData(group_id, statistics);

    const allReach = statistics.map(x => x.reach);
    const allVisitors = statistics.map(x => x.visitors);

    convertredStatistics.sex_statistics = sexStat;

    convertredStatistics.reach = this.mathService.GetAverageValue(allReach.map(x => x.reach));
    convertredStatistics.reach_subscribers = this.mathService.GetAverageValue(allReach.map(x => x.reach_subscribers));
    convertredStatistics.visitors = this.mathService.GetAverageValue(allVisitors.map(x => x.visitors));
    convertredStatistics.views = this.mathService.GetAverageValue(allVisitors.map(x => x.views));

    return convertredStatistics;
  }

  public ConvertGeo(group_id: number, statistics: Array<VkApiGroupStatistics>): GroupGeo {
    const geo: GroupGeo = {
      id: group_id
    };

    const geoOrigin = this.ExtractGeoData(group_id, statistics);

    if (geoOrigin) {
      geo.ru = geoOrigin.ru;
      geo.ua = geoOrigin.ua;
      geo.kz = geoOrigin.kz;
      geo.by = geoOrigin.by;
    }

    return geo;
  }

  public ExtractStatisticsSexData(group_id: number, stat: Array<VkApiGroupStatistics>): Array<GroupStatisticsSex> {
    let maleData: VkApiConvertSexDistributedData = new VkApiConvertSexDistributedData();
    let femaleData: VkApiConvertSexDistributedData = new VkApiConvertSexDistributedData();

    stat.forEach(x => {
      const data = x.reach;

      const male = data.sex_age.filter(y => y.value.startsWith('m'));
      const female = data.sex_age.filter(y => y.value.startsWith('f'));

      const males = male.map(y => y.count).reduce((a, b) => a + b, 0);
      const females = male.map(y => y.count).reduce((a, b) => a + b, 0);

      const total = males + females;

      maleData = this.AssignSexDistributedValues(maleData, male, total);
      femaleData = this.AssignSexDistributedValues(femaleData, female, total);
    });

    const averageMales = maleData.GetTotal();
    const averageFemales = femaleData.GetTotal();
    const averateTotal = averageMales + averageFemales;

    const response: Array<GroupStatisticsSex> = [];
    response.push(this.ConvertSexDistributedToStatisticsSex(group_id, 'female', femaleData, averageFemales, averateTotal));
    response.push(this.ConvertSexDistributedToStatisticsSex(group_id, 'male', maleData, averageMales, averateTotal));

    return response;
  }

  private AssignSexDistributedValues(dataSet: VkApiConvertSexDistributedData, sexAgeData: Array<GroupStatisticsDemograph>, total: number): VkApiConvertSexDistributedData {
    sexAgeData.forEach(item => {
      const itemAgeMarker = item.value.split(';')[1];

      const value = item.count;

      switch (itemAgeMarker) {
        case  '12-18':
          dataSet.age_12_18.push(value);
          break;
        case  '18-21':
          dataSet.age_18_21.push(value);
          break;
        case  '21-24':
          dataSet.age_21_24.push(value);
          break;
        case  '24-27':
          dataSet.age_24_27.push(value);
          break;
        case  '27-30':
          dataSet.age_27_30.push(value);
          break;
        case  '30-35':
          dataSet.age_30_35.push(value);
          break;
        case  '35-45':
          dataSet.age_35_45.push(value);
          break;
        case  '45-100':
          dataSet.age_45_100.push(value);
          break;
      }
    });

    return dataSet;
  }

  private ConvertSexDistributedToStatisticsSex(group_id: number, type: string, dataSet: VkApiConvertSexDistributedData, average: number, total: number): GroupStatisticsSex {
    const stat: GroupStatisticsSex = {
      group_id: group_id,
      id: type
    };

    if (total > 0) {
      stat.age_12_18 = dataSet.GetSum(dataSet.age_12_18) / total * 100;
      stat.age_18_21 = dataSet.GetSum(dataSet.age_18_21) / total * 100;
      stat.age_21_24 = dataSet.GetSum(dataSet.age_21_24) / total * 100;
      stat.age_24_27 = dataSet.GetSum(dataSet.age_24_27) / total * 100;
      stat.age_27_30 = dataSet.GetSum(dataSet.age_27_30) / total * 100;
      stat.age_30_35 = dataSet.GetSum(dataSet.age_30_35) / total * 100;
      stat.age_35_45 = dataSet.GetSum(dataSet.age_35_45) / total * 100;
      stat.age_45_100 = dataSet.GetSum(dataSet.age_45_100) / total * 100;
      stat.total = average / total * 100;
    }

    return stat;
  }

  private ExtractGeoData(group_id: number, stat: Array<VkApiGroupStatistics>): GroupGeo {
    const response: GroupGeo = {
      id: group_id
    };

    const ru: Array<number> = [];
    const ua: Array<number> = [];
    const kz: Array<number> = [];
    const by: Array<number> = [];

    stat.forEach(data => {
      const geo = data.reach.countries;

      const total = geo.map(x => x.count).reduce((a, b) => a + b, 0);

      const dataRu = geo.find(x => x.value === 1);
      const dataUa = geo.find(x => x.value === 2);
      const dataBy = geo.find(x => x.value === 3);
      const dataKz = geo.find(x => x.value === 4);

      if (dataRu) {
        ru.push(dataRu.count / total * 100);
      }

      if (dataUa) {
        ua.push(dataUa.count / total * 100);
      }

      if (dataKz) {
        kz.push(dataKz.count / total * 100);
      }

      if (dataBy) {
        by.push(dataBy.count / total * 100);
      }
    });

    const medianRu = this.mathService.GetAverageValue(ru);
    const medianUa = this.mathService.GetAverageValue(ua);
    const medianBy = this.mathService.GetAverageValue(by);
    const medianKz = this.mathService.GetAverageValue(kz);


    response.ru = medianRu;
    response.ua = medianUa;
    response.by = medianBy;
    response.kz = medianKz;

    return response;
  }

  public ConvertGroup(group: VkApiGroup): Group {
    if (!group) {
      return null;
    }

    const converted: Group = {
      id: group.id,
      name: group.name,
      screen_name: group.screen_name,
      description: group.description,
      status: group.status,
      members_count: group.members_count,
      photo_50: group.photo_50,
      photo_100: group.photo_100,
      photo_200: group.photo_200,
      meta_data: null,
      type: null,
      counters: null,
      links: null,
      contacts: null,
      statistics: null,
      members_history: null,
      engagement: null,
      pricing: null,
      geo: null,
      sex: null,
      inactive: null
    };

    return converted;
  }

  public ConvertPost(group: Group, post: VkApiPost): Post {
    const convertedPost: Post = {
      group_id: group.id,
      id: post.id,
      group: group,
      date: new Date(post.date * 1000).toUTCString(),
      marked_as_ads: post.marked_as_ads === 1,
      text: post.text,
      likes: (post.likes) ? post.likes.count : 0,
      comments: (post.comments) ? post.comments.count : 0,
      reposts: (post.reposts) ? post.reposts.count : 0,
      views: (post.views) ? post.views.count : 0,
      copy_history: null,
      type: null
    };

    const isStealthAds = post.post_type === 'post_ads';

    if (isStealthAds) {
      convertedPost.post_type = 2;
    }

    // TODO convert repost
    if (post.copy_history && post.copy_history.length > 0) {
      convertedPost.copy_history = [];

      post.copy_history.forEach(repost => {
        const repostGroupId = Math.abs(repost.owner_id);

        let repost_group: Group = {
          id: repostGroupId
        };

        if (repostGroupId === group.id) {
          repost_group = group;
        }

        const convertedRepost = this.ConvertPost(repost_group, repost);

        if (convertedRepost) {
          const copyHistory: PostCopyHistory = {
            parent_group_id: group.id,
            parent_post_id: post.id,
            group_id: convertedRepost.group_id,
            id: convertedRepost.id,
            child_post: convertedRepost
          };

          if (copyHistory) {
            convertedPost.copy_history.push(copyHistory);
          }
        }
      });
    }

    // Convert attachments
    if (post.attachments && post.attachments.length > 0) {
      post.attachments.forEach(attachment => {
        switch (attachment.type) {
          case 'photo':
            const convertedPhoto = this.ConvertPostPhoto(group.id, post.id, attachment.photo);

            if (convertedPost.photos) {
              convertedPost.photos.push(convertedPhoto);
            } else {
              convertedPost.photos = [convertedPhoto];
            }
            break;
          case 'video':
            const convertedVideo = this.ConvertPostVideo(group.id, post.id, attachment.video);

            if (convertedPost.videos) {
              convertedPost.videos.push(convertedVideo);
            } else {
              convertedPost.videos = [convertedVideo];
            }
            break;
          case 'doc':
            const convertedDocument = this.ConvertPostDocument(group.id, post.id, attachment.doc);

            if (convertedPost.links) {
              convertedPost.animations.push(convertedDocument);
            } else {
              convertedPost.animations = [convertedDocument];
            }
            break;
          case 'link':
            const convertedLink = this.ConvertPostLink(group.id, post.id, attachment.link);

            if (convertedPost.links) {
              convertedPost.links.push(convertedLink);
            } else {
              convertedPost.links = [convertedLink];
            }

            if(isStealthAds && convertedLink.button) {
              convertedPost.post_type = 3;
            }

            break;
          case 'pretty_cards':
            const convertedPrettyCards = this.ConvertPostPrettyCards(group.id, post.id, attachment.pretty_cards);

            convertedPost.carousel = convertedPrettyCards;
            convertedPost.post_type = 1;

            break;
        }
      });
    }

    return convertedPost;
  }

  private ConvertPostPhoto(group_id: number, post_id: number, photo: PostAttachmentPhoto): PostPhoto {
    if (!photo) {
      return null;
    }

    const converted: PostPhoto = {};

    const hiResPhoto = photo.sizes.reduce(function (prev, current) {
      return (prev.width > current.width) ? prev : current;
    });

    if (hiResPhoto) {
      converted.width = hiResPhoto.width;
      converted.height = hiResPhoto.height;
      converted.url = hiResPhoto.url;
    }

    return converted;
  }

  private ConvertPostVideo(group_id: number, post_id: number, video: PostAttachmentVideo): PostVideo {
    const converted: PostVideo = {
      group_id: video.owner_id,
      post_id: post_id,
      video_id: video.id,
      title: video.title,
      duration: video.duration,
      comments: video.comments,
      views: video.views,
      photo_130: video.photo_130,
      photo_320: video.photo_320,
      photo_800: video.photo_800
    };

    return converted;
  }

  private ConvertPostLink(group_id: number, post_id: number, link: PostAttachmentLink): PostLink {
    const converted: PostLink = {
      url: link.url,
      title: link.title,
      description: link.description,
    };

    const photo = this.ConvertPostPhoto(group_id, post_id, link.photo);
    const button = this.ConvertPostLinkButton(group_id, post_id, link.button);

    if (photo) {
      converted.preview = {
        url: photo.url
      };
    }

    if (button) {
      converted.button = button;
    }

    return converted;
  }

  private ConvertPostLinkButton(group_id: number, post_id: number, button: PostButton): PostLinkButton {
    if (!button) {
      return null;
    }

    const converted: PostLinkButton = {
      title: button.title,
      url: button.action.url
    };

    return converted;
  }

  private ConvertPostDocument(group_id: number, post_id: number, document: PostAttachmentDocument): PostAnimation {
    if (!document) {
      return null;
    }

    const converted: PostAnimation = {
      group_id: group_id,
      post_id: post_id,
      animation_id: document.id,
      title: document.title,
      url: document.url,
      video_url: null,
      size: document.size,
      width: 0,
      height: 0,
      previews: []
    };

    if (document.preview) {
      const video = document.preview.video;
      const photo = document.preview.photo;

      if (video) {
        converted.video_url = video.src;
        converted.width = video.width;
        converted.height = video.height;
      }

      if (photo && photo.sizes) {
        photo.sizes.forEach(size => {
          converted.previews.push({
            width: size.width,
            height: size.height,
            src: size.src
          });
        });
      }
    }

    return converted;
  }

  private ConvertPostPrettyCards(group_id: number, post_id: number, prettyCards: VkApiPostPrettyCards): PostCarousel {
    if (!prettyCards) {
      return null;
    }

    const converted: PostCarousel = {
      group_id: group_id,
      post_id: post_id,
      cards: []
    };

    if (prettyCards.cards && prettyCards.cards.length > 0) {
      let index = 1;
      prettyCards.cards.forEach(card => {
        const convertedCard = this.ConvertPrettyCard(group_id, post_id, index, card);

        converted.cards.push(convertedCard);

        index = index + 1;
      });
    }

    return converted;
  }

  private ConvertPrettyCard(group_id: number, post_id: number, id: number, card: VkApiPostPrettyCardsCards): PostCarouselCard {
    if (!card) {
      return null;
    }

    const converted: PostCarouselCard = {
      group_id: group_id,
      post_id: post_id,
      card_id: id,
      title: card.title,
      link_url: card.link_url,
      price: card.price,
      price_old: card.price_old,
      images: [],
      button: null
    };

    if (card.images && card.images.length > 0) {
      let index = 1;
      card.images.forEach(image => {
        const convertedImage: PostCarouselCardImage = {
          group_id: group_id,
          post_id: post_id,
          card_id: id,
          id: index,
          url: image.url,
          width: image.width,
          height: image.height
        };

        converted.images.push(convertedImage);

        index = index + 1;
      });
    }

    if (card.button) {
      const convertedButton: PostCarouselCardButton = {
        group_id: group_id,
        post_id: post_id,
        id: 1,
        title: card.button.title,
        url: (card.button.action) ? card.button.action.url : card.link_url
      };

      converted.button = convertedButton;
    }

    return converted;
  }
}
