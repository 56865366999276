import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {OauthRoutingModule} from './oauth-routing.module';
import {OauthBindEmailComponent} from './routes/oauth-bind-email/oauth-bind-email.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { TokenReceiverComponent } from './routes/token-receiver/token-receiver.component';
import { SecuredOauthBindEmailComponent } from './routes/secured-oauth-bind-email/secured-oauth-bind-email.component';
import {ClarityModule} from '@clr/angular';

@NgModule({
    imports: [
        CommonModule,
        OauthRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        ClarityModule
    ],
    declarations: [OauthBindEmailComponent, TokenReceiverComponent, SecuredOauthBindEmailComponent]
})
export class OauthModule {
}
