<form [formGroup]="form" class="vk-form" (submit)="SubmitForm()">
  <vk-form-group orientation="horizontal">
    <div vkFormGroupLabel>Контактное лицо</div>
    <div class="vk-form-item">
      <vk-form-field orientation="vertical" controlsWidth="100%">
        <vk-label [required]="true">Фамилия</vk-label>
        <input type="text" formControlName="contactPersonLastName" vkInput name="lastName" placeholder="Например: Иванов">
        <vk-error>Поле обязательно для заполнения</vk-error>
      </vk-form-field>
    </div>

    <div class="vk-form-item">
      <vk-form-field orientation="vertical" controlsWidth="100%">
        <vk-label [required]="true">Имя</vk-label>
        <input type="text" formControlName="contactPersonFirstName" vkInput name="firstName" placeholder="Например: Иван">
        <vk-error>Поле обязательно для заполнения</vk-error>
      </vk-form-field>
    </div>

    <div class="vk-form-item">
      <vk-form-field orientation="vertical" controlsWidth="100%">
        <vk-label [required]="true">Отчество</vk-label>
        <input type="text" formControlName="contactPersonMiddleName" vkInput name="middleName" placeholder="Например: Иванович">
        <vk-error>Поле обязательно для заполнения</vk-error>
      </vk-form-field>
    </div>
  </vk-form-group>

  <vk-form-group orientation="horizontal">
    <div vkFormGroupLabel>Информация для связи</div>
    <div class="vk-form-item">
      <vk-form-field orientation="vertical" controlsWidth="100%">
        <vk-label [required]="true">Email</vk-label>
        <input type="text" formControlName="organisationEmail" vkInput name="email" placeholder="Например: ivanov@ip-ivanov.ru">
        <vk-error validator="required">Поле обязательно для заполнения</vk-error>
        <vk-error validator="email">Введенная информация не является адресом электронной почты</vk-error>
      </vk-form-field>
    </div>

    <div class="vk-form-item">
      <vk-form-field orientation="vertical" controlsWidth="100%">
        <vk-label [required]="true">Телефон</vk-label>
        <input
          type="text"
          formControlName="organisationPhone"
          vkInput
          name="phone"
          placeholder="Например: +7-(999)-123-45-67"
          prefix="+7"
          mask="-(000)-000-00-00"
        >
        <vk-error>Поле обязательно для заполнения</vk-error>
      </vk-form-field>
    </div>
  </vk-form-group>

  <vk-form-group orientation="horizontal">
    <div vkFormGroupLabel>Информация об организации</div>
    <div class="vk-form-item">
      <vk-form-field orientation="vertical" controlsWidth="100%">
        <vk-label [required]="true">Название организации</vk-label>
        <input type="text" formControlName="organisationName" vkInput name="organisationName" placeholder="Например: ИП Иванов">
        <vk-error>Поле обязательно для заполнения</vk-error>
      </vk-form-field>
    </div>

    <div class="vk-form-item">
      <vk-form-field orientation="vertical" controlsWidth="100%">
        <vk-label [required]="true">Полное название организации</vk-label>
        <input type="text" formControlName="organisationFullName" vkInput name="organisationFullName" placeholder="Например: Индивидуальный предприниматель Иванов">
        <vk-error>Поле обязательно для заполнения</vk-error>
      </vk-form-field>
    </div>
  </vk-form-group>

  <vk-form-group>
    <vk-form-field orientation="vertical" controlsWidth="100%">
      <vk-label>Описание организации</vk-label>
      <textarea
        vkInput
        formControlName="organisationSpecialisation"
        name="organisationSpecialisation"
        placeholder="Опишите чем занимается ваше предприятие"
      ></textarea>
    </vk-form-field>
  </vk-form-group>

  <vk-form-group orientation="horizontal">
    <div vkFormGroupLabel>Юридическая информация</div>
    <div class="vk-form-item">
      <vk-form-field orientation="vertical" controlsWidth="100%">
        <vk-label [required]="true">ИНН</vk-label>
        <input
          type="text"
          formControlName="organisationInn"
          vkInput
          name="organisationInn"
          placeholder="Например: 7777777777"
          mask="999999999999"
        >
        <vk-error>Поле обязательно для заполнения</vk-error>
      </vk-form-field>
    </div>

    <div class="vk-form-item">
      <vk-form-field orientation="vertical" controlsWidth="100%">
        <vk-label [required]="true">ОГРН</vk-label>
        <input
          type="text"
          formControlName="organisationOgrn"
          vkInput
          name="organisationInn"
          placeholder="Например: 777777777777"
          mask="9999999999999"
        >
        <vk-error>Поле обязательно для заполнения</vk-error>
      </vk-form-field>
    </div>

    <div class="vk-form-item">
      <vk-form-field orientation="vertical" controlsWidth="100%">
        <vk-label [required]="true">КПП</vk-label>
        <input
          type="text"
          formControlName="organisationKpp"
          vkInput
          name="organisationKpp"
          placeholder="Например: 77777777777777"
          mask="999999999"
        >
        <vk-error>Поле обязательно для заполнения</vk-error>
      </vk-form-field>
    </div>

    <div class="vk-form-item">
      <vk-form-field orientation="vertical" controlsWidth="100%">
        <vk-label [required]="true">Вы оплачиваете НДС?</vk-label>
        <mat-select vkInput formControlName="ndsPayerTypeId" name="ndsPayerTypeId" placeholder="Например: Оплачиваю">
          <mat-option>Оплачиваю</mat-option>
          <mat-option>Не оплачиваю</mat-option>
        </mat-select>
        <vk-error>Поле обязательно для заполнения</vk-error>
      </vk-form-field>
    </div>
  </vk-form-group>

  <vk-form-group orientation="horizontal">
    <div vkFormGroupLabel>Адреса организации</div>

    <div class="vk-form-item">
      <vk-form-field orientation="vertical" controlsWidth="100%">
        <vk-label>Официальный адрес организации</vk-label>
        <textarea type="text" vkInput formControlName="organisationLegalAddress" placeholder="Например: г. Москва, Тверская улица, дом 13"></textarea>
      </vk-form-field>
    </div>

    <div class="vk-form-item">
      <vk-form-field orientation="vertical" controlsWidth="100%">
        <vk-label>Официальный адрес организации</vk-label>
        <textarea type="text" vkInput formControlName="organisationActualAddress" placeholder="Например: г. Москва, Тверская улица, дом 13"></textarea>
      </vk-form-field>
    </div>
  </vk-form-group>

  <div>
    <button class="vk-standart-button">Сохранить информацию</button>
  </div>
</form>
