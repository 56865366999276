import { OnInit } from '@angular/core';
import { AdManagerService } from '../../../../../api/services/ad-manager.service';
import { AdFormManagerService } from '../../../new-ad-manager-shared/services/ad-form-manager/ad-form-manager.service';
var NewUpdateLiteComponent = /** @class */ (function () {
    function NewUpdateLiteComponent(adFormManagerService, adManagerService) {
        this.adFormManagerService = adFormManagerService;
        this.adManagerService = adManagerService;
    }
    Object.defineProperty(NewUpdateLiteComponent.prototype, "IsLoading", {
        get: function () {
            return this.adFormManagerService.adDataLoading;
        },
        enumerable: true,
        configurable: true
    });
    NewUpdateLiteComponent.prototype.ngOnInit = function () {
        this.adFormManagerService.reset();
        this.adFormManagerService.IsUpdate = true;
        this.adFormManagerService.LoadData()
            .subscribe(function (data) {
            console.log('LOADING DATA FUCK!!!!');
            console.log(data);
        });
    };
    NewUpdateLiteComponent.prototype.OnSave = function (specifications) {
        this.adFormManagerService
            .UpdateAd()
            .subscribe(function (data) {
            console.log(data);
        });
    };
    return NewUpdateLiteComponent;
}());
export { NewUpdateLiteComponent };
