import {Injectable} from '@angular/core';
import {ChatMessageModel} from '../models/chat-message-model';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {ChatConversationModel} from "../models/chat-conversation-model";
import {ChatConversationCacheChangesModel} from "../models/chat-conversation-cache-changes-model";
import {ChatMark} from "../models/chat-mark";
import {Subject} from "rxjs/Rx";

@Injectable({
  providedIn: 'root'
})
export class ChatConversationMessagesService {
  public loadingMessages: boolean = false;
  public loadingConversations: boolean = false;

  public unreadMessageAmount: number = 0;

  public messages: Array<ChatMessageModel> = [];

  public conversationsCount: number = null;
  public conversations: Array<ChatConversationModel> = [];

  public isBanned: boolean = false;
  public banReason: boolean = false;

  public marks: Array<ChatMark> = [];

  private apiUrl = environment.backendUri;
  private apiRoute = `${environment.apiPrefix}/chat`;

  private soundNewMessage = new Audio();

  constructor(private http: HttpClient) {
    this.soundNewMessage.src = 'https://postmonitor.ru/static/sounds/message-notification.wav';
    this.soundNewMessage.load();
    this.soundNewMessage.volume = 0.5;
  }

  public PlayIncomeMessageSound(): void {
    if ( !this.soundNewMessage) {
      this.soundNewMessage.load();
    }

    this.soundNewMessage.play();
  }

  public GetMarks(): void {
    this.http.get(`${this.apiUrl}/${this.apiRoute}/mark`).subscribe((response: any) => {
      if (response) {
        this.marks = response.data;
      }
    }, (error: any) => {
      if (!this.CheckForBan(error)) {

      }
    });
  }

  public AssignNewMarkToConversation(userId: number, markId: number): void {
    this.http.post(`${this.apiUrl}/${this.apiRoute}/mark?userId=${userId}&markId=${markId}`, null).subscribe((response: any) => {
      if (response) {
        const relatedConversation = this.conversations.find(x => x.userId === userId);

        if (relatedConversation) {
          relatedConversation.markId = markId;
        }
      }
    }, (error: any) => {
      if (!this.CheckForBan(error)) {

      }
    });
  }

  public GetConversationChanges(updatesFrom: number, userId?: number, onlyReadUpdates: boolean = false, allowSound: boolean = false, unAnsweredFirst: boolean = true): void {
    // conversations-updates
    this.http.get(`${this.apiUrl}/${this.apiRoute}/conversations-updates?updatesFrom=${updatesFrom}&userId=${userId}&onlyReadUpdates=${onlyReadUpdates}`).subscribe((response: any) => {
      if (response) {
        this.conversationsCount = response.records_found;

        if (response.data && response.data.length > 0) {
          const data: Array<ChatConversationCacheChangesModel> = response.data;

          if (data && data.length > 0) {
            let playSound = false;

            data.forEach(update => {
              const relatedConversation = this.conversations.find(x => x.userId === update.userId);

              if (update.createdConversation) {
                if (update.createdConversation.lastMessage && !update.createdConversation.lastMessage.isRead && update.createdConversation.lastMessage.createdByUser) {
                  playSound = true;
                }

                if (!relatedConversation) {
                  this.conversations.unshift(update.createdConversation);
                } else {
                  relatedConversation.lastMessage = update.createdConversation.lastMessage;
                }
              } else if (update.changedLastMessage) {
                if (relatedConversation) {
                  relatedConversation.lastMessage = update.changedLastMessage;

                  if (relatedConversation.lastMessage && !relatedConversation.lastMessage.isRead && relatedConversation.lastMessage.createdByUser) {
                    playSound = true;
                  }
                }
              } else if (update!.messageReadIdList.length > 0) {
                update.messageReadIdList.forEach(item => {
                  const relatedMessage = this.messages.find(x => x.userId === update.userId && x.id === item);

                  if (relatedMessage) {
                    if (!relatedMessage.isRead && relatedMessage.createdByUser) {
                      playSound = true;
                    }

                    relatedMessage.isRead = true;
                    relatedMessage.readAt = update.messageReadDate;
                  }
                });
              }
            });

            if (playSound && allowSound) {
              this.PlayIncomeMessageSound();
            }

            if (unAnsweredFirst) {
              this.conversations.sort(function(a, b){
                const firstParameterA = a.lastMessage.createdByUser ? 1 : 0;
                const firstParameterB = b.lastMessage.createdByUser ? 1 : 0;

                const secondParameterA = new Date(a.lastMessage.createdAt).getTime();
                const secondParameterB = new Date(b.lastMessage.createdAt).getTime();

                return firstParameterA<firstParameterB ? 1 : firstParameterA>firstParameterB ? -1 :
                  secondParameterA<secondParameterB ? 1 : secondParameterA>secondParameterB ? -1 : 0;
              });
            } else {
              this.conversations.sort(function(a, b){
                const dateA = new Date(a.lastMessage.createdAt).getTime();
                const dateB = new Date(b.lastMessage.createdAt).getTime();
                return dateA < dateB ? 1 : -1;
              });
            }
          }
        }
      }
    }, (error: any) => {
      if (!this.CheckForBan(error)) {

      }
    });
  }

  public GetConversations(userId?: number, filterMode?: string, limit?: number, offset?: number, markFilterId: number = 0): void {
    this.loadingConversations = true;
    this.conversations = null;

    this.http.get(`${this.apiUrl}/${this.apiRoute}/conversations?userId=${userId}&filterMode=${filterMode}&limit=${limit}&offset=${offset}&markFilterId=${markFilterId}`).subscribe((response: any) => {
      console.log('GetConversatinos');
      this.loadingConversations = false;
      this.conversations = [];
      if (response && response.data) {
        this.conversations = response.data;
      }
    }, (error: any) => {
      this.conversations = [];
      if (!this.CheckForBan(error)) {

      }
    });
  }

  public GetAmountOfUnReadMessages(): void {
    this.http.get(`${this.apiUrl}/${this.apiRoute}/unread`).subscribe((response: any) => {
      if (response) {
        this.unreadMessageAmount = response.data;
      }
    }, (error: any) => {
      if (!this.CheckForBan(error)) {

      }
    });
  }

  public LoadNewMessages(userId?: number): void {
    let latestMessageId = 0;

    if (this.messages && this.messages.length > 0) {
      latestMessageId = this.messages[0].id;
    }

    const userRequest: boolean = (userId === undefined || userId === null);
    const userParameter = (userRequest) ? '' : `&userId=${userId}`;

    this.http.get(`${this.apiUrl}/${this.apiRoute}/latest-messages?lastMessageId=${latestMessageId}${userParameter}`).subscribe((response: any) => {
      if (response && response.data && response.data.length > 0) {
        response.data.forEach(message => {
          if (!this.messages.find(x => x.id === message.id)) {
            this.messages.unshift(message);
          }
        });

        // Mark all other as read (in frontend)
        this.messages.forEach(message => {
          message.isRead = true;
        });

        this.unreadMessageAmount = 0;
      }
    }, (error: any) => {
      if (!this.CheckForBan(error)) {

      }
    });
  }

  public SendMessage(text: string, userId?: number, sendNotificationOverEmail: boolean = false): void {
    const body = text;

    this.http.post(`${this.apiUrl}/${this.apiRoute}/messages?userId=${userId}&sendNotificationOverEmail=${sendNotificationOverEmail}`, body).subscribe((response: any) => {
      if (response && response.data) {
        const message = response.data;

        const alreadyAddedMessage = this.messages.find(x => x.id === message.id);

        if (!alreadyAddedMessage) {
          this.messages.unshift(message);
        }
      }
    }, (error: any) => {
      if (!this.CheckForBan(error)) {

      }
    });
  }

  public GetMessages(userId?: number): void {
    this.loadingMessages = true;
    this.messages = [];

    const userRequest: boolean = (userId === undefined || userId === null);
    const userParameter = (userRequest) ? '' : `?userId=${userId}`;

    this.http.get(`${this.apiUrl}/${this.apiRoute}/messages${userParameter}`).subscribe((response: any) => {
      console.log('GetMessages');
      this.loadingMessages = false;

      if (response && response.data) {
        this.messages = response.data;
      }

      this.unreadMessageAmount = 0;
    }, (error: any) => {
      if (!this.CheckForBan(error)) {

      }
    });
  }

  public BanConversation(userId: number, reason: string): void {

    this.http.delete(`${this.apiUrl}/${this.apiRoute}/conversations?userId=${userId}&reason=${reason}`).subscribe((response: any) => {
      if (response && response.data) {

      }
    }, (error: any) => {
      if (!this.CheckForBan(error)) {

      }
    });
  }

  private CheckForBan(response: any): boolean {
    if (response && response.status === 403 && response.error && response.error.reason) {
      this.isBanned = true;
      this.banReason = response.error.reason;

      return true;
    }

    return false;
  }
}
