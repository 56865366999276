import {Injectable} from '@angular/core';
import {ErrorDescription} from '../../api/models/error-description';
import {UserMeta} from '../../api/models/user-meta';

@Injectable()
export class AutomationExpirationManagerService {

  public expirationErrorOccured: boolean = false;
  public demoModeActive: boolean = false;

  constructor() {
  }

  public SetOccured(error: ErrorDescription) {
    this.expirationErrorOccured = false;
    if (error) {
      if (error.errorCode === 'AUTOMATION_VK_CABINET_ACCESS_EXPIRED') {
        this.expirationErrorOccured = true;
      }
    }
  }

  public SetDemoMode(user_meta: UserMeta): void {
    this.demoModeActive = (user_meta && user_meta.isDemoEnabled);
  }
}
