<div class="affiliate-report">
  <div class="affiliate-report__to-pay">
    <app-affiliate-tile title="Доступно к выплате" [value]="10">
      <app-to-pay-tile-content></app-to-pay-tile-content>
    </app-affiliate-tile>
  </div>
  <div class="affiliate-report__registrated">
    <app-affiliate-tile title="Зарегистрировано сегодня" [value]="10">
      <app-registrated-tile-content></app-registrated-tile-content>
    </app-affiliate-tile>
  </div>

  <div class="affiliate-report__payed">
    <app-affiliate-tile title="Переходов сегодня" [value]="10">
      <app-payed-tile-content></app-payed-tile-content>
    </app-affiliate-tile>
  </div>

  <div class="affiliate-report__full-balance">
    <app-affiliate-tile title="Всего заработано" [value]="10">
      <app-full-balance-tile-content></app-full-balance-tile-content>
    </app-affiliate-tile>
  </div>

  <div class="affiliate-report__description">
    <app-affiliate-tile>
      Получайте комиссию 25% с каждого клиента, которого вы привели в PostMonitor. Процент начисляется со всех платежей, сделанных клиентом в течение года после регистрации.
      <br><br>
      Выплаты осуществляются от 5000 руб. и по запросу каждый последний четверг месяца.
      <br><br>
      Выплаты денежных средств доступны только на расчетный счет ИП или юр.лица.
    </app-affiliate-tile>
  </div>

  <div class="affiliate-report__affiliate-link">
    <app-affiliate-tile title="Ваша партнерская ссылка">
      <ng-container *ngIf="affiliateLinks$ | async as affiliateLink">
        <span class="affiliate-member-link__url-area">{{affiliateBaseUrl}}/{{affiliateLink.code}}</span>
      </ng-container>
    </app-affiliate-tile>
  </div>
<!--  <div class="affiliate-report__description">-->
<!--    <app-affiliate-program-description></app-affiliate-program-description>-->
<!--  </div>-->
<!--  <div class="affiliate-report__table">-->
<!--    <app-affiliate-program-financial-report-table></app-affiliate-program-financial-report-table>-->
<!--  </div>-->
<!--  <div class="affiliate-report__membars-link">-->
<!--    <app-affiliate-program-member-link></app-affiliate-program-member-link>-->
<!--  </div>-->
</div>
