<form [formGroup]="form">
  <div class="services-selector">
    <div class="services-selector__select-control">
      <vk-form-field orientation="vertical" controlsWidth="100%">
        <vk-label>{{label}}</vk-label>
        <mat-select vkInput [formControlName]="controlName">
          <mat-option [value]="service.code" *ngFor="let service of services$">
            {{service.name}}
          </mat-option>
        </mat-select>
      </vk-form-field>
    </div>
    <div class="services-selector__service-settings">
      <ng-container [ngSwitch]="service">
        <ng-container *ngSwitchCase="'AMO_CRM_IMPORT'">
          <app-amo-crm-contacts-import></app-amo-crm-contacts-import>
        </ng-container>
        <ng-container *ngSwitchCase="'AMO_CRM_EXPORT'">
          <app-amo-crm-contacts-export></app-amo-crm-contacts-export>
        </ng-container>
        <ng-container *ngSwitchCase="'VK_LEAD_FORMS'">
          <app-vk-lead-forms></app-vk-lead-forms>
        </ng-container>
        <ng-container *ngSwitchCase="'MT_LEAD_FORMS'">
          <app-mt-lead-forms></app-mt-lead-forms>
        </ng-container>
        <ng-container *ngSwitchCase="'VK_RETARGETING_GROUPS'">
          <app-vk-retargeting-groups></app-vk-retargeting-groups>
        </ng-container>
        <ng-container *ngSwitchCase="'MT_REMARKETING_GROUPS'">
          <app-mt-remarketing-groups></app-mt-remarketing-groups>
        </ng-container>
      </ng-container>
    </div>
  </div>
</form>
