import {Injectable} from '@angular/core';
import {Query} from '@datorama/akita';
import {UserBalanceStore, UserBalanceState} from './user-balance.store';

@Injectable({providedIn: 'root'})
export class UserBalanceQuery extends Query<UserBalanceState> {

  public get registrated$() {
    return this.select(state => state.registrated);
  }

  public get visited$() {
    return this.select(state => state.visited);
  }

  public get currentUserBalance$() {
    return this.select(state => state.amount);
  }

  public get fullBalance$() {
    return this.select(state => state.fullBalance)
  }

  constructor(protected store: UserBalanceStore) {
    super(store);
  }

}
