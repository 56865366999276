import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AmoService} from '../../../../api/services/amo.service';
import {MegaplanService} from '../../../../api/services/megaplan.service';
import {MatDialogRef} from '@angular/material/dialog';
import {UonTravelService} from '../../../services/uon-travel/uon-travel.service';

@Component({
  selector: 'app-uon-travel-assignation-screen-dialog',
  templateUrl: './uon-travel-assignation-screen-dialog.component.html',
  styleUrls: ['./uon-travel-assignation-screen-dialog.component.scss']
})
export class UonTravelAssignationScreenDialogComponent implements OnInit {

  public loading: boolean = false;

  public form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private amo: AmoService,
    private dialogRef: MatDialogRef<UonTravelAssignationScreenDialogComponent>,
    private uonService: UonTravelService
  ) {
  }

  ngOnInit() {
    this.form = this.fb.group({
      domain: this.fb.control('', [Validators.required]),
      apiKey: this.fb.control('', [Validators.required])
    });
  }

  Assign() {
    console.log(this.form.value, this.form.valid);
    if (this.form.valid) {
      this.loading = true;
      const value = this.form.value;
      value.domain = value
        .domain
        .replace('https://', '')
        .replace('.u-on.ru', '')
        .replace(/\//g, '');
      console.log(value);
      this.uonService.Authorize(value)
        .subscribe(data => {
          this.loading = false;
          console.log('WTF');
          // localStorage.setItem('bind-return-to', '/new-integration');
          this.dialogRef.close();
        }, err => {
          console.log(err);
          this.loading = false;
        });
    }
  }
}
