import {Injectable} from '@angular/core';
import {QueryEntity} from '@datorama/akita';
import {
  AffiliateMemberStattisticsByUserStore,
  AffiliateMemberStattisticsByUserState
} from './affiliate-member-stattistics-by-user.store';

@Injectable({providedIn: 'root'})
export class AffiliateMemberStattisticsByUserQuery extends QueryEntity<AffiliateMemberStattisticsByUserState> {

  /**
   * Дата начала периода за который необходимо получить статистику (Observable)
   */
  public get dateFrom$() {
    return this.select(state => state.ui.dateFrom);
  }

  /**
   * Дата окончания периода а который необходимо получить статистику (Observable)
   */
  public get dateTo$() {
    return this.select(state => state.ui.dateTo);
  }

  /**
   * Дата начала периода за который необходимо получить статистику
   */
  public get dateFrom() {
    return this.getValue().ui.dateFrom;
  }

  /**
   * Дата окончания периода а который необходимо получить статистику
   */
  public get dateTo() {
    return this.getValue().ui.dateTo;
  }

  /**
   * Все данные о статистике по пользователюы
   */
  public get statistics$() {
    return this.selectAll();
  }

  constructor(protected store: AffiliateMemberStattisticsByUserStore) {
    super(store);
  }

}
