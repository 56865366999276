/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./testing-route.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "../../copying-module/components/copying-ads-dialog/copying-ads-dialog.component.ngfactory";
import * as i8 from "../../copying-module/services/copying.service";
import * as i9 from "../../../api/services/ad-manager.service";
import * as i10 from "../../../api/services/automation-vk.service";
import * as i11 from "../../../api/services/automation-vk-slot.service";
import * as i12 from "@angular/material/dialog";
import * as i13 from "../../copying-module/components/copying-ads-dialog/copying-ads-dialog.component";
import * as i14 from "./testing-route.component";
var styles_TestingRouteComponent = [i0.styles];
var RenderType_TestingRouteComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TestingRouteComponent, data: {} });
export { RenderType_TestingRouteComponent as RenderType_TestingRouteComponent };
export function View_TestingRouteComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["mat-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], null, null, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["\u041E\u0442\u043A\u0440\u044B\u0442\u044C \u0434\u0438\u0430\u043B\u043E\u0433\u043E \u043A\u043E\u043F\u0438\u0440\u043E\u0432\u0430\u043D\u0438\u044F"])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "app-copying-ads-dialog", [], null, null, null, i7.View_CopyingAdsDialogComponent_0, i7.RenderType_CopyingAdsDialogComponent)), i1.ɵprd(512, null, i8.CopyingService, i8.CopyingService, [i9.AdManagerService, i10.AutomationVkService, i11.AutomationVkSlotService, i12.MatDialog]), i1.ɵdid(5, 245760, null, 0, i13.CopyingAdsDialogComponent, [i8.CopyingService, i12.MAT_DIALOG_DATA, i12.MatDialogRef, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 5, 0); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_TestingRouteComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-testing-route", [], null, null, null, View_TestingRouteComponent_0, RenderType_TestingRouteComponent)), i1.ɵdid(1, 114688, null, 0, i14.TestingRouteComponent, [i12.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TestingRouteComponentNgFactory = i1.ɵccf("app-testing-route", i14.TestingRouteComponent, View_TestingRouteComponent_Host_0, {}, {}, []);
export { TestingRouteComponentNgFactory as TestingRouteComponentNgFactory };
