import {Component, OnInit} from '@angular/core';
import {environment} from '../../../../../environments/environment';
import {ActivatedRoute} from '@angular/router';
import {OAuthService} from '../../../../api/services/oauth.service';
import {MatDialog} from '@angular/material';
import {PlatformAssignationsService} from '../../../stores/platform-assignations/platform-assignations.service';
import {AssignationPlatforms} from '../../../stores/platform-assignations/platform-assignation.model';
import {MegaplanAssignationDialogComponent} from "../../assignation-dialogs/megaplan-assignation-dialog/megaplan-assignation-dialog.component";
import {PlatformAssignationsQuery} from "../../../stores/platform-assignations/platform-assignations.query";
import {ClaimsService} from "../../../../api/services";

@Component({
  selector: 'app-megaplan-assignation-screen',
  templateUrl: './megaplan-assignation-screen.component.html',
  styleUrls: ['./megaplan-assignation-screen.component.scss']
})
export class MegaplanAssignationScreenComponent implements OnInit {
  public baseUrl = environment.frontEndUrl;
  public provider = 'Token-mega-plan';
  public assigned = true;
  public loading: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private oauth: OAuthService,
    private dialog: MatDialog,
    private assignationService: PlatformAssignationsService,
    private assignationQuery: PlatformAssignationsQuery,
    private claimsService: ClaimsService
  ) {
  }

  ngOnInit() {
    this.CheckAssignation();
    this.assignationQuery.assingnationStatus$(AssignationPlatforms.MEGAPLAN)
      .subscribe(data => {
        this.assigned = data;
      })
  }

  public AddVkAdCabinetLinkClick(): void {
    this.dialog.open(MegaplanAssignationDialogComponent)
      .afterClosed()
      .subscribe(data => {
        this.CheckAssignation();
      });
  }

  public GetBindUrl(): string {
    return `${this.baseUrl}/${environment.apiPrefix}/OAuth/authorize/${this.provider}`;
  }

  public CheckAssignation() {
    this.loading = true;
    // setTimeout(() => {
    //   this.assignationService.assignPlatform(AssignationPlatforms.MEGAPLAN);
    //   this.loading = false;
    // }, 1000)
    this.claimsService.ExistsClaim(this.provider)
      .subscribe(data => {
          this.assigned = data;
          if (data) {
            this.assignationService.assignPlatform(AssignationPlatforms.MEGAPLAN);
          }
          this.loading = false;
        },
        err => {
          this.loading = false;
        }
      );
  }
}
