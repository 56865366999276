/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./rate-manager-explain.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "./rate-manager-explain.component";
var styles_RateManagerExplainComponent = [i0.styles];
var RenderType_RateManagerExplainComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RateManagerExplainComponent, data: {} });
export { RenderType_RateManagerExplainComponent as RenderType_RateManagerExplainComponent };
export function View_RateManagerExplainComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "vk-strip-wrapper vk-strip-wrapper_"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "vk-strip-wrapper__item vk-strip-wrapper__item_wide"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "vk-ad-cabinet-header"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "vk-strip-wrapper__item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "button", [["class", "modal-close-button"], ["mat-dialog-close", ""], ["type", "button"]], [[1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).dialogRef.close(i1.ɵnov(_v, 6).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 0, "i", [["class", "icon ion-md-close"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "p", [], [[8, "innerHTML", 1]], null, null, null, null))], function (_ck, _v) { var currVal_2 = ""; _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.header; _ck(_v, 3, 0, currVal_0); var currVal_1 = (i1.ɵnov(_v, 6).ariaLabel || null); _ck(_v, 5, 0, currVal_1); var currVal_3 = _co.data.text; _ck(_v, 8, 0, currVal_3); }); }
export function View_RateManagerExplainComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-rate-manager-explain", [], null, null, null, View_RateManagerExplainComponent_0, RenderType_RateManagerExplainComponent)), i1.ɵdid(1, 114688, null, 0, i3.RateManagerExplainComponent, [i2.MatDialogRef, i2.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RateManagerExplainComponentNgFactory = i1.ɵccf("app-rate-manager-explain", i3.RateManagerExplainComponent, View_RateManagerExplainComponent_Host_0, {}, {}, []);
export { RateManagerExplainComponentNgFactory as RateManagerExplainComponentNgFactory };
