import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {AffiliateUserInfoQuery} from "../../../../../storage/affiliate-user-info/affiliate-user-info.query";
import {filter} from "rxjs/operators";
import {AffiliateUserInfoService} from "../../../../../storage/affiliate-user-info/affiliate-user-info.service";
import {ToastrService} from "ngx-toastr";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-affiliate-program-settings-user-form',
  templateUrl: './affiliate-program-settings-user-form.component.html',
  styleUrls: ['./affiliate-program-settings-user-form.component.scss']
})
export class AffiliateProgramSettingsUserFormComponent implements OnInit {
  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private affiliateUserInfoQuery: AffiliateUserInfoQuery,
    private affiliateUserInfoService: AffiliateUserInfoService,
    private snakbar: MatSnackBar
  ) {
  }

  ngOnInit() {
    this.form = this.fb.group({
      affiliateUserId: [null],
      organisationName: [null, [Validators.required]],
      organisationFullName: [null, [Validators.required]],
      organisationSpecialisation: [null, [Validators.required]],
      organisationDescription: [null, [Validators.required]],
      organisationWebSite: [null, [Validators.required]],
      organisationInn: [null, [Validators.required]],
      organisationOgrn: [null, [Validators.required]],
      organisationKpp: [null, [Validators.required]],
      ndsPayerTypeId: [null, [Validators.required]],
      organisationLegalAddress: [null, [Validators.required]],
      organisationActualAddress: [null, [Validators.required]],
      organisationPhone: [null, [Validators.required]],
      organisationEmail: [null, [Validators.required, Validators.email]],
      contactPersonFirstName: [null, [Validators.required]],
      contactPersonLastName: [null, [Validators.required]],
      contactPersonMiddleName: [null, [Validators.required]],
      createdAt: [null],
      updatedAt: [null],
      deletedAt: [null]
    });

    this.affiliateUserInfoQuery.affiliateUserInfo$
      .pipe(
        filter(x => x.affiliateUserId !== null)
      )
      .subscribe(affiliateUserInfo => {
        console.log(affiliateUserInfo, 'AFFILIATE USER INFO');
        this.form.setValue(affiliateUserInfo);
        this.form.markAsPristine();
      })
  }

  public SubmitForm() {
    if (this.form.valid) {
      if (!this.form.value.affiliateUserId) {
        this.CreateNewUserInfo();
      } else {
        this.UpdateUserInfo();
      }
    }
  }

  public CreateNewUserInfo() {
    this.affiliateUserInfoService
      .CreateAffiliateUserAsync(this.form.value)
      .subscribe(data => {
        this.snakbar.open('Информация о пользователе сохранена');
      }, err => {
        this.snakbar.open('При сохранении информации о пользователе возникла ошибка');
      });
  }

  public UpdateUserInfo() {
    const affiliateUserInfo = this.GetJustDirtyControlsValues();
    this.affiliateUserInfoService
      .UpdateAffiliateUserAsync(affiliateUserInfo)
      .subscribe(data => {
        this.snakbar.open('Информация о пользователе обновлена');
      }, err => {
        this.snakbar.open('При обновлении информации о пользователе возникла ошибка');
      });
  }

  private GetJustDirtyControlsValues() {
    return Object.keys(this.form.controls)
      .map(formControlName => ({formControlName, formControl: this.form.controls[formControlName] as FormControl}))
      .filter(({formControl}) => formControl.dirty)
      .reduce((acc, {formControlName, formControl}) => {
        acc[formControlName] = formControl.value;
        return acc;
      }, {});
  }

}
