import {Injectable} from '@angular/core';
import {QueryEntity} from '@datorama/akita';
import {TariffPlansState, TariffPlansStore} from './tariff-plans.store';
import {TimePeriodsQuery} from '../time-periods/time-periods.query';
import {combineLatest, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {TimePeriod} from '../time-periods/time-period.model';
import {TariffPlan} from './tariff-plan.model';
import {PromocodeQuery} from '../promocode/promocode.query';
import {Promocode} from '../../../api/models/promocode';
import {time} from "ionicons/icons";

@Injectable({providedIn: 'root'})
export class TariffPlansQuery extends QueryEntity<TariffPlansState> {

  constructor(
    protected store: TariffPlansStore,
    private timePeriods: TimePeriodsQuery,
    private promocodesQuery: PromocodeQuery
  ) {
    super(store);
  }

  public get selectedTariffPlans() {
    return this.getValue().ui.selectedTariffPlans;
  }

  public get selectedTariffPlans$() {
    return this.select(state => state.ui.selectedTariffPlans);
  }

  public get tariffGroups() {
    return this.getAll();
  }

  public get tariffPlans() {
    return this.tariffGroups
      .map(x => x.packages)
      .reduce((all, current) => all.concat(current));
  }

  public tariifPlans$ = this.selectAll();

  public selectedTariffPlan$ = this.selectActive() as Observable<TariffPlan>;

  public selectedTariffPlanId$ = this.selectedTariffPlan$
    .pipe(
      map(x => x.id)
    );

  public tariffPlanPrice = (tariffPlanId, tariffId) =>
    combineLatest(
      this.timePeriods.activePeriod$(tariffPlanId),
      this.selectEntity(tariffPlanId)
        .pipe(map(x => x.packages.find(x => x.id === tariffId))),
      this.promocodesQuery.promocode$
    )
      .pipe(
        map(([timePeriod, tariff, promocode]) => CalculateTarifPrice(timePeriod, tariff, promocode))
      );

  public tariffCustomPrice$ = (tariffGroupId, tariffId) =>
    combineLatest(
      this.timePeriods.activePeriod$,
      this.selectEntity(tariffGroupId).pipe(map(tariffGroup => tariffGroup.packages.find(tariffPackage => tariffPackage.id === tariffId))),
    )
      .pipe(
        map(([timePeriod, tariff]) => GetCustomPrice(timePeriod, tariff))
      );

  public getTariffPlanPrice(tariffPlanId, timePeriodId) {
    const tariffPlan = this.tariffPlans.find(x => x.id === tariffPlanId);
    const timePeriod = this.timePeriods.getEntity(timePeriodId)
    const promocode = this.promocodesQuery.promocode;
    console.log(promocode, 'PROMOCODE');
    // let price = null;
    // let result = null;
    // if (tariffPlan) {
    //   price = tariffPlan.prices.find(x => x.periodId === timePeriodId)
    // }
    // console.log(price, 'CURRENT PRICE DATA', timePeriodId);
    // if (price) {
    //   result = price.customPrice ? price.customPrice : price.price
    // }

    return CalculateTarifPrice(timePeriod, tariffPlan, promocode);
  }

  getTariffGroupName(selectedTariffPlan: any) {
    const tariffPlan = this.tariffPlans.find(x => x.id === selectedTariffPlan);
    console.log(tariffPlan, selectedTariffPlan, 'getTariffGroupName');
    return this
      .getAll()
      .find(tariffGroup => tariffGroup.id === tariffPlan['groupId'])
      .paymentGroupName + ` - ${tariffPlan.name}`;
  }
}

/**
 * Расчет стоимости тарифа
 * @param timePeriod - период (1, 3, 6 или 12 месяцев)
 * @param tariff - тариф
 * @param promocode - данные промокода
 * @constructor
 */
export function CalculateTarifPrice(timePeriod, tariff, promocode: Promocode) {
  console.log(timePeriod, tariff, promocode, 'PRICE GOOD');
  let price = timePeriod ? tariff.prices.find(x => x.periodId === (timePeriod as TimePeriod).id).price : 0;
  if (promocode) {
    console.log(price, promocode.value, 'PRICE');
    price = price - (price * (promocode.value / 100));
    console.log(price, 'PRICE');
  }
  return price as number;
}

function GetCustomPrice(timePeriod, tariffPlan) {
  const currentPeriod = tariffPlan.prices.find(x => x.periodId === (timePeriod as TimePeriod).id);
  console.log(currentPeriod, 'CURRENT PERIOD');
  let price = timePeriod ? currentPeriod.customPrice : null;
  console.log(tariffPlan, timePeriod, price);
  return price;
}
