import { OnInit } from '@angular/core';
import { AdFormManagerService } from '../../../new-ad-manager-shared/services/ad-form-manager/ad-form-manager.service';
var NewTeazerFormLiteComponent = /** @class */ (function () {
    function NewTeazerFormLiteComponent(adFormManagerService) {
        this.adFormManagerService = adFormManagerService;
        this.controlsWidth = '256px';
        this.labelsWidth = '140px';
        this.IsAdToGroup = true;
    }
    Object.defineProperty(NewTeazerFormLiteComponent.prototype, "HasOneGroup", {
        get: function () {
            return this.adminGroups.length === 1;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewTeazerFormLiteComponent.prototype, "FirstGroup", {
        get: function () {
            return this.adminGroups[0];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewTeazerFormLiteComponent.prototype, "adminGroups", {
        get: function () {
            return this.adFormManagerService.adminGroups;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewTeazerFormLiteComponent.prototype, "form", {
        get: function () {
            return this.adFormManagerService.getControl('teazer');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewTeazerFormLiteComponent.prototype, "TeazerLength", {
        get: function () {
            return 33 - this.adFormManagerService.getTeazerTextLength(0);
        },
        enumerable: true,
        configurable: true
    });
    NewTeazerFormLiteComponent.prototype.ngOnInit = function () {
    };
    NewTeazerFormLiteComponent.prototype.ImageSelected = function (image) {
        console.log(image);
        this.adFormManagerService.AddTeazerImage(image);
    };
    return NewTeazerFormLiteComponent;
}());
export { NewTeazerFormLiteComponent };
