<app-ad-form-lite
  (AdSaved)="SaveAd($event)"
  [ad]="ad"
  [targetingData]="adTargetingData"
  [isUpdating]="true"

  *ngIf="!loading; else loadingScreen"
></app-ad-form-lite>
<ng-template #loadingScreen>
  <div class="update-loading-screen">
    <mat-spinner></mat-spinner>
    Подождите идет загрузка данных
  </div>
</ng-template>
