<div [class]="'card personal-cabinet personal-cabinet_'+cabinet.provider">
  <div class="card-media-block personal-cabinet__media">
  </div>
  <div class="card-block personal-cabinet__description">
    {{cabinet.description}}
  </div>
  <div class="card-footer personal-cabinet__status">
    <ng-container *ngIf="!IsLoggedIn; else loggedIn;">
      <a [href]="'https://postmonitor.ru' + Link" target="_blank" class="btn btn-primary btn-block btn-sm">Подключить</a>
    </ng-container>
    <ng-template #loggedIn>
      Вы подключены
    </ng-template>
  </div>
</div>
