<!--<app-memory-alert [id]="'automation-accounts-activation-tip'">-->
  <!--Выберите кабинеты, с которыми будете работать-->
<!--</app-memory-alert>-->
<div class="activate-cabinets">
  <ng-container *ngIf="accounts && slots">
    <ng-container *ngIf="accounts.length > 0; else emptyCabinets">
      <app-pick-list-ngr
        #pickList
        [dataList]="accounts"
        [maxCount]="countOfMaxSlots"
        [slots]="slots"
        (dataListChanged)="OnSaveCabinetsBined($event)"
        (onClose)="OnClose()"
      ></app-pick-list-ngr>
    </ng-container>
    <ng-template #emptyCabinets>
      Для продолжения работы вам необходимо создать рекламный кабинет ВКонтакте. Для этого перейдите <a href="https://postmonitor.ru/blog/instrukciya-po-sozdaniyu-reklamnogo-kabineta-vkontakte" target="_blank" rel="nofollow noopener">по ссылке</a>.
    </ng-template>
    <div class="activate-cabinets__toolbox">
      <button class="vk-standart-button" (click)="Refresh()">Обновить</button>
    </div>
  </ng-container>
</div>
