import {Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {
  AutomationVkService,
  AutomationYandexService,
  StrategiesService,
  UserStrategiesService
} from '../../../api/services';
import {ActivatedRoute} from '@angular/router';
import {AdViewModel} from '../../models/view-models/ad-view-model';
import {DexieCabinetsService} from '../../../shared/services/dexie/dexie-cabinets.service';
import {DexieCampaignsService} from '../../../shared/services/dexie/dexie-campaigns.service';
import {
  Client,
  Counter,
  Goal,
  GoalReachResponse,
  Strategy,
  StrategyInput,
  UserStrategyInput
} from '../../../api/models';
import {SecondsToTimePipe} from '../../../shared/pipes/seconds-to-time.pipe';
import {VkUtmExtractorService} from '../../../vk/services/vk-utm-extractor.service';
import {AutomationExpirationManagerService} from '../../services/automation-expiration-manager.service';
import {ExcelService} from '../../services/excel-service.service';
import {UrlExctractorService} from '../../../vk/services/url-exctractor.service';
import {YandexMetrikaWrapperService} from '../../services/yandex-metrika-wrapper.service';
import {UtmExtractorService} from '../../../shared/services/utm-extractor.service';
import {isNullOrUndefined} from 'util';
import {WallLink} from '../../../api/models/wall-link';
import {WallPost} from '../../../api/models/wall-post';
import * as moment from 'moment';
import {NgrChip} from '../../../ngr-ui/models/ngr-chip.model';
import {UtilsService} from '../../../shared/services/utils.service';

const DEFAULT_TIMEOUT = 1000;
const DEFAULT_HIDDEN_FIELDS = {
  reach: {hidden: false},
  name: {hidden: false},
  yandex_cr: {hidden: false},
  yandex_cpa: {hidden: false},
  status: {hidden: false},
  cpm: {hidden: false},
  ecpc: {hidden: false},
  ctr: {hidden: false},
  clicks: {hidden: false},
  impressions: {hidden: false},
  day_limit: {hidden: false},
  all_limit: {hidden: false},
  spent: {hidden: false},
  to_group: {hidden: false},
  join_group: {hidden: false},
  hide: {hidden: false},
  report: {hidden: false},
  unsubscribe: {hidden: false},
  reach_subscribers: {hidden: false},
  reach_total: {hidden: false},
  links: {hidden: false},
  pricing_subscribe: {hidden: false},
  price_click: {hidden: false},
  price_to_group: {hidden: false},
  yandex_reched_goals: {hidden: false},
  likes: {hidden: false},
  reposts: {hidden: false},
  comments: {hidden: false},
  yandex_cr_from_click: {hidden: false},
  yandex_cr_from_view: {hidden: false},
  yandex_average_duration: {hidden: false},
};

@Component({
  selector: 'app-new-automation-ads',
  templateUrl: './new-automation-ads.component.html',
  styleUrls: ['./new-automation-ads.component.scss']
})
export class NewAutomationAdsComponent implements OnInit, OnChanges {

  public accountId = null;
  public campaignId = null;
  public clients: Array<Client> = [];
  public currentCabinet = null;
  public onlyTizers = true;
  public goalId = null;
  public showLink = true;
  public showUtms = true;
  public showMin = true;
  public disabled = false;
  public hiddenColumns = DEFAULT_HIDDEN_FIELDS;
  public counterId = null;
  public BidManagmentModalOpened = false;
  public strategyType = 'bid_retention';
  public strategies: Array<Strategy>;
  public inputs: any = {};
  public strategiesUpdate = false;

  public SelectedGroups: Array<AdViewModel> = [];
  public errors: Array<string> = [];
  public ShowStrategyColumns: boolean = false;

  public modifyFunctions = {
    BID_MIN: (value) => parseFloat(value) * 100,
    BID_START: (value) => parseFloat(value) * 100,
    BID_END: (value) => parseFloat(value) * 100,
    BID_MAX: (value) => parseFloat(value) * 100,
    BID_STEP: (value) => parseFloat(value) * 100,
    ECPC_LIMIT: (value) => parseFloat(value) * 100,

  };

  public displayFunctions = {
    BID_MIN: (value) => parseFloat(value) / 100,
    BID_MAX: (value) => parseFloat(value) / 100,
    BID_START: (value) => parseFloat(value) / 100,
    BID_END: (value) => parseFloat(value) / 100,
    BID_STEP: (value) => parseFloat(value) / 100,
    ECPC_LIMIT: (value) => parseFloat(value) / 100,
  };

  public get SelectedGroupsStrategy() {
    if (this.SelectedGroups.length === 1) {
      return this.SelectedGroups[0].strategy.inputs.map(x => {
        return {
          key: x.type.code,
          value: x.value
        };
      })
        .reduce((a, b) => {
          if (this.displayFunctions[b.key]) {
            a[b.key] = this.displayFunctions[b.key](b.value);
          } else {
            a[b.key] = b.value;
          }
          return a;
        }, {});
    } else {
      return null;
    }
  }

  public get HasAllSelectedAdsStrategies() {
    return this.SelectedGroups.filter(x => x.strategy == null).length === 0;
  }

  public get SelectedGroupsChips() {
    return this.SelectedGroups.map(x => new NgrChip(x.id, x.name));
  }

  public get SelectedStrategy() {
    if (this.strategies && this.strategies.length > 0) {
      const fields = {};
      const inputs = this.strategies.filter(x => x.code === this.strategyType)[0].inputs;
      inputs.forEach(x => {
        const input = {
          label: '',
          name: '',
          description: '',
          type: '',
          value: 0,
          options: null,
          modyfyFunction: null
        };

        input.label = x.title;
        input.name = UtilsService.DashToCamelCase(x.code);
        input.description = x.description;
        input.type = 'number';
        input.options = x.options;
        if (this.modifyFunctions[x.code]) {
          input.modyfyFunction = this.modifyFunctions[x.code];
        }


        if (this.displayFunctions[x.code]) {
          input.value = this.displayFunctions[x.code](input.value);
        }
        fields[input.name] = input;
      });
      return fields;
    }
    return new Array<StrategyInput>();
  }

  public period = {
    period: 'overall',
    dateFrom: '0',
    dateTo: '0'
  };

  public IsYandexMetrikaLoading = false;

  public alternatives = {
    id: {
      name: 'Id объявдения'
    },
    name: {
      name: 'Название'
    },
    cpm: {
      name: 'CPM'
    },
    all_limit: {
      name: 'Общий лимит'
    },
    day_limit: {
      name: 'Дневной лимит'
    },
    spent: {
      name: 'Потрачено'
    },
    clicks: {
      name: 'Переходы'
    },
    ctr: {
      name: 'CTR'
    },
    ecpc: {
      name: 'eCPC'
    },
    reach: {
      name: 'Охват записи'
    },
    links: {
      name: 'Переходы по ссылке'
    },
    price_click: {
      name: 'Цена перехода по ссылке'
    },
    price_to_group: {
      name: 'Цена перехода в группу'
    },
    join_group: {
      name: 'Вступлений в группу'
    },
    pricing_subscribe: {
      name: 'Цена подписчика'
    },
    likes: {
      name: 'Лайки'
    },
    reposts: {
      name: 'Репосты'
    },
    comments: {
      name: 'Комментарии'
    },
    report: {
      name: 'Жалоб'
    },
    unsubscribe: {
      name: 'Скрытых записей всех'
    },
    reach_subscribers: {
      name: 'Охват подписчиков'
    },
    reach_total: {
      name: 'Охват не подписчиков'
    },
    yandex_reched_goals: {
      name: 'Yandex.Целей достигнуто'
    },
    yandex_cpa: {
      name: 'Yandex.Стоимость цели'
    },
    yandex_cr_from_click: {
      name: 'Yandex.CR переход в цель'
    },
    yandex_cr_from_view: {
      name: 'Yandex.CR просмотр в цель'
    },
    yandex_page_depth: {
      name: 'Yandex.Глубина просмотра'
    },
    yandex_average_duration: {
      name: 'Yandex.Время на сайте',
      pipe: SecondsToTimePipe
    }
  };


  public adsViewModels: Array<AdViewModel> = null;
  public showGroupJoinData = false;
  public counters: Array<Counter> = [];
  public goals: Array<Goal> = [];
  private currentCampaigns: any;
  private clientId: number;
  public hasAnyStrategies: boolean = false;

  public IsAnalyticServiceAvaialbe(analyticService: any) {
    return analyticService.IsAvaliable();
  }

  public get IsBlockedStrategy() {
    return this.SelectedGroups.length > 0;
  }

  constructor(
    private vkService: AutomationVkService,
    private route: ActivatedRoute,
    private localCabinetsDb: DexieCabinetsService,
    private localCampaignsDb: DexieCampaignsService,
    private excel: ExcelService,
    public yandex: YandexMetrikaWrapperService,
    private extractService: UtmExtractorService,
    private vkUrlExtractor: UrlExctractorService,
    private vkExtractService: VkUtmExtractorService,
    public accessManager: AutomationExpirationManagerService,
    private strategiesService: StrategiesService,
    private yandexService: AutomationYandexService,
    private usiService: UserStrategiesService
  ) {
  }

  public get IsPeriodOverall() {
    return this.period.period === 'overall';
  }

  ToggleDisabled() {
    this.disabled = !this.disabled;
  }

  ngOnInit() {
    this.accountId = parseInt(this.route.snapshot.queryParams.account_id, 10);
    this.campaignId = parseInt(this.route.snapshot.queryParams.campaign_id, 10);
    this.clientId = parseInt(this.route.snapshot.queryParams.client_id, 10);
    this.LoadCurrentCabinet()
      .then(() => {
        this.InitPage();
      });
    this.LoadCurrentCampaign();
    this.LoadActionValues();
  }

  public SetActionValues() {
    window.localStorage.setItem('show_actions', JSON.stringify({
      showUtms: this.showUtms,
      showMin: this.showMin,
      showLink: this.showLink
    }));
  }

  public LoadCurrentCampaign() {
    this.localCampaignsDb.findCampaignById(this.campaignId)
      .then(data => {
        this.currentCampaigns = data;
      });
  }

  public LoadCurrentCabinet() {
    return this.localCabinetsDb.getCabinetById(this.accountId)
      .then((cabinet) => {
        this.currentCabinet = cabinet;
      });
  }

  public InitPage() {
    this.LoadClients()
      .then(() => {
        return new Promise(resolve => {
          this.LoadData(this.clientId)
            .then(() => {
              resolve();
            });
        });
      });
    this.LoadCounters();
  }

  public LoadClients() {
    return new Promise(resolve => {
      if (this.currentCabinet && this.currentCabinet.account_type === 'agency') {
        this.vkService.getClients(this.accountId)
          .subscribe((data) => {
            this.clients = data.data;
            resolve();
          });
      } else {
        resolve();
      }
    });
  }

  public LoadData(clientId) {
    this.ToggleDisabled();
    return this.LoadAds(clientId)
      .then(this.LoadStatistics.bind(this))
      .then(this.LoadReach.bind(this))
      .then(this.LoadLayouts.bind(this))
      .then(this.LoadWallPost.bind(this))
      .then(this.LoadYandex.bind(this, 2))
      .then(() => {
        this.adsViewModels = this.adsViewModels
          .map((ad, i) => {
            ad.AddGroupsJoin();
            ad.ad_url = this.GetAdUrls(ad);

            return ad;
          });
        this.ToggleDisabled();
        this.LoadStrategies();
        this.LoadStrategiesForAds();
      });
  }

  public LoadAds(clientId) {
    this.adsViewModels = [];
    return new Promise(resolve => {
      setTimeout(() => {
        this.vkService.GetAds({
          accountId: this.accountId,
          campaignIds: [this.campaignId],
          adIds: null,
          clientId: clientId,
          includeDeleted: false,
          limit: 2000,
          offset: 0
        })
          .subscribe(data => {
            this.adsViewModels = data.data.map(ad => {
              this.onlyTizers = !(ad.ad_format === 9);
              const newAd = new AdViewModel();
              newAd.ConvertAd(ad);
              return newAd;
            });
            resolve(clientId);
          });
      }, DEFAULT_TIMEOUT);
    });
  }

  public LoadStatistics(clientId) {
    return new Promise(resolve => {
      const adsIds = this.GetIds();

      if (adsIds.length > 0) {
        // const period = this.period;
        let period = '';
        let period_date_from = this.period.dateFrom;
        let period_date_to = this.period.dateTo;
        if (this.period.period === 'overall') {
          period = 'day';
          period_date_from = '2012-06-01';
          period_date_to = moment(new Date()).format('YYYY-MM-DD');

        } else {
          period = this.period.period;
          period_date_from = this.period.dateFrom;
          period_date_to = this.period.dateTo;
        }

        setTimeout(() => {
          this.vkService.GetStatistics({
            accountId: this.accountId,
            ids: adsIds,
            idsType: 'ad',
            period: period,
            dateFrom: period_date_from,
            dateTo: period_date_to,
            clientId: this.clientId
          })
            .subscribe(data => {
              this.adsViewModels.forEach(ad => {
                const stat = data.data.find(x => x.id === ad.id);
                ad.AddStatistic(stat);
              });
              resolve(clientId);
            });
        }, DEFAULT_TIMEOUT);
      } else {
        resolve(clientId);
      }
    });
  }

  public LoadReach(clientId) {
    return new Promise(resolve => {
      const ids = this.adsViewModels.filter(x => x.ad_format === 9).map(x => x.id).join(',');
      if (ids && ids.length > 0) {
        setTimeout(() => {
          this.vkService.GetPostReach({
            ids,
            accountId: this.accountId,
            idsType: 'ad',
            clientId: this.clientId
          })
            .subscribe(data => {
              this.adsViewModels = this.adsViewModels.map(ad => {
                const reach = data.data.find(x => x.id === ad.id);
                ad.AddReach(reach);
                return ad;
              });
              resolve(clientId);
            });
        }, DEFAULT_TIMEOUT);
      } else {
        resolve(clientId);
      }
    });
  }

  public LoadWallPost(clientId) {
    return new Promise(resolve => {
      const ids = this.GetWithFormatIds();


      if (ids.length > 0) {
        this.vkService.GetWallById(ids)
          .subscribe(data => {
            this.adsViewModels = this.adsViewModels.map(ad => {
              const newIds = ad.link_url.replace('https://vk.com/wall', '')
                .replace('http://vk.com/wall', '')
                .split('_')
                .map(adl => parseInt(adl, 10));
              const w = data.data.find(wall => wall.owner_id === newIds[0] && wall.id === newIds[1]);
              ad.AddWall(w);
              ad.AddUtms(this.vkExtractService, this.extractService, w);
              return ad;
            });
            resolve(clientId);
          });
      } else {
        this.adsViewModels = this.adsViewModels.map(ad => {
          ad.AddUtms(this.vkExtractService, this.extractService, null);
          return ad;
        });
        resolve(clientId);
      }
    });
  }

  public LoadLayouts(clientId) {
    this.showGroupJoinData = false;

    return new Promise(resolve => {
      const ids = this.GetIds().split(',').map(x => parseInt(x, 10));
      setTimeout(() => {
        this.vkService.GetAdsLayout({
          adIds: ids,
          offset: 0,
          includeDeleted: false,
          limit: 2000,
          clientId,
          campaignIds: [this.campaignId],
          accountId: this.accountId
        })
          .subscribe(data => {
            this.adsViewModels = this.adsViewModels.map(ad => {
              const layout = data.data.find(x => x.id === ad.id);
              if (!this.showGroupJoinData && ad.ad_format !== 9) {
                if (layout && layout.link_url.indexOf('vk.com/') >= 0) {
                  this.showGroupJoinData = true;
                }
              }

              ad.AddLayout(layout, this.vkUrlExtractor);
              return ad;
            });
            resolve(clientId);
          });
      }, DEFAULT_TIMEOUT);
    });
  }

  public LoadYandex(countOfRepeats: number = 1) {
    this.StartYandexMetrikaLoading();
    return new Promise(resolve => {
      if (this.goalId) {
        setTimeout(() => {
          this.yandex.LoadGoalReach({
            counterId: this.counterId,
            goalId: this.goalId,
            dateFrom: (this.period.dateFrom === '0') ? '2012-06-01' : this.period.dateFrom,
            dateTo: (this.period.dateTo === '0') ? 'today' : this.period.dateTo
          })
            .then((data: GoalReachResponse) => {
              countOfRepeats = countOfRepeats - 1;


              if (countOfRepeats > 0 && data.data.length === 0) {
                setTimeout(() => {
                  this.LoadYandex(countOfRepeats);
                }, 2000);
              } else {
                this.adsViewModels = this.adsViewModels.map(ad => {
                  ad.AddYandexData(null, this.yandex);
                  return ad;
                });
                this.StopYandexMetrikaLoading();
              }
              resolve();
            });
        }, DEFAULT_TIMEOUT);
      }
      resolve();
    });
  }

  public LoadCounters() {
    this.yandex.LoadCounters();
  }

  public LoadGoals() {
    this.yandex.LoadGoals(this.counterId);
  }

  private GetIds() {
    return this.adsViewModels.map(x => x.id).join();
  }

  public OnChangeShowUtms(showUtms) {
    this.showUtms = showUtms;
    this.SetActionValues();
  }

  public OnChangeShowMin(showMin) {
    this.showMin = showMin;
    this.SetActionValues();
  }

  public OnChangeShowLink(showLink) {
    this.showLink = showLink;
    this.SetActionValues();
  }

  public LoadActionValues() {
    const values = JSON.parse(localStorage.getItem('show_actions'));
    if (values) {
      Object.keys(values).forEach(x => {
        this[x] = values[x];
      });
    }
  }

  public GetAdUrls(ad: AdViewModel): Array<string> {
    if (!ad) {
      return null;
    }
    if (ad.ad_format === 9) {
      return this.vkUrlExtractor.ExtractUrl(ad.wall);
    } else {
      return [ad.link_url];
    }
  }

  private IsArticle(post: WallPost, link: WallLink): boolean {
    const containOnlyLink = post && post.attachments.filter(x => isNullOrUndefined(x.link)).length === 0;

    return link.url.indexOf('vk.com/@') >= 0 && containOnlyLink;
  }

  public GetLinkClass(classname: string, post: WallPost, link: WallLink): string {
    return classname + (this.IsArticle(post, link) ? ` ${classname}_article-mode` : '');
  }

  public GetLinkTitleLength(post: WallPost, link: WallLink): number {
    return this.IsArticle(post, link) ? 16 : 28;
  }

  ExportToExcel() {
    const data = this.adsViewModels.map(x => {
      return Object.keys(this.alternatives).reduce((a, b) => {
        if (this.alternatives[b].pipe) {
          a[this.alternatives[b].name] = new this.alternatives[b].pipe().transform(parseInt(x[b], 10));
        } else {
          a[this.alternatives[b].name] = x[b];
        }
        return a;
      }, {});
    });
    this.excel.exportAsExcelFile(data, this.currentCampaigns.name.replace(' ', '_'));
  }

  public SelectCounter(data) {
    this.counterId = data;
    this.LoadGoals();
  }

  public SelectGoal(data) {
    this.goalId = data;
    this.LoadYandex(5);
  }

  public ChangeDate(date) {
    this.period = date;
    this.InitPage();
  }

  public GetAdUrlInVkCabinet(ad: AdViewModel): string {
    return `https://vk.com/ads?act=office&union_id=${ad.id}`;
  }


  private StartYandexMetrikaLoading() {
    this.IsYandexMetrikaLoading = true;
  }

  private StopYandexMetrikaLoading() {
    this.IsYandexMetrikaLoading = false;
  }

  private GetWithFormatIds() {
    return this.adsViewModels
      .filter(x => x.ad_format === 9)
      .map(x => x.link_url.replace('http://vk.com/wall', ''))
      .join(',');
  }

  public OpenBidManager(isUpdating) {
    this.strategiesUpdate = isUpdating;
    if (this.strategiesUpdate) {
      this.inputs = {};
      this.strategies.forEach(x => {
        const fields = {};
        x.inputs.forEach(y => {
          fields[y.code] = {
            name: y.code,
            type: y['frontendInputType'],
            label: y.title,
            value: (this.SelectedGroupsStrategy) ? this.SelectedGroupsStrategy[y.code] : 0,
            options: y.options
          };
          if (this.modifyFunctions[y.code]) {
            fields[y.code].modyfyFunction = this.modifyFunctions[y.code];
          }
        });
        this.inputs[x.code] = fields;
      });
    }
    this.BidManagmentModalOpened = true;
  }

  public HideSelected() {
    throw new Error('Not implemented');
  }

  public LoadStrategies() {
    this.strategiesService.GetAllStrategies({showInactive: false, onlyAllowedInCreationModule: false})
      .subscribe(data => {
        this.strategies = data;
        this.inputs = {};
        this.strategies.forEach(x => {
          const fields = {};
          x.inputs.forEach(y => {
            fields[y.code] = {
              name: y.code,
              type: y['frontendInputType'],
              label: y.title,
              value: (this.SelectedGroupsStrategy) ? this.SelectedGroupsStrategy[y.code] : 0,
              options: y.options
            };
          });
          this.inputs[x.code] = fields;
        });
      });
  }

  public SuccessHandle(formValue) {
    formValue.strategyType = this.strategyType;
    Object
      .keys(formValue)
      .forEach(x => {
        if (this.modifyFunctions[x]) {
          formValue[x] = this.modifyFunctions[x](formValue[x]);
        }
      });
    const data = {
      properties: formValue,
      accountId: this.accountId,
      clientId: this.clientId || 0,
      strategyType: this.strategyType,
      ads: this.SelectedGroups.map(x => x.id)
    };
    this.errors = [];
    formValue.ads = this.SelectedGroups.map(x => x.id);
    formValue.accountId = this.accountId;
    formValue.clientId = this.clientId;
    if (!this.strategiesUpdate) {
      this.usiService.CreateUserStrategy(data)
        .subscribe(data1 => {
          this.ToggleModalOpened();
          this.LoadStrategiesForAds();
        }, err => {
          this.errors.push(err.error.description);
        });
    } else {
      this.usiService.UpdateUserStrategies(data)
        .subscribe(data1 => {
          this.ToggleModalOpened();
          this.LoadStrategiesForAds();
        }, err => {
          this.errors.push(err.error.description);
        });
    }
  }

  public LoadStrategiesForAds() {
    this.usiService
      .GetStrategiesByAdAndAccountIds({
        accountId: this.accountId,
        clientId: this.clientId,
        viewModel: {
          adIds: this.adsViewModels.map(x => x.id)
        }
      })
      .subscribe(data => {
        if (data.length > 0) {
          this.adsViewModels.forEach(x => {
            const strategy = data.find(y => y.adId === x.id);
            x.AddStrategy(strategy);
          });
          this.ShowStrategyColumns = true;
        } else {
          this.adsViewModels.forEach(x => {
            x.AddStrategy(null);
          });
          this.ShowStrategyColumns = false;
        }
      });
  }

  public CancelHandle() {
    this.ToggleModalOpened();
  }

  ToggleModalOpened() {
    this.BidManagmentModalOpened = !this.BidManagmentModalOpened;
  }

  public CreateStrategy(data) {
    data.ads = this.SelectedGroups;

    this.usiService.CreateUserStrategy(data)
      .subscribe(data1 => {
      });
  }

  public RemoveStrategies() {
    const promises = this.SelectedGroups.map(x => {
      return new Promise(resolve => {
        this.usiService.RemoveStrategy({
          accountId: this.accountId,
          adId: x.id
        })
          .subscribe(data => {
            resolve();
          });
      });
    });

    Promise.all(promises)
      .then(x => {
        this.LoadStrategiesForAds();
      })
      .catch(x => {
        this.LoadStrategiesForAds();
      });
  }

  SelectedRow(data) {
    this.LoadStrategies();
  }

  GetSelectedOptionValue(input: UserStrategyInput) {
    return input.type.options.find(x => parseInt(x.value, 10) === input.value).title;
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  public ShowAutomationInputs(): boolean {
    return this.route.snapshot.queryParams.noonewillguessthatthisparameteropenssecretmethods;
  }
}
