import { EventEmitter, OnInit } from '@angular/core';
import { AutomationVkSlotService } from '../../../api/services/automation-vk-slot.service';
import { isNullOrUndefined } from 'util';
import * as moment from 'moment';
moment.locale('ru');
var PickListComponent = /** @class */ (function () {
    function PickListComponent(_service) {
        this._service = _service;
        /**
           * Элементы списка
           * @type {PickListItemModel[]}
           */
        this.dataList = [];
        /**
         * Событие сохранения кабинетов
         * @type {EventEmitter<any>}
         */
        this.dataListChanged = new EventEmitter();
        /**
         * Событие закрытия окна
         * @type {EventEmitter<any>}
         */
        this.onClose = new EventEmitter();
        this.error = '';
    }
    Object.defineProperty(PickListComponent.prototype, "Items", {
        get: function () {
            return this.dataList.filter(function (x) { return x.account_type !== 'agency'; }).map(function (x) {
                x.isSelected = !x.is_blocked;
                return x;
            });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PickListComponent.prototype, "FreeSlots", {
        get: function () {
            return this.slots ? this.slots.filter(function (x) { return x.bindedCabinetId === null; }).length : 0;
        },
        enumerable: true,
        configurable: true
    });
    PickListComponent.prototype.ngOnInit = function () {
    };
    PickListComponent.prototype.Change = function (status, data) {
        this.dataListChanged.emit(data);
    };
    /**
     * Сохраняет значения списка
     * @constructor
     */
    PickListComponent.prototype.Save = function () {
        this.onClose.emit();
    };
    PickListComponent.prototype.GetSlotByAccountId = function (account_id) {
        return this.slots.find(function (x) { return x.bindedCabinetId === account_id; });
    };
    PickListComponent.prototype.GetDeactivationTimeString = function (account_id) {
        var slot = this.GetSlotByAccountId(account_id);
        if (slot) {
            var date = moment(slot.canRebindAfter).local();
            return moment().to(date);
        }
        else {
            return '';
        }
    };
    PickListComponent.prototype.SetError = function (err) {
        this.error = err.description;
    };
    PickListComponent.prototype.CanBeSelected = function (account) {
        var slot = null;
        if (account.account_type === 'client') {
            slot = this.slots.find(function (x) { return x.bindedCabinetId === account.account_id && x.bindedClientId === account.client_id; });
        }
        else {
            slot = this.slots.find(function (x) { return x.bindedCabinetId === account.account_id; });
        }
        if (slot) {
            return (!isNullOrUndefined(slot.bindedCabinetId)) ? !slot.canRebindCabinet : this.FreeSlots === 0;
            ;
        }
        else {
            return this.FreeSlots === 0;
        }
    };
    return PickListComponent;
}());
export { PickListComponent };
