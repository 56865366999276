import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AutomationHomeComponent} from './routes/automation-home/automation-home.component';
import {AutomationAccountsComponent} from './routes/automation-accounts/automation-accounts.component';
import {AutomationCampaignsComponent} from './routes/automation-campaigns/automation-campaigns.component';
import {AutomationAdsComponent} from './routes/automation-ads/automation-ads.component';
import {NewAutomationAdsComponent} from './routes/new-automation-ads/new-automation-ads.component';
import {EasyAdsDashboardComponent} from './routes/easy-ads-dashboard/easy-ads-dashboard.component';
import {AutomationWrapperComponent} from '../shared/components/automation-wrapper/automation-wrapper.component';
import {TestingRouteComponent} from "./routes/testing-route/testing-route.component";

const routes: Routes = [
  {
    path: 'automation',
    component: AutomationWrapperComponent,
    children: [
      {
        path: '',
        component: AutomationHomeComponent,
      },
      {
        path: 'accounts',
        component: AutomationAccountsComponent
      },
      {
        path: 'campaigns',
        component: AutomationCampaignsComponent
      },
      {
        path: 'old-ads',
        component: NewAutomationAdsComponent
      },
      {
        path: 'ads',
        component: AutomationAdsComponent
      },
      {
        path: 'dashboard',
        component: EasyAdsDashboardComponent
      },
      {
        path: 'testing',
        component: TestingRouteComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AutomationRoutingModule {
}
