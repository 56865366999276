<div class="ad-format-container">
  <div [ngClass]='{
    "ad-format": true,
    "ad-format_selected": IsSelected(adFormat.value)
  }' (click)="SelectFormat(adFormat.value)">
    <div class="ad-format-strip">
      <div class="ad-format-strip__item">
        <div class="ad-format-icon-container">
          <img [src]="adFormat.image" [alt]="adFormat.title" class="ad-format-icon">
        </div>
      </div>

      <div class="ad-format-strip__item">
        <div class="ad-format-title">
          {{adFormat.title}}
        </div>
        <div class="ad-format-description">
          {{adFormat.description}}
        </div>
      </div>
    </div>
  </div>
</div>
