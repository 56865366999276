<clr-dropdown class="user-integration-status-wrapper">
  <div class="user-integration-status">
    <button class="user-integration-status__button" [ngStyle]="StatusStyle" clrDropdownTrigger [disabled]="isSessionExhausted$ | async">
      <span class="user-integration-status__icon">
        <mat-icon>{{status?.icon}}</mat-icon>
      </span>
      <span class="user-integration-status__name">{{status?.name}}</span>
    </button>
  </div>

  <clr-dropdown-menu *ngIf="!isRemoved">
    <button class="user-integration-status__button" [style.color]="'green'" clrDropdownItem (click)="ChangeStatus(true)">
      <span class="user-integration-status__icon">
        <mat-icon>play_arrow</mat-icon>
      </span>
      <span class="user-integration-status__name">Запустить</span>
    </button>
    <button class="user-integration-status__button" [style.color]="'red'" clrDropdownItem (click)="ChangeStatus(false)">
      <span class="user-integration-status__icon">
        <mat-icon>stop</mat-icon>
      </span>
      <span class="user-integration-status__name">Остановить</span>
    </button>
  </clr-dropdown-menu>

</clr-dropdown>
