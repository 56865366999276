import { OnInit } from '@angular/core';
import { AdFormManagerService } from '../../services/ad-form-manager/ad-form-manager.service';
var AdPromopostsListComponent = /** @class */ (function () {
    function AdPromopostsListComponent(adFormManagerService) {
        this.adFormManagerService = adFormManagerService;
    }
    Object.defineProperty(AdPromopostsListComponent.prototype, "promoposts", {
        get: function () {
            return this.adFormManagerService.promoposts;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdPromopostsListComponent.prototype, "selectedGroup", {
        get: function () {
            return this.adFormManagerService.selectedGroup;
        },
        enumerable: true,
        configurable: true
    });
    AdPromopostsListComponent.prototype.ngOnInit = function () {
    };
    return AdPromopostsListComponent;
}());
export { AdPromopostsListComponent };
