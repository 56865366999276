import { EventEmitter, OnInit } from '@angular/core';
var StrategyPreviewComponent = /** @class */ (function () {
    function StrategyPreviewComponent() {
        this.selectedChange = new EventEmitter();
        this.detailsShowed = false;
    }
    Object.defineProperty(StrategyPreviewComponent.prototype, "StrategyDescription", {
        get: function () {
            return (this.detailsShowed) ? this.strategy.description : this.strategy.descriptionPreview;
        },
        enumerable: true,
        configurable: true
    });
    StrategyPreviewComponent.prototype.ngOnInit = function () {
    };
    StrategyPreviewComponent.prototype.showMore = function () {
        this.detailsShowed = !this.detailsShowed;
    };
    StrategyPreviewComponent.prototype.change = function () {
        this.selectedChange.emit(this.strategy.code);
    };
    return StrategyPreviewComponent;
}());
export { StrategyPreviewComponent };
