import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var AffiliateStatusService = /** @class */ (function () {
    function AffiliateStatusService(http) {
        this.http = http;
        this.isPartner = false;
        this._baseUrl = environment.backendUri + "/" + environment.apiPrefix + "/affiliate-status";
    }
    AffiliateStatusService.prototype.LoadAffiliateMemberStatus = function () {
        var _this = this;
        this.http.get(this._baseUrl)
            .subscribe(function (affiliateStatus) {
            _this.isPartner = affiliateStatus ? affiliateStatus['isActivated'] : false;
        });
    };
    AffiliateStatusService.ngInjectableDef = i0.defineInjectable({ factory: function AffiliateStatusService_Factory() { return new AffiliateStatusService(i0.inject(i1.HttpClient)); }, token: AffiliateStatusService, providedIn: "root" });
    return AffiliateStatusService;
}());
export { AffiliateStatusService };
