<div class="b-card document-wrapper">
  <h1 class="document-header">Пользовательское соглашение</h1>
  <!--<p>-->
  <!--Настоящий документ «Пользовательское соглашение» представляет собой предложение <strong>ООО «_____»</strong>-->
  <!--(далее —-->
  <!--«Администрация»), заключить договор на изложенных ниже условиях Соглашения.-->
  <!--</p>-->
  <h2 class="document-header">1. Общие положения Пользовательского соглашения</h2>
  <h3 class="document-header">1.1. В настоящем документе и вытекающих или связанным с ним отношениях Сторон
    применяются следующие термины и определения:</h3>
  <ol class="document-list">
    <li>Платформа — программно-аппаратные средства, интегрированные с Сайтом Администрации;</li>
    <li>Пользователь — дееспособное физическое лицо, присоединившееся к настоящему Соглашению в собственном интересе
      либо выступающее от имени и в интересах представляемого им юридического лица;
    </li>
    <li>Сайт Администрации/ Сайт — интернет-сайты, размещенные в домене <strong>postmonitor.ru</strong> и его
      поддоменах;
    </li>
    <li>Сервис — комплекс услуг и лицензия, предоставляемые Пользователю с использованием Платформы;</li>
    <li>Соглашение — настоящее соглашение со всеми дополнениями и изменениями.</li>
  </ol>
  <h3 class="document-header">1.2 Допускается использование сервиса в пределах его объявленных
    функциональных возможностей, включая:</h3>
  <ol class="document-list">
    <li>Просмотр размещенных на Сайте материалов;</li>
    <li>Регистрация и/или авторизация на Сайт.;</li>
    <!--<li>Размещение или отображение на Сайте любых материалов, включая но не ограничиваясь такими как: тексты,-->
    <!--гипертекстовые ссылки, изображения, аудио и видео- файлы, сведения и/или иная информация;-->
    <!--</li>-->
  </ol>
  <!--<p>создает договор на условиях настоящего Соглашения в соответствии с положениями ст.437 и 438 Гражданского кодекса-->
  <!--Российской Федерации.</p>-->

  <h3 class="document-header">1.3 Воспользовавшись любой из указанных выше возможностей по использованию Сервиса вы
    подтверждаете, что:</h3>
  <ol class="document-list">
    <li>
      Ознакомились с условиями настоящего Соглашения в полном объеме до начала использования Сервиса;
    </li>

    <li>
      Принимаете все условия настоящего Соглашения в полном объеме без каких-либо изъятий и ограничений с вашей
      стороны и обязуетесь их соблюдать или прекратить использование Сервиса. Если вы не согласны с условиями
      настоящего
      Соглашения или не имеете права на заключение договора на их основе, вам следует незамедлительно прекратить любое
      использование Сервиса;
    </li>

    <li>
      Соглашение (в том числе любая из его частей) может быть изменено Администрацией без какого-либо специального
      уведомления. Новая редакция Соглашения вступает в силу с момента ее размещения на Сайте Администрации либо
      доведения
      до сведения Пользователя в иной удобной форме, если иное не предусмотрено новой редакцией Соглашения;
    </li>
  </ol>


  <h2 class="document-header">
    2. Общие условия пользования Сервисом
  </h2>

  <p>
    2.1. Использование функциональных возможностей Сервиса допускается только после прохождения Пользователем
    регистрации и авторизации на Сайте в соответствии с установленной Администрацией процедурой.
  </p>

  <p>
    2.2. Технические, организационные и коммерческие условия использования Сервиса, в том числе его функциональных
    возможностей доводятся до сведения Пользователей путем отдельного размещения на Сайте или путем нотификации
    Пользователей.
  </p>

  <p>
    2.3. Выбранные Пользователем логин и пароль являются необходимой и достаточной информацией для доступа
    Пользователя на Сайт. Пользователь несет полную ответственность за их сохранность, самостоятельно выбирая способ их
    хранения.
  </p>

  <p>
    2.4. Пользователю запрещается:
  </p>

  <ul>
    <li>
      Передавать свои логин и пароль третьим лицам;
    </li>
    <li>
      Использовать автоматические средства сбора информации: скрипты, специальный софт, ботов и любые другие методы;
    </li>
    <li>
      Пользоваться одним аккаунтом нескольким Пользователям.
    </li>
  </ul>
  <p>
    2.5. Для ознакомления с функционалом сервиса предоставляется демо-режим.
  </p>

  <!--<h2 class="document-header">-->
  <!--3. Лицензия на использование Сайта и допустимое использование Сервиса-->
  <!--</h2>-->
  <!--<p></p>-->
  <!--<p>-->
  <!--В данном разеле описываются разрешенные способы использования Сайта и предоставляемого на его основе Сервиса.-->
  <!--Безвозмездность лицензии препятствует применению Закона О защите прав потребителя в случае, когда на стороне-->
  <!--пользователя – физическое лицо.-->
  <!--</p>-->

  <h2 class="document-header">
    3. Гарантии Пользователя
  </h2>
  <p>
    Соглашаясь с данным пользовательским соглашением Пользователь обязуется соблюдать установленные данным соглашением
    правила.<br>
    В случае нарушения установленных правил Администрация вправе заблокировать или удалить профиль Пользователя без
    объяснения причин и без права на выплату за неиспользованный период оплаченного доступа.<br>
    Вся личная переписка Пользователя и Администрации сервиса является конфиденциальной информацией.
    Разглашение данной информации запрещено.
    В случае разглашения данной информации Администрация сервиса имеет право заблокировать или удалить профиль Пользователя без
    объяснения причин и без права на выплату за неиспользованный период оплаченного доступа.
  </p>

  <!--<p>-->
  <!--В разеле указываются гарантии и заверения со стороны пользователя о соблюдении требований законодательства и-->
  <!--Пользовательского соглашения при использовании Сайта и Сервиса на его основе. Данные положения необходимы, в-->
  <!--частности, для последующего возложения ответственности на пользователя за нарушения законодателства или прав-->
  <!--третьих лиц в связи с публикацией на сайте противоправных материалов.-->
  <!--</p>-->

  <!--<h2 class="document-header">-->
  <!--5. Лицензия на использование пользовательских материалов-->
  <!--</h2>-->

  <!--<p>-->
  <!--При организации социального сервиса или платформы для размещения пользователями различных материалов в публичном-->
  <!--доступе необходимо оформлять лицензионное соглашение с каждым пользователем на использование его материалов в-->
  <!--рамках такого Интернет-сервиса. Например разрешение пользователя на использование его фотографиии может-->
  <!--понадобиться для ее публикации на страницах других пользователей и т.д.-->

  <!--Кроме того, получение лицензии подтверждает факт использования контента с разрешения пользователя, который-->
  <!--отвечает за наличие у него полномочий на выдачу такой лицензии-->
  <!--</p>-->
</div>
