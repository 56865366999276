import { ElementRef, OnInit } from '@angular/core';
import { ControlValueAccessor } from "@angular/forms";
import { PopoverMenuComponent } from "../../../../../popover/components/popover-menu/popover-menu.component";
import { LinkQueryParamTemplateDirective } from "../../../directives/link-query-param-template.directive";
var LinkQueryParamInputComponent = /** @class */ (function () {
    function LinkQueryParamInputComponent() {
        this.items = [];
        this.selectedItem = {};
    }
    Object.defineProperty(LinkQueryParamInputComponent.prototype, "ShowTextField", {
        get: function () {
            return !this.value.includes('{{');
        },
        enumerable: true,
        configurable: true
    });
    LinkQueryParamInputComponent.prototype.ngOnInit = function () {
        console.log(this.popover, 'POPOVER  ');
        console.log(this.queryParamTemplates, 'QUERY PARAMS');
        if (this.queryParamTemplates) {
            this.selectedItemTemplate = this.queryParamTemplates.template;
        }
        // if (this.queryParamTemplates && this.queryParamTemplates.changes) {
        //   this.queryParamTemplates.changes.subscribe(x => {
        //     console.log(x, 'PARAMS');
        //     this.selectedItemTemplate = x;
        //   })
        // }
    };
    LinkQueryParamInputComponent.prototype.SelectItem = function (item) {
        this.value = item.value;
        this.selectedItem = item;
        this.emitChange();
        this.popover.close();
    };
    LinkQueryParamInputComponent.prototype.insertTextAtCursor = function (el, text, offset) {
        var val = el.value, endIndex, range, doc = el.ownerDocument;
        if (typeof el.selectionStart == "number"
            && typeof el.selectionEnd == "number") {
            endIndex = el.selectionEnd;
            el.value = val.slice(0, endIndex) + text + val.slice(endIndex);
            el.selectionStart = el.selectionEnd = endIndex + text.length + (offset ? offset : 0);
        }
        else if (doc.selection != "undefined" && doc.selection.createRange) {
            el.focus();
            range = doc.selection.createRange();
            range.collapse(false);
            range.text = text;
            range.select();
        }
    };
    LinkQueryParamInputComponent.prototype.registerOnChange = function (fn) {
        this.onChange = fn;
    };
    LinkQueryParamInputComponent.prototype.registerOnTouched = function (fn) {
        this.onTouch = fn;
    };
    LinkQueryParamInputComponent.prototype.setDisabledState = function (isDisabled) {
    };
    LinkQueryParamInputComponent.prototype.writeValue = function (obj) {
        this.value = obj;
    };
    LinkQueryParamInputComponent.prototype.emitChange = function () {
        this.onChange(this.value);
    };
    LinkQueryParamInputComponent.prototype.ClearField = function () {
        this.value = '';
    };
    return LinkQueryParamInputComponent;
}());
export { LinkQueryParamInputComponent };
