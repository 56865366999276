<form [formGroup]="form">
  <div class="ad-form-format">
    <div class="ad-form-format__formats-list">
      <ng-container *ngFor="let format of formats">
        <app-ad-format
          [adFormat]="format"
          [selectedField]="SelectedAdFormat.value"
          (selectedFieldChange)="SelectField($event)"
          formControlName="format"
        ></app-ad-format>
      </ng-container>
    </div>
    <div class="ad-form-format__preview">
      <app-new-ad-format-preview [adFormat]="SelectedAdFormat"></app-new-ad-format-preview>
    </div>
  </div>
</form>
