<div class="easy-ads-page-header">
  Настройки
</div>

<div class="easy-ads-page-sub-header">
  1. Контактная информация
</div>

<div class="easy-settings-data-container">
  <div class="easy-settings-data-strip">
    <div class="easy-settings-data-strip__label">
      E-mail
    </div>

    <div class="easy-settings-data-strip__content">
      <ng-container *ngIf="account; else noAccountEmailData">
        {{account.email}}
      </ng-container>
      <ng-template #noAccountEmailData>
        Загрузка...
      </ng-template>
    </div>
  </div>
</div>

<div class="easy-settings-data-container">
  <div class="easy-settings-data-strip">
    <div class="easy-settings-data-strip__label">
      Телефон
    </div>

    <div class="easy-settings-data-strip__content">
      <ng-container *ngIf="account; else noAccountPhoneData">
        {{account.phone}}
      </ng-container>
      <ng-template #noAccountPhoneData>
        Загрузка...
      </ng-template>
    </div>
  </div>
</div>

<!--<div class="easy-settings-data-container">-->
  <!--<div class="easy-settings-data-strip">-->
    <!--<div class="easy-settings-data-strip__label">-->
      <!--Подключенный профиль ВКонтакте-->
    <!--</div>-->

    <!--<div class="easy-settings-data-strip__content">-->
      <!--<a href="">id1234567890</a>-->
    <!--</div>-->
  <!--</div>-->
<!--</div>-->

<div class="easy-ads-page-sub-header">
  2. Тариф
</div>

<div class="easy-settings-data-container">
  <div class="easy-settings-data-strip">
    <div class="easy-settings-data-strip__label">
      Создание, запуск и редактирование объявлений ВКонтакте
    </div>

    <div class="easy-settings-data-strip__content">
      <ng-container *ngIf="automationTariffExpiration; else noAutomationExpirationData">
        <ng-container *ngIf="automationTariffExpiration.isDemo; else fullAutomationAccess">Тестовый доступ</ng-container>
        <ng-template #fullAutomationAccess>Полный доступ</ng-template>
        до {{ExpirationAt(automationTariffExpiration)}} <a routerLink="/easy-payments-create">продлить</a>
      </ng-container>
      <ng-template #noAutomationExpirationData>Загрузка...</ng-template>
    </div>
  </div>
</div>

<div class="easy-settings-data-container">
  <div class="easy-settings-data-strip">
    <div class="easy-settings-data-strip__label">
      Просмотр примеров объявлений
    </div>

    <div class="easy-settings-data-strip__content">
      <ng-container *ngIf="analyticsTariffExpiration; else noAnalyticsExpirationData">
        <ng-container *ngIf="analyticsTariffExpiration.isDemo; else fullAnalyticsAccess">Тестовый доступ</ng-container>
        <ng-template #fullAnalyticsAccess>Полный доступ</ng-template>
        до {{ExpirationAt(analyticsTariffExpiration)}} <a routerLink="/easy-payments-create">продлить</a>
      </ng-container>
      <ng-template #noAnalyticsExpirationData>Загрузка...</ng-template>
    </div>
  </div>
</div>

<div class="easy-settings-data-container">
  <div class="easy-settings-data-strip">
    <div class="easy-settings-data-strip__label">
      Баланс рекламного кабинета в ВКонтакте
    </div>

    <div class="easy-settings-data-strip__content">
      {{easyAdsDataService.budget}} руб. <a [href]="'https://vk.com/ads?act=payments'" target="_blank" rel="noopener nofollow">пополнить</a>
    </div>
  </div>
</div>

<div class="easy-ads-page-sub-header">
  3. Смена пароля
</div>

<div class="easy-settings-data-container">
  <div class="easy-settings-data-strip">
    <div class="easy-settings-data-strip__label">
      Смена пароля
    </div>

    <div class="easy-settings-data-strip__content">
      <button class="vk-standart-button" (click)="openedChangePasswordForm = true;">
        Сменить пароль
      </button>
    </div>
  </div>
</div>

<div class="easy-ads-page-sub-header">
  4. Удаление профиля
</div>

<div class="easy-settings-data-container">
  <div class="easy-settings-data-strip">
    <div class="easy-settings-data-strip__label">
      Удаление профиля
    </div>

    <div class="easy-settings-data-strip__content">
      <button class="vk-standart-button" (click)="RemoveAccountInitiate()">
        Удалить профиль
      </button>
    </div>
  </div>
</div>

<clr-modal [(clrModalOpen)]="openedChangePasswordForm">
  <div class="modal-title">Изменение пароля</div>
  <div class="modal-body">
    <app-auth-change-password></app-auth-change-password>
  </div>
</clr-modal>

<app-remove-account-modal [isVisible]="showRemoveAccountDataModal"
                          (onModalClose)="showRemoveAccountDataModal = false"></app-remove-account-modal>
