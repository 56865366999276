<h1>Партнерская программа</h1>

<p>
  Партнерская программа в данный момент разрабатывается. Она станет доступна в ближайшее время. <br>
  Мы можем дополнительно сообщить вам о запуске Партнерской программы. Для этого напишите нам в
  <a routerLink="/chat/conversation" target="_blank" rel="nofollow noopener">СООБЩЕНИЯ ЧАТА</a>:<br>
  "Оповестить о старте партнерской программы".
  Или подпишитесь на
  <a href="https://vk.com/postmonitor?w=app5748831_-150497232" target="_blank" rel="nofollow noopener">РАССЫЛКУ</a>
  в нашей группе ВКонтакте в которой мы <br>
  публикуем все обновления. Как только партнерская программа будет запущена, мы обязательно оповестим вас об этом.
</p>
