import {Injectable, OnInit} from '@angular/core';
import {CrmFieldOptionModel} from "../../models/crm-field-option.model";
import {CustomField} from "../../../../stores/custom-fields/custom-field.model";
import {CustomFieldsQuery} from "../../../../stores/custom-fields/custom-fields.query";
import {CustomFieldsService} from "../../../../stores/custom-fields/custom-fields.service";
import {AssignationPlatforms} from "../../../../stores/platform-assignations/platform-assignation.model";
import {CustomFieldValue} from "../../../../stores/custom-field-value/custom-field-value.model";
import {CustomFieldValueQuery} from "../../../../stores/custom-field-value/custom-field-value.query";
import {CustomFieldValueService} from "../../../../stores/custom-field-value/custom-field-value.service";
import {PlatformsTypes} from "../../../../platforms-types";

@Injectable({
  providedIn: 'root'
})
export class NewImportCrmService implements OnInit {

  defaultOptions: CrmFieldOptionModel[];
  usersOption: CrmFieldOptionModel[];
  pipelinesOptions: CrmFieldOptionModel[];
  stagesOptions: CrmFieldOptionModel[];
  customFields: CustomField[];
  customFieldValues: CustomFieldValue[];

  public advancedSettings = {
    FB_LEAD_FORMS_TO_MEGAPLAN_IMPORT: {
      DEALS: [
        {
          label: 'Передавать название кампании',
          key: 'includeCampaignName'
        },
        {
          label: 'Передавать название лид-формы',
          key: 'includeLeadFormName'
        },
        {
          label: 'Привязывать контакт к лиду',
          key: 'bindContact'
        }
      ]
    }
  }

  constructor(
    private customFieldsQuery: CustomFieldsQuery,
    private customFieldsService: CustomFieldsService,
    private customFieldsValuesQuery: CustomFieldValueQuery,
    private customFieldsValuesService: CustomFieldValueService,
  ) {
  }

  public SetDefaultOptions(options: CrmFieldOptionModel[]) {
    this.defaultOptions = options;
  }

  public SetUsersOptions(options: CrmFieldOptionModel[]) {
    this.usersOption = options;
  }

  public SetPipelinesOptions(options: CrmFieldOptionModel[]) {
    this.pipelinesOptions = options;
  }

  public SetStagesOptions(options: CrmFieldOptionModel[]) {
    this.stagesOptions = options;
  }

  public getFieldOptions(fieldCode: string, questions: CrmFieldOptionModel[]) {
    switch (fieldCode) {
      case 'PM_RESPONSIBLE_USER_ID':
        return this.usersOption;
      case 'PM_PIPELINE_ID':
        return this.pipelinesOptions;
      case 'PM_STAGE':
        return this.stagesOptions;
      default:
        return questions;
    }
  }

  /**
   * Get advanced settings required for destination settings
   * @param originPlatformCode
   * @param destinationPlatformCode
   * @param sectionCode
   * @constructor
   */
  public GetAdvancedSettings(originPlatformCode, destinationPlatformCode) {
    console.log(`${originPlatformCode}_TO_${destinationPlatformCode}`);
    return this.advancedSettings[`${originPlatformCode}_TO_${destinationPlatformCode}`];
  }

  ngOnInit(): void {
  }

  public LoadData() {
    this.customFieldsService.LoadCustomFields(AssignationPlatforms.MEGAPLAN)
      .subscribe()
    this.customFieldsQuery.CustomFields$.
    subscribe(customFields => {
      this.customFields = customFields;
    });
    this.customFieldsValuesQuery.CustomFieldsValues$.subscribe(customFieldsValues => {
      this.customFieldValues = customFieldsValues;
    });
  }
}
