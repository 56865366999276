import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TariffPlanViewModel} from '../../models/tariff-plan-view-model';


@Component({
  selector: 'app-easy-tarif-configurator-card',
  templateUrl: './easy-tarif-configurator-card.component.html',
  styleUrls: ['./easy-tarif-configurator-card.component.scss']
})
export class EasyTarifConfiguratorCardComponent implements OnInit {
  @Input() public disablePromocodeSelection: boolean = false;
  @Input() public disableOptionSelection: boolean = false;
  @Input() public disableTimeSelection: boolean = false;

  @Input() public showSelectTariffButton: boolean = true;

  @Input() public data: TariffPlanViewModel = null;
  @Output() public onCheckPromocode: EventEmitter<string> = new EventEmitter<string>();

  @Output() public onTarifSelected: EventEmitter<TariffPlanViewModel> = new EventEmitter<TariffPlanViewModel>();

  public promocode: string = null;

  constructor() { }

  ngOnInit() {
  }

  public SelectOption(event) {
    this.data.SelectOption(parseInt(event, 10));
    this.data.SelectTime(this.data.selectedTimeId);
  }

  public SelectTime(event) {
    this.data.SelectTime(parseInt(event, 10));
  }

  public CheckPromocode() {
    if (this.promocode && this.promocode.length > 0) {
      this.onCheckPromocode.emit(this.promocode);
    }
  }

  public EnterAnotherPromocode(): void {
    this.promocode = null;
    this.data.promocodeMessage = null;
    this.data.appliedPromocode = null;
  }

  public SelectTariff(): void {
    this.onTarifSelected.emit(this.data);
  }
}
