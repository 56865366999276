import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-budget-panel',
  templateUrl: './budget-panel.component.html',
  styleUrls: ['./budget-panel.component.scss']
})
export class BudgetPanelComponent implements OnInit {

  @Input() public budget: number;
  @Output() public refreshTriggered: EventEmitter<any> = new EventEmitter();

  public budgetInfo = `Для того, чтобы можно было показывать объявление пользователям ВКонтакте,
   необходимо пополнить бюджет вашего рекламного кабинета.
Средства будут списываться когда объявление будет показываться \nпользователям.
`;

  constructor() {
  }

  ngOnInit() {
  }

  public refresh() {
    this.refreshTriggered.emit();
  }
}
