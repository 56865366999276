import { HttpClient } from '@angular/common/http';
import { CustomFieldValueStore } from './custom-field-value.store';
import * as i0 from "@angular/core";
import * as i1 from "./custom-field-value.store";
import * as i2 from "@angular/common/http";
var CustomFieldValueService = /** @class */ (function () {
    function CustomFieldValueService(customFieldValueStore, http) {
        this.customFieldValueStore = customFieldValueStore;
        this.http = http;
    }
    CustomFieldValueService.prototype.get = function () {
        // return of([
        //     {
        //         Id: 1,
        //         Name: 'Название кампании',
        //         Description: 'Вы можете получить название кампании в вашем рекламном кабинете',
        //         Code: '{{CAMPAIGN_NAME}}'
        //     },
        //     {
        //         Id: 2,
        //         Name: 'Название рекламного объявления',
        //         Description: 'Вы можете получить название рекламного объявления в вашем рекламном кабинете',
        //         Code: '{{AD_NAME}}'
        //     }
        // ])
        //     .pipe(
        //         tap(fieldsValues => {
        //             this.customFieldValueStore.set(fieldsValues);
        //         })
        //     )
    };
    CustomFieldValueService.prototype.add = function (customFieldValue) {
        this.customFieldValueStore.add(customFieldValue);
    };
    CustomFieldValueService.prototype.AddRange = function (customFieldValues) {
        this.customFieldValueStore.add(customFieldValues);
    };
    CustomFieldValueService.prototype.update = function (id, customFieldValue) {
        this.customFieldValueStore.update(id, customFieldValue);
    };
    CustomFieldValueService.prototype.remove = function (id) {
        this.customFieldValueStore.remove(id);
    };
    CustomFieldValueService.ngInjectableDef = i0.defineInjectable({ factory: function CustomFieldValueService_Factory() { return new CustomFieldValueService(i0.inject(i1.CustomFieldValueStore), i0.inject(i2.HttpClient)); }, token: CustomFieldValueService, providedIn: "root" });
    return CustomFieldValueService;
}());
export { CustomFieldValueService };
