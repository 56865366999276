import {Component, OnInit} from '@angular/core';
import {StrategiesService} from '../../api/services';
import {Strategy} from '../../api/models';
import {FormControl, FormGroup} from '@angular/forms';
import {AdSourceViewModel} from '../../automation/ad-manager/models/ad-source.view-model';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit {



  ngOnInit(): void {
  }

}
