import { OnInit } from '@angular/core';
import { of } from 'rxjs/internal/observable/of';
import { AdManagerService, UserStrategiesService } from '../../../../../api/services';
import { AdFormManagerService } from '../../../new-ad-manager-shared/services/ad-form-manager/ad-form-manager.service';
var NewCreateAdProComponent = /** @class */ (function () {
    function NewCreateAdProComponent(adFormManagerService, adManagerService, userStrategiesService) {
        this.adFormManagerService = adFormManagerService;
        this.adManagerService = adManagerService;
        this.userStrategiesService = userStrategiesService;
    }
    Object.defineProperty(NewCreateAdProComponent.prototype, "IsLoading", {
        get: function () {
            return this.adFormManagerService.adDataLoading;
        },
        enumerable: true,
        configurable: true
    });
    NewCreateAdProComponent.prototype.ngOnInit = function () {
        this.adFormManagerService.isPro = true;
        this.adFormManagerService.reset();
        this.adFormManagerService.LoadData()
            .subscribe(function (data) {
            console.log(data, 'PRO');
        });
    };
    Object.defineProperty(NewCreateAdProComponent.prototype, "LoadingProgress", {
        get: function () {
            return this.adFormManagerService.loadingProgress;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewCreateAdProComponent.prototype, "LoadingStep", {
        get: function () {
            return this.adFormManagerService.LoadingStepMessage;
        },
        enumerable: true,
        configurable: true
    });
    NewCreateAdProComponent.prototype.CreateAds = function ($event) {
        var _this = this;
        this.adFormManagerService
            .CreateAd()
            .subscribe(function (data) {
        }, function (err) {
            _this.adFormManagerService.loadingCreateAd = false;
            alert('Во время создания объявления произошла ошибка');
        });
    };
    NewCreateAdProComponent.prototype.CreateStrategies = function (createdAdsResponse) {
        if (createdAdsResponse.error_code) {
            return of({});
        }
        else {
            return this.adFormManagerService.CreateStrategy(createdAdsResponse.id);
        }
    };
    NewCreateAdProComponent.prototype.sendModeration = function (createdAdsResponse) {
        var sendModeration = this.adFormManagerService.getControl('moderationData', 'sendModeration').value;
        if (sendModeration) {
            return this.userStrategiesService.ToggleAdStatus({})
                .map(function () { return createdAdsResponse; });
        }
        else {
            return of(createdAdsResponse);
        }
    };
    NewCreateAdProComponent.prototype.startAfterModeration = function (createdAdsResponse) {
        var sendModeration = this.adFormManagerService.getControl('moderationData', 'startAfterModeration').value;
        if (sendModeration) {
            return this.userStrategiesService.ToggleAdStatus({})
                .map(function () { return createdAdsResponse; });
        }
        else {
            return of(createdAdsResponse);
        }
    };
    return NewCreateAdProComponent;
}());
export { NewCreateAdProComponent };
