var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { map as __map, filter as __filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../api-configuration";
import * as i2 from "@angular/common/http";
var VKGroupService = /** @class */ (function (_super) {
    __extends(VKGroupService, _super);
    function VKGroupService(config, http) {
        return _super.call(this, config, http) || this;
    }
    /**
     * @param request undefined
     * @return Success
     */
    VKGroupService.prototype.findByFilterResponse = function (request) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = request;
        var req = new HttpRequest('POST', this.rootUrl + "/apiv2/vk/group/byfilter", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param request undefined
     * @return Success
     */
    VKGroupService.prototype.findByFilter = function (request) {
        return this.findByFilterResponse(request).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `VKGroupService.GetMembersHistoryParams` containing the following parameters:
     *
     * - `sortDescend`:
     *
     * - `id`:
     *
     * - `to`:
     *
     * - `from`:
     *
     * @return Success
     */
    VKGroupService.prototype.getMembersHistoryResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.sortDescend != null)
            __params = __params.set('sortDescend', params.sortDescend.toString());
        if (params.id != null)
            __params = __params.set('id', params.id.toString());
        if (params.to != null)
            __params = __params.set('to', params.to.toString());
        if (params.from != null)
            __params = __params.set('from', params.from.toString());
        var req = new HttpRequest('POST', this.rootUrl + "/apiv2/vk/group/membershistory", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `VKGroupService.GetMembersHistoryParams` containing the following parameters:
     *
     * - `sortDescend`:
     *
     * - `id`:
     *
     * - `to`:
     *
     * - `from`:
     *
     * @return Success
     */
    VKGroupService.prototype.getMembersHistory = function (params) {
        return this.getMembersHistoryResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param id undefined
     * @return Success
     */
    VKGroupService.prototype.getGroupPostAnalyticsResponse = function (id) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (id != null)
            __params = __params.set('id', id.toString());
        var req = new HttpRequest('POST', this.rootUrl + "/apiv2/vk/group/postanalytics", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param id undefined
     * @return Success
     */
    VKGroupService.prototype.getGroupPostAnalytics = function (id) {
        return this.getGroupPostAnalyticsResponse(id).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `VKGroupService.GetGroupEngagementAnalyticsParams` containing the following parameters:
     *
     * - `sortDescend`:
     *
     * - `id`:
     *
     * - `to`:
     *
     * - `from`:
     *
     * @return Success
     */
    VKGroupService.prototype.getGroupEngagementAnalyticsResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.sortDescend != null)
            __params = __params.set('sortDescend', params.sortDescend.toString());
        if (params.id != null)
            __params = __params.set('id', params.id.toString());
        if (params.to != null)
            __params = __params.set('to', params.to.toString());
        if (params.from != null)
            __params = __params.set('from', params.from.toString());
        var req = new HttpRequest('POST', this.rootUrl + "/apiv2/vk/group/engagementanalytics", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `VKGroupService.GetGroupEngagementAnalyticsParams` containing the following parameters:
     *
     * - `sortDescend`:
     *
     * - `id`:
     *
     * - `to`:
     *
     * - `from`:
     *
     * @return Success
     */
    VKGroupService.prototype.getGroupEngagementAnalytics = function (params) {
        return this.getGroupEngagementAnalyticsResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    VKGroupService.findByFilterPath = '/apiv2/vk/group/byfilter';
    VKGroupService.getMembersHistoryPath = '/apiv2/vk/group/membershistory';
    VKGroupService.getGroupPostAnalyticsPath = '/apiv2/vk/group/postanalytics';
    VKGroupService.getGroupEngagementAnalyticsPath = '/apiv2/vk/group/engagementanalytics';
    VKGroupService.ngInjectableDef = i0.defineInjectable({ factory: function VKGroupService_Factory() { return new VKGroupService(i0.inject(i1.ApiConfiguration), i0.inject(i2.HttpClient)); }, token: VKGroupService, providedIn: "root" });
    return VKGroupService;
}(__BaseService));
export { VKGroupService };
