import {Component, Input, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {RetargetingGroup} from '../../../../stores/retargeting-groups/retargeting-group.model';
import {AkitaNgFormsManager} from "@datorama/akita-ng-forms-manager";

@Component({
  selector: 'app-pipeline-status-selector',
  templateUrl: './pipeline-status-selector.component.html',
  styleUrls: ['./pipeline-status-selector.component.scss']
})
export class PipelineStatusSelectorComponent implements OnInit {

  @Input() public statusName: string;
  @Input() public control: FormControl;
  @Input() public options: RetargetingGroup[];
  @Input() public selectedRemarketingGroups: number[] = [];
  @Input() public suggestionCode: string = '';
  private selectedGroups: number[];

  constructor(
    private fm: AkitaNgFormsManager
  ) {
  }

  ngOnInit() {
    this.fm.selectValue('destinationPlatformSettings')
      .subscribe(data => {
        this.selectedGroups = this.GetSelectedGroups(data.pipeline_statuses);
      })
  }

  private GetSelectedGroups(pipeline_statuses) {
    if (pipeline_statuses) {
      const groups: number[] = Object.keys(pipeline_statuses)
        .map(key => pipeline_statuses[key].pipeline_retargeting_groups)
        .filter(x => x !== null)
        .reduce((arr, curr) => arr.concat(curr), []);

      return Array.from(new Set(groups));
    } else {
      return [];
    }
  }

  public isOptionDisabled(remarketingGroupId) {
    return this.ChekedForCountOfSelectedMore2() && this.CheckForIncludingGroupInSelected(remarketingGroupId);
  }

  private CheckForIncludingGroupInSelected(remarketingGroupId) {
    const status = !this.selectedGroups.includes(remarketingGroupId);
    return status;
  }

  private ChekedForCountOfSelectedMore2() {
    const status = this.selectedGroups && this.selectedGroups.length >= 10;
    return status;
  }
}
