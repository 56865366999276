<div class="promopost">
  <div class="promopost__header">
    <img [src]="GetPromosotGroupAvatar()" class="promopost__avatar">
    <div class="promopost-meta-data">
      <div class="promopost__group-title">
        {{GetPromosotGroupName()}}
      </div>
      <div class="promopost__date">Рекламная запись</div>
    </div>
  </div>
 <div class="promopost__content">
   <ng-container *ngIf="promopost && promopost.text; else noText">
     {{PromopostText}}
     <div class="promopost__text" [innerHtml]="PromopostText"></div>
   </ng-container>
   <ng-template #noText>
     <div class="placeholder-text">Вводите текст и он будет появляться здесь...</div>
   </ng-template>
   <div class="promopost__image-wrapper">
     <div class="promopost__image" [ngStyle]="{
      'background-image': Image
     }">
       <!--<img [src]="" class="promopost__image" />-->
       <div class="promopost-image__remove-button" (click)="removeImage()">
         <mat-icon>close</mat-icon>
       </div>
     </div>
     <div class="promopost__link-panel link-panel">
       <div class="link-panel__title">
         <ng-container *ngIf="editLinkTitle; else titleView">
           <div class="fx-row link-panel__form">
             <input class="vk-input vk-input_full-width" [(ngModel)]="editLinkTitleValue" maxlength="56"/>
             <button class="vk-standart-button vk-standart-button_sm" (click)="SaveLinkTitle()" [style.minWidth]="'100px'"  [style.lineHeight]="'10px'">Сохранить</button>
           </div>
         </ng-container>
         <ng-template #titleView>
           {{GetPromopostTitle()}}
           <button mat-icon-button class="icon-button" (click)="EnableLinkTitleEditor()" *ngIf="showEditForm">
             <mat-icon class="mat-icon">edit</mat-icon>
           </button>
         </ng-template>
       </div>
       <div class="link-panel__domain">{{GetPromopostAddress()}}</div>
     </div>
   </div>
 </div>
  <div class="promopost__footer">
    <div class="promopost-footer-item">
      <clr-icon shape="heart" size="20"></clr-icon>
    </div>
    <div class="promopost-footer-item">
      <clr-icon shape="volume-down" size="20"></clr-icon>
    </div>
  </div>
</div>
