import { OnInit } from '@angular/core';
import { FormControl } from "@angular/forms";
import { IntegrationFormService } from "../../../services/integration-form.service";
import { StoreService } from "../../../../../services/store.service";
import { distinctUntilChanged } from "rxjs/operators";
var MtRemarketingGroupsComponent = /** @class */ (function () {
    function MtRemarketingGroupsComponent(integrationFormService, store) {
        this.integrationFormService = integrationFormService;
        this.store = store;
        this.accountControl = new FormControl();
        this.accounts = [];
        this.remarketingGroups = [];
        this.accountsLoading = false;
        this.searchTargetGroups = new FormControl();
        this.pipelineStatuses = [
            {
                id: 1,
                name: 'Первичный контакт'
            },
            {
                id: 2,
                name: 'Согласование'
            },
            {
                id: 3,
                name: 'Сделка успешна'
            },
            {
                id: 4,
                name: 'Сделка сорвалась'
            }
        ];
        this.sortByPipelines = new FormControl(false);
    }
    Object.defineProperty(MtRemarketingGroupsComponent.prototype, "useSortingByPipelines", {
        get: function () {
            return this.sortByPipelines.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MtRemarketingGroupsComponent.prototype, "filteredRetargetingGroup", {
        get: function () {
            var search = this.searchTargetGroups.value;
            if (search && search !== '') {
                return this.remarketingGroups.filter(function (x) { return x.name.match(search); });
            }
            else {
                return this.remarketingGroups;
            }
        },
        enumerable: true,
        configurable: true
    });
    MtRemarketingGroupsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.form = this.integrationFormService.formService.getControl('mtRemarketingGroups');
        this.LoadAccounts();
        this.accountControl.valueChanges
            .pipe(distinctUntilChanged())
            .subscribe(function (account) {
            _this.LoadRemarketingGroups(account);
        });
    };
    MtRemarketingGroupsComponent.prototype.LoadAccounts = function () {
        var _this = this;
        this.accountsLoading = true;
        this.store.LoadMtAccounts().subscribe(function (accounts) {
            _this.accounts = accounts;
            _this.accountsLoading = false;
        });
    };
    MtRemarketingGroupsComponent.prototype.LoadRemarketingGroups = function (account) {
        var _this = this;
        this.store.LoadMtRemarketingGroups(account.id)
            .subscribe(function (groups) {
            _this.remarketingGroups = groups;
        });
    };
    return MtRemarketingGroupsComponent;
}());
export { MtRemarketingGroupsComponent };
