import { OnInit } from '@angular/core';
// import {ServicesFacade} from '../../../../store/facades/services.facade';
import { StoreService } from "../../../../services/store.service";
import { IntegrationFormService } from "../../services/integration-form.service";
import { IntegrationService, UserIntegrationService } from "../../../../../api/services";
import { IntegrationConfigurator } from "../../configurators/Integration-configurator";
import { MatDialogRef } from "@angular/material";
var IntegrationCreateEditFormComponent = /** @class */ (function () {
    function IntegrationCreateEditFormComponent(store, formsService, integrationService, userIntegrationService, dialogRef) {
        this.store = store;
        this.formsService = formsService;
        this.integrationService = integrationService;
        this.userIntegrationService = userIntegrationService;
        this.dialogRef = dialogRef;
    }
    Object.defineProperty(IntegrationCreateEditFormComponent.prototype, "leftServices", {
        get: function () {
            return (this.store.services || []).filter(function (x) { return x.isDataOrigin; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IntegrationCreateEditFormComponent.prototype, "isOriginServiceSelected", {
        get: function () {
            return this.store.IsOriginServiceSelected;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IntegrationCreateEditFormComponent.prototype, "rightServices", {
        get: function () {
            var _this = this;
            var relations = (this.store.relations || []).filter(function (x) { return x.fromService.code === _this.formsService.form.get('leftService').value; }).map(function (x) { return x.toService.code; });
            var rightServices = (this.store.services || []).filter(function (x) { return relations.includes(x.code); });
            return rightServices;
        },
        enumerable: true,
        configurable: true
    });
    IntegrationCreateEditFormComponent.prototype.ngOnInit = function () {
        // this.leftServices$ = this.services.leftServices$;
        // this.rightServices$ = this.services.rightServices$;
    };
    IntegrationCreateEditFormComponent.prototype.SaveIntegrations = function () {
        var _this = this;
        var data = this.formsService.form.value;
        console.log(data);
        this.userIntegrationService.AddUserIntegration({
            fromId: this.store.services.find(function (x) { return x.code === data.leftService; }).integrationServiceId,
            toId: this.store.services.find(function (x) { return x.code === data.rightService; }).integrationServiceId,
            model: {
                name: data.name,
                originServiceData: new IntegrationConfigurator(data.leftService, data).configure(),
                destinationServiceData: new IntegrationConfigurator(data.rightService, data).configure()
            }
        })
            .subscribe(function (data) {
            _this.dialogRef.close(true);
        });
        console.log();
    };
    return IntegrationCreateEditFormComponent;
}());
export { IntegrationCreateEditFormComponent };
