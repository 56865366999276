import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ad-manager-middleware',
  templateUrl: './ad-manager-middleware.component.html',
  styleUrls: ['./ad-manager-middleware.component.scss']
})
export class AdManagerMiddlewareComponent implements OnInit {

  constructor() {
    localStorage.setItem('dashboard-redirect-route', '/automation/ad-manager/create');
  }

  ngOnInit() {
  }

}
