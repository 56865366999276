<clr-datagrid>
  <clr-dg-action-bar>
    <a [routerLink]="['/admin/blog/posts/create']" class="btn btn-primary">Создать</a>
    <button class="btn btn-success" (click)="GenerateSitemap()">Сгенерировать sitemap.xml</button>
  </clr-dg-action-bar>

  <clr-dg-column clrDgField="title">Название</clr-dg-column>
  <clr-dg-column clrDgField="createdAt">Дата создания</clr-dg-column>
  <clr-dg-column clrDgField="updatedAt">Дата обновления</clr-dg-column>
  <clr-dg-column clrDgField="isPublished">Опубликован</clr-dg-column>
  <clr-dg-column>Функции</clr-dg-column>

  <clr-dg-row *clrDgItems="let post of posts">
    <clr-dg-cell>
      <a [routerLink]="'./' + post.postId">{{post.title}}</a>
    </clr-dg-cell>
    <clr-dg-cell>{{post.createdAt | date}}</clr-dg-cell>
    <clr-dg-cell>{{post.updatedAt | date}}</clr-dg-cell>
    <clr-dg-cell>
      <input type="checkbox" [checked]="post.isPublished" disabled>
    </clr-dg-cell>
    <clr-dg-cell>
      <button class="btn btn-sm" [ngClass]="{
        'btn-primary': !post.isPublished,
        'btn-danger': post.isPublished
      }" (click)="TogglePublication(post)">
        <ng-container *ngIf="!post.isPublished; else isPub">Опубликовать</ng-container>
      </button>
    </clr-dg-cell>
  </clr-dg-row>
</clr-datagrid>
<ng-template #isPub>Снять с публикации</ng-template>
