import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AdNetworkFormsQuery} from "../../../../stores/ad-network-forms/ad-network-forms.query";
import {untilDestroyed} from "ngx-take-until-destroy";
import {PlatformsQuery} from "../../../../stores/platforms/platforms.query";
import {IntegrationPlatformSection} from "../../../../../api/models/integration-platform-section";
import {AdNetworkForm} from "../../../../stores/ad-network-forms/ad-network-form.model";
import {AkitaAbstractGroup, AkitaNgFormsManager} from "@datorama/akita-ng-forms-manager";
import {combineLatest, forkJoin} from "rxjs";
import {unionAllArraysFromObjectsToArray} from "../../../../../utils/js/arrays";
import {PlatformsService, PlatformTypes} from "../../../../stores/platforms/platforms.service";
import {PlatformsTypes} from "../../../../platforms-types";
import {AbstractControl, FormControl, FormGroup, Validators} from "@angular/forms";
import {environment} from "../../../../../../environments/environment";
import {CrmUsersService} from "../../../../stores/crm-users/crm-users.service";
import {CrmPipelinesService} from "../../../../stores/crm-pipelines/crm-pipelines.service";
import {CrmUsersQuery} from "../../../../stores/crm-users/crm-users.query";
import {CrmPipelinesQuery} from "../../../../stores/crm-pipelines/crm-pipelines.query";
import {CrmUser} from "../../../../stores/crm-users/crm-user.model";
import {NewImportCrmService} from "../../services/new-import-crm/new-import-crm.service";
import {CrmFieldOptionModel} from "../../models/crm-field-option.model";
import {CrmPipeline} from "../../../../stores/crm-pipelines/crm-pipeline.model";
import {distinctUntilChanged, tap} from "rxjs/operators";
import {CustomFieldsQuery} from "../../../../stores/custom-fields/custom-fields.query";
import {getEntityType} from "@datorama/akita";
import {CustomFieldsState} from "../../../../stores/custom-fields/custom-fields.store";
import {CustomField} from "../../../../stores/custom-fields/custom-field.model";
import {isNullOrUndefined} from "util";
import {PlatformAssignationsQuery} from "../../../../stores/platform-assignations/platform-assignations.query";

@Component({
  selector: 'app-new-import-crm',
  templateUrl: './new-import-crm.component.html',
  styleUrls: ['./new-import-crm.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewImportCrmComponent implements OnInit, OnDestroy {

  @Input() type: PlatformsTypes;

  public formGroup = new FormGroup({});
  public originPlatformCode$ = this.platformsQuery.selectedOriginPlatformCode$
    .pipe(
      tap(originCode => this.originCode = originCode),
      untilDestroyed(this)
    );
  public destinationPlatformCode$ = this.platformsQuery.selectedDestinationPlatformCode$
    .pipe(
      tap(destinationCode => this.destinationCode = destinationCode),
      untilDestroyed(this)
    );
  public selectedForms$ = this.formsQuery.selectedForms
    .pipe(untilDestroyed(this));
  public destinationPlatformSections$ = this.platformsQuery.destinationPlatformSections$
    .pipe(untilDestroyed(this));
  public crmUsers$ = this.crmUsersQuery.users$
    .pipe(untilDestroyed(this));
  public crmPipelines$ = this.crmPipelinesQuery.pipelines$
    .pipe(untilDestroyed(this));
  public advancedSettingsFields = null;

  public forms: AdNetworkForm[] = [];
  public platformSections: IntegrationPlatformSection[] = [];
  private originCode: string;
  private destinationCode: string;

  constructor(
    private formsQuery: AdNetworkFormsQuery,
    private platformsQuery: PlatformsQuery,
    private cdr: ChangeDetectorRef,
    private fm: AkitaNgFormsManager,
    private crmUsersService: CrmUsersService,
    private crmPipelinesService: CrmPipelinesService,
    private crmUsersQuery: CrmUsersQuery,
    private crmPipelinesQuery: CrmPipelinesQuery,
    private newImportCrmService: NewImportCrmService,
    private customFieldsQuery: CustomFieldsQuery,
    private platformService: PlatformsService,
    private assginationsQuery: PlatformAssignationsQuery
  ) {
  }

  ngOnInit() {
    this.selectedForms$
      .subscribe((forms) => {
        this.forms = forms;
        this.cdr.markForCheck();
      });

    this.destinationPlatformSections$
      .subscribe(sections => {
        this.platformSections = sections;
        this.cdr.markForCheck();
      })

    this.destinationPlatformCode$.subscribe()

    this.fm.selectValue('originPlatformSettings')
      .subscribe((originPlatformSettings) => {
        combineLatest(
          this.originPlatformCode$,
          this.destinationPlatformSections$,
          this.customFieldsQuery.CustomFields$
        )
          .subscribe(([originPlatformCode, sections, customFields]) => {
            console.log('DATA LOG', originPlatformSettings);
            this.initializeForm(originPlatformSettings, originPlatformCode, sections, customFields)
          })
      })

    this.crmUsers$.subscribe((users: CrmUser[]) => {
      const usersOptions: CrmFieldOptionModel[] = users.map(usr => ({
        Key: usr.id.toString(),
        Label: usr.name
      }));
      this.newImportCrmService.SetUsersOptions(usersOptions);
    })

    this.crmPipelines$.subscribe((pipelines: CrmPipeline[]) => {
      const pipelinesOptions: CrmFieldOptionModel[] = pipelines.map(pipeline => ({
        Key: pipeline.id.toString(),
        Label: pipeline.name
      }));
      this.newImportCrmService.SetPipelinesOptions(pipelinesOptions);
    })

    this.advancedSettingsFields = this.newImportCrmService.GetAdvancedSettings(this.originCode, this.destinationCode);

    this.assginationsQuery
      .isPlatformAssigned$(this.type)
      .pipe(
        distinctUntilChanged(),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.crmUsersService.LoadUsers(this.type)
        this.crmPipelinesService.LoadPipelines(this.type)
        this.newImportCrmService.LoadData();
      })
  }

  ngOnDestroy(): void {
  }

  private initializeForm(originPlatformSettings: any, originPlatformCode: string, sections: IntegrationPlatformSection[], customFields: CustomField[]) {
    this.platformService.ClearRequiredFields();
    const selectedFormIds = this.getSelectedFormIds(originPlatformSettings, originPlatformCode);
    console.log(environment.integrations.destinationFormKey, 'FORM KEY', this.fm.getForm(environment.integrations.destinationFormKey));
    const form = this.fm.getForm(environment.integrations.destinationFormKey);
    const destinationForm = new FormGroup({});
    for (const formId of selectedFormIds) {
      const selectedFormGroup = new FormGroup({
        includeCampaignName: new FormControl(this.getPreviousFormValue(form, formId, 'includeCampaignName')),
        includeLeadFormName: new FormControl(this.getPreviousFormValue(form, formId, 'includeLeadFormName')),
        bindContact: new FormControl(this.getPreviousFormValue(form, formId, 'bindContact'))
      })

      for (const section of sections) {
        const selected = this.GetSelectionSectionStatus(form, formId, section);
        console.log(selected, section.isRequired, 'SELECTED');
        const sectionForm = new FormGroup({
          selected: new FormControl(isNullOrUndefined(selected) ? section.isRequired : selected, section.isRequired ? [Validators.required] : []),
          fields: new FormGroup({}),
          customFields: new FormGroup({})
        })
        for (const field of section.fields) {
          const fieldValue = this.GetFieldValue(formId, section.code, 'fields', field.code, form);
          console.log(fieldValue);
          const fieldValidators = [];
          if (field.isRequired) {
            this.platformService.AddRequiredField(`${formId}_${section.code}_${field.code}`);
            fieldValidators.push(Validators.required)
          }
          (sectionForm.get('fields') as FormGroup).addControl(field.code, new FormControl(fieldValue, fieldValidators))
        }
        for (const customField of customFields) {
          const fieldValue = this.GetFieldValue(formId, section.code, 'customFields', customField.Key, form);
          (sectionForm.get('customFields') as FormGroup).addControl(customField.Key, new FormControl(fieldValue, []))
        }
        console.log(sectionForm, 'SECTION FORM GROUP');
        selectedFormGroup.addControl(section.code, sectionForm);
      }
      destinationForm.addControl(formId, selectedFormGroup);
    }
    this.fm.remove(environment.integrations.destinationFormKey);
    this.fm.upsert(environment.integrations.destinationFormKey, destinationForm);
    console.log(destinationForm, 'FORM WORLD', selectedFormIds);
    this.formGroup = destinationForm;
    console.log(originPlatformSettings, originPlatformCode, 'INITIALIZE FORM');
  }

  private GetSelectionSectionStatus(form: AkitaAbstractGroup<any>, formId, section: IntegrationPlatformSection) {
    let value = null;
    if (form) {
      const formValue = form.value;
      if (formValue && formValue[formId] && formValue[formId][section.code]) {
        value = formValue[formId][section.code].selected;
      }
    }
    return value;
  }

  private getPreviousFormValue(previousForm: AkitaAbstractGroup<any>, formId, selectedFormFieldKey: any) {
    if (previousForm) {
      const form = previousForm.value[formId];
      if (form) {
        return form[selectedFormFieldKey];
      }
    }
    return null;
  }

  /**
   * Возвращает массив id выделенных форм (в левой части конфигуратора)
   * @param originPlatformSettings - настройки левой части конфигуратора
   * @param originPlatformCode - код выбранной в левой части платформы
   * @private
   */
  private getSelectedFormIds(originPlatformSettings: any, originPlatformCode) {
    let formIds = [];
    if (originPlatformCode === PlatformsTypes.MT_LEAD_FORMS) {
      const originPlatformSettingsKeys = Object.keys(originPlatformSettings);
      for (const originPlatformSettingsKey of originPlatformSettingsKeys) {
        if (originPlatformSettings[originPlatformSettingsKey]) {
          formIds.push(originPlatformSettingsKey)
        }
      }
    } else {
      formIds = unionAllArraysFromObjectsToArray(originPlatformSettings)
    }
    return formIds;
  }

  /**
   * Получить значение поля из формы
   * @param formId - id формы
   * @param sectionCode - код секции
   * @param fieldsSection
   * @param fieldCode - код поля
   * @param form - глобальная форма
   * @constructor
   * @private
   */
  private GetFieldValue(formId: any, sectionCode: string, fieldsSection: string, fieldCode: string, form: AkitaAbstractGroup<any>) {
    let value = '';
    // console.log('GetFieldValue: form', form)
    if (form) {
      const formValue = form.value;
      // console.log('GetFieldValue: value', formId, sectionCode, fieldCode, value);
      if (formValue && formValue[formId] && formValue[formId][sectionCode] && formValue[formId][sectionCode][fieldsSection][fieldCode]) {
        // console.log(`GetFieldValue: formValue`, formValue);
        // console.log(`GetFieldValue: formValue[${formId}]`, formValue[formId]);
        // console.log(`GetFieldValue: formValue[${formId}][${sectionCode}]`, formValue[formId][sectionCode]);
        // console.log(`GetFieldValue: formValue[${formId}][${sectionCode}][${fieldsSection}]`, formValue[formId][sectionCode][fieldsSection]);
        // console.log(`GetFieldValue: formValue[${formId}][${sectionCode}][${fieldsSection}][${fieldCode}]`, formValue[formId][sectionCode][fieldsSection][fieldCode]);
        value = formValue[formId][sectionCode][fieldsSection][fieldCode];
      }
    }
    return value;
  }

  GetFormGroup(id: string) {
    return this.formGroup.get(id) as FormGroup;
  }
}
