import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {environment} from '../../../../environments/environment';
import {IMyDate, IMyDateModel} from 'ngx-mydatepicker';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatepickerComponent),
      multi: true
    }
  ]
})
export class DatepickerComponent implements OnInit, ControlValueAccessor {

  public value: any;
  public disabled: boolean = false;
  public onChange;
  public onTouch;
  public options = environment.ngxMyDatePickerConfig;

  @Output() dateChangedFromComponent: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: Date): void {
    this.value = {jsdate: obj};
  }

  onDateChanged($event) {
    this.onChange($event.jsdate);
    this.dateChangedFromComponent.emit();
  }

  ngOnInit(): void {
  }

  // public OnChangeHour($event) {
  //   this.value.jsdate.setHours($event);
  // }
  //
  // public OnChangeMinute($event) {
  //   this.value.jsdate.setMinutes($event);
  // }
}
