/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { EquinoxStudiosExternalAPIProviderYandexAudienceModelsSegmentsSegmentResponseWrapper } from '../models/equinox-studios-external-apiprovider-yandex-audience-models-segments-segment-response-wrapper';
@Injectable({
  providedIn: 'root',
})
class YandexAudienceSegmentService extends __BaseService {
  static readonly YandexAudienceGetSegmentsPath = '/apiv2/yandex/audience/segment';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param pixelId undefined
   * @return Success
   */
  YandexAudienceGetSegmentsResponse(pixelId?: string): __Observable<__StrictHttpResponse<EquinoxStudiosExternalAPIProviderYandexAudienceModelsSegmentsSegmentResponseWrapper>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (pixelId != null) __params = __params.set('pixelId', pixelId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/yandex/audience/segment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EquinoxStudiosExternalAPIProviderYandexAudienceModelsSegmentsSegmentResponseWrapper>;
      })
    );
  }
  /**
   * @param pixelId undefined
   * @return Success
   */
  YandexAudienceGetSegments(pixelId?: string): __Observable<EquinoxStudiosExternalAPIProviderYandexAudienceModelsSegmentsSegmentResponseWrapper> {
    return this.YandexAudienceGetSegmentsResponse(pixelId).pipe(
      __map(_r => _r.body as EquinoxStudiosExternalAPIProviderYandexAudienceModelsSegmentsSegmentResponseWrapper)
    );
  }
}

module YandexAudienceSegmentService {
}

export { YandexAudienceSegmentService }
