import { EventEmitter, OnInit } from '@angular/core';
import { AdFormManagerService } from '../../../new-ad-manager-shared/services/ad-form-manager/ad-form-manager.service';
var AdFormProComponent = /** @class */ (function () {
    function AdFormProComponent(adFormManagerService) {
        this.adFormManagerService = adFormManagerService;
        this.onSave = new EventEmitter();
    }
    Object.defineProperty(AdFormProComponent.prototype, "slots", {
        get: function () {
            return this.adFormManagerService.slots;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdFormProComponent.prototype, "accounts", {
        get: function () {
            return this.adFormManagerService.accounts
                .filter(function (account) { return account.account_role === 'admin'; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdFormProComponent.prototype, "IsCampaignsValid", {
        get: function () {
            return this.adFormManagerService.IsValidCampaignData();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdFormProComponent.prototype, "IsPromopost", {
        get: function () {
            return this.adFormManagerService.IsSelectFormatPromopost;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdFormProComponent.prototype, "IsUpdate", {
        get: function () {
            return this.adFormManagerService.IsUpdate;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdFormProComponent.prototype, "IsAdLayoutDirty", {
        get: function () {
            return this.adFormManagerService.IsAdLayoutDirty();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdFormProComponent.prototype, "IsValidAd", {
        get: function () {
            return this.adFormManagerService.IsValidaAd();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdFormProComponent.prototype, "IsValidTargeting", {
        get: function () {
            return this.adFormManagerService.IsValidTargeting();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdFormProComponent.prototype, "ShowActiveAccounts", {
        get: function () {
            return this.adFormManagerService.ShowActiveAccounts();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdFormProComponent.prototype, "errors", {
        get: function () {
            return this.adFormManagerService.getErrors();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdFormProComponent.prototype, "createAdLoading", {
        get: function () {
            return this.adFormManagerService.loadingCreateAd;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdFormProComponent.prototype, "HasAssignedAccounts", {
        get: function () {
            return this.adFormManagerService.HasAssignedAccounts();
        },
        enumerable: true,
        configurable: true
    });
    AdFormProComponent.prototype.ngOnInit = function () {
        this.adFormManagerService.EnableProMode();
    };
    AdFormProComponent.prototype.CreateAd = function () {
        this.onSave.emit(this.adFormManagerService.getSpecifications());
    };
    AdFormProComponent.prototype.Bindend = function () {
        this.adFormManagerService.LoadAdCabinetData()
            .subscribe(function () {
            console.log('acounts loaded');
        });
    };
    return AdFormProComponent;
}());
export { AdFormProComponent };
