import {Component, Input, OnInit} from '@angular/core';
import {ControlContainer} from '@angular/forms';

@Component({
  selector: 'app-teaser-form-lite',
  templateUrl: './teaser-form-lite.component.html',
  styleUrls: ['./teaser-form-lite.component.scss']
})
export class TeaserFormLiteComponent implements OnInit {

  @Input() public groups: Array<any>;
  @Input() public adTargetResource: string;
  @Input() public isUpdating: boolean = false;

  public labelWidth = '200px';
  public controlWidth = '256px';

  public get ShowGroupControls() {
    return this.adTargetResource === 'vk_group';
  }

  constructor(
    public controlContainer: ControlContainer
  ) {
  }

  ngOnInit() {
    console.log(this.controlContainer);
  }

  SetTeaserImage(imageContent: string) {
    this.controlContainer.control.get('image').setValue(imageContent);
  }

  StartImageErrorTimer() {
    setTimeout(() => {
      const imageControl = this.controlContainer.control.get('image');
      imageControl.markAsDirty();
    }, 10000);
  }

  public CountOfOpenedGroups() {
    let countOfOpenedGroups = 0;
    if (this.groups && this.groups.length > 0) {
      const openedGroups = this.groups.filter(group => group.is_closed === 0);
      countOfOpenedGroups = openedGroups.length;
    }

    return countOfOpenedGroups;
  }
}
