var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { map as __map, filter as __filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../api-configuration";
import * as i2 from "@angular/common/http";
var BitrixService = /** @class */ (function (_super) {
    __extends(BitrixService, _super);
    function BitrixService(config, http) {
        return _super.call(this, config, http) || this;
    }
    /**
     * @param credentials undefined
     */
    BitrixService.prototype.AuthorizeAsyncResponse = function (credentials) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = credentials;
        var req = new HttpRequest('POST', this.rootUrl + "/apiv2/bitrix/auth", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param credentials undefined
     */
    BitrixService.prototype.AuthorizeAsync = function (credentials) {
        return this.AuthorizeAsyncResponse(credentials).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @return Success
     */
    BitrixService.prototype.GetCrmSettingsModeResponse = function () {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/bitrix/crm-settings-mode", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @return Success
     */
    BitrixService.prototype.GetCrmSettingsMode = function () {
        return this.GetCrmSettingsModeResponse().pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param start undefined
     * @return Success
     */
    BitrixService.prototype.GetDealCategoryListResponse = function (start) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (start != null)
            __params = __params.set('start', start.toString());
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/bitrix/deal-category-list", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param start undefined
     * @return Success
     */
    BitrixService.prototype.GetDealCategoryList = function (start) {
        return this.GetDealCategoryListResponse(start).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @return Success
     */
    BitrixService.prototype.GetDealCategoryDefaultResponse = function () {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/bitrix/deal-category-default", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @return Success
     */
    BitrixService.prototype.GetDealCategoryDefault = function () {
        return this.GetDealCategoryDefaultResponse().pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param start undefined
     * @return Success
     */
    BitrixService.prototype.GetUsersResponse = function (start) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (start != null)
            __params = __params.set('start', start.toString());
        var req = new HttpRequest('GET', this.rootUrl + "/apiv2/bitrix/users", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param start undefined
     * @return Success
     */
    BitrixService.prototype.GetUsers = function (start) {
        return this.GetUsersResponse(start).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param filterData undefined
     * @return Success
     */
    BitrixService.prototype.GetLeadsResponse = function (filterData) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = filterData;
        var req = new HttpRequest('POST', this.rootUrl + "/apiv2/bitrix/get-leads", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param filterData undefined
     * @return Success
     */
    BitrixService.prototype.GetLeads = function (filterData) {
        return this.GetLeadsResponse(filterData).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param filterData undefined
     * @return Success
     */
    BitrixService.prototype.GetDealsResponse = function (filterData) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = filterData;
        var req = new HttpRequest('POST', this.rootUrl + "/apiv2/bitrix/get-deals", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param filterData undefined
     * @return Success
     */
    BitrixService.prototype.GetDeals = function (filterData) {
        return this.GetDealsResponse(filterData).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param lead undefined
     * @return Success
     */
    BitrixService.prototype.CreateLeadResponse = function (lead) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = lead;
        var req = new HttpRequest('POST', this.rootUrl + "/apiv2/bitrix/create-lead", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param lead undefined
     * @return Success
     */
    BitrixService.prototype.CreateLead = function (lead) {
        return this.CreateLeadResponse(lead).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param deal undefined
     * @return Success
     */
    BitrixService.prototype.CreateDealResponse = function (deal) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = deal;
        var req = new HttpRequest('POST', this.rootUrl + "/apiv2/bitrix/create-deal", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param deal undefined
     * @return Success
     */
    BitrixService.prototype.CreateDeal = function (deal) {
        return this.CreateDealResponse(deal).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param filterData undefined
     * @return Success
     */
    BitrixService.prototype.GetTasksResponse = function (filterData) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = filterData;
        var req = new HttpRequest('POST', this.rootUrl + "/apiv2/bitrix/get-tasks", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param filterData undefined
     * @return Success
     */
    BitrixService.prototype.GetTasks = function (filterData) {
        return this.GetTasksResponse(filterData).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param task undefined
     * @return Success
     */
    BitrixService.prototype.CreateTaskResponse = function (task) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = task;
        var req = new HttpRequest('POST', this.rootUrl + "/apiv2/bitrix/create-task", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param task undefined
     * @return Success
     */
    BitrixService.prototype.CreateTask = function (task) {
        return this.CreateTaskResponse(task).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param contact undefined
     * @return Success
     */
    BitrixService.prototype.CreateContactResponse = function (contact) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = contact;
        var req = new HttpRequest('POST', this.rootUrl + "/apiv2/bitrix/create-contact", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param contact undefined
     * @return Success
     */
    BitrixService.prototype.CreateContact = function (contact) {
        return this.CreateContactResponse(contact).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param filterData undefined
     * @return Success
     */
    BitrixService.prototype.GetContactsResponse = function (filterData) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = filterData;
        var req = new HttpRequest('POST', this.rootUrl + "/apiv2/bitrix/get-contacts", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param filterData undefined
     * @return Success
     */
    BitrixService.prototype.GetContacts = function (filterData) {
        return this.GetContactsResponse(filterData).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param filterData undefined
     */
    BitrixService.prototype.Apiv2BitrixGetActivitiesPostResponse = function (filterData) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = filterData;
        var req = new HttpRequest('POST', this.rootUrl + "/apiv2/bitrix/get-activities", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param filterData undefined
     */
    BitrixService.prototype.Apiv2BitrixGetActivitiesPost = function (filterData) {
        return this.Apiv2BitrixGetActivitiesPostResponse(filterData).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param activity undefined
     */
    BitrixService.prototype.Apiv2BitrixCreateActivityPostResponse = function (activity) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = activity;
        var req = new HttpRequest('POST', this.rootUrl + "/apiv2/bitrix/create-activity", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param activity undefined
     */
    BitrixService.prototype.Apiv2BitrixCreateActivityPost = function (activity) {
        return this.Apiv2BitrixCreateActivityPostResponse(activity).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `BitrixService.GetCrmStatusesParams` containing the following parameters:
     *
     * - `start`:
     *
     * - `filterData`:
     *
     * @return Success
     */
    BitrixService.prototype.GetCrmStatusesResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (params.start != null)
            __params = __params.set('start', params.start.toString());
        __body = params.filterData;
        var req = new HttpRequest('POST', this.rootUrl + "/apiv2/bitrix/get-crm-statuses", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `BitrixService.GetCrmStatusesParams` containing the following parameters:
     *
     * - `start`:
     *
     * - `filterData`:
     *
     * @return Success
     */
    BitrixService.prototype.GetCrmStatuses = function (params) {
        return this.GetCrmStatusesResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    BitrixService.AuthorizeAsyncPath = '/apiv2/bitrix/auth';
    BitrixService.GetCrmSettingsModePath = '/apiv2/bitrix/crm-settings-mode';
    BitrixService.GetDealCategoryListPath = '/apiv2/bitrix/deal-category-list';
    BitrixService.GetDealCategoryDefaultPath = '/apiv2/bitrix/deal-category-default';
    BitrixService.GetUsersPath = '/apiv2/bitrix/users';
    BitrixService.GetLeadsPath = '/apiv2/bitrix/get-leads';
    BitrixService.GetDealsPath = '/apiv2/bitrix/get-deals';
    BitrixService.CreateLeadPath = '/apiv2/bitrix/create-lead';
    BitrixService.CreateDealPath = '/apiv2/bitrix/create-deal';
    BitrixService.GetTasksPath = '/apiv2/bitrix/get-tasks';
    BitrixService.CreateTaskPath = '/apiv2/bitrix/create-task';
    BitrixService.CreateContactPath = '/apiv2/bitrix/create-contact';
    BitrixService.GetContactsPath = '/apiv2/bitrix/get-contacts';
    BitrixService.Apiv2BitrixGetActivitiesPostPath = '/apiv2/bitrix/get-activities';
    BitrixService.Apiv2BitrixCreateActivityPostPath = '/apiv2/bitrix/create-activity';
    BitrixService.GetCrmStatusesPath = '/apiv2/bitrix/get-crm-statuses';
    BitrixService.ngInjectableDef = i0.defineInjectable({ factory: function BitrixService_Factory() { return new BitrixService(i0.inject(i1.ApiConfiguration), i0.inject(i2.HttpClient)); }, token: BitrixService, providedIn: "root" });
    return BitrixService;
}(__BaseService));
export { BitrixService };
