import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {AdminUsersService} from "../../../services/admin-users.service";
import {ActivatedRoute} from "@angular/router";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";

@Component({
  selector: 'app-call-form',
  templateUrl: './call-form.component.html',
  styleUrls: ['./call-form.component.scss']
})
export class CallFormComponent implements OnInit {

  public form: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data,
    private adminUsersService: AdminUsersService,
    private route: ActivatedRoute,
    private dialogRef: MatDialogRef<CallFormComponent>
  ) {
  }

  ngOnInit() {
    console.log(this.route.snapshot.paramMap, this.route.snapshot.paramMap);
    const userId = this.route.snapshot.paramMap['id'];
    const dateNow = new Date();
    const hours = dateNow.getHours();
    const minutes = dateNow.getMinutes();
    this.form = new FormGroup({
      date: new FormControl(dateNow),
      hours: new FormControl(hours),
      minutes: new FormControl(minutes),
      userId: new FormControl(this.data.userId),
      comment: new FormControl('')
    });

    this.subscribeToHours();
    this.subscribeToMinutes();
  }

  public subscribeToHours() {
    this.form.controls.hours.valueChanges.subscribe(hours => {
      this.form.patchValue({hours: Math.min(hours, 23)}, {emitEvent: false});
    });
  }

  public subscribeToMinutes() {
    this.form.controls.minutes.valueChanges.subscribe(minutes => {
      this.form.patchValue({minutes: Math.min(59, minutes)}, {emitEvent: false});
    });
  }

  SaveCall() {
    const date = this.form.value.date as Date;
    date.setHours(this.form.value.hours);
    date.setMinutes(this.form.value.minutes);
    const callData = {
      callDate: new Date(date),
      comment: this.form.value.comment,
      userId: this.data.userId
    };
    this.adminUsersService.CreateCall(callData)
      .subscribe(data => {
        this.dialogRef.close()
      });
  }
}
