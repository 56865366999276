import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {CopyingService} from "../../../services/copying.service";

@Component({
  selector: 'app-copying-by-grops-configuration',
  templateUrl: './copying-by-grops-configuration.component.html',
  styleUrls: ['./copying-by-grops-configuration.component.scss']
})
export class CopyingByGropsConfigurationComponent implements OnInit {

  public form: FormGroup;

  constructor(
    private copyingService: CopyingService
  ) { }

  ngOnInit() {
    this.form = this.copyingService.form.get('groupsConfiguration') as FormGroup;
    console.log(this.form);
  }

}
