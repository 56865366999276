import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {MtAccount} from "../../../../../models/mt-account";
import {IntegrationFormService} from "../../../services/integration-form.service";
import {StoreService} from "../../../../../services/store.service";
import {distinctUntilChanged} from "rxjs/operators";

@Component({
  selector: 'app-mt-remarketing-groups',
  templateUrl: './mt-remarketing-groups.component.html',
  styleUrls: ['./mt-remarketing-groups.component.scss']
})
export class MtRemarketingGroupsComponent implements OnInit {
  form: FormGroup;
  accountControl: FormControl = new FormControl();
  accounts: MtAccount[] = [];
  remarketingGroups: any[] = [];
  accountsLoading: boolean = false;
  searchTargetGroups: FormControl = new FormControl();

  public pipelineStatuses = [
    {
      id: 1,
      name: 'Первичный контакт'
    },
    {
      id: 2,
      name: 'Согласование'
    },
    {
      id: 3,
      name: 'Сделка успешна'
    },
    {
      id: 4,
      name: 'Сделка сорвалась'
    }
  ]

  public sortByPipelines = new FormControl(false);

  public get useSortingByPipelines() {
    return this.sortByPipelines.value;
  }

  public get filteredRetargetingGroup() {
    const search = this.searchTargetGroups.value;
    if (search && search !== '') {
      return this.remarketingGroups.filter(x => x.name.match(search));
    } else {
      return this.remarketingGroups;
    }
  }


  constructor(
    private integrationFormService: IntegrationFormService,
    private store: StoreService
  ) {
  }

  ngOnInit() {
    this.form = this.integrationFormService.formService.getControl('mtRemarketingGroups') as FormGroup;
    this.LoadAccounts();
    this.accountControl.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe(account => {
        this.LoadRemarketingGroups(account);
      });
  }

  public LoadAccounts() {
    this.accountsLoading = true;
    this.store.LoadMtAccounts().subscribe(accounts => {
      this.accounts = accounts;
      this.accountsLoading = false;
    });
  }

  public LoadRemarketingGroups(account: MtAccount) {
    this.store.LoadMtRemarketingGroups(account.id)
      .subscribe(groups => {
        this.remarketingGroups = groups;
      });
  }

}
