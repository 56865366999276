/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { APIReponseWrapperListPostMonitorBackend_V2ModelsAnalyticsExperienceLevelModel } from '../models/apireponse-wrapper-list-post-monitor-backend-_v2models-analytics-experience-level-model';
@Injectable({
  providedIn: 'root',
})
class ExperienceLevelService extends __BaseService {
  static readonly GetExperiencelLevelsPath = '/apiv2/experience-level';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `ExperienceLevelService.GetExperiencelLevelsParams` containing the following parameters:
   *
   * - `includeUnselectable`:
   *
   * - `experienceFieldId`:
   *
   * @return Success
   */
  GetExperiencelLevelsResponse(params: ExperienceLevelService.GetExperiencelLevelsParams): __Observable<__StrictHttpResponse<APIReponseWrapperListPostMonitorBackend_V2ModelsAnalyticsExperienceLevelModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.includeUnselectable != null) __params = __params.set('includeUnselectable', params.includeUnselectable.toString());
    if (params.experienceFieldId != null) __params = __params.set('experienceFieldId', params.experienceFieldId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/apiv2/experience-level`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<APIReponseWrapperListPostMonitorBackend_V2ModelsAnalyticsExperienceLevelModel>;
      })
    );
  }
  /**
   * @param params The `ExperienceLevelService.GetExperiencelLevelsParams` containing the following parameters:
   *
   * - `includeUnselectable`:
   *
   * - `experienceFieldId`:
   *
   * @return Success
   */
  GetExperiencelLevels(params: ExperienceLevelService.GetExperiencelLevelsParams): __Observable<APIReponseWrapperListPostMonitorBackend_V2ModelsAnalyticsExperienceLevelModel> {
    return this.GetExperiencelLevelsResponse(params).pipe(
      __map(_r => _r.body as APIReponseWrapperListPostMonitorBackend_V2ModelsAnalyticsExperienceLevelModel)
    );
  }
}

module ExperienceLevelService {

  /**
   * Parameters for GetExperiencelLevels
   */
  export interface GetExperiencelLevelsParams {
    includeUnselectable: boolean;
    experienceFieldId: number;
  }
}

export { ExperienceLevelService }
