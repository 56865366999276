<app-dynamic-form
  [inputs]="FormModel"

  (Canceled)="HandleCancel()"
  (Successed)="HandleSuccess($event)"
  *ngIf="FormModel !== {}"
></app-dynamic-form>
<button class="btn btn-success" (click)="Save()">
  Сохранить
</button>
