<!--<adForm [formGroup]="adForm" (submit)="submit($event)">-->
    <!--<label class="title">-->
        <!--<h3 class="welcome">Вход в систему</h3>-->
    <!--</label>-->
    <!--<div class="login-group">-->
        <!--<ng-container *ngIf="errors?.email">-->
            <!--<div class="error active" *ngFor="let error of errors.email">-->
                <!--{{error}}-->
            <!--</div>-->
        <!--</ng-container>-->
        <!--<input class="username" type="text" id="login_username" placeholder="Электронная почта" formControlName="email">-->
        <!--<ng-container *ngIf="errors?.password">-->
            <!--<div class="error active" *ngFor="let error of errors.password">-->
                <!--{{error}}-->
            <!--</div>-->
        <!--</ng-container>-->
        <!--<input class="password" type="password" id="login_password" placeholder="Пароль" formControlName="password">-->
        <!--<button type="submit" class="btn btn-primary" [clrLoading]="loading">Войти</button>-->
        <!--<a routerLink="/sign-up" class="signup">Зарегистироваться</a>-->
        <!--<a routerLink="/forgot-password" class="signup">Забыли пароль?</a>-->
    <!--</div>-->
<!--</adForm>-->
<form [formGroup]="form" (submit)="Submit($event)">
    <div class="title">
        <h3>Восстановление пароля</h3>
    </div>
    <ng-container *ngIf="!loading; else loadingScreen">
        <ng-container *ngIf="!message; else messageScreen">
            <div class="login-group">
                <input class="username" type="text" id="login_username" placeholder="Введите e-mail" formControlName="email">
                <div class="error active" *ngFor="let error of errors;">
                    {{error}}
                </div>
                <button type="submit" class="btn btn-primary">Восстановить ПАРОЛЬ</button>
            </div>
        </ng-container>
        <ng-template #messageScreen>
            <div class="alert alert-success">
                <div class="alert-items">
                    <div class="alert-item static">
                        <div class="alert-icon-wrapper">
                            <clr-icon class="alert-icon" shape="check-circle"></clr-icon>
                        </div>
                        <span class="alert-text">
                            На ваш email была отправлена ссылка с подтверждением смены пароля.
                        </span>
                    </div>
                </div>
            </div>
        </ng-template>
    </ng-container>
    <ng-template #loadingScreen>
        <div class="loading-screen">
            <div class="spinner"></div>
        </div>
    </ng-template>
    <p class="center">
        На указанный Вами e-mail прийдет ссылка для восстановления пароля привязанного профиля
    </p>
</form>
