import {FormMessageType} from "./form-message-type";

export class FormMessage {
  constructor(
    public Text: string,
    public Type: FormMessageType,
  ) {
  }

  static CreateSuccessMessage = (text: string) => new FormMessage(text, FormMessageType.SUCCESS);
  static CreateWarningMessage = (text: string) => new FormMessage(text, FormMessageType.WARNING);
  static CreateErrorMessage = (text: string) => new FormMessage(text, FormMessageType.ERROR);
  static CreateInfoMessage = (text: string) => new FormMessage(text, FormMessageType.INFO);
}
