import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ITeazerViewModel} from '../../../models/teazer.view-model';
import {ControlContainer} from '@angular/forms';
import {ImagesManagerComponent} from '../../images-manager/images-manager.component';

@Component({
  selector: 'app-create-teazer',
  templateUrl: './create-teazer.component.html',
  styleUrls: ['./create-teazer.component.scss']
})
export class CreateTeazerComponent implements OnInit {

  public valid = false;

  public type: string = 'linear';

  @Input() isUpdating = false;
  @Input() public categories: Array<any>;

  @Input() teazer: ITeazerViewModel;
  @Output() onNext: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(ImagesManagerComponent) ImagesManager: ImagesManagerComponent;

  public get teazers() {
    const teazers = [];
    const images = this.controlContainer.control.get('image').value;
    const titles = this.controlContainer.control.get('title').value;
    if (this.type === 'linear') {
      const minTeazers = Math.min(images.length, titles.length);
      for (let i = 0; i < minTeazers; i++) {
        teazers.push({
          teazer: this.controlContainer.control.value,
          image: images[i],
          title: titles[i],
        });
      }
    } else if (this.type === 'quadratic') {
      images.forEach(image => {
        titles.forEach(title => {
          teazers.push({
            teazer: this.controlContainer.control.value,
            image,
            title,
          });
        });
      });
    }
    return teazers;
  }

  constructor(
    public controlContainer: ControlContainer
  ) {
  }

  ngOnInit() {
    console.log(this.controlContainer.control.get('title'));
  }

  OnTeazerChange(data) {
    // if (data.image === '') {
    //   data.image = this.teazer.image;
    // }
    // if (data.link === '') {
    //   data.link = this.teazer.link;
    // }
    // this.teazer = Object.assign(this.teazer, data);
  }

  public clickNext() {
    this.onNext.emit();
  }

  OnValidStatusChange($event: any) {
    this.valid = $event;
  }

  public OnSelectImage(image) {
    console.log(image);
    this.controlContainer.control.get('image').patchValue(image);
    this.controlContainer.control.get('image').markAsDirty();
  }

  RemoveImageFromSelect($event: string) {
    this.ImagesManager.OnSelectImage($event);
  }
}
