import {Injectable} from '@angular/core';
import {Query} from '@datorama/akita';
import {IntegrationSessionStore, IntegrationSessionState} from './integration-session.store';

@Injectable({providedIn: 'root'})
export class IntegrationSessionQuery extends Query<IntegrationSessionState> {

  /**
   * Текущая сессия пользователя
   */
  public currentSession$ = this.select(state => state.session);

  /**
   * Происходит ли загрузка данных
   */
  public isLoading$ = this.selectLoading();

  constructor(protected store: IntegrationSessionStore) {
    super(store);
  }

}
