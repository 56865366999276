/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./external-login-form.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./external-login-form.component";
import * as i3 from "../../../../api/services/utils.service";
import * as i4 from "../../../../api/services/oauth.service";
import * as i5 from "../../../../shared/services/token-checker.service";
import * as i6 from "@angular/router";
var styles_ExternalLoginFormComponent = [i0.styles];
var RenderType_ExternalLoginFormComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ExternalLoginFormComponent, data: {} });
export { RenderType_ExternalLoginFormComponent as RenderType_ExternalLoginFormComponent };
export function View_ExternalLoginFormComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [["class", "btn btn-primary btn-block"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["class", "sign-in-icon"], ["src", "/assets/images/vk-social-network-logo-white.svg"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u0412\u043E\u0439\u0442\u0438 \u0447\u0435\u0440\u0435\u0437 \u0412\u041A\u043E\u043D\u0442\u0430\u043A\u0442\u0435\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.baseUrl + "/apiv2/OAuth/authorize/vk"); _ck(_v, 0, 0, currVal_0); }); }
export function View_ExternalLoginFormComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-external-login-form", [], null, null, null, View_ExternalLoginFormComponent_0, RenderType_ExternalLoginFormComponent)), i1.ɵdid(1, 114688, null, 0, i2.ExternalLoginFormComponent, [i3.UtilsService, i4.OAuthService, i5.TokenCheckerService, i6.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ExternalLoginFormComponentNgFactory = i1.ɵccf("app-external-login-form", i2.ExternalLoginFormComponent, View_ExternalLoginFormComponent_Host_0, {}, {}, []);
export { ExternalLoginFormComponentNgFactory as ExternalLoginFormComponentNgFactory };
